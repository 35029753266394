import { Reducer } from 'redux';
import { flow, set } from 'lodash/fp';

import { WorkflowActionTypes, WorkflowState, BaseScheduler, ScheduleEventTab, SchedulerAction, SendEmailContent, CreateAttestationContent, WorkflowPage, ScheduledActionsListView } from './types';
import { LoginActionTypes } from '../../../auth/login/store';
import { UserAnswerId } from '../../attestations/store';
import { formatDate } from '../../../../utils/luxon';

export const initialAttestationInstance = (userIds: UserAnswerId[]) => ({
    userIds,
    deadlineInterval: null,
    deadlineIntervalValue: null
});

export const initialContentByActionType = (action: SchedulerAction): SendEmailContent | CreateAttestationContent => {
    switch (action) {
        case SchedulerAction.CREATE_ATTESTATION:
            return {
                attestationFormId: null,
                instancesToCreate: []
            };
        case SchedulerAction.SEND_EMAIL:
        default:
            return {
                userIds: [],
                subject: '',
                wysiwygTemplate: null,
            };
    }
};

export const initialEvent = (firstDate: string | null = null): BaseScheduler => ({
    action: null,
    content: null,
    firstDate,
    nextDate: null,
    interval: null,
    intervalValue: null,
    hasCompleted: 0,
    repeat: 0,
    isArchived: 0
});

export const INITIAL_STATE: WorkflowState = {
    workflowPage: WorkflowPage.SELECT,
    calendarWizardOpen: false,
    event: initialEvent(),
    selectedEventTab: ScheduleEventTab.SCHEDULE,
    selectedTabValid: false,
    toolbarDate: new Date(),
    selectedMonthYear: formatDate(new Date(), 'yyyy-MM'),
    scheduleDetailsCorrect: false,
    isSaving: false,
    scheduledActions: [],
    selectedMonthActions: [],
    isEditing: false,
    showUpdatedMessage: false,
    confirmDeleteEvent: null,
    isDeleting: false,
    scheduledActionsListView: ScheduledActionsListView.ACTIVE,
    isLoading: false,
    selectedEventDate: null,
};

export const workflowReducer: Reducer<WorkflowState> = (state = INITIAL_STATE, { type, payload }): WorkflowState => {
    switch (type) {
        case WorkflowActionTypes.SET_WORKFLOW_PAGE:
            return set('workflowPage', payload, state);
        case WorkflowActionTypes.TOGGLE_SCHEDULE_WIZARD: {
            const { isOpen, event } = payload;
            const isEditing = isOpen && (!event || event && !event.scheduledActionId);
            return flow(
                set('calendarWizardOpen', isOpen),
                set('event', event || initialEvent()),
                set('selectedTabValid', false),
                set('selectedEventTab', ScheduleEventTab.SCHEDULE),
                set('scheduleDetailsCorrect', false),
                set('isEditing', isEditing),
                set('showUpdatedMessage', false),
            )(state);
        }
        case WorkflowActionTypes.SET_EVENT_VALUE: {
            const { key, value } = payload;
            return set(`event[${key}]`, value, state);
        }
        case WorkflowActionTypes.SET_TOOLBAR_DATE:
            return set('toolbarDate', payload, state);
        case WorkflowActionTypes.SET_SELECTED_EVENT_TAB:
            return set('selectedEventTab', payload, state);
        case WorkflowActionTypes.SET_SELECTED_EVENT_TAB_VALID:
            return set('selectedTabValid', payload, state);
        case WorkflowActionTypes.UPDATE_CONTENT_ATTESTATION_ID:
            return flow(
                set('event.content.attestationFormId', payload.attestationFormId),
                set('event.content.instancesToCreate', [initialAttestationInstance(payload.userAnswerIds)])
            )(state);
        case WorkflowActionTypes.UPDATE_CONTENT_ATTESTATION_INSTANCES:
            return set('event.content.instancesToCreate', payload, state);
        case WorkflowActionTypes.SET_SCHEDULED_EMAIL_CONTENT:
            return set(`event.content.[${payload.key}]`, payload.value, state);
        case WorkflowActionTypes.CONFIRM_SCHEDULED_DETAILS:
            return set('scheduleDetailsCorrect', payload, state);
        case WorkflowActionTypes.SAVE_SCHEDULED_ACTION_STARTED:
            return set('isSaving', true, state);
        case WorkflowActionTypes.SAVE_SCHEDULED_ACTION_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('selectedMonthActions', payload.scheduledActions),
                set('scheduledActions', payload.allScheduledActions)
            )(state);
        case WorkflowActionTypes.SAVE_SCHEDULED_ACTION_FAILED:
            return set('isSaving', false, state);
        case WorkflowActionTypes.FETCH_CALENDAR_EVENTS_SUCCESSFUL:
            return set('selectedMonthActions', payload, state);
        case WorkflowActionTypes.SKIP_SCHEDULED_ACTION_SUCCESSFUL:
            return set('selectedMonthActions', payload, state);
        case WorkflowActionTypes.FETCH_ALL_CALENDAR_EVENTS_STARTED:
            return set('isLoading', true, state);
        case WorkflowActionTypes.FETCH_ALL_CALENDAR_EVENTS_SUCCESSFUL:
            return flow(
                set('isLoading', false),
                set('scheduledActions', payload)
            )(state);
        case WorkflowActionTypes.SET_SELECTED_MONTH_YEAR:
            return set('selectedMonthYear', payload, state);
        case WorkflowActionTypes.RESET_CALENDAR:
            return flow(
                set('selectedMonthYear', formatDate(payload, 'yyyy-MM')),
                set('toolbarDate', payload),
                set('workflowPage', WorkflowPage.SELECT),
            )(state);
        case WorkflowActionTypes.SET_SCHEDULED_ACTION_EDITING:
            return set('isEditing', payload, state);
        case WorkflowActionTypes.SHOW_SCHEDULE_UPDATED_MESSAGE:
            return set('showUpdatedMessage', payload, state);
        case WorkflowActionTypes.TOGGLE_DELETE_EVENT_CONFIRMATION_MODAL:
            return set('confirmDeleteEvent', payload, state);
        case WorkflowActionTypes.DELETE_SCHEDULED_ACTION_STARTED:
            return set('isDeleting', true, state);
        case WorkflowActionTypes.DELETE_SCHEDULED_ACTION_SUCCESSFUL:
            return flow(
                set('isDeleting', false),
                set('confirmDeleteEvent', null),
                set('selectedMonthActions', payload.scheduledActions),
                set('scheduledActions', payload.allScheduledActions)
            )(state);
        case WorkflowActionTypes.DELETE_SCHEDULED_ACTION_FAILED:
            return set('isDeleting', false, state);
        case WorkflowActionTypes.TOGGLE_SCHEDULED_ACTIONS_LIST_VIEW:
            return set('scheduledActionsListView', payload, state);
        case WorkflowActionTypes.SET_SELECTED_EVENT_DATE:
            return set('selectedEventDate', payload, state);
        case WorkflowActionTypes.RECOVER_SCHEDULED_ACTION_STARTED:
            return set('isSaving', true, state);
        case WorkflowActionTypes.RECOVER_SCHEDULED_ACTION_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('selectedMonthActions', payload.scheduledActions),
                set('scheduledActions', payload.allScheduledActions)
            )(state);
        case WorkflowActionTypes.RECOVER_SCHEDULED_ACTION_FAILED:
            return set('isSaving', false, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
