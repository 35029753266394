import React, { useCallback, useMemo, useRef, useState } from 'react';

import Logo from '../../../assets/logos/Ark51_HORIBLACK&BLACK.png';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useTickerPosition } from '../../../hooks/useTickerPosition';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { getPortfolioStatistics, getShowPortfolioStatistics, toggleShowPortfolioStatistics } from '../../auth/notifications/store';
import { Icon } from '../icon/Icon';
import { HamburgerMenu } from '../icons';
import styles from './PortfolioStatistics.module.scss';

const WINDOW_WIDTH_OFFSET = 46;

interface StatisticsBarProps {
    portfolioStatistics: string[];
}

const StatisticsBar: React.FC<StatisticsBarProps> = ({ portfolioStatistics }) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [screenWidth] = useWindowResize();
    const moveWrapper = useRef<HTMLDivElement | null>(null);
    const leftPosition = useTickerPosition(moveWrapper, WINDOW_WIDTH_OFFSET, 15000, isHovering);
    const placeholderWidth = useMemo(() => screenWidth - WINDOW_WIDTH_OFFSET, [screenWidth]);

    return (
        <div className={styles.portfolioStatistics}>
            <div className={styles.portfolioMoveWrapper} ref={moveWrapper} style={{ left: leftPosition }} onMouseOver={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                <div className={styles.preStatisticsWrapper} style={{ width: `${placeholderWidth}px` }}><img className={styles.logo} src={Logo} /></div>
                {portfolioStatistics.map((statistic, index) => (
                    <div className={styles.statisticWrapper} key={index}>
                        <div className={styles.statisticSpacer} />
                        <div className={styles.statistic}>{statistic}</div>
                    </div>
                ))}
                <div className={styles.postStatisticsWrapper} style={{ width: `${placeholderWidth}px` }}><img className={styles.logo} src={Logo} /></div>
            </div>
        </div>
    );
};

export const PortfolioStatistics: React.FC = () => {
    const dispatch = useAppDispatch();
    const showPortfolioStatistics = useAppSelector(getShowPortfolioStatistics);
    const portfolioStatistics = useAppSelector(getPortfolioStatistics);
    const closeStatisticsBanner = useCallback(() => dispatch(toggleShowPortfolioStatistics(false)), [dispatch]);
    const openStatisticsBanner = useCallback(() => dispatch(toggleShowPortfolioStatistics(true)), [dispatch]);

    if (portfolioStatistics.length === 0) {
        return null;
    }

    if (!showPortfolioStatistics) {
        return (
            <div className={styles.portfolioStatisticsOpen}>
                <button onClick={openStatisticsBanner} className={styles.iconButton}>
                    <Icon icon={HamburgerMenu} fontSize={20} />
                </button>
            </div>
        );
    }

    return (
        <div className={styles.portfolioStatisticsWrapper}>
            <StatisticsBar portfolioStatistics={portfolioStatistics} />
            <button onClick={closeStatisticsBanner} className={styles.iconButton}>
                <Icon icon={HamburgerMenu} fontSize={20} />
            </button>
        </div>
    );
};
