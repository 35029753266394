import React from 'react';
import classnames from 'classnames';

import styles from './Radio.module.scss';
import { InformationTooltip } from '../tooltip';
import { Icon } from '../icon/Icon';
import { Scrollable } from '../scrollable/Scrollable';

export interface RadioListOption {
    id: string;
    label: string;
    isSelected: boolean;
    description?: string;
    icon?: React.FC;
    disabled?: boolean;
}

interface RadioListProps {
    onChange: (id: string) => void;
    options: RadioListOption[];
    testId?: string;
    fontSize?: number;
}

export const RadioList: React.FC<RadioListProps> = ({ onChange, testId, options, fontSize = 14 }) => (
    <Scrollable>
        <div className={styles.radioListWrapper} data-testid={`${testId}-radio-list-wrapper`}>
            {options.map(({ id, label, description, isSelected, icon, disabled }) => (
                <div className={classnames(styles.optionWrapper, { [styles.disabledOptionWrapper]: disabled })} key={id}>
                    <div className={styles.radioInputWrapper}>
                        <input
                            type='radio'
                            checked={isSelected}
                            onClick={() => onChange(id)}
                            onChange={() => onChange(id)}
                            data-testid={`${testId}-${id}-radio-button`}
                            disabled={disabled}
                            className={styles.radioInput}
                        />
                    </div>
                    {icon && <div className={styles.radioButtonIcon}><Icon icon={icon} fontSize={fontSize * 1.5} /></div>}
                    <div className={styles.radioButtonLabel} style={{ fontSize: `${fontSize}px` }}>{label}</div>
                    {description && description.length > 0 &&
                        <div className={styles.radioButtonDescription}>
                            <InformationTooltip content={description} fontSize={fontSize - 2} />
                        </div>
                    }
                </div>
            ))}
        </div>
    </Scrollable>
);
