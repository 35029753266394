import classnames from 'classnames';
import React from 'react';

import { AgencyDatasetFieldType } from '../../datasets/store';
import { InformationTooltip } from '../../shared/tooltip';
import styles from './AnnexPreview.module.scss';
import { widthsByType } from '../../admin/dataset-builder/preview/tablePreview/TableHeader';

const { white } = styles;

interface AnnexHeaderProps {
    label: string;
    aliasLabel?: string;
    isFirstCell?: boolean;
    isLastCell: boolean;
    type: AgencyDatasetFieldType;
    description: string;
    refLabel?: string
    showRef?: boolean;
    isCustom?: boolean
}

export const AnnexHeader: React.FC<AnnexHeaderProps> = ({ aliasLabel, label, isFirstCell = false, isLastCell, type, description, refLabel, showRef, isCustom }) => {
    const annexLabel = aliasLabel ? aliasLabel : label;
    const customFieldLabel = isCustom && !label ? 'Custom Field' : label;
    const displayLabel = isCustom ? customFieldLabel : annexLabel;
    const cellWidth = `${widthsByType[type]}px`;
    return (
        <div
            className={classnames(styles.annexHeaderWrapper, {
                [styles.headerFirstCell]: isFirstCell,
                [styles.headerLastCell]: isLastCell
            })}
            style={{ width: cellWidth, minWidth: cellWidth }}>
            <div className={styles.annexLabel}>{displayLabel}</div>
            {aliasLabel && <InformationTooltip label='L' content={label} labelColor={white} />}
            {description && <InformationTooltip content={description} labelColor={white} />}
            {refLabel && showRef && <InformationTooltip content={refLabel} label='?' labelColor={white} />}
        </div>
    );
};
