import React, { useCallback, useMemo } from 'react';
import { isNull, isUndefined, noop } from 'lodash/fp';

import { OpenSuggestedChange, Playbook, SuggestedChangesDB } from './store';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { ABSTRACT_ID } from '../../constants/playbooks';
import styles from './Playbook.module.scss';
import { SuggestedChangeTile } from './SuggestedChangeTile';
import { AbstractSuggestedChanges, SectionSuggestedChanges } from '../../playbook/store';
import { AbstractSuggestedChange } from './AbstractSuggestedChange';
import { SectionSuggestedChange } from './SectionSuggestedChange';

export interface SuggestedChangesProps {
    suggestedChanges: SuggestedChangesDB[];
    playbook: Playbook;
    setSuggestedChangeOpen: (playbookSuggestionId: number, sectionId: string) => void;
    openSuggestedChange: OpenSuggestedChange | null;
    openDeviations?: string[];
    toggleDeviation?: (sectionId: string) => void;
    abstractSuggestedChange?: AbstractSuggestedChanges;
    sectionSuggestedChange?: SectionSuggestedChanges;
    testId?: string;
}

export const SuggestedChanges: React.FC<SuggestedChangesProps> = ({ suggestedChanges, playbook, setSuggestedChangeOpen, openSuggestedChange, openDeviations = [], toggleDeviation = noop, abstractSuggestedChange, sectionSuggestedChange, testId = 'playbook-suggested-changes' }) => {

    const { content: { sections } } = playbook;

    const sectionSuggestedChanges = useCallback((sectionId: string) => suggestedChanges?.filter(suggestion => suggestion.sectionId === sectionId) || [], [suggestedChanges]);

    const suggestedChangeOpen = useCallback((sectionId: string, playbookSuggestionId: number) => openSuggestedChange && openSuggestedChange.playbookSuggestionId === playbookSuggestionId || false, [openSuggestedChange]);

    const content = useMemo(() => {
        if (isNull(openSuggestedChange)) {
            return (
                <div>
                    <div className={styles.sectionSuggestedChangesWrapper}>
                        <Scrollable>
                            <div className={styles.splitViewSuggestedChangeTilesWrapper}>
                                {sectionSuggestedChanges(ABSTRACT_ID).map(suggestedChange => (
                                    <SuggestedChangeTile suggestedChange={suggestedChange} openSuggestedChange={openSuggestedChange} key={suggestedChange.playbookSuggestionId} setSuggestedChangeOpen={setSuggestedChangeOpen} suggestedChangeOpen={suggestedChangeOpen(ABSTRACT_ID, suggestedChange.playbookSuggestionId)} testId={`${testId}-tile-abstract`} />
                                ))}
                            </div>
                        </Scrollable>
                    </div>
                    {sections.map(section => (
                        <div key={section.sectionId} className={styles.sectionSuggestedChangesWrapper}>
                            <Scrollable>
                                <div className={styles.splitViewSuggestedChangeTilesWrapper}>
                                    {sectionSuggestedChanges(section.sectionId).map(suggestedChange => (
                                        <SuggestedChangeTile suggestedChange={suggestedChange} openSuggestedChange={openSuggestedChange} key={suggestedChange.playbookSuggestionId} setSuggestedChangeOpen={setSuggestedChangeOpen} suggestedChangeOpen={suggestedChangeOpen(suggestedChange.sectionId, suggestedChange.playbookSuggestionId)} testId={`${testId}-tile-section`} />
                                    ))}
                                </div>
                            </Scrollable>
                        </div>
                    ))}
                </div>
            );
        }
        if (!isUndefined(abstractSuggestedChange)) {
            const sectionChanges = sectionSuggestedChanges(openSuggestedChange.sectionId);
            return (
                <AbstractSuggestedChange
                    suggestedChanges={sectionChanges}
                    setSuggestedChangeOpen={setSuggestedChangeOpen}
                    openSuggestedChange={openSuggestedChange}
                    abstractSuggestedChange={abstractSuggestedChange}
                    testId={testId}
                />
            );
        }
        if (!isUndefined(sectionSuggestedChange)) {
            const sectionChanges = sectionSuggestedChanges(openSuggestedChange.sectionId);
            return (
                <SectionSuggestedChange
                    suggestedChanges={sectionChanges}
                    setSuggestedChangeOpen={setSuggestedChangeOpen}
                    openSuggestedChange={openSuggestedChange}
                    sectionSuggestedChange={sectionSuggestedChange}
                    toggleDeviation={toggleDeviation}
                    openDeviations={openDeviations}
                    testId={testId}
                />
            );
        }
        return null;
    }, [openSuggestedChange, sectionSuggestedChange, openDeviations, abstractSuggestedChange, toggleDeviation, sectionSuggestedChanges, sections, suggestedChangeOpen, setSuggestedChangeOpen, testId]);

    return (
        <Scrollable>
            {content}
        </Scrollable>
    );
};
