import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppSelector, useAppDispatch } from '../../../../hooks/react-redux';
import { Spinner } from '../../../shared/spinner/Spinner';
import { setSelectedManagerTab, toggleAttestationManagerWizardOpen, AttestationManagerTab, getSelectedManagerTab, getManagerWizardOpen, AttestationManager, getAttestationInstanceUpdated, getIsSaving, saveAttestationInstanceStarted } from '../store';
import { WizardModal } from '../../../shared/wizard/WizardModal';
import { AttestationManager as AttestationManagerIcon } from '../../../shared/icons';
import { Attestations, Configure, Details } from './tabs';
import styles from './AttestationManager.module.scss';

interface AttestationManagerWizardProps {
    attestation: AttestationManager;
    testId?: string;
}

export const AttestationManagerWizard: React.FC<AttestationManagerWizardProps> = ({ attestation, testId }) => {
    const dispatch = useAppDispatch();

    const selectedTab = useAppSelector(getSelectedManagerTab);
    const wizardIsOpen = useAppSelector(getManagerWizardOpen);
    const attestationInstanceUpdated = useAppSelector(getAttestationInstanceUpdated);
    const isSaving = useAppSelector(getIsSaving);
    const isComplete = !isNull(attestation.attestationInstance.completedBy);
    const modalTitle = `${isComplete ? 'View' : 'Manage'} Attestation`;

    const managerTabs = Object.values(AttestationManagerTab);
    const selectedTabIndex = managerTabs.indexOf(selectedTab);

    const selectTab = useCallback((tab: AttestationManagerTab) => dispatch(setSelectedManagerTab(tab)), [dispatch]);
    const previous = useCallback(() => selectTab(managerTabs[selectedTabIndex - 1]), [managerTabs, selectTab, selectedTabIndex]);
    const next = useCallback(() => selectTab(managerTabs[selectedTabIndex + 1]), [managerTabs, selectTab, selectedTabIndex]);
    const save = useCallback(() => dispatch(saveAttestationInstanceStarted()), [dispatch]);
    const closeWizard = useCallback(() => dispatch(toggleAttestationManagerWizardOpen(false)), [dispatch]);

    const wizardTabs = useMemo(() => managerTabs.map(tab => ({
        label: tab,
        selected: tab === selectedTab,
        onClick: () => selectTab(tab)
    })), [selectTab, selectedTab, managerTabs]);

    const wizardButtons = useMemo(() => [
        {
            label: 'Close',
            onClick: closeWizard,
            showButton: true,
            displayLeft: true,
            disabled: false,
            disabledTooltip: null
        },
        {
            label: 'Previous',
            onClick: previous,
            disabled: false,
            showButton: selectedTabIndex !== 0,
            disabledTooltip: null,
        },
        {
            label: 'Next',
            onClick: next,
            disabled: false,
            disabledTooltip: null,
            showButton: selectedTabIndex !== managerTabs.length - 1
        },
        {
            label: 'Save',
            onClick: save,
            disabled: !attestationInstanceUpdated || isSaving,
            disabledTooltip: null,
            showButton: selectedTabIndex === managerTabs.length - 1 && attestationInstanceUpdated
        }
    ], [selectedTabIndex, managerTabs, closeWizard, previous, next, save, attestationInstanceUpdated, isSaving]);

    const tabContent = useMemo(() => {
        switch (selectedTab) {
            case AttestationManagerTab.DETAILS:
                return <Details attestation={attestation} testId={`${testId}-details`} />;
            case AttestationManagerTab.ATTESTATIONS:
                return <Attestations attestation={attestation} testId={`${testId}-attestations`} />;
            case AttestationManagerTab.CONFIGURE:
                return <Configure attestation={attestation} testId={`${testId}-configure`} />;
            default:
                return <Spinner />;
        }
    }, [selectedTab, attestation, testId]);

    return (
        <WizardModal
            isOpen={wizardIsOpen}
            buttons={wizardButtons}
            tabs={wizardTabs}
            title={modalTitle}
            testId={testId}
            closeWizard={closeWizard}
            headerIcon={AttestationManagerIcon}
        >
            <div className={styles.wizardTabContentWrapper}>
                {tabContent}
            </div>
        </WizardModal>
    );
};
