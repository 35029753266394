import React from 'react';
import { isNull } from 'lodash/fp';

import { endOfDay, diffToNowInMeasurement, formatDate, SHORT_MONTH_FORMAT } from '../../../utils/luxon';
import { CustomTooltip } from '../tooltip';
import styles from './Table.module.scss';

const { red, amber, green, french } = styles;

export const getDeadline = (deadline: string | null) => {
    let styledDeadline = { text: 'No Deadline Set', color: french };
    const ragBoundaries = { amber: 14, red: 7 };

    if (deadline) {
        const midnightDeadline = endOfDay(deadline);
        const hoursUntilDeadlineMissed = diffToNowInMeasurement(midnightDeadline, 'hours') || -1;
        const daysUntilDeadlineMissed = Math.floor(hoursUntilDeadlineMissed / 24);
        styledDeadline = { text: 'Deadline Passed', color: green };
        if (daysUntilDeadlineMissed <= ragBoundaries.amber && daysUntilDeadlineMissed > ragBoundaries.red) {
            styledDeadline.color = amber;
        } else if (daysUntilDeadlineMissed <= ragBoundaries.red) {
            styledDeadline.color = red;
        }

        if (daysUntilDeadlineMissed >= 1) {
            styledDeadline.text = `${daysUntilDeadlineMissed} Day${daysUntilDeadlineMissed > 1 ? 's' : ''} Remaining`;
        } else if (hoursUntilDeadlineMissed >= 0) {
            styledDeadline.text = `${Math.ceil(hoursUntilDeadlineMissed)} Hour${hoursUntilDeadlineMissed > 1 ? 's' : ''} Remaining`;
        }
    }

    return styledDeadline;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const DeadlineCell: React.FC<{ value: any; valueFormatted?: string | null }> = ({ value, valueFormatted }) => {
    const deadlineValue = valueFormatted || value as string | null;

    const { text, color } = getDeadline(deadlineValue);
    const overlayText = !isNull(deadlineValue) && formatDate(deadlineValue, SHORT_MONTH_FORMAT) || null;
    return (
        <CustomTooltip overlayText={overlayText}>
            <div className={styles.deadlineWrapper} style={{ color }}>{text}</div>
        </CustomTooltip>
    );
};
