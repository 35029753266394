import React from 'react';

import { Position, PositionModal } from '../../shared/modal/PositionModal';
import styles from './DatasetInstance.module.scss';

interface ClauseModalProps {
    isOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    clauseLabel?: string;
    updateClause: (val: string) => void;
}

export const ClauseModal: React.FC<ClauseModalProps> = ({ closeModal, isOpen, position, updateClause, clauseLabel }) => (
    <PositionModal
        isOpen={isOpen}
        closeModal={closeModal}
        position={position}
        testId='clause-modal'
    >
        <>
            <div data-testid='clause-title' className={styles.clauseInputTitle}>Clause</div>
            <textarea
                style={{ resize: 'none' }}
                maxLength={500}
                className={styles.clauseInput}
                placeholder='Add a clause...'
                value={clauseLabel}
                onChange={e => updateClause(e.target.value)}
                data-testid='clause-input'
            />
        </>
    </PositionModal>
);
