import { useMemo } from 'react';

import { PlaybookPageView } from '../components/admin/playbook/store';
import { useWindowResize } from './useWindowResize';

const NAV_BAR = 100;
const WIZARD_BUTTONS = 48;
const HEIGHT_OFFSET = NAV_BAR + WIZARD_BUTTONS;

export const usePlaybooksSplitView = (currentAnalysisView: PlaybookPageView, sideMenuOpen: boolean) => {
    const [screenWidth, screenHeight] = useWindowResize();

    const playbookScreenWidth = useMemo(() => {
        const sideMenuWidth = sideMenuOpen ? ((screenWidth * 0.2 ) - 10) : 42;
        const width = sideMenuOpen ? screenWidth - sideMenuWidth : screenWidth - sideMenuWidth;
        switch (currentAnalysisView) {
            case PlaybookPageView.SPLIT:
                return width * 0.5;
            default:
                return width;
        }
    }, [currentAnalysisView, screenWidth, sideMenuOpen]);

    const playbookScreenHeight = useMemo(() => screenHeight - HEIGHT_OFFSET, [screenHeight]);
    return [playbookScreenWidth, playbookScreenHeight];
};
