import React from 'react';

import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import { Toggle } from '../../../shared/toggle';
import styles from '../Reports.module.scss';

interface SectionWrapperProps {
    id: string;
    label: string;
    isOpen: boolean;
    toggleSection: (sectionId: string) => void;
    toggleSectionFields: (sectionId: string) => void;
    allFieldsSelected: boolean;
    children: JSX.Element;
}

export const SectionWrapper: React.FC<SectionWrapperProps> = ({ label, id, children, isOpen, toggleSection, toggleSectionFields, allFieldsSelected }) => {
    const sectionOpenIcon = isOpen ? CaretDown : CaretSide;

    return (
        <div key={id} className={styles.sectionWrapper} data-testid={`opinion-report-section-${id}-wrapper`}>
            <div className={styles.sectionHeaderWrapper}>
                <div className={styles.sectionHeader} onClick={() => toggleSection(id)} data-testid={`opinion-report-section-${id}-collapse`}>
                    <div className={styles.sectionOpenIcon}>
                        <Icon icon={sectionOpenIcon} fontSize={15} />
                    </div>
                    <div className={styles.sectionLabel} data-testid={`opinion-report-section-${id}-label`}>{label}</div>
                </div>
                <div className={styles.selectAllToggleWrapper}>
                    <div className={styles.selectAllToggleLabel}>{`${allFieldsSelected ? 'Des' : 'S'}elect All`}</div>
                    <Toggle
                        checked={allFieldsSelected}
                        onChange={() => toggleSectionFields(id)}
                    />
                </div>
            </div>
            {isOpen &&
                <div className={styles.allFieldsWrapper}>
                    {children}
                </div>
            }
        </div>
    );
};
