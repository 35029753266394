import React, { useCallback, useState } from 'react';

import { useAppDispatch } from '../../../../hooks/react-redux';
import { formatDate } from '../../../../utils/luxon';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { Icon } from '../../../shared/icon/Icon';
import { Document, LinkChain } from '../../../shared/icons';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Toggle } from '../../../shared/toggle';
import { ArkOpinion, assertPotentialBaseOpinionStarted } from '../store';
import styles from './UploadOpinions.module.scss';
import { OpinionCommissionedBy } from '../../../admin/opinions/store';

interface PotentialBaseOpinionModalProps {
    closeModal: () => void;
    opinion: ArkOpinion;
}

export const PotentialBaseOpinionModal: React.FC<PotentialBaseOpinionModalProps> = ({ closeModal, opinion }) => {
    const [isBaseOpinion, setIsBaseOpinion] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const { commissionedBy, commissionedByIfOther, jurisdiction, scope, dateOfOpinion, type, focus, agreementType } = opinion;
    const confirmBaseOpinion = useCallback(() => { dispatch(assertPotentialBaseOpinionStarted(isBaseOpinion)); }, [dispatch, isBaseOpinion]);

    return (
        <ConfirmationModal
            isOpen
            closeModal={closeModal}
            confirmLabel='Confirm'
            testId='potential-base-opinion'
            confirm={confirmBaseOpinion}
        >
            <ModalHeader label='Possible Secondary Document' icon={LinkChain} />
            <div className={styles.potentialBaseOpinionWrapper}>
                <div className={styles.warningHeader}>We have identified another opinion we believe may be related to the one uploaded although the dates do not match exactly</div>
                <div className={styles.potentialBaseOpinion}>
                    <div className={styles.potentialBaseOpinionIcon}><Icon icon={Document} fontSize={60} /></div>
                    <div className={styles.potentialBaseOpinionDetails}>
                        <div className={styles.jurisdiction}>{jurisdiction}</div>
                        <div className={styles.focusAndAgreementType}>
                            <div className={styles.agreementType}>Agreement Type: {agreementType}</div>
                            <div className={styles.focus}>Focus: {focus}</div>
                        </div>
                        <div className={styles.scopeAndCommissionedBy}>
                            <div className={styles.scope}>Scope: {capitaliseStartLetter(scope!)}</div>
                            <div className={styles.commissionedBy}>Commissioned By: {commissionedBy === OpinionCommissionedBy.OTHER ? commissionedByIfOther : commissionedBy}</div>
                        </div>
                        <div className={styles.typeAndDate}>
                            <div className={styles.type}>Type: {type}</div>
                            <div className={styles.dateOfOpinion}>Date of Opinion: {formatDate(dateOfOpinion!)}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.notesWrapper}>
                    <div className={styles.potentialBaseOpinionNote}>When the dates are an exact match we assert that the document uploaded is a secondary document to the previously uploaded opinion</div>
                    <div className={styles.potentialBaseOpinionNote}>You can either compile this document as a secondary document to the opinion listed above or add the document as a new point on the opinions timeline with its own unique data map.</div>
                </div>
                <div className={styles.assertionWrapper}>
                    <div className={styles.assertionLabel}>Add as a secondary document to the existing opinion listed above?</div>
                    <Toggle
                        checked={isBaseOpinion}
                        onChange={setIsBaseOpinion}
                    />
                </div>
                <div className={styles.optionDivider}>OR</div>
                <div className={styles.assertionWrapper}>
                    <div className={styles.assertionLabel}>Create a new data map in the timeline for this opinion?</div>
                    <Toggle
                        checked={!isBaseOpinion}
                        onChange={checked => setIsBaseOpinion(!checked)}
                    />
                </div>
            </div>
        </ConfirmationModal>
    );
};
