import React, { useCallback, useState } from 'react';

import { useWindowResize } from '../../../../../hooks/useWindowResize';
import { DatasetField, DatasetFieldType, DatasetSection, FormDatasetDefinition, SingleDatasetField, isGroupField } from '../../../../datasets/store';
import { Icon } from '../../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../../shared/icons';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { InformationTooltip } from '../../../../shared/tooltip';
import styles from './FormPreview.module.scss';
import { GroupFieldPreview } from './GroupFieldPreview';
import { MultiTogglePreview } from './MultiTogglePreview';
import { SingleFieldPreview } from './SingleFieldPreview';

interface FormPreviewProps {
    datasetDefinition: FormDatasetDefinition;
}

interface SectionPreviewProps {
    section: DatasetSection;
    datasetDefinition: FormDatasetDefinition;
    sectionIndex: number;
}

interface FieldsPreviewProps {
    fields: DatasetField[];
}

const FieldsPreview: React.FC<FieldsPreviewProps> = ({ fields }) => {
    const getPreviewContent = useCallback((field: DatasetField, index: number) => {
        const isLastFieldInSection = index === fields.length - 1;
        if (isGroupField(field)) {
            if (field.type === DatasetFieldType.GROUP) {
                return (
                    <GroupFieldPreview field={field} key={field.id} groupIndex={index} isLastFieldInSection={isLastFieldInSection} datasetBuilder={true} />
                );
            }
            if (field.type === DatasetFieldType.MULTI_TOGGLE) {
                return (
                    <MultiTogglePreview field={field} key={field.id} groupIndex={index} />
                );
            }
        }
        return (
            <SingleFieldPreview field={field as SingleDatasetField} key={field.id} index={index} isLastFieldInSection={isLastFieldInSection} datasetBuilder={true} />
        );
    }, [fields.length]);

    return (
        <div className={styles.allFieldsWrapper}>
            {fields.map((field, index) => getPreviewContent(field, index))}
        </div>
    );
};

const SectionPreview: React.FC<SectionPreviewProps> = ({ section, datasetDefinition, sectionIndex }) => {
    const [sectionOpen, setSectionOpen] = useState<boolean>(false);
    const { id, label, description } = section;
    const fields = datasetDefinition.datasetFields[id];
    const sectionOpenIcon = sectionOpen ? CaretDown : CaretSide;

    const toggleSection = () => setSectionOpen(!sectionOpen);

    return (
        <div key={id} className={styles.sectionPreviewWrapper} data-testid={`form-preview-section-${sectionIndex}-wrapper`}>
            <div className={styles.sectionHeader} onClick={toggleSection} data-testid={`form-preview-section-${sectionIndex}-collapse`}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={sectionOpenIcon} fontSize={15} />
                </div>
                <div className={styles.sectionLabel} data-testid={`form-preview-section-${sectionIndex}-label`}>{label}</div>
                {description && <InformationTooltip content={description} />}
            </div>
            {sectionOpen && <FieldsPreview fields={fields} />}
        </div>
    );
};

export const FormPreview: React.FC<FormPreviewProps> = ({ datasetDefinition }) => {
    const [screenWidth, screenHeight] = useWindowResize();

    // Modal height (80%) subtract the combination of static fixed height divs to give a px value of remaining space
    const contentHeight = screenHeight * 0.8 - 110;

    const isShortFormDataset = datasetDefinition.datasetSections.length === 1 && !datasetDefinition.datasetSections[0].label;

    const dimensions = { height: `${contentHeight}px`, width: `${screenWidth * 0.5}px` };

    return (
        <div className={styles.formPreviewWrapper} data-testid='form-dataset-builder-preview-wrapper' style={dimensions}>
            <Scrollable>
                {isShortFormDataset ? (
                    <FieldsPreview fields={datasetDefinition.datasetFields[datasetDefinition.datasetSections[0].id]} />
                ) : (
                    datasetDefinition.datasetSections.map((section, index) => (
                        <SectionPreview section={section} key={section.id} datasetDefinition={datasetDefinition} sectionIndex={index} />
                    ))
                )}
            </Scrollable>
        </div>
    );
};
