import React, { useCallback, useMemo } from 'react';

import { DatasetFieldType } from '../datasets/store';
import { Checkbox, Number, Date, Text, Delete, Link, Dropdown, Form, Currencies } from '../shared/icons';
import { useAppDispatch } from '../../hooks/react-redux';
import { Icon } from '../shared/icon/Icon';
import { AgencyAnnexField, removeAnnexDatasetField, updateAnnexDatasetField } from './store';
import styles from './AgencyAnnex.module.scss';
import { InformationTooltip, OverflowTooltip } from '../shared/tooltip';

interface DraggableFieldProps {
    field: AgencyAnnexField;
    index: number;
}

const fieldListIcons = {
    [DatasetFieldType.TEXT]: Text,
    [DatasetFieldType.NUMBER]: Number,
    [DatasetFieldType.CHECKBOX]: Checkbox,
    [DatasetFieldType.DROPDOWN]: Dropdown,
    [DatasetFieldType.DATE]: Date,
    [DatasetFieldType.LINKFIELD]: Link,
    [DatasetFieldType.WIZARD]: Form,
    [DatasetFieldType.CURRENCY_AMOUNT]: Currencies
};

export const DraggableField: React.FC<DraggableFieldProps> = ({ field, index }) => {
    const { label, id, type, aliasLabel, description, refLabel, settings, isCustom } = field;
    const dispatch = useAppDispatch();
    const updateAliasLabel = (value: string) => dispatch(updateAnnexDatasetField('aliasLabel', value, index));
    const updateLabel = (value: string) => dispatch(updateAnnexDatasetField('label', value, index));

    const canDelete = useMemo(() => id !== 'annex-field-principal', [id]);
    const removeRow = useCallback(() => canDelete && dispatch(removeAnnexDatasetField(id)), [dispatch, canDelete, id]);

    const testId = `draggable-field-item-${index}`;

    return (
        <div className={styles.fieldWrapper}>
            {isCustom ? (
                <div className={styles.fieldContentWrapper}>
                    <div className={styles.fieldContent}>
                        <OverflowTooltip className={styles.fieldLabel} overlayText={label || 'Custom Field'} />
                        <InformationTooltip content='Note: This is a custom field which can only be a free text input. Where possible, please use the fields provided' />
                        <Icon icon={fieldListIcons[type]} fontSize={25} />
                    </div>
                    <input
                        data-testid={`${testId}-label`}
                        className={styles.label}
                        placeholder='Label...'
                        onChange={e => updateLabel(e.target.value)}
                        value={label}
                    />
                    <button className={styles.removeRowWrapper} onClick={removeRow} data-testid={`${testId}-delete-button`}>
                        <Icon icon={Delete} fontSize={20} />
                    </button>
                </div>
            ) : (
                <div className={styles.fieldContentWrapper}>
                    <div className={styles.fieldContent}>
                        <OverflowTooltip className={styles.fieldLabel} overlayText={label} />
                        {description && <InformationTooltip content={description} />}
                        {refLabel && settings.showRef && <InformationTooltip content={refLabel} label='?' />}
                        <Icon icon={fieldListIcons[type]} fontSize={25} />
                    </div>
                    <input
                        data-testid={`${testId}-alias-label`}
                        className={styles.aliasLabel}
                        placeholder='Alias label...'
                        onChange={e => updateAliasLabel(e.target.value)}
                        value={aliasLabel}
                    />
                    {canDelete &&
                        <button className={styles.removeRowWrapper} onClick={removeRow} data-testid={`${testId}-delete-button`}>
                            <Icon icon={Delete} fontSize={20} />
                        </button>
                    }
                </div>
            )}
        </div>
    );
};
