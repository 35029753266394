import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted } from '../../admin/dropdown-lists/store';
import { CustomFilters, FilterTable } from '../../auth/login/store';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { Text } from '../../shared/text/Text';
import { addNewCustomFilterValue, getCurrentUserCustomFilters, getNewCustomFilter } from '../store';

interface CustomFiltersAddNewFilterProps {
    isOpen: boolean;
    confirm: (value: CustomFilters) => void;
    closeModal: () => void;
}

export const CustomFiltersAddNewFilter: React.FC<CustomFiltersAddNewFilterProps> = ({ isOpen, confirm, closeModal }) => {
    const dispatch = useAppDispatch();

    useFetchStarted([fetchAllDropdownListsStarted()]);

    const newCustomFilter = useAppSelector(getNewCustomFilter);
    const currentCustomFilters = useAppSelector(getCurrentUserCustomFilters);
    const tableTypeDropdownOptions: DropdownOption[] = useMemo(() => [{ value: FilterTable.DOCUMENT, label: 'Document' }, { value: FilterTable.OPINION, label: 'Opinion' }], []);
    const filterName = !isNull(newCustomFilter) ? newCustomFilter.filterName : '';
    const filterTable = tableTypeDropdownOptions.filter(({ value }) => value === newCustomFilter?.filterTable);

    const updateUserValueByKey = useCallback((key: keyof CustomFilters, value: string | null) => dispatch(addNewCustomFilterValue(key, value)), [dispatch]);

    const updateTableType = useCallback((value: DropdownOption | Options<DropdownOption> | null) => {
        let filterTable = null;
        if (!isNull(value)) {
            filterTable = (value as DropdownOption).value;
        }
        updateUserValueByKey('filterTable', filterTable);
    }, [updateUserValueByKey]);

    const confirmAdd = useCallback(() => !isNull(newCustomFilter) && confirm(newCustomFilter), [confirm, newCustomFilter]);

    const allCustomFilterNamesValid = useMemo(() => {
        let otherCustomFilterNames: string[] = [];
        if (filterName) {
            if (filterTable.length > 0) {
                otherCustomFilterNames = currentCustomFilters.filter(object => object.filterTable === filterTable[0].value).map(({ filterName }) => filterName);
            }
            return !otherCustomFilterNames.includes(filterName);
        }
        return false;
    }, [currentCustomFilters, filterName, filterTable]);

    const confirmDisabled = useMemo(() => !filterName || filterTable.length === 0 || !allCustomFilterNamesValid, [filterName, filterTable, allCustomFilterNamesValid]);
    const confirmDisabledTooltip = useMemo(() => {
        if (!filterName || filterTable.length === 0) {
            return ['You must add a name and select a table type'];
        }
        if (!allCustomFilterNamesValid) {
            return ['Custom filter names must be unique'];
        }
    }, [filterName, filterTable, allCustomFilterNamesValid]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            confirm={confirmAdd}
            confirmLabel='Add'
            confirmDisabled={confirmDisabled}
            confirmDisabledTooltip={confirmDisabledTooltip}
            closeModal={closeModal}
        >
            <Text
                label='Filter Name'
                testId='custom-filters-filter-name'
                placeholder='Custom Filter Name...'
                value={filterName}
                maxLength={128}
                onChange={e => updateUserValueByKey('filterName', e.target.value)}
            />
            <Dropdown
                value={filterTable}
                options={tableTypeDropdownOptions}
                testId='custom-filters-filter-table'
                onChange={updateTableType}
                label='Filter Table Type'
                marginBottom='10px'
            />
        </ConfirmationModal>
    );
};
