import { isNull } from 'lodash';

import { DatasetType, DatasetFieldType } from '../../store';
import { DatasetInstance, FormDatasetInstance, AnnexInstance, InstanceField, GroupInstanceField, SingleInstanceField, AnnexInstanceField } from './types';

export const isFormDatasetInstance = (datasetInstance: DatasetInstance): datasetInstance is FormDatasetInstance => datasetInstance.datasetType === DatasetType.FORM;
export const isAnnexInstance = (datasetInstance: DatasetInstance): datasetInstance is AnnexInstance => isNull(datasetInstance.datasetId) && !isNull(datasetInstance.annexDefinitionId);
export const isDatasetLinkedField = ({ settings, type }: SingleInstanceField) => type === DatasetFieldType.DATASET && settings.datasetLinked;

export const isGroupInstanceField = (field: InstanceField): field is GroupInstanceField => [DatasetFieldType.GROUP, DatasetFieldType.MULTI_TOGGLE].includes(field.type);
export const isAnnexField = (field: SingleInstanceField | AnnexInstanceField, isAnnexInstance: boolean): field is AnnexInstanceField => isAnnexInstance;
