export enum DropdownListsActionTypes {
    TOGGLE_MODAL = 'TOGGLE_MODAL',
    TOGGLE_PREVIEW_MODAL = 'TOGGLE_PREVIEW_MODAL',
    UPDATE_DROPDOWN_LIST = 'UPDATE_DROPDOWN_LIST',
    ADD_LIST_ITEM = 'ADD_LIST_ITEM',
    UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM',
    REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM',
    PUBLISH_DROPDOWN_LIST_STARTED = 'PUBLISH_DROPDOWN_LIST_STARTED',
    PUBLISH_DROPDOWN_LIST_SUCCESSFUL = 'PUBLISH_DROPDOWN_LIST_SUCCESSFUL',
    PUBLISH_DROPDOWN_LIST_FAILED = 'PUBLISH_DROPDOWN_LIST_FAILED',
    FETCH_ALL_DROPDOWN_LISTS_STARTED = 'FETCH_ALL_DROPDOWN_LISTS_STARTED',
    FETCH_ALL_DROPDOWN_LISTS_SUCCESSFUL = 'FETCH_ALL_DROPDOWN_LISTS_SUCCESSFUL',
    FETCH_ALL_DROPDOWN_LISTS_FAILED = 'FETCH_ALL_DROPDOWN_LISTS_FAILED',
}

export interface DropdownListDB {
    dropdownListId: number;
    name: string;
    description: string;
    options: string[];
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
}

export interface DropdownList {
    dropdownListId?: number | null;
    name: string;
    description: string;
    options: string[];
}

export interface DropdownListState {
    modalOpen: boolean;
    previewModalOpen: boolean;
    dropdownList: DropdownList;
    publishError: string | null;
    publishedDropdownLists: DropdownListDB[];
    fetchError: string | null;
    isSaving: boolean;
}
