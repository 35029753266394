import React from 'react';
import Modal from 'react-modal';

import { useAppSelector } from '../../../hooks/react-redux';
import { Spinner } from '../../shared/spinner/Spinner';
import { getIsResettingPassword } from '../resetPassword/store';
import styles from './Login.module.scss';
import { getIsSwitchingAccounts } from './store';

export const SwitchAccountOverlay: React.FC = () => {
    const isSwitchingAccount = useAppSelector(getIsSwitchingAccounts);
    const resettingPassword = useAppSelector(getIsResettingPassword);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isSwitchingAccount && !resettingPassword}
            className={styles.logoutOverlay}
            style={{ overlay: { display: 'flex', zIndex: 20 } }}
            shouldCloseOnEsc={false}
        >
            <Spinner size={100} message='Switching Account...' />;
        </Modal>
    );
};
