import classnames from 'classnames';
import { isEqual } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';

import { Button } from '../../shared/button/Button';
import { DragDrop } from '../../shared/drag-n-drop/DragDrop';
import { DraggableItem } from '../../shared/drag-n-drop/shared';
import { Pencil } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { OverflowTooltip } from '../../shared/tooltip';
import styles from './Playbook.module.scss';
import { DraggableTile } from './store';

interface ReorderModalProps {
    isOpen: boolean;
    savedOrder: DraggableTile[];
    currentOrder: DraggableTile[];
    closeModal: () => void;
    saveOrder: () => void;
    setOrder: (order: DraggableTile[]) => void;
    title: string;
    testId: string;
}

export const ReorderModal: React.FC<ReorderModalProps> = ({ isOpen, currentOrder, savedOrder, closeModal, saveOrder, setOrder, title, testId }) => {
    const [orderUpdated, setOrderUpdated] = useState<boolean>(false);

    const save = useCallback(() => {
        saveOrder();
        setOrderUpdated(false);
    }, [saveOrder]);

    const checkOrderUpdated = useCallback((newOrder: DraggableTile[]) => {
        const hasUpdated = !isEqual(savedOrder, newOrder);
        setOrderUpdated(hasUpdated);
    }, [savedOrder]);

    const listOptions = useMemo(() => currentOrder.map(({ id, title }) => ({ id, label: title })), [currentOrder]);

    const updateList = (list: DraggableItem[]) => {
        const newOrder = list.map(({ id }) => currentOrder.find(tile => tile.id === id)!);
        setOrder(newOrder);
        checkOrderUpdated(newOrder);
    };

    const indexOfSubheader = currentOrder.map(({ isSubheader }) => !!isSubheader).indexOf(true);
    const instepTile = useCallback((index: number, isSubheader: boolean | undefined) => indexOfSubheader >= 0 && index > indexOfSubheader && !isSubheader, [indexOfSubheader]);

    const getChildElement = useCallback((childId: string, index: number) => {
        const tile = currentOrder.find(({ id }) => id === childId);
        if (tile) {
            return (
                <div className={classnames(styles.reorderTile, { [styles.instepTile]: instepTile(index, tile.isSubheader) })}>
                    <OverflowTooltip className={styles.reorderTileTitle} overlayText={tile.title} testId={`admin-playbook-reorder-${testId}-tile-title-${index}`} />
                </div>
            );
        }
        return null;
    }, [currentOrder, instepTile, testId]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.reorderModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 9 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.reorderWrapper} data-testid={`admin-playbook-reorder-${testId}-modal-wrapper`}>
                <ModalHeader label={`Reorder ${title}`} icon={Pencil} testId={`admin-playbook-reorder-${testId}-modal`} />
                <div className={styles.scrollableWrapper}>
                    <DragDrop getChildElement={getChildElement} list={listOptions} listId='playbook' updateList={updateList} />
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' testId={`admin-playbook-reorder-${testId}-close`} />
                    <Button onClick={save} disabled={!orderUpdated} label='Save' testId={`admin-playbook-reorder-${testId}-save`} />
                </div>
            </div>
        </Modal>
    );
};
