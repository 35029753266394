import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Tabs } from '../../shared/table/TableTabs';
import { DatasetBuilder } from './DatasetBuilder';
import { AgencyDatasets } from './agency-builder/AgencyDatasets';
import { DatasetView, editDatasetDefinition, fetchAllDatasetDefinitionsStarted, fetchAllDocumentDatasetsStarted, getAllDatasetDefinitions, getDatasetBuilderPathname, getDatasetDefinition, getDatasetView, setDatasetDocumentConfigUpdated, removeAllFieldSections, resetDocumentDatasetConfigure, setPreviewSelectedDocument, toggleDatasetView } from './store';
import { DatasetsList } from './configure-datasets/DatasetsList';
import { DatasetConfigure } from './configure-datasets/DatasetConfigure';
import { DatasetPreview } from './configure-datasets/DatasetPreview';

export interface DatasetBuilderRouteParams {
    datasetId: string | undefined;
}

export const Datasets: React.FC<RouteComponentProps<DatasetBuilderRouteParams>> = ({ match: { params, url } }) => {
    const dispatch = useAppDispatch();
    const datasetView = useAppSelector(getDatasetView);
    const datasetDefinition = useAppSelector(getDatasetDefinition);
    const publishedDefinitions = useAppSelector(getAllDatasetDefinitions);
    const isTablePageView = [DatasetView.TABLE_STANDARD, DatasetView.TABLE_AGENCY].includes(datasetView);

    useFetchStarted([fetchAllDatasetDefinitionsStarted(), fetchAllDocumentDatasetsStarted()]);
    const datasetId = useMemo(() => params.datasetId ? parseInt(params.datasetId) : undefined, [params]);

    const showAgencyDatasets = useMemo(() => datasetView === DatasetView.TABLE_AGENCY, [datasetView]);

    const datasetDefinitionFromUrl = useMemo(() => {
        if (isNull(datasetDefinition) && publishedDefinitions.length && !isUndefined(params.datasetId) && !showAgencyDatasets && isTablePageView) {
            const definition = publishedDefinitions.find(({ datasetId }) => datasetId === parseInt(params.datasetId!));
            return definition;
        }
    }, [datasetDefinition, publishedDefinitions, params.datasetId, showAgencyDatasets, isTablePageView]);

    const viewAgencyDatasets = useCallback(() => dispatch(toggleDatasetView(DatasetView.TABLE_AGENCY)), [dispatch]);
    const viewStandardDatasets = useCallback(() => dispatch(toggleDatasetView(DatasetView.TABLE_STANDARD)), [dispatch]);

    const tabs: Tabs[] = useMemo(() => [
        { tabToggleAction: viewStandardDatasets, tabTitle: 'Standard Datasets', isSelected: !showAgencyDatasets },
        { tabToggleAction: viewAgencyDatasets, tabTitle: 'Agency Datasets', isSelected: showAgencyDatasets }
    ], [viewStandardDatasets, viewAgencyDatasets, showAgencyDatasets]);

    const urlDatasetView = useAppSelector(getDatasetBuilderPathname);

    const pageContent = useMemo(() => {
        switch (datasetView) {
            case DatasetView.TABLE_STANDARD:
            default:
                return <DatasetBuilder tabs={tabs} />;
            case DatasetView.TABLE_AGENCY:
                return <AgencyDatasets tabs={tabs} />;
            case DatasetView.DOCUMENTS_LIST:
                return <DatasetsList />;
            case DatasetView.DOCUMENTS_CONFIGURE:
                return <DatasetConfigure datasetId={datasetId!} />;
            case DatasetView.DOCUMENTS_PREVIEW:
                return <DatasetPreview datasetId={datasetId!} />;
        }
    }, [datasetView, tabs, datasetId]);

    useEffect(() => {
        dispatch(toggleDatasetView(urlDatasetView, datasetId));
        if (datasetDefinitionFromUrl) {
            dispatch(editDatasetDefinition(datasetDefinitionFromUrl));
        }
    }, [url, datasetDefinitionFromUrl, urlDatasetView, datasetId, dispatch]);

    useEffect(() => () => {
        dispatch(removeAllFieldSections());
        dispatch(setDatasetDocumentConfigUpdated(false));
        dispatch(resetDocumentDatasetConfigure());
        dispatch(setPreviewSelectedDocument(null));
    }, [dispatch]);

    return (
        <>
            {pageContent}
        </>
    );
};
