import React, { useCallback, useMemo, useState } from 'react';
import { isEqual, isNull } from 'lodash/fp';

import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { getAvailablePlaybookProvisionLinks, getAvailableProvisionLinkModalOpen, getCurrentProvisionLinks, getProvisionToLink, LinkedPlaybookProvision, setProvisionToLink, savePlaybookLinkedProvisions, updateCurrentLinkedProvision, getPlaybook, PlaybookProvision } from './store';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Link, Delete } from '../../shared/icons';
import styles from '../../shared/playbook/provision-link/ProvisionLink.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { OverflowTooltip } from '../../shared/tooltip';
import { IconButton } from '../../shared/button/IconButton';

const { french, included, frenchLight, playbookSelected } = styles;

export interface SelectedPlaybook {
    playbookId: number;
    playbookName: string;
}

export const AvailableProvisionLinksModal: React.FC = () => {

    const [selectedPlaybook, setSelectedPlaybook] = useState<SelectedPlaybook | null>(null);

    const dispatch = useAppDispatch();
    const playbook = useAppSelector(getPlaybook);
    const provisionToLink = useAppSelector(getProvisionToLink);
    const isOpen = useAppSelector(getAvailableProvisionLinkModalOpen);
    const availableLinks = useAppSelector(getAvailablePlaybookProvisionLinks);
    const currentProvisionLinks = useAppSelector(getCurrentProvisionLinks);

    const saveDisabled = useMemo(() => {
        if (!isNull(provisionToLink)) {
            const savedLinks = (playbook.content.sections.find(({ sectionId }) => sectionId === provisionToLink) as PlaybookProvision).linkedProvisions;
            const linksUpdated = isEqual(savedLinks, currentProvisionLinks);
            return linksUpdated;
        }
        return false;
    }, [provisionToLink, playbook, currentProvisionLinks]);

    const close = useCallback(() => {
        dispatch(setProvisionToLink(null));
        setSelectedPlaybook(null);
    }, [dispatch]);

    const save = useCallback(() => {
        dispatch(savePlaybookLinkedProvisions());
        close();
    }, [dispatch, close]);

    const availableProvisions = useMemo(() => {
        if (!isNull(selectedPlaybook)) {
            let availableSections = availableLinks.find(({ playbookId }) => playbookId === selectedPlaybook.playbookId)!.sections;
            if (selectedPlaybook.playbookId === playbook.playbookId) {
                availableSections = availableSections.filter(({ sectionId }) => sectionId !== provisionToLink);
            }
            return availableSections;
        }
        return [];
    }, [selectedPlaybook, availableLinks, provisionToLink, playbook]);

    const selectPlaybook = useCallback((playbookId: number, playbookName: string) => playbookId === selectedPlaybook?.playbookId ? setSelectedPlaybook(null) : setSelectedPlaybook({ playbookId, playbookName }), [selectedPlaybook]);

    const updateLinkedProvisions = useCallback((sectionId: string, provisionTitle: string) => {
        const linkedProvision: LinkedPlaybookProvision = { playbookId: selectedPlaybook!.playbookId!, playbookName: selectedPlaybook!.playbookName, sectionId, provisionTitle };
        dispatch(updateCurrentLinkedProvision(linkedProvision));
    }, [dispatch, selectedPlaybook]);

    const scrollToPlaybook = useCallback((playbookId: number) => {
        const playbook = document.getElementById(`${playbookId}`);
        playbook?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, []);

    const scrollToSection = useCallback((sectionId: string) => {
        const section = document.getElementById(sectionId);
        section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, []);

    const selectCurrentLink = useCallback((playbookId: number, playbookName: string, sectionId: string) => {
        if (playbookId !== selectedPlaybook?.playbookId) {
            setSelectedPlaybook({ playbookId, playbookName });
        }
        scrollToPlaybook(playbookId);
        setTimeout(scrollToSection, 100, sectionId);
    }, [scrollToPlaybook, scrollToSection, selectedPlaybook]);

    const removeLinkedProvision = useCallback((provisionLink: LinkedPlaybookProvision) => dispatch(updateCurrentLinkedProvision(provisionLink)), [dispatch]);

    const playbookBackgroundColor = useCallback((playbookId: number) => {
        if (playbookId === selectedPlaybook?.playbookId) {
            return playbookSelected;
        }
        if (!isNull(selectedPlaybook) && playbookId !== selectedPlaybook.playbookId) {
            return frenchLight;
        }
        return french;
    }, [selectedPlaybook]);

    const provisionBackgroundColour = useCallback((sectionId: string) => currentProvisionLinks.map(({ sectionId }) => sectionId).includes(sectionId) ? included : french, [currentProvisionLinks]);

    const getTotalProvisionLinksForPlaybook = useCallback((id: number) => currentProvisionLinks.filter(({ playbookId }) => playbookId === id).length, [currentProvisionLinks]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={close}
            confirm={save}
            confirmLabel='Save Links'
            confirmDisabled={saveDisabled}
            closeLabel='Close'
        >
            <div className={styles.provisionLinkModal}>
                <div className={styles.provisionLinkWrapper}>
                    <ModalHeader icon={Link} label='Provision Links' />
                    <div className={styles.scrollableWrapper}>
                        <Scrollable>
                            <div className={styles.availablePlaybooksWrapper}>
                                {availableLinks.map(({ playbookName, playbookId }) => {
                                    const totalLinks = getTotalProvisionLinksForPlaybook(playbookId);
                                    return (
                                        <div key={playbookId} id={playbookId.toString()} className={styles.playbookNameWrapper} style={{ backgroundColor: playbookBackgroundColor(playbookId) }} onClick={() => selectPlaybook(playbookId, playbookName)}>
                                            <div className={styles.availablePlaybookName}>
                                                <OverflowTooltip overlayText={playbookName} testId={`playbook-link-provisions-playbook-name-${playbookId}`} />
                                            </div>
                                            {totalLinks !== 0 && <div className={styles.totalLinkedProvisions}>{totalLinks}</div>}
                                        </div>
                                    );
                                })
                                }
                            </div>
                        </Scrollable>
                        <Scrollable>
                            <div className={styles.availableProvisionsWrapper}>
                                {availableProvisions && availableProvisions.map(({ sectionId, provisionTitle }) => {
                                    const title = provisionTitle !== '' ? provisionTitle : 'No Title Found';
                                    return (
                                        <div key={sectionId} id={sectionId} className={styles.provisionTitleWrapper} style={{ backgroundColor: provisionBackgroundColour(sectionId) }} onClick={() => updateLinkedProvisions(sectionId, title)}>
                                            <div className={styles.availableProvisionName}>
                                                <OverflowTooltip overlayText={title} testId={`playbook-link-provisions-provision-title-${sectionId}`} />
                                            </div>
                                        </div>
                                    );
                                })
                                }
                            </div>
                        </Scrollable>
                    </div>
                    <div className={styles.currentLinkedProvisionsScrollableWrapper}>
                        <Scrollable>
                            <div className={styles.currentLinkedProvisionsWrapper}>
                                {currentProvisionLinks.length > 0 ? currentProvisionLinks.map(currentLink => {
                                    const { sectionId, playbookName, provisionTitle, playbookId } = currentLink;
                                    const title = provisionTitle !== '' ? provisionTitle : 'No Title Found';
                                    return (
                                        <div key={sectionId} className={styles.linkedProvisionWrapper}>
                                            <div className={styles.linkedProvisionLabelWrapper} onClick={() => selectCurrentLink(playbookId, playbookName, sectionId)}>
                                                <OverflowTooltip overlayText={`Playbook: ${playbookName}`} testId={`playbook-link-provisions-playbook-title-${sectionId}`} className={styles.linkedProvisionLabel} />
                                                <OverflowTooltip overlayText={`Provision: ${title}`} testId={`playbook-link-provisions-provision-title-${sectionId}`} className={styles.linkedProvisionLabel} />
                                            </div>
                                            <div className={styles.removeProvisionLinkIconWrapper}>
                                                <IconButton onClick={() => removeLinkedProvision(currentLink)} disabled={false} testId='playbook-link-provision-modal-remove-provision-link' icon={Delete} fontSize={20} />
                                            </div>
                                        </div>
                                    );
                                }) :
                                    <div className={styles.linkedProvisionWrapper}>
                                        <div className={styles.linkedProvisionLabelWrapper}>
                                            <div className={styles.linkedProvisionLabel}>
                                                No current links
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Scrollable>
                    </div>
                </div>
            </div>
        </ConfirmationModal>
    );
};
