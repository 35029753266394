import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { PageToggleButton } from '../../../shared/button/PageToggleButton';
import { PlusButton } from '../../../shared/button/PlusButton';
import { AttestationManager } from '../../../shared/icons';
import { AttestationFormDB, AttestationPage, fetchAllAttestationFormsStarted, getAllAttestationForms, getAttestationConfirmationModalOpen, getSelectedAttestationForm, setAttestationPage, stripAttestationForm, toggleAttestationFormBuilderWizardOpen } from '../store';
import { AttestationFormConfirmationModal } from './AttestationFormConfirmationModal';
import styles from './AttestationForms.module.scss';
import { AttestationFormsTable } from './AttestationFormsTable';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { FormBuilderWizard } from './FormBuilderWizard';

export interface AttestationFormRouteParams {
    attestationFormId: string | undefined;
}

export const AttestationForms: React.FC<RouteComponentProps<AttestationFormRouteParams>> = ({ match: { params } }) => {
    const testId = 'admin-attestation-forms';
    const dispatch = useAppDispatch();
    const goToManager = () => dispatch(setAttestationPage(AttestationPage.MANAGER));
    const openWizard = () => dispatch(toggleAttestationFormBuilderWizardOpen(true));
    const attestationForm = useAppSelector(getSelectedAttestationForm);
    const confirmationModal = useAppSelector(getAttestationConfirmationModalOpen);

    useFetchStarted([fetchAllAttestationFormsStarted()]);
    const attestationForms = useAppSelector(getAllAttestationForms);
    const openAttestationForm = useCallback((attestationForm: AttestationFormDB) => dispatch(toggleAttestationFormBuilderWizardOpen(true, stripAttestationForm(attestationForm))), [dispatch]);

    const attestationFormInParamsExists = useMemo(() => (params.attestationFormId && attestationForms.map(({ attestationFormId }) => attestationFormId.toString()).includes(params.attestationFormId)) || false, [attestationForms, params]);
    useEffect(() => {
        if (!attestationForm && params.attestationFormId && attestationFormInParamsExists) {
            const attestationFormFromParams = attestationForms.find(({ attestationFormId }) => attestationFormId.toString() === params.attestationFormId)!;
            openAttestationForm(attestationFormFromParams);
        }
    }, [params, attestationForm, attestationFormInParamsExists, attestationForms, openAttestationForm]);

    return (
        <div className={styles.formsWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.formsHeaderWrapper}>
                <div className={styles.formsTitleButtonWrapper}>
                    <div className={styles.formsHeaderTitle} data-testid={`${testId}-title`}>Attestation Forms</div>
                    <PlusButton onClick={openWizard} testId={testId} fontSize={22} />
                </div>
                <PageToggleButton label='Manager' onClick={goToManager} icon={AttestationManager} testId={testId} />
            </div>
            <AttestationFormsTable attestationForms={attestationForms} />
            {attestationForm && <FormBuilderWizard attestationForm={attestationForm} />}
            {confirmationModal && <AttestationFormConfirmationModal modalOpen={confirmationModal} />}
            <DeleteConfirmationModal />
        </div>
    );
};
