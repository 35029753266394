import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';

import { PlusButton } from '../button/PlusButton';
import { OverflowTooltip } from '../tooltip/OverflowTooltip';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';
import { DeleteButton } from '../button/DeleteButton';
import { Icon } from '../icon/Icon';
import { Attestation } from '../icons';
import { SchedulerInterval } from '../../admin/workflow/store/types';
import { useAppSelector } from '../../../hooks/react-redux';
import { getAllUsers } from '../../admin/users/store';
import styles from './CreateAttestationInstancesTable.module.scss';
import { Scrollable } from '../scrollable/Scrollable';

const { white, lightGrey } = styles;

interface InstanceUsers {
    userAnswerId: string;
    label: string;
    userId: number | null;
    username?: string;
}

interface InstancesToCreate {
    deadlineInterval?: SchedulerInterval | null;
    deadlineIntervalValue?: number | null;
    deadline?: string | null;
    userIds: InstanceUsers[];
}

interface CreateAttestationInstancesTableProps {
    testId?: string;
    addAttestationInstance: () => void;
    updateUserAnswerId: (index: number, userAnswerId: string, dropdownValue: DropdownOption | Options<DropdownOption> | null) => void;
    removeAttestationInstance: (index: number) => void;
    instancesToCreate: InstancesToCreate[];
    isEditing: boolean;
    deadlineCell: (index: number) => JSX.Element;
    deadlineWidth?: string;
}

export const CreateAttestationInstancesTable: React.FC<CreateAttestationInstancesTableProps> = ({
    testId,
    addAttestationInstance,
    updateUserAnswerId,
    removeAttestationInstance,
    instancesToCreate,
    isEditing,
    deadlineCell,
    deadlineWidth = '300px'
}) => {
    const allUsers = useAppSelector(getAllUsers);
    const usernameOptions = useMemo(() => allUsers.map(({ userId, username }) => ({ value: userId.toString(), label: username })), [allUsers]);
    const getUsernameValue = useCallback((userId: number | null) => !isNull(userId) && usernameOptions.find(({ value }) => value === userId.toString()) || null, [usernameOptions]);

    return (
        <div className={styles.attestationInstancesWrapper} data-testid={`${testId}-attestation-instances-wrapper`}>
            <div className={styles.attestationInstancesTitleWrapper}>
                <div className={styles.attestationInstancesTitle} data-testid={`${testId}-attestation-instances-to-create-label`}>Attestations to Create</div>
                <PlusButton onClick={addAttestationInstance} fontSize={16} testId={`${testId}-attestation-instances`} />
            </div>
            <div className={styles.attestationInstancesTable} data-testid={`${testId}-attestation-instances-table`}>
                <Scrollable>
                    <div className={styles.attestationInstancesHeader}>
                        <div className={styles.headerAttestation}><Icon icon={Attestation} /></div>
                        {instancesToCreate[0].userIds.map(({ label, userAnswerId }, index) => (
                            <div className={styles.headerUserAnswerWrapper} key={userAnswerId}>
                                <OverflowTooltip className={styles.headerUserAnswerLabel} key={userAnswerId} overlayText={label} testId={`${testId}-attestation-instances-table-header-${index}`} />
                            </div>
                        ))}
                        <div className={styles.headerDeadline} style={{ width: deadlineWidth }} data-testid={`${testId}-attestation-instances-table-deadline-header`}>Deadline (optional)</div>
                        <div className={styles.headerRemoveRow} />
                    </div>
                    <div className={styles.attestationInstancesBody} data-testid={`${testId}-attestation-instances-table-body`}>
                        <Scrollable>
                            {instancesToCreate.map((instance, index) => (
                                <div className={styles.attestationInstanceRow} key={index} style={{ backgroundColor: index % 2 === 0 ? white : lightGrey }} data-testid={`${testId}-attestation-instances-table-row-${index}`}>
                                    <div className={styles.rowUserIdentifier}>{index + 1}</div>
                                    {instance.userIds.map(({ userAnswerId, userId }) => (
                                        <div key={`${userAnswerId}-${index}`} className={styles.rowUserAnswerDropdown} data-testid={`${testId}-attestation-instances-table-row-${index}-${userAnswerId}-dropdown-wrapper`}>
                                            <Dropdown
                                                value={getUsernameValue(userId)}
                                                options={usernameOptions}
                                                onChange={val => updateUserAnswerId(index, userAnswerId, val)}
                                                menuPortalTarget={document.body}
                                                disabled={!isEditing}
                                                testId={`${testId}-attestation-instances-table-row-${index}-${userAnswerId}`}
                                            />
                                        </div>
                                    ))}
                                    {deadlineCell(index)}
                                    <div className={styles.rowDeleteAttestation}>
                                        {index !== 0 && <DeleteButton onClick={() => removeAttestationInstance(index)} fontSize={20} disabled={!isEditing} />}
                                    </div>
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </Scrollable>
            </div>
        </div>
    );
};

