import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { QuestionMark, Document } from '../../shared/icons';
import { TileSelect, Tile } from '../../shared/tile-select/TileSelect';
import { NettingPage, setNettingEnginePage } from './store';

export const OpinionNettingEngineSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectNettingPage = useCallback((type: NettingPage) => dispatch(setNettingEnginePage(type)), [dispatch]);

    const nettingEngineTiles: Tile[] = useMemo(() => [
        {
            label: 'Existing Documents',
            description: [
                'Consider the netting implications of existing relevant trades by combining opinion and contract data'
            ],
            onClick: () => selectNettingPage(NettingPage.DOCUMENTS),
            testId: 'existing-documents',
            icon: Document
        },
        {
            label: 'Theoretical',
            description: [
                'Check the suitability of future agreements'
            ],
            onClick: () => selectNettingPage(NettingPage.THEORETICAL),
            testId: 'theoretical',
            icon: QuestionMark
        },
    ], [selectNettingPage]);

    return (
        <TileSelect testId='netting-engine' tiles={nettingEngineTiles} />
    );
};
