import classnames from 'classnames';
import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { DATABASE_DATE_FORMAT, endOfDay, formatDate, isBefore } from '../../../../../utils/luxon';
import { DatePicker } from '../../../../shared/datepicker/DatePicker';
import { Dropdown, DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { AttestationManager, AttestationStatus, updateAttestationDeadline, updateAttestationUserStatus } from '../../store';
import styles from '../AttestationManager.module.scss';

interface ConfigureProps {
    attestation: AttestationManager;
    testId?: string;
}

export const Configure: React.FC<ConfigureProps> = ({ attestation, testId }) => {
    const { attestationInstance, attestationStatus } = attestation;
    const isComplete = !isNull(attestationInstance.completedBy);

    const dispatch = useAppDispatch();

    const updateDeadline = (value: Date | null) => {
        const deadline = value ? formatDate(value, DATABASE_DATE_FORMAT) : null;
        dispatch(updateAttestationDeadline(deadline));
    };

    const updateUserStatus = (index: number, dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(dropdownValue)) {
            const value = (dropdownValue as DropdownOption).value as AttestationStatus;
            dispatch(updateAttestationUserStatus(index, value));
        }
    };

    const deadline = !isNull(attestationInstance.deadline) && new Date(attestationInstance.deadline) || null;

    const statusDropdownOptions = useMemo(() => [
        { value: AttestationStatus.AWAITING_USER_RESPONSE, label: 'Awaiting User Response' },
        { value: AttestationStatus.DRAFT, label: 'Submitted as Draft' },
        { value: AttestationStatus.USER_COMPLETED, label: 'Submitted as Completed by User' },
        { value: AttestationStatus.COMPLETED, label: 'Accept as Completed' }
    ], []);

    const allStatusOptions = useMemo(() => [...statusDropdownOptions,
        { value: AttestationStatus.DEADLINE_PASSED, label: 'Deadline Passed' },
        { value: AttestationStatus.DELETED, label: 'Attestation Instance Deleted' }
    ], [statusDropdownOptions]);

    const getStatusValue = useCallback((status: AttestationStatus) => allStatusOptions.find(({ value }) => value === status) || null, [allStatusOptions]);
    const statusDropdownDisabled = useCallback((status: AttestationStatus) => !statusDropdownOptions.map(({ value }) => value).includes(status), [statusDropdownOptions]);

    const deadlinePassed = !isNull(attestationInstance.deadline) && isBefore(endOfDay(attestationInstance.deadline));
    const attestationDeleted = attestationStatus.map(({ status }) => status).includes(AttestationStatus.DELETED);
    const deadlineDisabled = deadlinePassed || attestationDeleted || isComplete;

    return (
        <div className={styles.configureWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.deadlineSelect} data-testid={`${testId}-deadline-wrapper`}>
                <div className={styles.deadlineSelectLabel} data-testid={`${testId}-deadline-label`}>Deadline (optional)</div>
                <DatePicker
                    value={deadline}
                    onChange={updateDeadline}
                    testId={`${testId}-deadline`}
                    minDate={new Date()}
                    disabled={deadlineDisabled}
                />
            </div>
            <div className={styles.attestationUserStatus} data-testid={`${testId}-user-status`}>
                <div className={styles.attestationUserStatusLabel} data-testid={`${testId}-user-status-title`}>Attestation Status</div>
                <div className={styles.userStatusWrapper}>
                    <div className={styles.userStatusHeader}>
                        <div className={styles.usernameLabel} data-testid={`${testId}-username-label`}>Username</div>
                        <div className={styles.userStatusLabel} data-testid={`${testId}-user-status-label`}>Status</div>
                    </div>
                    <div className={styles.userStatusTable}>
                        <Scrollable>
                            {attestationStatus.map(({ username, status, userId }, index) => (
                                <div className={classnames(styles.userStatusRow, { [styles.rowBorder]: index !== attestationStatus.length - 1 })} key={userId}>
                                    <div className={styles.username} data-testid={`${testId}-username-row-${index}`}>{username}</div>
                                    <div className={styles.userStatusDropdown} data-testid={`${testId}-user-status-row-${index}`}>
                                        <Dropdown
                                            value={getStatusValue(status)}
                                            options={statusDropdownOptions}
                                            onChange={val => updateUserStatus(index, val)}
                                            menuPortalTarget={document.body}
                                            testId={`${testId}-user-status-row-${index}`}
                                            disabled={statusDropdownDisabled(status) || isComplete}
                                            isClearable={false}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </div>
            </div>
        </div>
    );
};
