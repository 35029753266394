import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { Dropdown, DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { Settings } from '../../../../shared/icons';
import { LongText } from '../../../../shared/longtext/LongText';
import { ConfirmationModal } from '../../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../../shared/modal/ModalHeader';
import { Toggle } from '../../../../shared/toggle';
import { InformationTooltip, OverflowTooltip } from '../../../../shared/tooltip';
import styles from '../AttestationForms.module.scss';
import { AttestationAnswers, AttestationQuestionConfig, FormUserAnswer, updateAttestationQuestionConfigValue } from '../../store';
import { CustomAnswers } from './CustomAnswers';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';

interface AttestationConfigModalProps {
    isOpen: boolean;
    closeModal: () => void;
    attestationIndex: number;
    config: AttestationQuestionConfig;
    question: string;
    userAnswers: FormUserAnswer[];
    testId?: string;
}

interface RedFlagAnswerOptions {
    [AttestationAnswers.YES_NO]: string[];
    [AttestationAnswers.RAG]: string[];
}

const redFlagAnswerOptions: RedFlagAnswerOptions = {
    [AttestationAnswers.YES_NO]: ['Yes', 'No'],
    [AttestationAnswers.RAG]: ['Red', 'Amber', 'Green']
};

export const AttestationConfigModal: React.FC<AttestationConfigModalProps> = ({ isOpen, closeModal, config, attestationIndex, question, userAnswers, testId }) => {
    const dispatch = useAppDispatch();
    const { answers, customAnswers, redFlagAnswer, isHidden, isRequired, alertMessage, forceRedFlagExplanation, userAnswerId } = config;

    const renderQuestion = useMemo(() => question ? (<OverflowTooltip className={styles.configModalToggleLabel} overlayText={question} testId={`${testId}-question`} />) : (<div className={styles.questionPlaceholder}>Question...</div>), [question, testId]);

    const customAnswersSelected = answers === AttestationAnswers.CUSTOM;
    const freeTextAnswersSelected = answers === AttestationAnswers.FREE_TEXT;

    const updateDropdownValue = (key: keyof AttestationQuestionConfig, dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = (dropdownValue as DropdownOption).value;
        }
        dispatch(updateAttestationQuestionConfigValue(attestationIndex, key, value));
    };

    const answersDropdownOptions = Object.values(AttestationAnswers).map(value => ({ label: value, value }));
    const selectedAnswers = { value: answers, label: answers };

    const setSelectedAnswers = (value: DropdownOption | Options<DropdownOption> | null) => {
        let customAnswers = undefined;
        if ((value as DropdownOption).value === AttestationAnswers.CUSTOM) {
            customAnswers = [''];
        }
        updateDropdownValue('answers', value);
        dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'customAnswers', customAnswers));
        dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'redFlagAnswer', null));
        dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'forceRedFlagExplanation', false));
        dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'alertMessage', ''));
    };

    const redFlagAnswerDropdownOptions = useMemo(() => {
        if (freeTextAnswersSelected) {
            return [];
        }
        if (customAnswersSelected) {
            return customAnswers!.filter(answer => answer).map(value => ({ value, label: value }));
        }
        return redFlagAnswerOptions[answers].map(value => ({ value, label: value }));
    }, [answers, customAnswers, customAnswersSelected, freeTextAnswersSelected]);
    const selectedRedFlagAnswer = redFlagAnswer ? { value: redFlagAnswer, label: redFlagAnswer } : null;

    const setRedFlagAnswer = (value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('redFlagAnswer', value);

    const selectedUserAnswerDropdownOptions = useMemo(() => userAnswers.map(({ userAnswerId, label }) => ({ value: userAnswerId, label })), [userAnswers]);
    const selectedUserAnswerId = useMemo(() => selectedUserAnswerDropdownOptions.find(({ value }) => value === userAnswerId) || null, [selectedUserAnswerDropdownOptions, userAnswerId]);

    const setUserAnswerId = (value: DropdownOption | Options<DropdownOption> | null) => dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'userAnswerId', (value as DropdownOption).value));

    const toggleIsRequired = (value: boolean) => dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'isRequired', value));
    const toggleIsHidden = (value: boolean) => dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'isHidden', value));
    const toggleForceRedFlagAnswer = (value: boolean) => dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'forceRedFlagExplanation', value));
    const setAlertMessage = (value: string) => dispatch(updateAttestationQuestionConfigValue(attestationIndex, 'alertMessage', value));

    const requiredTooltip = 'Require question to be answered for attestation to be submitted';
    const hideTooltip = 'Hide question from other users';
    const redFlagTooltip = 'Answer is a red flag and will require further investigation';
    const forceRedFlagTooltip = 'If a user selects an answer which you have identified as a red flag answer, require user to provide feedback and justify this answer';
    const alertTooltip = 'If a user selects a red flag answer, instantly show an alert to the user';
    const userAnswerTooltip = 'Select which user will be responsible for answering this question';

    return (
        <ConfirmationModal
            closeModal={closeModal}
            isOpen={isOpen}
            showConfirm={false}
            closeLabel='Update and Close'
            showOverlay={false}
            testId='create-attestation-form-wizard-attestation-tab'
        >
            <ModalHeader icon={Settings} label='Configure Attestation' testId={`${testId}`} />
            <div className={styles.configModalWrapper} data-testid={`${testId}-wrapper`}>
                <Scrollable>
                    <div className={styles.configQuestionWrapper} data-testid={`${testId}-question`}>
                        {renderQuestion}
                    </div>
                    <div className={styles.configModalDropdownWrapper}>
                        <div className={styles.configModalToggleLabel} data-testid={`${testId}-answers-label`}>Answers</div>
                        <Dropdown
                            isClearable={false}
                            onChange={setSelectedAnswers}
                            value={selectedAnswers}
                            options={answersDropdownOptions}
                            testId={`${testId}-answer`}
                            menuPortalTarget={document.body}
                        />
                    </div>
                    {customAnswersSelected && <CustomAnswers customAnswers={customAnswers!} attestationIndex={attestationIndex} />}
                    <div className={styles.configModalToggleWrapper}>
                        <div className={styles.configModalLabelWrapper}>
                            <div className={styles.configModalToggleLabel} data-testid={`${testId}-required-label`}>Required</div>
                            <InformationTooltip content={requiredTooltip} />
                        </div>
                        <Toggle checked={isRequired} onChange={toggleIsRequired} testId={`${testId}-required`} />
                    </div>
                    <div className={styles.configModalToggleWrapper}>
                        <div className={styles.configModalLabelWrapper}>
                            <div className={styles.configModalToggleLabel} data-testid={`${testId}-hide-label`}>Hide</div>
                            <InformationTooltip content={hideTooltip} />
                        </div>
                        <Toggle
                            checked={isHidden}
                            onChange={toggleIsHidden}
                            testId={`${testId}-hide`}
                        />
                    </div>
                    {!freeTextAnswersSelected &&
                        <div className={styles.configModalDropdownWrapper}>
                            <div className={styles.configModalLabelWrapper}>
                                <div className={styles.configModalToggleLabel} data-testid={`${testId}-red-flag-label`}>Red Flag Answer</div>
                                <InformationTooltip content={redFlagTooltip} />
                            </div>
                            <Dropdown
                                onChange={setRedFlagAnswer}
                                value={selectedRedFlagAnswer}
                                options={redFlagAnswerDropdownOptions}
                                testId={`${testId}-red-flag-label`}
                            />
                        </div>
                    }
                    {!freeTextAnswersSelected &&
                        <div className={styles.configModalToggleWrapper}>
                            <div className={styles.configModalLabelWrapper}>
                                <div className={styles.configModalToggleLabel} data-testid={`${testId}-force-red-flag-label`}>Force Red Flag Explanation</div>
                                <InformationTooltip content={forceRedFlagTooltip} />
                            </div>
                            <Toggle
                                checked={forceRedFlagExplanation}
                                onChange={toggleForceRedFlagAnswer}
                                testId={`${testId}-force-red-flag`}
                                disabled={isNull(selectedRedFlagAnswer)}
                            />
                        </div>
                    }
                    {!freeTextAnswersSelected &&
                        <div>
                            <div className={styles.configModalLabelWrapper}>
                                <div className={styles.configModalToggleLabel} data-testid={`${testId}-alert-message-label`}>Alert Message</div>
                                <InformationTooltip content={alertTooltip} />
                            </div>
                            <LongText
                                onChange={setAlertMessage}
                                value={alertMessage}
                                placeholder='Set your alert message'
                                testId={`${testId}-alert-message`}
                                disabled={isNull(selectedRedFlagAnswer)}
                            />
                        </div>
                    }
                    <div className={styles.configModalDropdownWrapper}>
                        <div className={styles.configModalLabelWrapper}>
                            <div className={styles.configModalToggleLabel} data-testid={`${testId}-user-answer-label`}>User Answer</div>
                            <InformationTooltip content={userAnswerTooltip} />
                        </div>
                        <Dropdown
                            onChange={setUserAnswerId}
                            value={selectedUserAnswerId}
                            options={selectedUserAnswerDropdownOptions}
                            isClearable={false}
                            menuPortalTarget={document.body}
                            testId={`${testId}-user-answer`}
                        />
                    </div>
                </Scrollable>
            </div>
        </ConfirmationModal>
    );
};
