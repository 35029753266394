import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { isNull } from 'lodash/fp';

import { CustomFilters, FilterTable, DocumentCustomFilters, OpinionCustomFilters } from '../../auth/login/store';
import { Icon } from '../../shared/icon/Icon';
import { Delete } from '../../shared/icons';
import { Spinner } from '../../shared/spinner/Spinner';
import { OverflowTooltip } from '../../shared/tooltip';
import styles from '../Home.module.scss';
import { Text } from '../../shared/text/Text';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { getCurrentUserCustomFilters, getSelectedCustomFilter, setSelectedCustomFilter, updateCustomFilterValue } from '../store';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { CustomFiltersBody } from './CustomFiltersBody';

interface CustomFiltersTableProps {
    onChangeFilterValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
    filterValue: string;
    filterTable: FilterTable;
    onDelete: (filterId: string) => void;
    filteredCustomFilters: CustomFilters[];
    isSaving: boolean;
}

export const CustomFiltersTable: React.FC<CustomFiltersTableProps> = ({ onChangeFilterValue, filterValue, filterTable, filteredCustomFilters, onDelete, isSaving }) => {
    const isDocument = filterTable === FilterTable.DOCUMENT;
    const dispatch = useAppDispatch();
    const selectedFilter = useAppSelector(getSelectedCustomFilter);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const setSelectedFilter = useCallback((value: CustomFilters) => {
        setIsLoading(true);
        dispatch(setSelectedCustomFilter(value));
        setTimeout(() => setIsLoading(false), 150);
    }, [dispatch, setIsLoading]);

    const defaultMessage = useMemo(() => `You have no ${isDocument ? 'document' : 'opinion'} custom filters. Click 'Add New Filter' to add some`, [isDocument]);

    const currentCustomFilters = useAppSelector(getCurrentUserCustomFilters);

    const selectedTile = useMemo(() => !isNull(selectedFilter) && currentCustomFilters.find(filter => filter.id === selectedFilter.id) || null, [currentCustomFilters, selectedFilter]);

    const updateFilter = useCallback((key: keyof DocumentCustomFilters | keyof OpinionCustomFilters, value: string | string[] | null, type: keyof TableFilterType = 'text') => !isNull(selectedFilter) && dispatch(updateCustomFilterValue(key, value, selectedFilter.id, type)), [dispatch, selectedFilter]);

    const filterList = useMemo(() => {
        if (isSaving) {
            return <Spinner />;
        }
        if (filteredCustomFilters.length === 0) {
            return <div className={styles.defaultContentMessage}>{defaultMessage}</div>;
        } else {
            return (
                <div className={styles.customFiltersWrapper}>
                    <div className={styles.customFilters} style={{ width: !selectedTile ? '100%' : '30%' }}>
                        <Scrollable>
                            {filteredCustomFilters.map((filter, index) => (
                                <div className={classnames(styles.filterTile, { [styles.selectedFilterTile]: filter.id === selectedTile?.id })} key={index} onClick={() => setSelectedFilter(filter)}>
                                    <OverflowTooltip className={styles.customFiltersTileHeader} overlayText={filter.filterName} />
                                    <div className={styles.deleteIcon} onClick={() => onDelete(filter.id)}><Icon icon={Delete} fontSize={20} /></div>
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                    {selectedTile &&
                        <div className={styles.selectedFilter} style={{ width: !selectedTile ? '0%' : 'calc(70% - 20px)' }}>
                            {isLoading ? <Spinner /> :
                                <Scrollable>
                                    <div className={styles.filterHeaderLabel}>Filter Name</div>
                                    <Text
                                        label=''
                                        testId='custom-filters-filter-name'
                                        placeholder='Custom Filter Name...'
                                        value={selectedTile.filterName}
                                        maxLength={128}
                                        onChange={e => updateFilter('filterName', e.target.value)}
                                    />
                                    <CustomFiltersBody updateFilter={updateFilter} selectedTile={selectedTile} />
                                </Scrollable>
                            }
                        </div>
                    }
                </div>
            );
        }
    }, [filteredCustomFilters, onDelete, isLoading, defaultMessage, isSaving, selectedTile, updateFilter, setSelectedFilter]);

    return (
        <div className={styles.tableWrapper}>
            <div className={styles.tableHeader}>
                <input placeholder='Search Filters...' className={styles.tableFilterInput} onChange={onChangeFilterValue} onClick={e => e.stopPropagation()} value={filterValue} autoFocus />
            </div>
            {filterList}
        </div>
    );
};
