import React from 'react';

export const Globe: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 256 256">
        <path fill="none" d="M0 0h256v256H0z"></path>
        <circle cx="128" cy="128" fill="none" r="96" stroke={color || 'currentColor'} strokeMiterlimit="10" strokeWidth="16" ></circle>
        <path fill="none" stroke={color || 'currentColor'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" d="M37.5 96h181M37.5 160h181" ></path>
        <ellipse cx="128" cy="128" fill="none" rx="40" ry="93.4" stroke={color || 'currentColor'} strokeMiterlimit="10" strokeWidth="16" ></ellipse>
    </svg>
);
