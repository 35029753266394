import { isNull, isUndefined, set, unset } from 'lodash/fp';
import { DateTime } from 'luxon';

import { CheckboxRiskConfig, CurrencyRiskConfig, DateRiskConfig, DropdownRiskConfig, MyRiskTolerance, NumberRiskConfig, RiskAssociated, RiskAssociatedValue, RiskComparator, RiskField, RiskTolerance, TimeRiskConfig } from '../components/admin/risk-tolerance/store';
import { FieldValue } from '../components/datasets/instances/store';
import { DatasetFieldType } from '../components/datasets/store';
import { formatDate, isAfter, isBefore, isSame, isSameOrAfter, isSameOrBefore } from './luxon';
import { SmileyIndicator } from '../components/shared/analytics/SingleIndicator';
import { CurrencyValue } from '../components/shared/number/CurrencyNumber';

export interface CityTimeZone {
    [string: string]: string;
}

export const riskLevels = [RiskAssociated.HIGHLY_DESIRABLE, RiskAssociated.DESIRABLE, RiskAssociated.ACCEPTABLE, RiskAssociated.OF_CONCERN, RiskAssociated.OF_SERIOUS_CONCERN];
export const fieldTypesAreEqual = (expectedType: DatasetFieldType, types: DatasetFieldType[]) => types.every(type => type === expectedType);

export const updateRiskToleranceConfig = (riskTolerance: (RiskTolerance | MyRiskTolerance)[], datasetId: number, fieldId: string, callback: (dataset: RiskField) => RiskField) => riskTolerance.map(riskDataset => riskDataset.datasetId === datasetId ? set('config', riskDataset.config.map(riskField => riskField.fieldId === fieldId ? callback(riskField) : riskField), riskDataset) : riskDataset);

const getCurrencyConfig = (riskToleranceConfig: (RiskTolerance | MyRiskTolerance)[], datasetId: number, fieldId: string) => (riskToleranceConfig!.find(riskDataset => riskDataset.datasetId === datasetId)?.config.find(riskField => riskField.fieldId === fieldId && riskField.type === DatasetFieldType.CURRENCY_AMOUNT)?.riskConfig as CurrencyRiskConfig);

const initialCurrencyRiskField = (currency: string): CurrencyRiskConfig => ({
    [currency]: {
        [RiskAssociated.OF_SERIOUS_CONCERN]: { value: undefined, comparator: null },
        [RiskAssociated.OF_CONCERN]: { value: undefined, comparator: null },
        [RiskAssociated.ACCEPTABLE]: { value: undefined, comparator: null },
        [RiskAssociated.DESIRABLE]: { value: undefined, comparator: null },
        [RiskAssociated.HIGHLY_DESIRABLE]: { value: undefined, comparator: null },
    }
});

export const updateCurrencyAmountRiskToleranceConfig = (stateRiskConfig: (RiskTolerance | MyRiskTolerance)[], datasetId: number, fieldId: string, selectedCurrency: string, riskAssociated: RiskAssociated, value: RiskAssociatedValue) => {
    const currencyConfig = getCurrencyConfig(stateRiskConfig, datasetId, fieldId);
    let currentConfigState = stateRiskConfig;
    if (isUndefined(currencyConfig[selectedCurrency])) {
        const initialRiskConfig = initialCurrencyRiskField(selectedCurrency);
        currentConfigState = updateRiskToleranceConfig(currentConfigState, datasetId, fieldId, riskField => set('riskConfig', { ...riskField.riskConfig, ...initialRiskConfig }, riskField));
    }
    let updatedRiskToleranceConfig = updateRiskToleranceConfig(currentConfigState, datasetId, fieldId, riskField => set(`riskConfig[${selectedCurrency}][${riskAssociated}]`, value, riskField));
    // if updatedRiskToleranceConfig is an empty config then we can remove that currency from the object
    let updatedConfig = getCurrencyConfig(updatedRiskToleranceConfig, datasetId, fieldId);
    const emptyConfig = [0, 1, 2, 3, 4].every(riskLevel => isUndefined(updatedConfig[selectedCurrency][riskLevel as RiskAssociated].value));
    if (emptyConfig) {
        updatedRiskToleranceConfig = updateRiskToleranceConfig(currentConfigState, datasetId, fieldId, riskField => set('riskConfig', unset(selectedCurrency, updatedConfig), riskField));
    }
    return updatedRiskToleranceConfig;
};

export const getRiskLevelIndicator = (riskLevel: number | null) => {
    if (isNull(riskLevel)) {
        return SmileyIndicator.NONE;
    }
    if (riskLevel === 4) {
        return SmileyIndicator.NEGATIVE;
    }
    if (riskLevel === 0) {
        return SmileyIndicator.POSITIVE;
    }
    return SmileyIndicator.NEUTRAL;
};

export const getCheckboxFieldRiskScore = (config: CheckboxRiskConfig, value: boolean | undefined): number => {
    const riskConfigHasValue = (riskLevel: RiskAssociated) => !isUndefined(config[riskLevel]);
    const riskConfigValue = (riskLevel: RiskAssociated) => config[riskLevel];
    let score = 2;
    riskLevels.map(riskLevel => {
        if (riskConfigHasValue(riskLevel) && !isUndefined(value) && value === riskConfigValue(riskLevel)) {
            score = riskLevel;
        }
    });
    return score;
};

export const getDropdownFieldRiskScore = (config: DropdownRiskConfig, value: string[],): number | null => {
    const riskConfigHasValue = (riskLevel: RiskAssociated) => config[riskLevel].every(val => val.length);
    const riskConfigValue = (riskLevel: RiskAssociated) => config[riskLevel];
    let score: number[] = [];
    riskLevels.map(riskLevel => {
        if (riskConfigHasValue(riskLevel)) {
            value.map(fieldValue => {
                if (riskConfigValue(riskLevel).includes(fieldValue)) {
                    score = [...score, riskLevel];
                }
            });
        }
    });
    if (!score.length) {
        return null;
    }
    return score.reduce((curr, a) => curr + a, 0) / score.length;
};

const getNumberFieldComparatorEvaluation = (fieldNumber: number, configNumber: number, comparator: RiskComparator): boolean => {
    switch (comparator) {
        case RiskComparator.LESS_THAN:
            return fieldNumber < configNumber;
        case RiskComparator.LESS_OR_EQUAL:
            return fieldNumber <= configNumber;
        case RiskComparator.GREATER_THAN:
            return fieldNumber > configNumber;
        case RiskComparator.GREATER_OR_EQUAL:
            return fieldNumber >= configNumber;
        case RiskComparator.NOT_EQUAL:
            return fieldNumber !== configNumber;
        default:
            return fieldNumber === configNumber;
    }
};

export const getNumberFieldRiskScore = (config: NumberRiskConfig, numberValue: number): number => {
    const riskConfigHasValue = (riskLevel: RiskAssociated) => !isUndefined(config[riskLevel].value) && !isNull(config[riskLevel].comparator);
    const riskConfigValue = (riskLevel: RiskAssociated) => config[riskLevel].value!;
    const riskConfigComparator = (riskLevel: RiskAssociated) => config[riskLevel].comparator!;
    let score = 2;
    riskLevels.map((riskLevel, index) => {
        if (riskConfigHasValue(riskLevel)) {
            const meetsConfigCriteria = getNumberFieldComparatorEvaluation(numberValue, riskConfigValue(riskLevel), riskConfigComparator(riskLevel));
            if (meetsConfigCriteria) {
                if (index === 0) {
                    score = riskLevel;
                } else {
                    let i = 1;
                    while (!riskConfigHasValue(index - i)) {
                        i++;
                    }
                    if (index - i < 0) {
                        score = riskLevel;
                    } else {
                        const previousRiskLevel = riskLevels[index - i];
                        const previousLevelAssignedValue = riskConfigHasValue(previousRiskLevel);
                        if (previousLevelAssignedValue) {
                            const previousValue = riskConfigValue(previousRiskLevel);
                            const previousComparator = riskConfigComparator(previousRiskLevel);
                            const currentValue = riskConfigValue(riskLevel);
                            const currentComparator = riskConfigComparator(riskLevel);
                            const previousMeetsConfigCriteria = getNumberFieldComparatorEvaluation(numberValue, riskConfigValue(previousRiskLevel), riskConfigComparator(previousRiskLevel));
                            if (!previousMeetsConfigCriteria) {
                                score = riskLevel;
                            }
                            if (currentComparator === RiskComparator.LESS_THAN && (previousComparator === RiskComparator.LESS_THAN || previousComparator === RiskComparator.LESS_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                if (currentValue < previousValue) {
                                    score = riskLevel;
                                }
                            }
                            if (currentComparator === RiskComparator.LESS_OR_EQUAL && (previousComparator === RiskComparator.LESS_THAN || previousComparator === RiskComparator.LESS_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                if (currentValue < previousValue) {
                                    score = riskLevel;
                                }
                            }
                            if (currentComparator === RiskComparator.GREATER_THAN && (previousComparator === RiskComparator.GREATER_THAN || previousComparator === RiskComparator.GREATER_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                if (currentValue > previousValue) {
                                    score = riskLevel;
                                }
                            }
                            if (currentComparator === RiskComparator.GREATER_OR_EQUAL && (previousComparator === RiskComparator.GREATER_THAN || previousComparator === RiskComparator.GREATER_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                if (currentValue > previousValue) {
                                    score = riskLevel;
                                }
                            }
                            if (currentComparator === RiskComparator.EQUAL) {
                                score = riskLevel;
                            }
                        }
                    }
                }
            }
        }
    });
    return score;
};

const getDateFieldComparatorEvaluation = (date: string, riskDate: string, comparator: RiskComparator): boolean => {
    switch (comparator) {
        case RiskComparator.LESS_THAN:
            return isBefore(date, riskDate);
        case RiskComparator.LESS_OR_EQUAL:
            return isSameOrBefore(date, riskDate);
        case RiskComparator.GREATER_THAN:
            return isAfter(date, riskDate);
        case RiskComparator.GREATER_OR_EQUAL:
            return isSameOrAfter(date, riskDate);
        case RiskComparator.NOT_EQUAL:
            return !isSame(date, riskDate);
        default:
            return isSame(date, riskDate);
    }
};

export const getDateFieldRiskScore = (config: DateRiskConfig, date: string): number | null => {
    const riskConfigHasValue = (riskLevel: RiskAssociated) => !isNull(config[riskLevel].value) && !isNull(config[riskLevel].comparator);
    const riskConfigValue = (riskLevel: RiskAssociated) => config[riskLevel].value!;
    const riskConfigComparator = (riskLevel: RiskAssociated) => config[riskLevel].comparator!;
    let score = null;
    riskLevels.map((riskLevel, index) => {
        if (riskConfigHasValue(riskLevel)) {
            const meetsConfigCriteria = getDateFieldComparatorEvaluation(date, riskConfigValue(riskLevel), riskConfigComparator(riskLevel));
            if (meetsConfigCriteria) {
                if (index === 0) {
                    score = riskLevel;
                } else {
                    let i = 1;

                    while (!riskConfigHasValue(index - i)) {
                        i++;
                    }
                    if (index - i < 0) {
                        score = riskLevel;
                    } else {
                        const previousRiskLevel = riskLevels[index - i];
                        const previousLevelAssignedValue = riskConfigHasValue(previousRiskLevel);
                        if (previousLevelAssignedValue) {
                            const previousValue = riskConfigValue(previousRiskLevel);
                            const previousComparator = riskConfigComparator(previousRiskLevel);
                            const currentValue = riskConfigValue(riskLevel);
                            const currentComparator = riskConfigComparator(riskLevel);
                            const previousMeetsConfigCriteria = getDateFieldComparatorEvaluation(date, riskConfigValue(previousRiskLevel), riskConfigComparator(previousRiskLevel));
                            if (currentComparator === RiskComparator.LESS_THAN && (previousComparator === RiskComparator.LESS_THAN || previousComparator === RiskComparator.LESS_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                if (isSameOrBefore(currentValue, previousValue)) {
                                    score = riskLevel;
                                }
                            }
                            if (currentComparator === RiskComparator.LESS_OR_EQUAL && (previousComparator === RiskComparator.LESS_THAN || previousComparator === RiskComparator.LESS_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                if (isSameOrBefore(currentValue, previousValue)) {
                                    score = riskLevel;
                                }
                            }
                            if (currentComparator === RiskComparator.GREATER_THAN && (previousComparator === RiskComparator.GREATER_THAN || previousComparator === RiskComparator.GREATER_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                if (isSameOrAfter(currentValue, previousValue)) {
                                    score = riskLevel;
                                }
                            }
                            if (currentComparator === RiskComparator.GREATER_OR_EQUAL && (previousComparator === RiskComparator.GREATER_THAN || previousComparator === RiskComparator.GREATER_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                if (isSameOrAfter(currentValue, previousValue)) {
                                    score = riskLevel;
                                }
                            }
                            if (currentComparator === RiskComparator.EQUAL) {
                                score = riskLevel;
                            }
                            if (!previousMeetsConfigCriteria) {
                                score = riskLevel;
                            }
                        }
                    }
                }
            }
        }
    });
    return score;
};

export const cityToTimezone: CityTimeZone = {
    'Abu Dhabi': 'Asia/Dubai',
    'Almaty': 'Asia/Almaty',
    'Amsterdam': 'Europe/Amsterdam',
    'Athens': 'Europe/Athens',
    'Bahamas': 'America/Nassau',
    'Bahrain': 'Asia/Bahrain',
    'Baku': 'Asia/Baku',
    'Bangkok': 'Asia/Bangkok',
    'Barbados': 'America/Barbados',
    'Beijing': 'Asia/Hong_Kong',
    'Belfast': 'Europe/London',
    'Bermuda': 'Atlantic/Bermuda',
    'Boston': 'America/New_York',
    'Bratislava': 'Europe/Bratislava',
    'British Virgin Islands': 'Atlantic/Bermuda',
    'Brussels': 'Europe/Brussels',
    'Budapest': 'Europe/Budapest',
    'Buenos Aires': 'America/Buenos_Aires',
    'Busan': 'Asia/Seoul',
    'Calgary': 'America/Edmonton',
    'Cape Town': 'Africa/Johannesburg',
    'Casablanca': 'Africa/Casablanca',
    'Cayman Islands': 'America/Cayman',
    'Chengdu': 'Asia/Hong_Kong',
    'Chicago': 'America/Chicago',
    'Copenhagen': 'Europe/Copenhagen',
    'Cyprus': 'Europe/Athens',
    'Dalian': 'Asia/Hong_Kong',
    'Doha': 'Asia/Qatar',
    'Dubai': 'Asia/Dubai',
    'Dublin': 'Europe/Dublin',
    'Edinburgh': 'Europe/London',
    'Frankfurt': 'Europe/Berlin',
    'Geneva': 'Europe/Zurich',
    'Gibraltar': 'Europe/Gibraltar',
    'GIFT City-Gujarat': 'Asia/Calcutta',
    'Glasgow': 'Europe/London',
    'Guangzhou': 'Asia/Hong_Kong',
    'Guernsey': 'Europe/London',
    'Hamburg': 'Europe/Berlin',
    'Hangzhou': 'Asia/Hong_Kong',
    'Helsinki': 'Europe/Helsinki',
    'Hong Kong': 'Asia/Hong_Kong',
    'Isle of Man': 'Europe/Isle_of_Man',
    'Istanbul': 'Europe/Istanbul',
    'Jakarta': 'Asia/Jakarta',
    'Jersey': 'Europe/Jersey',
    'Johannesburg': 'Africa/Johannesburg',
    'Kuala Lumpur': 'Asia/Kuala_Lumpur',
    'Kuwait City': 'Asia/Kuwait',
    'Labuan': 'Asia/Kuala_Lumpur',
    'Lahore': 'Asia/Karachi',
    'Liechtenstein': 'Europe/Vaduz',
    'Lisbon': 'Europe/Lisbon',
    'Los Angeles': 'America/Los_Angeles',
    'Luxembourg': 'Europe/Luxembourg',
    'Madrid': 'Europe/Madrid',
    'Malta': 'Europe/Malta',
    'Manila': 'Asia/Manila',
    'Mauritius': 'Indian/Mauritius',
    'Melbourne': 'Australia/Melbourne',
    'Mexico City': 'America/Mexico_City',
    'Miami': 'America/Louisville',
    'Milan': 'Europe/Rome',
    'Monaco': 'Europe/Monaco',
    'Montreal': 'America/Montreal',
    'Moscow': 'Europe/Moscow',
    'Mumbai': 'Asia/Calcutta',
    'Munich': 'Europe/Berlin',
    'Nairobi': 'Africa/Nairobi',
    'Nanjing': 'Asia/Hong_Kong',
    'New Delhi': 'Asia/Calcutta',
    'New York': 'America/New_York',
    'Nur-Sultan': 'Etc/GMT-6',
    'Osaka': 'Asia/Tokyo',
    'Oslo': 'Europe/Oslo',
    'Panama': 'America/Panama',
    'Paris': 'Europe/Paris',
    'Prague': 'Europe/Prague',
    'Qingdao': 'Asia/Hong_Kong',
    'Reykjavik': 'Atlantic/Reykjavik',
    'Riga': 'Europe/Riga',
    'Rio de Janeiro': 'America/Sao_Paulo',
    'Riyadh': 'Asia/Riyadh',
    'Rome': 'Europe/Rome',
    'Saint Petersberg': 'Europe/Moscow',
    'San Diego': 'America/Los_Angeles',
    'San Francisco': 'America/Los_Angeles',
    'Santiago': 'America/Santiago',
    'Sao Paulo': 'America/Sao_Paulo',
    'Seoul': 'Asia/Seoul',
    'Shanghai': 'Asia/Shanghai',
    'Shenzhen': 'Asia/Hong_Kong',
    'Singapore': 'Asia/Singapore',
    'Sofia': 'Europe/Sofia',
    'Stockholm': 'Europe/Stockholm',
    'Stuttgart': 'Europe/Berlin',
    'Sydney': 'Australia/Sydney',
    'Taipei': 'Asia/Taipei',
    'Tallinn': 'Europe/Tallinn',
    'Tehran': 'Asia/Tehran',
    'Tel Aviv': 'Asia/Jerusalem',
    'Tianjin': 'Asia/Hong_Kong',
    'Tokyo': 'Asia/Tokyo',
    'Toronto': 'America/Toronto',
    'Vancouver': 'America/Vancouver',
    'Vienna': 'Europe/Vienna',
    'Vilnius': 'Europe/Vilnius',
    'Warsaw': 'Europe/Warsaw',
    'Washington D.C.': 'America/New_York',
    'Wellington': 'Pacific/Auckland',
    'Wuhan': 'Asia/Hong_Kong',
    'Xi`an': 'Asia/Hong_Kong',
    'Zurich': 'Europe/Prague',
    'Other': ''
};

const getTimeFieldComparatorEvaluation = (fieldTime: string, riskTime: string, comparator: RiskComparator): boolean => {
    switch (comparator) {
        case RiskComparator.LESS_THAN:
            return isBefore(fieldTime, riskTime);
        case RiskComparator.LESS_OR_EQUAL:
            return isSameOrBefore(fieldTime, riskTime);
        case RiskComparator.GREATER_THAN:
            return isAfter(fieldTime, riskTime);
        case RiskComparator.GREATER_OR_EQUAL:
            return isSameOrAfter(fieldTime, riskTime);
        case RiskComparator.NOT_EQUAL:
            return !isSame(fieldTime, riskTime);
        default:
            return isSame(fieldTime, riskTime);
    }
};

export const getTimeFieldRiskScore = (config: TimeRiskConfig, time: [string, string]): number | null => {
    const riskConfigHasValue = (riskLevel: RiskAssociated) => !isNull(config[riskLevel]?.value) && !isNull(config[riskLevel]?.comparator);
    const riskConfigValue = (riskLevel: RiskAssociated) => config[riskLevel]?.value;
    const riskConfigComparator = (riskLevel: RiskAssociated) => config[riskLevel]?.comparator;
    let score = null;
    if (time.every(element => element !== '')) {
        riskLevels.map((riskLevel, index) => {
            if (riskConfigHasValue(riskLevel)) {
                let riskConfigTime = riskConfigValue(riskLevel)[0];
                const riskConfigTimeZone = riskConfigValue(riskLevel)[1];
                let fieldTime = time[0];
                const fieldTimeZone = time[1];
                if (riskConfigTimeZone !== fieldTimeZone) {
                    // If the city selected for the field does not match that selected for the config
                    // Then we need to convert the times to a UTC timezone to be able to accurately compare them
                    // First we convert the string to a DateTime so we can apply the initial zone, which is based on
                    // the city selected and then converted to a luxon accepted timeZone before we convert it to UTC time.
                    fieldTime = DateTime.fromISO(fieldTime as string, { zone: cityToTimezone[fieldTimeZone] }).setZone('Etc/UTC').toISO().toString();
                    riskConfigTime = DateTime.fromISO(riskConfigTime as string, { zone: cityToTimezone[riskConfigTimeZone] }).setZone('Etc/UTC').toISO().toString();
                }
                const meetsConfigCriteria = getTimeFieldComparatorEvaluation(fieldTime, riskConfigTime, riskConfigComparator(riskLevel)!);
                if (meetsConfigCriteria) {
                    if (index === 0) {
                        score = riskLevel;
                    } else {
                        let i = 1;
                        while (!riskConfigHasValue(index - i)) {
                            i++;
                        }
                        if (index - i < 0) {
                            score = riskLevel;
                        } else {
                            const previousRiskLevel = riskLevels[index - i];
                            const previousLevelAssignedValue = riskConfigHasValue(previousRiskLevel);
                            if (previousLevelAssignedValue) {
                                const previousValue = riskConfigValue(previousRiskLevel)[0];
                                const previousComparator = riskConfigComparator(previousRiskLevel)!;
                                const currentValue = riskConfigValue(riskLevel)[0];
                                const currentComparator = riskConfigComparator(riskLevel)!;
                                let previousRiskConfigTime = riskConfigValue(previousRiskLevel)[0];
                                const previousRiskConfigTimeZone = riskConfigValue(previousRiskLevel)[1];
                                if (previousRiskConfigTimeZone !== fieldTimeZone) {
                                    // Here again we have convert both to UTC before we can accurately check the conditions
                                    fieldTime = DateTime.fromISO(fieldTime as string, { zone: cityToTimezone[fieldTimeZone] }).setZone('Etc/UTC').toISO().toString();
                                    previousRiskConfigTime = DateTime.fromISO(previousRiskConfigTime as string, { zone: cityToTimezone[previousRiskConfigTimeZone] }).setZone('Etc/UTC').toISO().toString();
                                }
                                const previousMeetsConfigCriteria = getTimeFieldComparatorEvaluation(fieldTime, previousRiskConfigTime, previousComparator);
                                if ((currentComparator === RiskComparator.LESS_THAN || currentComparator === RiskComparator.LESS_OR_EQUAL) && (previousComparator === RiskComparator.LESS_THAN || previousComparator === RiskComparator.LESS_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                    if (isSameOrBefore(currentValue, previousValue)) {
                                        score = riskLevel;
                                    }
                                }
                                if (currentComparator === RiskComparator.LESS_OR_EQUAL && (previousComparator === RiskComparator.LESS_THAN || previousComparator === RiskComparator.LESS_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                    if (isSameOrBefore(currentValue, previousValue)) {
                                        score = riskLevel;
                                    }
                                }
                                if ((currentComparator === RiskComparator.GREATER_THAN || currentComparator === RiskComparator.GREATER_OR_EQUAL) && (previousComparator === RiskComparator.GREATER_THAN || previousComparator === RiskComparator.GREATER_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                    if (isSameOrAfter(currentValue, previousValue)) {
                                        score = riskLevel;
                                    }
                                }
                                if (currentComparator === RiskComparator.GREATER_OR_EQUAL && (previousComparator === RiskComparator.GREATER_THAN || previousComparator === RiskComparator.GREATER_OR_EQUAL || previousComparator === RiskComparator.EQUAL) && previousMeetsConfigCriteria) {
                                    if (isSameOrAfter(currentValue, previousValue)) {
                                        score = riskLevel;
                                    }
                                }
                                if (currentComparator === RiskComparator.EQUAL) {
                                    score = riskLevel;
                                }
                                if (!previousMeetsConfigCriteria) {
                                    score = riskLevel;
                                }
                            }
                        }
                    }
                }
            }
        });
    }
    return score;
};

export const getRiskToleranceIndicator = (fieldRiskConfig: RiskField | undefined, value: FieldValue, fieldType: DatasetFieldType) => {
    if (!isUndefined(fieldRiskConfig)) {
        const { type, riskConfig } = fieldRiskConfig;
        if (isUndefined(value) || isNull(value)) {
            // then the field has no value
            return SmileyIndicator.NONE;
        }
        if (fieldTypesAreEqual(DatasetFieldType.DROPDOWN, [fieldType, type])) {
            const riskLevel = getDropdownFieldRiskScore(riskConfig as DropdownRiskConfig, value as string[]);
            return getRiskLevelIndicator(riskLevel);
        }
        if (fieldTypesAreEqual(DatasetFieldType.NUMBER, [fieldType, type])) {
            const riskLevel = getNumberFieldRiskScore(riskConfig as NumberRiskConfig, value as number);
            return getRiskLevelIndicator(riskLevel);
        }
        if (fieldTypesAreEqual(DatasetFieldType.CHECKBOX, [fieldType, type])) {
            const riskLevel = getCheckboxFieldRiskScore(riskConfig as CheckboxRiskConfig, value as boolean);
            return getRiskLevelIndicator(riskLevel);
        }
        if (fieldTypesAreEqual(DatasetFieldType.DATE, [fieldType, type])) {
            const riskLevel = getDateFieldRiskScore(riskConfig as DateRiskConfig, formatDate(value as string));
            return getRiskLevelIndicator(riskLevel);
        }
        if (fieldTypesAreEqual(DatasetFieldType.TIME, [fieldType, type])) {
            const riskLevel = getTimeFieldRiskScore(riskConfig as TimeRiskConfig, value as [string, string]);
            return getRiskLevelIndicator(riskLevel);
        }
        if (fieldTypesAreEqual(DatasetFieldType.CURRENCY_AMOUNT, [fieldType, type])) {
            const hasConfigForCurrency: NumberRiskConfig | undefined = (riskConfig as CurrencyRiskConfig)[(value as CurrencyValue).currency!];
            if (isUndefined(hasConfigForCurrency) || isUndefined((value as CurrencyValue).value)) {
                return SmileyIndicator.NONE;
            }
            const riskLevel = getNumberFieldRiskScore(hasConfigForCurrency, (value as CurrencyValue).value as number);
            return getRiskLevelIndicator(riskLevel);
        }
    }
    return SmileyIndicator.NONE;
};

export const getRiskToleranceConfigIsEmpty = (fieldRiskConfig: RiskField, fieldType: DatasetFieldType, fieldValue?: FieldValue): boolean => {
    const { type, riskConfig } = fieldRiskConfig;
    if (fieldTypesAreEqual(DatasetFieldType.DROPDOWN, [fieldType, type])) {
        const dropdownRiskConfig = riskConfig as DropdownRiskConfig;
        return [0, 1, 2, 3 ,4].every(riskLevel => dropdownRiskConfig[riskLevel as RiskAssociated].length === 0);
    }
    if (fieldTypesAreEqual(DatasetFieldType.NUMBER, [fieldType, type])) {
        const numberRiskConfig = riskConfig as NumberRiskConfig;
        return [0, 1, 2, 3 ,4].every(riskLevel => isUndefined(numberRiskConfig[riskLevel as RiskAssociated].value));
    }
    if (fieldTypesAreEqual(DatasetFieldType.CHECKBOX, [fieldType, type])) {
        const checkboxRiskConfig = riskConfig as CheckboxRiskConfig;
        return [0, 1, 2, 3 ,4].every(riskLevel => isUndefined(checkboxRiskConfig[riskLevel as RiskAssociated]));
    }
    if (fieldTypesAreEqual(DatasetFieldType.DATE, [fieldType, type])) {
        const dateRiskConfig = riskConfig as DateRiskConfig;
        return [0, 1, 2, 3 ,4].every(riskLevel => isUndefined(dateRiskConfig[riskLevel as RiskAssociated].value));
    }
    if (fieldTypesAreEqual(DatasetFieldType.TIME, [fieldType, type])) {
        const timeRiskConfig = riskConfig as TimeRiskConfig;
        return [0, 1, 2, 3 ,4].every(riskLevel => isUndefined(timeRiskConfig[riskLevel as RiskAssociated].value[0] === '' && timeRiskConfig[riskLevel as RiskAssociated].value[1] === ''));
    }
    if (fieldTypesAreEqual(DatasetFieldType.CURRENCY_AMOUNT, [fieldType, type])) {
        const currencyRiskConfig = riskConfig as CurrencyRiskConfig;
        const hasCurrencyConfigs = Object.keys(currencyRiskConfig).length > 0;
        if (!hasCurrencyConfigs || hasCurrencyConfigs && (isUndefined(fieldValue) || isNull(fieldValue) || isNull((fieldValue as CurrencyValue).currency))) {
            return true;
        }
        const fieldCurrency = (fieldValue as CurrencyValue).currency!;
        if (isUndefined(currencyRiskConfig[fieldCurrency])) {
            return true;
        }
        return [0, 1, 2, 3, 4].every(riskLevel => isUndefined(currencyRiskConfig[fieldCurrency][riskLevel as RiskAssociated].value));
    }
    return false;
};
