import React, { useCallback, useMemo, useState } from 'react';
import { isEqual, uniq } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { BasicPlaybook, getAllPlaybookStarted, getPlaybookByIdStarted, getPlaybookCoverModalOpen, getPlaybookCoverSelected, getPlaybookShelves, togglePlaybookCoverSelected, getPlaybookSimpleSearchTerm, updatePlaybookSimpleSearch, resetPlaybookSearch, initialPlaybookSmartSearchTerm, getPlaybookSmartSearchTerm, PlaybookSmartSearch as PlaybookSmartSearchType, playbookSmartSearchStarted, resetPlaybookSmartSearch, getShowPlaybookViewLoadingSpinner, updatePlaybookSmartSearch, getSystemAdminBookshelfView, SystemAdminBookshelfView, changeSystemAdminBookshelfView } from '../store';
import styles from '../../../shared/playbook/PlaybookBookcase.module.scss';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { PlaybookShelf } from '../../../shared/playbook/PlaybookShelf';
import { fetchAllAgreementTypesStarted, fetchAvailableDocumentNamesStarted } from '../../documents/store';
import { BookCoverModal } from '../../../shared/playbook/BookCoverModal';
import { Button } from '../../../shared/button/Button';
import { PlaybookSmartSearch } from '../../../shared/playbook/PlaybookSmartSearch';
import { Spinner } from '../../../shared/spinner/Spinner';
import { SimpleSearch } from '../../../shared/search/SimpleSearch';
import { getUserRole } from '../../../auth/login/store';
import { systemAdminRole } from '../../../constants/permittedRoles';

export const MyPlaybooks: React.FC = () => {
    const testId = 'admin-playbooks';
    const dispatch = useAppDispatch();
    useFetchStarted([getAllPlaybookStarted(), fetchAvailableDocumentNamesStarted(), fetchAllAgreementTypesStarted()]);
    const [showSmartSearch, setShowSmartSearch] = useState<boolean>(false);

    const userRole = useAppSelector(getUserRole);
    const isSystemAdmin = systemAdminRole.includes(userRole!);

    const flexDirection = useMemo(() => showSmartSearch ? 'row' : 'column', [showSmartSearch]);
    const bookCaseWrapperStyle = useMemo(() => ({ width: showSmartSearch ? '45%' : '90%', margin: showSmartSearch ? '0 auto 0 0' : '0 auto' }), [showSmartSearch]);

    const playbookShelves = useAppSelector(getPlaybookShelves);
    const { topShelf, bottomShelf } = playbookShelves;
    const noPlaybooks = !topShelf.length && !bottomShelf.length;

    const playbookAuthors = useMemo(() => {
        const allAuthors = [...topShelf, ...bottomShelf].map(({ createdBy }) => createdBy);
        return uniq(allAuthors);
    }, [topShelf, bottomShelf]);

    const openPlaybookCoverModal = useCallback((playbook: BasicPlaybook) => { dispatch(togglePlaybookCoverSelected(playbook)); }, [dispatch]);

    const playbookSelected = useAppSelector(getPlaybookCoverSelected);
    const isOpen = useAppSelector(getPlaybookCoverModalOpen);
    const smartSearchTerm = useAppSelector(getPlaybookSmartSearchTerm);
    const showLoadingSpinner = useAppSelector(getShowPlaybookViewLoadingSpinner);
    const systemAdminView = useAppSelector(getSystemAdminBookshelfView);
    const showTemplateBookEnd = useMemo(() => isSystemAdmin && systemAdminView === SystemAdminBookshelfView.ALL, [isSystemAdmin, systemAdminView]);

    const closeModal = useCallback(() => dispatch(togglePlaybookCoverSelected(null)), [dispatch]);
    const openPlaybook = useCallback(() => playbookSelected && dispatch(getPlaybookByIdStarted(playbookSelected.playbookId)), [dispatch, playbookSelected]);

    const searchButtonLabel = useMemo(() => showSmartSearch ? 'Simple Search' : 'Smart Search', [showSmartSearch]);
    const playbookSearchTerm = useAppSelector(getPlaybookSimpleSearchTerm);
    const searchPlaybooks = useCallback((simpleSearchTerm: string) => dispatch(updatePlaybookSimpleSearch(simpleSearchTerm)), [dispatch]);
    const resetSearchTerm = useCallback(() => dispatch(resetPlaybookSearch()), [dispatch]);

    const updateSmartSearch = useCallback((smartSearchTerm: PlaybookSmartSearchType[]) => {
        dispatch(updatePlaybookSmartSearch(smartSearchTerm));
    }, [dispatch]);

    const startSmartSearch = useCallback(() => {
        dispatch(playbookSmartSearchStarted());
    }, [dispatch]);

    const resetSmartSearch = useCallback(() => {
        dispatch(getAllPlaybookStarted());
        dispatch(resetPlaybookSmartSearch());
    }, [dispatch]);

    const toggleSmartSearch = useCallback(() => {
        setShowSmartSearch(!showSmartSearch);
        resetSearchTerm();
        resetSmartSearch();
    }, [showSmartSearch, resetSearchTerm, resetSmartSearch]);

    const changeSystemAdminView = useCallback((view: SystemAdminBookshelfView) => {
        dispatch(changeSystemAdminBookshelfView(view));
    }, [dispatch]);

    const showDefaultBook = useMemo(() => noPlaybooks && playbookSearchTerm === '' && isEqual(smartSearchTerm, [initialPlaybookSmartSearchTerm]), [noPlaybooks, playbookSearchTerm, smartSearchTerm]);

    return (
        <div className={styles.playbooksWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.simpleSearchWrapper} data-testid={`${testId}-simple-search-wrapper`}>
                <Button label={searchButtonLabel} onClick={toggleSmartSearch} disabled={noPlaybooks} testId={`${testId}-search-toggle`} />
                {!showSmartSearch && <SimpleSearch
                    searchTerm={playbookSearchTerm}
                    updateSearchTerm={searchPlaybooks}
                    resetSimpleSearch={resetSearchTerm}
                    testId={testId}
                    searchDisabled={noPlaybooks}
                />}
                {isSystemAdmin &&
                    <div className={styles.systemAdminViewWrapper}>
                        <Button label='All' width='fit-content' onClick={() => changeSystemAdminView(SystemAdminBookshelfView.ALL)} disabled={systemAdminView === SystemAdminBookshelfView.ALL} testId={`${testId}-system-admin-view-all`} />
                        <Button label='Templates' width='fit-content' onClick={() => changeSystemAdminView(SystemAdminBookshelfView.TEMPLATES)} disabled={systemAdminView === SystemAdminBookshelfView.TEMPLATES} testId={`${testId}-system-admin-view-templates`} />
                        <Button label='Published' width='fit-content' onClick={() => changeSystemAdminView(SystemAdminBookshelfView.PUBLISHED)} disabled={systemAdminView === SystemAdminBookshelfView.PUBLISHED} testId={`${testId}-system-admin-view-published`} />
                    </div>
                }
            </div>
            <div className={styles.bookshelfSearchWrapper} style={{ flexDirection: flexDirection }}>
                {showSmartSearch &&
                    <PlaybookSmartSearch
                        initialPlaybookSmartSearchTerm={initialPlaybookSmartSearchTerm}
                        smartSearchTerm={smartSearchTerm}
                        playbookAuthors={playbookAuthors}
                        updateSmartSearch={updateSmartSearch}
                        startSmartSearch={startSmartSearch}
                        resetPlaybookSearch={resetSmartSearch}
                        isSearching={showLoadingSpinner}
                        testId={testId}
                    />
                }
                {showLoadingSpinner ?
                    <div className={styles.bookCaseSpinner} style={bookCaseWrapperStyle}>
                        <Spinner />
                    </div>
                    :
                    <div className={styles.bookCaseWrapper} style={bookCaseWrapperStyle} data-testid={`${testId}-bookcase-wrapper`}>
                        <div className={styles.bookCaseSideWrapper}>
                            <div className={styles.bookCaseSide} />
                        </div>
                        <Scrollable>
                            <div className={styles.bookCase}>
                                <div className={styles.shelfWrapper}>
                                    <div className={styles.shelf} />
                                </div>
                                <PlaybookShelf playbooks={topShelf} isTopShelf showDefaultBook={showDefaultBook} openPlaybookCoverModal={openPlaybookCoverModal} showTemplateBookEnd={showTemplateBookEnd} testId={`${testId}-top-shelf`} />
                                <div className={styles.shelfWrapper}>
                                    <div className={styles.shelf} />
                                </div>
                                <PlaybookShelf playbooks={bottomShelf} openPlaybookCoverModal={openPlaybookCoverModal} testId={`${testId}-bottom-shelf`} />
                                <div className={styles.shelfWrapper}>
                                    <div className={styles.shelf} />
                                </div>
                            </div>
                        </Scrollable>
                        <div className={styles.bookCaseSideWrapper}>
                            <div className={styles.bookCaseSide} />
                        </div>
                        <BookCoverModal
                            isOpen={isOpen}
                            playbookSelected={playbookSelected}
                            closeModal={closeModal}
                            openPlaybook={openPlaybook}
                            testId={`${testId}-book-cover`}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

