import { CompanyEntity, Lei, SearchEntityBy } from '../../../admin/entity/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';

export enum DoraThirdPartyActionTypes {
    TOGGLE_THIRD_PARTY_COMPANIES_MODAL = 'TOGGLE_THIRD_PARTY_COMPANIES_MODAL',
    FETCH_PAGINATED_THIRD_PARTY_COMPANIES_STARTED = 'FETCH_PAGINATED_THIRD_PARTY_COMPANIES_STARTED',
    FETCH_PAGINATED_THIRD_PARTY_COMPANIES_FAILED = 'FETCH_PAGINATED_THIRD_PARTY_COMPANIES_FAILED',
    FETCH_PAGINATED_THIRD_PARTY_COMPANIES_SUCCESSFUL = 'FETCH_PAGINATED_THIRD_PARTY_COMPANIES_SUCCESSFUL',
    SET_THIRD_PARTY_COMPANIES_TABLE_FILTERS = 'SET_THIRD_PARTY_COMPANIES_TABLE_FILTERS',
    CLEAR_THIRD_PARTY_COMPANIES_TABLE_FILTERS = 'CLEAR_THIRD_PARTY_COMPANIES_TABLE_FILTERS',
    SET_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT = 'SET_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT',
    SET_THIRD_PARTY_COMPANIES_PAGE_SIZE = 'SET_THIRD_PARTY_COMPANIES_PAGE_SIZE',
    THIRD_PARTY_COMPANIES_PAGINATION_NEXT = 'THIRD_PARTY_COMPANIES_PAGINATION_NEXT',
    THIRD_PARTY_COMPANIES_PAGINATION_PREVIOUS = 'THIRD_PARTY_COMPANIES_PAGINATION_PREVIOUS',
    SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS = 'SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS',
    CLEAR_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS = 'CLEAR_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS',
    SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT = 'SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT',
    SET_AVAILABLE_THIRD_PARTY_COMPANIES_PAGE_SIZE = 'SET_AVAILABLE_THIRD_PARTY_COMPANIES_PAGE_SIZE',
    AVAILABLE_THIRD_PARTY_COMPANIES_PAGINATION_NEXT = 'AVAILABLE_THIRD_PARTY_COMPANIES_PAGINATION_NEXT',
    AVAILABLE_THIRD_PARTY_COMPANIES_PAGINATION_PREVIOUS = 'AVAILABLE_THIRD_PARTY_COMPANIES_PAGINATION_PREVIOUS',
    SET_SELECTED_THIRD_PARTY_COMPANY = 'SET_SELECTED_THIRD_PARTY_COMPANY',
    UPDATE_THIRD_PARTY_COMPANY_VALUE = 'UPDATE_THIRD_PARTY_COMPANY_VALUE',
    UPDATE_THIRD_PARTY_COMPANY_CONTENT = 'UPDATE_THIRD_PARTY_COMPANY_CONTENT',
    UPDATE_THIRD_PARTY_COMPANY_ADDRESS_VALUE = 'UPDATE_THIRD_PARTY_COMPANY_ADDRESS_VALUE',
    SAVE_THIRD_PARTY_COMPANY_STARTED = 'SAVE_THIRD_PARTY_COMPANY_STARTED',
    SAVE_THIRD_PARTY_COMPANY_SUCCESSFUL = 'SAVE_THIRD_PARTY_COMPANY_SUCCESSFUL',
    SAVE_THIRD_PARTY_COMPANY_FAILED = 'SAVE_THIRD_PARTY_COMPANY_FAILED',
    TOGGLE_THIRD_PARTY_SEARCH_MODAL_OPEN = 'TOGGLE_THIRD_PARTY_SEARCH_MODAL_OPEN',
    SEARCH_THIRD_PARTY_COMPANIES_STARTED = 'SEARCH_THIRD_PARTY_COMPANIES_STARTED',
    SEARCH_THIRD_PARTY_COMPANIES_SUCCESSFUL = 'SEARCH_THIRD_PARTY_COMPANIES_SUCCESSFUL',
    SEARCH_THIRD_PARTY_COMPANIES_FAILED = 'SEARCH_THIRD_PARTY_COMPANIES_FAILED',
    SET_THIRD_PARTY_COMPANIES_SEARCH_PAGE_SIZE = 'SET_THIRD_PARTY_COMPANIES_SEARCH_PAGE_SIZE',
    THIRD_PARTY_COMPANIES_SEARCH_PAGINATION_NEXT = 'THIRD_PARTY_COMPANIES_SEARCH_PAGINATION_NEXT',
    THIRD_PARTY_COMPANIES_SEARCH_PAGINATION_PREVIOUS = 'THIRD_PARTY_COMPANIES_SEARCH_PAGINATION_PREVIOUS',
    RESET_THIRD_PARTY_COMPANY_SEARCH = 'RESET_THIRD_PARTY_COMPANY_SEARCH',
    SET_THIRD_PARTY_COMPANY_SEARCH_VALUE = 'SET_THIRD_PARTY_COMPANY_SEARCH_VALUE',
    SET_THIRD_PARTY_COMPANY_SEARCH_TYPE = 'SET_THIRD_PARTY_COMPANY_SEARCH_TYPE',
    UPSERT_THIRD_PARTY_LEI_ENTITY = 'UPSERT_THIRD_PARTY_LEI_ENTITY',
    CHANGE_THIRD_PARTY_VIEW = 'CHANGE_THIRD_PARTY_VIEW',
    FETCH_FUZZY_AND_AUTOCOMPLETIONS_STARTED = 'FETCH_THIRD_PARTY_FUZZY_AND_AUTOCOMPLETIONS_STARTED',
    FETCH_FUZZY_AND_AUTOCOMPLETIONS_SUCCESSFUL = 'FETCH_THIRD_PARTY_FUZZY_AND_AUTOCOMPLETIONS_SUCCESSFUL',
    FETCH_FUZZY_AND_AUTOCOMPLETIONS_FAILED = 'FETCH_THIRD_PARTY_FUZZY_AND_AUTOCOMPLETIONS_FAILED',
}

export enum DoraThirdPartyView {
    ACTIVE_THIRD_PARTIES = 'Active Third Parties',
    AVAILABLE_ENTITIES = 'Available Entities'
}

export interface DoraThirdPartyState {
    thirdPartyView: DoraThirdPartyView;
    fetchError: string | null;
    thirdPartyCompaniesFilters: TableFilters;
    thirdPartyCompaniesSort?: ColumnSort;
    thirdPartyCompaniesPage: number;
    thirdPartyCompaniesPageSize: number;
    availableThirdPartyCompaniesFilters: TableFilters;
    availableThirdPartyCompaniesSort?: ColumnSort;
    availableThirdPartyCompaniesPage: number;
    availableThirdPartyCompaniesPageSize: number;
    fetchingThirdPartyCompanies: boolean;
    allThirdPartyCompanies: CompanyEntity[];
    totalThirdPartyCompanies: number;
    selectedThirdPartyCompany: null | CompanyEntity;
    currentThirdPartyCompany: null | CompanyEntity;
    savingThirdPartyCompany: boolean;
    searchModalOpen: boolean;
    isSearching: boolean;
    searchEntityPage: number;
    searchEntityPageSize: number;
    totalSearchEntities: number;
    searchInput: string;
    searchBy: SearchEntityBy;
    searchResults: Lei[];
    isSaving: boolean;
    fuzzyCompletions: string[];
    autoCompletions: string[];
    showCompletions: boolean;
}
