import { flow, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';
import { AdminOpinionStateActionTypes, AdminOpinionState } from './types';

export const INITIAL_STATE: AdminOpinionState = {
    adminOpinionError: '',
    isLoadingPie: false,
    opinionPieData: [],
    isLoadingTable: false,
    selectedOpinions: [],
    selectedOpinionsTablePageSize: DEFAULT_PAGE_SIZE,
    selectedOpinionsTablePageNumber: 1,
    selectedOpinionsTableColumnSort: undefined,
    selectedOpinionsTableFilters: {},
    selectedOpinionsTotal: 0,
    selectedOpinionHealthCategory: null
};

export const adminOpinionReducer: Reducer<AdminOpinionState> = (state = INITIAL_STATE, { payload, type }): AdminOpinionState => {
    switch (type) {
        case AdminOpinionStateActionTypes.FETCH_ADMIN_OPINION_ANALYTICS_STARTED:
            return set('isLoadingPie', true, state);
        case AdminOpinionStateActionTypes.FETCH_ADMIN_OPINION_ANALYTICS_SUCCESSFUL:
            return flow(
                set('opinionPieData', payload),
                set('isLoadingPie', false)
            )(state);
        case AdminOpinionStateActionTypes.FETCH_ADMIN_OPINION_ANALYTICS_FAILED:
            return flow(
                set('adminOpinionError', payload),
                set('isLoadingPie', false)
            )(state);
        case AdminOpinionStateActionTypes.FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_STARTED:
            return flow(
                set('isLoadingTable', true),
                set('selectedOpinionHealthCategory', payload.healthIndicator),
                set('selectedOpinionsTablePageNumber', payload.pageNumber)
            )(state);
        case AdminOpinionStateActionTypes.FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_SUCCESSFUL:
            return flow(
                set('selectedOpinions', payload.data),
                set('selectedOpinionsTotal', payload.total),
                set('isLoadingTable', false)
            )(state);
        case AdminOpinionStateActionTypes.FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_FAILED:
            return flow(
                set('adminOpinionError', payload),
                set('isLoadingTable', false)
            )(state);
        case AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_COLUMN_SORT: {
            return set('selectedOpinionsTableColumnSort', payload, state);
        }
        case AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_PAGE_SIZE:
            return set('selectedOpinionsTablePageSize', payload, state);
        case AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`selectedOpinionsTableFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`selectedOpinionsTableFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case AdminOpinionStateActionTypes.CLEAR_SELECTED_OPINIONS_TABLE_FILTERS:
            return set('selectedOpinionsTableFilters', {}, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
