import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';

import styles from '../../MyDatasets.module.scss';
import { TableDatasetFieldType } from '../../../../datasets/store';
import { widthsByType } from '../../../dataset-builder/preview/tablePreview/TableHeader';
import { InformationTooltip, OverflowTooltip } from '../../../../shared/tooltip';
import { HiddenField, toggleHiddenField } from '../../store';
import { OpenFieldType } from '../../../../datasets/instances/store';
import { useAppDispatch } from '../../../../../hooks/react-redux';
import { RadioButton } from '../../../../shared/button/RadioButton';
import { Tick } from '../../../../shared/icons';
import { getHiddenDocumentsTooltip } from '../../formDefinition/MyDatasetsFormDefinition';

const { white } = styles;

interface TableHeaderProps {
    id: string;
    label: string;
    description: string;
    refLabel?: string
    showRef?: boolean;
    type: TableDatasetFieldType;
    isFirstCell: boolean;
    datasetId: number;
    columnHidden: boolean;
    isPreview: boolean;
    datasetHidden?: boolean;
    hiddenDocumentNames: string[];
}

export const TableHeader: React.FC<TableHeaderProps> = ({ label, description, id, refLabel, showRef, type, isFirstCell, datasetId, columnHidden, isPreview, datasetHidden, hiddenDocumentNames }) => {
    const dispatch = useAppDispatch();
    const cellWidth = useMemo(() => `${widthsByType[type]}px`, [type]);
    const columnLabel = useMemo(() => label || 'Label...', [label]);

    const hiddenField: HiddenField = useMemo(() => ({ fieldId: id!, type: OpenFieldType.FIELD }), [id]);
    const toggleColumnVisible = useCallback(() => dispatch(toggleHiddenField(datasetId, hiddenField)), [datasetId, hiddenField, dispatch]);

    const headerContent = useMemo(() => (
        <div className={styles.cellHeader}>
            <OverflowTooltip className={classnames(styles.headerLabel, { [styles.emptyHeaderLabel]: !label })} overlayText={columnLabel} testId={`table-header-${id}-label`} />
            <div className={styles.headerIconWrapper}>
                {description && <InformationTooltip content={description} labelColor={white} />}
                {refLabel && showRef && <InformationTooltip content={refLabel} label='?' labelColor={white} />}
                {!isPreview && <RadioButton fontSize={10} withBackground={!columnHidden} disabled={datasetHidden} onClick={toggleColumnVisible} icon={Tick} marginRight='5px' />}
                {hiddenDocumentNames.length > 0 && getHiddenDocumentsTooltip(hiddenDocumentNames, 'section')}
            </div>
        </div>
    ), [columnLabel, description, refLabel, showRef, label, id, columnHidden, toggleColumnVisible, datasetHidden, isPreview, hiddenDocumentNames]);

    return (
        <div
            className={classnames(styles.tableHeaderWrapper, {
                [styles.firstCell]: isFirstCell
            })}
            style={{ width: cellWidth, minWidth: cellWidth }}
        >
            {headerContent}
        </div>
    );
};
