import React, { useCallback, useMemo } from 'react';
import { isEqual, isNull } from 'lodash/fp';

import { SingleDatasetField, TableDatasetDefinition, TableDatasetFieldType } from '../../../../datasets/store';
import { addOpenFieldSection, DatasetsOpenFieldSection, DocumentSpecificHiddenFields, getOpenFieldsAndSections, removeOpenFieldSection } from '../../store';
import { DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { useSingleHiddenDocumentFields } from '../../../../../hooks/useHiddenSingleFields';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import styles from '../ConfigureDatasets.module.scss';
import { DocumentDatasetTableHeader } from './table/DocumentDatasetTableHeader';
import { DocumentDatasetTableCell } from './table/DocumentDatasetsTableCell';

interface DocumentDatasetTableDefinitionProps {
    tableDefinition: TableDatasetDefinition;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: DocumentSpecificHiddenFields;
    hiddenDocumentNameIds?: number[] | undefined;
    documentNames: DropdownOption[];
    parentSectionId: string | undefined;
    previewSelectedDocument: number | null;
}

export const DocumentDatasetTableDefinition: React.FC<DocumentDatasetTableDefinitionProps> = ({ tableDefinition, isPreview, modalInstance, hiddenFields, hiddenDocumentNameIds, documentNames, parentSectionId, previewSelectedDocument }) => {
    const { datasetFields, datasetId } = tableDefinition;
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);

    const getSectionOpen = useCallback((fieldSection: DatasetsOpenFieldSection) => openFieldsAndSections.some(openSection => isEqual(openSection, fieldSection)), [openFieldsAndSections]);

    const toggleSection = useCallback((fieldSection: DatasetsOpenFieldSection) => {
        const sectionOpen = openFieldsAndSections.find(openSection => isEqual(openSection, fieldSection));
        sectionOpen ? dispatch(removeOpenFieldSection(sectionOpen)) : dispatch(addOpenFieldSection(fieldSection));
    }, [dispatch, openFieldsAndSections]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId!] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenDocumentFields(currentHiddenDatasetFields);
    const getColumnHidden = useCallback((id: string) => {
        const hiddenSingleFieldDocumentNameIds = hiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds || [];
        const allHiddenDocumentNameIds = [...hiddenSingleFieldDocumentNameIds, ...hiddenDocumentNameIds || []];
        return !!isPreview && !isNull(previewSelectedDocument) && allHiddenDocumentNameIds.includes(previewSelectedDocument);
    }, [isPreview, previewSelectedDocument, hiddenSingleFields, hiddenDocumentNameIds]);

    const tableFirstCell = useCallback((fieldId: string) => datasetFields.filter(({ id }) => !hiddenSingleFields.map(({ fieldId }) => fieldId).includes(id!))[0].id === fieldId, [hiddenSingleFields, datasetFields]);
    return (
        <div className={styles.tableDefinitionWrapper} data-testid='table-dataset-definition-wrapper' style={{ height: '100%', maxHeight: '400px' }}>
            <Scrollable>
                <div className={styles.tableHeader}>
                    {datasetFields.map((field: SingleDatasetField, index: number) => {
                        const { label, description, refLabel, settings, id, type } = field;
                        const hiddenColumn = getColumnHidden(id!);
                        if (isPreview && hiddenColumn) {
                            return null;
                        }
                        const isFirstCell = isPreview ? tableFirstCell(id!) : index === 0;
                        return (
                            <DocumentDatasetTableHeader
                                key={id!}
                                label={label}
                                description={description}
                                refLabel={refLabel}
                                showRef={settings.showRef}
                                id={id!}
                                type={type as TableDatasetFieldType}
                                isFirstCell={isFirstCell}
                                datasetId={datasetId!}
                                isPreview={isPreview}
                                hiddenDocumentNameIds={hiddenDocumentNameIds}
                                hiddenSingleFields={hiddenSingleFields}
                                documentNames={documentNames}
                                parentSectionId={parentSectionId}
                            />
                        );
                    })}
                </div>
                <div className={styles.tableBody} style={{ height: 'calc(100% - 50px)', maxHeight: '350px' }}>
                    <Scrollable maxHeight='350px'>
                        <div className={styles.tableRow}>
                            {datasetFields.map((field, index) => {
                                const hiddenColumn = getColumnHidden(field.id!);
                                if (isPreview && hiddenColumn) {
                                    return null;
                                }
                                const isFirstCell = isPreview ? tableFirstCell(field.id!) : index === 0;
                                return (
                                    <DocumentDatasetTableCell
                                        key={field.id!}
                                        field={field}
                                        index={index}
                                        isFirstCell={isFirstCell}
                                        datasetId={datasetId!}
                                        toggleSection={toggleSection}
                                        getSectionOpen={getSectionOpen}
                                        modalInstance={modalInstance}
                                        columnHidden={hiddenColumn}
                                        hiddenDocumentNameIds={hiddenDocumentNameIds}
                                        hiddenSingleFields={hiddenSingleFields}
                                    />
                                );
                            })}
                        </div>
                    </Scrollable>
                </div>
            </Scrollable>
        </div>
    );
};
