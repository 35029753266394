import { isUndefined } from 'lodash/fp';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { LeiSearch } from '../../shared/lei/LeiSearch';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { addNewLeiEntityStarted, entitiesSearchPaginationNext, entitiesSearchPaginationPrevious, fetchFuzzyAutoCompletionsSuccessful, getAutoCompletions, getEntitiesSearchPageNumber, getEntitiesSearchPageSize, getFuzzyCompletions, getIsSaving, getIsSearching, getSearchEntityType, getSearchEntityValue, getSearchResults, getShowFuzzyAutoCompletions, getTotalSearchEntities, Lei, resetEntitySearch, SearchEntityBy, setEntitiesSearchPageSize, setSearchEntityType, setSearchEntityValue } from './store';

interface SearchEntityModalProps {
    isOpen: boolean;
    closeModal: () => void;
    searchEntities: () => void;
    softSaveEntity?: (entity: Lei) => void;
    selectedEntity?: Lei | null;
}

export const SearchEntityModal: React.FC<SearchEntityModalProps> = ({ isOpen, closeModal, searchEntities, softSaveEntity, selectedEntity }) => {
    const dispatch = useAppDispatch();
    const searchInput = useAppSelector(getSearchEntityValue);
    const searchBy = useAppSelector(getSearchEntityType);
    const isSearching = useAppSelector(getIsSearching);
    let searchResults = useAppSelector(getSearchResults);
    const isSaving = useAppSelector(getIsSaving);
    const showFuzzyAutoCompletions = useAppSelector(getShowFuzzyAutoCompletions);
    const fuzzyCompletions = useAppSelector(getFuzzyCompletions);
    const autoCompletions = useAppSelector(getAutoCompletions);

    const setSearchInput = (value: string) => dispatch(setSearchEntityValue(value));
    const setSearchBy = (type: SearchEntityBy | null) => dispatch(setSearchEntityType(type));

    const pageNumber = useAppSelector(getEntitiesSearchPageNumber);
    const totalEntities = useAppSelector(getTotalSearchEntities);
    const pageSize = useAppSelector(getEntitiesSearchPageSize);
    const nextPage = useCallback(() => { dispatch(entitiesSearchPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(entitiesSearchPaginationPrevious()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setEntitiesSearchPageSize(pageSize)); }, [dispatch]);

    const addEntity = useCallback((entity: Lei) => !isUndefined(softSaveEntity) ? softSaveEntity(entity) : dispatch(addNewLeiEntityStarted(entity)), [dispatch, softSaveEntity]);

    const searchAllEntities = useCallback(() => searchBy && searchEntities(), [searchBy, searchEntities]);

    const closeCompletionModal = useCallback(() => dispatch(fetchFuzzyAutoCompletionsSuccessful({ autoCompletions: [], fuzzyCompletions: [] })), [dispatch]);

    const closeAndReset = () => {
        dispatch(resetEntitySearch());
        closeModal();
    };

    if (selectedEntity) {
        searchResults = searchResults.map(result => result === selectedEntity ? ({ ...result, entityExists: true }) : result);
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeAndReset}
            confirmLabel='Search'
            confirm={searchAllEntities}
            confirmDisabled={!searchInput.length}
            testId='search-entities'
        >
            <LeiSearch
                setSearchInput={setSearchInput}
                setSearchBy={setSearchBy}
                pageNumber={pageNumber}
                totalEntities={totalEntities}
                pageSize={pageSize}
                nextPage={nextPage}
                previousPage={previousPage}
                updatePageSize={updatePageSize}
                addEntity={addEntity}
                isSearching={isSearching}
                searchResults={searchResults}
                searchBy={searchBy}
                searchInput={searchInput}
                searchEntities={searchAllEntities}
                isSaving={isSaving}
                showFuzzyAutoCompletions={showFuzzyAutoCompletions}
                fuzzyCompletions={fuzzyCompletions}
                autoCompletions={autoCompletions}
                closeCompletionModal={closeCompletionModal}
            />
        </ConfirmationModal>
    );
};
