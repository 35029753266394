import classnames from 'classnames';
import { isNull } from 'lodash/fp';
import React, { useCallback } from 'react';
import Modal from 'react-modal';

import { useAppSelector } from '../../../hooks/react-redux';
import { AgreementType, getAvailableDocumentNames } from '../../admin/documents/store';
import { BasicPlaybook } from '../../admin/playbook/store';
import styles from './PlaybookBookcase.module.scss';

interface BookCoverModalProps {
    isOpen: boolean;
    playbookSelected: BasicPlaybook | null;
    closeModal: () => void;
    openPlaybook: () => void;
    testId?: string
}

export const BookCoverModal: React.FC<BookCoverModalProps> = ({ isOpen, playbookSelected, closeModal, openPlaybook, testId = 'playbook-book-cover' }) => {

    const allDocumentNames = useAppSelector(getAvailableDocumentNames);

    const getDocumentNames = useCallback((ids: string[] | null) => {
        const documentNames = ids ? allDocumentNames.reduce((acc: string[], { documentNameId, documentName }) => documentNameId && ids.includes(documentNameId.toString()) ? [...acc, documentName] : acc, []) : [];
        return documentNames.join(' - ');
    }, [allDocumentNames]);

    if (!playbookSelected) {
        return null;
    }

    const { agreementTypeId, agreementType, createdBy, documentNameIds, name, versionMajor, versionMinor, isDraft } = playbookSelected;

    return (
        <Modal
            isOpen={isOpen}
            className={styles.bookCoverModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: 'flex',
                    backgroundColor: 'transparent',
                    zIndex: 10,
                    height: 'calc(100% - 90px)',
                    marginTop: '90px'
                }
            }}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            onRequestClose={closeModal}
            testId={testId}
        >
            <div
                className={classnames(styles.bookCoverWrapper, {
                    [styles.bookDefault]: isNull(agreementTypeId),
                    [styles.bookOne]: agreementTypeId === AgreementType.ISDA,
                    [styles.bookTwo]: agreementTypeId === AgreementType.CSA,
                    [styles.bookThree]: agreementTypeId === AgreementType.IM_CSA,
                    [styles.bookFour]: agreementTypeId === AgreementType.GMRA,
                    [styles.bookFive]: agreementTypeId === AgreementType.MRA,
                    [styles.bookSix]: agreementTypeId === AgreementType.GMSLA,
                    [styles.bookSeven]: agreementTypeId === AgreementType.MSLA,
                    [styles.bookEight]: agreementTypeId === AgreementType.DISTRIBUTION_AGREEMENT,
                    [styles.bookNine]: agreementTypeId === AgreementType.FUND_SIDE_LETTER,
                    [styles.bookTen]: agreementTypeId === AgreementType.CONFIDENTIALITY_AGREEMENT,
                    [styles.bookEleven]: agreementTypeId === AgreementType.INVESTMENT_MANAGEMENT,
                    [styles.bookTwelve]: agreementTypeId === AgreementType.ACCOUNT_CONTROL_AGREEMENT,
                    [styles.bookThirteen]: agreementTypeId === AgreementType.LOAN_AGREEMENT,
                    [styles.bookFourteen]: agreementTypeId === AgreementType.CLEARED_DERIVATIVES_EXECUTION_AGREEMENT,
                    [styles.bookFifteen]: agreementTypeId === AgreementType.RAF_REVIEW
                })}
                onClick={openPlaybook}
                data-testid={`${testId}-wrapper`}
            >
                <div className={styles.bookSpine} />
                <div className={styles.bookCover}>
                    <div className={styles.bookTitle} data-testid={`${testId}-title`}>{name}</div>
                    <div className={styles.authorAndVolumeWrapper}>
                        <div className={styles.divider} />
                        <div className={styles.bookAuthor} data-testid={`${testId}-author`}>{createdBy}</div>
                        <div className={styles.bookVolume} data-testid={`${testId}-volume`}>{`Vol ${versionMajor}.${versionMinor}`}</div>
                        {!!isDraft && <div className={styles.draftLabel} data-testid={`${testId}-draft`}>PUBLISHED AS DRAFT</div>}
                        <div className={styles.divider} />
                        <div className={styles.bookAgreementType} data-testid={`${testId}-agreement-type`}>{agreementType}</div>
                        <div className={styles.bookDocumentNames} data-testid={`${testId}-document-names`}>{getDocumentNames(documentNameIds)}</div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
