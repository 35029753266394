import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { forgottenPasswordReminderStarted, getAlternateAccounts, getSwitchAccountError, getSwitchPassword, getSwitchUsername, setAccountSwitchPassword, setAccountSwitchUsername, switchAccountStarted } from '../auth/login/store';
import { Dropdown, DropdownOption } from '../shared/dropdown/Dropdown';
import styles from './Home.module.scss';

export const SwitchAccount: React.FC = () => {
    const dispatch = useAppDispatch();
    const alternateUserAccounts = useAppSelector(getAlternateAccounts);
    const error = useAppSelector(getSwitchAccountError);
    const username = useAppSelector(getSwitchUsername);
    const password = useAppSelector(getSwitchPassword);

    const setPassword = useCallback((password: string) => dispatch(setAccountSwitchPassword(password)), [dispatch]);

    const alternateUsernames = useMemo(() => alternateUserAccounts.map(({ username }) => ({ value: username, label: username })), [alternateUserAccounts]);
    const clientName = useMemo(() => !isNull(username) && alternateUserAccounts.find(alternate => alternate.username === username)?.client || null, [alternateUserAccounts, username]);
    const selectedUsername = useMemo(() => !isNull(username) && alternateUsernames.find(({ value }) => value === username) || null, [alternateUsernames, username]);

    const switchAccount = useCallback(() => !isNull(username) && dispatch(switchAccountStarted(username, password)), [dispatch, username, password]);
    const attemptKeyPressLogin = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => e.key.toLowerCase() === 'enter' && switchAccount(), [switchAccount]);

    const scrollTo = useCallback(() => {
        const section = document.getElementById('forgot-password');
        section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }, []);

    const updateUsername = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = (option as DropdownOption).value;
            setTimeout(scrollTo, 100);
        }
        dispatch(setAccountSwitchUsername(value));
    }, [dispatch, scrollTo]);

    const resetPassword = useCallback(() => {
        if (username) {
            dispatch(forgottenPasswordReminderStarted(username));
        }
        setPassword('');
    }, [dispatch, username, setPassword]);

    return (
        <div className={styles.switchAccountWrapper}>
            <div className={styles.usernameWrapper}>
                <div className={styles.usernameLabel}>Username</div>
                <Dropdown
                    value={selectedUsername}
                    options={alternateUsernames}
                    isClearable={false}
                    onChange={updateUsername}
                />
            </div>
            {!isNull(username) &&
                <div className={styles.passwordWrapper}>
                    <div className={styles.passwordLabel}>Password</div>
                    <input
                        data-testid='switch-account-password-input'
                        className={styles.passwordInput}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type='password'
                        onKeyDown={attemptKeyPressLogin}
                    />
                </div>
            }
            {!isNull(clientName) &&
                <div className={styles.clientLabel}>Client: {clientName}</div>
            }
            <div className={styles.loginError} data-testid='incorrect-credentials-error'>{error && 'Failed to login: Incorrect login credentials'}</div>
            {!isNull(username) &&
                <div className={styles.resetPasswordWrapper} id='forgot-password'>
                    <div className={styles.resetPasswordLabel}>Forgot Password?</div>
                    <button
                        className={styles.resetPasswordButton}
                        onClick={resetPassword}
                        data-testid='password-reset-button'
                    >
                        Reset via Email
                    </button>
                </div>
            }
        </div>
    );
};
