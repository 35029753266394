import React from 'react';
import Modal from 'react-modal';
import { RawDraftContentState } from 'draft-js';
import { isNull, isUndefined } from 'lodash/fp';

import styles from '../shared/playbook/query/Query.module.scss';
import { PlaybookQuery } from './store';
import { Form } from '../shared/icons';
import { Button } from '../shared/button/Button';
import { Toggle } from '../shared/toggle';
import { useAppSelector } from '../../hooks/react-redux';
import { getUser, getUserHasFeaturePermission } from '../auth/login/store';
import { Conversation } from '../shared/playbook/conversation/Conversation';
import { WYSIWYG } from '../shared/wysiwyg/WYSIWYG';
import { PlaybookMessage } from '../admin/playbook/store';
import { OverflowModalHeader } from '../shared/modal/OverflowModalHeader';
import { FeaturePermission } from '../admin/users/store';

export interface QueryModalProps {
    isOpen: boolean;
    query: PlaybookQuery | null;
    queryReady: boolean;
    showSpinner: boolean;
    closeModal: () => void;
    sendQuery: () => void;
    updateQuery: (rawValue: RawDraftContentState, index: number) => void;
    toggleQueryIsReady: () => void;
    modalTitle: string;
    showSendReminder: boolean;
    sendReminder: () => void;
    queryVersionIsDifferent: boolean;
    goToDefinition: (playbookDefinitionId: number, sectionId: string) => void;
    additionalQuery: PlaybookMessage | null;
    updateAdditionalQuery: (rawValue: RawDraftContentState) => void;
    sendAdditionalQuery: () => void;
    testId?: string;
}

export const QueryModal: React.FC<QueryModalProps> = ({
    isOpen,
    query,
    queryReady,
    showSpinner,
    closeModal,
    sendQuery,
    updateQuery,
    toggleQueryIsReady,
    modalTitle,
    showSendReminder = false,
    sendReminder,
    queryVersionIsDifferent,
    goToDefinition,
    additionalQuery = null,
    updateAdditionalQuery,
    sendAdditionalQuery,
    testId = 'playbook'
}) => {

    const user = useAppSelector(getUser);
    const hasQueryPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.QUERY_PLAYBOOKS]));

    if (isNull(query)) {
        return null;
    }

    const { queryConversation, playbookDefinitionId, sectionId, resolved, raisedBy } = query;
    const newQuery = isUndefined(query.playbookQueryId);
    const isUsersQuery = raisedBy === user?.userId;
    const additionalQueryContent = isNull(additionalQuery) ? null : additionalQuery.content;
    const conversation = queryConversation.filter(message => !isNull(message));

    return (
        <Modal
            isOpen={isOpen}
            className={styles.queryModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', backgroundColor: 'transparent', zIndex: 10 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.queryModalWrapper} data-testid={`${testId}-query-modal-${sectionId}-wrapper`}>
                <OverflowModalHeader label={modalTitle} icon={Form} testId={`${testId}-query-modal-${sectionId}`} />
                <Conversation
                    conversation={conversation}
                    showSpinner={showSpinner}
                    currentUserId={user!.userId!}
                    isNewMessage={newQuery}
                    updateMessage={updateQuery}
                    testId={`${testId}-query-modal-${sectionId}`}
                />
                {hasQueryPermission && !newQuery && resolved !== 1 && isUsersQuery && !queryVersionIsDifferent &&
                    <div data-testid={`${testId}-query-modal-${sectionId}-input`}>
                        <WYSIWYG
                            content={additionalQueryContent}
                            updateContent={value => updateAdditionalQuery(value)}
                            showBorder={false}
                            height={'calc(30vh - 40px)'}
                            maxHeight={'calc(30vh - 40px)'}
                            showWrapperBorder
                        />
                    </div>
                }
                {newQuery &&
                    <div className={styles.queryToggleWrapper} data-testid={`${testId}-query-modal-${sectionId}-toggle-ready-wrapper`}>
                        <div className={styles.queryToggleLabel} data-testid={`${testId}-query-modal-${sectionId}-toggle-ready-label`}>
                            Please check when your query is ready to send
                        </div>
                        <Toggle
                            checked={queryReady}
                            onChange={toggleQueryIsReady}
                            testId={`${testId}-query-modal-${sectionId}-toggle-ready`}
                        />
                    </div>
                }
                <div className={styles.queryButtonWrapper} data-testid={`${testId}-query-modal-${sectionId}-buttons-wrapper`}>
                    <Button onClick={closeModal} label='Close' testId={`${testId}-query-modal-${sectionId}-close`} />
                    {hasQueryPermission && newQuery && <Button onClick={sendQuery} disabled={!queryReady || showSpinner} label='Send' testId={`${testId}-query-modal-${sectionId}-send`} />}
                    {hasQueryPermission && !newQuery && resolved !== 1 && !queryVersionIsDifferent && isUsersQuery && <Button onClick={sendAdditionalQuery} disabled={showSpinner} label='Send' testId={`${testId}-query-modal-${sectionId}-send-additional`} />}
                    {queryVersionIsDifferent && <Button onClick={() => goToDefinition(playbookDefinitionId, sectionId)} disabled={showSpinner} label='Go To Version' testId={`${testId}-query-modal-${sectionId}-go-to-version`} />}
                    {hasQueryPermission && showSendReminder && <Button onClick={sendReminder} disabled={!showSendReminder} label='Send Reminder' testId={`${testId}-query-modal-${sectionId}-send-reminder`} />}
                </div>
            </div>
        </Modal>
    );
};
