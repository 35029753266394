import { RawDraftContentState } from 'draft-js';
import { isEqual } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../../../hooks/useWindowResize';
import { ABSTRACT_ID } from '../../../../constants/playbooks';
import { ButtonResize } from '../../../../shared/button/ButtonResize';
import { CaretDown, Sort, Form, Text } from '../../../../shared/icons';
import { Abstract } from '../../../../shared/playbook/view/Abstract';
import { Provision } from '../../../../shared/playbook/view/Provision';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { fetchAllUsersStarted } from '../../../users/store';
import styles from '../../Playbook.module.scss';
import { ReorderDeviationsModal } from '../../ReorderDeviationsModal';
import { ReorderProvisionsModal } from '../../ReorderProvisionsModal';
import { PlaybookView, addPlaybookSection, getCurrentView, getOpenSections, getPlaybook, removePlaybookSection, toggleSection, toggleDeviation, updatePlaybookContentValue, updatePlaybookProvision, RelevantStakeholders, getOpenDeviations, addDeviationRow, deleteDeviationRow, updateSectionDeviation, collapseAllSections, toggleProvisionsOrderModalOpen, setDeviationsOrderModalOpen, DraggableTile, setDeviationsOrder, PlaybookProvision, addPlaybookSubheader, updatePlaybookSubheader, getAvailablePlaybookProvisionLinksStarted, setProvisionToLink, toggleDeleteSectionConfirmationModal, isSubheaderSection } from '../../store';
import { Subheader } from '../../../../shared/playbook/view/Subheader';
import { AvailableProvisionLinksModal } from '../../AvailableProvisionLinksModal';
import { DeletePlaybookSectionConfirmationModal } from '../../DeletePlaybookSectionConfirmationModal';

interface BuildProps {
    showIconButtons: boolean;
    testId?: string;
}

export const Build: React.FC<BuildProps> = ({ showIconButtons, testId = 'playbook-build' }) => {
    const dispatch = useAppDispatch();
    const [abstractOpen, setAbstractOpen] = useState<boolean>(false);
    const [, screenHeight] = useWindowResize();
    useFetchStarted([fetchAllUsersStarted(), getAvailablePlaybookProvisionLinksStarted()]);

    const playbook = useAppSelector(getPlaybook);
    const openSections = useAppSelector(getOpenSections);
    const openDeviations = useAppSelector(getOpenDeviations);

    const sectionOpen = useCallback((sectionId: string) => !!openSections.find(openSection => isEqual(openSection, sectionId)), [openSections]);
    const deviationOpen = useCallback((sectionId: string) => !!openDeviations.find(openDeviation => isEqual(openDeviation, sectionId)), [openDeviations]);

    const togglePlaybookSection = useCallback((sectionId: string) => dispatch(toggleSection(sectionId)), [dispatch]);
    const togglePlaybookDeviation = useCallback((sectionId: string) => dispatch(toggleDeviation(sectionId)), [dispatch]);
    const updateProvision = useCallback((sectionId: string, key: keyof PlaybookProvision, value: string | string[] | null | RawDraftContentState | RelevantStakeholders[]) => dispatch(updatePlaybookProvision(sectionId, key, value)), [dispatch]);
    const updateSubheader = useCallback((sectionId: string, value: string) => dispatch(updatePlaybookSubheader(sectionId, value)), [dispatch]);

    const { content: { abstract, sections } } = playbook;

    const setPlaybookAbstract = (value: RawDraftContentState | null) => dispatch(updatePlaybookContentValue(ABSTRACT_ID, value));

    const addSection = useCallback(() => dispatch(addPlaybookSection()), [dispatch]);
    const removeSection = useCallback((sectionId: string, hasProvisionLinks: boolean) => hasProvisionLinks ? dispatch(toggleDeleteSectionConfirmationModal(sectionId)) : dispatch(removePlaybookSection(sectionId)), [dispatch]);
    const addSubheader = useCallback(() => dispatch(addPlaybookSubheader()), [dispatch]);
    const removeSubheader = useCallback((sectionId: string) => dispatch(removePlaybookSection(sectionId)), [dispatch]);

    const addDeviation = useCallback((sectionId: string, index?: number) => dispatch(addDeviationRow(sectionId, index)), [dispatch]);
    const deleteDeviation = useCallback((sectionId: string, rowId: string) => dispatch(deleteDeviationRow(sectionId, rowId)), [dispatch]);
    const updateDeviationColumn = useCallback((sectionId: string, rowId: string, key: string, value: string | null | RawDraftContentState | number) => dispatch(updateSectionDeviation(sectionId, rowId, key, value)), [dispatch]);

    const openReorderDeviations = useCallback((sectionId: string | null) => {
        const currentDeviationOrder: DraggableTile[] = (sections.find(section => section.sectionId === sectionId) as PlaybookProvision).deviations.map(({ topic, rowId }) => ({ title: topic, id: rowId }));
        dispatch(setDeviationsOrder(currentDeviationOrder));
        dispatch(setDeviationsOrderModalOpen(sectionId));
    }, [dispatch, sections]);

    const toggleAbstractOpen = useCallback(() => setAbstractOpen(!abstractOpen), [abstractOpen]);

    const showReorderProvisions = useMemo(() => sections.length > 1, [sections]);
    const openReorderProvisions = useCallback(() => dispatch(toggleProvisionsOrderModalOpen()), [dispatch]);

    const showCollapseAll = useMemo(() => openSections.length > 1, [openSections]);
    const collapseSections = useCallback(() => {
        setAbstractOpen(false);
        dispatch(collapseAllSections());
    }, [dispatch]);

    const NAV_BAR = 100;
    const HEADER = 56;
    const TABS = 45;
    const PROVISION_BUTTON = 50;
    const WIZARD_BUTTONS = 48;
    const fixedElements = NAV_BAR + HEADER + TABS + PROVISION_BUTTON + WIZARD_BUTTONS;
    const scrollableHeight = screenHeight - fixedElements;

    const currentView = useAppSelector(getCurrentView);

    const isTableView = currentView === PlaybookView.TABLE || false;

    const openLinkModal = useCallback((sectionId: string) => dispatch(setProvisionToLink(sectionId)), [dispatch]);

    useEffect(() => () => {
        return collapseSections();
    }, [collapseSections]);

    return (
        <div className={styles.buildWrapper} data-testid={`${testId}-wrapper`}>
            <div style={{ height: scrollableHeight }}>
                <Scrollable>
                    <Abstract
                        abstract={abstract}
                        updateAbstract={setPlaybookAbstract}
                        toggleAbstractOpen={toggleAbstractOpen}
                        abstractOpen={abstractOpen}
                        testId={testId}
                    />
                    {sections.map((section, index) => isSubheaderSection(section) ?
                        (
                            <Subheader
                                key={section.sectionId}
                                subheader={section}
                                removeSection={removeSubheader}
                                updateSubheader={updateSubheader}
                                testId={`${testId}-${index}`}
                            />
                        ) : (
                            <Provision
                                key={section.sectionId}
                                section={section}
                                showDelete={sections.length > 1}
                                isTableView={isTableView}
                                sectionOpen={sectionOpen(section.sectionId)}
                                toggleSection={togglePlaybookSection}
                                updateProvision={updateProvision}
                                removeSection={removeSection}
                                isBuild
                                deviationOpen={deviationOpen(section.sectionId)}
                                toggleDeviation={togglePlaybookDeviation}
                                addDeviation={addDeviation}
                                deleteDeviationRow={deleteDeviation}
                                updateDeviationColumn={updateDeviationColumn}
                                openReorderDeviations={openReorderDeviations}
                                openLinkModal={openLinkModal}
                                testId={`${testId}-${index}`}
                            />
                        )
                    )}
                </Scrollable>
            </div>
            <div className={styles.addProvisionButton}>
                <ButtonResize showIconButton={showIconButtons} onClick={addSection} label='Add Provision' icon={Form} testId={testId} />
                <ButtonResize showIconButton={showIconButtons} onClick={addSubheader} label='Add Subheader' icon={Text} testId={testId} />
                {showReorderProvisions && <ButtonResize showIconButton={showIconButtons} icon={Sort} onClick={openReorderProvisions} label='Reorder Provisions' testId={testId} />}
                {showCollapseAll && <ButtonResize showIconButton={showIconButtons} onClick={collapseSections} icon={CaretDown} label='Collapse All' testId={testId} />}
            </div>
            <ReorderProvisionsModal />
            <ReorderDeviationsModal />
            <AvailableProvisionLinksModal />
            <DeletePlaybookSectionConfirmationModal />
        </div>
    );
};
