import { RawDraftContentState } from 'draft-js';

import { OpinionScope } from '../../admin/opinions/store';
import { DropdownOption } from '../../shared/dropdown/Dropdown';
import { TableFilters } from '../../shared/modal/TableFilterModal';
import { ColumnSort, SortOrder } from '../../shared/table/ArkTable';

export enum ClauseLibraryActionTypes {
    TOGGLE_CLAUSE_MODAL = 'TOGGLE_CLAUSE_MODAL',
    UPDATE_CLAUSE = 'UPDATE_CLAUSE',
    UPDATE_TAG_TERM = 'UPDATE_TAG_TERM',
    ADD_CLIENT_TAG_STARTED = 'ADD_CLIENT_TAG_STARTED',
    ADD_CLIENT_TAG_SUCCESSFUL = 'ADD_CLIENT_TAG_SUCCESSFUL',
    ADD_CLIENT_TAG_FAILED = 'ADD_CLIENT_TAG_FAILED',
    UPDATE_CLAUSE_TAGS = 'UPDATE_CLAUSE_TAGS',
    UPDATE_FUZZY_MATCH_RESULTS = 'UPDATE_FUZZY_MATCH_RESULTS',
    SET_FUZZY_MATCH_MODAL_OPEN = 'SET_FUZZY_MATCH_MODAL_OPEN',
    FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_STARTED = 'FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_STARTED',
    FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_SUCCESSFUL = 'FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_SUCCESSFUL',
    FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_FAILED = 'FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_FAILED',
    CHECK_FUZZY_MATCH = 'CHECK_FUZZY_MATCH',
    SET_CLAUSE_CAN_SAVE = 'SET_CLAUSE_CAN_SAVE',
    SAVE_CLAUSE_STARTED = 'SAVE_CLAUSE_STARTED',
    SAVE_CLAUSE_SUCCESSFUL= 'SAVE_CLAUSE_SUCCESSFUL',
    SAVE_CLAUSE_FAILED = 'SAVE_CLAUSE_FAILED',
    FETCH_ALL_CLAUSES_STARTED = 'FETCH_ALL_CLAUSES_STARTED',
    FETCH_ALL_CLAUSES_SUCCESSFUL = 'FETCH_ALL_CLAUSES_SUCCESSFUL',
    FETCH_ALL_CLAUSES_FAILED = 'FETCH_ALL_CLAUSES_FAILED',
    TOGGLE_CLAUSE_TABLE_VIEW = 'TOGGLE_CLAUSE_TABLE_VIEW',
    SET_CLAUSE_LIBRARY_TABLE_FILTERS = 'SET_CLAUSE_LIBRARY_TABLE_FILTERS',
    CLEAR_CLAUSE_LIBRARY_TABLE_FILTERS = 'CLEAR_CLAUSE_LIBRARY_TABLE_FILTERS',
    SET_CLAUSE_LIBRARY_TABLE_COLUMN_SORT = 'SET_CLAUSE_LIBRARY_TABLE_COLUMN_SORT',
    SET_CLAUSE_LIBRARY_PAGE_SIZE = 'SET_CLAUSE_LIBRARY_PAGE_SIZE',
    CLAUSE_LIBRARY_PAGINATION_NEXT = 'CLAUSE_LIBRARY_PAGINATION_NEXT',
    CLAUSE_LIBRARY_PAGINATION_PREVIOUS = 'CLAUSE_LIBRARY_PAGINATION_PREVIOUS',
    OPEN_SAVED_CLAUSE = 'OPEN_SAVED_CLAUSE',
    TOGGLE_DELETE_CLAUSE_MODAL = 'TOGGLE_DELETE_CLAUSE_MODAL',
    DELETE_CLAUSE_STARTED = 'DELETE_CLAUSE_STARTED',
    DELETE_CLAUSE_SUCCESSFUL = 'DELETE_CLAUSE_SUCCESSFUL',
    DELETE_CLAUSE_FAILED = 'DELETE_CLAUSE_FAILED',
    DUPLICATE_CLAUSE = 'DUPLICATE_CLAUSE',
    TOGGLE_CLAUSE_TAGS_MODAL = 'TOGGLE_CLAUSE_TAGS_MODAL',
    UPDATE_ALL_CLIENT_TAGS = 'UPDATE_ALL_CLIENT_TAGS',
    TOGGLE_DELETE_TAG_MODAL = 'TOGGLE_DELETE_TAG_MODAL',
    DELETE_CLIENT_TAG_STARTED = 'DELETE_CLIENT_TAG_STARTED',
    DELETE_CLIENT_TAG_SUCCESSFUL = 'DELETE_CLIENT_TAG_SUCCESSFUL',
    DELETE_CLIENT_TAG_FAILED = 'DELETE_CLIENT_TAG_FAILED',
    SET_SELECTED_CLIENT_TAG = 'SET_SELECTED_CLIENT_TAG',
    UPDATE_SELECTED_CLIENT_TAG = 'UPDATE_SELECTED_CLIENT_TAG',
    SET_CLIENT_TAG_CAN_SAVE = 'SET_CLIENT_TAG_CAN_SAVE',
    SAVE_CLIENT_TAG_STARTED = 'SAVE_CLIENT_TAG_STARTED',
    SAVE_CLIENT_TAG_SUCCESSFUL = 'SAVE_CLIENT_TAG_SUCCESSFUL',
    SAVE_CLIENT_TAG_FAILED = 'SAVE_CLIENT_TAG_FAILED',
    TOGGLE_TAG_COLUMN_SORT = 'TOGGLE_TAG_COLUMN_SORT',
    SET_CLIENT_TAG_FILTERS = 'SET_CLIENT_TAG_FILTERS',
    CLEAR_CLIENT_TAG_FILTERS = 'CLEAR_CLIENT_TAG_FILTERS',
    UPDATED_SELECTED_TAG_CLAUSE_USAGE = 'UPDATED_SELECTED_TAG_CLAUSE_USAGE',
    TOGGLE_CLAUSE_DESCRIPTION = 'TOGGLE_CLAUSE_DESCRIPTION',
    TOGGLE_SYSTEM_TAG_CATEGORY_MODAL = 'TOGGLE_SYSTEM_TAG_CATEGORY_MODAL',
    ADD_SYSTEM_TAG_STARTED = 'ADD_SYSTEM_TAG_STARTED',
    ADD_SYSTEM_TAG_SUCCESSFUL = 'ADD_SYSTEM_TAG_SUCCESSFUL',
    ADD_SYSTEM_TAG_FAILED = 'ADD_SYSTEM_TAG_FAILED',
    SET_TAG_MODAL_TYPE = 'SET_TAG_MODAL_TYPE',
    SET_SELECTED_TEMPLATE_TAG = 'SET_SELECTED_TEMPLATE_TAG',
    UPDATE_SELECTED_TEMPLATE_TAG = 'UPDATE_SELECTED_TEMPLATE_TAG',
    SET_TEMPLATE_TAG_CAN_SAVE = 'SET_TEMPLATE_TAG_CAN_SAVE',
    SAVE_TEMPLATE_TAG_STARTED = 'SAVE_TEMPLATE_TAG_STARTED',
    SAVE_TEMPLATE_TAG_SUCCESSFUL = 'SAVE_TEMPLATE_TAG_SUCCESSFUL',
    SAVE_TEMPLATE_TAG_FAILED = 'SAVE_TEMPLATE_TAG_FAILED',
    DELETE_TEMPLATE_TAG_STARTED = 'DELETE_TEMPLATE_TAG_STARTED',
    DELETE_TEMPLATE_TAG_SUCCESSFUL = 'DELETE_TEMPLATE_TAG_SUCCESSFUL',
    DELETE_TEMPLATE_TAG_FAILED = 'DELETE_TEMPLATE_TAG_FAILED',
    REDIRECT_TO_OPINION = 'REDIRECT_TO_OPINION_FROM_CLAUSE_LIBRARY',
    RESET_CLAUSE_TABLES = 'RESET_CLAUSE_TABLES'
}

export enum ClauseTagDropdown {
    JURISDICTION = 'OpinionJurisdiction',
    AGREEMENT = 'ClauseLibraryAgreementType',
    PRODUCT = 'ClauseLibraryProductType',
    COUNTERPARTY = 'ClauseLibraryCounterpartyType',
    PROVISION = 'ClauseLibraryProvisionType',
    MISCELLANEOUS = 'ClauseLibraryMiscellaneous',
    OPINIONS = 'ClauseLibraryOpinions'
}

export enum ClauseTableTab {
    CLIENT_CLAUSE_LIBRARY = 'My Clause Library',
    CURRENT_TEMPLATES = 'Current Templates',
    HISTORIC_TEMPLATES = 'Historic Templates',
    MISCELLANEOUS_TEMPLATE = 'Miscellaneous Templates'
}

export enum ClauseType {
    CLIENT_CLAUSE = 'client clause',
    TEMPLATE_CLAUSE = 'template clause'
}

export interface ClauseTags {
    agreementType: string[];
    jurisdiction: string[];
    counterpartyType: string[];
    productType: string[];
    provisionType: string[];
    miscellaneous: string[];
    opinions: string[];
}

export interface ClauseTagDropdownOptions {
    jurisdictionOptions: DropdownOption[];
    agreementTypeOptions: DropdownOption[];
    productTypeOptions: DropdownOption[];
    counterpartyTypeOptions: DropdownOption[];
    provisionTypeOptions: DropdownOption[];
    miscellaneousOptions: DropdownOption[];
    opinionsOptions: DropdownOption[];
    miscellaneousSystemOptions: DropdownOption[];
}

export interface Clause {
    clauseId?: number;
    title: string;
    description: string;
    content: RawDraftContentState | null;
    tags: ClauseTags;
    systemTemplate: number;
}

export interface ArkTableClause {
    clauseId: number;
    clauseTitle: string;
    clauseDescription: string;
    content: RawDraftContentState | null;
    agreementTags: string[];
    jurisdictionTags: string[];
    counterpartyTags: string[];
    productTags: string[];
    provisionTags: string[];
    miscellaneousTags: string[];
    opinionsTags: string[];
    systemTemplate: number;
    opinionLinks?: boolean;
}

export interface FuzzyMatchTags {
    key: keyof ClauseTags;
    value: string;
    score: number;
}

export interface ClientTag {
    tag: string;
    clauseTagId: number;
    category: keyof ClauseTags;
}

export interface CurrentClientTag {
    clauseTagId: number;
    tag: string;
    category: keyof ClauseTags;
    includedClauses: number[];
    clausesRemoved: number[];
    clausesAdded: number[];
}

export enum ClientTagTableColumns {
    TAG_LABEL = 'tag',
    TAG_CATEGORY = 'category',
    TAG_CLAUSE_COUNT = 'usage'
}

export interface ClientTagColumnSort {
    column: ClientTagTableColumns;
    order: SortOrder;
}

export interface SelectedSystemTag {
    tag: string;
    category: keyof ClauseTags;
}

export interface LinkedClauseOpinion {
    clauseId: number;
    jurisdiction: string;
    scope: OpinionScope;
    focus: string;
    opinionId: number;
    location: string;
}

export interface ClauseLibraryState {
    clauseModalOpen: boolean;
    clause: Clause;
    currentClause: Clause | null;
    tagTerm: string;
    fuzzyMatchResults: FuzzyMatchTags[];
    fuzzyMatchModalOpen: boolean;
    clauseLibraryDropdownOptions: ClauseTagDropdownOptions;
    clauseCanSave: boolean;
    clauseTableTab: ClauseTableTab;
    isLoading: boolean;
    allClientClauses: Clause[];
    allTemplateClauses: Clause[];
    allHistoricTemplateClauses: Clause[];
    allMiscellaneousTemplateClauses: Clause[];
    clientClausePage: number;
    totalClientClauses: number;
    clientClauseFilters: TableFilters;
    clientClauseSort?: ColumnSort;
    clientClausePageSize: number;
    templateClausePage: number;
    totalTemplateClauses: number;
    templateClauseFilters: TableFilters;
    templateClauseSort?: ColumnSort;
    templateClausePageSize: number;
    historicTemplateClausePage: number;
    totalHistoricTemplateClauses: number;
    historicTemplateClauseFilters: TableFilters;
    historicTemplateClauseSort?: ColumnSort;
    historicTemplateClausePageSize: number;
    miscellaneousTemplateClausePage: number;
    totalMiscellaneousTemplateClauses: number;
    miscellaneousTemplateClauseFilters: TableFilters;
    miscellaneousTemplateClauseSort?: ColumnSort;
    miscellaneousTemplateClausePageSize: number;
    clauseToDelete: Clause | null;
    clauseTagsModalOpen: boolean;
    allClientTags: ClientTag[];
    tagToDelete: CurrentClientTag | null;
    selectedClientTag: CurrentClientTag | null;
    currentSelectedClientTag: CurrentClientTag | null;
    clientTagModalCanSave: boolean;
    clientTagColumnSort?: ClientTagColumnSort;
    clientTagFilters: TableFilters;
    clauseDescriptionOpen: boolean;
    systemTagCategoryModalOpen: boolean;
    tagModalType: ClauseType | null;
    selectedTemplateTag: SelectedSystemTag | null;
    currentSelectedTemplateTag: SelectedSystemTag | null;
    templateTagModalCanSave: boolean;
    savingTemplateTag: boolean;
    templateTagToDelete: SelectedSystemTag | null;
    linkedClauseOpinions: LinkedClauseOpinion[];
}
