import React from 'react';

export const Robot: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill={color || 'currentColor'} d="M105.477,62.25h-6.7A36.99,36.99,0,0,0,83.989,43.208V26.83a6.578,6.578,0,1,0-3.5,0V41.192a36.907,36.907,0,0,0-32.978-.107V26.83a6.578,6.578,0,1,0-3.5,0V43.074a37.085,37.085,0,0,0-6.08,4.886,36.708,36.708,0,0,0-8.924,14.29H22.523a5.9,5.9,0,0,0-5.891,5.892V88.759a5.9,5.9,0,0,0,5.891,5.891h4.541V106a8.684,8.684,0,0,0,8.674,8.674H92.051A8.683,8.683,0,0,0,100.724,106V94.65h4.753a5.9,5.9,0,0,0,5.891-5.891V68.142A5.9,5.9,0,0,0,105.477,62.25ZM79.161,20.5a3.078,3.078,0,1,1,3.078,3.078A3.082,3.082,0,0,1,79.161,20.5Zm-36.478,0a3.078,3.078,0,1,1,3.078,3.078A3.081,3.081,0,0,1,42.683,20.5ZM22.523,91.15a2.394,2.394,0,0,1-2.391-2.391V68.142a2.394,2.394,0,0,1,2.391-2.392h5.493a37.046,37.046,0,0,0-.952,8.325V91.15Zm26.952,20.026V91.209a3.246,3.246,0,0,1,3.242-3.243H75.28a3.246,3.246,0,0,1,3.242,3.243v19.967ZM97.224,106a5.18,5.18,0,0,1-5.173,5.174H82.022V91.209a6.75,6.75,0,0,0-6.742-6.743H52.717a6.75,6.75,0,0,0-6.742,6.743v19.967H35.738A5.181,5.181,0,0,1,30.564,106V74.075a33.432,33.432,0,0,1,1.392-9.566,1.809,1.809,0,0,0,.051-.182A33.535,33.535,0,0,1,46.7,45.514l.053-.033A33.339,33.339,0,0,1,97.224,74.075Zm10.644-17.243a2.394,2.394,0,0,1-2.391,2.391h-4.753V74.075a37.1,37.1,0,0,0-.951-8.325h5.7a2.394,2.394,0,0,1,2.391,2.392Z" />
        <path fill={color || 'currentColor'} d="M48.458,56.906h-.092a9.093,9.093,0,0,0-.09,18.185h.095a9.093,9.093,0,0,0,.087-18.185Zm3.824,13.087a5.667,5.667,0,0,1-3.97,1.6,5.593,5.593,0,0,1,.054-11.185h.057a5.593,5.593,0,0,1,3.859,9.587Z" />
        <path fill={color || 'currentColor'} d="M79.542,56.906a9.093,9.093,0,0,0,.087,18.185h.095a9.094,9.094,0,0,0,9-9.183A9.125,9.125,0,0,0,79.542,56.906Zm4.085,13.008a5.593,5.593,0,1,1-4.05-9.508h.056a5.593,5.593,0,0,1,3.994,9.508Z" />
        <path fill={color || 'currentColor'} d="M71.4,93.643h-15a1.75,1.75,0,0,0,0,3.5h15a1.75,1.75,0,1,0,0-3.5Z" />
        <path fill={color || 'currentColor'} d="M71.4,103.064h-15a1.75,1.75,0,0,0,0,3.5h15a1.75,1.75,0,1,0,0-3.5Z" />
    </svg>
);
