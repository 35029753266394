import React from 'react';

import styles from './HomeWrapper.module.scss';
import { TopNav } from './TopNav';
import { LeftMenu } from './LeftMenu';
import { RightMenu } from './RightMenu';
import { UnauthorisedModuleModal } from './UnauthorisedModuleModal';
import { UserNotPermittedModal } from './UserNotPermittedModal';

interface HomeWrapperProps {
    children?: React.ReactNode;
}

export const HomeWrapper: React.FC<HomeWrapperProps> = ({ children }) => {
    return (
        <div data-testid='home-wrapper' className={styles.wrapper}>
            <div className={styles.main}>
                <TopNav />
                <div className={styles.content}>
                    {children}
                </div>
            </div>
            <LeftMenu />
            <RightMenu />
            <UnauthorisedModuleModal />
            <UserNotPermittedModal />
        </div>
    );
};
