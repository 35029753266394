import React from 'react';

import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { User } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { updateValue } from './store';
import { getAllClients } from '../clients/store';
import { Toggle } from '../../shared/toggle';
import styles from './DocumentName.module.scss';
import { Scrollable } from '../../shared/scrollable/Scrollable';

const { white, grey } = styles;

interface ClientDatasetModalProps {
    isOpen: boolean;
    closeModal: () => void;
    updateDocumentName: () => void;
    selectedClientIds: number[];
}

export const ClientDatasetModal: React.FC<ClientDatasetModalProps> = ({ isOpen, closeModal, updateDocumentName, selectedClientIds }) => {
    const clients = useAppSelector(getAllClients);
    const dispatch = useAppDispatch();

    const updateSelectedClientIds = (checked: boolean, clientId: number) => {
        const clientIds = checked ? [...selectedClientIds, clientId] : selectedClientIds.filter(id => id !== clientId);
        dispatch(updateValue('clientIds', clientIds));
    };

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel='Save'
            testId='client-dataset-confirmation'
            confirm={updateDocumentName}
        >
            <div className={styles.clientDatasetModalWrapper} data-testid='client-dataset-confirmation-wrapper'>
                <ModalHeader label='Add Document to Clients' icon={User} testId='client-dataset-confirmation' />
                <div className={styles.clientOptionsWrapper}>
                    <div className={styles.clientOptionsTitle}>Select the clients you would like to grant access to this document and dataset</div>
                    <div className={styles.clientOptionsTable}>
                        <Scrollable maxHeight='150px'>
                            {clients.map(({ clientId, clientName }, index) => (
                                <div className={styles.clientOptionRow} key={index} style={{ backgroundColor: index % 2 ? grey : white }}>
                                    <div className={styles.clientName}>{clientName}</div>
                                    <Toggle
                                        checked={selectedClientIds.includes(clientId!)}
                                        onChange={checked => updateSelectedClientIds(checked, clientId!)}
                                    />
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </div>
            </div>
        </ConfirmationModal>
    );
};
