import React from 'react';

export const Calculator: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <g>
            <path fill={color || 'currentColor'} d="M103.061,16.823a10.818,10.818,0,0,0-7.7-3.189H32.638A10.886,10.886,0,0,0,21.75,24.521v78.956a10.886,10.886,0,0,0,10.888,10.889H95.362a10.888,10.888,0,0,0,10.888-10.889V24.521A10.815,10.815,0,0,0,103.061,16.823ZM100.587,108.7a7.347,7.347,0,0,1-5.225,2.164H32.638a7.387,7.387,0,0,1-7.388-7.389V24.521a7.387,7.387,0,0,1,7.388-7.387H95.362a7.387,7.387,0,0,1,7.388,7.387C101.983,107.1,104.472,104.814,100.587,108.7Z" />
            <path fill={color || 'currentColor'} d="M42.75,55.6a9,9,0,1,0,9,9A9.01,9.01,0,0,0,42.75,55.6Zm0,14.5a5.5,5.5,0,1,1,5.5-5.5A5.506,5.506,0,0,1,42.75,70.1Z" />
            <path fill={color || 'currentColor'} d="M35.5,44.963h57a1.749,1.749,0,0,0,1.75-1.75v-17.5a1.75,1.75,0,0,0-1.75-1.75h-57a1.75,1.75,0,0,0-1.75,1.75v17.5A1.749,1.749,0,0,0,35.5,44.963Zm1.75-17.5h53.5v14H37.25Z" />
            <path fill={color || 'currentColor'} d="M86.249,55.6a8.993,8.993,0,0,0-9,9V87.7a9,9,0,0,0,18,0V64.6A8.994,8.994,0,0,0,86.249,55.6Zm5.5,32.1a5.5,5.5,0,0,1-11,0V64.6a5.5,5.5,0,0,1,11,0Z" />
            <path fill={color || 'currentColor'} d="M64.5,78.7a9,9,0,1,0,9,9A9.01,9.01,0,0,0,64.5,78.7Zm0,14.5A5.5,5.5,0,1,1,70,87.7,5.507,5.507,0,0,1,64.5,93.2Z" />
            <path fill={color || 'currentColor'} d="M42.75,78.7a9,9,0,1,0,9,9A9.01,9.01,0,0,0,42.75,78.7Zm0,14.5a5.5,5.5,0,1,1,5.5-5.5A5.507,5.507,0,0,1,42.75,93.2Z" />
            <path fill={color || 'currentColor'} d="M64.5,55.6a9,9,0,1,0,9,9A9.01,9.01,0,0,0,64.5,55.6Zm0,14.5A5.5,5.5,0,1,1,70,64.6,5.507,5.507,0,0,1,64.5,70.1Z" />
        </g>
    </svg>
);
