import React from 'react';

export const Download: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={8} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="63.2" y1="85.6" x2="63.2" y2="13" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={8} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="35.2" y1="61.2" x2="63.2" y2="90" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={8} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M11.1,83.3l0.4,24.3c0.1,5.4,4.5,9.6,9.8,9.6h85.8c5.4,0,9.8-4.4,9.8-9.8V83.3" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={8} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="91.8" y1="61.2" x2="63.7" y2="90" />
    </svg>
);
