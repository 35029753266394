import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppSelector } from '../../../hooks/react-redux';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { CheckList, OfficeBuilding } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { OverflowTooltip } from '../../shared/tooltip';
import { getAllDoraFunctions } from '../functions/store';
import styles from './MyCompanies.module.scss';
import { FunctionMigration } from './store';

interface MigrateFunctionConfirmationModalProps {
    isOpen: boolean;
    functionMigration: FunctionMigration | null;
    isCompanyMigration: boolean;
    closeModal: () => void;
    saveAndClose: () => void;
    updateFunctionMigrations: (functionId: number, value: number | null) => void;
}

export const MigrateFunctionConfirmationModal: React.FC<MigrateFunctionConfirmationModalProps> = ({
    isOpen,
    functionMigration,
    isCompanyMigration,
    closeModal,
    saveAndClose,
    updateFunctionMigrations
}) => {
    const doraFunctions = useAppSelector(getAllDoraFunctions);

    const migrationSelected = useMemo(() => {
        if (isNull(functionMigration)) {
            return false;
        }
        return functionMigration.migrations.some(({ newCompanyId }) => !isNull(newCompanyId));
    }, [functionMigration]);

    const confirmLabel = useMemo(() => migrationSelected ? 'Migrate' : 'Confirm', [migrationSelected]);

    const multipleMigrationOptions = useMemo(() => !isNull(functionMigration) && functionMigration.migrations.length > 1, [functionMigration]);

    const functionWarning = useMemo(() => `Are you sure you want to remove ${multipleMigrationOptions ? 'these functions?' : 'this function?'}`, [multipleMigrationOptions]);
    const functionMessage = useMemo(() => 'These functions would lose supply chain information, would you like to migrate any to different companies instead of clearing supply chain data?', []);
    const companyWarning = useMemo(() => 'Are you sure you want to mark this company as not in scope for Dora?', []);
    const companyMessage = useMemo(() => `This company currently performs the following ${multipleMigrationOptions ? 'functions which are in a supply chain' : 'function that is part of a supply chain'}, marking this as out of scope will affect the supply chain.`, [multipleMigrationOptions]);
    const warning = useMemo(() => isCompanyMigration ? companyWarning : functionWarning, [companyWarning, isCompanyMigration, functionWarning]);
    const message = useMemo(() => isCompanyMigration ? companyMessage : functionMessage, [companyMessage, isCompanyMigration, functionMessage]);
    const icon = useMemo(() => isCompanyMigration ? OfficeBuilding : CheckList, [isCompanyMigration]);

    const updateFunctionMigration = useCallback((functionId: number, dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = parseInt((dropdownValue as DropdownOption).value);
            updateFunctionMigrations(functionId, value);
        } else {
            updateFunctionMigrations(functionId, null);
        }
    }, [updateFunctionMigrations]);

    if (isNull(functionMigration)) {
        return null;
    }

    const getFunctionDropdownValue = (id: number) => {
        const newCompanyId = functionMigration.migrations.find(({ functionId }) => functionId === id)!.newCompanyId;
        if (!isNull(newCompanyId)) {
            return functionMigration.migrations.find(({ functionId }) => functionId === id)!.options.find(({ value }) => value === newCompanyId.toString())!;
        }
        return null;
    };
    const getFunctionDropdownOptions = (id: number) => functionMigration.migrations.find(({ functionId }) => functionId === id)!.options;
    const getFunctionName = (functionId: number) => doraFunctions.find(({ doraFunctionId }) => doraFunctionId === functionId)!.name;

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={saveAndClose}
            confirmLabel={confirmLabel}
            closeLabel='Close'
            testId='remove-dora-function'
            showOverlay={false}
        >
            <div className={styles.deleteFunctionConfirmationWrapper}>
                <ModalHeader icon={icon} label='DORA Supply Chain Function Migration' testId='migrate-dora-function-confirmation-modal-header' />
                <div className={styles.deleteFunctionWarning}>{warning}</div>
                {functionMigration.migrations.length > 0 &&
                    <div className={styles.functionUsageWrapper}>
                        <div className={styles.functionUsageLabel}>{message}</div>
                        <div className={styles.functionUsageLabel}>{`Please select the new company you would like to migrate the DORA function${multipleMigrationOptions ? 's' : ''} to`}</div>
                        <div className={styles.scrollableWrapper}>
                            <Scrollable maxHeight='200px'>
                                {functionMigration.migrations.map(({ functionId }) => (
                                    <div className={styles.companyWrapper} key={functionId}>
                                        <OverflowTooltip className={styles.companyName} overlayText={getFunctionName(functionId)} />
                                        <Dropdown
                                            value={getFunctionDropdownValue(functionId)}
                                            menuPortalTarget={document.body}
                                            options={getFunctionDropdownOptions(functionId)} onChange={(value) => updateFunctionMigration(functionId, value)} label='' menuPlacement='bottom' />
                                    </div>
                                ))}
                            </Scrollable>
                        </div>
                    </div>
                }
            </div>
        </ConfirmationModal>
    );
};
