import React, { MouseEvent } from 'react';

import { Icon as ArkIcon } from '../icon/Icon';
import { Hourglass } from '../spinner/Hourglass';
import styles from './Button.module.scss';

interface HourglassButtonProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    showHourglass: boolean;
    icon: React.FC;
    disabled?: boolean;
    marginRight?: string;
    marginLeft?: string;
    fontSize?: number;
    testId?: string;
    color?: string;
}

export const HourglassButton: React.FC<HourglassButtonProps> = ({ onClick, disabled = false, fontSize = 28, icon, testId = '', color, marginRight = '0px', marginLeft = '0px', showHourglass }) => (
    <button
        onClick={onClick}
        className={styles.hourglassButton}
        style={{ color, marginRight, marginLeft }}
        disabled={disabled}
        data-testid={`hourglass-button-${testId}`}
    >
        <ArkIcon icon={icon} fontSize={fontSize} />
        {showHourglass && <div className={styles.hourglass} style={{ left: `${fontSize - 12}px`, top: `${fontSize / 2}px` }}><Hourglass /></div>}
    </button>
);
