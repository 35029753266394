import { max, set, flow } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { select, geoMercator, geoPath, selectAll, GeoProjection, zoom, GeoPermissibleObjects, zoomIdentity, easeLinear } from 'd3';

import { Country } from '../../../documents/analytics/store';
import styles from '../Opinions.module.scss';
import { OpinionByCountry, OpinionsJurisdictionList } from '../store';
import { unitedKingdom } from '../../../constants/worldCountriesList';

const {
    lightestGrey,
    lightGrey,
    fontFamily,
    primary,
    primaryBorder,
    permittedToView,
    oceanBlue,
    gold
} = styles;

interface OpinionsMapProps {
    height: number;
    width: number;
    worldGeometry: Country[];
    graphData: OpinionsJurisdictionList[];
    selectedCountry?: Country;
    onDblClick: (countryName: string, opinions: OpinionByCountry[]) => void;
    resetZoom: boolean;
    zoomSteps: number;
    zoomUpdated: boolean;
    mapZoomExtent: (value: number) => void;
    scaleExtent: [number, number];
}

interface MapData extends Country {
    opinions: OpinionByCountry[];
    disabled: boolean;
    published: boolean;
}

export const OpinionsMap: React.FC<OpinionsMapProps> = ({
    height,
    width,
    worldGeometry,
    graphData,
    selectedCountry,
    onDblClick,
    resetZoom,
    zoomSteps,
    zoomUpdated,
    mapZoomExtent,
    scaleExtent
}) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const tooltipContent = useCallback((tooltipValue: string) => `<tspan dy=12 x=10>${tooltipValue}</tspan>`, []);

    const getTooltip = useCallback((name: string, value: number) => `${name}: ${value}`, []);
    const getTooltipWidth = useCallback((tooltipValue: string) => max([(`${tooltipValue}`.length * 8) + 10, 100])!, []);

    const getValue = useCallback((id: string) => graphData.find(({ geoId }) => geoId === id)?.numberOfOpinions || 0, [graphData]);
    const getOpinionsValue = useCallback((id: string) => graphData.find(({ geoId }) => geoId === id)?.opinions || [], [graphData]);
    const getOpinionsJurisdictionDisabled = useCallback((id: string) => graphData.find(({ geoId }) => geoId === id)?.opinionDisabled || false, [graphData]);
    const getOpinionsJurisdictionPublished = useCallback((id: string) => graphData.find(({ geoId }) => geoId === id)?.published || false, [graphData]);

    const data: MapData[] = useMemo(() => worldGeometry.map(country => flow(
        set('value', getValue(country.id)),
        set('opinions', getOpinionsValue(country.id)),
        set('disabled', getOpinionsJurisdictionDisabled(country.id)),
        set('published', getOpinionsJurisdictionPublished(country.id)),
    )(country)), [getValue, worldGeometry, getOpinionsValue, getOpinionsJurisdictionDisabled, getOpinionsJurisdictionPublished]);

    useEffect(() => {
        const svg = select<SVGSVGElement, unknown>(svgRef.current!);

        const projection: GeoProjection = geoMercator()
            .scale(500)
            .center([0, 60])
            .translate([width / 1.5, height / 50]);

        const countryLines = geoPath().projection(projection);

        const map = svg
            .selectAll('path')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'map')
            .attr('id', 'opinions-jurisdiction')
            .attr('overflow', 'hidden');

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const handleZoom = ({ transform }: any) => {
            map.attr('transform', transform);
            mapZoomExtent(transform.k);
        };

        const mapZoom = zoom<SVGSVGElement, unknown>()
            .extent([[0, 0], [width, height]])
            .scaleExtent(scaleExtent)
            .on('zoom', handleZoom);

        svg.call(mapZoom);

        const mapZoomIn = () => {
            svg.transition().call(mapZoom.scaleBy, 1.2);
        };
        const mapZoomOut = () => {
            svg.transition().call(mapZoom.scaleBy, 0.8);
        };

        if ((resetZoom && !selectedCountry) || (zoomSteps === 0 && !selectedCountry)) {
            // Coordinates for the UK, this ensures we are zoomed in to the same place each time we reload
            // and also after we clear the dropdown or reset the zoom it resets to this position
            const [[x0, y0], [x1, y1]] = countryLines.bounds(unitedKingdom as GeoPermissibleObjects);
            const minScale = 0.2 / Math.max((x1 - x0) / width, (y1 - y0) / height);
            const scale = Math.min(5, minScale < scaleExtent[0] ? scaleExtent[0] : minScale);
            svg.transition().duration(750).call(
                mapZoom.transform,
                zoomIdentity
                    .translate(width / 2, height / 2)
                    .scale(scale)
                    .translate(-(x0 + x1) / 2, -(y0 + y1) / 2)
            );
        } else {
            zoomSteps > 0 ? mapZoomIn() : mapZoomOut();
        }

        map
            .append('path')
            .attr('d', (d: Country, e) => countryLines(d as GeoPermissibleObjects, e))
            .attr('fill', (d: MapData) => {
                if (d.opinions.length === 0) {
                    return lightestGrey;
                }
                if (d.disabled) {
                    return lightGrey;
                }
                if (d.published) {
                    return permittedToView;
                }
                return gold;
            })
            .attr('stroke', primaryBorder)
            .attr('id', (d: Country) => `jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
            .attr('class', 'primary')
            .transition().delay((_, i) => i * 950).duration(250).ease(easeLinear);

        map
            .on('mouseover', (_, d: Country) => {
                tooltip.attr('display', null);
                selectAll(`#jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                    .transition()
                    .duration(10)
                    .attr('opacity', 0.9)
                    .attr('stroke', primary);
            })
            .on('mouseout', (_, d: Country) => {
                tooltip.attr('display', 'none');
                selectAll(`#jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                    .transition()
                    .duration(10)
                    .attr('opacity', 1)
                    .attr('stroke', primaryBorder);
            })
            .on('mousemove', (_, d: Country) => {
                const tooltipValue = getTooltip(d.properties.name, d.value!);
                tooltip.select('rect')
                    .attr('height', 25)
                    .attr('width', getTooltipWidth(tooltipValue));
                tooltip.select('text')
                    .html(tooltipContent(tooltipValue));
            })
            .on('click', (_, d) => {
                const [[x0, y0], [x1, y1]] = countryLines.bounds(d as GeoPermissibleObjects);
                const minScale = 0.6 / Math.max((x1 - x0) / width, (y1 - y0) / height);
                const scale = Math.min(5, minScale < scaleExtent[0] ? scaleExtent[0] : minScale);
                svg.transition().duration(750).call(
                    mapZoom.transform,
                    zoomIdentity
                        .translate(width / 2, height / 2)
                        .scale(scale)
                        .translate(-(x0 + x1) / 2, -(y0 + y1) / 2)
                );
            })
            .on('dblclick', (_, d) => {
                if (d.value) {
                    onDblClick(d.properties.name, d.opinions);
                }
            });

        if (selectedCountry && !zoomUpdated) {
            const [[x0, y0], [x1, y1]] = countryLines.bounds(selectedCountry as GeoPermissibleObjects);
            const minScale = 0.6 / Math.max((x1 - x0) / width, (y1 - y0) / height);
            const scale = Math.min(5, minScale < scaleExtent[0] ? scaleExtent[0] : minScale);
            svg.transition().duration(750).call(
                mapZoom.transform,
                zoomIdentity
                    .translate(width / 2, height / 2)
                    .scale(scale)
                    .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
            );
            // Transition in and out of stroke opacity to help highlight the selected country when zoomed
            map.
                selectAll(`#jurisdiction-${selectedCountry.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                .transition()
                .duration(3000)
                .attr('opacity', 0.8)
                .attr('stroke', primary)
                .attr('stroke-opacity', 1)
                .transition()
                .duration(300)
                .attr('opacity', 1)
                .attr('stroke', primary)
                .attr('stroke-opacity', 0.2);
        }

        const tooltip = svg
            .append('g')
            .attr('class', 'tooltip')
            .attr('data-testid', 'opinions-world-map-tooltip')
            .attr('display', 'none')
            .attr('transform', `translate(0, ${height - 25})`);

        tooltip
            .append('rect')
            .attr('rx', 5)
            .attr('fill', lightestGrey);

        tooltip
            .append('text')
            .attr('x', 10)
            .attr('y', 4)
            .attr('fill', primary)
            .attr('text-anchor', 'start')
            .attr('font-size', '14px')
            .attr('font-family', fontFamily)
            .attr('font-weight', 600);

        return () => {
            // Anything added to the svg on mount via .append must be removed on unmount to stop new elements being added each render
            svg.selectAll('.tooltip').remove();
            svg.selectAll('.map').remove();
        };

    }, [height, width, tooltipContent, getTooltip, getTooltipWidth, data, selectedCountry, onDblClick, resetZoom, zoomSteps, zoomUpdated, mapZoomExtent, scaleExtent]);

    return (
        <div className={styles.jurisdictionChart}>
            <div className={styles.svgWrapper} data-testid='opinions-jurisdiction-map-wrapper' style={{ height, width }}>
                <svg ref={svgRef} style={{ height, width, overflow: 'hidden', borderRadius: '5px', cursor: 'pointer', backgroundColor: oceanBlue }} />
            </div>
        </div>
    );
};
