import { ApplicationState } from '../../../../store/rootReducer';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';
import { AdminAnalyticsChart, AdminAnalyticsState, AdminAttestationAnalytics } from './types';

const getRoot = (state: ApplicationState): AdminAnalyticsState => state.admin.analytics;

export const getFetchingAnalytics = (state: ApplicationState): AdminAnalyticsChart[] => getRoot(state).fetchingAnalytics;

export const getAttestationProgressAnalytics = (state: ApplicationState): AdminAttestationAnalytics[] => getRoot(state).attestationProgress;

export const getClientPageUsageAnalytics = (state: ApplicationState): PieChartAnalyticsData[] => getRoot(state).pageUsageAnalytics;
