import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DatasetType } from '../../../datasets/store';
import { DragDrop } from '../../../shared/drag-n-drop/DragDrop';
import { DraggableItem } from '../../../shared/drag-n-drop/shared';
import styles from '../Reports.module.scss';
import { DocumentReportField, getReportFields, updateDocumentReportFields } from '../store';

const { amber } = styles;

interface FieldColumnProps {
    id: string;
    label: string;
    datasetType: DatasetType;
    index: number;
    isDragging?: boolean;
}

const FieldColumn: React.FC<FieldColumnProps> = ({ label, datasetType, isDragging = false }) => (
    <div className={classnames(styles.columnWrapper, {
        [styles.draggingColumn]: isDragging,
        [styles.tableColumn]: datasetType === DatasetType.TABLE,
        [styles.formColumn]: datasetType === DatasetType.FORM
    })}>
        <div className={styles.columnLabel}>{label}</div>
    </div>
);

export const ConfigureFields: React.FC = () => {
    const reportFields = useAppSelector(getReportFields);
    const dispatch = useAppDispatch();

    const updateFields = useCallback((fields: DocumentReportField[]) => dispatch(updateDocumentReportFields(fields)), [dispatch]);

    const listOptions = useMemo(() => reportFields.map(({ id, label }) => ({ id, label })), [reportFields]);

    const updateList = useCallback((list: DraggableItem[]) => {
        const newFields = list.map(({ id }) => reportFields.find(field => field.id === id)!);
        updateFields(newFields);
    }, [updateFields, reportFields]);

    const getChildElement = useCallback((childId: string, index: number) => {
        const field = reportFields.find(({ id }) => id === childId);
        if (field) {
            const { id, label, datasetType } = field;
            return (
                <FieldColumn id={id} label={label} index={index} datasetType={datasetType} />
            );
        }
        return null;
    }, [reportFields]);

    const getDraggingElement = useCallback((childId: string, index: number) => {
        const field = reportFields.find(({ id }) => id === childId)!;
        const { id, label, datasetType } = field;
        return (
            <FieldColumn id={id} label={label} index={index} datasetType={datasetType} isDragging />
        );
    }, [reportFields]);

    return (
        <div className={styles.configureFieldsWrapper}>
            <div className={styles.fieldsHeader}>Configure the order you would like to display the selected columns</div>
            <div className={styles.reportDroppableWrapper} data-testid='document-reporting-droppable-wrapper'>
                <DragDrop
                    getChildElement={getChildElement}
                    list={listOptions}
                    listId='document-reporting'
                    updateList={updateList}
                    type='horizontal'
                    getDraggingElement={getDraggingElement}
                    edgeColour={amber}
                    edgeWidth={3}
                />
            </div>
        </div>
    );
};
