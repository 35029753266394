import download from 'downloadjs';
import { flow, isEqual, isNull, isUndefined, set, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, debounce, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { analyzeDocument, completeInitialAI, deleteDocument, downloadAllDocuments, downloadDocument, exportAllToCSV, exportDocumentToCDM, exportDocumentToCSV, fetchAllBaseDocuments, fetchAllIncompleteDocuments, fetchAllOriginalDocuments, fetchDocumentsByAgreementType, fetchDocumentsLinkedToOriginalDocument, fetchMLData, getDocumentById, linkDocumentToOriginal, openDocument, renameDocumentDescriptions, runLatestMLQueries, updateDocumentDetails, uploadDocumentInAnalysis, uploadDocuments, fetchAllPreExecutionDocuments } from '../../../../services/document';
import { upsertMLEntity } from '../../../../services/entity';
import { getDocumentUrl } from '../../../../utils/getDocumentUrl';
import { updateIncompleteDocumentWithMLData } from '../../../../utils/mlData/updateIncompleteDocumentWithMLData';
import { dotsToDashes } from '../../../../utils/regex-utils';
import { AgreementType } from '../../../admin/documents/store';
import { fetchAllDropdownListsStarted } from '../../../admin/dropdown-lists/store';
import { BaseEntity, EntityType, fetchAllEntitiesStarted, fetchAllEntitiesSuccessful } from '../../../admin/entity/store';
import { initialCompanyEntity } from '../../../constants/entity';
import { AnnexDefinition, AnnexDefinitionDB, toggleAnnexBuilderModal, toggleAnnexDefinitionModal } from '../../../agency-annex/store';
import { CustomFilters, DocumentCustomFilters, FilterTable, getAllDocumentCustomFilters, getPathname } from '../../../auth/login/store';
import { getShowLegacy, openDatasetDefinitionStarted, openDatasetInstanceById } from '../../../datasets/instances/store';
import { MLData } from '../../../datasets/store/mlTypes';
import { TableFilters, TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import {
    createNewEntityFromMLDataFailed,
    createNewEntityFromMLDataStarted,
    createNewEntityFromMLDataSuccessful,
    deleteDocumentFailed,
    deleteDocumentStarted,
    deleteDocumentSuccessful,
    documentAnalysisComplete,
    documentAnalysisFailed,
    documentDescriptionsHaveUpdated,
    documentDetailsHaveUpdated,
    downloadAllDocumentsFailed,
    downloadAllDocumentsStarted,
    downloadAllDocumentsSuccessful,
    downloadCDMPreview,
    downloadDocumentFailed,
    downloadDocumentStarted,
    downloadDocumentSuccessful,
    exportAllCSVFailed,
    exportAllCSVSuccessful,
    exportToCDMFailed,
    exportToCDMStarted,
    exportToCDMSuccessful,
    exportToCSVFailed,
    exportToCSVStarted,
    exportToCSVSuccessful,
    fetchBaseDocumentsSuccessful,
    fetchDocumentsFailed,
    fetchDocumentsStarted,
    fetchIncompleteDocumentsFailed,
    fetchIncompleteDocumentsStarted,
    fetchIncompleteDocumentsSuccessful,
    fetchAgreementTypeDocumentsSuccessful,
    fetchLinkedDocumentsFailed,
    fetchLinkedDocumentsStarted,
    fetchLinkedDocumentsSuccessful,
    fetchOriginalDocumentsSuccessful,
    fetchPreExecutionDocumentsFailed,
    fetchPreExecutionDocumentsStarted,
    fetchPreExecutionDocumentsSuccessful,
    initialAIFailed,
    initialAIStarted,
    initialAISuccessful,
    linkDocumentFailed,
    linkDocumentStarted,
    linkDocumentSuccessful,
    openDocumentAndInstance,
    openDocumentFailed,
    openDocumentStarted,
    openDocumentSuccessful,
    openIncompleteDocumentFailed,
    openIncompleteDocumentStarted,
    openIncompleteDocumentSuccessful,
    openSecondaryDocumentFailed,
    openSecondaryDocumentStarted,
    openSecondaryDocumentSuccessful,
    openSupportDocumentFailed,
    openSupportDocumentStarted,
    openSupportDocumentSuccessful,
    reanalyzeDocumentFailed,
    reanalyzeDocumentStarted,
    renameDocumentsSuccessful,
    runLatestMLQueriesFailed,
    runLatestMLQueriesStarted,
    runLatestMLQueriesSuccessful,
    setInitialUploadDocuments,
    toggleCDMPreview,
    toggleDocumentDetailsModal,
    toggleDocumentInView,
    toggleDocumentsView,
    updateDocumentDetailsFailed,
    updateDocumentDetailsSuccessful,
    uploadDocumentFailed,
    uploadDocumentStarted,
    uploadDocumentSuccessful,
    uploadSecondaryDocumentSuccessful,
    setSelectedDocumentCustomFilterId,
    setOriginalDocumentTableFilters,
    setDocumentCustomFilterHasUpdated,
    updateDocumentCustomFilters,
    clearOriginalDocumentTableFilters
} from './actions';
import { getAllIncompleteDocuments, getAnalysisUploadDocument, getBaseDocumentColumnSort, getBaseDocumentFilters, getBaseDocumentPageSize, getBaseDocumentsPageNumber, getDocumentsView, getFilesToUpload, getIncompleteDocument, getIncompleteDocumentColumnSort, getIncompleteDocumentFilters, getIncompleteDocumentPageSize, getIncompleteDocumentsPageNumber, getIncompleteMLData, getAgreementTypeDocumentColumnSort, getAgreementTypeDocumentFilters, getAgreementTypeDocumentPageSize, getAgreementTypeDocumentsPageNumber, getOriginalDocumentColumnSort, getOriginalDocumentFilters, getOriginalDocumentPageSize, getOriginalDocumentToRename, getOriginalDocuments, getOriginalDocumentsPageNumber, getSelectedIncompleteDocuments, getSelectedSupportDocument, getSupportDocuments, getPreExecutionDocumentFilters, getPreExecutionDocumentColumnSort, getPreExecutionDocumentPageSize, getPreExecutionDocumentsPageNumber, getDocumentFetchAgreementTypeId, getSelectedDocumentCustomFilterId } from './selectors';
import { ArkDocument, DocumentsView, LinkedDocument, MyDocumentActionTypes, SecondaryUploadDocument, SelectedDocument } from './types';
import { addOrDeleteUserCustomFilter, getCurrentUserCustomFilters, updateCustomFiltersStarted } from '../../../home/store';

export const stripAnnexDefinition = (annexDefinition: AnnexDefinitionDB): AnnexDefinition => flow(
    unset('createdBy'),
    unset('createdDate'),
    unset('username')
)(annexDefinition);

const stripFilterValues = (filter: CustomFilters) => flow(
    unset('filterName'),
    unset('filterTable'),
    unset('id')
)(filter);

export const documentInitialFilter = (id: string, values: TableFilters): Omit<DocumentCustomFilters, 'filterName'> => ({ filterTable: FilterTable.DOCUMENT, id, entityA: values.entityA ? values.entityA : { text: '', dropdown: null }, entityB: values.entityB ? values.entityB : { text: '', dropdown: null }, documentDescription: values.documentDescription ? values.documentDescription : { text: '', dropdown: null }, documentName: values.documentName ? values.documentName : { text: '', dropdown: null } });

export function* uploadFiles(files: File[], completeWithAI = false, analyseLater = false) {
    let request = new FormData();
    const numberOfFilesToUpload = files.reduce((acc, cur) => {
        const updatedTotalFileSize = acc.total + cur.size;
        if (updatedTotalFileSize < MAX_UPLOAD_SIZE) {
            return { total: updatedTotalFileSize, numberOfFiles: acc.numberOfFiles + 1 };
        }
        return acc;
    }, { total: 0, numberOfFiles: 0 }).numberOfFiles;
    const filesToUpload = files.slice(0, numberOfFilesToUpload);
    filesToUpload.forEach(file => request.append('files', file));
    request.append('fileInfo', JSON.stringify({ completeWithAI, analyseLater }));
    const uploadedDocuments: ArkDocument[] = yield call(uploadDocuments, request);
    files.splice(0, numberOfFilesToUpload);
    yield put(setInitialUploadDocuments(files));
    return uploadedDocuments;
}

// 50MB
const MAX_UPLOAD_SIZE = 5e7;

export function* attemptUpload({ payload }: ReturnType<typeof uploadDocumentStarted>) {
    try {
        const { inAnalysis, completeWithAI, analyseLater } = payload;
        let request = new FormData();
        if (inAnalysis) {
            const { description, executedDate, file, type, originalDocumentId, amendmentLinkedDocumentIds, datedAsOf }: SecondaryUploadDocument = yield select(getAnalysisUploadDocument);
            const { entityA, entityB, documentNameId, linkedDocumentId }: ArkDocument = yield call(getDocumentById, { documentId: originalDocumentId! });
            request.append('file', file![0]);
            request.append('fileInfo', JSON.stringify({
                documentDescription: description,
                documentType: type,
                originalDocumentId,
                executedDate,
                entityA,
                entityB,
                documentNameId,
                linkedDocumentId,
                amendmentLinkedDocumentIds,
                datedAsOf
            }));
            yield call(uploadDocumentInAnalysis, request);
            const showLegacy: boolean = yield select(getShowLegacy);
            yield put(openDatasetDefinitionStarted(executedDate, type, showLegacy));
            yield put(uploadSecondaryDocumentSuccessful());
        } else {
            const allFiles: File[] = yield select(getFilesToUpload);
            let files = [...allFiles];
            let documents: ArkDocument[] = [];
            while (files.length) {
                const uploadedDocuments: ArkDocument[] = yield call(uploadFiles, files, completeWithAI, analyseLater);
                if (!analyseLater) {
                    documents = [...documents, ...uploadedDocuments];
                }
                const updatedAllFiles: File[] = yield select(getFilesToUpload);
                files = [...updatedAllFiles];
            }
            yield put(uploadDocumentSuccessful(documents));
            if (!completeWithAI && !analyseLater && documents.length) {
                yield put(openIncompleteDocumentStarted(documents[0]));
                yield put(toggleDocumentDetailsModal(true));
            }
        }
    } catch (e) {
        const errorMessage: string = (e as Error).message;
        const pageLimitExceededError = 'You have exceeded the monthly total of document pages you are allowed to upload. Please speak to your administrator to increase your monthly allowance.';
        yield put(uploadDocumentFailed(errorMessage));
        toast.error(errorMessage === 'Monthly total pages limit exceeded' ? pageLimitExceededError : 'There was a problem uploading your document. Please try again.');
    }
}

function* uploadWatcher() {
    yield takeLatest(MyDocumentActionTypes.UPLOAD_DOCUMENT_STARTED, attemptUpload);
}

export function* attemptOpenIncompleteDocument({ payload }: ReturnType<typeof openIncompleteDocumentStarted>) {
    try {
        const { mimeType, location, mlDataVersion, documentId } = payload;
        let mlData: MLData | null = null;
        let document = payload;
        const documentBlob: Blob = yield call(openDocument, { location });
        const documentUrl: string = yield call(getDocumentUrl, mimeType, documentBlob);
        if (!isUndefined(mlDataVersion)) {
            mlData = yield call(fetchMLData, { documentId });
            document = updateIncompleteDocumentWithMLData(document, mlData as MLData);
        }
        const scheduleStartPage = (mlData?.scheduleStartPage || document.scheduleStartPage || 1) as number;
        const scheduleEndPage = (mlData?.scheduleEndPage || document.scheduleEndPage) as number | null;
        // Clear the schedule start and end page to force manual entry from user as confirmation
        document = flow(
            set('scheduleStartPage', null),
            set('scheduleEndPage', null)
        )(document);

        yield put(openIncompleteDocumentSuccessful(documentUrl, document, mlData, scheduleStartPage, scheduleEndPage));
    } catch (e) {
        yield put(openIncompleteDocumentFailed((e as Error).message));
        toast.error('Unable to open document. Please try again.');
    }
}

function* openIncompleteDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.OPEN_INCOMPLETE_DOCUMENT_STARTED, attemptOpenIncompleteDocument);
}

export function* attemptOpenSecondaryDocument({ payload }: ReturnType<typeof openSecondaryDocumentStarted>) {
    try {
        const { location, mimeType } = payload;
        const documentBlob: Blob = yield call(openDocument, { location });
        const documentUrl: string = yield call(getDocumentUrl, mimeType, documentBlob);
        yield put(openSecondaryDocumentSuccessful(documentUrl, payload));
        yield put(toggleDocumentInView(SelectedDocument.SECONDARY));
    } catch (e) {
        yield put(openSecondaryDocumentFailed((e as Error).message));
        toast.error('Unable to open the additional document. Please try again.');
    }
}

function* openSecondaryDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.OPEN_SECONDARY_DOCUMENT_STARTED, attemptOpenSecondaryDocument);
}

export function* attemptFetchAllDocuments({ payload }: ReturnType<typeof fetchDocumentsStarted>) {
    try {
        const { pageNumber, agreementTypeId, baseDocument } = payload;
        if (agreementTypeId) {
            const filters: TableFilters = yield select(getAgreementTypeDocumentFilters);
            const columnSort: ColumnSort | undefined = yield select(getAgreementTypeDocumentColumnSort);
            const pageSize: number = yield select(getAgreementTypeDocumentPageSize);
            const { total, documents }: { total: number; documents: ArkDocument[]; } = yield call(fetchDocumentsByAgreementType, { agreementTypeId, filters, pageNumber, columnSort, pageSize });
            yield put(fetchAgreementTypeDocumentsSuccessful(documents, total, pageNumber));
        } else if (baseDocument) {
            const filters: TableFilters = yield select(getBaseDocumentFilters);
            const columnSort: ColumnSort | undefined = yield select(getBaseDocumentColumnSort);
            const pageSize: number = yield select(getBaseDocumentPageSize);
            const { total, documents }: { total: number; documents: ArkDocument[]; } = yield call(fetchAllBaseDocuments, { filters, pageNumber, columnSort, pageSize });
            yield put(fetchBaseDocumentsSuccessful(documents, total, pageNumber));
        } else {
            const filters: TableFilters = yield select(getOriginalDocumentFilters);
            const columnSort: ColumnSort | undefined = yield select(getOriginalDocumentColumnSort);
            const pageSize: number = yield select(getOriginalDocumentPageSize);
            const { total, documents }: { total: number; documents: ArkDocument[]; } = yield call(fetchAllOriginalDocuments, { filters, pageNumber, columnSort, pageSize });
            yield put(fetchOriginalDocumentsSuccessful(documents, total, pageNumber));
        }
    } catch (e) {
        yield put(fetchDocumentsFailed((e as Error).message));
        toast.error('Unable to fetch the latest list of documents. Please try again.');
    }
}

function* allDocumentsWatcher() {
    yield takeEvery(MyDocumentActionTypes.FETCH_DOCUMENTS_STARTED, attemptFetchAllDocuments);
}

export function* attemptOpenDocument({ payload }: ReturnType<typeof openDocumentStarted>) {
    try {
        const { location, documentId, mimeType, instanceId, executedDate, isLegacy, preventRedirect } = payload;
        const documentBlob: Blob = yield call(openDocument, { location });
        const document: ArkDocument = yield call(getDocumentById, { documentId, datasetInstanceId: instanceId });
        const documentUrl: string = yield call(getDocumentUrl, mimeType, documentBlob);
        yield put(openDocumentSuccessful(documentUrl, document));
        if (!instanceId) {
            yield put(openDatasetDefinitionStarted(executedDate));
        } else {
            yield put(openDatasetInstanceById(instanceId, isLegacy, documentId, preventRedirect));
        }
        yield put(fetchAllDropdownListsStarted());
        yield put(fetchAllEntitiesStarted());
    } catch (e) {
        yield put(openDocumentFailed((e as Error).message, payload.location));
        toast.error('Unable to open document. Please try again.');
    }
}

function* openDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.OPEN_DOCUMENT_STARTED, attemptOpenDocument);
}

export function* attemptDocumentDownload({ payload }: ReturnType<typeof downloadDocumentStarted>) {
    try {
        const { documentDescription, mimeType, location } = payload;
        const document: Blob = yield call(downloadDocument, { location });
        const formattedDocumentDescription = dotsToDashes(documentDescription);
        yield call(download, document, formattedDocumentDescription, mimeType);
        yield put(downloadDocumentSuccessful());
    } catch (e) {
        yield put(downloadDocumentFailed((e as Error).message));
        toast.error('Unable to download document. Please try again.');
    }
}

export function* attemptDeleteDocument({ payload }: ReturnType<typeof deleteDocumentStarted>) {
    try {
        const documentsView: DocumentsView = yield select(getDocumentsView);
        const incomplete = documentsView === DocumentsView.INCOMPLETE_DOCUMENTS;
        const preExecution = documentsView === DocumentsView.PRE_EXECUTION_DRAFT;
        if (!incomplete) {
            const pageNumber: number = yield select(getOriginalDocumentsPageNumber);
            const filters: TableFilters = yield select(getOriginalDocumentFilters);
            const columnSort: ColumnSort | undefined = yield select(getOriginalDocumentColumnSort);
            const pageSize: number = yield select(getOriginalDocumentPageSize);
            const { total, documents }: { total: number; documents: ArkDocument[]; } = yield call(deleteDocument, { location: payload, incomplete, preExecution, pageNumber, filters, columnSort, pageSize });
            yield put(fetchOriginalDocumentsSuccessful(documents, total, pageNumber));
        } else {
            const pageNumber: number = yield select(getIncompleteDocumentsPageNumber);
            const filters: TableFilters = yield select(getIncompleteDocumentFilters);
            const columnSort: ColumnSort | undefined = yield select(getIncompleteDocumentColumnSort);
            const pageSize: number = yield select(getIncompleteDocumentPageSize);
            const { total, documents }: { total: number; documents: ArkDocument[]; } = yield call(deleteDocument, { location: payload, incomplete, preExecution, pageNumber, filters, columnSort, pageSize });
            yield put(fetchIncompleteDocumentsSuccessful(documents, total, pageNumber));
        }
        yield put(deleteDocumentSuccessful());
        toast('Document successfully deleted');
    } catch (e) {
        yield put(deleteDocumentFailed((e as Error).message));
        toast.error('Unable to delete document. Please try again.');
    }
}

export function* attemptAllDocumentsDownload({ payload }: ReturnType<typeof downloadAllDocumentsStarted>) {
    try {
        const { locations, documentDescription } = payload;
        const documents: Blob = yield call(downloadAllDocuments, { locations });
        const formattedDocumentDescription = dotsToDashes(documentDescription);
        yield call(download, documents, formattedDocumentDescription, 'application/zip');
        yield put(downloadAllDocumentsSuccessful());
    } catch (e) {
        toast.error('Unable to download document. Please try again.');
        yield put(downloadAllDocumentsFailed((e as Error).message));
    }
}

function* downloadDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.DOWNLOAD_DOCUMENT_STARTED, attemptDocumentDownload);
}

function* deleteDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.DELETE_DOCUMENT_STARTED, attemptDeleteDocument);
}

function* downloadAllDocumentsWatcher() {
    yield takeEvery(MyDocumentActionTypes.DOWNLOAD_ALL_DOCUMENTS_STARTED, attemptAllDocumentsDownload);
}

export function* attemptOpenDocumentAndInstance({ payload }: ReturnType<typeof openDocumentAndInstance>) {
    try {
        const { documentId, datasetInstanceId, showLegacy } = payload;
        yield put(fetchDocumentsStarted());
        const { mimeType, location, isLegacy }: ArkDocument = yield call(getDocumentById, { documentId: parseInt(documentId), datasetInstanceId });
        yield put(openDocumentStarted(location, parseInt(documentId), mimeType, datasetInstanceId, undefined, isLegacy || showLegacy));
    } catch (e) {
        toast.error('Unable to open document. Please try again.');
        yield put(push('/home'));
    }
}

function* openDocumentAndInstanceWatcher() {
    yield takeEvery(MyDocumentActionTypes.OPEN_DOCUMENT_AND_INSTANCE, attemptOpenDocumentAndInstance);
}

export function* attemptLinkDocument({ payload }: ReturnType<typeof linkDocumentStarted>) {
    try {
        const { documentId, linkedDocumentId } = payload;
        const filters: TableFilters = yield select(getOriginalDocumentFilters);
        const columnSort: ColumnSort | undefined = yield select(getOriginalDocumentColumnSort);
        const pageNumber: number = yield select(getOriginalDocumentsPageNumber);
        const pageSize: number = yield select(getOriginalDocumentPageSize);
        const { total, documents }: { total: number; documents: ArkDocument[]; } = yield call(linkDocumentToOriginal, { documentId, linkedDocumentId, filters, columnSort, pageNumber, pageSize });
        yield put(linkDocumentSuccessful());
        yield put(fetchOriginalDocumentsSuccessful(documents, total, pageNumber));
    } catch (e) {
        yield put(linkDocumentFailed((e as Error).message));
        toast.error('Unable to link document. Please try again.');
    }
}

function* linkDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.LINK_DOCUMENT_STARTED, attemptLinkDocument);
}

export function* attemptFetchLinkedDocuments({ payload }: ReturnType<typeof fetchLinkedDocumentsStarted>) {
    try {
        const documents: LinkedDocument[] = yield call(fetchDocumentsLinkedToOriginalDocument, { documentId: payload });
        yield put(fetchLinkedDocumentsSuccessful(documents));
    } catch (e) {
        yield put(fetchLinkedDocumentsFailed((e as Error).message));
        toast.error('Unable to fetch linked documents. Please try again.');
    }
}

function* attemptFetchLinkedDocumentsWatcher() {
    yield takeLeading(MyDocumentActionTypes.FETCH_LINKED_DOCUMENTS_STARTED, attemptFetchLinkedDocuments);
}

export function* checkDocumentDetailsUpdated() {
    try {
        const incompleteDocuments: ArkDocument[] = yield select(getSelectedIncompleteDocuments);
        const currentDocument: ArkDocument = yield select(getIncompleteDocument);
        const unchangedCurrentDocument = incompleteDocuments.find(({ documentId }) => documentId === currentDocument.documentId);
        const documentDetailsUpdated = !isUndefined(unchangedCurrentDocument) && !isEqual(unchangedCurrentDocument, currentDocument);
        yield put(documentDetailsHaveUpdated(documentDetailsUpdated));
    } catch (e) {
        toast.error('We encountered a problem checking the document details completion.');
    }
}

function* detailsUpdatedWatcher() {
    yield debounce(1000, MyDocumentActionTypes.INCOMPLETE_DOCUMENT_UPDATE_VALUE, checkDocumentDetailsUpdated);
}

export function* attemptUpdateDocumentDetails() {
    try {
        const documentToUpdate: ArkDocument = yield select(getIncompleteDocument);
        const selectedIncompleteDocuments: ArkDocument[] = yield select(getSelectedIncompleteDocuments);
        const { document, documentComplete }: { document: ArkDocument, documentComplete: boolean } = yield call(updateDocumentDetails, documentToUpdate);
        let documents = selectedIncompleteDocuments.filter(({ documentId }) => documentId !== document.documentId);
        if (!documentComplete && selectedIncompleteDocuments.length > 1) {
            // If the document is still not complete, add it to the end of the array of recently uploaded incomplete documents
            documents.push(document);
        }
        if (documents.length) {
            yield put(openIncompleteDocumentStarted(documents[0]));
        } else {
            yield put(toggleDocumentDetailsModal(false));
        }
        yield put(updateDocumentDetailsSuccessful(documents));
        const pathname: string = yield select(getPathname);
        if (pathname.includes('incomplete')) {
            yield put(fetchIncompleteDocumentsStarted());
        } else {
            yield put(fetchDocumentsStarted());
        }
    } catch (e) {
        yield put(updateDocumentDetailsFailed((e as Error).message));
        toast.error('Unable to complete document details. Please try again.');
    }
}

function* updateDocumentDetailsWatcher() {
    yield takeLeading(MyDocumentActionTypes.UPDATE_DOCUMENT_DETAILS_STARTED, attemptUpdateDocumentDetails);
}

export function* skipIncompleteDocumentDetails() {
    try {
        const currentDocument: ArkDocument = yield select(getIncompleteDocument);
        const selectedIncompleteDocuments: ArkDocument[] = yield select(getSelectedIncompleteDocuments);
        let documents = selectedIncompleteDocuments.filter(({ documentId }) => documentId !== currentDocument.documentId);
        documents.push(currentDocument);
        yield put(openIncompleteDocumentStarted(documents[0]));
        yield put(updateDocumentDetailsSuccessful(documents));
    } catch (e) {
        yield put(updateDocumentDetailsFailed((e as Error).message));
        toast.error('Unable to complete document details. Please try again.');
    }
}

function* skipIncompleteDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.SKIP_INCOMPLETE_DOCUMENT, skipIncompleteDocumentDetails);
}

export function* attemptFetchAllIncompleteDocuments({ payload }: ReturnType<typeof fetchIncompleteDocumentsStarted>) {
    try {
        const filters: TableFilters = yield select(getIncompleteDocumentFilters);
        const columnSort: ColumnSort | undefined = yield select(getIncompleteDocumentColumnSort);
        const pageSize: number = yield select(getIncompleteDocumentPageSize);
        const { documents, total }: { documents: ArkDocument[]; total: number; } = yield call(fetchAllIncompleteDocuments, { pageNumber: payload, filters, columnSort, pageSize });
        yield put(fetchIncompleteDocumentsSuccessful(documents, total, payload));
    } catch (e) {
        yield put(fetchIncompleteDocumentsFailed((e as Error).message));
        toast.error('Unable to fetch your incomplete documents. Please try again.');
    }
}

function* allIncompleteDocumentsWatcher() {
    yield takeEvery(MyDocumentActionTypes.FETCH_INCOMPLETE_DOCUMENTS_STARTED, attemptFetchAllIncompleteDocuments);
}

const updateDocuments = (allDocs: ArkDocument[], location: string) => allDocs.map(document => document.location === location ? { ...document, analysisStarted: 1 } : document);

export function* attemptReanalyzeDocument({ payload }: ReturnType<typeof reanalyzeDocumentStarted>) {
    try {
        yield call(analyzeDocument, { location: payload });
        const pathname: string = yield select(getPathname);
        if (pathname.includes('incomplete')) {
            const incompleteDocuments: ArkDocument[] = yield select(getAllIncompleteDocuments);
            const documents = updateDocuments(incompleteDocuments, payload);
            yield put(fetchIncompleteDocumentsSuccessful(documents));
        }
        else {
            const allDocuments: ArkDocument[] = yield select(getOriginalDocuments);
            const documents = updateDocuments(allDocuments, payload);
            yield put(fetchOriginalDocumentsSuccessful(documents));
        }
    } catch (e) {
        yield put(reanalyzeDocumentFailed((e as Error).message));
        toast.error('Unable to analyze document. Please try again.');
    }
}

function* reanalyzeDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.REANALYZE_DOCUMENT_STARTED, attemptReanalyzeDocument);
}

export function* attemptInitialAI({ payload }: ReturnType<typeof initialAIStarted>) {
    try {
        const { mlDataVersion }: { mlDataVersion: number; } = yield call(completeInitialAI, { documentId: payload });
        yield put(initialAISuccessful(payload, mlDataVersion));
    } catch (e) {
        yield put(initialAIFailed((e as Error).message));
        toast.error('Unable to generate AI results from your document. Please try again.');
    }
}

function* initialAIWatcher() {
    yield takeLeading(MyDocumentActionTypes.INITIAL_AI_DOCUMENT_STARTED, attemptInitialAI);
}

export function* attemptRunLatestMLQueries({ payload }: ReturnType<typeof runLatestMLQueriesStarted>) {
    try {
        const pathname: string = yield select(getPathname);
        if (pathname.includes('documents/analysis')) {
            yield put(push('/documents/my-documents'));
        }
        yield call(runLatestMLQueries, { documentId: payload });
        yield put(runLatestMLQueriesSuccessful(payload));
    } catch (e) {
        yield put(runLatestMLQueriesFailed((e as Error).message));
    }
}

function* runLatestMLQueriesWatcher() {
    yield takeLeading(MyDocumentActionTypes.RUN_LATEST_ML_QUERIES_STARTED, attemptRunLatestMLQueries);
}

const updateCompleteDocument = (documents: ArkDocument[], documentId: number) => documents.map(document => document.documentId === documentId ? { ...document, analysisComplete: 1, analysisStarted: 0 } : document);
const updateFailedDocument = (documents: ArkDocument[], documentId: number) => documents.map(document => document.documentId === documentId ? { ...document, analysisStarted: 0 } : document);

export function* analysisCompleteWorker({ payload }: ReturnType<typeof documentAnalysisComplete>) {
    const documentId = payload;
    const originalDocuments: ArkDocument[] = yield select(getOriginalDocuments);
    const incompleteDocuments: ArkDocument[] = yield select(getAllIncompleteDocuments);
    const updatedOriginalDocuments = updateCompleteDocument(originalDocuments, documentId);
    const updatedIncompleteDocuments = updateCompleteDocument(incompleteDocuments, documentId);
    if (!isEqual(originalDocuments, updatedOriginalDocuments) && originalDocuments.length) {
        yield put(fetchOriginalDocumentsSuccessful(updatedOriginalDocuments));
    }
    if (!isEqual(incompleteDocuments, updatedIncompleteDocuments) && incompleteDocuments.length) {
        yield put(fetchIncompleteDocumentsSuccessful(updatedIncompleteDocuments));
    }
}

function* analysisCompleteWatcher() {
    yield takeEvery(MyDocumentActionTypes.DOCUMENT_ANALYSIS_COMPLETE, analysisCompleteWorker);
}

export function* analysisFailedWorker({ payload }: ReturnType<typeof documentAnalysisFailed>) {
    const documentId = payload;
    const originalDocuments: ArkDocument[] = yield select(getOriginalDocuments);
    const incompleteDocuments: ArkDocument[] = yield select(getAllIncompleteDocuments);
    const updatedOriginalDocuments = updateFailedDocument(originalDocuments, documentId);
    const updatedIncompleteDocuments = updateFailedDocument(incompleteDocuments, documentId);
    if (!isEqual(originalDocuments, updatedOriginalDocuments) && originalDocuments.length) {
        yield put(fetchOriginalDocumentsSuccessful(updatedOriginalDocuments));
    }
    if (!isEqual(incompleteDocuments, updatedIncompleteDocuments) && incompleteDocuments.length) {
        yield put(fetchIncompleteDocumentsSuccessful(updatedIncompleteDocuments));
    }
}

function* analysisFailedWatcher() {
    yield takeEvery(MyDocumentActionTypes.DOCUMENT_ANALYSIS_FAILED, analysisFailedWorker);
}

export function* attemptOpenSupportDocument({ payload }: ReturnType<typeof openSupportDocumentStarted>) {
    try {
        const { location, mimeType } = payload;
        const documentBlob: Blob = yield call(openDocument, { location });
        const documentUrl: string = yield call(getDocumentUrl, mimeType, documentBlob);
        yield put(openSupportDocumentSuccessful(documentUrl, payload));
    } catch (e) {
        yield put(openSupportDocumentFailed((e as Error).message));
        toast.error('Unable to open the support document. Please try again.');
    }
}

function* openSupportDocumentWatcher() {
    yield takeEvery(MyDocumentActionTypes.OPEN_SUPPORT_DOCUMENT_STARTED, attemptOpenSupportDocument);
}

export function* supportDocumentsWorker({ payload }: ReturnType<typeof toggleDocumentInView>) {
    if (payload === SelectedDocument.SUPPORT) {
        const supportDocuments: LinkedDocument[] = yield select(getSupportDocuments);
        const selectedSupportDocument: LinkedDocument | null = yield select(getSelectedSupportDocument);
        if (supportDocuments.length && !selectedSupportDocument) {
            yield put(openSupportDocumentStarted(supportDocuments[0]));
        }
    }
}

function* supportDocumentsWatcher() {
    yield takeEvery(MyDocumentActionTypes.TOGGLE_DOCUMENT_IN_VIEW, supportDocumentsWorker);
}

export function* redirectDocumentTab({ payload }: ReturnType<typeof toggleDocumentsView>) {
    let navigationPath = '/documents/my-documents';
    if (payload === DocumentsView.INCOMPLETE_DOCUMENTS) {
        navigationPath = navigationPath.concat('/incomplete');
    }
    if (payload === DocumentsView.PRE_EXECUTION_DRAFT) {
        navigationPath = navigationPath.concat('/pre-execution');
    }
    yield put(push(navigationPath));
}

function* redirectDocumentTabWatcher() {
    yield takeEvery(MyDocumentActionTypes.TOGGLE_DOCUMENTS_VIEW, redirectDocumentTab);
}

export function* attemptCSVExport({ payload }: ReturnType<typeof exportToCSVStarted>) {
    try {
        const { description, documentNameId, documentId, datasetInstanceId } = payload;
        const formattedDescription = `RAF Review (${dotsToDashes(description)})`;
        const csvDocument: Blob = yield call(exportDocumentToCSV, { documentNameId, documentId, datasetInstanceId });
        yield call(download, csvDocument, formattedDescription, 'text/csv');
        yield put(exportToCSVSuccessful());
    } catch (e) {
        yield put(exportToCSVFailed((e as Error).message));
        toast.error('Unable to export document to CSV. Please try again.');
    }
}

function* exportToCSVWatcher() {
    yield takeLeading(MyDocumentActionTypes.EXPORT_TO_CSV_STARTED, attemptCSVExport);
}

export function* attemptCDMExport({ payload }: ReturnType<typeof exportToCDMStarted>) {
    try {
        const { documentId, instantDownload } = payload;
        const cdmDocument: object = yield call(exportDocumentToCDM, { documentId });
        if (instantDownload) {
            yield put(downloadCDMPreview(cdmDocument));
        } else {
            yield put(toggleCDMPreview(cdmDocument));
        }
        yield put(exportToCDMSuccessful());
    } catch (e) {
        yield put(exportToCDMFailed((e as Error).message));
        toast.error('Unable to export document to CDM Model. Please try again.');
    }
}

function* exportToCDMWatcher() {
    yield takeLeading(MyDocumentActionTypes.EXPORT_TO_CDM_STARTED, attemptCDMExport);
}

export function* attemptDownloadCDMPreview({ payload }: ReturnType<typeof downloadCDMPreview>) {
    yield call(download, JSON.stringify(payload), 'cdm_export.json', 'application/json');
}

function* downloadCDMPreviewWatcher() {
    yield takeLeading(MyDocumentActionTypes.DOWNLOAD_CDM_PREVIEW, attemptDownloadCDMPreview);
}

export function* attemptCSVExportAll() {
    try {
        const csvDocument: Blob = yield call(exportAllToCSV, { agreementTypeId: AgreementType.RAF_REVIEW });
        yield call(download, csvDocument, 'RAF Review', 'text/csv');
        yield put(exportAllCSVSuccessful());
    } catch (e) {
        yield put(exportAllCSVFailed((e as Error).message));
        toast.error('Unable to export document to CSV. Please try again.');
    }
}

function* exportAllToCSVWatcher() {
    yield takeLeading(MyDocumentActionTypes.EXPORT_ALL_CSV_STARTED, attemptCSVExportAll);
}

export function* checkDocumentDescriptionUpdated() {
    try {
        const originalDocuments: ArkDocument[] = yield select(getOriginalDocuments);
        const renameDocument: ArkDocument = yield select(getOriginalDocumentToRename);
        const unchangedRenameDocument = originalDocuments.find(({ documentId }) => documentId === renameDocument.documentId);

        const descriptionsUpdated = !isUndefined(unchangedRenameDocument) && !isEqual(unchangedRenameDocument, renameDocument);
        yield put(documentDescriptionsHaveUpdated(descriptionsUpdated));
    } catch (e) {
        toast.error('We encountered a problem checking the document descriptions have updated.');
    }
}

function* descriptionUpdatedWatcher() {
    yield debounce(1000, MyDocumentActionTypes.UPDATE_DOCUMENT_DESCRIPTION, checkDocumentDescriptionUpdated);
}

export function* attemptRenameDocumentDescriptions() {
    try {
        const originalDocuments: ArkDocument[] = yield select(getOriginalDocuments);
        const renameDocument: ArkDocument = yield select(getOriginalDocumentToRename);
        const pageNumber: number = yield select(getOriginalDocumentsPageNumber);
        const unchangedAllDocuments = originalDocuments.find(({ documentId }) => documentId === renameDocument.documentId)!.allDocuments;
        const documentsToUpdate = renameDocument.allDocuments.filter(updatedDocument => !unchangedAllDocuments.filter(document => isEqual(updatedDocument, document)).length);
        yield call(renameDocumentDescriptions, { documents: documentsToUpdate });
        yield put(fetchDocumentsStarted(pageNumber));
        yield put(renameDocumentsSuccessful());
    } catch (e) {
        toast.error('Unable to rename your documents. Please try again.');
    }
}

function* renameDocumentDescriptionsWatcher() {
    yield takeEvery(MyDocumentActionTypes.RENAME_DOCUMENTS_STARTED, attemptRenameDocumentDescriptions);
}

export function* attemptCreateNewEntityFromMLData({ payload }: ReturnType<typeof createNewEntityFromMLDataStarted>) {
    try {
        const { key, name } = payload;
        const entity = set('name', name, initialCompanyEntity(EntityType.COMPANY));
        const incompleteDocument: ArkDocument = yield select(getIncompleteDocument);
        const incompleteMLData: MLData = yield select(getIncompleteMLData);
        const { entities, entityId }: { entities: BaseEntity[], entityId: number; } = yield call(upsertMLEntity, { entity, key, documentId: incompleteDocument.documentId });
        const document = set(key, entityId, incompleteDocument);
        const mlData = set(key, entityId, incompleteMLData);
        yield put(fetchAllEntitiesSuccessful(entities));
        yield put(createNewEntityFromMLDataSuccessful(document, mlData));
    } catch (e) {
        yield put(createNewEntityFromMLDataFailed((e as Error).message));
        toast.error('Unable to create entity. Please try again.');
    }
}

function* createNewEntityFromMLDataWatcher() {
    yield takeEvery(MyDocumentActionTypes.CREATE_NEW_ENTITY_FROM_ML_DATA_STARTED, attemptCreateNewEntityFromMLData);
}

export function* attemptFetchAllPreExecutionDocuments({ payload }: ReturnType<typeof fetchPreExecutionDocumentsStarted>) {
    try {
        const filters: TableFilters = yield select(getPreExecutionDocumentFilters);
        const columnSort: ColumnSort | undefined = yield select(getPreExecutionDocumentColumnSort);
        const pageSize: number = yield select(getPreExecutionDocumentPageSize);
        const { documents, total }: { documents: ArkDocument[]; total: number; } = yield call(fetchAllPreExecutionDocuments, { pageNumber: payload, filters, columnSort, pageSize });
        yield put(fetchPreExecutionDocumentsSuccessful(documents, total, payload));
    } catch (e) {
        yield put(fetchPreExecutionDocumentsFailed((e as Error).message));
        toast.error('Unable to fetch your pre-execution documents. Please try again.');
    }
}

function* fetchAllPreExecutionDocumentsWatcher() {
    yield takeEvery(MyDocumentActionTypes.FETCH_PRE_EXECUTION_DOCUMENTS_STARTED, attemptFetchAllPreExecutionDocuments);
}

// Document Pagination Sagas

export function* originalPaginationNext() {
    const pageNumber: number = yield select(getOriginalDocumentsPageNumber);
    yield put(fetchDocumentsStarted(pageNumber + 1));
}

function* originalPaginationNextWatcher() {
    yield takeEvery(MyDocumentActionTypes.ORIGINAL_DOCUMENTS_PAGINATION_NEXT, originalPaginationNext);
}

export function* originalPaginationPrevious() {
    const pageNumber: number = yield select(getOriginalDocumentsPageNumber);
    yield put(fetchDocumentsStarted(pageNumber - 1));
}

function* originalPaginationPreviousWatcher() {
    yield takeEvery(MyDocumentActionTypes.ORIGINAL_DOCUMENTS_PAGINATION_PREVIOUS, originalPaginationPrevious);
}

export function* originalTableConfigUpdated() {
    yield put(fetchDocumentsStarted(1));
}

function* originalTableConfigUpdatedWatcher() {
    yield debounce(200, [MyDocumentActionTypes.SET_ORIGINAL_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.CLEAR_ORIGINAL_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.SET_ORIGINAL_DOCUMENT_TABLE_COLUMN_SORT, MyDocumentActionTypes.SET_ORIGINAL_DOCUMENTS_PAGE_SIZE], originalTableConfigUpdated);
}

export function* basePaginationNext() {
    const pageNumber: number = yield select(getBaseDocumentsPageNumber);
    yield put(fetchDocumentsStarted(pageNumber + 1, undefined, true));
}

function* basePaginationNextWatcher() {
    yield takeEvery(MyDocumentActionTypes.BASE_DOCUMENTS_PAGINATION_NEXT, basePaginationNext);
}

export function* basePaginationPrevious() {
    const pageNumber: number = yield select(getBaseDocumentsPageNumber);
    yield put(fetchDocumentsStarted(pageNumber - 1, undefined, true));
}

function* basePaginationPreviousWatcher() {
    yield takeEvery(MyDocumentActionTypes.BASE_DOCUMENTS_PAGINATION_PREVIOUS, basePaginationPrevious);
}

export function* baseTableConfigUpdated() {
    yield put(fetchDocumentsStarted(1, undefined, true));
}

function* baseTableConfigUpdatedWatcher() {
    yield takeEvery([MyDocumentActionTypes.SET_BASE_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.CLEAR_BASE_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.SET_BASE_DOCUMENT_TABLE_COLUMN_SORT, MyDocumentActionTypes.SET_BASE_DOCUMENTS_PAGE_SIZE], baseTableConfigUpdated);
}

export function* agreementTypePaginationNext() {
    const pageNumber: number = yield select(getAgreementTypeDocumentsPageNumber);
    const agreementType: AgreementType = yield select(getDocumentFetchAgreementTypeId);
    yield put(fetchDocumentsStarted(pageNumber + 1, agreementType));
}

function* agreementTypePaginationNextWatcher() {
    yield takeEvery(MyDocumentActionTypes.AGREEMENT_TYPE_DOCUMENTS_PAGINATION_NEXT, agreementTypePaginationNext);
}

export function* agreementTypePaginationPrevious() {
    const pageNumber: number = yield select(getAgreementTypeDocumentsPageNumber);
    const agreementType: AgreementType = yield select(getDocumentFetchAgreementTypeId);
    yield put(fetchDocumentsStarted(pageNumber - 1, agreementType));
}

function* agreementTypePaginationPreviousWatcher() {
    yield takeEvery(MyDocumentActionTypes.AGREEMENT_TYPE_DOCUMENTS_PAGINATION_PREVIOUS, agreementTypePaginationPrevious);
}

export function* isdaTableConfigUpdated() {
    const agreementType: AgreementType = yield select(getDocumentFetchAgreementTypeId);
    yield put(fetchDocumentsStarted(1, agreementType));
}

function* isdaTableConfigUpdatedWatcher() {
    yield takeEvery([MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.CLEAR_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENT_TABLE_COLUMN_SORT, MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENTS_PAGE_SIZE], isdaTableConfigUpdated);
}

export function* incompletePaginationNext() {
    const pageNumber: number = yield select(getIncompleteDocumentsPageNumber);
    yield put(fetchIncompleteDocumentsStarted(pageNumber + 1));
}

function* incompletePaginationNextWatcher() {
    yield takeEvery(MyDocumentActionTypes.INCOMPLETE_DOCUMENTS_PAGINATION_NEXT, incompletePaginationNext);
}

export function* incompletePaginationPrevious() {
    const pageNumber: number = yield select(getIncompleteDocumentsPageNumber);
    yield put(fetchIncompleteDocumentsStarted(pageNumber - 1));
}

function* incompletePaginationPreviousWatcher() {
    yield takeEvery(MyDocumentActionTypes.INCOMPLETE_DOCUMENTS_PAGINATION_PREVIOUS, incompletePaginationPrevious);
}

export function* incompleteTableConfigUpdated() {
    yield put(fetchIncompleteDocumentsStarted(1));
}

function* incompleteTableConfigUpdatedWatcher() {
    yield takeEvery([MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.CLEAR_INCOMPLETE_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_TABLE_COLUMN_SORT, MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENTS_PAGE_SIZE], incompleteTableConfigUpdated);
}

export function* preExecutionPaginationNext() {
    const pageNumber: number = yield select(getPreExecutionDocumentsPageNumber);
    yield put(fetchPreExecutionDocumentsStarted(pageNumber + 1));
}

function* preExecutionPaginationNextWatcher() {
    yield takeEvery(MyDocumentActionTypes.PRE_EXECUTION_DOCUMENTS_PAGINATION_NEXT, preExecutionPaginationNext);
}

export function* preExecutionPaginationPrevious() {
    const pageNumber: number = yield select(getPreExecutionDocumentsPageNumber);
    yield put(fetchPreExecutionDocumentsStarted(pageNumber - 1));
}

function* preExecutionPaginationPreviousWatcher() {
    yield takeEvery(MyDocumentActionTypes.PRE_EXECUTION_DOCUMENTS_PAGINATION_PREVIOUS, preExecutionPaginationPrevious);
}

export function* preExecutionTableConfigUpdated() {
    yield put(fetchPreExecutionDocumentsStarted(1));
}

function* preExecutionTableConfigUpdatedWatcher() {
    yield takeEvery([MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.CLEAR_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS, MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENT_TABLE_COLUMN_SORT, MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENTS_PAGE_SIZE], preExecutionTableConfigUpdated);
}

export function* closeOtherModals({ payload }: ReturnType<typeof toggleDocumentDetailsModal>) {
    if (!payload) {
        yield put(toggleAnnexDefinitionModal(false));
        yield put(toggleAnnexBuilderModal(false));
    }
}

function* documentDetailsClosedWatcher() {
    yield takeEvery(MyDocumentActionTypes.TOGGLE_DOCUMENT_DETAILS_MODAL, closeOtherModals);
}

export function* attemptUpdateDocumentCustomFilters({ payload }: ReturnType<typeof updateDocumentCustomFilters>) {
    const { label, createNew } = payload;
    const originalDocumentFilters: TableFilters = yield select(getOriginalDocumentFilters);
    const currentCustomFilters: CustomFilters[] = yield select(getCurrentUserCustomFilters);
    if (createNew) {
        const id = v4();
        const unnamedDocumentFilter = documentInitialFilter(id, originalDocumentFilters);
        const newDocumentFilter: DocumentCustomFilters = { ...unnamedDocumentFilter, filterName: label };
        const updatedCustomFilters = [...currentCustomFilters, newDocumentFilter];
        yield put(addOrDeleteUserCustomFilter(updatedCustomFilters));
        yield put(setSelectedDocumentCustomFilterId(newDocumentFilter.id));
    } else {
        const filterId: string | null = yield select(getSelectedDocumentCustomFilterId);
        if (!isNull(filterId)) {
            const updatedCustomFilters = currentCustomFilters.map(customFilter => {
                if (customFilter.id === filterId) {
                    const unnamedDocumentFilter = documentInitialFilter(filterId, originalDocumentFilters);
                    return { ...unnamedDocumentFilter, filterName: label };
                }
                return customFilter;
            });
            yield put(addOrDeleteUserCustomFilter(updatedCustomFilters));
        }
    }
    yield put(setDocumentCustomFilterHasUpdated(false));
    yield put(updateCustomFiltersStarted());
}

function* attemptUpdateDocumentCustomFiltersWatcher() {
    yield takeLeading(MyDocumentActionTypes.UPDATE_DOCUMENT_CUSTOM_FILTERS, attemptUpdateDocumentCustomFilters);
}

export function* attemptSetSelectedCustomFilterId({ payload }: ReturnType<typeof setSelectedDocumentCustomFilterId>) {
    try {
        const customDocumentFilters: DocumentCustomFilters[] = yield select(getAllDocumentCustomFilters);
        const selectedDocumentFilter = customDocumentFilters.find(({ id }) => id === payload);
        if (selectedDocumentFilter) {
            yield put(clearOriginalDocumentTableFilters(false));
            const allFilters = stripFilterValues(selectedDocumentFilter);
            for (const [key, filterEntry] of Object.entries(allFilters)) {
                const filter = filterEntry as TableFilterType;
                if (!isNull(filter.dropdown)) {
                    yield put(setOriginalDocumentTableFilters(key, filter.dropdown, 'dropdown'));
                }
                if (filter.text.length > 0) {
                    yield put(setOriginalDocumentTableFilters(key, filter.text, 'text'));
                }
            }
        }
    } catch (e) {
        toast.error('Unable to set custom filter. Please try again.');
    }
}

function* attemptSetSelectedCustomFilterIdWatcher() {
    yield takeLeading(MyDocumentActionTypes.SET_SELECTED_DOCUMENT_CUSTOM_FILTER_ID, attemptSetSelectedCustomFilterId);
}

export function* checkOriginalTableFiltersUpdated() {
    try {
        const customDocumentFilters: DocumentCustomFilters[] = yield select(getAllDocumentCustomFilters);
        const selectedCustomFilterId: string | null = yield select(getSelectedDocumentCustomFilterId);
        const originalDocumentFilters: TableFilters = yield select(getOriginalDocumentFilters);
        const currentDocumentFilter = documentInitialFilter(selectedCustomFilterId || '', originalDocumentFilters);
        let savedDocumentFilter = documentInitialFilter(selectedCustomFilterId || '', {});
        if (!isNull(selectedCustomFilterId)) {
            const selectedDocumentFilter = customDocumentFilters.find(({ id }) => id === selectedCustomFilterId);
            if (selectedDocumentFilter) {
                savedDocumentFilter = unset('filterName', selectedDocumentFilter);
            }
        }
        const hasUpdated = !isEqual(currentDocumentFilter, savedDocumentFilter);
        yield put(setDocumentCustomFilterHasUpdated(hasUpdated));
    } catch (e) {
        toast.error('Unable to update custom filters. Please try again.');
    }
}

function* checkOriginalTableFiltersUpdatedWatcher() {
    yield debounce(500, MyDocumentActionTypes.SET_ORIGINAL_DOCUMENT_TABLE_FILTERS, checkOriginalTableFiltersUpdated);
}

export function* myDocumentsSaga() {
    yield all([
        fork(uploadWatcher),
        fork(allDocumentsWatcher),
        fork(openDocumentWatcher),
        fork(openSecondaryDocumentWatcher),
        fork(downloadDocumentWatcher),
        fork(deleteDocumentWatcher),
        fork(openDocumentAndInstanceWatcher),
        fork(downloadAllDocumentsWatcher),
        fork(linkDocumentWatcher),
        fork(openIncompleteDocumentWatcher),
        fork(detailsUpdatedWatcher),
        fork(updateDocumentDetailsWatcher),
        fork(skipIncompleteDocumentWatcher),
        fork(allIncompleteDocumentsWatcher),
        fork(reanalyzeDocumentWatcher),
        fork(analysisCompleteWatcher),
        fork(analysisFailedWatcher),
        fork(openSupportDocumentWatcher),
        fork(supportDocumentsWatcher),
        fork(redirectDocumentTabWatcher),
        fork(exportToCSVWatcher),
        fork(exportToCDMWatcher),
        fork(exportAllToCSVWatcher),
        fork(originalPaginationNextWatcher),
        fork(originalPaginationPreviousWatcher),
        fork(originalTableConfigUpdatedWatcher),
        fork(basePaginationNextWatcher),
        fork(basePaginationPreviousWatcher),
        fork(baseTableConfigUpdatedWatcher),
        fork(agreementTypePaginationNextWatcher),
        fork(agreementTypePaginationPreviousWatcher),
        fork(isdaTableConfigUpdatedWatcher),
        fork(incompletePaginationNextWatcher),
        fork(incompletePaginationPreviousWatcher),
        fork(incompleteTableConfigUpdatedWatcher),
        fork(attemptFetchLinkedDocumentsWatcher),
        fork(descriptionUpdatedWatcher),
        fork(renameDocumentDescriptionsWatcher),
        fork(createNewEntityFromMLDataWatcher),
        fork(initialAIWatcher),
        fork(runLatestMLQueriesWatcher),
        fork(downloadCDMPreviewWatcher),
        fork(fetchAllPreExecutionDocumentsWatcher),
        fork(preExecutionPaginationNextWatcher),
        fork(preExecutionPaginationPreviousWatcher),
        fork(preExecutionTableConfigUpdatedWatcher),
        fork(documentDetailsClosedWatcher),
        fork(attemptUpdateDocumentCustomFiltersWatcher),
        fork(attemptSetSelectedCustomFilterIdWatcher),
        fork(checkOriginalTableFiltersUpdatedWatcher)
    ]);
}
