import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';

import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Icon } from '../../../shared/icon/Icon';
import { Delete, Document, Robot } from '../../../shared/icons';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { OverflowTooltip } from '../../../shared/tooltip';
import { addDocumentDatasetSectionReference, deleteDocumentDatasetSectionReference, getCurrentDocumentSpecificSectionReferences, getSectionHasSpecificReferences, updateDocumentDatasetSectionReferenceOrder } from '../store';
import styles from './ConfigureDatasets.module.scss';
import { Button } from '../../../shared/button/Button';
import { IconButton } from '../../../shared/button/IconButton';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DragDrop } from '../../../shared/drag-n-drop/DragDrop';
import { DraggableItem } from '../../../shared/drag-n-drop/shared';
import { Text } from '../../../shared/text/Text';

const { green, primary } = styles;

interface SectionPageRefProps {
    datasetId: number;
    documentNames: DropdownOption[];
    label: string;
    sectionId: string;
}

export const SectionPageRef: React.FC<SectionPageRefProps> = ({ datasetId, documentNames, label, sectionId }) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<DropdownOption>(documentNames[0]);
    const [sectionReference, setSectionReference] = useState<string>('');

    const documentNameIds = documentNames.map(({ value }) => parseInt(value));
    const selectedDocumentNameId = useMemo(() => parseInt(selectedTab.value), [selectedTab]);
    const references = useAppSelector(getCurrentDocumentSpecificSectionReferences(datasetId, selectedDocumentNameId, sectionId));
    const sectionHasRefs = useAppSelector(getSectionHasSpecificReferences(datasetId, documentNameIds, sectionId));
    const iconFill = useMemo(() => sectionHasRefs ? green : primary, [sectionHasRefs]);

    const closeModal = useCallback(() => {
        setSelectedTab(documentNames[0]);
        setIsOpen(false);
    }, [setIsOpen, setSelectedTab, documentNames]);

    const wizardTabs = useMemo(() => documentNames.map((tab) => ({
        label: tab.label,
        selected: tab.value === selectedTab.value,
        onClick: () => setSelectedTab(tab),
        disabled: false,
        id: tab.value
    })), [setSelectedTab, selectedTab, documentNames]);

    const selectedTabIndex = documentNames.indexOf(selectedTab);

    const scrollToTab = useCallback((tabId: string) => {
        const sectionRefModal = document.getElementById(tabId);
        sectionRefModal?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }, []);

    const previous = useCallback(() => {
        setSelectedTab(documentNames[selectedTabIndex - 1]);
        scrollToTab(documentNames[selectedTabIndex - 1].value);
    }, [documentNames, setSelectedTab, selectedTabIndex, scrollToTab]);

    const next = useCallback(() => {
        setSelectedTab(documentNames[selectedTabIndex + 1]);
        scrollToTab(documentNames[selectedTabIndex + 1].value);
    }, [documentNames, setSelectedTab, selectedTabIndex, scrollToTab]);

    const nextDisabled = useMemo(() => selectedTabIndex === documentNames.length - 1, [selectedTabIndex, documentNames]);
    const referenceAlreadyExists = useMemo(() => references.includes(sectionReference), [references, sectionReference]);
    const addReferenceTooltip = useMemo(() => referenceAlreadyExists ? ['Reference already exists'] : [], [referenceAlreadyExists]);

    const addSectionLabel = useCallback(() => {
        if (!referenceAlreadyExists) {
            dispatch(addDocumentDatasetSectionReference(datasetId, selectedDocumentNameId, sectionId, sectionReference));
            setSectionReference('');
        }
    }, [dispatch, datasetId, sectionId, setSectionReference, sectionReference, selectedDocumentNameId, referenceAlreadyExists]);

    const deleteSectionLabel = useCallback((reference: string) => dispatch(deleteDocumentDatasetSectionReference(datasetId, selectedDocumentNameId, sectionId, reference)), [dispatch, datasetId, sectionId, selectedDocumentNameId]);

    const draggableList: DraggableItem[] = useMemo(() => references.map(ref => ({ id: ref, label: ref })), [references]);

    const getChildElement = useCallback((childId: string, index: number) => {
        const tile = references.find(ref => ref === childId);
        if (tile) {
            return (
                <div key={index} className={styles.referenceWrapper}>
                    <OverflowTooltip overlayText={`${index + 1}: ${childId}`} className={styles.reference} />
                    <div className={styles.removeTagWrapper}>
                        <IconButton onClick={() => deleteSectionLabel(childId)} icon={Delete} fontSize={15} />
                    </div>
                </div>
            );
        }
        return null;
    }, [references, deleteSectionLabel]);

    const updateList = (list: DraggableItem[]) => {
        const newOrder = list.map(({ id }) => draggableList.find(tile => tile.id === id)!).map(({ label }) => label);
        dispatch(updateDocumentDatasetSectionReferenceOrder(datasetId, selectedDocumentNameId, label, newOrder));
    };

    const keyPressEnter = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => e.key.toLowerCase() === 'enter' && addSectionLabel(), [addSectionLabel]);
    const wizardTabTestId = `document-specific-section-references-wizard-tab-${label.toLowerCase().replace(/ +/g, '-').replace(/&/g, 'and')}-label`;

    return (
        <>
            <div className={styles.sectionRefIcon} onClick={() => setIsOpen(true)}><Icon icon={Robot} fontSize={22} color={iconFill} /></div>
            <ConfirmationModal
                isOpen={isOpen}
                closeModal={closeModal}
                showClose={false}
                closeOnOverlayClick={false}
                showConfirm={false}
            >
                <div className={styles.wizardWrapper}>
                    <ModalHeader icon={Document} label={`Alternative Identifiers for: ${label}`} testId='document-specific-section-references' />
                    <div className={styles.tabsWrapper}>
                        <Scrollable>
                            <div className={styles.tabWrapper}>
                                {wizardTabs.map(({ label, onClick, selected, disabled, id }, index) => (
                                    <button
                                        key={index}
                                        onClick={onClick}
                                        className={classnames(styles.wizardTab, {
                                            [styles.selectedTab]: selected
                                        })}
                                        disabled={disabled}
                                        id={id}
                                    >
                                        <div className={styles.content} data-testid={wizardTabTestId}>
                                            <div>{label}</div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </Scrollable>
                    </div>
                    <div className={styles.referencesList}>
                        {references.length > 0 ? <DragDrop getChildElement={getChildElement} list={draggableList} listId='section-reference' updateList={updateList} /> : <div className={styles.emptyList}>Add alternative identifiers below...</div>}
                    </div>
                    <div className={styles.sectionIdInputWrapper}>
                        <Text
                            onChange={e => setSectionReference(e.target.value)}
                            value={sectionReference}
                            placeholder='Alternative Identifier...'
                            maxLength={256}
                            width='calc(100% - 150px)'
                            onKeyDown={keyPressEnter}
                            testId='document-specific-section-references-input'
                            marginBottom='0px'
                            marginRight='10px'
                        />
                        <Button onClick={addSectionLabel} label='Add' disabled={referenceAlreadyExists} disabledTooltip={addReferenceTooltip} />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div className={styles.leftButtonWrapper}>
                            <Button onClick={closeModal} label='Close' />
                        </div>
                        <div className={styles.rightButtonsWrapper}>
                            {selectedTabIndex !== 0 && <Button onClick={previous} label='Previous' disabled={selectedTabIndex === 0} />}
                            {selectedTabIndex !== documentNames.length - 1 && <Button onClick={next} label='Next' disabled={nextDisabled} />}
                        </div>
                    </div>
                </div>
            </ConfirmationModal>
        </>
    );
};
