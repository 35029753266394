import React, { useState, useMemo, useCallback } from 'react';
import { isNull } from 'lodash';

import { ConfirmationModal } from '../../../../shared/modal/ConfirmationModal';
import { ContractualDataAnalytics } from '../../store';
import styles from '../../Analytics.module.scss';
import { ModalHeader } from '../../../../shared/modal/ModalHeader';
import { Document, Search } from '../../../../shared/icons';
import { TextWithIcon } from '../../../../shared/text/TextWithIcon';
import { Icon } from '../../../../shared/icon/Icon';

interface ContractualInformationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    contractualInfoData: ContractualDataAnalytics[];
}

type CompanyAndLinkedFunctionCompaniesProps = Omit<ContractualDataAnalytics, 'doraSupplyChainAssessmentId' | 'doraSupplyChainContractId' | 'doraFunctionCompanyId' | 'doraSupplyChainId'>

const CompanyAndLinkedFunctionCompanies: React.FC<CompanyAndLinkedFunctionCompaniesProps> = ({ functionName, companyName, intraGroupCompanyName, thirdPartyCompanyName }) => (
    <div className={styles.contractualInfoCardWrapper}>
        <div className={styles.contractualInfoCardHeader}>Company Providing ICT Service: <span>{intraGroupCompanyName ? intraGroupCompanyName : thirdPartyCompanyName}</span></div>
        <div className={styles.contractualInfoCardDescription}>Supply Chain in which service is performed: <span>{companyName} - {functionName}</span></div>
    </div>
);

export const ContractualInformationModal: React.FC<ContractualInformationModalProps> = ({ isOpen, closeModal, contractualInfoData }) => {
    const [filter, setFilter] = useState<string>('');

    const filteredContractualInfo = useMemo(() => {
        const lowerCaseFilter = filter.toLowerCase();
        return filter.length > 0 ? contractualInfoData.filter(({ intraGroupCompanyName, thirdPartyCompanyName }) => {
            const filterBy = isNull(intraGroupCompanyName) ? thirdPartyCompanyName : intraGroupCompanyName;
            return filterBy!.toLowerCase().includes(lowerCaseFilter);
        }) : contractualInfoData;
    }, [contractualInfoData, filter]);

    const handleCloseModal = useCallback(() => {
        closeModal();
        setFilter('');
    }, [setFilter, closeModal]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={handleCloseModal}
            closeLabel='Close'
            testId='dora-contractual-information'
            showConfirm={false}
        >
            <ModalHeader
                label='Contractual and Assessment Information Breakdown'
                testId='contractual-info-modal'
                icon={Document}
            />
            <div className={styles.contractualInfoFilterWrapper}>
                <TextWithIcon
                    testId='search-supply-chain-provider'
                    placeholder='Search by ICT Provider...'
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    iconRenderer={<Icon icon={Search} />}
                    width='250px'
                />
            </div>
            <div className={styles.contractualInfoModalWrapper}>
                {filteredContractualInfo.map(({ companyName, functionName, intraGroupCompanyName, thirdPartyCompanyName }, i) => <CompanyAndLinkedFunctionCompanies
                    key={i}
                    companyName={companyName}
                    functionName={functionName}
                    intraGroupCompanyName={intraGroupCompanyName}
                    thirdPartyCompanyName={thirdPartyCompanyName}
                />
                )}
            </div>
        </ConfirmationModal>
    );
};
