import { flow, set } from 'lodash/fp';

import { ArkDocument, DocumentType } from '../../components/documents/my-documents/store';
import { convertLocalToUTCDate } from '../../components/shared/datepicker/DatePicker';
import { MLData, MLDataValue } from '../../components/datasets/store/mlTypes';

const getDocumentType = (mlData: MLData) => {
    if (mlData.isAmendment) {
        return DocumentType.AMENDMENT;
    }
    if (mlData.isRestatement) {
        return DocumentType.RESTATEMENT;
    }
    return DocumentType.ORIGINAL;
};

const calculateDatedAsOf = (rawDatedAsOf: MLDataValue) => {
    if (typeof rawDatedAsOf !== 'string') {
        return null;
    }
    const dateString = rawDatedAsOf.replace(/(\d+)(st|nd|rd|th)/g, '$1');
    const datedAsOfLocal = new Date(dateString);
    if (isNaN(datedAsOfLocal.getTime())) {
        return null;
    }
    return convertLocalToUTCDate(datedAsOfLocal);
};

export const updateIncompleteDocumentWithMLData = (document: ArkDocument, mlData: MLData) => {
    const documentType = getDocumentType(mlData);
    const entityA = mlData.entityA || null;
    const entityB = mlData.entityB || null;
    const documentNameId = mlData.documentNameId || null;
    const datedAsOf = calculateDatedAsOf(mlData.datedAsOf);

    return flow(
        set('documentType', documentType),
        set('entityA', entityA),
        set('entityB', entityB),
        set('documentNameId', documentNameId),
        set('datedAsOf', datedAsOf),
        set('executedDate', datedAsOf),
        set('originalDocumentId', mlData.originalDocumentId)
    )(document);
};
