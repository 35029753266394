import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Section } from './dataset-components/Section';
import { SectionButton } from './dataset-components/SectionButton';
import { SectionModal } from './dataset-components/SectionModal';
import styles from './DatasetBuilder.module.scss';
import { addSection, closeSectionModal, getSectionModalOpen, getSections, getSelectedSection, openSectionModal, removeSection, saveSection, SectionOpen, selectSection, updateSection } from './store';

export const FormDatasetBuilder: React.FC = () => {
    const dispatch = useAppDispatch();
    const openSection = (id: string) => {
        dispatch(selectSection(id));
    };

    const deleteSection = (id: string) => {
        dispatch(removeSection(id));
    };

    const editSection = (id: string) => {
        dispatch(openSectionModal(id));
    };

    const isEditSection = (sectionModal: SectionOpen | null) => sectionModal === SectionOpen.EDIT;

    const sectionModalOpen = useAppSelector(getSectionModalOpen);
    const closeModal = () => dispatch(closeSectionModal());
    const saveSelectedSection = () => isEditSection(sectionModalOpen) ? dispatch(saveSection()) : dispatch(addSection());

    const updateLabel = (value: string) => dispatch(updateSection('label', value));
    const updateDescription = (value: string) => dispatch(updateSection('description', value));

    const sections = useAppSelector(getSections);
    const selectedSection = useAppSelector(getSelectedSection);

    const isSingleSection = useMemo(() => !isNull(sections) && sections.length === 1, [sections]);

    return (
        <div className={styles.formBuilder} data-testid='form-dataset-builder-wrapper'>
            <div className={styles.sectionSelection}>
                {sections && sections.length ? (
                    <Scrollable>
                        <div className={styles.sectionWrapper}>
                            {sections.map((section, index) => (
                                <SectionButton
                                    key={section.id}
                                    openSection={openSection}
                                    section={section}
                                    selectedSection={selectedSection}
                                    index={index}
                                    deleteSection={deleteSection}
                                    editSection={editSection}
                                />
                            ))}
                        </div>
                    </Scrollable>
                ) : <div className={styles.sectionPlaceholder} data-testid='form-dataset-builder-no-sections'>Create a section to begin building Dataset</div>}
            </div>
            {selectedSection && <Section section={selectedSection} />}
            <SectionModal
                selectedSection={selectedSection}
                sectionModalOpen={!!sectionModalOpen}
                closeModal={closeModal}
                confirm={saveSelectedSection}
                updateLabel={updateLabel}
                updateDescription={updateDescription}
                isEdit={isEditSection(sectionModalOpen)}
                isSingleSection={isSingleSection}
            />
        </div>
    );
};
