import React, { useMemo } from 'react';
import { RawDraftContentState } from 'draft-js';
import { isNull } from 'lodash/fp';

import { useAppDispatch } from '../../../hooks/react-redux';
import { OpenField, OpenFieldSection, OpenFieldType, updateFieldValue } from '../instances/store';
import { WYSIWYG, isEmpty } from '../../shared/wysiwyg/WYSIWYG';
import { CaretDown, CaretSide } from '../../shared/icons';
import { Icon } from '../../shared/icon/Icon';
import styles from './Fields.module.scss';

const { amethyst, green, grey } = styles;

interface WYSIWYGFieldProps {
    id: string;
    index: number;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    value: RawDraftContentState | null;
    fieldOpen?: boolean;
    isCollapsible?: boolean;
    isTable?: boolean;
    disabled?: boolean;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    toolbarHidden?: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
}

export const WYSIWYGField: React.FC<WYSIWYGFieldProps> = ({
    id,
    value,
    disabled = false,
    index,
    groupIndex,
    sectionId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    fieldOpen = false,
    isCollapsible = false,
    isTable = false,
    toolbarHidden = false,
    rowId,
    datasetId,
    parentFieldId,
    modalInstance,
    getSectionOpen,
    toggleSection
}) => {
    const dispatch = useAppDispatch();
    const openField: OpenField = useMemo(() => ({ sectionId, datasetId: datasetId.toString() || '', fieldId: id!, type: OpenFieldType.FIELD, groupIndex, rowId, parentFieldId }), [datasetId, sectionId, id, groupIndex, rowId, parentFieldId]);
    const tableFieldOpen = useMemo(() => getSectionOpen(openField) || !isCollapsible, [getSectionOpen, isCollapsible, openField]);
    const fieldOpenIcon = fieldOpen ? CaretDown : CaretSide;

    const updateValue = (rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            if (!isNull(value)) {
                dispatch(updateFieldValue(datasetId, parentFieldId, null, index, sectionId, groupIndex, rowId, modalInstance));
            }
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, rawValue, index, sectionId, groupIndex, rowId, modalInstance));
        }
    };

    const borderColor = useMemo(() => {
        if (showDatasetUpdated) {
            return green;
        }
        if (showFieldUpdated) {
            return amethyst;
        }
        return grey;
    }, [showDatasetUpdated, showFieldUpdated]);

    // 89px is the height of the toolbar
    const height = toolbarHidden ? '200px' : '289px';

    return (
        <div className={styles.wysiwygWrapper} data-testid='definition-wysiwyg-wrapper'>
            {isCollapsible && isTable &&
                <div className={styles.fieldOpenWrapper} onClick={() => toggleSection(openField)} data-testid='table-definition-wysiwyg-wrapper-collapse'>
                    <Icon icon={fieldOpenIcon} fontSize={15} />
                </div>
            }
            {(fieldOpen || tableFieldOpen) &&
                <WYSIWYG
                    content={value}
                    updateContent={updateValue}
                    disabled={disabled}
                    borderColor={borderColor}
                    toolbarHidden={toolbarHidden}
                    height={height}
                    maxHeight={height}
                    minHeight={height}
                    isReadOnly={disabled}
                    showBorder={!toolbarHidden}
                />
            }
        </div>
    );
};
