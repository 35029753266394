import { RawDraftContentState } from 'draft-js';

import { ApplicationState } from '../../../../store/rootReducer';
import { NotificationTemplate, SendNotificationState } from './types';

const getRoot = (state: ApplicationState): SendNotificationState => state.admin.sendNotification;

export const getNotificationTitle = (state: ApplicationState): string => getRoot(state).title;

export const getNotificationContent = (state: ApplicationState): RawDraftContentState | null => getRoot(state).content;

export const getNotificationClientIds = (state: ApplicationState): number[] => getRoot(state).clientIds;

export const getNotificationAdminOnly = (state: ApplicationState): boolean => getRoot(state).adminOnly;

export const getIsSending = (state: ApplicationState): boolean => getRoot(state).isSending;

export const getSendingError = (state: ApplicationState): string | null => getRoot(state).sendError;

export const getNotificationToSend = (state: ApplicationState): NotificationTemplate => ({
    title: getNotificationTitle(state),
    content: getNotificationContent(state),
    clientIds: getNotificationClientIds(state),
    adminOnly: getNotificationAdminOnly(state)
});

export const getPreviewModalOpen = (state: ApplicationState): boolean => getRoot(state).previewOpen;
