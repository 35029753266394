import React, { useCallback, useMemo } from 'react';
import { isEqual, isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Delete, Home, Tick } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import styles from './MyCompanies.module.scss';
import { MyCompanyFunctionsPerformedTable } from './MyCompanyFunctionsPerformedTable';
import { getCurrentEntityFunctionIds, getSelectedEntityFunctionIds, getDoraCompanyEntityModalOpen, getSelectedDoraCompanyEntity, setSelectedCompanyEntity, toggleCompanyEntityModal, fetchAvailableFunctionsForMigrationStarted, getSavedDoraCompanyEntity, getEntityIsSaving } from './store';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { FeaturePermission } from '../../admin/users/store';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { InformationTooltip, OverflowTooltip } from '../../shared/tooltip';
import { Icon } from '../../shared/icon/Icon';

const { green, red } = styles;

export const MyCompaniesModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getDoraCompanyEntityModalOpen);
    const closeModal = useCallback(() => {
        dispatch(setSelectedCompanyEntity(null));
        dispatch(toggleCompanyEntityModal(false));
    }, [dispatch]);
    const selectedEntity = useAppSelector(getSelectedDoraCompanyEntity);
    const savedEntity = useAppSelector(getSavedDoraCompanyEntity);
    const selectedEntityFunctionIds = useAppSelector(getSelectedEntityFunctionIds);
    const currentEntityFunctionIds = useAppSelector(getCurrentEntityFunctionIds);
    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));
    const entityIsSaving = useAppSelector(getEntityIsSaving);

    const attemptSaveEntity = useCallback(() => dispatch(fetchAvailableFunctionsForMigrationStarted()), [dispatch]);

    const saveDisabled = useMemo(() => !hasDoraFullAccessPermission || (isEqual(selectedEntityFunctionIds, currentEntityFunctionIds) && isEqual(selectedEntity?.content.entityServiceType, savedEntity?.content.entityServiceType)), [selectedEntityFunctionIds, currentEntityFunctionIds, hasDoraFullAccessPermission, selectedEntity, savedEntity]);

    if (isNull(selectedEntity)) {
        return null;
    }

    const { doraInScope, content: { lei, jurisdiction, entityServiceType, dateOfRoiDeletion, dateOfRoiIntegration, doraEntityHierarchy, totalAssetsValue, competentAuthority, otherCompetentAuthority, branches }, name, doraMaintainerROI } = selectedEntity;

    const inScope = doraInScope === 1;
    const roiMaintainer = doraMaintainerROI === 1;

    const hierarchy = isNull(doraEntityHierarchy) ? '' : doraEntityHierarchy;
    const companyAssets = (isNull(totalAssetsValue) || isUndefined(totalAssetsValue)) ? '' : totalAssetsValue;
    const companyAuthority = !isUndefined(otherCompetentAuthority) && competentAuthority === 'Other' ? `Other: ${otherCompetentAuthority}` : competentAuthority;

    const detailsHeight = inScope ? 'calc(55% - 20px)' : '100%';

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel='Save'
            confirmDisabled={saveDisabled}
            confirm={attemptSaveEntity}
            testId='my-companies'
            confirmDisabledTooltip={undefined}
            showConfirmSpinner={entityIsSaving}
        >
            <ModalHeader
                label={name}
                testId='my-companies-modal'
                icon={Home}
            />
            <div className={styles.myCompanyModalWrapper} data-testid='my-companies-modal-wrapper'>
                <div className={styles.myCompanyDetailsWrapper} style={{ height: detailsHeight }}>
                    <div className={styles.entityDetailsWrapper}>
                        <Scrollable>
                            <div className={styles.detailWrapper}>
                                <div className={styles.entityDetailLabel}>Company LEI:</div>
                                <div className={styles.entityDetail}>{lei || ''}</div>
                            </div>
                            <div className={styles.detailWrapper}>
                                <div className={styles.entityDetailLabel}>Jurisdiction:</div>
                                <div className={styles.entityDetail}>{jurisdiction || ''}</div>
                            </div>
                            <div className={styles.detailWrapper}>
                                <div className={styles.entityDetailLabel}>For the purposes of DORA, this entity is a:</div>
                                <OverflowTooltip className={styles.entityDetail} overlayText={entityServiceType?.value || ''} />
                            </div>
                            <div className={styles.detailWrapper}>
                                <div className={styles.entityDetailLabel}>Date of integration into the ROI:</div>
                                <div className={styles.entityDetail}>{dateOfRoiIntegration}</div>
                            </div>
                            {!inScope && <div className={styles.detailWrapper}>
                                <div className={styles.entityDetailLabel}>Date of deletion from the ROI:</div>
                                <div className={styles.entityDetail}>{dateOfRoiDeletion}</div>
                            </div>}
                            <div className={styles.detailWrapper}>
                                <div className={styles.entityDetailLabel}>Hierarchy of the entity within the group (where applicable):</div>
                                <OverflowTooltip className={styles.entityDetail} overlayText={hierarchy} />
                            </div>
                            <div className={styles.detailWrapper}>
                                <div className={styles.entityDetailLabel}>Value of total assets - of the financial entity:</div>
                                <div className={styles.entityDetail}>{companyAssets}</div>
                            </div>
                            {roiMaintainer &&
                                <div className={styles.detailWrapper}>
                                    <OverflowTooltip className={styles.entityDetail} overlayText={companyAuthority} />
                                </div>
                            }
                        </Scrollable>
                    </div>
                    <div className={styles.branchesWrapper}>
                        <div className={styles.branchesLabelWrapper}>
                            <div className={styles.branchesLabel}>Branches</div>
                            <InformationTooltip content='Branch information can be updated via the Entity Administration section' />
                        </div>
                        <div className={styles.entityBranchesWrapper}>
                            {branches.length > 0 ?
                                <Scrollable>
                                    {branches.map((branch, index) => {
                                        const { name, inScopeForDora } = branch;
                                        const icon = inScopeForDora && inScope ? Tick : Delete;
                                        const iconColour = inScopeForDora && inScope ? green : red;
                                        return (
                                            <div key={index} className={styles.entityBranch}>
                                                <OverflowTooltip className={styles.entityBranchName} overlayText={name} />
                                                <Icon icon={icon} fontSize={20} color={iconColour} />
                                            </div>
                                        );
                                    })}
                                </Scrollable> : <div className={styles.noEntityBranches}>Selected Company has no branches</div>}
                        </div>
                    </div>
                </div>
                {inScope && <MyCompanyFunctionsPerformedTable />}
            </div>
        </ConfirmationModal>
    );
};
