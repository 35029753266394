import React, { useCallback, useState } from 'react';

import styles from './Search.module.scss';
import { Text } from '../text/Text';
import { Button } from '../button/Button';

interface SimpleSearchProps {
    searchTerm: string;
    updateSearchTerm: (searchTerm: string) => void;
    resetSimpleSearch: () => void;
    testId: string;
    searchDisabled?: boolean;
}

export const SimpleSearch: React.FC<SimpleSearchProps> = ({ searchTerm, updateSearchTerm, resetSimpleSearch, testId, searchDisabled = false }) => {

    const [simpleSearchTerm, setSimpleSearchTerm] = useState<string>(searchTerm);
    const resetDisabled = !searchTerm;

    const startSimpleSearch = useCallback(() => updateSearchTerm(simpleSearchTerm), [updateSearchTerm, simpleSearchTerm]);

    const attemptSimpleSearchKeydown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter') {
            startSimpleSearch();
        }
    }, [startSimpleSearch]);

    const resetSearchTerm = useCallback(() => {
        setSimpleSearchTerm('');
        resetSimpleSearch();
    }, [setSimpleSearchTerm, resetSimpleSearch]);

    return (
        <div className={styles.simpleSearchWrapper}>
            <div className={styles.searchInputWrapper} data-testid={`${testId}-simple-search-wrapper`}>
                <div className={styles.inputTextWrapper}>
                    <Text
                        testId={`simple-search-${testId}`}
                        placeholder='Search...'
                        marginBottom=''
                        value={simpleSearchTerm}
                        onChange={e => setSimpleSearchTerm(e.target.value)}
                        onKeyDown={attemptSimpleSearchKeydown}
                    />
                </div>
                <Button label='Reset' onClick={resetSearchTerm} disabled={resetDisabled} testId={`reset-${testId}-simple-search`} />
                <Button label='Search' onClick={startSimpleSearch} disabled={searchDisabled} testId={`${testId}-simple-search`} />
            </div>
        </div>
    );
};
