import React from 'react';
import { isUndefined } from 'lodash/fp';

import { TooltipList } from '../tooltip';
import styles from './Table.module.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const AddressCell: React.FC<{ valueFormatted?: string[] | null; value: any; }> = ({ valueFormatted, value }) => {
    if (isUndefined(valueFormatted) && isUndefined(value)) {
        return null;
    }

    return (
        <div className={styles.addressCellWrapper}>
            <TooltipList overlayText={valueFormatted!} showBulletPoints={false}>
                <div className={styles.addressCell}>{value}</div>
            </TooltipList>
        </div>
    );
};
