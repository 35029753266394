import React, { useMemo } from 'react';
import classnames from 'classnames';
import { isEqual, isUndefined } from 'lodash/fp';
import { RawDraftContentState } from 'draft-js';

import styles from '../../../../../shared/datasets/SharedStyling.module.scss';
import { DatasetFieldType, TableDatasetFieldType } from '../../../../store';
import { Text, Number, LongText, DateField, Dropdown, Checkbox, DatasetLink, Time, DocumentField, Wizard, WYSIWYGField, PartySelect } from '../../../../fields';
import { AnnexInstanceField, OpenFieldSection, SingleInstanceField, UpdatedTableField } from '../../../store';
import { widthsByType } from '../../../../../admin/dataset-builder/preview/tablePreview/TableHeader';
import { CalculatorCell } from '../../../../fields/CalculatorCell';
import { CalculatorField } from '../../../../../shared/calculator/constants';
import { InstanceIdentifier } from '../../../../store/mlTypes';
import { SelectedMLQuestion } from './MLTableInstance';
import { CurrencyAmountField } from '../../../../fields/CurrencyAmountField';
import { CurrencyValue } from '../../../../../shared/number/CurrencyNumber';

const getField = (
    field: SingleInstanceField | AnnexInstanceField,
    index: number,
    rowId: string,
    disabled: boolean,
    showFieldUpdated: boolean,
    showDatasetUpdated: boolean,
    showAIModified: boolean,
    showAIModifiedUserCorrected: boolean,
    includedInAnnex: boolean,
    showClause: boolean,
    isTable: boolean,
    datasetId: number,
    parentFieldId: string,
    toggleSection: (fieldSection: OpenFieldSection) => void,
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean,
    modalInstance?: boolean
) => {
    const defaultProps = { id: field.id!, index, disabled, rowId, showFieldUpdated, showDatasetUpdated, showClause, isTable, includedInAnnex, modalInstance, parentFieldId, showAIModified, showAIModifiedUserCorrected, isMLCorrection: true };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} value={field.value as string} datasetId={datasetId} />;
        case DatasetFieldType.LONGTEXT:
            return <LongText {...defaultProps} value={field.value as string} datasetId={datasetId} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={field.value as null | Date} datasetId={datasetId} />;
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
            return <Dropdown
                {...defaultProps}
                value={field.value as null | string[]}
                type={field.type}
                listId={field.settings.dropdownLinked!}
                isMulti={field.settings.isMultiSelect}
                entityType={field.settings.entityType}
                datasetId={datasetId}
                menuPortalTarget={document.body}
            />;
        case DatasetFieldType.PARTY:
            return <PartySelect
                {...defaultProps}
                value={field.value as null | string[]}
                datasetId={datasetId}
                menuPortalTarget={document.body}
            />;
        case DatasetFieldType.DOCUMENT:
            return <DocumentField {...defaultProps} id={field.id!} value={field.value as string[]} showClause />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} value={field.value as number} datasetId={datasetId} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={field.value as boolean} datasetId={datasetId} centreField={field.settings.centreField} />;
        case DatasetFieldType.DATASET:
            return <DatasetLink {...defaultProps} value={field.value as string | null} childDatasetId={field.settings.datasetLinked!} datasetId={datasetId!} />;
        case DatasetFieldType.TIME:
            return <Time {...defaultProps} value={field.value as string[]} datasetId={datasetId} />;
        case DatasetFieldType.WIZARD:
            return <Wizard {...defaultProps} value={field.value as string[]} field={field} datasetId={datasetId} toggleSection={toggleSection} getSectionOpen={getSectionOpen} />;
        case DatasetFieldType.WYSIWYG:
            return <WYSIWYGField
                {...defaultProps}
                value={field.value as RawDraftContentState | null}
                isCollapsible={field.settings.isCollapsible}
                toolbarHidden={disabled}
                datasetId={datasetId}
                toggleSection={toggleSection}
                getSectionOpen={getSectionOpen}
            />;
        case DatasetFieldType.CALCULATOR:
            return <CalculatorCell
                {...defaultProps}
                value={field.value as CalculatorField[]}
                fieldType={field.settings.calculatorFieldType!}
                datasetId={datasetId}
                showOutputField={field.settings.showOutputField!}
                showGracePeriod={field.settings.showGracePeriod!}
                includeFreeTextOption={field.settings.includeFreeTextOption}
            />;
        case DatasetFieldType.CURRENCY_AMOUNT:
            return <CurrencyAmountField {...defaultProps} currencyValue={field.value as CurrencyValue} datasetId={datasetId} />;
        default:
            return <div className={styles.unknownFieldType}>Field type unknown.</div>;
    }
};

interface MLTableCellProps {
    field: SingleInstanceField | AnnexInstanceField;
    index: number;
    isFirstCell: boolean;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedTableField[];
    rowId: string;
    annexFieldIds: string[];
    isAnnexInstance: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    columnCollapsed: boolean;
    instanceExecutedDateMatchesParent: boolean;
    cellMlData: InstanceIdentifier | undefined;
    mlQuestionKey: SelectedMLQuestion | null;
    edgeCaseMlCell: InstanceIdentifier | undefined;
}

export const MLTableCell: React.FC<MLTableCellProps> = ({
    field,
    index,
    isFirstCell,
    isEditing,
    rowId,
    isUpdating,
    fieldsUpdated,
    annexFieldIds,
    isAnnexInstance,
    datasetId,
    parentFieldId,
    modalInstance,
    toggleSection,
    getSectionOpen,
    columnCollapsed,
    instanceExecutedDateMatchesParent,
    cellMlData,
    mlQuestionKey,
    edgeCaseMlCell
}) => {
    const cellWidth = useMemo(() => columnCollapsed ? '16px' : `${widthsByType[field.type as TableDatasetFieldType]}px`, [columnCollapsed, field.type]);
    const fieldUpdatedByInstance = useMemo(() => !!fieldsUpdated.map(({ id, row }) => ({ id, row })).find(rowAndId => isEqual({ id: field.id!, row: rowId }, rowAndId)) && instanceExecutedDateMatchesParent, [field, fieldsUpdated, instanceExecutedDateMatchesParent, rowId]);
    const showFieldUpdated = fieldUpdatedByInstance;
    const newDatasetField = field.settings.datasetUpdated || false;
    const showDatasetUpdated = newDatasetField;
    const showAIModified = !!field.settings.aiModified;
    const showAIModifiedUserCorrected = !!field.settings.aiModifiedUserCorrected;
    const disabled = !isEditing && !(isUpdating && (fieldUpdatedByInstance || newDatasetField)) || (isUndefined(cellMlData) && isUndefined(edgeCaseMlCell));
    const includedInAnnex = !isAnnexInstance && annexFieldIds.includes(field.id!);
    const highlightCell = useMemo(() => mlQuestionKey && mlQuestionKey.key === cellMlData?.key, [mlQuestionKey, cellMlData]);

    return (
        <div
            className={classnames(styles.cellWrapper, {
                [styles.firstCell]: isFirstCell,
                [styles.mlCell]: (!isUndefined(cellMlData) || !isUndefined(edgeCaseMlCell)),
                [styles.mlCellHighlight]: highlightCell,
            })}
            style={{ width: cellWidth, minWidth: cellWidth }}
            data-testid={`table-cell-${index}-wrapper`}
            id={`ml-dataset-instance-field-${parentFieldId}`}
        >
            {columnCollapsed ? (<div className={styles.collapsedPlaceholder} />) : (
                <div className={styles.openCellWrapper}>
                    {getField(field, index, rowId, disabled, showFieldUpdated, showDatasetUpdated, showAIModified, showAIModifiedUserCorrected, includedInAnnex, field.settings.showClause || false, true, datasetId, parentFieldId, toggleSection, getSectionOpen, modalInstance)}
                </div>
            )}
        </div>
    );
};
