import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DATABASE_DATE_FORMAT, formatDate } from '../../../../utils/luxon';
import { getAllDropdownLists } from '../../../admin/dropdown-lists/store';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { getFocusOptions, opinionCommissionedByOptions } from '../../../constants/opinion';
import { DatePicker } from '../../../shared/datepicker/DatePicker';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Text } from '../../../shared/text/Text';
import { ArkOpinion, incompleteOpinionUpdateValue } from '../store';
import styles from './UploadOpinions.module.scss';
import { OpinionCommissionedBy, OpinionScope } from '../../../admin/opinions/store';

interface OpinionDetailsProps {
    opinion: ArkOpinion;
}

export const OpinionDetails: React.FC<OpinionDetailsProps> = ({ opinion }) => {
    const dispatch = useAppDispatch();
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const hasIndustryStandardPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));
    const { description, jurisdiction, commissionedBy, commissionedByIfOther, scope, type, dateOfOpinion, bespoke, focus } = opinion;

    const bespokeOptions = useMemo(() => {
        const allOptions = [{ label: 'Bespoke', value: '1' }, { label: 'Industry Standard', value: '0' }];
        if (hasIndustryStandardPermission) {
            return allOptions;
        }
        return allOptions.filter(({ label }) => label === 'Bespoke');
    }, [hasIndustryStandardPermission]);

    const bespokeValue = useMemo(() => bespokeOptions.find(({ value }) => parseInt(value) === bespoke) || { label: 'Bespoke', value: '1' }, [bespokeOptions, bespoke]);

    const updateDropdownValue = (key: keyof ArkOpinion, dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = (dropdownValue as DropdownOption).value;
        }
        dispatch(incompleteOpinionUpdateValue(key, value));
        if (key === 'scope' && value === OpinionScope.GMRA_GMSLA_NETTING && bespoke === 0) {
            dispatch(incompleteOpinionUpdateValue('commissionedBy', OpinionCommissionedBy.ICMA));
        }
    };

    const updateDateValue = (key: keyof ArkOpinion, value: Date | null) => {
        const date = value ? formatDate(value, DATABASE_DATE_FORMAT) : null;
        dispatch(incompleteOpinionUpdateValue(key, date));
    };

    const getDropdownListOptions = useCallback((listName: string) => {
        const dropdownList = dropdownLists.find(({ name }) => name === listName);
        return dropdownList ? dropdownList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const getDropdownValue = useCallback((initialValue: string | null, options: DropdownOption[]) => {
        if (!initialValue) {
            return null;
        }
        return options.find(({ value }) => value === initialValue) || null;
    }, []);

    const opinionDateValue = useMemo(() => dateOfOpinion ? new Date(dateOfOpinion) : null, [dateOfOpinion]);
    const setOpinionDate = (value: Date | null) => updateDateValue('dateOfOpinion', value);

    const setOpinionDescription = (description: string) => dispatch(incompleteOpinionUpdateValue('description', description));

    const jurisdictionOptions = getDropdownListOptions('OpinionJurisdiction');
    const jurisdictionValue = useMemo(() => getDropdownValue(jurisdiction, jurisdictionOptions), [jurisdiction, getDropdownValue, jurisdictionOptions]);

    const setJurisdiction = (value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('jurisdiction', value);

    const focusOptions = useMemo(() => getFocusOptions(jurisdiction), [jurisdiction]);
    const focusValue = useMemo(() => getDropdownValue(focus, focusOptions), [focus, getDropdownValue, focusOptions]);

    const setFocus = (value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('focus', value);
    const setBespokeFocus = (value: string) => dispatch(incompleteOpinionUpdateValue('focus', value));

    const setOpinionScope = (value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('scope', value);
    const setOpinionType = (value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('type', value);

    const opinionTypeOptions = useMemo(() => [
        { label: 'Original', value: 'Original' },
        { label: 'Update', value: 'Update' },
        { label: 'Blackline', value: 'Blackline' },
        { label: 'Full', value: 'Full' },
        { label: 'Supplemental', value: 'Supplemental' },
        { label: 'Consolidated', value: 'Consolidated' },
        { label: 'Consolidated Update', value: 'Consolidated Update' }
    ], []);

    const opinionTypeValue = useMemo(() => opinionTypeOptions.find(({ value }) => value === type) || null, [opinionTypeOptions, type]);

    const opinionScopeOptions = useMemo(() => [
        { label: 'Validity and enforceability of NETTING arrangements (ISDA Master Agreement)', value: OpinionScope.ISDA_NETTING },
        { label: 'Validity and enforceability of COLLATERAL arrangements (Collateral Provider Insolvency)', value: OpinionScope.COLLATERAL_PROVIDER },
        { label: 'Validity and enforceability of COLLATERAL arrangements (Collateral Taker Insolvency)', value: OpinionScope.COLLATERAL_TAKER },
        { label: 'Validity and enforceability of NETTING arrangements (GMRA)', value: OpinionScope.GMRA_NETTING },
        { label: 'Validity and enforceability of NETTING arrangements (GMSLA)', value: OpinionScope.GMSLA_NETTING },
        { label: 'Validity and enforceability of NETTING arrangements (GMRA & GMSLA)', value: OpinionScope.GMRA_GMSLA_NETTING }
    ], []);

    const commissionedByOptions = useMemo(() => opinionCommissionedByOptions(), []);

    const opinionScopeValue = useMemo(() => opinionScopeOptions.find(({ value }) => value === scope) || null, [opinionScopeOptions, scope]);

    const opinionCommissionedByValue = useMemo(() => commissionedByOptions.find(({ value }) => value === commissionedBy) || null, [commissionedBy, commissionedByOptions]);

    const setOpinionCommissionedByDropdown = (value: DropdownOption | Options<DropdownOption> | null) => {
        let commissionedByIfOther = null;
        if (!isNull(value) && (value as DropdownOption).value === OpinionCommissionedBy.OTHER) {
            commissionedByIfOther = '';
        }
        setOpinionCommissionedByIfOther(commissionedByIfOther);
        updateDropdownValue('commissionedBy', value);
    };
    const setOpinionCommissionedByIfOther = (value: string | null) => dispatch(incompleteOpinionUpdateValue('commissionedByIfOther', value));

    const setBespoke = (option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = parseInt((option as DropdownOption).value);
        }
        dispatch(incompleteOpinionUpdateValue('bespoke', value));
    };

    const hideCommissionedBy = useMemo(() => scope === OpinionScope.GMRA_GMSLA_NETTING && bespoke === 0, [scope, bespoke]);

    return (
        <div data-testid='opinion-details-wrapper' className={styles.opinionDetailsWrapper}>
            <Scrollable>
                <Text
                    label='Description'
                    onChange={e => setOpinionDescription(e.target.value)}
                    value={description}
                    maxLength={256}
                    testId='opinion-description'
                    marginBottom='0'
                />
                <div data-testid='bespoke-wrapper' className={styles.inputWrapper}>
                    <div className={styles.inputHeader}>Bespoke or Industry Standard?</div>
                    <Dropdown
                        value={bespokeValue}
                        options={bespokeOptions}
                        onChange={setBespoke}
                        testId='opinion-bespoke'
                        menuPortalTarget={document.body}
                        isClearable={false}
                    />
                </div>
                <div data-testid='jurisdiction-wrapper' className={styles.inputWrapper}>
                    <div className={styles.inputHeader}>Jurisdiction</div>
                    <Dropdown
                        value={jurisdictionValue}
                        options={jurisdictionOptions}
                        onChange={setJurisdiction}
                        testId='opinion-jurisdiction'
                        menuPortalTarget={document.body}
                    />
                </div>
                <div data-testid='focus-wrapper' className={styles.inputWrapper}>
                    <div className={styles.inputHeader}>Focus</div>
                    {bespoke === 1 ? (
                        <Text
                            onChange={e => setBespokeFocus(e.target.value)}
                            value={focus}
                            maxLength={128}
                            testId='bespoke-opinion-focus'
                            marginBottom='0'
                        />
                    ) : (
                        <Dropdown
                            value={focusValue}
                            options={focusOptions}
                            onChange={setFocus}
                            testId='opinion-focus'
                            menuPortalTarget={document.body}
                            isClearable={false}
                        />)}
                </div>
                <div data-testid='opinion-type-wrapper' className={styles.inputWrapper}>
                    <div className={styles.inputHeader}>Type</div>
                    <Dropdown
                        value={opinionTypeValue}
                        options={opinionTypeOptions}
                        onChange={setOpinionType}
                        testId='opinion-type'
                        menuPortalTarget={document.body}
                    />
                </div>
                <div data-testid='date-of-opinion-wrapper' className={styles.inputWrapper}>
                    <div className={styles.inputHeader}>Date of Opinion</div>
                    <DatePicker
                        value={opinionDateValue}
                        onChange={setOpinionDate}
                        testId='date-of-opinion'
                        maxDate={new Date()}
                    />
                </div>
                <div data-testid='scope-wrapper' className={styles.inputWrapper}>
                    <div className={styles.inputHeader}>Scope</div>
                    <Dropdown
                        value={opinionScopeValue}
                        options={opinionScopeOptions}
                        onChange={setOpinionScope}
                        testId='opinion-scope'
                        menuPortalTarget={document.body}
                    />
                </div>
                {!hideCommissionedBy && <div data-testid='opinion-commissioned-by-wrapper' className={styles.inputWrapper}>
                    <div className={styles.inputHeader}>Commissioned by</div>
                    <Dropdown
                        value={opinionCommissionedByValue}
                        options={commissionedByOptions}
                        onChange={setOpinionCommissionedByDropdown}
                        menuPortalTarget={document.body}
                        testId='opinion-commissioned-by'
                    />
                    {commissionedBy === OpinionCommissionedBy.OTHER &&
                        <div data-testid='opinion-commissioned-by-other-wrapper' className={styles.inputWrapper}>
                            <Text
                                label='Commissioned by other'
                                onChange={e => setOpinionCommissionedByIfOther(e.target.value)}
                                value={commissionedByIfOther || ''}
                                maxLength={256}
                                testId='opinion-commissioned-by'
                                marginBottom='0'
                            />
                        </div>
                    }
                </div>
                }
            </Scrollable>
        </div>
    );
};
