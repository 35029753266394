import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isUndefined } from 'lodash/fp';

import Logo from '../../../assets/logos/Ark51_VERTBLACK&BLACK.png';
import { useWindowResize } from '../../../hooks/useWindowResize';
import styles from './Table.module.scss';
import { Spinner } from '../spinner/Spinner';

const EmptyTable: React.FC = () => (
    <div className={styles.emptyPlaceholder}>
        <img className={styles.logoWrapper} src={Logo} />
    </div>
);

interface TableProps {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    rowData: any[];
    colDefs: ColDef[];
    gridOptions: GridOptions;
    width?: number;
    height?: number;
    testId?: string;
    pagination?: boolean;
    percentageWidths?: boolean;
    columnSort?: boolean;
    columnFilter?: boolean;
    onGridReady?: (api: GridApi) => void;
    onRowDoubleClicked?: (event: RowDoubleClickedEvent) => void;
    padding?: string;
    showLoadingOverlay?: boolean;
}

export const Table: React.FC<TableProps> = ({
    rowData,
    colDefs,
    gridOptions,
    width,
    height,
    testId,
    pagination = true,
    percentageWidths = false,
    columnSort = true,
    columnFilter = true,
    onGridReady,
    onRowDoubleClicked,
    padding = '10px',
    showLoadingOverlay = false
}) => {
    const [screenWidth, screenHeight] = useWindowResize();
    const [gridApi, setGridApi] = useState<null | GridApi>(null);

    const tableWidth = useMemo(() => width ? width : screenWidth * 0.85, [screenWidth, width]);
    const tableHeight = useMemo(() => height ? height : screenHeight * 0.75, [screenHeight, height]);
    const getWidth = useCallback((multiplier: number) => tableWidth * multiplier, [tableWidth]);

    const columnDefs: ColDef[] = useMemo(() =>
        percentageWidths ? colDefs.map(colDef => ({ ...colDef, width: getWidth(colDef.width || 1 / colDefs.length) - 0.5 })) : colDefs
    ,[getWidth, colDefs, percentageWidths]
    );

    const options: GridOptions = useMemo(() => ({
        ...gridOptions,
        headerHeight: 50,
        rowHeight: gridOptions.rowHeight ? gridOptions.rowHeight : (tableHeight - 81) / 20,
        noRowsOverlayComponent: 'emptyTable',
        loadingOverlayComponent: 'loadingSpinner',
        components: {
            ...gridOptions.components,
            emptyTable: EmptyTable,
            loadingSpinner: Spinner
        },
        onRowDoubleClicked: onRowDoubleClicked
    }), [tableHeight, gridOptions, onRowDoubleClicked]);

    const tableOnGridReady = ({ api }: GridReadyEvent) => {
        setGridApi(api);
        if (!isUndefined(onGridReady)) {
            onGridReady(api);
        }
    };

    useEffect(() => {
        if (gridApi) {
            if (showLoadingOverlay) {
                gridApi.showLoadingOverlay();
            } else if (!rowData.length) {
                gridApi.showNoRowsOverlay();
            } else {
                gridApi.hideOverlay();
            }
        }
    }, [gridApi, showLoadingOverlay, rowData]);

    return (
        <div className={styles.tableWrapper} style={{ padding }}>
            <div
                data-testid={`${testId}-table-container`}
                className='ag-theme-balham'
                style={{
                    height: `${tableHeight}px`,
                    width: `${tableWidth}px`
                }}
            >
                <AgGridReact
                    columnDefs={columnDefs}
                    pagination={pagination}
                    rowData={rowData}
                    defaultColDef={{ sortable: columnSort, filter: columnFilter }}
                    gridOptions={options}
                    onGridReady={tableOnGridReady}
                />
            </div>
        </div>
    );
};
