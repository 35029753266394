import { isNull, toArray, flatten, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Options } from 'react-select';
import classnames from 'classnames';

import styles from './ClauseModal.module.scss';
import { Clause, ClauseTagDropdownOptions, ClauseTags, ClientTag, dropdownOptionsByTagType, FuzzyMatchTags, toggleSystemTagCategoryModal } from '../../clause-library/store';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';
import { Position } from '../modal/PositionModal';
import { OverflowTooltip } from '../tooltip';
import { IconButton } from '../button/IconButton';
import { Delete } from '../icons';
import { Scrollable } from '../scrollable/Scrollable';
import { DropdownSummary } from '../dropdown/DropdownSummary';
import { Text } from '../text/Text';
import { Button } from '../button/Button';
import { TagsFuzzyMatchModal } from './TagsFuzzyMatchModal';
import { getUnselectedTagBackgroundColour } from '../../clause-library/clause-tags/client-tags-modal/CurrentClientTags';
import { useAppDispatch } from '../../../hooks/react-redux';
import { ClauseSystemTagCategoryModal } from './ClauseSystemTagCategoryModal';

const { agreementBackground, jurisdictionBackground, counterpartyBackground, productBackground, provisionBackground, miscellaneousBackground, opinionsBackground, lightGrey } = styles;

export const getTagTypeLabel = (value: keyof ClauseTags) => {
    switch (value) {
        case 'agreementType':
            return 'Agreement Type';
        case 'jurisdiction':
            return 'Jurisdiction';
        case 'counterpartyType':
            return 'Counterparty Type';
        case 'productType':
            return 'Product Type';
        case 'provisionType':
            return 'Provision Type';
        case 'opinions':
            return 'Opinions';
        case 'miscellaneous':
        default:
            return 'Miscellaneous';
    }
};

export const tagCategoryOptions: DropdownOption[] = [
    { value: 'agreementType', label: 'Agreement Type' },
    { value: 'jurisdiction', label: 'Jurisdiction' },
    { value: 'counterpartyType', label: 'Counterparty Type' },
    { value: 'productType', label: 'Product Type' },
    { value: 'provisionType', label: 'Provision Type' },
    { value: 'miscellaneous', label: 'Miscellaneous' },
    { value: 'opinions', label: 'Opinions' }
];

export const getTagBackgroundColour = (value: keyof ClauseTags) => {
    switch (value) {
        case 'agreementType':
            return agreementBackground;
        case 'jurisdiction':
            return jurisdictionBackground;
        case 'counterpartyType':
            return counterpartyBackground;
        case 'productType':
            return productBackground;
        case 'provisionType':
            return provisionBackground;
        case 'opinions':
            return opinionsBackground;
        case 'miscellaneous':
        default:
            return miscellaneousBackground;
    }
};

interface ClauseModalTagsProps {
    clause: Clause;
    tagTerm: string;
    clauseLibraryDropdownOptions: ClauseTagDropdownOptions;
    fuzzyTags: FuzzyMatchTags[];
    fuzzyMatchModalOpen: boolean;
    allClientTags: ClientTag[];
    inputDisabled: boolean;
    updateClauseTag: (key: keyof ClauseTags, value: string[] | null) => void;
    updateTagTerm: (value: string) => void;
    checkFuzzyMatch: () => void;
    addClientTagStarted: (value: string) => void;
    setFuzzyMatchModalOpen: (value: boolean) => void;
    testId: string;
    isOpenViaOpinion: boolean;
    allClauseLibraryTags: string[];
}

export const ClauseModalTags: React.FC<ClauseModalTagsProps> = ({
    clause,
    tagTerm,
    clauseLibraryDropdownOptions,
    fuzzyTags,
    fuzzyMatchModalOpen,
    allClientTags,
    inputDisabled = false,
    updateClauseTag,
    updateTagTerm,
    checkFuzzyMatch,
    addClientTagStarted,
    setFuzzyMatchModalOpen,
    testId,
    isOpenViaOpinion,
    allClauseLibraryTags
}) => {
    const dispatch = useAppDispatch();
    const miscellaneousTags = useMemo(() => allClientTags.map(({ tag }) => tag), [allClientTags]);
    const cursor = useMemo(() => inputDisabled ? 'not-allowed' : 'auto', [inputDisabled]);

    const { tags, systemTemplate } = clause;
    const [tagType, setTagType] = useState<keyof ClauseTags | null>(null);
    const isSystemTemplate = useMemo(() => systemTemplate === 1, [systemTemplate]);

    const selectedTagType = useMemo(() => !isNull(tagType) ? tagCategoryOptions.filter(({ value }) => value === tagType) : null, [tagType]);

    const selectTagType = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(option)) {
            const type = (option as DropdownOption).value as keyof ClauseTags;
            setTagType(type);
        } else {
            setTagType(null);
        }
    }, []);

    const selectSecondDropdownOption = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(dropdownValue) && !isNull(tagType) && (dropdownValue as Options<DropdownOption>).length > 0) {
            const tags = (dropdownValue as Options<DropdownOption>).map(({ value }) => value);
            updateClauseTag(tagType, tags);
        } else {
            updateClauseTag(tagType!, null);
        }
    };

    const selectedTags = useMemo(() => !isNull(tagType) && tags[tagType].length > 0 ? dropdownOptionsByTagType(tagType, clauseLibraryDropdownOptions, isSystemTemplate).filter(({ value }) => tags[tagType].includes(value)) : null, [tags, tagType, clauseLibraryDropdownOptions, isSystemTemplate]);

    const allClauseTags = useMemo(() => flatten(toArray(tags)).map(tag => tag.toLowerCase()), [tags]);

    const tagInputRef = useRef<HTMLDivElement>(null);
    const xPosition = tagInputRef.current?.offsetLeft || 0;
    const yPosition = tagInputRef.current?.offsetTop ? tagInputRef.current.offsetTop + 40 : 0;
    const inputWidth = tagInputRef.current?.offsetWidth ? tagInputRef.current.offsetWidth - 10 : 0;
    const fuzzyModalPosition: Position = { x: xPosition, y: yPosition };

    const createNewTag = useCallback((value: string) => {
        updateTagTerm(value);
        checkFuzzyMatch();
    }, [updateTagTerm, checkFuzzyMatch]);

    const addNewClientTag = useCallback(() => {
        const exactMatchFound = fuzzyTags.find(({ score }) => score! <= 0.1);
        if (!isUndefined(exactMatchFound) && (exactMatchFound.value.toLowerCase() === tagTerm.toLowerCase())) {
            const { key, value } = exactMatchFound;
            const updatedTags = [...tags[key], value];
            updateClauseTag(key, updatedTags);
        } else {
            if (isSystemTemplate) {
                dispatch(toggleSystemTagCategoryModal());
            } else {
                addClientTagStarted(tagTerm);
                const updatedTags = [...tags.miscellaneous, tagTerm];
                updateClauseTag('miscellaneous', updatedTags);
            }
        }
    }, [updateClauseTag, addClientTagStarted, tagTerm, tags, fuzzyTags, isSystemTemplate, dispatch]);

    const removeTag = useCallback((key: keyof ClauseTags, tag: string) => {
        const updatedTags = tags[key].filter(tags => tags !== tag);
        updateClauseTag(key, updatedTags);
    }, [updateClauseTag, tags]);

    const getCurrentTags = useCallback((key: keyof ClauseTags) => {
        return tags[key].sort().map((tag: string, index: number) => {
            let clientTagInOtherCategory = false;
            let clientTagInMisc = false;
            if (key === 'miscellaneous') {
                clientTagInMisc = !clauseLibraryDropdownOptions.miscellaneousSystemOptions.map(({ label }) => label).includes(tag);
            }
            if (key !== 'miscellaneous') {
                clientTagInOtherCategory = miscellaneousTags.includes(tag);
            }
            const tagStyle = (clientTagInOtherCategory || clientTagInMisc) ? { background: `repeating-linear-gradient(45deg, ${getTagBackgroundColour(key)}, ${getTagBackgroundColour(key)} 10px, ${getUnselectedTagBackgroundColour(key)} 10px, ${getUnselectedTagBackgroundColour(key)} 20px)` } : { backgroundColor: getTagBackgroundColour(key) };
            return (
                <div key={tag} className={classnames(styles.tagWrapper, { [styles.tagWrapperDisabled]: inputDisabled })} style={tagStyle} data-testid={`${testId}-modal-current-tag-${key}-${index}-wrapper`}>
                    <div className={styles.tagLabelWrapper}>
                        <OverflowTooltip overlayText={tag} testId={`${testId}-modal-tags-current-tag-${key}-${index}`} className={styles.tagLabel} />
                    </div>
                    <div className={styles.removeTagWrapper} style={{ cursor }}>
                        <IconButton disabled={inputDisabled} onClick={() => removeTag(key, tag)} testId={`${testId}-modal-tags-remove-current-tag-${key}-${index}`} icon={Delete} fontSize={15} />
                    </div>
                </div>
            );
        });
    }, [removeTag, tags, inputDisabled, cursor, miscellaneousTags, clauseLibraryDropdownOptions, testId]);

    const closeFuzzyMatchModal = useCallback(() => setFuzzyMatchModalOpen(false), [setFuzzyMatchModalOpen]);
    const tagInUse = useCallback((value: string) => allClauseTags.includes(value.toLowerCase()), [allClauseTags]);

    const toggleFuzzyTag = useCallback((key: keyof ClauseTags, value: string) => {
        if (tagInUse(value)) {
            const updatedTags = tags[key].filter(tags => tags !== value);
            updateClauseTag(key, updatedTags);
        } else {
            updateClauseTag(key, [...tags[key], value]);
        }
    }, [updateClauseTag, tagInUse, tags]);

    const addTagDisabled = useMemo(() => allClauseLibraryTags.includes(tagTerm.toLowerCase()) || inputDisabled || tagTerm === '', [tagTerm, inputDisabled, allClauseLibraryTags]);

    return (
        <div className={styles.tagsWrapper} data-testid={`${testId}-modal-tags-wrapper`}>
            <div className={styles.currentTagsScrollableWrapper}>
                <Scrollable>
                    <div className={styles.currentTagsWrapper} style={{ cursor }} data-testid={`${testId}-modal-tags-current-tags-wrapper`}>
                        {allClauseTags.length > 0 ? Object.keys(tags).map(value => getCurrentTags(value as keyof ClauseTags)) :
                            <div className={styles.noTagsWrapper} data-testid={`${testId}-modal-no-tags-wrapper`}>
                                Add tags below
                            </div>
                        }
                    </div>
                </Scrollable>
            </div>
            {!inputDisabled &&
                <div className={styles.tagsDropdownWrapper}>
                    <div className={styles.tagTypeDropdown} data-testid={`${testId}-modal-tags-categories-wrapper`}>
                        <Dropdown
                            value={selectedTagType}
                            onChange={selectTagType}
                            options={tagCategoryOptions}
                            placeholder='Select...'
                            testId={`${testId}-modal-tags-category`}
                            isClearable
                            label='Tag Categories'
                            marginBottom='10px'
                            disabled={inputDisabled}
                            cursor={cursor}
                        />
                    </div>
                    <div className={styles.tagTypeDropdown} data-testid={`${testId}-modal-tags-type-wrapper`}>
                        {!isNull(tagType) &&
                            <DropdownSummary
                                value={selectedTags}
                                onChange={selectSecondDropdownOption}
                                options={dropdownOptionsByTagType(tagType, clauseLibraryDropdownOptions, isSystemTemplate)}
                                placeholder='Select...'
                                testId={`${testId}-modal-tags-type-${tagType}`}
                                isClearable
                                label={getTagTypeLabel(tagType)}
                                marginBottom='10px'
                                disabled={inputDisabled}
                                cursor={cursor}
                                isMulti
                            />
                        }
                    </div>
                </div>
            }
            {!inputDisabled &&
                <div className={styles.addClientTagWrapper} data-testid={`${testId}-modal-tags-add-client-tag-wrapper`}>
                    <div className={styles.addClientTagInputWrapper}>
                        <div className={styles.clauseTagsSearchInput} id='add-tag-input' ref={tagInputRef}>
                            <Text
                                onChange={e => createNewTag(e.target.value)}
                                value={tagTerm}
                                maxLength={256}
                                testId={`${testId}-modal-tags-client-tag`}
                                placeholder='Tags...'
                                marginBottom='0px'
                                borderColour={lightGrey}
                                disabled={inputDisabled}
                                cursor={cursor}
                            />
                        </div>
                        <Button onClick={addNewClientTag} label='Add Tag' disabled={addTagDisabled} testId={`${testId}-modal-tags-add-client-tag`} />
                    </div>
                </div>
            }
            <TagsFuzzyMatchModal
                isOpen={fuzzyMatchModalOpen}
                fuzzyModalPosition={fuzzyModalPosition}
                inputWidth={inputWidth}
                toggleTag={toggleFuzzyTag}
                closeModal={closeFuzzyMatchModal}
                tagInUse={tagInUse}
                fuzzyTags={fuzzyTags}
                testId={testId}
            />
            <ClauseSystemTagCategoryModal
                tagTerm={tagTerm}
                tags={tags}
                isOpenViaOpinion={isOpenViaOpinion}
            />
        </div>
    );
};
