import React, { useCallback, useMemo } from 'react';

import { useSingleHiddenFields } from '../../../../../hooks/useHiddenSingleFields';
import { GroupDatasetField } from '../../../../datasets/store';
import { fieldTypeWidths, MAX_GROUP_WIDTH } from '../../../dataset-builder/FieldList';
import { DocumentSpecificHiddenFields } from '../../../dataset-builder/store';
import { DocumentNameDB } from '../../../documents/store';
import styles from '../../MyDatasets.module.scss';
import { HiddenFields, MyDatasetsOpenFieldSection } from '../../store';
import { SingleField } from './SingleField';

interface GroupFieldProps {
    field: GroupDatasetField;
    groupIndex: number;
    sectionId: string;
    datasetId: number;
    parents: number;
    toggleSection: (fieldSection: MyDatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: MyDatasetsOpenFieldSection) => boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: HiddenFields;
    datasetHidden?: boolean;
    documentSpecificHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    parentHiddenDocumentNameIds?: number[];
    isLastFieldInSection?: boolean;
    parentDatasetId: number;
}

export const GroupField: React.FC<GroupFieldProps> = ({ field, groupIndex, sectionId, datasetId, parents, toggleSection, getSectionOpen, isPreview, modalInstance, hiddenFields, datasetHidden, documentSpecificHiddenFields, allDocumentNames, parentHiddenDocumentNameIds, isLastFieldInSection, parentDatasetId }) => {

    const { children } = field;
    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const childFieldHidden = useCallback((id: string) => !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields]);

    const childrenWithoutHiddenFields = useMemo(() => children.filter(({ id }) => !hiddenSingleFields.map(({ fieldId }) => fieldId).includes(id!)), [children, hiddenSingleFields]);
    const childArray = useMemo(() => isPreview ? childrenWithoutHiddenFields : children, [isPreview, childrenWithoutHiddenFields, children]);

    const groupWidth = useMemo(() => childArray.reduce((acc, cur) => acc + (fieldTypeWidths[cur.type] / MAX_GROUP_WIDTH), 0), [childArray]);

    return (
        <div className={styles.groupFieldWrapper} data-testid={`form-groupfield-${groupIndex}-wrapper`}>
            {children.map((field, index) => {
                const childHidden = childFieldHidden(field.id!);
                if (isPreview && childHidden) {
                    return null;
                }
                const isLastFieldInGroup = index === childArray.length - 1;
                const width = fieldTypeWidths[field.type] / groupWidth * 0.98;
                return (
                    <SingleField
                        key={field.id}
                        field={field}
                        isLastFieldInGroup={isLastFieldInGroup}
                        groupIndex={groupIndex}
                        width={width}
                        sectionId={sectionId}
                        datasetId={datasetId}
                        parents={parents}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        isPreview={isPreview}
                        modalInstance={modalInstance}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        documentSpecificHiddenFields={documentSpecificHiddenFields}
                        allDocumentNames={allDocumentNames}
                        parentHiddenDocumentNameIds={parentHiddenDocumentNameIds}
                        isLastFieldInSection={isLastFieldInSection}
                        parentDatasetId={parentDatasetId}
                    />
                );
            })}
        </div>
    );
};
