import React from 'react';
import classnames from 'classnames';
import { isNull, noop } from 'lodash/fp';

import { PlaybookQueryDB } from '../../../admin/playbook/store';
import styles from './Query.module.scss';
import { QuestionMark } from '../../icons';
import { IconTooltip } from '../../tooltip';
import { formatDate, FULL_DATE_FORMAT } from '../../../../utils/luxon';

interface QueryMarkerProps {
    query: PlaybookQueryDB
    openQuery: (playbookQueryId: number) => void;
    currentPlaybookDefinitionId: number | null;
    getPlaybookInstanceVersion?: (playbookDefinitionId: number) => void;
    testId?: string;
}

export const QueryMarker: React.FC<QueryMarkerProps> = ({ query, openQuery, currentPlaybookDefinitionId, getPlaybookInstanceVersion = noop, testId = 'playbook-query-marker' }) => {
    const { resolved, playbookQueryId, playbookDefinitionId, createdDate, queryConversation } = query;

    const isPastDefinitionQuery = !isNull(currentPlaybookDefinitionId) && playbookDefinitionId < currentPlaybookDefinitionId;
    const isFutureDefinitionQuery = !isNull(currentPlaybookDefinitionId) && playbookDefinitionId > currentPlaybookDefinitionId;
    const version = isPastDefinitionQuery || isFutureDefinitionQuery ? `- ${getPlaybookInstanceVersion(playbookDefinitionId)}` : '';
    const name = queryConversation[0].username;
    const isResolved = resolved === 1 ? 'Resolved' : 'Unresolved';
    const date = formatDate(createdDate, FULL_DATE_FORMAT);
    const tooltipContent = `${name} - ${isResolved} ${version} - ${date}`;

    return (
        <div className={classnames(styles.raisedQueryWrapper, {
            [styles.resolvedQuery]: resolved,
            [styles.pastDefinitionQuery]: isPastDefinitionQuery,
            [styles.futureDefinitionQuery]: isFutureDefinitionQuery,
        })} onClick={() => openQuery(playbookQueryId)} data-testid={`${testId}-wrapper`}>
            <IconTooltip icon={QuestionMark} fontSize={18} content={tooltipContent} />
        </div>
    );
};
