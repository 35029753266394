import React, { useMemo, useState } from 'react';

import { Icon } from '../icon/Icon';
import { User } from '../icons';
import styles from './ProfilePicture.module.scss';

interface ProfilePictureProps {
    file?: File | undefined;
    profilePicture?: Buffer;
    initials?: string;
    userIconSize?: number;
    initialsFontSize?: number;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
    profilePicture,
    file,
    initials,
    userIconSize = 216,
    initialsFontSize = 100
}) => {
    const [heightWidthRatio, setHeightWidthRatio] = useState<number>(1);
    const setImageDimensions = (height: number, width: number) => setHeightWidthRatio(height / width);

    const fileSrc = useMemo(() => {
        if (file) {
            return URL.createObjectURL(file);
        } else if (profilePicture) {
            const byteArray = new Uint8Array(profilePicture);
            const blobOfData = new Blob([byteArray]);
            return URL.createObjectURL(blobOfData);
        }
        return undefined;
    }, [file, profilePicture]);

    const imageDimensions = useMemo(() => heightWidthRatio < 1 ? { height: '100%', width: `${100 / heightWidthRatio}%` } : { width: '100%', height: `${100 * heightWidthRatio}%` }, [heightWidthRatio]);
    const getProfilePicture = useMemo(() => {
        if (fileSrc) {
            return <img
                className={styles.profilePicture}
                style={{ ...imageDimensions }}
                src={fileSrc}
                onLoad={e => setImageDimensions(e.currentTarget.naturalHeight, e.currentTarget.naturalWidth)}
            />;
        }
        if (initials) {
            return <div className={styles.initials} style={{ fontSize: `${initialsFontSize}px` }}>{initials.toUpperCase()}</div>;
        }
        return <div className={styles.user}><Icon icon={User} fontSize={userIconSize} /></div>;
    }, [fileSrc, initials, imageDimensions, userIconSize, initialsFontSize]);

    return (
        <div className={styles.pictureWrapper}>
            {getProfilePicture}
        </div>
    );
};
