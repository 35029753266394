import { ColDef, GridOptions, RowDoubleClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Delete, Pencil } from '../../shared/icons';
import { ActionCell, arrayLengthFormatter, Table } from '../../shared/table';
import { Client, fetchAllClientsStarted, getAllClients, getConfirmDeleteClient, toggleClientsModal, toggleDeleteClientConfirmationModal } from './store';
import { fetchAllDocumentNamesStarted, getAllDocumentNames } from '../documents/store';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Action } from '../../shared/modal/ActionModal';
import { DeleteClientConfirmationModal } from './DeleteClientConfirmationModal';

export const ClientsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllClientsStarted(), fetchAllDocumentNamesStarted()]);

    const clients = useAppSelector(getAllClients);
    const allDocumentNames = useAppSelector(getAllDocumentNames);
    const confirmDeleteClient = useAppSelector(getConfirmDeleteClient);

    const datasetOptions = useMemo(() => allDocumentNames.filter(({ datasetId }) => datasetId), [allDocumentNames]);

    const documentNameFormatter = useCallback((params: ValueFormatterParams) => datasetOptions.filter(({ datasetId }) => params.value.includes(datasetId)).length.toString(), [datasetOptions]);

    const openDeleteConfirmationModal = useCallback((client: Client) => { dispatch(toggleDeleteClientConfirmationModal(client)); }, [dispatch]);
    const closeDeleteConfirmationModal = () => dispatch(toggleDeleteClientConfirmationModal(null));

    const editClient = useCallback((client: Client) => { dispatch(toggleClientsModal(true, client)); }, [dispatch]);

    const clientActions = useCallback((client: Client): Action[] => {
        let actions: Action[] = [
            { label: 'Open', icon: Pencil, onClick: () => editClient(client), withSeparator: true },
            { label: 'Delete', icon: Delete, onClick: () => openDeleteConfirmationModal(client), isDelete: true }
        ];
        return actions;
    }, [editClient, openDeleteConfirmationModal]);

    const actionCellParams = {
        testId: 'client-actions',
        actions: clientActions
    };

    const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };
    const columnDefs: ColDef[] = [
        { headerName: 'Client', field: 'clientName', cellStyle: defaultCellStyle, width: 0.5 },
        { headerName: 'Number of Users', field: 'users', cellStyle: defaultCellStyle, width: 0.25, valueFormatter: arrayLengthFormatter },
        { headerName: 'Number of Datasets', field: 'clientDatasetIds', cellStyle: defaultCellStyle, width: 0.2, valueFormatter: documentNameFormatter },
        { headerName: '', field: '', cellRenderer: 'actionCellRenderer', width: 0.05, cellRendererParams: actionCellParams }
    ];

    const gridOptions: GridOptions = {
        components: {
            actionCellRenderer: ActionCell,
        },
        paginationPageSize: 20
    };

    const onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => dispatch(toggleClientsModal(true, data));

    return (
        <>
            <Table
                colDefs={columnDefs}
                rowData={clients}
                gridOptions={gridOptions}
                percentageWidths={true}
                onRowDoubleClicked={onRowDoubleClicked}
                testId='clients'
            />
            {!isNull(confirmDeleteClient) &&
                <DeleteClientConfirmationModal
                    isOpen
                    closeModal={closeDeleteConfirmationModal}
                    client={confirmDeleteClient}
                />
            }
        </>
    );
};
