import { ApplicationState } from '../../../../store/rootReducer';
import { SearchDocumentsState, SearchDocumentsView, SearchFilter, SearchResult, SmartSearch } from './types';

const getRoot = (state: ApplicationState): SearchDocumentsState => state.document.search;

export const getSearchFilterModalOpen = (state: ApplicationState): boolean => getRoot(state).filterModalOpen;

export const getSearchFilters = (state: ApplicationState): SearchFilter => getRoot(state).searchFilter;

export const getSearchDocumentsView = (state: ApplicationState): SearchDocumentsView => getRoot(state).searchDocumentsView;

export const getSimpleSearchTerm = (state: ApplicationState): string => getRoot(state).simpleSearchTerm;

export const getSmartSearchTerm = (state: ApplicationState): SmartSearch[] => getRoot(state).smartSearchTerm;

export const getIsSearching = (state: ApplicationState): boolean => getRoot(state).isSearching;

export const getSearchResults = (state: ApplicationState): SearchResult[] | null => getRoot(state).searchResults;

export const getTotalSearchResults = (state: ApplicationState): number => getRoot(state).totalSearchResults;

export const getSearchResultPage = (state: ApplicationState): number => getRoot(state).searchPage;

export const getResultsType = (state: ApplicationState): SearchDocumentsView | null => getRoot(state).searchResultsType;

export const getTotalPages = (state: ApplicationState): number => getRoot(state).totalSearchPages;

export const getIsOpening = (state: ApplicationState): boolean => getRoot(state).isOpening;

export const getDocumentPreviewModalOpen = (state: ApplicationState): boolean => getRoot(state).documentPreviewModalOpen;

export const getDocumentPreviewUrl = (state: ApplicationState): string | null => getRoot(state).documentPreviewUrl;

export const getDocumentPreviewResult = (state: ApplicationState): SearchResult | null => getRoot(state).documentPreviewResult;
