import { action } from 'typesafe-actions';

import { AgencyDatasetField } from '../../datasets/store';
import { AgencyAnnexField, AnnexDefinition, AnnexDefinitionActionTypes, InstanceAnnexDefinition, InstanceAnnexView } from './types';

export const toggleAnnexDefinitionModal = (value: boolean) => action(AnnexDefinitionActionTypes.TOGGLE_ANNEX_DEFINITION_MODAL, value);
export const toggleAnnexBuilderModal = (value: boolean) => action(AnnexDefinitionActionTypes.TOGGLE_ANNEX_BUILDER_MODAL, value);

export const createNewAnnexDefinition = (documentNameId: number, annexDefinitionId?: number) => action(AnnexDefinitionActionTypes.CREATE_NEW_ANNEX_DEFINITION, { documentNameId, annexDefinitionId });
export const duplicateAnnexDefinition = (annexDefinition: AnnexDefinition) => action(AnnexDefinitionActionTypes.DUPLICATE_ANNEX_DEFINITION, annexDefinition);

export const updateAnnexDatasetFields = (fields: AgencyAnnexField[]) => action(AnnexDefinitionActionTypes.UPDATE_ANNEX_DATASET_FIELDS, fields);
export const updateAnnexDatasetField = (field: string, value: string, index: number) => action(AnnexDefinitionActionTypes.UPDATE_ANNEX_DATASET_FIELD, { field, value, index });

export const addAnnexDatasetField = (datasetField: AgencyDatasetField) => action(AnnexDefinitionActionTypes.ADD_ANNEX_DATASET_FIELD, datasetField);

export const addCustomAnnexDatasetField = () => action(AnnexDefinitionActionTypes.ADD_CUSTOM_ANNEX_DATASET_FIELD);

export const removeAnnexDatasetField = (id: string) => action(AnnexDefinitionActionTypes.REMOVE_ANNEX_DATASET_FIELD, id);

export const updateAnnexDefinitionTitle = (title: string) => action(AnnexDefinitionActionTypes.UPDATE_ANNEX_TITLE, title);

export const setInstanceAnnexDefinitions = (instanceAnnexDefinitions: InstanceAnnexDefinition[]) => action(AnnexDefinitionActionTypes.SET_INSTANCE_ANNEX_DEFINITIONS, instanceAnnexDefinitions);
export const updateInstanceAnnexField = (field: keyof InstanceAnnexDefinition, value: string | number | null, index: number) => action(AnnexDefinitionActionTypes.UPDATE_INSTANCE_ANNEX_FIELD, { field, value, index });
export const removeInstanceAnnexField = (index: number) => action(AnnexDefinitionActionTypes.REMOVE_INSTANCE_ANNEX_DEFINITION, index);
export const addInstanceAnnexField = () => action(AnnexDefinitionActionTypes.ADD_INSTANCE_ANNEX_DEFINITION);
export const setInstanceAnnexView = (view: InstanceAnnexView) => action(AnnexDefinitionActionTypes.SET_INSTANCE_ANNEX_VIEW, view);
export const setSelectedInstanceIndex = (index: number) => action(AnnexDefinitionActionTypes.SET_SELECTED_INSTANCE_INDEX, index);
export const setInstanceAnnexDefinitionsHaveUpdated = (hasUpdated: boolean) => action(AnnexDefinitionActionTypes.SET_INSTANCE_ANNEX_DEFINITIONS_UPDATED, hasUpdated);

export const publishAnnexDefinitionStarted = (isInstanceUpdate = false) => action(AnnexDefinitionActionTypes.PUBLISH_ANNEX_DEFINITION_STARTED, { isInstanceUpdate });
export const publishAnnexDefinitionSuccessful = () => action(AnnexDefinitionActionTypes.PUBLISH_ANNEX_DEFINITION_SUCCESSFUL);
export const publishAnnexDefinitionFailed = (error: string) => action(AnnexDefinitionActionTypes.PUBLISH_ANNEX_DEFINITION_FAILED, error);

export const upsertInstanceAnnexDefinitionsStarted = () => action(AnnexDefinitionActionTypes.UPSERT_ANNEX_INSTANCES_STARTED);
export const upsertInstanceAnnexDefinitionsSuccessful = () => action(AnnexDefinitionActionTypes.UPSERT_ANNEX_INSTANCES_SUCCESSFUL);
export const upsertInstanceAnnexDefinitionsFailed = (error: string) => action(AnnexDefinitionActionTypes.UPSERT_ANNEX_INSTANCES_FAILED, error);

export const fetchAllAnnexDefinitionsStarted = (documentNameId: string) => action(AnnexDefinitionActionTypes.FETCH_ALL_ANNEX_DEFINITIONS_STARTED, documentNameId);
export const fetchAllAnnexDefinitionsSuccessful = (annexDefinitions: AnnexDefinition[]) => action(AnnexDefinitionActionTypes.FETCH_ALL_ANNEX_DEFINITIONS_SUCCESSFUL, annexDefinitions);
export const fetchAllAnnexDefinitionsFailed = (error: string) => action(AnnexDefinitionActionTypes.FETCH_ALL_ANNEX_DEFINITIONS_FAILED, error);

export const fetchAgencyFieldsStarted = (agencyDatasetId: number) => action(AnnexDefinitionActionTypes.FETCH_AGENCY_FIELDS_STARTED, agencyDatasetId);
export const fetchAgencyFieldsSuccessful = (agencyFields: AgencyDatasetField[]) => action(AnnexDefinitionActionTypes.FETCH_AGENCY_FIELDS_SUCCESSFUL, agencyFields);
export const fetchAgencyFieldsFailed = (error: string) => action(AnnexDefinitionActionTypes.FETCH_AGENCY_FIELDS_FAILED, error);
