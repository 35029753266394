import { isEqual } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../hooks/react-redux';
import { Table } from '../shared/icons';
import { ModalHeader } from '../shared/modal/ModalHeader';
import { AgencyAnnexBuilder } from './AgencyAnnexBuilder';
import { DocumentAnnexModal } from './DocumentAnnexModal';
import { getAllAnnexDefinitions, getAnnexDefinition } from './store';

interface AnnexBuilderModalProps {
    isOpen: boolean;
    close: () => void;
    create: () => void;
}

export const AnnexBuilderModal: React.FC<AnnexBuilderModalProps> = ({ isOpen, close, create }) => {
    const annexDefinition = useAppSelector(getAnnexDefinition);
    const publishedDefinitions = useAppSelector(getAllAnnexDefinitions);

    const confirmDisabled = useMemo(() => {
        if (annexDefinition) {
            if (!annexDefinition.annexDefinitionId) {
                return false;
            }
            if (publishedDefinitions) {
                const published = publishedDefinitions.find(({ annexDefinitionId }) => annexDefinitionId === annexDefinition.annexDefinitionId)!;
                return isEqual(published.datasetFields, annexDefinition.datasetFields);
            }
        }
        return true;
    }, [publishedDefinitions, annexDefinition]);

    const confirmLabel = annexDefinition && !annexDefinition.annexDefinitionId ? 'Create' : 'Duplicate';

    return (
        <DocumentAnnexModal
            isOpen={isOpen}
            closeModal={close}
            confirmLabel={confirmLabel}
            confirm={create}
            confirmDisabled={confirmDisabled}
        >
            <ModalHeader
                label='Create Agency Annex'
                icon={Table}
                testId='agency-annex-builder-modal'
            />
            {annexDefinition && <AgencyAnnexBuilder annexDefinition={annexDefinition} />}
        </DocumentAnnexModal>
    );
};
