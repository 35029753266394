import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { isUndefined, noop } from 'lodash/fp';
import { select, scaleBand, scaleLinear, stack, max, BaseType, pointer, axisLeft, min } from 'd3';

import styles from './SharedAnalytics.module.scss';
import { Scrollable } from '../scrollable/Scrollable';
import { StackChartAnalyticsData } from '../../admin/analytics/store';

const { primary, french, white, fontFamily, lightGrey } = styles;

interface StackChartProps {
    data: StackChartAnalyticsData[];
    keys: string[];
    height: number;
    width: number;
    stackHeight?: number;
    getPercentageFill?: (id: string) => number;
    getBackgroundColor?: (id: string) => string;
    onDblClick?: (id: number) => void;
    getTooltipDetails?: (id: number, layerId: string) => { height: number; width: number; content: string; } | null;
    testId?: string;
}

interface Layer {
    key: string;
}

const defaultPercentageFill = () => 100;
const defaultBackgroundColor = () => french;

export const StackChart: React.FC<StackChartProps> = ({
    data,
    keys,
    height,
    width,
    stackHeight = 50,
    getPercentageFill = () => defaultPercentageFill(),
    getBackgroundColor = () => defaultBackgroundColor(),
    onDblClick = noop,
    getTooltipDetails,
    testId = 'stack-chart'
}) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const svgHeight = useMemo(() => data.length * stackHeight, [data.length, stackHeight]);
    const chartOffset = useMemo(() => min([max(data.map(({ key }) => key.slice(0, 33).length))! * 6, width / 2])!, [data, width]);

    const tooltipPosition = useCallback((tooltipWidth: number, tooltipHeight: number, x: number, y: number) => {
        const tooltipOverflowX = x + tooltipWidth > width;
        const tooltipOverflowY = y + tooltipHeight > height;
        const translateX = tooltipOverflowX ? x - (tooltipWidth + 5) : x + 5;
        const translateY = tooltipOverflowY ? y - (tooltipHeight + 5) : y - 5;
        return `translate(${translateX}, ${translateY})`;
    }, [height, width]);

    const getTickTooltipWidth = useCallback((key: string) => max([key.length * 6, 100])!, []);

    useEffect(() => {
        const svg = select(svgRef.current);
        const stackGenerator = stack()
            .keys(keys)
            .value((d, key) => d[key]);
        const layers = stackGenerator(data as { [key: string]: number; }[]);
        const extent = [0, max(layers, layer => max(layer, sequence => sequence[1]))!];
        const yScale = scaleBand()
            .domain(data.map(({ key }) => key))
            .range([0, svgHeight])
            .padding(0.2);

        const xScale = scaleLinear()
            .domain(extent)
            .range([width, chartOffset]);

        const rectIds = layers.reduce((acc: string[], cur) => {
            cur.forEach(({ data }) => acc.push(`${data.key.toString().replace(/[\s,(,)]/g, '').toLowerCase()}:${cur.key}`));
            return acc;
        }, []);

        const defs = svg.append('defs').attr('class', 'defs');

        const gradients = rectIds.map(id =>
            defs
                .append('linearGradient')
                .attr('id', `gradient-${id}`)
                .attr('x1', '0%')
                .attr('x2', `${getPercentageFill(id)}%`)
                .attr('y1', '0%')
                .attr('y2', '0%')
        );

        gradients.map(gradient =>
            gradient.selectAll('stop')
                .data((_, __, f) => [getBackgroundColor(f[0].id.replace('gradient-', '').split(':')[0]), lightGrey])
                .enter()
                .append('stop')
                .style('stop-color', d => d)
                .attr('offset', (d, i) => `${100 * (i % 2) + 100}%`)
        );

        const bars = svg
            .selectAll('.layer')
            .data(layers)
            .enter()
            .append('g')
            .attr('id', layer => `${testId}-layer-${layer.key}`)
            .attr('class', 'layer')
            .selectAll('rect')
            .data(layer => layer)
            .enter()
            .append('rect')
            .attr('id', sequence => `${testId}-rectangle-${sequence.data.key.toString().replace(/[\s,(,)]/g, '').toLowerCase()}`)
            .attr('data-testid', (sequence, _, e) => `${(select(e[0].parentNode as BaseType).datum() as Layer).key}-${testId}-rect-${sequence.data.key.toString().replace(/\s+/g, '-').replace(/[{()}]/g, '').toLowerCase()}`)
            .attr('fill', (d, _, f) => {
                const rectId = `${d.data.key.toString().replace(/[\s,(,)]/g, '').toLowerCase()}:${(select(f[0].parentNode as BaseType).datum() as Layer).key}`;
                return `url(#gradient-${rectId})`;
            })
            .attr('stroke', white)
            .attr('stroke-width', 3)
            .attr('y', sequence => yScale(sequence.data.key.toString())!)
            .attr('width', 0)
            .attr('x', sequence => xScale(sequence[1]))
            .attr('height', 0);

        bars.transition()
            .attr('width', sequence => {
                const isNumeric = !isNaN(xScale(sequence[0]) - xScale(sequence[1]));
                return isNumeric ? xScale(sequence[0]) - xScale(sequence[1]) : 0;
            })
            .attr('height', yScale.bandwidth())
            .duration(1000);

        const yAxis = axisLeft(yScale)
            .tickSizeOuter(0)
            .tickFormat(tick => tick.slice(0, 33));

        svg
            .append('g')
            .attr('class', 'y-axis')
            .attr('data-testid', `analytics-${testId}-stack-chart-y-axis`)
            .attr('transform', `translate(${chartOffset},0)`)
            .call(yAxis)
            .selectAll('.tick text')
            .attr('class', 'y-axis-label');

        const ticks = svg
            .selectAll('.y-axis-label')
            .attr('data-testid', tick => `analytics-${testId}-stack-chart-${(tick as string).replace(/\s+/g, '-').replace(/[{()}]/g, '').toLowerCase()}-label`);

        svg
            .selectAll('.y-axis line')
            .attr('stroke', primary);
        svg
            .selectAll('.y-axis path')
            .attr('stroke', 'none');
        svg
            .selectAll('.y-axis text')
            .attr('fill', primary)
            .attr('font-size', '11px')
            .attr('font-weight', 600)
            .attr('font-family', fontFamily);

        ticks
            .on('mouseover', () => tickTooltip.attr('display', null))
            .on('mouseout', () => tickTooltip.attr('display', 'none'))
            .on('mousemove', (e, d) => {
                const width = getTickTooltipWidth(d as string);
                tickTooltip.attr('transform', `translate(${e.offsetX}, ${e.offsetY})`);
                tickTooltip.select('rect')
                    .attr('height', 20)
                    .attr('width', width);
                tickTooltip.select('text')
                    .html(`<tspan dy=10 x=10>${d}</tspan>`);
            });

        const tickTooltip = svg
            .append('g')
            .attr('class', 'tick-tooltip')
            .attr('data-testid', `analytics-${testId}-stack-chart-tick-tooltip`)
            .attr('display', 'none');

        tickTooltip
            .append('rect')
            .attr('rx', 5)
            .attr('stroke', french)
            .attr('stroke-width', 1)
            .attr('fill', white);

        tickTooltip
            .append('text')
            .attr('x', 10)
            .attr('y', 4)
            .attr('fill', french)
            .attr('text-anchor', 'start')
            .attr('font-size', '11px')
            .attr('font-family', fontFamily)
            .attr('font-weight', 600);

        if (!isUndefined(getTooltipDetails)) {
            bars
                .on('mouseover', () => tooltip.attr('display', null))
                .on('mouseout', () => tooltip.attr('display', 'none'))
                .on('mousemove', (e, d) => {
                    const [x, y] = pointer(e);
                    const path = e.path || e.composedPath();
                    const layerId = (select(path[0].parentNode as BaseType).datum() as Layer).key;
                    const id = d.data.id;
                    const tooltipDetails = getTooltipDetails(id, layerId);
                    if (tooltipDetails) {
                        tooltip.attr('transform', tooltipPosition(tooltipDetails.width, tooltipDetails.height, x, y));
                        tooltip.select('rect')
                            .attr('height', tooltipDetails.height)
                            .attr('width', tooltipDetails.width);
                        tooltip.select('text')
                            .html(tooltipDetails.content);
                    }
                });

            const tooltip = svg
                .append('g')
                .attr('class', 'tooltip')
                .attr('data-testid', `analytics-${testId}-stack-chart-tooltip`)
                .attr('display', 'none');

            tooltip
                .append('rect')
                .attr('rx', 5)
                .attr('stroke', french)
                .attr('stroke-width', 1)
                .attr('fill', white);

            tooltip
                .append('text')
                .attr('x', 10)
                .attr('y', 4)
                .attr('fill', french)
                .attr('text-anchor', 'start')
                .attr('font-size', '12px')
                .attr('font-family', fontFamily)
                .attr('font-weight', 600);
        }

        bars
            .on('dblclick', (_, d) => {
                onDblClick(d.data.id);
            });

        return () => {
            // Anything added to the svg on mount via .append must be removed on unmount to stop new elements being added each render
            svg.selectAll('.layer').remove();
            svg.selectAll('.tooltip').remove();
            svg.selectAll('.y-axis').remove();
            svg.selectAll('.defs').remove();
        };

    }, [data, keys, width, height, svgHeight, chartOffset, testId, getPercentageFill, getBackgroundColor, getTooltipDetails, tooltipPosition, onDblClick, getTickTooltipWidth]);

    return (
        <div data-testid={`analytics-${testId}-stack-chart-wrapper`} style={{ height, width }}>
            <Scrollable>
                <svg ref={svgRef} style={{ height: `${svgHeight}px`, width: '100%', overflow: 'visible' }} />
            </Scrollable>
        </div>
    );
};
