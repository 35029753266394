import React, { useCallback, useMemo } from 'react';

import styles from '../SupplyChain.module.scss';
import { getSelectedElementWizardTab, setSelectedSupplyChainElementTab, SupplyChainElementTab } from '../store';
import { Wizard } from '../../../shared/wizard/Wizard';
import { Spinner } from '../../../shared/spinner/Spinner';
import { Details } from './Details';
import { ContractualInformation } from './ContractualInformation';
import { AssessmentInformation } from './AssessmentInformation';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';

export const ElementWizard: React.FC = () => {
    const dispatch = useAppDispatch();

    const supplyChainDetailsTabs = Object.values(SupplyChainElementTab);
    const selectedTab = useAppSelector(getSelectedElementWizardTab);

    const selectTab = useCallback((tab: SupplyChainElementTab) => dispatch(setSelectedSupplyChainElementTab(tab)), [dispatch]);

    const wizardTabs = useMemo(() => supplyChainDetailsTabs.map(tab => ({
        label: tab,
        selected: tab === selectedTab,
        onClick: () => selectTab(tab),
        disabled: false
    })), [selectTab, supplyChainDetailsTabs, selectedTab]);

    const content = useMemo(() => {
        switch (selectedTab) {
            case SupplyChainElementTab.DETAILS:
                return <Details />;
            case SupplyChainElementTab.CONTRACT:
                return <ContractualInformation />;
            case SupplyChainElementTab.ASSESSMENT:
                return <AssessmentInformation />;
            default:
                return <Spinner />;
        }
    }, [selectedTab]);

    return (
        <div className={styles.elementDetailsWizardWrapper}>
            <Wizard
                buttons={[]}
                tabs={wizardTabs}
                testId='supply-chain-element-wizard'
                showHeader={false}
                padding='0 10px'
            >
                <div className={styles.elementDetailsContent}>
                    {content}
                </div>
            </Wizard>
        </div>
    );
};
