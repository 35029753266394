import { isNull, noop } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { worldCountriesList } from '../../../constants/worldCountriesList';
import worldGeometryJson from '../../../constants/worldGeometry.json';
import { Country } from '../../../documents/analytics/store';
import { IconButton } from '../../../shared/button/IconButton';
import { Sort } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { InformationTooltip, OverflowTooltip } from '../../../shared/tooltip';
import styles from '../Analytics.module.scss';
import { OpinionAnalyticsChart, OpinionMapAnalytics, OpinionMapCountry, getFetchingAnalytics, getOpinionAnalyticsScope, getOpinionSingleField, getSelectedCountry, getSingleDataPointMapAnalytics, setSelectedCountry, setSelectedSingleFieldJurisdiction } from '../store';
import { SingleDataPointChart } from './SingleDataPointChart';
import { SingleDataPointDetails } from './SingleDataPointDetails';
import { getAvailableDropdownDetailsFieldsForScope } from '../store/utils';

const worldGeometry = worldGeometryJson as Country[];

interface SingleDataPointChartWrapperProps {
    dimensions?: {
        height: number;
        width: number;
    };
    tileInView: null | OpinionAnalyticsChart;
    setTileInView: (tile: OpinionAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
}

export const UK = 'United Kingdom of Great Britain and Northern Ireland (the)';

export const SingleDataPointChartWrapper: React.FC<SingleDataPointChartWrapperProps> = ({ dimensions, setTileInView, tileInView, analyticsSpinner }) => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(getSingleDataPointMapAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const selectedCountry = useAppSelector(getSelectedCountry);
    const singleField = useAppSelector(getOpinionSingleField);
    const scope = useAppSelector(getOpinionAnalyticsScope);

    const singleFieldOptions = useMemo(() => getAvailableDropdownDetailsFieldsForScope(scope), [scope]);
    const singleFieldValue = useMemo(() => singleFieldOptions.find(({ value }) => value === `${singleField.sectionId}---${singleField.fieldId}`) || null, [singleFieldOptions, singleField]);

    const showSpinner = useMemo(() => fetchingAnalytics.includes(OpinionAnalyticsChart.SINGLE_DATA_POINT_JURISDICTION), [fetchingAnalytics]);
    const toggleTileView = useCallback(() => setTileInView(isNull(tileInView) ? OpinionAnalyticsChart.SINGLE_DATA_POINT_JURISDICTION : null), [setTileInView, tileInView]);

    const getDataByCountry = useCallback((name: string): OpinionMapAnalytics[] => {
        if (name === UK) {
            return data.filter(({ jurisdiction }) => ['England & Wales', 'Scotland'].includes(jurisdiction));
        }
        return data.filter(({ jurisdiction }) => jurisdiction === name);
    }, [data]);

    const opinionMapCountry: OpinionMapCountry[] = useMemo(() => worldCountriesList.map(country => {
        const data = getDataByCountry(country.name);
        const disabled = data.every(({ isPermitted }) => !isPermitted);
        return { ...country, data, disabled };
    }), [getDataByCountry]);

    const selectedCountryGeometry = worldGeometry.find(({ id }) => id === selectedCountry);

    const { height, width } = useMemo(() => {
        if (dimensions) {
            if (tileInView === OpinionAnalyticsChart.SINGLE_DATA_POINT_JURISDICTION) {
                const svgWidth = ((dimensions.width - 20) / 4) * 3;
                const svgHeight = dimensions.height - 50;
                return { width: svgWidth, height: svgHeight };
            }
            const svgWidth = dimensions.width - 20;
            const svgHeight = dimensions.height - 50;
            return { width: svgWidth, height: svgHeight };
        }
        return { width: 1, height: 1 };
    }, [dimensions, tileInView]);

    const selectJurisdiction = (id: string) => {
        const countryInfo = worldCountriesList.find(({ geoId }) => geoId === id);
        if (countryInfo) {
            dispatch(setSelectedSingleFieldJurisdiction(countryInfo.name));
        }
    };

    useEffect(() => () => {
        dispatch(setSelectedCountry(null));
        dispatch(setSelectedSingleFieldJurisdiction(null));
    }, [dispatch]);

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    if (tileInView === OpinionAnalyticsChart.SINGLE_DATA_POINT_JURISDICTION) {
        return (
            <div className={styles.jurisdictionChartWrapper} data-testid='opinion-single-data-full-screen-chart-wrapper'>
                <div className={styles.jurisdictionChartHeader}>
                    <div className={styles.jurisdictionChartTitle} data-testid='opinion-single-data-full-screen-chart-title'>Opinion Data Map</div>
                    <div className={styles.jurisdictionChartDescription}><InformationTooltip content='A visualisation of the conclusions of individual data points across your opinion portfolio.' /></div>
                    <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
                </div>
                <div className={styles.splitViewWrapper} style={{ height: `${height}px` }}>
                    <div style={{ width: `${width}px`, height: '100%' }}>
                        <SingleDataPointChart
                            height={height}
                            width={width}
                            worldGeometry={worldGeometry}
                            graphData={opinionMapCountry}
                            selectedCountry={selectedCountryGeometry}
                            onClick={selectJurisdiction}
                        />
                    </div>
                    <SingleDataPointDetails height={height} />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.jurisdictionChartWrapper} data-testid='opinion-single-data-chart-wrapper'>
            <div className={styles.jurisdictionChartHeader}>
                <div className={styles.smallTileQuestion}><OverflowTooltip overlayText={singleFieldValue?.label || ''} /></div>
                <div className={styles.jurisdictionChartDescription}><InformationTooltip content='A visualisation of the conclusions of individual data points across your opinion portfolio.' /></div>
                <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
            </div>
            <SingleDataPointChart
                height={height}
                width={width}
                worldGeometry={worldGeometry}
                graphData={opinionMapCountry}
                selectedCountry={selectedCountryGeometry}
                onClick={noop}
            />
        </div>
    );
};
