import React from 'react';

import { CollateralProviderOpinionInstance } from './store/types';
import { ArkOpinion } from '../my-opinions/store';
import { ClauseSection, CounterpartyCoverageSection, EligibleCollateralSection, GeneralSection, IndependentAmountSection, OtherIssuesSection, ProtocolsSection, ProviderSFTDocumentsSection, SECIMSupplementSection, ScopeSection, SecurityInterestSection, TitleTransferSection, TransactionCoverageSection } from './sections';
import { CollateralAgreementCoverageSection } from './sections/CollateralAgreementCoverage';
import { collateralProviderOpinionGroups } from '../../constants/opinion';

interface CollateralProviderInstanceProps {
    instance: CollateralProviderOpinionInstance;
    opinion: ArkOpinion;
    isEditing: boolean;
    isUpdating: boolean;
    width: number;
}

export const CollateralProviderInstance: React.FC<CollateralProviderInstanceProps> = ({ instance, opinion, isEditing, isUpdating, width }) => {
    const { content: { general, securityInterest, sftDocuments, titleTransfer, protocols, independentAmount, secIMSupplement, otherIssues, transactionCoverage, counterpartyCoverage, eligibleCollateral, agreementCoverage, additionalProvisions }, fieldsUpdated } = instance;
    return (
        <div>
            <GeneralSection general={general} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <ScopeSection opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} />
            <SecurityInterestSection securityInterest={securityInterest} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <ProviderSFTDocumentsSection sftDocuments={sftDocuments} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <TitleTransferSection titleTransfer={titleTransfer} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <ProtocolsSection protocols={protocols} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <IndependentAmountSection independentAmount={independentAmount} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <SECIMSupplementSection secIMSupplement={secIMSupplement} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <OtherIssuesSection otherIssues={otherIssues} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <CollateralAgreementCoverageSection agreementCoverage={agreementCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} collateralOpinionGroups={collateralProviderOpinionGroups} />
            <TransactionCoverageSection transactionCoverage={transactionCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <CounterpartyCoverageSection counterpartyCoverage={counterpartyCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} jurisdiction={opinion.jurisdiction} />
            <EligibleCollateralSection eligibleCollateral={eligibleCollateral} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <ClauseSection additionalProvisions={additionalProvisions} opinion={opinion} width={width} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
        </div>
    );
};
