import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Button } from '../../shared/button/Button';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { OpinionTemplate } from './OpinionTemplate';
import styles from './Reports.module.scss';
import { OpinionReportTemplate, ReportPage, deleteReportTemplateStarted, getAllReportTemplates, getConfirmDeleteModalOpen, getConfirmDeleteReportTemplate, getIsDeleting, openOpinionReportTemplate, setOpinionReportScopes, setReportPage, setReportTemplateToDelete } from './store';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasFeaturePermission } from '../../auth/login/store';

const NAV_BAR = 100;
const BACK_BUTTON = 48;
const TITLE = 35;
const MARGIN = 40;
const HEIGHT_OFFSET = NAV_BAR + BACK_BUTTON + TITLE + MARGIN;

export const OpinionReportTemplates: React.FC = () => {
    const testId = 'opinion-reporting-templates';
    const [, screenHeight] = useWindowResize();
    const templatesWrapperHeight = useMemo(() => screenHeight - HEIGHT_OFFSET, [screenHeight]);

    const reportTemplateToDelete = useAppSelector(getConfirmDeleteReportTemplate);
    const deleteConfirmationModalOpen = useAppSelector(getConfirmDeleteModalOpen);
    const templates = useAppSelector(getAllReportTemplates);
    const dispatch = useAppDispatch();
    const goBack = () => dispatch(setReportPage(ReportPage.SELECT));
    const hasEditOpinionReportPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.EDIT_OPINION_REPORT_TEMPLATES]));

    const openOpinionTemplate = useCallback((reportTemplate: OpinionReportTemplate) => {
        const { scopes } = reportTemplate;
        dispatch(openOpinionReportTemplate(reportTemplate));
        dispatch(setOpinionReportScopes(scopes, false));
    }, [dispatch]);

    const deleteTemplate = useCallback(() => reportTemplateToDelete && dispatch(deleteReportTemplateStarted(reportTemplateToDelete)), [dispatch, reportTemplateToDelete]);
    const openDeleteConfirmationModal = useCallback((id: number) => dispatch(setReportTemplateToDelete(id)), [dispatch]);

    const reportTemplateName = useMemo(() => {
        let name = 'Unknown report template';
        if (!isNull(reportTemplateToDelete)) {
            const template = templates.find(({ opinionReportTemplateId }) => opinionReportTemplateId === reportTemplateToDelete);
            if (template) {
                name = template.name;
            }
        }
        return name;
    }, [reportTemplateToDelete, templates]);

    const isDeleting = useAppSelector(getIsDeleting);
    const closeDeleteConfirmationModal = useCallback(() => dispatch(setReportTemplateToDelete(null)), [dispatch]);

    const templatesContent = useMemo(() => {
        if (!templates.length) {
            return <div className={styles.noTemplates}>You do not have any Custom Opinion Templates. You can add custom templates by selecting the "Create a New Report" option.</div>;
        }
        return templates.map(template => (
            <OpinionTemplate
                key={template.opinionReportTemplateId}
                template={template}
                openTemplate={() => openOpinionTemplate(template)}
                openDeleteConfirmationModal={() => openDeleteConfirmationModal(template.opinionReportTemplateId)}
                testId='opinion-reporting'
                canMakeTemplateChanges={hasEditOpinionReportPermission}
            />
        ));
    }, [openOpinionTemplate, openDeleteConfirmationModal, templates, hasEditOpinionReportPermission]);

    return (
        <div className={styles.reportTemplatesWrapper}>
            <div className={styles.reportsHeader}>
                <div className={styles.templatesTitle} data-testid={`${testId}-header`}>Custom Opinion Report Templates</div>
            </div>
            <div className={styles.templatesWrapper} data-testid={`${testId}-wrapper`}>
                <div className={styles.allTemplatesWrapper}>
                    <Scrollable maxHeight={`${templatesWrapperHeight}px`} height={`${templatesWrapperHeight}px`}>
                        {templatesContent}
                    </Scrollable>
                </div>
                <div className={styles.templatesButtonWrapper}>
                    <Button onClick={goBack} label='Back' testId={`${testId}-back`} />
                </div>
                {deleteConfirmationModalOpen &&
                    <DeleteConfirmationModal
                        closeModal={closeDeleteConfirmationModal}
                        deleteReportTemplate={deleteTemplate}
                        isDeleting={isDeleting}
                        reportTemplateName={reportTemplateName}
                    />}
            </div>
        </div>
    );
};
