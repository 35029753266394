import React, { useCallback, useRef } from 'react';
import Select, { Options, StylesConfig } from 'react-select';

import { useMenuPlacement } from '../../../../hooks/useMenuPlacement';
import { AgencyLinkedSingleField } from '../../../datasets/store';
import { InformationTooltip } from '../../../shared/tooltip';
import styles from './AgencyDatasetBuilder.module.scss';

export interface AgencyLinkDropdownOption {
    value: AgencyLinkedSingleField;
    label: string;
}

type DropdownOptions = Options<AgencyLinkDropdownOption> | Options<AgencyLinkDropdownOption>[] | undefined

const { primary, french, white, disabledGrey, fontFamily, disabledBackground, grey } = styles;
type IsMulti = boolean;
export const customStyles: StylesConfig<AgencyLinkDropdownOption, IsMulti> = {
    option: (provided, state) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '3px 10px',
        color: primary,
        backgroundColor: state.isFocused ? french : white,
        fontSize: '13px',
        fontFamily: fontFamily
    }),
    control: (provided, state) => ({
        ...provided,
        color: primary,
        backgroundColor: state.isDisabled ? disabledBackground : white,
        border: `1px solid ${state.isDisabled ? disabledGrey : primary}`,
        borderRadius: '5px',
        fontSize: '13px',
        fontFamily: fontFamily,
        boxShadow: 'none'
    }),
    menu: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontFamily: fontFamily,
        zIndex: 11
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 11 }),
    menuList: (provided) => ({
        ...provided,
        '::-webkit-scrollbar': {
            width: '4px',
            height: '0px',
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-track': {
            background: disabledBackground,
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb': {
            background: disabledGrey,
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: grey
        }
    })
};

interface AgencyLinkFieldDropdownProps {
    value: AgencyLinkedSingleField[];
    options: AgencyLinkedSingleField[];
    updateValue: (option: AgencyLinkDropdownOption | Options<AgencyLinkDropdownOption> | null) => void;
    isMulti?: boolean;
    disabled?: boolean;
}

export const AgencyLinkFieldDropdown: React.FC<AgencyLinkFieldDropdownProps> = ({
    value,
    options,
    updateValue,
    disabled = false,
    isMulti = false
}) => {

    const fieldAsDropdownOption = useCallback((field: AgencyLinkedSingleField) => ({ value: field, label: `${field.label}${field.datasetTitle ? ` (${field.datasetTitle})` : ''}` }), []);

    const mappedOptions: DropdownOptions = options.map(field => fieldAsDropdownOption(field));
    const mappedValue: DropdownOptions = value.map(field => fieldAsDropdownOption(field));

    const dropdownRef = useRef<HTMLDivElement>(null);
    const MAX_MENU_HEIGHT = 150;
    const menuPlacement = useMenuPlacement(dropdownRef, MAX_MENU_HEIGHT);

    return (
        <div
            data-testid='agency-link-field-wrapper'
            className={styles.dropdownFieldWrapper}
            ref={dropdownRef}
        >
            <div className={styles.dropdownHeader}>
                <div className={styles.dropdownLabel}>Link to Dataset Fields<span className={styles.optionalLabel}>(optional)</span></div>
                <InformationTooltip content='Does this field link directly to one or more fields within the dataset for which this Annex is being created?' />
            </div>
            <Select
                className={styles.dropdownField}
                classNamePrefix='ark-dropdown'
                isDisabled={disabled}
                value={mappedValue}
                onChange={updateValue}
                isMulti={isMulti}
                options={mappedOptions}
                styles={customStyles}
                maxMenuHeight={MAX_MENU_HEIGHT}
                menuPlacement={menuPlacement}
                menuPortalTarget={document.body}
                isClearable
                fontWeight={500}
            />
        </div>
    );
};
