import { CompanyEntity } from '../../../admin/entity/store';

export enum DoraReportingActionTypes {
    DOWNLOAD_REGISTER_OF_INFORMATION_STARTED = 'DOWNLOAD_REGISTER_OF_INFORMATION_STARTED',
    DOWNLOAD_REGISTER_OF_INFORMATION_SUCCESSFUL = 'DOWNLOAD_REGISTER_OF_INFORMATION_SUCCESSFUL',
    DOWNLOAD_REGISTER_OF_INFORMATION_FAILED = 'DOWNLOAD_REGISTER_OF_INFORMATION_FAILED',
    FETCH_ALL_IN_SCOPE_ENTITIES_STARTED = 'FETCH_ALL_IN_SCOPE_ENTITIES_STARTED',
    FETCH_ALL_IN_SCOPE_ENTITIES_SUCCESSFUL = 'FETCH_ALL_IN_SCOPE_ENTITIES_SUCCESSFUL',
    FETCH_ALL_IN_SCOPE_ENTITIES_FAILED = 'FETCH_ALL_IN_SCOPE_ENTITIES_FAILED',
    SET_ROI_CURRENCY = 'SET_ROI_CURRENCY',
    SET_SELECTED_IN_SCOPE_ENTITY = 'SET_SELECTED_IN_SCOPE_ENTITY'
}

export interface PreliminaryRoi {
    currencyOfRegister: string | null;
}

export interface DoraReportingState {
    documentDownloading: boolean;
    downloadError: string | null;
    preliminaryRoi: PreliminaryRoi;
    entitiesInScope: CompanyEntity[];
    selectedInScopeEntity: CompanyEntity | null;
}
