import classnames from 'classnames';
import { isNull, isUndefined } from 'lodash/fp';
import React, { useMemo, MouseEvent } from 'react';

import { Icon } from '../../../../shared/icon/Icon';
import { Delete } from '../../../../shared/icons';
import { DatasetFieldSettings, DatasetFieldType, SingleDatasetFieldType } from '../../../../datasets/store';
import styles from '../DatasetComponents.module.scss';
import { capitaliseStartLetter } from '../../../../../utils/regex-utils';
import { Toggle } from '../../../../shared/toggle';
import { Position, PositionModal } from '../../../../shared/modal/PositionModal';
import { PaletteSquare } from './Highlight';

interface SettingsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    content: DatasetFieldSettings;
    toggleSetting: (key: keyof DatasetFieldSettings, value: boolean | null) => void;
    openDatasetLink: () => void;
    openDropdownLink: () => void;
    openEntityModal: () => void;
    openDocumentProperty: () => void;
    openLabelContentModal: () => void;
    openCalculatorTypeModal: () => void;
    openHighlightPalette: (e: MouseEvent<HTMLDivElement>) => void;
    isAgency: boolean;
    type: SingleDatasetFieldType;
}

interface Labels {
    mandatory: string;
    primaryKey: string;
    isMultiSelect: string;
    showRef: string;
    showClause: string;
    agencyField: string;
    isCollapsible: string;
    labelContent: string;
    centreField: string;
    riskField: string;
}

const labels: Labels = {
    mandatory: 'Mandatory',
    primaryKey: ' Primary Key',
    isMultiSelect: 'Multi Select',
    showRef: 'Show Ref',
    showClause: 'Show Clause',
    agencyField: 'Agency Field',
    isCollapsible: 'Collapsible',
    labelContent: 'Label Content',
    centreField: 'Centre Field',
    riskField: 'Risk Field'
};

const objectToArray = (settings: DatasetFieldSettings, hideAgencyField: boolean) => Object.entries(settings).filter(([key]) => ![
    'isOpen',
    'datasetLinked',
    'dropdownLinked',
    'calculatorFieldType',
    'refOpen',
    'systemIdOpen',
    'entityType',
    'documentProperty',
    'opinionProperty',
    'labelContent',
    'clauseOpen',
    'showOutputField',
    'showInView',
    'showGracePeriod',
    'includeFreeTextOption',
    'linkedEntities',
    'highlight',
    hideAgencyField && 'agencyField'
].includes(key)).sort();

interface LinkButtonProps {
    contentField: string | null;
    removeLink?: () => void;
    openLink: () => void;
    id: string;
    label: string;
}

interface SelectorProps {
    openSelector: () => void;
    contentField: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({ contentField, removeLink, openLink, id, label }) => (
    <div className={styles.linkButtonWrapper}>
        <button onClick={openLink} className={styles.linkButton} data-testid={`settings-item-${id}-link`}>
            {isNull(contentField) ? label : 'Linked'}
        </button>
        {!isNull(contentField) && !isUndefined(removeLink) &&
            <button
                className={styles.removeLink}
                onClick={removeLink}
                data-testid={`settings-item-remove-${id}-link`}
            >
                <Icon icon={Delete} fontSize={25} />
            </button>
        }
    </div>
);

const EntityTypeSelectorButton: React.FC<SelectorProps> = ({ openSelector, contentField }) => (
    <div className={styles.linkButtonWrapper}>
        <button onClick={openSelector} className={styles.linkButton} data-testid={'settings-item-entity-type-selector-link'}>
            {capitaliseStartLetter(contentField)}
        </button>
    </div>
);

export const SettingsModal: React.FC<SettingsModalProps> = ({
    closeModal,
    isOpen,
    position,
    content,
    toggleSetting,
    openDatasetLink,
    openDropdownLink,
    openEntityModal,
    openDocumentProperty,
    openLabelContentModal,
    openCalculatorTypeModal,
    openHighlightPalette,
    isAgency,
    type
}) => {
    const removeDropdownLink = () => toggleSetting('dropdownLinked', null);
    const removeDatasetLink = () => toggleSetting('datasetLinked', null);
    const removeDocumentProperty = () => toggleSetting('documentProperty', null);
    const disabledAgencyToggle = (key: string) => key === 'agencyField' && !isAgency;
    const hideAgencyField = [DatasetFieldType.DOCUMENT, DatasetFieldType.DATASET, DatasetFieldType.CALCULATOR].includes(type);
    const highlightSelected = useMemo(() => !isNull(content.highlight) && !isUndefined(content.highlight), [content]);

    if (!isOpen) {
        return null;
    }

    return (
        <PositionModal
            isOpen={isOpen}
            padding='5px'
            closeModal={closeModal}
            position={position}
            testId='dataset-builder-settings'
            width='160px'
            height='fit-content'
        >
            <div className={styles.settingsWrapper} data-testid='settings-modal-wrapper'>
                {objectToArray(content, hideAgencyField).map(([key, value]) => (
                    <div key={key} className={styles.settingsContent} data-testid={`settings-item-${key}-wrapper`}>
                        <div className={classnames(styles.settingsLabel, { [styles.settingsLabelDisabled]: disabledAgencyToggle(key) })} data-testid={`settings-item-${key}-label`}>{labels[key as keyof Labels]}</div>
                        <Toggle
                            checked={value}
                            onChange={(val) => toggleSetting(key as keyof DatasetFieldSettings, val)}
                            disabled={disabledAgencyToggle(key)}
                            testId={`settings-item-${key}`}
                        />
                    </div>
                ))}
                <div className={styles.settingsContent}>
                    <div className={styles.settingsLabel}>Highlight</div>
                    <PaletteSquare squareColor={content.highlight} isSelected={highlightSelected} onClick={e => openHighlightPalette(e)} />
                </div>
                {!isUndefined(content.datasetLinked) &&
                    <LinkButton
                        contentField={content.datasetLinked}
                        openLink={openDatasetLink}
                        removeLink={removeDatasetLink}
                        id='dataset'
                        label='Link to Dataset'
                    />
                }
                {!isUndefined(content.dropdownLinked) &&
                    <LinkButton
                        contentField={content.dropdownLinked}
                        openLink={openDropdownLink}
                        removeLink={removeDropdownLink}
                        id='dropdown'
                        label='Link Dropdown'
                    />
                }
                {!isUndefined(content.documentProperty) &&
                    <LinkButton
                        contentField={content.documentProperty}
                        openLink={openDocumentProperty}
                        removeLink={removeDocumentProperty}
                        id='document'
                        label='Link Document Property'
                    />
                }
                {!isUndefined(content.labelContent) &&
                    <LinkButton
                        contentField={null}
                        openLink={openLabelContentModal}
                        id='label'
                        label='Set Label'
                    />
                }
                {!isUndefined(content.calculatorFieldType) &&
                    <LinkButton
                        contentField={content.calculatorFieldType}
                        openLink={openCalculatorTypeModal}
                        id='calculator-field-type'
                        label='Select Field Type'
                    />
                }
                {!isUndefined(content.entityType) &&
                    <EntityTypeSelectorButton
                        openSelector={openEntityModal}
                        contentField={content.entityType}
                    />
                }
            </div>
        </PositionModal>
    );
};
