import classnames from 'classnames';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { Toggle } from '../../shared/toggle';
import { CustomTooltip } from '../../shared/tooltip';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import styles from './Fields.module.scss';

interface CheckboxProps {
    id: string;
    index: number;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean
    showAIModifiedUserCorrected?: boolean;
    isMLCorrection?: boolean;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    disabled?: boolean;
    checked: boolean;
    isTable?: boolean;
    showClause?: boolean;
    includedInAnnex?: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
    centreField?: boolean;
}

const { grey, amethyst, green, gold, amber } = styles;

export const Checkbox: React.FC<CheckboxProps> = ({
    id,
    disabled = false,
    checked,
    isTable = false,
    index,
    sectionId,
    groupIndex,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    showClause = false,
    includedInAnnex = false,
    isMLCorrection = false,
    datasetId,
    modalInstance,
    parentFieldId,
    centreField
}) => {
    const dispatch = useAppDispatch();
    const updateValue = (value: boolean) => {
        if (isMLCorrection) {
            dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
        }
    };

    const boxShadowColour = useMemo(() => {
        if (!showDatasetUpdated && !showFieldUpdated && !showAIModified && !showAIModifiedUserCorrected) {
            return grey;
        }
        if (showAIModifiedUserCorrected) {
            return amber;
        }
        if (showAIModified) {
            return gold;
        }
        return showFieldUpdated ? amethyst : green;
    }, [showDatasetUpdated, showFieldUpdated, showAIModified, showAIModifiedUserCorrected]);

    const onColour = useMemo(() => {
        if (showFieldUpdated) {
            return amethyst;
        }
        if (showAIModified) {
            return gold;
        }
        if (showAIModifiedUserCorrected) {
            return amber;
        }
    }, [showFieldUpdated, showAIModified, showAIModifiedUserCorrected]);

    const overlayText = includedInAnnex ? 'See Agency Annex' : null;

    const wrapperStyle = useMemo(() => isTable || centreField ? { alignSelf: 'center', marginTop: `${isTable ? '-5px' : undefined}` } : {}, [isTable, centreField]);

    return (
        <div style={wrapperStyle} className={classnames(styles.checkboxFieldWrapper, {
            [styles.withClauseLabel]: showClause && isTable,
            [styles.tableCheckBoxFieldWrapper]: !isTable
        })}>
            <CustomTooltip overlayText={overlayText}>
                <Toggle
                    checked={checked}
                    disabled={disabled || includedInAnnex}
                    onChange={updateValue}
                    testId={`definition-checkbox-${id}`}
                    boxShadowColour={boxShadowColour}
                    onColour={onColour}
                />
            </CustomTooltip>
        </div>
    );
};
