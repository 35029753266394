import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { AttestationManagerTableRow } from '../manager/AttestationManagerTable';

export enum AdminAttestationActionTypes {
    SELECT_ATTESTATION_PAGE = 'SELECT_ATTESTATION_PAGE',
    SET_SELECTED_ATTESTATION_FORM_TAB = 'SET_SELECTED_ATTESTATION_FORM_TAB',
    TOGGLE_ATTESTATION_FORM_BUILDER_WIZARD_OPEN = 'TOGGLE_ATTESTATION_FORM_BUILDER_WIZARD_OPEN',
    UPDATE_ATTESTATION_FORM_VALUE = 'UPDATE_ATTESTATION_FORM_VALUE',
    ADD_ATTESTATION_QUESTION = 'ADD_ATTESTATION_QUESTION',
    REMOVE_ATTESTATION_QUESTION = 'REMOVE_ATTESTATION_QUESTION',
    UPDATE_ATTESTATION_QUESTION_VALUE = 'UPDATE_ATTESTATION_QUESTION_VALUE',
    UPDATE_ATTESTATION_QUESTION_CONFIG_VALUE = 'UPDATE_ATTESTATION_QUESTION_CONFIG_VALUE',
    TOGGLE_ATTESTATION_QUESTION_CONFIG_MODAL_OPEN = 'TOGGLE_ATTESTATION_QUESTION_CONFIG_MODAL_OPEN',
    ADD_CUSTOM_ANSWER = 'ADD_CUSTOM_ANSWER',
    UPDATE_CUSTOM_ANSWER = 'UPDATE_CUSTOM_ANSWER',
    REMOVE_CUSTOM_ANSWER = 'REMOVE_CUSTOM_ANSWER',
    TOGGLE_ATTESTATION_FORM_BUILDER_CONFIRMATION_MODAL = 'TOGGLE_ATTESTATION_FORM_BUILDER_CONFIRMATION_MODAL',
    SAVE_ATTESTATION_FORM_STARTED = 'SAVE_ATTESTATION_FORM_STARTED',
    SAVE_ATTESTATION_FORM_SUCCESSFUL = 'SAVE_ATTESTATION_FORM_SUCCESSFUL',
    SAVE_ATTESTATION_FORM_FAILED = 'SAVE_ATTESTATION_FORM_FAILED',
    FETCH_ALL_ATTESTATION_FORMS_STARTED = 'FETCH_ALL_ATTESTATION_FORMS_STARTED',
    FETCH_ALL_ATTESTATION_FORMS_SUCCESSFUL = 'FETCH_ALL_ATTESTATION_FORMS_SUCCESSFUL',
    FETCH_ALL_ATTESTATION_FORMS_FAILED = 'FETCH_ALL_ATTESTATION_FORMS_FAILED',
    SET_ATTESTATION_FORM_UPDATED = 'SET_ATTESTATION_FORM_UPDATED',
    DELETE_ATTESTATION_FORM_STARTED = 'DELETE_ATTESTATION_FORM_STARTED',
    DELETE_ATTESTATION_FORM_SUCCESSFUL = 'DELETE_ATTESTATION_FORM_SUCCESSFUL',
    DELETE_ATTESTATION_FORM_FAILED = 'DELETE_ATTESTATION_FORM_FAILED',
    CREATE_ATTESTATION_STARTED = 'CREATE_ATTESTATION_STARTED',
    CREATE_ATTESTATION_SUCCESSFUL = 'CREATE_ATTESTATION_SUCCESSFUL',
    CREATE_ATTESTATION_FAILED = 'CREATE_ATTESTATION_FAILED',
    TOGGLE_ATTESTATION_INSTANCE_VIEW = 'TOGGLE_ATTESTATION_INSTANCE_VIEW',
    TOGGLE_NEW_ATTESTATION_MODAL_OPEN = 'TOGGLE_NEW_ATTESTATION_MODAL_OPEN',
    UPDATE_NEW_ATTESTATION_FORM_ID = 'UPDATE_NEW_ATTESTATION_FORM_ID',
    UPDATE_ATTESTATION_INSTANCES = 'UPDATE_ATTESTATION_INSTANCES',
    SET_SELECTED_ATTESTATION_MANAGER_TAB = 'SET_SELECTED_ATTESTATION_MANAGER_TAB',
    TOGGLE_ATTESTATION_MANAGER_WIZARD_OPEN = 'TOGGLE_ATTESTATION_MANAGER_WIZARD_OPEN',
    FETCH_ALL_ATTESTATION_INSTANCES_STARTED = 'FETCH_ALL_ATTESTATION_INSTANCES_STARTED',
    FETCH_ALL_ATTESTATION_INSTANCES_SUCCESSFUL = 'FETCH_ALL_ATTESTATION_INSTANCES_SUCCESSFUL',
    FETCH_ALL_ATTESTATION_INSTANCES_FAILED = 'FETCH_ALL_ATTESTATION_INSTANCES_FAILED',
    DELETE_ATTESTATION_INSTANCE_STARTED = 'DELETE_ATTESTATION_INSTANCE_STARTED',
    DELETE_ATTESTATION_INSTANCE_SUCCESSFUL = 'DELETE_ATTESTATION_INSTANCE_SUCCESSFUL',
    DELETE_ATTESTATION_INSTANCE_FAILED = 'DELETE_ATTESTATION_INSTANCE_FAILED',
    UPDATE_ATTESTATION_USER_STATUS = 'UPDATE_ATTESTATION_USER_STATUS',
    UPDATE_ATTESTATION_DEADLINE = 'UPDATE_ATTESTATION_DEADLINE',
    SET_ATTESTATION_INSTANCE_UPDATED = 'SET_ATTESTATION_INSTANCE_UPDATED',
    SAVE_ATTESTATION_INSTANCE_STARTED = 'SAVE_ATTESTATION_INSTANCE_STARTED',
    SAVE_ATTESTATION_INSTANCE_SUCCESSFUL = 'SAVE_ATTESTATION_INSTANCE_SUCCESSFUL',
    SAVE_ATTESTATION_INSTANCE_FAILED = 'SAVE_ATTESTATION_INSTANCE_FAILED',
    ADD_ATTESTATION_FORM_USER_ANSWER = 'ADD_ATTESTATION_FORM_USER_ANSWER',
    REMOVE_ATTESTATION_FORM_USER_ANSWER = 'REMOVE_ATTESTATION_FORM_USER_ANSWER',
    UPDATE_ATTESTATION_FORM_USER_ANSWER_LABEL = 'UPDATE_ATTESTATION_FORM_USER_ANSWER_LABEL',
    CLEAR_ATTESTATION_INSTANCES_TABLE_FILTERS = 'CLEAR_ATTESTATION_INSTANCES_TABLE_FILTERS',
    SET_ATTESTATION_INSTANCES_TABLE_FILTERS = 'SET_ATTESTATION_INSTANCES_TABLE_FILTERS',
    SET_ATTESTATION_INSTANCES_TABLE_COLUMN_SORT = 'SET_ATTESTATION_INSTANCES_TABLE_COLUMN_SORT',
    SET_ATTESTATION_INSTANCES_PAGE_SIZE = 'SET_ATTESTATIONS_PAGE_SIZE',
    ATTESTATION_INSTANCES_PAGINATION_NEXT = 'ATTESTATION_INSTANCES_PAGINATION_NEXT',
    ATTESTATION_INSTANCES_PAGINATION_PREVIOUS = 'ATTESTATION_INSTANCES_PAGINATION_PREVIOUS',
    TOGGLE_DELETE_ATTESTATION_FORM_CONFIRMATION_MODAL = 'TOGGLE_DELETE_ATTESTATION_FORM_CONFIRMATION_MODAL',
    TOGGLE_DELETE_ATTESTATION_CONFIRMATION_MODAL = 'TOGGLE_DELETE_ATTESTATION_CONFIRMATION_MODAL'
}

export enum AttestationPage {
    SELECT = 'Select',
    MANAGER = 'Attestation Manager',
    FORMS = 'Attestation Forms'
}

export enum FormBuilderTab {
    DEFINE = 'Define',
    ATTESTATIONS = 'Attestations'
}

export enum AttestationManagerTab {
    DETAILS = 'Details',
    ATTESTATIONS = 'Attestations',
    CONFIGURE = 'Configure'
}

export enum AttestationAnswers {
    YES_NO = 'Yes/No',
    RAG = 'Red/Amber/Green',
    CUSTOM = 'Custom',
    FREE_TEXT = 'Free Text'
}

export type ConfigValue = boolean | string | AttestationAnswers | string[] | undefined | null | number;

export enum AttestationConfirmationModal {
    CLOSE = 'Close',
    SAVE = 'Save'
}

export enum AttestationStatus {
    AWAITING_USER_RESPONSE = 'awaiting user response',
    DRAFT = 'draft',
    USER_COMPLETED = 'user completed',
    COMPLETED = 'completed',
    DEADLINE_PASSED = 'deadline passed',
    DELETED = 'deleted'
}

export interface FormUserAnswer {
    userAnswerId: string;
    label: string;
}

export interface UserAnswerId extends FormUserAnswer {
    userId: number | null;
}

export interface UserAnswerIdDB extends UserAnswerId {
    userId: number;
}

export interface AttestationQuestionConfig {
    answers: AttestationAnswers;
    customAnswers?: string[];
    isRequired: boolean;
    isHidden: boolean;
    redFlagAnswer: string | null;
    forceRedFlagExplanation: boolean;
    alertMessage: string;
    userAnswerId: string;
}

export interface AttestationQuestion {
    id: string;
    question: string;
    description: string;
    config: AttestationQuestionConfig;
}

export interface AttestationInstanceQuestion {
    id: string;
    question: string;
    description: string;
    config: AttestationQuestionConfig;
    answer: string | null;
    userNotes: string;
}

export interface AttestationForm {
    attestationFormId?: number;
    name: string;
    description: string;
    userAnswers: FormUserAnswer[];
    isSystem: number;
    attestations: AttestationQuestion[];
}

export interface AttestationFormDB extends AttestationForm {
    attestationFormId: number;
    createdDate: string;
    createdBy: number;
    modifiedDate: string;
    modifiedBy: number;
    isArchived: number;
    clientId: number;
}

export interface AttestationInstance {
    attestationInstanceId?: number;
    attestationFormId: number | null;
    userIds: UserAnswerId[];
    deadline: string | null;
    attestations: AttestationInstanceQuestion[];
}

export interface AttestationInstanceDB extends AttestationInstance {
    attestationInstanceId: number;
    attestationFormId: number;
    userIds: UserAnswerIdDB[];
    createdDate: string;
    createdBy: number;
    modifiedDate: string;
    modifiedBy: number;
    completedBy: number | null;
    completedDate: string | null;
    isArchived: number;
    clientId: number;
    attestationName: string;
}

export interface AttestationInstanceStatus {
    userId: number;
    username: string;
    userAnswerId: string;
    status: AttestationStatus;
}

export interface AttestationManager {
    attestationInstance: AttestationInstanceDB;
    attestationStatus: AttestationInstanceStatus[];
}

export interface InstancesToCreate {
    userIds: UserAnswerId[];
    deadline: string | null;
    attestations: AttestationInstanceQuestion[];
}

export interface NewAttestationInstances {
    attestationFormId: number | null;
    instancesToCreate: InstancesToCreate[];
}

export interface AdminAttestationState {
    attestationPage: AttestationPage;
    formBuilderWizardOpen: boolean;
    selectedFormTab: FormBuilderTab;
    selectedAttestationForm: AttestationForm | null;
    attestationQuestionConfigModalOpen: number | null;
    attestationConfirmationModal: AttestationConfirmationModal | null;
    isSaving: boolean;
    attestationForms: AttestationFormDB[];
    attestationFormUpdated: boolean;
    isLoading: boolean;
    isDeleting: boolean;
    viewCompletedAttestations: boolean;
    newAttestationModalOpen: boolean;
    newAttestations: NewAttestationInstances | null;
    attestations: AttestationManager[];
    attestationManagerWizardOpen: boolean;
    selectedManagerTab: AttestationManagerTab;
    selectedAttestationInstance: AttestationManager | null;
    attestationInstanceUpdated: boolean;
    attestationInstancesPage: number;
    totalAttestationInstances: number;
    attestationInstancesFilters: TableFilters;
    attestationInstancesSort?: ColumnSort;
    attestationInstancesPageSize: number;
    confirmDeleteAttestationForm: AttestationForm | null;
    confirmDeleteAttestation: AttestationManagerTableRow | null;
}
