import { get } from 'lodash';

const serviceRequest = async (endPoint: string, requestBody: any, method = 'post', headers = {}, isFormData = false) => {
    try {
        if (method === 'post') {
            const request: RequestInit = {
                method: method,
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...headers
                },
                body: isFormData ? requestBody : JSON.stringify(requestBody)
            };

            if (isFormData) delete request.headers;

            return await fetch(endPoint, request);
        } else {
            return await fetch(endPoint, { credentials: 'include' });
        }
    }
    catch (error) {
        console.log('Service Error', error); // eslint-disable-line
        throw error;
    }
};

const handleResponse = async (response: Response, isDownload: boolean): Promise<any> => {
    if (response.status !== 200) {
        const responseBody = await response.text();
        const parsedResponse = responseBody ? JSON.parse(responseBody) : {};
        if (!response.ok) {
            throw new Error(get(parsedResponse, 'message', 'Unknown Error'));
        }
        return parsedResponse;
    } else if (isDownload) {
        const responseBody = await response.blob();
        return responseBody;
    } else {
        const responseBody = await response.json();
        return responseBody;
    }
};

export const apiRoot = () => {
    const url = window.location.href;
    if (url.includes('localhost:5151')) {
        return 'http://localhost';
    } else if (url.includes('dev.ark-51.com')) {
        return 'https://dev-api.ark-51.com';
    } else if (url.includes('app.ark-51.com')) {
        return 'https://api.ark-51.com';
    }
};

export const api = async (endpoint: string, requestBody: any, method: string, headers = {}, isFormData = false, isDownload = false) => {
    const fullEndpoint = `${apiRoot()}/api/${endpoint}`;
    return await handleResponse(
        await serviceRequest(fullEndpoint, requestBody, method, headers, isFormData),
        isDownload
    );
};
