import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { IconButton } from '../../../../shared/button/IconButton';
import { PlusButton } from '../../../../shared/button/PlusButton';
import { DragDrop } from '../../../../shared/drag-n-drop/DragDrop';
import { DraggableItem } from '../../../../shared/drag-n-drop/shared';
import { Delete, Settings } from '../../../../shared/icons';
import { LongText } from '../../../../shared/longtext/LongText';
import { Text } from '../../../../shared/text/Text';
import { AttestationForm, AttestationQuestion, addAttestationQuestion, getAttestationConfigModalOpen, removeAttestationQuestion, toggleAttestationConfigModalOpen, updateAttestationFormValue, updateAttestationQuestionValue } from '../../store';
import styles from '../AttestationForms.module.scss';
import { AttestationConfigModal } from './AttestationConfigModal';

interface AttestationsTabProps {
    attestationForm: AttestationForm;
    testId?: string;
}

interface AttestationProps {
    attestation: AttestationQuestion;
    index: number;
    toggleConfigModal: (index: number) => void;
    showDelete: boolean;
    testId?: string;
}

const Attestation: React.FC<AttestationProps> = ({ attestation, index, toggleConfigModal, showDelete, testId }) => {
    const { id, question, description } = attestation;
    const dispatch = useAppDispatch();

    const removeAttestation = () => dispatch(removeAttestationQuestion(index));
    const toggleConfigModalOpen = () => toggleConfigModal(index);
    const updateQuestion = (value: string) => dispatch(updateAttestationQuestionValue(index, 'question', value));
    const updateDescription = (value: string) => dispatch(updateAttestationQuestionValue(index, 'description', value));

    return (
        <div className={styles.attestationWrapper}>
            <div className={styles.questionWrapper} data-testid={`${testId}-question-${index}-wrapper`}>
                <Text
                    marginBottom='0'
                    testId={`${testId}-question-${index}`}
                    maxLength={256}
                    value={question}
                    onChange={e => updateQuestion(e.target.value)}
                    placeholder='Question...'
                />
            </div>
            <div className={styles.descriptionWrapper}>
                <LongText
                    value={description}
                    maxLength={512}
                    onChange={updateDescription}
                    placeholder='Description...'
                    width='calc(100% - 20px)'
                    testId={`attestation-description-${id}`}
                />
            </div>
            <div className={styles.iconWrapper}>
                <div className={styles.configWrapper}>
                    <IconButton icon={Settings} onClick={toggleConfigModalOpen} testId={`${testId}-attestation-config-${index}`} />
                </div>
                {showDelete && <div className={styles.removeRowWrapper}>
                    <IconButton icon={Delete} onClick={removeAttestation} color={styles.red} testId={`${testId}-attestation-delete-${index}`} />
                </div>}
            </div>
        </div>
    );
};

export const AttestationsTab: React.FC<AttestationsTabProps> = ({ attestationForm, testId }) => {
    const { attestations, userAnswers } = attestationForm;
    const dispatch = useAppDispatch();
    const configModalOpen = useAppSelector(getAttestationConfigModalOpen);

    const listOptions = useMemo(() => attestations.map(({ id, question }) => ({ id, label: question })), [attestations]);

    const updateList = (list: DraggableItem[]) => {
        const newAttestations = list.map(({ id }) => attestations.find(attestation => attestation.id === id)!);
        dispatch(updateAttestationFormValue('attestations', newAttestations));
    };

    const addAttestation = () => dispatch(addAttestationQuestion());
    const toggleConfigModal = useCallback((index: number | null) => dispatch(toggleAttestationConfigModalOpen(index)), [dispatch]);

    const attestationConfig = useMemo(() => !isNull(configModalOpen) ? attestations[configModalOpen].config : null, [configModalOpen, attestations]);
    const showConfig = !isNull(configModalOpen) && !isNull(attestationConfig);

    const getChildElement = useCallback((childId: string, index: number) => {
        const attestation = attestations.find(({ id }) => id === childId);
        if (attestation) {
            return (
                <Attestation
                    key={attestation.id}
                    attestation={attestation}
                    index={index}
                    toggleConfigModal={toggleConfigModal}
                    showDelete={attestations.length > 1}
                    testId={testId}
                />
            );
        }
        return null;
    }, [attestations, toggleConfigModal, testId]);

    return (
        <>
            <div className={styles.attestationsTab} data-testid={`${testId}-wrapper`}>
                <div className={styles.attestationsHeader} data-testid={`${testId}-header-wrapper`}>
                    <div className={styles.questionHeader} data-testid={`${testId}-question-header`}>Question*</div>
                    <div className={styles.descriptionHeader} data-testid={`${testId}-description-header`}>Description</div>
                    <div className={styles.addAttestation}>
                        <PlusButton onClick={addAttestation} testId={testId} fontSize={20} />
                    </div>
                </div>
                <div className={styles.attestationsDroppableWrapper}>
                    <DragDrop getChildElement={getChildElement} list={listOptions} listId='attestations' updateList={updateList} />
                </div>
            </div>
            {showConfig &&
                <AttestationConfigModal
                    isOpen={showConfig}
                    closeModal={() => toggleConfigModal(null)}
                    attestationIndex={configModalOpen!}
                    config={attestationConfig!}
                    question={attestations[configModalOpen!].question}
                    userAnswers={userAnswers}
                    testId={`${testId}-config-modal`}
                />
            }
        </>
    );
};
