import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { addSystemTagStarted, ClauseTags, getSystemTagCategoryModalOpen, toggleSystemTagCategoryModal } from '../../clause-library/store';
import { Tag } from '../icons';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { ModalHeader } from '../modal/ModalHeader';
import { OverflowTooltip } from '../tooltip';
import styles from './ClauseModal.module.scss';
import { getTagBackgroundColour, getTagTypeLabel } from './ClauseModalTags';
import { addOpinionClauseSystemTagStarted } from '../../opinions/instances/store';

const { lightGrey } = styles;

interface ClauseSystemTagCategoryModalProps {
    tagTerm: string;
    tags: ClauseTags;
    isOpenViaOpinion: boolean;
}

export const ClauseSystemTagCategoryModal: React.FC<ClauseSystemTagCategoryModalProps> = ({ tagTerm, tags, isOpenViaOpinion }) => {
    const dispatch = useAppDispatch();
    const [selectedCategory, setCategory] = useState<keyof ClauseTags | null>(null);

    const isOpen = useAppSelector(getSystemTagCategoryModalOpen);

    const close = useCallback(() => {
        dispatch(toggleSystemTagCategoryModal());
        setCategory(null);
    }, [dispatch]);

    const confirm = useCallback(() => {
        dispatch(toggleSystemTagCategoryModal());
        const updatedTags = [...tags[selectedCategory!], tagTerm];
        isOpenViaOpinion ? dispatch(addOpinionClauseSystemTagStarted(tagTerm, selectedCategory!, updatedTags)) : dispatch(addSystemTagStarted(tagTerm, selectedCategory!, updatedTags));
        setCategory(null);
    }, [dispatch, isOpenViaOpinion, tagTerm, selectedCategory, tags]);

    const confirmDisabled = useMemo(() => isNull(selectedCategory), [selectedCategory]);
    const confirmDisabledTooltip = useMemo(() => confirmDisabled ? ['You must select a category for the tag'] : undefined, [confirmDisabled]);
    const clauseTagsKeys = [['agreementType', 'counterpartyType', 'productType'], ['provisionType', 'opinions', 'miscellaneous']];

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={close}
            confirm={confirm}
            confirmLabel='Confirm'
            confirmDisabled={confirmDisabled}
            confirmDisabledTooltip={confirmDisabledTooltip}
            testId='clause-library-system-template-tag-category-modal'
        >
            <div className={styles.systemTagCategoriesWrapper}>
                <ModalHeader
                    testId='clause-library-system-template-tag-category-modal'
                    label='Tag Category'
                    icon={Tag}
                />
                <div className={styles.tagTermWrapper}>
                    <div className={styles.tagTermLabel}>Please select a category for the tag:</div>
                    <div className={styles.tagTerm}><OverflowTooltip overlayText={tagTerm} /></div>
                </div>
                <div className={styles.categoriesWrapper}>
                    {clauseTagsKeys.map((column, index) => (
                        <div className={styles.categoryColumn} key={index}>
                            {column.map((category, index) => {
                                const tagCategory = category as keyof ClauseTags;
                                const isSelected = category === selectedCategory;
                                const wrapperBackground = isSelected ? getTagBackgroundColour(tagCategory) : lightGrey;
                                return (
                                    <div className={classnames(styles.tagCategoryWrapper, { [styles.selectedTagCategoryWrapper]: isSelected })} style={{ backgroundColor: wrapperBackground }} key={index}>
                                        <div className={styles.tagCategory} onClick={() => setCategory(tagCategory)}>{getTagTypeLabel(tagCategory)}</div>
                                        <div className={styles.tagColour} style={{ backgroundColor: getTagBackgroundColour(tagCategory) }} />
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </ConfirmationModal>
    );
};
