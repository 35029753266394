export enum RegistrationActionTypes {
    UPDATE_REGISTRATION_DETAILS = 'UPDATE_REGISTRATION_DETAILS',
    VALIDATE_REGISTRATION_EMAIL = 'VALIDATE_REGISTRATION_EMAIL',
    SET_REGISTRATION_EMAIL_VALID = 'SET_REGISTRATION_EMAIL_VALID',
    SUBMIT_REGISTRATION_DETAILS_STARTED = 'SUBMIT_REGISTRATION_DETAILS_STARTED',
    SUBMIT_REGISTRATION_DETAILS_SUCCESSFUL = 'SUBMIT_REGISTRATION_DETAILS_SUCCESSFUL',
    SUBMIT_REGISTRATION_DETAILS_FAILED = 'SUBMIT_REGISTRATION_DETAILS_FAILED'
}

export interface RegistrationDetails {
    name: string;
    companyName: string;
    position: string;
    email: string;
    phone: string;
}
export interface RegistrationState {
    registrationDetails: RegistrationDetails;
    emailValid: boolean;
}
