import { action } from 'typesafe-actions';

import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { DoraAnalyticsActionTypes, DoraAnalyticsChart, NetworkDiagramData, ThirdPartyCoverageAnalytics, SelectedThirdPartyCoverage, ThirdPartyFunctionCompanyCoveragePieAnalytics, ContractualDataAnalytics } from './types';

export const setDoraTileInView = (tileInView: DoraAnalyticsChart | null, shouldRedirect: boolean) => action(DoraAnalyticsActionTypes.SET_DORA_ANALYTICS_TILE_IN_VIEW, { tileInView, shouldRedirect });

export const fetchThirdPartyCoverageDataStarted = () => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_STARTED, { chart: DoraAnalyticsChart.THIRD_PARTY_COVERAGE });
export const fetchThirdPartyCoverageDataSuccessful = (analytics: ThirdPartyCoverageAnalytics[]) => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_SUCCESSFUL, { chart: DoraAnalyticsChart.THIRD_PARTY_COVERAGE, analytics, key: 'thirdPartyCoverage' });
export const fetchThirdPartyCoverageDataFailed = (e: string) => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_FAILED, { error: e, chart: DoraAnalyticsChart.THIRD_PARTY_COVERAGE });

export const setSelectedThirdPartyCompanyId = (id: number | null) => action(DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COMPANY_ID, id);

export const fetchThirdPartyFunctionCompanyDetailsDataStarted = (pageNumber = 1) => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_STARTED, { chart: DoraAnalyticsChart.THIRD_PARTY_COVERAGE, pageNumber });
export const fetchThirdPartyFunctionCompanyDetailsDataSuccessful = (data: SelectedThirdPartyCoverage[], total: number) => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_SUCCESSFUL, { data, total });
export const fetchThirdPartyFunctionCompanyDetailsDataFailed = (e: string) => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_FAILED, { error: e, chart: DoraAnalyticsChart.THIRD_PARTY_COVERAGE });

export const fetchThirdPartyFunctionCompanyPieAnalyticsStarted = () => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_STARTED);
export const fetchThirdPartyFunctionCompanyPieAnalyticsSuccessful = (analytics: ThirdPartyFunctionCompanyCoveragePieAnalytics[], selectedThirdPartyName: string) => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_SUCCESSFUL, { chart: DoraAnalyticsChart.THIRD_PARTY_COVERAGE, analytics, key: 'selectedThirdPartyCoveragePie', selectedThirdPartyName });
export const fetchThirdPartyFunctionCompanyPieAnalyticsFailed = (e: string) => action(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_FAILED, e);

export const fetchNetworkDiagramAnalyticsStarted = () => action(DoraAnalyticsActionTypes.FETCH_NETWORK_DIAGRAM_ANALYTICS_STARTED, { chart: DoraAnalyticsChart.NETWORK_DIAGRAM });
export const fetchNetworkDiagramAnalyticsSuccessful = (analytics: NetworkDiagramData) => action(DoraAnalyticsActionTypes.FETCH_NETWORK_DIAGRAM_ANALYTICS_SUCCESSFUL, { chart: DoraAnalyticsChart.NETWORK_DIAGRAM, analytics, key: 'networkDiagramData' });
export const fetchNetworkDiagramAnalyticsFailed = (error: string) => action(DoraAnalyticsActionTypes.FETCH_NETWORK_DIAGRAM_ANALYTICS_FAILED, { error, chart: DoraAnalyticsChart.NETWORK_DIAGRAM });

export const fetchOutstandingContractualDataAnalyticsStarted = () => action(DoraAnalyticsActionTypes.FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_STARTED, { chart: DoraAnalyticsChart.CONTRACTUAL_DATA });
export const fetchOutstandingContractualDataAnalyticsSuccessful = (analytics: ContractualDataAnalytics[]) => action(DoraAnalyticsActionTypes.FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_SUCCESSFUL, { chart: DoraAnalyticsChart.CONTRACTUAL_DATA, analytics, key: 'contractualDataAnalytics' });
export const fetchOutstandingContractualDataAnalyticsFailed = (error: string) => action(DoraAnalyticsActionTypes.FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_FAILED, { error, chart: DoraAnalyticsChart.CONTRACTUAL_DATA });

export const setSelectedOutstandingContractualDataSection = (section: string) => action(DoraAnalyticsActionTypes.SET_SELECTED_OUTSTANDING_CONTRACTUAL_DATA_SECTION, section);

// Tables
// Third Party Function Coverage
export const thirdPartyFunctionCompanyPaginationNext = () => action(DoraAnalyticsActionTypes.SELECTED_THIRD_PARTY_COVERAGE_PAGINATION_NEXT);
export const thirdPartyFunctionCompanyPaginationPrevious = () => action(DoraAnalyticsActionTypes.SELECTED_THIRD_PARTY_COVERAGE_PAGINATION_PREVIOUS);
export const setThirdPartyFunctionCompanySize = (pageSize: number) => action(DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_PAGE_SIZE, pageSize);
export const setThirdPartyFunctionCompanyColumnSort = (columnSort: ColumnSort | undefined) => action(DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_COLUMN_SORT, columnSort);
export const setThirdPartyFunctionCompanyTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS, { key, value, type });
export const clearThirdPartyFunctionCompanyTableFilters = () => action(DoraAnalyticsActionTypes.CLEAR_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS);
