import React, { useCallback, useMemo } from 'react';
import { ToolbarProps, NavigateAction } from 'react-big-calendar';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { formatDate, FULL_DATE_FORMAT, futureDate, pastDate } from '../../../../utils/luxon';
import { IconButton } from '../../../shared/button/IconButton';
import { DatePicker } from '../../../shared/datepicker/DatePicker';
import { CaretDown, CaretUp, Calendar } from '../../../shared/icons';
import { getToolbarDate, setToolbarDate, setSelectedMonthYear } from '../store';
import styles from '../Workflow.module.scss';

export const WorkflowCalendarToolbar: React.FC<ToolbarProps> = ({ date, onNavigate }) => {
    const dispatch = useAppDispatch();
    const selectedDate = useAppSelector(getToolbarDate);
    const todaysDate = useMemo(() => new Date(), []);
    const nextMonth = useMemo(() => futureDate({ months: 1 }, date), [date]);
    const previousMonth = useMemo(() => pastDate({ months: 1 }, date), [date]);

    const updateSelectedMonthYear = useCallback((date: string | Date) => dispatch(setSelectedMonthYear(formatDate(date, 'yyyy-MM'))), [dispatch]);

    const updateCalendarEvents = useCallback((value: NavigateAction, date?: Date) => {
        let updatedDate: string | Date = todaysDate;
        switch (value) {
            case 'PREV':
                updatedDate = previousMonth;
                break;
            case 'NEXT':
                updatedDate = nextMonth;
                break;
            case 'DATE':
                updatedDate = date!;
                break;
            case 'TODAY':
            default:
                updatedDate = todaysDate;
        }
        updateSelectedMonthYear(updatedDate);
    }, [previousMonth, nextMonth, todaysDate, updateSelectedMonthYear]);

    const updateDate = (value: NavigateAction, date?: Date) => {
        updateCalendarEvents(value, date);
        onNavigate(value, date);
    };

    const changeDate = (value: Date) => {
        dispatch(setToolbarDate(value));
        updateDate('DATE', value);
    };
    const calendarLabel = formatDate(date, FULL_DATE_FORMAT);

    return (
        <div className={styles.toolbarWrapper} data-testid='admin-workflow-calendar-toolbar-wrapper'>
            <div className={styles.workflowTitleWrapper}>
                <div className={styles.buttonGroupLeft}>
                    <IconButton onClick={() => updateDate('PREV')} disabled={false} testId='admin-workflow-calendar-toolbar-previous' icon={CaretUp} fontSize={20} withBackground />
                    <IconButton onClick={() => updateDate('TODAY')} disabled={false} testId='admin-workflow-calendar-toolbar-today' icon={Calendar} fontSize={20} withBackground />
                    <IconButton onClick={() => updateDate('NEXT')} disabled={false} testId='admin-workflow-calendar-toolbar-next' icon={CaretDown} fontSize={20} withBackground />
                </div>
            </div>
            <div className={styles.calendarLabel}>
                <label data-testid='admin-workflow-calendar-toolbar-month-label'>{calendarLabel}</label>
            </div>
            <div className={styles.calendarDatepicker}>
                <DatePicker
                    value={selectedDate}
                    onChange={value => changeDate(value!)}
                    testId={'workflow-calendar'}
                />
            </div>
        </div>
    );
};
