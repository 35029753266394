import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { BookCoverModal } from '../../../shared/playbook/BookCoverModal';
import styles from '../../../shared/playbook/PlaybookBookcase.module.scss';
import { PlaybookShelf } from '../../../shared/playbook/PlaybookShelf';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { SimpleSearch } from '../../../shared/search/SimpleSearch';
import { fetchAvailableDocumentNamesStarted } from '../../documents/store';
import { BasicPlaybook, fetchAllTemplatesStarted, getPlaybookTemplateShelves, getPlaybookTemplateSimpleSearchTerm, getPlaybookTemplateStarted, getTemplateCoverModalOpen, getTemplateCoverSelected, resetPlaybookTemplateSearch, toggleTemplateCoverSelected, updatePlaybookTemplateSimpleSearch } from '../store';

export const Templates: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllTemplatesStarted(), fetchAvailableDocumentNamesStarted()]);

    const playbookShelves = useAppSelector(getPlaybookTemplateShelves);
    const { topShelf, bottomShelf } = playbookShelves;
    const noPlaybooks = !topShelf.length && !bottomShelf.length;

    const openPlaybookCoverModal = useCallback((playbook: BasicPlaybook) => { dispatch(toggleTemplateCoverSelected(playbook)); }, [dispatch]);

    const playbookSelected = useAppSelector(getTemplateCoverSelected);
    const isOpen = useAppSelector(getTemplateCoverModalOpen);

    const closeModal = useCallback(() => dispatch(toggleTemplateCoverSelected(null)), [dispatch]);
    const openPlaybook = useCallback(() => playbookSelected && dispatch(getPlaybookTemplateStarted(playbookSelected.playbookId)), [dispatch, playbookSelected]);

    const playbookSearchTerm = useAppSelector(getPlaybookTemplateSimpleSearchTerm);
    const searchPlaybooks = useCallback((simpleSearchTerm: string) => dispatch(updatePlaybookTemplateSimpleSearch(simpleSearchTerm)), [dispatch]);
    const resetSearchTerm = useCallback(() => dispatch(resetPlaybookTemplateSearch()), [dispatch]);

    return (
        <div className={styles.playbooksWrapper}>
            <SimpleSearch
                searchTerm={playbookSearchTerm}
                updateSearchTerm={searchPlaybooks}
                resetSimpleSearch={resetSearchTerm}
                testId='playbooks-templates'
                searchDisabled={noPlaybooks}
            />
            <div className={styles.bookCaseWrapper} style={{ width: '90%', margin: '0 auto' }}>
                <div className={styles.bookCaseSideWrapper}>
                    <div className={styles.bookCaseSide}></div>
                </div>
                <Scrollable>
                    <div className={styles.bookCase}>
                        <div className={styles.shelfWrapper}>
                            <div className={styles.shelf}></div>
                        </div>
                        <PlaybookShelf playbooks={topShelf} isTopShelf openPlaybookCoverModal={openPlaybookCoverModal} templateShelf />
                        <div className={styles.shelfWrapper}>
                            <div className={styles.shelf}></div>
                        </div>
                        <PlaybookShelf playbooks={bottomShelf} openPlaybookCoverModal={openPlaybookCoverModal} templateShelf />
                        <div className={styles.shelfWrapper}>
                            <div className={styles.shelf}></div>
                        </div>
                    </div>
                </Scrollable>
                <div className={styles.bookCaseSideWrapper}>
                    <div className={styles.bookCaseSide}></div>
                </div>
                <BookCoverModal
                    isOpen={isOpen}
                    playbookSelected={playbookSelected}
                    closeModal={closeModal}
                    openPlaybook={openPlaybook}
                />
            </div>
        </div>
    );
};
