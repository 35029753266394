import React, { useCallback, useMemo, useState } from 'react';
import { isEqual, isNull } from 'lodash/fp';
import Modal from 'react-modal';
import classnames from 'classnames';

import { Button } from '../../shared/button/Button';
import styles from './AIManager.module.scss';
import { AIQueryRelevantDocumentNames, DocumentQuery } from './store';
import { CustomTooltip, OverflowTooltip } from '../../shared/tooltip';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Pencil, Tick } from '../../shared/icons';
import { DragDrop } from '../../shared/drag-n-drop/DragDrop';
import { DraggableItem } from '../../shared/drag-n-drop/shared';
import { UnsavedChangesModal } from '../../shared/modal/UnsavedChangesModal';
import { Icon } from '../../shared/icon/Icon';

const { gold, green, amethyst, french, red, ocean, white } = styles;

interface DocumentNamesKeys {
    [documentNameId: number]: { documentName: string; colour: string; }
}

const colours = [french, red, gold, ocean, amethyst, green];
interface AIQueryOrderModalProps {
    isOpen: boolean;
    closeModal: () => void;
    savedQueryOrder: DocumentQuery[];
    currentQueryOrder: DocumentQuery[];
    saveOrder: () => void;
    setOrder: (order: DocumentQuery[]) => void;
    relevantDocumentNames: AIQueryRelevantDocumentNames[];
}

export const AIQueryOrderModal: React.FC<AIQueryOrderModalProps> = ({ isOpen, closeModal, savedQueryOrder, currentQueryOrder, saveOrder, setOrder, relevantDocumentNames }) => {
    const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState<boolean>(false);
    const [selectedDocumentKey, setSelectedDocumentKey] = useState<number | null>(null);

    const documentNameColouredKeys: DocumentNamesKeys = useMemo(() => relevantDocumentNames.reduce((acc: DocumentNamesKeys, cur, index) => ({ ...acc, [cur.documentNameId!]: { documentName: cur.documentName, colour: colours[index] } }), {}), [relevantDocumentNames]);

    const orderUpdated = useMemo(() => !isEqual(savedQueryOrder, currentQueryOrder), [savedQueryOrder, currentQueryOrder]);
    const close = useCallback(() => orderUpdated ? setUnsavedChangesModalOpen(true) : closeModal(), [orderUpdated, setUnsavedChangesModalOpen, closeModal]);

    const save = useCallback(() => {
        saveOrder();
        closeModal();
    }, [saveOrder, closeModal]);

    const confirmAndClose = useCallback(() => {
        closeModal();
        setUnsavedChangesModalOpen(false);
        setOrder(savedQueryOrder);
    }, [closeModal, setOrder, savedQueryOrder]);

    const listOptions = useMemo(() => currentQueryOrder.map(({ documentQueryId, question }) => ({ id: documentQueryId?.toString() || '', label: question })), [currentQueryOrder]);

    const updateList = (list: DraggableItem[]) => {
        const newOrder = list.map(({ id }) => currentQueryOrder.find(tile => tile.documentQueryId!.toString() === id)!);
        setOrder(newOrder);
    };

    const getChildElement = useCallback((childId: string, index: number) => {
        const query = currentQueryOrder.find(({ documentQueryId }) => documentQueryId!.toString() === childId);
        const includedQuery = (documentNameIds: number[]) => !isNull(selectedDocumentKey) && documentNameIds.includes(selectedDocumentKey);
        const tickColour = (documentNameId: number) => documentNameId === selectedDocumentKey ? white : documentNameColouredKeys[documentNameId].colour;
        const boxShadow = (documentNameId: number) => documentNameId === selectedDocumentKey ? `0px 0px 4px ${documentNameColouredKeys[documentNameId].colour}` : 'none';
        if (query) {
            return (
                <div className={classnames(styles.queryTile, { [styles.excludedQueryTile]: !includedQuery(query.documentNameIds) && !isNull(selectedDocumentKey) })}>
                    <div className={styles.questionIndex}>{index + 1}</div>
                    <div className={styles.infoWrapper}>
                        <OverflowTooltip className={styles.query} overlayText={query.question} />
                        <div className={styles.queryIdWrapper}>
                            <OverflowTooltip className={styles.queryId} overlayText={`Key: ${query.queryKey}`} />
                            <OverflowTooltip className={styles.queryId} overlayText={`SystemId: ${query.systemId}`} />
                            <div className={styles.documentNameIds}>
                                {query.documentNameIds.map(documentNameId => (
                                    <CustomTooltip overlayText={documentNameColouredKeys[documentNameId].documentName} placement='top' key={documentNameId}>
                                        <div className={styles.relevantDocumentName} style={{ backgroundColor: documentNameColouredKeys[documentNameId].colour, boxShadow: boxShadow(documentNameId) }}>
                                            <Icon icon={Tick} color={tickColour(documentNameId)} fontSize={15} />
                                        </div>
                                    </CustomTooltip>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }, [currentQueryOrder, documentNameColouredKeys, selectedDocumentKey]);

    return (
        <>
            <Modal
                isOpen={isOpen}
                className={styles.reorderModal}
                ariaHideApp={false}
                style={{ overlay: { display: 'flex', zIndex: 9 } }}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
                onRequestClose={closeModal}
            >
                <div className={styles.reorderWrapper}>
                    <ModalHeader label='Reorder Questions' icon={Pencil} />
                    <div className={styles.scrollableWrapper}>
                        <DragDrop getChildElement={getChildElement} list={listOptions} listId='aiQueries' updateList={updateList} />
                    </div>
                    <div className={styles.buttonsWrapper}>
                        <div className={styles.documentNameKeys}>
                            <div className={styles.documentKeyLabel}>Document Names: </div>
                            <div className={styles.documentKeyWrapper}>
                                {Object.keys(documentNameColouredKeys).map(key => (
                                    <CustomTooltip key={key} overlayText={documentNameColouredKeys[parseInt(key)].documentName} placement='top'>
                                        <div onMouseOver={() => setSelectedDocumentKey(parseInt(key))} onMouseLeave={() => setSelectedDocumentKey(null)} className={styles.documentKey} style={{ backgroundColor: documentNameColouredKeys[parseInt(key)].colour }} />
                                    </CustomTooltip>
                                ))}
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <Button onClick={close} label='Close' />
                            <Button onClick={save} disabled={!orderUpdated} label='Save and Close' />
                        </div>
                    </div>
                </div>
            </Modal>
            <UnsavedChangesModal isOpen={unsavedChangesModalOpen} closeModal={() => setUnsavedChangesModalOpen(false)} confirm={confirmAndClose} />
        </>
    );
};
