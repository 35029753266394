import React, { useCallback, useMemo, useState } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Toggle } from '../../../shared/toggle';
import { getAvailableDocumentNames } from '../../documents/store';
import styles from '../DocumentName.module.scss';

const { white, grey } = styles;

interface DocumentNamesTableProps {
    clientDocumentIds: number[];
    updateDocumentNameIds: (ids: number[]) => void;
}

export const DocumentNamesTable: React.FC<DocumentNamesTableProps> = ({ clientDocumentIds, updateDocumentNameIds }) => {
    const [showDocumentsTable, setShowDocumentsTable] = useState(true);
    const allDocumentNames = useAppSelector(getAvailableDocumentNames);

    const updateDatasetIds = useCallback((checked: boolean, documentNameId: number) => {
        const documentNameIds = checked ? [...clientDocumentIds, documentNameId] : clientDocumentIds.filter(id => id !== documentNameId);
        updateDocumentNameIds(documentNameIds);
    }, [clientDocumentIds, updateDocumentNameIds]);

    const documentOptionHeaders = [{ name: 'Document' }, { name: 'Include in Group?' }];

    const sectionOpenIcon = useMemo(() => showDocumentsTable ? CaretDown : CaretSide, [showDocumentsTable]);

    return (
        <div className={styles.documentOptionsWrapper} data-testid='document-group-ids-wrapper'>
            <div className={styles.documentsHeader} onClick={() => setShowDocumentsTable(!showDocumentsTable)}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={sectionOpenIcon} fontSize={15} />
                </div>
                <div className={styles.documentOptionsTitle}>Available Documents ({clientDocumentIds.length})</div>
            </div>
            {showDocumentsTable &&
                <>
                    <div className={styles.documentOptionsHeader}>
                        {documentOptionHeaders.map(({ name }) => (
                            <div key={name}>{name}</div>
                        ))}
                    </div>
                    <div className={styles.documentOptionsTable}>
                        <Scrollable maxHeight='250px'>
                            {allDocumentNames.map(({ documentNameId, documentName }, index) => (
                                <div className={styles.documentOptionRow} key={index} style={{ backgroundColor: index % 2 ? grey : white }}>
                                    <div className={styles.documentName}>{documentName}</div>
                                    <Toggle
                                        checked={clientDocumentIds.includes(documentNameId!)}
                                        onChange={checked => updateDatasetIds(checked, documentNameId!)}
                                    />
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </>
            }
        </div>
    );
};
