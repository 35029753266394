import { isNull, uniq } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { Document } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Text } from '../../shared/text/Text';
import { fetchAllClientsStarted } from '../clients/store';
import { getAllDatasetDefinitions } from '../dataset-builder/store';
import { ClientDatasetModal } from './ClientDatasetModal';
import styles from './DocumentName.module.scss';
import { DocumentNameDB, getAllAgreementTypes, getAllDocumentNames, getClientDatasetConfirmationModalOpen, toggleClientDatasetConfirmationModal, updateDocumentNameStarted, updateValue } from './store';

interface DocumentNameModalProps {
    isOpen: boolean;
    closeModal: () => void;
    document: DocumentNameDB;
}

export const DocumentNameModal: React.FC<DocumentNameModalProps> = ({ isOpen, closeModal, document }) => {
    const { agreementType, agreementTypeId, documentNameId, documentName, datasetId, datasetTitle, clientIds } = document;
    const modalHeader = documentNameId ? 'Update Document Name' : 'Create a New Document Name';
    const confirmLabel = documentNameId ? 'Update' : 'Create';
    const confirmDisabled = !documentName.length;

    const dispatch = useAppDispatch();
    const datasetDefinitions = useAppSelector(getAllDatasetDefinitions);
    const documentNames = useAppSelector(getAllDocumentNames);
    const agreementTypes = useAppSelector(getAllAgreementTypes);
    const clientDatasetModalOpen = useAppSelector(getClientDatasetConfirmationModalOpen);

    const isNewDataset = useMemo(() => !isNull(datasetId) && !uniq(documentNames.filter(({ datasetId }) => datasetId).map(({ datasetId }) => datasetId)).includes(datasetId), [documentNames, datasetId]);

    const updateDataset = (option: DropdownOption | Options<DropdownOption> | null) => {
        let datasetId = null;
        let datasetTitle = '';
        if (!isNull(option)) {
            datasetId = parseInt((option as DropdownOption).value);
            datasetTitle = (option as DropdownOption).label;
        }
        dispatch(updateValue('datasetId', datasetId));
        dispatch(updateValue('datasetTitle', datasetTitle));
    };

    const updateAgreementType = (option: DropdownOption | Options<DropdownOption> | null) => {
        let agreementTypeId = null;
        let agreementType = null;
        if (!isNull(option)) {
            agreementTypeId = parseInt((option as DropdownOption).value);
            agreementType = (option as DropdownOption).label;
        }
        dispatch(updateValue('agreementTypeId', agreementTypeId));
        dispatch(updateValue('agreementType', agreementType));
    };

    const updateDocumentName = () => dispatch(updateDocumentNameStarted());
    const confirm = () => isNewDataset ? dispatch(toggleClientDatasetConfirmationModal(true)) : updateDocumentName();

    const confirmDisabledTooltip = confirmDisabled ? ['Your document must include a unique document name'] : [];

    const datasetLinked = datasetId ? { value: datasetId.toString(), label: datasetTitle! } : null;
    const agreementTypeLinked = agreementType && agreementTypeId ? { value: agreementTypeId.toString(), label: agreementType } : null;

    const datasetOptions = useMemo(() => datasetDefinitions
        .map(({ datasetId, datasetTitle }) => ({ value: datasetId.toString(), label: datasetTitle }))
        .sort((a, b) => a.label.localeCompare(b.label)), [datasetDefinitions]);

    const agreementTypeOptions = agreementTypes
        .map(({ agreementTypeId, name }) => ({ value: agreementTypeId.toString(), label: name }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const closeClientDatasetModal = () => dispatch(toggleClientDatasetConfirmationModal(false));

    useFetchStarted([fetchAllClientsStarted()]);

    return (
        <>
            <ConfirmationModal
                isOpen={isOpen}
                closeModal={closeModal}
                confirmLabel={confirmLabel}
                confirmDisabled={confirmDisabled}
                testId='document-name'
                confirm={confirm}
                confirmDisabledTooltip={confirmDisabledTooltip}
            >
                <div className={styles.modalWrapper} data-testid='documents-modal-wrapper'>
                    <ModalHeader label={modalHeader} icon={Document} testId='document-name' />
                    <div className={styles.inputsWrapper}>
                        <Text
                            label='Document Name'
                            testId='document-name'
                            placeholder='Document Name...'
                            value={documentName}
                            onChange={e => dispatch(updateValue('documentName', e.target.value))}
                        />
                        <Dropdown
                            disabled={false}
                            value={datasetLinked}
                            options={datasetOptions}
                            testId='document-name-dataset'
                            onChange={updateDataset}
                            label='Dataset'
                        />
                        <div className={styles.modalInputSpacer} />
                        <Dropdown
                            disabled={false}
                            value={agreementTypeLinked}
                            options={agreementTypeOptions}
                            testId='document-name-agreement-type'
                            onChange={updateAgreementType}
                            label='Agreement Type'
                        />
                    </div>
                </div>
            </ConfirmationModal>
            {clientIds &&
                <ClientDatasetModal
                    isOpen={clientDatasetModalOpen}
                    closeModal={closeClientDatasetModal}
                    updateDocumentName={updateDocumentName}
                    selectedClientIds={clientIds}
                />
            }
        </>
    );
};
