import { isNull } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { isUndefined } from 'lodash/fp';

import { useAppSelector } from '../../../hooks/react-redux';
import { formatDate } from '../../../utils/luxon';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { Delete, Link, Tick } from '../../shared/icons';
import { OverflowModalHeader } from '../../shared/modal/OverflowModalHeader';
import { Position, PositionModal } from '../../shared/modal/PositionModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { scopeFormatter } from '../../shared/table/arkTableFormatters';
import { CustomTooltip, IconTooltip } from '../../shared/tooltip';
import { WizardTab } from '../../shared/wizard/WizardTab';
import { LinkedOpinion } from '../my-opinions/store';
import styles from './OpinionInstance.module.scss';
import { Icon } from '../../shared/icon/Icon';
import { commissionedByFormatter } from './store';

interface LinkedOpinionModalProps {
    isOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    linkedOpinions: LinkedOpinion[];
    jurisdiction: string;
    linkToOpinion: (opinion: LinkedOpinion) => void;
    height?: string;
}

enum LinkedOpinionTab {
    BESPOKE = 'bespoke',
    INDUSTRY_STANDARD = 'industry standard'
}

const { green, red } = styles;

export const LinkedOpinionModal: React.FC<LinkedOpinionModalProps> = ({ closeModal, isOpen, position, linkedOpinions, linkToOpinion, jurisdiction, height = '300px' }) => {
    const [selectedTab, setSelectedTab] = useState<LinkedOpinionTab>(LinkedOpinionTab.INDUSTRY_STANDARD);
    const opinions = useMemo(() => linkedOpinions.filter(({ bespoke }) => bespoke === (selectedTab === LinkedOpinionTab.BESPOKE ? 1 : 0)), [linkedOpinions, selectedTab]);
    const totalIndustryStandard = useMemo(() => linkedOpinions.filter(({ bespoke }) => bespoke === 0).length, [linkedOpinions]);
    const totalBespoke = useMemo(() => linkedOpinions.filter(({ bespoke }) => bespoke === 1).length, [linkedOpinions]);

    const hasIndustryStandardPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));

    const getPublishedIndicator = useCallback((bespoke: number, instanceComplete: boolean | undefined) => {
        let indicator = { icon: Tick, color: green, tooltip: 'Latest opinion published' };
        if (bespoke === 0 && !isUndefined(instanceComplete) && !instanceComplete && hasIndustryStandardPermission) {
            indicator = { icon: Delete, color: red, tooltip: 'Latest opinion not published' };
        }
        return (
            <CustomTooltip overlayText={indicator.tooltip}>
                <div className={styles.publishedIndicator}>
                    <Icon icon={indicator.icon} color={indicator.color} fontSize={20} />
                </div>
            </CustomTooltip>
        );
    }, [hasIndustryStandardPermission]);

    return (
        <PositionModal
            isOpen={isOpen}
            position={position}
            height={height}
            width='500px'
            closeModal={closeModal}
            testId='linked-opinion-modal'
            renderLeftOfClick
        >
            <div className={styles.linkedOpinionModalWrapper} data-testid='linked-opinion-modal-wrapper'>
                <OverflowModalHeader label={`Linked Opinions (${jurisdiction})`} icon={Link} />
                <div className={styles.linkedOpinionTabs}>
                    <WizardTab label={`Industry Standard (${totalIndustryStandard})`} onClick={() => setSelectedTab(LinkedOpinionTab.INDUSTRY_STANDARD)} selected={selectedTab === LinkedOpinionTab.INDUSTRY_STANDARD} testId='linked-opinion-tab-industry-standard' />
                    <WizardTab label={`Bespoke (${totalBespoke})`} onClick={() => setSelectedTab(LinkedOpinionTab.BESPOKE)} selected={selectedTab === LinkedOpinionTab.BESPOKE} testId='linked-opinion-tab-bespoke' />
                </div>
                <div className={styles.linkedOpinionsWrapper}>
                    <Scrollable>
                        {opinions.map(linkedOpinion => {
                            const { agreementType, commissionedBy, commissionedByIfOther, opinionId, scope, focus, approvedBy, approvalDate, instanceComplete, bespoke } = linkedOpinion;
                            const signedOffTooltipContent = !isNull(approvalDate) ? `Signed off: ${formatDate(approvalDate)}` : '';
                            return (
                                <div className={styles.linkedOpinion} key={opinionId} onClick={() => linkToOpinion(linkedOpinion)}>
                                    <div className={styles.linkedOpinionScopeWrapper}>
                                        <div className={styles.scope}>{scopeFormatter(scope)}</div>
                                        <div className={styles.iconWrapper}>
                                            {!isNull(approvedBy) && <IconTooltip icon={Tick} content={signedOffTooltipContent} fontSize={20} iconColor={green} />}
                                            {hasIndustryStandardPermission && getPublishedIndicator(bespoke, instanceComplete)}
                                        </div>
                                    </div>
                                    <div className={styles.focus}>{focus}</div>
                                    <div className={styles.agreementTypeAndCommissionedBy}>
                                        <div className={styles.agreementType}>{agreementType}</div>
                                        <div className={styles.commissionedBy}>Commissioned By: {commissionedByFormatter(commissionedBy, commissionedByIfOther, scope, bespoke)}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </Scrollable>
                </div>
            </div>
        </PositionModal>
    );
};
