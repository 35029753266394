import { CustomFilters, UserDefaults } from '../../auth/login/store';
import { PieChartAnalyticsData } from '../../shared/analytics/PieChart';
import { TableFilterType } from '../../shared/modal/TableFilterModal';

export enum HomepageAnalyticsActionTypes {
    FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_STARTED = 'FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_STARTED',
    FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_SUCCESSFUL = 'FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_SUCCESSFUL',
    FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_FAILED = 'FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_FAILED',
    FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_STARTED = 'FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_STARTED',
    FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_SUCCESSFUL = 'FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_SUCCESSFUL',
    FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_FAILED = 'FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_FAILED',
    FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_STARTED = 'FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_STARTED',
    FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_SUCCESSFUL = 'FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_SUCCESSFUL',
    FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_FAILED = 'FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_FAILED',
    REDIRECT_ANALYTICS_MY_ATTESTATION = 'REDIRECT_ANALYTICS_MY_ATTESTATION',
    UPDATE_HOME_SCREEN_CONFIG = 'UPDATE_HOME_SCREEN_CONFIG',
    SET_HOME_SCREEN_CONFIG = 'SET_HOME_SCREEN_CONFIG',
    TOGGLE_UNAUTHORISED_MODULE_MODAL_OPEN = 'TOGGLE_UNAUTHORISED_MODULE_MODAL_OPEN',
    SET_UNAUTHORISED_MODULE = 'SET_UNAUTHORISED_MODULE',
    ENQUIRE_MODULE_STARTED = 'ENQUIRE_MODULE_STARTED',
    ENQUIRE_MODULE_SUCCESSFUL = 'ENQUIRE_MODULE_SUCCESSFUL',
    ENQUIRE_MODULE_FAILED = 'ENQUIRE_MODULE_FAILED',
    TOGGLE_USER_NOT_PERMITTED_MODAL_OPEN = 'TOGGLE_USER_NOT_PERMITTED_MODAL_OPEN',
    FETCH_RELEVANT_HOME_SCREEN_ANALYTICS = 'FETCH_RELEVANT_HOME_SCREEN_ANALYTICS',
    ADD_NEW_CUSTOM_FILTER = 'ADD_NEW_CUSTOM_FILTER',
    TOGGLE_ADD_NEW_FILTER_MODAL = 'TOGGLE_ADD_NEW_FILTER_MODAL',
    RESET_ADD_NEW_FILTER_MODAL = 'RESET_ADD_NEW_FILTER_MODAL',
    UPDATE_CUSTOM_FILTERS_STARTED = 'UPDATE_CUSTOM_FILTERS_STARTED',
    UPDATE_CUSTOM_FILTERS_SUCCESSFUL = 'UPDATE_CUSTOM_FILTERS_SUCCESSFUL',
    UPDATE_CUSTOM_FILTERS_FAILED = 'UPDATE_CUSTOM_FILTERS_FAILED',
    SET_USER_CUSTOM_FILTERS = 'SET_USER_CUSTOM_FILTERS',
    ADD_OR_DELETE_USER_CUSTOM_FILTER = 'ADD_OR_DELETE_USER_CUSTOM_FILTER',
    TOGGLE_FILTER_TILE_MODAL = 'TOGGLE_FILTER_TILE_MODAL',
    UPDATE_CUSTOM_FILTER_VALUES = 'UPDATE_CUSTOM_FILTER_VALUES',
    CUSTOM_FILTERS_UPDATED = 'CUSTOM_FILTERS_UPDATED',
    RESET_CUSTOM_FILTERS_SECTION = 'RESET_CUSTOM_FILTERS_SECTIONS',
    SET_MY_PROFILE_SECTION = 'SET_MY_PROFILE_SECTION',
    SET_SELECTED_CUSTOM_FILTER = 'SET_SELECTED_CUSTOM_FILTER',
    SET_USER_DEFAULTS = 'SET_USER_DEFAULTS',
    UPDATE_USER_DEFAULTS = 'UPDATE_USER_DEFAULTS',
    SET_UNSAVED_PROFILE_CHANGES_MODAL_OPEN = 'SET_UNSAVED_PROFILE_CHANGES_MODAL_OPEN',
    RESET_HOME_SCREEN_CONFIG = 'RESET_HOME_SCREEN_CONFIG',
    RESET_USER_DEFAULTS = 'RESET_USER_DEFAULTS'
}

export enum ArkModule {
    DOCUMENT = 'Document',
    OPINION = 'Opinion',
    DORA = 'DORA'
}

export enum HomePageTile {
    ADD_DOCUMENTS = 'add documents',
    MY_DOCUMENTS = 'my documents',
    SEARCH_DOCUMENTS = 'search documents',
    DOCUMENT_SPREAD = 'document spread',
    ENTITY_SPREAD = 'entity spread',
    MY_ATTESTATIONS = 'my attestations',
    MY_OPINIONS = 'my opinions',
    OPINION_ANALYTICS = 'opinion analytics',
    DOCUMENT_ANALYTICS = 'document analytics',
    OPINION_REPORTING = 'opinion reporting',
    DOCUMENT_REPORTING = 'document reporting',
    PLAYBOOKS = 'playbooks',
    CLAUSE_LIBRARY = 'clause library'
}

export enum MyProfileSection {
    GENERAL = 'General',
    NOTIFICATIONS = 'Notifications',
    HOME_SCREEN = 'Home Screen',
    TABLE_FILTERS = 'Table Filters',
    MY_PREFERENCES = 'My Preferences'
}

export enum CustomFiltersView {
    DOCUMENT = 'Document',
    OPINION = 'Opinion'
}

export interface UserAttestationAnalytics {
    name: string;
    userAnswerId: string;
    percentageComplete: number;
    redFlagAnswers: number;
    weighting: number;
}

export interface MyAttestationAnalytics {
    name: string;
    attestationInstanceId: number;
    percentageComplete: number;
    deadline: string | null;
}

export interface StackData {
    [userAnswerId: string]: number | string;
}

export interface StackChartAnalyticsData extends StackData {
    key: string;
    id: number;
}

export interface HomePageTileContent {
    id: HomePageTile;
    tile: JSX.Element | null;
}

export interface HomeScreenConfig {
    [columnId: string]: HomePageTile[];
}

export interface UpdatedCustomFilters {
    key: string;
    value: TableFilterType;
    filterName: string;
}

export interface HomepageState {
    documentSpread: PieChartAnalyticsData[];
    entitySpread: PieChartAnalyticsData[];
    homepageFetchingAnalytics: HomePageTile[];
    analyticsError: string | null;
    myAttestationProgress: MyAttestationAnalytics[];
    homeScreenConfigModalOpen: boolean;
    homeScreenConfig: HomeScreenConfig | null;
    currentHomeScreenConfig: HomeScreenConfig | null;
    unauthorisedModuleModalOpen: boolean;
    unauthorisedModule: ArkModule | null;
    isEnquiring: boolean;
    enquiryError: string | null;
    userNotPermittedModalOpen: boolean;
    newCustomFilter: CustomFilters | null;
    addNewFilterModalOpen: boolean;
    savedUserCustomFilters: CustomFilters[];
    currentUserCustomFilters: CustomFilters[];
    filterTileModalOpen: string | null;
    isSavingCustomFilters: boolean;
    customFiltersUpdated: boolean;
    onCloseConfirmationDialog: boolean;
    myProfileSection: MyProfileSection;
    selectedCustomFilter: CustomFilters | null;
    savedUserDefaults: UserDefaults;
    currentUserDefaults: UserDefaults;
    unsavedProfileChangesModalOpen: false;
}
