import { OpinionScope } from '../../../admin/opinions/store';
import { DatasetType } from '../../../datasets/store';
import { CollateralProviderInstance, CollateralTakerInstance, NettingInstance, OpinionInstanceField, OpinionInstanceType, RepoInstance, RepoStockLendingInstance, StockLendingInstance, createDefaultInstanceContent } from '../../../opinions/instances/store';
import { OpinionReportField } from './types';

const singleFieldSections = [
    { sectionId: 'agreementCoverage', label: 'Agreement Coverage' },
    { sectionId: 'transactionCoverage', label: 'Transaction Coverage' },
    { sectionId: 'annexCoverage', label: 'Annex Coverage' },
    { sectionId: 'additionalProvisions', label: 'Additional Provisions' }
];

const getOpinionReportType = (scope: OpinionScope | null): OpinionInstanceType => {
    if (scope === OpinionScope.COLLATERAL_PROVIDER) {
        return OpinionInstanceType.COLLATERAL_PROVIDER;
    }
    if (scope === OpinionScope.COLLATERAL_TAKER) {
        return OpinionInstanceType.COLLATERAL_TAKER;
    }
    if (scope === OpinionScope.GMRA_NETTING) {
        return OpinionInstanceType.REPO;
    }
    if (scope === OpinionScope.GMSLA_NETTING) {
        return OpinionInstanceType.STOCK_LENDING;
    }
    if (scope === OpinionScope.GMRA_GMSLA_NETTING) {
        return OpinionInstanceType.REPO_STOCK_LENDING;
    }
    return OpinionInstanceType.NETTING;
};

const createOpinionField = (sectionId: string, id: string, label: string): OpinionReportField => ({
    sectionId,
    id,
    label,
    datasetType: DatasetType.FORM
});

export const metaFields = [
    createOpinionField('general', 'jurisdiction', 'Jurisdiction'),
    createOpinionField('general', 'focus', 'Focus'),
    createOpinionField('general', 'dateOfOpinion', 'Date of Opinion'),
    createOpinionField('general', 'type', 'Type'),
    createOpinionField('scope', 'scope', 'Scope'),
    createOpinionField('scope', 'commissionedBy', 'Commissioned By'),
];

export const getAvailableOpinionReportFields = (scope: OpinionScope | null) => {
    const type = getOpinionReportType(scope);
    const content = createDefaultInstanceContent(type);
    const reportFields = Object.entries(content).reduce((acc: OpinionReportField[], [sectionId, fields]) => {
        if (sectionId === 'counterpartyCoverage') {
            return [...acc, createOpinionField(sectionId, 'coveredByOpinion', 'Covered by Opinion?'), createOpinionField(sectionId, 'aetRequired', 'AET Required?')];
        }
        if (sectionId === 'eligibleCollateral') {
            return [
                ...acc,
                createOpinionField(sectionId, 'onShoreIncluded', 'On-shore assets included?'),
                createOpinionField(sectionId, 'offShoreIncluded', 'Off-shore assets included?'),
                createOpinionField(sectionId, 'cash', 'Cash credited to an account'),
                createOpinionField(sectionId, 'debtSecuritiesInJurisdiction', 'Corporate debt securities issued by an entity organised in the jurisdiction under consideration'),
                createOpinionField(sectionId, 'debtSecuritiesOutsideJurisdiction', 'Corporate debt securities issued by an entity organised outside of the jurisdiction under consideration'),
                createOpinionField(sectionId, 'governmentDebtSecuritiesInJurisdiction', 'Government debt securities issued by the government of the jurisdiction under consideration'),
                createOpinionField(sectionId, 'governmentDebtSecuritiesOutsideJurisdiction', 'Government debt securities issued by a government outside of the jurisdiction under consideration'),
                createOpinionField(sectionId, 'debtSecuritiesMultilateral', 'Debt securities issued by multilateral development banks and international organisations'),
                createOpinionField(sectionId, 'equitySecuritiesInJurisdiction', 'Equity securities issued by an entity organised in the jurisdiction under consideration'),
                createOpinionField(sectionId, 'equitySecuritiesOutsideJurisdiction', 'Equity securities issued by an entity organised outside of the jurisdiction under consideration')
            ];
        }
        const singleFieldSectionIds = singleFieldSections.map(({ sectionId }) => sectionId);
        if (singleFieldSectionIds.includes(sectionId)) {
            const label = singleFieldSections.find(singleFieldSection => singleFieldSection.sectionId === sectionId)!.label;
            return [...acc, createOpinionField(sectionId, 'all', label)];
        }
        Object.entries(fields).forEach(([id, field]) => acc = [...acc, createOpinionField(sectionId, id, (field as OpinionInstanceField).label)]);
        return acc;
    }, metaFields);
    return reportFields;
};

export const getOpinionSectionOrder = (scope: OpinionScope | null) => {
    const type = getOpinionReportType(scope);
    switch (type) {
        case OpinionInstanceType.COLLATERAL_PROVIDER:
            return [
                { sectionId: 'general', label: 'General' },
                { sectionId: 'scope', label: 'Scope' },
                { sectionId: 'securityInterest', label: 'Security Interest' },
                { sectionId: 'sftDocuments', label: 'SFT Documents' },
                { sectionId: 'titleTransfer', label: 'Title Transfer' },
                { sectionId: 'protocols', label: 'Protocols' },
                { sectionId: 'independentAmount', label: 'Independent Amount' },
                { sectionId: 'secIMSupplement', label: 'SEC IM Supplement' },
                { sectionId: 'otherIssues', label: 'Other Issues' },
                { sectionId: 'agreementCoverage', label: 'Agreement Coverage' },
                { sectionId: 'transactionCoverage', label: 'Transaction Coverage' },
                { sectionId: 'counterpartyCoverage', label: 'Counterparty Coverage' },
                { sectionId: 'eligibleCollateral', label: 'Eligible Collateral' },
                { sectionId: 'additionalProvisions', label: 'Additional Provisions' }
            ];
        case OpinionInstanceType.COLLATERAL_TAKER:
            return [
                { sectionId: 'general', label: 'General' },
                { sectionId: 'scope', label: 'Scope' },
                { sectionId: 'collateralTakerInsolvency', label: 'Collateral Taker Insolvency' },
                { sectionId: 'sftDocuments', label: 'SFT Documents' },
                { sectionId: 'otherIssues', label: 'Other Issues' },
                { sectionId: 'agreementCoverage', label: 'Agreement Coverage' },
                { sectionId: 'transactionCoverage', label: 'Transaction Coverage' },
                { sectionId: 'counterpartyCoverage', label: 'Counterparty Coverage' },
                { sectionId: 'eligibleCollateral', label: 'Eligible Collateral' },
                { sectionId: 'additionalProvisions', label: 'Additional Provisions' }
            ];
        case OpinionInstanceType.REPO:
        case OpinionInstanceType.STOCK_LENDING:
        case OpinionInstanceType.REPO_STOCK_LENDING:
            return [
                { sectionId: 'general', label: 'General' },
                { sectionId: 'scope', label: 'Scope' },
                { sectionId: 'assumptions', label: 'Assumptions' },
                { sectionId: 'earlyCloseOut', label: 'Early Close Out' },
                { sectionId: 'legalIssues', label: 'Legal Issues' },
                { sectionId: 'marginArrangements', label: 'Margin Arrangements' },
                { sectionId: 'otherIssues', label: 'Other Issues' },
                { sectionId: 'agreementCoverage', label: 'Agreement Coverage' },
                { sectionId: 'transactionCoverage', label: 'Transaction Coverage' },
                { sectionId: 'counterpartyCoverage', label: 'Counterparty Coverage' },
                { sectionId: 'annexCoverage', label: 'Annex Coverage' },
                { sectionId: 'additionalProvisions', label: 'Additional Provisions' }
            ];
        case OpinionInstanceType.NETTING:
        default:
            return [
                { sectionId: 'general', label: 'General' },
                { sectionId: 'scope', label: 'Scope' },
                { sectionId: 'assumptions', label: 'Assumptions' },
                { sectionId: 'earlyTermination', label: 'Early Termination and Close-Out Netting' },
                { sectionId: 'legalIssues', label: 'Legal Issues' },
                { sectionId: 'sftTransactions', label: 'SFT Transactions' },
                { sectionId: 'otherIssues', label: 'Other Issues' },
                { sectionId: 'agreementCoverage', label: 'Agreement Coverage' },
                { sectionId: 'transactionCoverage', label: 'Transaction Coverage' },
                { sectionId: 'counterpartyCoverage', label: 'Counterparty Coverage' },
                { sectionId: 'additionalProvisions', label: 'Additional Provisions' }
            ];
    }
};

const collateralProviderFieldsBySection = {
    general: ['jurisdiction', 'focus', 'dateOfOpinion', 'type', 'provider', 'reviewer', 'dateOfReview'],
    scope: ['scope', 'commissionedBy'],
    securityInterest: [
        'governingLawRecognised',
        'jurisdictionOfCourtsRecognised',
        'validityRecognised',
        'validityBodyOfLaw',
        'perfectionGoverningLaw',
        'collateralTakerPerfected',
        'actionsToPerfect',
        'eligibleCollateralCreated',
        'affectedByFluctuation',
        'futureObligations',
        'futureCollateral',
        'fluctuatingAssets',
        'fixedAmount',
        'holdExcessCollateral',
        'careOfCollateral',
        'useRehypothecatePostedCollateral',
        'consentToSubstitution',
        'substituteAdverseImpact',
        'enforceRightsFormalities',
        'enforceLimitations',
        'enforceInDefault',
        'insolvencyCompetingPriorities',
        'rightsAffectedByCommencementOfInsolvency',
        'clawbackDuringSuspectPeriod',
        'imJapaneseAmendmentsAffectConclusion',
        'euroclearPledgeeAffectConclusion',
        'euroclearProprietaryRights',
        'cashLeakageConclusion'
    ],
    sftDocuments: [
        'loanTransactions',
        'koreanPledge',
        'transferOfTransferredSecurities',
        'titleTransferAffected'
    ],
    titleTransfer: [
        'governingLawRecognised',
        'unconditionalTransferOfOwnership',
        'recharacterisedSecurityInterest',
        'actionsContinuedTitle',
        'otherToEnsureValidity',
        'transferorRightToExchange',
        'transfereeConsent',
        'paragraphSix',
        'transfereeRightsInsolvency',
        'clawbackDuringSuspectPeriod'
    ],
    protocols: ['closeOutAmountProtocol', 'negativeInterestProtocol'],
    independentAmount: ['independentAmountProvisions'],
    secIMSupplement: ['secIMChanges'],
    otherIssues: ['otherKeyIssues'],
    agreementCoverage: ['all'],
    transactionCoverage: ['all'],
    counterpartyCoverage: ['coveredByOpinion', 'aetRequired'],
    eligibleCollateral: [
        'onShoreIncluded',
        'offShoreIncluded',
        'cash',
        'debtSecuritiesInJurisdiction',
        'debtSecuritiesOutsideJurisdiction',
        'governmentDebtSecuritiesInJurisdiction',
        'governmentDebtSecuritiesOutsideJurisdiction',
        'debtSecuritiesMultilateral',
        'equitySecuritiesInJurisdiction',
        'equitySecuritiesOutsideJurisdiction',
    ],
    additionalProvisions: ['all']
};

const collateralTakerFieldsBySection = {
    general: ['jurisdiction', 'focus', 'dateOfOpinion', 'type', 'provider', 'reviewer', 'dateOfReview'],
    scope: ['scope', 'commissionedBy'],
    collateralTakerInsolvency: [
        'providerRightsEnforceable',
        'providerRequirements',
        'allOutstandingSatisfied',
        'subjectToStayOrFreeze',
        'cashLeakageConclusion'
    ],
    sftDocuments: [
        'loanTransactions',
        'koreanPledge'
    ],
    otherIssues: ['otherKeyIssues'],
    agreementCoverage: ['all'],
    transactionCoverage: ['all'],
    counterpartyCoverage: ['coveredByOpinion', 'aetRequired'],
    eligibleCollateral: [
        'onShoreIncluded',
        'offShoreIncluded',
        'cash',
        'debtSecuritiesInJurisdiction',
        'debtSecuritiesOutsideJurisdiction',
        'governmentDebtSecuritiesInJurisdiction',
        'governmentDebtSecuritiesOutsideJurisdiction',
        'debtSecuritiesMultilateral',
        'equitySecuritiesInJurisdiction',
        'equitySecuritiesOutsideJurisdiction',
    ],
    additionalProvisions: ['all']
};

const repoStockLending = {
    general: ['jurisdiction', 'focus', 'dateOfOpinion', 'type', 'provider', 'reviewer', 'dateOfReview'],
    scope: ['scope', 'commissionedBy'],
    assumptions: ['governingLaw', 'governingLawIfOther', 'bothPartiesSingleBranch', 'multiBranchStatus'],
    earlyCloseOut: [
        'aetEnforceable',
        'aetRequired',
        'aetAdvisable',
        'optionalAetEnforceable',
        'closeOutNettingEnforceable',
        'securitiesHeldOutsideJurisdiction',
        'crossProductCrossAgreementImpact'
    ],
    legalIssues: ['governingLawRecognised', 'judgmentOfCourtsEnforced', 'onShoreInsolvency', 'offShoreInsolvency'],
    marginArrangements: [
        'recharacterisedSecurityInterest',
        'actionsContinuedTitle',
        'otherToEnsureValidity',
        'transferorRightToExchange',
        'alternativeMarginMethods',
        'transfereeConsent',
        'transfereeRightsInsolvency',
        'clawbackDuringSuspectPeriod'
    ],
    otherIssues: ['otherKeyIssues'],
    agreementCoverage: ['all'],
    transactionCoverage: ['all'],
    counterpartyCoverage: ['coveredByOpinion', 'aetRequired'],
    annexCoverage: ['all'],
    additionalProvisions: ['all']
};

const nettingFieldsBySection = {
    general: ['jurisdiction', 'focus', 'dateOfOpinion', 'type', 'provider', 'reviewer', 'dateOfReview'],
    scope: ['scope', 'commissionedBy'],
    assumptions: ['governingLaw', 'governingLawIfOther', 'bothPartiesSingleBranch', 'multiBranchStatus'],
    earlyTermination: [
        'optionalEarlyTermination',
        'closeOutNetting',
        'closeOutNettingNotCovered',
        'aetEnforceable',
        'aetRequired',
        'aetAdvisable',
        'bridgesOne',
        'bridgesTwo',
        'closeOutProtocol',
        'sectionTwoAThree',
        'paymentNettingTwoC',
        'nonInsolvency',
        'insolvency',
        'closeOutLocalCurrency'
    ],
    legalIssues: [
        'nettingLegislation',
        'governingLawRecognised',
        'judgmentOfCourtsEnforced',
        'arbitralAwardEnforceable',
        'onShoreInsolvency',
        'offShoreInsolvency',
        'absenceOfInsolvency',
        'capacityCounterpartyIssues',
        'enforceabilityTransactionIssues'
    ],
    sftTransactions: [
        'sftScheduleProvisions',
        'accelerationNonDefault',
        'automaticAcceleration',
        'bankruptcy'
    ],
    otherIssues: ['internationalOther', 'otherKeyIssues'],
    agreementCoverage: ['all'],
    transactionCoverage: ['all'],
    counterpartyCoverage: ['coveredByOpinion', 'aetRequired'],
    additionalProvisions: ['all']
};

const getCollateralProviderFieldOrderForSection = (sectionId: keyof CollateralProviderInstance) => collateralProviderFieldsBySection[sectionId];
const getCollateralTakerFieldOrderForSection = (sectionId: keyof CollateralTakerInstance) => collateralTakerFieldsBySection[sectionId];
const getRepoFieldOrderForSection = (sectionId: keyof RepoInstance) => repoStockLending[sectionId];
const getStockLendingFieldOrderForSection = (sectionId: keyof StockLendingInstance) => repoStockLending[sectionId];
const getNettingFieldOrderForSection = (sectionId: keyof NettingInstance) => nettingFieldsBySection[sectionId];

export const getOpinionSectionFieldOrder = (scope: OpinionScope | null, sectionId: string) => {
    const type = getOpinionReportType(scope);
    switch (type) {
        case OpinionInstanceType.COLLATERAL_PROVIDER:
            return getCollateralProviderFieldOrderForSection(sectionId as keyof CollateralProviderInstance);
        case OpinionInstanceType.COLLATERAL_TAKER:
            return getCollateralTakerFieldOrderForSection(sectionId as keyof CollateralTakerInstance);
        case OpinionInstanceType.REPO:
            return getRepoFieldOrderForSection(sectionId as keyof RepoInstance);
        case OpinionInstanceType.STOCK_LENDING:
            return getStockLendingFieldOrderForSection(sectionId as keyof StockLendingInstance);
        case OpinionInstanceType.REPO_STOCK_LENDING:
            return getRepoFieldOrderForSection(sectionId as keyof RepoStockLendingInstance);
        case OpinionInstanceType.NETTING:
        default:
            return getNettingFieldOrderForSection(sectionId as keyof NettingInstance);
    }
};
