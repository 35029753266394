import { HiddenFields } from '../components/admin/my-datasets/store';
import { MyRiskTolerance, RiskTolerance } from '../components/admin/risk-tolerance/store';
import { api } from './services';

type DatasetDefinitionRequest = { datasetId: number; };
type UpsertHiddenFieldsRequest = { hiddenFields: HiddenFields; };

export const getAllMyDatasetsByParentId = async () => await api('dataset/myDatasets/get', {}, 'get');
export const getDatasetDefinitionsByParentId = async (reqBody: DatasetDefinitionRequest) => await api('dataset/myDatasets/getDefinitionsByParentId', { ...reqBody }, 'post');
export const upsertHiddenFields = async (reqBody: UpsertHiddenFieldsRequest) => await api('dataset/myDatasets/upsert', { ...reqBody }, 'post');
export const upsertMyRiskTolerance = async (reqBody: { riskTolerance: (RiskTolerance | MyRiskTolerance)[]; datasetId: number; }) => await api('dataset/myDatasets/riskTolerance/upsert', { ...reqBody }, 'post');

export const getAllRiskTolerance = async () => await api('dataset/riskTolerance/getAll', {}, 'get');
export const getRiskToleranceByParentId = async (reqBody: { datasetId: number; }) => await api('dataset/riskTolerance/getByParentId', { ...reqBody }, 'post');
export const upsertRiskTolerance = async (reqBody: { riskTolerance: RiskTolerance[]; datasetId: number; }) => await api('dataset/riskTolerance/upsert', { ...reqBody }, 'post');
