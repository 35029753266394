import React, { useCallback } from 'react';

import { ConfirmationModal } from './ConfirmationModal';
import { Document } from '../icons';
import { ModalHeader } from './ModalHeader';
import { ArkOpinion } from '../../opinions/my-opinions/store';
import { ArkDocument } from '../../documents/my-documents/store';
import { ArkTable, ArkTableColumn, ColumnSort } from '../table/ArkTable';
import { TableFilterType, TableFilters } from './TableFilterModal';

interface LinkDocumentTableModalProps {
    isOpen: boolean;
    documents: ArkDocument[] | ArkOpinion[];
    columnDefs: ArkTableColumn[];
    select: (selectedId: number) => void;
    close: () => void;
    selectedIds: number[] | null;
    selectedField: string;
    testId: string;
    title: string;
    isLoading?: boolean;
    page: number;
    total: number;
    next: () => void;
    previous: () => void;
    filters: TableFilters;
    updateFilter: (field: string, value: string | string[] | null, type?: keyof TableFilterType) => void;
    clearAllFilters: () => void;
    columnSort?: ColumnSort;
    toggleSort?: (columnSort: ColumnSort) => void;
    pageSize: number;
    updatePageSize: (pageSize: number) => void;
    selectDisabled?: (documentId: number) => boolean;
}

export const LinkDocumentTableModal: React.FC<LinkDocumentTableModalProps> = ({
    isOpen,
    close,
    documents,
    select,
    selectedIds,
    selectedField,
    title,
    testId,
    columnDefs,
    isLoading,
    page,
    total,
    next,
    previous,
    filters,
    updateFilter,
    clearAllFilters,
    columnSort,
    toggleSort,
    pageSize,
    updatePageSize,
    selectDisabled = () => false
}) => {

    const selectId = useCallback((selectedId: number | string) => select(selectedId as number), [select]);

    const colDefs = columnDefs.concat([
        { id: 'selectCell', header: '', field: selectedField, width: 0.05, component: 'select', select: selectId, selectedIds: selectedIds, selectDisabled }
    ]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={close}
            closeOnOverlayClick
            showConfirm={false}
            closeLabel={selectedIds && selectedIds.length ? 'Close' : 'Cancel'}
        >
            <ModalHeader
                label={title}
                icon={Document}
                testId={testId}
            />
            <ArkTable
                colDefs={colDefs}
                rowData={documents}
                testId={testId}
                page={page}
                total={total}
                next={next}
                previous={previous}
                clearAllFilters={clearAllFilters}
                filters={filters}
                updateFilter={updateFilter}
                isLoading={isLoading}
                columnSort={columnSort}
                toggleSort={toggleSort}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
            />
        </ConfirmationModal>
    );
};
