import React, { useMemo } from 'react';

import { useWindowResize } from '../../../../hooks/useWindowResize';
import { LinkedPlaybookProvision } from '../../../admin/playbook/store';
import { Position, PositionModal } from '../../modal/PositionModal';
import { Scrollable } from '../../scrollable/Scrollable';
import { OverflowTooltip } from '../../tooltip';
import styles from './ProvisionLink.module.scss';

const { grey } = styles;

interface LinkedProvisionModalProps {
    isOpen: boolean;
    closeModal: () => void;
    provisionLinkHistory: LinkedPlaybookProvision[];
    linkToProvision: (index: number | null) => void;
}

export const LinkedProvisionHistoryModal: React.FC<LinkedProvisionModalProps> = ({ closeModal, isOpen, provisionLinkHistory, linkToProvision }) => {
    const [, screenHeight] = useWindowResize();
    const maxScrollableHeight = screenHeight - 70;
    const modalHeight = provisionLinkHistory.length * 53 >= maxScrollableHeight ? maxScrollableHeight : provisionLinkHistory.length * 53;
    const centredPosition: Position = { x: 5, y: screenHeight - (modalHeight + 55) };
    const provisionLinkBorder = useMemo(() => provisionLinkHistory.length > 1 ? `1px solid ${grey}` : 'none', [provisionLinkHistory]);

    return (
        <PositionModal
            isOpen={isOpen}
            position={centredPosition}
            height='fit-content'
            width='300px'
            closeModal={closeModal}
            testId='linked-provision-history'
            overflow='hidden'
            showOverlay
        >
            <div className={styles.linkedProvisionModalWrapper} data-testid='linked-playbook-provision-modal-wrapper'>
                <Scrollable maxHeight={`${maxScrollableHeight}px`}>
                    <div className={styles.linkedProvisionWrapper} style={{ borderBottom: `1px solid ${grey}` }} onClick={() => linkToProvision(null)}>
                        <div className={styles.linkedProvisionTitleWrapper}>
                            <OverflowTooltip overlayText='My Playbooks' className={styles.linkedPlaybookTitle} />
                        </div>
                    </div>
                    {provisionLinkHistory.map(({ playbookId, playbookName, provisionTitle }, index) => (
                        <div key={playbookId} className={styles.linkedProvisionWrapper} style={{ borderBottom: provisionLinkBorder }} onClick={() => linkToProvision(index)}>
                            <div className={styles.linkedProvisionTitleWrapper}>
                                <OverflowTooltip overlayText={playbookName} className={styles.linkedPlaybookTitle} />
                                <Scrollable maxHeight='38px'>
                                    <div className={styles.linkedProvisionTitle}>{provisionTitle}</div>
                                </Scrollable>
                            </div>
                        </div>
                    ))}
                </Scrollable>
            </div>
        </PositionModal>
    );
};
