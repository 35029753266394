import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasOneFeaturePermission } from '../../../auth/login/store';
import { UploadModal } from '../../../shared/modal/UploadModal';
import { getFilesToUpload, getIsUploading, getModalOpen, setUploadOpinions, toggleOpinionsModal, uploadOpinionStarted } from '../store';

export const UploadOpinionsModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const setOpinionsForUpload = (files: File[]) => dispatch(setUploadOpinions(files));
    const isOpen = useAppSelector(getModalOpen);
    const closeModal = () => dispatch(toggleOpinionsModal(false));
    const isUploading = useAppSelector(getIsUploading);
    const files = useAppSelector(getFilesToUpload);
    const uploadOpinion = () => dispatch(uploadOpinionStarted());
    const hasUploadOpinionPermission = useAppSelector(getUserHasOneFeaturePermission([FeaturePermission.UPLOAD_OPINIONS, FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));

    return (
        <UploadModal
            isOpen={isOpen}
            setUploadDocuments={setOpinionsForUpload}
            modalLabel='Upload a new opinion(s)'
            testId='upload-opinion-modal'
            closeModal={closeModal}
            isUploading={isUploading}
            files={files}
            uploadDocument={uploadOpinion}
            canUpload={hasUploadOpinionPermission}
        />
    );
};
