import React, { useCallback, useState } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Filter, Sort, SortAscending, SortDescending } from '../../../shared/icons';
import styles from '../ClauseTagsModal.module.scss';
import { ClauseTags, CurrentClientTag, getSelectedClientTag, setSelectedClientTag, getClientTagColumnSort, ClientTagTableColumns, toggleTagColumnSort, getClientTagFilters, setClientTagFilters, getFilteredClientTags } from '../../store';
import { tagCategoryOptions, getTagTypeLabel, getTagBackgroundColour } from '../../../shared/clause-library/ClauseModalTags';
import { IconButton } from '../../../shared/button/IconButton';
import { OverflowTooltip } from '../../../shared/tooltip';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { SortOrder } from '../../../shared/table/ArkTable';
import { FilterDropdownOptions, TableFilterModal, TableFilterType } from '../../../shared/modal/TableFilterModal';
import { Position } from '../../../shared/modal/PositionModal';

const { white, lightestGrey, agreementBackgroundSelected, jurisdictionBackgroundSelected, counterpartyBackgroundSelected, productBackgroundSelected, provisionBackgroundSelected, miscellaneousBackgroundSelected, opinionsBackgroundSelected } = styles;

export const getUnselectedTagBackgroundColour = (value: keyof ClauseTags) => {
    switch (value) {
        case 'agreementType':
            return agreementBackgroundSelected;
        case 'jurisdiction':
            return jurisdictionBackgroundSelected;
        case 'counterpartyType':
            return counterpartyBackgroundSelected;
        case 'productType':
            return productBackgroundSelected;
        case 'provisionType':
            return provisionBackgroundSelected;
        case 'opinions':
            return opinionsBackgroundSelected;
        case 'miscellaneous':
        default:
            return miscellaneousBackgroundSelected;
    }
};

export const CurrentClientTags: React.FC = () => {
    const [filterFieldOpen, setFilterFieldOpen] = useState<ClientTagTableColumns | null>(null);
    const [position, setPosition] = useState<Position | null>(null);

    const dispatch = useAppDispatch();
    const selectedTag = useAppSelector(getSelectedClientTag);
    const columnSort = useAppSelector(getClientTagColumnSort);
    const filters = useAppSelector(getClientTagFilters);
    const filteredTags = useAppSelector(getFilteredClientTags);
    const selectTag = useCallback((clientTag: CurrentClientTag) => clientTag.clauseTagId === selectedTag?.clauseTagId ? dispatch(setSelectedClientTag(null)) : dispatch(setSelectedClientTag(clientTag)), [selectedTag, dispatch]);

    const tagBackgroundColor = useCallback((clauseTagId: number, category: keyof ClauseTags) => {
        if (clauseTagId === selectedTag?.clauseTagId) {
            return getTagBackgroundColour(category);
        }
        return getUnselectedTagBackgroundColour(category);
    }, [selectedTag]);

    const sortColumn = useCallback((sortColumn: ClientTagTableColumns) => {
        let orderToSort = SortOrder.ASCENDING;
        if (columnSort) {
            const { column, order } = columnSort;
            orderToSort = sortColumn === column && order === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING;
        }
        dispatch(toggleTagColumnSort({ column: sortColumn, order: orderToSort }));
    }, [columnSort, dispatch]);

    const getSortIcon = useCallback((column: ClientTagTableColumns) => {
        if (columnSort && columnSort.column === column) {
            return columnSort.order === SortOrder.ASCENDING ? SortDescending : SortAscending;
        }
        return Sort;
    }, [columnSort]);

    const openFilterModal = (x: number, y: number, column: ClientTagTableColumns) => {
        setPosition({ x, y });
        setFilterFieldOpen(column);
    };

    const closeFilterModal = () => {
        setPosition(null);
        setFilterFieldOpen(null);
    };

    const columnFilterExists = useCallback((field: string) => !!filters[field] && (!isNull(filters[field].dropdown) || !!filters[field].text), [filters]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setClientTagFilters(key, value, type)), [dispatch]);
    const filterDropdownOptions: FilterDropdownOptions = { category: tagCategoryOptions };

    return (
        <Scrollable>
            <div className={styles.currentTagsWrapper} data-testid='client-tags-modal-current-tags-wrapper'>
                <div className={styles.currentTagsHeaders}>
                    <div className={styles.tagLabelHeader} data-testid='client-tags-modal-table-header-tag-wrapper'>
                        <div className={styles.tagHeaderLabel} data-testid='client-tags-modal-table-header-tag-label'>Tag</div>
                        <div className={styles.tableHeaderIcons}>
                            <IconButton icon={Filter} onClick={e => openFilterModal(e.clientX, e.clientY, ClientTagTableColumns.TAG_LABEL)} color={white} fontSize={20} testId={`client-tags-modal-table-header-${ClientTagTableColumns.TAG_LABEL}-filter`} margin={'0 5px 0 0'} showIndicator={columnFilterExists(ClientTagTableColumns.TAG_LABEL)} />
                            <IconButton icon={getSortIcon(ClientTagTableColumns.TAG_LABEL)} onClick={() => sortColumn(ClientTagTableColumns.TAG_LABEL)} color={white} fontSize={20} testId={`client-tags-modal-table-header-${ClientTagTableColumns.TAG_LABEL}-sort`} />
                        </div>
                    </div>
                    <div className={styles.headerSeparator}/>
                    <div className={styles.tagCategoryHeader} data-testid='client-tags-modal-table-header-category-wrapper'>
                        <div className={styles.tagHeaderLabel} data-testid='client-tags-modal-table-header-category-label'>Category</div>
                        <div className={styles.tableHeaderIcons}>
                            <IconButton icon={Filter} onClick={e => openFilterModal(e.clientX, e.clientY, ClientTagTableColumns.TAG_CATEGORY)} color={white} fontSize={20} testId={`client-tags-modal-table-header-${ClientTagTableColumns.TAG_CATEGORY}-filter`} margin={'0 5px 0 0'} showIndicator={columnFilterExists(ClientTagTableColumns.TAG_CATEGORY)} />
                            <IconButton icon={getSortIcon(ClientTagTableColumns.TAG_CATEGORY)} onClick={() => sortColumn(ClientTagTableColumns.TAG_CATEGORY)} color={white} fontSize={20} testId={`client-tags-modal-table-header-${ClientTagTableColumns.TAG_CATEGORY}-sort`} />
                        </div>
                    </div>
                    <div className={styles.headerSeparator}/>
                    <div className={styles.tagCountHeader} data-testid='client-tags-modal-table-header-usage-wrapper'>
                        <div className={styles.tagHeaderLabel} data-testid='client-tags-modal-table-header-usage-label'>Usage</div>
                        <div className={styles.tableHeaderIcons}>
                            <IconButton icon={getSortIcon(ClientTagTableColumns.TAG_CLAUSE_COUNT)} onClick={() => sortColumn(ClientTagTableColumns.TAG_CLAUSE_COUNT)} color={white} fontSize={20} testId={`client-tags-modal-table-header-${ClientTagTableColumns.TAG_CLAUSE_COUNT}-sort`} />
                        </div>
                    </div>
                </div>
                {filteredTags.map((clientTag, index) => {
                    const { clauseTagId, tag, includedClauses, category } = clientTag;
                    const tagCount = includedClauses.length > 0 ? includedClauses.length : '';
                    return (
                        <div key={clauseTagId} id={clauseTagId.toString()} className={styles.currentTagWrapper} style={{ backgroundColor: index % 2 ? lightestGrey : white }} onClick={() => selectTag(clientTag)} data-testid={`client-tags-modal-table-row-${index}-tag-wrapper`}>
                            <div className={styles.tagLabelWrapper}>
                                <OverflowTooltip className={styles.tagLabel} overlayText={tag} testId={`client-tags-modal-table-row-${index}-tag-label`} />
                            </div>
                            <div className={styles.tagCategoryWrapper} data-testid={`client-tags-modal-table-row-${index}-category-label`}>{getTagTypeLabel(category)}</div>
                            <div className={styles.tagCountWrapper}>
                                <div className={styles.tagCount} style={{ backgroundColor: tagBackgroundColor(clauseTagId, category) }} data-testid={`client-tags-modal-table-row-${index}-usage-label`}>{tagCount}</div>
                            </div>
                        </div>
                    );
                })
                }
            </div>
            {!isNull(filterFieldOpen) && !isNull(position) &&
                <TableFilterModal
                    position={position}
                    field={filterFieldOpen}
                    closeModal={closeFilterModal}
                    testId={`client-tags-modal-modal-filter-${filterFieldOpen}`}
                    filters={filters}
                    updateFilter={updateFilter}
                    filterDropdownOptions={filterDropdownOptions}
                    textFilterVisible={filterFieldOpen === ClientTagTableColumns.TAG_LABEL}
                />
            }
        </Scrollable>
    );
};
