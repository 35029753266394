import { noop } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { CustomTooltip, TooltipList } from '../../../shared/tooltip';
import { WYSIWYG } from '../../../shared/wysiwyg/WYSIWYG';
import { getAllUsers } from '../../users/store';
import { SendEmailContent } from '../store';
import styles from '../Workflow.module.scss';
import gradientLogo from '../../../../assets/logos/Ark51_HORIWHITE&GRAD.png';
import blackAndWhiteLogo from '../../../../assets/logos/Ark51_HORIBLACK&BLACK.png';
import { Scrollable } from '../../../shared/scrollable/Scrollable';

const { white } = styles;
interface ConfirmEmailProps {
    content: SendEmailContent;
    testId: string;
}

export const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ content, testId }) => {

    const { userIds, subject, wysiwygTemplate } = content;

    const allUsers = useAppSelector(getAllUsers);
    const emailUsers = useMemo(() => allUsers.filter(({ userId }) => userIds!.includes(userId)).map(({ username, email }) => ({ username, email })), [allUsers, userIds]);
    const tooltipUsers = useMemo(() => emailUsers.splice(4).map(({ username, email }) => `${username}: ${email}`), [emailUsers]);

    const emailUsername = useCallback((username: string, showComma: boolean) => `${username.length > 25 ? '...' : ''}${showComma ? '' : ','}`, []);

    return (
        <div className={styles.confirmEmailWrapper} data-testid={`${testId}-confirm-email-wrapper`}>
            <div className={styles.emailSubjectWrapper} data-testid={`${testId}-confirm-email-subject-wrapper`}>
                <div className={styles.summaryLabel} data-testid={`${testId}-confirm-email-subject-label`}>Subject:</div>
                <div className={styles.emailSubject} data-testid={`${testId}-confirm-email-subject`}>{subject}</div>
            </div>
            <div className={styles.emailUsersWrapper} data-testid={`${testId}-confirm-email-users-wrapper`}>
                <div className={styles.summaryLabel} data-testid={`${testId}-confirm-email-users-to-label`}>To:</div>
                <div className={styles.emailUsers} data-testid={`${testId}-confirm-email-users`}>
                    <Scrollable>
                        {emailUsers.map(({ username, email }, index) => (
                            <CustomTooltip overlayText={email} key={index}>
                                <div className={styles.emailUsername}>{username.substring(0, 25)}{emailUsername(username, index === userIds!.length - 1)}</div>
                            </CustomTooltip>
                        ))}
                    </Scrollable>
                    {!!tooltipUsers.length &&
                        <TooltipList overlayText={tooltipUsers} placement='right' >
                            <div className={styles.emailUsername}>
                                +{tooltipUsers.length} more
                            </div>
                        </TooltipList>
                    }
                </div>
            </div>
            <div className={styles.emailContentWrapper} data-testid={`${testId}-confirm-email-content-wrapper`}>
                <Scrollable>
                    <div className={styles.emailHeader} data-testid={`${testId}-confirm-email-content-header`}>
                        <img className={styles.emailHeaderImage} src={gradientLogo} alt='Logo' />
                    </div>
                    <div className={styles.emailBody} data-testid={`${testId}-confirm-email-content-body`}>
                        <WYSIWYG
                            content={wysiwygTemplate}
                            updateContent={noop}
                            height='fit-content'
                            disabled
                            showBorder={false}
                            borderColor={white}
                            toolbarHidden
                            maxHeight='fit-content'
                            minHeight='fit-content'
                        />
                    </div>
                    <div data-testid={`${testId}-confirm-email-content-footer`}>
                        <img className={styles.emailFooterIcon} src={blackAndWhiteLogo} alt='Image' />
                    </div>
                </Scrollable>
            </div>
        </div>
    );
};
