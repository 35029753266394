import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../../../../hooks/react-redux';
import { getCurrentInstances, getDatasetInstanceHierarchy, getParentExecutedDate } from '../../../store';
import styles from '../../../../fields/Fields.module.scss';
import { formatDate } from '../../../../../../utils/luxon';
import { MLDatasetInstance } from '../MLDatasetInstance';
import { InstanceMLData } from '../../../../store/mlTypes';

interface MLDatasetFieldProps {
    id: string;
    childDatasetId: string;
    isEditing: boolean;
    isUpdating: boolean;
    fieldOpen: boolean;
    mlData: InstanceMLData;
    modalInstance?: boolean;
    fieldHidden?: boolean;
}

export const MLDatasetField: React.FC<MLDatasetFieldProps> = ({
    id,
    childDatasetId,
    isEditing,
    isUpdating,
    fieldOpen,
    mlData,
    modalInstance,
    fieldHidden
}) => {
    const datasetInstances = useAppSelector(getCurrentInstances);
    const hierarchy = useAppSelector(getDatasetInstanceHierarchy);
    const parentExecutedDate = useAppSelector(getParentExecutedDate);
    const datasetInstance = useMemo(() => datasetInstances.find(({ instance, parentFieldId }) => (instance.datasetId === parseInt(childDatasetId) || (!isNull(instance.annexDefinitionId) && instance.annexDefinitionId === parseInt(childDatasetId))) && parentFieldId === id)?.instance, [datasetInstances, childDatasetId, id]);

    const getParentId = useCallback((id: string) => hierarchy.find(({ datasetId }) => datasetId === id)?.parentId, [hierarchy]);

    const parentDatasetIds = useMemo(() => {
        let parentIds: string[] = [];
        let currentDatasetId = childDatasetId;
        let parentId = getParentId(currentDatasetId);
        while (parentId) {
            parentIds.push(parentId);
            currentDatasetId = parentId;
            parentId = getParentId(currentDatasetId);
        }
        return parentIds;
    }, [getParentId, childDatasetId]);

    const instanceExecutedDateMatchesParent = useMemo(() => datasetInstance && parentExecutedDate && formatDate(datasetInstance.executedDate) === parentExecutedDate || false, [datasetInstance, parentExecutedDate]);

    return (
        <div className={styles.datasetField} data-testid={`dataset-instance-wrapper-${childDatasetId}`}>
            {fieldOpen && datasetInstance && <MLDatasetInstance datasetInstance={datasetInstance} isEditing={isEditing} isUpdating={isUpdating} parentDatasetIds={parentDatasetIds} modalInstance={modalInstance} parentFieldId={id} datasetHidden={fieldHidden} mlData={mlData} instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent} />}
        </div>
    );
};
