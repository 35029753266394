import { isNull } from 'lodash/fp';
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useLongPress } from '../../../hooks/useLongPress';
import { DatasetDefinition } from '../../datasets/store';
import { Button } from '../../shared/button/Button';
import { LongPressButton } from '../../shared/button/LongPressButton';
import { Position } from '../../shared/modal/PositionModal';
import { UnsavedChangesModal } from '../../shared/modal/UnsavedChangesModal';
import styles from './DatasetBuilder.module.scss';
import { DatasetHistoryModal } from './DatasetHistoryModal';
import { DeleteDatasetConfirmationModal } from './DeleteDatasetConfirmationModal';
import sharedStyles from './SharedBuilder.module.scss';
import { LinkDirection, getConfirmDeleteDataset, getDatasetPreviewOpen, getDefinitionHasIncompleteFields, getDefinitionHasUnlabelledFields, getDefinitionHasUpdated, getDefinitionHistoryModalOpen, getLinkUpUnsavedChangesModalOpen, getPreviousDatasetIds, goBackToDatasetDefinition, toggleDefinitionHistoryModal, toggleDeleteDatasetConfirmationModal, toggleUnsavedChangesModal } from './store';

interface DatasetBuilderModalProps {
    isOpen: boolean;
    closeModal: () => void;
    confirm: () => void;
    closeDisabled?: boolean;
    showButtons: boolean
    closeOnOverlayClick?: boolean
    confirmLabel?: string;
    togglePreview: () => void;
    datasetDefinition: DatasetDefinition | null;
    children?: React.ReactNode;
}

export const DatasetBuilderModal: React.FC<DatasetBuilderModalProps> = ({ isOpen, closeModal, confirm, closeDisabled, showButtons = true, closeOnOverlayClick = false, confirmLabel = 'Confirm', children, togglePreview, datasetDefinition }) => {
    const [historyPosition, setHistoryPosition] = useState<Position>({ x: 0, y: 0 });
    const [indexToGoBackTo, setIndexToGoBackTo] = useState<number | undefined>(undefined);
    const dispatch = useAppDispatch();
    const previewOpen = useAppSelector(getDatasetPreviewOpen);
    const confirmDeleteDataset = useAppSelector(getConfirmDeleteDataset);
    const previousDatasetIds = useAppSelector(getPreviousDatasetIds);
    const definitionHistoryModalOpen = useAppSelector(getDefinitionHistoryModalOpen);
    const hasUpdated = useAppSelector(getDefinitionHasUpdated);
    const unsavedChangesModalOpen = useAppSelector(getLinkUpUnsavedChangesModalOpen);
    const incompleteFields = useAppSelector(getDefinitionHasIncompleteFields);
    const unlabelledFields = useAppSelector(getDefinitionHasUnlabelledFields);

    const noDatasetTitle = useMemo(() => !!datasetDefinition && !datasetDefinition.datasetTitle, [datasetDefinition]);
    const publishDisabled = useMemo(() => noDatasetTitle || incompleteFields || unlabelledFields || !hasUpdated, [noDatasetTitle, incompleteFields, unlabelledFields, hasUpdated]);

    const showDelete = !!datasetDefinition && !!datasetDefinition.datasetId && !datasetDefinition.isParent;

    const openDeleteConfirmationModal = useCallback(() => showDelete && dispatch(toggleDeleteDatasetConfirmationModal(datasetDefinition)), [showDelete, datasetDefinition, dispatch]);
    const closeDeleteConfirmationModal = () => dispatch(toggleDeleteDatasetConfirmationModal(null));

    const publishDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (noDatasetTitle) {
            disabledArray.push('Dataset needs a title');
        }
        if (incompleteFields) {
            disabledArray.push('One or more of your fields is unlinked');
        }
        if (unlabelledFields) {
            disabledArray.push('One or more of your fields is unlabelled');
        }
        return disabledArray;
    }, [noDatasetTitle, incompleteFields, unlabelledFields]);

    const toggleUnsavedChangesWarningModal = useCallback((linkDirection: LinkDirection | null) => dispatch(toggleUnsavedChangesModal(linkDirection)), [dispatch]);
    const closeUnsavedChangesModal = useCallback(() => toggleUnsavedChangesWarningModal(null), [toggleUnsavedChangesWarningModal]);
    const closeHistoryModal = useCallback(() => dispatch(toggleDefinitionHistoryModal(false)), [dispatch]);
    const goBackToDefinition = useCallback((index?: number) => dispatch(goBackToDatasetDefinition(index)), [dispatch]);

    const confirmGoBack = useCallback(() => {
        goBackToDefinition(indexToGoBackTo);
        closeUnsavedChangesModal();
    }, [goBackToDefinition, indexToGoBackTo, closeUnsavedChangesModal]);

    const attemptGoBackToDefinition = useCallback((index: number) => {
        if (hasUpdated) {
            toggleUnsavedChangesWarningModal(LinkDirection.UP);
            setIndexToGoBackTo(index);
        } else {
            goBackToDefinition(index);
            closeHistoryModal();
        }
    }, [hasUpdated, toggleUnsavedChangesWarningModal, goBackToDefinition, setIndexToGoBackTo, closeHistoryModal]);

    const onClick = useCallback(() => hasUpdated ? toggleUnsavedChangesWarningModal(LinkDirection.UP) : goBackToDefinition(), [goBackToDefinition, toggleUnsavedChangesWarningModal, hasUpdated]);

    const onLongPress = useCallback((e: MouseEvent<Element, globalThis.MouseEvent>) => {
        setHistoryPosition({ x: e.clientX, y: e.clientY });
        if (previousDatasetIds.length > 0) {
            dispatch(toggleDefinitionHistoryModal(true));
        }
    }, [dispatch, previousDatasetIds]);

    const longPressEvent = useLongPress(onLongPress, onClick);

    const showBack = useMemo(() => previousDatasetIds.length > 0, [previousDatasetIds]);

    useEffect(() => () => {
        setIndexToGoBackTo(undefined);
    }, []);

    return (
        <>
            <Modal
                isOpen={isOpen}
                className={sharedStyles.builderModal}
                ariaHideApp={false}
                style={{ overlay: { display: 'flex' } }}
                shouldCloseOnOverlayClick={closeOnOverlayClick}
                shouldCloseOnEsc={closeOnOverlayClick}
                onRequestClose={closeModal}
            >
                <div
                    className={sharedStyles.builderWrapper}
                    data-testid='dataset-builder-modal-wrapper'
                >
                    {children}
                    {showButtons &&
                        <div className={styles.allButtonsWrapper}>
                            <div className={styles.leftButtonWrapper}>
                                {showBack && <LongPressButton longPressProps={longPressEvent} label='Back' />}
                                {showDelete && <Button onClick={openDeleteConfirmationModal} label='Delete' testId='delete-dataset' />}
                            </div>
                            <div className={sharedStyles.builderButtonWrapper}>
                                <Button onClick={togglePreview} label={!previewOpen ? 'Show Preview' : 'Continue Building'} testId='toggle-preview' />
                                <Button onClick={closeModal} label='Cancel' disabled={closeDisabled} testId='cancel-build' />
                                <Button onClick={confirm} label={confirmLabel} disabled={publishDisabled} testId='publish-build' disabledTooltip={publishDisabledTooltip} />
                            </div>
                        </div>
                    }
                </div>
            </Modal>
            {!isNull(confirmDeleteDataset) &&
                <DeleteDatasetConfirmationModal
                    isOpen
                    closeModal={closeDeleteConfirmationModal}
                    definition={confirmDeleteDataset}
                />
            }
            <DatasetHistoryModal
                isOpen={definitionHistoryModalOpen}
                closeModal={closeHistoryModal}
                goBackToDefinition={attemptGoBackToDefinition}
                position={historyPosition}
            />
            <UnsavedChangesModal
                isOpen={unsavedChangesModalOpen}
                closeModal={closeUnsavedChangesModal}
                confirm={confirmGoBack}
            />
        </>
    );
};
