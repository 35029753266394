import { isNull } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { LoadingDots } from '../../../../shared/loading-dots/LoadingDots';
import { RiskAssociated, riskAssociatedLabel } from '../../../../admin/risk-tolerance/store';
import styles from '../../Analytics.module.scss';

const { red, amber, gold, yellowGreen, green, french } = styles;

interface DocumentRiskToleranceLevelProps {
    percentage: number | null;
    chartIsFullScreen: boolean;
}

export const documentRiskLevelLabel = (riskLevel: number | null, isPercentage = false) => {
    if (isNull(riskLevel)) {
        return { colour: french, label: 'Unknown' };
    }

    const percentage = isPercentage ? riskLevel : riskLevel * 20;

    if (percentage < 20) {
        return { colour: green, label: riskAssociatedLabel[RiskAssociated.HIGHLY_DESIRABLE] };
    }
    if (percentage < 40) {
        return { colour: yellowGreen, label: riskAssociatedLabel[RiskAssociated.DESIRABLE] };
    }
    if (percentage < 60) {
        return { colour: gold, label: riskAssociatedLabel[RiskAssociated.ACCEPTABLE] };
    }
    if (percentage < 80) {
        return { colour: amber, label: riskAssociatedLabel[RiskAssociated.OF_CONCERN] };
    }
    if (percentage <= 100) {
        return { colour: red, label: riskAssociatedLabel[RiskAssociated.OF_SERIOUS_CONCERN] };
    }
    return { colour: french, label: 'Unknown' };
};

export const DocumentRiskToleranceLevel: React.FC<DocumentRiskToleranceLevelProps> = ({ percentage, chartIsFullScreen }) => {
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [activePercentage, setActivePercentage] = useState<number>(0);
    const { colour, label } = documentRiskLevelLabel(percentage, true);

    useEffect(() => {
        if (percentage !== activePercentage) {
            if (!isNull(percentage)) {
                setActivePercentage(percentage);
            }
            setTimeout(() => setShowLoading(false), 2500);
        }
        return () => {
            setShowLoading(true);
        };
    }, [activePercentage, percentage]);

    if (showLoading) {
        return <LoadingDots ragColours />;
    }

    return (
        <div className={classnames(styles.overallRiskLevel, { [styles.overallRiskLevelFullScreen]: !!chartIsFullScreen })} style={{ color: colour }}>{label}</div>
    );
};
