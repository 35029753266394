import { set } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { filterDropdownOptions } from '../../../clause-library/ClauseTable';
import { ArkTableClause, clearClauseLibraryTableFilters, setClauseLibraryTableFilters, clauseLibraryPaginationNext, setClauseLibraryPageSize, clauseLibraryPaginationPrevious, duplicateClause, getAllArkTableClauses, getClauseLibraryDropdownOptions, getClauseLibraryIsLoading, getClausesPageNumber, getTotalClauses, getClauseFilters, getClauseColumnSort, getClausePageSize, openSavedClause, setClauseLibraryTableColumnSort, ClauseTableTab, setClauseTableTab, fetchAllClausesStarted, getClauseTableTab } from '../../../clause-library/store';
import { Button } from '../../../shared/button/Button';
import { Document, Pencil, SaveFile, LinkChain, LongText } from '../../../shared/icons';
import { Action } from '../../../shared/modal/ActionModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../shared/table/ArkTable';
import { TableTabs } from '../../../shared/table/TableTabs';
import { linkOpinionClause, toggleLinkOpinionClauseModal, getAllOpinionClauseIds, fetchAllOpinionClausesStarted } from '../store';
import styles from './OpinionSection.module.scss';

export const clauseColumnDefs = (clauseActions: (data: ArkTableClause) => Action[]): ArkTableColumn[] => [
    { id: 'title', header: 'Title', field: 'clauseTitle', width: 0.13, component: 'tooltip', canFilter: true, canSort: true },
    { id: 'description', header: 'Description', field: 'clauseDescription', width: 0.06, component: 'iconTooltip', icon: LongText, canFilter: true, canSort: false },
    { id: 'agreementType', header: 'Agreement Tags', field: 'agreementTags', width: 0.10, component: 'tooltipListLabel', canFilter: true, canSort: true },
    { id: 'jurisdiction', header: 'Jurisdiction Tags', field: 'jurisdictionTags', width: 0.10, component: 'tooltipListLabel', canFilter: true, canSort: true },
    { id: 'counterpartyType', header: 'Counterparty Tags', field: 'counterpartyTags', width: 0.10, component: 'tooltipListLabel', canFilter: true, canSort: true },
    { id: 'productType', header: 'Product Tags', field: 'productTags', width: 0.10, component: 'tooltipListLabel', canFilter: true, canSort: true },
    { id: 'provisionType', header: 'Provision Tags', field: 'provisionTags', width: 0.10, component: 'tooltipListLabel', canFilter: true, canSort: true },
    { id: 'miscellaneousTags', header: 'Miscellaneous Tags', field: 'miscellaneousTags', width: 0.10, component: 'tooltipListLabel', canFilter: true, canSort: true },
    { id: 'opinionsTags', header: 'Opinions Tags', field: 'opinionsTags', width: 0.10, component: 'tooltipListLabel', canFilter: true, canSort: true },
    { id: 'opinionLinks', header: 'Linked to Opinion', field: 'opinionLinks', width: 0.07, component: 'tick', canFilter: false, canSort: false },
    { id: 'actions', header: '', field: '', width: 0.04, component: 'action', actions: clauseActions }
];

interface OpinionClauseTableProps {
    opinionId: number;
    isIndustryStandard: boolean;
    hasSystemClausePermission: boolean;
}

const PADDING = 20;
const MODAL_HEADER = 56;
const BUTTON_WRAPPER = 48;
const TAB_WRAPPER = 30;
const BESPOKE_HEIGHT_OFFSET = MODAL_HEADER + BUTTON_WRAPPER + PADDING;
const INDUSTRY_STANDARD_HEIGHT_OFFSET = BESPOKE_HEIGHT_OFFSET + TAB_WRAPPER;

export const OpinionClauseTable: React.FC<OpinionClauseTableProps> = ({
    opinionId,
    isIndustryStandard,
    hasSystemClausePermission
}) => {
    const dispatch = useAppDispatch();
    const pageNumber = useAppSelector(getClausesPageNumber);
    const totalClauses = useAppSelector(getTotalClauses);
    const clauseFilters = useAppSelector(getClauseFilters);
    const columnSort = useAppSelector(getClauseColumnSort);
    const pageSize = useAppSelector(getClausePageSize);

    const nextPage = useCallback(() => dispatch(clauseLibraryPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(clauseLibraryPaginationPrevious()), [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setClauseLibraryTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearClauseLibraryTableFilters()), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setClauseLibraryPageSize(pageSize)), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setClauseLibraryTableColumnSort(columnSort)), [dispatch]);

    const allClauses = useAppSelector(getAllArkTableClauses);
    const [screenWidth, screenHeight] = useWindowResize();
    const heightOffset = useMemo(() => isIndustryStandard ? INDUSTRY_STANDARD_HEIGHT_OFFSET : BESPOKE_HEIGHT_OFFSET, [isIndustryStandard]);
    const tableWidth = useMemo(() => (screenWidth * 0.8) - PADDING, [screenWidth]);
    const tableHeight = useMemo(() => (screenHeight * 0.8) - heightOffset, [screenHeight, heightOffset]);
    const isLoading = useAppSelector(getClauseLibraryIsLoading);
    const clauseLibraryDropdownOptions = useAppSelector(getClauseLibraryDropdownOptions);
    const allOpinionClauseIds = useAppSelector(getAllOpinionClauseIds);
    const openClause = useCallback(({ clauseId }: ArkTableClause) => dispatch(openSavedClause(clauseId)), [dispatch]);
    const opinionClauseLinking = useCallback((clauseId: number) => {
        const isAlreadyLinked = allOpinionClauseIds.includes(clauseId);
        dispatch(linkOpinionClause(opinionId, clauseId, isAlreadyLinked));
        dispatch(fetchAllClausesStarted(1));
    }, [dispatch, opinionId, allOpinionClauseIds]);
    const duplicate = useCallback((duplicateClauseId: number, isSave: boolean) => dispatch(duplicateClause(duplicateClauseId, isSave, hasSystemClausePermission)), [dispatch, hasSystemClausePermission]);

    const clauseActions = useCallback((data: ArkTableClause): Action[] => {
        const { clauseId } = data;
        const isAlreadyLinked = allOpinionClauseIds.includes(clauseId);
        const options: Action[] = [
            { label: 'Open', icon: Document, onClick: () => openClause(data) },
            { label: 'Duplicate', withSeparator: true, icon: SaveFile, onClick: () => duplicate(clauseId, false) },
            { label: `${isAlreadyLinked ? 'Remove' : 'Add'} Link`, icon: LinkChain, onClick: () => opinionClauseLinking(clauseId) }
        ];
        return options;
    }, [openClause, duplicate, opinionClauseLinking, allOpinionClauseIds]);

    const allOpinionClauses = allClauses.map(clause => {
        const { clauseId } = clause;
        const isAlreadyLinked = allOpinionClauseIds.includes(clauseId);
        return set('opinionLinks', isAlreadyLinked, clause);
    });

    const closeLinkClausesModal = useCallback(() => {
        dispatch(toggleLinkOpinionClauseModal());
        dispatch(fetchAllOpinionClausesStarted(opinionId, 1));
    }, [dispatch, opinionId]);

    const changeTab = useCallback((tabView: ClauseTableTab) => {
        dispatch(setClauseTableTab(tabView));
        dispatch(fetchAllClausesStarted(pageNumber));
    }, [dispatch, pageNumber]);

    const clauseTableTab = useAppSelector(getClauseTableTab);

    const tableTabs = useMemo(() => [
        { tabToggleAction: () => changeTab(ClauseTableTab.CURRENT_TEMPLATES), tabTitle: 'Current Templates', isSelected: clauseTableTab === ClauseTableTab.CURRENT_TEMPLATES },
        { tabToggleAction: () => changeTab(ClauseTableTab.HISTORIC_TEMPLATES), tabTitle: 'Historic Templates', isSelected: clauseTableTab === ClauseTableTab.HISTORIC_TEMPLATES },
        { tabToggleAction: () => changeTab(ClauseTableTab.MISCELLANEOUS_TEMPLATE), tabTitle: 'Miscellaneous Templates', isSelected: clauseTableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE }
    ], [changeTab, clauseTableTab]);

    return (
        <div className={styles.linkClauseModalWrapper} data-testid='clause-modal-wrapper'>
            <ModalHeader label='Available Clauses' icon={Pencil} testId='clause-modal' />
            {isIndustryStandard && <TableTabs
                tabs={tableTabs}
                testId='clause-library'
                margin='0 10px'
            />}
            <ArkTable
                colDefs={clauseColumnDefs(clauseActions)}
                rowData={allOpinionClauses}
                testId='client-clauses'
                isLoading={isLoading}
                page={pageNumber}
                total={totalClauses}
                next={nextPage}
                previous={previousPage}
                filters={clauseFilters}
                updateFilter={updateFilter}
                clearAllFilters={clearAllFilters}
                onRowDoubleClicked={row => openClause(row as ArkTableClause)}
                columnSort={columnSort}
                toggleSort={toggleColumnSort}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                filterDropdownOptions={filterDropdownOptions(clauseLibraryDropdownOptions)}
                height={tableHeight}
                width={tableWidth}
            />
            <Button onClick={closeLinkClausesModal} label='Close' testId='clause-modal-left-button-group-close' />
        </div>
    );
};
