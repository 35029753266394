import { isEqual, isNull } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { getSelectedSupportDocument, getSupportDocuments, openSupportDocumentStarted } from '../my-documents/store';
import styles from './DocumentAnalysis.module.scss';

export const SupportDocumentsSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const supportDocuments = useAppSelector(getSupportDocuments);
    const selectedSupportDocument = useAppSelector(getSelectedSupportDocument);

    const dropdownOptions = useMemo(() => supportDocuments.map(({ documentDescription, documentId }) => ({ value: documentId.toString(), label: documentDescription })), [supportDocuments]);
    const dropdownValue = useMemo(() => dropdownOptions.find(({ value }) => value === selectedSupportDocument?.documentId.toString()) || null, [dropdownOptions, selectedSupportDocument]);

    const openSupportDocument = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: string | null = null;
        if (!isNull(dropdownValue)) {
            value = (dropdownValue as DropdownOption).value;
        }
        if (!isNull(value) && !isEqual(value, selectedSupportDocument?.documentId.toString())) {
            const supportDocument = supportDocuments.find(({ documentId }) => documentId.toString() === value)!;
            dispatch(openSupportDocumentStarted(supportDocument));
        }
    };

    return (
        <div className={styles.supportDocumentsSelectWrapper}>
            <div className={styles.supportDocumentsDropdownWrapper}>
                <Dropdown
                    onChange={openSupportDocument}
                    options={dropdownOptions}
                    value={dropdownValue}
                    isClearable={false}
                />
            </div>
        </div>
    );
};
