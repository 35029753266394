import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { SecurityInterestDocumentation, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface SecurityInterestProps {
    securityInterest: SecurityInterestDocumentation;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const SecurityInterestSection: React.FC<SecurityInterestProps> = ({ securityInterest, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'securityInterest';
    const {
        governingLawRecognised,
        jurisdictionOfCourtsRecognised,
        validityRecognised,
        validityBodyOfLaw,
        perfectionGoverningLaw,
        collateralTakerPerfected,
        actionsToPerfect,
        eligibleCollateralCreated,
        affectedByFluctuation,
        futureObligations,
        futureCollateral,
        fluctuatingAssets,
        fixedAmount,
        holdExcessCollateral,
        careOfCollateral,
        useRehypothecatePostedCollateral,
        consentToSubstitution,
        substituteAdverseImpact,
        enforceRightsFormalities,
        enforceLimitations,
        enforceInDefault,
        insolvencyCompetingPriorities,
        rightsAffectedByCommencementOfInsolvency,
        clawbackDuringSuspectPeriod,
        imJapaneseAmendmentsAffectConclusion,
        euroclearPledgeeAffectConclusion,
        euroclearProprietaryRights,
        cashLeakageConclusion
    } = securityInterest;

    return (
        <SectionWrapper id={sectionId} label='Security Interest Documentation' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.securityInterestSectionWrapper}>
                <div className={styles.sectionHeaderLabel}>Under the laws of the jurisdiction under consideration:</div>
                <SingleField
                    field={governingLawRecognised}
                    fieldId='governingLawRecognised'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={jurisdictionOfCourtsRecognised}
                    fieldId='jurisdictionOfCourtsRecognised'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={validityRecognised}
                    fieldId='validityRecognised'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={validityBodyOfLaw}
                    fieldId='validityBodyOfLaw'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={perfectionGoverningLaw}
                    fieldId='perfectionGoverningLaw'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['Securities', 'Include relevant detail as to applicable conflict of laws rules in relation to non-cash collateral (i.e. whether lex situs, lex contractus or other conflict of laws principles apply). This should cover on what basis collateral will be deemed to be located (e.g. PRIMA). It can be assumed that securities are held in intermediated form.', 'Cash', 'Include relevant detail as to applicable conflict of laws rules in relation to cash collateral. This should cover on what basis collateral will be deemed to be located.']}
                />
                <SingleField
                    field={collateralTakerPerfected}
                    fieldId='collateralTakerPerfected'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['Include analysis as to what formalities, if any, are required in order to ensure a perfected, first ranking charge over the collateral.']}
                />
                <SingleField
                    field={actionsToPerfect}
                    fieldId='actionsToPerfect'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['If formalities are required, include detail of (i) what the formalities are; (ii) what party is expected to undertake such formalities; (iii) consequences of a failure to comply with such formalities; and (iv) any other relevant issues raised by the opinion.', 'If dealing with EU jurisdictions, please confirm whether the Agreements shall be considered to be Financial Collateral Arrangements']}
                />
                <SingleField
                    field={eligibleCollateralCreated}
                    fieldId='eligibleCollateralCreated'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['If answer is anything other than yes, explain']}
                />
                <SingleField
                    field={affectedByFluctuation}
                    fieldId='affectedByFluctuation'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['Include relevant detail of any effect, including – but not limited to – the impact of any recharacterisation as a floating charge.']}
                />
                <SingleField
                    field={futureObligations}
                    fieldId='futureObligations'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['Include relevant detail of issues in creating security over future obligations']}
                />
                <SingleField
                    field={futureCollateral}
                    fieldId='futureCollateral'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['Include relevant detail of issues in creating security over future collateral']}
                />
                <SingleField
                    field={fluctuatingAssets}
                    fieldId='fluctuatingAssets'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['Include relevant detail of any effect, including – but not limited to – the potential risk (and consequences of) recharacterisation as a floating charge.']}
                />
                <SingleField
                    field={fixedAmount}
                    fieldId='fixedAmount'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['Include relevant analysis']}
                />
                <SingleField
                    field={holdExcessCollateral}
                    fieldId='holdExcessCollateral'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['If no, explain']}
                />
                <SingleField
                    field={careOfCollateral}
                    fieldId='careOfCollateral'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['Include relevant analysis of any duties imposed on the collateral provider. Please include separate analysis as relevant for margin held directly by the collateral provider and margin held via a third party custodial arrangement']}
                />
                <SingleField
                    field={useRehypothecatePostedCollateral}
                    fieldId='useRehypothecatePostedCollateral'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['In relation to IM Security Documents - Not applicable.  The IM Security Documents do not contain a right of use given the requirements of the WGMR Regimes.']}
                />
                <SingleField
                    field={consentToSubstitution}
                    fieldId='consentToSubstitution'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={substituteAdverseImpact}
                    fieldId='substituteAdverseImpact'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['Analysis will likely differ dependent on the nature of how collateral is held (i.e. whether held directly or via a custodian). Please include separate analyses as appropriate. ANY impact on perfection or priority should be mentioned']}
                />
                <SingleField
                    field={enforceRightsFormalities}
                    fieldId='enforceRightsFormalities'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['The ideal position is that security should ideally be able to be enforced by either sale of the collateral or appropriation without the need for consent of a third party. Include detail of relevant formalities. If an EU jurisdiction, it should be confirmed if appropriation is a valid method of enforcement. Any requirement to observe any formalities should be analysis on its particular facts. The impact of such formalities will be dependent on whether they apply to all collateral arrangements or only those that have not been validly perfected, and if consent of a third party (whether a court, liquidator or otherwise) is required in order to enforce.']}
                />
                <SingleField
                    field={enforceLimitations}
                    fieldId='enforceLimitations'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['If yes, specify']}
                />
                <SingleField
                    field={enforceInDefault}
                    fieldId='enforceInDefault'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['If no, explain']}
                />
                <SingleField
                    field={insolvencyCompetingPriorities}
                    fieldId='insolvencyCompetingPriorities'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={rightsAffectedByCommencementOfInsolvency}
                    fieldId='rightsAffectedByCommencementOfInsolvency'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['In either case, include detail of the legal analysis that supports conclusion', 'If there is the potential for any stay, the following should be specified, at minimum:', '(i) what actions may be stayed', '(ii) the length of any stay, including the potential for any extensions and in what circumstances an extension may be granted', '(iii) in what circumstances will said stay be granted', '(iv) any potential “saving provisions” of protections for netting and financial collateral arrangements in legislation that may serve to preserve the enforceability of the arrangements', '(v) whether AET – assuming it is enforceable – may be applied to ensure termination of any agreement and enforcement of the security interest prior to the insolvency event.', 'ANY material delay is likely to prove fatal to the analysis.']}
                />
                <SingleField
                    field={clawbackDuringSuspectPeriod}
                    fieldId='clawbackDuringSuspectPeriod'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['It is common for jurisdictions to provide powers to liquidators enabling them to challenge contracts on the grounds that they provide fraudulent preferences to certain creditors, or otherwise were intended to defraud the general body of creditors as a whole. Accordingly, this question is a question of degree.', 'Suspect periods within “usual” parameters may be considered to be immaterial:', '- Maximum suspect period of around 2 years', '- Reason for challenge to the contract is that it is intended to disadvantage / defraud general body of creditors', '- No capability to cherry pick', '- Avoidance of transactions entered into post-insolvency is not a concern on the basis this is a common feature of insolvency regimes', 'The longer the suspect period, the greater the discretion a liquidator has in relation to the suspect period, any onerous requirements to prove claims or if the burden of proof is placed on the secured party to prove its claim should be flagged.']}
                />
                <SingleField
                    field={imJapaneseAmendmentsAffectConclusion}
                    fieldId='imJapaneseAmendmentsAffectConclusion'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={euroclearPledgeeAffectConclusion}
                    fieldId='euroclearPledgeeAffectConclusion'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={euroclearProprietaryRights}
                    fieldId='euroclearProprietaryRights'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={cashLeakageConclusion}
                    fieldId='cashLeakageConclusion'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
            </div>
        </SectionWrapper>
    );
};
