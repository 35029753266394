import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import styles from './HomeWrapper.module.scss';
import Logo from '../../assets/logos/Ark51_HORIWHITE&GRAD.png';
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { getPathname, getUser } from '../auth/login/store';
import { toggleLeftMenu, toggleRightMenu } from './store';
import { Icon as ArkIcon } from '../shared/icon/Icon';
import { HamburgerMenu } from '../shared/icons';
import { NotificationButton } from '../auth/notifications/NotificationButton';
import { ProfilePicture } from '../shared/picture/ProfilePicture';
import { MLProgressButton } from '../auth/notifications/MLProgressButton';
import { DocumentsView, getDocumentsView } from '../documents/my-documents/store';
import { getOpinionsView, OpinionsView } from '../opinions/my-opinions/store';

export const TopNav: React.FC = () => {
    const user = useAppSelector(getUser);
    const pathname = useAppSelector(getPathname);
    const dispatch = useAppDispatch();

    const toggleLeftMenuOpen = () => dispatch(toggleLeftMenu(true));
    const toggleRightMenuOpen = () => dispatch(toggleRightMenu(true));

    const opinionsView = useAppSelector(getOpinionsView);
    const isMapView = useMemo(() => opinionsView === OpinionsView.MAP, [opinionsView]);
    const documentView = useAppSelector(getDocumentsView);
    const isOriginalDocumentView = useMemo(() => documentView === DocumentsView.ORIGINAL_DOCUMENTS, [documentView]);
    const inDocumentAnalysis = pathname.includes('/documents/analysis');
    const inOpinionAnalysis = pathname.includes('/opinions/analysis');

    const linkTo = useMemo(() => {
        if (inOpinionAnalysis) {
            return isMapView ? '/opinions/my-opinions/map' : '/opinions/my-opinions';
        }
        return isOriginalDocumentView ? '/documents/my-documents' : '/documents/my-documents/pre-execution';
    }, [inOpinionAnalysis, isOriginalDocumentView, isMapView]);

    const { profilePicture, initials } = useMemo(() => {
        if (!user) {
            return { profilePicture: undefined, initials: undefined };
        }
        return { profilePicture: user.profilePicture, initials: `${user.forenames.charAt(0)}${user.surname.charAt(0)}` };
    }, [user]);

    return (
        <div className={styles.topNavWrapper} data-testid='top-nav-wrapper'>
            <div className={styles.menuWrapper}>
                <button className={styles.menuButton} onClick={toggleLeftMenuOpen} data-testid='top-nav-left-menu-button'>
                    <ArkIcon icon={HamburgerMenu} fontSize={40} />
                </button>
                {(inDocumentAnalysis || inOpinionAnalysis) && <Link to={linkTo} style={{ textDecoration: 'none' }}>
                    <div className={styles.backButton} data-testid='back'>← Back</div>
                </Link>}
            </div>
            <Link to='/home'>
                <div className={styles.logoWrapper} data-testid='top-nav-logo-wrapper'>
                    <img className={styles.logo} src={Logo} />
                </div>
            </Link>
            <div className={styles.usernameWrapper}>
                <MLProgressButton />
                <NotificationButton />
                <button className={styles.usernameButton} onClick={toggleRightMenuOpen} data-testid='top-nav-right-menu-button'>
                    <ProfilePicture profilePicture={profilePicture} initials={initials} userIconSize={42} initialsFontSize={16} />
                </button>
            </div>
        </div>
    );
};
