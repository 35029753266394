import { GeoPermissibleObjects, GeoProjection, easeLinear, geoMercator, geoPath, select, selectAll, zoom, zoomIdentity } from 'd3';
import { flow, isNull, max, set } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Options } from 'react-select';

import { useAppDispatch } from '../../../../hooks/react-redux';
import { unitedKingdom, worldCountriesList } from '../../../constants/worldCountriesList';
import { Country } from '../../../documents/analytics/store';
import { IconButton } from '../../../shared/button/IconButton';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Add, Minus, Search } from '../../../shared/icons';
import { CustomTooltip } from '../../../shared/tooltip';
import styles from '../Analytics.module.scss';
import { OpinionMapAnalytics, OpinionMapCountry, PermittedOpinionMapAnalytics, setSelectedCountry } from '../store';

const {
    lightestGrey,
    lightGrey,
    fontFamily,
    primary,
    primaryBorder,
    green,
    amber,
    grey,
    red,
    oceanBlue,
    french
} = styles;

export interface SingleDataPointChartProps {
    height: number;
    width: number;
    worldGeometry: Country[];
    graphData: OpinionMapCountry[];
    selectedCountry?: Country;
    onClick: (countryName: string) => void;
}

interface MapData extends Country {
    data: OpinionMapAnalytics[];
    disabled: boolean;
}

export const SingleDataPointChart: React.FC<SingleDataPointChartProps> = ({
    height,
    width,
    worldGeometry,
    graphData,
    selectedCountry,
    onClick
}) => {
    const [resetZoom, setResetZoom] = useState<boolean>(false);
    const [zoomSteps, setZoomSteps] = useState<number>(0);
    const [zoomUpdated, setZoomUpdated] = useState<boolean>(false);
    const [currentScale, setCurrentScale] = useState<number>(0);

    const scaleExtent: [number, number] = useMemo(() => [0.2, 5], []);

    const dispatch = useAppDispatch();
    const svgRef = useRef<SVGSVGElement | null>(null);
    const svgHeight = useMemo(() => height - 48, [height]);
    const tooltipContent = useCallback((tooltipValue: string) => `<tspan dy=12 x=10>${tooltipValue}</tspan>`, []);

    const getTooltip = useCallback((name: string, value: number) => `${name}: ${value}`, []);
    const getTooltipWidth = useCallback((tooltipValue: string) => max([(`${tooltipValue}`.length * 8) + 10, 100])!, []);

    const getValue = useCallback((id: string) => {
        let value = null;
        const country = graphData.find(({ geoId }) => geoId === id);
        if (country && country.data.length > 0 && country.data.every(({ isPermitted }) => !isPermitted)) {
            return 'Not permitted';
        }
        if (country && country.data.length > 0) {
            value = country.data.map(data => (data as PermittedOpinionMapAnalytics).dropdownValue || 'No value provided').join(', ');
        }
        if (!isNull(value)) {
            return value;
        }
        if (country && country.data.length === 0 || !country) {
            return 'Not found in Ark51';
        }
        return 'No value provided';
    }, [graphData]);
    const getData = useCallback((id: string) => graphData.find(({ geoId }) => geoId === id)?.data || [], [graphData]);
    const getOpinionDisabled = useCallback((id: string) => graphData.find(({ geoId }) => geoId === id)?.disabled || false, [graphData]);

    const data: MapData[] = useMemo(() => worldGeometry.map(country => flow(
        set('value', getValue(country.id)),
        set('data', getData(country.id)),
        set('disabled', getOpinionDisabled(country.id)),
    )(country)), [getValue, worldGeometry, getData, getOpinionDisabled]);

    const getFillColour = useCallback(({ data }: MapData) => {
        if (data.length === 0) {
            return lightestGrey;
        }
        if (data.every(({ isPermitted }) => isPermitted)) {
            const permittedData = data as PermittedOpinionMapAnalytics[];
            if (permittedData.some(({ isNegative }) => isNegative)) {
                return red;
            }
            if (permittedData.some(({ isPositive }) => isPositive)) {
                return green;
            }
            if (permittedData.every(({ isPositive, isNegative }) => !isNegative && !isPositive)) {
                return permittedData.every(({ dropdownValue }) => isNull(dropdownValue)) ? grey : amber;
            }
        }
        return lightGrey;
    }, []);

    const updateSelectedCountry = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        setZoomUpdated(false);
        dispatch(setSelectedCountry(selected));
    };

    const countryOptions = worldCountriesList.map(({ name, geoId }) => ({ label: name, value: geoId }));
    const countryValue = useMemo(() => selectedCountry ? { value: selectedCountry.id, label: selectedCountry.properties.name } : null, [selectedCountry]);

    const resetMapZoom = () => {
        dispatch(setSelectedCountry(null));
        setResetZoom(!resetZoom);
        setZoomSteps(0);
        setZoomUpdated(false);
    };

    const mapZoomIn = useCallback(() => {
        setResetZoom(false);
        zoomSteps < 0 ? setZoomSteps(1) : setZoomSteps(zoomSteps + 1);
        setZoomUpdated(true);
    }, [zoomSteps, setZoomUpdated]);

    const mapZoomOut = useCallback(() => {
        setResetZoom(false);
        zoomSteps > 0 ? setZoomSteps(-1) : setZoomSteps(zoomSteps - 1);
        setZoomUpdated(true);
    }, [zoomSteps, setZoomUpdated]);

    const zoomInDisabled = useMemo(() => currentScale >= scaleExtent[1], [currentScale, scaleExtent]);
    const zoomOutDisabled = useMemo(() => currentScale <= scaleExtent[0], [currentScale, scaleExtent]);

    useEffect(() => {
        const svg = select<SVGSVGElement, unknown>(svgRef.current!);

        const projection: GeoProjection = geoMercator()
            .scale(500)
            .center([0, 60])
            .translate([width / 1.5, svgHeight / 50]);

        const countryLines = geoPath().projection(projection);

        const map = svg
            .selectAll('path')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'map')
            .attr('id', 'opinions-jurisdiction')
            .attr('overflow', 'hidden');

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const handleZoom = ({ transform }: any) => {
            map.attr('transform', transform);
            setCurrentScale(transform.k);
        };

        const mapZoom = zoom<SVGSVGElement, unknown>()
            .extent([[0, 0], [width, svgHeight]])
            .scaleExtent(scaleExtent)
            .on('zoom', handleZoom);

        svg.call(mapZoom);

        const mapZoomIn = () => {
            svg.transition().call(mapZoom.scaleBy, 1.2);
        };
        const mapZoomOut = () => {
            svg.transition().call(mapZoom.scaleBy, 0.8);
        };

        if ((resetZoom && !selectedCountry) || (zoomSteps === 0 && !selectedCountry)) {
            // Coordinates for the UK, this ensures we are zoomed in to the same place each time we reload
            // and also after we clear the dropdown or reset the zoom it resets to this position
            const country = unitedKingdom as GeoPermissibleObjects;
            const [[x0, y0], [x1, y1]] = countryLines.bounds(country);
            const minScale = 0.2 / Math.max((x1 - x0) / width, (y1 - y0) / svgHeight);
            const scale = Math.min(5, minScale < scaleExtent[0] ? scaleExtent[0] : minScale);
            svg.transition().duration(750).call(
                mapZoom.transform,
                zoomIdentity
                    .translate(width / 2, svgHeight / 2)
                    .scale(scale)
                    .translate(-(x0 + x1) / 2, -(y0 + y1) / 2)
            );
        } else {
            zoomSteps > 0 ? mapZoomIn() : mapZoomOut();
        }

        map
            .append('path')
            .attr('d', (d: Country, e) => countryLines(d as GeoPermissibleObjects, e))
            .attr('fill', (d: MapData) => getFillColour(d))
            .attr('stroke', primaryBorder)
            .attr('id', (d: Country) => `jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
            .attr('class', 'primary')
            .transition().delay((_, i) => i * 950).duration(250).ease(easeLinear);

        map
            .on('mouseover', (_, d: Country) => {
                tooltip.attr('display', null);
                selectAll(`#jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                    .transition()
                    .duration(10)
                    .attr('opacity', 0.9)
                    .attr('stroke', primary);
            })
            .on('mouseout', (_, d: Country) => {
                tooltip.attr('display', 'none');
                selectAll(`#jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                    .transition()
                    .duration(10)
                    .attr('opacity', 1)
                    .attr('stroke', primaryBorder);
            })
            .on('mousemove', (_, d: Country) => {
                const tooltipValue = getTooltip(d.properties.name, d.value!);
                tooltip.select('rect')
                    .attr('height', 25)
                    .attr('width', getTooltipWidth(tooltipValue));
                tooltip.select('text')
                    .html(tooltipContent(tooltipValue));
            })
            .on('click', (_, d) => {
                if (d.data.length > 0) {
                    onClick(d.id);
                }
            });

        if (selectedCountry && !zoomUpdated) {
            const [[x0, y0], [x1, y1]] = countryLines.bounds(selectedCountry as GeoPermissibleObjects);
            const minScale = 0.6 / Math.max((x1 - x0) / width, (y1 - y0) / svgHeight);
            const scale = Math.min(5, minScale < scaleExtent[0] ? scaleExtent[0] : minScale);
            svg.transition().duration(750).call(
                mapZoom.transform,
                zoomIdentity
                    .translate(width / 2, svgHeight / 2)
                    .scale(scale)
                    .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
            );
            // Transition in and out of stroke opacity to help highlight the selected country when zoomed
            map.
                selectAll(`#jurisdiction-${selectedCountry.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                .transition()
                .duration(3000)
                .attr('opacity', 0.8)
                .attr('stroke', primary)
                .attr('stroke-opacity', 1)
                .transition()
                .duration(300)
                .attr('opacity', 1)
                .attr('stroke', primary)
                .attr('stroke-opacity', 0.2);
        }

        const tooltip = svg
            .append('g')
            .attr('class', 'tooltip')
            .attr('data-testid', 'opinions-world-map-tooltip')
            .attr('display', 'none')
            .attr('transform', `translate(0, ${svgHeight - 25})`);

        tooltip
            .append('rect')
            .attr('rx', 5)
            .attr('fill', lightestGrey);

        tooltip
            .append('text')
            .attr('x', 10)
            .attr('y', 4)
            .attr('fill', primary)
            .attr('text-anchor', 'start')
            .attr('font-size', '14px')
            .attr('font-family', fontFamily)
            .attr('font-weight', 600);

        return () => {
            // Anything added to the svg on mount via .append must be removed on unmount to stop new elements being added each render
            svg.selectAll('.tooltip').remove();
            svg.selectAll('.map').remove();
        };

    }, [svgHeight, width, tooltipContent, getTooltip, getTooltipWidth, data, selectedCountry, onClick, resetZoom, zoomSteps, zoomUpdated, scaleExtent, getFillColour, setZoomUpdated]);

    return (
        <div className={styles.jurisdictionChart}>
            <div className={styles.svgWrapper} data-testid='opinion-single-data-map-wrapper' style={{ height: `${svgHeight}px`, width }}>
                <svg ref={svgRef} style={{ height: svgHeight, width, overflow: 'hidden', borderRadius: '5px', cursor: 'pointer', backgroundColor: oceanBlue }} />
            </div>
            <div className={styles.jurisdictionChartDropdownWrapper}>
                <div className={styles.jurisdictionChartZoomWrapper}>
                    <CustomTooltip overlayText='Zoom In' placement='top'>
                        <div>
                            <IconButton onClick={mapZoomIn} disabled={zoomInDisabled} testId='jurisdiction-map-zoom-in' icon={Add} fontSize={20} withBackground />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip overlayText='Reset Zoom' placement='top'>
                        <div>
                            <IconButton onClick={resetMapZoom} disabled={false} testId='jurisdiction-map-reset-zoom' icon={Search} fontSize={20} withBackground iconFill={french} />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip overlayText='Zoom Out' placement='top'>
                        <div>
                            <IconButton onClick={mapZoomOut} disabled={zoomOutDisabled} testId='jurisdiction-map-zoom-out' icon={Minus} fontSize={20} withBackground />
                        </div>
                    </CustomTooltip>
                </div>
                <div className={styles.jurisdictionChartDropdown}>
                    <Dropdown
                        testId='country-select'
                        onChange={updateSelectedCountry}
                        value={countryValue}
                        options={countryOptions}
                        controlBackgroundColor={lightestGrey}
                        placeholder='Go to Country...'
                    />
                </div>
            </div>
        </div>
    );
};
