import React, { useEffect } from 'react';
import { Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useIntersectionObserver } from 'usehooks-ts';

const observerConfig = {
    threshold: 0
};

interface PageWithObserver {
    pageNumber: number;
    setPageVisibility: (pageNumber: number, isIntersecting: boolean) => void;
    pagesWidth: number;
    width: number;
    setRef: (element: HTMLDivElement | null, pageNumber: number) => void;
    scrollToThumbnail: (pageNumber: number) => void;
}

export const PageWithObserver: React.FC<PageWithObserver> = ({ pageNumber, setPageVisibility, pagesWidth, width, setRef, scrollToThumbnail }) => {
    const { ref, isIntersecting } = useIntersectionObserver(observerConfig);

    useEffect(() => {
        setPageVisibility(pageNumber, isIntersecting);
    }, [pageNumber, setPageVisibility, isIntersecting]);

    return (
        <div ref={ref}>
            <Page
                key={`page_${pageNumber}`}
                pageNumber={pageNumber}
                width={width * pagesWidth}
                loading={null}
                inputRef={element => setRef(element, pageNumber)}
                onClick={() => scrollToThumbnail(pageNumber)}
            />
        </div>
    );
};
