import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { Attestation } from '../../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../../shared/modal/TypeDeleteConfirmationModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { deleteAttestationFormStarted, getConfirmDeleteAttestationForm, getIsDeleting, toggleDeleteAttestationFormConfirmationModal } from '../store';

export const DeleteConfirmationModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const attestationForm = useAppSelector(getConfirmDeleteAttestationForm);
    const isDeleting = useAppSelector(getIsDeleting);

    const name = useMemo(() => attestationForm?.name || '', [attestationForm]);

    const closeModal = useCallback(() => dispatch(toggleDeleteAttestationFormConfirmationModal(null)), [dispatch]);
    const deleteAttestationForm = useCallback(() => !isNull(attestationForm) && dispatch(deleteAttestationFormStarted(attestationForm.attestationFormId!)), [dispatch, attestationForm]);

    if (isNull(attestationForm)) {
        return null;
    }

    return (
        <TypeDeleteConfirmationModal
            isOpen={!isNull(attestationForm)}
            closeModal={closeModal}
            deleteAction={deleteAttestationForm}
            isDeleting={isDeleting}
            headerLabel='Delete Attestation Form'
            name={name}
            testId='attestation-form'
            deleteLabel='attestation form'
            icon={Attestation}
        />
    );
};
