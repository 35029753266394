import { flow, set } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { IconButton } from '../../../shared/button/IconButton';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide, Filter } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Toggle } from '../../../shared/toggle';
import styles from '../Clients.module.scss';
import { Client, ClientOpinion, ClientSection } from '../store';

const { white, grey } = styles;

interface IndividualClientOpinionsProps {
    clientOpinions: ClientOpinion[];
    jurisdictionFilter: string;
    openJurisdictionFilter: (x: number, y: number) => void;
    toggleSection: (section: ClientSection) => void;
    getSectionOpen: (section: ClientSection) => boolean;
    updateClientProperties: (key: keyof Client, value: string | number[] | ClientOpinion[]) => void;
}

export const IndividualClientOpinions: React.FC<IndividualClientOpinionsProps> = ({ clientOpinions, jurisdictionFilter, openJurisdictionFilter, toggleSection, getSectionOpen, updateClientProperties }) => {

    const filteredClientOpinions = useMemo(() => clientOpinions.filter(({ jurisdiction }) => jurisdiction.toLowerCase().includes(jurisdictionFilter.toLowerCase())).sort((a, b) => a.jurisdiction.localeCompare(b.jurisdiction)), [clientOpinions, jurisdictionFilter]);

    const updateClientOpinions = (checked: boolean, jurisdiction: string, focus: string, property: keyof ClientOpinion) => {
        const updatedOpinions: ClientOpinion[] = clientOpinions.map(opinion => {
            if (opinion.jurisdiction === jurisdiction && opinion.focus === focus) {
                return set(property, checked, opinion);
            }
            return opinion;
        });
        updateClientProperties('clientOpinions', updatedOpinions);
    };

    const { totalIsdaNetting, totalIsdaCollateral, totalIcma, totalIsla } = useMemo(() => clientOpinions.reduce((acc, cur) => flow(
        set('totalIsdaNetting', acc.totalIsdaNetting + (cur.isdaNetting ? 1 : 0)),
        set('totalIsdaCollateral', acc.totalIsdaCollateral + (cur.isdaCollateral ? 1 : 0)),
        set('totalIcma', acc.totalIcma + (cur.icma ? 1 : 0)),
        set('totalIsla', acc.totalIsla + (cur.isla ? 1 : 0))
    )(acc), { totalIsdaNetting: 0, totalIsdaCollateral: 0, totalIcma: 0, totalIsla: 0 }), [clientOpinions]);

    const totalOpinions = useMemo(() => totalIcma + totalIsdaNetting + totalIsdaCollateral + totalIsla, [totalIcma, totalIsdaNetting, totalIsdaCollateral, totalIsla]);

    const getOpinionColumnHeader = useCallback((column: string) => {
        switch (column) {
            case 'ISDA Netting':
                return `ISDA Netting (${totalIsdaNetting})`;
            case 'ISDA Collateral':
                return `ISDA Collateral (${totalIsdaCollateral})`;
            case 'ICMA':
                return `ICMA (${totalIcma})`;
            case 'ISLA':
                return `ISLA (${totalIsla})`;
            case 'Jurisdiction':
            case 'Focus':
            default:
                return column;
        }
    }, [totalIcma, totalIsdaNetting, totalIsdaCollateral, totalIsla]);

    const opinionHeaders = [{ name: 'Jurisdiction', width: '20%', justifyContent: 'flex-start', showFilter: true }, { name: 'Focus', width: '20%', justifyContent: 'flex-start' }, { name: 'ISDA Netting', width: '15%', justifyContent: 'center' }, { name: 'ISDA Collateral', width: '15%', justifyContent: 'center' }, { name: 'ICMA', width: '15%', justifyContent: 'center' }, { name: 'ISLA', width: '15%', justifyContent: 'center' }];

    const opinionsSectionOpen = useMemo(() => getSectionOpen(ClientSection.INDIVIDUAL_OPINIONS), [getSectionOpen]);
    const getSectionOpenIcon = useCallback((isOpen: boolean) => isOpen ? CaretDown : CaretSide, []);

    return (
        <div className={styles.opinionsWrapper}>
            <div className={styles.opinionsHeader} onClick={() => toggleSection(ClientSection.INDIVIDUAL_OPINIONS)}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={getSectionOpenIcon(opinionsSectionOpen)} fontSize={15} />
                </div>
                <div className={styles.opinionsTitle}>Opinions Available to Client ({totalOpinions})</div>
            </div>
            {opinionsSectionOpen &&
                <>
                    <div className={styles.opinionsTableHeader}>
                        {opinionHeaders.map(({ name, width, justifyContent, showFilter }) => (
                            <div className={styles.opinionHeaderCell} key={name} style={{ width, justifyContent }}>
                                <div>{getOpinionColumnHeader(name)}</div>
                                {showFilter && <IconButton icon={Filter} onClick={e => openJurisdictionFilter(e.clientX, e.clientY)} color={white} fontSize={20} margin='0 0 0 10px' showIndicator={!!jurisdictionFilter.length} />}
                            </div>
                        ))}
                    </div>
                    <div className={styles.opinionsTable}>
                        <Scrollable maxHeight='250px'>
                            {filteredClientOpinions.map(({ jurisdiction, focus, isdaNetting, isdaCollateral, isla, icma }, index) => (
                                <div className={styles.opinionTableRow} key={index} style={{ backgroundColor: index % 2 ? grey : white }}>
                                    <div className={styles.jurisdiction}>{jurisdiction}</div>
                                    <div className={styles.focus}>{focus}</div>
                                    <div className={styles.toggleCell}>
                                        <Toggle
                                            checked={isdaNetting}
                                            onChange={checked => updateClientOpinions(checked, jurisdiction, focus, 'isdaNetting')}
                                        />
                                    </div>
                                    <div className={styles.toggleCell}>
                                        <Toggle
                                            checked={isdaCollateral}
                                            onChange={checked => updateClientOpinions(checked, jurisdiction, focus, 'isdaCollateral')}
                                        />
                                    </div>
                                    <div className={styles.toggleCell}>
                                        <Toggle
                                            checked={icma}
                                            onChange={checked => updateClientOpinions(checked, jurisdiction, focus, 'icma')}
                                        />
                                    </div>
                                    <div className={styles.toggleCell}>
                                        <Toggle
                                            checked={isla}
                                            onChange={checked => updateClientOpinions(checked, jurisdiction, focus, 'isla')}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </>
            }
        </div>
    );
};
