import { RawDraftContentState } from 'react-draft-wysiwyg';

import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { DoraSupplyChainDetails } from '../../supply-chain/store';

export enum DoraFunctionActionTypes {
    FETCH_DORA_FUNCTIONS_STARTED = 'FETCH_DORA_FUNCTIONS_STARTED',
    FETCH_DORA_FUNCTIONS_SUCCESSFUL = 'FETCH_DORA_FUNCTIONS_SUCCESSFUL',
    FETCH_DORA_FUNCTIONS_FAILED = 'FETCH_DORA_FUNCTIONS_FAILED',
    SET_DORA_FUNCTIONS_TABLE_FILTERS = 'SET_DORA_FUNCTIONS_TABLE_FILTERS',
    CLEAR_DORA_FUNCTIONS_TABLE_FILTERS = 'CLEAR_DORA_FUNCTIONS_TABLE_FILTERS',
    DORA_FUNCTIONS_PAGINATION_NEXT = 'DORA_FUNCTIONS_PAGINATION_NEXT',
    DORA_FUNCTIONS_PAGINATION_PREVIOUS = 'DORA_FUNCTIONS_PAGINATION_PREVIOUS',
    SET_DORA_FUNCTIONS_TABLE_COLUMN_SORT = 'SET_DORA_FUNCTIONS_TABLE_COLUMN_SORT',
    SET_DORA_FUNCTIONS_PAGE_SIZE = 'SET_DORA_FUNCTIONS_PAGE_SIZE',
    TOGGLE_DORA_FUNCTION_MODAL = 'TOGGLE_DORA_FUNCTION_MODAL',
    UPDATE_DORA_FUNCTION_VALUE = 'UPDATE_DORA_FUNCTION_VALUE',
    SAVE_DORA_FUNCTION_STARTED = 'SAVE_DORA_FUNCTION_STARTED',
    SAVE_DORA_FUNCTION_SUCCESSFUL = 'SAVE_DORA_FUNCTION_SUCCESSFUL',
    SAVE_DORA_FUNCTION_FAILED = 'SAVE_DORA_FUNCTION_FAILED',
    TOGGLE_FUNCTION_CONFIG_MODAL = 'TOGGLE_FUNCTION_CONFIG_MODAL',
    UPDATE_FUNCTION_CONFIG = 'UPDATE_FUNCTION_CONFIG',
    FETCH_FUNCTION_CONFIG_STARTED = 'FETCH_FUNCTION_CONFIG_STARTED',
    FETCH_FUNCTION_CONFIG_SUCCESSFUL = 'FETCH_FUNCTION_CONFIG_SUCCESSFUL',
    FETCH_FUNCTION_CONFIG_FAILED = 'FETCH_FUNCTION_CONFIG_FAILED',
    SAVE_FUNCTION_CONFIG_STARTED = 'SAVE_FUNCTION_CONFIG_STARTED',
    SAVE_FUNCTION_CONFIG_SUCCESSFUL = 'SAVE_FUNCTION_CONFIG_SUCCESSFUL',
    SAVE_FUNCTION_CONFIG_FAILED = 'SAVE_FUNCTION_CONFIG_FAILED',
    TOGGLE_DELETE_FUNCTION_CONFIRMATION_MODAL = 'TOGGLE_DELETE_FUNCTION_CONFIRMATION_MODAL',
    FETCH_DORA_FUNCTION_USAGE_STARTED = 'FETCH_DORA_FUNCTION_USAGE_STARTED',
    FETCH_DORA_FUNCTION_USAGE_SUCCESSFUL = 'FETCH_DORA_FUNCTION_USAGE_SUCCESSFUL',
    FETCH_DORA_FUNCTION_USAGE_FAILED = 'FETCH_DORA_FUNCTION_USAGE_FAILED',
    DELETE_DORA_FUNCTION_STARTED = 'DELETE_DORA_FUNCTION_STARTED',
    DELETE_DORA_FUNCTION_SUCCESSFUL = 'DELETE_DORA_FUNCTION_SUCCESSFUL',
    DELETE_DORA_FUNCTION_FAILED = 'DELETE_DORA_FUNCTION_FAILED',
    SET_SELECTED_FUNCTION_CONFIGURATION = 'SET_SELECTED_FUNCTION_CONFIGURATION'
}

export interface DoraFunction {
    doraFunctionId?: number;
    name: string;
    description: string;
    systemFunction: number;
}

export interface DoraFunctionDB extends DoraFunction {
    doraFunctionId: number;
    modifiedBy: number;
    modifiedDate: string;
    clientId: number;
}

export interface DoraConfigurationDB {
    doraConfigurationId: number;
    details: RawDraftContentState | null;
    modifiedBy: number;
    modifiedDate: string;
    clientId: number;
}

export interface DoraConfiguration {
    doraConfigurationId?: number;
    details: RawDraftContentState | null;
    effectiveTo?: string | null;
}

export interface DoraConfigurationTimeline {
    doraConfigurationId: number;
    details: RawDraftContentState | null;
    effectiveFrom: string;
    effectiveTo: string | null;
    createdBy: string;
}

export interface DoraSupplyChainDB {
    doraSupplyChainId: number;
    doraFunctionCompanyId: number;
    companyId: number | null;
    thirdPartyCompanyId: number | null;
    parentId: number | null;
    details: DoraSupplyChainDetails;
    modifiedBy: number;
    modifiedDate: string;
    clientId: number;
}

export interface DoraFunctionUsage {
    companyId: number;
    companyName: string;
    supplyChainDetails: DoraSupplyChainDetails | null;
}

export interface FunctionToDelete {
    functionId: number;
    functionName: string;
}

export interface DoraFunctionState {
    doraFunctions: DoraFunction[];
    totalDoraFunctions: number;
    doraFunctionsFilters: TableFilters;
    doraFunctionsColumnSort?: ColumnSort;
    doraFunctionsPage: number;
    doraFunctionsPageSize: number;
    fetchingFunctions: boolean;
    selectedDoraFunction: DoraFunction | null;
    currentDoraFunction: DoraFunction | null;
    savingDoraFunction: boolean;
    functionConfigModalOpen: boolean;
    doraFunctionConfiguration: DoraConfiguration;
    currentDoraFunctionConfiguration: DoraConfiguration;
    timelineConfiguration: DoraConfigurationTimeline | null;
    functionToDelete: FunctionToDelete | null;
    functionToDeleteUsage: DoraFunctionUsage[];
    fetchingFunctionUsage: boolean;
    configurationTimeline: DoraConfigurationTimeline[];
}
