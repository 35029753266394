import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { DraggableItem } from '../../../shared/drag-n-drop/shared';
import sharedStyles from '../SharedBuilder.module.scss';
import { getAgencyDatasetFields, updateAgencyDatasetFields } from '../store';
import { AgencyContent } from './AgencyContent';
import { DragDrop } from '../../../shared/drag-n-drop/DragDrop';

export const AgencyDatasetBuilder: React.FC = () => {
    const fields = useAppSelector(getAgencyDatasetFields);
    const dispatch = useAppDispatch();
    const [, screenHeight] = useWindowResize();

    const listOptions = useMemo(() => fields.map(({ id, label }) => ({ id: id!, label: label || 'Label...' })), [fields]);

    const updateList = (list: DraggableItem[]) => {
        const newFields = list.map(({ id }) => fields.find(field => field.id === id)!);
        dispatch(updateAgencyDatasetFields(newFields));
    };

    const getChildElement = useCallback((childId: string, index: number) => {
        const field = fields.find(({ id }) => id === childId);
        if (field) {
            return (
                <AgencyContent field={field} key={field.id} index={index} />
            );
        }
        return null;
    }, [fields]);

    // This is the height of the modal minus other fixed height elements such as header and buttons wrapper
    const droppableHeight = screenHeight * 0.8 - (51 + 58);
    return (
        <div className={sharedStyles.tableBuilder} data-testid='agency-table-dataset-builder-wrapper' style={{ height: `${droppableHeight}px` }}>
            {fields.length > 0 ?
                <DragDrop getChildElement={getChildElement} list={listOptions} listId='agency-table-builder' updateList={updateList} /> :
                <div className={sharedStyles.placeholder}>
                    <div className={sharedStyles.placeholderText} data-testid='agency-table-dataset-builder-no-fields'>Select a dataset field to begin building dataset</div>
                </div>
            }
        </div>
    );
};
