import React from 'react';
import { isNull, isUndefined } from 'lodash/fp';

import { IconTooltip } from '../tooltip';
import styles from './Table.module.scss';
import { Information } from '../icons';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const IconTooltipCell: React.FC<{ valueFormatted?: string | null; value: any; icon?: React.FC, testId?: string; }> = ({ valueFormatted, value, icon = Information, testId }) => {

    const displayValue = valueFormatted || value;

    if (isNull(displayValue) || isUndefined(displayValue) || displayValue === '') {
        return null;
    }

    return (
        <div className={styles.iconTooltipCellWrapper} data-testid={`${testId}-tooltip-cell-wrapper`}>
            <IconTooltip icon={icon} fontSize={30} content={displayValue} />
        </div>
    );
};
