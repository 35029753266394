import { OpenFieldType } from '../../../datasets/instances/store';
import { DatasetDefinition } from '../../../datasets/store';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';
import { MyRiskTolerance, RiskTolerance, SelectedRiskField } from '../../risk-tolerance/store';

export enum MyDatasetActionTypes {
    FETCH_ALL_MY_DATASETS_STARTED = 'FETCH_ALL_MY_DATASETS_STARTED',
    FETCH_ALL_MY_DATASETS_SUCCESSFUL = 'FETCH_ALL_MY_DATASETS_SUCCESSFUL',
    FETCH_ALL_MY_DATASETS_FAILED = 'FETCH_ALL_MY_DATASETS_FAILED',
    REDIRECT_MY_DATASET = 'REDIRECT_MY_DATASET',
    MY_DATASET_VIEW = 'MY_DATASET_VIEW',
    FETCH_MY_DATASET_DEFINITIONS_STARTED = 'FETCH_MY_DATASET_DEFINITIONS_STARTED',
    FETCH_MY_DATASET_DEFINITIONS_SUCCESSFUL = 'FETCH_MY_DATASET_DEFINITIONS_SUCCESSFUL',
    FETCH_MY_DATASET_DEFINITIONS_FAILED = 'FETCH_MY_DATASET_DEFINITIONS_FAILED',
    REMOVE_MY_DATASETS_OPEN_FIELD_SECTION = 'REMOVE_MY_DATASETS_OPEN_FIELD_SECTION',
    ADD_MY_DATASETS_OPEN_FIELD_SECTION = 'ADD_MY_DATASETS_OPEN_FIELD_SECTION',
    REMOVE_ALL_MY_DATASETS_FIELD_SECTIONS = 'REMOVE_ALL_MY_DATASETS_FIELD_SECTIONS',
    RESET_MY_DATASETS = 'RESET_MY_DATASETS',
    TOGGLE_MODAL_DATASET_ID = 'TOGGLE_MODAL_DATASET_ID',
    TOGGLE_HIDDEN_FIELDS = 'TOGGLE_HIDDEN_FIELDS',
    SAVE_HIDDEN_FIELDS_STARTED = 'SAVE_HIDDEN_FIELDS_STARTED',
    SAVE_HIDDEN_FIELDS_SUCCESSFUL = 'SAVE_HIDDEN_FIELDS_SUCCESSFUL',
    SAVE_HIDDEN_FIELDS_FAILED = 'SAVE_HIDDEN_FIELDS_FAILED',
    HIDDEN_FIELDS_HAVE_UPDATED = 'HIDDEN_FIELDS_HAVE_UPDATED',
    SET_CURRENT_RISK_TOLERANCE_FIELD = 'SET_MY_CURRENT_RISK_TOLERANCE_FIELD',
    UPDATE_RISK_FIELD_CONFIG = 'UPDATE_MY_RISK_FIELD_CONFIG',
    UPDATE_RISK_FIELD_WEIGHTING = 'UPDATE_MY_RISK_FIELD_WEIGHTING',
    TOGGLE_RISK_FIELD = 'TOGGLE_MY_RISK_FIELD',
    SAVE_RISK_TOLERANCE_STARTED = 'SAVE_MY_RISK_TOLERANCE_STARTED',
    SAVE_RISK_TOLERANCE_SUCCESSFUL = 'SAVE_MY_RISK_TOLERANCE_SUCCESSFUL',
    SAVE_RISK_TOLERANCE_FAILED = 'SAVE_MY_RISK_TOLERANCE_FAILED',
    SET_RISK_TOLERANCE_UPDATED = 'SET_MY_RISK_TOLERANCE_UPDATED',
    TOGGLE_UNSAVED_CHANGES_MODAL = 'TOGGLE_MY_DATASETS_UNSAVED_CHANGES_MODAL'
}

export enum MyDatasetView {
    LIST = 'List',
    PREVIEW = 'Preview',
    CONFIGURE = 'Configure',
    RISK_TOLERANCE = 'Risk Tolerance'
}

export interface MyDatasets {
    datasetId: number;
    documentNames: string[];
    agreementTypeId: number;
    agreementType: string;
}

export interface MyDatasetsOpenField {
    sectionId?: string;
    rowId?: string;
    fieldId: string;
    datasetId: string;
    groupIndex?: number;
    type: OpenFieldType.FIELD;
}

export interface MyDatasetsOpenSection {
    sectionId: string;
    datasetId: string;
    type: OpenFieldType.SECTION;
}

export type MyDatasetsOpenFieldSection = MyDatasetsOpenField | MyDatasetsOpenSection;

export interface HiddenField {
    fieldId: string;
    type: OpenFieldType.FIELD;
    sectionId?: string;
}

export interface HiddenSection {
    sectionId: string;
    type: OpenFieldType.SECTION;
}

export interface HiddenFields {
    [datasetId: string]: (HiddenField | HiddenSection)[];
}

export const isHiddenField = (field: HiddenField | HiddenSection): field is HiddenField => field.type === OpenFieldType.FIELD;

export interface MyDatasetsState {
    allDatasets: MyDatasets[];
    fetchingAllDatasets: boolean;
    fetchError: string | null;
    datasetView: MyDatasetView;
    selectedDataset: number | null;
    myDatasetDefinitions: DatasetDefinition[];
    openFieldsAndSections: MyDatasetsOpenFieldSection[];
    modalDatasetId: number | null;
    savedHiddenFields: HiddenFields;
    currentHiddenFields: HiddenFields;
    isSaving: boolean;
    saveError: string | null;
    hiddenFieldsUpdated: boolean;
    myRiskToleranceConfig: (RiskTolerance | MyRiskTolerance)[] | null;
    currentRiskToleranceConfig: (RiskTolerance | MyRiskTolerance)[] | null;
    currentRiskField: SelectedRiskField | null;
    riskToleranceUpdated: boolean;
    unsavedChangesModalOpen: boolean;
    fetchingDatasetDefinitions: boolean;
    documentHiddenFields: DocumentSpecificHiddenFields;
}
