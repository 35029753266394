import classnames from 'classnames';
import { min } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch } from '../../hooks/react-redux';
import { useWindowResize } from '../../hooks/useWindowResize';
import { toggleUploadModal } from '../documents/my-documents/store';
import { Icon } from '../shared/icon/Icon';
import styles from './Home.module.scss';

interface HomeButtonProps {
    label: string;
    icon: React.FC;
    disabled: boolean;
    onClick?: () => void;
    path?: string;
    testId?: string;
    fill?: string;
}

const HomeButton: React.FC<HomeButtonProps> = ({ label, icon, disabled, onClick, path, testId, fill }) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [screenWidth, screenHeight] = useWindowResize();
    const iconSize = useMemo(() => min([screenHeight / 4, screenWidth / 4, 250])!, [screenHeight, screenWidth]);

    const showLabel = useCallback(() => setIsHovering(true), [setIsHovering]);
    const hideLabel = useCallback(() => setIsHovering(false), [setIsHovering]);

    if (path && !disabled) {
        return (
            <Link className={styles.linkButton} to={path}>
                <button
                    className={styles.homeButton}
                    onClick={onClick}
                    data-testid={`home-button-${testId}`}
                    onMouseOver={showLabel}
                    onMouseOut={hideLabel}
                >
                    <div className={styles.homeButtonIcon}>
                        <Icon icon={icon} fontSize={iconSize} fill={fill} />
                    </div>
                    {isHovering && <div className={classnames(styles.homeButtonLabel, { [styles.disabledHomeButtonLabel]: disabled })}>{label}</div>}
                </button>
            </Link>
        );
    }
    return (
        <button
            className={classnames(styles.homeButton, { [styles.disabledHomeButton]: disabled })}
            onClick={onClick}
            data-testid={`home-button-${testId}`}
            onMouseOver={showLabel}
            onMouseOut={hideLabel}
        >
            <div className={styles.homeButtonIcon}>
                <Icon icon={icon} fontSize={iconSize} fill={fill} />
            </div>
            {isHovering && <div className={classnames(styles.homeButtonLabel, { [styles.disabledHomeButtonLabel]: disabled })}>{label}</div>}
        </button>
    );
};

interface DocumentHomeButtonProps {
    disabled: boolean;
    label: string;
    icon: React.FC;
    testId: string;
    path?: string;
    fill?: string;
    onDisabledClick?: () => void;
}

export const HomeButtonTileAdd: React.FC<DocumentHomeButtonProps> = ({ disabled, onDisabledClick, label, icon, testId }) => {
    const dispatch = useAppDispatch();
    const onClick = useCallback(() => disabled ? onDisabledClick : dispatch(toggleUploadModal(true)), [disabled, onDisabledClick, dispatch]);
    return (
        <HomeButton label={label} icon={icon} onClick={onClick} testId={testId} disabled={disabled} />
    );
};

export const HomeButtonTile: React.FC<DocumentHomeButtonProps> = ({ disabled, onDisabledClick, path, label, icon, testId, fill }) => <HomeButton path={path} label={label} icon={icon} testId={testId} fill={fill} disabled={disabled} onClick={onDisabledClick} />;
