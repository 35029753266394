import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { OfficeBuilding } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { DocumentAnalyticsChart, getEntitySpreadAnalytics, getFetchingAnalytics, getIsFetchingMyCompanies } from '../store';
import { PieChartWrapper } from './PieChartWrapper';

interface EntitySpreadChartProps {
    dimensions?: {
        height: number;
        width: number;
    }
    analyticsSpinner: boolean;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    tileInView: DocumentAnalyticsChart | null;
}

export const EntitySpreadChart: React.FC<EntitySpreadChartProps> = ({ dimensions, analyticsSpinner, setTileInView, tileInView }) => {
    const entitySpreadAnalytics = useAppSelector(getEntitySpreadAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const fetchingMyCompanies = useAppSelector(getIsFetchingMyCompanies);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(DocumentAnalyticsChart.ENTITY_SPREAD) || fetchingMyCompanies, [fetchingAnalytics, fetchingMyCompanies]);

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <PieChartWrapper
            data={entitySpreadAnalytics}
            title='Entity Spread'
            description='A visualisation of the entities which you have documents related to within Ark51'
            dimensions={dimensions}
            icon={OfficeBuilding}
            testId='entity-spread'
            colors='amethystFrench'
            tileInView={tileInView}
            setTileInView={setTileInView}
            currentTile={DocumentAnalyticsChart.ENTITY_SPREAD}
            showExpandIcon={false}
        />
    );
};
