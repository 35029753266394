import React from 'react';
import { ColDef, GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { flow, unset } from 'lodash/fp';

import styles from './DropdownLists.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Icon } from '../../shared/icon/Icon';
import { Pencil } from '../../shared/icons';
import { DropdownList, DropdownListDB, fetchAllDropdownListsStarted, getAllDropdownLists, toggleModal } from './store';
import { Table, dateFormatter } from '../../shared/table';
import { useFetchStarted } from '../../../hooks/useFetchStarted';

const stripDropdownList = (list: DropdownListDB): DropdownList => flow(
    unset('clientId'),
    unset('createdBy'),
    unset('createdDate'),
    unset('modifiedBy'),
    unset('modifiedDate')
)(list);

const IconCell = (params: ICellRendererParams) => {
    const dispatch = useAppDispatch();
    const strippedDropdownList = stripDropdownList(params.data);

    const editList = () => dispatch(toggleModal(true, strippedDropdownList));
    return (
        <div className={styles.editDropdownListButton} onClick={editList}>
            <Icon fontSize={20} icon={Pencil} />
        </div>
    );
};

export const DropdownListTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDropdownListsStarted()]);
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };
    const columnDefs: ColDef[] = [
        { headerName: 'Name', field: 'name', cellStyle: defaultCellStyle, width: 0.25 },
        { headerName: 'Description', field: 'description', cellStyle: defaultCellStyle, width: 0.45 },
        { headerName: 'Created Date', field: 'createdDate', cellStyle: defaultCellStyle, width: 0.25, valueFormatter: dateFormatter },
        { headerName: '', field: '', cellRenderer: 'iconCellRenderer', width: 0.05 },
    ];

    const gridOptions: GridOptions = {
        components: {
            iconCellRenderer: IconCell
        },
        paginationPageSize: 20
    };

    const onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => dispatch(toggleModal(true, stripDropdownList(data)));

    return (
        <Table
            rowData={dropdownLists}
            colDefs={columnDefs}
            gridOptions={gridOptions}
            percentageWidths={true}
            onRowDoubleClicked={onRowDoubleClicked}
            testId='dropdown-lists'
        />
    );
};
