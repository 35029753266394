import { action } from 'typesafe-actions';

import { DatasetDefinition } from '../../../datasets/store';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';
import { MyRiskTolerance, RiskAssociated, RiskAssociatedValue, RiskTolerance, SelectedRiskField } from '../../risk-tolerance/store';
import { HiddenField, HiddenFields, HiddenSection, MyDatasetActionTypes, MyDatasetView, MyDatasets, MyDatasetsOpenFieldSection } from './types';

export const fetchAllMyDatasetsStarted = () => action(MyDatasetActionTypes.FETCH_ALL_MY_DATASETS_STARTED);
export const fetchAllMyDatasetsSuccessful = (myDatasets: MyDatasets[]) => action(MyDatasetActionTypes.FETCH_ALL_MY_DATASETS_SUCCESSFUL, myDatasets);
export const fetchAllMyDatasetsFailed = (error: string) => action(MyDatasetActionTypes.FETCH_ALL_MY_DATASETS_FAILED, error);

export const setMyDatasetView = (view: MyDatasetView, datasetId: number | null, redirect = true) => action(MyDatasetActionTypes.MY_DATASET_VIEW, { view, datasetId, redirect });

export const fetchMyDatasetDefinitionsStarted = (datasetId: number) => action(MyDatasetActionTypes.FETCH_MY_DATASET_DEFINITIONS_STARTED, datasetId);
export const fetchMyDatasetDefinitionsSuccessful = (datasetDefinitions: DatasetDefinition[], hiddenFields: HiddenFields, riskTolerance: (RiskTolerance | MyRiskTolerance)[], documentHiddenFields: DocumentSpecificHiddenFields) => action(MyDatasetActionTypes.FETCH_MY_DATASET_DEFINITIONS_SUCCESSFUL, { datasetDefinitions, hiddenFields, riskTolerance, documentHiddenFields });
export const fetchMyDatasetDefinitionsFailed = (error: string) => action(MyDatasetActionTypes.FETCH_MY_DATASET_DEFINITIONS_FAILED, error);

export const removeOpenFieldSection = (fieldSection: MyDatasetsOpenFieldSection) => action(MyDatasetActionTypes.REMOVE_MY_DATASETS_OPEN_FIELD_SECTION, fieldSection);
export const addOpenFieldSection = (fieldSection: MyDatasetsOpenFieldSection) => action(MyDatasetActionTypes.ADD_MY_DATASETS_OPEN_FIELD_SECTION, fieldSection);
export const removeAllFieldSections = () => action(MyDatasetActionTypes.REMOVE_ALL_MY_DATASETS_FIELD_SECTIONS);

export const resetMyDatasets = () => action(MyDatasetActionTypes.RESET_MY_DATASETS);

export const toggleModalDatasetId = (datasetId: number | null) => action(MyDatasetActionTypes.TOGGLE_MODAL_DATASET_ID, datasetId);

export const hiddenFieldsHaveUpdated = (hasUpdated: boolean) => action(MyDatasetActionTypes.HIDDEN_FIELDS_HAVE_UPDATED, hasUpdated);

export const toggleHiddenField = (datasetId: number, hiddenField: HiddenField | HiddenSection) => action(MyDatasetActionTypes.TOGGLE_HIDDEN_FIELDS, { datasetId, hiddenField });

export const saveHiddenFieldsStarted = () => action(MyDatasetActionTypes.SAVE_HIDDEN_FIELDS_STARTED);
export const saveHiddenFieldsSuccessful = () => action(MyDatasetActionTypes.SAVE_HIDDEN_FIELDS_SUCCESSFUL);
export const saveHiddenFieldsFailed = (error: string) => action(MyDatasetActionTypes.SAVE_HIDDEN_FIELDS_FAILED, error);

export const setCurrentRiskToleranceField = (riskField: SelectedRiskField | null) => action(MyDatasetActionTypes.SET_CURRENT_RISK_TOLERANCE_FIELD, riskField);
export const updateRiskFieldConfig = (riskAssociated: RiskAssociated, value: RiskAssociatedValue, selectedCurrency?: string) => action(MyDatasetActionTypes.UPDATE_RISK_FIELD_CONFIG, { riskAssociated, value, selectedCurrency });
export const updateRiskFieldWeighting = (weighting: number) => action(MyDatasetActionTypes.UPDATE_RISK_FIELD_WEIGHTING, weighting);

export const toggleMyRiskField = (datasetId: number, fieldId: string) => action(MyDatasetActionTypes.TOGGLE_RISK_FIELD, { datasetId, fieldId });

export const saveRiskToleranceStarted = () => action(MyDatasetActionTypes.SAVE_RISK_TOLERANCE_STARTED);
export const saveRiskToleranceSuccessful = (newRiskTolerance: (RiskTolerance | MyRiskTolerance)[]) => action(MyDatasetActionTypes.SAVE_RISK_TOLERANCE_SUCCESSFUL, newRiskTolerance);
export const saveRiskToleranceFailed = () => action(MyDatasetActionTypes.SAVE_RISK_TOLERANCE_FAILED);

export const setRiskToleranceUpdated = (hasUpdated: boolean) => action(MyDatasetActionTypes.SET_RISK_TOLERANCE_UPDATED, hasUpdated);

export const toggleUnsavedChangesModal = (isOpen: boolean) => action(MyDatasetActionTypes.TOGGLE_UNSAVED_CHANGES_MODAL, isOpen);
