import React, { useCallback, useEffect, useMemo } from 'react';
import { snakeCase } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { FeaturePermission } from '../admin/users/store';
import { getUserHasOneFeaturePermissionNoAdmin } from '../auth/login/store';
import { toggleUserNotPermittedModalOpen } from '../home/store';
import styles from './DoraHome.module.scss';
import { DoraHomeButton } from './DoraHomeButton';
import { doraIcons } from './data-management/DataManagementWrapper';
import { DataManagementTile, setSelectedTab } from './data-management/store';

interface DoraHomePageTile {
    id: DataManagementTile;
    column: number;
    marginBottom?: string;
}

export const DoraHome: React.FC = () => {
    const dispatch = useAppDispatch();

    const userHasDoraFeaturePermissions = useAppSelector(getUserHasOneFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS, FeaturePermission.DORA_READ_ONLY]));
    const openUserNotPermittedModule = useCallback(() => dispatch(toggleUserNotPermittedModalOpen(true)), [dispatch]);
    const selectTab = useCallback((tab: DataManagementTile | null) => dispatch(setSelectedTab(tab)), [dispatch]);

    useEffect(() => {
        selectTab(null);
        if (!userHasDoraFeaturePermissions) {
            openUserNotPermittedModule();
        }
    }, [userHasDoraFeaturePermissions, openUserNotPermittedModule, selectTab]);

    const doraTiles: DoraHomePageTile[] = useMemo(() => [
        {
            id: DataManagementTile.FUNCTIONS_PERFORMED,
            marginBottom: '20px',
            column: 1
        },
        {
            id: DataManagementTile.MY_COMPANIES,
            marginBottom: '20px',
            column: 2
        },
        {
            id: DataManagementTile.RELEVANT_THIRD_PARTY_COMPANIES,
            column: 1
        },
        {
            id: DataManagementTile.SUPPLY_CHAIN,
            column: 2
        }
    ], []);

    const columns = useMemo(() => [{ column: 1 }, { column: 2 }], []);
    const sortColumns = useCallback((columnId: number) => doraTiles.filter(({ column }) => column === columnId), [doraTiles]);

    return (
        <div className={styles.doraWrapper}>
            <div className={styles.doraTilesWrapper}>
                {columns.map(({ column }) => (
                    <div key={column} className={styles.doraTilesColumnWrapper}>
                        {sortColumns(column).map(({ id, marginBottom }) => (
                            <div className={styles.doraTileWrapper} style={{ marginBottom }} key={id}>
                                <DoraHomeButton onClick={() => selectTab(id)} icon={doraIcons[id]} label={id} testId={snakeCase(id)} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
