import React, { useMemo } from 'react';
import { isUndefined, min } from 'lodash/fp';

import { useWindowResize } from '../../../../hooks/useWindowResize';
import styles from '../../Home.module.scss';
import { Icon } from '../../../shared/icon/Icon';
import Attestations from './Attestations.png';
import EntitySpread from './EntitySpread.png';
import DocumentSpread from './DocumentSpread.png';

interface HomeConfigTileProps {
    label?: string;
    icon?: React.FC;
    chart?: JSX.Element;
    testId?: string;
    fill?: string;
}

export const HomeConfigTile: React.FC<HomeConfigTileProps> = ({ label, icon, chart, testId, fill }) => {
    const [screenWidth, screenHeight] = useWindowResize();
    const iconSize = useMemo(() => min([(screenHeight * 0.9) / 6, (screenWidth * 0.9) / 6, 250])!, [screenHeight, screenWidth]);
    return (
        <div
            className={styles.homeConfigTile}
            data-testid={`home-screen-config-tile-${testId}`}
        >
            <div className={styles.homeConfigTileIcon}>
                {icon ? <Icon icon={icon} fontSize={iconSize} fill={fill} /> : chart}
            </div>
            {!isUndefined(label) && <div className={styles.homeConfigTileLabel}>{label}</div>}
        </div>
    );
};

const AnalyticsTile: React.FC<{ image: string; }> = ({ image }) => <img className={styles.homeScreenTileScreenshot} src={image} draggable={false} />;
export const AttestationsWidget: React.FC = () => <AnalyticsTile image={Attestations} />;
export const EntitySpreadWidget: React.FC = () => <AnalyticsTile image={EntitySpread} />;
export const DocumentSpreadWidget: React.FC = () => <AnalyticsTile image={DocumentSpread} />;
