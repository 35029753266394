import React, { useMemo } from 'react';
import classnames from 'classnames';
import { isNull, isUndefined, uniq } from 'lodash/fp';
import { Options } from 'react-select';

import { DatasetFieldType, SingleDatasetField } from '../../../../../datasets/store';
import { DocumentSpecificHiddenFields, DatasetsOpenFieldSection, toggleDocumentHiddenField, HiddenDocumentField } from '../../../store';
import { useAppDispatch } from '../../../../../../hooks/react-redux';
import { OpenFieldType } from '../../../../../datasets/instances/store';
import { CaretDown, CaretSide } from '../../../../../shared/icons';
import { InformationTooltip } from '../../../../../shared/tooltip';
import { DropdownOption } from '../../../../../shared/dropdown/Dropdown';
import { useSingleHiddenDocumentFields } from '../../../../../../hooks/useHiddenSingleFields';
import styles from '../../ConfigureDatasets.module.scss';
import { Icon } from '../../../../../shared/icon/Icon';
import { DatasetDocumentSelect } from '../../DatasetDocumentSelect';
import { getAdminFormDatasetField, getSingleFieldLabel } from '../../../../../shared/datasets/AdminDatasetFields';

interface DocumentDatasetSingleFieldProps {
    field: SingleDatasetField;
    sectionId: string;
    width?: number;
    isLastFieldInGroup?: boolean;
    isLastFieldInSection?: boolean;
    groupIndex?: number;
    datasetOnlySection?: boolean;
    datasetId: number;
    parents: number;
    toggleSection: (fieldSection: DatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: DatasetsOpenFieldSection) => boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: DocumentSpecificHiddenFields;
    hiddenDocumentNameIds?: number[];
    documentNames: DropdownOption[];
    previewSelectedDocument: number | null;
}

export const DocumentDatasetSingleField: React.FC<DocumentDatasetSingleFieldProps> = ({
    field,
    width = 100,
    isLastFieldInGroup = false,
    isLastFieldInSection = false,
    sectionId,
    groupIndex,
    datasetOnlySection = false,
    datasetId,
    parents,
    toggleSection,
    getSectionOpen,
    isPreview,
    modalInstance,
    hiddenFields,
    hiddenDocumentNameIds,
    documentNames,
    previewSelectedDocument
}) => {
    const dispatch = useAppDispatch();
    const { label, description, refLabel, settings, type, id } = field;

    const wysiwygFieldOpen = useMemo(() => !isUndefined(settings.isCollapsible) && !settings.isCollapsible, [settings.isCollapsible]);
    const openField = useMemo(() => ({ sectionId, datasetId: datasetId.toString() || '', fieldId: id!, type: OpenFieldType.FIELD, groupIndex }), [groupIndex, datasetId, id, sectionId]);
    const fieldOpen = useMemo(() => getSectionOpen(openField) || datasetOnlySection || wysiwygFieldOpen, [getSectionOpen, openField, datasetOnlySection, wysiwygFieldOpen]);
    const fieldOpenIcon = fieldOpen ? CaretDown : CaretSide;

    const isCollapsible = settings.isCollapsible || [DatasetFieldType.DATASET, DatasetFieldType.CALCULATOR].includes(type);
    const showFieldLabel = type !== DatasetFieldType.LABEL && !datasetOnlySection;
    const isLabelField = type === DatasetFieldType.LABEL;
    const singleFieldLabel = getSingleFieldLabel(label, type, id, isCollapsible, toggleSection, openField);

    const setDocumentsForField = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: number[] = [];
        if (!isNull(dropdownValue)) {
            const applicableDocuments = (dropdownValue as Options<DropdownOption>).map(({ value }) => value);
            value = documentNames.filter(({ value }) => !applicableDocuments.includes(value)).map(({ value }) => parseInt(value));
            const hiddenField: HiddenDocumentField = { fieldId: id!, type: OpenFieldType.FIELD, documentNameIds: value, sectionId };
            dispatch(toggleDocumentHiddenField(datasetId, hiddenField));
        }
    };

    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId] || [], [datasetId, hiddenFields]);
    const hiddenSingleFields = useSingleHiddenDocumentFields(currentHiddenDatasetFields);
    const documentsHiddenForSection = currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(hiddenField => hiddenField.sectionId === sectionId)?.documentNameIds || [];
    const documentsHiddenForField = hiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds || [];
    const parentHiddenDocuments = !isUndefined(hiddenDocumentNameIds) ? hiddenDocumentNameIds : [];
    const allDocumentsHiddenForField = uniq([...documentsHiddenForField, ...documentsHiddenForSection, ...parentHiddenDocuments]);
    const fieldDocuments = allDocumentsHiddenForField.length > 0 ? documentNames.filter(({ value }) => !allDocumentsHiddenForField.includes(parseInt(value))) : documentNames;
    const documentNameOptions = [...parentHiddenDocuments, ...documentsHiddenForSection].length > 0 ? documentNames.map(option => [...parentHiddenDocuments, ...documentsHiddenForSection].includes(parseInt(option.value)) ? ({ ...option, disabled: true }) : option) : documentNames;
    const fieldIsHidden = useMemo(() => !isNull(previewSelectedDocument) && allDocumentsHiddenForField.includes(previewSelectedDocument) && !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [previewSelectedDocument, allDocumentsHiddenForField, hiddenSingleFields, id]);

    if (isPreview && fieldIsHidden) {
        return null;
    }

    return (
        <div
            className={classnames(styles.singleFieldWrapper, { [styles.lastFieldInGroup]: isLastFieldInGroup, [styles.isLabelField]: isLabelField && !isLastFieldInSection })}
            style={{ width: `${width}%` }}
            data-testid={`form-field-${id}-wrapper`}
        >
            {showFieldLabel &&
                <div className={classnames(styles.fieldTitleWrapper, { [styles.fieldCanCollapse]: isCollapsible })}>
                    {isCollapsible &&
                        <div className={styles.fieldOpenWrapper} onClick={() => toggleSection(openField)} data-testid='collapsible-field'>
                            <Icon icon={fieldOpenIcon} fontSize={15} />
                        </div>
                    }
                    {singleFieldLabel}
                    {description && <InformationTooltip content={description} />}
                    {refLabel && settings.showRef && <InformationTooltip content={refLabel} label='?' />}
                    {settings.showClause && <InformationTooltip content='Add personalised clause label here' label='cl' />}
                    {!isPreview && <DatasetDocumentSelect
                        selectedDocuments={fieldDocuments}
                        onChange={setDocumentsForField}
                        documentNames={documentNameOptions}
                        label={label}
                    />}
                </div>
            }
            {getAdminFormDatasetField(
                field,
                datasetId,
                fieldOpen,
                parents,
                toggleSection,
                getSectionOpen,
                sectionId,
                isPreview,
                false,
                groupIndex,
                modalInstance,
                undefined,
                allDocumentsHiddenForField,
                documentNames
            )}
        </div>
    );
};
