import { Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { noop } from 'lodash/fp';

import { createRootReducer, ApplicationState } from './rootReducer';
import rootSaga from './rootSaga';

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory({ getUserConfirmation: noop }) });

const createStore = (): Store<ApplicationState> => {
    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore({
        reducer: createRootReducer(routerReducer),
        middleware: [routerMiddleware, sagaMiddleware]
    });
    sagaMiddleware.run(rootSaga);
    return store;
};

export const store = createStore();
export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
