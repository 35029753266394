import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { Spinner } from '../spinner/Spinner';
import styles from './SharedAnalytics.module.scss';

export interface AnalyticsTile {
    id: string;
    chart: JSX.Element | null;
    column: number;
    marginBottom?: string;
    fullColumnTile?: boolean;
}

interface AnalyticsProps {
    tileInView: JSX.Element | null;
    dropdownSelect?: JSX.Element | null;
    charts: AnalyticsTile[];
    testId: string;
    header?: string;
    showSpinner: boolean;
    analyticsColumns?: number;
    analyticsRows?: number;
}

export const Analytics: React.FC<AnalyticsProps> = ({ testId, tileInView, charts, header = 'Analytics', showSpinner, dropdownSelect = null, analyticsColumns = 3, analyticsRows = 2 }) => {

    const columns = useMemo(() => Array(analyticsColumns).fill(1).map((columnNumber, i) => ({ column: columnNumber + i })), [analyticsColumns]);

    const getAnalyticsByColumn = useCallback((columnId: number) => charts.filter(({ column }) => column === columnId), [charts]);
    const getHeight = useCallback((fullColumnTile: boolean) => fullColumnTile ? 'calc(100% - 14px)' : `calc(${100 / analyticsRows}% - 12px)`, [analyticsRows]);

    if (showSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.analyticsWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.analyticsHeader}>
                <div className={styles.analyticsTitle} data-testid={`${testId}-header`}>{header}</div>
                {dropdownSelect}
            </div>
            <div className={styles.analyticsChartsWrapper} data-testid={`${testId}-charts-wrapper`}>
                {isNull(tileInView) ? (columns.map(({ column }) => (
                    <div key={column} className={styles.analyticsColumnWrapper} style={{ width: `calc((100% / ${analyticsColumns}) - (20px / ${analyticsColumns}))` }}>
                        {getAnalyticsByColumn(column).map(({ id, chart, marginBottom, fullColumnTile = false }) => (
                            <div className={styles.chartWrapper} style={{ marginBottom, minHeight: getHeight(fullColumnTile), height: getHeight(fullColumnTile) }} key={id}>
                                {chart}
                            </div>
                        ))}
                    </div>
                ))) : (
                    <div className={styles.wholePageChartWrapper}>
                        {tileInView}
                    </div>
                )}
            </div>
        </div>
    );
};
