import { combineReducers } from 'redux';

import { doraFunctionReducer, DoraFunctionState } from '../functions/store';
import { DoraMyCompaniesState, doraMyCompaniesReducer } from '../my-companies/store';
import { doraSupplyChainReducer, DoraSupplyChainState } from '../supply-chain/store';
import { doraThirdPartyReducer, DoraThirdPartyState } from '../third-party-companies/store';
import { doraDataManagementReducer, DoraDataManagementState } from '../data-management/store';
import { doraAnalyticsReducer, DoraAnalyticsState } from '../analytics/store';
import { doraReportingReducer, DoraReportingState } from '../reporting/store';

export interface DoraState {
    dataManagement: DoraDataManagementState;
    functions: DoraFunctionState;
    myCompanies: DoraMyCompaniesState;
    thirdParty: DoraThirdPartyState;
    supplyChain: DoraSupplyChainState;
    analytics: DoraAnalyticsState,
    reporting: DoraReportingState
}

export const doraReducer = combineReducers({
    dataManagement: doraDataManagementReducer,
    functions: doraFunctionReducer,
    myCompanies: doraMyCompaniesReducer,
    thirdParty: doraThirdPartyReducer,
    supplyChain: doraSupplyChainReducer,
    analytics: doraAnalyticsReducer,
    reporting: doraReportingReducer
});
