import React, { useMemo, useCallback } from 'react';
import { isNull, noop } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { DocumentAnalyticsChart, getEntityDocumentsRiskTolerance, getDocumentRiskTolerance, getFetchingAnalytics, EntityDocumentRiskView, getEntityDocumentRiskToleranceView, setEntityDocumentRiskToleranceView, getSelectedMyCompanyEntity } from '../../store';
import { useFetchStarted } from '../../../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted } from '../../../../admin/dropdown-lists/store';
import { RiskToleranceChart } from './RiskToleranceChart';

interface EntityDocumentRiskToleranceChartWrapperProps {
    tileInView: null | DocumentAnalyticsChart;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
    dimensions?: {
        height: number;
        width: number;
    };
}

export const EntityDocumentRiskToleranceChartWrapper: React.FC<EntityDocumentRiskToleranceChartWrapperProps> = ({ setTileInView, tileInView, analyticsSpinner, dimensions }) => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDropdownListsStarted()]);

    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const documentRiskTolerance = useAppSelector(getDocumentRiskTolerance);
    const entityDocumentsRiskTolerance = useAppSelector(getEntityDocumentsRiskTolerance);
    const riskToleranceView = useAppSelector(getEntityDocumentRiskToleranceView);
    const selectedCompanyEntityId = useAppSelector(getSelectedMyCompanyEntity);

    const entityId = useMemo(() => !isNull(selectedCompanyEntityId) ? selectedCompanyEntityId : undefined, [selectedCompanyEntityId]);
    const chartIsFullScreen = useMemo(() => tileInView === DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE, [tileInView]);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE) || analyticsSpinner, [fetchingAnalytics, analyticsSpinner]);
    const documentTableView = useCallback((view: EntityDocumentRiskView) => chartIsFullScreen && riskToleranceView === view, [chartIsFullScreen, riskToleranceView]);
    const showDocumentTable = useMemo(() => documentTableView(EntityDocumentRiskView.ENTITY_DOCUMENTS), [documentTableView]);
    const showDocumentBreakdown = useMemo(() => documentTableView(EntityDocumentRiskView.ENTITY_DOCUMENT_BREAKDOWN), [documentTableView]);
    const showDocumentDataOptions = useMemo(() => documentTableView(EntityDocumentRiskView.ENTITY_DATASETS), [documentTableView]);
    const showDocumentFieldBreakdown = useMemo(() => documentTableView(EntityDocumentRiskView.ENTITY_DATASET_FIELD), [documentTableView]);

    const getDocumentRiskToleranceAnswers = useCallback(() => isNull(documentRiskTolerance) ? noop : dispatch(setEntityDocumentRiskToleranceView(EntityDocumentRiskView.ENTITY_DOCUMENT_BREAKDOWN)), [documentRiskTolerance, dispatch]);
    const setDocumentsView = useCallback(() => dispatch(setEntityDocumentRiskToleranceView(EntityDocumentRiskView.ENTITY_DOCUMENTS)), [dispatch]);

    const switchRiskTypeLabel = useMemo(() => riskToleranceView !== EntityDocumentRiskView.ENTITY_DATASETS ? 'Agreement Type' : 'Document Table', [riskToleranceView]);
    const switchRiskTypeValue = useCallback(() => riskToleranceView === EntityDocumentRiskView.ENTITY_DATASETS ? dispatch(setEntityDocumentRiskToleranceView(EntityDocumentRiskView.ENTITY_DOCUMENTS)) : dispatch(setEntityDocumentRiskToleranceView((EntityDocumentRiskView.ENTITY_DATASETS))), [riskToleranceView, dispatch]);

    return (
        <RiskToleranceChart
            tile={DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE}
            setTileInView={setTileInView}
            tileInView={tileInView}
            dimensions={dimensions}
            allDocumentsRiskTolerance={entityDocumentsRiskTolerance}
            chartIsFullScreen={chartIsFullScreen}
            showSpinner={showSpinner}
            showDocumentTable={showDocumentTable}
            showDocumentBreakdown={showDocumentBreakdown}
            showDocumentDataOptions={showDocumentDataOptions}
            showDocumentFieldBreakdown={showDocumentFieldBreakdown}
            switchRiskTypeValue={switchRiskTypeValue}
            getDocumentRiskToleranceAnswers={getDocumentRiskToleranceAnswers}
            setDocumentsView={setDocumentsView}
            switchRiskTypeLabel={switchRiskTypeLabel}
            entityId={entityId}
        />
    );
};
