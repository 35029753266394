import React, { useCallback, useMemo, useState } from 'react';

import { Icon } from '../../shared/icon/Icon';
import { Document, SaveFile } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Toggle } from '../../shared/toggle';
import styles from './DatasetInstance.module.scss';

interface ConfirmSaveModalProps {
    isOpen: boolean;
    isUpdating: boolean;
    save: (isDraft: boolean) => void;
    cancel: () => void;
    description: string;
    showDocument: boolean;
}

interface IncompleteInstanceProps {
    description: string;
    showDocument: boolean;
    instanceComplete: boolean;
    setInstanceComplete: (value: boolean) => void;
}

const IncompleteInstance: React.FC<IncompleteInstanceProps> = ({ showDocument, description, instanceComplete, setInstanceComplete }) => (
    <div className={styles.incompleteInstanceWrapper}>
        <div className={styles.newDocumentWrapper}>
            {showDocument ? (
                <div className={styles.newDocumentHeader}>
                    <Icon icon={Document} />
                    <div className={styles.newDocumentTitle}>{description}</div>
                </div>
            ) : (
                <div data-testid='no-document-save-warning' className={styles.emptyDocumentPlaceholder}>You have not uploaded a document with these changes</div>
            )}
        </div>
        <div className={styles.completeWarningWrapper}>
            <div className={styles.completeWarningTitle} data-testid='complete-save-warning'>Is this dataset ready to complete?</div>
            <Toggle checked={instanceComplete} onChange={setInstanceComplete} />
        </div>
        <div className={styles.completeWarningMessage}>Once completed, you will not be able to make any further amendments</div>
        <div className={styles.completeWarningMessage}>If you are unsure, please leave unchecked</div>
    </div>
);

const UpdatedInstance: React.FC = () => (
    <div className={styles.updatedInstanceWrapper}>
        <div data-testid='updated-instance-save-warning' className={styles.updatedInstanceWarning}>Are you happy with the updated changes you have made?</div>
        <div className={styles.updatedInstanceInfo}>These changes will also be applied to any future amendments</div>
    </div>
);

export const ConfirmSaveModal: React.FC<ConfirmSaveModalProps> = ({ isOpen, save, cancel, isUpdating, showDocument, description }) => {
    const [instanceComplete, setInstanceComplete] = useState<boolean>(false);
    const saveAsDraft = useMemo(() => !(isUpdating || instanceComplete), [isUpdating, instanceComplete]);
    const saveInstance = useCallback(() => save(saveAsDraft), [saveAsDraft, save]);
    return (
        <ConfirmationModal
            isOpen={isOpen}
            confirm={saveInstance}
            closeModal={cancel}
            confirmLabel={instanceComplete ? 'Complete & Save' : 'Save'}
            closeLabel='Cancel'
        >
            <div className={styles.confirmSaveModalWrapper}>
                <ModalHeader
                    testId='confirm-save'
                    label='Are you sure you want to save?'
                    icon={SaveFile}
                />
                {isUpdating ? <UpdatedInstance /> :
                    <IncompleteInstance
                        showDocument={showDocument}
                        description={description}
                        instanceComplete={instanceComplete}
                        setInstanceComplete={setInstanceComplete}
                    />}
            </div>
        </ConfirmationModal>
    );
};
