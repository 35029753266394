import { geoMercator, geoPath, GeoPermissibleObjects, GeoProjection, select } from 'd3';
import React, { useEffect, useRef, useMemo } from 'react';

import { Country } from '../../documents/analytics/store';
import styles from './NettingEngine.module.scss';

const {
    primaryBorder,
    grey,
} = styles;

export interface NettingEngineMapOutline {
    country: Country;
    fillColour?: string;
    svgHeight: number;
    svgWidth: number;
}

export const NettingEngineMapOutline: React.FC<NettingEngineMapOutline> = ({
    country,
    fillColour = grey,
    svgHeight,
    svgWidth
}) => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    const data: Country[] = useMemo(() => [country], [country]);

    useEffect(() => {
        const svg = select<SVGSVGElement, unknown>(svgRef.current!);

        const projection: GeoProjection = geoMercator()
            .scale(1)
            .translate([0, 0]);

        const countryLines = geoPath().projection(projection);
        const [[x0, y0], [x1, y1]] = countryLines.bounds(country as GeoPermissibleObjects);
        const scale = 1 / Math.max(((x1 - x0) / svgWidth) * 1.2, ((y1 - y0) / svgHeight) * 1.2);

        projection
            .scale(scale)
            .translate([(svgWidth - scale * (x1 + x0)) / 2, (svgHeight - scale * (y1 + y0)) / 2]);

        const map = svg
            .selectAll('path')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'map')
            .attr('id', 'opinions-jurisdiction')
            .attr('overflow', 'hidden');

        map
            .append('path')
            .attr('d', (d: Country, e) => countryLines(d as GeoPermissibleObjects, e))
            .attr('fill', fillColour)
            .attr('stroke', primaryBorder)
            .attr('class', 'primary');

        return () => {
            // Anything added to the svg on mount via .append must be removed on unmount to stop new elements being added each render
            svg.selectAll('.map').remove();
        };

    }, [fillColour, country, data, svgWidth, svgHeight]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <svg ref={svgRef} style={{ height: '100%', width: '100%', overflow: 'hidden' }} />
        </div>
    );
};
