import { isUndefined } from 'lodash/fp';
import React from 'react';

import { CustomTooltip } from '../tooltip';
import styles from './Table.module.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const ColourCell: React.FC<{ value: any, valueFormatted: string[] }> = ({ value, valueFormatted }) => {
    if (isUndefined(valueFormatted) && isUndefined(value)) {
        return null;
    }

    const backgroundColor = valueFormatted[0] || '';
    const overlayText = valueFormatted[1] || '';

    return (
        <CustomTooltip overlayText={overlayText} placement='top'>
            <div className={styles.colourCellWrapper} style={{ backgroundColor: backgroundColor }} />
        </CustomTooltip>
    );
};
