import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isNull } from 'lodash';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Button } from '../../shared/button/Button';
import { Spinner } from '../../shared/spinner/Spinner';
import { getAllAgreementTypes, getAllDocumentNames } from '../documents/store';
import styles from './AIManager.module.scss';
import { addNewDocumentQuery, DocumentQuery, getCurrentDocumentQueries, getDocumentQuerySaveDisabled, getIsEditingDocumentQueries, getIsFetchingDocumentQueries, getLastUpdatedOrder, getQueriesUpdated, getSavedDocumentQueries, getSelectedAIAgreementTypeId, saveQueryOrderStarted, saveUpdatedQueriesStarted, setIsEditingDocumentQueries, setLastUpdatedOrder, setQueryOrder, setSelectedAIAgreementType } from './store';
import { AIQueryOrderModal } from './AIQueryOrderModal';
import { UnsavedChangesModal } from '../../shared/modal/UnsavedChangesModal';
import { AIQuery } from './AIQuery';

export const AIQueries: React.FC = () => {
    const dispatch = useAppDispatch();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState<boolean>(false);

    const isFetchingQueries = useAppSelector(getIsFetchingDocumentQueries);
    const selectedAgreementTypeId = useAppSelector(getSelectedAIAgreementTypeId);
    const agreementTypes = useAppSelector(getAllAgreementTypes);
    const documentNames = useAppSelector(getAllDocumentNames);
    const currentQueries = useAppSelector(getCurrentDocumentQueries);
    const savedQueries = useAppSelector(getSavedDocumentQueries);
    const queriesUpdated = useAppSelector(getQueriesUpdated);
    const isEditing = useAppSelector(getIsEditingDocumentQueries);
    const { saveDisabled, saveDisabledTooltip } = useAppSelector(getDocumentQuerySaveDisabled);
    const lastUpdatedOrder = useAppSelector(getLastUpdatedOrder);

    const goBack = useCallback(() => dispatch(setSelectedAIAgreementType(null)), [dispatch]);

    const selectedAgreementType = useMemo(() => agreementTypes.find(({ agreementTypeId }) => agreementTypeId === selectedAgreementTypeId)?.name || '', [agreementTypes, selectedAgreementTypeId]);
    const aiQueriesHeader = useMemo(() => `${selectedAgreementType} (${currentQueries.length} Queries)`, [selectedAgreementType, currentQueries]);
    const relevantDocumentNames = useMemo(() => documentNames.filter(({ agreementTypeId }) => agreementTypeId === selectedAgreementTypeId).map(({ documentName, documentNameId }) => ({ documentName, documentNameId })), [documentNames, selectedAgreementTypeId]);

    const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);
    const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);
    const saveOrder = useCallback(() => dispatch(saveQueryOrderStarted()), [dispatch]);
    const setOrder = useCallback((queryOrder: DocumentQuery[]) => dispatch(setQueryOrder(queryOrder)), [dispatch]);

    const setIsEditing = useCallback((value: boolean) => dispatch(setIsEditingDocumentQueries(value)), [dispatch]);
    const saveQueries = useCallback(() => dispatch(saveUpdatedQueriesStarted()), [dispatch]);
    const cancel = useCallback(() => {
        if (!currentQueries.length) {
            goBack();
            setIsEditing(false);
        }
        queriesUpdated ? setUnsavedChangesModalOpen(true) : setIsEditing(false);
    }, [queriesUpdated, setIsEditing, currentQueries, goBack]);

    const confirmAndClose = useCallback(() => {
        if (!savedQueries.length) {
            goBack();
        }
        setUnsavedChangesModalOpen(false);
        setIsEditing(false);
    }, [setIsEditing, savedQueries, goBack]);

    const scrollToQuery = useCallback((queryOrder: number) => {
        const query = document.getElementById(`ai-query-${queryOrder}`);
        query?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, []);

    const addNewQuery = useCallback(() => {
        dispatch(addNewDocumentQuery());
        const queryOrder = currentQueries.length + 1;
        setTimeout(() => scrollToQuery(queryOrder), 100);
    }, [dispatch, scrollToQuery, currentQueries]);

    useEffect(() => {
        if (!isNull(lastUpdatedOrder)) {
            setTimeout(() => scrollToQuery(lastUpdatedOrder), 100);
            dispatch(setLastUpdatedOrder(null));
        }
    }, [lastUpdatedOrder, scrollToQuery, dispatch]);

    if (isFetchingQueries) {
        return <div className={styles.aiQueriesWrapper} data-testid='ai-agreement-type-queries-wrapper'><Spinner /></div>;
    }

    return (
        <div className={styles.aiQueriesWrapper} data-testid='ai-agreement-type-queries-wrapper'>
            <div className={styles.aiQueriesTitle}>{aiQueriesHeader}</div>
            <AIQuery />
            <div className={styles.aiQueriesButtons}>
                <div className={styles.leftButtons}>
                    {!isEditing && <Button onClick={goBack} label='Back' />}
                    {isEditing && <Button onClick={cancel} label='Cancel' />}
                </div>
                {!isEditing &&
                    <div className={styles.rightButtons}>
                        {currentQueries.length > 1 && <Button label='Re-order Questions' onClick={openModal} />}
                        <Button label='Edit' onClick={() => setIsEditing(true)} />
                    </div>
                }
                {isEditing &&
                    <div className={styles.rightButtons}>
                        <Button label='Save' onClick={saveQueries} disabled={saveDisabled} disabledTooltip={saveDisabledTooltip} />
                        <Button label='New Question' onClick={addNewQuery} />
                    </div>
                }
            </div>
            <AIQueryOrderModal
                isOpen={modalOpen}
                closeModal={closeModal}
                saveOrder={saveOrder}
                setOrder={setOrder}
                savedQueryOrder={savedQueries}
                currentQueryOrder={currentQueries}
                relevantDocumentNames={relevantDocumentNames}
            />
            <UnsavedChangesModal isOpen={unsavedChangesModalOpen} closeModal={() => setUnsavedChangesModalOpen(false)} confirm={confirmAndClose} />
        </div>
    );
};
