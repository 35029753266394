import React, { useMemo } from 'react';

import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Choice, QuestionMark, WarningSign } from '../../shared/icons';
import styles from './NettingEngine.module.scss';
import { Carousel } from '../../shared/carousel/Carousel';
import { Icon } from '../../shared/icon/Icon';

const { darkRed, french, amethyst } = styles;

interface NettingEngineWhatAreTheseModalProps {
    isOpen: boolean;
    closeModal: () => void;
    sectionTenImpactsDecision: boolean;
    showCustomConclusionIcon: boolean;
}

interface WhatIsThisCardProps {
    icon: React.ElementType;
    iconColor: string;
    title: string;
    explanatoryText: string;
}

const MODAL_WIDTH = 550;
const CAROUSEL_HEIGHT = 350;

const WhatIsThisCard: React.FC<WhatIsThisCardProps> = ({ icon, explanatoryText, iconColor, title }) => (
    <div className={styles.whatIsThisCard}>
        <Icon icon={icon} color={iconColor} fontSize={200} />
        <div className={styles.whatIsThisTitle} style={{ color: iconColor }}>{title}</div>
        <div className={styles.whatIsThisText}>{explanatoryText}</div>
    </div>
);

export const NettingEngineWhatAreTheseModal: React.FC<NettingEngineWhatAreTheseModalProps> = ({ isOpen, closeModal, showCustomConclusionIcon, sectionTenImpactsDecision }) => {
    const whatAreTheseCards = useMemo(() => {
        let cards = [
            {
                icon: QuestionMark,
                iconColor: amethyst,
                title: 'Assumptions',
                explanatoryText: 'Clicking this icon will display what assumptions (if any) were made to allow the system to complete the analysis.'
            }
        ];

        if (showCustomConclusionIcon) {
            cards.unshift({
                icon: Choice,
                iconColor: french,
                title: 'Toggle custom / Ark 51 conclusions',
                explanatoryText: 'Clicking this icon will allow you to change the view between your own client sign off conclusions and the Ark51 system conclusions.'
            });
        }

        if (sectionTenImpactsDecision) {
            cards.unshift({
                icon: WarningSign,
                iconColor: darkRed,
                title: 'Section 10(a)',
                explanatoryText: 'If considerations need to be made about Section 10(a) of the ISDA Master Agreement, this icon can be clicked to learn more.'
            });
        }
        return cards;
    }, [showCustomConclusionIcon, sectionTenImpactsDecision]);

    const modalHeaderLabel = useMemo(() => `What ${whatAreTheseCards.length === 1 ? 'is this' : 'are these'}?`, [whatAreTheseCards]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            showConfirm={false}
            closeLabel='Close'
        >
            <div className={styles.whatIsThisModalWrapper}>
                <ModalHeader
                    testId='netting-engine-what-is-this-modal'
                    label={modalHeaderLabel}
                    icon={QuestionMark}
                />
                <Carousel
                    markers={whatAreTheseCards.map((_, index) => index)}
                    height={CAROUSEL_HEIGHT}
                    width={MODAL_WIDTH}
                >
                    {whatAreTheseCards.map(({ icon, explanatoryText, iconColor, title }, index) => (
                        <WhatIsThisCard key={index} icon={icon} explanatoryText={explanatoryText} iconColor={iconColor} title={title} />
                    ))}
                </Carousel>
            </div>
        </ConfirmationModal>
    );
};
