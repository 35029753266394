import { v4 } from 'uuid';

import { AttestationQuestion, AttestationAnswers, AttestationForm, FormUserAnswer, NewAttestationInstances } from './store';

export const newUserAnswer = (userAnswerId = v4(), label = ''): FormUserAnswer => ({ userAnswerId, label });

export const newAttestation = (userAnswerId = v4(), id = v4()): AttestationQuestion => ({
    id,
    question: '',
    description: '',
    config: {
        answers: AttestationAnswers.YES_NO,
        isRequired: false,
        isHidden: false,
        redFlagAnswer: null,
        alertMessage: '',
        forceRedFlagExplanation: false,
        userAnswerId
    }
});

export const defaultAttestationForm = (attestationId = v4(), userAnswerId = v4(), label = 'User'): AttestationForm => ({
    name: '',
    description: '',
    userAnswers: [newUserAnswer(userAnswerId, label)],
    isSystem: 0,
    attestations: [newAttestation(userAnswerId, attestationId)]
});

export const getSelectedAttestationForm = (isOpen: boolean, attestationForm?: AttestationForm) => {
    if (attestationForm) {
        return attestationForm;
    }
    return isOpen ? defaultAttestationForm() : null;
};

export const newAttestationInstances: NewAttestationInstances = {
    attestationFormId: null,
    instancesToCreate: []
};
