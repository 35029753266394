import React from 'react';

import styles from '../Playbook.module.scss';
import { Toggle } from '../../../shared/toggle';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getPlaybookIsDraft, toggleIsDraft } from '../store';

interface DraftPublishToggleProps {
    testId?: string;
}

export const DraftPublishToggle: React.FC<DraftPublishToggleProps> = ({ testId = 'playbook-draft-publish-toggle' }) => {
    const dispatch = useAppDispatch();
    const isDraft = useAppSelector(getPlaybookIsDraft);

    const togglePlaybookIsDraft = () => dispatch(toggleIsDraft());

    return (
        <div className={styles.draftPublishWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.draftPublishToggleWrapper}>
                <div className={styles.draftPublishLabel} data-testid={`${testId}-label`}>Would you like this Playbook to be visible to all users now?</div>
                <div className={styles.draftPublishToggle}>
                    <Toggle
                        checked={!isDraft}
                        onChange={togglePlaybookIsDraft}
                        testId={testId}
                    />
                </div>
            </div>
            <div className={styles.draftPublishInfo} data-testid={`${testId}-info`}>
                If you would like to publish a pre-release version only visible to admin users, you should deselect this toggle and publish as draft
            </div>
        </div>
    );
};
