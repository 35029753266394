import React, { useState } from 'react';
import { RawDraftContentState } from 'draft-js';

import { Position, PositionModal } from './PositionModal';
import styles from './Modal.module.scss';
import { WYSIWYG } from '../wysiwyg/WYSIWYG';

interface PopoutWysiwygModalProps {
    triggerElement: React.ReactNode;
    disabled?: boolean;
    content: RawDraftContentState | null;
    updateContent: (rawValue: RawDraftContentState) => void;
    hideToolbar?: boolean;
    width?: string;
}

export const PopoutWysiwygModal: React.FC<PopoutWysiwygModalProps> = ({ triggerElement, disabled = false, content, width='900px', hideToolbar = false, updateContent }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [position, setPosition] = useState<Position | null>(null);

    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className={styles.popoutWrapper} onClick={e => openModal(e.clientX, e.clientY)}>
                {triggerElement}
            </div>
            <PositionModal
                isOpen={modalOpen}
                testId='popout-wysiwyg-modal'
                closeModal={closeModal}
                position={position}
                height='fit-content'
                width={width}
            >
                <WYSIWYG
                    content={content}
                    updateContent={updateContent}
                    toolbarHidden={hideToolbar}
                    disabled={disabled}
                    showBorder={false}
                    showWrapperBorder={false}
                />
            </PositionModal>
        </>
    );
};
