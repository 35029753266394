import { Reducer } from 'redux';
import { isNull, set, flow, unset, isUndefined } from 'lodash/fp';

import { Clause, ClauseLibraryActionTypes, ClauseLibraryState, ClauseTableTab, ClauseTagDropdownOptions, ClauseTags, ClientTag } from './types';
import { LoginActionTypes } from '../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../constants/arkTable';
import { initialClause } from '../../constants/clauseLibrary';

export const dropdownOptionsByTagType = (tagType: keyof ClauseTags | null, clauseLibraryDropdownOptions: ClauseTagDropdownOptions, isSystemTemplate: boolean) => {
    const { jurisdictionOptions, agreementTypeOptions, productTypeOptions, counterpartyTypeOptions, provisionTypeOptions, miscellaneousOptions, opinionsOptions, miscellaneousSystemOptions } = clauseLibraryDropdownOptions;

    switch (tagType) {
        case 'jurisdiction':
            return jurisdictionOptions;
        case 'agreementType':
            return agreementTypeOptions;
        case 'productType':
            return productTypeOptions;
        case 'counterpartyType':
            return counterpartyTypeOptions;
        case 'provisionType':
            return provisionTypeOptions;
        case 'opinions':
            return opinionsOptions;
        case 'miscellaneous':
        default:
            return !isSystemTemplate ? miscellaneousOptions : miscellaneousSystemOptions;
    }
};

export const createDuplicateClause = (clause: Clause, isSave: boolean, hasSystemClausePermission: boolean): Clause => flow(
    set('systemTemplate', isSave || hasSystemClausePermission ? clause.systemTemplate : 0),
    set('title', isSave ? clause.title : ''),
    set('description', isSave ? clause.description : ''),
    unset('clauseId')
)(clause);

const getClauseTableState = (tableTab: ClauseTableTab) => {
    let allClauses = 'allClientClauses';
    let totalClauses = 'totalClientClauses';
    let clausePage = 'clientClausePage';
    let tableFilters = 'clientClauseFilters';
    let tableColumnSort = 'clientClauseSort';
    let tablePageSize = 'clientClausePageSize';

    if (tableTab === ClauseTableTab.CURRENT_TEMPLATES) {
        allClauses = 'allTemplateClauses';
        totalClauses = 'totalTemplateClauses';
        clausePage = 'templateClausePage';
        tableFilters = 'templateClauseFilters';
        tableColumnSort = 'templateClauseSort';
        tablePageSize = 'templateClausePageSize';
    }
    if (tableTab === ClauseTableTab.HISTORIC_TEMPLATES) {
        allClauses = 'allHistoricTemplateClauses';
        totalClauses = 'totalHistoricTemplateClauses';
        clausePage = 'historicTemplateClausePage';
        tableFilters = 'historicTemplateClauseFilters';
        tableColumnSort = 'historicTemplateClauseSort';
        tablePageSize = 'historicTemplateClausePageSize';
    }
    if (tableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE) {
        allClauses = 'allMiscellaneousTemplateClauses';
        totalClauses = 'totalMiscellaneousTemplateClauses';
        clausePage = 'miscellaneousTemplateClausePage';
        tableFilters = 'miscellaneousTemplateClauseFilters';
        tableColumnSort = 'miscellaneousTemplateClauseSort';
        tablePageSize = 'miscellaneousTemplateClausePageSize';
    }
    return { allClauses, totalClauses, clausePage, tableFilters, tableColumnSort, tablePageSize };
};

export const INITIAL_STATE: ClauseLibraryState = {
    clauseModalOpen: false,
    clause: initialClause(),
    currentClause: null,
    tagTerm: '',
    fuzzyMatchResults: [],
    fuzzyMatchModalOpen: false,
    clauseLibraryDropdownOptions: { jurisdictionOptions: [], agreementTypeOptions: [], productTypeOptions: [], counterpartyTypeOptions: [], provisionTypeOptions: [], miscellaneousOptions: [], opinionsOptions: [], miscellaneousSystemOptions: [] },
    clauseCanSave: false,
    allClientClauses: [],
    allTemplateClauses: [],
    allHistoricTemplateClauses: [],
    allMiscellaneousTemplateClauses: [],
    clauseTableTab: ClauseTableTab.CLIENT_CLAUSE_LIBRARY,
    isLoading: false,
    clientClausePage: 1,
    totalClientClauses: 0,
    clientClauseFilters: {},
    clientClauseSort: undefined,
    clientClausePageSize: DEFAULT_PAGE_SIZE,
    templateClausePage: 1,
    totalTemplateClauses: 0,
    templateClauseFilters: {},
    templateClauseSort: undefined,
    templateClausePageSize: DEFAULT_PAGE_SIZE,
    historicTemplateClausePage: 1,
    totalHistoricTemplateClauses: 0,
    historicTemplateClauseFilters: {},
    historicTemplateClauseSort: undefined,
    historicTemplateClausePageSize: DEFAULT_PAGE_SIZE,
    miscellaneousTemplateClausePage: 1,
    totalMiscellaneousTemplateClauses: 0,
    miscellaneousTemplateClauseFilters: {},
    miscellaneousTemplateClauseSort: undefined,
    miscellaneousTemplateClausePageSize: DEFAULT_PAGE_SIZE,
    clauseToDelete: null,
    clauseTagsModalOpen: false,
    allClientTags: [],
    tagToDelete: null,
    selectedClientTag: null,
    currentSelectedClientTag: null,
    clientTagModalCanSave: false,
    clientTagColumnSort: undefined,
    clientTagFilters: {},
    clauseDescriptionOpen: false,
    systemTagCategoryModalOpen: false,
    tagModalType: null,
    selectedTemplateTag: null,
    currentSelectedTemplateTag: null,
    templateTagModalCanSave: false,
    savingTemplateTag: false,
    templateTagToDelete: null,
    linkedClauseOpinions: []
};

export const clauseLibraryReducer: Reducer<ClauseLibraryState> = (state = INITIAL_STATE, { payload, type }): ClauseLibraryState => {
    switch (type) {
        case ClauseLibraryActionTypes.TOGGLE_CLAUSE_MODAL: {
            const isOpen = !state.clauseModalOpen;
            if (!isOpen) {
                return flow(
                    set('clause', initialClause()),
                    set('clauseDescriptionOpen', false),
                    set('tagTerm', ''),
                    set('fuzzyMatchResults', []),
                    set('clauseCanSave', false),
                    set('clauseModalOpen', false)
                )(state);
            }
            return set('clauseModalOpen', isOpen, state);
        }
        case ClauseLibraryActionTypes.UPDATE_CLAUSE: {
            const { key, value } = payload;
            if (key === 'systemTemplate' && value === 1) {
                const allClientTagLabels = state.allClientTags.map(({ tag }) => tag);
                const filteredClauseTags = Object.keys(state.clause.tags).reduce((acc, tagKey) => {
                    acc[tagKey as keyof ClauseTags] = acc[tagKey as keyof ClauseTags].filter(tag => !allClientTagLabels.includes(tag));
                    return acc;
                }, { ...state.clause.tags });
                return flow(
                    set('clause.tags', filteredClauseTags),
                    set(`clause.${key}`, value),
                )(state);
            }
            return set(`clause.${key}`, value, state);
        }
        case ClauseLibraryActionTypes.TOGGLE_CLAUSE_DESCRIPTION:
            return set('clauseDescriptionOpen', !state.clauseDescriptionOpen, state);
        case ClauseLibraryActionTypes.UPDATE_CLAUSE_TAGS: {
            const { key, value } = payload;
            const updatedTags = !isNull(value) ? value : [];
            return set(`clause.tags.${key}`, updatedTags, state);
        }
        case ClauseLibraryActionTypes.UPDATE_TAG_TERM:
            return set('tagTerm', payload, state);
        case ClauseLibraryActionTypes.ADD_CLIENT_TAG_SUCCESSFUL: {
            const { allClientTags }: { allClientTags: ClientTag[] } = payload;
            const clientTags = allClientTags.map(({ tag }) => ({ value: tag, label: tag })) || [];
            return flow(
                set('allClientTags', allClientTags),
                set('clauseLibraryDropdownOptions.miscellaneousOptions', [...state.clauseLibraryDropdownOptions.miscellaneousSystemOptions, ...clientTags]),
                set('tagTerm', ''),
            )(state);
        }
        case ClauseLibraryActionTypes.ADD_SYSTEM_TAG_SUCCESSFUL: {
            const { key, value } = payload;
            if (!isUndefined(value)) {
                const updatedTags = !isNull(value) ? value : [];
                return flow(
                    set('tagTerm', ''),
                    set(`clause.tags.${key}`, updatedTags)
                )(state);
            }
            return set('tagTerm', '', state);
        }
        case ClauseLibraryActionTypes.UPDATE_FUZZY_MATCH_RESULTS:
            return flow(
                set('fuzzyMatchResults', payload,),
                set('fuzzyMatchModalOpen', payload.length > 0),
            )(state);
        case ClauseLibraryActionTypes.SET_FUZZY_MATCH_MODAL_OPEN:
            return set('fuzzyMatchModalOpen', payload, state);
        case ClauseLibraryActionTypes.FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_SUCCESSFUL:
            return set('clauseLibraryDropdownOptions', payload, state);
        case ClauseLibraryActionTypes.SET_CLAUSE_CAN_SAVE:
            return set('clauseCanSave', payload, state);
        case ClauseLibraryActionTypes.SAVE_CLAUSE_SUCCESSFUL: {
            const { clause, closeModal } = payload;
            if (closeModal) {
                return flow(
                    set('clause', initialClause()),
                    set('clauseDescriptionOpen', false),
                    set('currentClause', null),
                    set('tagTerm', ''),
                    set('fuzzyMatchResults', []),
                    set('clauseCanSave', false),
                    set('clauseModalOpen', false)
                )(state);
            }
            return flow(
                set('clause', clause,),
                set('currentClause', clause),
                set('clauseCanSave', false)
            )(state);
        }
        case ClauseLibraryActionTypes.FETCH_ALL_CLAUSES_STARTED:
            return set('isLoading', true, state);
        case ClauseLibraryActionTypes.FETCH_ALL_CLAUSES_SUCCESSFUL: {
            const { clauses, total, pageNumber, linkedClauseOpinions } = payload;
            const tableTab = state.clauseTableTab;
            const { allClauses, totalClauses, clausePage } = getClauseTableState(tableTab);
            return flow(
                set(allClauses, clauses),
                set(totalClauses, total),
                set(clausePage, pageNumber),
                set('isLoading', false),
                set('linkedClauseOpinions', linkedClauseOpinions)
            )(state);
        }
        case ClauseLibraryActionTypes.FETCH_ALL_CLAUSES_FAILED:
            return set('isLoading', false, state);
        case ClauseLibraryActionTypes.TOGGLE_CLAUSE_TABLE_VIEW:
            return set('clauseTableTab', payload.tab, state);
        case ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_TABLE_FILTERS: {
            const { key, type, value } = payload;
            const tableTab = state.clauseTableTab;
            const { tableFilters } = getClauseTableState(tableTab);
            const isTextFilter = type === 'text';
            return flow(
                set(`${tableFilters}[${key}][${type}]`, value),
                set(`${tableFilters}[${key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case ClauseLibraryActionTypes.CLEAR_CLAUSE_LIBRARY_TABLE_FILTERS: {
            const tableTab = state.clauseTableTab;
            const { tableFilters } = getClauseTableState(tableTab);
            return set(tableFilters, {}, state);
        }
        case ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_TABLE_COLUMN_SORT: {
            const tableTab = state.clauseTableTab;
            const { tableColumnSort } = getClauseTableState(tableTab);
            return set(tableColumnSort, payload, state);
        }
        case ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_PAGE_SIZE: {
            const tableTab = state.clauseTableTab;
            const { tablePageSize } = getClauseTableState(tableTab);
            return set(tablePageSize, payload, state);
        }
        case ClauseLibraryActionTypes.RESET_CLAUSE_TABLES: {
            const { tableFilters, tableColumnSort, tablePageSize } = getClauseTableState(payload);
            return flow(
                set(tableFilters, {}),
                set(tableColumnSort, undefined),
                set(tablePageSize, DEFAULT_PAGE_SIZE),
            )(state);
        }
        case ClauseLibraryActionTypes.OPEN_SAVED_CLAUSE: {
            const clauseTableView = state.clauseTableTab;
            let clauses = state.allClientClauses;
            if (clauseTableView === ClauseTableTab.CURRENT_TEMPLATES) clauses = state.allTemplateClauses;
            if (clauseTableView === ClauseTableTab.HISTORIC_TEMPLATES) clauses = state.allHistoricTemplateClauses;
            if (clauseTableView === ClauseTableTab.MISCELLANEOUS_TEMPLATE) clauses = state.allMiscellaneousTemplateClauses;
            const clause = clauses.find(({ clauseId }) => clauseId === payload)!;
            return flow(
                set('clause', clause),
                set('currentClause', clause),
                set('clauseModalOpen', true)
            )(state);
        }
        case ClauseLibraryActionTypes.TOGGLE_DELETE_CLAUSE_MODAL: {
            if (isNull(payload)) {
                return set('clauseToDelete', null, state);
            }
            const clauseTableView = state.clauseTableTab;
            let clauses = state.allClientClauses;
            if (clauseTableView === ClauseTableTab.CURRENT_TEMPLATES) clauses = state.allTemplateClauses;
            if (clauseTableView === ClauseTableTab.HISTORIC_TEMPLATES) clauses = state.allHistoricTemplateClauses;
            if (clauseTableView === ClauseTableTab.MISCELLANEOUS_TEMPLATE) clauses = state.allMiscellaneousTemplateClauses;
            const clause = clauses.find(({ clauseId }) => clauseId === payload)!;
            return set('clauseToDelete', clause, state);
        }
        case ClauseLibraryActionTypes.DELETE_CLAUSE_SUCCESSFUL:
            return flow(
                set('clause', initialClause()),
                set('clauseToDelete', null),
                set('currentClause', null),
                set('tagTerm', ''),
                set('fuzzyMatchResults', []),
                set('clauseCanSave', false),
                set('clauseModalOpen', false),
            )(state);
        case ClauseLibraryActionTypes.DUPLICATE_CLAUSE: {
            const { duplicateClauseId, isSave, hasSystemClausePermission } = payload;
            const clauseTableView = state.clauseTableTab;
            let clauses = state.allClientClauses;
            if (clauseTableView === ClauseTableTab.CURRENT_TEMPLATES) clauses = state.allTemplateClauses;
            if (clauseTableView === ClauseTableTab.HISTORIC_TEMPLATES) clauses = state.allHistoricTemplateClauses;
            if (clauseTableView === ClauseTableTab.MISCELLANEOUS_TEMPLATE) clauses = state.allMiscellaneousTemplateClauses;
            const clause = state.clause?.clauseId === duplicateClauseId ? state.clause : clauses.find(({ clauseId }) => clauseId === duplicateClauseId)!;
            const newClause = createDuplicateClause(clause, isSave, hasSystemClausePermission);
            return flow(
                set('clause', newClause),
                set('currentClause', newClause),
                set('tagTerm', ''),
                set('fuzzyMatchResults', []),
                set('clauseModalOpen', true)
            )(state);
        }
        case ClauseLibraryActionTypes.TOGGLE_CLAUSE_TAGS_MODAL: {
            const isOpen = !state.clauseTagsModalOpen;
            if (!isOpen) {
                return flow(
                    set('clauseTagsModalOpen', isOpen),
                    set('tagToDelete', null),
                    set('selectedClientTag', null),
                    set('currentSelectedClientTag', null),
                    set('clientTagModalCanSave', false),
                    set('clientTagColumnSort', undefined),
                    set('clientTagFilters', {}),
                    set('selectedTemplateTag', null),
                    set('currentSelectedTemplateTag', null),
                    set('templateTagModalCanSave', false),
                    set('templateTagToDelete', null)
                )(state);
            }
            return set('clauseTagsModalOpen', isOpen, state);
        }
        case ClauseLibraryActionTypes.UPDATE_ALL_CLIENT_TAGS:
            return set('allClientTags', payload, state);
        case ClauseLibraryActionTypes.TOGGLE_DELETE_TAG_MODAL: {
            const { tag, isTemplate } = payload;
            const tagType = isTemplate ? 'templateTagToDelete' : 'tagToDelete';
            if (isNull(tag)) {
                return flow(
                    set('tagToDelete', null),
                    set('templateTagToDelete', null)
                )(state);
            }
            return set(tagType, tag, state);
        }
        case ClauseLibraryActionTypes.DELETE_CLIENT_TAG_SUCCESSFUL:
            return flow(
                set('allClientTags', payload),
                set('tagToDelete', null),
                set('selectedClientTag', null),
                set('currentSelectedClientTag', null),
                set('clientTagModalCanSave', false)
            )(state);
        case ClauseLibraryActionTypes.SAVE_CLIENT_TAG_SUCCESSFUL:
            return flow(
                set('allClientTags', payload),
                set('currentSelectedClientTag', state.selectedClientTag),
                set('clientTagModalCanSave', false)
            )(state);
        case ClauseLibraryActionTypes.SET_SELECTED_CLIENT_TAG:
            return flow(
                set('selectedClientTag', payload),
                set('currentSelectedClientTag', payload)
            )(state);
        case ClauseLibraryActionTypes.UPDATE_SELECTED_CLIENT_TAG: {
            const { key, value } = payload;
            return set(`selectedClientTag[${key}]`, value, state);
        }
        case ClauseLibraryActionTypes.SET_CLIENT_TAG_CAN_SAVE:
            return set('clientTagModalCanSave', payload, state);
        case ClauseLibraryActionTypes.TOGGLE_TAG_COLUMN_SORT:
            return set('clientTagColumnSort', payload, state);
        case ClauseLibraryActionTypes.SET_CLIENT_TAG_FILTERS: {
            const { type, key, value } = payload;
            const isTextFilter = type === 'text';
            return flow(
                set(`clientTagFilters[${key}][${type}]`, value),
                set(`clientTagFilters[${key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case ClauseLibraryActionTypes.CLEAR_CLIENT_TAG_FILTERS:
            return flow(
                set('clientTagFilters', {}),
                set('clientTagColumnSort', undefined)
            )(state);
        case ClauseLibraryActionTypes.UPDATED_SELECTED_TAG_CLAUSE_USAGE: {
            const { key, value } = payload;
            return set(`selectedClientTag[${key}]`, value, state);
        }
        case ClauseLibraryActionTypes.TOGGLE_SYSTEM_TAG_CATEGORY_MODAL:
            return set('systemTagCategoryModalOpen', !state.systemTagCategoryModalOpen, state);
        case ClauseLibraryActionTypes.SET_TAG_MODAL_TYPE:
            return set('tagModalType', payload, state);
        case ClauseLibraryActionTypes.SET_SELECTED_TEMPLATE_TAG:
            return flow(
                set('selectedTemplateTag', payload),
                set('currentSelectedTemplateTag', payload),
                set('templateTagModalCanSave', false)
            )(state);
        case ClauseLibraryActionTypes.UPDATE_SELECTED_TEMPLATE_TAG: {
            const { key, value } = payload;
            return set(`selectedTemplateTag[${key}]`, value, state);
        }
        case ClauseLibraryActionTypes.SET_TEMPLATE_TAG_CAN_SAVE:
            return set('templateTagModalCanSave', payload, state);
        case ClauseLibraryActionTypes.SAVE_TEMPLATE_TAG_STARTED:
            return set('savingTemplateTag', true, state);
        case ClauseLibraryActionTypes.SAVE_TEMPLATE_TAG_SUCCESSFUL:
            return flow(
                set('savingTemplateTag', false),
                set('templateTagModalCanSave', false)
            )(state);
        case ClauseLibraryActionTypes.SAVE_TEMPLATE_TAG_FAILED:
            return set('savingTemplateTag', false, state);
        case ClauseLibraryActionTypes.DELETE_TEMPLATE_TAG_STARTED:
            return set('savingTemplateTag', true, state);
        case ClauseLibraryActionTypes.DELETE_TEMPLATE_TAG_SUCCESSFUL:
            return flow(
                set('savingTemplateTag', false),
                set('templateTagModalCanSave', false)
            )(state);
        case ClauseLibraryActionTypes.DELETE_TEMPLATE_TAG_FAILED:
            return set('savingTemplateTag', false, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
