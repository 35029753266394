import { flow, isNull, unset } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { worldCountriesList } from '../../../constants/worldCountriesList';
import styles from '../Opinions.module.scss';
import worldGeometryJson from '../../../constants/worldGeometry.json';
import { Country } from '../../../documents/analytics/store';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { fetchAllOpinionsByJurisdictionStarted, getAllOpinionsForMap, getSelectedCountry, OpinionByCountry, setSelectedOpinionJurisdiction, getIsLoading, getCountryOpinions, getCountryOpinionsModalOpen, setCountryOpinions, openOpinionStarted, setUnauthorisedOpinion, ArkMapOpinion, resetOpinionsMapZoom, getOpinionsMapResetZoom, setOpinionsMapZoomSteps, getOpinionsMapZoomSteps, getOpinionsMapZoomUpdated, setOpinionsMapZoomUpdated, setOpinionsMapCurrentScale, getOpinionsMapCurrentScale, getOpinionsMapScaleExtent, getBritishOpinions, getBritishOpinionsModalOpen, setBritishOpinions, OpinionsJurisdictionList } from '../store';
import { OpinionsMap } from './OpinionsMap';
import { CustomTooltip, OverflowTooltip } from '../../../shared/tooltip';
import { IconButton } from '../../../shared/button/IconButton';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Add, Minus, Search } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { LinkedOpinionModal } from '../../instances/LinkedOpinionModal';
import { editOpinionInstance } from '../../instances/store';
import { MultiJurisdictionModal } from './MultiJurisdictionModal';
import { Position } from '../../../shared/modal/PositionModal';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';

const { lightestGrey, french, permittedToView, grey, gold } = styles;

export const stripArkOpinionsMap = (opinion: ArkMapOpinion): OpinionByCountry => flow(
    unset('description'),
    unset('dateOfOpinion'),
    unset('type'),
    unset('allOpinionLocations')
)(opinion);

const worldGeometry = worldGeometryJson as Country[];

interface OpinionMapLegend {
    colour: string;
    label: string;
}

export const OpinionsMapWrapper: React.FC = () => {
    useFetchStarted([fetchAllOpinionsByJurisdictionStarted()]);
    const dispatch = useAppDispatch();

    const [screenWidth, screenHeight] = useWindowResize();
    const width = useMemo(() => screenWidth * 0.85 - 2, [screenWidth]);
    const height = useMemo(() => screenHeight * 0.75 - 2, [screenHeight]);

    const middleOfMap: Position = useMemo(() => ({ x: (width + 750) / 2, y: height / 2.5 }), [height, width]);

    const opinions = useAppSelector(getAllOpinionsForMap);
    const resetZoom = useAppSelector(getOpinionsMapResetZoom);
    const zoomSteps = useAppSelector(getOpinionsMapZoomSteps);
    const zoomUpdated = useAppSelector(getOpinionsMapZoomUpdated);
    const setZoomUpdated = useCallback((value: boolean) => dispatch(setOpinionsMapZoomUpdated(value)), [dispatch]);
    const currentScale = useAppSelector(getOpinionsMapCurrentScale);
    const scaleExtent = useAppSelector(getOpinionsMapScaleExtent);
    const hasIndustryStandardPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));

    const isLoading = useAppSelector(getIsLoading);

    const getOpinionsByCountry = useCallback((name: string): OpinionByCountry[] => opinions.filter(opinion => {
        if (name === 'United Kingdom of Great Britain and Northern Ireland (the)' && (opinion.jurisdiction === 'England & Wales' || opinion.jurisdiction === 'Scotland')) {
            return stripArkOpinionsMap(opinion);
        }
        if (opinion.jurisdiction === name) {
            return stripArkOpinionsMap(opinion);
        }
    }), [opinions]);

    const getOpinionsDisabledValue = useCallback((name: string) => {
        let opinionsByJurisdiction = getOpinionsByCountry(name);
        if (name === 'United Kingdom of Great Britain and Northern Ireland (the)') {
            const scottishOpinions = getOpinionsByCountry('Scotland');
            const englishOpinions = getOpinionsByCountry('England & Wales');
            opinionsByJurisdiction = [...scottishOpinions, ...englishOpinions];
        }
        if (opinionsByJurisdiction.length > 0) {
            return !(opinionsByJurisdiction.some(({ bespoke }) => bespoke === 1) || opinionsByJurisdiction.some(({ permittedToView }) => !!permittedToView));
        }
        return true;
    }, [getOpinionsByCountry]);

    const getAllPublished = useCallback((name: string) => {
        let opinionsByJurisdiction = getOpinionsByCountry(name);
        if (name === 'United Kingdom of Great Britain and Northern Ireland (the)') {
            const scottishOpinions = getOpinionsByCountry('Scotland');
            const englishOpinions = getOpinionsByCountry('England & Wales');
            opinionsByJurisdiction = [...scottishOpinions, ...englishOpinions];
        }
        if (opinionsByJurisdiction.length > 0) {
            return opinionsByJurisdiction.every(({ instanceComplete, bespoke }) => instanceComplete || bespoke === 1);
        }
        return true;
    }, [getOpinionsByCountry]);

    const opinionsList: OpinionsJurisdictionList[] = useMemo(() => worldCountriesList.map(country => ({ opinions: getOpinionsByCountry(country.name), opinionDisabled: getOpinionsDisabledValue(country.name), numberOfOpinions: getOpinionsByCountry(country.name).length, published: getAllPublished(country.name), ...country })), [getOpinionsDisabledValue, getOpinionsByCountry, getAllPublished]);
    const countryList = worldCountriesList.map(({ name, geoId }) => ({ label: name, value: geoId }));

    const selectedCountry = useAppSelector(getSelectedCountry);
    const selectedCountryGeometry = worldGeometry.find(({ id }) => id === selectedCountry);
    const getSelectedCountryLabel = useCallback((country: string) => opinionsList.find(({ geoId }) => geoId === country)!.name || '', [opinionsList]);
    const jurisdictionValue = useMemo(() => selectedCountry ? { value: selectedCountry, label: getSelectedCountryLabel(selectedCountry) } : null, [selectedCountry, getSelectedCountryLabel]);

    const linkedOpinionModalOpen = useAppSelector(getCountryOpinionsModalOpen);
    const selectedCountryOpinions = useAppSelector(getCountryOpinions);
    const selectedOpinionJurisdiction = useMemo(() => isNull(selectedCountryOpinions) ? '' : selectedCountryOpinions.name, [selectedCountryOpinions]);
    const opinionsForCountry = useMemo(() => isNull(selectedCountryOpinions) ? [] : selectedCountryOpinions.opinions, [selectedCountryOpinions]);
    const modalPosition = useMemo(() => isNull(selectedCountryOpinions) ? null : selectedCountryOpinions.position, [selectedCountryOpinions]);
    const closeModal = useCallback(() => dispatch(setCountryOpinions(null)), [dispatch]);

    const britishOpinions = useAppSelector(getBritishOpinions);
    const britishOpinionsModalOpen = useAppSelector(getBritishOpinionsModalOpen);
    const toggleBritishOpinionsModal = useCallback(() => dispatch(setBritishOpinions(null)), [dispatch]);

    const openOpinionsModal = useCallback((name: string, opinions: OpinionByCountry[]) => {
        if (name === 'United Kingdom') {
            const scotland = opinions.filter(({ jurisdiction }) => jurisdiction === 'Scotland');
            const englandAndWales = opinions.filter(({ jurisdiction }) => jurisdiction === 'England & Wales');
            dispatch(setBritishOpinions({ scotland, englandAndWales }));
        } else {
            dispatch(setCountryOpinions({ name, opinions, position: middleOfMap }));
        }
    }, [dispatch, middleOfMap]);

    const linkedOpinionModalHeight = useMemo(() => `${height / 1.5}px`, [height]);

    const updateDropdown = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        setZoomUpdated(false);
        dispatch(setSelectedOpinionJurisdiction(selected));
    };

    const resetMapZoom = useCallback(() => {
        dispatch(setSelectedOpinionJurisdiction(null));
        dispatch(resetOpinionsMapZoom(!resetZoom));
        dispatch(setOpinionsMapZoomSteps(0));
        setOpinionsMapZoomUpdated(false);
    }, [dispatch, resetZoom]);

    const mapZoomIn = useCallback(() => {
        dispatch(resetOpinionsMapZoom(false));
        zoomSteps < 0 ? dispatch(setOpinionsMapZoomSteps(1)) : dispatch(setOpinionsMapZoomSteps(zoomSteps + 1));
        dispatch(setOpinionsMapZoomUpdated(true));
    }, [zoomSteps, dispatch]);

    const mapZoomOut = useCallback(() => {
        dispatch(resetOpinionsMapZoom(false));
        zoomSteps > 0 ? dispatch(setOpinionsMapZoomSteps(-1)) : dispatch(setOpinionsMapZoomSteps(zoomSteps - 1));
        dispatch(setOpinionsMapZoomUpdated(true));
    }, [zoomSteps, dispatch]);

    const mapZoomExtent = useCallback((value: number) => dispatch(setOpinionsMapCurrentScale(value)), [dispatch]);

    const zoomInDisabled = useMemo(() => currentScale >= scaleExtent[1], [currentScale, scaleExtent]);
    const zoomOutDisabled = useMemo(() => currentScale <= scaleExtent[0], [currentScale, scaleExtent]);

    const linkToOpinion = (linkedOpinion: OpinionByCountry) => {
        const { permittedToView, location, mimeType, opinionId } = linkedOpinion;
        if (permittedToView) {
            closeModal();
            dispatch(editOpinionInstance(false));
            dispatch(openOpinionStarted(location, mimeType, opinionId));
        } else {
            dispatch(setUnauthorisedOpinion(linkedOpinion));
        }
    };

    const legend: OpinionMapLegend[] = useMemo(() => {
        let legendLabels = [
            { colour: permittedToView, label: hasIndustryStandardPermission ? 'Published' : 'Permitted to view' },
            { colour: lightestGrey, label: 'No opinions available' }
        ];
        const secondaryLegend = hasIndustryStandardPermission ? { colour: gold, label: 'Unpublished' } : { colour: grey, label: 'Not permitted to view' };
        legendLabels.splice(1, 0, secondaryLegend);

        return legendLabels;
    }, [hasIndustryStandardPermission]);

    useEffect(() => () => {
        dispatch(setSelectedOpinionJurisdiction(null));
    }, [dispatch]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className={styles.opinionsMapWrapper}>
            <OpinionsMap
                height={height}
                width={width}
                worldGeometry={worldGeometry}
                graphData={opinionsList}
                selectedCountry={selectedCountryGeometry}
                onDblClick={openOpinionsModal}
                resetZoom={resetZoom}
                zoomSteps={zoomSteps}
                zoomUpdated={zoomUpdated}
                mapZoomExtent={mapZoomExtent}
                scaleExtent={scaleExtent}
            />
            <div className={styles.opinionsMapDropdownWrapper}>
                <div className={styles.opinionsMapZoomWrapper}>
                    <CustomTooltip overlayText='Zoom In' placement='top'>
                        <div>
                            <IconButton onClick={mapZoomIn} disabled={zoomInDisabled} testId='opinions-jurisdiction-map-zoom-in' icon={Add} fontSize={20} withBackground />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip overlayText='Reset Zoom' placement='top'>
                        <div>
                            <IconButton onClick={resetMapZoom} disabled={false} testId='opinions-jurisdiction-map-reset-zoom' icon={Search} fontSize={20} withBackground iconFill={french} />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip overlayText='Zoom Out' placement='top'>
                        <div>
                            <IconButton onClick={mapZoomOut} disabled={zoomOutDisabled} testId='opinions-jurisdiction-map-zoom-out' icon={Minus} fontSize={20} withBackground />
                        </div>
                    </CustomTooltip>
                </div>
                <div className={styles.legendsWrapper}>
                    {legend.map(({ colour, label }, index) => (
                        <div className={styles.legendWrapper} key={index} style={{ width: `${100 / legend.length}%` }}>
                            <div className={styles.legendColour} style={{ backgroundColor: colour }} />
                            <OverflowTooltip className={styles.legendLabel} overlayText={label} testId='opinion-legend-label' />
                        </div>
                    ))}
                </div>
                <div className={styles.opinionsMapDropdown}>
                    <Dropdown
                        testId='opinion-jurisdiction'
                        onChange={updateDropdown}
                        value={jurisdictionValue}
                        options={countryList}
                        controlBackgroundColor={lightestGrey}
                    />
                </div>
            </div>
            <MultiJurisdictionModal
                isOpen={britishOpinionsModalOpen}
                britishOpinions={britishOpinions!}
                toggleBritishOpinionsModal={toggleBritishOpinionsModal}
                openOpinionsModal={openOpinionsModal}
            />
            <LinkedOpinionModal
                isOpen={linkedOpinionModalOpen}
                linkedOpinions={opinionsForCountry}
                position={modalPosition}
                closeModal={closeModal}
                linkToOpinion={linkToOpinion}
                jurisdiction={selectedOpinionJurisdiction}
                height={linkedOpinionModalHeight}
            />
        </div>
    );
};
