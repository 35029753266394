import React, { ChangeEvent } from 'react';
import classnames from 'classnames';

import styles from './Text.module.scss';

const { primary } = styles;

export interface TextProps {
    label?: string;
    testId: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    value?: string | string[];
    placeholder?: string;
    width?: string;
    height?: string;
    marginBottom?: string
    maxLength?: number;
    disabled?: boolean;
    autoFocus?: boolean;
    cursor?: string;
    marginRight?: string;
    showBoldPlaceholder?: boolean;
    borderColour?: string;
}

export const Text: React.FC<TextProps> = ({
    label,
    testId,
    onChange,
    onKeyDown,
    value,
    placeholder = '',
    width = '100%',
    height = '',
    marginBottom = '10px',
    maxLength,
    disabled = false,
    autoFocus = false,
    cursor = 'auto',
    marginRight = '0px',
    showBoldPlaceholder = false,
    borderColour = primary
}) => (
    <div className={styles.inputWrapper} style={{
        width,
        marginRight
    }}>
        {label && <div data-testid={`${testId}-label`} className={styles.inputLabel}>{label}</div>}
        <input
            placeholder={placeholder}
            className={classnames(styles.textInput, {
                [styles.boldPlaceholder]: showBoldPlaceholder
            })}
            data-testid={`${testId}-input`}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value}
            style={{ marginBottom, height, cursor, borderColor: borderColour }}
            maxLength={maxLength}
            disabled={disabled}
            autoFocus={autoFocus}
        />
    </div>
);
