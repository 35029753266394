import download from 'downloadjs';
import { getOr, isEqual, isNull, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { createReport, deleteReportTemplate, fetchAllDocumentReportTemplates, fetchAvailableReportFields, upsertReportTemplate } from '../../../../services/report';
import { dotsToDashes } from '../../../../utils/regex-utils';
import { SearchFilter } from '../../search/store';
import {
    deleteReportTemplateFailed,
    deleteReportTemplateStarted,
    deleteReportTemplateSuccessful,
    fetchAllReportTemplatesFailed,
    fetchAllReportTemplatesStarted,
    fetchAllReportTemplatesSuccessful,
    fetchAvailableDocumentFieldsSuccessful,
    fetchAvailableFieldsFailed,
    fetchAvailableFieldsStarted,
    generateReportFailed,
    generateReportSuccessful,
    openDocumentReportTemplate,
    resetReport,
    saveReportTemplateFailed,
    saveReportTemplateStarted,
    saveReportTemplateSuccessful,
    setReportPage,
    setReportUpdated,
    updateDocumentName,
    updateDocumentReportFilter
} from './actions';
import { initialDocumentReportFilter } from './reducer';
import { getReportFields, getReportFilters, getSelectedTemplate, getUpsertReportTemplate } from './selectors';
import { DocumentReportField, DocumentReportHierarchy, DocumentReportTemplate, DocumentReportingActionTypes, ReportPage, UpsertDocumentReportTemplate } from './types';

export function* attemptFetchAvailableReportFields({ payload }: ReturnType<typeof fetchAvailableFieldsStarted>) {
    try {
        const { documentNameId, reportFields } = payload;
        const { availableFields, hierarchy }: { availableFields: DocumentReportField[]; hierarchy: DocumentReportHierarchy[]; } = yield call(fetchAvailableReportFields, { documentNameId });
        yield put(fetchAvailableDocumentFieldsSuccessful(availableFields, hierarchy, reportFields));
    } catch (e) {
        yield put(fetchAvailableFieldsFailed((e as Error).message));
        toast.error('Unable to retrieve the available report fields. Please try again.');
    }
}

function* fetchAvailableReportFieldsWatcher() {
    yield takeEvery(DocumentReportingActionTypes.FETCH_AVAILABLE_FIELDS_STARTED, attemptFetchAvailableReportFields);
}

export function* triggerFetchAvailableReports({ payload }: ReturnType<typeof updateDocumentName>) {
    const value = payload;
    const template: DocumentReportTemplate | null = yield select(getSelectedTemplate);
    yield put(updateDocumentReportFilter('documentNameId', value));
    if (isNull(value)) {
        yield put(fetchAvailableDocumentFieldsSuccessful([], []));
    } else if (value.length === 1 && isNull(template)) {
        yield put(fetchAvailableFieldsStarted(value[0]));
    }
}

function* updateDocumentNameIdWatcher() {
    yield takeEvery(DocumentReportingActionTypes.UPDATE_REPORT_DOCUMENT_NAME, triggerFetchAvailableReports);
}

export function* attemptGenerateReport() {
    try {
        const reportFields: DocumentReportField[] = yield select(getReportFields);
        const filters: SearchFilter = yield select(getReportFilters);
        const selectedTemplate: DocumentReportTemplate | null = yield select(getSelectedTemplate);
        const fileName = selectedTemplate ? selectedTemplate.name : 'Ark51 Custom Report';
        const formattedFileName = dotsToDashes(fileName);
        const doubleRowHeight = navigator.userAgent.indexOf('Win') !== -1;
        const report: Blob = yield call(createReport, { reportFields, filters, fileName, doubleRowHeight });
        yield call(download, report, formattedFileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        yield put(generateReportSuccessful());
    } catch (e) {
        yield put(generateReportFailed((e as Error).message));
        toast.error('Unable to generate report. Please try again.');
    }
}

function* generateReportWatcher() {
    yield takeEvery(DocumentReportingActionTypes.GENERATE_REPORT_STARTED, attemptGenerateReport);
}

export function* checkDocumentReportUpdated() {
    try {
        const reportFields: DocumentReportField[] = yield select(getReportFields);
        const filters: SearchFilter = yield select(getReportFilters);
        const selectedTemplate: DocumentReportTemplate | null = yield select(getSelectedTemplate);
        const selectedTemplateFields = getOr([], 'reportFields', selectedTemplate);
        const selectedReportFilters = getOr(initialDocumentReportFilter, 'filters', selectedTemplate);
        const reportFieldsUpdated = !isEqual({ reportFields: selectedTemplateFields, filters: selectedReportFilters }, { reportFields, filters });
        yield put(setReportUpdated(reportFieldsUpdated));
    } catch (e) {
        toast.error('Something went wrong when checking if report has been updated.');
    }
}

function* documentReportUpdatedWatcher() {
    yield takeEvery([DocumentReportingActionTypes.UPDATE_DOCUMENT_REPORT_FIELDS, DocumentReportingActionTypes.ADD_DOCUMENT_REPORT_FIELD, DocumentReportingActionTypes.REMOVE_DOCUMENT_REPORT_FIELD, DocumentReportingActionTypes.UPDATE_DOCUMENT_REPORT_FILTER], checkDocumentReportUpdated);
}

export function* attemptSaveReportTemplate({ payload }: ReturnType<typeof saveReportTemplateStarted>) {
    try {
        const saveAs = payload;
        let reportTemplate: UpsertDocumentReportTemplate = yield select(getUpsertReportTemplate);
        if (saveAs) {
            reportTemplate = unset('reportTemplateId', reportTemplate);
        }
        const upsertedTemplate: DocumentReportTemplate = yield call(upsertReportTemplate, reportTemplate);
        yield put(saveReportTemplateSuccessful(upsertedTemplate));
        yield put(fetchAllReportTemplatesStarted());
        toast('Successfully saved report template');
    } catch (e) {
        yield put(saveReportTemplateFailed((e as Error).message));
        toast.error('Unable to save report template. Please try again.');
    }
}

function* saveReportTemplateWatcher() {
    yield takeEvery(DocumentReportingActionTypes.SAVE_REPORT_TEMPLATE_STARTED, attemptSaveReportTemplate);
}

export function* attemptFetchAllReportTemplates() {
    try {
        const reportTemplates: DocumentReportTemplate[] = yield call(fetchAllDocumentReportTemplates);
        yield put(fetchAllReportTemplatesSuccessful(reportTemplates));
    } catch (e) {
        yield put(fetchAllReportTemplatesFailed((e as Error).message));
        toast.error('Unable to fetch report templates. Please try again.');
    }
}

function* fetchAllReportTemplatesWatcher() {
    yield takeEvery(DocumentReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_STARTED, attemptFetchAllReportTemplates);
}

export function* attemptDeleteReportTemplate({ payload }: ReturnType<typeof deleteReportTemplateStarted>) {
    try {
        const reportTemplates: DocumentReportTemplate[] = yield call(deleteReportTemplate, { id: payload, isOpinionTemplate: false });
        yield put(deleteReportTemplateSuccessful(reportTemplates));
    } catch (e) {
        yield put(deleteReportTemplateFailed((e as Error).message));
        toast.error('Unable to delete report template. Please try again.');
    }
}

function* deleteReportTemplateWatcher() {
    yield takeEvery(DocumentReportingActionTypes.DELETE_REPORT_TEMPLATE_STARTED, attemptDeleteReportTemplate);
}

export function* attemptOpenTemplate(templateId: string) {
    const reportTemplates: DocumentReportTemplate[] = yield call(fetchAllDocumentReportTemplates);
    const template = reportTemplates.find(({ reportTemplateId }) => reportTemplateId.toString() === templateId);
    if (template) {
        const { filters, reportFields } = template;
        yield put(openDocumentReportTemplate(template));
        yield put(fetchAvailableFieldsStarted(filters.documentNameId![0].toString(), reportFields));
    } else {
        yield put(push('/documents/reporting/templates'));
    }
}

export function* redirectReportPage({ payload }: ReturnType<typeof setReportPage>) {
    const { page, reportTemplateId } = payload;
    if (reportTemplateId) {
        yield call(attemptOpenTemplate, reportTemplateId);
    } else {
        let navigationPath = '/documents/reporting';
        if (page !== ReportPage.SELECT) {
            navigationPath = `/documents/reporting/${page === ReportPage.WIZARD ? 'create' : 'templates'}`;
        } else {
            yield put(resetReport(ReportPage.SELECT));
        }
        yield put(push(navigationPath));
    }
}

function* redirectReportPageWatcher() {
    yield takeEvery(DocumentReportingActionTypes.SELECT_REPORT_PAGE, redirectReportPage);
}

export function* redirectDocumentTemplatePage({ payload }: ReturnType<typeof openDocumentReportTemplate>) {
    const navigationPath = `/documents/reporting/templates/${payload.reportTemplateId}`;
    yield put(push(navigationPath));
}

function* redirectDocumentTemplatePageWatcher() {
    yield takeEvery(DocumentReportingActionTypes.OPEN_DOCUMENT_REPORT_TEMPLATE, redirectDocumentTemplatePage);
}

export function* documentReportingSaga() {
    yield all([
        fork(updateDocumentNameIdWatcher),
        fork(fetchAvailableReportFieldsWatcher),
        fork(generateReportWatcher),
        fork(documentReportUpdatedWatcher),
        fork(saveReportTemplateWatcher),
        fork(fetchAllReportTemplatesWatcher),
        fork(deleteReportTemplateWatcher),
        fork(redirectReportPageWatcher),
        fork(redirectDocumentTemplatePageWatcher)
    ]);
}
