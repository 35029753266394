import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { CheckboxRiskField, RiskAssociated, RiskAssociatedValue } from '../../../admin/risk-tolerance/store';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import styles from './RiskField.module.scss';

const { grey }= styles;

interface CheckboxRiskFieldConfigProps {
    riskField: CheckboxRiskField;
    riskAssociated: RiskAssociated;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValue) => void;
}

export const CheckboxRiskFieldConfig: React.FC<CheckboxRiskFieldConfigProps> = ({ riskField, riskAssociated, updateRiskConfig }) => {
    const { riskConfig, includeRiskField } = riskField;

    const allDropdownOptions = useMemo(() => [{ value: 'true', label: 'When toggled on (true)' }, { value: 'false', label: 'When toggled off (false)' }], []);
    const unavailableDropdownOptions = useMemo(() => Object.entries(riskConfig).reduce((acc: string[], [risk, value]) => {
        if (parseInt(risk) === riskAssociated || isUndefined(value)) {
            return acc;
        }
        return [...acc, value.toString()];
    }, []), [riskConfig, riskAssociated]);

    const dropdownOptions = useMemo(() => allDropdownOptions.filter(({ value }) => !unavailableDropdownOptions.includes(value)), [allDropdownOptions, unavailableDropdownOptions]);

    const value = useMemo(() => dropdownOptions.find(({ value }) => value === riskConfig[riskAssociated]?.toString()) || null, [riskConfig, riskAssociated, dropdownOptions]);

    const updateDropdownValue = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: boolean | undefined = undefined;
        if (!isNull(dropdownValue)) {
            value = (dropdownValue as DropdownOption).value === 'true';
        }
        updateRiskConfig(riskAssociated, value);
    }, [riskAssociated, updateRiskConfig]);

    return (
        <div className={styles.dropdownWrapper}>
            <Dropdown options={dropdownOptions} value={value} onChange={updateDropdownValue} isClearable controlBorderColor={grey} disabled={!includeRiskField} />
        </div>
    );
};
