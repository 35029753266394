import classnames from 'classnames';
import { isArray, isUndefined } from 'lodash/fp';
import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './Fields.module.scss';
import { SignOffConversation } from '../store';
import { SignOffConversationIcon } from '../sign-off';

const convertUTCToLocalDate = (date: Date | null): null | Date => {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return date;
};

const convertLocalToUTCDate = (date: Date | [Date | null, Date | null] | null) => {
    if (isArray(date)) {
        date = date[0];
    }

    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return date;
};

interface DateFieldProps {
    id: string;
    value: Date | null;
    disabled?: boolean;
    showFieldUpdated?: boolean;
    updateValue: (value: Date | null) => void;
    signOffConversation?: SignOffConversation;
}

export const DateField: React.FC<DateFieldProps> = ({
    value,
    disabled = false,
    id,
    showFieldUpdated = false,
    updateValue,
    signOffConversation
}) => {

    return (
        <div className={styles.datePickerWrapper}>
            <DatePicker
                dateFormat='dd/MM/yyyy'
                disabled={disabled}
                selected={convertUTCToLocalDate(value)}
                onChange={date => updateValue(convertLocalToUTCDate(date))}
                placeholderText='Select...'
                className={classnames(styles.dateField, {
                    [styles.updatedInputField]: showFieldUpdated
                })}
                data-testid={`opinion-date-${id}`}
                wrapperClassName={styles.dateFieldWrapper}
                fixedHeight
                showYearDropdown
                dateFormatCalendar='MMMM'
                yearDropdownItemNumber={40}
                scrollableYearDropdown
                dropdownMode='scroll'
                portalId='root'
            />
            {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={id} />}
        </div>
    );
};
