import React from 'react';

import { useAppDispatch } from '../../../../../../hooks/react-redux';
import { toggleModalDocumentDataset } from '../../../store';
import styles from '../../ConfigureDatasets.module.scss';

interface DocumentDatasetLinkProps {
    id: string;
    childDatasetId: string;
    columnHidden: boolean;
    hiddenDocumentNameIds?: number[] | undefined
}

export const DocumentDatasetLink: React.FC<DocumentDatasetLinkProps> = ({
    id,
    childDatasetId,
    columnHidden,
    hiddenDocumentNameIds
}) => {
    const dispatch = useAppDispatch();
    const linkDataset = () => dispatch(toggleModalDocumentDataset(parseInt(childDatasetId), hiddenDocumentNameIds));

    return (
        <div className={styles.datasetLinkField} data-testid={`definition-dataset-link-${id}`}>
            <button
                onClick={linkDataset}
                className={styles.datasetLinkButton}
                disabled={columnHidden}
            >
                Open
            </button>
        </div>
    );
};
