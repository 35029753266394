import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { User as UserIcon } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';
import { deleteUserStarted, getIsDeleting, ClientUser } from './store';

interface DeleteConfirmationModalProps {
    isOpen: boolean;
    user: ClientUser;
    closeModal: () => void;
    clientName?: string;
    clientId?: number;
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ isOpen, closeModal, clientName, clientId, user }) => {
    const dispatch = useAppDispatch();
    const isDeleting = useAppSelector(getIsDeleting);
    const { userId, username } = user;
    const deleteUser = () => dispatch(deleteUserStarted(userId, username, clientId));

    const headerLabel = `Delete User${clientName ? ` (${clientName})` : ''}`;

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={deleteUser}
            isDeleting={isDeleting}
            headerLabel={headerLabel}
            name={username}
            testId='user'
            icon={UserIcon}
        />
    );
};
