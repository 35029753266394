import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Icon } from '../icon/Icon';
import { Add } from '../icons';
import { ProfilePicture } from './ProfilePicture';
import styles from './ProfilePicture.module.scss';

interface DropzonePictureProps {
    setProfilePicture: (files: File[]) => void;
    file?: File | undefined;
    profilePicture?: Buffer;
    initials?: string;
    testId?: string;
    disabled?: boolean;
}

export const DropzonePicture: React.FC<DropzonePictureProps> = ({
    disabled = false,
    setProfilePicture,
    profilePicture,
    file,
    initials,
    testId
}) => {
    const onDrop = useCallback((acceptedFiles: File[]) => setProfilePicture(acceptedFiles), [setProfilePicture]);
    const {
        getRootProps,
        getInputProps
    } = useDropzone({ disabled, maxFiles: 1, accept: ['image/*'], onDrop, maxSize: 2097152 });

    return (
        <div {...getRootProps({ className: classnames(styles.dropzone, { [styles.dropzoneDisabled]: disabled }) })} data-testid={`${testId}-dropzone-picture-wrapper`}>
            <input {...getInputProps()} />
            <ProfilePicture profilePicture={profilePicture} file={file} initials={initials} />
            {!disabled && <div className={styles.updateProfilePicture}><Icon icon={Add} /></div>}
        </div>
    );
};
