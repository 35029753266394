import React, { useCallback } from 'react';
import { isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { AnnexPreviewTable } from '../../../agency-annex/annex-preview/AnnexPreviewTable';
import { AnnexDefinitionDB, duplicateAnnexDefinition, getAllAnnexDefinitions, getCurrentInstanceAnnexDefinitions, InstanceAnnexView, removeInstanceAnnexField, setInstanceAnnexView, updateInstanceAnnexField } from '../../../agency-annex/store';
import { Button } from '../../../shared/button/Button';
import styles from '../DatasetInstance.module.scss';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { getClientHasFeaturePermission } from '../../../auth/login/store';
import { ClientFeaturePermission } from '../../../admin/users/store';
import { AnnexPageRefs } from './AnnexPageRefs';
import { Icon } from '../../../shared/icon/Icon';
import { Pencil } from '../../../shared/icons';

export const Configure: React.FC = () => {
    const dispatch = useAppDispatch();
    const annexDefinitions = useAppSelector(getAllAnnexDefinitions);
    const instanceAnnexDefinitions = useAppSelector(getCurrentInstanceAnnexDefinitions);
    const hasDocumentExtractAIPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DOCUMENT_EXTRACT_WITH_AI]));

    const updateLabel = useCallback((value: string, index: number) => dispatch(updateInstanceAnnexField('label', value, index)), [dispatch]);
    const removeAnnexInstance = useCallback((index: number) => dispatch(removeInstanceAnnexField(index)), [dispatch]);
    const setView = (view: InstanceAnnexView) => dispatch(setInstanceAnnexView(view));
    const duplicate = (annexDefinition: AnnexDefinitionDB) => {
        dispatch(duplicateAnnexDefinition(annexDefinition));
        setView(InstanceAnnexView.BUILDER);
    };

    const getAnnexDefinition = useCallback((id: number) => annexDefinitions.find(({ annexDefinitionId }) => annexDefinitionId === id), [annexDefinitions]);

    return (
        <Scrollable>
            {instanceAnnexDefinitions.map(({ label, annexDefinitionId }, index) => {
                if (isNull(annexDefinitionId)) {
                    return null;
                }
                const annexDefinition = getAnnexDefinition(annexDefinitionId);
                if (isUndefined(annexDefinition)) {
                    return null;
                }
                return (
                    <div key={annexDefinitionId} className={styles.annexDefinitionWrapper}>
                        <div className={styles.annexDefinitionLabelWrapper}>
                            <div className={styles.labelIconWrapper}><Icon icon={Pencil} fontSize={20} /></div>
                            <input
                                className={styles.fieldLabelInput}
                                placeholder='Label...'
                                value={label}
                                onChange={e => updateLabel(e.currentTarget.value, index)}
                            />
                        </div>
                        {hasDocumentExtractAIPermission && <AnnexPageRefs index={index} />}
                        <AnnexPreviewTable annexDefinition={annexDefinition} isSelected />
                        <div className={styles.buttonWrapper}>
                            <div className={styles.leftButtonWrapper}>
                                {instanceAnnexDefinitions.length > 1 && <Button label='Remove' onClick={() => removeAnnexInstance(index)} />}
                            </div>
                            <div className={styles.rightButtonWrapper}>
                                <Button label='Replace' onClick={() => setView(InstanceAnnexView.SELECT)} />
                                <Button label='Modify' onClick={() => duplicate(annexDefinition)} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </Scrollable>
    );
};
