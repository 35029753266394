import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { isNull, isUndefined, sum } from 'lodash/fp';
import GaugeChart from 'react-gauge-chart';

import styles from '../../Analytics.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { DocumentAnalyticsChart, fetchDocumentRiskToleranceSuccessful, getDocumentRiskTolerance, getSelectedRiskToleranceParentDatasetId, getDocumentFieldRiskToleranceOverallPercentage, getTotalRiskFieldDocuments, getSelectedRiskFieldDocument, setRiskToleranceDatasetId, AllDocumentsRiskTolerance } from '../../store';
import { Spinner } from '../../../../shared/spinner/Spinner';
import { IconButton } from '../../../../shared/button/IconButton';
import { Sort } from '../../../../shared/icons';
import { InformationTooltip, OverflowTooltip } from '../../../../shared/tooltip';
import { DocumentRiskToleranceLevel } from './DocumentRiskToleranceLevel';
import { DocumentRiskToleranceTable } from './DocumentRiskToleranceTable';
import { DocumentRiskToleranceBreakdown } from './DocumentRiskToleranceBreakdown';
import { Button } from '../../../../shared/button/Button';
import { DocumentDatasetsRiskTolerance } from './DocumentDatasetsRiskTolerance';
import { getSelectedRiskField } from '../../../../admin/risk-tolerance/store';
import { DocumentFieldRiskToleranceTable } from './DocumentFieldRiskToleranceTable';
import { fetchMyDatasetDefinitionsStarted } from '../../../../admin/my-datasets/store';
import { DatasetType } from '../../../../datasets/store';

const { red, amber, gold, yellowGreen, green, grey } = styles;

interface RiskToleranceChartProps {
    tile: DocumentAnalyticsChart;
    tileInView: null | DocumentAnalyticsChart;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    dimensions?: {
        height: number;
        width: number;
    };
    allDocumentsRiskTolerance: AllDocumentsRiskTolerance;
    chartIsFullScreen: boolean;
    showSpinner: boolean;
    showDocumentTable: boolean;
    showDocumentBreakdown: boolean;
    showDocumentDataOptions: boolean;
    showDocumentFieldBreakdown: boolean;
    switchRiskTypeValue: () => void;
    getDocumentRiskToleranceAnswers: () => void;
    setDocumentsView: () => void;
    switchRiskTypeLabel: string;
    entityId?: number;
    isPreExecution?: boolean;
}

export const RiskToleranceChart: React.FC<RiskToleranceChartProps> = ({
    tile,
    setTileInView,
    tileInView,
    dimensions,
    allDocumentsRiskTolerance,
    chartIsFullScreen,
    showSpinner,
    showDocumentTable,
    showDocumentBreakdown,
    showDocumentDataOptions,
    showDocumentFieldBreakdown,
    switchRiskTypeValue,
    getDocumentRiskToleranceAnswers,
    setDocumentsView,
    switchRiskTypeLabel,
    entityId,
    isPreExecution = false
}) => {

    const dispatch = useAppDispatch();
    const [activePercentage, setActivePercentage] = useState<number>(0);
    const documentRiskTolerance = useAppSelector(getDocumentRiskTolerance);
    const riskFieldOverallPercentage = useAppSelector(getDocumentFieldRiskToleranceOverallPercentage);
    const totalRiskFieldDocuments = useAppSelector(getTotalRiskFieldDocuments);
    const selectedRiskFieldDocument = useAppSelector(getSelectedRiskFieldDocument);
    const chartWidth = useMemo(() => chartIsFullScreen ? 'calc(50% - 10px)' : '90%', [chartIsFullScreen]);
    const selectedDatasetId = useAppSelector(getSelectedRiskToleranceParentDatasetId);
    const selectedField = useAppSelector(getSelectedRiskField);

    const toggleTileView = useCallback(() => {
        setTileInView(isNull(tileInView) ? tile : null);
        if (!isNull(tileInView)) {
            dispatch(fetchDocumentRiskToleranceSuccessful(null));
        }
    }, [setTileInView, tileInView, dispatch, tile]);

    const documentRiskPercentage = useMemo(() => {
        if (showDocumentFieldBreakdown) {
            if (!isNull(selectedRiskFieldDocument)) {
                if (selectedRiskFieldDocument.datasetType === DatasetType.TABLE) {
                    const rowFieldPercentages = sum(selectedRiskFieldDocument.riskTolerance.map(({ fieldPercentage }) => fieldPercentage));
                    const divisibleBy = selectedRiskFieldDocument.riskTolerance.length * 100;
                    return rowFieldPercentages / divisibleBy * 100;
                }
                return selectedRiskFieldDocument.riskTolerance[0].fieldPercentage;
            }
            return riskFieldOverallPercentage;
        }
        if (!isNull(documentRiskTolerance)) {
            return documentRiskTolerance.percentage;
        }
        return allDocumentsRiskTolerance.totalDocuments > 0 ? allDocumentsRiskTolerance.overallPercentage : null;
    }, [allDocumentsRiskTolerance, documentRiskTolerance, showDocumentFieldBreakdown, riskFieldOverallPercentage, selectedRiskFieldDocument]);

    const totalDocuments = useMemo(() => showDocumentFieldBreakdown ? totalRiskFieldDocuments : allDocumentsRiskTolerance.totalDocuments, [showDocumentFieldBreakdown, totalRiskFieldDocuments, allDocumentsRiskTolerance]);

    const totalDocumentsLabel = useMemo(() => isNull(documentRiskTolerance) ? `Total Documents: ${totalDocuments}` : '', [documentRiskTolerance, totalDocuments]);

    const chartCursor = useMemo(() => isNull(documentRiskTolerance) ? 'cursor' : 'pointer', [documentRiskTolerance]);

    const fetchRiskTolerance = useCallback((datasetId: number) => {
        dispatch(setRiskToleranceDatasetId(datasetId));
        dispatch(fetchMyDatasetDefinitionsStarted(datasetId));
    }, [dispatch]);

    useEffect(() => () => {
        dispatch(fetchDocumentRiskToleranceSuccessful(null));
        setDocumentsView();
        dispatch(setRiskToleranceDatasetId(null));
    }, [dispatch, setDocumentsView]);

    useEffect(() => {
        if (!isNull(documentRiskPercentage) && documentRiskPercentage !== activePercentage) {
            setActivePercentage(documentRiskPercentage);
        }
    }, [activePercentage, documentRiskPercentage]);

    const testId = useMemo(() => {
        let id = 'document-risk-tolerance-chart';
        if (isPreExecution) {
            id = 'pre-execution-'.concat(id);
        }
        if (!isUndefined(entityId)) {
            id = 'entity-'.concat(id);
        }
        return id;
    }, [isPreExecution, entityId]);

    const chartLabel = useMemo(() => {
        let label = ' Document Risk Tolerance';
        if (isPreExecution) {
            return 'Pre Execution'.concat(label);
        }
        if (!isUndefined(entityId)) {
            return 'Entity'.concat(label);
        }
        return 'Full'.concat(label);
    }, [isPreExecution, entityId]);

    const labelBottom = useMemo(() => chartIsFullScreen ? '20%' : '25%', [chartIsFullScreen]);

    const wrapperDimensions = dimensions ? { height: dimensions.height - 20, width: dimensions.width } : { height: '100%', width: '100%' };
    const tooltipInfo = useMemo(() => {
        if (showDocumentBreakdown) {
            return 'Below is a summary of the risk associated with the specified document.';
        }
        if (showDocumentDataOptions) {
            if (isNull(selectedDatasetId)) {
                return 'Below is a list of agreement types, select one and choose a provision to see the risk associated.';
            }
            if (!isNull(selectedDatasetId)) {
                return 'Below is a list of available provisions, please select one to see the risk tolerance score across your document portfolio.';
            }
            if (!isNull(selectedDatasetId) && !isNull(selectedField)) {
                return `Below is a summary of the risk tolerance for the selected provision across your document portfolio${!isUndefined(entityId) ? ' for the selected my company entity' : ''}.`;
            }
        }
        let portfolioDescription = 'entire document portfolio';
        if (!isUndefined(entityId)) {
            portfolioDescription = 'documents for the selected entity';
        }
        if (isPreExecution) {
            portfolioDescription = 'pre execution documents';
        }
        return `Below is a summary of the risk associated with your ${portfolioDescription}.`;
    }, [showDocumentBreakdown, showDocumentDataOptions, selectedDatasetId, selectedField, entityId, isPreExecution]);

    const showDocumentMeta = useMemo(() => chartIsFullScreen && (!isNull(documentRiskTolerance) || !isNull(selectedRiskFieldDocument)), [chartIsFullScreen, documentRiskTolerance, selectedRiskFieldDocument]);
    const { documentName, documentDescription, partyA, partyB } = useMemo(() => {
        if (!isNull(documentRiskTolerance)) {
            return {
                documentName: documentRiskTolerance.documentName,
                documentDescription: documentRiskTolerance.documentDescription,
                partyA: documentRiskTolerance.entityAName,
                partyB: documentRiskTolerance.entityBName
            };
        }
        if (!isNull(selectedRiskFieldDocument)) {
            return {
                documentName: selectedRiskFieldDocument.documentName,
                documentDescription: selectedRiskFieldDocument.documentDescription,
                partyA: selectedRiskFieldDocument.partyA,
                partyB: selectedRiskFieldDocument.partyB
            };
        }
        return { documentName: '', documentDescription: '', partyA: '', partyB: '' };
    }, [documentRiskTolerance, selectedRiskFieldDocument]);

    if (showSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.documentRiskToleranceChartWrapper} style={wrapperDimensions} data-testid={`${testId}-wrapper`}>
            <div className={styles.documentRiskToleranceChartHeader}>
                <div className={styles.centreGroup} style={{ width: wrapperDimensions.width }}>
                    <div style={{ maxWidth: 'calc(100% - 45px)' }}>
                        <OverflowTooltip className={styles.documentRiskToleranceChartTitle} overlayText={chartLabel} testId={`${testId}-title`} />
                    </div>
                    <div className={styles.documentRiskToleranceChartDescription}><InformationTooltip content={tooltipInfo} /></div>
                    <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
                </div>
                {chartIsFullScreen && <div className={styles.toggleDataButton}><Button label={switchRiskTypeLabel} onClick={switchRiskTypeValue} /></div>}
            </div>
            <div className={styles.chartContents}>
                <div className={styles.chartAndMeta} style={{ width: chartWidth, justifyContent: chartIsFullScreen ? '' : 'center' }}>
                    <div className={styles.gaugeChartWrapper} style={{ cursor: chartCursor }} onClick={getDocumentRiskToleranceAnswers}>
                        <div className={styles.acceptableLabel}>Acceptable</div>
                        <GaugeChart
                            nrOfLevels={5}
                            colors={[green, yellowGreen, gold, amber, red]}
                            needleColor={grey}
                            needleBaseColor={grey}
                            hideText
                            style={{ marginBottom: '0px' }}
                            percent={(documentRiskPercentage || 0) / 100}
                            animate={activePercentage !== documentRiskPercentage}
                        />
                        <div className={styles.highlyDesirableLabel} style={{ bottom: labelBottom }}>Highly Desirable</div>
                        <div className={styles.ofSeriousConcernLabel} style={{ bottom: labelBottom }}>Of Serious Concern</div>
                        <div>
                            <DocumentRiskToleranceLevel percentage={documentRiskPercentage} chartIsFullScreen={chartIsFullScreen} />
                            <div className={styles.totalDocumentsWrapper}>{totalDocumentsLabel}</div>
                        </div>
                    </div>

                    {showDocumentMeta &&
                        <div className={styles.documentMeta}>
                            <div className={styles.documentNameWrapper}>
                                <div className={styles.documentDetailLabel}>Document Name:</div>
                                <OverflowTooltip className={styles.documentName} overlayText={documentName} testId={`${testId}-document-name`} />
                            </div>
                            <div className={styles.documentEntitiesWrapper}>
                                <div className={styles.entityWrapper}>
                                    <div className={styles.documentDetailLabel}>Party A:</div>
                                    <OverflowTooltip className={styles.entityName} overlayText={partyA} testId={`${testId}-entity-a-name`} />
                                </div>
                                <div className={styles.entityWrapper}>
                                    <div className={styles.documentDetailLabel}>Party B:</div>
                                    <OverflowTooltip className={styles.entityName} overlayText={partyB} testId={`${testId}-entity-b-name`} />
                                </div>
                            </div>
                            <div className={styles.documentDescriptionWrapper}>
                                <div className={styles.documentDetailLabel}>Document Description:</div>
                                <div className={styles.documentDetail}>{documentDescription}</div>
                            </div>
                        </div>
                    }
                </div>
                {showDocumentTable && <DocumentRiskToleranceTable height={dimensions?.height || 0} width={dimensions?.width || 0} entityId={entityId} isPreExecution={isPreExecution} />}
                {showDocumentBreakdown && <DocumentRiskToleranceBreakdown documentRiskFields={documentRiskTolerance?.documentRiskFields} height={dimensions?.height || 0} width={dimensions?.width || 0} setShowBreakdown={setDocumentsView} />}
                {showDocumentDataOptions && <DocumentDatasetsRiskTolerance height={dimensions?.height || 0} width={dimensions?.width || 0} fetchRiskTolerance={fetchRiskTolerance} testId={testId} entityId={entityId} isPreExecution={isPreExecution} />}
                {showDocumentFieldBreakdown && <DocumentFieldRiskToleranceTable height={dimensions?.height || 0} width={dimensions?.width || 0} testId={testId} entityId={entityId} isPreExecution={isPreExecution} />}
            </div>
        </div>
    );
};
