import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import classnames from 'classnames';
import { isNull, isUndefined } from 'lodash/fp';

import styles from './Tooltip.module.scss';
import { Colors, tooltipColors } from './Tooltip';
import { Scrollable } from '../scrollable/Scrollable';

interface TooltipListProps {
    children: JSX.Element;
    overlayText: string[] | null;
    color?: keyof Colors;
    width?: number;
    placement?: string;
    trigger?: string;
    fontSize?: number;
    showBulletPoints?: boolean;
    listHeader?: string;
}

const getTextWidth = (overlayText: string[] | null, fontSize: number) => {
    if (!overlayText) {
        return 200;
    }
    const font = 'Manrope, sans-serif';
    const longestString: string = overlayText.sort((a, b) => b.length - a.length)[0];
    // Create a div element with the correct styling to find the width
    const stringDiv = document.createElement('div');
    stringDiv.style.position = 'absolute';
    stringDiv.style.left = '-999px';
    stringDiv.style.whiteSpace = 'nowrap';
    stringDiv.style.font = font;
    stringDiv.style.fontSize = `${fontSize}px`;
    stringDiv.style.padding = '8px 10px';
    stringDiv.style.minHeight = '10px';
    stringDiv.style.borderRadius = '5px';
    stringDiv.style.display = 'flex';
    stringDiv.innerHTML = longestString;
    document.body.appendChild(stringDiv);
    const result = stringDiv.clientWidth;
    // Remove the div
    document.body.removeChild(stringDiv);
    return result;
};

const MAX_WIDTH = 310;
const PADDING_AND_BORDER = 22;

export const TooltipList: React.FC<TooltipListProps> = ({ overlayText, children, width, placement = 'right', color = 'french', trigger = 'hover', fontSize = 11, showBulletPoints = true, listHeader }) => {
    const tooltipText = !isUndefined(listHeader) && !isNull(overlayText) ? [...overlayText, listHeader] : overlayText;
    const tooltipWidth = width || getTextWidth(tooltipText, fontSize);
    const { backgroundColor, fontColor } = tooltipColors[color];

    const overlay = overlayText && overlayText.length && <div className={styles.tooltipListWrapper}>
        {listHeader && <div className={styles.listHeader}>{listHeader}</div>}
        <Scrollable>
            {overlayText.map((tooltip, index) =>
                <div key={index} className={classnames(styles.overlayTextWrapper, {
                    [styles.overlayMarginBottom]: index !== overlayText.length
                })}>
                    <div className={styles.listWrapper}>
                        {showBulletPoints && <div className={styles.overlayBulletPoint} style={{ backgroundColor: fontColor }} />}
                        <div style={{ width: tooltipWidth <= MAX_WIDTH ? `${tooltipWidth}px` : `${MAX_WIDTH}px`, fontSize: `${fontSize}px` }} className={styles.overlayTextStyle}>{tooltip}</div>
                    </div>
                </div>
            )}
        </Scrollable>
    </div>;

    const tooltipStyles = {
        backgroundColor,
        color: fontColor,
        borderColor: fontColor,
        borderRadius: '5px',
        minHeight: '10px',
        display: overlayText && overlayText.length ? 'flex' : 'none'
    };

    const overlayStyle = {
        maxWidth: `${MAX_WIDTH} + ${PADDING_AND_BORDER}px`
    };

    return (
        <Tooltip
            placement={placement}
            overlay={overlay}
            trigger={trigger}
            overlayInnerStyle={tooltipStyles}
            overlayStyle={overlayStyle}
            overlayClassName={styles.tooltipOverlay}
        >
            {children}
        </Tooltip>
    );
};
