import { Reducer } from 'redux';
import { set } from 'lodash/fp';

import { RegistrationActionTypes, RegistrationState } from './types';
import { LoginActionTypes } from '../../login/store';

export const initialRegistrationDetails = {
    name: '',
    companyName: '',
    position: '',
    email: '',
    phone: ''
};

export const INITIAL_STATE: RegistrationState = {
    registrationDetails: initialRegistrationDetails,
    emailValid: false
};

export const registrationReducer: Reducer<RegistrationState> = (state = INITIAL_STATE, { payload, type }): RegistrationState => {
    switch (type) {
        case RegistrationActionTypes.UPDATE_REGISTRATION_DETAILS: {
            const { value, key } = payload;
            return set(`registrationDetails[${key}]`, value, state);
        }
        case RegistrationActionTypes.SET_REGISTRATION_EMAIL_VALID: {
            return set('emailValid', payload, state);
        }
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
