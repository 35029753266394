import { DatasetDefinition, DatasetFieldType } from '../../../datasets/store';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';

export enum RiskToleranceActionTypes {
    FETCH_ALL_RISK_TOLERANCE_STARTED = 'FETCH_ALL_RISK_TOLERANCE_STARTED',
    FETCH_ALL_RISK_TOLERANCE_SUCCESSFUL = 'FETCH_ALL_RISK_TOLERANCE_SUCCESSFUL',
    FETCH_ALL_RISK_TOLERANCE_FAILED = 'FETCH_ALL_RISK_TOLERANCE_FAILED',
    FETCH_RISK_TOLERANCE_BY_DATASET_ID_STARTED = 'FETCH_RISK_TOLERANCE_BY_DATASET_ID_STARTED',
    FETCH_RISK_TOLERANCE_BY_DATASET_ID_SUCCESSFUL = 'FETCH_RISK_TOLERANCE_BY_DATASET_ID_SUCCESSFUL',
    FETCH_RISK_TOLERANCE_BY_DATASET_ID_FAILED = 'FETCH_RISK_TOLERANCE_BY_DATASET_ID_FAILED',
    GO_BACK_TO_ALL_RISK_TOLERANCE_DATASETS = 'GO_BACK_TO_ALL_RISK_TOLERANCE_DATASETS',
    SET_CURRENT_RISK_TOLERANCE_FIELD = 'SET_CURRENT_RISK_TOLERANCE_FIELD',
    UPDATE_RISK_FIELD_CONFIG = 'UPDATE_RISK_FIELD_CONFIG',
    UPDATE_RISK_FIELD_WEIGHTING = 'UPDATE_RISK_FIELD_WEIGHTING',
    SAVE_RISK_TOLERANCE_STARTED = 'SAVE_RISK_TOLERANCE_STARTED',
    SAVE_RISK_TOLERANCE_SUCCESSFUL = 'SAVE_RISK_TOLERANCE_SUCCESSFUL',
    SAVE_RISK_TOLERANCE_FAILED = 'SAVE_RISK_TOLERANCE_FAILED',
    SET_RISK_TOLERANCE_UPDATED = 'SET_RISK_TOLERANCE_UPDATED',
    TOGGLE_UNSAVED_CHANGES_MODAL = 'TOGGLE_RISK_TOLERANCE_UNSAVED_CHANGES_MODAL'
}

export enum RiskAssociated {
    OF_SERIOUS_CONCERN = 4,
    OF_CONCERN = 3,
    ACCEPTABLE = 2,
    DESIRABLE = 1,
    HIGHLY_DESIRABLE = 0
}

export interface RiskAssociatedDetails {
    [RiskAssociated.OF_SERIOUS_CONCERN]: string;
    [RiskAssociated.OF_CONCERN]: string;
    [RiskAssociated.ACCEPTABLE]: string;
    [RiskAssociated.DESIRABLE]: string;
    [RiskAssociated.HIGHLY_DESIRABLE]: string;
}

export const riskAssociatedLabel: RiskAssociatedDetails = {
    [RiskAssociated.OF_SERIOUS_CONCERN]: 'Of Serious Concern',
    [RiskAssociated.OF_CONCERN]: 'Of Concern',
    [RiskAssociated.ACCEPTABLE]: 'Acceptable',
    [RiskAssociated.DESIRABLE]: 'Desirable',
    [RiskAssociated.HIGHLY_DESIRABLE]: 'Highly Desirable'
};

export enum RiskComparator {
    LESS_THAN = '<',
    GREATER_THAN = '>',
    LESS_OR_EQUAL = '<=',
    GREATER_OR_EQUAL = '>=',
    EQUAL = '=',
    NOT_EQUAL = '<>'
}

export interface NumberComparatorRisk {
    value: number | undefined;
    comparator: RiskComparator | null;
}

export interface DateComparatorRisk {
    value: string | null;
    comparator: RiskComparator | null;
}

export interface TimeComparatorRisk {
    value: [string, string];
    comparator: RiskComparator | null;
}

export interface RiskToleranceDataset {
    datasetId: number;
    documentNames: string[];
    agreementType: string;
    agreementTypeId: number;
}

export interface DropdownRiskConfig {
    [RiskAssociated.OF_SERIOUS_CONCERN]: string[];
    [RiskAssociated.OF_CONCERN]: string[];
    [RiskAssociated.ACCEPTABLE]: string[];
    [RiskAssociated.DESIRABLE]: string[];
    [RiskAssociated.HIGHLY_DESIRABLE]: string[];
}

export interface NumberRiskConfig {
    [RiskAssociated.OF_SERIOUS_CONCERN]: NumberComparatorRisk;
    [RiskAssociated.OF_CONCERN]: NumberComparatorRisk;
    [RiskAssociated.ACCEPTABLE]: NumberComparatorRisk;
    [RiskAssociated.DESIRABLE]: NumberComparatorRisk;
    [RiskAssociated.HIGHLY_DESIRABLE]: NumberComparatorRisk;
}

export interface CheckboxRiskConfig {
    [RiskAssociated.OF_SERIOUS_CONCERN]: boolean | undefined;
    [RiskAssociated.OF_CONCERN]: boolean | undefined;
    [RiskAssociated.ACCEPTABLE]: boolean | undefined;
    [RiskAssociated.DESIRABLE]: boolean | undefined;
    [RiskAssociated.HIGHLY_DESIRABLE]: boolean | undefined;
}

export interface DateRiskConfig {
    [RiskAssociated.OF_SERIOUS_CONCERN]: DateComparatorRisk;
    [RiskAssociated.OF_CONCERN]: DateComparatorRisk;
    [RiskAssociated.ACCEPTABLE]: DateComparatorRisk;
    [RiskAssociated.DESIRABLE]: DateComparatorRisk;
    [RiskAssociated.HIGHLY_DESIRABLE]: DateComparatorRisk;
}

export interface TimeRiskConfig {
    [RiskAssociated.OF_SERIOUS_CONCERN]: TimeComparatorRisk;
    [RiskAssociated.OF_CONCERN]: TimeComparatorRisk;
    [RiskAssociated.ACCEPTABLE]: TimeComparatorRisk;
    [RiskAssociated.DESIRABLE]: TimeComparatorRisk;
    [RiskAssociated.HIGHLY_DESIRABLE]: TimeComparatorRisk;
}

export interface CurrencyRiskConfig {
    [currency: string]: NumberRiskConfig;
}

export type RiskToleranceConfig = TimeRiskConfig | DateRiskConfig | CheckboxRiskConfig | NumberRiskConfig | DropdownRiskConfig | CurrencyRiskConfig;
export type RiskAssociatedValue = string[] | NumberComparatorRisk | boolean | undefined | DateComparatorRisk | TimeComparatorRisk;

export interface BaseRiskField {
    fieldId: string;
    sectionId: string | null;
    label: string;
    weighting: number;
    includeRiskField: boolean;
}

export interface DropdownRiskField extends BaseRiskField {
    type: DatasetFieldType.DROPDOWN;
    dropdownLinked: string | null;
    riskConfig: DropdownRiskConfig;
}

export interface NumberRiskField extends BaseRiskField {
    type: DatasetFieldType.NUMBER;
    riskConfig: NumberRiskConfig;
}

export interface CheckboxRiskField extends BaseRiskField {
    type: DatasetFieldType.CHECKBOX;
    riskConfig: CheckboxRiskConfig;
}

export interface DateRiskField extends BaseRiskField {
    type: DatasetFieldType.DATE;
    riskConfig: DateRiskConfig;
}

export interface TimeRiskField extends BaseRiskField {
    type: DatasetFieldType.TIME;
    riskConfig: TimeRiskConfig;
}

export interface CurrencyAmountRiskField extends BaseRiskField {
    type: DatasetFieldType.CURRENCY_AMOUNT;
    riskConfig: CurrencyRiskConfig;
}

export type RiskField = DropdownRiskField | NumberRiskField | CheckboxRiskField | DateRiskField | TimeRiskField | CurrencyAmountRiskField;

export interface BaseRiskTolerance {
    datasetId: number;
    datasetTitle: string;
    config: RiskField[];
}

export interface RiskTolerance extends BaseRiskTolerance {
    riskToleranceId: number;
}

export interface MyRiskTolerance extends BaseRiskTolerance {
    clientRiskToleranceId: number;
}

export interface SelectedRiskField {
    fieldId: string;
    datasetId: number;
}

export interface RiskToleranceState {
    allRiskToleranceDatasets: RiskToleranceDataset[];
    isLoading: boolean;
    fetchError: string | null;
    riskToleranceConfig: RiskTolerance[] | null;
    currentRiskToleranceConfig: RiskTolerance[] | null;
    definitions: DatasetDefinition[];
    currentRiskField: SelectedRiskField | null;
    riskToleranceUpdated: boolean;
    isSaving: boolean;
    unsavedChangesModalOpen: boolean;
    documentHiddenFields: DocumentSpecificHiddenFields;
    selectedRiskToleranceDataset: number | null;
}
