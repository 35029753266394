import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { Spinner } from '../../../../shared/spinner/Spinner';
import { InformationTooltip, OverflowTooltip } from '../../../../shared/tooltip';
import styles from '../../Analytics.module.scss';
import { DoraAnalyticsChart, getContractualDataModalOpen, getFetchingAnalytics, getSelectedContractualDataAnalytics, setSelectedOutstandingContractualDataSection } from '../../store';
import { ContractualInformationModal } from './ContractualInformationModal';
import { ContractualInformationPie } from './ContractualInformationPie';

interface ContractualInformationWrapperProps {
    tileInView: DoraAnalyticsChart | null;
    setTileInView: (tile: DoraAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
    dimensions?: {
        height: number;
        width: number;
    }
}

export const ContractualInformationWrapper: React.FC<ContractualInformationWrapperProps> = ({ dimensions, analyticsSpinner }) => {
    const dispatch = useAppDispatch();

    const testId = 'supply-chain-contractual-information';

    const closeModal = useCallback(() => dispatch(setSelectedOutstandingContractualDataSection('')), [dispatch]);

    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const modalOpen = useAppSelector(getContractualDataModalOpen);
    const contractualInfoData = useAppSelector(getSelectedContractualDataAnalytics);

    const wrapperDimensions = dimensions ? { height: (dimensions.height / 2) - 5, width: dimensions.width } : { height: '100%', width: '100%' };
    const tooltipInfo = 'This pie chart gives you an overview of the total number of companies in your supply chain, which are considered material and are missing either Contractual or Assessment data. Click a segment for further breakdown of information.';

    const showSpinner = useMemo(() => fetchingAnalytics.includes(DoraAnalyticsChart.CONTRACTUAL_DATA), [fetchingAnalytics]);

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.networkDiagramWrapper} style={wrapperDimensions} data-testid={`pie-chart-wrapper-${testId}`}>
            <div className={styles.networkDiagramHeader}>
                <OverflowTooltip className={styles.networkDiagramTitle} overlayText='Supply Chain Contractual Information' testId={`${testId}-title`} />
                <div className={styles.networkDiagramDescription}><InformationTooltip content={tooltipInfo} /></div>
            </div>
            <ContractualInformationPie testId={testId} dimensions={dimensions} />
            <ContractualInformationModal
                isOpen={modalOpen}
                closeModal={closeModal}
                contractualInfoData={contractualInfoData}
            />
        </div>
    );
};
