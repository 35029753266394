import { getOr } from 'lodash/fp';
import React, { useMemo } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { customStyles, DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { ConfirmationModal } from '../../../../shared/modal/ConfirmationModal';
import { SettingsModal, updateSettingsValue } from '../../store';
import styles from '../DatasetComponents.module.scss';

interface DocumentPropertyModalProps {
    isOpen: boolean;
    closeModal: () => void;
    settingsModalIndex: SettingsModal;
    value: string | null | undefined;
}

const documentPropertyOptions: DropdownOption[] = [
    { label: 'Document Name', value: 'documentName' },
    { label: 'Party A', value: 'entityAName' },
    { label: 'Party B', value: 'entityBName' },
    { label: 'Date of Execution', value: 'executedDate' },
    { label: 'Dated as of', value: 'datedAsOf' }
];

export const DocumentPropertyModal: React.FC<DocumentPropertyModalProps> = ({ isOpen, closeModal, settingsModalIndex, value }) => {
    const dispatch = useAppDispatch();
    const { index, groupIndex } = settingsModalIndex;

    const selectedProperty = useMemo(() => documentPropertyOptions.find(option => option.value === value) || null, [value]);

    const selectDocumentProperty = (selected: SingleValue<DropdownOption> | MultiValue<DropdownOption> | null) => dispatch(updateSettingsValue('documentProperty', getOr(null, 'value', selected), index!, groupIndex));

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            showConfirm={false}
            closeLabel='Close'
            testId='document-property'
            showOverlay={false}
        >
            <div className={styles.settingsModalLinkWrapper} data-testid='document-property-wrapper'>
                <div className={styles.settingsModalLinkTitle}>Select Document Property</div>
                <div className={styles.settingsModalLinkDropdown}>
                    <Select
                        classNamePrefix='ark-dropdown'
                        value={selectedProperty}
                        onChange={selectDocumentProperty}
                        options={documentPropertyOptions}
                        isClearable={true}
                        styles={customStyles}
                        fontWeight={500}
                    />
                </div>
            </div>
        </ConfirmationModal>
    );
};
