import React from 'react';

import { TableDatasetDefinition, TableDatasetFieldType } from '../../../../datasets/store';
import { TableHeader } from './TableHeader';
import { TableCell } from './TableCell';
import { useWindowResize } from '../../../../../hooks/useWindowResize';
import styles from './TablePreview.module.scss';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';

interface TablePreviewProps {
    datasetDefinition: TableDatasetDefinition
}

export const TablePreview: React.FC<TablePreviewProps> = ({ datasetDefinition }) => {
    const [, screenHeight] = useWindowResize();

    // Modal height subtract the combination of static fixed height divs to give a px value of remaining space
    const contentHeight = screenHeight * 0.8 - 107;

    return (
        <div className={styles.tableInstanceWrapper} style={{ height: `${contentHeight}px` }} data-testid='table-dataset-builder-preview-wrapper'>
            <Scrollable>
                <div className={styles.tableHeader}>
                    {datasetDefinition.datasetFields.map(({ label, description, refLabel, settings, id, type }, index) => {
                        const isLastCell = index === datasetDefinition.datasetFields.length - 1;
                        return (
                            <TableHeader
                                key={id!}
                                label={label}
                                description={description}
                                refLabel={refLabel}
                                settings={settings}
                                id={id!}
                                type={type as TableDatasetFieldType}
                                isLastCell={isLastCell}
                                isFirstCell={index === 0}
                            />
                        );
                    })}
                </div>
                <div className={styles.tableRow}>
                    {datasetDefinition.datasetFields.map((field, index) => {
                        const isLastCell = index === datasetDefinition.datasetFields.length - 1;
                        return (
                            <TableCell key={field.id!} field={field} index={index} isLastCell={isLastCell} isFirstCell={index === 0} />
                        );
                    })}
                </div>
            </Scrollable>
        </div>
    );
};
