import React, { useMemo } from 'react';
import { isNull } from 'lodash/fp';

import styles from './Documents.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { createNewEntityFromMLDataStarted, getIncompleteMLData, getIsCreatingEntity, getMLCompletedModalOpen, toggleMLCompletedModal } from './store';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Analytics, Checkbox } from '../../shared/icons';
import { Button } from '../../shared/button/Button';
import { Spinner } from '../../shared/spinner/Spinner';

const { lightGrey } = styles;

interface UnknownEntityParams {
    entityName: string;
    entityA?: boolean;
}

const UnknownEntity: React.FC<UnknownEntityParams> = ({ entityName, entityA }) => {
    const dispatch = useAppDispatch();
    const isCreatingEntity = useAppSelector(getIsCreatingEntity);
    const createNewEntity = () => dispatch(createNewEntityFromMLDataStarted(entityName, entityA ? 'entityA' : 'entityB'));

    return (
        <div className={styles.unknownEntityWrapper}>
            <div className={styles.unknownEntityInfoWrapper}>
                <div className={styles.unknownEntityInfo} style={{ fontWeight: 600, marginBottom: '3px' }}>Party {entityA ? 'A' : 'B'} not found</div>
                <div className={styles.unknownEntityInfo}>We have identified an entity that we believe is Party {entityA ? 'A' : 'B'}.</div>
                <div className={styles.unknownEntityInfo}>But the entity does not exist in our system and we were unable to find details in an LEI search.</div>
                <div className={styles.unknownEntityInfo} style={{ padding: '5px 0', fontWeight: 600 }}>Would you like us to create a new entity under the name <span style={{ fontWeight: 800, border: `1px solid ${lightGrey}`, padding: '3px', borderRadius: '5px', margin: '0 3px' }}>{entityName}</span>?</div>
            </div>
            <div className={styles.unknownEntityButtonWrapper}>
                {isCreatingEntity ? <Spinner /> : <Button label='Create' onClick={createNewEntity} />}
            </div>
        </div>
    );
};

const GENERIC_ENTITY_NAMES = ['pledgor', 'secured party', 'party a', 'party b'];

export const MLCompletedModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getMLCompletedModalOpen);
    const mlData = useAppSelector(getIncompleteMLData);

    const closeModal = () => dispatch(toggleMLCompletedModal(false));

    const entityAUnknown = useMemo(() => !isNull(mlData) && mlData.entityA === 0 && (typeof mlData.entityAName === 'string' && !GENERIC_ENTITY_NAMES.includes(mlData.entityAName.toLowerCase())), [mlData]);
    const entityBUnknown = useMemo(() => !isNull(mlData) && mlData.entityB === 0 && (typeof mlData.entityBName === 'string' && !GENERIC_ENTITY_NAMES.includes(mlData.entityBName.toLowerCase())), [mlData]);
    const entityASuggestedName = useMemo(() => !isNull(mlData) ? mlData.entityAName as string : '', [mlData]);
    const entityBSuggestedName = useMemo(() => !isNull(mlData) ? mlData.entityBName as string : '', [mlData]);

    if (isNull(mlData)) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            showConfirm={false}
            closeIcon={Checkbox}
            closeLabel='I understand'
            testId='ml-completed'
        >
            <div className={styles.mlCompletedWrapper} data-testid='ml-completed-wrapper'>
                <ModalHeader label='AI Populated Data' icon={Analytics} />
                <div className={styles.mlCompletedBody}>
                    <div className={styles.mlCompleted}>We have attempted to complete details of your document using artificial intelligence</div>
                    <div className={styles.mlCompleted}>There is a possibility that some of the information is missing or incorrect</div>
                    {entityAUnknown && <UnknownEntity entityName={entityASuggestedName} entityA />}
                    {entityBUnknown && <UnknownEntity entityName={entityBSuggestedName} />}
                    <div className={styles.mlCheckWarning}>Please check that these details are correct before completing the document</div>
                </div>
            </div>
        </ConfirmationModal>
    );
};
