import { RawDraftContentState } from 'draft-js';
import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';

import { ClauseModalTags } from './ClauseModalTags';
import { ClauseUnsavedChangesConfirmationModal } from './ClauseUnsavedChangesConfirmationModal';
import { Clause, ClauseTableTab, ClauseTagDropdownOptions, ClauseTags, ClientTag, FuzzyMatchTags, getLinkedClauseOpinions, redirectToOpinion, setClauseTableTab } from '../../clause-library/store';
import { Icon } from '../icon/Icon';
import { ArrowLeft, CaretDown, CaretSide, Delete, Duplicate, LinkChain, Pencil, SaveFile } from '../icons';
import { LongText } from '../longtext/LongText';
import { ModalHeader } from '../modal/ModalHeader';
import { Text } from '../text/Text';
import { Toggle } from '../toggle';
import { isEmpty, WYSIWYG } from '../wysiwyg/WYSIWYG';
import styles from './ClauseModal.module.scss';
import { OpinionClause } from '../../opinions/instances/store';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { ButtonResize } from '../button/ButtonResize';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasFeaturePermission } from '../../auth/login/store';
import { Position } from '../modal/PositionModal';
import { IconTooltip } from '../tooltip';
import { LinkedOpinionsModal } from './LinkedOpinionsModal';

const { lightGrey } = styles;

const MODAL_HEADER = 56;
const CLAUSE_TITLE = 50;
const SYSTEM_ADMIN_TOGGLE = 32;
const BUTTON_WIDTH = 154;

interface ClauseModalProps {
    clause: Clause;
    clauseCanSave: boolean;
    descriptionOpen: boolean;
    toggleClauseModal: () => void;
    updateClause: (key: keyof Clause, value: string | string[] | RawDraftContentState | null | number) => void;
    saveClause: (closeModal: boolean) => void;
    toggleDeleteClauseModal: (deleteClauseId: number | null) => void;
    duplicateClause: (duplicateClauseId: number, isSave: boolean) => void;
    toggleClauseDescription: () => void;
    tagTerm: string;
    clauseLibraryDropdownOptions: ClauseTagDropdownOptions;
    fuzzyTags: FuzzyMatchTags[];
    fuzzyMatchModalOpen: boolean;
    allClientTags: ClientTag[];
    updateClauseTag: (key: keyof ClauseTags, value: string[] | null) => void;
    updateTagTerm: (value: string) => void;
    checkFuzzyMatch: () => void;
    addClientTagStarted: (value: string) => void;
    setFuzzyMatchModalOpen: (value: boolean) => void;
    hasSystemClausePermission: boolean;
    showConfirmationModal: boolean;
    setShowConfirmationModal: (value: boolean) => void;
    testId?: string;
    showDelete?: boolean;
    opinionClauseLinking?: (clauseId: number) => void;
    opinionClauses?: OpinionClause[];
    allClauseLibraryTags: string[];
    opinionClauseTable?: boolean;
}

export const ClauseBuilder: React.FC<ClauseModalProps> = ({
    clause,
    clauseCanSave,
    descriptionOpen,
    toggleClauseModal,
    updateClause,
    saveClause,
    toggleDeleteClauseModal,
    duplicateClause,
    toggleClauseDescription,
    tagTerm,
    clauseLibraryDropdownOptions,
    fuzzyTags,
    fuzzyMatchModalOpen,
    allClientTags,
    updateClauseTag,
    updateTagTerm,
    checkFuzzyMatch,
    addClientTagStarted,
    setFuzzyMatchModalOpen,
    hasSystemClausePermission,
    showConfirmationModal,
    setShowConfirmationModal,
    testId = 'clause',
    showDelete = true,
    opinionClauseLinking,
    opinionClauses,
    allClauseLibraryTags,
    opinionClauseTable = false
}) => {
    const dispatch = useAppDispatch();

    const [position, setPosition] = useState<Position | null>(null);
    const [positionModalOpen, setPositionModalOpen] = useState<boolean>(false);

    const hasAddClausePermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.ADD_CLAUSES]));
    const linkedClauseOpinions = useAppSelector(getLinkedClauseOpinions);
    const opinionLinks = useMemo(() => linkedClauseOpinions.filter(({ clauseId }) => clauseId === clause.clauseId), [linkedClauseOpinions, clause]);

    const { title, description, content, systemTemplate, clauseId } = clause;
    const descriptionOpenIcon = useMemo(() => descriptionOpen ? CaretDown : CaretSide, [descriptionOpen]);
    const descriptionWrapperHeight = useMemo(() => descriptionOpen ? '25%' : '7%', [descriptionOpen]);
    const contentWrapperHeight = useMemo(() => descriptionOpen ? '65%' : '83%', [descriptionOpen]);

    const isOpenViaOpinion = useMemo(() => !isUndefined(opinionClauses) && !opinionClauseTable, [opinionClauses, opinionClauseTable]);

    const isTemplate = useMemo(() => systemTemplate === 1, [systemTemplate]);

    const modalHeaderLabel = useMemo(() => isUndefined(clauseId) ? `Create a new Clause ${isTemplate ? ' Template' : ''}` : 'Update Clause', [clauseId, isTemplate]);

    const userNotPermitted = useMemo(() => (isTemplate && !hasSystemClausePermission) || (!isTemplate && !hasAddClausePermission), [isTemplate, hasSystemClausePermission, hasAddClausePermission]);
    const inputDisabled = useMemo(() => userNotPermitted, [userNotPermitted]);
    const cursor = useMemo(() => userNotPermitted ? 'not-allowed' : 'auto', [userNotPermitted]);

    const closeModal = useCallback(() => clauseCanSave ? setShowConfirmationModal(true) : toggleClauseModal(), [clauseCanSave, toggleClauseModal, setShowConfirmationModal]);

    const updateCurrentClause = useCallback((key: keyof Clause, value: RawDraftContentState | null | string | number) => updateClause(key, value), [updateClause]);

    const updateClauseWysiwyg = (rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            if (!isNull(content)) {
                updateCurrentClause('content', null);
            }
        } else {
            updateCurrentClause('content', rawValue);
        }
    };

    const closeConfirmationModal = useCallback(() => setShowConfirmationModal(false), [setShowConfirmationModal]);
    const closeWithoutSaving = useCallback(() => {
        setShowConfirmationModal(false);
        toggleClauseModal();
    }, [toggleClauseModal, setShowConfirmationModal]);

    const isClauseLibraryAndHasPermission = isUndefined(opinionClauses) && hasSystemClausePermission;

    const clauseModalContentHeight = useMemo(() => `calc(100% - ${MODAL_HEADER + CLAUSE_TITLE + (isClauseLibraryAndHasPermission ? SYSTEM_ADMIN_TOGGLE : 0)}px)`, [isClauseLibraryAndHasPermission]);

    const save = useCallback((close: boolean) => saveClause(close), [saveClause]);
    const deleteClause = useCallback(() => toggleDeleteClauseModal(clauseId!), [toggleDeleteClauseModal, clauseId]);
    const duplicate = useCallback(() => {
        duplicateClause(clauseId!, clauseCanSave);
        if (clauseCanSave) {
            saveClause(false);
        }
    }, [clauseId, clauseCanSave, duplicateClause, saveClause]);

    const saveDisabledTooltip = useMemo(() => (!clauseCanSave && title === '') ? ['A Clause must have a title'] : null, [clauseCanSave, title]);

    const toggleDescription = useCallback(() => toggleClauseDescription(), [toggleClauseDescription]);

    const showDeleteButton = useMemo(() => !!clauseId && showDelete && !userNotPermitted, [clauseId, showDelete, userNotPermitted]);
    const clauseIsLinkedToOpinion = useMemo(() => opinionClauses ? opinionClauses.map(({ clauseId }) => clauseId).includes(clause.clauseId!) : false, [opinionClauses, clause]);
    const opinionClauseLinkingLabel = useMemo(() => `${clauseIsLinkedToOpinion ? 'Unlink from' : 'Link To'} Opinion`, [clauseIsLinkedToOpinion]);
    const duplicateLabel = useMemo(() => !clauseCanSave ? 'Duplicate' : 'Save as Duplicate', [clauseCanSave]);

    const updateIsSystemTemplate = () => updateCurrentClause('systemTemplate', systemTemplate === 0 ? 1 : 0);

    const [width] = useWindowResize();
    const modalWidth = useMemo(() => width / 100 * 80, [width]);
    const buttonWrapperWidth = modalWidth - 20;
    const numberOfButtons = clauseId ? 5 : 3;
    const showIconButtons = useMemo(() => BUTTON_WIDTH * numberOfButtons > buttonWrapperWidth, [numberOfButtons, buttonWrapperWidth]);

    const openLinkedOpinionsModal = useCallback((x: number, y: number) => {
        setPosition({ x, y });
        setPositionModalOpen(true);
    }, []);

    const closeLinkedOpinionsModal = useCallback(() => setPositionModalOpen(false), []);

    const redirect = useCallback((opinionId: number, location: string) => {
        dispatch(redirectToOpinion(opinionId, location));
        dispatch(setClauseTableTab(ClauseTableTab.CLIENT_CLAUSE_LIBRARY));
        closeWithoutSaving();
    }, [dispatch, closeWithoutSaving]);

    const showOpinionsLink = useMemo(() => opinionLinks.length > 0 && isUndefined(opinionClauses), [opinionLinks, opinionClauses]);

    return (
        <>
            <div className={styles.clauseWrapper} data-testid={`${testId}-modal-wrapper`}>
                <ModalHeader label={modalHeaderLabel} icon={Pencil} testId={`${testId}-modal`} />
                {isClauseLibraryAndHasPermission && !isUndefined(clauseId) &&
                    <div className={styles.isSystemWrapper} data-testid={`${testId}-modal-is-system-template-wrapper`}>
                        <div className={styles.systemTemplateLabel} data-testid={`${testId}-modal-is-system-template-label`}>Are you creating a system template?</div>
                        <Toggle
                            checked={isTemplate}
                            onChange={updateIsSystemTemplate}
                            testId={`${testId}-modal-is-system-template-toggle`}
                        />
                    </div>
                }
                {isClauseLibraryAndHasPermission && isUndefined(clauseId) && isTemplate &&
                    <div className={styles.isSystemWrapper} data-testid={`${testId}-modal-is-system-template-wrapper`}>
                        <div className={styles.systemTemplateInfo} data-testid={`${testId}-modal-is-system-template-info`}>Note: You are creating a template clause, this will be available to all ARK-51 clients.</div>
                    </div>
                }
                <div className={styles.clauseModalContent} style={{ height: clauseModalContentHeight }}>
                    <div className={styles.clauseContent}>
                        <div className={styles.clauseTitleWrapper} data-testid={`${testId}-modal-title-wrapper`}>
                            <div className={styles.clauseTitleLabel} data-testid={`${testId}-modal-title-label`}>Clause Title: </div>
                            <div className={styles.clauseTitleInput}>
                                <Text
                                    onChange={e => updateCurrentClause('title', e.target.value)}
                                    value={title}
                                    maxLength={1024}
                                    testId={`${testId}-modal-title`}
                                    placeholder='Clause Title'
                                    marginBottom='0px'
                                    borderColour={lightGrey}
                                    disabled={inputDisabled}
                                    cursor={cursor}
                                />
                            </div>
                        </div>
                        <div className={styles.clauseDescriptionWrapper} style={{ height: descriptionWrapperHeight }} data-testid={`${testId}-modal-description-wrapper`}>
                            <div className={styles.clauseDescriptionLabelWrapper} data-testid={`${testId}-modal-description-label-wrapper`}>
                                <div className={styles.clauseDescriptionLabel} data-testid={`${testId}-modal-description-label`}>Clause Description: </div>
                                <div onClick={toggleDescription} className={styles.buildSectionCollapseIcon} data-testid={`${testId}-modal-description-collapse-icon`}>
                                    <Icon icon={descriptionOpenIcon} fontSize={20} />
                                </div>
                            </div>
                            {descriptionOpen &&
                                <div className={styles.clauseDescriptionInput}>
                                    <LongText
                                        onChange={e => updateCurrentClause('description', e)}
                                        value={description}
                                        maxLength={2048}
                                        testId={`${testId}-modal-description`}
                                        placeholder='Clause Description'
                                        marginBottom='0px'
                                        wrapperHeight='100%'
                                        width='100%'
                                        lightBorder
                                        disabled={inputDisabled}
                                        cursor={cursor}
                                    />
                                </div>
                            }
                        </div>
                        <div className={styles.clauseContentInput} style={{ height: contentWrapperHeight }} data-testid={`${testId}-modal-content-wrapper`}>
                            <div className={styles.clauseContentLabelWrapper} data-testid={`${testId}-modal-content-label-wrapper`}>
                                <div className={styles.clauseContentLabel} data-testid={`${testId}-modal-content-label`}>Clause Content:</div>
                                {showOpinionsLink && <div className={styles.linkedOpinions} onClick={e => openLinkedOpinionsModal(e.clientX, e.clientY)}>
                                    <IconTooltip content='Opinions this clause is linked to' fontSize={25} icon={LinkChain} placement='top' cursor='pointer' />
                                </div>}
                            </div>
                            <WYSIWYG
                                content={content}
                                updateContent={value => updateClauseWysiwyg(value)}
                                showBorder={false}
                                height='calc(100% - 50px)'
                                minHeight='calc(100% - 50px)'
                                maxHeight='calc(100% - 50px)'
                                disabled={inputDisabled}
                                toolbarHidden={inputDisabled}
                            />
                        </div>
                    </div>
                    <ClauseModalTags
                        clause={clause}
                        tagTerm={tagTerm}
                        clauseLibraryDropdownOptions={clauseLibraryDropdownOptions}
                        fuzzyTags={fuzzyTags}
                        fuzzyMatchModalOpen={fuzzyMatchModalOpen}
                        allClientTags={allClientTags}
                        updateClauseTag={updateClauseTag}
                        updateTagTerm={updateTagTerm}
                        checkFuzzyMatch={checkFuzzyMatch}
                        addClientTagStarted={addClientTagStarted}
                        setFuzzyMatchModalOpen={setFuzzyMatchModalOpen}
                        inputDisabled={inputDisabled}
                        testId={testId}
                        isOpenViaOpinion={isOpenViaOpinion}
                        allClauseLibraryTags={allClauseLibraryTags}
                    />
                </div>
                <div className={styles.buttonWrapper} data-testid={`${testId}-modal-buttons-wrapper`}>
                    <div className={styles.leftButtonGroup} data-testid={`${testId}-modal-left-button-group-wrapper`}>
                        <ButtonResize showIconButton={showIconButtons} onClick={closeModal} label='Close' icon={ArrowLeft} />
                        {showDeleteButton && <ButtonResize showIconButton={showIconButtons} disabled={userNotPermitted} onClick={deleteClause} label='Delete' icon={Delete} />}
                    </div>
                    <div className={styles.rightButtonGroup} data-testid={`${testId}-modal-right-button-group-wrapper`}>
                        {(opinionClauseLinking && clauseId) && <ButtonResize showIconButton={showIconButtons} onClick={() => opinionClauseLinking(clause.clauseId!)} label={opinionClauseLinkingLabel} icon={LinkChain} />}
                        {hasAddClausePermission && clauseId && <ButtonResize showIconButton={showIconButtons} onClick={duplicate} label={duplicateLabel} icon={Duplicate} />}
                        {!inputDisabled && <ButtonResize showIconButton={showIconButtons} onClick={() => save(false)} disabledTooltip={saveDisabledTooltip} disabled={!clauseCanSave} label='Save' icon={SaveFile} />}
                        {!inputDisabled && <ButtonResize showIconButton={showIconButtons} onClick={() => save(true)} disabledTooltip={saveDisabledTooltip} disabled={!clauseCanSave} label='Save and Close' icon={SaveFile} />}
                    </div>
                </div>
            </div>
            <ClauseUnsavedChangesConfirmationModal
                isOpen={showConfirmationModal}
                close={closeConfirmationModal}
                confirm={closeWithoutSaving}
            />
            <LinkedOpinionsModal
                modalOpen={positionModalOpen}
                closeModal={closeLinkedOpinionsModal}
                position={position}
                opinionLinks={opinionLinks}
                redirect={redirect}
            />
        </>
    );
};
