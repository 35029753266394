import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide, Engine } from '../../../shared/icons';
import { IconTooltip, TooltipList } from '../../../shared/tooltip';
import { setSelectedOpinionPage } from '../../my-opinions/store';
import { addFieldPageRef, removeFieldPageRef, toggleRefModalOpen, updateFieldPageRef, updateFieldPageRefVerified, updateFieldRef, updateFieldValue } from '../store/actions';
import { DropdownDetailsSignOffConversation, OpinionFieldValue, OpinionInstanceField, OpinionInstanceFieldType, SignOffConversation, UpdatedOpinionField } from '../store/types';
import { DateField } from './DateField';
import { Dropdown } from './Dropdown';
import { DropdownDetails } from './DropdownDetails';
import { Entity } from './Entity';
import styles from './Fields.module.scss';
import { LongText } from './LongText';
import { Ref } from './Ref';
import { Text } from './Text';
import { Toggle } from './Toggle';
import { WYSIWYGField } from './WYSIWYGField';
import { getFieldSignOffNotes, getIsFirstTimelineEntry } from '../store';

export const getField = (
    field: OpinionInstanceField,
    showFieldUpdated: boolean,
    sectionId: string,
    fieldId: string,
    disabled: boolean,
    updateValue: (value: OpinionFieldValue) => void,
    fieldOpen: boolean,
    positiveAnswers?: string[],
    negativeAnswers?: string[],
    signOffConversation?: SignOffConversation
) => {
    const id = `${sectionId}-${fieldId}`;
    const defaultProps = { id, sectionId, fieldId, disabled, showFieldUpdated };
    switch (field.type) {
        case OpinionInstanceFieldType.TEXT:
            return <Text {...defaultProps} value={field.value} updateValue={updateValue} signOffConversation={signOffConversation} />;
        case OpinionInstanceFieldType.LONGTEXT:
            return <LongText {...defaultProps} value={field.value} updateValue={updateValue} />;
        case OpinionInstanceFieldType.DATE:
            return <DateField {...defaultProps} value={field.value} updateValue={updateValue} signOffConversation={signOffConversation} />;
        case OpinionInstanceFieldType.DROPDOWN:
            return <Dropdown
                {...defaultProps}
                value={field.value}
                isMulti={field.isMulti}
                updateValue={updateValue}
                dropdownList={field.dropdownList}
                signOffConversation={signOffConversation}
            />;
        case OpinionInstanceFieldType.ENTITY:
            return <Entity
                {...defaultProps}
                value={field.value}
                updateValue={updateValue}
                signOffConversation={signOffConversation}
            />;
        case OpinionInstanceFieldType.TOGGLE:
            return <Toggle {...defaultProps} value={field.value} updateValue={updateValue} />;
        case OpinionInstanceFieldType.WYSIWYG:
            return <WYSIWYGField
                {...defaultProps}
                value={field.value}
                toolbarHidden={disabled}
                updateValue={updateValue}
                fieldOpen={fieldOpen}
                signOffConversation={signOffConversation}
            />;
        case OpinionInstanceFieldType.DROPDOWN_DETAILS:
            return <DropdownDetails
                {...defaultProps}
                wysiwygValue={field.wysiwygValue}
                dropdownValue={field.dropdownValue}
                fieldOpen={fieldOpen}
                dropdownList={field.dropdownList}
                positiveAnswers={positiveAnswers}
                negativeAnswers={negativeAnswers}
                signOffConversation={signOffConversation as DropdownDetailsSignOffConversation}
            />;
        default:
            return null;
    }
};

interface SingleFieldProps {
    field: OpinionInstanceField;
    sectionId: string;
    fieldId: string;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
    width?: string;
    isLastField?: boolean;
    getFieldOpen: (id: string) => boolean;
    toggleFieldOpen: (id: string) => void;
    singleSectionField?: boolean;
    positiveAnswers?: string[];
    negativeAnswers?: string[];
    guidanceNotes?: string[];
    isNettingEngineField?: boolean;
}

export const SingleField: React.FC<SingleFieldProps> = ({
    field,
    fieldId,
    width = '100%',
    isLastField = false,
    sectionId,
    isEditing,
    isUpdating,
    fieldsUpdated,
    getFieldOpen,
    toggleFieldOpen,
    singleSectionField = false,
    positiveAnswers,
    negativeAnswers,
    guidanceNotes,
    isNettingEngineField = false
}) => {
    const dispatch = useAppDispatch();
    const isFirstTimelineEntry = useAppSelector(getIsFirstTimelineEntry);
    const signOffConversation = useAppSelector(getFieldSignOffNotes(sectionId, fieldId));
    const [windowWidth] = useWindowResize();
    const { label, ref, refOpen, pageRef, pageRefVerified } = field;
    const id = `${sectionId}-${fieldId}`;
    const fieldUpdatedByInstance = useMemo(() => fieldsUpdated.map(({ fieldId, sectionId }) => `${sectionId}-${fieldId}`).includes(id), [fieldsUpdated, id]);
    const showFieldUpdated = fieldUpdatedByInstance && !isUpdating && !isEditing && !isFirstTimelineEntry;
    const showGuidanceNotes = useMemo(() => guidanceNotes && guidanceNotes.length && (isEditing || isUpdating), [guidanceNotes, isEditing, isUpdating]);
    const disabled = !isEditing && !(isUpdating && fieldUpdatedByInstance);
    const fieldOpen = useMemo(() => getFieldOpen(id) || singleSectionField, [getFieldOpen, id, singleSectionField]);
    const fieldOpenIcon = fieldOpen ? CaretDown : CaretSide;
    const isCollapsible = [OpinionInstanceFieldType.WYSIWYG].includes(field.type);

    const updateValue = useCallback((value: OpinionFieldValue) => { dispatch(updateFieldValue(sectionId, fieldId, value)); }, [dispatch, sectionId, fieldId]);
    const updateRefLabel = useCallback((value: string) => { dispatch(updateFieldRef(sectionId, fieldId, value)); }, [dispatch, sectionId, fieldId]);
    const updatePageRef = useCallback((value: string, index: number) => { dispatch(updateFieldPageRef(sectionId, fieldId, index, value)); }, [dispatch, sectionId, fieldId]);
    const verifyPageRefs = useCallback((checked: boolean) => { dispatch(updateFieldPageRefVerified(sectionId, fieldId, checked)); }, [dispatch, sectionId, fieldId]);
    const toggleRefOpen = useCallback((isOpen: boolean) => { dispatch(toggleRefModalOpen(sectionId, fieldId, isOpen)); }, [dispatch, sectionId, fieldId]);
    const scrollToPage = useCallback((pageNumber: number) => { dispatch(setSelectedOpinionPage(pageNumber)); }, [dispatch]);

    const addPageRef = useCallback(() => { dispatch(addFieldPageRef(sectionId, fieldId)); }, [dispatch, sectionId, fieldId]);
    const removePageRef = useCallback((index: number) => { dispatch(removeFieldPageRef(sectionId, fieldId, index)); }, [dispatch, sectionId, fieldId]);

    return (
        <div
            className={classnames(styles.singleFieldWrapper, { [styles.lastFieldInGroup]: isLastField })}
            style={{ width }}
            data-testid={`opinion-field-${id}-wrapper`}
        >
            <div className={classnames(styles.fieldTitleWrapper, { [styles.fieldCanCollapse]: isCollapsible })} onClick={() => isCollapsible && toggleFieldOpen(id)}>
                {isCollapsible && !singleSectionField &&
                    <div className={styles.fieldOpenWrapper} data-testid='collapsible-field'>
                        <Icon icon={fieldOpenIcon} fontSize={15} />
                    </div>
                }
                <div className={styles.fieldLabel} data-testid={`opinion-field-${id}-label`}>{label}</div>
                {showGuidanceNotes &&
                    <TooltipList overlayText={guidanceNotes!} width={windowWidth / 4} showBulletPoints={false}>
                        <div className={styles.informationTooltipIcon}>i</div>
                    </TooltipList>
                }
                {isNettingEngineField && <IconTooltip content='This field is used in the Netting Engine analysis' icon={Engine} />}
                <Ref
                    id={id}
                    refLabel={ref}
                    modalOpen={refOpen}
                    readOnly={disabled}
                    toggleRefOpen={toggleRefOpen}
                    updateRefLabel={updateRefLabel}
                    updatePageRef={updatePageRef}
                    addPageRef={addPageRef}
                    removePageRef={removePageRef}
                    verifyPageRefs={verifyPageRefs}
                    scrollToPage={scrollToPage}
                    pageRef={pageRef}
                    pageRefVerified={pageRefVerified}
                />
            </div>
            {getField(
                field,
                showFieldUpdated,
                sectionId,
                fieldId,
                disabled,
                updateValue,
                fieldOpen,
                positiveAnswers,
                negativeAnswers,
                signOffConversation
            )}
        </div>
    );
};
