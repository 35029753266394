import { action } from 'typesafe-actions';

import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { AdminOpinionStateActionTypes, SelectedOpinionAnalytics } from './types';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

// Fetch Pie Analytics
export const fetchAdminOpinionAnalyticsStarted = () => action(AdminOpinionStateActionTypes.FETCH_ADMIN_OPINION_ANALYTICS_STARTED);
export const fetchAdminOpinionAnalyticsSuccessful = (data: PieChartAnalyticsData[]) => action(AdminOpinionStateActionTypes.FETCH_ADMIN_OPINION_ANALYTICS_SUCCESSFUL, data);
export const fetchAdminOpinionAnalyticsFailed = (e: string) => action(AdminOpinionStateActionTypes.FETCH_ADMIN_OPINION_ANALYTICS_FAILED, e);

// Fetch Table Opinions
export const fetchOpinionsByDateHealthCategoryStarted = (healthIndicator: string, pageNumber = 1) => action(AdminOpinionStateActionTypes.FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_STARTED, { healthIndicator, pageNumber });
export const fetchOpinionsByDateHealthCategorySuccessful = (data: SelectedOpinionAnalytics[], total: number) => action(AdminOpinionStateActionTypes.FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_SUCCESSFUL, { data, total });
export const fetchOpinionsByDateHealthCategoryFailed = (e: string) => action(AdminOpinionStateActionTypes.FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_FAILED, e);

// Admin Opinions Table
export const selectedOpinionsPaginationNext = () => action(AdminOpinionStateActionTypes.SELECTED_OPINIONS_PAGINATION_NEXT);
export const selectedOpinionsPaginationPrevious = () => action(AdminOpinionStateActionTypes.SELECTED_OPINIONS_PAGINATION_PREVIOUS);
export const setSelectedOpinionsTablePageSize = (pageSize: number) => action(AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_PAGE_SIZE, pageSize);
export const setSelectedOpinionsTableColumnSort = (columnSort: ColumnSort | undefined) => action(AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_COLUMN_SORT, columnSort);
export const setSelectedOpinionsTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_TABLE_FILTERS, { key, value, type });
export const clearSelectedOpinionsTableFilters = () => action(AdminOpinionStateActionTypes.CLEAR_SELECTED_OPINIONS_TABLE_FILTERS);
