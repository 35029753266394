import React from 'react';
import classnames from 'classnames';

import { Icon } from '../icon/Icon';
import styles from './Wizard.module.scss';

export interface WizardTabProps {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    selected: boolean;
    icon?: React.FC;
    testId?: string;
}

export const WizardTab: React.FC<WizardTabProps> = ({ label = '', onClick, disabled = false, selected, icon, testId }) => (
    <div className={styles.wizardTabWrapper}>
        <button
            onClick={onClick}
            className={classnames(styles.wizardTab, {
                [styles.selectedTab]: selected
            })}
            disabled={disabled}
            data-testid={`${testId}-wizard-tab-${label.toLowerCase().replace(/ +/g, '-').replace(/&/g, 'and')}`}
        >
            <div className={styles.content} data-testid={`${testId}-wizard-tab-${label.toLowerCase().replace(/ +/g, '-').replace(/&/g, 'and')}-label`}>
                {icon && <Icon icon={icon} />}
                <div style={{ marginLeft: icon ? '5px' : '0' }}>{label}</div>
            </div>
        </button>
    </div>
);
