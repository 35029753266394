import React from 'react';
import { Options } from 'react-select';

import styles from './Filter.module.scss';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';

interface FilterDropdownWithLabel {
    value: DropdownOption | DropdownOption[] | null;
    options: DropdownOption[];
    onChange: (option: DropdownOption | Options<DropdownOption> | null) => void;
    label: string;
    fontSize: string;
    testId?: string;
    isMulti?: boolean;
    isClearable?: boolean;
}

export const FilterDropdownWithLabel: React.FC<FilterDropdownWithLabel> = ({ value, options, onChange, label, testId, fontSize, isMulti = true, isClearable = true }) => (
    <div className={styles.inputWithLabel} data-testid={`${testId}-filter-input-wrapper`}>
        <div className={styles.filterInputLabel} style={{ fontSize }}>{label}</div>
        <div className={styles.dropdownWidth}>
            <Dropdown
                value={value}
                options={options}
                onChange={onChange}
                menuPortalTarget={document.body}
                isMulti={isMulti}
                testId={`filter-${testId}`}
                maxControlHeight='80px'
                isClearable={isClearable}
            />
        </div>
    </div>
);
