import { all, fork, put, takeEvery, takeLeading, select, call, debounce, delay } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { toast } from 'react-toastify';
import { isEqual, isNull, flow, unset, set, isUndefined } from 'lodash/fp';

import { updateExistingPlaybook, getAllPlaybookFailed, getAllPlaybookSuccessful, getPlaybookByIdFailed, getPlaybookByIdStarted, getPlaybookByIdSuccessful, publishPlaybookFailed, publishPlaybookSuccessful, savePlaybookFailed, savePlaybookSuccessful, setPlaybookCanNext, setPlaybookCanPublish, setPlaybookCanSave, setPlaybookPage, setPlaybookVersionUpdated, togglePlaybookCoverSelected, updatePlaybookTags, resetPlaybookBuild, getPlaybookByDefinitionIdStarted, getPlaybookByDefinitionIdSuccessful, getPlaybookByDefinitionIdFailed, setPlaybookVersionMajorIncreased, setBuildPage, fetchAllTemplatesSuccessful, fetchAllTemplatesFailed, getPlaybookTemplateStarted, getPlaybookTemplateSuccessful, getPlaybookTemplateFailed, sendQueryResponseSuccessful, sendQueryResponseFailed, setPlaybookPublishReason, openPlaybookSuggestedChanges, backToOpenPlaybook, confirmResolvedSuccessful, confirmResolvedFailed, updateSuggestedChangesConversationSuccessful, updateSuggestedChangesConversationFailed, playbookSmartSearchSuccessful, playbookSmartSearchFailed, getAvailablePlaybookProvisionLinksSuccessful, setUpdatedProvisionLinks, openProvisionLink, updateLinkedProvisionHistory, getAvailablePlaybookProvisionLinksFailed, playbookBackCalled, toggleProvisionLinkHistoryModal } from './actions';
import { getCurrentPlaybook, getDeviationModalSectionId, getDeviationOrder, getLinkedProvisionHistory, getOpenSuggestedChange, getOutstandingSuggestedChanges, getPlaybook, getPlaybookPublishReason, getPlaybookSmartSearchTerm, getPlaybookSuggestedChanges, getPlaybookText, getProvisionLinksUpdated, getProvisionOrder, getQueryMarkedAsResolved, getQueryResponse, getResolvedSuggestedChanges, getSelectedPlaybookQuery, getSendEmailReply, getSuggestedChangesMessage } from './selectors';
import { Playbook, AdminPlaybookActionTypes, PlaybookPage, BasicPlaybook, PlaybookById, PlaybookDB, MatchingProvisions, PlaybookText, PlaybookByDefinitionId, BuildPage, PlaybookQueryDB, PlaybookMessage, DraggableTile, SuggestedChangesDB, OpenSuggestedChange, PlaybookProvision, PlaybookSmartSearch, AvailablePlaybookProvisionLinks, PlaybookSectionType, SectionLinkedProvisions, UpdatedProvisionLinks, PlaybookSection, LinkedPlaybookProvision } from './types';
import { isSubheaderSection } from './typeAssertions';
import { publishPlaybook, getAllPlaybooks, getPlaybookById, savePlaybook, getPlaybookByDefinitionId, getAllPlaybookTemplates, getPlaybookTemplateById, queryResponse, confirmResolved, sendSuggestedChangesMessage, smartSearchPlaybooks, getAvailableProvisionLinks } from '../../../../services/playbook';
import { initialPlaybook } from './reducer';
import { scrollToProvisionLink } from '../../../shared/playbook/view/PlaybookSideMenu';
import { getUserRole } from '../../../auth/login/store';
import { systemAdminRole, UserRole } from '../../../constants/permittedRoles';

const stripPlaybookDb = (playbook: PlaybookDB): Playbook => flow(
    unset('publishReason'),
    unset('createdBy'),
    unset('createdDate'),
    unset('effectiveTo'),
    unset('clientId')
)(playbook);

export function* attemptRedirectPlaybook({ payload }: ReturnType<typeof setPlaybookPage>) {
    if (payload !== PlaybookPage.SELECT) {
        const navigationPath = `/admin/playbook/${payload === PlaybookPage.BUILD ? 'build-select' : 'list'}`;
        yield put(resetPlaybookBuild());
        yield put(push(navigationPath));
    }
}

function* redirectPlaybookWatcher() {
    yield takeEvery(AdminPlaybookActionTypes.SELECT_PLAYBOOK_PAGE, attemptRedirectPlaybook);
}

export function* attemptRedirectPlaybookBuild({ payload }: ReturnType<typeof setBuildPage>) {
    if (payload !== BuildPage.SELECT) {
        const navigationPath = `/admin/playbook/${payload === BuildPage.BUILD_FROM_SCRATCH ? 'build' : 'templates'}`;
        yield put(resetPlaybookBuild());
        yield put(push(navigationPath));
    }
}

function* redirectBuildWatcher() {
    yield takeEvery(AdminPlaybookActionTypes.SELECT_BUILD_PAGE, attemptRedirectPlaybookBuild);
}

export function* attemptCheckPlaybookUpdated() {
    try {
        const playbook: Playbook = yield select(getPlaybook);
        const currentPlaybook: PlaybookDB = yield select(getCurrentPlaybook);
        const provisionOrder: DraggableTile[] = yield select(getProvisionOrder);
        const deviationOrder: DraggableTile[] = yield select(getDeviationOrder);
        const deviationSectionId: string | null = yield select(getDeviationModalSectionId);
        const originalPlaybook: Playbook | PlaybookDB = !isNull(currentPlaybook) ? currentPlaybook : initialPlaybook(playbook.content.sections[0].sectionId);
        const nameUpdated = currentPlaybook ? !isEqual(originalPlaybook.name, playbook.name) : playbook.name !== '';
        const agreementTypeUpdated = currentPlaybook ? !isEqual(originalPlaybook.agreementType, playbook.agreementType) : !isNull(playbook.agreementType);
        const documentNameIdsUpdated = currentPlaybook ? !isEqual(originalPlaybook.documentNameIds, playbook.documentNameIds) : !isNull(playbook.documentNameIds);
        const tagsUpdated = !isEqual(originalPlaybook.tags, playbook.tags);
        const versionMajorIncreased = playbook.versionMajor > originalPlaybook.versionMajor;
        const versionMinorUpdated = !isEqual(originalPlaybook.versionMinor, playbook.versionMinor);
        const versionMinorNotLower = playbook.versionMinor >= originalPlaybook.versionMinor;
        const versionNotLower = playbook.versionMajor >= originalPlaybook.versionMajor && versionMinorNotLower;
        const versionUpdated = versionMajorIncreased || versionMinorUpdated && versionMinorNotLower;
        const abstractUpdated = !isNull(originalPlaybook.content.abstract) ? !isEqual(playbook.content.abstract, originalPlaybook.content.abstract) : !isNull(playbook.content.abstract);
        const sectionsUpdated = !isEqual(originalPlaybook.content.sections, playbook.content.sections);
        const originalPlaybookSections = originalPlaybook.content.sections.map(({ sectionId }) => sectionId);
        const currentProvisionOrder = provisionOrder.map(({ id }) => id);
        const provisionOrderUpdated = !isEqual(originalPlaybookSections, currentProvisionOrder);
        const originalPlaybookDeviations = deviationSectionId ? (originalPlaybook.content.sections.find(({ sectionId }) => sectionId === deviationSectionId) as PlaybookProvision).deviations.map(({ rowId }) => ({ rowId })) : [];
        const currentDeviationOrder = deviationOrder.map(({ id }) => id);
        const deviationOrderUpdated = !isEqual(originalPlaybookDeviations, currentDeviationOrder);
        const canSave = (nameUpdated || agreementTypeUpdated || documentNameIdsUpdated || abstractUpdated || sectionsUpdated || tagsUpdated || provisionOrderUpdated || deviationOrderUpdated) && !versionUpdated;
        const canNext = !playbook.playbookId ? (nameUpdated || versionUpdated) : true;
        const canPublish = (!playbook.playbookId && nameUpdated && versionNotLower) || versionUpdated || (currentPlaybook && !!currentPlaybook.isDraft);
        yield put(setPlaybookVersionUpdated(versionUpdated));
        yield put(setPlaybookVersionMajorIncreased(versionMajorIncreased));
        yield put(setPlaybookCanNext(canNext));
        yield put(setPlaybookCanPublish(canPublish));
        yield put(setPlaybookCanSave(canSave));
    } catch (e) {
        toast.error('We encountered a problem checking if the playbook has updated.');
    }
}

function* checkPlaybookUpdatedWatcher() {
    yield debounce(1000,
        [
            AdminPlaybookActionTypes.UPDATE_PLAYBOOK_VALUE,
            AdminPlaybookActionTypes.UPDATE_PLAYBOOK_CONTENT_VALUE,
            AdminPlaybookActionTypes.UPDATE_PLAYBOOK_TAGS,
            AdminPlaybookActionTypes.UPDATE_PLAYBOOK_PROVISION,
            AdminPlaybookActionTypes.UPDATE_PLAYBOOK_SUBHEADER,
            AdminPlaybookActionTypes.ADD_PLAYBOOK_SECTION,
            AdminPlaybookActionTypes.ADD_PLAYBOOK_SUBHEADER,
            AdminPlaybookActionTypes.REMOVE_PLAYBOOK_SECTION,
            AdminPlaybookActionTypes.UPDATE_DEVIATION_VALUE,
            AdminPlaybookActionTypes.ADD_DEVIATION_ROW,
            AdminPlaybookActionTypes.REMOVE_DEVIATION_ROW,
            AdminPlaybookActionTypes.SAVE_PROVISIONS_ORDER,
            AdminPlaybookActionTypes.SAVE_DEVIATIONS_ORDER,
            AdminPlaybookActionTypes.SAVE_PLAYBOOK_LINKED_PROVISIONS
        ], attemptCheckPlaybookUpdated);
}

export function* attemptCheckPlaybookProvisionLinksUpdated() {
    try {
        const playbook: Playbook = yield select(getPlaybook);
        const currentPlaybook: PlaybookDB = yield select(getCurrentPlaybook);
        const originalPlaybook: Playbook | PlaybookDB = !isNull(currentPlaybook) ? currentPlaybook : initialPlaybook(playbook.content.sections[0].sectionId);
        const originalProvisionLinks = (originalPlaybook.content.sections.filter(({ type }) => type !== PlaybookSectionType.SUBHEADER) as PlaybookProvision[]).map(({ linkedProvisions, sectionId }) => ({ sectionId, linkedProvisions }));
        const newProvisionLinks = (playbook.content.sections.filter(({ type }) => type !== PlaybookSectionType.SUBHEADER) as PlaybookProvision[]).map(({ linkedProvisions, sectionId }) => ({ sectionId, linkedProvisions }));
        let linksAdded: SectionLinkedProvisions[] = [];
        let linksRemoved: SectionLinkedProvisions[] = [];
        originalProvisionLinks.forEach(section => {
            const { sectionId, linkedProvisions } = section;
            const newVersion = newProvisionLinks.find(section => section.sectionId === sectionId) || null;
            if (isNull(newVersion)) {
                linksRemoved.push({ sectionId, linkedProvisions });
            } else {
                const removed = linkedProvisions.filter((element) => !newVersion.linkedProvisions.includes(element));
                const added = newVersion.linkedProvisions.filter((element) => !linkedProvisions.includes(element));
                if (removed.length > 0) {
                    linksRemoved.push({ sectionId, linkedProvisions: removed });
                }
                if (added.length > 0) {
                    linksAdded.push({ sectionId, linkedProvisions: added });
                }
            }
        });
        newProvisionLinks.forEach(section => {
            const { sectionId, linkedProvisions } = section;
            const newSectionWithLinks = originalProvisionLinks.find(section => section.sectionId === sectionId) || null;
            if (isNull(newSectionWithLinks) && linkedProvisions.length > 0) {
                linksAdded.push({ sectionId, linkedProvisions });
            }
        });
        if (linksAdded.length > 0 || linksRemoved.length > 0) {
            yield put(setUpdatedProvisionLinks({ linksAdded, linksRemoved }));
        }
    } catch (e) {
        toast.error('We encountered a problem checking if the provision links have updated.');
    }
}

function* checkPlaybookProvisionLinksUpdatedWatcher() {
    yield debounce(1000, [AdminPlaybookActionTypes.SAVE_PLAYBOOK_LINKED_PROVISIONS, AdminPlaybookActionTypes.REMOVE_PLAYBOOK_SECTION], attemptCheckPlaybookProvisionLinksUpdated);
}

export function* attemptUpdateTags() {
    const { tags }: Playbook = yield select(getPlaybook);
    const playbookText: PlaybookText = yield select(getPlaybookText);
    const updatedTags = tags.map(tag => {
        const { searchTerm } = tag;
        const regex = new RegExp(searchTerm.toLowerCase(), 'gi');
        const matchingProvisions = Object.entries(playbookText).reduce((acc: MatchingProvisions, [sectionId, rows]) => {
            const matchingRows = rows.reduce((acc: { rowIndex: number; index: number; }[], row, rowIndex) => {
                const rowMatches = row.toLowerCase().match(regex);
                rowMatches && rowMatches.forEach((_, index) => acc.push({ rowIndex, index }));
                return acc;
            }, []);
            return set(sectionId, matchingRows, acc);
        }, {});
        return set('matchingProvisions', matchingProvisions, tag);
    });
    yield put(updatePlaybookTags(updatedTags));
}

function* updateTagsWatcher() {
    yield debounce(2000, [AdminPlaybookActionTypes.UPDATE_PLAYBOOK_CONTENT_VALUE, AdminPlaybookActionTypes.UPDATE_PLAYBOOK_PROVISION, AdminPlaybookActionTypes.REMOVE_PLAYBOOK_SECTION], attemptUpdateTags);
}

export function* attemptGetPlaybookAndProvisionLinks() {
    const playbook: Playbook = yield select(getPlaybook);
    const provisionLinksUpdated: UpdatedProvisionLinks | null = yield select(getProvisionLinksUpdated);
    const playbookIsDraft = playbook.isDraft === 1;

    if (playbookIsDraft && !isNull(provisionLinksUpdated)) {
        if (provisionLinksUpdated.linksAdded.length > 0) {
            const linksAdded = provisionLinksUpdated.linksAdded.map(({ sectionId, linkedProvisions }) => {
                const updatedLinkedProvisions = linkedProvisions.map(link => set('isDraft', true, link));
                return { sectionId, linkedProvisions: updatedLinkedProvisions };
            });
            const updatedSections = playbook.content.sections.map(section => {
                if (isSubheaderSection(section)) {
                    return section;
                }
                const sectionContainsLinkAdded = provisionLinksUpdated.linksAdded.map(({ sectionId }) => sectionId).includes(section.sectionId);
                if (!sectionContainsLinkAdded) {
                    return section;
                }
                const linkedProvisions = section.linkedProvisions.map(linkedProvision => {
                    const needsToBeUpdatedToDraft = provisionLinksUpdated.linksAdded.find(({ sectionId }) => sectionId === section.sectionId)!.linkedProvisions.find(link => isEqual(link, linkedProvision));
                    if (needsToBeUpdatedToDraft) {
                        return set('isDraft', true, linkedProvision);
                    }
                    return linkedProvision;
                });
                return set('linkedProvisions', linkedProvisions, section);
            });
            const updatedPlaybook = set('content.sections', updatedSections, playbook);

            return { playbook: updatedPlaybook, provisionLinksUpdated: { linksAdded, linksRemoved: provisionLinksUpdated.linksRemoved } };
        }
    }
    if (!playbookIsDraft) {
        let draftLinksAdded: SectionLinkedProvisions[] = [];
        let updatedProvisionLinks: UpdatedProvisionLinks | null = provisionLinksUpdated;
        const updatedSections = playbook.content.sections.reduce((acc: PlaybookSection[], section) => {
            if (isSubheaderSection(section)) {
                return [...acc, section];
            }
            const sectionDraftLinksAdded = section.linkedProvisions.filter(({ isDraft }) => isDraft).map(linkedProvision => unset('isDraft', linkedProvision));
            if (sectionDraftLinksAdded.length > 0) {
                draftLinksAdded.push({ sectionId: section.sectionId, linkedProvisions: sectionDraftLinksAdded });
            }
            const updatedLinkedProvisions = section.linkedProvisions.map(linkedProvision => unset('isDraft', linkedProvision));
            const updatedSection = set('linkedProvisions', updatedLinkedProvisions, section);
            return [...acc, updatedSection];
        }, []);

        const updatedPlaybook = set('content.sections', updatedSections, playbook);
        if (draftLinksAdded.length > 0) {
            if (isNull(provisionLinksUpdated)) {
                updatedProvisionLinks = { linksAdded: draftLinksAdded, linksRemoved: [] };
            } else {
                updatedProvisionLinks = { linksAdded: [...provisionLinksUpdated.linksAdded, ...draftLinksAdded], linksRemoved: provisionLinksUpdated.linksRemoved };
            }
        }
        return { playbook: updatedPlaybook, provisionLinksUpdated: updatedProvisionLinks };
    }
    return { playbook, provisionLinksUpdated };
}

export function* attemptPublishNewPlaybook() {
    try {
        const noOutstandingSuggestedChanges: boolean = yield select(getOutstandingSuggestedChanges);
        const resolvedSuggestedChanges: number[] = yield select(getResolvedSuggestedChanges);
        const publishReason: string = yield select(getPlaybookPublishReason);
        const { playbook, provisionLinksUpdated }: { playbook: Playbook, provisionLinksUpdated: UpdatedProvisionLinks | null } = yield call(attemptGetPlaybookAndProvisionLinks);
        const { allPlaybooks, latestPlaybook }: { allPlaybooks: BasicPlaybook[]; latestPlaybook: PlaybookById; } = yield call(publishPlaybook, { playbook, publishReason, resolvedSuggestedChanges, provisionLinksUpdated });
        const { currentPlaybook } = latestPlaybook;
        yield put(setPlaybookPublishReason(publishReason));
        const strippedCurrentPlaybook = stripPlaybookDb(currentPlaybook);
        yield put(publishPlaybookSuccessful(allPlaybooks, strippedCurrentPlaybook, latestPlaybook));
        if (noOutstandingSuggestedChanges) {
            const navigationPath = `/admin/playbook/list/${latestPlaybook.currentPlaybook.playbookId}`;
            yield put(push(navigationPath));
        }
        toast(`Successfully published${playbook.isDraft ? ' draft' : ''} playbook`);
    } catch (e) {
        yield put(publishPlaybookFailed((e as Error).message));
        toast.error('We encountered a problem publishing the playbook, please try again.');
    }
}

function* publishPlaybookWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.PUBLISH_PLAYBOOK_STARTED, attemptPublishNewPlaybook);
}

export function* attemptSaveNewPlaybook() {
    try {
        const noOutstandingSuggestedChanges: boolean = yield select(getOutstandingSuggestedChanges);
        const resolvedSuggestedChanges: number[] = yield select(getResolvedSuggestedChanges);
        const publishReason: string = yield select(getPlaybookPublishReason);
        const { playbook, provisionLinksUpdated }: { playbook: Playbook, provisionLinksUpdated: UpdatedProvisionLinks | null } = yield call(attemptGetPlaybookAndProvisionLinks);

        const { allPlaybooks, latestPlaybook }: { allPlaybooks: BasicPlaybook[]; latestPlaybook: PlaybookById; } = yield call(savePlaybook, { playbook, publishReason, resolvedSuggestedChanges, provisionLinksUpdated });
        const { currentPlaybook } = latestPlaybook;
        yield put(setPlaybookPublishReason(publishReason));
        const strippedCurrentPlaybook = stripPlaybookDb(currentPlaybook);
        yield put(savePlaybookSuccessful(allPlaybooks, strippedCurrentPlaybook, latestPlaybook));
        if (noOutstandingSuggestedChanges) {
            const navigationPath = `/admin/playbook/list/${latestPlaybook.currentPlaybook.playbookId}`;
            yield put(push(navigationPath));
        }
        toast(`Successfully ${playbook.isDraft ? 'published draft' : 'saved'} playbook`);
    } catch (e) {
        yield put(savePlaybookFailed((e as Error).message));
        toast.error('We encountered a problem saving the playbook, please try again.');
    }
}

function* savePlaybookWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.SAVE_PLAYBOOK_STARTED, attemptSaveNewPlaybook);
}

export function* attemptConfirmResolvedChanges() {
    try {
        const playbook: Playbook = yield select(getPlaybook);
        const noOutstandingSuggestedChanges: boolean = yield select(getOutstandingSuggestedChanges);
        const resolvedSuggestedChanges: number[] = yield select(getResolvedSuggestedChanges);
        const { playbookSuggestedChanges }: { playbookSuggestedChanges: SuggestedChangesDB[]; } = yield call(confirmResolved, { playbookId: playbook.playbookId!, playbookDefinitionId: playbook.playbookDefinitionId!, resolvedSuggestedChanges });
        yield put(confirmResolvedSuccessful(playbookSuggestedChanges));
        if (noOutstandingSuggestedChanges) {
            const navigationPath = `/admin/playbook/list/${playbook.playbookId}`;
            yield put(push(navigationPath));
        }
        toast('Successfully resolved suggested changes');
    } catch (e) {
        yield put(confirmResolvedFailed((e as Error).message));
        toast.error('We encountered a problem resolving the suggested changes, please try again.');
    }
}

function* attemptConfirmResolvedChangesWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.CONFIRM_RESOLVED_STARTED, attemptConfirmResolvedChanges);
}

export function* attemptFetchAllPlaybooks() {
    try {
        const userRole: UserRole | undefined = yield select(getUserRole);
        const isSystemAdmin = systemAdminRole.includes(userRole!);
        const allPlaybooks: BasicPlaybook[] = yield call(getAllPlaybooks, { viewTemplates: isSystemAdmin, includeDrafts: true });
        yield put(getAllPlaybookSuccessful(allPlaybooks));
    } catch (e) {
        yield put(getAllPlaybookFailed((e as Error).message));
        toast.error('We encountered a problem fetching the playbooks, please try again.');
    }
}

function* getAllPlaybooksWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.GET_ALL_PLAYBOOKS_STARTED, attemptFetchAllPlaybooks);
}

export function* attemptFetchAllPlaybookTemplates() {
    try {
        const allTemplates: BasicPlaybook[] = yield call(getAllPlaybookTemplates);
        yield put(fetchAllTemplatesSuccessful(allTemplates));
    } catch (e) {
        yield put(fetchAllTemplatesFailed((e as Error).message));
        toast.error('We encountered a problem fetching the playbook templates, please try again.');
    }
}

function* fetchAllPlaybookTemplatesWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.FETCH_ALL_PLAYBOOK_TEMPLATES_STARTED, attemptFetchAllPlaybookTemplates);
}

export function* attemptGetCurrentPlaybookById({ payload }: ReturnType<typeof getPlaybookByIdStarted>) {
    try {
        const { playbookId, listView, suggestedChangesView } = payload;
        const latestPlaybook: PlaybookById = yield call(getPlaybookById, { playbookId, includeDrafts: true, includeResolvedSuggestedChanges: true });
        const { currentPlaybook } = latestPlaybook;
        const { publishReason } = currentPlaybook;
        yield put(setPlaybookPublishReason(publishReason || ''));
        yield put(setPlaybookCanPublish(!!currentPlaybook.isDraft));
        const strippedCurrentPlaybook = stripPlaybookDb(currentPlaybook);
        yield put(getPlaybookByIdSuccessful(strippedCurrentPlaybook, latestPlaybook));
        yield put(togglePlaybookCoverSelected(null));
        if (suggestedChangesView) {
            yield put(openPlaybookSuggestedChanges(playbookId));
        } else {
            yield put(push(`/admin/playbook/${listView ? 'list' : 'build'}/${playbookId}`));
        }
    } catch (e) {
        yield put(getPlaybookByIdFailed((e as Error).message));
        toast.error('We encountered a problem fetching the playbook, please try again.');
    }

}

function* getPlaybookByIdWatcher() {
    yield takeEvery(AdminPlaybookActionTypes.GET_PLAYBOOK_BY_ID_STARTED, attemptGetCurrentPlaybookById);
}

export function* attemptGetPlaybookTemplate({ payload }: ReturnType<typeof getPlaybookTemplateStarted>) {
    try {
        const { playbookId } = payload;
        const playbookTemplate: Playbook = yield call(getPlaybookTemplateById, { playbookId });
        yield put(getPlaybookTemplateSuccessful(playbookTemplate));
        yield put(togglePlaybookCoverSelected(null));
        yield put(push('/admin/playbook/build'));
    } catch (e) {
        yield put(getPlaybookTemplateFailed((e as Error).message));
        toast.error('We encountered a problem fetching the playbook template, please try again.');
    }

}

function* getPlaybookTemplateWatcher() {
    yield takeEvery(AdminPlaybookActionTypes.GET_PLAYBOOK_TEMPLATE_STARTED, attemptGetPlaybookTemplate);
}

export function* attemptGetPlaybookByDefinitionId({ payload }: ReturnType<typeof getPlaybookByDefinitionIdStarted>) {
    try {
        const { playbookDefinitionId } = payload;
        const latestPlaybook: PlaybookByDefinitionId = yield call(getPlaybookByDefinitionId, { playbookDefinitionId, includeDrafts: true, includeResolvedSuggestedChanges: true });
        const { playbookInstance } = latestPlaybook;
        const { publishReason } = playbookInstance;
        yield put(setPlaybookPublishReason(publishReason || ''));
        yield put(setPlaybookCanPublish(!!playbookInstance.isDraft));
        const strippedCurrentPlaybook = stripPlaybookDb(playbookInstance);
        yield put(getPlaybookByDefinitionIdSuccessful(strippedCurrentPlaybook, latestPlaybook));
    } catch (e) {
        yield put(getPlaybookByDefinitionIdFailed((e as Error).message));
        toast.error('We encountered a problem fetching the playbook, please try again.');
    }

}

function* getPlaybookByDefinitionIdWatcher() {
    yield takeEvery(AdminPlaybookActionTypes.GET_PLAYBOOK_BY_DEFINITION_ID_STARTED, attemptGetPlaybookByDefinitionId);
}

export function* attemptRedirectExistingPlaybook({ payload }: ReturnType<typeof updateExistingPlaybook>) {
    const navigationPath = `/admin/playbook/build/${payload.playbookId!}`;
    yield put(push(navigationPath));
}

function* redirectExistingPlaybookWatcher() {
    yield takeEvery(AdminPlaybookActionTypes.UPDATE_EXISTING_PLAYBOOK, attemptRedirectExistingPlaybook);
}

export function* attemptRedirectPlaybookSuggestedChanges({ payload }: ReturnType<typeof openPlaybookSuggestedChanges>) {
    const navigationPath = `/admin/playbook/list/${payload}/changes`;
    yield put(push(navigationPath));
}

function* redirectPlaybookSuggestedChangesWatcher() {
    yield takeEvery(AdminPlaybookActionTypes.OPEN_PLAYBOOK_SUGGESTED_CHANGES, attemptRedirectPlaybookSuggestedChanges);
}

export function* attemptRedirectBackToOpenPlaybook({ payload }: ReturnType<typeof backToOpenPlaybook>) {
    const navigationPath = `/admin/playbook/list/${payload}`;
    yield put(push(navigationPath));
}

function* redirectBackToOpenPlaybookWatcher() {
    yield takeEvery(AdminPlaybookActionTypes.BACK_TO_OPEN_PLAYBOOK, attemptRedirectBackToOpenPlaybook);
}

export function* attemptSendQueryResponse() {
    try {
        const query: PlaybookQueryDB = yield select(getSelectedPlaybookQuery);
        const latestPlaybook: PlaybookById = yield call(getPlaybookById, { playbookId: query.playbookId, includeDrafts: false, includeResolvedSuggestedChanges: true });
        const { playbookQueries } = latestPlaybook;
        const mostRecentQuery = playbookQueries.find(({ playbookQueryId }) => playbookQueryId === query.playbookQueryId)!;
        const response: PlaybookMessage | null = yield select(getQueryResponse);
        const sendEmail: boolean = yield select(getSendEmailReply);
        const markAsResolved: boolean = yield select(getQueryMarkedAsResolved);
        const conversation = isNull(response) ? mostRecentQuery.queryConversation : [...mostRecentQuery.queryConversation, response];
        const updatedQuery: PlaybookQueryDB = { ...mostRecentQuery, queryConversation: conversation };
        const allQueries: PlaybookQueryDB[] = yield call(queryResponse, { query: updatedQuery, sendEmail, markAsResolved });
        yield put(sendQueryResponseSuccessful(allQueries));
        toast('Response successfully sent.');
    } catch (e) {
        yield put(sendQueryResponseFailed((e as Error).message));
        toast.error('We encountered a problem sending the response, please try again.');
    }
}

function* attemptSendQueryResponseWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.SEND_ADMIN_QUERY_RESPONSE_STARTED, attemptSendQueryResponse);
}

export function* attemptUpdateSuggestedChangesConversation() {
    try {
        const openSuggestedChange: OpenSuggestedChange = yield select(getOpenSuggestedChange);
        const message: PlaybookMessage = yield select(getSuggestedChangesMessage);
        const allSuggestedChanges: SuggestedChangesDB[] = yield select(getPlaybookSuggestedChanges);
        const suggestedChanges = allSuggestedChanges.find(({ playbookSuggestionId }) => playbookSuggestionId === openSuggestedChange.playbookSuggestionId);
        if (suggestedChanges) {
            const conversation: PlaybookMessage[] = [...suggestedChanges.conversation, message];
            const updatedChanges = set('conversation', conversation, suggestedChanges);
            const updatedAllSuggestedChanges = allSuggestedChanges.map(suggestion => suggestion.playbookSuggestionId === updatedChanges.playbookSuggestionId ? updatedChanges : suggestion);
            yield call(sendSuggestedChangesMessage, { conversation, playbookSuggestionId: suggestedChanges.playbookSuggestionId, playbookDefinitionId: suggestedChanges.playbookDefinitionId });
            yield put(updateSuggestedChangesConversationSuccessful(updatedAllSuggestedChanges));
        }
    } catch (e) {
        yield put(updateSuggestedChangesConversationFailed((e as Error).message));
        toast.error('We encountered a problem sending your message, please try again.');
    }
}

function* updateSuggestedChangesConversationWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED, attemptUpdateSuggestedChangesConversation);
}

export function* attemptSmartSearchPlaybooks() {
    try {
        const searchTerm: PlaybookSmartSearch[] = yield select(getPlaybookSmartSearchTerm);
        const filteredPlaybooks: BasicPlaybook[] = yield call(smartSearchPlaybooks, { searchTerm: searchTerm, includeDrafts: true });
        yield put(playbookSmartSearchSuccessful(filteredPlaybooks));
    } catch (e) {
        yield put(playbookSmartSearchFailed((e as Error).message));
        toast.error('We encountered a problem searching the playbooks, please try again.');
    }
}

function* attemptSmartSearchPlaybooksWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.ADMIN_PLAYBOOK_SMART_SEARCH_STARTED, attemptSmartSearchPlaybooks);
}

export function* attemptFetchAvailablePlaybookProvisionLinks() {
    try {
        const availableLinks: AvailablePlaybookProvisionLinks[] = yield call(getAvailableProvisionLinks);
        yield put(getAvailablePlaybookProvisionLinksSuccessful(availableLinks));
    } catch (e) {
        yield put(getAvailablePlaybookProvisionLinksFailed((e as Error).message));
        toast.error('We encountered a problem fetching the provision links, please try again.');
    }
}

function* attemptFetchAvailablePlaybookProvisionLinksWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.GET_AVAILABLE_PROVISION_LINKS_STARTED, attemptFetchAvailablePlaybookProvisionLinks);
}

export function* attemptRedirectPlaybookProvisionLink(playbookId: number, sectionId: string) {
    const latestPlaybook: PlaybookById = yield call(getPlaybookById, { playbookId, includeDrafts: true, includeResolvedSuggestedChanges: true });
    const { currentPlaybook } = latestPlaybook;
    const strippedCurrentPlaybook = stripPlaybookDb(currentPlaybook);
    yield put(getPlaybookByIdSuccessful(strippedCurrentPlaybook, latestPlaybook));
    const navigationPath = `/admin/playbook/list/${playbookId}`;
    yield put(push(navigationPath));
    yield delay(500);
    let hasScrolled: boolean = yield call(scrollToProvisionLink, sectionId);
    while (!hasScrolled) {
        yield delay(250);
        hasScrolled = yield call(scrollToProvisionLink, sectionId);
    }
}

export function* attemptRedirectToPlaybookProvisionLink({ payload }: ReturnType<typeof openProvisionLink>) {
    const { linkTo: { playbookId, sectionId }, linkFrom } = payload;
    const linkedProvisionHistory: LinkedPlaybookProvision[] = yield select(getLinkedProvisionHistory);
    yield call(attemptRedirectPlaybookProvisionLink, playbookId, sectionId);
    const updatedHistory = [...linkedProvisionHistory, linkFrom];
    yield put(updateLinkedProvisionHistory(updatedHistory));
}

function* redirectToPlaybookProvisionLinkWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.OPEN_PROVISION_LINK, attemptRedirectToPlaybookProvisionLink);
}

export function* attemptRedirectFromPlaybookProvisionLink({ payload }: ReturnType<typeof playbookBackCalled>) {
    const linkedProvisionHistory: LinkedPlaybookProvision[] = yield select(getLinkedProvisionHistory);
    if (linkedProvisionHistory.length > 0) {
        if (isUndefined(payload)) {
            const { playbookId, sectionId }: { playbookId: number, sectionId: string } = linkedProvisionHistory[linkedProvisionHistory.length - 1];
            yield call(attemptRedirectPlaybookProvisionLink, playbookId, sectionId);
            yield put(updateLinkedProvisionHistory(linkedProvisionHistory.slice(0, -1)));
        } else {
            if (isNull(payload)) {
                yield put(updateLinkedProvisionHistory([]));
                yield put(setPlaybookPage(PlaybookPage.MY_PLAYBOOKS));
            } else {
                const { playbookId, sectionId }: { playbookId: number, sectionId: string } = linkedProvisionHistory[payload];
                yield call(attemptRedirectPlaybookProvisionLink, playbookId, sectionId);
                yield put(updateLinkedProvisionHistory(linkedProvisionHistory.slice(0, payload)));
            }
            yield put(toggleProvisionLinkHistoryModal(false));
        }
    } else {
        yield put(setPlaybookPage(PlaybookPage.MY_PLAYBOOKS));
    }
}

function* redirectFromPlaybookProvisionLinkWatcher() {
    yield takeLeading(AdminPlaybookActionTypes.PLAYBOOK_BACK_CALLED, attemptRedirectFromPlaybookProvisionLink);
}

export function* adminPlaybookSaga() {
    yield all([
        fork(redirectPlaybookWatcher),
        fork(redirectBuildWatcher),
        fork(publishPlaybookWatcher),
        fork(savePlaybookWatcher),
        fork(getAllPlaybooksWatcher),
        fork(fetchAllPlaybookTemplatesWatcher),
        fork(getPlaybookByIdWatcher),
        fork(checkPlaybookUpdatedWatcher),
        fork(updateTagsWatcher),
        fork(getPlaybookByDefinitionIdWatcher),
        fork(redirectExistingPlaybookWatcher),
        fork(getPlaybookTemplateWatcher),
        fork(attemptSendQueryResponseWatcher),
        fork(redirectPlaybookSuggestedChangesWatcher),
        fork(redirectBackToOpenPlaybookWatcher),
        fork(attemptConfirmResolvedChangesWatcher),
        fork(updateSuggestedChangesConversationWatcher),
        fork(attemptSmartSearchPlaybooksWatcher),
        fork(attemptFetchAvailablePlaybookProvisionLinksWatcher),
        fork(checkPlaybookProvisionLinksUpdatedWatcher),
        fork(redirectToPlaybookProvisionLinkWatcher),
        fork(redirectFromPlaybookProvisionLinkWatcher)
    ]);
}
