import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { Assumptions, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface AssumptionsSectionProps {
    assumptions: Assumptions;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const AssumptionsSection: React.FC<AssumptionsSectionProps> = ({ assumptions, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'assumptions';
    const { governingLaw, governingLawIfOther, bothPartiesSingleBranch, multiBranchStatus } = assumptions;
    const governingLawIsOther = useMemo(() => governingLaw.value && governingLaw.value.includes('Other'), [governingLaw]);

    return (
        <SectionWrapper id={sectionId} label='Assumptions' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.assumptionsSectionWrapper}>
                <div className={styles.sectionGroup}>
                    <SingleField
                        field={governingLaw}
                        fieldId='governingLaw'
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        getFieldOpen={getFieldOpen}
                        toggleFieldOpen={toggleFieldOpen}
                        width={governingLawIsOther ? 'calc(50% - 10px)' : '100%'}
                    />
                    {governingLawIsOther &&
                        <SingleField
                            field={governingLawIfOther}
                            fieldId='governingLawIfOther'
                            sectionId={sectionId}
                            isEditing={isEditing}
                            isUpdating={isUpdating}
                            fieldsUpdated={fieldsUpdated}
                            getFieldOpen={getFieldOpen}
                            toggleFieldOpen={toggleFieldOpen}
                            width='calc(50% - 10px)'
                        />
                    }
                </div>
                <SingleField
                    field={bothPartiesSingleBranch}
                    fieldId='bothPartiesSingleBranch'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={multiBranchStatus}
                    fieldId='multiBranchStatus'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
            </div>
        </SectionWrapper>
    );
};
