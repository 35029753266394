import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { isEqual, isNull, noop } from 'lodash/fp';
import { RawDraftContentState } from 'draft-js';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import styles from './Playbook.module.scss';
import {
    backToOpenPlaybook,
    getCurrentView,
    getOpenDeviations,
    getOpenSections,
    getOpenSuggestedChange,
    getPlaybook,
    getPlaybookByIdStarted,
    getPlaybookSuggestedChanges,
    PlaybookView,
    toggleDeviation,
    toggleSection,
    setSuggestedChange,
    getCurrentPlaybookPageView,
    setPlaybookPageView,
    PlaybookPageView,
    updatePlaybookContentValue,
    addDeviationRow,
    deleteDeviationRow,
    RelevantStakeholders,
    updatePlaybookProvision,
    updateSectionDeviation,
    getPlaybookCanSave,
    getPlaybookCanPublish,
    getPlaybookIsDraft,
    togglePlaybookPublishModalOpen,
    togglePlaybookSaveModalOpen,
    savePlaybookStarted,
    resetPlaybookState,
    getResolvedChangesExist,
    confirmResolvedStarted,
    toggleSuggestionReviewModalOpen,
    PlaybookProvision,
    isSubheaderSection
} from './store';
import { PlaybookSideMenu } from '../../shared/playbook/view/PlaybookSideMenu';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Abstract } from '../../shared/playbook/view/Abstract';
import { Provision } from '../../shared/playbook/view/Provision';
import { SuggestedChanges } from './SuggestedChanges';
import { ABSTRACT_ID } from '../../constants/playbooks';
import { usePlaybooksSplitView } from '../../../hooks/usePlaybooksSplitView';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllBasicUsersStarted, fetchAllUsersStarted } from '../users/store';
import { AbstractSuggestedChanges, PlaybookContentType, SectionSuggestedChanges } from '../../playbook/store';
import { PlaybookSaveModal } from './builder/PlaybookSaveModal';
import { PlaybookPublishModal } from './builder/PlaybookPublishModal';
import { VersionControl } from './builder/tabs/VersionControl';
import { ArrowLeft, SaveFile, PlaybookIcon } from '../../shared/icons';
import { ButtonResize } from '../../shared/button/ButtonResize';
import { Spinner } from '../../shared/spinner/Spinner';
import { Button } from '../../shared/button/Button';
import { ReviewSuggestionModal } from './ReviewSuggestionModal';

const { primary } = styles;
export const VERSION_CONTROL_WIDTH = 175;
export const BUTTON_WIDTH = 150;
export const DISPLAYED_BUTTON_WIDTH = (BUTTON_WIDTH * 3) + VERSION_CONTROL_WIDTH;

export interface PlaybookSuggestedChangesViewerParams {
    playbookId: string | undefined;
}

export const PlaybookSuggestedChangesViewer: React.FC<RouteComponentProps<PlaybookSuggestedChangesViewerParams>> = ({ match: { params } }) => {
    const testId = 'admin-playbook';
    const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
    const [abstractOpen, setAbstractOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllUsersStarted(), fetchAllBasicUsersStarted()]);
    const playbook = useAppSelector(getPlaybook);
    const currentView = useAppSelector(getCurrentView);
    const openSections = useAppSelector(getOpenSections);
    const openDeviations = useAppSelector(getOpenDeviations);
    const suggestedChanges = useAppSelector(getPlaybookSuggestedChanges);
    const openSuggestedChange = useAppSelector(getOpenSuggestedChange);
    const resolvedChangesExist = useAppSelector(getResolvedChangesExist);
    const currentPlaybookPageView = useAppSelector(getCurrentPlaybookPageView);
    const canSave = useAppSelector(getPlaybookCanSave);
    const canPublish = useAppSelector(getPlaybookCanPublish);
    const isDraft = useAppSelector(getPlaybookIsDraft);

    const { content: { abstract, sections } } = playbook;

    const isTableView = currentView === PlaybookView.TABLE || false;
    const playbookAbstractOpen = useMemo(() => !!abstractOpen || !!openSuggestedChange && openSuggestedChange.sectionId === ABSTRACT_ID, [abstractOpen, openSuggestedChange]);
    const sectionOpen = useCallback((sectionId: string) => !!openSections.find(openSection => isEqual(openSection, sectionId)) || !!openSuggestedChange && openSuggestedChange.sectionId === sectionId, [openSections, openSuggestedChange]);
    const deviationOpen = useCallback((sectionId: string) => !!openDeviations.find(openDeviation => isEqual(openDeviation, sectionId)), [openDeviations]);
    const togglePlaybookSection = useCallback((sectionId: string) => dispatch(toggleSection(sectionId)), [dispatch]);
    const togglePlaybookDeviation = useCallback((sectionId: string) => dispatch(toggleDeviation(sectionId)), [dispatch]);
    const toggleAbstractOpen = useCallback(() => setAbstractOpen(!abstractOpen), [abstractOpen]);
    const backToPlaybook = useCallback(() => dispatch(backToOpenPlaybook(parseInt(params.playbookId!))), [dispatch, params]);
    const changePlaybookPageView = useCallback((view: PlaybookPageView) => dispatch(setPlaybookPageView(view)), [dispatch]);

    const reviewSuggestion = useCallback(() => dispatch(toggleSuggestionReviewModalOpen()), [dispatch]);

    const setSuggestedChangeOpen = useCallback((playbookSuggestionId: number, sectionId: string) => {
        dispatch(setSuggestedChange(playbookSuggestionId, sectionId));
        setSideMenuOpen(false);
    }, [dispatch]);

    useEffect(() => {
        if (!playbook.playbookId && params.playbookId) {
            dispatch(getPlaybookByIdStarted(parseInt(params.playbookId), false, true));
        }
    }, [params, playbook, dispatch]);

    useEffect(() => () => { dispatch(resetPlaybookState()); }, [dispatch]);

    const [width] = usePlaybooksSplitView(currentPlaybookPageView, sideMenuOpen);

    const buttonWidth = useCallback((view: PlaybookPageView) => {
        if (currentPlaybookPageView === PlaybookPageView.SPLIT) {
            return width - 11;
        }
        return currentPlaybookPageView === view ? width - 20 : 0;
    }, [currentPlaybookPageView, width]);

    const pageWidth = useCallback((view: PlaybookPageView) => currentPlaybookPageView === PlaybookPageView.SPLIT ? `calc(${width}px - 11px)` : currentPlaybookPageView === view ? `calc(${width}px - 20px)` : '0px', [currentPlaybookPageView, width]);
    const pageDisplay = useCallback((view: PlaybookPageView) => currentPlaybookPageView === PlaybookPageView.SPLIT ? '' : currentPlaybookPageView === view ? '' : 'none', [currentPlaybookPageView]);
    const showLeftButtonGroup = useMemo(() => currentPlaybookPageView !== PlaybookPageView.CHANGES, [currentPlaybookPageView]);
    const showRightButtonGroup = useMemo(() => currentPlaybookPageView !== PlaybookPageView.CURRENT, [currentPlaybookPageView]);
    const borderRight = useMemo(() => currentPlaybookPageView === PlaybookPageView.SPLIT ? `solid 1px ${primary}` : 'none', [currentPlaybookPageView]);
    const contentDisabled = useMemo(() => !isNull(openSuggestedChange), [openSuggestedChange]);

    const showReviewButton = useMemo(() => !isNull(openSuggestedChange) && currentPlaybookPageView !== PlaybookPageView.CURRENT, [openSuggestedChange, currentPlaybookPageView]);
    const showConfirmResolved = useMemo(() => currentPlaybookPageView !== PlaybookPageView.CURRENT && resolvedChangesExist && !canSave, [canSave, resolvedChangesExist, currentPlaybookPageView]);

    const setPlaybookAbstract = useCallback((value: RawDraftContentState | null) => dispatch(updatePlaybookContentValue(ABSTRACT_ID, value)), [dispatch]);
    const updateProvision = useCallback((sectionId: string, key: keyof PlaybookProvision, value: string | string[] | null | RawDraftContentState | RelevantStakeholders[]) => dispatch(updatePlaybookProvision(sectionId, key, value)), [dispatch]);

    const addDeviation = useCallback((sectionId: string, index?: number) => dispatch(addDeviationRow(sectionId, index)), [dispatch]);
    const deleteDeviation = useCallback((sectionId: string, rowId: string) => dispatch(deleteDeviationRow(sectionId, rowId)), [dispatch]);
    const updateDeviationColumn = useCallback((sectionId: string, rowId: string, key: string, value: string | null | RawDraftContentState | number) => dispatch(updateSectionDeviation(sectionId, rowId, key, value)), [dispatch]);

    const publishPlaybook = useCallback(() => dispatch(togglePlaybookPublishModalOpen(true)), [dispatch]);
    const savePlaybook = useCallback(() => !isDraft ? dispatch(togglePlaybookSaveModalOpen(true)) : dispatch(savePlaybookStarted()), [dispatch, isDraft]);
    const confirmResolved = useCallback(() => dispatch(confirmResolvedStarted()), [dispatch]);

    const content = useMemo(() => {
        if (isNull(openSuggestedChange)) {
            return (
                <Scrollable>
                    <div className={styles.splitViewWrapper} data-testid={`${testId}-suggested-changes-split-view-wrapper`}>
                        <div className={styles.suggestedChangesCurrentPlaybook} style={{ minWidth: pageWidth(PlaybookPageView.CURRENT), borderRight: borderRight, display: pageDisplay(PlaybookPageView.CURRENT) }} data-testid={`${testId}-suggested-changes-split-view-playbook-wrapper`}>
                            <div id={ABSTRACT_ID}>
                                <Abstract
                                    abstract={abstract}
                                    updateAbstract={noop}
                                    toggleAbstractOpen={toggleAbstractOpen}
                                    abstractOpen={playbookAbstractOpen}
                                    minHeight='58px'
                                    showCollapseToggle={false}
                                    testId={`${testId}-suggested-changes`}
                                />
                            </div>
                            {sections.map((section, index) => (
                                <div id={section.sectionId} key={section.sectionId}>
                                    {isSubheaderSection(section) ? (null) : (
                                        <Provision
                                            section={section}
                                            showDelete={false}
                                            isTableView={isTableView}
                                            sectionOpen={sectionOpen(section.sectionId)}
                                            toggleSection={togglePlaybookSection}
                                            updateProvision={noop}
                                            removeSection={noop}
                                            isBuild
                                            deviationOpen={deviationOpen(section.sectionId)}
                                            toggleDeviation={togglePlaybookDeviation}
                                            addDeviation={noop}
                                            deleteDeviationRow={noop}
                                            updateDeviationColumn={noop}
                                            showCollapseToggle={false}
                                            testId={`${testId}-suggested-changes-${index}`}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                        {currentPlaybookPageView !== PlaybookPageView.CURRENT &&
                            <div className={styles.suggestedChangesList} style={{ minWidth: pageWidth(PlaybookPageView.CHANGES), display: pageDisplay(PlaybookPageView.CHANGES) }} data-testid={`${testId}-suggested-changes-split-view-changes-wrapper`}>
                                <SuggestedChanges
                                    suggestedChanges={suggestedChanges}
                                    playbook={playbook}
                                    setSuggestedChangeOpen={setSuggestedChangeOpen}
                                    openSuggestedChange={openSuggestedChange}
                                    openDeviations={openDeviations}
                                    toggleDeviation={togglePlaybookDeviation}
                                    testId={`${testId}-suggested-changes`}
                                />
                            </div>
                        }
                    </div>
                </Scrollable>
            );
        }
        if (openSuggestedChange.sectionId === ABSTRACT_ID) {
            const abstractSuggestedChange = suggestedChanges.filter(({ suggestedSection }) => suggestedSection.type === PlaybookContentType.ABSTRACT).find(suggestedChange => suggestedChange.playbookSuggestionId === openSuggestedChange.playbookSuggestionId)!.suggestedSection as AbstractSuggestedChanges;
            return (
                <>
                    <div className={styles.suggestedChangesCurrentPlaybook} style={{ minWidth: pageWidth(PlaybookPageView.CURRENT), borderRight: borderRight }}>
                        <Scrollable>
                            <Abstract
                                abstract={abstract}
                                updateAbstract={setPlaybookAbstract}
                                toggleAbstractOpen={toggleAbstractOpen}
                                abstractOpen={playbookAbstractOpen}
                                minHeight='fit-content'
                                showCollapseToggle={false}
                                maxHeight='fit-content'
                                testId={`${testId}-suggested-changes`}
                            />
                        </Scrollable>
                    </div>
                    {currentPlaybookPageView !== PlaybookPageView.CURRENT &&
                        <div className={styles.suggestedChangesList} style={{ minWidth: pageWidth(PlaybookPageView.CHANGES) }}>
                            <SuggestedChanges
                                suggestedChanges={suggestedChanges}
                                playbook={playbook}
                                setSuggestedChangeOpen={setSuggestedChangeOpen}
                                openSuggestedChange={openSuggestedChange}
                                openDeviations={openDeviations}
                                toggleDeviation={togglePlaybookDeviation}
                                abstractSuggestedChange={abstractSuggestedChange}
                                testId={`${testId}-suggested-changes`}
                            />
                        </div>
                    }
                </>
            );
        } else {
            const section = sections.find(({ sectionId }) => sectionId === openSuggestedChange.sectionId) as PlaybookProvision;
            const sectionSuggestedChange = suggestedChanges.filter(({ suggestedSection }) => suggestedSection.type === PlaybookContentType.SECTION).find(suggestedChange => suggestedChange.playbookSuggestionId === openSuggestedChange.playbookSuggestionId)!.suggestedSection as SectionSuggestedChanges;
            return (
                <>
                    <div className={styles.suggestedChangesCurrentPlaybook} style={{ minWidth: pageWidth(PlaybookPageView.CURRENT), borderRight: borderRight }}>
                        <Scrollable>
                            <Provision
                                key={section.sectionId}
                                section={section}
                                showDelete={sections.length > 1}
                                isTableView={isTableView}
                                sectionOpen={sectionOpen(section.sectionId)}
                                toggleSection={togglePlaybookSection}
                                updateProvision={updateProvision}
                                removeSection={noop}
                                isBuild
                                deviationOpen={deviationOpen(section.sectionId)}
                                toggleDeviation={togglePlaybookDeviation}
                                addDeviation={addDeviation}
                                deleteDeviationRow={deleteDeviation}
                                updateDeviationColumn={updateDeviationColumn}
                                showCollapseToggle={false}
                                testId={`${testId}-suggested-changes-0`}
                            />
                        </Scrollable>
                    </div>
                    {currentPlaybookPageView !== PlaybookPageView.CURRENT &&
                        <div className={styles.suggestedChangesList} style={{ minWidth: pageWidth(PlaybookPageView.CHANGES) }}>
                            <SuggestedChanges
                                suggestedChanges={suggestedChanges}
                                playbook={playbook}
                                setSuggestedChangeOpen={setSuggestedChangeOpen}
                                openSuggestedChange={openSuggestedChange}
                                openDeviations={openDeviations}
                                toggleDeviation={togglePlaybookDeviation}
                                sectionSuggestedChange={sectionSuggestedChange}
                                testId={`${testId}-suggested-changes`}
                            />
                        </div>
                    }
                </>
            );
        }
    }, [
        abstract,
        deviationOpen,
        isTableView,
        openSuggestedChange,
        playbookAbstractOpen,
        sectionOpen,
        sections,
        toggleAbstractOpen,
        togglePlaybookDeviation,
        togglePlaybookSection,
        openDeviations,
        pageWidth,
        playbook,
        borderRight,
        suggestedChanges,
        setSuggestedChangeOpen,
        addDeviation,
        deleteDeviation,
        setPlaybookAbstract,
        updateDeviationColumn,
        updateProvision,
        pageDisplay,
        currentPlaybookPageView
    ]);

    const showIconButtons = useMemo(() => DISPLAYED_BUTTON_WIDTH >= buttonWidth(PlaybookPageView.CURRENT), [buttonWidth]);

    if (!playbook.playbookId) {
        return (
            <Spinner />
        );
    }

    if (suggestedChanges.length === 0) {
        return (
            <Redirect to={`/admin/playbook/list/${params.playbookId}`} />
        );
    }

    return (
        <div className={styles.playbookSuggestedChangesWrapper} data-testid={`${testId}-suggested-changes-wrapper`}>
            <div className={styles.suggestedChangesContentWrapper}>
                <PlaybookSideMenu
                    playbook={playbook}
                    isAdmin
                    sideMenuOpen={sideMenuOpen}
                    setSideMenuOpen={setSideMenuOpen}
                    showViewButtons
                    changePlaybookPageView={changePlaybookPageView}
                    currentView={currentPlaybookPageView}
                    tagsSectionFilter={openSuggestedChange?.sectionId}
                    contentDisabled={contentDisabled}
                    testId={`${testId}-suggested-changes-side-menu`}
                />
                {content}
            </div>
            <div className={styles.buttonWrapper}>
                <div className={styles.buttonGroupLeft} style={{ width: pageWidth(PlaybookPageView.CURRENT) }}>
                    {showLeftButtonGroup &&
                        <>
                            <ButtonResize showIconButton={showIconButtons} onClick={backToPlaybook} label='Back' icon={ArrowLeft} testId={`${testId}-suggested-changes`} />
                            <VersionControl testId={`${testId}-suggested-changes-version-control`} />
                            <div className={styles.saveAndPublishWrapper}>
                                <ButtonResize showIconButton={showIconButtons} onClick={savePlaybook} label='Save' icon={SaveFile} disabled={!canSave} testId={`${testId}-suggested-changes`} />
                                <ButtonResize showIconButton={showIconButtons} onClick={publishPlaybook} label='Publish' icon={PlaybookIcon} disabled={!canPublish} testId={`${testId}-suggested-changes`} />
                            </div>
                        </>
                    }
                </div>
                {showRightButtonGroup &&
                    <div className={styles.buttonGroupRight} style={{ width: pageWidth(PlaybookPageView.CHANGES) }}>
                        {showConfirmResolved && <Button onClick={confirmResolved} label='Confirm Resolved' testId={`${testId}-suggested-changes-confirm-resolved`} />}
                        {showReviewButton && <Button onClick={reviewSuggestion} label='Review Suggestion' testId={`${testId}-suggested-changes-review-suggestion`} />}
                    </div>
                }
            </div>
            <PlaybookSaveModal testId={`${testId}-save`} />
            <PlaybookPublishModal testId={`${testId}-publish`} />
            <ReviewSuggestionModal testId={`${testId}-review-changes`} />
        </div>
    );
};
