import { min } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useWindowResize } from '../../hooks/useWindowResize';
import { Icon } from '../shared/icon/Icon';
import styles from './DoraHome.module.scss';

interface DoraHomeButtonProps {
    label: string;
    icon: React.FC;
    onClick?: () => void;
    testId?: string;
    fill?: string;
}

export const DoraHomeButton: React.FC<DoraHomeButtonProps> = ({ label, icon, onClick, testId, fill }) => {
    const [screenWidth, screenHeight] = useWindowResize();
    const iconSize = useMemo(() => min([screenHeight / 4, screenWidth / 4, 250])!, [screenHeight, screenWidth]);

    return (
        <button
            className={styles.doraHomeButton}
            onClick={onClick}
            data-testid={`dora-home-button-${testId}`}
        >
            <div className={styles.doraHomeButtonIcon}>
                <Icon icon={icon} fontSize={iconSize} fill={fill} />
            </div>
            <div className={styles.doraHomeButtonLabel}>{label}</div>
        </button>
    );
};
