import { ArkTableColumn } from './ArkTable';
import { commissionedByFormatter, dateFormatter, nullFormatter } from './arkTableFormatters';

export const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };

export const documentColDefs: ArkTableColumn[] = [
    { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', width: 0.3, component: 'tooltip', canFilter: true, canSort: true },
    { id: 'documentName', header: 'Document', field: 'documentName', width: 0.25, component: 'tooltip', canFilter: true, canSort: true },
    { id: 'entityAName', header: 'Party A', field: 'entityAName', width: 0.15, component: 'tooltip', canFilter: true, canSort: true },
    { id: 'entityBName', header: 'Party B', field: 'entityBName', width: 0.15, component: 'tooltip', canFilter: true, canSort: true },
    { id: 'executedDate', header: 'Date of Execution', field: 'executedDate', width: 0.1, valueFormatter: dateFormatter, canSort: true }
];

export const opinionColDefs: ArkTableColumn[] = [
    { id: 'description', header: 'Description', field: 'description', width: 0.25 },
    { id: 'jurisdiction', header: 'Jurisdiction', field: 'jurisdiction', width: 0.10 },
    { id: 'dateOfOpinion', header: 'Date of Opinion', field: 'dateOfOpinion', width: 0.15, valueFormatter: dateFormatter },
    { id: 'scope', header: 'Scope', field: 'scope', width: 0.11, valueFormatter: nullFormatter },
    { id: 'providedBy', header: 'Provided By', field: 'providedBy', width: 0.11, valueFormatter: nullFormatter },
    { id: 'commissionedBy', header: 'Commissioned By', field: 'commissionedBy', width: 0.11, valueFormatter: commissionedByFormatter },
    { id: 'reviewedBy', header: 'Reviewed By', field: 'reviewedByName', width: 0.12, valueFormatter: nullFormatter }
];
