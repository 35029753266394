import React from 'react';
import Select, { Options } from 'react-select';
import { noop } from 'lodash/fp';
import classnames from 'classnames';

import styles from './DropdownLists.module.scss';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { OverflowTooltip, InformationTooltip } from '../../shared/tooltip';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { customStyles } from '../../shared/dropdown/Dropdown';

interface DropdownOption {
    value: string;
    label: string;
}

type DropdownOptions = Options<DropdownOption> | Options<DropdownOption>[] | undefined

interface DropdownProps {
    value: string[] | null;
    options: string[];
}

export const Dropdown: React.FC<DropdownProps> = ({ value, options }) => {
    const mappedOptions: DropdownOptions = options.map(option => ({ value: option, label: capitaliseStartLetter(option) }));
    const mappedValue: DropdownOptions = value?.map(value => ({ value, label: capitaliseStartLetter(value) }));

    return (
        <div
            data-testid='dropdown-list-dropdown-preview'
            className={styles.dropdownFieldWrapper}
        >
            <Select
                className={styles.dropdownField}
                classNamePrefix='ark-dropdown'
                value={mappedValue}
                onChange={noop}
                options={mappedOptions}
                styles={customStyles}
                fontWeight={500}
            />
        </div>
    );
};

interface PreviewModalProps {
    previewOpen: boolean;
    closePreview: () => void;
    options: string[];
    name: string;
    description: string;
}

export const PreviewModal: React.FC<PreviewModalProps> = ({ previewOpen, closePreview, options, name, description }) => (
    <ConfirmationModal
        isOpen={previewOpen}
        closeModal={closePreview}
        showConfirm={false}
        closeLabel='Close'
        testId='dropdown-list-preview'
    >
        <div className={styles.dropdownListPreviewWrapper} data-testid='dropdown-list-preview-wrapper'>
            <div className={styles.dropdownListPreviewTitle}>Preview</div>
            <div className={styles.dropdownListPreview}>
                <div className={styles.previewTitleWrapper}>
                    <OverflowTooltip className={classnames(styles.previewTitle, { [styles.emptyTitle]: !name.length })} overlayText={name} testId='dropdown-list-preview-title' />
                    {description.length > 0 && <InformationTooltip content={description} />}
                </div>
                <Dropdown
                    options={options}
                    value={null}
                />
            </div>
        </div>
    </ConfirmationModal>
);
