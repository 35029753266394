import React from 'react';

import { Tag } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';

interface DeleteTagConfirmationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    confirmDelete: () => void;
    tagToDelete: string;
}

export const DeleteTagConfirmationModal: React.FC<DeleteTagConfirmationModalProps> = ({ isOpen, closeModal, confirmDelete, tagToDelete }) => {

    const warning = 'This will permanently delete the tag and remove it from all clauses where it is being used. This cannot be undone.';

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={confirmDelete}
            isDeleting={false}
            headerLabel='Delete Tag'
            name={tagToDelete}
            testId='clause-tag'
            warning={warning}
            icon={Tag}
            deleteLabel='tag from your library'
        />
    );
};
