import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getRiskToleranceHelpModalOpen, toggleRiskToleranceHelpModal } from '../../admin/dataset-builder/store';
import { SingleIndicator, SmileyIndicator } from '../analytics/SingleIndicator';
import { SmileyNeutral } from '../icons';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { ModalHeader } from '../modal/ModalHeader';
import styles from './RiskTolerance.module.scss';

const { primary, white } = styles;

const generalExplanation = [
    'Certain document fields have been designated as risk fields by our system. These are fields where the answer may impact the overall risk of the agreement, such as "Governing Law".',
    'The risk tolerance for one of these fields is split into 5 categories: Of Serious Concern, Of Concern, Acceptable, Desirable, Highly Desirable.',
    'These fields can then help us identify the overall risk of an agreement, this can be seen within the document analytics.'
];

export const RiskToleranceHelpModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getRiskToleranceHelpModalOpen);
    const closeModal = () => dispatch(toggleRiskToleranceHelpModal());

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            closeLabel='Close'
            showConfirm={false}
        >
            <div className={styles.riskToleranceHelpWrapper}>
                <ModalHeader icon={SmileyNeutral} label='Risk Tolerance Help' iconColor={primary} iconFill={white} />
                <div className={styles.helpContent}>
                    <div className={styles.generalHelpContent}>
                        {generalExplanation.map((help, index) => (
                            <div key={index} className={styles.generalHelp}>{help}</div>
                        ))}
                    </div>
                    <div className={styles.riskLevelContentWrapper}>
                        <div className={styles.riskLevelHelpTitle}>Any risk fields will be shown with one of the following indicators</div>
                        <div className={styles.riskLevelContent}>
                            <div className={styles.riskLevelWrapper}>
                                <SingleIndicator indicator={SmileyIndicator.POSITIVE} fontSize={50} />
                                <div>The answer for the field is Highly Desirable</div>
                            </div>
                            <div className={styles.riskLevelWrapper}>
                                <SingleIndicator indicator={SmileyIndicator.NEGATIVE} fontSize={50} />
                                <div>The answer for the field is Of Serious Concern</div>
                            </div>
                            <div className={styles.riskLevelWrapper}>
                                <SingleIndicator indicator={SmileyIndicator.NEUTRAL} fontSize={50} />
                                <div>The answer for the field is Of Concern, Acceptable or Desirable</div>
                            </div>
                            <div className={styles.riskLevelWrapper}>
                                <SingleIndicator indicator={SmileyIndicator.NONE} fontSize={50} />
                                <div>Either no value for the fields has been provided, or it does not match any of the risk tolerance configuration criteria</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ ConfirmationModal>
    );
};
