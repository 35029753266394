import React from 'react';
import classnames from 'classnames';

import { useAppDispatch } from '../../../../hooks/react-redux';
import { Icon as ArkIcon } from '../../../shared/icon/Icon';
import { Checkbox, Number, Date, Text, Dropdown, Form, Link, Currencies } from '../../../shared/icons';
import sharedStyles from '../SharedBuilder.module.scss';
import { addAgencyDatasetField } from '../store';
import { AgencyDatasetField, AgencyDatasetFieldType, DatasetFieldType } from '../../../datasets/store';
import { EntityType } from '../../entity/store';
import { defaultField } from '../utils';

const fields: AgencyDatasetField[] = [
    {
        ...defaultField,
        type: DatasetFieldType.TEXT,
        linkedFields: []
    },
    {
        ...defaultField,
        type: DatasetFieldType.NUMBER,
        linkedFields: []
    },
    {
        ...defaultField,
        type: DatasetFieldType.CHECKBOX,
        linkedFields: []
    },
    {
        ...defaultField,
        type: DatasetFieldType.DROPDOWN,
        linkedFields: [],
        settings: {
            ...defaultField.settings,
            dropdownLinked: null
        }
    },
    {
        ...defaultField,
        type: DatasetFieldType.LINKFIELD,
        linkedFields: [],
        settings: {
            ...defaultField.settings,
            entityType: EntityType.COMPANY
        }
    },
    {
        ...defaultField,
        type: DatasetFieldType.DATE,
        linkedFields: []
    },
    {
        ...defaultField,
        type: DatasetFieldType.WIZARD,
        linkedFields: []
    },
    {
        ...defaultField,
        type: DatasetFieldType.CURRENCY_AMOUNT,
        linkedFields: []
    }
];

interface AgencyFieldListItemProps {
    label: AgencyDatasetFieldType;
    onClick: () => void;
    isFirstItem: boolean;
    isLastItem: boolean;
}

export const fieldListIcons = {
    [DatasetFieldType.TEXT]: Text,
    [DatasetFieldType.NUMBER]: Number,
    [DatasetFieldType.CHECKBOX]: Checkbox,
    [DatasetFieldType.DROPDOWN]: Dropdown,
    [DatasetFieldType.LINKFIELD]: Link,
    [DatasetFieldType.DATE]: Date,
    [DatasetFieldType.WIZARD]: Form,
    [DatasetFieldType.CURRENCY_AMOUNT]: Currencies
};

const AgencyFieldListItem: React.FC<AgencyFieldListItemProps> = ({ label, onClick, isFirstItem, isLastItem }) => {
    return (
        <button
            onClick={onClick}
            className={classnames(sharedStyles.fieldListItem, {
                [sharedStyles.firstItem]: isFirstItem,
                [sharedStyles.lastItem]: isLastItem
            })}
            data-testid={`agency-field-list-item-button-${label}`}
        >
            <div className={sharedStyles.fieldListLabel} data-testid={`agency-field-list-item-${label}`}>{label}</div>
            <ArkIcon icon={fieldListIcons[label]} fontSize={40} />
        </button>
    );
};

export const AgencyFieldList: React.FC = () => {
    const dispatch = useAppDispatch();

    const addFieldToDefinition = (field: AgencyDatasetField) => {
        dispatch(addAgencyDatasetField(field));
    };

    return (
        <div className={sharedStyles.fieldListWrapper} data-testid='agency-field-list-items-wrapper'>
            {fields.map((field, index) => {
                const isFirstItem = index === 0;
                const isLastItem = index === fields.length - 1;
                const addField = () => addFieldToDefinition(field);
                return (
                    <AgencyFieldListItem
                        key={field.type}
                        label={field.type}
                        onClick={addField}
                        isFirstItem={isFirstItem}
                        isLastItem={isLastItem}
                    />
                );
            })}
        </div>
    );
};
