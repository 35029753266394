import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { AnalysisView, ArkDocument } from '../../../documents/my-documents/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { NettingPage, OpinionNettingEngineState, NettingEngineTheory, NettingEngineValidGoverningLaw, NettingResponseInformation, NettingEngineTableView } from './types';

const getRoot = (state: ApplicationState): OpinionNettingEngineState => state.opinion.nettingEngine;

export const getNettingPage = (state: ApplicationState): NettingPage => getRoot(state).nettingPage;

export const getNettingEngineTheorySurvey = (state: ApplicationState): NettingEngineTheory => getRoot(state).nettingEngineTheory;

export const getCurrentNettingEngineTheory = (state: ApplicationState): NettingEngineTheory | null => getRoot(state).currentNettingEngineTheory;

export const getNettingEngineResults = (state: ApplicationState): NettingResponseInformation | null => getRoot(state).theoreticalResults;

export const getTheoreticalQuestion = (state: ApplicationState): string | null => getRoot(state).theoreticalQuestion;

export const getIsCalculatingResults = (state: ApplicationState): boolean => getRoot(state).calculatingResults;

export const getSurveyQuestionIndex = (state: ApplicationState): number => getRoot(state).surveyQuestionIndex;

export const getShowSummary = (state: ApplicationState): boolean => getRoot(state).showSummary;

export const getLeiModalOpen = (state: ApplicationState): boolean => getRoot(state).leiModalOpen;

export const getIsChangingQuestion = (state: ApplicationState): boolean => getRoot(state).isChangingQuestion;

export const getNettingAssumptionsModalOpen = (state: ApplicationState): boolean => getRoot(state).nettingAssumptionsModalOpen;

export const getNettingAssumptions = (state: ApplicationState): string[] => {
    const currentTheory = getCurrentNettingEngineTheory(state);
    const currentDocument = getCurrentNettingEngineDocument(state);
    // One of these should always be null. If theory is NOT null, we check the theory data
    const result: string[] = [];
    if (!isNull(currentTheory)) {
        if (currentTheory.governingLaw && !Object.values(NettingEngineValidGoverningLaw).includes(currentTheory.governingLaw as NettingEngineValidGoverningLaw)) {
            result.push(`The agreement is enforceable as a matter of local law (${currentTheory.governingLaw}).`);
        }
        if (currentTheory.counterpartyOffices && currentTheory.counterpartyOffices.length === 0) {
            result.push('The counterparty is operating from an office in the jurisdiction of its incorporation.');
        }
        result.push('Section 10(a) is applicable');
    } else if (!isNull(currentDocument)) {
        if (currentDocument.governingLaw && !Object.values(NettingEngineValidGoverningLaw).includes(currentDocument.governingLaw as NettingEngineValidGoverningLaw)) {
            result.push(`The agreement is enforceable as a matter of local law (${currentDocument.governingLaw}).`);
        }
        if (currentDocument.counterpartyOffices && currentDocument.counterpartyOffices.length === 0) {
            result.push('The counterparty is operating from an office in the jurisdiction of its incorporation.');
        }
    }
    if (result.length === 0) {
        result.push('There are no assumptions made on the current netting theory');
    }
    return result;
};

export const getUnauthorisedOpinionJurisdictions = (state: ApplicationState): string[] => {
    const results = getNettingEngineResults(state);
    if (isNull(results)) {
        return [];
    }
    const unauthorisedRequestedJurisdictions = results.jurisdictionalInformation.filter(eachResult => !eachResult.permittedToView).map(eachResult => eachResult.jurisdiction);
    const localLawJurisdiction = getLocalLawIsValidJurisdiction(state);
    return !isNull(localLawJurisdiction) ? [...unauthorisedRequestedJurisdictions, localLawJurisdiction] : unauthorisedRequestedJurisdictions;
};

export const getLocalLawIsValidJurisdiction = (state: ApplicationState): string | null => {
    const results = getNettingEngineResults(state);
    if (isNull(results)) {
        return null;
    }
    const localLawValid = results.jurisdictionalInformation[0].details.localLawIsValid;
    return (isNull(localLawValid) || localLawValid === 'Yes') ? null : getCurrentNettingEngineTheory(state)!.governingLaw;
};

export const getEnquiredOpinions = (state: ApplicationState): string[] => getRoot(state).enquiredOpinions;

export const getIsCustomConclusionValue = (state: ApplicationState): boolean => getRoot(state).isCustomConclusion;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getIsOpening = (state: ApplicationState): boolean => getRoot(state).isOpening;

export const getNettingEngineDocuments = (state: ApplicationState): ArkDocument[] => getRoot(state).nettingEngineDocuments;

export const getSavedNettingEngineDocument = (state: ApplicationState): NettingEngineTheory | null => getRoot(state).savedNettingEngineDocument;
export const getCurrentNettingEngineDocument = (state: ApplicationState): NettingEngineTheory | null => getRoot(state).currentNettingEngineDocument;

export const getCalculatingExistingDocumentsResults = (state: ApplicationState): boolean => getRoot(state).calculatingExistingDocumentsResults;
export const getExistingDocumentsResults = (state: ApplicationState): NettingResponseInformation | null => getRoot(state).existingDocumentsResults;

export const getNettingEngineTableView = (state: ApplicationState): NettingEngineTableView => getRoot(state).nettingEngineTableView;

export const getDocumentSummaryView = (state: ApplicationState): AnalysisView => getRoot(state).documentSummaryView;

// Table Filters and Pagination
export const getNettingEngineDocumentsPageNumber = (state: ApplicationState): number => getRoot(state).nettingEngineDocumentPage;
export const getTotalNettingEngineDocuments = (state: ApplicationState): number => getRoot(state).totalNettingEngineDocuments;
export const getNettingEngineDocumentFilters = (state: ApplicationState): TableFilters => getRoot(state).nettingEngineDocumentFilters;
export const getNettingEngineDocumentColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).nettingEngineDocumentSort;
export const getNettingEngineDocumentPageSize = (state: ApplicationState): number => getRoot(state).nettingEngineDocumentPageSize;
