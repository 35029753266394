import { Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Scrollable } from '../../scrollable/Scrollable';
import { DraggableListProps, ListContext, ListContextValue, getItemRegistry, isItemData } from '../shared';
import { Draggable } from './Draggable';
import styles from './Draggable.module.scss';

export const VerticalDraggableList: React.FC<DraggableListProps> = ({ listId, list, updateList, getChildElement, getDraggingElement, edgeColour, edgeWidth }) => {
    const [registry] = useState(getItemRegistry);

    // Isolated instances of this component from one another
    const [instanceId] = useState(() => Symbol(listId));

    const reorderItem = useCallback(
        ({
            startIndex,
            indexOfTarget,
            closestEdgeOfTarget,
        }: {
            startIndex: number;
            indexOfTarget: number;
            closestEdgeOfTarget: Edge | null;
        }) => {
            const finishIndex = getReorderDestinationIndex({
                startIndex,
                closestEdgeOfTarget,
                indexOfTarget,
                axis: 'vertical'
            });

            if (finishIndex === startIndex) {
                // If there would be no change, we skip the update
                return;
            }

            const updatedList = reorder({
                list,
                startIndex,
                finishIndex,
            });
            updateList(updatedList);
        }, [list, updateList]);

    useEffect(() => monitorForElements({
        canMonitor: ({ source }) => isItemData(source.data) && source.data.instanceId === instanceId,
        onDrop: ({ location, source }) => {
            const target = location.current.dropTargets[0];
            if (!target) {
                return;
            }

            const sourceData = source.data;
            const targetData = target.data;
            if (!isItemData(sourceData) || !isItemData(targetData)) {
                return;
            }

            const indexOfTarget = list.findIndex(({ id }) => id === targetData.item.id);
            if (indexOfTarget < 0) {
                return;
            }
            const closestEdgeOfTarget = extractClosestEdge(targetData);
            reorderItem({
                startIndex: sourceData.index,
                indexOfTarget,
                closestEdgeOfTarget,
            });
        },
    }), [instanceId, list, reorderItem]);

    const getListLength = useCallback(() => list.length, [list.length]);

    const contextValue: ListContextValue = useMemo(() => ({
        registerItem: registry.register,
        reorderItem,
        instanceId,
        getListLength,
    }), [registry.register, reorderItem, instanceId, getListLength]);

    return (
        <ListContext.Provider value={contextValue}>
            <div className={styles.listContainer}>
                <Scrollable>
                    {list.map((item, index) => (
                        <Draggable
                            key={item.id}
                            item={item}
                            index={index}
                            getChildElement={getChildElement}
                            getDraggingElement={getDraggingElement}
                            edgeColour={edgeColour}
                            edgeWidth={edgeWidth}
                        />
                    ))}
                </Scrollable>
            </div>
        </ListContext.Provider>
    );
};
