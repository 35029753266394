import React from 'react';

export const MultiAnalytics: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg viewBox="0 0 64 64" width={width || '1em'} height={height || '1em'}>
        <g>
            <g>
                <path d="M25,52H7a3.00328,3.00328,0,0,1-3-3V12H54v7a1,1,0,0,0,2,0V7a5.00588,5.00588,0,0,0-5-5H7A5.00588,5.00588,0,0,0,2,7V49a5.00588,5.00588,0,0,0,5,5H25a1,1,0,0,0,0-2ZM7,4H51a3.00328,3.00328,0,0,1,3,3v3H4V7A3.00328,3.00328,0,0,1,7,4ZM8,7A1,1,0,1,1,9,8,1,1,0,0,1,8,7Zm6,0a1,1,0,1,1,1,1A1,1,0,0,1,14,7Zm6,0a1,1,0,1,1,1,1A1,1,0,0,1,20,7ZM61,34H53a1,1,0,0,0-1,1V61a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V35A1,1,0,0,0,61,34ZM60,60H54V36h6ZM49,40H41a1,1,0,0,0-1,1V61a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V41A1,1,0,0,0,49,40ZM48,60H42V42h6ZM37,46H29a1,1,0,0,0-1,1V61a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V47A1,1,0,0,0,37,46ZM36,60H30V48h6ZM8,26A10,10,0,1,0,18,16,10.01114,10.01114,0,0,0,8,26Zm10,8a7.99643,7.99643,0,0,1-1-15.93054V26a1,1,0,0,0,1,1h7.93054A8.007,8.007,0,0,1,18,34Zm7.93054-9H19V18.06946A8.00705,8.00705,0,0,1,25.93054,25ZM26,41a1,1,0,0,1-1,1H9a1,1,0,0,1,0-2H25A1,1,0,0,1,26,41ZM9,48a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2ZM31,17a1,1,0,0,1,1-1H47a1,1,0,0,1,0,2H32A1,1,0,0,1,31,17Zm0,6a1,1,0,0,1,1-1H47a1,1,0,0,1,0,2H32A1,1,0,0,1,31,23Zm0,6a1,1,0,0,1,1-1h6a1,1,0,0,1,0,2H32A1,1,0,0,1,31,29Zm22-7h6a1,1,0,0,1,1,1v6a1,1,0,0,1-2,0V25.41406L47.707,35.707a.99962.99962,0,0,1-1.41406,0L43,32.41406,31.707,43.707A.99989.99989,0,0,1,30.293,42.293l12-12a.99962.99962,0,0,1,1.41406,0L47,33.58594,56.58594,24H53a1,1,0,0,1,0-2Z" fill={color || 'currentColor'} stroke='none' />
            </g>
        </g>
    </svg>
);
