import { Reducer } from 'redux';
import { set, flow } from 'lodash/fp';

import { UsersActionTypes, NewUser, UsersState } from './types';
import { emailValidator } from '../../../../utils/regex-utils';
import { LoginActionTypes } from '../../../auth/login/store';
import { ClientActionTypes } from '../../clients/store';
import { UserRole } from '../../../constants/permittedRoles';

export const initialUser = (clientId?: number): NewUser => ({
    username: '',
    forenames: '',
    surname: '',
    email: '',
    role: UserRole.USER,
    featurePermissions: [],
    clientId
});

export const INITIAL_STATE: UsersState = {
    modalOpen: false,
    user: initialUser(),
    allUsers: [],
    fetchingUsers: false,
    userError: '',
    usernameUnavailable: false,
    checkingUsername: false,
    emailValid: false,
    availableFeaturePermissions: [],
    confirmDeleteUser: null,
    deletingUser: false,
    basicUserInfo: []
};

export const usersReducer: Reducer<UsersState> = (state = INITIAL_STATE, { type, payload }): UsersState => {
    switch (type) {
        case UsersActionTypes.TOGGLE_USERS_MODAL: {
            const { user, clientId, isOpen } = payload;
            const selectedUser = user || initialUser(clientId);
            return flow(
                set('modalOpen', isOpen),
                set('user', selectedUser),
                set('usernameUnavailable', false)
            )(state);
        }
        case UsersActionTypes.UPDATE_USER_VALUE: {
            const { key, value } = payload;
            return set(`user[${key}]`, value, state);
        }
        case UsersActionTypes.CHECK_EMAIL_VALID: {
            const emailValid = emailValidator(payload);
            return set('emailValid', emailValid, state);
        }
        case UsersActionTypes.UPSERT_USER_FAILED:
            return set('userError', payload, state);
        case UsersActionTypes.UPSERT_USER_SUCCESSFUL:
            return flow(
                set('modalOpen', false),
                set('allUsers', payload)
            )(state);
        case UsersActionTypes.CHECK_USERNAME_AVAILABLE_STARTED:
            return set('checkingUsername', true, state);
        case UsersActionTypes.CHECK_USERNAME_AVAILABLE_SUCCESSFUL:
            return flow(
                set('checkingUsername', false),
                set('usernameUnavailable', payload)
            )(state);
        case UsersActionTypes.CHECK_USERNAME_AVAILABLE_FAILED:
            return flow(
                set('checkingUsername', false),
                set('userError', payload)
            )(state);
        case UsersActionTypes.FETCH_ALL_USERS_STARTED:
            return set('fetchingUsers', true, state);
        case UsersActionTypes.FETCH_ALL_USERS_SUCCESSFUL: {
            return flow(
                set('allUsers', payload),
                set('fetchingUsers', false)
            )(state);
        }
        case UsersActionTypes.FETCH_ALL_USERS_FAILED:
            return flow(
                set('userError', payload),
                set('fetchingUsers', false)
            )(state);
        case UsersActionTypes.FETCH_AVAILABLE_FEATURE_PERMISSIONS_SUCCESSFUL:
            return set('availableFeaturePermissions', payload, state);
        case UsersActionTypes.TOGGLE_DELETE_USER_CONFIRMATION_MODAL:
            return set('confirmDeleteUser', payload, state);
        case UsersActionTypes.DELETE_USER_STARTED:
            return set('deletingUser', true, state);
        case UsersActionTypes.DELETE_USER_SUCCESSFUL:
            return flow(
                set('allUsers', payload),
                set('deletingUser', false),
                set('confirmDeleteUser', null)
            )(state);
        case UsersActionTypes.DELETE_USER_FAILED:
            return flow(
                set('userError', payload),
                set('deletingUser', false)
            )(state);
        case ClientActionTypes.UPDATE_CLIENT_USERS:
            return flow(
                set('deletingUser', false),
                set('confirmDeleteUser', null),
                set('modalOpen', false)
            )(state);
        case UsersActionTypes.FETCH_ALL_BASIC_USERS_STARTED:
            return set('fetchingUsers', true, state);
        case UsersActionTypes.FETCH_ALL_BASIC_USERS_SUCCESSFUL: {
            return flow(
                set('basicUserInfo', payload),
                set('fetchingUsers', false)
            )(state);
        }
        case UsersActionTypes.FETCH_ALL_BASIC_USERS_FAILED:
            return flow(
                set('userError', payload),
                set('fetchingUsers', false)
            )(state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
