import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { PageToggleButton } from '../../../shared/button/PageToggleButton';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Attestation } from '../../../shared/icons';
import { TableTabs } from '../../../shared/table/TableTabs';
import { fetchAllUsersStarted } from '../../users/store';
import {
    AttestationManager as AttestationManagerType,
    AttestationPage,
    fetchAllAttestationFormsStarted,
    fetchAllAttestationInstancesStarted,
    getAllAttestationInstances,
    getAttestationInstancesCompleted,
    getNewAttestationModalOpen,
    getNewAttestations,
    getSelectedAttestationInstance,
    setAttestationPage,
    toggleAttestationInstanceView,
    toggleAttestationManagerWizardOpen,
    toggleNewAttestationModalOpen
} from '../store';
import styles from './AttestationManager.module.scss';
import { AttestationManagerTable } from './AttestationManagerTable';
import { AttestationManagerWizard } from './AttestationManagerWizard';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { NewAttestationModal } from './NewAttestationModal';

export interface AttestationManagerRouteParams {
    attestationInstanceId: string | undefined;
}

export const AttestationManager: React.FC<RouteComponentProps<AttestationManagerRouteParams>> = ({ match: { params, path } }) => {
    const testId = 'admin-attestation-manager';
    const dispatch = useAppDispatch();
    const goToForms = () => dispatch(setAttestationPage(AttestationPage.FORMS));
    useFetchStarted([fetchAllAttestationFormsStarted(), fetchAllUsersStarted()]);
    const newAttestationModalOpen = useAppSelector(getNewAttestationModalOpen);
    const newAttestations = useAppSelector(getNewAttestations);
    const completed = useAppSelector(getAttestationInstancesCompleted);
    const changeView = useCallback((attestationInstanceId?: string) => dispatch(toggleAttestationInstanceView(!completed, attestationInstanceId)), [completed, dispatch]);
    const toggleNewAttestationModal = useCallback((isOpen: boolean) => dispatch(toggleNewAttestationModalOpen(isOpen)), [dispatch]);
    const openNewAttestationModal = () => toggleNewAttestationModal(true);
    const closeNewAttestationModal = () => toggleNewAttestationModal(false);
    const attestations = useAppSelector(getAllAttestationInstances);
    const attestationInstance = useAppSelector(getSelectedAttestationInstance);

    const fetchAttestations = useCallback(() => dispatch(fetchAllAttestationInstancesStarted(completed)), [dispatch, completed]);

    const openAttestationInstance = useCallback((attestation: AttestationManagerType) => dispatch(toggleAttestationManagerWizardOpen(true, attestation)), [dispatch]);

    const attestationInParamsExists = useMemo(() => (params.attestationInstanceId && attestations.map(({ attestationInstance: { attestationInstanceId } }) => attestationInstanceId.toString()).includes(params.attestationInstanceId)) || false, [attestations, params]);
    useEffect(() => {
        if (!attestationInstance && params.attestationInstanceId && attestationInParamsExists) {
            const attestationFromParams = attestations.find(({ attestationInstance: { attestationInstanceId } }) => attestationInstanceId.toString() === params.attestationInstanceId)!;
            openAttestationInstance(attestationFromParams);
        }
    }, [params, attestationInstance, attestationInParamsExists, attestations, openAttestationInstance]);

    useEffect(() => {
        if ((path.includes('completed') && !completed) || (!path.includes('completed') && completed)) {
            changeView(params.attestationInstanceId);
        }
    }, [completed, path, changeView, params]);

    useEffect(() => {
        fetchAttestations();
    }, [fetchAttestations]);

    return (
        <div className={styles.managerWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.managerHeaderWrapper}>
                <div className={styles.managerLeftHeaderWrapper}>
                    <div className={styles.managerHeaderTitle} data-testid={`${testId}-title`}>Attestation Manager</div>
                    <PlusButton onClick={openNewAttestationModal} testId={`${testId}-create`} fontSize={22} />
                </div>
                <PageToggleButton label='Forms' onClick={goToForms} icon={Attestation} testId={`${testId}`} />
            </div>
            <TableTabs
                tabs={[
                    { tabToggleAction: changeView, tabTitle: 'Incomplete', isSelected: !completed },
                    { tabToggleAction: changeView, tabTitle: 'Complete', isSelected: completed }
                ]}
                testId={testId}
            />
            <AttestationManagerTable attestations={attestations} completed={completed} />
            {newAttestations && <NewAttestationModal isOpen={newAttestationModalOpen} closeModal={closeNewAttestationModal} attestationInstances={newAttestations} testId={`${testId}-new`} />}
            {attestationInstance && <AttestationManagerWizard attestation={attestationInstance} testId={`${testId}-instance`} />}
            <DeleteConfirmationModal />
        </div>
    );
};
