import { action } from 'typesafe-actions';

import { DateFilter, SearchDocumentActionTypes, SearchFilter, SearchResult, SearchDocumentsView, SmartSearch } from './types';

export const toggleSearchDocumentsView = (view: SearchDocumentsView | null, isPreExecution?: boolean) => action(SearchDocumentActionTypes.TOGGLE_SEARCH_DOCUMENTS_VIEW, { view, isPreExecution });
export const toggleSearchFilterModal = (isOpen: boolean) => action(SearchDocumentActionTypes.TOGGLE_SEARCH_FILTER_MODAL, isOpen);

export const updateSearchFilter = (key: keyof SearchFilter, value: string[] | string | boolean | null) => action(SearchDocumentActionTypes.UPDATE_SEARCH_FILTER, { key, value });
export const updateExecutedDateFilter = (key: keyof DateFilter, value: string | null) => action(SearchDocumentActionTypes.UPDATE_EXECUTED_DATE_FILTER, { key, value });
export const resetFilters = () => action(SearchDocumentActionTypes.RESET_FILTERS);

export const openIncompleteSearchDocument = (documentId: number) => action(SearchDocumentActionTypes.OPEN_INCOMPLETE_SEARCH_DOCUMENT, documentId);

export const updateSimpleSearch = (searchTerm: string) => action(SearchDocumentActionTypes.UPDATE_SIMPLE_SEARCH, searchTerm);
export const updateSmartSearch = (smartSearch: SmartSearch[]) => action(SearchDocumentActionTypes.UPDATE_SMART_SEARCH, smartSearch);

export const resetSearch = () => action(SearchDocumentActionTypes.RESET_SEARCH);
export const searchDocumentsStarted = (pageNumber: number) => action(SearchDocumentActionTypes.SEARCH_DOCUMENTS_STARTED, pageNumber);
export const searchDocumentsSuccessful = (searchResults: SearchResult[] | null, pages: number, searchType: SearchDocumentsView | null, total: number) => action(SearchDocumentActionTypes.SEARCH_DOCUMENTS_SUCCESSFUL, { searchResults, pages, searchType, total });
export const searchDocumentsFailed = (error: string) => action(SearchDocumentActionTypes.SEARCH_DOCUMENTS_FAILED, error);

export const toggleDocumentPreviewModal = (value: boolean) => action(SearchDocumentActionTypes.TOGGLE_DOCUMENT_PREVIEW_MODAL, value);

export const openDocumentPreviewStarted = (result: SearchResult) => action(SearchDocumentActionTypes.OPEN_DOCUMENT_PREVIEW_STARTED, result);
export const openDocumentPreviewSuccessful = (documentUrl: string, previewResult: SearchResult) => action(SearchDocumentActionTypes.OPEN_DOCUMENT_PREVIEW_SUCCESSFUL, { documentUrl, previewResult });
export const openDocumentPreviewFailed = (error: string) => action(SearchDocumentActionTypes.OPEN_DOCUMENT_PREVIEW_FAILED, error);
