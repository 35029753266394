import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getAgencyDefinition, getAgencyModalOpen, toggleAgencyBuilder } from '../store';
import { AgencyBuilderModal } from './AgencyBuilderModal';
import { AgencyDatasetsTable } from './AgencyDatasetsTable';
import styles from './AgencyDatasetBuilder.module.scss';
import { TableTabs, Tabs } from '../../../shared/table/TableTabs';

interface AgencyDatasetsProps {
    tabs: Tabs[];
}

export const AgencyDatasets: React.FC<AgencyDatasetsProps> = ({ tabs }) => {
    const dispatch = useAppDispatch();
    const agencyBuilderOpen = useAppSelector(getAgencyModalOpen);
    const agencyDefinition = useAppSelector(getAgencyDefinition);

    const closeBuilder = () => dispatch(toggleAgencyBuilder(false));

    return (
        <div className={styles.agencyDatasetsWrapper} data-testid='agency-datasets-wrapper'>
            <div className={styles.datasetsTitleContainer}>
                <div className={styles.datasetsTitle} data-testid='agency-datasets-title'>Agency Datasets</div>
            </div>
            <TableTabs
                tabs={tabs}
                testId='agency-datasets'
            />
            <AgencyDatasetsTable />
            <AgencyBuilderModal
                isOpen={agencyBuilderOpen}
                closeModal={closeBuilder}
                agencyDefinition={agencyDefinition}
            />
        </div>
    );
};
