import { isNull } from 'lodash/fp';
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import { useAppSelector } from '../../../../hooks/react-redux';
import { DATASET_HEIGHT_OFFSET, PAGE_MARGIN_PADDING_BORDER, useSplitView } from '../../../../hooks/useSplitView';
import { Icon } from '../../../shared/icon/Icon';
import { Reference } from '../../../shared/icons';
import { Position } from '../../../shared/modal/PositionModal';
import { IconTooltip } from '../../../shared/tooltip';
import { getOpinionAnalysisView } from '../../my-opinions/store';
import styles from './Fields.module.scss';
import { ReadOnlyPageRef } from './ReadOnlyPageRef';
import { RefModal } from './RefModal';

interface RefProps {
    id: string;
    modalOpen: boolean;
    refLabel: string;
    pageRef: string[];
    pageRefVerified: boolean;
    toggleRefOpen: (isOpen: boolean) => void;
    updateRefLabel: (value: string) => void;
    updatePageRef: (value: string, index: number) => void;
    removePageRef: (index: number) => void;
    verifyPageRefs: (checked: boolean) => void;
    addPageRef: () => void;
    scrollToPage: (pageNumber: number) => void;
    readOnly: boolean;
}

export const Ref: React.FC<RefProps> = ({ id, modalOpen, refLabel, updateRefLabel, toggleRefOpen, readOnly, pageRef, pageRefVerified, verifyPageRefs, updatePageRef, addPageRef, removePageRef, scrollToPage }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);
    const opinionAnalysisView = useAppSelector(getOpinionAnalysisView);
    const [datasetWidth] = useSplitView(opinionAnalysisView, PAGE_MARGIN_PADDING_BORDER, (DATASET_HEIGHT_OFFSET - 20));
    const refWidth = useMemo(() => datasetWidth / 2, [datasetWidth]);

    const openModal = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setPosition({ x: e.clientX, y: e.clientY });
        setIsOpen(true);
        toggleRefOpen(true);
    }, [setPosition, setIsOpen, toggleRefOpen]);

    const closeModal = () => {
        setIsOpen(false);
        toggleRefOpen(false);
    };

    const content = useMemo(() => refLabel.length ? refLabel : null, [refLabel]);

    useEffect(() => {
        if (modalOpen) {
            setIsOpen(modalOpen);
        }
    }, [modalOpen]);

    const renderRefButton = useMemo(() => {
        if (readOnly) {
            if (isNull(content)) {
                return null;
            }
            return (
                <div className={styles.disabledRefButton} >
                    <IconTooltip content={content} icon={Reference} width={refWidth} />
                </div>
            );
        }
        return (
            <button className={classnames(styles.refButton, { [styles.unverifiedRefButton]: !pageRefVerified })} onClick={openModal} data-testid={`${id}-button`}>
                <Icon icon={Reference} fontSize={20} />
            </button>
        );
    }, [id, content, openModal, readOnly, refWidth, pageRefVerified]);

    const renderPageRefButton = useMemo(() => {
        if (pageRef.some(val => val.length) && readOnly) {
            return (
                <div className={styles.disabledRefButton} >
                    <ReadOnlyPageRef pageRefs={pageRef} onClick={scrollToPage} pageRefVerified={pageRefVerified} />
                </div>
            );
        }
        return null;
    }, [pageRef, scrollToPage, readOnly, pageRefVerified]);

    return (
        <>
            <div className={styles.refButtonWrapper}>
                {renderRefButton}
                {renderPageRefButton}
            </div>
            <RefModal
                isOpen={isOpen}
                closeModal={closeModal}
                position={position}
                updateRefLabel={updateRefLabel}
                refLabel={refLabel}
                updatePageRef={updatePageRef}
                addPageRef={addPageRef}
                removePageRef={removePageRef}
                pageRef={pageRef}
                pageRefVerified={pageRefVerified}
                verifyPageRefs={verifyPageRefs}
            />
        </>
    );
};
