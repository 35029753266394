import React from 'react';

import { Icon } from '../icon/Icon';
import { Delete } from '../icons';
import styles from './Button.module.scss';
import { OverflowTooltip } from '../tooltip';

interface DocumentLinkButtonProps {
    open: () => void;
    remove: () => void;
    buttonLabel: string;
    header: string;
    showRemove: boolean;
    testId?: string;
    disabled?: boolean;
}

export const DocumentLinkButton: React.FC<DocumentLinkButtonProps> = ({ open, remove, buttonLabel, showRemove, testId = 'document-link-button', header, disabled = false }) => (
    <div data-testid={`${testId}-wrapper`} className={styles.documentLinkWrapper}>
        <div className={styles.documentLinkHeader} data-testid={`${testId}-header`}>{header}</div>
        <div className={styles.buttonWrapper}>
            <button data-testid={`${testId}-button`} onClick={open} className={styles.linkButton} disabled={disabled}>
                <OverflowTooltip overlayText={buttonLabel} />
            </button>
            {showRemove &&
                <button className={styles.removeLink} onClick={remove} disabled={disabled}>
                    <Icon icon={Delete} fontSize={25} />
                </button>
            }
        </div>
    </div>
);
