import React, { MouseEvent } from 'react';
import classnames from 'classnames';

import styles from './Button.module.scss';
import { Icon as ArkIcon } from '../icon/Icon';
import { Delete } from '../icons';

interface DeleteButtonProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    fontSize?: number;
    withBorder?: boolean;
    testId?: string;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, disabled = false, fontSize, testId = '', withBorder = false }) => (
    <button
        onClick={onClick}
        className={classnames(styles.deleteButton, { [styles.deleteButtonWithBorder]: withBorder })}
        disabled={disabled}
        data-testid={`delete-button-${testId}`}
    >
        <ArkIcon icon={Delete} fontSize={fontSize} />
    </button>
);
