import React, { useMemo } from 'react';
import classnames from 'classnames';

import styles from '../../ConfigureDatasets.module.scss';
import { SingleDatasetField, TableDatasetFieldType } from '../../../../../datasets/store';
import { DatasetsOpenFieldSection, HiddenDocumentField } from '../../../store';
import { widthsByType } from '../../../preview/tablePreview/TableHeader';
import { InformationTooltip } from '../../../../../shared/tooltip';
import { getAdminTableDatasetField } from '../../../../../shared/datasets/AdminDatasetFields';

interface DocumentDatasetTableCellProps {
    field: SingleDatasetField;
    index: number;
    isFirstCell: boolean;
    datasetId: number;
    toggleSection: (fieldSection: DatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: DatasetsOpenFieldSection) => boolean;
    modalInstance?: boolean
    columnHidden: boolean;
    hiddenDocumentNameIds?: number[] | undefined;
    hiddenSingleFields: HiddenDocumentField[];
}

export const DocumentDatasetTableCell: React.FC<DocumentDatasetTableCellProps> = ({ field, index, isFirstCell, datasetId, toggleSection, getSectionOpen, modalInstance, columnHidden, hiddenDocumentNameIds, hiddenSingleFields }) => {

    const cellWidth = useMemo(() => `${widthsByType[field.type as TableDatasetFieldType]}px`, [field.type]);

    const currentHiddenDocumentsForField = hiddenSingleFields.find(({ fieldId }) => fieldId === field.id)?.documentNameIds || [];
    const allHiddenFieldsForCell = [...currentHiddenDocumentsForField, ...hiddenDocumentNameIds || []];

    return (
        <div
            className={classnames(styles.cellWrapper, {
                [styles.firstCell]: isFirstCell,
            })}
            style={{ width: cellWidth, minWidth: cellWidth }}
            data-testid={`table-cell-${index}-wrapper`}
        >
            <div className={styles.openCellWrapper}>
                {getAdminTableDatasetField(field, index, datasetId, toggleSection, getSectionOpen, columnHidden, false, modalInstance, allHiddenFieldsForCell)}
                {field.settings.showClause && <InformationTooltip content='Add personalised clause label here' label='cl' />}
            </div>
        </div>
    );
};
