import { isArray } from 'lodash/fp';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import classnames from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePicker.module.scss';

const convertUTCToLocalDate = (date: Date | null): null | Date => {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return date;
};

export const convertLocalToUTCDate = (date: Date | [Date | null, Date | null] | null) => {
    if (isArray(date)) {
        date = date[0];
    }
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return date;
};

interface DatePickerProps {
    value: Date | null;
    onChange: (value: Date | null) => void;
    disabled?: boolean;
    testId: string;
    minDate?: Date;
    maxDate?: Date;
    lightBorder?: boolean;
    placeholder?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({ value, disabled = false, onChange, testId, minDate, maxDate, lightBorder = false, placeholder = 'Select a date' }) => (
    <ReactDatePicker
        dateFormat='dd/MM/yyyy'
        disabled={disabled}
        selected={convertUTCToLocalDate(value)}
        onChange={date => onChange(convertLocalToUTCDate(date))}
        placeholderText={placeholder}
        className={classnames(styles.dateField, { [styles.dateFieldLightBorder]: lightBorder })}
        data-testid={`datepicker-${testId}`}
        wrapperClassName={styles.dateFieldWrapper}
        minDate={minDate ? convertUTCToLocalDate(minDate) : undefined}
        maxDate={maxDate ? convertUTCToLocalDate(maxDate) : undefined}
        fixedHeight
        showYearDropdown
        dateFormatCalendar='MMMM'
        yearDropdownItemNumber={40}
        scrollableYearDropdown
        dropdownMode='scroll'
        id={`datepicker-${testId}`}
        portalId='root'
        autoComplete='off'
    />
);
