import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Icon } from '../../shared/icon/Icon';
import { Document } from '../../shared/icons';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { OverflowTooltip, TooltipList } from '../../shared/tooltip';
import { getAllDocumentNames } from '../documents/store';
import styles from './AIManager.module.scss';
import { fetchAgreementTypeQueriesStarted, getAllAIAgreementTypes } from './store';

export const AgreementTypeSelect: React.FC = () => {
    const dispatch = useAppDispatch();

    const agreementTypes = useAppSelector(getAllAIAgreementTypes);
    const documentNames = useAppSelector(getAllDocumentNames);

    const getRelevantDocumentNames = useCallback((id: number) => documentNames.filter(({ agreementTypeId }) => agreementTypeId === id).map(({ documentName }) => documentName), [documentNames]);
    const selectAgreementType = useCallback((agreementTypeId: number) => dispatch(fetchAgreementTypeQueriesStarted(agreementTypeId)), [dispatch]);

    return (
        <div className={styles.agreementTypeSelectWrapper} data-testid='ai-agreement-type-select-wrapper'>
            <Scrollable>
                {agreementTypes.map(({ agreementTypeId, agreementType, correctQueries, totalQueries, percentage }) => (
                    <button className={styles.agreementTypeWrapper} onClick={() => selectAgreementType(agreementTypeId)} key={agreementTypeId}>
                        <div className={styles.documentNames}>
                            <div className={styles.agreementTypeLabel}>{agreementType}</div>
                            <TooltipList overlayText={getRelevantDocumentNames(agreementTypeId)}>
                                <div className={styles.agreementTypeDocumentNames}>
                                    <Icon icon={Document} />
                                </div>
                            </TooltipList>
                        </div>
                        <div className={styles.percentageWrapper}>
                            <div className={styles.percentageBar}>
                                <div className={styles.successPercentage} style={{ width: `${percentage}%` }} />
                            </div>
                            <div className={styles.successInfo}>
                                <div className={styles.successString}>
                                    <OverflowTooltip overlayText={`Success Rate: ${correctQueries} / ${totalQueries}`} />
                                </div>
                                <div className={styles.displayPercentage}>{percentage}%</div>
                            </div>
                        </div>
                    </button>
                ))}
            </Scrollable>
        </div>
    );
};
