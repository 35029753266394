import React from 'react';

import { AttestationInstanceQuestion } from '../admin/attestations/store';
import { WarningSign } from '../shared/icons';
import { ConfirmationModal } from '../shared/modal/ConfirmationModal';
import { ModalHeader } from '../shared/modal/ModalHeader';
import styles from './Attestations.module.scss';

interface AlertModalProps {
    isOpen: boolean;
    closeModal: () => void;
    attestation: AttestationInstanceQuestion;
}

export const AlertModal: React.FC<AlertModalProps> = ({ isOpen, closeModal, attestation }) => {
    const { question, answer, config: { alertMessage } } = attestation;
    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            closeLabel='Close'
            showConfirm={false}
            testId='attestation-alert'
        >
            <div className={styles.alertModalWrapper}>
                <ModalHeader label='Red Flag Answer Alert' icon={WarningSign} iconSize={35} />
                <div className={styles.alertTitle}>For question:</div>
                <div className={styles.alertAnswer}>{question}</div>
                <div className={styles.alertTitle}>You answered:</div>
                <div className={styles.alertAnswer}>{answer}</div>
                <div className={styles.alertMessage}>{alertMessage}</div>
            </div>
        </ConfirmationModal>
    );
};
