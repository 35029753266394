import { AnnexDefinition, InstanceAnnexDefinition } from '../components/agency-annex/store';
import { api } from './services';

type PublishRequest = { annexDefinition: AnnexDefinition; };
type AnnexRequest = { documentNameId: number; };
type AgencyFieldRequest = { agencyDatasetId: number; };
type UpsertInstanceAnnexDefinitionsRequest = { instanceAnnexDefinitions: InstanceAnnexDefinition[]; documentId: number; documentNameId: number; };

export const publishAnnexDefinition = async (reqBody: PublishRequest) => await api('dataset/annex/upsertAnnexDefinition', { ...reqBody }, 'post');
export const fetchAllAnnexDefinitions = async (reqBody: AnnexRequest) => await api('dataset/annex/allAnnexDefinitions', { ...reqBody }, 'post');
export const fetchAgencyFieldsByAgencyDatasetId = async (reqBody: AgencyFieldRequest) => await api('dataset/annex/getAgencyFields', { ...reqBody }, 'post');
export const fetchAgencyFieldsByDatasetId = async (reqBody: { datasetId: number }) => await api('dataset/annex/getAgencyFieldsByDatasetId', { ...reqBody }, 'post');

export const fetchAnnexDefinitionByDocumentLocation = async (reqBody: { location: string }) => await api('dataset/annex/getByLocation', { ...reqBody }, 'post');

export const updateInstanceAnnexDefinitions = async (reqBody: UpsertInstanceAnnexDefinitionsRequest) => await api('dataset/annex/instance/upsert', { ...reqBody }, 'post');
