import React from 'react';
import Select, { components, Options, StylesConfig, ValueContainerProps } from 'react-select';

import { customStyles, DropdownOption, SharedDropdownProps } from './Dropdown';
import styles from './Dropdown.module.scss';

export type DropdownOptionsType = Options<DropdownOption> | Options<DropdownOption>[] | undefined;

const { primary, french, frenchSelected, white, fontFamily } = styles;

type IsMulti = boolean;
const customMultiSummaryStyles: StylesConfig<DropdownOption, IsMulti> = {
    ...customStyles,
    option: (provided, state) => {
        let backgroundColour = white;
        if (state.isFocused) {
            backgroundColour = french;
        }
        if (state.isSelected) {
            backgroundColour = frenchSelected;
        }
        return ({
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '3px 5px',
            color: primary,
            backgroundColor: backgroundColour,
            fontSize: '13px',
            fontFamily: fontFamily,
            cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            borderBottom: state.data.showDivider ? `1px dashed ${french}` : ''
        });
    }
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<DropdownOption, IsMulti>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let [values, input] = children as any;
    if (Array.isArray(values)) {
        const plural = values.length === 1 ? '' : 's';
        const selectedValues = values.length === props.options.length ? 'All Selected' : `${values.length} item${plural} selected`;
        values = selectedValues;
    }

    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

export const DropdownSummary: React.FC<SharedDropdownProps> = ({
    testId,
    value,
    disabled = false,
    onChange,
    options,
    placeholder,
    label,
    isClearable = true,
    menuPortalTarget,
    marginBottom = '',
    controlBackgroundColor,
    hideIndicator = false,
    controlHeight = '38px',
    minControlHeight = '38px',
    maxControlHeight = '78.5px',
    disableControlColor,
    controlBorderColor,
    noControlBorder,
    fontWeight = 500,
    menuPlacement = 'auto',
    cursor = 'auto',
    isMulti = false
}) => {
    return (
        <div
            data-testid={`multi-dropdown-summary-${testId}`}
            className={styles.dropdownFieldWrapper}
            style={{ marginBottom, cursor }}
        >
            {label && <div data-testid={`multi-dropdown-summary-${testId}-label`} className={styles.dropdownLabel}>{label}</div>}
            <Select
                className={styles.dropdownField}
                classNamePrefix='ark-dropdown'
                isDisabled={disabled}
                value={value}
                onChange={onChange}
                isMulti={isMulti}
                options={options}
                isClearable={isClearable}
                placeholder={placeholder}
                styles={customMultiSummaryStyles}
                menuPortalTarget={menuPortalTarget}
                isOptionDisabled={({ disabled }) => !!disabled}
                controlBackgroundColor={controlBackgroundColor}
                controlBorderColor={controlBorderColor}
                hideIndicator={hideIndicator}
                controlHeight={controlHeight}
                disableControlColor={disableControlColor}
                noControlBorder={noControlBorder}
                fontWeight={fontWeight}
                minControlHeight={minControlHeight}
                maxControlHeight={maxControlHeight}
                menuPlacement={menuPlacement}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ ValueContainer }}
            />
        </div>
    );
};
