import { Reducer } from 'redux';
import { set, flow } from 'lodash/fp';

import { DocumentAnalyticsActionTypes, DocumentAnalyticsChart, DocumentAnalyticsState, DocumentRiskView, EntityDocumentRiskView, PreExecutionDocumentRiskView } from './types';
import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';

export const INITIAL_STATE: DocumentAnalyticsState = {
    tileInView: null,
    fetchingAnalytics: [],
    documentSpread: [],
    entitySpread: [],
    entityJurisdiction: [],
    jurisdictionDocuments: [],
    totalDocuments: 0,
    documentPage: 1,
    analyticsError: null,
    selectedCountry: null,
    countryEntities: null,
    countryEntityModalOpen: false,
    selectedMyCompanyEntityId: null,
    availableAnalyticsEntities: [],
    fetchingMyCompanies: false,
    documentRiskTolerance: null,
    documentRiskToleranceView: DocumentRiskView.DOCUMENTS,
    entityDocumentRiskToleranceView: EntityDocumentRiskView.ENTITY_DOCUMENTS,
    preExecutionDocumentRiskToleranceView: PreExecutionDocumentRiskView.PRE_EXECUTION_DOCUMENTS,
    documentRiskToleranceLoading: false,
    allDocumentsRiskTolerance: { overallPercentage: 0, totalDocuments: 0 },
    entityDocumentsRiskTolerance: { overallPercentage: 0, totalDocuments: 0 },
    preExecutionDocumentsRiskTolerance: { overallPercentage: 0, totalDocuments: 0 },
    paginatedRiskToleranceDocuments: [],
    totalPaginatedRiskToleranceDocuments: 0,
    riskToleranceDocumentsPage: 1,
    riskToleranceDocumentsPageSize: DEFAULT_PAGE_SIZE,
    fetchingRiskToleranceDocuments: false,
    riskToleranceDocumentsFilters: {},
    riskToleranceDocumentsSort: undefined,
    allDatasetsRiskTolerance: [],
    selectedRiskToleranceParentDatasetId: null,
    selectedRiskToleranceChildDatasetId: null,
    selectedRiskToleranceDatasetFieldId: null,
    paginatedDatasetFieldRiskTolerance: [],
    paginatedDatasetFieldRiskToleranceTotal: 0,
    paginatedDatasetFieldRiskToleranceOverallPercentage: 0,
    documentRiskFieldTolerance: null,
    riskToleranceDatasetFieldPage: 1,
    riskToleranceDatasetFieldPageSize: DEFAULT_PAGE_SIZE,
    riskToleranceDatasetFieldFilters: {},
    riskToleranceDatasetFieldSort: undefined,
};

const addAnalyticsToFetchingArray = (state: DocumentAnalyticsState, chart: DocumentAnalyticsChart) => [...state.fetchingAnalytics, chart];
const removeAnalyticsFromFetchingArray = (state: DocumentAnalyticsState, chart: DocumentAnalyticsChart) => state.fetchingAnalytics.filter(analytics => analytics !== chart);

export const documentAnalyticsReducer: Reducer<DocumentAnalyticsState> = (state = INITIAL_STATE, { payload, type }): DocumentAnalyticsState => {
    switch (type) {
        case DocumentAnalyticsActionTypes.SET_DOCUMENT_ANALYTICS_TILE_IN_VIEW:
            return set('tileInView', payload.tileInView, state);
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_JURISDICTION_ANALYTICS_STARTED:
        case DocumentAnalyticsActionTypes.FETCH_DOCUMENT_SPREAD_ANALYTICS_STARTED:
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_SPREAD_ANALYTICS_STARTED:
            return set('fetchingAnalytics', addAnalyticsToFetchingArray(state, payload.chart), state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_MY_COMPANY_ENTITIES_STARTED:
            return set('fetchingMyCompanies', true, state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_MY_COMPANY_ENTITIES_SUCCESSFUL:
            return flow(
                set('availableAnalyticsEntities', payload),
                set('fetchingMyCompanies', false)
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_DOCUMENT_SPREAD_ANALYTICS_SUCCESSFUL:
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_SPREAD_ANALYTICS_SUCCESSFUL:
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_JURISDICTION_ANALYTICS_SUCCESSFUL: {
            const { key, chart, analytics } = payload;
            return flow(
                set(`${key}`, analytics),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case DocumentAnalyticsActionTypes.FETCH_DOCUMENT_SPREAD_ANALYTICS_FAILED:
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_SPREAD_ANALYTICS_FAILED:
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_JURISDICTION_ANALYTICS_FAILED: {
            const { error, chart } = payload;
            return flow(
                set('analyticsError', error),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case DocumentAnalyticsActionTypes.FETCH_ALL_MY_COMPANY_ENTITIES_FAILED:
            return flow(
                set('analyticsError', payload),
                set('fetchingMyCompanies', false)
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_JURISDICTION_DOCUMENTS_STARTED:
            return set('fetchingAnalytics', addAnalyticsToFetchingArray(state, DocumentAnalyticsChart.JURISDICTION_DOCUMENTS), state);
        case DocumentAnalyticsActionTypes.FETCH_JURISDICTION_DOCUMENTS_SUCCESSFUL:
            return flow(
                set('jurisdictionDocuments', payload.documents),
                set('totalDocuments', payload.total),
                set('documentPage', payload.pageNumber),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, DocumentAnalyticsChart.JURISDICTION_DOCUMENTS))
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_JURISDICTION_DOCUMENTS_FAILED:
            return flow(
                set('analyticsError', payload),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, DocumentAnalyticsChart.JURISDICTION_DOCUMENTS))
            )(state);
        case DocumentAnalyticsActionTypes.SET_SELECTED_COUNTRY:
            return set('selectedCountry', payload, state);
        case DocumentAnalyticsActionTypes.SET_SELECTED_MY_COMPANY_ENTITY:
            return set('selectedMyCompanyEntityId', payload, state);
        case DocumentAnalyticsActionTypes.SET_COUNTRY_ENTITIES:
            return flow(
                set('countryEntities', payload),
                set('countryEntityModalOpen', true)
            )(state);
        case DocumentAnalyticsActionTypes.CLEAR_COUNTRY_ENTITIES: {
            return flow(
                set('countryEntities', null),
                set('countryEntityModalOpen', false),
                set('jurisdictionDocuments', []),
                set('totalDocuments', 0),
                set('documentPage', 1)
            )(state);
        }
        case DocumentAnalyticsActionTypes.FETCH_DOCUMENT_RISK_TOLERANCE_STARTED:
            return set('documentRiskToleranceLoading', true, state);
        case DocumentAnalyticsActionTypes.FETCH_DOCUMENT_RISK_TOLERANCE_FAILED:
            return set('documentRiskToleranceLoading', false, state);
        case DocumentAnalyticsActionTypes.FETCH_DOCUMENT_RISK_TOLERANCE_SUCCESSFUL:
            return flow(
                set('documentRiskTolerance', payload),
                set('documentRiskToleranceLoading', false)
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_STARTED:
            return set('fetchingAnalytics', addAnalyticsToFetchingArray(state, DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE), state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL:
            return flow(
                set('allDocumentsRiskTolerance', payload),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE))
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_FAILED:
            return flow(
                set('analyticsError', payload),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE))
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_STARTED:
            return set('fetchingAnalytics', addAnalyticsToFetchingArray(state, DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE), state);
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL:
            return flow(
                set('entityDocumentsRiskTolerance', payload),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE))
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_FAILED:
            return flow(
                set('analyticsError', payload),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE))
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_STARTED:
            return set('fetchingAnalytics', addAnalyticsToFetchingArray(state, DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE), state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL:
            return flow(
                set('preExecutionDocumentsRiskTolerance', payload),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE))
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_FAILED:
            return flow(
                set('analyticsError', payload),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE))
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_STARTED:
            return set('fetchingRiskToleranceDocuments', true, state);
        case DocumentAnalyticsActionTypes.FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL: {
            const { documents, total, pageNumber } = payload;
            return flow(
                set('paginatedRiskToleranceDocuments', documents),
                set('totalPaginatedRiskToleranceDocuments', total),
                set('fetchingRiskToleranceDocuments', false),
                set('riskToleranceDocumentsPage', pageNumber)
            )(state);
        }
        case DocumentAnalyticsActionTypes.FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_FAILED:
            return set('fetchingRiskToleranceDocuments', false, state);
        case DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`riskToleranceDocumentsFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`riskToleranceDocumentsFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DocumentAnalyticsActionTypes.CLEAR_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS:
            return set('riskToleranceDocumentsFilters', {}, state);
        case DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_TABLE_COLUMN_SORT:
            return set('riskToleranceDocumentsSort', payload, state);
        case DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_PAGE_SIZE:
            return set('riskToleranceDocumentsPageSize', payload, state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_DATASETS_RISK_TOLERANCE_STARTED:
            return set('fetchingRiskToleranceDocuments', true, state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_DATASETS_RISK_TOLERANCE_SUCCESSFUL:
            return flow(
                set('allDatasetsRiskTolerance', payload),
                set('fetchingRiskToleranceDocuments', false)
            )(state);
        case DocumentAnalyticsActionTypes.FETCH_ALL_DATASETS_RISK_TOLERANCE_FAILED:
            return set('fetchingRiskToleranceDocuments', false, state);
        case DocumentAnalyticsActionTypes.SET_RISK_TOLERANCE_VIEW: {
            if ([DocumentRiskView.DOCUMENTS, DocumentRiskView.DATASETS].includes(payload)) {
                return flow(
                    set('documentRiskToleranceView', payload),
                    set('documentRiskTolerance', null),
                    set('riskToleranceDocumentsFilters', {}),
                    set('riskToleranceDocumentsSort', undefined),
                    set('riskToleranceDatasetFieldFilters', {}),
                    set('riskToleranceDatasetFieldSort', undefined),
                    set('documentRiskFieldTolerance', null)
                )(state);
            }
            return set('documentRiskToleranceView', payload, state);
        }
        case DocumentAnalyticsActionTypes.SET_ENTITY_DOCUMENT_RISK_TOLERANCE_VIEW: {
            if ([EntityDocumentRiskView.ENTITY_DOCUMENTS, EntityDocumentRiskView.ENTITY_DATASETS].includes(payload)) {
                return flow(
                    set('entityDocumentRiskToleranceView', payload),
                    set('documentRiskTolerance', null),
                    set('riskToleranceDocumentsFilters', {}),
                    set('riskToleranceDocumentsSort', undefined),
                    set('riskToleranceDatasetFieldFilters', {}),
                    set('riskToleranceDatasetFieldSort', undefined),
                    set('documentRiskFieldTolerance', null)
                )(state);
            }
            return set('entityDocumentRiskToleranceView', payload, state);
        }
        case DocumentAnalyticsActionTypes.SET_PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE_VIEW: {
            if ([PreExecutionDocumentRiskView.PRE_EXECUTION_DOCUMENTS, PreExecutionDocumentRiskView.PRE_EXECUTION_DATASETS].includes(payload)) {
                return flow(
                    set('preExecutionDocumentRiskToleranceView', payload),
                    set('documentRiskTolerance', null),
                    set('riskToleranceDocumentsFilters', {}),
                    set('riskToleranceDocumentsSort', undefined),
                    set('riskToleranceDatasetFieldFilters', {}),
                    set('riskToleranceDatasetFieldSort', undefined),
                    set('documentRiskFieldTolerance', null)
                )(state);
            }
            return set('preExecutionDocumentRiskToleranceView', payload, state);
        }
        case DocumentAnalyticsActionTypes.SET_RISK_TOLERANCE_DATASET_ID: {
            return flow(
                set('selectedRiskToleranceParentDatasetId', payload),
                set('selectedRiskToleranceChildDatasetId', null),
                set('selectedRiskToleranceDatasetFieldId', null)
            )(state);
        }
        case DocumentAnalyticsActionTypes.SET_SELECTED_DATASET_RISK_FIELD: {
            const { childDatasetId, fieldId } = payload;
            return flow(
                set('selectedRiskToleranceChildDatasetId', childDatasetId),
                set('selectedRiskToleranceDatasetFieldId', fieldId)
            )(state);
        }
        case DocumentAnalyticsActionTypes.FETCH_DATASET_FIELD_RISK_TOLERANCE_STARTED:
            return set('fetchingRiskToleranceDocuments', true, state);
        case DocumentAnalyticsActionTypes.FETCH_DATASET_FIELD_RISK_TOLERANCE_SUCCESSFUL: {
            const { documents, total, overallPercentage, pageNumber } = payload;
            return flow(
                set('paginatedDatasetFieldRiskTolerance', documents),
                set('paginatedDatasetFieldRiskToleranceTotal', total),
                set('paginatedDatasetFieldRiskToleranceOverallPercentage', overallPercentage),
                set('fetchingRiskToleranceDocuments', false),
                set('riskToleranceDatasetFieldPage', pageNumber)
            )(state);
        }
        case DocumentAnalyticsActionTypes.FETCH_DATASET_FIELD_RISK_TOLERANCE_FAILED:
            return set('fetchingRiskToleranceDocuments', false, state);
        case DocumentAnalyticsActionTypes.GET_SELECTED_RISK_FIELD_DOCUMENT_META: {
            const document = state.paginatedDatasetFieldRiskTolerance.find(({ originalDocumentId }) => originalDocumentId === payload)!;
            return set('documentRiskFieldTolerance', document, state);
        }
        case DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`riskToleranceDatasetFieldFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`riskToleranceDatasetFieldFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DocumentAnalyticsActionTypes.CLEAR_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS:
            return set('riskToleranceDatasetFieldFilters', {}, state);
        case DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_COLUMN_SORT:
            return set('riskToleranceDatasetFieldSort', payload, state);
        case DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_PAGE_SIZE:
            return set('riskToleranceDatasetFieldPageSize', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
