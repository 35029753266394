import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from '../Analytics.module.scss';
import { DocumentAnalyticsChart, CountryEntity, CountryEntityInformation, fetchJurisdictionDocumentsStarted, getDocumentPage, getDocumentsTotal, getFetchingAnalytics, getJurisdictionDocuments, selectJurisdictionDocumentsPage } from '../store';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Eye, OfficeBuilding } from '../../../shared/icons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { Spinner } from '../../../shared/spinner/Spinner';
import { Pagination } from '../../../shared/pagination/Pagination';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Icon } from '../../../shared/icon/Icon';

interface JurisdictionEntityModalProps {
    closeModal: () => void;
    isOpen: boolean;
    selectedCountryEntities: CountryEntityInformation;
}

export const JurisdictionEntityModal: React.FC<JurisdictionEntityModalProps> = ({ closeModal, isOpen, selectedCountryEntities }) => {
    const [selectedEntityId, setSelectedEntityId] = useState<number | null>(null);
    const dispatch = useAppDispatch();

    const documents = useAppSelector(getJurisdictionDocuments);
    const total = useAppSelector(getDocumentsTotal);
    const pageNumber = useAppSelector(getDocumentPage);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);

    const showSpinner = useMemo(() => fetchingAnalytics.includes(DocumentAnalyticsChart.JURISDICTION_DOCUMENTS), [fetchingAnalytics]);
    const countryEntities: CountryEntity[] = selectedCountryEntities?.entities || [];

    const selectEntity = useCallback((entityId: number) => {
        setSelectedEntityId(entityId);
        dispatch(fetchJurisdictionDocumentsStarted(entityId));
    }, [dispatch, setSelectedEntityId]);

    const totalPages = Math.ceil(total / 20);

    const openPage = useCallback((page: number) => { dispatch(selectJurisdictionDocumentsPage(selectedEntityId!, page)); }, [dispatch, selectedEntityId]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeLabel='Close'
            closeModal={closeModal}
            showConfirm={false}
            testId='jurisdiction-country-modal'
        >
            <div className={styles.jurisdictionEntityModalWrapper}>
                <ModalHeader label={selectedCountryEntities.name} icon={OfficeBuilding} testId='document-name' />
                <div className={styles.jurisdictionEntityModalInformationWrapper}>
                    <div className={styles.jurisdictionEntityModalLeft}>
                        <Scrollable>
                            {countryEntities.map(({ entityId, name, onWatchlist }) => (
                                <button
                                    key={entityId}
                                    onClick={() => selectEntity(entityId)}
                                    className={classnames(styles.jurisdictionEntityModalEntity, { [styles.selectedJurisdictionEntity]: entityId === selectedEntityId })}
                                >
                                    <div className={styles.entityNameWrapper}>
                                        <div className={styles.entityName}>{name}</div>
                                        {!!onWatchlist && <Icon icon={Eye} fontSize={20} />}
                                    </div>
                                </button>
                            ))}
                        </Scrollable>
                    </div>
                    <div className={styles.jurisdictionEntityModalRight}>
                        <div className={styles.documentsWrapper}>
                            <Scrollable>
                                {showSpinner ? (<Spinner />) : (
                                    documents.map(({ documentId, documentDescription, documentName, entityAName, entityBName }, index) => (
                                        <Link key={index} to={`/documents/analysis/${documentId}`} style={{ textDecoration: 'none' }}>
                                            <button key={index} className={styles.jurisdictionEntityModalEntityDocument} onClick={closeModal}>
                                                <div className={styles.documentDescription}>{documentDescription}</div>
                                                <div className={styles.documentName}>{documentName}</div>
                                                <div className={styles.entitiesWrapper}>
                                                    <div className={styles.entityAName}>{entityAName}</div>
                                                    <div className={styles.entityBName}>{entityBName}</div>
                                                </div>
                                            </button>
                                        </Link>
                                    )))}
                            </Scrollable>
                        </div>
                        <Pagination totalPages={totalPages} selectedPage={pageNumber} openPage={openPage} />
                    </div>
                </div>
            </div>
        </ConfirmationModal>
    );
};
