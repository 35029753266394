import { action } from 'typesafe-actions';
import { RawDraftContentState } from 'react-draft-wysiwyg';

import { ChangedContent, PlaybookActionTypes } from './types';
import { BasicPlaybook, PlaybookById, PlaybookByDefinitionId, PlaybookView, PlaybookQueryDB, RelevantStakeholders, SuggestedChangesDB, PlaybookProvision, ReadOnlyContentType, PlaybookSmartSearch, LinkedPlaybookProvision } from '../../admin/playbook/store';
import { User } from '../../auth/login/store';

export const getAllPlaybookStarted = () => action(PlaybookActionTypes.GET_ALL_PLAYBOOKS_STARTED);
export const getAllPlaybookSuccessful = (allPlaybooks: BasicPlaybook[]) => action(PlaybookActionTypes.GET_ALL_PLAYBOOKS_SUCCESSFUL, allPlaybooks);
export const getAllPlaybookFailed = (error: string) => action(PlaybookActionTypes.GET_ALL_PLAYBOOKS_FAILED, error);

export const togglePlaybookCoverSelected = (playbook: BasicPlaybook | null) => action(PlaybookActionTypes.TOGGLE_PLAYBOOK_COVER_SELECTED, playbook);

export const getUserPlaybookByIdStarted = (playbookId: number) => action(PlaybookActionTypes.GET_USER_PLAYBOOK_BY_ID_STARTED, { playbookId });
export const getUserPlaybookByIdSuccessful = (playbook: PlaybookById) => action(PlaybookActionTypes.GET_USER_PLAYBOOK_BY_ID_SUCCESSFUL, { playbook });
export const getUserPlaybookByIdFailed = (error: string) => action(PlaybookActionTypes.GET_USER_PLAYBOOK_BY_ID_FAILED, error);

export const getUserPlaybookByDefinitionIdStarted = (playbookDefinitionId: number) => action(PlaybookActionTypes.GET_USER_PLAYBOOK_BY_DEFINITION_ID_STARTED, { playbookDefinitionId });
export const getUserPlaybookByDefinitionIdSuccessful = (playbook: PlaybookByDefinitionId) => action(PlaybookActionTypes.GET_USER_PLAYBOOK_BY_DEFINITION_ID_SUCCESSFUL, { playbook });
export const getUserPlaybookByDefinitionIdFailed = (error: string) => action(PlaybookActionTypes.GET_USER_PLAYBOOK_BY_DEFINITION_ID_FAILED, error);

export const redirectUserPlaybook = () => action(PlaybookActionTypes.REDIRECT_USER_PLAYBOOK);

export const changeUserPlaybookCurrentView = (view: PlaybookView) => action(PlaybookActionTypes.CHANGE_USER_PLAYBOOK_CURRENT_VIEW, view);

export const toggleQueryModalOpen = (sectionId: string | null, user?: User | null) => action(PlaybookActionTypes.TOGGLE_PLAYBOOK_QUERY_MODAL_OPEN, { sectionId, user });

export const toggleSuggestedChangesModalOpen = (sectionId: string | null) => action(PlaybookActionTypes.TOGGLE_PLAYBOOK_SUGGESTED_CHANGES_MODAL_OPEN, sectionId);

export const updateUserQuery = (content: RawDraftContentState, index: number) => action(PlaybookActionTypes.UPDATE_USER_PLAYBOOK_QUERY, { content, index });

export const sendQueryStarted = () => action(PlaybookActionTypes.SEND_QUERY_STARTED);
export const sendQuerySuccessful = (allQueries: PlaybookQueryDB[]) => action(PlaybookActionTypes.SEND_QUERY_SUCCESSFUL, allQueries);
export const sendQueryFailed = (error: string) => action(PlaybookActionTypes.SEND_QUERY_FAILED, error);

export const toggleQueryReady = () => action(PlaybookActionTypes.TOGGLE_QUERY_READY);

export const setSelectedQuery = (playbookQueryId: number | null) => action(PlaybookActionTypes.SET_SELECTED_QUERY, playbookQueryId);

export const sendQueryReminderStarted = () => action(PlaybookActionTypes.SEND_QUERY_REMINDER_STARTED);
export const sendQueryReminderSuccessful = (allQueries: PlaybookQueryDB[]) => action(PlaybookActionTypes.SEND_QUERY_REMINDER_SUCCESSFUL, allQueries);
export const sendQueryReminderFailed = (error: string) => action(PlaybookActionTypes.SEND_QUERY_REMINDER_FAILED, error);

export const updateSuggestedChanges = (value: RawDraftContentState | string | string[] | null | RelevantStakeholders[], key?: keyof PlaybookProvision) => action(PlaybookActionTypes.UPDATE_PLAYBOOK_SUGGESTED_CHANGES, { value, key });
export const updateSuggestedChangedContent = (changedContent: ChangedContent) => action(PlaybookActionTypes.UPDATE_PLAYBOOK_SUGGESTED_CHANGED_CONTENT, changedContent);

export const addSuggestedChangesDeviationRow = (index?: number) => action(PlaybookActionTypes.ADD_SUGGESTED_CHANGES_DEVIATION_ROW, index);
export const deleteSuggestedChangesDeviationRow = (rowId: string) => action(PlaybookActionTypes.REMOVE_SUGGESTED_CHANGES_DEVIATION_ROW, rowId);
export const updateSuggestedChangesSectionDeviation = (rowId: string, key: string, value: string | RawDraftContentState | null | number) => action(PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_DEVIATION_VALUE, { rowId, key, value });

export const setSelectedSuggestedChange = (playbookSuggestionId: number | null) => action(PlaybookActionTypes.SET_SELECTED_SUGGESTED_CHANGE, playbookSuggestionId);

export const updateAdditionalUserQuery = (content: RawDraftContentState | null, user: User) => action(PlaybookActionTypes.UPDATE_ADDITIONAL_USER_QUERY, { content, user });

export const sendAdditionalQueryStarted = () => action(PlaybookActionTypes.SEND_ADDITIONAL_USER_QUERY_STARTED);
export const sendAdditionalQuerySuccessful = (allQueries: PlaybookQueryDB[]) => action(PlaybookActionTypes.SEND_ADDITIONAL_USER_QUERY_SUCCESSFUL, allQueries);
export const sendAdditionalQueryFailed = (error: string) => action(PlaybookActionTypes.SEND_ADDITIONAL_USER_QUERY_FAILED, error);

export const setSuggestedChangesUpdated = (value: boolean) => action(PlaybookActionTypes.SET_SUGGESTED_CHANGES_UPDATED, value);

export const setShowConfirmationModal = () => action(PlaybookActionTypes.SET_SHOW_CONFIRMATION_MODAL);

export const sendSuggestedChangesStarted = () => action(PlaybookActionTypes.SEND_SUGGESTED_CHANGES_STARTED);
export const sendSuggestedChangesSuccessful = (playbookSuggestedChanges: SuggestedChangesDB[]) => action(PlaybookActionTypes.SEND_SUGGESTED_CHANGES_SUCCESSFUL, playbookSuggestedChanges);
export const sendSuggestedChangesFailed = (error: string) => action(PlaybookActionTypes.SEND_SUGGESTED_CHANGES_FAILED, error);

export const toggleUserDeviation = (sectionId: string) => action(PlaybookActionTypes.TOGGLE_USER_SECTION_DEVIATION, sectionId);

export const closeAllUserDeviations = () => action(PlaybookActionTypes.CLOSE_ALL_USER_DEVIATIONS);

export const updateSuggestedChangesNewMessage = (content: RawDraftContentState | null, user: User) => action(PlaybookActionTypes.UPDATE_USER_SUGGESTED_CHANGES_NEW_MESSAGE, { content, user });

export const updateReadOnlySuggestedChangesContent = (contentType: ReadOnlyContentType) => action(PlaybookActionTypes.UPDATE_READ_ONLY_SUGGESTED_CHANGES_CONTENT, contentType);

export const updateSuggestedChangesConversationStarted = () => action(PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED);
export const updateSuggestedChangesConversationSuccessful = (suggestedChanges: SuggestedChangesDB, allSuggestedChanges: SuggestedChangesDB[]) => action(PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_SUCCESSFUL, { suggestedChanges, allSuggestedChanges });
export const updateSuggestedChangesConversationFailed = (error: string) => action(PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_FAILED, error);

export const updatePlaybookSimpleSearch = (searchTerm: string) => action(PlaybookActionTypes.UPDATE_PLAYBOOK_SIMPLE_SEARCH, searchTerm);
export const resetPlaybookSearch = () => action(PlaybookActionTypes.RESET_PLAYBOOK_SEARCH);

export const updatePlaybookSmartSearch = (searchTerm: PlaybookSmartSearch[]) => action(PlaybookActionTypes.UPDATE_PLAYBOOK_SMART_SEARCH, searchTerm);
export const playbookSmartSearchStarted = () => action(PlaybookActionTypes.PLAYBOOK_SMART_SEARCH_STARTED);
export const playbookSmartSearchSuccessful = (playbooks: BasicPlaybook[]) => action(PlaybookActionTypes.PLAYBOOK_SMART_SEARCH_SUCCESSFUL, playbooks);
export const playbookSmartSearchFailed = (error: string) => action(PlaybookActionTypes.PLAYBOOK_SMART_SEARCH_FAILED, error);
export const resetPlaybookSmartSearch = () => action(PlaybookActionTypes.RESET_PLAYBOOK_SMART_SEARCH);

export const toggleProvisionLinkModal = (sectionId: string | null) => action(PlaybookActionTypes.TOGGLE_USER_LINKED_PROVISION_MODAL, sectionId);
export const openProvisionLink = (linkFrom: LinkedPlaybookProvision, linkTo: LinkedPlaybookProvision) => action(PlaybookActionTypes.OPEN_USER_PROVISION_LINK, { linkFrom, linkTo });
export const updateLinkedProvisionHistory = (linkHistory: LinkedPlaybookProvision[]) => action(PlaybookActionTypes.UPDATE_USER_LINKED_PROVISION_HISTORY, linkHistory);

export const userPlaybookBackCalled = (index?: number | null) => action(PlaybookActionTypes.USER_PLAYBOOK_BACK_CALLED, index);

export const toggleProvisionLinkHistoryModal = (value: boolean) => action(PlaybookActionTypes.TOGGLE_USER_PROVISION_LINK_HISTORY_MODAL, value);
