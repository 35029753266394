import { set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../auth/login/store';
import { NavigationActionTypes, NavigationState } from './types';

export const INITIAL_STATE: NavigationState = {
    leftMenuOpen: false,
    rightMenuOpen: false
};

export const navigationReducer: Reducer<NavigationState> = (state = INITIAL_STATE, { payload, type }): NavigationState => {
    switch (type) {
        case NavigationActionTypes.TOGGLE_LEFT_MENU:
            return set('leftMenuOpen', payload, state);
        case NavigationActionTypes.TOGGLE_RIGHT_MENU:
            return set('rightMenuOpen', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
