import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { DatasetInstance } from '../instances/DatasetInstance';
import { getCurrentInstances, getDatasetInstanceHierarchy, getParentExecutedDate } from '../instances/store';
import styles from './Fields.module.scss';
import { formatDate } from '../../../utils/luxon';

interface DatasetFieldProps {
    id: string;
    childDatasetId: string;
    isEditing: boolean;
    isUpdating: boolean;
    fieldOpen: boolean;
    modalInstance?: boolean;
    fieldHidden?: boolean;
    isMLCorrection?: boolean;
}

export const DatasetField: React.FC<DatasetFieldProps> = ({
    id,
    childDatasetId,
    isEditing,
    isUpdating,
    fieldOpen,
    modalInstance,
    fieldHidden,
    isMLCorrection
}) => {
    const datasetInstances = useAppSelector(getCurrentInstances);
    const hierarchy = useAppSelector(getDatasetInstanceHierarchy);
    const parentExecutedDate = useAppSelector(getParentExecutedDate);
    const datasetInstance = useMemo(() => datasetInstances.find(({ instance, parentFieldId }) => (instance.datasetId === parseInt(childDatasetId) || (!isNull(instance.annexDefinitionId) && instance.annexDefinitionId === parseInt(childDatasetId))) && parentFieldId === id)?.instance, [datasetInstances, childDatasetId, id]);

    const getParentId = useCallback((id: string) => hierarchy.find(({ datasetId }) => datasetId === id)?.parentId, [hierarchy]);

    const parentDatasetIds = useMemo(() => {
        let parentIds: string[] = [];
        let currentDatasetId = childDatasetId;
        let parentId = getParentId(currentDatasetId);
        while (parentId) {
            parentIds.push(parentId);
            currentDatasetId = parentId;
            parentId = getParentId(currentDatasetId);
        }
        return parentIds;
    }, [getParentId, childDatasetId]);

    const instanceExecutedDateMatchesParent = useMemo(() => datasetInstance && parentExecutedDate && formatDate(datasetInstance.executedDate) === parentExecutedDate || false, [datasetInstance, parentExecutedDate]);

    return (
        <div className={styles.datasetField} data-testid={`dataset-instance-wrapper-${childDatasetId}`}>
            {fieldOpen && datasetInstance && <DatasetInstance datasetInstance={datasetInstance} isEditing={isEditing} isUpdating={isUpdating} parentDatasetIds={parentDatasetIds} modalInstance={modalInstance} parentFieldId={id} datasetHidden={fieldHidden} instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent} isMLCorrection={isMLCorrection} />}
        </div>
    );
};
