import React from 'react';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import styles from '../../MyDatasets.module.scss';
import { toggleModalDatasetId } from '../../store';

interface DatasetLinkProps {
    id: string;
    childDatasetId: string;
    columnHidden: boolean
}

export const DatasetLink: React.FC<DatasetLinkProps> = ({
    id,
    childDatasetId,
    columnHidden
}) => {
    const dispatch = useAppDispatch();
    const linkDataset = () => dispatch(toggleModalDatasetId(parseInt(childDatasetId)));

    return (
        <div className={styles.datasetLinkField} data-testid={`definition-dataset-link-${id}`}>
            <button
                onClick={linkDataset}
                className={styles.datasetLinkButton}
                disabled={columnHidden}
            >
                Open
            </button>
        </div>
    );
};
