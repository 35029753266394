import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { flow, isNull, isUndefined, max, noop, unset } from 'lodash/fp';
import { RawDraftContentState } from 'draft-js';

import styles from './Playbook.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { changeUserPlaybookCurrentView, getAdditionalQuery, getPlaybookQuery, getQueryModalOpen, getQueryReady, getSendingQuery, getUserCurrentView, getUserPlaybook, getUserPlaybookByDefinitionIdStarted, getUserPlaybookByIdStarted, getUserPlaybookHistory, getUserPlaybookQueries, sendQueryReminderStarted, sendQueryStarted, setSelectedQuery, toggleQueryModalOpen, toggleQueryReady, toggleSuggestedChangesModalOpen, updateUserQuery, updateAdditionalUserQuery, sendAdditionalQueryStarted, getOpenUserDeviations, toggleUserDeviation, getUserPlaybookSuggestedChanges, setSelectedSuggestedChange, getShowUserPlaybookLoadingSpinner, getProvisionLinksModalOpen, getLinkedProvisionHistory, toggleProvisionLinkModal, openProvisionLink, userPlaybookBackCalled, toggleProvisionLinkHistoryModal, getLinkedProvisionHistoryModalOpen } from './store';
import { Spinner } from '../shared/spinner/Spinner';
import { PlaybookTimeline } from '../shared/playbook/playbook-history/PlaybookHistory';
import { Playbook } from '../shared/playbook/view/Playbook';
import { Button } from '../shared/button/Button';
import { Position } from '../shared/modal/PositionModal';
import { ActionModal } from '../shared/modal/ActionModal';
import { Form, Pencil } from '../shared/icons';
import { LinkedPlaybookProvision, Playbook as PlaybookType, PlaybookDB, PlaybookProvision, PlaybookView, isSubheaderSection } from '../admin/playbook/store';
import { QueryModal } from './QueryModal';
import { getUser, getUserHasFeaturePermission } from '../auth/login/store';
import { endOfDay, diffToNowInMeasurement } from '../../utils/luxon/index';
import { SuggestedChangesModal } from './SuggestedChangesModal';
import { SuggestedChangesConfirmationModal } from './SuggestedChangesConfirmationModal';
import { ReadOnlySuggestedChangesModal } from './ReadOnlySuggestedChangesModal';
import { ABSTRACT_ID } from '../constants/playbooks';
import { useFetchStarted } from '../../hooks/useFetchStarted';
import { FeaturePermission, fetchAllBasicUsersStarted } from '../admin/users/store';
import { isEmpty } from '../shared/wysiwyg/WYSIWYG';
import { useLongPress } from '../../hooks/useLongPress';
import { LongPressButton } from '../shared/button/LongPressButton';
import { LinkedProvisionHistoryModal } from '../shared/playbook/provision-link/LinkedProvisionHistoryModal';

export interface UserPlaybookViewerRouteParams {
    playbookId: string | undefined;
}

export const UserPlaybookViewer: React.FC<RouteComponentProps<UserPlaybookViewerRouteParams>> = ({ match: { params } }) => {
    const [selectedSectionId, setSelectedSectionId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState<Position | null>(null);

    const testId = 'user-playbook';
    const playbook = useAppSelector(getUserPlaybook);
    const playbookHistory = useAppSelector(getUserPlaybookHistory);
    const currentView = useAppSelector(getUserCurrentView);
    const queryModalOpen = useAppSelector(getQueryModalOpen);
    const query = useAppSelector(getPlaybookQuery);
    const queryReady = useAppSelector(getQueryReady);
    const sendingQuery = useAppSelector(getSendingQuery);
    const queries = useAppSelector(getUserPlaybookQueries);
    const additionalQuery = useAppSelector(getAdditionalQuery);
    const openDeviations = useAppSelector(getOpenUserDeviations);
    const showLoadingSpinner = useAppSelector(getShowUserPlaybookLoadingSpinner);
    const latestPlaybookDefinitionId = useMemo(() => max(playbookHistory.map(({ playbookDefinitionId }) => playbookDefinitionId)), [playbookHistory]);
    const isMostRecent = useMemo(() => (playbook && playbook.playbookDefinitionId === latestPlaybookDefinitionId) || false, [playbook, latestPlaybookDefinitionId]);
    const suggestedChanges = useAppSelector(getUserPlaybookSuggestedChanges);
    const readOnlyProvisionLinksModalOpen = useAppSelector(getProvisionLinksModalOpen);
    const linkedProvisionHistory = useAppSelector(getLinkedProvisionHistory);
    const linkedProvisionHistoryModalOpen = useAppSelector(getLinkedProvisionHistoryModalOpen);
    const hasPlaybookQueryPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.QUERY_PLAYBOOKS]));
    const hasPlaybookSuggestChangesPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.SUGGEST_CHANGES_PLAYBOOKS]));

    const canOpenActionModal = useMemo(() => hasPlaybookQueryPermission || hasPlaybookSuggestChangesPermission, [hasPlaybookQueryPermission, hasPlaybookSuggestChangesPermission]);

    useFetchStarted([fetchAllBasicUsersStarted()]);

    const dispatch = useAppDispatch();
    const playbookDefinitionId = useMemo(() => playbook?.playbookDefinitionId || null, [playbook]);

    const onClick = useCallback(() => dispatch(userPlaybookBackCalled()), [dispatch]);
    const onLongPress = useCallback(() => {
        if (linkedProvisionHistory.length > 0) {
            dispatch(toggleProvisionLinkHistoryModal(true));
        }
    }, [dispatch, linkedProvisionHistory]);

    const longPressEvent = useLongPress(onLongPress, onClick);

    const closeLinkHistoryModal = useCallback(() => dispatch(toggleProvisionLinkHistoryModal(false)), [dispatch]);

    const goToHistoricalLink = useCallback((index: number | null) => dispatch(userPlaybookBackCalled(index)), [dispatch]);

    const backButtonLabel = useMemo(() => linkedProvisionHistory.length > 0 ? 'Back' : 'My Playbooks', [linkedProvisionHistory]);

    const isTableView = currentView === PlaybookView.TABLE;
    const changeViewLabel = useMemo(() => isTableView ? 'List view' : 'Table view', [isTableView]);
    const changeView = useCallback(() => dispatch(changeUserPlaybookCurrentView(isTableView ? PlaybookView.LIST : PlaybookView.TABLE)), [dispatch, isTableView]);

    const selectPlaybookTimeline = (value: number | number[]) => {
        const index = (value as number) - 1;
        const playbookInstance = playbookHistory[index];
        if (playbookInstance && playbookInstance.playbookDefinitionId !== playbook?.playbookDefinitionId) {
            dispatch(getUserPlaybookByDefinitionIdStarted(playbookInstance.playbookDefinitionId));
        }
    };

    const openPlaybookActionModal = useCallback((e: MouseEvent<HTMLDivElement>, sectionId: string) => {
        setPosition({ x: e.clientX, y: e.clientY });
        setSelectedSectionId(sectionId);
    }, [setPosition, setSelectedSectionId]);

    const closePlaybookActionModal = useCallback(() => {
        setPosition(null);
        setSelectedSectionId(undefined);
    }, [setPosition, setSelectedSectionId]);

    const user = useAppSelector(getUser);
    const openQueryModal = useCallback((sectionId: string) => { dispatch(toggleQueryModalOpen(sectionId, user)); }, [dispatch, user]);
    const openSuggestedChangesModal = useCallback((sectionId: string) => dispatch(toggleSuggestedChangesModalOpen(sectionId)), [dispatch]);

    const playbookActions = useMemo(() => {
        let options = [];
        if (hasPlaybookQueryPermission) {
            options.push({ label: 'Raise Query', icon: Form, onClick: () => openQueryModal(selectedSectionId!) });
        }
        if (hasPlaybookSuggestChangesPermission) {
            options.push({ label: 'Suggest Changes', icon: Pencil, onClick: () => openSuggestedChangesModal(selectedSectionId!) });
        }
        return options;
    }, [selectedSectionId, openQueryModal, openSuggestedChangesModal, hasPlaybookQueryPermission, hasPlaybookSuggestChangesPermission]);

    const closeQueryModal = useCallback(() => {
        dispatch(toggleQueryModalOpen(null));
        dispatch(setSelectedQuery(null));
    }, [dispatch]);
    const sendQuery = useCallback(() => dispatch(sendQueryStarted()), [dispatch]);
    const updateQuery = useCallback((rawValue: RawDraftContentState, index: number) => dispatch(updateUserQuery(rawValue, index)), [dispatch]);
    const toggleQueryIsReady = useCallback(() => dispatch(toggleQueryReady()), [dispatch]);
    const openQuery = useCallback((playbookQueryId: number) => dispatch(setSelectedQuery(playbookQueryId)), [dispatch]);
    const provisionTitle = useMemo(() => {
        if (query?.sectionId === ABSTRACT_ID) {
            return 'Abstract';
        }
        const section = playbook?.content.sections.find(({ sectionId }) => sectionId === query?.sectionId);
        if (section) {
            return isSubheaderSection(section) ? section.title : section.title.filter(item => !!item).join(': ');
        }
        return '';
    }, [query, playbook]);

    const modalTitle = useMemo(() => query?.playbookQueryId ? `Provision/Section: ${provisionTitle}` : 'Raise Query', [query, provisionTitle]);
    const lastReminderSent = query?.lastReminderSent || '';
    const midnightDeadline = endOfDay(lastReminderSent);
    const daysSinceLastReminder = diffToNowInMeasurement(midnightDeadline, 'days') || -1;
    const isUsersQuery = query?.raisedBy === user?.userId;
    const queryHasBeenRespondedTo = !!query?.queryConversation.filter(message => !isNull(message)).find(({ userId }) => userId !== query?.raisedBy);
    const showSendReminder = useMemo(() => isUsersQuery && daysSinceLastReminder <= -3 && !queryHasBeenRespondedTo && query?.resolved !== 1, [daysSinceLastReminder, isUsersQuery, query, queryHasBeenRespondedTo]);
    const sendReminder = useCallback(() => dispatch(sendQueryReminderStarted()), [dispatch]);
    const queryVersionIsDifferent = useMemo(() => playbookDefinitionId !== query?.playbookDefinitionId, [playbookDefinitionId, query]);
    const goToDefinition = useCallback((playbookDefinitionId: number, sectionId: string) => {
        dispatch(getUserPlaybookByDefinitionIdStarted(playbookDefinitionId));
        const section = document.getElementById(sectionId);
        section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, [dispatch]);

    const getPlaybookInstanceVersion = useCallback((playbookDefinitionId: number) => {
        const playbookInstance = playbookHistory.find(instance => instance.playbookDefinitionId === playbookDefinitionId);
        if (!isUndefined(playbookInstance)) {
            const { versionMajor, versionMinor } = playbookInstance;
            return `(${versionMajor}.${versionMinor})`;
        }
        return 'Version unknown';
    }, [playbookHistory]);

    const updateAdditionalQuery = useCallback((rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            if (!isNull(additionalQuery?.content)) {
                dispatch(updateAdditionalUserQuery(null, user!));
            }
        } else {
            dispatch(updateAdditionalUserQuery(rawValue, user!));
        }
    }, [dispatch, user, additionalQuery]);

    const sendAdditionalQuery = useCallback(() => dispatch(sendAdditionalQueryStarted()), [dispatch]);

    const togglePlaybookDeviation = useCallback((sectionId: string) => dispatch(toggleUserDeviation(sectionId)), [dispatch]);

    const openSuggestedChange = useCallback((playbookSuggestionId: number | null) => dispatch(setSelectedSuggestedChange(playbookSuggestionId)), [dispatch]);

    const stripPlaybookDb = useCallback((playbook: PlaybookDB): PlaybookType => flow(
        unset('PublishReason'),
        unset('createdBy'),
        unset('createdDate'),
        unset('effectiveTo'),
        unset('clientId')
    )(playbook), []);

    const toggleLinkedProvisionModal = useCallback((sectionId: string) => dispatch(toggleProvisionLinkModal(sectionId)), [dispatch]);
    const openLinkToProvision = useCallback((linkTo: LinkedPlaybookProvision) => {
        const provisionTitle = (playbook!.content.sections.find(section => section.sectionId === readOnlyProvisionLinksModalOpen!) as PlaybookProvision).title.filter(item => !!item).join(': ');
        const linkFrom = { playbookId: playbook!.playbookId!, sectionId: readOnlyProvisionLinksModalOpen!, playbookName: playbook!.name, provisionTitle };
        dispatch(openProvisionLink(linkFrom, linkTo));
    }, [dispatch, readOnlyProvisionLinksModalOpen, playbook]);

    useEffect(() => {
        if (isNull(playbook) && params.playbookId) {
            dispatch(getUserPlaybookByIdStarted(parseInt(params.playbookId!)));
        }
    }, [params, playbook, dispatch]);

    if (!playbook || (playbook && !playbook.playbookId) || showLoadingSpinner) {
        return (
            <Spinner />
        );
    }

    return (
        <div className={styles.playbookViewWrapper} data-testid={`${testId}-viewer-wrapper`}>
            <PlaybookTimeline timeline={playbookHistory} currentPlaybook={playbook} onChange={selectPlaybookTimeline} testId={`${testId}-timeline`} />
            <div className={styles.playbookPreview}>
                <Playbook
                    playbook={stripPlaybookDb(playbook)}
                    currentView={currentView}
                    openPlaybookActionModal={isMostRecent ? openPlaybookActionModal : noop}
                    queries={queries}
                    playbookDefinitionId={playbookDefinitionId}
                    openQuery={openQuery}
                    getPlaybookInstanceVersion={getPlaybookInstanceVersion}
                    openDeviations={openDeviations}
                    toggleDeviation={togglePlaybookDeviation}
                    suggestedChanges={suggestedChanges}
                    openSuggestedChange={openSuggestedChange}
                    playbookHistory={playbookHistory}
                    provisionLinkModalOpen={readOnlyProvisionLinksModalOpen}
                    toggleLinkedProvisionModal={toggleLinkedProvisionModal}
                    openLinkToProvision={openLinkToProvision}
                    testId={testId}
                    canOpenActionModal={canOpenActionModal}
                />
            </div>
            <div className={styles.buttonWrapper}>
                <LongPressButton longPressProps={longPressEvent} label={backButtonLabel} />
                <Button onClick={changeView} label={changeViewLabel} />
            </div>
            <ActionModal
                isOpen={!isNull(position) && !isUndefined(selectedSectionId)}
                position={position}
                closeModal={closePlaybookActionModal}
                actions={playbookActions}
                testId={`${testId}-actions`}
            />
            <SuggestedChangesModal testId={testId} />
            <ReadOnlySuggestedChangesModal testId={testId} />
            <SuggestedChangesConfirmationModal testId={testId} />
            <QueryModal
                isOpen={queryModalOpen}
                query={query}
                queryReady={queryReady}
                showSpinner={sendingQuery}
                closeModal={closeQueryModal}
                sendQuery={sendQuery}
                updateQuery={updateQuery}
                toggleQueryIsReady={toggleQueryIsReady}
                modalTitle={modalTitle}
                showSendReminder={showSendReminder}
                sendReminder={sendReminder}
                queryVersionIsDifferent={queryVersionIsDifferent}
                goToDefinition={goToDefinition}
                additionalQuery={additionalQuery}
                updateAdditionalQuery={updateAdditionalQuery}
                sendAdditionalQuery={sendAdditionalQuery}
                testId={testId}
            />
            <LinkedProvisionHistoryModal
                isOpen={linkedProvisionHistoryModalOpen}
                closeModal={closeLinkHistoryModal}
                provisionLinkHistory={linkedProvisionHistory}
                linkToProvision={goToHistoricalLink}
            />
        </div>
    );
};
