import React from 'react';

export const Reference: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color, fill }) => (
    <svg width={width || '1em'} height={height || '1em'} x='0px' y='0px' viewBox='0 0 128 128'>
        <g>
            <g>
                <path fill={fill || '#FFFFFF'} stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d='M87.6,107.5H29.4c-4.5,0-8.1-3.7-8.1-8.1v-84c0-4.5,3.7-8.1,8.1-8.1h58.2c4.5,0,8.1,3.7,8.1,8.1v84 C95.7,103.8,92.1,107.5,87.6,107.5z' />
                <path fill={fill || '#FFFFFF'} stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d='M98.6,120.7H40.4c-4.5,0-8.1-3.7-8.1-8.1v-84c0-4.5,3.7-8.1,8.1-8.1h58.2c4.5,0,8.1,3.7,8.1,8.1v84 C106.8,117.1,103.1,120.7,98.6,120.7z' />
            </g>
            <path fill={color || 'currentColor'} d='M57.2,36.8h24.6c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8H57.2c-1,0-1.8,0.8-1.8,1.8C55.5,36,56.2,36.8,57.2,36.8z' />
            <path fill={color || 'currentColor'} d='M44.9,90.4h49.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H44.9c-1,0-1.8,0.8-1.8,1.8S43.9,90.4,44.9,90.4z' />
            <path fill={color || 'currentColor'} d='M44.9,79.7h24.6c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H44.9c-1,0-1.8,0.8-1.8,1.8S43.9,79.7,44.9,79.7z' />
            <path fill={color || 'currentColor'} d='M44.9,68.9h49.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H44.9c-1,0-1.8,0.8-1.8,1.8S43.9,68.9,44.9,68.9z' />
            <path fill={color || 'currentColor'} d='M44.9,47.5h49.3c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8H44.9c-1,0-1.8,0.8-1.8,1.8C43.1,46.7,43.9,47.5,44.9,47.5z' />
            <path fill={color || 'currentColor'} d='M44.9,58.2h49.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H44.9c-1,0-1.8,0.8-1.8,1.8S43.9,58.2,44.9,58.2z' />
            <path fill={color || 'currentColor'} d='M57.2,107.9c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H44.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8H57.2z' />
        </g>
        <rect x='39.507' y='28.284' width='60.259' height='22.348' fill={fill || '#FFFFFF'} stroke={fill || '#FFFFFF'} strokeMiterlimit={10} />
        <text style={{ fontFamily: 'Manrope', fontSize: '28px', fontWeight: '600', stroke: `${color || 'currentColor'}`, fill: `${color || 'currentColor'}` }} x='42.001' y='49.135'>REF</text>
    </svg>
);
