import { RawDraftContentState } from 'draft-js';
import { Options } from 'react-select';

import { BaseEntity } from '../../../admin/entity/store';
import { HiddenFields } from '../../../admin/my-datasets/store';
import { MyRiskTolerance, RiskTolerance } from '../../../admin/risk-tolerance/store';
import { DocumentType, LinkedDocument } from '../../../documents/my-documents/store';
import { CalculatorField } from '../../../shared/calculator/constants';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { AgencyDatasetFieldType, DatasetDefinitionDB, DatasetFieldSettings, DatasetFieldType, DatasetSection, DatasetType, SingleDatasetField, SingleDatasetFieldType } from '../../store';
import { InstanceMLData } from '../../store/mlTypes';
import { CurrencyValue } from '../../../shared/number/CurrencyNumber';

export enum DatasetInstanceActionTypes {
    OPEN_DATASET_DEFINITION_STARTED = 'OPEN_DATASET_DEFINITION_STARTED',
    OPEN_DATASET_DEFINITION_SUCCESSFUL = 'OPEN_DATASET_DEFINITION_SUCCESSFUL',
    OPEN_DATASET_DEFINITION_FAILED = 'OPEN_DATASET_DEFINITION_FAILED',
    UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE',
    USER_CORRECT_AI_FIELD_VALUE = 'USER_CORRECT_AI_FIELD_VALUE',
    USER_CORRECT_AI_GROUP_FIELD_VALUE = 'USER_CORRECT_AI_GROUP_FIELD_VALUE',
    UPDATE_FIELD_CUSTOM_CHILD = 'UPDATE_FIELD_CUSTOM_CHILD',
    ADD_FIELD_CUSTOM_CHILD = 'ADD_FIELD_CUSTOM_CHILD',
    SET_FIELDS_UPDATED_VALUE = 'SET_FIELDS_UPDATED_VALUE',
    UPDATE_GROUP_FIELD_VALUE = 'UPDATE_GROUP_FIELD_VALUE',
    UPSERT_DATASET_INSTANCE_STARTED = 'UPSERT_DATASET_INSTANCE_STARTED',
    UPSERT_DATASET_INSTANCE_SUCCESSFUL = 'UPSERT_DATASET_INSTANCE_SUCCESSFUL',
    UPSERT_DATASET_INSTANCE_FAILED = 'UPSERT_DATASET_INSTANCE_FAILED',
    EDIT_DATASET_INSTANCE = 'EDIT_DATASET_INSTANCE',
    UPDATE_DATASET_INSTANCE = 'UPDATE_DATASET_INSTANCE',
    OPEN_DATASET_INSTANCE_BY_ID = 'OPEN_DATASET_INSTANCE_BY_ID',
    INSTANCE_UPDATED = 'INSTANCE_UPDATED',
    TOGGLE_UPDATED_INSTANCE_MODAL = 'TOGGLE_UPDATED_INSTANCE_MODAL',
    RESET_DATASET_INSTANCE = 'RESET_DATASET_INSTANCE',
    ADD_TABLE_DATASET_ROW = 'ADD_TABLE_DATASET_ROW',
    REMOVE_TABLE_DATASET_ROW = 'REMOVE_TABLE_DATASET_ROW',
    TOGGLE_SAVE_INSTANCE_MODAL = 'TOGGLE_SAVE_INSTANCE_MODAL',
    TOGGLE_LINKED_DOCUMENT_MODAL = 'TOGGLE_LINKED_DOCUMENT_MODAL',
    TOGGLE_CLAUSE_MODAL_OPEN = 'TOGGLE_CLAUSE_MODAL_OPEN',
    TOGGLE_GROUP_CLAUSE_MODAL_OPEN = 'TOGGLE_GROUP_CLAUSE_MODAL_OPEN',
    UPDATE_CLAUSE_LABEL = 'UPDATE_CLAUSE_LABEL',
    UPDATE_GROUP_CLAUSE_LABEL = 'UPDATE_GROUP_CLAUSE_LABEL',
    SET_ANNEX_FIELD_IDS = 'SET_ANNEX_FIELD_IDS',
    OPEN_ANNEX_FIELD_WIZARD = 'OPEN_ANNEX_FIELD_WIZARD',
    CLOSE_ANNEX_FIELD_WIZARD = 'CLOSE_ANNEX_FIELD_WIZARD',
    UPDATE_LAST_MODIFIED_FIELDS = 'UPDATE_LAST_MODIFIED_FIELDS',
    OPEN_TIMELINE_INSTANCE_STARTED = 'OPEN_TIMELINE_INSTANCE_STARTED',
    OPEN_TIMELINE_INSTANCE_SUCCESSFUL = 'OPEN_TIMELINE_INSTANCE_SUCCESSFUL',
    OPEN_TIMELINE_INSTANCE_FAILED = 'OPEN_TIMELINE_INSTANCE_FAILED',
    SET_DATASET_INSTANCE_TIMELINE = 'SET_DATASET_INSTANCE_TIMELINE',
    SET_PARENT_DATASET_ID = 'SET_PARENT_DATASET_ID',
    UPDATE_DATASET_HIERARCHY = 'UPDATE_DATASET_HIERARCHY',
    REMOVE_OPEN_FIELD_SECTION = 'REMOVE_OPEN_FIELD_SECTION',
    ADD_OPEN_FIELD_SECTION = 'ADD_OPEN_FIELD_SECTION',
    REMOVE_ALL_FIELD_SECTIONS = 'REMOVE_ALL_FIELD_SECTIONS',
    REMOVE_COLLAPSED_COLUMN = 'REMOVE_COLLAPSED_COLUMN',
    ADD_COLLAPSED_COLUMN = 'ADD_COLLAPSED_COLUMN',
    DUPLICATE_TABLE_DATASET_ROW = 'DUPLICATE_TABLE_DATASET_ROW',
    FETCH_ECS_DATASET_ID_STARTED = 'FETCH_ECS_DATASET_ID_STARTED',
    FETCH_ECS_DATASET_ID_SUCCESSFUL = 'FETCH_ECS_DATASET_ID_SUCCESSFUL',
    FETCH_ECS_DATASET_ID_FAILED = 'FETCH_ECS_DATASET_ID_FAILED',
    GENERATE_NEW_ECS_TABLE_STARTED = 'GENERATE_NEW_ECS_TABLE_STARTED',
    GENERATE_NEW_ECS_TABLE_SUCCESSFUL = 'GENERATE_NEW_ECS_TABLE_SUCCESSFUL',
    GENERATE_NEW_ECS_TABLE_FAILED = 'GENERATE_NEW_ECS_TABLE_FAILED',
    UPDATE_FIELD_LABEL = 'UPDATE_FIELD_LABEL',
    UPDATE_LINKED_ENTITIES = 'UPDATE_LINKED_ENTITIES',
    REMOVE_ECS_TABLE = 'REMOVE_ECS_TABLE',
    DUPLICATE_ECS_TABLE = 'DUPLICATE_ECS_TABLE',
    SET_AGENCY_LINKED_ENTITIES = 'SET_AGENCY_LINKED_ENTITIES',
    SET_HIDDEN_FIELDS = 'SET_HIDDEN_FIELDS',
    TOGGLE_DELETE_SECONDARY_DOCUMENT_MODAL = 'TOGGLE_DELETE_SECONDARY_DOCUMENT_MODAL',
    DELETE_SECONDARY_DOCUMENT_STARTED = 'DELETE_SECONDARY_DOCUMENT_STARTED',
    DELETE_SECONDARY_DOCUMENT_SUCCESSFUL = 'DELETE_SECONDARY_DOCUMENT_SUCCESSFUL',
    DELETE_SECONDARY_DOCUMENT_FAILED = 'DELETE_SECONDARY_DOCUMENT_FAILED',
    SET_TIMELINE_INSTANCE = 'SET_TIMELINE_INSTANCE',
    TOGGLE_DATASET_INSTANCE_SHOW_LEGACY = 'TOGGLE_DATASET_INSTANCE_SHOW_LEGACY',
    TOGGLE_ML_DATA_MODAL_OPEN = 'TOGGLE_ML_DATA_MODAL_OPEN',
    CONFIRM_ML_DATASET_INSTANCE_STARTED = 'CONFIRM_ML_DATASET_INSTANCE_STARTED',
    CONFIRM_ML_DATASET_INSTANCE_SUCCESSFUL = 'CONFIRM_ML_DATASET_INSTANCE_SUCCESSFUL',
    CONFIRM_ML_DATASET_INSTANCE_FAILED = 'CONFIRM_ML_DATASET_INSTANCE_FAILED',
    SET_DATASET_INSTANCE_RISK_TOLERANCE = 'SET_DATASET_INSTANCE_RISK_TOLERANCE',
    SCROLL_TO_DATASET_INSTANCE_SECTION = 'SCROLL_TO_DATASET_INSTANCE_SECTION',
    TOGGLE_ANNEX_DEFINITION_MODAL_OPEN = 'TOGGLE_ANNEX_DEFINITION_MODAL_OPEN',
    SET_IS_UPDATING_INSTANCE_ANNEX_DEFINITIONS = 'SET_IS_UPDATING_INSTANCE_ANNEX_DEFINITIONS',
    SET_DATASET_INSTANCE_SEARCH_FIELD_SECTION = 'SET_DATASET_INSTANCE_SEARCH_FIELD_SECTION',
    SET_DATASET_INSTANCE_FUZZY_MATCH_SEARCH_VALUE = 'SET_DATASET_INSTANCE_FUZZY_MATCH_SEARCH_VALUE',
    SET_DATASET_INSTANCE_SEARCH_FUZZY_MATCHES = 'SET_DATASET_INSTANCE_SEARCH_FUZZY_MATCHES',
    CALCULATE_ALL_INSTANCE_SEARCH_FIELDS = 'CALCULATE_ALL_INSTANCE_SEARCH_FIELDS',
    SET_ALL_DATASET_INSTANCE_SEARCH_FIELD_SECTIONS = 'SET_ALL_DATASET_INSTANCE_SEARCH_FIELD_SECTIONS',
    // Modal Instance
    OPEN_MODAL_DATASET_STARTED = 'OPEN_MODAL_DATASET_STARTED',
    OPEN_MODAL_DATASET_SUCCESSFUL = 'OPEN_MODAL_DATASET_SUCCESSFUL',
    OPEN_MODAL_DATASET_FAILED = 'OPEN_MODAL_DATASET_FAILED',
    CLOSE_MODAL_DATASET = 'CLOSE_MODAL_DATASET',
    UPSERT_MODAL_DATASET_STARTED = 'UPSERT_MODAL_DATASET_STARTED',
    UPSERT_MODAL_DATASET_SUCCESSFUL = 'UPSERT_MODAL_DATASET_SUCCESSFUL',
    UPSERT_MODAL_DATASET_FAILED = 'UPSERT_MODAL_DATASET_FAILED',
    UPDATE_MODAL_FIELD_VALUE = 'UPDATE_MODAL_FIELD_VALUE',
    UPDATE_MODAL_GROUP_FIELD_VALUE = 'UPDATE_MODAL_GROUP_FIELD_VALUE',
    ADD_MODAL_TABLE_DATASET_ROW = 'ADD_MODAL_TABLE_DATASET_ROW',
    REMOVE_MODAL_TABLE_DATASET_ROW = 'REMOVE_MODAL_TABLE_DATASET_ROW',
    TOGGLE_MODAL_CLAUSE_MODAL_OPEN = 'TOGGLE_MODAL_CLAUSE_MODAL_OPEN',
    TOGGLE_MODAL_GROUP_CLAUSE_MODAL_OPEN = 'TOGGLE_MODAL_GROUP_CLAUSE_MODAL_OPEN',
    UPDATE_MODAL_CLAUSE_LABEL = 'UPDATE_MODAL_CLAUSE_LABEL',
    UPDATE_MODAL_GROUP_CLAUSE_LABEL = 'UPDATE_MODAL_GROUP_CLAUSE_LABEL',
    MODAL_INSTANCE_UPDATED = 'MODAL_INSTANCE_UPDATED',
    SET_MODAL_FIELDS_UPDATED_VALUE = 'SET_MODAL_FIELDS_UPDATED_VALUE',
    UPDATE_MODAL_LAST_MODIFIED_FIELDS = 'UPDATE_MODAL_LAST_MODIFIED_FIELDS',
    ADD_MODAL_COLLAPSED_COLUMN = 'ADD_MODAL_COLLAPSED_COLUMN',
    REMOVE_MODAL_COLLAPSED_COLUMN = 'REMOVE_MODAL_COLLAPSED_COLUMN',
    DUPLICATE_MODAL_DATASET_ROW = 'DUPLICATE_MODAL_DATASET_ROW',
    EXPAND_ALL_DATASET_INSTANCE_SECTIONS = 'EXPAND_ALL_DATASET_INSTANCE_SECTIONS'
}

export interface DatasetInstanceSection extends DatasetSection {
    pageRef: string | null;
    isOriginal: boolean;
    isRestatement: boolean;
}

export interface GroupInstanceField {
    id: string;
    type: DatasetFieldType.GROUP | DatasetFieldType.MULTI_TOGGLE;
    children: SingleInstanceField[];
    customChildren?: SingleInstanceField[];
    label?: string;
}

export interface SingleInstanceField extends SingleDatasetField {
    value: FieldValue;
    modifiedBy?: number | null;
    modifiedDate?: string | null;
    isCustom?: boolean;
}

export interface AgencyLinkedInstanceField extends SingleInstanceField {
    datasetId: string;
    datasetTitle: string;
}

export interface AnnexInstanceField {
    id: string;
    label: string;
    description: string;
    type: AgencyDatasetFieldType;
    refLabel: string;
    settings: DatasetFieldSettings;
    linkedFields: AgencyLinkedInstanceField[];
    value: FieldValue;
    aliasLabel?: string;
    modifiedBy?: number | null;
    modifiedDate?: string | null;
    isCustom?: boolean;
    systemId?: string;
}

export type InstanceField = SingleInstanceField | GroupInstanceField;

export interface FormInstanceFields {
    [sectionId: string]: InstanceField[];
}

export type TableInstanceFields = {
    [rowId: string]: SingleInstanceField[];
}

export type AnnexInstanceFields = {
    [rowId: string]: AnnexInstanceField[];
}

interface BaseInstance {
    datasetInstanceId?: number;
    documentId: number;
    originalDocumentId: number;
    entityA: number | null;
    entityB: number | null;
    executedDate: string;
    isDraft: number;
}

interface BaseDatasetInstance extends BaseInstance {
    datasetId: number;
    datasetDefinitionId: number;
    annexDefinitionId: null;
}

export interface AnnexInstance extends BaseInstance {
    datasetId: null;
    datasetDefinitionId: null;
    annexDefinitionId: number;
    datasetType: DatasetType.TABLE;
    datasetFields: AnnexInstanceFields;
    datasetSections?: null;
    datasetRows: string[];
    fieldsUpdated: UpdatedTableField[];
    collapsedColumns: string[] | null;
}

export interface FormDatasetInstance extends BaseDatasetInstance {
    datasetType: DatasetType.FORM;
    datasetFields: FormInstanceFields;
    datasetSections: DatasetSection[] | DatasetInstanceSection[];
    fieldsUpdated: UpdatedFormField[];
    collapsedColumns: null;
}

export interface TableDatasetInstance extends BaseDatasetInstance {
    datasetType: DatasetType.TABLE;
    datasetFields: TableInstanceFields;
    datasetSections?: null;
    datasetRows: string[];
    fieldsUpdated: UpdatedTableField[];
    collapsedColumns: string[] | null;
}

export type DatasetInstance = FormDatasetInstance | TableDatasetInstance | AnnexInstance;

interface BaseInstanceDB {
    datasetInstanceId?: number;
    documentId: number;
    originalDocumentId: number;
    entityA: number | null;
    entityB: number | null;
    executedDate: string;
    isCurrent: number;
    isDraft: number;
    createdBy: number;
    createdDate: string;
    effectiveTo: string | null;
    clientId: number;
    username?: string;
    documentDescription?: string;
    documentType?: DocumentType;
}

export interface TableDatasetInstanceDB extends BaseInstanceDB {
    datasetId: number;
    datasetDefinitionId: number;
    annexDefinitionId: null;
    datasetType: DatasetType.TABLE;
    datasetFields: TableInstanceFields;
    datasetSections?: null;
    datasetRows: string[];
    fieldsUpdated: UpdatedTableField[];
    collapsedColumns: string[] | null;
}

export interface FormDatasetInstanceDB extends BaseInstanceDB {
    datasetId: number;
    datasetDefinitionId: number;
    annexDefinitionId: null;
    datasetType: DatasetType.FORM;
    datasetFields: FormInstanceFields;
    datasetSections: DatasetSection[] | DatasetInstanceSection[];
    fieldsUpdated: UpdatedFormField[];
    collapsedColumns: null;
}

export interface AnnexInstanceDB extends BaseInstanceDB {
    datasetId: null;
    datasetDefinitionId: null;
    annexDefinitionId: number;
    datasetType: DatasetType.TABLE;
    datasetFields: AnnexInstanceFields;
    datasetSections?: null;
    datasetRows: string[];
    fieldsUpdated: UpdatedTableField[];
    collapsedColumns: string[] | null;
}

export type DatasetInstanceDB = FormDatasetInstanceDB | TableDatasetInstanceDB | AnnexInstanceDB;

export type FieldValue = string | number | boolean | Date | DropdownOption | Options<DropdownOption> | string[] | null | RawDraftContentState | CalculatorField[] | CurrencyValue | undefined;

export interface UpdatedFormField {
    id: string;
    value: FieldValue;
    type: Omit<SingleDatasetFieldType, DatasetFieldType.SECTION> | DatasetFieldType.WIZARD;
    clauseLabel?: string;
    isCustom?: boolean;
    groupId?: string;
    label?: string;
}

export interface UpdatedTableField extends UpdatedFormField {
    row: string;
}

export type UpdatedField = UpdatedFormField | UpdatedTableField;

export interface WizardFieldOpen {
    rowId: string;
    index: number;
}

export interface DocumentToDelete {
    documentId: number;
    documentDescription: string;
    datasetInstanceId: number;
}

export interface TimelineInstance {
    datasetInstanceId: number;
    datasetId: number;
    username: string;
    documentId: number;
    documentDescription: string;
    documentType: DocumentType;
    createdDate: string;
    executedDate: string;
    isDraft: number;
    documentName: string;
    isLegacy: boolean;
}

export interface DatasetInstanceTimeline {
    instances: TimelineInstance[];
    datasetDefinition: DatasetDefinitionDB;
    linkedDocuments: LinkedDocument[] | null;
    documentName: string;
    originalDocumentId: number;
    replicatedOriginalDocumentId: number | null;
    showLegacy: boolean;
}

export interface Hierarchy {
    datasetId: string;
    parentId: string | null;
    hasUpdated: boolean;
    depthIndex: number;
    isAnnexDataset: boolean;
    fieldId: string;
    sectionId: string;
}

export enum OpenFieldType {
    FIELD = 'field',
    SECTION = 'section'
}

export interface OpenField {
    sectionId?: string;
    rowId?: string;
    fieldId: string;
    datasetId: string;
    groupIndex?: number;
    type: OpenFieldType.FIELD;
    parentFieldId: string;
}

export interface OpenSection {
    sectionId: string;
    datasetId: string;
    type: OpenFieldType.SECTION;
    parentFieldId: string;
}

export type OpenFieldSection = OpenField | OpenSection;

export interface SearchField extends OpenField {
    sectionLabel?: string;
    label: string;
    fieldType: SingleDatasetFieldType;
}

export interface SearchSection extends OpenSection {
    sectionLabel: string;
}

export type SearchFieldSection = SearchField | SearchSection;

export interface ModalInstanceParentDetails {
    datasetId: number;
    rowId: string;
    index: number;
    parentFieldId: string;
}

export interface Instance {
    parentFieldId: string;
    instance: DatasetInstance;
}

export interface DatasetInstanceState {
    datasetDefinition: DatasetDefinitionDB | null;
    ecsDatasetId: number | null;
    agencyLinkedEntities: BaseEntity[];
    parentDatasetId: number | null;
    currentInstances: Instance[];
    savedInstances: Instance[];
    hierarchy: Hierarchy[];
    openFieldsAndSections: OpenFieldSection[];
    timeline: DatasetInstanceTimeline | null;
    annexFieldIds: string[];
    isEditing: boolean;
    isUpdating: boolean;
    isSaving: boolean;
    error: string | null;
    isLoading: boolean;
    updatedInstanceModalOpen: boolean;
    confirmSaveModalOpen: boolean;
    linkedDocumentModalOpen: boolean;
    wizardFieldOpen: WizardFieldOpen | null;
    modalInstanceOpen: boolean;
    modalInstanceParentDetails: ModalInstanceParentDetails | null;
    modalInstanceLoading: boolean;
    modalInstanceSaving: boolean;
    modalInstanceUpdated: boolean;
    currentModalInstance: DatasetInstance | null;
    savedModalInstance: DatasetInstance | null;
    hiddenFields: HiddenFields;
    secondaryDocumentToDelete: DocumentToDelete | null;
    isDeleting: boolean;
    showLegacy: boolean;
    mlData: InstanceMLData | null;
    mlDataModalOpen: boolean;
    riskToleranceForDefinitions: (RiskTolerance | MyRiskTolerance)[];
    annexConfigurationModalOpen: boolean;
    isUpdatingInstanceAnnexDefinitions: boolean;
    allSearchFieldSections: SearchFieldSection[];
    searchFuzzyMatches: SearchFieldSection[];
    searchFieldSection: SearchFieldSection | null;
    fieldSectionSearch: string;
}
