import React, { useCallback, useMemo } from 'react';

import styles from './Fields.module.scss';
import { Icon } from '../../../shared/icon/Icon';
import { SmileyNegative, SmileyNeutral, SmileyPositive } from '../../../shared/icons';

const { green, amber, grey, lightGrey, red, primary } = styles;

interface AnswerIndicatorProps {
    isPositive: boolean;
    isNegative: boolean;
    showNone: boolean;
}

enum SmileyIndicator {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    NEUTRAL = 'neutral'
}

export const AnswerIndicator: React.FC<AnswerIndicatorProps> = ({ isPositive, isNegative, showNone }) => {
    const getColor = useCallback((icon: SmileyIndicator) => {
        if (icon === SmileyIndicator.POSITIVE && isPositive && !showNone) {
            return { fill: green, color: primary, fontSize: 36 };
        }
        if (icon === SmileyIndicator.NEGATIVE && isNegative && !showNone) {
            return { fill: red, color: primary, fontSize: 36 };
        }
        if (icon === SmileyIndicator.NEUTRAL && !isNegative && !isPositive && !showNone) {
            return { fill: amber, color: primary, fontSize: 36 };
        }
        return { fill: lightGrey, color: grey, fontSize: 28 };
    }, [isPositive, isNegative, showNone]);

    const negativeColor = useMemo(() => getColor(SmileyIndicator.NEGATIVE), [getColor]);
    const neutralColor = useMemo(() => getColor(SmileyIndicator.NEUTRAL), [getColor]);
    const positiveColor = useMemo(() => getColor(SmileyIndicator.POSITIVE), [getColor]);

    return (
        <div className={styles.answerIndicator}>
            <div className={styles.indicatorWrapper}><Icon icon={SmileyNegative} {...negativeColor} /></div>
            <div className={styles.indicatorWrapper}><Icon icon={SmileyNeutral} {...neutralColor} /></div>
            <div className={styles.indicatorWrapper}><Icon icon={SmileyPositive} {...positiveColor} /></div>
        </div>
    );
};
