import { ClientUser } from '../../users/store';

export enum ClientActionTypes {
    TOGGLE_CLIENTS_MODAL = 'TOGGLE_CLIENTS_MODAL',
    FETCH_ALL_CLIENTS_STARTED = 'FETCH_ALL_CLIENTS_STARTED',
    FETCH_ALL_CLIENTS_SUCCESSFUL = 'FETCH_ALL_CLIENTS_SUCCESSFUL',
    FETCH_ALL_CLIENTS_FAILED = 'FETCH_ALL_CLIENTS_FAILED',
    UPDATE_CLIENT_STARTED = 'UPDATE_CLIENT_STARTED',
    UPDATE_CLIENT_SUCCESSFUL = 'UPDATE_CLIENT_SUCCESSFUL',
    UPDATE_CLIENT_FAILED = 'UPDATE_CLIENT_FAILED',
    UPDATE_CLIENT_USERS = 'UPDATE_CLIENT_USERS',
    UPDATE_CLIENT_DETAILS = 'UPDATE_CLIENT_DETAILS',
    TOGGLE_DELETE_CLIENT_CONFIRMATION_MODAL = 'TOGGLE_DELETE_CLIENT_CONFIRMATION_MODAL',
    DELETE_CLIENT_STARTED = 'DELETE_CLIENT_STARTED',
    DELETE_CLIENT_SUCCESSFUL = 'DELETE_CLIENT_SUCCESSFUL',
    DELETE_CLIENT_FAILED = 'DELETE_CLIENT_FAILED',
    FETCH_CLIENT_FEATURE_PERMISSIONS_STARTED = 'FETCH_CLIENT_FEATURE_PERMISSIONS_STARTED',
    FETCH_CLIENT_FEATURE_PERMISSIONS_SUCCESSFUL = 'FETCH_CLIENT_FEATURE_PERMISSIONS_SUCCESSFUL',
    FETCH_CLIENT_FEATURE_PERMISSIONS_FAILED = 'FETCH_CLIENT_FEATURE_PERMISSIONS_FAILED',
    RESET_CLIENT_USER_PASSWORD = 'RESET_CLIENT_USER_PASSWORD',
    SET_OPEN_CLIENT_SECTIONS = 'SET_OPEN_CLIENT_SECTIONS',
}

export interface ClientOpinion {
    jurisdiction: string;
    focus: string;
    isdaNetting: boolean;
    isdaCollateral: boolean;
    icma: boolean;
    isla: boolean;
}

export interface Client {
    clientId?: number;
    clientName: string;
    description: string;
    users: ClientUser[];
    clientDatasetIds: number[];
    clientFeaturePermissionIds: number[] | null;
    clientOpinions: ClientOpinion[];
    demoClient: number;
    freePages: number;
    maxPages: number | null;
    monthlyTotalPages: number;
}

export interface ClientFeaturePermissionDB {
    clientFeaturePermissionId: number;
    label: string;
    description: string;
}

export enum ClientSection {
    USERS = 'users',
    FEATURE_PERMISSIONS = 'feature permissions',
    DATASETS = 'datasets',
    INDIVIDUAL_OPINIONS = 'individual opinions',
    OPINION_GROUPS = 'opinion groups'
}

export interface ClientState {
    modalOpen: boolean;
    clients: Client[];
    selectedClient: Client | null;
    isSaving: boolean;
    confirmDeleteClient: Client | null;
    deletingClient: boolean;
    clientError: null | string;
    availableClientFeaturePermissions: ClientFeaturePermissionDB[];
    openClientSections: ClientSection[];
}
