import { max, min } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { PieChart, PieChartAnalyticsData } from '../../shared/analytics/PieChart';
import { Spinner } from '../../shared/spinner/Spinner';
import styles from './AdminOpinions.module.scss';
import { OpinionHealthIndicator, getAdminOpinionPieData, getIsLoadingPie, fetchOpinionsByDateHealthCategoryStarted } from './store';

interface AdminOpinionsPieProps {
    dimensions?: {
        height: number;
        width: number;
    }
    id?: string;
    testId?: string;
}

const { red, green, amber, grey } = styles;

export const AdminOpinionsPie: React.FC<AdminOpinionsPieProps> = ({ testId, id, dimensions }) => {
    const dispatch = useAppDispatch();
    const pieData = useAppSelector(getAdminOpinionPieData);
    const isLoading = useAppSelector(getIsLoadingPie);

    const diameter = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 40;
            const svgHeight = dimensions.height - 95;
            return min([svgWidth, svgHeight])!;
        }
        return 0;
    }, [dimensions]);

    const formattedPieData: PieChartAnalyticsData[] = useMemo(() => pieData.map(segment => {
        let color = grey;
        if (segment.key === OpinionHealthIndicator.HEALTHY) color = green;
        if (segment.key === OpinionHealthIndicator.BORDERLINE) color = amber;
        if (segment.key === OpinionHealthIndicator.STALE) color = red;
        return { ...segment, color };
    }), [pieData]);

    const handleFetchTableData = useCallback((key: string) => { dispatch(fetchOpinionsByDateHealthCategoryStarted(key)); }, [dispatch]);

    const tooltipContent = ({ value }: PieChartAnalyticsData) => `<tspan dy=12 x=10>Total: ${value}</tspan>`;
    const tooltipWidth = (key: string, value: number) => max([(`Total: ${value}`.length * 7), 10])!;

    const body = useMemo(() => {
        if (isLoading) {
            return <Spinner />;
        }

        if (formattedPieData.length === 0) {
            return <div className={styles.noDataMessage}>No Opinion data found</div>;
        }

        return (
            <PieChart
                data={formattedPieData}
                testId={testId}
                id={id}
                diameter={diameter}
                tooltipContent={tooltipContent}
                tooltipWidth={tooltipWidth}
                fullOpacity
                onDblClick={handleFetchTableData}
                includeLegend
                legendPosition='bottom'
            />

        );
    }, [diameter, formattedPieData, id, testId, isLoading, handleFetchTableData]);

    return (
        <div className={styles.pieChartWrapper} data-testid={`admin-opinions-wrapper-${testId}`}>
            <div className={styles.sectionHeader}>
                <div className={styles.sectionTitle} data-testid={`admin-opinions-title-${testId}`}>Opinions Breakdown</div>
            </div>
            {body}
        </div>
    );
};
