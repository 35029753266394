import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isNull } from 'lodash/fp';

import styles from '../../Analytics.module.scss';
import { InformationTooltip, OverflowTooltip } from '../../../../shared/tooltip';
import { IconButton } from '../../../../shared/button/IconButton';
import { Sort } from '../../../../shared/icons';
import { DoraAnalyticsChart, getFetchingAnalytics, SupplyChainRole, NetworkNodeDetails, getNetworkDiagramAnalytics } from '../../store';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { Spinner } from '../../../../shared/spinner/Spinner';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { NetworkDiagramChart } from './NetworkDiagramChart';
import { NetworkDiagramLegend } from './NetworkDiagramLegend';
import { DataManagementTile, setSelectedTab } from '../../../data-management/store';

const { red, green, gold } = styles;

interface NetworkDiagramWrapperProps {
    tileInView: DoraAnalyticsChart | null;
    setTileInView: (tile: DoraAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
    dimensions?: {
        height: number;
        width: number;
    }
}

export const NetworkDiagramWrapper: React.FC<NetworkDiagramWrapperProps> = ({ tileInView, setTileInView, analyticsSpinner, dimensions }) => {
    const [nodeDetails, setNodeDetails] = useState<NetworkNodeDetails | null>(null);
    const [zoomDistance, setZoomDistance] = useState<number>(250);
    const [showDoraNetworkAnalyticsSpinner, setDoraNetworkAnalyticsSpinner] = useState(false);
    const dispatch = useAppDispatch();
    const setDetailsAndSpinner = useCallback((value: NetworkNodeDetails) => {
        setNodeDetails(value);
        setDoraNetworkAnalyticsSpinner(true);
    }, []);

    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const graphData = useAppSelector(getNetworkDiagramAnalytics);
    const chartIsFullScreen = useMemo(() => tileInView === DoraAnalyticsChart.NETWORK_DIAGRAM, [tileInView]);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(DoraAnalyticsChart.NETWORK_DIAGRAM), [fetchingAnalytics]);

    const { height, width } = useMemo(() => {
        if (dimensions) {
            const svgWidth = chartIsFullScreen ? ((dimensions.width / 5) * 3) - 20 : dimensions.width - 20;
            const svgHeight = chartIsFullScreen ? dimensions.height - 120 : ((dimensions.height / 2) - 70);
            return { height: svgHeight, width: svgWidth };
        }
        return { height: 0, width: 0 };
    }, [dimensions, chartIsFullScreen]);

    const toggleTileView = useCallback(() => setTileInView(isNull(tileInView) ? DoraAnalyticsChart.NETWORK_DIAGRAM : null), [setTileInView, tileInView]);

    const description = 'This chart displays all of the connections between your Functions, Companies and Third Party ICT Providers to get a broad view of interactions and potentially spot any patterns or risks that might be underlying your ICT provision.';
    const nodeInfo = 'Clicking or dragging an element of the network diagram provides further information.';
    const wrapperDimensions = dimensions ? { height: chartIsFullScreen ? dimensions.height : (dimensions.height / 2) - 5, width: dimensions.width } : { height: '100%', width: '100%' };

    const zoomIn = useCallback(() => setZoomDistance(zoomDistance - 50), [zoomDistance, setZoomDistance]);
    const zoomOut = useCallback(() => setZoomDistance(zoomDistance + 50), [zoomDistance, setZoomDistance]);
    const resetZoom = useCallback(() => setZoomDistance(250), [setZoomDistance]);

    const noNetwork = useMemo(() => graphData.nodes.length === 0, [graphData]);
    const emptyMessage = 'Your in scope companies are currently not performing any functions. Please add a function to one of your companies to begin its supply chain';

    const nodeHasDirectLinks = useMemo(() => !isNull(nodeDetails) && nodeDetails.directLinks.length > 0, [nodeDetails]);
    const nodeHasIndirectLinks = useMemo(() => !isNull(nodeDetails) && nodeDetails.indirectLinks.length > 0, [nodeDetails]);
    const scrollableDetailsHeight = useMemo(() => nodeHasIndirectLinks ? 'calc(50% - 40px)' : 'calc(100% - 60px)', [nodeHasIndirectLinks]);

    const redirectToCompanies = useCallback(() => {
        dispatch(setSelectedTab(DataManagementTile.MY_COMPANIES, true));
    }, [dispatch]);

    const nodeBulletPointColour = (role: string) => {
        if (role === 'My Company') return green;
        if (role === 'Third Party') return gold;
        return red;
    };

    useEffect(() => {
        if (showDoraNetworkAnalyticsSpinner) {
            setTimeout(() => setDoraNetworkAnalyticsSpinner(false), 100);
        }
    }, [showDoraNetworkAnalyticsSpinner]);

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.networkDiagramWrapper} style={wrapperDimensions}>
            <div className={styles.networkDiagramHeader}>
                <div className={styles.networkDiagramTitle}>DORA Network</div>
                {!chartIsFullScreen && <div className={styles.networkDiagramDescription}><InformationTooltip content={description} /></div>}
                <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
            </div>
            <div className={styles.networkChartMetaWrapper}>
                {chartIsFullScreen &&
                    <div className={styles.metaWrapper}>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>{description}</div>
                            <div className={styles.description}>{nodeInfo}</div>
                        </div>
                        {showDoraNetworkAnalyticsSpinner ? <Spinner /> : !isNull(nodeDetails) &&
                            <div className={styles.nodeDetailsWrapper}>
                                <div className={styles.nodeDetails}>
                                    <OverflowTooltip className={styles.nodeDetailsLabel} overlayText={`Selected ${nodeDetails.role}:`} />
                                    <div className={styles.nodeDetailsLabelValue}>
                                        <div className={styles.bulletPointWrapper}>
                                            <div className={styles.bulletPoint} style={{ backgroundColor: nodeBulletPointColour(nodeDetails.role) }} />
                                            <OverflowTooltip className={styles.nodeDetailsValue} overlayText={nodeDetails.name} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.nodeDetails}>
                                    <OverflowTooltip className={styles.nodeDetailsLabel} overlayText={nodeDetails.role === SupplyChainRole.FUNCTION ? 'Links to this function: ' : 'Links to dora functions: '} />
                                    <div className={styles.nodeDetailsValue}>{nodeDetails.count}</div>
                                </div>
                                {nodeHasDirectLinks && <div className={styles.scrollableNodeDetails} style={{ height: scrollableDetailsHeight }}>
                                    <OverflowTooltip className={styles.nodeDetailsLabel} overlayText={'Direct links:'} />
                                    <Scrollable>
                                        <div className={styles.nodeLinks}>{nodeDetails.directLinks.map(({ name, role }, index) => (
                                            <div key={index} className={styles.bulletPointWrapper}>
                                                <div className={styles.bulletPoint} style={{ backgroundColor: nodeBulletPointColour(role) }} />
                                                <OverflowTooltip className={styles.nodeDetailsValue} overlayText={name} />
                                            </div>
                                        ))}</div>
                                    </Scrollable>
                                </div>}
                                {nodeHasIndirectLinks && <div className={styles.scrollableNodeDetails} style={{ height: scrollableDetailsHeight }}>
                                    <OverflowTooltip className={styles.nodeDetailsLabel} overlayText='Indirect links:' />
                                    <Scrollable>
                                        <div className={styles.nodeLinks}>{nodeDetails.indirectLinks.map(({ name, role }, index) => (
                                            <div key={index} className={styles.bulletPointWrapper}>
                                                <div className={styles.bulletPoint} style={{ backgroundColor: nodeBulletPointColour(role) }} />
                                                <OverflowTooltip className={styles.nodeDetailsValue} overlayText={name} />
                                            </div>
                                        ))}</div>
                                    </Scrollable>
                                </div>}
                            </div>}
                    </div>
                }
                {noNetwork ? <div className={styles.noNetworkDataWrapper}><button onClick={redirectToCompanies} className={styles.noNetworkDataButton}>{emptyMessage}</button></div> : <NetworkDiagramChart height={height} width={width} setNodeDetails={setDetailsAndSpinner} zoomDistance={zoomDistance} graphData={graphData} />}
            </div>
            <NetworkDiagramLegend
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                resetZoom={resetZoom}
                chartIsFullScreen={chartIsFullScreen}
            />
        </div>
    );
};
