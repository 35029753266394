import React from 'react';
import classnames from 'classnames';

import { Icon } from '../../../shared/icon/Icon';
import { Delete } from '../../../shared/icons';
import { DatasetSection } from '../../../datasets/store';
import { InformationTooltip } from '../../../shared/tooltip';
import styles from './DatasetComponents.module.scss';
import { useAppSelector } from '../../../../hooks/react-redux';
import { getSectionHasIncompleteFields } from '../store';

interface SectionButtonProps {
    section: DatasetSection;
    selectedSection: DatasetSection | null;
    openSection: (id: string) => void;
    deleteSection: (id: string) => void;
    editSection: (id: string) => void;
    index: number;
}

export const SectionButton: React.FC<SectionButtonProps> = ({ selectedSection, section, openSection, deleteSection, editSection, index }) => {
    const { id, description, label } = section;
    const sectionHasIncompleteFields = useAppSelector(getSectionHasIncompleteFields(id));
    const sectionSelected = !!selectedSection && id === selectedSection.id;
    return (
        <div
            className={classnames(styles.sectionButtonWrapper, {
                [styles.incompleteSectionButtonWrapper]: sectionHasIncompleteFields && !sectionSelected,
                [styles.selectedSectionButtonWrapper]: sectionSelected
            })}
            data-testid={`form-dataset-builder-section-${index}-wrapper`}
        >
            <div className={styles.sectionButtonActionWrapper}>
                <button className={styles.deleteSection} onClick={() => deleteSection(id)} data-testid={`form-dataset-builder-section-${index}-delete`}>
                    <Icon icon={Delete} fontSize={20} />
                </button>
            </div>
            <button
                className={styles.sectionButton}
                onClick={() => openSection(id)}
                onDoubleClick={() => editSection(id)}
                data-testid={`form-dataset-builder-section-${index}`}
            >
                <div className={styles.sectionButtonLabel} data-testid={`form-dataset-builder-section-${index}-label`}>{label || '...'}</div>
                {description && <InformationTooltip content={description} />}
            </button>
        </div>
    );
};
