import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DeleteButton } from '../../../shared/button/DeleteButton';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Text } from '../../../shared/text/Text';
import { getAllDropdownLists } from '../../dropdown-lists/store';
import { addCompanyBicCode, AIGeneratedEntityField, CompanyEntity, CompanyEntityContent, EntityAIFieldKey, getAvailableSubCounterpartyTypes, removeCompanyBicCode, updateEntityAIField, updateEntityContent } from '../store';
import { AiDropdownField } from './AiDropdownField';
import styles from './EntityType.module.scss';
import { MiFIDOptions, emirOptions, opinionCounterpartyTypeOptions } from '../../../constants/entity';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { InformationTooltip } from '../../../shared/tooltip';

interface CompanyEntityDetailsSectionProps {
    entity: CompanyEntity;
}

export const CompanyEntityDetailsSection: React.FC<CompanyEntityDetailsSectionProps> = ({ entity }) => {
    const dispatch = useAppDispatch();
    const handleUpdateContent = useCallback((key: keyof CompanyEntityContent, value: string | string[] | Date | number | null) => dispatch(updateEntityContent(key, value)), [dispatch]);
    const updateAIFieldContent = useCallback((key: EntityAIFieldKey, secondaryKey: keyof AIGeneratedEntityField, value: string | null | boolean) => dispatch(updateEntityAIField(key, secondaryKey, value)), [dispatch]);
    const addBicCode = () => dispatch(addCompanyBicCode());
    const deleteBicCode = (index: number) => dispatch(removeCompanyBicCode(index));
    const allDropdowns = useAppSelector(getAllDropdownLists);
    const availableSubCounterpartyTypes = useAppSelector(getAvailableSubCounterpartyTypes);

    const availableSubCounterpartyTypeOptions = availableSubCounterpartyTypes.map(({ subCounterpartyName, subCounterpartyTypeId }) => ({ value: subCounterpartyTypeId!.toString(), label: subCounterpartyName }));
    const { content: { MiFIDClassification, emirClassification, companyNumber, counterpartyType, primaryContact, opinionCounterpartyType, opinionSubCounterpartyType } } = entity;

    const updateBicCode = (index: number, value: string) => handleUpdateContent('bic', entityBIC.map((bic, i) => i === index ? value : bic));
    const handleUpdateSubCounterparty = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = parseInt((option as DropdownOption).value);
        }
        handleUpdateContent('opinionSubCounterpartyType', selected);
    };

    const counterpartyTypeDropdownOptions = allDropdowns.find(({ name }) => name === 'CounterpartyType')?.options || [];

    const updateCounterpartyType = (value: string | null) => updateAIFieldContent('counterpartyType', 'value', value);
    const updateOpinionCounterpartyType = (value: string | null) => {
        updateAIFieldContent('opinionCounterpartyType', 'value', value);
        if (isNull(value) || value !== opinionCounterpartyType?.value) {
            handleUpdateContent('opinionSubCounterpartyType', null);
        }
    };
    const updateEmirClassification = (value: string | null) => updateAIFieldContent('emirClassification', 'value', value);
    const updateMiFIDClassification = (value: string | null) => updateAIFieldContent('MiFIDClassification', 'value', value);

    const entityBIC = useMemo(() => entity.content.bic || [''], [entity.content.bic]);

    const subCounterpartyValue = useMemo(() => opinionSubCounterpartyType ? { value: opinionSubCounterpartyType.toString(), label: availableSubCounterpartyTypeOptions.find(({ value }) => value === opinionSubCounterpartyType.toString())!.label } : null, [availableSubCounterpartyTypeOptions, opinionSubCounterpartyType]);
    const hideSubCounterpartyType = useMemo(() => !entity.content.opinionJurisdiction || !opinionCounterpartyType?.value, [entity, opinionCounterpartyType]);

    return (
        <div className={styles.detailsWrapper}>
            <Scrollable>
                <Text
                    label='Company Number'
                    testId='entity-company-number'
                    onChange={e => handleUpdateContent('companyNumber', e.target.value)}
                    value={companyNumber || ''}
                    placeholder='Company Number...'
                />
                <Text
                    label='Primary Contact'
                    testId='entity-primary-contact'
                    onChange={e => handleUpdateContent('primaryContact', e.target.value)}
                    value={primaryContact}
                    placeholder='Primary Contact...'
                />
                <AiDropdownField
                    label='MiFID Classification'
                    testId='entity-mifid-classification'
                    updateValue={updateMiFIDClassification}
                    toggleConfirmation={value => updateAIFieldContent('MiFIDClassification', 'aiConfirmed', value)}
                    field={MiFIDClassification}
                    options={MiFIDOptions}
                />
                <AiDropdownField
                    label='EMIR Classification'
                    testId='entity-emir-classification'
                    updateValue={updateEmirClassification}
                    toggleConfirmation={value => updateAIFieldContent('emirClassification', 'aiConfirmed', value)}
                    field={emirClassification}
                    options={emirOptions}
                />
                <AiDropdownField
                    label='Counterparty Type'
                    testId='entity-counterparty-type'
                    updateValue={updateCounterpartyType}
                    toggleConfirmation={value => updateAIFieldContent('counterpartyType', 'aiConfirmed', value)}
                    field={counterpartyType}
                    options={counterpartyTypeDropdownOptions}
                />
                <AiDropdownField
                    label='Opinion Counterparty Type'
                    testId='entity-opinion-counterparty-type'
                    updateValue={updateOpinionCounterpartyType}
                    field={opinionCounterpartyType}
                    options={opinionCounterpartyTypeOptions}
                    toggleConfirmation={value => updateAIFieldContent('opinionCounterpartyType', 'aiConfirmed', value)}
                />
                {!hideSubCounterpartyType && <div className={styles.inputWrapper}>
                    <div className={styles.inputLabelWrapper}>
                        <div className={styles.inputLabel}>Opinion Sub Counterparty Type</div>
                        <div className={styles.tooltipWrapper}>
                            <InformationTooltip content='This list is driven by the sub counterparty types available for the given parent and opinion jurisdiction.' />
                        </div>
                    </div>
                    <Dropdown
                        testId='entity-opinion-sub-counterparty-type'
                        onChange={handleUpdateSubCounterparty}
                        value={subCounterpartyValue}
                        options={availableSubCounterpartyTypeOptions}
                    />
                </div>}
                <div data-testid='entity-bic-label' className={styles.bicHeader}>
                    <div className={styles.bicLabel}>BIC Code</div>
                    <PlusButton onClick={addBicCode} fontSize={15} />
                </div>
                <div className={styles.bicWrapper}>
                    <Scrollable maxHeight='215px'>
                        {entityBIC.map((bic, index) => (
                            <div key={index} className={styles.bicCodeWrapper}>
                                <Text
                                    testId={`entity-bic-${index}`}
                                    onChange={e => updateBicCode(index, e.target.value)}
                                    value={bic}
                                    marginBottom='0px'
                                    marginRight='10px'
                                    width='calc(100% - 30px)'
                                    placeholder='BIC Code...'
                                />
                                <DeleteButton onClick={() => deleteBicCode(index)} fontSize={20} />
                            </div>
                        ))}
                    </Scrollable>
                </div>
            </Scrollable>
        </div>
    );
};
