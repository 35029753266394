import { api } from './services';
import { DatasetInstance, TimelineInstance } from '../components/datasets/instances/store';

type UpsertDatasetInstanceRequest = { datasetInstance: DatasetInstance; isUpdate: boolean; isDraft: boolean; };
type FetchDatasetInstanceRequest = { datasetId: number | string | null; documentId: number | string; datasetInstanceId: string | null; };
type InstanceExistsRequest = { datasetInstanceId: number | string; documentId: number | string; documentNameId: number; };
type GenerateECSTableRequest = { parentFieldId: string; ecsTableDatasetId: string; documentId: number; };

export const upsertDatasetInstance = async (reqBody: UpsertDatasetInstanceRequest) => await api('dataset/instance/upsert', { ...reqBody }, 'post');
export const fetchDatasetInstance = async (reqBody: FetchDatasetInstanceRequest) => await api('dataset/instance/getById', { ...reqBody }, 'post');
export const fetchDatasetId = async (reqBody: { documentNameId: number }) => await api('dataset/getDatasetId', { ...reqBody }, 'post');
export const datasetInstanceExistsForDocumentId = async (reqBody: InstanceExistsRequest) => await api('dataset/instance/exists', { ...reqBody }, 'post');

export const fetchECSDatasetId = async () => await api('dataset/ecsDatasetId', {}, 'get');
export const generateECSTable = async (reqBody: GenerateECSTableRequest) => await api('dataset/instance/generateECS', { ...reqBody }, 'post');

export const fetchTimelineInstances = async (reqBody: { datasetId: number | string | null; documentId: number | string; documentNameId: number; showLegacy?: boolean; }) => await api('dataset/instance/getTimelineInstances', { ...reqBody }, 'post');
export const fetchInstanceHierarchy = async (reqBody: { instance: TimelineInstance; }) => await api('dataset/instance/getInstanceHierarchy', { ...reqBody }, 'post');
export const fetchAgencyAnnexDetails = async (reqBody: { documentId: number; }) => await api('dataset/instance/annexDetails', { ...reqBody }, 'post');
