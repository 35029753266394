import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Store } from 'redux';
import { History } from 'history';
import { ToastContainer, toast } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';
import 'react-toastify/dist/ReactToastify.css';

import { ApplicationState } from './store/rootReducer';
import App from './App';
import { LogoutOverlay } from './components/auth/login/LogoutOverlay';
import { Sockets } from './Sockets';
import { SystemNotification } from './components/auth/notifications/SystemNotification';
import { SwitchAccountOverlay } from './components/auth/login/SwitchAccountOverlay';

const INTERCOM_APP_ID = 'wz2qysk5';

interface MainProps {
    store: Store<ApplicationState>
    history: History
}

const Main: React.FC<MainProps> = ({ store, history }) => (
    <Provider store={store}>
        <Router history={history}>
            <IntercomProvider appId={INTERCOM_APP_ID} key='ark-51-intercom'>
                <SwitchAccountOverlay />
                <App />
                <Sockets />
                <SystemNotification />
                <LogoutOverlay />
            </IntercomProvider>
        </Router>
        <ToastContainer
            position={toast.POSITION.BOTTOM_LEFT}
            autoClose={4000}
            closeOnClick
            limit={3}
        />
    </Provider>
);

export default Main;
