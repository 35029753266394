import classnames from 'classnames';
import { isNull, isUndefined, noop, uniq } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch } from '../../../../../../hooks/react-redux';
import { useSingleHiddenDocumentFields } from '../../../../../../hooks/useHiddenSingleFields';
import { OpenFieldType } from '../../../../../datasets/instances/store';
import { GroupDatasetField } from '../../../../../datasets/store';
import { PlusButton } from '../../../../../shared/button/PlusButton';
import { DropdownOption } from '../../../../../shared/dropdown/Dropdown';
import { Toggle } from '../../../../../shared/toggle';
import { OverflowTooltip } from '../../../../../shared/tooltip';
import { SingleFieldPreview } from '../../../preview/formPreview/SingleFieldPreview';
import { DocumentSpecificHiddenFields, HiddenDocumentField, toggleDocumentHiddenField } from '../../../store';
import { DatasetDocumentSelect } from '../../DatasetDocumentSelect';
import styles from '../../ConfigureDatasets.module.scss';

const { lightGrey } = styles;

interface DocumentDatasetMultiToggleProps {
    field: GroupDatasetField;
    groupIndex: number;
    sectionId: string;
    datasetId: number;
    isPreview: boolean;
    hiddenFields: DocumentSpecificHiddenFields;
    hiddenDocumentNameIds?: number[] | undefined;
    documentNames: DropdownOption[];
    previewSelectedDocument: number | null;
}

export const DocumentDatasetMultiToggle: React.FC<DocumentDatasetMultiToggleProps> = ({
    field,
    groupIndex,
    sectionId,
    datasetId,
    isPreview,
    hiddenFields,
    hiddenDocumentNameIds,
    documentNames,
    previewSelectedDocument
}) => {
    const dispatch = useAppDispatch();
    const { children, label, id } = field;
    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenDocumentFields(currentHiddenDatasetFields);

    const getOptionWrapperStyle = useCallback((index: number) => {
        const totalEntries = children.length;
        const numberOfLastRowEntries = totalEntries % 3 || 3;
        const lastRowEntries = Array(numberOfLastRowEntries).fill(totalEntries - numberOfLastRowEntries).map((val, i) => val + i);
        const isLastRow = lastRowEntries.includes(index);
        const isFirstColumn = ((index + 1) % 3) === 1;
        if (!isLastRow) {
            return !isFirstColumn ? { width: 'calc((100% / 3) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}`, borderBottom: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)', borderBottom: `1px solid ${lightGrey}` };
        }
        return !isFirstColumn ? { width: 'calc((100% / 3) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)' };
    }, [children]);

    const documentsHiddenForSection = currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(hiddenField => hiddenField.sectionId === sectionId)?.documentNameIds || [];
    const documentsHiddenForField = hiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds || [];

    const parentHiddenDocuments = !isUndefined(hiddenDocumentNameIds) ? hiddenDocumentNameIds : [];
    const allDocumentsHiddenForField = uniq([...documentsHiddenForField, ...documentsHiddenForSection, ...parentHiddenDocuments]);
    const fieldDocuments = allDocumentsHiddenForField.length > 0 ? documentNames.filter(({ value }) => !allDocumentsHiddenForField.includes(parseInt(value))) : documentNames;
    const documentNameOptions = [...parentHiddenDocuments, ...documentsHiddenForSection].length > 0 ? documentNames.map(option => [...parentHiddenDocuments, ...documentsHiddenForSection].includes(parseInt(option.value)) ? ({ ...option, disabled: true }) : option) : documentNames;
    const fieldIsHidden = useMemo(() => !isNull(previewSelectedDocument) && allDocumentsHiddenForField.includes(previewSelectedDocument) && !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [previewSelectedDocument, allDocumentsHiddenForField, hiddenSingleFields, id]);

    const setDocumentsForField = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: number[] = [];
        if (!isNull(dropdownValue)) {
            const applicableDocuments = (dropdownValue as Options<DropdownOption>).map(({ value }) => value);
            value = documentNames.filter(({ value }) => !applicableDocuments.includes(value)).map(({ value }) => parseInt(value));
            const hiddenField: HiddenDocumentField = { fieldId: id!, type: OpenFieldType.FIELD, documentNameIds: value, sectionId };
            dispatch(toggleDocumentHiddenField(datasetId, hiddenField));
            children.map(field => {
                const hiddenField: HiddenDocumentField = { fieldId: field.id!, type: OpenFieldType.FIELD, documentNameIds: value, sectionId };
                dispatch(toggleDocumentHiddenField(datasetId, hiddenField));
            });
        }
    };

    if (isPreview && fieldIsHidden) {
        return null;
    }

    return (
        <div className={styles.multiToggleWrapper}>
            <div className={styles.multiToggleGroupLabelWrapper}>
                <div className={classnames(styles.multiToggleGroupLabel, { [styles.emptyFieldLabel]: !label })}>
                    <OverflowTooltip overlayText={label || 'Label...'} />
                </div>
                {!isPreview &&
                    <DatasetDocumentSelect
                        selectedDocuments={fieldDocuments}
                        onChange={setDocumentsForField}
                        documentNames={documentNameOptions}
                        label={label || 'Label...'}
                    />
                }
            </div>
            <div className={styles.multiToggleHeaderWrapper}>
                <div className={styles.selectAllWrapper}>
                    <div className={styles.selectAllLabel}>Select All</div>
                    <Toggle onChange={noop} checked={false} disabled />
                </div>
                <div className={styles.addOptionWrapper}>
                    <div className={styles.addOptionLabel}>Add another option</div>
                    <div className={styles.addOptionButton}><PlusButton onClick={noop} fontSize={16} disabled /></div>
                </div>
            </div>
            <div className={styles.multiToggleOptionsWrapper} data-testid={`form-preview-multi-toggle-${groupIndex}-wrapper`}>
                {children.map((field, index) => {
                    const singleIndex = `${groupIndex}${index}`;
                    const optionWrapperStyle = getOptionWrapperStyle(index);
                    return (
                        <div className={styles.toggleOptionWrapper} key={field.id} style={optionWrapperStyle}>
                            <SingleFieldPreview
                                field={field}
                                singleIndex={singleIndex}
                                index={index}
                                groupIndex={groupIndex}
                                isMultiToggle
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
