import { ApplicationState } from '../../../../store/rootReducer';
import { ResetPasswordState } from './types';

const getRoot = (state: ApplicationState): ResetPasswordState => state.auth.resetPassword;

export const getCurrentPassword = (state: ApplicationState): string => getRoot(state).currentPassword;
export const getNewPassword = (state: ApplicationState): string => getRoot(state).newPassword;
export const getConfirmNewPassword = (state: ApplicationState): string => getRoot(state).confirmNewPassword;

export const getPasswordScore = (state: ApplicationState): number => getRoot(state).passwordScore;

export const getPasswordIsStrongEnough = (state: ApplicationState): boolean => {
    const passwordScore = getPasswordScore(state);
    return passwordScore > 2;
};

export const getErrorMessage = (state: ApplicationState): string | null => getRoot(state).errorMessage;

export const getIsResettingPassword = (state: ApplicationState): boolean => getRoot(state).resettingPassword;
