import { FieldValue } from '../../../datasets/instances/store';
import { DatasetFieldType, DatasetType } from '../../../datasets/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { BaseEntity } from '../../../admin/entity/store';
import { BaseRiskField, CheckboxRiskConfig, DateRiskConfig, DropdownRiskConfig, NumberRiskConfig, RiskToleranceDataset, TimeRiskConfig } from '../../../admin/risk-tolerance/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

export enum DocumentAnalyticsActionTypes {
    FETCH_DOCUMENT_SPREAD_ANALYTICS_STARTED = 'FETCH_DOCUMENT_SPREAD_ANALYTICS_STARTED',
    FETCH_DOCUMENT_SPREAD_ANALYTICS_SUCCESSFUL = 'FETCH_DOCUMENT_SPREAD_ANALYTICS_SUCCESSFUL',
    FETCH_DOCUMENT_SPREAD_ANALYTICS_FAILED = 'FETCH_DOCUMENT_SPREAD_ANALYTICS_FAILED',
    FETCH_ENTITY_SPREAD_ANALYTICS_STARTED = 'FETCH_ENTITY_SPREAD_ANALYTICS_STARTED',
    FETCH_ENTITY_SPREAD_ANALYTICS_SUCCESSFUL = 'FETCH_ENTITY_SPREAD_ANALYTICS_SUCCESSFUL',
    FETCH_ENTITY_SPREAD_ANALYTICS_FAILED = 'FETCH_ENTITY_SPREAD_ANALYTICS_FAILED',
    FETCH_ENTITY_JURISDICTION_ANALYTICS_STARTED = 'FETCH_ENTITY_JURISDICTION_ANALYTICS_STARTED',
    FETCH_ENTITY_JURISDICTION_ANALYTICS_SUCCESSFUL = 'FETCH_ENTITY_JURISDICTION_ANALYTICS_SUCCESSFUL',
    FETCH_ENTITY_JURISDICTION_ANALYTICS_FAILED = 'FETCH_ENTITY_JURISDICTION_ANALYTICS_FAILED',
    FETCH_JURISDICTION_DOCUMENTS_STARTED = 'FETCH_JURISDICTION_DOCUMENTS_STARTED',
    FETCH_JURISDICTION_DOCUMENTS_SUCCESSFUL = 'FETCH_JURISDICTION_DOCUMENTS_SUCCESSFUL',
    FETCH_JURISDICTION_DOCUMENTS_FAILED = 'FETCH_JURISDICTION_DOCUMENTS_FAILED',
    JURISDICTION_DOCUMENTS_SELECT_PAGE = 'JURISDICTION_DOCUMENTS_SELECT_PAGE',
    SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY',
    SET_COUNTRY_ENTITIES = 'SET_COUNTRY_ENTITIES',
    CLEAR_COUNTRY_ENTITIES = 'CLEAR_COUNTRY_ENTITIES',
    SET_DOCUMENT_ANALYTICS_TILE_IN_VIEW = 'SET_DOCUMENT_ANALYTICS_TILE_IN_VIEW',
    SET_SELECTED_MY_COMPANY_ENTITY = 'SET_SELECTED_MY_COMPANY_ENTITY',
    FETCH_ALL_MY_COMPANY_ENTITIES_STARTED = 'FETCH_ALL_MY_COMPANY_ENTITIES_STARTED',
    FETCH_ALL_MY_COMPANY_ENTITIES_SUCCESSFUL = 'FETCH_ALL_MY_COMPANY_ENTITIES_SUCCESSFUL',
    FETCH_ALL_MY_COMPANY_ENTITIES_FAILED = 'FETCH_ALL_MY_COMPANY_ENTITIES_FAILED',
    FETCH_DOCUMENT_RISK_TOLERANCE_STARTED = 'FETCH_DOCUMENT_RISK_TOLERANCE_STARTED',
    FETCH_DOCUMENT_RISK_TOLERANCE_SUCCESSFUL = 'FETCH_DOCUMENT_RISK_TOLERANCE_SUCCESSFUL',
    FETCH_DOCUMENT_RISK_TOLERANCE_FAILED = 'FETCH_DOCUMENT_RISK_TOLERANCE_FAILED',
    FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_STARTED = 'FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_STARTED',
    FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL = 'FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL',
    FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_FAILED = 'FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_FAILED',
    FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_STARTED = 'FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_STARTED',
    FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL = 'FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL',
    FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_FAILED = 'FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_FAILED',
    SET_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS = 'SET_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS',
    CLEAR_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS = 'CLEAR_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS',
    SET_DOCUMENT_RISK_TOLERANCE_TABLE_COLUMN_SORT = 'SET_DOCUMENT_RISK_TOLERANCE_TABLE_COLUMN_SORT',
    SET_DOCUMENT_RISK_TOLERANCE_PAGE_SIZE = 'SET_DOCUMENT_RISK_TOLERANCE_PAGE_SIZE',
    DOCUMENT_RISK_TOLERANCE_PAGINATION_NEXT = 'DOCUMENT_RISK_TOLERANCE_PAGINATION_NEXT',
    DOCUMENT_RISK_TOLERANCE_PAGINATION_PREVIOUS = 'DOCUMENT_RISK_TOLERANCE_PAGINATION_PREVIOUS',
    FETCH_ALL_DATASETS_RISK_TOLERANCE_STARTED = 'FETCH_ALL_DATASETS_RISK_TOLERANCE_STARTED',
    FETCH_ALL_DATASETS_RISK_TOLERANCE_SUCCESSFUL = 'FETCH_ALL_DATASETS_RISK_TOLERANCE_SUCCESSFUL',
    FETCH_ALL_DATASETS_RISK_TOLERANCE_FAILED = 'FETCH_ALL_DATASETS_RISK_TOLERANCE_FAILED',
    SET_RISK_TOLERANCE_VIEW = 'SET_RISK_TOLERANCE_VIEW',
    SET_ENTITY_DOCUMENT_RISK_TOLERANCE_VIEW = 'SET_ENTITY_DOCUMENT_RISK_TOLERANCE_VIEW',
    SET_PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE_VIEW = 'SET_PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE_VIEW',
    FETCH_DATASET_FIELD_RISK_TOLERANCE_STARTED = 'FETCH_DATASET_FIELD_RISK_TOLERANCE_STARTED',
    FETCH_DATASET_FIELD_RISK_TOLERANCE_SUCCESSFUL = 'FETCH_DATASET_FIELD_RISK_TOLERANCE_SUCCESSFUL',
    FETCH_DATASET_FIELD_RISK_TOLERANCE_FAILED = 'FETCH_DATASET_FIELD_RISK_TOLERANCE_FAILED',
    SET_RISK_TOLERANCE_DATASET_ID = 'SET_RISK_TOLERANCE_DATASET_ID',
    GET_SELECTED_RISK_FIELD_DOCUMENT_META = 'GET_SELECTED_RISK_FIELD_DOCUMENT_META',
    RESET_DOCUMENT_FIELD_SELECTION = 'RESET_DOCUMENT_FIELD_SELECTION',
    SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS = 'SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS',
    CLEAR_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS = 'CLEAR_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS',
    SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_COLUMN_SORT = 'SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_COLUMN_SORT',
    SET_DATASET_FIELD_RISK_TOLERANCE_PAGE_SIZE = 'SET_DATASET_FIELD_RISK_TOLERANCE_PAGE_SIZE',
    DATASET_FIELD_RISK_TOLERANCE_PAGINATION_NEXT = 'DATASET_FIELD_RISK_TOLERANCE_PAGINATION_NEXT',
    DATASET_FIELD_RISK_TOLERANCE_PAGINATION_PREVIOUS = 'DATASET_FIELD_RISK_TOLERANCE_PAGINATION_PREVIOUS',
    SET_SELECTED_DATASET_RISK_FIELD = 'SET_SELECTED_DATASET_RISK_FIELD',
    REDIRECT_TO_DOCUMENT_INSTANCE = 'REDIRECT_TO_DOCUMENT_INSTANCE',
    FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_STARTED = 'FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_STARTED',
    FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL = 'FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL',
    FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_FAILED = 'FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_FAILED',
    FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_STARTED = 'FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_STARTED',
    FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL = 'FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL',
    FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_FAILED = 'FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_FAILED',
}

export enum DocumentAnalyticsChart {
    DOCUMENT_ENTITY_SPREAD = 'document entity spread',
    DOCUMENT_SPREAD = 'document spread',
    ENTITY_SPREAD = 'entity spread',
    ENTITY_JURISDICTION = 'entity jurisdiction',
    JURISDICTION_DOCUMENTS = 'jurisdiction documents',
    DOCUMENT_RISK_TOLERANCE = 'document risk tolerance',
    ENTITY_DOCUMENT_RISK_TOLERANCE = 'entity document risk tolerance',
    PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE = 'pre execution document risk tolerance'
}

export enum DocumentRiskView {
    DOCUMENTS = 'documents',
    DOCUMENT_BREAKDOWN = 'document breakdown',
    DATASETS = 'datasets',
    DATASET_FIELD = 'dataset field'
}

export enum PreExecutionDocumentRiskView {
    PRE_EXECUTION_DOCUMENTS = 'pre execution documents',
    PRE_EXECUTION_DOCUMENT_BREAKDOWN = 'pre execution document breakdown',
    PRE_EXECUTION_DATASETS = 'pre execution datasets',
    PRE_EXECUTION_DATASET_FIELD = 'pre execution dataset field'
}

export enum EntityDocumentRiskView {
    ENTITY_DOCUMENTS = 'entity documents',
    ENTITY_DOCUMENT_BREAKDOWN = 'entity document breakdown',
    ENTITY_DATASETS = 'entity documents datasets',
    ENTITY_DATASET_FIELD = 'entity documents dataset field'
}

export interface EntityJurisdictionAnalytics extends BaseEntity {
    jurisdiction: string | null;
    selectedMyCompanyEntity: number | null;
    documents: number;
}

interface CountryProperty {
    name: string;
}

interface CountryGeometry {
    type: string;
    coordinates: number | number[] | number[][] | number[][][] | number[][][][];
}

export interface Country {
    id: string;
    type: string;
    properties: CountryProperty;
    geometry: CountryGeometry;
    value?: number;
    entities?: CountryEntity[];
}

export interface CountryEntity {
    name: string;
    entityId: number;
    onWatchlist?: number;
}

export interface CountryEntityInformation {
    name: string;
    entities: CountryEntity[];
}

export interface BaseArkDocument {
    documentId: number;
    documentDescription: string;
    documentName: string;
    entityAName: string;
    entityBName: string;
}

export type DocumentInstanceRiskConfig = DropdownRiskConfig | NumberRiskConfig | CheckboxRiskConfig | DateRiskConfig | TimeRiskConfig;
export interface DocumentInstanceRiskField extends BaseRiskField {
    value: FieldValue;
    riskScore: number;
    riskLevel: number;
    dropdownLinked: string;
    type: DatasetFieldType;
    riskConfig: DocumentInstanceRiskConfig;
}

export interface TableInstanceRiskField {
    rowId: string;
    rowLabel: string;
    riskFields: DocumentInstanceRiskField[];
}

export interface DocumentDatasetRiskTolerance {
    datasetId: number;
    type: DatasetType;
    includedFields: (DocumentInstanceRiskField | TableInstanceRiskField)[];
    datasetRiskRating: number;
    isClientRiskTolerance: boolean;
}

export interface DocumentRiskTolerance {
    documentRiskToleranceId?: number;
    originalDocumentId: number;
    documentRiskFields: DocumentDatasetRiskTolerance[];
    riskRating: number;
    maxRating: number;
    percentage: number;
    documentName: string;
    documentDescription: string;
    entityAName: string | null;
    entityBName: string | null;
    dateChecked?: string;
}

export interface AllDocumentsRiskTolerance {
    overallPercentage: number;
    totalDocuments: number;
}

export interface TableDocumentsRiskToleranceDB {
    originalDocumentId: number;
    datasetInstanceId: number;
    documentName: string;
    documentDescription: string;
    partyA: string | null;
    partyB: string | null;
    riskRating: string;
}

export interface TableDocumentsRiskTolerance {
    documents: TableDocumentsRiskToleranceDB[];
    total: number;
}

export interface DocumentRiskBreakdown {
    datasetType: DatasetType;
    datasetId: number;
    fieldId: string;
    fieldType: DatasetFieldType;
    label: string;
    formattedValue: string;
    weighting: number;
    riskLevel: number;
    sectionId?: string | null;
    rowId?: string;
    rowLabel?: string;
}

export interface PaginatedDatasetFieldRiskTolerance {
    documents: DocumentFieldRiskToleranceAnalytics[];
    total: number;
    overallPercentage: number;
}

export interface DatasetFieldRiskTolerance {
    fieldLabel: string;
    rowLabel?: string;
    fieldPercentage: number;
    fieldValue: FieldValue;
    fieldType: DatasetFieldType;
}

export interface DocumentFieldRiskToleranceAnalytics {
    datasetType: DatasetType;
    documentRiskToleranceId: number;
    originalDocumentId: number;
    datasetInstanceId: number;
    documentName: string;
    documentDescription: string;
    partyA: string | null;
    partyB: string | null;
    riskTolerance: DatasetFieldRiskTolerance[];
}

export interface DocumentAnalyticsState {
    tileInView: DocumentAnalyticsChart | null;
    documentSpread: PieChartAnalyticsData[];
    entitySpread: PieChartAnalyticsData[];
    entityJurisdiction: EntityJurisdictionAnalytics[];
    jurisdictionDocuments: BaseArkDocument[];
    totalDocuments: number;
    documentPage: number;
    fetchingAnalytics: DocumentAnalyticsChart[];
    fetchingMyCompanies: boolean;
    analyticsError: string | null;
    selectedCountry: string | null;
    countryEntities: CountryEntityInformation | null
    countryEntityModalOpen: boolean;
    selectedMyCompanyEntityId: number | null;
    availableAnalyticsEntities: DropdownOption[];
    documentRiskTolerance: DocumentRiskTolerance | null;
    documentRiskToleranceView: DocumentRiskView;
    entityDocumentRiskToleranceView: EntityDocumentRiskView;
    preExecutionDocumentRiskToleranceView: PreExecutionDocumentRiskView;
    documentRiskToleranceLoading: boolean;
    allDocumentsRiskTolerance: AllDocumentsRiskTolerance;
    entityDocumentsRiskTolerance: AllDocumentsRiskTolerance;
    preExecutionDocumentsRiskTolerance: AllDocumentsRiskTolerance;
    allDatasetsRiskTolerance: RiskToleranceDataset[];
    fetchingRiskToleranceDocuments: boolean;
    paginatedRiskToleranceDocuments: TableDocumentsRiskToleranceDB[];
    totalPaginatedRiskToleranceDocuments: number;
    riskToleranceDocumentsPage: number;
    riskToleranceDocumentsPageSize: number;
    riskToleranceDocumentsFilters: TableFilters;
    riskToleranceDocumentsSort: ColumnSort | undefined;
    selectedRiskToleranceParentDatasetId: number | null;
    selectedRiskToleranceChildDatasetId: number | null;
    selectedRiskToleranceDatasetFieldId: string | null;
    paginatedDatasetFieldRiskTolerance: DocumentFieldRiskToleranceAnalytics[];
    paginatedDatasetFieldRiskToleranceTotal: number;
    paginatedDatasetFieldRiskToleranceOverallPercentage: number
    documentRiskFieldTolerance: DocumentFieldRiskToleranceAnalytics | null;
    riskToleranceDatasetFieldPage: number;
    riskToleranceDatasetFieldPageSize: number;
    riskToleranceDatasetFieldFilters: TableFilters;
    riskToleranceDatasetFieldSort: ColumnSort | undefined;
}
