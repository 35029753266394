import { isUndefined, min } from 'lodash/fp';
import React, { useCallback } from 'react';
import classnames from 'classnames';

import { useWindowResize } from '../../../hooks/useWindowResize';
import { Position, PositionModal } from '../../shared/modal/PositionModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import styles from './DatasetInstance.module.scss';
import { OpenFieldType, SearchFieldSection } from './store';
import { Icon } from '../../shared/icon/Icon';
import { Section } from '../../shared/icons';
import { fieldListIcons } from '../../admin/dataset-builder/FieldList';

interface FuzzyFieldMatchModalProps {
    isOpen: boolean;
    position: Position;
    inputWidth: number;
    selectMatch: (value: SearchFieldSection) => void;
    setFuzzyMatchIndex: (index: number) => void;
    closeModal: () => void;
    fuzzyMatches: SearchFieldSection[];
    searchValue: string;
    fuzzyMatchIndex: number;
}

export const FuzzyFieldMatchModal: React.FC<FuzzyFieldMatchModalProps> = ({ isOpen, position, inputWidth, selectMatch, fuzzyMatches, searchValue, closeModal, fuzzyMatchIndex, setFuzzyMatchIndex }) => {
    const [, screenHeight] = useWindowResize();
    const maxScrollableHeight = min([(screenHeight - position.y) - 60, 150])!;

    const getDisplayValue = useCallback((value: string, index: number, fontSize: string) => {
        const indexOfSearch = value.toLowerCase().indexOf(searchValue.toLowerCase());
        if (indexOfSearch >= 0) {
            const indexOfSearchValueEnd = indexOfSearch + searchValue.length;
            return (
                <div className={styles.fuzzyMatchTitle} style={{ fontSize }}>
                    <span>{value.slice(0, indexOfSearch)}</span>
                    <span className={styles.boldHighlight}>{value.slice(indexOfSearch, indexOfSearchValueEnd)}</span>
                    <span>{value.slice(indexOfSearchValueEnd)}</span>
                </div>
            );
        }
        return <div className={styles.fuzzyMatchTitle} style={{ fontSize }}>{value}</div>;
    }, [searchValue]);

    const getFuzzyMatchComponent = useCallback((match: SearchFieldSection, index: number) => {
        if (match.type === OpenFieldType.SECTION) {
            return (
                <div
                    key={index}
                    data-testid={`dataset-instance-field-match-${index}`}
                    id={`dataset-instance-field-match-${index}`}
                    className={classnames(styles.fuzzyMatchWrapper, { [styles.selectedFuzzyMatch]: index === fuzzyMatchIndex })}
                    onClick={() => selectMatch(match)}
                    onMouseOver={() => setFuzzyMatchIndex(index)}
                >
                    <div className={styles.fuzzyMatchIconWrapper}><Icon icon={Section} fontSize={20} /></div>
                    <div className={styles.fuzzyMatch}>
                        {getDisplayValue(match.sectionLabel, index, '14px')}
                    </div>
                </div>
            );
        }
        return (
            <div
                key={index}
                data-testid={`dataset-instance-field-match-${index}`}
                id={`dataset-instance-field-match-${index}`}
                className={classnames(styles.fuzzyMatchWrapper, { [styles.selectedFuzzyMatch]: index === fuzzyMatchIndex })}
                onClick={() => selectMatch(match)}
                onMouseOver={() => setFuzzyMatchIndex(index)}
            >
                <div className={styles.fuzzyMatchIconWrapper}><Icon icon={fieldListIcons[match.fieldType]} fontSize={20} /></div>
                <div className={styles.fuzzyMatch}>
                    {!isUndefined(match.sectionLabel) && <div className={styles.sectionLabel}>{getDisplayValue(match.sectionLabel, index, '14px')}</div>}
                    {getDisplayValue(match.label, index, '13px')}
                </div>
            </div>
        );
    }, [fuzzyMatchIndex, getDisplayValue, selectMatch, setFuzzyMatchIndex]);

    return (
        <PositionModal
            isOpen={isOpen}
            position={position}
            height='fit-content'
            width={`${inputWidth}px`}
            closeModal={closeModal}
            testId='dataset-instance-field-match'
            overflow='hidden'
            padding='0px'
        >
            <div className={styles.fuzzyFieldMatchModalWrapper}>
                <div className={styles.fuzzyMatchesWrapper}>
                    <Scrollable maxHeight={`${maxScrollableHeight}px`}>
                        {fuzzyMatches.map((value, index) => getFuzzyMatchComponent(value, index))}
                    </Scrollable>
                </div>
            </div>
        </PositionModal >
    );
};
