import React, { useMemo } from 'react';
import Modal from 'react-modal';
import { noop } from 'lodash/fp';

import { Button } from '../../shared/button/Button';
import { Calculator } from '../../shared/calculator/Calculator';
import { CalculatorField, CalculatorFieldType } from '../../shared/calculator/constants';
import styles from './Fields.module.scss';
import { useWindowResize } from '../../../hooks/useWindowResize';

interface CalculatorReadOnlyModalProps {
    isOpen: boolean;
    closeModal: () => void;
    disabled: boolean;
    fieldType: CalculatorFieldType;
    value: CalculatorField[];
    borderColor: string;
    showOutputField: boolean;
    showGracePeriod: boolean;
    includeFreeTextOption: boolean;
}

export const CalculatorReadOnlyModal: React.FC<CalculatorReadOnlyModalProps> = ({
    closeModal,
    isOpen,
    disabled,
    fieldType,
    value,
    borderColor,
    showOutputField,
    showGracePeriod,
    includeFreeTextOption
}) => {
    const [screenWidth, screenHeight] = useWindowResize();
    const maxHeight = useMemo(() => (screenHeight * 0.6) - 48, [screenHeight]);
    const width = useMemo(() => (screenWidth * 0.6) - 20, [screenWidth]);
    return (
        <Modal
            isOpen={isOpen}
            className={styles.readOnlyCalculatorModal}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={true}
            style={{ overlay: { display: 'flex', zIndex: 11 } }}
        >
            <div className={styles.calculatorReadOnlyModalWrapper}>
                <Calculator
                    value={value}
                    updateValue={noop}
                    isEditing={!disabled}
                    fieldType={fieldType}
                    width={width}
                    borderColor={borderColor}
                    maxHeight={maxHeight}
                    showOutputField={showOutputField}
                    showGracePeriod={showGracePeriod}
                    includeFreeTextOption={includeFreeTextOption}
                />
                <div className={styles.closeModalWrapper}>
                    <Button onClick={closeModal} label='Cancel' />
                </div>
            </div>
        </Modal>
    );
};
