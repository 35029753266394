import { action } from 'typesafe-actions';

import { AnalysisView } from '../../../documents/my-documents/store';
import { ArkOpinion, MyOpinionActionTypes, OpinionsTableView, ArkMapOpinion, LinkedOpinion, BritishOpinions, CountryOpinions, OpinionsView } from './types';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { OpinionCommissionedBy, OpinionScope } from '../../../admin/opinions/store';

export const toggleOpinionsView = (view: OpinionsView) => action(MyOpinionActionTypes.TOGGLE_OPINIONS_VIEW, view);

export const toggleOpinionsTableView = (view: OpinionsTableView) => action(MyOpinionActionTypes.TOGGLE_OPINIONS_TABLE_VIEW, view);

export const toggleOpinionsModal = (isOpen: boolean) => action(MyOpinionActionTypes.TOGGLE_OPINION_MODAL, isOpen);

export const setUploadOpinions = (files: File[]) => action(MyOpinionActionTypes.SET_INITIAL_UPLOAD_OPINIONS, files);

export const uploadOpinionStarted = () => action(MyOpinionActionTypes.UPLOAD_OPINION_STARTED);
export const uploadOpinionSuccessful = (opinions: ArkOpinion[]) => action(MyOpinionActionTypes.UPLOAD_OPINION_SUCCESSFUL, opinions);
export const uploadOpinionFailed = (error: string) => action(MyOpinionActionTypes.UPLOAD_OPINION_FAILED, error);

export const uploadSupportOpinionStarted = () => action(MyOpinionActionTypes.UPLOAD_SUPPORT_OPINION_STARTED);
export const uploadSupportOpinionSuccessful = () => action(MyOpinionActionTypes.UPLOAD_SUPPORT_OPINION_SUCCESSFUL);
export const uploadSupportOpinionFailed = (error: string) => action(MyOpinionActionTypes.UPLOAD_SUPPORT_OPINION_FAILED, error);

export const fetchOpinionsStarted = (pageNumber = 1) => action(MyOpinionActionTypes.FETCH_OPINIONS_STARTED, pageNumber);
export const fetchOpinionsSuccessful = (opinions: ArkOpinion[], total?: number, pageNumber?: number) => action(MyOpinionActionTypes.FETCH_OPINIONS_SUCCESSFUL, { opinions, total, pageNumber });
export const fetchOpinionsFailed = (error: string) => action(MyOpinionActionTypes.FETCH_OPINIONS_FAILED, error);

export const toggleOpinionDetailsModal = (isOpen: boolean) => action(MyOpinionActionTypes.TOGGLE_OPINION_DETAILS_MODAL, isOpen);

export const openIncompleteOpinionStarted = (opinion: ArkOpinion) => action(MyOpinionActionTypes.OPEN_INCOMPLETE_OPINION_STARTED, opinion);
export const openIncompleteOpinionSuccessful = (opinionUrl: string, opinion: ArkOpinion) => action(MyOpinionActionTypes.OPEN_INCOMPLETE_OPINION_SUCCESSFUL, { opinionUrl, opinion });
export const openIncompleteOpinionFailed = (error: string) => action(MyOpinionActionTypes.OPEN_INCOMPLETE_OPINION_FAILED, error);

export const incompleteOpinionUpdateValue = (key: keyof ArkOpinion, value: string | string[] | Date | null | number | number[] | OpinionCommissionedBy | OpinionScope) => action(MyOpinionActionTypes.INCOMPLETE_OPINION_UPDATE_VALUE, { key, value });

export const opinionDetailsHaveUpdated = (haveUpdated: boolean) => action(MyOpinionActionTypes.INCOMPLETE_OPINION_DETAILS_UPDATED, haveUpdated);

export const updateOpinionDetailsStarted = () => action(MyOpinionActionTypes.UPDATE_OPINION_DETAILS_STARTED);
export const updateOpinionDetailsSuccessful = (opinions: ArkOpinion[]) => action(MyOpinionActionTypes.UPDATE_OPINION_DETAILS_SUCCESSFUL, opinions);
export const updateOpinionDetailsFailed = (error: string) => action(MyOpinionActionTypes.UPDATE_OPINION_DETAILS_FAILED, error);

export const skipIncompleteOpinion = () => action(MyOpinionActionTypes.SKIP_INCOMPLETE_OPINION);

export const fetchIncompleteOpinionsStarted = (pageNumber = 1) => action(MyOpinionActionTypes.FETCH_INCOMPLETE_OPINIONS_STARTED, pageNumber);
export const fetchIncompleteOpinionsSuccessful = (incompleteOpinions: ArkOpinion[], total?: number, pageNumber?: number) => action(MyOpinionActionTypes.FETCH_INCOMPLETE_OPINIONS_SUCCESSFUL, { incompleteOpinions, total, pageNumber });
export const fetchIncompleteOpinionsFailed = (error: string) => action(MyOpinionActionTypes.FETCH_INCOMPLETE_OPINIONS_FAILED, error);

export const setSelectedIncompleteOpinions = (opinions: ArkOpinion[]) => action(MyOpinionActionTypes.SET_SELECTED_INCOMPLETE_OPINIONS, opinions);

export const openOpinionStarted = (location: string, mimeType: string, opinionId: number, instanceId?: string) => action(MyOpinionActionTypes.OPEN_OPINION_STARTED, { location, mimeType, opinionId, instanceId });
export const openOpinionSuccessful = (opinionUrl: string, opinion: ArkOpinion) => action(MyOpinionActionTypes.OPEN_OPINION_SUCCESSFUL, { opinionUrl, opinion });
export const openOpinionFailed = (error: string) => action(MyOpinionActionTypes.OPEN_OPINION_FAILED, error);

export const resetAllOpinions = () => action(MyOpinionActionTypes.RESET_ALL_OPINIONS);

export const openOpinionAndInstance = (opinionId: string, datasetInstanceId?: string) => action(MyOpinionActionTypes.OPEN_OPINION_AND_INSTANCE, { opinionId, datasetInstanceId });

export const downloadOpinionStarted = (location: string, mimeType: string, description: string) => action(MyOpinionActionTypes.DOWNLOAD_OPINION_STARTED, { location, mimeType, description });
export const downloadOpinionSuccessful = () => action(MyOpinionActionTypes.DOWNLOAD_OPINION_SUCCESSFUL);
export const downloadOpinionFailed = (error: string) => action(MyOpinionActionTypes.DOWNLOAD_OPINION_FAILED, error);

export const toggleDeleteConfirmationModal = (opinionToDelete: ArkOpinion | null) => action(MyOpinionActionTypes.TOGGLE_DELETE_OPINION_MODAL, opinionToDelete);
export const deleteOpinionStarted = (location: string) => action(MyOpinionActionTypes.DELETE_OPINION_STARTED, location);
export const deleteOpinionSuccessful = () => action(MyOpinionActionTypes.DELETE_OPINION_SUCCESSFUL);
export const deleteOpinionFailed = (error: string) => action(MyOpinionActionTypes.DELETE_OPINION_FAILED, error);

export const downloadAllOpinionsStarted = (locations: string[], description: string) => action(MyOpinionActionTypes.DOWNLOAD_ALL_OPINIONS_STARTED, { locations, description });
export const downloadAllOpinionsFailed = (error: string) => action(MyOpinionActionTypes.DOWNLOAD_ALL_OPINIONS_FAILED, error);
export const downloadAllOpinionsSuccessful = () => action(MyOpinionActionTypes.DOWNLOAD_ALL_OPINIONS_SUCCESSFUL);

export const downloadOpinionSummaryStarted = (opinion: ArkOpinion) => action(MyOpinionActionTypes.DOWNLOAD_OPINION_SUMMARY_STARTED, opinion);
export const downloadOpinionSummaryFailed = (error: string) => action(MyOpinionActionTypes.DOWNLOAD_OPINION_SUMMARY_FAILED, error);
export const downloadOpinionSummarySuccessful = () => action(MyOpinionActionTypes.DOWNLOAD_OPINION_SUMMARY_SUCCESSFUL);

export const enquireOpinionStarted = (opinionId: number) => action(MyOpinionActionTypes.ENQUIRE_OPINION_STARTED, opinionId);
export const enquireOpinionFailed = (error: string) => action(MyOpinionActionTypes.ENQUIRE_OPINION_FAILED, error);
export const enquireOpinionSuccessful = () => action(MyOpinionActionTypes.ENQUIRE_OPINION_SUCCESSFUL);

export const toggleOpinionAnalysisView = (view: AnalysisView) => action(MyOpinionActionTypes.TOGGLE_OPINION_ANALYSIS_VIEW, view);

export const setLinkedOpinions = (linkedOpinions: LinkedOpinion[]) => action(MyOpinionActionTypes.SET_LINKED_OPINIONS, linkedOpinions);

export const setUnauthorisedOpinion = (opinion: LinkedOpinion | ArkOpinion | null) => action(MyOpinionActionTypes.SET_UNAUTHORISED_OPINION, opinion);

export const openAdditionalOpinionsStarted = (opinionIds: number[]) => action(MyOpinionActionTypes.OPEN_ADDITIONAL_OPINIONS_STARTED, opinionIds);
export const openAdditionalOpinionsSuccessful = (additionalOpinions: ArkOpinion[], urls: string[]) => action(MyOpinionActionTypes.OPEN_ADDITIONAL_OPINIONS_SUCCESSFUL, { urls, additionalOpinions });
export const openAdditionalOpinionsFailed = (error: string) => action(MyOpinionActionTypes.OPEN_ADDITIONAL_OPINIONS_FAILED, error);

export const setAdditionalOpinionSelected = (opinionId: number | null) => action(MyOpinionActionTypes.SET_ADDITIONAL_OPINION_SELECTED, opinionId);

export const setSelectedOpinionPage = (pageNumber: number | null) => action(MyOpinionActionTypes.SET_SELECTED_OPINION_PAGE, pageNumber);

export const setPotentialBaseOpinion = (opinion: ArkOpinion | null) => action(MyOpinionActionTypes.SET_POTENTIAL_BASE_OPINION, opinion);
export const assertPotentialBaseOpinionStarted = (isBaseOpinion: boolean) => action(MyOpinionActionTypes.ASSERT_POTENTIAL_BASE_OPINION_STARTED, isBaseOpinion);
export const assertPotentialBaseOpinionSuccessful = () => action(MyOpinionActionTypes.ASSERT_POTENTIAL_BASE_OPINION_SUCCESSFUL);
export const assertPotentialBaseOpinionFailed = (error: string) => action(MyOpinionActionTypes.ASSERT_POTENTIAL_BASE_OPINION_FAILED, error);

export const toggleOpinionSummaryPreviewOpen = (isOpen: boolean) => action(MyOpinionActionTypes.TOGGLE_OPINION_SUMMARY_PREVIEW_OPEN, isOpen);

export const openOpinionSummaryStarted = (opinionSummaryId: number) => action(MyOpinionActionTypes.OPEN_OPINION_SUMMARY_STARTED, opinionSummaryId);
export const openOpinionSummarySuccessful = (opinionUrl: string) => action(MyOpinionActionTypes.OPEN_OPINION_SUMMARY_SUCCESSFUL, opinionUrl);
export const openOpinionSummaryFailed = (error: string) => action(MyOpinionActionTypes.OPEN_OPINION_SUMMARY_FAILED, error);

export const setOpinionSummaryGenerating = (value: boolean) => action(MyOpinionActionTypes.SET_OPINION_SUMMARY_GENERATING, value);
export const setUpdatedOpinionSummaryId = (opinionId: number, opinionSummaryId: number) => action(MyOpinionActionTypes.SET_UPDATED_OPINION_SUMMARY_ID, { opinionId, opinionSummaryId });

export const setSelectedOpinionJurisdiction = (jurisdiction: string | null) => action(MyOpinionActionTypes.SET_SELECTED_OPINIONS_JURISDICTION, jurisdiction);

// Table Filters and Pagination
export const setOpinionTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(MyOpinionActionTypes.SET_OPINION_TABLE_FILTERS, { key, value, type });
export const clearOpinionTableFilters = (resetSelectedFilterId = true) => action(MyOpinionActionTypes.CLEAR_OPINION_TABLE_FILTERS, resetSelectedFilterId);
export const setOpinionTableColumnSort = (columnSort: ColumnSort | undefined) => action(MyOpinionActionTypes.SET_OPINION_TABLE_COLUMN_SORT, columnSort);
export const opinionsPaginationNext = () => action(MyOpinionActionTypes.OPINIONS_PAGINATION_NEXT);
export const opinionsPaginationPrevious = () => action(MyOpinionActionTypes.OPINIONS_PAGINATION_PREVIOUS);
export const setOpinionsPageSize = (pageSize: number) => action(MyOpinionActionTypes.SET_OPINIONS_PAGE_SIZE, pageSize);

export const setIncompleteOpinionTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(MyOpinionActionTypes.SET_INCOMPLETE_OPINION_TABLE_FILTERS, { key, value, type });
export const clearIncompleteOpinionTableFilters = () => action(MyOpinionActionTypes.CLEAR_INCOMPLETE_OPINION_TABLE_FILTERS);
export const incompleteOpinionsPaginationNext = () => action(MyOpinionActionTypes.INCOMPLETE_OPINIONS_PAGINATION_NEXT);
export const incompleteOpinionsPaginationPrevious = () => action(MyOpinionActionTypes.INCOMPLETE_OPINIONS_PAGINATION_PREVIOUS);
export const setIncompleteOpinionsPageSize = (pageSize: number) => action(MyOpinionActionTypes.SET_INCOMPLETE_OPINIONS_PAGE_SIZE, pageSize);
export const setIncompleteOpinionTableColumnSort = (columnSort: ColumnSort | undefined) => action(MyOpinionActionTypes.SET_INCOMPLETE_OPINION_TABLE_COLUMN_SORT, columnSort);

// Opinion Map functions

export const fetchAllOpinionsByJurisdictionStarted = () => action(MyOpinionActionTypes.FETCH_ALL_OPINIONS_BY_JURISDICTION_STARTED);
export const fetchAllOpinionsByJurisdictionSuccessful = (opinions: ArkMapOpinion[]) => action(MyOpinionActionTypes.FETCH_ALL_OPINIONS_BY_JURISDICTION_SUCCESSFUL, opinions);
export const fetchAllOpinionsByJurisdictionFailed = (error: string) => action(MyOpinionActionTypes.FETCH_ALL_OPINIONS_BY_JURISDICTION_FAILED, error);

export const setCountryOpinions = (value: CountryOpinions | null) => action(MyOpinionActionTypes.SET_COUNTRY_OPINIONS, value);

export const resetOpinionsMapZoom = (value: boolean) => action(MyOpinionActionTypes.RESET_OPINIONS_MAP_ZOOM, value);
export const setOpinionsMapZoomSteps = (value: number) => action(MyOpinionActionTypes.SET_OPINIONS_MAP_ZOOM_STEPS, value);
export const setOpinionsMapZoomUpdated = (value: boolean) => action(MyOpinionActionTypes.SET_OPINIONS_MAP_ZOOM_UPDATED, value);
export const setOpinionsMapCurrentScale = (value: number) => action(MyOpinionActionTypes.SET_OPINIONS_MAP_CURRENT_SCALE, value);

export const setBritishOpinions = (britishOpinions: BritishOpinions | null) => action(MyOpinionActionTypes.SET_BRITISH_OPINIONS, britishOpinions);

// Opinion Custom Filters
export const toggleOpinionCustomFiltersModal = (isOpen: boolean) => action(MyOpinionActionTypes.TOGGLE_OPINION_CUSTOM_FILTERS_MODAL, isOpen);
export const updateOpinionCustomFilters = (label: string, createNew: boolean) => action(MyOpinionActionTypes.UPDATE_OPINION_CUSTOM_FILTERS, { label, createNew });
export const setSelectedOpinionCustomFilterId = (value: string | null) => action(MyOpinionActionTypes.SET_SELECTED_OPINION_CUSTOM_FILTER_ID, value);
export const setOpinionCustomFilterHasUpdated = (value: boolean) => action(MyOpinionActionTypes.SET_OPINION_CUSTOM_FILTER_HAS_UPDATED, value);
