import classnames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Icon } from '../../../shared/icon/Icon';
import { Document } from '../../../shared/icons';
import { Text } from '../../../shared/text/Text';
import { SupportingUploadDocument, getOriginalDocument, getSecondaryDocument, uploadDocumentUpdateValue } from '../store';
import styles from './UploadDocument.module.scss';

interface SupportDocumentProps {
    uploadDocument: SupportingUploadDocument;
    disabled?: boolean;
}

export const SupportDocument: React.FC<SupportDocumentProps> = ({ disabled = false, uploadDocument }) => {
    const dispatch = useAppDispatch();
    const { description, file } = uploadDocument;

    const setDocumentDescription = (description: string) => dispatch(uploadDocumentUpdateValue('description', description));

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({ disabled, maxFiles: 1, maxSize: 10485760, accept: ['.pdf', '.doc', '.docx', '.tif'] });

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length) {
            dispatch(uploadDocumentUpdateValue('file', acceptedFiles));
        }
    }, [acceptedFiles, dispatch]);

    const documentToUpload = file ? `${file[0].name} - ${(file[0].size / 1e6).toFixed(2)}MB` : 'None';
    const dropzoneText = useMemo(() => {
        if (isDragActive) {
            return 'Drop document here';
        }
        if (acceptedFiles.length) {
            return 'Document successfully added, click here or drop a new document to override';
        }
        return 'Drag \'n\' drop documents here, or click to select files';
    }, [isDragActive, acceptedFiles]);

    const originalDocument = useAppSelector(getOriginalDocument);
    const secondaryDocument = useAppSelector(getSecondaryDocument);

    const baseDocumentDescription = useMemo(() => {
        if (secondaryDocument) {
            return secondaryDocument.documentDescription;
        }
        return originalDocument ? originalDocument.documentDescription : '';
    }, [originalDocument, secondaryDocument]);

    return (
        <>
            <Text
                label='Document Description'
                onChange={e => setDocumentDescription(e.target.value)}
                value={description}
                maxLength={256}
                testId='upload-document-modal-description'
            />
            <div {...getRootProps({ className: classnames(styles.dropzone, { [styles.dropzoneDisabled]: disabled }) })}>
                <input {...getInputProps()} />
                <div className={styles.dropzonePlaceholder}>{dropzoneText}</div>
            </div>
            <div className={styles.selectedDocumentWrapper}>
                <div className={styles.selectedDocumentHeader}>Document to be uploaded</div>
                <div className={styles.selectedDocument}>{documentToUpload}</div>
            </div>
            <div className={styles.baseDocumentHeader}>
                <Icon icon={Document} />
                <div data-testid='support-document-link' className={styles.baseDocumentTitle}>
                    {baseDocumentDescription}
                </div>
            </div>
        </>
    );
};
