import React, { useCallback, useMemo, useState } from 'react';
import { isEqual, isNull } from 'lodash/fp';

import styles from './SupplyChain.module.scss';
import { SupplyChainCard } from './SupplyChainCard';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { DoraSupplyChainTableColumns, fetchAllFunctionCompanyDetailsStarted, getCompanyFunctionFilters, getDoraSupplyChainCompanies, getDoraSupplyChainFunctions, getIsFetchingDoraFunctionCompanyDetails, getSelectedCompanyId, getSelectedFunctionId, setCompanyFunctionFilters, setSelectedCompanyId, setSelectedFunctionId } from './store';
import { Spinner } from '../../shared/spinner/Spinner';
import Logo from '../../../assets/logos/Ark51_VERTBLACK&BLACK.png';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { IconButton } from '../../shared/button/IconButton';
import { Filter } from '../../shared/icons';
import { Position } from '../../shared/modal/PositionModal';
import { TableFilterModal, TableFilterType } from '../../shared/modal/TableFilterModal';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { HEIGHT_OF_THE_WIZARD } from '../my-companies/MyCompaniesInScopeForDora';
import { DataManagementTile, setSelectedTab } from '../data-management/store';

const { white } = styles;

interface EmptyTableProps {
    onClick: () => void;
}

const EmptyTable: React.FC<EmptyTableProps> = ({ onClick }) => (
    <div className={styles.emptyTable}>
        <div className={styles.emptyPlaceholder}>
            <button onClick={onClick} className={styles.emptyTableButton}>Your in scope companies are currently not performing any functions. Please add a function to one of your companies to begin its supply chain</button>
            <img className={styles.logoWrapper} src={Logo} />
        </div>
    </div>
);

export const SupplyChainTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllFunctionCompanyDetailsStarted()]);
    const [screenWidth, screenHeight] = useWindowResize();

    const [filterFieldOpen, setFilterFieldOpen] = useState<DoraSupplyChainTableColumns | null>(null);
    const [position, setPosition] = useState<Position | null>(null);

    const supplyChainCompanies = useAppSelector(getDoraSupplyChainCompanies);
    const supplyChainFunctions = useAppSelector(getDoraSupplyChainFunctions);

    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const selectedFunctionId = useAppSelector(getSelectedFunctionId);

    const isLoading = useAppSelector(getIsFetchingDoraFunctionCompanyDetails);
    const filters = useAppSelector(getCompanyFunctionFilters);

    const openFilterModal = (x: number, y: number, column: DoraSupplyChainTableColumns) => {
        setPosition({ x, y });
        setFilterFieldOpen(column);
    };

    const closeFilterModal = () => {
        setPosition(null);
        setFilterFieldOpen(null);
    };

    const columnFilterExists = useCallback((field: string) => !!filters[field] && (!isNull(filters[field].dropdown) || filters[field].text.length > 0), [filters]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => {
        dispatch(setCompanyFunctionFilters(key, value, type));
        dispatch(fetchAllFunctionCompanyDetailsStarted());
    }, [dispatch]);

    const setSelectedSupplyChainCompany = useCallback((companyId: number) => {
        const selectedSupplyChainCompany = isEqual(selectedCompanyId, companyId) ? null : companyId;
        dispatch(setSelectedCompanyId(selectedSupplyChainCompany));
    }, [dispatch, selectedCompanyId]);

    const setSelectedSupplyChainFunction = useCallback((doraFunctionId: number) => {
        const selectedSupplyChainFunction = isEqual(selectedFunctionId, doraFunctionId) ? null : doraFunctionId;
        dispatch(setSelectedFunctionId(selectedSupplyChainFunction));
    }, [dispatch, selectedFunctionId]);

    const tableHeight = useMemo(() => (screenHeight * 0.75) - HEIGHT_OF_THE_WIZARD, [screenHeight]);
    const tableWidth = useMemo(() => (screenWidth * 0.85) - 2, [screenWidth]);

    const redirectToMyCompanies = useCallback(() => dispatch(setSelectedTab(DataManagementTile.MY_COMPANIES, true)), [dispatch]);

    const body = useMemo(() => {
        if (isLoading) {
            return <Spinner />;
        }

        if (!supplyChainCompanies.length && !supplyChainFunctions.length) {
            return (
                <EmptyTable onClick={redirectToMyCompanies} />
            );
        }

        return (
            <>
                <Scrollable>
                    <div className={styles.tableColumn}>
                        {supplyChainCompanies.map(company => {
                            const { value, label } = company;
                            return <SupplyChainCard key={value} label={label} onClick={() => setSelectedSupplyChainCompany(value)} isSelected={value === selectedCompanyId} />;
                        })}
                    </div>
                </Scrollable>
                <div className={styles.rowBorder} />
                <Scrollable>
                    <div className={styles.tableColumn}>
                        {supplyChainFunctions.map(doraFunction => {
                            const { value, label } = doraFunction;
                            return <SupplyChainCard key={value} label={label} onClick={() => setSelectedSupplyChainFunction(value)} isSelected={value === selectedFunctionId} />;
                        })}
                    </div>
                </Scrollable>
            </>
        );
    }, [isLoading, supplyChainCompanies, supplyChainFunctions, setSelectedSupplyChainCompany, setSelectedSupplyChainFunction, selectedCompanyId, selectedFunctionId, redirectToMyCompanies]);

    return (
        <div className={styles.supplyChainTableWrapper} style={{ height: tableHeight, width: tableWidth }}>
            <div className={styles.tableHeader}>
                <div className={styles.tableHeaderCell}>
                    <div className={styles.tableHeaderName}>
                        Companies In Scope
                    </div>
                    <div className={styles.tableHeaderIcons}>
                        <IconButton icon={Filter} onClick={e => openFilterModal(e.clientX, e.clientY, DoraSupplyChainTableColumns.COMPANY)} color={white} fontSize={20} testId={'dora-supply-chain-companies-filter'} margin={'0 5px 0 0'} showIndicator={columnFilterExists(DoraSupplyChainTableColumns.COMPANY)} />
                    </div>
                </div>
                <div className={styles.tableHeaderCell}>
                    <div className={styles.tableHeaderName}>
                        Functions Performed
                    </div>
                    <div className={styles.tableHeaderIcons}>
                        <IconButton icon={Filter} onClick={e => openFilterModal(e.clientX, e.clientY, DoraSupplyChainTableColumns.FUNCTION)} color={white} fontSize={20} testId={'dora-supply-chain-companies-filter'} margin={'0 5px 0 0'} showIndicator={columnFilterExists(DoraSupplyChainTableColumns.FUNCTION)} />
                    </div>
                </div>
            </div>
            {filterFieldOpen && !isNull(position) &&
                <TableFilterModal
                    position={position}
                    field={filterFieldOpen}
                    closeModal={closeFilterModal}
                    testId={'dora-supply-chain-filter'}
                    filters={filters}
                    updateFilter={updateFilter}
                    filterDropdownOptions={{ ['companyName']: [] }}
                />
            }
            <div className={styles.tableBody}>
                {body}
            </div>
        </div>
    );
};
