import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { AttestationConfirmationModal, getIsSaving, getSelectedAttestationForm, saveAttestationFormStarted, toggleAttestationConfirmationModal, toggleAttestationFormBuilderWizardOpen } from '../store';
import styles from './AttestationForms.module.scss';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Form, SaveFile } from '../../../shared/icons';

interface AttestationFormConfirmationModalProps {
    modalOpen: AttestationConfirmationModal;
}

export const AttestationFormConfirmationModal: React.FC<AttestationFormConfirmationModalProps> = ({ modalOpen }) => {
    const dispatch = useAppDispatch();
    const selectedAttestationForm = useAppSelector(getSelectedAttestationForm);
    const isSaving = useAppSelector(getIsSaving);

    const closeWizard = useCallback(() => dispatch(toggleAttestationFormBuilderWizardOpen(false)), [dispatch]);
    const closeConfirmation = useCallback(() => dispatch(toggleAttestationConfirmationModal(null)), [dispatch]);
    const saveAttestationForm = useCallback(() => dispatch(saveAttestationFormStarted()), [dispatch]);

    const { confirmLabel, confirm, close, testId, confirmDisabled } = useMemo(() => modalOpen === AttestationConfirmationModal.SAVE ? {
        confirmLabel: 'Save',
        close: closeConfirmation,
        confirm: saveAttestationForm,
        testId: 'save',
        confirmDisabled: isSaving
    } : {
        confirmLabel: 'Yes, Close',
        close: closeConfirmation,
        confirm: closeWizard,
        testId: 'close',
        confirmDisabled: false
    }, [closeConfirmation, closeWizard, saveAttestationForm, modalOpen, isSaving]);

    const { header, subheader } = useMemo(() => modalOpen === AttestationConfirmationModal.CLOSE ?
        { header: 'Are you sure you want to close?', subheader: 'Any unsaved changes will be lost' } :
        { header: 'Are you sure you want to save?', subheader: `${selectedAttestationForm?.attestationFormId ? 'Any changes made will affect incomplete attestations for all users' : ''}` }
    , [selectedAttestationForm, modalOpen]);

    const icon = modalOpen === AttestationConfirmationModal.SAVE ? SaveFile : Form;

    return (
        <ConfirmationModal
            isOpen
            closeModal={close}
            confirm={confirm}
            confirmLabel={confirmLabel}
            confirmDisabled={confirmDisabled}
            testId={`attestation-form-confirmation-${testId}-modal`}
        >
            <div className={styles.confirmationModalWrapper}>
                <ModalHeader
                    testId={`attestation-form-${testId}`}
                    label={`${modalOpen} Attestation Form`}
                    icon={icon}
                />
                <div className={styles.confirmationModalHeader}>{header}</div>
                <div className={styles.confirmationModalSubheader}>{subheader}</div>
            </div>
        </ConfirmationModal>
    );
};
