import { Socket } from 'socket.io-client';
import { isNull, isUndefined } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { UserRole } from '../../../constants/permittedRoles';
import { AlternateUserAccount, CustomFilters, DocumentCustomFilters, LoginState, OpinionCustomFilters, ReminderTypes, User } from './types';
import { ClientFeaturePermission, FeaturePermission } from '../../../admin/users/store';
import { HomeScreenConfig } from '../../../home/store';
import { defaultHomeScreenConfig } from '../../../constants/homeScreenConfig';
import { isDocumentCustomFilter, isOpinionCustomFilter } from './typeAssertions';

const getRoot = (state: ApplicationState): LoginState => state.auth.login;

export const getUser = (state: ApplicationState): User | null => getRoot(state).user;

export const getAllCustomFilters = (state: ApplicationState): CustomFilters[] => getUser(state)!.customFilters || [];

export const getAllDocumentCustomFilters = (state: ApplicationState): DocumentCustomFilters[] => getAllCustomFilters(state).filter(customFilter => isDocumentCustomFilter(customFilter)) as DocumentCustomFilters[];

export const getAllOpinionCustomFilters = (state: ApplicationState): OpinionCustomFilters[] => getAllCustomFilters(state).filter(customFilter => isOpinionCustomFilter(customFilter)) as OpinionCustomFilters[];

export const getUserHomeScreenConfig = (state: ApplicationState): HomeScreenConfig => getUser(state)?.config || defaultHomeScreenConfig;

export const getMyProfile = (state: ApplicationState): User | null => getRoot(state).profileDetails;

export const getUsername = (state: ApplicationState): string | undefined => {
    const user = getUser(state);
    if (user) {
        return user.username;
    }
};

export const getUserRole = (state: ApplicationState): UserRole | undefined => {
    const user = getUser(state);
    if (user) {
        return user.role;
    }
};

export const getUserFeaturePermissions = (state: ApplicationState): number[] => {
    const user = getUser(state);
    if (user) {
        return user.featurePermissions || [];
    }
    return [];
};

export const getClientFeaturePermissions = (state: ApplicationState): number[] => {
    const user = getUser(state);
    if (user) {
        return user.clientFeaturePermissionIds || [];
    }
    return [];
};

export const getIsAdmin = (state: ApplicationState): boolean => {
    const userRole = getUserRole(state);
    if (userRole) {
        return UserRole.SYSTEM_ADMIN === userRole || UserRole.ADMIN === userRole;
    }
    return false;
};

export const getIsSystemAdmin = (state: ApplicationState): boolean => {
    const userRole = getUserRole(state);
    if (userRole) {
        return UserRole.SYSTEM_ADMIN === userRole;
    }
    return false;
};

export const getUserHasFeaturePermission = (featurePermissions: FeaturePermission[]) => (state: ApplicationState): boolean => {
    const userFeaturePermissions = getUserFeaturePermissions(state);
    return featurePermissions.every(featurePermission => userFeaturePermissions.includes(featurePermission)) || getIsAdmin(state);
};

export const getUserHasOneFeaturePermission = (featurePermissions: FeaturePermission[]) => (state: ApplicationState): boolean => {
    const userFeaturePermissions = getUserFeaturePermissions(state);
    return featurePermissions.some(featurePermission => userFeaturePermissions.includes(featurePermission)) || getIsAdmin(state);
};

export const getUserHasFeaturePermissionNoAdmin = (featurePermissions: FeaturePermission[]) => (state: ApplicationState): boolean => {
    const userFeaturePermissions = getUserFeaturePermissions(state);
    return featurePermissions.every(featurePermission => userFeaturePermissions.includes(featurePermission)) || getIsSystemAdmin(state);
};

export const getUserHasOneFeaturePermissionNoAdmin = (featurePermissions: FeaturePermission[]) => (state: ApplicationState): boolean => {
    const userFeaturePermissions = getUserFeaturePermissions(state);
    return featurePermissions.some(featurePermission => userFeaturePermissions.includes(featurePermission)) || getIsSystemAdmin(state);
};

export const getClientHasFeaturePermission = (featurePermissions: ClientFeaturePermission[]) => (state: ApplicationState): boolean => {
    const clientFeaturePermissions = getClientFeaturePermissions(state);
    return featurePermissions.every(featurePermission => clientFeaturePermissions.includes(featurePermission)) || getIsSystemAdmin(state);
};

export const getUserId = (state: ApplicationState): number | null => getUser(state)?.userId || null;

export const getIsLoggedIn = (state: ApplicationState): boolean => getRoot(state).isLoggedIn;

export const getLoginError = (state: ApplicationState): string | null => getRoot(state).loginError;

export const getCheckAuth = (state: ApplicationState): boolean => getRoot(state).checkingAuth;

export const getPathname = (state: ApplicationState): string => state.router.location?.pathname || '';

export const getIsLoggingOut = (state: ApplicationState): boolean => getRoot(state).loggingOut;

export const getReminderType = (state: ApplicationState): ReminderTypes | null => getRoot(state).reminderType;

export const getReminderError = (state: ApplicationState): string | null => getRoot(state).reminderError;

export const getSocket = (state: ApplicationState): Socket | null => getRoot(state).socket;

export const getLogoutWarningModalOpen = (state: ApplicationState): boolean => getRoot(state).logoutWarningModalOpen;

export const getProfileModalOpen = (state: ApplicationState): boolean => getRoot(state).profileModalOpen;

export const getIsEditing = (state: ApplicationState): boolean => getRoot(state).editingProfile;

export const getProfileHasUpdated = (state: ApplicationState): boolean => getRoot(state).profileHasUpdated;

export const getUpdatingProfile = (state: ApplicationState): boolean => getRoot(state).updatingProfile;

export const getIsSwitchingAccounts = (state: ApplicationState): boolean => getRoot(state).switchingAccounts;

export const getSwitchAccountError = (state: ApplicationState): string | null => getRoot(state).switchAccountError;

export const getSwitchUsername = (state: ApplicationState): string | null => getRoot(state).switchUsername;
export const getSwitchPassword = (state: ApplicationState): string => getRoot(state).switchPassword;

export const getShowAccountSwitchButton = (state: ApplicationState): boolean => {
    const user = getUser(state);
    return !isNull(user) && !isUndefined(user.alternateUserAccounts) && user.alternateUserAccounts.length > 0;
};

export const getAlternateAccounts = (state: ApplicationState): AlternateUserAccount[] => {
    const user = getUser(state);
    return !isNull(user) && !isUndefined(user.alternateUserAccounts) && user.alternateUserAccounts || [];
};

export const getIdleCheckTokenValid = (state: ApplicationState): boolean => getRoot(state).idleCheckTokenValid;

export const getSendingReminder = (state: ApplicationState): boolean => getRoot(state).sendingReminder;

export const getShowResetPassword = (state: ApplicationState): boolean => getRoot(state).showResetPassword;

export const getShowSwitchAccount = (state: ApplicationState): boolean => getRoot(state).showSwitchAccount;
