import React, { useCallback, useEffect, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Functions, OfficeBuilding, SupplyChain, ThirdParty } from '../../shared/icons';
import { WizardTab, WizardTabProps } from '../../shared/wizard/WizardTab';
import styles from './DataManagement.module.scss';
import { DataManagementTile, getSelectedTab, setSelectedTab } from './store';
import { getPathname } from '../../auth/login/store';
import { doraPathnames } from './store/sagas';

interface DataManagementWrapperProps {
    children: JSX.Element | null;
}

export const doraIcons = {
    [DataManagementTile.MY_COMPANIES]: OfficeBuilding,
    [DataManagementTile.FUNCTIONS_PERFORMED]: Functions,
    [DataManagementTile.SUPPLY_CHAIN]: SupplyChain,
    [DataManagementTile.RELEVANT_THIRD_PARTY_COMPANIES]: ThirdParty
};

export const DataManagementWrapper: React.FC<DataManagementWrapperProps> = ({ children }) => {
    const dispatch = useAppDispatch();
    const selectedTab = useAppSelector(getSelectedTab);
    const pathname = useAppSelector(getPathname);

    const pathnameElements = useMemo(() => pathname.split('/'), [pathname]);
    const pathnameSelectedTab = useMemo(() => {
        const tabsAndPaths = Object.entries(doraPathnames);
        const selectedTabEntries = tabsAndPaths.find(([, path]) => pathnameElements.includes(path));
        if (selectedTabEntries) {
            return selectedTabEntries[0] as DataManagementTile;
        }
        return null;
    }, [pathnameElements]);

    const tabs = Object.values(DataManagementTile);
    const selectTab = useCallback((tab: DataManagementTile | null, reroute = true) => dispatch(setSelectedTab(tab, reroute)), [dispatch]);

    const wizardTabs: WizardTabProps[] = useMemo(() => tabs.map(tab => ({
        label: tab,
        selected: tab === selectedTab,
        onClick: () => selectTab(tab),
        icon: doraIcons[tab]
    })), [selectTab, selectedTab, tabs]);

    useEffect(() => {
        if (isNull(selectedTab) && !isNull(pathnameSelectedTab)) {
            selectTab(pathnameSelectedTab, false);
        }
    }, [selectedTab, pathnameSelectedTab, selectTab]);

    return (
        <div className={styles.dataManagementWrapper}>
            <div className={styles.tabSelectWrapper}>
                {wizardTabs.map(({ label, onClick, selected, icon }) => (
                    <div key={label} className={styles.wizardTabWrapper}>
                        <WizardTab label={label} onClick={onClick} selected={selected} icon={icon} />
                    </div>
                ))}
            </div>
            <div className={styles.pageWrapper}>
                {children}
            </div>
        </div>
    );
};
