import React, { useCallback } from 'react';
import classnames from 'classnames';
import { isNull } from 'lodash/fp';

import styles from '../Opinions.module.scss';
import { BritishOpinions, OpinionByCountry } from '../store';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Globe } from '../../../shared/icons/Globe';

interface MultiJurisdictionModalProps {
    isOpen: boolean;
    britishOpinions: BritishOpinions | null;
    toggleBritishOpinionsModal: () => void;
    openOpinionsModal: (name: string, opinions: OpinionByCountry[]) => void;
}

export const MultiJurisdictionModal: React.FC<MultiJurisdictionModalProps> = ({ isOpen, britishOpinions, toggleBritishOpinionsModal, openOpinionsModal }) => {
    const openLinkedOpinions = useCallback((country: string, opinions: OpinionByCountry[]) => {
        openOpinionsModal(country, opinions);
        toggleBritishOpinionsModal();
    }, [openOpinionsModal, toggleBritishOpinionsModal]);

    if (isNull(britishOpinions)) {
        return null;
    }
    const { scotland, englandAndWales } = britishOpinions;

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeLabel='Close'
            closeModal={() => toggleBritishOpinionsModal()}
            showConfirm={false}
            testId='opinion-jurisdiction-uk-modal'
        >
            <div className={styles.multiJurisdictionModalWrapper}>
                <ModalHeader label='Opinions for the United Kingdom' icon={Globe} testId='document-name' />
                <div className={styles.jurisdictionOptions}>
                    <div className={classnames(styles.enabledOpinion, { [styles.disabledOpinion]: !scotland.length })} onClick={() => openLinkedOpinions('Scotland', scotland)}>{`Scotland (${scotland.length})`}</div>
                    <div className={classnames(styles.enabledOpinion, { [styles.disabledOpinion]: !englandAndWales.length })} onClick={() => openLinkedOpinions('England & Wales', englandAndWales)}>{`England & Wales (${englandAndWales.length})`}</div>
                </div>
            </div>
        </ConfirmationModal>
    );
};
