import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Lei, SearchEntityBy } from '../../admin/entity/store';
import { LeiSearch } from '../../shared/lei/LeiSearch';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { fetchFuzzyAutoCompletionsSuccessful, getAutoCompletions, getFuzzyCompletions, getIsSaving, getIsSearching, getSearchModalOpen, getSearchResults, getSearchType, getSearchValue, getShowFuzzyAutoCompletions, getThirdPartyCompanySearchPageNumber, getThirdPartyCompanySearchPageSize, getTotalSearchThirdPartyCompanies, resetEntitySearch, searchThirdPartyCompaniesStarted, setSearchEntityType, setSearchEntityValue, setThirdPatyCompanySearchPageSize, thirdPartyCompanySearchPaginationNext, thirdPartyCompanySearchPaginationPrevious, toggleThirdPartySearchModalOpen, upsertThirdPartyLeiEntity } from './store';

export const ThirdPartySearchModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getSearchModalOpen);
    const searchInput = useAppSelector(getSearchValue);
    const searchBy = useAppSelector(getSearchType);
    const isSearching = useAppSelector(getIsSearching);
    const searchResults = useAppSelector(getSearchResults);
    const pageNumber = useAppSelector(getThirdPartyCompanySearchPageNumber);
    const totalEntities = useAppSelector(getTotalSearchThirdPartyCompanies);
    const pageSize = useAppSelector(getThirdPartyCompanySearchPageSize);
    const isSaving = useAppSelector(getIsSaving);
    const showFuzzyAutoCompletions = useAppSelector(getShowFuzzyAutoCompletions);
    const fuzzyCompletions = useAppSelector(getFuzzyCompletions);
    const autoCompletions = useAppSelector(getAutoCompletions);

    const closeModal = useCallback(() => dispatch(toggleThirdPartySearchModalOpen(false)), [dispatch]);
    const setSearchInput = (value: string) => dispatch(setSearchEntityValue(value));
    const setSearchBy = (type: SearchEntityBy | null) => dispatch(setSearchEntityType(type));

    const nextPage = useCallback(() => { dispatch(thirdPartyCompanySearchPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(thirdPartyCompanySearchPaginationPrevious()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setThirdPatyCompanySearchPageSize(pageSize)); }, [dispatch]);

    const addEntity = useCallback((entity: Lei) => { dispatch(upsertThirdPartyLeiEntity(entity)); }, [dispatch]);

    const searchEntities = useCallback(() => searchBy && dispatch(searchThirdPartyCompaniesStarted()), [dispatch, searchBy]);

    const closeCompletionModal = useCallback(() => dispatch(fetchFuzzyAutoCompletionsSuccessful({ autoCompletions: [], fuzzyCompletions: [] })), [dispatch]);

    const closeAndReset = useCallback(() => {
        dispatch(resetEntitySearch());
        closeModal();
    }, [closeModal, dispatch]);

    if (!isOpen) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeAndReset}
            confirmLabel='Search'
            confirm={searchEntities}
            confirmDisabled={!searchInput.length}
            testId='search-third-party-companies'
        >
            <LeiSearch
                setSearchInput={setSearchInput}
                setSearchBy={setSearchBy}
                pageNumber={pageNumber}
                totalEntities={totalEntities}
                pageSize={pageSize}
                nextPage={nextPage}
                previousPage={previousPage}
                updatePageSize={updatePageSize}
                addEntity={addEntity}
                isSearching={isSearching}
                searchResults={searchResults}
                searchBy={searchBy}
                searchInput={searchInput}
                searchEntities={searchEntities}
                isSaving={isSaving}
                showFuzzyAutoCompletions={showFuzzyAutoCompletions}
                fuzzyCompletions={fuzzyCompletions}
                autoCompletions={autoCompletions}
                closeCompletionModal={closeCompletionModal}
            />
        </ConfirmationModal>
    );
};
