import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';

export enum AdminOpinionStateActionTypes {
    FETCH_ADMIN_OPINION_ANALYTICS_STARTED = 'FETCH_ADMIN_OPINION_ANALYTICS_STARTED',
    FETCH_ADMIN_OPINION_ANALYTICS_FAILED = 'FETCH_ADMIN_OPINION_ANALYTICS_FAILED',
    FETCH_ADMIN_OPINION_ANALYTICS_SUCCESSFUL = 'FETCH_ADMIN_OPINION_ANALYTICS_SUCCESSFUL',
    FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_STARTED = 'FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_STARTED',
    FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_FAILED = 'FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_FAILED',
    FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_SUCCESSFUL = 'FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_SUCCESSFUL',
    SET_SELECTED_OPINIONS_PAGE_SIZE = 'SET_SELECTED_OPINIONS_PAGE_SIZE',
    SET_SELECTED_OPINIONS_COLUMN_SORT = 'SET_SELECTED_OPINIONS_COLUMN_SORT',
    SELECTED_OPINIONS_PAGINATION_NEXT = 'SELECTED_OPINIONS_PAGINATION_NEXT',
    SELECTED_OPINIONS_PAGINATION_PREVIOUS = 'SELECTED_OPINIONS_PAGINATION_PREVIOUS',
    SET_SELECTED_OPINIONS_TABLE_FILTERS = 'SET_SELECTED_OPINIONS_TABLE_FILTERS',
    CLEAR_SELECTED_OPINIONS_TABLE_FILTERS = 'CLEAR_SELECTED_OPINIONS_TABLE_FILTERS'
}

export enum OpinionHealthIndicator {
    HEALTHY = 'Healthy',
    BORDERLINE = 'Borderline',
    STALE = 'Stale'
}

export enum OpinionCommissionedBy {
    ISDA = 'ISDA',
    ICMA = 'ICMA',
    ISLA = 'ISLA',
    OTHER = 'OTHER'
}

export enum OpinionScope {
    COLLATERAL_PROVIDER = 'collateral provider',
    COLLATERAL_TAKER = 'collateral taker',
    ISDA_NETTING = 'isda netting',
    GMRA_NETTING = 'gmra netting',
    GMSLA_NETTING = 'gmsla netting',
    GMRA_GMSLA_NETTING = 'gmra/gmsla netting'
}

export interface SelectedOpinionAnalytics {
    opinionId: number;
    jurisdiction: string;
    focus: string;
    modifiedDate: string | null;
    scope: OpinionScope;
    commissionedBy: OpinionCommissionedBy;
}

export interface AdminOpinionState {
    isLoadingPie: boolean;
    opinionPieData: PieChartAnalyticsData[];
    selectedOpinions: SelectedOpinionAnalytics[];
    adminOpinionError: string;
    isLoadingTable: boolean;
    selectedOpinionHealthCategory: OpinionHealthIndicator | null;
    selectedOpinionsTablePageSize: number;
    selectedOpinionsTablePageNumber: number;
    selectedOpinionsTableColumnSort?: ColumnSort;
    selectedOpinionsTableFilters: TableFilters;
    selectedOpinionsTotal: number;
}
