import { flatten, isEqual, isNull, isUndefined, set } from 'lodash/fp';
import { toast } from 'react-toastify';
import { all, call, debounce, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import Fuse from 'fuse.js';

import { assertEntityClassification, classifySoftSearchEntity, fetchAllAvailableOpinionSubCounterpartyTypes, fetchAllEntities, fetchAllGroupEntities, fetchAllMyCompanyEntities, fetchAllNettingEngineEntities, fetchFuzzyAutoCompletions, fetchPaginatedEntities, getAllCompanyClassificationEntities, searchEntities, updateClassificationEntities, upsertEntity, upsertOpinionSubCounterpartyType } from '../../../../services/entity';
import { getPathname } from '../../../auth/login/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { addNewLeiEntityFailed, addNewLeiEntityStarted, addNewLeiEntitySuccessful, fetchAllEntitiesFailed, fetchAllEntitiesStarted, fetchAllEntitiesSuccessful, fetchAvailableDoraFunctionsForMigrationFailed, fetchAvailableDoraFunctionsForMigrationStarted, fetchAvailableDoraFunctionsForMigrationSuccessful, fetchMyCompanyEntitiesFailed, fetchMyCompanyEntitiesSuccessful, fetchMyEntityGroupsFailed, fetchMyEntityGroupsSuccessful, fetchPaginatedEntitiesFailed, fetchPaginatedEntitiesStarted, fetchPaginatedEntitiesSuccessful, migrateDoraCompanyFunctionsFailed, searchEntitiesFailed, searchEntitiesStarted, searchEntitiesSuccessful, updateGroupEntityIds, upsertEntityFailed, upsertEntityStarted, upsertEntitySuccessful, updateBranchesFuzzyMatchResults, checkBranchesFuzzyMatch, fetchAllCompanyClassificationEntitiesSuccessful, fetchAllCompanyClassificationEntitiesFailed, assertEntityClassificationStarted, assertEntityClassificationSuccessful, assertEntityClassificationFailed, setEntityClassificationHasUpdated, updateClassificationEntitiesFailed, updateClassificationEntitiesSuccessful, fetchAllNettingEntitiesSuccessful, fetchAllNettingEntitiesFailed, classifySoftSearchEntityStarted, classifySoftSearchEntityFailed, classifySoftSearchEntitySuccessful, fetchOpinionSubCounterpartyTypesFailed, fetchOpinionSubCounterpartyTypesSuccessful, fetchOpinionSubCounterpartyTypesStarted, upsertOpinionSubCounterpartyTypesSuccessful, upsertOpinionSubCounterpartyTypesFailed, upsertOpinionSubCounterpartyTypesStarted, fetchFuzzyAutoCompletionsFailed, fetchFuzzyAutoCompletionsSuccessful, setSearchEntityValue } from './actions';
import { getCompanyEntityGroups, getCurrentClassificationEntities, getCurrentClassifyingEntityIds, getCurrentEntity, getEntitiesPageNumber, getEntitiesPageSize, getEntitiesSearchPageNumber, getEntitiesSearchPageSize, getEntityColumnSort, getEntityFilters, getEntityIdsForGroup, getFunctionMigration, getIsSearching, getSavedClassificationEntities, getSavedEntity, getSearchEntityType, getSearchEntityValue, getSearchResults, getSelectedSubCounterpartyJurisdiction, getSelectedSubCounterpartyParent, getSelectedSubCounterpartyType } from './selectors';
import { Address, BaseEntity, BranchesFuzzyMatch, ClassificationCompanyEntity, CompanyEntity, EntitiesForGroups, Entity, EntityActionTypes, EntityDB, EntityType, FuzzyAutoCompletions, GroupEntity, Lei, NettingEngineEntity, SearchEntityBy, SoftSearchEntity, SubCounterpartyType } from './types';
import { FunctionMigration } from '../../../dora/my-companies/store';
import { checkCompanyFunctionMigration, migrateCompanyFunctions } from '../../../../services/dora';
import { fetchDoraFunctionsStarted } from '../../../dora/functions/store';
import { DropdownListDB, getAllDropdownLists } from '../../dropdown-lists/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { initialAIField } from '../../../constants/entity';
import { setLeiModalOpen, updateNettingEngineTheory } from '../../../opinions/netting-engine/store';

const updateSearchResults = (lei: string, searchResults: Lei[]) => searchResults.map(result => result.lei === lei ? set('entityExists', true, result) : result);

export function* attemptHandleUpsert() {
    const currentEntity: Entity = yield select(getCurrentEntity);
    const savedEntity: Entity = yield select(getSavedEntity);
    if (!isNull(currentEntity.entityId) && ((savedEntity.myCompany === 1 && currentEntity.myCompany === 0) || (savedEntity.doraInScope === 1 && currentEntity.doraInScope === 0))) {
        yield put(fetchDoraFunctionsStarted(1));
        yield put(fetchAvailableDoraFunctionsForMigrationStarted(currentEntity.entityId));
    } else {
        yield put(upsertEntityStarted());
    }
}

export function* attemptUpsertEntity() {
    try {
        const entity: Entity = yield select(getCurrentEntity);
        const entityIdsForGroup: string[] | null = yield select(getEntityIdsForGroup);
        const companyEntityGroupIds: string[] = yield select(getCompanyEntityGroups);
        const filters: TableFilters = yield select(getEntityFilters);
        const columnSort: ColumnSort | undefined = yield select(getEntityColumnSort);
        const pageSize: number = yield select(getEntitiesPageSize);
        const pageNumber: number = yield select(getEntitiesPageNumber);
        const { entities, total }: { entities: EntityDB[]; total: number; } = yield call(upsertEntity, { entity, filters, columnSort, pageSize, pageNumber, entityIdsForGroup, companyEntityGroupIds });
        yield put(upsertEntitySuccessful(entities, total));
        const pathname: string = yield select(getPathname);
        if (!pathname.includes('entities')) {
            yield put(fetchAllEntitiesStarted());
        }
        toast(`Successfully ${isNull(entity.entityId) ? 'added a new' : 'updated'} ${entity.type} ${isNull(entity.entityId) ? 'to entities' : 'entity'}`);
    } catch (e) {
        yield put(upsertEntityFailed((e as Error).message));
        toast.error('Unable to save entity. Please try again.');
    }
}

export function* attemptSearchEntities({ payload }: ReturnType<typeof searchEntitiesStarted>) {
    try {
        const pageNumber = payload;
        const pageSize: number = yield select(getEntitiesSearchPageSize);
        const value: string = yield select(getSearchEntityValue);
        const type: SearchEntityBy = yield select(getSearchEntityType);
        const { entities, total }: { entities: Lei[]; total: number; } = yield call(searchEntities, { type, value, pageSize, pageNumber });
        yield put(searchEntitiesSuccessful(entities, total, pageNumber));
    } catch (e) {
        yield put(searchEntitiesFailed((e as Error).message));
        toast.error('There was a problem with the search. Please try again.');
    }
}

export function* attemptFetchAllEntities({ payload }: ReturnType<typeof fetchAllEntitiesStarted>) {
    try {
        const includeGroups = isUndefined(payload) ? false : payload;
        const entities: BaseEntity[] = yield call(fetchAllEntities, { includeGroups });
        yield put(fetchAllEntitiesSuccessful(entities));
    } catch (e) {
        toast.error('Unable to fetch the latest entities. Please try again.');
        yield put(fetchAllEntitiesFailed((e as Error).message));
    }
}

export function* attemptFetchAllNettingEntities() {
    try {
        const entities: NettingEngineEntity[] = yield call(fetchAllNettingEngineEntities);
        yield put(fetchAllNettingEntitiesSuccessful(entities));
    } catch (e) {
        toast.error('Unable to fetch the latest entities. Please try again.');
        yield put(fetchAllNettingEntitiesFailed((e as Error).message));
    }
}

function* attemptFetchAllNettingEntitiesWatcher() {
    yield takeEvery(EntityActionTypes.FETCH_ALL_NETTING_ENTITIES_STARTED, attemptFetchAllNettingEntities);
}

export function* attemptClassifySoftSearchEntity({ payload }: ReturnType<typeof classifySoftSearchEntityStarted>) {
    try {
        const entity: SoftSearchEntity = yield call(classifySoftSearchEntity, payload);
        yield put(updateNettingEngineTheory('counterparty', entity.jurisdiction, 'jurisdiction'));
        yield put(updateNettingEngineTheory('counterparty', entity.opinionCounterpartyType!.value, 'counterpartyClassification'));
        yield put(classifySoftSearchEntitySuccessful());
        yield put(setLeiModalOpen(false));
    } catch (e) {
        toast.error('Unable to use AI to classify the selected counterparty. Please complete manually.');
        yield put(classifySoftSearchEntityFailed((e as Error).message));
    }
}

function* attemptClassifySoftSearchEntityWatcher() {
    yield takeLeading(EntityActionTypes.CLASSIFY_SOFT_SEARCH_ENTITY_STARTED, attemptClassifySoftSearchEntity);
}

export function* attemptFetchPaginatedEntities({ payload }: ReturnType<typeof fetchPaginatedEntitiesStarted>) {
    try {
        const { pageNumber } = payload;
        const filters: TableFilters = yield select(getEntityFilters);
        const columnSort: ColumnSort | undefined = yield select(getEntityColumnSort);
        const pageSize: number = yield select(getEntitiesPageSize);
        const { entities, total }: { entities: EntityDB[]; total: number; } = yield call(fetchPaginatedEntities, { filters, pageNumber, columnSort, pageSize });
        yield put(fetchPaginatedEntitiesSuccessful(entities, total, pageNumber));
    } catch (e) {
        toast.error('Unable to fetch the latest entities. Please try again.');
        yield put(fetchPaginatedEntitiesFailed((e as Error).message));
    }
}

export function* attemptFetchMyCompanyEntities() {
    try {
        const entities: BaseEntity[] = yield call(fetchAllMyCompanyEntities);
        yield put(fetchMyCompanyEntitiesSuccessful(entities));
    } catch (e) {
        yield put(fetchMyCompanyEntitiesFailed((e as Error).message));
    }
}

function* attemptFetchMyCompanyEntitiesWatcher() {
    yield takeEvery(EntityActionTypes.FETCH_MY_COMPANY_ENTITIES_STARTED, attemptFetchMyCompanyEntities);
}

export function* attemptFetchCompanyClassificationEntities() {
    try {
        const entities: ClassificationCompanyEntity[] = yield call(getAllCompanyClassificationEntities);
        yield put(fetchAllCompanyClassificationEntitiesSuccessful(entities));
    } catch (e) {
        yield put(fetchAllCompanyClassificationEntitiesFailed((e as Error).message));
    }
}

function* attemptFetchCompanyClassificationEntitiesWatcher() {
    yield takeEvery(EntityActionTypes.FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_STARTED, attemptFetchCompanyClassificationEntities);
}

export function* attemptFetchGroupEntities() {
    try {
        const { groupEntities, entityGroups }: { groupEntities: GroupEntity[]; entityGroups: EntitiesForGroups[]; } = yield call(fetchAllGroupEntities);
        const { entityId, type }: Entity = yield select(getCurrentEntity);
        let companyEntityGroupIds: string[] = [];
        if (groupEntities.length > 0 && entityGroups.length > 0 && !isNull(entityId)) {
            const groupsWithEntity = entityGroups.filter(group => group.entityIds.includes(entityId.toString()));
            if (groupsWithEntity.length > 0) {
                companyEntityGroupIds = groupsWithEntity.map(({ groupEntityId }) => groupEntityId.toString());
            }
            if (type === EntityType.GROUP) {
                const entityIdsForGroup = entityGroups.find(({ groupEntityId }) => groupEntityId === entityId)?.entityIds || [];
                yield put(updateGroupEntityIds(entityIdsForGroup));
            }
        }
        yield put(fetchMyEntityGroupsSuccessful(groupEntities, companyEntityGroupIds));
    } catch (e) {
        yield put(fetchMyEntityGroupsFailed((e as Error).message));
    }
}

function* attemptFetchGroupEntitiesWatcher() {
    yield takeEvery(EntityActionTypes.FETCH_MY_GROUP_ENTITIES_STARTED, attemptFetchGroupEntities);
}

export function* attemptUploadLeiEntity({ payload }: ReturnType<typeof addNewLeiEntityStarted>) {
    try {
        const { legalName, legalAddress, lei, headquartersAddress, jurisdiction, bic, otherNames } = payload;
        const otherAddresses: Address[] = headquartersAddress.address1 !== legalAddress.address1 || headquartersAddress.postalCode !== legalAddress.postalCode ? [headquartersAddress] : [];
        const newLeiEntity: CompanyEntity = {
            entityId: null,
            name: legalName,
            content: {
                lei: lei,
                address: legalAddress,
                jurisdiction: jurisdiction,
                bic: isNull(bic) ? [] : bic,
                MiFIDClassification: initialAIField,
                emirClassification: initialAIField,
                otherAddresses: otherAddresses,
                counterpartyType: initialAIField,
                opinionCounterpartyType: initialAIField,
                entityServiceType: initialAIField,
                opinionSubCounterpartyType: null,
                email: '',
                phoneNumber: '',
                mobileNumber: '',
                faxNumber: '',
                primaryContact: '',
                branches: [],
                dateOfRoiIntegration: '',
                dateOfRoiDeletion: '',
                competentAuthority: null,
                doraEntityHierarchy: null,
                totalAssetsValue: null,
                otherNames: otherNames.filter(({ name }) => !isNull(name)).map(({ name }) => name!)
            },
            type: EntityType.COMPANY,
            myCompany: 0,
            doraInScope: 0,
            doraMaintainerROI: 0,
            onWatchlist: 0,
            canBeAgent: 0,
            isDoraThirdParty: 0
        };
        const filters: TableFilters = yield select(getEntityFilters);
        const columnSort: ColumnSort | undefined = yield select(getEntityColumnSort);
        const pageSize: number = yield select(getEntitiesPageSize);
        const pageNumber: number = yield select(getEntitiesPageNumber);
        const { entities, total }: { entities: EntityDB[]; total: number; } = yield call(upsertEntity, { entity: newLeiEntity, filters, columnSort, pageSize, pageNumber, entityIdsForGroup: null, companyEntityGroupIds: [] });
        const searchResults: Lei[] = yield select(getSearchResults);
        const updatedSearchResults = updateSearchResults(lei, searchResults);
        yield put(searchEntitiesSuccessful(updatedSearchResults));
        yield put(addNewLeiEntitySuccessful(entities, total));
        const pathname: string = yield select(getPathname);
        if (!pathname.includes('entities')) {
            yield put(fetchAllEntitiesStarted());
        }
        toast(`Successfully added ${legalName} to entities`);
    } catch (e) {
        yield put(addNewLeiEntityFailed((e as Error).message));
        toast.error(`Unable to add ${payload.legalName} to entities. Please try again.`);
    }
}

function* handleUpsertWatcher() {
    yield takeLeading(EntityActionTypes.UPSERT_ENTITY_HANDLER, attemptHandleUpsert);
}

function* upsertEntityWatcher() {
    yield takeLeading(EntityActionTypes.UPSERT_ENTITY_STARTED, attemptUpsertEntity);
}

function* searchEntitiesWatcher() {
    yield takeEvery(EntityActionTypes.SEARCH_ENTITIES_STARTED, attemptSearchEntities);
}

function* fetchAllEntitiesWatcher() {
    yield takeEvery(EntityActionTypes.FETCH_ALL_ENTITIES_STARTED, attemptFetchAllEntities);
}

function* fetchPaginatedEntitiesWatcher() {
    yield takeEvery(EntityActionTypes.FETCH_PAGINATED_ENTITIES_STARTED, attemptFetchPaginatedEntities);
}

function* createLeiEntityWatcher() {
    yield takeEvery(EntityActionTypes.ADD_LEI_ENTITY_STARTED, attemptUploadLeiEntity);
}

export function* paginationNext() {
    const pageNumber: number = yield select(getEntitiesPageNumber);
    yield put(fetchPaginatedEntitiesStarted(pageNumber + 1));
}

function* paginationNextWatcher() {
    yield takeEvery(EntityActionTypes.ENTITIES_PAGINATION_NEXT, paginationNext);
}

export function* paginationPrevious() {
    const pageNumber: number = yield select(getEntitiesPageNumber);
    yield put(fetchPaginatedEntitiesStarted(pageNumber - 1));
}

function* paginationPreviousWatcher() {
    yield takeEvery(EntityActionTypes.ENTITIES_PAGINATION_PREVIOUS, paginationPrevious);
}

export function* tableConfigUpdated() {
    yield put(fetchPaginatedEntitiesStarted(1));
}

function* tableConfigUpdatedWatcher() {
    yield takeEvery([EntityActionTypes.SET_ENTITY_TABLE_FILTERS, EntityActionTypes.CLEAR_ENTITY_TABLE_FILTERS, EntityActionTypes.SET_ENTITY_TABLE_COLUMN_SORT, EntityActionTypes.SET_ENTITIES_PAGE_SIZE], tableConfigUpdated);
}

export function* searchPaginationNext() {
    const pageNumber: number = yield select(getEntitiesSearchPageNumber);
    yield put(searchEntitiesStarted(pageNumber + 1));
}

function* searchPaginationNextWatcher() {
    yield takeEvery(EntityActionTypes.ENTITIES_SEARCH_PAGINATION_NEXT, searchPaginationNext);
}

export function* searchPaginationPrevious() {
    const pageNumber: number = yield select(getEntitiesSearchPageNumber);
    yield put(searchEntitiesStarted(pageNumber - 1));
}

function* searchPaginationPreviousWatcher() {
    yield takeEvery(EntityActionTypes.ENTITIES_SEARCH_PAGINATION_PREVIOUS, searchPaginationPrevious);
}

export function* searchPageSizeUpdated() {
    yield put(searchEntitiesStarted());
}

function* searchPageSizeUpdatedWatcher() {
    yield takeEvery(EntityActionTypes.SET_ENTITIES_SEARCH_PAGE_SIZE, searchPageSizeUpdated);
}

export function* attemptCheckDoraFunctionsMigration({ payload }: ReturnType<typeof fetchAvailableDoraFunctionsForMigrationStarted>) {
    try {
        const functionMigration: FunctionMigration = yield call(checkCompanyFunctionMigration, { entityId: payload });
        if (!functionMigration.migrations.length) {
            yield put(upsertEntityStarted());
        } else {
            yield put(fetchAvailableDoraFunctionsForMigrationSuccessful(functionMigration));
        }
    } catch (e) {
        toast.error('Something went wrong. Please try again.');
        yield put(fetchAvailableDoraFunctionsForMigrationFailed((e as Error).message));
    }
}

function* checkDoraFunctionsMigrationWatcher() {
    yield takeEvery(EntityActionTypes.FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_STARTED, attemptCheckDoraFunctionsMigration);
}

export function* attemptMigrateDoraCompanyFunctions() {
    try {
        const functionMigration: FunctionMigration = yield select(getFunctionMigration);
        yield call(migrateCompanyFunctions, { functionMigration });
        yield put(upsertEntityStarted());
    } catch (e) {
        yield put(migrateDoraCompanyFunctionsFailed((e as Error).message));
        toast.error('Unable to migrate DORA Functions. Please try again.');
    }
}

function* migrateDoraCompanyFunctionsWatcher() {
    yield takeLeading(EntityActionTypes.MIGRATE_DORA_COMPANY_FUNCTIONS_STARTED, attemptMigrateDoraCompanyFunctions);
}

export function* attemptFuzzyMatch({ payload }: ReturnType<typeof checkBranchesFuzzyMatch>) {
    try {
        const { value, index } = payload;
        const allDropdowns: DropdownListDB[] = yield select(getAllDropdownLists);
        const cityDropdownList = allDropdowns.find(({ name }) => name === 'City') || { options: [] as string[] };
        const cityDropdownOptions = cityDropdownList.options.filter(option => option !== 'Other').map(value => ({ value, label: value }));
        const fuzzyMatchOptions = {
            includeScore: true,
            threshold: 0.5,
            keys: ['value']
        };
        const entityFuse = new Fuse(cityDropdownOptions, fuzzyMatchOptions);
        const fuzzyMatches: Fuse.FuseResult<DropdownOption>[] = entityFuse.search(value);
        const results = fuzzyMatches.map(({ item, score }) => ({ value: item.value, score: score! }));
        let sortedMatches: BranchesFuzzyMatch[] = flatten(results).sort((a, b) => a.score! - b.score!);
        yield put(updateBranchesFuzzyMatchResults(sortedMatches, index));
    } catch (e) {
        toast.error('Unable to find any suggestions. Please try again.');
    }
}

function* fuzzyMatchWatcher() {
    yield debounce(1000, EntityActionTypes.CHECK_BRANCHES_FUZZY_MATCH, attemptFuzzyMatch);
}

export function* attemptAssertEntityClassification({ payload }: ReturnType<typeof assertEntityClassificationStarted>) {
    try {
        const currentClassifyingEntities: number[] = yield select(getCurrentClassifyingEntityIds);
        if (currentClassifyingEntities.length < 5) {
            const updatedEntity: ClassificationCompanyEntity = yield call(assertEntityClassification, { entity: payload });
            yield put(assertEntityClassificationSuccessful(updatedEntity));
        }
    } catch (e) {
        yield put(assertEntityClassificationFailed(payload.entityId));
    }
}

function* assertEntityClassificationWatcher() {
    yield takeEvery(EntityActionTypes.ASSERT_ENTITY_CLASSIFICATION_STARTED, attemptAssertEntityClassification);
}

export function* checkEntityClassificationUpdated() {
    const currentClassificationEntities: ClassificationCompanyEntity[] = yield select(getCurrentClassificationEntities);
    const savedClassificationEntities: ClassificationCompanyEntity[] = yield select(getSavedClassificationEntities);
    const hasUpdated = !isEqual(currentClassificationEntities, savedClassificationEntities);
    yield put(setEntityClassificationHasUpdated(hasUpdated));
}

function* checkEntityClassificationUpdatedWatcher() {
    yield debounce(500, EntityActionTypes.UPDATE_ENTITY_CLASSIFICATION_FIELD, checkEntityClassificationUpdated);
}

export function* attemptUpdateClassificationEntities() {
    try {
        const currentClassificationEntities: ClassificationCompanyEntity[] = yield select(getCurrentClassificationEntities);
        const savedClassificationEntities: ClassificationCompanyEntity[] = yield select(getSavedClassificationEntities);
        const updatedClassificationEntities = currentClassificationEntities.filter(entity => {
            const savedEntity = savedClassificationEntities.find(({ entityId }) => entity.entityId === entityId);
            return !isEqual(savedEntity, entity);
        });
        yield call(updateClassificationEntities, { entities: updatedClassificationEntities });
        yield put(updateClassificationEntitiesSuccessful());
        yield put(fetchPaginatedEntitiesStarted());
        toast('Successfully updated entity classifications');
    } catch (e) {
        yield put(updateClassificationEntitiesFailed((e as Error).message));
    }
}

function* updateClassificationEntitiesWatcher() {
    yield takeLeading(EntityActionTypes.UPDATE_CLASSIFICATION_ENTITIES_STARTED, attemptUpdateClassificationEntities);
}

export function* attemptFetchFuzzyAutoCompletions({ payload }: ReturnType<typeof setSearchEntityValue>) {
    try {
        const searchType: SearchEntityBy | null = yield select(getSearchEntityType);
        const isSearching: boolean = yield select(getIsSearching);
        if (!isNull(searchType) && searchType === SearchEntityBy.COMPANY_NAME && !isSearching) {
            const fuzzyAutoCompletions: FuzzyAutoCompletions = yield call(fetchFuzzyAutoCompletions, { value: payload });
            yield put(fetchFuzzyAutoCompletionsSuccessful(fuzzyAutoCompletions));
        }
    } catch (e) {
        yield put(fetchFuzzyAutoCompletionsFailed((e as Error).message));
    }
}

function* fetchFuzzyAutoCompletionsWatcher() {
    yield debounce(1000, EntityActionTypes.SET_SEARCH_ENTITY_VALUE, attemptFetchFuzzyAutoCompletions);
}

export function* attemptFetchAvailableOpinionSubCounterpartyTypes({ payload }: ReturnType<typeof fetchOpinionSubCounterpartyTypesStarted>) {
    const { opinionJurisdiction, parentCounterpartyTypeFilter } = payload;
    try {
        const subCounterpartyTypes: SubCounterpartyType[] = yield call(fetchAllAvailableOpinionSubCounterpartyTypes, {
            opinionJurisdiction,
            parentCounterpartyTypeFilter
        });
        yield put(fetchOpinionSubCounterpartyTypesSuccessful(subCounterpartyTypes));
    } catch (e) {
        yield put(fetchOpinionSubCounterpartyTypesFailed((e as Error).message));
    }
}

function* attemptFetchAvailableOpinionSubCounterpartyTypesWatcher() {
    yield takeEvery(EntityActionTypes.FETCH_OPINION_SUB_COUNTERPARTY_TYPES_STARTED, attemptFetchAvailableOpinionSubCounterpartyTypes);
}

export function* attemptUpsertOpinionSubCounterpartyTypes({ payload }: ReturnType<typeof upsertOpinionSubCounterpartyTypesStarted>) {
    try {
        const request: SubCounterpartyType = yield select(getSelectedSubCounterpartyType);
        if (isUndefined(request.subCounterpartyTypeId)) {
            request.parentCounterpartyType = yield select(getSelectedSubCounterpartyParent);
            request.jurisdiction = yield select(getSelectedSubCounterpartyJurisdiction);
        }
        yield call(upsertOpinionSubCounterpartyType, request);
        yield put(upsertOpinionSubCounterpartyTypesSuccessful(request.isSystem));
        let parentCounterpartyValue: string | undefined = undefined;
        if (!payload) {
            parentCounterpartyValue = request.parentCounterpartyType;
        }
        yield put(fetchOpinionSubCounterpartyTypesStarted(request.jurisdiction, parentCounterpartyValue));
        toast(`Successfully ${request.subCounterpartyTypeId ? 'updated' : 'added'} Sub Counterparty Type`);
    } catch (e) {
        yield put(upsertOpinionSubCounterpartyTypesFailed((e as Error).message));
    }
}

function* attemptUpsertOpinionSubCounterpartyTypesWatcher() {
    yield takeEvery(EntityActionTypes.UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_STARTED, attemptUpsertOpinionSubCounterpartyTypes);
}

export function* entitySaga() {
    yield all([
        fork(handleUpsertWatcher),
        fork(upsertEntityWatcher),
        fork(searchEntitiesWatcher),
        fork(fetchAllEntitiesWatcher),
        fork(createLeiEntityWatcher),
        fork(paginationNextWatcher),
        fork(paginationPreviousWatcher),
        fork(tableConfigUpdatedWatcher),
        fork(fetchPaginatedEntitiesWatcher),
        fork(searchPaginationPreviousWatcher),
        fork(searchPaginationNextWatcher),
        fork(attemptFetchMyCompanyEntitiesWatcher),
        fork(attemptFetchGroupEntitiesWatcher),
        fork(searchPageSizeUpdatedWatcher),
        fork(checkDoraFunctionsMigrationWatcher),
        fork(migrateDoraCompanyFunctionsWatcher),
        fork(fuzzyMatchWatcher),
        fork(attemptFetchCompanyClassificationEntitiesWatcher),
        fork(assertEntityClassificationWatcher),
        fork(checkEntityClassificationUpdatedWatcher),
        fork(updateClassificationEntitiesWatcher),
        fork(attemptFetchAllNettingEntitiesWatcher),
        fork(attemptClassifySoftSearchEntityWatcher),
        fork(fetchFuzzyAutoCompletionsWatcher),
        fork(attemptFetchAvailableOpinionSubCounterpartyTypesWatcher),
        fork(attemptUpsertOpinionSubCounterpartyTypesWatcher)
    ]);
}
