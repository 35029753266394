import { put, all, call, fork, select, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { Socket } from 'socket.io-client';

import { getConfirmNewPassword, getCurrentPassword, getNewPassword } from './selectors';
import { checkResetLinkValidFailed, checkResetLinkValidStarted, checkResetLinkValidSuccessful, resetPasswordFailed, resetPasswordStarted, resetPasswordSuccessful } from './actions';
import { ResetPasswordActionTypes } from './types';
import { checkResetLinkIsValid, resetForgottenPassword, resetPassword } from '../../../../services/auth';
import { getSocket } from '../../login/store';

export function* attemptResetPassword({ payload }: ReturnType<typeof resetPasswordStarted>) {
    try {
        const token = payload;
        const currentPassword: string = yield select(getCurrentPassword);
        const newPassword: string = yield select(getNewPassword);
        const confirmNewPassword: string = yield select(getConfirmNewPassword);
        const passwordsMatch = newPassword === confirmNewPassword;
        if (passwordsMatch) {
            if (token) {
                yield call(resetForgottenPassword, { token, newPassword });
            } else {
                yield call(resetPassword, { currentPassword, newPassword });
            }
            yield put(resetPasswordSuccessful());
            yield put(push('/login'));
            toast('Password updated successfully');
        } else {
            yield put(resetPasswordFailed('Your new password and confirm password do not match'));
        }
    } catch (e) {
        yield put(resetPasswordFailed((e as Error).message));
    }
}

function* resetPasswordWatcher() {
    yield takeLeading(ResetPasswordActionTypes.RESET_PASSWORD_STARTED, attemptResetPassword);
}

export function* attemptCheckResetLinkIsValid({ payload }: ReturnType<typeof checkResetLinkValidStarted>) {
    try {
        const token = payload;
        const socket: Socket | null = yield select(getSocket);
        yield call(checkResetLinkIsValid, { token, socketId: socket?.id || null });
        yield put(checkResetLinkValidSuccessful());
    } catch (e) {
        yield put(push('/login'));
        yield put(checkResetLinkValidFailed((e as Error).message));
    }
}

function* checkResetLinkIsValidWatcher() {
    yield takeLeading(ResetPasswordActionTypes.CHECK_RESET_LINK_IS_VALID_STARTED, attemptCheckResetLinkIsValid);
}

export function* resetPasswordSaga() {
    yield all([
        fork(resetPasswordWatcher),
        fork(checkResetLinkIsValidWatcher)
    ]);
}
