import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Options } from 'react-select';

import { DATABASE_DATE_FORMAT, formatDate } from '../../../../utils/luxon';
import { DatePicker } from '../../../shared/datepicker/DatePicker';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { DateComparatorRisk, DateRiskField, RiskAssociated, RiskAssociatedValue, RiskComparator } from '../../../admin/risk-tolerance/store';
import styles from './RiskField.module.scss';

const { grey } = styles;

interface DateRiskFieldConfigProps {
    riskField: DateRiskField;
    riskAssociated: RiskAssociated;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValue) => void;
}

export const DateRiskFieldConfig: React.FC<DateRiskFieldConfigProps> = ({ riskField, riskAssociated, updateRiskConfig }) => {
    const { riskConfig, includeRiskField } = riskField;
    const [comparator, setComparator] = useState<null | RiskComparator>(null);
    const [value, setValue] = useState<null | string>(null);

    const comparatorOptions = useMemo(() => Object.values(RiskComparator).map(value => ({ value, label: value })), []);
    const comparatorValue = useMemo(() => comparator ? { value: comparator, label: comparator } : null, [comparator]);

    const dateValue = !isNull(value) && new Date(value) || null;

    const updateComparatorValue = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let updatedComparator = null;
        if (!isNull(dropdownValue)) {
            updatedComparator = (dropdownValue as DropdownOption).value as RiskComparator;
        }
        const dateConfig: DateComparatorRisk = { value, comparator: updatedComparator };
        setComparator(updatedComparator);
        updateRiskConfig(riskAssociated, dateConfig);
    }, [riskAssociated, value, updateRiskConfig]);

    const updateDateValue = useCallback((date: Date | null) => {
        const updatedDate = date ? formatDate(date, DATABASE_DATE_FORMAT) : null;
        const dateConfig: DateComparatorRisk = { value: updatedDate, comparator };
        setValue(updatedDate);
        updateRiskConfig(riskAssociated, dateConfig);
    }, [riskAssociated, comparator, updateRiskConfig]);

    useEffect(() => {
        setValue(riskConfig[riskAssociated].value);
        setComparator(riskConfig[riskAssociated].comparator);
        return () => {
            setValue(null);
            setComparator(null);
        };
    }, [riskConfig, riskAssociated]);

    return (
        <div className={styles.dateWrapper}>
            <div className={styles.comparatorWrapper}>
                <Dropdown options={comparatorOptions} value={comparatorValue} onChange={updateComparatorValue} isClearable controlBorderColor={grey} disabled={!includeRiskField} />
            </div>
            <div className={styles.dateInputWrapper}>
                <DatePicker value={dateValue} onChange={updateDateValue} testId='risk-tolerance-date' disabled={!includeRiskField} />
            </div>
        </div>
    );
};
