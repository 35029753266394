import React from 'react';

import { Add, Search, Export, Bookshelf, Form, Document, Opinion, OpinionAnalytics, MultiAnalytics, OpinionReporting } from '../../shared/icons';
import { HomePageTile } from '../store';
import { AttestationsWidget, DocumentSpreadWidget, EntitySpreadWidget, HomeConfigTile } from './home-screen-tile-preview/HomeConfigTile';
import styles from '../Home.module.scss';

const { grey } = styles;

interface AvailableTile {
    id: HomePageTile;
    tile: JSX.Element | React.ReactNode;
}

export const availableTiles: AvailableTile[] = [
    {
        id: HomePageTile.ADD_DOCUMENTS,
        tile: <HomeConfigTile label='Upload' icon={Add} testId='upload-document' />
    },
    {
        id: HomePageTile.MY_DOCUMENTS,
        tile: <HomeConfigTile label='My Documents' icon={Document} testId='my-documents' />
    },
    {
        id: HomePageTile.SEARCH_DOCUMENTS,
        tile: <HomeConfigTile label='Search' icon={Search} testId='search-documents' fill={grey} />
    },
    {
        id: HomePageTile.DOCUMENT_SPREAD,
        tile: <HomeConfigTile chart={<DocumentSpreadWidget />} testId='document-spread-chart' />
    },
    {
        id: HomePageTile.ENTITY_SPREAD,
        tile: <HomeConfigTile chart={<EntitySpreadWidget />} testId='entity-spread-chart' />
    },
    {
        id: HomePageTile.MY_ATTESTATIONS,
        tile: <HomeConfigTile chart={<AttestationsWidget />} testId='my-attestation-progress' />
    },
    {
        id: HomePageTile.MY_OPINIONS,
        tile: <HomeConfigTile label='My Opinions' icon={Opinion} testId='my-opinions' />
    },
    {
        id: HomePageTile.OPINION_ANALYTICS,
        tile: <HomeConfigTile label='Opinion Analytics' icon={OpinionAnalytics} testId='opinions-analytics' />
    },
    {
        id: HomePageTile.DOCUMENT_ANALYTICS,
        tile: <HomeConfigTile label='Document Analytics' icon={MultiAnalytics} testId='documents-analytics' />
    },
    {
        id: HomePageTile.OPINION_REPORTING,
        tile: <HomeConfigTile label='Opinion Reporting' icon={OpinionReporting} testId='opinions-reporting' />
    },
    {
        id: HomePageTile.DOCUMENT_REPORTING,
        tile: <HomeConfigTile label='Document Reporting' icon={Export} testId='documents-reporting' />
    },
    {
        id: HomePageTile.PLAYBOOKS,
        tile: <HomeConfigTile label='Playbooks' icon={Bookshelf} testId='playbook' />
    },
    {
        id: HomePageTile.CLAUSE_LIBRARY,
        tile: <HomeConfigTile label='Clause Library' icon={Form} testId='clause-library' />
    }
];
