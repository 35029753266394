import { isNull } from 'lodash/fp';

import { DropdownOption } from '../shared/dropdown/Dropdown';
import { OpinionCommissionedBy, OpinionScope } from '../admin/opinions/store';

export const allOpinionFocusOptions = [
    { value: 'QFC', jurisdiction: 'Qatar' },
    { value: 'Federal & Free Zone', jurisdiction: 'Qatar' },
    { value: 'ADGM', jurisdiction: 'United Arab Emirates (the)' },
    { value: 'DIFC', jurisdiction: 'United Arab Emirates (the)' },
    { value: 'Federal', jurisdiction: 'United Arab Emirates (the)' },
    { value: 'Illinois Insurance Co\'s', jurisdiction: 'United States of America (the)' },
    { value: 'Indiana Insurance Co\'s', jurisdiction: 'United States of America (the)' },
    { value: 'Iowa Insurance Co\'s', jurisdiction: 'United States of America (the)' },
    { value: 'Massachusetts Insurance Co\'s', jurisdiction: 'United States of America (the)' },
    { value: 'Minnesota Insurance Co\'s', jurisdiction: 'United States of America (the)' },
    { value: 'NY Insurance Co\'s', jurisdiction: 'United States of America (the)' },
    { value: 'Virginia Insurance Co\'s', jurisdiction: 'United States of America (the)' },
];

export const allFocusOptions: DropdownOption[] = [{ value: 'General', label: 'General' }, ...allOpinionFocusOptions.map(({ value }) => ({ value, label: value }))].sort((a, b) => a.label.localeCompare(b.label));

export const getFocusOptions = (currentJurisdiction: string | null): DropdownOption[] => {
    const baseFocusOptions = [{ value: 'General', label: 'General' }];
    const jurisdictionSpecificOptions = allOpinionFocusOptions.filter(({ jurisdiction }) => jurisdiction === currentJurisdiction).map(({ value }) => ({ value, label: value }));
    return [...baseFocusOptions, ...jurisdictionSpecificOptions];
};

export const getFocusOptionsMultipleJurisdictions = (jurisdictions: string[] | null): DropdownOption[] => {
    const baseFocusOptions = [{ value: 'General', label: 'General' }];
    const jurisdictionSpecificOptions = !isNull(jurisdictions) ? allOpinionFocusOptions.filter(({ jurisdiction }) => jurisdictions.includes(jurisdiction)).map(({ value }) => ({ value, label: value })) : [];
    return [...baseFocusOptions, ...jurisdictionSpecificOptions];
};

export enum CollateralAgreementCoverageGroup {
    CSA_NY = '2016 Credit Support Annex for Variation Margin (New York law)',
    CSA_ENGLISH = '2016 Credit Support Annex for Variation Margin (English law)',
    IM_CSA_NY = 'IM Credit Support Annex (NY Law)',
    IM_CSA_ENGLISH = 'IM Credit Support Deed (English law)',
    EUROCLEAR_COLLATERAL = 'Euroclear Collateral Transfer Agreement',
    EUROCLEAR_SECURITY = 'Euroclear Security Agreement',
    CLEARSTREAM_COLLATERAL = 'Clearstream Collateral Transfer Agreement',
    CLEARSTREAM_SECURITY = 'Clearstream Security Agreement',
    IM_CTA = '2019 Collateral Transfer Agreement for Initial Margin',
    IM_BCSA = '2019 Bank Custodian Security Agreement for Initial Margin'
}

export interface CollateralProviderAgreementGroups {
    [CollateralAgreementCoverageGroup.CSA_NY]: string[];
    [CollateralAgreementCoverageGroup.CSA_ENGLISH]: string[];
    [CollateralAgreementCoverageGroup.IM_CSA_NY]: string[];
    [CollateralAgreementCoverageGroup.IM_CSA_ENGLISH]: string[];
    [CollateralAgreementCoverageGroup.EUROCLEAR_COLLATERAL]: string[];
    [CollateralAgreementCoverageGroup.EUROCLEAR_SECURITY]: string[];
    [CollateralAgreementCoverageGroup.CLEARSTREAM_COLLATERAL]: string[];
    [CollateralAgreementCoverageGroup.CLEARSTREAM_SECURITY]: string[];
    [CollateralAgreementCoverageGroup.IM_CTA]: string[];
    [CollateralAgreementCoverageGroup.IM_BCSA]: string[];
}

export interface CollateralTakerAgreementGroups {
    [CollateralAgreementCoverageGroup.EUROCLEAR_COLLATERAL]: string[];
    [CollateralAgreementCoverageGroup.EUROCLEAR_SECURITY]: string[];
    [CollateralAgreementCoverageGroup.CLEARSTREAM_COLLATERAL]: string[];
    [CollateralAgreementCoverageGroup.CLEARSTREAM_SECURITY]: string[];
    [CollateralAgreementCoverageGroup.IM_CTA]: string[];
    [CollateralAgreementCoverageGroup.IM_BCSA]: string[];
}

const euroclearCollateral = [
    '2016 ISDA Euroclear Collateral Transfer Agreement (New York law)',
    '2016 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)',
    '2017 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)',
    '2017 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (New York law)',
    '2018 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (New York law)',
    '2018 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)',
    '2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope',
    '2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope - French Law Addendum',
    '2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope Pledgee Representative Rider'
];

const euroclearSecurity = [
    '2016 ISDA Euroclear Security Agreement (Belgian law)',
    '2018 ISDA Euroclear Security Agreement (Belgian law)',
    '2019 ISDA Euroclear Security Agreement (Belgian law)',
    '2019 ISDA Euroclear Security Agreement Pledgee Representative Rider',
    '2022 ISDA Euroclear NextGen Security Agreement (Belgian law)',
    '2022 ISDA Euroclear Security Agreement (account or subdivision version) (Belgian law)'
];

const clearstreamCollateral = [
    '2016 ISDA Clearstream Collateral Transfer Agreement (New York law)',
    '2016 ISDA Clearstream Collateral Transfer Agreement (English law)',
    '2019 ISDA Clearstream Collateral Transfer Agreement - Multi-Regime Scope',
    '2019 ISDA Clearstream Collateral Transfer Agreement - Multi-Regime Scope - French Law Addendum'
];

const clearstreamSecurity = [
    '2016 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Provider (Luxembourg law)',
    '2017 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Taker (Luxembourg law)',
    '2019 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Taker (Luxembourg law)',
    '2019 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Provider (Luxembourg law)'
];

const imCTA = [
    '2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope',
    '2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope - French Law Addendum',
    '2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope - KRW Collateral (IM) Addendum'
];

const imBCSA = [
    '2019 ISDA Security Agreement for Initial Margin (English law)',
    '2019 ISDA Security Agreement for Initial Margin (New York law)',
    '2019 ISDA Security Agreement (Irish law)',
    '2019 ISDA Security Agreement (Luxembourg law)',
    '2019 ISDA Security Agreement (Belgian law)',
    '2019 ISDA Security Agreement (French law)',
    '2021 ISDA Security Agreement (Korean law)',
    '2022 ISDA Security Agreement (Belgian law)',
    '2022 ISDA Security Agreement (Hong Kong law)'
];

export const collateralProviderOpinionGroups: CollateralProviderAgreementGroups = {
    [CollateralAgreementCoverageGroup.CSA_NY]: [
        '2016 Credit Support Annex for Variation Margin Independent Amount Amendments (New York law)',
        '2016 Credit Support Annex for Variation Margin (New York law)'
    ],
    [CollateralAgreementCoverageGroup.CSA_ENGLISH]: [
        '2016 Credit Support Annex for Variation Margin Independent Amount Amendments (English law)',
        '2016 Credit Support Annex for Variation Margin (English law)'
    ],
    [CollateralAgreementCoverageGroup.IM_CSA_NY]: [
        '2016 ISDA Phase 1 Credit Support Annex (New York law)',
        '2018 ISDA Credit Support Annex for Initial Margin (New York law)'
    ],
    [CollateralAgreementCoverageGroup.IM_CSA_ENGLISH]: [
        '2016 ISDA Phase 1 IM Credit Support Deed (English law)',
        '2018 ISDA Credit Support Deed for Initial Margin (English law)'
    ],
    [CollateralAgreementCoverageGroup.EUROCLEAR_COLLATERAL]: euroclearCollateral,
    [CollateralAgreementCoverageGroup.EUROCLEAR_SECURITY]: euroclearSecurity,
    [CollateralAgreementCoverageGroup.CLEARSTREAM_COLLATERAL]: clearstreamCollateral,
    [CollateralAgreementCoverageGroup.CLEARSTREAM_SECURITY]: clearstreamSecurity,
    [CollateralAgreementCoverageGroup.IM_CTA]: imCTA,
    [CollateralAgreementCoverageGroup.IM_BCSA]: imBCSA
};

export const collateralTakerOpinionGroups: CollateralTakerAgreementGroups = {
    [CollateralAgreementCoverageGroup.EUROCLEAR_COLLATERAL]: euroclearCollateral,
    [CollateralAgreementCoverageGroup.EUROCLEAR_SECURITY]: euroclearSecurity,
    [CollateralAgreementCoverageGroup.CLEARSTREAM_COLLATERAL]: clearstreamCollateral,
    [CollateralAgreementCoverageGroup.CLEARSTREAM_SECURITY]: clearstreamSecurity,
    [CollateralAgreementCoverageGroup.IM_CTA]: imCTA,
    [CollateralAgreementCoverageGroup.IM_BCSA]: imBCSA
};

// A list of all of the sections and fields that have an opposite configuration of positive and negative assertions
const oppositeFieldSections = [
    'assumptions-multiBranchStatus',
    'collateralTakerInsolvency-providerRequirements',
    'collateralTakerInsolvency-allOutstandingSatisfied',
    'collateralTakerInsolvency-subjectToStayOrFreeze',
    'collateralTakerInsolvency-cashLeakageConclusion',
    'earlyTermination-aetRequired',
    'earlyTermination-aetAdvisable',
    'earlyTermination-bridgesOne',
    'earlyTermination-bridgesTwo',
    'earlyTermination-closeOutProtocol',
    'earlyTermination-sectionTwoAThree',
    'earlyTermination-closeOutLocalCurrency',
    'legalIssues-onShoreInsolvency',
    'legalIssues-capacityCounterpartyIssues',
    'legalIssues-enforceabilityTransactionIssues',
    'sftTransactions-sftScheduleProvisions',
    'protocols-closeOutAmountProtocol',
    'protocols-negativeInterestProtocol',
    'sftDocuments-loanTransactions',
    'sftDocuments-koreanPledge',
    'sftDocuments-titleTransferAffected',
    'earlyCloseOut-aetRequired',
    'earlyCloseOut-aetAdvisable',
    'secIMSupplement-secIMChanges',
    'securityInterest-actionsToPerfect',
    'securityInterest-eligibleCollateralCreated',
    'securityInterest-affectedByFluctuation',
    'securityInterest-fixedAmount',
    'securityInterest-careOfCollateral',
    'securityInterest-consentToSubstitution',
    'securityInterest-substituteAdverseImpact',
    'securityInterest-enforceRightsFormalities',
    'securityInterest-enforceLimitations',
    'securityInterest-rightsAffectedByCommencementOfInsolvency',
    'securityInterest-clawbackDuringSuspectPeriod',
    'securityInterest-imJapaneseAmendmentsAffectConclusion',
    'securityInterest-euroclearPledgeeAffectConclusion',
    'securityInterest-cashLeakageConclusion',
    'titleTransfer-recharacterisedSecurityInterest',
    'titleTransfer-actionsContinuedTitle',
    'titleTransfer-otherToEnsureValidity',
    'titleTransfer-transferorRightToExchange',
    'titleTransfer-transfereeConsent',
    'titleTransfer-clawbackDuringSuspectPeriod',
];
const defaultPositiveNegative = { positiveAnswers: ['Yes', 'Yes - with exceptions'], negativeAnswers: ['No'] };
const oppositePositiveNegative = { positiveAnswers: ['No'], negativeAnswers: ['Yes', 'Yes - with exceptions'] };

export const getPositiveAndNegativeAnswers = (sectionId: string, fieldId: string) => {
    if (oppositeFieldSections.includes(`${sectionId}-${fieldId}`)) {
        return oppositePositiveNegative;
    }
    return defaultPositiveNegative;
};

export const opinionCommissionedByOptions = () => [
    { label: 'ISDA', value: OpinionCommissionedBy.ISDA },
    { label: 'ICMA', value: OpinionCommissionedBy.ICMA },
    { label: 'ISLA', value: OpinionCommissionedBy.ISLA },
    { label: 'Other', value: OpinionCommissionedBy.OTHER }
];

export const opinionScopeOptions = () => [
    { label: 'ISDA Netting', value: OpinionScope.ISDA_NETTING },
    { label: 'Collateral Provider Insolvency', value: OpinionScope.COLLATERAL_PROVIDER },
    { label: 'Collateral Taker Insolvency', value: OpinionScope.COLLATERAL_TAKER },
    { label: 'GMRA Netting', value: OpinionScope.GMRA_NETTING },
    { label: 'GMSLA Netting', value: OpinionScope.GMSLA_NETTING }
];
