import { RawDraftContentState } from 'draft-js';
import React from 'react';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { ConfirmationModal } from '../../../../shared/modal/ConfirmationModal';
import { WYSIWYG } from '../../../../shared/wysiwyg/WYSIWYG';
import { SettingsModal, updateSettingsValue } from '../../store';
import styles from '../DatasetComponents.module.scss';

interface LabelContentModalProps {
    isOpen: boolean;
    closeModal: () => void;
    settingsModalIndex: SettingsModal;
    value: null | RawDraftContentState;
}

export const LabelContentModal: React.FC<LabelContentModalProps> = ({ isOpen, closeModal, value, settingsModalIndex }) => {
    const dispatch = useAppDispatch();
    const { index, groupIndex } = settingsModalIndex;

    const updateContent = (content: RawDraftContentState) => dispatch(updateSettingsValue('labelContent', content, index!, groupIndex));

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            closeLabel='Close'
            showConfirm={false}
            testId='dataset-builder-label'
            showOverlay={false}
        >
            <div className={styles.labelContentWrapper} data-testid='dataset-builder-label-field-wrapper'>
                <div className={styles.labelContentTitle}>Label</div>
                <WYSIWYG
                    content={value}
                    updateContent={updateContent}
                />
            </div>
        </ConfirmationModal>
    );
};
