import { action } from 'typesafe-actions';

import { ArkDocument, DocumentType, AnalysisView } from '../../../documents/my-documents/store';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { NettingPage, OpinionNettingEngineActionTypes, NettingEngineTheory, NettingEngineCounterparty, NettingResponseInformation, NettingEngineTableView } from './types';

export const setNettingEnginePage = (page: NettingPage) => action(OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_PAGE, page);

export const resetNettingEnginePage = () => action(OpinionNettingEngineActionTypes.RESET_NETTING_ENGINE_PAGE);

export const updateNettingEngineTheory = (key: keyof NettingEngineTheory, value: string | string[] | null | boolean, counterpartyKey?: keyof NettingEngineCounterparty ) => action(OpinionNettingEngineActionTypes.UPDATE_NETTING_ENGINE_THEORY, { key, value, counterpartyKey });

export const calculateNettingEngineTheoryStarted = () => action(OpinionNettingEngineActionTypes.CALCULATE_NETTING_ENGINE_THEORY_STARTED);
export const calculateNettingEngineTheorySuccessful = (results: NettingResponseInformation) => action(OpinionNettingEngineActionTypes.CALCULATE_NETTING_ENGINE_THEORY_SUCCESSFUL, results);
export const calculateNettingEngineTheoryFailed = (error: string) => action(OpinionNettingEngineActionTypes.CALCULATE_NETTING_ENGINE_THEORY_FAILED, error);

export const setSurveyQuestionIndex = (index: number) => action(OpinionNettingEngineActionTypes.SET_SURVEY_QUESTION_INDEX, index);

export const setShowSummary = (value: boolean) => action(OpinionNettingEngineActionTypes.SET_SHOW_SUMMARY, value);

export const setLeiModalOpen = (value: boolean) => action(OpinionNettingEngineActionTypes.SET_LEI_MODAL_OPEN, value);

export const setIsChangingQuestion = (value: boolean) => action(OpinionNettingEngineActionTypes.SET_CHANGING_QUESTION, value);

export const resetTheoreticalState = () => action(OpinionNettingEngineActionTypes.RESET_THEORETICAL_STATE);

export const setNettingAssumptionsModalOpen = (value: boolean) => action(OpinionNettingEngineActionTypes.SET_NETTING_ASSUMPTIONS_MODAL_OPEN, value);

export const toggleIndustryOrCustomConclusions = (value: boolean) => action(OpinionNettingEngineActionTypes.TOGGLE_INDUSTRY_OR_CUSTOM_CONCLUSIONS, value);

export const enquireSingleNettingOpinion = (value: string) => action(OpinionNettingEngineActionTypes.ENQUIRE_SINGLE_NETTING_OPINION_STARTED, value);
export const enquireAllNettingOpinions = () => action(OpinionNettingEngineActionTypes.ENQUIRE_ALL_NETTING_OPINIONS_STARTED);
export const enquireOpinionSuccessful = (value: string[]) => action(OpinionNettingEngineActionTypes.ENQUIRE_NETTING_OPINION_SUCCESSFUL, value);
export const enquireOpinionFailed = () => action(OpinionNettingEngineActionTypes.ENQUIRE_NETTING_OPINION_FAILED);

export const fetchNettingEngineDocumentsStarted = (pageNumber: number, documentType: DocumentType, agreementTypeId?: number, baseDocument?: boolean) => action(OpinionNettingEngineActionTypes.FETCH_NETTING_ENGINE_DOCUMENTS_STARTED, { pageNumber, agreementTypeId, baseDocument, documentType });
export const fetchNettingEngineDocumentsSuccessful = (documents: ArkDocument[], total?: number, pageNumber?: number) => action(OpinionNettingEngineActionTypes.FETCH_NETTING_ENGINE_DOCUMENTS_SUCCESSFUL, { documents, total, pageNumber });
export const fetchNettingEngineDocumentsFailed = (error: string) => action(OpinionNettingEngineActionTypes.FETCH_NETTING_ENGINE_DOCUMENTS_FAILED, error);

export const openNettingEngineDocumentStarted = (documentId: number, shouldRedirect = true) => action(OpinionNettingEngineActionTypes.OPEN_NETTING_ENGINE_DOCUMENT_STARTED, { documentId, shouldRedirect });
export const openNettingEngineDocumentSuccessful = (document: NettingEngineTheory) => action(OpinionNettingEngineActionTypes.OPEN_NETTING_ENGINE_DOCUMENT_SUCCESSFUL, { document });
export const openNettingEngineDocumentFailed = (error: string) => action(OpinionNettingEngineActionTypes.OPEN_NETTING_ENGINE_DOCUMENT_FAILED, { error });

export const updateNettingEngineField = (key: keyof NettingEngineTheory, value: string | string[] | boolean | null, counterpartyKey?: string) => action(OpinionNettingEngineActionTypes.UPDATE_NETTING_ENGINE_FIELDS, { key, value, counterpartyKey });

export const calculateExistingDocumentsNettingEngineStarted = () => action(OpinionNettingEngineActionTypes.CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_STARTED);
export const calculateExistingDocumentsNettingEngineSuccessful = (results: NettingResponseInformation) => action(OpinionNettingEngineActionTypes.CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_SUCCESSFUL, results);
export const calculateExistingDocumentsNettingEngineFailed = (error: string) => action(OpinionNettingEngineActionTypes.CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_FAILED, error);

export const resetExistingDocumentsState = () => action(OpinionNettingEngineActionTypes.RESET_DOCUMENTS_STATE);

export const toggleNettingEngineView = (view: NettingEngineTableView) => action(OpinionNettingEngineActionTypes.TOGGLE_NETTING_ENGINE_VIEW, view);

export const toggleDocumentSummaryView = (view: AnalysisView) => action(OpinionNettingEngineActionTypes.TOGGLE_DOCUMENT_SUMMARY_VIEW, view);

// Table Filters and Pagination
export const setNettingEngineDocumentTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS, { key, value, type });
export const clearNettingEngineDocumentTableFilters = (resetSelectedFilterId = true) => action(OpinionNettingEngineActionTypes.CLEAR_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS, resetSelectedFilterId);
export const setNettingEngineDocumentTableColumnSort = (columnSort: ColumnSort | undefined) => action(OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENT_TABLE_COLUMN_SORT, columnSort);
export const setNettingEngineDocumentsPageSize = (pageSize: number) => action(OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENTS_PAGE_SIZE, pageSize);
export const nettingEngineDocumentsPaginationNext = () => action(OpinionNettingEngineActionTypes.NETTING_ENGINE_DOCUMENTS_PAGINATION_NEXT);
export const nettingEngineDocumentsPaginationPrevious = () => action(OpinionNettingEngineActionTypes.NETTING_ENGINE_DOCUMENTS_PAGINATION_PREVIOUS);
