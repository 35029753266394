import React, { useCallback, useMemo } from 'react';
import { noop } from 'lodash/fp';

import styles from '../../Analytics.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { fetchDatasetFieldRiskToleranceStarted, setSelectedDatasetRiskField } from '../../store';
import { Button } from '../../../../shared/button/Button';
import { RiskToleranceDataset, SelectedRiskField } from '../../../../admin/risk-tolerance/store';
import { TooltipList } from '../../../../shared/tooltip';
import { RiskToleranceDatasetsMenu } from '../../../../shared/risk-tolerance/RiskToleranceDatasetsMenu';
import { getMyDatasetDefinitions, getCurrentRiskToleranceConfig, HiddenFields, getDocumentSpecificHiddenFields } from '../../../../admin/my-datasets/store';
import { getAllDocumentNames } from '../../../../admin/documents/store';

interface DocumentDatasetsRiskToleranceMenuProps {
    datasetId: number;
    allRiskToleranceDatasets: RiskToleranceDataset[];
    back: () => void;
    height: number;
    width: number;
    hiddenFields: HiddenFields;
    testId?: string;
    entityId?: number;
    isPreExecution?: boolean;
    selectedDatasetId: number | null;
}

export const DocumentDatasetsRiskToleranceMenu: React.FC<DocumentDatasetsRiskToleranceMenuProps> = ({ datasetId, allRiskToleranceDatasets, back, height, width, hiddenFields, entityId, isPreExecution, selectedDatasetId }) => {
    const dispatch = useAppDispatch();
    const riskTolerance = useAppSelector(getCurrentRiskToleranceConfig);
    const myDatasetDefinitions = useAppSelector(getMyDatasetDefinitions);
    const documentHiddenFields = useAppSelector(getDocumentSpecificHiddenFields);
    const allDocumentNames = useAppSelector(getAllDocumentNames);

    const agreementType = useMemo(() => allRiskToleranceDatasets.find(riskDataset => riskDataset.datasetId === datasetId)?.agreementType || '', [allRiskToleranceDatasets, datasetId]);
    const documentNames = useMemo(() => allRiskToleranceDatasets.find(riskDataset => riskDataset.datasetId === datasetId)?.documentNames || null, [allRiskToleranceDatasets, datasetId]);

    const selectRiskField = useCallback((riskField: SelectedRiskField) => {
        dispatch(setSelectedDatasetRiskField(riskField.datasetId, riskField.fieldId));
        dispatch(fetchDatasetFieldRiskToleranceStarted(1, entityId, isPreExecution));
    }, [dispatch, entityId, isPreExecution]);

    return (
        <div className={styles.riskToleranceDatasetWrapper} style={{ height, width }} data-testid='risk-tolerance-dataset-wrapper'>
            <TooltipList overlayText={documentNames} placement='left'>
                <div className={styles.riskToleranceDatasetHeader} data-testid='risk-tolerance-dataset-header'>{agreementType}</div>
            </TooltipList>
            <div className={styles.contentWrapper} data-testid='risk-tolerance-dataset-content-wrapper'>
                <RiskToleranceDatasetsMenu
                    datasetId={datasetId}
                    setRiskToleranceField={selectRiskField}
                    riskTolerance={riskTolerance}
                    selectedField={null}
                    datasetDefinitions={myDatasetDefinitions}
                    showRiskFieldToggles={false}
                    toggleRiskField={noop}
                    hiddenFields={hiddenFields}
                    removeClientHiddenFields={true}
                    documentHiddenFields={documentHiddenFields}
                    allDocumentNames={allDocumentNames}
                    agreementTypeDatasetId={selectedDatasetId}
                />
            </div>
            <Button label='Back' onClick={back} />
        </div>
    );
};
