import { toast } from 'react-toastify';
import { all, call, fork, put, select, takeEvery, debounce, takeLeading } from 'redux-saga/effects';
import Fuse from 'fuse.js';
import { flatten, flow, isEqual, isNull, isUndefined, toArray, unset } from 'lodash/fp';
import { push } from 'redux-first-history';

import { fetchAllDropdownLists } from '../../../services/dropdownList';
import { DropdownList, DropdownListDB } from '../../admin/dropdown-lists/store';
import { DropdownOption } from '../../shared/dropdown/Dropdown';
import { fetchClauseLibraryDropdownOptionsFailed, fetchClauseLibraryDropdownOptionsSuccessful, saveClauseFailed, saveClauseStarted, saveClauseSuccessful, setClauseCanSave, updateFuzzyMatchResults, addClientTagStarted, addClientTagSuccessful, addClientTagFailed, setClauseTableTab, deleteClauseSuccessful, deleteClauseStarted, deleteClauseFailed, updateAllClientTags, deleteTagStarted, deleteTagSuccessful, deleteTagFailed, setClientTagCanSave, saveTagStarted, updateSelectedClientTagClauseUsage, saveTagSuccessful, saveTagFailed, addSystemTagStarted, addSystemTagFailed, addSystemTagSuccessful, setTemplateTagCanSave, saveTemplateTagStarted, saveTemplateTagSuccessful, saveTemplateTagFailed, deleteTemplateTagStarted, deleteTemplateTagSuccessful, deleteTemplateTagFailed, setSelectedTemplateTag, fetchAllClausesStarted, fetchAllClausesSuccessful, fetchAllClausesFailed, redirectToOpinion } from './actions';
import { getClause, getClauseLibraryDropdownOptions, getClauseTableTab, getCurrentClause, getTagTerm, getClauseFilters, getClauseColumnSort, getClausePageSize, getSelectedClientTag, getCurrentSelectedClientTag, getSelectedTemplateTag, getCurrentSelectedTemplateTag, getClausesPageNumber } from './selectors';
import { ClauseLibraryActionTypes, ClauseTagDropdownOptions, ClauseTags, FuzzyMatchTags, Clause, ClauseTagDropdown, ClientTag, ClauseTableTab, CurrentClientTag, SelectedSystemTag, LinkedClauseOpinion } from './types';
import { dropdownOptionsByTagType } from './reducer';
import { saveClause, getAllClientTags, addNewClientTag, getAllClauses, deleteClause, deleteClientTag, saveClientTag, addNewSystemTag, saveTemplateTag, SaveTemplateTagRequest, deleteTemplateTag } from '../../../services/clause';
import { TableFilters } from '../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../shared/table/ArkTable';
import { initialClause } from '../../constants/clauseLibrary';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { FeaturePermission } from '../../admin/users/store';
import { fetchOpinionsStarted, openOpinionStarted } from '../../opinions/my-opinions/store';

export const stripDropdownList = (list: DropdownListDB): DropdownList => flow(
    unset('clientId'),
    unset('createdBy'),
    unset('createdDate'),
    unset('modifiedBy'),
    unset('modifiedDate')
)(list);

export const getTagDropdownCategory = (value: keyof ClauseTags) => {
    switch (value) {
        case 'agreementType':
            return ClauseTagDropdown.AGREEMENT;
        case 'counterpartyType':
            return ClauseTagDropdown.COUNTERPARTY;
        case 'productType':
            return ClauseTagDropdown.PRODUCT;
        case 'provisionType':
            return ClauseTagDropdown.PROVISION;
        case 'opinions':
            return ClauseTagDropdown.OPINIONS;
        case 'miscellaneous':
        default:
            return ClauseTagDropdown.MISCELLANEOUS;
    }
};

export const addTagToDropdownOptions = (options: DropdownOption[], tag: string): DropdownOption[] => {
    options.push({ value: tag, label: tag });
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
};

export const getTagDropdownOptions = (dropdownLists: DropdownListDB[], dropdownName: ClauseTagDropdown) => dropdownLists.find(({ name }) => name === dropdownName)?.options.sort((a, b) => a.localeCompare(b)).map(value => ({ value, label: value })) || [];

export const getClauseLibraryDropdownLists = (dropdownLists: DropdownListDB[], allClientSpecificTags: ClientTag[]): { clientSpecificTags: ClientTag[], dropdownOptions: ClauseTagDropdownOptions } => {
    const jurisdictionOptions: DropdownOption[] = getTagDropdownOptions(dropdownLists, ClauseTagDropdown.JURISDICTION);
    const agreementTypeOptions: DropdownOption[] = getTagDropdownOptions(dropdownLists, ClauseTagDropdown.AGREEMENT);
    const productTypeOptions: DropdownOption[] = getTagDropdownOptions(dropdownLists, ClauseTagDropdown.PRODUCT);
    const counterpartyTypeOptions: DropdownOption[] = getTagDropdownOptions(dropdownLists, ClauseTagDropdown.COUNTERPARTY);
    const provisionTypeOptions: DropdownOption[] = getTagDropdownOptions(dropdownLists, ClauseTagDropdown.PROVISION);
    const miscellaneousSystemOptions: DropdownOption[] = getTagDropdownOptions(dropdownLists, ClauseTagDropdown.MISCELLANEOUS);
    const opinionsOptions: DropdownOption[] = getTagDropdownOptions(dropdownLists, ClauseTagDropdown.OPINIONS);
    const changeTagDropdownOption = (category: keyof ClauseTags, tag: string) => {
        switch (category) {
            case 'agreementType': {
                addTagToDropdownOptions(agreementTypeOptions, tag);
            }
                break;
            case 'jurisdiction': {
                addTagToDropdownOptions(jurisdictionOptions, tag);
            }
                break;
            case 'counterpartyType': {
                addTagToDropdownOptions(counterpartyTypeOptions, tag);
            }
                break;
            case 'provisionType': {
                addTagToDropdownOptions(provisionTypeOptions, tag);
            }
                break;
            case 'productType': {
                addTagToDropdownOptions(productTypeOptions, tag);
            }
                break;
            case 'opinions': {
                addTagToDropdownOptions(opinionsOptions, tag);
            }
        }
    };
    let miscellaneousClientTags: DropdownOption[] = [];
    allClientSpecificTags.map(({ tag, category }) => {
        if (category !== 'miscellaneous') {
            changeTagDropdownOption(category, tag);
        } else {
            miscellaneousClientTags.push(({ value: tag, label: tag }));
        }
    });
    return { clientSpecificTags: allClientSpecificTags, dropdownOptions: { jurisdictionOptions, agreementTypeOptions, productTypeOptions, counterpartyTypeOptions, provisionTypeOptions, miscellaneousOptions: [...miscellaneousSystemOptions, ...miscellaneousClientTags], opinionsOptions, miscellaneousSystemOptions } };
};

export function* attemptFetchAllClauseLibraryDropdownLists() {
    try {
        const dropdownLists: DropdownListDB[] = yield call(fetchAllDropdownLists);
        const allClientSpecificTags: ClientTag[] = yield call(getAllClientTags);
        const { clientSpecificTags, dropdownOptions } = getClauseLibraryDropdownLists(dropdownLists, allClientSpecificTags);
        yield put(updateAllClientTags(clientSpecificTags));
        yield put(fetchClauseLibraryDropdownOptionsSuccessful(dropdownOptions));
    } catch (e) {
        yield put(fetchClauseLibraryDropdownOptionsFailed((e as Error).message));
        toast.error('Unable to fetch the latest dropdown list options. Please try again.');
    }
}

function* fetchAllClauseLibraryDropdownListsWatcher() {
    yield takeEvery([ClauseLibraryActionTypes.FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_STARTED], attemptFetchAllClauseLibraryDropdownLists);
}

export function* attemptFuzzyMatch() {
    try {
        const { tags, systemTemplate } = yield select(getClause);
        const allClauseTags = flatten(toArray(tags)).map(tag => tag.toLowerCase());
        const clauseLibraryDropdownOptions: ClauseTagDropdownOptions = yield select(getClauseLibraryDropdownOptions);
        const tagTerm: string = yield select(getTagTerm);
        const getFuzzyMatchesByType = (key: keyof ClauseTags, value: string) => {
            const isSystemTemplate = systemTemplate === 1;
            const fuzzyMatchOptions = {
                includeScore: true,
                threshold: 0.5,
                keys: ['value']
            };
            const entityFuse = new Fuse(dropdownOptionsByTagType(key, clauseLibraryDropdownOptions, isSystemTemplate), fuzzyMatchOptions);
            const fuzzyMatches = entityFuse.search(value);
            return fuzzyMatches.map(({ item, score }) => ({ key, value: item.value, score: score! }));
        };
        const results = Object.keys(tags).map(value => getFuzzyMatchesByType(value as keyof ClauseTags, tagTerm));
        let sortedMatches: FuzzyMatchTags[] = flatten(results).sort((a, b) => a.score! - b.score!);
        sortedMatches = sortedMatches.filter(({ value }) => !allClauseTags.includes(value.toLowerCase())).slice(0, 10);
        yield put(updateFuzzyMatchResults(sortedMatches));
    } catch (e) {
        toast.error('Unable to find any suggestions. Please try again.');
    }
}

function* fuzzyMatchWatcher() {
    yield debounce(1000, ClauseLibraryActionTypes.CHECK_FUZZY_MATCH, attemptFuzzyMatch);
}

export function* attemptCheckClauseUpdated() {
    try {
        const currentClause: Clause | null = yield select(getCurrentClause);
        const originalClause: Clause = isNull(currentClause) ? initialClause() : currentClause;
        const clause: Clause = yield select(getClause);
        const clauseHasTitle = clause.title !== '';
        const clauseUpdated = !isEqual(originalClause, clause);
        const canSave = clauseHasTitle && clauseUpdated;
        yield put(setClauseCanSave(canSave));
    } catch (e) {
        toast.error('Unable to check if the clause has updated. Please try again.');
    }
}

function* checkClauseUpdatedWatcher() {
    yield debounce(1000, [ClauseLibraryActionTypes.UPDATE_CLAUSE, ClauseLibraryActionTypes.UPDATE_CLAUSE_TAGS, ClauseLibraryActionTypes.ADD_SYSTEM_TAG_SUCCESSFUL], attemptCheckClauseUpdated);
}

export function* attemptCheckClientTagUpdated() {
    try {
        const selectedClientTag: CurrentClientTag | null = yield select(getSelectedClientTag);
        const currentSelectedClientTag: CurrentClientTag | null = yield select(getCurrentSelectedClientTag);
        const tagUpdated = !isEqual(selectedClientTag, currentSelectedClientTag);
        const clausesAdded = selectedClientTag?.includedClauses.filter(clauseId => !currentSelectedClientTag?.includedClauses.includes(clauseId)) || [];
        const clausesRemoved = currentSelectedClientTag?.includedClauses.filter(clauseId => !selectedClientTag?.includedClauses.includes(clauseId)) || [];
        if (clausesAdded.length > 0) {
            yield put(updateSelectedClientTagClauseUsage('clausesAdded', clausesAdded));
        }
        if (clausesRemoved.length > 0) {
            yield put(updateSelectedClientTagClauseUsage('clausesRemoved', clausesRemoved));
        }
        yield put(setClientTagCanSave(tagUpdated));
    } catch (e) {
        toast.error('Unable to check if the tag has updated. Please try again.');
    }
}

function* checkClientTagUpdatedWatcher() {
    yield debounce(1000, [ClauseLibraryActionTypes.UPDATE_SELECTED_CLIENT_TAG], attemptCheckClientTagUpdated);
}

export function* attemptCheckTemplateTagUpdated() {
    try {
        const selectedTemplateTag: SelectedSystemTag | null = yield select(getSelectedTemplateTag);
        const currentSelectedTemplateTag: SelectedSystemTag | null = yield select(getCurrentSelectedTemplateTag);
        const tagUpdated = !isEqual(selectedTemplateTag, currentSelectedTemplateTag);
        yield put(setTemplateTagCanSave(tagUpdated));
    } catch (e) {
        toast.error('Unable to check if the tag has updated. Please try again.');
    }
}

function* checkTemplateTagUpdatedWatcher() {
    yield debounce(1000, [ClauseLibraryActionTypes.UPDATE_SELECTED_TEMPLATE_TAG], attemptCheckTemplateTagUpdated);
}

export function* attemptAddClientClauseTag({ payload }: ReturnType<typeof addClientTagStarted>) {
    try {
        const currentClientTags: ClientTag[] = yield call(getAllClientTags);
        const isNewTag = !currentClientTags.map(({ tag }) => tag).includes(payload);
        if (isNewTag) {
            const savedClientTags: ClientTag[] = yield call(addNewClientTag, { tag: payload });
            yield put(addClientTagSuccessful(savedClientTags));
        }
    } catch (e) {
        yield put(addClientTagFailed((e as Error).message));
        toast.error('Unable to add the tag. Please try again.');
    }
}

function* addClientClauseTagWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.ADD_CLIENT_TAG_STARTED, attemptAddClientClauseTag);
}

export function* attemptAddSystemClauseTag({ payload }: ReturnType<typeof addSystemTagStarted>) {
    try {
        const { newTag, category, updatedTags } = payload;

        const dropdownLists: DropdownListDB[] = yield call(fetchAllDropdownLists);
        const listToUpdate = dropdownLists.find(({ name }) => name === getTagDropdownCategory(category));
        if (!isUndefined(listToUpdate)) {
            const dropdownList = stripDropdownList(listToUpdate);
            const updatedList = { ...dropdownList, options: [...dropdownList.options, newTag] };
            const { allDropdownLists, allClientTags }: { allDropdownLists: DropdownListDB[]; allClientTags: ClientTag[]; } = yield call(addNewSystemTag, { dropdownList: updatedList, newTag, category });
            const { clientSpecificTags, dropdownOptions } = getClauseLibraryDropdownLists(allDropdownLists, allClientTags);
            yield put(updateAllClientTags(clientSpecificTags));
            yield put(fetchClauseLibraryDropdownOptionsSuccessful(dropdownOptions));
            yield put(addSystemTagSuccessful(category, updatedTags));
        }
    } catch (e) {
        yield put(addSystemTagFailed((e as Error).message));
        toast.error('Unable to add the tag. Please try again.');
    }
}

function* addSystemClauseTagWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.ADD_SYSTEM_TAG_STARTED, attemptAddSystemClauseTag);
}

export function* attemptDeleteClientTag({ payload }: ReturnType<typeof deleteTagStarted>) {
    try {
        const { clauseTagId, includedClauses } = payload;
        const allClientTags: ClientTag[] = yield call(deleteClientTag, { clauseTagId, includedClauses });
        yield put(deleteTagSuccessful(allClientTags));
        const pageNumber: number = yield select(getClausesPageNumber);
        yield put(fetchAllClausesStarted(pageNumber));
    } catch (e) {
        yield put(deleteTagFailed((e as Error).message));
        toast.error('Unable to delete the tag. Please try again.');
    }
}

function* deleteClientTagWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.DELETE_CLIENT_TAG_STARTED, attemptDeleteClientTag);
}

export function* attemptSaveClientTag({ payload }: ReturnType<typeof saveTagStarted>) {
    try {
        const { clauseTagId, includedClauses, tag, category, clausesAdded, clausesRemoved } = payload;
        const allClientTags: ClientTag[] = yield call(saveClientTag, { clauseTagId, tag, category, includedClauses, clausesAdded, clausesRemoved });
        yield put(saveTagSuccessful(allClientTags));
        const pageNumber: number = yield select(getClausesPageNumber);
        yield put(fetchAllClausesStarted(pageNumber));
        yield call(attemptFetchAllClauseLibraryDropdownLists);
    } catch (e) {
        yield put(saveTagFailed((e as Error).message));
        toast.error('Unable to save the tag. Please try again.');
    }
}

function* saveClientTagWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.SAVE_CLIENT_TAG_STARTED, attemptSaveClientTag);
}

export function* attemptSaveTemplateTag({ payload }: ReturnType<typeof saveTemplateTagStarted>) {
    try {
        const { tag, category } = payload;
        const currentSelectedTemplateTag: SelectedSystemTag = yield select(getCurrentSelectedTemplateTag);
        const currentCategory = currentSelectedTemplateTag.category;
        const currentTag = currentSelectedTemplateTag.tag;
        const categoryHasChanged = category !== currentCategory;
        const tagWordingHasChanged = tag !== currentTag;

        const dropdownLists: DropdownListDB[] = yield call(fetchAllDropdownLists);
        const listToUpdate = dropdownLists.find(({ name }) => name === getTagDropdownCategory(currentCategory))!;
        let dropdownList = stripDropdownList(listToUpdate);
        let filteredOptions = dropdownList.options.filter(option => option !== currentTag);
        if (!categoryHasChanged) {
            // If the category is the same then we add the tag to the dropdown list
            filteredOptions.push(tag);
        }
        const currentDropdownList = { ...dropdownList, options: filteredOptions };

        let updatedDropdownList: DropdownList | undefined = undefined;
        if (categoryHasChanged) {
            // If the category has changed, we need to add the tag to the new dropdown list
            const listToUpdate = dropdownLists.find(({ name }) => name === getTagDropdownCategory(category))!;
            let dropdownList = stripDropdownList(listToUpdate);
            const options = [...dropdownList.options, tag];
            updatedDropdownList = { ...dropdownList, options };
        }

        let request: SaveTemplateTagRequest = {
            currentTag,
            updatedTag: tagWordingHasChanged ? tag : undefined,
            currentCategory,
            updatedCategory: categoryHasChanged ? category : undefined,
            currentDropdownList,
            updatedDropdownList
        };
        yield call(saveTemplateTag, request);
        yield call(attemptFetchAllClauseLibraryDropdownLists);
        yield call(fetchRelevantClauses);
        yield put(saveTemplateTagSuccessful());
        yield put(setSelectedTemplateTag(null));
    } catch (e) {
        yield put(saveTemplateTagFailed((e as Error).message));
        toast.error('Unable to save the tag. Please try again.');
    }
}

function* saveTemplateTagWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.SAVE_TEMPLATE_TAG_STARTED, attemptSaveTemplateTag);
}

export function* attemptDeleteTemplateTag({ payload }: ReturnType<typeof deleteTemplateTagStarted>) {
    try {
        const { tag, category } = payload;
        const dropdownLists: DropdownListDB[] = yield call(fetchAllDropdownLists);
        const listToUpdate = dropdownLists.find(({ name }) => name === getTagDropdownCategory(category))!;
        let dropdownList = stripDropdownList(listToUpdate);
        const filteredOptions = dropdownList.options.filter(option => option !== tag);
        const updatedDropdownList = { ...dropdownList, options: filteredOptions };

        yield call(deleteTemplateTag, { tagToDelete: tag, tagCategory: category, updatedDropdownList });
        yield call(attemptFetchAllClauseLibraryDropdownLists);
        yield call(fetchRelevantClauses);
        yield put(deleteTemplateTagSuccessful());
        yield put(setSelectedTemplateTag(null));
    } catch (e) {
        yield put(deleteTemplateTagFailed((e as Error).message));
        toast.error('Unable to delete the tag. Please try again.');
    }
}

function* deleteTemplateTagWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.DELETE_TEMPLATE_TAG_STARTED, attemptDeleteTemplateTag);
}

export function* fetchRelevantClauses() {
    const pageNumber: number = yield select(getClausesPageNumber);
    yield put(fetchAllClausesStarted(pageNumber));
}

export function* attemptSaveClause({ payload }: ReturnType<typeof saveClauseStarted>) {
    try {
        const clause: Clause = yield select(getClause);
        const savedClause: Clause = yield call(saveClause, { clause });
        yield put(saveClauseSuccessful(savedClause, payload));
        yield call(fetchRelevantClauses);
        toast('Clause saved successfully.');
    } catch (e) {
        yield put(saveClauseFailed((e as Error).message));
        toast.error('Unable to save the clause. Please try again.');
    }
}

function* saveClauseWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.SAVE_CLAUSE_STARTED, attemptSaveClause);
}

export function* attemptDeleteClause({ payload }: ReturnType<typeof deleteClauseStarted>) {
    try {
        const { clauseId } = payload;
        yield call(deleteClause, { clauseId });
        yield put(deleteClauseSuccessful());
        yield call(fetchRelevantClauses);
        toast('Clause deleted successfully.');
    } catch (e) {
        yield put(deleteClauseFailed((e as Error).message));
        toast.error('Unable to delete the clause. Please try again.');
    }
}

function* deleteClauseWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.DELETE_CLAUSE_STARTED, attemptDeleteClause);
}

export function* attemptFetchAllClauses({ payload }: ReturnType<typeof fetchAllClausesStarted>) {
    try {
        const filters: TableFilters = yield select(getClauseFilters);
        const columnSort: ColumnSort | undefined = yield select(getClauseColumnSort);
        const pageSize: number = yield select(getClausePageSize);
        const tableTab: ClauseTableTab = yield select(getClauseTableTab);
        const isSystemTemplate = tableTab !== ClauseTableTab.CLIENT_CLAUSE_LIBRARY;
        const isCurrent = tableTab === ClauseTableTab.CURRENT_TEMPLATES;
        const isMiscellaneous = tableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE;
        const { clauses, total, linkedClauseOpinions }: { clauses: Clause[], total: number, linkedClauseOpinions: LinkedClauseOpinion[] } = yield call(getAllClauses, { isSystemTemplate, isCurrent, isMiscellaneous, pageNumber: payload, filters, columnSort, pageSize });
        yield put(fetchAllClausesSuccessful(clauses, total, payload, linkedClauseOpinions));
    } catch (e) {
        yield put(fetchAllClausesFailed((e as Error).message));
        toast.error('Unable to fetch clauses. Please try again.');
    }
}

function* fetchAllClausesWatcher() {
    yield takeEvery([ClauseLibraryActionTypes.FETCH_ALL_CLAUSES_STARTED], attemptFetchAllClauses);
}

export function* redirectClauseTab({ payload }: ReturnType<typeof setClauseTableTab>) {
    const { tab, redirect } = payload;
    let navigationPath = '/clause-library';
    const hasSystemClausePermission: boolean = yield select(getUserHasFeaturePermissionNoAdmin([FeaturePermission.SYSTEM_CLAUSE_MANAGEMENT]));

    if (tab === ClauseTableTab.CURRENT_TEMPLATES) {
        navigationPath = navigationPath.concat('/current');
    }
    if (tab === ClauseTableTab.HISTORIC_TEMPLATES) {
        navigationPath = navigationPath.concat('/historic');
    }
    if (tab === ClauseTableTab.MISCELLANEOUS_TEMPLATE && hasSystemClausePermission) {
        navigationPath = navigationPath.concat('/miscellaneous');
    }
    if (redirect) {
        yield put(push(navigationPath));
    }
}

function* redirectClauseTabWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.TOGGLE_CLAUSE_TABLE_VIEW, redirectClauseTab);
}

export function* attemptRedirectToOpinion({ payload }: ReturnType<typeof redirectToOpinion>) {
    const { opinionId, location } = payload;
    yield put(fetchOpinionsStarted(1));
    yield put(openOpinionStarted(location, 'application/pdf', opinionId));
}

function* redirectToOpinionWatcher() {
    yield takeLeading(ClauseLibraryActionTypes.REDIRECT_TO_OPINION, attemptRedirectToOpinion);
}

// Clause Pagination Sagas
export function* clauseTablePaginationNext() {
    const pageNumber: number = yield select(getClausesPageNumber);
    yield put(fetchAllClausesStarted(pageNumber + 1));
}

function* clauseLibraryPaginationNextWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.CLAUSE_LIBRARY_PAGINATION_NEXT, clauseTablePaginationNext);
}

export function* clauseTablePaginationPrevious() {
    const pageNumber: number = yield select(getClausesPageNumber);
    yield put(fetchAllClausesStarted(pageNumber - 1));
}

function* clauseLibraryPaginationPreviousWatcher() {
    yield takeEvery(ClauseLibraryActionTypes.CLAUSE_LIBRARY_PAGINATION_PREVIOUS, clauseTablePaginationPrevious);
}

export function* clauseTableConfigUpdated() {
    yield put(fetchAllClausesStarted(1));
}

function* clauseLibraryTableConfigUpdatedWatcher() {
    yield takeEvery([ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_TABLE_FILTERS, ClauseLibraryActionTypes.CLEAR_CLAUSE_LIBRARY_TABLE_FILTERS, ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_TABLE_COLUMN_SORT, ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_PAGE_SIZE], clauseTableConfigUpdated);
}

export function* clauseLibrarySaga() {
    yield all([
        fork(fetchAllClauseLibraryDropdownListsWatcher),
        fork(fuzzyMatchWatcher),
        fork(checkClauseUpdatedWatcher),
        fork(saveClauseWatcher),
        fork(addClientClauseTagWatcher),
        fork(clauseLibraryPaginationNextWatcher),
        fork(clauseLibraryPaginationPreviousWatcher),
        fork(clauseLibraryTableConfigUpdatedWatcher),
        fork(redirectClauseTabWatcher),
        fork(deleteClauseWatcher),
        fork(deleteClientTagWatcher),
        fork(checkClientTagUpdatedWatcher),
        fork(saveClientTagWatcher),
        fork(addSystemClauseTagWatcher),
        fork(checkTemplateTagUpdatedWatcher),
        fork(saveTemplateTagWatcher),
        fork(deleteTemplateTagWatcher),
        fork(fetchAllClausesWatcher),
        fork(redirectToOpinionWatcher)
    ]);
}
