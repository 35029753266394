import classnames from 'classnames';
import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { CurrencyValue } from '../../shared/number/CurrencyNumber';
import { Number as NumberInput } from '../../shared/number/Number';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import styles from './Fields.module.scss';

const { green, gold, amethyst, primary, disabledGrey, amber } = styles;

interface CurrencyAmountFieldProps {
    id: string;
    currencyValue: CurrencyValue;
    index: number;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    disabled?: boolean;
    placeholder?: string;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean;
    showAIModifiedUserCorrected?: boolean;
    isMLCorrection?: boolean;
    showClause?: boolean;
    isTable?: boolean;
    includedInAnnex?: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
}

export const CurrencyAmountField: React.FC<CurrencyAmountFieldProps> = ({
    id,
    currencyValue,
    disabled = false,
    index,
    sectionId,
    groupIndex,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    isMLCorrection = false,
    showClause = false,
    isTable = false,
    includedInAnnex = false,
    datasetId,
    parentFieldId,
    modalInstance
}) => {
    const dispatch = useAppDispatch();
    const allDropdownLists = useAppSelector(getAllDropdownLists);

    const { currency, value } = currencyValue;

    const currencyCodeOptions: DropdownOption[] = useMemo(() => {
        const currencyCodes = allDropdownLists.find(({ name }) => name === 'Currency Codes');
        return currencyCodes ? currencyCodes.options.map(type => ({ value: type, label: type })) : [];
    }, [allDropdownLists]);

    const updateValue = (value: CurrencyValue) => {
        if (isMLCorrection) {
            dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
        }
    };

    const updateDropdownOption = (option: DropdownOption | Options<DropdownOption> | null) => {
        let newValue = null;
        if (!isNull(option)) {
            newValue = (option as DropdownOption).value;
        }
        updateValue({ currency: newValue, value });
    };

    const updateNumberValue = (value: number | undefined) => updateValue({ currency, value });

    const currencyDropdownValue = currencyCodeOptions.find((code) => code.value === currency) || null;

    const isDisabled = useMemo(() => disabled || includedInAnnex, [disabled, includedInAnnex]);

    const controlBorderColor = useMemo(() => {
        if (!showDatasetUpdated && !showFieldUpdated && !showAIModified && !showAIModifiedUserCorrected) {
            return isDisabled ? disabledGrey : primary;
        }
        if (showAIModifiedUserCorrected) {
            return amber;
        }
        if (showAIModified) {
            return gold;
        }
        return showFieldUpdated ? amethyst : green;
    }, [showDatasetUpdated, showFieldUpdated, showAIModified, isDisabled, showAIModifiedUserCorrected]);

    return (
        <div className={classnames(styles.currencyAmountField, { [styles.withClauseLabel]: showClause && isTable })}>
            <div className={styles.inputWrapper}>
                <div className={styles.labelWrapper} style={{ width: sectionId ? '30%' : '40%' }}>
                    <Dropdown
                        onChange={updateDropdownOption}
                        value={currencyDropdownValue}
                        options={currencyCodeOptions}
                        menuPortalTarget={document.body}
                        disabled={disabled}
                        hideDropdownIndicator
                        controlBorderColor={controlBorderColor}
                        testId={`definition-currency-amount-${id}`}
                    />
                </div>
                <div className={styles.labelWrapper}>
                    <NumberInput
                        value={value}
                        onChange={updateNumberValue}
                        disabled={disabled}
                        controlBorderColor={controlBorderColor}
                    />
                </div>
            </div>
        </div>
    );
};
