import React, { useMemo } from 'react';
import classNames from 'classnames';

import { FieldList } from './FieldList';
import { FormDatasetBuilder } from './FormDatasetBuilder';
import { TableDatasetBuilder } from './TableDatasetBuilder';
import sharedStyles from './SharedBuilder.module.scss';
import { getDatasetPreviewOpen, toggleHelpModal, toggleIsAgency, toggleReorderSectionsModal, updateDatasetDefinitionTitle } from './store';
import { DatasetDefinition, DatasetType } from '../../datasets/store';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DefinitionBuilderPreview } from './preview/DefinitionBuilderPreview';
import { HelpModal } from './HelpModal';
import { fetchAllDropdownListsStarted } from '../dropdown-lists/store';
import { Form, Sort, Table } from '../../shared/icons';
import { Icon } from '../../shared/icon/Icon';
import { CustomTooltip } from '../../shared/tooltip';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Toggle } from '../../shared/toggle';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllClientsStarted } from '../clients/store';
import { IconButton } from '../../shared/button/IconButton';
import { ReorderSectionsModal } from './ReorderSectionsModal';

interface DefinitionBuilderProps {
    datasetDefinition: DatasetDefinition;
}

export const DefinitionBuilder: React.FC<DefinitionBuilderProps> = ({ datasetDefinition }) => {
    const [, screenHeight] = useWindowResize();
    const dispatch = useAppDispatch();
    const updateTitle = (title: string) => dispatch(updateDatasetDefinitionTitle(title));
    const toggleAgencyDefinition = (value: boolean) => dispatch(toggleIsAgency(value));

    useFetchStarted([fetchAllDropdownListsStarted()]);

    const { datasetTitle, datasetType, settings } = datasetDefinition;

    const previewOpen = useAppSelector(getDatasetPreviewOpen);
    const openHelpModal = () => dispatch(toggleHelpModal(true));
    const openSectionReorderModal = () => dispatch(toggleReorderSectionsModal());
    const builderIcon = datasetType === DatasetType.FORM ? Form : Table;
    const noDatasetTitle = useMemo(() => !!datasetDefinition && !datasetDefinition.datasetTitle, [datasetDefinition]);

    // This is the height of the modal minus other fixed height elements such as header, title and buttons wrapper
    const builderContentHeight = screenHeight * 0.8 - (41 + 60 + 58 + 20);

    useFetchStarted([fetchAllClientsStarted()]);

    return (
        <div className={sharedStyles.definitionBuilderWrapper} data-testid='definition-builder-wrapper'>
            {previewOpen ? (
                <DefinitionBuilderPreview datasetDefinition={datasetDefinition} />
            ) : (
                <>
                    <div className={sharedStyles.builderHeaderWrapper}>
                        <div className={sharedStyles.builderHeaderLabel} data-testid='definition-builder-header'>Dataset Builder</div>
                        <div className={sharedStyles.builderHeaderButtonWrapper}>
                            {datasetType === DatasetType.FORM && <div className={sharedStyles.reorderSectionsButton}><IconButton icon={Sort} onClick={openSectionReorderModal} withBorder fontSize={20} overlayText='Reorder Sections' /></div>}
                            <button className={sharedStyles.builderHelp} onClick={openHelpModal} data-testid='definition-builder-help-button'>?</button>
                        </div>
                    </div>
                    <div className={sharedStyles.builderHeader}>
                        <div className={sharedStyles.leftHeader}>
                            <input
                                data-testid='definition-builder-title'
                                className={classNames(sharedStyles.builderTitle, { [sharedStyles.builderTitleRequired]: noDatasetTitle })}
                                placeholder='Dataset Title...'
                                onChange={e => updateTitle(e.target.value)}
                                value={datasetTitle}
                                maxLength={128}
                            />
                            <div className={sharedStyles.agencyToggleWrapper}>
                                <CustomTooltip overlayText='Is there a chance this dataset could be part of an Agency agreement with multiple parties covered within one document?' trigger='click'>
                                    <div className={sharedStyles.agencyToggleTitle}>Agency?</div>
                                </CustomTooltip>
                                <Toggle
                                    checked={settings.isAgency || false}
                                    onChange={toggleAgencyDefinition}
                                    testId='definition-is-agency'
                                />
                            </div>
                        </div>
                        <div className={sharedStyles.builderTypeWrapper}>
                            <div className={sharedStyles.builderType}>{datasetType}</div>
                            <Icon icon={builderIcon} fontSize={40} />
                        </div>
                    </div>
                    <div className={sharedStyles.builderContent} style={{ height: `${builderContentHeight}px` }}>
                        <div className={sharedStyles.builderTable}>
                            {datasetType === DatasetType.FORM && <FormDatasetBuilder />}
                            {datasetType === DatasetType.TABLE && <TableDatasetBuilder />}
                        </div>
                        <FieldList datasetType={datasetType} />
                    </div>
                </>
            )}
            <HelpModal datasetType={datasetType} />
            <ReorderSectionsModal />
        </div>
    );
};
