import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppSelector } from '../../../hooks/react-redux';
import { getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { getFocusOptionsMultipleJurisdictions } from '../../constants/opinion';
import { DropdownOption } from '../dropdown/Dropdown';
import { Scrollable } from '../scrollable/Scrollable';
import styles from './Filter.module.scss';
import { FilterDropdownWithLabel } from './FilterDropdownWithLabel';
import { OpinionReportFilter } from '../../opinions/reporting/store';

interface OpinionFilterProps {
    jurisdiction: string[] | null;
    focus: string[] | null;
    updateFilter: (key: keyof OpinionReportFilter, value: string[] | null | boolean) => void;
    fontSize?: string;
}

export const OpinionFilter: React.FC<OpinionFilterProps> = ({
    updateFilter,
    jurisdiction,
    focus,
    fontSize = '14px'
}) => {
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const getDropdownListOptions = useCallback((listName: string) => {
        const dropdownList = dropdownLists.find(({ name }) => name === listName);
        return dropdownList ? dropdownList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const getDropdownValue = useCallback((initialValue: string[] | null, options: DropdownOption[]) => {
        if (isNull(initialValue)) {
            return null;
        }
        return options.filter(({ value }) => initialValue.includes(value)) || null;
    }, []);

    const jurisdictionOptions = getDropdownListOptions('OpinionJurisdiction');
    const jurisdictionValue = useMemo(() => getDropdownValue(jurisdiction, jurisdictionOptions), [jurisdiction, getDropdownValue, jurisdictionOptions]);

    const focusOptions = useMemo(() => getFocusOptionsMultipleJurisdictions(jurisdiction), [jurisdiction]);
    const focusValue = useMemo(() => getDropdownValue(focus, focusOptions), [focus, getDropdownValue, focusOptions]);

    const updateDropdown = (option: DropdownOption | Options<DropdownOption> | null, filterKey: keyof OpinionReportFilter) => {
        let value = null;
        if (!isNull(option) && (option as Options<DropdownOption>).length) {
            value = (option as Options<DropdownOption>).map(({ value }) => value);
        }
        updateFilter(filterKey, value);
    };

    const updateJurisdiction = (option: DropdownOption | Options<DropdownOption> | null) => updateDropdown(option, 'jurisdiction');
    const updateFocus = (option: DropdownOption | Options<DropdownOption> | null) => updateDropdown(option, 'focus');

    return (
        <Scrollable height='100' width='100%'>
            <div className={styles.filterSettingsWrapper}>
                <FilterDropdownWithLabel
                    label='Jurisdiction'
                    value={jurisdictionValue}
                    options={jurisdictionOptions}
                    onChange={updateJurisdiction}
                    testId='jurisdiction'
                    fontSize={fontSize}
                />
                <FilterDropdownWithLabel
                    label='Focus'
                    value={focusValue}
                    options={focusOptions}
                    onChange={updateFocus}
                    testId='focus'
                    fontSize={fontSize}
                />
            </div>
        </Scrollable>
    );
};
