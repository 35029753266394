import React, { useMemo, useState } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';

import { useAppSelector } from '../../../hooks/react-redux';
import { DropdownOption } from '../dropdown/Dropdown';
import { getBaseEntityDropdownOptions } from '../../admin/entity/store';
import { getAllClientDocumentGroups, getAvailableDocumentNames } from '../../admin/documents/store';
import { DATABASE_DATE_FORMAT, formatDate } from '../../../utils/luxon';
import { FilterDropdownWithLabel } from './FilterDropdownWithLabel';
import { DateFilter } from './DateFilter';
import { Radio } from '../radio/Radio';
import { DateFilter as DateFilterType, preExecutionOptions, SearchFilter } from '../../documents/search/store';
import styles from './Filter.module.scss';
import { Scrollable } from '../scrollable/Scrollable';

interface DocumentFilterProps {
    to: string | null;
    from: string | null;
    entityId: string[] | null;
    isExclusiveEntityFilter: boolean;
    includePreExecution: string | null;
    documentNameId?: string[] | null;
    documentNameGroupId?: string[] | null;
    showDocumentNameFilter?: boolean;
    updateFilter: (key: keyof SearchFilter, value: string[] | string | null | boolean) => void;
    updateDateFilter: (key: keyof DateFilterType, value: string | null) => void;
    fontSize?: string;
}

export const DocumentFilter: React.FC<DocumentFilterProps> = ({
    to,
    from,
    entityId,
    isExclusiveEntityFilter,
    includePreExecution,
    documentNameId = null,
    documentNameGroupId = null,
    showDocumentNameFilter = true,
    updateFilter,
    updateDateFilter,
    fontSize = '12px'
}) => {
    const [exclusiveFilterWarningVisible, setExclusiveFilterWarningVisible] = useState(false);
    const entityOptions = useAppSelector(getBaseEntityDropdownOptions);
    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const allDocumentNameGroups = useAppSelector(getAllClientDocumentGroups);

    const updateToDate = (value: Date | null) => updateDateFilter('to', isNull(value) ? value : formatDate(value, DATABASE_DATE_FORMAT));
    const updateFromDate = (value: Date | null) => updateDateFilter('from', isNull(value) ? value : formatDate(value, DATABASE_DATE_FORMAT));
    const toggleExclusiveFilter = () => {
        updateFilter('entityId', entityId?.slice(0, 2) || null);
        updateFilter('isExclusiveEntityFilter', !isExclusiveEntityFilter);
        setExclusiveFilterWarningVisible(false);
    };

    const mappedDocumentNames: DropdownOption[] = allDocumentNames.map(({ documentNameId, documentName }) => ({ value: documentNameId!.toString(), label: documentName }));
    const mappedDocumentNameGroups: DropdownOption[] = allDocumentNameGroups.map(({ clientDocumentGroupId, groupName }, i) => ({ value: clientDocumentGroupId!.toString(), label: groupName, isGroup: true, showDivider: i === allDocumentNameGroups.length - 1 }));
    const documentGroupNamesAndDocumentNamesOptions = [...mappedDocumentNameGroups, ...mappedDocumentNames];

    const entityFilter = useMemo(() => {
        const getEntityName = (val: string) => entityOptions.find(({ value }) => value === val)!.label;
        if (isNull(entityId)) {
            return null;
        }
        return entityId.map(value => ({ value, label: getEntityName(value) }));
    }, [entityId, entityOptions]);

    const documentNameAndGroupFilter = useMemo(() => {
        let documentNameFilter: DropdownOption[] | null = null;
        let documentGroupFilter: DropdownOption[] | null = null;
        const getDocumentName = (value: string) => allDocumentNames.find(({ documentNameId }) => documentNameId?.toString() === value)!.documentName;
        const getDocumentGroupName = (value: string) => allDocumentNameGroups.find(({ clientDocumentGroupId }) => clientDocumentGroupId?.toString() === value)!.groupName;

        if (!isNull(documentNameId)) {
            documentNameFilter = documentNameId.map(value => ({ value, label: getDocumentName(value) }));
        }

        if (!isNull(documentNameGroupId)) {
            documentGroupFilter = documentNameGroupId.map(value => ({ value, label: getDocumentGroupName(value), isGroup: true }));
        }

        if (isNull(documentNameFilter) && isNull(documentGroupFilter)) {
            return null;
        }

        return [...(documentNameFilter || []), ...(documentGroupFilter || [])];
    }, [documentNameId, allDocumentNames, allDocumentNameGroups, documentNameGroupId]);

    const preExecutionFilter = useMemo(() => preExecutionOptions.find(({ value }) => value === includePreExecution) || null, [includePreExecution]);

    const updatePreExecutionFilter = (option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = (option as DropdownOption).value;
        }
        updateFilter('includePreExecution', value);
    };

    const updateEntitiesFilter = (option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option) && (option as Options<DropdownOption>).length) {
            value = (option as Options<DropdownOption>).map(({ value }) => value);
            if (isExclusiveEntityFilter && value.length > 2) {
                setExclusiveFilterWarningVisible(true);
                return;
            }
            setExclusiveFilterWarningVisible(false);
        }
        updateFilter('entityId', value);
    };

    const updateDocumentFilter = (option: DropdownOption | Options<DropdownOption> | null) => {
        let documentNameIds = null;
        let groupDocumentNameIds = null;
        if (!isNull(option) && (option as Options<DropdownOption>).length) {
            documentNameIds = (option as Options<DropdownOption>).filter(({ isGroup }) => !isGroup).map(({ value }) => value) || null;
            groupDocumentNameIds = (option as Options<DropdownOption>).filter(({ isGroup }) => isGroup).map(({ value }) => value) || null;
        }
        updateFilter('documentNameId', documentNameIds);
        updateFilter('documentNameGroupId', groupDocumentNameIds);
    };

    const toDate = isNull(to) ? null : new Date(to);
    const fromDate = isNull(from) ? null : new Date(from);

    return (
        <Scrollable height='100' width='100%'>
            <div className={styles.filterSettingsWrapper}>
                <div className={styles.exclusiveWrapper}>
                    <Radio
                        checked={isExclusiveEntityFilter}
                        onChange={toggleExclusiveFilter}
                        onClick={toggleExclusiveFilter}
                        showWarning={exclusiveFilterWarningVisible}
                        warning='Max 2 options for exclusive filter'
                        testId='exclusive-filter'
                        label='Exclusive Filter'
                        fontSize={fontSize}
                    />
                </div>
                <FilterDropdownWithLabel
                    label='Parties'
                    value={entityFilter}
                    options={entityOptions}
                    onChange={updateEntitiesFilter}
                    testId='entities'
                    fontSize={fontSize}
                />
                {showDocumentNameFilter &&
                    <FilterDropdownWithLabel
                        label='Documents'
                        value={documentNameAndGroupFilter}
                        options={documentGroupNamesAndDocumentNamesOptions}
                        onChange={updateDocumentFilter}
                        testId='documents'
                        fontSize={fontSize}
                    />
                }
                <FilterDropdownWithLabel
                    label='Include Pre-Execution Documents'
                    value={preExecutionFilter}
                    options={preExecutionOptions}
                    onChange={updatePreExecutionFilter}
                    testId='pre-execution'
                    fontSize={fontSize}
                    isMulti={false}
                    isClearable={false}
                />
                <DateFilter
                    updateFromDate={updateFromDate}
                    updateToDate={updateToDate}
                    fromDate={fromDate}
                    toDate={toDate}
                    label='Executed Date'
                    fontSize={fontSize}
                />
            </div>
        </Scrollable>
    );
};
