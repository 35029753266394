import { isUndefined } from 'lodash/fp';
import React from 'react';

import { Button } from '../../shared/button/Button';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import styles from './MyDatasets.module.scss';

interface MyDatasetTileProps {
    datasetId: number;
    documentNames: string[];
    agreementType: string;
    preview: () => void;
    configure: () => void;
    riskTolerance?: () => void;
}

export const MyDatasetTile: React.FC<MyDatasetTileProps> = ({ datasetId, documentNames, agreementType, preview, configure, riskTolerance }) => (
    <div key={datasetId} className={styles.datasetTileWrapper}>
        <div className={styles.documentNamesWrapper}>
            <div className={styles.documentNamesHeader}>{agreementType}:</div>
            <div className={styles.documentNamesList}>
                <Scrollable>
                    <div>
                        {documentNames.map((name, i) => <div className={styles.documentName} key={i}>{name}</div>)}
                    </div>
                </Scrollable>
            </div>
        </div >
        <div className={styles.buttonWrapper}>
            <Button label='Preview' onClick={preview} />
            <Button label='Configure' onClick={configure} />
            {!isUndefined(riskTolerance) && <Button label='Risk Tolerance' onClick={riskTolerance} />}
        </div>
    </div>
);
