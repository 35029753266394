import { push } from 'redux-first-history';
import { flow, isEqual, isNull, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { all, call, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { fetchAllUserAttestationInstances, saveUserAttestationInstance } from '../../../services/attestation';
import {
    fetchAllUserAttestationInstancesSuccessful,
    fetchAllUserAttestationInstancesFailed,
    fetchAllUserAttestationInstancesStarted,
    setUserAttestationInstanceUpdated,
    saveUserAttestationInstanceStarted,
    saveUserAttestationInstanceSuccessful,
    saveUserAttestationInstanceFailed,
    updateAttestationAnswerValue,
    toggleAlertModalOpen,
    toggleUserAttestationInstanceModalOpen,
    toggleUserAttestationInstanceView
} from './actions';
import { getAllUserAttestationInstances, getAttestationColumnSort, getAttestationFilters, getAttestationsPageNumber, getAttestationsPageSize, getCompletedTableView, getSelectedUserAttestationInstance } from './selectors';
import { UserAttestationActionTypes, UserAttestationInstance, UserAttestationInstanceDB } from './types';
import { TableFilters } from '../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../shared/table/ArkTable';

export const stripUserAttestationInstance = (attestationInstance: UserAttestationInstanceDB): UserAttestationInstance => flow(
    unset('createdDate'),
    unset('createdBy'),
    unset('modifiedDate'),
    unset('modifiedBy'),
    unset('completedBy'),
    unset('completedDate'),
    unset('isArchived'),
    unset('clientId'),
    unset('userId')
)(attestationInstance);

export function* attemptFetchAllUserAttestationInstances({ payload }: ReturnType<typeof fetchAllUserAttestationInstancesStarted>) {
    try {
        const { completed, pageNumber } = payload;
        const filters: TableFilters = yield select(getAttestationFilters);
        const columnSort: ColumnSort | undefined = yield select(getAttestationColumnSort);
        const pageSize: number = yield select(getAttestationsPageSize);
        const { attestationInstances, total }: { attestationInstances: UserAttestationInstanceDB[]; total: number; } = yield call(fetchAllUserAttestationInstances, { completed, pageNumber, pageSize, columnSort, filters });
        yield put(fetchAllUserAttestationInstancesSuccessful(attestationInstances, total, pageNumber));
    } catch (e) {
        yield put(fetchAllUserAttestationInstancesFailed((e as Error).message));
        toast.error('Unable to fetch your attestations. Please try again.');
    }
}

function* fetchAllUserAttestationInstancesWatcher() {
    yield takeLatest(UserAttestationActionTypes.FETCH_ALL_USER_ATTESTATION_INSTANCES_STARTED, attemptFetchAllUserAttestationInstances);
}

export function* checkMyAttestation() {
    try {
        const selectedAttestationInstance: UserAttestationInstance = yield select(getSelectedUserAttestationInstance);
        const allMyAttestations: UserAttestationInstanceDB[] = yield select(getAllUserAttestationInstances);
        const existingAttestationForm = stripUserAttestationInstance(allMyAttestations.find(({ attestationInstanceId }) => attestationInstanceId === selectedAttestationInstance.attestationInstanceId)!);
        const attestationUpdated = !isEqual(selectedAttestationInstance, existingAttestationForm);
        yield put(setUserAttestationInstanceUpdated(attestationUpdated));
    } catch (e) {
        toast.error('Something went wrong when checking if attestation has been updated.');
    }
}

function* attestationInstanceUpdatedWatcher() {
    yield takeEvery([UserAttestationActionTypes.UPDATE_ATTESTATION_ANSWER_VALUE, UserAttestationActionTypes.UPDATE_ATTESTATION_USER_NOTES], checkMyAttestation);
}

export function* displayAlertMessage({ payload }: ReturnType<typeof updateAttestationAnswerValue>) {
    const { answer } = payload;
    const selectedAttestationInstance: UserAttestationInstance = yield select(getSelectedUserAttestationInstance);
    const { config: { redFlagAnswer, alertMessage }, id } = selectedAttestationInstance.attestations.find(({ id }) => id === payload.id)!;
    if (!isNull(answer) && answer === redFlagAnswer && alertMessage) {
        yield put(toggleAlertModalOpen(id));
    }
}

function* displayAlertMessageWatcher() {
    yield takeEvery(UserAttestationActionTypes.UPDATE_ATTESTATION_ANSWER_VALUE, displayAlertMessage);
}

export function* attemptSaveAttestationInstance({ payload }: ReturnType<typeof saveUserAttestationInstanceStarted>) {
    try {
        const { isDraft, userAnswerId } = payload;
        const completed: boolean = yield select(getCompletedTableView);
        const filters: TableFilters = yield select(getAttestationFilters);
        const columnSort: ColumnSort | undefined = yield select(getAttestationColumnSort);
        const pageSize: number = yield select(getAttestationsPageSize);
        const pageNumber: number = yield select(getAttestationsPageNumber);
        const attestation: UserAttestationInstance = yield select(getSelectedUserAttestationInstance);
        const { attestationInstances, total }: { attestationInstances: UserAttestationInstanceDB[]; total: number; } = yield call(saveUserAttestationInstance, { isDraft, attestation, userAnswerId, pageSize, pageNumber, filters, columnSort, completed });
        yield put(saveUserAttestationInstanceSuccessful(attestationInstances, total));
        yield put(push(`/my-attestations${completed ? '/completed' : ''}`));
    } catch (e) {
        yield put(saveUserAttestationInstanceFailed((e as Error).message));
        toast.error('Unable to save your attestation. Please try again.');
    }
}

function* saveUserAttestationWatcher() {
    yield takeLeading(UserAttestationActionTypes.SAVE_USER_ATTESTATION_INSTANCE_STARTED, attemptSaveAttestationInstance);
}

export function* redirectAttestations({ payload }: ReturnType<typeof toggleUserAttestationInstanceModalOpen>) {
    const { attestationInstance, isOpen } = payload;
    const completed: boolean = yield select(getCompletedTableView);
    if (isOpen && attestationInstance) {
        const navigationPath = `/my-attestations/${completed ? 'completed/' : ''}${attestationInstance.attestationInstanceId}`;
        yield put(push(navigationPath));
    } else if (!isOpen) {
        yield put(push(`/my-attestations${completed ? '/completed' : ''}`));
    }
}

function* redirectAttestationsWatcher() {
    yield takeEvery(UserAttestationActionTypes.TOGGLE_USER_ATTESTATION_INSTANCE_MODAL_OPEN, redirectAttestations);
}

export function* redirectAttestationTab({ payload }: ReturnType<typeof toggleUserAttestationInstanceView>) {
    const { completed, attestationInstanceId } = payload;
    const navigationPath = `/my-attestations${completed ? '/completed' : ''}${attestationInstanceId ? `/${attestationInstanceId}` : ''}`;
    yield put(push(navigationPath));
}

function* redirectAttestationTabWatcher() {
    yield takeEvery(UserAttestationActionTypes.TOGGLE_USER_ATTESTATION_INSTANCE_VIEW, redirectAttestationTab);
}

export function* paginationNext() {
    const pageNumber: number = yield select(getAttestationsPageNumber);
    const completed: boolean = yield select(getCompletedTableView);
    yield put(fetchAllUserAttestationInstancesStarted(completed, pageNumber + 1));
}

function* paginationNextWatcher() {
    yield takeEvery(UserAttestationActionTypes.ATTESTATIONS_PAGINATION_NEXT, paginationNext);
}

export function* paginationPrevious() {
    const pageNumber: number = yield select(getAttestationsPageNumber);
    const completed: boolean = yield select(getCompletedTableView);
    yield put(fetchAllUserAttestationInstancesStarted(completed, pageNumber - 1));
}

function* paginationPreviousWatcher() {
    yield takeEvery(UserAttestationActionTypes.ATTESTATIONS_PAGINATION_PREVIOUS, paginationPrevious);
}

export function* tableConfigUpdated() {
    const completed: boolean = yield select(getCompletedTableView);
    yield put(fetchAllUserAttestationInstancesStarted(completed, 1));
}

function* tableConfigUpdatedWatcher() {
    yield takeEvery([UserAttestationActionTypes.SET_ATTESTATION_TABLE_FILTERS, UserAttestationActionTypes.CLEAR_ATTESTATION_TABLE_FILTERS, UserAttestationActionTypes.SET_ATTESTATION_TABLE_COLUMN_SORT, UserAttestationActionTypes.SET_ATTESTATIONS_PAGE_SIZE], tableConfigUpdated);
}

export function* userAttestationsSaga() {
    yield all([
        fork(fetchAllUserAttestationInstancesWatcher),
        fork(attestationInstanceUpdatedWatcher),
        fork(saveUserAttestationWatcher),
        fork(displayAlertMessageWatcher),
        fork(redirectAttestationTabWatcher),
        fork(redirectAttestationsWatcher),
        fork(paginationNextWatcher),
        fork(paginationPreviousWatcher),
        fork(tableConfigUpdatedWatcher)
    ]);
}
