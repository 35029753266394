import React from 'react';

export const OpinionAnalytics: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg viewBox="0 0 64 64" width={width || '1em'} height={height || '1em'}>
        <g>
            <g>
                <path d="M55.29,13.76H39a3.25,3.25,0,0,1-2.61-1.33L31.06,5.07A6.24,6.24,0,0,0,26,2.5H8.71A6.22,6.22,0,0,0,2.5,8.71V55.29A6.22,6.22,0,0,0,8.71,61.5H55.29a6.22,6.22,0,0,0,6.21-6.21V20A6.22,6.22,0,0,0,55.29,13.76ZM8.71,5.5H26a3.25,3.25,0,0,1,2.61,1.33l5.31,7.36a6.24,6.24,0,0,0,5,2.57H55.29A3.21,3.21,0,0,1,58.5,20v1.86a6.18,6.18,0,0,0-3.21-.9H8.71a6.18,6.18,0,0,0-3.21.9V8.71A3.21,3.21,0,0,1,8.71,5.5ZM58.5,55.29a3.21,3.21,0,0,1-3.21,3.21H8.71A3.21,3.21,0,0,1,5.5,55.29V27.14a3.21,3.21,0,0,1,3.21-3.21H55.29a3.21,3.21,0,0,1,3.21,3.21Z" fill={color || 'currentColor'} stroke='none' />
                <path d="M49,51.1H16.48V28.88a1.5,1.5,0,0,0-3,0V52.6A1.5,1.5,0,0,0,15,54.1H49a1.5,1.5,0,0,0,0-3Z" fill={color || 'currentColor'} stroke='none' />
                <rect x="19.12" y="42.66" width="6.45" height="6.77" rx="1.5" fill={color || 'currentColor'} stroke='none' />
                <rect x="27.05" y="33.39" width="6.45" height="16.04" rx="1.5" fill={color || 'currentColor'} stroke='none' />
                <rect x="34.98" y="38.08" width="6.45" height="11.34" rx="1.5" fill={color || 'currentColor'} stroke='none' />
                <rect x="42.92" y="28.21" width="6.45" height="21.22" rx="1.5" fill={color || 'currentColor'} stroke='none' />
            </g>
        </g>
    </svg>
);
