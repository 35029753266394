import React, { useCallback, useMemo } from 'react';

import { DatasetSection } from '../../../datasets/store';
import { Icon } from '../../icon/Icon';
import { CaretDown, CaretSide } from '../../icons';
import { OverflowTooltip } from '../../tooltip';
import { DatasetFieldSection } from '../RiskTolerance';
import { SectionMenu } from './SectionMenu';
import { RiskField, SelectedRiskField } from '../../../admin/risk-tolerance/store';
import styles from '../RiskTolerance.module.scss';
import { DocumentSpecificHiddenFields } from '../../../admin/dataset-builder/store';
import { DocumentNameDB } from '../../../admin/documents/store';
import { OpenFieldType } from '../../../datasets/instances/store';

interface DatasetMenuProps {
    parentDatasetId: number;
    getDatasetFields: (datasetId: number, sectionId: string | null) => DatasetFieldSection[];
    getDatasetSections: (datasetId: number) => DatasetSection[];
    datasetSectionOpen: (datasetId: number) => boolean;
    getDatasetRiskFields: (datasetId: number, sectionId: string | null) => RiskField[];
    selectRiskField: (fieldId: string, datasetId: number) => void;
    selectedField: SelectedRiskField | null;
    toggleDatasetOpen: (datasetId: number) => void;
    getSectionOpen: (sectionId: string | null, datasetId: number) => boolean;
    toggleSectionOpen: (sectionId: string | null, datasetId: number) => void;
    depth: number;
    showRiskFieldToggles: boolean;
    toggleRiskField: (datasetId: number, fieldId: string) => void;
    documentHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    agreementTypeDatasetId: number | null;
}

export const DatasetMenu: React.FC<DatasetMenuProps> = ({
    parentDatasetId,
    getDatasetFields,
    getDatasetSections,
    datasetSectionOpen,
    getDatasetRiskFields,
    selectedField,
    toggleDatasetOpen,
    selectRiskField,
    getSectionOpen,
    toggleSectionOpen,
    depth,
    showRiskFieldToggles,
    toggleRiskField,
    documentHiddenFields,
    allDocumentNames,
    agreementTypeDatasetId
}) => {
    const sectionOpenIcon = useCallback((sectionId: string) => getSectionOpen(sectionId, parentDatasetId) ? CaretDown : CaretSide, [getSectionOpen, parentDatasetId]);
    const sections = useMemo(() => getDatasetSections(parentDatasetId), [getDatasetSections, parentDatasetId]);
    const sectionStyle = { width: `calc(100% - ${depth * 10}px`, paddingLeft: `${depth * 10}px` };

    if (sections.length > 1) {
        return (
            <div className={styles.sectionMenuWrapper} style={sectionStyle}>
                {sections.map(({ id, label }) => {
                    const documentSpecificHiddenFields = documentHiddenFields[parentDatasetId] || [];
                    const currentHiddenDocumentsForSection = documentSpecificHiddenFields.filter(({ type }) => type === OpenFieldType.SECTION).find(({ sectionId }) => sectionId === id)?.documentNameIds || [];
                    const documentNameIdsForDataset = allDocumentNames.filter(({ datasetId }) => datasetId === agreementTypeDatasetId).map(({ documentNameId }) => documentNameId!);
                    const sectionHiddenForAllDatasetDocuments = currentHiddenDocumentsForSection.length > 0 && documentNameIdsForDataset.every(documentNameId => currentHiddenDocumentsForSection.includes(documentNameId));
                    if (sectionHiddenForAllDatasetDocuments) {
                        return null;
                    }
                    return (
                        <div className={styles.sectionWrapper} key={id}>
                            <div className={styles.sectionHeader} onClick={() => toggleSectionOpen(id, parentDatasetId)} data-testid={`risk-tolerance-dataset-section-${id}`}>
                                <div className={styles.sectionOpenIcon}>
                                    <Icon icon={sectionOpenIcon(id)} fontSize={15} />
                                </div>
                                <OverflowTooltip overlayText={label} />
                            </div>
                            {getSectionOpen(id, parentDatasetId) &&
                                <SectionMenu
                                    sectionId={id}
                                    parentDatasetId={parentDatasetId}
                                    getDatasetFields={getDatasetFields}
                                    datasetSectionOpen={datasetSectionOpen}
                                    getDatasetRiskFields={getDatasetRiskFields}
                                    selectedField={selectedField}
                                    toggleDatasetOpen={toggleDatasetOpen}
                                    selectRiskField={selectRiskField}
                                    getDatasetSections={getDatasetSections}
                                    getSectionOpen={getSectionOpen}
                                    toggleSectionOpen={toggleSectionOpen}
                                    depth={depth}
                                    showRiskFieldToggles={showRiskFieldToggles}
                                    toggleRiskField={toggleRiskField}
                                    documentHiddenFields={documentHiddenFields}
                                    allDocumentNames={allDocumentNames}
                                    agreementTypeDatasetId={agreementTypeDatasetId}
                                />
                            }
                        </div>
                    );
                })}
            </div>
        );
    }
    return (
        <SectionMenu
            sectionId={sections[0] ? sections[0].id : null}
            parentDatasetId={parentDatasetId}
            getDatasetFields={getDatasetFields}
            datasetSectionOpen={datasetSectionOpen}
            getDatasetRiskFields={getDatasetRiskFields}
            selectedField={selectedField}
            toggleDatasetOpen={toggleDatasetOpen}
            selectRiskField={selectRiskField}
            getDatasetSections={getDatasetSections}
            getSectionOpen={getSectionOpen}
            toggleSectionOpen={toggleSectionOpen}
            depth={depth}
            showRiskFieldToggles={showRiskFieldToggles}
            toggleRiskField={toggleRiskField}
            documentHiddenFields={documentHiddenFields}
            allDocumentNames={allDocumentNames}
            agreementTypeDatasetId={agreementTypeDatasetId}
        />
    );
};
