import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo, useRef } from 'react';
import Select, { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useMenuPlacement } from '../../../hooks/useMenuPlacement';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { DeleteButton } from '../../shared/button/DeleteButton';
import { DuplicateButton } from '../../shared/button/DuplicateButton';
import { customStyles, DropdownOption, DropdownOptions } from '../../shared/dropdown/Dropdown';
import { CustomTooltip } from '../../shared/tooltip';
import { duplicateECSTable, getAgencyLinkedEntities, getSelectedLinkedEntities, removeECSTable, updateFieldLabel, updateLinkedEntities } from '../instances/store';
import styles from './Fields.module.scss';

interface AgencyECSLabelProps {
    id: string;
    index: number;
    sectionId: string;
    datasetId: number;
    parentFieldId: string;
    label: string;
    linkedEntities: string[] | null;
    ecsTableDatasetId: string;
    disabled?: boolean;
    canDelete: boolean;
}

interface AgencyPrinciplesProps {
    id: string;
    linkedEntities: string[] | null;
    disabled?: boolean;
    updateLinkedEntities: (value: string[] | null) => void;
}

const AgencyPrinciples: React.FC<AgencyPrinciplesProps> = ({
    id,
    linkedEntities,
    disabled = false,
    updateLinkedEntities
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const updateValue = (option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = (option as Options<DropdownOption>).map(({ value }) => value);
        }
        updateLinkedEntities(value);
    };

    const availableLinkedEntities = useAppSelector(getAgencyLinkedEntities);
    const selectedEntities = useAppSelector(getSelectedLinkedEntities);
    const options = useMemo(() => availableLinkedEntities.filter(({ name }) => !selectedEntities.includes(name)).map(({ name }) => name), [availableLinkedEntities, selectedEntities]);

    const mappedOptions: DropdownOptions = useMemo(() => options.map(option => ({ value: option, label: capitaliseStartLetter(option) })), [options]);
    const mappedValue: DropdownOptions | null = useMemo(() => linkedEntities?.map(value => ({ value, label: capitaliseStartLetter(value) })) || null, [linkedEntities]);

    const dropdownTooltip = useMemo(() => {
        if (!linkedEntities || !disabled) {
            return null;
        }
        return linkedEntities[0];
    }, [linkedEntities, disabled]);

    const MAX_MENU_HEIGHT = 150;
    const menuPlacement = useMenuPlacement(dropdownRef, MAX_MENU_HEIGHT);

    return (
        <CustomTooltip overlayText={dropdownTooltip} trigger='click'>
            <div
                data-testid={`agency-principles-${id}`}
                className={styles.dropdownFieldWrapper}
                ref={dropdownRef}
            >
                <Select
                    className={styles.dropdownField}
                    classNamePrefix='ark-dropdown'
                    isDisabled={disabled}
                    value={mappedValue}
                    onChange={updateValue}
                    isMulti
                    options={mappedOptions}
                    styles={customStyles}
                    maxMenuHeight={MAX_MENU_HEIGHT}
                    menuPlacement={menuPlacement}
                    menuPortalTarget={document.body}
                    fontWeight={500}
                />
            </div>
        </CustomTooltip>
    );
};

export const AgencyECSLabel: React.FC<AgencyECSLabelProps> = ({
    id,
    index,
    sectionId,
    datasetId,
    parentFieldId,
    label,
    linkedEntities,
    ecsTableDatasetId,
    canDelete,
    disabled
}) => {
    const dispatch = useAppDispatch();
    const updateLabel = useCallback((value: string) => dispatch(updateFieldLabel(datasetId, parentFieldId, value, index, sectionId)), [dispatch, datasetId, parentFieldId, index, sectionId]);
    const updateEntities = useCallback((value: string[] | null) => dispatch(updateLinkedEntities(datasetId, parentFieldId, value, index, sectionId)), [dispatch, datasetId, parentFieldId, index, sectionId]);
    const removeField = useCallback(() => dispatch(removeECSTable(datasetId, parentFieldId, sectionId, id!, ecsTableDatasetId)), [dispatch, datasetId, parentFieldId, sectionId, id, ecsTableDatasetId]);
    const duplicateField = useCallback(() => dispatch(duplicateECSTable(datasetId, parentFieldId, sectionId, id!, ecsTableDatasetId)), [dispatch, datasetId, parentFieldId, sectionId, id, ecsTableDatasetId]);

    const principlesWrapperWidth = useMemo(() => {
        let adjustment = 0;
        if (!disabled) {
            adjustment += canDelete ? 56 : 28;
        }
        return `calc(100% - ${adjustment}px)`;
    }, [canDelete, disabled]);

    return (
        <div className={styles.agencyECSWrapper}>
            <input
                className={styles.fieldLabelInput}
                placeholder='Label...'
                value={label}
                onChange={e => updateLabel(e.currentTarget.value)}
                disabled={disabled}
            />
            <div className={styles.rightECSWrapper}>
                <div className={styles.principlesWrapper} style={{ width: principlesWrapperWidth }}>
                    <AgencyPrinciples id={id} updateLinkedEntities={updateEntities} linkedEntities={linkedEntities} disabled={disabled} />
                </div>
                {!disabled && <DuplicateButton onClick={duplicateField} />}
                {!disabled && canDelete && <DeleteButton onClick={removeField} />}
            </div>
        </div>
    );
};
