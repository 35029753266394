import React from 'react';
import classnames from 'classnames';

import styles from './Table.module.scss';

export interface Tabs {
    tabTitle: string;
    tabToggleAction: () => void;
    isSelected: boolean;
}

interface TableTabsProps {
    tabs: Tabs[];
    testId: string;
    margin?: string;
}

export const TableTabs: React.FC<TableTabsProps> = ({
    tabs,
    testId,
    margin = '0 8vw'
}) => (
    <div className={styles.tableTabsWrapper} data-testid={`${testId}-tabs-wrapper`} style={{ margin: margin }}>
        {tabs.map(({ tabTitle, tabToggleAction, isSelected }, index) => (
            <button
                className={classnames(styles.tab, {
                    [styles.isSelected]: isSelected
                })}
                onClick={() => tabToggleAction()}
                key={index}
            >
                <div className={styles.tabTitle} data-testid={`${testId}-tab-${index}`}>
                    {tabTitle}
                </div>
            </button>
        ))}
    </div>
);
