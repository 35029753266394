import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import styles from './Spinner.module.scss';

const { primary } = styles;

interface SpinnerProps {
    size?: number;
    message?: string;
    color?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ size = 50, message, color = primary }) => (
    <div className={styles.spinnerWrapper}>
        <ClipLoader color={color} loading={true} size={size} />
        {message && <div>{message}</div>}
    </div>
);
