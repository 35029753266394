import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { DatasetDefinition } from '../../../datasets/store';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';
import { MyRiskTolerance, RiskField, RiskTolerance, SelectedRiskField } from '../../risk-tolerance/store';
import { HiddenFields, MyDatasetView, MyDatasets, MyDatasetsOpenFieldSection, MyDatasetsState } from './types';

const getRoot = (state: ApplicationState): MyDatasetsState => state.admin.myDatasets;

export const getAllMyDatasets = (state: ApplicationState): MyDatasets[] => getRoot(state).allDatasets;

export const getIsFetchingMyDatasets = (state: ApplicationState): boolean => getRoot(state).fetchingAllDatasets;

export const getMyDatasetsView = (state: ApplicationState): MyDatasetView => getRoot(state).datasetView;

export const getSelectedDatasetId = (state: ApplicationState): number | null => getRoot(state).selectedDataset;

export const getMyDatasetDefinitions = (state: ApplicationState): DatasetDefinition[] => getRoot(state).myDatasetDefinitions;

export const getOpenFieldsAndSections = (state: ApplicationState): MyDatasetsOpenFieldSection[] => getRoot(state).openFieldsAndSections;

export const getModalDatasetId = (state: ApplicationState): number | null => getRoot(state).modalDatasetId;

export const getCurrentHiddenFields = (state: ApplicationState): HiddenFields => getRoot(state).currentHiddenFields;

export const getDocumentSpecificHiddenFields = (state: ApplicationState): DocumentSpecificHiddenFields => getRoot(state).documentHiddenFields;

export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;

export const getSavedHiddenFields = (state: ApplicationState): HiddenFields => getRoot(state).savedHiddenFields;

export const getHiddenFieldsUpdated = (state: ApplicationState): boolean => getRoot(state).hiddenFieldsUpdated;

export const getMyRiskToleranceConfig = (state: ApplicationState): (RiskTolerance | MyRiskTolerance)[] | null => getRoot(state).myRiskToleranceConfig;

export const getCurrentRiskToleranceConfig = (state: ApplicationState): (RiskTolerance | MyRiskTolerance)[] | null => getRoot(state).currentRiskToleranceConfig;

export const getSelectedRiskField = (state: ApplicationState): SelectedRiskField | null => getRoot(state).currentRiskField;

export const getCurrentRiskField = (state: ApplicationState): RiskField | null => {
    const selectedRiskField = getSelectedRiskField(state);
    const currentRiskToleranceConfig = getCurrentRiskToleranceConfig(state);
    if (isNull(selectedRiskField) || isNull(currentRiskToleranceConfig)) {
        return null;
    }
    const { datasetId, fieldId } = selectedRiskField;
    return currentRiskToleranceConfig.find(riskDataset => riskDataset.datasetId === datasetId)?.config.find(riskField => riskField.fieldId === fieldId) || null;
};

export const getRiskToleranceHasUpdated = (state: ApplicationState): boolean => getRoot(state).riskToleranceUpdated;

export const getUnsavedChangesModalOpen = (state: ApplicationState): boolean => getRoot(state).unsavedChangesModalOpen;

export const getIsFetchingDatasetDefinitions = (state: ApplicationState): boolean => getRoot(state).fetchingDatasetDefinitions;
