import React, { useState } from 'react';

import { useWindowResize } from '../../../../hooks/useWindowResize';
import { LinkedPlaybookProvision } from '../../../admin/playbook/store';
import { IconButton } from '../../button/IconButton';
import { LinkChain } from '../../icons';
import { Position } from '../../modal/PositionModal';
import { LinkedProvisionModal } from './LinkedProvisionModal';
import styles from './ProvisionLink.module.scss';

interface LinkedProvisionButtonProps {
    isOpen: boolean
    linkedProvisions: LinkedPlaybookProvision[];
    toggleLinkedProvisionModal: () => void;
    openLinkToProvision: (link: LinkedPlaybookProvision) => void;
}

export const LinkedProvisionButton: React.FC<LinkedProvisionButtonProps> = ({ isOpen, linkedProvisions, toggleLinkedProvisionModal, openLinkToProvision }) => {
    const [position, setPosition] = useState<Position | null>(null);
    const [screenWidth] = useWindowResize();
    const openModal = (x: number, y: number) => {
        const xPosition = screenWidth - 350;
        setPosition({ x: xPosition, y });
        toggleLinkedProvisionModal();
    };

    const closeModal = () => toggleLinkedProvisionModal();

    const linkToProvision = (link: LinkedPlaybookProvision) => {
        closeModal();
        openLinkToProvision(link);
    };

    return (
        <>
            <div className={styles.linkIconWrapper}>
                <IconButton onClick={e => openModal(e.clientX, e.clientY)} disabled={false} testId='admin-read-only-playbook-provision-link' icon={LinkChain} />
            </div>
            <LinkedProvisionModal
                isOpen={isOpen}
                linkedProvisions={linkedProvisions}
                position={position}
                closeModal={closeModal}
                linkToProvision={linkToProvision}
            />
        </>
    );
};
