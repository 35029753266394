import { RawDraftContentState } from 'draft-js';
import { flatten, flattenDeep, isNull, set } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { sortPlaybookShelves, ABSTRACT_ID } from '../../../constants/playbooks';
import { getAvailableDocumentNames } from '../../documents/store';
import { AdminPlaybookState, PlaybookPage, PlaybookBuilderTab, Playbook, PlaybookText, PlaybookView, TagContent, PlaybookDB, BasicPlaybook, PlaybookHistory, BuildPage, PlaybookQueryDB, PlaybookMessage, SuggestedChangesDB, DraggableTile, OpenSuggestedChange, PlaybookPageView, PlaybookProvision, ReadOnlyContentType, PlaybookSmartSearch, AvailablePlaybookProvisionLinks, LinkedPlaybookProvision, UpdatedProvisionLinks, SystemAdminBookshelfView } from './types';
import { isSubheaderSection } from './typeAssertions';

const getTextFromEditor = (content: RawDraftContentState | null) => isNull(content) ? [''] : content.blocks.map(({ text }) => text);

const getRoot = (state: ApplicationState): AdminPlaybookState => state.admin.playbook;

export const getPlaybookPage = (state: ApplicationState): PlaybookPage => getRoot(state).playbookPage;

export const getBuildPage = (state: ApplicationState): BuildPage => getRoot(state).buildPage;

export const getSelectedTab = (state: ApplicationState): PlaybookBuilderTab => getRoot(state).selectedTab;

export const getPlaybook = (state: ApplicationState): Playbook => getRoot(state).playbook;

export const getPlaybookHistory = (state: ApplicationState): PlaybookHistory[] => getRoot(state).playbookHistory;

export const getOpenSections = (state: ApplicationState): string[] => getRoot(state).openSections;

export const getOpenDeviations = (state: ApplicationState): string[] => getRoot(state).openDeviations;

export const getCurrentView = (state: ApplicationState): PlaybookView => getRoot(state).currentView;

export const getTagToCreate = (state: ApplicationState): TagContent | null => getRoot(state).tagToCreate;

export const getCreateTagModalOpen = (state: ApplicationState): boolean => !isNull(getTagToCreate(state));

export const getPlaybookText = (state: ApplicationState): PlaybookText => {
    const abstract = getPlaybook(state).content.abstract;
    const abstractText = getTextFromEditor(abstract);
    const provisions = getPlaybook(state).content.sections.filter(section => !isSubheaderSection(section)) as PlaybookProvision[];
    const playbookText = provisions.reduce((acc: PlaybookText, { sectionId, provisionContent, backgroundContent, preferredTerms, deviations }: PlaybookProvision) =>
        set(sectionId, [...getTextFromEditor(provisionContent), ...getTextFromEditor(backgroundContent), ...getTextFromEditor(preferredTerms), ...deviations.map(({ topic }) => topic), ...flatten(deviations.map(({ variations }) => ([...getTextFromEditor(variations)]))), ...flatten(deviations.map(({ background }) => ([...getTextFromEditor(background)]))), ...deviations.map(({ nid }) => nid)], acc)
    , { [ABSTRACT_ID]: abstractText }
    );
    return playbookText;
};

export const getPlaybookPublishReason = (state: ApplicationState): string => getRoot(state).publishReason;

export const getPlaybookPublishError = (state: ApplicationState): string | null => getRoot(state).publishError;

export const getAllPlaybooks = (state: ApplicationState): BasicPlaybook[] => getRoot(state).allPlaybooks;

export const getAllTemplates = (state: ApplicationState): BasicPlaybook[] => getRoot(state).allTemplates;

export const getCurrentPlaybook = (state: ApplicationState): PlaybookDB | null => getRoot(state).currentPlaybook;

export const getPlaybookIsDraft = (state: ApplicationState): boolean => !!getRoot(state).playbook.isDraft;

export const getPlaybookCanSave = (state: ApplicationState): boolean => getRoot(state).canSave;

export const getPlaybookPublishModalOpen = (state: ApplicationState): boolean => getRoot(state).publishModalOpen;

export const getPlaybookSaveModalOpen = (state: ApplicationState): boolean => getRoot(state).saveModalOpen;

export const getPlaybookCanNext = (state: ApplicationState): boolean => getRoot(state).canNext;

export const getPlaybookCanPublish = (state: ApplicationState): boolean => getRoot(state).canPublish;

export const getPlaybookVersionUpdated = (state: ApplicationState): boolean => getRoot(state).versionUpdated;

export const getPlaybookVersionMajorIncreased = (state: ApplicationState): boolean => getRoot(state).versionMajorIncreased;

export const getPlaybookShelves = (state: ApplicationState) => {
    const searchTerm = getPlaybookSimpleSearchTerm(state);
    const systemAdminBookshelfView = getSystemAdminBookshelfView(state);
    let allPlaybooks: BasicPlaybook[] = getAllPlaybooks(state);
    if (searchTerm !== '') {
        const allDocumentNames = getAvailableDocumentNames(state);
        const getDocumentNames = (ids: string[] | null) => ids ? allDocumentNames.reduce((acc: string[], { documentNameId, documentName }) => documentNameId && ids.includes(documentNameId.toString()) ? [...acc, documentName.toLowerCase()] : acc, []) : [];
        const playbooksToSearch = allPlaybooks.map(({ playbookId, agreementType, createdBy, name, documentNameIds, versionMajor, versionMinor, isDraft }) => ({ playbookId: playbookId, searchableValues: flattenDeep([!isNull(agreementType) ? agreementType.toString().toLowerCase() : '', createdBy.toLowerCase(), name.toLowerCase(), getDocumentNames(documentNameIds), versionMajor.toString(), `${versionMajor.toString()}.${versionMinor.toString()}`, isDraft ? 'draft' : '']) }));
        const matchedPlaybookIds = playbooksToSearch.map(({ playbookId, searchableValues }) => searchableValues.some(text => text.includes(searchTerm)) ? playbookId : null).filter(value => !isNull(value));
        allPlaybooks = allPlaybooks.filter(playbook => matchedPlaybookIds.includes(playbook.playbookId));
    }
    if (systemAdminBookshelfView !== SystemAdminBookshelfView.ALL) {
        const templatesOnly = systemAdminBookshelfView === SystemAdminBookshelfView.TEMPLATES;
        allPlaybooks = templatesOnly ? allPlaybooks.filter(({ isSystemTemplate }) => !!isSystemTemplate) : allPlaybooks.filter(({ isSystemTemplate }) => !isSystemTemplate);
    }
    const playbookShelves = sortPlaybookShelves(allPlaybooks);
    return playbookShelves;
};

export const getPlaybookTemplateShelves = (state: ApplicationState) => {
    const searchTerm = getPlaybookTemplateSimpleSearchTerm(state);
    let allTemplates: BasicPlaybook[] = getAllTemplates(state);
    if (searchTerm !== '') {
        const allDocumentNames = getAvailableDocumentNames(state);
        const getDocumentNames = (ids: string[] | null) => ids ? allDocumentNames.reduce((acc: string[], { documentNameId, documentName }) => documentNameId && ids.includes(documentNameId.toString()) ? [...acc, documentName.toLowerCase()] : acc, []) : [];
        const playbooksToSearch = allTemplates.map(({ playbookId, agreementType, createdBy, name, documentNameIds, versionMajor, versionMinor, isDraft }) => ({ playbookId: playbookId, searchableValues: flattenDeep([!isNull(agreementType) ? agreementType.toString().toLowerCase() : '', createdBy.toLowerCase(), name.toLowerCase(), getDocumentNames(documentNameIds), versionMajor.toString(), `${versionMajor.toString()}.${versionMinor.toString()}`, isDraft ? 'draft' : '']) }));
        const matchedPlaybookIds = playbooksToSearch.map(({ playbookId, searchableValues }) => searchableValues.some(text => text.includes(searchTerm)) ? playbookId : null).filter(value => !isNull(value));
        allTemplates = allTemplates.filter(playbook => matchedPlaybookIds.includes(playbook.playbookId));
    }
    const playbookShelves = sortPlaybookShelves(allTemplates);
    return playbookShelves;
};

export const getPlaybookCoverSelected = (state: ApplicationState): BasicPlaybook | null => getRoot(state).playbookCoverSelected;

export const getPlaybookCoverModalOpen = (state: ApplicationState): boolean => !isNull(getPlaybookCoverSelected(state));

export const getTemplateCoverSelected = (state: ApplicationState): BasicPlaybook | null => getRoot(state).templateCoverSelected;

export const getTemplateCoverModalOpen = (state: ApplicationState): boolean => !isNull(getTemplateCoverSelected(state));

export const getPlaybookQueries = (state: ApplicationState): PlaybookQueryDB[] => getRoot(state).queries;

export const getSelectedPlaybookQuery = (state: ApplicationState): PlaybookQueryDB | null => getRoot(state).selectedQuery;

export const getQueryModalOpen = (state: ApplicationState): boolean => !isNull(getSelectedPlaybookQuery(state));

export const getQueryResponse = (state: ApplicationState): PlaybookMessage | null => getRoot(state).queryResponse;

export const getSendingQueryResponse = (state: ApplicationState): boolean => getRoot(state).sendingResponse;

export const getSendEmailReply = (state: ApplicationState): boolean => getRoot(state).sendEmailReply;

export const getQueryMarkedAsResolved = (state: ApplicationState): boolean => getRoot(state).queryMarkedAsResolved;

export const getPlaybookSuggestedChanges = (state: ApplicationState): SuggestedChangesDB[] => getRoot(state).playbookSuggestedChanges;

export const getProvisionOrder = (state: ApplicationState): DraggableTile[] => getRoot(state).provisionsOrder;

export const getReorderProvisionModalOpen = (state: ApplicationState): boolean => getRoot(state).provisionsModalOpen;

export const getDeviationOrder = (state: ApplicationState): DraggableTile[] => getRoot(state).deviationsOrder;

export const getDeviationModalSectionId = (state: ApplicationState): string | null => getRoot(state).deviationsModalSectionId;

export const getReorderDeviationModalOpen = (state: ApplicationState): boolean => !isNull(getDeviationModalSectionId(state));

export const getOpenSuggestedChange = (state: ApplicationState): OpenSuggestedChange | null => getRoot(state).openSuggestedChange;

export const getCurrentPlaybookPageView = (state: ApplicationState): PlaybookPageView => getRoot(state).playbookPageView;

export const getOutstandingSuggestedChanges = (state: ApplicationState): boolean => {
    const suggestedChanges = getRoot(state).playbookSuggestedChanges;
    return suggestedChanges.length > 0 && !suggestedChanges.filter(({ outstanding }) => !!outstanding).length;
};

export const getResolvedSuggestedChanges = (state: ApplicationState): number[] => {
    const suggestedChanges = getRoot(state).playbookSuggestedChanges;
    return suggestedChanges.filter(({ outstanding }) => !outstanding).map(({ playbookSuggestionId }) => playbookSuggestionId);
};

export const getResolvedChangesExist = (state: ApplicationState): boolean => getResolvedSuggestedChanges(state).length > 0;

export const getSuggestionReviewModalOpen = (state: ApplicationState): boolean => getRoot(state).suggestionReviewModalOpen;

export const getSuggestedChangesMessage = (state: ApplicationState): PlaybookMessage | null => getRoot(state).suggestedChangesMessage;

export const getSuggestedChangesMessageUpdated = (state: ApplicationState): boolean => getRoot(state).suggestedChangesMessageUpdated;

export const getSendingSuggestedChangesMessage = (state: ApplicationState): boolean => getRoot(state).sendingMessage;

export const getShowPlaybookViewLoadingSpinner = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getDisablePublishAndVersionControl = (state: ApplicationState): boolean => {
    const currentPlaybook: PlaybookDB | null = getCurrentPlaybook(state);
    const playbookHistory: PlaybookHistory[] = getPlaybookHistory(state);
    const playbookHasDraftVersion = playbookHistory.some(({ isDraft }) => !!isDraft);
    const disablePublishAndVersionControl = playbookHasDraftVersion && !isNull(currentPlaybook) && currentPlaybook.playbookDefinitionId !== playbookHistory.find(({ isDraft }) => !!isDraft)?.playbookDefinitionId;
    return disablePublishAndVersionControl;
};

export const getResolvedPlaybookSuggestedChanges = (state: ApplicationState): SuggestedChangesDB[] => getRoot(state).resolvedPlaybookSuggestedChanges;

export const getResolvedPlaybookSuggestedChange = (state: ApplicationState): SuggestedChangesDB | null => getRoot(state).resolvedSuggestedChange;

export const getResolvedSuggestedChangesOpen = (state: ApplicationState): boolean => !isNull(getResolvedPlaybookSuggestedChange(state));

export const getResolvedChangesContent = (state: ApplicationState): ReadOnlyContentType => getRoot(state).resolvedSuggestedChangesContent;

export const getPlaybookSimpleSearchTerm = (state: ApplicationState): string => getRoot(state).simpleSearchTerm;

export const getPlaybookTemplateSimpleSearchTerm = (state: ApplicationState): string => getRoot(state).templateSimpleSearchTerm;

export const getPlaybookSmartSearchTerm = (state: ApplicationState): PlaybookSmartSearch[] => getRoot(state).smartSearchTerm;

export const getAvailablePlaybookProvisionLinks = (state: ApplicationState): AvailablePlaybookProvisionLinks[] => getRoot(state).availableProvisionLinks;

export const getAvailableProvisionLinkModalOpen = (state: ApplicationState): boolean => !isNull(getRoot(state).provisionToLink);

export const getProvisionToLink = (state: ApplicationState): string | null => getRoot(state).provisionToLink;

export const getCurrentProvisionLinks = (state: ApplicationState): LinkedPlaybookProvision[] => getRoot(state).currentProvisionLinks;

export const getLinkedProvisionModalOpen = (state: ApplicationState): boolean => getRoot(state).linkedProvisionModalOpen;

export const getProvisionLinksUpdated = (state: ApplicationState): UpdatedProvisionLinks | null => getRoot(state).updatedProvisionLinks;

export const getDeleteSectionConfirmationModalOpen = (state: ApplicationState): boolean => !isNull(getRoot(state).sectionWithLinksToDelete);

export const getSectionWithLinksToDelete = (state: ApplicationState): string | null => getRoot(state).sectionWithLinksToDelete;

export const getReadOnlyProvisionLinksModalOpen = (state: ApplicationState): string | null => getRoot(state).readOnlyLinkedProvisionModalOpen;

export const getLinkedProvisionHistory = (state: ApplicationState): LinkedPlaybookProvision[] => getRoot(state).provisionLinkHistory;

export const getLinkedProvisionHistoryModalOpen = (state: ApplicationState): boolean => getRoot(state).provisionLinkHistoryModalOpen;

export const getSystemAdminBookshelfView = (state: ApplicationState): SystemAdminBookshelfView => getRoot(state).systemAdminBookshelfView;
