import React, { useMemo, useState, MouseEvent, useCallback } from 'react';
import { isEqual, isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllDocumentGroupsStarted, fetchAvailableDocumentNamesStarted } from '../../admin/documents/store';
import { fetchAllEntitiesStarted } from '../../admin/entity/store';
import { IconButton } from '../../shared/button/IconButton';
import { Document, Filter } from '../../shared/icons';
import { Pagination } from '../../shared/pagination/Pagination';
import { getResultsType, getSearchFilterModalOpen, getSearchFilters, getSearchResultPage, getSimpleSearchTerm, getTotalPages, getTotalSearchResults, initialSearchFilter, searchDocumentsStarted, SearchDocumentsView, toggleSearchDocumentsView, toggleSearchFilterModal, updateSimpleSearch, resetSearch } from './store';
import { FilterModal } from './FilterModal';
import styles from './SearchDocuments.module.scss';
import { SearchResults } from './SearchResults';
import { SimpleSearch } from '../../shared/search/SimpleSearch';
import { SmartSearch } from './SmartSearch';
import { Position } from '../../shared/modal/PositionModal';
import { RadioListOption } from '../../shared/radio/RadioList';
import { RadioRow } from '../../shared/radio/RadioRow';

interface SearchDocumentsProps {
    searchType: SearchDocumentsView;
}

export const SearchDocuments: React.FC<SearchDocumentsProps> = ({ searchType }) => {
    const [filterModalPosition, setFilterModalPosition] = useState<Position | null>(null);
    const dispatch = useAppDispatch();
    const page = useAppSelector(getSearchResultPage);
    const totalPages = useAppSelector(getTotalPages);
    const filterModalOpen = useAppSelector(getSearchFilterModalOpen);
    const filters = useAppSelector(getSearchFilters);
    const resultsType = useAppSelector(getResultsType);
    const resultsTotal = useAppSelector(getTotalSearchResults);
    const simpleSearchTerm = useAppSelector(getSimpleSearchTerm);
    const filtersActive = !isEqual(filters, initialSearchFilter);

    const openFilter = (x: number, y: number) => setFilterModalPosition({ x, y });
    const closeFilter = () => setFilterModalPosition(null);
    const setSearchDocumentsView = useCallback((view: SearchDocumentsView | null) => dispatch(toggleSearchDocumentsView(view)), [dispatch]);
    const toggleOriginalDocuments = () => setSearchDocumentsView(null);
    const closeFilterModal = () => {
        closeFilter();
        dispatch(toggleSearchFilterModal(false));
    };
    const openFilterModal = (event: MouseEvent<HTMLButtonElement>) => {
        openFilter(event.clientX, event.clientY);
        dispatch(toggleSearchFilterModal(true));
    };

    const openPage = (page: number) => dispatch(searchDocumentsStarted(page));

    const applyFilters = () => dispatch(searchDocumentsStarted(1));

    useFetchStarted([fetchAvailableDocumentNamesStarted(), fetchAllEntitiesStarted(true), fetchAllDocumentGroupsStarted()]);

    const isSimpleSearch = searchType === SearchDocumentsView.SIMPLE_SEARCH;
    const isSmartSearch = searchType === SearchDocumentsView.SMART_SEARCH;
    const flexDirection = useMemo(() => isSmartSearch ? 'row' : 'column', [isSmartSearch]);
    const width = useMemo(() => isSmartSearch ? '50%' : '100%', [isSmartSearch]);
    const showTotal = useMemo(() => totalPages !== 0, [totalPages]);

    const updateSearchTerm = (simpleSearchTerm: string) => {
        dispatch(updateSimpleSearch(simpleSearchTerm));
        dispatch(searchDocumentsStarted(page));
    };

    const resetSimpleSearch = () => {
        dispatch(resetSearch());
    };

    const searchOptions: RadioListOption[] = useMemo(() => [
        {
            id: SearchDocumentsView.SIMPLE_SEARCH,
            isSelected: isSimpleSearch,
            label: 'Simple'
        },
        {
            id: SearchDocumentsView.SMART_SEARCH,
            isSelected: isSmartSearch,
            label: 'Smart'
        }
    ], [isSimpleSearch, isSmartSearch]);

    const toggleSearchView = useCallback((id: string) => setSearchDocumentsView(id as SearchDocumentsView), [setSearchDocumentsView]);

    return (
        <div className={styles.searchDocumentsWrapper} data-testid='search-documents-wrapper'>
            <div className={styles.searchDocumentsHeader}>
                <div className={styles.searchDocumentsTitle} data-testid='search-documents-title'>Search All Documents</div>
                <IconButton icon={Document} onClick={toggleOriginalDocuments} fontSize={35} testId='original-documents-return' />
            </div>
            <div className={styles.searchTypeAndInputWrapper}>
                <div className={styles.searchTypeWrapper} data-testid='document-search-type-wrapper'>
                    <div className={styles.radioWrapper}>
                        <RadioRow options={searchOptions} onChange={toggleSearchView} testId='search-documents' fontSize={16} />
                    </div>
                    <IconButton
                        icon={Filter}
                        onClick={openFilterModal}
                        fontSize={24}
                        testId='filter-search'
                        showIndicator={filtersActive}
                    />
                </div>
                {isSimpleSearch && <SimpleSearch
                    searchTerm={simpleSearchTerm}
                    updateSearchTerm={updateSearchTerm}
                    resetSimpleSearch={resetSimpleSearch}
                    testId='documents'
                />}
            </div>
            <div className={styles.smartSearchAndResultsWrapper} style={{ flexDirection: flexDirection }}>
                {isSmartSearch && <SmartSearch page={page} />}
                <div className={styles.resultsAndPaginationWrapper} style={{ width: width }}>
                    <SearchResults resultsType={resultsType} />
                    <div className={styles.paginationAndTotalWrapper}>
                        <Pagination totalPages={totalPages} selectedPage={page} openPage={openPage} />
                        {showTotal && <div className={styles.resultsTotal}>Total: {resultsTotal}</div>}
                    </div>
                </div>
            </div>
            {!isNull(filterModalPosition) &&
                <FilterModal
                    isOpen={filterModalOpen}
                    closeModal={closeFilterModal}
                    applyFilters={applyFilters}
                    closeOnOverlayClick={true}
                    position={filterModalPosition}
                />
            }
        </div>
    );
};
