import React, { useCallback, useMemo, useState } from 'react';
import { isNull, noop } from 'lodash/fp';
import classnames from 'classnames';

import styles from './NettingEngine.module.scss';
import { JurisdictionalNettingInformation, NettingEngineSummary, ProductTypeCovered, ProductTypeCoveredEnum, JurisdictionalAnswers } from './store';
import { NettingEngineMapOutline } from './NettingEngineMapOutline';
import { CaretDown, CaretSide, Delete, SmileyNegative, SmileyNeutral, SmileyPositive, Tick, WarningSign } from '../../shared/icons';
import { Icon } from '../../shared/icon/Icon';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { OverflowTooltip } from '../../shared/tooltip';
import { NettingEngineEnquireOverlay } from './NettingEngineEnquireOverlay';
import { Spinner } from '../../shared/spinner/Spinner';
import { countryGeometry } from './store/utils';

const { green, red, grey, amber, primary } = styles;

interface NettingEngineJurisdictionResultsProps {
    jurisdictionalResults: JurisdictionalNettingInformation;
    viewCustomConclusions: boolean;
    height: number;
    width: number;
    isLoading: boolean;
    index: number;
}

export const NettingEngineJurisdictionResults: React.FC<NettingEngineJurisdictionResultsProps> = ({ jurisdictionalResults, viewCustomConclusions, height, width, isLoading, index }) => {

    const {
        details,
        permittedToView,
        jurisdiction,
        counterpartyOffices,
        summary,
        clientSignOffDetails,
        clientSignOffSummary
    } = jurisdictionalResults;

    const country = countryGeometry(jurisdiction);

    const customConclusionsExist = useMemo(() => !isNull(clientSignOffDetails) && viewCustomConclusions, [clientSignOffDetails, viewCustomConclusions]);

    const summaryValue = useMemo(() => customConclusionsExist ? clientSignOffSummary : summary, [customConclusionsExist, clientSignOffSummary, summary]);

    const fillColour = useMemo(() => {
        if (!permittedToView) return grey;
        if (summaryValue === NettingEngineSummary.YES) return green;
        if (summaryValue === NettingEngineSummary.NO) return red;
        if (summaryValue === NettingEngineSummary.MAYBE || summaryValue === NettingEngineSummary.PRODUCTS_COVERED) return amber;
        return grey;
    }, [permittedToView, summaryValue]);

    const svgHeight = useMemo(() => height - 30, [height]);
    const svgWidth = useMemo(() => width * 0.45, [width]);

    const leftHandComponent = useMemo(() => !country ? <div className={styles.jurisdictionWrapper}>{jurisdiction}</div> : <NettingEngineMapOutline country={country} fillColour={fillColour} svgHeight={svgHeight} svgWidth={svgWidth} />, [jurisdiction, country, fillColour, svgHeight, svgWidth]);

    const getIcon = useCallback((answerString: string, noPositive = false, isLocalLaw = false) => {
        if (
            (!permittedToView && !isLocalLaw) ||
            (isLocalLaw && answerString === 'Not permitted to view')
        ) return <Icon icon={SmileyNeutral} fill={grey} color={primary} />;
        if (!noPositive) {
            if (answerString === 'Yes' || answerString === 'Not relevant to head office') return <Icon icon={SmileyPositive} fill={green} color={primary} />;
            if (answerString === 'No') return <Icon icon={SmileyNegative} fill={red} color={primary} />;
            return <Icon icon={SmileyNeutral} fill={amber} color={primary} />;
        } else {
            if (answerString === 'No' || answerString === 'Not relevant to head office') return <Icon icon={SmileyPositive} fill={green} color={primary} />;
            if (answerString === 'Yes') return <Icon icon={SmileyNegative} fill={red} color={primary} />;
            return <Icon icon={SmileyNeutral} fill={amber} color={primary} />;
        }
    }, [permittedToView]);

    const summaryIconToShow = useMemo(() => {
        switch (summaryValue) {
            case NettingEngineSummary.YES:
                return { icon: Tick, color: green };
            case NettingEngineSummary.NO:
                return { icon: Delete, color: red };
            default:
                return { icon: WarningSign, color: amber };
        }
    }, [summaryValue]);

    const summaryOfJurisdiction = useMemo(() => <><span className={styles.answerText}>{summaryValue}</span><Icon icon={summaryIconToShow.icon} color={summaryIconToShow.color} /></>, [summaryIconToShow, summaryValue]);

    const getAnswerJSX = useCallback((answer: string, noPositive = false, isLocalLaw = false): JSX.Element => (
        <>
            <span className={styles.answerText}>{answer}</span>{getIcon(answer, noPositive, isLocalLaw)}
        </>
    ), [getIcon]);

    const [productsSectionOpen, setProductsSectionOpen] = useState<boolean>(true);
    const sectionOpenIcon = productsSectionOpen ? CaretDown : CaretSide;

    const getProductsCoveredColumn = useCallback((column: ProductTypeCovered[]) => (
        <div className={styles.productColumn}>
            {column.map(({ product, covered }, index) => <div className={styles.productsCovered} key={index}>
                <OverflowTooltip className={styles.product} overlayText={product} />
                <div className={styles.covered}><Icon icon={covered === ProductTypeCoveredEnum.YES ? Tick : Delete} color={covered === ProductTypeCoveredEnum.YES ? green : red} /></div>
            </div>)}
        </div>
    ), []);

    const getProductsCovered = useCallback((products: ProductTypeCovered[]) => {
        if (!productsSectionOpen) {
            return null;
        }
        const isOdd = (num: number) => num % 2;
        const columnOne = products.filter((product, index) => !isOdd(index) ? product : null);
        const columnTwo = products.filter((product, index) => isOdd(index) ? product : null);

        return <div className={styles.productsCoveredWrapper}>
            <Scrollable>
                <div className={styles.productColumns}>
                    {getProductsCoveredColumn(columnOne)}
                    {getProductsCoveredColumn(columnTwo)}
                </div>
            </Scrollable>
        </div>;
    }, [productsSectionOpen, getProductsCoveredColumn]);

    const { agreementCovered, counterpartyCovered, governingLawRecognised, closeOutNettingEnforceable, automaticEarlyTerminationRequired, onShoreInsolvency, offShoreInsolvency, productsCovered, localLawIsValid } = customConclusionsExist ? clientSignOffDetails! : details;

    const eachAnswer: JurisdictionalAnswers[] = useMemo(() => {
        const answers: JurisdictionalAnswers[] = [
            { question: 'Is the selected Agreement covered by the opinion?', answer: getAnswerJSX(agreementCovered) },
            { question: 'Is the selected counterparty type covered by the opinion?', answer: getAnswerJSX(counterpartyCovered) },
            { question: 'Will the governing law of the contract be recognised?', answer: getAnswerJSX(governingLawRecognised) },
            { question: 'Is close out netting enforceable?', answer: getAnswerJSX(closeOutNettingEnforceable) },
            { question: 'Is automatic early termination required?', answer: getAnswerJSX(automaticEarlyTerminationRequired, true) },
            { question: 'Could separate insolvency proceedings be instituted against a local branch?', answer: getAnswerJSX(onShoreInsolvency, true) },
            { question: 'Would multi-branch close-out netting be respected in a separate local branch insolvency?', answer: getAnswerJSX(offShoreInsolvency) }
        ];
        if (!isNull(localLawIsValid)) {
            answers.push({ question: 'Is the ISDA Master Agreement (when governed by the laws of your jurisdiction) enforceable?', answer: getAnswerJSX(localLawIsValid, false, true) });
        }
        return answers;
    }, [agreementCovered, counterpartyCovered, governingLawRecognised, localLawIsValid, getAnswerJSX, automaticEarlyTerminationRequired, closeOutNettingEnforceable, offShoreInsolvency, onShoreInsolvency]);

    const summaryAndProductsCoveredAnswers: JurisdictionalAnswers[] = useMemo(() => {
        const answers: JurisdictionalAnswers[] = [
            { question: 'Summary of the Jurisdiction:', answer: summaryOfJurisdiction }
        ];
        if (productsCovered.length > 0) {
            const products = { question: 'Products Covered', answer: getProductsCovered(productsCovered), showIcon: true };
            answers.push(products);
        }
        return answers;
    }, [summaryOfJurisdiction, getProductsCovered, productsCovered]);

    const left = (width + 20) * index + 'px';

    const noClientSignOffDetailsMessage = useMemo(() => isNull(clientSignOffDetails) && viewCustomConclusions ? 'Because there are no sign off details around this jurisdiction, you are still viewing system data' : null, [clientSignOffDetails, viewCustomConclusions]);

    if (isLoading) {
        return (
            <div className={styles.nettingJurisdictionResults} style={{ height, width }}>
                <Spinner />
            </div>
        );
    }

    const offices = counterpartyOffices.map(office => office).join(', ');
    // This keeps the jurisdiction label centred above the country
    const marginLeft = `calc((45% / 2) - ${(jurisdiction.length * 10 / 2)}px)`;
    const counterpartyOfficesWidth = `calc(100% - calc((45% / 2) + ${(jurisdiction.length * 10 / 2)}px))`;

    return (
        <div className={styles.nettingJurisdictionResults} style={{ height: height, width: width }}>
            <div className={styles.jurisdictionWrapper}>
                <div style={{ marginLeft: marginLeft, minWidth: 'fit-content' }}>{jurisdiction}</div>
                {counterpartyOffices.length > 0 && <div className={styles.counterpartyOfficesWrapper} style={{ maxWidth: counterpartyOfficesWidth }}>
                    <div className={styles.counterpartyOfficesLabel}> - Counterparty Offices:</div>
                    <OverflowTooltip overlayText={offices} className={styles.counterpartyOffices} />
                </div>}
            </div>
            <div className={styles.resultsWrapper}>
                <div className={styles.leftHandSummary}>
                    {leftHandComponent}
                </div>
                <div className={styles.noClientSignOffDetailsMessage}>
                    <div className={styles.message}>{noClientSignOffDetailsMessage}</div>
                    <div className={styles.rightHandSummary}>
                        <Scrollable>
                            <div className={styles.questionAndAnswerWrapper}>
                                {eachAnswer.map(({ question, answer }, index) => {
                                    return (
                                        <div className={styles.questionAndAnswer} key={index}>
                                            <div className={styles.question}>{question}</div>
                                            <div className={styles.answer}>{answer}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={styles.summaryAndProductsCoveredAnswers}>
                                {summaryAndProductsCoveredAnswers.map(({ question, answer, showIcon }, index) => {
                                    const onClick = (productsSectionOpen: boolean) => showIcon ? setProductsSectionOpen(!productsSectionOpen) : noop;
                                    const questionIcon = showIcon && <div className={styles.questionIcon}><Icon icon={sectionOpenIcon} fontSize={14} /></div>;
                                    return (
                                        <div className={classnames(styles.questionAndAnswer, [styles.jurisdictionAndProductsCoveredWidth])} key={index}>
                                            <div className={styles.question} onClick={() => onClick(productsSectionOpen)}>{question}{questionIcon}</div>
                                            <div className={(styles.answer)}>{answer}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Scrollable>
                    </div>
                </div>
            </div>
            {!permittedToView && <NettingEngineEnquireOverlay jurisdiction={jurisdiction} width={width} left={left} />}
        </div>
    );
};
