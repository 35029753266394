import { isNumber } from 'lodash/fp';
import { action } from 'typesafe-actions';
import { RawDraftContentState } from 'draft-js';

import { DatasetBuilderActionTypes, DatasetsOpenFieldSection, DatasetView, DocumentNameDatasets, DocumentSpecificHiddenFields, DocumentSpecificSectionIds, HiddenDocumentField, HiddenDocumentSection, LinkDirection, SettingsModalType } from './types';
import { AgencyDataset, AgencyDatasetField, AgencyDefinition, AgencyLinkedSingleField, DatasetDefinition, DatasetDefinitionDB, DatasetField, DatasetFieldSettings, DatasetSection, DatasetType, FieldHighlight, SingleDatasetField } from '../../../datasets/store';
import { HiddenSection, HiddenField } from '../../my-datasets/store';

export const openDatasetBuilder = () => action(DatasetBuilderActionTypes.OPEN_BUILDER);
export const closeDatasetBuilder = () => action(DatasetBuilderActionTypes.CLOSE_BUILDER);

export const newDatasetDefinition = (type: DatasetType) => action(DatasetBuilderActionTypes.NEW_DATASET_DEFINITION, type);

export const updateDatasetDefinitionTitle = (title: string) => action(DatasetBuilderActionTypes.UPDATE_DATASET_DEFINITION_TITLE, title);

export const updateDatasetFields = (fields: DatasetField[]) => action(DatasetBuilderActionTypes.UPDATE_DATASET_FIELDS, fields);
export const updateDatasetField = (field: string, value: string, index: number) => action(DatasetBuilderActionTypes.UPDATE_DATASET_FIELD, { field, value, index });

export const openSectionModal = (id?: string) => action(DatasetBuilderActionTypes.OPEN_SECTION_MODAL, id);
export const closeSectionModal = () => action(DatasetBuilderActionTypes.CLOSE_SECTION_MODAL);
export const addSection = () => action(DatasetBuilderActionTypes.ADD_SECTION);
export const saveSection = () => action(DatasetBuilderActionTypes.SAVE_SECTION);
export const removeSection = (id: string) => action(DatasetBuilderActionTypes.REMOVE_SECTION, id);
export const updateSection = (key: string, value: string) => action(DatasetBuilderActionTypes.UPDATE_SELECTED_SECTION, { key, value });
export const selectSection = (id: string) => action(DatasetBuilderActionTypes.SELECT_SECTION, id);
export const updateSections = (sections: DatasetSection[]) => action(DatasetBuilderActionTypes.UPDATE_SECTIONS, sections);

export const addDatasetField = (datasetField: DatasetField) => action(DatasetBuilderActionTypes.ADD_DATASET_FIELD, datasetField);
export const removeDatasetField = (id: string) => action(DatasetBuilderActionTypes.REMOVE_DATASET_FIELD, id);

export const toggleRefModalOpen = (index: number, value: boolean, groupIndex?: number) => {
    return !isNumber(groupIndex) ? action(DatasetBuilderActionTypes.TOGGLE_REF_MODAL_OPEN, { index, value }) : action(DatasetBuilderActionTypes.TOGGLE_GROUP_REF_MODAL_OPEN, { index, value, groupIndex });
};

export const toggleSystemIdModalOpen = (index: number, value: boolean, groupIndex?: number) => {
    return !isNumber(groupIndex) ? action(DatasetBuilderActionTypes.TOGGLE_SYSTEM_ID_MODAL_OPEN, { index, value }) : action(DatasetBuilderActionTypes.TOGGLE_GROUP_SYSTEM_ID_MODAL_OPEN, { index, value, groupIndex });
};

export const toggleSettingsMenuOpen = (index: number, value: boolean, groupIndex?: number) => {
    return !isNumber(groupIndex) ? action(DatasetBuilderActionTypes.TOGGLE_SETTINGS_MENU_OPEN, { index, value }) : action(DatasetBuilderActionTypes.TOGGLE_GROUP_SETTINGS_MENU_OPEN, { index, value, groupIndex });
};
export const updateSettingsValue = (key: keyof DatasetFieldSettings, value: boolean | string | null | RawDraftContentState | FieldHighlight, index: number, groupIndex?: number) => {
    return !isNumber(groupIndex) ? action(DatasetBuilderActionTypes.UPDATE_SETTINGS_VALUE, { key, value, index }) : action(DatasetBuilderActionTypes.UPDATE_GROUP_SETTINGS_VALUE, { key, value, index, groupIndex });
};

export const toggleDeleteDatasetConfirmationModal = (dataset: DatasetDefinitionDB | DatasetDefinition | null) => action(DatasetBuilderActionTypes.TOGGLE_DELETE_DATASET_CONFIRMATION_MODAL, dataset);
export const deleteDatasetStarted = (datasetId: number) => action(DatasetBuilderActionTypes.DELETE_DATASET_STARTED, datasetId);
export const deleteDatasetSuccessful = (definitions: DatasetDefinitionDB[]) => action(DatasetBuilderActionTypes.DELETE_DATASET_SUCCESSFUL, definitions);
export const deleteDatasetFailed = (error: string) => action(DatasetBuilderActionTypes.DELETE_DATASET_FAILED, error);

export const selectGroup = (id: string | null) => action(DatasetBuilderActionTypes.SELECT_GROUP, id);
export const addDatasetFieldToGroup = (field: DatasetField, id: string) => action(DatasetBuilderActionTypes.ADD_DATASET_FIELD_TO_GROUP, { field, id });
export const removeDatasetGroupField = (id: string, groupIndex: number) => action(DatasetBuilderActionTypes.REMOVE_DATASET_FIELD_FROM_GROUP, { id, groupIndex });
export const updateDatasetGroupField = (field: string, value: string, index: number, groupIndex: number) => action(DatasetBuilderActionTypes.UPDATE_DATASET_GROUP_FIELD, { field, value, index, groupIndex });
export const updateDatasetGroupFields = (fields: SingleDatasetField[], groupIndex: number) => action(DatasetBuilderActionTypes.UPDATE_DATASET_GROUP_FIELDS, { fields, groupIndex });

export const toggleDatasetPreview = () => action(DatasetBuilderActionTypes.TOGGLE_PREVIEW);

export const togglePublishModal = (value: boolean) => action(DatasetBuilderActionTypes.TOGGLE_PUBLISH_MODAL, value);
export const updatePublishReason = (value: string) => action(DatasetBuilderActionTypes.UPDATE_PUBLISH_REASON, value);

export const publishDatasetDefinitionStarted = () => action(DatasetBuilderActionTypes.PUBLISH_DATASET_DEFINITION_STARTED);
export const publishDatasetDefinitionSuccessful = () => action(DatasetBuilderActionTypes.PUBLISH_DATASET_DEFINITION_SUCCESSFUL);
export const publishDatasetDefinitionFailed = (error: string) => action(DatasetBuilderActionTypes.PUBLISH_DATASET_DEFINITION_FAILED, error);

export const toggleHelpModal = (value: boolean) => action(DatasetBuilderActionTypes.TOGGLE_HELP_MODAL, value);

export const toggleReorderSectionsModal = () => action(DatasetBuilderActionTypes.TOGGLE_SECTION_REORDER_MODAL);

export const updateDefinitionClientIds = (clientIds: number[]) => action(DatasetBuilderActionTypes.UPDATE_DATASET_DEFINITION_CLIENT_IDS, clientIds);

export const updateDefinitionNewFields = (newFields: (HiddenField | HiddenSection)[]) => action(DatasetBuilderActionTypes.UPDATE_DATASET_DEFINITION_NEW_FIELDS, newFields);

export const fetchAllDatasetDefinitionsStarted = () => action(DatasetBuilderActionTypes.FETCH_ALL_DATASET_DEFINITIONS_STARTED);
export const fetchAllDatasetDefinitionsSuccessful = (datasetDefinitions: DatasetDefinitionDB[]) => action(DatasetBuilderActionTypes.FETCH_ALL_DATASET_DEFINITIONS_SUCCESSFUL, datasetDefinitions);
export const fetchAllDatasetDefinitionsFailed = (error: string) => action(DatasetBuilderActionTypes.FETCH_ALL_DATASET_DEFINITIONS_FAILED, error);

export const openDatasetDefinition = (datasetDefinition: DatasetDefinition) => action(DatasetBuilderActionTypes.OPEN_DATASET_DEFINITION, datasetDefinition);
export const goBackToDatasetDefinition = (index?: number) => action(DatasetBuilderActionTypes.GO_BACK_TO_DATASET_DEFINITION, index);
export const editDatasetDefinition = (datasetDefinition: DatasetDefinition, previousDatasetIds: number[] = []) => action(DatasetBuilderActionTypes.EDIT_DATASET_DEFINITION, { datasetDefinition, previousDatasetIds });

export const toggleDefinitionHistoryModal = (isOpen: boolean) => action(DatasetBuilderActionTypes.TOGGLE_DEFINITION_HISTORY_MODAL, isOpen);

export const toggleSettingsLinkModal = (index: number | null, type: SettingsModalType | null, groupIndex?: number) => action(DatasetBuilderActionTypes.TOGGLE_SETTINGS_LINK_MODAL, { index, groupIndex, type });

export const toggleIsAgency = (value: boolean) => action(DatasetBuilderActionTypes.TOGGLE_IS_AGENCY, value);

export const toggleDatasetView = (view: DatasetView, datasetId?: number) => action(DatasetBuilderActionTypes.TOGGLE_DATASET_VIEW, { view, datasetId });

export const toggleCalculatorHelpModal = () => action(DatasetBuilderActionTypes.TOGGLE_CALCULATOR_HELP_MODAL);

export const toggleRiskToleranceHelpModal = () => action(DatasetBuilderActionTypes.TOGGLE_RISK_TOLERANCE_HELP_MODAL);

export const toggleUnsavedChangesModal = (linkDirection: LinkDirection | null) => action(DatasetBuilderActionTypes.TOGGLE_UNSAVED_CHANGES_MODAL, linkDirection);
export const setDatasetDefinitionHasUpdated = (hasUpdated: boolean) => action(DatasetBuilderActionTypes.SET_DATASET_DEFINITION_HAS_UPDATED, hasUpdated);

export const fetchAllDocumentDatasetsStarted = () => action(DatasetBuilderActionTypes.FETCH_ALL_DOCUMENT_DATASETS_STARTED);
export const fetchAllDocumentDatasetsSuccessful = (datasets: DocumentNameDatasets[]) => action(DatasetBuilderActionTypes.FETCH_ALL_DOCUMENT_DATASETS_SUCCESSFUL, datasets);
export const fetchAllDocumentDatasetsFailed = (error: string) => action(DatasetBuilderActionTypes.FETCH_ALL_DOCUMENT_DATASETS_FAILED, error);

export const fetchDefinitionsForDatasetIdStarted = (datasetId: number) => action(DatasetBuilderActionTypes.FETCH_DEFINITIONS_FOR_DATASET_STARTED, datasetId);
export const fetchDefinitionsForDatasetIdSuccessful = (datasetDefinitions: DatasetDefinition[], documentHiddenFields: DocumentSpecificHiddenFields, documentSpecificMLSectionIds: DocumentSpecificSectionIds) => action(DatasetBuilderActionTypes.FETCH_DEFINITIONS_FOR_DATASET_SUCCESSFUL, { datasetDefinitions, documentHiddenFields, documentSpecificMLSectionIds });
export const fetchDefinitionsForDatasetIdFailed = (error: string) => action(DatasetBuilderActionTypes.FETCH_DEFINITIONS_FOR_DATASET_FAILED, error);

export const removeOpenFieldSection = (fieldSection: DatasetsOpenFieldSection) => action(DatasetBuilderActionTypes.REMOVE_DATASETS_OPEN_FIELD_SECTION, fieldSection);
export const addOpenFieldSection = (fieldSection: DatasetsOpenFieldSection) => action(DatasetBuilderActionTypes.ADD_DATASETS_OPEN_FIELD_SECTION, fieldSection);
export const removeAllFieldSections = () => action(DatasetBuilderActionTypes.REMOVE_ALL_DATASETS_FIELD_SECTIONS);

export const toggleDocumentHiddenField = (datasetId: number, hiddenField: HiddenDocumentField | HiddenDocumentSection) => action(DatasetBuilderActionTypes.TOGGLE_DOCUMENT_HIDDEN_FIELDS, { datasetId, hiddenField });

export const toggleModalDocumentDataset = (datasetId: number | null, hiddenDocumentNameIds: number[] | undefined) => action(DatasetBuilderActionTypes.TOGGLE_MODAL_DOCUMENT_DATASET, { datasetId, hiddenDocumentNameIds });

export const resetDocumentDatasetConfigure = () => action(DatasetBuilderActionTypes.RESET_DOCUMENT_DATASET_CONFIGURE);

export const toggleUnsavedDatasetConfigurationModal = (isOpen: boolean) => action(DatasetBuilderActionTypes.TOGGLE_UNSAVED_DATASET_CONFIGURATION_MODAL, isOpen);

export const setPreviewSelectedDocument = (value: number | null) => action(DatasetBuilderActionTypes.SET_PREVIEW_SELECTED_DOCUMENT, value);

export const saveDocumentDatasetConfigurationStarted = () => action(DatasetBuilderActionTypes.SAVE_DOCUMENT_DATASET_CONFIGURATION_STARTED);
export const saveDocumentDatasetConfigurationSuccessful = () => action(DatasetBuilderActionTypes.SAVE_DOCUMENT_DATASET_CONFIGURATION_SUCCESSFUL);
export const saveDocumentDatasetConfigurationFailed = (error: string) => action(DatasetBuilderActionTypes.SAVE_DOCUMENT_DATASET_CONFIGURATION_FAILED, error);

export const addDocumentDatasetSectionReference = (datasetId: number, documentNameId: number, sectionId: string, reference: string) => action(DatasetBuilderActionTypes.ADD_DOCUMENT_DATASET_SECTION_REFERENCE, { datasetId, documentNameId, sectionId, reference });
export const deleteDocumentDatasetSectionReference = (datasetId: number, documentNameId: number, sectionId: string, reference: string) => action(DatasetBuilderActionTypes.DELETE_DOCUMENT_DATASET_SECTION_REFERENCE, { datasetId, documentNameId, sectionId, reference });
export const updateDocumentDatasetSectionReferenceOrder = (datasetId: number, documentNameId: number, sectionId: string, references: string[]) => action(DatasetBuilderActionTypes.UPDATE_DOCUMENT_DATASET_SECTION_REFERENCE_ORDER, { datasetId, documentNameId, sectionId, references });

export const setDatasetDocumentConfigUpdated = (hasUpdated: boolean) => action(DatasetBuilderActionTypes.DATASET_DOCUMENT_CONFIG_UPDATED, hasUpdated);

export const setConfigureDatasetParentId = (datasetId: number) => action(DatasetBuilderActionTypes.SET_CONFIGURE_DATASET_PARENT_ID, datasetId);

// Agency Builder Actions
export const toggleAgencyBuilder = (value: boolean) => action(DatasetBuilderActionTypes.TOGGLE_AGENCY_BUILDER, value);

export const fetchAgencyDefinitionsStarted = () => action(DatasetBuilderActionTypes.FETCH_AGENCY_DEFINITIONS_STARTED);
export const fetchAgencyDefinitionsSuccessful = (agencyDefinitions: AgencyDataset[]) => action(DatasetBuilderActionTypes.FETCH_AGENCY_DEFINITIONS_SUCCESSFUL, agencyDefinitions);
export const fetchAgencyDefinitionsFailed = (error: string) => action(DatasetBuilderActionTypes.FETCH_AGENCY_DEFINITIONS_FAILED, error);

export const editAgencyDefinition = (agencyDefinition: AgencyDefinition | null, datasetTitle: string, agreementTypeId: number, datasetId: number) => action(DatasetBuilderActionTypes.EDIT_AGENCY_DEFINITION, { agencyDefinition, datasetTitle, agreementTypeId, datasetId });

export const addAgencyDatasetField = (datasetField: AgencyDatasetField) => action(DatasetBuilderActionTypes.ADD_AGENCY_DATASET_FIELD, datasetField);
export const removeAgencyDatasetField = (id: string) => action(DatasetBuilderActionTypes.REMOVE_AGENCY_DATASET_FIELD, id);
export const updateAgencyDatasetField = (field: string, value: string, index: number) => action(DatasetBuilderActionTypes.UPDATE_AGENCY_DATASET_FIELD, { field, value, index });

export const updateAgencyDatasetFields = (fields: AgencyDatasetField[]) => action(DatasetBuilderActionTypes.UPDATE_AGENCY_DATASET_FIELDS, fields);

export const toggleAgencyRefModalOpen = (index: number, value: boolean) => action(DatasetBuilderActionTypes.TOGGLE_AGENCY_REF_MODAL_OPEN, { index, value });

export const toggleAgencySettingsMenuOpen = (index: number | null) => action(DatasetBuilderActionTypes.TOGGLE_AGENCY_SETTINGS_MODAL_OPEN, index);

export const updateAgencyFieldSetting = (key: string, value: boolean | string | null, index: number) => action(DatasetBuilderActionTypes.UPDATE_AGENCY_SETTINGS_VALUE, { key, value, index });

export const updateAgencyLinkedFields = (index: number, value: AgencyLinkedSingleField[]) => action(DatasetBuilderActionTypes.UPDATE_AGENCY_LINKED_FIELDS, { value, index });

export const fetchAgencyFieldsStarted = (datasetId: number) => action(DatasetBuilderActionTypes.FETCH_AGENCY_FIELDS_STARTED, datasetId);
export const fetchAgencyFieldsSuccessful = (agencyFields: AgencyLinkedSingleField[]) => action(DatasetBuilderActionTypes.FETCH_AGENCY_FIELDS_SUCCESSFUL, agencyFields);
export const fetchAgencyFieldsFailed = (error: string) => action(DatasetBuilderActionTypes.FETCH_AGENCY_FIELDS_FAILED, error);
export const togglePublishAgencyConfirmation = (value: boolean) => action(DatasetBuilderActionTypes.TOGGLE_PUBLISH_AGENCY_CONFIRMATION, value);

export const publishAgencyDatasetDefinitionStarted = () => action(DatasetBuilderActionTypes.PUBLISH_AGENCY_DATASET_DEFINITION_STARTED);
export const publishAgencyDatasetDefinitionSuccessful = () => action(DatasetBuilderActionTypes.PUBLISH_AGENCY_DATASET_DEFINITION_SUCCESSFUL);
export const publishAgencyDatasetDefinitionFailed = (error: string) => action(DatasetBuilderActionTypes.PUBLISH_AGENCY_DATASET_DEFINITION_FAILED, error);
