import classnames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch } from '../../../hooks/react-redux';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import styles from './Fields.module.scss';

interface NumberProps {
    id: string;
    disabled?: boolean;
    value: string | number | undefined | null;
    index: number;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    placeholder?: string;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean;
    showAIModifiedUserCorrected?: boolean;
    isMLCorrection?: boolean;
    showClause?: boolean;
    isTable?: boolean;
    includedInAnnex?: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
}

export const Number: React.FC<NumberProps> = ({
    id,
    disabled = false,
    value,
    placeholder = '0',
    index,
    sectionId,
    groupIndex,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    isMLCorrection = false,
    showClause = false,
    isTable = false,
    includedInAnnex = false,
    datasetId,
    parentFieldId,
    modalInstance
}) => {
    const [includeDecimal, setIncludeDecimal] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const updateValue = useCallback((stringValue: string) => {
        setIncludeDecimal(stringValue.length > 0 && stringValue[stringValue.length - 1] === '.');
        const numberValue = parseFloat(stringValue.replace(/,/g, ''));
        if (!isNaN(numberValue) || (isNaN(numberValue) && stringValue.length === 0)) {
            const value = !isNaN(numberValue) ? numberValue : undefined;
            if (isMLCorrection) {
                dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
            } else {
                dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
            }
        }
    }, [dispatch, datasetId, parentFieldId, index, sectionId, groupIndex, rowId, modalInstance, isMLCorrection]);

    const placeholderText = includedInAnnex ? 'See Agency Annex' : placeholder;
    const formattedValue = useMemo(() => {
        if (isUndefined(value) || isNull(value)) {
            return '';
        }
        let pattern = /(?=(?!^)\d{3}(?:\b|(?:\d{3})+)\b)/g;
        if (value.toString().includes('.')) {
            pattern = /(?=(?!^)\d{3}(?:\b|(?:\d{3})+)\b\.)/g;
        }
        return `${value.toString().replace(pattern, ',')}${includeDecimal ? '.' : ''}`;
    }, [value, includeDecimal]);

    return (
        <input
            className={classnames(styles.numberField, {
                [styles.updatedInputField]: showFieldUpdated,
                [styles.newInputField]: showDatasetUpdated,
                [styles.aiInputField]: showAIModified,
                [styles.aiCorrectedInputField]: showAIModifiedUserCorrected,
                [styles.numberFieldWithClauseLabel]: showClause && isTable
            })}
            data-testid={`definition-number-${id}`}
            disabled={disabled || includedInAnnex}
            value={formattedValue}
            onChange={e => updateValue(e.target.value)}
            placeholder={placeholderText}
        />
    );
};
