import { isNull } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { InformationTooltip } from '../../../../shared/tooltip';
import { getCurrentPlaybook, getDisablePublishAndVersionControl, getPlaybook, getPlaybookVersionMajorIncreased, updatePlaybookValue } from '../../store';
import styles from '../../Playbook.module.scss';

interface VersionControlProps {
    testId?: string;
}

export const VersionControl: React.FC<VersionControlProps> = ({ testId = 'playbook-version-control' }) => {
    const dispatch = useAppDispatch();

    const latestPlaybookVersion = useAppSelector(getCurrentPlaybook);
    const majorVersionIncreased = useAppSelector(getPlaybookVersionMajorIncreased);
    const { versionMajor, versionMinor } = useAppSelector(getPlaybook);
    const disablePublishAndVersionControl = useAppSelector(getDisablePublishAndVersionControl);

    const updateVersion = useCallback((major: boolean, number: string) => {
        const versionNumber = parseInt(number);
        if (major) {
            if (!latestPlaybookVersion || versionNumber >= latestPlaybookVersion.versionMajor) {
                dispatch(updatePlaybookValue('versionMajor', versionNumber));
            }
        } else {
            if (!latestPlaybookVersion || (!majorVersionIncreased && versionNumber >= latestPlaybookVersion.versionMinor) || majorVersionIncreased) {
                dispatch(updatePlaybookValue('versionMinor', versionNumber));
            }
        }
    }, [dispatch, latestPlaybookVersion, majorVersionIncreased]);

    const minMajorVersion = useMemo(() => !isNull(latestPlaybookVersion) ? latestPlaybookVersion.versionMajor : 1, [latestPlaybookVersion]);
    const minMinorVersion = useMemo(() => !isNull(latestPlaybookVersion) && !majorVersionIncreased ? latestPlaybookVersion.versionMinor : 0, [latestPlaybookVersion, majorVersionIncreased]);

    return (
        <div className={styles.versionControlWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.versionWrapper}>
                <div className={styles.versionInputLabel} data-testid={`${testId}-label`}>Version</div>
                <div className={styles.versionTooltipWrapper}>
                    <InformationTooltip content='Version number allows you to keep track of major and minor incremental releases. Please update the left value for major releases and the right for minor changes to the playbook.' />
                </div>
            </div>
            <div className={styles.versionInput}>
                <input
                    className={styles.versionNumber}
                    type='number'
                    min={minMajorVersion}
                    data-testid={`${testId}-version-major`}
                    value={versionMajor}
                    onChange={e => updateVersion(true, e.target.value)}
                    disabled={disablePublishAndVersionControl}
                />
                <div className={styles.versionSeparator}>.</div>
                <input
                    className={styles.versionNumber}
                    type='number'
                    min={minMinorVersion}
                    data-testid={`${testId}-version-minor`}
                    value={versionMinor}
                    onChange={e => updateVersion(false, e.target.value)}
                    disabled={disablePublishAndVersionControl}
                />
            </div>
        </div>
    );
};
