import React from 'react';

import { PositionModal, PositionModalProps } from '../../shared/modal/PositionModal';
import { Notification } from './store';
import { Notifications } from './Notifications';

interface NotificationsModalProps extends PositionModalProps {
    notifications: Notification[];
}

export const NotificationsModal: React.FC<NotificationsModalProps> = ({ notifications, position, closeModal, isOpen, testId }) => (
    <PositionModal
        isOpen={isOpen}
        closeModal={closeModal}
        position={position}
        testId={testId}
        height='fit-content'
        renderLeftOfClick
        width='320px'
        overflow='none'
    >
        <Notifications closeModal={closeModal} notifications={notifications} />
    </PositionModal>
);
