import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { fetchAllDocumentGroupsStarted, fetchAvailableDocumentNamesStarted } from '../../admin/documents/store';
import { fetchAllEntitiesStarted } from '../../admin/entity/store';
import { DropdownOption } from '../../shared/dropdown/Dropdown';
import { DropdownSummary } from '../../shared/dropdown/DropdownSummary';
import { Spinner } from '../../shared/spinner/Spinner';
import { fetchDocumentsStarted } from '../my-documents/store';
import styles from './Analytics.module.scss';
import { DocumentEntitySpreadChart } from './charts/DocumentEntitySpreadChart';
import { AllRiskToleranceCharts } from './charts/DocumentRiskTolerance/AllRiskToleranceCharts';
import { DocumentRiskToleranceChartWrapper } from './charts/DocumentRiskTolerance/DocumentRiskToleranceChart';
import { EntityDocumentRiskToleranceChartWrapper } from './charts/DocumentRiskTolerance/EntityDocumentRiskToleranceChart';
import { PreExecutionDocumentRiskToleranceChartWrapper } from './charts/DocumentRiskTolerance/PreExecutionDocumentRiskToleranceChart';
import { JurisdictionChartWrapper } from './charts/JurisdictionChartWrapper';
import { DocumentAnalyticsChart, fetchAllDocumentsRiskToleranceStarted, fetchAllMyCompanyEntitiesStarted, fetchAllPreExecutionDocumentsRiskToleranceStarted, getAvailableAnalyticsEntities, getSelectedMyCompanyEntity, getTileInView, setDocumentTileInView, setSelectedMyCompanyEntity } from './store';

export interface DocumentAnalyticsRouteParams {
    chart: string | undefined;
}

export const DocumentAnalytics: React.FC<RouteComponentProps<DocumentAnalyticsRouteParams>> = ({ match: { params } }) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [tilesDimensions, setTilesDimensions] = useState<{ height: number; width: number; }>({ height: 0, width: 0 });

    useFetchStarted([fetchAllMyCompanyEntitiesStarted(), fetchDocumentsStarted(), fetchAllDocumentsRiskToleranceStarted(), fetchAvailableDocumentNamesStarted(), fetchAllEntitiesStarted(true), fetchAllPreExecutionDocumentsRiskToleranceStarted(), fetchAllDocumentGroupsStarted()]);
    const [screenWidth, screenHeight] = useWindowResize();

    const dispatch = useAppDispatch();

    const availableEntities = useAppSelector(getAvailableAnalyticsEntities);
    const selectedCompanyEntityId = useAppSelector(getSelectedMyCompanyEntity);
    const tileInView = useAppSelector(getTileInView);

    const setTileInView = useCallback((chart: DocumentAnalyticsChart | null, shouldRedirect = true) => dispatch(setDocumentTileInView(chart, shouldRedirect)), [dispatch]);
    const getSelectedCompanyById = useCallback((selectedEntityId: number) => availableEntities.find(({ value }) => value === selectedEntityId.toString())!.label || '', [availableEntities]);
    const myCompanyValue = useMemo(() => selectedCompanyEntityId ? { value: selectedCompanyEntityId.toString(), label: getSelectedCompanyById(selectedCompanyEntityId) } : null, [selectedCompanyEntityId, getSelectedCompanyById]);
    const myCompanyPlaceholder = useMemo(() => availableEntities.length ? 'Select...' : 'None selected as my company', [availableEntities]);

    const updateDropdown = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
            dispatch(setSelectedMyCompanyEntity(parseInt(selected)));
        }
    }, [dispatch]);

    const wideViewChartDimensions = useMemo(() => ({ height: tilesDimensions.height / 2, width: tilesDimensions.width * 0.6 }), [tilesDimensions]);
    const splitWideViewChartDimensions = useMemo(() => ({ height: wideViewChartDimensions.height, width: wideViewChartDimensions.width / 2 }), [wideViewChartDimensions]);
    const splitViewChartDimensions = useMemo(() => ({ height: tilesDimensions.height, width: tilesDimensions.width / 2 }), [tilesDimensions]);
    const spreadChartDimensions = useMemo(() => ({ height: tilesDimensions.height, width: tilesDimensions.width * 0.4 }), [tilesDimensions]);

    const tileInViewChart: JSX.Element | null = useMemo(() => {
        switch (tileInView) {
            case DocumentAnalyticsChart.DOCUMENT_ENTITY_SPREAD:
                return <DocumentEntitySpreadChart dimensions={splitViewChartDimensions} analyticsSpinner={showSpinner} tileInView={tileInView} setTileInView={setTileInView} />;
            case DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE:
                return <DocumentRiskToleranceChartWrapper dimensions={tilesDimensions} tileInView={tileInView} setTileInView={setTileInView} analyticsSpinner={showSpinner} />;
            case DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE:
                return <EntityDocumentRiskToleranceChartWrapper dimensions={tilesDimensions} tileInView={tileInView} setTileInView={setTileInView} analyticsSpinner={showSpinner} />;
            case DocumentAnalyticsChart.ENTITY_JURISDICTION:
                return <JurisdictionChartWrapper dimensions={tilesDimensions!} tileInView={tileInView} setTileInView={setTileInView} analyticsSpinner={showSpinner} />;
            case DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE:
                return <PreExecutionDocumentRiskToleranceChartWrapper dimensions={tilesDimensions} tileInView={tileInView} setTileInView={setTileInView} analyticsSpinner={showSpinner} />;
            default:
                return null;
        }
    }, [tilesDimensions, tileInView, setTileInView, showSpinner, splitViewChartDimensions]);

    useEffect(() => {
        setShowSpinner(true);
        if (screenWidth && screenHeight) {
            const width = (screenWidth - 80);
            const height = (screenHeight - 190);
            setTilesDimensions({ height, width });
            setTimeout(() => setShowSpinner(false), 50);
        }
    }, [screenWidth, screenHeight, tileInView, setShowSpinner]);

    const allUrlCharts = useMemo(() => Object.values(DocumentAnalyticsChart).map(val => val.replaceAll(' ', '-')), []);
    const tileIsValidAndNotInView = useCallback((urlChart: string) => {
        const urlChartIsValid = allUrlCharts.includes(urlChart);
        if (urlChartIsValid) {
            const chart = urlChart.replaceAll('-', ' ') as DocumentAnalyticsChart;
            return isNull(tileInView) || tileInView !== chart;
        }
    }, [tileInView, allUrlCharts]);

    useEffect(() => {
        if (params.chart && tileIsValidAndNotInView(params.chart)) {
            const chart = params.chart.replaceAll('-', ' ') as DocumentAnalyticsChart;
            setTileInView(chart, false);
        }
    }, [params, tileIsValidAndNotInView, setTileInView]);

    useEffect(() => () => {
        setTileInView(null, false);
    }, [setTileInView]);

    const dropdownSelect = useMemo(() => (
        <div className={styles.myCompanyDropdown}>
            <DropdownSummary
                value={myCompanyValue}
                onChange={updateDropdown}
                options={availableEntities}
                disabled={!availableEntities.length}
                testId='analytics-my-company'
                menuPlacement='bottom'
                hideIndicator={true}
                menuPortalTarget={document.body}
                placeholder={myCompanyPlaceholder}
            />
        </div>
    ), [updateDropdown, myCompanyValue, availableEntities, myCompanyPlaceholder]);

    const testId = 'document-analytics';

    if (showSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.analyticsWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.analyticsHeader}>
                <div className={styles.analyticsTitle} data-testid={`${testId}-header`}>Analytics</div>
                {dropdownSelect}
            </div>
            <div className={styles.analyticsChartsWrapper} data-testid={`${testId}-charts-wrapper`}>
                {isNull(tileInView) ? (
                    <>
                        <div className={styles.leftChartsWrapper}>
                            <div className={styles.wideChartWrapper}>
                                <div className={styles.chartWrapper} style={{ marginBottom: '20px', height: 'calc(100% - 20px)' }}>
                                    <DocumentEntitySpreadChart dimensions={splitWideViewChartDimensions} analyticsSpinner={showSpinner} tileInView={tileInView} setTileInView={setTileInView} />
                                </div>
                            </div>
                            <div className={styles.wideChartWrapper}>
                                <div className={styles.chartWrapper} style={{ height: '100%' }}>
                                    <JurisdictionChartWrapper dimensions={wideViewChartDimensions!} tileInView={tileInView} setTileInView={setTileInView} analyticsSpinner={showSpinner} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.rightChartsWrapper}>
                            <div className={styles.chartWrapper}>
                                <div className={styles.spreadChartWrapper}>
                                    <AllRiskToleranceCharts dimensions={spreadChartDimensions} setTileInView={setTileInView} tileInView={tileInView} />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={styles.wholePageChartWrapper}>
                        {tileInViewChart}
                    </div>
                )}
            </div>
        </div>
    );
};
