import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { formatDate } from '../../../../utils/luxon';
import { Button } from '../../../shared/button/Button';
import { Icon } from '../../../shared/icon/Icon';
import { WarningSign } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { DatasetInstance } from '../DatasetInstance';
import { closeModalDataset, getCurrentInstances, getCurrentModalInstance, getModalInstanceLoading, getModalInstanceOpen, getModalInstanceParentDetails, getModalInstanceSaving, getModalInstanceUpdated, getParentExecutedDate, upsertModalDatasetStarted } from '../store';
import { DatasetModal } from './DatasetModal';
import styles from './ModalInstance.module.scss';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermission } from '../../../auth/login/store';

interface ModalInstanceProps {
    isEditing: boolean;
    isUpdating: boolean;
}

export const ModalInstance: React.FC<ModalInstanceProps> = ({ isEditing, isUpdating }) => {
    const dispatch = useAppDispatch();
    const datasetInstances = useAppSelector(getCurrentInstances);
    const modalInstance = useAppSelector(getCurrentModalInstance);
    const isOpen = useAppSelector(getModalInstanceOpen);
    const isLoading = useAppSelector(getModalInstanceLoading);
    const instanceUpdated = useAppSelector(getModalInstanceUpdated);
    const isSaving = useAppSelector(getModalInstanceSaving);
    const parentDetails = useAppSelector(getModalInstanceParentDetails);
    const parentExecutedDate = useAppSelector(getParentExecutedDate);
    const hasEditDatasetPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.EDIT_DOCUMENT_DATA]));

    const closeModal = useCallback(() => dispatch(closeModalDataset()), [dispatch]);
    const save = useCallback(() => dispatch(upsertModalDatasetStarted()), [dispatch]);
    const saveAndClose = useCallback(() => { save(); closeModal(); }, [save, closeModal]);

    const saveDisabled = useMemo(() => !instanceUpdated || isSaving || !hasEditDatasetPermission, [instanceUpdated, isSaving, hasEditDatasetPermission]);

    const datasetInstance = useMemo(() => datasetInstances.find(({ instance, parentFieldId }) => (instance.datasetId === parentDetails?.datasetId || (!isNull(instance.annexDefinitionId) && instance.annexDefinitionId === parentDetails?.datasetId)) && parentFieldId === parentDetails?.parentFieldId)?.instance, [datasetInstances, parentDetails]);
    const instanceExecutedDateMatchesParent = useMemo(() => datasetInstance && parentExecutedDate && formatDate(datasetInstance.executedDate) === parentExecutedDate || false, [datasetInstance, parentExecutedDate]);

    return (
        <DatasetModal isOpen={isOpen} closeModal={closeModal} topOffset='30vh'>
            <div className={styles.modalInstanceWrapper}>
                {modalInstance && !isLoading && parentDetails ? <DatasetInstance datasetInstance={modalInstance} isEditing={isEditing} isUpdating={isUpdating} parentDatasetIds={[]} modalInstance parentFieldId={parentDetails.parentFieldId} instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent} /> : <Spinner />}
                <div className={styles.buttonWrapper}>
                    <div className={styles.leftButtonWrapper}>
                        <Button onClick={closeModal} label='Close' />
                        {instanceUpdated && <div className={styles.unsavedDatasetWarning}>
                            <Icon icon={WarningSign} fontSize={22} />
                            <div className={styles.unsavedDatasetWarningTitle}>Unsaved changes</div>
                        </div>}
                    </div>
                    {(isEditing || isUpdating) &&
                        <div className={styles.rightButtonWrapper}>
                            <Button onClick={saveAndClose} label='Save & Close' disabled={saveDisabled} />
                            <Button onClick={save} label='Save' disabled={saveDisabled} />
                        </div>
                    }
                </div>
            </div>
        </DatasetModal>
    );
};
