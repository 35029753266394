import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { User } from '../../shared/icons';
import { getIsDeleting, Client, deleteClientStarted } from './store';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';

interface DeleteClientConfirmationModalProps {
    isOpen: boolean;
    client: Client;
    closeModal: () => void;
}

export const DeleteClientConfirmationModal: React.FC<DeleteClientConfirmationModalProps> = ({ isOpen, closeModal, client }) => {
    const dispatch = useAppDispatch();
    const isDeleting = useAppSelector(getIsDeleting);
    const deleteClient = () => dispatch(deleteClientStarted(client));
    const headerLabel = `Delete Client${client.clientName ? ` (${client.clientName})` : ''}`;

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={deleteClient}
            isDeleting={isDeleting}
            headerLabel={headerLabel}
            name={client.clientName}
            testId='client'
            icon={User}
        />
    );
};
