import React from 'react';
import Modal from 'react-modal';
import { noop } from 'lodash/fp';

import { Button } from '../button/Button';
import styles from './Modal.module.scss';

interface ConfirmationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    confirm?: () => void;
    confirmDisabled?: boolean;
    closeDisabled?: boolean;
    showConfirm?: boolean;
    showClose?: boolean;
    closeOnOverlayClick?: boolean
    confirmLabel?: string;
    closeLabel?: string;
    testId?: string;
    showPreview?: boolean;
    openPreview?: () => void;
    previewLabel?: string;
    previewDisabled?: boolean;
    showOverlay?: boolean;
    confirmDisabledTooltip?: string[];
    previewDisabledTooltip?: string[];
    closeDisabledTooltip?: string[];
    closeIcon?: React.FC;
    children?: React.ReactNode;
    zIndex?: number;
    maxWidth?: number;
    showConfirmSpinner?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    closeModal,
    confirm = noop,
    closeDisabled,
    confirmDisabled,
    showConfirm = true,
    showClose = true,
    closeOnOverlayClick = false,
    confirmLabel = 'Confirm',
    closeLabel = 'Cancel',
    testId = '',
    children,
    showPreview = false,
    openPreview = noop,
    previewLabel = 'Preview',
    previewDisabled = false,
    showOverlay = true,
    confirmDisabledTooltip = [],
    previewDisabledTooltip = [],
    closeDisabledTooltip = [],
    closeIcon,
    zIndex = 10,
    maxWidth,
    showConfirmSpinner = false
}) => {
    const defaultOverlayStyle = showOverlay ? { display: 'flex', zIndex } : { display: 'flex', backgroundColor: 'transparent', zIndex };
    return (
        <Modal
            isOpen={isOpen}
            className={styles.modal}
            ariaHideApp={false}
            style={{ overlay: defaultOverlayStyle, content: { maxWidth: maxWidth ? `${maxWidth}%` : '' } }}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            shouldCloseOnEsc={closeOnOverlayClick}
            onRequestClose={closeModal}
        >
            <div className={styles.wrapper} data-testid={`${testId}-confirmation-modal-wrapper`}>
                {children}
                <div className={styles.buttonWrapper}>
                    {showPreview && <Button onClick={openPreview} label={previewLabel} disabled={previewDisabled} testId={`${testId}-preview`} disabledTooltip={previewDisabledTooltip} />}
                    {showClose && <Button onClick={closeModal} label={closeLabel} disabled={closeDisabled} testId={`${testId}-close`} icon={closeIcon} fontSize={18} renderIconRight disabledTooltip={closeDisabledTooltip} />}
                    {showConfirm && <Button onClick={confirm} label={confirmLabel} disabled={confirmDisabled} testId={`${testId}-confirm`} disabledTooltip={confirmDisabledTooltip} showSpinner={showConfirmSpinner} />}
                </div>
            </div>
        </Modal>
    );
};
