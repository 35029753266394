import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { getAvailableCompanies, getAvailableThirdPartyCompanies, getNewSupplyChainElement, NewSupplyChainElement, updateNewElement } from '../store';
import styles from '../SupplyChain.module.scss';
import { RadioListOption } from '../../../shared/radio/RadioList';
import { RadioRow } from '../../../shared/radio/RadioRow';

enum CompanyType {
    INTRA_GROUP_COMPANY = 'Intra Group Company',
    THIRD_PARTY_COMPANY = 'Third Party Company'
}

interface NewElementCompanyProps {
    parentCompanyId: number;
}

export const NewElementCompany: React.FC<NewElementCompanyProps> = ({ parentCompanyId }) => {
    const [companyType, setCompanyType] = useState<CompanyType | null>(null);
    const dispatch = useAppDispatch();
    const availableCompanies = useAppSelector(getAvailableCompanies);
    const availableThirdPartyCompanies = useAppSelector(getAvailableThirdPartyCompanies);
    const newElement = useAppSelector(getNewSupplyChainElement);
    const { companyId, thirdPartyCompanyId } = newElement;

    const availableCompaniesWithoutParent = useMemo(() => availableCompanies.filter(({ value }) => value !== parentCompanyId.toString()), [availableCompanies, parentCompanyId]);

    const companyValue = useMemo(() => companyId && availableCompaniesWithoutParent.find(({ value }) => value === companyId.toString()) || null, [companyId, availableCompaniesWithoutParent]);
    const thirdPartyCompanyValue = useMemo(() => thirdPartyCompanyId && availableThirdPartyCompanies.find(({ value }) => value === thirdPartyCompanyId.toString()) || null, [thirdPartyCompanyId, availableThirdPartyCompanies]);

    const updateNewElementValue = useCallback((key: keyof NewSupplyChainElement, value: number | null) => dispatch(updateNewElement(key, value)), [dispatch]);

    const updateDropdownValue = useCallback((key: keyof NewSupplyChainElement, dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = parseInt((dropdownValue as DropdownOption).value);
        }
        updateNewElementValue(key, value);
    }, [updateNewElementValue]);

    const updateCompanyTypeSelection = useCallback((type: CompanyType) => {
        if (companyType === type) {
            return;
        }
        setCompanyType(type);
        updateNewElementValue('companyId', null);
        updateNewElementValue('thirdPartyCompanyId', null);
    }, [updateNewElementValue, companyType]);

    const companyOptions: RadioListOption[] = useMemo(() => [
        {
            id: CompanyType.INTRA_GROUP_COMPANY,
            isSelected: companyType === CompanyType.INTRA_GROUP_COMPANY,
            label: 'Intra Group Company'
        },
        {
            id: CompanyType.THIRD_PARTY_COMPANY,
            isSelected: companyType === CompanyType.THIRD_PARTY_COMPANY,
            label: 'ICT Third-Party Service Provider'
        }
    ], [companyType]);

    const toggleCompanyType = useCallback((id: string) => updateCompanyTypeSelection(id as CompanyType), [updateCompanyTypeSelection]);

    return (
        <div className={styles.newElementCompanyWrapper}>
            <div className={styles.companyTypeTitle}>Is this one of your companies or an ICT third-party service provider?</div>
            <div className={styles.companyTypeWrapper}>
                <RadioRow options={companyOptions} onChange={toggleCompanyType} testId='dora-new-element-company' />
            </div>
            {companyType === CompanyType.INTRA_GROUP_COMPANY &&
                <div className={styles.companySelectWrapper}>
                    <div className={styles.companySelectLabel}>Which of your companies would you like to add to the supply chain?</div>
                    <Dropdown
                        testId='supply-chain-my-companies'
                        onChange={val => updateDropdownValue('companyId', val)}
                        value={companyValue}
                        options={availableCompaniesWithoutParent}
                        isClearable
                        placeholder='Select...'
                        menuPortalTarget={document.body}
                    />
                </div>
            }
            {companyType === CompanyType.THIRD_PARTY_COMPANY &&
                <div className={styles.companySelectWrapper}>
                    <div className={styles.companySelectLabel}>Which third party service provider would you like to add to the supply chain?</div>
                    <Dropdown
                        testId='supply-chain-third-party-companies'
                        onChange={val => updateDropdownValue('thirdPartyCompanyId', val)}
                        value={thirdPartyCompanyValue}
                        options={availableThirdPartyCompanies}
                        isClearable
                        placeholder='Select...'
                        menuPortalTarget={document.body}
                    />
                </div>
            }
        </div>
    );
};
