import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { isNull } from 'lodash/fp';

import { Login } from './components/auth/login/Login';
import styles from './App.module.scss';
import { SecureRoute } from './components/shared/SecureRoute';
import { Home } from './components/home/Home';
import { Registration } from './components/auth/registration/Registration';
import { Documents } from './components/documents/my-documents/Documents';
import { DocumentAnalysis } from './components/documents/analysis/DocumentAnalysis';
import { Entity } from './components/admin/entity/Entity';
import { DropdownLists } from './components/admin/dropdown-lists/DropdownLists';
import { Users } from './components/admin/users/Users';
import { Documents as AdminDocuments } from './components/admin/documents/Documents';
import { adminRoles, systemAdminRole } from './components/constants/permittedRoles';
import { ResetForgottenPassword } from './components/auth/resetPassword/ResetForgottenPassword';
import { Datasets } from './components/admin/dataset-builder/Datasets';
import { Opinions } from './components/opinions/my-opinions/Opinions';
import { LogoutWarningModal } from './components/shared/modal/LogoutWarningModal';
import { OpinionAnalysis } from './components/opinions/analysis/OpinionAnalysis';
import { DocumentReports } from './components/documents/reporting/DocumentReports';
import { Attestations } from './components/attestations/Attestations';
import { Attestations as AdminAttestations } from './components/admin/attestations/Attestations';
import { AdminAnalytics } from './components/admin/analytics/AdminAnalytics';
import { AttestationForms } from './components/admin/attestations/form/AttestationForms';
import { AttestationManager } from './components/admin/attestations/manager/AttestationManager';
import { Clients } from './components/admin/clients/Clients';
import { SearchDocumentsViewSelector } from './components/documents/search/SearchDocumentsViewSelector';
import { DocumentAnalytics } from './components/documents/analytics/Analytics';
import { Workflow } from './components/admin/workflow/Workflow';
import { WorkflowCalendar } from './components/admin/workflow/calendar/WorkflowCalendar';
import { WorkflowList } from './components/admin/workflow/list/WorkflowList';
import { MyDatasets } from './components/admin/my-datasets/MyDatasets';
import { Playbook as AdminPlaybook } from './components/admin/playbook/Playbook';
import { Builder as PlaybookBuilder } from './components/admin/playbook/builder/Builder';
import { MyPlaybooks } from './components/admin/playbook/my-playbooks/MyPlaybooks';
import { SendNotification } from './components/admin/notification/SendNotification';
import { UserPlaybooks } from './components/playbook/UserPlaybooks';
import { PlaybookViewer } from './components/admin/playbook/PlaybookViewer';
import { UserPlaybookViewer } from './components/playbook/UserPlaybookViewer';
import { BuildSelect } from './components/admin/playbook/builder/BuildSelect';
import { Templates } from './components/admin/playbook/templates/Templates';
import { PlaybookSuggestedChangesViewer } from './components/admin/playbook/PlaybookSuggestedChangesViewer';
import { ClientFeaturePermission, FeaturePermission } from './components/admin/users/store';
import { ClauseLibrary } from './components/clause-library/ClauseLibrary';
import { RiskTolerance } from './components/admin/risk-tolerance/RiskTolerance';
import { RiskToleranceDataset } from './components/admin/risk-tolerance/RiskToleranceDataset';
import { OpinionReports } from './components/opinions/reporting/OpinionReports';
import { OpinionAnalytics } from './components/opinions/analytics/Analytics';
import { DoraHome } from './components/dora/DoraHome';
import { ThirdPartyCompanies } from './components/dora/third-party-companies/ThirdPartyCompanies';
import { MyCompanies } from './components/dora/my-companies/MyCompanies';
import { DoraFunctions } from './components/dora/functions/DoraFunctions';
import { Guides } from './components/guides/Guides';
import { NettingEngine } from './components/opinions/netting-engine/NettingEngine';
import { SupplyChain } from './components/dora/supply-chain/SupplyChain';
import { SupplyChainConfigure } from './components/dora/supply-chain/SupplyChainConfigure';
import { DoraAnalytics } from './components/dora/analytics/Analytics';
import { DoraRegisterOfInformation } from './components/dora/reporting/DoraRegisterOfInformation';
import { AdminOpinions } from './components/admin/opinions/AdminOpinions';
import { getIsLoggedIn, getUser } from './components/auth/login/store';
import { useAppSelector } from './hooks/react-redux';
import { getRightMenuOpen } from './components/navigation/store';
import { DocumentGroups } from './components/admin/documents/groups/DocumentGroups';
import { SubCounterpartyTypes } from './components/admin/sub-counterparty-types/SubCounterpartyTypes';
import { NettingEngineDocuments } from './components/opinions/netting-engine/documents/NettingEngineDocuments';
import { NettingEngineDocument } from './components/opinions/netting-engine/documents/NettingEngineDocument';
import { AIManager } from './components/admin/ai/AIManager';

const App: React.FC = () => {
    const { update, shutdown, boot } = useIntercom();
    const isLoggedIn = useAppSelector(getIsLoggedIn);
    const user = useAppSelector(getUser);
    const rightMenuOpen = useAppSelector(getRightMenuOpen);
    const hideIntercom = useMemo(() => (user && user.userDefaults && !user.userDefaults.showIntercom) || false, [user]);

    useEffect(() => {
        if (isLoggedIn && !isNull(user)) {
            const { email, forenames, surname, username } = user;
            const name = (forenames && surname) ? `${forenames} ${surname} (${username})` : username;
            boot({ hideDefaultLauncher: true });
            update({ name, email });
        } else {
            shutdown();
        }
    }, [isLoggedIn, update, user, shutdown, boot]);

    useEffect(() => {
        const intercomWrapper = document.getElementById('intercom-frame');
        if (!isNull(intercomWrapper)) {
            update({ hideDefaultLauncher: !rightMenuOpen || hideIntercom });
        }
    }, [rightMenuOpen, update, hideIntercom]);

    return (
        <div data-testid='app' className={styles.app}>
            <Switch>
                <Route path='/login' component={Login} />
                <Route path='/registration' component={Registration} />
                <Route path='/forgotten-password/:resetLink' render={(props) => <ResetForgottenPassword {...props} />} />
                <Route path='/new-user/:resetLink' render={(props) => <ResetForgottenPassword {...props} />} />
                <SecureRoute path='/home' component={Home} />
                <SecureRoute path='/admin/entities' component={Entity} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.ENTITY_MANAGEMENT]} />
                <SecureRoute path='/admin/ai/:agreementTypeId' component={AIManager} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/ai' component={AIManager} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/datasets/documents/preview/:datasetId' component={Datasets} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/datasets/documents/configure/:datasetId' component={Datasets} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/datasets/documents' component={Datasets} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/datasets/:datasetId' component={Datasets} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/datasets' component={Datasets} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/risk-tolerance/:datasetId' component={RiskToleranceDataset} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/risk-tolerance' component={RiskTolerance} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/users' component={Users} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/dropdown-lists' component={DropdownLists} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/documents' component={AdminDocuments} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/document-groups' component={DocumentGroups} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/admin/attestation/form/:attestationFormId' component={AttestationForms} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/attestation/form' component={AttestationForms} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/attestation/manager/completed/:attestationInstanceId' component={AttestationManager} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/attestation/manager/completed' component={AttestationManager} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/attestation/manager/:attestationInstanceId' component={AttestationManager} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/attestation/manager' component={AttestationManager} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/attestation' component={AdminAttestations} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/analytics' component={AdminAnalytics} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/clients' component={Clients} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/notification' component={SendNotification} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/workflow/calendar/:scheduledActionId' component={WorkflowCalendar} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/workflow/calendar' component={WorkflowCalendar} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/workflow/list/:scheduledActionId' component={WorkflowList} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/workflow/list' component={WorkflowList} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/workflow' component={Workflow} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/my-datasets/configure/:datasetId' component={MyDatasets} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/my-datasets/preview/:datasetId' component={MyDatasets} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/my-datasets/risk-tolerance/:datasetId' component={MyDatasets} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/my-datasets' component={MyDatasets} permittedRoles={adminRoles} />
                <SecureRoute path='/admin/opinions' component={AdminOpinions} permittedRoles={systemAdminRole} />
                <SecureRoute path='/admin/playbook/templates' component={Templates} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.PLAYBOOK_MANAGEMENT]} />
                <SecureRoute path='/admin/playbook/build-select' component={BuildSelect} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.PLAYBOOK_MANAGEMENT]} />
                <SecureRoute path='/admin/playbook/list/:playbookId/changes' component={PlaybookSuggestedChangesViewer} featurePermissions={[FeaturePermission.PLAYBOOK_MANAGEMENT]} />
                <SecureRoute path='/admin/playbook/list/:playbookId' component={PlaybookViewer} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.PLAYBOOK_MANAGEMENT]} />
                <SecureRoute path='/admin/playbook/list' component={MyPlaybooks} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.PLAYBOOK_MANAGEMENT]} />
                <SecureRoute path='/admin/playbook/build/:playbookId' component={PlaybookBuilder} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.PLAYBOOK_MANAGEMENT]} />
                <SecureRoute path='/admin/playbook/build' component={PlaybookBuilder} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.PLAYBOOK_MANAGEMENT]} />
                <SecureRoute path='/admin/playbook' component={AdminPlaybook} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.PLAYBOOK_MANAGEMENT]} />
                <SecureRoute path='/admin/sub-counterparties' component={SubCounterpartyTypes} permittedRoles={adminRoles} featurePermissions={[FeaturePermission.SUB_COUNTERPARTY_TYPE]} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/documents/my-documents' component={Documents} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/search' component={SearchDocumentsViewSelector} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/reporting/templates/:templateId' component={DocumentReports} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/reporting/templates' component={DocumentReports} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/reporting/create' component={DocumentReports} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/reporting' component={DocumentReports} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/analytics/:chart' component={DocumentAnalytics} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/analytics' component={DocumentAnalytics} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/analysis/:documentId/:datasetInstanceId' component={DocumentAnalysis} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/analysis/:documentId' component={DocumentAnalysis} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/documents/analysis' component={DocumentAnalysis} clientFeaturePermissions={[ClientFeaturePermission.DOCUMENT_MODULE]} />
                <SecureRoute path='/playbook/:playbookId' component={UserPlaybookViewer} />
                <SecureRoute path='/playbook' component={UserPlaybooks} />
                <SecureRoute path='/opinions/analysis/:opinionId/:datasetInstanceId' component={OpinionAnalysis} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/analysis/:opinionId' component={OpinionAnalysis} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/analysis' component={OpinionAnalysis} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/reporting/templates/:templateId' component={OpinionReports} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/reporting/templates' component={OpinionReports} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/reporting/create' component={OpinionReports} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/reporting' component={OpinionReports} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/analytics/:chart' component={OpinionAnalytics} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/analytics' component={OpinionAnalytics} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/my-opinions' component={Opinions} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/netting-engine/documents/:documentId' component={NettingEngineDocument} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/netting-engine/documents' component={NettingEngineDocuments} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/netting-engine/theory' component={NettingEngine} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/opinions/netting-engine' component={NettingEngine} clientFeaturePermissions={[ClientFeaturePermission.OPINION_MODULE]} />
                <SecureRoute path='/my-attestations/completed/:attestationInstanceId' component={Attestations} />
                <SecureRoute path='/my-attestations/completed' component={Attestations} />
                <SecureRoute path='/my-attestations/:attestationInstanceId' component={Attestations} />
                <SecureRoute path='/my-attestations' component={Attestations} />
                <SecureRoute path='/clause-library' component={ClauseLibrary} />
                <SecureRoute path='/dora/data-management/third-party-companies/available' component={ThirdPartyCompanies} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/data-management/third-party-companies/active' component={ThirdPartyCompanies} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/data-management/companies/out-of-scope' component={MyCompanies} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/data-management/companies/in-scope' component={MyCompanies} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/data-management/functions' component={DoraFunctions} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/data-management/supply-chain/:doraFunctionCompanyId' component={SupplyChainConfigure} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/data-management/supply-chain' component={SupplyChain} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/data-management' component={DoraHome} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/analytics/:chart' component={DoraAnalytics} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/analytics' component={DoraAnalytics} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/dora/reporting' component={DoraRegisterOfInformation} permittedRoles={systemAdminRole} clientFeaturePermissions={[ClientFeaturePermission.DORA_MODULE]} featurePermissions={[FeaturePermission.DORA_READ_ONLY, FeaturePermission.DORA_FULL_ACCESS]} isMultiPermissioned />
                <SecureRoute path='/guides' component={Guides} />
                <Redirect exact from='/opinions' to='/opinions/my-opinions' />
                <Redirect exact from='/documents' to='/documents/my-documents' />
                <Redirect exact from='/dora' to='/dora/data-management' />
                <Redirect exact from='/' to='/home' />
            </Switch>
            <LogoutWarningModal />
        </div>
    );
};

export default App;
