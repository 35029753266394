import React from 'react';
import classnames from 'classnames';

import { useAppDispatch } from '../../../hooks/react-redux';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import styles from './Fields.module.scss';

interface LongTextProps {
    id: string;
    index: number;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    value: string | undefined;
    disabled?: boolean;
    maxLength?: number;
    placeholder?: string;
    isTable?: boolean;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean;
    showAIModifiedUserCorrected?: boolean;
    isMLCorrection?: boolean;
    showClause?: boolean;
    includedInAnnex?: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
}

const getStyles = (isTable: boolean) => ({ height: isTable ? '52px' : '80px' });

export const LongText: React.FC<LongTextProps> = ({
    id,
    value,
    disabled = false,
    maxLength = 500,
    placeholder = 'Aa...',
    isTable = false,
    index,
    groupIndex,
    sectionId,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    isMLCorrection = false,
    showClause = false,
    includedInAnnex = false,
    datasetId,
    parentFieldId,
    modalInstance
}) => {
    const dispatch = useAppDispatch();

    const updateValue = (value: string) => {
        if (isMLCorrection) {
            dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
        }
    };

    const placeholderText = includedInAnnex ? 'See Agency Annex' : placeholder;

    return (
        <textarea
            data-testid={`definition-longtext-${id}`}
            className={classnames(styles.longTextField, {
                [styles.updatedInputField]: showFieldUpdated,
                [styles.newInputField]: showDatasetUpdated,
                [styles.aiInputField]: showAIModified,
                [styles.aiCorrectedInputField]: showAIModifiedUserCorrected,
                [styles.withClauseLabel]: showClause && isTable
            })}
            value={value}
            onChange={e => updateValue(e.target.value)}
            maxLength={maxLength}
            disabled={disabled || includedInAnnex}
            placeholder={placeholderText}
            style={getStyles(isTable)}
        />
    );
};
