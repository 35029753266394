export const productsTradedOptions = [
    'Basis Swap',
    'Bond Forward',
    'Bond Option',
    'Bullion Option',
    'Bullion Swap',
    'Bullion Trade',
    'Buy/Sell-Back Transaction',
    'Cap Transaction',
    'Collar Transaction',
    'Commodity Forward',
    'Commodity Index Transaction',
    'Commodity Option',
    'Commodity Swap',
    'Contingent Credit Default Swap',
    'Credit Default Swap Option',
    'Credit Default Swap',
    'Credit Derivative Transaction on Asset-Backed Securities',
    'Credit Spread Transaction',
    'Cross Currency Rate Swap',
    'Currency Option',
    'Currency Swap',
    'Economic Statistic Transaction',
    'Emissions Allowance Transaction',
    'Equity Forward',
    'Equity Index Option',
    'Equity Option',
    'Equity Swap',
    'Floor Transaction',
    'Foreign Exchange Transaction',
    'Forward Rate Transaction',
    'Freight Transaction',
    'Fund Option Transaction',
    'Fund Forward Transaction',
    'Fund Swap Transaction',
    'Interest Rate Option',
    'Interest Rate Swap',
    'Longevity/Mortality Transaction',
    'Non-deliverable Digital Asset Forward',
    'Non-deliverable Digital Asset Option',
    'Physical Commodity Transaction',
    'Property Index Derivative Transaction',
    'Renewable Energy Certificate Transaction',
    'Repurchase Transaction',
    'Securities Lending Transaction',
    'Swap Deliverable Contingent Credit Default Swap',
    'Swap Option',
    'Total Return Swap',
    'Variance Swap',
    'VCC Transaction',
    'Volatility Swap',
    'Weather Index Transaction'
];
