import React, { useState } from 'react';
import classnames from 'classnames';
import { Options } from 'react-select';
import { isNull, isUndefined } from 'lodash/fp';

import { getAgencySettingsModalOpen, getAvailableAgencyFields, toggleAgencySettingsMenuOpen, updateAgencyFieldSetting, updateAgencyLinkedFields } from '../store';
import { AgencyDatasetFieldType, AgencyLinkedSingleField, DatasetFieldSettings, DatasetFieldType } from '../../../datasets/store';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Icon as ArkIcon } from '../../../shared/icon/Icon';
import { Settings as SettingsIcon } from '../../../shared/icons';
import styles from './AgencyDatasetBuilder.module.scss';
import sharedStyles from '../SharedBuilder.module.scss';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { AgencyLinkDropdownOption, AgencyLinkFieldDropdown } from './AgencyLinkFieldDropdown';
import { AgencyDatasetDropdownList } from './AgencyDatasetDropdownList';
import { getAllDropdownLists } from '../../dropdown-lists/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { EntityType } from '../../entity/store';
import { AgencyEntityTypeSelector } from './AgencyEntityTypeSelector';
import { Toggle } from '../../../shared/toggle';
import { Position, PositionModal } from '../../../shared/modal/PositionModal';

interface AgencyFieldSettingsProps {
    settings: DatasetFieldSettings;
    index: number;
    testId: string;
    type: AgencyDatasetFieldType;
    linkedFields: AgencyLinkedSingleField[];
    disableInputs: boolean;
}

export const AgencyFieldSettings: React.FC<AgencyFieldSettingsProps> = ({ settings, index, testId, type, linkedFields, disableInputs }) => {
    const dispatch = useAppDispatch();
    const [position, setPosition] = useState<Position | null>(null);
    const isWizardField = type === DatasetFieldType.WIZARD;

    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        dispatch(toggleAgencySettingsMenuOpen(index));
    };

    const closeModal = () => {
        setPosition(null);
        dispatch(toggleAgencySettingsMenuOpen(null));
    };

    const updateShowRef = (value: boolean) => dispatch(updateAgencyFieldSetting('showRef', value, index));

    const settingsModalOpen = useAppSelector(getAgencySettingsModalOpen);
    const agencyFields = useAppSelector(getAvailableAgencyFields);
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const availableFields = isWizardField ? agencyFields : agencyFields.filter(field => field.type === type);

    const updateLinkedFields = (option: AgencyLinkDropdownOption | Options<AgencyLinkDropdownOption> | null) => {
        let value: AgencyLinkedSingleField[] = [];
        if (!isNull(option)) {
            if (isWizardField) {
                value = (option as Options<AgencyLinkDropdownOption>).map(({ value }) => value);
            } else {
                value = [(option as AgencyLinkDropdownOption).value];
            }
        }
        dispatch(updateAgencyLinkedFields(index, value));
    };

    const updateDropdown = (option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = (option as DropdownOption).value;
        }
        dispatch(updateAgencyFieldSetting('dropdownLinked', value, index));
    };

    const updateEntityType = (selected: EntityType) => dispatch(updateAgencyFieldSetting('entityType', selected, index));

    const showRefChecked = !isWizardField && !!linkedFields.length ? !!linkedFields[0].settings.showRef : !!settings.showRef;
    const dropdownLinked = !isWizardField && !!linkedFields.length ? linkedFields[0].settings.dropdownLinked : settings.dropdownLinked;
    const entityType = !isWizardField && !!linkedFields.length ? linkedFields[0].settings.entityType : settings.entityType;
    const showModal = settingsModalOpen === index;

    return (
        <>
            <button className={sharedStyles.settingsWrapper} onClick={e => openModal(e.clientX, e.clientY)} data-testid={`${testId}-button`}>
                <ArkIcon icon={SettingsIcon} fontSize={25} />
            </button>
            {showModal &&
                <PositionModal
                    isOpen
                    closeModal={closeModal}
                    position={position}
                    testId='agency-builder-settings'
                    width='300px'
                    height='fit-content'
                >
                    <div className={styles.settingsModalWrapper}>
                        <ModalHeader label='Settings' icon={SettingsIcon} />
                        <div className={styles.settingsContent} data-testid='settings-show-ref-wrapper'>
                            <div className={classnames(styles.settingsLabel, { [styles.settingsLabelDisabled]: disableInputs })} data-testid='settings-show-ref-label'>Show Ref</div>
                            <Toggle
                                checked={showRefChecked}
                                onChange={updateShowRef}
                                testId='settings-show-ref'
                                disabled={disableInputs}
                            />
                        </div>
                        <AgencyLinkFieldDropdown
                            value={linkedFields}
                            options={availableFields}
                            updateValue={updateLinkedFields}
                            isMulti={isWizardField}
                        />
                        {!isUndefined(dropdownLinked) &&
                            <AgencyDatasetDropdownList
                                updateValue={updateDropdown}
                                disabled={disableInputs}
                                options={dropdownLists}
                                value={dropdownLinked}
                            />
                        }
                        {!isUndefined(entityType) &&
                            <AgencyEntityTypeSelector
                                updateEntityType={updateEntityType}
                                selected={entityType}
                            />
                        }
                    </div>
                </PositionModal>
            }
        </>
    );
};
