import React from 'react';

import { Icon } from '../icon/Icon';
import { OverflowTooltip } from '../tooltip';
import styles from './Modal.module.scss';

interface OverflowModalHeaderProps {
    label: string;
    icon: React.FC;
    testId?: string;
    iconSize?: number;
    fontSize?: string;
    fontWeight?: number;
    marginBottom?: string;
}

export const OverflowModalHeader: React.FC<OverflowModalHeaderProps> = ({ label, testId, icon, iconSize = 40, fontSize = '22px', fontWeight = 800, marginBottom = '10px' }) => (
    <div className={styles.modalHeader} style={{ marginBottom, fontWeight, fontSize }}>
        <OverflowTooltip testId={`${testId}-header`} overlayText={label} />
        <Icon icon={icon} fontSize={iconSize} />
    </div>
);
