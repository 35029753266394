import { User } from '../components/auth/login/store';
import { RegistrationDetails } from '../components/auth/registration/store';
import { ArkModule, HomeScreenConfig } from '../components/home/store';
import { api } from './services';

export type LoginRequest = { username: string; password: string; socketId: string | null; };
export type ResetPasswordRequest = { currentPassword: string; newPassword: string; };
export type ResetForgottenPasswordRequest = { token: string; newPassword: string; };

export const extendToken = async () => await api('auth/extend', {}, 'get');
export const checkIdleToken = async () => await api('auth/idleCheck', {}, 'get');
export const fetchProfilePicture = async (reqBody: { userId: number; socketId: string; }) => await api('auth/picture', { ...reqBody }, 'post');

export const login = async (reqBody: LoginRequest): Promise<User> => await api('auth/login', { ...reqBody }, 'post');
export const logout = async () => await api('auth/logout', {}, 'post');
export const switchAccount = async (reqBody: LoginRequest) => await api('auth/switchAccount', { ...reqBody }, 'post');

export const resetPassword = async (reqBody: ResetPasswordRequest) => await api('auth/resetPassword', { ...reqBody }, 'post');
export const resetForgottenPassword = async (reqBody: ResetForgottenPasswordRequest) => await api('auth/resetForgottenPassword', { ...reqBody }, 'post');
export const checkResetLinkIsValid = async (reqBody: { token: string; socketId: string | null; }) => await api('auth/resetToken/checkValid', { ...reqBody }, 'post');

export const submitRegistrationRequest = async (reqBody: RegistrationDetails) => await api('auth/register', { ...reqBody }, 'post');

export const enquireModule = async (reqBody: { module: ArkModule; }) => await api('auth/module/enquire', { ...reqBody }, 'post');

export const upsertHomeScreenConfig = async (reqBody: { config: HomeScreenConfig; }) => await api('auth/profile/home-screen-config/upsert', { ...reqBody }, 'post');
