import React from 'react';

import { Client } from '../../admin/clients/store';
import { Scrollable } from '../scrollable/Scrollable';
import { Toggle } from '../toggle';
import styles from './Clients.module.scss';

const { lightGrey, white } = styles;

interface ClientSelectProps {
    allClientIdsSelected: boolean;
    allClients: Client[];
    selectedClientIds: number[];
    updateAllClientIds: (value: boolean) => void;
    updateSelectedClientIds: (value: boolean, clientId: number) => void;
    title: string;
    maxHeight?: string;
}

export const ClientSelect: React.FC<ClientSelectProps> = ({ allClientIdsSelected, allClients, updateAllClientIds, updateSelectedClientIds, selectedClientIds, title, maxHeight = '200px' }) => (
    <div className={styles.clientOptionsWrapper}>
        <div className={styles.clientOptionsTitle}>{title}</div>
        <div className={styles.clientOptionsTable} style={{ maxHeight }}>
            <div className={styles.allOptionsRow}>
                <div className={styles.clientName}>All Clients</div>
                <Toggle
                    checked={allClientIdsSelected}
                    onChange={updateAllClientIds}
                />
            </div>
            <Scrollable maxHeight={`calc(${maxHeight} - 28px)`}>
                {allClients.map(({ clientId, clientName }, index) => (
                    <div className={styles.clientOptionRow} key={index} style={{ backgroundColor: index % 2 ? lightGrey : white, borderRadius: index === allClients.length - 1 ? '0 0 5px 5px' : undefined }}>
                        <div className={styles.clientName}>{clientName}</div>
                        <Toggle
                            checked={selectedClientIds.includes(clientId!)}
                            onChange={checked => updateSelectedClientIds(checked, clientId!)}
                        />
                    </div>
                ))}
            </Scrollable>
        </div>
    </div>
);
