import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { CompanyEntity, CompanyEntityContent, getCompanyEntityGroups, getMyEntityGroups, setCompanyEntityGroupIds, updateEntityContent, updateEntityValue } from '../store';
import { Text } from '../../../shared/text/Text';
import styles from './EntityType.module.scss';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { worldCountriesList } from '../../../constants/worldCountriesList';
import { Toggle } from '../../../shared/toggle';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { FeaturePermission } from '../../users/store';
import { DropdownSummary } from '../../../shared/dropdown/DropdownSummary';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { getAllDropdownLists } from '../../dropdown-lists/store';
import { InformationTooltip } from '../../../shared/tooltip';

interface CompanyEntityGeneralSectionProps {
    entity: CompanyEntity;
}

export const CompanyEntityGeneralSection: React.FC<CompanyEntityGeneralSectionProps> = ({ entity }) => {
    const dispatch = useAppDispatch();
    const handleUpdateValue = (key: string, value: string | Date | null) => dispatch(updateEntityValue(key, value));
    const handleUpdateContent = (key: keyof CompanyEntityContent, value: string | string[] | Date | null) => dispatch(updateEntityContent(key, value));
    const groupEntities = useAppSelector(getMyEntityGroups);
    const companyEntityGroups = useAppSelector(getCompanyEntityGroups);
    const dropdownLists = useAppSelector(getAllDropdownLists);
    const getDropdownListOptions = useCallback((listName: string) => {
        const dropdownList = dropdownLists.find(({ name }) => name === listName);
        return dropdownList ? dropdownList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const opinionJurisdictionOptions = getDropdownListOptions('OpinionJurisdiction');

    const { name, myCompany, doraInScope, doraMaintainerROI, canBeAgent, onWatchlist, content: { lei, euid, jurisdiction, opinionJurisdiction } } = entity;

    const countryListOptions = useMemo(() => worldCountriesList.map(({ name, id }) => ({ label: name, value: id })), []);
    const jurisdictionValue = countryListOptions.find(({ value }) => value === jurisdiction) || null;
    const opinionJurisdictionValue = opinionJurisdictionOptions.find(({ value }) => value === opinionJurisdiction) || null;

    const updateDropdownOption = (option: DropdownOption | Options<DropdownOption> | null, valueToUpdate: keyof CompanyEntityContent) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        dispatch(handleUpdateContent(valueToUpdate, selected));
    };

    const updateOpinionJurisdiction = (option: DropdownOption | Options<DropdownOption> | null) => {
        updateDropdownOption(option, 'opinionJurisdiction');
        if (isNull(option) || (option as DropdownOption).value !== opinionJurisdiction) {
            handleUpdateContent('opinionSubCounterpartyType', null);
        }
    };

    const updateJurisdiction = (option: DropdownOption | Options<DropdownOption> | null) => {
        updateDropdownOption(option, 'jurisdiction');
        if (isNull(option)) {
            updateOpinionJurisdiction(null);
            return;
        }
        if ((option as DropdownOption).label) {
            const optionLabel = (option as DropdownOption).label;
            // Attempt to match the jurisdiction of incoporation to the opinion jurisdiction. If this is an exact match, then populate. If not, update to null
            const correspondingCountryName = opinionJurisdictionOptions.find(({ value }) => value === optionLabel) || null;
            updateOpinionJurisdiction(correspondingCountryName);
        }
    };

    const toggleMyCompany = (value: boolean) => {
        dispatch(updateEntityValue('myCompany', !value ? 0 : 1));
        if (!value) {
            dispatch(setCompanyEntityGroupIds([]));
        }
    };
    const toggleOnWatchlist = (value: boolean) => dispatch(updateEntityValue('onWatchlist', !value ? 0 : 1));
    const toggleCanBeAgent = (value: boolean) => dispatch(updateEntityValue('canBeAgent', !value ? 0 : 1));

    const entityGroupOptions = useMemo(() => groupEntities.length ? groupEntities.map(({ name, entityId }) => ({ label: name, value: entityId!.toString() })) : [], [groupEntities]);

    const updateEntityGroup = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected: string[] = [];
        if (!isNull(option) && (option as Options<DropdownOption>).length > 0) {
            selected = (option as Options<DropdownOption>).map(({ value }) => value);
        }
        dispatch(setCompanyEntityGroupIds(selected));
    };

    const selectedEntityGroups = useMemo(() => {
        if (groupEntities.length > 0) {
            return groupEntities.filter(({ entityId }) => companyEntityGroups.includes(entityId!.toString())).map(({ name, entityId }) => ({ label: name, value: entityId!.toString() }));
        }
        return null;
    }, [groupEntities, companyEntityGroups]);

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const doraMaintainerDisabledTooltip = useMemo(() => doraMaintainerROI ? [`${name} is the designated entity to maintain the Register of Information for Dora.`] : null, [doraMaintainerROI, name]);

    const myCompanyDisabled = useMemo(() => doraMaintainerROI === 1 || (doraInScope === 1 && !hasDoraFullAccessPermission) || !!onWatchlist, [doraMaintainerROI, doraInScope, hasDoraFullAccessPermission, onWatchlist]);

    const doraMyCompanyDisabledTooltip = useMemo(() => {
        if (doraInScope === 1 && !hasDoraFullAccessPermission) {
            return ['This entity is in scope for Dora, only users with full access to the Dora Module can remove this company.'];
        }
        return doraMaintainerDisabledTooltip;
    }, [doraInScope, hasDoraFullAccessPermission, doraMaintainerDisabledTooltip]);

    return (
        <div className={styles.generalWrapper}>
            <Scrollable>
                <Text
                    label='Company Name'
                    testId='entity-legal-name'
                    onChange={e => handleUpdateValue('name', e.target.value)}
                    value={name}
                    maxLength={128}
                    placeholder='Company Name...'
                />
                <Text
                    label='Legal Entity Identifier (LEI)'
                    testId='entity-lei'
                    onChange={e => handleUpdateContent('lei', e.target.value)}
                    value={lei || ''}
                    placeholder='LEI...'
                />
                <div className={styles.euidWrapper}>
                    <div className={styles.euidLabelWrapper}>
                        <div className={styles.euidLabel}>European Unified ID (EUID)</div>
                        <InformationTooltip content='Please note this is only applicable to companies registered in the European Union' />
                    </div>
                    <Text
                        testId='entity-euid'
                        onChange={e => handleUpdateContent('euid', e.target.value)}
                        value={euid || ''}
                        placeholder='EUID...'
                    />
                </div>
                <Dropdown
                    label='Jurisdiction of Incorporation'
                    testId='entity-jurisdiction'
                    onChange={updateJurisdiction}
                    value={jurisdictionValue}
                    options={countryListOptions}
                    marginBottom='10px'
                />
                <Dropdown
                    label='Jurisdiction of the relevant opinion'
                    testId='entity-jurisdiction'
                    onChange={updateOpinionJurisdiction}
                    value={opinionJurisdictionValue}
                    options={opinionJurisdictionOptions}
                    marginBottom='10px'
                />
                <div className={styles.togglesWrapper}>
                    <div className={styles.toggleWrapper}>
                        <div className={styles.toggleLabel}>Is this your company?</div>
                        <Toggle
                            checked={!!myCompany}
                            onChange={toggleMyCompany}
                            testId='entity-my-company'
                            disabled={myCompanyDisabled}
                            disabledTooltip={doraMyCompanyDisabledTooltip}
                        />
                    </div>
                    {myCompany === 1 &&
                        <DropdownSummary
                            label='Entity Group(s)'
                            testId='entity-groups'
                            onChange={updateEntityGroup}
                            value={selectedEntityGroups}
                            options={entityGroupOptions}
                            marginBottom='10px'
                            isMulti
                        />
                    }
                    <div className={styles.toggleWrapper}>
                        <div className={styles.toggleLabel}>Should this company be on your watchlist?</div>
                        <Toggle
                            checked={!!onWatchlist}
                            onChange={toggleOnWatchlist}
                            disabled={!!myCompany}
                            testId='entity-on-watchlist'
                        />
                    </div>
                    <div className={styles.toggleWrapper}>
                        <div className={styles.toggleLabel}>Can this company be an agent?</div>
                        <Toggle
                            checked={!!canBeAgent}
                            onChange={toggleCanBeAgent}
                            testId='entity-agency'
                        />
                    </div>
                </div>
            </Scrollable>
        </div>
    );
};
