import { max, set, flow, min } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { select, geoMercator, scaleThreshold, geoPath, selectAll, GeoProjection, zoom, GeoPermissibleObjects, zoomIdentity, easeLinear } from 'd3';

import { JurisdictionList } from './JurisdictionChartWrapper';
import { Country, CountryEntity } from '../store';
import styles from '../Analytics.module.scss';
import { unitedKingdom } from '../../../constants/worldCountriesList';
import { EyePath } from '../../../shared/icons';

const {
    lightestGrey,
    fontFamily,
    primary,
    primaryBorder,
    heatMap0,
    heatMap1,
    heatMap2,
    heatMap3,
    heatMap4,
    heatMap5,
    heatMap6,
    heatMap7,
    heatMap8,
    heatMap9,
    heatMap10,
    amber,
    watchlistBorder,
    watchlistBorderSelected
} = styles;

interface JurisdictionChartProps {
    id?: string;
    height: number;
    width: number;
    worldGeometry: Country[];
    graphData: JurisdictionList[];
    selectedCountry?: Country;
    onDblClick: (countryName: string, entities: CountryEntity[]) => void;
    resetZoom: boolean;
    zoomSteps: number;
    zoomUpdated: boolean;
    mapZoomExtent: (value: number) => void;
    scaleExtent: [number, number];
}

interface MapData extends Country {
    entities: CountryEntity[];
}

const colourRange = [heatMap0, heatMap1, heatMap2, heatMap3, heatMap4, heatMap5, heatMap6, heatMap7, heatMap8, heatMap9, heatMap10];

export const JurisdictionChart: React.FC<JurisdictionChartProps> = ({
    id = 'jurisdiction',
    height,
    width,
    worldGeometry,
    graphData,
    selectedCountry,
    onDblClick,
    resetZoom,
    zoomSteps,
    zoomUpdated,
    mapZoomExtent,
    scaleExtent
}) => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    const tooltipContent = useCallback((tooltipValue: string) => `<tspan dy=12 x=10>${tooltipValue}</tspan>`, []);

    const getTooltip = useCallback((name: string, value: number) => `${name}: ${value}`, []);
    const getTooltipWidth = useCallback((tooltipValue: string) => max([(`${tooltipValue}`.length * 8) + 10, 100])!, []);

    const getValue = useCallback((id: string) => graphData.find(({ geoId }) => geoId === id)?.documents || 0, [graphData]);
    const getEntityValue = useCallback((id: string) => graphData.find(({ geoId }) => geoId === id)?.entities || [], [graphData]);

    const entitiesOnWatchList = useCallback((entities: CountryEntity[] | undefined) => entities && entities.some(({ onWatchlist }) => onWatchlist) || false, []);

    const data: MapData[] = useMemo(() => worldGeometry.map(country => flow(
        set('value', getValue(country.id)),
        set('entities', getEntityValue(country.id))
    )(country)), [getValue, worldGeometry, getEntityValue]);

    const colorDomain = useMemo(() => {
        const minimum = min(data.map(({ value }) => value))!;
        const maximum = max(data.map(({ value }) => value))!;
        const integer = Math.floor((maximum - minimum) / 9) || 1;
        return Array(10).fill(0).map((_, index) => minimum + (integer * index));
    }, [data]);

    useEffect(() => {
        const svg = select<SVGSVGElement, unknown>(svgRef.current!);

        const projection: GeoProjection = geoMercator()
            .scale(500)
            .center([0, 60])
            .translate([width / 1.5, height / 50]);

        const colorScale = scaleThreshold()
            .domain(colorDomain)
            .range(colourRange as unknown as number[]);

        const countryLines = geoPath().projection(projection);

        const map = svg
            .selectAll('path')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'map')
            .attr('id', `${id}`)
            .attr('overflow', 'hidden');

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const handleZoom = ({ transform }: any) => {
            map.attr('transform', transform);
            mapZoomExtent(transform.k);
        };

        const mapZoom = zoom<SVGSVGElement, unknown>()
            .extent([[0, 0], [width, height]])
            .scaleExtent(scaleExtent)
            .on('zoom', handleZoom);

        svg.call(mapZoom);

        const mapZoomIn = () => {
            svg.transition().call(mapZoom.scaleBy, 1.2);
        };
        const mapZoomOut = () => {
            svg.transition().call(mapZoom.scaleBy, 0.8);
        };

        if ((resetZoom && !selectedCountry) || (zoomSteps === 0 && !selectedCountry)) {
            // Coordinates for the UK, this ensures we are zoomed in to the same place each time we reload
            // and also after we clear the dropdown or reset the zoom it resets to this position
            const [[x0, y0], [x1, y1]] = countryLines.bounds(unitedKingdom as GeoPermissibleObjects);
            const minScale = 0.3 / Math.max((x1 - x0) / width, (y1 - y0) / height);
            const scale = Math.min(5, minScale < scaleExtent[0] ? scaleExtent[0] : minScale);
            svg.transition().duration(750).call(
                mapZoom.transform,
                zoomIdentity
                    .translate(width / 2, height / 2)
                    .scale(scale)
                    .translate(-(x0 + x1) / 2, -(y0 + y1) / 2)
            );
        } else {
            zoomSteps > 0 ? mapZoomIn() : mapZoomOut();
        }

        map
            .append('path')
            .attr('d', (d: Country, e) => countryLines(d as GeoPermissibleObjects, e))
            .attr('fill', (d: Country) => colorScale(d.value!))
            .attr('stroke', (d: Country) => entitiesOnWatchList(d.entities) ? watchlistBorder : primaryBorder)
            .attr('id', (d: Country) => `jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
            .attr('class', 'primary')
            .transition().delay((_, i) => i * 950).duration(250).ease(easeLinear);

        map
            .on('mouseover', (_, d: Country) => {
                tooltip.attr('display', null);
                tooltip.attr('x', 20);
                if (entitiesOnWatchList(d.entities)) {
                    watchlistIcon.attr('display', 'null');
                }
                selectAll(`#jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                    .transition()
                    .duration(200)
                    .attr('opacity', 0.9)
                    .attr('stroke', entitiesOnWatchList(d.entities) ? watchlistBorderSelected : primary);
            })
            .on('mouseout', (_, d: Country) => {
                tooltip.attr('display', 'none');
                watchlistIcon.attr('display', 'none');
                selectAll(`#jurisdiction-${d.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                    .transition()
                    .duration(200)
                    .attr('opacity', 1)
                    .attr('stroke', entitiesOnWatchList(d.entities) ? watchlistBorder : primaryBorder);
            })
            .on('mousemove', (_, d: Country) => {
                const tooltipValue = getTooltip(d.properties.name, d.value!);
                tooltip.select('rect')
                    .attr('height', 25)
                    .attr('width', getTooltipWidth(tooltipValue));
                tooltip.select('text')
                    .html(tooltipContent(tooltipValue));
                watchlistIcon.select('rect')
                    .attr('x', getTooltipWidth(tooltipValue) - 10);
                watchlistIcon.select('svg')
                    .attr('x', getTooltipWidth(tooltipValue) - 10);
            })
            .on('click', (_, d) => {
                const [[x0, y0], [x1, y1]] = countryLines.bounds(d as GeoPermissibleObjects);
                const minScale = 0.6 / Math.max((x1 - x0) / width, (y1 - y0) / height);
                const scale = Math.min(5, minScale < scaleExtent[0] ? scaleExtent[0] : minScale);
                svg.transition().duration(750).call(
                    mapZoom.transform,
                    zoomIdentity
                        .translate(width / 2, height / 2)
                        .scale(scale)
                        .translate(-(x0 + x1) / 2, -(y0 + y1) / 2)
                );
            })
            .on('dblclick', (_, d) => {
                if (d.value) {
                    onDblClick(d.properties.name, d.entities);
                }
            });

        if (selectedCountry && !zoomUpdated) {
            const [[x0, y0], [x1, y1]] = countryLines.bounds(selectedCountry as GeoPermissibleObjects);
            const minScale = 0.6 / Math.max((x1 - x0) / width, (y1 - y0) / height);
            const scale = Math.min(5, minScale < scaleExtent[0] ? scaleExtent[0] : minScale);
            svg.transition().duration(750).call(
                mapZoom.transform,
                zoomIdentity
                    .translate(width / 2, height / 2)
                    .scale(scale)
                    .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
            );
            // Transition in and out of stroke opacity to help highlight the selected country when zoomed
            map.
                selectAll(`#jurisdiction-${selectedCountry.properties.name.replace(/[ ,.]/g, '-').toLowerCase()}`)
                .transition()
                .duration(3000)
                .attr('opacity', 0.8)
                .attr('stroke', primary)
                .attr('stroke-opacity', 1)
                .transition()
                .duration(300)
                .attr('opacity', 1)
                .attr('stroke', primary)
                .attr('stroke-opacity', 0.2);
        }

        const tooltip = svg
            .append('g')
            .attr('class', 'tooltip')
            .attr('data-testid', 'analytics-world-chart-tooltip')
            .attr('display', 'none')
            .attr('transform', `translate(0, ${height - 25})`);

        tooltip
            .append('rect')
            .attr('rx', 5)
            .attr('fill', lightestGrey);

        tooltip
            .append('text')
            .attr('x', 10)
            .attr('y', 4)
            .attr('fill', primary)
            .attr('text-anchor', 'start')
            .attr('font-size', '14px')
            .attr('font-family', fontFamily)
            .attr('font-weight', 600);

        const watchlistIcon = svg
            .append('g')
            .attr('class', 'watchlistIcon')
            .attr('data-testid', 'analytics-world-chart-watchlist-icon')
            .attr('display', 'none')
            .attr('transform', `translate(0, ${height - 25})`);

        watchlistIcon
            .append('rect')
            .attr('rx', 5)
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', 25)
            .attr('width', 35)
            .attr('fill', lightestGrey);

        watchlistIcon
            .append('svg')
            .attr('x', 0)
            .attr('y', -4)
            .attr('viewBox', '0 0 56 56')
            .attr('width', 30)
            .attr('height', 30)
            .append('path')
            .attr('d', EyePath)
            .attr('fill', amber)
            .attr('stroke-width', 1)
            .attr('stroke', amber)
            .attr('transform', 'scale(1)');

        return () => {
            // Anything added to the svg on mount via .append must be removed on unmount to stop new elements being added each render
            svg.selectAll('.watchlistIcon').remove();
            svg.selectAll('.tooltip').remove();
            svg.selectAll('.map').remove();
        };

    }, [height, width, tooltipContent, getTooltip, getTooltipWidth, entitiesOnWatchList, data, id, selectedCountry, onDblClick, colorDomain, resetZoom, zoomSteps, zoomUpdated, mapZoomExtent, scaleExtent]);

    return (
        <div className={styles.jurisdictionChart}>
            <div className={styles.svgWrapper} data-testid={`analytics-${id}-jurisdiction-chart-wrapper`} style={{ height, width }}>
                <svg ref={svgRef} style={{ height, width, overflow: 'hidden', borderRadius: '5px', cursor: 'pointer' }} />
            </div>
        </div>
    );
};
