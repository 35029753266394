import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../hooks/useWindowResize';
import styles from './AdminOpinions.module.scss';
import { AdminOpinionsPie } from './AdminOpinionsPie';
import { AdminOpinionsTable } from './AdminOpinionsTable';
import { fetchAdminOpinionAnalyticsStarted, getSelectedOpinionHealthCategory, OpinionHealthIndicator } from './store';

export const AdminOpinions: React.FC = () => {
    const [screenWidth, screenHeight] = useWindowResize();
    const [tileDimensions, setTileDimensions] = useState<undefined | { height: number; width: number; }>(undefined);
    const selectedCategory = useAppSelector(getSelectedOpinionHealthCategory);

    useFetchStarted([fetchAdminOpinionAnalyticsStarted()]);

    const { header, subTitle }: { header: string, subTitle: string } = useMemo(() => {
        switch (selectedCategory) {
            case OpinionHealthIndicator.HEALTHY:
                return {
                    header: 'Healthy Opinions',
                    subTitle: 'These opinions have been updated within the last 9 months'
                };
            case OpinionHealthIndicator.BORDERLINE:
                return {
                    header: 'Borderline Opinions',
                    subTitle: 'These opinions have been updated between the last 9 to 12 months'
                };
            case OpinionHealthIndicator.STALE:
                return {
                    header: 'Stale Opinions',
                    subTitle: 'These opinions have been not been updated in over 12 months'
                };
            default:
                return {
                    header: '',
                    subTitle: ''
                };
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (screenWidth && screenHeight) {
            const width = (screenWidth - 80) / 2;
            const height = (screenHeight - 190);
            setTileDimensions({ height, width });
        }
    }, [screenWidth, screenHeight]);

    return (
        <div className={styles.adminOpinionWrapper}>
            <div className={styles.adminOpinionSectionWrapper}>
                <AdminOpinionsPie
                    dimensions={tileDimensions}
                    id='admin-opinions'
                    testId='admin-opinions'
                />
            </div>
            <div className={classnames(styles.adminOpinionSectionWrapper, [styles.additionalTableStyling])}>
                <div className={styles.sectionHeader}>
                    <div className={styles.sectionTitle}>{header}</div>
                    <div>{subTitle}</div>
                </div>
                <AdminOpinionsTable tableWidth={screenWidth / 2 - 80} tableHeight={screenHeight - 220} />
            </div>
        </div>
    );
};
