import React, { useCallback, useEffect, useMemo } from 'react';
import { getOr, isNull, noop, uniq } from 'lodash/fp';
import classnames from 'classnames';

import styles from './SignOff.module.scss';
import { getSectionOrFieldId, setNoteToUpdate, SignOffSmileyAndNote, getSavedFieldSignOffNotes } from '../store';
import { Spinner } from '../../../shared/spinner/Spinner';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { formatDate, FULL_DATE_FORMAT } from '../../../../utils/luxon';
import { WYSIWYG } from '../../../shared/wysiwyg/WYSIWYG';
import { SmileyIndicator } from '../../../shared/analytics/SingleIndicator';
import { Pencil, SmileyNegative, SmileyNeutral, SmileyPositive } from '../../../shared/icons';
import { Icon } from '../../../shared/icon/Icon';
import { IconButton } from '../../../shared/button/IconButton';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';

const { red, amethyst, green, amber, gold, primary } = styles;

interface SignOffConversationNotesColour {
    [index: number]: string
}
const conversationColours: SignOffConversationNotesColour = {
    1: red,
    2: amethyst,
    3: green,
    4: amber,
    5: gold
};

export interface SignOffConversationNotesProps {
    conversation: SignOffSmileyAndNote[];
    showSpinner: boolean;
    currentUserId: number;
    hasAddNotesPermission: boolean;
    opinionInstanceIsSignedOff: boolean;
    canAddNotes: boolean;
    testId?: string;
    setShowEditNoteSpinner: (value: boolean) => void;
    hasFieldLabel: boolean;
}

const MODAL_HEADER = 56;
const FIELD_LABEL = 27;
const NEW_NOTE_WRAPPER = 220;
const BORDER = 2;

export const SignOffConversationNotes: React.FC<SignOffConversationNotesProps> = ({
    conversation,
    showSpinner,
    currentUserId,
    hasAddNotesPermission,
    testId = 'opinions-sign-off',
    setShowEditNoteSpinner,
    hasFieldLabel
}) => {
    const dispatch = useAppDispatch();
    const [, screenHeight] = useWindowResize();
    const uniqueUserIds = useMemo(() => uniq(conversation.map(({ createdBy }) => createdBy).filter(createdBy => createdBy !== currentUserId)), [conversation, currentUserId]);

    const height = useMemo(() => {
        let offset = MODAL_HEADER + BORDER;
        if (hasAddNotesPermission) {
            offset += NEW_NOTE_WRAPPER;
        }
        if (hasFieldLabel) {
            offset += FIELD_LABEL;
        }
        return `${(screenHeight * 0.8) - offset}px`;
    }, [hasAddNotesPermission, hasFieldLabel, screenHeight]);

    const id = useAppSelector(getSectionOrFieldId);

    const getDisplayNameColour = useCallback((userId: number) => {
        const userIndex = uniqueUserIds.indexOf(userId);
        const usersColourNumber = (userIndex % 5) + 1;
        const colour = conversationColours[usersColourNumber];
        return colour;
    }, [uniqueUserIds]);

    const getSmileyIcon = useCallback((smileyValue: SmileyIndicator) => {
        switch (smileyValue) {
            case SmileyIndicator.NEGATIVE: {
                return ({ icon: SmileyNegative, props: { fill: red, color: primary, fontSize: 36 } });
            }
            case SmileyIndicator.NEUTRAL: {
                return ({ icon: SmileyNeutral, props: { fill: amber, color: primary, fontSize: 36 } });
            }
            case SmileyIndicator.POSITIVE: {
                return ({ icon: SmileyPositive, props: { fill: green, color: primary, fontSize: 36 } });
            }
        }
    }, []);

    useEffect(() => {
        const highestIndex = conversation.length - 1;
        const lastMessage = document.getElementById(`message-${highestIndex}`);
        if (lastMessage) {
            lastMessage.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation.length]);

    const sectionAndFieldIds: { sectionId: string; fieldId: string | undefined; } = useMemo(() => {
        const [sectionId, fieldId] = id.split('-');
        return { sectionId, fieldId };
    }, [id]);

    const { sectionId, fieldId } = sectionAndFieldIds;

    const handleSetNotesToUpdate = useCallback((index: number) => {
        setShowEditNoteSpinner(true);
        dispatch(setNoteToUpdate(sectionId, index, fieldId));
    }, [dispatch, fieldId, sectionId, setShowEditNoteSpinner]);

    const savedNotesForSection = useAppSelector(getSavedFieldSignOffNotes(sectionId, fieldId));

    return (
        <div style={{ height }} className={styles.conversationNotesWrapper} >
            <div className={styles.conversationScrollableWrapper} data-testid={`${testId}-conversation-wrapper`}>
                <Scrollable>
                    <div style={{ height: '100%' }}>
                        <div style={{ height: '20px' }} />
                        {showSpinner ? <Spinner /> : conversation.map(({ smileyValue, notes, createdBy, name, createdDate, ...note }, index) => {
                            const formattedDate = formatDate(createdDate!, FULL_DATE_FORMAT);
                            const isResponse = createdBy !== currentUserId;
                            const displayNameColour = getDisplayNameColour(createdBy!);
                            const smileyIconAndProps = getSmileyIcon(smileyValue);
                            const isInSavedNotes = !!savedNotesForSection?.notes[index];
                            const dropdownValue: string[] | null = getOr(null, 'dropdownValue', note);
                            return (
                                <div key={index} className={styles.message} id={`message-${index}`} data-testid={`${testId}-conversation-${index}-wrapper`}>
                                    <div className={classnames(styles.messageMetaData, { [styles.centralisedData]: !isResponse })}>
                                        {isResponse && <div className={styles.displayName} style={{ color: displayNameColour }} data-testid={`${testId}-conversation-${index}-name`}>{name}</div>}
                                        <div
                                            className={classnames(styles.messageDate, { [styles.responseDate]: isResponse })}
                                            data-testid={`${testId}-conversation-${index}-date`}
                                        >
                                            {formattedDate}
                                        </div>
                                    </div>
                                    <div className={classnames(styles.signOffNotesConversationWrapper, { [styles.userMessage]: !isResponse })}>

                                        <div
                                            className={classnames(styles.messageWrapper, { [styles.messageResponseWrapper]: isResponse })}
                                            data-testid={`${testId}-conversation-${index}-message`}
                                        >
                                            <div className={styles.messageIconWrapper}>
                                                <Icon icon={smileyIconAndProps!.icon} {...smileyIconAndProps!.props} fontSize={46} />
                                            </div>
                                            <div className={styles.messageContentWrapper}>
                                                {!isNull(dropdownValue) && <div className={styles.dropdownLabel}>{dropdownValue[0]}</div>}
                                                <WYSIWYG
                                                    content={notes}
                                                    updateContent={noop}
                                                    showBorder={false}
                                                    height='fit-content'
                                                    maxHeight='fit-content'
                                                    minHeight='fit-content'
                                                    showWrapperBorder={false}
                                                    toolbarHidden
                                                    disabled
                                                    isReadOnly
                                                />
                                            </div>
                                        </div>
                                        {!isResponse && !isInSavedNotes && <IconButton icon={Pencil} onClick={() => handleSetNotesToUpdate(index)} />}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Scrollable>
            </div>
        </div>
    );
};
