import React from 'react';

export const LongText: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,108.7,108.7,114.1,102.1,114.1z" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="29.1" y1="41.9" x2="99.1" y2="41.6" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="29.1" y1="53.7" x2="99.1" y2="53.3" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="29.1" y1="65.4" x2="99.1" y2="65" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="29.1" y1="77.1" x2="99.1" y2="76.8" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="29.1" y1="88.9" x2="99.1" y2="88.5" />
    </svg>
);
