import React from 'react';
import classnames from 'classnames';

import styles from './Fields.module.scss';

interface LongTextProps {
    id: string;
    value: string | undefined;
    updateValue: (value: string) => void;
    disabled?: boolean;
    maxLength?: number;
    placeholder?: string;
    showFieldUpdated?: boolean;
}

export const LongText: React.FC<LongTextProps> = ({
    id,
    value,
    updateValue,
    disabled = false,
    maxLength = 500,
    placeholder = 'Aa...',
    showFieldUpdated = false
}) => (
    <textarea
        data-testid={`opinion-longtext-${id}`}
        className={classnames(styles.longTextField, {
            [styles.updatedInputField]: showFieldUpdated
        })}
        value={value}
        onChange={e => updateValue(e.target.value)}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
    />
);
