import { flow, isNull, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';
import { DoraFunctionActionTypes, DoraConfiguration, DoraFunction, DoraFunctionState } from './types';

export const initialDoraFunction: DoraFunction = {
    name: '',
    description: '',
    systemFunction: 0
};

export const initialDoraConfiguration: DoraConfiguration = {
    doraConfigurationId: undefined,
    details: null
};

export const INITIAL_STATE: DoraFunctionState = {
    doraFunctions: [],
    totalDoraFunctions: 0,
    doraFunctionsFilters: {},
    doraFunctionsColumnSort: undefined,
    doraFunctionsPage: 1,
    doraFunctionsPageSize: DEFAULT_PAGE_SIZE,
    fetchingFunctions: false,
    selectedDoraFunction: null,
    currentDoraFunction: null,
    savingDoraFunction: false,
    functionConfigModalOpen: false,
    doraFunctionConfiguration: initialDoraConfiguration,
    currentDoraFunctionConfiguration: initialDoraConfiguration,
    timelineConfiguration: null,
    functionToDelete: null,
    functionToDeleteUsage: [],
    fetchingFunctionUsage: false,
    configurationTimeline: []
};

export const doraFunctionReducer: Reducer<DoraFunctionState> = (state = INITIAL_STATE, { payload, type }): DoraFunctionState => {
    switch (type) {
        case DoraFunctionActionTypes.FETCH_DORA_FUNCTIONS_STARTED:
            return set('fetchingFunctions', true, state);
        case DoraFunctionActionTypes.FETCH_DORA_FUNCTIONS_SUCCESSFUL: {
            const { doraFunctions, total, pageNumber } = payload;
            return flow(
                set('doraFunctions', doraFunctions),
                set('totalDoraFunctions', total),
                set('doraFunctionsPage', pageNumber),
                set('fetchingFunctions', false)
            )(state);
        }
        case DoraFunctionActionTypes.FETCH_DORA_FUNCTIONS_FAILED:
            return set('fetchingFunctions', false, state);
        case DoraFunctionActionTypes.SET_DORA_FUNCTIONS_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`doraFunctionsFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`doraFunctionsFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DoraFunctionActionTypes.SET_DORA_FUNCTIONS_TABLE_COLUMN_SORT:
            return set('doraFunctionsColumnSort', payload, state);
        case DoraFunctionActionTypes.SET_DORA_FUNCTIONS_PAGE_SIZE:
            return set('doraFunctionsPageSize', payload, state);
        case DoraFunctionActionTypes.CLEAR_DORA_FUNCTIONS_TABLE_FILTERS:
            return set('doraFunctionsFilters', {}, state);
        case DoraFunctionActionTypes.TOGGLE_DORA_FUNCTION_MODAL:
            return flow(
                set('selectedDoraFunction', payload),
                set('currentDoraFunction', payload),
            )(state);
        case DoraFunctionActionTypes.UPDATE_DORA_FUNCTION_VALUE: {
            const { key, value } = payload;
            return set(`selectedDoraFunction[${key}]`, value, state);
        }
        case DoraFunctionActionTypes.SAVE_DORA_FUNCTION_STARTED:
            return flow(
                set('fetchingFunctions', true),
                set('savingDoraFunction', true)
            )(state);
        case DoraFunctionActionTypes.SAVE_DORA_FUNCTION_SUCCESSFUL: {
            const { doraFunctions, total, pageNumber } = payload;
            return flow(
                set('doraFunctions', doraFunctions),
                set('totalDoraFunctions', total),
                set('doraFunctionsPage', pageNumber),
                set('fetchingFunctions', false),
                set('savingDoraFunction', false),
                set('selectedDoraFunction', null),
                set('currentDoraFunction', null)
            )(state);
        }
        case DoraFunctionActionTypes.SAVE_DORA_FUNCTION_FAILED:
            return flow(
                set('fetchingFunctions', false),
                set('savingDoraFunction', false)
            )(state);
        case DoraFunctionActionTypes.TOGGLE_FUNCTION_CONFIG_MODAL:
            return set('functionConfigModalOpen', payload, state);
        case DoraFunctionActionTypes.FETCH_FUNCTION_CONFIG_SUCCESSFUL: {
            const { doraConfig, timeline } = payload;
            const timelineConfig = timeline.length > 0 ? timeline[timeline.length - 1] : null;
            return flow(
                set('doraFunctionConfiguration', doraConfig),
                set('currentDoraFunctionConfiguration', doraConfig),
                set('configurationTimeline', timeline),
                set('timelineConfiguration', timelineConfig)
            )(state);
        }
        case DoraFunctionActionTypes.SAVE_FUNCTION_CONFIG_SUCCESSFUL: {
            const { doraConfig, timeline } = payload;
            const timelineConfig = timeline.length > 0 ? timeline[timeline.length - 1] : null;
            return flow(
                set('doraFunctionConfiguration', doraConfig),
                set('currentDoraFunctionConfiguration', doraConfig),
                set('configurationTimeline', timeline),
                set('timelineConfiguration', timelineConfig)
            )(state);
        }
        case DoraFunctionActionTypes.UPDATE_FUNCTION_CONFIG:
            return set('doraFunctionConfiguration.details', payload, state);
        case DoraFunctionActionTypes.SET_SELECTED_FUNCTION_CONFIGURATION:
            return set('timelineConfiguration', payload, state);
        case DoraFunctionActionTypes.FETCH_DORA_FUNCTION_USAGE_STARTED:
            return set('fetchingFunctionUsage', true, state);
        case DoraFunctionActionTypes.FETCH_DORA_FUNCTION_USAGE_SUCCESSFUL:
            return flow(
                set('functionToDeleteUsage', payload),
                set('fetchingFunctionUsage', false),
            )(state);
        case DoraFunctionActionTypes.FETCH_DORA_FUNCTION_USAGE_FAILED:
            return set('fetchingFunctionUsage', false, state);
        case DoraFunctionActionTypes.DELETE_DORA_FUNCTION_SUCCESSFUL: {
            const { doraFunctions, total, pageNumber } = payload;
            return flow(
                set('doraFunctions', doraFunctions),
                set('totalDoraFunctions', total),
                set('doraFunctionsPage', pageNumber)
            )(state);
        }
        case DoraFunctionActionTypes.TOGGLE_DELETE_FUNCTION_CONFIRMATION_MODAL: {
            if (isNull(payload)) {
                return flow(
                    set('functionToDelete', payload),
                    set('functionToDeleteUsage', [])
                )(state);
            }
            return set('functionToDelete', payload, state);
        }
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
