import classnames from 'classnames';
import React, { useCallback } from 'react';

import { Icon } from '../icon/Icon';
import { InformationTooltip, OverflowTooltip } from '../tooltip';
import styles from './Radio.module.scss';
import { RadioListOption } from './RadioList';

interface RadioRowProps {
    onChange: (id: string) => void;
    options: RadioListOption[];
    testId?: string;
    fontSize?: number;
}

export const RadioRow: React.FC<RadioRowProps> = ({ onChange, testId, options, fontSize = 14 }) => {
    const getWidth = useCallback((index: number) => `calc(${100 / options.length}% - ${index === options.length - 1 ? 0 : 10}px)`, [options]);
    return (
        <div className={styles.radioRowWrapper} data-testid={`${testId}-radio-row-wrapper`}>
            {options.map(({ id, label, description, isSelected, icon, disabled }, index) => (
                <div className={classnames(styles.rowOptionWrapper, { [styles.disabledOptionWrapper]: disabled })} key={id} style={{ width: getWidth(index) }}>
                    <div className={styles.radioInputWrapper}>
                        <input
                            type='radio'
                            checked={isSelected}
                            onClick={() => onChange(id)}
                            onChange={() => onChange(id)}
                            data-testid={`${testId}-${id}-radio-button`}
                            disabled={disabled}
                            className={styles.radioInput}
                        />
                    </div>
                    {icon && <div className={styles.radioButtonIcon}><Icon icon={icon} fontSize={fontSize * 1.5} /></div>}
                    <div className={styles.radioButtonLabel} style={{ fontSize: `${fontSize}px` }}>
                        <OverflowTooltip overlayText={label} testId={`${testId}-${id}`} />
                    </div>
                    {description && description.length > 0 &&
                        <div className={styles.radioButtonDescription}>
                            <InformationTooltip content={description} fontSize={fontSize - 2} />
                        </div>
                    }
                </div>
            ))}
        </div>
    );
};
