import { indexOf, max, noop } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { select, pie, arc, easeLinear, interpolate, pointer, PieArcDatum, min } from 'd3';

import styles from './SharedAnalytics.module.scss';

const { french, ocean, white, lightestGrey, fontFamily, aqua, primary, red, amber, amethyst } = styles;

export interface PieChartAnalyticsData {
    key: string;
    value: number;
    color?: string;
    onWatchlist?: number;
}

export interface PieColors {
    frenchOcean: string[];
    oceanFrench: string[];
    aquaFrench: string[];
    primaryFrench: string[];
    amethystFrench: string[];
    aquaPrimary: string[];
    redAmber: string[];
}

const colorOptions: PieColors = {
    frenchOcean: [french, ocean],
    oceanFrench: [ocean, french],
    aquaFrench: [aqua, french],
    primaryFrench: [primary, french],
    amethystFrench: [amethyst, french],
    aquaPrimary: [aqua, primary],
    redAmber: [red, amber]
};

interface PieChartProps {
    data: PieChartAnalyticsData[];
    transitionOnHover?: boolean;
    diameter: number;
    colors?: keyof PieColors;
    id?: string;
    testId?: string;
    includeLegend?: boolean;
    legendPosition?: 'right' | 'bottom';
    tooltipContent?: (pieChart: PieChartAnalyticsData) => string;
    tooltipWidth?: (key: string, value: number, onWatchlist: number | undefined) => number;
    fullOpacity?: boolean;
    rawData?: boolean;
    onDblClick?: (key: string) => void;
}

const defaultTooltipContent = ({ key, value, onWatchlist }: PieChartAnalyticsData) => `<tspan dy=12 x=10>${key}: ${value}${onWatchlist ? ' (Entity on Watchlist)' : ''}</tspan>`;
const defaultTooltipWidth = (key: string, value: number, onWatchlist: number | undefined) => max([(`${key}: ${value}${onWatchlist ? ' (Entity on Watchlist)' : ''}`.length * 7) + 12, 100])!;

export const PieChart: React.FC<PieChartProps> = ({
    data,
    transitionOnHover = true,
    diameter,
    id = 'pie-chart',
    colors = 'frenchOcean',
    testId,
    includeLegend = false,
    legendPosition = 'right',
    tooltipContent = defaultTooltipContent,
    tooltipWidth = defaultTooltipWidth,
    fullOpacity = false,
    rawData = false,
    onDblClick
}) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const XPosition = useMemo(() => includeLegend && legendPosition === 'right' ? 20 : 2, [includeLegend, legendPosition]);
    const sliceId = useCallback((key: string) => `${id}-slice-${key.replace(/[\s, .,(,)"']/g, '')}`, [id]);

    const tooltipPosition = useCallback((x: number, y: number) => `translate(${x + (diameter / XPosition) + 5}, ${y + (diameter / 2) - 5})`, [diameter, XPosition]);

    const getColor = useCallback((d: PieChartAnalyticsData) => {
        if (d.color) {
            return d.color;
        }
        return Math.floor(indexOf(d, data) / 5) % 2 ? colorOptions[colors][1] : colorOptions[colors][0];
    }, [data, colors]);

    const arcStrokeWidth = useMemo(() => {
        const minimum = min(data.map(({ value }) => value)) || 1;
        const total = data.reduce((acc, { value }) => acc + value, 0);
        const potentialStrokeWidth = Math.ceil((minimum / total) * diameter);
        return min([potentialStrokeWidth, 10])!;
    }, [data, diameter]);

    const dataToBeUsed = useMemo(() => rawData ? data : data.sort((a, b) => b.value - a.value), [data, rawData]);

    useEffect(() => {
        const svg = select(svgRef.current);
        const oRadius = diameter / 2 * 0.9;
        const iRadius = diameter / 2 * 0.4;
        const pieData = pie<PieChartAnalyticsData>().sort(null).value(({ value }) => value)(dataToBeUsed);
        const g = svg
            .append('g')
            .attr('class', `${id}-pie`)
            .attr('data-testid', `analytics-${testId}-pie-chart-pie`)
            .attr('transform', `translate(${diameter / XPosition}, ${diameter / 2})`);

        const arcPath = arc<PieArcDatum<PieChartAnalyticsData>>()
            .innerRadius(iRadius)
            .outerRadius(oRadius);

        const arcCrust = arc<PieArcDatum<PieChartAnalyticsData>>()
            .innerRadius(oRadius - (arcStrokeWidth * 2))
            .outerRadius(oRadius - (arcStrokeWidth / 2));

        const arcPhantom = arc<PieArcDatum<PieChartAnalyticsData>>()
            .innerRadius(iRadius)
            .outerRadius(oRadius);

        const percentageFill = (d: PieChartAnalyticsData) => {
            const index = indexOf(d, dataToBeUsed);
            const percentage = (10 - ((index % 5) * 2)) / 10;
            return fullOpacity ? 1 : percentage;
        };

        const arcs = g
            .selectAll('arc')
            .data(pieData)
            .enter()
            .append('g')
            .attr('class', `${id}-arc`)
            .attr('cursor', !onDblClick ? 'auto' : 'pointer');

        arcs
            .on('dblclick', (_, { data }) => {
                onDblClick ? onDblClick(data.key) : noop;
            });

        arcs
            .append('path')
            .attr('id', d => `${sliceId(d.data.key)}`)
            .attr('data-testid', d => `analytics-${testId}-${sliceId(d.data.key)}`)
            .attr('class', `${id}-slice`)
            .attr('fill', d => getColor(d.data))
            .attr('opacity', d => `${percentageFill(d.data)}`)
            .attr('stroke', lightestGrey)
            .attr('stroke-opacity', '0%')
            .attr('stroke-width', arcStrokeWidth)
            .transition().delay((d, i) => i * 250).duration(250).ease(easeLinear)
            .attrTween('d', d => arcTweenSlice(d));

        arcs
            .append('path')
            .attr('display', d => d.data.onWatchlist ? null : 'none')
            .attr('id', d => `${sliceId(d.data.key)}-outer`)
            .attr('data-testid', d => `analytics-${testId}-${sliceId(d.data.key)}-outer`)
            .attr('class', `${id}-slice-outer`)
            .attr('height', '10px')
            .attr('opacity', '100%')
            .attr('fill', amber)
            .transition().delay((d, i) => i * 250).duration(250).ease(easeLinear)
            .attrTween('d', d => arcTweenOuter(d));

        arcs
            .append('path')
            .attr('id', d => `${sliceId(d.data.key)}-phantom`)
            .attr('data-testid', d => `analytics-${testId}-${sliceId(d.data.key)}-phantom`)
            .attr('class', `${id}-slice-phantom`)
            .attr('fill', lightestGrey)
            .attr('fill-opacity', 0.1)
            .attr('stroke', lightestGrey)
            .attr('stroke-width', arcStrokeWidth)
            .transition().delay((d, i) => i * 250).duration(250).ease(easeLinear)
            .attrTween('d', d => arcTweenPhantom(d));

        if (includeLegend) {
            const legend = svg.append('g')
                .attr('class', `${id}-legend`);

            const legendSquareSize = 18;
            const legendSpacing = 4;

            if (legendPosition === 'right') {
                legend.attr('transform', `translate(${diameter}, ${diameter / 4})`);

                const legendData = legend.selectAll(`.${id}-legend-item`)
                    .data(dataToBeUsed)
                    .enter()
                    .append('g')
                    .attr('class', `${id}-legend-item`)
                    .attr('transform', (d, i) => `translate(-80, ${i * (legendSquareSize + legendSpacing)})`);

                legendData.append('rect')
                    .attr('width', legendSquareSize)
                    .attr('height', legendSquareSize)
                    .style('fill', d => getColor(d));

                legendData.append('text')
                    .attr('x', legendSquareSize + legendSpacing)
                    .attr('y', legendSquareSize - legendSpacing)
                    .text(d => d.key)
                    .attr('font-size', '12px')
                    .attr('font-family', fontFamily)
                    .attr('fill', french);

            } else if (legendPosition === 'bottom') {
                const baseSeparation = 10;

                const totalLegendWidth = dataToBeUsed.reduce(
                    (acc, d) => acc + (d.key.length * baseSeparation) + legendSquareSize,
                    0
                );
                const initialXOffset = (diameter - totalLegendWidth) / 2;

                legend.attr('transform', `translate(${initialXOffset}, ${diameter + 20})`);

                let currentXPosition = 0;

                const legendData = legend.selectAll(`.${id}-legend-item`)
                    .data(dataToBeUsed)
                    .enter()
                    .append('g')
                    .attr('class', `${id}-legend-item`)
                    .attr('transform', (d) => {
                        const xPosition = currentXPosition;
                        currentXPosition += (d.key.length * baseSeparation) + legendSquareSize;
                        return `translate(${xPosition}, 0)`;
                    });

                legendData.append('rect')
                    .attr('width', legendSquareSize)
                    .attr('height', legendSquareSize)
                    .style('fill', d => getColor(d));

                legendData.append('text')
                    .attr('x', legendSquareSize + legendSpacing)
                    .attr('y', legendSquareSize - legendSpacing)
                    .text(d => d.key)
                    .attr('font-size', '12px')
                    .attr('font-family', fontFamily)
                    .attr('fill', french);
            }
        }

        const arcTweenSlice = (d: PieArcDatum<PieChartAnalyticsData>) => {
            const i = interpolate(d.startAngle, d.endAngle);
            return (t: number) => {
                d.endAngle = i(t);
                return arcPath(d)!;
            };
        };

        const arcTweenOuter = (d: PieArcDatum<PieChartAnalyticsData>) => {
            const i = interpolate(d.startAngle, d.endAngle);
            return (t: number) => {
                d.endAngle = i(t);
                return arcCrust(d)!;
            };
        };

        const arcTweenPhantom = (d: PieArcDatum<PieChartAnalyticsData>) => {
            const i = interpolate(d.startAngle, d.endAngle);
            return (t: number) => {
                d.endAngle = i(t);
                return arcPhantom(d)!;
            };
        };

        arcs
            .on('mouseover', (e, d) => {
                tooltip.style('display', null);
                if (transitionOnHover) {
                    select(`#${sliceId(d.data.key)}`)
                        .transition()
                        .duration(300)
                        .attr('transform', `translate(${arcPath.centroid(d)[0] / 15}, ${arcPath.centroid(d)[1] / 15})`);
                    select(`#${sliceId(d.data.key)}-outer`)
                        .transition()
                        .duration(300)
                        .attr('transform', `translate(${arcPath.centroid(d)[0] / 15}, ${arcPath.centroid(d)[1] / 15})`);
                    select(`#${sliceId(d.data.key)}-phantom`)
                        .transition()
                        .duration(300)
                        .attr('transform', `translate(${arcPath.centroid(d)[0] / 15}, ${arcPath.centroid(d)[1] / 15})`);
                }
            })
            .on('mouseout', (e, d) => {
                tooltip.style('display', 'none');
                if (transitionOnHover) {
                    select(`#${sliceId(d.data.key)}`)
                        .transition()
                        .attr('opacity', `${percentageFill(d.data)}`)
                        .duration(300)
                        .attr('transform', 'translate(0, 0)');
                    select(`#${sliceId(d.data.key)}-outer`)
                        .transition()
                        .duration(300)
                        .attr('transform', 'translate(0, 0)');
                    select(`#${sliceId(d.data.key)}-phantom`)
                        .transition()
                        .duration(300)
                        .attr('transform', 'translate(0, 0)');
                }
            })
            .on('mousemove', (e, d) => {
                const [x, y] = pointer(e);
                const { key, value, onWatchlist } = d.data;
                tooltip.attr('transform', tooltipPosition(x, y));
                tooltip.select('rect')
                    .attr('height', 25)
                    .attr('width', tooltipWidth(key, value, onWatchlist));
                tooltip.select('text')
                    .html(tooltipContent(d.data));
            });

        const tooltip = svg
            .append('g')
            .attr('class', `${id}-pie-tooltip`)
            .attr('data-testid', `analytics-${testId}-pie-chart-tooltip`)
            .style('display', 'none');

        tooltip
            .append('rect')
            .attr('rx', 5)
            .attr('stroke', french)
            .attr('stroke-width', 1)
            .attr('fill', white);

        tooltip
            .append('text')
            .attr('x', 10)
            .attr('y', 4)
            .attr('fill', french)
            .style('text-anchor', 'start')
            .attr('font-size', '12px')
            .attr('font-family', fontFamily)
            .attr('font-weight', 600);

        return () => {
            svg.selectAll(`.${id}-pie`).remove();
            svg.selectAll(`.${id}-pie-tooltip`).remove();
            svg.selectAll(`.${id}-legend`).remove();
        };

    }, [dataToBeUsed, transitionOnHover, diameter, id, testId, sliceId, tooltipContent, tooltipPosition, tooltipWidth, getColor, arcStrokeWidth, includeLegend, fullOpacity, XPosition, onDblClick, legendPosition]);

    return (
        <div className={styles.pieChart}>
            <div className={styles.svgWrapper} data-testid={`analytics-${testId}-pie-chart-wrapper`} style={{ height: diameter, width: diameter }}>
                <svg ref={svgRef} style={{ height: '100%', width: '100%', overflow: 'visible' }} />
            </div>
        </div>
    );
};
