import React, { useMemo, useCallback } from 'react';
import { Options } from 'react-select';

import styles from './PlaybookBookcase.module.scss';
import { PlaybookCondition, PlaybookLogicalOperator, PlaybookSearchContentType, PlaybookSmartSearch as PlaybookSmartSearchType } from '../../admin/playbook/store';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';
import { Text } from '../text/Text';
import { DeleteButton } from '../button/DeleteButton';
import { getAllAgreementTypes } from '../../admin/documents/store';
import { useAppSelector } from '../../../hooks/react-redux';

interface PlaybookSmartSearchRowProps {
    index: number;
    isFirstRow: boolean;
    showDelete: boolean;
    removeRow: (index: number) => void;
    updateValue: (key: keyof PlaybookSmartSearchType, value: string, index: number) => void;
    row: PlaybookSmartSearchType;
    playbookAuthors: string[];
    testId?: string;
}

const renderFirstColumn = (isFirstRow: boolean, options: DropdownOption[], value: DropdownOption, onChange: (option: DropdownOption | Options<DropdownOption> | null, key: keyof PlaybookSmartSearchType) => void, index: number, testId: string) => {
    if (isFirstRow) {
        return (
            <div className={styles.logicalOperatorColumn} data-testid={`${testId}-${index}-initial-column-wrapper`}>
                <div className={styles.firstRowTitle} data-testid={`${testId}-${index}-initial-column-label`}>Playbook</div>
            </div>
        );
    }
    return (
        <div className={styles.logicalOperatorColumn} data-testid={`${testId}-${index}-initial-column-wrapper`}>
            <Dropdown
                onChange={val => onChange(val, 'logicalOperator')}
                value={value}
                options={options}
                isClearable={false}
                menuPortalTarget={document.body}
                testId={`${testId}-${index}-conditional`}
                hideIndicator
            />
        </div>
    );
};

export const PlaybookSmartSearchRow: React.FC<PlaybookSmartSearchRowProps> = ({ index, isFirstRow, showDelete, removeRow, updateValue, row, playbookAuthors, testId = 'playbooks-smart-search-row' }) => {
    const { logicalOperator, condition, searchTerm, contentType } = row;
    const agreementTypes = useAppSelector(getAllAgreementTypes);

    const logicalOperatorValue = { label: logicalOperator, value: logicalOperator };
    const conditionValue = { label: condition, value: condition };
    const contentTypeValue = { label: contentType, value: contentType };

    const mapDropdownOptions = useCallback((options: string[]) => options.map(value => ({ label: value, value })), []);

    const logicalOperatorOptions = mapDropdownOptions(Object.values(PlaybookLogicalOperator));

    const contentTypeOptions = mapDropdownOptions(Object.values(PlaybookSearchContentType));

    const conditionOptions = mapDropdownOptions(Object.values(PlaybookCondition));

    const authorOptions = mapDropdownOptions(playbookAuthors);

    const agreementTypeOptions = agreementTypes
        .map(({ agreementTypeId, name }) => ({ value: agreementTypeId.toString(), label: name }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const showSearchDropdown = useMemo(() => [PlaybookSearchContentType.AUTHOR, PlaybookSearchContentType.AGREEMENT_TYPE].includes(contentType), [contentType]);

    const updateDropdown = useCallback((option: DropdownOption | Options<DropdownOption> | null, key: keyof PlaybookSmartSearchType) => {
        const value = (option as DropdownOption).value;
        updateValue(key, value, index);
    }, [updateValue, index]);

    const updateSearchTerm = useCallback((value: string) => updateValue('searchTerm', value, index), [updateValue, index]);

    const searchDropdownOptions = useMemo(() => contentType === PlaybookSearchContentType.AUTHOR ? authorOptions : agreementTypeOptions, [contentType, authorOptions, agreementTypeOptions]);

    const smartSearchTerm = useMemo(() => {
        if (contentType === PlaybookSearchContentType.AUTHOR) {
            const term = authorOptions.find(({ value }) => value === searchTerm) || null;
            return term;
        }
        if (contentType === PlaybookSearchContentType.AGREEMENT_TYPE) {
            const term = agreementTypeOptions.find(({ label }) => label === searchTerm) || null;
            return term;
        }
        return searchTerm;
    }, [contentType, searchTerm, authorOptions, agreementTypeOptions]);

    return (
        <div key={index} className={styles.smartInputRow}>
            {renderFirstColumn(isFirstRow, logicalOperatorOptions, logicalOperatorValue, updateDropdown, index, testId)}
            <div className={styles.conditionColumn}>
                <Dropdown
                    isClearable={false}
                    onChange={val => updateDropdown(val, 'condition')}
                    value={conditionValue}
                    options={conditionOptions}
                    menuPortalTarget={document.body}
                    testId={`${testId}-${index}-condition`}
                    hideIndicator
                />
            </div>
            <div className={styles.contentTypeColumn}>
                <Dropdown
                    isClearable={false}
                    onChange={val => updateDropdown(val, 'contentType')}
                    value={contentTypeValue}
                    options={contentTypeOptions}
                    menuPortalTarget={document.body}
                    testId={`${testId}-${index}-content-type`}
                    hideIndicator
                />
            </div>
            {showSearchDropdown ?
                <div className={styles.inputWrapper}>
                    <Dropdown
                        isClearable={false}
                        onChange={val => updateDropdown(val, 'searchTerm')}
                        value={smartSearchTerm as DropdownOption | DropdownOption[]}
                        options={searchDropdownOptions}
                        menuPortalTarget={document.body}
                        testId={`${testId}-${index}-search-term`}
                        hideIndicator
                    />
                </div> :
                <div className={styles.inputWrapper}>
                    <Text
                        value={smartSearchTerm as string}
                        testId={`${testId}-${index}-search-term`}
                        marginBottom=''
                        height='26px'
                        onChange={e => updateSearchTerm(e.target.value)}
                    />
                </div>
            }
            {showDelete ? <DeleteButton fontSize={20} onClick={() => removeRow(index)} testId={`${testId}-${index}`} /> : <div className={styles.deletePlaceholder} />}
        </div>
    );
};
