import React, { useCallback } from 'react';
import { isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { SaveFile } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Text } from '../../shared/text/Text';
import { getSaveTemplateModalOpen, saveReportTemplateStarted, toggleSaveTemplateModalOpen, updateReportTemplateValue, getReportTemplateName, getReportTemplateDescription, getSelectedTemplateId } from './store';
import { LongText } from '../../shared/longtext/LongText';

export const SaveDocumentTemplateModal: React.FC = () => {
    const dispatch = useAppDispatch();

    const isOpen = useAppSelector(getSaveTemplateModalOpen);
    const name = useAppSelector(getReportTemplateName);
    const description = useAppSelector(getReportTemplateDescription);
    const selectedTemplateId = useAppSelector(getSelectedTemplateId);
    const confirmDisabled = !name;

    const confirmDisabledTooltip = confirmDisabled ? ['Report template must have a name'] : [];

    const closeModal = () => dispatch(toggleSaveTemplateModalOpen(false));
    const updateName = useCallback((value: string) => dispatch(updateReportTemplateValue('templateName', value)), [dispatch]);
    const updateDescription = useCallback((value: string) => dispatch(updateReportTemplateValue('templateDescription', value)), [dispatch]);
    const saveTemplate = () => dispatch(saveReportTemplateStarted(!isUndefined(selectedTemplateId)));

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={saveTemplate}
            confirmDisabled={confirmDisabled}
            confirmDisabledTooltip={confirmDisabledTooltip}
            confirmLabel='Save'
            testId='save-report-template'
        >
            <ModalHeader label='Save Report Template' icon={SaveFile} testId='save-report-template' />
            <Text
                testId='save-report-template-name'
                label='Template Name'
                value={name}
                onChange={e => updateName(e.target.value)}
                maxLength={100}
            />
            <LongText
                testId='save-report-template-description'
                label='Description'
                value={description}
                onChange={updateDescription}
                width='500px'
            />
        </ConfirmationModal>
    );
};
