import React from 'react';

import { Position, PositionModal } from '../../../shared/modal/PositionModal';
import styles from './DatasetComponents.module.scss';

interface RefModalProps {
    isOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    refLabel: string;
    updateRef: (val: string) => void;
    disableInputs: boolean;
}

export const RefModal: React.FC<RefModalProps> = ({ closeModal, isOpen, position, updateRef, refLabel, disableInputs }) => (
    <PositionModal
        isOpen={isOpen}
        position={position}
        closeModal={closeModal}
        testId='ref'
    >
        <textarea
            style={{ resize: 'none' }}
            maxLength={500}
            className={styles.refInput}
            placeholder='Add a ref...'
            value={refLabel}
            onChange={e => updateRef(e.target.value)}
            data-testid='ref-input'
            disabled={disableInputs}
        />
    </PositionModal>
);
