import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { CalculatorField as CalculatorValue, CalculatorFieldType } from '../../shared/calculator/constants';
import styles from './Fields.module.scss';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import { IconButton } from '../../shared/button/IconButton';
import { Calculator as CalculatorIcon } from '../../shared/icons';
import { CalculatorModal } from './CalculatorModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { ReadOnlyCalculatorField } from './ReadOnlyCalculatorField';
import { MLDataValue } from '../store/mlTypes';

const { lightGrey, amethyst, green, amber, gold } = styles;

interface CalculatorCellProps {
    id: string;
    index: number;
    showOutputField: boolean;
    showGracePeriod: boolean;
    includeFreeTextOption?: boolean;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    disabled?: boolean;
    value: CalculatorValue[];
    isMLCorrection?: boolean;
    showAIModified?: boolean;
    showAIModifiedUserCorrected?: boolean;
    fieldType: CalculatorFieldType;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
    mlQuestion?: string;
    mlValue?: MLDataValue;
}

export const CalculatorCell: React.FC<CalculatorCellProps> = ({
    id,
    disabled = false,
    value,
    index,
    sectionId,
    groupIndex,
    rowId,
    fieldType,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    isMLCorrection = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    parentFieldId,
    datasetId,
    modalInstance,
    showOutputField,
    showGracePeriod,
    includeFreeTextOption = false,
    mlQuestion,
    mlValue
}) => {

    const dispatch = useAppDispatch();
    const [showCalculatorModal, setShowCalculatorModal] = useState<boolean>(false);
    const [calculatorInstance, setCalculatorInstance] = useState<CalculatorValue[]>(value);

    const openModal = () => setShowCalculatorModal(true);

    const closeModal = () => {
        setShowCalculatorModal(false);
        setCalculatorInstance(value);
    };

    const updateCalculatorInstance = (value: CalculatorValue[]) => setCalculatorInstance(value);

    const closeAndUpdateCalculatorCell = () => {
        setShowCalculatorModal(false);
        updateValue(calculatorInstance);
    };

    const updateValue = useCallback((value: CalculatorValue[]) => {
        if (isMLCorrection) {
            dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
        }
    }, [datasetId, parentFieldId, index, sectionId, groupIndex, rowId, modalInstance, dispatch, isMLCorrection]);

    const borderColor = useMemo(() => {
        if (!showDatasetUpdated && !showFieldUpdated && !showAIModified && !showAIModifiedUserCorrected) {
            return lightGrey;
        }
        if (showAIModifiedUserCorrected) {
            return amber;
        }
        if (showAIModified) {
            return gold;
        }
        return showFieldUpdated ? amethyst : green;
    }, [showDatasetUpdated, showFieldUpdated, showAIModified, showAIModifiedUserCorrected]);

    return (
        <>
            <div className={styles.tableCalculatorWrapper}>
                <Scrollable maxHeight='200px'>
                    <div className={styles.inputAndIconWrapper}>
                        <div className={styles.outputRowsWrapper} style={{ width: '100%' }}>
                            {value.map(({ calculation, calculationCondition, output, gracePeriod }, calculationIndex) => (
                                <ReadOnlyCalculatorField
                                    value={value}
                                    calculation={calculation}
                                    output={output}
                                    gracePeriod={gracePeriod}
                                    calculationCondition={calculationCondition}
                                    calculationIndex={calculationIndex}
                                    fieldType={fieldType}
                                    borderColor={borderColor}
                                    key={calculationIndex}
                                    showOutputField={showOutputField}
                                    showGracePeriod={showGracePeriod}
                                />
                            ))}
                        </div>
                    </div>
                </Scrollable>
                <div className={styles.calculatorIcon}>
                    <IconButton icon={CalculatorIcon} disabled={disabled} onClick={openModal} fontSize={40} />
                </div>
            </div >
            {showCalculatorModal &&
                <CalculatorModal
                    isOpen={showCalculatorModal}
                    disabled={disabled}
                    closeModal={closeModal}
                    fieldType={fieldType}
                    value={calculatorInstance}
                    updateValue={updateCalculatorInstance}
                    borderColor={borderColor}
                    id={id}
                    saveModal={closeAndUpdateCalculatorCell}
                    showOutputField={showOutputField}
                    showGracePeriod={showGracePeriod}
                    mlValue={mlValue}
                    mlQuestion={mlQuestion}
                    includeFreeTextOption={includeFreeTextOption}
                />
            }
        </>
    );
};
