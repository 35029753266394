import React, { useMemo, useCallback, useEffect } from 'react';
import { isNull } from 'lodash/fp';

import styles from '../../Analytics.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { fetchDocumentRiskToleranceStarted, getFetchingRiskToleranceDocuments, getPaginatedRiskToleranceDocuments, getRiskToleranceDocumentsPageNumber, getRiskToleranceDocumentsPageSize, getTotalRiskToleranceDocuments, TableDocumentsRiskToleranceDB, setDocumentRiskToleranceTableFilters, clearDocumentRiskToleranceTableFilters, setDocumentRiskToleranceTableColumnSort, setDocumentRiskTolerancePageSize, documentRiskTolerancePaginationNext, documentRiskTolerancePaginationPrevious, getRiskToleranceDocumentsColumnSort, getRiskToleranceDocumentsFilters, setRiskToleranceView, DocumentRiskView, fetchPaginatedDocumentsRiskToleranceStarted, getDocumentRiskTolerance, redirectToDocumentInstance, EntityDocumentRiskView, setEntityDocumentRiskToleranceView, setPreExecutionDocumentRiskToleranceView, PreExecutionDocumentRiskView } from '../../store';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../../shared/table/ArkTable';
import { TableFilterType } from '../../../../shared/modal/TableFilterModal';
import { getOriginalDocumentNameTableDropdownOptions } from '../../../../admin/documents/store';
import { getBaseEntityDropdownOptions } from '../../../../admin/entity/store';
import { DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { documentRiskLevelLabel } from './DocumentRiskToleranceLevel';

const riskRatingFormatter = (riskRating: string) => {
    const riskPercentage = Math.ceil(parseInt(riskRating));
    const { colour, label } = documentRiskLevelLabel(riskPercentage, true);
    return [colour, label];
};

export const riskLevelOptions: DropdownOption[] = [
    { value: '20', label: 'Highly Desirable' },
    { value: '40', label: 'Desirable' },
    { value: '60', label: 'Acceptable' },
    { value: '80', label: 'Of Concern' },
    { value: '100', label: 'Of Serious Concern' },
];

interface DocumentRiskToleranceTableProps {
    height: number;
    width: number;
    entityId?: number;
    isPreExecution?: boolean;
}

export const DocumentRiskToleranceTable: React.FC<DocumentRiskToleranceTableProps> = ({ height, width, entityId, isPreExecution = false }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchPaginatedDocumentsRiskToleranceStarted(1, entityId, isPreExecution));
    }, [dispatch, entityId, isPreExecution]);

    const paginatedRiskToleranceDocuments = useAppSelector(getPaginatedRiskToleranceDocuments);
    const totalPaginatedRiskToleranceDocuments = useAppSelector(getTotalRiskToleranceDocuments);
    const isFetchingPaginatedDocuments = useAppSelector(getFetchingRiskToleranceDocuments);

    const documentsAndDocumentGroupOptions = useAppSelector(getOriginalDocumentNameTableDropdownOptions);
    const entityOptions = useAppSelector(getBaseEntityDropdownOptions);
    const documentRiskTolerance = useAppSelector(getDocumentRiskTolerance);

    const riskToleranceDocumentFilters = useAppSelector(getRiskToleranceDocumentsFilters);
    const columnSort = useAppSelector(getRiskToleranceDocumentsColumnSort);
    const pageNumber = useAppSelector(getRiskToleranceDocumentsPageNumber);
    const pageSize = useAppSelector(getRiskToleranceDocumentsPageSize);
    const nextPage = useCallback(() => dispatch(documentRiskTolerancePaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(documentRiskTolerancePaginationPrevious()), [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setDocumentRiskToleranceTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearDocumentRiskToleranceTableFilters()), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setDocumentRiskTolerancePageSize(pageSize)), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setDocumentRiskToleranceTableColumnSort(columnSort)), [dispatch]);

    const openDocument = useCallback((data: TableDocumentsRiskToleranceDB) => dispatch(fetchDocumentRiskToleranceStarted(data.originalDocumentId, isPreExecution)), [dispatch, isPreExecution]);
    const showDocumentBreakdown = useCallback((data: TableDocumentsRiskToleranceDB) => {
        dispatch(fetchDocumentRiskToleranceStarted(data.originalDocumentId));
        if (entityId) {
            dispatch(setEntityDocumentRiskToleranceView(EntityDocumentRiskView.ENTITY_DOCUMENT_BREAKDOWN));
        } else if (isPreExecution) {
            dispatch(setPreExecutionDocumentRiskToleranceView(PreExecutionDocumentRiskView.PRE_EXECUTION_DOCUMENT_BREAKDOWN));
        } else {
            dispatch(setRiskToleranceView(DocumentRiskView.DOCUMENT_BREAKDOWN));
        }
    }, [dispatch, entityId, isPreExecution]);

    const filterDropdownOptions = useMemo(() => ({
        documentName: documentsAndDocumentGroupOptions,
        partyA: entityOptions,
        partyB: entityOptions,
        riskRating: riskLevelOptions
    }), [documentsAndDocumentGroupOptions, entityOptions]);

    const tableWidth = useMemo(() => width / 2, [width]);
    const tableHeight = useMemo(() => height - 80, [height]);
    const selectedTableRow = useMemo(() => !isNull(documentRiskTolerance) ? { field: 'originalDocumentId', fieldValue: documentRiskTolerance.originalDocumentId } : undefined, [documentRiskTolerance]);

    const redirectToDocument = useCallback((row: TableDocumentsRiskToleranceDB) => dispatch(redirectToDocumentInstance(row.originalDocumentId, row.datasetInstanceId)), [dispatch]);

    const documentRiskToleranceColumnDefs: ArkTableColumn[] = [
        { id: 'documentName', header: 'Document Name', field: 'documentName', width: 0.2, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', width: 0.25, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'partyA', header: 'Party A', field: 'partyA', width: 0.15, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'partyB', header: 'Party B', field: 'partyB', width: 0.15, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'riskRating', header: 'Risk Rating', field: 'riskRating', width: 0.15, component: 'colour', canFilter: true, canSort: true, valueFormatter: riskRatingFormatter },
        { id: 'documentLink', header: 'Document Link', field: 'documentDescription', width: 0.1, component: 'redirect', redirect: redirectToDocument }
    ];

    return (
        <div className={styles.tableWrapper}>
            <ArkTable
                colDefs={documentRiskToleranceColumnDefs}
                rowData={paginatedRiskToleranceDocuments}
                testId='risk-tolerance-documents'
                isLoading={isFetchingPaginatedDocuments}
                page={pageNumber}
                total={totalPaginatedRiskToleranceDocuments}
                next={nextPage}
                previous={previousPage}
                filters={riskToleranceDocumentFilters}
                updateFilter={updateFilter}
                clearAllFilters={clearAllFilters}
                onRowClick={row => openDocument(row as TableDocumentsRiskToleranceDB)}
                onRowDoubleClicked={row => showDocumentBreakdown(row as TableDocumentsRiskToleranceDB)}
                columnSort={columnSort}
                toggleSort={toggleColumnSort}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                width={tableWidth}
                filterDropdownOptions={filterDropdownOptions}
                removeTextFilter={['riskRating']}
                height={tableHeight}
                selectedRow={selectedTableRow}
            />
        </div>
    );
};
