import { isUndefined } from 'lodash';
import { flow, isNull, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';
import { DoraMyCompaniesActionTypes, DoraMyCompaniesView, DoraMyCompaniesState, Migrations } from './types';

export const INITIAL_STATE: DoraMyCompaniesState = {
    myCompaniesView: DoraMyCompaniesView.IN_SCOPE,
    companyEntityModalOpen: false,
    selectedCompanyEntity: null,
    savedCompanyEntity: null,
    currentCompanyEntityFunctionIds: [],
    selectedCompanyEntityFunctionIds: [],
    fetchingCompanies: false,
    inScopeCompanies: [],
    outOfScopeCompanies: [],
    inScopeTotalCompanies: 0,
    outOfScopeTotalCompanies: 0,
    inScopeCompanyFilters: {},
    outOfScopeCompanyFilters: {},
    inScopeCompanyPage: 1,
    outOfScopeCompanyPage: 1,
    inScopeCompanyPageSize: DEFAULT_PAGE_SIZE,
    outOfScopeCompanyPageSize: DEFAULT_PAGE_SIZE,
    fetchError: null,
    inScopeCompanySort: undefined,
    outOfScopeCompanySort: undefined,
    myCompanyEntities: [],
    fetchingEntityFunctionIds: false,
    functionMigration: null,
    isCompanyFunctionMigration: false,
    entityDescriptionOpen: false,
    isSavingEntity: false
};

export const doraMyCompaniesReducer: Reducer<DoraMyCompaniesState> = (state = INITIAL_STATE, { payload, type }): DoraMyCompaniesState => {
    switch (type) {
        case DoraMyCompaniesActionTypes.TOGGLE_MY_COMPANIES_VIEW:
            return set('myCompaniesView', payload, state);
        case DoraMyCompaniesActionTypes.TOGGLE_COMPANY_ENTITY_MODAL:
            return set('companyEntityModalOpen', payload, state);
        case DoraMyCompaniesActionTypes.SET_SELECTED_COMPANY_ENTITY:
            if (isNull(payload)) {
                return flow(
                    set('selectedCompanyEntity', payload),
                    set('savedCompanyEntity', payload),
                    set('currentCompanyEntityFunctionIds', []),
                    set('selectedCompanyEntityFunctionIds', [])
                )(state);
            }
            return flow(
                set('selectedCompanyEntity', payload),
                set('savedCompanyEntity', payload)
            )(state);
        case DoraMyCompaniesActionTypes.UPDATE_SELECTED_ENTITY: {
            const { key, value, contentKey } = payload;
            if (!isUndefined(contentKey)) {
                return set(`selectedCompanyEntity[${key}][${contentKey}]`, value, state);
            }
            return set(`selectedCompanyEntity[${key}]`, value, state);
        }
        case DoraMyCompaniesActionTypes.FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_STARTED:
            return set('fetchingCompanies', true, state);
        case DoraMyCompaniesActionTypes.FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_SUCCESSFUL: {
            return flow(
                set('inScopeCompanies', payload.entities),
                set('inScopeTotalCompanies', payload.total),
                set('inScopeCompanyPage', payload.pageNumber || state.inScopeCompanyPage),
                set('fetchingCompanies', false)
            )(state);
        }
        case DoraMyCompaniesActionTypes.FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_FAILED:
            return flow(
                set('fetchError', payload),
                set('fetchingCompanies', false)
            )(state);
        case DoraMyCompaniesActionTypes.FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_STARTED:
            return set('fetchingCompanies', true, state);
        case DoraMyCompaniesActionTypes.FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_SUCCESSFUL:
            return flow(
                set('outOfScopeCompanies', payload.entities),
                set('outOfScopeTotalCompanies', payload.total),
                set('outOfScopeCompanyPage', payload.pageNumber || state.outOfScopeCompanyPage),
                set('fetchingCompanies', false)
            )(state);
        case DoraMyCompaniesActionTypes.FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_FAILED:
            return flow(
                set('fetchError', payload),
                set('fetchingCompanies', false)
            )(state);
        case DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANY_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`inScopeCompanyFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`inScopeCompanyFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANY_TABLE_COLUMN_SORT:
            return set('inScopeCompanySort', payload, state);
        case DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANIES_PAGE_SIZE:
            return set('inScopeCompanyPageSize', payload, state);
        case DoraMyCompaniesActionTypes.CLEAR_IN_SCOPE_COMPANY_TABLE_FILTERS:
            return set('inScopeCompanyFilters', {}, state);
        case DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`outOfScopeCompanyFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`outOfScopeCompanyFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANY_TABLE_COLUMN_SORT:
            return set('outOfScopeCompanySort', payload, state);
        case DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANIES_PAGE_SIZE:
            return set('outOfScopeCompanyPageSize', payload, state);
        case DoraMyCompaniesActionTypes.CLEAR_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS:
            return set('outOfScopeCompanyFilters', {}, state);
        case DoraMyCompaniesActionTypes.FETCH_MY_COMPANY_ENTITIES_SUCCESSFUL:
            return set('myCompanyEntities', payload, state);
        case DoraMyCompaniesActionTypes.UPDATE_SELECTED_ENTITY_FUNCTION_IDS: {
            return set('selectedCompanyEntityFunctionIds', payload, state);
        }
        case DoraMyCompaniesActionTypes.SAVE_COMPANY_ENTITY_STARTED:
            return set('isSavingEntity', true, state);
        case DoraMyCompaniesActionTypes.SAVE_COMPANY_ENTITY_SUCCESSFUL: {
            return flow(
                set('currentCompanyEntityFunctionIds', state.selectedCompanyEntityFunctionIds),
                set('savedCompanyEntity', state.selectedCompanyEntity),
                set('isSavingEntity', false)
            )(state);
        }
        case DoraMyCompaniesActionTypes.SAVE_COMPANY_ENTITY_FAILED:
            return set('isSavingEntity', false, state);
        case DoraMyCompaniesActionTypes.FETCH_ENTITY_FUNCTION_IDS_STARTED:
            return set('fetchingEntityFunctionIds', true, state);
        case DoraMyCompaniesActionTypes.FETCH_ENTITY_FUNCTION_IDS_SUCCESSFUL:
            return flow(
                set('currentCompanyEntityFunctionIds', payload),
                set('selectedCompanyEntityFunctionIds', payload),
                set('fetchingEntityFunctionIds', false),
            )(state);
        case DoraMyCompaniesActionTypes.FETCH_ENTITY_FUNCTION_IDS_FAILED:
            return set('fetchingEntityFunctionIds', false, state);
        case DoraMyCompaniesActionTypes.CLOSE_MIGRATE_FUNCTION_CONFIRMATION_MODAL:
            return flow(
                set('functionMigration', null),
                set('isCompanyFunctionMigration', false)
            )(state);
        case DoraMyCompaniesActionTypes.FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_SUCCESSFUL:
            return set('functionMigration', payload, state);
        case DoraMyCompaniesActionTypes.UPDATE_COMPANY_FUNCTION_MIGRATIONS: {
            const { functionId, value } = payload;
            if (!isNull(state.functionMigration)) {
                const migrations = state.functionMigration.migrations.reduce((acc, cur) => {
                    if (cur.functionId === functionId) {
                        cur.newCompanyId = value;
                    }
                    acc.push(cur);
                    return acc;
                }, [] as Migrations[]);
                return set('functionMigration.migrations', migrations, state);
            }
            return set('functionMigration', null, state);
        }
        case DoraMyCompaniesActionTypes.COMPANY_FUNCTION_MIGRATION_CHECK_SUCCESSFUL: {
            return flow(
                set('functionMigration', payload),
                set('isCompanyFunctionMigration', true)
            )(state);
        }
        case DoraMyCompaniesActionTypes.TOGGLE_TYPE_OF_ENTITY_DESCRIPTION:
            return set('entityDescriptionOpen', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
