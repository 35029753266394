import { toast } from 'react-toastify';
import { all, call, debounce, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { isEqual } from 'lodash/fp';

import { fetchAllRiskToleranceFailed, fetchAllRiskToleranceSuccessful, fetchRiskToleranceByDatasetIdFailed, fetchRiskToleranceByDatasetIdStarted, fetchRiskToleranceByDatasetIdSuccessful, saveRiskToleranceFailed, saveRiskToleranceStarted, saveRiskToleranceSuccessful, setRiskToleranceUpdated } from './actions';
import { RiskTolerance, RiskToleranceActionTypes, RiskToleranceDataset } from './types';
import { getAllRiskTolerance, getRiskToleranceByParentId, upsertRiskTolerance } from '../../../../services/dataset';
import { DatasetDefinition } from '../../../datasets/store';
import { getCurrentRiskToleranceConfig, getRiskToleranceConfig } from './selectors';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';

export function* attemptFetchAllRiskTolerance() {
    try {
        const riskToleranceDatasets: RiskToleranceDataset[] = yield call(getAllRiskTolerance);
        yield put(fetchAllRiskToleranceSuccessful(riskToleranceDatasets));
    } catch (e) {
        toast.error('Unable to fetch the dataset risk tolerance. Please try again.');
        yield put(fetchAllRiskToleranceFailed((e as Error).message));
    }
}

function* fetchAllRiskToleranceWatcher() {
    yield takeEvery(RiskToleranceActionTypes.FETCH_ALL_RISK_TOLERANCE_STARTED, attemptFetchAllRiskTolerance);
}

export function* attemptFetchRiskTolerance({ payload }: ReturnType<typeof fetchRiskToleranceByDatasetIdStarted>) {
    try {
        const { riskTolerance, definitions, documentHiddenFields }: { riskTolerance: RiskTolerance[]; definitions: DatasetDefinition[]; documentHiddenFields: DocumentSpecificHiddenFields; } = yield call(getRiskToleranceByParentId, { datasetId: payload });
        yield put(fetchRiskToleranceByDatasetIdSuccessful(riskTolerance, definitions, documentHiddenFields, payload));
        yield put(push(`/admin/risk-tolerance/${payload}`));
    } catch (e) {
        toast.error('Unable to fetch risk tolerance for the selected dataset. Please try again.');
        yield put(fetchRiskToleranceByDatasetIdFailed((e as Error).message));
    }
}

function* fetchRiskToleranceByDatasetIdWatcher() {
    yield takeLeading(RiskToleranceActionTypes.FETCH_RISK_TOLERANCE_BY_DATASET_ID_STARTED, attemptFetchRiskTolerance);
}

export function* attemptGoBackToAllRiskToleranceDatasets() {
    yield put(push('/admin/risk-tolerance'));
}

function* goBackToAllRiskToleranceDatasets() {
    yield takeEvery(RiskToleranceActionTypes.GO_BACK_TO_ALL_RISK_TOLERANCE_DATASETS, attemptGoBackToAllRiskToleranceDatasets);
}

export function* checkRiskToleranceUpdated() {
    const currentRiskToleranceConfig: RiskTolerance[] = yield select(getCurrentRiskToleranceConfig);
    const savedRiskToleranceConfig: RiskTolerance[] = yield select(getRiskToleranceConfig);
    const hasUpdated = !isEqual(currentRiskToleranceConfig, savedRiskToleranceConfig);
    yield put(setRiskToleranceUpdated(hasUpdated));
}

function* checkRiskToleranceUpdatedWatcher() {
    yield debounce(500, [RiskToleranceActionTypes.UPDATE_RISK_FIELD_CONFIG, RiskToleranceActionTypes.UPDATE_RISK_FIELD_WEIGHTING], checkRiskToleranceUpdated);
}

export function* attemptSaveRiskTolerance({ payload }: ReturnType<typeof saveRiskToleranceStarted>) {
    try {
        const currentRiskToleranceConfig: RiskTolerance[] = yield select(getCurrentRiskToleranceConfig);
        const savedRiskToleranceConfig: RiskTolerance[] = yield select(getRiskToleranceConfig);
        const updatedRiskToleranceDatasets = currentRiskToleranceConfig.filter(riskConfig => {
            const savedRiskConfig = savedRiskToleranceConfig.find(({ datasetId }) => riskConfig.datasetId === datasetId);
            return !!savedRiskConfig && !isEqual(savedRiskConfig, riskConfig);
        });
        yield call(upsertRiskTolerance, { riskTolerance: updatedRiskToleranceDatasets, datasetId: payload });
        yield put(saveRiskToleranceSuccessful());
        toast('Risk Tolerance saved');
    } catch (e) {
        toast.error('Unable to save risk tolerance. Please try again.');
        yield put(saveRiskToleranceFailed());
    }
}

function* saveRiskToleranceWatcher() {
    yield takeLeading(RiskToleranceActionTypes.SAVE_RISK_TOLERANCE_STARTED, attemptSaveRiskTolerance);
}

export function* riskToleranceSaga() {
    yield all([
        fork(fetchAllRiskToleranceWatcher),
        fork(fetchRiskToleranceByDatasetIdWatcher),
        fork(goBackToAllRiskToleranceDatasets),
        fork(checkRiskToleranceUpdatedWatcher),
        fork(saveRiskToleranceWatcher)
    ]);
}
