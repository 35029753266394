import React, { useCallback } from 'react';

import styles from './Tags.module.scss';
import { MatchingProvisions } from '../../../admin/playbook/store';
import { Button } from '../../button/Button';
import { Scrollable } from '../../scrollable/Scrollable';
import { Text } from '../../text/Text';
import { OverflowTooltip } from '../../tooltip';

interface SearchTagsProps {
    matchingProvisions: MatchingProvisions;
    updateSearchTerm: (searchTerm: string) => void;
    openTagModal: () => void;
    getProvisionTitle: (id: string) => string;
    searchTerm: string;
    totalMatches: number;
    testId?: string;
}

export const SearchTags: React.FC<SearchTagsProps> = ({ updateSearchTerm, searchTerm, matchingProvisions, getProvisionTitle, openTagModal, totalMatches, testId = 'playbook-tags-search' }) => {
    const keyPressCreate = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => e.key.toLowerCase() === 'enter' && openTagModal(), [openTagModal]);
    return (
        <div className={styles.tagSearchWrapper}>
            <Text
                value={searchTerm}
                onChange={e => updateSearchTerm(e.target.value)}
                testId={`${testId}`}
                onKeyDown={keyPressCreate}
            />
            <div className={styles.resultsWrapper}>
                <div className={styles.totalWrapper}>
                    <div className={styles.totalLabel} data-testId={`${testId}-total-matches-label`}>Total Matches:</div>
                    <div className={styles.totalMatches} data-testId={`${testId}-total-matches-number`}>{totalMatches}</div>
                </div>
                <div className={styles.matchingProvisions}>
                    <Scrollable maxHeight='calc(100% - 25px)'>
                        {Object.entries(matchingProvisions).map(([sectionId, matches], index) => (
                            <div className={styles.matchingProvision} key={sectionId}>
                                <div className={styles.provisionTitle}>
                                    <OverflowTooltip overlayText={getProvisionTitle(sectionId)} testId={`${testId}-matched-section-${index}`} />
                                </div>
                                <div className={styles.matchingCount} data-testid={`${testId}-matched-section-${index}-length`}>{matches.length}</div>
                            </div>
                        ))}
                    </Scrollable>
                </div>
            </div>
            <div className={styles.createTagWrapper}>
                <Button label='Create Tag' onClick={openTagModal} testId={`${testId}-create-tag`} />
            </div>
        </div>
    );
};
