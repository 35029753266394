import { isEqual, isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { AttestationAnswers, AttestationInstanceQuestion, AttestationStatus } from '../admin/attestations/store';
import { Dropdown, DropdownOption } from '../shared/dropdown/Dropdown';
import { Attestation, Eye } from '../shared/icons';
import { ConfirmationModal } from '../shared/modal/ConfirmationModal';
import { ModalHeader } from '../shared/modal/ModalHeader';
import { IconTooltip, InformationTooltip } from '../shared/tooltip';
import { getAlertModalOpen, getUserAttestationUpdated, saveUserAttestationInstanceStarted, toggleAlertModalOpen, updateAttestationAnswerValue, updateAttestationUserNotes, UserAttestationInstance } from './store';
import styles from './Attestations.module.scss';
import { LongText } from '../shared/longtext/LongText';
import { AlertModal } from './AlertModal';
import { Scrollable } from '../shared/scrollable/Scrollable';

interface UserAttestationInstanceProps {
    isOpen: boolean;
    closeModal: () => void;
    attestation: UserAttestationInstance;
    testId?: string;
}

interface AnswerOptions {
    [AttestationAnswers.YES_NO]: string[];
    [AttestationAnswers.RAG]: string[];
}

const answerOptions: AnswerOptions = {
    [AttestationAnswers.YES_NO]: ['Yes', 'No'],
    [AttestationAnswers.RAG]: ['Red', 'Amber', 'Green']
};

export const UserAttestationInstanceModal: React.FC<UserAttestationInstanceProps> = ({ isOpen, closeModal, attestation, testId }) => {
    const { attestationName, attestations, userAnswerId, status } = attestation;

    const dispatch = useAppDispatch();
    const instanceUpdated = useAppSelector(getUserAttestationUpdated);
    const alertModalOpen = useAppSelector(getAlertModalOpen);
    const isFreeTextAnswer = useCallback((answers: AttestationAnswers) => answers === AttestationAnswers.FREE_TEXT, []);

    const answerDisabled = useCallback((answerId: string): boolean => answerId !== userAnswerId, [userAnswerId]);

    const saveAttestation = () => dispatch(saveUserAttestationInstanceStarted(userAnswerId));
    const saveAttestationAsDraft = () => dispatch(saveUserAttestationInstanceStarted(userAnswerId, true));

    const answerDropdownOptions = useCallback((answers: AttestationAnswers, customAnswers?: string[]) => {
        if (answers === AttestationAnswers.FREE_TEXT) {
            return [];
        }
        if (answers === AttestationAnswers.CUSTOM) {
            return customAnswers!.map(value => ({ value, label: value }));
        }
        return answerOptions[answers].map(value => ({ value, label: value }));
    }, []);

    const getAnswer = useCallback((value: string | null) => value ? { value, label: value } : null, []);

    const displayRedFlagExplanation = useCallback((answer: string | null, redFlagAnswer: string | null) =>
        !isNull(answer) && !isNull(redFlagAnswer) && isEqual(answer, redFlagAnswer), []);

    const updateAnswer = useCallback((id: string, dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = (dropdownValue as DropdownOption).value;
        }
        dispatch(updateAttestationAnswerValue(id, value));
    }, [dispatch]);

    const updateFreeTextAnswer = useCallback((id: string, value: string) => dispatch(updateAttestationAnswerValue(id, value)), [dispatch]);

    const updateUserNotes = useCallback((id: string, value: string) => dispatch(updateAttestationUserNotes(id, value)), [dispatch]);

    const closeAlertModal = () => dispatch(toggleAlertModalOpen(null));

    const validateQuestion = useCallback(({ answer, userNotes, config: { redFlagAnswer, forceRedFlagExplanation, isRequired } }: AttestationInstanceQuestion) => {
        if (isNull(answer)) {
            return isRequired;
        }
        if (!isNull(redFlagAnswer) && isEqual(answer, redFlagAnswer) && forceRedFlagExplanation) {
            return !userNotes;
        }
    }, []);
    const myAttestations = useMemo(() => attestations.filter(({ config }) => config.userAnswerId === userAnswerId), [attestations, userAnswerId]);
    const invalidAttestations = useMemo(() => myAttestations.some(validateQuestion), [validateQuestion, myAttestations]);
    const saveDisabled = useMemo(() => invalidAttestations || !instanceUpdated, [instanceUpdated, invalidAttestations]);

    const saveDisabledTooltip = useMemo(() => invalidAttestations ? ['One or more of your attestations is incomplete'] : [], [invalidAttestations]);
    const isComplete = status === AttestationStatus.COMPLETED;
    const attestationCausingAlert = useMemo(() => attestations.find(({ id }) => id === alertModalOpen) || null, [alertModalOpen, attestations]);

    const attestationInput = useCallback((id: string, answerId: string, answers: AttestationAnswers, answer: string | null, customAnswers?: string[]) => {
        if (isFreeTextAnswer(answers)) {
            return (
                <LongText
                    value={answer as string}
                    onChange={val => updateFreeTextAnswer(id, val)}
                    width='calc(100% - 12px)'
                    height='60px'
                    disabled={answerDisabled(answerId) || isComplete}
                />
            );
        }
        return (
            <Dropdown
                value={getAnswer(answer)}
                options={answerDropdownOptions(answers, customAnswers)}
                onChange={val => updateAnswer(id, val)}
                testId={testId}
                disabled={answerDisabled(answerId) || isComplete}
                isClearable={false}
                menuPortalTarget={document.body}
            />
        );
    }, [isFreeTextAnswer, updateFreeTextAnswer, getAnswer, answerDropdownOptions, updateAnswer, answerDisabled, isComplete, testId]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={saveAttestation}
            confirmDisabled={saveDisabled}
            confirmDisabledTooltip={saveDisabledTooltip}
            confirmLabel='Save'
            previewDisabled={!instanceUpdated}
            showPreview={!isComplete}
            showConfirm={!isComplete}
            previewLabel='Save Draft'
            openPreview={saveAttestationAsDraft}
            testId={testId}
        >
            <div className={styles.attestationInstanceWrapper}>
                <ModalHeader label={attestationName} icon={Attestation} testId={`${testId}-instance`} />
                <div className={styles.questionsWrapper}>
                    <Scrollable maxHeight='60vh'>
                        {attestations.map(({ question, description, config, id, answer, userNotes }, index) => {
                            const { redFlagAnswer, forceRedFlagExplanation, userAnswerId, answers, customAnswers, isHidden } = config;
                            return (
                                <div key={index} className={styles.attestationWrapper}>
                                    <div className={styles.questionAndDescriptionWrapper} data-testid={`${testId}-instance-question-${index}-wrapper`}>
                                        <div className={styles.questionLabel} data-testid={`${testId}-instance-question-${index}`}>{question}</div>
                                        {description && <InformationTooltip content={description} />}
                                        {isHidden && <IconTooltip icon={Eye} content='This question and your answer will not be visible to other users' />}
                                    </div>
                                    {attestationInput(id, userAnswerId, answers, answer, customAnswers)}
                                    {displayRedFlagExplanation(answer, redFlagAnswer) &&
                                        <div className={styles.redFlagExplanationWrapper}>
                                            <LongText
                                                value={userNotes}
                                                onChange={val => updateUserNotes(id, val)}
                                                width='calc(100% - 12px)'
                                                placeholder='Notes...'
                                                height='60px'
                                                marginBottom='0'
                                                disabled={answerDisabled(userAnswerId) || isComplete}
                                            />
                                            {forceRedFlagExplanation && !isComplete && !answerDisabled(userAnswerId) && !userNotes &&
                                                <div className={styles.forceRedFlagExplanation}>* Required</div>
                                            }
                                        </div>
                                    }
                                </div>
                            );
                        })}
                    </Scrollable>
                </div>
                {attestationCausingAlert &&
                    <AlertModal
                        isOpen={!isNull(alertModalOpen)}
                        closeModal={closeAlertModal}
                        attestation={attestationCausingAlert}
                    />
                }
            </div>
        </ConfirmationModal>
    );
};
