import React, { useCallback } from 'react';

import styles from './DocumentName.module.scss';
import { PlusButton } from '../../shared/button/PlusButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { fetchAllAgreementTypesStarted, getAgreementTypeModalOpen, getModalOpen, getOpenDocumentName, toggleModal, toggleAgreementTypeModal } from './store';
import { DocumentNameModal } from './DocumentNameModal';
import { DocumentNameTable } from './DocumentNameTable';
import { fetchAllDatasetDefinitionsStarted } from '../dataset-builder/store';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Bookshelf } from '../../shared/icons';
import { CustomTooltip } from '../../shared/tooltip';
import { IconButton } from '../../shared/button/IconButton';
import { AgreementTypesModal } from './AgreementTypesModal';

export const Documents: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDatasetDefinitionsStarted(), fetchAllAgreementTypesStarted()]);

    const handleModalToggle = useCallback((isOpen: boolean) => dispatch(toggleModal(isOpen)), [dispatch]);
    const openModal = useCallback(() => handleModalToggle(true), [handleModalToggle]);
    const closeModal = useCallback(() => handleModalToggle(false), [handleModalToggle]);

    const toggleAgreementTypeModalOpen = useCallback((isOpen: boolean) => dispatch(toggleAgreementTypeModal(isOpen)), [dispatch]);
    const openAgreementTypeModal = useCallback(() => toggleAgreementTypeModalOpen(true), [toggleAgreementTypeModalOpen]);
    const closeAgreementTypeModal = useCallback(() => toggleAgreementTypeModalOpen(false), [toggleAgreementTypeModalOpen]);

    const modalOpen = useAppSelector(getModalOpen);
    const agreementTypeModalOpen = useAppSelector(getAgreementTypeModalOpen);
    const document = useAppSelector(getOpenDocumentName);

    return (
        <div className={styles.documentsWrapper} data-testid='documents-wrapper'>
            <div className={styles.documentsTitleContainer}>
                <div className={styles.documentsTitle} data-testid='documents-title'>Documents</div>
                <PlusButton onClick={openModal} fontSize={22} testId='create-document-name' />
                <CustomTooltip overlayText='Manage Agreement Types'>
                    <div className={styles.agreementTypeButton}>
                        <IconButton icon={Bookshelf} onClick={openAgreementTypeModal} fontSize={30} testId='agreement-types' />
                    </div>
                </CustomTooltip>
            </div>
            {document && <DocumentNameModal isOpen={modalOpen} closeModal={closeModal} document={document} />}
            <DocumentNameTable />
            <AgreementTypesModal isOpen={agreementTypeModalOpen} closeModal={closeAgreementTypeModal} />
        </div>
    );
};
