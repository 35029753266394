import React, { useCallback, useMemo } from 'react';

import { useAppSelector, useAppDispatch } from '../../../../hooks/react-redux';
import { Spinner } from '../../../shared/spinner/Spinner';
import styles from './AttestationForms.module.scss';
import {
    FormBuilderTab,
    getFormBuilderWizardOpen,
    getSelectedFormTab,
    setSelectedFormTab,
    toggleAttestationFormBuilderWizardOpen,
    AttestationForm,
    getAttestationFormUpdated,
    getIsSaving,
    AttestationQuestionConfig,
    AttestationAnswers,
    AttestationConfirmationModal,
    toggleAttestationConfirmationModal
} from '../store';
import { DefineTab } from './form-builder-tabs/DefineTab';
import { AttestationsTab } from './form-builder-tabs/AttestationsTab';
import { WizardModal } from '../../../shared/wizard/WizardModal';
import { Attestation } from '../../../shared/icons';

interface FormBuilderWizardProps {
    attestationForm: AttestationForm;
}

export const FormBuilderWizard: React.FC<FormBuilderWizardProps> = ({ attestationForm }) => {
    const dispatch = useAppDispatch();

    const testId = 'create-attestation-form';

    const selectedTab = useAppSelector(getSelectedFormTab);
    const wizardIsOpen = useAppSelector(getFormBuilderWizardOpen);
    const attestationFormUpdated = useAppSelector(getAttestationFormUpdated);
    const isSaving = useAppSelector(getIsSaving);

    const { name, description, userAnswers } = attestationForm;

    const formBuilderTabs = Object.values(FormBuilderTab);
    const selectedTabIndex = formBuilderTabs.indexOf(selectedTab);

    const validateAttestationConfig = useCallback(({ answers, customAnswers }: AttestationQuestionConfig) => answers !== AttestationAnswers.CUSTOM || customAnswers!.every(answer => answer), []);
    const allAttestationsHaveQuestion = useMemo(() => attestationForm.attestations.every(({ question }) => question),[attestationForm]);
    const allAttestationsConfigsValid = useMemo(() => attestationForm.attestations.every(({ config }) => validateAttestationConfig(config)),[attestationForm, validateAttestationConfig]);

    const allUsersHaveLabels = userAnswers.every(({ label }) => label);
    const saveDisabled = !attestationFormUpdated || isSaving || !allAttestationsHaveQuestion || !allAttestationsConfigsValid;
    const saveDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!allAttestationsHaveQuestion) {
            disabledArray.push('Each attestation must have a question');
        }
        if (!allAttestationsConfigsValid) {
            disabledArray.push('The configuation for one or more of your questions is not valid');
        }
        return disabledArray;
    }, [allAttestationsHaveQuestion, allAttestationsConfigsValid]);

    const nextDisabled = !name || !description || !allUsersHaveLabels;
    const nextDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!name) {
            disabledArray.push('You must give your attestation form a name');
        }
        if (!description) {
            disabledArray.push('You must give your attestation form a description');
        }
        if (!allUsersHaveLabels) {
            disabledArray.push('You must give each of your user options a label or remove the empty option');
        }
        return disabledArray;
    }, [description, name, allUsersHaveLabels]);

    const selectTab = useCallback((tab: FormBuilderTab) => dispatch(setSelectedFormTab(tab)), [dispatch]);
    const previous = useCallback(() => selectTab(formBuilderTabs[selectedTabIndex - 1]), [formBuilderTabs, selectTab, selectedTabIndex]);
    const next = useCallback(() => selectTab(formBuilderTabs[selectedTabIndex + 1]), [formBuilderTabs, selectTab, selectedTabIndex]);
    const toggleConfirmationModal = useCallback((modal: AttestationConfirmationModal | null) => dispatch(toggleAttestationConfirmationModal(modal)), [dispatch]);
    const closeWizard = useCallback(() => dispatch(toggleAttestationFormBuilderWizardOpen(false)), [dispatch]);
    const closeAttestationForm = useCallback(() => attestationFormUpdated ? toggleConfirmationModal(AttestationConfirmationModal.CLOSE) : closeWizard(), [attestationFormUpdated, closeWizard, toggleConfirmationModal]);
    const saveAttestationForm = useCallback(() => toggleConfirmationModal(AttestationConfirmationModal.SAVE), [toggleConfirmationModal]);

    const checkTabDisabled = useCallback((tab: FormBuilderTab) => tab === FormBuilderTab.ATTESTATIONS && nextDisabled, [nextDisabled]);

    const wizardTabs = useMemo(() => formBuilderTabs.map(tab => ({
        label: tab,
        selected: tab === selectedTab,
        onClick: () => selectTab(tab),
        disabled: checkTabDisabled(tab)
    })), [selectTab, selectedTab, formBuilderTabs, checkTabDisabled]);

    const wizardButtons = useMemo(() => [
        {
            label: 'Close',
            onClick: closeAttestationForm,
            showButton: true,
            displayLeft: true,
            disabled: false,
            disabledTooltip: null
        },
        {
            label: 'Previous',
            onClick: previous,
            disabled: false,
            showButton: selectedTabIndex !== 0,
            displayLeft: true,
            disabledTooltip: null,
        },
        {
            label: 'Next',
            onClick: next,
            disabled: nextDisabled,
            disabledTooltip: nextDisabledTooltip,
            showButton: selectedTabIndex !== formBuilderTabs.length - 1
        },
        {
            label: 'Save',
            onClick: saveAttestationForm,
            disabled: saveDisabled,
            disabledTooltip: saveDisabledTooltip,
            showButton: attestationFormUpdated && selectedTabIndex === formBuilderTabs.length - 1
        }
    ], [selectedTabIndex, formBuilderTabs, closeAttestationForm, previous, next, saveAttestationForm, nextDisabled, nextDisabledTooltip, saveDisabled, saveDisabledTooltip, attestationFormUpdated]);

    const formBuilderContent = useMemo(() => {
        switch (selectedTab) {
            case FormBuilderTab.DEFINE:
                return <DefineTab attestationForm={attestationForm} testId={`${testId}-wizard-define-tab`} />;
            case FormBuilderTab.ATTESTATIONS:
                return <AttestationsTab attestationForm={attestationForm} testId={`${testId}-wizard-attestation-tab`} />;
            default:
                return <Spinner />;
        }
    }, [selectedTab, attestationForm]);

    return (
        <WizardModal
            isOpen={wizardIsOpen}
            buttons={wizardButtons}
            tabs={wizardTabs}
            title='Create an Attestation Form'
            testId={testId}
            closeWizard={closeWizard}
            headerIcon={Attestation}
        >
            <div className={styles.wizardTabContentWrapper}>
                {formBuilderContent}
            </div>
        </WizardModal>
    );
};
