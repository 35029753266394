import { flow, isNull, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { initialAssessmentDetails } from '../../../constants/dora';
import { DoraSupplyChainActionTypes, DoraSupplyChainState, NewSupplyChainElement, SupplyChainElementTab, SupplyChainElement, IctContractDisplay } from './types';

export const newSupplyChainElement: NewSupplyChainElement = {
    details: {
        isMaterial: undefined,
        justification: null,
        serviceProvided: null,
        furtherICTServiceDetails: null
    },
    companyId: null,
    thirdPartyCompanyId: null
};
const getRankedSupplyChain = (supplyChain: SupplyChainElement[]) => supplyChain.filter(({ supplyChainRank }) => !isNull(supplyChainRank) && supplyChainRank !== 0);
const supplyChainIncludesThirdPartyCompanies = (supplyChain: SupplyChainElement[]) => {
    const rankedSupplyChain = getRankedSupplyChain(supplyChain);
    return rankedSupplyChain.length > 0 && rankedSupplyChain.some(({ thirdPartyCompanyId }) => !isNull(thirdPartyCompanyId));
};
const supplyChainIncludesIntraGroupCompanies = (supplyChain: SupplyChainElement[]) => {
    const rankedSupplyChain = getRankedSupplyChain(supplyChain);
    return rankedSupplyChain.length > 0 && rankedSupplyChain.some(({ companyId }) => !isNull(companyId));
};

export const INITIAL_STATE: DoraSupplyChainState = {
    doraFunctionCompanyDetails: [],
    isFetchingDoraFunctionCompanyDetails: false,
    selectedCompanyId: null,
    selectedFunctionId: null,
    droppableHeight: 0,
    selectedSupplyChainId: null,
    currentSupplyChain: null,
    savedSupplyChain: null,
    isFetchingSupplyChain: false,
    supplyChainDetailsModalOpen: false,
    newSupplyChainElement,
    supplyChainElementHasUpdated: false,
    supplyChainHasUpdated: false,
    availableCompanies: [],
    availableThirdPartyCompanies: [],
    isSaving: false,
    supplyChainFilters: {},
    deleteElementConfirmationModalOpen: false,
    isDeleting: false,
    selectedElementWizardTab: SupplyChainElementTab.DETAILS,
    savedSupplyChainElementDetails: { assessmentInfo: { details: initialAssessmentDetails }, assessmentTimeline: [], contractualInfo: null },
    supplyChainElementDetails: { assessmentInfo: { details: initialAssessmentDetails }, assessmentTimeline: [], contractualInfo: null },
    serviceDescriptionOpen: false,
    selectedSupplyChainAssessmentTimeline: null,
    unsavedChangesModalOpen: false,
    isUploading: false,
    isOpening: false,
    isDownloading: false,
    preview: null,
    assessmentInfoFiles: [],
    supplyChainError: '',
    linkingContract: false,
    ictContractDisplay: IctContractDisplay.INSTANCE
};

export const doraSupplyChainReducer: Reducer<DoraSupplyChainState> = (state = INITIAL_STATE, { payload, type }): DoraSupplyChainState => {
    switch (type) {
        case DoraSupplyChainActionTypes.FETCH_ALL_FUNCTION_COMPANY_DETAILS_STARTED:
            return set('isFetchingDoraFunctionCompanyDetails', true, state);
        case DoraSupplyChainActionTypes.FETCH_ALL_FUNCTION_COMPANY_DETAILS_SUCCESSFUL:
            return flow(
                set('doraFunctionCompanyDetails', payload),
                set('isFetchingDoraFunctionCompanyDetails', false)
            )(state);
        case DoraSupplyChainActionTypes.FETCH_ALL_FUNCTION_COMPANY_DETAILS_FAILED:
            return set('isFetchingDoraFunctionCompanyDetails', false, state);
        case DoraSupplyChainActionTypes.SET_SELECTED_COMPANY_ID:
            return set('selectedCompanyId', payload, state);
        case DoraSupplyChainActionTypes.SET_SELECTED_FUNCTION_ID:
            return set('selectedFunctionId', payload, state);
        case DoraSupplyChainActionTypes.SET_DROPPABLE_HEIGHT:
            return set('droppableHeight', payload, state);
        case DoraSupplyChainActionTypes.TOGGLE_SUPPLY_CHAIN_ELEMENT:
            return flow(
                set('selectedSupplyChainId', payload),
                set('selectedElementWizardTab', SupplyChainElementTab.DETAILS),
                set('ictContractDisplay', IctContractDisplay.INSTANCE)
            )(state);
        case DoraSupplyChainActionTypes.TOGGLE_SUPPLY_CHAIN_DETAILS_MODAL_OPEN: {
            if (!isNull(state.selectedSupplyChainId) && !isNull(state.currentSupplyChain) && !isNull(state.savedSupplyChain)) {
                const currentSupplyChain = state.currentSupplyChain.supplyChain;
                const savedSupplyChain = state.savedSupplyChain.supplyChain;
                const savedSupplyChainElementDetails = savedSupplyChain.find(element => element.doraSupplyChainId === state.selectedSupplyChainId)!.details;
                const resetDetailsSupplyChain = currentSupplyChain.map(element => element.doraSupplyChainId === state.selectedSupplyChainId ? set('details', savedSupplyChainElementDetails, element) : element);
                return flow(
                    set('supplyChainDetailsModalOpen', payload),
                    set('newSupplyChainElement', newSupplyChainElement),
                    set('supplyChainElementHasUpdated', false),
                    set('currentSupplyChain.supplyChain', resetDetailsSupplyChain),
                    set('assessmentInfoFiles', [])
                )(state);
            }
            return flow(
                set('supplyChainDetailsModalOpen', payload),
                set('newSupplyChainElement', newSupplyChainElement),
                set('supplyChainElementHasUpdated', false),
                set('assessmentInfoFiles', [])
            )(state);
        }
        case DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_CONFIGURATION_STARTED:
            return set('isFetchingSupplyChain', true, state);
        case DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_CONFIGURATION_SUCCESSFUL:
            return flow(
                set('savedSupplyChain', payload),
                set('currentSupplyChain', payload),
                set('isFetchingSupplyChain', false)
            )(state);
        case DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_CONFIGURATION_FAILED:
            return set('isFetchingSupplyChain', false, state);
        case DoraSupplyChainActionTypes.UPDATE_SUPPLY_CHAIN_CONFIGURATION: {
            const isThirdParty = supplyChainIncludesThirdPartyCompanies(payload);
            const isIntraGroup = supplyChainIncludesIntraGroupCompanies(payload);
            return flow(
                set('currentSupplyChain.supplyChain', payload),
                set('currentSupplyChain.details.isThirdParty', isThirdParty),
                set('currentSupplyChain.details.isIntraGroup', isIntraGroup)
            )(state);
        }
        case DoraSupplyChainActionTypes.UPDATE_SAVED_SUPPLY_CHAIN_CONFIGURATION:
            return set('savedSupplyChain.supplyChain', payload, state);
        case DoraSupplyChainActionTypes.UPDATE_NEW_DORA_SUPPLY_CHAIN_ELEMENT:
            return set(`newSupplyChainElement[${payload.key}]`, payload.value, state);
        case DoraSupplyChainActionTypes.UPDATE_DORA_SUPPLY_CHAIN_ELEMENT_DETAILS: {
            const { key, value } = payload;
            if (!isNull(state.selectedSupplyChainId) && !isNull(state.currentSupplyChain)) {
                const currentSupplyChain = state.currentSupplyChain.supplyChain;
                const updatedSupplyChain = currentSupplyChain.map(element => element.doraSupplyChainId === state.selectedSupplyChainId ? set(`details[${key}]`, value, element) : element);
                return set('currentSupplyChain.supplyChain', updatedSupplyChain, state);
            }
            return set(`newSupplyChainElement.details[${key}]`, value, state);
        }
        case DoraSupplyChainActionTypes.SET_SUPPLY_CHAIN_ELEMENT_HAS_UPDATED:
            return set('supplyChainElementHasUpdated', payload, state);
        case DoraSupplyChainActionTypes.SET_SUPPLY_CHAIN_HAS_UPDATED:
            return set('supplyChainHasUpdated', payload, state);
        case DoraSupplyChainActionTypes.FETCH_ALL_DORA_BASE_COMPANIES_SUCCESSFUL:
            return flow(
                set('availableCompanies', payload.companies),
                set('availableThirdPartyCompanies', payload.thirdPartyCompanies)
            )(state);
        case DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_ELEMENT_STARTED:
            return set('isSaving', true, state);
        case DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_ELEMENT_SUCCESSFUL: {
            const { hasContractDetails, hasAssessmentDetails } = payload;
            if (!isNull(state.selectedSupplyChainId) && !isNull(state.currentSupplyChain)) {
                const currentSupplyChain = state.currentSupplyChain.supplyChain;
                const currentSelectedSupplyChainElementDetails = currentSupplyChain.find(element => element.doraSupplyChainId === state.selectedSupplyChainId)!.details;
                const updatedCurrentSupplyChain = currentSupplyChain.map(element => {
                    if (element.doraSupplyChainId === state.selectedSupplyChainId) {
                        return flow(
                            set('hasContractDetails', hasContractDetails),
                            set('hasAssessmentDetails', hasAssessmentDetails)
                        )(element);
                    }
                    return element;
                });
                if (!isNull(state.savedSupplyChain)) {
                    const savedSupplyChain = state.savedSupplyChain.supplyChain;
                    const updatedSavedSupplyChain = savedSupplyChain.map(element => {
                        if (element.doraSupplyChainId === state.selectedSupplyChainId) {
                            return flow(
                                set('details', currentSelectedSupplyChainElementDetails),
                                set('hasContractDetails', hasContractDetails),
                                set('hasAssessmentDetails', hasAssessmentDetails)
                            )(element);
                        }
                        return element;
                    });
                    return flow(
                        set('savedSupplyChain.supplyChain', updatedSavedSupplyChain),
                        set('currentSupplyChain.supplyChain', updatedCurrentSupplyChain),
                        set('isSaving', false)
                    )(state);
                }
                return flow(
                    set('currentSupplyChain.supplyChain', updatedCurrentSupplyChain),
                    set('isSaving', false)
                )(state);
            }
            return set('isSaving', false, state);
        }
        case DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_ELEMENT_FAILED:
            return set('isSaving', false, state);
        case DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_CONFIGURATION_STARTED:
            return set('isSaving', true, state);
        case DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_CONFIGURATION_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('supplyChainHasUpdated', false),
                set('savedSupplyChain', state.currentSupplyChain)
            )(state);
        case DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_CONFIGURATION_FAILED:
            return set('isSaving', false, state);
        case DoraSupplyChainActionTypes.UPDATE_COMPANY_FUNCTION_DETAILS:
            return set('currentSupplyChain.details.isCriticalOrImportant', payload, state);
        case DoraSupplyChainActionTypes.SET_COMPANY_FILTER: {
            const { type, key, value } = payload;
            const isTextFilter = type === 'text';
            return flow(
                set(`supplyChainFilters[${key}][${type}]`, value),
                set(`supplyChainFilters[${key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DoraSupplyChainActionTypes.CLEAR_SUPPLY_CHAIN_TABLE_FILTERS:
            return set('supplyChainFilters', {}, state);
        case DoraSupplyChainActionTypes.NAVIGATE_BACK_TO_FUNCTION_COMPANY_SELECT:
            return set('supplyChainHasUpdated', false, state);
        case DoraSupplyChainActionTypes.TOGGLE_DELETE_SUPPLY_CHAIN_ELEMENT_MODAL:
            return set('deleteElementConfirmationModalOpen', payload, state);
        case DoraSupplyChainActionTypes.DELETE_SUPPLY_CHAIN_ELEMENT_STARTED:
            return set('isDeleting', true, state);
        case DoraSupplyChainActionTypes.DELETE_SUPPLY_CHAIN_ELEMENT_SUCCESSFUL: {
            return flow(
                set('isDeleting', false),
                set('currentSupplyChain.supplyChain', payload),
                set('savedSupplyChain.supplyChain', payload),
                set('deleteElementConfirmationModalOpen', false),
                set('supplyChainDetailsModalOpen', false),
                set('selectedSupplyChainId', null)
            )(state);
        }
        case DoraSupplyChainActionTypes.DELETE_SUPPLY_CHAIN_ELEMENT_FAILED:
            return set('isDeleting', false, state);
        case DoraSupplyChainActionTypes.TOGGLE_IT_SERVICE_DESCRIPTION:
            return set('serviceDescriptionOpen', payload, state);
        case DoraSupplyChainActionTypes.SET_SELECTED_SUPPLY_CHAIN_ELEMENT_TAB:
            return set('selectedElementWizardTab', payload, state);
        case DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_SUCCESSFUL: {
            const { assessmentTimeline } = payload;
            const selectedAssessmentTimeline = assessmentTimeline.length > 0 ? assessmentTimeline[assessmentTimeline.length - 1] : null;
            return flow(
                set('savedSupplyChainElementDetails', payload),
                set('supplyChainElementDetails', payload),
                set('selectedSupplyChainAssessmentTimeline', selectedAssessmentTimeline)
            )(state);
        }
        case DoraSupplyChainActionTypes.UPDATE_SUPPLY_CHAIN_ELEMENT_ASSESSMENT: {
            const { key, value } = payload;
            return set(`supplyChainElementDetails.assessmentInfo.details[${key}]`, value, state);
        }
        case DoraSupplyChainActionTypes.SET_SELECTED_ASSESSMENT_TIMELINE_DETAILS:
            return set('selectedSupplyChainAssessmentTimeline', payload, state);
        case DoraSupplyChainActionTypes.CANCEL_CONTRACT_DETAILS_UPDATES:
            return set('supplyChainElementDetails.contractualInfo', state.savedSupplyChainElementDetails.contractualInfo, state);
        case DoraSupplyChainActionTypes.CANCEL_ASSESSMENT_DETAILS_UPDATES:
            return set('supplyChainElementDetails.assessmentInfo', state.savedSupplyChainElementDetails.assessmentInfo, state);
        case DoraSupplyChainActionTypes.SET_UNSAVED_CHANGES_MODAL_OPEN:
            return set('unsavedChangesModalOpen', payload, state);
        case DoraSupplyChainActionTypes.UPLOAD_DORA_DOCUMENT_STARTED:
            return set('isUploading', true, state);
        case DoraSupplyChainActionTypes.UPLOAD_DORA_DOCUMENT_SUCCESSFUL:
            return flow(
                set('isUploading', false),
                set('assessmentInfoFiles', [])
            )(state);
        case DoraSupplyChainActionTypes.UPLOAD_DORA_DOCUMENT_FAILED:
            return set('isUploading', false, state);
        case DoraSupplyChainActionTypes.DOWNLOAD_DORA_DOCUMENT_STARTED:
            return set('isDownloading', true, state);
        case DoraSupplyChainActionTypes.DOWNLOAD_DORA_DOCUMENT_SUCCESSFUL:
        case DoraSupplyChainActionTypes.DOWNLOAD_DORA_DOCUMENT_FAILED:
            return set('isDownloading', false, state);
        case DoraSupplyChainActionTypes.CLOSE_PREVIEW_DORA_DOCUMENT_MODAL:
            return set('preview', null, state);
        case DoraSupplyChainActionTypes.OPEN_DORA_DOCUMENT_STARTED:
            return set('isOpening', true, state);
        case DoraSupplyChainActionTypes.OPEN_DORA_DOCUMENT_SUCCESSFUL:
            return flow(
                set('isOpening', false),
                set('preview', payload)
            )(state);
        case DoraSupplyChainActionTypes.OPEN_DORA_DOCUMENT_FAILED:
            return set('isOpening', false, state);
        case DoraSupplyChainActionTypes.REMOVE_DORA_DOCUMENT_FILE: {
            const updatedFiles = state.assessmentInfoFiles.filter((_, index) => index !== payload);
            return set('assessmentInfoFiles', updatedFiles, state);
        }
        case DoraSupplyChainActionTypes.SET_DORA_DOCUMENT_FILES: {
            return set('assessmentInfoFiles', payload, state);
        }
        case DoraSupplyChainActionTypes.LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_STARTED:
            return set('linkingContract', true, state);
        case DoraSupplyChainActionTypes.LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_SUCCESSFUL:
            return flow(
                set('supplyChainElementDetails', payload),
                set('linkingContract', false)
            )(state);
        case DoraSupplyChainActionTypes.LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_FAILED:
            return set('linkingContract', false, state);
        case DoraSupplyChainActionTypes.SET_ICT_CONTRACT_DISPLAY:
            return set('ictContractDisplay', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
