import React from 'react';

import { Button } from '../../../shared/button/Button';
import { Add } from '../../../shared/icons';
import { AddressInput } from './AddressInput';
import { CompanyEntity, PersonEntity } from '../store';
import styles from './EntityType.module.scss';

interface OtherAddressesProps {
    entity: CompanyEntity | PersonEntity;
    handleUpdateAddressValue: (key: string, value: string | null, index?: number | undefined) => void;
    deleteAddress: (index: number | undefined) => void;
    addAddress: () => void;
    showAddressType?: boolean;
}

export const OtherAddresses: React.FC<OtherAddressesProps> = ({ entity, handleUpdateAddressValue, deleteAddress, addAddress, showAddressType = false }) => (
    <div className={styles.otherAddressWrapper}>
        <div>
            {entity.content.otherAddresses && entity.content.otherAddresses.length > 0 && entity.content.otherAddresses.map((address, index) => (
                <AddressInput
                    key={index}
                    index={index}
                    address1={address.address1}
                    address2={address.address2 || ''}
                    addressType={address.addressType || null}
                    city={address.city || ''}
                    country={address.country || ''}
                    postalCode={address.postalCode || ''}
                    onValueChange={handleUpdateAddressValue}
                    deleteFunction={deleteAddress}
                    entity={entity}
                    showAddressType={showAddressType}
                />
            )
            )}
        </div>
        <div className={styles.addOtherAddressWrapper}>
            <Button label='Add Address' icon={Add} onClick={addAddress} fontSize={20} testId='entity-add-other-address' />
        </div>
    </div>
);
