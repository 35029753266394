import React, { useCallback, useEffect, useMemo } from 'react';
import { RawDraftContentState } from 'draft-js';
import { isEqual, isNull } from 'lodash/fp';

import styles from './Notification.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllClientsStarted, getAllClients } from '../clients/store';
import { Text } from '../../shared/text/Text';
import { WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import { getNotificationToSend, getPreviewModalOpen, resetNotification, sendNotificationStarted, toggleNotificationPreview, updateNotificationAdminOnly, updateNotificationClientIds, updateNotificationContent, updateNotificationTitle } from './store';
import { ClientSelect } from '../../shared/clients/ClientSelect';
import { Toggle } from '../../shared/toggle';
import { Button } from '../../shared/button/Button';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Notification } from '../../shared/icons';
import { NotificationPreviewModal } from './NotificationPreviewModal';

export const SendNotification: React.FC = () => {
    const dispatch = useAppDispatch();
    const { title, content, clientIds, adminOnly } = useAppSelector(getNotificationToSend);
    const clients = useAppSelector(getAllClients);
    const previewModalOpen = useAppSelector(getPreviewModalOpen);

    useFetchStarted([fetchAllClientsStarted()]);

    const setNotificationTitle = useCallback((title: string) => dispatch(updateNotificationTitle(title)), [dispatch]);
    const setNotificationContent = useCallback((content: RawDraftContentState | null) => dispatch(updateNotificationContent(content)), [dispatch]);
    const setNotificationAdminOnly = useCallback((value: boolean) => dispatch(updateNotificationAdminOnly(value)), [dispatch]);

    const allClientIds = useMemo(() => clients.map(({ clientId }) => clientId!), [clients]);

    const updateSelectedClientIds = (checked: boolean, clientId: number) => {
        const selectedClientIds = checked ? [...clientIds, clientId] : clientIds.filter(id => id !== clientId);
        dispatch(updateNotificationClientIds(selectedClientIds));
    };

    const updateAllClientIds = (checked: boolean) => {
        const clientIds = checked ? allClientIds : [];
        dispatch(updateNotificationClientIds(clientIds));
    };

    const allClientIdsSelected = useMemo(() => isEqual(allClientIds.sort(), clientIds.sort()), [allClientIds, clientIds]);

    const sendNotification = useCallback(() => dispatch(sendNotificationStarted()), [dispatch]);
    const togglePreview = useCallback((isOpen: boolean) => dispatch(toggleNotificationPreview(isOpen)), [dispatch]);
    const closePreviewModal = useCallback(() => togglePreview(false), [togglePreview]);
    const openPreviewModal = useCallback(() => togglePreview(true), [togglePreview]);

    const sendDisabled = !title.length || isNull(content) || !clientIds.length;

    useEffect(() => () => {
        dispatch(resetNotification());
    }, [dispatch]);

    return (
        <div className={styles.notificationWrapper} data-testid='notification-wrapper'>
            <ModalHeader label='Send Notification' icon={Notification} />
            <div className={styles.notificationContent}>
                <div className={styles.notificationTitleWrapper} data-testid='notification-title-wrapper'>
                    <div className={styles.notificationTitleLabel} data-testid='notification-title-label'>Title: </div>
                    <div className={styles.notificationTitleInput}>
                        <Text
                            marginBottom='0'
                            testId='notification-title'
                            maxLength={256}
                            value={title}
                            onChange={e => setNotificationTitle(e.target.value)}
                            placeholder='Title...'
                        />
                    </div>
                </div>
                <div className={styles.contentWrapper} data-testid='notification-content-wrapper'>
                    <WYSIWYG
                        content={content}
                        updateContent={val => setNotificationContent(val)}
                        height='90%'
                    />
                </div>
            </div>
            <div className={styles.notificationRecipientWrapper}>
                <div className={styles.clientSelectWrapper}>
                    <ClientSelect
                        allClientIdsSelected={allClientIdsSelected}
                        allClients={clients}
                        selectedClientIds={clientIds}
                        title='Select which clients to send the notification to'
                        updateAllClientIds={updateAllClientIds}
                        updateSelectedClientIds={updateSelectedClientIds}
                    />
                </div>
                <div className={styles.adminOnlyWrapper}>
                    <div className={styles.adminOnlyTitle}>Send to client administrators only?</div>
                    <Toggle
                        checked={adminOnly}
                        onChange={setNotificationAdminOnly}
                    />
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <Button label='Preview & Send' onClick={openPreviewModal} disabled={sendDisabled} />
            </div>
            <NotificationPreviewModal
                isOpen={previewModalOpen}
                closeModal={closePreviewModal}
                sendNotification={sendNotification}
                allClientIdsSelected={allClientIdsSelected}
            />
        </div>
    );
};
