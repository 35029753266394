import React, { MouseEvent } from 'react';

import styles from './Button.module.scss';
import { Icon as ArkIcon } from '../icon/Icon';

interface CountButtonProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    count: number;
    icon: React.FC;
    disabled?: boolean;
    marginRight?: string;
    marginLeft?: string;
    fontSize?: number;
    testId?: string;
    color?: string;
    withBorder?: boolean;
}

export const CountButton: React.FC<CountButtonProps> = ({ onClick, disabled = false, fontSize = 28, icon, testId = '', color, count, marginRight = '0px', marginLeft = '0px' }) => (
    <button
        onClick={onClick}
        className={styles.countButton}
        style={{ color, marginRight, marginLeft }}
        disabled={disabled}
        data-testid={`count-button-${testId}`}
    >
        <ArkIcon icon={icon} fontSize={fontSize} />
        {count > 0 && <div className={styles.countValue} style={{ left: `${fontSize - 12}px` }}>{count}</div>}
    </button>
);
