import React from 'react';

export const Notification: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg x='0px' y='0px' width={width || '1em'} height={height || '1em'} viewBox='0 0 128 128'>
        <path fill={color || 'currentColor'} d='M63.8,101.8c-39.4,0-50.8-10-52-11l0.3-7.3c0.1-0.1,10.5-9.2,10.5-22.4c0-17.4,4-31.2,11.9-40.9
   c7.3-8.9,17.7-13.8,29.3-13.8c11.6,0,22.1,4.9,29.3,13.8c7.9,9.7,11.9,23.5,11.9,40.9c0,13.4,10.4,22.4,10.5,22.4l0.3,7.3
   C114.6,91.9,103.1,101.8,63.8,101.8L63.8,101.8z M23.2,86.1c1.2,0.6,2.8,1,4.6,1.6c6.2,1.9,17.5,4.2,36,4.2
   c22.6,0,34.8-3.4,40.7-5.8c-4-5.1-9.4-13.8-9.4-25.1c0-14.9-3.3-26.9-9.7-34.6c-5.3-6.7-12.9-10.3-21.7-10.3
   c-8.8,0-16.2,3.6-21.7,10.3c-6.4,7.9-9.7,19.8-9.7,34.6C32.4,72.2,27.2,81,23.2,86.1L23.2,86.1z'/>
        <path fill={color || 'currentColor'} d='M63.8,121.1c-12.2,0-22-11.6-22-22h10c0,5.9,5.5,12,12,12s12-6.1,12-12h10C85.8,109.5,76,121.1,63.8,121.1z' />
    </svg>
);
