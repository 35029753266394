import { flattenDeep, isNull } from 'lodash/fp';

import { ApplicationState } from '../../../store/rootReducer';
import { sortPlaybookShelves } from '../../constants/playbooks';
import { PlaybookQuery, PlaybookState, SuggestedChanges } from './types';
import { BasicPlaybook, PlaybookHistory, PlaybookView, PlaybookDB, PlaybookMessage, PlaybookQueryDB, SuggestedChangesDB, ReadOnlyContentType, PlaybookSmartSearch, LinkedPlaybookProvision } from '../../admin/playbook/store';
import { getAvailableDocumentNames } from '../../admin/documents/store/selectors';

const getRoot = (state: ApplicationState): PlaybookState => state.playbook;

export const getAllPlaybooks = (state: ApplicationState): BasicPlaybook[] => getRoot(state).allPlaybooks;

export const getPlaybookShelves = (state: ApplicationState) => {
    const searchTerm = getPlaybookSimpleSearchTerm(state);
    let allPlaybooks: BasicPlaybook[] = getRoot(state).allPlaybooks;
    if (searchTerm !== '') {
        const allDocumentNames = getAvailableDocumentNames(state);
        const getDocumentNames = (ids: string[] | null) => ids ? allDocumentNames.reduce((acc: string[], { documentNameId, documentName }) => documentNameId && ids.includes(documentNameId.toString()) ? [...acc, documentName.toLowerCase()] : acc, []) : [];
        const playbooksToSearch = allPlaybooks.map(({ playbookId, agreementType, createdBy, name, documentNameIds, versionMajor, versionMinor }) => ({ playbookId: playbookId, searchableValues: flattenDeep([!isNull(agreementType) ? agreementType.toString().toLowerCase() : '', createdBy.toLowerCase(), name.toLowerCase(), getDocumentNames(documentNameIds), versionMajor.toString(), `${versionMajor.toString()}.${versionMinor.toString()}`]) }));
        const matchedPlaybookIds = playbooksToSearch.filter(({ searchableValues }) => searchableValues.some(text => text.includes(searchTerm))).map(({ playbookId }) => playbookId);
        allPlaybooks = allPlaybooks.filter(playbook => matchedPlaybookIds.includes(playbook.playbookId));
    }
    const playbookShelves = sortPlaybookShelves(allPlaybooks);
    return playbookShelves;
};

export const getPlaybookCoverSelected = (state: ApplicationState): BasicPlaybook | null => getRoot(state).playbookCoverSelected;

export const getPlaybookCoverModalOpen = (state: ApplicationState): boolean => !isNull(getPlaybookCoverSelected(state));

export const getUserPlaybook = (state: ApplicationState): PlaybookDB | null => getRoot(state).playbook;

export const getUserPlaybookHistory = (state: ApplicationState): PlaybookHistory[] => getRoot(state).playbookHistory;

export const getUserCurrentView = (state: ApplicationState): PlaybookView => getRoot(state).currentView;

export const getQuerySectionId = (state: ApplicationState): string | null => getRoot(state).querySectionId;

export const getQueryModalOpen = (state: ApplicationState): boolean => !isNull(getQuerySectionId(state)) || !isNull(getPlaybookQuery(state));

export const getPlaybookQuery = (state: ApplicationState): PlaybookQuery | null => getRoot(state).query;

export const getQueryReady = (state: ApplicationState): boolean => getRoot(state).queryReady;

export const getSendingQuery = (state: ApplicationState): boolean => getRoot(state).sendingQuery;

export const getUserPlaybookQueries = (state: ApplicationState): PlaybookQueryDB[] => getRoot(state).queries;

export const getSuggestedChanges = (state: ApplicationState): SuggestedChanges | null => getRoot(state).suggestedChanges;

export const getSuggestedChangesModalOpen = (state: ApplicationState): boolean => !isNull(getSuggestedChanges(state));

export const getAdditionalQuery = (state: ApplicationState): PlaybookMessage | null => getRoot(state).additionalQuery;

export const getSuggestedChangesUpdated = (state: ApplicationState): boolean => getRoot(state).suggestedChangesUpdated;

export const getConfirmationModalOpen = (state: ApplicationState): boolean => getRoot(state).showConfirmationModal;

export const getSendingSuggestion = (state: ApplicationState): boolean => getRoot(state).sendingSuggestion;

export const getUserPlaybookSuggestedChanges = (state: ApplicationState): SuggestedChangesDB[] => getRoot(state).playbookSuggestedChanges;

export const getPlaybookSuggestedChange = (state: ApplicationState): SuggestedChangesDB | null => getRoot(state).playbookSuggestedChange;

export const getReadOnlySuggestedChangesOpen = (state: ApplicationState): boolean => !isNull(getPlaybookSuggestedChange(state));

export const getOpenUserDeviations = (state: ApplicationState): string[] => getRoot(state).openDeviations;

export const getSuggestedChangesMessage = (state: ApplicationState): PlaybookMessage | null => getRoot(state).suggestedChangesMessage;

export const getSuggestedChangesMessageUpdated = (state: ApplicationState): boolean => getRoot(state).suggestedChangesMessageUpdated;

export const getSendingSuggestedChangesMessage = (state: ApplicationState): boolean => getRoot(state).sendingMessage;

export const getReadOnlyChangesContent = (state: ApplicationState): ReadOnlyContentType => getRoot(state).readOnlySuggestedChangesContent;

export const getShowUserPlaybookLoadingSpinner = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getPlaybookSimpleSearchTerm = (state: ApplicationState): string => getRoot(state).simpleSearchTerm;

export const getPlaybookSmartSearchTerm = (state: ApplicationState): PlaybookSmartSearch[] => getRoot(state).smartSearchTerm;

export const getProvisionLinksModalOpen = (state: ApplicationState): string | null => getRoot(state).linkedProvisionModalOpen;

export const getLinkedProvisionHistory = (state: ApplicationState): LinkedPlaybookProvision[] => getRoot(state).provisionLinkHistory;

export const getLinkedProvisionHistoryModalOpen = (state: ApplicationState): boolean => getRoot(state).provisionLinkHistoryModalOpen;

