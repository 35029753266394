import { min } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { PieChart, PieChartAnalyticsData } from '../../shared/analytics/PieChart';
import { Icon } from '../../shared/icon/Icon';
import { Robot } from '../../shared/icons';
import { Spinner } from '../../shared/spinner/Spinner';
import styles from './AIManager.module.scss';
import { AIAgreementType, getIsFetchingAgreementTypes, getPortfolioTotal } from './store';

const { green, amber } = styles;

interface AIManagerPieChartProps {
    screenWidth: number;
    screenHeight: number;
    selectedAgreement: AIAgreementType | undefined;
    newAgreementSelected: boolean;
    pieChartMultiplier: number;
}

export const AIManagerPieChart: React.FC<AIManagerPieChartProps> = ({
    screenHeight,
    screenWidth,
    selectedAgreement,
    newAgreementSelected,
    pieChartMultiplier
}) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [diameter, setDiameter] = useState<number>(0);

    const portfolioData = useAppSelector(getPortfolioTotal);
    const isFetchingAgreementTypes = useAppSelector(getIsFetchingAgreementTypes);

    const constructPieData = (totalQueries: number, correctQueries: number): PieChartAnalyticsData[] => [{ key: 'Correct', value: correctQueries, color: green }, { key: 'User Corrected', value: totalQueries - correctQueries, color: amber }];

    const { chartData, totalQueries, correctQueries, percentage } = useMemo(() => {
        if (selectedAgreement) {
            const { totalQueries, correctQueries, percentage } = selectedAgreement;
            const chartData = constructPieData(totalQueries, correctQueries);
            return { chartData, totalQueries, correctQueries, percentage };
        }
        if (newAgreementSelected) {
            const chartData = constructPieData(0, 0);
            return { chartData, totalQueries: 0, correctQueries: 0, percentage: 0 };
        }
        const totalQueries = portfolioData?.totalQueries || 0;
        const correctQueries = portfolioData?.correctQueries || 0;
        const chartData = constructPieData(totalQueries, correctQueries);
        return { chartData, totalQueries, correctQueries, percentage: portfolioData?.percentage || 0 };
    }, [portfolioData, selectedAgreement, newAgreementSelected]);

    const correctPercentage = useMemo(() => `${percentage}%`, [percentage]);

    useEffect(() => {
        setShowSpinner(true);
        if (screenWidth && screenHeight) {
            const width = (screenWidth * pieChartMultiplier) - 30;
            const height = screenHeight - 224;
            setDiameter(min([width, height])!);
            setTimeout(() => setShowSpinner(false), 50);
        }
    }, [screenWidth, screenHeight, setShowSpinner, pieChartMultiplier]);

    if (showSpinner || isFetchingAgreementTypes) {
        return <Spinner />;
    }

    return (
        <>
            <div className={styles.pieChartWrapper} style={{ height: `${diameter}px`, width: `${diameter}px` }}>
                <PieChart data={chartData} diameter={diameter} fullOpacity />
                <div className={styles.pieChartIcon} style={{ height: `${diameter / 3}px`, width: `${diameter / 3}px` }}>
                    <Icon icon={Robot} fontSize={diameter / 5} />
                    <div className={styles.correctPercentage}>{correctPercentage}</div>
                </div>
            </div>
            <div className={styles.successRatioWrapper}>
                <div className={styles.successRatioHeader}>Success Rate</div>
                <div className={styles.successRatio}>{correctQueries} / {totalQueries}</div>
            </div>
        </>
    );
};
