import { Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { indexOf, isUndefined, set } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { DatasetField, DatasetFieldType, DatasetSection, GroupDatasetField, SingleDatasetField, isGroupField } from '../../../datasets/store';
import { DraggableItem, ItemData, ListContext, ListContextValue, getItemRegistry, isItemData } from '../../../shared/drag-n-drop/shared';
import { Draggable } from '../../../shared/drag-n-drop/vertical/Draggable';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { fieldTypeWidths, getRemainingGroupWidth } from '../FieldList';
import { getFormDatasetFields, updateDatasetFields, updateDatasetGroupFields } from '../store';
import { Content } from './Content';
import styles from './DatasetComponents.module.scss';
import { GroupContent } from './GroupContent';
import { MultiToggleContent } from './MultiToggleContent';

interface SectionProps {
    section: DatasetSection;
}

export const Section: React.FC<SectionProps> = ({ section }) => {
    const fields = useAppSelector(getFormDatasetFields);
    const dispatch = useAppDispatch();

    const listId = section.id;
    const topLevelList: DraggableItem[] = useMemo(() => fields.map(({ type, id, label }) => ({ id: id!, label: label ? `${label} (${type})` : type, type })), [fields]);

    const getGroupList = useCallback((groupId: string): DraggableItem[] => (fields.find(({ id }) => id === groupId) as GroupDatasetField).children.map(({ id, label, type }) => ({ id: id!, label: label ? `${label} (${type})` : type, type })), [fields]);

    const [, screenHeight] = useWindowResize();
    // This is the height of the modal minus other fixed height elements such as header, title, sections and buttons wrappers
    const droppableHeight = screenHeight * 0.8 - (41 + 60 + 58 + 75);

    const [registry] = useState(getItemRegistry);

    // Isolated instances of this component from one another
    const [instanceId] = useState(() => Symbol(listId));

    const updateList = useCallback((updatedList: DraggableItem[], groupId?: string) => {
        if (isUndefined(groupId) || groupId === 'standard') {
            const newFields = updatedList.map(({ id }) => fields.find(field => field.id === id)!);
            dispatch(updateDatasetFields(newFields));
        } else {
            const topLevelFieldIds = topLevelList.map(({ id }) => id!);
            const groupIndex = indexOf(groupId, topLevelFieldIds);
            const groupField = fields.find(({ id }) => id === groupId);
            if (groupField && isGroupField(groupField)) {
                const children = updatedList.map(({ id }) => groupField.children.find(field => field.id === id)!);
                dispatch(updateDatasetGroupFields(children, groupIndex));
            }
        }
    }, [fields, topLevelList, dispatch]);

    const updatedMovedItems = useCallback((type: string, fields: DatasetField[] | SingleDatasetField[]) => {
        if (type === 'standard') {
            dispatch(updateDatasetFields(fields));
        } else {
            const topLevelFieldIds = topLevelList.map(({ id }) => id!);
            const groupIndex = indexOf(type, topLevelFieldIds);
            dispatch(updateDatasetGroupFields(fields as SingleDatasetField[], groupIndex));
        }
    }, [dispatch, topLevelList]);

    const getRelevantList = useCallback((type: string) => type === 'standard' ? topLevelList : getGroupList(type), [topLevelList, getGroupList]);

    const getRelocatedField = useCallback((sourceData: ItemData) => {
        if (sourceData.type === 'standard') {
            return fields.find(({ id }) => id === sourceData.item.id);
        }
        return (fields.find(({ id }) => id === sourceData.type) as GroupDatasetField).children.find(({ id }) => id === sourceData.item.id);
    }, [fields]);

    const getFieldsForGroupId = useCallback((groupId: string) => {
        if (groupId === 'standard') {
            return fields;
        }
        return (fields.find(({ id }) => id === groupId) as GroupDatasetField).children;
    }, [fields]);

    const reorderItem = useCallback(
        ({
            startIndex,
            indexOfTarget,
            closestEdgeOfTarget,
            list,
            groupId
        }: {
            startIndex: number;
            indexOfTarget: number;
            closestEdgeOfTarget: Edge | null;
            list?: DraggableItem[];
            groupId?: string;
        }) => {
            const finishIndex = getReorderDestinationIndex({
                startIndex,
                closestEdgeOfTarget,
                indexOfTarget,
                axis: 'vertical'
            });
            const listItems = list || topLevelList;

            const updatedList = reorder({
                list: listItems,
                startIndex,
                finishIndex
            });
            updateList(updatedList, groupId);
        }, [topLevelList, updateList]);

    const moveItem = useCallback(
        ({
            sourceGroupId,
            targetGroupId,
            sourceGroupItemIndex,
            targetGroupItemIndex,
        }: {
            sourceGroupId: string;
            targetGroupId: string;
            sourceGroupItemIndex: number;
            targetGroupItemIndex: number;
        }) => {
            if (sourceGroupId === targetGroupId) {
                return;
            }
            const targetGroupFields = getFieldsForGroupId(targetGroupId);
            const sourceGroupFields = getFieldsForGroupId(sourceGroupId);
            const relocatedField = sourceGroupFields[sourceGroupItemIndex];
            const newSourceGroupsFields = sourceGroupFields.filter((_, index) => index !== sourceGroupItemIndex);
            let newTargetGroupFields = [...targetGroupFields.slice(0, targetGroupItemIndex), relocatedField, ...targetGroupFields.slice(targetGroupItemIndex)];
            if (targetGroupId === 'standard') {
                newTargetGroupFields = newTargetGroupFields.map(field => field.id === sourceGroupId ? set('children', (field as GroupDatasetField).children.filter(({ id }) => id !== relocatedField.id), field) : field);
            }
            updatedMovedItems(sourceGroupId, newSourceGroupsFields);
            updatedMovedItems(targetGroupId, newTargetGroupFields);
        }, [updatedMovedItems, getFieldsForGroupId]);

    useEffect(() => monitorForElements({
        canMonitor: ({ source }) => isItemData(source.data) && source.data.instanceId === instanceId,
        onDrop: ({ location, source }) => {
            const target = location.current.dropTargets[0];
            if (!target) {
                return;
            }

            const sourceData = source.data;
            const targetData = target.data;
            if (!isItemData(sourceData) || !isItemData(targetData)) {
                return;
            }

            const list = getRelevantList(sourceData.type);
            const isWithinSameGroup = sourceData.type === targetData.type;
            if (isWithinSameGroup) {
                const indexOfTarget = list.findIndex(({ id }) => id === targetData.item.id);
                if (indexOfTarget < 0) {
                    return;
                }
                const closestEdgeOfTarget = extractClosestEdge(targetData);
                reorderItem({
                    startIndex: sourceData.index,
                    indexOfTarget,
                    closestEdgeOfTarget,
                    list,
                    groupId: sourceData.type
                });
                return;
            }

            const isDraggingIntoGroup = targetData.type !== 'standard';
            if (isDraggingIntoGroup) {
                const destinationGroup = (fields.find(({ id }) => id === targetData.type) as GroupDatasetField);
                const { type, children } = destinationGroup;
                const groupFieldRelocated = getRelocatedField(sourceData);
                invariant(groupFieldRelocated);
                if (type === DatasetFieldType.GROUP) {
                    if (fieldTypeWidths[groupFieldRelocated.type] > getRemainingGroupWidth(children)) {
                        toast.warning('Group does not have space for this field to be added.');
                        return;
                    }
                }
                if (type === DatasetFieldType.MULTI_TOGGLE) {
                    if (groupFieldRelocated.type !== DatasetFieldType.CHECKBOX) {
                        toast.warning('Multi-Toggle group fields can only contain checkbox fields.');
                        return;
                    }
                }
            }
            const closestEdgeOfTarget = extractClosestEdge(targetData);
            const targetGroupItemIndex = closestEdgeOfTarget === 'bottom' ? targetData.index + 1 : targetData.index;

            moveItem({
                sourceGroupId: sourceData.type,
                targetGroupId: targetData.type,
                sourceGroupItemIndex: sourceData.index,
                targetGroupItemIndex
            });
        },
    }), [instanceId, topLevelList, reorderItem, moveItem, getRelevantList, getRelocatedField, fields]);

    const getListLength = useCallback(() => topLevelList.length, [topLevelList.length]);

    const contextValue: ListContextValue = useMemo(() => ({
        registerItem: registry.register,
        reorderItem,
        instanceId,
        getListLength,
        moveItem
    }), [registry.register, reorderItem, instanceId, getListLength, moveItem]);

    const getChildElement = useCallback((id: string, index: number, type?: string) => {
        let field = fields.find(field => field.id === id);
        let groupIndex = undefined;
        let isGroup = false;
        if (!isUndefined(type) && !isUndefined(field)) {
            if (field.type === DatasetFieldType.GROUP) {
                return (<GroupContent field={field as GroupDatasetField} groupIndex={index} groupId={id} getChildElement={getChildElement} />);
            }
            if (field.type === DatasetFieldType.MULTI_TOGGLE) {
                return (<MultiToggleContent field={field as GroupDatasetField} groupIndex={index} groupId={id} getChildElement={getChildElement} />);
            }
        }
        if (isUndefined(field)) {
            field = (fields.find(field => field.id === type) as GroupDatasetField)?.children.find(childField => childField.id === id);
            groupIndex = indexOf(type, topLevelList.map(({ id }) => id));
            isGroup = true;
        }
        if (field) {
            return (<Content field={field as SingleDatasetField} index={index} isGroup={isGroup} groupIndex={groupIndex} />);
        }
        return null;
    }, [fields, topLevelList]);

    return (
        <ListContext.Provider value={contextValue}>
            <div className={styles.sectionWrapper} data-testid='form-dataset-builder-section-wrapper' style={{ height: `${droppableHeight}px` }}>
                <Scrollable>
                    {topLevelList.map((item, index) => (
                        <Draggable
                            key={item.id}
                            item={item}
                            index={index}
                            getChildElement={getChildElement}
                        />
                    ))}
                </Scrollable>
            </div>
        </ListContext.Provider>
    );
};
