import { RawDraftContentState } from 'react-draft-wysiwyg';

import { BasicPlaybook, LinkedPlaybookProvision, PlaybookDB, PlaybookDeviation, PlaybookHistory, PlaybookMessage, PlaybookProvision, PlaybookQueryDB, PlaybookSmartSearch, PlaybookView, ReadOnlyContentType, SuggestedChangesDB } from '../../admin/playbook/store';

export enum PlaybookActionTypes {
    GET_ALL_PLAYBOOKS_STARTED = 'GET_ALL_USER_PLAYBOOKS_STARTED',
    GET_ALL_PLAYBOOKS_SUCCESSFUL = 'GET_ALL_USER_PLAYBOOKS_SUCCESSFUL',
    GET_ALL_PLAYBOOKS_FAILED = 'GET_ALL_USER_PLAYBOOKS_FAILED',
    TOGGLE_PLAYBOOK_COVER_SELECTED = 'TOGGLE_USER_PLAYBOOK_COVER_SELECTED',
    GET_USER_PLAYBOOK_BY_ID_STARTED = 'GET_USER_PLAYBOOK_BY_ID_STARTED',
    GET_USER_PLAYBOOK_BY_ID_SUCCESSFUL = 'GET_USER_PLAYBOOK_BY_ID_SUCCESSFUL',
    GET_USER_PLAYBOOK_BY_ID_FAILED = 'GET_USER_PLAYBOOK_BY_ID_FAILED',
    GET_USER_PLAYBOOK_BY_DEFINITION_ID_STARTED = 'GET_USER_PLAYBOOK_BY_DEFINITION_ID_STARTED',
    GET_USER_PLAYBOOK_BY_DEFINITION_ID_SUCCESSFUL = 'GET_USER_PLAYBOOK_BY_DEFINITION_ID_SUCCESSFUL',
    GET_USER_PLAYBOOK_BY_DEFINITION_ID_FAILED = 'GET_USER_PLAYBOOK_BY_DEFINITION_ID_FAILED',
    REDIRECT_USER_PLAYBOOK = 'REDIRECT_USER_PLAYBOOK',
    CHANGE_USER_PLAYBOOK_CURRENT_VIEW = 'CHANGE_USER_PLAYBOOK_CURRENT_VIEW',
    TOGGLE_PLAYBOOK_QUERY_MODAL_OPEN = 'TOGGLE_PLAYBOOK_QUERY_MODAL_OPEN',
    TOGGLE_PLAYBOOK_SUGGESTED_CHANGES_MODAL_OPEN = 'TOGGLE_PLAYBOOK_SUGGESTED_CHANGES_MODAL_OPEN',
    UPDATE_USER_PLAYBOOK_QUERY = 'UPDATE_USER_PLAYBOOK_QUERY',
    SEND_QUERY_STARTED = 'SEND_QUERY_STARTED',
    SEND_QUERY_SUCCESSFUL = 'SEND_QUERY_SUCCESSFUL',
    SEND_QUERY_FAILED = 'SEND_QUERY_FAILED',
    TOGGLE_QUERY_READY = 'TOGGLE_QUERY_READY',
    SET_SELECTED_QUERY = 'SET_SELECTED_QUERY',
    SEND_QUERY_REMINDER_STARTED = 'SEND_QUERY_REMINDER_STARTED',
    SEND_QUERY_REMINDER_SUCCESSFUL = 'SEND_QUERY_REMINDER_SUCCESSFUL',
    SEND_QUERY_REMINDER_FAILED = 'SEND_QUERY_REMINDER_FAILED',
    UPDATE_PLAYBOOK_SUGGESTED_CHANGES = 'UPDATE_PLAYBOOK_SUGGESTED_CHANGES',
    UPDATE_ADDITIONAL_USER_QUERY = 'UPDATE_ADDITIONAL_USER_QUERY',
    SEND_ADDITIONAL_USER_QUERY_STARTED = 'SEND_ADDITIONAL_USER_QUERY_STARTED',
    SEND_ADDITIONAL_USER_QUERY_SUCCESSFUL = 'SEND_ADDITIONAL_USER_QUERY_SUCCESSFUL',
    SEND_ADDITIONAL_USER_QUERY_FAILED = 'SEND_ADDITIONAL_USER_QUERY_FAILED',
    SET_SUGGESTED_CHANGES_UPDATED = 'SET_SUGGESTED_CHANGES_UPDATED',
    SET_SHOW_CONFIRMATION_MODAL = 'SET_SHOW_CONFIRMATION_MODAL',
    SEND_SUGGESTED_CHANGES_STARTED = 'SEND_SUGGESTED_CHANGES_STARTED',
    SEND_SUGGESTED_CHANGES_SUCCESSFUL = 'SEND_SUGGESTED_CHANGES_SUCCESSFUL',
    SEND_SUGGESTED_CHANGES_FAILED = 'SEND_SUGGESTED_CHANGES_FAILED',
    ADD_SUGGESTED_CHANGES_DEVIATION_ROW = 'ADD_SUGGESTED_CHANGES_DEVIATION_ROW',
    REMOVE_SUGGESTED_CHANGES_DEVIATION_ROW = 'REMOVE_SUGGESTED_CHANGES_DEVIATION_ROW',
    UPDATE_SUGGESTED_CHANGES_DEVIATION_VALUE = 'UPDATE_SUGGESTED_CHANGES_DEVIATION_VALUE',
    TOGGLE_USER_SECTION_DEVIATION = 'TOGGLE_USER_SECTION_DEVIATION',
    CLOSE_ALL_USER_DEVIATIONS = 'CLOSE_ALL_USER_DEVIATIONS',
    SET_SELECTED_SUGGESTED_CHANGE = 'SET_SELECTED_SUGGESTED_CHANGE',
    UPDATE_PLAYBOOK_SUGGESTED_CHANGED_CONTENT = 'UPDATE_PLAYBOOK_SUGGESTED_CHANGED_CONTENT',
    UPDATE_USER_SUGGESTED_CHANGES_NEW_MESSAGE = 'UPDATE_USER_SUGGESTED_CHANGES_NEW_MESSAGE',
    UPDATE_READ_ONLY_SUGGESTED_CHANGES_CONTENT = 'UPDATE_READ_ONLY_SUGGESTED_CHANGES_CONTENT',
    UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED = 'UPDATE_USER_SUGGESTED_CHANGES_CONVERSATION_STARTED',
    UPDATE_SUGGESTED_CHANGES_CONVERSATION_SUCCESSFUL = 'UPDATE_USER_SUGGESTED_CHANGES_CONVERSATION_SUCCESSFUL',
    UPDATE_SUGGESTED_CHANGES_CONVERSATION_FAILED = 'UPDATE_USER_SUGGESTED_CHANGES_CONVERSATION_FAILED',
    UPDATE_PLAYBOOK_SIMPLE_SEARCH = 'UPDATE_PLAYBOOK_SIMPLE_SEARCH',
    RESET_PLAYBOOK_SEARCH = 'RESET_PLAYBOOK_SEARCH',
    UPDATE_PLAYBOOK_SMART_SEARCH = 'UPDATE_PLAYBOOK_SMART_SEARCH',
    PLAYBOOK_SMART_SEARCH_STARTED = 'PLAYBOOK_SMART_SEARCH_STARTED',
    PLAYBOOK_SMART_SEARCH_SUCCESSFUL = 'PLAYBOOK_SMART_SEARCH_SUCCESSFUL',
    PLAYBOOK_SMART_SEARCH_FAILED = 'PLAYBOOK_SMART_SEARCH_FAILED',
    RESET_PLAYBOOK_SMART_SEARCH = 'RESET_PLAYBOOK_SMART_SEARCH',
    TOGGLE_USER_LINKED_PROVISION_MODAL = 'TOGGLE_USER_LINKED_PROVISION_MODAL',
    OPEN_USER_PROVISION_LINK = 'OPEN_USER_PROVISION_LINK',
    USER_PLAYBOOK_BACK_CALLED = 'USER_PLAYBOOK_BACK_CALLED',
    UPDATE_USER_LINKED_PROVISION_HISTORY = 'UPDATE_USER_LINKED_PROVISION_HISTORY',
    TOGGLE_USER_PROVISION_LINK_HISTORY_MODAL = 'TOGGLE_USER_PROVISION_LINK_HISTORY_MODAL'
}

export interface PlaybookQuery {
    playbookQueryId?: number;
    playbookId: number;
    playbookDefinitionId: number;
    queryConversation: PlaybookMessage[];
    sectionId: string;
    playbookName: string;
    resolved: number;
    version?: string;
    raisedBy?: number;
    lastReminderSent?: string;
}

export enum PlaybookContentType {
    ABSTRACT = 'abstract',
    SECTION = 'section'
}

export interface ChangedContent {
    provisionChanges: Array<keyof PlaybookProvision>;
    deviationChanges: DeviationSuggestedChange[];
}

export interface DeviationSuggestedChange {
    rowId: string;
    content: Array<keyof PlaybookDeviation>;
}

export interface AbstractSuggestedChanges {
    type: PlaybookContentType.ABSTRACT;
    content: RawDraftContentState | null;
    name?: string;
    changedContent: ChangedContent;
}

export interface SectionSuggestedChanges {
    type: PlaybookContentType.SECTION;
    content: PlaybookProvision;
    name?: string;
    changedContent: ChangedContent;
}

export type SuggestedChanges = AbstractSuggestedChanges | SectionSuggestedChanges;

export interface PlaybookState {
    allPlaybooks: BasicPlaybook[];
    playbookCoverSelected: BasicPlaybook | null;
    currentView: PlaybookView;
    playbookHistory: PlaybookHistory[];
    playbook: PlaybookDB | null;
    querySectionId: string | null;
    query: PlaybookQuery | null;
    queryReady: boolean;
    sendingQuery: boolean;
    queries: PlaybookQueryDB[];
    suggestedChanges: SuggestedChanges | null;
    additionalQuery: PlaybookMessage | null;
    suggestedChangesMessage: PlaybookMessage | null;
    suggestedChangesMessageUpdated: boolean;
    suggestedChangesUpdated: boolean;
    showConfirmationModal: boolean;
    sendingSuggestion: boolean;
    playbookSuggestedChanges: SuggestedChangesDB[];
    openDeviations: string[];
    playbookSuggestedChange: SuggestedChangesDB | null;
    readOnlySuggestedChangesContent: ReadOnlyContentType;
    sendingMessage: boolean;
    isLoading: boolean;
    simpleSearchTerm: string;
    smartSearchTerm: PlaybookSmartSearch[];
    linkedProvisionModalOpen: string | null;
    provisionLinkHistory: LinkedPlaybookProvision[];
    provisionLinkHistoryModalOpen: boolean;
}
