import React from 'react';

import { Pencil } from '../icons';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { ModalHeader } from '../modal/ModalHeader';
import styles from './ClauseModal.module.scss';

interface ClauseUnsavedChangesConfirmationModalProps {
    isOpen: boolean;
    close: () => void;
    confirm: () => void;
}

export const ClauseUnsavedChangesConfirmationModal: React.FC<ClauseUnsavedChangesConfirmationModalProps> = ({ isOpen, close, confirm }) => (
    <ConfirmationModal
        isOpen={isOpen}
        closeModal={close}
        confirm={confirm}
        confirmLabel='Confirm'
        testId='unsaved-clause-changes'
    >
        <div className={styles.confirmationModalWrapper}>
            <ModalHeader
                testId='unsaved-clause-changes'
                label='Clause Library'
                icon={Pencil}
            />
            <div className={styles.confirmationModalHeader} data-testid='unsaved-clause-changes-title'>Are you sure you want to close?</div>
            <div className={styles.confirmationModalSubheader} data-testid='unsaved-clause-changes-warning'>Any unsaved changes will be lost</div>
        </div>
    </ConfirmationModal>
);
