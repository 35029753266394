import React from 'react';
import { isUndefined } from 'lodash/fp';

import { TooltipList } from '../tooltip';
import styles from './Table.module.scss';
import { Icon } from '../icon/Icon';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const TooltipListCell: React.FC<{ valueFormatted?: string[] | null; value: any; icon?: React.FC }> = ({ valueFormatted, value, icon }) => {
    if (isUndefined(valueFormatted) && isUndefined(value)) {
        return null;
    }

    const displayValue = valueFormatted || value;

    return (
        <div className={styles.tooltipCellWrapper}>
            <TooltipList overlayText={displayValue} placement='right' trigger='click'>
                {icon ? <div className={styles.tooltipIcon}><Icon icon={icon} /></div> : <div className={styles.tooltipValue}>{displayValue.length}</div>}
            </TooltipList>
        </div>
    );
};
