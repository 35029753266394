import React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { getOr } from 'lodash/fp';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { customStyles, DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { ConfirmationModal } from '../../../../shared/modal/ConfirmationModal';
import { EntityType } from '../../../entity/store';
import styles from '../DatasetComponents.module.scss';
import { SettingsModal, updateSettingsValue } from '../../store';

interface LinkfieldEntityTypeProps {
    isOpen: boolean;
    closeModal: () => void;
    settingsModalIndex: SettingsModal;
    value?: string | null | undefined;
}

export const LinkfieldEntityTypeModal: React.FC<LinkfieldEntityTypeProps> = ({ isOpen, closeModal, settingsModalIndex, value = EntityType.COMPANY }) => {
    const dispatch = useAppDispatch();

    const options: DropdownOption[] = [
        {
            value: EntityType.COMPANY,
            label: 'Company'
        },
        {
            value: EntityType.PERSON,
            label: 'Person'
        }
    ];

    const { index, groupIndex } = settingsModalIndex;

    const entityTypeValue = value ? options.filter(option => option.value === value) : null;

    const selectEntityType = (selected: SingleValue<DropdownOption> | MultiValue<DropdownOption> | null) => dispatch(updateSettingsValue('entityType', getOr(EntityType.COMPANY, 'value', selected), index!, groupIndex));

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            showConfirm={false}
            closeLabel='Close'
            testId='linkfield-entity-type-dropdown-list'
            showOverlay={false}
        >
            <div className={styles.settingsModalLinkWrapper} data-testid='linkfield-entity-type-wrapper'>
                <div className={styles.settingsModalLinkTitle}>Select Entity Type</div>
                <div className={styles.settingsModalLinkDropdown}>
                    <Select
                        classNamePrefix='ark-dropdown'
                        value={entityTypeValue}
                        onChange={selectEntityType}
                        options={options}
                        isClearable={false}
                        styles={customStyles}
                        fontWeight={500}
                    />
                </div>
            </div>
        </ConfirmationModal>
    );
};
