import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getIsEditing, getShowResetPassword, getShowSwitchAccount, User } from '../../auth/login/store';
import { User as UserIcon } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { OverflowTooltip } from '../../shared/tooltip';
import styles from '../Home.module.scss';
import { getMyProfileSection, MyProfileSection, setMyProfileSection } from '../store';
import { MyProfileGeneral } from './MyProfileGeneral';
import { MyProfileNotifications } from './MyProfileNotifications';
import { MyProfileHomeScreen } from './MyProfileHomeScreen';
import { MyProfileTableFilters } from './MyProfileTableFilters';
import { MyProfileMyPreferences } from './MyProfileMyPreferences';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { MyProfileButtons } from './MyProfileButtons';

const { french, primary, lightGrey } = styles;

interface MyProfileProps {
    isOpen: boolean;
    myProfile: User;
}

export const MyProfile: React.FC<MyProfileProps> = ({ isOpen, myProfile }) => {
    const dispatch = useAppDispatch();
    const profileSection = useAppSelector(getMyProfileSection);
    const showResetPassword = useAppSelector(getShowResetPassword);
    const showSwitchAccount = useAppSelector(getShowSwitchAccount);
    const isEditing = useAppSelector(getIsEditing);

    const testId = 'my-profile';

    const openSection = useMemo(() => {
        if (profileSection === MyProfileSection.NOTIFICATIONS) return <MyProfileNotifications />;
        if (profileSection === MyProfileSection.HOME_SCREEN) return <MyProfileHomeScreen />;
        if (profileSection === MyProfileSection.TABLE_FILTERS) return <MyProfileTableFilters />;
        if (profileSection === MyProfileSection.MY_PREFERENCES) return <MyProfileMyPreferences />;
        return <MyProfileGeneral user={myProfile} />;
    }, [profileSection, myProfile]);

    const myProfileSections = [MyProfileSection.GENERAL, MyProfileSection.NOTIFICATIONS, MyProfileSection.HOME_SCREEN, MyProfileSection.TABLE_FILTERS, MyProfileSection.MY_PREFERENCES];

    const sideMenuDisabled = useMemo(() => showResetPassword || showSwitchAccount || isEditing, [showResetPassword, showSwitchAccount, isEditing]);

    const sectionColour = useCallback((value: MyProfileSection) => {
        if (sideMenuDisabled) return lightGrey;
        return profileSection === value ? french : primary;
    }, [sideMenuDisabled, profileSection]);

    const selectSection = useCallback((section: MyProfileSection) => !sideMenuDisabled && dispatch(setMyProfileSection(section)), [dispatch, sideMenuDisabled]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.modal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 10 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className={styles.modalWrapper} data-testid={`${testId}-modal-wrapper`}>
                <ModalHeader icon={UserIcon} label='My Profile' testId={testId} />
                <div className={styles.myProfileWrapper} data-testid='create-entity-content-wrapper'>
                    <div className={styles.sideMenuWrapper}>
                        <Scrollable>
                            {myProfileSections.map((value, index) => (
                                <div key={index} className={styles.myProfileSection} style={{ color: sectionColour(value) }} onClick={() => selectSection(value as MyProfileSection)}>
                                    <OverflowTooltip overlayText={value} className={classnames(styles.sectionLabel, { [styles.sectionLabelDisabled]: sideMenuDisabled })} />
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                    <div className={styles.scrollableContentWrapper}>
                        {openSection}
                    </div>
                </div>
                <MyProfileButtons />
            </div>
        </Modal>
    );
};
