import React, { useMemo } from 'react';
import { Options } from 'react-select';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { fetchMyCompanyEntitiesStarted, fetchMyEntityGroupsStarted, getEntityIdsForGroup, getMyCompanyEntities, GroupEntity, GroupEntityContent, updateEntityContent, updateEntityValue, updateGroupEntityIds } from '../store';
import { Text } from '../../../shared/text/Text';
import styles from './EntityType.module.scss';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { LongText } from '../../../shared/longtext/LongText';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { DropdownSummary } from '../../../shared/dropdown/DropdownSummary';

interface GroupEntityFormProps {
    entity: GroupEntity;
}

export const GroupEntityForm: React.FC<GroupEntityFormProps> = ({ entity }) => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchMyCompanyEntitiesStarted(), fetchMyEntityGroupsStarted()]);
    const handleUpdateValue = (key: string, value: string | Date | null) => dispatch(updateEntityValue(key, value));
    const handleUpdateContent = (key: keyof GroupEntityContent, value: string | string[] | null) => dispatch(updateEntityContent(key, value));
    const { name, content: { description } } = entity;
    const myCompanyEntities = useAppSelector(getMyCompanyEntities);
    const myCompanyList = myCompanyEntities.length ? myCompanyEntities.map(({ name, entityId }) => ({ label: name, value: entityId.toString() })) : [];
    const groupEntityIds = useAppSelector(getEntityIdsForGroup);

    const updateDropdownOption = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option) && (option as Options<DropdownOption>).length > 0) {
            selected = (option as Options<DropdownOption>).map(({ value }) => value);
        }
        dispatch(updateGroupEntityIds(selected));
    };

    const selectedCompanyEntities = useMemo(() => {
        if (myCompanyEntities && groupEntityIds) {
            return myCompanyEntities.filter(({ entityId }) => groupEntityIds.includes(entityId.toString())).map(({ name, entityId }) => ({ label: name, value: entityId.toString() }));
        }
        return null;
    }, [myCompanyEntities, groupEntityIds]);

    return (
        <div className={styles.groupEntityContentWrapper} data-testid='create-entity-group-content-wrapper'>
            <Scrollable>
                <Text
                    label='Group Name'
                    testId='entity-group-name'
                    onChange={e => handleUpdateValue('name', e.target.value)}
                    value={name}
                    maxLength={128}
                    placeholder='Group Name...'
                />
                <LongText
                    onChange={e => handleUpdateContent('description', e)}
                    value={description}
                    label='Group Description'
                    placeholder='Group Description...'
                    width='calc(100% - 12px)'
                />
                <DropdownSummary
                    label='My Company Entities'
                    testId='entity-jurisdiction'
                    onChange={updateDropdownOption}
                    value={selectedCompanyEntities}
                    options={myCompanyList}
                    marginBottom='10px'
                    isClearable
                    isMulti
                />
            </Scrollable>
        </div>
    );
};
