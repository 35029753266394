import { isUndefined } from 'lodash/fp';
import { toast } from 'react-toastify';
import { all, call, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';

import { fetchAllClients, updateClient, createClient, deleteClient, fetchAvailableClientFeaturePermissions } from '../../../../services/client';
import { resetPassword } from '../../../../services/users';
import { deleteClientFailed, deleteClientStarted, deleteClientSuccessful, fetchAllClientsFailed, fetchAllClientsSuccessful, fetchClientFeaturePermissionsFailed, fetchClientFeaturePermissionsSuccessful, resetClientUserPassword, updateClientFailed, updateClientSuccessful } from './actions';
import { getSelectedClient } from './selectors';
import { Client, ClientActionTypes, ClientFeaturePermissionDB } from './types';

export function* attemptFetchAllClients() {
    try {
        const clients: Client[] = yield call(fetchAllClients);
        yield put(fetchAllClientsSuccessful(clients));
    } catch (e) {
        toast.error('Unable to fetch the latest list of clients. Please try again.');
        yield put(fetchAllClientsFailed((e as Error).message));
    }
}

function* fetchAllClientsWatcher() {
    yield takeEvery(ClientActionTypes.FETCH_ALL_CLIENTS_STARTED, attemptFetchAllClients);
}

export function* attemptUpdateClient() {
    try {
        const client: Client = yield select(getSelectedClient);
        const callToMake = isUndefined(client.clientId) ? createClient : updateClient;
        const clients: Client[] = yield call(callToMake, client);
        yield put(updateClientSuccessful(clients));
        toast(`${client.clientName} successfully ${client.clientId ? 'updated' : 'saved'}.`);
    } catch (e) {
        toast.error('Unable to save the details for this client. Please try again.');
        yield put(updateClientFailed((e as Error).message));
    }
}

function* updateClientWatcher() {
    yield takeLeading(ClientActionTypes.UPDATE_CLIENT_STARTED, attemptUpdateClient);
}

export function* attemptDeleteClient({ payload }: ReturnType<typeof deleteClientStarted>) {
    try {
        const { client: { clientId, clientName } } = payload;
        const deleteRequest = { clientId: clientId! };
        const allClients: Client[] = yield call(deleteClient, deleteRequest);
        yield put(deleteClientSuccessful(allClients));
        toast(`${clientName} successfully deleted.`);
    } catch (e) {
        toast.error('Unable to delete client. Please try again.');
        yield put(deleteClientFailed((e as Error).message));
    }
}

function* deleteClientWatcher() {
    yield takeLeading(ClientActionTypes.DELETE_CLIENT_STARTED, attemptDeleteClient);
}

export function* attemptFetchAvailableClientFeaturePermissions() {
    try {
        const permissions: ClientFeaturePermissionDB[] = yield call(fetchAvailableClientFeaturePermissions);
        yield put(fetchClientFeaturePermissionsSuccessful(permissions));
    } catch (e) {
        toast.error('Unable to fetch feature permissions.');
        yield put(fetchClientFeaturePermissionsFailed((e as Error).message));
    }
}

function* fetchAvailableClientFeaturePermissionsWatcher() {
    yield takeEvery(ClientActionTypes.FETCH_CLIENT_FEATURE_PERMISSIONS_STARTED, attemptFetchAvailableClientFeaturePermissions);
}

export function* attemptResetClientUserPassword({ payload }: ReturnType<typeof resetClientUserPassword>) {
    try {
        const { username } = payload;
        yield call(resetPassword, { username });
        toast(`Password reset link sent to ${username}.`);
    } catch (e) {
        toast.error('Unable to send password reset link. Please try again.');
    }
}

function* resetClientUserPasswordWatcher() {
    yield takeLeading(ClientActionTypes.RESET_CLIENT_USER_PASSWORD, attemptResetClientUserPassword);
}

export function* clientSaga() {
    yield all([
        fork(fetchAllClientsWatcher),
        fork(updateClientWatcher),
        fork(deleteClientWatcher),
        fork(fetchAvailableClientFeaturePermissionsWatcher),
        fork(resetClientUserPasswordWatcher),
    ]);
}
