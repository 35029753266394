import React from 'react';
import { DateHeaderProps } from 'react-big-calendar';

import styles from '../Workflow.module.scss';

export const WorkflowCalendarDateHeader = (({ label }: DateHeaderProps) => (
    <div className={styles.calendarDateHeaderWrapper}>
        {label}
    </div>
));
