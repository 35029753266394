import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { DocumentType } from '../../my-documents/store';

export enum SearchDocumentActionTypes {
    TOGGLE_SEARCH_DOCUMENTS_VIEW = 'TOGGLE_SEARCH_DOCUMENTS_VIEW',
    UPDATE_SIMPLE_SEARCH = 'UPDATE_SIMPLE_SEARCH',
    UPDATE_SMART_SEARCH = 'UPDATE_SMART_SEARCH',
    SEARCH_DOCUMENTS_STARTED = 'SEARCH_DOCUMENTS_STARTED',
    SEARCH_DOCUMENTS_SUCCESSFUL = 'SEARCH_DOCUMENTS_SUCCESSFUL',
    SEARCH_DOCUMENTS_FAILED = 'SEARCH_DOCUMENTS_FAILED',
    TOGGLE_DELETE_ORIGINAL_DOCUMENT_MODAL = 'TOGGLE_DELETE_ORIGINAL_DOCUMENT_MODAL',
    TOGGLE_DOCUMENT_PREVIEW_MODAL = 'TOGGLE_DOCUMENT_PREVIEW_MODAL',
    OPEN_DOCUMENT_PREVIEW_STARTED = 'OPEN_DOCUMENT_PREVIEW_STARTED',
    OPEN_DOCUMENT_PREVIEW_SUCCESSFUL = 'OPEN_DOCUMENT_PREVIEW_SUCCESSFUL',
    OPEN_DOCUMENT_PREVIEW_FAILED = 'OPEN_DOCUMENT_PREVIEW_FAILED',
    TOGGLE_SEARCH_FILTER_MODAL = 'TOGGLE_SEARCH_FILTER_MODAL',
    UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER',
    RESET_FILTERS = 'RESET_FILTERS',
    UPDATE_EXECUTED_DATE_FILTER = 'UPDATE_EXECUTED_DATE_FILTER',
    RESET_SEARCH = 'RESET_SEARCH',
    OPEN_INCOMPLETE_SEARCH_DOCUMENT = 'OPEN_INCOMPLETE_SEARCH_DOCUMENT'
}

export enum SearchDocumentsView {
    SIMPLE_SEARCH = 'simple search',
    SMART_SEARCH = 'smart search'
}

export enum PreExecutionFilterOption {
    INCLUDE = 'Include',
    EXCLUDE = 'Exclude',
    EXCLUSIVE = 'Exclusive/Only'
}

export const preExecutionOptions: DropdownOption[] = Object.values(PreExecutionFilterOption).map(value => ({ label: value, value }));

export interface DateFilter {
    to: string | null;
    from: string | null;
}

export interface SearchFilter {
    documentNameId: string[] | null;
    entityId: string[] | null;
    includePreExecution: PreExecutionFilterOption | null;
    date: DateFilter;
    isExclusiveEntityFilter: boolean;
    documentNameGroupId?: string[] | null;
}

export interface PageSearchResult {
    pageNumber: number;
    textSnippet: string;
}

export interface SearchResult {
    documentId: number;
    originalDocumentId: number | null;
    documentType: DocumentType;
    datasetId: number | null;
    datasetInstanceId?: number;
    entityAName: string | null;
    entityAMyCompany: number | null;
    entityAOnWatchlist: number | null;
    entityBName: string | null;
    entityBMyCompany: number | null;
    entityBOnWatchlist: number | null;
    documentDescription: string;
    documentName: string | null;
    location: string;
    mimeType: string;
    textSnippet: string[];
    linkedEntities: number;
    entityActingAsAgent: string | null;
}

export enum LogicalOperator {
    AND = 'AND',
    OR = 'OR'
}

export enum Condition {
    INCLUDES = 'INCLUDES',
    NOT_INCLUDES = 'DOES NOT INCLUDE'
}

export interface SmartSearch {
    logicalOperator: LogicalOperator;
    condition: Condition;
    searchTerm: string;
}

export interface SearchDocumentsState {
    searchDocumentsView: SearchDocumentsView.SIMPLE_SEARCH;
    filterModalOpen: boolean;
    simpleSearchTerm: string;
    smartSearchTerm: SmartSearch[];
    searchFilter: SearchFilter;
    isSearching: boolean;
    searchResults: SearchResult[] | null;
    searchResultsType: SearchDocumentsView | null;
    totalSearchPages: number;
    totalSearchResults: number;
    searchPage: number;
    searchError: string | null;
    isOpening: boolean;
    isLoading: boolean;
    documentPreviewModalOpen: boolean;
    documentPreviewUrl: null | string;
    documentPreviewResult: null | SearchResult;
    documentPreviewError: string | null;
}
