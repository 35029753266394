import { flow, isNull, set } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DATABASE_DATE_FORMAT, formatDate } from '../../../../utils/luxon';
import { CreateAttestationInstancesTable, AttestationManagerDeadlineCell } from '../../../shared/attestations';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { AttestationManager } from '../../../shared/icons';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { UserAnswerId, AttestationAnswers, AttestationQuestion, createAttestationStarted, getAllAttestationForms, NewAttestationInstances, updateNewAttestationFormId, initialAttestationInstance, InstancesToCreate, updateNewAttestationInstances } from '../store';
import styles from './AttestationManager.module.scss';

interface NewAttestationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    attestationInstances: NewAttestationInstances;
    testId?: string;
}

export const NewAttestationModal: React.FC<NewAttestationModalProps> = ({ isOpen, closeModal, attestationInstances, testId }) => {
    const dispatch = useAppDispatch();
    const createAttestation = () => dispatch(createAttestationStarted());
    const attestationForms = useAppSelector(getAllAttestationForms);

    const attestationFormOptions = useMemo(() => attestationForms.map(({ attestationFormId, name }) => ({ value: attestationFormId.toString(), label: name })), [attestationForms]);
    const attestationFormId = useMemo(() => attestationFormOptions.find(({ value }) => value === attestationInstances.attestationFormId?.toString()) || null, [attestationFormOptions, attestationInstances]);

    const updateAttestationInstances = useCallback((instancesToCreate: InstancesToCreate[]) => dispatch(updateNewAttestationInstances(instancesToCreate)), [dispatch]);

    const newAttestationInstance = useCallback((value: number) => {
        const getAnswer = (question: AttestationQuestion) => question.config.answers === AttestationAnswers.FREE_TEXT ? '' : null;
        const { attestations, userAnswers } = attestationForms.find(({ attestationFormId }) => attestationFormId === value)!;
        const attestationsWithValue = attestations.map(question => flow(
            set('answer', getAnswer(question)),
            set('userNotes', '')
        )(question));
        const userAnswerIds: UserAnswerId[] = userAnswers.map(({ userAnswerId, label }) => ({ userAnswerId, userId: null, label }));
        return { attestationsWithValue, userAnswerIds };
    }, [attestationForms]);

    const updateAttestationForm = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: number | null = null;
        if (!isNull(dropdownValue)) {
            value = parseInt((dropdownValue as DropdownOption).value);
            const { attestationsWithValue, userAnswerIds } = newAttestationInstance(value);
            dispatch(updateNewAttestationFormId(value, userAnswerIds, attestationsWithValue));
        }
    }, [dispatch, newAttestationInstance]);

    const addAttestationInstance = useCallback(() => {
        const { attestationsWithValue, userAnswerIds } = newAttestationInstance(attestationInstances.attestationFormId!);
        const updatedInstances = [...attestationInstances.instancesToCreate, initialAttestationInstance(userAnswerIds, attestationsWithValue)];
        updateAttestationInstances(updatedInstances);
    }, [attestationInstances.instancesToCreate, attestationInstances.attestationFormId, updateAttestationInstances, newAttestationInstance]);

    const updateUserAnswerId = useCallback((index: number, userAnswerId: string, dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let userId: null | number = null;
        if (!isNull(dropdownValue)) {
            userId = parseInt((dropdownValue as DropdownOption).value);
        }
        const updatedInstances = attestationInstances.instancesToCreate.map((instance, i) => i === index ? { ...instance, userIds: instance.userIds.map(user => user.userAnswerId === userAnswerId ? { ...user, userId } : user) } : instance);
        updateAttestationInstances(updatedInstances);
    }, [attestationInstances.instancesToCreate, updateAttestationInstances]);

    const removeAttestationInstance = useCallback((index: number) => {
        const updatedInstances = attestationInstances.instancesToCreate.filter((_, i) => i !== index);
        updateAttestationInstances(updatedInstances);
    }, [attestationInstances.instancesToCreate, updateAttestationInstances]);

    const updateDeadline = useCallback((value: Date | null, index: number) => {
        const deadline = value ? formatDate(value, DATABASE_DATE_FORMAT) : null;
        const updatedInstances = attestationInstances.instancesToCreate.map((instance, i) => i === index ? { ...instance, deadline: deadline } : instance);
        updateAttestationInstances(updatedInstances);
    }, [attestationInstances.instancesToCreate, updateAttestationInstances]);

    const incompleteUserAnswers = attestationInstances.instancesToCreate.some(({ userIds }) => userIds.some(({ userId }) => isNull(userId)));
    const createDisabled = isNull(attestationInstances.attestationFormId) || incompleteUserAnswers;
    const createdDisabledTooltip = useMemo(() => {
        const disabledArray = [];
        if (isNull(attestationInstances.attestationFormId)) {
            disabledArray.push('You must select an attestation form');
        }
        if (incompleteUserAnswers) {
            disabledArray.push('You must assign a user to each user answer for attestation assignment');
        }
        return disabledArray;
    }, [attestationInstances, incompleteUserAnswers]);

    const getDeadline = useCallback((index: number) => attestationInstances.instancesToCreate[index].deadline, [attestationInstances.instancesToCreate]);

    const deadlineCell = useCallback((index: number) => (
        <AttestationManagerDeadlineCell index={index} deadline={getDeadline(index)} isEditing updateDeadline={updateDeadline} testId={testId} />
    ), [updateDeadline, getDeadline, testId]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={createAttestation}
            confirmLabel='Create'
            confirmDisabled={createDisabled}
            confirmDisabledTooltip={createdDisabledTooltip}
            testId={testId}
        >
            <div className={styles.newAttestationWrapper}>
                <ModalHeader label='Create New Attestation' icon={AttestationManager} testId={`${testId}-attestation-instances`} />
                <div className={styles.attestationFormSelect} data-testid={`${testId}-attestation-instances-form-wrapper`}>
                    <div className={styles.attestationFormSelectLabel} data-testid={`${testId}-attestation-instances-form-label`}>Select Attestation Form</div>
                    <Dropdown
                        value={attestationFormId}
                        options={attestationFormOptions}
                        onChange={updateAttestationForm}
                        isClearable={false}
                        testId={`${testId}-attestation-instances-form`}
                    />
                </div>
                {!isNull(attestationInstances.attestationFormId) && !!attestationInstances.instancesToCreate.length &&
                    <CreateAttestationInstancesTable
                        testId={testId}
                        addAttestationInstance={addAttestationInstance}
                        updateUserAnswerId={updateUserAnswerId}
                        removeAttestationInstance={removeAttestationInstance}
                        instancesToCreate={attestationInstances.instancesToCreate}
                        isEditing
                        deadlineCell={deadlineCell}
                        deadlineWidth='200px'
                    />
                }
            </div>
        </ConfirmationModal>
    );
};
