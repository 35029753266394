import React from 'react';
import classnames from 'classnames';

import { TextProps } from './Text';
import styles from './Text.module.scss';

interface TextPropsWithIcon extends TextProps {
    iconRenderer?: JSX.Element | undefined;
}

export const TextWithIcon: React.FC<TextPropsWithIcon> = ({ label, testId, onChange, value, placeholder = '', width = '450px', maxLength, disabled = false, iconRenderer }) => (
    <div className={styles.inputWrapper}>
        <div data-testid={`${testId}-label`} className={styles.inputLabel}>{label}</div>
        <div className={classnames(styles.inputFieldWrapper, {
            [styles.disabled]: disabled
        })}>
            <input
                placeholder={placeholder}
                className={styles.textWithIconInput}
                data-testid={`${testId}-input-with-icon`}
                onChange={onChange}
                value={value}
                style={{ width }}
                maxLength={maxLength}
                disabled={disabled}
            />
            <div className={styles.iconPlacer}>
                {iconRenderer}
            </div>
        </div>
    </div>
);
