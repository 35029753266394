import { Reducer } from 'redux';
import { flow, isEqual, isNull, isNumber, set } from 'lodash/fp';
import { v4 } from 'uuid';

import { AdminPlaybookActionTypes, AdminPlaybookState, PlaybookPage, PlaybookBuilderTab, PlaybookSection, Playbook, PlaybookView, BuildPage, PlaybookDeviation, PlaybookPageView, SuggestedChangesDB, PlaybookSectionType, PlaybookProvision, PlaybookSubheader, ReadOnlyContentType, PlaybookLogicalOperator, PlaybookCondition, PlaybookSearchContentType, SystemAdminBookshelfView } from './types';
import { isSubheaderSection } from './typeAssertions';
import { LoginActionTypes } from '../../../auth/login/store';
import { formatDateTime } from '../../../../utils/luxon';

export const initialSection = (sectionId = v4(), rowId = v4()): PlaybookProvision => ({
    title: ['', ''],
    provisionTitle: '',
    provisionContent: null,
    backgroundTitle: '',
    backgroundContent: null,
    sectionId,
    relevantStakeholders: [],
    preferredTerms: null,
    type: PlaybookSectionType.PROVISION,
    deviations: [{ topic: '', variations: null, background: null, nid: '', rowId, approvalDate: null, validForInterval: null, validForIntervalValue: null }],
    linkedProvisions: []
});

export const initialPlaybook = (sectionId = v4(), rowId = v4()): Playbook => ({
    name: '',
    agreementType: null,
    agreementTypeId: null,
    documentNameIds: null,
    versionMajor: 1,
    versionMinor: 0,
    content: {
        abstract: null,
        sections: [initialSection(sectionId, rowId)]
    },
    defaultView: PlaybookView.LIST,
    tags: [],
    isSystemTemplate: 0,
    isDraft: 1
});

export const initialPlaybookSmartSearchTerm = {
    logicalOperator: PlaybookLogicalOperator.AND,
    condition: PlaybookCondition.INCLUDES,
    contentType: PlaybookSearchContentType.TITLE,
    searchTerm: ''
};

export const INITIAL_STATE = (sectionId = v4(), rowId = v4()): AdminPlaybookState => ({
    playbookPage: PlaybookPage.SELECT,
    buildPage: BuildPage.SELECT,
    selectedTab: PlaybookBuilderTab.GENERAL,
    playbook: initialPlaybook(sectionId, rowId),
    openSections: [sectionId],
    currentView: PlaybookView.LIST,
    tagToCreate: null,
    publishReason: '',
    publishError: null,
    currentPlaybook: null,
    allPlaybooks: [],
    allTemplates: [],
    canSave: false,
    playbookHistory: [],
    publishModalOpen: false,
    saveModalOpen: false,
    canNext: false,
    canPublish: false,
    versionUpdated: false,
    playbookCoverSelected: null,
    templateCoverSelected: null,
    versionMajorIncreased: false,
    queries: [],
    selectedQuery: null,
    queryResponse: null,
    sendingResponse: false,
    sendEmailReply: false,
    queryMarkedAsResolved: false,
    playbookSuggestedChanges: [],
    openDeviations: [],
    provisionsOrder: [],
    provisionsModalOpen: false,
    deviationsOrder: [],
    deviationsModalSectionId: null,
    openSuggestedChange: null,
    playbookPageView: PlaybookPageView.SPLIT,
    suggestionReviewModalOpen: false,
    suggestedChangesMessage: null,
    suggestedChangesMessageUpdated: false,
    sendingMessage: false,
    isLoading: false,
    resolvedPlaybookSuggestedChanges: [],
    resolvedSuggestedChange: null,
    resolvedSuggestedChangesContent: ReadOnlyContentType.CHANGES,
    simpleSearchTerm: '',
    templateSimpleSearchTerm: '',
    smartSearchTerm: [initialPlaybookSmartSearchTerm],
    availableProvisionLinks: [],
    provisionToLink: null,
    currentProvisionLinks: [],
    linkedProvisionModalOpen: false,
    updatedProvisionLinks: null,
    sectionWithLinksToDelete: null,
    readOnlyLinkedProvisionModalOpen: null,
    provisionLinkHistory: [],
    provisionLinkHistoryModalOpen: false,
    systemAdminBookshelfView: SystemAdminBookshelfView.ALL
});

const updatedOpenSections = (currentSections: PlaybookSection[], id: string, callback: (section: PlaybookSection) => PlaybookSection) => currentSections.map(section => (section.sectionId === id ? callback(section) : section));
const updateOpenDeviations = (currentDeviations: PlaybookDeviation[], rowId: string, callback: (deviation: PlaybookDeviation) => PlaybookDeviation) => currentDeviations.map(deviation => deviation.rowId === rowId ? callback(deviation) : deviation);
const getProvisionOrder = (sections: PlaybookSection[]) => sections.map(section => {
    const isSubheader = isSubheaderSection(section);
    let title: string | string[] = '';
    if (isSubheader) {
        title = section.title || 'Subheader not provided';
    } else {
        title = (section as PlaybookProvision).title.filter(item => !!item).join(': ') || 'Title not provided';
    }
    return { title, id: section.sectionId, isSubheader };
});
const updateSuggestedChanges = (suggestedChanges: SuggestedChangesDB[], playbookSuggestionId: number, callback: (suggestedChange: SuggestedChangesDB) => SuggestedChangesDB) => suggestedChanges.map(suggestedChange => suggestedChange.playbookSuggestionId === playbookSuggestionId ? callback(suggestedChange) : suggestedChange);

export const adminPlaybookReducer: Reducer<AdminPlaybookState> = (state = INITIAL_STATE(), { payload, type }): AdminPlaybookState => {
    switch (type) {
        case AdminPlaybookActionTypes.SELECT_PLAYBOOK_PAGE:
            return set('playbookPage', payload, state);
        case AdminPlaybookActionTypes.SELECT_BUILD_PAGE:
            return set('buildPage', payload, state);
        case AdminPlaybookActionTypes.SELECT_CREATE_PLAYBOOK_TAB:
            return flow(
                set('selectedTab', payload),
                set('openDeviations', [])
            )(state);
        case AdminPlaybookActionTypes.UPDATE_PLAYBOOK_VALUE: {
            const { key, value } = payload;
            if (key === 'defaultView') {
                return flow(
                    set(`playbook[${key}]`, value),
                    set('currentView', value)
                )(state);
            }
            return set(`playbook[${key}]`, value, state);
        }
        case AdminPlaybookActionTypes.UPDATE_PLAYBOOK_CONTENT_VALUE: {
            const { key, value } = payload;
            return set(`playbook.content[${key}]`, value, state);
        }
        case AdminPlaybookActionTypes.UPDATE_PLAYBOOK_TAGS:
            return set('playbook.tags', payload, state);
        case AdminPlaybookActionTypes.ADD_PLAYBOOK_SECTION: {
            const sectionId = v4();
            const rowId = v4();
            const newSection = initialSection(sectionId, rowId);
            const sections = [...state.playbook.content.sections, newSection];
            const openSections = [...state.openSections, sectionId];
            const provisionsOrder = getProvisionOrder(sections);
            return flow(
                set('playbook.content.sections', sections),
                set('openSections', openSections),
                set('provisionsOrder', provisionsOrder)
            )(state);
        }
        case AdminPlaybookActionTypes.ADD_PLAYBOOK_SUBHEADER: {
            const sectionId = v4();
            const newSubheader: PlaybookSubheader = { sectionId, type: PlaybookSectionType.SUBHEADER, title: '' };
            const sections = [...state.playbook.content.sections, newSubheader];
            const provisionsOrder = getProvisionOrder(sections);
            return flow(
                set('playbook.content.sections', sections),
                set('provisionsOrder', provisionsOrder)
            )(state);
        }
        case AdminPlaybookActionTypes.REMOVE_PLAYBOOK_SECTION: {
            const playbookSections = state.playbook.content.sections.filter(({ sectionId }) => !isEqual(sectionId, payload));
            const openSections = state.openSections.filter(sectionId => !isEqual(sectionId, payload));
            const provisionsOrder = getProvisionOrder(playbookSections);
            return flow(
                set('playbook.content.sections', playbookSections),
                set('openSections', openSections),
                set('provisionsOrder', provisionsOrder)
            )(state);
        }
        case AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_SECTION: {
            const shouldCloseSection = state.openSections.find(sectionId => isEqual(sectionId, payload));
            const updatedOpenSections = shouldCloseSection ? state.openSections.filter(sectionId => !isEqual(sectionId, payload)) : [...state.openSections, payload];
            const updateOpenDeviations = shouldCloseSection ? state.openDeviations.filter(sectionId => !isEqual(sectionId, payload)) : [...state.openDeviations];
            return flow(
                set('openSections', updatedOpenSections),
                set('openDeviations', updateOpenDeviations)
            )(state);
        }
        case AdminPlaybookActionTypes.TOGGLE_SECTION_DEVIATION: {
            const shouldCloseDeviation = state.openDeviations.find(sectionId => isEqual(sectionId, payload));
            const updatedOpenDeviations = shouldCloseDeviation ? state.openDeviations.filter(sectionId => !isEqual(sectionId, payload)) : [...state.openDeviations, payload];
            return set('openDeviations', updatedOpenDeviations, state);
        }
        case AdminPlaybookActionTypes.UPDATE_PLAYBOOK_PROVISION: {
            const { sectionId, key, value } = payload;
            const updatedSections = updatedOpenSections(state.playbook.content.sections, sectionId, set(`${key}`, value));
            if (key === 'title') {
                const provisionsOrder = getProvisionOrder(updatedSections);
                return flow(
                    set('playbook.content.sections', updatedSections),
                    set('provisionsOrder', provisionsOrder)
                )(state);
            }
            return set('playbook.content.sections', updatedSections, state);
        }
        case AdminPlaybookActionTypes.SAVE_PLAYBOOK_LINKED_PROVISIONS: {
            const updatedSections = updatedOpenSections(state.playbook.content.sections, state.provisionToLink!, set('linkedProvisions', state.currentProvisionLinks));
            return set('playbook.content.sections', updatedSections, state);
        }
        case AdminPlaybookActionTypes.UPDATE_CURRENT_LINKED_PROVISIONS: {
            const alreadyExists = state.currentProvisionLinks.some(({ sectionId }) => sectionId === payload.sectionId);
            if (alreadyExists) {
                const currentProvisionLinks = state.currentProvisionLinks.filter(({ sectionId }) => sectionId !== payload.sectionId);
                return set('currentProvisionLinks', currentProvisionLinks, state);
            }
            const currentProvisionLinks = [...state.currentProvisionLinks, payload];
            return set('currentProvisionLinks', currentProvisionLinks, state);
        }
        case AdminPlaybookActionTypes.UPDATE_PLAYBOOK_SUBHEADER: {
            const { sectionId, value } = payload;
            const updatedSections = updatedOpenSections(state.playbook.content.sections, sectionId, set('title', value));
            const provisionsOrder = getProvisionOrder(updatedSections);
            return flow(
                set('playbook.content.sections', updatedSections),
                set('provisionsOrder', provisionsOrder)
            )(state);
        }
        case AdminPlaybookActionTypes.COLLAPSE_ALL_SECTIONS:
            return flow(
                set('openSections', []),
                set('openDeviations', [])
            )(state);
        case AdminPlaybookActionTypes.CHANGE_PLAYBOOK_CURRENT_VIEW:
            return set('currentView', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_CREATE_TAG_MODAL:
            return set('tagToCreate', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_IS_DRAFT: {
            const isDraft = state.playbook.isDraft ? 0 : 1;
            return set('playbook.isDraft', isDraft, state);
        }
        case AdminPlaybookActionTypes.PUBLISH_PLAYBOOK_SUCCESSFUL: {
            const { allPlaybooks, playbook, latestPlaybook: { currentPlaybook, playbookHistory, playbookQueries, playbookSuggestedChanges, resolvedPlaybookSuggestedChanges } } = payload;
            return flow(
                set('allPlaybooks', allPlaybooks),
                set('playbook', playbook),
                set('currentPlaybook', currentPlaybook),
                set('playbookHistory', playbookHistory),
                set('queries', playbookQueries),
                set('playbookSuggestedChanges', playbookSuggestedChanges),
                set('publishModalOpen', false),
                set('saveModalOpen', false),
                set('publishReason', ''),
                set('canSave', false),
                set('canPublish', false),
                set('canNext', true),
                set('openSuggestedChange', null),
                set('resolvedPlaybookSuggestedChanges', resolvedPlaybookSuggestedChanges)
            )(state);
        }
        case AdminPlaybookActionTypes.SAVE_PLAYBOOK_SUCCESSFUL: {
            const { allPlaybooks, playbook, latestPlaybook: { currentPlaybook, playbookHistory, playbookQueries, playbookSuggestedChanges, resolvedPlaybookSuggestedChanges } } = payload;
            return flow(
                set('allPlaybooks', allPlaybooks),
                set('playbook', playbook),
                set('currentPlaybook', currentPlaybook),
                set('playbookHistory', playbookHistory),
                set('queries', playbookQueries),
                set('playbookSuggestedChanges', playbookSuggestedChanges),
                set('publishModalOpen', false),
                set('saveModalOpen', false),
                set('publishReason', ''),
                set('canSave', false),
                set('canPublish', false),
                set('canNext', true),
                set('openSuggestedChange', null),
                set('resolvedPlaybookSuggestedChanges', resolvedPlaybookSuggestedChanges)
            )(state);
        }
        case AdminPlaybookActionTypes.CONFIRM_RESOLVED_SUCCESSFUL:
            return flow(
                set('playbookSuggestedChanges', payload),
                set('openSuggestedChange', null)
            )(state);
        case AdminPlaybookActionTypes.GET_ALL_PLAYBOOKS_STARTED:
            return set('isLoading', true, state);
        case AdminPlaybookActionTypes.GET_ALL_PLAYBOOKS_SUCCESSFUL:
            return flow(
                set('allPlaybooks', payload),
                set('isLoading', false)
            )(state);
        case AdminPlaybookActionTypes.GET_ALL_PLAYBOOKS_FAILED:
            return set('isLoading', false, state);
        case AdminPlaybookActionTypes.FETCH_ALL_PLAYBOOK_TEMPLATES_SUCCESSFUL:
            return set('allTemplates', payload, state);
        case AdminPlaybookActionTypes.PUBLISH_PLAYBOOK_FAILED:
            return set('publishError', payload, state);
        case AdminPlaybookActionTypes.GET_PLAYBOOK_BY_ID_SUCCESSFUL: {
            const { playbook, latestPlaybook: { currentPlaybook, playbookHistory, playbookQueries, playbookSuggestedChanges, resolvedPlaybookSuggestedChanges } } = payload;
            const provisionsOrder = getProvisionOrder(playbook.content.sections);
            return flow(
                set('playbook', playbook),
                set('currentView', playbook.defaultView),
                set('currentPlaybook', currentPlaybook),
                set('playbookHistory', playbookHistory),
                set('queries', playbookQueries),
                set('playbookSuggestedChanges', playbookSuggestedChanges),
                set('canNext', true),
                set('provisionsOrder', provisionsOrder),
                set('resolvedPlaybookSuggestedChanges', resolvedPlaybookSuggestedChanges)
            )(state);
        }
        case AdminPlaybookActionTypes.GET_PLAYBOOK_TEMPLATE_SUCCESSFUL: {
            const provisionsOrder = getProvisionOrder(payload.content.sections);
            return flow(
                set('playbook', payload),
                set('currentView', payload.defaultView),
                set('currentPlaybook', null),
                set('canNext', true),
                set('provisionsOrder', provisionsOrder)
            )(state);
        }
        case AdminPlaybookActionTypes.GET_PLAYBOOK_BY_DEFINITION_ID_STARTED:
            return set('isLoading', true, state);
        case AdminPlaybookActionTypes.GET_PLAYBOOK_BY_DEFINITION_ID_SUCCESSFUL: {
            const { playbook, latestPlaybook: { playbookInstance, playbookHistory, resolvedPlaybookSuggestedChanges } } = payload;
            return flow(
                set('playbook', playbook),
                set('currentView', playbook.defaultView),
                set('currentPlaybook', playbookInstance),
                set('playbookHistory', playbookHistory),
                set('openDeviations', []),
                set('isLoading', false),
                set('resolvedPlaybookSuggestedChanges', resolvedPlaybookSuggestedChanges)
            )(state);
        }
        case AdminPlaybookActionTypes.GET_PLAYBOOK_BY_DEFINITION_ID_FAILED:
            return set('isLoading', false, state);
        case AdminPlaybookActionTypes.SET_PLAYBOOK_CAN_SAVE:
            return set('canSave', payload, state);
        case AdminPlaybookActionTypes.SET_PLAYBOOK_PUBLISH_REASON:
            return set('publishReason', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_PUBLISH_MODAL_OPEN:
            return set('publishModalOpen', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_SAVE_MODAL_OPEN:
            return set('saveModalOpen', payload, state);
        case AdminPlaybookActionTypes.SET_PLAYBOOK_CAN_NEXT:
            return set('canNext', payload, state);
        case AdminPlaybookActionTypes.SET_PLAYBOOK_CAN_PUBLISH:
            return set('canPublish', payload, state);
        case AdminPlaybookActionTypes.SET_PLAYBOOK_VERSION_UPDATED:
            return set('versionUpdated', payload, state);
        case AdminPlaybookActionTypes.SET_PLAYBOOK_VERSION_MAJOR_INCREASED:
            return set('versionMajorIncreased', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_COVER_SELECTED:
            return set('playbookCoverSelected', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_TEMPLATE_COVER_SELECTED:
            return set('templateCoverSelected', payload, state);
        case AdminPlaybookActionTypes.UPDATE_EXISTING_PLAYBOOK:
            return flow(
                set('canSave', false),
                set('canPublish', !!payload.isDraft),
                set('canNext', true)
            )(state);
        case AdminPlaybookActionTypes.TOGGLE_ADMIN_QUERY_MODAL: {
            const selectedQuery = state.queries.find(({ playbookQueryId }) => playbookQueryId === payload) || null;
            if (isNull(selectedQuery)) {
                return flow(
                    set('selectedQuery', null),
                    set('queryResponse', null),
                    set('sendEmailReply', false),
                    set('queryMarkedAsResolved', false),
                )(state);
            }
            return set('selectedQuery', selectedQuery, state);
        }
        case AdminPlaybookActionTypes.UPDATE_ADMIN_QUERY_RESPONSE: {
            const { content, user: { userId, username } } = payload;
            const queryResponse = { content, userId, username, date: formatDateTime() };
            return set('queryResponse', queryResponse, state);
        }
        case AdminPlaybookActionTypes.SEND_ADMIN_QUERY_RESPONSE_STARTED:
            return set('sendingResponse', true, state);
        case AdminPlaybookActionTypes.SEND_ADMIN_QUERY_RESPONSE_SUCCESSFUL:
            return flow(
                set('queries', payload),
                set('selectedQuery', null),
                set('queryResponse', null),
                set('sendingResponse', false),
                set('sendEmailReply', false),
                set('queryMarkedAsResolved', false),
            )(state);
        case AdminPlaybookActionTypes.SEND_ADMIN_QUERY_RESPONSE_FAILED:
            return set('sendingResponse', false, state);
        case AdminPlaybookActionTypes.TOGGLE_ADMIN_SEND_EMAIL_REPLY:
            return set('sendEmailReply', !state.sendEmailReply, state);
        case AdminPlaybookActionTypes.TOGGLE_ADMIN_QUERY_RESOLVED:
            return set('queryMarkedAsResolved', !state.queryMarkedAsResolved, state);
        case AdminPlaybookActionTypes.ADD_DEVIATION_ROW: {
            const { sectionId, index } = payload;
            const newRow = { topic: '', variations: null, background: null, nid: '', rowId: v4(), approvalDate: null, validForInterval: null, validForIntervalValue: null };
            const currentDeviations = (state.playbook.content.sections.find(({ sectionId }) => sectionId === payload.sectionId) as PlaybookProvision).deviations;
            const deviations = [...currentDeviations];
            const spliceIndex = isNumber(index) ? index : deviations.length;
            deviations.splice(spliceIndex, 0, newRow);
            const updatedSections = updatedOpenSections(state.playbook.content.sections, sectionId, set('deviations', deviations));
            return set('playbook.content.sections', updatedSections, state);
        }
        case AdminPlaybookActionTypes.REMOVE_DEVIATION_ROW: {
            const { sectionId, rowId } = payload;
            const deviations = (state.playbook.content.sections.find(section => section.sectionId === sectionId) as PlaybookProvision).deviations.filter(row => row.rowId !== rowId);
            const updatedSections = updatedOpenSections(state.playbook.content.sections, sectionId, set('deviations', deviations));
            return set('playbook.content.sections', updatedSections, state);
        }
        case AdminPlaybookActionTypes.UPDATE_DEVIATION_VALUE: {
            const { sectionId, rowId, key, value } = payload;
            const currentDeviations = (state.playbook.content.sections.find(({ sectionId }) => sectionId === payload.sectionId) as PlaybookProvision).deviations;
            const updatedDeviations = updateOpenDeviations(currentDeviations, rowId, set(key as string, value));
            const updatedSections = updatedOpenSections(state.playbook.content.sections, sectionId, set('deviations', updatedDeviations));
            return set('playbook.content.sections', updatedSections, state);
        }
        case AdminPlaybookActionTypes.SET_PROVISIONS_ORDER:
            return set('provisionsOrder', payload, state);
        case AdminPlaybookActionTypes.SAVE_PROVISIONS_ORDER: {
            const currentProvisionOrder = state.provisionsOrder.map(({ id }) => id);
            const sections = [...state.playbook.content.sections];
            const newSectionOrder = sections.sort((a, b) => currentProvisionOrder.indexOf(a.sectionId) - currentProvisionOrder.indexOf(b.sectionId));
            return flow(
                set('playbook.content.sections', newSectionOrder),
                set('provisionsModalOpen', false)
            )(state);
        }
        case AdminPlaybookActionTypes.TOGGLE_PROVISIONS_ORDER_MODAL:
            return set('provisionsModalOpen', !state.provisionsModalOpen, state);
        case AdminPlaybookActionTypes.SET_DEVIATIONS_ORDER:
            return set('deviationsOrder', payload, state);
        case AdminPlaybookActionTypes.SAVE_DEVIATIONS_ORDER: {
            const currentDeviationOrder = state.deviationsOrder.map(({ id }) => id);
            const currentDeviations = (state.playbook.content.sections.find(({ sectionId }) => sectionId === state.deviationsModalSectionId!) as PlaybookProvision).deviations;
            const newDeviationOrder = currentDeviations.sort((a, b) => currentDeviationOrder.indexOf(a.rowId) - currentDeviationOrder.indexOf(b.rowId));
            const updatedSections = updatedOpenSections(state.playbook.content.sections, state.deviationsModalSectionId!, set('deviations', newDeviationOrder));
            return flow(
                set('playbook.content.sections', updatedSections),
                set('deviationsModalSectionId', null)
            )(state);
        }
        case AdminPlaybookActionTypes.SET_DEVIATIONS_ORDER_MODAL:
            return set('deviationsModalSectionId', payload, state);
        case AdminPlaybookActionTypes.SET_SUGGESTED_CHANGE_OPEN: {
            const closeSuggestedChange = isEqual(state.openSuggestedChange, payload);
            if (closeSuggestedChange) {
                return set('openSuggestedChange', null, state);
            }
            return flow(
                set('openSuggestedChange', payload),
                set('openDeviations', [])
            )(state);
        }
        case AdminPlaybookActionTypes.BACK_TO_OPEN_PLAYBOOK:
            return flow(
                set('openSuggestedChange', null),
                set('openDeviations', [])
            )(state);
        case AdminPlaybookActionTypes.SET_PLAYBOOK_PAGE_VIEW:
            return set('playbookPageView', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_OUTSTANDING_SUGGESTED_CHANGE: {
            const currentOutstandingValue = state.playbookSuggestedChanges.find(suggestedChange => suggestedChange.playbookSuggestionId === payload)!.outstanding;
            const value = currentOutstandingValue === 1 ? 0 : 1;
            const updatedSuggestedChanges = updateSuggestedChanges(state.playbookSuggestedChanges, payload, set('outstanding', value));
            return set('playbookSuggestedChanges', updatedSuggestedChanges, state);
        }
        case AdminPlaybookActionTypes.RESET_PLAYBOOK_BUILD:
            return { ...INITIAL_STATE(), playbookPage: state.playbookPage, allPlaybooks: state.allPlaybooks };
        case AdminPlaybookActionTypes.RESET_PLAYBOOK_STATE:
        case AdminPlaybookActionTypes.TOGGLE_SUGGESTION_REVIEW_MODAL_OPEN:
            return set('suggestionReviewModalOpen', !state.suggestionReviewModalOpen, state);
        case AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_NEW_MESSAGE: {
            const { content, user: { userId, username } } = payload;
            if (isNull(state.suggestedChangesMessage)) {
                const newMessage = { content, userId, username, date: formatDateTime() };
                return flow(
                    set('suggestedChangesMessage', newMessage),
                    set('suggestedChangesMessageUpdated', !isNull(content))
                )(state);
            }
            return flow(
                set('suggestedChangesMessage.content', content),
                set('suggestedChangesMessageUpdated', !isNull(content))
            )(state);
        }
        case AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED:
            return set('sendingMessage', true, state);
        case AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_SUCCESSFUL:
            return flow(
                set('playbookSuggestedChanges', payload),
                set('sendingMessage', false),
                set('suggestedChangesMessage', null),
                set('suggestedChangesMessageUpdated', false)
            )(state);
        case AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_FAILED:
            return set('sendingMessage', false, state);
        case AdminPlaybookActionTypes.SET_RESOLVED_SUGGESTED_CHANGE: {
            const selectedSuggestedChange = state.resolvedPlaybookSuggestedChanges.find(({ playbookSuggestionId }) => playbookSuggestionId === payload) || null;
            if (isNull(selectedSuggestedChange)) {
                return flow(
                    set('resolvedSuggestedChange', null),
                    set('resolvedSuggestedChangesContent', ReadOnlyContentType.CHANGES)
                )(state);
            }
            return set('resolvedSuggestedChange', selectedSuggestedChange, state);
        }
        case AdminPlaybookActionTypes.TOGGLE_RESOLVED_SUGGESTED_CHANGES_CONTENT:
            return set('resolvedSuggestedChangesContent', payload, state);
        case AdminPlaybookActionTypes.UPDATE_ADMIN_PLAYBOOK_SIMPLE_SEARCH:
            return set('simpleSearchTerm', payload.toLowerCase(), state);
        case AdminPlaybookActionTypes.RESET_ADMIN_PLAYBOOK_SEARCH:
            return set('simpleSearchTerm', '', state);
        case AdminPlaybookActionTypes.UPDATE_ADMIN_TEMPLATE_PLAYBOOK_SIMPLE_SEARCH:
            return set('templateSimpleSearchTerm', payload.toLowerCase(), state);
        case AdminPlaybookActionTypes.RESET_ADMIN_TEMPLATE_SEARCH:
            return set('templateSimpleSearchTerm', '', state);
        case AdminPlaybookActionTypes.UPDATE_ADMIN_PLAYBOOK_SMART_SEARCH:
            return set('smartSearchTerm', payload, state);
        case AdminPlaybookActionTypes.ADMIN_PLAYBOOK_SMART_SEARCH_STARTED:
            return set('isLoading', true, state);
        case AdminPlaybookActionTypes.ADMIN_PLAYBOOK_SMART_SEARCH_SUCCESSFUL:
            return flow(
                set('allPlaybooks', payload),
                set('isLoading', false)
            )(state);
        case AdminPlaybookActionTypes.ADMIN_PLAYBOOK_SMART_SEARCH_FAILED:
            return set('isLoading', false, state);
        case AdminPlaybookActionTypes.RESET_ADMIN_PLAYBOOK_SMART_SEARCH:
            return set('smartSearchTerm', [initialPlaybookSmartSearchTerm], state);
        case AdminPlaybookActionTypes.GET_AVAILABLE_PROVISION_LINKS_SUCCESSFUL:
            return set('availableProvisionLinks', payload, state);
        case AdminPlaybookActionTypes.GET_AVAILABLE_PROVISION_LINKS_FAILED:
            return set('availableProvisionLinks', [], state);
        case AdminPlaybookActionTypes.SET_PROVISION_TO_LINK: {
            const currentProvisionLinks = !isNull(payload) && (state.playbook.content.sections.find(section => section.sectionId === payload) as PlaybookProvision).linkedProvisions || [];
            return flow(
                set('provisionToLink', payload),
                set('currentProvisionLinks', currentProvisionLinks)
            )(state);
        }
        case AdminPlaybookActionTypes.TOGGLE_LINKED_PROVISIONS_MODAL:
            return set('linkedProvisionModalOpen', !state.linkedProvisionModalOpen, state);
        case AdminPlaybookActionTypes.SET_UPDATED_PROVISION_LINKS:
            return set('updatedProvisionLinks', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_DELETE_SECTION_CONFIRMATION_MODAL:
            return set('sectionWithLinksToDelete', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_READ_ONLY_LINKED_PROVISION_MODAL: {
            if (payload === state.readOnlyLinkedProvisionModalOpen) {
                return set('readOnlyLinkedProvisionModalOpen', null, state);
            }
            return set('readOnlyLinkedProvisionModalOpen', payload, state);
        }
        case AdminPlaybookActionTypes.UPDATE_LINKED_PROVISION_HISTORY:
            return set('provisionLinkHistory', payload, state);
        case AdminPlaybookActionTypes.TOGGLE_PROVISION_LINK_HISTORY_MODAL:
            return set('provisionLinkHistoryModalOpen', payload, state);
        case AdminPlaybookActionTypes.CHANGE_SYSTEM_ADMIN_BOOKSHELF_VIEW:
            return set('systemAdminBookshelfView', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE();
        default:
            return state;
    }
};
