import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Position } from '../../shared/modal/PositionModal';
import { Text } from '../../shared/text/Text';
import styles from './DatasetInstance.module.scss';
import { FuzzyFieldMatchModal } from './FuzzyFieldMatchModal';
import { getFieldSectionSearchValue, getSearchFuzzyMatches, OpenFieldType, SearchFieldSection, setFuzzyMatchSearchValue, setSearchFieldSection, setSearchFuzzyMatches } from './store';

export const FieldSearch: React.FC = () => {
    const [shouldOpen, setShouldOpen] = useState<boolean>(true);
    const [fuzzyMatchIndex, setFuzzyMatchIndex] = useState<number>(0);
    const searchInputRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const fuzzyMatches = useAppSelector(getSearchFuzzyMatches);
    const searchValue = useAppSelector(getFieldSectionSearchValue);

    const xPosition = searchInputRef.current?.offsetLeft ? searchInputRef.current.offsetLeft + 1 : 0;
    const yPosition = searchInputRef.current?.offsetTop ? searchInputRef.current.offsetTop + 145 : 0;
    const inputWidth = searchInputRef.current?.offsetWidth ? searchInputRef.current.offsetWidth - 2 : 0;
    const fieldMatchPosition: Position = { x: xPosition, y: yPosition };

    const isOpen = useMemo(() => fuzzyMatches.length > 0 && shouldOpen, [shouldOpen, fuzzyMatches]);

    const scrollToSearchMatch = useCallback((index: number) => {
        const searchMatch = document.getElementById(`dataset-instance-field-match-${index}`);
        searchMatch?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }, []);

    const scrollToFieldSection = useCallback((match: SearchFieldSection) => {
        let ref: HTMLElement | null = null;
        if (match.type === OpenFieldType.SECTION) {
            ref = document.getElementById(`dataset-instance-section-${match.sectionId}`);

        } else {
            ref = document.getElementById(`dataset-instance-field-${match.fieldId}`);
        }
        ref?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }, []);

    const resetSearch = useCallback(() => {
        dispatch(setSearchFieldSection(null));
        dispatch(setSearchFuzzyMatches([]));
    }, [dispatch]);

    const selectMatch = useCallback((match: SearchFieldSection) => {
        dispatch(setSearchFieldSection(match));
        setShouldOpen(false);
        setTimeout(() => scrollToFieldSection(match), 150);
        setTimeout(() => resetSearch(), 3000);
    }, [dispatch, scrollToFieldSection, resetSearch]);

    const onKeyDown = useCallback((e: KeyboardEvent) => {
        if (isOpen) {
            const maxIndex = fuzzyMatches.length - 1;
            if (e.key.toLowerCase() === 'enter') {
                const match = fuzzyMatches[fuzzyMatchIndex];
                selectMatch(match);
            }
            if (e.key.toLowerCase() === 'arrowup') {
                const indexToUpdate = fuzzyMatchIndex === 0 ? maxIndex : fuzzyMatchIndex - 1;
                setFuzzyMatchIndex(indexToUpdate);
                scrollToSearchMatch(indexToUpdate);
            }
            if (e.key.toLowerCase() === 'arrowdown') {
                const indexToUpdate = fuzzyMatchIndex === maxIndex ? 0 : fuzzyMatchIndex + 1;
                setFuzzyMatchIndex(indexToUpdate);
                scrollToSearchMatch(indexToUpdate);
            }
        }
    }, [setFuzzyMatchIndex, fuzzyMatches, fuzzyMatchIndex, isOpen, scrollToSearchMatch, selectMatch]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);

    const updateSearchTerm = useCallback((value: string) => {
        setShouldOpen(true);
        dispatch(setFuzzyMatchSearchValue(value));
        setFuzzyMatchIndex(0);
    }, [dispatch]);

    const closeModal = useCallback(() => {
        setShouldOpen(false);
        setTimeout(() => resetSearch(), 3000);
    }, [resetSearch]);

    useEffect(() => () => {
        resetSearch();
        dispatch(setFuzzyMatchSearchValue(''));
    }, [resetSearch, dispatch]);

    return (
        <div className={styles.fieldSearchWrapper} data-testid='dataset-instance-field-search' ref={searchInputRef}>
            <Text
                testId='dataset-instance-search'
                placeholder='Find a data point...'
                value={searchValue}
                onChange={e => updateSearchTerm(e.target.value)}
                marginBottom='0'
            />
            <FuzzyFieldMatchModal
                isOpen={isOpen}
                position={fieldMatchPosition}
                inputWidth={inputWidth}
                selectMatch={selectMatch}
                closeModal={closeModal}
                fuzzyMatches={fuzzyMatches}
                searchValue={searchValue}
                fuzzyMatchIndex={fuzzyMatchIndex}
                setFuzzyMatchIndex={index => setFuzzyMatchIndex(index)}
            />
        </div>
    );
};
