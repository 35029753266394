import React, { useMemo } from 'react';
import Modal from 'react-modal';

import { AgencyDatasetField, AgencyDefinition, DatasetFieldType } from '../../../datasets/store';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { Button } from '../../../shared/button/Button';
import sharedStyles from '../SharedBuilder.module.scss';
import styles from './AgencyDatasetBuilder.module.scss';
import { Icon } from '../../../shared/icon/Icon';
import { Table } from '../../../shared/icons';
import { AgencyFieldList } from './AgencyFieldList';
import { AgencyDatasetBuilder } from './AgencyDatasetBuilder';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { getPublishConfirmationModalOpen, publishAgencyDatasetDefinitionStarted, togglePublishAgencyConfirmation } from '../store';
import { useAppSelector, useAppDispatch } from '../../../../hooks/react-redux';

interface PublishConfirmationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    confirm: () => void;
}

interface AgencyBuilderModalProps {
    isOpen: boolean;
    closeModal: () => void;
    agencyDefinition: AgencyDefinition | null;
}

const PublishConfirmationModal: React.FC<PublishConfirmationModalProps> = ({ isOpen, closeModal, confirm }) => (
    <ConfirmationModal closeModal={closeModal} isOpen={isOpen} confirm={confirm}>
        <div className={styles.confirmationHeader}>Publish Dataset</div>
        <div className={styles.confirmationSecondaryMessage}>Are you sure you want these changes to be published?</div>
    </ConfirmationModal>
);

const validateLink = (field: AgencyDatasetField) => {
    switch (field.type) {
        case DatasetFieldType.DROPDOWN:
            return !field.linkedFields.length && !field.settings.dropdownLinked;
        case DatasetFieldType.WIZARD:
            return !field.linkedFields.length;
        default:
            return false;
    }
};

const validateLinkableFields = (agencyDefinition: AgencyDefinition | null) => agencyDefinition?.datasetFields.some(field => validateLink(field));
const validateLabels = (agencyDefinition: AgencyDefinition | null) => agencyDefinition?.datasetFields.some(field => field.type !== DatasetFieldType.WIZARD && field.linkedFields.length ? false : !field.label.length);

export const AgencyBuilderModal: React.FC<AgencyBuilderModalProps> = ({ isOpen, closeModal, agencyDefinition }) => {
    const dispatch = useAppDispatch();
    const [screenWidth, screenHeight] = useWindowResize();
    const isPublishConfirmationModalOpen = useAppSelector(getPublishConfirmationModalOpen);

    const openPublishConfirmationModal = () => dispatch(togglePublishAgencyConfirmation(true));
    const closePublishConfirmationModal = () => dispatch(togglePublishAgencyConfirmation(false));

    const publishDataset = () => dispatch(publishAgencyDatasetDefinitionStarted());

    const unlinkedFields = validateLinkableFields(agencyDefinition);
    const missingLabels = validateLabels(agencyDefinition);
    const noFields = !agencyDefinition?.datasetFields.length;
    const publishDisabled = unlinkedFields || noFields || missingLabels;

    const publishDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (unlinkedFields) {
            disabledArray.push('One or more of your fields is unlinked');
        }
        if (noFields) {
            disabledArray.push('You must add a field to publish your dataset');
        }
        if (missingLabels) {
            disabledArray.push('One or more of your fields is missing a label');
        }
        return disabledArray;
    }, [unlinkedFields, noFields, missingLabels]);

    return (
        <Modal
            isOpen={isOpen}
            className={sharedStyles.builderModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex' } }}
        >
            <div
                className={sharedStyles.builderWrapper}
                data-testid='agency-dataset-builder-modal-wrapper'
                style={{ width: `${screenWidth * 0.65}px`, height: `${screenHeight * 0.8}px` }}
            >
                <div
                    className={sharedStyles.definitionBuilderWrapper}
                    data-testid='agency-dataset-builder-modal-wrapper'
                    style={{ width: `${screenWidth * 0.65}px`, height: `${screenHeight * 0.8}px` }}
                >
                    <div className={sharedStyles.builderHeaderWrapper} style={{ marginBottom: '10px' }}>
                        <div className={sharedStyles.builderHeaderLabel} data-testid='agency-definition-builder-header'>Agency Dataset Builder</div>
                        <div className={sharedStyles.builderTypeWrapper}>
                            <Icon icon={Table} fontSize={40} />
                        </div>
                    </div>
                    <div className={sharedStyles.builderContent}>
                        <div className={sharedStyles.builderTable}>
                            <AgencyDatasetBuilder />
                        </div>
                        <AgencyFieldList />
                    </div>
                    <div className={sharedStyles.builderButtonWrapper}>
                        <Button onClick={closeModal} label='Cancel' testId='cancel-agency-dataset' />
                        <Button
                            onClick={openPublishConfirmationModal}
                            label='Publish'
                            testId='publish-agency-dataset'
                            disabled={publishDisabled}
                            disabledTooltip={publishDisabledTooltip}
                        />
                    </div>
                    <PublishConfirmationModal isOpen={isPublishConfirmationModalOpen} closeModal={closePublishConfirmationModal} confirm={publishDataset} />
                </div>
            </div>
        </Modal>
    );
};
