import { isNumber } from 'lodash/fp';
import { action } from 'typesafe-actions';

import { BaseEntity } from '../../../admin/entity/store';
import { HiddenFields } from '../../../admin/my-datasets/store';
import { MyRiskTolerance, RiskTolerance } from '../../../admin/risk-tolerance/store';
import { AnnexDefinitionDB } from '../../../agency-annex/store';
import { DocumentType } from '../../../documents/my-documents/store';
import { DatasetDefinitionDB } from '../../store';
import { InstanceMLData } from '../../store/mlTypes';
import { DatasetInstance, DatasetInstanceActionTypes, DatasetInstanceSection, DatasetInstanceTimeline, DocumentToDelete, FieldValue, Hierarchy, Instance, OpenFieldSection, SearchFieldSection, TimelineInstance, UpdatedField } from './types';

export const openDatasetDefinitionStarted = (executedDate?: string, documentType?: DocumentType, showLegacy?: boolean) => action(DatasetInstanceActionTypes.OPEN_DATASET_DEFINITION_STARTED, { executedDate, documentType, showLegacy });
export const openDatasetDefinitionSuccessful = (datasetDefinition: DatasetDefinitionDB | AnnexDefinitionDB) => action(DatasetInstanceActionTypes.OPEN_DATASET_DEFINITION_SUCCESSFUL, datasetDefinition);
export const openDatasetDefinitionFailed = (error: string) => action(DatasetInstanceActionTypes.OPEN_DATASET_DEFINITION_FAILED, error);

export const fetchECSDatasetIdStarted = () => action(DatasetInstanceActionTypes.FETCH_ECS_DATASET_ID_STARTED);
export const fetchECSDatasetIdSuccessful = (ecsDatasetId: number) => action(DatasetInstanceActionTypes.FETCH_ECS_DATASET_ID_SUCCESSFUL, ecsDatasetId);
export const fetchECSDatasetIdFailed = (error: string) => action(DatasetInstanceActionTypes.FETCH_ECS_DATASET_ID_FAILED, error);

export const generateNewECSTableStarted = () => action(DatasetInstanceActionTypes.GENERATE_NEW_ECS_TABLE_STARTED);
export const generateNewECSTableSuccessful = (ecsTableInstance: Instance, sectionId: string) => action(DatasetInstanceActionTypes.GENERATE_NEW_ECS_TABLE_SUCCESSFUL, { ecsTableInstance, sectionId });
export const generateNewECSTableFailed = (error: string) => action(DatasetInstanceActionTypes.GENERATE_NEW_ECS_TABLE_FAILED, error);

export const updateHierarchyFieldValue = (datasetId: number, parentFieldId: string, value: FieldValue, index: number, sectionId?: string, groupIndex?: number, rowId?: string) => !isNumber(groupIndex) ?
    action(DatasetInstanceActionTypes.UPDATE_FIELD_VALUE, { datasetId, parentFieldId, value, index, sectionId, rowId }) :
    action(DatasetInstanceActionTypes.UPDATE_GROUP_FIELD_VALUE, { datasetId, parentFieldId, value, index, sectionId, groupIndex });

export const userCorrectAIFieldValue = (datasetId: number, parentFieldId: string, value: FieldValue, index: number, sectionId?: string, groupIndex?: number, rowId?: string) => !isNumber(groupIndex) ?
    action(DatasetInstanceActionTypes.USER_CORRECT_AI_FIELD_VALUE, { datasetId, parentFieldId, value, index, sectionId, rowId }) :
    action(DatasetInstanceActionTypes.USER_CORRECT_AI_GROUP_FIELD_VALUE, { datasetId, parentFieldId, value, index, sectionId, groupIndex });

export const updateFieldCustomChild = (datasetId: number, parentFieldId: string, value: FieldValue, index: number, sectionId: string, groupIndex: number, property: 'value' | 'label') => action(DatasetInstanceActionTypes.UPDATE_FIELD_CUSTOM_CHILD, { datasetId, parentFieldId, value, index, sectionId, groupIndex, property });
export const addFieldCustomChild = (datasetId: number, parentFieldId: string, sectionId: string, groupIndex: number) => action(DatasetInstanceActionTypes.ADD_FIELD_CUSTOM_CHILD, { datasetId, parentFieldId, sectionId, groupIndex });

export const upsertDatasetInstanceStarted = (isDraft: boolean) => action(DatasetInstanceActionTypes.UPSERT_DATASET_INSTANCE_STARTED, isDraft);
export const upsertDatasetInstanceSuccessful = () => action(DatasetInstanceActionTypes.UPSERT_DATASET_INSTANCE_SUCCESSFUL);
export const upsertDatasetInstanceFailed = (error: string) => action(DatasetInstanceActionTypes.UPSERT_DATASET_INSTANCE_FAILED, error);

export const confirmMLDatasetInstanceStarted = () => action(DatasetInstanceActionTypes.CONFIRM_ML_DATASET_INSTANCE_STARTED);
export const confirmMLDatasetInstanceSuccessful = () => action(DatasetInstanceActionTypes.CONFIRM_ML_DATASET_INSTANCE_SUCCESSFUL);
export const confirmMLDatasetInstanceFailed = (error: string) => action(DatasetInstanceActionTypes.CONFIRM_ML_DATASET_INSTANCE_FAILED, error);

export const editDatasetInstance = (value: boolean) => action(DatasetInstanceActionTypes.EDIT_DATASET_INSTANCE, value);

export const updateDatasetInstance = (value: boolean) => action(DatasetInstanceActionTypes.UPDATE_DATASET_INSTANCE, value);

export const openDatasetInstanceById = (datasetInstanceId: string, showLegacy?: boolean, originalDocumentId?: number, preventRedirect = false) => action(DatasetInstanceActionTypes.OPEN_DATASET_INSTANCE_BY_ID, { datasetInstanceId, showLegacy, originalDocumentId, preventRedirect });

export const instanceHasUpdated = (datasetId: number, parentFieldId: string, value: boolean) => action(DatasetInstanceActionTypes.INSTANCE_UPDATED, { datasetId, value, parentFieldId });

export const setFieldsUpdated = (datasetId: number, parentFieldId: string, fieldsUpdated: UpdatedField[]) => action(DatasetInstanceActionTypes.SET_FIELDS_UPDATED_VALUE, { datasetId, fieldsUpdated, parentFieldId });

export const toggleUpdatedInstanceModal = (value: boolean) => action(DatasetInstanceActionTypes.TOGGLE_UPDATED_INSTANCE_MODAL, value);

export const resetDatasetInstance = () => action(DatasetInstanceActionTypes.RESET_DATASET_INSTANCE);

export const removeHierarchyTableDatasetRow = (datasetId: number, parentFieldId: string, rowId: string) => action(DatasetInstanceActionTypes.REMOVE_TABLE_DATASET_ROW, { rowId, datasetId, parentFieldId });
export const addHierarchyTableDatasetRow = (datasetId: number, parentFieldId: string) => action(DatasetInstanceActionTypes.ADD_TABLE_DATASET_ROW, { datasetId, parentFieldId });
export const duplicateHierarchyTableDatasetRow = (datasetId: number, parentFieldId: string, rowId: string) => action(DatasetInstanceActionTypes.DUPLICATE_TABLE_DATASET_ROW, { rowId, datasetId, parentFieldId });

export const removeHierarchyCollapsedColumn = (datasetId: number, parentFieldId: string, columnId: string) => action(DatasetInstanceActionTypes.REMOVE_COLLAPSED_COLUMN, { columnId, datasetId, parentFieldId });
export const addHierarchyCollapsedColumn = (datasetId: number, parentFieldId: string, columnId: string) => action(DatasetInstanceActionTypes.ADD_COLLAPSED_COLUMN, { columnId, datasetId, parentFieldId });

export const toggleSaveInstanceModal = (value: boolean) => action(DatasetInstanceActionTypes.TOGGLE_SAVE_INSTANCE_MODAL, value);

export const toggleLinkedDocumentModal = (value: boolean) => action(DatasetInstanceActionTypes.TOGGLE_LINKED_DOCUMENT_MODAL, value);

export const toggleHierarchyClauseModalOpen = (datasetId: number, parentFieldId: string, index: number, value: boolean, sectionId?: string, groupIndex?: number, rowId?: string) => !isNumber(groupIndex) ?
    action(DatasetInstanceActionTypes.TOGGLE_CLAUSE_MODAL_OPEN, { datasetId, parentFieldId, index, value, sectionId, rowId }) :
    action(DatasetInstanceActionTypes.TOGGLE_GROUP_CLAUSE_MODAL_OPEN, { datasetId, parentFieldId, index, value, sectionId, groupIndex });

export const updateHierarchyClauseLabel = (datasetId: number, parentFieldId: string, value: FieldValue, index: number, sectionId?: string, groupIndex?: number, rowId?: string) => !isNumber(groupIndex) ?
    action(DatasetInstanceActionTypes.UPDATE_CLAUSE_LABEL, { datasetId, parentFieldId, value, index, sectionId, rowId }) :
    action(DatasetInstanceActionTypes.UPDATE_GROUP_CLAUSE_LABEL, { datasetId, parentFieldId, value, index, sectionId, groupIndex });

export const setAnnexFieldIds = (fieldIds: string[]) => action(DatasetInstanceActionTypes.SET_ANNEX_FIELD_IDS, fieldIds);

export const openAnnexFieldWizard = (index: number, rowId: string) => action(DatasetInstanceActionTypes.OPEN_ANNEX_FIELD_WIZARD, { index, rowId });
export const closeAnnexFieldWizard = () => action(DatasetInstanceActionTypes.CLOSE_ANNEX_FIELD_WIZARD);

export const updateLastModifiedFields = (datasetId: number, parentFieldId: string, updatedFields: UpdatedField[], userId: number) => action(DatasetInstanceActionTypes.UPDATE_LAST_MODIFIED_FIELDS, { datasetId, updatedFields, parentFieldId, userId });

export const toggleShowLegacy = (showLegacy: boolean) => action(DatasetInstanceActionTypes.TOGGLE_DATASET_INSTANCE_SHOW_LEGACY, showLegacy);
export const setTimeline = (timeline: DatasetInstanceTimeline) => action(DatasetInstanceActionTypes.SET_DATASET_INSTANCE_TIMELINE, timeline);

export const setParentDatasetId = (parentId: number) => action(DatasetInstanceActionTypes.SET_PARENT_DATASET_ID, parentId);

export const updateDatasetHierarchy = (hierarchy: Hierarchy[]) => action(DatasetInstanceActionTypes.UPDATE_DATASET_HIERARCHY, hierarchy);

export const setTimelineInstance = (timelineInstance: TimelineInstance, originalDocumentId: number, preventRedirect = false) => action(DatasetInstanceActionTypes.SET_TIMELINE_INSTANCE, { timelineInstance, originalDocumentId, preventRedirect });
export const openTimelineInstanceStarted = (timelineInstance: TimelineInstance, originalDocumentId: number, preventRedirect = false) => action(DatasetInstanceActionTypes.OPEN_TIMELINE_INSTANCE_STARTED, { timelineInstance, originalDocumentId, preventRedirect });
export const openTimelineInstanceSuccessful = (datasetInstances: Instance[], hierarchy: Hierarchy[], mlData: InstanceMLData | null = null) => action(DatasetInstanceActionTypes.OPEN_TIMELINE_INSTANCE_SUCCESSFUL, { datasetInstances, hierarchy, mlData });
export const openTimelineInstanceFailed = (error: string) => action(DatasetInstanceActionTypes.OPEN_TIMELINE_INSTANCE_FAILED, error);

export const removeOpenFieldSection = (fieldSection: OpenFieldSection) => action(DatasetInstanceActionTypes.REMOVE_OPEN_FIELD_SECTION, fieldSection);
export const addOpenFieldSection = (fieldSection: OpenFieldSection) => action(DatasetInstanceActionTypes.ADD_OPEN_FIELD_SECTION, fieldSection);
export const removeAllFieldSections = () => action(DatasetInstanceActionTypes.REMOVE_ALL_FIELD_SECTIONS);
export const expandAllDatasetFieldSections = (fieldSection: OpenFieldSection[]) => action(DatasetInstanceActionTypes.EXPAND_ALL_DATASET_INSTANCE_SECTIONS, fieldSection);

export const setHiddenFields = (hiddenFields: HiddenFields) => action(DatasetInstanceActionTypes.SET_HIDDEN_FIELDS, hiddenFields);

export const toggleDeleteConfirmationModal = (documentToDelete: DocumentToDelete | null) => action(DatasetInstanceActionTypes.TOGGLE_DELETE_SECONDARY_DOCUMENT_MODAL, documentToDelete);
export const deleteSecondaryDocumentStarted = (documentId: number, datasetInstanceId: number) => action(DatasetInstanceActionTypes.DELETE_SECONDARY_DOCUMENT_STARTED, { documentId, datasetInstanceId });
export const deleteSecondaryDocumentSuccessful = () => action(DatasetInstanceActionTypes.DELETE_SECONDARY_DOCUMENT_SUCCESSFUL);
export const deleteSecondaryDocumentFailed = (error: string) => action(DatasetInstanceActionTypes.DELETE_SECONDARY_DOCUMENT_FAILED, error);

export const toggleMLDataModal = (isOpen: boolean) => action(DatasetInstanceActionTypes.TOGGLE_ML_DATA_MODAL_OPEN, isOpen);

export const setDatasetInstanceRiskTolerance = (riskToleranceDefinitions: (RiskTolerance | MyRiskTolerance)[]) => action(DatasetInstanceActionTypes.SET_DATASET_INSTANCE_RISK_TOLERANCE, riskToleranceDefinitions);

export const scrollToSection = (section: DatasetInstanceSection) => action(DatasetInstanceActionTypes.SCROLL_TO_DATASET_INSTANCE_SECTION, section);

export const toggleAnnexConfigurationModal = (isOpen: boolean) => action(DatasetInstanceActionTypes.TOGGLE_ANNEX_DEFINITION_MODAL_OPEN, isOpen);

export const setIsUpdatingInstanceAnnexDefinitions = (isUpdating: boolean) => action(DatasetInstanceActionTypes.SET_IS_UPDATING_INSTANCE_ANNEX_DEFINITIONS, isUpdating);

export const setFuzzyMatchSearchValue = (searchValue: string) => action(DatasetInstanceActionTypes.SET_DATASET_INSTANCE_FUZZY_MATCH_SEARCH_VALUE, searchValue);
export const setSearchFuzzyMatches = (fuzzyMatches: SearchFieldSection[]) => action(DatasetInstanceActionTypes.SET_DATASET_INSTANCE_SEARCH_FUZZY_MATCHES, fuzzyMatches);

export const calculateSearchFieldSections = (instances: Instance[], hierarchy: Hierarchy[], hiddenFields: HiddenFields) => action(DatasetInstanceActionTypes.CALCULATE_ALL_INSTANCE_SEARCH_FIELDS, { instances, hierarchy, hiddenFields });
export const setAllSearchFieldSections = (searchFieldSections: SearchFieldSection[]) => action(DatasetInstanceActionTypes.SET_ALL_DATASET_INSTANCE_SEARCH_FIELD_SECTIONS, searchFieldSections);
export const setSearchFieldSection = (match: SearchFieldSection | null) => action(DatasetInstanceActionTypes.SET_DATASET_INSTANCE_SEARCH_FIELD_SECTION, match);

// Modal Instance
export const openModalDatasetStarted = (datasetId: number, rowId: string, index: number, parentFieldId: string, childDatasetId: string, instanceId: string | null) => action(DatasetInstanceActionTypes.OPEN_MODAL_DATASET_STARTED, { datasetId, rowId, index, parentFieldId, childDatasetId, instanceId });
export const openModalDatasetSuccessful = (datasetInstance: DatasetInstance) => action(DatasetInstanceActionTypes.OPEN_MODAL_DATASET_SUCCESSFUL, datasetInstance);
export const openModalDatasetFailed = (error: string) => action(DatasetInstanceActionTypes.OPEN_MODAL_DATASET_FAILED, error);

export const closeModalDataset = () => action(DatasetInstanceActionTypes.CLOSE_MODAL_DATASET);

export const upsertModalDatasetStarted = () => action(DatasetInstanceActionTypes.UPSERT_MODAL_DATASET_STARTED);
export const upsertModalDatasetSuccessful = () => action(DatasetInstanceActionTypes.UPSERT_MODAL_DATASET_SUCCESSFUL);
export const upsertModalDatasetFailed = (error: string) => action(DatasetInstanceActionTypes.UPSERT_MODAL_DATASET_FAILED, error);

export const toggleModalClauseModalOpen = (index: number, value: boolean, sectionId?: string, groupIndex?: number, rowId?: string) => !isNumber(groupIndex) ?
    action(DatasetInstanceActionTypes.TOGGLE_MODAL_CLAUSE_MODAL_OPEN, { index, value, sectionId, rowId }) :
    action(DatasetInstanceActionTypes.TOGGLE_MODAL_GROUP_CLAUSE_MODAL_OPEN, { index, value, sectionId, groupIndex });

export const updateModalClauseLabel = (value: FieldValue, index: number, sectionId?: string, groupIndex?: number, rowId?: string) => !isNumber(groupIndex) ?
    action(DatasetInstanceActionTypes.UPDATE_MODAL_CLAUSE_LABEL, { value, index, sectionId, rowId }) :
    action(DatasetInstanceActionTypes.UPDATE_MODAL_GROUP_CLAUSE_LABEL, { value, index, sectionId, groupIndex });

export const updateModalFieldValue = (value: FieldValue, index: number, sectionId?: string, groupIndex?: number, rowId?: string) => !isNumber(groupIndex) ?
    action(DatasetInstanceActionTypes.UPDATE_MODAL_FIELD_VALUE, { value, index, sectionId, rowId }) :
    action(DatasetInstanceActionTypes.UPDATE_MODAL_GROUP_FIELD_VALUE, { value, index, sectionId, groupIndex });

export const removeModalTableDatasetRow = (rowId: string) => action(DatasetInstanceActionTypes.REMOVE_MODAL_TABLE_DATASET_ROW, rowId);
export const addModalTableDatasetRow = () => action(DatasetInstanceActionTypes.ADD_MODAL_TABLE_DATASET_ROW);
export const duplicateModalTableDatasetRow = (rowId: string) => action(DatasetInstanceActionTypes.DUPLICATE_MODAL_DATASET_ROW, rowId);

export const removeModalCollapsedColumn = (columnId: string) => action(DatasetInstanceActionTypes.REMOVE_MODAL_COLLAPSED_COLUMN, columnId);
export const addModalCollapsedColumn = (columnId: string) => action(DatasetInstanceActionTypes.ADD_MODAL_COLLAPSED_COLUMN, columnId);

export const modalInstanceHasUpdated = (value: boolean) => action(DatasetInstanceActionTypes.MODAL_INSTANCE_UPDATED, value);

export const setModalFieldsUpdated = (fieldsUpdated: UpdatedField[]) => action(DatasetInstanceActionTypes.SET_MODAL_FIELDS_UPDATED_VALUE, fieldsUpdated);

export const updateModalLastModifiedFields = (updatedFields: UpdatedField[], userId: number) => action(DatasetInstanceActionTypes.UPDATE_MODAL_LAST_MODIFIED_FIELDS, { updatedFields, userId });

// Switch action handlers (hierarchy and modal instances)
export const updateFieldValue = (datasetId: number, parentFieldId: string, value: FieldValue, index: number, sectionId?: string, groupIndex?: number, rowId?: string, modalInstance = false) => modalInstance ?
    updateModalFieldValue(value, index, sectionId, groupIndex, rowId) : updateHierarchyFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId);

export const toggleClauseModalOpen = (datasetId: number, parentFieldId: string, index: number, value: boolean, sectionId?: string, groupIndex?: number, rowId?: string, modalInstance = false) => modalInstance ?
    toggleModalClauseModalOpen(index, value, sectionId, groupIndex, rowId) : toggleHierarchyClauseModalOpen(datasetId, parentFieldId, index, value, sectionId, groupIndex, rowId);

export const updateClauseLabel = (datasetId: number, parentFieldId: string, value: FieldValue, index: number, sectionId?: string, groupIndex?: number, rowId?: string, modalInstance = false) => modalInstance ?
    updateModalClauseLabel(value, index, sectionId, groupIndex, rowId) : updateHierarchyClauseLabel(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId);

export const removeTableDatasetRow = (datasetId: number, parentFieldId: string, rowId: string, modalInstance = false) => modalInstance ?
    removeModalTableDatasetRow(rowId) : removeHierarchyTableDatasetRow(datasetId, parentFieldId, rowId);

export const addTableDatasetRow = (datasetId: number, parentFieldId: string, modalInstance = false) => modalInstance ?
    addModalTableDatasetRow() : addHierarchyTableDatasetRow(datasetId, parentFieldId);

export const duplicateTableDatasetRow = (datasetId: number, parentFieldId: string, rowId: string, modalInstance = false) => modalInstance ?
    duplicateModalTableDatasetRow(rowId) : duplicateHierarchyTableDatasetRow(datasetId, parentFieldId, rowId);

export const removeCollapsedColumn = (datasetId: number, parentFieldId: string, columnId: string, modalInstance = false) => modalInstance ?
    removeModalCollapsedColumn(columnId) : removeHierarchyCollapsedColumn(datasetId, parentFieldId, columnId);

export const addCollapsedColumn = (datasetId: number, parentFieldId: string, columnId: string, modalInstance = false) => modalInstance ?
    addModalCollapsedColumn(columnId) : addHierarchyCollapsedColumn(datasetId, parentFieldId, columnId);

// ECS Table Actions
export const updateFieldLabel = (datasetId: number, parentFieldId: string, value: string, index: number, sectionId: string) => action(DatasetInstanceActionTypes.UPDATE_FIELD_LABEL, { datasetId, parentFieldId, value, index, sectionId });
export const updateLinkedEntities = (datasetId: number, parentFieldId: string, value: string[] | null, index: number, sectionId: string) => action(DatasetInstanceActionTypes.UPDATE_LINKED_ENTITIES, { datasetId, parentFieldId, value, index, sectionId });
export const removeECSTable = (datasetId: number, parentFieldId: string, sectionId: string, fieldId: string, ecsTableDatasetId: string) => action(DatasetInstanceActionTypes.REMOVE_ECS_TABLE, { datasetId, parentFieldId, sectionId, fieldId, ecsTableDatasetId });
export const duplicateECSTable = (datasetId: number, parentFieldId: string, sectionId: string, fieldId: string, ecsTableDatasetId: string) => action(DatasetInstanceActionTypes.DUPLICATE_ECS_TABLE, { datasetId, parentFieldId, sectionId, fieldId, ecsTableDatasetId });
export const setAgencyLinkedEntities = (agencyLinkedEntities: BaseEntity[]) => action(DatasetInstanceActionTypes.SET_AGENCY_LINKED_ENTITIES, agencyLinkedEntities);
