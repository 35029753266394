import React, { useCallback } from 'react';
import Modal from 'react-modal';

import { useAppSelector } from '../../../hooks/react-redux';
import { ArkOpinion, getIsDownloadingSummary, getIsOpeningSummary, getOpinion, getOpinionSummaryUrl } from './store';
import { DocumentPreviewViewer } from '../../documents/document-viewer/DocumentPreviewViewer';
import { Button } from '../../shared/button/Button';
import { Spinner } from '../../shared/spinner/Spinner';
import styles from './Opinions.module.scss';

interface SummaryPreviewModalProps {
    isOpen: boolean;
    closeModal: () => void;
    download: (opinion: ArkOpinion) => void;
    canDownload: boolean;
}

export const SummaryPreviewModal: React.FC<SummaryPreviewModalProps> = ({ isOpen, closeModal, download, canDownload }) => {
    const isOpeningSummary = useAppSelector(getIsOpeningSummary);
    const isDownloadingSummary = useAppSelector(getIsDownloadingSummary);
    const previewUrl = useAppSelector(getOpinionSummaryUrl);
    const opinion = useAppSelector(getOpinion);
    const downloadSummary = useCallback(() => opinion && download(opinion), [opinion, download]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.summaryPreviewModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 20 } }}
            shouldCloseOnOverlayClick={!isOpeningSummary}
            shouldCloseOnEsc={!isOpeningSummary}
            onRequestClose={closeModal}
        >
            <div className={styles.summaryPreviewWrapper}>
                <div className={styles.summaryPreview}>
                    {isOpeningSummary ? <Spinner size={70} /> : (
                        <DocumentPreviewViewer url={previewUrl} mimeType='application/pdf' />
                    )}
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' disabled={isOpeningSummary} testId='opinion-summary-preview-close' />
                    <Button onClick={downloadSummary} label='Download' disabled={isOpeningSummary || isDownloadingSummary || !canDownload} testId='opinion-summary-preview-download' />
                </div>
            </div>
        </Modal>
    );
};
