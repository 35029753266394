import React, { useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { PlusButton } from '../button/PlusButton';
import { Icon } from '../icon/Icon';
import { Tick } from '../icons';
import styles from './Table.module.scss';
import { Spinner } from '../spinner/Spinner';

interface SelectCellProps {
    select: (selectedId: number | string) => void;
    selectDisabled?: (selectedId: number) => boolean;
    selectedIds: number[] | string[] | null;
    value: number | string;
    isLoading?: boolean;
}

export const SelectCell: React.FC<SelectCellProps> = ({ select, selectedIds, value, selectDisabled = () => false, isLoading = false }) => {
    const selectCell = () => select(value);
    const selected = useMemo(() => {
        if (isNull(selectedIds)) {
            return false;
        }
        if (typeof value === 'string') {
            return (selectedIds as string[]).includes(value);
        }
        return (selectedIds as number[]).includes(value);
    }, [selectedIds, value]);

    const isDisabled = typeof value === 'number' && selectDisabled(value);

    if (isLoading) {
        return (
            <div className={styles.selectCell}>
                <Spinner size={18} />
            </div>
        );
    }

    if (selected) {
        return (
            <div className={styles.selectCell}>
                <Icon icon={Tick} fontSize={24} />
            </div>
        );
    }

    return (
        <div className={styles.selectCell}>
            <PlusButton onClick={selectCell} fontSize={18} disabled={isDisabled} />
        </div>
    );
};
