import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { uniq } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { useFetchStarted } from '../../hooks/useFetchStarted';
import { fetchAllAgreementTypesStarted, fetchAvailableDocumentNamesStarted } from '../admin/documents/store';
import { BookCoverModal } from '../shared/playbook/BookCoverModal';
import { PlaybookShelf } from '../shared/playbook/PlaybookShelf';
import { Scrollable } from '../shared/scrollable/Scrollable';
import styles from '../shared/playbook/PlaybookBookcase.module.scss';
import { getAllPlaybookStarted, getPlaybookCoverModalOpen, getPlaybookCoverSelected, getPlaybookShelves, getPlaybookSimpleSearchTerm, getUserPlaybookByIdStarted, togglePlaybookCoverSelected, updatePlaybookSimpleSearch, resetPlaybookSearch, getPlaybookSmartSearchTerm, initialUserPlaybookSmartSearchTerm, playbookSmartSearchStarted, resetPlaybookSmartSearch, getShowUserPlaybookLoadingSpinner, updatePlaybookSmartSearch } from './store';
import { BasicPlaybook, PlaybookSmartSearch as PlaybookSmartSearchType } from '../admin/playbook/store';
import { PlaybookSmartSearch } from '../shared/playbook/PlaybookSmartSearch';
import { Button } from '../shared/button/Button';
import { Spinner } from '../shared/spinner/Spinner';
import { SimpleSearch } from '../shared/search/SimpleSearch';

export const UserPlaybooks: React.FC = () => {
    const testId = 'user-playbooks';
    const dispatch = useAppDispatch();
    useFetchStarted([getAllPlaybookStarted(), fetchAvailableDocumentNamesStarted(), fetchAllAgreementTypesStarted()]);
    const [showSmartSearch, setShowSmartSearch] = useState<boolean>(false);

    const flexDirection = useMemo(() => showSmartSearch ? 'row' : 'column', [showSmartSearch]);
    const bookCaseWrapperStyle = useMemo(() => ({ width: showSmartSearch ? '45%' : '90%', margin: showSmartSearch ? '0 auto 0 0' : '0 auto' }), [showSmartSearch]);

    const playbookShelves = useAppSelector(getPlaybookShelves);
    const { topShelf, bottomShelf } = playbookShelves;
    const noPlaybooks = !topShelf.length && !bottomShelf.length;

    const playbookAuthors = useMemo(() => {
        const allAuthors = [...topShelf, ...bottomShelf].map(({ createdBy }) => createdBy);
        return uniq(allAuthors);
    }, [topShelf, bottomShelf]);

    const openPlaybookCoverModal = useCallback((playbook: BasicPlaybook) => { dispatch(togglePlaybookCoverSelected(playbook)); }, [dispatch]);

    const playbookSelected = useAppSelector(getPlaybookCoverSelected);
    const isOpen = useAppSelector(getPlaybookCoverModalOpen);
    const showLoadingSpinner = useAppSelector(getShowUserPlaybookLoadingSpinner);

    const closeModal = useCallback(() => dispatch(togglePlaybookCoverSelected(null)), [dispatch]);
    const openPlaybook = useCallback(() => playbookSelected && dispatch(getUserPlaybookByIdStarted(playbookSelected.playbookId)), [dispatch, playbookSelected]);

    const searchButtonLabel = useMemo(() => showSmartSearch ? 'Simple Search' : 'Smart Search', [showSmartSearch]);
    const playbookSearchTerm = useAppSelector(getPlaybookSimpleSearchTerm);
    const smartSearchTerm = useAppSelector(getPlaybookSmartSearchTerm);
    const searchPlaybooks = useCallback((simpleSearchTerm: string) => dispatch(updatePlaybookSimpleSearch(simpleSearchTerm)), [dispatch]);
    const resetSearchTerm = useCallback(() => dispatch(resetPlaybookSearch()), [dispatch]);

    const toggleSmartSearch = useCallback(() => {
        setShowSmartSearch(!showSmartSearch);
        resetSearchTerm();
    }, [showSmartSearch, resetSearchTerm]);

    const resetSmartSearch = useCallback(() => {
        dispatch(getAllPlaybookStarted());
        dispatch(resetPlaybookSmartSearch());
    }, [dispatch]);

    const updateSmartSearch = useCallback((smartSearchTerm: PlaybookSmartSearchType[]) => {
        dispatch(updatePlaybookSmartSearch(smartSearchTerm));
    }, [dispatch]);

    const startSmartSearch = useCallback(() => {
        dispatch(playbookSmartSearchStarted());
    }, [dispatch]);

    useEffect(() => () => {
        resetSearchTerm();
        resetSmartSearch();
    }, [resetSearchTerm, resetSmartSearch]);

    return (
        <div className={styles.playbooksWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.simpleSearchWrapper} data-testid={`${testId}-simple-search-wrapper`}>
                <Button label={searchButtonLabel} onClick={toggleSmartSearch} disabled={noPlaybooks} testId={`${testId}-search-toggle`} />
                {!showSmartSearch && <SimpleSearch
                    searchTerm={playbookSearchTerm}
                    updateSearchTerm={searchPlaybooks}
                    resetSimpleSearch={resetSearchTerm}
                    testId={testId}
                    searchDisabled={noPlaybooks}
                />}
            </div>
            <div className={styles.bookshelfSearchWrapper} style={{ flexDirection: flexDirection }}>
                {showSmartSearch &&
                    <PlaybookSmartSearch
                        initialPlaybookSmartSearchTerm={initialUserPlaybookSmartSearchTerm}
                        smartSearchTerm={smartSearchTerm}
                        playbookAuthors={playbookAuthors}
                        updateSmartSearch={updateSmartSearch}
                        startSmartSearch={startSmartSearch}
                        resetPlaybookSearch={resetSmartSearch}
                        isSearching={showLoadingSpinner}
                        testId={testId}
                    />
                }
                {showLoadingSpinner ?
                    <div className={styles.bookCaseSpinner} style={bookCaseWrapperStyle}>
                        <Spinner />
                    </div>
                    :
                    <div className={styles.bookCaseWrapper} style={bookCaseWrapperStyle} data-testid={`${testId}-bookcase-wrapper`}>
                        <div className={styles.bookCaseSideWrapper}>
                            <div className={styles.bookCaseSide} />
                        </div>
                        <Scrollable>
                            <div className={styles.bookCase}>
                                <div className={styles.shelfWrapper}>
                                    <div className={styles.shelf} />
                                </div>
                                <PlaybookShelf playbooks={topShelf} isTopShelf openPlaybookCoverModal={openPlaybookCoverModal} testId={`${testId}-top-shelf`} />
                                <div className={styles.shelfWrapper}>
                                    <div className={styles.shelf} />
                                </div>
                                <PlaybookShelf playbooks={bottomShelf} openPlaybookCoverModal={openPlaybookCoverModal} testId={`${testId}-bottom-shelf`} />
                                <div className={styles.shelfWrapper}>
                                    <div className={styles.shelf} />
                                </div>
                            </div>
                        </Scrollable>
                        <div className={styles.bookCaseSideWrapper}>
                            <div className={styles.bookCaseSide} />
                        </div>
                        <BookCoverModal
                            isOpen={isOpen}
                            playbookSelected={playbookSelected}
                            closeModal={closeModal}
                            openPlaybook={openPlaybook}
                            testId={`${testId}-book-cover`}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

