import { isEqual } from 'lodash';
import { isUndefined } from 'lodash/fp';

import { ClientDocumentGroup, AgreementTypeDB, CurrentAgreementType, DocumentNameDB, DocumentNameState } from './types';
import { ApplicationState } from '../../../../store/rootReducer';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';

const getRoot = (state: ApplicationState): DocumentNameState => state.admin.documents;

export const getModalOpen = (state: ApplicationState): boolean => getRoot(state).modalOpen;

export const getAllDocumentNames = (state: ApplicationState): DocumentNameDB[] => getRoot(state).documents;

export const getAllAgreementTypes = (state: ApplicationState): AgreementTypeDB[] => getRoot(state).savedAgreementTypes;

export const getCurrentAgreementTypes = (state: ApplicationState): CurrentAgreementType[] => getRoot(state).currentAgreementTypes;

export const getOpenDocumentName = (state: ApplicationState): DocumentNameDB | null => getRoot(state).openDocument;

export const getAvailableDocumentNames = (state: ApplicationState): DocumentNameDB[] => getRoot(state).availableDocumentNames;

export const getClientDatasetConfirmationModalOpen = (state: ApplicationState): boolean => getRoot(state).clientDatasetConfirmationModalOpen;

export const getCreateDocumentGroupModalOpen = (state: ApplicationState): boolean => getRoot(state).createDocumentGroupModalOpen;
export const getLoadingDocumentGroups = (state: ApplicationState): boolean => getRoot(state).isLoadingGroups;
export const getAllClientDocumentGroups = (state: ApplicationState): ClientDocumentGroup[] => getRoot(state).allDocumentGroups;
export const getSelectedDocumentGroup = (state: ApplicationState): ClientDocumentGroup | null => getRoot(state).currentClientDocumentGroup;
export const getIsSavingGroup = (state: ApplicationState): boolean => getRoot(state).isSaving;
export const getClientDocumentGroupHasUpdated = (state: ApplicationState) => !isEqual(getRoot(state).currentClientDocumentGroup, getRoot(state).savedClientDocumentGroup);

export const getAgreementTypeModalOpen = (state: ApplicationState): boolean => getRoot(state).agreementTypeModalOpen;

export const getAgreementTypesHaveUpdated = (state: ApplicationState): boolean => getRoot(state).agreementTypesHaveUpdated;

export const getAgreementTypesAreUpdating = (state: ApplicationState): boolean => getRoot(state).isUpdatingAgreementTypes;

export const getOriginalDocumentNameTableDropdownOptions = (state: ApplicationState): DropdownOption[] => {
    const documentNameOptions = getAvailableDocumentNames(state)
        .filter(({ datasetId }) => datasetId)
        .map(({ documentName }) => ({ value: documentName, label: documentName }));

    const clientGroupOptions = getAllClientDocumentGroups(state);
    // group option values are the clientDocumentNameGroupId, in order to disignuish between dropdown options.
    const mappedGroups = clientGroupOptions.map(({ groupName, clientDocumentGroupId }, i) => ({ value: clientDocumentGroupId!.toString(), label: groupName, showDivider: i === clientGroupOptions.length - 1 }));
    return [...mappedGroups, ...documentNameOptions];
};

export const getMLReadyAgreementTypeIds = (state: ApplicationState): number[] => getRoot(state).mlReadyAgreementTypeIds;

export const getIsMLReadyAgreementType = (agreementTypeId: number | undefined) => (state: ApplicationState): boolean => {
    const agreementTypeIds = getMLReadyAgreementTypeIds(state);
    return !isUndefined(agreementTypeId) && agreementTypeIds.includes(agreementTypeId);
};
