import React, { useMemo } from 'react';

import styles from './SharedAnalytics.module.scss';
import { Icon } from '../icon/Icon';
import { SmileyPositive, SmileyNegative, SmileyNeutral } from '../icons';

const { green, amber, grey, lightGrey, red, primary } = styles;

export enum SmileyIndicator {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    NEUTRAL = 'neutral',
    NONE = 'none'
}

interface SingleIndicatorProps {
    indicator: SmileyIndicator;
    fontSize?: number;
}

export const SingleIndicator: React.FC<SingleIndicatorProps> = ({ indicator, fontSize = 36 }) => {
    const style = useMemo(() => {
        if (indicator === SmileyIndicator.POSITIVE) {
            return { fill: green, color: primary, fontSize };
        }
        if (indicator === SmileyIndicator.NEGATIVE) {
            return { fill: red, color: primary, fontSize };
        }
        if (indicator === SmileyIndicator.NEUTRAL) {
            return { fill: amber, color: primary, fontSize };
        }
        return { fill: lightGrey, color: grey, fontSize };
    }, [indicator, fontSize]);

    const icon = useMemo(() => {
        if (indicator === SmileyIndicator.POSITIVE) {
            return SmileyPositive;
        }
        if (indicator === SmileyIndicator.NEGATIVE) {
            return SmileyNegative;
        }
        return SmileyNeutral;
    }, [indicator]);

    return (
        <div className={styles.answerIndicator}>
            <Icon icon={icon} {...style} />
        </div>
    );
};
