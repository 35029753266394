import React, { useMemo } from 'react';

import { OverflowTooltip } from '../tooltip';
import styles from './SharedAnalytics.module.scss';

interface FixedXAxisProps {
    label?: string;
    dataPoints: string[];
}

export const FixedXAxis: React.FC<FixedXAxisProps> = ({ label, dataPoints }) => {
    const nonZeroDataPoints = useMemo(() => dataPoints.filter((_, index) => index != 0), [dataPoints]);
    const firstPoint = useMemo(() => dataPoints[0], [dataPoints]);

    return (
        <div className={styles.xAxisWrapper}>
            <div className={styles.xAxis} />
            <div className={styles.xAxisPoints}>
                <div className={styles.xAxisPointWrapper}>
                    <div className={styles.xAxisTick} />
                    <OverflowTooltip className={styles.xAxisPointLabel} overlayText={firstPoint} />
                </div>
                {nonZeroDataPoints.map(point => (
                    <div key={point} style={{ width: `${100 / dataPoints.length + 1}%`, alignItems: 'flex-end' }} className={styles.xAxisPointWrapper}>
                        <div className={styles.xAxisTick} />
                        <OverflowTooltip className={styles.xAxisPointLabel} overlayText={point} />
                    </div>
                ))}
            </div>
            {label && <div className={styles.xAxisLabel}>{label}</div>}
        </div>
    );
};
