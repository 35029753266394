import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { set } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Tag } from '../../icons';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import { ModalHeader } from '../../modal/ModalHeader';
import { getCreateTagModalOpen, getPlaybook, getPlaybookText, getTagToCreate, MatchingProvisions, PlaybookTag, toggleCreateTagModal, updatePlaybookTags } from '../../../admin/playbook/store';
import styles from './Tags.module.scss';
import { Scrollable } from '../../scrollable/Scrollable';
import { Text } from '../../text/Text';
import { OverflowTooltip } from '../../tooltip';
import { getProvisionTitle } from '../../../constants/playbooks';

interface CreateTagModalProps {
    testId?: string;
}

export const CreateTagModal: React.FC<CreateTagModalProps> = ({ testId = 'playbook-tags-create-tag' }) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getCreateTagModalOpen);
    const tagToCreate = useAppSelector(getTagToCreate);
    const { content: { sections }, tags } = useAppSelector(getPlaybook);
    const playbookText = useAppSelector(getPlaybookText);
    const [label, setLabel] = useState<string>('');
    const [type, setType] = useState<string>('');

    const closeModal = useCallback(() => dispatch(toggleCreateTagModal(null)), [dispatch]);

    const provisionTitle = useCallback((id: string) => getProvisionTitle(id, sections), [sections]);

    const totalMatches = useMemo(() => tagToCreate ? Object.values(tagToCreate.matchingProvisions).reduce((acc, cur) => acc + cur.length, 0) : 0, [tagToCreate]);

    const getRelevantMatchingProvisions = useCallback((matchingProvisions: MatchingProvisions) => Object.entries(matchingProvisions).reduce((acc: MatchingProvisions, [sectionId, matches]) => {
        if (matches.length) {
            return set(sectionId, matches, acc);
        }
        return acc;
    }, {}), []);

    const tagLabels = useMemo(() => tags.map(({ label }) => label), [tags]);

    const confirmDisabled = useMemo(() => tagLabels.includes(label) || (!tagToCreate || (tagToCreate && tagToCreate.searchTerm.length < 1)), [tagLabels, label, tagToCreate]);
    const confirmDisabledTooltip = useMemo(() => confirmDisabled ? ['Tag labels must be unique'] : [], [confirmDisabled]);

    const createTag = useCallback(() => {
        if (tagToCreate) {
            const { matchingProvisions, searchTerm } = tagToCreate;
            const relevantMatchingProvisions = getRelevantMatchingProvisions(matchingProvisions);
            const newTag: PlaybookTag = { label, type, matchingProvisions: relevantMatchingProvisions, searchTerm };
            dispatch(updatePlaybookTags([...tags, newTag]));
            closeModal();
            setType('');
        }
    }, [dispatch, label, type, tagToCreate, tags, getRelevantMatchingProvisions, closeModal]);

    const searchPlaybook = useCallback((searchTerm: string) => {
        if (searchTerm.length) {
            const regex = new RegExp(searchTerm.toLowerCase(), 'gi');
            const matchingProvisions = Object.entries(playbookText).reduce((acc: MatchingProvisions, [sectionId, rows]) => {
                const matchingRows = rows.reduce((acc: { rowIndex: number; index: number; }[], row, rowIndex) => {
                    const rowMatches = row.toLowerCase().match(regex);
                    rowMatches && rowMatches.forEach((_, index) => acc.push({ rowIndex, index }));
                    return acc;
                }, []);
                return set(sectionId, matchingRows, acc);
            }, {});
            dispatch(toggleCreateTagModal({ matchingProvisions, searchTerm }));
        } else {
            dispatch(toggleCreateTagModal({ matchingProvisions: {}, searchTerm }));
        }
    }, [playbookText, dispatch]);

    useEffect(() => {
        if (tagToCreate) {
            setLabel(tagToCreate.searchTerm);
        }
    }, [tagToCreate]);

    if (!tagToCreate) {
        return null;
    }

    const { matchingProvisions, searchTerm } = tagToCreate;

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={createTag}
            confirmLabel='Add Tag'
            confirmDisabled={confirmDisabled}
            confirmDisabledTooltip={confirmDisabledTooltip}
            testId={testId}
        >
            <ModalHeader label='Create Tag' icon={Tag} testId={`${testId}-modal`} />
            <div className={styles.createTagModalWrapper}>
                <div className={styles.tagDetailsWrapper}>
                    <div className={styles.tagInputWrapper}>
                        <div className={styles.tagInputTitle} data-testid={`${testId}-text-title`}>Text: </div>
                        <div className={styles.tagInput}>
                            <input
                                className={styles.searchTerm}
                                onChange={e => searchPlaybook(e.target.value)}
                                value={searchTerm}
                                placeholder='What text would you like to tag?'
                                data-testid={`${testId}-text-input`}
                            />
                        </div>
                    </div>
                    <div className={styles.tagInputWrapper}>
                        <div className={styles.tagInputTitle} data-testid={`${testId}-label-title`}>Label: </div>
                        <div className={styles.tagInput}>
                            <Text
                                value={label}
                                onChange={e => setLabel(e.target.value)}
                                testId={`${testId}-label`}
                                marginBottom='0'
                            />
                        </div>
                    </div>
                    <div className={styles.tagInputWrapper}>
                        <div className={styles.tagInputTitle} data-testid={`${testId}-type-title`}>Type: </div>
                        <div className={styles.tagInput}>
                            <Text
                                value={type}
                                onChange={e => setType(e.target.value)}
                                testId={`${testId}-type`}
                                placeholder='Optional...'
                                marginBottom='0'
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.resultsWrapper}>
                    <div className={styles.totalWrapper}>
                        <div className={styles.totalLabel} data-testid={`${testId}-total-matches-label`}>Total Matches:</div>
                        <div className={styles.totalMatches} data-testid={`${testId}-total-matches-number`}>{totalMatches}</div>
                    </div>
                    <div className={styles.matchingProvisions}>
                        <Scrollable maxHeight='calc(50vh - 195px)'>
                            {Object.entries(matchingProvisions).map(([sectionId, matches], index) => (
                                <div className={styles.matchingProvision} key={sectionId}>
                                    <div className={styles.provisionTitle}>
                                        <OverflowTooltip overlayText={provisionTitle(sectionId)} testId={`${testId}-matched-section-${index}`} />
                                    </div>
                                    <div className={styles.matchingCount} data-testid={`${testId}-matched-section-${index}-length`}>{matches.length}</div>
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </div>
            </div>
        </ ConfirmationModal>
    );
};
