import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getIsEditing, toggleEditMyProfile, toggleMyProfileModal } from '../../auth/login/store';
import { dismissAllNotificationsStarted, dismissNotificationStarted, getNotifications, SystemNotification, toggleSystemNotificationModal } from '../../auth/notifications/store';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import styles from '../Home.module.scss';
import { UserNotification } from '../../auth/notifications/Notifications';
import { getCustomFiltersUpdated, getHomeScreenConfigUpdated, getUserDefaultsUpdated, MyProfileSection, resetCustomFiltersSection, resetHomeScreenConfig, setMyProfileSection, setUnsavedProfileChangedModalOpen } from '../store';

export const MyProfileNotifications: React.FC = () => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(getNotifications);
    const isEditing = useAppSelector(getIsEditing);
    const customFiltersUpdated = useAppSelector(getCustomFiltersUpdated);
    const userDefaultsUpdated = useAppSelector(getUserDefaultsUpdated);
    const homeScreenConfigUpdated = useAppSelector(getHomeScreenConfigUpdated);

    const unsavedChanges = useMemo(() => isEditing || customFiltersUpdated || userDefaultsUpdated || homeScreenConfigUpdated, [isEditing, customFiltersUpdated, userDefaultsUpdated, homeScreenConfigUpdated]);

    const closeModal = useCallback(() => {
        dispatch(toggleMyProfileModal(false));
        dispatch(toggleEditMyProfile(false));
        dispatch(setMyProfileSection(MyProfileSection.GENERAL));
        dispatch(resetCustomFiltersSection());
        dispatch(resetHomeScreenConfig());
        if (unsavedChanges) {
            dispatch(setUnsavedProfileChangedModalOpen());
        }
    }, [dispatch, unsavedChanges]);

    const dismissNotification = (notificationId: number) => dispatch(dismissNotificationStarted(notificationId));
    const dismissAllNotifications = () => dispatch(dismissAllNotificationsStarted());
    const toggleUnsavedChangesModal = useCallback(() => dispatch(setUnsavedProfileChangedModalOpen()), [dispatch]);

    const dismissNotificationAndCloseModal = useCallback((notificationId: number) => {
        dispatch(dismissNotificationStarted(notificationId));
        unsavedChanges ? toggleUnsavedChangesModal() : closeModal();
    }, [dispatch, closeModal, unsavedChanges, toggleUnsavedChangesModal]);

    const openSystemNotification = useCallback((notification: SystemNotification) => {
        dispatch(toggleSystemNotificationModal(notification));
        unsavedChanges ? toggleUnsavedChangesModal() : closeModal();
    }, [dispatch, closeModal, unsavedChanges, toggleUnsavedChangesModal]);

    const testId = 'my-profile';

    return (
        <div className={styles.notificationsSectionWrapper}>
            <div className={styles.notificationsTitleWrapper}>
                <div className={styles.notificationsTitle} data-testid={`${testId}-notifications-title`}>Notifications</div>
                {!!notifications.length && (
                    <button className={styles.dismissAllNotificationsButton} onClick={dismissAllNotifications} >
                        Dismiss All
                    </button>
                )}
            </div>
            <div className={styles.notifications}>
                <Scrollable>
                    {notifications.length ? notifications.map(notification => (
                        <UserNotification
                            notification={notification}
                            dismissNotification={dismissNotification}
                            dismissNotificationAndCloseModal={dismissNotificationAndCloseModal}
                            openSystemNotification={openSystemNotification}
                            key={notification.userNotificationId}
                        />
                    )) : (
                        <div className={styles.emptyNotifications}>No new notifications</div>
                    )}
                </Scrollable>
            </div>
        </div>
    );
};
