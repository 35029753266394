import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';
import Modal from 'react-modal';

import { useAppSelector } from '../../../../hooks/react-redux';
import { Button } from '../../../shared/button/Button';
import { Spinner } from '../../../shared/spinner/Spinner';
import { DocumentPreviewViewer } from '../../../documents/document-viewer/DocumentPreviewViewer';
import styles from '../SupplyChain.module.scss';
import { getDoraDocumentPreview, getIsOpening } from '../store';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Document } from '../../../shared/icons';

interface DocumentPreviewModalProps {
    isOpen: boolean;
    closeModal: () => void;
    download: (id: number, description: string) => void;
}

export const DocumentPreviewModal: React.FC<DocumentPreviewModalProps> = ({ isOpen, closeModal, download }) => {
    const isOpeningDocument = useAppSelector(getIsOpening);
    const previewDocument = useAppSelector(getDoraDocumentPreview);

    const label = useMemo(() => previewDocument?.description || 'Preview', [previewDocument]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.documentPreviewModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex' } }}
            shouldCloseOnOverlayClick={!isOpeningDocument}
            shouldCloseOnEsc={!isOpeningDocument}
            onRequestClose={closeModal}
        >
            <div className={styles.documentPreviewWrapper}>
                <ModalHeader label={label} icon={Document} />
                <div className={styles.documentPreview}>
                    {isOpeningDocument || isNull(previewDocument) ? <Spinner size={70} /> : (
                        <DocumentPreviewViewer url={previewDocument.url} mimeType={previewDocument.mimeType} />
                    )}
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' disabled={isOpeningDocument} testId='document-preview-close' />
                    <Button onClick={() => previewDocument && download(previewDocument.id, previewDocument.description)} label='Download' disabled={isOpeningDocument} testId='document-preview-download' />
                </div>
            </div>
        </Modal>
    );
};
