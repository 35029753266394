import React from 'react';

import { LinkedClauseOpinion } from '../../clause-library/store';
import { Position, PositionModal } from '../modal/PositionModal';
import { scopeFormatter } from '../table/arkTableFormatters';
import { OverflowTooltip } from '../tooltip';
import { Scrollable } from '../scrollable/Scrollable';
import styles from './ClauseModal.module.scss';

interface LinkedOpinionsModalProps {
    modalOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    opinionLinks: LinkedClauseOpinion[];
    redirect: (opinionId: number, location: string) => void;
}

export const LinkedOpinionsModal: React.FC<LinkedOpinionsModalProps> = ({
    modalOpen,
    closeModal,
    position,
    opinionLinks,
    redirect
}) => {

    return (
        <PositionModal
            isOpen={modalOpen}
            closeModal={closeModal}
            position={position}
            testId='clause-library-linked-opinions'
            width='450px'
            height='fit-content'
            renderLeftOfClick={true}
            overflow='hidden'
        >
            <div className={styles.opinionLinksWrapper}>
                <Scrollable maxHeight='400px'>
                    {opinionLinks.map(({ jurisdiction, scope, focus, opinionId, location }, index) => (
                        <OverflowTooltip className={styles.opinionLink} key={index} onClick={() => redirect(opinionId, location)} overlayText={`${jurisdiction} - ${scopeFormatter(scope)} - ${focus}`} />
                    ))}
                </Scrollable>
            </div>
        </PositionModal>
    );
};
