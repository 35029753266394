import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isNull } from 'lodash/fp';

import styles from './NettingEngine.module.scss';
import { getIsCustomConclusionValue, getNettingAssumptions, getNettingAssumptionsModalOpen, NettingEngineSummary, NettingResponseInformation, resetTheoreticalState, setNettingAssumptionsModalOpen, toggleIndustryOrCustomConclusions, resetExistingDocumentsState } from './store';
import { ChoiceLeft, ChoiceRight, Delete, QuestionMark, Tick, WarningSign } from '../../shared/icons';
import { Icon } from '../../shared/icon/Icon';
import { NettingEngineJurisdictionResults } from './NettingEngineJurisdictionResults';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { NettingEngineAssumptionModal } from './NettingEngineAssumptionModal';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Carousel } from '../../shared/carousel/Carousel';
import { NettingEngineSectionTenModal } from './NettingEngineSectionTenModal';
import { IconTooltip } from '../../shared/tooltip';
import { NettingEngineWhatAreTheseModal } from './NettingEngineWhatAreTheseModal';

const { green, red, amber, darkRed, amethyst, french } = styles;

const WIDTH_OFFSET = 40;
const HEIGHT_OFFSET = 216;
const ARROW_WIDTH_OFFSET = 28;
const RESULT_MARGIN_PADDING_BORDER_OFFSET = 42;
const CAROUSEL_MARKERS_OFFSET = 10;
const CAROUSEL_MARGIN_RIGHT = 10;

interface NettingEngineResultsProps {
    results: NettingResponseInformation;
    existingDocumentsNettingEngine?: boolean;
}

export const NettingEngineResults: React.FC<NettingEngineResultsProps> = ({ results, existingDocumentsNettingEngine }) => {
    const { jurisdictionalInformation, overallSummary, overallClientSummary, sectionTenImpactsDecision } = results;

    const dispatch = useAppDispatch();
    const [sectionTenModalOpen, setSectionTenModalOpen] = useState(false);
    const [whatIsThisModalOpen, setWhatIsThisModalOpen] = useState(false);
    const nettingEngineAssumptionsModalOpen = useAppSelector(getNettingAssumptionsModalOpen);
    const nettingEngineAssumptions = useAppSelector(getNettingAssumptions);
    const isCustomConclusion = useAppSelector(getIsCustomConclusionValue);
    const openNettingAssumptionsModal = useCallback(() => dispatch(setNettingAssumptionsModalOpen(true)), [dispatch]);
    const closeNettingAssumptionsModal = useCallback(() => dispatch(setNettingAssumptionsModalOpen(false)), [dispatch]);
    const openSectionTenModal = useCallback(() => {
        if (sectionTenImpactsDecision) {
            setSectionTenModalOpen(true);
        }
    }, [setSectionTenModalOpen, sectionTenImpactsDecision]);
    const closeSectionTenModal = useCallback(() => setSectionTenModalOpen(false), [setSectionTenModalOpen]);
    const openWhatIsThisModal = useCallback(() => setWhatIsThisModalOpen(true), [setWhatIsThisModalOpen]);
    const closeWhatIsThisModal = useCallback(() => setWhatIsThisModalOpen(false), [setWhatIsThisModalOpen]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const conclusionsButton = useCallback(() => {
        setIsLoading(true);
        // Loading spinner added in for user experience when switching between industry and custom answers. No data is being fetched
        dispatch(toggleIndustryOrCustomConclusions(!isCustomConclusion));
        setTimeout(() => setIsLoading(false), 150);
    }, [dispatch, setIsLoading, isCustomConclusion]);

    const [screenWidth, screenHeight] = useWindowResize();
    const width = useMemo(() => screenWidth - WIDTH_OFFSET, [screenWidth]);
    const height = useMemo(() => screenHeight - HEIGHT_OFFSET, [screenHeight]);

    const summaryValue = useMemo(() => isCustomConclusion ? overallClientSummary : overallSummary, [isCustomConclusion, overallClientSummary, overallSummary]);
    const showCustomConclusionIcon = useMemo(() => jurisdictionalInformation.some(jusridictionalResult => !isNull(jusridictionalResult.clientSignOffDetails)), [jurisdictionalInformation]);

    const iconToShow = useMemo(() => {
        switch (summaryValue) {
            case NettingEngineSummary.YES:
                return { icon: Tick, color: green };
            case NettingEngineSummary.NO:
                return { icon: Delete, color: red };
            default:
                return { icon: WarningSign, color: amber };
        }
    }, [summaryValue]);

    const markers = useMemo(() => jurisdictionalInformation.map((_, index) => index), [jurisdictionalInformation]);

    const backToTiles = useCallback(() => existingDocumentsNettingEngine ? dispatch(resetExistingDocumentsState()) : dispatch(resetTheoreticalState()), [existingDocumentsNettingEngine, dispatch]);

    const conclusionsLabel = useMemo(() => isCustomConclusion ? 'View Ark51 Conclusions' : 'View Custom Conclusions', [isCustomConclusion]);
    const conslusionIcon = useMemo(() => isCustomConclusion ? ChoiceLeft : ChoiceRight, [isCustomConclusion]);
    const whatAreTheseText = useMemo(() => (!showCustomConclusionIcon && !sectionTenImpactsDecision) ? '(What is this?)' : '(What are these?)', [showCustomConclusionIcon, sectionTenImpactsDecision]);

    const resultsWidth = useMemo(() => width - ((RESULT_MARGIN_PADDING_BORDER_OFFSET + ARROW_WIDTH_OFFSET) - CAROUSEL_MARGIN_RIGHT), [width]);
    const resultsHeight = useMemo(() => height - (RESULT_MARGIN_PADDING_BORDER_OFFSET + CAROUSEL_MARKERS_OFFSET), [height]);

    useEffect(() => () => {
        dispatch(resetTheoreticalState());
        dispatch(resetExistingDocumentsState());
    }, [dispatch]);

    return (
        <div className={styles.nettingResultsWrapper}>
            <div className={styles.titleContainer}>
                <div className={styles.title} data-testid='netting-engine-title'>Netting Engine</div>
            </div>
            <div className={styles.nettingOverallSummary}>
                <div className={styles.backButtonWrapper}>
                    <Link onClick={backToTiles} className={styles.backButton} to='/opinions/netting-engine'>← Back</Link>
                </div>
                <div className={styles.nettingSummary}>Overall Analysis:
                    <span className={styles.nettingOverallSpan} style={{ color: `${iconToShow.color}` }}>{summaryValue}</span>
                    <Icon icon={iconToShow.icon} fontSize={20} color={iconToShow.color} />
                </div>
                <div className={styles.helpIconsWrapper}>
                    {sectionTenImpactsDecision ?
                        <div className={styles.helpIcon} onClick={openSectionTenModal}>
                            <IconTooltip icon={WarningSign} fontSize={28} iconColor={darkRed} content='Section 10(a)' cursor='pointer' placement='left' />
                        </div> :
                        null
                    }
                    {showCustomConclusionIcon ?
                        <div className={styles.helpIcon} onClick={conclusionsButton}>
                            <IconTooltip icon={conslusionIcon} fontSize={28} iconColor={french} content={conclusionsLabel} cursor='pointer' placement='top' />
                        </div> :
                        null
                    }
                    <div className={styles.helpIcon} onClick={openNettingAssumptionsModal}>
                        <IconTooltip icon={QuestionMark} fontSize={28} iconColor={amethyst} content='View Assumptions' cursor='pointer' placement='top' />
                    </div>
                    <div className={styles.whatIsThis} onClick={openWhatIsThisModal}>{whatAreTheseText}</div>
                </div>
            </div>
            <Carousel
                markers={markers}
                height={height}
                width={width}
            >
                {jurisdictionalInformation.map((jurisdiction, index) => (
                    <NettingEngineJurisdictionResults key={index} jurisdictionalResults={jurisdiction} viewCustomConclusions={isCustomConclusion} height={resultsHeight} width={resultsWidth} isLoading={isLoading} index={index} />
                ))}
            </Carousel>
            <NettingEngineAssumptionModal isOpen={nettingEngineAssumptionsModalOpen} closeModal={closeNettingAssumptionsModal} assumptions={nettingEngineAssumptions} />
            <NettingEngineSectionTenModal isOpen={sectionTenModalOpen} closeModal={closeSectionTenModal} />
            <NettingEngineWhatAreTheseModal isOpen={whatIsThisModalOpen} closeModal={closeWhatIsThisModal} sectionTenImpactsDecision={sectionTenImpactsDecision} showCustomConclusionIcon={showCustomConclusionIcon} />
        </div>
    );
};
