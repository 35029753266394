import { useMemo } from 'react';

import { AnalysisView } from '../components/documents/my-documents/store';
import { useWindowResize } from './useWindowResize';

export const ANALYSIS_VIEW_BUTTONS = 40;
export const NAV_BAR = 100;
export const ANALYSIS_MARGIN = 10;
export const PAGE_BORDER = 2;
export const PAGE_MARGIN_PADDING_BORDER = 62;
export const PAGE_VERTICAL_MARGIN_PADDING_BORDER = 54;
export const DATASET_INSTANCE_HEADER = 69;
export const TIMELINE_WRAPPER = 55;
export const BUTTON_WRAPPER = 58;
export const DATASET_HEIGHT_OFFSET = PAGE_MARGIN_PADDING_BORDER + TIMELINE_WRAPPER + DATASET_INSTANCE_HEADER + BUTTON_WRAPPER;

export const useSplitView = (currentAnalysisView: AnalysisView, widthOffset: number, heightOffset: number) => {
    const [screenWidth, screenHeight] = useWindowResize();

    const analysisWidth = useMemo(() => {
        const width = screenWidth - ANALYSIS_VIEW_BUTTONS;
        switch (currentAnalysisView) {
            case AnalysisView.SPLIT:
                return width * 0.5 - widthOffset;
            default:
                return width * 1 - widthOffset;
        }
    }, [currentAnalysisView, screenWidth, widthOffset]);

    const analysisHeight = useMemo(() => screenHeight - NAV_BAR - heightOffset, [screenHeight, heightOffset]);
    return [analysisWidth, analysisHeight];
};
