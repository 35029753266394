import { ApplicationState } from '../../../../store/rootReducer';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { RiskToleranceDataset } from '../../../admin/risk-tolerance/store';
import { DocumentAnalyticsChart, DocumentAnalyticsState, CountryEntityInformation, EntityJurisdictionAnalytics, BaseArkDocument, DocumentRiskTolerance, AllDocumentsRiskTolerance, TableDocumentsRiskToleranceDB, DocumentRiskView, DocumentFieldRiskToleranceAnalytics, EntityDocumentRiskView, PreExecutionDocumentRiskView } from './types';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

const getRoot = (state: ApplicationState): DocumentAnalyticsState => state.document.analytics;

export const getFetchingAnalytics = (state: ApplicationState): DocumentAnalyticsChart[] => getRoot(state).fetchingAnalytics;

export const getTileInView = (state: ApplicationState): DocumentAnalyticsChart | null => getRoot(state).tileInView;

export const getDocumentSpreadAnalytics = (state: ApplicationState): PieChartAnalyticsData[] => getRoot(state).documentSpread;

export const getEntitySpreadAnalytics = (state: ApplicationState): PieChartAnalyticsData[] => getRoot(state).entitySpread;

export const getAvailableAnalyticsEntities = (state: ApplicationState): DropdownOption[] => getRoot(state).availableAnalyticsEntities;
export const getSelectedMyCompanyEntity = (state: ApplicationState): number | null => getRoot(state).selectedMyCompanyEntityId;
export const getEntityJurisdictionAnalytics = (state: ApplicationState): EntityJurisdictionAnalytics[] => getRoot(state).entityJurisdiction;

export const getJurisdictionDocuments = (state: ApplicationState): BaseArkDocument[] => getRoot(state).jurisdictionDocuments;
export const getDocumentPage = (state: ApplicationState): number => getRoot(state).documentPage;
export const getDocumentsTotal = (state: ApplicationState): number => getRoot(state).totalDocuments;

export const getSelectedCountry = (state: ApplicationState): string | null => getRoot(state).selectedCountry;

export const getCountryEntities = (state: ApplicationState): CountryEntityInformation | null => getRoot(state).countryEntities;

export const getCountryEntitiesModalOpen = (state: ApplicationState): boolean => getRoot(state).countryEntityModalOpen;

export const getIsFetchingMyCompanies = (state: ApplicationState): boolean => getRoot(state).fetchingMyCompanies;

export const getDocumentRiskTolerance = (state: ApplicationState): DocumentRiskTolerance | null => getRoot(state).documentRiskTolerance;

export const getDocumentRiskToleranceView = (state: ApplicationState): DocumentRiskView => getRoot(state).documentRiskToleranceView;

export const getEntityDocumentRiskToleranceView = (state: ApplicationState): EntityDocumentRiskView => getRoot(state).entityDocumentRiskToleranceView;

export const getPreExecutionDocumentRiskToleranceView = (state: ApplicationState): PreExecutionDocumentRiskView => getRoot(state).preExecutionDocumentRiskToleranceView;

export const getDocumentRiskToleranceLoading = (state: ApplicationState): boolean => getRoot(state).documentRiskToleranceLoading;

export const getAllDocumentsRiskTolerance = (state: ApplicationState): AllDocumentsRiskTolerance => getRoot(state).allDocumentsRiskTolerance;

export const getEntityDocumentsRiskTolerance = (state: ApplicationState): AllDocumentsRiskTolerance => getRoot(state).entityDocumentsRiskTolerance;

export const getPreExecutionDocumentsRiskTolerance = (state: ApplicationState): AllDocumentsRiskTolerance => getRoot(state).preExecutionDocumentsRiskTolerance;

export const getAllDatasetsRiskTolerance = (state: ApplicationState): RiskToleranceDataset[] => getRoot(state).allDatasetsRiskTolerance;

export const getSelectedRiskToleranceParentDatasetId = (state: ApplicationState): number | null => getRoot(state).selectedRiskToleranceParentDatasetId;

export const getSelectedRiskToleranceChildDatasetId = (state: ApplicationState): number | null => getRoot(state).selectedRiskToleranceChildDatasetId;

export const getSelectedRiskToleranceDatasetFieldId = (state: ApplicationState): string | null => getRoot(state).selectedRiskToleranceDatasetFieldId;

export const getDocumentFieldRiskTolerance = (state: ApplicationState): DocumentFieldRiskToleranceAnalytics[] => getRoot(state).paginatedDatasetFieldRiskTolerance;

export const getTotalRiskFieldDocuments = (state: ApplicationState): number => getRoot(state).paginatedDatasetFieldRiskToleranceTotal;

export const getDocumentFieldRiskToleranceOverallPercentage = (state: ApplicationState): number => getRoot(state).paginatedDatasetFieldRiskToleranceOverallPercentage;

export const getSelectedRiskFieldDocument = (state: ApplicationState): DocumentFieldRiskToleranceAnalytics | null => getRoot(state).documentRiskFieldTolerance;

// Risk Tolerance Documents Table Pagination
export const getPaginatedRiskToleranceDocuments = (state: ApplicationState): TableDocumentsRiskToleranceDB[] => getRoot(state).paginatedRiskToleranceDocuments;

export const getFetchingRiskToleranceDocuments = (state: ApplicationState): boolean => getRoot(state).fetchingRiskToleranceDocuments;

export const getRiskToleranceDocumentsPageNumber = (state: ApplicationState): number => getRoot(state).riskToleranceDocumentsPage;
export const getTotalRiskToleranceDocuments = (state: ApplicationState): number => getRoot(state).totalPaginatedRiskToleranceDocuments;
export const getRiskToleranceDocumentsPageSize = (state: ApplicationState): number => getRoot(state).riskToleranceDocumentsPageSize;
export const getRiskToleranceDocumentsFilters = (state: ApplicationState): TableFilters => getRoot(state).riskToleranceDocumentsFilters;
export const getRiskToleranceDocumentsColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).riskToleranceDocumentsSort;

// Risk Tolerance Dataset Field Table Pagination
export const getRiskToleranceDatasetFieldPageNumber = (state: ApplicationState): number => getRoot(state).riskToleranceDatasetFieldPage;
export const getTotalRiskToleranceDatasetField = (state: ApplicationState): number => getRoot(state).paginatedDatasetFieldRiskToleranceTotal;
export const getRiskToleranceDatasetFieldPageSize = (state: ApplicationState): number => getRoot(state).riskToleranceDatasetFieldPageSize;
export const getRiskToleranceDatasetFieldFilters = (state: ApplicationState): TableFilters => getRoot(state).riskToleranceDatasetFieldFilters;
export const getRiskToleranceDatasetFieldColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).riskToleranceDatasetFieldSort;
