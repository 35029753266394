import { getOr, isEqual, isNull, isUndefined } from 'lodash/fp';
import React, { MouseEvent, useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/react-redux';
import { HiddenFields } from '../../../../../admin/my-datasets/store';
import { IconButton } from '../../../../../shared/button/IconButton';
import styles from '../../../../../shared/datasets/SharedStyling.module.scss';
import { Icon } from '../../../../../shared/icon/Icon';
import { ArrowRight, CaretDown, CaretSide } from '../../../../../shared/icons';
import { Scrollable } from '../../../../../shared/scrollable/Scrollable';
import { InformationTooltip } from '../../../../../shared/tooltip';
import { DatasetFieldType, DatasetSection, SingleDatasetField } from '../../../../store';
import { InstanceIdentifier, InstanceMLData } from '../../../../store/mlTypes';
import {
    addOpenFieldSection,
    DatasetInstance,
    DatasetInstanceSection,
    FormDatasetInstance,
    getAnnexDefinitionFieldIds,
    getCurrentInstances,
    getDatasetInstanceTimeline,
    getOpenFieldsAndSections,
    Instance,
    InstanceField,
    OpenFieldSection,
    OpenFieldType,
    OpenSection,
    removeOpenFieldSection,
    scrollToSection,
    SingleInstanceField,
    TableDatasetInstance,
    UpdatedFormField
} from '../../../store';
import { isFormDatasetInstance, isGroupInstanceField } from '../../../store/typeAssertions';
import { MLGroupField } from './MLGroupField';
import { MLMultiToggle } from './MLMultiToggleField';
import { MLSingleField } from './MLSingleField';

const { mandatory } = styles;

const displayEntireSectionsKeys = ['partyANoticesAddress', 'partyATradingAddress', 'partyBNoticesAddress', 'partyBTradingAddress', 'partyADocumentationAddress', 'partyBDocumentationAddress'];

interface FieldsProps {
    fields: InstanceField[];
    fieldsUpdated: UpdatedFormField[];
    sectionId: string;
    isEditing: boolean;
    isUpdating: boolean;
    isAnnexInstance: boolean;
    annexFieldIds: string[];
    datasetOnlySection?: boolean;
    datasetId: number;
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    mlData: InstanceMLData;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    documentName: string;
}

export const Fields: React.FC<FieldsProps> = ({
    fields,
    sectionId,
    isEditing,
    isUpdating,
    fieldsUpdated,
    annexFieldIds,
    isAnnexInstance,
    datasetOnlySection = false,
    datasetId,
    parentFieldId,
    instanceExecutedDateMatchesParent,
    modalInstance,
    toggleSection,
    getSectionOpen,
    mlData,
    hiddenFields,
    datasetHidden,
    documentName
}) => {
    const showEntireSection = useMemo(() => !isUndefined(mlData.instanceMLData.find(({ key, ...mlField }) => displayEntireSectionsKeys.includes(key) && sectionId === mlField.sectionId)), [mlData, sectionId]);
    const getContent = useCallback((field: InstanceField, index: number, fields: InstanceField[], documentName: string) => {
        if (isGroupInstanceField(field)) {
            if (field.type === DatasetFieldType.GROUP) {
                return (
                    <MLGroupField
                        field={field}
                        key={field.id}
                        groupIndex={index}
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        annexFieldIds={annexFieldIds}
                        isAnnexInstance={isAnnexInstance}
                        datasetId={datasetId}
                        parentFieldId={parentFieldId}
                        instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                        modalInstance={modalInstance}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        mlData={mlData}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        showEntireSection={showEntireSection}
                    />
                );
            }
            if (field.type === DatasetFieldType.MULTI_TOGGLE) {
                return (
                    <MLMultiToggle
                        key={field.id}
                        field={field}
                        groupIndex={index}
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        annexFieldIds={annexFieldIds}
                        isAnnexInstance={isAnnexInstance}
                        datasetId={datasetId}
                        parentFieldId={parentFieldId}
                        instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                        modalInstance={modalInstance}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        mlData={mlData}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        showEntireSection={showEntireSection}
                    />
                );
            }
        }
        if (field.label === 'Is Multiple Transaction Payment Netting limited to certain products?' && documentName === '1992 ISDA Master Agreement') {
            const section2CAppliesField = (fields as SingleInstanceField[]).find(({ systemId }) => systemId === 'subParagraphSectionTwoCApplies')?.value;
            const section2CApplies = !isNull(section2CAppliesField) && (section2CAppliesField as string[])[0] === 'Yes' || null;
            const multipleProducts = (fields as SingleInstanceField[]).find(({ systemId }) => systemId === 'multipleTransactionPaymentNettingType')?.value as string[];

            let edgeCaseAnswer = null;
            if (!isNull(section2CAppliesField) && section2CApplies) {
                edgeCaseAnswer = 'Not Applicable';
            }
            if (!isNull(section2CAppliesField) && !section2CApplies && multipleProducts.includes('All')) {
                edgeCaseAnswer = 'No';
            }
            if (!isNull(section2CAppliesField) && !section2CApplies && multipleProducts.length > 1) {
                edgeCaseAnswer = 'Yes';
            }
            return (
                <MLSingleField
                    key={field.id}
                    field={field as SingleInstanceField}
                    index={index}
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    annexFieldIds={annexFieldIds}
                    isAnnexInstance={isAnnexInstance}
                    datasetOnlySection={datasetOnlySection}
                    datasetId={datasetId}
                    parentFieldId={parentFieldId}
                    instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                    modalInstance={modalInstance}
                    toggleSection={toggleSection}
                    getSectionOpen={getSectionOpen}
                    mlData={mlData}
                    hiddenFields={hiddenFields}
                    datasetHidden={datasetHidden}
                    edgeCaseAnswer={edgeCaseAnswer}
                    showEdgeCaseLabel
                    showEntireSection={showEntireSection}
                />
            );
        }
        if (field.label === 'Is Multiple Transaction Payment Netting limited to certain products?' && documentName === '2002 ISDA Master Agreement') {
            const nettingApplicableField = (fields as SingleInstanceField[]).find(({ systemId }) => systemId === 'multipleTransactionPaymentNettingApply')?.value;
            const nettingApplicable = !isNull(nettingApplicableField) && (nettingApplicableField as string[])[0] === 'Yes';
            const multipleProducts = (fields as SingleInstanceField[]).find(({ systemId }) => systemId === 'multipleTransactionPaymentNettingType')?.value as string[];

            let edgeCaseAnswer = null;
            if (!isNull(nettingApplicableField) && !nettingApplicable) {
                edgeCaseAnswer = 'Not Applicable';
            }
            if (!isNull(nettingApplicableField) && nettingApplicable && multipleProducts.includes('All')) {
                edgeCaseAnswer = 'No';
            }
            if (!isNull(nettingApplicableField) && nettingApplicable && multipleProducts.length > 1) {
                edgeCaseAnswer = 'Yes';
            }
            return (
                <MLSingleField
                    key={field.id}
                    field={field as SingleInstanceField}
                    index={index}
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    annexFieldIds={annexFieldIds}
                    isAnnexInstance={isAnnexInstance}
                    datasetOnlySection={datasetOnlySection}
                    datasetId={datasetId}
                    parentFieldId={parentFieldId}
                    instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                    modalInstance={modalInstance}
                    toggleSection={toggleSection}
                    getSectionOpen={getSectionOpen}
                    mlData={mlData}
                    hiddenFields={hiddenFields}
                    datasetHidden={datasetHidden}
                    edgeCaseAnswer={edgeCaseAnswer}
                    showEdgeCaseLabel
                    showEntireSection={showEntireSection}
                />
            );
        }
        return (
            <MLSingleField
                key={field.id}
                field={field as SingleInstanceField}
                index={index}
                sectionId={sectionId}
                isEditing={isEditing}
                isUpdating={isUpdating}
                fieldsUpdated={fieldsUpdated}
                annexFieldIds={annexFieldIds}
                isAnnexInstance={isAnnexInstance}
                datasetOnlySection={datasetOnlySection}
                datasetId={datasetId}
                parentFieldId={parentFieldId}
                instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                modalInstance={modalInstance}
                toggleSection={toggleSection}
                getSectionOpen={getSectionOpen}
                mlData={mlData}
                hiddenFields={hiddenFields}
                datasetHidden={datasetHidden}
                showEntireSection={showEntireSection}
            />
        );
    }, [
        sectionId,
        isEditing,
        isUpdating,
        fieldsUpdated,
        annexFieldIds,
        isAnnexInstance,
        datasetOnlySection,
        datasetId,
        parentFieldId,
        instanceExecutedDateMatchesParent,
        modalInstance,
        toggleSection,
        getSectionOpen,
        hiddenFields,
        datasetHidden,
        mlData,
        showEntireSection
    ]);

    return (
        <div className={styles.allFieldsWrapper}>
            {fields && fields.map((field, index) => getContent(field, index, fields, documentName))}
        </div>
    );
};

interface SectionProps {
    section: DatasetSection;
    datasetInstance: FormDatasetInstance;
    sectionIndex: number;
    isEditing: boolean;
    isUpdating: boolean;
    isAnnexInstance: boolean;
    annexFieldIds: string[];
    datasetId: number;
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    mlData: InstanceMLData;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    allInstances: Instance[];
    documentName: string;
}

export const checkDatasetFieldHasMlData = (currentInstances: DatasetInstance[], linkedDatasetId: string | null, mlData: InstanceMLData) => {
    let datasetHasMlData = false;
    if (!isNull(linkedDatasetId)) {
        const linkedDatasetInstance = currentInstances.find(({ datasetId }) => datasetId === parseInt(linkedDatasetId));
        if (!isUndefined(linkedDatasetInstance)) {
            if (isFormDatasetInstance(linkedDatasetInstance)) {
                const formInstance = linkedDatasetInstance as FormDatasetInstance;
                const { datasetSections, datasetFields } = formInstance;
                const linkedSectionsHaveMl = datasetSections.some(({ id }) => mlData.instanceMLData.find(({ sectionId }) => sectionId === id));
                let childFieldHasMlData: InstanceIdentifier[] = [];
                datasetSections.map(({ id }) => {
                    const fieldsForSection = datasetFields[id];
                    const fieldIds = fieldsForSection.map(({ id }) => id);
                    const fieldsHaveMlData = mlData.instanceMLData.filter(data => fieldIds.includes(data.parentFieldId));
                    if (fieldsHaveMlData.length > 0) {
                        childFieldHasMlData = [...childFieldHasMlData, ...fieldsHaveMlData];
                    }
                });
                const linkedDatasetHasData = childFieldHasMlData.length > 0 || !!linkedSectionsHaveMl;
                datasetHasMlData = linkedDatasetHasData;
            } else {
                const tableInstance = linkedDatasetInstance as TableDatasetInstance;
                const { datasetRows, datasetFields } = tableInstance;
                const rowHasMl = datasetRows.some(id => mlData.instanceMLData.find(({ rowId }) => rowId === id));
                let rowHasMlData: InstanceIdentifier[] = [];
                datasetRows.map(id => {
                    const fieldsForRow = datasetFields[id];
                    const fieldIds = fieldsForRow.map(({ id }) => id);
                    const fieldsHaveMlData = mlData.instanceMLData.filter(data => fieldIds.includes(data.parentFieldId));
                    if (fieldsHaveMlData.length > 0) {
                        rowHasMlData = [...rowHasMlData, ...fieldsHaveMlData];
                    }
                });
                const linkedDatasetHasData = rowHasMlData.length > 0 || rowHasMl;
                datasetHasMlData = linkedDatasetHasData;
            }
        }
    }
    return datasetHasMlData;
};

export const Section: React.FC<SectionProps> = ({
    section,
    datasetInstance,
    sectionIndex,
    isEditing,
    isUpdating,
    annexFieldIds,
    isAnnexInstance,
    datasetId,
    parentFieldId,
    instanceExecutedDateMatchesParent,
    modalInstance,
    getSectionOpen,
    toggleSection,
    mlData,
    hiddenFields,
    datasetHidden,
    allInstances,
    documentName
}) => {
    const dispatch = useAppDispatch();
    const { id, label, description } = section;
    const openSection: OpenSection = useMemo(() => ({ sectionId: id, datasetId: datasetId.toString(), type: OpenFieldType.SECTION, parentFieldId }), [id, datasetId, parentFieldId]);
    const sectionOpen = getSectionOpen(openSection);
    const fields = datasetInstance.datasetFields[id];
    const datasetOnlySection = fields.length === 1 && fields[0].type === DatasetFieldType.DATASET;
    const sectionOpenIcon = sectionOpen ? CaretDown : CaretSide;
    const { fieldsUpdated } = datasetInstance;
    const pageRef: string | null = useMemo(() => getOr(null, 'pageRef', section), [section]);

    const scrollToSectionPage = useCallback(() => {
        if (!isNull(pageRef)) {
            dispatch(scrollToSection(section as DatasetInstanceSection));
        }
    }, [pageRef, section, dispatch]);

    const onSectionClick = useCallback(() => {
        if (!sectionOpen) {
            scrollToSectionPage();
        }
        toggleSection(openSection);
    }, [sectionOpen, scrollToSectionPage, toggleSection, openSection]);

    const onRedirectClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        scrollToSectionPage();
    }, [scrollToSectionPage]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields && hiddenFields[datasetId] || [], [datasetId, hiddenFields]);
    const sectionHidden = useMemo(() => !!currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(({ sectionId }) => sectionId === id), [currentHiddenDatasetFields, id]);

    const allFieldIdsForSection = useMemo(() => fields.map(field => field.id), [fields]);
    const mlDataIncludesSectionId = useMemo(() => !!mlData.instanceMLData.find(({ sectionId }) => sectionId === id), [mlData, id]);

    const datasetOnlySectionHasMlData = useMemo(() => {
        if (datasetOnlySection) {
            const currentInstances = allInstances.map(({ instance }) => instance);
            const linkedDatasetId = (fields[0] as SingleDatasetField).settings.datasetLinked || null;
            return checkDatasetFieldHasMlData(currentInstances, linkedDatasetId, mlData);
        }
        return false;
    }, [datasetOnlySection, allInstances, fields, mlData]);

    const mlDataForSection = useMemo(() => mlData.instanceMLData.filter(data => allFieldIdsForSection.includes(data.parentFieldId)).length > 0 || mlDataIncludesSectionId || datasetOnlySectionHasMlData, [mlData, allFieldIdsForSection, mlDataIncludesSectionId, datasetOnlySectionHasMlData]);

    if (sectionHidden || !mlDataForSection) {
        return null;
    }

    return (
        <div key={id} className={styles.sectionWrapper} data-testid={`form-ml-modal-section-${sectionIndex}-wrapper`}>
            <div className={styles.sectionHeader} onClick={onSectionClick} data-testid={`form-ml-modal-section-${sectionIndex}-collapse`}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={sectionOpenIcon} fontSize={18} />
                </div>
                <div className={styles.sectionLabel} data-testid={`form-ml-modal-section-${sectionIndex}-label`}>{label}</div>
                {description && <InformationTooltip content={description} />}
                {!isNull(pageRef) && <div className={styles.redirectWrapper}><IconButton icon={ArrowRight} onClick={onRedirectClick} fontSize={16} color={mandatory} /></div>}
            </div>
            {sectionOpen &&
                <Fields
                    fields={fields}
                    sectionId={id}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    annexFieldIds={annexFieldIds}
                    isAnnexInstance={isAnnexInstance}
                    datasetOnlySection={datasetOnlySection}
                    datasetId={datasetId}
                    parentFieldId={parentFieldId}
                    instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                    modalInstance={modalInstance}
                    toggleSection={toggleSection}
                    getSectionOpen={getSectionOpen}
                    mlData={mlData}
                    hiddenFields={hiddenFields}
                    datasetHidden={datasetHidden}
                    documentName={documentName}
                />
            }
        </div>
    );
};

interface MLFormInstanceProps {
    datasetInstance: FormDatasetInstance;
    isEditing: boolean;
    isUpdating: boolean;
    parentFieldId: string;
    hiddenFields: HiddenFields;
    instanceExecutedDateMatchesParent: boolean;
    mlData: InstanceMLData;
    modalInstance?: boolean;
    datasetHidden?: boolean;
}

export const MLFormInstance: React.FC<MLFormInstanceProps> = ({ datasetInstance, isEditing, isUpdating, hiddenFields, modalInstance, parentFieldId, datasetHidden, instanceExecutedDateMatchesParent, mlData }) => {
    const dispatch = useAppDispatch();
    const annexFieldIds = useAppSelector(getAnnexDefinitionFieldIds);
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const allInstances = useAppSelector(getCurrentInstances);
    const timeline = useAppSelector(getDatasetInstanceTimeline);

    const timelineInstance = timeline && timeline.instances.find(({ datasetInstanceId }) => datasetInstanceId === datasetInstance.datasetInstanceId);
    let documentName = timeline?.documentName || '';

    if (timelineInstance) {
        documentName = timelineInstance.documentName;
    }

    const firstSection = useMemo(() => datasetInstance.datasetSections[0], [datasetInstance.datasetSections]);
    const isShortFormInstance = useMemo(() => datasetInstance.datasetSections.length === 1 && !firstSection.label, [datasetInstance.datasetSections, firstSection]);
    const shortFormFields = useMemo(() => datasetInstance.datasetFields[firstSection.id], [datasetInstance.datasetFields, firstSection]);
    const datasetOnlySection = useMemo(() => shortFormFields.length === 1 && shortFormFields[0].type === DatasetFieldType.DATASET, [shortFormFields]);
    const datasetId = useMemo(() => isNull(datasetInstance.annexDefinitionId) ? datasetInstance.datasetId! : datasetInstance.annexDefinitionId!, [datasetInstance]);

    const getSectionOpen = useCallback((fieldSection: OpenFieldSection) => openFieldsAndSections.some(openSection => isEqual(openSection, fieldSection)), [openFieldsAndSections]);

    const toggleSection = useCallback((fieldSection: OpenFieldSection) => {
        const sectionOpen = openFieldsAndSections.find(openSection => isEqual(openSection, fieldSection));
        sectionOpen ? dispatch(removeOpenFieldSection(sectionOpen)) : dispatch(addOpenFieldSection(fieldSection));
    }, [dispatch, openFieldsAndSections]);

    return (
        <div className={styles.formInstanceWrapper} data-testid='form-ml-modal-dataset-instance-wrapper'>
            <div style={{ height: '100%' }}>
                <Scrollable>
                    {isShortFormInstance ? (
                        <Fields
                            fields={datasetInstance.datasetFields[firstSection.id]}
                            isEditing={isEditing}
                            sectionId={firstSection.id}
                            isUpdating={isUpdating}
                            fieldsUpdated={datasetInstance.fieldsUpdated}
                            annexFieldIds={annexFieldIds}
                            isAnnexInstance={!!datasetInstance.annexDefinitionId}
                            datasetOnlySection={datasetOnlySection}
                            datasetId={datasetId}
                            modalInstance={modalInstance}
                            toggleSection={toggleSection}
                            getSectionOpen={getSectionOpen}
                            mlData={mlData}
                            parentFieldId={parentFieldId}
                            hiddenFields={hiddenFields}
                            datasetHidden={datasetHidden}
                            instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                            documentName={documentName}
                        />
                    ) : (datasetInstance.datasetSections.map((section, index) => (
                        <Section
                            section={section}
                            key={section.id}
                            datasetInstance={datasetInstance}
                            sectionIndex={index}
                            isEditing={isEditing}
                            isUpdating={isUpdating}
                            annexFieldIds={annexFieldIds}
                            isAnnexInstance={!!datasetInstance.annexDefinitionId}
                            datasetId={datasetId}
                            modalInstance={modalInstance}
                            toggleSection={toggleSection}
                            getSectionOpen={getSectionOpen}
                            mlData={mlData}
                            parentFieldId={parentFieldId}
                            hiddenFields={hiddenFields}
                            datasetHidden={datasetHidden}
                            instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                            allInstances={allInstances}
                            documentName={documentName}
                        />
                    )))}
                </Scrollable>
            </div>
        </div>
    );
};
