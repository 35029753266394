import { useMemo } from 'react';

import { HiddenDocumentField, HiddenDocumentSection, isHiddenDocumentField } from '../components/admin/dataset-builder/store';
import { HiddenField, HiddenSection, isHiddenField } from '../components/admin/my-datasets/store';

export const useSingleHiddenFields = (currentHiddenDatasetFields: (HiddenField | HiddenSection)[]) => {
    const hiddenSingleFields = useMemo(() => currentHiddenDatasetFields.filter(field => isHiddenField(field)) as HiddenField[], [currentHiddenDatasetFields]);
    return hiddenSingleFields;
};

export const useSingleHiddenDocumentFields = (currentHiddenDatasetFields: (HiddenDocumentField | HiddenDocumentSection)[]) => {
    const hiddenSingleFields = useMemo(() => currentHiddenDatasetFields.filter(field => isHiddenDocumentField(field)) as HiddenDocumentField[], [currentHiddenDatasetFields]);
    return hiddenSingleFields;
};
