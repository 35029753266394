import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getAvailableDocumentNames } from '../../../admin/documents/store';
import { isLinkAgreementType } from '../../../admin/documents/store/typeAssertions';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermission } from '../../../auth/login/store';
import { IncompleteDetailsModal } from '../../../shared/modal/IncompleteDetailsModal';
import { Spinner } from '../../../shared/spinner/Spinner';
import { DocumentType, getIncompleteDocument, getIncompleteDocumentDetailsUpdated, getIncompleteDocumentPageTotal, getIncompleteDocumentUrl, getIncompleteScheduleStartPage, getIsOpening, getIsUpdatingDocument, getSelectedIncompleteDocuments, setIncompleteDocumentSchedulePage, skipIncompleteDocument, toggleDocumentDetailsModal, updateDocumentDetailsStarted } from '../store';
import { DocumentDetails } from './DocumentDetails';

interface DocumentDetailsModalProps {
    isOpen: boolean;
}

export const DocumentDetailsModal: React.FC<DocumentDetailsModalProps> = ({ isOpen }) => {
    const dispatch = useAppDispatch();
    const selectedIncompleteDocuments = useAppSelector(getSelectedIncompleteDocuments);
    const isOpeningDocument = useAppSelector(getIsOpening);
    const documentUrl = useAppSelector(getIncompleteDocumentUrl);
    const document = useAppSelector(getIncompleteDocument);
    const scheduleStartPage = useAppSelector(getIncompleteScheduleStartPage);
    const numPages = useAppSelector(getIncompleteDocumentPageTotal);
    const documentDetailsUpdated = useAppSelector(getIncompleteDocumentDetailsUpdated);
    const isUpdatingDocumentDetails = useAppSelector(getIsUpdatingDocument);
    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const [showDocumentPreview, setShowDocumentPreview] = useState<boolean>(true);
    const uploadDocumentName = useMemo(() => document && document.documentNameId && allDocumentNames.find(documentName => documentName.documentNameId! === document.documentNameId!) || null, [document, allDocumentNames]);
    const documentIsLinkAgreementType = (uploadDocumentName && uploadDocumentName.agreementTypeId) && isLinkAgreementType(uploadDocumentName.agreementTypeId);
    const hasCompleteDocumentPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.COMPLETE_DOCUMENT_DETAILS]));

    const closeModal = () => dispatch(toggleDocumentDetailsModal(false));
    const completeUpload = () => dispatch(updateDocumentDetailsStarted());
    const updateAndSkip = () => documentDetailsUpdated ? completeUpload() : dispatch(skipIncompleteDocument());
    const toggleDocumentPreview = () => setShowDocumentPreview(!showDocumentPreview);

    const setSelectedPage = useCallback((page: number | null) => dispatch(setIncompleteDocumentSchedulePage(page)), [dispatch]);
    const mimeType = document && document.mimeType;
    const documentTypeSelected = !isNull(document) && !isNull(document.documentType);
    const isSecondaryDocumentType = documentTypeSelected && [DocumentType.AMENDMENT, DocumentType.RESTATEMENT].includes(document!.documentType!);
    const isOriginalDocumentType = documentTypeSelected && [DocumentType.ORIGINAL, DocumentType.REPLICATION].includes(document!.documentType!);
    const missingDescription = !isNull(document) && !document.documentDescription.length;
    const missingExecutedDate = !isNull(document) && !document.executedDate;
    const secondaryWithoutOriginalLinked = isSecondaryDocumentType && !isNull(document) && !document.originalDocumentId;
    const originalDocumentWithoutDocumentName = isOriginalDocumentType && !isNull(document) && !document.documentNameId;
    const agencyDocumentWithoutAnnexDefinition = isOriginalDocumentType && !isNull(document) && !isNull(document.documentNameId) && !!document.isAgency && !document.annexDefinitionId && !documentIsLinkAgreementType;
    const originalDocumentWithoutEntities = isOriginalDocumentType && !isNull(document) && !(document.entityA && document.entityB);
    const isAgencyDocument = !!document?.isAgency;
    const noActingAgencyEntitySelected = isAgencyDocument && isOriginalDocumentType && !document?.agentEntitySelected;
    const isSupportDocument = useMemo(() => !isNull(document) && document.documentType === DocumentType.SUPPORT, [document]);
    const isPreExecutionDraft = useMemo(() => !isNull(document) && document.documentType === DocumentType.PRE_EXECUTION_DRAFT, [document]);
    const missingBaseDocuments = useMemo(() => !isNull(document) && (isUndefined(document.baseDocuments) || !document.baseDocuments.length), [document]);
    const missingScheduleStartPage = useMemo(() => !isNull(document) && !isNull(document.documentType) && document.documentType === DocumentType.ORIGINAL && isNull(document.scheduleStartPage), [document]);
    const missingScheduleEndPage = useMemo(() => !isNull(document) && !isNull(document.documentType) && document.documentType === DocumentType.ORIGINAL && isNull(document.scheduleEndPage), [document]);
    const selectedPageIsGreaterThanTotalPages = useMemo(() => !isNull(document) && !isNull(document.scheduleStartPage) && !isNull(document.scheduleEndPage) && !isNull(numPages) && (document.scheduleStartPage || document.scheduleEndPage) > numPages, [document, numPages]);
    const missingPartyA = useMemo(() => !isNull(document) && isNull(document.entityA), [document]);
    const missingPartyB = useMemo(() => !isNull(document) && isNull(document.entityB), [document]);
    const documentNameIdMissing = useMemo(() => !isNull(document) && isNull(document.documentNameId), [document]);
    const completeDisabled = useMemo(() =>
        !hasCompleteDocumentPermission
        || !documentTypeSelected
        || missingDescription
        || ((!isSupportDocument && !isPreExecutionDraft) && (missingExecutedDate || secondaryWithoutOriginalLinked || originalDocumentWithoutDocumentName || originalDocumentWithoutEntities || agencyDocumentWithoutAnnexDefinition || isUpdatingDocumentDetails || noActingAgencyEntitySelected))
        || (isSupportDocument && missingBaseDocuments)
        || selectedPageIsGreaterThanTotalPages
        || missingScheduleStartPage
        || missingScheduleEndPage
        || isPreExecutionDraft && (missingPartyA || missingPartyB || documentNameIdMissing), [isSupportDocument, missingBaseDocuments, missingDescription, documentTypeSelected, missingExecutedDate, secondaryWithoutOriginalLinked, originalDocumentWithoutDocumentName, originalDocumentWithoutEntities, agencyDocumentWithoutAnnexDefinition, isUpdatingDocumentDetails, noActingAgencyEntitySelected, selectedPageIsGreaterThanTotalPages, missingScheduleStartPage, missingScheduleEndPage, isPreExecutionDraft, missingPartyA, missingPartyB, documentNameIdMissing, hasCompleteDocumentPermission]
    );

    const completeDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!hasCompleteDocumentPermission) {
            disabledArray.push('You are not permitted to complete this stage of document upload');
        }
        if (isPreExecutionDraft) {
            if (missingPartyA) {
                disabledArray.push('Your are required to provide Party A');
            }
            if (missingPartyB) {
                disabledArray.push('Your are required to provide Party B');
            }
            if (documentNameIdMissing) {
                disabledArray.push('Your are required to provide a document');
            }
        }
        if (missingDescription) {
            disabledArray.push('Your document requires a description');
        }
        if (selectedPageIsGreaterThanTotalPages) {
            disabledArray.push('You cannot have a selected start page greater than the total number of pages');
        }
        if (missingScheduleStartPage) {
            disabledArray.push('You are required to provide a schedule start page');
        }
        if (missingScheduleEndPage) {
            disabledArray.push('You are required to provide a schedule end page');
        }
        if (!documentTypeSelected) {
            disabledArray.push('You have not selected the type of document you are uploading');
        } else {
            if (!isSupportDocument && !isPreExecutionDraft) {
                if (missingExecutedDate) {
                    disabledArray.push('Your document requires a date of execution');
                }
                if (originalDocumentWithoutDocumentName) {
                    disabledArray.push('You have not selected the type of document you are uploading');
                }
                if (agencyDocumentWithoutAnnexDefinition) {
                    disabledArray.push('You have not selected an agency annex to support your agency document');
                }
                if (originalDocumentWithoutEntities) {
                    disabledArray.push('You have not selected the entities linked to this agreement');
                }
                if (secondaryWithoutOriginalLinked) {
                    disabledArray.push('You have not linked your new document to an existing document');
                }
                if (noActingAgencyEntitySelected) {
                    disabledArray.push('You have not selected which entity is acting as the agent');
                }
            } else if (isSupportDocument && missingBaseDocuments) {
                disabledArray.push('You must select at least one document to link this support document to');
            }
        }
        return disabledArray;
    }, [
        missingDescription,
        missingExecutedDate,
        documentTypeSelected,
        secondaryWithoutOriginalLinked,
        originalDocumentWithoutDocumentName,
        originalDocumentWithoutEntities,
        agencyDocumentWithoutAnnexDefinition,
        noActingAgencyEntitySelected,
        isSupportDocument,
        missingBaseDocuments,
        selectedPageIsGreaterThanTotalPages,
        missingScheduleStartPage,
        missingScheduleEndPage,
        isPreExecutionDraft,
        missingPartyA,
        missingPartyB,
        documentNameIdMissing,
        hasCompleteDocumentPermission
    ]);

    const updateAndSkipLabel = useMemo(() => {
        if (documentDetailsUpdated) {
            return selectedIncompleteDocuments.length < 2 ? 'Update & Close' : 'Update & Skip';
        }
        return 'Skip';
    }, [documentDetailsUpdated, selectedIncompleteDocuments]);

    const updateAndSkipDisabled = (selectedIncompleteDocuments.length < 2 && !documentDetailsUpdated) || !completeDisabled || isUpdatingDocumentDetails || !hasCompleteDocumentPermission;
    const updateAndSkipDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!hasCompleteDocumentPermission) {
            disabledArray.push('You are not permitted to complete this stage of document upload');
        }
        if (selectedIncompleteDocuments.length < 2 && !documentDetailsUpdated) {
            disabledArray.push('This is the only remaining document that you recently uploaded');
        }
        if (!completeDisabled && !isOpeningDocument) {
            disabledArray.push('Document is complete');
        }
        return disabledArray;
    }, [selectedIncompleteDocuments, documentDetailsUpdated, completeDisabled, isOpeningDocument, hasCompleteDocumentPermission]);

    return (
        <IncompleteDetailsModal
            isOpen={isOpen}
            url={documentUrl}
            testId='incomplete-documents'
            closeModal={closeModal}
            completeUpload={completeUpload}
            updateAndSkip={updateAndSkip}
            completeDisabledTooltip={completeDisabledTooltip}
            updateAndSkipDisabledTooltip={updateAndSkipDisabledTooltip}
            updateAndSkipDisabled={updateAndSkipDisabled}
            updateAndSkipLabel={updateAndSkipLabel}
            completeDisabled={completeDisabled}
            mimeType={mimeType}
            isOpeningDocument={isOpeningDocument}
            label='Incomplete Document Upload Details'
            showDocumentPreview={showDocumentPreview}
            toggleDocumentPreview={toggleDocumentPreview}
            selectedPage={scheduleStartPage}
            setSelectedPage={setSelectedPage}
            isPreExecutionDraft={isPreExecutionDraft}
        >
            {document ? <DocumentDetails arkDocument={document} /> : <Spinner size={70} />}
        </IncompleteDetailsModal>
    );
};
