import React from 'react';
import Modal from 'react-modal';

import { Button } from '../button/Button';
import styles from './Modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { extendToken, getLogoutWarningModalOpen } from '../../auth/login/store';
import { ModalHeader } from './ModalHeader';
import { User } from '../icons';

export const LogoutWarningModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getLogoutWarningModalOpen);
    const extend = () => dispatch(extendToken());

    return (
        <Modal
            isOpen={isOpen}
            className={styles.modal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 10 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className={styles.wrapper}>
                <ModalHeader label='Are you still there?' icon={User} testId='logout-warning-modal' />
                <div className={styles.logoutWarningMessage} data-testid='logout-warning-modal-message'>You will be logged out shortly due to inactivity</div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={extend} label='Stay Logged In' testId='logout-warning-modal' />
                </div>
            </div>
        </Modal>
    );
};
