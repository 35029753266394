import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { isUndefined } from 'lodash/fp';

import { useAppSelector } from '../../hooks/react-redux';
import { ClientFeaturePermission, FeaturePermission } from '../admin/users/store';
import { getCheckAuth, getClientHasFeaturePermission, getIsLoggedIn, getMyProfile, getProfileModalOpen, getUser, getUserHasFeaturePermission, getUserHasOneFeaturePermissionNoAdmin, getUserRole } from '../auth/login/store';
import { UserRole, allRoles } from '../constants/permittedRoles';
import { MyProfile } from '../home/my-profile/MyProfile';
import { HomeWrapper } from '../navigation/HomeWrapper';
import { Spinner } from './spinner/Spinner';
import { PortfolioStatistics } from './portfolio-statistics/PortfolioStatistics';

export interface SecureRouteProps {
    path: RouteProps['path'];
    component: React.ElementType;
    permittedRoles?: UserRole[];
    featurePermissions?: FeaturePermission[];
    clientFeaturePermissions?: ClientFeaturePermission[];
    isMultiPermissioned?: boolean;
}

export const SecureRoute: React.FC<SecureRouteProps> = ({ component: Component, permittedRoles = allRoles, featurePermissions = [], clientFeaturePermissions = [], isMultiPermissioned = false, ...routeProps }) => {
    const [loaded, setLoaded] = useState(false);
    const user = useAppSelector(getUser);
    const isLoggedIn = useAppSelector(getIsLoggedIn);
    const userRole = useAppSelector(getUserRole);
    const isCheckingAuth = useAppSelector(getCheckAuth);
    const myProfile = useAppSelector(getMyProfile);
    const profileModalOpen = useAppSelector(getProfileModalOpen);
    const hasFeaturePermission = useAppSelector(getUserHasFeaturePermission(featurePermissions));
    const hasSomeRequiredFeaturePermissions = useAppSelector(getUserHasOneFeaturePermissionNoAdmin(featurePermissions));
    const hasClientFeaturePermission = useAppSelector(getClientHasFeaturePermission(clientFeaturePermissions));

    const hasPermittedRole = useMemo(() => !isUndefined(userRole) && permittedRoles.includes(userRole), [userRole, permittedRoles]);
    const featurePermissionsToCheck = useMemo(() => !isMultiPermissioned ? hasFeaturePermission : hasSomeRequiredFeaturePermissions, [isMultiPermissioned, hasFeaturePermission, hasSomeRequiredFeaturePermissions]);
    const hasRequiredFeaturePermissions = useMemo(() => featurePermissions.length > 0 && featurePermissionsToCheck, [featurePermissions, featurePermissionsToCheck]);
    const hasRequiredClientFeaturePermissions = useMemo(() => clientFeaturePermissions.length > 0 && hasClientFeaturePermission, [clientFeaturePermissions, hasClientFeaturePermission]);
    const isPermitted = useMemo(() => hasPermittedRole || hasRequiredFeaturePermissions || hasRequiredClientFeaturePermissions, [hasPermittedRole, hasRequiredFeaturePermissions, hasRequiredClientFeaturePermissions]);
    const showStatisticsTicker = useMemo(() => user?.userDefaults?.showTicker || true, [user]);

    useEffect(() => {
        setLoaded(true);
    }, [setLoaded]);

    if (!loaded) {
        return null;
    }

    if (isCheckingAuth) {
        return <Spinner />;
    }

    if (isLoggedIn) {
        if (isPermitted) {
            return (
                <Route {...routeProps} render={routeProps => {
                    return <HomeWrapper>
                        <Component {...routeProps} />
                        {myProfile && <MyProfile isOpen={profileModalOpen} myProfile={myProfile} />}
                        {showStatisticsTicker && <PortfolioStatistics />}
                    </HomeWrapper>;
                }} />
            );
        }
        return <Redirect to="/home" />;
    }

    return <Redirect to="/login" />;
};
