import { isNull } from 'lodash/fp';
import { MutableRefObject, useEffect, useMemo, useState } from 'react';

import { useWindowResize } from './useWindowResize';

export const useTickerPosition = (movingElement: MutableRefObject<HTMLDivElement | null>, screenWidthOffset = 0, duration = 15000, isHovering = false): number => {
    const [elementWidth, setElementWidth] = useState<number>(0);
    const [leftPosition, setLeftPosition] = useState<number>(0);
    const [screenWidth] = useWindowResize();

    const wrapperWidth = useMemo(() => screenWidth - screenWidthOffset, [screenWidth, screenWidthOffset]);

    const leftPositionMoveTime = useMemo(() => Math.floor((duration / wrapperWidth)), [wrapperWidth, duration]);

    useEffect(() => {
        if (!isNull(movingElement) && !isNull(movingElement.current) && screenWidth > 0) {
            setElementWidth(movingElement.current.clientWidth);
        }
    }, [movingElement, screenWidth]);

    useEffect(() => {
        if ((elementWidth + leftPosition) > wrapperWidth) {
            if (!isHovering) {
                setTimeout(() => setLeftPosition(leftPosition - 1), leftPositionMoveTime);
            }
        } else if (elementWidth > 0) {
            setTimeout(() => setLeftPosition(0), 500);
        }
    }, [elementWidth, leftPosition, leftPositionMoveTime, isHovering, wrapperWidth]);

    return leftPosition;
};
