import React, { useCallback, useMemo } from 'react';
import { RawDraftContentState } from 'draft-js';
import { isEqual, isNull } from 'lodash/fp';

import { getDoraFunctionConfig, getCurrentDoraFunctionConfig, getFunctionConfigModalOpen, saveFunctionConfigStarted, toggleFunctionConfigModal, updateFunctionConfig, getConfigurationTimeline, setSelectedFunctionConfig, getSelectedTimelineConfiguration } from './store';
import styles from './DoraFunctions.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { PositionModal, Position } from '../../shared/modal/PositionModal';
import { isEmpty, WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import { Button } from '../../shared/button/Button';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { FeaturePermission } from '../../admin/users/store';
import { ConfigurationTimeline } from './DoraFunctionConfigurationTimeline';

interface DoraFunctionConfigurationModalProps {
    position: Position | null;
    setPosition: (value: null) => void;
}

export const DoraFunctionConfigurationModal: React.FC<DoraFunctionConfigurationModalProps> = ({ position, setPosition }) => {
    const dispatch = useAppDispatch();
    const functionConfigOpen = useAppSelector(getFunctionConfigModalOpen);
    const doraFunctionConfig = useAppSelector(getDoraFunctionConfig);
    const currentDoraFunctionConfig = useAppSelector(getCurrentDoraFunctionConfig);
    const configurationTimeline = useAppSelector(getConfigurationTimeline);
    const timelineConfiguration = useAppSelector(getSelectedTimelineConfiguration);

    const hasTimeline = useMemo(() => configurationTimeline.length > 0, [configurationTimeline]);
    const activeConfiguration = useMemo(() => hasTimeline ? configurationTimeline[configurationTimeline.length - 1] : null, [hasTimeline, configurationTimeline]);

    const timelineDetails = useMemo(() => !isNull(timelineConfiguration) ? timelineConfiguration.details : doraFunctionConfig.details, [timelineConfiguration, doraFunctionConfig]);

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const selectedTimelineConfiguration = useMemo(() => !isNull(timelineConfiguration) ? timelineConfiguration : doraFunctionConfig, [timelineConfiguration, doraFunctionConfig]);

    const closeConfigModal = useCallback(() => {
        setPosition(null);
        dispatch(setSelectedFunctionConfig(activeConfiguration));
        dispatch(toggleFunctionConfigModal(false));
    }, [setPosition, dispatch, activeConfiguration]);

    const saveFunctionConfig = useCallback(() => dispatch(saveFunctionConfigStarted(doraFunctionConfig)), [dispatch, doraFunctionConfig]);

    const editFunctionConfig = useCallback(() => dispatch(setSelectedFunctionConfig(null)), [dispatch]);
    const cancel = useCallback(() => dispatch(setSelectedFunctionConfig(activeConfiguration)), [dispatch, activeConfiguration]);

    const updateConfig = useCallback((value: RawDraftContentState) => {
        if (isEmpty(value)) {
            if (!isNull(doraFunctionConfig.details)) {
                dispatch(updateFunctionConfig(null));
            }
        } else {
            dispatch(updateFunctionConfig(value));
        }
    }, [dispatch, doraFunctionConfig]);

    const [screenWidth, screenHeight] = useWindowResize();

    const tableHeight = screenHeight * .7;
    const tableWidth = screenWidth * .7;

    const isReadOnlyView = useMemo(() => !hasDoraFullAccessPermission || !isNull(timelineConfiguration), [hasDoraFullAccessPermission, timelineConfiguration]);
    const showEditButton = useMemo(() => hasDoraFullAccessPermission && isEqual(timelineConfiguration, activeConfiguration) && hasTimeline, [hasDoraFullAccessPermission, hasTimeline, timelineConfiguration, activeConfiguration]);
    const saveDisabled = useMemo(() => isEqual(currentDoraFunctionConfig, doraFunctionConfig) || !hasDoraFullAccessPermission, [currentDoraFunctionConfig, doraFunctionConfig, hasDoraFullAccessPermission]);

    const showClose = useMemo(() => hasTimeline ? isReadOnlyView : true, [hasTimeline, isReadOnlyView]);
    const showCancel = useMemo(() => !hasTimeline ? false : !isReadOnlyView, [hasTimeline, isReadOnlyView]);

    const changeTimeline = useCallback((value: number | number[]) => {
        if (isReadOnlyView) {
            const index = (value as number) - 1;
            dispatch(setSelectedFunctionConfig(configurationTimeline[index]));
        }
    }, [dispatch, configurationTimeline, isReadOnlyView]);

    return (
        <PositionModal
            isOpen={functionConfigOpen}
            position={position}
            height={`${tableHeight}px`}
            width={`${tableWidth}px`}
            closeModal={closeConfigModal}
            testId='dora-function-config-modal'
            overflow='hidden'
        >
            <div className={styles.functionsConfigModalWrapper}>
                <div className={styles.functionsConfigWrapper}>
                    <div className={styles.functionConfigTitle}>Definition of critical or important function:</div>
                    <ConfigurationTimeline timeline={configurationTimeline} doraFunctionConfig={selectedTimelineConfiguration} onChange={changeTimeline} />
                    <div className={styles.functionWysiwygWrapper}>
                        <WYSIWYG
                            content={timelineDetails}
                            updateContent={val => updateConfig(val)}
                            showBorder={false}
                            height='calc(100% - 10px)'
                            maxHeight='calc(100% - 10px)'
                            disabled={isReadOnlyView}
                            isReadOnly={!isNull(timelineConfiguration)}
                            toolbarHidden={!isNull(timelineConfiguration)}
                        />
                    </div>
                </div>
                <div className={styles.configButtonWrapper}>
                    {showClose && <Button onClick={closeConfigModal} label='Close' testId='function-config-close' />}
                    {showEditButton && <Button onClick={editFunctionConfig} label='Edit' testId='function-config-edit' />}
                    {showCancel && <Button onClick={cancel} label='Cancel' testId='function-config-cancel' />}
                    {!isReadOnlyView && <Button onClick={saveFunctionConfig} label='Save' disabled={saveDisabled} testId='function-config-save' />}
                </div>
            </div>
        </PositionModal>
    );
};
