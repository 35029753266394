import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { Calendar, Table } from '../../shared/icons';
import { TileSelect, Tile } from '../../shared/tile-select/TileSelect';
import { WorkflowPage, setWorkflowPage } from './store';
import styles from './Workflow.module.scss';

export const WorkflowSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectPage = useCallback((page: WorkflowPage) => dispatch(setWorkflowPage(page)), [dispatch]);
    const selectCalendar = useCallback(() => selectPage(WorkflowPage.CALENDAR), [selectPage]);
    const selectList = useCallback(() => selectPage(WorkflowPage.LIST), [selectPage]);

    const attestationsTiles: Tile[] = useMemo(() => [
        {
            label: 'Calendar',
            description: ['View and manage all of your scheduled actions in calendar format with clear visualisation of events.'],
            onClick: selectCalendar,
            testId: 'calendar',
            icon: Calendar
        },
        {
            label: 'View All',
            description: ['View and manage all of your scheduled actions in table format.'],
            onClick: selectList,
            testId: 'list',
            icon: Table
        },
    ], [selectCalendar, selectList]);

    return (
        <div className={styles.workflowSelectWrapper}>
            <TileSelect testId='admin-workflow' tiles={attestationsTiles} />
        </div>
    );
};
