import React, { useState, useMemo, useCallback } from 'react';
import classnames from 'classnames';

import styles from './Carousel.module.scss';
import { Icon } from '../icon/Icon';
import { CaretSide } from '../icons';

const { primary } = styles;

interface CarouselProps {
    children: JSX.Element[];
    markers: number[];
    height: number;
    width: number;
}

const MARKERS_HEIGHT = 15;
const ARROW_WIDTH_OFFSET = 28;

export const Carousel: React.FC<CarouselProps> = ({ children, markers, height, width }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [tileLeftPosition, setTileLeftPosition] = useState('0px');
    const totalMarkers = useMemo(() => markers.length, [markers]);
    const showArrows = useMemo(() => totalMarkers > 1, [totalMarkers]);

    const next = useCallback(() => {
        const nextIndex = (selectedIndex + 1) % totalMarkers;
        const nextLeft = ((-width + ARROW_WIDTH_OFFSET) * nextIndex) + 'px';
        setSelectedIndex((selectedIndex + 1) % totalMarkers);
        setTileLeftPosition(nextLeft);
    }, [selectedIndex, totalMarkers, width]);

    const previous = useCallback(() => {
        const previousIndex = (selectedIndex + totalMarkers - 1) % totalMarkers;
        const previousLeft = ((-width + ARROW_WIDTH_OFFSET) * previousIndex) + 'px';
        setSelectedIndex((selectedIndex + totalMarkers - 1) % totalMarkers);
        setTileLeftPosition(previousLeft);
    }, [selectedIndex, totalMarkers, width]);

    const selectSlide = useCallback((index: number) => {
        setSelectedIndex(index);
        const nextLeft = ((-width + ARROW_WIDTH_OFFSET) * index) + 'px';
        setTileLeftPosition(nextLeft);
    }, [width]);

    return (
        <div className={styles.carouselWrapper} style={{ height }}>
            <div className={styles.carouselContentWrapper}>
                {showArrows ? <div onClick={previous} className={styles.previousArrow}><Icon icon={CaretSide} color={primary} /></div> : <div className={styles.placeholderArrow} />}
                <div className={styles.carousel}>
                    <div className={styles.tile} style={{ left: tileLeftPosition }}>
                        {children}
                    </div>
                </div>
                {showArrows ? <div onClick={next} className={styles.nextArrow}><Icon icon={CaretSide} color={primary} /></div> : <div className={styles.placeholderArrow} />}
            </div>
            {showArrows &&
                <div className={styles.markers} style={{ top: height - MARKERS_HEIGHT, width }}>
                    {markers.map(index => (
                        <div key={index} onClick={() => selectSlide(index)} className={classnames(styles.marker, { [styles.selectedMarker]: index === selectedIndex })} />
                    ))}
                </div>
            }
        </div>
    );
};
