import React, { useCallback, useMemo } from 'react';
import { isEqual, isUndefined } from 'lodash/fp';

import { SingleDatasetField, TableDatasetDefinition, TableDatasetFieldType } from '../../../datasets/store';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import styles from '../MyDatasets.module.scss';
import { TableHeader } from './table/TableHeader';
import { TableCell } from './table/TableCell';
import { addOpenFieldSection, getOpenFieldsAndSections, HiddenFields, MyDatasetsOpenFieldSection, removeOpenFieldSection } from '../store';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useSingleHiddenDocumentFields, useSingleHiddenFields } from '../../../../hooks/useHiddenSingleFields';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';
import { DocumentNameDB } from '../../documents/store';

interface TableDefinitionProps {
    tableDefinition: TableDatasetDefinition;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: HiddenFields;
    datasetHidden?: boolean;
    documentSpecificHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    parentHiddenDocumentNameIds?: number[];
    parentDatasetId: number;
}

export const TableDefinition: React.FC<TableDefinitionProps> = ({ tableDefinition, isPreview, modalInstance, hiddenFields, datasetHidden, documentSpecificHiddenFields, allDocumentNames, parentHiddenDocumentNameIds, parentDatasetId }) => {
    const { datasetFields, datasetId } = tableDefinition;
    const dispatch = useAppDispatch();

    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);

    const getSectionOpen = useCallback((fieldSection: MyDatasetsOpenFieldSection) => openFieldsAndSections.some(openSection => isEqual(openSection, fieldSection)), [openFieldsAndSections]);

    const toggleSection = useCallback((fieldSection: MyDatasetsOpenFieldSection) => {
        const sectionOpen = openFieldsAndSections.find(openSection => isEqual(openSection, fieldSection));
        sectionOpen ? dispatch(removeOpenFieldSection(sectionOpen)) : dispatch(addOpenFieldSection(fieldSection));
    }, [dispatch, openFieldsAndSections]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId!] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const columnIsHidden = useCallback((id: string) => datasetHidden || !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields, datasetHidden]);

    const tableFirstCell = useCallback((fieldId: string) => datasetFields.filter(({ id }) => !hiddenSingleFields.map(({ fieldId }) => fieldId).includes(id!))[0].id === fieldId, [hiddenSingleFields, datasetFields]);

    const documentHiddenFields = useMemo(() => documentSpecificHiddenFields[datasetId!] || [], [datasetId, documentSpecificHiddenFields]);
    const documentHiddenSingleFields = useSingleHiddenDocumentFields(documentHiddenFields);
    const documentNameIdsForDataset = useMemo(() => allDocumentNames.filter(document => document.datasetId === parentDatasetId).map(({ documentNameId }) => documentNameId!), [allDocumentNames, parentDatasetId]);
    const getColumnHiddenForAllDatasetDocuments = useCallback((hiddenFieldDocumentNameIds: number[]) => hiddenFieldDocumentNameIds.length > 0 && documentNameIdsForDataset.every(documentNameId => hiddenFieldDocumentNameIds.includes(documentNameId)), [documentNameIdsForDataset]);
    const getColumnHiddenDocumentNames = useCallback((id: string) => {
        const hiddenFieldDocumentNameIds = documentHiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds;
        if (!isUndefined(hiddenFieldDocumentNameIds)) {
            const allHiddenDocumentNameIds = [...hiddenFieldDocumentNameIds, ...parentHiddenDocumentNameIds || []];
            const hiddenDocumentNames = allDocumentNames.filter(document => document.datasetId === parentDatasetId).reduce((acc: string[], { documentNameId, documentName }) => documentNameId && allHiddenDocumentNameIds.includes(documentNameId) ? [...acc, documentName.toLowerCase()] : acc, []);
            return { hiddenDocumentNames, hiddenFieldDocumentNameIds };
        }
        return { hiddenDocumentNames: [], hiddenFieldDocumentNameIds: [] };
    }, [documentHiddenSingleFields, allDocumentNames, parentHiddenDocumentNameIds, parentDatasetId]);

    return (
        <div className={styles.tableDefinitionWrapper} data-testid='table-dataset-definition-wrapper' style={{ height: '100%', maxHeight: '400px' }}>
            <Scrollable>
                <div className={styles.tableHeader}>
                    {datasetFields.map((field: SingleDatasetField, index: number) => {
                        const { label, description, refLabel, settings, id, type } = field;
                        const columnHidden = columnIsHidden(id!);
                        const { hiddenDocumentNames, hiddenFieldDocumentNameIds } = getColumnHiddenDocumentNames(id!);
                        const columnHiddenForAllDatasetDocuments = getColumnHiddenForAllDatasetDocuments(hiddenFieldDocumentNameIds);

                        if ((isPreview && columnHidden) || columnHiddenForAllDatasetDocuments) {
                            return null;
                        }
                        const isFirstCell = isPreview ? tableFirstCell(id!) : index === 0;
                        return (
                            <TableHeader
                                key={id!}
                                label={label}
                                description={description}
                                refLabel={refLabel}
                                showRef={settings.showRef}
                                id={id!}
                                type={type as TableDatasetFieldType}
                                isFirstCell={isFirstCell}
                                datasetId={datasetId!}
                                columnHidden={columnHidden}
                                isPreview={isPreview}
                                datasetHidden={datasetHidden}
                                hiddenDocumentNames={hiddenDocumentNames}
                            />
                        );
                    })}
                </div>
                <div className={styles.tableBody} style={{ height: 'calc(100% - 50px)', maxHeight: '350px' }}>
                    <Scrollable maxHeight='350px'>
                        <div className={styles.tableRow}>
                            {datasetFields.map((field, index) => {
                                const columnHidden = columnIsHidden(field.id!);
                                const { hiddenFieldDocumentNameIds } = getColumnHiddenDocumentNames(field.id!);
                                const columnHiddenForAllDatasetDocuments = getColumnHiddenForAllDatasetDocuments(hiddenFieldDocumentNameIds);

                                if (isPreview && columnHidden || columnHiddenForAllDatasetDocuments) {
                                    return null;
                                }
                                const isFirstCell = isPreview ? tableFirstCell(field.id!) : index === 0;
                                return (
                                    <TableCell
                                        key={field.id!}
                                        field={field}
                                        index={index}
                                        isFirstCell={isFirstCell}
                                        datasetId={datasetId!}
                                        toggleSection={toggleSection}
                                        getSectionOpen={getSectionOpen}
                                        modalInstance={modalInstance}
                                        columnHidden={columnHidden}
                                    />
                                );
                            })}
                        </div>
                    </Scrollable>
                </div>
            </Scrollable>
        </div>
    );
};
