import React from 'react';

import styles from './Filter.module.scss';
import { DatePicker } from '../datepicker/DatePicker';

interface DateFilterProps {
    updateFromDate: (value: Date | null) => void;
    updateToDate: (value: Date | null) => void;
    fromDate: Date | null;
    toDate: Date | null;
    label: string;
    fontSize: string;
}

export const DateFilter: React.FC<DateFilterProps> = ({ updateFromDate, updateToDate, fromDate, toDate, label, fontSize }) => (
    <div className={styles.dateWrapper}>
        <div className={styles.fromDateWrapper}>
            <div className={styles.dateLabel} style={{ fontSize }}>{label}</div>
            <div className={styles.fromDateWithLabel}>
                <div className={styles.filterInputLabel} style={{ fontSize }}>From</div>
                <DatePicker
                    onChange={updateFromDate}
                    testId='date-from'
                    value={fromDate}
                />
            </div>
        </div>
        <div className={styles.toDateWrapper}>
            <div className={styles.toDateWithLabel}>
                <div className={styles.filterInputLabel} style={{ fontSize }}>To</div>
                <DatePicker
                    onChange={updateToDate}
                    testId='date-to'
                    value={toDate}
                />
            </div>
        </div>
    </div>
);
