import { Reducer } from 'redux';
import { set, flow } from 'lodash/fp';

import { SendNotificationActionTypes, SendNotificationState } from './types';
import { LoginActionTypes } from '../../../auth/login/store';

export const INITIAL_STATE: SendNotificationState = {
    adminOnly: true,
    clientIds: [],
    content: null,
    title: '',
    isSending: false,
    sendError: null,
    previewOpen: false
};

export const sendNotificationReducer: Reducer<SendNotificationState> = (state = INITIAL_STATE, { payload, type }): SendNotificationState => {
    switch (type) {
        case SendNotificationActionTypes.UPDATE_NOTIFICATION_TITLE:
            return set('title', payload, state);
        case SendNotificationActionTypes.UPDATE_NOTIFICATION_CONTENT:
            return set('content', payload, state);
        case SendNotificationActionTypes.UPDATE_NOTIFICATION_CLIENT_IDS:
            return set('clientIds', payload, state);
        case SendNotificationActionTypes.UPDATE_NOTIFICATION_ADMIN_ONLY:
            return set('adminOnly', payload, state);
        case SendNotificationActionTypes.SEND_NOTIFICATION_STARTED:
            return set('isSending', true, state);
        case SendNotificationActionTypes.SEND_NOTIFICATION_SUCCESSFUL:
        case SendNotificationActionTypes.RESET_NOTIFICATION_CONTENT:
            return INITIAL_STATE;
        case SendNotificationActionTypes.SEND_NOTIFICATION_FAILED:
            return flow(
                set('isSending', false),
                set('sendError', payload)
            )(state);
        case SendNotificationActionTypes.TOGGLE_NOTIFICATION_PREVIEW:
            return set('previewOpen', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
