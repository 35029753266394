import React from 'react';

import { RepoOpinionInstance } from './store/types';
import { ArkOpinion } from '../my-opinions/store';
import { GeneralSection, ScopeSection, AssumptionsSection, RepoEarlyCloseOutSection, RepoLegalIssuesSection, MarginArrangementsSection, OtherIssuesSection, TransactionCoverageSection, CounterpartyCoverageSection, AnnexCoverageSection, ClauseSection, RepoAgreementCoverageSection } from './sections';

interface RepoInstanceProps {
    instance: RepoOpinionInstance;
    opinion: ArkOpinion;
    isEditing: boolean;
    isUpdating: boolean;
    width: number;
}

export const RepoInstance: React.FC<RepoInstanceProps> = ({ instance, opinion, isEditing, isUpdating, width }) => {
    const { content: { general, assumptions, earlyCloseOut, legalIssues, marginArrangements, otherIssues, agreementCoverage, transactionCoverage, counterpartyCoverage, annexCoverage, additionalProvisions }, fieldsUpdated } = instance;
    return (
        <div>
            <GeneralSection general={general} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <ScopeSection opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} />
            <AssumptionsSection assumptions={assumptions} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <RepoEarlyCloseOutSection earlyCloseOut={earlyCloseOut} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <RepoLegalIssuesSection legalIssues={legalIssues} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <MarginArrangementsSection marginArrangements={marginArrangements} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <OtherIssuesSection otherIssues={otherIssues} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <RepoAgreementCoverageSection agreementCoverage={agreementCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <TransactionCoverageSection transactionCoverage={transactionCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <CounterpartyCoverageSection counterpartyCoverage={counterpartyCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} jurisdiction={opinion.jurisdiction} />
            <AnnexCoverageSection annexCoverage={annexCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} opinion={opinion} />
            <ClauseSection additionalProvisions={additionalProvisions} opinion={opinion} width={width} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
        </div>
    );
};
