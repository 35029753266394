import { Socket } from 'socket.io-client';

import { ClientUser } from '../../../admin/users/store';
import { HomeScreenConfig } from '../../../home/store';
import { OpinionsView } from '../../../opinions/my-opinions/store';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';

export enum LoginActionTypes {
    LOGIN_STARTED = 'LOGIN_STARTED',
    LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL',
    LOGIN_FAILED = 'LOGIN_FAILED',
    LOGOUT_STARTED = 'LOGOUT_STARTED',
    LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL',
    LOGOUT_FAILED = 'LOGOUT_FAILED',
    SET_REMINDER_TYPE = 'SET_REMINDER_TYPE',
    FORGOTTEN_USERNAME_REMINDER_STARTED = 'FORGOTTEN_USERNAME_REMINDER_STARTED',
    FORGOTTEN_USERNAME_REMINDER_SUCCESSFUL = 'FORGOTTEN_USERNAME_REMINDER_SUCCESSFUL',
    FORGOTTEN_USERNAME_REMINDER_FAILED = 'FORGOTTEN_USERNAME_REMINDER_FAILED',
    FORGOTTEN_PASSWORD_REMINDER_STARTED = 'FORGOTTEN_PASSWORD_REMINDER_STARTED',
    FORGOTTEN_PASSWORD_REMINDER_SUCCESSFUL = 'FORGOTTEN_PASSWORD_REMINDER_SUCCESSFUL',
    FORGOTTEN_PASSWORD_REMINDER_FAILED = 'FORGOTTEN_PASSWORD_REMINDER_FAILED',
    SET_SOCKETS_CONNECTION = 'SET_SOCKETS_CONNECTION',
    SET_SOCKETS_CONNECTION_FAILED = 'SET_SOCKETS_CONNECTION_FAILED',
    SOCKET_LOGIN = 'SOCKET_LOGIN',
    TOGGLE_LOGOUT_WARNING_MODAL = 'TOGGLE_LOGOUT_WARNING_MODAL',
    EXTEND_TOKEN = 'EXTEND_TOKEN',
    REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN',
    USER_UPDATED = 'USER_UPDATED',
    TOGGLE_MY_PROFILE_MODAL = 'TOGGLE_MY_PROFILE_MODAL',
    UPDATE_MY_PROFILE_DETAILS = 'UPDATE_MY_PROFILE_DETAILS',
    EDIT_MY_PROFILE_DETAILS = 'EDIT_MY_PROFILE_DETAILS',
    SET_MY_PROFILE_UPDATED = 'SET_MY_PROFILE_UPDATED',
    UPDATE_MY_PROFILE_STARTED = 'UPDATE_MY_PROFILE_STARTED',
    UPDATE_MY_PROFILE_SUCCESSFUL = 'UPDATE_MY_PROFILE_SUCCESSFUL',
    UPDATE_MY_PROFILE_FAILED = 'UPDATE_MY_PROFILE_FAILED',
    SET_UPDATING_PROFILE = 'SET_UPDATING_PROFILE',
    SWITCH_USER_ACCOUNT_STARTED = 'SWITCH_USER_ACCOUNT_STARTED',
    SWITCH_USER_ACCOUNT_SUCCESSFUL = 'SWITCH_USER_ACCOUNT_SUCCESSFUL',
    SWITCH_USER_ACCOUNT_FAILED = 'SWITCH_USER_ACCOUNT_FAILED',
    SET_ACCOUNT_SWITCH_USERNAME = 'SET_ACCOUNT_SWITCH_USERNAME',
    SET_ACCOUNT_SWITCH_PASSWORD = 'SET_ACCOUNT_SWITCH_PASSWORD',
    IDLE_CHECK_TOKEN = 'IDLE_CHECK_TOKEN',
    SET_IDLE_CHECK_TOKEN_VALID = 'SET_IDLE_CHECK_TOKEN_VALID',
    UPDATE_USER_CUSTOM_FILTERS = 'UPDATE_USER_CUSTOM_FILTERS',
    SET_SHOW_RESET_PASSWORD = 'SET_SHOW_RESET_PASSWORD',
    SET_SHOW_SWITCH_ACCOUNT = 'SET_SHOW_SWITCH_ACCOUNT'
}

export enum ReminderTypes {
    USERNAME = 'USERNAME',
    PASSWORD = 'PASSWORD'
}

export interface AlternateUserAccount {
    username: string;
    client: string;
}

export enum FilterTable {
    DOCUMENT = 'document',
    OPINION = 'opinion'
}

export interface DocumentCustomFilters {
    filterName: string;
    filterTable: FilterTable.DOCUMENT;
    id: string;
    entityA: TableFilterType;
    entityB: TableFilterType;
    documentDescription: TableFilterType;
    documentName: TableFilterType;
}

export interface OpinionCustomFilters {
    filterName: string;
    filterTable: FilterTable.OPINION;
    id: string;
    jurisdiction: TableFilterType;
    focus: TableFilterType;
    bespoke: TableFilterType;
    scope: TableFilterType;
    commissionedBy: TableFilterType;
}

export type CustomFilters = DocumentCustomFilters | OpinionCustomFilters;

export interface UserDefaults {
    opinionPageView: OpinionsView;
    showTicker: boolean;
    showIntercom: boolean;
}

export interface User extends ClientUser {
    clientFeaturePermissionIds: number[] | null;
    config: HomeScreenConfig | null;
    file?: File;
    token?: string;
    alternateUserAccounts?: AlternateUserAccount[];
    clientName: string;
    customFilters: CustomFilters[] | null;
    userDefaults: UserDefaults | null;
}

export interface LoginState {
    user: User | null;
    isLoggedIn: boolean;
    loginError: null | string;
    logoutError: null | string;
    checkingAuth: boolean;
    loggingOut: boolean;
    reminderType: ReminderTypes | null;
    reminderError: null | string;
    socket: Socket | null;
    logoutWarningModalOpen: boolean;
    profileModalOpen: boolean;
    profileDetails: User | null;
    editingProfile: boolean;
    updatingProfile: boolean;
    profileHasUpdated: boolean;
    switchingAccounts: boolean;
    switchAccountError: null | string;
    switchUsername: string | null,
    switchPassword: string;
    idleCheckTokenValid: boolean;
    sendingReminder: boolean;
    showResetPassword: boolean;
    showSwitchAccount: boolean;
}
