import { Reducer } from 'redux';
import { flow, set } from 'lodash/fp';

import { ResetPasswordState, ResetPasswordActionTypes } from './types';

export const INITIAL_STATE: ResetPasswordState = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    errorMessage: null,
    resettingPassword: false,
    passwordScore: 0
};

export const resetPasswordReducer: Reducer<ResetPasswordState> = (state = INITIAL_STATE, action): ResetPasswordState => {
    switch (action.type) {
        case ResetPasswordActionTypes.UPDATE_PASSWORD_FIELD: {
            const { key, value } = action.payload;
            return set(`${key}`, value, state);
        }
        case ResetPasswordActionTypes.RESET_PASSWORD_STARTED:
            return flow(
                set('resettingPassword', true),
                set('errorMessage', null)
            )(state);
        case ResetPasswordActionTypes.RESET_PASSWORD_SUCCESSFUL:
            return flow(
                set('resettingPassword', false),
                set('currentPassword', ''),
                set('newPassword', ''),
                set('confirmNewPassword', ''),
                set('passwordScore', 0)
            )(state);
        case ResetPasswordActionTypes.RESET_PASSWORD_FAILED:
            return flow(
                set('resettingPassword', false),
                set('errorMessage', action.payload),
            )(state);
        case ResetPasswordActionTypes.SET_PASSWORD_SCORE:
            return set('passwordScore', action.payload, state);
        case ResetPasswordActionTypes.RESET_STORE_VALUES:
            return INITIAL_STATE;
        default:
            return state;
    }
};
