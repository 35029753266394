import { action } from 'typesafe-actions';

import { DocumentNameActionTypes, DocumentNameDB, ClientDocumentGroup, AgreementTypeDB } from './types';

export const toggleModal = (isOpen: boolean, documentName?: DocumentNameDB) => action(DocumentNameActionTypes.TOGGLE_DOCUMENT_NAME_MODAL, { isOpen, documentName });

export const toggleClientDatasetConfirmationModal = (isOpen: boolean) => action(DocumentNameActionTypes.TOGGLE_CLIENT_DATASET_CONFIRMATION_MODAL_OPEN, isOpen);

export const updateValue = (key: keyof DocumentNameDB, value: string | null | number | number[]) => action(DocumentNameActionTypes.UPDATE_VALUE, { key, value });

export const updateDocumentNameStarted = () => action(DocumentNameActionTypes.UPDATE_DOCUMENT_NAME_STARTED);
export const updateDocumentNameSuccessful = (documentNames: DocumentNameDB[]) => action(DocumentNameActionTypes.UPDATE_DOCUMENT_NAME_SUCCESSFUL, documentNames);
export const updateDocumentNameFailed = (e: string) => action(DocumentNameActionTypes.UPDATE_DOCUMENT_NAME_FAILED, e);

export const fetchAllAgreementTypesStarted = () => action(DocumentNameActionTypes.FETCH_ALL_AGREEMENT_TYPES_STARTED);
export const fetchAllAgreementTypesSuccessful = (agreementTypes: AgreementTypeDB[]) => action(DocumentNameActionTypes.FETCH_ALL_AGREEMENT_TYPES_SUCCESSFUL, agreementTypes);
export const fetchAllAgreementTypesFailed = (e: string) => action(DocumentNameActionTypes.FETCH_ALL_AGREEMENT_TYPES_FAILED, e);

export const fetchAllDocumentNamesStarted = () => action(DocumentNameActionTypes.FETCH_ALL_DOCUMENT_NAMES_STARTED);
export const fetchAllDocumentNamesSuccessful = (documentNames: DocumentNameDB[]) => action(DocumentNameActionTypes.FETCH_ALL_DOCUMENT_NAMES_SUCCESSFUL, documentNames);
export const fetchAllDocumentNamesFailed = (e: string) => action(DocumentNameActionTypes.FETCH_ALL_DOCUMENT_NAMES_FAILED, e);

export const fetchAvailableDocumentNamesStarted = () => action(DocumentNameActionTypes.FETCH_AVAILABLE_DOCUMENT_NAMES_STARTED);
export const fetchAvailableDocumentNamesSuccessful = (documentNames: DocumentNameDB[]) => action(DocumentNameActionTypes.FETCH_AVAILABLE_DOCUMENT_NAMES_SUCCESSFUL, documentNames);
export const fetchAvailableDocumentNamesFailed = (e: string) => action(DocumentNameActionTypes.FETCH_AVAILABLE_DOCUMENT_NAMES_FAILED, e);

export const toggleCreateDocumentGroupModal = (isOpen: boolean, clientDocumentGroupId?: number) => action(DocumentNameActionTypes.TOGGLE_CREATE_DOCUMENT_GROUP_MODAL_OPEN, { isOpen, clientDocumentGroupId });

export const fetchAllDocumentGroupsStarted = () => action(DocumentNameActionTypes.FETCH_ALL_DOCUMENT_GROUPS_STARTED);
export const fetchAllDocumentGroupsSuccessful = (documentGroups: ClientDocumentGroup[]) => action(DocumentNameActionTypes.FETCH_ALL_DOCUMENT_GROUPS_SUCCESSFUL, documentGroups);
export const fetchAllDocumentGroupsFailed = (e: string) => action(DocumentNameActionTypes.FETCH_ALL_DOCUMENT_GROUPS_FAILED, e);

export const updateClientDocumentGroupValue = (key: keyof ClientDocumentGroup, value: string | number[]) => action(DocumentNameActionTypes.UPDATE_CLIENT_DOCUMENT_GROUP, { key, value });

export const saveClientDocumentGroupStarted = () => action(DocumentNameActionTypes.SAVE_CLIENT_DOCUMENT_GROUP_STARTED);
export const saveClientDocumentGroupSuccessful = () => action(DocumentNameActionTypes.SAVE_CLIENT_DOCUMENT_GROUP_SUCCESSFUL);
export const saveClientDocumentGroupFailed = (e: string) => action(DocumentNameActionTypes.SAVE_CLIENT_DOCUMENT_GROUP_FAILED, e);

export const deleteClientDocumentGroupStarted = () => action(DocumentNameActionTypes.DELETE_CLIENT_DOCUMENT_GROUP_STARTED);
export const deleteClientDocumentGroupSuccessful = () => action(DocumentNameActionTypes.DELETE_CLIENT_DOCUMENT_GROUP_SUCCESSFUL);
export const deleteClientDocumentGroupFailed = (e: string) => action(DocumentNameActionTypes.DELETE_CLIENT_DOCUMENT_GROUP_FAILED, e);
export const toggleAgreementTypeModal = (isOpen: boolean) => action(DocumentNameActionTypes.TOGGLE_AGREEMENT_TYPE_MODAL_OPEN, isOpen);

export const addNewAgreementType = () => action(DocumentNameActionTypes.ADD_NEW_AGREEMENT_TYPE);
export const removeNewAgreementType = (index: number) => action(DocumentNameActionTypes.REMOVE_NEW_AGREEMENT_TYPE, index);
export const updateAgreementTypeName = (index: number, name: string) => action(DocumentNameActionTypes.UPDATE_AGREEMENT_TYPE_NAME, { index, name });

export const setAgreementTypesHaveUpdated = (hasUpdated: boolean) => action(DocumentNameActionTypes.SET_AGREEMENT_TYPES_HAVE_UPDATED, hasUpdated);

export const updateAgreementTypesStarted = () => action(DocumentNameActionTypes.UPDATE_AGREEMENT_TYPES_STARTED);
export const updateAgreementTypesSuccessful = (agreementTypes: AgreementTypeDB[]) => action(DocumentNameActionTypes.UPDATE_AGREEMENT_TYPES_SUCCESSFUL, agreementTypes);
export const updateAgreementTypesFailed = (e: string) => action(DocumentNameActionTypes.UPDATE_AGREEMENT_TYPES_FAILED, e);

export const fetchMLReadyAgreementTypeIdsStarted = () => action(DocumentNameActionTypes.FETCH_ML_READY_AGREEMENT_TYPES_STARTED);
export const fetchMLReadyAgreementTypeIdsSuccessful = (agreementTypeIds: number[]) => action(DocumentNameActionTypes.FETCH_ML_READY_AGREEMENT_TYPES_SUCCESSFUL, agreementTypeIds);
export const fetchMLReadyAgreementTypeIdsFailed = (error: string) => action(DocumentNameActionTypes.FETCH_ML_READY_AGREEMENT_TYPES_FAILED, error);
