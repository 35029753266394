import React, { ChangeEvent } from 'react';

import { OverflowTooltip } from '../../shared/tooltip';
import styles from './SubCounterpartyTypes.module.scss';

interface SubCounterpartyTypesInputProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    label: string;
    selected: boolean;
    selectedValueName: string;
    disabled: boolean;
}

export const SubCounterpartyTypesInput: React.FC<SubCounterpartyTypesInputProps> = ({ onChange, label, selected, selectedValueName, disabled }) => {
    if (selected) {
        return <input
            className={styles.subCounterpartyTypeInput}
            onChange={onChange}
            value={selectedValueName}
            disabled={disabled}
            maxLength={254}
        />;
    }

    return <div className={styles.subCounterpartyButtonWrapper}>
        <OverflowTooltip overlayText={label}></OverflowTooltip>
    </div>;
};
