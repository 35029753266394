import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo, useRef } from 'react';
import Select, { Options } from 'react-select';

import { useMenuPlacement } from '../../../../hooks/useMenuPlacement';
import { DropdownOption, customStyles } from '../../../shared/dropdown/Dropdown';
import { CustomTooltip } from '../../../shared/tooltip';
import styles from './Fields.module.scss';
import { useAppSelector } from '../../../../hooks/react-redux';
import { getAllDropdownLists } from '../../../admin/dropdown-lists/store';
import { getScrollableWrapper, SignOffConversation } from '../store';
import { SignOffConversationIcon } from '../sign-off';

const { amethyst, primary, disabledGrey } = styles;

interface DropdownProps {
    id: string;
    value: string[] | null;
    updateValue: (value: string[] | null) => void;
    dropdownList: string;
    isMulti?: boolean;
    disabled?: boolean;
    showFieldUpdated?: boolean;
    menuPortalTarget?: HTMLElement | null;
    hideDropdownIndicator?: boolean;
    signOffConversation?: SignOffConversation;
}

export const Dropdown: React.FC<DropdownProps> = ({
    id,
    value,
    updateValue,
    dropdownList,
    disabled = false,
    isMulti = false,
    showFieldUpdated = false,
    menuPortalTarget = document.body,
    hideDropdownIndicator = false,
    signOffConversation
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownLists = useAppSelector(getAllDropdownLists);
    const scrollableWrapper = useAppSelector(getScrollableWrapper);

    const mapDropdownOptions = useCallback((options: string[]) => options.map(value => ({ label: value, value })), []);
    const options = useMemo(() => mapDropdownOptions(dropdownLists.find(({ name }) => name === dropdownList)?.options || []), [dropdownLists, dropdownList, mapDropdownOptions]);

    const updateDropdownValue = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            if (isMulti) {
                value = (option as Options<DropdownOption>).map(({ value }) => value);
            } else {
                value = [(option as DropdownOption).value];
            }
        }
        updateValue(value);
    }, [updateValue, isMulti]);

    const mappedValue = useMemo(() => options.filter(option => value?.includes(option.value)) || null, [value, options]);

    const dropdownTooltip = useMemo(() => {
        if (!value || !disabled) {
            return null;
        }
        return value.join(' - ');
    }, [value, disabled]);

    const MAX_MENU_HEIGHT = 150;
    const menuPlacement = useMenuPlacement(dropdownRef, MAX_MENU_HEIGHT, scrollableWrapper);
    const controlBorderColor = useMemo(() => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return disabled ? disabledGrey : primary;
    }, [showFieldUpdated, disabled]);

    const dropdownComponent = useMemo(() => (
        <CustomTooltip overlayText={dropdownTooltip} trigger='click'>
            <div
                data-testid={`opinion-dropdown-${id}`}
                className={styles.dropdownFieldWrapper}
                ref={dropdownRef}
                style={{ maxWidth: '400px' }}
            >
                <Select
                    className={styles.dropdownField}
                    classNamePrefix='ark-dropdown'
                    isDisabled={disabled}
                    value={mappedValue}
                    onChange={updateDropdownValue}
                    isMulti={isMulti}
                    options={options}
                    styles={customStyles}
                    controlBorderColor={controlBorderColor}
                    maxMenuHeight={MAX_MENU_HEIGHT}
                    menuPlacement={menuPlacement}
                    menuPortalTarget={menuPortalTarget}
                    isClearable
                    hideDropdownIndicator={hideDropdownIndicator}
                    minControlHeight='38px'
                    fontWeight={500}
                />
            </div>
        </CustomTooltip>
    ), [disabled, mappedValue, updateDropdownValue, isMulti, options, controlBorderColor, menuPlacement, menuPortalTarget, hideDropdownIndicator, dropdownTooltip, id]);

    if (!isUndefined(signOffConversation)) {
        return (
            <div className={styles.dropdownAndNotesWrapper}>
                {dropdownComponent}
                <SignOffConversationIcon signOffConversation={signOffConversation} id={id} />
            </div>
        );
    }

    return dropdownComponent;
};
