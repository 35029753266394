import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { RawDraftContentState } from 'draft-js';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { useFetchStarted } from '../../hooks/useFetchStarted';
import { PlusButton } from '../shared/button/PlusButton';
import { TableTabs } from '../shared/table/TableTabs';
import styles from './ClauseLibrary.module.scss';
import { addClientTagStarted, checkFuzzyMatch, Clause, ClauseTableTab, ClauseTags, ClauseType, duplicateClause, fetchClauseLibraryDropdownOptionsStarted, getAllClientTags, getClause, getClauseCanSave, getClauseDescriptionOpen, getClauseLibraryDropdownOptions, getClauseTableTab, getClauseTagsModalOpen, getCreateClauseModalOpen, getFuzzyMatchModalOpen, getFuzzyMatchResults, getTagModalType, getTagTerm, saveClauseStarted, setFuzzyMatchModalOpen, setTagModalType, toggleClauseDescription, toggleClauseModal, setClauseTableTab, toggleClauseTagsModal, toggleDeleteClauseModal, updateClause, updateClauseTag, updateTagTerm, getAllArkTableClauses, getClauseLibraryIsLoading, getClausesPageNumber, fetchAllClausesStarted, getAllClauseLibraryTags } from './store';
import { IconButton } from '../shared/button/IconButton';
import { Tag } from '../shared/icons';
import { ClauseModal } from '../shared/clause-library/ClauseModal';
import { DeleteClauseConfirmationModal } from '../shared/clause-library/DeleteClauseConfirmationModal';
import { ClauseTagsModal } from './clause-tags/ClauseTagsModal';
import { getUserHasFeaturePermission, getUserHasFeaturePermissionNoAdmin } from '../auth/login/store';
import { FeaturePermission } from '../admin/users/store';
import { ClauseBuilder } from '../shared/clause-library/ClauseBuilder';
import { ClauseTileSelector } from '../shared/clause-library/ClauseTypeSelector';
import { ClientTagsManager } from './clause-tags/client-tags-modal/ClientTagsManager';
import { SystemTagsManager } from './clause-tags/system-tags-modal/SystemTagsManager';
import { ClauseLibraryTable } from './ClauseTable';

export const ClauseLibrary: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    useFetchStarted([fetchClauseLibraryDropdownOptionsStarted()]);

    const dispatch = useAppDispatch();

    const [showClauseSelect, setShowClauseSelect] = useState<boolean>(false);
    const [showTagSelect, setShowTagSelect] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const hasSystemClausePermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.SYSTEM_CLAUSE_MANAGEMENT]));
    const hasAddClausePermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.ADD_CLAUSES]));

    const openClauseModal = useCallback(() => dispatch(toggleClauseModal()), [dispatch]);
    const clauseTableTab = useAppSelector(getClauseTableTab);

    const allClauses = useAppSelector(getAllArkTableClauses);

    const isLoading = useAppSelector(getClauseLibraryIsLoading);
    const tablePageNumber = useAppSelector(getClausesPageNumber);

    const changeTab = useCallback((tabView: ClauseTableTab) => {
        dispatch(setClauseTableTab(tabView, true));
        dispatch(fetchAllClausesStarted(tablePageNumber));
    }, [dispatch, tablePageNumber]);

    const tableTabs = useMemo(() => {
        let tabs = [
            { tabToggleAction: () => changeTab(ClauseTableTab.CLIENT_CLAUSE_LIBRARY), tabTitle: 'My Clause Library', isSelected: clauseTableTab === ClauseTableTab.CLIENT_CLAUSE_LIBRARY },
            { tabToggleAction: () => changeTab(ClauseTableTab.CURRENT_TEMPLATES), tabTitle: 'Current Templates', isSelected: clauseTableTab === ClauseTableTab.CURRENT_TEMPLATES },
            { tabToggleAction: () => changeTab(ClauseTableTab.HISTORIC_TEMPLATES), tabTitle: 'Historic Templates', isSelected: clauseTableTab === ClauseTableTab.HISTORIC_TEMPLATES }
        ];
        if (hasSystemClausePermission) {
            tabs = [...tabs, { tabToggleAction: () => changeTab(ClauseTableTab.MISCELLANEOUS_TEMPLATE), tabTitle: 'Miscellaneous Templates', isSelected: clauseTableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE }];
        }
        return tabs;
    }, [changeTab, clauseTableTab, hasSystemClausePermission]);

    const isOpen = useAppSelector(getCreateClauseModalOpen);
    const clause = useAppSelector(getClause);
    const clauseCanSave = useAppSelector(getClauseCanSave);
    const descriptionOpen = useAppSelector(getClauseDescriptionOpen);
    const updateCurrentClause = useCallback((key: keyof Clause, value: RawDraftContentState | null | string | string[] | number) => dispatch(updateClause(key, value)), [dispatch]);
    const saveClause = useCallback((close: boolean) => dispatch(saveClauseStarted(close)), [dispatch]);
    const toggleDescription = useCallback(() => dispatch(toggleClauseDescription()), [dispatch]);
    const deleteClause = useCallback((clauseId: number | null) => dispatch(toggleDeleteClauseModal(clauseId)), [dispatch]);
    const duplicate = useCallback((duplicateClauseId: number, isSave: boolean) => dispatch(duplicateClause(duplicateClauseId, isSave, hasSystemClausePermission)), [dispatch, hasSystemClausePermission]);
    const tagTerm = useAppSelector(getTagTerm);
    const clauseLibraryDropdownOptions = useAppSelector(getClauseLibraryDropdownOptions);
    const fuzzyTags = useAppSelector(getFuzzyMatchResults);
    const fuzzyMatchModalOpen = useAppSelector(getFuzzyMatchModalOpen);
    const allClientTags = useAppSelector(getAllClientTags);
    const updateTag = useCallback((tagType: keyof ClauseTags, tags: string[] | null) => dispatch(updateClauseTag(tagType, tags)), [dispatch]);
    const updateClauseTagTerm = useCallback((value: string) => dispatch(updateTagTerm(value)), [dispatch]);
    const addTagStarted = useCallback((value: string) => dispatch(addClientTagStarted(value)), [dispatch]);
    const toggleFuzzyMatchModalOpen = useCallback((value: boolean) => dispatch(setFuzzyMatchModalOpen(value)), [dispatch]);
    const checkClauseFuzzyMatch = useCallback(() => dispatch(checkFuzzyMatch()), [dispatch]);
    const clauseTagsModalOpen = useAppSelector(getClauseTagsModalOpen);
    const clauseTagModalType = useAppSelector(getTagModalType);
    const setTagType = useCallback((tagType: ClauseType | null) => dispatch(setTagModalType(tagType)), [dispatch]);
    const allClauseLibraryTags = useAppSelector(getAllClauseLibraryTags);

    const editClauseTags = useCallback(() => {
        dispatch(toggleClauseTagsModal());
        if (hasSystemClausePermission) {
            setShowTagSelect(true);
        } else {
            setTagType(ClauseType.CLIENT_CLAUSE);
        }
    }, [dispatch, hasSystemClausePermission, setTagType]);

    const createNewClause = useCallback(() => {
        setShowClauseSelect(hasSystemClausePermission);
        openClauseModal();
    }, [openClauseModal, hasSystemClausePermission]);

    const selectClauseType = useCallback((clauseType: ClauseType) => {
        updateCurrentClause('systemTemplate', clauseType === ClauseType.TEMPLATE_CLAUSE ? 1 : 0);
        setShowClauseSelect(false);
    }, [updateCurrentClause]);

    const closeModal = useCallback(() => clauseCanSave ? setShowConfirmationModal(true) : dispatch(toggleClauseModal()), [clauseCanSave, dispatch]);

    const closeTagsModal = useCallback(() => {
        dispatch(toggleClauseTagsModal());
        setTagType(null);
    }, [dispatch, setTagType]);

    const selectTagType = useCallback((clauseType: ClauseType) => {
        setTagType(clauseType);
        setShowTagSelect(false);
    }, [setTagType, setShowTagSelect]);
    const showClientTagManager = useMemo(() => !isNull(clauseTagModalType) && clauseTagModalType === ClauseType.CLIENT_CLAUSE, [clauseTagModalType]);
    const showSystemTagManager = useMemo(() => !isNull(clauseTagModalType) && clauseTagModalType === ClauseType.TEMPLATE_CLAUSE, [clauseTagModalType]);

    useEffect(() => {
        if (pathname.includes('current') && clauseTableTab !== ClauseTableTab.CURRENT_TEMPLATES) {
            changeTab(ClauseTableTab.CURRENT_TEMPLATES);
        }
        if (pathname.includes('historic') && clauseTableTab !== ClauseTableTab.HISTORIC_TEMPLATES) {
            changeTab(ClauseTableTab.HISTORIC_TEMPLATES);
        }
        if (pathname.includes('miscellaneous') && hasSystemClausePermission && clauseTableTab !== ClauseTableTab.MISCELLANEOUS_TEMPLATE) {
            changeTab(ClauseTableTab.MISCELLANEOUS_TEMPLATE);
        }
    }, [clauseTableTab, pathname, changeTab, hasSystemClausePermission]);

    return (
        <div className={styles.clauseLibraryWrapper} data-testid='clause-library-wrapper'>
            <div className={styles.clauseLibraryTitleContainer}>
                <div className={styles.clauseLibraryTitle} data-testid='clause-library-title'>Clause Library</div>
                {(hasSystemClausePermission || hasAddClausePermission) && <PlusButton onClick={createNewClause} fontSize={22} testId='create-clause' />}
                <IconButton margin='5px' onClick={editClauseTags} testId='edit-clause-tags' icon={Tag} fontSize={25} />
            </div>
            <TableTabs
                tabs={tableTabs}
                testId='clause-library'
            />
            <ClauseLibraryTable clauseTableTab={clauseTableTab} allClauses={allClauses} isLoading={isLoading} />
            <ClauseModal
                isOpen={isOpen}
                closeModal={closeModal}
                shouldCloseOnEsc={showClauseSelect}
                shouldCloseOnOverlayClick={showClauseSelect}
            >
                {showClauseSelect && <ClauseTileSelector selectType={selectClauseType} setShowClauseSelect={setShowClauseSelect} />}
                {!showClauseSelect && <ClauseBuilder
                    clause={clause}
                    clauseCanSave={clauseCanSave}
                    descriptionOpen={descriptionOpen}
                    toggleClauseModal={openClauseModal}
                    updateClause={updateCurrentClause}
                    saveClause={saveClause}
                    toggleDeleteClauseModal={deleteClause}
                    duplicateClause={duplicate}
                    toggleClauseDescription={toggleDescription}
                    testId='clause'
                    tagTerm={tagTerm}
                    clauseLibraryDropdownOptions={clauseLibraryDropdownOptions}
                    fuzzyTags={fuzzyTags}
                    fuzzyMatchModalOpen={fuzzyMatchModalOpen}
                    allClientTags={allClientTags}
                    updateClauseTag={updateTag}
                    updateTagTerm={updateClauseTagTerm}
                    checkFuzzyMatch={checkClauseFuzzyMatch}
                    addClientTagStarted={addTagStarted}
                    setFuzzyMatchModalOpen={toggleFuzzyMatchModalOpen}
                    hasSystemClausePermission={hasSystemClausePermission}
                    showConfirmationModal={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    allClauseLibraryTags={allClauseLibraryTags}
                />}
            </ClauseModal>
            <DeleteClauseConfirmationModal />
            <ClauseTagsModal
                isOpen={clauseTagsModalOpen}
                closeModal={closeTagsModal}
                shouldCloseOnEsc={showTagSelect}
                shouldCloseOnOverlayClick={showTagSelect}
            >
                {showTagSelect && <ClauseTileSelector selectType={selectTagType} setShowTagSelect={setShowTagSelect} clauseTags />}
                {showClientTagManager && <ClientTagsManager closeModal={closeTagsModal} />}
                {showSystemTagManager && <SystemTagsManager
                    closeModal={closeTagsModal}
                    clauseLibraryDropdownOptions={clauseLibraryDropdownOptions}
                />}
            </ClauseTagsModal>
        </div>
    );
};
