import { ClauseTags, Clause } from '../clause-library/store';

export const initialClauseTags: ClauseTags = {
    agreementType: [],
    productType: [],
    counterpartyType: [],
    provisionType: [],
    jurisdiction: [],
    miscellaneous: [],
    opinions: []
};

export const initialClause = (systemTemplate = 0): Clause => ({
    title: '',
    description: '',
    content: null,
    tags: initialClauseTags,
    systemTemplate
});
