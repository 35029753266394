import { isNull, set } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../../hooks/react-redux';
import { DATASET_HEIGHT_OFFSET, PAGE_MARGIN_PADDING_BORDER, useSplitView } from '../../../../../hooks/useSplitView';
import { HiddenFields, HiddenSection } from '../../../../admin/my-datasets/store';
import { getDocumentAnalysisView } from '../../../../documents/my-documents/store';
import { DatasetType } from '../../../store';
import { ExtractedAnnexData } from '../../../store/mlTypes';
import { FormInstance } from '../../form-instance/FormInstance';
import { DatasetInstance, isFormDatasetInstance, OpenFieldType } from '../../store';

interface MLAnnexInstanceProps {
    datasetInstance: DatasetInstance;
    isEditing: boolean;
    isUpdating: boolean;
    parentDatasetIds: string[];
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    extractedAnnexData: ExtractedAnnexData[];
}

export const MLAnnexInstance: React.FC<MLAnnexInstanceProps> = ({ datasetInstance, isEditing, isUpdating, parentDatasetIds, parentFieldId, instanceExecutedDateMatchesParent, extractedAnnexData }) => {
    const widthOffset = PAGE_MARGIN_PADDING_BORDER + (parentDatasetIds.length * 10) + 10;
    const heightOffset = DATASET_HEIGHT_OFFSET;
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const [width] = useSplitView(documentAnalysisView, widthOffset, heightOffset);
    const padding = parentDatasetIds.length ? 10 : 0;
    const annexSectionId = isFormDatasetInstance(datasetInstance) && datasetInstance.datasetSections.find(({ id }) => id.includes('annex-section'))?.id || null;
    const hiddenFields = useMemo(() => {
        if (isFormDatasetInstance(datasetInstance)) {
            return datasetInstance.datasetSections.map(({ id }) => id).reduce((acc: HiddenFields, sectionId) => {
                const currentSections = acc[datasetInstance.datasetId] || [];
                if (sectionId === annexSectionId) {
                    return acc;
                }
                const newHiddenSection: HiddenSection = { sectionId, type: OpenFieldType.SECTION };
                const updatedSections = [...currentSections, newHiddenSection];
                return set(datasetInstance.datasetId, updatedSections, acc);
            }, {});
        }
        return null;
    }, [datasetInstance, annexSectionId]);

    if (isNull(annexSectionId) || isNull(hiddenFields)) {
        return null;
    }

    return (
        <div style={{ width: `${width}px`, maxWidth: `${width}px`, paddingLeft: `${padding}px`, height: '100%' }}>
            {datasetInstance.datasetType === DatasetType.FORM && <FormInstance datasetInstance={datasetInstance} isEditing={isEditing} isUpdating={isUpdating} hiddenFields={hiddenFields} modalInstance={false} parentFieldId={parentFieldId} instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent} extractedAnnexData={extractedAnnexData} isMLCorrection />}
        </div>
    );
};
