import { RawDraftContentState } from 'draft-js';
import { noop } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { addClientTagStarted, checkFuzzyMatch, Clause, ClauseTags, duplicateClause, fetchClauseLibraryDropdownOptionsStarted, getAllClauseLibraryTags, getAllClientTags, getClause, getClauseCanSave, getClauseDescriptionOpen, getClauseLibraryDropdownOptions, getCreateClauseModalOpen, getFuzzyMatchModalOpen, getFuzzyMatchResults, getTagTerm, saveClauseStarted, setFuzzyMatchModalOpen, toggleClauseDescription, toggleClauseModal, updateClause, updateClauseTag, updateTagTerm } from '../../../clause-library/store';
import { ClauseBuilder } from '../../../shared/clause-library/ClauseBuilder';
import { ClauseModal } from '../../../shared/clause-library/ClauseModal';
import { getAllOpinionClauseIds, getAllOpinionClauses, getExistingClausesModalOpen, linkOpinionClause, toggleLinkOpinionClauseModal } from '../store';
import { OpinionClauseTable } from './LinkOpinionClauseTable';
import styles from './OpinionSection.module.scss';
interface LinkOpinionClauseModalProps {
    opinionId: number;
    isIndustryStandard: boolean;
    hasSystemClausePermission: boolean;
}

export const LinkOpinionClauseModal: React.FC<LinkOpinionClauseModalProps> = ({
    opinionId,
    isIndustryStandard,
    hasSystemClausePermission
}) => {
    useFetchStarted([fetchClauseLibraryDropdownOptionsStarted()]);
    const existingClausesModalOpen = useAppSelector(getExistingClausesModalOpen);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const closeLinkClausesModal = useCallback(() => dispatch(toggleLinkOpinionClauseModal()), [dispatch]);
    const clauseLibraryDropdownOptions = useAppSelector(getClauseLibraryDropdownOptions);
    const allOpinionClauseIds = useAppSelector(getAllOpinionClauseIds);
    const clausesLinkedToOpinion = useAppSelector(getAllOpinionClauses);
    const clauseModalOpen = useAppSelector(getCreateClauseModalOpen);
    const clause = useAppSelector(getClause);
    const clauseCanSave = useAppSelector(getClauseCanSave);
    const descriptionOpen = useAppSelector(getClauseDescriptionOpen);
    const fuzzyTags = useAppSelector(getFuzzyMatchResults);
    const fuzzyMatchModalOpen = useAppSelector(getFuzzyMatchModalOpen);
    const allClientTags = useAppSelector(getAllClientTags);
    const tagTerm = useAppSelector(getTagTerm);
    const allOpinionClauseLibraryTags = useAppSelector(getAllClauseLibraryTags);

    const opinionClauseLinking = useCallback((clauseId: number) => {
        const isAlreadyLinked = allOpinionClauseIds.includes(clauseId);
        dispatch(linkOpinionClause(opinionId, clauseId, isAlreadyLinked));
    }, [dispatch, opinionId, allOpinionClauseIds]);
    const duplicate = useCallback((duplicateClauseId: number, isSave: boolean) => dispatch(duplicateClause(duplicateClauseId, isSave, hasSystemClausePermission)), [dispatch, hasSystemClausePermission]);

    const openClauseModal = useCallback(() => dispatch(toggleClauseModal()), [dispatch]);
    const saveClause = useCallback((close: boolean) => dispatch(saveClauseStarted(close)), [dispatch]);
    const toggleDescription = useCallback(() => dispatch(toggleClauseDescription()), [dispatch]);
    const updateCurrentClause = useCallback((key: keyof Clause, value: RawDraftContentState | null | string | string[] | number) => dispatch(updateClause(key, value)), [dispatch]);
    const updateTag = useCallback((tagType: keyof ClauseTags, tags: string[] | null) => dispatch(updateClauseTag(tagType, tags)), [dispatch]);
    const updateClauseTagTerm = useCallback((value: string) => dispatch(updateTagTerm(value)), [dispatch]);
    const addTagStarted = useCallback((value: string) => dispatch(addClientTagStarted(value)), [dispatch]);
    const toggleFuzzyMatchModalOpen = useCallback((value: boolean) => dispatch(setFuzzyMatchModalOpen(value)), [dispatch]);
    const checkClauseFuzzyMatch = useCallback(() => dispatch(checkFuzzyMatch()), [dispatch]);

    return (
        <Modal
            isOpen={existingClausesModalOpen}
            className={styles.clauseModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 9 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeLinkClausesModal}
        >
            <OpinionClauseTable opinionId={opinionId} isIndustryStandard={isIndustryStandard} hasSystemClausePermission={hasSystemClausePermission} />
            <ClauseModal
                isOpen={clauseModalOpen}
                closeModal={openClauseModal}
            >
                <ClauseBuilder
                    clause={clause}
                    clauseCanSave={clauseCanSave}
                    descriptionOpen={descriptionOpen}
                    toggleClauseModal={openClauseModal}
                    updateClause={updateCurrentClause}
                    saveClause={saveClause}
                    toggleDeleteClauseModal={noop}
                    duplicateClause={duplicate}
                    toggleClauseDescription={toggleDescription}
                    testId='opinion-clauses'
                    tagTerm={tagTerm}
                    clauseLibraryDropdownOptions={clauseLibraryDropdownOptions}
                    fuzzyTags={fuzzyTags}
                    fuzzyMatchModalOpen={fuzzyMatchModalOpen}
                    allClientTags={allClientTags}
                    updateClauseTag={updateTag}
                    updateTagTerm={updateClauseTagTerm}
                    checkFuzzyMatch={checkClauseFuzzyMatch}
                    addClientTagStarted={addTagStarted}
                    setFuzzyMatchModalOpen={toggleFuzzyMatchModalOpen}
                    showDelete={false}
                    opinionClauseLinking={opinionClauseLinking}
                    opinionClauses={clausesLinkedToOpinion}
                    hasSystemClausePermission={hasSystemClausePermission}
                    showConfirmationModal={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    allClauseLibraryTags={allOpinionClauseLibraryTags}
                    opinionClauseTable
                />
            </ClauseModal>
        </Modal>
    );
};
