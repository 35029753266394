import React from 'react';
import { isNull, isUndefined } from 'lodash/fp';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { CustomTooltip } from '../tooltip';
import styles from './Table.module.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const FlagCell: React.FC<{ value: any; }> = ({ value }) => {
    if (isUndefined(value) || isNull(value)) {
        return null;
    }

    return (
        <div className={styles.flagCellWrapper}>
            <CustomTooltip overlayText={value}>
                <div className={styles.flagCell}>
                    {getUnicodeFlagIcon(value)}
                </div>
            </CustomTooltip>
        </div>
    );
};
