import { RawDraftContentState } from 'draft-js';

export enum NotificationActionTypes {
    NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED',
    TOGGLE_NOTIFICATION_MODAL_OPEN = 'TOGGLE_NOTIFICATION_MODAL_OPEN',
    DISMISS_NOTIFICATION_STARTED = 'DISMISS_NOTIFICATION_STARTED',
    DISMISS_NOTIFICATION_SUCCESSFUL = 'DISMISS_NOTIFICATION_SUCCESSFUL',
    DISMISS_NOTIFICATION_FAILED = 'DISMISS_NOTIFICATION_FAILED',
    DISMISS_ALL_NOTIFICATIONS_STARTED = 'DISMISS_ALL_NOTIFICATIONS_STARTED',
    DISMISS_ALL_NOTIFICATIONS_SUCCESSFUL = 'DISMISS_ALL_NOTIFICATIONS_SUCCESSFUL',
    DISMISS_ALL_NOTIFICATIONS_FAILED = 'DISMISS_ALL_NOTIFICATIONS_FAILED',
    TOGGLE_SYSTEM_NOTIFICATION_MODAL = 'TOGGLE_SYSTEM_NOTIFICATION_MODAL',
    SET_ACTIVE_ML_DOCUMENTS = 'SET_ACTIVE_ML_DOCUMENTS',
    TRIGGER_PROGRESS_PERCENTAGE_ACTIVE_ML_DOCUMENT = 'TRIGGER_PROGRESS_PERCENTAGE_ACTIVE_ML_DOCUMENT',
    UPDATE_PERCENTAGE_ACTIVE_ML_DOCUMENT = 'UPDATE_PERCENTAGE_ACTIVE_ML_DOCUMENT',
    TOGGLE_ML_PROGRESS_MODAL_OPEN = 'TOGGLE_ML_PROGRESS_MODAL_OPEN',
    REMOVE_ACTIVE_ML_DOCUMENT = 'REMOVE_ACTIVE_ML_DOCUMENT',
    SET_PORTFOLIO_STATISTICS = 'SET_PORTFOLIO_STATISTICS',
    TOGGLE_SHOW_PORTFOLIO_STATISTICS = 'TOGGLE_SHOW_PORTFOLIO_STATISTICS'
}

export enum NotificationTypeId {
    ATTESTATION_CREATED = 1,
    ATTESTATION_SUBMITTED = 2,
    ATTESTATION_DEADLINE_UPDATED = 3,
    ATTESTATION_DEADLINE_APPROACHING = 4,
    ATTESTATION_DEADLINE_PASSED = 5,
    DOCUMENT_UPLOADED = 6,
    OPINION_UPLOADED = 7,
    SYSTEM = 8,
    PLAYBOOK_QUERY_RAISED = 9,
    PLAYBOOK_QUERY_RESPONSE = 10,
    PLAYBOOK_QUERY_RESOLVED = 11,
    PLAYBOOK_QUERY_REMINDER = 12,
    PLAYBOOK_SUGGESTED_CHANGES = 13,
    PLAYBOOK_SUGGESTED_CHANGES_CONVERSATION_RESPONSE = 14,
    PLAYBOOK_SUGGESTED_CHANGES_RESOLVED = 15,
    INITIAL_AI_EXTRACTION_COMPLETE = 16,
    EXTENSIVE_AI_EXTRACTION_COMPLETE = 17,
    DOCUMENT_ANALYSIS_FAILED = 18,
    INITIAL_AI_EXTRACTION_FAILED = 19,
    EXTENSIVE_AI_EXTRACTION_FAILED = 20
}

export enum NotificationType {
    ATTESTATION_CREATED = 'attestation created',
    ATTESTATION_SUBMITTED = 'attestation submitted',
    ATTESTATION_DEADLINE_UPDATED = 'attestation deadline updated',
    ATTESTATION_DEADLINE_APPROACHING = 'attestation deadline approaching',
    ATTESTATION_DEADLINE_PASSED = 'attestation deadline passed',
    DOCUMENT_UPLOADED = 'document uploaded',
    OPINION_UPLOADED = 'opinion uploaded',
    SYSTEM = 'system notification',
    PLAYBOOK_QUERY_RAISED = 'playbook query raised',
    PLAYBOOK_QUERY_RESPONSE = 'playbook query response',
    PLAYBOOK_QUERY_RESOLVED = 'playbook query resolved',
    PLAYBOOK_QUERY_REMINDER = 'playbook query reminder',
    PLAYBOOK_SUGGESTED_CHANGES = 'playbook suggested changes',
    PLAYBOOK_SUGGESTED_CHANGES_CONVERSATION_RESPONSE = 'playbook suggested changes conversation response',
    PLAYBOOK_SUGGESTED_CHANGES_RESOLVED = 'playbook suggested changes resolved',
    INITIAL_AI_EXTRACTION_COMPLETE = 'initial AI extraction complete',
    EXTENSIVE_AI_EXTRACTION_COMPLETE = 'extensive AI extraction complete',
    DOCUMENT_ANALYSIS_FAILED = 'document analysis failed',
    INITIAL_AI_EXTRACTION_FAILED = 'initial AI extraction failed',
    EXTENSIVE_AI_EXTRACTION_FAILED = 'extensive AI extraction failed'
}

export interface NotificationBody {
    title: string;
    description: string;
}

export interface SystemNotificationBody {
    title: string;
    description: string;
    content: RawDraftContentState;
}

export interface StandardNotification {
    userNotificationId: number;
    notificationId: number;
    notificationTypeId: number;
    linkId: number;
    body: NotificationBody;
    createdBy: number | null;
    createdDate: string;
    clientId: number;
    label: string;
    routeAdmin: number;
    picture?: Buffer;
    initials?: string;
}

export interface SystemNotification {
    userNotificationId: number;
    notificationId: number;
    notificationTypeId: number;
    linkId: null;
    body: SystemNotificationBody;
    createdBy: number | null;
    createdDate: string;
    clientId: number;
    label: string;
}

export interface ActiveMLDocument {
    documentDescription: string;
    entityAName: string;
    entityBName: string;
    documentName: string;
    documentId: number;
    progress: number;
    initialUpload: boolean;
    progressString?: string;
}

export type Notification = SystemNotification | StandardNotification;

export const isSystemNotification = (notification: Notification): notification is SystemNotification => notification.notificationTypeId === NotificationTypeId.SYSTEM;

export enum InitialProgress {
    FAILED = 0,
    DOCUMENT_UPLOADED = 10,
    TEXTRACT_OCR_DOCUMENT_COMPLETE_AI = 60,
    TEXTRACT_OCR_DOCUMENT_COMPLETE = 70,
    INITIAL_CLASSIFICATION_COMPLETE = 80,
    CROSS_REFERENCING_LEI_DATABASE_COMPLETE = 90,
    INTIAL_DOCUMENT_PROCESSING_COMPLETE = 100
}

export enum ExtensiveProgress {
    FAILED = 0,
    QUERIES_AND_INSTANCE_COLLECTED = 10,
    EXTENSIVE_QUERIES_RECEIVED = 30,
    ANNEX_TABLE_EXTRACTION_COMPLETE = 40,
    PARTIAL_POST_PROCESSING_COMPLETE = 60,
    FURTHER_POST_PROCESSING_COMPLETE = 80,
    ALL_POST_PROCESSING_COMPLETE = 100
}

export interface NotificationState {
    modalOpen: boolean;
    notifications: Notification[];
    systemNotification: SystemNotification | null;
    activeMLDocuments: ActiveMLDocument[];
    mlProgressModalOpen: boolean;
    portfolioStatistics: string[];
    showPortfolioStatistics: boolean;
}
