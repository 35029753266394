import { flatten, isEqual, isUndefined, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, debounce, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';

import { fetchDocumentSpreadAnalytics, fetchEntitySpreadAnalytics, fetchMyAttestationProgressAnalytics } from '../../../services/analytics';
import { enquireModule } from '../../../services/auth';
import { updateCustomFilters } from '../../../services/users';
import { CustomFilters, getUser, updateUserCustomFilters } from '../../auth/login/store';
import { defaultHomeScreenConfig } from '../../constants/homeScreenConfig';
import {
    enquireModuleFailed,
    enquireModuleSuccessful,
    fetchHomepageAttestationProgressFailed,
    fetchHomepageAttestationProgressStarted,
    fetchHomepageAttestationProgressSuccessful,
    fetchHomepageDocumentSpreadFailed,
    fetchHomepageDocumentSpreadStarted,
    fetchHomepageDocumentSpreadSuccessful,
    fetchHomepageEntitySpreadFailed,
    fetchHomepageEntitySpreadStarted,
    fetchHomepageEntitySpreadSuccessful,
    fetchRelevantHomeScreenAnalytics,
    redirectMyAttestation,
    setCustomFiltersUpdated,
    setHomeScreenConfig,
    toggleUnauthorisedModuleModalOpen,
    updateCustomFiltersFailed,
    updateCustomFiltersSuccessful
} from './actions';
import { getCurrentUserCustomFilters, getIsSavingCustomFilters, getSavedUserCustomFilters, getUnauthorisedModule } from './selectors';
import { ArkModule, HomepageAnalyticsActionTypes, HomePageTile, MyAttestationAnalytics } from './types';
import { PieChartAnalyticsData } from '../../shared/analytics/PieChart';

export function* attemptFetchHomepageDocumentSpread() {
    try {
        const analytics: PieChartAnalyticsData[] = yield call(fetchDocumentSpreadAnalytics, { entityId: null });
        yield put(fetchHomepageDocumentSpreadSuccessful(analytics));
    } catch (e) {
        yield put(fetchHomepageDocumentSpreadFailed((e as Error).message));
    }
}

function* fetchHomepageDocumentSpreadWatcher() {
    yield takeEvery(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_STARTED, attemptFetchHomepageDocumentSpread);
}

export function* attemptFetchHomepageEntitySpread() {
    try {
        const analytics: PieChartAnalyticsData[] = yield call(fetchEntitySpreadAnalytics, { entityId: null });
        yield put(fetchHomepageEntitySpreadSuccessful(analytics));
    } catch (e) {
        yield put(fetchHomepageEntitySpreadFailed((e as Error).message));
    }
}

function* fetchHomepageEntitySpreadWatcher() {
    yield takeEvery(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_STARTED, attemptFetchHomepageEntitySpread);
}

export function* attemptFetchMyAttestationProgress() {
    try {
        const analytics: MyAttestationAnalytics[] = yield call(fetchMyAttestationProgressAnalytics);
        yield put(fetchHomepageAttestationProgressSuccessful(analytics));
    } catch (e) {
        yield put(fetchHomepageAttestationProgressFailed((e as Error).message));
    }
}

function* fetchMyAttestationProgressWatcher() {
    yield takeEvery(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_STARTED, attemptFetchMyAttestationProgress);
}

export function* redirectMyAttestationAnalytics({ payload }: ReturnType<typeof redirectMyAttestation>) {
    const navigationPath = `/my-attestations/${payload}`;
    yield put(push(navigationPath));
}

function* redirectMyAttestationWatcher() {
    yield takeEvery(HomepageAnalyticsActionTypes.REDIRECT_ANALYTICS_MY_ATTESTATION, redirectMyAttestationAnalytics);
}

export function* openUnauthorisedModuleModal() {
    yield put(toggleUnauthorisedModuleModalOpen(true));
}

function* unauthorisedModuleWatcher() {
    yield takeEvery(HomepageAnalyticsActionTypes.SET_UNAUTHORISED_MODULE, openUnauthorisedModuleModal);
}

export function* attemptEnquireModule() {
    try {
        const module: ArkModule = yield select(getUnauthorisedModule);
        yield call(enquireModule, { module });
        yield put(enquireModuleSuccessful());
    } catch (e) {
        yield put(enquireModuleFailed((e as Error).message));
    }
}

function* enquireModuleWatcher() {
    yield takeLeading(HomepageAnalyticsActionTypes.ENQUIRE_MODULE_STARTED, attemptEnquireModule);
}

export function* attemptUpdateCustomFilters() {
    try {
        const currentUserCustomFilters: CustomFilters[] = yield select(getCurrentUserCustomFilters);
        yield call(updateCustomFilters, { customFilters: currentUserCustomFilters });
        yield put(updateCustomFiltersSuccessful(currentUserCustomFilters));
        yield put(updateUserCustomFilters(currentUserCustomFilters));
        yield put(setCustomFiltersUpdated(false));
        toast('Custom filters updated');
    } catch (e) {
        yield put(updateCustomFiltersFailed((e as Error).message));
        toast.error('Unable to update custom filters. Please try again.');
    }
}

function* attemptUpdateCustomFiltersWatcher() {
    yield takeLeading(HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTERS_STARTED, attemptUpdateCustomFilters);
}

export function* checkCustomFiltersUpdated() {
    const isSaving: boolean = yield select(getIsSavingCustomFilters);
    const currentUserCustomFilters: CustomFilters[] = yield select(getCurrentUserCustomFilters);
    const savedUserCustomFilters: CustomFilters[] = yield select(getSavedUserCustomFilters);
    if (!isSaving) {
        const updated = !isEqual(currentUserCustomFilters, savedUserCustomFilters);
        yield put(setCustomFiltersUpdated(updated));
    }
}

function* checkCustomFiltersUpdatedWatcher() {
    yield debounce(500, [HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTER_VALUES, HomepageAnalyticsActionTypes.ADD_OR_DELETE_USER_CUSTOM_FILTER], checkCustomFiltersUpdated);
}

const potentialAnalyticsToFetch = [
    { id: HomePageTile.DOCUMENT_SPREAD, action: fetchHomepageDocumentSpreadStarted },
    { id: HomePageTile.ENTITY_SPREAD, action: fetchHomepageEntitySpreadStarted },
    { id: HomePageTile.MY_ATTESTATIONS, action: fetchHomepageAttestationProgressStarted }
];

export function* attemptFetchRelevantHomeScreenAnalytics({ payload }: ReturnType<typeof fetchRelevantHomeScreenAnalytics>) {
    let user = payload;
    if (isUndefined(user)) {
        user = yield select(getUser);
    }
    const homeScreenConfig = user?.config || defaultHomeScreenConfig;
    yield put(setHomeScreenConfig(homeScreenConfig));
    const tiles = flatten(Object.values(unset('column-4', homeScreenConfig)));
    for (const tile of tiles) {
        const analyticsToFetch = potentialAnalyticsToFetch.find(({ id }) => id === tile);
        if (analyticsToFetch) {
            yield put(analyticsToFetch.action());
        }
    }
}

function* relevantHomeScreenAnalyticsWatcher() {
    yield takeLeading(HomepageAnalyticsActionTypes.FETCH_RELEVANT_HOME_SCREEN_ANALYTICS, attemptFetchRelevantHomeScreenAnalytics);
}

export function* homepageSaga() {
    yield all([
        fork(fetchHomepageDocumentSpreadWatcher),
        fork(fetchHomepageEntitySpreadWatcher),
        fork(fetchMyAttestationProgressWatcher),
        fork(redirectMyAttestationWatcher),
        fork(enquireModuleWatcher),
        fork(unauthorisedModuleWatcher),
        fork(relevantHomeScreenAnalyticsWatcher),
        fork(attemptUpdateCustomFiltersWatcher),
        fork(checkCustomFiltersUpdatedWatcher)
    ]);
}
