import React, { useCallback, useMemo } from 'react';

import { AnalysisView } from '../../documents/my-documents/store';
import { ChoiceLeft, Choice, ChoiceRight } from '../icons';
import { IconTooltip } from '../tooltip';
import styles from './Analysis.module.scss';

const { grey, primary, french } = styles;

interface AnalysisViewButtonsProps {
    toggleAnalysisView: (view: AnalysisView) => void;
    currentView: AnalysisView;
    disabled: boolean;
}

export const AnalysisViewButtons: React.FC<AnalysisViewButtonsProps> = ({ toggleAnalysisView, currentView, disabled }) => {

    const isSelected = useCallback((view: AnalysisView) => currentView === view, [currentView]);
    const getTooltipContent = useCallback((view: AnalysisView) => {
        if (disabled) {
            return null;
        }
        switch (view) {
            case AnalysisView.DATASET:
                return 'Data';
            case AnalysisView.SPLIT:
                return 'Split';
            case AnalysisView.DOCUMENT:
                return 'Document';
            default:
                return null;
        }
    }, [disabled]);

    const getIconColour = useCallback((view: AnalysisView) => {
        if (disabled) {
            return grey;
        }
        return isSelected(view) ? french : primary;
    }, [disabled, isSelected]);

    const cursor = useMemo(() => disabled ? 'not-allowed' : 'pointer', [disabled]);

    return (
        <div className={styles.viewButtonsWrapper}>
            <button className={styles.viewButton} onClick={() => toggleAnalysisView(AnalysisView.DATASET)} disabled={disabled}>
                <IconTooltip icon={ChoiceLeft} fontSize={30} content={getTooltipContent(AnalysisView.DATASET)} iconColor={getIconColour(AnalysisView.DATASET)} cursor={cursor} />
            </button>
            <button className={styles.viewButton} onClick={() => toggleAnalysisView(AnalysisView.SPLIT)} disabled={disabled}>
                <IconTooltip icon={Choice} fontSize={30} content={getTooltipContent(AnalysisView.SPLIT)} iconColor={getIconColour(AnalysisView.SPLIT)} cursor={cursor} />
            </button>
            <button className={styles.viewButton} onClick={() => toggleAnalysisView(AnalysisView.DOCUMENT)} disabled={disabled}>
                <IconTooltip icon={ChoiceRight} fontSize={30} content={getTooltipContent(AnalysisView.DOCUMENT)} iconColor={getIconColour(AnalysisView.DOCUMENT)} cursor={cursor} />
            </button>
        </div>
    );
};
