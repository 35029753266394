import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { isNull } from 'lodash/fp';

import styles from './Modal.module.scss';
const { white, grey } = styles;

export interface PositionModalProps {
    closeModal: () => void;
    isOpen: boolean;
    position: Position | null;
    children?: React.ReactNode;
    testId: string;
    height?: string;
    width?: string;
    maxWidth?: string;
    padding?: string;
    renderLeftOfClick?: boolean;
    renderAboveClick?: boolean;
    overflow?: string;
    showOverlay?: boolean;
    closeOnOverlayClick?: boolean;
}

export interface Position {
    x: number;
    y: number;
}

export const PositionModal: React.FC<PositionModalProps> = ({
    closeModal,
    isOpen,
    position,
    children,
    testId,
    height = '80px',
    width = '200px',
    renderLeftOfClick = false,
    renderAboveClick = false,
    overflow = 'auto',
    padding = '5px',
    maxWidth,
    showOverlay = false,
    closeOnOverlayClick = true
}) => {
    const [modalWidth, setModalWidth] = useState<number>(width.match(/(\d+)/) ? parseInt(width.match(/(\d+)/)![0]) : 0);
    const [modalHeight, setModalHeight] = useState<number>(height.match(/(\d+)/) ? parseInt(height.match(/(\d+)/)![0]) : 0);
    const positionModalRef = useRef<HTMLDivElement>(null);
    const renderLeft = useMemo(() => {
        if (isNull(position)) {
            return false;
        }
        if (renderLeftOfClick) {
            return renderLeftOfClick;
        }

        return position.x + modalWidth > window.innerWidth;
    }, [renderLeftOfClick, position, modalWidth]);

    const leftPosition = useMemo(() => {
        if (!position) {
            return 0;
        }
        return renderLeft ? position.x - modalWidth : position.x;
    }, [renderLeft, position, modalWidth]);

    const getModalDimensions = useCallback(() => {
        if (!isNull(positionModalRef) && !isNull(positionModalRef.current)) {
            setModalWidth(positionModalRef.current.clientWidth);
            setModalHeight(positionModalRef.current.clientHeight);
        }
    }, [positionModalRef]);

    const renderAbove = useMemo(() => {
        if (isNull(position)) {
            return false;
        }
        if (renderAboveClick) {
            return renderAboveClick;
        }
        return position.y + modalHeight > window.innerHeight;
    }, [renderAboveClick, position, modalHeight]);

    const topPosition = useMemo(() => {
        if (!position) {
            return 0;
        }
        return renderAbove ? position.y - modalHeight : position.y;
    }, [renderAbove, position, modalHeight]);

    const defaultOverlayStyle = showOverlay ? { display: 'flex', zIndex: 10 } : { display: 'flex', backgroundColor: 'transparent', zIndex: 10 };

    useEffect(() => {
        const initialMountTimeout = setTimeout(() => getModalDimensions(), 50);
        window.addEventListener('resize', getModalDimensions);
        return () => {
            window.removeEventListener('resize', getModalDimensions);
            clearTimeout(initialMountTimeout);
        };
    }, [getModalDimensions]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.positionModal}
            ariaHideApp={false}
            style={{
                overlay: defaultOverlayStyle,
                content: {
                    position: 'absolute',
                    top: `${topPosition}px`,
                    left: `${leftPosition}px`,
                    border: `1px solid ${grey}`,
                    background: white,
                    overflow,
                    borderRadius: '5px',
                    outline: 'none',
                    padding,
                    maxWidth
                }
            }}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            shouldCloseOnEsc
            onRequestClose={closeModal}
        >
            <div className={styles.positionModalInputWrapper} style={{ height, width }} data-testid={`${testId}-position-modal-wrapper`} ref={positionModalRef}>
                {children}
            </div>
        </Modal>
    );
};
