import { isArray, isEqual, isNull, noop } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppSelector } from '../../../../../hooks/react-redux';
import { getPositiveAndNegativeAnswers } from '../../../../constants/opinion';
import { Button } from '../../../../shared/button/Button';
import { Dropdown } from '../../../../shared/dropdown/Dropdown';
import { Icon } from '../../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../../shared/icons';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { Spinner } from '../../../../shared/spinner/Spinner';
import { WYSIWYG } from '../../../../shared/wysiwyg/WYSIWYG';
import { defaultEmptyWysiwyg } from '../../../instances/fields/DropdownDetails';
import { OpinionInstanceContent, OpinionInstanceField, OpinionInstanceFieldType } from '../../../instances/store';
import styles from '../../Analytics.module.scss';
import { getIsFetchingOpinionAverageBreakdown, getSelectedOpinionAverage, getUltimateSingleFieldForScope } from '../../store';
import { DropdownDetailsAverage, OpinionAverageView } from '../../store/types';
import { getAverageLabel } from './AverageLevel';

const { primary } = styles;

interface BreakdownFieldProps {
    field: DropdownDetailsAverage;
}

export const BreakdownField: React.FC<BreakdownFieldProps> = ({ field }) => {
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
    const wysiwygOpenIcon = detailsOpen ? CaretDown : CaretSide;
    const toggleDetailsOpen = () => setDetailsOpen(!detailsOpen);

    const { fieldId, sectionId, label, dropdownValue, score, wysiwygValue } = field;
    const uniqueFieldId = `${sectionId}-${fieldId}`;
    const { colour } = useMemo(() => getAverageLabel(score), [score]);
    const renderedDropdownValue = useMemo(() => isNull(dropdownValue) ? null : { value: dropdownValue[0], label: dropdownValue[0] }, [dropdownValue]);
    const detailsLabel = useMemo(() => `Details${isNull(wysiwygValue) ? ' (none)' : ''}`, [wysiwygValue]);
    const wysiwygFieldValue = useMemo(() => isNull(wysiwygValue) ? defaultEmptyWysiwyg : wysiwygValue, [wysiwygValue]);

    const detailsColour = useMemo(() => {
        if (isNull(dropdownValue) || isNull(wysiwygValue)) {
            return primary;
        }
        return colour;
    }, [dropdownValue, wysiwygValue, colour]);

    return (
        <div className={styles.breakdownFieldWrapper} key={uniqueFieldId} style={{ border: `solid 2px ${colour}` }}>
            <div className={styles.score} style={{ backgroundColor: colour }} />
            <div className={styles.contentWrapper}>
                <div className={styles.question}>{label}</div>
                <div className={styles.dropdownWrapper}>
                    <Dropdown
                        options={[]}
                        value={renderedDropdownValue}
                        disabled={true}
                        menuPortalTarget={document.body}
                        onChange={noop}
                        placeholder='No value provided'
                    />
                </div>
                <div className={styles.wysiwygDetailsWrapper}>
                    <div className={styles.wysiwygHeader} onClick={toggleDetailsOpen} data-testid='opinion-breakdown-analytics-collapse'>
                        <div className={styles.wysiwygOpenIcon}>
                            <Icon icon={wysiwygOpenIcon} fontSize={15} color={detailsColour} />
                        </div>
                        <div className={styles.wysiwygLabel} data-testid='opinion-breakdown-wysiwyg-details'>{detailsLabel}</div>
                    </div>
                    {detailsOpen &&
                        <div className={styles.wysiwygContent}>
                            <WYSIWYG
                                content={wysiwygFieldValue}
                                updateContent={noop}
                                disabled={true}
                                toolbarHidden={true}
                                height='fit-content'
                                maxHeight='fit-content'
                                min-height='fit-content'
                                showBorder={false}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

interface AverageBreakdownProps {
    height: number;
    width: number;
    changeView: (value: OpinionAverageView) => void;
}

export const AverageBreakdown: React.FC<AverageBreakdownProps> = ({ height, width, changeView }) => {
    const opinionAverageBreakdown = useAppSelector(getSelectedOpinionAverage);
    const isFetching = useAppSelector(getIsFetchingOpinionAverageBreakdown);
    const ultimateField = useAppSelector(getUltimateSingleFieldForScope);

    const getOpinionBreakdown = useCallback((content: OpinionInstanceContent) => {
        const fields = Object.entries(content).reduce((acc: DropdownDetailsAverage[], [sectionId, fields]) => {
            Object.entries(fields).forEach(([fieldId, fieldContent]) => {
                if (!isArray(fieldContent)) {
                    const singleField = fieldContent as OpinionInstanceField;
                    if (singleField.type === OpinionInstanceFieldType.DROPDOWN_DETAILS) {
                        const { label, dropdownValue, wysiwygValue } = singleField;
                        const { positiveAnswers, negativeAnswers } = getPositiveAndNegativeAnswers(sectionId, fieldId);
                        let score = 0;
                        if (isNull(dropdownValue)) {
                            score = 0.5;
                        } else {
                            const dropdownString = dropdownValue[0];
                            if (positiveAnswers.includes(dropdownString)) {
                                score = 1;
                            }
                            if (negativeAnswers.includes(dropdownString)) {
                                if (isEqual(ultimateField, { sectionId, fieldId })) {
                                    score = -1;
                                }
                                score = 0;
                            }
                        }
                        acc.push({ fieldId, sectionId, label, dropdownValue, wysiwygValue, score });
                    }
                }
            });
            return acc;
        }, []);
        return fields.sort((a, b) => a.score - b.score);
    }, [ultimateField]);

    const breakdown = useMemo(() => {
        if (isNull(opinionAverageBreakdown)) {
            return null;
        }
        const { content } = opinionAverageBreakdown;
        return getOpinionBreakdown(content);
    }, [opinionAverageBreakdown, getOpinionBreakdown]);

    const breakdownWidth = useMemo(() => width / 2, [width]);
    const breakdownHeight = useMemo(() => height - 80, [height]);

    if (isFetching) {
        return (
            <div className={styles.breakdownWrapper} style={{ height: `${breakdownHeight}px`, width: `${breakdownWidth}px` }}>
                <Spinner />
            </div>
        );
    }

    if (isNull(breakdown)) {
        return null;
    }

    return (
        <div className={styles.breakdownWrapper} style={{ height: `${breakdownHeight}px`, width: `${breakdownWidth}px` }}>
            <Scrollable height={`${breakdownHeight - 45}px`}>
                {breakdown.map((field, index) => <BreakdownField field={field} key={index} />)}
            </Scrollable>
            <Button label='Back' onClick={() => changeView(OpinionAverageView.OPINIONS)} />
        </div>
    );
};
