import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { DoraThirdPartyState, DoraThirdPartyView } from './types';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { CompanyEntity, Lei, SearchEntityBy } from '../../../admin/entity/store';

const getRoot = (state: ApplicationState): DoraThirdPartyState => state.dora.thirdParty;

// Third Party Companies
export const getThirdPartyCompaniesModalOpen = (state: ApplicationState): boolean => !isNull(getRoot(state).selectedThirdPartyCompany);
export const getSelectedThirdPartyCompany = (state: ApplicationState): CompanyEntity | null => getRoot(state).selectedThirdPartyCompany;
export const getCurrentThirdPartyCompany = (state: ApplicationState): CompanyEntity | null => getRoot(state).currentThirdPartyCompany;
export const getPaginatedThirdPartyCompanies = (state: ApplicationState): CompanyEntity[] => getRoot(state).allThirdPartyCompanies;
export const getThirdPartyDropdownOptions = (state: ApplicationState): DropdownOption[] => {
    const thirdParties = getRoot(state).allThirdPartyCompanies;
    return thirdParties.map(({ name }) => ({ value: name, label: name }));
};
export const getSearchModalOpen = (state: ApplicationState): boolean => getRoot(state).searchModalOpen;
export const getIsSearching = (state: ApplicationState): boolean => getRoot(state).isSearching;
export const getSearchResults = (state: ApplicationState): Lei[] => getRoot(state).searchResults;
export const getSearchType = (state: ApplicationState): SearchEntityBy => getRoot(state).searchBy;
export const getSearchValue = (state: ApplicationState): string => getRoot(state).searchInput;
export const getThirdPartyView = (state: ApplicationState): DoraThirdPartyView => getRoot(state).thirdPartyView;
export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;
export const getThirdPartyCompanyIsSaving = (state: ApplicationState): boolean => getRoot(state).savingThirdPartyCompany;

export const getFuzzyCompletions = (state: ApplicationState): string[] => getRoot(state).fuzzyCompletions;
export const getAutoCompletions = (state: ApplicationState): string[] => getRoot(state).autoCompletions;
export const getShowCompletions = (state: ApplicationState): boolean => getRoot(state).showCompletions;
export const getShowFuzzyAutoCompletions = (state: ApplicationState): boolean => (getFuzzyCompletions(state).length > 0 || getAutoCompletions(state).length > 0) && getShowCompletions(state);

// Table pagination

export const getThirdPartyCompaniesFilters = (state: ApplicationState): TableFilters => getRoot(state).thirdPartyCompaniesFilters;
export const getThirdPartyCompaniesColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).thirdPartyCompaniesSort;
export const getThirdPartyCompaniesPageSize = (state: ApplicationState): number => getRoot(state).thirdPartyCompaniesPageSize;
export const getThirdPartyCompaniesPageNumber = (state: ApplicationState): number => getRoot(state).thirdPartyCompaniesPage;

export const getIsFetchingThirdPartyCompanies = (state: ApplicationState): boolean => getRoot(state).fetchingThirdPartyCompanies;
export const getTotalThirdPartyCompanies = (state: ApplicationState): number => getRoot(state).totalThirdPartyCompanies;

export const getAvailableThirdPartyCompaniesFilters = (state: ApplicationState): TableFilters => getRoot(state).availableThirdPartyCompaniesFilters;
export const getAvailableThirdPartyCompaniesColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).availableThirdPartyCompaniesSort;
export const getAvailableThirdPartyCompaniesPageSize = (state: ApplicationState): number => getRoot(state).availableThirdPartyCompaniesPageSize;
export const getAvailableThirdPartyCompaniesPageNumber = (state: ApplicationState): number => getRoot(state).availableThirdPartyCompaniesPage;

// Search pagination

export const getThirdPartyCompanySearchPageNumber = (state: ApplicationState): number => getRoot(state).searchEntityPage;
export const getTotalSearchThirdPartyCompanies = (state: ApplicationState): number => getRoot(state).totalSearchEntities;
export const getThirdPartyCompanySearchPageSize = (state: ApplicationState): number => getRoot(state).searchEntityPageSize;
