import React from 'react';

export const Delete: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill={color || 'currentColor'} d="M100,28.8L100,28.8c3.5,3.5,3.6,9.4,0,13l-59.1,59.1c-3.5,3.5-9.4,3.6-13,0l0,0c-3.5-3.5-3.6-9.4,0-13
	    l59.2-59.2C90.6,25.1,96.4,25.1,100,28.8z"/>
        <path fill={color || 'currentColor'} d="M27.9,28.8L27.9,28.8c3.5-3.5,9.4-3.6,13,0l59.2,59.2c3.5,3.5,3.6,9.4,0,13l0,0c-3.5,3.5-9.4,3.6-13,0
	    L27.8,41.7C24.3,38.2,24.4,32.3,27.9,28.8z"/>
    </svg>
);
