import React from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { getSearchDocumentsView } from '../search/store';
import { SearchDocuments } from './SearchDocuments';
import styles from './SearchDocuments.module.scss';

export const SearchDocumentsViewSelector: React.FC = () => {
    const searchDocumentsView = useAppSelector(getSearchDocumentsView);
    return (
        <div className={styles.viewSelectorWrapper} data-testid='search-documents-view-wrapper'>
            <SearchDocuments searchType={searchDocumentsView} />
        </div>
    );
};
