import React, { useCallback, useMemo } from 'react';
import { isNull, set } from 'lodash/fp';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { getAllAttestationForms } from '../../attestations/store';
import { AttestationInstanceScheduler, CreateAttestationScheduler, initialAttestationInstance, SchedulerInterval, updateContentAttestationId, updateContentAttestationInstances } from '../store';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import styles from '../Workflow.module.scss';
import { CreateAttestationInstancesTable, CreateAttestationDeadlineCell } from '../../../shared/attestations';

interface CreateAttestationContentProps {
    event: CreateAttestationScheduler;
    isEditing: boolean;
    testId: string;
}

export const CreateAttestationContent: React.FC<CreateAttestationContentProps> = ({ event, testId, isEditing }) => {
    const dispatch = useAppDispatch();
    const attestationForms = useAppSelector(getAllAttestationForms);

    const attestationFormOptions = useMemo(() => attestationForms.map(({ attestationFormId, name }) => ({ value: attestationFormId.toString(), label: name })), [attestationForms]);
    const attestationFormId = useMemo(() => attestationFormOptions.find(({ value }) => value === event.content.attestationFormId?.toString()) || null, [attestationFormOptions, event]);

    const intervalValueOptions = useMemo(() => Array(31).fill(1).map((_, index) => ({ value: `${(index + 1) * 1}`, label: `${(index + 1) * 1}` })), []);
    const intervalValue = useCallback((index: number) => intervalValueOptions.find(({ value }) => value === event.content.instancesToCreate[index].deadlineIntervalValue?.toString()) || null, [event.content.instancesToCreate, intervalValueOptions]);

    const intervalOptions = useMemo(() => Object.values(SchedulerInterval).map(value => ({ value, label: capitaliseStartLetter(value) })), []);
    const interval = useCallback((index: number) => intervalOptions.find(({ value }) => value === event.content.instancesToCreate[index].deadlineInterval) || null, [intervalOptions, event.content.instancesToCreate]);

    const getAttestationUserAnswers = useCallback((selectedId: number) => attestationForms.find(({ attestationFormId }) => attestationFormId === selectedId)?.userAnswers.map(userAnswer => ({ ...userAnswer, userId: null })) || [], [attestationForms]);

    const updateAttestationInstances = useCallback((instancesToCreate: AttestationInstanceScheduler[]) => dispatch(updateContentAttestationInstances(instancesToCreate)), [dispatch]);

    const updateAttestationForm = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = parseInt((dropdownValue as DropdownOption).value);
            const userAnswers = getAttestationUserAnswers(value);
            dispatch(updateContentAttestationId(value, userAnswers));
        }
    };

    const updateUserAnswerId = useCallback((index: number, userAnswerId: string, dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let userId: null | number = null;
        if (!isNull(dropdownValue)) {
            userId = parseInt((dropdownValue as DropdownOption).value);
        }
        const updatedInstances = event.content.instancesToCreate.map((instance, i) => i === index ? { ...instance, userIds: instance.userIds.map(user => user.userAnswerId === userAnswerId ? { ...user, userId } : user) } : instance);
        updateAttestationInstances(updatedInstances);
    }, [event.content.instancesToCreate, updateAttestationInstances]);

    const updateDeadlineDropdownValue = useCallback((index: number, key: 'deadlineInterval' | 'deadlineIntervalValue', dropdownValue: DropdownOption | Options<DropdownOption> | null, asNumber = false) => {
        let value: number | string | null = null;
        if (!isNull(dropdownValue)) {
            value = asNumber ? parseInt((dropdownValue as DropdownOption).value) : (dropdownValue as DropdownOption).value;
        }
        const updatedInstances = event.content.instancesToCreate.map((instance, i) => i === index ? set(key, value, instance) : instance);
        updateAttestationInstances(updatedInstances);
    }, [event.content.instancesToCreate, updateAttestationInstances]);

    const updateDeadlineIntervalValue = useCallback((index: number, value: DropdownOption | Options<DropdownOption> | null) => updateDeadlineDropdownValue(index, 'deadlineIntervalValue', value, true), [updateDeadlineDropdownValue]);
    const updateDeadlineInterval = useCallback((index: number, value: DropdownOption | Options<DropdownOption> | null) => updateDeadlineDropdownValue(index, 'deadlineInterval', value), [updateDeadlineDropdownValue]);

    const removeAttestationInstance = useCallback((index: number) => {
        const updatedInstances = event.content.instancesToCreate.filter((_, i) => i !== index);
        updateAttestationInstances(updatedInstances);
    }, [event.content.instancesToCreate, updateAttestationInstances]);

    const addAttestationInstance = useCallback(() => {
        const updatedInstances = [...event.content.instancesToCreate, initialAttestationInstance(getAttestationUserAnswers(event.content.attestationFormId!))];
        updateAttestationInstances(updatedInstances);
    }, [updateAttestationInstances, getAttestationUserAnswers, event.content]);

    const deadlineCell = useCallback((index: number) => (
        <CreateAttestationDeadlineCell
            index={index}
            isEditing={isEditing}
            testId={testId}
            interval={interval}
            intervalValue={intervalValue}
            updateDeadlineIntervalValue={updateDeadlineIntervalValue}
            updateDeadlineInterval={updateDeadlineInterval}
            intervalValueOptions={intervalValueOptions}
            intervalOptions={intervalOptions}
        />
    ), [testId, interval, intervalValue, updateDeadlineIntervalValue, updateDeadlineInterval, intervalValueOptions, intervalOptions, isEditing]);

    return (
        <div className={styles.createAttestationWrapper} data-testid={`${testId}-attestation-content-wrapper`}>
            <div className={styles.attestationFormSelect} data-testid={`${testId}-attestation-form-wrapper`}>
                <div className={styles.attestationFormSelectLabel} data-testid={`${testId}-attestation-form-label`}>Select Attestation Form</div>
                <Dropdown
                    value={attestationFormId}
                    options={attestationFormOptions}
                    onChange={updateAttestationForm}
                    isClearable={false}
                    testId={`${testId}-attestation-form`}
                    disabled={!isEditing}
                />
            </div>
            {!isNull(event.content.attestationFormId) && !!event.content.instancesToCreate.length &&
                <CreateAttestationInstancesTable
                    testId={testId}
                    addAttestationInstance={addAttestationInstance}
                    updateUserAnswerId={updateUserAnswerId}
                    removeAttestationInstance={removeAttestationInstance}
                    instancesToCreate={event.content.instancesToCreate}
                    isEditing={isEditing}
                    deadlineCell={deadlineCell}
                />
            }
        </div>
    );
};

