import { isNull } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Spinner } from '../../../shared/spinner/Spinner';
import { NettingEngineResults } from '../NettingEngineResults';
import { getCalculatingExistingDocumentsResults, getExistingDocumentsResults, getIsOpening, getSavedNettingEngineDocument, openNettingEngineDocumentStarted, resetExistingDocumentsState } from '../store';
import { NettingEngineDocumentSummary } from './NettingEngineDocumentSummary';

interface NettingEngineRouteParams {
    documentId?: string;
}

export const NettingEngineDocument: React.FC<RouteComponentProps<NettingEngineRouteParams>> = ({ match: { params } }) => {
    const dispatch = useAppDispatch();
    const isOpening = useAppSelector(getIsOpening);
    const calculatingExistingDocumentsResults = useAppSelector(getCalculatingExistingDocumentsResults);
    const savedNettingEngineDocument = useAppSelector(getSavedNettingEngineDocument);
    const existingDocumentsResults = useAppSelector(getExistingDocumentsResults);

    useEffect(() => {
        if (isNull(savedNettingEngineDocument) && params.documentId) {
            const documentId = parseInt(params.documentId, 10);
            dispatch(openNettingEngineDocumentStarted(documentId, false));
        }
    }, [dispatch, savedNettingEngineDocument, params]);

    useEffect(() => () => {
        dispatch(resetExistingDocumentsState());
    }, [dispatch]);

    const component = useMemo(() => {
        if (isOpening || calculatingExistingDocumentsResults) {
            return <Spinner />;
        }
        if (!isNull(existingDocumentsResults)) {
            return <NettingEngineResults results={existingDocumentsResults} existingDocumentsNettingEngine />;
        }
        return <NettingEngineDocumentSummary />;
    }, [isOpening, calculatingExistingDocumentsResults, existingDocumentsResults]);

    return component;
};
