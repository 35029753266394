import React, { useCallback, useMemo, useState } from 'react';
import { isNull, isUndefined } from 'lodash/fp';

import { useAppSelector } from '../../../hooks/react-redux';
import { Icon } from '../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../shared/icons';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Toggle } from '../../shared/toggle';
import { InformationTooltip } from '../../shared/tooltip';
import { getAvailableFeaturePermissions } from './store';
import styles from './Users.module.scss';
import { getUserRole } from '../../auth/login/store';
import { systemAdminRole } from '../../constants/permittedRoles';

const { white, grey } = styles;

interface FeaturePermissionsProps {
    featurePermissionIds: number[] | null;
    isAdmin: boolean;
    isSystemAdmin: boolean;
    updateFeaturePermissions: (permissions: number[]) => void;
}

export const FeaturePermissions: React.FC<FeaturePermissionsProps> = ({ featurePermissionIds, isAdmin, isSystemAdmin, updateFeaturePermissions }) => {
    const [sectionOpen, setSectionOpen] = useState<boolean>(true);
    const availableFeaturePermissions = useAppSelector(getAvailableFeaturePermissions);
    const userRole = useAppSelector(getUserRole);

    const isSystemAdminUser = systemAdminRole.includes(userRole!);

    const permissionOptions = useMemo(() => availableFeaturePermissions.filter(({ label, systemAdminOnly }) => label && (!systemAdminOnly || systemAdminOnly && isSystemAdminUser)).sort((a, b) => a.label.localeCompare(b.label)), [availableFeaturePermissions, isSystemAdminUser]);

    const updateFeaturePermissionIds = (checked: boolean, featurePermissionId: number) => {
        const featurePermissions = checked ? [...featurePermissionIds!, featurePermissionId] : featurePermissionIds!.filter(id => id !== featurePermissionId);
        updateFeaturePermissions(featurePermissions);
    };

    const permissionOptionHeaders = [{ name: 'Feature Permission' }, { name: 'Has Permission' }];

    const sectionOpenIcon = useMemo(() => sectionOpen ? CaretDown : CaretSide, [sectionOpen]);

    const getIsAdminChecked = useCallback((id: number) => {
        const featurePermissionDB = availableFeaturePermissions.find(({ featurePermissionId }) => featurePermissionId === id);
        const isAdminPermission = !isUndefined(featurePermissionDB) && featurePermissionDB.adminInclusive === 1;
        return (isAdmin && isAdminPermission) || isSystemAdmin;
    }, [availableFeaturePermissions, isAdmin, isSystemAdmin]);

    const getIsChecked = useCallback((featurePermissionId: number) => {
        const isAdminChecked = getIsAdminChecked(featurePermissionId);
        if (isNull(featurePermissionIds)) {
            return isAdminChecked || isSystemAdmin;
        }
        return featurePermissionIds.includes(featurePermissionId) || isAdminChecked || isSystemAdmin;
    }, [featurePermissionIds, getIsAdminChecked, isSystemAdmin]);

    const totalPermissions = useMemo(() => {
        const permissionsApplied = permissionOptions.filter(({ featurePermissionId }) => getIsChecked(featurePermissionId)).length;
        const availablePermissions = permissionOptions.length;
        return `(${permissionsApplied} / ${availablePermissions})`;
    }, [permissionOptions, getIsChecked]);

    return (
        <div className={styles.featurePermissionOptionsWrapper}>
            <div className={styles.featurePermissionHeader} onClick={() => setSectionOpen(!sectionOpen)}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={sectionOpenIcon} fontSize={15} />
                </div>
                <div className={styles.featurePermissionOptionsTitle}>Feature Permissions {totalPermissions}</div>
            </div>
            {sectionOpen &&
                <>
                    <div className={styles.featurePermissionOptionsHeader}>
                        {permissionOptionHeaders.map(({ name }) => (
                            <div key={name}>{name}</div>
                        ))}
                    </div>
                    <div className={styles.featurePermissionOptionsTable}>
                        <Scrollable maxHeight='250px'>
                            {permissionOptions.map(({ featurePermissionId, label, description }, index) => (
                                <div className={styles.featurePermissionOptionRow} key={index} style={{ backgroundColor: index % 2 ? grey : white }}>
                                    <div className={styles.featurePermissionInfo}>
                                        <div className={styles.featurePermissionLabel}>{label}</div>
                                        <div className={styles.featurePermissionDescription}><InformationTooltip content={description} trigger='click' /></div>
                                    </div>
                                    <Toggle
                                        checked={getIsChecked(featurePermissionId)}
                                        onChange={checked => updateFeaturePermissionIds(checked, featurePermissionId)}
                                        disabled={getIsAdminChecked(featurePermissionId)}
                                    />
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </>
            }
        </div>
    );
};
