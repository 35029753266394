import { flattenDeep, getOr, isNull, isUndefined } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { AgencyDataset, AgencyDatasetField, AgencyDefinition, AgencyLinkedSingleField, DatasetDefinition, DatasetDefinitionDB, DatasetField, DatasetSection, FormDatasetDefinition, TableDatasetDefinition, isFormDatasetDefinition } from '../../../datasets/store';
import { HiddenField, HiddenSection } from '../../my-datasets/store';
import { DatasetBuilderState, DatasetsOpenFieldSection, DatasetView, DocumentDatasetModal, DocumentNameDatasets, DocumentSpecificHiddenFields, DocumentSpecificSectionIds, LinkDirection, SectionOpen, SelectedDataset, SettingsModal } from './types';
import { validateAllFields, validateFieldLabels, validateSectionFieldLabels, validateSectionFields } from './utils';

const getRoot = (state: ApplicationState): DatasetBuilderState => state.admin.dataset;

export const getModalOpen = (state: ApplicationState): boolean => getRoot(state).modalOpen;

export const getDatasetDefinition = (state: ApplicationState): DatasetDefinition | null => getRoot(state).datasetDefinition;

export const getPreviousDatasetIds = (state: ApplicationState): number[] => getRoot(state).previousDatasetIds;

export const getAllDatasetDefinitions = (state: ApplicationState): DatasetDefinitionDB[] => getRoot(state).publishedDatasetDefinitions;

export const getDefinitionsForDataset = (state: ApplicationState): DatasetDefinition[] => getRoot(state).datasetDefinitions;

export const getPreviousDatasetDefinitions = (state: ApplicationState): { datasetTitle: string; datasetId: number; }[] => {
    const previousDatasetIds = getPreviousDatasetIds(state);
    const allDatasetDefinitions = getAllDatasetDefinitions(state);
    return previousDatasetIds.map(id => {
        const { datasetId, datasetTitle } = allDatasetDefinitions.find(({ datasetId }) => datasetId === id)!;
        return { datasetId, datasetTitle };
    });
};

export const getSectionModalOpen = (state: ApplicationState): SectionOpen | null => getRoot(state).sectionModalOpen;

export const getSelectedSection = (state: ApplicationState): DatasetSection | null => getRoot(state).selectedSection;

export const getSections = (state: ApplicationState): DatasetSection[] | null => getRoot(state).datasetDefinition ? (getRoot(state).datasetDefinition as FormDatasetDefinition)!.datasetSections : null;

export const getDatasetPreviewOpen = (state: ApplicationState): boolean => getRoot(state).previewOpen;

export const getDefinitionHistoryModalOpen = (state: ApplicationState): boolean => getRoot(state).definitionHistoryModalOpen;

export const getFormDatasetFields = (state: ApplicationState): DatasetField[] => {
    const selectedSection = getSelectedSection(state);
    const datasetDefinition = getDatasetDefinition(state);
    if (selectedSection && datasetDefinition) {
        return (getDatasetDefinition(state) as FormDatasetDefinition).datasetFields[selectedSection.id] || [];
    } else {
        return [];
    }
};

export const getTableDatasetFields = (state: ApplicationState): DatasetField[] => {
    const datasetDefinition = getDatasetDefinition(state);
    if (datasetDefinition) {
        return (getRoot(state).datasetDefinition as TableDatasetDefinition)!.datasetFields;
    } else {
        return [];
    }
};

export const getDefinitionHasUnlabelledFields = (state: ApplicationState): boolean => {
    const datasetDefinition = getDatasetDefinition(state);
    if (!isNull(datasetDefinition)) {
        return !validateFieldLabels(datasetDefinition);
    }
    return false;
};

export const getDefinitionHasIncompleteFields = (state: ApplicationState): boolean => {
    const datasetDefinition = getDatasetDefinition(state);
    if (!isNull(datasetDefinition)) {
        return !validateAllFields(datasetDefinition);
    }
    return false;
};

export const getSectionHasIncompleteFields = (sectionId: string) => (state: ApplicationState): boolean => {
    const datasetDefinition = getDatasetDefinition(state);
    if (!isNull(datasetDefinition) && isFormDatasetDefinition(datasetDefinition)) {
        return !validateSectionFields(datasetDefinition, sectionId) || !validateSectionFieldLabels(datasetDefinition, sectionId);
    }
    return false;
};

export const getSelectedGroup = (state: ApplicationState): string | null => getRoot(state).selectedGroup;

export const getPublishModalOpen = (state: ApplicationState): boolean => getRoot(state).publishModalOpen;

export const getPublishReason = (state: ApplicationState): string => getRoot(state).datasetDefinition?.publishReason || '';

export const getPublishError = (state: ApplicationState): string | null => getRoot(state).publishError;

export const getHelpModalOpen = (state: ApplicationState): boolean => getRoot(state).helpModalOpen;

export const getSettingsLinkModalOpen = (state: ApplicationState): SettingsModal => getRoot(state).settingsLinkModalOpen;

export const getDatasetView = (state: ApplicationState): DatasetView => getRoot(state).datasetView;

export const getConfirmDeleteDataset = (state: ApplicationState): DatasetDefinitionDB | DatasetDefinition | null => getRoot(state).confirmDeleteDataset;

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;

export const getDefinitionClientIds = (state: ApplicationState): number[] => getRoot(state).definitionClientIds;

export const getNewDatasetFields = (state: ApplicationState): (HiddenField | HiddenSection)[] => getRoot(state).newFields;

export const getReorderSectionsModalOpen = (state: ApplicationState): boolean => getRoot(state).reorderSectionsModalOpen;

export const getCalculatorHelpModalOpen = (state: ApplicationState): boolean => getRoot(state).calculatorHelpModalOpen;

export const getRiskToleranceHelpModalOpen = (state: ApplicationState): boolean => getRoot(state).riskToleranceHelpModalOpen;

export const getUnsavedChangesModalStatus = (state: ApplicationState): LinkDirection | null => getRoot(state).unsavedChangesModalOpen;

export const getLinkUpUnsavedChangesModalOpen = (state: ApplicationState): boolean => getUnsavedChangesModalStatus(state) === LinkDirection.UP;
export const getLinkDownUnsavedChangesModalOpen = (state: ApplicationState): boolean => getUnsavedChangesModalStatus(state) === LinkDirection.DOWN;

export const getDefinitionHasUpdated = (state: ApplicationState): boolean => getRoot(state).datasetDefinitionUpdated;

export const getAllDatasets = (state: ApplicationState): DocumentNameDatasets[] => getRoot(state).documentNameDatasets;

export const isFetchingDatasets = (state: ApplicationState): boolean => getRoot(state).fetchingDocumentNameDatasets;

export const getDatasetBuilderPathname = (state: ApplicationState): DatasetView => {
    const url = state.router.location?.pathname || '';
    const showAgencyDatasets = getDatasetView(state) === DatasetView.TABLE_AGENCY;
    if (url && url.includes('/configure')) {
        return DatasetView.DOCUMENTS_CONFIGURE;
    }
    if (url && url.includes('/preview')) {
        return DatasetView.DOCUMENTS_PREVIEW;
    }
    if (url && url.includes('/documents')) {
        return DatasetView.DOCUMENTS_LIST;
    }
    return showAgencyDatasets ? DatasetView.TABLE_AGENCY : DatasetView.TABLE_STANDARD;
};

export const getCurrentHiddenDocumentFields = (state: ApplicationState): DocumentSpecificHiddenFields => getRoot(state).currentHiddenDocumentFields;

export const getSavedHiddenDocumentFields = (state: ApplicationState): DocumentSpecificHiddenFields => getRoot(state).savedHiddenDocumentFields;

export const getOpenFieldsAndSections = (state: ApplicationState): DatasetsOpenFieldSection[] => getRoot(state).openFieldsAndSections;

export const getDocumentDatasetModal = (state: ApplicationState): DocumentDatasetModal | null => getRoot(state).documentDatasetModal;

export const getUnsavedHiddenDocumentChangesModalOpen = (state: ApplicationState): boolean => getRoot(state).unsavedHiddenDocumentChangesModalOpen;

export const getPreviewDatasetSelectedDocument = (state: ApplicationState): number | null => getRoot(state).previewDatasetSelectedDocument;

export const getIsSavingHiddenFields = (state: ApplicationState): boolean => getRoot(state).isSavingDocumentDatasetHiddenFields;

export const getCurrentDocumentSpecificSectionIds = (state: ApplicationState): DocumentSpecificSectionIds => getRoot(state).currentDatasetSectionReferences;

export const getSavedDocumentSpecificSectionIds = (state: ApplicationState): DocumentSpecificSectionIds => getRoot(state).savedDatasetSectionReferences;

export const getCurrentDocumentSpecificSectionReferences = (datasetId: number, documentNameId: number, sectionLabel: string) => (state: ApplicationState): string[] => {
    const currentReferences = getRoot(state).currentDatasetSectionReferences[datasetId];
    if (!isUndefined(currentReferences)) {
        return currentReferences[documentNameId].find(({ sectionId }) => sectionId === sectionLabel)?.references || [];
    }
    return [];
};

export const getSectionHasSpecificReferences = (datasetId: number, documentNameIds: number[], sectionLabel: string) => (state: ApplicationState): boolean => {
    let documentReferences: boolean[] = [];
    documentNameIds.map(documentNameId => {
        const datasetReferences = getRoot(state).currentDatasetSectionReferences[datasetId];
        let hasRef = false;
        if (!isUndefined(datasetReferences)) {
            hasRef = !!datasetReferences[documentNameId].find(({ sectionId }) => sectionId === sectionLabel)?.references || false;
        }
        documentReferences.push(hasRef);
    });
    return documentReferences.some(ref => !!ref);
};

export const getDatasetDocumentConfigUpdated = (state: ApplicationState): boolean => getRoot(state).datasetDocumentConfigUpdated;

export const getConfiguredDatasetParentId = (state: ApplicationState): number | null => getRoot(state).configureDatasetParentId;

// Agency Builder selectors

export const getAgencyModalOpen = (state: ApplicationState): boolean => getRoot(state).agencyModalOpen;

export const getAgencyDefinition = (state: ApplicationState): AgencyDefinition | null => getRoot(state).agencyDefinition;

export const getAllAgencyDefinitions = (state: ApplicationState): AgencyDataset[] => getRoot(state).agencyDefinitions;

export const getAgencyDatasetFields = (state: ApplicationState): AgencyDatasetField[] => getOr([], 'datasetFields', getAgencyDefinition(state));

export const getAgencySettingsModalOpen = (state: ApplicationState): number | null => getRoot(state).agencySettingsModalOpen;

export const getAvailableAgencyFields = (state: ApplicationState): AgencyLinkedSingleField[] => {
    const agencyDefinition = getAgencyDefinition(state);
    const allAgencyFields = getRoot(state).availableAgencyFields;
    if (!agencyDefinition) {
        return allAgencyFields;
    }
    const linkedAgencyFields = flattenDeep(agencyDefinition.datasetFields.map(({ linkedFields }) => linkedFields));
    return allAgencyFields.filter(({ id }) => !linkedAgencyFields.map(linked => linked.id).includes(id));
};

export const getPublishConfirmationModalOpen = (state: ApplicationState): boolean => getRoot(state).publishConfirmationModalOpen;

export const getSelectedDatasetId = (state: ApplicationState): SelectedDataset | null => getRoot(state).selectedDataset;
