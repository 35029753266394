import React, { useCallback, useMemo } from 'react';
import { EventProps } from 'react-big-calendar';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DATABASE_DATE_FORMAT, formatDate, isSameOrBefore } from '../../../../utils/luxon';
import { Icon } from '../../../shared/icon/Icon';
import { Attestation, Mail, Repeat } from '../../../shared/icons';
import { CustomTooltip } from '../../../shared/tooltip';
import { getAllAttestationForms } from '../../attestations/store';
import { CalendarEvent, SchedulerAction, toggleScheduleWizard, stripEvent, setSelectedEventDate } from '../store';
import styles from '../Workflow.module.scss';

const { ocean, amethyst } = styles;

export const WorkflowCalendarEvent: React.FC<EventProps> = ({ event }) => {
    const arkEvent = event as CalendarEvent;
    const dispatch = useAppDispatch();
    const attestationForms = useAppSelector(getAllAttestationForms);
    const getAttestationFormName = useCallback((id: number) => attestationForms.find(({ attestationFormId }) => attestationFormId === id)?.name || '', [attestationForms]);

    const eventHasPassed = !!event.start && isSameOrBefore(event.start, new Date());

    const openEvent = () => {
        dispatch(toggleScheduleWizard(true, stripEvent(arkEvent)));
        const dateToSkip = formatDate(arkEvent.start!, DATABASE_DATE_FORMAT);
        dispatch(setSelectedEventDate(dateToSkip));
    };

    const eventIsSkipped = !!arkEvent.isSkipped;

    const repeatTooltip = useMemo(() => {
        if (eventIsSkipped) {
            return 'This event has been skipped';
        }
        return `Repeat: ${arkEvent.repeat ? `Every ${arkEvent.intervalValue} ${arkEvent.interval}` : 'Never'}`;
    }, [arkEvent, eventIsSkipped]);

    const eventInfo = useMemo(() => {
        let fullInfo = '';
        if (arkEvent.action === SchedulerAction.CREATE_ATTESTATION) {
            const attestationForm = getAttestationFormName(arkEvent.content.attestationFormId!);
            fullInfo = attestationForm;
        } else {
            fullInfo = arkEvent.content.subject;
        }
        return `${fullInfo.substring(0, 25)}${fullInfo.length > 25 ? '...' : ''}`;
    }, [arkEvent, getAttestationFormName]);

    const { icon, title, backgroundColor } = useMemo(() => {
        if (arkEvent.action === SchedulerAction.CREATE_ATTESTATION) {
            return { title: 'Attestation', icon: Attestation, backgroundColor: ocean };
        }
        return { title: 'Email', icon: Mail, backgroundColor: amethyst };
    }, [arkEvent]);

    return (
        <button className={classnames(styles.eventWrapper, {
            [styles.eventHasPassed]: eventHasPassed,
            [styles.eventIsSkipped]: eventIsSkipped
        })} onClick={openEvent} style={{ backgroundColor }} data-testid={`admin-workflow-calendar-${title.toLocaleLowerCase()}-event-wrapper-${arkEvent.scheduledActionId}`}>
            <div className={styles.eventInfo}>
                <CustomTooltip overlayText={eventInfo}>
                    <div className={styles.iconWrapper}>
                        <Icon icon={icon} fontSize={20} />
                    </div>
                </CustomTooltip>
                <div className={styles.eventTitle}>{title}</div>
            </div>
            {!!arkEvent.repeat &&
                <CustomTooltip overlayText={repeatTooltip}>
                    <div className={styles.repeatWrapper}>
                        <Icon icon={Repeat} fontSize={16} />
                    </div>
                </CustomTooltip>
            }
        </button>
    );
};
