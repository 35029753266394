import React, { useCallback, useMemo } from 'react';
import { indexOf, max, min, set } from 'lodash/fp';
import Slider from 'rc-slider';

import styles from './DoraFunctions.module.scss';
import { DoraConfiguration, DoraConfigurationTimeline } from './store';
import { TooltipList } from '../../shared/tooltip';
import { formatDate, DATE_FORMAT } from '../../../utils/luxon';

const { french, white, grey } = styles;

interface MarkerProps {
    configTimeline: DoraConfigurationTimeline;
}

const Marker: React.FC<MarkerProps> = ({ configTimeline }) => {
    const overlay = [`Created By: ${configTimeline.createdBy}`, `Created Date: ${formatDate(configTimeline.effectiveFrom, DATE_FORMAT)}`];
    return (
        <TooltipList overlayText={overlay}>
            <div className={styles.sliderMarker} />
        </TooltipList>
    );
};

interface ConfigurationTimelineProps {
    timeline: DoraConfigurationTimeline[];
    doraFunctionConfig: DoraConfiguration;
    onChange: (value: number | number[]) => void;
}

export const ConfigurationTimeline: React.FC<ConfigurationTimelineProps> = ({ timeline, doraFunctionConfig, onChange }) => {

    const mapAllInstances = useCallback((timeline: DoraConfigurationTimeline[]) => timeline.reduce((acc, playbookHistory, index) => {
        const key = index + 1;
        return set(key, <Marker configTimeline={playbookHistory} />, acc);
    }, {}), []);

    const historicalInstances = useMemo(() => mapAllInstances(timeline), [timeline, mapAllInstances]);

    const minValue = min((Object.keys(historicalInstances).map(val => parseInt(val))));
    const maxValue = max((Object.keys(historicalInstances).map(val => parseInt(val))));
    const minimum = minValue && maxValue !== minValue ? minValue : 0;
    const selected = useMemo(() => timeline.find(({ doraConfigurationId }) => doraConfigurationId === doraFunctionConfig?.doraConfigurationId), [timeline, doraFunctionConfig]);

    const value = useMemo(() => indexOf(selected, timeline) + 1, [timeline, selected]);

    return (
        <div className={styles.configurationTimelineWrapper} style={{ display: timeline.length > 0 ? 'flex' : 'none' }}>
            {timeline.length ?
                <Slider
                    min={minimum}
                    marks={historicalInstances}
                    onChange={onChange}
                    defaultValue={maxValue}
                    value={value}
                    max={maxValue}
                    step={1}
                    trackStyle={{ backgroundColor: french }}
                    railStyle={{ backgroundColor: grey }}
                    handleStyle={{ backgroundColor: white, border: `1px solid ${french}`, height: '16px', width: '16px', marginTop: '-6px', opacity: 1 }}
                    dotStyle={{ backgroundColor: white, border: `1px solid ${french}` }}
                /> : <div style={{ height: '14px' }} />}
        </div>
    );
};
