import { flow, set, isUndefined, isNull } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { getNewEntityByType, initialAddress, initialBranch } from '../../../constants/entity';
import { EntityState, EntityActionTypes, CompanyEntity, PersonEntity, CompanyEntitySection, SubCounterpartyType, SearchEntityBy } from './types';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';
import { Migrations } from '../../../dora/my-companies/store';

export const initialSubCounterparty: SubCounterpartyType = {
    subCounterpartyName: '',
    jurisdiction: '',
    parentCounterpartyType: '',
    isSystem: 0
};

export const INITIAL_STATE: EntityState = {
    modalOpen: null,
    currentEntity: null,
    savedEntity: null,
    uploadError: null,
    searchingEntities: false,
    fetchingEntities: false,
    searchError: null,
    searchResults: [],
    paginatedEntities: [],
    allEntities: [],
    nettingEntities: [],
    entityPage: 1,
    totalEntities: 0,
    entityFilters: {},
    entityPageSize: DEFAULT_PAGE_SIZE,
    fetchError: null,
    saveError: null,
    entitySort: undefined,
    searchEntityPage: 1,
    searchEntityPageSize: DEFAULT_PAGE_SIZE,
    totalSearchEntities: 0,
    searchBy: SearchEntityBy.COMPANY_NAME,
    searchInput: '',
    myCompanyEntities: [],
    groupEntities: [],
    entityIdsForGroup: null,
    companyEntityGroupIds: [],
    doraFunctionMigration: null,
    selectedCompanySection: CompanyEntitySection.GENERAL,
    fuzzyMatchResults: [],
    isSaving: false,
    fuzzyMatchModalOpen: null,
    currentClassificationEntities: [],
    savedClassificationEntities: [],
    fetchingClassificationEntities: false,
    assertingEntityClassification: [],
    classificationFilter: '',
    showCompleted: false,
    entityClassificationHasUpdated: false,
    closeClassificationConfirmationModalOpen: false,
    fuzzyCompletions: [],
    autoCompletions: [],
    showCompletions: false,
    availableOpinionSubCounterpartyTypes: [],
    selectedSubCounterparty: initialSubCounterparty,
    jurisdiction: null,
    parentCounterparty: '',
    isFetchingSubCounterpartyTypes: false
};

export const entityReducer: Reducer<EntityState> = (state = INITIAL_STATE, action): EntityState => {
    switch (action.type) {
        case EntityActionTypes.TOGGLE_ENTITY_MODAL: {
            const { entity, value } = action.payload;
            const selectedEntity = entity || null;
            return flow(
                set('modalOpen', value),
                set('currentEntity', selectedEntity),
                set('savedEntity', selectedEntity),
                set('entityIdsForGroup', null),
                set('selectedCompanySection', CompanyEntitySection.GENERAL),
                set('classificationFilter', ''),
                set('showCompleted', false),
                set('entityClassificationHasUpdated', false)
            )(state);
        }
        case EntityActionTypes.UPDATE_ENTITY_VALUE: {
            const { key, value } = action.payload;
            return set(`currentEntity[${key}]`, value, state);
        }
        case EntityActionTypes.UPDATE_ENTITY_CONTENT: {
            const { key, value } = action.payload;
            return set(`currentEntity.content[${key}]`, value, state);
        }
        case EntityActionTypes.UPDATE_ENTITY_AI_FIELD: {
            const { key, secondaryKey, value } = action.payload;
            return set(`currentEntity.content[${key}][${secondaryKey}]`, value, state);
        }
        case EntityActionTypes.UPSERT_ENTITY_STARTED:
            return set('isSaving', true, state);
        case EntityActionTypes.UPSERT_ENTITY_SUCCESSFUL:
            return flow(
                set('modalOpen', false),
                set('paginatedEntities', action.payload.entities),
                set('totalEntities', action.payload.total),
                set('entityPage', 1),
                set('currentEntity', null),
                set('savedEntity', null),
                set('entityIdsForGroup', null),
                set('isSaving', false),
            )(state);
        case EntityActionTypes.UPSERT_ENTITY_FAILED:
            return flow(
                set('publishError', action.payload),
                set('isSaving', false)
            )(state);
        case EntityActionTypes.SEARCH_ENTITIES_STARTED:
            return flow(
                set('searchingEntities', true),
                set('fuzzyCompletions', []),
                set('autoCompletions', []),
                set('showCompletions', false)
            )(state);
        case EntityActionTypes.SEARCH_ENTITIES_SUCCESSFUL: {
            const { total, entities, pageNumber } = action.payload;
            return flow(
                set('searchingEntities', false),
                set('searchResults', entities),
                set('totalSearchEntities', total || state.totalSearchEntities),
                set('searchEntityPage', pageNumber || state.searchEntityPage)
            )(state);
        }
        case EntityActionTypes.SEARCH_ENTITIES_FAILED:
            return flow(
                set('searchingEntities', false),
                set('searchError', action.payload)
            )(state);
        case EntityActionTypes.FETCH_PAGINATED_ENTITIES_STARTED:
            return set('fetchingEntities', true, state);
        case EntityActionTypes.FETCH_PAGINATED_ENTITIES_SUCCESSFUL:
            return flow(
                set('paginatedEntities', action.payload.entities),
                set('totalEntities', action.payload.total || state.totalEntities),
                set('entityPage', action.payload.pageNumber || state.entityPage),
                set('fetchingEntities', false)
            )(state);
        case EntityActionTypes.FETCH_PAGINATED_ENTITIES_FAILED:
            return flow(
                set('fetchError', action.payload),
                set('fetchingEntities', false)
            )(state);
        case EntityActionTypes.FETCH_ALL_ENTITIES_STARTED:
            return set('fetchingEntities', true, state);
        case EntityActionTypes.FETCH_ALL_ENTITIES_SUCCESSFUL:
            return flow(
                set('allEntities', action.payload),
                set('fetchingEntities', false)
            )(state);
        case EntityActionTypes.FETCH_ALL_ENTITIES_FAILED:
            return flow(
                set('fetchError', action.payload),
                set('fetchingEntities', false)
            )(state);
        case EntityActionTypes.FETCH_ALL_NETTING_ENTITIES_STARTED:
            return set('fetchingEntities', true, state);
        case EntityActionTypes.FETCH_ALL_NETTING_ENTITIES_SUCCESSFUL:
            return flow(
                set('nettingEntities', action.payload),
                set('fetchingEntities', false)
            )(state);
        case EntityActionTypes.FETCH_ALL_NETTING_ENTITIES_FAILED:
            return flow(
                set('fetchError', action.payload),
                set('fetchingEntities', false)
            )(state);
        case EntityActionTypes.UPDATE_ADDRESS_ENTITY_VALUE: {
            const { key, value, index } = action.payload;
            if (!isUndefined(index)) {
                return set(`currentEntity.content.otherAddresses[${index}].[${key}]`, value, state);
            }
            return set(`currentEntity.content.address[${key}]`, value, state);
        }
        case EntityActionTypes.ADD_ALTERNATIVE_ADDRESS: {
            const entityOtherAddresses = (state.currentEntity! as CompanyEntity | PersonEntity).content.otherAddresses!;
            return set('currentEntity.content.otherAddresses', entityOtherAddresses.concat(initialAddress), state);
        }
        case EntityActionTypes.REMOVE_ALTERNATIVE_ADDRESS: {
            const entityOtherAddresses = (state.currentEntity! as CompanyEntity | PersonEntity).content.otherAddresses!;
            const updatedEmailAddresses = entityOtherAddresses.filter((_, index) => index !== action.payload);
            return set('currentEntity.content.otherAddresses', updatedEmailAddresses, state);
        }
        case EntityActionTypes.ADD_COMPANY_BIC_CODE: {
            const bicCodes = (state.currentEntity! as CompanyEntity).content.bic!;
            return set('currentEntity.content.bic', [...bicCodes, ''], state);
        }
        case EntityActionTypes.REMOVE_COMPANY_BIC_CODE: {
            const bicCodes = (state.currentEntity! as CompanyEntity).content.bic!;
            return set('currentEntity.content.bic', bicCodes.filter((_, index) => index !== action.payload), state);
        }
        case EntityActionTypes.ADD_LEI_ENTITY_STARTED:
            return set('isSaving', true, state);
        case EntityActionTypes.ADD_LEI_ENTITY_SUCCESSFUL:
            return flow(
                set('paginatedEntities', action.payload.entities),
                set('totalEntities', action.payload.total),
                set('entityPage', 1),
                set('isSaving', false)
            )(state);
        case EntityActionTypes.ADD_LEI_ENTITY_FAILED:
            return flow(
                set('publishError', action.payload),
                set('isSaving', false)
            )(state);
        case EntityActionTypes.SELECT_ENTITY_TYPE: {
            const newEntity = getNewEntityByType(action.payload);
            return flow(
                set('currentEntity', newEntity),
                set('savedEntity', newEntity)
            )(state);
        }
        case EntityActionTypes.SET_ENTITY_TABLE_FILTERS: {
            const isTextFilter = action.payload.type === 'text';
            return flow(
                set(`entityFilters[${action.payload.key}][${action.payload.type}]`, action.payload.value),
                set(`entityFilters[${action.payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case EntityActionTypes.CLEAR_ENTITY_TABLE_FILTERS:
            return set('entityFilters', {}, state);
        case EntityActionTypes.SET_ENTITY_TABLE_COLUMN_SORT:
            return set('entitySort', action.payload, state);
        case EntityActionTypes.SET_ENTITIES_PAGE_SIZE:
            return set('entityPageSize', action.payload, state);
        case EntityActionTypes.SET_ENTITIES_SEARCH_PAGE_SIZE:
            return set('searchEntityPageSize', action.payload, state);
        case EntityActionTypes.SET_SEARCH_ENTITY_TYPE:
            return flow(
                set('searchBy', action.payload),
                set('searchInput', ''),
                set('searchEntityPage', 1),
                set('totalSearchEntities', 0),
                set('searchResults', [])
            )(state);
        case EntityActionTypes.SET_SEARCH_ENTITY_VALUE:
            return flow(
                set('searchInput', action.payload),
                set('showCompletions', true)
            )(state);
        case EntityActionTypes.RESET_ENTITY_SEARCH:
            return flow(
                set('searchInput', ''),
                set('searchBy', SearchEntityBy.COMPANY_NAME),
                set('searchEntityPage', 1),
                set('searchEntityPageSize', DEFAULT_PAGE_SIZE),
                set('totalSearchEntities', 0),
                set('searchResults', []),
                set('fuzzyCompletions', []),
                set('autoCompletions', []),
                set('showCompletions', false)
            )(state);
        case EntityActionTypes.FETCH_MY_COMPANY_ENTITIES_SUCCESSFUL:
            return set('myCompanyEntities', action.payload, state);
        case EntityActionTypes.FETCH_MY_GROUP_ENTITIES_SUCCESSFUL: {
            const { groupEntities, companyEntityGroupIds } = action.payload;
            return flow(
                set('groupEntities', groupEntities),
                set('companyEntityGroupIds', companyEntityGroupIds)
            )(state);
        }
        case EntityActionTypes.UPDATE_GROUP_ENTITY_IDS:
            return set('entityIdsForGroup', action.payload, state);
        case EntityActionTypes.SET_COMPANY_ENTITY_GROUP_IDS:
            return set('companyEntityGroupIds', action.payload, state);
        case EntityActionTypes.TOGGLE_MIGRATE_DORA_FUNCTION_CONFIRMATION_MODAL:
            return set('doraFunctionMigration', null, state);
        case EntityActionTypes.FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_SUCCESSFUL:
            return set('doraFunctionMigration', action.payload, state);
        case EntityActionTypes.UPDATE_DORA_COMPANY_FUNCTION_MIGRATIONS: {
            const { functionId, value } = action.payload;
            if (!isNull(state.doraFunctionMigration)) {
                const migrations = state.doraFunctionMigration.migrations.reduce((acc, cur) => {
                    if (cur.functionId === functionId) {
                        cur.newCompanyId = value;
                    }
                    acc.push(cur);
                    return acc;
                }, [] as Migrations[]);
                return set('doraFunctionMigration.migrations', migrations, state);
            }
            return set('doraFunctionMigration', null, state);
        }
        case EntityActionTypes.SELECT_COMPANY_ENTITY_SECTION:
            return set('selectedCompanySection', action.payload, state);
        case EntityActionTypes.UPDATE_BRANCHES_FUZZY_MATCH_RESULTS: {
            const { tags, index } = action.payload;
            return flow(
                set('fuzzyMatchResults', tags),
                set('fuzzyMatchModalOpen', tags.length > 0 ? index : null),
            )(state);
        }
        case EntityActionTypes.SET_BRANCHES_FUZZY_MATCH_MODAL_OPEN:
            return set('fuzzyMatchModalOpen', action.payload, state);
        case EntityActionTypes.ADD_COMPANY_ENTITY_BRANCH: {
            const entityBranches = (state.currentEntity! as CompanyEntity).content.branches!;
            const updatedBranches = entityBranches.concat(initialBranch);
            return set('currentEntity.content.branches', updatedBranches, state);
        }
        case EntityActionTypes.UPDATE_ENTITY_BRANCH_INFORMATION: {
            const { key, value, index } = action.payload;
            return set(`currentEntity.content.branches[${index}].[${key}]`, value, state);
        }
        case EntityActionTypes.DELETE_COMPANY_ENTITY_BRANCH: {
            const entityBranches = (state.currentEntity! as CompanyEntity).content.branches!;
            const updatedBranches = entityBranches.filter((branch, index) => index !== action.payload);
            return set('currentEntity.content.branches', updatedBranches, state);
        }
        case EntityActionTypes.FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_STARTED:
            return set('fetchingClassificationEntities', true, state);
        case EntityActionTypes.FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_SUCCESSFUL:
            return flow(
                set('currentClassificationEntities', action.payload),
                set('savedClassificationEntities', action.payload),
                set('fetchingClassificationEntities', false)
            )(state);
        case EntityActionTypes.FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_FAILED:
            return set('fetchingClassificationEntities', false, state);
        case EntityActionTypes.ASSERT_ENTITY_CLASSIFICATION_STARTED: {
            const assertingEntityClassification = [...state.assertingEntityClassification, action.payload.entityId];
            return set('assertingEntityClassification', assertingEntityClassification, state);
        }
        case EntityActionTypes.ASSERT_ENTITY_CLASSIFICATION_SUCCESSFUL: {
            const assertingEntityClassification = state.assertingEntityClassification.filter(entityId => action.payload.entityId !== entityId);
            const savedClassificationEntities = state.savedClassificationEntities.map(entity => entity.entityId === action.payload.entityId ? action.payload : entity);
            const currentClassificationEntities = state.currentClassificationEntities.map(entity => entity.entityId === action.payload.entityId ? action.payload : entity);
            return flow(
                set('currentClassificationEntities', currentClassificationEntities),
                set('savedClassificationEntities', savedClassificationEntities),
                set('assertingEntityClassification', assertingEntityClassification)
            )(state);
        }
        case EntityActionTypes.ASSERT_ENTITY_CLASSIFICATION_FAILED: {
            const assertingEntityClassification = state.assertingEntityClassification.filter(entityId => action.payload.entityId !== entityId);
            return set('assertingEntityClassification', assertingEntityClassification, state);
        }
        case EntityActionTypes.SET_ENTITY_CLASSIFICATION_FILTER:
            return set('classificationFilter', action.payload, state);
        case EntityActionTypes.TOGGLE_ENTITY_CLASSIFICATION_COMPLETED:
            return set('showCompleted', action.payload, state);
        case EntityActionTypes.UPDATE_ENTITY_CLASSIFICATION_FIELD: {
            const { entityId, key, secondaryKey, value } = action.payload;
            const currentClassificationEntities = state.currentClassificationEntities.map(entity => entity.entityId === entityId ? set(`${key}.${secondaryKey}`, value, entity) : entity);
            return set('currentClassificationEntities', currentClassificationEntities, state);
        }
        case EntityActionTypes.SET_ENTITY_CLASSIFICATION_HAS_UPDATED:
            return set('entityClassificationHasUpdated', action.payload, state);
        case EntityActionTypes.TOGGLE_ENTITY_CLASSIFICATION_CLOSE_CONFIRMATION_MODAL_OPEN:
            return set('closeClassificationConfirmationModalOpen', action.payload, state);
        case EntityActionTypes.UPDATE_CLASSIFICATION_ENTITIES_STARTED:
            return set('isSaving', true, state);
        case EntityActionTypes.UPDATE_CLASSIFICATION_ENTITIES_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('entityClassificationHasUpdated', false),
                set('savedClassificationEntities', state.currentClassificationEntities)
            )(state);
        case EntityActionTypes.UPDATE_CLASSIFICATION_ENTITIES_FAILED:
            return set('isSaving', false, state);
        case EntityActionTypes.CLASSIFY_SOFT_SEARCH_ENTITY_STARTED:
            return set('isSaving', true, state);
        case EntityActionTypes.CLASSIFY_SOFT_SEARCH_ENTITY_SUCCESSFUL:
            return set('isSaving', false, state);
        case EntityActionTypes.CLASSIFY_SOFT_SEARCH_ENTITY_FAILED:
            return flow(
                set('fetchError', action.payload),
                set('isSaving', false)
            )(state);
        case EntityActionTypes.FETCH_OPINION_SUB_COUNTERPARTY_TYPES_STARTED:
            return set('isFetchingSubCounterpartyTypes', true, state);
        case EntityActionTypes.FETCH_FUZZY_AND_AUTOCOMPLETIONS_SUCCESSFUL:
            return flow(
                set('fuzzyCompletions', action.payload.fuzzyCompletions),
                set('autoCompletions', action.payload.autoCompletions)
            )(state);
        case EntityActionTypes.FETCH_OPINION_SUB_COUNTERPARTY_TYPES_SUCCESSFUL:
            return flow(
                set('availableOpinionSubCounterpartyTypes', action.payload),
                set('isFetchingSubCounterpartyTypes', false)
            )(state);
        case EntityActionTypes.FETCH_OPINION_SUB_COUNTERPARTY_TYPES_FAILED:
            return flow(
                set('fetchError', action.payload),
                set('isFetchingSubCounterpartyTypes', false)
            )(state);
        case EntityActionTypes.SET_SELECTED_OPINION_SUB_COUNTERPARTY:
            return set('selectedSubCounterparty', action.payload, state);
        case EntityActionTypes.UPDATE_OPINION_SUB_COUNTERPARTY_VALUE: {
            const { payload: { key, value } } = action;
            return set(`selectedSubCounterparty[${key}]`, value, state);
        }
        case EntityActionTypes.UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_STARTED:
            return set('isSaving', true, state);
        case EntityActionTypes.UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_SUCCESSFUL:
            return flow(
                set('selectedSubCounterparty', { ...initialSubCounterparty, isSystem: action.payload }),
                set('isSaving', false)
            )(state);
        case EntityActionTypes.UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_FAILED:
            return flow(
                set('saveError', action.payload),
                set('isSaving', false)
            )(state);
        case EntityActionTypes.SET_SUB_COUNTERPARTY_JURISDICTION:
            return set('jurisdiction', action.payload, state);
        case EntityActionTypes.SET_SUB_COUNTERPARTY_PARENT:
            return set('parentCounterparty', action.payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
