import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../../hooks/react-redux';
import styles from '../../Analytics.module.scss';
import { DocumentAnalyticsChart, getAllDocumentsRiskTolerance, getEntityDocumentsRiskTolerance, getPreExecutionDocumentsRiskTolerance, getSelectedMyCompanyEntity } from '../../store';
import { MinifiedRiskToleranceChart } from './MinifiedRiskToleranceChart';
import { OverflowTooltip } from '../../../../shared/tooltip';

interface AllRiskToleranceChartsProps {
    tileInView: null | DocumentAnalyticsChart;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    dimensions: {
        height: number;
        width: number;
    };
}

export const AllRiskToleranceCharts: React.FC<AllRiskToleranceChartsProps> = ({
    setTileInView,
    tileInView,
    dimensions
}) => {
    const selectedCompanyEntityId = useAppSelector(getSelectedMyCompanyEntity);
    const entityId = useMemo(() => !isNull(selectedCompanyEntityId) ? selectedCompanyEntityId : undefined, [selectedCompanyEntityId]);
    const allDocumentsRiskTolerance = useAppSelector(getAllDocumentsRiskTolerance);
    const entityDocumentsRiskTolerance = useAppSelector(getEntityDocumentsRiskTolerance);
    const preExecutionDocumentsRiskTolerance = useAppSelector(getPreExecutionDocumentsRiskTolerance);

    const chartDimensions = useMemo(() => ({ height: (dimensions.height - 30) / 3, width: dimensions.width }), [dimensions]);
    return (
        <div className={styles.allRiskToleranceChartsWrapper} style={dimensions}>
            <div className={styles.allRiskToleranceChartsHeader}><OverflowTooltip className={styles.documentRiskToleranceChartTitle} overlayText='Risk Tolerance' /></div>
            <div className={styles.allRiskToleranceCharts}>
                <MinifiedRiskToleranceChart tile={DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE} tileInView={tileInView} setTileInView={setTileInView} allDocumentsRiskTolerance={allDocumentsRiskTolerance} dimensions={chartDimensions} marginBottom='20px' />
                <MinifiedRiskToleranceChart tile={DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE} tileInView={tileInView} setTileInView={setTileInView} allDocumentsRiskTolerance={entityDocumentsRiskTolerance} dimensions={chartDimensions} entityId={entityId} marginBottom='20px' />
                <MinifiedRiskToleranceChart tile={DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE} tileInView={tileInView} setTileInView={setTileInView} allDocumentsRiskTolerance={preExecutionDocumentsRiskTolerance} dimensions={chartDimensions} isPreExecution />
            </div>
        </div>
    );
};
