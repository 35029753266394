import { push } from 'redux-first-history';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { isNull } from 'lodash/fp';

import { setSelectedTab } from './actions';
import { DataManagementTile, DoraDataManagementActionTypes } from './types';
import { DoraMyCompaniesView, toggleDoraMyCompaniesView } from '../../my-companies/store';
import { changeThirdPartyView, DoraThirdPartyView } from '../../third-party-companies/store';

export const doraPathnames = {
    [DataManagementTile.MY_COMPANIES]: 'companies',
    [DataManagementTile.FUNCTIONS_PERFORMED]: 'functions',
    [DataManagementTile.SUPPLY_CHAIN]: 'supply-chain',
    [DataManagementTile.RELEVANT_THIRD_PARTY_COMPANIES]: 'third-party-companies'
};

export function* redirectSelectedTab({ payload }: ReturnType<typeof setSelectedTab>) {
    const { reroute, selectedTab } = payload;
    if (!isNull(selectedTab) && reroute) {
        if (selectedTab === DataManagementTile.MY_COMPANIES) {
            yield put(toggleDoraMyCompaniesView(DoraMyCompaniesView.IN_SCOPE));
        } else if (selectedTab === DataManagementTile.RELEVANT_THIRD_PARTY_COMPANIES) {
            yield put(changeThirdPartyView(DoraThirdPartyView.ACTIVE_THIRD_PARTIES));
        } else {
            const extension = doraPathnames[selectedTab];
            const navigationPath = `/dora/data-management/${extension}`;
            yield put(push(navigationPath));
        }
    }
}

function* redirectSelectedTabWatcher() {
    yield takeEvery(DoraDataManagementActionTypes.SET_SELECTED_DORA_MANAGEMENT_TAB, redirectSelectedTab);
}

export function* doraDataManagementSaga() {
    yield all([
        fork(redirectSelectedTabWatcher)
    ]);
}
