import { isEqual } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';

import { Button } from '../button/Button';
import { SaveFile } from '../icons';
import { ModalHeader } from '../modal/ModalHeader';
import { FilterDropdownOptions, TableFilters } from '../modal/TableFilterModal';
import { Scrollable } from '../scrollable/Scrollable';
import { Text } from '../text/Text';
import { ReadOnlyFilters } from './ReadOnlyFilters';
import styles from './Table.module.scss';

const { red, primary } = styles;

interface CustomFilterHeader {
    field: string;
    header: string;
}

interface CustomFiltersSaveModalProps {
    isOpen: boolean;
    closeModal: () => void;
    save: (label: string, createNew: boolean) => void;
    label: string;
    isExistingCustomFilter: boolean;
    filters: TableFilters;
    filterDropdownOptions: FilterDropdownOptions;
    customFilterHeaders: CustomFilterHeader[];
    removeTextFilter?: string[];
}

export const CustomFiltersSaveModal: React.FC<CustomFiltersSaveModalProps> = ({ isOpen, closeModal, save, label, isExistingCustomFilter, filters, filterDropdownOptions, customFilterHeaders }) => {
    const [filterName, setFilterName] = useState<string>(label);
    const showSaveAs = useMemo(() => (isExistingCustomFilter && filterName !== label) || !isExistingCustomFilter, [filterName, isExistingCustomFilter, label]);

    const update = useCallback(() => save(filterName, false), [filterName, save]);
    const saveAs = useCallback(() => save(filterName, true), [filterName, save]);

    const filterNameEmpty = useMemo(() => filterName.length === 0, [filterName]);
    const filterNameBorderColour = useMemo(() => filterNameEmpty ? red : primary, [filterNameEmpty]);
    const saveDisabledTooltip = useMemo(() => filterNameEmpty ? ['Custom filter must have a name'] : [], [filterNameEmpty]);
    const namesMatch = useMemo(() => isEqual(label, filterName), [label, filterName]);
    const showWarningMessage = useMemo(() => isExistingCustomFilter && namesMatch, [isExistingCustomFilter, namesMatch]);

    const testId = 'save-custom-filters';

    useEffect(() => {
        setFilterName(label);
    }, [setFilterName, label]);

    if (!isOpen) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            className={styles.customFiltersSaveModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 10 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.customFiltersSaveModalWrapper}>
                <ModalHeader icon={SaveFile} label='Save Custom Filters' />
                <div className={styles.contentWrapper}>
                    <div>Filter Name</div>
                    <Text
                        value={filterName}
                        onChange={e => setFilterName(e.target.value)}
                        autoFocus={!isExistingCustomFilter}
                        borderColour={filterNameBorderColour}
                        testId={testId}
                    />
                    <div className={styles.scrollableWrapper}>
                        <Scrollable>
                            <ReadOnlyFilters
                                customFilterHeaders={customFilterHeaders}
                                filters={filters}
                                filterDropdownOptions={filterDropdownOptions}
                            />
                        </Scrollable>
                    </div>
                    {showWarningMessage && <div className={styles.warningMessage}>Please note: this will override your existing custom filter. If you would like to create a new custom filter, update the filter name and click "Save New", otherwise click "Update".</div>}
                </div>
                <div className={styles.buttonWrapper}>
                    <div className={styles.leftButtonWrapper}>
                        <Button onClick={closeModal} label='Cancel' testId={`${testId}-close`} />
                    </div>
                    <div className={styles.rightButtonWrapper}>
                        {isExistingCustomFilter && <Button onClick={update} disabled={filterNameEmpty} disabledTooltip={saveDisabledTooltip} label='Update' testId={`${testId}-update`} />}
                        {showSaveAs && <Button onClick={saveAs} disabled={filterNameEmpty} disabledTooltip={saveDisabledTooltip} label='Save New' testId={`${testId}-save-new`} />}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
