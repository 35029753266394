import { combineReducers } from 'redux';

import { myDocumentsReducer, MyDocumentsState } from '../my-documents/store';
import { searchDocumentsReducer, SearchDocumentsState } from '../search/store';
import { documentAnalyticsReducer, DocumentAnalyticsState } from '../analytics/store';
import { documentReportingReducer, DocumentReportingState } from '../reporting/store';

export interface DocumentState {
    myDocuments: MyDocumentsState;
    search: SearchDocumentsState;
    analytics: DocumentAnalyticsState;
    reporting: DocumentReportingState;
}

export const documentReducer = combineReducers({
    myDocuments: myDocumentsReducer,
    search: searchDocumentsReducer,
    analytics: documentAnalyticsReducer,
    reporting: documentReportingReducer
});
