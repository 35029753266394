import React, { useCallback, useMemo } from 'react';
import { noop } from 'lodash/fp';
import classnames from 'classnames';

import { OpenSuggestedChange, SuggestedChangesDB } from './store';
import styles from './Playbook.module.scss';
import { SuggestedChangeTile } from './SuggestedChangeTile';
import { WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import { AbstractSuggestedChanges } from '../../playbook/store';
import { Scrollable } from '../../shared/scrollable/Scrollable';

const { white } = styles;

export interface AbstractSuggestedChangeProps {
    suggestedChanges: SuggestedChangesDB[]
    setSuggestedChangeOpen: (playbookSuggestionId: number, sectionId: string) => void;
    openSuggestedChange: OpenSuggestedChange;
    abstractSuggestedChange: AbstractSuggestedChanges;
    testId?: string;
}

export const AbstractSuggestedChange: React.FC<AbstractSuggestedChangeProps> = ({ suggestedChanges, setSuggestedChangeOpen, openSuggestedChange, abstractSuggestedChange, testId = 'abstract-suggested-change' }) => {

    const suggestedChangeOpen = useCallback((playbookSuggestionId: number) => openSuggestedChange && openSuggestedChange.playbookSuggestionId === playbookSuggestionId || false, [openSuggestedChange]);
    const abstract = useMemo(() => abstractSuggestedChange.content, [abstractSuggestedChange.content]);

    return (
        <div className={styles.sectionSuggestedChangesWrapper} data-testid={`${testId}-wrapper`}>
            <Scrollable>
                <div className={classnames(styles.suggestedChangeTilesWrapper, { [styles.suggestedChangeTilesWrapperWithBorder]: true })}>
                    {suggestedChanges.map(suggestedChange => (
                        <SuggestedChangeTile suggestedChange={suggestedChange} openSuggestedChange={openSuggestedChange} key={suggestedChange.playbookSuggestionId} setSuggestedChangeOpen={setSuggestedChangeOpen} suggestedChangeOpen={suggestedChangeOpen(suggestedChange.playbookSuggestionId)} />
                    ))}
                </div>
            </Scrollable>
            <div className={styles.suggestedChangeAbstractWrapper} data-testid={`${testId}-content`}>
                <WYSIWYG
                    content={abstract}
                    updateContent={noop}
                    showBorder={false}
                    height='fit-content'
                    minHeight='fit-content'
                    maxHeight='fit-content'
                    borderColor={white}
                    padding='0px'
                    editorPadding='0px'
                    disabled
                    toolbarHidden
                    isReadOnly
                />
            </div>
        </div>
    );
};
