import { getOr, isEqual, isUndefined } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Toggle } from '../../../shared/toggle';
import { OverflowTooltip } from '../../../shared/tooltip';
import { getAgreementCoverageSearchTerm, getFieldSignOffNotes, getIsFirstTimelineEntry, updateAgreementCoverageSearch, updateSingleToggleValue } from '../store';
import { AgreementCoverage, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';
import { CollateralAgreementCoverageGroup, CollateralProviderAgreementGroups, CollateralTakerAgreementGroups } from '../../../constants/opinion';
import { SubSectionWrapper } from './SubSectionWrapper';
import { Text } from '../../../shared/text/Text';
import { SignOffConversationIcon } from '../sign-off';

const { lightGrey, amethyst, grey, french, gold } = styles;

interface CollateralAgreementCoverageProps {
    agreementCoverage: AgreementCoverage;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
    collateralOpinionGroups: CollateralProviderAgreementGroups | CollateralTakerAgreementGroups;
}

export const CollateralAgreementCoverageSection: React.FC<CollateralAgreementCoverageProps> = ({ agreementCoverage, isEditing, isUpdating, fieldsUpdated, collateralOpinionGroups }) => {
    const dispatch = useAppDispatch();

    const agreementCoverageSearchTerm = useAppSelector(getAgreementCoverageSearchTerm);
    const searchAgreementCoverage = useCallback((searchTerm: string) => dispatch(updateAgreementCoverageSearch(searchTerm)), [dispatch]);
    const isFirstTimelineEntry = useAppSelector(getIsFirstTimelineEntry);
    const sectionId = 'agreementCoverage';
    const { standardOptions } = agreementCoverage;

    const updateValue = useCallback((fieldId: 'standardOptions', value: boolean, index: number) => dispatch(updateSingleToggleValue(sectionId, fieldId, index, value)), [dispatch]);

    const fieldUpdatedByInstance = useCallback((fieldId: 'standardOptions', index: number) => !!fieldsUpdated.find(field => isEqual({ fieldId: field.fieldId, sectionId: field.sectionId, index: field.index }, { fieldId, sectionId, index })), [fieldsUpdated, sectionId]);
    const getIsDisabled = useCallback((fieldId: 'standardOptions', index: number) => !isEditing && !(isUpdating && fieldUpdatedByInstance(fieldId, index)), [isUpdating, isEditing, fieldUpdatedByInstance]);
    const shouldShowFieldUpdated = useCallback((fieldId: 'standardOptions', index: number) => fieldUpdatedByInstance(fieldId, index) && !isUpdating && !isEditing && !isFirstTimelineEntry, [isUpdating, isEditing, fieldUpdatedByInstance, isFirstTimelineEntry]);

    const disabled = !isEditing && !isUpdating;

    const updateAll = (checked: boolean) => {
        standardOptions.forEach((_, index) => updateValue('standardOptions', checked, index));
    };

    const getOptionWrapperStyle = useCallback((index: number, totalEntries = standardOptions.length) => {
        const numberOfLastRowEntries = totalEntries % 2 || 2;
        const lastRowEntries = Array(numberOfLastRowEntries).fill(totalEntries - numberOfLastRowEntries).map((val, i) => val + i);
        const isLastRow = lastRowEntries.includes(index);
        const isFirstColumn = ((index + 1) % 2) === 1;
        if (!isLastRow) {
            return !isFirstColumn ? { width: 'calc((100% / 2) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}`, borderBottom: `1px solid ${lightGrey}` } : { width: 'calc((100% / 2) - 10px)', borderBottom: `1px solid ${lightGrey}` };
        }
        return !isFirstColumn ? { width: 'calc((100% / 2) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}` } : { width: 'calc((100% / 2) - 10px)' };
    }, [standardOptions.length]);

    const boxShadowColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return grey;
    }, []);

    const onColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return french;
    }, []);

    const allSelected = useMemo(() => standardOptions.map(({ value }) => value).every(value => !!value), [standardOptions]);

    const signOffConversation = useAppSelector(getFieldSignOffNotes(sectionId));

    const getOptionsFromGroup = useCallback((group: CollateralAgreementCoverageGroup) => getOr([], group, collateralOpinionGroups), [collateralOpinionGroups]);
    const groupOptions = useMemo(() => Object.keys(collateralOpinionGroups) as CollateralAgreementCoverageGroup[], [collateralOpinionGroups]);
    const getAllGroupSelected = useCallback((group: CollateralAgreementCoverageGroup) => {
        const groupNames = getOptionsFromGroup(group);
        const groupOptions = standardOptions.filter(({ label }) => groupNames.includes(label));
        return groupOptions.map(({ value }) => value).every(value => !!value);
    }, [standardOptions, getOptionsFromGroup]);

    const updateGroupValue = useCallback((checked: boolean, group: CollateralAgreementCoverageGroup) => {
        const groupNames = getOptionsFromGroup(group);
        standardOptions.forEach(({ label }, index) => {
            if (groupNames.includes(label)) {
                updateValue('standardOptions', checked, index);
            }
        });
    }, [updateValue, getOptionsFromGroup, standardOptions]);

    // Escapes all special characters in case a user does search with these
    const filterSearchInput = (text: string): string => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const highlightText = useCallback((label: string): JSX.Element => {
        if (!agreementCoverageSearchTerm) {
            return <>{label}</>;
        }

        const filteredSearchTerm = filterSearchInput(agreementCoverageSearchTerm);
        // We split up the text into an array of strings based on what's typed into the filter box
        // The RegExp takes the filteredSearchTerm and matches it to all instances (global) and ignores the case (this is the 'gi' string)
        const searchTermParts = label.split(new RegExp(`(${filteredSearchTerm})`, 'gi'));

        return (
            <>
                {searchTermParts.map((searchTermPart, index) => (
                    <span key={index} style={{ backgroundColor: searchTermPart.toLowerCase() === agreementCoverageSearchTerm.toLowerCase() ? gold : undefined }}>
                        {searchTermPart}
                    </span>
                )
                )}
            </>
        );
    }, [agreementCoverageSearchTerm]);

    useEffect(() => () => {
        // Reset the search term to an empty string on unMount
        searchAgreementCoverage('');
    }, [searchAgreementCoverage]);

    return (
        <SectionWrapper id={sectionId} label='Agreement Coverage' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.agreementCoverageSectionWrapper}>
                <div className={styles.agreementCoverageWrapper}>
                    <div className={styles.agreementCoverageHeaderWrapper}>
                        <div className={styles.selectAllWrapper}>
                            <div className={styles.selectAllLabel}>Select All</div>
                            <Toggle onChange={updateAll} checked={allSelected} disabled={disabled} />
                        </div>
                        {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={sectionId} />}
                    </div>
                    <div className={styles.groupOptionsWrapper} data-testid={'agreement-coverage-group-options-wrapper'}>
                        {groupOptions.map((label, index) => {
                            const optionWrapperStyle = getOptionWrapperStyle(index, groupOptions.length);
                            const value = getAllGroupSelected(label);
                            return (
                                <div className={styles.toggleOptionWrapper} key={index} style={optionWrapperStyle}>
                                    <div className={styles.agreementCoverageLabel}>
                                        <OverflowTooltip overlayText={label} trigger='click' />
                                    </div>
                                    <Toggle
                                        onChange={checked => updateGroupValue(checked, label)}
                                        checked={value}
                                        disabled={disabled}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <SubSectionWrapper id={`${sectionId}-individual`} label='Individual Agreements Breakdown'>
                        <>
                            <div className={styles.searchWrapper}>
                                <Text
                                    onChange={e => searchAgreementCoverage(e.target.value)}
                                    value={agreementCoverageSearchTerm}
                                    maxLength={256}
                                    testId='agreement-coverage-search'
                                    placeholder='Search...'
                                    marginBottom='0px'
                                    borderColour={lightGrey}
                                />
                            </div>
                            <div className={styles.agreementCoverageOptionsWrapper} data-testid='agreement-coverage-standard-options-wrapper'>
                                {standardOptions.map(({ value, label }, index) => {
                                    const fieldId = 'standardOptions';
                                    const isDisabled = getIsDisabled(fieldId, index);
                                    const showFieldUpdated = shouldShowFieldUpdated(fieldId, index);
                                    const optionWrapperStyle = getOptionWrapperStyle(index);
                                    const highlighted = highlightText(label);
                                    return (
                                        <div className={styles.toggleOptionWrapper} key={index} style={optionWrapperStyle}>
                                            <div className={styles.agreementCoverageLabel}>
                                                <OverflowTooltip overlayText={highlighted} trigger='click' />
                                            </div>
                                            <Toggle
                                                onChange={checked => updateValue(fieldId, checked, index)}
                                                checked={value}
                                                disabled={isDisabled}
                                                boxShadowColour={boxShadowColour(showFieldUpdated)}
                                                onColour={onColour(showFieldUpdated)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    </SubSectionWrapper>
                </div>
            </div>
        </SectionWrapper>
    );
};
