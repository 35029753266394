import React, { useCallback } from 'react';

import { ArkOpinion } from '../../my-opinions/store';
import { General, UpdatedOpinionField } from '../store/types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { MetaField } from '../fields/MetaField';
import { SectionWrapper } from './SectionWrapper';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import styles from './OpinionSection.module.scss';
import { formatDate } from '../../../../utils/luxon';

interface GeneralSectionProps {
    opinion: ArkOpinion;
    general: General;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const GeneralSection: React.FC<GeneralSectionProps> = ({ opinion, general, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'general';
    const { jurisdiction, dateOfOpinion, type, focus } = opinion;
    const { dateOfReview, reviewer, provider } = general;

    return (
        <SectionWrapper id={sectionId} label='General' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.generalSectionWrapper}>
                <div className={styles.sectionGroup}>
                    <MetaField id='jurisdiction' label='Jurisdiction' value={[jurisdiction!]} width='calc(50% - 10px)' />
                    <MetaField id='focus' label='Focus' value={[focus]} width='calc(50% - 10px)' />
                </div>
                <MetaField id='date-of-opinion' label='Date of Opinion' value={[formatDate(dateOfOpinion!)]} width='100%' />
                <div className={styles.sectionGroup}>
                    <MetaField id='type' label='Type' value={[type!]} width='calc(50% - 10px)' />
                    <SingleField
                        field={dateOfReview}
                        fieldId='dateOfReview'
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        getFieldOpen={getFieldOpen}
                        toggleFieldOpen={toggleFieldOpen}
                        width='calc(50% - 10px)'
                    />
                </div>
                <div className={styles.sectionGroup}>
                    <SingleField
                        field={reviewer}
                        fieldId='reviewer'
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        getFieldOpen={getFieldOpen}
                        toggleFieldOpen={toggleFieldOpen}
                        width='calc(50% - 10px)'
                    />
                    <SingleField
                        field={provider}
                        fieldId='provider'
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        getFieldOpen={getFieldOpen}
                        toggleFieldOpen={toggleFieldOpen}
                        width='calc(50% - 10px)'
                    />
                </div>
            </div>
        </SectionWrapper>
    );
};
