import React, { useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { OpenSuggestedChange, SuggestedChangesDB } from './store';
import styles from './Playbook.module.scss';
import { formatDate, SHORT_MONTH_FORMAT } from '../../../utils/luxon';
import { OverflowTooltip, TooltipList } from '../../shared/tooltip';
import { Icon } from '../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../shared/icons';
import { ABSTRACT_ID } from '../../constants/playbooks';

const { french, resolved, frenchLight, resolvedUnselected } = styles;

export interface SuggestedChangeTileProps {
    suggestedChange: SuggestedChangesDB;
    suggestedChangeOpen: boolean;
    setSuggestedChangeOpen: (playbookSuggestionId: number, sectionId: string) => void;
    openSuggestedChange: OpenSuggestedChange | null;
    testId?: string;
}

export const SuggestedChangeTile: React.FC<SuggestedChangeTileProps> = ({ suggestedChange, suggestedChangeOpen = false, setSuggestedChangeOpen, openSuggestedChange, testId = 'suggested-change-tile' }) => {
    const { suggestedSection, createdDate, playbookSuggestionId, sectionId, outstanding } = suggestedChange;
    const { changedContent: { deviationChanges, provisionChanges } } = suggestedSection;

    const showNumberOfChanges = useMemo(() => sectionId !== ABSTRACT_ID && outstanding === 1, [sectionId, outstanding]);
    const numberOfChanges = useMemo(() => deviationChanges.length + provisionChanges.filter(change => change !== 'deviations').length, [deviationChanges, provisionChanges]);

    const tileColour = useMemo(() => {
        if (isNull(openSuggestedChange) || suggestedChangeOpen) {
            return outstanding ? french : resolved;
        }
        return outstanding ? frenchLight : resolvedUnselected;

    }, [outstanding, suggestedChangeOpen, openSuggestedChange]);

    const tooltipLabels = useMemo(() => ({
        title: 'Section Title',
        provisionTitle: 'Standard Provision Title',
        provisionContent: 'Standard Provision Content',
        backgroundTitle: 'Discussion and Background Title',
        backgroundContent: 'Discussion and Background Content',
        preferredTerms: 'Preferred Terms',
        deviations: `Amendments/ Deviations(${deviationChanges.length})`,
        sectionId: '',
        relevantStakeholders: '',
        type: '',
        linkedProvisions: ''
    }), [deviationChanges]);

    const suggestedChangesTooltip = useMemo(() => provisionChanges.map(change => tooltipLabels[change]), [provisionChanges, tooltipLabels]);

    return (
        <div>
            <div className={styles.suggestedChangeTileWrapper} style={{ background: tileColour, border: `1px solid ${tileColour}` }} onClick={() => setSuggestedChangeOpen(playbookSuggestionId, sectionId)} data-testid={`${testId}-${playbookSuggestionId}-wrapper`}>
                <div className={styles.suggestedChangeTileLabelWrapper}>
                    <OverflowTooltip overlayText={suggestedSection.name!} testId={`${testId}-${playbookSuggestionId}-username`} />
                    <OverflowTooltip overlayText={formatDate(createdDate, SHORT_MONTH_FORMAT)} testId={`${testId}-${playbookSuggestionId}-created-date`} />
                </div>
                {showNumberOfChanges &&
                    <TooltipList overlayText={suggestedChangesTooltip} placement='bottom' >
                        <div className={styles.suggestedChangeMarker} data-testid={`${testId}-${playbookSuggestionId}-number-of-changes`}>{numberOfChanges}</div>
                    </TooltipList>
                }
                <Icon icon={suggestedChangeOpen ? CaretDown : CaretSide} fontSize={20} color='white' />
            </div>
        </div>
    );
};
