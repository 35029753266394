import { OpinionHealthIndicator, OpinionScope } from '../components/admin/opinions/store';
import { TableFilters } from '../components/shared/modal/TableFilterModal';
import { ColumnSort } from '../components/shared/table/ArkTable';
import { api } from './services';

interface FetchOpinionJurisdictionSummaryParams {
    scope: OpinionScope;
    sectionId: string;
    fieldId: string;
    viewSignOffAnswers: boolean;
}

export const fetchDocumentSpreadAnalytics = async (reqBody: { entityId: number | null; }) => await api('analytics/document/spread', { ...reqBody }, 'post');
export const fetchEntitySpreadAnalytics = async (reqBody: { entityId: number | null; }) => await api('analytics/entity/spread', { ...reqBody }, 'post');
export const fetchAttestationProgressAnalytics = async () => await api('analytics/attestation/progress', {}, 'get');
export const fetchEntityJurisdictionAnalytics = async (reqBody: { entityId: number | null; }) => await api('analytics/jurisdiction/entity', { ...reqBody }, 'post');
export const fetchJurisdictionDocumentsForEntity = async (reqBody: { myEntityId: number; entityId: number; pageNumber: number; }) => await api('analytics/jurisdiction/documents', { ...reqBody }, 'post');
export const fetchMyAttestationProgressAnalytics = async () => await api('analytics/myAttestation/progress', {}, 'get');
export const fetchDocumentRiskToleranceAnalytics = async (reqBody: { documentId: number, isPreExecution: boolean | undefined; }) => await api('analytics/riskTolerance/document', { ...reqBody }, 'post');
export const fetchAllDocumentsRiskToleranceAnalytics = async () => await api('analytics/riskTolerance/allDocuments', {}, 'get');
export const fetchEntityDocumentsRiskToleranceAnalytics = async (reqBody: { entityId: number }) => await api('analytics/riskTolerance/entityDocuments', { ...reqBody }, 'post');
export const fetchPreExecutionDocumentsRiskToleranceAnalytics = async () => await api('analytics/riskTolerance/preExecution', {}, 'get');
export const fetchAllDatasetsRiskToleranceAnalytics = async (reqBody: { entityId: number | undefined; isPreExecution: boolean | undefined; }) => await api('analytics/riskTolerance/allDatasets', { ...reqBody }, 'post');
export const fetchRiskToleranceDatasetDefinitionsAnalytics = async (reqBody: { datasetId: number }) => await api('analytics/riskTolerance/datasetDefinitions', { ...reqBody }, 'post');
export const fetchDatasetFieldRiskToleranceAnalytics = async (reqBody: { parentDatasetId: number, childDatasetId: number, fieldId: string, pageNumber: number; pageSize: number; filters: TableFilters; columnSort: ColumnSort | undefined; entityId: number | undefined; isPreExecution: boolean | undefined; }) => await api('analytics/riskTolerance/datasetField', { ...reqBody }, 'post');
export const fetchPaginatedRiskToleranceDocuments = async (reqBody: { pageNumber: number; pageSize: number; filters: TableFilters; columnSort: ColumnSort | undefined; entityId: number | undefined, isPreExecution: boolean | undefined; }) => await api('analytics/riskTolerance/getPaginatedDocuments', { ...reqBody }, 'post');

export const fetchOpinionJurisdictionSummary = async (reqBody: FetchOpinionJurisdictionSummaryParams) => await api('analytics/opinion/getJurisdictionSummary', { ...reqBody }, 'post');
export const fetchOpinionsAverage = async (reqBody: { scope: OpinionScope; }) => await api('analytics/opinion/average', { ...reqBody }, 'post');
export const fetchOpinionAverageBreakdown = async (reqBody: { opinionId: number; scope: OpinionScope; }) => await api('analytics/opinion/average/breakdown', { ...reqBody }, 'post');

export const fetchNetworkDiagramAnalytics = async () => await api('analytics/dora/networkDiagram', {}, 'get');
export const fetchDoraThirdPartyCoverageAnalytics = async () => await api('analytics/dora/thirdPartyCoverage', {}, 'get');
export const fetchSelectedDoraThirdPartyCoveragePieAnalytics = async (reqBody: { thirdPartyCompanyId: number; }) => await api('analytics/dora/thirdPartyCoverage/getThirdPartyFunctionCompanyPie', { ...reqBody }, 'post');
export const fetchSelectedDoraThirdPartyCoverageTableAnalytics = async (reqBody: { pageNumber: number; pageSize: number; filters: TableFilters; columnSort: ColumnSort | undefined; thirdPartyCompanyId: number; }) => await api('analytics/dora/thirdPartyCoverage/getPaginatedFunctionCompanies', { ...reqBody }, 'post');

export const fetchOutstandingContractData = async () => await api('analytics/dora/missingContractData', {}, 'get');

export const fetchClientPageUsageAnalytics = async () => await api('analytics/client/pageUsage', {}, 'get');

//admin opinion analytics
export const fetchAdminOpinionPieAnalytics = async () => await api('analytics/opinion/admin/getOpinions', {}, 'get');
export const fetchSelectedAdminOpinions = async (reqBody: { health: OpinionHealthIndicator; pageNumber: number; pageSize: number; filters: TableFilters; columnSort: ColumnSort | undefined }) => await api('analytics/opinion/admin/selectedOpinions', { ...reqBody }, 'post');
