import React, { useState } from 'react';
import classnames from 'classnames';
import { isUndefined, noop } from 'lodash/fp';

import { DatasetFieldType, SingleDatasetField } from '../../../../datasets/store';
import { OverflowTooltip, InformationTooltip } from '../../../../shared/tooltip';
import { Text, LongText, DateField, Dropdown, Checkbox, Number, Time, DocumentField, WYSIWYGField, LabelField, PartySelect } from '../../../../datasets/fields';
import styles from './FormPreview.module.scss';
import { CalculatorField } from '../../../../datasets/fields/CalculatorField';
import { CalculatorFieldType, newCalculatorField } from '../../../../shared/calculator/constants';
import { CaretDown, CaretSide } from '../../../../shared/icons';
import { Icon } from '../../../../shared/icon/Icon';
import { DatasetField } from '../../../../datasets/fields/DatasetField';
import { useFieldHighlightColor } from '../../../../../hooks/useFieldHighlightColor';
import { CurrencyAmountField } from '../../../../datasets/fields/CurrencyAmountField';

const getField = (field: SingleDatasetField, index: number, fieldOpen: boolean, groupIndex?: number, datasetBuilder?: boolean) => {
    const defaultProps = { id: field.id!, disabled: true, value: '', index, groupIndex, datasetId: 1, parentFieldId: '' };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} />;
        case DatasetFieldType.LONGTEXT:
            return <LongText {...defaultProps} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={null} />;
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
            return <Dropdown {...defaultProps} value={null} type={field.type} listId='0' />;
        case DatasetFieldType.PARTY:
            return <PartySelect {...defaultProps} value={null} />;
        case DatasetFieldType.DOCUMENT:
            return <DocumentField id={field.id!} value={undefined} />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={false} />;
        case DatasetFieldType.DATASET:
            return <DatasetField
                childDatasetId={field.settings.datasetLinked || '1'}
                isEditing={false}
                isUpdating={false}
                fieldOpen={true}
                id={field.id!}
            />;
        case DatasetFieldType.TIME:
            return <Time {...defaultProps} value={['', '']} />;
        case DatasetFieldType.WYSIWYG:
            return <WYSIWYGField {...defaultProps} value={null} fieldOpen={fieldOpen} toggleSection={noop} getSectionOpen={() => false} />;
        case DatasetFieldType.LABEL:
            return <LabelField value={field.settings.labelContent!} />;
        case DatasetFieldType.CALCULATOR:
            return <CalculatorField
                {...defaultProps}
                value={[newCalculatorField()]}
                fieldType={field.settings.calculatorFieldType || CalculatorFieldType.RATINGS_TRIGGERS}
                showOutputField={field.settings.showOutputField || true}
                showGracePeriod={field.settings.showGracePeriod || true}
                includeFreeTextOption={field.settings.includeFreeTextOption || true}
                fieldOpen={fieldOpen}
                datasetBuilder={datasetBuilder}
            />;
        case DatasetFieldType.CURRENCY_AMOUNT:
            return <CurrencyAmountField
                {...defaultProps}
                currencyValue={{ currency: null, value: undefined }}
            />;
        default:
            return <div className={styles.unknownFieldType}>Field type preview incomplete.</div>;
    }
};

interface SingleFieldPreviewProps {
    field: SingleDatasetField;
    width?: number;
    isLastFieldInGroup?: boolean;
    isLastFieldInSection?: boolean;
    index: number;
    singleIndex?: string;
    groupIndex?: number;
    isMultiToggle?: boolean;
    datasetBuilder?: boolean;
}

export const SingleFieldPreview: React.FC<SingleFieldPreviewProps> = ({ field, width = 100, isLastFieldInGroup = false, isLastFieldInSection = false, index, singleIndex, groupIndex, isMultiToggle = false, datasetBuilder }) => {
    const { label, description, refLabel, settings, type } = field;
    const wysiwygFieldOpen = !isUndefined(field.settings.isCollapsible) && !field.settings.isCollapsible;
    const [fieldOpen, setFieldOpen] = useState<boolean>(wysiwygFieldOpen);
    const fieldOpenIcon = fieldOpen ? CaretDown : CaretSide;
    const { color, backgroundColor } = useFieldHighlightColor(settings.highlight);

    const toggleSection = () => setFieldOpen(!fieldOpen);
    const isCollapsible = settings.isCollapsible || [DatasetFieldType.DATASET, DatasetFieldType.CALCULATOR].includes(type);
    const testId = singleIndex || index;
    const isLabelField = type === DatasetFieldType.LABEL;

    if (isMultiToggle) {
        return (
            <div className={styles.multiToggleField}>
                <div className={classnames(styles.multiToggleLabel, { [styles.emptyFieldLabel]: !label })}>
                    <OverflowTooltip overlayText={label || 'Label...'} />
                </div>
                {getField(field, index, fieldOpen, groupIndex, datasetBuilder)}
            </div>
        );
    }

    return (
        <div
            className={classnames(styles.singleFieldWrapper, {
                [styles.lastFieldInGroup]: isLastFieldInGroup,
                [styles.isLabelField]: isLabelField && !isLastFieldInSection,
            })}
            style={{ width: `calc(${width}% - 5px)` }}
            data-testid={`form-preview-field-${testId}-wrapper`}
        >
            {type !== DatasetFieldType.LABEL &&
                <div className={styles.fieldTitleWrapper} style={{ color, backgroundColor }}>
                    {isCollapsible &&
                        <div className={styles.fieldOpenWrapper} onClick={toggleSection} data-testid='collapsible-field'>
                            <Icon icon={fieldOpenIcon} fontSize={15} />
                        </div>
                    }
                    <OverflowTooltip className={classnames(styles.fieldLabel, { [styles.emptyFieldLabel]: !label })} overlayText={label || 'Label...'} testId={`form-preview-field-${testId}-label`} />
                    {description && <InformationTooltip content={description} />}
                    {refLabel && settings.showRef && <InformationTooltip content={refLabel} label='?' />}
                    {settings.showClause && <InformationTooltip content='Clause Label Preview' label='cl' />}
                </div>
            }
            {getField(field, index, fieldOpen, groupIndex, datasetBuilder)}
        </div>
    );
};
