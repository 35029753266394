import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import classnames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { useWindowResize } from '../../../hooks/useWindowResize';
import { DraggableItem, DraggableProps, getItemData, isItemData, useListContext } from '../../shared/drag-n-drop/shared';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import styles from '../Home.module.scss';

type TileState = 'idle' | 'dragging' | 'over';

interface HomeScreenTileProps extends DraggableProps {
    tileHeight: number;
    tileWidth: number;
    type: string;
}

const HomeScreenTile: React.FC<HomeScreenTileProps> = ({ item, getChildElement, index, type, tileHeight, tileWidth }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { instanceId, registerItem } = useListContext();
    const [tileState, setTileState] = useState<TileState>('idle');

    useEffect(() => {
        const element = ref.current;
        invariant(element);

        const data = getItemData({ item, index, instanceId, type });

        return combine(
            registerItem({ itemId: item.id, type, element }),
            draggable({
                element,
                getInitialData: () => data,
                onDragStart: () => setTileState('dragging'),
                onDrop: () => setTileState('idle'),
            }),
            dropTargetForElements({
                element,
                getData: () => data,
                getIsSticky: () => true,
                canDrop: ({ source }) =>
                    source.data.instanceId === instanceId &&
                    isItemData(source.data) &&
                    source.data.item.id !== item.id,
                onDragEnter: () => setTileState('over'),
                onDragLeave: () => setTileState('idle'),
                onDrop: () => setTileState('idle'),
            }),
        );
    }, [instanceId, index, item, type, registerItem]);

    const tile = useMemo(() => getChildElement(item.id, index, type), [getChildElement, item.id, index, type]);

    return (
        <div
            className={classnames(styles.homeScreenConfigTileWrapper, {
                [styles.homeTileDragging]: tileState === 'dragging',
                [styles.homeTileOver]: tileState === 'over',
                [styles.homeTileIdle]: tileState === 'idle'
            })}
            ref={ref}
            style={{ height: `${tileHeight}px`, width: `${tileWidth}px` }}
        >
            {tile}
        </div>
    );
};

interface HomeScreenConfigContentProps {
    columnId: string;
    tiles: DraggableItem[];
    isLastColumn: boolean;
    getChildElement: (id: string, index: number, type?: string) => JSX.Element | null;
}

export const HomeScreenConfigContent: React.FC<HomeScreenConfigContentProps> = ({ columnId, tiles, isLastColumn, getChildElement }) => {
    const [screenWidth, screenHeight] = useWindowResize();

    const tileHeight = useMemo(() => ((((screenHeight * 0.8) - 129) * 0.85) / 2) - 40, [screenHeight]);
    const width = useMemo(() => (screenWidth * 0.7) < 800 ? 800 : screenWidth * 0.7, [screenWidth]);
    const tileWidth = useMemo(() => ((width - 230) / 4) - (isLastColumn ? 41 : 40), [width, isLastColumn]);

    const column = useMemo(() => (
        <div className={classnames(styles.homeScreenConfigTilesWrapper, { [styles.lastColumn]: isLastColumn })}>
            {tiles.map((item, index) => (
                <HomeScreenTile getChildElement={getChildElement} type={columnId} index={index} item={item} key={item.id} tileHeight={tileHeight} tileWidth={tileWidth} />
            ))}
        </div>
    ), [columnId, isLastColumn, tiles, getChildElement, tileHeight, tileWidth]);

    if (isLastColumn) {
        return (
            <div className={styles.scrollableDroppableWrapper}>
                <Scrollable>
                    {column}
                </Scrollable>
            </div>
        );
    }

    return (
        <div className={styles.droppableWrapper}>
            {column}
        </div>
    );
};
