import React from 'react';
import classnames from 'classnames';

import { useAppDispatch } from '../../../hooks/react-redux';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import styles from './Fields.module.scss';

interface TextProps {
    id: string;
    value: string | undefined;
    index: number;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    disabled?: boolean;
    placeholder?: string;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean;
    showAIModifiedUserCorrected?: boolean;
    isMLCorrection?: boolean;
    showClause?: boolean;
    isTable?: boolean;
    includedInAnnex?: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
}

export const Text: React.FC<TextProps> = ({
    id,
    value,
    disabled = false,
    placeholder = 'Aa...',
    index,
    sectionId,
    groupIndex,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    isMLCorrection = false,
    showClause = false,
    isTable = false,
    includedInAnnex = false,
    datasetId,
    parentFieldId,
    modalInstance
}) => {
    const dispatch = useAppDispatch();
    const updateValue = (value: string) => {
        if (isMLCorrection) {
            dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
        }
    };

    const placeholderText = includedInAnnex ? 'See Agency Annex' : placeholder;

    return (
        <input
            data-testid={`definition-text-${id}`}
            className={classnames(styles.textField, {
                [styles.updatedInputField]: showFieldUpdated,
                [styles.newInputField]: showDatasetUpdated,
                [styles.aiInputField]: showAIModified,
                [styles.aiCorrectedInputField]: showAIModifiedUserCorrected,
                [styles.withClauseLabel]: showClause && isTable
            })}
            onChange={e => updateValue(e.target.value)}
            value={value}
            disabled={disabled || includedInAnnex}
            placeholder={placeholderText}
        />
    );
};
