import React, { useMemo, useState } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { CalculatorField as CalculatorValue, CalculatorFieldType } from '../../shared/calculator/constants';
import { updateFieldValue } from '../instances/store';
import styles from './Fields.module.scss';
import { ReadOnlyCalculatorField } from './ReadOnlyCalculatorField';
import { CalculatorModal } from './CalculatorModal';
import { IconButton } from '../../shared/button/IconButton';
import { Calculator as CalculatorIcon } from '../../shared/icons';
import { CalculatorReadOnlyModal } from './CalculatorReadOnlyModal';

const { lightGrey, amethyst, green } = styles;

interface CalculatorFieldProps {
    id: string;
    index: number;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    disabled?: boolean;
    value: CalculatorValue[];
    fieldType: CalculatorFieldType;
    showOutputField: boolean;
    showGracePeriod: boolean;
    includeFreeTextOption?: boolean;
    fieldOpen: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
    datasetBuilder?: boolean;
}

export const CalculatorField: React.FC<CalculatorFieldProps> = ({
    id,
    disabled = false,
    value,
    index,
    sectionId,
    groupIndex,
    rowId,
    fieldType,
    showOutputField,
    showGracePeriod,
    includeFreeTextOption = false,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    parentFieldId,
    fieldOpen,
    datasetId,
    modalInstance,
    datasetBuilder
}) => {
    const dispatch = useAppDispatch();
    const [showCalculatorModal, setShowCalculatorModal] = useState<boolean>(false);
    const [calculatorInstance, setCalculatorInstance] = useState<CalculatorValue[]>(value);

    const updateValue = (value: CalculatorValue[]) => dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
    const borderColor = useMemo(() => {
        if (!showDatasetUpdated && !showFieldUpdated) {
            return lightGrey;
        }
        return showFieldUpdated ? amethyst : green;
    }, [showDatasetUpdated, showFieldUpdated]);

    const openModal = () => setShowCalculatorModal(true);

    const updateCalculatorInstance = (value: CalculatorValue[]) => setCalculatorInstance(value);

    const closeModal = () => {
        setShowCalculatorModal(false);
        setCalculatorInstance(value);
    };

    const closeAndUpdateCalculatorCell = () => {
        setShowCalculatorModal(false);
        updateValue(calculatorInstance);
    };

    const iconDisabled = useMemo(() => disabled && !datasetBuilder, [disabled, datasetBuilder]);

    return (
        <div className={styles.calculatorWrapper}>
            {fieldOpen &&
                <div className={styles.fieldOpenWrapper}>
                    <div className={styles.readOnlyCalculatorField}>{value.map(({ calculation, calculationCondition, output, gracePeriod }, calculationIndex) => (
                        <ReadOnlyCalculatorField
                            value={value}
                            calculation={calculation}
                            output={output}
                            gracePeriod={gracePeriod}
                            calculationCondition={calculationCondition}
                            calculationIndex={calculationIndex}
                            fieldType={fieldType}
                            borderColor={borderColor}
                            key={calculationIndex}
                            showOutputField={showOutputField}
                            showGracePeriod={showGracePeriod}
                        />
                    ))}</div>
                    <div>
                        <IconButton icon={CalculatorIcon} disabled={iconDisabled} onClick={openModal} fontSize={40} />
                    </div>
                </div>
            }
            {showCalculatorModal && !datasetBuilder &&
                <CalculatorModal
                    isOpen={showCalculatorModal}
                    disabled={disabled}
                    closeModal={closeModal}
                    fieldType={fieldType}
                    value={calculatorInstance}
                    updateValue={updateCalculatorInstance}
                    borderColor={borderColor}
                    id={id}
                    saveModal={closeAndUpdateCalculatorCell}
                    showOutputField={showOutputField}
                    showGracePeriod={showGracePeriod}
                    includeFreeTextOption={includeFreeTextOption}
                />
            }
            {showCalculatorModal && datasetBuilder &&
                <CalculatorReadOnlyModal
                    isOpen={showCalculatorModal}
                    disabled={false}
                    closeModal={closeModal}
                    fieldType={fieldType}
                    value={calculatorInstance}
                    borderColor={borderColor}
                    showOutputField={showOutputField}
                    showGracePeriod={showGracePeriod}
                    includeFreeTextOption={includeFreeTextOption}
                />
            }
        </div>
    );
};
