import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { DatasetDefinition } from '../../../datasets/store';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';
import { RiskField, RiskTolerance, RiskToleranceDataset, RiskToleranceState, SelectedRiskField } from './types';

const getRoot = (state: ApplicationState): RiskToleranceState => state.admin.riskTolerance;

export const getAllRiskToleranceDatasets = (state: ApplicationState): RiskToleranceDataset[] => getRoot(state).allRiskToleranceDatasets;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getRiskToleranceConfig = (state: ApplicationState): RiskTolerance[] | null => getRoot(state).riskToleranceConfig;

export const getCurrentRiskToleranceConfig = (state: ApplicationState): RiskTolerance[] | null => getRoot(state).currentRiskToleranceConfig;

export const getDatasetDefinitions = (state: ApplicationState): DatasetDefinition[] => getRoot(state).definitions;

export const getDocumentHiddenFields = (state: ApplicationState): DocumentSpecificHiddenFields => getRoot(state).documentHiddenFields;

export const getSelectedRiskField = (state: ApplicationState): SelectedRiskField | null => getRoot(state).currentRiskField;

export const getCurrentRiskField = (state: ApplicationState): RiskField | null => {
    const selectedRiskField = getSelectedRiskField(state);
    const currentRiskToleranceConfig = getCurrentRiskToleranceConfig(state);
    if (isNull(selectedRiskField) || isNull(currentRiskToleranceConfig)) {
        return null;
    }
    const { datasetId, fieldId } = selectedRiskField;
    return currentRiskToleranceConfig.find(riskDataset => riskDataset.datasetId === datasetId)?.config.find(riskField => riskField.fieldId === fieldId) || null;
};

export const getRiskToleranceHasUpdated = (state: ApplicationState): boolean => getRoot(state).riskToleranceUpdated;

export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;

export const getUnsavedChangesModalOpen = (state: ApplicationState): boolean => getRoot(state).unsavedChangesModalOpen;

export const getSelectedRiskToleranceDatasetId = (state: ApplicationState): number | null => getRoot(state).selectedRiskToleranceDataset;
