import React from 'react';
import { isUndefined } from 'lodash/fp';

import { OverflowTooltip } from '../tooltip';
import styles from './Table.module.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const TooltipCell: React.FC<{ valueFormatted?: string | null; value: any; testId?: string; }> = ({ valueFormatted, value, testId }) => {
    if (isUndefined(valueFormatted) && isUndefined(value)) {
        return null;
    }

    const displayValue = valueFormatted || value;

    return (
        <div className={styles.tooltipCellWrapper}>
            <OverflowTooltip className={styles.tooltipValue} overlayText={displayValue} testId={testId} />
        </div>
    );
};
