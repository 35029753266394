import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Spinner } from '../../../shared/spinner/Spinner';
import styles from '../DocumentName.module.scss';
import { fetchAllDocumentGroupsStarted, fetchAllDocumentNamesStarted, fetchAvailableDocumentNamesStarted, getAllClientDocumentGroups, getAvailableDocumentNames, getCreateDocumentGroupModalOpen, getLoadingDocumentGroups, toggleCreateDocumentGroupModal } from '../store';
import { CreateDocumentGroupModal } from './CreateDocumentGroupModal';
import { DocumentGroupTile } from './DocumentGroupTile';

export const DocumentGroups: React.FC = () => {
    const [, screenHeight] = useWindowResize();
    const resultsHeight = useMemo(() => screenHeight - 240, [screenHeight]);

    useFetchStarted([fetchAvailableDocumentNamesStarted(), fetchAllDocumentNamesStarted(), fetchAllDocumentGroupsStarted()]);

    const modalOpen = useAppSelector(getCreateDocumentGroupModalOpen);
    const allDocumentGroups = useAppSelector(getAllClientDocumentGroups);
    const isLoading = useAppSelector(getLoadingDocumentGroups);
    const availableDocumentNames = useAppSelector(getAvailableDocumentNames);

    const dispatch = useAppDispatch();

    const openModal = useCallback((id?: number) => dispatch(toggleCreateDocumentGroupModal(true, id)), [dispatch]);
    const closeModal = useCallback(() => dispatch(toggleCreateDocumentGroupModal(false)), [dispatch]);
    const getDocumentNames = useCallback((documentNameIds: number[]) => availableDocumentNames.filter(doc => documentNameIds.includes(doc.documentNameId!)).map(({ documentName }) => documentName), [availableDocumentNames]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className={styles.myDocumentGroupsWrapper} data-testid='my-document-groups-wrapper'>
            <div className={styles.myDocumentGroupsHeaderWrapper}>
                <div className={styles.myDocumentGroupsHeader} data-testid='my-document-groups-header'>My Document Groups</div>
                <PlusButton onClick={() => openModal()} />
            </div>
            <div className={styles.myDocumentGroupsResultsWrapper} data-testid='my-document-groups-results-wrapper' style={{ height: `${resultsHeight}px` }}>
                {allDocumentGroups.length === 0 ? <div className={styles.emptyDocumentGroupMessage}>Click + to add a document group</div> : (
                    <Scrollable>
                        <div className={styles.tilesWrapper}>
                            <div className={styles.tilesColumnWrapper}>
                                {allDocumentGroups.map(({ documentNameIds, clientDocumentGroupId, groupName }) => (
                                    <DocumentGroupTile
                                        key={clientDocumentGroupId}
                                        groupName={groupName}
                                        configure={() => openModal(clientDocumentGroupId!)}
                                        documentNames={getDocumentNames(documentNameIds)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Scrollable>
                )}
            </div>
            <CreateDocumentGroupModal isOpen={modalOpen} closeModal={closeModal} />
        </div>
    );
};
