import React, { useCallback, MouseEvent, useMemo } from 'react';
import classnames from 'classnames';
import { isNull, noop } from 'lodash/fp';

import styles from './PlaybookReadOnly.module.scss';
import { LinkedPlaybookProvision, PlaybookProvision, PlaybookQueryDB } from '../../../admin/playbook/store';
import { OverflowTooltip } from '../../tooltip';
import { WYSIWYG } from '../../wysiwyg/WYSIWYG';
import { QueryMarker } from '../query/QueryMarker';
import { ReadOnlyDeviations } from './ReadOnlyDeviations';
import { CaretDown, CaretSide } from '../../icons';
import { Icon } from '../../icon/Icon';
import { ChangedContent } from '../../../playbook/store';
import { SuggestedChangesMarker } from './SuggestedChangesMarker';
import { LinkedProvisionButton } from '../provision-link/LinkedProvisionButton';

const { white } = styles;

interface ReadOnlyProvisionProps {
    section: PlaybookProvision;
    readOnly?: boolean;
    selectedSectionId?: string;
    setSelectedSectionId?: (sectionId: string) => void;
    openPlaybookActionModal?: (e: MouseEvent<HTMLDivElement>, sectionId: string) => void;
    getSelectedText?: (selectedText: string) => void;
    isTableView: boolean;
    queries?: PlaybookQueryDB[];
    openQuery?: (playbookQueryId: number) => void;
    playbookDefinitionId?: number | null;
    getPlaybookInstanceVersion?: (playbookDefinitionId: number) => void;
    deviationOpen?: boolean;
    toggleDeviation?: (sectionId: string) => void;
    showQueries?: boolean;
    suggestedChangeContent?: ChangedContent;
    showValidFor?: boolean;
    showProvisionLinks?: boolean;
    provisionLinkModalOpen?: string | null;
    toggleLinkedProvisionModal?: (sectionId: string) => void;
    openLinkToProvision?: (link: LinkedPlaybookProvision) => void;
    testId?: string;
}

export const ReadOnlyProvision: React.FC<ReadOnlyProvisionProps> = ({
    section,
    selectedSectionId,
    setSelectedSectionId = noop,
    readOnly = true,
    getSelectedText,
    isTableView,
    openPlaybookActionModal = noop,
    queries,
    openQuery = noop,
    playbookDefinitionId,
    getPlaybookInstanceVersion = noop,
    deviationOpen = false,
    toggleDeviation = noop,
    showQueries = true,
    suggestedChangeContent,
    showValidFor = false,
    showProvisionLinks = false,
    provisionLinkModalOpen = null,
    toggleLinkedProvisionModal = noop,
    openLinkToProvision = noop,
    testId = 'playbook-read-only-provision'
}) => {
    const { title, sectionId, provisionContent, provisionTitle, backgroundContent, backgroundTitle, relevantStakeholders, preferredTerms, deviations, linkedProvisions } = section;

    const deviationOpenIcon = useMemo(() => deviationOpen ? CaretDown : CaretSide, [deviationOpen]);

    const stakeholders = relevantStakeholders.map(({ name }) => name).join(', ');

    const onSectionClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
        setSelectedSectionId(sectionId);
        openPlaybookActionModal(e, sectionId);
    }, [setSelectedSectionId, openPlaybookActionModal, sectionId]);

    const showPreferredTerms = useMemo(() => !isNull(preferredTerms), [preferredTerms]);
    const showDeviations = useMemo(() => deviations.length > 1 || deviations[0].topic !== '' || (!isNull(deviations[0].variations) || !isNull(deviations[0].background) || deviations[0].nid !== '' || !isNull(deviations[0].approvalDate)), [deviations]);
    const showSuggestedChangeMarker = useCallback((key: keyof PlaybookProvision) => !!suggestedChangeContent?.provisionChanges.includes(key), [suggestedChangeContent]);
    const sectionTitle = useMemo(() => title.filter(item => !!item).join(': '), [title]);
    const toggleProvisionModal = useCallback(() => toggleLinkedProvisionModal(sectionId), [toggleLinkedProvisionModal, sectionId]);
    const provisionLinkOpen = useMemo(() => !isNull(provisionLinkModalOpen) && provisionLinkModalOpen === sectionId, [provisionLinkModalOpen, sectionId]);

    return (
        <div className={styles.readOnlySection} key={sectionId} id={sectionId} onDoubleClick={onSectionClick} data-testid={`${testId}-wrapper`}>
            {showQueries &&
                <div className={styles.queriesWrapper}>
                    {queries && queries.map(query => (
                        <QueryMarker
                            key={query.playbookQueryId}
                            openQuery={() => openQuery(query.playbookQueryId)}
                            query={query}
                            currentPlaybookDefinitionId={playbookDefinitionId!}
                            getPlaybookInstanceVersion={getPlaybookInstanceVersion}
                            testId={`${testId}-query-marker-${query.playbookQueryId}`}
                        />
                    ))}
                </div>
            }
            <div className={styles.readOnlySectionTitleWrapper} data-testid={`${testId}-title-wrapper`}>
                <div className={styles.readOnlySectionTitle}>
                    <OverflowTooltip className={styles.readOnlySectionTitleOverflow} overlayText={sectionTitle} testId={`${testId}-title`} />
                    {showSuggestedChangeMarker('title') && <SuggestedChangesMarker testId={`${testId}-title`} />}
                </div>
                {showProvisionLinks && linkedProvisions.length > 0 &&
                    <div className={styles.readOnlySectionLinksWrapper} data-testid={`${testId}-links-wrapper`}>
                        <LinkedProvisionButton
                            isOpen={provisionLinkOpen}
                            linkedProvisions={linkedProvisions}
                            toggleLinkedProvisionModal={toggleProvisionModal}
                            openLinkToProvision={openLinkToProvision}
                        />
                    </div>
                }
            </div>
            {stakeholders.length > 0 &&
                <div className={styles.readOnlyStakeholdersWrapper} data-testid={`${testId}-stakeholders-wrapper`}>
                    <div className={styles.readOnlyStakeholdersTitle} data-testid={`${testId}-stakeholders-title`}>Relevant Stakeholders:</div>
                    <OverflowTooltip className={styles.readOnlyStakeholdersInput} overlayText={stakeholders} testId={`${testId}-stakeholders`} />
                </div>
            }
            <div className={classnames(styles.readOnlySectionList, { [styles.readOnlySectionTable]: isTableView })}>
                <div className={styles.readOnlyProvisionWrapper} style={{ borderRadius: isTableView ? '5px 0 0 5px' : '5px' }} data-testid={`${testId}-standard-provision-wrapper`}>
                    <div className={styles.readOnlyProvisionTitleWrapper} style={{ borderRight: isTableView ? `1px solid ${white}` : 'none' }}>
                        <div className={styles.readOnlyProvisionTitleMarkerWrapper}>
                            <div className={styles.readOnlyProvisionTitle} data-testid={`${testId}-standard-provision-title`}>Standard Provision: </div>
                            <OverflowTooltip className={styles.readOnlyProvisionTitleInput} overlayText={provisionTitle} testId={`${testId}-standard-provision`} />
                        </div>
                        {showSuggestedChangeMarker('provisionTitle') && <SuggestedChangesMarker testId={`${testId}-provision-title`} />}
                    </div>
                    <div className={styles.readOnlyWysiwygWrapper} data-testid={`${testId}-standard-provision-content`}>
                        <WYSIWYG
                            content={provisionContent}
                            updateContent={noop}
                            showBorder={false}
                            showWrapperBorder={false}
                            height='fit-content'
                            minHeight='fit-content'
                            maxHeight='fit-content'
                            disabled={readOnly}
                            toolbarHidden
                            isSelected={sectionId === selectedSectionId}
                            getSelectedText={getSelectedText}
                            isReadOnly={readOnly}
                        />
                        {showSuggestedChangeMarker('provisionContent') && <SuggestedChangesMarker testId={`${testId}-provision-content`} />}
                    </div>
                </div>
                <div className={styles.readOnlyProvisionWrapper} style={{ borderRadius: isTableView ? '0 5px 5px 0' : '5px' }} data-testid={`${testId}-background-wrapper`}>
                    <div className={styles.readOnlyProvisionTitleWrapper} style={{ borderLeft: isTableView ? `1px solid ${white}` : 'none' }}>
                        <div className={styles.readOnlyProvisionTitleMarkerWrapper}>
                            <div className={styles.readOnlyProvisionTitle} data-testid={`${testId}-background-title`}>Discussion and Background: </div>
                            <OverflowTooltip className={styles.readOnlyProvisionTitleInput} overlayText={backgroundTitle} testId={`${testId}-background`} />
                        </div>
                        {showSuggestedChangeMarker('backgroundTitle') && <SuggestedChangesMarker testId={`${testId}-background-title`} />}
                    </div>
                    <div className={styles.readOnlyWysiwygWrapper} data-testid={`${testId}-background-content`}>
                        <WYSIWYG
                            content={backgroundContent}
                            updateContent={noop}
                            showBorder={false}
                            showWrapperBorder={false}
                            height='fit-content'
                            minHeight='fit-content'
                            maxHeight='fit-content'
                            disabled={readOnly}
                            toolbarHidden
                            isSelected={sectionId === selectedSectionId}
                            getSelectedText={getSelectedText}
                            isReadOnly={readOnly}
                        />
                        {showSuggestedChangeMarker('backgroundContent') && <SuggestedChangesMarker testId={`${testId}-background-content`} />}
                    </div>
                </div>
            </div>
            {showPreferredTerms &&
                <div className={styles.readOnlyPreferredTerms} data-testid={`${testId}-preferred-terms-wrapper`}>
                    <div className={styles.readOnlyPreferredTermsTitleWrapper}>
                        <div className={styles.readOnlyPreferredTermsTitle} data-testid={`${testId}-preferred-terms-title`}>Preferred Terms: </div>
                    </div>
                    <div className={styles.readOnlyWysiwygWrapper} data-testid={`${testId}-preferred-terms-content`}>
                        <WYSIWYG
                            content={preferredTerms}
                            updateContent={noop}
                            showBorder={false}
                            showWrapperBorder={false}
                            height='fit-content'
                            minHeight='fit-content'
                            maxHeight='fit-content'
                            disabled={readOnly}
                            toolbarHidden
                            isSelected={sectionId === selectedSectionId}
                            getSelectedText={getSelectedText}
                            isReadOnly={readOnly}
                        />
                        {showSuggestedChangeMarker('preferredTerms') && <SuggestedChangesMarker testId={`${testId}-preferred-terms-content`} />}
                    </div>
                </div>
            }
            {showDeviations &&
                <div className={styles.readOnlyDeviationsWrapper} data-testid={`${testId}-deviations-wrapper`}>
                    <div className={styles.readOnlyDeviationTitleWrapper}>
                        <div className={styles.readOnlyDeviationCollapseTitleWrapper}>
                            <div onClick={() => toggleDeviation(sectionId)} className={styles.readOnlyDeviationsCollapseIcon} data-testid={`${testId}-deviations-toggle-icon`}>
                                <Icon icon={deviationOpenIcon} fontSize={16} />
                            </div>
                            <div className={styles.readOnlyDeviationTitle} data-testid={`${testId}-deviations-title`}>Amendments/ Deviations: </div>
                        </div>
                        {showSuggestedChangeMarker('deviations') && <SuggestedChangesMarker testId={`${testId}-deviations-wrapper`} />}
                    </div>
                    {deviationOpen &&
                        <ReadOnlyDeviations
                            deviations={deviations}
                            readOnly={readOnly}
                            sectionId={sectionId}
                            selectedSectionId={selectedSectionId}
                            getSelectedText={getSelectedText}
                            deviationChangedContent={suggestedChangeContent?.deviationChanges}
                            showValidFor={showValidFor}
                            testId={`${testId}`}
                        />
                    }
                </div>
            }
        </div>
    );
};
