import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { PlusButton } from '../../shared/button/PlusButton';
import { Icon } from '../../shared/icon/Icon';
import { CheckList, Delete } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Text } from '../../shared/text/Text';
import styles from './DropdownLists.module.scss';
import { PreviewModal } from './PreviewModal';
import { addListItem, getDropdownList, getPreviewModalOpen, publishDropdownListStarted, removeListItem, togglePreviewModal, updateDropdownList, updateListItem } from './store';
import { DraggableItem } from '../../shared/drag-n-drop/shared';
import { DragDrop } from '../../shared/drag-n-drop/DragDrop';

interface DropdownListsModalProps {
    closeModal: () => void
    isOpen: boolean;
    confirmDisabled?: boolean;
}

export const DropdownListModal: React.FC<DropdownListsModalProps> = ({ isOpen, closeModal, confirmDisabled = false }) => {
    const dispatch = useAppDispatch();

    const previewOpen = useAppSelector(getPreviewModalOpen);

    const dropdownList = useAppSelector(getDropdownList);
    const { name, description, options } = dropdownList;
    const previewDisabled = options && !options.length || false;
    const confirmLabel = dropdownList.dropdownListId ? 'Update' : 'Create';
    const modalHeader = dropdownList.dropdownListId ? 'Update Dropdown List' : 'Create a New Dropdown List';
    const previewDisabledTooltip = !dropdownList.options.length ? ['Add list items to this dropdown list'] : [];

    const updateListValue = useCallback((key: string, value: string | string[]) => dispatch(updateDropdownList(key, value)), [dispatch]);
    const addItem = useCallback(() => dispatch(addListItem()), [dispatch]);
    const deleteItem = useCallback((index: number) => dispatch(removeListItem(index)), [dispatch]);
    const updateItemName = useCallback((index: number, value: string) => dispatch(updateListItem(index, value)), [dispatch]);
    const previewToggle = useCallback((isOpen: boolean) => dispatch(togglePreviewModal(isOpen)), [dispatch]);
    const openPreview = useCallback(() => previewToggle(true), [previewToggle]);
    const closePreview = useCallback(() => previewToggle(false), [previewToggle]);
    const createDropdownList = useCallback(() => dispatch(publishDropdownListStarted()), [dispatch]);

    const listOptions = useMemo(() => options.map((value, index) => ({ id: index.toString(), label: value })), [options]);

    const updateList = (list: DraggableItem[]) => {
        const options = list.map(({ label }) => label);
        updateListValue('options', options);
    };

    const getChildElement = useCallback((childId: string, index: number) => {
        const listOption = listOptions.find(({ id }) => id === childId);
        if (listOption) {
            return (
                <div className={styles.listItemWrapper}>
                    <input
                        className={styles.listItemInput}
                        value={listOption.label}
                        onChange={e => updateItemName(index, e.target.value)}
                        data-testid={`dropdown-list-item-input-${index}`}
                    />
                    <button className={styles.deleteItem} onClick={() => deleteItem(index)} data-testid={`dropdown-list-item-delete-${index}`}>
                        <Icon icon={Delete} fontSize={18} />
                    </button>
                </div>
            );
        }
        return null;
    }, [listOptions, deleteItem, updateItemName]);

    const confirmDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!name.length) {
            disabledArray.push('Dropdown lists require a title');
        }
        if (!dropdownList.options.length) {
            disabledArray.push('Dropdown list requires a minimum of one item');
        }
        return disabledArray;
    }, [name, dropdownList]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel={confirmLabel}
            confirmDisabled={confirmDisabled}
            confirm={createDropdownList}
            showPreview={true}
            previewLabel='Show Preview'
            openPreview={openPreview}
            previewDisabled={previewDisabled}
            testId='dropdown-list-modal'
            confirmDisabledTooltip={confirmDisabledTooltip}
            previewDisabledTooltip={previewDisabledTooltip}
        >
            <div className={styles.createDropdownListWrapper} data-testid='dropdown-list-modal-wrapper'>
                <ModalHeader
                    testId='dropdown-list-modal'
                    label={modalHeader}
                    icon={CheckList}
                />
                <Text
                    onChange={e => updateListValue('name', e.target.value)}
                    maxLength={128}
                    value={name}
                    testId='dropdown-list-modal-title'
                    label='List Title'
                />
                <div>
                    <div className={styles.dropdownListInputLabel} data-testid='dropdown-list-modal-description-label'>List Description</div>
                    <textarea
                        className={styles.dropdownListTextArea}
                        name='description'
                        onChange={e => updateListValue(e.target.name, e.target.value)}
                        maxLength={256}
                        rows={2}
                        style={{ resize: 'none' }}
                        data-testid='dropdown-list-modal-description-input'
                        value={description}
                    />
                </div>
                <div>
                    <div className={styles.dropdownListLabelContainer}>
                        <div data-testid='dropdown-list-builder-label'>List Items</div>
                        <PlusButton onClick={addItem} fontSize={22} testId='dropdown-list-builder-plus' />
                    </div>
                    <div className={styles.dropdownListBuilder} data-testid='dropdown-list-builder-wrapper'>
                        <DragDrop getChildElement={getChildElement} list={listOptions} listId='dropdown-list' updateList={updateList} />
                    </div>
                    <PreviewModal
                        previewOpen={previewOpen}
                        closePreview={closePreview}
                        options={options.filter(item => item.length)}
                        name={name}
                        description={description}
                    />
                </div>
            </div>
        </ConfirmationModal>
    );
};
