import React from 'react';
import { RawDraftContentState } from 'draft-js';
import { noop } from 'lodash/fp';

import { WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import styles from './Fields.module.scss';

const { white } = styles;

interface LabelFieldProps {
    value: RawDraftContentState | null;
}

export const LabelField: React.FC<LabelFieldProps> = ({ value }) => (
    <div className={styles.labelWrapper} data-testid='definition-label-wrapper'>
        <WYSIWYG
            content={value}
            updateContent={noop}
            disabled
            borderColor={white}
            showBorder={false}
            toolbarHidden
            height='fit-content'
            maxHeight='fit-content'
            minHeight='fit-content'
            placeholder='Label...'
            padding='0px'
            editorPadding='0px'
        />
    </div>
);
