import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { RawDraftContentState } from 'draft-js';
import { isNull } from 'lodash/fp';

import styles from '../../shared/playbook/query/Query.module.scss';
import { Form } from '../../shared/icons';
import { Button } from '../../shared/button/Button';
import { WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import { Toggle } from '../../shared/toggle';
import { PlaybookQueryDB, PlaybookMessage } from './store';
import { useAppSelector } from '../../../hooks/react-redux';
import { getUser } from '../../auth/login/store';
import { Conversation } from '../../shared/playbook/conversation/Conversation';
import { OverflowModalHeader } from '../../shared/modal/OverflowModalHeader';

export interface AdminQueryModalProps {
    isOpen: boolean;
    query: PlaybookQueryDB | null;
    showSpinner: boolean;
    closeModal: () => void;
    sendResponse: () => void;
    updateResponse: (rawValue: RawDraftContentState) => void;
    queryResponse: PlaybookMessage | null;
    queryDisabled: boolean;
    modalTitle: string;
    toggleEmailReply: () => void;
    emailReply: boolean;
    toggleResolved: () => void;
    markedAsResolved: boolean;
    goToDefinition: (playbookDefinitionId: number, sectionId: string) => void;
    testId?: string;
}

export const AdminQueryModal: React.FC<AdminQueryModalProps> = ({
    isOpen,
    query,
    showSpinner,
    closeModal,
    sendResponse,
    updateResponse,
    queryResponse = null,
    queryDisabled = false,
    modalTitle,
    toggleEmailReply,
    emailReply = false,
    toggleResolved,
    markedAsResolved = false,
    goToDefinition,
    testId = 'admin-playbook'
}) => {

    const user = useAppSelector(getUser);
    const queryAlreadyResolved = query?.resolved === 1;
    const resolveAndSend = !queryAlreadyResolved && markedAsResolved;

    const responseLabel = useMemo(() => resolveAndSend ? 'Resolve and Send' : 'Respond', [resolveAndSend]);
    const response = isNull(queryResponse) ? null : queryResponse.content;

    const adminContent = useCallback((sectionId: string) => {
        if (!queryDisabled) {
            return (
                <>
                    <div data-testid={`${testId}-query-modal-${sectionId}-response-input`}>
                        <WYSIWYG
                            content={response}
                            updateContent={value => updateResponse(value)}
                            showBorder={false}
                            height={'calc(30vh - 40px)'}
                            maxHeight={'calc(30vh - 40px)'}
                            showWrapperBorder
                        />
                    </div>
                    <div className={styles.adminQueryToggleWrapper} data-testid={`${testId}-query-modal-${sectionId}-email-reply-wrapper`}>
                        <div className={styles.queryToggleWrapper}>
                            <div className={styles.queryToggleLabel} data-testid={`${testId}-query-modal-${sectionId}-email-reply-label`}>
                                Send Email Reply (Optional)
                            </div>
                            <Toggle
                                checked={emailReply}
                                onChange={toggleEmailReply}
                                testId={`${testId}-query-modal-${sectionId}-email-reply`}
                            />
                        </div>
                        {!queryAlreadyResolved &&
                            <div className={styles.queryToggleWrapper} data-testid={`${testId}-query-modal-${sectionId}-resolve-wrapper`}>
                                <div className={styles.queryToggleLabel} data-testid={`${testId}-query-modal-${sectionId}-resolve-label`}>
                                    Resolve Query?
                                </div>
                                <Toggle
                                    checked={markedAsResolved}
                                    onChange={toggleResolved}
                                    disabled={queryAlreadyResolved || queryDisabled}
                                    testId={`${testId}-query-modal-${sectionId}-resolve`}
                                />
                            </div>
                        }
                    </div>
                </>
            );
        }
        return;
    }, [queryDisabled, response, updateResponse, toggleEmailReply, emailReply, markedAsResolved, toggleResolved, queryAlreadyResolved, testId]);

    if (isNull(query)) {
        return null;
    }
    const { queryConversation, playbookDefinitionId, sectionId } = query;

    const conversation = queryConversation.filter(message => !isNull(message));

    return (
        <Modal
            isOpen={isOpen}
            className={styles.queryModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', backgroundColor: 'transparent', zIndex: 10 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
            testId={testId}
        >
            <div className={styles.queryModalWrapper} data-testid={`${testId}-query-modal-${sectionId}-wrapper`}>
                <OverflowModalHeader label={modalTitle} icon={Form} testId={`${testId}-query-modal-${sectionId}`} />
                <Conversation
                    conversation={conversation}
                    showSpinner={showSpinner}
                    currentUserId={user!.userId!}
                    testId={`${testId}-query-modal-${sectionId}`}
                />
                {adminContent(sectionId)}
                <div className={styles.queryButtonWrapper}>
                    <Button onClick={closeModal} label='Close' testId={`${testId}-query-modal-${sectionId}-close`} />
                    {!queryDisabled && <Button onClick={sendResponse} disabled={showSpinner} label={responseLabel} testId={`${testId}-query-modal-${sectionId}-respond`} />}
                    {queryDisabled && <Button onClick={() => goToDefinition(playbookDefinitionId, sectionId)} disabled={showSpinner} label='Go To Version' testId={`${testId}-query-modal-${sectionId}-go-to-version`} />}
                </div>
            </div>
        </Modal>
    );
};
