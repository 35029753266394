import React from 'react';

import { formatDate } from '../../../utils/luxon';
import { Icon } from '../../shared/icon/Icon';
import { Tick } from '../../shared/icons';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { OverflowTooltip } from '../../shared/tooltip';
import { AnnexDefinitionDB } from '../store';
import { AnnexCell } from './AnnexCell';
import { AnnexHeader } from './AnnexHeader';
import styles from './AnnexPreview.module.scss';

const { green } = styles;

interface AnnexPreviewTableProps {
    annexDefinition: AnnexDefinitionDB;
    isSelected: boolean;
}

export const AnnexPreviewTable: React.FC<AnnexPreviewTableProps> = ({ annexDefinition, isSelected }) => {
    const annexDefinitionTitle = annexDefinition.annexDefinitionTitle ? annexDefinition.annexDefinitionTitle : `Agency Annex-${annexDefinition.annexDefinitionId}`;

    return (
        <>
            <div className={styles.topSectionWrapper}>
                <div className={styles.annexName}>
                    {isSelected && <Icon icon={Tick} color={green} />}
                    <OverflowTooltip overlayText={annexDefinitionTitle} />
                </div>
                <div className={styles.userDateWrapper}>
                    <div className={styles.userDetails}>{annexDefinition.username}</div>
                    <div className={styles.userDetails}>{formatDate(annexDefinition.createdDate)}</div>
                </div>
            </div>
            <Scrollable>
                <div className={styles.annexWrapper}>
                    {annexDefinition.datasetFields.map((field, index) => {
                        const isLastCell = index === annexDefinition.datasetFields.length - 1;
                        const { aliasLabel, label, id, type, description, refLabel, settings, isCustom } = field;
                        return (
                            <div key={id}>
                                <AnnexHeader
                                    type={type}
                                    label={label}
                                    aliasLabel={aliasLabel}
                                    isLastCell={isLastCell}
                                    description={description}
                                    showRef={settings.showRef}
                                    refLabel={refLabel}
                                    isCustom={isCustom}
                                />
                                <AnnexCell index={index} field={field} isLastCell={isLastCell} />
                            </div>
                        );
                    })}
                </div>
            </Scrollable>
        </>
    );
};
