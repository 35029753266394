import { flow, isEqual, isNull, set, unset } from 'lodash/fp';
import { Reducer } from 'redux';
import { v4 } from 'uuid';

import { formatDate } from '../../../../utils/luxon';
import { createNewAnnexRow, createNewTableRow, getNewCheckboxField, updateLastModified } from '../utils';
import { AnnexInstance, AnnexInstanceField, DatasetInstanceActionTypes, DatasetInstanceState, FormInstanceFields, GroupInstanceField, Hierarchy, Instance, SingleInstanceField, TableDatasetInstance } from './types';
import { isAnnexInstance, isFormDatasetInstance } from './typeAssertions';

const getDatasetInstance = (currentInstances: Instance[], datasetId: number, fieldId: string) => currentInstances.find(({ instance, parentFieldId }) => (isNull(instance.annexDefinitionId) && instance.datasetId === datasetId || !isNull(instance.annexDefinitionId) && instance.annexDefinitionId === datasetId) && fieldId === parentFieldId)!;

export const INITIAL_STATE: DatasetInstanceState = {
    datasetDefinition: null,
    ecsDatasetId: null,
    agencyLinkedEntities: [],
    parentDatasetId: null,
    currentInstances: [],
    savedInstances: [],
    timeline: null,
    hierarchy: [],
    annexFieldIds: [],
    openFieldsAndSections: [],
    error: null,
    isEditing: false,
    isUpdating: false,
    isSaving: false,
    isLoading: false,
    updatedInstanceModalOpen: false,
    confirmSaveModalOpen: false,
    linkedDocumentModalOpen: false,
    wizardFieldOpen: null,
    modalInstanceOpen: false,
    modalInstanceParentDetails: null,
    modalInstanceLoading: false,
    modalInstanceSaving: false,
    modalInstanceUpdated: false,
    currentModalInstance: null,
    savedModalInstance: null,
    hiddenFields: {},
    secondaryDocumentToDelete: null,
    isDeleting: false,
    showLegacy: false,
    mlData: null,
    mlDataModalOpen: false,
    riskToleranceForDefinitions: [],
    annexConfigurationModalOpen: false,
    isUpdatingInstanceAnnexDefinitions: false,
    searchFieldSection: null,
    searchFuzzyMatches: [],
    fieldSectionSearch: '',
    allSearchFieldSections: []
};

const updateHierarchy = (hierarchy: Hierarchy[], datasetId: number, fieldId: string, callback: (dataset: Hierarchy) => Hierarchy) => hierarchy.map(dataset => (parseInt(dataset.datasetId) === datasetId && dataset.fieldId === fieldId) ? callback(dataset) : dataset);
const updateInstances = (currentInstances: Instance[], datasetId: number, fieldId: string, callback: (instance: Instance) => Instance) => currentInstances.map(({ instance, parentFieldId }) => ((isNull(instance.annexDefinitionId) && instance.datasetId === datasetId || !isNull(instance.annexDefinitionId) && instance.annexDefinitionId === datasetId) && fieldId === parentFieldId) ? callback({ instance, parentFieldId }) : { instance, parentFieldId });
const removeInstanceRow = (datasetInstance: Instance, rowId: string, updatedRows: string[]): Instance => flow(
    unset(`instance.datasetFields[${rowId}]`),
    set('instance.datasetRows', updatedRows)
)(datasetInstance);

export const datasetInstanceReducer: Reducer<DatasetInstanceState> = (state = INITIAL_STATE, action): DatasetInstanceState => {
    switch (action.type) {
        case DatasetInstanceActionTypes.OPEN_DATASET_DEFINITION_STARTED:
        case DatasetInstanceActionTypes.OPEN_DATASET_INSTANCE_BY_ID:
            return set('isLoading', true, state);
        case DatasetInstanceActionTypes.OPEN_DATASET_DEFINITION_SUCCESSFUL:
            return flow(
                set('datasetDefinition', action.payload),
                set('isLoading', false)
            )(state);
        case DatasetInstanceActionTypes.OPEN_DATASET_DEFINITION_FAILED:
            return flow(
                set('error', action.payload),
                set('isLoading', false)
            )(state);
        case DatasetInstanceActionTypes.UPDATE_FIELD_VALUE: {
            const { index, sectionId, value, rowId, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            if (isFormDatasetInstance(instance)) {
                const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}][${index}].value`, value));
                return set('currentInstances', updatedInstances, state);
            }
            if (!isNull(state.wizardFieldOpen)) {
                const wizardValue = instance.datasetFields[`${state.wizardFieldOpen.rowId}`][state.wizardFieldOpen.index].value as string[];
                let wizardFieldValue = value;
                if (value instanceof Date) {
                    wizardFieldValue = formatDate(value);
                }
                wizardValue.splice(index, 1, wizardFieldValue);
                const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, flow(
                    set(`instance.datasetFields[${state.wizardFieldOpen.rowId}][${state.wizardFieldOpen.index}].linkedFields[${index}].value`, value),
                    set(`instance.datasetFields[${state.wizardFieldOpen.rowId}][${state.wizardFieldOpen.index}].value`, wizardValue),
                ));
                return set('currentInstances', updatedInstances, state);
            }
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${rowId}][${index}].value`, value));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.UPDATE_GROUP_FIELD_VALUE: {
            const { groupIndex, index, sectionId, value, datasetId, parentFieldId } = action.payload;
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}][${groupIndex}].children[${index}].value`, value));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.USER_CORRECT_AI_FIELD_VALUE: {
            const { index, sectionId, value, rowId, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            if (isFormDatasetInstance(instance)) {
                const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, flow(
                    set(`instance.datasetFields[${sectionId}][${index}].value`, value),
                    set(`instance.datasetFields[${sectionId}][${index}].settings.aiModifiedUserCorrected`, true)
                ));
                return set('currentInstances', updatedInstances, state);
            }
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, flow(
                set(`instance.datasetFields[${rowId}][${index}].value`, value),
                set(`instance.datasetFields[${rowId}][${index}].settings.aiModifiedUserCorrected`, true)
            ));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.USER_CORRECT_AI_GROUP_FIELD_VALUE: {
            const { groupIndex, index, sectionId, value, datasetId, parentFieldId } = action.payload;
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, flow(
                set(`instance.datasetFields[${sectionId}][${groupIndex}].children[${index}].value`, value),
                set(`instance.datasetFields[${sectionId}][${groupIndex}].children[${index}].settings.aiModifiedUserCorrected`, true)
            ));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.ADD_FIELD_CUSTOM_CHILD: {
            const { sectionId, datasetId, parentFieldId, groupIndex } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const customChildren = (instance.datasetFields[sectionId][groupIndex] as GroupInstanceField).customChildren || [];
            const customFieldId = v4();
            const newCheckboxField = getNewCheckboxField(customFieldId);
            const updatedCustomChildren = [...customChildren, newCheckboxField];
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}][${groupIndex}].customChildren`, updatedCustomChildren));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.UPDATE_FIELD_CUSTOM_CHILD: {
            const { groupIndex, index, sectionId, value, datasetId, parentFieldId, property } = action.payload;
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}][${groupIndex}].customChildren[${index}][${property}]`, value));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.SET_FIELDS_UPDATED_VALUE: {
            const { fieldsUpdated, datasetId, parentFieldId } = action.payload;
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set('instance.fieldsUpdated', fieldsUpdated));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.CONFIRM_ML_DATASET_INSTANCE_STARTED:
            return set('isSaving', true, state);
        case DatasetInstanceActionTypes.UPSERT_DATASET_INSTANCE_STARTED:
            return set('isSaving', true, state);
        case DatasetInstanceActionTypes.UPSERT_DATASET_INSTANCE_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('isEditing', false),
                set('isUpdating', false),
                set('savedInstances', state.currentInstances)
            )(state);
        case DatasetInstanceActionTypes.UPSERT_DATASET_INSTANCE_FAILED:
            return flow(
                set('isSaving', false),
                set('isEditing', false),
                set('isUpdating', false),
                set('error', action.payload)
            )(state);
        case DatasetInstanceActionTypes.CONFIRM_ML_DATASET_INSTANCE_FAILED:
            return flow(
                set('isSaving', false),
                set('error', action.payload)
            )(state);
        case DatasetInstanceActionTypes.EDIT_DATASET_INSTANCE:
            return set('isEditing', action.payload, state);
        case DatasetInstanceActionTypes.UPDATE_DATASET_INSTANCE:
            return set('isUpdating', action.payload, state);
        case DatasetInstanceActionTypes.TOGGLE_UPDATED_INSTANCE_MODAL:
            return set('updatedInstanceModalOpen', action.payload, state);
        case DatasetInstanceActionTypes.ADD_TABLE_DATASET_ROW: {
            const { datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const newRowId = v4();
            let newRow: AnnexInstanceField[] | SingleInstanceField[] = [];
            if (isAnnexInstance(instance)) {
                newRow = createNewAnnexRow(Object.values(instance.datasetFields)[0]);
            } else {
                newRow = createNewTableRow(Object.values((instance as TableDatasetInstance).datasetFields)[0]);
            }
            const datasetFields = { ...instance!.datasetFields, [newRowId]: newRow };
            const datasetRows = (instance as TableDatasetInstance | AnnexInstance).datasetRows.concat(newRowId);
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, flow(
                set('instance.datasetFields', datasetFields),
                set('instance.datasetRows', datasetRows)
            ));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.DUPLICATE_TABLE_DATASET_ROW: {
            const { datasetId, parentFieldId, rowId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const newRowId = v4();
            let newRow = instance.datasetFields[rowId];
            const datasetFields = { ...instance!.datasetFields, [newRowId]: newRow };
            const datasetRows = (instance as TableDatasetInstance | AnnexInstance).datasetRows.concat(newRowId);
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, flow(
                set('instance.datasetFields', datasetFields),
                set('instance.datasetRows', datasetRows)
            ));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.REMOVE_TABLE_DATASET_ROW: {
            const { datasetId, rowId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const updatedRows = (instance as TableDatasetInstance).datasetRows.filter(id => id !== rowId);
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, instance => removeInstanceRow(instance, rowId, updatedRows));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.RESET_DATASET_INSTANCE:
            return INITIAL_STATE;
        case DatasetInstanceActionTypes.TOGGLE_SAVE_INSTANCE_MODAL:
            return set('confirmSaveModalOpen', action.payload, state);
        case DatasetInstanceActionTypes.TOGGLE_LINKED_DOCUMENT_MODAL:
            return set('linkedDocumentModalOpen', action.payload, state);
        case DatasetInstanceActionTypes.TOGGLE_CLAUSE_MODAL_OPEN: {
            const { index, value, sectionId, rowId, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const propertyToUpdate = isFormDatasetInstance(instance)
                ? `instance.datasetFields[${sectionId}][${index}].settings.clauseOpen`
                : `instance.datasetFields[${rowId}][${index}].settings.clauseOpen`;
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(propertyToUpdate, value));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.TOGGLE_GROUP_CLAUSE_MODAL_OPEN: {
            const { index, value, sectionId, groupIndex, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            if (isFormDatasetInstance(instance)) {
                const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}][${groupIndex}].children[${index}].settings.clauseOpen`, value));
                return set('currentInstances', updatedInstances, state);
            }
            return state;
        }
        case DatasetInstanceActionTypes.UPDATE_CLAUSE_LABEL: {
            const { index, sectionId, value, rowId, datasetId, parentFieldId } = action.payload;
            const valueToUpdate = value.length ? value : undefined;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const propertyToUpdate = isFormDatasetInstance(instance)
                ? `instance.datasetFields[${sectionId}][${index}].clauseLabel`
                : `instance.datasetFields[${rowId}][${index}].clauseLabel`;
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(propertyToUpdate, valueToUpdate));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.UPDATE_GROUP_CLAUSE_LABEL: {
            const { groupIndex, index, sectionId, value, datasetId, parentFieldId } = action.payload;
            const valueToUpdate = value.length ? value : undefined;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            if (isFormDatasetInstance(instance)) {
                const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}][${groupIndex}].children[${index}].clauseLabel`, valueToUpdate));
                return set('currentInstances', updatedInstances, state);
            }
            return state;
        }
        case DatasetInstanceActionTypes.SET_ANNEX_FIELD_IDS:
            return set('annexFieldIds', action.payload, state);
        case DatasetInstanceActionTypes.OPEN_ANNEX_FIELD_WIZARD:
            return set('wizardFieldOpen', action.payload, state);
        case DatasetInstanceActionTypes.CLOSE_ANNEX_FIELD_WIZARD:
            return set('wizardFieldOpen', null, state);
        case DatasetInstanceActionTypes.UPDATE_LAST_MODIFIED_FIELDS: {
            const { updatedFields, userId, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set('instance.datasetFields', updateLastModified(instance, updatedFields, userId)));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.ADD_COLLAPSED_COLUMN: {
            const { columnId, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set('instance.collapsedColumns', [...instance.collapsedColumns || [], columnId]));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.REMOVE_COLLAPSED_COLUMN: {
            const { columnId, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set('instance.collapsedColumns', instance.collapsedColumns?.filter(id => id !== columnId)));
            return set('currentInstances', updatedInstances, state);
        }
        case DatasetInstanceActionTypes.SET_DATASET_INSTANCE_TIMELINE:
            return set('timeline', action.payload, state);
        case DatasetInstanceActionTypes.SET_PARENT_DATASET_ID:
            return set('parentDatasetId', action.payload, state);
        case DatasetInstanceActionTypes.SET_TIMELINE_INSTANCE:
            return flow(
                set('isEditing', false),
                set('isUpdating', false)
            )(state);
        case DatasetInstanceActionTypes.OPEN_TIMELINE_INSTANCE_SUCCESSFUL:
            return flow(
                set('currentInstances', action.payload.datasetInstances),
                set('savedInstances', action.payload.datasetInstances),
                set('hierarchy', action.payload.hierarchy),
                set('mlData', action.payload.mlData),
                set('isUpdatingInstanceAnnexDefinitions', false)
            )(state);
        case DatasetInstanceActionTypes.INSTANCE_UPDATED: {
            const { datasetId, value, parentFieldId } = action.payload;
            const hierarchy = updateHierarchy(state.hierarchy, datasetId, parentFieldId, set('hasUpdated', value));
            return set('hierarchy', hierarchy, state);
        }
        case DatasetInstanceActionTypes.UPDATE_DATASET_HIERARCHY:
            return set('hierarchy', action.payload, state);
        case DatasetInstanceActionTypes.REMOVE_OPEN_FIELD_SECTION: {
            const openFieldsAndSections = state.openFieldsAndSections.filter(fieldSection => !isEqual(fieldSection, action.payload));
            return set('openFieldsAndSections', openFieldsAndSections, state);
        }
        case DatasetInstanceActionTypes.ADD_OPEN_FIELD_SECTION: {
            const openFieldsAndSections = [...state.openFieldsAndSections, action.payload];
            return set('openFieldsAndSections', openFieldsAndSections, state);
        }
        case DatasetInstanceActionTypes.EXPAND_ALL_DATASET_INSTANCE_SECTIONS: {

            return set('openFieldsAndSections', action.payload, state);
        }
        case DatasetInstanceActionTypes.REMOVE_ALL_FIELD_SECTIONS:
            return set('openFieldsAndSections', INITIAL_STATE.openFieldsAndSections, state);
        case DatasetInstanceActionTypes.OPEN_MODAL_DATASET_STARTED: {
            const { rowId, index, datasetId, parentFieldId } = action.payload;
            return flow(
                set('modalInstanceParentDetails', { rowId, index, datasetId, parentFieldId }),
                set('modalInstanceOpen', true),
                set('modalInstanceLoading', true)
            )(state);
        }
        case DatasetInstanceActionTypes.OPEN_MODAL_DATASET_SUCCESSFUL:
            return flow(
                set('currentModalInstance', action.payload),
                set('savedModalInstance', action.payload),
                set('modalInstanceLoading', false)
            )(state);
        case DatasetInstanceActionTypes.OPEN_MODAL_DATASET_FAILED:
            return flow(
                set('error', action.payload),
                set('modalInstanceLoading', false)
            )(state);
        case DatasetInstanceActionTypes.CLOSE_MODAL_DATASET:
            return flow(
                set('modalInstanceParentDetails', null),
                set('modalInstanceUpdated', false),
                set('modalInstanceOpen', false)
            )(state);
        case DatasetInstanceActionTypes.UPSERT_MODAL_DATASET_STARTED:
            return set('modalInstanceSaving', true, state);
        case DatasetInstanceActionTypes.UPSERT_MODAL_DATASET_SUCCESSFUL:
            return flow(
                set('modalInstanceSaving', false),
                set('modalInstanceUpdated', false),
                set('savedModalInstance', state.currentModalInstance)
            )(state);
        case DatasetInstanceActionTypes.UPSERT_MODAL_DATASET_FAILED:
            return flow(
                set('modalInstanceSaving', false),
                set('error', action.payload)
            )(state);
        case DatasetInstanceActionTypes.UPDATE_MODAL_FIELD_VALUE: {
            const { index, sectionId, value, rowId } = action.payload;
            const datasetInstance = state.currentModalInstance!;
            if (isFormDatasetInstance(datasetInstance)) {
                return set(`currentModalInstance.datasetFields[${sectionId}][${index}].value`, value, state);
            }
            return set(`currentModalInstance.datasetFields[${rowId}][${index}].value`, value, state);
        }
        case DatasetInstanceActionTypes.UPDATE_MODAL_GROUP_FIELD_VALUE: {
            const { groupIndex, index, sectionId, value } = action.payload;
            return set(`currentModalInstance.datasetFields[${sectionId}][${groupIndex}].children[${index}].value`, value, state);
        }
        case DatasetInstanceActionTypes.ADD_MODAL_TABLE_DATASET_ROW: {
            const datasetInstance = state.currentModalInstance as TableDatasetInstance;
            const newRowId = v4();
            const newRow = createNewTableRow(Object.values(datasetInstance.datasetFields)[0]);
            const datasetFields = { ...datasetInstance!.datasetFields, [newRowId]: newRow };
            const datasetRows = datasetInstance.datasetRows.concat(newRowId);
            return flow(
                set('currentModalInstance.datasetFields', datasetFields),
                set('currentModalInstance.datasetRows', datasetRows)
            )(state);
        }
        case DatasetInstanceActionTypes.REMOVE_MODAL_TABLE_DATASET_ROW: {
            const rowId = action.payload;
            const datasetInstance = state.currentModalInstance as TableDatasetInstance;
            const updatedRows = datasetInstance.datasetRows.filter(id => id !== rowId);
            return flow(
                unset(`currentModalInstance.datasetFields[${rowId}]`),
                set('currentModalInstance.datasetRows', updatedRows)
            )(state);
        }
        case DatasetInstanceActionTypes.DUPLICATE_MODAL_DATASET_ROW: {
            const rowId = action.payload;
            const datasetInstance = state.currentModalInstance as TableDatasetInstance;
            const newRowId = v4();
            const duplicateRow = datasetInstance.datasetFields[rowId];
            const datasetFields = { ...datasetInstance!.datasetFields, [newRowId]: duplicateRow };
            const datasetRows = datasetInstance.datasetRows.concat(newRowId);
            return flow(
                set('currentModalInstance.datasetFields', datasetFields),
                set('currentModalInstance.datasetRows', datasetRows)
            )(state);
        }
        case DatasetInstanceActionTypes.ADD_MODAL_COLLAPSED_COLUMN:
            return set('currentModalInstance.collapsedColumns', [...state.currentModalInstance!.collapsedColumns || [], action.payload], state);
        case DatasetInstanceActionTypes.REMOVE_MODAL_COLLAPSED_COLUMN:
            return set('currentModalInstance.collapsedColumns', state.currentModalInstance!.collapsedColumns?.filter(id => id !== action.payload), state);
        case DatasetInstanceActionTypes.MODAL_INSTANCE_UPDATED:
            return set('modalInstanceUpdated', action.payload, state);
        case DatasetInstanceActionTypes.UPDATE_MODAL_LAST_MODIFIED_FIELDS: {
            const { updatedFields, userId } = action.payload;
            const datasetInstance = state.currentModalInstance!;
            return set('currentModalInstance.datasetFields', updateLastModified(datasetInstance, updatedFields, userId), state);
        }
        case DatasetInstanceActionTypes.SET_MODAL_FIELDS_UPDATED_VALUE:
            return set('currentModalInstance.fieldsUpdated', action.payload, state);
        case DatasetInstanceActionTypes.FETCH_ECS_DATASET_ID_SUCCESSFUL:
            return set('ecsDatasetId', action.payload, state);
        case DatasetInstanceActionTypes.GENERATE_NEW_ECS_TABLE_SUCCESSFUL: {
            const { ecsTableInstance, sectionId } = action.payload;
            const ecsFormInstance = state.currentInstances.find(({ instance }) => !isNull(instance.datasetId) && instance.datasetId === state.ecsDatasetId!)!;
            const datasetField = ecsFormInstance.instance.datasetFields[`${sectionId}`][0] as SingleInstanceField;
            const datasetFields = [...ecsFormInstance.instance.datasetFields[`${sectionId}`], { ...datasetField, id: ecsTableInstance.parentFieldId, label: '', clauseLabel: '', modifiedDate: null, modifiedBy: null, settings: { ...datasetField.settings, linkedEntities: null } }];
            const updatedInstances = [...updateInstances(state.currentInstances, state.ecsDatasetId!, ecsFormInstance.parentFieldId, set(`instance.datasetFields[${sectionId}]`, datasetFields)), ecsTableInstance];
            const updatedSavedInstances = [...updateInstances(state.savedInstances, state.ecsDatasetId!, ecsFormInstance.parentFieldId, set(`instance.datasetFields[${sectionId}]`, datasetFields)), ecsTableInstance];
            const hierarchy = updateHierarchy(state.hierarchy, ecsFormInstance.instance.datasetId!, ecsFormInstance.parentFieldId, set('hasUpdated', true));
            return flow(
                set('currentInstances', updatedInstances),
                set('savedInstances', updatedSavedInstances),
                set('hierarchy', hierarchy)
            )(state);
        }
        case DatasetInstanceActionTypes.REMOVE_ECS_TABLE: {
            const { sectionId, fieldId, datasetId, parentFieldId, ecsTableDatasetId } = action.payload;
            const ecsFormInstance = state.currentInstances.find(({ instance }) => !isNull(instance.datasetId) && instance.datasetId === datasetId)!;
            const datasetFields = (ecsFormInstance.instance.datasetFields as FormInstanceFields)[`${sectionId}`].filter(({ id }) => id !== fieldId);
            const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}]`, datasetFields)).filter(({ instance, parentFieldId }) => !isEqual({ datasetId: instance.datasetId, parentFieldId }, { datasetId: parseInt(ecsTableDatasetId), parentFieldId: fieldId }));
            const updatedSavedInstances = updateInstances(state.savedInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}]`, datasetFields)).filter(({ instance, parentFieldId }) => !isEqual({ datasetId: instance.datasetId, parentFieldId }, { datasetId: parseInt(ecsTableDatasetId), parentFieldId: fieldId }));
            const hierarchy = updateHierarchy(state.hierarchy, datasetId, parentFieldId, set('hasUpdated', true));
            return flow(
                set('currentInstances', updatedInstances),
                set('savedInstances', updatedSavedInstances),
                set('hierarchy', hierarchy)
            )(state);
        }
        case DatasetInstanceActionTypes.DUPLICATE_ECS_TABLE: {
            const { sectionId, fieldId, datasetId, parentFieldId, ecsTableDatasetId } = action.payload;
            const newFieldId = v4();
            const ecsFormInstance = state.currentInstances.find(({ instance }) => !isNull(instance.datasetId) && instance.datasetId === datasetId)!;
            const duplicateInstance = state.currentInstances.find(({ instance, parentFieldId }) => isEqual({ datasetId: instance.datasetId, parentFieldId }, { datasetId: parseInt(ecsTableDatasetId), parentFieldId: fieldId }))!;
            const duplicateHierarchy = state.hierarchy.find(val => isEqual({ datasetId: val.datasetId, fieldId: val.fieldId }, { datasetId: ecsTableDatasetId, fieldId }))!;
            const newInstance = { instance: duplicateInstance.instance, parentFieldId: newFieldId };
            const newHierarchy: Hierarchy = { ...duplicateHierarchy, fieldId: newFieldId, hasUpdated: true };
            const datasetField = (ecsFormInstance.instance.datasetFields as FormInstanceFields)[`${sectionId}`].find(({ id }) => id === fieldId)! as SingleInstanceField;
            const datasetFields = [...ecsFormInstance.instance.datasetFields[`${sectionId}`], { ...datasetField, id: newFieldId, label: '', clauseLabel: '', modifiedDate: null, modifiedBy: null, settings: { ...datasetField.settings, linkedEntities: null }, value: null }];
            const updatedInstances = [...updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}]`, datasetFields)), newInstance];
            const updatedSavedInstances = [...updateInstances(state.savedInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}]`, datasetFields)), newInstance];
            const hierarchy = [...updateHierarchy(state.hierarchy, datasetId, parentFieldId, set('hasUpdated', true)), newHierarchy];
            return flow(
                set('currentInstances', updatedInstances),
                set('savedInstances', updatedSavedInstances),
                set('hierarchy', hierarchy)
            )(state);
        }
        case DatasetInstanceActionTypes.UPDATE_FIELD_LABEL: {
            const { index, sectionId, value, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            if (isFormDatasetInstance(instance)) {
                const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}][${index}].label`, value));
                return set('currentInstances', updatedInstances, state);
            }
            return state;
        }
        case DatasetInstanceActionTypes.UPDATE_LINKED_ENTITIES: {
            const { index, sectionId, value, datasetId, parentFieldId } = action.payload;
            const { instance } = getDatasetInstance(state.currentInstances, datasetId, parentFieldId);
            if (isFormDatasetInstance(instance)) {
                const updatedInstances = updateInstances(state.currentInstances, datasetId, parentFieldId, set(`instance.datasetFields[${sectionId}][${index}].settings.linkedEntities`, value));
                return set('currentInstances', updatedInstances, state);
            }
            return state;
        }
        case DatasetInstanceActionTypes.SET_AGENCY_LINKED_ENTITIES:
            return set('agencyLinkedEntities', action.payload, state);
        case DatasetInstanceActionTypes.SET_HIDDEN_FIELDS:
            return set('hiddenFields', action.payload, state);
        case DatasetInstanceActionTypes.SET_DATASET_INSTANCE_RISK_TOLERANCE:
            return set('riskToleranceForDefinitions', action.payload, state);
        case DatasetInstanceActionTypes.TOGGLE_DELETE_SECONDARY_DOCUMENT_MODAL:
            return set('secondaryDocumentToDelete', action.payload, state);
        case DatasetInstanceActionTypes.DELETE_SECONDARY_DOCUMENT_STARTED:
            return set('isDeleting', true, state);
        case DatasetInstanceActionTypes.DELETE_SECONDARY_DOCUMENT_SUCCESSFUL:
            return flow(
                set('isDeleting', false),
                set('secondaryDocumentToDelete', null)
            )(state);
        case DatasetInstanceActionTypes.DELETE_SECONDARY_DOCUMENT_FAILED:
            return flow(
                set('isDeleting', false),
                set('error', action.payload)
            )(state);
        case DatasetInstanceActionTypes.TOGGLE_DATASET_INSTANCE_SHOW_LEGACY:
            return set('showLegacy', action.payload, state);
        case DatasetInstanceActionTypes.TOGGLE_ML_DATA_MODAL_OPEN:
            return set('mlDataModalOpen', action.payload, state);
        case DatasetInstanceActionTypes.TOGGLE_ANNEX_DEFINITION_MODAL_OPEN:
            return set('annexConfigurationModalOpen', action.payload, state);
        case DatasetInstanceActionTypes.SET_IS_UPDATING_INSTANCE_ANNEX_DEFINITIONS:
            return set('isUpdatingInstanceAnnexDefinitions', action.payload, state);
        case DatasetInstanceActionTypes.SET_DATASET_INSTANCE_SEARCH_FIELD_SECTION:
            return set('searchFieldSection', action.payload, state);
        case DatasetInstanceActionTypes.SET_DATASET_INSTANCE_SEARCH_FUZZY_MATCHES:
            return set('searchFuzzyMatches', action.payload, state);
        case DatasetInstanceActionTypes.SET_DATASET_INSTANCE_FUZZY_MATCH_SEARCH_VALUE:
            return set('fieldSectionSearch', action.payload, state);
        case DatasetInstanceActionTypes.SET_ALL_DATASET_INSTANCE_SEARCH_FIELD_SECTIONS:
            return set('allSearchFieldSections', action.payload, state);
        default:
            return state;
    }
};
