import React, { useMemo } from 'react';
import { isEqual, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { getDatasetDefinition, getDefinitionClientIds, getNewDatasetFields, getPublishError, getPublishModalOpen, getPublishReason, publishDatasetDefinitionStarted, togglePublishModal, updateDefinitionClientIds, updatePublishReason } from './store';
import styles from './DatasetBuilder.module.scss';
import { LongText } from '../../shared/longtext/LongText';
import { getAllClients } from '../clients/store';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Pencil } from '../../shared/icons';
import { ClientSelect } from '../../shared/clients/ClientSelect';

export const PublishModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getPublishModalOpen);
    const publishReason = useAppSelector(getPublishReason);
    const publishError = useAppSelector(getPublishError);
    const clients = useAppSelector(getAllClients);
    const selectedClientIds = useAppSelector(getDefinitionClientIds);
    const datasetDefinition = useAppSelector(getDatasetDefinition);
    const newDatasetFields = useAppSelector(getNewDatasetFields);

    const isNewDataset = useMemo(() => !!datasetDefinition && isUndefined(datasetDefinition.datasetId), [datasetDefinition]);
    const showClientToggles = useMemo(() => !isNewDataset && newDatasetFields.length > 0, [isNewDataset, newDatasetFields]);

    const allClientIds = useMemo(() => clients.map(({ clientId }) => clientId!), [clients]);
    const closeModal = () => dispatch(togglePublishModal(false));

    const updateReason = (value: string) => dispatch(updatePublishReason(value));

    const publishDatasetDefinition = () => dispatch(publishDatasetDefinitionStarted());

    const updateSelectedClientIds = (checked: boolean, clientId: number) => {
        const clientIds = checked ? [...selectedClientIds, clientId] : selectedClientIds.filter(id => id !== clientId);
        dispatch(updateDefinitionClientIds(clientIds));
    };

    const updateAllClientIds = (checked: boolean) => {
        const clientIds = checked ? allClientIds : [];
        dispatch(updateDefinitionClientIds(clientIds));
    };

    const allClientIdsSelected = useMemo(() => isEqual(allClientIds.sort(), selectedClientIds.sort()), [allClientIds, selectedClientIds]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={publishDatasetDefinition}
            showConfirm={true}
            showClose={true}
            closeOnOverlayClick={false}
            confirmLabel='Publish'
        >
            <div className={styles.publishWrapper}>
                <ModalHeader icon={Pencil} label='Publish Dataset' />
                <LongText
                    onChange={updateReason}
                    value={publishReason}
                    placeholder='Optional...'
                    label='Reason'
                    maxLength={256}
                    width='calc(100% - 12px)'
                />
                {showClientToggles &&
                    <ClientSelect
                        allClientIdsSelected={allClientIdsSelected}
                        allClients={clients}
                        selectedClientIds={selectedClientIds}
                        title='Select the clients you would like the new fields to be visible for'
                        updateAllClientIds={updateAllClientIds}
                        updateSelectedClientIds={updateSelectedClientIds}
                    />
                }
                <div className={styles.publishError}>{publishError && 'There was an error when trying to publish your dataset'}</div>
            </div>
        </ ConfirmationModal>
    );
};
