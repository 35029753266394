import { flow, isNull, set, unset } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DATABASE_DATE_FORMAT, formatDate, futureDate, isSameOrBefore } from '../../../../utils/luxon';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { Button } from '../../../shared/button/Button';
import { DatePicker } from '../../../shared/datepicker/DatePicker';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Toggle } from '../../../shared/toggle';
import { CreateAttestationContent, getSelectedEventDate, getWorkflowPage, initialContentByActionType, recoverScheduledActionStarted, Scheduler, SchedulerAction, SchedulerInterval, SendEmailContent, setEventValue, skipScheduledActionStarted, toggleDeleteEventConfirmationModal, WorkflowPage, toggleScheduleWizard } from '../store';
import styles from '../Workflow.module.scss';

interface ScheduleProps {
    event: Scheduler;
    isEditing: boolean;
    testId?: string;
}

export const Schedule: React.FC<ScheduleProps> = ({ event, isEditing, testId }) => {
    const dispatch = useAppDispatch();
    const isArchived = !!event.isArchived;
    const hasCompleted = !!event.hasCompleted;
    const eventSkipped = !!event.isSkipped;
    const page = useAppSelector(getWorkflowPage);
    const selectedEventDate = useAppSelector(getSelectedEventDate);
    const showSkip = useMemo(() => page === WorkflowPage.CALENDAR && !isSameOrBefore(selectedEventDate!, new Date()) && !!event.repeat, [page, selectedEventDate, event]);
    const showDelete = useMemo(() => !isArchived && !hasCompleted, [isArchived, hasCompleted]);
    const showDuplicate = useMemo(() => !eventSkipped && !!event.scheduledActionId, [eventSkipped, event]);

    const updateEventValue = useCallback((key: keyof Scheduler, value: string | null | number | CreateAttestationContent | SendEmailContent) => dispatch(setEventValue(key, value)), [dispatch]);

    const updateFirstDate = useCallback((date: Date | null) => updateEventValue('firstDate', !isNull(date) ? formatDate(date, DATABASE_DATE_FORMAT) : date), [updateEventValue]);
    const firstDate = !isNull(event.firstDate) && new Date(event.firstDate) || null;

    // We will check in the services onSave if repeat is toggled and if not, we will make sure to clear interval, intervalValue, nextDate etc
    const updateRepeat = useCallback((checked: boolean) => updateEventValue('repeat', checked ? 1 : 0), [updateEventValue]);

    const intervalValueOptions = useMemo(() => Array(12).fill(1).map((_, index) => ({ value: `${(index + 1) * 1}`, label: `${(index + 1) * 1}` })), []);
    const intervalValue = useMemo(() => intervalValueOptions.find(({ value }) => value === event.intervalValue?.toString()) || null, [event.intervalValue, intervalValueOptions]);

    const intervalOptions = useMemo(() => Object.values(SchedulerInterval).map(value => ({ value, label: capitaliseStartLetter(value) })), []);
    const interval = useMemo(() => intervalOptions.find(({ value }) => value === event.interval) || null, [intervalOptions, event.interval]);

    const actionOptions = useMemo(() => [{ value: SchedulerAction.CREATE_ATTESTATION, label: 'Create an attestation' }, { value: SchedulerAction.SEND_EMAIL, label: 'Send an email' }], []);
    const action = useMemo(() => actionOptions.find(({ value }) => value === event.action) || null, [actionOptions, event.action]);

    const updateDropdownValue = useCallback((key: keyof Scheduler, dropdownValue: DropdownOption | Options<DropdownOption> | null, asNumber = false) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = asNumber ? parseInt((dropdownValue as DropdownOption).value) : (dropdownValue as DropdownOption).value;
        }
        updateEventValue(key, value);
    }, [updateEventValue]);

    const updateIntervalValue = useCallback((value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('intervalValue', value, true), [updateDropdownValue]);
    const updateInterval = useCallback((value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('interval', value), [updateDropdownValue]);
    const updateAction = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        // Only update if the action is changing, so we reset the content as well which we would not want to do if the same action was selected
        if (isNull(event.action) || (!isNull(event.action) && !isNull(dropdownValue) && (dropdownValue as DropdownOption).value !== event.action)) {
            updateDropdownValue('action', dropdownValue);
            updateEventValue('content', initialContentByActionType((dropdownValue as DropdownOption).value as SchedulerAction));
        }
    }, [updateDropdownValue, updateEventValue, event.action]);

    const openDeleteConfirmationModal = useCallback(() => dispatch(toggleDeleteEventConfirmationModal(event.scheduledActionId!)), [dispatch, event.scheduledActionId]);

    const skipEvent = useCallback(() => dispatch(skipScheduledActionStarted(event.scheduledActionId!, eventSkipped)), [event, eventSkipped, dispatch]);
    const skipLabel = useMemo(() => !eventSkipped ? 'Skip' : 'Include', [eventSkipped]);

    const openDuplicateScheduledAction = useCallback(() => {
        const duplicateEvent = flow(
            unset('scheduledActionId'),
            set('hasCompleted', 0),
            set('firstDate', null),
            set('nextDate', null)
        )(event);
        dispatch(toggleScheduleWizard(true, duplicateEvent));
    }, [event, dispatch]);

    const recoverScheduledAction = useCallback(() => dispatch(recoverScheduledActionStarted(event)), [dispatch, event]);
    const minDate = new Date(futureDate({ days: 1 }));

    return (
        <div className={styles.scheduleTabWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.topWrapper}>
                <div className={styles.actionWrapper}>
                    <div className={styles.actionTitle} data-testid={`${testId}-action-label`}>I would like to</div>
                    <div className={styles.actionDropdown}>
                        <Dropdown
                            options={actionOptions}
                            value={action}
                            onChange={updateAction}
                            isClearable={false}
                            disabled={!isEditing}
                            testId={`${testId}-action`}
                        />
                    </div>
                </div>
                <div className={styles.dateWrapper}>
                    <div className={styles.firstDateWrapper}>
                        <div className={styles.firstDateTitle} data-testid={`${testId}-first-date-label`}>on</div>
                        <DatePicker value={firstDate} onChange={updateFirstDate} testId={`${testId}-first-date`} minDate={minDate} disabled={!isEditing} />
                    </div>
                    <div className={styles.repeatWrapper}>
                        <div className={styles.repeatTitle} data-testid={`${testId}-repeat-label`}>Repeat?</div>
                        <Toggle checked={!!event.repeat} onChange={updateRepeat} testId={`${testId}-repeat`} disabled={!isEditing} />
                    </div>
                </div>
                {!!event.repeat &&
                    <div className={styles.intervalWrapper}>
                        <div className={styles.intervalTitle} data-testid={`${testId}-interval-label`}>and repeat every</div>
                        <div className={styles.intervalValueDropdown}>
                            <Dropdown
                                options={intervalValueOptions}
                                value={intervalValue}
                                onChange={updateIntervalValue}
                                isClearable={false}
                                disabled={!isEditing}
                                testId={`${testId}-interval-value`}
                            />
                        </div>
                        <div className={styles.intervalDropdown}>
                            <Dropdown
                                options={intervalOptions}
                                value={interval}
                                onChange={updateInterval}
                                isClearable={false}
                                disabled={!isEditing}
                                testId={`${testId}-interval`}
                            />
                        </div>
                    </div>
                }
            </div>
            {event.scheduledActionId &&
                <div className={styles.deleteSkipWrapper}>
                    {showDuplicate && <Button label='Duplicate' testId={`${testId}-duplicate`} onClick={openDuplicateScheduledAction} />}
                    {showSkip && <Button label={skipLabel} testId={`${testId}-skip`} onClick={skipEvent} />}
                    {showDelete && <Button label='Delete' testId={`${testId}-delete`} onClick={openDeleteConfirmationModal} />}
                    {isArchived && <Button label='Recover' testId={`${testId}-recover`} onClick={recoverScheduledAction} />}
                </div>
            }
        </div>
    );
};
