import { api } from './services';
import { SearchEntityBy, Entity, ClassificationCompanyEntity, ClassifySoftSearchEntityRequest } from '../components/admin/entity/store/types';
import { TableFilters } from '../components/shared/modal/TableFilterModal';
import { ColumnSort } from '../components/shared/table/ArkTable';

interface SearchEntitiesRequest {
    type: SearchEntityBy;
    value: string;
    pageSize: number;
    pageNumber: number;
}

interface UpsertEntityRequest {
    entity: Entity;
    pageSize: number;
    pageNumber: number;
    filters: TableFilters;
    columnSort: ColumnSort | undefined;
    entityIdsForGroup: string[] | null;
    companyEntityGroupIds: string[];
}

interface UpsertSubCounterpartyTypeRequest {
    subCounterpartyTypeId?: number;
    parentCounterpartyType: string;
    subCounterpartyName: string;
    isSystem: number;
    jurisdiction: string;
}

export const upsertEntity = async (reqBody: UpsertEntityRequest) => await api('entity/upsert', { ...reqBody }, 'post');
export const upsertMLEntity = async (reqBody: { entity: Entity; key: string; documentId: number; }) => await api('entity/upsertML', { ...reqBody }, 'post');
export const searchEntities = async (reqBody: SearchEntitiesRequest) => await api('entity/search', { ...reqBody }, 'post');
export const classifySoftSearchEntity = async (reqBody: ClassifySoftSearchEntityRequest) => await api('entity/softSearch/classify', { ...reqBody }, 'post');
export const fetchPaginatedEntities = async (reqBody: { filters: TableFilters; pageNumber: number; columnSort: ColumnSort | undefined; pageSize: number; }) => await api('entity/getPaginated', { ...reqBody }, 'post');
export const fetchAllEntities = async (reqBody: { includeGroups: boolean; }) => await api('entity/getAll', { ...reqBody }, 'post');
export const fetchAllMyCompanyEntities = async () => await api('entity/myCompany/getAll', {}, 'get');
export const fetchAllGroupEntities = async () => await api('entity/groups/getAll', {}, 'get');
export const fetchAllNettingEngineEntities = async () => await api('entity/netting/getAll', {}, 'get');
export const getAllCompanyClassificationEntities = async () => await api('entity/classification/getAll', {}, 'get');
export const assertEntityClassification = async (reqBody: { entity: ClassificationCompanyEntity; }) => await api('entity/classification/assert', { ...reqBody }, 'post');
export const updateClassificationEntities = async (reqBody: { entities: ClassificationCompanyEntity[]; }) => await api('entity/classification/update', { ...reqBody }, 'post');
export const fetchFuzzyAutoCompletions = async (reqBody: { value: string; }) => await api('entity/fuzzyAutoSearch', { ...reqBody }, 'post');
export const fetchAllAvailableOpinionSubCounterpartyTypes = async (reqBody: { opinionJurisdiction: string; parentCounterpartyTypeFilter?: string; }) => await api('entity/admin/subCounterparty/getAll', { ...reqBody }, 'post');
export const upsertOpinionSubCounterpartyType = async (reqBody: UpsertSubCounterpartyTypeRequest) => await api('entity/admin/subCounterparty/upsert', { ...reqBody }, 'post');
