import React, { useCallback, useMemo, useState } from 'react';

import { ConfirmationModal } from './ConfirmationModal';
import { ModalHeader } from './ModalHeader';
import { Spinner } from '../spinner/Spinner';
import { Text } from '../text/Text';
import styles from './Modal.module.scss';
import { capitaliseStartLetter } from '../../../utils/regex-utils';

interface TypeDeleteConfirmationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    deleteAction: () => void;
    isDeleting: boolean;
    headerLabel: string;
    name: string;
    warning?: string;
    testId: string;
    icon: React.FC
    deleteLabel?: string;
}

export const TypeDeleteConfirmationModal: React.FC<TypeDeleteConfirmationModalProps> = ({ isOpen, closeModal, deleteAction, isDeleting, headerLabel, name, warning, testId, icon, deleteLabel }) => {
    const [deletePledge, setDeletePledge] = useState<string>('');

    const deleteButtonDisabled = deletePledge !== 'delete';
    const deleteButtonDisabledTooltip = deleteButtonDisabled ? ['Please type delete above to confirm delete']: [];

    const warningLabel = useMemo(() => warning || `Once deleted, the ${testId.split('-').join(' ')} will lose all access to the application. This cannot be undone.`, [warning, testId]);
    const deleteThisLabel = useMemo(() => deleteLabel || testId, [deleteLabel, testId]);

    const onDelete = useCallback(() => {
        deleteAction();
        setDeletePledge('');
    }, [deleteAction, setDeletePledge]);

    const onKeydown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter' && !deleteButtonDisabled) {
            onDelete();
        }
    }, [deleteButtonDisabled, onDelete]);

    const onClose = useCallback(() => {
        closeModal();
        setDeletePledge('');
    }, [closeModal, setDeletePledge]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={onClose}
            confirmLabel='Delete'
            testId={`delete-${testId}`}
            confirm={onDelete}
            confirmDisabled={deleteButtonDisabled}
            confirmDisabledTooltip={deleteButtonDisabledTooltip}
        >
            <ModalHeader label={headerLabel} icon={icon} testId={`delete-${testId}-modal`} />
            <div className={styles.deleteConfirmationWrapper} data-testid={`delete-${testId}-modal-wrapper`}>
                {isDeleting ? (<Spinner message={`Deleting ${capitaliseStartLetter(testId)}...`} />) : (
                    <div>
                        <div className={styles.warningTitle} data-testid={`delete-${testId}-warning-title`}>Are you sure you want to delete this {deleteThisLabel}?</div>
                        <div className={styles.name} data-testid={`delete-${testId}-name`}>{name}</div>
                        <div className={styles.warningLabel} data-testid={`delete-${testId}-warning-label`}>{warningLabel}</div>
                        <div className={styles.confirmationInputLabel} data-testid={`delete-${testId}-instruction`}>Type delete in the box below to confirm deletion of {testId.replace(/-/g, ' ').toLowerCase()}.</div>
                        <Text
                            value={deletePledge}
                            placeholder='delete'
                            onChange={e => setDeletePledge(e.target.value)}
                            onKeyDown={onKeydown}
                            testId={`delete-${testId}-pledge`}
                            autoFocus
                        />
                    </div>
                )}
            </div>
        </ConfirmationModal>
    );
};
