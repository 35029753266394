import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { RepoEarlyCloseOut, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface RepoEarlyCloseOutSectionProps {
    earlyCloseOut: RepoEarlyCloseOut;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const RepoEarlyCloseOutSection: React.FC<RepoEarlyCloseOutSectionProps> = ({ earlyCloseOut, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'earlyCloseOut';
    const { aetEnforceable, aetRequired, aetAdvisable, optionalAetEnforceable, closeOutNettingEnforceable, baseCurrencyCashPayment, securitiesHeldOutsideJurisdiction, crossProductCrossAgreementImpact } = earlyCloseOut;

    return (
        <SectionWrapper id={sectionId} label='Early Close-Out' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.earlyCloseOutSectionWrapper}>
                <div className={styles.sectionHeaderLabel}>Under the laws of the jurisdiction under consideration:</div>
                <SingleField
                    field={aetEnforceable}
                    fieldId='aetEnforceable'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={aetRequired}
                    fieldId='aetRequired'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={aetAdvisable}
                    fieldId='aetAdvisable'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={optionalAetEnforceable}
                    fieldId='optionalAetEnforceable'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={closeOutNettingEnforceable}
                    fieldId='closeOutNettingEnforceable'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={baseCurrencyCashPayment}
                    fieldId='baseCurrencyCashPayment'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={securitiesHeldOutsideJurisdiction}
                    fieldId='securitiesHeldOutsideJurisdiction'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={crossProductCrossAgreementImpact}
                    fieldId='crossProductCrossAgreementImpact'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
            </div>
        </SectionWrapper>
    );
};
