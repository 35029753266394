import React from 'react';

export const Ellipsis: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <g>
            <circle fill={color || 'currentColor'} cx="31.4" cy="64" r="8" />
            <circle fill={color || 'currentColor'} cx="63.6" cy="64" r="8" />
            <circle fill={color || 'currentColor'} cx="95.8" cy="64" r="8" />
        </g>
    </svg>
);
