import { flow, get, indexOf, isNull, isUndefined, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { Clause, ClientTag, createDuplicateClause } from '../../../clause-library/store';
import { initialClause } from '../../../constants/clauseLibrary';
import { SmileyIndicator } from '../../../shared/analytics/SingleIndicator';
import { OpinionClause, OpinionInstanceActionTypes, OpinionInstanceState, SignOffSmileyAndNote } from './types';

const initialSignOffNotes: SignOffSmileyAndNote = {
    smileyValue: SmileyIndicator.NONE,
    notes: null,
    createdBy: null,
    name: '',
    createdDate: ''
};

export const convertOpinionClause = (clauses: Clause[]) => clauses.map(clause => {
    const { clauseId, title, description, content, tags: { agreementType, jurisdiction, productType, counterpartyType, provisionType, miscellaneous, opinions } } = clause;
    const constructedClause: OpinionClause = {
        clauseId: clauseId!,
        clauseTitle: title,
        clauseDescription: description,
        content,
        tags: [
            ...agreementType,
            ...jurisdiction,
            ...counterpartyType,
            ...productType,
            ...provisionType,
            ...miscellaneous,
            ...opinions
        ]
    };
    return constructedClause;
});

export const INITIAL_STATE: OpinionInstanceState = {
    currentInstance: null,
    savedInstance: null,
    currentSignOff: null,
    savedSignOff: null,
    timeline: [],
    openFieldsAndSections: [],
    error: null,
    isEditing: false,
    isUpdating: false,
    isSaving: false,
    isLoading: false,
    updatedInstanceModalOpen: false,
    confirmSaveModalOpen: false,
    instanceUpdated: false,
    linkedOpinionModalOpen: false,
    clauses: [],
    opinionClauses: [],
    allOpinionClauseIds: [],
    additionalProvisionsUpdated: false,
    clauseTableLoading: false,
    totalOpinionClauses: 0,
    opinionClausePage: 1,
    opinionClauseFilters: {},
    opinionClauseSort: undefined,
    clauseModalOpen: false,
    clause: initialClause(),
    currentClause: null,
    tagTerm: '',
    fuzzyMatchResults: [],
    fuzzyMatchModalOpen: false,
    clauseLibraryDropdownOptions: { jurisdictionOptions: [], agreementTypeOptions: [], productTypeOptions: [], counterpartyTypeOptions: [], provisionTypeOptions: [], miscellaneousOptions: [], opinionsOptions: [], miscellaneousSystemOptions: [] },
    clauseCanSave: false,
    clauseDescriptionOpen: false,
    allClientTags: [],
    linkOpinionClauseModalOpen: false,
    scrollableWrapper: null,
    agreementCoverageSearchTerm: '',
    signOffModalOpen: false,
    signOffNotesModalOpen: false,
    sectionOrFieldId: '',
    signOffNotesContent: initialSignOffNotes,
    signOffInstanceUpdated: false,
    isEditingSignOff: false,
    supportOpinionUploadModalOpen: false,
    indexOfUpdatedNote: null,
    subCounterpartyTypeModalOpen: false,
    parentCounterparty: ''
};

export const opinionInstanceReducer: Reducer<OpinionInstanceState> = (state = INITIAL_STATE, { type, payload }): OpinionInstanceState => {
    switch (type) {
        case OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_BY_ID:
            return flow(
                set('isLoading', true),
                set('instanceUpdated', false)
            )(state);
        case OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_STARTED:
            return flow(
                set('isLoading', true),
                set('instanceUpdated', false),
                set('currentInstance', null)
            )(state);
        case OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_SUCCESSFUL:
            return set('isLoading', false, state);
        case OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_FAILED:
            return flow(
                set('error', payload),
                set('isLoading', false)
            )(state);
        case OpinionInstanceActionTypes.TOGGLE_SUPPORT_OPINION_UPLOAD_MODAL_OPEN:
            return set('supportOpinionUploadModalOpen', payload, state);
        case OpinionInstanceActionTypes.UPDATE_FIELD_VALUE: {
            const { sectionId, fieldId, value } = payload;
            return set(`currentInstance.content[${sectionId}][${fieldId}].value`, value, state);
        }
        case OpinionInstanceActionTypes.UPDATE_DROPDOWN_FIELD_VALUE: {
            const { sectionId, fieldId, value } = payload;
            return set(`currentInstance.content[${sectionId}][${fieldId}].dropdownValue`, value, state);
        }
        case OpinionInstanceActionTypes.UPDATE_WYSIWYG_FIELD_VALUE: {
            const { sectionId, fieldId, value } = payload;
            return set(`currentInstance.content[${sectionId}][${fieldId}].wysiwygValue`, value, state);
        }
        case OpinionInstanceActionTypes.UPDATE_SINGLE_TOGGLE_FIELD_VALUE:
        case OpinionInstanceActionTypes.UPDATE_TABLE_CELL_FIELD_VALUE:
        case OpinionInstanceActionTypes.UPDATE_COUNTERPARTY_COVERAGE_FIELD_VALUE: {
            const { sectionId, fieldId, index, value } = payload;
            return set(`currentInstance.content[${sectionId}][${fieldId}][${index}].value`, value, state);
        }
        case OpinionInstanceActionTypes.UPDATE_CUSTOM_TOGGLE_LABEL: {
            const { sectionId, index, value } = payload;
            return set(`currentInstance.content[${sectionId}].customOptions[${index}].label`, value, state);
        }
        case OpinionInstanceActionTypes.ADD_OPINION_FIELD_CUSTOM_CHILD: {
            const { customOptions, sectionId } = payload;
            return set(`currentInstance.content[${sectionId}].customOptions`, customOptions, state);
        }
        case OpinionInstanceActionTypes.UPDATE_INCLUDED_INSTANCE_SUB_COUNTERPARTY_TYPES: {
            const counterpartyCoverageLabels = state.currentInstance?.content.counterpartyCoverage.standardOptions.map(({ label }) => label) || [];
            const counterpartyCoverageIndex = indexOf(state.parentCounterparty, counterpartyCoverageLabels);
            if (counterpartyCoverageIndex >= 0) {
                return set(`currentInstance.content.counterpartyCoverage.standardOptions[${counterpartyCoverageIndex}].includedSubCounterpartyTypeIds`, payload, state);
            }
            return state;
        }
        case OpinionInstanceActionTypes.SET_SUB_COUNTERPARTY_PARENT:
            return set('parentCounterparty', payload, state);
        case OpinionInstanceActionTypes.SET_FIELDS_UPDATED_VALUE: {
            return set('currentInstance.fieldsUpdated', payload, state);
        }
        case OpinionInstanceActionTypes.UPDATE_INSTANCE_ADDITIONAL_OPINION_IDS: {
            return flow(
                set('currentInstance.additionalOpinionIds', payload),
                set('savedInstance.additionalOpinionIds', payload)
            )(state);
        }
        case OpinionInstanceActionTypes.TOGGLE_SAVE_INSTANCE_MODAL:
            return set('confirmSaveModalOpen', payload, state);
        case OpinionInstanceActionTypes.UPSERT_OPINION_INSTANCE_STARTED:
            return set('isSaving', true, state);
        case OpinionInstanceActionTypes.UPSERT_OPINION_INSTANCE_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('isEditing', false),
                set('isUpdating', false),
                set('instanceUpdated', false),
                set('savedInstance', payload),
                set('currentInstance', payload),
                set('agreementCoverageSearchTerm', '')
            )(state);
        case OpinionInstanceActionTypes.UPSERT_OPINION_INSTANCE_FAILED:
            return flow(
                set('isSaving', false),
                set('isEditing', false),
                set('isUpdating', false),
                set('instanceUpdated', false),
                set('savedInstance', state.savedInstance),
                set('error', payload)
            )(state);
        case OpinionInstanceActionTypes.EDIT_OPINION_INSTANCE:
            return set('isEditing', payload, state);
        case OpinionInstanceActionTypes.UPDATE_OPINION_INSTANCE:
            return set('isUpdating', payload, state);
        case OpinionInstanceActionTypes.TOGGLE_UPDATED_INSTANCE_MODAL:
            return set('updatedInstanceModalOpen', payload, state);
        case OpinionInstanceActionTypes.RESET_OPINION_INSTANCE:
            return INITIAL_STATE;
        case OpinionInstanceActionTypes.TOGGLE_REF_MODAL_OPEN: {
            const { sectionId, fieldId, isOpen } = payload;
            return set(`currentInstance.content[${sectionId}][${fieldId}].refOpen`, isOpen, state);
        }
        case OpinionInstanceActionTypes.UPDATE_FIELD_REF: {
            const { sectionId, fieldId, value } = payload;
            return set(`currentInstance.content[${sectionId}][${fieldId}].ref`, value, state);
        }
        case OpinionInstanceActionTypes.UPDATE_FIELD_PAGE_REF: {
            const { sectionId, fieldId, index, value } = payload;
            return set(`currentInstance.content[${sectionId}][${fieldId}].pageRef[${index}]`, value, state);
        }
        case OpinionInstanceActionTypes.UPDATE_FIELD_PAGE_REF_VERIFIED: {
            const { sectionId, fieldId, pageRefVerified } = payload;
            return set(`currentInstance.content[${sectionId}][${fieldId}].pageRefVerified`, pageRefVerified, state);
        }
        case OpinionInstanceActionTypes.ADD_FIELD_PAGE_REF: {
            const { sectionId, fieldId } = payload;
            const pageRefs: string[] = get(`currentInstance.content[${sectionId}][${fieldId}].pageRef`, state);
            const updatedPageRefs = [...pageRefs, ''];
            return set(`currentInstance.content[${sectionId}][${fieldId}].pageRef`, updatedPageRefs, state);
        }
        case OpinionInstanceActionTypes.REMOVE_FIELD_PAGE_REF: {
            const { sectionId, fieldId, index } = payload;
            const pageRefs: string[] = get(`currentInstance.content[${sectionId}][${fieldId}].pageRef`, state);
            const updatedPageRefs = pageRefs.filter((_, i) => i !== index);
            return set(`currentInstance.content[${sectionId}][${fieldId}].pageRef`, updatedPageRefs, state);
        }
        case OpinionInstanceActionTypes.SET_TIMELINE:
            return flow(
                set('timeline', payload),
                set('isEditing', false),
                set('isUpdating', false)
            )(state);
        case OpinionInstanceActionTypes.SET_OPINION_SIGN_OFF:
            return flow(
                set('currentSignOff', payload),
                set('savedSignOff', payload)
            )(state);
        case OpinionInstanceActionTypes.OPEN_TIMELINE_INSTANCE_SUCCESSFUL:
            return flow(
                set('currentInstance', payload),
                set('savedInstance', payload),
                set('instanceUpdated', false)
            )(state);
        case OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_SMILEY_CONTENT: {
            const { smileyValue } = payload;
            const currentNotes = { ...state.signOffNotesContent, smileyValue };
            return set('signOffNotesContent', currentNotes, state);
        }
        case OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_DROPDOWN_VALUE: {
            const currentNotes = { ...state.signOffNotesContent, dropdownValue: payload };
            return set('signOffNotesContent', currentNotes, state);
        }
        case OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_CONTENT: {
            const { notes, createdBy, name, createdDate } = payload;
            const currentNotes = { ...state.signOffNotesContent, notes, createdBy, name, createdDate };
            return set('signOffNotesContent', currentNotes, state);
        }
        case OpinionInstanceActionTypes.SET_NOTE_TO_EDIT: {
            const { index, sectionId, fieldId } = payload;
            const path = fieldId ? `currentSignOff.content[${sectionId}][${fieldId}].notes[${index}]` : `currentSignOff.content[${sectionId}].notes[${index}]`;
            const value: SignOffSmileyAndNote = get(path, state);
            return flow(
                set('signOffNotesContent', value),
                set('indexOfUpdatedNote', index)
            )(state);
        }
        case OpinionInstanceActionTypes.ADD_OPINION_INSTANCE_SIGN_OFF_NOTES: {
            const { sectionId, fieldId, signOffConversation } = payload;
            const indexToUpdate = state.indexOfUpdatedNote;
            const currentNotes = { ...state.signOffNotesContent };
            if (!isNull(indexToUpdate)) {
                const signOffPath = fieldId ? `currentSignOff.content[${sectionId}][${fieldId}].notes[${indexToUpdate}]` : `currentSignOff.content[${sectionId}].notes[${indexToUpdate}]`;
                return flow(
                    set(signOffPath, currentNotes),
                    set('indexOfUpdatedNote', null),
                    set('signOffNotesContent', initialSignOffNotes)
                )(state);
            }
            const signOffPath = fieldId ? `currentSignOff.content[${sectionId}][${fieldId}].notes` : `currentSignOff.content[${sectionId}].notes`;
            return flow(
                set(signOffPath, [...signOffConversation.notes, currentNotes]),
                set('signOffNotesContent', initialSignOffNotes)
            )(state);
        }
        case OpinionInstanceActionTypes.SIGN_OFF_INSTANCE_UPDATED:
            return set('signOffInstanceUpdated', payload, state);
        case OpinionInstanceActionTypes.INSTANCE_UPDATED:
            return set('instanceUpdated', payload, state);
        case OpinionInstanceActionTypes.TOGGLE_OPEN_FIELD_SECTION: {
            const currentOpenFieldsAndSections = state.openFieldsAndSections;
            const shouldRemove = currentOpenFieldsAndSections.includes(payload);
            if (shouldRemove) {
                return set('openFieldsAndSections', currentOpenFieldsAndSections.filter(id => id !== payload), state);
            }
            return set('openFieldsAndSections', [...currentOpenFieldsAndSections, payload], state);
        }
        case OpinionInstanceActionTypes.REMOVE_ALL_FIELD_SECTIONS:
            return set('openFieldsAndSections', [], state);
        case OpinionInstanceActionTypes.TOGGLE_LINKED_OPINION_MODAL_OPEN:
            return set('linkedOpinionModalOpen', payload, state);
        case OpinionInstanceActionTypes.TOGGLE_SUB_COUNTERPARTY_TYPE_MODAL_OPEN:
            return set('subCounterpartyTypeModalOpen', payload, state);
        case OpinionInstanceActionTypes.SET_SCROLLABLE_WRAPPER:
            return set('scrollableWrapper', payload, state);
        case OpinionInstanceActionTypes.FETCH_ALL_OPINION_CLAUSES_STARTED:
            return set('clauseTableLoading', true, state);
        case OpinionInstanceActionTypes.FETCH_ALL_OPINION_CLAUSES_SUCCESSFUL: {
            const { clauses, total, pageNumber, allOpinionClauseIds, additionalProvisionsUpdated } = payload;
            return flow(
                set('clauses', clauses),
                set('allOpinionClauseIds', allOpinionClauseIds),
                set('opinionClauses', convertOpinionClause(clauses)),
                set('totalOpinionClauses', total),
                set('opinionClausePage', pageNumber),
                set('clauseTableLoading', false),
                set('additionalProvisionsUpdated', additionalProvisionsUpdated),
            )(state);
        }
        case OpinionInstanceActionTypes.FETCH_ALL_OPINION_CLAUSES_FAILED:
            return set('clauseTableLoading', false, state);
        case OpinionInstanceActionTypes.SET_OPINION_CLAUSES_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`opinionClauseFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`opinionClauseFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case OpinionInstanceActionTypes.CLEAR_OPINION_CLAUSES_TABLE_FILTERS:
            return set('opinionClauseFilters', {}, state);
        case OpinionInstanceActionTypes.SET_OPINION_CLAUSES_TABLE_COLUMN_SORT:
            return set('opinionClauseSort', payload, state);
        case OpinionInstanceActionTypes.OPEN_OPINION_CLAUSE: {
            const clause = state.clauses.find(({ clauseId }) => clauseId === payload)!;
            return flow(
                set('clause', clause,),
                set('currentClause', clause),
                set('clauseModalOpen', true)
            )(state);
        }
        case OpinionInstanceActionTypes.TOGGLE_OPINION_CLAUSE_MODAL: {
            const isOpen = !state.clauseModalOpen;
            if (!isOpen) {
                return flow(
                    set('clause', initialClause()),
                    set('clauseDescriptionOpen', false),
                    set('tagTerm', ''),
                    set('fuzzyMatchResults', []),
                    set('clauseCanSave', false),
                    set('clauseModalOpen', false)
                )(state);
            }
            return flow(
                set('clause', initialClause(payload)),
                set('clauseModalOpen', isOpen)
            )(state);
        }
        case OpinionInstanceActionTypes.TOGGLE_OPINION_CLAUSE_DESCRIPTION:
            return set('clauseDescriptionOpen', !state.clauseDescriptionOpen, state);
        case OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE: {
            const { key, value } = payload;
            if (key === 'systemTemplate' && value === 1) {
                const systemAdminMiscellaneous = state.clauseLibraryDropdownOptions.miscellaneousSystemOptions.map(({ label }) => label);
                const filteredTags = state.clause.tags.miscellaneous.filter(tag => systemAdminMiscellaneous.includes(tag));
                return flow(
                    set('clause.tags.miscellaneous', filteredTags),
                    set(`clause.${key}`, value),
                )(state);
            }
            return set(`clause.${key}`, value, state);
        }
        case OpinionInstanceActionTypes.SAVE_OPINION_CLAUSE_SUCCESSFUL: {
            const { clause, closeModal } = payload;
            if (closeModal) {
                return flow(
                    set('clause', initialClause()),
                    set('clauseDescriptionOpen', true),
                    set('currentClause', null),
                    set('tagTerm', ''),
                    set('fuzzyMatchResults', []),
                    set('clauseCanSave', false),
                    set('clauseModalOpen', false)
                )(state);
            }
            return flow(
                set('clause', clause,),
                set('currentClause', clause),
                set('clauseCanSave', false)
            )(state);
        }
        case OpinionInstanceActionTypes.SET_OPINION_CLAUSE_CAN_SAVE:
            return set('clauseCanSave', payload, state);
        case OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE_TAGS: {
            const { key, value } = payload;
            const updatedTags = !isNull(value) ? value : [];
            return set(`clause.tags.${key}`, updatedTags, state);
        }
        case OpinionInstanceActionTypes.OPINION_CLAUSE_UPDATE_ALL_CLIENT_TAGS:
            return set('allClientTags', payload, state);
        case OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE_TAG_TERM:
            return set('tagTerm', payload, state);
        case OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE_FUZZY_MATCH_RESULTS:
            return flow(
                set('fuzzyMatchResults', payload,),
                set('fuzzyMatchModalOpen', payload.length > 0),
            )(state);
        case OpinionInstanceActionTypes.SET_OPINION_CLAUSE_FUZZY_MATCH_MODAL_OPEN:
            return set('fuzzyMatchModalOpen', payload, state);
        case OpinionInstanceActionTypes.FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_SUCCESSFUL:
            return set('clauseLibraryDropdownOptions', payload, state);
        case OpinionInstanceActionTypes.TOGGLE_LINK_OPINION_CLAUSE_MODAL:
            return set('linkOpinionClauseModalOpen', !state.linkOpinionClauseModalOpen, state);
        case OpinionInstanceActionTypes.DUPLICATE_OPINION_CLAUSE: {
            const { duplicateClauseId, isSave, hasSystemClausePermission } = payload;
            const clause = state.clause?.clauseId === duplicateClauseId ? state.clause : state.clauses.find(({ clauseId }) => clauseId === duplicateClauseId)!;
            const newClause = createDuplicateClause(clause, isSave, hasSystemClausePermission);
            return flow(
                set('clause', newClause),
                set('currentClause', newClause),
                set('tagTerm', ''),
                set('fuzzyMatchResults', []),
                set('clauseModalOpen', true)
            )(state);
        }
        case OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_CLIENT_TAG_SUCCESSFUL: {
            const { allClientTags }: { allClientTags: ClientTag[] } = payload;
            const clientTags = allClientTags.map(({ tag }) => ({ value: tag, label: tag })) || [];
            return flow(
                set('allClientTags', allClientTags),
                set('clauseLibraryDropdownOptions.miscellaneousOptions', [...state.clauseLibraryDropdownOptions.miscellaneousSystemOptions, ...clientTags]),
                set('tagTerm', ''),
            )(state);
        }
        case OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_SYSTEM_TAG_SUCCESSFUL: {
            const { key, value } = payload;
            if (!isUndefined(value)) {
                const updatedTags = !isNull(value) ? value : [];
                return flow(
                    set('tagTerm', ''),
                    set(`clause.tags.${key}`, updatedTags)
                )(state);
            }
            return set('tagTerm', '', state);
        }
        case OpinionInstanceActionTypes.UPDATE_AGREEMENT_TYPE_SEARCH:
            return set('agreementCoverageSearchTerm', payload, state);
        case OpinionInstanceActionTypes.TOGGLE_SIGN_OFF_MODAL_OPEN:
            return set('signOffModalOpen', payload, state);
        case OpinionInstanceActionTypes.UPDATE_SIGN_OFF_FINAL_NOTES:
            return set('currentSignOff.finalApprovalNotes', payload, state);
        case OpinionInstanceActionTypes.UPSERT_SIGN_OFF_OPINION_INSTANCE_STARTED:
            return set('isSaving', true, state);
        case OpinionInstanceActionTypes.UPSERT_SIGN_OFF_OPINION_INSTANCE_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('isEditingSignOff', false),
                set('isUpdating', false),
                set('instanceUpdated', false),
                set('savedSignOff', state.currentSignOff),
                set('signOffModalOpen', false),
                set('signOffInstanceUpdated', false)
            )(state);
        case OpinionInstanceActionTypes.UPSERT_SIGN_OFF_OPINION_INSTANCE_FAILED:
            return flow(
                set('isSaving', false),
                set('isEditingSignOff', false),
                set('isUpdating', false),
                set('error', payload)
            )(state);
        case OpinionInstanceActionTypes.SET_SIGN_OFF_NOTES_MODAL_OPEN: {
            const { value, id } = payload;
            return flow(
                set('signOffNotesContent', value ? state.signOffNotesContent : initialSignOffNotes),
                set('signOffNotesModalOpen', value),
                set('sectionOrFieldId', id),
                set('isEditingSignOff', value),
                set('indexOfUpdatedNote', null)
            )(state);
        }
        case OpinionInstanceActionTypes.RESET_SIGN_OFF_OPINION_INSTANCE:
            return flow(
                set('isSaving', false),
                set('isEditingSignOff', false),
                set('isUpdating', false),
                set('instanceUpdated', false),
                set('signOffNotesContent', initialSignOffNotes),
                set('savedSignOff', state.savedSignOff),
                set('signOffModalOpen', false),
                set('signOffInstanceUpdated', false)
            )(state);
        default:
            return state;
    }
};
