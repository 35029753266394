import { isEqual, isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppSelector } from '../../../hooks/react-redux';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { FieldValue } from '../../datasets/instances/store';
import { DatasetFieldType, SingleDatasetFieldType } from '../../datasets/store';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { LongText } from '../../shared/longtext/LongText';
import { Text } from '../../shared/text/Text';
import { getAllDropdownLists } from '../dropdown-lists/store';
import styles from './AIManager.module.scss';
import { Number } from '../../shared/number/Number';

interface DefaultValueProps {
    defaultAnswer: FieldValue;
    updateQuery: (value: string | number | number[] | FieldValue) => void;
    fieldType: SingleDatasetFieldType;
    dropdownListId: number | null;
    isMultiSelect: number;
    isEditing: boolean;
}

interface DropdownDefaultProps {
    defaultAnswer: string[] | null;
    updateQuery: (value: string[] | null) => void;
    dropdownListId: number | null;
    isMultiSelect: number;
    isEditing: boolean;
}

const DropdownDefault: React.FC<DropdownDefaultProps> = ({ defaultAnswer, updateQuery, dropdownListId, isMultiSelect, isEditing }) => {
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const isMulti = useMemo(() => isMultiSelect === 1, [isMultiSelect]);

    const options = useMemo(() => dropdownLists.find(list => list.dropdownListId === dropdownListId)?.options || [], [dropdownListId, dropdownLists]);

    const mappedOptions: DropdownOption[] = useMemo(() => options.map(option => ({ value: option, label: capitaliseStartLetter(option) })), [options]);
    const mappedValue: DropdownOption[] | null = useMemo(() => defaultAnswer?.map(value => ({ value, label: capitaliseStartLetter(value) })) || null, [defaultAnswer]);

    const updateDropdownValue = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        let dropdownValue = null;
        if (!isNull(option)) {
            if (isMulti) {
                dropdownValue = (option as Options<DropdownOption>).map(({ value }) => value);
            } else {
                dropdownValue = [(option as DropdownOption).value];
            }
        }
        if (!isEqual(dropdownValue, defaultAnswer)) {
            updateQuery(dropdownValue);
        }
    }, [updateQuery, isMulti, defaultAnswer]);

    return (
        <div className={styles.dropdownFieldWrapper}>
            <Dropdown
                disabled={!isEditing}
                value={mappedValue}
                onChange={updateDropdownValue}
                isMulti={isMulti}
                options={mappedOptions}
                menuPortalTarget={document.body}
                isClearable={isEditing}
                fontWeight={500}
            />
        </div>
    );
};

export const DefaultValue: React.FC<DefaultValueProps> = ({ defaultAnswer, fieldType, updateQuery, dropdownListId, isMultiSelect, isEditing }) => {
    const defaultElement = useMemo(() => {
        switch (fieldType) {
            case DatasetFieldType.DROPDOWN:
                return <DropdownDefault defaultAnswer={defaultAnswer as string[] | null} dropdownListId={dropdownListId} isMultiSelect={isMultiSelect} isEditing={isEditing} updateQuery={updateQuery} />;
            case DatasetFieldType.TEXT:
                return <Text testId='ai-default-value' disabled={!isEditing} value={(defaultAnswer as string | null) || ''} marginBottom='0px' onChange={e => updateQuery(e.target.value)} width='100%' />;
            case DatasetFieldType.LONGTEXT:
                return <LongText testId='ai-default-value' disabled={!isEditing} value={(defaultAnswer as string | null) || ''} marginBottom='0px' onChange={updateQuery} width='100%' />;
            case DatasetFieldType.NUMBER:
                return <Number testId='ai-default-value' disabled={!isEditing} value={defaultAnswer as number | null | undefined} onChange={updateQuery} />;
            default:
                return null;
        }
    }, [defaultAnswer, fieldType, updateQuery, dropdownListId, isMultiSelect, isEditing]);

    return (
        <div className={styles.defaultValueWrapper} data-testid='ai-default-value-wrapper'>
            {defaultElement}
        </div>
    );
};
