import { flow, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { OpinionAnalyticsActionTypes, OpinionAnalyticsChart, OpinionAnalyticsState, OpinionAverageView } from './types';
import { getDefaultSingleFieldForScope } from './utils';
import { OpinionScope } from '../../../admin/opinions/store';

export const INITIAL_STATE: OpinionAnalyticsState = {
    tileInView: null,
    fetchingAnalytics: [],
    analyticsError: null,
    singleDataPointMapAnalytics: [],
    scope: OpinionScope.ISDA_NETTING,
    singleField: getDefaultSingleFieldForScope(OpinionScope.ISDA_NETTING),
    selectedCountry: null,
    inspectedSingleFieldJurisdiction: null,
    opinionsWithAverage: [],
    averageTotal: 0,
    opinionAverageView: OpinionAverageView.OPINIONS,
    fetchingOpinionAverageBreakdown: false,
    selectedOpinionWithAverage: null,
    opinionAverageTableFilters: {},
    opinionAverageTableSort: undefined,
    viewSignOffAnswers: false
};

const addAnalyticsToFetchingArray = (state: OpinionAnalyticsState, chart: OpinionAnalyticsChart) => [...state.fetchingAnalytics, chart];
const removeAnalyticsFromFetchingArray = (state: OpinionAnalyticsState, chart: OpinionAnalyticsChart) => state.fetchingAnalytics.filter(analytics => analytics !== chart);

export const opinionAnalyticsReducer: Reducer<OpinionAnalyticsState> = (state = INITIAL_STATE, { payload, type }): OpinionAnalyticsState => {
    switch (type) {
        case OpinionAnalyticsActionTypes.SET_OPINION_ANALYTICS_TILE_IN_VIEW:
            return set('tileInView', payload.tileInView, state);
        case OpinionAnalyticsActionTypes.FETCH_OPINIONS_AVERAGE_ANALYTICS_STARTED:
        case OpinionAnalyticsActionTypes.FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_STARTED:
            return set('fetchingAnalytics', addAnalyticsToFetchingArray(state, payload.chart), state);
        case OpinionAnalyticsActionTypes.FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_SUCCESSFUL: {
            const { key, chart, analytics } = payload;
            return flow(
                set(`${key}`, analytics),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case OpinionAnalyticsActionTypes.FETCH_OPINIONS_AVERAGE_ANALYTICS_FAILED:
        case OpinionAnalyticsActionTypes.FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_FAILED: {
            const { error, chart } = payload;
            return flow(
                set('analyticsError', error),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case OpinionAnalyticsActionTypes.FETCH_OPINIONS_AVERAGE_ANALYTICS_SUCCESSFUL: {
            const { chart, opinionsWithAverage, total } = payload;
            return flow(
                set('opinionsWithAverage', opinionsWithAverage),
                set('opinionAverageTableFilters', {}),
                set('opinionAverageTableSort', undefined),
                set('averageTotal', total),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case OpinionAnalyticsActionTypes.SET_OPINION_ANALYTICS_SCOPE:
            return set('scope', payload, state);
        case OpinionAnalyticsActionTypes.SET_OPINION_SINGLE_FIELD:
            return set('singleField', payload, state);
        case OpinionAnalyticsActionTypes.SET_SELECTED_COUNTRY:
            return set('selectedCountry', payload, state);
        case OpinionAnalyticsActionTypes.SET_SELECTED_SINGLE_FIELD_JURISDICTION:
            return set('inspectedSingleFieldJurisdiction', payload, state);
        case OpinionAnalyticsActionTypes.SET_OPINION_AVERAGE_VIEW: {
            if (payload === OpinionAverageView.BREAKDOWN) {
                return set('opinionAverageView', payload, state);
            }
            return flow(
                set('opinionAverageView', payload),
                set('selectedOpinionWithAverage', null)
            )(state);
        }
        case OpinionAnalyticsActionTypes.FETCH_OPINION_AVERAGE_BREAKDOWN_STARTED:
            return set('fetchingOpinionAverageBreakdown', true, state);
        case OpinionAnalyticsActionTypes.FETCH_OPINION_AVERAGE_BREAKDOWN_SUCCESSFUL:
            return flow(
                set('fetchingOpinionAverageBreakdown', false),
                set('selectedOpinionWithAverage', payload)
            )(state);
        case OpinionAnalyticsActionTypes.FETCH_OPINION_AVERAGE_BREAKDOWN_FAILED:
            return set('fetchingOpinionAverageBreakdown', false, state);
        case OpinionAnalyticsActionTypes.SET_OPINION_AVERAGE_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`opinionAverageTableFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`opinionAverageTableFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case OpinionAnalyticsActionTypes.CLEAR_OPINION_AVERAGE_TABLE_FILTERS:
            return set('opinionAverageTableFilters', {}, state);
        case OpinionAnalyticsActionTypes.SET_OPINION_AVERAGE_TABLE_COLUMN_SORT:
            return set('opinionAverageTableSort', payload, state);
        case OpinionAnalyticsActionTypes.SET_VIEW_SIGN_OFF_ANSWERS:
            return set('viewSignOffAnswers', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
