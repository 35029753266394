import { formatDateTime } from '../../utils/luxon';
import { BasicPlaybook, PlaybookMessage, PlaybookSection, isSubheaderSection } from '../admin/playbook/store';

export const ABSTRACT_ID = 'abstract';

export const sortPlaybookShelves = (allPlaybooks: BasicPlaybook[]) => {
    const playbookSystemTemplates = allPlaybooks.filter(({ isSystemTemplate }) => !!isSystemTemplate);
    const nonTemplatePlaybooks = allPlaybooks.filter(({ isSystemTemplate }) => !isSystemTemplate);
    const numberOfTemplates = playbookSystemTemplates.length;

    const topShelf: BasicPlaybook[] = nonTemplatePlaybooks.filter((playbook, index) => (index + numberOfTemplates) <= 3 || ((index + numberOfTemplates) > 5 && (index + numberOfTemplates) % 2 !== 0));
    const bottomShelf: BasicPlaybook[] = nonTemplatePlaybooks.filter((playbook, index) => ((index + numberOfTemplates) > 3 && (index + numberOfTemplates) <= 5) || ((index + numberOfTemplates) > 5 && (index + numberOfTemplates) % 2 === 0));
    return { topShelf: [...playbookSystemTemplates, ...topShelf], bottomShelf };
};

export const topTiltLeft = [4, 9, 14, 24, 29, 36, 52, 66, 72, 88, 93];
export const topTiltRight = [8, 17, 23, 41, 49, 57, 65, 74, 96];

export const bottomTiltLeft = [7, 14, 25, 36, 44, 65, 75, 88, 91];
export const bottomTiltRight = [2, 6, 12, 24, 41, 49, 57, 68, 74, 82, 94];

export const bookTiltedLeft = (index: number, topShelfLength: number, isTopShelf: boolean) => (isTopShelf ? topTiltLeft.includes(index) : bottomTiltLeft.includes(index)) && index !== topShelfLength;
export const bookTiltedRight = (index: number, bottomShelfLength: number, isTopShelf: boolean) => (isTopShelf ? topTiltRight.includes(index) : bottomTiltRight.includes(index)) && index !== bottomShelfLength;

export const initialUserQuery = (playbookName: string, version: string, sectionTitle: string, signOff: string, userId: number, username: string): PlaybookMessage => {
    const queryTitle = `${playbookName} ${version}`;
    return ({
        content: {
            'blocks': [
                {
                    'key': 'e2roj',
                    'text': `Playbook: ${queryTitle}`,
                    'type': 'unstyled',
                    'depth': 0,
                    'inlineStyleRanges': [
                        {
                            'offset': 0,
                            'length': 10,
                            'style': 'BOLD'
                        }
                    ],
                    'entityRanges': [],
                    'data': {}
                },
                {
                    'key': 'emfmb',
                    'text': `Provision/Section: ${sectionTitle}`,
                    'type': 'unstyled',
                    'depth': 0,
                    'inlineStyleRanges': [
                        {
                            'offset': 0,
                            'length': 18,
                            'style': 'BOLD'
                        }
                    ],
                    'entityRanges': [],
                    'data': {}
                },
                {
                    'key': '7pd3p',
                    'text': '',
                    'type': 'unstyled',
                    'depth': 0,
                    'inlineStyleRanges': [],
                    'entityRanges': [],
                    'data': {}
                },
                {
                    'key': '18dav',
                    'text': '[Please provide details of the query]',
                    'type': 'unstyled',
                    'depth': 0,
                    'inlineStyleRanges': [],
                    'entityRanges': [],
                    'data': {}
                },
                {
                    'key': '7yh6f',
                    'text': '',
                    'type': 'unstyled',
                    'depth': 0,
                    'inlineStyleRanges': [],
                    'entityRanges': [],
                    'data': {}
                },
                {
                    'key': '7oil3',
                    'text': signOff,
                    'type': 'unstyled',
                    'depth': 0,
                    'inlineStyleRanges': [
                        {
                            'offset': 0,
                            'length': signOff.length,
                            'style': 'ITALIC'
                        }
                    ],
                    'entityRanges': [],
                    'data': {}
                }
            ],
            'entityMap': {}
        },
        userId,
        username,
        date: formatDateTime()
    });
};

export const getProvisionTitle = (id: string, sections: PlaybookSection[]) => {
    if (id === ABSTRACT_ID) {
        return 'Abstract';
    }
    const section = sections.find(({ sectionId }) => sectionId === id);
    if (section) {
        return isSubheaderSection(section) ? section.title : section.title.filter(item => !!item).join(': ');
    }
    return '';
};
