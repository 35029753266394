import { action } from 'typesafe-actions';

import { ColumnSort } from '../../../shared/table/ArkTable';
import { AIGeneratedEntityField, BaseEntity, BranchesFuzzyMatch, ClassificationCompanyEntity, ClassifySoftSearchEntityRequest, CompanyEntityBranch, CompanyEntityContent, CompanyEntitySection, Entity, EntityActionTypes, EntityAIFieldKey, EntityDB, EntityModalType, EntityType, FuzzyAutoCompletions, GroupEntity, GroupEntityContent, Lei, NettingEngineEntity, PersonEntityContent, SearchEntityBy, SubCounterpartyType } from './types';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { FunctionMigration } from '../../../dora/my-companies/store';

export const toggleModal = (value: EntityModalType | null, entity?: Entity) => action(EntityActionTypes.TOGGLE_ENTITY_MODAL, { value, entity });

export const updateEntityValue = (key: string, value: string | Date | null | number) => action(EntityActionTypes.UPDATE_ENTITY_VALUE, { key, value });
export const updateEntityContent = (key: keyof PersonEntityContent | keyof CompanyEntityContent | keyof GroupEntityContent, value: string | string[] | Date | null | CompanyEntityBranch[] | number) => action(EntityActionTypes.UPDATE_ENTITY_CONTENT, { key, value });
export const updateEntityAIField = (key: EntityAIFieldKey, secondaryKey: keyof AIGeneratedEntityField, value: string | null | boolean) => action(EntityActionTypes.UPDATE_ENTITY_AI_FIELD, { key, secondaryKey, value });

export const upsertEntityHandler = () => action(EntityActionTypes.UPSERT_ENTITY_HANDLER);

export const upsertEntityStarted = () => action(EntityActionTypes.UPSERT_ENTITY_STARTED);
export const upsertEntitySuccessful = (entities: EntityDB[], total: number) => action(EntityActionTypes.UPSERT_ENTITY_SUCCESSFUL, { entities, total });
export const upsertEntityFailed = (error: string) => action(EntityActionTypes.UPSERT_ENTITY_FAILED, error);

export const toggleMigrateDoraFunctionConfirmationModal = (isOpen: boolean) => action(EntityActionTypes.TOGGLE_MIGRATE_DORA_FUNCTION_CONFIRMATION_MODAL, isOpen);

export const fetchAvailableDoraFunctionsForMigrationStarted = (entityId: number) => action(EntityActionTypes.FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_STARTED, entityId);
export const fetchAvailableDoraFunctionsForMigrationSuccessful = (functionMigration: FunctionMigration) => action(EntityActionTypes.FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_SUCCESSFUL, functionMigration);
export const fetchAvailableDoraFunctionsForMigrationFailed = (error: string) => action(EntityActionTypes.FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_FAILED, error);

export const updateDoraCompanyFunctionMigrations = (functionId: number, value: number | null) => action(EntityActionTypes.UPDATE_DORA_COMPANY_FUNCTION_MIGRATIONS, { functionId, value });

export const migrateDoraCompanyFunctionsStarted = () => action(EntityActionTypes.MIGRATE_DORA_COMPANY_FUNCTIONS_STARTED);
export const migrateDoraCompanyFunctionsSuccessful = () => action(EntityActionTypes.MIGRATE_DORA_COMPANY_FUNCTIONS_SUCCESSFUL);
export const migrateDoraCompanyFunctionsFailed = (error: string) => action(EntityActionTypes.MIGRATE_DORA_COMPANY_FUNCTIONS_FAILED, error);

export const searchEntitiesStarted = (pageNumber = 1) => action(EntityActionTypes.SEARCH_ENTITIES_STARTED, pageNumber);
export const searchEntitiesSuccessful = (entities: Lei[], total?: number, pageNumber?: number) => action(EntityActionTypes.SEARCH_ENTITIES_SUCCESSFUL, { entities, total, pageNumber });
export const searchEntitiesFailed = (error: string) => action(EntityActionTypes.SEARCH_ENTITIES_FAILED, error);

export const classifySoftSearchEntityStarted = (payload: ClassifySoftSearchEntityRequest) => action(EntityActionTypes.CLASSIFY_SOFT_SEARCH_ENTITY_STARTED, payload);
export const classifySoftSearchEntitySuccessful = () => action(EntityActionTypes.CLASSIFY_SOFT_SEARCH_ENTITY_SUCCESSFUL);
export const classifySoftSearchEntityFailed = (error: string) => action(EntityActionTypes.CLASSIFY_SOFT_SEARCH_ENTITY_FAILED, error);

export const fetchPaginatedEntitiesStarted = (pageNumber = 1) => action(EntityActionTypes.FETCH_PAGINATED_ENTITIES_STARTED, { pageNumber });
export const fetchPaginatedEntitiesSuccessful = (entities: EntityDB[], total?: number, pageNumber?: number) => action(EntityActionTypes.FETCH_PAGINATED_ENTITIES_SUCCESSFUL, { entities, total, pageNumber });
export const fetchPaginatedEntitiesFailed = (error: string) => action(EntityActionTypes.FETCH_PAGINATED_ENTITIES_FAILED, error);

export const fetchAllEntitiesStarted = (includeGroups?: boolean) => action(EntityActionTypes.FETCH_ALL_ENTITIES_STARTED, includeGroups);
export const fetchAllEntitiesSuccessful = (entities: BaseEntity[]) => action(EntityActionTypes.FETCH_ALL_ENTITIES_SUCCESSFUL, entities);
export const fetchAllEntitiesFailed = (error: string) => action(EntityActionTypes.FETCH_ALL_ENTITIES_FAILED, error);

export const fetchAllNettingEntitiesStarted = () => action(EntityActionTypes.FETCH_ALL_NETTING_ENTITIES_STARTED);
export const fetchAllNettingEntitiesSuccessful = (entities: NettingEngineEntity[]) => action(EntityActionTypes.FETCH_ALL_NETTING_ENTITIES_SUCCESSFUL, entities);
export const fetchAllNettingEntitiesFailed = (error: string) => action(EntityActionTypes.FETCH_ALL_NETTING_ENTITIES_FAILED, error);

export const fetchAllCompanyClassificationEntitiesStarted = () => action(EntityActionTypes.FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_STARTED);
export const fetchAllCompanyClassificationEntitiesSuccessful = (entities: ClassificationCompanyEntity[]) => action(EntityActionTypes.FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_SUCCESSFUL, entities);
export const fetchAllCompanyClassificationEntitiesFailed = (error: string) => action(EntityActionTypes.FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_FAILED, error);

export const assertEntityClassificationStarted = (entity: ClassificationCompanyEntity) => action(EntityActionTypes.ASSERT_ENTITY_CLASSIFICATION_STARTED, entity);
export const assertEntityClassificationSuccessful = (updatedEntity: ClassificationCompanyEntity) => action(EntityActionTypes.ASSERT_ENTITY_CLASSIFICATION_SUCCESSFUL, updatedEntity);
export const assertEntityClassificationFailed = (entityId: number) => action(EntityActionTypes.ASSERT_ENTITY_CLASSIFICATION_FAILED, entityId);

export const updateClassificationEntitiesStarted = () => action(EntityActionTypes.UPDATE_CLASSIFICATION_ENTITIES_STARTED);
export const updateClassificationEntitiesSuccessful = () => action(EntityActionTypes.UPDATE_CLASSIFICATION_ENTITIES_SUCCESSFUL);
export const updateClassificationEntitiesFailed = (error: string) => action(EntityActionTypes.UPDATE_CLASSIFICATION_ENTITIES_FAILED, error);

export const setEntityClassificationFilter = (filterValue: string) => action(EntityActionTypes.SET_ENTITY_CLASSIFICATION_FILTER, filterValue);
export const toggleEntityClassificationCompleted = (showCompleted: boolean) => action(EntityActionTypes.TOGGLE_ENTITY_CLASSIFICATION_COMPLETED, showCompleted);
export const updateEntityClassificationField = (entityId: number, key: EntityAIFieldKey, secondaryKey: keyof AIGeneratedEntityField, value: string | null | boolean) => action(EntityActionTypes.UPDATE_ENTITY_CLASSIFICATION_FIELD, { entityId, key, secondaryKey, value });

export const toggleEntityClassificationCloseModal = (isOpen: boolean) => action(EntityActionTypes.TOGGLE_ENTITY_CLASSIFICATION_CLOSE_CONFIRMATION_MODAL_OPEN, isOpen);
export const setEntityClassificationHasUpdated = (hasUpdated: boolean) => action(EntityActionTypes.SET_ENTITY_CLASSIFICATION_HAS_UPDATED, hasUpdated);

export const updateAddressEntityValue = (key: string, value: string | null, index?: number | undefined) => action(EntityActionTypes.UPDATE_ADDRESS_ENTITY_VALUE, { key, value, index });

export const addOtherAddress = () => action(EntityActionTypes.ADD_ALTERNATIVE_ADDRESS);
export const removeAlternativeAddress = (index: number | undefined) => action(EntityActionTypes.REMOVE_ALTERNATIVE_ADDRESS, index);

export const addCompanyBicCode = () => action(EntityActionTypes.ADD_COMPANY_BIC_CODE);
export const removeCompanyBicCode = (index: number) => action(EntityActionTypes.REMOVE_COMPANY_BIC_CODE, index);

export const addNewLeiEntityStarted = (lei: Lei) => action(EntityActionTypes.ADD_LEI_ENTITY_STARTED, lei);
export const addNewLeiEntitySuccessful = (entities: EntityDB[], total: number) => action(EntityActionTypes.ADD_LEI_ENTITY_SUCCESSFUL, { entities, total });
export const addNewLeiEntityFailed = (error: string) => action(EntityActionTypes.ADD_LEI_ENTITY_FAILED, error);

export const selectEntityType = (type: EntityType) => action(EntityActionTypes.SELECT_ENTITY_TYPE, type);

export const setSearchEntityValue = (value: string) => action(EntityActionTypes.SET_SEARCH_ENTITY_VALUE, value);
export const setSearchEntityType = (type: SearchEntityBy | null) => action(EntityActionTypes.SET_SEARCH_ENTITY_TYPE, type);
export const resetEntitySearch = () => action(EntityActionTypes.RESET_ENTITY_SEARCH);

export const fetchMyCompanyEntitiesStarted = () => action(EntityActionTypes.FETCH_MY_COMPANY_ENTITIES_STARTED);
export const fetchMyCompanyEntitiesSuccessful = (entities: BaseEntity[]) => action(EntityActionTypes.FETCH_MY_COMPANY_ENTITIES_SUCCESSFUL, entities);
export const fetchMyCompanyEntitiesFailed = (error: string) => action(EntityActionTypes.FETCH_MY_COMPANY_ENTITIES_FAILED, error);

export const fetchMyEntityGroupsStarted = () => action(EntityActionTypes.FETCH_MY_GROUP_ENTITIES_STARTED);
export const fetchMyEntityGroupsSuccessful = (groupEntities: GroupEntity[], companyEntityGroupIds: string[]) => action(EntityActionTypes.FETCH_MY_GROUP_ENTITIES_SUCCESSFUL, { groupEntities, companyEntityGroupIds });
export const fetchMyEntityGroupsFailed = (error: string) => action(EntityActionTypes.FETCH_MY_GROUP_ENTITIES_FAILED, error);

export const updateGroupEntityIds = (value: string[] | null) => action(EntityActionTypes.UPDATE_GROUP_ENTITY_IDS, value);

export const setCompanyEntityGroupIds = (value: string[]) => action(EntityActionTypes.SET_COMPANY_ENTITY_GROUP_IDS, value);

export const selectCompanyEntitySection = (section: CompanyEntitySection) => action(EntityActionTypes.SELECT_COMPANY_ENTITY_SECTION, section);

export const checkBranchesFuzzyMatch = (value: string, index: number) => action(EntityActionTypes.CHECK_BRANCHES_FUZZY_MATCH, { value, index });

export const setBranchesFuzzyMatchModalOpen = (value: boolean) => action(EntityActionTypes.SET_BRANCHES_FUZZY_MATCH_MODAL_OPEN, value);

export const updateBranchesFuzzyMatchResults = (tags: BranchesFuzzyMatch[], index: number) => action(EntityActionTypes.UPDATE_BRANCHES_FUZZY_MATCH_RESULTS, { tags, index });

export const addEntityBranch = () => action(EntityActionTypes.ADD_COMPANY_ENTITY_BRANCH);

export const deleteEntityBranch = (index: number) => action(EntityActionTypes.DELETE_COMPANY_ENTITY_BRANCH, index);

export const updateBranchInformation = (key: keyof CompanyEntityBranch, value: string | string[] | number | null | boolean, index: number) => action(EntityActionTypes.UPDATE_ENTITY_BRANCH_INFORMATION, { key, value, index });

export const fetchFuzzyAutoCompletionsStarted = () => action(EntityActionTypes.FETCH_FUZZY_AND_AUTOCOMPLETIONS_STARTED);
export const fetchFuzzyAutoCompletionsSuccessful = (fuzzyAutoCompletions: FuzzyAutoCompletions) => action(EntityActionTypes.FETCH_FUZZY_AND_AUTOCOMPLETIONS_SUCCESSFUL, fuzzyAutoCompletions);
export const fetchFuzzyAutoCompletionsFailed = (error: string) => action(EntityActionTypes.FETCH_FUZZY_AND_AUTOCOMPLETIONS_FAILED, error);

export const fetchOpinionSubCounterpartyTypesStarted = (opinionJurisdiction: string, parentCounterpartyTypeFilter?: string) => action(EntityActionTypes.FETCH_OPINION_SUB_COUNTERPARTY_TYPES_STARTED, { opinionJurisdiction, parentCounterpartyTypeFilter });
export const fetchOpinionSubCounterpartyTypesSuccessful = (subCounterpartyTypes: SubCounterpartyType[]) => action(EntityActionTypes.FETCH_OPINION_SUB_COUNTERPARTY_TYPES_SUCCESSFUL, subCounterpartyTypes);
export const fetchOpinionSubCounterpartyTypesFailed = (error: string) => action(EntityActionTypes.FETCH_OPINION_SUB_COUNTERPARTY_TYPES_FAILED, error);

export const upsertOpinionSubCounterpartyTypesStarted = (searchWithoutParentTypeFilter = false) => action(EntityActionTypes.UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_STARTED, searchWithoutParentTypeFilter);
export const upsertOpinionSubCounterpartyTypesSuccessful = (isSystem: number) => action(EntityActionTypes.UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_SUCCESSFUL, isSystem);
export const upsertOpinionSubCounterpartyTypesFailed = (error: string) => action(EntityActionTypes.UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_FAILED, error);

export const setSelectedOpinionSubCounterparty = (subCounterpartyType: SubCounterpartyType) => action(EntityActionTypes.SET_SELECTED_OPINION_SUB_COUNTERPARTY, subCounterpartyType);

export const updateOpinionSubCounterparty = (key: keyof SubCounterpartyType, value: string | number) => action(EntityActionTypes.UPDATE_OPINION_SUB_COUNTERPARTY_VALUE, { key, value });

export const selectSubCounterpartyTypesJurisdiction = (value: string | null) => action(EntityActionTypes.SET_SUB_COUNTERPARTY_JURISDICTION, value);

export const selectSubCounterpartyTypesParent = (value: string) => action(EntityActionTypes.SET_SUB_COUNTERPARTY_PARENT, value);

// Table Filters and Pagination
export const setEntityTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(EntityActionTypes.SET_ENTITY_TABLE_FILTERS, { key, value, type });
export const clearEntityTableFilters = () => action(EntityActionTypes.CLEAR_ENTITY_TABLE_FILTERS);
export const setEntityTableColumnSort = (columnSort: ColumnSort | undefined) => action(EntityActionTypes.SET_ENTITY_TABLE_COLUMN_SORT, columnSort);
export const setEntitiesPageSize = (pageSize: number) => action(EntityActionTypes.SET_ENTITIES_PAGE_SIZE, pageSize);
export const entitiesPaginationNext = () => action(EntityActionTypes.ENTITIES_PAGINATION_NEXT);
export const entitiesPaginationPrevious = () => action(EntityActionTypes.ENTITIES_PAGINATION_PREVIOUS);

export const setEntitiesSearchPageSize = (pageSize: number) => action(EntityActionTypes.SET_ENTITIES_SEARCH_PAGE_SIZE, pageSize);
export const entitiesSearchPaginationNext = () => action(EntityActionTypes.ENTITIES_SEARCH_PAGINATION_NEXT);
export const entitiesSearchPaginationPrevious = () => action(EntityActionTypes.ENTITIES_SEARCH_PAGINATION_PREVIOUS);
