import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DatasetSection } from '../../datasets/store';
import { Button } from '../../shared/button/Button';
import { DraggableItem } from '../../shared/drag-n-drop/shared';
import { Section } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { OverflowTooltip } from '../../shared/tooltip';
import styles from './DatasetBuilder.module.scss';
import { getReorderSectionsModalOpen, getSections, toggleReorderSectionsModal, updateSections } from './store';
import { DragDrop } from '../../shared/drag-n-drop/DragDrop';

export const ReorderSectionsModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const sections = useAppSelector(getSections);
    const isOpen = useAppSelector(getReorderSectionsModalOpen);

    const closeModal = () => dispatch(toggleReorderSectionsModal());

    const saveSectionsOrder = useCallback((sections: DatasetSection[]) => dispatch(updateSections(sections)), [dispatch]);

    const listOptions = useMemo(() => sections?.map(({ id, label }) => ({ id, label })) || [], [sections]);

    const updateList = useCallback((list: DraggableItem[]) => {
        const updatedSections = list.map(({ id }) => sections!.find(section => section.id === id)!);
        saveSectionsOrder(updatedSections);
    }, [saveSectionsOrder, sections]);

    const getChildElement = useCallback((childId: string) => {
        const listOption = listOptions.find(({ id }) => id === childId);
        if (listOption) {
            return (
                <div className={styles.reorderTile}>
                    <OverflowTooltip className={styles.reorderTileTitle} overlayText={listOption.label || 'Section has no title'} testId={`reorder-section-tile-${childId}`} />
                </div>
            );
        }
        return null;
    }, [listOptions]);

    if (!sections) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            className={styles.reorderModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 9 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.reorderWrapper} data-testid='reorder-sections-modal-wrapper'>
                <ModalHeader label='Reorder Sections' icon={Section} />
                <div className={styles.scrollableWrapper}>
                    <DragDrop getChildElement={getChildElement} list={listOptions} listId='dataset-sections' updateList={updateList} />
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' />
                </div>
            </div>
        </Modal>
    );
};
