import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Spinner } from '../../shared/spinner/Spinner';
import { getDocumentPreviewModalOpen, getIsSearching, getSearchResults, openDocumentPreviewStarted, openIncompleteSearchDocument, SearchDocumentsView, SearchResult, toggleDocumentPreviewModal } from '../search/store';
import { openDocumentAndInstance } from '../my-documents/store';
import { DocumentPreviewModal } from './DocumentPreviewModal';
import { Result } from './Result';
import styles from './SearchDocuments.module.scss';

const TOP_NAV = 100;
const SEARCH_HEADER = 35;
const FOOTER = 30;
const SEARCH_TERM_BUTTONS = 58;
const remainingPageHeight = TOP_NAV + SEARCH_HEADER + SEARCH_TERM_BUTTONS + FOOTER;

interface SearchResultsProps {
    resultsType: SearchDocumentsView | null;
}

export const SearchResults: React.FC<SearchResultsProps> = ({ resultsType }) => {
    const [, screenHeight] = useWindowResize();
    const isSearching = useAppSelector(getIsSearching);
    const searchResults = useAppSelector(getSearchResults);
    const documentPreviewModalOpen = useAppSelector(getDocumentPreviewModalOpen);
    const dispatch = useAppDispatch();
    const openPreview = (result: SearchResult) => dispatch(openDocumentPreviewStarted(result));
    const openDocument = (documentId: string, datasetInstanceId: string) => dispatch(openDocumentAndInstance(documentId, datasetInstanceId));
    const completeDocument = (documentId: number) => dispatch(openIncompleteSearchDocument(documentId));
    const closePreview = () => dispatch(toggleDocumentPreviewModal(false));

    const resultsHeight = useMemo(() => screenHeight - remainingPageHeight, [screenHeight]);

    if (isSearching) {
        return (
            <div className={styles.searchResultsWrapper} style={{ height: `${resultsHeight}px` }}>
                <Spinner size={70} />
            </div>
        );
    }

    if (!searchResults || !resultsType) {
        return (
            <div className={styles.searchResultsWrapper} data-testid='unsearched-results-wrapper' style={{ height: `${resultsHeight}px` }} />
        );
    }

    if (searchResults && !searchResults.length) {
        return (
            <div className={styles.searchResultsWrapper} data-testid='empty-search-results-wrapper' style={{ height: `${resultsHeight}px` }}>
                <div className={styles.noResults}>We did not find any documents matching your search</div>
            </div>
        );
    }

    return (
        <div className={styles.searchResultsWrapper} data-testid='search-results-wrapper' style={{ height: `${resultsHeight}px` }}>
            <Scrollable>
                {searchResults.map(result => <Result
                    key={result.documentId}
                    result={result}
                    openPreview={openPreview}
                    openDocument={openDocument}
                    resultsType={resultsType}
                    completeDocument={completeDocument}
                />)}
            </Scrollable>
            <DocumentPreviewModal
                isOpen={documentPreviewModalOpen}
                closeModal={closePreview}
                openDocument={openDocument}
                completeDocument={completeDocument}
            />
        </div>
    );
};
