import React, { useMemo } from 'react';

import { SingleFieldPreview } from './SingleFieldPreview';
import { GroupDatasetField } from '../../../../datasets/store';
import styles from './FormPreview.module.scss';
import { fieldTypeWidths, MAX_GROUP_WIDTH } from '../../FieldList';

interface GroupFieldPreviewProps {
    field: GroupDatasetField;
    groupIndex: number;
    isLastFieldInSection?: boolean;
    datasetBuilder?: boolean;
}

export const GroupFieldPreview: React.FC<GroupFieldPreviewProps> = ({ field, groupIndex, isLastFieldInSection, datasetBuilder }) => {
    const { children } = field;

    const groupWidth = useMemo(() => children.reduce((acc, cur) => acc + (fieldTypeWidths[cur.type] / MAX_GROUP_WIDTH), 0), [children]);
    return (
        <div className={styles.groupFieldWrapper} data-testid={`form-preview-groupfield-${groupIndex}-wrapper`}>
            {children.map((field, index) => {
                const isLastFieldInGroup = index === children.length - 1;
                const singleIndex = `${groupIndex}${index}`;
                const width = fieldTypeWidths[field.type] / groupWidth * 0.98;
                return (
                    <SingleFieldPreview
                        key={field.id}
                        field={field}
                        isLastFieldInGroup={isLastFieldInGroup}
                        singleIndex={singleIndex}
                        index={index}
                        groupIndex={groupIndex}
                        width={width}
                        isLastFieldInSection={isLastFieldInSection}
                        datasetBuilder={datasetBuilder}
                    />
                );
            })}
        </div>
    );
};
