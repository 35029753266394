import React, { useMemo } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { isUndefined } from 'lodash/fp';

import { tooltipColors, Colors } from './Tooltip';
import { Icon } from '../icon/Icon';
import styles from './Tooltip.module.scss';

const { primary } = styles;

interface IconTooltipProps {
    content: string | null;
    color?: keyof Colors;
    width?: number;
    trigger?: string;
    placement?: string;
    fontSize?: number;
    icon: React.FC;
    fill?: string;
    iconColor?: string;
    cursor?: string;
    margin?: string;
}

export const IconTooltip: React.FC<IconTooltipProps> = ({ content, color = 'french', width = 200, trigger = 'hover', placement = 'right', fontSize = 18, icon, fill = 'white', iconColor = primary, cursor, margin = 'none' }) => {

    const overlay = content && <span style={{ maxWidth: `${width - 18}px` }} className={styles.overlayTextStyle}>{content}</span>;

    const { backgroundColor, fontColor } = tooltipColors[color];

    const tooltipStyles = {
        backgroundColor,
        color: fontColor,
        borderColor: fontColor,
        borderRadius: '5px',
        minHeight: '10px',
        display: content ? 'flex' : 'none'
    };

    const cursorStyle = useMemo(() => {
        let cursorStyle = trigger === 'click' ? 'pointer' : 'default';
        if (!isUndefined(cursor)) cursorStyle = cursor;
        return cursorStyle;
    }, [cursor, trigger]);

    const infoStyles = {
        height: `${fontSize + 1}px`,
        width: `${fontSize + 1}px`,
        minHeight: `${fontSize + 1}px`,
        minWidth: `${fontSize + 1}px`,
        fontSize: `${fontSize}px`,
        cursor: cursorStyle,
        margin: margin
    };

    const overlayStyle = {
        maxWidth: `${width}px`
    };

    return (
        <Tooltip
            placement={placement}
            overlay={overlay}
            trigger={trigger}
            overlayClassName={styles.overlay}
            overlayInnerStyle={tooltipStyles}
            overlayStyle={overlayStyle}
        >
            <div style={infoStyles}>
                <Icon icon={icon} fontSize={fontSize} fill={fill} color={iconColor} />
            </div>
        </Tooltip>
    );
};
