import { RawDraftContentState } from 'draft-js';

import { WorldCountriesList } from '../../../constants/worldCountriesList';
import { DropdownDetailsSignOffSmileyAndNote, OpinionInstanceContent } from '../../instances/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { OpinionScope, OpinionCommissionedBy } from '../../../admin/opinions/store';

export enum OpinionAnalyticsActionTypes {
    FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_STARTED = 'FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_STARTED',
    FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_SUCCESSFUL = 'FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_SUCCESSFUL',
    FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_FAILED = 'FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_FAILED',
    SET_OPINION_SINGLE_FIELD = 'SET_OPINION_SINGLE_FIELD',
    SET_OPINION_ANALYTICS_SCOPE = 'SET_OPINION_ANALYTICS_SCOPE',
    SET_SELECTED_COUNTRY = 'SET_SELECTED_OPINION_COUNTRY',
    SET_SELECTED_SINGLE_FIELD_JURISDICTION = 'SET_SELECTED_SINGLE_FIELD_JURISDICTION',
    FETCH_OPINIONS_AVERAGE_ANALYTICS_STARTED = 'FETCH_OPINIONS_AVERAGE_ANALYTICS_STARTED',
    FETCH_OPINIONS_AVERAGE_ANALYTICS_SUCCESSFUL = 'FETCH_OPINIONS_AVERAGE_ANALYTICS_SUCCESSFUL',
    FETCH_OPINIONS_AVERAGE_ANALYTICS_FAILED = 'FETCH_OPINIONS_AVERAGE_ANALYTICS_FAILED',
    SET_OPINION_AVERAGE_VIEW = 'SET_OPINION_AVERAGE_VIEW',
    FETCH_OPINION_AVERAGE_BREAKDOWN_STARTED = 'FETCH_OPINION_AVERAGE_BREAKDOWN_STARTED',
    FETCH_OPINION_AVERAGE_BREAKDOWN_SUCCESSFUL = 'FETCH_OPINION_AVERAGE_BREAKDOWN_SUCCESSFUL',
    FETCH_OPINION_AVERAGE_BREAKDOWN_FAILED = 'FETCH_OPINION_AVERAGE_BREAKDOWN_FAILED',
    REDIRECT_TO_OPINION = 'REDIRECT_TO_OPINION_FROM_ANALYTICS',
    CLEAR_OPINION_AVERAGE_TABLE_FILTERS = 'CLEAR_OPINION_AVERAGE_TABLE_FILTERS',
    SET_OPINION_AVERAGE_TABLE_FILTERS = 'SET_OPINION_AVERAGE_TABLE_FILTERS',
    SET_OPINION_AVERAGE_TABLE_COLUMN_SORT = 'SET_OPINION_AVERAGE_TABLE_COLUMN_SORT',
    SET_OPINION_ANALYTICS_TILE_IN_VIEW = 'SET_OPINION_ANALYTICS_TILE_IN_VIEW',
    SET_VIEW_SIGN_OFF_ANSWERS = 'SET_VIEW_SIGN_OFF_ANSWERS'
}

export enum OpinionAnalyticsChart {
    SINGLE_DATA_POINT_JURISDICTION = 'single data point jurisdiction',
    OPINIONS_AVERAGE = 'opinions average'
}

export enum OpinionAverageView {
    OPINIONS = 'opinions',
    BREAKDOWN = 'breakdown'
}

export interface BaseOpinionAnalytics {
    opinionId: number;
    jurisdiction: string;
    focus: string;
    dateOfOpinion: string | null;
    scope: OpinionScope;
    commissionedBy: OpinionCommissionedBy;
    signOff: DropdownDetailsSignOffSmileyAndNote | null;
}

export interface PermittedOpinionMapAnalytics extends BaseOpinionAnalytics {
    isPositive: boolean;
    isNegative: boolean;
    isPermitted: true;
    dropdownValue: string | null;
    wysiwygValue: RawDraftContentState | null;
}

export interface UnpermittedOpinionMapAnalytics extends BaseOpinionAnalytics {
    isPermitted: false;
}

export type OpinionMapAnalytics = PermittedOpinionMapAnalytics | UnpermittedOpinionMapAnalytics;

export interface OpinionSingleField {
    sectionId: string;
    fieldId: string;
}

export interface OpinionMapCountry extends WorldCountriesList {
    data: OpinionMapAnalytics[];
    disabled: boolean;
}

export interface OpinionAverage extends BaseOpinionAnalytics {
    average: number;
    bespoke: number;
    commissionedByIfOther: string | null;
    location: string;
}

export type OpinionAverageSortColumn = Pick<OpinionAverage, 'average' | 'bespoke' | 'jurisdiction' | 'focus' | 'commissionedBy'>

export interface ExtensiveOpinionAverage extends OpinionAverage {
    content: OpinionInstanceContent;
}

export interface DropdownDetailsAverage {
    fieldId: string;
    sectionId: string;
    label: string;
    dropdownValue: string[] | null;
    wysiwygValue: RawDraftContentState | null;
    score: number;
}

export enum SmileyTileEnum {
    ARK_ANSWER = 'ark answer',
    CLIENT_ANSWER = 'client answer'
}

export interface OpinionAnalyticsState {
    tileInView: OpinionAnalyticsChart | null;
    fetchingAnalytics: OpinionAnalyticsChart[];
    analyticsError: string | null;
    singleDataPointMapAnalytics: OpinionMapAnalytics[];
    scope: OpinionScope;
    singleField: OpinionSingleField;
    selectedCountry: string | null;
    inspectedSingleFieldJurisdiction: string | null;
    opinionsWithAverage: OpinionAverage[];
    averageTotal: number;
    opinionAverageView: OpinionAverageView;
    selectedOpinionWithAverage: ExtensiveOpinionAverage | null;
    fetchingOpinionAverageBreakdown: boolean;
    opinionAverageTableFilters: TableFilters;
    opinionAverageTableSort: ColumnSort | undefined;
    viewSignOffAnswers: boolean;
}
