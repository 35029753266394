import { indexOf, isNull } from 'lodash/fp';

import { ArkOpinion, LinkedOpinion, ArkMapOpinion, MyOpinionsState, OpinionsTableView, OpinionsView, CountryOpinions, BritishOpinions } from './types';
import { ApplicationState } from '../../../../store/rootReducer';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { AnalysisView } from '../../../documents/my-documents/store';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { getAllOpinionCustomFilters } from '../../../auth/login/store';

const getRoot = (state: ApplicationState): MyOpinionsState => state.opinion.myOpinions;

export const getOpinionsView = (state: ApplicationState): OpinionsView => getRoot(state).opinionsView;

export const getOpinionsTableView = (state: ApplicationState): OpinionsTableView => getRoot(state).opinionsTableView;

export const getModalOpen = (state: ApplicationState): boolean => getRoot(state).modalOpen;

export const getOpinionDetailsModalOpen = (state: ApplicationState): boolean => getRoot(state).opinionDetailsModalOpen;

export const getFilesToUpload = (state: ApplicationState): File[] | null => getRoot(state).filesToUpload;

export const getIsUploading = (state: ApplicationState): boolean => getRoot(state).isUploading;

export const getIsOpening = (state: ApplicationState): boolean => getRoot(state).isOpening;

export const getIsOpeningSummary = (state: ApplicationState): boolean => getRoot(state).isOpeningSummary;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getIsUpdatingOpinion = (state: ApplicationState): boolean => getRoot(state).isUpdatingOpinionDetails;

export const getIncompleteOpinionUrl = (state: ApplicationState): string | null => getRoot(state).incompleteOpinionUrl;

export const getIncompleteOpinion = (state: ApplicationState): ArkOpinion | null => getRoot(state).incompleteOpinion;

export const getSelectedIncompleteOpinions = (state: ApplicationState): ArkOpinion[] => getRoot(state).selectedIncompleteOpinions;

export const getOpinionDetailsUpdated = (state: ApplicationState): boolean => getRoot(state).incompleteOpinionDetailsUpdated;

export const getIncompleteOpinions = (state: ApplicationState): ArkOpinion[] => getRoot(state).incompleteOpinions;

export const getOpinions = (state: ApplicationState): ArkOpinion[] => getRoot(state).opinions;

export const getOpinion = (state: ApplicationState): ArkOpinion | null => getRoot(state).opinion;
export const getOpinionId = (state: ApplicationState): number | null => getOpinion(state)?.opinionId || null;

export const getOpinionUrl = (state: ApplicationState): string | null => getRoot(state).opinionUrl;

export const getOpinionSummaryUrl = (state: ApplicationState): string | null => getRoot(state).opinionSummaryUrl;

export const getAdditionalOpinions = (state: ApplicationState): ArkOpinion[] => getRoot(state).additionalOpinions;
export const getSelectedAdditionalOpinionId = (state: ApplicationState): number | null => getRoot(state).selectedAdditionalOpinionId;

export const getAdditionalOpinionUrls = (state: ApplicationState): string[] => getRoot(state).additionalOpinionUrls;
export const getSelectedOpinionUrl = (state: ApplicationState): string | null => {
    const selectedAdditionalOpinionId = getSelectedAdditionalOpinionId(state);
    if (selectedAdditionalOpinionId) {
        const additionalOpinions = getAdditionalOpinions(state);
        const additionalOpinionIds = additionalOpinions.map(({ opinionId }) => opinionId);
        const selectedIndex = indexOf(selectedAdditionalOpinionId, additionalOpinionIds);
        const additionalOpinionUrls = getAdditionalOpinionUrls(state);
        return additionalOpinionUrls[selectedIndex];
    }
    return getOpinionUrl(state);
};

export const getOpinionAnalysisView = (state: ApplicationState): AnalysisView => getRoot(state).opinionAnalysisView;

export const getOpinionToDelete = (state: ApplicationState): ArkOpinion | null => getRoot(state).opinionToDelete;

export const getDeleteOpinionModalOpen = (state: ApplicationState): boolean => !isNull(getOpinionToDelete(state));

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;

export const getLinkedOpinions = (state: ApplicationState): LinkedOpinion[] => getRoot(state).linkedOpinions;

export const getUnauthorisedOpinion = (state: ApplicationState): LinkedOpinion | ArkOpinion | null => getRoot(state).unauthorisedOpinion;
export const getUnauthorisedOpinionModalOpen = (state: ApplicationState): boolean => !isNull(getUnauthorisedOpinion(state));

export const getPotentialBaseOpinion = (state: ApplicationState): ArkOpinion | null => getRoot(state).potentialBaseOpinion;
export const getPotentialBaseOpinionModalOpen = (state: ApplicationState): boolean => !isNull(getPotentialBaseOpinion(state));

export const getSelectedPageNumber = (state: ApplicationState): number | null => getRoot(state).selectedPageNumber;

export const getIsDownloadingSummary = (state: ApplicationState): boolean => getRoot(state).isDownloadingSummary;

export const getOpinionSummaryModalOpen = (state: ApplicationState): boolean => getRoot(state).summaryPreviewModalOpen;
export const getOpinionSummaryGenerating = (state: ApplicationState): boolean => getRoot(state).summaryGenerating;

// Table Filters and Pagination
export const getOpinionsPageNumber = (state: ApplicationState): number => getRoot(state).opinionPage;
export const getTotalOpinions = (state: ApplicationState): number => getRoot(state).totalOpinions;
export const getOpinionFilters = (state: ApplicationState): TableFilters => getRoot(state).opinionFilters;
export const getOpinionPageSize = (state: ApplicationState): number => getRoot(state).opinionPageSize;
export const getOpinionColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).opinionColumnSort;

export const getIncompleteOpinionsPageNumber = (state: ApplicationState): number => getRoot(state).incompleteOpinionPage;
export const getTotalIncompleteOpinions = (state: ApplicationState): number => getRoot(state).totalIncompleteOpinions;
export const getIncompleteOpinionFilters = (state: ApplicationState): TableFilters => getRoot(state).incompleteOpinionFilters;
export const getIncompleteOpinionPageSize = (state: ApplicationState): number => getRoot(state).incompleteOpinionPageSize;
export const getIncompleteOpinionColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).incompleteOpinionColumnSort;

// Map selectors

export const getSelectedCountry = (state: ApplicationState): string | null => getRoot(state).selectedCountry;

export const getAllOpinionsForMap = (state: ApplicationState): ArkMapOpinion[] => getRoot(state).mapOpinions;

export const getCountryOpinions = (state: ApplicationState): CountryOpinions | null => getRoot(state).countryOpinions;

export const getCountryOpinionsModalOpen = (state: ApplicationState): boolean => !isNull(getCountryOpinions(state));

export const getOpinionsMapResetZoom = (state: ApplicationState): boolean => getRoot(state).resetOpinionsMapZoom;
export const getOpinionsMapZoomSteps = (state: ApplicationState): number => getRoot(state).opinionsMapZoomSteps;
export const getOpinionsMapZoomUpdated = (state: ApplicationState): boolean => getRoot(state).opinionsMapZoomUpdated;
export const getOpinionsMapCurrentScale = (state: ApplicationState): number => getRoot(state).opinionsMapCurrentScale;
export const getOpinionsMapScaleExtent = (state: ApplicationState): [number, number] => getRoot(state).opinionsMapScaleExtent;

export const getBritishOpinions = (state: ApplicationState): BritishOpinions | null => getRoot(state).britishOpinions;
export const getBritishOpinionsModalOpen = (state: ApplicationState): boolean => !isNull(getBritishOpinions(state));

// Custom Filters
export const getOpinionCustomFiltersModalOpen = (state: ApplicationState): boolean => getRoot(state).opinionCustomFiltersModalOpen;
export const getSelectedOpinionCustomFilterId = (state: ApplicationState): string | null => getRoot(state).selectedOpinionCustomFilterId;
export const getOpinionCustomFilterHasUpdated = (state: ApplicationState): boolean => getRoot(state).opinionCustomFilterHasUpdated;
export const getSelectedCustomFilterName = (state: ApplicationState): string => {
    const allOpinionFilters = getAllOpinionCustomFilters(state);
    const selectedId = getSelectedOpinionCustomFilterId(state);
    return allOpinionFilters.find(({ id }) => id === selectedId)?.filterName || '';
};
