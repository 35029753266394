import classnames from 'classnames';
import { isEqual, isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Toggle } from '../../../shared/toggle';
import styles from '../Reports.module.scss';
import { OpinionReportField, addOpinionReportField, getAvailableOpinionReportFields, getReportFields, getOpinionSectionFieldOrder, getOpinionSectionOrder, removeOpinionReportField, getCurrentScope, updateOpinionReportFields, getOpinionScope, setCurrentScope, getOpenScopeSections, setScopeOpenSections } from '../store';
import { SectionWrapper } from './SectionWrapper';
import { WizardTab } from '../../../shared/wizard/WizardTab';
import { scopeFormatter } from '../../../shared/table/arkTableFormatters';
import { OpinionScope } from '../../../admin/opinions/store';

interface OpinionFieldProps {
    field: OpinionReportField;
    isSelected: boolean;
    toggleField: (field: OpinionReportField, deselect: boolean) => void;
    isMandatoryField?: boolean;
}

const OpinionField: React.FC<OpinionFieldProps> = ({ field, isSelected, toggleField, isMandatoryField = false }) => (
    <button onClick={() => toggleField(field, isSelected)} className={classnames(styles.reportFieldItemWrapper, {
        [styles.selectedField]: isSelected,
        [styles.mandatoryField]: isMandatoryField
    })} disabled={isMandatoryField}>
        <div className={styles.reportFieldLabel}>{field.label}</div>
        {isMandatoryField && <div>This field is required to generate your report</div>}
    </button>
);

interface ScopeTabsProps {
    reportScopes: OpinionScope[];
    currentScope: OpinionScope | null;
}

export const ScopeTabs: React.FC<ScopeTabsProps> = ({ reportScopes, currentScope }) => {
    const dispatch = useAppDispatch();

    const scopeTabs = useMemo(() => reportScopes.map(tab => ({
        label: scopeFormatter(tab),
        selected: tab === currentScope,
        onClick: () => dispatch(setCurrentScope(tab)),
    })), [reportScopes, currentScope, dispatch]);

    return (
        <>
            {reportScopes.length > 1 && <div className={styles.tabWrapper}>
                {scopeTabs.map(({ label, onClick, selected }) => (
                    <WizardTab key={label} label={label} onClick={onClick} selected={selected} />
                ))}
            </div>}
        </>
    );
};

export const OpinionReportFields: React.FC = () => {
    const dispatch = useAppDispatch();
    const reportScopes = useAppSelector(getOpinionScope);
    const currentScope = useAppSelector(getCurrentScope);
    const reportFields = useAppSelector(getReportFields);
    const availableFields = getAvailableOpinionReportFields(currentScope);
    const openSections = useAppSelector(getOpenScopeSections);
    const sections = getOpinionSectionOrder(currentScope);
    const getFieldsForSection = useCallback((currentSectionId: string) => {
        const fieldOrder = getOpinionSectionFieldOrder(currentScope, currentSectionId);
        const relevantFields = availableFields.filter(({ sectionId }) => sectionId === currentSectionId);
        const fields = fieldOrder.map(fieldId => relevantFields.find(({ id }) => id === fieldId)!);
        return fields;
    }, [currentScope, availableFields]);

    const getSectionOpen = useCallback((sectionId: string) => openSections.includes(sectionId), [openSections]);

    const toggleSection = useCallback((sectionId: string) => {
        const sectionIsOpen = getSectionOpen(sectionId);
        const updatedSections = sectionIsOpen ? openSections.filter(section => section !== sectionId) : [...openSections, sectionId];
        return dispatch(setScopeOpenSections(updatedSections, currentScope!));
    }, [openSections, getSectionOpen, currentScope, dispatch]);

    const toggleField = useCallback((field: OpinionReportField, deselect: boolean) => deselect ? dispatch(removeOpinionReportField(field.id)) : dispatch(addOpinionReportField(field)), [dispatch]);

    // We will not allow these fields to be removed as they are important for the structure of the report
    const getIsMandatoryField = useCallback((fieldId: string) => ['jurisdiction', 'focus'].includes(fieldId), []);

    const fieldIsSelected = useCallback((field: OpinionReportField) => !isNull(currentScope) ? !!reportFields[currentScope].find(({ datasetType, id, label, sectionId }) => datasetType === field.datasetType && id === field.id && label === field.label && sectionId === field.sectionId) : false, [reportFields, currentScope]);

    const sectionsHeight = useMemo(() => reportScopes.length > 1 ? 'calc(100% - 75px)' : 'calc(100% - 32px)', [reportScopes]);

    const allFields = sections.reduce((acc, { sectionId }) => [...acc, ...getFieldsForSection(sectionId)], [] as OpinionReportField[]);

    const mandatoryFields = allFields.reduce((acc, cur) => getIsMandatoryField(cur.id) ? [...acc, cur] : acc, [] as OpinionReportField[]);

    const allFieldsSelected = useMemo(() => isEqual(reportFields[currentScope!], allFields), [reportFields, allFields, currentScope]);

    const toggleAllFormFields = useCallback(() => dispatch(updateOpinionReportFields(allFieldsSelected ? mandatoryFields : allFields)), [dispatch, allFieldsSelected, mandatoryFields, allFields]);

    const toggleSectionFields = useCallback((sectionId: string) => {
        const fields = getFieldsForSection(sectionId);
        const allNonMandatoryFields = fields.reduce((acc, cur) => getIsMandatoryField(cur.id) ? acc : [...acc, cur], [] as OpinionReportField[]);
        const allFieldsSelected = isEqual(reportFields[currentScope!].filter(field => field.sectionId === sectionId), fields);
        const filteredFields = reportFields[currentScope!].reduce((acc, cur) => {
            const isMandatoryField = getIsMandatoryField(cur.id);
            if (cur.sectionId !== sectionId || (cur.sectionId === sectionId && isMandatoryField)) {
                return [...acc, cur];
            }
            return acc;
        }, [] as OpinionReportField[]);
        const updatedFields = allFieldsSelected ? filteredFields : [...filteredFields, ...allNonMandatoryFields];
        dispatch(updateOpinionReportFields(updatedFields));
    }, [dispatch, getFieldsForSection, reportFields, getIsMandatoryField, currentScope]);

    return (
        <div className={styles.reportFieldsWrapper}>
            <ScopeTabs reportScopes={reportScopes} currentScope={currentScope} />
            <div className={styles.fieldsHeader}>
                <div className={styles.fieldsTitle}>Select the fields you would like to include in your report</div>
                <div className={styles.selectAllToggleWrapper}>
                    <div className={styles.selectAllToggleLabel}>{`${allFieldsSelected ? 'Des' : 'S'}elect All Form Fields`}</div>
                    <Toggle
                        checked={allFieldsSelected}
                        onChange={toggleAllFormFields}
                    />
                </div>
            </div>
            <div className={styles.sectionsWrapper} style={{ height: sectionsHeight }}>
                <Scrollable>
                    {sections.map(({ sectionId, label }) => {
                        const fields = getFieldsForSection(sectionId);
                        const isOpen = getSectionOpen(sectionId);
                        const allFieldsSelected = isEqual(reportFields[currentScope!].filter(field => field.sectionId === sectionId), fields);
                        return (
                            <SectionWrapper key={sectionId} id={sectionId} isOpen={isOpen} toggleSection={toggleSection} toggleSectionFields={toggleSectionFields} allFieldsSelected={allFieldsSelected} label={label}>
                                <div className={styles.reportFieldsWrapper}>
                                    {fields.map(field => {
                                        const isSelected = fieldIsSelected(field);
                                        const isMandatoryField = getIsMandatoryField(field.id);
                                        return (
                                            <OpinionField key={field.id} isSelected={isSelected} field={field} toggleField={toggleField} isMandatoryField={isMandatoryField} />
                                        );
                                    })}
                                </div>
                            </SectionWrapper>
                        );
                    })}
                </Scrollable>
            </div>
        </div>
    );
};
