import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import styles from './ThirdPartyCompanies.module.scss';
import { ThirdPartyCompaniesTable } from './ThirdPartyCompaniesTable';
import { ThirdPartyAvailableCompaniesTable } from './ThirdPartyAvailableCompaniesTable';
import { ThirdPartyCompaniesModal } from './ThirdPartyCompaniesModal';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { changeThirdPartyView, DoraThirdPartyView, getThirdPartyView, toggleThirdPartySearchModalOpen } from './store';
import { DataManagementWrapper } from '../data-management/DataManagementWrapper';
import { IconButton } from '../../shared/button/IconButton';
import { Search } from '../../shared/icons';
import { ThirdPartySearchModal } from './ThirdPartySearchModal';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { FeaturePermission } from '../../admin/users/store';
import { TableTabs } from '../../shared/table/TableTabs';

export const ThirdPartyCompanies: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    const dispatch = useAppDispatch();
    const openSearchModal = useCallback(() => dispatch(toggleThirdPartySearchModalOpen(true)), [dispatch]);

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const thirdPartyView = useAppSelector(getThirdPartyView);
    const isActiveThirdParties = useMemo(() => thirdPartyView === DoraThirdPartyView.ACTIVE_THIRD_PARTIES, [thirdPartyView]);
    const header = useMemo(() => isActiveThirdParties ? 'ICT Third-Party Service Providers' : 'Available Companies', [isActiveThirdParties]);

    const changeView = useCallback((view: DoraThirdPartyView) => dispatch(changeThirdPartyView(view)), [dispatch]);

    useEffect(() => {
        if (pathname.includes('available') && isActiveThirdParties) {
            changeView(DoraThirdPartyView.AVAILABLE_ENTITIES);
        }
    }, [isActiveThirdParties, pathname, changeView]);

    const tableTabs = [
        { tabToggleAction: () => changeView(DoraThirdPartyView.ACTIVE_THIRD_PARTIES), tabTitle: 'Active Third Parties', isSelected: isActiveThirdParties },
        { tabToggleAction: () => changeView(DoraThirdPartyView.AVAILABLE_ENTITIES), tabTitle: 'Available Third Parties', isSelected: !isActiveThirdParties }
    ];

    const table = useMemo(() => isActiveThirdParties ? <ThirdPartyCompaniesTable /> : <ThirdPartyAvailableCompaniesTable />, [isActiveThirdParties]);

    return (
        <DataManagementWrapper>
            <div className={styles.thirdPartyCompaniesWrapper}>
                <div className={styles.thirdPartyCompaniesTitleContainer}>
                    <div className={styles.thirdPartyCompaniesTitle} data-testid='third-party-companies'>{header}</div>
                    {hasDoraFullAccessPermission && <IconButton icon={Search} onClick={openSearchModal} fontSize={35} testId='search-third-party-companies' />}
                </div>
                <TableTabs
                    tabs={tableTabs}
                    testId='my-companies'
                />
                {table}
                <ThirdPartyCompaniesModal />
                <ThirdPartySearchModal />
            </div>
        </DataManagementWrapper>
    );
};
