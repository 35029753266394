import { flow, isEqual, isNull, isUndefined, unset } from 'lodash/fp';
import { put, takeEvery, all, call, fork, select, debounce, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';

import { fetchAgencyFieldsByDatasetId } from '../../../../services/annexDefinition';
import { fetchAllDropdownListsStarted } from '../../dropdown-lists/store';
import { deleteDataset, fetchAllAgencyDefinitions, fetchAllDatasetDefinitions, fetchAllDocumentDatasets, publishAgencyDataset, publishDataset, getDefinitionsForDatasetId, upsertHiddenDocumentDatasetFields } from '../../../../services/datasetBuilder';
import { publishDatasetDefinitionSuccessful, publishDatasetDefinitionFailed, fetchAllDatasetDefinitionsSuccessful, fetchAllDatasetDefinitionsFailed, fetchAllDatasetDefinitionsStarted, fetchAgencyDefinitionsSuccessful, fetchAgencyDefinitionsFailed, publishAgencyDatasetDefinitionSuccessful, publishAgencyDatasetDefinitionFailed, fetchAgencyDefinitionsStarted, fetchAgencyFieldsSuccessful, fetchAgencyFieldsFailed, fetchAgencyFieldsStarted, deleteDatasetFailed, deleteDatasetStarted, deleteDatasetSuccessful, updateDefinitionNewFields, editDatasetDefinition, openDatasetDefinition, goBackToDatasetDefinition, setDatasetDefinitionHasUpdated, toggleDatasetView, fetchAllDocumentDatasetsSuccessful, fetchAllDocumentDatasetsFailed, fetchDefinitionsForDatasetIdStarted, fetchDefinitionsForDatasetIdSuccessful, fetchDefinitionsForDatasetIdFailed, saveDocumentDatasetConfigurationFailed, saveDocumentDatasetConfigurationSuccessful, setDatasetDocumentConfigUpdated } from './actions';
import { getAgencyDefinition, getAllDatasetDefinitions, getDatasetDefinition, getDefinitionClientIds, getNewDatasetFields, getPreviousDatasetIds, getSelectedDatasetId, getSavedHiddenDocumentFields, getCurrentHiddenDocumentFields, getSavedDocumentSpecificSectionIds, getCurrentDocumentSpecificSectionIds, getConfiguredDatasetParentId } from './selectors';
import { DatasetBuilderActionTypes, DatasetView, DocumentNameDatasets, DocumentSpecificHiddenFields, DocumentSpecificSectionIds, SelectedDataset } from './types';
import { AgencyDataset, AgencyDatasetField, AgencyDefinition, AgencyLinkedSingleField, DatasetDefinition, DatasetDefinitionDB, DatasetFieldType } from '../../../datasets/store';
import { getAllClientIds } from '../../clients/store';
import { getNewFields } from '../utils';
import { HiddenField, HiddenSection } from '../../my-datasets/store';

export const overwriteField = (field: AgencyDatasetField) => {
    if (field.type !== DatasetFieldType.WIZARD && field.linkedFields[0]) {
        const { label, description, refLabel, settings } = field.linkedFields[0];
        return {
            ...field,
            label,
            description,
            refLabel,
            settings
        };
    }
    return field;
};

const stripDatasetDefinition = (definition: DatasetDefinitionDB): DatasetDefinition => flow(
    unset('datasetDefinitionVersion'),
    unset('isCurrent'),
    unset('createdBy'),
    unset('createdDate'),
    unset('effectiveTo'),
    unset('clientId')
)(definition);

export function* attemptPublishDataset() {
    try {
        const datasetDefinition: DatasetDefinition = yield select(getDatasetDefinition);
        const newFields: (HiddenField | HiddenSection)[] = yield select(getNewDatasetFields);
        const allClientIds: number[] = yield select(getAllClientIds);
        const definitionClientIds: number[] = yield select(getDefinitionClientIds);
        // Make any new dataset avaiable to all clients, nothing hidden until updating when toggles can be used
        const clientIds = datasetDefinition.datasetId ? allClientIds.filter(clientId => !definitionClientIds.includes(clientId)) : [];
        yield call(publishDataset, { datasetDefinition, clientIds, newFields });
        yield put(publishDatasetDefinitionSuccessful());
        yield put(push('/admin/datasets'));
        toast(`Successfully published ${datasetDefinition.datasetType} Dataset: ${datasetDefinition.datasetTitle}`);
    } catch (e) {
        yield put(publishDatasetDefinitionFailed((e as Error).message));
        toast.error('Publishing dataset failed. Please try again.');
    } finally {
        yield put(fetchAllDatasetDefinitionsStarted());
    }
}

export function* attemptFetchDatasets() {
    try {
        const datasetDefinitions: DatasetDefinitionDB[] = yield call(fetchAllDatasetDefinitions);
        yield put(fetchAllDatasetDefinitionsSuccessful(datasetDefinitions));
    } catch (e) {
        yield put(fetchAllDatasetDefinitionsFailed((e as Error).message));
        toast.error('Could not retrieve the most recent set of published datasets. Please try again.');
    }
}

export function* attemptFetchAllDocumentDatasets() {
    try {
        const allDatasets: DocumentNameDatasets[] = yield call(fetchAllDocumentDatasets);
        yield put(fetchAllDocumentDatasetsSuccessful(allDatasets));
    } catch (e) {
        toast.error('Unable to fetch the dataset document names and types. Please try again.');
        yield put(fetchAllDocumentDatasetsFailed((e as Error).message));
    }
}

export function* attemptFetchDefinitionsForDataset({ payload }: ReturnType<typeof fetchDefinitionsForDatasetIdStarted>) {
    try {
        const { definitions, documentHiddenFields, documentSpecificMLSectionIds }: { definitions: DatasetDefinition[], documentHiddenFields: DocumentSpecificHiddenFields, documentSpecificMLSectionIds: DocumentSpecificSectionIds } = yield call(getDefinitionsForDatasetId, { datasetId: payload });
        yield put(fetchDefinitionsForDatasetIdSuccessful(definitions, documentHiddenFields, documentSpecificMLSectionIds));
    } catch (e) {
        toast.error('Unable to fetch dataset definitions. Please try again.');
        yield put(fetchDefinitionsForDatasetIdFailed((e as Error).message));
    }
}

function* definitionsForDatasetWatcher() {
    yield takeLeading(DatasetBuilderActionTypes.FETCH_DEFINITIONS_FOR_DATASET_STARTED, attemptFetchDefinitionsForDataset);
}

export function* checkDatasetDocumentConfigUpdated() {
    try {
        const savedHiddenFields: DocumentSpecificHiddenFields = yield select(getSavedHiddenDocumentFields);
        const currentHiddenFields: DocumentSpecificHiddenFields = yield select(getCurrentHiddenDocumentFields);
        const hiddenFieldsUpdated = !isEqual(Object.values(currentHiddenFields), Object.values(savedHiddenFields));
        const savedDocumentSectionIds: DocumentSpecificSectionIds = yield select(getSavedDocumentSpecificSectionIds);
        const currentDocumentSectionIds: DocumentSpecificSectionIds = yield select(getCurrentDocumentSpecificSectionIds);
        const sectionIdsUpdated = !isEqual(Object.values(currentDocumentSectionIds), Object.values(savedDocumentSectionIds));
        const hasUpdated = !!sectionIdsUpdated || !!hiddenFieldsUpdated;
        yield put(setDatasetDocumentConfigUpdated(hasUpdated));
    } catch (e) {
        toast.error('We encountered a problem checking if you had made any changes.');
    }
}

function* checkDatasetDocumentConfigUpdatedWatcher() {
    yield debounce(500, [DatasetBuilderActionTypes.TOGGLE_DOCUMENT_HIDDEN_FIELDS, DatasetBuilderActionTypes.ADD_DOCUMENT_DATASET_SECTION_REFERENCE, DatasetBuilderActionTypes.DELETE_DOCUMENT_DATASET_SECTION_REFERENCE, DatasetBuilderActionTypes.UPDATE_DOCUMENT_DATASET_SECTION_REFERENCE_ORDER], checkDatasetDocumentConfigUpdated);
}

export function* attemptUpsertDocumentDatasetConfiguration() {
    try {
        const hiddenFields: DocumentSpecificHiddenFields = yield select(getCurrentHiddenDocumentFields);
        const documentMLSections: DocumentSpecificSectionIds = yield select(getCurrentDocumentSpecificSectionIds);
        const parentDatasetId: number = yield select(getConfiguredDatasetParentId);
        yield call(upsertHiddenDocumentDatasetFields, { hiddenFields, documentMLSections, parentDatasetId });
        yield put(saveDocumentDatasetConfigurationSuccessful());
        toast('Document specific dataset configuration updated successfully.');
    } catch (e) {
        toast.error('Unable to save your dataset configuration. Please try again.');
        yield put(saveDocumentDatasetConfigurationFailed((e as Error).message));
    }
}

function* upsertDocumentDatasetConfigurationWatcher() {
    yield takeLeading(DatasetBuilderActionTypes.SAVE_DOCUMENT_DATASET_CONFIGURATION_STARTED, attemptUpsertDocumentDatasetConfiguration);
}

export function* closeDatasetDefinition() {
    yield put(push('/admin/datasets'));
}

export function* redirectDatasetDefinition({ payload }: ReturnType<typeof openDatasetDefinition>) {
    const currentDefinition: DatasetDefinition | null = yield select(getDatasetDefinition);
    const previousDatasetIds: number[] = yield select(getPreviousDatasetIds);
    const currentDatasetId = !isNull(currentDefinition) ? [currentDefinition.datasetId!] : [];
    const updatedPreviousDatasetIds = [...previousDatasetIds, ...currentDatasetId];
    yield put(editDatasetDefinition(payload, updatedPreviousDatasetIds));
    yield put(push(`/admin/datasets/${payload.datasetId!}`));
}

export function* redirectDatasetsPageView({ payload }: ReturnType<typeof toggleDatasetView>) {
    const { view, datasetId } = payload;
    const isTableView = [DatasetView.TABLE_STANDARD, DatasetView.TABLE_AGENCY].includes(view);
    if (isTableView) {
        const navigationPath = '/admin/datasets';
        yield put(push(navigationPath));
    } else {
        if (view === DatasetView.DOCUMENTS_LIST) {
            const navigationPath = '/admin/datasets/documents';
            yield put(push(navigationPath));
        } else {
            if (!isUndefined(datasetId)) {
                yield put(fetchDefinitionsForDatasetIdStarted(datasetId));
            }
            const documentsView = view === DatasetView.DOCUMENTS_PREVIEW ? 'preview' : 'configure';
            const dataset = isUndefined(datasetId) ? '' : `/${datasetId}`;
            const navigationPath = `/admin/datasets/documents/${documentsView}${dataset}`;
            yield put(push(navigationPath));
        }
    }
}

export function* redirectBackDatasetDefinition({ payload }: ReturnType<typeof goBackToDatasetDefinition>) {
    const publishedDefinitions: DatasetDefinitionDB[] = yield select(getAllDatasetDefinitions);
    const previousDatasetIds: number[] = yield select(getPreviousDatasetIds);
    const index = isUndefined(payload) ? (previousDatasetIds.length - 1) : payload;
    const previousDatasetId = previousDatasetIds[index];
    if (previousDatasetId) {
        const definitionDB = publishedDefinitions.find(({ datasetId }) => datasetId === previousDatasetId);
        if (definitionDB) {
            const datasetDefinition = stripDatasetDefinition(definitionDB);
            const updatedPreviousDatasetIds = previousDatasetIds.slice(0, index);
            yield put(editDatasetDefinition(datasetDefinition, updatedPreviousDatasetIds));
            yield put(push(`/admin/datasets/${datasetDefinition.datasetId!}`));
        }
    }
}

export function* attemptFetchAgencyDatasets() {
    try {
        const agencyDefinitions: AgencyDataset[] = yield call(fetchAllAgencyDefinitions);
        yield put(fetchAgencyDefinitionsSuccessful(agencyDefinitions));
        yield put(fetchAllDropdownListsStarted());
    } catch (e) {
        yield put(fetchAgencyDefinitionsFailed((e as Error).message));
        toast.error('Could not retrieve the most recent set of agency datasets. Please try again.');
    }
}

export function* attemptPublishAgencyDataset() {
    try {
        const definition: AgencyDefinition = yield select(getAgencyDefinition);
        const selectedDataset: SelectedDataset = yield select(getSelectedDatasetId);
        const agencyDefinition = {
            ...definition,
            datasetFields: definition.datasetFields.map(field => overwriteField(field))
        };
        yield call(publishAgencyDataset, { agencyDefinition, ...selectedDataset });
        yield put(publishAgencyDatasetDefinitionSuccessful());
        toast(`Successfully published Dataset: ${agencyDefinition.datasetTitle}`);
    } catch (e) {
        yield put(publishAgencyDatasetDefinitionFailed((e as Error).message));
        toast.error('Publishing agency dataset failed. Please try again.');
    } finally {
        yield put(fetchAgencyDefinitionsStarted());
    }
}

export function* attemptFetchAgencyFields({ payload }: ReturnType<typeof fetchAgencyFieldsStarted>) {
    try {
        const agencyFields: AgencyLinkedSingleField[] = yield call(fetchAgencyFieldsByDatasetId, { datasetId: payload });
        yield put(fetchAgencyFieldsSuccessful(agencyFields));
    } catch (e) {
        yield put(fetchAgencyFieldsFailed((e as Error).message));
    }
}

export function* attemptDeleteDataset({ payload }: ReturnType<typeof deleteDatasetStarted>) {
    try {
        const datasetId = payload;
        const publishedDefinitions: DatasetDefinitionDB[] = yield call(deleteDataset, { datasetId });
        yield put(deleteDatasetSuccessful(publishedDefinitions));
        toast('Dataset successfully deleted.');
    } catch (e) {
        toast.error('Unable to delete dataset. Please try again.');
        yield put(deleteDatasetFailed((e as Error).message));
    }
}

export function* checkNewFields() {
    try {
        const publishedDefinitions: DatasetDefinitionDB[] = yield select(getAllDatasetDefinitions);
        const currentDefinition: DatasetDefinition = yield select(getDatasetDefinition);
        const currentDefinitionId = currentDefinition.datasetId;
        if (currentDefinitionId) {
            const savedDefinitionDB = publishedDefinitions.find(({ datasetId }) => datasetId === currentDefinitionId);
            if (savedDefinitionDB) {
                const savedDefinition = stripDatasetDefinition(savedDefinitionDB);
                const hasUpdated = !isEqual(savedDefinition, currentDefinition);
                const newFields = getNewFields(currentDefinition, savedDefinition);
                yield put(updateDefinitionNewFields(newFields));
                yield put(setDatasetDefinitionHasUpdated(hasUpdated));
            }
        } else {
            yield put(setDatasetDefinitionHasUpdated(true));
        }
    } catch (e) {
        toast.error('Failed to establish which new fields have been added.');
    }
}

function* publishDatasetWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.PUBLISH_DATASET_DEFINITION_STARTED, attemptPublishDataset);
}

function* fetchAllWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.FETCH_ALL_DATASET_DEFINITIONS_STARTED, attemptFetchDatasets);
}

function* fetchAgencyDatasetsWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.FETCH_AGENCY_DEFINITIONS_STARTED, attemptFetchAgencyDatasets);
}

function* fetchAgencyFieldsWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.FETCH_AGENCY_FIELDS_STARTED, attemptFetchAgencyFields);
}

function* publishAgencyDatasetWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.PUBLISH_AGENCY_DATASET_DEFINITION_STARTED, attemptPublishAgencyDataset);
}

function* deleteDatasetWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.DELETE_DATASET_STARTED, attemptDeleteDataset);
}

function* openDatasetDefinitionWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.OPEN_DATASET_DEFINITION, redirectDatasetDefinition);
}

function* goBackToDatasetDefinitionWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.GO_BACK_TO_DATASET_DEFINITION, redirectBackDatasetDefinition);
}

function* closeDatasetDefinitionWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.CLOSE_BUILDER, closeDatasetDefinition);
}

function* redirectDatasetsPageViewWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.TOGGLE_DATASET_VIEW, redirectDatasetsPageView);
}

function* fetchAllDatasetsWatcher() {
    yield takeEvery(DatasetBuilderActionTypes.FETCH_ALL_DOCUMENT_DATASETS_STARTED, attemptFetchAllDocumentDatasets);
}

function* checkNewFieldsWatcher() {
    yield debounce(1000, [
        DatasetBuilderActionTypes.ADD_DATASET_FIELD,
        DatasetBuilderActionTypes.ADD_DATASET_FIELD_TO_GROUP,
        DatasetBuilderActionTypes.ADD_DATASET_FIELD_WITHIN_SECTION,
        DatasetBuilderActionTypes.ADD_SECTION,
        DatasetBuilderActionTypes.SAVE_SECTION,
        DatasetBuilderActionTypes.REMOVE_DATASET_FIELD,
        DatasetBuilderActionTypes.REMOVE_DATASET_FIELD_FROM_GROUP,
        DatasetBuilderActionTypes.REMOVE_DATASET_FIELD_WITHIN_SECTION,
        DatasetBuilderActionTypes.REMOVE_SECTION,
        DatasetBuilderActionTypes.UPDATE_DATASET_FIELD,
        DatasetBuilderActionTypes.UPDATE_DATASET_FIELDS,
        DatasetBuilderActionTypes.UPDATE_DATASET_GROUP_FIELD,
        DatasetBuilderActionTypes.UPDATE_DATASET_GROUP_FIELDS,
        DatasetBuilderActionTypes.UPDATE_SETTINGS_VALUE,
        DatasetBuilderActionTypes.UPDATE_GROUP_SETTINGS_VALUE
    ], checkNewFields);
}

export function* datasetBuilderSaga() {
    yield all([
        fork(publishDatasetWatcher),
        fork(fetchAllWatcher),
        fork(fetchAgencyDatasetsWatcher),
        fork(fetchAgencyFieldsWatcher),
        fork(publishAgencyDatasetWatcher),
        fork(deleteDatasetWatcher),
        fork(checkNewFieldsWatcher),
        fork(openDatasetDefinitionWatcher),
        fork(goBackToDatasetDefinitionWatcher),
        fork(closeDatasetDefinitionWatcher),
        fork(redirectDatasetsPageViewWatcher),
        fork(fetchAllDatasetsWatcher),
        fork(definitionsForDatasetWatcher),
        fork(checkDatasetDocumentConfigUpdatedWatcher),
        fork(upsertDocumentDatasetConfigurationWatcher)
    ]);
}
