import { flow, isEqual, isUndefined, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { updateCurrencyAmountRiskToleranceConfig, updateRiskToleranceConfig } from '../../../../utils/riskTolerance';
import { LoginActionTypes } from '../../../auth/login/store';
import { MyRiskTolerance, RiskTolerance } from '../../risk-tolerance/store';
import { HiddenField, HiddenSection, MyDatasetActionTypes, MyDatasetView, MyDatasetsState, isHiddenField } from './types';

const compareStrings = (a: string, b: string) => a < b ? -1 : 1;
const sortHiddenFields = (hiddenFields: (HiddenField | HiddenSection)[]) => hiddenFields.sort((a, b) => {
    if (a.type == b.type) {
        if (isHiddenField(a) && isHiddenField(b)) {
            return compareStrings(a.fieldId, b.fieldId);
        }
        if (!isHiddenField(a) && !isHiddenField(b)) {
            return compareStrings(a.sectionId, b.sectionId);
        }
    }
    return compareStrings(a.type, b.type);
});

export const INITIAL_STATE: MyDatasetsState = {
    fetchingAllDatasets: false,
    fetchError: null,
    allDatasets: [],
    datasetView: MyDatasetView.LIST,
    selectedDataset: null,
    myDatasetDefinitions: [],
    openFieldsAndSections: [],
    modalDatasetId: null,
    savedHiddenFields: {},
    currentHiddenFields: {},
    isSaving: false,
    saveError: null,
    hiddenFieldsUpdated: false,
    myRiskToleranceConfig: null,
    currentRiskToleranceConfig: null,
    currentRiskField: null,
    riskToleranceUpdated: false,
    unsavedChangesModalOpen: false,
    fetchingDatasetDefinitions: false,
    documentHiddenFields: []
};

export const myDatasetsReducer: Reducer<MyDatasetsState> = (state = INITIAL_STATE, { type, payload }): MyDatasetsState => {
    switch (type) {
        case MyDatasetActionTypes.FETCH_ALL_MY_DATASETS_STARTED:
            return set('fetchingAllDatasets', true, state);
        case MyDatasetActionTypes.FETCH_ALL_MY_DATASETS_SUCCESSFUL:
            return flow(
                set('allDatasets', payload),
                set('fetchingAllDatasets', false)
            )(state);
        case MyDatasetActionTypes.FETCH_ALL_MY_DATASETS_FAILED:
            return flow(
                set('fetchError', payload),
                set('fetchingAllDatasets', false)
            )(state);
        case MyDatasetActionTypes.MY_DATASET_VIEW: {
            const { view, datasetId } = payload;
            return flow(
                set('datasetView', view),
                set('selectedDataset', datasetId),
                set('unsavedChangesModalOpen', false)
            )(state);
        }
        case MyDatasetActionTypes.FETCH_MY_DATASET_DEFINITIONS_STARTED:
            return set('fetchingDatasetDefinitions', true, state);
        case MyDatasetActionTypes.FETCH_MY_DATASET_DEFINITIONS_SUCCESSFUL: {
            const { datasetDefinitions, hiddenFields, riskTolerance, documentHiddenFields } = payload;
            return flow(
                set('myDatasetDefinitions', datasetDefinitions),
                set('savedHiddenFields', hiddenFields),
                set('currentHiddenFields', hiddenFields),
                set('myRiskToleranceConfig', riskTolerance),
                set('currentRiskToleranceConfig', riskTolerance),
                set('documentHiddenFields', documentHiddenFields),
                set('hiddenFieldsUpdated', false),
                set('fetchingDatasetDefinitions', false)
            )(state);
        }
        case MyDatasetActionTypes.FETCH_MY_DATASET_DEFINITIONS_FAILED:
            return flow(
                set('fetchError', payload),
                set('fetchingDatasetDefinitions', false)
            )(state);
        case MyDatasetActionTypes.REMOVE_MY_DATASETS_OPEN_FIELD_SECTION: {
            const openFieldsAndSections = state.openFieldsAndSections.filter(fieldSection => !isEqual(fieldSection, payload));
            return set('openFieldsAndSections', openFieldsAndSections, state);
        }
        case MyDatasetActionTypes.ADD_MY_DATASETS_OPEN_FIELD_SECTION: {
            const openFieldsAndSections = [...state.openFieldsAndSections, payload];
            return set('openFieldsAndSections', openFieldsAndSections, state);
        }
        case MyDatasetActionTypes.REMOVE_ALL_MY_DATASETS_FIELD_SECTIONS:
            return set('openFieldsAndSections', INITIAL_STATE.openFieldsAndSections, state);
        case MyDatasetActionTypes.TOGGLE_MODAL_DATASET_ID:
            return set('modalDatasetId', payload, state);
        case MyDatasetActionTypes.TOGGLE_HIDDEN_FIELDS: {
            const { datasetId, hiddenField } = payload;
            const currentHiddenFields = state.currentHiddenFields[datasetId] || [];
            let updatedHiddenFields = currentHiddenFields;
            const shouldRemoveField = currentHiddenFields.find(field => isEqual(field, hiddenField));
            updatedHiddenFields = shouldRemoveField ? updatedHiddenFields.filter(field => !isEqual(field, hiddenField)) : [...updatedHiddenFields, hiddenField];
            updatedHiddenFields = sortHiddenFields(updatedHiddenFields);
            return set(`currentHiddenFields[${datasetId}]`, updatedHiddenFields, state);
        }
        case MyDatasetActionTypes.SAVE_HIDDEN_FIELDS_STARTED:
            return set('isSaving', true, state);
        case MyDatasetActionTypes.SAVE_HIDDEN_FIELDS_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('savedHiddenFields', state.currentHiddenFields),
                set('hiddenFieldsUpdated', false)
            )(state);
        case MyDatasetActionTypes.SAVE_HIDDEN_FIELDS_FAILED:
            return flow(
                set('isSaving', false),
                set('saveError', payload)
            )(state);
        case MyDatasetActionTypes.HIDDEN_FIELDS_HAVE_UPDATED:
            return set('hiddenFieldsUpdated', payload, state);
        case MyDatasetActionTypes.SET_CURRENT_RISK_TOLERANCE_FIELD:
            return set('currentRiskField', payload, state);
        case MyDatasetActionTypes.UPDATE_RISK_FIELD_CONFIG: {
            const { riskAssociated, value, selectedCurrency } = payload;
            const { datasetId, fieldId } = state.currentRiskField!;
            let updatedRiskToleranceConfig: (RiskTolerance | MyRiskTolerance)[] = [];
            if (!isUndefined(selectedCurrency)) {
                updatedRiskToleranceConfig = updateCurrencyAmountRiskToleranceConfig(state.currentRiskToleranceConfig!, datasetId, fieldId, selectedCurrency, riskAssociated, value) as RiskTolerance[];
            } else {
                updatedRiskToleranceConfig = updateRiskToleranceConfig(state.currentRiskToleranceConfig!, datasetId, fieldId, riskField => set(`riskConfig[${riskAssociated}]`, value, riskField));
            }
            return set('currentRiskToleranceConfig', updatedRiskToleranceConfig, state);
        }
        case MyDatasetActionTypes.UPDATE_RISK_FIELD_WEIGHTING: {
            const { datasetId, fieldId } = state.currentRiskField!;
            const updatedRiskToleranceConfig = updateRiskToleranceConfig(state.currentRiskToleranceConfig!, datasetId, fieldId, riskField => set('weighting', payload, riskField));
            return set('currentRiskToleranceConfig', updatedRiskToleranceConfig, state);
        }
        case MyDatasetActionTypes.TOGGLE_RISK_FIELD: {
            const { datasetId, fieldId } = payload;
            const updatedRiskTolerance = updateRiskToleranceConfig(state.currentRiskToleranceConfig!, datasetId, fieldId, riskField => set('includeRiskField', !riskField.includeRiskField, riskField));
            return set('currentRiskToleranceConfig', updatedRiskTolerance, state);
        }
        case MyDatasetActionTypes.SET_RISK_TOLERANCE_UPDATED:
            return set('riskToleranceUpdated', payload, state);
        case MyDatasetActionTypes.SAVE_RISK_TOLERANCE_STARTED:
            return set('isSaving', true, state);
        case MyDatasetActionTypes.SAVE_RISK_TOLERANCE_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('riskToleranceConfig', payload),
                set('currentRiskToleranceConfig', payload),
                set('riskToleranceUpdated', false)
            )(state);
        case MyDatasetActionTypes.SAVE_RISK_TOLERANCE_FAILED:
            return set('isSaving', false, state);
        case MyDatasetActionTypes.TOGGLE_UNSAVED_CHANGES_MODAL:
            return set('unsavedChangesModalOpen', payload, state);
        case MyDatasetActionTypes.RESET_MY_DATASETS:
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
