import React from 'react';
import classnames from 'classnames';

import styles from './SupplyChain.module.scss';
import { OverflowTooltip } from '../../shared/tooltip';

interface SupplyChainCardProps {
    label: string;
    onClick: () => void;
    isSelected: boolean;
}

export const SupplyChainCard: React.FC<SupplyChainCardProps> = ({ label, onClick, isSelected }) => (
    <div className={classnames(styles.supplyChainCard, { [styles.isSelected]: isSelected })} onClick={onClick}>
        <OverflowTooltip overlayText={label} />
    </div>
);
