import { ApplicationState } from '../../../../store/rootReducer';
import { OpinionScope } from '../../../admin/opinions/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { ExtensiveOpinionAverage, OpinionAnalyticsChart, OpinionAnalyticsState, OpinionAverage, OpinionAverageView, OpinionMapAnalytics, OpinionSingleField } from './types';
import { filterAndSortOpinionsWithAverage, getDefaultSingleFieldForScope } from './utils';

const getRoot = (state: ApplicationState): OpinionAnalyticsState => state.opinion.analytics;

export const getFetchingAnalytics = (state: ApplicationState): OpinionAnalyticsChart[] => getRoot(state).fetchingAnalytics;

export const getTileInView = (state: ApplicationState): OpinionAnalyticsChart | null => getRoot(state).tileInView;

export const getSingleDataPointMapAnalytics = (state: ApplicationState): OpinionMapAnalytics[] => getRoot(state).singleDataPointMapAnalytics;

export const getOpinionAnalyticsScope = (state: ApplicationState): OpinionScope => getRoot(state).scope;

export const getOpinionSingleField = (state: ApplicationState): OpinionSingleField => getRoot(state).singleField;

export const getSelectedCountry = (state: ApplicationState): string | null => getRoot(state).selectedCountry;

export const getSelectedSingleFieldJurisdiction = (state: ApplicationState): string | null => getRoot(state).inspectedSingleFieldJurisdiction;

export const getAllOpinionsWithAverage = (state: ApplicationState): OpinionAverage[] => getRoot(state).opinionsWithAverage;

export const getOpinionAverageFilters = (state: ApplicationState): TableFilters => getRoot(state).opinionAverageTableFilters;
export const getOpinionAverageColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).opinionAverageTableSort;

export const getOpinionsWithAverage = (state: ApplicationState): OpinionAverage[] => {
    const opinionsWithAverage = getAllOpinionsWithAverage(state);
    if (opinionsWithAverage.length > 0) {
        const filters = getOpinionAverageFilters(state);
        const sort = getOpinionAverageColumnSort(state);
        const filteredOpinions = filterAndSortOpinionsWithAverage(opinionsWithAverage, filters, sort);
        return filteredOpinions;
    }
    return opinionsWithAverage;
};

export const getTotalOpinionAverage = (state: ApplicationState): number => {
    const opinionsWithAverage = getOpinionsWithAverage(state);
    const rawTotal = opinionsWithAverage.reduce((acc, cur) => acc + cur.average, 0) / opinionsWithAverage.length || 0;
    const total = Math.round(rawTotal * 100) / 100;
    return total;
};

export const getOpinionAverageView = (state: ApplicationState): OpinionAverageView => getRoot(state).opinionAverageView;

export const getIsFetchingOpinionAverageBreakdown = (state: ApplicationState): boolean => getRoot(state).fetchingOpinionAverageBreakdown;

export const getSelectedOpinionAverage = (state: ApplicationState): ExtensiveOpinionAverage | null => getRoot(state).selectedOpinionWithAverage;

export const getUltimateSingleFieldForScope = (state: ApplicationState): OpinionSingleField => getDefaultSingleFieldForScope(getOpinionAnalyticsScope(state));

export const getViewSignOffAnswers = (state: ApplicationState): boolean => getRoot(state).viewSignOffAnswers;
