import { Reducer } from 'redux';
import { set, flow, getOr, unset } from 'lodash/fp';

import { ClientDocumentGroup, DocumentNameActionTypes, DocumentNameDB, DocumentNameState, NewAgreementType } from './types';
import { LoginActionTypes } from '../../../auth/login/store';

const newDocumentName: DocumentNameDB = {
    documentName: '',
    datasetId: null,
    datasetTitle: null,
    agreementType: null,
    agencyDatasetId: null,
    agreementTypeId: null
};

const newAgreementType: NewAgreementType = {
    agreementTypeId: null,
    name: ''
};

const initialClientDocumentGroup: ClientDocumentGroup = {
    clientDocumentGroupId: null,
    groupName: '',
    description: '',
    documentNameIds: []
};

export const INITIAL_STATE: DocumentNameState = {
    modalOpen: false,
    documents: [],
    openDocument: null,
    availableDocumentNames: [],
    clientDatasetConfirmationModalOpen: false,
    savedAgreementTypes: [],
    currentAgreementTypes: [],
    agreementTypeModalOpen: false,
    agreementTypesHaveUpdated: false,
    isUpdatingAgreementTypes: false,
    createDocumentGroupModalOpen: false,
    allDocumentGroups: [],
    isLoadingGroups: false,
    currentClientDocumentGroup: null,
    savedClientDocumentGroup: null,
    isSaving: false,
    mlReadyAgreementTypeIds: []
};

export const documentNameReducer: Reducer<DocumentNameState> = (state = INITIAL_STATE, { payload, type }): DocumentNameState => {
    switch (type) {
        case DocumentNameActionTypes.TOGGLE_DOCUMENT_NAME_MODAL: {
            const documentName = payload.isOpen ? getOr(newDocumentName, 'documentName', payload) : null;
            return flow(
                set('modalOpen', payload.isOpen),
                set('openDocument', documentName)
            )(state);
        }
        case DocumentNameActionTypes.UPDATE_VALUE: {
            const { key, value } = payload;
            return set(`openDocument.${key}`, value, state);
        }
        case DocumentNameActionTypes.UPDATE_DOCUMENT_NAME_SUCCESSFUL:
            return flow(
                set('modalOpen', false),
                set('documents', payload),
                set('clientDatasetConfirmationModalOpen', false)
            )(state);
        case DocumentNameActionTypes.FETCH_ALL_DOCUMENT_NAMES_SUCCESSFUL:
            return set('documents', payload, state);
        case DocumentNameActionTypes.FETCH_ALL_AGREEMENT_TYPES_SUCCESSFUL:
            return flow(
                set('savedAgreementTypes', payload),
                set('currentAgreementTypes', payload)
            )(state);
        case DocumentNameActionTypes.FETCH_AVAILABLE_DOCUMENT_NAMES_SUCCESSFUL:
            return set('availableDocumentNames', payload, state);
        case DocumentNameActionTypes.TOGGLE_CLIENT_DATASET_CONFIRMATION_MODAL_OPEN:
            if (payload) {
                return set('clientDatasetConfirmationModalOpen', payload, state);
            }
            return flow(
                unset('openDocument.clientIds'),
                set('clientDatasetConfirmationModalOpen', payload)
            )(state);
        case DocumentNameActionTypes.FETCH_ALL_DOCUMENT_GROUPS_STARTED:
            return set('isLoadingGroups', true, state);
        case DocumentNameActionTypes.FETCH_ALL_DOCUMENT_GROUPS_SUCCESSFUL:
            return flow(
                set('isLoadingGroups', false),
                set('allDocumentGroups', payload)
            )(state);
        case DocumentNameActionTypes.FETCH_ALL_DOCUMENT_GROUPS_FAILED:
            return set('isLoadingGroups', false, state);
        case DocumentNameActionTypes.TOGGLE_CREATE_DOCUMENT_GROUP_MODAL_OPEN: {
            const { isOpen, clientDocumentGroupId } = payload;
            const currentClientDocumentGroup = state.allDocumentGroups.find((group) => group.clientDocumentGroupId === clientDocumentGroupId) || initialClientDocumentGroup;
            return flow(
                set('currentClientDocumentGroup', currentClientDocumentGroup),
                set('savedClientDocumentGroup', currentClientDocumentGroup),
                set('createDocumentGroupModalOpen', isOpen)
            )(state);
        }
        case DocumentNameActionTypes.UPDATE_CLIENT_DOCUMENT_GROUP: {
            const { key, value } = payload;
            return set(`currentClientDocumentGroup[${key}]`, value, state);
        }
        case DocumentNameActionTypes.SAVE_CLIENT_DOCUMENT_GROUP_STARTED:
            return set('isSaving', true, state);
        case DocumentNameActionTypes.SAVE_CLIENT_DOCUMENT_GROUP_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('createDocumentGroupModalOpen', false),
                set('savedClientDocumentGroup', null),
                set('currentClientDocumentGroup', null)
            )(state);
        case DocumentNameActionTypes.SAVE_CLIENT_DOCUMENT_GROUP_FAILED:
            return set('isSaving', false, state);
        case DocumentNameActionTypes.DELETE_CLIENT_DOCUMENT_GROUP_SUCCESSFUL:
            return flow(
                set('createDocumentGroupModalOpen', false),
                set('savedClientDocumentGroup', null),
                set('currentClientDocumentGroup', null)
            )(state);
        case DocumentNameActionTypes.TOGGLE_AGREEMENT_TYPE_MODAL_OPEN:
            return flow(
                set('agreementTypeModalOpen', payload),
                set('currentAgreementTypes', state.savedAgreementTypes),
                set('agreementTypesHaveUpdated', false)
            )(state);
        case DocumentNameActionTypes.ADD_NEW_AGREEMENT_TYPE:
            return set('currentAgreementTypes', [...state.currentAgreementTypes, newAgreementType], state);
        case DocumentNameActionTypes.REMOVE_NEW_AGREEMENT_TYPE:
            return set('currentAgreementTypes', state.currentAgreementTypes.filter((_, index) => index !== payload), state);
        case DocumentNameActionTypes.UPDATE_AGREEMENT_TYPE_NAME: {
            const { index, name } = payload;
            const currentAgreementTypes = state.currentAgreementTypes.map((agreementType, i) => index === i ? set('name', name, agreementType) : agreementType);
            return set('currentAgreementTypes', currentAgreementTypes, state);
        }
        case DocumentNameActionTypes.SET_AGREEMENT_TYPES_HAVE_UPDATED:
            return set('agreementTypesHaveUpdated', payload, state);
        case DocumentNameActionTypes.UPDATE_AGREEMENT_TYPES_STARTED:
            return set('isUpdatingAgreementTypes', true, state);
        case DocumentNameActionTypes.UPDATE_AGREEMENT_TYPES_SUCCESSFUL:
            return flow(
                set('isUpdatingAgreementTypes', false),
                set('currentAgreementTypes', payload),
                set('savedAgreementTypes', payload),
                set('agreementTypesHaveUpdated', false)
            )(state);
        case DocumentNameActionTypes.UPDATE_AGREEMENT_TYPES_FAILED:
            return set('isUpdatingAgreementTypes', false, state);
        case DocumentNameActionTypes.FETCH_ML_READY_AGREEMENT_TYPES_SUCCESSFUL:
            return set('mlReadyAgreementTypeIds', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
