import { toast } from 'react-toastify';
import { put, takeEvery, all, fork, select, call, takeLeading } from 'redux-saga/effects';
import { flow, isUndefined, unset } from 'lodash/fp';

import { fetchPaginatedDoraFunctions, upsertDoraFunction, fetchDoraConfiguration, upsertFunctionConfiguration, deleteDoraFunctionCheck, deleteDoraFunction } from '../../../../services/dora';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { fetchDoraFunctionsStarted, fetchDoraFunctionsSuccessful, fetchDoraFunctionsFailed, saveDoraFunctionSuccessful, saveDoraFunctionFailed, fetchFunctionConfigSuccessful, fetchFunctionConfigFailed, saveFunctionConfigStarted, saveFunctionConfigFailed, saveFunctionConfigSuccessful, fetchDoraFunctionUsageStarted, fetchDoraFunctionUsageSuccessful, fetchDoraFunctionUsageFailed, deleteDoraFunctionStarted, deleteDoraFunctionSuccessful, deleteDoraFunctionFailed } from './actions';
import { getDoraFunctionsFilters, getDoraFunctionsColumnSort, getDoraFunctionsPageSize, getDoraFunctionsPageNumber, getSelectedDoraFunction } from './selectors';
import { DoraConfigurationDB, DoraFunction, DoraFunctionDB, DoraFunctionUsage, DoraFunctionActionTypes, DoraConfigurationTimeline } from './types';
import { initialDoraConfiguration } from './reducer';

export const stripDoraFunctionDB = (entity: DoraFunctionDB): DoraFunction => flow(
    unset('clientId'),
    unset('modifiedBy'),
    unset('modifiedDate')
)(entity);

export function* attemptFetchPaginatedDoraFunctions({ payload }: ReturnType<typeof fetchDoraFunctionsStarted>) {
    try {
        const { pageNumber } = payload;
        const filters: TableFilters = yield select(getDoraFunctionsFilters);
        const columnSort: ColumnSort | undefined = yield select(getDoraFunctionsColumnSort);
        const pageSize: number = yield select(getDoraFunctionsPageSize);
        const { doraFunctions, total }: { doraFunctions: DoraFunctionDB[]; total: number; } = yield call(fetchPaginatedDoraFunctions, { filters, pageNumber, columnSort, pageSize });
        const strippedDoraFunctions = doraFunctions.map(doraFunction => stripDoraFunctionDB(doraFunction));
        yield put(fetchDoraFunctionsSuccessful(strippedDoraFunctions, total, pageNumber));
    } catch (e) {
        toast.error('Unable to fetch the functions. Please try again.');
        yield put(fetchDoraFunctionsFailed((e as Error).message));
    }
}

function* attemptFetchPaginatedDoraFunctionsWatcher() {
    yield takeEvery(DoraFunctionActionTypes.FETCH_DORA_FUNCTIONS_STARTED, attemptFetchPaginatedDoraFunctions);
}

export function* paginationDoraFunctionsNext() {
    const pageNumber: number = yield select(getDoraFunctionsPageNumber);
    yield put(fetchDoraFunctionsStarted(pageNumber + 1));
}

function* paginationDoraFunctionsNextWatcher() {
    yield takeEvery(DoraFunctionActionTypes.DORA_FUNCTIONS_PAGINATION_NEXT, paginationDoraFunctionsNext);
}

export function* paginationDoraFunctionsPrevious() {
    const pageNumber: number = yield select(getDoraFunctionsPageNumber);
    yield put(fetchDoraFunctionsStarted(pageNumber - 1));
}

function* paginationDoraFunctionsPreviousWatcher() {
    yield takeEvery(DoraFunctionActionTypes.DORA_FUNCTIONS_PAGINATION_PREVIOUS, paginationDoraFunctionsPrevious);
}

export function* doraFunctionsTableConfigUpdated() {
    yield put(fetchDoraFunctionsStarted(1));
}

function* doraFunctionsTableConfigUpdatedWatcher() {
    yield takeEvery([DoraFunctionActionTypes.SET_DORA_FUNCTIONS_TABLE_FILTERS, DoraFunctionActionTypes.CLEAR_DORA_FUNCTIONS_TABLE_FILTERS, DoraFunctionActionTypes.SET_DORA_FUNCTIONS_TABLE_COLUMN_SORT, DoraFunctionActionTypes.SET_DORA_FUNCTIONS_PAGE_SIZE], doraFunctionsTableConfigUpdated);
}

export function* attemptSaveDoraFunction() {
    const pageNumber: number = yield select(getDoraFunctionsPageNumber);
    const filters: TableFilters = yield select(getDoraFunctionsFilters);
    const columnSort: ColumnSort | undefined = yield select(getDoraFunctionsColumnSort);
    const pageSize: number = yield select(getDoraFunctionsPageSize);
    const doraFunction: DoraFunction = yield select(getSelectedDoraFunction);
    try {
        const { doraFunctions, total, functionId }: { doraFunctions: DoraFunctionDB[]; total: number; functionId: number; } = yield call(upsertDoraFunction, { doraFunction, filters, pageNumber, columnSort, pageSize });
        const strippedDoraFunctions = doraFunctions.map(doraFunction => stripDoraFunctionDB(doraFunction));
        yield put(saveDoraFunctionSuccessful(strippedDoraFunctions, total, pageNumber, functionId));
        toast(`Successfully ${!isUndefined(doraFunction.doraFunctionId) ? 'updated' : 'saved'} DORA function.`);
    } catch (e) {
        toast.error(`Unable to ${!isUndefined(doraFunction.doraFunctionId) ? 'update' : 'save'} DORA function. Please try again.`);
        yield put(saveDoraFunctionFailed((e as Error).message));
    }
}

function* attemptSaveDoraFunctionWatcher() {
    yield takeEvery(DoraFunctionActionTypes.SAVE_DORA_FUNCTION_STARTED, attemptSaveDoraFunction);
}

export function* attemptCheckDoraFunctionUsage({ payload }: ReturnType<typeof fetchDoraFunctionUsageStarted>) {
    try {
        const doraFunctionUsage: DoraFunctionUsage[] = yield call(deleteDoraFunctionCheck, { functionId: payload });
        yield put(fetchDoraFunctionUsageSuccessful(doraFunctionUsage));
    } catch (e) {
        toast.error('Unable to fetch the information for the function. Please try again.');
        yield put(fetchDoraFunctionUsageFailed((e as Error).message));
    }
}

function* attemptCheckDoraFunctionUsageWatcher() {
    yield takeEvery(DoraFunctionActionTypes.FETCH_DORA_FUNCTION_USAGE_STARTED, attemptCheckDoraFunctionUsage);
}

export function* attemptDeleteDoraFunction({ payload }: ReturnType<typeof deleteDoraFunctionStarted>) {
    try {
        const pageNumber: number = yield select(getDoraFunctionsPageNumber);
        const filters: TableFilters = yield select(getDoraFunctionsFilters);
        const columnSort: ColumnSort | undefined = yield select(getDoraFunctionsColumnSort);
        const pageSize: number = yield select(getDoraFunctionsPageSize);
        const { doraFunctions, total }: { doraFunctions: DoraFunctionDB[]; total: number; } = yield call(deleteDoraFunction, { functionId: payload, filters, pageNumber, columnSort, pageSize });
        const strippedDoraFunctions = doraFunctions.map(doraFunction => stripDoraFunctionDB(doraFunction));
        yield put(deleteDoraFunctionSuccessful(strippedDoraFunctions, total, pageNumber));
        toast('Successfully deleted DORA function.');
    } catch (e) {
        toast.error('Unable to delete DORA function. Please try again.');
        yield put(deleteDoraFunctionFailed((e as Error).message));
    }
}

function* attemptDeleteDoraFunctionWatcher() {
    yield takeLeading(DoraFunctionActionTypes.DELETE_DORA_FUNCTION_STARTED, attemptDeleteDoraFunction);
}

export function* attemptFetchDoraFunctionConfig() {
    try {
        const { doraConfig, timeline }: { doraConfig: DoraConfigurationDB | undefined, timeline: DoraConfigurationTimeline[] } = yield call(fetchDoraConfiguration);
        const doraConfiguration = isUndefined(doraConfig) ? initialDoraConfiguration : { doraConfigurationId: doraConfig.doraConfigurationId, details: doraConfig.details };
        yield put(fetchFunctionConfigSuccessful(doraConfiguration, timeline));
    } catch (e) {
        toast.error('Unable to fetch the configuration. Please try again.');
        yield put(fetchFunctionConfigFailed((e as Error).message));
    }
}

function* attemptFetchDoraFunctionConfigWatcher() {
    yield takeEvery(DoraFunctionActionTypes.FETCH_FUNCTION_CONFIG_STARTED, attemptFetchDoraFunctionConfig);
}

export function* attemptSaveDoraFunctionConfig({ payload }: ReturnType<typeof saveFunctionConfigStarted>) {
    try {
        const { doraConfig, timeline }: { doraConfig: DoraConfigurationDB, timeline: DoraConfigurationTimeline[] } = yield call(upsertFunctionConfiguration, { doraConfiguration: payload });
        const doraConfiguration = { doraConfigurationId: doraConfig.doraConfigurationId, details: doraConfig.details };
        yield put(saveFunctionConfigSuccessful(doraConfiguration, timeline));
        toast('Successfully saved function configuration.');
    } catch (e) {
        toast.error('Unable to save the configuration. Please try again.');
        yield put(saveFunctionConfigFailed((e as Error).message));
    }
}

function* attemptSaveDoraFunctionConfigWatcher() {
    yield takeLeading(DoraFunctionActionTypes.SAVE_FUNCTION_CONFIG_STARTED, attemptSaveDoraFunctionConfig);
}

export function* doraFunctionSaga() {
    yield all([
        fork(attemptFetchPaginatedDoraFunctionsWatcher),
        fork(paginationDoraFunctionsNextWatcher),
        fork(paginationDoraFunctionsPreviousWatcher),
        fork(doraFunctionsTableConfigUpdatedWatcher),
        fork(attemptSaveDoraFunctionWatcher),
        fork(attemptFetchDoraFunctionConfigWatcher),
        fork(attemptSaveDoraFunctionConfigWatcher),
        fork(attemptCheckDoraFunctionUsageWatcher),
        fork(attemptDeleteDoraFunctionWatcher)
    ]);
}
