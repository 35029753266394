import React from 'react';

import { diffToNowInMeasurement, formatDate, SHORT_MONTH_FORMAT } from '../../../utils/luxon';
import { OverflowTooltip } from '../tooltip';
import styles from './Table.module.scss';

const { red, amber, green, primary } = styles;

export interface DateCellColourBoundaries {
    red: number;
    amber: number;
}

interface DateCellProps {
    colours?: DateCellColourBoundaries;
    value: string | null;
    valueFormatted?: string | null
}

export const getCellValues = (date: string | null, ragBoundaries: DateCellColourBoundaries) => {
    let styledDate = { text: '', color: primary };

    if (date === 'NONE' || !date) {
        return styledDate;
    }

    const monthsSinceDate = Math.floor(diffToNowInMeasurement(date, 'months') || -1);

    if (monthsSinceDate < 0 && monthsSinceDate >= ragBoundaries.amber) {
        styledDate = { text: date, color: green };
    } else if (monthsSinceDate < ragBoundaries.amber && monthsSinceDate >= ragBoundaries.red) {
        styledDate = { text: date, color: amber };
    } else if (monthsSinceDate < ragBoundaries.red) {
        styledDate = { text: date, color: red };
    }
    return styledDate;
};

export const DateCell: React.FC<DateCellProps> = ({ value, valueFormatted, colours = { red: -12, amber: -9 } }) => {
    const deadlineValue = valueFormatted || value as string | null;

    const { text, color } = getCellValues(deadlineValue, colours);
    const overlayText = text ? formatDate(text, SHORT_MONTH_FORMAT) : '';
    return (
        <div className={styles.tooltipCellWrapper} style={{ color }}>
            <OverflowTooltip className={styles.tooltipValue} overlayText={overlayText} />
        </div>
    );
};
