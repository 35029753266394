import React from 'react';

export const Pencil: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill={color || 'currentColor'} d="M100.5,20.1c-3.8-3.8-9.9-3.8-13.7,0c0,0,0,0,0,0L75.1,31.8L21.2,85.7c-0.3,0.3-0.4,0.6-0.5,0.9l-3.4,18.5
	    c-0.5,2.6,1.2,5.1,3.8,5.6c0.6,0.1,1.2,0.1,1.7,0l18.5-3.4c0.3-0.1,0.7-0.2,0.9-0.5l53.9-53.9l11.7-11.7c3.8-3.8,3.8-9.9,0-13.7
	    c0,0,0,0,0,0L100.5,20.1z M40.2,103.9l-17.9,3.3c-0.7,0.1-1.4-0.3-1.5-1c0-0.2,0-0.3,0-0.5l3.3-17.9l52.3-52.3l16.1,16.1L40.2,103.9
	    z M105.5,38.7L95,49.2L78.8,33l10.5-10.5c2.4-2.4,6.3-2.4,8.7,0l7.4,7.4C107.9,32.4,107.9,36.3,105.5,38.7
	    C105.5,38.7,105.5,38.7,105.5,38.7L105.5,38.7z"/>
        <path fill={color || 'currentColor'} d="M26.4,88.9l12.5,12.5c1.4,1.4,3.6,1.4,5,0l50-50c1.4-1.4,1.4-3.6,0-5L81.4,33.8c-1.4-1.4-3.6-1.4-5,0l-50,50
	    C25,85.2,25,87.5,26.4,88.9z"/>
    </svg>
);
