import React, { useMemo, useRef } from 'react';
import Select, { Options } from 'react-select';

import { useMenuPlacement } from '../../../../hooks/useMenuPlacement';
import { customStyles, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { InformationTooltip } from '../../../shared/tooltip';
import { DropdownListDB } from '../../dropdown-lists/store';
import styles from './AgencyDatasetBuilder.module.scss';

interface AgencyDatasetDropdownListProps {
    value: string | null;
    options: DropdownListDB[];
    updateValue: (option: DropdownOption | Options<DropdownOption> | null) => void;
    disabled?: boolean;
}

const mapDropdownListsToOptions = (lists: DropdownListDB[]) => lists
    .map(({ dropdownListId, name }) => ({ value: dropdownListId.toString(), label: name }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const AgencyDatasetDropdownList: React.FC<AgencyDatasetDropdownListProps> = ({
    value,
    options,
    updateValue,
    disabled = false
}) => {
    const selectedList = useMemo(() => options.filter(({ dropdownListId }) => dropdownListId.toString() === value), [options, value]);
    const mappedValue = useMemo(() => selectedList ? mapDropdownListsToOptions(selectedList) : null, [selectedList]);
    const mappedOptions = useMemo(() => mapDropdownListsToOptions(options), [options]);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const MAX_MENU_HEIGHT = 150;
    const menuPlacement = useMenuPlacement(dropdownRef, MAX_MENU_HEIGHT);

    return (
        <div
            data-testid='agency-link-field-wrapper'
            className={styles.dropdownFieldWrapper}
            ref={dropdownRef}
        >
            <div className={styles.dropdownHeader}>
                <div className={styles.dropdownLabel}>Select Dropdown *</div>
                <InformationTooltip content='You must have a dropdown list selected, either manually or via a field link' />
            </div>
            <Select
                className={styles.dropdownField}
                classNamePrefix='ark-dropdown'
                isDisabled={disabled}
                value={mappedValue}
                onChange={updateValue}
                options={mappedOptions}
                styles={customStyles}
                maxMenuHeight={MAX_MENU_HEIGHT}
                menuPlacement={menuPlacement}
                menuPortalTarget={document.body}
                fontWeight={500}
            />
        </div>
    );
};
