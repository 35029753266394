import React, { useCallback, useMemo } from 'react';

import { EntityType } from '../../entity/store';
import styles from './AgencyDatasetBuilder.module.scss';
import { RadioRow } from '../../../shared/radio/RadioRow';
import { RadioListOption } from '../../../shared/radio/RadioList';

interface AgencyEntityTypeSelectorProps {
    updateEntityType: (type: EntityType) => void;
    selected: EntityType;
}

export const AgencyEntityTypeSelector: React.FC<AgencyEntityTypeSelectorProps> = ({ updateEntityType, selected }) => {
    const options: RadioListOption[] = useMemo(() => [
        {
            id: EntityType.COMPANY,
            isSelected: selected === EntityType.COMPANY,
            label: 'Company'
        },
        {
            id: EntityType.PERSON,
            isSelected: selected === EntityType.PERSON,
            label: 'Person'
        }
    ], [selected]);

    const onChange = useCallback((id: string) => updateEntityType(id as EntityType), [updateEntityType]);

    return (
        <div className={styles.agencyEntityWrapper}>
            <div className={styles.agencyEntityTitle}>Entities</div>
            <RadioRow onChange={onChange} options={options} />
        </div>
    );
};
