import React, { useMemo } from 'react';
import { flatten } from 'lodash/fp';

import { formatDate } from '../../../utils/luxon';
import { Button } from '../../shared/button/Button';
import { scopeFormatter } from '../../shared/table/arkTableFormatters';
import { InformationTooltip, TooltipList } from '../../shared/tooltip';
import styles from './Reports.module.scss';
import { OpinionReportTemplate } from './store';

interface OpinionTemplateProps {
    template: OpinionReportTemplate;
    openTemplate: (reportTemplateId: number) => void;
    openDeleteConfirmationModal: (reportTemplateId: number) => void;
    canMakeTemplateChanges: boolean;
    testId?: string;
}

export const OpinionTemplate: React.FC<OpinionTemplateProps> = ({ template, openTemplate, openDeleteConfirmationModal, testId, canMakeTemplateChanges }) => {
    const { opinionReportTemplateId, modifiedDate, modifiedByName, name, description, reportFields, scopes } = template;

    const reportFieldBulletPoints = useMemo(() => flatten(Object.values(reportFields)).map(({ label }) => label), [reportFields]);

    return (
        <div className={styles.templateWrapper} onDoubleClick={() => openTemplate(opinionReportTemplateId)} data-testid={`${testId}-template-wrapper`}>
            <div className={styles.templateHeader}>
                <div className={styles.templateHeaderColumn}>
                    <div className={styles.templateTitle}>
                        <div className={styles.templateName} data-testid={`${testId}-template-title`}>{name}</div>
                        {description && <InformationTooltip content={description} />}
                    </div>
                    <div className={styles.templateDocumentName} data-testid={`${testId}-template-document-name`}>{scopes.map(scope => scopeFormatter(scope)).join(' - ')}</div>
                </div>
                <div className={styles.templateHeaderColumn}>
                    <div className={styles.templateModifiedBy} data-testid={`${testId}-template-modified-by`}>{modifiedByName}</div>
                    <div className={styles.templateModifiedDate} data-testid={`${testId}-template-modified-date`}>{formatDate(modifiedDate)}</div>
                </div>
            </div>
            <div className={styles.templateFieldsHeader} data-testid={`${testId}-template-fields-label`}>Fields</div>
            <TooltipList overlayText={reportFieldBulletPoints} placement='top'>
                <div className={styles.templateFieldsWrapper} data-testid={`${testId}-template-fields`}>{reportFieldBulletPoints.join(' - ')}</div>
            </TooltipList>
            <div className={styles.templateButtonWrapper}>
                <Button label='Delete' onClick={() => openDeleteConfirmationModal(opinionReportTemplateId)} testId={`${testId}-template-delete`} disabled={!canMakeTemplateChanges} />
                <Button label='Open' onClick={() => openTemplate(opinionReportTemplateId)} testId={`${testId}-template-open`} />
            </div>
        </div>
    );
};
