import { ColDef, GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { flow, isNull, unset } from 'lodash/fp';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DatasetDefinition, DatasetDefinitionDB } from '../../datasets/store';
import { Delete, Form } from '../../shared/icons';
import { Action } from '../../shared/modal/ActionModal';
import { ActionCell, Table, dateFormatter } from '../../shared/table';
import { DeleteDatasetConfirmationModal } from './DeleteDatasetConfirmationModal';
import { getAllDatasetDefinitions, getConfirmDeleteDataset, openDatasetDefinition, toggleDeleteDatasetConfirmationModal } from './store';

export const stripDefinition = (definition: DatasetDefinitionDB): DatasetDefinition => flow(
    unset('clientId'),
    unset('createdBy'),
    unset('createdDate'),
    unset('effectiveTo'),
    unset('isCurrent'),
    unset('datasetDefinitionVersion'),
)(definition);

export const PublishedDatasetsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const datasetDefinitions = useAppSelector(getAllDatasetDefinitions);
    const confirmDeleteDataset = useAppSelector(getConfirmDeleteDataset);

    const openDefinition = useCallback((definition: DatasetDefinitionDB) => { dispatch(openDatasetDefinition(stripDefinition(definition))); }, [dispatch]);
    const openDeleteConfirmationModal = useCallback((definition: DatasetDefinitionDB) => { dispatch(toggleDeleteDatasetConfirmationModal(definition)); }, [dispatch]);
    const closeDeleteConfirmationModal = () => dispatch(toggleDeleteDatasetConfirmationModal(null));

    const datasetActions = useCallback((definition: DatasetDefinitionDB): Action[] => {
        const canDelete = !definition.isParent;
        let actions: Action[] = [
            { label: 'Open', icon: Form, onClick: () => openDefinition(definition), withSeparator: canDelete }
        ];
        if (canDelete) {
            actions.push({ label: 'Delete', icon: Delete, onClick: () => openDeleteConfirmationModal(definition), isDelete: true });
        }
        return actions;
    }, [openDefinition, openDeleteConfirmationModal]);

    const actionCellParams = {
        testId: 'dataset-actions',
        actions: datasetActions
    };

    const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };
    const columnDefs: ColDef[] = [
        { headerName: 'Title', field: 'datasetTitle', cellStyle: defaultCellStyle, width: 0.45 },
        { headerName: 'Created Date', field: 'createdDate', cellStyle: defaultCellStyle, width: 0.2, valueFormatter: dateFormatter },
        { headerName: 'Dataset Type', field: 'datasetType', cellStyle: defaultCellStyle, width: 0.15 },
        { headerName: 'Version Number', field: 'datasetDefinitionVersion', cellStyle: defaultCellStyle, width: 0.15 },
        { colId: 'actions', headerName: '', field: '', cellRenderer: 'actionCellRenderer', width: 0.05, cellRendererParams: actionCellParams }
    ];

    const gridOptions: GridOptions = {
        components: {
            actionCellRenderer: ActionCell
        },
        paginationPageSize: 20
    };

    const onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => dispatch(openDatasetDefinition(stripDefinition(data)));

    return (
        <>
            <Table
                colDefs={columnDefs}
                rowData={datasetDefinitions}
                gridOptions={gridOptions}
                percentageWidths={true}
                onRowDoubleClicked={onRowDoubleClicked}
                testId='published-dataset'
                padding='0px'
            />
            {!isNull(confirmDeleteDataset) &&
                <DeleteDatasetConfirmationModal
                    isOpen
                    closeModal={closeDeleteConfirmationModal}
                    definition={confirmDeleteDataset}
                />
            }
        </>
    );
};
