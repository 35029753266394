import React from 'react';
import { isNull } from 'lodash/fp';

import { Icon } from '../icon/Icon';
import { Tick } from '../icons';
import styles from './Table.module.scss';

const { french } = styles;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const TickCell: React.FC<{ value: any; valueFormatted: any; }> = ({ value, valueFormatted }) => {
    const cellValue = !isNull(valueFormatted) ? valueFormatted : value;
    return (
        <div className={styles.tickWrapper}>
            {cellValue ? <Icon icon={Tick} color={french} /> : ''}
        </div>
    );
};
