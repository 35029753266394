import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DatasetType } from '../../../datasets/store';
import { DragDrop } from '../../../shared/drag-n-drop/DragDrop';
import { DraggableItem } from '../../../shared/drag-n-drop/shared';
import styles from '../Reports.module.scss';
import { OpinionReportField, getCurrentScope, getReportFields, updateOpinionReportFields, getOpinionScope } from '../store';
import { ScopeTabs } from './OpinionReportFields';

const { amber } = styles;

interface FieldColumnProps {
    id: string;
    label: string;
    datasetType: DatasetType;
    index: number;
    isDragging?: boolean;
    multipleReportScopes: boolean;
}

const FieldColumn: React.FC<FieldColumnProps> = ({ id, label, datasetType, isDragging = false, multipleReportScopes }) => (
    <div style={{ height: multipleReportScopes ? 'calc(50vh - 75px)' : 'calc(50vh - 38px)' }} className={classnames(styles.columnWrapper, {
        [styles.tableColumn]: datasetType === DatasetType.TABLE,
        [styles.formColumn]: datasetType === DatasetType.FORM,
        [styles.draggingColumn]: isDragging,
        [styles.mandatoryColumn]: ['jurisdiction', 'focus'].includes(id)
    })}>
        <div className={styles.columnLabel}>{label}</div>
    </div>
);

export const ConfigureFields: React.FC = () => {
    const reportFields = useAppSelector(getReportFields);
    const reportScopes = useAppSelector(getOpinionScope);
    const currentScope = useAppSelector(getCurrentScope);
    const dispatch = useAppDispatch();

    const updateFields = useCallback((fields: OpinionReportField[]) => dispatch(updateOpinionReportFields(fields)), [dispatch]);
    const currentReportFields = useMemo(() => !isNull(currentScope) ? reportFields[currentScope] : [], [currentScope, reportFields]);
    const listOptions = useMemo(() => currentReportFields.map(({ id, label }) => ({ id, label })), [currentReportFields]);

    const updateList = useCallback((list: DraggableItem[]) => {
        const newFields = list.map(({ id }) => currentReportFields.find(field => field.id === id)!);
        updateFields(newFields);
    }, [updateFields, currentReportFields]);

    const multipleReportScopes = useMemo(() => reportScopes.length > 1, [reportScopes]);

    const getChildElement = useCallback((childId: string, index: number) => {
        const field = currentReportFields.find(({ id }) => id === childId);
        if (field) {
            const { id, label, datasetType } = field;
            return (
                <FieldColumn key={id} id={id} label={label} index={index} datasetType={datasetType} multipleReportScopes={multipleReportScopes} />
            );
        }
        return null;
    }, [currentReportFields, multipleReportScopes]);

    const getDraggingElement = useCallback((childId: string, index: number) => {
        const field = currentReportFields.find(({ id }) => id === childId)!;
        const { id, label, datasetType } = field;
        return (
            <FieldColumn id={id} label={label} index={index} datasetType={datasetType} isDragging multipleReportScopes={multipleReportScopes} />
        );
    }, [currentReportFields, multipleReportScopes]);

    return (
        <div className={styles.configureFieldsWrapper}>
            <ScopeTabs reportScopes={reportScopes} currentScope={currentScope} />
            <div className={styles.fieldsHeader}>Configure the order you would like to display the selected columns</div>
            <div className={styles.reportDroppableWrapper} style={{ height: multipleReportScopes ? 'calc(50vh - 35px)' : '50vh' }} data-testid='opinion-reporting-droppable-wrapper'>
                <DragDrop
                    getChildElement={getChildElement}
                    list={listOptions}
                    listId='opinion-reporting'
                    updateList={updateList}
                    type='horizontal'
                    getDraggingElement={getDraggingElement}
                    edgeColour={amber}
                    edgeWidth={3}
                />
            </div>
        </div>
    );
};
