import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DateFilter, SearchFilter } from '../../search/store';
import { Spinner } from '../../../shared/spinner/Spinner';
import styles from '../Reports.module.scss';
import { getIsGeneratingReport, getReportFilters, updateReportDateFilter, updateDocumentReportFilter } from '../store';
import { DocumentReportFilter } from './DocumentReportFilter';

export const ReportFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const isGeneratingReport = useAppSelector(getIsGeneratingReport);
    const reportFilters = useAppSelector(getReportFilters);

    const updateDocumentFilter = useCallback((key: keyof SearchFilter, value: string[] | string | null | boolean) => dispatch(updateDocumentReportFilter(key, value)), [dispatch]);
    const updateDateFilter = useCallback((key: keyof DateFilter, value: string | null) => dispatch(updateReportDateFilter(key, value)), [dispatch]);

    const content = useMemo(() => {
        if (isGeneratingReport) {
            return (<Spinner />);
        }
        return <DocumentReportFilter reportFilters={reportFilters as SearchFilter} updateFilter={updateDocumentFilter} updateDateFilter={updateDateFilter} />;
    }, [isGeneratingReport, updateDocumentFilter, updateDateFilter, reportFilters]);

    return (
        <div className={styles.reportFilterWrapper}>
            {content}
        </div>
    );
};

