import { toast } from 'react-toastify';
import { all, call, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';

import { fetchAdminOpinionAnalyticsFailed, fetchAdminOpinionAnalyticsSuccessful, fetchOpinionsByDateHealthCategoryFailed, fetchOpinionsByDateHealthCategoryStarted, fetchOpinionsByDateHealthCategorySuccessful } from './actions';
import { AdminOpinionStateActionTypes, OpinionHealthIndicator, SelectedOpinionAnalytics } from './types';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { getSelectedOpinionHealthCategory, getSelectedOpinionsColumnSort, getSelectedOpinionsFilters, getSelectedOpinionsPageNumber, getSelectedOpinionsPageSize } from './selectors';
import { fetchAdminOpinionPieAnalytics, fetchSelectedAdminOpinions } from '../../../../services/analytics';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

export function* attemptFetchOpinionAnalytics() {
    try {
        const pieData: PieChartAnalyticsData[] = yield call(fetchAdminOpinionPieAnalytics);
        yield put(fetchAdminOpinionAnalyticsSuccessful(pieData));
    } catch (e) {
        toast.error('Failed to fetch opinions. Please try again.');
        yield put(fetchAdminOpinionAnalyticsFailed((e as Error).message));
    }
}

function* fetchOpinionAnalyticsWatcher() {
    yield takeLeading(AdminOpinionStateActionTypes.FETCH_ADMIN_OPINION_ANALYTICS_STARTED, attemptFetchOpinionAnalytics);
}

export function* attemptFetchSelectedOpinions({ payload }: ReturnType<typeof fetchOpinionsByDateHealthCategoryStarted>) {
    try {
        const pageNumber: number = payload.pageNumber;
        const health: OpinionHealthIndicator = yield select(getSelectedOpinionHealthCategory);
        const filters: TableFilters = yield select(getSelectedOpinionsFilters);
        const columnSort: ColumnSort | undefined = yield select(getSelectedOpinionsColumnSort);
        const pageSize: number = yield select(getSelectedOpinionsPageSize);
        const { data, total }: { data: SelectedOpinionAnalytics[], total: number } = yield call(fetchSelectedAdminOpinions, { health, pageNumber, pageSize, filters, columnSort });
        yield put(fetchOpinionsByDateHealthCategorySuccessful(data, total));
    } catch (e) {
        toast.error('Failed to fetch opinions. Please try again.');
        yield put(fetchOpinionsByDateHealthCategoryFailed((e as Error).message));
    }
}

function* fetchSelectedOpinionsWatcher() {
    yield takeLeading(AdminOpinionStateActionTypes.FETCH_OPINIONS_BY_DATE_HEALTH_CATEGORY_STARTED, attemptFetchSelectedOpinions);
}

export function* paginationSelectedOpinionsNext() {
    const pageNumber: number = yield select(getSelectedOpinionsPageNumber);
    const health: OpinionHealthIndicator = yield select(getSelectedOpinionHealthCategory);
    yield put(fetchOpinionsByDateHealthCategoryStarted(health, pageNumber + 1));
}

function* paginationSelectedOpinionsNextWatcher() {
    yield takeEvery(AdminOpinionStateActionTypes.SELECTED_OPINIONS_PAGINATION_NEXT, paginationSelectedOpinionsNext);
}

export function* paginationSelectedOpinionsPrevious() {
    const pageNumber: number = yield select(getSelectedOpinionsPageNumber);
    const health: OpinionHealthIndicator = yield select(getSelectedOpinionHealthCategory);
    yield put(fetchOpinionsByDateHealthCategoryStarted(health, pageNumber - 1));
}

function* paginationSelectedOpinionsPreviousWatcher() {
    yield takeEvery(AdminOpinionStateActionTypes.SELECTED_OPINIONS_PAGINATION_PREVIOUS, paginationSelectedOpinionsPrevious);
}

export function* selectedOpinionsTableUpdated() {
    const pageNumber: number = yield select(getSelectedOpinionsPageNumber);
    const health: OpinionHealthIndicator = yield select(getSelectedOpinionHealthCategory);
    yield put(fetchOpinionsByDateHealthCategoryStarted(health, pageNumber));
}

function* selectedOpinionsTableUpdatedWatcher() {
    yield takeEvery([AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_COLUMN_SORT, AdminOpinionStateActionTypes.CLEAR_SELECTED_OPINIONS_TABLE_FILTERS, AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_TABLE_FILTERS, AdminOpinionStateActionTypes.SET_SELECTED_OPINIONS_PAGE_SIZE], selectedOpinionsTableUpdated);
}

export function* adminOpinionsSaga() {
    yield all([
        fork(fetchOpinionAnalyticsWatcher),
        fork(fetchSelectedOpinionsWatcher),
        fork(paginationSelectedOpinionsNextWatcher),
        fork(paginationSelectedOpinionsPreviousWatcher),
        fork(selectedOpinionsTableUpdatedWatcher)
    ]);
}
