import { DocumentReportField, UpsertDocumentReportTemplate } from '../components/documents/reporting/store';
import { SearchFilter } from '../components/documents/search/store';
import { OpinionReportFilter, OpinionScopeFields, UpsertOpinionReportTemplate } from '../components/opinions/reporting/store';
import { api } from './services';

interface CreateReportRequest { reportFields: DocumentReportField[]; filters: SearchFilter; fileName: string; doubleRowHeight: boolean; }
interface CreateOpinionReportRequest { reportFields: OpinionScopeFields; filters: OpinionReportFilter; fileName: string; includeSignOffNotes: boolean; }

export const createReport = async (reqBody: CreateReportRequest) => await api('report/create', { ...reqBody }, 'post', {}, false, true);
export const createOpinionReport = async (reqBody: CreateOpinionReportRequest) => await api('report/opinion/create', { ...reqBody }, 'post', {}, false, true);
export const fetchAvailableReportFields = async (reqBody: { documentNameId: string }) => await api('report/getAvailableFields', { ...reqBody }, 'post');

export const deleteReportTemplate = async (reqBody: { id: number; isOpinionTemplate: boolean; }) => await api('report/template/delete', { ...reqBody }, 'post');
export const fetchAllDocumentReportTemplates = async () => await api('report/template/getAll', {}, 'get');
export const fetchAllOpinionReportTemplates = async () => await api('report/opinion/template/getAll', {}, 'get');
export const upsertReportTemplate = async (reqBody: UpsertOpinionReportTemplate | UpsertDocumentReportTemplate) => await api('report/template/upsert', { ...reqBody }, 'post');
