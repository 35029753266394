import { action } from 'typesafe-actions';

import { UsersActionTypes, ClientUser, NewUser, FeaturePermissionDB, BasicUserInfo } from './types';

export const toggleUsersModal = (isOpen: boolean, user?: ClientUser, clientId?: number) => action(UsersActionTypes.TOGGLE_USERS_MODAL, { isOpen, user, clientId });

export const updateUserValue = (key: keyof NewUser, value: string | null | number[]) => action(UsersActionTypes.UPDATE_USER_VALUE, { key, value });

export const upsertUserFailed = (error: string) => action(UsersActionTypes.UPSERT_USER_FAILED, error);
export const upsertUserSuccessful = (allUsers: ClientUser[]) => action(UsersActionTypes.UPSERT_USER_SUCCESSFUL, allUsers);
export const upsertUserStarted = (clientView: boolean) => action(UsersActionTypes.UPSERT_USER_STARTED, clientView);

export const checkUsernameAvailableStarted = () => action(UsersActionTypes.CHECK_USERNAME_AVAILABLE_STARTED);
export const checkUsernameAvailableFailed = (error: string) => action(UsersActionTypes.CHECK_USERNAME_AVAILABLE_FAILED, error);
export const checkUsernameAvailableSuccessful = (userUnavailable: boolean) => action(UsersActionTypes.CHECK_USERNAME_AVAILABLE_SUCCESSFUL, userUnavailable);

export const checkEmailValid = (email: string) => action(UsersActionTypes.CHECK_EMAIL_VALID, email);

export const toggleDeleteUserConfirmationModal = (user: ClientUser | null) => action(UsersActionTypes.TOGGLE_DELETE_USER_CONFIRMATION_MODAL, user);

export const deleteUserStarted = (userId: number, username: string, clientId?: number) => action(UsersActionTypes.DELETE_USER_STARTED, { userId, username, clientId });
export const deleteUserSuccessful = (allUsers: ClientUser[]) => action(UsersActionTypes.DELETE_USER_SUCCESSFUL, allUsers);
export const deleteUserFailed = (error: string) => action(UsersActionTypes.DELETE_USER_FAILED, error);

export const fetchAllUsersFailed = (error: string) => action(UsersActionTypes.FETCH_ALL_USERS_FAILED, error);
export const fetchAllUsersSuccessful = (allUsers: ClientUser[]) => action(UsersActionTypes.FETCH_ALL_USERS_SUCCESSFUL, allUsers);
export const fetchAllUsersStarted = () => action(UsersActionTypes.FETCH_ALL_USERS_STARTED);

export const fetchAllBasicUsersFailed = (error: string) => action(UsersActionTypes.FETCH_ALL_BASIC_USERS_FAILED, error);
export const fetchAllBasicUsersSuccessful = (allUsers: BasicUserInfo[]) => action(UsersActionTypes.FETCH_ALL_BASIC_USERS_SUCCESSFUL, allUsers);
export const fetchAllBasicUsersStarted = () => action(UsersActionTypes.FETCH_ALL_BASIC_USERS_STARTED);

export const fetchAvailableFeaturePermissionsFailed = (error: string) => action(UsersActionTypes.FETCH_AVAILABLE_FEATURE_PERMISSIONS_FAILED, error);
export const fetchAvailableFeaturePermissionsSuccessful = (permissions: FeaturePermissionDB[]) => action(UsersActionTypes.FETCH_AVAILABLE_FEATURE_PERMISSIONS_SUCCESSFUL, permissions);
export const fetchAvailableFeaturePermissionsStarted = () => action(UsersActionTypes.FETCH_AVAILABLE_FEATURE_PERMISSIONS_STARTED);

export const resetUserPassword = (username: string) => action(UsersActionTypes.RESET_USER_PASSWORD, { username });
