import React from 'react';

export const Analytics: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <g>
            <path fill={color || 'currentColor'} d="M64.929,47.339a17.592,17.592,0,1,0,12.439,5.152A17.476,17.476,0,0,0,64.929,47.339Zm9.964,27.555a14.089,14.089,0,1,1,4.128-9.964A14.109,14.109,0,0,1,74.893,74.894Z" />
            <path fill={color || 'currentColor'} d="M106.032,93.726l-8.47-7.434h5a1.749,1.749,0,0,0,1.75-1.75V20.875a1.749,1.749,0,0,0-1.75-1.75H36.1a1.75,1.75,0,0,0-1.237.513L24.2,30.305a1.749,1.749,0,0,0-.512,1.237v53a1.749,1.749,0,0,0,1.75,1.75h26.5A24.985,24.985,0,0,0,75.6,87.545a5.632,5.632,0,0,1,4.834,0l-.4.4a1.749,1.749,0,0,0-.078,2.392l13.8,15.721a8.641,8.641,0,0,0,6.23,2.81l.186,0a8.711,8.711,0,0,0,5.863-15.149ZM34.354,25.1v4.692H29.662Zm-7.166,8.192H36.1a1.75,1.75,0,0,0,1.75-1.75V22.625h62.958V82.792H93.573l-3.231-2.836a1.75,1.75,0,0,0-2.392.078l-.407.407a5.549,5.549,0,0,1-.016-4.8,25.061,25.061,0,1,0-40.094,7.149H27.188ZM74.11,84.381A21.51,21.51,0,1,1,84.365,74.143,9.028,9.028,0,0,0,85,82.989L82.988,85A9.089,9.089,0,0,0,74.11,84.381Zm29.739,19.468a5.214,5.214,0,0,1-7.493-.125L83.668,89.266l5.6-5.6,14.427,12.66a5.214,5.214,0,0,1,.156,7.521Z" />
        </g>
    </svg>

);
