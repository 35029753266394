import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import {
    DocumentsView,
    exportAllCSVStarted,
    getDocumentCustomFiltersModalOpen,
    getDocumentDetailsModalOpen,
    getDocumentsView,
    getSelectedDocumentCustomFilterId,
    getSelectedIncompleteDocuments,
    openIncompleteDocumentStarted,
    setSelectedDocumentCustomFilterId,
    toggleDocumentCustomFiltersModal,
    toggleDocumentDetailsModal,
    toggleDocumentsView,
    toggleUploadModal
} from './store';
import { PlusButton } from '../../shared/button/PlusButton';
import { IconButton } from '../../shared/button/IconButton';
import { Export, Filter, Pencil, Search } from '../../shared/icons';
import { DocumentDetailsModal } from './upload/DocumentDetailsModal';
import { IncompleteDocumentsTable } from './IncompleteDocumentsTable';
import { OriginalDocumentsTable } from './OriginalDocumentsTable';
import { TableTabs } from '../../shared/table/TableTabs';
import { UploadDocumentsModal } from './upload/UploadDocumentsModal';
import styles from './Documents.module.scss';
import { hasPermission } from '../../constants/featurePermissions';
import { FilterTable, getAllDocumentCustomFilters, getMyProfile, getUserHasFeaturePermission } from '../../auth/login/store';
import { ClientFeaturePermission, FeaturePermission } from '../../admin/users/store';
import { MLCompletedModal } from './MLCompletedModal';
import { toggleSearchDocumentsView, SearchDocumentsView } from '../search/store';
import { PreExecutionDocumentsTable } from './PreExecutionTable';
import { ApplyCustomFiltersModal } from '../../shared/custom-filters/ApplyCustomFiltersModal';

export const Documents: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    const dispatch = useAppDispatch();
    const documentView = useAppSelector(getDocumentsView);
    const isOriginalDocumentView = useMemo(() => documentView === DocumentsView.ORIGINAL_DOCUMENTS, [documentView]);
    const isIncompleteDocumentView = useMemo(() => documentView === DocumentsView.INCOMPLETE_DOCUMENTS, [documentView]);
    const isPreExecutionDocumentView = useMemo(() => documentView === DocumentsView.PRE_EXECUTION_DRAFT, [documentView]);
    const openUploadDocumentModal = useCallback(() => dispatch(toggleUploadModal(true)), [dispatch]);
    const toggleSearch = useCallback((isPreExecution?: boolean) => dispatch(toggleSearchDocumentsView(SearchDocumentsView.SIMPLE_SEARCH, isPreExecution)), [dispatch]);
    const changeView = useCallback((view: DocumentsView) => dispatch(toggleDocumentsView(view)), [dispatch]);
    const hasUploadDocumentPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.UPLOAD_DOCUMENTS]));
    const customFilters = useAppSelector(getAllDocumentCustomFilters);
    const documentCustomFiltersModalOpen = useAppSelector(getDocumentCustomFiltersModalOpen);
    const selectedFilterId = useAppSelector(getSelectedDocumentCustomFilterId);

    const documentDetailsModalOpen = useAppSelector(getDocumentDetailsModalOpen);
    const selectedIncompleteDocuments = useAppSelector(getSelectedIncompleteDocuments);
    const user = useAppSelector(getMyProfile);
    const showCSVExport = hasPermission([ClientFeaturePermission.EXPORT_RAF_DOCUMENTS_TO_CSV], user!.clientFeaturePermissionIds);
    const exportRAFCSV = useCallback(() => dispatch(exportAllCSVStarted()), [dispatch]);

    const openDocumentCustomFiltersModal = useCallback(() => dispatch(toggleDocumentCustomFiltersModal(true)), [dispatch]);
    const closeDocumentCustomFiltersModal = useCallback(() => dispatch(toggleDocumentCustomFiltersModal(false)), [dispatch]);

    const updateDisabled = selectedIncompleteDocuments.length <= 0;

    const handleUpdateMultipleDocuments = useCallback(() => {
        dispatch(openIncompleteDocumentStarted(selectedIncompleteDocuments[0]));
        dispatch(toggleDocumentDetailsModal(true));
    }, [dispatch, selectedIncompleteDocuments]);

    const headerComponents = useMemo(() => {
        if (isOriginalDocumentView) {
            return (
                <div className={styles.documentsTitleContainer}>
                    <div className={styles.documentsTitle} data-testid='documents-title'>Documents</div>
                    {hasUploadDocumentPermission && <PlusButton onClick={openUploadDocumentModal} fontSize={22} testId='upload-document' />}
                    <IconButton icon={Search} onClick={() => toggleSearch()} fontSize={35} testId='search-documents' />
                    {customFilters.length > 0 && <IconButton icon={Filter} onClick={openDocumentCustomFiltersModal} fontSize={35} testId='complete-custom-document-filters' />}
                    {showCSVExport && <IconButton icon={Export} onClick={exportRAFCSV} fontSize={35} testId='export-raf-documents' />}
                </div>
            );
        }
        if (isIncompleteDocumentView) {
            return (
                <div className={styles.documentsTitleContainer}>
                    <div className={styles.documentsTitle} data-testid='documents-title'>Incomplete Documents</div>
                    <IconButton icon={Pencil} onClick={handleUpdateMultipleDocuments} disabled={updateDisabled} withBorder fontSize={25} />
                </div>
            );
        }
        return (
            <div className={styles.documentsTitleContainer}>
                <div className={styles.documentsTitle} data-testid='documents-title'>Pre-Execution Documents</div>
                <IconButton icon={Search} onClick={() => toggleSearch(true)} fontSize={35} testId='search-documents' />
            </div>
        );

    }, [handleUpdateMultipleDocuments, isOriginalDocumentView, isIncompleteDocumentView, openUploadDocumentModal, toggleSearch, updateDisabled, showCSVExport, exportRAFCSV, hasUploadDocumentPermission, openDocumentCustomFiltersModal, customFilters]);

    const setCustomDocumentFilters = useCallback((filterId: string) => {
        if (isOriginalDocumentView) {
            dispatch(setSelectedDocumentCustomFilterId(filterId));
        }
        closeDocumentCustomFiltersModal();
    }, [dispatch, closeDocumentCustomFiltersModal, isOriginalDocumentView]);

    useEffect(() => {
        if (pathname.includes('incomplete') && isOriginalDocumentView) {
            changeView(DocumentsView.INCOMPLETE_DOCUMENTS);
        }
        if (pathname.includes('pre-execution') && isOriginalDocumentView) {
            changeView(DocumentsView.PRE_EXECUTION_DRAFT);
        }
    }, [isOriginalDocumentView, isIncompleteDocumentView, pathname, changeView]);

    const tableTabs = [
        { tabToggleAction: () => changeView(DocumentsView.ORIGINAL_DOCUMENTS), tabTitle: 'Complete', isSelected: isOriginalDocumentView },
        { tabToggleAction: () => changeView(DocumentsView.INCOMPLETE_DOCUMENTS), tabTitle: 'Incomplete', isSelected: isIncompleteDocumentView },
        { tabToggleAction: () => changeView(DocumentsView.PRE_EXECUTION_DRAFT), tabTitle: 'Pre-Execution', isSelected: isPreExecutionDocumentView }
    ];

    return (
        <div className={styles.documentsWrapper} data-testid='documents-wrapper'>
            {headerComponents}
            <TableTabs
                tabs={tableTabs}
                testId='documents'
            />
            {isOriginalDocumentView && <OriginalDocumentsTable />}
            {isIncompleteDocumentView && <IncompleteDocumentsTable />}
            {isPreExecutionDocumentView && <PreExecutionDocumentsTable />}
            <DocumentDetailsModal isOpen={documentDetailsModalOpen} />
            <UploadDocumentsModal />
            <MLCompletedModal />
            <ApplyCustomFiltersModal
                setCustomFilters={setCustomDocumentFilters}
                modalOpen={documentCustomFiltersModalOpen}
                closeModal={closeDocumentCustomFiltersModal}
                customFilters={customFilters}
                filterTable={FilterTable.DOCUMENT}
                selectedFilterId={selectedFilterId}
            />
        </div>
    );
};
