import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchPaginatedThirdPartyCompaniesStarted, getThirdPartyCompaniesPageNumber, getIsFetchingThirdPartyCompanies, getThirdPartyCompaniesFilters, getTotalThirdPartyCompanies, getThirdPartyCompaniesColumnSort, getThirdPartyCompaniesPageSize, getPaginatedThirdPartyCompanies, setThirdPartyCompaniesTableFilters, clearThirdPartyCompaniesTableFilters, setThirdPartyCompaniesTableColumnSort, setThirdPartyCompaniesPageSize, thirdPartyCompaniesPaginationNext, thirdPartyCompaniesPaginationPrevious, getThirdPartyDropdownOptions, setSelectedThirdPartyCompany } from './store';
import { OfficeBuilding } from '../../shared/icons';
import { Action } from '../../shared/modal/ActionModal';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { HEIGHT_OF_THE_WIZARD } from '../my-companies/MyCompaniesInScopeForDora';
import { CompanyEntity } from '../../admin/entity/store';

export const ThirdPartyCompaniesTable: React.FC = () => {

    useFetchStarted([fetchPaginatedThirdPartyCompaniesStarted()]);
    const [, screenHeight] = useWindowResize();

    const dispatch = useAppDispatch();
    const setSelectedThirdParty = useCallback((row: CompanyEntity) => dispatch(setSelectedThirdPartyCompany(row)), [dispatch]);

    const thirdPartyCompanies = useAppSelector(getPaginatedThirdPartyCompanies);
    const isLoading = useAppSelector(getIsFetchingThirdPartyCompanies);
    const pageNumber = useAppSelector(getThirdPartyCompaniesPageNumber);
    const totalThirdPartyCompanies = useAppSelector(getTotalThirdPartyCompanies);
    const filters = useAppSelector(getThirdPartyCompaniesFilters);
    const columnSort = useAppSelector(getThirdPartyCompaniesColumnSort);
    const pageSize = useAppSelector(getThirdPartyCompaniesPageSize);
    const thirdPartyDropdownOptions = useAppSelector(getThirdPartyDropdownOptions);

    const nextPage = useCallback(() => dispatch(thirdPartyCompaniesPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(thirdPartyCompaniesPaginationPrevious()), [dispatch]);

    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setThirdPartyCompaniesTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearThirdPartyCompaniesTableFilters()), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setThirdPartyCompaniesTableColumnSort(columnSort)), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setThirdPartyCompaniesPageSize(pageSize)), [dispatch]);

    const actions = useCallback((data: CompanyEntity): Action[] => [{ label: 'Open', icon: OfficeBuilding, onClick: () => setSelectedThirdParty(data) }], [setSelectedThirdParty]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'name', header: 'Company Name', field: 'name', width: 0.95, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
    ];

    const tableHeight = useMemo(() => (screenHeight * 0.75) - HEIGHT_OF_THE_WIZARD, [screenHeight]);

    const filterDropdownOptions = useMemo(() => ({
        companyName: thirdPartyDropdownOptions
    }), [thirdPartyDropdownOptions]);

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={thirdPartyCompanies}
            testId='third-party-company'
            isLoading={isLoading}
            page={pageNumber}
            total={totalThirdPartyCompanies}
            next={nextPage}
            previous={previousPage}
            filters={filters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => setSelectedThirdParty(row as CompanyEntity)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
            filterDropdownOptions={filterDropdownOptions}
            height={tableHeight}
        />
    );
};
