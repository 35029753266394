import React from 'react';
import { getOr } from 'lodash/fp';

import { AddressInput } from './AddressInput';
import { useAppDispatch } from '../../../../hooks/react-redux';
import { addOtherAddress, CompanyEntity, CompanyEntityContent, removeAlternativeAddress, updateAddressEntityValue, updateEntityContent } from '../store';
import { Text } from '../../../shared/text/Text';
import styles from './EntityType.module.scss';
import { OtherAddresses } from './OtherAddresses';
import { Scrollable } from '../../../shared/scrollable/Scrollable';

interface CompanyEntityContactSectionProps {
    entity: CompanyEntity;
}

export const CompanyEntityContactSection: React.FC<CompanyEntityContactSectionProps> = ({ entity }) => {
    const dispatch = useAppDispatch();
    const handleUpdateContent = (key: keyof CompanyEntityContent, value: string | string[] | Date | null) => dispatch(updateEntityContent(key, value));
    const handleUpdateAddressValue = (key: string, value: string | null, index?: number) => dispatch(updateAddressEntityValue(key, value, index));
    const addAnotherAddress = () => dispatch(addOtherAddress());
    const deleteAddress = (index: number | undefined) => dispatch(removeAlternativeAddress(index));

    const { content: { address, phoneNumber, faxNumber, mobileNumber, email } } = entity;

    return (
        <div className={styles.contactWrapper}>
            <Scrollable>
                <Text
                    label='Phone Number'
                    testId='entity-phone-number'
                    onChange={e => handleUpdateContent('phoneNumber', e.target.value)}
                    value={phoneNumber}
                    placeholder='Phone Number...'
                />
                <Text
                    label='Mobile Number'
                    testId='entity-mobile-number'
                    onChange={e => handleUpdateContent('mobileNumber', e.target.value)}
                    value={mobileNumber}
                    placeholder='Mobile Number...'
                />
                <Text
                    label='Email'
                    testId='entity-email'
                    onChange={e => handleUpdateContent('email', e.target.value)}
                    value={email}
                    placeholder='Email...'
                />
                <div>
                    <div className={styles.addressLabel} data-testid='entity-address-label'>Address</div>
                    <AddressInput
                        address1={getOr('', 'address1', address)}
                        address2={getOr('', 'address2', address)}
                        city={getOr('', 'city', address)}
                        country={getOr('', 'country', address)}
                        postalCode={getOr('', 'postalCode', address)}
                        addressType={getOr(null, 'addressType', address)}
                        onValueChange={handleUpdateAddressValue}
                        deleteFunction={deleteAddress}
                        entity={entity}
                        showAddressType
                    />
                </div>
                <Text
                    label='Fax Number'
                    testId='entity-fax-number'
                    onChange={e => handleUpdateContent('faxNumber', e.target.value)}
                    value={faxNumber}
                    placeholder='Fax Number...'
                />
                <OtherAddresses
                    handleUpdateAddressValue={handleUpdateAddressValue}
                    addAddress={addAnotherAddress}
                    deleteAddress={deleteAddress}
                    entity={entity}
                    showAddressType
                />
            </Scrollable>
        </div>
    );
};
