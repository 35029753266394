import { flow, isNull, unset } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { ArkDocument, DocumentLinkDetails, SelectedDocument, UploadDocument, LinkedDocument, DocumentsView, AnalysisView, MyDocumentsState, ArkTableDocument } from './types';
import { MLData } from '../../../datasets/store/mlTypes';
import { getAllDocumentCustomFilters } from '../../../auth/login/store';

const getRoot = (state: ApplicationState): MyDocumentsState => state.document.myDocuments;

export const getDocumentsView = (state: ApplicationState): DocumentsView => getRoot(state).documentsView;

export const getUploadModalOpen = (state: ApplicationState): boolean => getRoot(state).uploadModalOpen;

export const getIsUploading = (state: ApplicationState): boolean => getRoot(state).isUploading;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getIsUpdatingDocument = (state: ApplicationState): boolean => getRoot(state).isUpdatingDocumentDetails;

export const getFilesToUpload = (state: ApplicationState): File[] | null => getRoot(state).filesToUpload;

export const getSelectedIncompleteDocuments = (state: ApplicationState): ArkDocument[] => getRoot(state).selectedIncompleteDocuments;

export const getDocumentDetailsModalOpen = (state: ApplicationState): boolean => getRoot(state).documentDetailsModalOpen;

export const getOriginalTableDocuments = (state: ApplicationState): ArkTableDocument[] => {
    const documents = getRoot(state).availableOriginalDocuments;

    const stripDocumentEntities = (document: ArkDocument) => flow(
        unset('clientId'),
        unset('createdBy'),
        unset('createdDate'),
        unset('effectiveTo'),
        unset('isCurrent'),
        unset('version')
    )(document);

    const tableDocuments: ArkTableDocument[] = documents.map(document => {
        const { entityA, entityAName, entityAMyCompany, entityAOnWatchlist, entityB, entityBName, entityBMyCompany, entityBOnWatchlist } = document;
        const entityAInfo = {
            entityId: entityA,
            entityName: entityAName,
            myCompanyEntity: entityAMyCompany,
            entityOnWatchlist: entityAOnWatchlist
        };
        const entityBInfo = {
            entityId: entityB,
            entityName: entityBName,
            myCompanyEntity: entityBMyCompany,
            entityOnWatchlist: entityBOnWatchlist
        };
        const strippedDocument = stripDocumentEntities(document);
        return { ...strippedDocument, entityA: entityAInfo, entityB: entityBInfo };
    });

    return tableDocuments;
};

export const getOriginalDocuments = (state: ApplicationState): ArkDocument[] => getRoot(state).availableOriginalDocuments;
export const getBaseDocuments = (state: ApplicationState): ArkDocument[] => getRoot(state).availableBaseDocuments;
export const getAgreementTypeDocuments = (state: ApplicationState): ArkDocument[] => getRoot(state).agreementTypeDocuments;

export const getAnalysisUploadDocument = (state: ApplicationState): UploadDocument => getRoot(state).analysisUploadDocument;

export const getOriginalDocumentUrl = (state: ApplicationState): string | null => getRoot(state).originalDocumentUrl;

export const getSecondaryDocumentUrl = (state: ApplicationState): string | null => getRoot(state).secondaryDocumentUrl;

export const getDocumentInView = (state: ApplicationState): null | SelectedDocument => getRoot(state).documentInView;

export const getDocumentUrl = (state: ApplicationState): string | null => {
    const documentInView = getDocumentInView(state);
    if (documentInView === SelectedDocument.SUPPORT) {
        return getSupportDocumentUrl(state);
    }
    return documentInView === SelectedDocument.SECONDARY ? getSecondaryDocumentUrl(state) : getOriginalDocumentUrl(state);
};

export const getOriginalDocument = (state: ApplicationState): ArkDocument | null => getRoot(state).originalDocument;

export const getDocumentLinkDetails = (state: ApplicationState): DocumentLinkDetails | null => {
    const selectedDocument = getOriginalDocument(state);
    if (!selectedDocument) {
        return null;
    }
    const { entityA, entityB, documentNameId, documentId } = selectedDocument;
    return {
        entityA,
        entityB,
        documentNameId,
        documentId
    };
};

export const getIsAgencyDocument = (state: ApplicationState): boolean => {
    const originalDocument = getOriginalDocument(state);
    return !!originalDocument && !!originalDocument.isAgency;
};

export const getIsSecondaryAnalyzing = (state: ApplicationState): boolean => getRoot(state).secondaryDocumentAnalyzing;

export const getSecondaryDocument = (state: ApplicationState): ArkDocument | null => getRoot(state).secondaryDocument;

export const getIsOpening = (state: ApplicationState): boolean => getRoot(state).isOpening;

export const getNewDocumentId = (state: ApplicationState): number | null => getRoot(state).newDocumentId;

export const getOriginalDocumentsModalOpen = (state: ApplicationState): boolean => getRoot(state).originalDocumentsModalOpen;

export const getBaseDocumentsModalOpen = (state: ApplicationState): boolean => getRoot(state).baseDocumentsModalOpen;

export const getIsdaDocumentsModalOpen = (state: ApplicationState): boolean => getRoot(state).isdaDocumentsModalOpen;

export const getLinkedDocumentsModalOpen = (state: ApplicationState): number | null => getRoot(state).linkDocumentsModalOpen;

export const getIncompleteDocumentUrl = (state: ApplicationState): string | null => getRoot(state).incompleteDocumentUrl;

export const getIncompleteDocument = (state: ApplicationState): ArkDocument | null => getRoot(state).incompleteDocument;

export const getIncompleteDocumentDetailsUpdated = (state: ApplicationState): boolean => getRoot(state).incompleteDocumentDetailsUpdated;

export const getAllIncompleteDocuments = (state: ApplicationState): ArkDocument[] => getRoot(state).incompleteDocuments;

export const getSupportDocuments = (state: ApplicationState): LinkedDocument[] => {
    const originalDocument = getOriginalDocument(state);
    const secondaryDocument = getSecondaryDocument(state);
    if (secondaryDocument) {
        return secondaryDocument.supportDocuments!;
    }
    return originalDocument?.supportDocuments || [];
};

export const getLinkedDocuments = (state: ApplicationState): LinkedDocument[] | null => getRoot(state).linkedDocuments;

export const getSelectedSupportDocument = (state: ApplicationState): LinkedDocument | null => getRoot(state).supportDocument;

export const getSupportDocumentUrl = (state: ApplicationState): string | null => getRoot(state).supportDocumentUrl;

export const getDocumentAnalysisView = (state: ApplicationState): AnalysisView => getRoot(state).documentAnalysisView;

export const getOriginalDocumentToDelete = (state: ApplicationState): ArkDocument | null => getRoot(state).originalDocumentToDelete;

export const getDeleteDocumentModalOpen = (state: ApplicationState): boolean => !isNull(getOriginalDocumentToDelete(state));

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;

export const getOriginalDocumentToRename = (state: ApplicationState): ArkDocument | null => getRoot(state).renameDocument;

export const getRenameDocumentModalOpen = (state: ApplicationState): boolean => !isNull(getOriginalDocumentToRename(state));

export const getDocumentDescriptionsHaveUpdated = (state: ApplicationState): boolean => getRoot(state).documentDescriptionsHaveUpdated;

export const getIsRenaming = (state: ApplicationState): boolean => getRoot(state).isRenaming;

export const getMLCompletedModalOpen = (state: ApplicationState): boolean => getRoot(state).mlCompletedModalOpen;

export const getIncompleteMLData = (state: ApplicationState): MLData | null => getRoot(state).incompleteDocumentMLData;

export const getIsCreatingEntity = (state: ApplicationState): boolean => getRoot(state).isCreatingEntity;

export const getIncompleteScheduleStartPage = (state: ApplicationState): number | null => getRoot(state).incompleteScheduleStartPage;

export const getIncompleteDocumentPageTotal = (state: ApplicationState): number | null => getRoot(state).incompleteDocumentTotalPage;

export const getDocumentAnalysisSelectedPage = (state: ApplicationState): number | null => getRoot(state).documentAnalysisSelectedPage;

export const getDocumentAnalysisPageTotal = (state: ApplicationState): number | null => getRoot(state).documentAnalysisTotalPage;

export const getScheduleStartPagePlaceholder = (state: ApplicationState): number | null => getRoot(state).scheduleStartPlaceholder;

export const getScheduleEndPagePlaceholder = (state: ApplicationState): number | null => getRoot(state).scheduleEndPlaceholder;

export const getCdmJsonPreview = (state: ApplicationState): object | null => getRoot(state).cdmJson;

export const getAllPreExecutionDocuments = (state: ApplicationState): ArkDocument[] => getRoot(state).preExecutionDocuments;

export const getDocumentFetchAgreementTypeId = (state: ApplicationState): number | null => getRoot(state).fetchDocumentsAgreementTypeId;

// Table Filters and Pagination
export const getOriginalDocumentsPageNumber = (state: ApplicationState): number => getRoot(state).originalDocumentPage;
export const getTotalOriginalDocuments = (state: ApplicationState): number => getRoot(state).totalOriginalDocuments;
export const getOriginalDocumentFilters = (state: ApplicationState): TableFilters => getRoot(state).originalDocumentFilters;
export const getOriginalDocumentColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).originalDocumentSort;
export const getOriginalDocumentPageSize = (state: ApplicationState): number => getRoot(state).originalDocumentPageSize;

export const getBaseDocumentsPageNumber = (state: ApplicationState): number => getRoot(state).baseDocumentPage;
export const getTotalBaseDocuments = (state: ApplicationState): number => getRoot(state).totalBaseDocuments;
export const getBaseDocumentFilters = (state: ApplicationState): TableFilters => getRoot(state).baseDocumentFilters;
export const getBaseDocumentColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).baseDocumentSort;
export const getBaseDocumentPageSize = (state: ApplicationState): number => getRoot(state).baseDocumentPageSize;

export const getAgreementTypeDocumentsPageNumber = (state: ApplicationState): number => getRoot(state).agreementTypeDocumentPage;
export const getTotalAgreementTypeDocuments = (state: ApplicationState): number => getRoot(state).totalAgreementTypeDocuments;
export const getAgreementTypeDocumentFilters = (state: ApplicationState): TableFilters => getRoot(state).agreementTypeDocumentFilters;
export const getAgreementTypeDocumentColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).agreementTypeDocumentSort;
export const getAgreementTypeDocumentPageSize = (state: ApplicationState): number => getRoot(state).agreementTypeDocumentPageSize;

export const getIncompleteDocumentsPageNumber = (state: ApplicationState): number => getRoot(state).incompleteDocumentPage;
export const getTotalIncompleteDocuments = (state: ApplicationState): number => getRoot(state).totalIncompleteDocuments;
export const getIncompleteDocumentFilters = (state: ApplicationState): TableFilters => getRoot(state).incompleteDocumentFilters;
export const getIncompleteDocumentColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).incompleteDocumentSort;
export const getIncompleteDocumentPageSize = (state: ApplicationState): number => getRoot(state).incompleteDocumentPageSize;

export const getPreExecutionDocumentsPageNumber = (state: ApplicationState): number => getRoot(state).preExecutionDocumentPage;
export const getTotalPreExecutionDocuments = (state: ApplicationState): number => getRoot(state).totalPreExecutionDocuments;
export const getPreExecutionDocumentFilters = (state: ApplicationState): TableFilters => getRoot(state).preExecutionDocumentFilters;
export const getPreExecutionDocumentColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).preExecutionDocumentSort;
export const getPreExecutionDocumentPageSize = (state: ApplicationState): number => getRoot(state).preExecutionDocumentPageSize;

// Custom Filters
export const getDocumentCustomFiltersModalOpen = (state: ApplicationState): boolean => getRoot(state).documentCustomFiltersModalOpen;
export const getSelectedDocumentCustomFilterId = (state: ApplicationState): string | null => getRoot(state).selectedDocumentCustomFilterId;
export const getDocumentCustomFilterHasUpdated = (state: ApplicationState): boolean => getRoot(state).documentCustomFilterHasUpdated;
export const getSelectedCustomFilterName = (state: ApplicationState): string => {
    const allDocumentFilters = getAllDocumentCustomFilters(state);
    const selectedId = getSelectedDocumentCustomFilterId(state);
    return allDocumentFilters.find(({ id }) => id === selectedId)?.filterName || '';
};
