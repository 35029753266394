import React, { useMemo } from 'react';
import { RawDraftContentState } from 'draft-js';
import classnames from 'classnames';
import { isNull, isUndefined } from 'lodash/fp';

import { DatasetFieldType, DatasetType } from '../store';
import { OverflowTooltip, InformationTooltip } from '../../shared/tooltip';
import { Text, LongText, DateField, Dropdown, Number, Checkbox, Time, DocumentField, LabelField, PartySelect } from '.';
import styles from '../../shared/datasets/SharedStyling.module.scss';
import { FieldValue, getCurrentInstances, getRiskToleranceForDefinitions, getSearchFieldSection, OpenFieldSection, OpenFieldType, SingleInstanceField, UpdatedFormField } from '../instances/store';
import { Clause } from '../instances/Clause';
import { WYSIWYGField } from './WYSIWYGField';
import { CalculatorField as Calculator } from './CalculatorField';
import { CalculatorField } from '../../shared/calculator/constants';
import { DatasetField } from './DatasetField';
import { Calculator as CalculatorIcon, CaretDown, CaretSide, Table } from '../../shared/icons';
import { Icon } from '../../shared/icon/Icon';
import { AgencyECSLabel } from './AgencyECSLabel';
import { useAppSelector } from '../../../hooks/react-redux';
import { getUserRole } from '../../auth/login/store';
import { adminRoles } from '../../constants/permittedRoles';
import { useSingleHiddenFields } from '../../../hooks/useHiddenSingleFields';
import { HiddenFields } from '../../admin/my-datasets/store';
import { getRiskToleranceConfigIsEmpty, getRiskToleranceIndicator } from '../../../utils/riskTolerance';
import { SingleIndicator } from '../../shared/analytics/SingleIndicator';
import { useFieldHighlightColor } from '../../../hooks/useFieldHighlightColor';
import { CurrencyAmountField } from './CurrencyAmountField';
import { CurrencyValue } from '../../shared/number/CurrencyNumber';

export const getField = (
    field: SingleInstanceField,
    value: FieldValue,
    isEditing: boolean,
    isUpdating: boolean,
    showFieldUpdated: boolean,
    showDatasetUpdated: boolean,
    showAIModified: boolean,
    showAIModifiedUserCorrected: boolean,
    includedInAnnex: boolean,
    index: number,
    disabled: boolean,
    fieldOpen: boolean,
    datasetId: number,
    parentFieldId: string,
    toggleSection: (fieldSection: OpenFieldSection) => void,
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean,
    sectionId?: string,
    groupIndex?: number,
    menuPortalTarget?: HTMLElement | null,
    modalInstance?: boolean,
    fieldHidden?: boolean,
    isMLCorrection?: boolean
) => {
    const defaultProps = { id: field.id!, index, sectionId, groupIndex, disabled, showFieldUpdated, showDatasetUpdated, showAIModified, showAIModifiedUserCorrected, includedInAnnex, modalInstance, parentFieldId, isMLCorrection };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} value={value as string} datasetId={datasetId} />;
        case DatasetFieldType.LONGTEXT:
            return <LongText {...defaultProps} value={value as string} datasetId={datasetId} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={value as null | Date} datasetId={datasetId} />;
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
            return <Dropdown
                {...defaultProps}
                value={value as null | string[]}
                type={field.type}
                listId={field.settings.dropdownLinked!}
                isMulti={field.settings.isMultiSelect}
                entityType={field.settings.entityType}
                menuPortalTarget={menuPortalTarget}
                datasetId={datasetId}
            />;
        case DatasetFieldType.PARTY:
            return <PartySelect
                {...defaultProps}
                value={value as null | string[]}
                menuPortalTarget={menuPortalTarget}
                datasetId={datasetId}
            />;
        case DatasetFieldType.DOCUMENT:
            return <DocumentField id={field.id!} value={value as string[]} />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} value={value as number} datasetId={datasetId} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={value as boolean} datasetId={datasetId} centreField={field.settings.centreField} />;
        case DatasetFieldType.DATASET:
            return <DatasetField childDatasetId={field.settings.datasetLinked!} isEditing={isEditing} isUpdating={isUpdating} fieldOpen={fieldOpen} id={field.id!} fieldHidden={fieldHidden} isMLCorrection={isMLCorrection} />;
        case DatasetFieldType.TIME:
            return <Time {...defaultProps} value={value as string[]} datasetId={datasetId} />;
        case DatasetFieldType.WYSIWYG:
            return <WYSIWYGField
                {...defaultProps}
                value={value as RawDraftContentState | null}
                fieldOpen={fieldOpen}
                toolbarHidden={disabled}
                datasetId={datasetId}
                isCollapsible={field.settings.isCollapsible}
                toggleSection={toggleSection}
                getSectionOpen={getSectionOpen}
            />;
        case DatasetFieldType.LABEL:
            return <LabelField value={field.settings.labelContent!} />;
        case DatasetFieldType.CALCULATOR:
            return <Calculator
                {...defaultProps}
                value={value as CalculatorField[]}
                fieldType={field.settings.calculatorFieldType!}
                showOutputField={field.settings.showOutputField!}
                showGracePeriod={field.settings.showGracePeriod!}
                includeFreeTextOption={field.settings.includeFreeTextOption}
                fieldOpen={fieldOpen}
                datasetId={datasetId}
            />;
        case DatasetFieldType.CURRENCY_AMOUNT:
            return <CurrencyAmountField
                {...defaultProps}
                currencyValue={value as CurrencyValue}
                datasetId={datasetId}
            />;
        default:
            return <div className={styles.unknownFieldType}>Field type unknown.</div>;
    }
};

interface SingleFieldProps {
    field: SingleInstanceField;
    sectionId: string;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedFormField[];
    annexFieldIds: string[];
    width?: number;
    isLastFieldInGroup?: boolean;
    isLastFieldInSection?: boolean;
    index: number;
    singleIndex?: string;
    groupIndex?: number;
    isAnnexInstance: boolean;
    datasetOnlySection?: boolean;
    datasetId: number;
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    isAgencyECS?: boolean;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    isMultiToggle?: boolean;
    isMLCorrection?: boolean;
}

export const SingleField: React.FC<SingleFieldProps> = ({
    field,
    width = 100,
    isLastFieldInGroup = false,
    isLastFieldInSection = false,
    index,
    sectionId,
    singleIndex,
    groupIndex,
    isEditing,
    isUpdating,
    fieldsUpdated,
    annexFieldIds,
    isAnnexInstance,
    datasetOnlySection = false,
    datasetId,
    parentFieldId,
    instanceExecutedDateMatchesParent,
    modalInstance,
    toggleSection,
    getSectionOpen,
    isAgencyECS = false,
    hiddenFields,
    datasetHidden,
    isMultiToggle,
    isMLCorrection
}) => {
    const { label, description, refLabel, settings, value, id, clauseLabel, type } = field;
    const datasetInstances = useAppSelector(getCurrentInstances);
    const searchFieldSection = useAppSelector(getSearchFieldSection);
    const wysiwygFieldOpen = useMemo(() => !isUndefined(settings.isCollapsible) && !settings.isCollapsible, [settings.isCollapsible]);
    const openField = useMemo(() => ({ sectionId, datasetId: datasetId.toString() || '', fieldId: id!, type: OpenFieldType.FIELD, groupIndex, parentFieldId }), [groupIndex, datasetId, id, sectionId, parentFieldId]);
    const fieldOpen = useMemo(() => getSectionOpen(openField) || datasetOnlySection || wysiwygFieldOpen, [getSectionOpen, openField, datasetOnlySection, wysiwygFieldOpen]);
    const fieldOpenIcon = fieldOpen ? CaretDown : CaretSide;
    const { color, backgroundColor } = useFieldHighlightColor(settings.highlight);

    const isCollapsible = settings.isCollapsible || [DatasetFieldType.DATASET, DatasetFieldType.CALCULATOR].includes(type);
    const testId = singleIndex || index;
    const fieldUpdatedByInstance = useMemo(() => fieldsUpdated.map(({ id }) => id).includes(id!) && instanceExecutedDateMatchesParent, [fieldsUpdated, id, instanceExecutedDateMatchesParent]);
    const showFieldUpdated = fieldUpdatedByInstance && !isUpdating && !isEditing;
    const newDatasetField = field.settings.datasetUpdated || false;
    const showDatasetUpdated = newDatasetField && !isUpdating && !isEditing;
    const showAIModified = !!settings.aiModified && ((!isUpdating && !isEditing) || !!isMLCorrection);
    const showAIModifiedUserCorrected = !!settings.aiModifiedUserCorrected && ((!isUpdating && !isEditing) || !!isMLCorrection);
    const disabled = !isEditing && !(isUpdating && (fieldUpdatedByInstance || newDatasetField));
    const includedInAnnex = !isAnnexInstance && annexFieldIds.includes(id!);
    const showFieldLabel = type !== DatasetFieldType.LABEL && !datasetOnlySection;
    const menuPortalTarget = document.body;
    const isLabelField = type === DatasetFieldType.LABEL;

    const userRole = useAppSelector(getUserRole);
    const isAdminUser = adminRoles.includes(userRole!);

    const riskTolerance = useAppSelector(getRiskToleranceForDefinitions);
    const riskToleranceForDataset = riskTolerance.find(riskTolerance => riskTolerance.datasetId === datasetId);

    const fieldRiskConfig = !isUndefined(riskToleranceForDataset) && riskToleranceForDataset.config.find(({ fieldId }) => fieldId === id) || undefined;

    const showRiskFieldIndicator = !isUndefined(fieldRiskConfig) && !!fieldRiskConfig.includeRiskField && !getRiskToleranceConfigIsEmpty(fieldRiskConfig, type, value);

    const childDatasetInstance = useMemo(() => !isUndefined(settings.datasetLinked) ? datasetInstances.find(({ instance, parentFieldId }) => (instance.datasetId === parseInt(settings.datasetLinked as string) || (!isNull(instance.annexDefinitionId) && instance.annexDefinitionId === parseInt(settings.datasetLinked as string))) && parentFieldId === id)?.instance : undefined, [datasetInstances, settings, id]);

    const fieldTypeIcon = useMemo(() => {
        if (type === DatasetFieldType.DATASET && !isUndefined(childDatasetInstance) && childDatasetInstance.datasetType === DatasetType.TABLE) {
            return <Icon icon={Table} fontSize={25} />;
        }
        if (type === DatasetFieldType.CALCULATOR) {
            return <Icon icon={CalculatorIcon} fontSize={24} />;
        }
        return null;
    }, [type, childDatasetInstance]);

    const singleFieldLabel = useMemo(() => {
        if (isAgencyECS) {
            return <AgencyECSLabel
                id={id!}
                index={index}
                sectionId={sectionId}
                label={label}
                datasetId={datasetId}
                parentFieldId={parentFieldId}
                ecsTableDatasetId={settings.datasetLinked!}
                disabled={disabled}
                linkedEntities={settings.linkedEntities || null}
                canDelete={isAdminUser || isNull(field.value)}
            />;
        }
        return <OverflowTooltip
            className={classnames(styles.fieldLabel, {
                [styles.emptyFieldLabel]: !label,
                [styles.datasetSectionText]: type === DatasetFieldType.DATASET,
                [styles.calculatorFieldLabel]: type === DatasetFieldType.CALCULATOR
            })}
            overlayText={label || 'Label...'}
            testId={`form-field-${testId}-label`}
            onClick={() => isCollapsible && toggleSection(openField)}
        />;
    }, [isAgencyECS, id, index, sectionId, label, parentFieldId, datasetId, settings.datasetLinked, settings.linkedEntities, isAdminUser, field.value, type, testId, isCollapsible, toggleSection, openField, disabled]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields && hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const sectionIsHidden = useMemo(() => !!currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(field => field.sectionId === sectionId), [currentHiddenDatasetFields, sectionId]);

    const fieldHidden = useMemo(() => sectionIsHidden || datasetHidden || !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields, id, sectionIsHidden, datasetHidden]);

    const isSearchedField = useMemo(() => !isNull(searchFieldSection) && searchFieldSection.type === OpenFieldType.FIELD && searchFieldSection.fieldId === id && !isUndefined(searchFieldSection.sectionId) && searchFieldSection.sectionId === sectionId, [searchFieldSection, id, sectionId]);

    if (fieldHidden) {
        return null;
    }

    if (isMultiToggle) {
        return (
            <div className={styles.multiToggleField} id={`dataset-instance-field-${id}`}>
                <div className={classnames(styles.multiToggleLabelWrapper, { [styles.emptyFieldLabel]: !label, [styles.searchedFieldWrapper]: isSearchedField })}>
                    <div className={classnames(styles.multiToggleLabel, { [styles.riskFieldMultiToggleLabel]: showRiskFieldIndicator })}><OverflowTooltip overlayText={label || 'Label...'} /></div>
                    {showRiskFieldIndicator && <SingleIndicator indicator={getRiskToleranceIndicator(fieldRiskConfig, value, type)} fontSize={22} />}
                </div>
                {getField(
                    field,
                    value,
                    isEditing,
                    isUpdating,
                    showFieldUpdated,
                    showDatasetUpdated,
                    showAIModified,
                    showAIModifiedUserCorrected,
                    includedInAnnex,
                    index,
                    disabled,
                    fieldOpen,
                    datasetId,
                    parentFieldId,
                    toggleSection,
                    getSectionOpen,
                    sectionId,
                    groupIndex,
                    menuPortalTarget,
                    modalInstance,
                    fieldHidden,
                    isMLCorrection
                )}
            </div>
        );
    }

    return (
        <div
            className={classnames(styles.singleFieldWrapper, {
                [styles.lastFieldInGroup]: isLastFieldInGroup,
                [styles.isLabelField]: isLabelField && !isLastFieldInSection,
                [styles.searchedFieldWrapper]: isSearchedField
            })}
            style={{ width: `${width}%` }}
            data-testid={`form-field-${testId}-wrapper`}
            id={`dataset-instance-field-${id}`}
        >
            {showFieldLabel &&
                <div className={classnames(styles.fieldTitleWrapper, { [styles.fieldCanCollapse]: isCollapsible })} style={{ color, backgroundColor }}>
                    {isCollapsible &&
                        <div className={styles.fieldOpenWrapper} onClick={() => toggleSection(openField)} data-testid='collapsible-field'>
                            {fieldTypeIcon}
                            <Icon icon={fieldOpenIcon} fontSize={15} />
                        </div>
                    }
                    {singleFieldLabel}
                    {description && <InformationTooltip content={description} />}
                    {refLabel && settings.showRef && <InformationTooltip content={refLabel} label='?' />}
                    {settings.showClause &&
                        <Clause
                            index={index}
                            groupIndex={groupIndex}
                            testId='clause'
                            clauseLabel={clauseLabel}
                            modalOpen={settings.clauseOpen || false}
                            sectionId={sectionId}
                            isEditing={!isEditing}
                            datasetId={datasetId}
                            modalInstance={modalInstance}
                            parentFieldId={parentFieldId}
                        />
                    }
                    {showRiskFieldIndicator && <SingleIndicator indicator={getRiskToleranceIndicator(fieldRiskConfig, value, type)} fontSize={22} />}
                </div>
            }
            {getField(
                field,
                value,
                isEditing,
                isUpdating,
                showFieldUpdated,
                showDatasetUpdated,
                showAIModified,
                showAIModifiedUserCorrected,
                includedInAnnex,
                index,
                disabled,
                fieldOpen,
                datasetId,
                parentFieldId,
                toggleSection,
                getSectionOpen,
                sectionId,
                groupIndex,
                menuPortalTarget,
                modalInstance,
                fieldHidden,
                isMLCorrection
            )}
        </div>
    );
};
