import { noop } from 'lodash/fp';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Notification } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import { getSystemNotification, getSystemNotificationModalOpen, toggleSystemNotificationModal } from './store';
import styles from './Notifications.module.scss';

const { white } = styles;

export const SystemNotification: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getSystemNotificationModalOpen);
    const notification = useAppSelector(getSystemNotification);

    const closeModal = useCallback(() => dispatch(toggleSystemNotificationModal(null)), [dispatch]);

    if (notification) {
        const { body: { content, description } } = notification;
        return (
            <ConfirmationModal
                isOpen={isOpen}
                closeModal={closeModal}
                showConfirm={false}
                showClose
                closeOnOverlayClick
                closeLabel='Close'
            >
                <div className={styles.systemNotificationModal}>
                    <ModalHeader icon={Notification} label={description} overflowTooltipClassName={styles.systemModalHeader} />
                    <div className={styles.contentWrapper}>
                        <WYSIWYG
                            content={content}
                            updateContent={noop}
                            height='40vh'
                            disabled
                            showBorder={false}
                            borderColor={white}
                            toolbarHidden
                            padding='0px'
                            editorPadding='0px'
                        />
                    </div>
                </div>
            </ ConfirmationModal>
        );
    }
    return null;
};
