import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide, Reference, Engine } from '../../../shared/icons';
import { CustomTooltip, IconTooltip } from '../../../shared/tooltip';
import { toggleOpenFieldSection } from '../store/actions';
import { getIsFirstTimelineEntry, getOpenFieldsAndSections, getSectionIncludesUnverifiedPageRefs, getSectionUpdated } from '../store/selectors';
import styles from './OpinionSection.module.scss';

interface SectionWrapperProps {
    id: string;
    label: string;
    children: JSX.Element;
    isEditing: boolean;
    isUpdating: boolean;
    isNettingEngineField?: boolean;
    nettingEngineTooltip?: string;
}

export const SectionWrapper: React.FC<SectionWrapperProps> = ({ label, id, children, isUpdating, isEditing, isNettingEngineField = false, nettingEngineTooltip = 'Some of the fields in this section are used in the Netting Engine anaylsis' }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const sectionUpdated = useAppSelector(getSectionUpdated(id));
    const isFirstTimelineEntry = useAppSelector(getIsFirstTimelineEntry);
    const sectionIncludesUnverifiedPageRefs = useAppSelector(getSectionIncludesUnverifiedPageRefs(id));
    const isOpen = useMemo(() => openFieldsAndSections.includes(id), [openFieldsAndSections, id]);
    const sectionOpenIcon = isOpen ? CaretDown : CaretSide;
    const toggleSection = useCallback(() => dispatch(toggleOpenFieldSection(id)), [dispatch, id]);
    const showSectionUpdated = useMemo(() => sectionUpdated && !isFirstTimelineEntry && !isUpdating && !isEditing, [sectionUpdated, isFirstTimelineEntry, isUpdating, isEditing]);

    return (
        <div key={id} className={styles.sectionWrapper} data-testid={`opinion-section-${id}-wrapper`}>
            <div className={styles.sectionHeader} onClick={toggleSection} data-testid={`opinion-section-${id}-collapse`}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={sectionOpenIcon} fontSize={15} />
                </div>
                <div className={styles.sectionLabel} data-testid={`opinion-section-${id}-label`}>{label}</div>
                {isNettingEngineField && <IconTooltip content={nettingEngineTooltip} icon={Engine} margin='0px 10px 0px 0px' />}
                {showSectionUpdated &&
                    <div className={styles.sectionUpdated}>UPDATED</div>
                }
                {sectionIncludesUnverifiedPageRefs &&
                    <CustomTooltip overlayText='Contains page references which have not been duplicated but not yet verified'>
                        <div className={styles.unverifiedSectionIcon}>
                            <Icon icon={Reference} fontSize={20} />
                        </div>
                    </CustomTooltip>
                }
            </div>
            {isOpen &&
                <div className={styles.allFieldsWrapper}>
                    {children}
                </div>
            }
        </div>
    );
};
