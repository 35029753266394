import React, { useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { isNull } from 'lodash/fp';

import styles from './Dropdown.module.scss';
import { DropdownOption, customStyles, SharedDropdownProps } from './Dropdown';

export interface CreatableDropdownProps extends SharedDropdownProps {
    updateOptions: (value: DropdownOption[]) => void;
    closeOnSelect?: boolean;
    hideDropdownIndicator?: boolean;
    selectedIds?: string[];
}

export const CreatableDropdown: React.FC<CreatableDropdownProps> = ({
    testId,
    value,
    disabled = false,
    isMulti = false,
    onChange,
    updateOptions,
    options,
    placeholder,
    label,
    isClearable = true,
    menuPortalTarget,
    marginBottom = '',
    controlBackgroundColor,
    hideIndicator = false,
    controlHeight = '38px',
    minControlHeight = '38px',
    // This is to allow three times a multi-select value height
    maxControlHeight = '86px',
    disableControlColor,
    controlBorderColor,
    noControlBorder,
    fontWeight = 500,
    menuPlacement = 'auto',
    cursor = 'auto',
    closeOnSelect = true,
    hideDropdownIndicator = false,
    selectedIds = []
}) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleCreate = (inputValue: string) => {
        setIsLoading(true);
        // Capitalises the first letter at the start of the string "^\w", after each space "\s\w" and after each hyphen "-\w"
        const formattedOption = inputValue.replace(/(^\w|\s\w|-\w)/g, m => m.toUpperCase());
        const newOption = { label: formattedOption, value: formattedOption };
        const updatedOptions = [...options, newOption].sort((a, b) => a.label.localeCompare(b.label));
        const currentValue = isNull(value) ? [] : value;
        const updatedValue = isMulti ? [...(currentValue as DropdownOption[]), newOption] : newOption;
        setTimeout(() => {
            updateOptions(updatedOptions);
            onChange(updatedValue, { action: 'select-option', option: newOption });
            setIsLoading(false);
        }, 1000);
    };

    const dropdownDisabled = useMemo(() => disabled || isLoading, [disabled, isLoading]);

    return (
        <div
            data-testid={`creatable-dropdown-${testId}`}
            className={styles.dropdownFieldWrapper}
            style={{ marginBottom, cursor }}
        >
            {label && <div data-testid={`creatable-dropdown-${testId}-label`} className={styles.dropdownLabel}>{label}</div>}
            <CreatableSelect
                classNamePrefix='ark-dropdown'
                className={styles.dropdownField}
                isDisabled={dropdownDisabled}
                isLoading={isLoading}
                onChange={onChange}
                onCreateOption={handleCreate}
                options={options}
                value={value}
                isMulti={isMulti}
                isClearable={isClearable}
                placeholder={placeholder}
                styles={customStyles}
                menuPortalTarget={menuPortalTarget}
                isOptionDisabled={({ disabled }) => !!disabled}
                controlBackgroundColor={controlBackgroundColor}
                controlBorderColor={controlBorderColor}
                hideIndicator={hideIndicator}
                controlHeight={controlHeight}
                disableControlColor={disableControlColor}
                noControlBorder={noControlBorder}
                fontWeight={fontWeight}
                minControlHeight={minControlHeight}
                maxControlHeight={maxControlHeight}
                menuPlacement={menuPlacement}
                closeMenuOnSelect={closeOnSelect}
                hideDropdownIndicator={hideDropdownIndicator}
                selectedIds={selectedIds}
            />
        </div>
    );
};
