import { isNull } from 'lodash/fp';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { CheckList, LinkChain } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { IconTooltip } from '../../shared/tooltip';
import { deleteDoraFunctionStarted, getDeleteFunctionConfirmationModalOpen, getFunctionToDelete, getFunctionToDeleteUsage, toggleDeleteFunctionConfirmationModal } from './store';
import styles from './DoraFunctions.module.scss';

export const DeleteFunctionConfirmationModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getDeleteFunctionConfirmationModalOpen);
    const functionToDelete = useAppSelector(getFunctionToDelete);
    const closeModal = useCallback(() => dispatch(toggleDeleteFunctionConfirmationModal(null)), [dispatch]);
    const functionUsage = useAppSelector(getFunctionToDeleteUsage);
    const supplyChainWarning = 'A supply chain has been configured and includes the use of this function, the supply chain will be lost if the function is deleted.';
    const deleteFunction = useCallback(() => {
        dispatch(deleteDoraFunctionStarted(functionToDelete!.functionId));
        dispatch(toggleDeleteFunctionConfirmationModal(null));
    }, [dispatch, functionToDelete]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={deleteFunction}
            closeLabel='Close'
            testId='delete-dora-function'
            showOverlay={false}
        >
            <div className={styles.deleteFunctionConfirmationWrapper}>
                <ModalHeader icon={CheckList} label={`Delete ${functionToDelete?.functionName}`} testId='delete-dora-function-confirmation-modal-header' />
                <div className={styles.deleteFunctionWarning}>Are you sure you want to delete this function?</div>
                {functionUsage.length > 0 &&
                    <div className={styles.functionUsageWrapper}>
                        <div className={styles.functionUsageLabel}>The function is currently linked to these companies and will be unlinked if deleted.</div>
                        <Scrollable maxHeight='200px'>
                            <div className={styles.scrollableWrapper}>
                                {functionUsage.map(({ companyName, supplyChainDetails, companyId }) => {
                                    return (
                                        <div className={styles.companyWrapper} key={companyId}>
                                            <div className={styles.companyName}>- {companyName}</div>
                                            {!isNull(supplyChainDetails) && <IconTooltip content={supplyChainWarning} fontSize={20} icon={LinkChain} placement='right' />}
                                        </div>
                                    );
                                })}
                            </div>
                        </Scrollable>
                    </div>
                }
                <div className={styles.deleteFunctionWarning}>Please note this cannot be undone.</div>
            </div>
        </ConfirmationModal>
    );
};
