import { getOr, isEqual, isNull, isUndefined, set } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Icon } from '../icon/Icon';
import { CaretDown, CaretSide } from '../icons';
import { TableFilters, FilterDropdownOptions, TableFilterType } from '../modal/TableFilterModal';
import { OverflowTooltip } from '../tooltip';
import styles from './Table.module.scss';

export interface CustomFilterHeader {
    field: string;
    header: string;
}

interface ReadOnlyFiltersProps {
    filters: TableFilters;
    filterDropdownOptions: FilterDropdownOptions;
    customFilterHeaders: CustomFilterHeader[];
}

const emptyFilter: TableFilterType = { text: '', dropdown: null };

export const ReadOnlyFilters: React.FC<ReadOnlyFiltersProps> = (({ filters, filterDropdownOptions, customFilterHeaders }) => {
    const [openSections, setOpenSections] = useState<string[]>([]);

    const populatedFilters: TableFilters = useMemo(() => customFilterHeaders.reduce((acc, curr) => {
        const fieldFilters: TableFilterType | undefined = getOr(undefined, curr.field, filters);
        if (!isUndefined(fieldFilters) && !isEqual(fieldFilters, emptyFilter)) {
            return set(curr.field, fieldFilters, acc);
        }
        return acc;
    }, {}), [customFilterHeaders, filters]);

    const getSectionOpenIcon = useCallback((isOpen: boolean) => isOpen ? CaretDown : CaretSide, []);

    const toggleSection = useCallback((key: string) => {
        const sectionOpen = openSections.includes(key);
        const updatedOpenSections = sectionOpen ? openSections.filter(openKey => openKey !== key) : [...openSections, key];
        setOpenSections(updatedOpenSections);
    }, [openSections]);

    useEffect(() => {
        const allSectionsKeys = !isNull(filters) ? Object.keys(filters) : [];
        setOpenSections(allSectionsKeys);
    }, [filters]);

    const getFilterLabel = useCallback((key: string, numberSelected: number) => `${customFilterHeaders.find(({ field }) => field === key)?.header || ''} (${numberSelected})`, [customFilterHeaders]);
    const getFilterOptions = useCallback((key: string, filterValues: TableFilterType) => {
        if (!isNull(filterValues.dropdown)) {
            const relevantDropdownOptions = filterDropdownOptions[key] || [];
            return relevantDropdownOptions.filter(({ value }) => filterValues.dropdown?.includes(value)).map(({ label }) => label);
        }
        return [filterValues.text];
    }, [filterDropdownOptions]);

    return (
        <div className={styles.readOnlyCustomFilterInformation}>
            {Object.entries(populatedFilters).map(([key, filterValues]) => {
                const filterOptions = getFilterOptions(key, filterValues);
                return (
                    <div key={key} className={styles.readOnlyCustomFilterSection}>
                        <div className={styles.readOnlyCustomFilterSectionHeader} onClick={() => toggleSection(key)}>
                            <div className={styles.sectionOpenIcon}>
                                <Icon icon={getSectionOpenIcon(openSections.includes(key))} fontSize={15} />
                            </div>
                            <div>{getFilterLabel(key, filterOptions.length)}</div>
                        </div>
                        {openSections.includes(key) &&
                            <div className={styles.filterValuesWrapper}>
                                {getFilterOptions(key, filterValues).map((filter, index) => (
                                    <div key={index} className={styles.filterValue}>
                                        <OverflowTooltip overlayText={`- ${filter}`} />
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                );
            })}
        </div>
    );
});
