import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Options } from 'react-select';

import { useAppSelector } from '../../../../hooks/react-redux';
import { getAllDropdownLists } from '../../../admin/dropdown-lists/store';
import { RiskAssociated, RiskAssociatedValue, RiskComparator, TimeComparatorRisk, TimeRiskField } from '../../../admin/risk-tolerance/store';
import { CitySelect } from '../../../datasets/fields';
import { DropdownOption, Dropdown } from '../../dropdown/Dropdown';
import styles from './RiskField.module.scss';

const { grey } = styles;

interface TimeRiskFieldConfigProps {
    riskField: TimeRiskField;
    riskAssociated: RiskAssociated;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValue) => void;
}

export const TimeRiskFieldConfig: React.FC<TimeRiskFieldConfigProps> = ({ riskField, riskAssociated, updateRiskConfig }) => {
    const { riskConfig, includeRiskField } = riskField;
    const [comparator, setComparator] = useState<null | RiskComparator>(null);
    const [value, setValue] = useState<[string, string]>(['', '']);

    const allDropdownLists = useAppSelector(getAllDropdownLists);

    const comparatorOptions = useMemo(() => Object.values(RiskComparator).map(value => ({ value, label: value })), []);
    const comparatorValue = useMemo(() => comparator ? { value: comparator, label: comparator } : null, [comparator]);

    const updateComparatorValue = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let updatedComparator = null;
        if (!isNull(dropdownValue)) {
            updatedComparator = (dropdownValue as DropdownOption).value as RiskComparator;
        }
        const timeConfig: TimeComparatorRisk = { value, comparator: updatedComparator };
        setComparator(updatedComparator);
        updateRiskConfig(riskAssociated, timeConfig);
    }, [updateRiskConfig, riskAssociated, value]);

    const updateValue = useCallback((value: [string, string]) => {
        const timeConfig: TimeComparatorRisk = { value, comparator };
        setValue(value);
        updateRiskConfig(riskAssociated, timeConfig);
    }, [comparator, riskAssociated, updateRiskConfig]);

    const cityOptions = allDropdownLists.find(({ name }) => name === 'City')?.options || [];
    const cityOptionsPlusLocal = [...cityOptions, 'Local Time'];
    const updateTimeValue = (timeValue: string) => updateValue([timeValue, value[1]]);
    const updateCity = (cityValue: string | null) => updateValue([value[0], cityValue || '']);

    useEffect(() => {
        setValue(riskConfig[riskAssociated].value);
        setComparator(riskConfig[riskAssociated].comparator);
        return () => {
            setValue(['', '']);
            setComparator(null);
        };
    }, [riskConfig, riskAssociated]);

    return (
        <div className={styles.timeWrapper}>
            <div className={styles.comparatorWrapper}>
                <Dropdown options={comparatorOptions} value={comparatorValue} onChange={updateComparatorValue} isClearable controlBorderColor={grey} disabled={!includeRiskField} />
            </div>
            <input
                className={styles.timeValue}
                onChange={e => updateTimeValue(e.target.value)}
                value={value[0]}
                type='time'
                disabled={!includeRiskField}
            />
            <div className={styles.cityValue}>
                <CitySelect
                    options={cityOptionsPlusLocal}
                    value={value[1]}
                    updateCity={updateCity}
                    controlBorderColor={grey}
                    width='100%'
                    disabled={!includeRiskField}
                />
            </div>
        </div>
    );
};
