import React from 'react';
import classnames from 'classnames';

import { useAppDispatch } from '../../hooks/react-redux';
import { fieldListIcons } from '../admin/dataset-builder/FieldList';
import { AgencyDatasetField, DatasetFieldType } from '../datasets/store';
import { Icon as ArkIcon } from '../shared/icon/Icon';
import { addAnnexDatasetField, addCustomAnnexDatasetField, AgencyAnnexField, removeAnnexDatasetField } from './store';
import styles from './AgencyAnnex.module.scss';
import { Text } from '../shared/icons';
import { Scrollable } from '../shared/scrollable/Scrollable';
import { OverflowTooltip } from '../shared/tooltip';

interface FieldListItemProps {
    label: string;
    type: DatasetFieldType;
    onClick: () => void;
    selected: boolean;
}

const FieldListItem: React.FC<FieldListItemProps> = ({ label, onClick, selected, type }) => (
    <button onClick={onClick} className={classnames(styles.fieldListItem, { [styles.selectedFieldListItem]: selected })} data-testid={`field-list-item-button-${label}`}>
        <div className={styles.fieldListLabel} data-testid={`field-list-item-${label}`}>
            <OverflowTooltip overlayText={label} />
        </div>
        <ArkIcon icon={fieldListIcons[type]} fontSize={30} />
    </button>
);

interface FieldListProps {
    availableFields: AgencyDatasetField[];
    datasetFields: AgencyAnnexField[];
}

export const FieldList: React.FC<FieldListProps> = ({ availableFields, datasetFields }) => {
    const dispatch = useAppDispatch();
    const addField = (field: AgencyDatasetField) => dispatch(addAnnexDatasetField(field));
    const removeField = (id: string) => dispatch(removeAnnexDatasetField(id));
    const addCustomField = () => dispatch(addCustomAnnexDatasetField());

    return (
        <div className={styles.fieldListWrapper} data-testid='field-list-items-wrapper'>
            <Scrollable height='calc(100% - 40px)'>
                {availableFields.map(field => {
                    const selected = datasetFields.map(({ id }) => id).includes(field.id!);
                    const onClick = selected ? () => removeField(field.id!) : () => addField(field);
                    return (
                        <FieldListItem key={field.id} type={field.type} label={field.label} onClick={onClick} selected={selected} />
                    );
                })}
            </Scrollable>
            <button onClick={addCustomField} className={styles.fieldListItem} data-testid='field-list-custom-button' >
                <div className={styles.fieldListLabel} data-testid='field-list-custom-label'>
                    <OverflowTooltip overlayText='Custom Field' />
                </div>
                <ArkIcon icon={Text} fontSize={30} />
            </button>
        </div>
    );
};
