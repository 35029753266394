import React from 'react';

export const Attestation: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg x='0px' y='0px' width={width || '1em'} height={height || '1em'} viewBox='0 0 128 128'>
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2
   C114.1,108.7,108.7,114.1,102.1,114.1z"/>
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M39.6,42.3H27.7c-1.6,0-2.9-1.3-2.9-2.9V27.5c0-1.6,1.3-2.9,2.9-2.9h11.9c1.6,0,2.9,1.3,2.9,2.9v11.9
   C42.5,41,41.2,42.3,39.6,42.3z"/>
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M98.8,67.4H27.7c-1.6,0-2.9-1.3-2.9-2.9v-12c0-1.6,1.3-2.9,2.9-2.9h71.1c1.6,0,2.9,1.3,2.9,2.9v11.9
   C101.7,66,100.4,67.4,98.8,67.4z"/>
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M69.2,42.3H57.3c-1.6,0-2.9-1.3-2.9-2.9V27.5c0-1.6,1.3-2.9,2.9-2.9h11.9c1.6,0,2.9,1.3,2.9,2.9v11.9
   C72.1,41,70.8,42.3,69.2,42.3z"/>
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="83.4" y1="26.9" x2="101.7" y2="26.9" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="83.4" y1="33.4" x2="101.7" y2="33.4" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="83.4" y1="39.8" x2="101.7" y2="39.8" />
        <g>
            <path fill={color || 'currentColor'} d="M50.4,81.6c0,5.8-4.7,10.5-10.5,10.5s-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5C45.7,71.1,50.4,75.8,50.4,81.6" />
            <path fill={color || 'currentColor'} d="M58,104.5c-2.1-5.8-6.7-10.2-12.6-11.9c-0.2,0-0.4,0-0.5,0c-1.5,0.8-3.1,1.2-4.9,1.2s-3.4-0.4-4.9-1.2
       c-0.1-0.1-0.3-0.1-0.5,0C28.7,94.3,24,98.8,22,104.5c-0.6,1.6,0.6,3.3,2.3,3.3h31.5C57.4,107.8,58.6,106,58,104.5L58,104.5z"/>
        </g>
        <g>
            <path fill={color || 'currentColor'} d="M78.6,104.3l-0.5,0.4c-1.3,1.2-3.2,0.9-4.3-0.5L67,95.2c-1-1.4-0.8-3.6,0.4-4.7l0.5-0.4c1.3-1.2,3.2-0.9,4.3,0.5l6.8,9.1
       C80.1,101,79.9,103.1,78.6,104.3z"/>
            <path fill={color || 'currentColor'} d="M74,104.2l-0.4-0.5c-1-1.4-0.8-3.6,0.4-4.7l25.9-23.5c1.3-1.2,3.2-0.9,4.3,0.5l0.4,0.5c1,1.4,0.8,3.6-0.4,4.7l-25.9,23.5
       C76.9,105.8,75,105.7,74,104.2z"/>
        </g>
    </svg>
);
