import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNull } from 'lodash/fp';

import { useWindowResize } from '../../../hooks/useWindowResize';
import { Analytics, AnalyticsTile } from '../../shared/analytics/Analytics';
import { Spinner } from '../../shared/spinner/Spinner';
import { DoraAnalyticsChart, fetchNetworkDiagramAnalyticsStarted, fetchOutstandingContractualDataAnalyticsStarted, fetchThirdPartyCoverageDataStarted, getTileInView, setDoraTileInView } from './store';
import { ThirdPartyCoverage } from './charts/ThirdPartyCoverage';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { NetworkDiagramWrapper } from './charts/network-diagram/NetworkDiagramWrapper';
import { ContractualInformationWrapper } from './charts/contractual-information/ContractualInformationWrapper';

export interface DoraAnalyticsRouteParams {
    chart: string | undefined;
}

export const DoraAnalytics: React.FC<RouteComponentProps<DoraAnalyticsRouteParams>> = ({ match: { params } }) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [tileDimensions, setTileDimensions] = useState<undefined | { height: number; width: number; }>(undefined);

    const [screenWidth, screenHeight] = useWindowResize();
    const dispatch = useAppDispatch();
    useFetchStarted([fetchThirdPartyCoverageDataStarted(), fetchNetworkDiagramAnalyticsStarted(), fetchOutstandingContractualDataAnalyticsStarted()]);

    const tileInView = useAppSelector(getTileInView);
    const setTileInView = useCallback((chart: DoraAnalyticsChart | null, shouldRedirect = true) => dispatch(setDoraTileInView(chart, shouldRedirect)), [dispatch]);

    const analyticsCharts: AnalyticsTile[] = useMemo(() => [
        {
            id: DoraAnalyticsChart.THIRD_PARTY_COVERAGE,
            chart: <ThirdPartyCoverage setTileInView={setTileInView} tileInView={tileInView} analyticsSpinner={showSpinner} dimensions={tileDimensions} />,
            column: 1,
            fullColumnTile: true,
            marginBottom: '10px'
        },
        {
            id: DoraAnalyticsChart.NETWORK_DIAGRAM,
            chart: <NetworkDiagramWrapper setTileInView={setTileInView} tileInView={tileInView} analyticsSpinner={showSpinner} dimensions={tileDimensions} />,
            column: 2,
            marginBottom: '10px'
        },
        {
            id: DoraAnalyticsChart.CONTRACTUAL_DATA,
            chart: <ContractualInformationWrapper setTileInView={setTileInView} tileInView={null} analyticsSpinner={showSpinner} dimensions={tileDimensions} />,
            column: 2
        }
    ], [setTileInView, showSpinner, tileInView, tileDimensions]);

    const tileInViewChart = useMemo(() => analyticsCharts.find(({ id }) => id === tileInView)?.chart || null, [analyticsCharts, tileInView]);

    useEffect(() => {
        setShowSpinner(true);
        if (screenWidth && screenHeight) {
            const width = (screenWidth - 80) / (isNull(tileInView) ? 2 : 1);
            const height = screenHeight - 190;
            setTileDimensions({ height, width });
            setTimeout(() => setShowSpinner(false), 50);
        }
    }, [screenWidth, screenHeight, setShowSpinner, tileInView]);

    const allUrlCharts = useMemo(() => Object.values(DoraAnalyticsChart).map(val => val.replace(' ', '-')), []);
    const tileIsValidAndNotInView = useCallback((urlChart: string) => {
        const urlChartIsValid = allUrlCharts.includes(urlChart);
        if (urlChartIsValid) {
            const chart = urlChart.replaceAll('-', ' ') as DoraAnalyticsChart;
            return isNull(tileInView) || tileInView !== chart;
        }
    }, [tileInView, allUrlCharts]);

    useEffect(() => {
        if (params.chart && tileIsValidAndNotInView(params.chart)) {
            const chart = params.chart.replaceAll('-', ' ') as DoraAnalyticsChart;
            setTileInView(chart, false);
        }
    }, [params, tileIsValidAndNotInView, setTileInView, tileInView]);

    if (showSpinner) {
        return <Spinner />;
    }

    return (
        <Analytics
            charts={analyticsCharts}
            showSpinner={showSpinner}
            testId='dora-analytics'
            analyticsColumns={2}
            analyticsRows={2}
            tileInView={tileInViewChart}
        />
    );
};
