import { CompanyEntity, SearchEntityBy } from '../components/admin/entity/store';
import { DoraConfiguration, DoraFunction } from '../components/dora/functions/store';
import { FunctionMigration } from '../components/dora/my-companies/store';
import { PreliminaryRoi } from '../components/dora/reporting/store';
import { DoraFunctionCompany, DoraSupplyChainAssessmentInfo, NewSupplyChainElement, SupplyChainElement } from '../components/dora/supply-chain/store';
import { TableFilters } from '../components/shared/modal/TableFilterModal';
import { ColumnSort } from '../components/shared/table/ArkTable';
import { api } from './services';

export interface CheckMigrationRequest {
    currentCompanyId: number | null;
    functionIds: number[];
}

interface SearchEntitiesRequest {
    type: SearchEntityBy;
    value: string;
    pageSize: number;
    pageNumber: number;
}

export const fetchPaginatedDoraCompanyEntities = async (reqBody: { filters: TableFilters; pageNumber: number; columnSort: ColumnSort | undefined; pageSize: number; doraInScope: number; }) => await api('dora/entity/getPaginatedDoraCompanyEntities', { ...reqBody }, 'post');
export const upsertDoraEntity = async (reqBody: { entity: CompanyEntity, doraFunctionIds?: number[]; }) => await api('dora/entity/upsert', { ...reqBody }, 'post');
export const checkCompanyFunctionMigration = async (reqBody: { entityId: number }) => await api('dora/entity/function/migrate/check', { ...reqBody }, 'post');
export const updateDoraROIMaintainer = async (reqBody: { filters: TableFilters; pageNumber: number; columnSort: ColumnSort | undefined; pageSize: number; doraInScope: number; entityId: number; isMaintainer: boolean; }) => await api('dora/entity/updateDoraROIMaintainer', { ...reqBody }, 'post');
export const fetchAllInScopeEntities = async () => await api('dora/entity/getAllInScope', {}, 'get');

export const fetchPaginatedThirdPartyCompanies = async (reqBody: { filters: TableFilters; pageNumber: number; pageSize: number; active: number; columnSort: ColumnSort | undefined; }) => await api('dora/thirdParties/getAll', { ...reqBody }, 'post');
export const upsertThirdPartyCompany = async (reqBody: { thirdPartyCompany: CompanyEntity; filters: TableFilters; pageNumber: number; pageSize: number; active: number; columnSort: ColumnSort | undefined; }) => await api('dora/thirdParties/upsert', { ...reqBody }, 'post');
export const searchThirdPartyCompanies = async (reqBody: SearchEntitiesRequest) => await api('dora/thirdParties/search', { ...reqBody }, 'post');

export const fetchEntityFunctionIds = async (reqBody: { entityId: number }) => await api('dora/entity/functionIds/get', { ...reqBody }, 'post');
export const fetchPaginatedDoraFunctions = async (reqBody: { filters: TableFilters; pageNumber: number; columnSort: ColumnSort | undefined; pageSize: number; }) => await api('dora/function/getPaginatedDoraFunctions', { ...reqBody }, 'post');
export const deleteDoraFunctionCheck = async (reqBody: { functionId: number }) => await api('dora/function/delete/check', { ...reqBody }, 'post');
export const deleteDoraFunction = async (reqBody: { functionId: number, filters: TableFilters; pageNumber: number; columnSort: ColumnSort | undefined; pageSize: number; }) => await api('dora/function/delete', { ...reqBody }, 'post');
export const upsertDoraFunction = async (reqBody: { doraFunction: DoraFunction; filters: TableFilters; pageNumber: number; columnSort: ColumnSort | undefined; pageSize: number; }) => await api('dora/function/upsert', { ...reqBody }, 'post');
export const checkDoraFunctionMigration = async (reqBody: CheckMigrationRequest) => await api('dora/function/migrate/check', { ...reqBody }, 'post');
export const migrateCompanyFunctions = async (reqBody: { functionMigration: FunctionMigration }) => await api('dora/function/migrate', { ...reqBody }, 'post');

export const fetchDoraConfiguration = async () => await api('dora/configuration/get', {}, 'get');
export const upsertFunctionConfiguration = async (reqBody: { doraConfiguration: DoraConfiguration; }) => await api('dora/configuration/upsert', { ...reqBody }, 'post');

export const fetchAllFunctionCompanies = async (reqBody: { filters: TableFilters; }) => await api('dora/functionCompany/getAll', { ...reqBody }, 'post');

export const fetchSupplyChainConfiguration = async (reqBody: { doraFunctionCompany: DoraFunctionCompany; }) => await api('dora/supplyChain/getAll', { ...reqBody }, 'post');
export const fetchAllBaseCompanies = async () => await api('dora/supplyChain/companies/getAll', {}, 'get');
export const upsertSupplyChainElement = async (reqBody: { element: SupplyChainElement | NewSupplyChainElement; doraFunctionCompanyId: number; assessmentInfo?: DoraSupplyChainAssessmentInfo; }) => await api('dora/supplyChain/upsert', { ...reqBody }, 'post');
export const upsertSupplyChainConfiguration = async (reqBody: { supplyChain: SupplyChainElement[]; doraFunctionCompany: DoraFunctionCompany; }) => await api('dora/supplyChain/configure', { ...reqBody }, 'post');
export const deleteSupplyChainElement = async (reqBody: { doraSupplyChainId: number; }) => await api('dora/supplyChain/element/delete', { ...reqBody }, 'post');
export const fetchSupplyChainElementDetails = async (reqBody: { doraSupplyChainId: number; }) => await api('dora/supplyChain/element/details', { ...reqBody }, 'post');

export const uploadDoraDocuments = async (reqBody: FormData) => await api('dora/supplyChain/element/document/upload', reqBody, 'post', {}, true);
export const previewDoraDocument = async (reqBody: { doraDocumentId: number }) => await api('dora/supplyChain/element/document/open', { ...reqBody }, 'post', {}, false, true);
export const downloadDoraDocument = async (reqBody: { doraDocumentId: number }) => await api('dora/supplyChain/element/document/download', { ...reqBody }, 'post', {}, false, true);

export const downloadRegisterOfInformation = async (reqBody: { preliminaryRoi: PreliminaryRoi; doubleRowHeight: boolean; }) => await api('dora/registerOfInformation/download', { ...reqBody }, 'post', {}, false, true);

export const linkSupplyChainElementToIctContract = async (reqBody: { originalDocumentId: number | null; doraSupplyChainId: number; doraSupplyChainContractId: number | undefined; }) => await api('dora/supplyChain/element/linkContract', { ...reqBody }, 'post');
