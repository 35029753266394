import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { AttestationManager } from '../../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../../shared/modal/TypeDeleteConfirmationModal';
import { deleteAttestationInstanceStarted, getConfirmDeleteAttestation, getIsDeleting, toggleDeleteAttestationConfirmationModal } from '../store';

export const DeleteConfirmationModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const attestationInstance = useAppSelector(getConfirmDeleteAttestation);
    const isDeleting = useAppSelector(getIsDeleting);

    const name = useMemo(() => attestationInstance?.attestationName || '', [attestationInstance]);

    const closeModal = useCallback(() => dispatch(toggleDeleteAttestationConfirmationModal(null)), [dispatch]);
    const deleteAttestationInstance = useCallback(() => !isNull(attestationInstance) && dispatch(deleteAttestationInstanceStarted(attestationInstance.attestationInstanceId)), [dispatch, attestationInstance]);

    if (isNull(attestationInstance)) {
        return null;
    }

    return (
        <TypeDeleteConfirmationModal
            isOpen={!isNull(attestationInstance)}
            closeModal={closeModal}
            deleteAction={deleteAttestationInstance}
            isDeleting={isDeleting}
            headerLabel='Delete Attestation'
            name={name}
            testId='attestation-manager'
            deleteLabel='attestation'
            icon={AttestationManager}
        />
    );
};
