import classnames from 'classnames';
import React from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { Icon } from '../../shared/icon/Icon';
import { Group, OfficeBuilding, User } from '../../shared/icons';
import styles from './Entity.module.scss';
import { EntityType, selectEntityType } from './store';

const entityTypesButton = [
    {
        title: 'Company',
        disabled: false,
        icon: OfficeBuilding,
        subtext: 'Data about your business and other third parties that you work with.',
        type: EntityType.COMPANY
    },
    {
        title: 'Person',
        disabled: false,
        icon: User,
        subtext: 'Data about your staff and people that work for, or with, your business.',
        type: EntityType.PERSON
    },
    {
        title: 'Group',
        disabled: false,
        icon: Group,
        subtext: 'Create a collection of your company entities to be grouped together.',
        type: EntityType.GROUP
    }
];

export const EntityTypeSelector: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectType = (type: EntityType) => dispatch(selectEntityType(type));
    return (
        <div className={styles.entityContentWrapper}>
            {entityTypesButton.map(({ title, disabled, icon, subtext, type }, index) => (
                <div
                    key={index}
                    onClick={() => selectType(type)}
                    className={classnames(styles.entityTypeButton, {
                        [styles.entityTypeButton__disabled]: disabled
                    })}
                    data-testid={`${title.toLowerCase()}-entity-type-select-button`}
                >
                    <Icon icon={icon} fontSize={40} />
                    <div className={styles.entityTypeButton__textWrapper}>
                        <div className={styles.entityTypeButton__title}>{title}</div>
                        <p className={styles.entityTypeButton__subtext}>{subtext}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};
