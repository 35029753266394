import { DocumentType } from '../components/documents/my-documents/store';
import { ArkOpinion } from '../components/opinions/my-opinions/store';
import { NettingRequestInformation } from '../components/opinions/netting-engine/store';
import { TableFilters } from '../components/shared/modal/TableFilterModal';
import { ColumnSort } from '../components/shared/table/ArkTable';
import { api } from './services';

type UploadOpinionRequest = FormData;
interface FetchAllRequest { pageNumber: number; filters: TableFilters; pageSize: number; columnSort: ColumnSort | undefined; }
interface DeleteOpinionRequest extends FetchAllRequest { location: string; incomplete: boolean; }
interface AssertBaseOpinionRequest { baseOpinionId: number; updateOpinionId: number; isBaseOpinion: boolean; }
interface FetchAllNettingEngineDocumentsRequest extends FetchAllRequest { documentType: DocumentType }

export const assertBaseOpinion = async (reqBody: AssertBaseOpinionRequest) => await api('opinion/assertBase', { ...reqBody }, 'post');
export const updateOpinionDetails = async (reqBody: ArkOpinion) => await api('opinion/updateDetails', { ...reqBody }, 'post');
export const fetchAllOpinions = async (reqBody: FetchAllRequest) => await api('opinion/getAll', { ...reqBody }, 'post');
export const fetchAllIncompleteOpinions = async (reqBody: FetchAllRequest) => await api('opinion/incomplete/getAll', { ...reqBody }, 'post');
export const getOpinionById = async (reqBody: { opinionId: number }) => await api('opinion/getById', { ...reqBody }, 'post');
export const uploadOpinions = async (reqBody: UploadOpinionRequest) => await api('opinion/upload', reqBody, 'post', {}, true);
export const uploadSupportOpinion = async (reqBody: UploadOpinionRequest) => await api('opinion/support/upload', reqBody, 'post', {}, true);
export const openOpinion = async (reqBody: { location: string; }) => await api('opinion/open', { ...reqBody }, 'post', {}, false, true);
export const openOpinionSummary = async (reqBody: { opinionSummaryId: number; }) => await api('opinion/summary/open', { ...reqBody }, 'post', {}, false, true);
export const fetchLinkedOpinions = async (reqBody: { opinionId: number; }) => await api('opinion/getLinked', { ...reqBody }, 'post');

export const deleteOpinion = async (reqBody: DeleteOpinionRequest) => await api('opinion/delete', { ...reqBody }, 'post');
export const downloadOpinion = async (reqBody: { location: string }) => await api('opinion/download', { ...reqBody }, 'post', {}, false, true);
export const downloadAllOpinions = async (reqBody: { locations: string[] }) => await api('opinion/downloadAll', { ...reqBody }, 'post', {}, false, true);
export const downloadOpinionSummary = async (reqBody: { opinionSummaryId: number; }) => await api('opinion/summary/download', { ...reqBody }, 'post', {}, false, true);

export const fetchOpinionsByJurisdiction = async () => await api('opinion/getAllByJurisdiction', {}, 'get');

export const enquireOpinion = async (reqBody: { opinionId: number; }) => await api('opinion/enquire', { ...reqBody }, 'post', {}, false, true);

// Netting Engine
export const calculateNettingEngineAnalysis = async (reqBody: NettingRequestInformation) => await api('opinion/netting/calculate', { ...reqBody }, 'post');
export const enquireNetting = async (reqBody: { jurisdictions: string[] }) => await api('opinion/netting/enquire', { ...reqBody }, 'post');
export const fetchAllNettingDocuments = async (reqBody: FetchAllNettingEngineDocumentsRequest) => await api('opinion/netting/getAllNettingDocuments', { ...reqBody }, 'post');
export const getNettingDocument = async (reqBody: { documentId: number; }) => await api('opinion/netting/getExistingDocument', { ...reqBody }, 'post');

