import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllDocumentNamesStarted } from '../../admin/documents/store';
import { IconButton } from '../../shared/button/IconButton';
import { PlusButton } from '../../shared/button/PlusButton';
import { Filter, Pencil, Table } from '../../shared/icons';
import { TableTabs } from '../../shared/table/TableTabs';
import { IncompleteOpinionsTable } from './IncompleteOpinionsTable';
import styles from './Opinions.module.scss';
import { OpinionsTable } from './OpinionsTable';
import { OpinionDetailsModal } from './upload/OpinionDetailsModal';
import { PotentialBaseOpinionModal } from './upload/PotentialBaseOpinionModal';
import { UploadOpinionsModal } from './upload/UploadOpinionsModal';
import { getOpinionsTableView, getOpinionsView, getSelectedIncompleteOpinions, getUnauthorisedOpinion, getPotentialBaseOpinion, getUnauthorisedOpinionModalOpen, openIncompleteOpinionStarted, setPotentialBaseOpinion, OpinionsTableView, OpinionsView, setUnauthorisedOpinion, toggleOpinionDetailsModal, toggleOpinionsModal, toggleOpinionsTableView, toggleOpinionsView, getOpinionCustomFiltersModalOpen, toggleOpinionCustomFiltersModal, setSelectedOpinionCustomFilterId, getSelectedOpinionCustomFilterId } from './store';
import { OpinionsMapWrapper } from './map/OpinionsMapWrapper';
import { UnauthorisedOpinionModal } from './UnauthorisedOpinionModal';
import { Globe } from '../../shared/icons/Globe';
import { CustomTooltip } from '../../shared/tooltip';
import { ApplyCustomFiltersModal } from '../../shared/custom-filters/ApplyCustomFiltersModal';
import { FilterTable, getAllOpinionCustomFilters } from '../../auth/login/store';

export const Opinions: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    const testId = 'opinions';
    const dispatch = useAppDispatch();
    const opinionsView = useAppSelector(getOpinionsView);
    const opinionTableView = useAppSelector(getOpinionsTableView);
    const isCompleteOpinionsView = opinionTableView === OpinionsTableView.COMPLETE;
    const potentialBaseOpinion = useAppSelector(getPotentialBaseOpinion);
    const selectedIncompleteOpinions = useAppSelector(getSelectedIncompleteOpinions);
    const unauthorisedOpinionModalOpen = useAppSelector(getUnauthorisedOpinionModalOpen);
    const unauthorisedOpinion = useAppSelector(getUnauthorisedOpinion);
    useFetchStarted([fetchAllDocumentNamesStarted()]);

    const opinionCustomFiltersModalOpen = useAppSelector(getOpinionCustomFiltersModalOpen);
    const customFilters = useAppSelector(getAllOpinionCustomFilters);
    const selectedFilterId = useAppSelector(getSelectedOpinionCustomFilterId);

    const openOpinionCustomFiltersModal = useCallback(() => dispatch(toggleOpinionCustomFiltersModal(true)), [dispatch]);
    const closeOpinionCustomFiltersModal = useCallback(() => dispatch(toggleOpinionCustomFiltersModal(false)), [dispatch]);

    const isTableView = useMemo(() => opinionsView === OpinionsView.TABLE, [opinionsView]);
    const opinionsViewSwitch = useMemo(() => isTableView ? OpinionsView.MAP : OpinionsView.TABLE, [isTableView]);
    const uploadNewOpinions = useCallback(() => dispatch(toggleOpinionsModal(true)), [dispatch]);
    const changeOpinionsView = useCallback((view: OpinionsView) => dispatch(toggleOpinionsView(view)), [dispatch]);
    const changeTableView = useCallback((view: OpinionsTableView) => dispatch(toggleOpinionsTableView(view)), [dispatch]);
    const closePotentialBaseOpinionModal = useCallback(() => dispatch(setPotentialBaseOpinion(null)), [dispatch]);

    const updateDisabled = selectedIncompleteOpinions.length <= 0;
    const switchViewLabel = useMemo(() => isTableView ? 'Map' : 'Table', [isTableView]);
    const switchViewIcon = useMemo(() => isTableView ? Globe : Table, [isTableView]);
    const handleUpdateMultipleOpinions = useCallback(() => {
        dispatch(openIncompleteOpinionStarted(selectedIncompleteOpinions[0]));
        dispatch(toggleOpinionDetailsModal(true));
    }, [dispatch, selectedIncompleteOpinions]);

    const closeUnauthorisedModal = useCallback(() => dispatch(setUnauthorisedOpinion(null)), [dispatch]);

    const setCustomOpinionFilters = useCallback((filterId: string) => {
        if (isCompleteOpinionsView) {
            dispatch(setSelectedOpinionCustomFilterId(filterId));
        }
        closeOpinionCustomFiltersModal();
    }, [dispatch, closeOpinionCustomFiltersModal, isCompleteOpinionsView]);

    const headerComponents = useMemo(() => isCompleteOpinionsView ? (
        <div className={styles.opinionsTitleContainer}>
            <div className={styles.titleAndPlusButton}>
                <div data-testid={`original-${testId}-header`} className={styles.opinionsTitle}>Opinions</div>
                <PlusButton onClick={uploadNewOpinions} fontSize={22} testId='create-opinion' />
                {customFilters.length > 0 && isTableView && <IconButton icon={Filter} onClick={openOpinionCustomFiltersModal} fontSize={35} testId='complete-custom-opinion-filters' />}
            </div>
            <CustomTooltip overlayText={`${switchViewLabel} View`} placement='left'>
                <div className={styles.opinionsView}>
                    <IconButton onClick={() => changeOpinionsView(opinionsViewSwitch)} disabled={false} testId='opinions-toggle-view' icon={switchViewIcon} fontSize={30} withBackground />
                </div>
            </CustomTooltip>
        </div>
    ) : (
        <div className={styles.opinionsTitleContainer}>
            <div className={styles.titleAndPlusButton}>
                <div className={styles.opinionsTitle} data-testid={`incomplete-${testId}-header`}>Incomplete Opinions</div>
                <IconButton icon={Pencil} onClick={handleUpdateMultipleOpinions} disabled={updateDisabled} testId='incomplete-opinions' withBorder fontSize={22} />
            </div>
        </div>
    ), [handleUpdateMultipleOpinions, isCompleteOpinionsView, uploadNewOpinions, updateDisabled, changeOpinionsView, switchViewIcon, opinionsViewSwitch, switchViewLabel, openOpinionCustomFiltersModal, customFilters, isTableView]);

    const opinionsBody = useMemo(() => {
        const tableTabs = [
            { tabToggleAction: () => changeTableView(OpinionsTableView.COMPLETE), tabTitle: 'Complete', isSelected: isCompleteOpinionsView },
            { tabToggleAction: () => changeTableView(OpinionsTableView.INCOMPLETE), tabTitle: 'Incomplete', isSelected: !isCompleteOpinionsView }
        ];
        return isTableView ? (
            <>
                <TableTabs
                    tabs={tableTabs}
                    testId={testId}
                />
                {isCompleteOpinionsView ? <OpinionsTable /> : <IncompleteOpinionsTable />}
            </>
        ) : (
            <OpinionsMapWrapper />
        );
    }, [isTableView, testId, isCompleteOpinionsView, changeTableView]);

    useEffect(() => {
        if (pathname.includes('incomplete') && isCompleteOpinionsView) {
            changeTableView(OpinionsTableView.INCOMPLETE);
        }
        if (pathname.includes('map') && isTableView) {
            changeOpinionsView(OpinionsView.MAP);
        }
        if (pathname.includes('table') && !isTableView) {
            changeOpinionsView(OpinionsView.TABLE);
        }
    }, [isCompleteOpinionsView, pathname, isTableView, changeTableView, changeOpinionsView]);

    return (
        <div className={styles.opinionsWrapper} data-testid='opinions-wrapper'>
            {headerComponents}
            {opinionsBody}
            <OpinionDetailsModal />
            <UploadOpinionsModal />
            {unauthorisedOpinionModalOpen && <UnauthorisedOpinionModal closeModal={closeUnauthorisedModal} opinion={unauthorisedOpinion!} />}
            {!isNull(potentialBaseOpinion) && <PotentialBaseOpinionModal opinion={potentialBaseOpinion} closeModal={closePotentialBaseOpinionModal} />}
            <ApplyCustomFiltersModal
                setCustomFilters={setCustomOpinionFilters}
                modalOpen={opinionCustomFiltersModalOpen}
                closeModal={closeOpinionCustomFiltersModal}
                customFilters={customFilters}
                filterTable={FilterTable.OPINION}
                selectedFilterId={selectedFilterId}
            />
        </div>
    );
};
