import React, { useCallback, useEffect, useMemo } from 'react';
import { isNull } from 'lodash/fp';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Calendar } from '../../../shared/icons';
import { deleteScheduledActionStarted, fetchAllCalendarEventsStarted, getAllScheduledActions, getCalendarEvent, getConfirmDeleteEvent, getIsDeleting, getWorkflowPage, initialEvent, runIndirectRulesStarted, SchedulerDB, setWorkflowPage, stripEvent, toggleDeleteEventConfirmationModal, toggleScheduleWizard, WorkflowPage, getScheduledActionsListView, ScheduledActionsListView, toggleScheduledActionsListView, getIsLoading, SchedulerAction } from '../store';
import { PageToggleButton } from '../../../shared/button/PageToggleButton';
import { PlusButton } from '../../../shared/button/PlusButton';
import { WorkflowListTable } from './WorkflowListTable';
import { WorkflowWizard } from '../WorkflowWizard';
import { fetchAllAttestationFormsStarted, getAllAttestationForms } from '../../attestations/store';
import { getUserRole } from '../../../auth/login/store';
import { systemAdminRole } from '../../../constants/permittedRoles';
import { Button } from '../../../shared/button/Button';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal';
import { WorkflowRouteParams } from '../Workflow';
import styles from '../Workflow.module.scss';
import { TableTabs } from '../../../shared/table/TableTabs';
import { fetchAllUsersStarted } from '../../users/store';

export const WorkflowList: React.FC<RouteComponentProps<WorkflowRouteParams>> = ({ match: { params } }) => {
    const testId = 'admin-worfklow-list';
    const dispatch = useAppDispatch();
    const scheduledActionsListView = useAppSelector(getScheduledActionsListView);
    useFetchStarted([fetchAllAttestationFormsStarted(), fetchAllUsersStarted()]);
    const attestationForms = useAppSelector(getAllAttestationForms);

    const fetchEvents = useCallback(() => dispatch(fetchAllCalendarEventsStarted(scheduledActionsListView)), [dispatch, scheduledActionsListView]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    const userRole = useAppSelector(getUserRole);
    const isSystemAdmin = systemAdminRole.includes(userRole!);
    const scheduledActions = useAppSelector(getAllScheduledActions);
    const event = useAppSelector(getCalendarEvent);
    const deleteConfirmationModal = useAppSelector(getConfirmDeleteEvent);
    const isDeleting = useAppSelector(getIsDeleting);
    const page = useAppSelector(getWorkflowPage);
    const isActiveActionsView = scheduledActionsListView === ScheduledActionsListView.ACTIVE;
    const isArchivedActionsView = scheduledActionsListView === ScheduledActionsListView.ARCHIVED;
    const isLoading = useAppSelector(getIsLoading);

    const openWizard = useCallback((date: string | null = null) => dispatch(toggleScheduleWizard(true, initialEvent(date))), [dispatch]);
    const openScheduledAction = useCallback((event: SchedulerDB) => dispatch(toggleScheduleWizard(true, stripEvent(event))), [dispatch]);

    const openCalendarView = useCallback(() => dispatch(setWorkflowPage(WorkflowPage.CALENDAR)), [dispatch]);
    const openListView = useCallback(() => dispatch(setWorkflowPage(WorkflowPage.LIST)), [dispatch]);

    const deleteScheduledAction = useCallback(() => dispatch(deleteScheduledActionStarted()), [dispatch]);
    const closeDeleteConfirmationModal = useCallback(() => dispatch(toggleDeleteEventConfirmationModal(null)), [dispatch]);

    const runIndirectRules = () => dispatch(runIndirectRulesStarted());

    const resetPageView = useCallback(() => dispatch(setWorkflowPage(WorkflowPage.SELECT)), [dispatch]);
    const resetListTab = useCallback(() => dispatch(toggleScheduledActionsListView(ScheduledActionsListView.ACTIVE)), [dispatch]);

    const getAttestationFormName = useCallback((id: number) => attestationForms.find(({ attestationFormId }) => attestationFormId === id)?.name || '', [attestationForms]);

    const nameFormatter = useCallback((event: SchedulerDB) => {
        if (event.action === SchedulerAction.CREATE_ATTESTATION) {
            return getAttestationFormName(event.content.attestationFormId!);
        }
        return event.content.subject;
    }, [getAttestationFormName]);

    useEffect(() => () => {
        resetPageView();
        resetListTab();
    }, [resetPageView, resetListTab]);

    const scheduledActionInParamsExists = useMemo(() => (params.scheduledActionId && scheduledActions.map(({ scheduledActionId }) => scheduledActionId!.toString()).includes(params.scheduledActionId)) || false, [scheduledActions, params]);
    useEffect(() => {
        if (!event && params.scheduledActionId && scheduledActionInParamsExists) {
            const scheduledAction = scheduledActions.find(({ scheduledActionId }) => scheduledActionId!.toString() === params.scheduledActionId)!;
            openScheduledAction(scheduledAction);
        }
    }, [params, event, scheduledActionInParamsExists, scheduledActions, openScheduledAction]);

    useEffect(() => {
        if (page !== WorkflowPage.LIST) {
            openListView();
        }
    }, [page, openListView]);

    const changeView = useCallback((view: ScheduledActionsListView) => {
        dispatch(toggleScheduledActionsListView(view));
    }, [dispatch]);

    const isActiveTab = useCallback((view: ScheduledActionsListView) => scheduledActionsListView === view, [scheduledActionsListView]);

    const tableTabs = [
        { tabToggleAction: () => changeView(ScheduledActionsListView.ACTIVE), tabTitle: 'Active', isSelected: isActiveTab(ScheduledActionsListView.ACTIVE) },
        { tabToggleAction: () => changeView(ScheduledActionsListView.ARCHIVED), tabTitle: 'Archived', isSelected: isActiveTab(ScheduledActionsListView.ARCHIVED) },
        { tabToggleAction: () => changeView(ScheduledActionsListView.COMPLETED), tabTitle: 'Completed', isSelected: isActiveTab(ScheduledActionsListView.COMPLETED) }
    ];

    const scheduledActionsForViewType = useMemo(() => {
        switch (scheduledActionsListView) {
            case ScheduledActionsListView.ACTIVE:
                return scheduledActions.filter(({ hasCompleted }) => !hasCompleted);
            case ScheduledActionsListView.ARCHIVED:
                return scheduledActions;
            case ScheduledActionsListView.COMPLETED:
                return scheduledActions.filter(({ hasCompleted }) => hasCompleted);
            default:
                return scheduledActions;
        }
    }, [scheduledActionsListView, scheduledActions]);

    const deleteEventName = useMemo(() => !isNull(deleteConfirmationModal) ? nameFormatter(scheduledActionsForViewType.find(({ scheduledActionId }) => scheduledActionId === deleteConfirmationModal)!) : 'scheduled action', [deleteConfirmationModal, nameFormatter, scheduledActionsForViewType]);

    return (
        <div className={styles.workflowListWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.workflowHeader}>
                <div className={styles.workflowLeftHeader}>
                    <div className={styles.workflowTitle} data-testid={`${testId}-title`}>Workflow</div>
                    <PlusButton onClick={() => openWizard()} testId={testId} fontSize={18} />
                </div>
                <PageToggleButton label='View Calendar' onClick={openCalendarView} icon={Calendar} testId='workflow-calendar' />
            </div>
            <TableTabs
                tabs={tableTabs}
                testId={testId}
                margin='0px'
            />
            <WorkflowListTable
                isLoading={isLoading}
                scheduledActions={scheduledActionsForViewType}
                attestationForms={attestationForms}
                isActiveActionsView={isActiveActionsView}
                isArchivedActionsView={isArchivedActionsView}
                testId={testId}
            />
            {isSystemAdmin &&
                <div className={styles.runIndirectRulesButton}>
                    <Button onClick={runIndirectRules} label='Run Indirect Rules' testId='workflow-run-indirect-rules-button' />
                </div>
            }
            {event && <WorkflowWizard event={event} />}
            {!isNull(deleteConfirmationModal) &&
                <DeleteConfirmationModal
                    closeModal={closeDeleteConfirmationModal}
                    isDeleting={isDeleting}
                    deleteScheduledAction={deleteScheduledAction}
                    isOpen={!isNull(deleteConfirmationModal)}
                    name={deleteEventName}
                />
            }
        </div>
    );
};
