import { isUndefined } from 'lodash/fp';
import React, { useMemo } from 'react';

import { CurrencyAmountRiskField, RiskAssociated, RiskAssociatedValue } from '../../../admin/risk-tolerance/store';
import { NumberRiskFieldConfig } from './NumberRiskFieldConfig';

interface CurrencyAmountRiskFieldConfigProps {
    riskField: CurrencyAmountRiskField;
    riskAssociated: RiskAssociated;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValue, selectedCurrency?: string) => void;
    selectedCurrency: string | undefined;
}

export const CurrencyAmountRiskFieldConfig: React.FC<CurrencyAmountRiskFieldConfigProps> = ({ riskField, riskAssociated, updateRiskConfig, selectedCurrency }) => {

    const { riskConfig, includeRiskField } = riskField;

    const currencyRiskConfig = useMemo(() => !isUndefined(selectedCurrency) && !isUndefined(riskConfig[selectedCurrency]) ? riskConfig[selectedCurrency] : null, [selectedCurrency, riskConfig]);

    return <NumberRiskFieldConfig riskConfig={currencyRiskConfig} includeRiskField={includeRiskField} riskAssociated={riskAssociated} updateRiskConfig={updateRiskConfig} selectedCurrency={selectedCurrency} />;
};
