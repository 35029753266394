import React, { useEffect, useMemo, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Builder } from './builder/Builder';
import { MyPlaybooks } from './my-playbooks/MyPlaybooks';
import styles from './Playbook.module.scss';
import { PlaybookSelect } from './PlaybookSelect';
import { PlaybookPage, getPlaybookPage, setPlaybookPage, getAllPlaybookStarted } from './store';

export interface PlaybookRouteParams {
    playbookId: string | undefined;
}

export const Playbook: React.FC<RouteComponentProps<PlaybookRouteParams>> = props => {
    const playbookPage = useAppSelector(getPlaybookPage);
    const dispatch = useAppDispatch();
    const resetPageView = useCallback(() => dispatch(setPlaybookPage(PlaybookPage.SELECT)), [dispatch]);
    useFetchStarted([getAllPlaybookStarted()]);

    useEffect(() => () => {
        resetPageView();
    }, [resetPageView]);

    const pageContent = useMemo(() => {
        switch (playbookPage) {
            case PlaybookPage.SELECT:
            default:
                return <PlaybookSelect />;
            case PlaybookPage.BUILD:
                return <Builder {...props} />;
            case PlaybookPage.MY_PLAYBOOKS:
                return <MyPlaybooks />;
        }
    }, [playbookPage, props]);

    return (
        <div className={styles.playbookWrapper} data-testid='playbook-wrapper'>
            {pageContent}
        </div>
    );
};
