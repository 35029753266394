import { isNull } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted } from '../../../admin/dropdown-lists/store';
import { Spinner } from '../../../shared/spinner/Spinner';
import styles from './Theoretical.module.scss';
import { getCurrentNettingEngineTheory, getNettingEngineResults, getNettingEngineTheorySurvey, getSurveyQuestionIndex, getIsCalculatingResults, resetTheoreticalState } from '../store';
import { TheoreticalSurvey } from './TheoreticalSurvey';
import { fetchAllNettingEntitiesStarted } from '../../../admin/entity/store';
import { NettingEngineResults } from '../NettingEngineResults';

export const NettingEngineTheoretical: React.FC = () => {
    const dispatch = useAppDispatch();

    useFetchStarted([fetchAllDropdownListsStarted(), fetchAllNettingEntitiesStarted()]);

    const currentNettingEngineTheory = useAppSelector(getCurrentNettingEngineTheory);
    const theoreticalResults = useAppSelector(getNettingEngineResults);
    const calculatingResults = useAppSelector(getIsCalculatingResults);
    const surveyQuestionIndex = useAppSelector(getSurveyQuestionIndex);
    const surveyAnswers = useAppSelector(getNettingEngineTheorySurvey);

    const END_OF_SURVEY_INDEX = 10;

    useEffect(() => () => {
        dispatch(resetTheoreticalState());
    }, [dispatch]);

    const component = useMemo(() => {
        if (calculatingResults && isNull(currentNettingEngineTheory)) {
            return <Spinner />;
        }
        if (!isNull(theoreticalResults)) {
            return <NettingEngineResults results={theoreticalResults} />;
        }
        if (isNull(theoreticalResults) && surveyQuestionIndex !== END_OF_SURVEY_INDEX) {
            return <TheoreticalSurvey
                surveyQuestionIndex={surveyQuestionIndex}
                surveyAnswers={surveyAnswers}
            />;
        }
    }, [calculatingResults, currentNettingEngineTheory, theoreticalResults, surveyQuestionIndex, surveyAnswers]);

    return (
        <div className={styles.theoreticalWrapper}>
            {component}
        </div>
    );
};
