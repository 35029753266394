import React, { useMemo, useCallback } from 'react';
import { isNull } from 'lodash/fp';

import styles from '../../Analytics.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { getFetchingRiskToleranceDocuments, getDocumentFieldRiskTolerance, getTotalRiskFieldDocuments, DocumentFieldRiskToleranceAnalytics, getRiskFieldDocumentMeta, setRiskToleranceView, DocumentRiskView, getRiskToleranceDatasetFieldFilters, getRiskToleranceDatasetFieldColumnSort, getRiskToleranceDatasetFieldPageNumber, getRiskToleranceDatasetFieldPageSize, datasetFieldRiskTolerancePaginationNext, datasetFieldRiskTolerancePaginationPrevious, setDatasetFieldRiskToleranceTableFilters, clearDatasetFieldRiskToleranceTableFilters, setDatasetFieldRiskTolerancePageSize, setDatasetFieldRiskToleranceTableColumnSort, getSelectedRiskToleranceParentDatasetId, getSelectedRiskFieldDocument, redirectToDocumentInstance, setEntityDocumentRiskToleranceView, EntityDocumentRiskView, PreExecutionDocumentRiskView, setPreExecutionDocumentRiskToleranceView } from '../../store';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../../shared/table/ArkTable';
import { TableFilterType } from '../../../../shared/modal/TableFilterModal';
import { documentRiskLevelLabel } from './DocumentRiskToleranceLevel';
import { DatasetFieldType, DatasetType } from '../../../../datasets/store';
import { getFieldValue } from './DocumentRiskToleranceBreakdown';
import { riskLevelOptions } from './DocumentRiskToleranceTable';
import { Icon } from '../../../../shared/icon/Icon';
import { fieldListIcons } from '../../../../admin/dataset-builder/FieldList';
import { Button } from '../../../../shared/button/Button';
import { FieldValue } from '../../../../datasets/instances/store';
import { getAllClientDocumentGroups, getAvailableDocumentNames } from '../../../../admin/documents/store';

const MARGIN_TOP_AND_BOTTOM = 40;
const TILE_HEADER = 40;
const FIELD_HEADER = 40;
const BACK_BUTTON = 40;
const HEADER_AND_MARGIN = MARGIN_TOP_AND_BOTTOM + TILE_HEADER + FIELD_HEADER + BACK_BUTTON;

const riskRatingFormatter = (riskRating: number) => {
    const riskPercentage = Math.ceil(riskRating);
    const { colour, label } = documentRiskLevelLabel(riskPercentage, true);
    return [colour, label];
};

interface DocumentFieldRiskToleranceTableProps {
    height: number;
    width: number;
    testId?: string;
    entityId?: number;
    isPreExecution?: boolean;
}

export const DocumentFieldRiskToleranceTable: React.FC<DocumentFieldRiskToleranceTableProps> = ({ height, width, testId, entityId, isPreExecution = false }) => {
    const dispatch = useAppDispatch();

    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const allClientDocumentGroups = useAppSelector(getAllClientDocumentGroups);
    const riskFieldDocuments = useAppSelector(getDocumentFieldRiskTolerance);
    const totalRiskFieldDocuments = useAppSelector(getTotalRiskFieldDocuments);
    const isFetchingRiskFieldDocuments = useAppSelector(getFetchingRiskToleranceDocuments);
    const fieldDatasetType = useMemo(() => riskFieldDocuments.length ? riskFieldDocuments[0].riskTolerance[0].fieldType : DatasetFieldType.NUMBER, [riskFieldDocuments]);
    const isTableDataset = useMemo(() => riskFieldDocuments.length ? riskFieldDocuments[0].datasetType === DatasetType.TABLE : false, [riskFieldDocuments]);
    const fieldLabel = useMemo(() => riskFieldDocuments.length ? riskFieldDocuments[0].riskTolerance[0].fieldLabel : '', [riskFieldDocuments]);
    const selectedDatasetId = useAppSelector(getSelectedRiskToleranceParentDatasetId);
    const selectedRiskFieldDocument = useAppSelector(getSelectedRiskFieldDocument);
    const selectedTableRow = useMemo(() => !isNull(selectedRiskFieldDocument) ? { field: 'documentRiskToleranceId', fieldValue: selectedRiskFieldDocument.documentRiskToleranceId } : undefined, [selectedRiskFieldDocument]);

    const redirectToDocument = useCallback((row: DocumentFieldRiskToleranceAnalytics) => dispatch(redirectToDocumentInstance(row.originalDocumentId, row.datasetInstanceId)), [dispatch]);

    const documentFieldRiskToleranceColumnDefs: ArkTableColumn[] = useMemo(() => {
        const fieldValueFormatter = (fieldValue: FieldValue) => getFieldValue(fieldValue, fieldDatasetType);
        if (!isTableDataset) {
            return [
                { id: 'documentName', header: 'Document Name', field: 'documentName', width: 0.2, component: 'tooltip', canFilter: true, canSort: true },
                { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', width: 0.25, component: 'tooltip', canFilter: true, canSort: true },
                { id: 'fieldValue', header: 'Field Value', parentField: 'riskTolerance', field: 'fieldValue', width: 0.2, component: 'table', secondaryComponent: 'tooltip', canFilter: false, canSort: false, valueFormatter: fieldValueFormatter },
                { id: 'riskRating', header: 'Risk Rating', parentField: 'riskTolerance', field: 'fieldPercentage', width: 0.2, component: 'table', secondaryComponent: 'colour', canFilter: true, canSort: true, valueFormatter: riskRatingFormatter },
                { id: 'documentLink', header: 'Document Link', field: 'documentDescription', width: 0.15, component: 'redirect', redirect: redirectToDocument }
            ];
        } else {
            return [
                { id: 'documentName', header: 'Document Name', field: 'documentName', width: 0.2, component: 'tooltip', canFilter: true, canSort: true },
                { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', width: 0.2, component: 'tooltip', canFilter: true, canSort: true },
                { id: 'rowLabel', header: 'Row Label', parentField: 'riskTolerance', field: 'rowLabel', width: 0.15, component: 'table', secondaryComponent: 'tooltip', canFilter: false, canSort: false },
                { id: 'fieldValue', header: 'Field Value', parentField: 'riskTolerance', field: 'fieldValue', width: 0.15, component: 'table', secondaryComponent: 'tooltip', canFilter: false, canSort: false, valueFormatter: fieldValueFormatter },
                { id: 'riskRating', header: 'Risk Rating', parentField: 'riskTolerance', field: 'fieldPercentage', width: 0.15, component: 'table', secondaryComponent: 'colour', canFilter: true, canSort: true, valueFormatter: riskRatingFormatter },
                { id: 'documentLink', header: 'Document Link', field: 'documentDescription', width: 0.15, component: 'redirect', redirect: redirectToDocument }
            ];
        }
    }, [isTableDataset, fieldDatasetType, redirectToDocument]);

    const riskToleranceDocumentFilters = useAppSelector(getRiskToleranceDatasetFieldFilters);
    const columnSort = useAppSelector(getRiskToleranceDatasetFieldColumnSort);
    const pageNumber = useAppSelector(getRiskToleranceDatasetFieldPageNumber);
    const pageSize = useAppSelector(getRiskToleranceDatasetFieldPageSize);
    const nextPage = useCallback(() => dispatch(datasetFieldRiskTolerancePaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(datasetFieldRiskTolerancePaginationPrevious()), [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setDatasetFieldRiskToleranceTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearDatasetFieldRiskToleranceTableFilters()), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setDatasetFieldRiskTolerancePageSize(pageSize)), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort | undefined) => dispatch(setDatasetFieldRiskToleranceTableColumnSort(columnSort)), [dispatch]);

    const openDocument = useCallback((data: DocumentFieldRiskToleranceAnalytics) => dispatch(getRiskFieldDocumentMeta(data.originalDocumentId)), [dispatch]);
    const showDocumentBreakdown = useCallback((data: DocumentFieldRiskToleranceAnalytics) => dispatch(getRiskFieldDocumentMeta(data.originalDocumentId)), [dispatch]);

    const backToFields = useCallback(() => {
        if (entityId) {
            dispatch(setEntityDocumentRiskToleranceView(EntityDocumentRiskView.ENTITY_DATASETS));
        } else if (isPreExecution) {
            dispatch(setPreExecutionDocumentRiskToleranceView(PreExecutionDocumentRiskView.PRE_EXECUTION_DATASETS));
        } else {
            dispatch(setRiskToleranceView(DocumentRiskView.DATASETS));
        }
    }, [dispatch, entityId, isPreExecution]);

    const tableWidth = useMemo(() => width / 2, [width]);
    const tableHeight = useMemo(() => height - HEADER_AND_MARGIN, [height]);
    const documentNameOptions = useMemo(() => allDocumentNames
        .filter(({ datasetId }) => datasetId === selectedDatasetId)
        .map(({ documentName }) => ({ value: documentName, label: documentName })), [allDocumentNames, selectedDatasetId]
    );

    const clientDocumentGroupOptions = useMemo(() => allClientDocumentGroups.map(({ groupName, clientDocumentGroupId }, i) => ({ value: clientDocumentGroupId!.toString(), label: groupName, showDivider: i === allClientDocumentGroups.length - 1 })), [allClientDocumentGroups]);
    const documentsAndDocumentGroupOptions = useMemo(() => [...clientDocumentGroupOptions, ...documentNameOptions], [documentNameOptions, clientDocumentGroupOptions]);

    const filterDropdownOptions = useMemo(() => ({
        documentName: documentsAndDocumentGroupOptions,
        fieldPercentage: riskLevelOptions
    }), [documentsAndDocumentGroupOptions]);

    return (
        <div className={styles.tableWrapper} data-testid={`${testId}-table-wrapper`}>
            <div className={styles.riskFieldLabelWrapper}>
                <div className={styles.riskFieldLabel}>{fieldLabel}</div>
                <Icon icon={fieldListIcons[fieldDatasetType]} />
            </div>
            <ArkTable
                colDefs={documentFieldRiskToleranceColumnDefs}
                rowData={riskFieldDocuments}
                testId='risk-tolerance-documents'
                isLoading={isFetchingRiskFieldDocuments}
                page={pageNumber}
                total={totalRiskFieldDocuments}
                next={nextPage}
                previous={previousPage}
                filters={riskToleranceDocumentFilters}
                updateFilter={updateFilter}
                clearAllFilters={clearAllFilters}
                onRowClick={row => openDocument(row as DocumentFieldRiskToleranceAnalytics)}
                onRowDoubleClicked={row => showDocumentBreakdown(row as DocumentFieldRiskToleranceAnalytics)}
                columnSort={columnSort}
                toggleSort={toggleColumnSort}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                width={tableWidth}
                filterDropdownOptions={filterDropdownOptions}
                height={tableHeight}
                selectedRow={selectedTableRow}
                removeTextFilter={['fieldPercentage']}
            />
            <div><Button onClick={backToFields} label='Back' /></div>
        </div>
    );
};
