import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { ANALYSIS_MARGIN, DATASET_INSTANCE_HEADER, NAV_BAR, PAGE_MARGIN_PADDING_BORDER, useSplitView } from '../../../../hooks/useSplitView';
import { addInstanceAnnexField, createNewAnnexDefinition, getAllAnnexDefinitions, getCurrentInstanceAnnexDefinitions, getInstanceAnnexDefinitionsUpdated, getInstanceAnnexView, getIsPublishing, InstanceAnnexView, publishAnnexDefinitionStarted, removeInstanceAnnexField, setInstanceAnnexView, setSelectedInstanceIndex, upsertInstanceAnnexDefinitionsStarted } from '../../../agency-annex/store';
import { getDocumentAnalysisView } from '../../../documents/my-documents/store';
import { Button } from '../../../shared/button/Button';
import { Settings } from '../../../shared/icons';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import styles from '../DatasetInstance.module.scss';
import { DatasetModal } from '../modal-instance/DatasetModal';
import { getAnnexConfigurationModalOpen, toggleAnnexConfigurationModal } from '../store';
import { Builder } from './Builder';
import { Configure } from './Configure';
import { Select } from './Select';

const MODAL_HEADER_HEIGHT = 78;
const MODAL_PADDING = 20;
const TITLE_MARGIN = 40;
const BUTTON_WRAPPER = 48;
const heightOffset = NAV_BAR + MODAL_HEADER_HEIGHT + MODAL_PADDING + TITLE_MARGIN + BUTTON_WRAPPER;
const widthOffset = PAGE_MARGIN_PADDING_BORDER;

interface AnnexConfigurationModalProps {
    documentName: string;
}

export const AnnexConfigurationModal: React.FC<AnnexConfigurationModalProps> = ({ documentName }) => {
    const dispatch = useAppDispatch();
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const isOpen = useAppSelector(getAnnexConfigurationModalOpen);
    const instanceAnnexDefinitions = useAppSelector(getCurrentInstanceAnnexDefinitions);
    const view = useAppSelector(getInstanceAnnexView);
    const annexDefinitions = useAppSelector(getAllAnnexDefinitions);
    const isPublishing = useAppSelector(getIsPublishing);
    const hasUpdated = useAppSelector(getInstanceAnnexDefinitionsUpdated);

    const closeModal = () => dispatch(toggleAnnexConfigurationModal(false));
    const addNewAnnex = () => dispatch(addInstanceAnnexField());

    const documentNameId = useMemo(() => annexDefinitions.length > 0 ? annexDefinitions[0].documentNameId : null, [annexDefinitions]);

    const createNewDefintion = () => {
        if (!isNull(documentNameId)) {
            dispatch(createNewAnnexDefinition(documentNameId));
            dispatch(setInstanceAnnexView(InstanceAnnexView.BUILDER));
        }
    };

    const goBackToConfigure = () => {
        dispatch(setInstanceAnnexView(InstanceAnnexView.CONFIGURE));
        dispatch(setSelectedInstanceIndex(0));
        instanceAnnexDefinitions.forEach(({ annexDefinitionId }, index) => {
            if (isNull(annexDefinitionId)) {
                dispatch(removeInstanceAnnexField(index));
            }
        });
    };

    const publishAnnexDefinition = () => dispatch(publishAnnexDefinitionStarted(true));

    const updateInstanceAnnexDefinitions = () => dispatch(upsertInstanceAnnexDefinitionsStarted());

    const [width, height] = useSplitView(documentAnalysisView, widthOffset, heightOffset);

    const content = useMemo(() => {
        switch (view) {
            case InstanceAnnexView.SELECT:
                return <Select documentName={documentName} />;
            case InstanceAnnexView.BUILDER:
                return <Builder height={height} />;
            case InstanceAnnexView.CONFIGURE:
                return <Configure />;
        }
    }, [view, documentName, height]);

    const isConfigureView = useMemo(() => view === InstanceAnnexView.CONFIGURE, [view]);
    const isSelectView = useMemo(() => view === InstanceAnnexView.SELECT, [view]);
    const isBuilderView = useMemo(() => view === InstanceAnnexView.BUILDER, [view]);

    if (!isOpen || instanceAnnexDefinitions.length === 0) {
        return null;
    }

    return (
        <DatasetModal
            isOpen={isOpen}
            closeModal={closeModal}
            topOffset={`${DATASET_INSTANCE_HEADER + NAV_BAR + ANALYSIS_MARGIN}px`}
        >
            <div className={styles.annexConfigurationWrapper} data-testid='annex-config-wrapper' style={{ width }}>
                <ModalHeader label='Configure Annex Definition' icon={Settings} />
                <div className={styles.annexConfiguration}>
                    <div className={styles.viewWrapper} style={{ height: `${height}px` }}>
                        {content}
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div className={styles.leftButtonWrapper}>
                            <Button onClick={closeModal} label='Close' />
                            {!isConfigureView && <Button onClick={goBackToConfigure} label='Back' />}
                        </div>
                        <div className={styles.rightButtonWrapper}>
                            {isConfigureView && <Button onClick={addNewAnnex} label='Add' />}
                            {isSelectView && <Button onClick={createNewDefintion} label='Create New' />}
                            {isBuilderView && <Button onClick={publishAnnexDefinition} label='Publish' disabled={isPublishing} />}
                            {isConfigureView && <Button onClick={updateInstanceAnnexDefinitions} label='Save' disabled={!hasUpdated} />}
                        </div>
                    </div>
                </div>

            </div>
        </DatasetModal>
    );
};
