import { isEqual, isNull, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { all, call, debounce, fork, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

import { fetchAgencyFieldsByAgencyDatasetId, fetchAllAnnexDefinitions, publishAnnexDefinition, updateInstanceAnnexDefinitions } from '../../../services/annexDefinition';
import { DatasetInstanceActionTypes, getInstanceDocumentId, getOriginalDocumentId, openDatasetInstanceById, setIsUpdatingInstanceAnnexDefinitions, toggleAnnexConfigurationModal } from '../../datasets/instances/store';
import { AgencyDatasetField } from '../../datasets/store';
import { incompleteDocumentUpdateValue } from '../../documents/my-documents/store';
import { fetchAgencyFieldsFailed, fetchAgencyFieldsStarted, fetchAgencyFieldsSuccessful, fetchAllAnnexDefinitionsFailed, fetchAllAnnexDefinitionsStarted, fetchAllAnnexDefinitionsSuccessful, publishAnnexDefinitionFailed, publishAnnexDefinitionStarted, publishAnnexDefinitionSuccessful, setInstanceAnnexDefinitions, setInstanceAnnexDefinitionsHaveUpdated, setInstanceAnnexView, setSelectedInstanceIndex, toggleAnnexBuilderModal, updateInstanceAnnexField, upsertInstanceAnnexDefinitionsFailed, upsertInstanceAnnexDefinitionsSuccessful } from './actions';
import { getAllAnnexDefinitions, getAnnexDefinition, getCurrentInstanceAnnexDefinitions, getSavedInstanceAnnexDefinitions, getSelectedInstanceIndex } from './selectors';
import { AnnexDefinition, AnnexDefinitionActionTypes, AnnexDefinitionDB, InstanceAnnexDefinition, InstanceAnnexView } from './types';

const stripDefinition = (annexDefinition: AnnexDefinition): AnnexDefinition => unset('annexDefinitionId', annexDefinition);

export function* attemptPublishAnnexDefinition({ payload }: ReturnType<typeof publishAnnexDefinitionStarted>) {
    try {
        const { isInstanceUpdate } = payload;
        const annexDefinition: AnnexDefinition = yield select(getAnnexDefinition);
        const strippedDefinition = stripDefinition(annexDefinition);
        const definitionId: number = yield call(publishAnnexDefinition, { annexDefinition: strippedDefinition });
        yield put(fetchAllAnnexDefinitionsStarted(annexDefinition.documentNameId.toString()));
        if (!isInstanceUpdate) {
            yield put(incompleteDocumentUpdateValue('annexDefinitionId', definitionId));
        } else {
            const index: number = yield select(getSelectedInstanceIndex);
            yield put(updateInstanceAnnexField('annexDefinitionId', definitionId, index));
            yield put(updateInstanceAnnexField('startPage', null, index));
            yield put(updateInstanceAnnexField('endPage', null, index));
            yield put(updateInstanceAnnexField('extractedData', null, index));
            yield put(setInstanceAnnexView(InstanceAnnexView.CONFIGURE));
        }
        yield put(publishAnnexDefinitionSuccessful());
        toast('Successfully created new agency annex');
    } catch (e) {
        yield put(publishAnnexDefinitionFailed((e as Error).message));
        toast.error('Something went wrong. Please try again.');
    }
}

function* publishAnnexDefinitionWatcher() {
    yield takeLeading(AnnexDefinitionActionTypes.PUBLISH_ANNEX_DEFINITION_STARTED, attemptPublishAnnexDefinition);
}

export function* attemptFetchAllAnnexDefinitions({ payload }: ReturnType<typeof fetchAllAnnexDefinitionsStarted>) {
    try {
        const annexDefinitions: AnnexDefinition[] = yield call(fetchAllAnnexDefinitions, { documentNameId: parseInt(payload) });
        yield put(fetchAllAnnexDefinitionsSuccessful(annexDefinitions));
    } catch (e) {
        yield put(fetchAllAnnexDefinitionsFailed((e as Error).message));
        toast.error('Failed to fetch annex definitions');
    }
}

function* fetchAnnexDefinitionWatcher() {
    yield takeLatest(AnnexDefinitionActionTypes.FETCH_ALL_ANNEX_DEFINITIONS_STARTED, attemptFetchAllAnnexDefinitions);
}

export function* attemptFetchAgencyFields({ payload }: ReturnType<typeof fetchAgencyFieldsStarted>) {
    try {
        const agencyFields: AgencyDatasetField[] = yield call(fetchAgencyFieldsByAgencyDatasetId, { agencyDatasetId: payload });
        yield put(fetchAgencyFieldsSuccessful(agencyFields));
    } catch (e) {
        yield put(fetchAgencyFieldsFailed((e as Error).message));
    }
}

function* fetchAgencyFieldsWatcher() {
    yield takeLatest(AnnexDefinitionActionTypes.FETCH_AGENCY_FIELDS_STARTED, attemptFetchAgencyFields);
}

export function* checkInstanceAnnexDefinitionsUpdated() {
    const currentInstanceAnnexDefinitions: InstanceAnnexDefinition[] = yield select(getCurrentInstanceAnnexDefinitions);
    const savedInstanceAnnexDefinitions: InstanceAnnexDefinition[] = yield select(getSavedInstanceAnnexDefinitions);
    const hasUpdated = !isEqual(currentInstanceAnnexDefinitions, savedInstanceAnnexDefinitions);
    yield put(setInstanceAnnexDefinitionsHaveUpdated(hasUpdated));
}

function* checkInstanceAnnexDefinitionsUpdatedWatcher() {
    yield debounce(500, [AnnexDefinitionActionTypes.UPDATE_INSTANCE_ANNEX_FIELD, AnnexDefinitionActionTypes.REMOVE_INSTANCE_ANNEX_DEFINITION], checkInstanceAnnexDefinitionsUpdated);
}

export function* closeInstanceAnnexDefinitionModal({ payload }: ReturnType<typeof toggleAnnexConfigurationModal>) {
    if (!payload) {
        const savedInstanceAnnexDefinitions: InstanceAnnexDefinition[] = yield select(getSavedInstanceAnnexDefinitions);
        yield put(setInstanceAnnexDefinitions(savedInstanceAnnexDefinitions));
        yield put(setInstanceAnnexView(InstanceAnnexView.CONFIGURE));
        yield put(setSelectedInstanceIndex(0));
    }
}

function* closeInstanceAnnexDefinitionModalWatcher() {
    yield takeLatest(DatasetInstanceActionTypes.TOGGLE_ANNEX_DEFINITION_MODAL_OPEN, closeInstanceAnnexDefinitionModal);
}

export function* attemptUpdateInstanceAnnexDefinitions() {
    try {
        const currentInstanceAnnexDefinitions: InstanceAnnexDefinition[] = yield select(getCurrentInstanceAnnexDefinitions);
        const annexDefinitions: AnnexDefinitionDB[] = yield select(getAllAnnexDefinitions);
        const documentNameId = annexDefinitions.length > 0 ? annexDefinitions[0].documentNameId : null;
        const documentId: number | null = yield select(getInstanceDocumentId);
        const originalDocumentId: number | null = yield select(getOriginalDocumentId);

        if (!isNull(documentId) && !isNull(documentNameId) && !isNull(originalDocumentId)) {
            const { instanceId }: { instanceId: string | null; } = yield call(updateInstanceAnnexDefinitions, { instanceAnnexDefinitions: currentInstanceAnnexDefinitions, documentId, documentNameId });
            yield put(setIsUpdatingInstanceAnnexDefinitions(true));
            if (!isNull(instanceId)) {
                yield put(toggleAnnexBuilderModal(false));
                yield put(toggleAnnexConfigurationModal(false));
                yield put(openDatasetInstanceById(instanceId, false, originalDocumentId, true));
            }
        }
        yield put(upsertInstanceAnnexDefinitionsSuccessful());
    } catch (e) {
        toast.error('Something went wrong. Please try again.');
        yield put(upsertInstanceAnnexDefinitionsFailed((e as Error).message));
    }
}

function* updateInstanceAnnexDefinitionsWatcher() {
    yield takeLeading(AnnexDefinitionActionTypes.UPSERT_ANNEX_INSTANCES_STARTED, attemptUpdateInstanceAnnexDefinitions);
}

export function* annexDefinitionSaga() {
    yield all([
        fork(publishAnnexDefinitionWatcher),
        fork(fetchAnnexDefinitionWatcher),
        fork(fetchAgencyFieldsWatcher),
        fork(checkInstanceAnnexDefinitionsUpdatedWatcher),
        fork(closeInstanceAnnexDefinitionModalWatcher),
        fork(updateInstanceAnnexDefinitionsWatcher)
    ]);
}
