import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/logos/Ark51_VERTWHITE&WHITE.png';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getLoginError, getReminderError, getReminderType, getSendingReminder, ReminderTypes } from './store';
import { forgottenPasswordReminderStarted, forgottenUsernameReminderStarted, loginStarted, setReminderType } from './store/actions';
import styles from './Login.module.scss';
import { useWindowResize } from '../../../hooks/useWindowResize';

export const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const loginError = useAppSelector(getLoginError);
    const reminderType = useAppSelector(getReminderType);
    const reminderError = useAppSelector(getReminderError);
    const isSendingReminder = useAppSelector(getSendingReminder);

    const [, screenHeight] = useWindowResize();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    const resetInputValues = useCallback(() => {
        setEmail('');
        setPassword('');
        setUsername('');
    }, [setEmail, setPassword, setUsername]);

    const attemptLogin = useCallback(() => dispatch(loginStarted(username, password)), [dispatch, username, password]);
    const handleUsernameReminder = useCallback(() => {
        dispatch(forgottenUsernameReminderStarted(email));
        resetInputValues();
    }, [dispatch, resetInputValues, email]);

    const handlePasswordReminder = useCallback(() => {
        dispatch(forgottenPasswordReminderStarted(username));
        resetInputValues();
    }, [dispatch, resetInputValues, username]);

    const attemptKeyPressLogin = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => e.key.toLowerCase() === 'enter' && dispatch(loginStarted(username, password)), [dispatch, username, password]);
    const attemptKeyPressUsernameReminder = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => e.key.toLowerCase() === 'enter' && handleUsernameReminder(), [handleUsernameReminder]);

    const handleForgotUsername = useCallback(() => {
        resetInputValues();
        dispatch(setReminderType(ReminderTypes.USERNAME));
    }, [dispatch, resetInputValues]);

    const handleCancelReminder = useCallback(() => {
        resetInputValues();
        dispatch(setReminderType(null));
    }, [dispatch, resetInputValues]);

    const handleForgotPassword = useCallback(() => {
        resetInputValues();
        dispatch(setReminderType(ReminderTypes.PASSWORD));
    }, [dispatch, resetInputValues]);

    const showLoginErrorMessage = useMemo(() => loginError && ['Your account has been locked, you have received an email with instructions on how to unlock it', 'You have incorrectly entered your password 3 times, you have 2 more attempts before being locked out of the account.'].includes(loginError), [loginError]);

    const loginComponent = useMemo(() => (
        <div className={styles.loginInputWrapper}>
            <input
                data-testid='login-username-input'
                className={styles.username}
                onChange={e => setUsername(e.target.value)}
                value={username}
                onKeyDown={attemptKeyPressLogin}
            />
            <input
                data-testid='login-password-input'
                className={styles.password}
                onChange={e => setPassword(e.target.value)}
                value={password}
                type='password'
                onKeyDown={attemptKeyPressLogin}
            />
            <div className={styles.loginError} data-testid='incorrect-credentials-error'>{loginError && 'Failed to login: Incorrect login credentials'}</div>
            <div className={styles.loginErrorWarning} data-testid='incorrect-credentials-error-warning-message'>{showLoginErrorMessage && loginError}</div>
            <button
                className={styles.loginButton}
                onClick={attemptLogin}
                data-testid='login-button'
            >
                Login
            </button>
            <div className={styles.loginAssistance}>
                <button className={styles.forgottenPassword} data-testid='forgot-password-button' onClick={handleForgotPassword}> Forgotten password?</button>
                <button className={styles.forgottenUsername} data-testid='forgot-username-button' onClick={handleForgotUsername}> Forgotten username?</button>
            </div>
        </div>
    ), [setUsername, attemptKeyPressLogin, setPassword, attemptLogin, username, password, handleForgotPassword, handleForgotUsername, loginError, showLoginErrorMessage]);

    const usernameReminder = useMemo(() => (
        <div className={styles.loginInputWrapper}>
            <div data-testid='forgotten-login-email-label' className={styles.inputLabel}>What is your email address?</div>
            <input
                data-testid='forgotten-login-email-input'
                className={styles.username}
                onChange={e => setEmail(e.target.value)}
                value={email}
                onKeyDown={attemptKeyPressUsernameReminder}
            />
            <div className={styles.loginError} data-testid='username-reminder-error'>{reminderError && reminderError}</div>
            <button
                className={styles.loginButton}
                onClick={handleUsernameReminder}
                data-testid='username-reminder-button'
                disabled={isSendingReminder}
            >Submit</button>
            <div className={styles.loginAssistance}>
                <button className={styles.forgottenPassword} onClick={handleCancelReminder} data-testid='return-to-login-button'> Back to login</button>
            </div>
        </div>
    ), [attemptKeyPressUsernameReminder, setEmail, handleCancelReminder, handleUsernameReminder, reminderError, email, isSendingReminder]);

    const passwordReminder = useMemo(() => (
        <div className={styles.loginInputWrapper}>
            <div data-testid='forgotten-login-username-label' className={styles.inputLabel}>What is the username linked to your account?</div>
            <input
                data-testid='forgotten-login-username-input'
                className={styles.username}
                onChange={e => setUsername(e.target.value)}
                value={username}
            />
            <div className={styles.loginError} data-testid='password-reminder-error'>{reminderError && reminderError}</div>
            <button
                className={styles.loginButton}
                onClick={handlePasswordReminder}
                data-testid='password-reminder-button'
                disabled={isSendingReminder}
            >Submit</button>
            <div className={styles.loginAssistance}>
                <button className={styles.forgottenPassword} onClick={handleCancelReminder} data-testid='return-to-login-button'> Back to login</button>
            </div>
        </div>
    ), [handlePasswordReminder, handleCancelReminder, reminderError, username, isSendingReminder]);

    const loginRender = useMemo(() => {
        switch (reminderType) {
            case ReminderTypes.USERNAME: {
                return usernameReminder;
            }
            case ReminderTypes.PASSWORD: {
                return passwordReminder;
            }
            case null:
            default:
                return loginComponent;
        }
    }, [reminderType, passwordReminder, usernameReminder, loginComponent]);

    const logoHeight = useMemo(() => `${screenHeight / 4}px`, [screenHeight]);

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.topWrapper}>
                <img className={styles.logoWrapper} src={Logo} style={{ height: logoHeight }} />
                {loginRender}
            </div>
            <div className={styles.summaryWrapper}>
                <div className={styles.summary}>Unlock the power of AI: Your smart contract management solution</div>
                <div className={styles.registrationWrapper}>
                    <div className={styles.registrationTitle}>Don't have an account - click here to register interest</div>
                    <Link to='/registration'>
                        <button className={styles.registrationButton} data-testid='registration-button'>Register</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
