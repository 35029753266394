import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { Action } from '../../../shared/modal/ActionModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../shared/table/ArkTable';
import { getNettingEngineDocumentColumnSort, getNettingEngineDocumentFilters, getNettingEngineDocumentPageSize, getNettingEngineDocuments, getNettingEngineDocumentsPageNumber, getTotalNettingEngineDocuments, getIsLoading, nettingEngineDocumentsPaginationNext, nettingEngineDocumentsPaginationPrevious, setNettingEngineDocumentTableFilters, clearNettingEngineDocumentTableFilters, setNettingEngineDocumentsPageSize, setNettingEngineDocumentTableColumnSort, openNettingEngineDocumentStarted, toggleNettingEngineView, fetchNettingEngineDocumentsStarted, NettingEngineTableView } from '../store';
import { getBaseEntityDropdownOptions } from '../../../admin/entity/store';
import { ArkDocument, DocumentType } from '../../../documents/my-documents/store';
import { Document } from '../../../shared/icons';
import { dateFormatter } from '../../../shared/table/arkTableFormatters';
import { getAllClientDocumentGroups, getAvailableDocumentNames } from '../../../admin/documents/store';
import styles from './NettingEngineDocuments.module.scss';
import { TableTabs } from '../../../shared/table/TableTabs';

interface NettingEngineDocumentsTableProps {
    isExistingView: boolean;
}

export const NettingEngineDocumentsTable: React.FC<NettingEngineDocumentsTableProps> = ({ isExistingView }) => {
    const dispatch = useAppDispatch();
    const nettingEngineDocuments = useAppSelector(getNettingEngineDocuments);
    const isLoading = useAppSelector(getIsLoading);
    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const allClientDocumentGroups = useAppSelector(getAllClientDocumentGroups);
    const entityOptions = useAppSelector(getBaseEntityDropdownOptions);

    const pageNumber = useAppSelector(getNettingEngineDocumentsPageNumber);
    const totalDocuments = useAppSelector(getTotalNettingEngineDocuments);
    const documentFilters = useAppSelector(getNettingEngineDocumentFilters);
    const columnSort = useAppSelector(getNettingEngineDocumentColumnSort);
    const pageSize = useAppSelector(getNettingEngineDocumentPageSize);

    const nextPage = useCallback(() => dispatch(nettingEngineDocumentsPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(nettingEngineDocumentsPaginationPrevious()), [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setNettingEngineDocumentTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearNettingEngineDocumentTableFilters()), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setNettingEngineDocumentsPageSize(pageSize)), [dispatch]);

    const openDocument = useCallback(({ documentId }: ArkDocument) => dispatch(openNettingEngineDocumentStarted(documentId)), [dispatch]);

    const nettingEngineAction = useCallback((data: ArkDocument): Action[] => [{ label: 'Select', icon: Document, onClick: () => openDocument(data) }], [openDocument]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', width: 0.3, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'documentName', header: 'Document', field: 'documentName', width: 0.2, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'executedDate', header: 'Date of Execution', field: 'executedDate', width: 0.15, valueFormatter: dateFormatter, canSort: true },
        { id: 'entityAName', header: 'Party A', field: 'entityAName', width: 0.15, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'entityBName', header: 'Party B', field: 'entityBName', width: 0.15, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'actions', header: '', field: '', width: 0.05, component: 'action', actions: nettingEngineAction }
    ];

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setNettingEngineDocumentTableColumnSort(columnSort)), [dispatch]);

    const documentNameOptions = useMemo(() => allDocumentNames
        .filter(({ datasetId }) => datasetId)
        .map(({ documentName }) => ({ value: documentName, label: documentName })), [allDocumentNames]
    );
    const clientDocumentGroupOptions = useMemo(() => allClientDocumentGroups.map(({ groupName, clientDocumentGroupId }, i) => ({ value: clientDocumentGroupId!.toString(), label: groupName, showDivider: i === allClientDocumentGroups.length - 1 })), [allClientDocumentGroups]);

    const documentsAndDocumentGroupOptions = useMemo(() => [...clientDocumentGroupOptions, ...documentNameOptions], [documentNameOptions, clientDocumentGroupOptions]);

    const filterDropdownOptions = useMemo(() => ({
        documentName: documentsAndDocumentGroupOptions,
        entityA: entityOptions,
        entityB: entityOptions
    }), [documentsAndDocumentGroupOptions, entityOptions]);

    const changeView = useCallback((view: NettingEngineTableView) => {
        dispatch(toggleNettingEngineView(view));
        dispatch(fetchNettingEngineDocumentsStarted(1, isExistingView ? DocumentType.PRE_EXECUTION_DRAFT : DocumentType.ORIGINAL));
    }, [dispatch, isExistingView]);

    const tableTabs = [
        { tabToggleAction: () => changeView(NettingEngineTableView.EXISTING), tabTitle: 'Existing', isSelected: isExistingView },
        { tabToggleAction: () => changeView(NettingEngineTableView.PRE_EXECUTION), tabTitle: 'Pre-Execution', isSelected: !isExistingView }
    ];

    return (
        <div className={styles.nettingEngineDocumentsWrapper} data-testid='documents-wrapper'>
            <div className={styles.nettingEngineDocumentsTitleContainer}>
                <div className={styles.nettingEngineDocumentsTitle} data-testid='documents-title'>My Documents</div>
            </div>
            <TableTabs
                tabs={tableTabs}
                testId='documents'
            />
            <ArkTable
                colDefs={columnDefs}
                rowData={nettingEngineDocuments}
                testId='netting-engine-documents'
                isLoading={isLoading}
                page={pageNumber}
                total={totalDocuments}
                next={nextPage}
                previous={previousPage}
                filters={documentFilters}
                updateFilter={updateFilter}
                clearAllFilters={clearAllFilters}
                onRowDoubleClicked={row => openDocument(row as ArkDocument)}
                columnSort={columnSort}
                toggleSort={toggleColumnSort}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                filterDropdownOptions={filterDropdownOptions}
            />
        </div>
    );
};
