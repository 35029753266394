import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { Form, Export } from '../../shared/icons';
import { ReportPage, setReportPage } from './store';
import { TileSelect, Tile } from '../../shared/tile-select/TileSelect';

export const DocumentReportSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectReportPage = useCallback((type: ReportPage) => dispatch(setReportPage(type)), [dispatch]);
    const selectNew = useCallback(() => selectReportPage(ReportPage.WIZARD), [selectReportPage]);
    const selectTemplates = useCallback(() => selectReportPage(ReportPage.TEMPLATES), [selectReportPage]);

    const reportTiles: Tile[] = useMemo(() =>[
        {
            label: 'Create a New Report',
            description: [
                'Start with a blank canvas',
                'Select the document type you wish to generate a report for',
                'Build your custom fields',
                'Apply filters to limit the documents included in your report'
            ],
            onClick: selectNew,
            testId: 'new',
            icon: Export
        },
        {
            label: 'Report Templates',
            description: [
                'Use an existing template as a base to generate a report',
                'Modify an existing template',
                'Delete templates that are no longer required'
            ],
            onClick: selectTemplates,
            testId: 'templates',
            icon: Form
        },
    ], [selectNew, selectTemplates]);

    return (
        <TileSelect testId='reporting' tiles={reportTiles} />
    );
};
