import { useEffect, useCallback } from 'react';
import { AnyAction } from 'redux';

import { useAppDispatch } from './react-redux';

export const useFetchStarted = (fetchStartedCallbacks: AnyAction[]) => {
    const dispatch = useAppDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchStartedCallback = useCallback(() => fetchStartedCallbacks.forEach(callback => dispatch(callback)), [dispatch]);

    useEffect(() => {
        fetchStartedCallback();
    }, [fetchStartedCallback]);
};
