import { DoraSupplyChainAssessmentDetails, SubstitutabilityReasoning } from '../dora/supply-chain/store';

export const initialAssessmentDetails: DoraSupplyChainAssessmentDetails = {
    ictSubstitutability: null,
    substitutabilityReasoning: {
        lackOfAlternatives: false,
        difficultiesMigrating: false,
        both: false
    },
    dateOfLastAudit: null,
    exitPlan: false,
    reintegration: null,
    discontinuingImpact: null,
    alternativeIctProviders: null,
    documents: [],
    furtherDetails: null
};

export const initialSubstitutabilityReasoning: SubstitutabilityReasoning = {
    lackOfAlternatives: false,
    difficultiesMigrating: false,
    both: false
};

type SubstitutabilityReasoningLabels = {
    [key in keyof SubstitutabilityReasoning]: string;
}

export const substitutabilityReasoningLabels: SubstitutabilityReasoningLabels = {
    lackOfAlternatives: 'The lack of real alternatives, even partial, due to the limited number of ICT third-party service providers active on a specific market, or the market share of the relevant ICT third-party service provider, or the technical complexity or sophistication involved, including in relation to any proprietary technology, or the specific features of the ICT third-party service provider’s organisation or activity.',
    difficultiesMigrating: 'Difficulties in relation to partial or full migration the relevant data and workloads from the relevant ICT third-party service provider to another ICT third-party service provider or by reintegrating them in the financial entity’s operations, due either to significant financial costs, time or other resources that the migration process may entail, or to increased ICT risk or other operational risks to which the financial entity might be exposed.',
    both: 'Both reasons referred to in points 1 and 2.'
};

enum ICTServiceTypesEnums {
    ICTProjectManagement = 'ICT Project Management',
    ICTDevelopment = 'ICT Development',
    ICTHelpDeskFirstLevelSupport = 'ICT help desk and first level support',
    ICTSecurityManagementServices = 'ICT security management services',
    ProvisionOfData = 'Provision of data',
    DataAnalysis = 'Data analysis',
    ICTFacilitiesAndHostingServicesNoCloud = 'ICT, facilities and hosting services (excluding Cloud services)',
    Computation = 'Computation',
    NonCloudDataStorage = 'Non-Cloud Data storage',
    TelecomCarrier = 'Telecom carrier',
    NetworkInfrastructure = 'Network infrastructure',
    HardwareAndPhysicalDevices = 'Hardware and physical devices',
    SoftwareLicencingNoSaaS = 'Software licencing (excluding SaaS)',
    ICTPperationManagementIncludingMaintenance = 'ICT operation management (including maintenance)',
    ICTConsulting = 'ICT Consulting',
    ICTRiskManagement = 'ICT Risk management',
    CloudServicesIaaS = 'Cloud services: IaaS',
    CloudServicesPaaS = 'Cloud services: PaaS',
    CloudServicesSaaS = 'Cloud services: SaaS'
}

export interface ICTServiceTypes {
    id: string;
    type: ICTServiceTypesEnums;
    description: string;
}

export const doraICTServiceTypes: ICTServiceTypes[] = [
    { id: 'S01', type: ICTServiceTypesEnums.ICTProjectManagement, description: 'Provision of services related to Project Management Officer (PMO).' },
    { id: 'S02', type: ICTServiceTypesEnums.ICTDevelopment, description: 'Provision of services related to: business analysis, software design and development, testing.' },
    { id: 'S03', type: ICTServiceTypesEnums.ICTHelpDeskFirstLevelSupport, description: 'Provision of services related to: helpdesk support and first level support on ICT incident.' },
    { id: 'S04', type: ICTServiceTypesEnums.ICTSecurityManagementServices, description: 'Provision of services related to: ICT security (protection, detection, response and recovering), including security incident handling and forensics.' },
    { id: 'S05', type: ICTServiceTypesEnums.ProvisionOfData, description: 'Subscription to the services of data providers (digital data service)' },
    { id: 'S06', type: ICTServiceTypesEnums.DataAnalysis, description: 'Provision of services related to the support for data analysis (digital data service)' },
    { id: 'S07', type: ICTServiceTypesEnums.ICTFacilitiesAndHostingServicesNoCloud, description: 'Provision of ICT infrastructure, facilities and hosting services, including the provision of utilities (energy, heat management etc), telecom access and physical security (excluding cloud services), payment-processing activities, or operating payment infrastructures' },
    { id: 'S08', type: ICTServiceTypesEnums.Computation, description: 'Provision of digital processing capabilities (including data computation), excluding the computation services performed in the context of a cloud environment.' },
    { id: 'S09', type: ICTServiceTypesEnums.NonCloudDataStorage, description: 'Provision of data storage platform (excluding cloud services).' },
    { id: 'S10', type: ICTServiceTypesEnums.TelecomCarrier, description: 'Operations for telecommunication systems and flow management. Traditional analogue telephone services are explicitly excluded pursuant to Article 3, point(21) of Regulation (EU) 2022/2554' },
    { id: 'S11', type: ICTServiceTypesEnums.NetworkInfrastructure, description: 'Provision of network infrastructure.' },
    { id: 'S12', type: ICTServiceTypesEnums.HardwareAndPhysicalDevices, description: 'Provision of workstations, phones, servers, data storage devices, appliances, etc. in a form of a service.' },
    { id: 'S13', type: ICTServiceTypesEnums.SoftwareLicencingNoSaaS, description: 'Provision of software run on premises.' },
    { id: 'S14', type: ICTServiceTypesEnums.ICTPperationManagementIncludingMaintenance, description: 'Provision of services related to: infrastructure (systems and hardware except network) configuration, maintenance, installing, capacity management, business continuity management, etc. Including Managed Service Providers (MSP).' },
    { id: 'S15', type: ICTServiceTypesEnums.ICTConsulting, description: 'Provision of intellectual / ICT expertise services.' },
    { id: 'S16', type: ICTServiceTypesEnums.ICTRiskManagement, description: 'Verification of compliance with ICT risk management requirements in accordance with Article 6(10) of Regulation (EU) 2022/2554.' },
    { id: 'S17', type: ICTServiceTypesEnums.CloudServicesIaaS, description: 'Infrastructure-as-a-Service.' },
    { id: 'S18', type: ICTServiceTypesEnums.CloudServicesPaaS, description: 'Platform-as-a-Service.' },
    { id: 'S19', type: ICTServiceTypesEnums.CloudServicesSaaS, description: 'Software-as-a-Service.' }
];

enum EntityServiceTypeEnums {
    CreditInstitutions = 'Credit institutions',
    PaymentInstitutions = 'Payment institutions, including payment institutions exempted pursuant to Directive (EU) 2015/2366',
    AccountInformationServiceProviders = 'Account information service providers',
    ElectronicMoneyInstitutions = 'Electronic money institutions, including electronic money institutions exempted pursuant to Directive 2009/110/EC',
    InvestmentFirms = 'Investment firms',
    CryptoAssetServiceProviders = 'Crypto-asset service providers authorised under Regulation (EU) 2023/1114',
    IssuersOfAssetReferencedTokens = 'Issuers of asset-reference tokens authorised under Regulation (EU) 2023/1114',
    CentralSecuritiesDepositories = 'Central securities depositories',
    CentralCounterparties = 'Central counterparties',
    TradingVenues = 'Trading venues',
    TradeRepositories = 'Trade repositories',
    ManagersOfAlternativeInvestmentFunds = 'Managers of alternative investment funds',
    ManagementCompanies = 'Management companies',
    DataReportingServiceProviders = 'Data reporting service providers',
    InsuranceAndReinsuranceUndertakings = 'Insurance and reinsurance undertakings',
    InsuranceIntermediariesReinsuranceIntermediariesAncillaryInsuranceIntermediaries = 'Insurance intermediaries, reinsurance intermediaries and ancillary insurance intermediaries',
    InstitutionsForOccupationalRetirementProvision = 'Institutions for occupational retirement provision',
    CreditRatingAgencies = 'Credit rating agencies',
    AdministratorsOfCriticalBenchmarks = 'Administrators of critical benchmarks',
    CrowdfundingServiceProviders = 'Crowdfunding service providers',
    SecuritisationRepositories = 'Securitisation repositories',
    OtherFinancialEntity = 'Other financial entity',
    NonFinancialEntityICTIntraGroupServiceProvider = 'Non-financial entity: ICT intra-group service provider',
    NonFinancialEntityOther = 'Non-financial entity: Other'
}

export interface EntityServiceType {
    type: EntityServiceTypeEnums;
    description: string;
}

export const doraEntityServiceType: EntityServiceType[] = [
    { type: EntityServiceTypeEnums.CreditInstitutions, description: 'For credit institutions and for institutions exempted pursuant to Directive 2013/36/EU, the competent authority designated in accordance with Article 4 of that Directive, and for credit institutions classified as significant in accordance with Article 6(4) of Regulation (EU) No 1024/2013, the ECB in accordance with the powers and tasks conferred by that Regulation.' },
    { type: EntityServiceTypeEnums.PaymentInstitutions, description: 'For payment institutions, including payment institutions exempted pursuant to Directive (EU) 2015/2366, electronic money institutions, including those exempted pursuant to Directive 2009/110/EC, and account information service providers as referred to in Article 33(1) of Directive (EU) 2015/2366, the competent authority designated in accordance with Article 22 of Directive (EU) 2015/2366.' },
    { type: EntityServiceTypeEnums.AccountInformationServiceProviders, description: 'For payment institutions, including payment institutions exempted pursuant to Directive (EU) 2015/2366, electronic money institutions, including those exempted pursuant to Directive 2009/110/EC, and account information service providers as referred to in Article 33(1) of Directive (EU) 2015/2366, the competent authority designated in accordance with Article 22 of Directive (EU) 2015/2366.' },
    { type: EntityServiceTypeEnums.ElectronicMoneyInstitutions, description: 'For payment institutions, including payment institutions exempted pursuant to Directive (EU) 2015/2366, electronic money institutions, including those exempted pursuant to Directive 2009/110/EC, and account information service providers as referred to in Article 33(1) of Directive (EU) 2015/2366, the competent authority designated in accordance with Article 22 of Directive (EU) 2015/2366.' },
    { type: EntityServiceTypeEnums.InvestmentFirms, description: 'For investment firms, the competent authority designated in accordance with Article 4 of Directive (EU) 2019/2034 of the European Parliament and of the Council.' },
    { type: EntityServiceTypeEnums.CryptoAssetServiceProviders, description: 'For crypto-asset service providers as authorised under the Regulation on markets in crypto-assets and issuers of assetreferenced tokens, the competent authority designated in accordance with the relevant provision of that Regulation.' },
    { type: EntityServiceTypeEnums.IssuersOfAssetReferencedTokens, description: '' },
    { type: EntityServiceTypeEnums.CentralSecuritiesDepositories, description: 'For central securities depositories, the competent authority designated in accordance with Article 11 of Regulation (EU) No 909/2014.' },
    { type: EntityServiceTypeEnums.CentralCounterparties, description: 'For central counterparties, the competent authority designated in accordance with Article 22 of Regulation (EU) No 648/2012.' },
    { type: EntityServiceTypeEnums.TradingVenues, description: 'For trading venues and data reporting service providers, the competent authority designated in accordance with Article 67 of Directive 2014/65/EU, and the competent authority as defined in Article 2(1), point (18), of Regulation (EU) No 600/2014.' },
    { type: EntityServiceTypeEnums.TradeRepositories, description: 'For trade repositories, the competent authority designated in accordance with Article 22 of Regulation (EU) No 648/2012.' },
    { type: EntityServiceTypeEnums.ManagersOfAlternativeInvestmentFunds, description: 'For managers of alternative investment funds, the competent authority designated in accordance with Article 44 of Directive 2011/61/EU.' },
    { type: EntityServiceTypeEnums.ManagementCompanies, description: 'For management companies, the competent authority designated in accordance with Article 97 of Directive 2009/65/EC.' },
    { type: EntityServiceTypeEnums.DataReportingServiceProviders, description: 'For trading venues and data reporting service providers, the competent authority designated in accordance with Article 67 of Directive 2014/65/EU, and the competent authority as defined in Article 2(1), point (18), of Regulation (EU) No 600/2014.' },
    { type: EntityServiceTypeEnums.InsuranceAndReinsuranceUndertakings, description: 'For insurance and reinsurance undertakings, the competent authority designated in accordance with Article 30 of Directive 2009/138/EC.' },
    { type: EntityServiceTypeEnums.InsuranceIntermediariesReinsuranceIntermediariesAncillaryInsuranceIntermediaries, description: 'For insurance intermediaries, reinsurance intermediaries and ancillary insurance intermediaries, the competent authority designated in accordance with Article 12 of Directive (EU) 2016/97.' },
    { type: EntityServiceTypeEnums.InstitutionsForOccupationalRetirementProvision, description: 'For institutions for occupational retirement provision, the competent authority designated in accordance with Article 47 of Directive (EU) 2016/2341.' },
    { type: EntityServiceTypeEnums.CreditRatingAgencies, description: 'For credit rating agencies, the competent authority designated in accordance with Article 21 of Regulation (EC) No 1060/2009.' },
    { type: EntityServiceTypeEnums.AdministratorsOfCriticalBenchmarks, description: 'For administrators of critical benchmarks, the competent authority designated in accordance with Articles 40 and 41 of Regulation (EU) 2016/1011.' },
    { type: EntityServiceTypeEnums.CrowdfundingServiceProviders, description: 'For crowdfunding service providers, the competent authority designated in accordance with Article 29 of Regulation (EU) 2020/1503.' },
    { type: EntityServiceTypeEnums.SecuritisationRepositories, description: 'For securitisation repositories, the competent authority designated in accordance with Articles 10 and 14(1) of Regulation (EU) 2017/2402.' },
    { type: EntityServiceTypeEnums.OtherFinancialEntity, description: '' },
    { type: EntityServiceTypeEnums.NonFinancialEntityICTIntraGroupServiceProvider, description: 'Not applicable' },
    { type: EntityServiceTypeEnums.NonFinancialEntityOther, description: 'Not applicable' }
];
