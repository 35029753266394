import React from 'react';
import classnames from 'classnames';
import { noop } from 'lodash/fp';

import styles from './AnnexPreview.module.scss';
import { AgencyAnnexField } from '../store';
import { Text, Dropdown, DateField, Number, Checkbox, Wizard } from '../../datasets/fields';
import { DatasetFieldType } from '../../datasets/store';
import { widthsByType } from '../../admin/dataset-builder/preview/tablePreview/TableHeader';
import { AnnexInstanceField } from '../../datasets/instances/store';
import { CurrencyAmountField } from '../../datasets/fields/CurrencyAmountField';

interface AnnexCellProps {
    field: AgencyAnnexField;
    index: number;
    isFirstCell?: boolean;
    isLastCell: boolean;
}

const getField = (field: AgencyAnnexField, index: number) => {
    const defaultProps = { id: field.id!, index, rowId: 'annex-preview', value: '', disabled: true, isTable: true, datasetId: 1, parentFieldId: '' };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={null} />;
        case DatasetFieldType.DROPDOWN:
            return <Dropdown {...defaultProps} value={null} type={field.type} listId={field.settings.dropdownLinked!} />;
        case DatasetFieldType.LINKFIELD:
            return <Dropdown {...defaultProps} value={null} type={field.type} listId={field.settings.dropdownLinked!} isMulti={field.settings.isMultiSelect} entityType={field.settings.entityType} />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={false} />;
        case DatasetFieldType.WIZARD:
            return <Wizard {...defaultProps} value={['']} field={{ ...field, value: [''] } as AnnexInstanceField} isPreview toggleSection={noop} getSectionOpen={() => false} />;
        case DatasetFieldType.CURRENCY_AMOUNT:
            return <CurrencyAmountField {...defaultProps} currencyValue={{ currency: null, value: undefined }} />;
        default:
            return <div className={styles.unknownFieldType}>Field type unknown.</div>;
    }
};

export const AnnexCell: React.FC<AnnexCellProps> = ({ field, index, isFirstCell = false, isLastCell }) => {
    const cellWidth = `${widthsByType[field.type]}px`;
    return (
        <div className={classnames(styles.annexCellWrapper, {
            [styles.firstCell]: isFirstCell,
            [styles.lastCell]: isLastCell
        })}
        style={{ width: cellWidth, minWidth: cellWidth }}
        >
            {getField(field, index)}
        </div>
    );
};
