import React, { useCallback, useMemo } from 'react';
import { findLast, isEqual, isNull, isUndefined, noop, get } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermission } from '../../../auth/login/store';
import { SmileyIndicator } from '../../../shared/analytics/SingleIndicator';
import { Icon } from '../../../shared/icon/Icon';
import { SmileyNegative, SmileyNeutral, SmileyPositive } from '../../../shared/icons';
import { SpeechBubble } from '../../../shared/icons/SpeechBubble';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { IconTooltip } from '../../../shared/tooltip';
import { addOpinionInstanceSignOffNotes, BasicSignOffConversation, DropdownDetailsSignOffConversation, getCurrentInstance, getCurrentInstanceIsSignedOff, getIsEditing, getIsEditingNote, getSectionOrFieldId, getSignOffNotesContent, getSignOffNotesModalOpen, OpinionInstanceField, setSignOffNotesModalOpen, getOpinionInstanceTimeline } from '../store';
import styles from './SignOff.module.scss';
import { SignOffConversationNotesWrapper } from './SignOffConversationNotesWrapper';

const { green, red, amber, primary, lightGrey, grey } = styles;

interface SignOffConversationProps {
    signOffConversation: BasicSignOffConversation | DropdownDetailsSignOffConversation;
    id: string;
    isDropdownDetailsField?: boolean;
}

export const SignOffConversationIcon: React.FC<SignOffConversationProps> = ({ signOffConversation, id, isDropdownDetailsField = false }) => {
    const dispatch = useAppDispatch();
    const isEditing = useAppSelector(getIsEditing);
    const isEditingNote = useAppSelector(getIsEditingNote);
    const signOffNotesModalOpen = useAppSelector(getSignOffNotesModalOpen);
    const sectionOrFieldId = useAppSelector(getSectionOrFieldId);
    const signOffNotesContent = useAppSelector(getSignOffNotesContent);
    const hasAddNotesPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.ADD_OPINION_NOTES]));
    const opinionInstanceIsSignedOff = useAppSelector(getCurrentInstanceIsSignedOff);
    const timeline = useAppSelector(getOpinionInstanceTimeline);
    const currentInstance = useAppSelector(getCurrentInstance);
    const sectionOrFieldIdsMatch = id.localeCompare(sectionOrFieldId);
    const opinionInstance = useAppSelector(getCurrentInstance);
    const modalVisible = useMemo(() => !isEditing && signOffNotesModalOpen && sectionOrFieldIdsMatch === 0, [isEditing, signOffNotesModalOpen, sectionOrFieldIdsMatch]);
    const hasNotes = useMemo(() => signOffConversation.notes.length > 0, [signOffConversation]);
    const latestPublishedInstance = useMemo(() => findLast(instance => !instance.isDraft, timeline), [timeline]);
    const isLatestPublishedInstance = useMemo(() => !isUndefined(latestPublishedInstance) && isEqual(latestPublishedInstance.opinionInstanceId, currentInstance?.opinionInstanceId), [latestPublishedInstance, currentInstance]);
    const selectedInstanceIsDraft = !isNull(currentInstance) && !!currentInstance.isDraft;
    const toggleSignOffModal = useCallback(() => {
        if (!hasAddNotesPermission && !hasNotes || (opinionInstanceIsSignedOff && !hasNotes) || isEditing || (selectedInstanceIsDraft && isLatestPublishedInstance) || (!isLatestPublishedInstance && !hasNotes)) {
            return noop;
        }
        dispatch(setSignOffNotesModalOpen(!signOffNotesModalOpen, id));
    }, [hasAddNotesPermission, hasNotes, opinionInstanceIsSignedOff, isEditing, dispatch, signOffNotesModalOpen, id, selectedInstanceIsDraft, isLatestPublishedInstance]);
    const smileyIconAndNotesValid = useMemo(() => signOffNotesContent.smileyValue !== SmileyIndicator.NONE && !isUndefined(signOffNotesContent.notes?.blocks.find(({ text }) => text !== '')), [signOffNotesContent]);

    const smileyIcon = useMemo(() => {
        if (!hasNotes) {
            return { icon: SmileyNeutral, style: { fill: lightGrey, color: grey } };
        }
        const lastConversation = signOffConversation.notes[signOffConversation.notes.length - 1];
        if (lastConversation.smileyValue === SmileyIndicator.POSITIVE) return { icon: SmileyPositive, style: { fill: green, color: primary } };
        if (lastConversation.smileyValue === SmileyIndicator.NEGATIVE) return { icon: SmileyNegative, style: { fill: red, color: primary } };
        return { icon: SmileyNeutral, style: { fill: amber, color: primary } };
    }, [hasNotes, signOffConversation]);

    const { icon, style } = smileyIcon;

    const camelCaseToWords = (string: string) => {
        // If the id coming through is hyphenated we only want the first part, which is the section title
        const sectionTitle = string.split('-')[0];
        // Convert the section title from camelCase into a capitalised string
        const modalTitle = sectionTitle.replace(/([A-Z])/g, ' $1');
        return capitaliseStartLetter(modalTitle);
    };

    const modalTitle: string = camelCaseToWords(id);

    const { sectionId, fieldId }: { sectionId: string; fieldId: string | undefined; } = useMemo(() => {
        const [sectionId, fieldId] = id.split('-');
        return { sectionId, fieldId };
    }, [id]);

    const fieldLabel = useMemo(() => {
        if (isUndefined(fieldId)) {
            return null;
        }
        const field: OpinionInstanceField = get(`content[${sectionId}][${fieldId}]`, opinionInstance);
        return field?.label || null;
    }, [opinionInstance, fieldId, sectionId]);

    const addSignOffNotes = useCallback(() => {
        dispatch(addOpinionInstanceSignOffNotes(sectionId, fieldId, signOffConversation));
    }, [dispatch, sectionId, fieldId, signOffConversation]);

    const disabledTooltip = useMemo(() => {
        if (!opinionInstanceIsSignedOff && !smileyIconAndNotesValid) {
            return ['You must select a smiley icon and supply a written note'];
        }
        if (opinionInstanceIsSignedOff) {
            return ['No further notes can be added once the opinion has been signed off'];
        }
    }, [smileyIconAndNotesValid, opinionInstanceIsSignedOff]);

    const reset = useCallback(() => {
        dispatch(setSignOffNotesModalOpen(false, ''));
    }, [dispatch]);

    const canAddNote = useMemo(() => hasAddNotesPermission && smileyIconAndNotesValid && !opinionInstanceIsSignedOff, [hasAddNotesPermission, smileyIconAndNotesValid, opinionInstanceIsSignedOff]);
    const closeLabel = useMemo(() => canAddNote ? 'Cancel' : 'Close', [canAddNote]);
    const tooltipContent = useMemo(() => {
        if (isEditing) {
            return 'You cannot view sign off notes while editing an opinion instance';
        } else if ((!hasAddNotesPermission && !hasNotes) || (opinionInstanceIsSignedOff && !hasNotes) || (!selectedInstanceIsDraft && !isLatestPublishedInstance && !hasNotes)) {
            return 'There are no sign off notes for this section';
        } else if (selectedInstanceIsDraft) {
            return 'You cannot view/add sign off notes until the opinion instance has been published.';
        } else {
            return 'View sign off notes';
        }
    }, [isEditing, hasAddNotesPermission, hasNotes, opinionInstanceIsSignedOff, selectedInstanceIsDraft, isLatestPublishedInstance]);

    const confirmLabel = useMemo(() => `${isEditingNote ? 'Update' : 'Add'} Note`, [isEditingNote]);

    return (
        <div className={styles.signOffWrapper}>
            <div className={styles.iconWrapper} onClick={toggleSignOffModal}>
                <IconTooltip content={tooltipContent} icon={SpeechBubble} fontSize={36} iconColor={hasNotes && !isEditing ? primary : grey} trigger='hover' />
                <div className={styles.iconValue}>
                    <Icon icon={icon} fontSize={24} {...style} />
                </div>
            </div>
            {modalVisible &&
                <ConfirmationModal
                    isOpen={modalVisible}
                    closeModal={reset}
                    confirmLabel={confirmLabel}
                    confirm={addSignOffNotes}
                    confirmDisabled={!canAddNote}
                    confirmDisabledTooltip={disabledTooltip}
                    showConfirm={(hasAddNotesPermission && !opinionInstanceIsSignedOff) && (!selectedInstanceIsDraft && isLatestPublishedInstance)}
                    closeLabel={closeLabel}
                >
                    <SignOffConversationNotesWrapper signOffConversation={signOffConversation} modalTitle={modalTitle} hasAddNotesPermission={hasAddNotesPermission} canAddNotes={!selectedInstanceIsDraft && isLatestPublishedInstance} fieldLabel={fieldLabel} isDropdownDetailsField={isDropdownDetailsField} />
                </ConfirmationModal>
            }
        </div>
    );
};
