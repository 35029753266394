import { indexOf, isNull, max, min, set } from 'lodash/fp';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { AnalysisView } from '../../../documents/my-documents/store';
import { Icon } from '../../../shared/icon/Icon';
import { Ellipsis } from '../../../shared/icons';
import { getOpinionAnalysisView } from '../../my-opinions/store';
import { TimelineOpinionInstance, getCurrentInstance, getIsSaving, setTimelineInstance } from '../store';
import styles from './HistoricalInstances.module.scss';
import { InstanceTooltip } from './InstanceTooltip';

const { french, white, grey } = styles;

interface MarkerProps {
    timelineInstance: TimelineOpinionInstance;
    showDate: boolean;
}

const Marker: React.FC<MarkerProps> = ({ timelineInstance, showDate }) => {
    if (showDate) {
        return (
            <InstanceTooltip timelineInstance={timelineInstance}>
                <div className={styles.marker}>{timelineInstance.dateOfOpinion}</div>
            </InstanceTooltip>
        );
    }
    return (
        <InstanceTooltip timelineInstance={timelineInstance}>
            <div className={styles.iconWrapper}>
                <Icon icon={Ellipsis} fontSize={20} />
            </div>
        </InstanceTooltip>
    );
};

interface HistoricalInstancesProps {
    timeline: TimelineOpinionInstance[];
}

export const HistoricalInstances: React.FC<HistoricalInstancesProps> = ({ timeline }) => {
    const dispatch = useAppDispatch();
    const isSaving = useAppSelector(getIsSaving);
    const opinionAnalysisView = useAppSelector(getOpinionAnalysisView);
    const currentInstance = useAppSelector(getCurrentInstance);

    const maxMarkers = useMemo(() => opinionAnalysisView === AnalysisView.SPLIT ? 6 : 12, [opinionAnalysisView]);
    const showMarkerDate = useCallback((numberOfMarks: number, extremityDate: boolean) => numberOfMarks < maxMarkers || extremityDate, [maxMarkers]);

    const selectedTimeline = useMemo(() => !isNull(currentInstance) && timeline.find(({ opinionInstanceId }) => currentInstance.opinionInstanceId === opinionInstanceId) || null, [timeline, currentInstance]);

    const historicalInstances = useMemo(() => timeline.reduce((acc, timelineInstance, index) => {
        const numberOfMarks = timeline.length;
        const key = index + 1;
        const extremityDate = key === 1 || key === numberOfMarks;
        const showDate = showMarkerDate(numberOfMarks, extremityDate);
        return set(key,
            <Marker
                timelineInstance={timelineInstance}
                showDate={showDate}
            />,
            acc);
    }, {}), [showMarkerDate, timeline]);

    const minValue = min((Object.keys(historicalInstances).map(val => parseInt(val))));
    const maxValue = max((Object.keys(historicalInstances).map(val => parseInt(val))));
    const minimum = minValue && maxValue !== minValue ? minValue : 0;
    const value = useMemo(() => selectedTimeline ? indexOf(selectedTimeline, timeline) + 1 : maxValue, [selectedTimeline, maxValue, timeline]);

    const onChange = (value: number | number[]) => {
        const index = (value as number) - 1;
        const clickedInstance = timeline[index];
        if (clickedInstance && clickedInstance.opinionInstanceId !== selectedTimeline?.opinionInstanceId) {
            dispatch(setTimelineInstance(clickedInstance));
        }
    };

    return (
        <div className={styles.historicalSliderWrapper}>
            {!isSaving ?
                <Slider
                    min={minimum}
                    onChange={onChange}
                    marks={historicalInstances}
                    defaultValue={maxValue}
                    value={value}
                    max={maxValue}
                    step={1}
                    trackStyle={{
                        backgroundColor: french,
                        height: '6px'
                    }}
                    railStyle={{
                        backgroundColor: grey,
                        height: '6px'
                    }}
                    handleStyle={{
                        backgroundColor: white,
                        border: `1px solid ${french}`,
                        height: '16px',
                        width: '16px',
                        marginTop: '-5px',
                        opacity: 1
                    }}
                    dotStyle={{
                        backgroundColor: white,
                        border: `1px solid ${french}`,
                        height: '12px',
                        width: '12px',
                        top: '-3px'
                    }}
                /> : <div style={{ height: '14px' }} />}
        </div>
    );
};
