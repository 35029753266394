import download from 'downloadjs';
import { getOr, isEqual, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { createOpinionReport, deleteReportTemplate, fetchAllOpinionReportTemplates, upsertReportTemplate } from '../../../../services/report';
import { DATE_FORMAT, formatDate } from '../../../../utils/luxon';
import { dotsToDashes } from '../../../../utils/regex-utils';
import {
    deleteReportTemplateFailed,
    deleteReportTemplateStarted,
    deleteReportTemplateSuccessful,
    fetchAllReportTemplatesFailed,
    fetchAllReportTemplatesStarted,
    fetchAllReportTemplatesSuccessful,
    generateReportFailed,
    generateReportSuccessful,
    openOpinionReportTemplate,
    resetReport,
    saveReportTemplateFailed,
    saveReportTemplateStarted,
    saveReportTemplateSuccessful,
    setOpinionReportScopes,
    setReportPage,
    setReportUpdated
} from './actions';
import { initialOpinionReportFilter, initialReportFields } from './reducer';
import { getIncludeSignOffNotes, getReportFields, getReportFilters, getSelectedTemplate, getUpsertReportTemplate } from './selectors';
import { OpinionReportField, OpinionReportFilter, OpinionReportTemplate, OpinionReportingActionTypes, OpinionScopeFields, ReportPage, UpsertOpinionReportTemplate } from './types';

export function* attemptGenerateReport() {
    try {
        const reportFields: OpinionScopeFields = yield select(getReportFields);
        const filters: OpinionReportFilter = yield select(getReportFilters);
        const includeSignOffNotes: boolean = yield select(getIncludeSignOffNotes);
        const selectedTemplate: OpinionReportTemplate | null = yield select(getSelectedTemplate);
        const fileName = selectedTemplate ? selectedTemplate.name : 'Ark51 Custom Report';
        const datedFileName = `${fileName} ${formatDate(new Date(), DATE_FORMAT)}`;
        const formattedFileName = dotsToDashes(datedFileName);
        const report: Blob = yield call(createOpinionReport, { fileName: datedFileName, filters, reportFields, includeSignOffNotes });
        yield call(download, report, formattedFileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        yield put(generateReportSuccessful());
    } catch (e) {
        yield put(generateReportFailed((e as Error).message));
        toast.error('Unable to generate report. Please try again.');
    }
}

function* generateReportWatcher() {
    yield takeEvery(OpinionReportingActionTypes.GENERATE_REPORT_STARTED, attemptGenerateReport);
}

export function* checkOpinionReportUpdated() {
    try {
        const reportFields: OpinionReportField[] = yield select(getReportFields);
        const filters: OpinionReportFilter = yield select(getReportFilters);
        const includeSignOffNotes: boolean = yield select(getIncludeSignOffNotes);
        const selectedTemplate: OpinionReportTemplate | null = yield select(getSelectedTemplate);
        const selectedTemplateFields = getOr(initialReportFields, 'reportFields', selectedTemplate);
        const selectedTemplateFilters = getOr(initialOpinionReportFilter, 'filters', selectedTemplate);
        const selectedTemplateIncludeSignOffNotes = getOr(false, 'includeSignOffNotes', selectedTemplate);
        const reportFieldsUpdated = !isEqual({ reportFields: selectedTemplateFields, filters: selectedTemplateFilters, includeSignOffNotes: selectedTemplateIncludeSignOffNotes }, { reportFields, filters, includeSignOffNotes });
        yield put(setReportUpdated(reportFieldsUpdated));
    } catch (e) {
        toast.error('Something went wrong when checking if report has been updated.');
    }
}

function* opinionReportUpdatedWatcher() {
    yield takeEvery([OpinionReportingActionTypes.UPDATE_OPINION_REPORT_FIELDS, OpinionReportingActionTypes.ADD_OPINION_REPORT_FIELD, OpinionReportingActionTypes.REMOVE_OPINION_REPORT_FIELD, OpinionReportingActionTypes.UPDATE_REPORT_FILTER, OpinionReportingActionTypes.TOGGLE_INCLUDE_SIGN_OFF_NOTES], checkOpinionReportUpdated);
}

export function* attemptSaveReportTemplate({ payload }: ReturnType<typeof saveReportTemplateStarted>) {
    try {
        const saveAs = payload;
        let reportTemplate: UpsertOpinionReportTemplate = yield select(getUpsertReportTemplate);
        if (saveAs) {
            reportTemplate = unset('opinionReportTemplateId', reportTemplate);
        }
        const upsertedTemplate: OpinionReportTemplate = yield call(upsertReportTemplate, reportTemplate);
        yield put(saveReportTemplateSuccessful(upsertedTemplate));
        yield put(fetchAllReportTemplatesStarted());
    } catch (e) {
        yield put(saveReportTemplateFailed((e as Error).message));
        toast.error('Unable to save report template. Please try again.');
    }
}

function* saveReportTemplateWatcher() {
    yield takeEvery(OpinionReportingActionTypes.SAVE_REPORT_TEMPLATE_STARTED, attemptSaveReportTemplate);
}

export function* attemptFetchAllReportTemplates() {
    try {
        const reportTemplates: OpinionReportTemplate[] = yield call(fetchAllOpinionReportTemplates);
        yield put(fetchAllReportTemplatesSuccessful(reportTemplates));
    } catch (e) {
        yield put(fetchAllReportTemplatesFailed((e as Error).message));
        toast.error('Unable to fetch report templates. Please try again.');
    }
}

function* fetchAllReportTemplatesWatcher() {
    yield takeEvery(OpinionReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_STARTED, attemptFetchAllReportTemplates);
}

export function* attemptDeleteReportTemplate({ payload }: ReturnType<typeof deleteReportTemplateStarted>) {
    try {
        const reportTemplates: OpinionReportTemplate[] = yield call(deleteReportTemplate, { id: payload, isOpinionTemplate: true });
        yield put(deleteReportTemplateSuccessful(reportTemplates));
    } catch (e) {
        yield put(deleteReportTemplateFailed((e as Error).message));
        toast.error('Unable to delete report template. Please try again.');
    }
}

function* deleteReportTemplateWatcher() {
    yield takeEvery(OpinionReportingActionTypes.DELETE_REPORT_TEMPLATE_STARTED, attemptDeleteReportTemplate);
}

export function* attemptOpenTemplate(templateId: string) {
    const reportTemplates: OpinionReportTemplate[] = yield call(fetchAllOpinionReportTemplates);
    const template = reportTemplates.find(({ opinionReportTemplateId }) => opinionReportTemplateId.toString() === templateId);
    if (template) {
        yield put(openOpinionReportTemplate(template));
        yield put(setOpinionReportScopes(template.scopes, false));
    } else {
        yield put(push('/opinions/reporting/templates'));
    }
}

export function* redirectReportPage({ payload }: ReturnType<typeof setReportPage>) {
    const { page, templateId } = payload;
    if (templateId) {
        yield call(attemptOpenTemplate, templateId);
    } else {
        let navigationPath = '/opinions/reporting';
        if (page !== ReportPage.SELECT) {
            navigationPath = `/opinions/reporting/${page === ReportPage.WIZARD ? 'create' : 'templates'}`;
        } else {
            yield put(resetReport(ReportPage.SELECT));
        }
        yield put(push(navigationPath));
    }
}

function* redirectReportPageWatcher() {
    yield takeEvery(OpinionReportingActionTypes.SELECT_REPORT_PAGE, redirectReportPage);
}

export function* redirectOpinionTemplatePage({ payload }: ReturnType<typeof openOpinionReportTemplate>) {
    const navigationPath = `/opinions/reporting/templates/${payload.opinionReportTemplateId}`;
    yield put(push(navigationPath));
}

function* redirectOpinionTemplatePageWatcher() {
    yield takeEvery(OpinionReportingActionTypes.OPEN_OPINION_REPORT_TEMPLATE, redirectOpinionTemplatePage);
}

export function* opinionReportingSaga() {
    yield all([
        fork(generateReportWatcher),
        fork(opinionReportUpdatedWatcher),
        fork(saveReportTemplateWatcher),
        fork(fetchAllReportTemplatesWatcher),
        fork(deleteReportTemplateWatcher),
        fork(redirectReportPageWatcher),
        fork(redirectOpinionTemplatePageWatcher)
    ]);
}
