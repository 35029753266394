import { AnalysisView, ArkDocument } from '../../../documents/my-documents/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';

export enum OpinionNettingEngineActionTypes {
    SET_NETTING_ENGINE_PAGE = 'SET_NETTING_ENGINE_PAGE',
    RESET_NETTING_ENGINE_PAGE = 'RESET_NETTING_ENGINE_PAGE',
    UPDATE_NETTING_ENGINE_THEORY = 'UPDATE_NETTING_ENGINE_THEORY',
    CALCULATE_NETTING_ENGINE_THEORY_STARTED = 'CALCULATE_NETTING_ENGINE_THEORY_STARTED',
    CALCULATE_NETTING_ENGINE_THEORY_SUCCESSFUL = 'CALCULATE_NETTING_ENGINE_THEORY_SUCCESSFUL',
    CALCULATE_NETTING_ENGINE_THEORY_FAILED = 'CALCULATE_NETTING_ENGINE_THEORY_FAILED',
    SET_SURVEY_QUESTION_INDEX = 'SET_SURVEY_QUESTION_INDEX',
    RESET_THEORETICAL_STATE = 'RESET_THEORETICAL_STATE',
    SET_SHOW_SUMMARY = 'SET_SHOW_SUMMARY',
    SET_LEI_MODAL_OPEN = 'SET_LEI_MODAL_OPEN',
    SET_CHANGING_QUESTION = 'SET_CHANGING_QUESTION',
    SET_NETTING_ASSUMPTIONS_MODAL_OPEN = 'SET_NETTING_ASSUMPTIONS_MODAL_OPEN',
    ENQUIRE_ALL_NETTING_OPINIONS_STARTED = 'ENQUIRE_ALL_NETTING_OPINIONS_STARTED',
    ENQUIRE_SINGLE_NETTING_OPINION_STARTED = 'ENQUIRE_SINGLE_NETTING_OPINION_STARTED',
    ENQUIRE_NETTING_OPINION_SUCCESSFUL = 'ENQUIRE_NETTING_OPINION_SUCCESSFUL',
    ENQUIRE_NETTING_OPINION_FAILED = 'ENQUIRE_NETTING_OPINION_FAILED',
    TOGGLE_INDUSTRY_OR_CUSTOM_CONCLUSIONS = 'TOGGLE_INDUSTRY_OR_CUSTOM_CONCLUSIONS',
    FETCH_NETTING_ENGINE_DOCUMENTS_STARTED = 'FETCH_NETTING_ENGINE_DOCUMENTS_STARTED',
    FETCH_NETTING_ENGINE_DOCUMENTS_SUCCESSFUL = 'FETCH_NETTING_ENGINE_DOCUMENTS_SUCCESSFUL',
    FETCH_NETTING_ENGINE_DOCUMENTS_FAILED = 'FETCH_NETTING_ENGINE_DOCUMENTS_FAILED',
    SET_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS = 'SET_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS',
    CLEAR_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS = 'CLEAR_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS',
    SET_NETTING_ENGINE_DOCUMENT_TABLE_COLUMN_SORT = 'SET_NETTING_ENGINE_DOCUMENT_TABLE_COLUMN_SORT',
    SET_NETTING_ENGINE_DOCUMENTS_PAGE_SIZE = 'SET_NETTING_ENGINE_DOCUMENTS_PAGE_SIZE',
    NETTING_ENGINE_DOCUMENTS_PAGINATION_NEXT = 'NETTING_ENGINE_DOCUMENTS_PAGINATION_NEXT',
    NETTING_ENGINE_DOCUMENTS_PAGINATION_PREVIOUS = 'NETTING_ENGINE_DOCUMENTS_PAGINATION_PREVIOUS',
    OPEN_NETTING_ENGINE_DOCUMENT_STARTED = 'OPEN_NETTING_ENGINE_DOCUMENT_STARTED',
    OPEN_NETTING_ENGINE_DOCUMENT_SUCCESSFUL = 'OPEN_NETTING_ENGINE_DOCUMENT_SUCCESSFUL',
    OPEN_NETTING_ENGINE_DOCUMENT_FAILED = 'OPEN_NETTING_ENGINE_DOCUMENT_FAILED',
    UPDATE_NETTING_ENGINE_FIELDS = 'UPDATE_NETTING_ENGINE_FIELDS',
    CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_STARTED = 'CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_STARTED',
    CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_SUCCESSFUL = 'CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_SUCCESSFUL',
    CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_FAILED = 'CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_FAILED',
    RESET_DOCUMENTS_STATE = 'RESET_DOCUMENTS_STATE',
    TOGGLE_NETTING_ENGINE_VIEW = 'TOGGLE_NETTING_ENGINE_VIEW',
    TOGGLE_DOCUMENT_SUMMARY_VIEW = 'TOGGLE_DOCUMENT_SUMMARY_VIEW'
}

export enum NettingPage {
    SELECT = 'Select',
    DOCUMENTS = 'Existing Documents',
    THEORETICAL = 'Theoretical Agreements'
}

export enum NettingEngineAgreement {
    ISDA_1992_MASTER = '1992 ISDA Master Agreement (Multicurrency - Cross Border)',
    ISDA_2002_MASTER = '2002 ISDA Master Agreement'
}

export enum NettingEngineQuestionType {
    BOOLEAN = 'boolean',
    DROPDOWN = 'dropdown'
}

export enum NettingEngineTableView {
    EXISTING = 'EXISTING',
    PRE_EXECUTION = 'PRE_EXECUTION'
}

export interface TheoryParty {
    counterpartyType: string | null;
    jurisdiction: string | null;
}

export interface NettingEngineCounterparty {
    entityId: string | null;
    jurisdiction: string | null;
    counterpartyClassification: string | null;
}
export interface NettingEngineTheory {
    counterpartyNameKnown: boolean | null;
    counterparty: NettingEngineCounterparty;
    agreement: NettingEngineAgreement | null;
    governingLaw: string | null;
    productTypeKnown: boolean | null;
    productsTraded: string[];
    counterpartyIsMultiBranch: boolean | null;
    counterpartyOffices: string[];
    sectionTenAApplies?: string | null;
}

export interface NettingEngineSubQuestion {
    question: string;
    value: DropdownOption | null;
    dropdownOptions: DropdownOption[];
    key: keyof NettingEngineTheory;
    counterpartyKey?: keyof NettingEngineCounterparty;
}

export interface NettingEngineSurvey {
    question: string;
    type: NettingEngineQuestionType;
    value: DropdownOption | DropdownOption[] | boolean | null;
    key: keyof NettingEngineTheory;
    dropdownOptions?: DropdownOption[];
    counterpartyKey?: keyof NettingEngineCounterparty;
    subQuestion?: NettingEngineSubQuestion;
    isMulti?: boolean;
    closeOnSelect?: boolean;
}

export interface NettingEngineDocumentSummaryQuestion extends NettingEngineSurvey {
    isHidden?: boolean;
}

export enum NettingEngineSummary {
    NO = 'Close out netting is not enforceable',
    MAYBE = 'Close out netting might be enforceable',
    YES = 'Close out netting is enforceable',
    PRODUCTS_COVERED = 'Close out netting is enforceable for the following products'
}

export enum ProductTypeCoveredEnum {
    YES = 'Yes',
    NO = 'No',
    UNCLEAR = 'Unclear'
}

export enum NettingEngineValidGoverningLaw {
    ENGLAND = 'England',
    FRANCE = 'France',
    IRELAND = 'Ireland',
    NEW_YORK = 'New York',
}

export interface NettingRequestInformation {
    counterpartyJurisdiction: string;
    governingLawJurisdiction: string;
    agreementType: NettingEngineAgreement;
    counterpartyType: string;
    productTypes: string[];
    branches: string[];
    sectionTenAApplies?: string;
}

export interface ProductTypeCovered {
    product: string;
    covered: ProductTypeCoveredEnum
}

export interface JurisdictionalNettingDetails {
    agreementCovered: string;
    counterpartyCovered: string;
    governingLawRecognised: string;
    closeOutNettingEnforceable: string;
    automaticEarlyTerminationRequired: string;
    productsCovered: ProductTypeCovered[];
    onShoreInsolvency: string;
    offShoreInsolvency: string;
    localLawIsValid: string | null;
}

export interface JurisdictionalNettingInformation {
    details: JurisdictionalNettingDetails;
    jurisdiction: string;
    counterpartyOffices: string[];
    summary: NettingEngineSummary;
    permittedToView: boolean;
    clientSignOffDetails: JurisdictionalNettingDetails | null;
    clientSignOffSummary: NettingEngineSummary;
}

export interface JurisdictionalAnswers {
    question: string;
    answer: JSX.Element | null;
    showIcon?: boolean;
}

export interface NettingResponseInformation {
    jurisdictionalInformation: JurisdictionalNettingInformation[];
    overallSummary: NettingEngineSummary;
    overallClientSummary: NettingEngineSummary;
    sectionTenImpactsDecision: boolean;
}

export interface CountriesRequiringFiltering {
    countryGeoId: string;
    westernLatitude?: number;
    easternLatitude?: number;
    northernLongitude?: number;
    southernLongitude?: number;
}

export const countriesRequiringFiltering: CountriesRequiringFiltering[] = [
    { countryGeoId: 'USA', northernLongitude: 49, westernLatitude: -129 },
    { countryGeoId: 'RUS', westernLatitude: 23, easternLatitude: 180 },
    { countryGeoId: 'NOR', westernLatitude: -5, southernLongitude: 57, northernLongitude: 72 },
    { countryGeoId: 'NLD', southernLongitude: 51 },
    { countryGeoId: 'FRA', southernLongitude: 42 },
    { countryGeoId: 'ESP', southernLongitude: 35 },
    { countryGeoId: 'NZL', southernLongitude: -48, westernLatitude: -166 },
    { countryGeoId: 'PRT', westernLatitude: -9 },
    { countryGeoId: 'ECU', westernLatitude: -81 },
    { countryGeoId: 'CHL', westernLatitude: -76 },
    { countryGeoId: 'ASM', northernLongitude: -14, easternLatitude: -169.20 },
    { countryGeoId: 'BVT', northernLongitude: -54 },
    { countryGeoId: 'FJI', northernLongitude: -16, easternLatitude: 180, westernLatitude: 177 },
    { countryGeoId: 'GUF', northernLongitude: 6, easternLatitude: -51 },
    { countryGeoId: 'GLP', southernLongitude: 15.5, easternLatitude: -60 },
    { countryGeoId: 'MTQ', northernLongitude: 15, easternLatitude: -60 },
    { countryGeoId: 'KIR', northernLongitude: 2, southernLongitude: 1.6, easternLatitude: 172 },
    { countryGeoId: 'MDV', northernLongitude: 3.7, southernLongitude: 1.6 },
    { countryGeoId: 'PCN', easternLatitude: -127, westernLatitude: -129 },
    { countryGeoId: 'REU', westernLatitude: 55 },
    { countryGeoId: 'PLW', southernLongitude: 6.8 },
    { countryGeoId: 'TUV', westernLatitude: 178.3 },
    { countryGeoId: 'TKL', northernLongitude: -9.1, southernLongitude: -9.6 },
    { countryGeoId: 'MUS', northernLongitude: -19, easternLatitude: 58 },
    { countryGeoId: 'MYT', northernLongitude: -12, southernLongitude: -13 },
    { countryGeoId: 'SYC', northernLongitude: -4.2, southernLongitude: -4.8, westernLatitude: 55 },
    { countryGeoId: 'MHL', northernLongitude: 7.6 },
    { countryGeoId: 'COK', northernLongitude: -18 },
    { countryGeoId: 'IOT', northernLongitude: -6.1 },
    { countryGeoId: 'ATF', northernLongitude: -48.4 },
    { countryGeoId: 'FSM', northernLongitude: 7.1, southernLongitude: 6.7, westernLatitude: 158 },
    { countryGeoId: 'SHN', northernLongitude: -15.8 },
    { countryGeoId: 'SJM', southernLongitude: 76 },
    { countryGeoId: 'UMI', easternLatitude: 177 }
];

export interface OpinionNettingEngineState {
    nettingPage: NettingPage;
    nettingEngineTheory: NettingEngineTheory;
    currentNettingEngineTheory: NettingEngineTheory | null;
    theoreticalResults: NettingResponseInformation | null;
    theoreticalQuestion: string | null;
    calculatingResults: boolean;
    surveyQuestionIndex: number;
    showSummary: boolean;
    leiModalOpen: boolean;
    isChangingQuestion: boolean;
    nettingAssumptionsModalOpen: boolean;
    enquiredOpinions: string[];
    isCustomConclusion: boolean;
    nettingEngineDocumentFilters: TableFilters;
    nettingEngineDocumentSort?: ColumnSort;
    nettingEngineDocumentPageSize: number;
    isLoading: boolean;
    nettingEngineDocuments: ArkDocument[];
    totalNettingEngineDocuments: number;
    nettingEngineDocumentPage: number;
    isOpening: boolean;
    savedNettingEngineDocument: NettingEngineTheory | null;
    currentNettingEngineDocument: NettingEngineTheory | null;
    calculatingExistingDocumentsResults: boolean;
    existingDocumentsResults: NettingResponseInformation | null;
    nettingEngineTableView: NettingEngineTableView;
    documentSummaryView: AnalysisView;
}
