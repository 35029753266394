import React from 'react';
import { isUndefined } from 'lodash/fp';

import styles from './AnnexPreview.module.scss';
import { AnnexDefinitionDB } from '../store';
import { AnnexCapsule } from './AnnexCapsule';
import { Scrollable } from '../../shared/scrollable/Scrollable';

interface AnnexPreviewProps {
    annexDefinitions: AnnexDefinitionDB[]
    documentName: string;
    duplicate: (annexDefinition: AnnexDefinitionDB) => void;
    select: (annexDefinitionId: number) => void;
    showHeader?: boolean;
    selectedIds?: number[];
}

export const AnnexPreview: React.FC<AnnexPreviewProps> = ({ annexDefinitions, documentName, duplicate, select, showHeader = true, selectedIds = [] }) => {

    if (annexDefinitions.length === 0) {
        return <div data-testid='no-definitions-warning' className={styles.annexDefinitionsWrapper} style={{ height: 'calc(100% - 56px)' }}>You currently have no annex definitions</div>;
    }

    return (
        <div data-testid='annex-preview-wrapper' className={styles.annexDefinitionsWrapper} style={{ height: `calc(100% - ${showHeader ? 88 : 56}px)` }}>
            {showHeader && <div data-testid='annex-preview-header' className={styles.documentName}>{documentName}</div>}
            <div className={styles.scrollableWrapper} style={{ height: `calc(100% - ${showHeader ? 22 : 0}px)` }}>
                <Scrollable>
                    {annexDefinitions.map((annexDefinition, index) => {
                        const isSelected = !isUndefined(annexDefinition.annexDefinitionId) && selectedIds.includes(annexDefinition.annexDefinitionId);
                        return (
                            <AnnexCapsule key={index} annexDefinition={annexDefinition} duplicate={() => duplicate(annexDefinition)} select={() => select(annexDefinition.annexDefinitionId!)} isSelected={isSelected} />
                        );
                    })}
                </Scrollable>
            </div>
        </div>
    );
};
