import React from 'react';
import classnames from 'classnames';

import styles from '../../Analytics.module.scss';
import { CustomTooltip, OverflowTooltip } from '../../../../shared/tooltip';
import { IconButton } from '../../../../shared/button/IconButton';
import { Add, Minus, Search } from '../../../../shared/icons';
import { LegendIndicator } from '../../store/types';

const { french, red, green, gold, ocean, darkFrench } = styles;

const legend: LegendIndicator[] = [
    { title: 'In Scope Company', backgroundColor: green },
    { title: 'Third Party Company', backgroundColor: gold },
    { title: 'DORA Function', backgroundColor: red },
    { title: 'Direct Link', backgroundColor: darkFrench, linkIndicator: true },
    { title: 'Indirect Link', backgroundColor: ocean, linkIndicator: true },
];

interface NetworkDiagramLegendProps {
    zoomIn: () => void;
    zoomOut: () => void;
    resetZoom: () => void;
    chartIsFullScreen: boolean;
}

export const NetworkDiagramLegend: React.FC<NetworkDiagramLegendProps> = ({ zoomIn, zoomOut, resetZoom, chartIsFullScreen }) => (
    <div className={styles.zoomLegendWrapper}>
        <div className={styles.legendWrapper} style={{ width: chartIsFullScreen ? '70%' : 'calc(100% - 110px)' }}>
            <div className={styles.legendLabel}>Key:</div>
            {legend.map(({ title, backgroundColor, linkIndicator }, index) => (
                <div className={styles.indicatorWrapper} key={index}>
                    <div className={classnames(styles.indicator, { [styles.linkIndicator]: !!linkIndicator })} style={{ backgroundColor }} />
                    <OverflowTooltip className={styles.indicatorLabel} overlayText={title} />
                </div>
            ))}
        </div>
        <div className={styles.zoomWrapper}>
            <CustomTooltip overlayText='Zoom In' placement='top'>
                <div>
                    <IconButton onClick={zoomIn} icon={Add} fontSize={20} withBackground />
                </div>
            </CustomTooltip>
            <CustomTooltip overlayText='Reset Zoom' placement='top'>
                <div>
                    <IconButton onClick={resetZoom} disabled={false} icon={Search} fontSize={20} withBackground iconFill={french} />
                </div>
            </CustomTooltip>
            <CustomTooltip overlayText='Zoom Out' placement='top'>
                <div>
                    <IconButton onClick={zoomOut} icon={Minus} fontSize={20} withBackground />
                </div>
            </CustomTooltip>
        </div>
    </div>
);
