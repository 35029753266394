import React from 'react';
import { Link } from 'react-router-dom';

import { dismissAllNotificationsStarted, dismissNotificationStarted, isSystemNotification, Notification, NotificationTypeId, SystemNotification, toggleSystemNotificationModal } from './store';
import styles from './Notifications.module.scss';
import { Delete, Attestation, Document, Notification as NotificationIcon, OfficeBuilding, PlaybookIcon, PullRequest, Robot } from '../../shared/icons';
import { Icon } from '../../shared/icon/Icon';
import { useAppDispatch } from '../../../hooks/react-redux';
import { ProfilePicture } from '../../shared/picture/ProfilePicture';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import Logo from '../../../assets/logos/Ark51_HORIBLACK&GRAD.png';

const { french, green, amber, red, primary } = styles;
interface NotificationsProps {
    notifications: Notification[];
    closeModal: () => void;
    width?: string;
    testId?: string;
}

const notificationIconAndLink = (notificationTypeId: NotificationTypeId, linkId: number, routeAdmin: boolean) => {
    switch (notificationTypeId) {
        case NotificationTypeId.ATTESTATION_CREATED:
            return { icon: Attestation, color: french, link: `/my-attestations/${linkId}` };
        case NotificationTypeId.ATTESTATION_SUBMITTED:
            return { icon: Attestation, color: green, link: `/admin/attestation/manager/${linkId}` };
        case NotificationTypeId.ATTESTATION_DEADLINE_UPDATED:
            return { icon: Attestation, color: french, link: `/my-attestations/${linkId}` };
        case NotificationTypeId.ATTESTATION_DEADLINE_APPROACHING:
            return { icon: Attestation, color: amber, link: routeAdmin ? `/admin/attestation/manager/${linkId}` : `/my-attestations/${linkId}` };
        case NotificationTypeId.ATTESTATION_DEADLINE_PASSED:
            return { icon: Attestation, color: red, link: routeAdmin ? `/admin/attestation/manager/${linkId}` : `/my-attestations/${linkId}` };
        case NotificationTypeId.DOCUMENT_UPLOADED:
            return { icon: Document, color: french, link: '/documents/my-documents' };
        case NotificationTypeId.EXTENSIVE_AI_EXTRACTION_FAILED:
            return { icon: Robot, color: red, link: '/documents/my-documents' };
        case NotificationTypeId.INITIAL_AI_EXTRACTION_COMPLETE:
            return { icon: Document, color: french, link: '/documents/my-documents/incomplete' };
        case NotificationTypeId.INITIAL_AI_EXTRACTION_FAILED:
        case NotificationTypeId.DOCUMENT_ANALYSIS_FAILED:
            return { icon: Robot, color: red, link: '/documents/my-documents/incomplete' };
        case NotificationTypeId.EXTENSIVE_AI_EXTRACTION_COMPLETE:
            return { icon: Document, color: french, link: `/documents/analysis/${linkId}` };
        case NotificationTypeId.OPINION_UPLOADED:
            return { icon: Document, color: french, link: '/opinions/my-opinions/map' };
        case NotificationTypeId.PLAYBOOK_QUERY_RAISED:
            return { icon: PlaybookIcon, color: french, link: `/admin/playbook/list/${linkId}` };
        case NotificationTypeId.PLAYBOOK_QUERY_REMINDER:
            return { icon: PlaybookIcon, color: french, link: `/admin/playbook/list/${linkId}` };
        case NotificationTypeId.PLAYBOOK_QUERY_RESPONSE:
            return { icon: PlaybookIcon, color: french, link: `/playbook/${linkId}` };
        case NotificationTypeId.PLAYBOOK_QUERY_RESOLVED:
            return { icon: PlaybookIcon, color: french, link: `/playbook/${linkId}` };
        case NotificationTypeId.PLAYBOOK_SUGGESTED_CHANGES:
            return { icon: PullRequest, color: french, link: `/admin/playbook/list/${linkId}/changes` };
        case NotificationTypeId.PLAYBOOK_SUGGESTED_CHANGES_CONVERSATION_RESPONSE:
            return { icon: PullRequest, color: french, link: routeAdmin ? `/admin/playbook/list/${linkId}/changes` : `/playbook/${linkId}` };
        case NotificationTypeId.PLAYBOOK_SUGGESTED_CHANGES_RESOLVED:
            return { icon: PullRequest, color: french, link: `/playbook/${linkId}` };
        default:
            return { icon: NotificationIcon, color: french, link: '/home' };
    }
};

interface NotificationPictureProps {
    icon: React.ElementType;
    color: string;
    picture?: Buffer;
    initials?: string;
}

const NotificationPicture: React.FC<NotificationPictureProps> = ({ icon, color, picture, initials }) => (
    <div className={styles.notificationTypeIcon}>
        <Icon icon={icon} color={color} fontSize={40} />
        <div className={styles.userIconWrapper}>
            <ProfilePicture profilePicture={picture} initials={initials} userIconSize={32} initialsFontSize={13} />
        </div>
    </div>
);

const SystemNotificationPicture: React.FC = () => (
    <div className={styles.notificationTypeIcon}>
        <Icon icon={OfficeBuilding} color={primary} fontSize={40} />
        <div className={styles.userIconWrapper}>
            <div className={styles.logoWrapper}>
                <img src={Logo} style={{ height: '23px' }} />
            </div>
        </div>
    </div>
);

interface UserNotificationProps {
    notification: Notification;
    dismissNotification: (notificationId: number) => void;
    dismissNotificationAndCloseModal: (notificationId: number) => void;
    openSystemNotification: (notification: SystemNotification) => void;
}

export const UserNotification: React.FC<UserNotificationProps> = ({ notification, dismissNotification, dismissNotificationAndCloseModal, openSystemNotification }) => {
    if (isSystemNotification(notification)) {
        const { notificationId, body: { description, title } } = notification;
        return (
            <div className={styles.notificationWrapper}>
                <SystemNotificationPicture />
                <button className={styles.systemNotification} onClick={() => openSystemNotification(notification)}>
                    <div className={styles.notificationTitleWrapper}>
                        <div className={styles.notificationTitle}>{title}</div>
                        <Scrollable height='38px'>
                            <div className={styles.notificationDescription}>
                                {description}
                            </div>
                        </Scrollable>
                    </div>
                </button>
                <button className={styles.dismissNotificationIcon} onClick={() => dismissNotification(notificationId)} >
                    <Icon icon={Delete} fontSize={16} />
                </button>
            </div>
        );
    }
    const { notificationId, body, notificationTypeId, routeAdmin, linkId, initials, picture } = notification;
    const { icon, color, link } = notificationIconAndLink(notificationTypeId, linkId, !!routeAdmin);
    return (
        <div className={styles.notificationWrapper}>
            <NotificationPicture color={color} icon={icon} picture={picture} initials={initials} />
            <Link to={link} onClick={() => dismissNotificationAndCloseModal(notificationId)} className={styles.notificationTitleAndDescriptionWrapper}>
                <div className={styles.notificationTitleWrapper}>
                    <div className={styles.notificationTitle}>{body.title}</div>
                    <Scrollable height='38px'>
                        <div className={styles.notificationDescription}>
                            {body.description}
                        </div>
                    </Scrollable>
                </div>
            </Link>
            <button className={styles.dismissNotificationIcon} onClick={() => dismissNotification(notificationId)} >
                <Icon icon={Delete} fontSize={16} />
            </button>
        </div>
    );
};

export const Notifications: React.FC<NotificationsProps> = ({ notifications, closeModal, width = '320px', testId }) => {
    const dispatch = useAppDispatch();
    const dismissNotification = (notificationId: number) => dispatch(dismissNotificationStarted(notificationId));
    const dismissAllNotifications = () => dispatch(dismissAllNotificationsStarted());
    const dismissNotificationAndCloseModal = (notificationId: number) => {
        dispatch(dismissNotificationStarted(notificationId));
        closeModal();
    };

    const openSystemNotification = (notification: SystemNotification) => {
        dispatch(toggleSystemNotificationModal(notification));
        closeModal();
    };

    return (
        <div className={styles.notificationsWrapper} style={{ width }} data-testid={`${testId}-notifications-wrapper`}>
            <div className={styles.notificationsTitleWrapper}>
                <div className={styles.notificationsTitle} data-testid={`${testId}-notifications-title`}>Notifications</div>
                {!!notifications.length && (
                    <button className={styles.dismissAllNotificationsButton} onClick={dismissAllNotifications} >
                        Dismiss All
                    </button>
                )}
            </div>
            <div className={styles.notifications}>
                <Scrollable>
                    {notifications.length ? notifications.map(notification => (
                        <UserNotification
                            notification={notification}
                            dismissNotification={dismissNotification}
                            dismissNotificationAndCloseModal={dismissNotificationAndCloseModal}
                            openSystemNotification={openSystemNotification}
                            key={notification.userNotificationId}
                        />
                    )) : (
                        <div className={styles.emptyNotifications}>No new notifications</div>
                    )}
                </Scrollable>
            </div>
        </div>
    );
};
