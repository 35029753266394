import classnames from 'classnames';
import React from 'react';

import styles from './loadingDots.module.scss';

type LoadingDotsProps = {
    wrapperHeight?: string,
    dotSize?: string
    numberOfDots?: number;
    ragColours?: boolean;
}

export const LoadingDots: React.FC<LoadingDotsProps> = ({ wrapperHeight = '33px', dotSize = '20px', numberOfDots = 5, ragColours = false }) => (
    <div className={styles.loadingDotsWrapper} style={{ height: wrapperHeight }}>
        {Array(numberOfDots).fill(1).map((val, index) => (<div className={classnames(styles.loadingDot, { [styles.ragLoadingDot]: ragColours })} style={{ width: dotSize, height: dotSize }} key={index} />))}
    </div>
);
