import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { ClientFeaturePermission, FeaturePermission } from '../admin/users/store';
import { getClientHasFeaturePermission, getUser, getUserHasOneFeaturePermissionNoAdmin } from '../auth/login/store';
import { ArkModule, setUnauthorisedModule, toggleUserNotPermittedModalOpen } from '../home/store';
import { OpinionsView } from '../opinions/my-opinions/store';
import { Icon as ArkIcon } from '../shared/icon/Icon';
import { HamburgerMenu } from '../shared/icons';
import { CollapsibleSideMenuItem } from './CollapsibleSideMenuItem';
import styles from './HomeWrapper.module.scss';
import { SideMenuItem } from './SideMenuItem';
import { getLeftMenuOpen, toggleLeftMenu } from './store';

export const LeftMenu: React.FC = () => {
    const { pathname } = useLocation();
    const [openParents, setOpenParents] = useState<string[]>([]);
    const isOpen = useAppSelector(getLeftMenuOpen);
    const user = useAppSelector(getUser);
    const dispatch = useAppDispatch();
    const hasDocumentModulePermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DOCUMENT_MODULE]));
    const hasOpinionModulePermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.OPINION_MODULE]));
    const hasDoraModulePermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DORA_MODULE]));
    const userHasDoraFeaturePermissions = useAppSelector(getUserHasOneFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS, FeaturePermission.DORA_READ_ONLY]));

    const getIsParentOpen = useCallback((id: string) => openParents.includes(id) || pathname.includes(id), [openParents, pathname]);

    const getIsSelected = useCallback((id: string) => pathname.includes(id), [pathname]);

    const toggleParentOpen = useCallback((id: string) => {
        if (getIsParentOpen(id)) {
            setOpenParents(openParents.filter(openId => openId !== id));
        } else {
            setOpenParents([...openParents, id]);
        }
    }, [getIsParentOpen, openParents]);

    const closeMenu = useCallback(() => dispatch(toggleLeftMenu(false)), [dispatch]);
    const openUserNotPermittedModule = useCallback(() => dispatch(toggleUserNotPermittedModalOpen(true)), [dispatch]);

    const openUnauthorisedModule = useCallback((module: ArkModule) => dispatch(setUnauthorisedModule(module)), [dispatch]);

    const handleDoraModuleClick = useCallback(() => userHasDoraFeaturePermissions ? closeMenu() : openUserNotPermittedModule(), [userHasDoraFeaturePermissions, closeMenu, openUserNotPermittedModule]);
    const handleDocumentModuleClick = useCallback(() => hasDocumentModulePermission ? closeMenu() : openUnauthorisedModule(ArkModule.DOCUMENT), [hasDocumentModulePermission, closeMenu, openUnauthorisedModule]);
    const handleOpinionModuleClick = useCallback(() => hasOpinionModulePermission ? closeMenu() : openUnauthorisedModule(ArkModule.OPINION), [hasOpinionModulePermission, closeMenu, openUnauthorisedModule]);

    const defaultOpinionView = useMemo(() => user?.userDefaults?.opinionPageView === OpinionsView.TABLE ? 'table' : 'map', [user]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.leftMenu}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 10 } }}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            onRequestClose={closeMenu}
        >
            <div data-testid='left-menu-wrapper' className={styles.leftMenuWrapper}>
                <div className={styles.sectionWrapper}>
                    <div className={styles.leftMenuButtonWrapper}>
                        <button data-testid='left-menu-button' className={styles.leftMenuButton} onClick={closeMenu}>
                            <ArkIcon icon={HamburgerMenu} fontSize={40} />
                        </button>
                    </div>
                    <SideMenuItem
                        label='Home'
                        selected={getIsSelected('home')}
                        onClick={closeMenu}
                        textAlign='left'
                        path='/home'
                        testId='home'
                    />
                    <CollapsibleSideMenuItem
                        isOpen={getIsParentOpen('documents')}
                        label='Documents'
                        toggleOpen={() => toggleParentOpen('documents')}
                        testId='documents'
                        disabled={!hasDocumentModulePermission}
                    >
                        <SideMenuItem
                            label='My Documents'
                            selected={getIsSelected('documents/my-documents') || getIsSelected('documents/analysis')}
                            onClick={handleDocumentModuleClick}
                            textAlign='left'
                            path='/documents/my-documents'
                            testId='my-documents'
                            isChild
                            disabled={!hasDocumentModulePermission}
                        />
                        <SideMenuItem
                            label='Search'
                            selected={getIsSelected('documents/search')}
                            onClick={handleDocumentModuleClick}
                            textAlign='left'
                            path='/documents/search'
                            testId='search-documents'
                            isChild
                            disabled={!hasDocumentModulePermission}
                        />
                        <SideMenuItem
                            label='Reporting'
                            selected={getIsSelected('documents/reporting')}
                            onClick={handleDocumentModuleClick}
                            textAlign='left'
                            path='/documents/reporting'
                            testId='document-reporting'
                            isChild
                            disabled={!hasDocumentModulePermission}
                        />
                        <SideMenuItem
                            label='Analytics'
                            selected={getIsSelected('documents/analytics')}
                            onClick={handleDocumentModuleClick}
                            textAlign='left'
                            path='/documents/analytics'
                            testId='document-analytics'
                            isChild
                            disabled={!hasDocumentModulePermission}
                        />
                    </CollapsibleSideMenuItem>
                    <CollapsibleSideMenuItem
                        isOpen={getIsParentOpen('opinions')}
                        label='Opinions'
                        toggleOpen={() => toggleParentOpen('opinions')}
                        testId='opinions'
                        disabled={!hasOpinionModulePermission}
                    >
                        <SideMenuItem
                            label='My Opinions'
                            selected={getIsSelected('opinions/my-opinions') || getIsSelected('opinions/analysis')}
                            onClick={handleOpinionModuleClick}
                            textAlign='left'
                            path={`/opinions/my-opinions/${defaultOpinionView}`}
                            testId='my-opinions'
                            isChild
                            disabled={!hasOpinionModulePermission}
                        />
                        <SideMenuItem
                            label='Reporting'
                            selected={getIsSelected('opinions/reporting')}
                            onClick={handleOpinionModuleClick}
                            textAlign='left'
                            path='/opinions/reporting'
                            testId='opinions-reporting'
                            isChild
                            disabled={!hasOpinionModulePermission}
                        />
                        <SideMenuItem
                            label='Analytics'
                            selected={getIsSelected('opinions/analytics')}
                            onClick={handleOpinionModuleClick}
                            textAlign='left'
                            path='/opinions/analytics'
                            testId='opinion-analytics'
                            isChild
                            disabled={!hasOpinionModulePermission}
                        />
                        <SideMenuItem
                            label='Netting Engine'
                            selected={getIsSelected('opinion-netting-engine')}
                            onClick={handleOpinionModuleClick}
                            textAlign='left'
                            path='/opinions/netting-engine'
                            testId='opinion-netting-engine'
                            isChild
                            disabled={!hasOpinionModulePermission}
                        />
                    </CollapsibleSideMenuItem>
                    <SideMenuItem
                        label='Playbooks'
                        selected={getIsSelected('playbook')}
                        onClick={closeMenu}
                        textAlign='left'
                        path='/playbook'
                        testId='playbooks'
                    />
                    <SideMenuItem
                        label='My Attestations'
                        selected={getIsSelected('my-attestations')}
                        onClick={closeMenu}
                        textAlign='left'
                        path='/my-attestations'
                        testId='my-attestations'
                    />
                    <SideMenuItem
                        label='Clause Library'
                        selected={getIsSelected('clause-library')}
                        onClick={closeMenu}
                        textAlign='left'
                        path='/clause-library'
                        testId='clause-library'
                    />
                    <CollapsibleSideMenuItem
                        isOpen={getIsParentOpen('dora')}
                        label='DORA'
                        toggleOpen={() => toggleParentOpen('dora')}
                        testId='dora'
                        disabled={!hasDoraModulePermission}
                    >
                        <SideMenuItem
                            label='Data Management'
                            selected={getIsSelected('dora/data-management')}
                            onClick={handleDoraModuleClick}
                            textAlign='left'
                            path='/dora/data-management'
                            testId='dora-data-management'
                            disabled={!hasDoraModulePermission || !userHasDoraFeaturePermissions}
                            isChild
                        />
                        <SideMenuItem
                            label='Analytics'
                            selected={getIsSelected('dora/analytics')}
                            onClick={handleDoraModuleClick}
                            textAlign='left'
                            path='/dora/analytics'
                            testId='dora-analytics'
                            disabled={!hasDoraModulePermission || !userHasDoraFeaturePermissions}
                            isChild
                        />
                        <SideMenuItem
                            label='Reporting'
                            selected={getIsSelected('dora/reporting')}
                            onClick={handleDoraModuleClick}
                            textAlign='left'
                            path='/dora/reporting'
                            testId='dora-reporting'
                            disabled={!hasDoraModulePermission || !userHasDoraFeaturePermissions}
                            isChild
                        />
                    </CollapsibleSideMenuItem>
                    <SideMenuItem
                        label='Negotiation Guides'
                        selected={getIsSelected('guides')}
                        onClick={closeMenu}
                        textAlign='left'
                        path='/guides'
                        testId='guides'
                    />
                </div>
            </div>
        </Modal>
    );
};
