import React from 'react';

import { AllMyDatasetDefinitions } from './AllMyDatasetDefinitions';

export interface MyDatasetsPreviewProps {
    datasetId: number;
}

export const MyDatasetsPreview: React.FC<MyDatasetsPreviewProps> = ({ datasetId }) => (
    <AllMyDatasetDefinitions title='Preview' datasetId={datasetId} isPreview />
);
