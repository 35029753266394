import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getAvailableDocumentNames } from '../../../admin/documents/store';
import { getSelectedDocumentNameIds, getSelectedTemplate, updateDocumentName } from '../store';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import styles from '../Reports.module.scss';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermission } from '../../../auth/login/store';

export const DocumentSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const selectedDocumentNameIds = useAppSelector(getSelectedDocumentNameIds);
    const selectedTemplate = useAppSelector(getSelectedTemplate);
    const selectedDatasetId = selectedDocumentNameIds ? allDocumentNames.find(({ documentNameId }) => documentNameId?.toString() === selectedDocumentNameIds[0])!.datasetId : null;
    const hasEditDocumentReportPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.EDIT_DOCUMENT_REPORT_TEMPLATES]));

    const isClearable = isNull(selectedTemplate) && hasEditDocumentReportPermission;

    const documentNameOptions = useMemo(() => allDocumentNames
        .filter(({ datasetId }) => datasetId)
        .filter(({ datasetId }) => isNull(selectedDatasetId) || selectedDatasetId === datasetId)
        .map(({ documentName, documentNameId }) => ({ value: documentNameId!.toString(), label: documentName })), [allDocumentNames, selectedDatasetId]
    );

    const selectedDocumentName = useMemo(() => {
        if (selectedDocumentNameIds) {
            if (selectedDocumentNameIds.length === 1 && !isClearable) {
                return documentNameOptions.filter(({ value }) => selectedDocumentNameIds.includes(value)).map(option => ({ ...option, disabled: true }));
            }
            return documentNameOptions.filter(({ value }) => selectedDocumentNameIds.includes(value));
        }
        return null;
    }, [documentNameOptions, selectedDocumentNameIds, isClearable]);

    const setSelectedDocumentNameId = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(dropdownValue)) {
            if ((dropdownValue as Options<DropdownOption>).length > 0) {
                dispatch(updateDocumentName((dropdownValue as Options<DropdownOption>).map(({ value }) => value)));
            } else if (isClearable) {
                dispatch(updateDocumentName(null));
            }
        } else {
            dispatch(updateDocumentName(null));
        }
    };

    return (
        <div className={styles.documentNameWrapper}>
            <div className={styles.documentNameHeader} data-testid='generate-report-select-document-label'>Select the documents you would like to generate a report for</div>
            <Dropdown
                value={selectedDocumentName}
                onChange={setSelectedDocumentNameId}
                options={documentNameOptions}
                placeholder={documentNameOptions.length ? 'Select...' : 'No documents available'}
                testId='generate-report-select-document'
                isClearable={isClearable}
                isMulti
                disabled={!isClearable}
            />
        </div>
    );
};
