import React from 'react';

import { Button } from '../../shared/button/Button';
import { AnnexDefinitionDB } from '../store';
import styles from './AnnexPreview.module.scss';
import { AnnexPreviewTable } from './AnnexPreviewTable';

interface AnnexCapsuleProps {
    annexDefinition: AnnexDefinitionDB;
    duplicate: () => void;
    select: () => void;
    isSelected: boolean;
    duplicateLabel?: string;
}

export const AnnexCapsule: React.FC<AnnexCapsuleProps> = ({ annexDefinition, duplicate, select, isSelected, duplicateLabel = 'Duplicate' }) => (
    <div className={styles.capsuleWrapper} onDoubleClick={select}>
        <AnnexPreviewTable isSelected={isSelected} annexDefinition={annexDefinition} />
        <div className={styles.buttonContainer}>
            <Button label={duplicateLabel} onClick={duplicate} />
            {!isSelected && <Button label='Select' onClick={select} />}
        </div>
    </div>
);
