import { Reducer } from 'redux';
import { set, flow } from 'lodash/fp';

import { AdminAnalyticsActionTypes, AdminAnalyticsChart, AdminAnalyticsState } from './types';

export const INITIAL_STATE: AdminAnalyticsState = {
    attestationProgress: [],
    fetchingAnalytics: [],
    analyticsError: null,
    pageUsageAnalytics: []
};

const addAnalyticsToFetchingArray = (state: AdminAnalyticsState, chart: AdminAnalyticsChart) => [...state.fetchingAnalytics, chart];
const removeAnalyticsFromFetchingArray = (state: AdminAnalyticsState, chart: AdminAnalyticsChart) => state.fetchingAnalytics.filter(analytics => analytics !== chart);

export const adminAnalyticsReducer: Reducer<AdminAnalyticsState> = (state = INITIAL_STATE, { payload, type }): AdminAnalyticsState => {
    switch (type) {
        case AdminAnalyticsActionTypes.FETCH_PAGE_USAGE_ANALYTICS_STARTED:
        case AdminAnalyticsActionTypes.FETCH_ATTESTATION_PROGRESS_ANALYTICS_STARTED:
            return set('fetchingAnalytics', addAnalyticsToFetchingArray(state, payload), state);
        case AdminAnalyticsActionTypes.FETCH_PAGE_USAGE_ANALYTICS_SUCCESSFUL:
        case AdminAnalyticsActionTypes.FETCH_ATTESTATION_PROGRESS_ANALYTICS_SUCCESSFUL: {
            const { key, chart, analytics } = payload;
            return flow(
                set(`${key}`, analytics),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case AdminAnalyticsActionTypes.FETCH_PAGE_USAGE_ANALYTICS_FAILED:
        case AdminAnalyticsActionTypes.FETCH_ATTESTATION_PROGRESS_ANALYTICS_FAILED: {
            const { error, chart } = payload;
            return flow(
                set('analyticsError', error),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        default:
            return state;
    }
};
