import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Delete, Document } from '../../shared/icons';
import { Action } from '../../shared/modal/ActionModal';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { entityFormatter, stringFormatter } from '../../shared/table/arkTableFormatters';
import {
    ArkDocument,
    clearPreExecutionDocumentTableFilters,
    getPreExecutionDocumentColumnSort,
    getPreExecutionDocumentFilters,
    getPreExecutionDocumentPageSize,
    getPreExecutionDocumentsPageNumber,
    getIsLoading,
    getTotalPreExecutionDocuments,
    preExecutionDocumentsPaginationNext,
    preExecutionDocumentsPaginationPrevious,
    openDocumentStarted,
    toggleDeleteConfirmationModal,
    getAllPreExecutionDocuments,
    fetchPreExecutionDocumentsStarted,
    setPreExecutionDocumentTableFilters,
    setPreExecutionDocumentsPageSize,
    setPreExecutionDocumentTableColumnSort
} from './store';

export const PreExecutionDocumentsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchPreExecutionDocumentsStarted()]);
    const preExecutionDocument = useAppSelector(getAllPreExecutionDocuments);
    const isLoading = useAppSelector(getIsLoading);

    const pageNumber = useAppSelector(getPreExecutionDocumentsPageNumber);
    const totalDocuments = useAppSelector(getTotalPreExecutionDocuments);
    const documentFilters = useAppSelector(getPreExecutionDocumentFilters);
    const columnSort = useAppSelector(getPreExecutionDocumentColumnSort);
    const pageSize = useAppSelector(getPreExecutionDocumentPageSize);
    const nextPage = useCallback(() => { dispatch(preExecutionDocumentsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(preExecutionDocumentsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setPreExecutionDocumentTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearPreExecutionDocumentTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setPreExecutionDocumentsPageSize(pageSize)); }, [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setPreExecutionDocumentTableColumnSort(columnSort)); }, [dispatch]);

    const openDocument = useCallback(({ documentId, location, mimeType }: ArkDocument) => { dispatch(openDocumentStarted(location, documentId, mimeType)); }, [dispatch]);

    const deleteDocument = useCallback((document: ArkDocument) => { dispatch(toggleDeleteConfirmationModal(document)); }, [dispatch]);

    const preExecutionDocumentActions = useCallback((data: ArkDocument) => {
        let options: Action[] = [
            { label: 'Open', icon: Document, onClick: () => openDocument(data), withSeparator: true },
            { label: 'Delete', icon: Delete, onClick: () => deleteDocument(data), isDelete: true }
        ];
        return options;
    }, [openDocument, deleteDocument]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', width: 0.30, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'documentName', header: 'Document', field: 'documentName', width: 0.25, component: 'tooltip', valueFormatter: stringFormatter, canFilter: true },
        { id: 'entityAName', header: 'Party A', field: 'entityAName', width: 0.20, valueFormatter: entityFormatter, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'entityBName', header: 'Party B', field: 'entityBName', width: 0.20, valueFormatter: entityFormatter, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'actions', header: '', field: '', width: 0.05, component: 'action', actions: preExecutionDocumentActions }
    ];

    return (
        <ArkTable
            testId='pre-execution-documents'
            rowData={preExecutionDocument}
            colDefs={columnDefs}
            isLoading={isLoading}
            page={pageNumber}
            total={totalDocuments}
            next={nextPage}
            previous={previousPage}
            filters={documentFilters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => openDocument(row as ArkDocument)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
        />
    );
};
