import React, { useCallback, useMemo } from 'react';
import { isEqual, max } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import styles from './Home.module.scss';
import { getFetchingAnalytics, getMyAttestationProgressAnalytics, HomePageTile, redirectMyAttestation } from './store';
import { Spinner } from '../shared/spinner/Spinner';
import { BarChart, BarChartAnalytics } from '../shared/analytics/BarChart';
import { getDeadline } from '../shared/table';
import { formatDate } from '../../utils/luxon';

const { french } = styles;

interface MyAttestationProgressProps {
    dimensions?: {
        height: number;
        width: number;
    }
    analyticsSpinner: boolean;
}

export const MyAttestationProgress: React.FC<MyAttestationProgressProps> = ({ dimensions, analyticsSpinner }) => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(getMyAttestationProgressAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(HomePageTile.MY_ATTESTATIONS) || analyticsSpinner, [fetchingAnalytics, analyticsSpinner]);

    const viewAttestation = useCallback((attestationInstanceId: number) => { dispatch(redirectMyAttestation(attestationInstanceId)); }, [dispatch]);

    const { height, width } = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 20;
            const svgHeight = dimensions.height - 40;
            return { height: svgHeight, width: svgWidth };
        }
        return { height: 0, width: 0 };
    }, [dimensions]);

    const barChartData: BarChartAnalytics[] = useMemo(() => data.map(({ name, attestationInstanceId, percentageComplete, deadline }) => {
        return {
            id: attestationInstanceId,
            name,
            percentageFill: percentageComplete,
            deadline: deadline || undefined
        };
    }), [data]);

    const findAttestation = useCallback((id: number) => data.find(({ attestationInstanceId }) => isEqual(id, attestationInstanceId)), [data]);

    const getPercentageFill = useCallback((id: number) => {
        const attestation = findAttestation(id);
        return attestation?.percentageComplete || 0;
    }, [findAttestation]);

    const getBackgroundColor = useCallback((id: number) => {
        const attestation = findAttestation(id);
        if (attestation) {
            return getDeadline(attestation.deadline).color;
        }
        return french;
    }, [findAttestation]);

    const getTooltipDetails = useCallback((instanceId: number) => {
        const attestation = data.find(({ attestationInstanceId }) => isEqual(instanceId, attestationInstanceId));
        if (attestation) {
            const { deadline, percentageComplete } = attestation;
            let tooltipRows = [`${percentageComplete}% Complete`];
            if (deadline) {
                tooltipRows.push(`Deadline: ${formatDate(deadline)}`);
            }
            const content = tooltipRows.map(row => `<tspan dy=12 x=10>${row}</tspan>`).join('');
            const tooltipHeight = (tooltipRows.length + 1) * 12;
            const tooltipWidth = max([20 + (max(tooltipRows.map(row => row.length))! * 7), 100])!;
            return { height: tooltipHeight, width: tooltipWidth, content };
        }
        return null;
    }, [data]);

    return showSpinner ?
        <Spinner /> : (
            <div className={styles.attestationProgressWrapper} data-testid='my-attestation-progress-wrapper'>
                <div className={styles.attestationsHeader}>My Attestations</div>
                <BarChart
                    data={barChartData}
                    testId='my-attestation-progress'
                    height={height}
                    width={width}
                    getPercentageFill={getPercentageFill}
                    getBackgroundColor={getBackgroundColor}
                    getTooltipDetails={getTooltipDetails}
                    onDblClick={viewAttestation}
                />
            </div>
        );
};
