import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { clearFunctionsTableFilters, functionsPaginationNext, functionsPaginationPrevious, setFunctionsPageSize, setFunctionsTableColumnSort, setFunctionsTableFilters, getDoraFunctionDropdownOptions, getAllDoraFunctions, getIsFetchingFunctions, getDoraFunctionsPageNumber, getTotalDoraFunctions, getDoraFunctionsFilters, getDoraFunctionsColumnSort, getDoraFunctionsPageSize, DoraFunction, toggleSelectedDoraFunctionModal, toggleDeleteFunctionConfirmationModal, fetchDoraFunctionUsageStarted } from './store';
import { Action } from '../../shared/modal/ActionModal';
import { CheckList, Delete, LongText } from '../../shared/icons';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { FeaturePermission } from '../../admin/users/store';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { HEIGHT_OF_THE_WIZARD } from '../my-companies/MyCompaniesInScopeForDora';

export const DoraFunctionsTable: React.FC = () => {
    const [, screenHeight] = useWindowResize();

    const dispatch = useAppDispatch();
    const doraFunctions = useAppSelector(getAllDoraFunctions);
    const isLoading = useAppSelector(getIsFetchingFunctions);
    const pageNumber = useAppSelector(getDoraFunctionsPageNumber);
    const totalCompanies = useAppSelector(getTotalDoraFunctions);
    const functionFilters = useAppSelector(getDoraFunctionsFilters);
    const columnSort = useAppSelector(getDoraFunctionsColumnSort);
    const pageSize = useAppSelector(getDoraFunctionsPageSize);
    const doraFunctionOptions = useAppSelector(getDoraFunctionDropdownOptions);

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const setSelectedDoraFunction = useCallback((data: DoraFunction) => dispatch(toggleSelectedDoraFunctionModal(data)), [dispatch]);

    const nextPage = useCallback(() => dispatch(functionsPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(functionsPaginationPrevious()), [dispatch]);

    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setFunctionsTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearFunctionsTableFilters()), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setFunctionsTableColumnSort(columnSort)), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setFunctionsPageSize(pageSize)), [dispatch]);

    const toggleDeleteFunction = useCallback((data: DoraFunction) => {
        dispatch(toggleDeleteFunctionConfirmationModal({ functionName: data.name, functionId: data.doraFunctionId! }));
        dispatch(fetchDoraFunctionUsageStarted(data.doraFunctionId!));
    }, [dispatch]);

    const actions = useCallback((data: DoraFunction): Action[] => {
        const options: Action[] = [{ label: 'Open', icon: CheckList, onClick: () => setSelectedDoraFunction(data) }];
        if (hasDoraFullAccessPermission && data.systemFunction !==1) {
            options.push({ label: 'Delete', icon: Delete, onClick: () => toggleDeleteFunction(data) });
        }
        return options;
    }, [setSelectedDoraFunction, hasDoraFullAccessPermission, toggleDeleteFunction]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'name', header: 'Name', field: 'name', width: 0.80, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'description', header: 'Description', field: 'description', width: 0.15, component: 'iconTooltip', icon: LongText },
        { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
    ];

    const tableHeight = useMemo(() => (screenHeight * 0.75) - HEIGHT_OF_THE_WIZARD, [screenHeight]);

    const filterDropdownOptions = useMemo(() => ({
        name: doraFunctionOptions
    }), [doraFunctionOptions]);

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={doraFunctions}
            testId='dora-functions'
            isLoading={isLoading}
            page={pageNumber}
            total={totalCompanies}
            next={nextPage}
            previous={previousPage}
            filters={functionFilters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => setSelectedDoraFunction(row as DoraFunction)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
            filterDropdownOptions={filterDropdownOptions}
            height={tableHeight}
        />
    );
};
