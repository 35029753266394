import React, { useState, useMemo, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { formatDate, DATE_FORMAT } from '../../../../utils/luxon';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import { Toggle } from '../../../shared/toggle';
import { confirmScheduleDetails, Scheduler, SchedulerAction, SendEmailContent, getScheduledDetailsConfirmed, CreateAttestationContent, getScheduledActionUpdated } from '../store';
import styles from '../Workflow.module.scss';
import { ConfirmAttestation } from './ConfirmAttestation';
import { ConfirmEmail } from './ConfirmEmail';

interface ConfirmProps {
    event: Scheduler;
    testId: string;
    isEditing: boolean;
}

export const Confirm: React.FC<ConfirmProps> = ({ event, testId, isEditing }) => {
    const [fieldOpen, setFieldOpen] = useState<boolean>(false);
    const fieldOpenIcon = fieldOpen ? CaretDown : CaretSide;
    const toggleSection = () => setFieldOpen(!fieldOpen);

    const dispatch = useAppDispatch();

    const isConfirmed = useAppSelector(getScheduledDetailsConfirmed);
    const showUpdatedMessage = useAppSelector(getScheduledActionUpdated);
    const warningMessage = useMemo(() => showUpdatedMessage ? 'The changes you have made to this event will take effect immediately after saving' : '', [showUpdatedMessage]);

    const { action, content, firstDate, interval, intervalValue, repeat } = event;

    const summary = useMemo(() => `You have selected to ${action === SchedulerAction.SEND_EMAIL ? 'send an email' : 'create an attestation'} on: ${formatDate(firstDate!, DATE_FORMAT)}${repeat ? `, this will repeat every ${intervalValue} ${interval}` : ''}`, [action, firstDate, interval, intervalValue, repeat]);

    const confirmContent = useMemo(() => {
        switch (action) {
            case SchedulerAction.CREATE_ATTESTATION:
                return <ConfirmAttestation content={content as CreateAttestationContent} testId={testId} />;
            case SchedulerAction.SEND_EMAIL:
                return <ConfirmEmail content={content as SendEmailContent} testId={testId} />;
            default:
                return null;
        }
    }, [action, content, testId]);

    const collapseLabel = useCallback(() => fieldOpen ? 'Collapse content' : 'View content', [fieldOpen]);
    const toggleScheduleDetailsConfirmed = useCallback((checked: boolean) => dispatch(confirmScheduleDetails(checked)), [dispatch]);

    return (
        <div className={styles.confirmTabWrapper} data-testid={`${testId}-confirm-tab-wrapper`}>
            <div className={styles.summaryWrapper} data-testid={`${testId}-confirm-summary-wrapper`}>
                <div className={styles.summaryLabel} data-testid={`${testId}-confirm-summary-label`}>Event summary: </div>
                <div className={styles.summaryContent} data-testid={`${testId}-confirm-summary`}>{summary}</div>
            </div>
            <div className={styles.collapseWrapper} data-testid={`${testId}-confirm-summary-collapse-wrapper`}>
                <div className={styles.collapseIconWrapper} onClick={toggleSection}>
                    <div className={styles.collapseSectionLabel} data-testid={`${testId}-confirm-summary-collapse-label`}>{collapseLabel()}</div>
                    <Icon icon={fieldOpenIcon} fontSize={15} />
                </div>
                {fieldOpen &&
                    <div className={styles.collapseContent}>{confirmContent}</div>
                }
            </div>
            <div className={styles.toggleWrapper} data-testid={`${testId}-confirm-summary-details-toggle-wrapper`}>
                <div className={styles.toggleLabel} data-testid={`${testId}-confirm-summary-details-toggle-label`}>Are these details correct?</div>
                <Toggle checked={isConfirmed} onChange={toggleScheduleDetailsConfirmed} testId={`${testId}-confirm-summary-details`} disabled={!isEditing} />
            </div>
            <div className={styles.updatedWarningMessage}>{warningMessage}</div>
        </div>
    );
};
