import download from 'downloadjs';
import { flow, isEqual, isNull, isUndefined, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, debounce, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { assertBaseOpinion, deleteOpinion, downloadAllOpinions, downloadOpinion, downloadOpinionSummary, enquireOpinion, fetchAllIncompleteOpinions, fetchAllOpinions, fetchOpinionsByJurisdiction, getOpinionById, openOpinion, openOpinionSummary, updateOpinionDetails, uploadOpinions, uploadSupportOpinion } from '../../../../services/opinion';
import { getDocumentUrl } from '../../../../utils/getDocumentUrl';
import { dotsToDashes } from '../../../../utils/regex-utils';
import { fetchAllDropdownListsStarted } from '../../../admin/dropdown-lists/store';
import { fetchAllEntitiesStarted } from '../../../admin/entity/store';
import { OpinionCommissionedBy } from '../../../admin/opinions/store';
import { ClientFeaturePermission } from '../../../admin/users/store';
import { CustomFilters, FilterTable, getAllOpinionCustomFilters, getClientHasFeaturePermission, getPathname, OpinionCustomFilters } from '../../../auth/login/store';
import { AnalysisView } from '../../../documents/my-documents/store';
import { addOrDeleteUserCustomFilter, getCurrentUserCustomFilters, updateCustomFiltersStarted } from '../../../home/store';
import { TableFilters, TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { scopeFormatter } from '../../../shared/table/arkTableFormatters';
import { openOpinionInstanceStarted, toggleSupportOpinionUploadModal, updateInstanceAdditionalOpinionIds } from '../../instances/store/actions';
import {
    assertPotentialBaseOpinionFailed,
    assertPotentialBaseOpinionStarted,
    assertPotentialBaseOpinionSuccessful,
    clearOpinionTableFilters,
    deleteOpinionFailed,
    deleteOpinionStarted,
    deleteOpinionSuccessful,
    downloadAllOpinionsFailed,
    downloadAllOpinionsStarted,
    downloadAllOpinionsSuccessful,
    downloadOpinionFailed,
    downloadOpinionStarted,
    downloadOpinionSuccessful,
    downloadOpinionSummaryFailed,
    downloadOpinionSummaryStarted,
    downloadOpinionSummarySuccessful,
    enquireOpinionFailed,
    enquireOpinionStarted,
    enquireOpinionSuccessful,
    fetchAllOpinionsByJurisdictionFailed,
    fetchAllOpinionsByJurisdictionStarted,
    fetchAllOpinionsByJurisdictionSuccessful,
    fetchIncompleteOpinionsFailed,
    fetchIncompleteOpinionsStarted,
    fetchIncompleteOpinionsSuccessful,
    fetchOpinionsFailed,
    fetchOpinionsStarted,
    fetchOpinionsSuccessful,
    openAdditionalOpinionsFailed,
    openAdditionalOpinionsStarted,
    openAdditionalOpinionsSuccessful,
    openIncompleteOpinionFailed,
    openIncompleteOpinionStarted,
    openIncompleteOpinionSuccessful,
    openOpinionAndInstance,
    openOpinionFailed,
    openOpinionStarted,
    openOpinionSuccessful,
    openOpinionSummaryFailed,
    openOpinionSummaryStarted,
    openOpinionSummarySuccessful,
    opinionDetailsHaveUpdated,
    setOpinionCustomFilterHasUpdated,
    setOpinionTableFilters,
    setPotentialBaseOpinion,
    setSelectedOpinionCustomFilterId,
    setUploadOpinions,
    toggleOpinionAnalysisView,
    toggleOpinionDetailsModal,
    toggleOpinionsTableView,
    toggleOpinionsView,
    updateOpinionCustomFilters,
    updateOpinionDetailsFailed,
    updateOpinionDetailsSuccessful,
    uploadOpinionFailed,
    uploadOpinionSuccessful,
    uploadSupportOpinionFailed,
    uploadSupportOpinionSuccessful
} from './actions';
import { getAdditionalOpinions, getFilesToUpload, getIncompleteOpinion, getIncompleteOpinionColumnSort, getIncompleteOpinionFilters, getIncompleteOpinionPageSize, getIncompleteOpinionsPageNumber, getOpinionColumnSort, getOpinionFilters, getOpinionId, getOpinionPageSize, getOpinionsPageNumber, getOpinionsTableView, getPotentialBaseOpinion, getSelectedIncompleteOpinions, getSelectedOpinionCustomFilterId } from './selectors';
import { ArkMapOpinion, ArkOpinion, MyOpinionActionTypes, OpinionsTableView, OpinionsView } from './types';

const stripFilterValues = (filter: CustomFilters) => flow(
    unset('filterName'),
    unset('filterTable'),
    unset('id')
)(filter);

const opinionInitialFilter = (id: string, values: TableFilters): Omit<OpinionCustomFilters, 'filterName'> => ({ filterTable: FilterTable.OPINION, id, jurisdiction: values.jurisdiction ? values.jurisdiction : { text: '', dropdown: null }, focus: values.focus ? values.focus : { text: '', dropdown: null }, bespoke: values.bespoke ? values.bespoke : { text: '', dropdown: null }, scope: values.scope ? values.scope : { text: '', dropdown: null }, commissionedBy: values.commissionedBy ? values.commissionedBy : { text: '', dropdown: null } });

export function* uploadFiles(files: File[]) {
    let request = new FormData();
    const numberOfFilesToUpload = files.reduce((acc, cur) => {
        const updatedTotalFileSize = acc.total + cur.size;
        if (updatedTotalFileSize < MAX_UPLOAD_SIZE) {
            return { total: updatedTotalFileSize, numberOfFiles: acc.numberOfFiles + 1 };
        }
        return acc;
    }, { total: 0, numberOfFiles: 0 }).numberOfFiles;
    const filesToUpload = files.slice(0, numberOfFilesToUpload);
    filesToUpload.forEach(file => request.append('files', file));
    const uploadedDocuments: ArkOpinion[] = yield call(uploadOpinions, request);
    files.splice(0, numberOfFilesToUpload);
    yield put(setUploadOpinions(files));
    return uploadedDocuments;
}

// 50MB
const MAX_UPLOAD_SIZE = 5e7;

export function* attemptUpload() {
    try {
        const allFiles: File[] = yield select(getFilesToUpload);
        let files = [...allFiles];
        let opinions: ArkOpinion[] = [];
        while (files.length) {
            const uploadedOpinions: ArkOpinion[] = yield call(uploadFiles, files);
            opinions = [...opinions, ...uploadedOpinions];
            const updatedAllFiles: File[] = yield select(getFilesToUpload);
            files = [...updatedAllFiles];
        }
        yield put(uploadOpinionSuccessful(opinions));
        if (opinions.length) {
            yield put(toggleOpinionDetailsModal(true));
            yield put(openIncompleteOpinionStarted(opinions[0]));
        }
    } catch (e) {
        yield put(uploadOpinionFailed((e as Error).message));
        toast.error('There was a problem uploading your document. Please try again.');
    }
}

function* uploadWatcher() {
    yield takeLatest(MyOpinionActionTypes.UPLOAD_OPINION_STARTED, attemptUpload);
}

export function* attemptUploadSupportOpinion() {
    try {
        let request = new FormData();
        const files: File[] = yield select(getFilesToUpload);
        const baseOpinionId: number = yield select(getOpinionId);
        request.append('file', files[0]);
        request.append('fileInfo', JSON.stringify({ baseOpinionId }));
        const { opinionId }: { opinionId: number; } = yield call(uploadSupportOpinion, request);
        yield put(uploadSupportOpinionSuccessful());
        yield put(toggleSupportOpinionUploadModal(false));
        const additionalOpinions: ArkOpinion[] = yield select(getAdditionalOpinions);
        const currentAdditionalOpinionIds = additionalOpinions.map(({ opinionId }) => opinionId);
        const updatedAdditionalOpinionIds = [...currentAdditionalOpinionIds, opinionId];
        yield put(updateInstanceAdditionalOpinionIds(updatedAdditionalOpinionIds));
        yield put(openAdditionalOpinionsStarted(updatedAdditionalOpinionIds));
    } catch (e) {
        yield put(uploadSupportOpinionFailed((e as Error).message));
        toast.error('There was a problem uploading your document. Please try again.');
    }
}

function* uploadSupportOpinionWatcher() {
    yield takeLatest(MyOpinionActionTypes.UPLOAD_SUPPORT_OPINION_STARTED, attemptUploadSupportOpinion);
}

export function* attemptFetchAllOpinions({ payload }: ReturnType<typeof fetchOpinionsStarted>) {
    try {
        const filters: TableFilters = yield select(getOpinionFilters);
        const pageSize: number = yield select(getOpinionPageSize);
        const columnSort: ColumnSort | undefined = yield select(getOpinionColumnSort);
        const { total, opinions }: { total: number; opinions: ArkOpinion[]; } = yield call(fetchAllOpinions, { pageNumber: payload, filters, pageSize, columnSort });
        yield put(fetchOpinionsSuccessful(opinions, total, payload));
    } catch (e) {
        yield put(fetchOpinionsFailed((e as Error).message));
        toast.error('Unable to fetch the latest list of opinions. Please try again.');
    }
}

function* fetchAllOpinionsWatcher() {
    yield takeEvery(MyOpinionActionTypes.FETCH_OPINIONS_STARTED, attemptFetchAllOpinions);
}

export function* attemptOpenIncompleteOpinion({ payload }: ReturnType<typeof openIncompleteOpinionStarted>) {
    try {
        const { mimeType, location } = payload;
        const opinion: Blob = yield call(openOpinion, { location });
        const opinionUrl: string = yield call(getDocumentUrl, mimeType, opinion);
        yield put(openIncompleteOpinionSuccessful(opinionUrl, payload));
    } catch (e) {
        yield put(openIncompleteOpinionFailed((e as Error).message));
        toast.error('Unable to open document. Please try again.');
    }
}

function* openIncompleteOpinionWatcher() {
    yield takeEvery(MyOpinionActionTypes.OPEN_INCOMPLETE_OPINION_STARTED, attemptOpenIncompleteOpinion);
}

export function* checkOpinionDetailsUpdated() {
    try {
        const incompleteOpinions: ArkOpinion[] = yield select(getSelectedIncompleteOpinions);
        const currentOpinion: ArkOpinion = yield select(getIncompleteOpinion);
        const unchangedCurrentOpinion = incompleteOpinions.find(({ opinionId }) => opinionId === currentOpinion.opinionId);
        const opinionDetailsUpdated = !isUndefined(unchangedCurrentOpinion) && !isEqual(unchangedCurrentOpinion, currentOpinion);
        yield put(opinionDetailsHaveUpdated(opinionDetailsUpdated));
    } catch (e) {
        toast.error('We encountered a problem checking the document details completion.');
    }
}

function* detailsUpdatedWatcher() {
    yield debounce(1000, MyOpinionActionTypes.INCOMPLETE_OPINION_UPDATE_VALUE, checkOpinionDetailsUpdated);
}

function* confirmCompletion(opinion: ArkOpinion, opinionComplete: boolean) {
    const selectedIncompleteOpinions: ArkOpinion[] = yield select(getSelectedIncompleteOpinions);
    let opinions = selectedIncompleteOpinions.filter(({ opinionId }) => opinion.opinionId !== opinionId);
    if (!opinionComplete && selectedIncompleteOpinions.length > 1) {
        // If the opinion is still not complete, add it to the end of the array of recently uploaded incomplete opinions
        opinions.push(opinion);
    }
    if (opinions.length) {
        yield put(openIncompleteOpinionStarted(opinions[0]));
    } else {
        yield put(toggleOpinionDetailsModal(false));
    }
    yield put(updateOpinionDetailsSuccessful(opinions));
    const pathname: string = yield select(getPathname);
    if (pathname.includes('incomplete')) {
        yield put(fetchIncompleteOpinionsStarted());
    } else if (pathname.includes('map')) {
        yield put(fetchAllOpinionsByJurisdictionStarted());
    } else {
        yield put(fetchOpinionsStarted());
    }
}

export function* attemptUpdateOpinionDetails() {
    try {
        const opinionToUpdate: ArkOpinion = yield select(getIncompleteOpinion);
        const { opinion, opinionComplete, potentialBaseOpinion }: { opinion: ArkOpinion; opinionComplete: boolean; potentialBaseOpinion: ArkOpinion | null; } = yield call(updateOpinionDetails, opinionToUpdate);
        if (!isNull(potentialBaseOpinion)) {
            yield put(setPotentialBaseOpinion(potentialBaseOpinion));
        } else {
            yield call(confirmCompletion, opinion, opinionComplete);
        }
    } catch (e) {
        const error = e as Error;
        yield put(updateOpinionDetailsFailed(error.message));
        toast.error('Unable to confirm your opinion details. Please try again.');
    }
}

function* updateOpinionDetailsWatcher() {
    yield takeLeading(MyOpinionActionTypes.UPDATE_OPINION_DETAILS_STARTED, attemptUpdateOpinionDetails);
}

export function* attemptAssertBaseOpinion({ payload }: ReturnType<typeof assertPotentialBaseOpinionStarted>) {
    try {
        const opinionToUpdate: ArkOpinion = yield select(getIncompleteOpinion);
        const baseOpinion: ArkOpinion = yield select(getPotentialBaseOpinion);
        const baseOpinionId = baseOpinion.opinionId;
        const updateOpinionId = opinionToUpdate.opinionId;
        const { opinionComplete }: { opinionComplete: boolean; } = yield call(assertBaseOpinion, { baseOpinionId, updateOpinionId, isBaseOpinion: payload });
        yield put(assertPotentialBaseOpinionSuccessful());
        yield call(confirmCompletion, opinionToUpdate, opinionComplete);
    } catch (e) {
        const error = e as Error;
        yield put(assertPotentialBaseOpinionFailed(error.message));
    }
}

function* assertBaseOpinionWatcher() {
    yield takeLeading(MyOpinionActionTypes.ASSERT_POTENTIAL_BASE_OPINION_STARTED, attemptAssertBaseOpinion);
}

export function* skipIncompleteOpinionDetails() {
    try {
        const currentOpinion: ArkOpinion = yield select(getIncompleteOpinion);
        const selectedIncompleteOpinions: ArkOpinion[] = yield select(getSelectedIncompleteOpinions);
        let opinions = selectedIncompleteOpinions.filter(({ opinionId }) => opinionId !== currentOpinion.opinionId);
        opinions.push(currentOpinion);
        yield put(openIncompleteOpinionStarted(opinions[0]));
        yield put(updateOpinionDetailsSuccessful(opinions));
    } catch (e) {
        yield put(updateOpinionDetailsFailed((e as Error).message));
    }
}

function* skipIncompleteOpinionWatcher() {
    yield takeEvery(MyOpinionActionTypes.SKIP_INCOMPLETE_OPINION, skipIncompleteOpinionDetails);
}

export function* attemptFetchAllIncompleteOpinions({ payload }: ReturnType<typeof fetchIncompleteOpinionsStarted>) {
    try {
        const filters: TableFilters = yield select(getIncompleteOpinionFilters);
        const pageSize: number = yield select(getIncompleteOpinionPageSize);
        const columnSort: ColumnSort | undefined = yield select(getIncompleteOpinionColumnSort);
        const { total, incompleteOpinions }: { total: number; incompleteOpinions: ArkOpinion[]; } = yield call(fetchAllIncompleteOpinions, { pageNumber: payload, filters, pageSize, columnSort });
        yield put(fetchIncompleteOpinionsSuccessful(incompleteOpinions, total, payload));
    } catch (e) {
        yield put(fetchIncompleteOpinionsFailed((e as Error).message));
        toast.error('Unable to fetch your incomplete opinions. Please try again.');
    }
}

function* allIncompleteDocumentsWatcher() {
    yield takeEvery(MyOpinionActionTypes.FETCH_INCOMPLETE_OPINIONS_STARTED, attemptFetchAllIncompleteOpinions);
}

export function* checkOpinionPermission(commissionedBy: string | null | OpinionCommissionedBy) {
    const hasViewISDAOpinionPermission: boolean = yield select(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ISDA]));
    const hasViewICMAOpinionPermission: boolean = yield select(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ICMA]));
    const hasViewISLAOpinionPermission: boolean = yield select(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ISLA]));
    let hasOpinionViewPermission = false;
    switch (commissionedBy) {
        case OpinionCommissionedBy.ISDA:
            hasOpinionViewPermission = hasViewISDAOpinionPermission;
            break;
        case OpinionCommissionedBy.ISLA:
            hasOpinionViewPermission = hasViewISLAOpinionPermission;
            break;
        case OpinionCommissionedBy.ICMA:
            hasOpinionViewPermission = hasViewICMAOpinionPermission;
            break;
        default:
            hasOpinionViewPermission = false;
    }
    return hasOpinionViewPermission;
}

export function* attemptOpenOpinion({ payload }: ReturnType<typeof openOpinionStarted>) {
    try {
        const { location, mimeType, opinionId } = payload;
        const opinion: ArkOpinion = yield call(getOpinionById, { opinionId });
        const blob: Blob = yield call(openOpinion, { location });
        const url: string = yield call(getDocumentUrl, mimeType, blob);
        yield put(openOpinionSuccessful(url, opinion));
        const hasOpinionViewPermission: boolean = yield call(checkOpinionPermission, opinion.commissionedBy);
        if (!hasOpinionViewPermission && opinion.bespoke === 0) {
            yield put(toggleOpinionAnalysisView(AnalysisView.DATASET));
        }
        yield put(openOpinionInstanceStarted());
        yield put(fetchAllDropdownListsStarted());
        yield put(fetchAllEntitiesStarted());
    } catch (e) {
        yield put(openOpinionFailed((e as Error).message));
        toast.error('Unable to open opinion. Please try again.');
    }
}

function* openOpinionWatcher() {
    yield takeEvery(MyOpinionActionTypes.OPEN_OPINION_STARTED, attemptOpenOpinion);
}

export function* attemptOpenOpinionSummary({ payload }: ReturnType<typeof openOpinionSummaryStarted>) {
    try {
        const blob: Blob = yield call(openOpinionSummary, { opinionSummaryId: payload });
        const url: string = yield call(getDocumentUrl, 'application/pdf', blob);
        yield put(openOpinionSummarySuccessful(url));
    } catch (e) {
        yield put(openOpinionSummaryFailed((e as Error).message));
        toast.error('Unable to open opinion summary. Please try again.');
    }
}

function* openOpinionSummaryWatcher() {
    yield takeEvery(MyOpinionActionTypes.OPEN_OPINION_SUMMARY_STARTED, attemptOpenOpinionSummary);
}

export function* attemptOpenOpinionAndInstance({ payload }: ReturnType<typeof openOpinionAndInstance>) {
    try {
        const { opinionId, datasetInstanceId } = payload;
        yield put(fetchOpinionsStarted());
        const { mimeType, location, dateOfOpinion }: ArkOpinion = yield call(getOpinionById, { opinionId: parseInt(opinionId) });
        if (dateOfOpinion) {
            yield put(openOpinionStarted(location, mimeType, parseInt(opinionId), datasetInstanceId));
        }
    } catch (e) {
        toast.error('Unable to open opinion. Please try again.');
        yield put(push('/home'));
    }
}

function* openOpinionAndInstanceWatcher() {
    yield takeEvery(MyOpinionActionTypes.OPEN_OPINION_AND_INSTANCE, attemptOpenOpinionAndInstance);
}

export function* attemptOpinionDownload({ payload }: ReturnType<typeof downloadOpinionStarted>) {
    try {
        const { description, mimeType, location } = payload;
        const opinion: Blob = yield call(downloadOpinion, { location });
        const formattedDescription = dotsToDashes(description);
        yield call(download, opinion, formattedDescription, mimeType);
        yield put(downloadOpinionSuccessful());
    } catch (e) {
        yield put(downloadOpinionFailed((e as Error).message));
        toast.error('Unable to download opinion. Please try again.');
    }
}

export function* attemptOpinionSummaryDownload({ payload }: ReturnType<typeof downloadOpinionSummaryStarted>) {
    try {
        const { opinionSummaryId, jurisdiction, scope, focus } = payload;
        if (!isNull(opinionSummaryId) && !isUndefined(opinionSummaryId)) {
            const opinion: Blob = yield call(downloadOpinionSummary, { opinionSummaryId });
            const filename = `${jurisdiction!} (${focus}) ${scopeFormatter(scope)} Opinion Summary`;
            yield call(download, opinion, filename, 'application/pdf');
        }
        yield put(downloadOpinionSummarySuccessful());
    } catch (e) {
        yield put(downloadOpinionSummaryFailed((e as Error).message));
        toast.error('Unable to download opinion summary. Please try again.');
    }
}

export function* attemptDeleteOpinion({ payload }: ReturnType<typeof deleteOpinionStarted>) {
    try {
        const opinionsTableView: OpinionsTableView = yield select(getOpinionsTableView);
        const incomplete = opinionsTableView === OpinionsTableView.INCOMPLETE;
        if (!incomplete) {
            const pageNumber: number = yield select(getOpinionsPageNumber);
            const filters: TableFilters = yield select(getOpinionFilters);
            const pageSize: number = yield select(getOpinionPageSize);
            const columnSort: ColumnSort | undefined = yield select(getOpinionColumnSort);
            const { total, opinions }: { total: number; opinions: ArkOpinion[]; } = yield call(deleteOpinion, { location: payload, incomplete, pageNumber, filters, pageSize, columnSort });
            yield put(fetchOpinionsSuccessful(opinions, total, pageNumber));
        } else {
            const pageNumber: number = yield select(getIncompleteOpinionsPageNumber);
            const filters: TableFilters = yield select(getIncompleteOpinionFilters);
            const pageSize: number = yield select(getIncompleteOpinionPageSize);
            const columnSort: ColumnSort | undefined = yield select(getIncompleteOpinionColumnSort);
            const { total, incompleteOpinions }: { total: number; incompleteOpinions: ArkOpinion[]; } = yield call(deleteOpinion, { location: payload, incomplete, pageNumber, filters, pageSize, columnSort });
            yield put(fetchIncompleteOpinionsSuccessful(incompleteOpinions, total, pageNumber));
        }
        yield put(deleteOpinionSuccessful());
        toast('Opinion successfully deleted');
    } catch (e) {
        yield put(deleteOpinionFailed((e as Error).message));
        toast.error('Unable to delete opinion. Please try again.');
    }
}

export function* attemptAllOpinionsDownload({ payload }: ReturnType<typeof downloadAllOpinionsStarted>) {
    try {
        const { locations, description } = payload;
        const opinions: Blob = yield call(downloadAllOpinions, { locations });
        const formattedDescription = dotsToDashes(description);
        yield call(download, opinions, formattedDescription, 'application/zip');
        yield put(downloadAllOpinionsSuccessful());
    } catch (e) {
        toast.error('Unable to download opinion. Please try again.');
        yield put(downloadAllOpinionsFailed((e as Error).message));
    }
}

function* downloadOpinionWatcher() {
    yield takeEvery(MyOpinionActionTypes.DOWNLOAD_OPINION_STARTED, attemptOpinionDownload);
}

function* downloadOpinionSummaryWatcher() {
    yield takeEvery(MyOpinionActionTypes.DOWNLOAD_OPINION_SUMMARY_STARTED, attemptOpinionSummaryDownload);
}

function* deleteOpinionWatcher() {
    yield takeEvery(MyOpinionActionTypes.DELETE_OPINION_STARTED, attemptDeleteOpinion);
}

function* downloadAllOpinionsWatcher() {
    yield takeEvery(MyOpinionActionTypes.DOWNLOAD_ALL_OPINIONS_STARTED, attemptAllOpinionsDownload);
}

export function* attemptEnquireOpinion({ payload }: ReturnType<typeof enquireOpinionStarted>) {
    try {
        yield call(enquireOpinion, { opinionId: payload });
        yield put(enquireOpinionSuccessful());
    } catch (e) {
        yield put(enquireOpinionFailed((e as Error).message));
    }
}

function* enquireOpinionWatcher() {
    yield takeLeading(MyOpinionActionTypes.ENQUIRE_OPINION_STARTED, attemptEnquireOpinion);
}

function* openAdditionalOpinion(opinionId: number) {
    const opinion: ArkOpinion = yield call(getOpinionById, { opinionId });
    const opinionBlob: Blob = yield call(openOpinion, { location: opinion.location });
    const url: string = yield call(getDocumentUrl, opinion.mimeType, opinionBlob);
    return { opinion, url };
}

export function* attemptOpenAdditionalOpinions({ payload }: ReturnType<typeof openAdditionalOpinionsStarted>) {
    try {
        const opinionsAndUrls: { opinion: ArkOpinion; url: string; }[] = yield all(payload.map(opinionId => call(openAdditionalOpinion, opinionId)));
        const additionalOpinions = opinionsAndUrls.map(({ opinion }) => opinion);
        const urls = opinionsAndUrls.map(({ url }) => url);
        yield put(openAdditionalOpinionsSuccessful(additionalOpinions, urls));
    } catch (e) {
        yield put(openAdditionalOpinionsFailed((e as Error).message));
        toast.error('Unable to open additional opinion. Please try again.');
    }
}

function* openAdditionalOpinionsWatcher() {
    yield takeEvery(MyOpinionActionTypes.OPEN_ADDITIONAL_OPINIONS_STARTED, attemptOpenAdditionalOpinions);
}

export function* redirectOpinionTab({ payload }: ReturnType<typeof toggleOpinionsTableView>) {
    const navigationPath = `/opinions/my-opinions${payload === OpinionsTableView.INCOMPLETE ? '/incomplete' : ''}`;
    yield put(push(navigationPath));
}

function* redirectOpinionTabWatcher() {
    yield takeEvery(MyOpinionActionTypes.TOGGLE_OPINIONS_TABLE_VIEW, redirectOpinionTab);
}

export function* redirectOpinionsView({ payload }: ReturnType<typeof toggleOpinionsView>) {
    const navigationPath = `/opinions/my-opinions${payload === OpinionsView.MAP ? '/map' : ''}`;
    yield put(push(navigationPath));
}

function* redirectOpinionsViewWatcher() {
    yield takeEvery(MyOpinionActionTypes.TOGGLE_OPINIONS_VIEW, redirectOpinionsView);
}

export function* paginationNext() {
    const pageNumber: number = yield select(getOpinionsPageNumber);
    yield put(fetchOpinionsStarted(pageNumber + 1));
}

function* paginationNextWatcher() {
    yield takeEvery(MyOpinionActionTypes.OPINIONS_PAGINATION_NEXT, paginationNext);
}

export function* paginationPrevious() {
    const pageNumber: number = yield select(getOpinionsPageNumber);
    yield put(fetchOpinionsStarted(pageNumber - 1));
}

function* paginationPreviousWatcher() {
    yield takeEvery(MyOpinionActionTypes.OPINIONS_PAGINATION_PREVIOUS, paginationPrevious);
}

export function* tableConfigUpdated() {
    yield put(fetchOpinionsStarted(1));
}

function* tableConfigUpdatedWatcher() {
    yield debounce(200, [MyOpinionActionTypes.SET_OPINION_TABLE_FILTERS, MyOpinionActionTypes.CLEAR_OPINION_TABLE_FILTERS, MyOpinionActionTypes.SET_OPINIONS_PAGE_SIZE, MyOpinionActionTypes.SET_OPINION_TABLE_COLUMN_SORT], tableConfigUpdated);
}

export function* incompletePaginationNext() {
    const pageNumber: number = yield select(getIncompleteOpinionsPageNumber);
    yield put(fetchOpinionsStarted(pageNumber + 1));
}

function* incompletePaginationNextWatcher() {
    yield takeEvery(MyOpinionActionTypes.INCOMPLETE_OPINIONS_PAGINATION_NEXT, incompletePaginationNext);
}

export function* incompletePaginationPrevious() {
    const pageNumber: number = yield select(getIncompleteOpinionsPageNumber);
    yield put(fetchOpinionsStarted(pageNumber - 1));
}

function* incompletePaginationPreviousWatcher() {
    yield takeEvery(MyOpinionActionTypes.INCOMPLETE_OPINIONS_PAGINATION_PREVIOUS, incompletePaginationPrevious);
}

export function* incompleteTableConfigUpdated() {
    yield put(fetchIncompleteOpinionsStarted(1));
}

function* incompleteTableConfigUpdatedWatcher() {
    yield takeEvery([MyOpinionActionTypes.SET_INCOMPLETE_OPINION_TABLE_FILTERS, MyOpinionActionTypes.CLEAR_INCOMPLETE_OPINION_TABLE_FILTERS, MyOpinionActionTypes.SET_INCOMPLETE_OPINIONS_PAGE_SIZE], incompleteTableConfigUpdated);
}

// Opinions Map

export function* attemptFetchAllOpinionsByJurisdiction() {
    try {
        const { opinions }: { opinions: ArkMapOpinion[]; } = yield call(fetchOpinionsByJurisdiction);
        yield put(fetchAllOpinionsByJurisdictionSuccessful(opinions));
    } catch (e) {
        yield put(fetchAllOpinionsByJurisdictionFailed((e as Error).message));
        toast.error('Unable to fetch the latest opinions. Please try again.');
    }
}

function* fetchAllOpinionsByJurisdictionWatcher() {
    yield takeEvery(MyOpinionActionTypes.FETCH_ALL_OPINIONS_BY_JURISDICTION_STARTED, attemptFetchAllOpinionsByJurisdiction);
}

export function* attemptUpdateOpinionCustomFilters({ payload }: ReturnType<typeof updateOpinionCustomFilters>) {
    const { label, createNew } = payload;
    const opinionFilters: TableFilters = yield select(getOpinionFilters);
    const currentCustomFilters: CustomFilters[] = yield select(getCurrentUserCustomFilters);
    if (createNew) {
        const id = v4();
        const unnamedOpinionFilter = opinionInitialFilter(id, opinionFilters);
        const newOpinionFilter: OpinionCustomFilters = { ...unnamedOpinionFilter, filterName: label };
        const updatedCustomFilters = [...currentCustomFilters, newOpinionFilter];
        yield put(addOrDeleteUserCustomFilter(updatedCustomFilters));
        yield put(setSelectedOpinionCustomFilterId(newOpinionFilter.id));
    } else {
        const filterId: string | null = yield select(getSelectedOpinionCustomFilterId);
        if (!isNull(filterId)) {
            const updatedCustomFilters = currentCustomFilters.map(customFilter => {
                if (customFilter.id === filterId) {
                    const unnamedOpinionFilter = opinionInitialFilter(filterId, opinionFilters);
                    return { ...unnamedOpinionFilter, filterName: label };
                }
                return customFilter;
            });
            yield put(addOrDeleteUserCustomFilter(updatedCustomFilters));
        }
    }
    yield put(setOpinionCustomFilterHasUpdated(false));
    yield put(updateCustomFiltersStarted());

}

function* attemptUpdateOpinionCustomFiltersWatcher() {
    yield takeLeading(MyOpinionActionTypes.UPDATE_OPINION_CUSTOM_FILTERS, attemptUpdateOpinionCustomFilters);
}

export function* attemptSetSelectedCustomFilterId({ payload }: ReturnType<typeof setSelectedOpinionCustomFilterId>) {
    try {
        const customOpinionFilters: OpinionCustomFilters[] = yield select(getAllOpinionCustomFilters);
        const selectedOpinionFilter = customOpinionFilters.find(({ id }) => id === payload);
        if (selectedOpinionFilter) {
            yield put(clearOpinionTableFilters(false));
            const allFilters = stripFilterValues(selectedOpinionFilter);
            for (const [key, filterEntry] of Object.entries(allFilters)) {
                const filter = filterEntry as TableFilterType;
                if (!isNull(filter.dropdown)) {
                    yield put(setOpinionTableFilters(key, filter.dropdown, 'dropdown'));
                }
                if (filter.text.length > 0) {
                    yield put(setOpinionTableFilters(key, filter.text, 'text'));
                }
            }
        }
    } catch (e) {
        toast.error('Unable to set custom filter. Please try again.');
    }
}

function* attemptSetSelectedCustomFilterIdWatcher() {
    yield takeLeading(MyOpinionActionTypes.SET_SELECTED_OPINION_CUSTOM_FILTER_ID, attemptSetSelectedCustomFilterId);
}

export function* checkOpinionTableFiltersUpdated() {
    try {
        const customOpinionFilters: OpinionCustomFilters[] = yield select(getAllOpinionCustomFilters);
        const selectedCustomFilterId: string | null = yield select(getSelectedOpinionCustomFilterId);
        const opinionFilters: TableFilters = yield select(getOpinionFilters);
        const currentOpinionFilter = opinionInitialFilter(selectedCustomFilterId || '', opinionFilters);
        let savedOpinionFilter = opinionInitialFilter(selectedCustomFilterId || '', {});
        if (!isNull(selectedCustomFilterId)) {
            const selectedOpinionFilter = customOpinionFilters.find(({ id }) => id === selectedCustomFilterId);
            if (selectedOpinionFilter) {
                savedOpinionFilter = unset('filterName', selectedOpinionFilter);
            }
        }
        const hasUpdated = !isEqual(currentOpinionFilter, savedOpinionFilter);
        yield put(setOpinionCustomFilterHasUpdated(hasUpdated));
    } catch (e) {
        toast.error('Unable to update custom filters. Please try again.');
    }
}

function* checkOriginalTableFiltersUpdatedWatcher() {
    yield debounce(500, MyOpinionActionTypes.SET_OPINION_TABLE_FILTERS, checkOpinionTableFiltersUpdated);
}

export function* myOpinionsSaga() {
    yield all([
        fork(uploadWatcher),
        fork(openIncompleteOpinionWatcher),
        fork(detailsUpdatedWatcher),
        fork(updateOpinionDetailsWatcher),
        fork(skipIncompleteOpinionWatcher),
        fork(allIncompleteDocumentsWatcher),
        fork(fetchAllOpinionsWatcher),
        fork(openOpinionWatcher),
        fork(openOpinionAndInstanceWatcher),
        fork(downloadOpinionWatcher),
        fork(deleteOpinionWatcher),
        fork(downloadAllOpinionsWatcher),
        fork(redirectOpinionTabWatcher),
        fork(paginationNextWatcher),
        fork(paginationPreviousWatcher),
        fork(tableConfigUpdatedWatcher),
        fork(incompletePaginationNextWatcher),
        fork(incompletePaginationPreviousWatcher),
        fork(incompleteTableConfigUpdatedWatcher),
        fork(fetchAllOpinionsByJurisdictionWatcher),
        fork(enquireOpinionWatcher),
        fork(openAdditionalOpinionsWatcher),
        fork(assertBaseOpinionWatcher),
        fork(redirectOpinionsViewWatcher),
        fork(downloadOpinionSummaryWatcher),
        fork(openOpinionSummaryWatcher),
        fork(uploadSupportOpinionWatcher),
        fork(attemptUpdateOpinionCustomFiltersWatcher),
        fork(attemptSetSelectedCustomFilterIdWatcher),
        fork(checkOriginalTableFiltersUpdatedWatcher)
    ]);
}
