import React, { MouseEvent } from 'react';
import classnames from 'classnames';

import styles from './Button.module.scss';
import { Icon as ArkIcon } from '../icon/Icon';

interface ToggleButtonProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    icon: React.FC;
    fontSize?: number;
    testId?: string;
    color?: string;
    withBackground?: boolean;
    iconFill?: string;
    backgroundColor?: string;
    marginRight?: string;
}

export const RadioButton: React.FC<ToggleButtonProps> = ({ onClick, disabled = false, fontSize, icon, testId = '', color, withBackground = false, iconFill, backgroundColor, marginRight = '0px' }) => (
    <button
        onClick={onClick}
        className={classnames(styles.toggleButtonWithBorder, { [styles.toggleButtonWithBackground]: withBackground })}
        disabled={disabled}
        data-testid={`toggle-button-${testId}`}
        style={{ backgroundColor: (withBackground && backgroundColor) ? backgroundColor : '', border: (withBackground && backgroundColor) ? `2px solid ${backgroundColor}` : '', marginRight }}
    >
        {withBackground && <ArkIcon color={color} icon={icon} fontSize={fontSize} fill={iconFill} />}
    </button>
);
