import { UserRole } from '../../../constants/permittedRoles';

export enum UsersActionTypes {
    TOGGLE_USERS_MODAL = 'TOGGLE_USERS_MODAL',
    UPDATE_USER_VALUE = 'UPDATE_USER_VALUE',
    UPSERT_USER_STARTED = 'UPSERT_USER_STARTED',
    UPSERT_USER_SUCCESSFUL = 'UPSERT_USER_SUCCESSFUL',
    UPSERT_USER_FAILED = 'UPSERT_USER_FAILED',
    CHECK_USERNAME_AVAILABLE_STARTED = 'CHECK_USERNAME_AVAILABLE_STARTED',
    CHECK_USERNAME_AVAILABLE_SUCCESSFUL = 'CHECK_USERNAME_AVAILABLE_SUCCESSFUL',
    CHECK_USERNAME_AVAILABLE_FAILED = 'CHECK_USERNAME_AVAILABLE_FAILED',
    CHECK_EMAIL_VALID = 'CHECK_EMAIL_VALID',
    FETCH_ALL_USERS_STARTED = 'FETCH_ALL_USERS_STARTED',
    FETCH_ALL_USERS_SUCCESSFUL = 'FETCH_ALL_USERS_SUCCESSFUL',
    FETCH_ALL_USERS_FAILED = 'FETCH_ALL_USERS_FAILED',
    FETCH_AVAILABLE_FEATURE_PERMISSIONS_STARTED = 'FETCH_AVAILABLE_FEATURE_PERMISSIONS_STARTED',
    FETCH_AVAILABLE_FEATURE_PERMISSIONS_SUCCESSFUL = 'FETCH_AVAILABLE_FEATURE_PERMISSIONS_SUCCESSFUL',
    FETCH_AVAILABLE_FEATURE_PERMISSIONS_FAILED = 'FETCH_AVAILABLE_FEATURE_PERMISSIONS_FAILED',
    TOGGLE_DELETE_USER_CONFIRMATION_MODAL = 'TOGGLE_DELETE_USER_CONFIRMATION_MODAL',
    DELETE_USER_STARTED = 'DELETE_USER_STARTED',
    DELETE_USER_SUCCESSFUL = 'DELETE_USER_SUCCESSFUL',
    DELETE_USER_FAILED = 'DELETE_USER_FAILED',
    RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
    FETCH_ALL_BASIC_USERS_STARTED = 'FETCH_ALL_BASIC_USERS_STARTED',
    FETCH_ALL_BASIC_USERS_SUCCESSFUL = 'FETCH_ALL_BASIC_USERS_SUCCESSFUL',
    FETCH_ALL_BASIC_USERS_FAILED = 'FETCH_ALL_BASIC_USERS_FAILED'
}

export enum FeaturePermission {
    UPLOAD_INDUSTRY_STANDARD_OPINIONS = 1,
    PLAYBOOK_MANAGEMENT = 2,
    ENTITY_MANAGEMENT = 3,
    SYSTEM_CLAUSE_MANAGEMENT = 4,
    DORA_READ_ONLY = 5,
    DORA_FULL_ACCESS = 6,
    UPLOAD_DOCUMENTS = 7,
    EDIT_DOCUMENT_DATA = 8,
    COMPLETE_DOCUMENT_DETAILS = 9,
    DOWNLOAD_DOCUMENTS = 10,
    DELETE_DOCUMENTS = 11,
    UPLOAD_OPINIONS = 12,
    EDIT_OPINION_DATA = 13,
    DOWNLOAD_OPINIONS = 14,
    DOWNLOAD_OPINION_SUMMARIES = 15,
    DELETE_OPINIONS = 16,
    GENERATE_DOCUMENT_REPORTS = 17,
    EDIT_DOCUMENT_REPORT_TEMPLATES = 18,
    GENERATE_OPINION_REPORTS = 19,
    EDIT_OPINION_REPORT_TEMPLATES = 20,
    QUERY_PLAYBOOKS = 21,
    SUGGEST_CHANGES_PLAYBOOKS = 22,
    ADD_CLAUSES = 23,
    ADD_OPINION_NOTES = 24,
    SIGN_OFF_OPINIONS = 25,
    DOCUMENT_GROUP_MANAGEMENT = 26,
    SUB_COUNTERPARTY_TYPE = 27
}

export enum ClientFeaturePermission {
    EXPORT_RAF_DOCUMENTS_TO_CSV = 1,
    MEMBER_OF_ISDA = 2,
    MEMBER_OF_ICMA = 3,
    DOCUMENT_EXTRACT_WITH_AI = 4,
    MEMBER_OF_ISLA = 5,
    DOCUMENT_MODULE = 6,
    OPINION_MODULE = 7,
    DORA_MODULE = 8,
    ENTITY_CLASSIFICATION_AI = 9
}

export enum HomePageTile {
    ADD_DOCUMENTS = 'add documents',
    MY_DOCUMENTS = 'my documents',
    SEARCH_DOCUMENTS = 'search documents',
    DOCUMENT_SPREAD = 'document spread',
    ENTITY_SPREAD = 'entity spread',
    MY_ATTESTATIONS = 'my attestations',
    MY_OPINIONS = 'my opinions',
    OPINION_ANALYTICS = 'opinion analytics',
    DOCUMENT_ANALYTICS = 'document analytics',
    OPINION_REPORTING = 'opinion reporting',
    DOCUMENT_REPORTING = 'document reporting',
    PLAYBOOKS = 'playbooks',
    CLAUSE_LIBRARY = 'clause library'
}

export interface NewUser {
    username: string;
    forenames: string;
    surname: string;
    email: string;
    role: UserRole;
    userId?: number;
    featurePermissions: number[] | null;
    clientId?: number;
    profilePicture?: Buffer;
}

export interface AlternateUserAccount {
    username: string;
    client: string;
}

export interface ClientUser {
    userId: number;
    forenames: string;
    surname: string;
    username: string;
    email: string;
    clientId: number;
    role: UserRole;
    featurePermissions: number[] | null;
    profilePicture?: Buffer;
}

export interface BasicUserInfo {
    userId: number;
    forenames: string;
    surname: string;
    username: string;
}

export interface FeaturePermissionDB {
    featurePermissionId: number;
    label: string;
    description: string;
    adminInclusive: number;
    systemAdminOnly: number;
}

export interface UsersState {
    modalOpen: boolean;
    user: NewUser;
    fetchingUsers: boolean;
    allUsers: ClientUser[];
    userError: string;
    usernameUnavailable: boolean;
    checkingUsername: boolean;
    emailValid: boolean;
    availableFeaturePermissions: FeaturePermissionDB[];
    confirmDeleteUser: ClientUser | null;
    deletingUser: boolean;
    basicUserInfo: BasicUserInfo[];
}
