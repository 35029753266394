import { FieldValue } from '../../../datasets/instances/store';
import { SingleDatasetFieldType } from '../../../datasets/store';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

export enum AdminAIActionTypes {
    FETCH_AI_AGREEMENT_TYPES_STARTED = 'FETCH_AI_AGREEMENT_TYPES_STARTED',
    FETCH_AI_AGREEMENT_TYPES_SUCCESSFUL = 'FETCH_AI_AGREEMENT_TYPES_SUCCESSFUL',
    FETCH_AI_AGREEMENT_TYPES_FAILED = 'FETCH_AI_AGREEMENT_TYPES_FAILED',
    FETCH_AGREEMENT_TYPE_QUERIES_STARTED = 'FETCH_AGREEMENT_TYPE_QUERIES_STARTED',
    FETCH_AGREEMENT_TYPE_QUERIES_SUCCESSFUL = 'FETCH_AGREEMENT_TYPE_QUERIES_SUCCESSFUL',
    FETCH_AGREEMENT_TYPE_QUERIES_FAILED = 'FETCH_AGREEMENT_TYPE_QUERIES_FAILED',
    SET_SELECTED_AI_AGREEMENT_TYPE = 'SET_SELECTED_AI_AGREEMENT_TYPE',
    SET_QUERY_ORDER = 'SET_QUERY_ORDER',
    SAVE_QUERY_ORDER_STARTED = 'SAVE_QUERY_ORDER_STARTED',
    SAVE_QUERY_ORDER_SUCCESSFUL = 'SAVE_QUERY_ORDER_SUCCESSFUL',
    SAVE_QUERY_ORDER_FAILED = 'SAVE_QUERY_ORDER_FAILED',
    ADD_NEW_DOCUMENT_QUERY = 'ADD_NEW_DOCUMENT_QUERY',
    UPDATE_DOCUMENT_QUERY = 'UPDATE_DOCUMENT_QUERY',
    UPDATE_DOCUMENT_QUERY_SYSTEM_INFO = 'UPDATE_DOCUMENT_QUERY_SYSTEM_INFO',
    DELETE_DOCUMENT_QUERY = 'DELETE_DOCUMENT_QUERY',
    SET_DOCUMENT_QUERIES_UPDATED = 'SET_DOCUMENT_QUERIES_UPDATED',
    SET_IS_EDITING_DOCUMENT_QUERIES = 'SET_IS_EDITING_DOCUMENT_QUERIES',
    SAVE_UPDATED_QUERIES_STARTED = 'SAVE_UPDATED_QUERIES_STARTED',
    SAVE_UPDATED_QUERIES_SUCCESSFUL = 'SAVE_UPDATED_QUERIES_SUCCESSFUL',
    SAVE_UPDATED_QUERIES_FAILED = 'SAVE_UPDATED_QUERIES_FAILED',
    SET_LAST_UPDATED_ORDER = 'SET_LAST_UPDATED_ORDER',
    SET_AI_QUERIES_SELECTED_CLIENT = 'SET_AI_QUERIES_SELECTED_CLIENT'
}

export interface AIAgreementType {
    agreementTypeId: number;
    agreementType: string;
    totalQueries: number;
    correctQueries: number;
    percentage: number;
}

export interface PortfolioTotal {
    totalQueries: number;
    correctQueries: number;
    percentage: number;
}

export interface DocumentQuery {
    documentQueryId: number | null;
    question: string;
    queryKey: string;
    systemId: string;
    aiVersion: number;
    queryOrder: number;
    multiRowQuery: number;
    isEdgeCase: number;
    partyRowExists: number;
    totalQueries: number;
    correctQueries: number;
    documentNameIds: number[];
    percentage: number;
    guidanceNotes: string;
    fieldType: SingleDatasetFieldType | null;
    dropdownListId: number | null;
    isMultiSelect: number;
    defaultAnswer: FieldValue | null;
}

export interface UpdatedQueryOrder {
    documentQueryId: number;
    queryOrder: number;
}

export interface AIQueryRelevantDocumentNames {
    documentName: string;
    documentNameId: number | undefined;
}

export interface PieChartData {
    chartData: PieChartAnalyticsData[];
    totalQueries: number;
    correctQueries: number;
    percentage: number;
}

export interface SystemIdInfo {
    systemId: string;
    type: SingleDatasetFieldType;
    isMultiSelect: boolean;
    dropdownListId: number | null;
}

export interface SystemIdInfoUI extends Omit<SystemIdInfo, 'type'> {
    type: SingleDatasetFieldType | null;
}

export interface AdminAIState {
    isFetchingAgreementTypes: boolean;
    isFetchingQueries: boolean;
    agreementTypes: AIAgreementType[];
    portfolioTotal: PortfolioTotal | null;
    selectedAgreement: number | null;
    savedQueries: DocumentQuery[];
    currentQueries: DocumentQuery[];
    queriesUpdated: boolean;
    isEditing: boolean;
    lastUpdatedOrder: number | null;
    agreementTypeSystemInfo: SystemIdInfo[];
    selectedClientId: number | null;
}
