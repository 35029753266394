import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import styles from './Registration.module.scss';
import Logo from '../../../assets/logos/Ark51_VERTWHITE&WHITE.png';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { updateRegistrationDetails, getEmailValid, submitRegistrationDetailsStarted, RegistrationDetails, validateRegistrationEmail } from './store';

export const Registration: React.FC = () => {

    const dispatch = useAppDispatch();

    const handleUpdateValue = useCallback((value: string, key: keyof RegistrationDetails) => {
        dispatch(updateRegistrationDetails(value, key));
        if (key === 'email') {
            dispatch(validateRegistrationEmail(value));
        }
    }, [dispatch]);

    const registrationText = 'If you would like to sign up to Ark 51 or get more information, please provide your details below and someone from our team will get in touch';
    const emailValid = useAppSelector(getEmailValid);

    const submit = () => {
        dispatch(submitRegistrationDetailsStarted());
    };

    return (
        <div className={styles.registrationWrapper}>
            <img className={styles.logoWrapper} src={Logo} />
            <div className={styles.registrationInfo}>{registrationText}</div>
            <input
                placeholder='Name'
                name='name'
                data-testid='register-name-input'
                className={styles.registrationInput}
                onChange={e => handleUpdateValue(e.target.value, 'name')}
                type='input'
                autoFocus
            />
            <input
                placeholder='Company Name'
                name='companyName'
                data-testid='register-company-name-input'
                className={styles.registrationInput}
                onChange={e => handleUpdateValue(e.target.value, 'companyName')}
                type='input'
            />
            <input
                placeholder='Position'
                name='position'
                data-testid='register-position-input'
                className={styles.registrationInput}
                onChange={e => handleUpdateValue(e.target.value, 'position')}
                type='input'
            />
            <input
                placeholder='Email Address'
                name='email'
                data-testid='register-email-input'
                className={styles.registrationInput}
                onChange={e => handleUpdateValue(e.target.value, 'email')}
                type='input'
            />
            <input
                placeholder='Phone Number'
                name='phone'
                data-testid='register-phone-number-input'
                className={styles.registrationInput}
                onChange={e => handleUpdateValue(e.target.value, 'phone')}
                type='number'
            />
            <div className={styles.registrationButtonsWrapper}>
                <Link to='/login' className={styles.registrationButton} data-testid='registration-return-to-login-button'>
                    <div className={styles.registrationButtonText}>Return to login</div>
                </Link>
                <Link to='/login' onClick={submit} className={styles.registrationButton} data-testid='registration-submit-button' style={{ pointerEvents: emailValid ? 'auto' : 'none', opacity: emailValid ? '1' : '0.8' }}>
                    <div className={styles.registrationButtonText}>Submit</div>
                </Link>
            </div>
        </div>
    );
};
