import { api } from './services';
import { Playbook, PlaybookQueryDB, PlaybookMessage, PlaybookSmartSearch, UpdatedProvisionLinks } from '../components/admin/playbook/store';
import { PlaybookQuery, SuggestedChanges } from '../components/playbook/store';

interface PublishPlaybookRequest {
    playbook: Playbook;
    publishReason: string;
    resolvedSuggestedChanges: number[];
    provisionLinksUpdated: UpdatedProvisionLinks | null;
}

export const publishPlaybook = async (reqBody: PublishPlaybookRequest) => await api('playbook/admin/publish', { ...reqBody }, 'post');
export const savePlaybook = async (reqBody: PublishPlaybookRequest) => await api('playbook/admin/save', { ...reqBody }, 'post');
export const confirmResolved = async (reqBody: { playbookId: number, playbookDefinitionId: number, resolvedSuggestedChanges: number[] }) => await api('playbook/admin/confirmResolved', { ...reqBody }, 'post');
export const getAllPlaybooks = async (reqBody: { viewTemplates: boolean; includeDrafts: boolean; }) => await api('playbook/getAll', { ...reqBody }, 'post');
export const getAllPlaybookTemplates = async () => await api('playbook/template/getAll', {}, 'get');
export const getPlaybookById = async (reqBody: { playbookId: number; includeDrafts: boolean; includeResolvedSuggestedChanges: boolean; }) => await api('playbook/getById', { ...reqBody }, 'post');
export const getPlaybookByDefinitionId = async (reqBody: { playbookDefinitionId: number; includeDrafts: boolean; includeResolvedSuggestedChanges: boolean; }) => await api('playbook/getByDefinitionId', { ...reqBody }, 'post');
export const sendQueryAndEmail = async (reqBody: { query: PlaybookQuery; }) => await api('playbook/sendQuery', { ...reqBody }, 'post');
export const getPlaybookTemplateById = async (reqBody: { playbookId: number; }) => await api('playbook/template/getById', { ...reqBody }, 'post');
export const queryResponse = async (reqBody: { query: PlaybookQueryDB; sendEmail: boolean, markAsResolved: boolean }) => await api('playbook/admin/queryResponse', { ...reqBody }, 'post');
export const sendQueryReminder = async (reqBody: { query: PlaybookQuery; }) => await api('playbook/sendQueryReminder', { ...reqBody }, 'post');
export const sendAdditionalQuery = async (reqBody: { updatedQuery: PlaybookQueryDB; }) => await api('playbook/sendAdditionalQuery', { ...reqBody }, 'post');
export const sendSuggestedChanges = async (reqBody: { suggestedChanges: SuggestedChanges; playbookId: number; playbookDefinitionId: number; conversation: PlaybookMessage[]; }) => await api('playbook/suggestion/upsert', { ...reqBody }, 'post');
export const sendSuggestedChangesMessage = async (reqBody: { conversation: PlaybookMessage[]; playbookSuggestionId: number; playbookDefinitionId: number; }) => await api('playbook/suggestion/sendMessage', { ...reqBody }, 'post');
export const smartSearchPlaybooks = async(reqBody: { searchTerm: PlaybookSmartSearch[], includeDrafts: boolean }) => await api('playbook/smartSearch', { ...reqBody }, 'post');
export const getAvailableProvisionLinks = async () => await api('playbook/admin/getAvailableProvisionLinks', {}, 'get');
