import React from 'react';

export const Number: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,108.7,108.7,114.1,102.1,114.1z" />
        <g>
            <path fill={color || 'currentColor'} d="M32.5,81.8V53.2l-6.8,4.1v-7.5l6.8-4h7v36H32.5z" />
            <path fill={color || 'currentColor'} d="M48,81.8v-5.9l15.1-13.4c1.1-1,1.9-1.9,2.4-2.8s0.7-1.7,0.7-2.5c0-1.1-0.2-2.1-0.7-2.9c-0.5-0.9-1.1-1.5-1.9-2s-1.8-0.7-2.9-0.7c-1.2,0-2.2,0.3-3,0.8s-1.6,1.2-2,2.1s-0.7,1.8-0.7,2.8h-7c0-2.5,0.5-4.6,1.6-6.4c1.1-1.8,2.6-3.2,4.5-4.2c1.9-1,4.2-1.5,6.8-1.5c2.3,0,4.5,0.5,6.3,1.5c1.9,1,3.3,2.5,4.4,4.3s1.6,4,1.6,6.4c0,1.8-0.2,3.3-0.8,4.4c-0.5,1.2-1.2,2.3-2.2,3.2c-1,1-2.2,2.1-3.6,3.4l-9.8,8.5l-0.5-1.5h16.9v6.4H48z" />
            <path fill={color || 'currentColor'} d="M89.7,82.5c-1.8,0-3.5-0.4-5.2-1.1c-1.6-0.7-3.1-1.7-4.3-3.1c-1.2-1.3-2.1-2.9-2.6-4.7l6.6-1.8c0.4,1.3,1,2.3,2.1,3.1c1,0.7,2.1,1.1,3.3,1.1c1,0,2-0.2,2.8-0.8c0.9-0.5,1.5-1.2,2-2c0.5-0.8,0.8-1.8,0.8-2.8c0-1.5-0.5-2.9-1.6-4s-2.4-1.7-4.1-1.7c-0.5,0-1,0.1-1.5,0.2c-0.5,0.1-0.9,0.3-1.3,0.6l-3-5.3l11.4-9.5l0.5,1.4H79.1v-6.4h22.5v6.4l-8.9,8.6l0-1.9c2.1,0.3,3.8,1,5.2,2.2s2.5,2.5,3.2,4.1c0.7,1.6,1.1,3.4,1.1,5.2c0,2.3-0.6,4.3-1.7,6.1c-1.2,1.8-2.7,3.2-4.6,4.3C94,81.9,91.9,82.5,89.7,82.5z" />
        </g>
    </svg>
);
