import React, { useMemo } from 'react';
import classnames from 'classnames';
import { max, min } from 'lodash/fp';

import styles from './Pagination.module.scss';

interface PaginationProps {
    selectedPage: number;
    totalPages: number;
    openPage: (pageNumber: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({ selectedPage, totalPages, openPage }) => {
    const pagesToDisplay: number[] = useMemo(() => {
        if (totalPages) {
            const minPage = max([selectedPage - 5, 1])!;
            const maxPage = min([totalPages, minPage + 9])!;
            const pageRange = maxPage - minPage + 1;
            return Array(pageRange).fill(minPage).map((page, index) => page + index);
        }
        return [];
    }, [selectedPage, totalPages]);

    const openPrevious = () => openPage(selectedPage - 1);
    const openNext = () => openPage(selectedPage + 1);

    const previousVisible = selectedPage !== 1;
    const nextVisible = selectedPage !== totalPages;

    if (!totalPages) {
        return <div className={styles.wrapper} />;
    }

    return (
        <div className={styles.wrapper}>
            {previousVisible ? <button className={styles.previousButton} onClick={openPrevious}>{'<'}</button> : <div className={styles.placeholder} />}
            {pagesToDisplay.map(page =>
                <button
                    key={page}
                    className={classnames(styles.paginationButton, { [styles.selectedButton]: selectedPage === page })}
                    onClick={() => selectedPage !== page && openPage(page)}
                >
                    {page}
                </button>
            )}
            {nextVisible ? <button className={styles.nextButton} onClick={openNext}>{'>'}</button> : <div className={styles.placeholder} />}
        </div>
    );
};
