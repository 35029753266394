import { Scheduler, SchedulerDB } from '../components/admin/workflow/store';
import { api } from './services';

export const upsertScheduledAction = async (reqBody: { scheduler: Scheduler; monthYear: string; }) => await api('scheduler/upsert', { ...reqBody }, 'post');

export const fetchCalendarEvents = async (reqBody: { monthYear: string; }) => await api('scheduler/calendar/get', { ...reqBody }, 'post');
export const fetchAllCalendarEvents = async (reqBody: { isArchived: number; }) => await api('scheduler/getAll', { ...reqBody }, 'post');
export const deleteScheduledAction = async (reqBody: { monthYear: string; scheduledActionId: number; }) => await api('scheduler/delete', { ...reqBody }, 'post');
export const skipScheduledAction = async (reqBody: { scheduledActionId: number; dateToSkip: string; monthYear: string; isSkipped: boolean; }) => await api('scheduler/skip', { ...reqBody }, 'post');
export const recoverScheduledAction = async (reqBody: { scheduledAction: Scheduler | SchedulerDB; monthYear: string; }) => await api('scheduler/recover', { ...reqBody }, 'post');
