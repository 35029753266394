import { action } from 'typesafe-actions';
import { RawDraftContentState } from 'draft-js';

import { OpinionFieldValue, OpinionInstance, OpinionInstanceActionTypes, OpinionMultiToggleField, OpinionToggleField, SignOffConversation, SignOffOpinionInstance, TimelineOpinionInstance, UpdatedOpinionField } from './types';
import { Clause, ClauseTagDropdownOptions, ClientTag, ClauseTags, FuzzyMatchTags } from '../../../clause-library/store';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { ScrollableElement } from '../../../shared/scrollable/Scrollable';
import { SmileyIndicator } from '../../../shared/analytics/SingleIndicator';

export const updateFieldValue = (sectionId: string, fieldId: string, value: OpinionFieldValue) => action(OpinionInstanceActionTypes.UPDATE_FIELD_VALUE, { sectionId, fieldId, value });
export const updateSingleToggleValue = (sectionId: string, fieldId: string, index: number, value: boolean) => action(OpinionInstanceActionTypes.UPDATE_SINGLE_TOGGLE_FIELD_VALUE, { sectionId, fieldId, index, value });
export const updateTableCellValue = (sectionId: string, fieldId: string, index: number, value: string[] | null) => action(OpinionInstanceActionTypes.UPDATE_TABLE_CELL_FIELD_VALUE, { sectionId, fieldId, index, value });
export const updateCounterpartyCoverageValue = (sectionId: string, fieldId: string, index: number, value: boolean[]) => action(OpinionInstanceActionTypes.UPDATE_COUNTERPARTY_COVERAGE_FIELD_VALUE, { sectionId, fieldId, index, value });
export const updateDropdownFieldValue = (sectionId: string, fieldId: string, value: string[] | null) => action(OpinionInstanceActionTypes.UPDATE_DROPDOWN_FIELD_VALUE, { sectionId, fieldId, value });
export const updateWYSIWYGFieldValue = (sectionId: string, fieldId: string, value: RawDraftContentState | null) => action(OpinionInstanceActionTypes.UPDATE_WYSIWYG_FIELD_VALUE, { sectionId, fieldId, value });
export const updateCustomToggleLabel = (sectionId: string, index: number, value: string) => action(OpinionInstanceActionTypes.UPDATE_CUSTOM_TOGGLE_LABEL, { sectionId, index, value });
export const addOpinionFieldCustomChild = (sectionId: string, customOptions: OpinionToggleField[] | OpinionMultiToggleField[]) => action(OpinionInstanceActionTypes.ADD_OPINION_FIELD_CUSTOM_CHILD, { sectionId, customOptions });

export const toggleSupportOpinionUploadModal = (isOpen: boolean) => action(OpinionInstanceActionTypes.TOGGLE_SUPPORT_OPINION_UPLOAD_MODAL_OPEN, isOpen);
export const toggleLinkedOpinionModal = (isOpen: boolean) => action(OpinionInstanceActionTypes.TOGGLE_LINKED_OPINION_MODAL_OPEN, isOpen);
export const toggleOpenFieldSection = (id: string) => action(OpinionInstanceActionTypes.TOGGLE_OPEN_FIELD_SECTION, id);
export const closeAllOpenFieldSections = () => action(OpinionInstanceActionTypes.REMOVE_ALL_FIELD_SECTIONS);

export const toggleRefModalOpen = (sectionId: string, fieldId: string, isOpen: boolean) => action(OpinionInstanceActionTypes.TOGGLE_REF_MODAL_OPEN, { sectionId, fieldId, isOpen });
export const updateFieldRef = (sectionId: string, fieldId: string, value: string) => action(OpinionInstanceActionTypes.UPDATE_FIELD_REF, { sectionId, fieldId, value });
export const updateFieldPageRef = (sectionId: string, fieldId: string, index: number, value: string) => action(OpinionInstanceActionTypes.UPDATE_FIELD_PAGE_REF, { sectionId, fieldId, index, value });
export const addFieldPageRef = (sectionId: string, fieldId: string) => action(OpinionInstanceActionTypes.ADD_FIELD_PAGE_REF, { sectionId, fieldId });
export const removeFieldPageRef = (sectionId: string, fieldId: string, index: number) => action(OpinionInstanceActionTypes.REMOVE_FIELD_PAGE_REF, { sectionId, fieldId, index });
export const updateFieldPageRefVerified = (sectionId: string, fieldId: string, pageRefVerified: boolean) => action(OpinionInstanceActionTypes.UPDATE_FIELD_PAGE_REF_VERIFIED, { sectionId, fieldId, pageRefVerified });

export const editOpinionInstance = (value: boolean) => action(OpinionInstanceActionTypes.EDIT_OPINION_INSTANCE, value);
export const updateOpinionInstance = (value: boolean) => action(OpinionInstanceActionTypes.UPDATE_OPINION_INSTANCE, value);

export const toggleSaveInstanceModal = (value: boolean) => action(OpinionInstanceActionTypes.TOGGLE_SAVE_INSTANCE_MODAL, value);

export const upsertOpinionInstanceStarted = (isDraft: boolean) => action(OpinionInstanceActionTypes.UPSERT_OPINION_INSTANCE_STARTED, isDraft);
export const upsertOpinionInstanceSuccessful = (updatedInstance: OpinionInstance) => action(OpinionInstanceActionTypes.UPSERT_OPINION_INSTANCE_SUCCESSFUL, updatedInstance);
export const upsertOpinionInstanceFailed = (error: string) => action(OpinionInstanceActionTypes.UPSERT_OPINION_INSTANCE_FAILED, error);

export const openTimelineOpinionInstanceStarted = (timelineInstance: TimelineOpinionInstance) => action(OpinionInstanceActionTypes.OPEN_TIMELINE_INSTANCE_STARTED, timelineInstance);
export const openTimelineOpinionInstanceSuccessful = (instance: OpinionInstance) => action(OpinionInstanceActionTypes.OPEN_TIMELINE_INSTANCE_SUCCESSFUL, instance);
export const openTimelineOpinionInstanceFailed = (error: string) => action(OpinionInstanceActionTypes.OPEN_TIMELINE_INSTANCE_FAILED, error);

export const removeOpinionOpenFieldSection = (fieldSection: string) => action(OpinionInstanceActionTypes.TOGGLE_OPEN_FIELD_SECTION, fieldSection);
export const removeAllOpinionFieldSections = () => action(OpinionInstanceActionTypes.REMOVE_ALL_FIELD_SECTIONS);

export const openOpinionInstanceById = (opinionInstanceId: string) => action(OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_BY_ID, opinionInstanceId);

export const openOpinionInstanceStarted = () => action(OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_STARTED);
export const openOpinionInstanceSuccessful = () => action(OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_SUCCESSFUL);
export const openOpinionInstanceFailed = (error: string) => action(OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_FAILED, error);

export const setTimelineInstance = (timelineInstance: TimelineOpinionInstance) => action(OpinionInstanceActionTypes.SET_TIMELINE_INSTANCE, timelineInstance);
export const setTimeline = (timeline: TimelineOpinionInstance[]) => action(OpinionInstanceActionTypes.SET_TIMELINE, timeline);

export const setFieldsUpdated = (fieldsUpdated: UpdatedOpinionField[]) => action(OpinionInstanceActionTypes.SET_FIELDS_UPDATED_VALUE, fieldsUpdated);
export const setInstanceUpdated = (hasUpdated: boolean) => action(OpinionInstanceActionTypes.INSTANCE_UPDATED, hasUpdated);

export const resetOpinionInstance = () => action(OpinionInstanceActionTypes.RESET_OPINION_INSTANCE);

export const setScrollableWrapper = (element: ScrollableElement | null) => action(OpinionInstanceActionTypes.SET_SCROLLABLE_WRAPPER, element);

export const updateInstanceAdditionalOpinionIds = (additionalOpinionIds: number[]) => action(OpinionInstanceActionTypes.UPDATE_INSTANCE_ADDITIONAL_OPINION_IDS, additionalOpinionIds);

export const upsertSignOffOpinionInstanceStarted = (signedOff: boolean) => action(OpinionInstanceActionTypes.UPSERT_SIGN_OFF_OPINION_INSTANCE_STARTED, signedOff);
export const upsertSignOffOpinionInstanceSuccessful = () => action(OpinionInstanceActionTypes.UPSERT_SIGN_OFF_OPINION_INSTANCE_SUCCESSFUL);
export const upsertSignOffOpinionInstanceFailed = (error: string) => action(OpinionInstanceActionTypes.UPSERT_SIGN_OFF_OPINION_INSTANCE_FAILED, error);

// Opinion Clause
export const fetchAllOpinionClausesStarted = (opinionId: number | undefined, pageNumber: number) => action(OpinionInstanceActionTypes.FETCH_ALL_OPINION_CLAUSES_STARTED, { opinionId, pageNumber });
export const fetchAllOpinionClausesSuccessful = (clauses: Clause[], total: number, pageNumber: number, allOpinionClauseIds: number[], additionalProvisionsUpdated: boolean) => action(OpinionInstanceActionTypes.FETCH_ALL_OPINION_CLAUSES_SUCCESSFUL, { clauses, total, pageNumber, allOpinionClauseIds, additionalProvisionsUpdated });
export const fetchAllOpinionClausesFailed = (error: string) => action(OpinionInstanceActionTypes.FETCH_ALL_OPINION_CLAUSES_FAILED, error);

export const openOpinionClause = (savedClauseId: number) => action(OpinionInstanceActionTypes.OPEN_OPINION_CLAUSE, savedClauseId);

export const toggleOpinionClauseModal = (isSystemTemplate: number) => action(OpinionInstanceActionTypes.TOGGLE_OPINION_CLAUSE_MODAL, isSystemTemplate);

export const toggleOpinionClauseDescription = () => action(OpinionInstanceActionTypes.TOGGLE_OPINION_CLAUSE_DESCRIPTION);

export const updateOpinionClause = (key: keyof Clause, value: string | string[] | RawDraftContentState | null | number) => action(OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE, { key, value });

export const saveOpinionClauseStarted = (opinionId: number, closeModal: boolean) => action(OpinionInstanceActionTypes.SAVE_OPINION_CLAUSE_STARTED, { opinionId, closeModal });
export const saveOpinionClauseSuccessful = (clause: Clause, closeModal: boolean) => action(OpinionInstanceActionTypes.SAVE_OPINION_CLAUSE_SUCCESSFUL, { clause, closeModal });
export const saveOpinionClauseFailed = (error: string) => action(OpinionInstanceActionTypes.SAVE_OPINION_CLAUSE_FAILED, error);

export const duplicateOpinionClause = (duplicateClauseId: number, isSave: boolean, hasSystemClausePermission: boolean) => action(OpinionInstanceActionTypes.DUPLICATE_OPINION_CLAUSE, { duplicateClauseId, isSave, hasSystemClausePermission });

export const setOpinionClauseCanSave = (value: boolean) => action(OpinionInstanceActionTypes.SET_OPINION_CLAUSE_CAN_SAVE, value);

export const fetchOpinionClauseLibraryDropdownOptionsStarted = () => action(OpinionInstanceActionTypes.FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_STARTED);
export const fetchOpinionClauseLibraryDropdownOptionsSuccessful = (dropdownOptions: ClauseTagDropdownOptions) => action(OpinionInstanceActionTypes.FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_SUCCESSFUL, dropdownOptions);
export const fetchOpinionClauseLibraryDropdownOptionsFailed = (error: string) => action(OpinionInstanceActionTypes.FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_FAILED, error);

export const updateAllOpinionClauseClientTags = (tags: ClientTag[]) => action(OpinionInstanceActionTypes.OPINION_CLAUSE_UPDATE_ALL_CLIENT_TAGS, tags);

export const updateOpinionClauseTag = (key: keyof ClauseTags, value: string[] | null) => action(OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE_TAGS, { key, value });

export const updateOpinionClauseTagTerm = (value: string) => action(OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE_TAG_TERM, value);

export const checkOpinionClauseFuzzyMatch = () => action(OpinionInstanceActionTypes.CHECK_OPINION_CLAUSE_FUZZY_MATCH);
export const updateOpinionClauseFuzzyMatchResults = (tags: FuzzyMatchTags[]) => action(OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE_FUZZY_MATCH_RESULTS, tags);

export const addOpinionClauseClientTagStarted = (value: string) => action(OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_CLIENT_TAG_STARTED, value);
export const addOpinionClauseClientTagSuccessful = (allClientTags: ClientTag[]) => action(OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_CLIENT_TAG_SUCCESSFUL, { allClientTags });
export const addOpinionClauseClientTagFailed = (error: string) => action(OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_CLIENT_TAG_FAILED, error);

export const addOpinionClauseSystemTagStarted = (newTag: string, category: keyof ClauseTags, updatedTags?: string[]) => action(OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_SYSTEM_TAG_STARTED, { newTag, category, updatedTags });
export const addOpinionClauseSystemTagSuccessful = (key: keyof ClauseTags, value?: string[] | null) => action(OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_SYSTEM_TAG_SUCCESSFUL, { key, value });
export const addOpinionClauseSystemTagFailed = (error: string) => action(OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_SYSTEM_TAG_FAILED, error);

export const setOpinionClauseFuzzyMatchModalOpen = (value: boolean) => action(OpinionInstanceActionTypes.SET_OPINION_CLAUSE_FUZZY_MATCH_MODAL_OPEN, value);

export const toggleLinkOpinionClauseModal = () => action(OpinionInstanceActionTypes.TOGGLE_LINK_OPINION_CLAUSE_MODAL);

export const toggleSubCounterpartyModal = (value: boolean) => action(OpinionInstanceActionTypes.TOGGLE_SUB_COUNTERPARTY_TYPE_MODAL_OPEN, value);

export const updateIncludedInstanceSubCounterpartyTypes = (value: number[]) => action(OpinionInstanceActionTypes.UPDATE_INCLUDED_INSTANCE_SUB_COUNTERPARTY_TYPES, value);

export const linkOpinionClause = (opinionId: number, clauseId: number, shouldRemoveLink: boolean) => action(OpinionInstanceActionTypes.LINK_OPINION_CLAUSE, { opinionId, clauseId, shouldRemoveLink });

export const selectSubCounterpartiesParent = (value: string) => action(OpinionInstanceActionTypes.SET_SUB_COUNTERPARTY_PARENT, value);

// Agreement coverage search
export const updateAgreementCoverageSearch = (searchTerm: string) => action(OpinionInstanceActionTypes.UPDATE_AGREEMENT_TYPE_SEARCH, searchTerm);

// Opinion Sign Off
export const addOpinionInstanceSignOffNotes = (sectionId: string, fieldId: string | undefined, signOffConversation: SignOffConversation, index?: number) => action(OpinionInstanceActionTypes.ADD_OPINION_INSTANCE_SIGN_OFF_NOTES, { sectionId, fieldId, signOffConversation, index });
export const setSignOffNotesModalOpen = (value: boolean, id: string) => action(OpinionInstanceActionTypes.SET_SIGN_OFF_NOTES_MODAL_OPEN, { value, id });
export const updateSmileyContent = (smileyValue: SmileyIndicator) => action(OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_SMILEY_CONTENT, { smileyValue });
export const updateDropdownDetailsContent = (value: string[] | null) => action(OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_DROPDOWN_VALUE, value);
export const updateNotesSignOffContent = (notes: RawDraftContentState | null, createdBy: number | null, name: string, createdDate: string) => action(OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_CONTENT, { notes, createdBy, name, createdDate });
export const setSignOffInstanceUpdated = (hasUpdated: boolean) => action(OpinionInstanceActionTypes.SIGN_OFF_INSTANCE_UPDATED, hasUpdated);
export const setOpinionSignOff = (signOffOpinionInstance: SignOffOpinionInstance) => action(OpinionInstanceActionTypes.SET_OPINION_SIGN_OFF, signOffOpinionInstance);
export const toggleSignOffModalOpen = (value: boolean) => action(OpinionInstanceActionTypes.TOGGLE_SIGN_OFF_MODAL_OPEN, value);
export const updateOpinionSignOffFinalNotes = (value: null | RawDraftContentState) => action(OpinionInstanceActionTypes.UPDATE_SIGN_OFF_FINAL_NOTES, value);
export const resetSignOffNotesInstance = () => action(OpinionInstanceActionTypes.RESET_SIGN_OFF_OPINION_INSTANCE);
export const setNoteToUpdate = (sectionId: string, index: number, fieldId?: string) => action(OpinionInstanceActionTypes.SET_NOTE_TO_EDIT, { sectionId, index, fieldId });

// Clause Table Filter and pagination
export const setOpinionClausesTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(OpinionInstanceActionTypes.SET_OPINION_CLAUSES_TABLE_FILTERS, { key, value, type });
export const clearOpinionClausesTableFilters = () => action(OpinionInstanceActionTypes.CLEAR_OPINION_CLAUSES_TABLE_FILTERS);
export const setOpinionClausesTableColumnSort = (columnSort: ColumnSort | undefined) => action(OpinionInstanceActionTypes.SET_OPINION_CLAUSES_TABLE_COLUMN_SORT, columnSort);
export const opinionClausesPaginationNext = () => action(OpinionInstanceActionTypes.OPINION_CLAUSES_PAGINATION_NEXT);
export const opinionClausesPaginationPrevious = () => action(OpinionInstanceActionTypes.OPINION_CLAUSES_PAGINATION_PREVIOUS);
