import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { AttestationPage, setAttestationPage } from '../admin/attestations/store';
import { MyDatasetView, setMyDatasetView } from '../admin/my-datasets/store';
import { PlaybookPage, setPlaybookPage } from '../admin/playbook/store';
import { ClientFeaturePermission, FeaturePermission } from '../admin/users/store';
import { getClientHasFeaturePermission, getUser, getUserHasFeaturePermission, getUsername, getUserRole, logoutStarted, toggleMyProfileModal } from '../auth/login/store';
import { adminRoles, systemAdminRole, UserRole } from '../constants/permittedRoles';
import { ProfilePicture } from '../shared/picture/ProfilePicture';
import { OverflowTooltip } from '../shared/tooltip';
import styles from './HomeWrapper.module.scss';
import { SideMenuItem } from './SideMenuItem';
import { getRightMenuOpen, toggleRightMenu } from './store';
import { Scrollable } from '../shared/scrollable/Scrollable';
import { CollapsibleSideMenuItem } from './CollapsibleSideMenuItem';

const HEIGHT_OFFSET = 10;
const INTERCOM_HEIGHT = 68;

export const RightMenu: React.FC = () => {
    const [openParents, setOpenParents] = useState<string[]>([]);
    const { pathname } = useLocation();
    const username = useAppSelector(getUsername);
    const user = useAppSelector(getUser);
    const userRole = useAppSelector(getUserRole);
    const isOpen = useAppSelector(getRightMenuOpen);
    const hideIntercom = useMemo(() => (user && user.userDefaults && !user.userDefaults.showIntercom) || false, [user]);
    const rightWrapperHeight = useMemo(() => {
        let heightOffset = HEIGHT_OFFSET;
        if (!hideIntercom) {
            heightOffset += INTERCOM_HEIGHT;
        }
        return `calc(100% - ${heightOffset}px)`;
    }, [hideIntercom]);
    const dispatch = useAppDispatch();
    const closeMenu = () => dispatch(toggleRightMenu(false));

    const logout = () => {
        closeMenu();
        dispatch(logoutStarted());
    };

    const openAttestations = () => {
        dispatch(setAttestationPage(AttestationPage.SELECT));
        closeMenu();
    };

    const viewMyProfile = () => {
        closeMenu();
        dispatch(toggleMyProfileModal(true));
    };

    const openPlaybooks = () => {
        dispatch(setPlaybookPage(PlaybookPage.SELECT));
        closeMenu();
    };

    const openMyDatasets = () => {
        dispatch(setMyDatasetView(MyDatasetView.LIST, null, false));
        closeMenu();
    };

    const getIsParentOpen = useCallback((id: string) => openParents.includes(id) || pathname.includes(id), [openParents, pathname]);

    const getIsSelected = useCallback((id: string) => pathname.includes(id), [pathname]);

    const toggleParentOpen = useCallback((id: string) => {
        if (getIsParentOpen(id)) {
            setOpenParents(openParents.filter(openId => openId !== id));
        } else {
            setOpenParents([...openParents, id]);
        }
    }, [getIsParentOpen, openParents]);

    const isPermitted = useCallback((permittedRoles: UserRole[]) => permittedRoles.includes(userRole!), [userRole]);
    const adminOnly = isPermitted(adminRoles);
    const systemAdminOnly = isPermitted(systemAdminRole);
    const hasPlaybookManagementPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.PLAYBOOK_MANAGEMENT]));
    const hasEntityManagementPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.ENTITY_MANAGEMENT]));
    const hasGroupDocumentManagementFeaturePermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.DOCUMENT_GROUP_MANAGEMENT]));
    const hasSubCounterpartyTypePermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.SUB_COUNTERPARTY_TYPE]));
    const hasClientDocumentPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DOCUMENT_MODULE]));
    const hasOpinionPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.OPINION_MODULE]));
    const adminMenuVisible = adminOnly || hasEntityManagementPermission || hasPlaybookManagementPermission || hasSubCounterpartyTypePermission;

    const { profilePicture, initials } = useMemo(() => {
        if (!user) {
            return { profilePicture: undefined, initials: undefined };
        }
        return { profilePicture: user.profilePicture, initials: `${user.forenames.charAt(0)}${user.surname.charAt(0)}` };
    }, [user]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.rightMenu}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 10 } }}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            onRequestClose={closeMenu}
        >
            <div data-testid='right-menu-wrapper' className={styles.rightMenuWrapper} style={{ height: rightWrapperHeight }}>
                <div className={styles.sectionWrapper}>
                    <div className={styles.rightMenuUserWrapper}>
                        <OverflowTooltip className={styles.rightMenuUsername} overlayText={username || ''} />
                        <div className={styles.rightMenuUser}>
                            <ProfilePicture profilePicture={profilePicture} initials={initials} userIconSize={42} initialsFontSize={16} />
                        </div>
                    </div>
                    <SideMenuItem
                        label='My Profile'
                        selected={getIsSelected('my-profile')}
                        onClick={viewMyProfile}
                        textAlign='right'
                        testId='my-profile'
                    />
                    <SideMenuItem
                        label='Logout'
                        selected={getIsSelected('logout')}
                        onClick={logout}
                        textAlign='right'
                        testId='logout'
                    />
                </div>
                {adminMenuVisible &&
                    <div className={styles.sectionWrapper} style={{ maxHeight: 'calc(100% - 168px)', justifyContent: 'flex-end' }}>
                        <div className={styles.sectionSpacer} />
                        <div className={styles.scrollableWrapper}>
                            <Scrollable>
                                {adminOnly &&
                                    <SideMenuItem
                                        label='Users'
                                        selected={getIsSelected('admin/users')}
                                        onClick={closeMenu}
                                        textAlign='right'
                                        path='/admin/users'
                                        testId='users'
                                    />
                                }
                                {(adminOnly || hasEntityManagementPermission) &&
                                    <SideMenuItem
                                        label='Entities'
                                        selected={getIsSelected('admin/entities')}
                                        onClick={closeMenu}
                                        textAlign='right'
                                        path='/admin/entities'
                                        testId='entities'
                                    />
                                }
                                {adminOnly &&
                                    <SideMenuItem
                                        label='Attestations'
                                        selected={getIsSelected('admin/attestation')}
                                        onClick={openAttestations}
                                        textAlign='right'
                                        path='/admin/attestation'
                                        testId='attestations'
                                    />
                                }
                                {adminOnly &&
                                    <SideMenuItem
                                        label='Analytics'
                                        selected={getIsSelected('admin/analytics')}
                                        onClick={closeMenu}
                                        textAlign='right'
                                        path='/admin/analytics'
                                        testId='analytics'
                                    />
                                }
                                {adminOnly &&
                                    <SideMenuItem
                                        label='Workflow'
                                        selected={getIsSelected('admin/workflow')}
                                        onClick={closeMenu}
                                        textAlign='right'
                                        path='/admin/workflow'
                                        testId='workflow'
                                    />
                                }
                                {adminOnly &&
                                    <SideMenuItem
                                        label='My Datasets'
                                        selected={getIsSelected('admin/my-datasets')}
                                        onClick={openMyDatasets}
                                        textAlign='right'
                                        path='/admin/my-datasets'
                                        testId='my-datasets'
                                    />
                                }
                                {(adminOnly || hasPlaybookManagementPermission) &&
                                    <SideMenuItem
                                        label='Playbooks'
                                        selected={getIsSelected('admin/playbook')}
                                        onClick={openPlaybooks}
                                        textAlign='right'
                                        path='/admin/playbook'
                                        testId='playbook'
                                    />
                                }
                                {((hasSubCounterpartyTypePermission || adminOnly) && hasOpinionPermission) &&
                                    <SideMenuItem
                                        label='Sub Counterparty Types'
                                        selected={getIsSelected('admin/sub-counterparties')}
                                        onClick={closeMenu}
                                        textAlign='right'
                                        path='/admin/sub-counterparties'
                                        testId='sub-counterparties'
                                    />
                                }
                                {(hasClientDocumentPermission && hasGroupDocumentManagementFeaturePermission) &&
                                    <SideMenuItem
                                        label='My Document Groups'
                                        selected={getIsSelected('admin/document-groups')}
                                        onClick={openMyDatasets}
                                        textAlign='right'
                                        path='/admin/document-groups'
                                        testId='document-groups'
                                    />
                                }
                                {systemAdminOnly && <div className={styles.sectionWrapper}>
                                    <div className={styles.sectionSpacer} />
                                    <CollapsibleSideMenuItem
                                        isOpen={getIsParentOpen('system')}
                                        label='System'
                                        toggleOpen={() => toggleParentOpen('system')}
                                        testId='system'
                                        textAlign='right'
                                    >
                                        <SideMenuItem
                                            label='Datasets'
                                            selected={getIsSelected('admin/datasets')}
                                            onClick={closeMenu}
                                            textAlign='right'
                                            path='/admin/datasets'
                                            testId='datasets'
                                        />
                                        <SideMenuItem
                                            label='AI'
                                            selected={getIsSelected('admin/ai')}
                                            onClick={closeMenu}
                                            textAlign='right'
                                            path='/admin/ai'
                                            testId='ai'
                                        />
                                        <SideMenuItem
                                            label='Risk Tolerance'
                                            selected={getIsSelected('admin/risk-tolerance')}
                                            onClick={closeMenu}
                                            textAlign='right'
                                            path='/admin/risk-tolerance'
                                            testId='risk-tolerance'
                                        />
                                        <SideMenuItem
                                            label='Documents'
                                            selected={getIsSelected('admin/documents')}
                                            onClick={closeMenu}
                                            textAlign='right'
                                            path='/admin/documents'
                                            testId='documents'
                                        />
                                        <SideMenuItem
                                            label='Opinions'
                                            selected={getIsSelected('admin/opinions')}
                                            onClick={closeMenu}
                                            textAlign='right'
                                            path='/admin/opinions'
                                            testId='opinion-analytics'
                                        />
                                        <SideMenuItem
                                            label='Dropdown Lists'
                                            selected={getIsSelected('admin/dropdown-lists')}
                                            onClick={closeMenu}
                                            textAlign='right'
                                            path='/admin/dropdown-lists'
                                            testId='dropdown-lists'
                                        />
                                        <SideMenuItem
                                            label='Clients'
                                            selected={getIsSelected('admin/clients')}
                                            onClick={closeMenu}
                                            textAlign='right'
                                            path='/admin/clients'
                                            testId='clients'
                                        />
                                        <SideMenuItem
                                            label='Send Notification'
                                            selected={getIsSelected('admin/notification')}
                                            onClick={closeMenu}
                                            textAlign='right'
                                            path='/admin/notification'
                                            testId='send-notification'
                                        />
                                    </CollapsibleSideMenuItem>
                                </div>
                                }
                            </Scrollable>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
};
