import { min } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useWindowResize } from '../../../hooks/useWindowResize';
import { Icon } from '../icon/Icon';
import { Scrollable } from '../scrollable/Scrollable';
import styles from './TileSelect.module.scss';

export interface Tile {
    label: string;
    description?: string[];
    onClick: () => void;
    icon: React.FC;
    testId: string;
    iconSize?: number;
}

interface TileSelectProps {
    testId: string;
    tiles: Tile[];
}

const Tile: React.FC<Tile> = ({ label, description = [], onClick, icon, testId, iconSize }) => (
    <button className={styles.selectButton} onClick={onClick} data-testid={`${testId}-tile-select-page-wrapper`}>
        <div className={styles.selectIcon}>
            <Icon icon={icon} fontSize={iconSize} />
        </div>
        <div className={styles.selectLabel} data-testid={`${testId}-tile-select-page-label`}>{label}</div>
        <div className={styles.allDescriptionsWrapper}>
            <Scrollable>
                {description.map(point => (
                    <div className={styles.selectDescription} data-testid={`${testId}-tile-select-page-description`} key={point}>{point}</div>
                ))}
            </Scrollable>
        </div>
    </button>
);

export const TileSelect: React.FC<TileSelectProps> = ({ testId, tiles }) => {
    const [, screenHeight] = useWindowResize();
    const iconSize = useMemo(() => min([screenHeight / 4, 300])!, [screenHeight]);
    const parentTestId = testId;
    return (
        <div className={styles.selectWrapper} data-testid={`${testId}-tile-select-wrapper`}>
            <div className={styles.selectionWrapper}>
                {tiles.map(({ label, onClick, description, icon, testId }, index) => <Tile
                    key={index}
                    label={label}
                    onClick={onClick}
                    description={description}
                    icon={icon}
                    testId={`${parentTestId}-${testId}`}
                    iconSize={iconSize}
                />
                )}
            </div>
        </div>
    );
};
