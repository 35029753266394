import { push } from 'redux-first-history';
import { flow, isEqual, isNull, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { put, all, fork, call, takeLeading, takeEvery, select, takeLatest, debounce } from 'redux-saga/effects';

import { upsertScheduledAction, fetchCalendarEvents, fetchAllCalendarEvents, deleteScheduledAction, skipScheduledAction, recoverScheduledAction } from '../../../../services/scheduler';
import { runIndirectRules } from '../../../../services/workflow';
import { formatDate, DATABASE_DATE_FORMAT } from '../../../../utils/luxon';
import {
    deleteScheduledActionFailed,
    deleteScheduledActionSuccessful,
    fetchAllCalendarEventsFailed,
    fetchAllCalendarEventsStarted,
    fetchAllCalendarEventsSuccessful,
    fetchCalendarEventsFailed,
    fetchCalendarEventsStarted,
    fetchCalendarEventsSuccessful,
    runIndirectRulesFailed,
    runIndirectRulesSuccessful,
    saveScheduledActionFailed,
    saveScheduledActionSuccessful,
    setSelectedTabValid,
    setWorkflowPage,
    showUpdatedMessage,
    skipScheduledActionStarted,
    toggleScheduleWizard,
    skipScheduledActionSuccessful,
    skipScheduledActionFailed,
    recoverScheduledActionStarted,
    recoverScheduledActionSuccessful,
    recoverScheduledActionFailed
} from './actions';
import { getAllScheduledActions, getCalendarEvent, getConfirmDeleteEvent, getScheduledDetailsConfirmed, getSelectedEventDate, getSelectedEventTab, getSelectedMonthYear, getWorkflowPage } from './selectors';
import { CalendarEvent, CreateAttestationContent, ScheduledActionsListView, ScheduledEventsByMonth, ScheduleEventTab, Scheduler, SchedulerAction, SchedulerDB, SendEmailContent, WorkflowActionTypes, WorkflowPage } from './types';

export const stripEvent = (event: CalendarEvent | SchedulerDB): Scheduler => flow(
    unset('clientId'),
    unset('createdBy'),
    unset('createdDate'),
    unset('modifiedDate'),
    unset('modifiedBy'),
    unset('end'),
    unset('start')
)(event);

export function* attemptRunIndirectRules() {
    try {
        yield call(runIndirectRules);
        yield put(runIndirectRulesSuccessful());
        toast('Indirect rules successfully ran');
    } catch (e) {
        yield put(runIndirectRulesFailed((e as Error).message));
        toast.error('Unable to run indirect rules. Please try again.');
    }
}

function* runIndirectRulesWatcher() {
    yield takeLeading(WorkflowActionTypes.RUN_INDIRECT_RULES_STARTED, attemptRunIndirectRules);
}

export function* checkTabValid() {
    let tabValid = false;
    const selectedEvent: Scheduler = yield select(getCalendarEvent);
    const selectedTab: ScheduleEventTab = yield select(getSelectedEventTab);
    if (selectedTab === ScheduleEventTab.SCHEDULE) {
        tabValid = !isNull(selectedEvent.action) && !!selectedEvent.firstDate && (!selectedEvent.repeat || (!!selectedEvent.repeat && !isNull(selectedEvent.interval) && !isNull(selectedEvent.intervalValue)));
    }
    if (selectedTab === ScheduleEventTab.CONTENT) {
        if (selectedEvent.action === SchedulerAction.CREATE_ATTESTATION) {
            const content = selectedEvent.content as CreateAttestationContent;
            tabValid = !isNull(content.attestationFormId) && content.instancesToCreate.every(({ userIds, deadlineInterval, deadlineIntervalValue }) =>
                userIds.every(({ userId }) => !isNull(userId))
                && (isNull(deadlineInterval) && isNull(deadlineIntervalValue)) || (!isNull(deadlineInterval) && !isNull(deadlineIntervalValue))
            );
        }
        if (selectedEvent.action === SchedulerAction.SEND_EMAIL) {
            const content = selectedEvent.content as SendEmailContent;
            tabValid = !isNull(content) && (!isNull(content.userIds) && !!content.userIds.length) && !!content.subject.length && (!isNull(content.wysiwygTemplate) && content.wysiwygTemplate.blocks[0].text.length > 0);
        }
    }
    if (selectedTab === ScheduleEventTab.CONFIRM) {
        const detailsConfirmed: boolean = yield select(getScheduledDetailsConfirmed);
        tabValid = detailsConfirmed;
    }
    yield put(setSelectedTabValid(tabValid));
}

function* scheduleUpdatedWatcher() {
    yield takeEvery([
        WorkflowActionTypes.TOGGLE_SCHEDULE_WIZARD,
        WorkflowActionTypes.UPDATE_CONTENT_ATTESTATION_INSTANCES,
        WorkflowActionTypes.UPDATE_CONTENT_ATTESTATION_ID,
        WorkflowActionTypes.SET_EVENT_VALUE,
        WorkflowActionTypes.SET_SELECTED_EVENT_TAB,
        WorkflowActionTypes.SET_SCHEDULED_EMAIL_CONTENT,
        WorkflowActionTypes.CONFIRM_SCHEDULED_DETAILS
    ], checkTabValid);
}

export function* scheduledActionUpdated() {
    const selectedEvent: Scheduler = yield select(getCalendarEvent);
    if (selectedEvent.scheduledActionId) {
        const allScheduledActions: SchedulerDB[] = yield select(getAllScheduledActions);
        const strippedAction = stripEvent(allScheduledActions.find(({ scheduledActionId }) => scheduledActionId === selectedEvent.scheduledActionId)!);
        const originalScheduledAction = { ...strippedAction, firstDate: formatDate(strippedAction.firstDate!, DATABASE_DATE_FORMAT) };
        const hasUpdated = !isEqual(originalScheduledAction, selectedEvent);
        yield put(showUpdatedMessage(hasUpdated));
    }
}

function* scheduledActionUpdatedWatcher() {
    yield takeEvery([
        WorkflowActionTypes.UPDATE_CONTENT_ATTESTATION_INSTANCES,
        WorkflowActionTypes.UPDATE_CONTENT_ATTESTATION_ID,
        WorkflowActionTypes.SET_EVENT_VALUE,
        WorkflowActionTypes.SET_SCHEDULED_EMAIL_CONTENT
    ], scheduledActionUpdated);
}

const manipulateCalendarEvents = (eventsByMonth: ScheduledEventsByMonth, monthYear: string) => Object.entries(eventsByMonth).reduce((allEvents: CalendarEvent[], [day, events]) => [...allEvents, ...events.map(event => {
    const date = new Date(`${monthYear}-${`0${day}`.slice(-2)}`);
    return { ...event, start: date, end: date };
})], []);

export function* attemptSaveScheduledAction() {
    try {
        const scheduler: Scheduler = yield select(getCalendarEvent);
        const { action, scheduledActionId } = scheduler;
        const monthYear: string = yield select(getSelectedMonthYear);
        const { scheduledActions, allScheduledActions }: { scheduledActions: ScheduledEventsByMonth; allScheduledActions: SchedulerDB[]; } = yield call(upsertScheduledAction, { scheduler, monthYear });
        const events = manipulateCalendarEvents(scheduledActions, monthYear);
        yield put(saveScheduledActionSuccessful(events, allScheduledActions));
        yield put(toggleScheduleWizard(false));
        toast(`Scheduled ${action === SchedulerAction.CREATE_ATTESTATION ? 'attestation' : 'email'} successfully ${scheduledActionId ? 'updated' : 'saved'}.`);
    } catch (e) {
        toast.error('Unable to save scheduled action. Please try again.');
        yield put(saveScheduledActionFailed((e as Error).message));
    }
}

function* saveScheduledActionWatcher() {
    yield takeLeading(WorkflowActionTypes.SAVE_SCHEDULED_ACTION_STARTED, attemptSaveScheduledAction);
}

export function* attemptFetchCalendarEvents() {
    try {
        const monthYear: string = yield select(getSelectedMonthYear);
        const calendarEvents: ScheduledEventsByMonth = yield call(fetchCalendarEvents, { monthYear });
        const events = manipulateCalendarEvents(calendarEvents, monthYear);
        yield put(fetchCalendarEventsSuccessful(events));
    } catch (e) {
        yield put(fetchCalendarEventsFailed((e as Error).message));
        toast.error('Unable to fetch calendar events. Please try again.');
    }
}

function* fetchCalendarEventsWatcher() {
    yield takeLatest(WorkflowActionTypes.FETCH_CALENDAR_EVENTS_STARTED, attemptFetchCalendarEvents);
}

export function* attemptFetchAllCalendarEvents({ payload }: ReturnType<typeof fetchAllCalendarEventsStarted>) {
    try {
        const isArchived = payload === ScheduledActionsListView.ARCHIVED ? 1 : 0;

        const calendarEvents: SchedulerDB[] = yield call(fetchAllCalendarEvents, { isArchived });
        yield put(fetchAllCalendarEventsSuccessful(calendarEvents));
    } catch (e) {
        yield put(fetchAllCalendarEventsFailed((e as Error).message));
        toast.error('Unable to fetch calendar events. Please try again.');
    }
}

function* fetchAllCalendarEventsWatcher() {
    yield takeLatest(WorkflowActionTypes.FETCH_ALL_CALENDAR_EVENTS_STARTED, attemptFetchAllCalendarEvents);
}

export function* attemptDeleteScheduledAction() {
    try {
        const scheduledActionId: number = yield select(getConfirmDeleteEvent);
        const monthYear: string = yield select(getSelectedMonthYear);

        const { scheduledActions, allScheduledActions }: { scheduledActions: ScheduledEventsByMonth; allScheduledActions: SchedulerDB[]; } = yield call(deleteScheduledAction, { scheduledActionId, monthYear });
        const events = manipulateCalendarEvents(scheduledActions, monthYear);
        yield put(deleteScheduledActionSuccessful(events, allScheduledActions));
        yield put(toggleScheduleWizard(false));
        toast('Scheduled action successfully deleted.');
    } catch (e) {
        toast.error('Unable to delete scheduled action. Please try again.');
        yield put(deleteScheduledActionFailed((e as Error).message));
    }
}

function* deleteScheduledActionWatcher() {
    yield takeLeading(WorkflowActionTypes.DELETE_SCHEDULED_ACTION_STARTED, attemptDeleteScheduledAction);
}

export function* attemptSkipScheduledAction({ payload }: ReturnType<typeof skipScheduledActionStarted>) {
    const { scheduledActionId, isSkipped } = payload;
    try {
        const dateToSkip: string = yield select(getSelectedEventDate);
        const monthYear: string = yield select(getSelectedMonthYear);
        const scheduledActions: ScheduledEventsByMonth = yield call(skipScheduledAction, { scheduledActionId, dateToSkip, monthYear, isSkipped });
        const events = manipulateCalendarEvents(scheduledActions, monthYear);
        yield put(skipScheduledActionSuccessful(events));
        yield put(toggleScheduleWizard(false));
        toast(`Scheduled action successfully ${isSkipped ? 'included' : 'skipped'}.`);
    } catch (e) {
        toast.error(`Unable to ${isSkipped ? 'include' : 'skip'} scheduled action. Please try again.`);
        yield put(skipScheduledActionFailed((e as Error).message));
    }
}

function* skipScheduledActionWatcher() {
    yield takeLeading(WorkflowActionTypes.SKIP_SCHEDULED_ACTION_STARTED, attemptSkipScheduledAction);
}

export function* attemptRecoverScheduledAction({ payload }: ReturnType<typeof recoverScheduledActionStarted>) {
    try {
        const monthYear: string = yield select(getSelectedMonthYear);
        const { scheduledActions, allScheduledActions }: { scheduledActions: ScheduledEventsByMonth; allScheduledActions: SchedulerDB[]; } = yield call(recoverScheduledAction, { scheduledAction: payload, monthYear });
        const events = manipulateCalendarEvents(scheduledActions, monthYear);
        yield put(recoverScheduledActionSuccessful(events, allScheduledActions));
        yield put(toggleScheduleWizard(false));
        toast('Scheduled action successfully recovered.');
    } catch (e) {
        toast.error('Unable to recover scheduled action. Please try again.');
        yield put(recoverScheduledActionFailed((e as Error).message));
    }
}

function* recoverScheduledActionWatcher() {
    yield takeLeading(WorkflowActionTypes.RECOVER_SCHEDULED_ACTION_STARTED, attemptRecoverScheduledAction);
}

export function* monthYearSelectedWorker() {
    yield put(fetchCalendarEventsStarted());
}

function* monthYearSelectedWatcher() {
    yield debounce(1000, WorkflowActionTypes.SET_SELECTED_MONTH_YEAR, monthYearSelectedWorker);
}

export function* redirectWorkflowPage({ payload }: ReturnType<typeof setWorkflowPage>) {
    if (payload !== WorkflowPage.SELECT) {
        const navigationPath = `/admin/workflow/${payload === WorkflowPage.CALENDAR ? 'calendar' : 'list'}`;
        yield put(push(navigationPath));
    }
}

function* redirectWorkflowPageWatcher() {
    yield takeEvery(WorkflowActionTypes.SET_WORKFLOW_PAGE, redirectWorkflowPage);
}

export function* redirectScheduledAction({ payload }: ReturnType<typeof toggleScheduleWizard>) {
    const { event, isOpen } = payload;
    const page: WorkflowPage = yield select(getWorkflowPage);
    if (isOpen && event && event.scheduledActionId) {
        const navigationPath = `/admin/workflow/${page}/${event.scheduledActionId}`;
        yield put(push(navigationPath));
    } else if (!isOpen) {
        yield put(push(`/admin/workflow/${page}`));
    }
}

function* redirectScheduledActionWatcher() {
    yield takeEvery(WorkflowActionTypes.TOGGLE_SCHEDULE_WIZARD, redirectScheduledAction);
}

export function* workflowSaga() {
    yield all([
        fork(runIndirectRulesWatcher),
        fork(scheduleUpdatedWatcher),
        fork(saveScheduledActionWatcher),
        fork(fetchCalendarEventsWatcher),
        fork(monthYearSelectedWatcher),
        fork(scheduledActionUpdatedWatcher),
        fork(fetchAllCalendarEventsWatcher),
        fork(deleteScheduledActionWatcher),
        fork(redirectWorkflowPageWatcher),
        fork(redirectScheduledActionWatcher),
        fork(skipScheduledActionWatcher),
        fork(recoverScheduledActionWatcher)
    ]);
}
