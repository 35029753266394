import React, { useCallback, useMemo } from 'react';
import { RawDraftContentState } from 'draft-js';
import { isNull, isUndefined } from 'lodash/fp';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { RadioListOption } from '../../../shared/radio/RadioList';
import { RadioRow } from '../../../shared/radio/RadioRow';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { isEmpty, WYSIWYG } from '../../../shared/wysiwyg/WYSIWYG';
import { DoraSupplyChainDetails, getCurrentSupplyChain, getDetailsInformation, getSelectedSupplyChainId, toggleITServiceDescriptionModal, updateElementDetails, getServiceDescriptionModalOpen } from '../store';
import styles from '../SupplyChain.module.scss';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { doraICTServiceTypes } from '../../../constants/dora';
import { DoraDescriptionModal } from '../../../shared/modal/DoraDescriptionModal';
import { NewElementCompany } from './NewElementCompany';

export const Details: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedElementId = useAppSelector(getSelectedSupplyChainId);
    const details = useAppSelector(getDetailsInformation);
    const supplyChain = useAppSelector(getCurrentSupplyChain);
    const parentCompanyId = useMemo(() => (supplyChain && supplyChain.companyId) || 0, [supplyChain]);

    const isNewElement = useMemo(() => isNull(selectedElementId), [selectedElementId]);

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));
    const serviceDescriptionModalOpen = useAppSelector(getServiceDescriptionModalOpen);

    const { serviceProvided, furtherICTServiceDetails, isMaterial, justification } = details;

    const serviceProvidedOptions = doraICTServiceTypes.map(({ type }) => ({ value: type, label: type }));
    const serviceProvidedValue = useMemo(() => serviceProvided ? ({ value: serviceProvided, label: serviceProvided }) : null, [serviceProvided]);

    const updateDetails = useCallback((key: keyof DoraSupplyChainDetails, value: RawDraftContentState | boolean | string | null | undefined) => dispatch(updateElementDetails(key, value)), [dispatch]);

    const updateContent = useCallback((key: keyof DoraSupplyChainDetails, rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            if (!isNull(details[key])) {
                updateDetails(key, null);
            }
        } else {
            updateDetails(key, rawValue);
        }
    }, [updateDetails, details]);

    const updateDropdownOption = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        updateDetails('serviceProvided', selected);
    };

    const toggleDescriptionModal = useCallback(() => dispatch(toggleITServiceDescriptionModal(!serviceDescriptionModalOpen)), [dispatch, serviceDescriptionModalOpen]);

    const isMaterialOptions: RadioListOption[] = useMemo(() => [
        {
            id: 'yes',
            isSelected: isMaterial ?? false,
            label: 'Yes',
            disabled: !hasDoraFullAccessPermission
        },
        {
            id: 'no',
            isSelected: !isUndefined(isMaterial) && !isMaterial,
            label: 'No',
            disabled: !hasDoraFullAccessPermission
        }
    ], [isMaterial, hasDoraFullAccessPermission]);

    const toggleIsMaterial = useCallback((id: string) => {
        let value: boolean | undefined = id === 'yes';
        if (!isUndefined(isMaterial) && (isMaterial && value || !isMaterial && !value)) {
            value = undefined;
        }
        updateDetails('isMaterial', value);
    }, [updateDetails, isMaterial]);

    return (
        <div className={styles.supplyChainElementDetailsWrapper}>
            <Scrollable>
                {isNewElement && <NewElementCompany parentCompanyId={parentCompanyId} />}
                <div className={styles.isMaterialWrapper}>
                    <div className={styles.isMaterialLabel}>Is Material?</div>
                    <div className={styles.isMaterialRadioWrapper}>
                        <RadioRow options={isMaterialOptions} onChange={toggleIsMaterial} testId='supply-chain-is-material' fontSize={16} />
                    </div>
                </div>
                <div className={styles.detailsInputWrapper}>
                    <div className={styles.detailsInputLabel}>ICT Service Provided:</div>
                    <div className={styles.serviceProvidedWrapper}>
                        <div className={styles.dropdownWrapper}>
                            <Dropdown
                                onChange={updateDropdownOption}
                                value={serviceProvidedValue}
                                options={serviceProvidedOptions}
                                disabled={!hasDoraFullAccessPermission}
                                menuPortalTarget={document.body}
                            />
                        </div>
                        <button className={styles.button} onClick={toggleDescriptionModal}>What does this mean?</button>
                        <DoraDescriptionModal isOpen={serviceDescriptionModalOpen} toggleDescriptionModal={toggleDescriptionModal} ictOrEntityTypes={doraICTServiceTypes} label='IT Service Provided Descriptions' />
                    </div>
                </div>
                <div className={styles.detailsInputWrapper}>
                    <div className={styles.detailsInputLabel}>Further Details of ICT Service Provided:</div>
                    <WYSIWYG
                        content={furtherICTServiceDetails}
                        updateContent={val => updateContent('furtherICTServiceDetails', val)}
                        disabled={!hasDoraFullAccessPermission}
                    />
                </div>
                <div className={styles.detailsInputWrapper}>
                    <div className={styles.detailsInputLabel}>Justification:</div>
                    <WYSIWYG
                        content={justification}
                        updateContent={val => updateContent('justification', val)}
                        disabled={!hasDoraFullAccessPermission}
                    />
                </div>
            </Scrollable>
        </div>
    );
};
