import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GaugeChart from 'react-gauge-chart';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { IconButton } from '../../../../shared/button/IconButton';
import { Sort } from '../../../../shared/icons';
import { InformationTooltip } from '../../../../shared/tooltip';
import styles from '../../Analytics.module.scss';
import { AllDocumentsRiskTolerance, DocumentAnalyticsChart, fetchDocumentRiskToleranceSuccessful, setRiskToleranceDatasetId } from '../../store';
import { DocumentRiskToleranceLevel } from './DocumentRiskToleranceLevel';

const { red, amber, gold, yellowGreen, green, grey } = styles;

interface MinifiedRiskToleranceChartProps {
    tile: DocumentAnalyticsChart;
    tileInView: null | DocumentAnalyticsChart;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    dimensions: {
        height: number;
        width: number;
    };
    allDocumentsRiskTolerance: AllDocumentsRiskTolerance;
    entityId?: number;
    isPreExecution?: boolean;
    marginBottom?: string;
}

export const MinifiedRiskToleranceChart: React.FC<MinifiedRiskToleranceChartProps> = ({
    tile,
    setTileInView,
    tileInView,
    dimensions,
    allDocumentsRiskTolerance,
    entityId,
    isPreExecution = false,
    marginBottom = '0px'
}) => {

    const dispatch = useAppDispatch();
    const [activePercentage, setActivePercentage] = useState<number>(0);

    const toggleTileView = useCallback(() => {
        setTileInView(isNull(tileInView) ? tile : null);
        if (!isNull(tileInView)) {
            dispatch(fetchDocumentRiskToleranceSuccessful(null));
        }
    }, [setTileInView, tileInView, dispatch, tile]);

    const documentRiskPercentage = useMemo(() => allDocumentsRiskTolerance.totalDocuments > 0 ? allDocumentsRiskTolerance.overallPercentage : null, [allDocumentsRiskTolerance]);

    const totalDocuments = useMemo(() => allDocumentsRiskTolerance.totalDocuments, [allDocumentsRiskTolerance]);

    const totalDocumentsLabel = useMemo(() => `Total Documents: ${totalDocuments}`, [totalDocuments]);

    useEffect(() => () => {
        dispatch(fetchDocumentRiskToleranceSuccessful(null));
        dispatch(setRiskToleranceDatasetId(null));
    }, [dispatch]);

    useEffect(() => {
        if (!isNull(documentRiskPercentage) && documentRiskPercentage !== activePercentage) {
            setActivePercentage(documentRiskPercentage);
        }
    }, [activePercentage, documentRiskPercentage]);

    const testId = useMemo(() => {
        let id = 'document-risk-tolerance-chart';
        if (isPreExecution) {
            id = 'pre-execution-'.concat(id);
        }
        if (!isUndefined(entityId)) {
            id = 'entity-'.concat(id);
        }
        return id;
    }, [isPreExecution, entityId]);

    const chartLabel = useMemo(() => {
        if (isPreExecution) {
            return 'Pre Execution';
        }
        if (!isUndefined(entityId)) {
            return 'Entity';
        }
        return 'Full';
    }, [isPreExecution, entityId]);

    const tooltipInfo = useMemo(() => {
        let portfolioDescription = 'entire document portfolio';
        if (!isUndefined(entityId)) {
            portfolioDescription = 'documents for the selected entity';
        }
        if (isPreExecution) {
            portfolioDescription = 'pre execution documents';
        }
        return `This is a summary of the risk associated with your ${portfolioDescription}.`;
    }, [entityId, isPreExecution]);

    const wrapperStyle = useMemo(() => ({ height: `${dimensions.height - parseInt(marginBottom)}px`, width: dimensions.width, marginBottom, borderBottom: parseInt(marginBottom) > 0 ? `1px dotted ${grey}` : 'none' }), [dimensions, marginBottom]);

    const gaugeWidthPercentage = useMemo(() => (dimensions.height / dimensions.width) * 170, [dimensions]);
    return (
        <div className={styles.minifiedRiskToleranceChartWrapper} style={wrapperStyle} data-testid={`${testId}-wrapper`}>
            <div className={styles.riskChart}>
                <div className={styles.gaugeChartWrapper}>
                    <GaugeChart
                        nrOfLevels={5}
                        colors={[green, yellowGreen, gold, amber, red]}
                        needleColor={grey}
                        needleBaseColor={grey}
                        hideText
                        percent={(documentRiskPercentage || 0) / 100}
                        animate={activePercentage !== documentRiskPercentage}
                        style={{ width: `${gaugeWidthPercentage}%`, height: '100%', margin: 'auto' }}
                    />
                </div>
                <DocumentRiskToleranceLevel percentage={documentRiskPercentage} chartIsFullScreen={false} />
                <div className={styles.totalDocumentsWrapper}>{totalDocumentsLabel}</div>
            </div>
            <div className={styles.chartHeader}>
                <div className={styles.chartTitle}>{chartLabel}</div>
                <div className={styles.chartDescription}><InformationTooltip content={tooltipInfo} /></div>
                <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
            </div>
        </div>
    );
};
