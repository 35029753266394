import React from 'react';
import classnames from 'classnames';

import { OverflowTooltip, InformationTooltip } from '../../../../shared/tooltip';
import styles from './TablePreview.module.scss';
import { DatasetFieldSettings, DatasetFieldType, TableDatasetFieldType } from '../../../../datasets/store';
import { useFieldHighlightColor } from '../../../../../hooks/useFieldHighlightColor';

interface TableHeaderProps {
    id: string;
    label: string;
    description: string;
    refLabel?: string
    settings: DatasetFieldSettings
    type: TableDatasetFieldType;
    isFirstCell: boolean;
    isLastCell: boolean;
}

export interface CellWidths {
    [DatasetFieldType.TEXT]: number;
    [DatasetFieldType.LONGTEXT]: number;
    [DatasetFieldType.NUMBER]: number;
    [DatasetFieldType.DATE]: number;
    [DatasetFieldType.DROPDOWN]: number;
    [DatasetFieldType.TIME]: number;
    [DatasetFieldType.LINKFIELD]: number;
    [DatasetFieldType.CHECKBOX]: number;
    [DatasetFieldType.DATASET]: number;
    [DatasetFieldType.DOCUMENT]: number;
    [DatasetFieldType.WIZARD]: number;
    [DatasetFieldType.WYSIWYG]: number;
    [DatasetFieldType.CALCULATOR]: number;
    [DatasetFieldType.PARTY]: number;
    [DatasetFieldType.CURRENCY_AMOUNT]: number;
}

export const widthsByType: CellWidths = {
    [DatasetFieldType.TEXT]: 200,
    [DatasetFieldType.LONGTEXT]: 250,
    [DatasetFieldType.NUMBER]: 175,
    [DatasetFieldType.DATE]: 200,
    [DatasetFieldType.DROPDOWN]: 230,
    [DatasetFieldType.DOCUMENT]: 230,
    [DatasetFieldType.TIME]: 230,
    [DatasetFieldType.LINKFIELD]: 230,
    [DatasetFieldType.CHECKBOX]: 80,
    [DatasetFieldType.DATASET]: 180,
    [DatasetFieldType.WIZARD]: 200,
    [DatasetFieldType.WYSIWYG]: 500,
    [DatasetFieldType.CALCULATOR]: 400,
    [DatasetFieldType.PARTY]: 230,
    [DatasetFieldType.CURRENCY_AMOUNT]: 230
};

export const TableHeader: React.FC<TableHeaderProps> = ({ label, description, id, refLabel, settings, type, isFirstCell, isLastCell }) => {
    const cellWidth = `${widthsByType[type]}px`;
    const { color, backgroundColor } = useFieldHighlightColor(settings.highlight, true);
    return (
        <div
            className={classnames(styles.tableHeaderWrapper, {
                [styles.firstCell]: isFirstCell,
                [styles.lastCell]: isLastCell
            })}
            style={{ width: cellWidth, minWidth: cellWidth, color, backgroundColor }}
        >
            <OverflowTooltip className={classnames(styles.headerLabel, { [styles.emptyHeaderLabel]: !label })} overlayText={label || 'Label...'} testId={`table-preview-header-${id}-label`} />
            {description && <InformationTooltip content={description} />}
            {refLabel && settings.showRef && <InformationTooltip content={refLabel} label='?' />}
        </div>
    );
};
