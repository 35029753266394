import { Reducer } from 'redux';
import { set, flow, isNull } from 'lodash/fp';

import { AdminAIActionTypes, AdminAIState, DocumentQuery, SystemIdInfoUI } from './types';

export const initialDocumentQuery = (aiVersion: number, queryOrder: number,): DocumentQuery => ({
    documentQueryId: null,
    question: '',
    guidanceNotes: '',
    queryKey: '',
    systemId: '',
    aiVersion,
    queryOrder,
    multiRowQuery: 0,
    isEdgeCase: 0,
    partyRowExists: 0,
    totalQueries: 0,
    correctQueries: 0,
    documentNameIds: [],
    percentage: 0,
    defaultAnswer: null,
    dropdownListId: null,
    isMultiSelect: 0,
    fieldType: null
});

export const INITIAL_STATE: AdminAIState = {
    isFetchingAgreementTypes: false,
    isFetchingQueries: false,
    agreementTypes: [],
    portfolioTotal: null,
    selectedAgreement: null,
    savedQueries: [],
    currentQueries: [],
    queriesUpdated: false,
    isEditing: false,
    lastUpdatedOrder: null,
    agreementTypeSystemInfo: [],
    selectedClientId: null
};

export const adminAIReducer: Reducer<AdminAIState> = (state = INITIAL_STATE, { payload, type }): AdminAIState => {
    switch (type) {
        case AdminAIActionTypes.FETCH_AI_AGREEMENT_TYPES_STARTED:
            return set('isFetchingAgreementTypes', true, state);
        case AdminAIActionTypes.FETCH_AI_AGREEMENT_TYPES_SUCCESSFUL:
            return flow(
                set('isFetchingAgreementTypes', false),
                set('agreementTypes', payload.agreementTypes),
                set('portfolioTotal', payload.portfolioTotal)
            )(state);
        case AdminAIActionTypes.FETCH_AI_AGREEMENT_TYPES_FAILED:
            return set('isFetchingAgreementTypes', false, state);
        case AdminAIActionTypes.FETCH_AGREEMENT_TYPE_QUERIES_STARTED:
            return flow(
                set('isFetchingQueries', true),
                set('agreementTypeSystemInfo', [])
            )(state);
        case AdminAIActionTypes.FETCH_AGREEMENT_TYPE_QUERIES_SUCCESSFUL: {
            const { queries, systemInfo } = payload;
            return flow(
                set('isFetchingQueries', false),
                set('savedQueries', queries),
                set('currentQueries', queries),
                set('agreementTypeSystemInfo', systemInfo)
            )(state);
        }
        case AdminAIActionTypes.FETCH_AGREEMENT_TYPE_QUERIES_FAILED:
            return set('isFetchingQueries', false, state);
        case AdminAIActionTypes.SET_SELECTED_AI_AGREEMENT_TYPE: {
            if (isNull(payload.agreementTypeId)) {
                return flow(
                    set('selectedAgreement', null),
                    set('savedQueries', []),
                    set('currentQueries', []),
                    set('savedQueryOrder', []),
                    set('currentQueryOrder', [])
                )(state);
            }
            return set('selectedAgreement', payload.agreementTypeId, state);
        }
        case AdminAIActionTypes.SET_QUERY_ORDER:
            return set('currentQueries', payload, state);
        case AdminAIActionTypes.SAVE_QUERY_ORDER_STARTED:
            return set('isFetchingQueries', true, state);
        case AdminAIActionTypes.SAVE_QUERY_ORDER_SUCCESSFUL:
            return flow(
                set('isFetchingQueries', false),
                set('savedQueries', payload),
                set('currentQueries', payload)
            )(state);
        case AdminAIActionTypes.SAVE_QUERY_ORDER_FAILED:
            return set('isFetchingQueries', false, state);
        case AdminAIActionTypes.ADD_NEW_DOCUMENT_QUERY: {
            const version = state.savedQueries.length > 0 ? Math.max(...state.savedQueries.map(({ aiVersion }) => aiVersion)) + 1 : 1;
            const queryOrder = state.currentQueries.length + 1;
            const newQuery = initialDocumentQuery(version, queryOrder);
            const updatedQueries = [...state.currentQueries, newQuery];
            return set('currentQueries', updatedQueries, state);
        }
        case AdminAIActionTypes.SET_DOCUMENT_QUERIES_UPDATED:
            return set('queriesUpdated', payload, state);
        case AdminAIActionTypes.DELETE_DOCUMENT_QUERY: {
            const updatedQueries = state.currentQueries.reduce((acc: DocumentQuery[], cur) => {
                if (cur.queryOrder < payload) {
                    acc.push(cur);
                }
                if (cur.queryOrder > payload) {
                    acc.push({ ...cur, queryOrder: cur.queryOrder - 1 });
                }
                return acc;
            }, []);
            return set('currentQueries', updatedQueries, state);
        }
        case AdminAIActionTypes.UPDATE_DOCUMENT_QUERY: {
            const { queryOrder, key, value }: { queryOrder: number; key: keyof DocumentQuery; value: boolean | string; } = payload;
            const updatedQueries = state.currentQueries.map(query => query.queryOrder === queryOrder ? ({ ...query, [key]: value }) : (query));
            return set('currentQueries', updatedQueries, state);
        }
        case AdminAIActionTypes.UPDATE_DOCUMENT_QUERY_SYSTEM_INFO: {
            const { queryOrder, value }: { queryOrder: number; value: SystemIdInfoUI; } = payload;
            const { dropdownListId, isMultiSelect, systemId, type } = value;
            const updatedQueries = state.currentQueries.map(query => query.queryOrder === queryOrder ? flow(
                set('systemId', systemId),
                set('isMultiSelect', isMultiSelect ? 1 : 0),
                set('dropdownListId', dropdownListId),
                set('fieldType', type),
                set('defaultAnswer', null)
            )(query) : query);
            return set('currentQueries', updatedQueries, state);
        }
        case AdminAIActionTypes.SET_IS_EDITING_DOCUMENT_QUERIES: {
            if (!payload) {
                return flow(
                    set('isEditing', false),
                    set('queriesUpdated', false),
                    set('currentQueries', state.savedQueries)
                )(state);
            }
            return set('isEditing', true, state);
        }
        case AdminAIActionTypes.SAVE_UPDATED_QUERIES_STARTED:
            return set('isFetchingQueries', true, state);
        case AdminAIActionTypes.SAVE_UPDATED_QUERIES_SUCCESSFUL: {
            const { agreementTypes, portfolioTotal, queries } = payload;
            return flow(
                set('isFetchingQueries', false),
                set('isEditing', false),
                set('queriesUpdated', false),
                set('savedQueries', queries),
                set('currentQueries', queries),
                set('agreementTypes', agreementTypes),
                set('portfolioTotal', portfolioTotal)
            )(state);
        }
        case AdminAIActionTypes.SAVE_UPDATED_QUERIES_FAILED:
            return set('isFetchingQueries', false, state);
        case AdminAIActionTypes.SET_LAST_UPDATED_ORDER: {
            return set('lastUpdatedOrder', payload, state);
        }
        case AdminAIActionTypes.SET_AI_QUERIES_SELECTED_CLIENT:
            return set('selectedClientId', payload.selectedClientId, state);
        default:
            return state;
    }
};
