import React, { useMemo, useState } from 'react';
import { Options } from 'react-select';

import { RadioButton } from '../../../shared/button/RadioButton';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { DropdownSummary } from '../../../shared/dropdown/DropdownSummary';
import { Delete, Minus, Tick } from '../../../shared/icons';
import { PositionModal, Position } from '../../../shared/modal/PositionModal';
import { OverflowTooltip } from '../../../shared/tooltip';
import styles from './ConfigureDatasets.module.scss';

const { amber, green } = styles;

interface DatasetDocumentSelectProps {
    selectedDocuments: DropdownOption[];
    onChange: (dropdownValue: DropdownOption | Options<DropdownOption> | null) => void;
    documentNames: DropdownOption[];
    label: string;
}

export const DatasetDocumentSelect: React.FC<DatasetDocumentSelectProps> = ({ selectedDocuments, onChange, documentNames, label }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);

    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        setIsOpen(true);
    };

    const allSelected = useMemo(() => selectedDocuments.length === documentNames.length, [selectedDocuments, documentNames]);
    const backgroundColor = useMemo(() => allSelected ? green : amber, [allSelected]);
    const icon = useMemo(() => {
        if (allSelected) {
            return Tick;
        }
        if (!selectedDocuments.length) {
            return Delete;
        }
        return Minus;
    }, [allSelected, selectedDocuments]);

    return (
        <>
            <RadioButton fontSize={10} withBackground={true} backgroundColor={backgroundColor} onClick={e => openModal(e.clientX, e.clientY)} icon={icon} />
            <PositionModal
                isOpen={isOpen}
                closeModal={() => setIsOpen(false)}
                position={position}
                testId='dataset-document-select-modal'
                height='100px'
                width='350px'
                overflow='visible'
            >
                <div className={styles.documentSelectModalWrapper}>
                    <OverflowTooltip className={styles.fieldTitle} overlayText={label} testId='dataset-document-select-title' />
                    <DropdownSummary
                        value={selectedDocuments}
                        onChange={onChange}
                        options={documentNames}
                        testId='dataset-document-select'
                        marginBottom='10px'
                        menuPlacement='bottom'
                        hideIndicator={true}
                        isMulti
                        placeholder='No items selected'
                    />
                </div>
            </PositionModal>
        </>
    );
};
