import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { NettingSFT, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface NettingSFTTransactionsProps {
    sftTransactions: NettingSFT;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const NettingSFTTransactions: React.FC<NettingSFTTransactionsProps> = ({ sftTransactions, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'sftTransactions';
    const { sftScheduleProvisions, accelerationNonDefault, automaticAcceleration, bankruptcy } = sftTransactions;

    return (
        <SectionWrapper id={sectionId} label='SFT Transactions' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.providerSFTDocumentsSectionWrapper}>
                <div className={styles.sectionHeaderLabel}>Under the laws of the jurisdiction under consideration:</div>
                <SingleField
                    field={sftScheduleProvisions}
                    fieldId='sftScheduleProvisions'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={accelerationNonDefault}
                    fieldId='accelerationNonDefault'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={automaticAcceleration}
                    fieldId='automaticAcceleration'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={bankruptcy}
                    fieldId='bankruptcy'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
            </div>
        </SectionWrapper>
    );
};
