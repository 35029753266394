import React, { useMemo } from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { formatDate } from '../../../utils/luxon';
import { getAvailableDocumentNames } from '../../admin/documents/store';
import { Button } from '../../shared/button/Button';
import { InformationTooltip, TooltipList } from '../../shared/tooltip';
import styles from './Reports.module.scss';
import { DocumentReportTemplate } from './store';

interface DocumentTemplateProps {
    template: DocumentReportTemplate;
    openTemplate: (reportTemplateId: number) => void;
    openDeleteConfirmationModal: (reportTemplateId: number) => void;
    canMakeTemplateChanges: boolean;
    testId?: string;
}

export const DocumentTemplate: React.FC<DocumentTemplateProps> = ({ template, openTemplate, openDeleteConfirmationModal, testId, canMakeTemplateChanges }) => {
    const { reportTemplateId, modifiedDate, modifiedByName, name, description, reportFields, filters: { documentNameId } } = template;
    const documentNames = useAppSelector(getAvailableDocumentNames);

    const documentNamesCovered = useMemo(() => documentNames.filter(documentName => documentNameId!.includes(documentName.documentNameId!.toString())).map(({ documentName }) => documentName), [documentNames, documentNameId]);

    const reportFieldBulletPoints = useMemo(() => reportFields.map(({ label }) => label), [reportFields]);

    return (
        <div className={styles.templateWrapper} onDoubleClick={() => openTemplate(reportTemplateId)} data-testid={`${testId}-template-wrapper`}>
            <div className={styles.templateHeader}>
                <div className={styles.templateHeaderColumn}>
                    <div className={styles.templateTitle}>
                        <div className={styles.templateName} data-testid={`${testId}-template-title`}>{name}</div>
                        {description && <InformationTooltip content={description} />}
                    </div>
                    <div className={styles.templateDocumentName} data-testid={`${testId}-template-document-name`}>{documentNamesCovered.join(' - ')}</div>
                </div>
                <div className={styles.templateHeaderColumn}>
                    <div className={styles.templateModifiedBy} data-testid={`${testId}-template-modified-by`}>{modifiedByName}</div>
                    <div className={styles.templateModifiedDate} data-testid={`${testId}-template-modified-date`}>{formatDate(modifiedDate)}</div>
                </div>
            </div>
            <div className={styles.templateFieldsHeader} data-testid={`${testId}-template-fields-label`}>Fields</div>
            <TooltipList overlayText={reportFieldBulletPoints} placement='top'>
                <div className={styles.templateFieldsWrapper} data-testid={`${testId}-template-fields`}>{reportFieldBulletPoints.join(' - ')}</div>
            </TooltipList>
            <div className={styles.templateButtonWrapper}>
                <Button label='Delete' onClick={() => openDeleteConfirmationModal(reportTemplateId)} testId={`${testId}-template-delete`} disabled={!canMakeTemplateChanges} />
                <Button label='Open' onClick={() => openTemplate(reportTemplateId)} testId={`${testId}-template-open`} />
            </div>
        </div>
    );
};
