import { isEqual, isNull, max } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { BarChart, BarChartAnalytics } from '../../../shared/analytics/BarChart';
import { IconButton } from '../../../shared/button/IconButton';
import { Sort } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { InformationTooltip, OverflowTooltip } from '../../../shared/tooltip';
import styles from '../Analytics.module.scss';
import { DoraAnalyticsChart, fetchThirdPartyFunctionCompanyDetailsDataStarted, fetchThirdPartyFunctionCompanyPieAnalyticsStarted, getFetchingAnalytics, getThirdPartyCompanyId, getThirdPartyCoverageAnalytics, setSelectedThirdPartyCompanyId } from '../store';
import { ThirdPartyFunctionCompanyPie } from './ThirdPartyFunctionCompanyPie';

const { french, red, amber, green, gold } = styles;

interface ThirdPartyCoverageChartProps {
    tileInView: DoraAnalyticsChart | null;
    setTileInView: (tile: DoraAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
    dimensions?: {
        height: number;
        width: number;
    }
}

export const ThirdPartyCoverage: React.FC<ThirdPartyCoverageChartProps> = ({ tileInView, setTileInView, dimensions, analyticsSpinner }) => {
    const dispatch = useAppDispatch();
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const data = useAppSelector(getThirdPartyCoverageAnalytics);
    const selectedThirdPartyCompanyId = useAppSelector(getThirdPartyCompanyId);

    const chartIsFullScreen = useMemo(() => tileInView === DoraAnalyticsChart.THIRD_PARTY_COVERAGE, [tileInView]);
    const chartWidth = useMemo(() => chartIsFullScreen ? 'calc(50% - 20px)' : '90%', [chartIsFullScreen]);
    const metaWidth = useMemo(() => chartIsFullScreen ? 'calc(50% - 20px)' : '0', [chartIsFullScreen]);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(DoraAnalyticsChart.THIRD_PARTY_COVERAGE), [fetchingAnalytics]);
    const selectThirdPartyCompanyId = useCallback((id: number | null) => dispatch(setSelectedThirdPartyCompanyId(id)), [dispatch]);

    const { height, width } = useMemo(() => {
        if (dimensions) {
            const svgWidth = chartIsFullScreen ? dimensions.width / 2 - 20 : dimensions.width - 80;
            const svgHeight = dimensions.height - 80;
            return { height: svgHeight, width: svgWidth };
        }
        return { height: 0, width: 0 };
    }, [dimensions, chartIsFullScreen]);

    const barChartData: BarChartAnalytics[] = useMemo(() => data.map(({ name, thirdPartyCompanyId, percentage }) => {
        return {
            id: thirdPartyCompanyId,
            name,
            percentageFill: percentage
        };
    }), [data]);

    const testId = 'opinion-average-chart';

    const toggleTileView = useCallback(() => {
        setTileInView(isNull(tileInView) ? DoraAnalyticsChart.THIRD_PARTY_COVERAGE : null);
        if (selectedThirdPartyCompanyId) {
            selectThirdPartyCompanyId(null);
        }
    }, [setTileInView, tileInView, selectThirdPartyCompanyId, selectedThirdPartyCompanyId]);

    const findThirdParty = useCallback((id: number) => data.find(({ thirdPartyCompanyId }) => isEqual(id, thirdPartyCompanyId)), [data]);

    const getPercentageFill = useCallback((id: number) => {
        const thirdParty = findThirdParty(id);
        return thirdParty?.percentage || 0;
    }, [findThirdParty]);

    const getBackgroundColor = useCallback((id: number) => {
        const thirdParty = findThirdParty(id);
        if (thirdParty) {
            const { percentage } = thirdParty;
            if (percentage <= 40) return green;
            if (percentage > 40 && percentage <= 60) return gold;
            if (percentage > 60 && percentage <= 80) return amber;
            if (percentage > 80) return red;
        }
        return french;
    }, [findThirdParty]);

    const getTooltipDetails = useCallback((instanceId: number) => {
        const thirdParty = data.find(({ thirdPartyCompanyId }) => isEqual(instanceId, thirdPartyCompanyId));
        if (thirdParty) {
            const { name, percentage } = thirdParty;
            let tooltipRows = [`${name}`, `${percentage}% exposure to Function Companies`];
            const content = tooltipRows.map(row => `<tspan dy=12 x=10>${row}</tspan>`).join('');
            const tooltipHeight = (tooltipRows.length + 1) * 12;
            const tooltipWidth = max([10 + (max(tooltipRows.map(row => row.length))! * 7), 100])!;
            return { height: tooltipHeight, width: tooltipWidth, content };
        }
        return null;
    }, [data]);

    const fetchThirdPartyFunctionCoverageDetails = useCallback(() => dispatch(fetchThirdPartyFunctionCompanyDetailsDataStarted()), [dispatch]);
    const fetchThirdPartyFunctionCoveragePieData = useCallback(() => dispatch(fetchThirdPartyFunctionCompanyPieAnalyticsStarted()), [dispatch]);

    const handleFetchThirdPartyDetails = useCallback((id: number) => {
        if (!chartIsFullScreen) {
            toggleTileView();
        }
        selectThirdPartyCompanyId(id);
        fetchThirdPartyFunctionCoverageDetails();
        fetchThirdPartyFunctionCoveragePieData();
    }, [chartIsFullScreen, fetchThirdPartyFunctionCoverageDetails, toggleTileView, fetchThirdPartyFunctionCoveragePieData, selectThirdPartyCompanyId]);

    const wrapperDimensions = dimensions ? { height: dimensions.height, width: dimensions.width } : { height: '100%', width: '100%' };
    const tooltipInfo = 'Below is a bar graph outlining the percentage of exposure your Third Party ICT Providers have across all of the functions performed by companies in your network.';

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.thirdPartyCoverageChartWrapper} style={wrapperDimensions} data-testid={`${testId}-wrapper`}>
            <div className={styles.thirdPartyCoverageChartHeader}>
                <div className={styles.centreGroup}>
                    <OverflowTooltip className={styles.thirdPartyCoverageChartTitle} overlayText='Third Party Coverage' testId={`${testId}-title`} />
                    <div className={styles.thirdPartyCoverageChartDescription}><InformationTooltip content={tooltipInfo} /></div>
                    <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
                </div>
            </div>
            <div className={styles.chartContents}>
                <div className={styles.chartAndMeta} style={{ justifyContent: chartIsFullScreen ? 'space-evenly' : 'center' }}>
                    <div className={styles.barChartWrapper} style={{ width: chartWidth }}>
                        <BarChart
                            data={barChartData}
                            height={height}
                            width={width}
                            getPercentageFill={getPercentageFill}
                            getBackgroundColor={getBackgroundColor}
                            getTooltipDetails={getTooltipDetails}
                            onDblClick={handleFetchThirdPartyDetails}
                            showXAxisLabels
                            xAxisLabel='Percentage Coverage'
                        />
                    </div>
                    {chartIsFullScreen &&
                        <div className={styles.chartContentsMetaWrapper} style={{ width: metaWidth }}>
                            <ThirdPartyFunctionCompanyPie
                                dimensions={dimensions}
                                testId='third-party-function-company'
                                id='third-party-function-company' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
