import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import styles from './NettingEngine.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { enquireAllNettingOpinions, enquireSingleNettingOpinion, getEnquiredOpinions, getLocalLawIsValidJurisdiction, getUnauthorisedOpinionJurisdictions } from './store';
import { adminRoles } from '../../constants/permittedRoles';
import { getUserRole } from '../../auth/login/store';
import { Button } from '../../shared/button/Button';

interface NettingEngineEnquireOverlayProps {
    jurisdiction: string;
    width: number;
    left: string
}

export const NettingEngineEnquireOverlay: React.FC<NettingEngineEnquireOverlayProps> = ({ jurisdiction, width, left }) => {
    const dispatch = useAppDispatch();
    const userRole = useAppSelector(getUserRole);
    const isAdminUser = adminRoles.includes(userRole!);

    const messageOne = useMemo(() => isAdminUser ? 'If you would like to know more about any of the relevant opinions and the cost associated with accessing them,' : 'If you think any of the relevant opinions would be of interest to your company, please reach out to your team administrator.', [isAdminUser]);
    const messageTwo = useMemo(() => isAdminUser ? 'please click one of the \'Enquire\' buttons below to send an enquiry email and someone from our team will be in touch' : 'They can use this page to send an enquiry via the button below and someone from our team will be in touch.', [isAdminUser]);
    const unauthorisedOpinions = useAppSelector(getUnauthorisedOpinionJurisdictions);
    const enquiredOpinions = useAppSelector(getEnquiredOpinions);
    const localLawIsValidJurisdiction = useAppSelector(getLocalLawIsValidJurisdiction);

    const individualButtonDisabled = useMemo(() => enquiredOpinions.includes(jurisdiction), [jurisdiction, enquiredOpinions]);
    const individualButtonLabel = useMemo(() => isNull(localLawIsValidJurisdiction) ? `Enquire about ${jurisdiction}` : 'Enquire about this segment only', [jurisdiction, localLawIsValidJurisdiction]);
    const individualButtonWidth = useMemo(() => `${individualButtonLabel.length > 25 ? 250 : individualButtonLabel.length * 10}px`, [individualButtonLabel]);
    const individualTooltip = useMemo(() => individualButtonDisabled ? ['Enquiry already sent'] : undefined, [individualButtonDisabled]);
    const enquireAllButtonDisabled = useMemo(() => unauthorisedOpinions.length === enquiredOpinions.length, [unauthorisedOpinions, enquiredOpinions]);
    const enquireAllTooltip = useMemo(() => enquireAllButtonDisabled ? ['Enquiry already sent'] : [], [enquireAllButtonDisabled]);

    const enquireIndividual = useCallback(() => dispatch(enquireSingleNettingOpinion(jurisdiction)), [dispatch, jurisdiction]);
    const enquireAll = useCallback(() => dispatch(enquireAllNettingOpinions()), [dispatch]);

    return (
        <div className={styles.notPermittedJurisdiction} style={{ maxWidth: width / 2 + 'px', left: left }}>
            <div>You are not permitted to view the opinion information for this jurisdiction (<span className={styles.notPermittedJurisdictionImpact}>{jurisdiction}</span>) as your client does not have access</div>
            {!isNull(localLawIsValidJurisdiction) ? <div>You are also not permitted to view the opinion information for the enforcability of the agreement regarding local law (<span className={styles.notPermittedJurisdictionImpact}>{localLawIsValidJurisdiction}</span>).</div> : null}
            <div>{messageOne}</div>
            <div>{messageTwo}</div>
            <div className={styles.notPermittedButtonWrapper}>
                <Button disabled={individualButtonDisabled} disabledTooltip={individualTooltip} label={individualButtonLabel} onClick={enquireIndividual} width={individualButtonWidth} />
                <Button disabled={enquireAllButtonDisabled} disabledTooltip={enquireAllTooltip} label='Enquire about all missing opinions' onClick={enquireAll} width='250px'/>
            </div>
        </div>
    );
};
