import React, { useCallback, useMemo } from 'react';

import styles from '../SupplyChain.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { deleteSupplyChainElementStarted, getDeleteConfirmationModalOpen, getIsDeleting } from '../store';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { SupplyChain } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { ModalHeader } from '../../../shared/modal/ModalHeader';

interface DeleteElementModalProps {
    closeModal: () => void;
}

export const DeleteElementModal: React.FC<DeleteElementModalProps> = ({ closeModal }) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getDeleteConfirmationModalOpen);
    const isDeleting = useAppSelector(getIsDeleting);

    const deleteElement = useCallback(() => dispatch(deleteSupplyChainElementStarted()), [dispatch]);

    const content = useMemo(() => {
        if (isDeleting) {
            return (<Spinner message='Deleting company from supply chain...' />);
        }
        return (
            <div>
                <div className={styles.warningTitle} data-testid='delete-supply-chain-element-warning-title'>Are you sure you want to delete this company from your supply chain?</div>
                <div className={styles.warningInfo} data-testid='delete-supply-chain-element-warning-info'>Any contractual and/or assessment information will also be removed</div>
            </div>
        );
    }, [isDeleting]);

    if (!isOpen) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen
            closeModal={closeModal}
            confirmLabel='Delete'
            testId='delete-supply-chain-element'
            confirm={deleteElement}
        >
            <ModalHeader label='Delete Supply Chain Company' icon={SupplyChain} testId='delete-supply-chain-element-modal' />
            <div className={styles.deleteConfirmationWrapper} data-testid='delete-supply-chain-element-modal-wrapper'>
                {content}
            </div>
        </ConfirmationModal>
    );
};
