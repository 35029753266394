import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Document } from '../../../shared/icons';
import { LongText } from '../../../shared/longtext/LongText';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Text } from '../../../shared/text/Text';
import styles from '../DocumentName.module.scss';
import { deleteClientDocumentGroupStarted, getClientDocumentGroupHasUpdated, getIsSavingGroup, getSelectedDocumentGroup, saveClientDocumentGroupStarted, updateClientDocumentGroupValue } from '../store';
import { DocumentNamesTable } from './DocumentNamesTable';

interface CreateDocumentGroupModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

export const CreateDocumentGroupModal: React.FC<CreateDocumentGroupModalProps> = ({ isOpen, closeModal }) => {
    const dispatch = useAppDispatch();

    const selectedDocumentGroup = useAppSelector(getSelectedDocumentGroup);
    const isSavingGroup = useAppSelector(getIsSavingGroup);
    const hasUpdated = useAppSelector(getClientDocumentGroupHasUpdated);

    const groupName = useMemo(() => selectedDocumentGroup && selectedDocumentGroup.groupName || '', [selectedDocumentGroup]);
    const description = useMemo(() => selectedDocumentGroup && selectedDocumentGroup.description || '', [selectedDocumentGroup]);
    const documentNameIds = useMemo(() => selectedDocumentGroup && selectedDocumentGroup.documentNameIds || [], [selectedDocumentGroup]);
    const saveDisabled = useMemo(() => isSavingGroup || !hasUpdated || documentNameIds.length === 0 || groupName.length === 0, [isSavingGroup, hasUpdated, documentNameIds, groupName]);
    const saveDisabledTooltip = useMemo(() => {
        let tooltip = [];
        if (documentNameIds.length === 0) tooltip.push('A minimum of one document must be selected');
        if (groupName.length === 0) tooltip.push('A group name must be provided');
        return tooltip;
    }, [documentNameIds, groupName]);
    const hideDelete = useMemo(() => selectedDocumentGroup ? !selectedDocumentGroup.clientDocumentGroupId : true, [selectedDocumentGroup]);

    const handleUpdateGroupName = useCallback((value: string) => dispatch(updateClientDocumentGroupValue('groupName', value)), [dispatch]);
    const handleUpdateDescription = useCallback((value: string) => dispatch(updateClientDocumentGroupValue('description', value)), [dispatch]);
    const handleUpdateDocumentNameIds = useCallback((ids: number[]) => dispatch(updateClientDocumentGroupValue('documentNameIds', ids)), [dispatch]);
    const handleSaveDocumentGroup = useCallback(() => dispatch(saveClientDocumentGroupStarted()), [dispatch]);
    const handleDeleteDocumentGroup = useCallback(() => dispatch(deleteClientDocumentGroupStarted()), [dispatch]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            testId='create-document-group'
            confirm={handleSaveDocumentGroup}
            confirmDisabled={saveDisabled}
            confirmDisabledTooltip={saveDisabledTooltip}
            showPreview={!hideDelete}
            previewLabel='Delete'
            openPreview={handleDeleteDocumentGroup}
        >
            <div className={styles.groupModalWrapper} data-testid='configure-document-group-modal-wrapper'>
                <ModalHeader label='Configure Document Group' icon={Document} />
                <Text
                    label='Group Name'
                    testId='document-group-name'
                    value={groupName}
                    onChange={e => handleUpdateGroupName(e.target.value)}
                    maxLength={100}
                />
                <LongText
                    label='Description'
                    value={description}
                    testId='document-group-description'
                    width='calc(100% - 12px)'
                    onChange={e => handleUpdateDescription(e)}
                    maxLength={500}
                />
                <DocumentNamesTable
                    clientDocumentIds={documentNameIds}
                    updateDocumentNameIds={handleUpdateDocumentNameIds}
                />
            </div>
        </ConfirmationModal>
    );
};
