import React from 'react';
import { Options } from 'react-select';

import styles from './SearchDocuments.module.scss';
import { LogicalOperator, Condition, SmartSearch } from '../search/store';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { Text } from '../../shared/text/Text';
import { DeleteButton } from '../../shared/button/DeleteButton';

interface SmartSearchRowProps {
    index: number;
    isFirstRow: boolean;
    showDelete: boolean;
    removeRow: (index: number) => void;
    updateValue: (key: keyof SmartSearch, value: string, index: number) => void;
    row: SmartSearch;
}

const renderFirstRow = (isFirstRow: boolean, options: DropdownOption[], value: DropdownOption, onChange: (option: DropdownOption | Options<DropdownOption> | null) => void, index: number) => {
    if (isFirstRow) {
        return (
            <div className={styles.logicalOperatorColumn}>
                <div className={styles.firstRowTitle}>Document</div>
            </div>
        );
    }
    return (
        <div className={styles.logicalOperatorColumn}>
            <Dropdown
                onChange={onChange}
                value={value}
                options={options}
                isClearable={false}
                menuPortalTarget={document.body}
                testId={`search-conditional-${index}`}
            />
        </div>
    );
};

export const SmartSearchRow: React.FC<SmartSearchRowProps> = ({ index, isFirstRow, showDelete, removeRow, updateValue, row }) => {
    const { logicalOperator, condition, searchTerm } = row;

    const logicalOperatorValue = { label: logicalOperator, value: logicalOperator };
    const conditionValue = { label: condition, value: condition };

    const logicalOperatorOptions = Object.values(LogicalOperator).map(operator => ({ label: operator, value: operator }));

    const conditionOptions = Object.values(Condition).map(condition => ({ label: condition, value: condition }));

    const updateSearchTerm = (value: string) => updateValue('searchTerm', value, index);

    const updateLogicalOperator = (option: DropdownOption | Options<DropdownOption> | null) => {
        const value = (option as DropdownOption).value;
        updateValue('logicalOperator', value, index);
    };

    const updateCondition = (option: DropdownOption | Options<DropdownOption> | null) => {
        const value = (option as DropdownOption).value;
        updateValue('condition', value, index);
    };

    return (
        <div key={index} className={styles.smartInputRow}>
            {renderFirstRow(isFirstRow, logicalOperatorOptions, logicalOperatorValue, updateLogicalOperator, index)}
            <div className={styles.conditionColumn}>
                <Dropdown
                    isClearable={false}
                    onChange={updateCondition}
                    value={conditionValue}
                    options={conditionOptions}
                    menuPortalTarget={document.body}
                    testId={`search-logical-operator-${index}`}
                />
            </div>
            <div className={styles.inputWrapper}>
                <Text
                    value={searchTerm}
                    testId={`smart-search-term-${index}`}
                    marginBottom=''
                    height='26px'
                    onChange={e => updateSearchTerm(e.target.value)}
                />
            </div>
            {showDelete ? <DeleteButton fontSize={20} onClick={() => removeRow(index)} testId={`search-row-${index}`} /> : <div className={styles.deletePlaceholder}></div>}
        </div>
    );
};
