import { isUndefined, noop } from 'lodash/fp';
import React, { useMemo } from 'react';
import { RawDraftContentState } from 'draft-js';

import { useAppSelector } from '../../../../hooks/react-redux';
import { Checkbox, DateField, DocumentField, Dropdown, LongText, Number, PartySelect, Text, Time, WYSIWYGField } from '../../fields';
import { DatasetFieldType } from '../../store';
import { InstanceIdentifier, MLDataValue } from '../../store/mlTypes';
import { GroupInstanceField, SingleInstanceField, getCurrentInstances, isAnnexInstance, isFormDatasetInstance } from '../store';
import styles from './MLDataModal.module.scss';
import { CalculatorCell } from '../../fields/CalculatorCell';
import { CalculatorField } from '../../../shared/calculator/constants';
import { CurrencyAmountField } from '../../fields/CurrencyAmountField';
import { CurrencyValue } from '../../../shared/number/CurrencyNumber';

interface CurrentInstanceValueProps {
    field: InstanceIdentifier;
    versionConfirmed: number;
    disabled: boolean;
    mlValue: MLDataValue;
    question: string;
}

const getField = (
    field: SingleInstanceField,
    index: number,
    disabled: boolean,
    datasetId: number,
    parentFieldId: string,
    question: string,
    mlValue: MLDataValue,
    sectionId?: string,
    groupIndex?: number,
    rowId?: string,
    menuPortalTarget?: HTMLElement | null
) => {
    const { value, settings } = field;
    const showAIModified = !!settings.aiModified;
    const showAIModifiedUserCorrected = !!settings.aiModifiedUserCorrected;

    const defaultProps = { id: field.id!, index, sectionId, groupIndex, disabled, showAIModified, showAIModifiedUserCorrected, parentFieldId, rowId, isMLCorrection: true };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} value={value as string} datasetId={datasetId} />;
        case DatasetFieldType.LONGTEXT:
            return <LongText {...defaultProps} value={value as string} datasetId={datasetId} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={value as null | Date} datasetId={datasetId} />;
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
            return <Dropdown
                {...defaultProps}
                value={value as null | string[]}
                type={field.type}
                listId={field.settings.dropdownLinked!}
                isMulti={field.settings.isMultiSelect}
                entityType={field.settings.entityType}
                menuPortalTarget={menuPortalTarget}
                datasetId={datasetId}
            />;
        case DatasetFieldType.PARTY:
            return <PartySelect
                {...defaultProps}
                value={value as null | string[]}
                menuPortalTarget={menuPortalTarget}
                datasetId={datasetId}
            />;
        case DatasetFieldType.DOCUMENT:
            return <DocumentField id={field.id!} value={value as string[]} />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} value={value as number} datasetId={datasetId} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={value as boolean} datasetId={datasetId} centreField={field.settings.centreField} />;
        case DatasetFieldType.TIME:
            return <Time {...defaultProps} value={value as string[]} datasetId={datasetId} />;
        case DatasetFieldType.CALCULATOR:
            return <CalculatorCell
                {...defaultProps}
                value={field.value as CalculatorField[]}
                fieldType={field.settings.calculatorFieldType!}
                datasetId={datasetId}
                showOutputField={field.settings.showOutputField!}
                showGracePeriod={field.settings.showGracePeriod!}
                includeFreeTextOption={field.settings.includeFreeTextOption}
                mlQuestion={question}
                mlValue={mlValue}
            />;
        case DatasetFieldType.WYSIWYG:
            return <WYSIWYGField
                {...defaultProps}
                value={value as RawDraftContentState | null}
                fieldOpen
                toolbarHidden
                datasetId={datasetId}
                isCollapsible={field.settings.isCollapsible}
                toggleSection={noop}
                getSectionOpen={() => true}
            />;
        case DatasetFieldType.CURRENCY_AMOUNT:
            return <CurrencyAmountField {...defaultProps} currencyValue={value as unknown as CurrencyValue} datasetId={datasetId} />;
        default:
            return <div className={styles.unknownFieldType}>Unknown</div>;
    }
};

export const CurrentInstanceValue: React.FC<CurrentInstanceValueProps> = ({ field, versionConfirmed, disabled, question, mlValue }) => {
    const { parentFieldId, datasetId, rowId, sectionId, childIndex, index, isEdgeCase } = field;
    const datasetInstances = useAppSelector(getCurrentInstances);
    const isGroupField = useMemo(() => !isUndefined(field.childIndex), [field]);
    const instanceField = useMemo(() => {
        const instance = datasetInstances.find(({ parentFieldId }) => parentFieldId === field.parentFieldId)?.instance;
        if (instance) {
            if (isFormDatasetInstance(instance)) {
                const sectionId = field.sectionId!;
                if (isGroupField) {
                    const childIndex = field.childIndex!;
                    const instanceField = (instance.datasetFields[sectionId][field.index] as GroupInstanceField).children[childIndex];
                    return instanceField;
                }
                return instance.datasetFields[sectionId][field.index] as SingleInstanceField;
            } else if (!isAnnexInstance(instance)) {
                const rowId = field.rowId;
                if (rowId) {
                    return instance.datasetFields[rowId][field.index];
                }
            }
        }
    }, [datasetInstances, field, isGroupField]);

    const isDisabled = useMemo(() => field.version <= versionConfirmed || disabled, [field, versionConfirmed, disabled]);

    if (isEdgeCase) {
        return (
            <div className={styles.currentInstanceValue}>This data cannot be represented by one field</div>
        );
    }

    if (isUndefined(instanceField)) {
        return (
            <div className={styles.currentInstanceValue}>Coming soon...</div>
        );
    }

    if (isGroupField) {
        return (
            <div className={styles.currentInstanceValue}>
                {getField(instanceField, childIndex!, isDisabled, datasetId, parentFieldId, question, mlValue, sectionId, index, undefined, document.body)}
            </div>
        );
    }

    return (
        <div className={styles.currentInstanceValue}>
            {getField(instanceField, index, isDisabled, datasetId, parentFieldId, question, mlValue, sectionId, undefined, rowId, document.body)}
        </div>
    );
};
