import React, { useCallback, useEffect, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { CompanyEntity, CompanyEntitySection, fetchMyEntityGroupsStarted, fetchOpinionSubCounterpartyTypesStarted, fetchOpinionSubCounterpartyTypesSuccessful, selectCompanyEntitySection } from '../store';
import styles from './EntityType.module.scss';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { CompanyEntityGeneralSection } from './CompanyEntityGeneral';
import { CompanyEntityContactSection } from './CompanyEntityContact';
import { CompanyEntityDetailsSection } from './CompanyEntityDetails';
import { CompanyEntityDoraSection } from './CompanyEntityDora';
import { OverflowTooltip } from '../../../shared/tooltip';
import { getClientHasFeaturePermission } from '../../../auth/login/store';
import { ClientFeaturePermission } from '../../users/store';
import { CompanyEntityBranchesSection } from './CompanyEntityBranches';
import { fetchAllDropdownListsStarted } from '../../dropdown-lists/store';

const { french, primary } = styles;

interface CompanyEntityFormProps {
    entity: CompanyEntity;
    selectedSection: CompanyEntitySection
}

export const CompanyEntityForm: React.FC<CompanyEntityFormProps> = ({ entity, selectedSection }) => {
    useFetchStarted([fetchMyEntityGroupsStarted(), fetchAllDropdownListsStarted()]);
    const dispatch = useAppDispatch();

    const { content } = entity;
    const opinionCounterpartyType = content.opinionCounterpartyType?.value;
    const opinionJurisdiction = content.opinionJurisdiction || null;

    const openSection = useMemo(() => {
        if (selectedSection === CompanyEntitySection.CONTACT) return <CompanyEntityContactSection entity={entity} />;
        if (selectedSection === CompanyEntitySection.BRANCHES) return <CompanyEntityBranchesSection entity={entity} />;
        if (selectedSection === CompanyEntitySection.DETAILS) return <CompanyEntityDetailsSection entity={entity} />;
        if (selectedSection === CompanyEntitySection.DORA) return <CompanyEntityDoraSection entity={entity} />;
        return <CompanyEntityGeneralSection entity={entity} />;
    }, [selectedSection, entity]);

    const selectSection = useCallback((section: CompanyEntitySection) => dispatch(selectCompanyEntitySection(section)), [dispatch]);

    const hasDoraClientPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DORA_MODULE]));

    const sections = useMemo(() => [CompanyEntitySection.GENERAL, CompanyEntitySection.CONTACT, CompanyEntitySection.BRANCHES, CompanyEntitySection.DETAILS], []);

    const entitySections = useMemo(() => hasDoraClientPermission ? [...sections, CompanyEntitySection.DORA] : sections, [hasDoraClientPermission, sections]);
    const sectionColour = useCallback((value: CompanyEntitySection) => selectedSection === value ? french : primary, [selectedSection]);

    useEffect(() => {
        if (!isNull(opinionJurisdiction) && !isNull(opinionCounterpartyType)) {
            dispatch(fetchOpinionSubCounterpartyTypesStarted(opinionJurisdiction, opinionCounterpartyType));
        }
        if (isNull(opinionJurisdiction) || isNull(opinionCounterpartyType)) {
            dispatch(fetchOpinionSubCounterpartyTypesSuccessful([]));
        }
    }, [opinionJurisdiction, opinionCounterpartyType, dispatch]);

    return (
        <div className={styles.companyEntityContentWrapper} data-testid='create-entity-content-wrapper'>
            <div className={styles.sideMenuWrapper}>
                <Scrollable>
                    {entitySections.map((value, index) => (
                        <div key={index} className={styles.entitySection} style={{ color: sectionColour(value) }} onClick={() => selectSection(value as CompanyEntitySection)}>
                            <OverflowTooltip overlayText={value} className={styles.contentsPageSectionLabel} />
                        </div>
                    ))}
                </Scrollable>
            </div>
            <div className={styles.scrollableContentWrapper}>
                {openSection}
            </div>
        </div>
    );
};
