import React, { useCallback, useMemo, MouseEvent } from 'react';
import Tooltip from 'rc-tooltip';
import { noop } from 'lodash/fp';
import 'rc-tooltip/assets/bootstrap_white.css';

import { formatDate } from '../../../../utils/luxon';
import { TimelineInstance, toggleDeleteConfirmationModal } from '../store';
import styles from './HistoricalInstances.module.scss';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { Bin } from '../../../shared/icons';
import { DocumentType } from '../../../documents/my-documents/store';
import { Icon } from '../../../shared/icon/Icon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getUserRole } from '../../../auth/login/store';
import { adminRoles } from '../../../constants/permittedRoles';

interface ContentProps {
    documentDescription?: string;
    documentType?: string;
    username?: string;
    executedDate: string;
    modifiedDate: string;
    width: number;
    showDelete: boolean;
    isLegacy: boolean;
    deleteAmendment?: (event: MouseEvent<HTMLButtonElement>) => void;
}

interface InstanceTooltipProps {
    datasetInstance: TimelineInstance | null;
    children: JSX.Element;
    width?: number;
}

const { french, white, lightGrey } = styles;

const Content: React.FC<ContentProps> = ({ documentDescription = 'No document Added', username = 'Unknown', executedDate, modifiedDate, documentType = 'N/A', width, deleteAmendment = noop, showDelete, isLegacy }) => (
    <div style={{ maxWidth: `${width - 18}px` }} className={styles.overlayWrapper}>
        <div className={styles.contentDescription}>{documentDescription}</div>
        <div className={styles.contentType}>Document Type: {capitaliseStartLetter(documentType)}</div>
        <div className={styles.contentExecutedDate}>Date of Execution: {executedDate}</div>
        <div className={styles.contentModifiedDate}>Last Modified: {modifiedDate}</div>
        <div className={styles.contentUsername}>Last Modified By: {username}</div>
        {isLegacy && <div className={styles.isLegacy}>LEGACY DOCUMENT</div>}
        {showDelete &&
            <button className={styles.deleteAmendmentWrapper} onClick={deleteAmendment}>
                <div>Delete {capitaliseStartLetter(documentType)}</div>
                <Icon icon={Bin} fill='inherit' fontSize={16} />
            </button>
        }
    </div>
);

export const InstanceTooltip: React.FC<InstanceTooltipProps> = ({ datasetInstance, children, width = 300 }) => {
    const dispatch = useAppDispatch();
    const userRole = useAppSelector(getUserRole);
    const tooltipStyles = {
        backgroundColor: datasetInstance && datasetInstance.isLegacy ? lightGrey : white,
        color: french,
        borderColor: french,
        borderRadius: '5px',
        minHeight: '10px',
        display: 'flex'
    };

    const overlayStyle = {
        maxWidth: `${width}px`,
        width: 'fit-content'
    };

    const instance = useMemo(() => {
        if (datasetInstance) {
            const { username, executedDate, createdDate, documentDescription, documentType, isLegacy } = datasetInstance;
            return { username, executedDate: formatDate(executedDate), modifiedDate: formatDate(createdDate), documentDescription, documentType, isLegacy };
        }
        return { username: 'Unknown', executedDate: 'Unknown', modifiedDate: 'Unknown', documentDescription: 'Unknown', documentType: 'Unknown', isLegacy: false };
    }, [datasetInstance]);

    const { username, executedDate, modifiedDate, documentDescription, documentType, isLegacy } = instance;

    const isAdmin = useMemo(() => adminRoles.includes(userRole!), [userRole]);
    const showDelete = useMemo(() => documentType !== DocumentType.ORIGINAL && isAdmin && !isLegacy, [documentType, isAdmin, isLegacy]);

    const deleteAmendment = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(toggleDeleteConfirmationModal({ documentId: datasetInstance!.documentId, documentDescription, datasetInstanceId: datasetInstance!.datasetInstanceId! }));
    }, [dispatch, datasetInstance, documentDescription]);

    if (!datasetInstance || datasetInstance && !datasetInstance.datasetInstanceId) {
        return children;
    }

    return (
        <Tooltip
            placement='bottom'
            overlay={<Content
                documentDescription={documentDescription}
                username={username}
                executedDate={executedDate}
                modifiedDate={modifiedDate}
                documentType={documentType}
                width={width}
                showDelete={showDelete}
                deleteAmendment={deleteAmendment}
                isLegacy={isLegacy}
            />}
            trigger='hover'
            overlayInnerStyle={tooltipStyles}
            overlayStyle={overlayStyle}
        >
            {children}
        </Tooltip>
    );
};
