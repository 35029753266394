import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

export enum AdminAnalyticsActionTypes {
    FETCH_ATTESTATION_PROGRESS_ANALYTICS_STARTED = 'FETCH_ATTESTATION_PROGRESS_ANALYTICS_STARTED',
    FETCH_ATTESTATION_PROGRESS_ANALYTICS_SUCCESSFUL = 'FETCH_ATTESTATION_PROGRESS_ANALYTICS_SUCCESSFUL',
    FETCH_ATTESTATION_PROGRESS_ANALYTICS_FAILED = 'FETCH_ATTESTATION_PROGRESS_ANALYTICS_FAILED',
    REDIRECT_ANALYTICS_ATTESTATION = 'REDIRECT_ANALYTICS_ATTESTATION',
    FETCH_PAGE_USAGE_ANALYTICS_STARTED = 'FETCH_PAGE_USAGE_ANALYTICS_STARTED',
    FETCH_PAGE_USAGE_ANALYTICS_SUCCESSFUL = 'FETCH_PAGE_USAGE_ANALYTICS_SUCCESSFUL',
    FETCH_PAGE_USAGE_ANALYTICS_FAILED = 'FETCH_PAGE_USAGE_ANALYTICS_FAILED'
}

export enum AdminAnalyticsChart {
    ATTESTATION_PROGRESS = 'attestation progress',
    PAGE_USAGE = 'page usage'
}

export enum ClientPageUsage {
    MONTHLY_TOTAL = 'Pages uploaded',
    AVAILABLE_FREE_PAGES = 'Remaining free uploads',
    AVAILABLE_PAGES = 'Remaining upload limit'
}

export interface UserAttestationAnalytics {
    name: string;
    userAnswerId: string;
    percentageComplete: number;
    redFlagAnswers: number;
    weighting: number;
}

export interface AdminAttestationAnalytics {
    name: string;
    attestationInstanceId: number;
    users: UserAttestationAnalytics[];
    deadline: string | null;
}

export interface StackData {
    [userAnswerId: string]: number | string;
}

export interface StackChartAnalyticsData extends StackData {
    key: string;
    id: number;
}

export interface AdminAnalyticsState {
    attestationProgress: AdminAttestationAnalytics[];
    fetchingAnalytics: AdminAnalyticsChart[];
    analyticsError: string | null;
    pageUsageAnalytics: PieChartAnalyticsData[];
}
