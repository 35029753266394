import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';
import { isNull, isUndefined } from 'lodash/fp';
import classnames from 'classnames';

import styles from './DoraRegisterOfInformation.module.scss';
import { Button } from '../../shared/button/Button';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { downloadRegisterOfInformationStarted, fetchAllInScopeEntitiesStarted, getEntitiesInScope, getPreliminaryRoi, getReportDownloading, getSelectedInScopeEntity, setRoiCurrency, setSelectedInScopeEntity } from './store';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted, getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { InformationTooltip, OverflowTooltip } from '../../shared/tooltip';
import { CompanyEntity } from '../../admin/entity/store';
import { Delete, Tick } from '../../shared/icons';
import { Icon } from '../../shared/icon/Icon';
import { LoadingDots } from '../../shared/loading-dots/LoadingDots';

const { green, red } = styles;

export const DoraRegisterOfInformation: React.FC = () => {
    useFetchStarted([fetchAllDropdownListsStarted(), fetchAllInScopeEntitiesStarted()]);
    const documentDownloading = useAppSelector(getReportDownloading);
    const dispatch = useAppDispatch();

    const attemptDownloadRegisterOfInformation = useCallback(() => dispatch(downloadRegisterOfInformationStarted()), [dispatch]);
    const { currencyOfRegister } = useAppSelector(getPreliminaryRoi);

    const allDropdowns = useAppSelector(getAllDropdownLists);
    const currencyDropdown = allDropdowns.find(({ name }) => name === 'Currency Codes');
    const currencyDropdownOptions = currencyDropdown?.options.map(value => ({ value, label: value })) || [];
    const currencyDropdownValue = currencyOfRegister ? { value: currencyOfRegister, label: currencyOfRegister } : null;

    const missingPreliminaryData = useMemo(() => isNull(currencyOfRegister) ? ['Please provide the currency of the register'] : [], [currencyOfRegister]);

    const entitiesInScope = useAppSelector(getEntitiesInScope);

    const selectedEntity = useAppSelector(getSelectedInScopeEntity);

    const updateDropdownOption = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        dispatch(setRoiCurrency(selected));
    };

    const selectEntity = useCallback((entity: CompanyEntity) => {
        const value = entity === selectedEntity ? null : entity;
        dispatch(setSelectedInScopeEntity(value));
    }, [dispatch, selectedEntity]);

    const emptyBranchMessage = useMemo(() => isNull(selectedEntity) ? 'Please select a Company to see its branches' : 'Selected Company has no branches', [selectedEntity]);

    const entityServiceType = useMemo(() => !isNull(selectedEntity) && selectedEntity.content.entityServiceType?.value, [selectedEntity]);
    const dateOfRoiIntegration = useMemo(() => !isNull(selectedEntity) && !isNull(selectedEntity.content.dateOfRoiIntegration) ? selectedEntity.content.dateOfRoiIntegration : '', [selectedEntity]);
    const hierarchy = useMemo(() => !isNull(selectedEntity) && !(isNull(selectedEntity.content.doraEntityHierarchy) || isUndefined(selectedEntity.content.doraEntityHierarchy)) ? selectedEntity.content.doraEntityHierarchy : '', [selectedEntity]);

    const formattedValue = useMemo(() => {
        // This formats the numerical value with the correct commas, eg 10000 to 10,000
        if (!isNull(selectedEntity) && !(isNull(selectedEntity.content.totalAssetsValue) || isUndefined(selectedEntity.content.totalAssetsValue))) {
            return selectedEntity.content.totalAssetsValue.toString().replace(/(?=(?!^)\d{3}(?:\b|(?:\d{3})+)\b)/g, ',');
        }
        return '';
    }, [selectedEntity]);

    const getBranchName = useCallback((name: string, city: string, country: string | null) => {
        let branchName = name;
        if (city !== '') branchName = branchName.concat(`, ${city}`);
        if (!isNull(country) && country !== '') branchName = branchName.concat(`, ${country}`);
        return branchName;
    }, []);

    return (
        <div className={styles.registerOfInformationWrapper}>
            <div className={styles.registerOfInformationHeaderWrapper}>
                <div className={styles.registerOfInformationHeader}>DORA Register of Information</div>
            </div>
            <div className={styles.registerOfInformationContentWrapper}>
                <div className={styles.registerOfInformationExplanation}>
                    <p className={styles.registerOfInformationParagraph}>All financial entities must maintain and keep up-to-date a <span className={styles.registerOfInformationImpact}>REGISTER OF INFORMATION</span> in relation to <span className={styles.registerOfInformationImpact}>ALL</span> contractual arrangements on the use of ICT services provided by ICT third-party service providers.</p>
                    <p className={styles.registerOfInformationParagraph}>The objective of the register of information is to capture functions of all in-scope financial entities and establish a link between those functions and the ICT third-party service providers which support them.</p>
                    <p className={styles.registerOfInformationParagraph}>The register of information must be maintained at entity level, at sub-consolidated level, and at consolidated level. A “group” of companies can use a single register of information, but it must cover ALL contractual arrangements on the use of ICT services provided by ICT third-party service providers. It must also distinguish between those functions that are critical or important, and those that are not.</p>
                    <p className={styles.registerOfInformationParagraph}>Click the button below to download the Register Of Information for DORA data stored in Ark 51</p>
                </div>
                <div className={styles.registerOfInformationDetails}>
                    <div className={styles.branchesLabelWrapper}>
                        <div className={styles.entityBranchesLabel}>Summary of the in scope entity details</div>
                    </div>
                    <div className={styles.entitiesInScopeWrapper}>
                        <div className={styles.entitiesInScope}>
                            <Scrollable>
                                {entitiesInScope.map((entity, index) => {
                                    const { name } = entity;
                                    const selected = selectedEntity === entity;
                                    return (
                                        <div key={index}>
                                            <OverflowTooltip onClick={() => selectEntity(entity)} className={classnames(styles.entityInScope, { [styles.selectedEntityInScope]: selected })} overlayText={name} />
                                        </div>
                                    );
                                })}
                            </Scrollable>
                        </div>
                        {selectedEntity &&
                            <div className={styles.entityInScopeDetails}>
                                <Scrollable>
                                    <div className={styles.detailWrapper}>
                                        <div className={styles.entityDetailLabel}>For the purposes of DORA, this entity is a:</div>
                                        <OverflowTooltip className={styles.entityDetail} overlayText={entityServiceType || ''} />
                                    </div>
                                    <div className={styles.detailWrapper}>
                                        <div className={styles.entityDetailLabel}>Date of integration into the ROI:</div>
                                        <div className={styles.entityDetail}>{dateOfRoiIntegration}</div>
                                    </div>
                                    {!isNull(selectedEntity.content.doraEntityHierarchy) && <div className={styles.detailWrapper}>
                                        <div className={styles.entityDetailLabel}>Hierarchy of the entity within the group (where applicable):</div>
                                        <OverflowTooltip className={styles.entityDetail} overlayText={hierarchy} />
                                    </div>}
                                    <div className={styles.detailWrapper}>
                                        <div className={styles.entityDetailLabel}>Value of total assets - of the financial entity:</div>
                                        <div className={styles.entityDetail}>{formattedValue}</div>
                                    </div>
                                    <div className={styles.branchesLabelWrapper}>
                                        <div className={styles.branchesLabel}>Branches</div>
                                        <InformationTooltip content='Branch information can be updated via the Entity Administration section' />
                                    </div>
                                    <div className={styles.entityBranchesWrapper}>
                                        {selectedEntity.content.branches.length > 0 ? selectedEntity.content.branches.map((branch, index) => {
                                            const { name, city, country, inScopeForDora } = branch;
                                            const icon = inScopeForDora ? Tick : Delete;
                                            const iconColour = inScopeForDora ? green : red;
                                            const branchName = getBranchName(name, city, country);
                                            return (
                                                <div key={index} className={styles.entityBranch}>
                                                    <OverflowTooltip className={styles.entityBranchName} overlayText={branchName} />
                                                    <Icon icon={icon} fontSize={20} color={iconColour} />
                                                </div>
                                            );
                                        }) : <div className={styles.noEntityBranches}>{emptyBranchMessage}</div>}
                                    </div>
                                </Scrollable>
                            </div>
                        }
                    </div>
                    <div className={styles.registerCurrencyWrapper}>
                        <Dropdown
                            label='Currency of Register'
                            testId='dora-roi-currency'
                            onChange={updateDropdownOption}
                            value={currencyDropdownValue}
                            options={currencyDropdownOptions}
                            marginBottom='10px'
                        />
                    </div>
                </div>
                <div className={styles.registerOfInformationButtonWrapper}>
                    <Button width='400px' label='Generate the register of information for DORA' onClick={attemptDownloadRegisterOfInformation} disabled={isNull(currencyOfRegister)} disabledTooltip={missingPreliminaryData} />
                </div>
            </div>
            {documentDownloading &&
                <div className={styles.spinnerWrapper}>
                    <div className={styles.spinnerBackground} />
                    <div className={styles.spinnerDots}><LoadingDots wrapperHeight='100%' /></div>
                </div>
            }
        </div>
    );
};
