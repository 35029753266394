import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { TableTabs } from '../../shared/table/TableTabs';
import { fetchDoraFunctionsStarted } from '../functions/store';
import { closeMigrateFunctionConfirmationModal, DoraMyCompaniesView, fetchMyCompanyEntitiesStarted, getDoraMyCompaniesView, getFunctionMigration, getIsCompanyFunctionMigration, getMigrateFunctionConfirmationModalOpen, migrateCompanyFunctionsStarted, updateSelectedEntity, toggleDoraMyCompaniesView, updateCompanyFunctionMigrations } from './store';
import styles from './MyCompanies.module.scss';
import { MyCompaniesInScopeForDora } from './MyCompaniesInScopeForDora';
import { MyCompaniesModal } from './MyCompaniesModal';
import { MyCompaniesOutOfScopeForDora } from './MyCompaniesOutOfScopeForDora';
import { DataManagementWrapper } from '../data-management/DataManagementWrapper';
import { MigrateFunctionConfirmationModal } from './MigrateFunctionConfirmationModal';

export const MyCompanies: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    useFetchStarted([fetchMyCompanyEntitiesStarted(), fetchDoraFunctionsStarted(1)]);
    const dispatch = useAppDispatch();
    const myCompaniesView = useAppSelector(getDoraMyCompaniesView);
    const isInScopeView = useMemo(() => myCompaniesView === DoraMyCompaniesView.IN_SCOPE, [myCompaniesView]);
    const changeView = useCallback((view: DoraMyCompaniesView) => dispatch(toggleDoraMyCompaniesView(view)), [dispatch]);
    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const headerTitle = useMemo(() => `My ${isInScopeView ? 'In' : 'Out of'} Scope Companies`, [isInScopeView]);
    const headerTestId = useMemo(() => `my-companies-${isInScopeView ? 'in' : 'out-of'}-scope`, [isInScopeView]);

    const migrateModalOpen = useAppSelector(getMigrateFunctionConfirmationModalOpen);
    const functionMigration = useAppSelector(getFunctionMigration);
    const isCompanyMigration = useAppSelector(getIsCompanyFunctionMigration);

    const closeMigrateModal = useCallback(() => dispatch(closeMigrateFunctionConfirmationModal()), [dispatch]);

    const saveAndCloseMigrateModal = useCallback(() => {
        dispatch(migrateCompanyFunctionsStarted());
        closeMigrateModal();
        if (isCompanyMigration) {
            dispatch(updateSelectedEntity('doraInScope', 0));
        }
    }, [dispatch, closeMigrateModal, isCompanyMigration]);

    const updateFunctionMigrations = useCallback((functionId: number, value: number | null) => dispatch(updateCompanyFunctionMigrations(functionId, value)), [dispatch]);

    useEffect(() => {
        if (pathname.includes('out-of-scope') && isInScopeView) {
            changeView(DoraMyCompaniesView.OUT_OF_SCOPE);
        }
    }, [isInScopeView, pathname, changeView]);

    const tableTabs = [
        { tabToggleAction: () => changeView(DoraMyCompaniesView.IN_SCOPE), tabTitle: 'In Scope', isSelected: isInScopeView },
        { tabToggleAction: () => changeView(DoraMyCompaniesView.OUT_OF_SCOPE), tabTitle: 'Out of Scope', isSelected: !isInScopeView }
    ];

    const content = useMemo(() => isInScopeView ? <MyCompaniesInScopeForDora hasDoraFullAccessPermission={hasDoraFullAccessPermission} /> : <MyCompaniesOutOfScopeForDora hasDoraFullAccessPermission={hasDoraFullAccessPermission} />, [isInScopeView, hasDoraFullAccessPermission]);

    return (
        <DataManagementWrapper>
            <div className={styles.myCompaniesWrapper}>
                <div className={styles.myCompaniesTitleContainer}>
                    <div className={styles.myCompaniesTitle} data-testid={headerTestId}>{headerTitle}</div>
                </div>
                <TableTabs
                    tabs={tableTabs}
                    testId='my-companies'
                />
                {content}
                <MyCompaniesModal />
                <MigrateFunctionConfirmationModal
                    isOpen={migrateModalOpen}
                    closeModal={closeMigrateModal}
                    saveAndClose={saveAndCloseMigrateModal}
                    functionMigration={functionMigration}
                    isCompanyMigration={isCompanyMigration}
                    updateFunctionMigrations={updateFunctionMigrations}
                />
            </div>
        </DataManagementWrapper>
    );
};
