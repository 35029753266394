import { isNull, isUndefined } from 'lodash';
import { flattenDeep, flow, getOr, isEqual, set, uniq, unset } from 'lodash/fp';

import { formatDateTime } from '../../../utils/luxon';
import { defaultField } from '../../admin/dataset-builder/utils';
import { HiddenField, HiddenFields, HiddenSection } from '../../admin/my-datasets/store';
import { newCalculatorField } from '../../shared/calculator/constants';
import { isGroupField } from '../store';
import { DatasetFieldType, DatasetSection, SingleDatasetField } from '../store/types';
import { AnnexInstanceField, AnnexInstanceFields, DatasetInstance, DatasetInstanceSection, FieldValue, FormDatasetInstance, FormInstanceFields, Hierarchy, Instance, InstanceField, OpenField, OpenFieldSection, OpenFieldType, OpenSection, SearchField, SearchFieldSection, SearchSection, SingleInstanceField, TableDatasetInstance, TableInstanceFields, UpdatedField, UpdatedFormField, UpdatedTableField, isFormDatasetInstance } from './store';
import { isAnnexInstance, isGroupInstanceField } from './store/typeAssertions';

const mapFormInstanceFields = (field: InstanceField): UpdatedFormField | UpdatedFormField[] => {
    if (isGroupInstanceField(field)) {
        const children = field.children.map(({ value, id, type, clauseLabel, label }) => ({ id: id!, value, type, clauseLabel, label }));
        const customChildren = field.customChildren?.map(({ value, id, type, clauseLabel, isCustom, label }) => ({ id: id!, value, type, clauseLabel, isCustom, groupId: field.id, label })) || [];
        return [...children, ...customChildren];
    } else {
        return { id: field.id!, value: field.value, type: field.type, clauseLabel: field.clauseLabel, label: field.label };
    }
};

const mapNewFormInstanceFields = (field: InstanceField): UpdatedFormField | UpdatedFormField[] => {
    if (isGroupInstanceField(field)) {
        const children = field.children.map(({ id, type, clauseLabel, label }) => ({ id: id!, value: getDefaultValue(type), type, clauseLabel, label }));
        const customChildren = field.customChildren?.map(({ id, type, clauseLabel, isCustom, label }) => ({ id: id!, value: getDefaultValue(type), type, clauseLabel, isCustom, groupId: field.id, label })) || [];
        return [...children, ...customChildren];
    } else {
        return { id: field.id!, value: getDefaultValue(field.type), type: field.type, clauseLabel: field.clauseLabel, label: field.label };
    }
};
export const flattenFormInstanceFields = (fields: FormInstanceFields) => flattenDeep(Object.values(fields).map(section => section.map(field => mapFormInstanceFields(field)))) as UpdatedFormField[];
export const flattenNewFormInstanceFields = (fields: FormInstanceFields) => flattenDeep(Object.values(fields).map(section => section.map(field => mapNewFormInstanceFields(field)))) as UpdatedFormField[];

export const flattenTableInstanceFields = (fields: TableInstanceFields | AnnexInstanceFields): UpdatedTableField[] => flattenDeep(Object.entries(fields).map(([rowId, row]) => (row as SingleInstanceField[] | AnnexInstanceField[]).map((field: SingleInstanceField | AnnexInstanceField) => ({ id: field.id!, value: field.value, row: rowId, type: field.type, clauseLabel: getOr(undefined, 'clauseLabel', field) }))));
export const flattenNewTableInstanceFields = (fields: TableInstanceFields | AnnexInstanceFields): UpdatedTableField[] => flattenDeep(Object.entries(fields).map(([rowId, row]) => (row as SingleInstanceField[] | AnnexInstanceField[]).map((field: SingleInstanceField | AnnexInstanceField) => ({ id: field.id!, value: getDefaultValue(field.type, getOr(undefined, 'linkedFields', field)), row: rowId, type: field.type }))));

export const getDefaultValue = (type: DatasetFieldType, linkedFields?: SingleDatasetField[]): FieldValue => {
    switch (type) {
        case DatasetFieldType.TEXT:
        case DatasetFieldType.LONGTEXT:
            return '';
        case DatasetFieldType.DATE:
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
        case DatasetFieldType.PARTY:
        case DatasetFieldType.DATASET:
        case DatasetFieldType.WYSIWYG:
        case DatasetFieldType.LABEL:
            return null;
        case DatasetFieldType.NUMBER:
            return undefined;
        case DatasetFieldType.TIME:
            return ['', ''];
        case DatasetFieldType.CHECKBOX:
            return false;
        case DatasetFieldType.WIZARD:
            return linkedFields!.map(() => '');
        case DatasetFieldType.CALCULATOR:
            return [newCalculatorField()];
        case DatasetFieldType.CURRENCY_AMOUNT:
            return { currency: null, value: undefined };
        default:
            return null;
    }
};

const getFieldValue = (field: SingleInstanceField): SingleInstanceField => ({ ...field, value: getDefaultValue(field.type), modifiedBy: null, modifiedDate: null });
const getAnnexFieldValue = (field: AnnexInstanceField): AnnexInstanceField => {
    const linkedFields = field.linkedFields.map(linkedField => ({ ...linkedField, value: getDefaultValue(linkedField.type) }));
    return { ...field, value: getDefaultValue(field.type, field.linkedFields), linkedFields };
};
export const createNewTableRow = (datasetFields: SingleInstanceField[]): SingleInstanceField[] => datasetFields.map(field => getFieldValue(field));

export const createNewAnnexRow = (datasetFields: AnnexInstanceField[]): AnnexInstanceField[] => datasetFields.map(field => getAnnexFieldValue(field));

const createFormKey = (field: UpdatedFormField) => field.id;
const compareFormFields = (existingField: UpdatedFormField, field: UpdatedFormField) => isEqual(existingField.id, field.id) && (!isEqual(existingField.value, field.value) || !isEqual(existingField.clauseLabel, field.clauseLabel) || !isEqual(existingField.label, field.label));
const getUpdatedFormFields = (currentFields: UpdatedFormField[], existingFields: UpdatedFormField[]) => {
    const existingFieldMap = new Map<string, UpdatedFormField>();

    existingFields.forEach(field => {
        const key = createFormKey(field);
        existingFieldMap.set(key, field);
    });

    return currentFields.filter(field => {
        const key = createFormKey(field);
        const existingField = existingFieldMap.get(key);

        return !existingField || compareFormFields(existingField, field);
    });
};

const createTableKey = (field: UpdatedTableField) => `${field.id}:${field.row}`;
const compareExistingRowTableField = (existingField: UpdatedTableField, field: UpdatedTableField) => isEqual({ id: existingField.id, row: existingField.row }, { id: field.id, row: field.row }) && (!isEqual(existingField.value, field.value) || !isEqual(existingField.clauseLabel, field.clauseLabel));
const compareNewRowTableField = (field: UpdatedTableField) => !isEqual(field.value, getDefaultValue(field.type as DatasetFieldType)) || !isUndefined(field.clauseLabel);
const getUpdatedTableFields = (currentFields: UpdatedTableField[], existingFields: UpdatedTableField[]) => {
    const existingFieldMap = new Map<string, UpdatedTableField>();

    existingFields.forEach(field => {
        const key = createTableKey(field);
        existingFieldMap.set(key, field);
    });

    return currentFields.filter(field => {
        const key = createTableKey(field);
        const existingField = existingFieldMap.get(key);
        return existingField ? compareExistingRowTableField(existingField, field) : compareNewRowTableField(field);
    });
};

export const compareInstanceFields = (currentInstance: DatasetInstance, existingInstance?: DatasetInstance, isUpdating = false) => {
    if (isFormDatasetInstance(currentInstance)) {
        const currentFields = flattenFormInstanceFields(currentInstance.datasetFields);
        if (existingInstance && isFormDatasetInstance(existingInstance)) {
            const existingFields = flattenFormInstanceFields(existingInstance.datasetFields);
            const updatedFields = getUpdatedFormFields(currentFields, existingFields);
            const previouslyUpdatedFields = existingInstance.fieldsUpdated;
            if (isUpdating || currentInstance.isDraft) {
                const unchangedFields = previouslyUpdatedFields.filter(({ id }) => !updatedFields.map(updatedField => updatedField.id).includes(id));
                return { unchangedFields, updatedFields };
            }
            return { updatedFields, unchangedFields: [] };
        }
        const newInstanceFields = flattenNewFormInstanceFields(currentInstance.datasetFields);
        const updatedFields = getUpdatedFormFields(currentFields, newInstanceFields);
        return { updatedFields, unchangedFields: [] };
    }
    const currentFields = flattenTableInstanceFields(currentInstance.datasetFields);
    if (existingInstance && !isFormDatasetInstance(existingInstance)) {
        const existingFields = flattenTableInstanceFields(existingInstance.datasetFields);
        const updatedFields = getUpdatedTableFields(currentFields, existingFields);
        const previouslyUpdatedFields = existingInstance.fieldsUpdated.filter(({ row }) => currentInstance.datasetRows.includes(row));
        const rowsRemoved = existingInstance.datasetRows.filter(rowId => !currentInstance.datasetRows.includes(rowId));
        if (isUpdating || currentInstance.isDraft) {
            const unchangedFields = previouslyUpdatedFields.filter(prev => updatedFields.every(({ id, row }) => !isEqual({ id, row }, { id: prev.id, row: prev.row })));
            return { unchangedFields, updatedFields, rowsRemoved };
        }
        return { updatedFields, unchangedFields: [] };
    }
    const newInstanceFields = flattenNewTableInstanceFields(currentInstance.datasetFields);
    const updatedFields = getUpdatedTableFields(currentFields, newInstanceFields);
    return { updatedFields, unchangedFields: [] };
};

export const unsetNewFormField = (field: SingleInstanceField, updatedFields: UpdatedFormField[]) => {
    const updatedField = updatedFields.find(({ id }) => id === field.id);
    if (updatedField) {
        return flow(
            unset('settings.datasetUpdated'),
            unset('settings.aiModified')
        )(field);
    }
    return field;
};

export const removeNewFieldsFromSection = (fields: InstanceField[], updatedFields: UpdatedFormField[]): InstanceField[] => fields.map(field => {
    if (isGroupInstanceField(field)) {
        const childFields = field.children.map(childField => unsetNewFormField(childField, updatedFields));
        return { ...field, children: childFields };
    }
    return unsetNewFormField(field, updatedFields);
});

export const removeNewFieldsFromRow = (fields: SingleInstanceField[], updatedFields: UpdatedTableField[], rowId: string): SingleInstanceField[] => fields.map(field => {
    const updatedField = updatedFields.find(({ id, row }) => isEqual({ id, row }, { id: field.id, row: rowId }));
    if (updatedField) {
        return flow(
            unset('settings.datasetUpdated'),
            unset('settings.aiModified')
        )(field);
    }
    return field;
});

export const removeNewPropertyFromUpdatedFields = (currentInstance: DatasetInstance, updatedFields: UpdatedFormField[] | UpdatedTableField[]): FormInstanceFields | TableInstanceFields => {
    if (isFormDatasetInstance(currentInstance)) {
        return Object.entries(currentInstance.datasetFields).reduce((acc, [sectionId, fields]) => set(`${sectionId}`, removeNewFieldsFromSection(fields, updatedFields), acc), {});
    } else {
        return Object.entries(currentInstance.datasetFields).reduce((acc, [rowId, fields]) => set(`${rowId}`, removeNewFieldsFromRow(fields, updatedFields as UpdatedTableField[], rowId), acc), {});
    }
};

export const childInstanceUpdated = (currentInstance: DatasetInstance, id: string, instanceId: string, rowId?: string) => {
    if (isFormDatasetInstance(currentInstance)) {
        const childInstanceField = currentInstance.fieldsUpdated.find(updatedField => updatedField.id === id);
        return childInstanceField && childInstanceField.value !== instanceId;
    } else {
        const childInstanceField = currentInstance.fieldsUpdated.find(field => isEqual({ id: field.id, row: field.row }, { id, row: rowId }));
        return childInstanceField && childInstanceField.value !== instanceId;
    }
};

export const updateFieldsUpdated = (datasetInstance: DatasetInstance, savedInstances: DatasetInstance[], isUpdating: boolean, executedDate?: string): DatasetInstance => {
    let fieldsUpdated = datasetInstance.fieldsUpdated;
    let datasetFields = datasetInstance.datasetFields;
    if (datasetInstance && datasetInstance.datasetInstanceId && savedInstances.length) {
        const existingInstance = savedInstances.find(({ datasetInstanceId }) => datasetInstanceId === datasetInstance.datasetInstanceId)!;
        const { updatedFields, unchangedFields } = compareInstanceFields(datasetInstance, existingInstance, isUpdating);
        fieldsUpdated = [...unchangedFields, ...updatedFields];
        datasetFields = removeNewPropertyFromUpdatedFields(datasetInstance, updatedFields);
    } else {
        const { updatedFields, unchangedFields } = compareInstanceFields(datasetInstance);
        fieldsUpdated = [...unchangedFields, ...updatedFields];
    }
    const instance = { ...datasetInstance, fieldsUpdated, datasetFields } as DatasetInstance;
    if (!isUpdating && executedDate) {
        return set('executedDate', executedDate, instance);
    }
    return instance;
};

export const setLastModifiedFormField = (field: SingleInstanceField, updatedFields: UpdatedFormField[], userId: number, currentTimestamp: string) => {
    const updatedField = updatedFields.find(({ id }) => id === field.id);
    if (updatedField) {
        return flow(
            set('modifiedDate', currentTimestamp),
            set('modifiedBy', userId)
        )(field);
    }
    return field;
};

export const setLastModified = (fields: InstanceField[], updatedFields: UpdatedFormField[], userId: number, currentTimestamp: string): InstanceField[] => fields.map(field => {
    if (isGroupInstanceField(field)) {
        const childFields = field.children.map(childField => setLastModifiedFormField(childField, updatedFields, userId, currentTimestamp));
        return { ...field, children: childFields };
    }
    return setLastModifiedFormField(field, updatedFields, userId, currentTimestamp);
});

export const setLastModifiedForRow = (fields: SingleInstanceField[], updatedFields: UpdatedTableField[], rowId: string, userId: number, currentTimestamp: string): SingleInstanceField[] => fields.map(field => {
    const updatedField = updatedFields.find(({ id, row }) => isEqual({ id, row }, { id: field.id, row: rowId }));
    if (updatedField) {
        return flow(
            set('modifiedDate', currentTimestamp),
            set('modifiedBy', userId)
        )(field);
    }
    return field;
});

export const updateLastModified = (datasetInstance: DatasetInstance, updatedFields: UpdatedField[], userId: number): FormInstanceFields | TableInstanceFields | AnnexInstanceFields => {
    const currentTimestamp = formatDateTime();
    if (isFormDatasetInstance(datasetInstance)) {
        return Object.entries(datasetInstance.datasetFields).reduce((acc, [sectionId, fields]) => set(`${sectionId}`, setLastModified(fields, updatedFields, userId, currentTimestamp), acc), {});
    }
    return Object.entries(datasetInstance.datasetFields).reduce((acc, [rowId, fields]) => set(`${rowId}`, setLastModifiedForRow(fields, updatedFields as UpdatedTableField[], rowId, userId, currentTimestamp), acc), {});
};

export const getNewCheckboxField = (id: string): SingleInstanceField => ({
    ...defaultField,
    id,
    type: DatasetFieldType.CHECKBOX,
    settings: { ...defaultField.settings, centreField: false, riskField: false },
    value: false,
    isCustom: true
});

// Search Dataset Fields within Dataset Instances
const flattenFormSearchFields = (fields: FormInstanceFields, sections: DatasetSection[] | DatasetInstanceSection[], parentFieldId: string, datasetId: string, parentFieldName: string | null, hiddenSectionIds: string[], hiddenSingleFields: HiddenField[], filterDatasetFields: boolean) =>
    flattenDeep(Object.entries(fields)
        // Filter out any sections which are hidden within hiddenFields for datasetId
        .filter(([sectionId]) => !hiddenSectionIds.includes(sectionId))
        .map(([sectionId, section]) => section
            // Filter out datasetFields unless we specify we do not want to when finding parent search fields to establish fields and sections to open
            .filter(({ type }) => !filterDatasetFields || type !== DatasetFieldType.DATASET)
            .map(field => {
                const fieldIsHidden = hiddenSingleFields.find(hiddenField => hiddenField.fieldId === field.id && hiddenField.sectionId === sectionId);
                if (fieldIsHidden) {
                    return [];
                }
                const sectionLabel = parentFieldName || sections.find(({ id }) => id === sectionId)?.label;
                if (isGroupField(field)) {
                    return field.children.map(({ id, label, type }, groupIndex) => ({ fieldId: id!, sectionId, type: OpenFieldType.FIELD, datasetId, parentFieldId, sectionLabel, label, fieldType: type, groupIndex })) as SearchField[];
                }
                return { fieldId: field.id!, sectionId, type: OpenFieldType.FIELD, datasetId, parentFieldId, sectionLabel, label: field.label, fieldType: field.type, groupIndex: undefined };
            })
        )
    ) as SearchField[];

const flattenTableSearchFields = (fields: TableInstanceFields, parentFieldId: string, datasetId: string, parentFieldName: string | null, hiddenSingleFieldIds: string[], filterDatasetFields: boolean): SearchField[] => Object.values(fields)[0]
    // Filter out datasetFields and any hidden fields for that dataset unless we specify we do not want to when finding parent search fields to establish fields and sections to open
    .filter(({ type, id }) => !filterDatasetFields || (type !== DatasetFieldType.DATASET && !hiddenSingleFieldIds.includes(id!)))
    .map(({ id, label, type }) => ({ fieldId: id!, type: OpenFieldType.FIELD, datasetId, parentFieldId, label, sectionLabel: parentFieldName || undefined, fieldType: type }));

// This function gets a flattened array of search fields and search sections which are very similar to HiddenField and HiddenSections with additional information on for labels and field type
export const getFlattenedSearchFields = (instance: DatasetInstance, parentFieldId: string, parentFieldName: string | null, hiddenSectionIds: string[], hiddenSingleFields: HiddenField[], filterDatasetFields = true) => {
    if (isFormDatasetInstance(instance)) {
        return flattenFormSearchFields(instance.datasetFields, instance.datasetSections, parentFieldId, instance.datasetId.toString(), parentFieldName, hiddenSectionIds, hiddenSingleFields, filterDatasetFields);
    }
    if (!isAnnexInstance(instance)) {
        const hiddenFieldIds = hiddenSingleFields.map(({ fieldId }) => fieldId);
        return flattenTableSearchFields(instance.datasetFields, parentFieldId, instance.datasetId.toString(), parentFieldName, hiddenFieldIds, filterDatasetFields);
    }
    return [];
};

// This will get the parent field name for a reference title for any datasets etc that are not within a section
// By using the parentFieldId and datasetId we are able to find the label of the parent field that the dataset sits within
const getParentFieldName = (instances: Instance[], hierarchy: Hierarchy[], instance: DatasetInstance, parentFieldId: string) => {
    let parentFieldName = null;
    const id = isNull(instance.annexDefinitionId) ? instance.datasetId! : instance.annexDefinitionId;
    const instanceInHierarchy = hierarchy.find(({ datasetId, fieldId }) => datasetId === id.toString() && fieldId === parentFieldId);
    if (instanceInHierarchy && instanceInHierarchy.parentId) {
        const { parentId, sectionId, fieldId } = instanceInHierarchy;
        const parentInstance = instances.map(({ instance }) => instance).find(({ datasetId }) => datasetId?.toString() === parentId);
        if (parentInstance) {
            if (isFormDatasetInstance(parentInstance)) {
                parentFieldName = parentInstance.datasetFields[sectionId].find(({ id }) => id === fieldId)?.label || null;
            } else {
                parentFieldName = (Object.values(parentInstance.datasetFields)[0] as SingleInstanceField[]).find(({ id }) => id === fieldId)?.label || null;
            }
        }
    }
    return parentFieldName;
};

const getParentId = (hierarchy: Hierarchy[], id: string, parentFieldId: string) => {
    const instanceInHierarchy = hierarchy.find(({ datasetId, fieldId }) => datasetId === id && fieldId === parentFieldId);
    if (instanceInHierarchy && instanceInHierarchy.parentId) {
        return instanceInHierarchy.parentId;
    }
    return null;
};

export const getAllFlattenedSearchFields = (instances: Instance[], hierarchy: Hierarchy[], hiddenFields: HiddenFields) => {
    const hiddenFieldSections = flattenDeep(Object.entries(hiddenFields).map(([datasetId, fields]) => fields.map(fieldSection => ({ ...fieldSection, datasetId }))));
    // Establish which if any of the datasets are completely hidden due to a parent section or field being toggled off in the hidden fields
    // If a dataset is completely hidden then we can retrun no search fields for this dataset
    const hiddenDatasetIds = uniq(hiddenFieldSections.reduce((acc: string[], cur) => {
        const instance = instances.find(({ instance: { datasetId, annexDefinitionId } }) => isNull(annexDefinitionId) && datasetId!.toString() === cur.datasetId);
        if (instance) {
            // If a hidden field or section has a sectionId then we can assert it is a FormInstance
            if (cur.sectionId) {
                if (cur.type === OpenFieldType.SECTION) {
                    const hiddenDatasetIds = (instance.instance as FormDatasetInstance).datasetFields[cur.sectionId].filter(({ type }) => type === DatasetFieldType.DATASET).map(field => (field as SingleInstanceField).settings.datasetLinked!);
                    return [...acc, ...hiddenDatasetIds];
                }
                if (cur.type === OpenFieldType.FIELD) {
                    const datasetField = (instance.instance as FormDatasetInstance).datasetFields[cur.sectionId].find(({ id }) => id === cur.fieldId);
                    if (datasetField && datasetField.type === DatasetFieldType.DATASET) {
                        return [...acc, (datasetField as SingleInstanceField).settings.datasetLinked!];
                    }
                }
                return acc;
            }
            const hiddenDatasetIds = Object.values((instance.instance as TableDatasetInstance).datasetFields)[0].filter(({ type }) => type === DatasetFieldType.DATASET).map(field => (field as SingleInstanceField).settings.datasetLinked!);
            return [...acc, ...hiddenDatasetIds];
        }
        return acc;
    }, []));

    const searchFields = flattenDeep(instances.map(({ instance, parentFieldId }) => {
        if (instance.datasetId && hiddenDatasetIds.includes(instance.datasetId.toString())) {
            return [];
        }
        // For each dataset, we establish what the hidden sections and hidden fields are within to decide whether to include them in the dataset search fields
        const hiddenSectionIds = !isNull(instance.datasetId) && hiddenFields[instance.datasetId] && (hiddenFields[instance.datasetId].filter(({ type }) => type === OpenFieldType.SECTION) as HiddenSection[]).map(({ sectionId }) => sectionId) || [];
        const hiddenSingleFields = !isNull(instance.datasetId) && hiddenFields[instance.datasetId] && hiddenFields[instance.datasetId].filter(({ type }) => type === OpenFieldType.FIELD) as HiddenField[] || [];
        const isTableOrSingleSectionDataset = isNull(instance.datasetSections) || isUndefined(instance.datasetSections) || instance.datasetSections.length === 1;
        const searchSections: SearchSection[] = !isTableOrSingleSectionDataset && instance.datasetSections!.filter(({ id }) => !hiddenSectionIds.includes(id)).map(({ id, label }) => ({ sectionId: id, datasetId: instance.datasetId!.toString(), type: OpenFieldType.SECTION, parentFieldId, sectionLabel: label })) || [];
        const parentFieldName = isTableOrSingleSectionDataset ? getParentFieldName(instances, hierarchy, instance, parentFieldId) : null;
        const searchFields: SearchField[] = getFlattenedSearchFields(instance, parentFieldId, parentFieldName, hiddenSectionIds, hiddenSingleFields);
        return [...searchSections, ...searchFields];
    }));
    return searchFields;
};

export const calculateOpenFieldSections = (match: SearchFieldSection, instances: Instance[], hierarchy: Hierarchy[]) => {
    // Initially, we check if the match which is selected is within a section which should be opened
    const initialOpenFieldsAndSections: OpenFieldSection[] = match.sectionId ? [{ sectionId: match.sectionId, datasetId: match.datasetId, type: OpenFieldType.SECTION, parentFieldId: match.parentFieldId }] : [];
    let parentId = getParentId(hierarchy, match.datasetId, match.parentFieldId);
    let currentParentFieldId = match.parentFieldId;
    let openFieldsAndSections: OpenFieldSection[] = initialOpenFieldsAndSections;
    // While we are not at the top level parent instance we need to iterate up the tree, going to the next parent and opening any dataset fields and sections which are releavnt to the next parent
    while (!isNull(parentId)) {
        const parentInstance = instances.find(({ instance: { datasetId, annexDefinitionId } }) => isNull(annexDefinitionId) && datasetId === parseInt(parentId!));
        if (parentInstance) {
            // Get the parent instance search fields to allow us to easily find the correct fields within that parent and identify where the dataset field is and open it accordingly as an OpenField
            const allParentSearchFields = getFlattenedSearchFields(parentInstance.instance, parentInstance.parentFieldId, null, [], [], false);
            const parentSearchField = allParentSearchFields.find(({ fieldId }) => fieldId === currentParentFieldId);
            if (parentSearchField && parentSearchField.fieldType === DatasetFieldType.DATASET && parentSearchField.type === OpenFieldType.FIELD) {
                const openField: OpenField = flow(
                    unset('fieldType'),
                    unset('label'),
                    unset('sectionLabel')
                )(parentSearchField);
                openFieldsAndSections.push(openField);
                // Simialr to above, if the parentSearchField is within a section, we have to open up that section as well
                if (parentSearchField.sectionId) {
                    const openSection: OpenSection = { sectionId: parentSearchField.sectionId, datasetId: parentSearchField.datasetId, type: OpenFieldType.SECTION, parentFieldId: parentSearchField.parentFieldId };
                    openFieldsAndSections.push(openSection);
                }
                parentId = getParentId(hierarchy, parentSearchField.datasetId, parentSearchField.parentFieldId);
                currentParentFieldId = parentSearchField.parentFieldId;
            }
        } else {
            // If there is no parentInstance then we set the parentId to null which will escape the while loop and return the openFieldsAndSections
            parentId = null;
        }
    }
    return openFieldsAndSections;
};
