import React, { useCallback, useMemo } from 'react';
import { flow, unset } from 'lodash/fp';

import { Eye, Home, OfficeBuilding } from '../../shared/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { clearEntityTableFilters, entitiesPaginationNext, entitiesPaginationPrevious, Entity, EntityDB, EntityModalType, EntityType, fetchPaginatedEntitiesStarted, getEntitiesPageNumber, getEntitiesPageSize, getEntityColumnSort, getEntityFilters, getIsFetchingEntities, getPaginatedEntities, getTotalEntities, setEntitiesPageSize, setEntityTableColumnSort, setEntityTableFilters, toggleModal } from './store';
import { dateFormatter, stringFormatter } from '../../shared/table/arkTableFormatters';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { Action } from '../../shared/modal/ActionModal';
import { DropdownOption } from '../../shared/dropdown/Dropdown';
import { TableFilterType } from '../../shared/modal/TableFilterModal';

const stripEntity = (entity: EntityDB): Entity => flow(
    unset('clientId'),
    unset('active'),
    unset('createdBy'),
    unset('createdDate'),
    unset('modifiedBy'),
    unset('modifiedDate')
)(entity);

export const entityTypeOptions: DropdownOption[] = [
    { value: EntityType.COMPANY, label: 'Company' },
    { value: EntityType.PERSON, label: 'Person' },
    { value: EntityType.GROUP, label: 'Group' }
];

export const EntityTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchPaginatedEntitiesStarted()]);
    const entities = useAppSelector(getPaginatedEntities);
    const isLoading = useAppSelector(getIsFetchingEntities);

    const pageNumber = useAppSelector(getEntitiesPageNumber);
    const totalEntities = useAppSelector(getTotalEntities);
    const entityFilters = useAppSelector(getEntityFilters);
    const columnSort = useAppSelector(getEntityColumnSort);
    const pageSize = useAppSelector(getEntitiesPageSize);
    const nextPage = useCallback(() => { dispatch(entitiesPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(entitiesPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setEntityTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearEntityTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setEntitiesPageSize(pageSize)); }, [dispatch]);

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setEntityTableColumnSort(columnSort)); }, [dispatch]);

    const openEntity = useCallback((entity: EntityDB) => { dispatch(toggleModal(EntityModalType.CREATE, stripEntity(entity))); }, [dispatch]);

    const actions = useCallback((data: EntityDB): Action[] => [
        { label: 'Open', icon: OfficeBuilding, onClick: () => openEntity(data) }
    ], [openEntity]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'name', header: 'Name', field: 'name', width: 0.47, canFilter: true, canSort: true },
        { id: 'myCompany', header: '', field: 'myCompany', component: 'icon', width: 0.03, icon: Home },
        { id: 'onWatchlist', header: '', field: 'onWatchlist', component: 'icon', width: 0.03, icon: Eye },
        { id: 'type', header: 'Type', field: 'type', width: 0.17, valueFormatter: stringFormatter, canFilter: true, canSort: true },
        { id: 'createdDate', header: 'Created Date', field: 'createdDate', width: 0.25, valueFormatter: dateFormatter, canSort: true },
        { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
    ];

    const filterDropdownOptions = useMemo(() => ({ type: entityTypeOptions }), []);

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={entities}
            testId='entities'
            isLoading={isLoading}
            page={pageNumber}
            total={totalEntities}
            next={nextPage}
            previous={previousPage}
            filters={entityFilters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => openEntity(row as EntityDB)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
            filterDropdownOptions={filterDropdownOptions}
            removeTextFilter={['type']}
        />
    );
};
