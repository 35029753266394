import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getAllDatasets, setPreviewSelectedDocument } from '../store';
import { AllDocumentDatasetDefinitions } from './AllDocumentDatasetDefinitions';

export interface DatasetPreviewProps {
    datasetId: number;
}

export const DatasetPreview: React.FC<DatasetPreviewProps> = ({ datasetId }) => {
    const dispatch = useAppDispatch();
    const allDatasets = useAppSelector(getAllDatasets);
    const initialDocumentOption = useMemo(() => {
        const initialDocument = allDatasets.find(dataset => dataset.datasetId === datasetId)?.documentNames[0].documentNameId;
        return initialDocument && initialDocument || null;
    }, [allDatasets, datasetId]);
    dispatch(setPreviewSelectedDocument(initialDocumentOption));
    return (
        <AllDocumentDatasetDefinitions title='Preview' datasetId={datasetId} isPreview />
    );
};
