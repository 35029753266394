import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { ContractualDataAnalytics, DoraAnalyticsChart, DoraAnalyticsState, NetworkDiagramData, OutstandingContractualInformationPieAnalytics, OutstandingContractualInformationPieCategories, SelectedThirdPartyCoverage, ThirdPartyCoverageAnalytics, ThirdPartyFunctionCompanyCoveragePieAnalytics } from './types';

const categorizeContractualData = (data: ContractualDataAnalytics) => {
    const { doraSupplyChainAssessmentId, doraSupplyChainContractId } = data;
    if (doraSupplyChainAssessmentId && doraSupplyChainContractId) {
        return OutstandingContractualInformationPieCategories.COMPLETE;
    }
    if (isNull(doraSupplyChainAssessmentId) && isNull(doraSupplyChainContractId)) {
        return OutstandingContractualInformationPieCategories.MISSING_BOTH;
    }
    return OutstandingContractualInformationPieCategories.MISSING_ONE;
};

const getRoot = (state: ApplicationState): DoraAnalyticsState => state.dora.analytics;

export const getTileInView = (state: ApplicationState): DoraAnalyticsChart | null => getRoot(state).tileInView;

export const getFetchingAnalytics = (state: ApplicationState): DoraAnalyticsChart[] => getRoot(state).fetchingAnalytics;

export const getThirdPartyCoverageAnalytics = (state: ApplicationState): ThirdPartyCoverageAnalytics[] => getRoot(state).thirdPartyCoverage;

export const getPaginatedThirdPartyFunctionCompanyData = (state: ApplicationState): SelectedThirdPartyCoverage[] => getRoot(state).selectedThirdPartyCoverageTable;

export const getThirdPartyFunctionCompanyPieData = (state: ApplicationState): ThirdPartyFunctionCompanyCoveragePieAnalytics[] => getRoot(state).selectedThirdPartyCoveragePie;
export const getThirdPartyName = (state: ApplicationState): string => getRoot(state).selectedThirdPartyCompanyName;
export const getThirdPartyCompanyId = (state: ApplicationState): number | null => getRoot(state).selectedThirdPartyCompanyId;

export const getIsFetchingThirdPartyCoverageTableData = (state: ApplicationState): boolean => getRoot(state).fetchingSelectedThirdPartyCoverageTable;
export const getThirdPartyCoverageFilters = (state: ApplicationState): TableFilters => getRoot(state).selectedThirdPartyCoverageTableFilters;
export const getThirdPartyCoveragePageNumber = (state: ApplicationState): number => getRoot(state).selectedThirdPartyCoverageTablePageNumber;
export const getThirdPartyCoverageColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).selectedThirdPartyCoverageTableColumnSort;
export const getThirdPartyCoveragePageSize = (state: ApplicationState): number => getRoot(state).selectedThirdPartyCoverageTablePageSize;
export const getTotalFunctionCompanies = (state: ApplicationState): number => getRoot(state).totalFunctionCompanies;

export const getNetworkDiagramAnalytics = (state: ApplicationState): NetworkDiagramData => getRoot(state).networkDiagramData;

export const getContractualDataModalOpen = (state: ApplicationState): boolean => getRoot(state).contractualDataModalOpen;

export const getSelectedContractualDataAnalytics = (state: ApplicationState): ContractualDataAnalytics[] => {
    const selectedPieSegment = getRoot(state).selectedContractualDataAnalytics;
    const selectedContractualDataAnalytics = [...getRoot(state).contractualDataAnalytics].filter(item => {
        if (selectedPieSegment === OutstandingContractualInformationPieCategories.MISSING_BOTH) {
            return !item.doraSupplyChainAssessmentId && !item.doraSupplyChainContractId;
        }
        if (selectedPieSegment === OutstandingContractualInformationPieCategories.COMPLETE) {
            return item.doraSupplyChainAssessmentId && item.doraSupplyChainContractId;
        }
        if (selectedPieSegment === OutstandingContractualInformationPieCategories.MISSING_ONE) {
            return (item.doraSupplyChainAssessmentId && !item.doraSupplyChainContractId) || (!item.doraSupplyChainAssessmentId && item.doraSupplyChainContractId);
        }
    });
    return selectedContractualDataAnalytics;
};

export const getContractualDataAnalyticsPieData = (state: ApplicationState): OutstandingContractualInformationPieAnalytics[] => {
    const analyticsData = getRoot(state).contractualDataAnalytics;
    const allCategories = analyticsData.reduce((acc, curr) => {
        const category = categorizeContractualData(curr);
        if (acc[category]) {
            acc[category] += 1;
        } else {
            acc[category] = 1;
        }
        return acc;
    }, {} as Record<OutstandingContractualInformationPieCategories, number>);

    return Object.entries(allCategories).map(([key, value]) => ({
        key: key as OutstandingContractualInformationPieCategories,
        value
    }));
};
