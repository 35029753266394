import React, { useCallback, useMemo } from 'react';
import { isEqual, isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Home } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { getSelectedThirdPartyCompany, getCurrentThirdPartyCompany, getThirdPartyCompaniesModalOpen, saveThirdPartyCompanyStarted, updateThirdPartyCompanyValue, setSelectedThirdPartyCompany, getThirdPartyCompanyIsSaving } from './store';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Text } from '../../shared/text/Text';
import styles from './ThirdPartyCompanies.module.scss';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { Toggle } from '../../shared/toggle';
import { Spinner } from '../../shared/spinner/Spinner';

export const ThirdPartyCompaniesModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getThirdPartyCompaniesModalOpen);
    const isSaving = useAppSelector(getThirdPartyCompanyIsSaving);
    const closeModal = useCallback(() => dispatch(setSelectedThirdPartyCompany(null)), [dispatch]);
    const selectedThirdPartyCompany = useAppSelector(getSelectedThirdPartyCompany);
    const currentThirdPartyCompany = useAppSelector(getCurrentThirdPartyCompany);

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const thirdPartyUpdated = useMemo(() => !isEqual(selectedThirdPartyCompany, currentThirdPartyCompany), [selectedThirdPartyCompany, currentThirdPartyCompany]);
    const saveDisabled = useMemo(() => selectedThirdPartyCompany?.name === '' || !thirdPartyUpdated || !hasDoraFullAccessPermission, [thirdPartyUpdated, selectedThirdPartyCompany, hasDoraFullAccessPermission]);
    const saveThirdParty = useCallback(() => dispatch(saveThirdPartyCompanyStarted()), [dispatch]);

    const toggleIsThirdParty = useCallback((value: boolean) => dispatch(updateThirdPartyCompanyValue('isDoraThirdParty', !value ? 0 : 1)), [dispatch]);

    const isActiveThirdParty = useMemo(() => !isNull(currentThirdPartyCompany) && !!currentThirdPartyCompany.isActiveThirdParty, [currentThirdPartyCompany]);
    const thirdPartyDisabled = useMemo(() => !hasDoraFullAccessPermission || isActiveThirdParty, [hasDoraFullAccessPermission, isActiveThirdParty]);

    if (isNull(selectedThirdPartyCompany)) {
        return null;
    }

    const { name, content, isDoraThirdParty } = selectedThirdPartyCompany;

    const entityName = name || '';
    const companyLei = content.lei || '';

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            testId='third-party-companies'
            confirm={saveThirdParty}
            confirmLabel='Save'
            confirmDisabled={saveDisabled}
            closeLabel='Close'
        >
            <ModalHeader label={entityName || 'ICT Third-Party Service Provider'} icon={Home} testId='third-party-companies' />
            <div className={styles.thirdPartyCompaniesContentWrapper} data-testid='create-entity-content-wrapper'>
                <Scrollable width='700px'>
                    {isSaving ?
                        <div className={styles.thirdPartyCompaniesLoadingWrapper}>
                            <Spinner />
                        </div> :
                        <>
                            <Text
                                label='Company Name'
                                testId='third-party-company-name'
                                value={entityName}
                                maxLength={256}
                                disabled
                            />
                            <Text
                                label='Legal Entity Identifier (LEI)'
                                testId='third-party-company-lei'
                                value={companyLei}
                                disabled
                            />
                            <div className={styles.toggleWrapper}>
                                <div className={styles.toggleLabel}>Is the company acting as an ICT Third Party Service Provider?</div>
                                <Toggle
                                    checked={!!isDoraThirdParty}
                                    onChange={toggleIsThirdParty}
                                    testId='third-party-company-toggle'
                                    disabled={thirdPartyDisabled}
                                />
                            </div>
                            {isActiveThirdParty && <div className={styles.warningMessage}>This company is an active part of a DORA supply chain within your organisation. You must remove it from all supply chains before changing its ICT Third Party Service Provider status</div>}
                        </>
                    }
                </Scrollable>
            </div>
        </ConfirmationModal>
    );
};
