import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { LinkedDocument, openDocumentStarted } from '../../documents/my-documents/store';
import { Icon } from '../../shared/icon/Icon';
import { Link } from '../../shared/icons';
import { Position } from '../../shared/modal/PositionModal';
import styles from './DatasetInstance.module.scss';
import { LinkedDocumentModal } from './LinkedDocumentModal';
import { editDatasetInstance, getLinkedDocumentModalOpen, toggleLinkedDocumentModal, updateDatasetInstance } from './store';

interface LinkedDocumentButtonProps {
    linkedDocuments: LinkedDocument[];
    disabled: boolean;
}

export const LinkedDocumentButton: React.FC<LinkedDocumentButtonProps> = ({ linkedDocuments, disabled }) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getLinkedDocumentModalOpen);
    const [position, setPosition] = useState<Position | null>(null);
    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        dispatch(toggleLinkedDocumentModal(true));
    };

    const closeModal = () => dispatch(toggleLinkedDocumentModal(false));

    const linkToDocument = (location: string, documentId: number, mimeType: string) => {
        closeModal();
        dispatch(editDatasetInstance(false));
        dispatch(updateDatasetInstance(false));
        dispatch(openDocumentStarted(location, documentId, mimeType));
    };

    return (
        <>
            <button className={styles.iconButtonWrapper} disabled={disabled} onClick={e => openModal(e.clientX, e.clientY)}>
                <Icon icon={Link} />
            </button>
            <LinkedDocumentModal
                isOpen={isOpen}
                linkedDocuments={linkedDocuments}
                position={position}
                closeModal={closeModal}
                linkToDocument={linkToDocument}
            />
        </>
    );
};
