import React, { useCallback, useMemo } from 'react';
import { isNull, noop } from 'lodash/fp';

import { PlaybookDeviation, ValidForInterval } from '../../../admin/playbook/store';
import { WYSIWYG } from '../../wysiwyg/WYSIWYG';
import styles from './PlaybookReadOnly.module.scss';
import { OverflowTooltip } from '../../tooltip';
import { Scrollable } from '../../scrollable/Scrollable';
import { dateNow, formatDateTime, futureDate, startOfDay, isBefore } from '../../../../utils/luxon';
import { DeviationSuggestedChange } from '../../../playbook/store';
import { SuggestedChangesMarker } from './SuggestedChangesMarker';

interface ReadOnlyDeviationsProps {
    deviations: PlaybookDeviation[];
    readOnly?: boolean;
    selectedSectionId?: string;
    sectionId: string;
    getSelectedText?: (selectedText: string) => void;
    deviationChangedContent?: DeviationSuggestedChange[];
    showValidFor: boolean;
    testId?: string;
}
const { white, green, red } = styles;

export const ReadOnlyDeviations: React.FC<ReadOnlyDeviationsProps> = ({
    deviations,
    readOnly = true,
    selectedSectionId,
    sectionId,
    getSelectedText,
    deviationChangedContent = [],
    showValidFor = false,
    testId = 'playbook'
}) => {

    const showSuggestedChangeMarker = useCallback((rowId: string, key: keyof PlaybookDeviation) => !!deviationChangedContent.find(change => change.rowId === rowId)?.content.includes(key), [deviationChangedContent]);

    const columnHeaders = useMemo(() => [
        { label: 'Topic', width: '17%', minWidth: '140px', borderRight: `solid 1px ${white}`, padding: '5px' },
        { label: 'Possible Amendments/Variations', width: '30%', minWidth: '400px', borderRight: `solid 1px ${white}`, padding: '5px 0' },
        { label: 'Background/Rationale/Stakeholder', width: '30%', minWidth: '400px', borderRight: `solid 1px ${white}`, padding: '5px 0' },
        { label: 'Negotiation ID', width: '13%', minWidth: '140px', borderRight: `solid 1px ${white}`, padding: '5px' },
        { label: 'Approval Date', width: '10%', minWidth: '140px', borderRight: 'none', padding: '5px' }
    ], []);

    const approvalDateValid = useCallback((approvalDate: string | null, validForInterval: ValidForInterval | null, validForIntervalValue: number | null) => {
        if (isNull(approvalDate)) {
            return 'none';
        }
        const validFor = { [`${validForInterval}`]: validForIntervalValue };
        const validUntilDate = formatDateTime(startOfDay(futureDate(validFor, approvalDate)));
        const todaysDate = formatDateTime(dateNow());
        return isBefore(todaysDate, validUntilDate) ? green : red;
    }, []);

    const approvalDateLabel = useCallback((approvalDate: string | null, validForInterval: ValidForInterval | null, validForIntervalValue: number | null) => {
        if (isNull(approvalDate)) {
            return '';
        }
        return showValidFor ? `${approvalDate} - Valid for: ${validForIntervalValue} ${validForInterval}` : approvalDate;
    }, [showValidFor]);

    return (
        <div className={styles.scrollableTableWrapper}>
            <Scrollable>
                <div className={styles.readOnlyDeviationTableWrapper} data-testid={`${testId}-deviations-table-wrapper`}>
                    <div className={styles.readOnlyTableHeaders}>
                        {columnHeaders.map(({ label, width, borderRight, minWidth, padding }, index) => (
                            <div className={styles.readOnlyColumnHeaderWrapper} style={{ borderRight, width, minWidth, padding }} key={index}>
                                <OverflowTooltip className={styles.readOnlyColumnHeader} overlayText={label} testId={`${testId}-deviations-column-header-${index}`} />
                            </div>
                        ))}
                    </div>
                    {deviations.map(({ variations, background, nid, rowId, topic, approvalDate, validForInterval, validForIntervalValue }, index) => (
                        <div className={styles.readOnlyTableRowWrapper} key={rowId} data-testid={`${testId}-deviations-table-row-${index}-wrapper`}>
                            <div className={styles.readOnlyTopicWrapper} data-testid={`${testId}-deviations-table-row-${index}-topic`}>
                                {topic}
                                {showSuggestedChangeMarker(rowId, 'topic') && <SuggestedChangesMarker testId={`${testId}-deviations-table-row-${index}-topic`} />}
                            </div>
                            <div className={styles.readOnlyWysiwygColumnWrapper}>
                                <div className={styles.readOnlyWysiwygWrapper} data-testid={`${testId}-deviations-table-row-${index}-amendments`}>
                                    <WYSIWYG
                                        content={variations}
                                        updateContent={noop}
                                        showBorder={false}
                                        showWrapperBorder={false}
                                        height='fit-content'
                                        minHeight='fit-content'
                                        maxHeight='fit-content'
                                        disabled={readOnly}
                                        toolbarHidden
                                        isSelected={sectionId === selectedSectionId}
                                        getSelectedText={getSelectedText}
                                        isReadOnly={readOnly}
                                    />
                                    {showSuggestedChangeMarker(rowId, 'variations') && <SuggestedChangesMarker testId={`${testId}-deviations-table-row-${index}-amendments`} />}
                                </div>
                            </div>
                            <div className={styles.readOnlyWysiwygColumnWrapper}>
                                <div className={styles.readOnlyWysiwygWrapper} data-testid={`${testId}-deviations-table-row-${index}-rationale`}>
                                    <WYSIWYG
                                        content={background}
                                        updateContent={noop}
                                        showBorder={false}
                                        showWrapperBorder={false}
                                        height='fit-content'
                                        minHeight='fit-content'
                                        maxHeight='fit-content'
                                        disabled={readOnly}
                                        toolbarHidden
                                        isSelected={sectionId === selectedSectionId}
                                        getSelectedText={getSelectedText}
                                        isReadOnly={readOnly}
                                    />
                                    {showSuggestedChangeMarker(rowId, 'background') && <SuggestedChangesMarker testId={`${testId}-deviations-table-row-${index}-rationale`} />}
                                </div>
                            </div>
                            <div className={styles.readOnlyTextColumnWrapper}>
                                <div className={styles.textOverflowWrapper}>
                                    <OverflowTooltip overlayText={nid} testId={`${testId}-deviations-table-row-${index}-nid`} placement='top' />
                                </div>
                                {showSuggestedChangeMarker(rowId, 'nid') && <SuggestedChangesMarker testId={`${testId}-deviations-table-row-${index}-nid`} />}
                            </div>
                            <div className={styles.readOnlyApprovalDateWrapper} style={{ color: approvalDateValid(approvalDate, validForInterval, validForIntervalValue) }}>
                                <div className={styles.textOverflowWrapper}>
                                    <OverflowTooltip overlayText={approvalDateLabel(approvalDate, validForInterval, validForIntervalValue)} testId={`${testId}-deviations-table-row-${index}-approval-date`} />
                                </div>
                                {showSuggestedChangeMarker(rowId, 'approvalDate') && <SuggestedChangesMarker testId={`${testId}-deviations-table-row-${index}-approval-date`} />}
                            </div>
                        </div>
                    ))}
                </div>
            </Scrollable>
        </div>
    );
};
