import React, { useEffect, useMemo } from 'react';
import { isUndefined } from 'lodash/fp';

import styles from './ClauseModal.module.scss';
import { User, Globe } from '../../shared/icons';
import { TileSelect, Tile } from '../../shared/tile-select/TileSelect';
import { ClauseType } from '../../clause-library/store';

interface ClauseTileSelectorProps {
    selectType: (type: ClauseType) => void;
    setShowClauseSelect?: (type: boolean) => void;
    setShowTagSelect?: (type: boolean) => void;
    clauseTags?: boolean;
}
export const ClauseTileSelector: React.FC<ClauseTileSelectorProps> = ({ selectType, clauseTags = false, setShowClauseSelect, setShowTagSelect }) => {
    const testId = useMemo(() => clauseTags ? 'tag' : 'modal', [clauseTags]);
    const selectTiles: Tile[] = useMemo(() => {
        switch (clauseTags) {
            case true:
                return (
                    [{
                        label: 'My Tags',
                        description: ['Manage and create your clause library tags.'],
                        onClick: () => selectType(ClauseType.CLIENT_CLAUSE),
                        testId: 'my-clause-tags',
                        icon: User
                    },
                    {
                        label: 'Template Tags',
                        description: ['Manage and create template clause library tags, note these will be available to all ARK-51 users.'],
                        onClick: () => selectType(ClauseType.TEMPLATE_CLAUSE),
                        testId: 'template-clause-tags',
                        icon: Globe
                    }]
                );
            case false:
            default:
                return (
                    [{
                        label: 'My Clause',
                        description: ['Create a clause that will only be available to your users.'],
                        onClick: () => selectType(ClauseType.CLIENT_CLAUSE),
                        testId: 'my-clause',
                        icon: User
                    },
                    {
                        label: 'Template Clause',
                        description: ['Create a clause that will be available to all ARK-51 users.'],
                        onClick: () => selectType(ClauseType.TEMPLATE_CLAUSE),
                        testId: 'template-clause',
                        icon: Globe
                    }]
                );
        }
    }, [selectType, clauseTags]);

    useEffect(() => () => {
        [setShowClauseSelect, setShowTagSelect].forEach(givenAction=> {
            if (!isUndefined(givenAction)) {
                givenAction(false);
            }
        });
    }, [setShowClauseSelect, setShowTagSelect]);

    return (
        <div className={styles.tilesWrapper}>
            <TileSelect testId={`clause-${testId}-select`} tiles={selectTiles} />
        </div>
    );
};
