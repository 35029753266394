import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import styles from '../../Analytics.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { getAllDatasetsRiskTolerance, getSelectedRiskToleranceParentDatasetId, setRiskToleranceDatasetId } from '../../store';
import { DocumentDatasetsRiskToleranceMenu } from './DocumentDatasetRiskToleranceMenu';
import { RiskToleranceDatasetTiles } from '../../../../admin/risk-tolerance/RiskTolerance';
import { getIsFetchingDatasetDefinitions, getSavedHiddenFields } from '../../../../admin/my-datasets/store';
import { Spinner } from '../../../../shared/spinner/Spinner';
import { useFetchStarted } from '../../../../../hooks/useFetchStarted';
import { fetchAllDocumentNamesStarted } from '../../../../admin/documents/store';

interface DocumentDatasetsRiskToleranceProps {
    height: number;
    width: number;
    fetchRiskTolerance: (datasetId: number) => void;
    testId?: string;
    entityId?: number;
    isPreExecution?: boolean;
}

const MARGIN_TOP_AND_BOTTOM = 40;
const TILE_HEADER = 40;
const HEADER_AND_MARGIN = MARGIN_TOP_AND_BOTTOM + TILE_HEADER;

export const DocumentDatasetsRiskTolerance: React.FC<DocumentDatasetsRiskToleranceProps> = ({ height, width, fetchRiskTolerance, testId, entityId, isPreExecution }) => {
    const dispatch = useAppDispatch();

    useFetchStarted([fetchAllDocumentNamesStarted()]);

    const datasetsRiskTolerance = useAppSelector(getAllDatasetsRiskTolerance);
    const selectedDatasetId = useAppSelector(getSelectedRiskToleranceParentDatasetId);
    const hiddenFields = useAppSelector(getSavedHiddenFields);
    const isFetchingDefinitions = useAppSelector(getIsFetchingDatasetDefinitions);

    const backToDatasets = useCallback(() => dispatch(setRiskToleranceDatasetId(null)), [dispatch]);

    const datasetsWrapperWidth = useMemo(() => width / 2, [width]);
    const datasetsWrapperHeight = useMemo(() => height - HEADER_AND_MARGIN, [height]);

    if (isFetchingDefinitions) {
        return (
            <div className={styles.datasetRiskToleranceWrapper} style={{ height: datasetsWrapperHeight, width: datasetsWrapperWidth }} data-testid={`${testId}-datasets-wrapper`}>
                <Spinner />;
            </div>
        );
    }

    return (
        <div className={styles.datasetRiskToleranceWrapper} style={{ height: datasetsWrapperHeight, width: datasetsWrapperWidth }} data-testid={`${testId}-datasets-wrapper`}>
            {!isNull(selectedDatasetId) ?
                <DocumentDatasetsRiskToleranceMenu
                    datasetId={selectedDatasetId}
                    allRiskToleranceDatasets={datasetsRiskTolerance}
                    back={backToDatasets}
                    height={datasetsWrapperHeight}
                    width={datasetsWrapperWidth}
                    hiddenFields={hiddenFields}
                    entityId={entityId}
                    isPreExecution={isPreExecution}
                    selectedDatasetId={selectedDatasetId}
                />
                :
                <RiskToleranceDatasetTiles
                    isLoading={false}
                    riskToleranceDatasets={datasetsRiskTolerance}
                    buttonLabel='Provisions'
                    onClick={fetchRiskTolerance}
                    tileWrapperWidth={'calc((100% / 2) - 40px)'}
                />
            }
        </div>
    );
};
