import { isNull } from 'lodash/fp';
import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Document } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';
import { deleteClauseStarted, getClauseToDelete, getDeleteClauseModalOpen, toggleDeleteClauseModal } from '../../clause-library/store';

export const DeleteClauseConfirmationModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getDeleteClauseModalOpen);
    const clauseToDelete = useAppSelector(getClauseToDelete);

    if (isNull(clauseToDelete)) {
        return null;
    }
    const { clauseId, title, systemTemplate } = clauseToDelete;

    const closeModal = () => dispatch(toggleDeleteClauseModal(null));
    const deleteClause = () => {
        dispatch(deleteClauseStarted(clauseId!, systemTemplate));
        closeModal();
    };

    const headerLabel = `Delete ${systemTemplate ? 'Template ' : ''}Clause`;

    const warning = 'This will permanently delete the clause and cannot be undone, are you sure you want to remove it? (Note this will also remove any links to an opinion)';

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={deleteClause}
            isDeleting={false}
            headerLabel={headerLabel}
            name={title}
            testId='clause'
            warning={warning}
            icon={Document}
            deleteLabel='clause from your library'
        />
    );
};
