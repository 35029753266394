import { noop } from 'lodash/fp';
import Slider from 'rc-slider';
import React, { useCallback, useMemo } from 'react';

import { RiskAssociated } from '../../admin/risk-tolerance/store';
import { RadioButton } from '../button/RadioButton';
import { Tick } from '../icons';
import styles from './RiskTolerance.module.scss';
import { riskAssociatedColors } from './RiskToleranceConfig';

const { grey, white } = styles;

interface WeightingSliderProps {
    weighting: number;
    setWeighting: (value: number) => void;
    includeRiskField: boolean;
    showRiskFieldToggles: boolean;
    toggleRiskField: () => void;
}

interface MarkerProps {
    label: string;
    centreText?: boolean;
}
const Marker: React.FC<MarkerProps> = ({ label, centreText = false }) => (
    <div className={styles.markerWrapper} style={{ textAlign: centreText ? 'center' : 'left' }}>{label}</div>
);

const marks = (centreText: boolean) => ({
    1: <Marker label='Of Little Importance' centreText={centreText} />,
    2: <Marker label='-' centreText={centreText} />,
    3: <Marker label='Important' centreText={centreText} />,
    4: <Marker label='-' centreText={centreText} />,
    5: <Marker label='Very Important' centreText={centreText} />
});

const getLinearGradient = ((weighting: number, getSelectedColor: (value: number) => string) => {
    const weightingArray = Array(weighting).fill(1).map((value, index) => value * index + 1);
    const percentageSplit = 100 / weightingArray.length;
    return weightingArray.map(value => `${getSelectedColor(value)} ${percentageSplit * value}%`).join(', ');
});

export const WeightingSlider: React.FC<WeightingSliderProps> = ({ weighting, setWeighting, showRiskFieldToggles, includeRiskField, toggleRiskField }) => {
    const getSelectedColor = useCallback((value: number) => riskAssociatedColors[(value - 1) as RiskAssociated], []);
    const selectedColor = useMemo(() => getSelectedColor(weighting), [weighting, getSelectedColor]);
    const linearGradient = getLinearGradient(weighting, getSelectedColor);

    return (
        <div className={styles.weightingWrapper}>
            <div className={styles.sliderWrapper} style={{ height: showRiskFieldToggles ? '80%' : '100%' }}>
                <Slider
                    min={1}
                    onChange={value => setWeighting(value as number)}
                    defaultValue={3}
                    value={weighting || 3}
                    max={5}
                    step={1}
                    vertical
                    marks={marks(false)}
                    railStyle={{
                        backgroundColor: grey,
                        width: '6px'
                    }}
                    handleStyle={{
                        backgroundColor: selectedColor,
                        border: `1px solid ${selectedColor}`,
                        height: '16px',
                        width: '16px',
                        marginLeft: '-5px',
                        opacity: 1
                    }}
                    dotStyle={(dotValue: number) => ({
                        backgroundColor: white,
                        border: `2px solid ${getSelectedColor(dotValue)}`,
                        height: '12px',
                        width: '12px',
                        left: '-1px'
                    })}
                    trackStyle={{ backgroundImage: `linear-gradient(to top, ${linearGradient}`, width: '6px' }}
                    disabled={!includeRiskField}
                />
            </div>
            {showRiskFieldToggles &&
                <div className={styles.includeFieldWrapper}>
                    <div className={styles.includeFieldLabel}>I want to exclude this field from my Risk Tolerance configuration</div>
                    <RadioButton fontSize={10} withBackground={!includeRiskField} onClick={toggleRiskField} icon={Tick} />
                </div>
            }
        </div>
    );
};

interface HorizontalWeightingSliderProps {
    weighting: number;
}

export const HorizontalWeightingSlider: React.FC<HorizontalWeightingSliderProps> = ({ weighting }) => {
    const getSelectedColor = useCallback((value: number) => riskAssociatedColors[(value - 1) as RiskAssociated], []);
    const selectedColor = useMemo(() => getSelectedColor(weighting), [weighting, getSelectedColor]);
    const linearGradient = getLinearGradient(weighting, getSelectedColor);

    return (
        <div className={styles.horizontalWeightingWrapper}>
            <div className={styles.horizontalSliderWrapper}>
                <Slider
                    min={1}
                    marks={marks(true)}
                    onChange={noop}
                    value={weighting || 3}
                    max={5}
                    step={1}
                    trackStyle={{
                        backgroundImage: `linear-gradient(to right, ${linearGradient}`,
                        height: '6px'
                    }}
                    railStyle={{
                        backgroundColor: grey
                    }}
                    handleStyle={{
                        backgroundColor: selectedColor,
                        border: `1px solid ${selectedColor}`,
                        height: '16px',
                        width: '16px',
                        marginTop: '-6px',
                        opacity: 1,
                        cursor: 'default'
                    }}
                    dotStyle={(dotValue: number) => ({
                        backgroundColor: white,
                        border: `1px solid ${getSelectedColor(dotValue)}`,
                        cursor: 'default'
                    })}
                />
            </div>
        </div>
    );
};
