import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import styles from './DatasetBuilder.module.scss';
import { newDatasetDefinition } from './store';
import { DatasetType } from '../../datasets/store';
import { Table, Form } from '../../shared/icons';
import { TileSelect, Tile } from '../../shared/tile-select/TileSelect';

export const DefinitionTypeSelector: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectDatasetDefinitionType = useCallback((type: DatasetType) => dispatch(newDatasetDefinition(type)), [dispatch]);
    const selectForm = useCallback(() => selectDatasetDefinitionType(DatasetType.FORM), [selectDatasetDefinitionType]);
    const selectTable = useCallback(() => selectDatasetDefinitionType(DatasetType.TABLE), [selectDatasetDefinitionType]);

    const datasetTypeTiles: Tile[] = useMemo(() => [
        {
            label: 'Form',
            description: ['For capturing questionnaires or other question / response based data'],
            onClick: selectForm,
            testId: 'form',
            icon: Form
        },
        {
            label: 'Table',
            description: ['Manage all of your existing attestations'],
            onClick: selectTable,
            testId: 'table',
            icon: Table
        },
    ], [selectForm, selectTable]);

    return (
        <div className={styles.typeSelectorWrapper} data-testid='definition-type-selector-wrapper'>
            <div className={styles.typeSelectorTitle} data-testid='definition-type-selector-title'>Dataset Type</div>
            <div className={styles.typeSelectorSubtitle} data-testid='definition-type-selector-subtitle'>Select the type of dataset you would like to build</div>
            <div className={styles.tilesWrapper}>
                <TileSelect testId='dataset' tiles={datasetTypeTiles} />
            </div>
        </div>
    );
};
