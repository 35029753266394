import classnames from 'classnames';
import { isUndefined, noop, uniq } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { useSingleHiddenDocumentFields, useSingleHiddenFields } from '../../../../../hooks/useHiddenSingleFields';
import { OpenFieldType } from '../../../../datasets/instances/store';
import { GroupDatasetField } from '../../../../datasets/store';
import { PlusButton } from '../../../../shared/button/PlusButton';
import { RadioButton } from '../../../../shared/button/RadioButton';
import { Tick } from '../../../../shared/icons';
import { Toggle } from '../../../../shared/toggle';
import { OverflowTooltip } from '../../../../shared/tooltip';
import { SingleFieldPreview } from '../../../dataset-builder/preview/formPreview/SingleFieldPreview';
import { DocumentSpecificHiddenFields } from '../../../dataset-builder/store';
import { DocumentNameDB } from '../../../documents/store';
import styles from '../../MyDatasets.module.scss';
import { HiddenField, HiddenFields, toggleHiddenField } from '../../store';
import { getHiddenDocumentsTooltip } from '../MyDatasetsFormDefinition';

const { lightGrey } = styles;

interface MultiToggleFieldProps {
    field: GroupDatasetField;
    groupIndex: number;
    sectionId: string;
    datasetId: number;
    isPreview: boolean;
    hiddenFields: HiddenFields;
    datasetHidden?: boolean;
    documentSpecificHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    parentHiddenDocumentNameIds?: number[];
    parentDatasetId: number;
}

export const MultiToggleField: React.FC<MultiToggleFieldProps> = ({
    field,
    groupIndex,
    sectionId,
    datasetId,
    isPreview,
    hiddenFields,
    datasetHidden,
    documentSpecificHiddenFields,
    allDocumentNames,
    parentHiddenDocumentNameIds,
    parentDatasetId
}) => {
    const dispatch = useAppDispatch();
    const { children, label, id } = field;

    const getOptionWrapperStyle = useCallback((index: number) => {
        const totalEntries = children.length;
        const numberOfLastRowEntries = totalEntries % 3 || 3;
        const lastRowEntries = Array(numberOfLastRowEntries).fill(totalEntries - numberOfLastRowEntries).map((val, i) => val + i);
        const isLastRow = lastRowEntries.includes(index);
        const isFirstColumn = ((index + 1) % 3) === 1;
        if (!isLastRow) {
            return !isFirstColumn ? { width: 'calc((100% / 3) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}`, borderBottom: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)', borderBottom: `1px solid ${lightGrey}` };
        }
        return !isFirstColumn ? { width: 'calc((100% / 3) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)' };
    }, [children]);

    const hiddenField: HiddenField = useMemo(() => ({ fieldId: id!, type: OpenFieldType.FIELD, sectionId }), [id, sectionId]);
    const toggleFieldVisible = useCallback(() => dispatch(toggleHiddenField(datasetId, hiddenField)), [datasetId, hiddenField, dispatch]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const sectionIsHidden = useMemo(() => !!currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(field => field.sectionId === sectionId), [currentHiddenDatasetFields, sectionId]);

    const fieldHidden = useMemo(() => sectionIsHidden || datasetHidden || !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields, id, sectionIsHidden, datasetHidden]);

    const documentHiddenFields = useMemo(() => documentSpecificHiddenFields[datasetId] || [], [datasetId, documentSpecificHiddenFields]);
    const documentHiddenSingleFields = useSingleHiddenDocumentFields(documentHiddenFields);
    const documentsHiddenForSection = useMemo(() => documentHiddenFields.filter(({ type }) => type === OpenFieldType.SECTION).find(hiddenField => hiddenField.sectionId === sectionId)?.documentNameIds || [], [documentHiddenFields, sectionId]);
    const documentsHiddenForField = useMemo(() => documentHiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds || [], [documentHiddenSingleFields, id]);
    const parentHiddenDocuments = !isUndefined(parentHiddenDocumentNameIds) ? parentHiddenDocumentNameIds : [];
    const allDocumentsHiddenForField = uniq([...documentsHiddenForField, ...documentsHiddenForSection, ...parentHiddenDocuments]);
    const hiddenDocumentNames = useMemo(() => allDocumentNames.filter(document => document.datasetId === parentDatasetId).reduce((acc: string[], { documentNameId, documentName }) => documentNameId && allDocumentsHiddenForField.includes(documentNameId) ? [...acc, documentName.toLowerCase()] : acc, []), [allDocumentNames, allDocumentsHiddenForField, parentDatasetId]);
    const documentNameIdsForDataset = useMemo(() => allDocumentNames.filter(document => document.datasetId === parentDatasetId).map(({ documentNameId }) => documentNameId!), [allDocumentNames, parentDatasetId]);
    const fieldHiddenForAllDatasetDocuments = useMemo(() => documentsHiddenForField.length > 0 && documentNameIdsForDataset.every(documentNameId => documentsHiddenForField.includes(documentNameId)), [documentNameIdsForDataset, documentsHiddenForField]);
    const sectionHiddenForAllDatasetDocuments = useMemo(() => documentsHiddenForSection.length > 0 && documentNameIdsForDataset.every(documentNameId => documentsHiddenForSection.includes(documentNameId)), [documentNameIdsForDataset, documentsHiddenForSection]);

    if ((isPreview && fieldHidden) || fieldHiddenForAllDatasetDocuments || sectionHiddenForAllDatasetDocuments) {
        return null;
    }

    return (
        <div className={styles.multiToggleWrapper}>
            <div className={styles.multiToggleGroupLabelWrapper}>
                <div className={classnames(styles.multiToggleGroupLabel, { [styles.emptyFieldLabel]: !label })}>
                    <OverflowTooltip overlayText={label || 'Label...'} />
                </div>
                {!isPreview && <RadioButton fontSize={10} withBackground={!fieldHidden} disabled={sectionIsHidden || datasetHidden} onClick={toggleFieldVisible} icon={Tick} marginRight='5px' />}
                {hiddenDocumentNames.length > 0 && getHiddenDocumentsTooltip(hiddenDocumentNames, 'field')}
            </div>
            <div className={styles.multiToggleHeaderWrapper}>
                <div className={styles.selectAllWrapper}>
                    <div className={styles.selectAllLabel}>Select All</div>
                    <Toggle onChange={noop} checked={false} disabled />
                </div>
                <div className={styles.addOptionWrapper}>
                    <div className={styles.addOptionLabel}>Add another option</div>
                    <div className={styles.addOptionButton}><PlusButton onClick={noop} fontSize={16} disabled /></div>
                </div>
            </div>
            <div className={styles.multiToggleOptionsWrapper} data-testid={`form-multi-toggle-${groupIndex}-wrapper`}>
                {children.map((field, index) => {
                    const singleIndex = `${groupIndex}${index}`;
                    const optionWrapperStyle = getOptionWrapperStyle(index);
                    return (
                        <div className={styles.toggleOptionWrapper} key={field.id} style={optionWrapperStyle}>
                            <SingleFieldPreview
                                field={field}
                                singleIndex={singleIndex}
                                index={index}
                                groupIndex={groupIndex}
                                isMultiToggle
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
