import { isNull, isUndefined } from 'lodash/fp';
import { push } from 'redux-first-history';
import { all, call, fork, put, select, takeEvery, takeLeading, takeLatest } from 'redux-saga/effects';

import { fetchAllDatasetsRiskToleranceAnalytics, fetchAllDocumentsRiskToleranceAnalytics, fetchDatasetFieldRiskToleranceAnalytics, fetchDocumentRiskToleranceAnalytics, fetchDocumentSpreadAnalytics, fetchEntityDocumentsRiskToleranceAnalytics, fetchEntityJurisdictionAnalytics, fetchEntitySpreadAnalytics, fetchJurisdictionDocumentsForEntity, fetchPaginatedRiskToleranceDocuments, fetchPreExecutionDocumentsRiskToleranceAnalytics } from '../../../../services/analytics';
import { fetchAllGroupEntities, fetchAllMyCompanyEntities } from '../../../../services/entity';
import { openDocumentAndInstance } from '../../my-documents/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { BaseEntity, GroupEntity } from '../../../admin/entity/store';
import { RiskToleranceDataset } from '../../../admin/risk-tolerance/store';
import {
    fetchAllDatasetRiskToleranceFailed,
    fetchAllDatasetRiskToleranceSuccessful,
    fetchAllDocumentsRiskToleranceFailed,
    fetchAllDocumentsRiskToleranceSuccessful,
    fetchAllMyCompanyEntitiesFailed,
    fetchAllMyCompanyEntitiesSuccessful,
    fetchDatasetFieldRiskToleranceFailed,
    fetchDatasetFieldRiskToleranceStarted,
    fetchDatasetFieldRiskToleranceSuccessful,
    fetchDocumentRiskToleranceFailed,
    fetchDocumentRiskToleranceStarted,
    fetchDocumentRiskToleranceSuccessful,
    fetchDocumentSpreadFailed,
    fetchDocumentSpreadStarted,
    fetchDocumentSpreadSuccessful,
    fetchAllEntityDocumentsRiskToleranceStarted,
    fetchAllEntityDocumentsRiskToleranceSuccessful,
    fetchEntityJurisdictionFailed,
    fetchEntityJurisdictionStarted,
    fetchEntityJurisdictionSuccessful,
    fetchEntitySpreadFailed,
    fetchEntitySpreadStarted,
    fetchEntitySpreadSuccessful,
    fetchJurisdictionDocumentsFailed,
    fetchJurisdictionDocumentsStarted,
    fetchJurisdictionDocumentsSuccessful,
    fetchPaginatedDocumentsRiskToleranceFailed,
    fetchPaginatedDocumentsRiskToleranceStarted,
    fetchPaginatedDocumentsRiskToleranceSuccessful,
    redirectToDocumentInstance,
    selectJurisdictionDocumentsPage,
    setDocumentTileInView,
    setSelectedMyCompanyEntity,
    fetchAllEntityDocumentsRiskToleranceFailed,
    setRiskToleranceView,
    setEntityDocumentRiskToleranceView,
    fetchAllDatasetRiskToleranceStarted,
    fetchAllPreExecutionDocumentsRiskToleranceSuccessful,
    fetchAllPreExecutionDocumentsRiskToleranceFailed,
    setPreExecutionDocumentRiskToleranceView
} from './actions';
import { getRiskToleranceDatasetFieldColumnSort, getRiskToleranceDatasetFieldFilters, getRiskToleranceDatasetFieldPageNumber, getRiskToleranceDatasetFieldPageSize, getRiskToleranceDocumentsColumnSort, getRiskToleranceDocumentsFilters, getRiskToleranceDocumentsPageNumber, getRiskToleranceDocumentsPageSize, getSelectedMyCompanyEntity, getSelectedRiskToleranceChildDatasetId, getSelectedRiskToleranceDatasetFieldId, getSelectedRiskToleranceParentDatasetId, getTileInView } from './selectors';
import { AllDocumentsRiskTolerance, DocumentAnalyticsActionTypes, BaseArkDocument, PaginatedDatasetFieldRiskTolerance, DocumentRiskTolerance, EntityJurisdictionAnalytics, TableDocumentsRiskTolerance, DocumentAnalyticsChart, EntityDocumentRiskView, DocumentRiskView, PreExecutionDocumentRiskView } from './types';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

export function* attemptRedirectTileInView({ payload }: ReturnType<typeof setDocumentTileInView>) {
    const { tileInView, shouldRedirect } = payload;
    if (shouldRedirect) {
        const redirectUrl = isNull(tileInView) ? '/documents/analytics' : `/documents/analytics/${tileInView.replaceAll(' ', '-')}`;
        yield put(push(redirectUrl));
    }
}

function* setTileInViewWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.SET_DOCUMENT_ANALYTICS_TILE_IN_VIEW, attemptRedirectTileInView);
}

export function* attemptFetchDocumentSpread({ payload }: ReturnType<typeof fetchDocumentSpreadStarted>) {
    try {
        const { entityId } = payload;
        const analytics: PieChartAnalyticsData[] = yield call(fetchDocumentSpreadAnalytics, { entityId });
        yield put(fetchDocumentSpreadSuccessful(analytics));
    } catch (e) {
        yield put(fetchDocumentSpreadFailed((e as Error).message));
    }
}

function* fetchDocumentSpreadWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.FETCH_DOCUMENT_SPREAD_ANALYTICS_STARTED, attemptFetchDocumentSpread);
}

export function* attemptFetchEntitySpread({ payload }: ReturnType<typeof fetchEntitySpreadStarted>) {
    try {
        const { entityId } = payload;
        const analytics: PieChartAnalyticsData[] = yield call(fetchEntitySpreadAnalytics, { entityId });
        yield put(fetchEntitySpreadSuccessful(analytics));
    } catch (e) {
        yield put(fetchEntitySpreadFailed((e as Error).message));
    }
}

function* fetchEntitySpreadWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.FETCH_ENTITY_SPREAD_ANALYTICS_STARTED, attemptFetchEntitySpread);
}

export function* attemptFetchAvailableAnalyticsEntities() {
    try {
        const entities: BaseEntity[] = yield call(fetchAllMyCompanyEntities);
        const { groupEntities }: { groupEntities: GroupEntity[]; } = yield call(fetchAllGroupEntities);
        let entityOptions: DropdownOption[] = [];
        if (groupEntities.length > 0) {
            groupEntities.map(({ name, entityId }) => entityOptions.push({ label: name, value: entityId!.toString() }));
            entityOptions.push({ value: '', label: '', showDivider: true, disabled: true });
        }
        if (entities.length > 0) {
            entities.map(({ name, entityId }) => entityOptions.push({ label: name, value: entityId.toString() }));
        }
        yield put(fetchAllMyCompanyEntitiesSuccessful(entityOptions));
        const entityId = entities.length ? entities[0].entityId : null;
        yield put(setSelectedMyCompanyEntity(entityId));
    } catch (e) {
        yield put(fetchAllMyCompanyEntitiesFailed((e as Error).message));
    }
}

function* attemptFetchAvailableAnalyticsEntitiesWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.FETCH_ALL_MY_COMPANY_ENTITIES_STARTED, attemptFetchAvailableAnalyticsEntities);
}

export function* setSelectedCompanyWorker({ payload }: ReturnType<typeof setSelectedMyCompanyEntity>) {
    yield put(fetchEntitySpreadStarted(payload));
    yield put(fetchDocumentSpreadStarted(payload));
    const tileInView: DocumentAnalyticsChart = yield select(getTileInView);

    if (!isNull(payload)) {
        yield put(fetchAllEntityDocumentsRiskToleranceStarted(payload));
        yield put(fetchEntityJurisdictionStarted(payload));
        if (tileInView === DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE) {
            const entityId: number = yield select(getSelectedMyCompanyEntity);
            yield put(fetchPaginatedDocumentsRiskToleranceStarted(1, entityId));
            yield put(fetchAllDatasetRiskToleranceStarted(entityId));
        }
        if (tileInView === DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE) {
            yield put(fetchPaginatedDocumentsRiskToleranceStarted(1, undefined));
        }
    } else {
        yield put(fetchEntityJurisdictionSuccessful([]));
    }
}

function* setSelectedCompanyWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.SET_SELECTED_MY_COMPANY_ENTITY, setSelectedCompanyWorker);
}

export function* attemptFetchEntityJurisdiction({ payload }: ReturnType<typeof fetchEntityJurisdictionStarted>) {
    try {
        const { entityId } = payload;
        const analytics: EntityJurisdictionAnalytics[] = yield call(fetchEntityJurisdictionAnalytics, { entityId });
        yield put(fetchEntityJurisdictionSuccessful(analytics));
    } catch (e) {
        yield put(fetchEntityJurisdictionFailed((e as Error).message));
    }
}

function* fetchEntityJurisdictionWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.FETCH_ENTITY_JURISDICTION_ANALYTICS_STARTED, attemptFetchEntityJurisdiction);
}

export function* attemptFetchJurisdictionDocuments({ payload }: ReturnType<typeof fetchJurisdictionDocumentsStarted>) {
    try {
        const { pageNumber, entityId } = payload;
        const myEntityId: number = yield select(getSelectedMyCompanyEntity);
        const { documents, total }: { documents: BaseArkDocument[]; total: number; } = yield call(fetchJurisdictionDocumentsForEntity, { myEntityId, entityId, pageNumber });
        yield put(fetchJurisdictionDocumentsSuccessful(documents, total, pageNumber));
    } catch (e) {
        yield put(fetchJurisdictionDocumentsFailed((e as Error).message));
    }
}

function* fetchJurisdictionDocumentsWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.FETCH_JURISDICTION_DOCUMENTS_STARTED, attemptFetchJurisdictionDocuments);
}

export function* jurisdictionDocumentsSelectPage({ payload }: ReturnType<typeof selectJurisdictionDocumentsPage>) {
    const { pageNumber, entityId } = payload;
    yield put(fetchJurisdictionDocumentsStarted(entityId, pageNumber + 1));
}

function* jurisdictionDocumentsSelectPageWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.JURISDICTION_DOCUMENTS_SELECT_PAGE, jurisdictionDocumentsSelectPage);
}

export function* attemptFetchDocumentRiskTolerance({ payload }: ReturnType<typeof fetchDocumentRiskToleranceStarted>) {
    try {
        const { documentId, isPreExecution } = payload;
        const documentRiskTolerance: DocumentRiskTolerance | undefined = yield call(fetchDocumentRiskToleranceAnalytics, { documentId, isPreExecution });
        yield put(fetchDocumentRiskToleranceSuccessful(documentRiskTolerance || null));
    } catch (e) {
        yield put(fetchDocumentRiskToleranceFailed((e as Error).message));
    }
}

function* fetchDocumentRiskToleranceWatcher() {
    yield takeLeading(DocumentAnalyticsActionTypes.FETCH_DOCUMENT_RISK_TOLERANCE_STARTED, attemptFetchDocumentRiskTolerance);
}

export function* attemptFetchAllDocumentsRiskTolerance() {
    try {
        const allDocumentsRiskTolerance: AllDocumentsRiskTolerance = yield call(fetchAllDocumentsRiskToleranceAnalytics);
        yield put(fetchAllDocumentsRiskToleranceSuccessful(allDocumentsRiskTolerance));
    } catch (e) {
        yield put(fetchAllDocumentsRiskToleranceFailed((e as Error).message));
    }
}

function* fetchAllDocumentsRiskToleranceWatcher() {
    yield takeLeading(DocumentAnalyticsActionTypes.FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_STARTED, attemptFetchAllDocumentsRiskTolerance);
}

export function* attemptFetchAllEntityDocumentsRiskTolerance({ payload }: ReturnType<typeof fetchAllEntityDocumentsRiskToleranceStarted>) {
    try {
        const allEntityDocumentsRiskTolerance: AllDocumentsRiskTolerance = yield call(fetchEntityDocumentsRiskToleranceAnalytics, { entityId: payload });
        yield put(fetchAllEntityDocumentsRiskToleranceSuccessful(allEntityDocumentsRiskTolerance));
    } catch (e) {
        yield put(fetchAllEntityDocumentsRiskToleranceFailed((e as Error).message));
    }
}

function* fetchAllEntityDocumentsRiskToleranceWatcher() {
    yield takeLeading(DocumentAnalyticsActionTypes.FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_STARTED, attemptFetchAllEntityDocumentsRiskTolerance);
}

export function* attemptFetchAllPreExecutionDocumentsRiskTolerance() {
    try {
        const preExecutionDocumentsRiskTolerance: AllDocumentsRiskTolerance = yield call(fetchPreExecutionDocumentsRiskToleranceAnalytics);
        yield put(fetchAllPreExecutionDocumentsRiskToleranceSuccessful(preExecutionDocumentsRiskTolerance));
    } catch (e) {
        yield put(fetchAllPreExecutionDocumentsRiskToleranceFailed((e as Error).message));
    }
}

function* fetchAllPreExecutionDocumentsRiskToleranceWatcher() {
    yield takeLeading(DocumentAnalyticsActionTypes.FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_STARTED, attemptFetchAllPreExecutionDocumentsRiskTolerance);
}

export function* attemptFetchAllDatasetsRiskTolerance({ payload }: ReturnType<typeof fetchAllDatasetRiskToleranceStarted>) {
    try {
        const { entityId, isPreExecution } = payload;
        const riskToleranceDatasets: RiskToleranceDataset[] = yield call(fetchAllDatasetsRiskToleranceAnalytics, { entityId, isPreExecution });
        yield put(fetchAllDatasetRiskToleranceSuccessful(riskToleranceDatasets));
    } catch (e) {
        yield put(fetchAllDatasetRiskToleranceFailed((e as Error).message));
    }
}

function* fetchAllRiskToleranceWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.FETCH_ALL_DATASETS_RISK_TOLERANCE_STARTED, attemptFetchAllDatasetsRiskTolerance);
}

export function* attemptRedirectToDocumentInstance({ payload }: ReturnType<typeof redirectToDocumentInstance>) {
    const { originalDocumentId, datasetInstanceId } = payload;
    yield put(openDocumentAndInstance(originalDocumentId.toString(), datasetInstanceId.toString()));
    yield put(push(`/documents/analysis/${originalDocumentId}/${datasetInstanceId}`));
}

function* goToDocumentInstance() {
    yield takeLeading(DocumentAnalyticsActionTypes.REDIRECT_TO_DOCUMENT_INSTANCE, attemptRedirectToDocumentInstance);
}

export function* attemptFetchPaginatedDatasetFieldRiskTolerance({ payload }: ReturnType<typeof fetchDatasetFieldRiskToleranceStarted>) {
    try {
        const { pageNumber, entityId, isPreExecution } = payload;
        const parentDatasetId: number | null = yield select(getSelectedRiskToleranceParentDatasetId);
        const childDatasetId: number | null = yield select(getSelectedRiskToleranceChildDatasetId);
        const fieldId: string | null = yield select(getSelectedRiskToleranceDatasetFieldId);
        const pageSize: number = yield select(getRiskToleranceDatasetFieldPageSize);
        const filters: TableFilters = yield select(getRiskToleranceDatasetFieldFilters);
        const columnSort: ColumnSort | undefined = yield select(getRiskToleranceDatasetFieldColumnSort);
        const { documents, total, overallPercentage }: PaginatedDatasetFieldRiskTolerance = yield call(fetchDatasetFieldRiskToleranceAnalytics, { parentDatasetId: parentDatasetId!, childDatasetId: childDatasetId!, fieldId: fieldId!, pageNumber, pageSize, filters, columnSort, entityId, isPreExecution });
        yield put(fetchDatasetFieldRiskToleranceSuccessful(documents, total, overallPercentage, pageNumber));
        if (!isUndefined(entityId)) {
            yield put(setEntityDocumentRiskToleranceView(EntityDocumentRiskView.ENTITY_DATASET_FIELD));
        } else if (isPreExecution) {
            yield put(setPreExecutionDocumentRiskToleranceView(PreExecutionDocumentRiskView.PRE_EXECUTION_DATASET_FIELD));
        } else {
            yield put(setRiskToleranceView(DocumentRiskView.DATASET_FIELD));
        }

    } catch (e) {
        yield put(fetchDatasetFieldRiskToleranceFailed((e as Error).message));
    }
}

function* fetchPagindatedDatasetFieldRiskToleranceWatcher() {
    yield takeLeading(DocumentAnalyticsActionTypes.FETCH_DATASET_FIELD_RISK_TOLERANCE_STARTED, attemptFetchPaginatedDatasetFieldRiskTolerance);
}

export function* attemptFetchPaginatedDocumentsRiskTolerance({ payload }: ReturnType<typeof fetchPaginatedDocumentsRiskToleranceStarted>) {
    try {
        const { pageNumber, entityId, isPreExecution } = payload;
        const pageSize: number = yield select(getRiskToleranceDocumentsPageSize);
        const filters: TableFilters = yield select(getRiskToleranceDocumentsFilters);
        const columnSort: ColumnSort | undefined = yield select(getRiskToleranceDocumentsColumnSort);
        const { documents, total }: TableDocumentsRiskTolerance = yield call(fetchPaginatedRiskToleranceDocuments, { pageNumber, pageSize, filters, columnSort, entityId, isPreExecution });
        yield put(fetchPaginatedDocumentsRiskToleranceSuccessful(documents, total, pageNumber));
    } catch (e) {
        yield put(fetchPaginatedDocumentsRiskToleranceFailed((e as Error).message));
    }
}

function* fetchPaginatedDocumentsRiskToleranceWatcher() {
    yield takeLatest(DocumentAnalyticsActionTypes.FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_STARTED, attemptFetchPaginatedDocumentsRiskTolerance);
}

// Document Table Pagination Sagas

export function* documentRiskTolerancePaginationNext() {
    const pageNumber: number = yield select(getRiskToleranceDocumentsPageNumber);
    const tileInView: DocumentAnalyticsChart = yield select(getTileInView);
    const companyEntityId: number | null = yield select(getSelectedMyCompanyEntity);
    const isPreExecution = tileInView === DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE;
    const isEntityRiskToleranceView = tileInView === DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE;
    const entityId = !isNull(companyEntityId) && isEntityRiskToleranceView ? companyEntityId : undefined;
    yield put(fetchPaginatedDocumentsRiskToleranceStarted(pageNumber + 1, entityId, isPreExecution));
}

function* documentRiskTolerancePaginationNextWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.DOCUMENT_RISK_TOLERANCE_PAGINATION_NEXT, documentRiskTolerancePaginationNext);
}

export function* documentRiskTolerancePaginationPrevious() {
    const pageNumber: number = yield select(getRiskToleranceDocumentsPageNumber);
    const tileInView: DocumentAnalyticsChart = yield select(getTileInView);
    const companyEntityId: number | null = yield select(getSelectedMyCompanyEntity);
    const isPreExecution = tileInView === DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE;
    const isEntityRiskToleranceView = tileInView === DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE;
    const entityId = !isNull(companyEntityId) && isEntityRiskToleranceView ? companyEntityId : undefined;
    yield put(fetchPaginatedDocumentsRiskToleranceStarted(pageNumber - 1, entityId, isPreExecution));
}

function* documentRiskTolerancePaginationPreviousWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.DOCUMENT_RISK_TOLERANCE_PAGINATION_PREVIOUS, documentRiskTolerancePaginationPrevious);
}

export function* documentRiskToleranceTableConfigUpdated() {
    const tileInView: DocumentAnalyticsChart = yield select(getTileInView);
    const companyEntityId: number | null = yield select(getSelectedMyCompanyEntity);
    const isPreExecution = tileInView === DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE;
    const isEntityRiskToleranceView = tileInView === DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE;
    const entityId = !isNull(companyEntityId) && isEntityRiskToleranceView ? companyEntityId : undefined;
    yield put(fetchPaginatedDocumentsRiskToleranceStarted(1, entityId, isPreExecution));
}

function* documentRiskToleranceTableConfigUpdatedWatcher() {
    yield takeEvery([DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS, DocumentAnalyticsActionTypes.CLEAR_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS, DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_TABLE_COLUMN_SORT, DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_PAGE_SIZE], documentRiskToleranceTableConfigUpdated);
}

// Dataset Field Table Pagination Sagas

export function* datasetFieldRiskTolerancePaginationNext() {
    const pageNumber: number = yield select(getRiskToleranceDatasetFieldPageNumber);
    const tileInView: DocumentAnalyticsChart = yield select(getTileInView);
    const companyEntityId: number | null = yield select(getSelectedMyCompanyEntity);
    const isPreExecution = tileInView === DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE;
    const isEntityRiskToleranceView = tileInView === DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE;
    const entityId = !isNull(companyEntityId) && isEntityRiskToleranceView ? companyEntityId : undefined;
    yield put(fetchDatasetFieldRiskToleranceStarted(pageNumber + 1, entityId, isPreExecution));
}

function* datasetFieldRiskTolerancePaginationNextWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.DATASET_FIELD_RISK_TOLERANCE_PAGINATION_NEXT, datasetFieldRiskTolerancePaginationNext);
}

export function* datasetFieldRiskTolerancePaginationPrevious() {
    const pageNumber: number = yield select(getRiskToleranceDatasetFieldPageNumber);
    const tileInView: DocumentAnalyticsChart = yield select(getTileInView);
    const companyEntityId: number | null = yield select(getSelectedMyCompanyEntity);
    const isPreExecution = tileInView === DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE;
    const isEntityRiskToleranceView = tileInView === DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE;
    const entityId = !isNull(companyEntityId) && isEntityRiskToleranceView ? companyEntityId : undefined;
    yield put(fetchDatasetFieldRiskToleranceStarted(pageNumber - 1, entityId, isPreExecution));
}

function* datasetFieldRiskTolerancePaginationPreviousWatcher() {
    yield takeEvery(DocumentAnalyticsActionTypes.DATASET_FIELD_RISK_TOLERANCE_PAGINATION_PREVIOUS, datasetFieldRiskTolerancePaginationPrevious);
}

export function* datasetFieldRiskToleranceTableConfigUpdated() {
    const tileInView: DocumentAnalyticsChart = yield select(getTileInView);
    const companyEntityId: number | null = yield select(getSelectedMyCompanyEntity);
    const isPreExecution = tileInView === DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE;
    const isEntityRiskToleranceView = tileInView === DocumentAnalyticsChart.ENTITY_DOCUMENT_RISK_TOLERANCE;
    const entityId = !isNull(companyEntityId) && isEntityRiskToleranceView ? companyEntityId : undefined;
    yield put(fetchDatasetFieldRiskToleranceStarted(1, entityId, isPreExecution));
}

function* datasetFieldRiskToleranceTableConfigUpdatedWatcher() {
    yield takeEvery([DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS, DocumentAnalyticsActionTypes.CLEAR_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS, DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_COLUMN_SORT, DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_PAGE_SIZE], datasetFieldRiskToleranceTableConfigUpdated);
}

export function* documentAnalyticsSaga() {
    yield all([
        fork(setTileInViewWatcher),
        fork(fetchDocumentSpreadWatcher),
        fork(fetchEntitySpreadWatcher),
        fork(fetchEntityJurisdictionWatcher),
        fork(fetchJurisdictionDocumentsWatcher),
        fork(jurisdictionDocumentsSelectPageWatcher),
        fork(attemptFetchAvailableAnalyticsEntitiesWatcher),
        fork(setSelectedCompanyWatcher),
        fork(fetchDocumentRiskToleranceWatcher),
        fork(fetchAllDocumentsRiskToleranceWatcher),
        fork(fetchPaginatedDocumentsRiskToleranceWatcher),
        fork(documentRiskTolerancePaginationNextWatcher),
        fork(documentRiskTolerancePaginationPreviousWatcher),
        fork(documentRiskToleranceTableConfigUpdatedWatcher),
        fork(fetchAllRiskToleranceWatcher),
        fork(fetchPagindatedDatasetFieldRiskToleranceWatcher),
        fork(datasetFieldRiskTolerancePaginationNextWatcher),
        fork(datasetFieldRiskTolerancePaginationPreviousWatcher),
        fork(datasetFieldRiskToleranceTableConfigUpdatedWatcher),
        fork(goToDocumentInstance),
        fork(fetchAllEntityDocumentsRiskToleranceWatcher),
        fork(fetchAllPreExecutionDocumentsRiskToleranceWatcher)
    ]);
}
