import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Button } from '../../shared/button/Button';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { DocumentTemplate } from './DocumentTemplate';
import styles from './Reports.module.scss';
import { DocumentReportTemplate, ReportPage, deleteReportTemplateStarted, fetchAvailableFieldsStarted, getAllReportTemplates, getConfirmDeleteModalOpen, getConfirmDeleteReportTemplate, getIsDeleting, openDocumentReportTemplate, setReportPage, setReportTemplateToDelete } from './store';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasFeaturePermission } from '../../auth/login/store';

const NAV_BAR = 100;
const BACK_BUTTON = 48;
const TITLE = 35;
const MARGIN = 40;
const HEIGHT_OFFSET = NAV_BAR + BACK_BUTTON + TITLE + MARGIN;

export const DocumentReportTemplates: React.FC = () => {
    const testId = 'document-reporting-templates';
    const [, screenHeight] = useWindowResize();
    const templatesWrapperHeight = useMemo(() => screenHeight - HEIGHT_OFFSET, [screenHeight]);

    const reportTemplateToDelete = useAppSelector(getConfirmDeleteReportTemplate);
    const deleteConfirmationModalOpen = useAppSelector(getConfirmDeleteModalOpen);
    const templates = useAppSelector(getAllReportTemplates);
    const dispatch = useAppDispatch();
    const goBack = () => dispatch(setReportPage(ReportPage.SELECT));
    const hasEditDocumentReportPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.EDIT_DOCUMENT_REPORT_TEMPLATES]));

    const openDocumentTemplate = useCallback((reportTemplate: DocumentReportTemplate) => {
        const { filters, reportFields } = reportTemplate;
        dispatch(openDocumentReportTemplate(reportTemplate));
        dispatch(fetchAvailableFieldsStarted(filters.documentNameId![0].toString(), reportFields));
    }, [dispatch]);

    const deleteTemplate = useCallback(() => !isNull(reportTemplateToDelete) && dispatch(deleteReportTemplateStarted(reportTemplateToDelete)), [dispatch, reportTemplateToDelete]);
    const openDeleteConfirmationModal = useCallback((id: number) => dispatch(setReportTemplateToDelete(id)), [dispatch]);

    const reportTemplateName = useMemo(() => {
        let name = 'Unknown report template';
        if (!isNull(reportTemplateToDelete)) {
            const template = templates.find(({ reportTemplateId }) => reportTemplateId === reportTemplateToDelete);
            if (template) {
                name = template.name;
            }
        }
        return name;
    }, [reportTemplateToDelete, templates]);

    const isDeleting = useAppSelector(getIsDeleting);
    const closeDeleteConfirmationModal = useCallback(() => dispatch(setReportTemplateToDelete(null)), [dispatch]);

    const templatesContent = useMemo(() => {
        if (!templates.length) {
            return <div className={styles.noTemplates}>You do not have any Custom Document Templates. You can add custom templates by selecting the "Create a New Report" option.</div>;
        }
        return templates.map(template => (
            <DocumentTemplate
                key={template.reportTemplateId}
                template={template}
                openTemplate={() => openDocumentTemplate(template)}
                openDeleteConfirmationModal={() => openDeleteConfirmationModal(template.reportTemplateId)}
                testId='document-reporting'
                canMakeTemplateChanges={hasEditDocumentReportPermission}
            />
        ));
    }, [openDocumentTemplate, openDeleteConfirmationModal, templates, hasEditDocumentReportPermission]);

    return (
        <div className={styles.reportTemplatesWrapper}>
            <div className={styles.reportsHeader}>
                <div className={styles.templatesTitle} data-testid={`${testId}-header`}>Custom Document Report Templates</div>
            </div>
            <div className={styles.templatesWrapper} data-testid={`${testId}-wrapper`}>
                <div className={styles.allTemplatesWrapper}>
                    <Scrollable maxHeight={`${templatesWrapperHeight}px`} height={`${templatesWrapperHeight}px`}>
                        {templatesContent}
                    </Scrollable>
                </div>
                <div className={styles.templatesButtonWrapper}>
                    <Button onClick={goBack} label='Back' testId={`${testId}-back`} />
                </div>
                {deleteConfirmationModalOpen &&
                    <DeleteConfirmationModal
                        closeModal={closeDeleteConfirmationModal}
                        deleteReportTemplate={deleteTemplate}
                        isDeleting={isDeleting}
                        reportTemplateName={reportTemplateName}
                    />}
            </div>
        </div>
    );
};
