import React, { useMemo } from 'react';

import { Toggle as CustomToggle } from '../../../shared/toggle';
import styles from './Fields.module.scss';

interface ToggleProps {
    id: string;
    value: boolean;
    showFieldUpdated?: boolean;
    disabled?: boolean;
    centreField?: boolean;
    updateValue: (value: boolean) => void;
}

const { grey, amethyst } = styles;

export const Toggle: React.FC<ToggleProps> = ({
    id,
    disabled = false,
    value,
    updateValue,
    showFieldUpdated = false,
    centreField
}) => {

    const boxShadowColour = useMemo(() => showFieldUpdated ? amethyst : grey, [showFieldUpdated]);

    const onColour = useMemo(() => {
        if (showFieldUpdated) {
            return amethyst;
        }
    }, [showFieldUpdated]);

    const wrapperStyle = useMemo(() => centreField ? { alignSelf: 'center' } : {}, [centreField]);

    return (
        <div style={wrapperStyle} className={styles.toggleFieldWrapper}>
            <CustomToggle
                checked={value}
                disabled={disabled}
                onChange={updateValue}
                testId={`definition-checkbox-${id}`}
                boxShadowColour={boxShadowColour}
                onColour={onColour}
            />
        </div>
    );
};
