export enum DocumentNameActionTypes {
    TOGGLE_DOCUMENT_NAME_MODAL = 'TOGGLE_DOCUMENT_NAME_MODAL',
    UPDATE_VALUE = 'UPDATE_VALUE',
    UPDATE_DOCUMENT_NAME_STARTED = 'UPDATE_DOCUMENT_NAME_STARTED',
    UPDATE_DOCUMENT_NAME_SUCCESSFUL = 'UPDATE_DOCUMENT_NAME_SUCCESSFUL',
    UPDATE_DOCUMENT_NAME_FAILED = 'UPDATE_DOCUMENT_NAME_FAILED',
    FETCH_ALL_DOCUMENT_NAMES_STARTED = 'FETCH_ALL_DOCUMENT_NAMES_STARTED',
    FETCH_ALL_DOCUMENT_NAMES_SUCCESSFUL = 'FETCH_ALL_DOCUMENT_NAMES_SUCCESSFUL',
    FETCH_ALL_DOCUMENT_NAMES_FAILED = 'FETCH_ALL_DOCUMENT_NAMES_FAILED',
    FETCH_ALL_AGREEMENT_TYPES_STARTED = 'FETCH_ALL_AGREEMENT_TYPES_STARTED',
    FETCH_ALL_AGREEMENT_TYPES_SUCCESSFUL = 'FETCH_ALL_AGREEMENT_TYPES_SUCCESSFUL',
    FETCH_ALL_AGREEMENT_TYPES_FAILED = 'FETCH_ALL_AGREEMENT_TYPES_FAILED',
    FETCH_AVAILABLE_DOCUMENT_NAMES_STARTED = 'FETCH_AVAILABLE_DOCUMENT_NAMES_STARTED',
    FETCH_AVAILABLE_DOCUMENT_NAMES_SUCCESSFUL = 'FETCH_AVAILABLE_DOCUMENT_NAMES_SUCCESSFUL',
    FETCH_AVAILABLE_DOCUMENT_NAMES_FAILED = 'FETCH_AVAILABLE_DOCUMENT_NAMES_FAILED',
    TOGGLE_CLIENT_DATASET_CONFIRMATION_MODAL_OPEN = 'TOGGLE_CLIENT_DATASET_CONFIRMATION_MODAL_OPEN',
    TOGGLE_CREATE_DOCUMENT_GROUP_MODAL_OPEN = 'TOGGLE_CREATE_DOCUMENT_GROUP_MODAL_OPEN',
    FETCH_ALL_DOCUMENT_GROUPS_STARTED = 'FETCH_ALL_DOCUMENT_GROUPS_STARTED',
    FETCH_ALL_DOCUMENT_GROUPS_SUCCESSFUL = 'FETCH_ALL_DOCUMENT_GROUPS_SUCCESSFUL',
    FETCH_ALL_DOCUMENT_GROUPS_FAILED = 'FETCH_ALL_DOCUMENT_GROUPS_FAILED',
    UPDATE_CLIENT_DOCUMENT_GROUP = 'UPDATE_CLIENT_DOCUMENT_GROUP',
    SAVE_CLIENT_DOCUMENT_GROUP_STARTED = 'SAVE_CLIENT_DOCUMENT_GROUP_STARTED',
    SAVE_CLIENT_DOCUMENT_GROUP_SUCCESSFUL = 'SAVE_CLIENT_DOCUMENT_GROUP_SUCCESSFUL',
    SAVE_CLIENT_DOCUMENT_GROUP_FAILED = 'SAVE_CLIENT_DOCUMENT_GROUP_FAILED',
    DELETE_CLIENT_DOCUMENT_GROUP_STARTED = 'DELETE_CLIENT_DOCUMENT_GROUP_STARTED',
    DELETE_CLIENT_DOCUMENT_GROUP_SUCCESSFUL = 'DELETE_CLIENT_DOCUMENT_GROUP_SUCCESSFUL',
    DELETE_CLIENT_DOCUMENT_GROUP_FAILED = 'DELETE_CLIENT_DOCUMENT_GROUP_FAILED',
    TOGGLE_AGREEMENT_TYPE_MODAL_OPEN = 'TOGGLE_AGREEMENT_TYPE_MODAL_OPEN',
    UPDATE_AGREEMENT_TYPE_NAME = 'UPDATE_AGREEMENT_TYPE_NAME',
    ADD_NEW_AGREEMENT_TYPE = 'ADD_NEW_AGREEMENT_TYPE',
    REMOVE_NEW_AGREEMENT_TYPE = 'REMOVE_NEW_AGREEMENT_TYPE',
    SET_AGREEMENT_TYPES_HAVE_UPDATED = 'SET_AGREEMENT_TYPES_HAVE_UPDATED',
    UPDATE_AGREEMENT_TYPES_STARTED = 'UPDATE_AGREEMENT_TYPES_STARTED',
    UPDATE_AGREEMENT_TYPES_SUCCESSFUL = 'UPDATE_AGREEMENT_TYPES_SUCCESSFUL',
    UPDATE_AGREEMENT_TYPES_FAILED = 'UPDATE_AGREEMENT_TYPES_FAILED',
    FETCH_ML_READY_AGREEMENT_TYPES_STARTED = 'FETCH_ML_READY_AGREEMENT_TYPES_STARTED',
    FETCH_ML_READY_AGREEMENT_TYPES_SUCCESSFUL = 'FETCH_ML_READY_AGREEMENT_TYPES_SUCCESSFUL',
    FETCH_ML_READY_AGREEMENT_TYPES_FAILED = 'FETCH_ML_READY_AGREEMENT_TYPES_FAILED'
}

export enum AgreementType {
    ISDA = 1,
    CSA = 2,
    IM_CSA = 3,
    GMRA = 4,
    MRA = 18,
    GMSLA = 6,
    MSLA = 20,
    DISTRIBUTION_AGREEMENT = 8,
    FUND_SIDE_LETTER = 9,
    CONFIDENTIALITY_AGREEMENT = 10,
    INVESTMENT_MANAGEMENT = 11,
    ACCOUNT_CONTROL_AGREEMENT = 12,
    LOAN_AGREEMENT = 13,
    CLEARED_DERIVATIVES_EXECUTION_AGREEMENT = 14,
    RAF_REVIEW = 15,
    ICT_CONTRACT = 16,
    PRIME_BROKERAGE_AGREEMENT = 17,
    MSFTA = 19,
    BOILERPLATE = 21,
    MCA = 22
}

export enum CDMDocumentNameId {
    ISDA_1987_AGREEMENT = 1,
    ISDA_1992_AGREEMENT = 2,
    ISDA_2002_AGREEMENT = 3,
    NY_LAW_VM_CSA = 6,
    ENGLISH_LAW_VM_CSA = 7,
    NY_2016_IM_CSA = 8,
    ENGLISH_2016_IM_CSD = 9,
    NY_2018_IM_CSA = 10,
    ENGLISH_2018_IM_CSD = 11,
    GMRA_1995 = 17,
    GMRA_2000 = 18,
    GMRA_2011 = 19,
    GMSLA_2000 = 21,
    GMSLA_2010 = 22
}

export interface DocumentNameDB {
    documentNameId?: number;
    documentName: string;
    datasetId: number | null;
    datasetTitle: string | null;
    agreementType: string | null;
    agreementTypeId: number | null;
    agencyDatasetId: number | null;
    clientIds?: number[];
}

export interface ClientDocumentGroup {
    clientDocumentGroupId: number | null;
    groupName: string;
    description: string;
    documentNameIds: number[];
}

export interface AgreementTypeDB {
    agreementTypeId: number;
    name: string;
}

export interface NewAgreementType {
    agreementTypeId: null;
    name: string;
}

export type CurrentAgreementType = AgreementTypeDB | NewAgreementType;
export interface DocumentNameState {
    modalOpen: boolean;
    documents: DocumentNameDB[];
    savedAgreementTypes: AgreementTypeDB[];
    currentAgreementTypes: CurrentAgreementType[];
    availableDocumentNames: DocumentNameDB[];
    openDocument: DocumentNameDB | null;
    clientDatasetConfirmationModalOpen: boolean;
    createDocumentGroupModalOpen: boolean;
    allDocumentGroups: ClientDocumentGroup[];
    isLoadingGroups: boolean;
    currentClientDocumentGroup: ClientDocumentGroup | null;
    savedClientDocumentGroup: ClientDocumentGroup | null;
    isSaving: boolean;
    agreementTypeModalOpen: boolean;
    agreementTypesHaveUpdated: boolean;
    isUpdatingAgreementTypes: boolean;
    mlReadyAgreementTypeIds: number[];
}
