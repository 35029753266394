import React from 'react';

import { PlaybookSubheader } from '../../../admin/playbook/store';
import { IconButton } from '../../button/IconButton';
import { Bin } from '../../icons';
import { Text } from '../../text/Text';
import styles from './PlaybookBuild.module.scss';

const { primary, lightGrey } = styles;

interface SubheaderProps {
    subheader: PlaybookSubheader;
    updateSubheader: (sectionId: string, value: string) => void;
    removeSection: (sectionId: string) => void;
    testId?: string;
}

export const Subheader: React.FC<SubheaderProps> = ({ subheader: { sectionId, title }, updateSubheader, removeSection, testId = 'playbook' }) => (
    <div className={styles.buildSectionWrapper} style={{ border: `1px solid ${primary}` }}>
        <div className={styles.buildSubheaderWrapper} data-testid={`${testId}-subheader-wrapper`} >
            <Text
                onChange={e => updateSubheader(sectionId, e.target.value)}
                value={title}
                maxLength={256}
                testId={`${testId}-subheader`}
                placeholder='Subheader'
                marginBottom='0px'
                borderColour={lightGrey}
                width='95%'
            />
            <div className={styles.buildSectionDeleteIcon}>
                <IconButton onClick={() => removeSection(sectionId)} testId={`${testId}-subheader-delete`} icon={Bin} fontSize={20} />
            </div>
        </div>
    </div>
);
