import { isNull, isUndefined, uniq } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Options } from 'react-select';

import { useAppSelector } from '../../../hooks/react-redux';
import { getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';
import { allInputs, availableOutputsByFieldType, availableInputsByFieldType, CalculatorField, CalculatorInput, CalculatorInputType, CalculatorOption, CalculatorOptionType, CalculatorFieldType, additionalConditionsAvailable, CalculationType, availableForGracePeriod, priorityInputFields, ifElseAvailable, freeTextOption } from './constants';
import styles from './Calculator.module.scss';
import { CustomTooltip } from '../tooltip';
import { Scrollable } from '../scrollable/Scrollable';
import { Tick } from '../icons';
import { Icon } from '../icon/Icon';
import { Number } from '../number/Number';

const { white, grey } = styles;

interface CalculatorInputsProps {
    fieldType: CalculatorFieldType;
    selectedCalculation: CalculatorField;
    selectedBuilderIndex: number | null;
    selectedCalculationBuilder: CalculationType;
    addOption: (value: CalculatorInput) => void;
    addNewCalculation: () => void;
    addNewBuilder: () => void;
    getFontSize: (type: CalculatorInputType, inputType: CalculatorOptionType, isInput: boolean) => string;
    disabled: boolean;
    width: number;
    maxHeight: number;
    showOutputField: boolean;
    includeFreeTextOption: boolean;
}

export const CalculatorInputs: React.FC<CalculatorInputsProps> = ({
    fieldType,
    selectedCalculation,
    selectedBuilderIndex,
    selectedCalculationBuilder,
    addOption,
    addNewCalculation,
    addNewBuilder,
    getFontSize,
    disabled,
    width,
    maxHeight,
    showOutputField,
    includeFreeTextOption
}) => {
    const [numberInput, setNumberInput] = useState<number | undefined>(undefined);
    const [decimalInput, setDecimalInput] = useState<number | undefined>(undefined);
    const dropdownLists = useAppSelector(getAllDropdownLists);
    const availableInputsList = useMemo(() => {
        if (isNull(selectedBuilderIndex)) {
            return selectedCalculationBuilder === CalculationType.OUTPUT ? availableOutputsByFieldType[fieldType] : availableForGracePeriod;
        } else {
            return availableInputsByFieldType[fieldType];
        }
    }, [selectedBuilderIndex, fieldType, selectedCalculationBuilder]);

    const independentAmountsInputFields = useCallback((availableInputFields: CalculatorOption[]) => [...availableInputFields.filter(({ inputType }) => priorityInputFields.includes(inputType)), ...availableInputFields.filter(({ inputType }) => !priorityInputFields.includes(inputType))], []);

    const availableInputFields = useMemo(() => {
        const availableInputFields = allInputs.filter(({ inputType }) => availableInputsList.includes(inputType));
        if (fieldType === CalculatorFieldType.INDEPENDENT_AMOUNTS) {
            return independentAmountsInputFields(availableInputFields);
        }
        return availableInputFields;
    }, [availableInputsList, fieldType, independentAmountsInputFields]);

    const availableInputs = useMemo(() => {
        if (!includeFreeTextOption) {
            return availableInputFields;
        }
        return uniq([...availableInputFields, ...freeTextOption]);
    }, [availableInputFields, includeFreeTextOption]);

    const selectedBuilder = useMemo(() => selectedCalculation.calculation.find(({ index }) => index === selectedBuilderIndex)?.builder || [], [selectedCalculation, selectedBuilderIndex]);

    const getDropdownOptions = useCallback((dropdownName: string) => {
        if (dropdownName === 'LTST') {
            return [{ value: 'LT', label: 'LT' }, { value: 'ST', label: 'ST' }];
        }

        if (dropdownName === 'AffectedParty') {
            return [{ value: 'Party A', label: 'Party A' }, { value: 'Party B', label: 'Party B' }, { value: 'Both Parties', label: 'Both Parties' }];
        }

        const list = dropdownLists.find(({ name }) => name === dropdownName)?.options || [];
        return list.map(value => ({ value, label: value }));
    }, [dropdownLists]);

    const formatNumber = useCallback((number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), []);

    const addNumberOption = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter' && !isUndefined(numberInput)) {
            addOption({ value: formatNumber(numberInput), label: 'Number', type: CalculatorInputType.NUMBER });
            setNumberInput(undefined);
        }
    }, [addOption, numberInput, setNumberInput, formatNumber]);

    const addDecimalOption = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter' && !isUndefined(decimalInput)) {
            addOption({ value: formatNumber(decimalInput), label: 'Decimal', type: CalculatorInputType.DECIMAL });
            setDecimalInput(undefined);
        }
    }, [addOption, decimalInput, setDecimalInput, formatNumber]);

    const addNumberOptionClick = useCallback(() => {
        if (!isUndefined(numberInput)) {
            addOption({ value: formatNumber(numberInput), label: 'Number', type: CalculatorInputType.NUMBER });
            setNumberInput(undefined);
        }
    }, [addOption, numberInput, setNumberInput, formatNumber]);

    const addDecimalOptionClick = useCallback(() => {
        if (!isUndefined(decimalInput)) {
            addOption({ value: formatNumber(decimalInput), label: 'Decimal', type: CalculatorInputType.DECIMAL });
            setDecimalInput(undefined);
        }
    }, [addOption, decimalInput, setDecimalInput, formatNumber]);

    const addDropdownOption = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null, label: string, type: CalculatorInputType) => {
        if (!isNull(dropdownValue)) {
            let value = (dropdownValue as DropdownOption).value;
            if (type === CalculatorInputType.RATING_TYPE) {
                value = `${label} ${value}`;
            }
            if (type === CalculatorInputType.AFFECTED_PARTY) {
                const isBothParties = value === 'Both Parties';
                value = `${value} ${isBothParties ? 'are' : 'is'} Affected Part${isBothParties ? 'ies' : 'y'}`;
            }
            addOption({ value, label, type });
        }
    }, [addOption]);

    const addStandardOption = useCallback(({ type, label, inputType }: CalculatorOption) => {
        if (type === CalculatorOptionType.STANDARD) {
            const value = [CalculatorInputType.RATING_CONDITIONAL, CalculatorInputType.OPERATIONAL_ADMINISTRATIVE_DEFAULT].includes(inputType) ? `(${label})` : label;
            addOption({ value, label, type: inputType });
        }
        if (type === CalculatorOptionType.TEXT) {
            addOption({ value: '', label, type: inputType });
        }
    }, [addOption]);

    const getInputContent = useCallback((input: CalculatorOption, inputDisabled: boolean) => {
        switch (input.type) {
            case CalculatorOptionType.DROPDOWN: {
                const { dropdownName, inputType, label } = input;
                const dropdownOptions = getDropdownOptions(dropdownName);
                return <div className={styles.dropdownOption}>
                    <Dropdown
                        options={dropdownOptions}
                        value={null}
                        onChange={value => addDropdownOption(value, label, inputType)}
                        menuPortalTarget={document.body}
                        hideIndicator
                        maxControlHeight='30px'
                        minControlHeight='30px'
                        disabled={inputDisabled || disabled}
                        disableControlColor={white}
                    />
                </div>;
            }
            case CalculatorOptionType.NUMBER:
                return <div className={styles.numberOption}>
                    <Number
                        value={numberInput}
                        onChange={val => setNumberInput(val)}
                        onKeyDown={addNumberOption}
                        disabled={inputDisabled || disabled}
                    />
                    <div onClick={addNumberOptionClick} className={styles.numberButton}><Icon icon={Tick} fontSize={18} /></div>
                </div>;
            case CalculatorOptionType.DECIMAL:
                return <div className={styles.numberOption}>
                    <Number
                        value={decimalInput}
                        onChange={val => setDecimalInput(val)}
                        onKeyDown={addDecimalOption}
                        disabled={inputDisabled || disabled}
                        allowDecimal
                    />
                    <div onClick={addDecimalOptionClick} className={styles.numberButton}><Icon icon={Tick} fontSize={18} /></div>
                </div>;
            case CalculatorOptionType.TEXT:
            case CalculatorOptionType.STANDARD:
            default:
                return null;
        }
    }, [getDropdownOptions, addDropdownOption, addNumberOption, addNumberOptionClick, setNumberInput, numberInput, addDecimalOption, addDecimalOptionClick, setDecimalInput, decimalInput, disabled]);

    const getRatingsLabel = useCallback((ratingsLabel: string) => {
        let label = ratingsLabel;
        if (ratingsLabel.includes('LT')) {
            label = label.replace('LT', 'L-T Rating');
        }
        if (ratingsLabel.includes('ST')) {
            label = label.replace('ST', 'S-T Rating');
        }
        return label;
    }, []);

    const getRatingsTriggersInputDisabled = useCallback((input: CalculatorOption) => {
        const secondLatestOutput = selectedBuilder.length > 1 ? selectedBuilder[selectedBuilder.length - 2] : null;
        const latestOutput = selectedBuilder[selectedBuilder.length - 1];
        if (isUndefined(latestOutput)) {
            return false;
        }
        if (latestOutput.type === CalculatorInputType.RATING_TYPE && ![CalculatorInputType.EQUALS, CalculatorInputType.COMPARATOR].includes(input.inputType)) {
            return true;
        }
        if (selectedBuilder.map(({ type }) => type).includes(CalculatorInputType.LOGICAL_OPERATOR) && input.inputType === CalculatorInputType.LOGICAL_OPERATOR) {
            return true;
        }
        if (secondLatestOutput?.type === CalculatorInputType.RATING_TYPE && [CalculatorInputType.COMPARATOR, CalculatorInputType.EQUALS].includes(latestOutput.type)) {
            return input.label !== (getRatingsLabel(secondLatestOutput.value));
        }
        return false;
    }, [selectedBuilder, getRatingsLabel]);

    const getMaturityInputDisabled = useCallback((input: CalculatorOption) => {
        const latestOutput = selectedBuilder[selectedBuilder.length - 1];
        if (isUndefined(latestOutput)) {
            return ![CalculatorInputType.MATURITY, CalculatorInputType.NUMBER].includes(input.inputType);
        }
        if (
            (selectedBuilder[0].type === CalculatorInputType.MATURITY && selectedBuilder.length === 4) ||
            selectedBuilder.length === 7
        ) {
            return true;
        }
        if (latestOutput.type === CalculatorInputType.NUMBER) {
            return input.inputType !== CalculatorInputType.MATURITY_PERIOD;
        }
        if ([CalculatorInputType.MATURITY, CalculatorInputType.MATURITY_PERIOD].includes(latestOutput.type)) {
            const previousComparator = selectedBuilder.find(({ type }) => type === CalculatorInputType.COMPARATOR);
            if (!previousComparator) {
                return input.inputType !== CalculatorInputType.COMPARATOR;
            }
            const previousComparatorWasPrevious = ['<', '<='].includes(previousComparator.value);
            return previousComparatorWasPrevious ?
                !['<', '<='].includes(input.label) :
                !['>', '>='].includes(input.label);
        }
        if (latestOutput.type === CalculatorInputType.COMPARATOR) {
            const secondLatestOutput = selectedBuilder[selectedBuilder.length - 2];
            return secondLatestOutput.type === CalculatorInputType.MATURITY ?
                input.inputType !== CalculatorInputType.NUMBER :
                input.inputType !== CalculatorInputType.MATURITY;
        }
        return false;
    }, [selectedBuilder]);

    const getInputDisabled = useMemo(() => {
        if (fieldType === CalculatorFieldType.RATINGS_TRIGGERS) {
            return getRatingsTriggersInputDisabled;
        }
        if (fieldType === CalculatorFieldType.MATURITY) {
            return getMaturityInputDisabled;
        }
        return () => false;
    }, [fieldType, getRatingsTriggersInputDisabled, getMaturityInputDisabled]);

    const getCursorType = useCallback((type: CalculatorOptionType, disabled: boolean) => {
        if (disabled) {
            return 'not-allowed';
        }
        return [CalculatorOptionType.STANDARD, CalculatorOptionType.TEXT].includes(type) ? 'pointer' : 'default';
    }, []);

    const calculatorInputsWidth = useMemo(() => (width / 2) - 6, [width]);
    const inputWrapperWidth = useMemo(() => `calc((100% / ${Math.floor(calculatorInputsWidth / 110)}) - 4px)`, [calculatorInputsWidth]);

    const showButtonWrapper = useMemo(() => fieldType !== CalculatorFieldType.CROSS_DEFAULT && (ifElseAvailable.includes(fieldType) || (!isNull(selectedBuilderIndex) && additionalConditionsAvailable.includes(fieldType))), [fieldType, selectedBuilderIndex]);
    const buttonWrapperStyle = useMemo(() => showButtonWrapper ? { borderTop: `2px solid ${grey}`, marginTop: '3px' } : {}, [showButtonWrapper]);

    const maxInputHeight = `${maxHeight - 55}px`;

    return (
        <div className={styles.editorWrapper} style={{ maxHeight: `${maxHeight - 2}px` }}>
            <Scrollable maxHeight={maxInputHeight}>
                <div className={styles.inputItemsWrapper} style={{ maxHeight: maxInputHeight }}>
                    {availableInputs.map((input, index) => {
                        const { type, label, inputType } = input;
                        const inputDisabled = getInputDisabled(input);
                        return (
                            <button className={styles.inputWrapper} key={index} onClick={() => addStandardOption(input)} style={{ cursor: getCursorType(type, inputDisabled), width: inputWrapperWidth }} disabled={inputDisabled}>
                                <div className={styles.inputLabel} style={{ fontSize: getFontSize(inputType, type, true) }}>{label}</div>
                                {getInputContent(input, inputDisabled)}
                            </button>
                        );
                    })}
                </div>
            </Scrollable>
            <div className={styles.buttonWrapper} style={buttonWrapperStyle}>
                {ifElseAvailable.includes(fieldType) && showOutputField &&
                    <CustomTooltip overlayText='Represent alternative outcomes by inserting an “If…Else…” statement into the calculation field, enabling you to represent multiple outcomes.' placement='left'>
                        <button className={styles.conditionButton} onClick={addNewCalculation}>IF...ELSE...</button>
                    </CustomTooltip>
                }
                {!isNull(selectedBuilderIndex) && additionalConditionsAvailable.includes(fieldType) &&
                    <CustomTooltip overlayText='Link together the statements that you have constructed by adding an “and” or an “or” conjunction BETWEEN statements.'>
                        <button className={styles.conditionButton} onClick={addNewBuilder}>Add Conjunction</button>
                    </CustomTooltip>
                }
            </div>
        </div>
    );
};
