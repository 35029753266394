import React, { useCallback } from 'react';
import { noop } from 'lodash/fp';
import { useHistory } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import styles from './HomeWrapper.module.scss';
import { WarningSign } from '../shared/icons';
import { ModalHeader } from '../shared/modal/ModalHeader';
import { ConfirmationModal as AccessDeniedModal } from '../shared/modal/ConfirmationModal';
import { getUserNotPermittedModalOpen, toggleUserNotPermittedModalOpen } from '../home/store';

export const UserNotPermittedModal: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getUserNotPermittedModalOpen);
    const closeModal = useCallback(() => {
        history.push('/home');
        dispatch(toggleUserNotPermittedModalOpen(false));
    }, [dispatch, history]);

    if (!isOpen) {
        return null;
    }

    return (
        <AccessDeniedModal
            isOpen
            closeModal={closeModal}
            testId='user-not-permitted-module'
            confirm={noop}
            showConfirm={false}
            zIndex={20}
            closeLabel='Close'
        >
            <ModalHeader label='Unauthorised' icon={WarningSign} />
            <div className={styles.unauthorisedModuleAccess} data-testid='access-denied-modal-message'>You do not have permission to access this module. Please speak to your administrator to gain access.</div>
        </AccessDeniedModal>
    );
};
