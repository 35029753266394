import React from 'react';

import { PlaybookSubheader } from '../../../admin/playbook/store';
import styles from './PlaybookReadOnly.module.scss';
import { OverflowTooltip } from '../../tooltip';

interface ReadOnlySubheaderProps {
    subheader: PlaybookSubheader;
    testId?: string;
}

export const ReadOnlySubheader: React.FC<ReadOnlySubheaderProps> = ({ subheader: { sectionId, title }, testId = 'playbook' }) => (
    <div className={styles.readOnlySubheader} data-testid={`${testId}-wrapper`} id={sectionId}>
        <OverflowTooltip overlayText={title} testId={testId} />
    </div>
);
