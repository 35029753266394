import React from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { DATASET_HEIGHT_OFFSET, PAGE_MARGIN_PADDING_BORDER, useSplitView } from '../../../hooks/useSplitView';
import { getDocumentAnalysisView } from '../../documents/my-documents/store';
import { DatasetType } from '../store';
import styles from './DatasetInstance.module.scss';
import { FormInstance } from './form-instance/FormInstance';
import { DatasetInstance as DatasetInstanceType, getHiddenFields } from './store';
import { TableInstance } from './table-instance/TableInstance';

interface DatasetInstanceProps {
    datasetInstance: DatasetInstanceType;
    isEditing: boolean;
    isUpdating: boolean;
    parentDatasetIds: string[];
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    modalInstance?: boolean;
    datasetHidden?: boolean;
    fullWidth?: boolean;
    isMLCorrection?: boolean;
}

export const DatasetInstance: React.FC<DatasetInstanceProps> = ({ datasetInstance, isEditing, isUpdating, parentDatasetIds, modalInstance, parentFieldId, datasetHidden, instanceExecutedDateMatchesParent, fullWidth = false, isMLCorrection }) => {
    const widthOffset = PAGE_MARGIN_PADDING_BORDER + (parentDatasetIds.length * 10) + (modalInstance ? 18 : 0);
    const heightOffset = DATASET_HEIGHT_OFFSET;
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const [width] = useSplitView(documentAnalysisView, widthOffset, heightOffset);
    const padding = parentDatasetIds.length ? 10 : 0;
    const datasetInstanceWidth = modalInstance ? 'fit-content' : width;
    const hiddenFields = useAppSelector(getHiddenFields);

    return (
        <div className={styles.datasetInstanceContent} style={{ width: fullWidth ? '100%' : datasetInstanceWidth, maxWidth: fullWidth ? '100%' : `${width}px`, paddingLeft: `${padding}px`, height: '100%' }}>
            {datasetInstance.datasetType === DatasetType.FORM ?
                <FormInstance datasetInstance={datasetInstance} isEditing={isEditing} isUpdating={isUpdating} hiddenFields={hiddenFields} modalInstance={modalInstance} parentFieldId={parentFieldId} datasetHidden={datasetHidden} instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent} isMLCorrection={isMLCorrection} /> :
                <TableInstance datasetInstance={datasetInstance} isEditing={isEditing} isUpdating={isUpdating} modalInstance={modalInstance} parentFieldId={parentFieldId} hiddenFields={hiddenFields} datasetHidden={datasetHidden} instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent} isMLCorrection={isMLCorrection} />
            }
        </div>
    );
};
