import React from 'react';
import classnames from 'classnames';

import styles from './LongText.module.scss';

interface LongTextProps {
    value: string;
    onChange: (value: string) => void;
    maxLength?: number;
    placeholder?: string;
    label?: string;
    height?: string;
    width?: string;
    testId?: string;
    marginBottom?: string;
    disabled?: boolean;
    cursor?: string;
    lightBorder?: boolean;
    fontSize?: string;
    wrapperHeight?: string;
}

export const LongText: React.FC<LongTextProps> = ({
    onChange,
    placeholder,
    value,
    label,
    maxLength = 200,
    height = '80px',
    width = '350px',
    testId = '',
    marginBottom = '10px',
    disabled = false,
    cursor = 'auto',
    lightBorder = false,
    fontSize = '13px',
    wrapperHeight = 'none'
}) => (
    <div className={styles.wrapper} data-testid={`longtext-wrapper-${testId}`} style={{ marginBottom, height: wrapperHeight, width }}>
        {label && <div className={styles.label} data-testid={`longtext-label-${testId}`}>{label}</div>}
        <textarea
            className={classnames(styles.textArea, { [styles.textAreaWithLightBorder]: lightBorder })}
            style={{ height, cursor, fontSize }}
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disabled}
            data-testid={`longtext-input-${testId}`}
        />
    </div>
);
