import React from 'react';
import { isUndefined } from 'lodash/fp';

import { OverflowTooltip, TooltipList } from '../tooltip';
import styles from './Table.module.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const TooltipListLabelCell: React.FC<{ valueFormatted?: string[] | null; value: any; testId?: string; }> = ({ valueFormatted, value, testId }) => {
    if (isUndefined(valueFormatted) && isUndefined(value)) {
        return null;
    }

    const displayValue = valueFormatted || value;
    const firstValue = displayValue.length > 0 ? displayValue[0] : '';
    const additionalValues = displayValue.length > 0 ? `${displayValue.length > 1 ? `+ ${displayValue.length - 1}` : ''}` : '';
    const tooltipUsageWidth = displayValue.length > 1 ? '30px' : '0px';
    return (
        <div className={styles.tooltipListLabelCellWrapper}>
            <TooltipList overlayText={displayValue} placement='right' trigger='click'>
                <OverflowTooltip className={styles.tooltipValue} overlayText={firstValue} testId={testId} />
            </TooltipList>
            <div className={styles.tooltipLength} style={{ width: tooltipUsageWidth }} data-testid={`${testId}-additional-values`}>{additionalValues}</div>
        </div>
    );
};
