import React, { useCallback, useMemo, useState, MouseEvent } from 'react';
import { isEqual, isNull, noop } from 'lodash/fp';

import { useAppSelector } from '../../../../hooks/react-redux';
import { LinkedPlaybookProvision, Playbook as PlaybookType, PlaybookHistory, PlaybookQueryDB, PlaybookView, SuggestedChangesDB, isSubheaderSection } from '../../../admin/playbook/store';
import styles from './PlaybookReadOnly.module.scss';
import { getAvailableDocumentNames } from '../../../admin/documents/store';
import { WYSIWYG } from '../../wysiwyg/WYSIWYG';
import { Scrollable } from '../../scrollable/Scrollable';
import { ReadOnlyProvision } from './ReadOnlyProvision';
import { ABSTRACT_ID } from '../../../constants/playbooks';
import { QueryMarker } from '../query/QueryMarker';
import { PlaybookSideMenu } from './PlaybookSideMenu';
import { ReadOnlySubheader } from './ReadOnlySubheader';

const { white } = styles;

interface PlaybookProps {
    playbook: PlaybookType;
    currentView: PlaybookView;
    openPlaybookActionModal?: (e: MouseEvent<HTMLDivElement>, sectionId: string) => void;
    queries?: PlaybookQueryDB[]
    playbookDefinitionId?: number | null;
    openQuery?: (playbookQueryId: number) => void
    getPlaybookInstanceVersion?: (playbookDefinitionId: number) => void;
    openDeviations?: string[];
    toggleDeviation?: (sectionId: string) => void;
    suggestedChanges?: SuggestedChangesDB[];
    openSuggestedChange?: (playbookSuggestionId: number | null) => void;
    isAdmin?: boolean;
    openAdminSuggestedChanges?: (playbookId: number) => void;
    playbookHistory?: PlaybookHistory[];
    resolvedSuggestedChanges?: SuggestedChangesDB[];
    openResolvedSuggestedChange?: (playbookSuggestionId: number | null) => void;
    provisionLinkModalOpen?: string | null;
    toggleLinkedProvisionModal?: (sectionId: string) => void;
    openLinkToProvision?: (link: LinkedPlaybookProvision) => void;
    testId?: string;
    canOpenActionModal?: boolean;
}

export const Playbook: React.FC<PlaybookProps> = ({
    playbook,
    currentView,
    openPlaybookActionModal = noop,
    queries,
    playbookDefinitionId,
    openQuery = noop,
    getPlaybookInstanceVersion = noop,
    openDeviations = [],
    toggleDeviation = noop,
    suggestedChanges,
    openSuggestedChange = noop,
    isAdmin = false,
    openAdminSuggestedChanges = noop,
    playbookHistory = [],
    resolvedSuggestedChanges,
    openResolvedSuggestedChange = noop,
    provisionLinkModalOpen = null,
    toggleLinkedProvisionModal = noop,
    openLinkToProvision = noop,
    testId = 'playbook',
    canOpenActionModal = false
}) => {
    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(true);

    const { name, agreementType, documentNameIds, content: { abstract, sections }, isDraft } = playbook;

    const selectedDocumentName = useMemo(() => {
        if (documentNameIds && documentNameIds.length > 0) {
            return allDocumentNames.filter(({ documentNameId }) => documentNameIds.includes(documentNameId!.toString())).map(({ documentName }) => documentName).join(', ');
        }
        return null;
    }, [documentNameIds, allDocumentNames]);

    const agreementTypeLabel = useMemo(() => !isNull(agreementType) ? agreementType : '', [agreementType]);

    const isTableView = useMemo(() => currentView === PlaybookView.TABLE, [currentView]);

    const contentsWidth = useMemo(() => sideMenuOpen ? 'calc(80% - 40px)' : 'fit-content', [sideMenuOpen]);

    const sectionQueries = useCallback((sectionId: string) => queries?.filter(query => query.sectionId === sectionId) || [], [queries]);
    const deviationOpen = useCallback((sectionId: string) => !!openDeviations.find(openDeviation => isEqual(openDeviation, sectionId)), [openDeviations]);

    return (
        <div className={styles.previewWrapper} data-testid={`${testId}-viewer-wrapper`}>
            <PlaybookSideMenu
                playbook={playbook}
                suggestedChanges={suggestedChanges}
                openSuggestedChange={openSuggestedChange}
                isAdmin={isAdmin}
                openAdminSuggestedChanges={openAdminSuggestedChanges}
                sideMenuOpen={sideMenuOpen}
                setSideMenuOpen={setSideMenuOpen}
                playbookHistory={playbookHistory}
                resolvedSuggestedChanges={resolvedSuggestedChanges}
                openResolvedSuggestedChange={openResolvedSuggestedChange}
                testId={`${testId}-side-menu`}
            />
            <div className={styles.contentsWrapper} style={{ width: contentsWidth }} data-testid={`${testId}-content-wrapper`}>
                <Scrollable>
                    <div className={styles.previewTitleWrapper} id='playbook-title'>
                        <div className={styles.previewTitle} data-testid={`${testId}-title`}>{`${name}${isDraft ? ' (PUBLISHED AS DRAFT)' : ''}`}</div>
                        {agreementType &&
                            <div className={styles.previewInfo} data-testid={`${testId}-agreement-label`}>{agreementTypeLabel}</div>
                        }
                        {documentNameIds &&
                            <div className={styles.previewInfo} data-testid={`${testId}-document-name`}>{selectedDocumentName}</div>
                        }
                    </div>
                    <div className={styles.previewAbstractWrapper} id={ABSTRACT_ID} onDoubleClick={e => canOpenActionModal ? openPlaybookActionModal(e, ABSTRACT_ID) : noop} data-testid={`${testId}-abstract-read-only-wrapper`}>
                        <div className={styles.queriesWrapper}>
                            {sectionQueries(ABSTRACT_ID).map(query => (
                                <QueryMarker key={query.playbookQueryId} openQuery={openQuery} query={query} currentPlaybookDefinitionId={playbookDefinitionId!} getPlaybookInstanceVersion={getPlaybookInstanceVersion} />
                            ))}
                        </div>
                        <div className={styles.abstractSuggestedChanges} />
                        <WYSIWYG
                            content={abstract}
                            updateContent={noop}
                            showBorder={false}
                            height='fit-content'
                            minHeight='fit-content'
                            maxHeight='fit-content'
                            borderColor={white}
                            padding='0px'
                            editorPadding='0px'
                            disabled
                            toolbarHidden
                            isReadOnly
                        />
                    </div>
                    {sections.map((section, index) => isSubheaderSection(section) ? (
                        <ReadOnlySubheader
                            subheader={section}
                            key={section.sectionId}
                            testId={`${testId}-subheader-${index}`}
                        />
                    ) : (
                        <ReadOnlyProvision
                            key={section.sectionId}
                            section={section}
                            isTableView={isTableView}
                            openPlaybookActionModal={openPlaybookActionModal}
                            queries={sectionQueries(section.sectionId)}
                            openQuery={openQuery}
                            playbookDefinitionId={playbookDefinitionId}
                            getPlaybookInstanceVersion={getPlaybookInstanceVersion}
                            deviationOpen={deviationOpen(section.sectionId)}
                            toggleDeviation={toggleDeviation}
                            showProvisionLinks
                            provisionLinkModalOpen={provisionLinkModalOpen}
                            toggleLinkedProvisionModal={toggleLinkedProvisionModal}
                            openLinkToProvision={openLinkToProvision}
                            testId={`${testId}-read-only-provision-${index}`}
                        />
                    ))}
                </Scrollable>
            </div>
        </div>
    );
};
