import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { GroupDatasetField } from '../../../datasets/store';
import { Icon as ArkIcon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide, Checkbox, Delete, Sort } from '../../../shared/icons';
import sharedStyles from '../SharedBuilder.module.scss';
import { getSelectedGroup, removeDatasetField, selectGroup, updateDatasetField } from '../store';
import styles from './DatasetComponents.module.scss';
import { Draggable } from '../../../shared/drag-n-drop/vertical/Draggable';

interface MultiToggleContentProps {
    field: GroupDatasetField;
    groupId: string;
    groupIndex: number;
    getChildElement: (id: string, index: number, type?: string) => JSX.Element | null;
}

export const MultiToggleContent: React.FC<MultiToggleContentProps> = ({ groupId, field, groupIndex, getChildElement }) => {
    const [groupOpen, setGroupOpen] = useState<boolean>(true);
    const { id, label, children } = field;
    const dispatch = useAppDispatch();
    const selectedGroupId = useAppSelector(getSelectedGroup);

    const removeRow = () => {
        dispatch(selectGroup(null));
        dispatch(removeDatasetField(groupId));
    };

    const toggleGroup = () => {
        const groupIdToSelect = groupId === selectedGroupId ? null : groupId;
        dispatch(selectGroup(groupIdToSelect));
    };

    const groupOpenIcon = groupOpen ? CaretDown : CaretSide;

    const toggleGroupOpen = () => setGroupOpen(!groupOpen);

    const updateLabel = (value: string) => dispatch(updateDatasetField('label', value, groupIndex));

    const childItems = useMemo(() => children.map(({ id, label, type }) => ({ id: id!, label: label ? `${label} (${type})` : type, type: groupId })), [children, groupId]);

    const labelNotPopulated = useMemo(() => children.some(({ label }) => label.length === 0), [children]);
    const groupIsSelected = useMemo(() => groupId === selectedGroupId, [groupId, selectedGroupId]);

    return (
        <div
            className={classNames(sharedStyles.contentWrapper, {
                [styles.selectedGroupWrapper]: groupIsSelected,
                [styles.selectedGroupWrapperRequired]: labelNotPopulated
            })}
        >
            <div className={styles.groupContentWrapper}>
                <div className={styles.headerWrapper}>
                    <div className={styles.groupHeader} onClick={toggleGroup} data-testid={`draggable-multi-toggle-${groupIndex}`}>
                        <ArkIcon icon={Sort} fontSize={25} />
                        <input
                            data-testid={`multi-toggle-${id}-label`}
                            className={styles.multiToggleLabel}
                            placeholder='Label...'
                            onChange={e => updateLabel(e.target.value)}
                            value={label}
                        />
                    </div>
                    <div className={styles.groupRightButtons}>
                        <div className={styles.groupHeaderLabel}>Multi Toggle</div>
                        <ArkIcon icon={Checkbox} fontSize={25} />
                        <button className={sharedStyles.groupOpenToggle} onClick={toggleGroupOpen} data-testid={`draggable-multi-toggle-${groupIndex}-collapse`}>
                            <ArkIcon icon={groupOpenIcon} fontSize={20} />
                        </button>
                        <button className={sharedStyles.removeRowWrapper} onClick={removeRow} data-testid={`draggable-multi-toggle-${groupIndex}-delete-button`}>
                            <ArkIcon icon={Delete} fontSize={20} />
                        </button>
                    </div>
                </div>
                <div>
                    {groupOpen && childItems.map((item, index) => (
                        <Draggable
                            key={item.id}
                            item={item}
                            index={index}
                            getChildElement={getChildElement}
                            type={groupId}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
