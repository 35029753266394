import React from 'react';
import classnames from 'classnames';

import styles from './DefinitionBuilderPreview.module.scss';
import { DatasetDefinition, DatasetType } from '../../../datasets/store';
import { FormPreview } from './formPreview/FormPreview';
import { TablePreview } from './tablePreview/TablePreview';

interface DefinitionBuilderPreviewProps {
    datasetDefinition: DatasetDefinition
}

export const DefinitionBuilderPreview: React.FC<DefinitionBuilderPreviewProps> = ({ datasetDefinition }) => (
    <div className={styles.previewWrapper}>
        <div
            className={classnames(styles.previewTitle, { [styles.emptyPreviewTitle]: !datasetDefinition.datasetTitle })}
            data-testid='definition-builder-preview-title'
        >
            {datasetDefinition.datasetTitle || 'Title...'}
        </div>
        {datasetDefinition.datasetType === DatasetType.FORM ?
            <FormPreview datasetDefinition={datasetDefinition} /> :
            <TablePreview datasetDefinition={datasetDefinition} />
        }
    </div>
);
