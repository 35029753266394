import { RawDraftContentState } from 'draft-js';
import { action } from 'typesafe-actions';

import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../shared/table/ArkTable';
import { Clause, ClauseLibraryActionTypes, ClauseTableTab, ClauseTagDropdownOptions, ClauseTags, ClauseType, ClientTag, ClientTagColumnSort, CurrentClientTag, FuzzyMatchTags, LinkedClauseOpinion, SelectedSystemTag } from './types';

export const toggleClauseModal = () => action(ClauseLibraryActionTypes.TOGGLE_CLAUSE_MODAL);

export const updateClause = (key: keyof Clause, value: string| string[] | RawDraftContentState | null | number ) => action(ClauseLibraryActionTypes.UPDATE_CLAUSE, { key, value });

export const toggleClauseDescription = () => action(ClauseLibraryActionTypes.TOGGLE_CLAUSE_DESCRIPTION);

export const updateClauseTag = (key: keyof ClauseTags, value: string[] | null) => action(ClauseLibraryActionTypes.UPDATE_CLAUSE_TAGS, { key, value });

export const updateTagTerm = (value: string) => action(ClauseLibraryActionTypes.UPDATE_TAG_TERM, value);

export const updateFuzzyMatchResults = (tags: FuzzyMatchTags[]) => action(ClauseLibraryActionTypes.UPDATE_FUZZY_MATCH_RESULTS, tags);

export const addClientTagStarted = (value: string) => action(ClauseLibraryActionTypes.ADD_CLIENT_TAG_STARTED, value);
export const addClientTagSuccessful = (allClientTags: ClientTag[]) => action(ClauseLibraryActionTypes.ADD_CLIENT_TAG_SUCCESSFUL, { allClientTags });
export const addClientTagFailed = (error: string) => action(ClauseLibraryActionTypes.ADD_CLIENT_TAG_FAILED, error);

export const addSystemTagStarted = (newTag: string, category: keyof ClauseTags, updatedTags?: string[]) => action(ClauseLibraryActionTypes.ADD_SYSTEM_TAG_STARTED, { newTag, category, updatedTags });
export const addSystemTagSuccessful = (key: keyof ClauseTags, value?: string[] | null ) => action(ClauseLibraryActionTypes.ADD_SYSTEM_TAG_SUCCESSFUL, { key, value });
export const addSystemTagFailed = (error: string) => action(ClauseLibraryActionTypes.ADD_SYSTEM_TAG_FAILED, error);

export const setFuzzyMatchModalOpen = (value: boolean) => action(ClauseLibraryActionTypes.SET_FUZZY_MATCH_MODAL_OPEN, value);

export const fetchClauseLibraryDropdownOptionsStarted = () => action(ClauseLibraryActionTypes.FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_STARTED);
export const fetchClauseLibraryDropdownOptionsSuccessful = (dropdownOptions: ClauseTagDropdownOptions) => action(ClauseLibraryActionTypes.FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_SUCCESSFUL, dropdownOptions);
export const fetchClauseLibraryDropdownOptionsFailed = (error: string) => action(ClauseLibraryActionTypes.FETCH_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_FAILED, error);

export const checkFuzzyMatch = () => action(ClauseLibraryActionTypes.CHECK_FUZZY_MATCH);

export const setClauseCanSave = (value: boolean) => action(ClauseLibraryActionTypes.SET_CLAUSE_CAN_SAVE, value);

export const saveClauseStarted = (closeModal: boolean) => action(ClauseLibraryActionTypes.SAVE_CLAUSE_STARTED, closeModal);
export const saveClauseSuccessful = (clause: Clause, closeModal: boolean) => action(ClauseLibraryActionTypes.SAVE_CLAUSE_SUCCESSFUL, { clause, closeModal });
export const saveClauseFailed = (error: string) => action(ClauseLibraryActionTypes.SAVE_CLAUSE_FAILED, error);

export const fetchAllClausesStarted = (pageNumber: number) => action(ClauseLibraryActionTypes.FETCH_ALL_CLAUSES_STARTED, pageNumber);
export const fetchAllClausesSuccessful = (clauses: Clause[], total: number, pageNumber: number, linkedClauseOpinions: LinkedClauseOpinion[]) => action(ClauseLibraryActionTypes.FETCH_ALL_CLAUSES_SUCCESSFUL, { clauses, total, pageNumber, linkedClauseOpinions });
export const fetchAllClausesFailed = (error: string) => action(ClauseLibraryActionTypes.FETCH_ALL_CLAUSES_FAILED, error);

export const setClauseTableTab = (tab: ClauseTableTab, redirect?: boolean) => action(ClauseLibraryActionTypes.TOGGLE_CLAUSE_TABLE_VIEW, { tab, redirect });

export const openSavedClause = (savedClauseId: number) => action(ClauseLibraryActionTypes.OPEN_SAVED_CLAUSE, savedClauseId);

export const toggleDeleteClauseModal = (deleteClauseId: number | null) => action(ClauseLibraryActionTypes.TOGGLE_DELETE_CLAUSE_MODAL, deleteClauseId);

export const deleteClauseStarted = (clauseId: number, systemTemplate: number) => action(ClauseLibraryActionTypes.DELETE_CLAUSE_STARTED, { clauseId, systemTemplate });
export const deleteClauseSuccessful = () => action(ClauseLibraryActionTypes.DELETE_CLAUSE_SUCCESSFUL);
export const deleteClauseFailed = (error: string) => action(ClauseLibraryActionTypes.DELETE_CLAUSE_FAILED, error);

export const duplicateClause = (duplicateClauseId: number, isSave: boolean, hasSystemClausePermission: boolean) => action(ClauseLibraryActionTypes.DUPLICATE_CLAUSE, { duplicateClauseId, isSave, hasSystemClausePermission });

export const updateAllClientTags = (tags: ClientTag[]) => action(ClauseLibraryActionTypes.UPDATE_ALL_CLIENT_TAGS, tags);

export const toggleSystemTagCategoryModal = () => action(ClauseLibraryActionTypes.TOGGLE_SYSTEM_TAG_CATEGORY_MODAL);

export const redirectToOpinion = (opinionId: number, location: string) => action(ClauseLibraryActionTypes.REDIRECT_TO_OPINION, { opinionId, location });

// Clause Tags Modal

export const setTagModalType = (tagType: ClauseType | null) => action(ClauseLibraryActionTypes.SET_TAG_MODAL_TYPE, tagType);

export const toggleClauseTagsModal = () => action(ClauseLibraryActionTypes.TOGGLE_CLAUSE_TAGS_MODAL);

export const toggleDeleteTagModal = (tag: CurrentClientTag | SelectedSystemTag | null, isTemplate?: boolean) => action(ClauseLibraryActionTypes.TOGGLE_DELETE_TAG_MODAL, { tag, isTemplate });

export const deleteTagStarted = (clauseTagId: number, includedClauses: number[]) => action(ClauseLibraryActionTypes.DELETE_CLIENT_TAG_STARTED, { clauseTagId, includedClauses });
export const deleteTagSuccessful = (tags: ClientTag[]) => action(ClauseLibraryActionTypes.DELETE_CLIENT_TAG_SUCCESSFUL, tags);
export const deleteTagFailed = (error: string) => action(ClauseLibraryActionTypes.DELETE_CLIENT_TAG_FAILED, error);

export const setSelectedClientTag = (tag: CurrentClientTag | null) => action(ClauseLibraryActionTypes.SET_SELECTED_CLIENT_TAG, tag);

export const updateSelectedClientTag = (key: keyof CurrentClientTag, value: string | string[] | number[] | null) => action(ClauseLibraryActionTypes.UPDATE_SELECTED_CLIENT_TAG, { key, value });

export const updateSelectedClientTagClauseUsage = (key: keyof CurrentClientTag, value: number[]) => action(ClauseLibraryActionTypes.UPDATED_SELECTED_TAG_CLAUSE_USAGE, { key, value });

export const setClientTagCanSave = (value: boolean) => action(ClauseLibraryActionTypes.SET_CLIENT_TAG_CAN_SAVE, value);

export const saveTagStarted = (selectedTag: CurrentClientTag) => action(ClauseLibraryActionTypes.SAVE_CLIENT_TAG_STARTED, selectedTag);
export const saveTagSuccessful = (tags: ClientTag[]) => action(ClauseLibraryActionTypes.SAVE_CLIENT_TAG_SUCCESSFUL, tags);
export const saveTagFailed = (error: string) => action(ClauseLibraryActionTypes.SAVE_CLIENT_TAG_FAILED, error);

export const toggleTagColumnSort = (columnSort: ClientTagColumnSort) => action(ClauseLibraryActionTypes.TOGGLE_TAG_COLUMN_SORT, columnSort);
export const setClientTagFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(ClauseLibraryActionTypes.SET_CLIENT_TAG_FILTERS, { key, value, type });
export const clearClientTagFilters = () => action(ClauseLibraryActionTypes.CLEAR_CLIENT_TAG_FILTERS);

export const setSelectedTemplateTag = (tag: SelectedSystemTag | null) => action(ClauseLibraryActionTypes.SET_SELECTED_TEMPLATE_TAG, tag);

export const updateSelectedTemplateTag = (key: keyof SelectedSystemTag, value: string | keyof ClauseTags) => action(ClauseLibraryActionTypes.UPDATE_SELECTED_TEMPLATE_TAG, { key, value });

export const setTemplateTagCanSave = (value: boolean) => action(ClauseLibraryActionTypes.SET_TEMPLATE_TAG_CAN_SAVE, value);

export const saveTemplateTagStarted = (selectedTag: SelectedSystemTag) => action(ClauseLibraryActionTypes.SAVE_TEMPLATE_TAG_STARTED, selectedTag);
export const saveTemplateTagSuccessful = () => action(ClauseLibraryActionTypes.SAVE_TEMPLATE_TAG_SUCCESSFUL);
export const saveTemplateTagFailed = (error: string) => action(ClauseLibraryActionTypes.SAVE_TEMPLATE_TAG_FAILED, error);

export const deleteTemplateTagStarted = (selectedTag: SelectedSystemTag) => action(ClauseLibraryActionTypes.DELETE_TEMPLATE_TAG_STARTED, selectedTag);
export const deleteTemplateTagSuccessful = () => action(ClauseLibraryActionTypes.DELETE_TEMPLATE_TAG_SUCCESSFUL);
export const deleteTemplateTagFailed = (error: string) => action(ClauseLibraryActionTypes.DELETE_TEMPLATE_TAG_FAILED, error);

// Table filters and Pagination
export const setClauseLibraryTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_TABLE_FILTERS, { key, value, type });
export const clearClauseLibraryTableFilters = () => action(ClauseLibraryActionTypes.CLEAR_CLAUSE_LIBRARY_TABLE_FILTERS);
export const setClauseLibraryTableColumnSort = (columnSort: ColumnSort | undefined) => action(ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_TABLE_COLUMN_SORT, columnSort);
export const setClauseLibraryPageSize = (pageSize: number) => action(ClauseLibraryActionTypes.SET_CLAUSE_LIBRARY_PAGE_SIZE, pageSize);
export const clauseLibraryPaginationNext = () => action(ClauseLibraryActionTypes.CLAUSE_LIBRARY_PAGINATION_NEXT);
export const clauseLibraryPaginationPrevious = () => action(ClauseLibraryActionTypes.CLAUSE_LIBRARY_PAGINATION_PREVIOUS);
export const resetOpinionClausesTable = (tab: ClauseTableTab) => action(ClauseLibraryActionTypes.RESET_CLAUSE_TABLES, tab);
