import React, { useMemo } from 'react';
import { noop } from 'lodash/fp';

import { useAppSelector } from '../../../hooks/react-redux';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { getNotificationToSend } from './store';
import styles from './Notification.module.scss';
import { WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import { getAllClients } from '../clients/store';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Notification } from '../../shared/icons';

const { white } = styles;

interface NotificationPreviewModalProps {
    isOpen: boolean;
    closeModal: () => void;
    sendNotification: () => void;
    allClientIdsSelected: boolean;
}

export const NotificationPreviewModal: React.FC<NotificationPreviewModalProps> = ({ isOpen, closeModal, sendNotification, allClientIdsSelected }) => {
    const { title, content, clientIds, adminOnly } = useAppSelector(getNotificationToSend);
    const allClients = useAppSelector(getAllClients);
    const clientNames = useMemo(() => allClientIdsSelected ? ['All Clients'] : allClients.filter(({ clientId }) => clientIds.includes(clientId!)).map(({ clientName }) => clientName).join(' - '), [allClientIdsSelected, allClients, clientIds]);
    const sendToLabel = useMemo(() => `Sending to ${adminOnly ? 'administrators' : 'all users'} for${allClientIdsSelected ? '' : ' the following clients'}:`, [adminOnly, allClientIdsSelected]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={sendNotification}
            showConfirm
            showClose
            closeOnOverlayClick={false}
            confirmLabel='Send Notification'
        >
            <div className={styles.previewModal}>
                <ModalHeader icon={Notification} label={title} overflowTooltipClassName={styles.previewModalHeader} />
                <div className={styles.previewContent}>
                    <WYSIWYG
                        content={content}
                        updateContent={noop}
                        height='40vh'
                        disabled
                        showBorder={false}
                        borderColor={white}
                        toolbarHidden
                        padding='0px'
                        editorPadding='0px'
                    />
                </div>
                <div className={styles.clientNamesWrapper}>
                    <div className={styles.sendToLabel}>{sendToLabel}</div>
                    <div className={styles.clientNames}>
                        {clientNames}
                    </div>
                </div>
            </div>
        </ ConfirmationModal>
    );
};
