import { RawDraftContentState } from 'draft-js';
import React, { useCallback } from 'react';

import styles from './OpinionInstance.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Pencil } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import { updateOpinionSignOffFinalNotes, getCurrentSignOffNotes, getSignOffModalOpen, toggleSignOffModalOpen, upsertSignOffOpinionInstanceStarted } from './store';

export const SignOffModal: React.FC = () => {
    const dispatch = useAppDispatch();

    const isOpen = useAppSelector(getSignOffModalOpen);
    const signOffNotes = useAppSelector(getCurrentSignOffNotes);

    const finalApprovalNotes = signOffNotes && signOffNotes.finalApprovalNotes || null;

    const updateFinalNotes = useCallback((value: RawDraftContentState | null) => dispatch(updateOpinionSignOffFinalNotes(value)), [dispatch]);
    const closeModal = useCallback(() => dispatch(toggleSignOffModalOpen(false)), [dispatch]);
    const confirm = useCallback(() => dispatch(upsertSignOffOpinionInstanceStarted(true)), [dispatch]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={confirm}
            confirmLabel='Sign Off'
            testId='opinion-sign-off-confirmation-modal'
        >
            <div className={styles.finalSignOffWrapper}>
                <ModalHeader
                    testId='opinion-sign-off-modal-header'
                    label='Sign Off'
                    icon={Pencil}
                />
                <div className={styles.opinionInstanceModalLabel}>Final Notes</div>
                <WYSIWYG
                    content={finalApprovalNotes}
                    updateContent={updateFinalNotes}
                />
                <div className={styles.opinionInstanceModalWarning}>Please note that once an instance of an Opinion is signed off it is final and no further changes can be made.</div>
            </div>
        </ConfirmationModal>
    );
};
