import React, { useState } from 'react';
import { isUndefined } from 'lodash/fp';
import classnames from 'classnames';

import styles from './EntityType.module.scss';
import { CaretDown, CaretSide, Delete } from '../../../shared/icons';
import { AddressType, CompanyEntity, PersonEntity } from '../store';
import { Icon } from '../../../shared/icon/Icon';
import { Text } from '../../../shared/text/Text';

interface AddressInputProps {
    address1: string;
    address2: string;
    city: string;
    country: string;
    postalCode: string;
    addressType?: AddressType | null;
    index?: number;
    onValueChange: (key: string, value: string | null, index?: number) => void;
    deleteFunction: (index: number | undefined) => void;
    name?: string;
    entity: CompanyEntity | PersonEntity | null;
    showAddressType?: boolean;
    disabled?: boolean;
}

export const AddressInput: React.FC<AddressInputProps> = ({ address1, address2, city, country, postalCode, onValueChange, index, deleteFunction, entity, showAddressType = false, addressType, disabled = false }) => {
    const [sectionOpen, setSectionOpen] = useState<boolean>(true);
    const sectionOpenIcon = sectionOpen ? CaretDown : CaretSide;
    const sectionTitle = entity?.content.otherAddresses && !isUndefined(index) && entity.content.otherAddresses[index].address1.length ? entity.content.otherAddresses[index].address1 : `Address ${index! + 1}`;

    const toggleSection = () => setSectionOpen(!sectionOpen);

    const testId = isUndefined(index) ? '' : '-' + index;

    const addressInputs = [{
        placeholder: 'Address Line 1...',
        name: 'address1',
        value: address1
    },
    {
        placeholder: 'Address Line 2...',
        name: 'address2',
        value: address2
    }];

    const additionalAddressInputs = [{
        placeholder: 'City...',
        name: 'city',
        value: city
    },
    {
        placeholder: 'Country...',
        name: 'country',
        value: country
    },
    {
        placeholder: 'Postcode...',
        name: 'postalCode',
        value: postalCode
    }];

    if (sectionOpen) {
        return <div data-testid={`entity-address-input-wrapper${testId}`} className={styles.addressInputWrapper}>
            {!isUndefined(index) && <div className={styles.sectionDividerWrapper}>
                <div data-testid={`other-address-title${testId}`} className={styles.entityAddressLabel}>{sectionTitle}</div>
                <div className={styles.sectionDividerWrapper__buttons}>
                    {!disabled && <button disabled={disabled} data-testid={`address-input-delete-address-button-${index}`} className={styles.sectionIconButton} onClick={() => deleteFunction(index)}>
                        <Icon icon={Delete} fontSize={22} />
                    </button>}
                    <button data-testid={`address-input-close-address-button-${index}`} className={styles.sectionIconButton} onClick={toggleSection}>
                        <Icon icon={sectionOpenIcon} fontSize={14} />
                    </button>
                </div>
            </div>}
            {showAddressType &&
                <div className={styles.addressTypeSelection}>
                    <div className={styles.addressType}>
                        <input
                            className={styles.addressTypeCheckbox}
                            type='checkbox'
                            value={AddressType.BUSINESS}
                            checked={addressType === AddressType.BUSINESS}
                            onChange={e => onValueChange('addressType', e.target.checked ? AddressType.BUSINESS : null, index)}
                            data-testid='entity-address-type-business'
                            disabled={disabled}
                        />
                        <div className={styles.addressTypeLabel}>Business Address</div>
                    </div>
                    <div className={styles.addressType}>
                        <input
                            className={styles.addressTypeCheckbox}
                            type='checkbox'
                            value={AddressType.REGISTERED}
                            checked={addressType === AddressType.REGISTERED}
                            onChange={e => onValueChange('addressType', e.target.checked ? AddressType.REGISTERED : null, index)}
                            data-testid='entity-address-type-registered'
                            disabled={disabled}
                        />
                        <div className={styles.addressTypeLabel}>Registered Address</div>
                    </div>
                </div>}
            {addressInputs.map(({ placeholder, value, name }) => (
                <Text
                    key={name}
                    testId={`entity-address-${name}${testId}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => onValueChange(name, e.target.value, index)}
                    disabled={disabled}
                />
            ))}
            <div className={styles.addressSpacer}>
                {additionalAddressInputs.map(({ placeholder, value, name }, i) => {
                    const isFirstInput = i === 0;
                    const isLastInput = i === additionalAddressInputs.length - 1;
                    return (
                        <div key={name} className={classnames(styles.narrowInput, { [styles.firstNarrowInput]: isFirstInput, [styles.lastNarrowInput]: isLastInput })}>
                            <Text
                                testId={`entity-address-${name}${testId}`}
                                placeholder={placeholder}
                                value={value}
                                onChange={e => onValueChange(name, e.target.value, index)}
                                disabled={disabled}
                            />
                        </div>
                    );
                }
                )}
            </div>
        </div>;
    }

    return (
        <div className={styles.sectionDividerWrapper}>
            <div data-testid={`other-address-title-${index}`} className={styles.entityAddressLabel}>{sectionTitle}</div>
            <div className={styles.sectionDividerWrapper__buttons}>
                {!disabled && <button disabled={disabled} data-testid={`other-address-input-delete-address-button-${index}`} className={styles.sectionIconButton} onClick={() => deleteFunction(index)}>
                    <Icon icon={Delete} fontSize={22} />
                </button>}
                <button data-testid={`address-input-close-address-button-${index}`} className={styles.sectionIconButton} onClick={toggleSection}>
                    <Icon icon={sectionOpenIcon} fontSize={14} />
                </button>
            </div>
        </div>
    );
};
