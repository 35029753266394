import React, { useCallback, useState } from 'react';
import { isNull, isUndefined } from 'lodash/fp';

import styles from './Number.module.scss';

const { primary } = styles;

export const formatNumericalValue = (value: number | string | undefined | null, includeDecimal = false) => {
    if (isUndefined(value) || isNull(value)) {
        return '';
    }
    let pattern = /(?=(?!^)\d{3}(?:\b|(?:\d{3})+)\b)/g;
    if (value.toString().includes('.')) {
        pattern = /(?=(?!^)\d{3}(?:\b|(?:\d{3})+)\b\.)/g;
    }
    return `${value.toString().replace(pattern, ',')}${includeDecimal ? '.' : ''}`;
};
export interface NumberProps {
    value: number | undefined | null;
    placeholder?: string;
    onChange: (value: number | undefined) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    testId?: string;
    disabled?: boolean;
    allowDecimal?: boolean;
    controlBorderColor?: string;
}

export const Number: React.FC<NumberProps> = ({
    value,
    placeholder = '0',
    onChange,
    onKeyDown,
    disabled = false,
    allowDecimal = true,
    testId,
    controlBorderColor = primary
}) => {
    const [includeDecimal, setIncludeDecimal] = useState<boolean>(false);
    const updateValue = useCallback((stringValue: string) => {
        setIncludeDecimal(allowDecimal && stringValue.length > 0 && stringValue[stringValue.length - 1] === '.');
        const numberValue = parseFloat(stringValue.replace(/,/g, ''));
        if (!isNaN(numberValue) || (isNaN(numberValue) && stringValue.length === 0)) {
            const value = !isNaN(numberValue) ? numberValue : undefined;
            onChange(value);
        }
    }, [onChange, allowDecimal]);

    const formattedValue = formatNumericalValue(value, includeDecimal);

    return (
        <input
            className={styles.numberField}
            data-testid={`${testId}-number`}
            value={formattedValue}
            onChange={e => updateValue(e.target.value)}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={20}
            style={{ borderColor: controlBorderColor }}
        />
    );
};
