import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Button } from '../../shared/button/Button';
import styles from './MyDatasets.module.scss';
import { getHiddenFieldsUpdated, getIsSaving, getMyDatasetsView, getRiskToleranceHasUpdated, getUnsavedChangesModalOpen, MyDatasetView, removeAllFieldSections, saveHiddenFieldsStarted, saveRiskToleranceStarted, setMyDatasetView, toggleUnsavedChangesModal } from './store';
import { UnsavedChangesModal } from '../../shared/modal/UnsavedChangesModal';

interface MyDatasetsButtonsProps {
    datasetId: number;
}

export const MyDatasetsButtons: React.FC<MyDatasetsButtonsProps> = ({ datasetId }) => {
    const dispatch = useAppDispatch();

    const isSaving = useAppSelector(getIsSaving);
    const hiddenFieldsUpdated = useAppSelector(getHiddenFieldsUpdated);
    const riskToleranceUpdated = useAppSelector(getRiskToleranceHasUpdated);
    const datasetView = useAppSelector(getMyDatasetsView);
    const unsavedChangesModalOpen = useAppSelector(getUnsavedChangesModalOpen);

    const hasUnsavedChanges = useMemo(() => hiddenFieldsUpdated || riskToleranceUpdated, [hiddenFieldsUpdated, riskToleranceUpdated]);

    const collapseAllFieldsAndSections = () => dispatch(removeAllFieldSections());
    const toggleUnsavedChangesModalOpen = useCallback((isOpen: boolean) => dispatch(toggleUnsavedChangesModal(isOpen)), [dispatch]);
    const back = useCallback(() => dispatch(setMyDatasetView(MyDatasetView.LIST, null)), [dispatch]);
    const onBackClick = useCallback(() => hasUnsavedChanges ? toggleUnsavedChangesModalOpen(true) : back(), [hasUnsavedChanges, toggleUnsavedChangesModalOpen, back]);
    const saveHiddenFields = useCallback(() => dispatch(saveHiddenFieldsStarted()), [dispatch]);
    const goToView = useCallback((view: MyDatasetView) => dispatch(setMyDatasetView(view, datasetId)), [dispatch, datasetId]);
    const saveRiskTolerance = useCallback(() => dispatch(saveRiskToleranceStarted()), [dispatch]);

    const saveHiddenFieldsDisabled = useMemo(() => isSaving || !hiddenFieldsUpdated, [isSaving, hiddenFieldsUpdated]);
    const saveRiskToleranceDisabled = useMemo(() => isSaving || !riskToleranceUpdated, [isSaving, riskToleranceUpdated]);

    const isRiskToleranceView = useMemo(() => datasetView === MyDatasetView.RISK_TOLERANCE, [datasetView]);

    const wrapperStyle = useMemo(() => isRiskToleranceView ? { width: 'calc(100% - 20px)', padding: '0 10px' } : { width: '100%' }, [isRiskToleranceView]);

    const buttons = useMemo(() => (
        [MyDatasetView.PREVIEW, MyDatasetView.CONFIGURE, MyDatasetView.RISK_TOLERANCE].filter(view => view !== datasetView)
            .map(view => (<Button key={view} label={view} onClick={() => goToView(view)} />))
    ), [datasetView, goToView]);

    return (
        <div className={styles.buttonWrapper} style={wrapperStyle}>
            <div>
                <Button onClick={onBackClick} label='Back' />
            </div>
            <div className={styles.rightButtonsWrapper}>
                {!isRiskToleranceView && <Button onClick={collapseAllFieldsAndSections} label='Collapse All' />}
                {buttons}
                {!isRiskToleranceView && <Button onClick={saveHiddenFields} label='Save' disabled={saveHiddenFieldsDisabled} />}
                {isRiskToleranceView && <Button onClick={saveRiskTolerance} label='Save' disabled={saveRiskToleranceDisabled} />}
            </div>
            <UnsavedChangesModal isOpen={unsavedChangesModalOpen} closeModal={() => toggleUnsavedChangesModalOpen(false)} confirm={back} />
        </div>
    );
};
