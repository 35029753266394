import React, { useMemo } from 'react';
import { min } from 'lodash/fp';

import { PieChart, PieColors, PieChartAnalyticsData } from '../shared/analytics/PieChart';
import { Icon } from '../shared/icon/Icon';
import styles from './Home.module.scss';

interface PieChartWrapperProps {
    data: PieChartAnalyticsData[];
    dimensions?: {
        height: number;
        width: number;
    }
    id?: string;
    testId?: string;
    icon?: React.FC;
    colors?: keyof PieColors;
}

export const PieChartWrapper: React.FC<PieChartWrapperProps> = ({ data, testId, id, dimensions, icon, colors }) => {
    const diameter = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 20;
            const svgHeight = dimensions.height - 55;
            return min([svgWidth, svgHeight])!;
        }
        return 0;
    }, [dimensions]);

    const filteredData = useMemo(() => data.filter(({ value }) => value), [data]);

    return (
        <div className={styles.pieChartWrapper} data-testid={`home-page-pie-chart-wrapper-${testId}`}>
            <PieChart data={filteredData} testId={testId} id={id} diameter={diameter} colors={colors} />
            {icon &&
                <div
                    className={styles.pieChartIcon}
                    style={{ height: `${diameter / 3}px`, width: `${diameter / 3}px` }}
                    data-testid={`pie-chart-icon-${testId}`}
                >
                    <Icon icon={icon} fontSize={diameter / 5} />
                </div>
            }
        </div>
    );
};
