import { flow, isArray, isEqual, set, unset } from 'lodash/fp';

import {
    AdditionalProvisions,
    AgreementCoverage,
    AnnexCoverage,
    Assumptions,
    CollateralTakerInsolvency,
    CounterpartyCoverage,
    EarlyTerminationAndCloseOutNetting,
    EligibleCollateral,
    General,
    IndependentAmountProvisions,
    MarginArrangements,
    NettingLegalIssues,
    NettingOtherIssues,
    NettingSFT,
    OpinionDateField,
    OpinionDropdownDetailsField,
    OpinionDropdownField,
    OpinionFieldValue,
    OpinionInstance,
    OpinionInstanceContent,
    OpinionInstanceField,
    OpinionInstanceFieldType,
    OpinionInstanceType,
    OpinionMultiToggleField,
    OpinionTextField,
    OpinionToggleField,
    OpinionWYSIWYGField,
    OtherIssues,
    Protocols,
    ProviderSFTDocuments,
    RepoEarlyCloseOut,
    RepoLegalIssues,
    SECIMSupplement,
    SecurityInterestDocumentation,
    SignOffConversation,
    SignOffOpinionInstance,
    SignOffOpinionInstanceContent,
    TakerSFTDocuments,
    TitleTransferDocumentation,
    TransactionCoverage,
    UpdatedOpinionField,
    UpdatedSignOffNotesField
} from './types';
import { OpinionCommissionedBy, OpinionScope } from '../../../admin/opinions/store';

export const getDefaultValue = (type: OpinionInstanceFieldType): OpinionFieldValue => {
    switch (type) {
        case OpinionInstanceFieldType.TEXT:
        case OpinionInstanceFieldType.LONGTEXT:
            return '';
        case OpinionInstanceFieldType.DATE:
        case OpinionInstanceFieldType.DROPDOWN:
        case OpinionInstanceFieldType.WYSIWYG:
        case OpinionInstanceFieldType.ENTITY:
            return null;
        case OpinionInstanceFieldType.TOGGLE:
            return false;
        case OpinionInstanceFieldType.MULTI_TOGGLE:
            return [false, false];
        default:
            return null;
    }
};

const sharedProperties = { ref: '', pageRef: [''], refOpen: false, pageRefVerified: true };
const createDropdownField = (label: string, dropdownList: string, isMulti = false): OpinionDropdownField => ({ ...sharedProperties, label, value: null, type: OpinionInstanceFieldType.DROPDOWN, dropdownList, isMulti });
const createDropdownDetailsField = (label: string, dropdownList: string): OpinionDropdownDetailsField => ({ ...sharedProperties, label, dropdownValue: null, wysiwygValue: null, type: OpinionInstanceFieldType.DROPDOWN_DETAILS, dropdownList });
const createWYSIWYGField = (label: string): OpinionWYSIWYGField => ({ ...sharedProperties, label, value: null, type: OpinionInstanceFieldType.WYSIWYG });
const createDateField = (label: string): OpinionDateField => ({ ...sharedProperties, label, value: null, type: OpinionInstanceFieldType.DATE });
export const createToggleField = (label: string): OpinionToggleField => ({ ...sharedProperties, label, value: false, type: OpinionInstanceFieldType.TOGGLE });
export const createMultiToggleField = (label: string, toggles: number): OpinionMultiToggleField => ({ ...sharedProperties, label, value: Array(toggles).fill(false), type: OpinionInstanceFieldType.MULTI_TOGGLE });
const createTextField = (label: string): OpinionTextField => ({ ...sharedProperties, label, value: '', type: OpinionInstanceFieldType.TEXT });

const general: General = {
    provider: createTextField('Provider'),
    reviewer: createTextField('Reviewer'),
    dateOfReview: createDateField('Date of review')
};

const assumptions: Assumptions = {
    governingLaw: createDropdownField('Governing law of Master Agreement', 'GoverningLaw', true),
    governingLawIfOther: createTextField('Governing law (if other)'),
    bothPartiesSingleBranch: createDropdownDetailsField('Both parties acting as \'single branch\' party?', 'YesNoUnclear'),
    multiBranchStatus: createDropdownDetailsField('Would multibranch status adversely impact the conclusions of the opinion?', 'YesNoUnclear')
};

const repoAssumptions: Assumptions = {
    governingLaw: createDropdownField('Governing law of Master Agreement', 'GoverningLaw', true),
    governingLawIfOther: createTextField('Governing law (if other)'),
    bothPartiesSingleBranch: createDropdownDetailsField('Both parties acting as \'single branch\' party?', 'YesNoUnclear'),
    multiBranchStatus: createDropdownDetailsField('If a party acts through multiple Designated Offices, would this adversely impact the conclusions of the opinion?', 'YesNoUnclear')
};

const earlyTermination: EarlyTerminationAndCloseOutNetting = {
    optionalEarlyTermination: createDropdownDetailsField('Is Optional Early Termination enforceable?', 'YesNoUnclear'),
    closeOutNetting: createDropdownDetailsField('Is close-out netting enforceable?', 'YesNoUnclear'),
    closeOutNettingNotCovered: createDropdownDetailsField('Is close-out netting enforceable if transactions that are not covered by the relevant netting legislation are included within the relevant Master Agreement?', 'YesNoUnclear'),
    aetEnforceable: createDropdownDetailsField('Is Automatic Early Termination enforceable?', 'YesNoUnclear'),
    aetRequired: createDropdownDetailsField('Is Automatic Early Termination required?', 'YesNoUnclear'),
    aetAdvisable: createDropdownDetailsField('Is Automatic Early Termination advisable?', 'YesNoUnclear'),
    bridgesOne: createDropdownDetailsField('Does the inclusion of the 2001 Bridge adversely affect the enforceability of close-out netting?', 'YesNoUnclear'),
    bridgesTwo: createDropdownDetailsField('Does the inclusion of the 2002 Bridge adversely affect the enforceability of close-out netting?', 'YesNoUnclear'),
    closeOutProtocol: createDropdownDetailsField('Does the inclusion of the Close-out Amount Protocol language adversely affect the enforceability of close-out netting?', 'YesNoUnclear'),
    sectionTwoAThree: createDropdownDetailsField('Do the changes made to Section 2(a)(iii) pursuant to ISDA\'s June 2014 amendment adversely affect the enforceability of close-out netting?', 'YesNoUnclear'),
    paymentNettingTwoC: createDropdownDetailsField('Would payment netting under Section 2(c) of the Master Agreement be enforceable?', 'YesNoUnclear'),
    nonInsolvency: createDropdownDetailsField('Is the choice of Termination Currency enforceable (NON-insolvency related termination)?', 'YesNoUnclear'),
    insolvency: createDropdownDetailsField('Is the choice of Termination Currency enforceable (insolvency related termination)?', 'YesNoUnclear'),
    closeOutLocalCurrency: createDropdownDetailsField('Does a claim for a Close-out Amount have to be converted into the local currency of the insolvent/defaulting party?', 'YesNoUnclear')
};

const nettingLegalIssues: NettingLegalIssues = {
    nettingLegislation: createDropdownDetailsField('Is netting legislation in place in the relevant jurisdiction?', 'YesNoUnclear'),
    governingLawRecognised: createDropdownDetailsField('Will the governing law of the master agreement be recognised?', 'YesNoUnclear'),
    judgmentOfCourtsEnforced: createDropdownDetailsField('Will a judgment of the courts of the jurisdiction governing the master agreement be enforced?', 'YesNoUnclear'),
    arbitralAwardEnforceable: createDropdownDetailsField('Is an arbitral award enforceable?', 'YesNoUnclear'),
    onShoreInsolvency: createDropdownDetailsField('On the insolvency of a foreign counterparty, could separate insolvency proceedings be instituted in relation to the assets of an on-shore branch of that foreign counterparty?', 'YesNoUnclear'),
    offShoreInsolvency: createDropdownDetailsField('Where separate insolvency proceedings may be instituted against an on-shore branch of a foreign counterparty, on the insolvency of a foreign counterparty, would the relevant insolvency official and courts in the location of the branch enforce close-out netting under the ISDA Master Agreement on a multibranch basis in accordance with their terms?', 'YesNoUnclear'),
    absenceOfInsolvency: createDropdownDetailsField('Is the ISDA Master Agreement (including the close-out netting provisions) when governed by the laws of your jurisdiction enforceable in the absence of insolvency proceedings?', 'YesNoUnclear'),
    capacityCounterpartyIssues: createDropdownDetailsField('Are there any CAPACITY, VALIDITY or ENFORCEABILITY issues with respect to any particular counterparty types?', 'YesNoUnclear'),
    enforceabilityTransactionIssues: createDropdownDetailsField('Are there any ENFORCEABILITY issues with respect to any particular transaction types?', 'YesNoUnclear')
};

const nettingSFT: NettingSFT = {
    sftScheduleProvisions: createDropdownDetailsField('Would the inclusion of the SFT Schedule Provisions adversely affect any of the conclusions within the opinion?', 'YesNoUnclear'),
    accelerationNonDefault: createDropdownDetailsField('Are the provisions of the SFT Definitions allowing the Acceleration Option Non-defaulting Party to elect to accelerate each parties\' obligations upon the insolvency of a counterparty enforceable?', 'YesNoUnclear'),
    automaticAcceleration: createDropdownDetailsField('Are the provisions of the SFT Definitions allowing the AUTOMATIC acceleration of each parties\' obligations upon the insolvency of a counterparty enforceable?', 'YesNoUnclear'),
    bankruptcy: createDropdownDetailsField('If a Bankruptcy Event were to occur, is it possible to include any unpaid amounts in respect of the exercise of the Acceleration Option or a Termination Date/Repurchase Date Securities Transfer Failure as Unpaid Amounts under the ISDA Master Agreement?', 'YesNoUnclear')
};

const securityInterest: SecurityInterestDocumentation = {
    governingLawRecognised: createDropdownDetailsField('Would the GOVERNING LAW of the security documents be recognised?', 'YesNoUnclear'),
    jurisdictionOfCourtsRecognised: createDropdownDetailsField('Would the JURISDICTION of the courts of the law governing the security documents be recognised?', 'YesNoUnclear'),
    validityRecognised: createDropdownDetailsField('Would the VALIDITY of the security interest created under each security document be recognised?', 'YesNoUnclear'),
    validityBodyOfLaw: createDropdownDetailsField('Would the parties\' choice of governing law govern the VALIDITY of the security interest created under each security document?', 'YesNoUnclear'),
    perfectionGoverningLaw: createDropdownDetailsField('Would the law of the place of the location of assets (\'lex situs\') govern PERFECTION of the security interest created under each security document?', 'YesNoUnclear'),
    collateralTakerPerfected: createDropdownDetailsField('Will the collateral taker have a PERFECTED security interest in the collateral under the collateral security documents?', 'YesNoUnclear'),
    actionsToPerfect: createDropdownDetailsField('Are any actions required in order to PERFECT the security interest?', 'YesNoUnclear'),
    eligibleCollateralCreated: createDropdownDetailsField('Would a security interest in each type of Eligible Collateral created under each Security Document be recognised?', 'YesNoUnclear'),
    affectedByFluctuation: createDropdownDetailsField('Will the security interest be adversely affected by virtue of the fact that both the amount secured and the amount of collateral may FLUCTUATE?', 'YesNoUnclear'),
    futureObligations: createDropdownDetailsField('Would the security interest be valid in relation to FUTURE OBLIGATIONS of the collateral provider?', 'YesNoUnclear'),
    futureCollateral: createDropdownDetailsField('Would the security interest be valid in relation to FUTURE COLLATERAL delivered to the secured party?', 'YesNoUnclear'),
    fluctuatingAssets: createDropdownDetailsField('Can a security interest be created over a fluctuating pool of assets?', 'YesNoUnclear'),
    fixedAmount: createDropdownDetailsField('Is it necessary for the amount secured under each security document to be a fixed amount or subject to a fixed maximum amount?', 'YesNoUnclear'),
    holdExcessCollateral: createDropdownDetailsField('Is it permissible for the secured party to hold collateral IN EXCESS of its actual exposure?', 'YesNoUnclear'),
    careOfCollateral: createDropdownDetailsField('Are there any duties imposed on the secured party in relation to the CARE OF COLLATERAL held under the security documents?', 'YesNoUnclear'),
    useRehypothecatePostedCollateral: createDropdownDetailsField('Can the secured party USE/REHYPOTHECATE the posted collateral (non-IM Security Documentation only)?', 'YesNoUnclear'),
    consentToSubstitution: createDropdownDetailsField('Is CONSENT TO SUBSTITUTION of collateral required/advisable in order to protect the validity, continuity, perfection or priority of the security interest?', 'YesNoUnclear'),
    substituteAdverseImpact: createDropdownDetailsField('Does the right to SUBSTITUTE collateral adversely impact the validity, continuity, perfection or priority of the security interest?', 'YesNoUnclear'),
    enforceRightsFormalities: createDropdownDetailsField('Is it necessary to observe any formalities in order for the secured party to ENFORCE its rights against posted collateral?', 'YesNoUnclear'),
    enforceLimitations: createDropdownDetailsField('Are there any other potential limitations on the right of a secured party to ENFORCE against the posted collateral?', 'YesNoUnclear'),
    enforceInDefault: createDropdownDetailsField('Would the secured party still be able to ENFORCE its rights against the posted collateral even if it was in \'default\'?', 'YesNoUnclear'),
    insolvencyCompetingPriorities: createDropdownDetailsField('Do rules exist for determining between the COMPETING PRIORITIES of creditors, following the insolvency of the collateral provider?', 'YesNoUnclear'),
    rightsAffectedByCommencementOfInsolvency: createDropdownDetailsField('Would the secured party\'s rights be subject to any STAY or FREEZE on commencement of the insolvency?', 'YesNoUnclear'),
    clawbackDuringSuspectPeriod: createDropdownDetailsField('Will the collateral provider be able to \'CLAWBACK\' any collateral transferred during a \'suspect period\' preceding its insolvency?', 'YesNoUnclear'),
    imJapaneseAmendmentsAffectConclusion: createDropdownDetailsField('Would the inclusion of the IM Japanese Amendments adversely affect any of the conclusions within the opinion?', 'YesNoUnclear'),
    euroclearPledgeeAffectConclusion: createDropdownDetailsField('Would the inclusion of the Euroclear Pledgee Representative Riders in the 2019 Euroclear IM Documents adversely affect any of the conclusions in the opinion?', 'YesNoUnclear'),
    euroclearProprietaryRights: createDropdownDetailsField('Would the courts of the jurisdiction under consideration recognise the proprietary rights of the Represented Party in the security created pursuant to the 2019 Euroclear Security Agreement incorporating the Euroclear Security Agreement Pledgee Representative Rider?', 'YesNoUnclear'),
    cashLeakageConclusion: createDropdownDetailsField('Would the inclusion of the \'Cash Leakage Rider\' within an IM document adversely affect any of the conclusions within the opinion?', 'YesNoUnclear')
};

const providerSftDocuments: ProviderSFTDocuments = {
    loanTransactions: createDropdownDetailsField('Would the inclusion of the \'SFT Schedule Provisions\' and the applicability of the \'U.S. Pledge Structure\' with respect to Loan Transactions adversely affect any of the conclusions within the opinion?', 'YesNoUnclear'),
    koreanPledge: createDropdownDetailsField('Would the inclusion of the \'SFT Schedule Provisions\' and the applicability of the \'Korean Pledge Margin Structure\' adversely affect any of the conclusions within the opinion?', 'YesNoUnclear'),
    transferOfTransferredSecurities: createDropdownDetailsField('Would each transfer of Transferred Securities constitute an unconditional transfer of ownership?', 'YesNoUnclear'),
    titleTransferAffected: createDropdownDetailsField('Would any of the conclusions within the opinion regarding \'title transfer\' be adversely affected if the SFT Schedule Provisions are included in the ISDA Master Agreement and the title transfer margin provisions apply with respect to SFT Transactions under the ISDA Master Agreement?', 'YesNoUnclear')
};

const titleTransfer: TitleTransferDocumentation = {
    governingLawRecognised: createDropdownDetailsField('Would the GOVERNING LAW of the transfer documents be recognised?', 'YesNoUnclear'),
    unconditionalTransferOfOwnership: createDropdownDetailsField('Would transfers of collateral be characterised as effecting an UNCONDITIONAL TRANSFER OF OWNERSHIP in the collateral transferred?', 'YesNoUnclear'),
    recharacterisedSecurityInterest: createDropdownDetailsField('Is there any risk that transfers of collateral would be RECHARACTERISED as creating a security interest?', 'YesNoUnclear'),
    actionsContinuedTitle: createDropdownDetailsField('Would a collateral taker need to take actions in order to ensure CONTINUED TITLE in the collateral transferred?', 'YesNoUnclear'),
    otherToEnsureValidity: createDropdownDetailsField('Are any other requirements necessary to ensure the VALIDITY of a transfer of collateral?', 'YesNoUnclear'),
    transferorRightToExchange: createDropdownDetailsField('Does the right of the Transferor to EXCHANGE collateral already posted to its counterparty adversely affect the VALIDITY of the title transfer collateral documents?', 'YesNoUnclear'),
    transfereeConsent: createDropdownDetailsField('Is the CONSENT of the Transferee required in order to protect the VALIDITY of title transfer?', 'YesNoUnclear'),
    paragraphSix: createDropdownDetailsField('Would paragraph 6 (\'Default\') of each transfer annex be valid to the extent that it provides for the \'Value\' of the \'Credit Support Balance\' to be included in the calculation of the net amount payable under Section 6(e) of the Master Agreement?', 'YesNoUnclear'),
    transfereeRightsInsolvency: createDropdownDetailsField('Would the rights of the Transferee be enforceable under each transfer document, irrespective of the INSOLVENCY of the Transferor?', 'YesNoUnclear'),
    clawbackDuringSuspectPeriod: createDropdownDetailsField('Will the Transferor be able to \'CLAWBACK\' any collateral transferred during a \'suspect period\' preceding its insolvency?', 'YesNoUnclear')
};

const protocols: Protocols = {
    closeOutAmountProtocol: createDropdownDetailsField('Would the changes to any collateral documentation introduced by the ISDA Close-out Amount Protocol adversely affect the conclusions reached in the opinion?', 'YesNoUnclear'),
    negativeInterestProtocol: createDropdownDetailsField('Would the changes to any collateral documentation introduced by the ISDA Collateral Agreement Negative Interest Protocol adversley affect the conclusions reached in the opinion?', 'YesNoUnclear')
};

const independentAmount: IndependentAmountProvisions = {
    independentAmountProvisions: createDropdownDetailsField('Would the changes to any collateral documentation introduced by the Independent Amount (IA) Provisions within a VM CSA adversely affect the conclusions reached in the opinion?', 'YesNoUnclear')
};

const secIMSupplement: SECIMSupplement = {
    secIMChanges: createDropdownDetailsField('Would the changes to any 1995 English Law CSA or VM CSA introduced by the SEC IM Supplement adversely affect the conclusions reached in the opinion?', 'YesNoUnclear')
};

const repoEarlyCloseOut: RepoEarlyCloseOut = {
    aetEnforceable: createDropdownDetailsField('Is Automatic Early Termination enforceable?', 'YesNoUnclear'),
    aetRequired: createDropdownDetailsField('Is Automatic Early Termination required?', 'YesNoUnclear'),
    aetAdvisable: createDropdownDetailsField('Is Automatic Early Termination advisable?', 'YesNoUnclear'),
    optionalAetEnforceable: createDropdownDetailsField('Is optional early termination enforceable?', 'YesNoUnclear'),
    closeOutNettingEnforceable: createDropdownDetailsField('Is close-out netting enforceable?', 'YesNoUnclear'),
    baseCurrencyCashPayment: createDropdownDetailsField('Would the conversion of any cash payment into the Base Currency be valid?', 'YesNoUnclear'),
    securitiesHeldOutsideJurisdiction: createDropdownDetailsField('Is the analysis adversely impacted if Purchased Securities, Loaned Securities, Margin Securities or Collateral or Margin comprising securities are held outside of the jurisdiction in question?', 'YesNoUnclear'),
    crossProductCrossAgreementImpact: createDropdownDetailsField('Would the entry into a Cross-Product Master Agreement or the Cross-Agreement Set-off provisions adversely impact the analysis?', 'YesNoUnclear')
};

const sharedRepoLegalIssues = {
    governingLawRecognised: createDropdownDetailsField('Will the governing law of the master agreement be recognised?', 'YesNoUnclear'),
    judgmentOfCourtsEnforced: createDropdownDetailsField('Will a judgment of the courts of the jurisdiction governing the master agreement be enforced?', 'YesNoUnclear'),
    masterAgreementEnforceableObligations: createDropdownDetailsField('Will the master agreement create legal, valid, binding and enforceable obligations?', 'YesNoUnclear'),
    onShoreInsolvency: createDropdownDetailsField('On the insolvency of a foreign counterparty, could separate insolvency proceedings be instituted in relation to the assets of an on-shore branch of that foreign counterparty?', 'YesNoUnclear'),
};

const repoLegalIssues: RepoLegalIssues = {
    ...sharedRepoLegalIssues,
    offShoreInsolvency: createDropdownDetailsField('Where separate insolvency proceedings may be instituted against an on-shore branch of a foreign counterparty, on the insolvency of a foreign counterparty, would the relevant insolvency official and courts in the location of the branch enforce close-out netting under the GMRA Agreement on the basis of all Designated Offices in accordance with its terms?', 'YesNoUnclear')
};

const stockLendingLegalIssues: RepoLegalIssues = {
    ...sharedRepoLegalIssues,
    offShoreInsolvency: createDropdownDetailsField('Where separate insolvency proceedings may be instituted against an on-shore branch of a foreign counterparty, on the insolvency of a foreign counterparty, would the relevant insolvency official and courts in the location of the branch enforce close-out netting under the GMSLA Agreement on the basis of all Designated Offices in accordance with its terms?', 'YesNoUnclear')
};

const repoStockLendingLegalIssues: RepoLegalIssues = {
    ...sharedRepoLegalIssues,
    offShoreInsolvency: createDropdownDetailsField('Where separate insolvency proceedings may be instituted against an on-shore branch of a foreign counterparty, on the insolvency of a foreign counterparty, would the relevant insolvency official and courts in the location of the branch enforce close-out netting under the GMRA/GMSLA Agreement on the basis of all Designated Offices in accordance with its terms?', 'YesNoUnclear')
};

const marginArrangements: MarginArrangements = {
    recharacterisedSecurityInterest: createDropdownDetailsField('Would a transfer of Securities (whether Purchased Securities or as Margin) be respected as an outright transfer of title?', 'YesNoUnclear'),
    actionsContinuedTitle: createDropdownDetailsField('Are there any other requirements necessary to ensure the VALIDITY of a transfer of Securities or Margin?', 'YesNoUnclear'),
    otherToEnsureValidity: createDropdownDetailsField('Would a collateral taker be required to take actions in order to ensure CONTINUED TITLE in the collateral transferred?', 'YesNoUnclear'),
    transferorRightToExchange: createDropdownDetailsField('Does the right of a Transferor to exchange collateral adversely affect the validity of the margin arrangements?', 'YesNoUnclear'),
    alternativeMarginMethods: createDropdownDetailsField('Would the ALTERNATIVE MARGIN METHODS be upheld?', 'YesNoUnclear'),
    transfereeConsent: createDropdownDetailsField('Is the CONSENT of the Transferee to substitution required in order to protect the VALIDITY of title transfer?', 'YesNoUnclear'),
    transfereeRightsInsolvency: createDropdownDetailsField('Would the rights of a Transferee to set-off any amounts owed to it against any Margin posted be ENFORCEABLE, irrespective of the insolvency of the counterparty?', 'YesNoUnclear'),
    clawbackDuringSuspectPeriod: createDropdownDetailsField('Will the Transferor be able to CLAWBACK any collateral transferred during a suspect period?', 'YesNoUnclear')
};

const collateralTakerInsolvency: CollateralTakerInsolvency = {
    providerRightsEnforceable: createDropdownDetailsField('Would the contractual rights of the collateral provider to secure the RETURN of collateral be enforceable?', 'YesNoUnclear'),
    providerRequirements: createDropdownDetailsField('Are there any requirements that the collateral provider must satisfy in order to secure the RETURN of collateral?', 'YesNoUnclear'),
    allOutstandingSatisfied: createDropdownDetailsField('Must the collateral provider have satisfied ALL OUTSTANDING OBLIGATIONS to the collateral taker before it can secure the RETURN of collateral?', 'YesNoUnclear'),
    subjectToStayOrFreeze: createDropdownDetailsField('Would the collateral provider\'s rights to the return of collateral be subject to any STAY or FREEZE on commencement of the insolvency of the collateral taker?', 'YesNoUnclear'),
    cashLeakageConclusion: createDropdownDetailsField('Would the inclusion of the \'Cash Leakage Rider\' within an IM document adversely affect any of the conclusions within the opinion?', 'YesNoUnclear')
};

const takerSftDocuments: TakerSFTDocuments = {
    loanTransactions: createDropdownDetailsField('Would the inclusion of the \'SFT Schedule Provisions\' and the applicability of the \'U.S. Pledge Structure\' with respect to Loan Transactions adversely affect any of the conclusions within the opinion?', 'YesNoUnclear'),
    koreanPledge: createDropdownDetailsField('Would the inclusion of the \'SFT Schedule Provisions\' and the applicability of the \'Korean Pledge Margin Structure\' adversely affect any of the conclusions within the opinion?', 'YesNoUnclear'),
};

const otherIssues: OtherIssues = {
    otherKeyIssues: createWYSIWYGField('Other key issues')
};

const nettingOtherIssues: NettingOtherIssues = {
    internationalOther: createDropdownDetailsField('Would the conclusions reached in the opinion in relation to the ISDA Master Agreement apply equally to the International Currency Options Market Master Agreement (“ICOM”), the International Foreign Exchange Master Agreement (“IFEMA”), the Foreign Exchange and Options Master Agreement (“FEOMA”) and the International FX and Currency Option Master Agreement Terms and Terms Agreement (“IFXCO”)?', 'YesNoUnclear'),
    otherKeyIssues: createWYSIWYGField('Other key issues')
};

const collateralProviderAgreementCoverage: AgreementCoverage = {
    standardOptions: [
        createToggleField('2016 ISDA Phase 1 Credit Support Annex (New York law)'),
        createToggleField('2018 ISDA Credit Support Annex for Initial Margin (New York law)'),
        createToggleField('2016 ISDA Phase 1 IM Credit Support Deed (English law)'),
        createToggleField('2018 ISDA Credit Support Deed for Initial Margin (English law)'),
        createToggleField('2016 ISDA Euroclear Collateral Transfer Agreement (New York law)'),
        createToggleField('2016 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)'),
        createToggleField('2017 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)'),
        createToggleField('2017 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (New York law)'),
        createToggleField('2018 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (New York law)'),
        createToggleField('2018 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)'),
        createToggleField('2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope'),
        createToggleField('2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope - French Law Addendum'),
        createToggleField('2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope Pledgee Representative Rider'),
        createToggleField('2016 ISDA Euroclear Security Agreement (Belgian law)'),
        createToggleField('2018 ISDA Euroclear Security Agreement (Belgian law)'),
        createToggleField('2019 ISDA Euroclear Security Agreement (Belgian law)'),
        createToggleField('2019 ISDA Euroclear Security Agreement Pledgee Representative Rider'),
        createToggleField('2022 ISDA Euroclear NextGen Security Agreement (Belgian law)'),
        createToggleField('2022 ISDA Euroclear Security Agreement (account or subdivision version) (Belgian law)'),
        createToggleField('2016 ISDA Clearstream Collateral Transfer Agreement (New York law)'),
        createToggleField('2016 ISDA Clearstream Collateral Transfer Agreement (English law)'),
        createToggleField('2019 ISDA Clearstream Collateral Transfer Agreement - Multi-Regime Scope'),
        createToggleField('2019 ISDA Clearstream Collateral Transfer Agreement - Multi-Regime Scope - French Law Addendum'),
        createToggleField('2016 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Provider (Luxembourg law)'),
        createToggleField('2017 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Taker (Luxembourg law)'),
        createToggleField('2019 ISDA Clearstream Security Agreement (Pledge Account in the Name of the Security-Taker) (Luxembourg law)'),
        createToggleField('2019 ISDA Clearstream Security Agreement (Pledge Account in the Name of the Security-Provider) (Luxembourg law)'),
        createToggleField('2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope'),
        createToggleField('2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope - French Law Addendum'),
        createToggleField('2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope - KRW Collateral (IM) Addendum'),
        createToggleField('2019 ISDA Security Agreement for Initial Margin (English law)'),
        createToggleField('2019 ISDA Security Agreement for Initial Margin (New York law)'),
        createToggleField('2019 ISDA Security Agreement (Irish law)'),
        createToggleField('2019 ISDA Security Agreement (Luxembourg law)'),
        createToggleField('2019 ISDA Security Agreement (Belgian law)'),
        createToggleField('2019 ISDA Security Agreement (French law)'),
        createToggleField('2021 ISDA Security Agreement (Korean law)'),
        createToggleField('2022 ISDA Security Agreement (Belgian law)'),
        createToggleField('2022 ISDA Security Agreement (Hong Kong law)'),
        createToggleField('SEC IM Supplement (Third Party Segregation)'),
        createToggleField('SEC IM Supplement (Omnibus or no Segregation)'),
        createToggleField('SEC IM Supplement (Alternative Compliance Mechanism)'),
        createToggleField('Cash Leakage Rider'),
        createToggleField('ISDA Securities Financing Transactions Schedule Provisions'),
        createToggleField('2022 ISDA Securities Financing Transactions Definitions'),
        createToggleField('1994 Credit Support Annex (New York law)'),
        createToggleField('2016 Credit Support Annex for Variation Margin (New York law)'),
        createToggleField('2016 Credit Support Annex for Variation Margin Independent Amount Amendments (New York law)'),
        createToggleField('1995 Credit Support Deed (English law)'),
        createToggleField('1995 Credit Support Annex (English law)'),
        createToggleField('2016 Credit Support Annex for Variation Margin (English law)'),
        createToggleField('2016 Credit Support Annex for Variation Margin Independent Amount Amendments (English law)'),
        createToggleField('1995 Credit Support Annex (French law)'),
        createToggleField('2016 Credit Support Annex for Variation Margin (French law)'),
        createToggleField('1995 Credit Support Annex (Irish law)'),
        createToggleField('2016 Credit Support Annex (Irish law)')
    ]
};

const collateralTakerAgreementCoverage: AgreementCoverage = {
    standardOptions: [
        createToggleField('2016 ISDA Euroclear Collateral Transfer Agreement (New York law)'),
        createToggleField('2016 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)'),
        createToggleField('2017 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)'),
        createToggleField('2017 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (New York law)'),
        createToggleField('2018 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (New York law)'),
        createToggleField('2018 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope (English law)'),
        createToggleField('2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope'),
        createToggleField('2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope - French Law Addendum'),
        createToggleField('2019 ISDA Euroclear Collateral Transfer Agreement - Multi-Regime Scope Pledgee Representative Rider'),
        createToggleField('2016 ISDA Euroclear Security Agreement (Belgian law)'),
        createToggleField('2018 ISDA Euroclear Security Agreement (Belgian law)'),
        createToggleField('2019 ISDA Euroclear Security Agreement (Belgian law)'),
        createToggleField('2019 ISDA Euroclear Security Agreement Pledgee Representative Rider'),
        createToggleField('2022 ISDA Euroclear NextGen Security Agreement (Belgian law)'),
        createToggleField('2022 ISDA Euroclear Security Agreement (account or subdivision version) (Belgian law)'),
        createToggleField('2016 ISDA Clearstream Collateral Transfer Agreement (New York law)'),
        createToggleField('2016 ISDA Clearstream Collateral Transfer Agreement (English law)'),
        createToggleField('2019 ISDA Clearstream Collateral Transfer Agreement - Multi-Regime Scope'),
        createToggleField('2019 ISDA Clearstream Collateral Transfer Agreement - Multi-Regime Scope - French Law Addendum'),
        createToggleField('2016 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Provider (Luxembourg law)'),
        createToggleField('2017 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Taker (Luxembourg law)'),
        createToggleField('2019 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Taker (Luxembourg law)'),
        createToggleField('2019 ISDA Clearstream Security Agreement - Pledge Account in the Name of the Security-Provider (Luxembourg law)'),
        createToggleField('2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope'),
        createToggleField('2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope - French Law Addendum'),
        createToggleField('2019 ISDA Collateral Transfer Agreement for Initial Margin - Multi-Regime Scope - KRW Collateral (IM) Addendum'),
        createToggleField('2019 ISDA Security Agreement for Initial Margin (English law)'),
        createToggleField('2019 ISDA Security Agreement for Initial Margin (New York law)'),
        createToggleField('2019 ISDA Security Agreement (Irish law)'),
        createToggleField('2019 ISDA Security Agreement (Luxembourg law)'),
        createToggleField('2019 ISDA Security Agreement (Belgian law)'),
        createToggleField('2019 ISDA Security Agreement (French law)'),
        createToggleField('2021 ISDA Security Agreement (Korean law)'),
        createToggleField('2022 ISDA Security Agreement (Belgian law)'),
        createToggleField('2022 ISDA Security Agreement (Hong Kong law)'),
        createToggleField('SEC IM Supplement (Third Party Segregation)'),
        createToggleField('SEC IM Supplement (Omnibus or no Segregation)'),
        createToggleField('SEC IM Supplement (Alternative Compliance Mechanism)'),
        createToggleField('Cash Leakage Rider'),
        createToggleField('ISDA Securities Financing Transactions Schedule Provisions'),
        createToggleField('2022 ISDA Securities Financing Transactions Definitions')
    ]
};

const nettingAgreementCoverage: AgreementCoverage = {
    standardOptions: [
        createToggleField('1992 ISDA Master Agreement (Multicurrency - Cross Border)'),
        createToggleField('1992 ISDA Master Agreement (Local Currency - Single Jurisdiction)'),
        createToggleField('2002 ISDA Master Agreement (English law)'),
        createToggleField('2002 ISDA Master Agreement (New York law)'),
        createToggleField('2002 ISDA Master Agreement (French law)'),
        createToggleField('2002 ISDA Master Agreement (Irish law)'),
        createToggleField('2001 ISDA Cross-Agreement Bridge'),
        createToggleField('2002 ISDA Energy Agreement Bridge'),
        createToggleField('ISDA Securities Financing Transactions Schedule Provisions'),
        createToggleField('2022 ISDA Securities Financing Transactions Definitions')
    ]
};

const repoAgreementCoverage: AgreementCoverage = {
    standardOptions: [
        createToggleField('2000 TBMA/ISMA Global Master Repurchase Agreement'),
        createToggleField('2011 ICMA/SIFMA Global Master Repurchase Agreement')
    ]
};

const stockLendingAgreementCoverage: AgreementCoverage = {
    standardOptions: [
        createToggleField('2000 Global Master Securities Lending Agreement'),
        createToggleField('2009 Global Master Securities Lending Agreement'),
        createToggleField('2010 Global Master Securities Lending Agreement')
    ]
};

const repoStockLendingAgreementCoverage: AgreementCoverage = {
    standardOptions: [
        createToggleField('2000 TBMA/ISMA Global Master Repurchase Agreement'),
        createToggleField('2011 ICMA/SIFMA Global Master Repurchase Agreement'),
        createToggleField('2000 Global Master Securities Lending Agreement'),
        createToggleField('2009 Global Master Securities Lending Agreement'),
        createToggleField('2010 Global Master Securities Lending Agreement')
    ]
};

const transactionCoverage: TransactionCoverage = {
    standardOptions: [
        createToggleField('Basis Swap'),
        createToggleField('Bond Forward'),
        createToggleField('Bond Option'),
        createToggleField('Bullion Option'),
        createToggleField('Bullion Swap'),
        createToggleField('Bullion Trade'),
        createToggleField('Buy/Sell-Back Transaction'),
        createToggleField('Cap Transaction'),
        createToggleField('Collar Transaction'),
        createToggleField('Commodity Forward'),
        createToggleField('Commodity Index Transaction'),
        createToggleField('Commodity Option'),
        createToggleField('Commodity Swap'),
        createToggleField('Contingent Credit Default Swap'),
        createToggleField('Credit Default Swap Option'),
        createToggleField('Credit Default Swap'),
        createToggleField('Credit Derivative Transaction on ABS'),
        createToggleField('Credit Spread Transaction'),
        createToggleField('Cross Currency Rate Swap'),
        createToggleField('Currency Option'),
        createToggleField('Currency Swap'),
        createToggleField('Economic Statistic Transaction'),
        createToggleField('Emissions Allowance Transaction'),
        createToggleField('Equity Forward'),
        createToggleField('Equity Index Option'),
        createToggleField('Equity Option'),
        createToggleField('Equity Swap'),
        createToggleField('Floor Transaction'),
        createToggleField('Foreign Exchange Transaction'),
        createToggleField('Forward Rate Transaction'),
        createToggleField('Freight Transaction'),
        createToggleField('Fund Option Transaction'),
        createToggleField('Fund Forward Transaction'),
        createToggleField('Fund Swap Transaction'),
        createToggleField('Interest Rate Option'),
        createToggleField('Interest Rate Swap'),
        createToggleField('Longevity/Mortality Transaction'),
        createToggleField('Non-deliverable Digital Asset Forward'),
        createToggleField('Non-deliverable Digital Asset Option'),
        createToggleField('Physical Commodity Transaction'),
        createToggleField('Property Index Derivative Transaction'),
        createToggleField('Renewable Energy Certificate Transaction'),
        createToggleField('Repurchase Transaction'),
        createToggleField('Securities Lending Transaction'),
        createToggleField('Swap Deliverable Contingent CDS'),
        createToggleField('Swap Option'),
        createToggleField('Total Return Swap'),
        createToggleField('Variance Swap'),
        createToggleField('VCC Transaction'),
        createToggleField('Volatility Swap'),
        createToggleField('Weather Index Transaction'),
        createToggleField('Other')
    ],
    customOptions: [],
    other: createWYSIWYGField('Transaction details (if other)')
};

const repoTransactionCoverage: TransactionCoverage = {
    standardOptions: [
        createToggleField('Buy/Sell-Back Transaction'),
        createToggleField('Repurchase Transaction'),
        createToggleField('Reverse Repurchase Transaction'),
        createToggleField('Reverse Securities Lending Transaction'),
        createToggleField('Securities Lending Transaction'),
        createToggleField('Other')
    ],
    customOptions: [],
    other: createWYSIWYGField('Transaction details (if other)')
};

const stockLendingTransactionCoverage: TransactionCoverage = {
    standardOptions: [
        createToggleField('Securities Lending Transaction'),
        createToggleField('Other')
    ],
    customOptions: [],
    other: createWYSIWYGField('Transaction details (if other)')
};

const repoStockLendingTransactionCoverage: TransactionCoverage = {
    standardOptions: [
        createToggleField('Buy/Sell-Back Transaction'),
        createToggleField('Repurchase Transaction'),
        createToggleField('Reverse Repurchase Transaction'),
        createToggleField('Reverse Securities Lending Transaction'),
        createToggleField('Securities Lending Transaction'),
        createToggleField('Other')
    ],
    customOptions: [],
    other: createWYSIWYGField('Transaction details (if other)')
};

const counterpartyCoverage: CounterpartyCoverage = {
    standardOptions: [
        createMultiToggleField('Bank/Credit Institution', 2),
        createMultiToggleField('Central Bank', 2),
        createMultiToggleField('Corporation', 2),
        createMultiToggleField('Hedge Fund/Proprietary Trader', 2),
        createMultiToggleField('Insurance Company', 2),
        createMultiToggleField('International Organisation', 2),
        createMultiToggleField('Investment Firm/Broker Dealer', 2),
        createMultiToggleField('Investment Fund', 2),
        createMultiToggleField('Local Authority', 2),
        createMultiToggleField('Partnership', 2),
        createMultiToggleField('Pension Fund', 2),
        createMultiToggleField('Sovereign', 2),
        createMultiToggleField('Sovereign Wealth Fund', 2),
        createMultiToggleField('Sovereign-Owned Entity', 2),
        createMultiToggleField('State of a Federal Sovereign', 2),
        createMultiToggleField('Other', 2)
    ],
    customOptions: [],
    other: createWYSIWYGField('Counterparty details (if other)')
};

const nonCashCollateralRow = [
    createDropdownField('Directly held bearer securities', 'YesNoUnclear'),
    createDropdownField('Directly held registered securities', 'YesNoUnclear'),
    createDropdownField('Directly held dematerialised securities', 'YesNoUnclear'),
    createDropdownField('Intermediated securities', 'YesNoUnclear')
];

const eligibleCollateral: EligibleCollateral = {
    onShoreIncluded: createDropdownField('On-shore assets included?', 'YesNoUnclear'),
    offShoreIncluded: createDropdownField('Off-shore assets included?', 'YesNoUnclear'),
    cash: [createDropdownField('Cash', 'YesNoUnclear')],
    debtSecuritiesInJurisdiction: nonCashCollateralRow,
    debtSecuritiesOutsideJurisdiction: nonCashCollateralRow,
    governmentDebtSecuritiesInJurisdiction: nonCashCollateralRow,
    governmentDebtSecuritiesOutsideJurisdiction: nonCashCollateralRow,
    debtSecuritiesMultilateral: nonCashCollateralRow,
    equitySecuritiesInJurisdiction: nonCashCollateralRow,
    equitySecuritiesOutsideJurisdiction: nonCashCollateralRow
};

const gmraAnnexCoverage: AnnexCoverage = {
    standardOptions: [
        createToggleField('Agency Annex'),
        createToggleField('Bills/Bills of Exchange Annex'),
        createToggleField('Buy/Sell Back Annex'),
        createToggleField('EMU Annex (1995 GMRA only)'),
        createToggleField('Equities Annex'),
        createToggleField('Gilts Annex'),
        createToggleField('Net Paying Securities Annex (1995 GMRA only)'),
        createToggleField('Canadian Annex'),
        createToggleField('Italian Annex'),
        createToggleField('Japanese Securities Annex'),
        createToggleField('Japanese Annex'),
        createToggleField('Russian Annex 2021'),
        createToggleField('Other')
    ],
    customOptions: [],
    other: createWYSIWYGField('Details (if other)')
};

const gmslaAnnexCoverage: AnnexCoverage = {
    standardOptions: [
        createToggleField('Agency Annex'),
        createToggleField('Addendum for Pooled Principal Agency Loans'),
        createToggleField('Other')
    ],
    customOptions: [],
    other: createWYSIWYGField('Details (if other)')
};

const gmraGmslaAnnexCoverage: AnnexCoverage = {
    standardOptions: [
        createToggleField('Agency Annex'),
        createToggleField('Addendum for Pooled Principal Agency Loans'),
        createToggleField('Bills/Bills of Exchange Annex'),
        createToggleField('Buy/Sell Back Annex'),
        createToggleField('EMU Annex (1995 GMRA only)'),
        createToggleField('Equities Annex'),
        createToggleField('Gilts Annex'),
        createToggleField('Net Paying Securities Annex (1995 GMRA only)'),
        createToggleField('Canadian Annex'),
        createToggleField('Italian Annex'),
        createToggleField('Japanese Securities Annex'),
        createToggleField('Japanese Annex'),
        createToggleField('Russian Annex 2021'),
        createToggleField('Other')
    ],
    customOptions: [],
    other: createWYSIWYGField('Details (if other)')
};

export const additionalProvisions: AdditionalProvisions = {
    noneRequired: createToggleField('No additional provisions required')
};

export const createDefaultInstanceContent = (type: OpinionInstanceType): OpinionInstanceContent => {
    switch (type) {
        case OpinionInstanceType.COLLATERAL_PROVIDER:
            return {
                general,
                securityInterest,
                sftDocuments: providerSftDocuments,
                titleTransfer,
                protocols,
                independentAmount,
                secIMSupplement,
                otherIssues,
                agreementCoverage: collateralProviderAgreementCoverage,
                transactionCoverage,
                counterpartyCoverage,
                eligibleCollateral,
                additionalProvisions
            };
        case OpinionInstanceType.COLLATERAL_TAKER:
            return {
                general,
                collateralTakerInsolvency,
                sftDocuments: takerSftDocuments,
                otherIssues,
                agreementCoverage: collateralTakerAgreementCoverage,
                transactionCoverage,
                counterpartyCoverage,
                eligibleCollateral,
                additionalProvisions
            };
        case OpinionInstanceType.REPO:
            return {
                general,
                assumptions: repoAssumptions,
                earlyCloseOut: repoEarlyCloseOut,
                legalIssues: repoLegalIssues,
                marginArrangements,
                otherIssues,
                agreementCoverage: repoAgreementCoverage,
                transactionCoverage: repoTransactionCoverage,
                counterpartyCoverage,
                annexCoverage: gmraAnnexCoverage,
                additionalProvisions
            };
        case OpinionInstanceType.STOCK_LENDING:
            return {
                general,
                assumptions: repoAssumptions,
                earlyCloseOut: repoEarlyCloseOut,
                legalIssues: stockLendingLegalIssues,
                marginArrangements,
                otherIssues,
                agreementCoverage: stockLendingAgreementCoverage,
                transactionCoverage: stockLendingTransactionCoverage,
                counterpartyCoverage,
                annexCoverage: gmslaAnnexCoverage,
                additionalProvisions
            };
        case OpinionInstanceType.REPO_STOCK_LENDING:
            return {
                general,
                assumptions: repoAssumptions,
                earlyCloseOut: repoEarlyCloseOut,
                legalIssues: repoStockLendingLegalIssues,
                marginArrangements,
                otherIssues,
                agreementCoverage: repoStockLendingAgreementCoverage,
                transactionCoverage: repoStockLendingTransactionCoverage,
                counterpartyCoverage,
                annexCoverage: gmraGmslaAnnexCoverage,
                additionalProvisions
            };
        case OpinionInstanceType.NETTING:
        default:
            return {
                general,
                assumptions,
                earlyTermination,
                legalIssues: nettingLegalIssues,
                sftTransactions: nettingSFT,
                otherIssues: nettingOtherIssues,
                agreementCoverage: nettingAgreementCoverage,
                transactionCoverage,
                counterpartyCoverage,
                additionalProvisions
            };
    }
};

const flattenInstanceFields = (content: OpinionInstanceContent) => {
    const fields = Object.entries(content).reduce((acc: UpdatedOpinionField[], [sectionId, fields]) => {
        Object.entries(fields).forEach(([fieldId, fieldContent]) => {
            if (isArray(fieldContent)) {
                fieldContent.forEach((field: OpinionInstanceField, index) => {
                    if (field.type === OpinionInstanceFieldType.MULTI_TOGGLE) {
                        field.value.forEach((value, columnIndex) => acc.push({ sectionId, fieldId, index, columnIndex, value, pageRef: field.pageRef, ref: field.ref, includedSubCounterpartyTypeIds: field.includedSubCounterpartyTypeIds }));
                    } else if (field.type !== OpinionInstanceFieldType.DROPDOWN_DETAILS) {
                        acc.push({ sectionId, fieldId, index, value: field.value, pageRef: field.pageRef, ref: field.ref });
                    }
                });
            } else {
                const singleField = fieldContent as OpinionInstanceField;
                if (singleField.type === OpinionInstanceFieldType.DROPDOWN_DETAILS) {
                    acc.push({ sectionId, fieldId, value: singleField.dropdownValue, wysiwygValue: singleField.wysiwygValue, pageRef: singleField.pageRef, ref: singleField.ref });
                } else {
                    acc.push({ sectionId, fieldId, value: singleField.value, pageRef: singleField.pageRef, ref: singleField.ref });
                }
            }
        });
        return acc;
    }, []);
    return fields;
};

const stripVariables = (field: UpdatedOpinionField) => flow(unset('value'), unset('wysiwygValue'), unset('pageRef'), unset('ref'), unset('includedSubCounterpartyTypeIds'))(field);

const compareFields = (existingField: UpdatedOpinionField, currentField: UpdatedOpinionField) => {
    const currentIdentifier = stripVariables(currentField);
    const existingIdentifier = stripVariables(existingField);
    return isEqual(currentIdentifier, existingIdentifier) && ['value', 'wysiwygValue', 'ref', 'pageRef', 'includedSubCounterpartyTypeIds'].some(property => !isEqual(currentField[property as keyof UpdatedOpinionField], existingField[property as keyof UpdatedOpinionField]));
};

const createKey = (field: UpdatedOpinionField) => `${field.sectionId}:${field.fieldId}${field.index ? field.index : ''}${field.columnIndex ? field.columnIndex : ''}`;

export const getUpdatedFields = (currentFields: UpdatedOpinionField[], existingFields: UpdatedOpinionField[]) => {
    const existingFieldMap = new Map<string, UpdatedOpinionField>();

    existingFields.forEach(field => {
        const key = createKey(field);
        existingFieldMap.set(key, field);
    });

    return currentFields.filter(field => {
        const key = createKey(field);
        const existingField = existingFieldMap.get(key);

        return !existingField || compareFields(existingField, field);
    });
};

export const compareInstanceFields = (currentInstance: OpinionInstance, existingInstance: OpinionInstance, isUpdating = false) => {
    const currentFields = flattenInstanceFields(currentInstance.content);
    const existingFields = flattenInstanceFields(existingInstance.content);
    const updatedFields = getUpdatedFields(currentFields, existingFields);
    const previouslyUpdatedFields = existingInstance.fieldsUpdated;
    if (isUpdating || currentInstance.isDraft) {
        const unchangedFields = previouslyUpdatedFields.filter(previous => !updatedFields.find(updatedField => isEqual(stripVariables(updatedField), stripVariables(previous))));
        return { unchangedFields, updatedFields };
    }
    return { updatedFields, unchangedFields: [] };
};

const compareSignOffFields = (existingField: UpdatedSignOffNotesField, currentField: UpdatedSignOffNotesField) => {
    const currentIdentifier = flow(unset('value'))(currentField);
    const existingIdentifier = flow(unset('value'))(existingField);
    return isEqual(currentIdentifier, existingIdentifier) && !isEqual(currentField.value, existingField.value);
};

export const compareSignOffNotesFields = (currentInstance: SignOffOpinionInstance, existingInstance: SignOffOpinionInstance) => {
    const currentFields = flattenSignOffNotesFields(currentInstance.content);
    const existingFields = flattenSignOffNotesFields(existingInstance.content);
    const updatedFields = currentFields.filter(field => existingFields.find(existingField => compareSignOffFields(existingField, field)));
    return { updatedFields };
};

const flattenSignOffNotesFields = (content: SignOffOpinionInstanceContent) => {
    const fields = Object.entries(content).reduce((acc: UpdatedSignOffNotesField[], [sectionId, fields]) => {
        Object.entries(fields).forEach(([fieldId, fieldContent]) => {
            const singleField = fieldContent as SignOffConversation;
            // If the fieldId is 'notes' it's one of the sections that only has one speech bubble and therefore we don't need a fieldId
            fieldId === 'notes' ? acc.push({ sectionId, fieldId: '', value: singleField }) : acc.push({ sectionId, fieldId, value: singleField });
        });
        return acc;
    }, []);
    return fields;
};

export const checkAllPageRefsVerified = (content: OpinionInstanceContent): boolean => {
    const allVerified = Object.values(content).every(fields => Object.values(fields).every(fieldContent => {
        if (isArray(fieldContent)) {
            const allVerified = fieldContent.every((field: OpinionInstanceField) => field.pageRefVerified);
            return allVerified;
        } else {
            const singleField = fieldContent as OpinionInstanceField;
            return singleField.pageRefVerified;
        }
    }));
    return allVerified;
};

export const getUnverifiedSectionIds = (content: OpinionInstanceContent): string[] => {
    const unverifiedSectionIds = Object.entries(content).reduce((acc: string[], [sectionId, fields]) => {
        const allVerified = Object.values(fields).every(fieldContent => {
            if (isArray(fieldContent)) {
                const allVerified = fieldContent.every((field: OpinionInstanceField) => field.pageRefVerified);
                return allVerified;
            } else {
                const singleField = fieldContent as OpinionInstanceField;
                return singleField.pageRefVerified;
            }
        });
        if (allVerified) {
            return acc;
        }
        return [...acc, sectionId];
    }, []);
    return unverifiedSectionIds;
};

const verifyAllPageRefs = (content: OpinionInstanceContent): OpinionInstanceContent => {
    const updatedContent = Object.entries(content).reduce((acc, [sectionId, fields]) => {
        const section = Object.entries(fields).reduce((acc, [fieldId, fieldContent]) => {
            if (isArray(fieldContent)) {
                const fields = fieldContent.map((field: OpinionInstanceField) => set('pageRefVerified', true, field));
                return set(fieldId, fields, acc);
            } else {
                const singleField = fieldContent as OpinionInstanceField;
                const field = set('pageRefVerified', true, singleField);
                return set(fieldId, field, acc);
            }
        }, {});
        return set(sectionId, section, acc);
    }, {}) as OpinionInstanceContent;
    return updatedContent;
};

export const updateFieldsUpdated = (opinionInstance: OpinionInstance, savedInstance: OpinionInstance, isUpdating: boolean, isDraft: boolean): OpinionInstance => {
    let fieldsUpdated = opinionInstance.fieldsUpdated;
    const { updatedFields, unchangedFields } = compareInstanceFields(opinionInstance, savedInstance, isUpdating);
    fieldsUpdated = [...unchangedFields, ...updatedFields];
    if (!isDraft) {
        opinionInstance = set('content', verifyAllPageRefs(opinionInstance.content), opinionInstance);
    }
    return { ...opinionInstance, fieldsUpdated } as OpinionInstance;
};

export const commissionedByFormatter = (commissionedBy: OpinionCommissionedBy, commissionedByIfOther: string | null, scope: OpinionScope, bespoke: number) => {
    if (scope === OpinionScope.GMRA_GMSLA_NETTING && bespoke === 0) {
        return 'ICMA/ISLA';
    }
    return commissionedBy === OpinionCommissionedBy.OTHER ? commissionedByIfOther : commissionedBy;
};
