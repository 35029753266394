import React from 'react';
import classnames from 'classnames';

import { DocumentDatasetField } from '../../admin/dataset-builder/configure-datasets/form-definition/fields/DocumentDatasetField';
import { DatasetsOpenFieldSection } from '../../admin/dataset-builder/store';
import { DatasetField } from '../../admin/my-datasets/formDefinition/fields/DatasetField';
import { MyDatasetsOpenFieldSection } from '../../admin/my-datasets/store';
import { CalculatorField, Checkbox, DateField, DocumentField, Dropdown, LabelField, LongText, Number, PartySelect, Text, Time, WYSIWYGField } from '../../datasets/fields';
import { DatasetFieldType, SingleDatasetField, SingleDatasetFieldType } from '../../datasets/store';
import { newCalculatorField } from '../calculator/constants';
import { DropdownOption } from '../dropdown/Dropdown';
import { OverflowTooltip } from '../tooltip';
import styles from './AdminDatasetFields.module.scss';
import { DatasetLink } from '../../admin/my-datasets/tableDefinition/table/DatasetLink';
import { DocumentDatasetLink } from '../../admin/dataset-builder/configure-datasets/table-definition/table/DocumentDatasetLink';
import { CalculatorCell } from '../../datasets/fields/CalculatorCell';
import { CurrencyAmountField } from '../../datasets/fields/CurrencyAmountField';

export const getAdminFormDatasetField = (
    field: SingleDatasetField,
    datasetId: number,
    fieldOpen: boolean,
    parents: number,
    toggleSection: (fieldSection: DatasetsOpenFieldSection | MyDatasetsOpenFieldSection) => void,
    getSectionOpen: (fieldSection: DatasetsOpenFieldSection | MyDatasetsOpenFieldSection) => boolean,
    sectionId: string,
    isPreview: boolean,
    myDatasets: boolean,
    groupIndex?: number,
    modalInstance?: boolean,
    fieldHidden?: boolean,
    hiddenDocumentNameIds?: number[] | undefined,
    documentNames?: DropdownOption[]
) => {
    const defaultProps = { id: field.id!, disabled: true, value: '', index: 0, groupIndex, datasetId, parentFieldId: '', sectionId, modalInstance };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} />;
        case DatasetFieldType.LONGTEXT:
            return <LongText {...defaultProps} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={null} />;
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
            return <Dropdown {...defaultProps} value={null} type={field.type} listId='0' />;
        case DatasetFieldType.PARTY:
            return <PartySelect {...defaultProps} value={null} />;
        case DatasetFieldType.DOCUMENT:
            return <DocumentField id={field.id!} value={undefined} />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={false} />;
        case DatasetFieldType.DATASET:
            return myDatasets ?
                <DatasetField
                    childDatasetId={field.settings.datasetLinked!}
                    fieldOpen={fieldOpen}
                    parents={parents}
                    fieldHidden={fieldHidden!}
                    isPreview={isPreview}
                    modalInstance={modalInstance}
                    parentHiddenDocumentNameIds={hiddenDocumentNameIds}
                /> : <DocumentDatasetField
                    childDatasetId={field.settings.datasetLinked!}
                    fieldOpen={fieldOpen}
                    parents={parents}
                    hiddenDocumentNameIds={hiddenDocumentNameIds}
                    isPreview={isPreview}
                    modalInstance={modalInstance}
                    documentNames={documentNames!}
                    parentSectionId={sectionId}
                />;
        case DatasetFieldType.TIME:
            return <Time {...defaultProps} value={['', '']} />;
        case DatasetFieldType.WYSIWYG:
            return <WYSIWYGField {...defaultProps} value={null} fieldOpen={fieldOpen} toolbarHidden={true} toggleSection={toggleSection} getSectionOpen={getSectionOpen} />;
        case DatasetFieldType.LABEL:
            return <LabelField value={field.settings.labelContent!} />;
        case DatasetFieldType.CALCULATOR:
            return <CalculatorField
                {...defaultProps}
                value={[newCalculatorField()]}
                fieldType={field.settings.calculatorFieldType!}
                showOutputField={field.settings.showOutputField!}
                showGracePeriod={field.settings.showGracePeriod!}
                includeFreeTextOption={field.settings.includeFreeTextOption}
                fieldOpen={fieldOpen}
            />;
        case DatasetFieldType.CURRENCY_AMOUNT:
            return <CurrencyAmountField
                {...defaultProps}
                currencyValue={{ currency: null, value: undefined }}
            />;
        default:
            return <div className={styles.unknownFieldType}>Field type unknown.</div>;
    }
};

export const getSingleFieldLabel = (
    label: string,
    type: SingleDatasetFieldType,
    id: string | undefined,
    isCollapsible: boolean,
    toggleSection: (fieldSection: DatasetsOpenFieldSection | MyDatasetsOpenFieldSection) => void,
    openField: DatasetsOpenFieldSection | MyDatasetsOpenFieldSection
) => {
    return <OverflowTooltip
        className={classnames(styles.fieldLabel, {
            [styles.emptyFieldLabel]: !label,
            [styles.datasetSectionText]: type === DatasetFieldType.DATASET
        })}
        overlayText={label || 'Label...'}
        testId={`form-field-${id}-label`}
        onClick={() => isCollapsible && toggleSection(openField)}
    />;
};

export const getAdminTableDatasetField = (
    field: SingleDatasetField,
    index: number,
    datasetId: number,
    toggleSection: (fieldSection: DatasetsOpenFieldSection) => void,
    getSectionOpen: (fieldSection: DatasetsOpenFieldSection) => boolean,
    columnHidden: boolean,
    myDatasets: boolean,
    modalInstance?: boolean,
    hiddenDocumentNameIds?: number[] | undefined
) => {
    const defaultProps = { id: field.id!, value: '', index, disabled: true, isTable: true, parentFieldId: '', datasetId, modalInstance };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} />;
        case DatasetFieldType.LONGTEXT:
            return <LongText {...defaultProps} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={null} />;
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
            return <Dropdown
                {...defaultProps}
                value={null}
                type={field.type}
                listId={field.settings.dropdownLinked!}
                isMulti={field.settings.isMultiSelect}
                entityType={field.settings.entityType}
                menuPortalTarget={document.body}
            />;
        case DatasetFieldType.PARTY:
            return <PartySelect
                {...defaultProps}
                value={null}
                menuPortalTarget={document.body}
            />;
        case DatasetFieldType.DOCUMENT:
            return <DocumentField {...defaultProps} id={field.id!} value={['']} showClause />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={false} />;
        case DatasetFieldType.DATASET:
            return myDatasets ?
                <DatasetLink {...defaultProps} childDatasetId={field.settings.datasetLinked!} columnHidden={columnHidden} /> :
                <DocumentDatasetLink {...defaultProps} childDatasetId={field.settings.datasetLinked!} columnHidden={columnHidden} hiddenDocumentNameIds={hiddenDocumentNameIds} />;
        case DatasetFieldType.TIME:
            return <Time {...defaultProps} value={['']} />;
        case DatasetFieldType.WYSIWYG:
            return <WYSIWYGField
                {...defaultProps}
                value={null}
                isCollapsible={field.settings.isCollapsible}
                toolbarHidden
                datasetId={datasetId}
                toggleSection={toggleSection}
                getSectionOpen={getSectionOpen}
            />;
        case DatasetFieldType.CALCULATOR:
            return <CalculatorCell
                {...defaultProps}
                value={[newCalculatorField()]}
                fieldType={field.settings.calculatorFieldType!}
                datasetId={datasetId}
                showOutputField={field.settings.showOutputField!}
                showGracePeriod={field.settings.showGracePeriod!}
                includeFreeTextOption={field.settings.includeFreeTextOption}
            />;
        default:
            return <div className={styles.unknownFieldType}>Field type unknown.</div>;
    }
};
