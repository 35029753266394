import { getOr } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { DatasetDefinitionDB, DatasetType } from '../../../../datasets/store';
import { DropdownOption, customStyles } from '../../../../shared/dropdown/Dropdown';
import { ConfirmationModal } from '../../../../shared/modal/ConfirmationModal';
import { SettingsModal, getAllDatasetDefinitions, updateSettingsValue } from '../../store';
import styles from '../DatasetComponents.module.scss';
import { RadioListOption } from '../../../../shared/radio/RadioList';
import { RadioRow } from '../../../../shared/radio/RadioRow';

interface DatasetLinkModalProps {
    isOpen: boolean;
    closeModal: () => void;
    settingsModalIndex: SettingsModal;
    value: string | null | undefined;
}

const mapDefinitionsToOptions = (definitions: DatasetDefinitionDB[]) => definitions
    .map(({ datasetId, datasetTitle }) => ({ value: datasetId.toString(), label: datasetTitle }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const DatasetLinkModal: React.FC<DatasetLinkModalProps> = ({ isOpen, closeModal, value, settingsModalIndex }) => {
    const dispatch = useAppDispatch();
    const datasetDefinitions = useAppSelector(getAllDatasetDefinitions);

    const selectedDefinition = useMemo(() => datasetDefinitions.filter(({ datasetId }) => datasetId.toString() === value), [datasetDefinitions, value]);

    const initialType = useMemo(() => selectedDefinition && selectedDefinition.length ? selectedDefinition[0].datasetType : null, [selectedDefinition]);
    const [definitionType, setDefinitionType] = useState<null | DatasetType>(initialType);

    const options = useMemo(() => {
        if (!definitionType) {
            return [];
        }
        const definitionsByType = datasetDefinitions.filter(({ datasetType }) => datasetType === definitionType);
        return mapDefinitionsToOptions(definitionsByType);
    }, [definitionType, datasetDefinitions]);

    const { index, groupIndex } = settingsModalIndex;

    const linkToDataset = (selected: SingleValue<DropdownOption> | MultiValue<DropdownOption> | null) => dispatch(updateSettingsValue('datasetLinked', getOr(null, 'value', selected), index!, groupIndex));

    const definitionValue = useMemo(() => selectedDefinition ? mapDefinitionsToOptions(selectedDefinition) : null, [selectedDefinition]);

    const datasetTypeOptions: RadioListOption[] = useMemo(() => [
        {
            id: DatasetType.FORM,
            isSelected: definitionType === DatasetType.FORM,
            label: 'Form'
        },
        {
            id: DatasetType.TABLE,
            isSelected: definitionType === DatasetType.TABLE,
            label: 'Table'
        }
    ], [definitionType]);

    const changeDefinitionType = useCallback((id: string) => setDefinitionType(id as DatasetType), [setDefinitionType]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            closeLabel='Close'
            showConfirm={false}
            testId='dataset-link'
            showOverlay={false}
        >
            <div className={styles.datasetLinkWrapper} data-testid='dataset-link-wrapper'>
                <div className={styles.datasetLinkTitle}>Link to Dataset</div>
                <div className={styles.datasetLinkTypeWrapper}>
                    <RadioRow options={datasetTypeOptions} onChange={changeDefinitionType} testId='dataset-link' />
                </div>
                <div className={styles.datasetLinkDropdown}>
                    <Select
                        className={styles.dropdownField}
                        classNamePrefix='ark-dropdown'
                        isDisabled={definitionType === null}
                        value={definitionValue}
                        onChange={linkToDataset}
                        options={options}
                        isClearable={true}
                        styles={customStyles}
                        fontWeight={500}
                    />
                </div>
            </div>
        </ConfirmationModal>
    );
};
