import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useSingleHiddenDocumentFields } from '../../../../../../hooks/useHiddenSingleFields';
import { GroupDatasetField } from '../../../../../datasets/store';
import { DropdownOption } from '../../../../../shared/dropdown/Dropdown';
import { fieldTypeWidths, MAX_GROUP_WIDTH } from '../../../FieldList';
import { DatasetsOpenFieldSection, DocumentSpecificHiddenFields } from '../../../store';
import { DocumentDatasetSingleField } from './DocumentDatasetSingleField';
import styles from '../../ConfigureDatasets.module.scss';

interface DocumentDatasetGroupFieldProps {
    field: GroupDatasetField;
    groupIndex: number;
    sectionId: string;
    datasetId: number;
    parents: number;
    toggleSection: (fieldSection: DatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: DatasetsOpenFieldSection) => boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: DocumentSpecificHiddenFields;
    hiddenDocumentNameIds?: number[] | undefined;
    documentNames: DropdownOption[];
    previewSelectedDocument: number | null;
    isLastFieldInSection?: boolean;
}

export const DocumentDatasetGroupField: React.FC<DocumentDatasetGroupFieldProps> = ({ field, groupIndex, sectionId, datasetId, parents, toggleSection, getSectionOpen, isPreview, modalInstance, hiddenFields, hiddenDocumentNameIds, documentNames, previewSelectedDocument, isLastFieldInSection }) => {

    const { children } = field;
    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenDocumentFields(currentHiddenDatasetFields);

    const childFieldHidden = useCallback((id: string) => {
        const documentsHiddenForField = hiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds || [];
        return !isNull(previewSelectedDocument) && documentsHiddenForField.includes((previewSelectedDocument)) && !!hiddenSingleFields.find(({ fieldId }) => fieldId === id);
    }, [hiddenSingleFields, previewSelectedDocument]);

    const childrenWithoutHiddenFields = useMemo(() => children.filter(({ id }) => !hiddenSingleFields.map(({ fieldId }) => fieldId).includes(id!)), [children, hiddenSingleFields]);
    const childArray = useMemo(() => isPreview ? childrenWithoutHiddenFields : children, [isPreview, childrenWithoutHiddenFields, children]);

    const groupWidth = useMemo(() => childArray.reduce((acc, cur) => acc + (fieldTypeWidths[cur.type] / MAX_GROUP_WIDTH), 0), [childArray]);

    return (
        <div className={styles.groupFieldWrapper} data-testid={`form-groupfield-${groupIndex}-wrapper`}>
            {children.map((field, index) => {
                const childHidden = childFieldHidden(field.id!);
                if (isPreview && childHidden) {
                    return null;
                }
                const isLastFieldInGroup = index === childArray.length - 1;
                const width = fieldTypeWidths[field.type] / groupWidth * 0.98;
                return (
                    <DocumentDatasetSingleField
                        key={field.id}
                        field={field}
                        isLastFieldInGroup={isLastFieldInGroup}
                        groupIndex={groupIndex}
                        width={width}
                        sectionId={sectionId}
                        datasetId={datasetId}
                        parents={parents}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        isPreview={isPreview}
                        modalInstance={modalInstance}
                        hiddenFields={hiddenFields}
                        hiddenDocumentNameIds={hiddenDocumentNameIds}
                        documentNames={documentNames}
                        previewSelectedDocument={previewSelectedDocument}
                        isLastFieldInSection={isLastFieldInSection}
                    />
                );
            })}
        </div>
    );
};
