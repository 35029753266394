import { useMemo } from 'react';

import { FieldHighlight } from '../components/datasets/store';
import styles from './Hooks.module.scss';

const { french, primary, green, red, amber, amethyst, ocean, aqua, white, gold } = styles;

export const useFieldHighlightColor = (highlight: FieldHighlight | null | undefined, isTable = false) => {
    const defaultStyle = useMemo(() => isTable ? { backgroundColor: french, color: white } : { backgroundColor: 'transparent', color: primary }, [isTable]);
    const { backgroundColor, color } = useMemo(() => {
        switch (highlight) {
            case FieldHighlight.FRENCH:
                return { backgroundColor: french, color: white };
            case FieldHighlight.GREEN:
                return { backgroundColor: green, color: primary };
            case FieldHighlight.AMBER:
                return { backgroundColor: amber, color: primary };
            case FieldHighlight.GOLD:
                return { backgroundColor: gold, color: primary };
            case FieldHighlight.RED:
                return { backgroundColor: red, color: primary };
            case FieldHighlight.AMETHYST:
                return { backgroundColor: amethyst, color: white };
            case FieldHighlight.AQUA:
                return { backgroundColor: aqua, color: primary };
            case FieldHighlight.OCEAN:
                return { backgroundColor: ocean, color: white };
            default:
                return defaultStyle;
        }
    }, [highlight, defaultStyle]);
    return { backgroundColor, color };
};
