export enum DoraDataManagementActionTypes {
    SET_SELECTED_DORA_MANAGEMENT_TAB = 'SET_SELECTED_DORA_MANAGEMENT_TAB'
}

export enum DataManagementTile {
    FUNCTIONS_PERFORMED = 'Functions Performed',
    MY_COMPANIES = 'My In Scope Companies',
    RELEVANT_THIRD_PARTY_COMPANIES = 'ICT Third-Party Service Providers',
    SUPPLY_CHAIN = 'Supply Chain',
}

export interface DoraDataManagementState {
    selectedTab: DataManagementTile | null;
}
