import React, { useCallback } from 'react';

import { Dropdown } from '../fields/Dropdown';
import { OpinionDropdownField } from '../store';
import styles from './OpinionSection.module.scss';

interface EligibleCollateralRowProps {
    fieldId: string;
    collateral: string;
    fields: OpinionDropdownField[];
    getIsDisabled: (fieldId: string, index: number) => boolean;
    shouldShowFieldUpdated: (fieldId: string, index: number) => boolean;
    updateField: (fieldId: string, index: number, option: string[] | null) => void;
    isCashRow?: boolean;
}

export const EligibleCollateralRow: React.FC<EligibleCollateralRowProps> = ({ fieldId, collateral, fields, getIsDisabled, shouldShowFieldUpdated, updateField, isCashRow = false }) => {
    const id = `eligibleCollateral-${fieldId}`;
    const updateValue = useCallback((index: number, option: string[] | null) => updateField(fieldId, index, option), [updateField, fieldId]);

    if (isCashRow) {
        return (
            <div className={styles.collateralRowWrapper}>
                <div className={styles.collateralCell}>{collateral}</div>
                {fields.map((field, index) => (
                    <div className={styles.dropdownCell} key={index}>
                        <Dropdown
                            dropdownList={field.dropdownList}
                            id={`${id}-${index}`}
                            updateValue={value => updateValue(index, value)}
                            value={field.value}
                            disabled={getIsDisabled(fieldId, index)}
                            showFieldUpdated={shouldShowFieldUpdated(fieldId, index)}
                            hideDropdownIndicator
                        />
                    </div>
                ))}
                {Array(4).fill(1).map((_, i) => (<div key={i} className={styles.emptyCell} />))}
            </div>
        );
    }

    return (
        <div className={styles.collateralRowWrapper}>
            <div className={styles.collateralCell}>{collateral}</div>
            <div className={styles.emptyCell} />
            {fields.map((field, index) => (
                <div className={styles.dropdownCell} key={index}>
                    <Dropdown
                        dropdownList={field.dropdownList}
                        id={`${id}-${index}`}
                        updateValue={value => updateValue(index, value)}
                        value={field.value}
                        disabled={getIsDisabled(fieldId, index)}
                        showFieldUpdated={shouldShowFieldUpdated(fieldId, index)}
                        hideDropdownIndicator
                    />
                </div>
            ))}
        </div>
    );
};
