import { flow, isEqual, set, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { all, call, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { fetchAllAttestationForms, upsertAttestationForm, deleteAttestationForm, upsertAttestationInstance, fetchAllAttestationInstances, deleteAttestationInstance, updateAttestationInstance, completeAttestationInstance } from '../../../../services/attestation';
import {
    fetchAllAttestationFormsFailed,
    fetchAllAttestationFormsSuccessful,
    saveAttestationFormFailed,
    saveAttestationFormSuccessful,
    setAttestationFormUpdated,
    deleteAttestationFormFailed,
    deleteAttestationFormSuccessful,
    deleteAttestationFormStarted,
    createAttestationSuccessful,
    createAttestationFailed,
    fetchAllAttestationInstancesSuccessful,
    fetchAllAttestationInstancesFailed,
    deleteAttestationInstanceStarted,
    deleteAttestationInstanceSuccessful,
    deleteAttestationInstanceFailed,
    setAttestationInstanceUpdated,
    saveAttestationInstanceSuccessful,
    saveAttestationInstanceFailed,
    removeUserAnswer,
    updateAttestationFormValue,
    toggleAttestationFormBuilderWizardOpen,
    setAttestationPage,
    toggleAttestationManagerWizardOpen,
    toggleAttestationInstanceView,
    fetchAllAttestationInstancesStarted
} from './actions';
import { defaultAttestationForm } from '../utils';
import { getAllAttestationForms, getAllAttestationInstances, getAttestationInstancesColumnSort, getAttestationInstancesCompleted, getAttestationInstancesFilters, getAttestationInstancesPageNumber, getAttestationInstancesPageSize, getNewAttestations, getSelectedAttestationForm, getSelectedAttestationInstance } from './selectors';
import { AdminAttestationActionTypes, AttestationForm, AttestationFormDB, AttestationManager, AttestationPage, AttestationQuestion, AttestationStatus, NewAttestationInstances } from './types';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';

export const stripAttestationForm = (attestationForm: AttestationFormDB): AttestationForm => flow(
    unset('createdDate'),
    unset('createdBy'),
    unset('modifiedDate'),
    unset('modifiedBy'),
    unset('isArchived'),
    unset('clientId')
)(attestationForm);

export function* checkAttestationForm() {
    try {
        let attestationUpdated = false;
        const selectedAttestationForm: AttestationForm = yield select(getSelectedAttestationForm);
        if (selectedAttestationForm.attestationFormId) {
            const allAttestationForms: AttestationFormDB[] = yield select(getAllAttestationForms);
            const existingAttestationForm = stripAttestationForm(allAttestationForms.find(({ attestationFormId }) => attestationFormId === selectedAttestationForm.attestationFormId)!);
            attestationUpdated = !isEqual(selectedAttestationForm, existingAttestationForm);
        } else {
            const emptyAttestationForm = defaultAttestationForm(selectedAttestationForm.attestations[0].id, selectedAttestationForm.userAnswers[0].userAnswerId);
            attestationUpdated = !isEqual(selectedAttestationForm, emptyAttestationForm);
        }
        yield put(setAttestationFormUpdated(attestationUpdated));
    } catch (e) {
        toast.error('Something went wrong when checking if attestation has been updated.');
    }
}

function* attestationFormUpdatedWatcher() {
    yield takeEvery([
        AdminAttestationActionTypes.ADD_ATTESTATION_FORM_USER_ANSWER,
        AdminAttestationActionTypes.REMOVE_ATTESTATION_FORM_USER_ANSWER,
        AdminAttestationActionTypes.UPDATE_ATTESTATION_FORM_USER_ANSWER_LABEL,
        AdminAttestationActionTypes.UPDATE_ATTESTATION_FORM_VALUE,
        AdminAttestationActionTypes.ADD_ATTESTATION_QUESTION,
        AdminAttestationActionTypes.REMOVE_ATTESTATION_QUESTION,
        AdminAttestationActionTypes.UPDATE_ATTESTATION_QUESTION_VALUE,
        AdminAttestationActionTypes.UPDATE_ATTESTATION_QUESTION_CONFIG_VALUE
    ], checkAttestationForm);
}

const checkAttestationUser = (attestation: AttestationQuestion, userAnswerId: string, updatedUserAnswerId: string) => {
    if (attestation.config.userAnswerId === userAnswerId) {
        return set('config.userAnswerId', updatedUserAnswerId, attestation);
    }
    return attestation;
};

export function* updateFormUserAnswers({ payload }: ReturnType<typeof removeUserAnswer>) {
    const selectedAttestationForm: AttestationForm = yield select(getSelectedAttestationForm);
    const firstUserAnswerId = selectedAttestationForm.userAnswers[0].userAnswerId;
    const newAttestations = selectedAttestationForm.attestations.map(attestation => checkAttestationUser(attestation, payload, firstUserAnswerId));
    yield put(updateAttestationFormValue('attestations', newAttestations));
}

function* userAnswerRemovedWatcher() {
    yield takeEvery(AdminAttestationActionTypes.REMOVE_ATTESTATION_FORM_USER_ANSWER, updateFormUserAnswers);
}

export function* attemptSaveAttestationForm() {
    try {
        const attestationForm: AttestationForm = yield select(getSelectedAttestationForm);
        const attestationForms: AttestationFormDB[] = yield call(upsertAttestationForm, attestationForm);
        yield put(saveAttestationFormSuccessful(attestationForms));
        yield put(push('/admin/attestation/form'));
    } catch (e) {
        yield put(saveAttestationFormFailed((e as Error).message));
        toast.error('Unable to save attestation form. Please try again.');
    }
}

function* saveAttestationFormWatcher() {
    yield takeLeading(AdminAttestationActionTypes.SAVE_ATTESTATION_FORM_STARTED, attemptSaveAttestationForm);
}

export function* attemptFetchAllAttestationForms() {
    try {
        const attestationForms: AttestationFormDB[] = yield call(fetchAllAttestationForms);
        yield put(fetchAllAttestationFormsSuccessful(attestationForms));
    } catch (e) {
        yield put(fetchAllAttestationFormsFailed((e as Error).message));
        toast.error('Unable to fetch attestation forms. Please try again.');
    }
}

function* fetchAllAttestationFormsWatcher() {
    yield takeEvery(AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_FORMS_STARTED, attemptFetchAllAttestationForms);
}

export function* attemptDeleteAttestationForm({ payload }: ReturnType<typeof deleteAttestationFormStarted>) {
    try {
        const attestationForms: AttestationFormDB[] = yield call(deleteAttestationForm, { attestationFormId: payload });
        yield put(deleteAttestationFormSuccessful(attestationForms));
    } catch (e) {
        yield put(deleteAttestationFormFailed((e as Error).message));
        toast.error('Unable to delete attestation form. Please try again.');
    }
}

function* deleteAttestationFormWatcher() {
    yield takeLeading(AdminAttestationActionTypes.DELETE_ATTESTATION_FORM_STARTED, attemptDeleteAttestationForm);
}

export function* attemptCreateAttestation() {
    try {
        const newAttestations: NewAttestationInstances = yield select(getNewAttestations);
        const filters: TableFilters = yield select(getAttestationInstancesFilters);
        const columnSort: ColumnSort | undefined = yield select(getAttestationInstancesColumnSort);
        const pageSize: number = yield select(getAttestationInstancesPageSize);
        const pageNumber: number = yield select(getAttestationInstancesPageNumber);
        const completed: boolean = yield select(getAttestationInstancesCompleted);
        const { attestations, total }: { attestations: AttestationManager[]; total: number } = yield call(upsertAttestationInstance, { newAttestations, completed, pageNumber, pageSize, filters, columnSort });
        yield put(createAttestationSuccessful(attestations, total));
        toast('Successfully created attestation and assigned to users specified.');
    } catch (e) {
        yield put(createAttestationFailed((e as Error).message));
        toast.error('Unable to create attestation instance. Please try again.');
    }
}

function* createAttestationWatcher() {
    yield takeLeading(AdminAttestationActionTypes.CREATE_ATTESTATION_STARTED, attemptCreateAttestation);
}

export function* attemptFetchAllAttestationInstances({ payload }: ReturnType<typeof fetchAllAttestationInstancesStarted>) {
    try {
        const { completed, pageNumber } = payload;
        const filters: TableFilters = yield select(getAttestationInstancesFilters);
        const columnSort: ColumnSort | undefined = yield select(getAttestationInstancesColumnSort);
        const pageSize: number = yield select(getAttestationInstancesPageSize);
        const { attestations, total }: { attestations: AttestationManager[]; total: number } = yield call(fetchAllAttestationInstances, { completed, pageNumber, pageSize, filters, columnSort });
        yield put(fetchAllAttestationInstancesSuccessful(attestations, total));
    } catch (e) {
        yield put(fetchAllAttestationInstancesFailed((e as Error).message));
        toast.error('Unable to fetch attestation instances. Please try again.');
    }
}

function* fetchAllAttestationInstancesWatcher() {
    yield takeLatest(AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_INSTANCES_STARTED, attemptFetchAllAttestationInstances);
}

export function* attemptDeleteAttestationInstance({ payload }: ReturnType<typeof deleteAttestationInstanceStarted>) {
    try {
        const filters: TableFilters = yield select(getAttestationInstancesFilters);
        const columnSort: ColumnSort | undefined = yield select(getAttestationInstancesColumnSort);
        const pageSize: number = yield select(getAttestationInstancesPageSize);
        const pageNumber: number = yield select(getAttestationInstancesPageNumber);
        const completed: boolean = yield select(getAttestationInstancesCompleted);
        const { attestations, total }: { attestations: AttestationManager[]; total: number } = yield call(deleteAttestationInstance, { attestationInstanceId: payload, pageSize, pageNumber, filters, columnSort, completed });
        yield put(deleteAttestationInstanceSuccessful(attestations, total));
    } catch (e) {
        yield put(deleteAttestationInstanceFailed((e as Error).message));
        toast.error('Unable to delete attestation instance. Please try again.');
    }
}

function* deleteAttestationInstanceWatcher() {
    yield takeLeading(AdminAttestationActionTypes.DELETE_ATTESTATION_INSTANCE_STARTED, attemptDeleteAttestationInstance);
}

export function* checkAttestationInstance() {
    try {
        const selectedAttestation: AttestationManager = yield select(getSelectedAttestationInstance);
        const allAttestations: AttestationManager[] = yield select(getAllAttestationInstances);
        const existingAttestation = allAttestations.find(({ attestationInstance: { attestationInstanceId } }) => attestationInstanceId === selectedAttestation.attestationInstance.attestationInstanceId)!;
        const attestationUpdated = !isEqual(selectedAttestation, existingAttestation);
        yield put(setAttestationInstanceUpdated(attestationUpdated));
    } catch (e) {
        toast.error('Something went wrong when checking if attestation instance has been updated.');
    }
}

function* attestationInstanceUpdatedWatcher() {
    yield takeEvery([AdminAttestationActionTypes.UPDATE_ATTESTATION_DEADLINE, AdminAttestationActionTypes.UPDATE_ATTESTATION_USER_STATUS], checkAttestationInstance);
}

export function* attemptSaveAttestationInstance() {
    try {
        const attestation: AttestationManager = yield select(getSelectedAttestationInstance);
        const filters: TableFilters = yield select(getAttestationInstancesFilters);
        const columnSort: ColumnSort | undefined = yield select(getAttestationInstancesColumnSort);
        const pageSize: number = yield select(getAttestationInstancesPageSize);
        const pageNumber: number = yield select(getAttestationInstancesPageNumber);
        const completed: boolean = yield select(getAttestationInstancesCompleted);
        const allUsersAcceptedComplete = attestation.attestationStatus.every(({ status }) => status === AttestationStatus.COMPLETED);
        const serviceCall = allUsersAcceptedComplete ? completeAttestationInstance : updateAttestationInstance;
        const { attestations, total }: { attestations: AttestationManager[]; total: number } = yield call(serviceCall, { attestation, pageSize, pageNumber, columnSort, filters, completed });
        yield put(saveAttestationInstanceSuccessful(attestations, total));
        yield put(push('/admin/attestation/manager'));
    } catch (e) {
        yield put(saveAttestationInstanceFailed((e as Error).message));
        toast.error('Unable to save attestation instance. Please try again.');
    }
}

function* saveAttestationInstanceWatcher() {
    yield takeLeading(AdminAttestationActionTypes.SAVE_ATTESTATION_INSTANCE_STARTED, attemptSaveAttestationInstance);
}

export function* redirectAttestationTab({ payload }: ReturnType<typeof setAttestationPage>) {
    if (payload !== AttestationPage.SELECT) {
        const navigationPath = `/admin/attestation/${payload === AttestationPage.FORMS ? 'form' : 'manager'}`;
        yield put(push(navigationPath));
    }
}

function* redirectAttestationTabWatcher() {
    yield takeEvery(AdminAttestationActionTypes.SELECT_ATTESTATION_PAGE, redirectAttestationTab);
}

export function* redirectAttestationForm({ payload }: ReturnType<typeof toggleAttestationFormBuilderWizardOpen>) {
    const { attestationForm, isOpen } = payload;
    if (isOpen && attestationForm) {
        const navigationPath = `/admin/attestation/form/${attestationForm.attestationFormId}`;
        yield put(push(navigationPath));
    } else if (!isOpen) {
        yield put(push('/admin/attestation/form'));
    }
}

function* redirectAttestationFormWatcher() {
    yield takeEvery(AdminAttestationActionTypes.TOGGLE_ATTESTATION_FORM_BUILDER_WIZARD_OPEN, redirectAttestationForm);
}

export function* redirectAttestationManager({ payload }: ReturnType<typeof toggleAttestationManagerWizardOpen>) {
    const { attestationInstance, isOpen } = payload;
    const completed: boolean = yield select(getAttestationInstancesCompleted);
    if (isOpen && attestationInstance) {
        const navigationPath = `/admin/attestation/manager/${completed ? 'completed/' : ''}${attestationInstance.attestationInstance.attestationInstanceId}`;
        yield put(push(navigationPath));
    } else if (!isOpen) {
        yield put(push(`/admin/attestation/manager${completed ? '/completed' : ''}`));
    }
}

function* redirectAttestationManagerWatcher() {
    yield takeEvery(AdminAttestationActionTypes.TOGGLE_ATTESTATION_MANAGER_WIZARD_OPEN, redirectAttestationManager);
}

export function* redirectAttestationCompletedTab({ payload }: ReturnType<typeof toggleAttestationInstanceView>) {
    const { completed, attestationInstanceId } = payload;
    const navigationPath = `/admin/attestation/manager${completed ? '/completed' : ''}${attestationInstanceId ? `/${attestationInstanceId}` : ''}`;
    yield put(push(navigationPath));
}

function* redirectAttestationCompletedTabWatcher() {
    yield takeEvery(AdminAttestationActionTypes.TOGGLE_ATTESTATION_INSTANCE_VIEW, redirectAttestationCompletedTab);
}

export function* instancesPaginationNext() {
    const pageNumber: number = yield select(getAttestationInstancesPageNumber);
    const completed: boolean = yield select(getAttestationInstancesCompleted);
    yield put(fetchAllAttestationInstancesStarted(completed, pageNumber + 1));
}

function* instancesPaginationNextWatcher() {
    yield takeEvery(AdminAttestationActionTypes.ATTESTATION_INSTANCES_PAGINATION_NEXT, instancesPaginationNext);
}

export function* instancesPaginationPrevious() {
    const pageNumber: number = yield select(getAttestationInstancesPageNumber);
    const completed: boolean = yield select(getAttestationInstancesCompleted);
    yield put(fetchAllAttestationInstancesStarted(completed, pageNumber - 1));
}

function* instancesPaginationPreviousWatcher() {
    yield takeEvery(AdminAttestationActionTypes.ATTESTATION_INSTANCES_PAGINATION_PREVIOUS, instancesPaginationPrevious);
}

export function* instancesTableConfigUpdated() {
    const completed: boolean = yield select(getAttestationInstancesCompleted);
    yield put(fetchAllAttestationInstancesStarted(completed, 1));
}

function* instancesTableConfigUpdatedWatcher() {
    yield takeEvery([AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_TABLE_FILTERS, AdminAttestationActionTypes.CLEAR_ATTESTATION_INSTANCES_TABLE_FILTERS, AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_TABLE_COLUMN_SORT, AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_PAGE_SIZE], instancesTableConfigUpdated);
}

export function* adminAttestationsSaga() {
    yield all([
        fork(attestationFormUpdatedWatcher),
        fork(userAnswerRemovedWatcher),
        fork(saveAttestationFormWatcher),
        fork(fetchAllAttestationFormsWatcher),
        fork(deleteAttestationFormWatcher),
        fork(createAttestationWatcher),
        fork(fetchAllAttestationInstancesWatcher),
        fork(deleteAttestationInstanceWatcher),
        fork(attestationInstanceUpdatedWatcher),
        fork(saveAttestationInstanceWatcher),
        fork(redirectAttestationTabWatcher),
        fork(redirectAttestationFormWatcher),
        fork(redirectAttestationManagerWatcher),
        fork(redirectAttestationCompletedTabWatcher),
        fork(instancesPaginationNextWatcher),
        fork(instancesPaginationPreviousWatcher),
        fork(instancesTableConfigUpdatedWatcher)
    ]);
}
