import { NewUser } from '../components/admin/users/store';
import { CustomFilters, UserDefaults } from '../components/auth/login/store';
import { api } from './services';

type UpsertUserRequest = { newUser: NewUser; };
type ForgotUsernameRequest = { email: string; };
type ForgotPasswordRequest = { username: string; };
type DeleteUserRequest = { userId: number; clientId?: number; };

interface CheckUsernameRequest {
    username: string;
    userId?: number;
}

export const upsertUser = async (reqBody: UpsertUserRequest) => await api('users/upsert', { ...reqBody }, 'post');
export const checkUsername = async (reqBody: CheckUsernameRequest) => await api('users/checkUsernames', { ...reqBody }, 'post');
export const fetchAllUsers = async () => await api('users/getAll', {}, 'get');
export const fetchAllBasicUsers = async () => await api('users/basic/getAll', {}, 'get');
export const deleteUser = async (reqBody: DeleteUserRequest) => await api('users/delete', { ...reqBody }, 'post');

export const forgotUsername = async (reqBody: ForgotUsernameRequest) => await api('users/forgotUsername', { ...reqBody }, 'post');
export const forgotPassword = async (reqBody: ForgotPasswordRequest) => await api('users/forgotPassword', { ...reqBody }, 'post');
export const resetPassword = async (reqBody: ForgotPasswordRequest) => await api('users/resetPassword', { ...reqBody }, 'post');

export const updateMyProfile = async (reqBody: FormData) => await api('users/profile/upsert', reqBody, 'post', {}, true);

export const fetchAvailableFeaturePermissions = async () => await api('users/permissions/getAll', {}, 'get');

export const updateCustomFilters = async (reqBody: { customFilters: CustomFilters[] }) => await api('users/customFilters/update', { ...reqBody }, 'post');

export const updateUserDefaults = async (reqBody: UserDefaults) => await api('users/userDefaults/update', { ...reqBody }, 'post');
