import { action } from 'typesafe-actions';

import { DropdownList, DropdownListDB, DropdownListsActionTypes } from './types';

export const toggleModal = (isOpen: boolean, dropdownList?: DropdownList) => action(DropdownListsActionTypes.TOGGLE_MODAL, { isOpen, dropdownList });

export const togglePreviewModal = (isOpen: boolean) => action(DropdownListsActionTypes.TOGGLE_PREVIEW_MODAL, isOpen);

export const updateDropdownList = (key: string, value: string | string[]) => action(DropdownListsActionTypes.UPDATE_DROPDOWN_LIST, { key, value });

export const addListItem = () => action(DropdownListsActionTypes.ADD_LIST_ITEM);
export const updateListItem = (index: number, value: string) => action(DropdownListsActionTypes.UPDATE_LIST_ITEM, { index, value });
export const removeListItem = (index: number) => action(DropdownListsActionTypes.REMOVE_LIST_ITEM, index);

export const publishDropdownListStarted = () => action(DropdownListsActionTypes.PUBLISH_DROPDOWN_LIST_STARTED);
export const publishDropdownListSuccessful = (dropdownLists: DropdownListDB[]) => action(DropdownListsActionTypes.PUBLISH_DROPDOWN_LIST_SUCCESSFUL, dropdownLists);
export const publishDropdownListFailed = (error: string) => action(DropdownListsActionTypes.PUBLISH_DROPDOWN_LIST_FAILED, error);

export const fetchAllDropdownListsStarted = () => action(DropdownListsActionTypes.FETCH_ALL_DROPDOWN_LISTS_STARTED);
export const fetchAllDropdownListsSuccessful = (dropdownLists: DropdownListDB[]) => action(DropdownListsActionTypes.FETCH_ALL_DROPDOWN_LISTS_SUCCESSFUL, dropdownLists);
export const fetchAllDropdownListsFailed = (error: string) => action(DropdownListsActionTypes.FETCH_ALL_DROPDOWN_LISTS_FAILED, error);
