import React from 'react';

export const Information: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg width={width || '1em'} height={height || '1em'} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" xmlSpace="preserve">
        <g>
            <path fill={color || 'currentColor'} d="M74.4,94.5H53.6c-1,0-1.7-0.8-1.8-1.7c0-0.1,0-7.3,0-7.1c0-1,0.8-1.8,1.8-1.8h3.1V62.5h-3.1c-1,0-1.7-0.8-1.8-1.7
                c0-0.1,0-7.2,0-7.1c0-1,0.8-1.7,1.7-1.8c0.2,0,15.7,0,15.9,0c1,0,1.7,0.8,1.8,1.7c0,0,0,0,0,0v30.3h3.1c1,0,1.8,0.8,1.8,1.8v7.2
                C76.1,93.8,75.3,94.5,74.4,94.5z M55.4,91h17.2v-3.6h-3.1c-1,0-1.7-0.8-1.8-1.7c0,0,0,0,0,0V55.3H55.4V59h3.1c1,0,1.7,0.8,1.8,1.7
                c0,0.2,0,24.8,0,24.9c0,1-0.8,1.7-1.7,1.8c-0.1,0-3.2,0-3.1,0V91z"/>
            <path fill={color || 'currentColor'} d="M64.1,48.9c-4.3,0-7.8-3.4-7.8-7.7c0-4.3,3.4-7.8,7.7-7.8s7.8,3.4,7.8,7.7c0,0,0,0,0,0C71.7,45.4,68.3,48.9,64.1,48.9z
                M64.1,37c-2.3-0.1-4.3,1.8-4.3,4.2c-0.1,2.4,1.9,4.4,4.3,4.3c2.3-0.1,4.2-2,4.2-4.3C68.2,38.8,66.3,37,64.1,37z"/>
            <path fill={color || 'currentColor'} d="M64,109.1c-24.9,0-45.1-20.2-45.1-45.1S39.1,18.9,64,18.9s45.1,20.2,45.1,45.1c0,0,0,0,0,0C109,88.9,88.9,109,64,109.1z
                M64,22.4C41,22.4,22.4,41,22.4,64S41,105.6,64,105.6S105.6,87,105.6,64v0C105.5,41,87,22.5,64,22.4z"/>
            <path fill={color || 'currentColor'} d="M4.7,121.1l9.4-24.8C-3.7,68.7,4.2,31.9,31.7,14.1s64.4-9.9,82.2,17.7s9.9,64.4-17.7,82.2c-19.6,12.7-44.9,12.7-64.5,0
                l-24.8,9.4C5.5,123.9,4.1,122.5,4.7,121.1z M32.9,110.5c25.7,17.2,60.4,10.2,77.6-15.5s10.2-60.4-15.5-77.6S34.6,7.2,17.5,32.9
                c-12.6,18.8-12.6,43.3,0,62.1c0.3,0.5,0.4,1.1,0.2,1.6l-8.3,22l22-8.3C31.9,110.1,32.5,110.2,32.9,110.5z"/>
        </g>
    </svg>
);
