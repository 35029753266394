import React, { useCallback, useMemo } from 'react';
import Slider from 'rc-slider';
import { findIndex, findLastIndex, indexOf, max, min, set } from 'lodash/fp';

import 'rc-slider/assets/index.css';
import styles from './HistoricalInstances.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getIsSaving, DatasetInstanceTimeline, TimelineInstance, setTimelineInstance, getCurrentInstances, getParentDatasetId, getIsDeleting } from '../store';
import { formatDate } from '../../../../utils/luxon';
import { Ellipsis } from '../../../shared/icons';
import { Icon } from '../../../shared/icon/Icon';
import { InstanceTooltip } from './InstanceTooltip';
import { AnalysisView, DocumentType, getDocumentAnalysisView } from '../../../documents/my-documents/store';

const { french, white, grey, restatementGrey, primary } = styles;
interface MarkerProps {
    datasetInstance: TimelineInstance;
    showDate: boolean;
}

const Marker: React.FC<MarkerProps> = ({ datasetInstance, showDate }) => {
    const date = formatDate(datasetInstance.executedDate);
    if (showDate) {
        return (
            <InstanceTooltip datasetInstance={datasetInstance}>
                <div className={styles.marker}>{date}</div>
            </InstanceTooltip>
        );
    }
    return (
        <InstanceTooltip datasetInstance={datasetInstance}>
            <div className={styles.iconWrapper}>
                <Icon icon={Ellipsis} fontSize={20} />
            </div>
        </InstanceTooltip>
    );
};

interface HistoricalInstancesProps {
    timeline: DatasetInstanceTimeline;
    preventRedirect?: boolean;
}

export const HistoricalInstances: React.FC<HistoricalInstancesProps> = ({ timeline, preventRedirect = false }) => {
    const { instances, originalDocumentId } = timeline;
    const dispatch = useAppDispatch();
    const allInstances = useAppSelector(getCurrentInstances);
    const parentDatasetId = useAppSelector(getParentDatasetId);
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);

    const maxMarkers = useMemo(() => documentAnalysisView === AnalysisView.SPLIT ? 6 : 12, [documentAnalysisView]);
    const showMarkerDate = useCallback((numberOfMarks: number, extremityDate: boolean) => numberOfMarks < maxMarkers || extremityDate, [maxMarkers]);

    const selectedInstance = useMemo(() => allInstances.find(({ instance: { datasetId } }) => datasetId === parentDatasetId)?.instance || null, [allInstances, parentDatasetId]);

    const isSaving = useAppSelector(getIsSaving);
    const isDeleting = useAppSelector(getIsDeleting);

    const mapAllInstances = useCallback((instances: TimelineInstance[]) => instances.reduce((acc, datasetInstance, index) => {
        const numberOfMarks = instances.length;
        const key = index + 1;
        const extremityDate = key === 1 || key === numberOfMarks;
        const showDate = showMarkerDate(numberOfMarks, extremityDate);
        return set(key,
            <Marker
                datasetInstance={datasetInstance}
                showDate={showDate}
            />,
            acc);
    }, {}), [showMarkerDate]);
    const historicalInstances = useMemo(() => mapAllInstances(instances), [instances, mapAllInstances]);

    const minValue = min((Object.keys(historicalInstances).map(val => parseInt(val))));
    const maxValue = max((Object.keys(historicalInstances).map(val => parseInt(val))));
    const minimum = minValue && maxValue !== minValue ? minValue : 0;
    const selected = useMemo(() => instances.find(({ datasetInstanceId }) => selectedInstance?.datasetInstanceId === datasetInstanceId), [instances, selectedInstance]);
    const value = useMemo(() => selectedInstance?.datasetInstanceId ? indexOf(selected, instances) + 1 : maxValue, [selectedInstance, selected, maxValue, instances]);

    const onChange = (value: number | number[]) => {
        const index = (value as number) - 1;
        const clickedInstance = instances[index];
        if (clickedInstance && clickedInstance.datasetInstanceId !== selectedInstance?.datasetInstanceId) {
            dispatch(setTimelineInstance(clickedInstance, originalDocumentId, preventRedirect));
        }
    };

    const getTrackStyle = useMemo(() => {
        const indexOfRestatement = findLastIndex(({ documentType }) => documentType && documentType === DocumentType.RESTATEMENT)(instances);
        const indexOfNonLegacy = findIndex(({ isLegacy }) => !isLegacy)(instances);
        const selectedIndex = indexOf(selected, instances);
        const legacyPercentage = indexOfNonLegacy ? (indexOfNonLegacy / selectedIndex) * 100 : 0;
        const restatementPercentage = indexOfRestatement > 0 ? ((indexOfRestatement - indexOfNonLegacy) / selectedIndex) * 100 : 0;
        const remainingPercentage = 100 - (restatementPercentage + legacyPercentage);
        if (legacyPercentage && restatementPercentage) {
            return {
                background: `linear-gradient(
                    90deg, ${primary} ${legacyPercentage}%,
                    ${restatementGrey} ${legacyPercentage}%,
                    ${restatementGrey} ${restatementPercentage + legacyPercentage}%,
                    ${french} ${restatementPercentage + legacyPercentage}%,
                    ${french} 100%
                )`,
                height: '6px'
            };
        }
        if (legacyPercentage) {
            return { backgroundImage: `linear-gradient(90deg, ${primary} ${legacyPercentage}%, ${french} ${remainingPercentage}%)`, height: '6px' };
        }
        if (restatementPercentage) {
            return { backgroundImage: `linear-gradient(90deg, ${restatementGrey} ${restatementPercentage}%, ${french} ${remainingPercentage}%)`, height: '6px' };
        }
        return { backgroundColor: french, height: '6px' };
    }, [instances, selected]);

    return (
        <div className={styles.historicalSliderWrapper}>
            {instances.length && !isSaving && !isDeleting ?
                <Slider
                    min={minimum}
                    marks={historicalInstances}
                    onChange={onChange}
                    defaultValue={maxValue}
                    value={value}
                    max={maxValue}
                    step={1}
                    trackStyle={getTrackStyle}
                    railStyle={{
                        backgroundColor: grey,
                        height: '6px'
                    }}
                    handleStyle={{
                        backgroundColor: white,
                        border: `1px solid ${french}`,
                        height: '16px',
                        width: '16px',
                        marginTop: '-5px',
                        opacity: 1
                    }}
                    dotStyle={{
                        backgroundColor: white,
                        border: `1px solid ${french}`,
                        height: '12px',
                        width: '12px',
                        top: '-3px'
                    }}
                /> : <div style={{ height: '14px' }} />}
        </div>
    );
};
