import { isArray, isNull, isUndefined } from 'lodash/fp';

import { OpinionInstanceContent, OpinionInstanceField, OpinionInstanceFieldType, OpinionInstanceType, createDefaultInstanceContent } from '../../instances/store';
import { OpinionAverage, OpinionAverageSortColumn, OpinionSingleField } from './types';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort, SortOrder } from '../../../shared/table/ArkTable';
import { OpinionScope } from '../../../admin/opinions/store';

export const getDefaultSingleFieldForScope = (scope: OpinionScope): OpinionSingleField => {
    switch (scope) {
        case OpinionScope.GMRA_NETTING:
        case OpinionScope.GMSLA_NETTING:
        case OpinionScope.GMRA_GMSLA_NETTING:
            return { sectionId: 'earlyCloseOut', fieldId: 'closeOutNettingEnforceable' };
        case OpinionScope.COLLATERAL_PROVIDER:
            return { sectionId: 'securityInterest', fieldId: 'validityRecognised' };
        case OpinionScope.COLLATERAL_TAKER:
            return { sectionId: 'collateralTakerInsolvency', fieldId: 'providerRightsEnforceable' };
        case OpinionScope.ISDA_NETTING:
        default:
            return { sectionId: 'earlyTermination', fieldId: 'closeOutNetting' };
    }
};

const getOpinionInstanceType = (scope: OpinionScope | null): OpinionInstanceType => {
    if (scope === OpinionScope.COLLATERAL_PROVIDER) {
        return OpinionInstanceType.COLLATERAL_PROVIDER;
    }
    if (scope === OpinionScope.COLLATERAL_TAKER) {
        return OpinionInstanceType.COLLATERAL_TAKER;
    }
    if (scope === OpinionScope.GMRA_NETTING) {
        return OpinionInstanceType.REPO;
    }
    if (scope === OpinionScope.GMSLA_NETTING) {
        return OpinionInstanceType.STOCK_LENDING;
    }
    if (scope === OpinionScope.GMRA_GMSLA_NETTING) {
        return OpinionInstanceType.REPO_STOCK_LENDING;
    }
    return OpinionInstanceType.NETTING;
};

const flattenInstanceDropdownDetailsFields = (content: OpinionInstanceContent) => {
    const fields = Object.entries(content).reduce((acc: DropdownOption[], [sectionId, fields]) => {
        Object.entries(fields).forEach(([fieldId, fieldContent]) => {
            if (!isArray(fieldContent)) {
                const singleField = fieldContent as OpinionInstanceField;
                if (singleField.type === OpinionInstanceFieldType.DROPDOWN_DETAILS) {
                    acc.push({ value: `${sectionId}---${fieldId}`, label: singleField.label });
                }
            }
        });
        return acc;
    }, []);
    return fields;
};

export const getAvailableDropdownDetailsFieldsForScope = (scope: OpinionScope) => {
    const instanceType = getOpinionInstanceType(scope);
    const content = createDefaultInstanceContent(instanceType);
    return flattenInstanceDropdownDetailsFields(content);
};

const THRESHOLD_DIFFERENCE = 0.2;
const filterOpinionsWithAverage = (opinionsWithAverage: OpinionAverage[], filters: TableFilters) => {
    let opinions = opinionsWithAverage;
    Object.entries(filters).forEach(([key, value]) => {
        if (key === 'average') {
            if (!isNull(value.dropdown)) {
                const averageOptions = value.dropdown.map(value => ([parseInt(value), parseInt(value) - THRESHOLD_DIFFERENCE]));
                opinions = opinions.filter(({ average }) => averageOptions.some(([topValue, bottomValue]) => average <= topValue && average >= bottomValue));
            }

        }
        if (key === 'bespoke') {
            if (!isNull(value.dropdown)) {
                opinions = opinions.filter(({ bespoke }) => value.dropdown!.map(bespokeString => parseInt(bespokeString)).includes(bespoke));
            }
        }
        if (key === 'jurisdiction') {
            if (!isNull(value.dropdown)) {
                opinions = opinions.filter(({ jurisdiction }) => value.dropdown!.includes(jurisdiction));
            } else {
                opinions = opinions.filter(({ jurisdiction }) => jurisdiction.includes(value.text));
            }
        }
        if (key === 'commissionedBy') {
            if (!isNull(value.dropdown)) {
                opinions = opinions.filter(({ commissionedBy }) => value.dropdown!.includes(commissionedBy));
            } else {
                opinions = opinions.filter(({ commissionedBy, commissionedByIfOther }) => commissionedBy.includes(value.text) || (!isNull(commissionedByIfOther) && commissionedByIfOther.includes(value.text)));
            }
        }
        if (key === 'focus') {
            opinions = opinions.filter(({ focus }) => focus.includes(value.text));
        }
    });
    return opinions;
};

const sortOpinionsWithAverage = (opinions: OpinionAverage[], sortField: keyof OpinionAverageSortColumn, sortOrder: SortOrder) => {
    if (sortOrder === SortOrder.ASCENDING) {
        if (typeof opinions[0][sortField] === 'string') {
            return opinions.sort((a, b) => (a[sortField] as string).localeCompare((b[sortField] as string)));
        }
        return opinions.sort((a, b) => (a[sortField] as number) - (b[sortField] as number));
    }
    if (typeof opinions[0][sortField] === 'string') {
        return opinions.sort((a, b) => (b[sortField] as string).localeCompare((a[sortField] as string)));
    }
    return opinions.sort((a, b) => (b[sortField] as number) - (a[sortField] as number));
};

export const filterAndSortOpinionsWithAverage = (opinionsWithAverage: OpinionAverage[], filters: TableFilters, sort?: ColumnSort) => {
    const filtered = filterOpinionsWithAverage(opinionsWithAverage, filters);
    if (isUndefined(sort) || filtered.length === 0) {
        return filtered;
    }
    const sortField = sort.field as keyof OpinionAverageSortColumn;
    const sortOrder = sort.order;
    const sorted = sortOpinionsWithAverage(filtered, sortField, sortOrder);
    return sorted;
};
