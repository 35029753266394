import React from 'react';
import { Options } from 'react-select';

import styles from './CreateAttestationInstancesTable.module.scss';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';
import { SchedulerInterval } from '../../admin/workflow/store';

interface IntervalOptions {
    value: SchedulerInterval;
    label: string;
}

interface IntervalValueOptions {
    value: string;
    label: string;
}

interface CreateAttestationDeadlineCellProps {
    testId: string;
    isEditing: boolean;
    intervalValueOptions: IntervalValueOptions[];
    intervalOptions: IntervalOptions[];
    index: number;
    interval: (index: number) => IntervalValueOptions | null;
    intervalValue: (index: number) => IntervalValueOptions | null;
    updateDeadlineIntervalValue: (index: number, value: DropdownOption | Options<DropdownOption> | null) => void;
    updateDeadlineInterval: (index: number, value: DropdownOption | Options<DropdownOption> | null) => void;
}

export const CreateAttestationDeadlineCell: React.FC<CreateAttestationDeadlineCellProps> = ({ testId, isEditing, index, intervalValueOptions, intervalOptions, interval, intervalValue, updateDeadlineInterval, updateDeadlineIntervalValue }) => (
    <div className={styles.rowDeadlineWrapper} style={{ width: '300px' }} data-testid={`${testId}-attestation-instances-table-row-${index}-deadline-wrapper`}>
        <div className={styles.intervalValueDropdown} data-testid={`${testId}-attestation-instances-table-row-${index}-deadline-interval-value-dropdown`}>
            <Dropdown
                options={intervalValueOptions!}
                value={intervalValue(index)}
                onChange={val => updateDeadlineIntervalValue(index, val)}
                menuPortalTarget={document.body}
                disabled={!isEditing}
            />
        </div>
        <div className={styles.intervalDropdown} data-testid={`${testId}-attestation-instances-table-row-${index}-deadline-interval-dropdown`}>
            <Dropdown
                options={intervalOptions!}
                value={interval(index)}
                onChange={val => updateDeadlineInterval(index, val)}
                menuPortalTarget={document.body}
                disabled={!isEditing}
            />
        </div>
    </div>
);
