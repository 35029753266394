import React from 'react';

import { useAppDispatch } from '../../../../hooks/react-redux';
import { removeAgencyDatasetField, toggleAgencyRefModalOpen, updateAgencyDatasetField } from '../store';
import { AgencyDatasetField, DatasetFieldType } from '../../../datasets/store';
import styles from '../SharedBuilder.module.scss';
import { Icon as ArkIcon } from '../../../shared/icon/Icon';
import { Checkbox, Number, Date, Text, Delete, Dropdown, Form, Link, Currencies } from '../../../shared/icons';
import { Ref } from '../dataset-components/Ref';
import { AgencyFieldSettings } from './AgencyFieldSettings';

interface AgencyContentProps {
    field: AgencyDatasetField;
    index: number;
}

const fieldListIcons = {
    [DatasetFieldType.TEXT]: Text,
    [DatasetFieldType.NUMBER]: Number,
    [DatasetFieldType.CHECKBOX]: Checkbox,
    [DatasetFieldType.DROPDOWN]: Dropdown,
    [DatasetFieldType.LINKFIELD]: Link,
    [DatasetFieldType.DATE]: Date,
    [DatasetFieldType.WIZARD]: Form,
    [DatasetFieldType.CURRENCY_AMOUNT]: Currencies
};

export const AgencyContent: React.FC<AgencyContentProps> = ({ field, index }) => {
    const { label, description, id, type, refLabel, settings, linkedFields } = field;
    const dispatch = useAppDispatch();

    const updateLabel = (value: string) => dispatch(updateAgencyDatasetField('label', value, index));
    const updateDescription = (value: string) => dispatch(updateAgencyDatasetField('description', value, index));
    const removeRow = () => dispatch(removeAgencyDatasetField(id!));

    const updateRef = (value: string) => dispatch(updateAgencyDatasetField('refLabel', value, index));
    const toggleMenuOpen = (value: boolean) => dispatch(toggleAgencyRefModalOpen(index, value));

    const testId = `draggable-agency-field-item-${index}`;

    const isWizardField = type === DatasetFieldType.WIZARD;
    const disableInputs = !isWizardField && !!linkedFields.length;
    const contentLabel = disableInputs ? linkedFields[0].label : label;
    const contentDescription = disableInputs ? linkedFields[0].description : description;
    const contentRef = disableInputs ? linkedFields[0].refLabel : refLabel;

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.type}>
                <div className={styles.typeLabel} data-testid={testId}>{type}</div>
                <ArkIcon icon={fieldListIcons[type]} fontSize={35} />
            </div>
            <Ref
                modalOpen={false}
                testId={`${testId}-ref`}
                refLabel={contentRef}
                updateRef={updateRef}
                toggleMenuOpen={toggleMenuOpen}
                disableInputs={disableInputs}
            />
            <input
                data-testid={`${testId}-label`}
                className={styles.contentLabel}
                placeholder='Label...'
                onChange={e => updateLabel(e.target.value)}
                value={contentLabel}
                disabled={disableInputs}
            />
            <input
                data-testid={`${testId}-description`}
                className={styles.contentDescription}
                placeholder='Description...'
                onChange={e => updateDescription(e.target.value)}
                value={contentDescription}
                disabled={disableInputs}
            />
            <AgencyFieldSettings
                settings={settings}
                type={type}
                index={index}
                linkedFields={linkedFields}
                testId={`${testId}-settings`}
                disableInputs={disableInputs}
            />
            <button className={styles.removeRowWrapper} onClick={removeRow} data-testid={`${testId}-delete-button`}>
                <ArkIcon icon={Delete} fontSize={20} />
            </button>
        </div>
    );
};
