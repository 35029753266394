import styles from './Calculator.module.scss';
const { red, amber, green } = styles;

export enum CalculatorOptionType {
    DROPDOWN = 'dropdown',
    NUMBER = 'number',
    DECIMAL = 'decimal',
    STANDARD = 'standard',
    TEXT = 'text'
}

export enum CalculationType {
    OUTPUT = 'output',
    GRACE_PERIOD = 'gracePeriod'
}

export enum CalculatorInputType {
    LOGICAL_OPERATOR = 'logical operator',
    MIN_MAX = 'min max',
    BRACKET = 'bracket',
    RATING_TYPE = 'rating type',
    RATING_VALUE = 'rating value',
    RATING_CONDITIONAL = 'rating conditional',
    NEG_WATCH = 'neg watch',
    CONDITIONAL = 'conditional',
    COMPARATOR = 'comparator',
    EQUALS = 'equals',
    FALLS_BY = 'falls by',
    NUMBER = 'number',
    COMMA = 'comma',
    PERCENTAGE = 'percentage',
    CURRENCY = 'currency',
    SHAREHOLDER_EQUITY = 'shareholder equity',
    NAV = 'nav',
    HIGHEST_EVER_NAV = 'highest ever nav',
    NAV_SHAREHOLDER = 'nav per shareholder equity',
    INITIAL_NAV = 'initial nav',
    INITIAL_NAV_SHAREHOLDER = 'initial nav per shareholder equity',
    OBSERVATION_POINT = 'observation point',
    OBSERVATION_PERIOD = 'observation period',
    NOTIONAL_AMOUNT = 'notional amount',
    ADDITIONAL_TERMINATION_EVENT = 'additional termination event',
    AFFECTED_PARTY = 'affected party',
    ORDINAL_NUMBER = 'ordinal number',
    DAY_TYPE = 'day type',
    OPERATIONAL_ADMINISTRATIVE_DEFAULT = 'operation administrative defaults only',
    COB = 'close of business',
    OTHER = 'other',
    EVENT_OF_DEFAULT_ARISES = 'event of default arises',
    TERMINATION_EVENT_ARISES = 'termination event arises',
    FROM = 'from',
    OF = 'of',
    IN_THE_PRECEDING = 'in the preceding',
    NAV_OBSERVATION_PERIOD = 'nav observation period',
    NOT_APPLICABLE = 'not applicable',
    MATURITY = 'maturity',
    MATURITY_PERIOD = 'maturity period',
    MATERIALLY_WEAKER = 'materially weaker',
    INTEREST_RATE = 'interest rate',
    DESIGNATED_MATURITY = 'designated maturity',
    OVERNIGHT = 'overnight',
    DECIMAL = 'decimal',
    SPREAD = 'spread',
    OPERATOR = 'operator',
    FLAT = 'flat',
    PARTY = 'party',
    TIME_PERIOD = 'time period',
    TRUST_PROPERTY = 'trust property',
    TRIGGER = 'trigger',
    CONJUNCTION = 'conjunction',
    DOCUMENT_TYPE = 'document type',
    DEADLINE = 'deadline',
    PERIOD = 'period'
}

export enum CalculatorFieldType {
    CROSS_DEFAULT = 'cross default threshold amounts',
    INDEPENDENT_AMOUNTS = 'independent amounts',
    THRESHOLDS = 'thresholds',
    RATINGS_TRIGGERS = 'ratings triggers',
    NET_ASSET_VALUE_TRIGGERS = 'net asset value triggers',
    MATURITY = 'maturity',
    CREDIT_EVENT_UPON_MERGER = 'credit event upon merger',
    INTEREST_RATES = 'interest rates',
    DOCUMENTS_TO_BE_DELIVERED = 'documents to be delivered',
    DEADLINE = 'deadline',
    TIMESPAN = 'timespan',
    PERCENTAGE_COMPARATOR = 'percentage comparator'
}

export enum LogicalOperator {
    AND = 'AND',
    OR = 'OR'
}

export enum CalculationCondition {
    IF = 'IF',
    ELSE_IF = 'ELSE IF',
    ELSE = 'ELSE'
}

interface CalculationConditionColors {
    [CalculationCondition.IF]: string;
    [CalculationCondition.ELSE_IF]: string;
    [CalculationCondition.ELSE]: string;
}

export const calcConditionColors: CalculationConditionColors = {
    [CalculationCondition.IF]: green,
    [CalculationCondition.ELSE_IF]: amber,
    [CalculationCondition.ELSE]: red
};

export interface CalculatorInput {
    type: CalculatorInputType;
    label: string;
    value: string;
}

export type CalculationBuilder = CalculatorInput[];

export interface InitialCalculation {
    index: 0;
    builder: CalculationBuilder;
}

export interface AdditionalCalculation {
    index: number;
    builder: CalculatorInput[];
    condition: LogicalOperator | null;
}

export type Calculation = InitialCalculation | AdditionalCalculation;

export interface CalculatorField {
    calculationCondition: CalculationCondition | null;
    output: CalculationBuilder;
    calculation: Calculation[];
    gracePeriod: CalculationBuilder;
}

interface CalculatorDropdownOption {
    inputType: CalculatorInputType;
    label: string;
    type: CalculatorOptionType.DROPDOWN,
    dropdownName: string;
}

interface CalculatorStandardOption {
    inputType: CalculatorInputType;
    label: string;
    type: CalculatorOptionType.NUMBER | CalculatorOptionType.STANDARD | CalculatorOptionType.TEXT | CalculatorOptionType.DECIMAL;
}

export type CalculatorOption = CalculatorDropdownOption | CalculatorStandardOption;

export const allInputs: CalculatorOption[] = [
    { inputType: CalculatorInputType.RATING_TYPE, label: 'Moody\'s', type: CalculatorOptionType.DROPDOWN, dropdownName: 'LTST' },
    { inputType: CalculatorInputType.RATING_VALUE, label: 'Moody\'s L-T Rating', type: CalculatorOptionType.DROPDOWN, dropdownName: 'MoodysRatingLT' },
    { inputType: CalculatorInputType.RATING_VALUE, label: 'Moody\'s S-T Rating', type: CalculatorOptionType.DROPDOWN, dropdownName: 'MoodysRatingST' },
    { inputType: CalculatorInputType.RATING_TYPE, label: 'S&P', type: CalculatorOptionType.DROPDOWN, dropdownName: 'LTST' },
    { inputType: CalculatorInputType.RATING_VALUE, label: 'S&P L-T Rating', type: CalculatorOptionType.DROPDOWN, dropdownName: 'SandPRatingLT' },
    { inputType: CalculatorInputType.RATING_VALUE, label: 'S&P S-T Rating', type: CalculatorOptionType.DROPDOWN, dropdownName: 'SandPRatingST' },
    { inputType: CalculatorInputType.RATING_TYPE, label: 'Fitch', type: CalculatorOptionType.DROPDOWN, dropdownName: 'LTST' },
    { inputType: CalculatorInputType.RATING_VALUE, label: 'Fitch L-T Rating', type: CalculatorOptionType.DROPDOWN, dropdownName: 'FitchRatingLT' },
    { inputType: CalculatorInputType.RATING_VALUE, label: 'Fitch S-T Rating', type: CalculatorOptionType.DROPDOWN, dropdownName: 'FitchRatingST' },
    { inputType: CalculatorInputType.RATING_TYPE, label: 'DBRS', type: CalculatorOptionType.DROPDOWN, dropdownName: 'LTST' },
    { inputType: CalculatorInputType.RATING_VALUE, label: 'DBRS L-T Rating', type: CalculatorOptionType.DROPDOWN, dropdownName: 'DBRSRatingLT' },
    { inputType: CalculatorInputType.RATING_VALUE, label: 'DBRS S-T Rating', type: CalculatorOptionType.DROPDOWN, dropdownName: 'DBRSRatingST' },
    { inputType: CalculatorInputType.NEG_WATCH, label: 'Neg Watch', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.COMPARATOR, label: '<', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.COMPARATOR, label: '>', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.COMPARATOR, label: '<=', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.COMPARATOR, label: '>=', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.EQUALS, label: '=', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.EQUALS, label: '<>', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.CURRENCY, label: 'Currency', type: CalculatorOptionType.DROPDOWN, dropdownName: 'Currency Codes' },
    { inputType: CalculatorInputType.NUMBER, label: 'Number', type: CalculatorOptionType.NUMBER },
    { inputType: CalculatorInputType.DECIMAL, label: 'Decimal', type: CalculatorOptionType.DECIMAL },
    { inputType: CalculatorInputType.LOGICAL_OPERATOR, label: 'OR', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.LOGICAL_OPERATOR, label: 'AND', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.PERCENTAGE, label: '%', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.MIN_MAX, label: 'MIN', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.MIN_MAX, label: 'MAX', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.BRACKET, label: '(', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.BRACKET, label: ')', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.COMMA, label: ',', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.NAV, label: 'NAV', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.INITIAL_NAV, label: 'Initial NAV', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.HIGHEST_EVER_NAV, label: 'Highest ever NAV', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.NAV_SHAREHOLDER, label: 'NAV / Share', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.INITIAL_NAV_SHAREHOLDER, label: 'Initial NAV / Share', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.SHAREHOLDER_EQUITY, label: 'Shareholders\' Equity', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.TRUST_PROPERTY, label: 'Trust Property', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.RATING_CONDITIONAL, label: 'If S-T available', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.RATING_CONDITIONAL, label: 'If S-T not available', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.RATING_CONDITIONAL, label: 'If L-T available', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.RATING_CONDITIONAL, label: 'If L-T not available', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.OBSERVATION_POINT, label: 'Observation Point', type: CalculatorOptionType.DROPDOWN, dropdownName: 'NAVMeasurementPoint' },
    { inputType: CalculatorInputType.OBSERVATION_PERIOD, label: 'Observation Period', type: CalculatorOptionType.DROPDOWN, dropdownName: 'ObservationPeriod' },
    { inputType: CalculatorInputType.ORDINAL_NUMBER, label: 'Ordinal Number', type: CalculatorOptionType.DROPDOWN, dropdownName: 'OrdinalNumber' },
    { inputType: CalculatorInputType.DAY_TYPE, label: 'Day Type', type: CalculatorOptionType.DROPDOWN, dropdownName: 'DayType' },
    { inputType: CalculatorInputType.FALLS_BY, label: 'Falls by', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.COB, label: 'Close of Business', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.NOTIONAL_AMOUNT, label: 'Notional Amount', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.ADDITIONAL_TERMINATION_EVENT, label: 'Additional Termination Event', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.AFFECTED_PARTY, label: 'Affected Party', type: CalculatorOptionType.DROPDOWN, dropdownName: 'AffectedParty' },
    { inputType: CalculatorInputType.OPERATIONAL_ADMINISTRATIVE_DEFAULT, label: 'Operational / Administrative defaults only', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.EVENT_OF_DEFAULT_ARISES, label: 'Event of Default arises', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.TERMINATION_EVENT_ARISES, label: 'Termination Event arises', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.FROM, label: 'from', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.OF, label: 'of', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.IN_THE_PRECEDING, label: 'In the preceding', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.NAV_OBSERVATION_PERIOD, label: 'Nav Observation Period', type: CalculatorOptionType.DROPDOWN, dropdownName: 'NAVObservationPeriod' },
    { inputType: CalculatorInputType.OTHER, label: 'Other', type: CalculatorOptionType.TEXT },
    { inputType: CalculatorInputType.NOT_APPLICABLE, label: 'Not Applicable', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.MATURITY, label: 'Maturity', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.MATURITY_PERIOD, label: 'Maturity Period', type: CalculatorOptionType.DROPDOWN, dropdownName: 'NAVObservationPeriod' },
    { inputType: CalculatorInputType.MATERIALLY_WEAKER, label: '"Materially Weaker"', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.INTEREST_RATE, label: 'Interest Rate', type: CalculatorOptionType.DROPDOWN, dropdownName: 'InterestRate' },
    { inputType: CalculatorInputType.DESIGNATED_MATURITY, label: 'Designated Maturity', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.OVERNIGHT, label: 'Overnight', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.TIME_PERIOD, label: 'Time Period', type: CalculatorOptionType.DROPDOWN, dropdownName: 'TimePeriod' },
    { inputType: CalculatorInputType.SPREAD, label: 'Spread', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.OPERATOR, label: '+', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.OPERATOR, label: '-', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.FLAT, label: 'Flat', type: CalculatorOptionType.STANDARD },
    { inputType: CalculatorInputType.PARTY, label: 'Party', type: CalculatorOptionType.DROPDOWN, dropdownName: 'CalculatorParty' },
    { inputType: CalculatorInputType.TRIGGER, label: 'Trigger', type: CalculatorOptionType.DROPDOWN, dropdownName: 'DeliveredTrigger' },
    { inputType: CalculatorInputType.CONJUNCTION, label: 'Conjunction', type: CalculatorOptionType.DROPDOWN, dropdownName: 'DeliveredConjunction' },
    { inputType: CalculatorInputType.DOCUMENT_TYPE, label: 'Document Type', type: CalculatorOptionType.DROPDOWN, dropdownName: 'DeliveredDocumentType' },
    { inputType: CalculatorInputType.DEADLINE, label: 'Deadline', type: CalculatorOptionType.DROPDOWN, dropdownName: 'Deadline' },
    { inputType: CalculatorInputType.PERIOD, label: 'Period', type: CalculatorOptionType.DROPDOWN, dropdownName: 'DeliveredPeriod' }
];

export const freeTextOption = allInputs.filter(({ type }) => type === CalculatorOptionType.TEXT);

export const freeTextOptionAvailable = [CalculatorFieldType.INTEREST_RATES];
export const outputFieldAvailable = [CalculatorFieldType.INDEPENDENT_AMOUNTS, CalculatorFieldType.THRESHOLDS, CalculatorFieldType.RATINGS_TRIGGERS, CalculatorFieldType.NET_ASSET_VALUE_TRIGGERS];
export const gracePeriodAvailable = [CalculatorFieldType.CROSS_DEFAULT, CalculatorFieldType.THRESHOLDS, CalculatorFieldType.RATINGS_TRIGGERS, CalculatorFieldType.NET_ASSET_VALUE_TRIGGERS];
export const additionalConditionsAvailable = [CalculatorFieldType.INDEPENDENT_AMOUNTS, CalculatorFieldType.THRESHOLDS, CalculatorFieldType.RATINGS_TRIGGERS, CalculatorFieldType.NET_ASSET_VALUE_TRIGGERS];
export const ifElseAvailable = [CalculatorFieldType.INDEPENDENT_AMOUNTS, CalculatorFieldType.THRESHOLDS, CalculatorFieldType.RATINGS_TRIGGERS, CalculatorFieldType.NET_ASSET_VALUE_TRIGGERS];

export const symbolInputs = [CalculatorInputType.BRACKET, CalculatorInputType.COMMA, CalculatorInputType.PERCENTAGE, CalculatorInputType.COMPARATOR, CalculatorInputType.EQUALS, CalculatorInputType.OPERATOR];

export const priorityInputFields = [CalculatorInputType.CURRENCY, CalculatorInputType.NUMBER, CalculatorInputType.DECIMAL, CalculatorInputType.MIN_MAX, CalculatorInputType.BRACKET];

const availableCrossDefault = [
    CalculatorInputType.MIN_MAX,
    CalculatorInputType.BRACKET,
    CalculatorInputType.COMMA,
    CalculatorInputType.CURRENCY,
    CalculatorInputType.NUMBER,
    CalculatorInputType.PERCENTAGE,
    CalculatorInputType.NAV,
    CalculatorInputType.SHAREHOLDER_EQUITY,
    CalculatorInputType.TRUST_PROPERTY
];

const availableThresholdsAndRatings = [
    CalculatorInputType.RATING_CONDITIONAL,
    CalculatorInputType.LOGICAL_OPERATOR,
    CalculatorInputType.RATING_TYPE,
    CalculatorInputType.RATING_VALUE,
    CalculatorInputType.NEG_WATCH,
    CalculatorInputType.COMPARATOR,
    CalculatorInputType.EQUALS,
    CalculatorInputType.CONDITIONAL,
    CalculatorInputType.BRACKET,
    CalculatorInputType.CURRENCY,
    CalculatorInputType.NUMBER
];

const availableIndependentAmounts = [
    CalculatorInputType.CURRENCY,
    CalculatorInputType.NUMBER,
    CalculatorInputType.LOGICAL_OPERATOR,
    CalculatorInputType.MIN_MAX,
    CalculatorInputType.BRACKET,
    CalculatorInputType.RATING_CONDITIONAL,
    CalculatorInputType.RATING_TYPE,
    CalculatorInputType.RATING_VALUE,
    CalculatorInputType.NEG_WATCH,
    CalculatorInputType.COMPARATOR,
    CalculatorInputType.EQUALS,
    CalculatorInputType.CONDITIONAL,
    CalculatorInputType.FALLS_BY,
    CalculatorInputType.NOTIONAL_AMOUNT
];

const availableNAVTriggers = [
    CalculatorInputType.MIN_MAX,
    CalculatorInputType.LOGICAL_OPERATOR,
    CalculatorInputType.COMPARATOR,
    CalculatorInputType.EQUALS,
    CalculatorInputType.FALLS_BY,
    CalculatorInputType.BRACKET,
    CalculatorInputType.COMMA,
    CalculatorInputType.CURRENCY,
    CalculatorInputType.NUMBER,
    CalculatorInputType.PERCENTAGE,
    CalculatorInputType.NAV,
    CalculatorInputType.INITIAL_NAV,
    CalculatorInputType.HIGHEST_EVER_NAV,
    CalculatorInputType.NAV_SHAREHOLDER,
    CalculatorInputType.INITIAL_NAV_SHAREHOLDER,
    CalculatorInputType.SHAREHOLDER_EQUITY,
    CalculatorInputType.DAY_TYPE,
    CalculatorInputType.ORDINAL_NUMBER,
    CalculatorInputType.COB,
    CalculatorInputType.EVENT_OF_DEFAULT_ARISES,
    CalculatorInputType.TERMINATION_EVENT_ARISES,
    CalculatorInputType.FROM,
    CalculatorInputType.OF,
    CalculatorInputType.IN_THE_PRECEDING,
    CalculatorInputType.NAV_OBSERVATION_PERIOD
];

const availableMaturity = [
    CalculatorInputType.NUMBER,
    CalculatorInputType.COMPARATOR,
    CalculatorInputType.MATURITY,
    CalculatorInputType.MATURITY_PERIOD
];

const availableCreditEventUponMerger = [
    CalculatorInputType.RATING_TYPE,
    CalculatorInputType.RATING_VALUE,
    CalculatorInputType.NEG_WATCH,
    CalculatorInputType.COMPARATOR,
    CalculatorInputType.EQUALS,
    CalculatorInputType.LOGICAL_OPERATOR,
    CalculatorInputType.MATERIALLY_WEAKER
];

const availableInterestRates = [
    CalculatorInputType.INTEREST_RATE,
    CalculatorInputType.DESIGNATED_MATURITY,
    CalculatorInputType.OVERNIGHT,
    CalculatorInputType.NUMBER,
    CalculatorInputType.DECIMAL,
    CalculatorInputType.PERCENTAGE,
    CalculatorInputType.TIME_PERIOD,
    CalculatorInputType.SPREAD,
    CalculatorInputType.OPERATOR,
    CalculatorInputType.FLAT,
    CalculatorInputType.PARTY
];

const availableDocumentsDelivered = [
    CalculatorInputType.TRIGGER,
    CalculatorInputType.CONJUNCTION,
    CalculatorInputType.DOCUMENT_TYPE,
    CalculatorInputType.DEADLINE,
    CalculatorInputType.NUMBER,
    CalculatorInputType.ORDINAL_NUMBER,
    CalculatorInputType.PERIOD,
    CalculatorInputType.OTHER
];

const availableDeadline = [
    CalculatorInputType.DEADLINE,
    CalculatorInputType.NUMBER,
    CalculatorInputType.ORDINAL_NUMBER,
    CalculatorInputType.PERIOD
];

const availableTimespan = [
    CalculatorInputType.NUMBER,
    CalculatorInputType.TIME_PERIOD
];

const availablePercentageComparator = [
    CalculatorInputType.NUMBER,
    CalculatorInputType.DECIMAL,
    CalculatorInputType.COMPARATOR,
    CalculatorInputType.PERCENTAGE
];

interface AvailableInputFields {
    [CalculatorFieldType.CROSS_DEFAULT]: CalculatorInputType[];
    [CalculatorFieldType.INDEPENDENT_AMOUNTS]: CalculatorInputType[];
    [CalculatorFieldType.THRESHOLDS]: CalculatorInputType[];
    [CalculatorFieldType.RATINGS_TRIGGERS]: CalculatorInputType[];
    [CalculatorFieldType.NET_ASSET_VALUE_TRIGGERS]: CalculatorInputType[];
    [CalculatorFieldType.MATURITY]: CalculatorInputType[];
    [CalculatorFieldType.CREDIT_EVENT_UPON_MERGER]: CalculatorInputType[];
    [CalculatorFieldType.INTEREST_RATES]: CalculatorInputType[];
    [CalculatorFieldType.DOCUMENTS_TO_BE_DELIVERED]: CalculatorInputType[];
    [CalculatorFieldType.DEADLINE]: CalculatorInputType[];
    [CalculatorFieldType.TIMESPAN]: CalculatorInputType[];
    [CalculatorFieldType.PERCENTAGE_COMPARATOR]: CalculatorInputType[];
}

export const availableInputsByFieldType: AvailableInputFields = {
    [CalculatorFieldType.CROSS_DEFAULT]: availableCrossDefault,
    [CalculatorFieldType.INDEPENDENT_AMOUNTS]: availableIndependentAmounts,
    [CalculatorFieldType.THRESHOLDS]: availableThresholdsAndRatings,
    [CalculatorFieldType.RATINGS_TRIGGERS]: availableThresholdsAndRatings,
    [CalculatorFieldType.NET_ASSET_VALUE_TRIGGERS]: availableNAVTriggers,
    [CalculatorFieldType.MATURITY]: availableMaturity,
    [CalculatorFieldType.CREDIT_EVENT_UPON_MERGER]: availableCreditEventUponMerger,
    [CalculatorFieldType.INTEREST_RATES]: availableInterestRates,
    [CalculatorFieldType.DOCUMENTS_TO_BE_DELIVERED]: availableDocumentsDelivered,
    [CalculatorFieldType.DEADLINE]: availableDeadline,
    [CalculatorFieldType.TIMESPAN]: availableTimespan,
    [CalculatorFieldType.PERCENTAGE_COMPARATOR]: availablePercentageComparator
};

const availableForOutput = [
    CalculatorInputType.MIN_MAX,
    CalculatorInputType.BRACKET,
    CalculatorInputType.COMMA,
    CalculatorInputType.CURRENCY,
    CalculatorInputType.NUMBER,
    CalculatorInputType.PERCENTAGE,
    CalculatorInputType.NAV,
    CalculatorInputType.INITIAL_NAV,
    CalculatorInputType.HIGHEST_EVER_NAV,
    CalculatorInputType.NAV_SHAREHOLDER,
    CalculatorInputType.INITIAL_NAV_SHAREHOLDER,
    CalculatorInputType.SHAREHOLDER_EQUITY,
    CalculatorInputType.NOTIONAL_AMOUNT,
    CalculatorInputType.AFFECTED_PARTY,
    CalculatorInputType.EVENT_OF_DEFAULT_ARISES,
    CalculatorInputType.TERMINATION_EVENT_ARISES,
    CalculatorInputType.OTHER
];

const filteredOutput = availableForOutput.filter(inputType => ![CalculatorInputType.EVENT_OF_DEFAULT_ARISES, CalculatorInputType.TERMINATION_EVENT_ARISES].includes(inputType));

export const availableOutputsByFieldType: AvailableInputFields = {
    [CalculatorFieldType.CROSS_DEFAULT]: filteredOutput,
    [CalculatorFieldType.INDEPENDENT_AMOUNTS]: filteredOutput,
    [CalculatorFieldType.THRESHOLDS]: filteredOutput,
    [CalculatorFieldType.RATINGS_TRIGGERS]: availableForOutput,
    [CalculatorFieldType.NET_ASSET_VALUE_TRIGGERS]: availableForOutput,
    [CalculatorFieldType.MATURITY]: filteredOutput,
    [CalculatorFieldType.CREDIT_EVENT_UPON_MERGER]: filteredOutput,
    [CalculatorFieldType.INTEREST_RATES]: filteredOutput,
    [CalculatorFieldType.DOCUMENTS_TO_BE_DELIVERED]: filteredOutput,
    [CalculatorFieldType.DEADLINE]: filteredOutput,
    [CalculatorFieldType.TIMESPAN]: filteredOutput,
    [CalculatorFieldType.PERCENTAGE_COMPARATOR]: filteredOutput
};

export const availableForGracePeriod = [
    CalculatorInputType.NUMBER,
    CalculatorInputType.DAY_TYPE,
    CalculatorInputType.OPERATIONAL_ADMINISTRATIVE_DEFAULT,
    CalculatorInputType.NOT_APPLICABLE
];

export const newCalculatorField = (calculationCondition: CalculationCondition | null = null): CalculatorField => ({
    calculation: [{
        index: 0,
        builder: []
    }],
    calculationCondition,
    output: [],
    gracePeriod: []
});
