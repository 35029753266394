import React, { useCallback, useMemo } from 'react';
import { RawDraftContentState } from 'draft-js';
import { isNull, isUndefined } from 'lodash/fp';

import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import styles from './Fields.module.scss';
import { useAppDispatch } from '../../../../hooks/react-redux';
import { toggleOpenFieldSection, updateDropdownFieldValue, updateWYSIWYGFieldValue } from '../store/actions';
import { WYSIWYGField } from './WYSIWYGField';
import { Dropdown } from './Dropdown';
import { AnswerIndicator } from './AnswerIndicator';
import { DropdownDetailsSignOffConversation } from '../store';
import { SignOffConversationIcon } from '../sign-off';

const { green, red, amber, primary } = styles;

export const defaultEmptyWysiwyg: RawDraftContentState = {
    blocks: [
        {
            key: 'default-placeholder-key',
            text: 'No details provided',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
        }
    ],
    entityMap: {}
};

interface DropdownDetailsProps {
    id: string;
    sectionId: string;
    fieldId: string;
    dropdownValue: string[] | null;
    wysiwygValue: RawDraftContentState | null;
    fieldOpen: boolean;
    disabled: boolean;
    showFieldUpdated: boolean;
    dropdownList: string;
    positiveAnswers?: string[];
    negativeAnswers?: string[];
    signOffConversation?: DropdownDetailsSignOffConversation;
}

export const DropdownDetails: React.FC<DropdownDetailsProps> = ({
    id,
    sectionId,
    fieldId,
    dropdownValue,
    wysiwygValue,
    fieldOpen,
    disabled,
    showFieldUpdated,
    dropdownList,
    positiveAnswers = ['Yes', 'Yes - with exceptions'],
    negativeAnswers = ['No'],
    signOffConversation
}) => {
    const dispatch = useAppDispatch();
    const wysiwygOpenIcon = fieldOpen ? CaretDown : CaretSide;

    const toggleFieldOpen = useCallback(() => { dispatch(toggleOpenFieldSection(id)); }, [dispatch, id]);
    const updateDropdownValue = useCallback((value: string[] | null) => { dispatch(updateDropdownFieldValue(sectionId, fieldId, value)); }, [dispatch, sectionId, fieldId]);
    const updateWYSIWYGValue = useCallback((value: RawDraftContentState | null) => { dispatch(updateWYSIWYGFieldValue(sectionId, fieldId, value)); }, [dispatch, sectionId, fieldId]);

    const { showNone, isPositive, isNegative } = useMemo(() => {
        const showNone = isNull(dropdownValue);
        const isPositive = !isNull(dropdownValue) && dropdownValue.some(value => positiveAnswers?.includes(value));
        const isNegative = !isNull(dropdownValue) && dropdownValue.some(value => negativeAnswers?.includes(value));
        return { showNone, isPositive, isNegative };
    }, [dropdownValue, positiveAnswers, negativeAnswers]);

    const color = useMemo(() => {
        if (showNone || isNull(wysiwygValue)) {
            return primary;
        }
        if (isPositive) {
            return green;
        }
        if (isNegative) {
            return red;
        }
        if (!isNegative && !isPositive) {
            return amber;
        }
    }, [isPositive, isNegative, showNone, wysiwygValue]);

    const detailsLabel = useMemo(() => `Details${isNull(wysiwygValue) ? ' (none)' : ''}`, [wysiwygValue]);

    const wysiwygFieldValue = useMemo(() => disabled && isNull(wysiwygValue) ? defaultEmptyWysiwyg : wysiwygValue, [disabled, wysiwygValue]);

    return (
        <div key={id} className={styles.dropdownDetailsWrapper} data-testid={`opinion-dropdown-details-${id}-wrapper`}>
            <div className={styles.dropdownContentWrapper}>
                <div className={styles.dropdownAndIndicatorWrapper}>
                    <div className={styles.dropdownWrapper}>
                        <Dropdown
                            id={id}
                            dropdownList={dropdownList}
                            value={dropdownValue}
                            updateValue={updateDropdownValue}
                            disabled={disabled}
                            showFieldUpdated={showFieldUpdated}
                            menuPortalTarget={document.body}
                        />
                    </div>
                    <AnswerIndicator showNone={showNone} isPositive={isPositive} isNegative={isNegative} />
                </div>
                {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={id} isDropdownDetailsField />}
            </div>
            <div className={styles.wysiwygDetailsWrapper}>
                {!isNull(dropdownValue) &&
                    <div className={styles.wysiwygHeader} onClick={toggleFieldOpen} data-testid={`opinion-wysiwyg-${id}-collapse`}>
                        <div className={styles.wysiwygOpenIcon}>
                            <Icon icon={wysiwygOpenIcon} fontSize={15} color={color} />
                        </div>
                        <div className={styles.wysiwygLabel} data-testid={`opinion-wysiwyg-${id}-details`}>{detailsLabel}</div>
                    </div>
                }
                {!isNull(dropdownValue) &&
                    <div className={styles.wysiwygContent}>
                        <WYSIWYGField
                            id={id}
                            updateValue={updateWYSIWYGValue}
                            value={wysiwygFieldValue}
                            disabled={disabled}
                            showFieldUpdated={showFieldUpdated}
                            fieldOpen={fieldOpen}
                            toolbarHidden={disabled}
                        />
                    </div>
                }
            </div>
        </div>
    );
};
