import React, { useCallback, useMemo, MouseEvent } from 'react';
import { RawDraftContentState } from 'draft-js';
import { isNull, noop } from 'lodash/fp';
import { Options } from 'react-select';

import { PlaybookDeviation, ValidForInterval } from '../../../admin/playbook/store';
import { Text } from '../../text/Text';
import { WYSIWYG, isEmpty } from '../../wysiwyg/WYSIWYG';
import styles from './PlaybookBuild.module.scss';
import { Scrollable } from '../../scrollable/Scrollable';
import { DeleteButton } from '../../button/DeleteButton';
import { OverflowTooltip } from '../../tooltip';
import { DatePicker } from '../../datepicker/DatePicker';
import { Dropdown, DropdownOption } from '../../dropdown/Dropdown';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { DATABASE_DATE_FORMAT, formatDate } from '../../../../utils/luxon';

const { lightGrey, white, red } = styles;

interface DeviationsProps {
    deviations: PlaybookDeviation[];
    sectionId: string;
    deleteDeviationRow?: (sectionId: string, rowId: string) => void;
    updateDeviationColumn?: (sectionId: string, rowId: string, key: keyof PlaybookDeviation, value: RawDraftContentState | null | string | number) => void;
    showDeleteDeviation: boolean;
    openDeviationActionModal?: (e: MouseEvent<HTMLDivElement>, index: number) => void;
    testId?: string;
}

export const Deviations: React.FC<DeviationsProps> = ({
    deviations,
    sectionId,
    deleteDeviationRow = noop,
    updateDeviationColumn = noop,
    showDeleteDeviation,
    openDeviationActionModal = noop,
    testId = 'playbook'
}) => {

    const updateDeviationWysiwyg = (rowId: string, key: keyof PlaybookDeviation, rawValue: RawDraftContentState) => {
        const deviation = deviations.find(deviation => deviation.rowId === rowId);
        const value = key === 'variations' ? deviation!.variations : deviation!.background;
        if (isEmpty(rawValue)) {
            if (!isNull(value)) {
                updateDeviationColumn(sectionId, rowId, key, null);
            }
        } else {
            updateDeviationColumn(sectionId, rowId, key, rawValue);
        }
    };

    const updateText = useCallback((rowId: string, value: string, key: keyof PlaybookDeviation) => updateDeviationColumn(sectionId, rowId, key, value), [sectionId, updateDeviationColumn]);

    const dateOfApproval = useCallback((approvalDate: string | null) => !!approvalDate && new Date(approvalDate) || null, []);
    const updateDateOfApproval = useCallback((rowId: string, date: Date | null) => updateDeviationColumn(sectionId, rowId, 'approvalDate', !isNull(date) ? formatDate(date, DATABASE_DATE_FORMAT) : date), [sectionId, updateDeviationColumn]);

    const intervalValueOptions = useMemo(() => Array(12).fill(1).map((_, index) => ({ value: `${(index + 1) * 1}`, label: `${(index + 1) * 1}` })), []);
    const intervalValue = useCallback((intervalValue: number | null) => intervalValueOptions.find(({ value }) => value === intervalValue?.toString()) || null, [intervalValueOptions]);

    const updateDropdownValue = useCallback((rowId: string, key: keyof PlaybookDeviation, dropdownValue: DropdownOption | Options<DropdownOption> | null, asNumber = false) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = asNumber ? parseInt((dropdownValue as DropdownOption).value) : (dropdownValue as DropdownOption).value;
        }
        updateDeviationColumn(sectionId, rowId, key, value);
    }, [sectionId, updateDeviationColumn]);

    const intervalOptions = useMemo(() => Object.values(ValidForInterval).map(value => ({ value, label: capitaliseStartLetter(value) })), []);
    const interval = useCallback((interval: ValidForInterval | null) => intervalOptions.find(({ value }) => value === interval) || null, [intervalOptions]);
    const updateIntervalValue = useCallback((rowId: string, value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue(rowId, 'validForIntervalValue', value, true), [updateDropdownValue]);
    const updateInterval = useCallback((rowId: string, value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue(rowId, 'validForInterval', value), [updateDropdownValue]);

    const validForIntervalBorder = useCallback((approvalDate: string | null, validForInterval: ValidForInterval | null) => (!!approvalDate && isNull(validForInterval)) ? red : lightGrey, []);
    const validForIntervalValueBorder = useCallback((approvalDate: string | null, validForIntervalValue: number | null) => (!!approvalDate && isNull(validForIntervalValue)) ? red : lightGrey, []);

    const columnHeaders = useMemo(() => [
        { label: 'Topic', width: '14%', minWidth: '150px', showBorder: true },
        { label: 'Possible Amendments/Variations', width: '28%', minWidth: '400px', showBorder: true },
        { label: 'Background/Rationale/Stakeholder', width: '28%', minWidth: '400px', showBorder: true },
        { label: 'Negotiation ID', width: '8%', minWidth: '150px', showBorder: true },
        { label: 'Approval Date', width: '8%', minWidth: '150px', showBorder: true },
        { label: 'Valid For', width: '10%', minWidth: '160px', showBorder: true },
        { label: '', width: '4%', minWidth: '80px', showBorder: false },
    ], []);

    return (
        <div className={styles.scrollableTableWrapper}>
            <Scrollable>
                <div className={styles.buildDeviationTableWrapper} data-testid={`${testId}-deviations-table-wrapper`}>
                    <div className={styles.tableHeaders}>
                        {columnHeaders.map(({ label, width, showBorder, minWidth }, index) => (
                            <div className={styles.columnHeaderWrapper} style={{ borderRight: showBorder ? `solid 1px ${white}` : 'none', minWidth, width }} key={index}>
                                <OverflowTooltip className={styles.columnHeader} overlayText={label} testId={`${testId}-deviations-column-header-${index}`} />
                            </div>
                        ))}
                    </div>
                    {deviations.map(({ variations, background, nid, rowId, topic, approvalDate, validForInterval, validForIntervalValue }, index) => (
                        <div className={styles.tableRowWrapper} key={rowId} onDoubleClick={e => openDeviationActionModal(e, index)} data-testid={`${testId}-deviations-table-row-${index}-wrapper`}>
                            <div className={styles.topicColumnWrapper}>
                                <textarea
                                    maxLength={250}
                                    className={styles.topicInput}
                                    placeholder='Add a topic...'
                                    value={topic}
                                    onChange={e => updateText(rowId, e.target.value, 'topic')}
                                    data-testid={`${testId}-deviations-table-row-${index}-topic`}
                                />
                            </div>
                            <div className={styles.wysiwygColumnWrapper} data-testid={`${testId}-deviations-table-row-${index}-amendments`}>
                                <WYSIWYG
                                    content={variations}
                                    updateContent={value => updateDeviationWysiwyg(rowId, 'variations', value)}
                                    showBorder={false}
                                    borderColor={lightGrey}
                                    minHeight='350px'
                                    height='fit-content'
                                    maxHeight='20vh'
                                />
                            </div>
                            <div className={styles.wysiwygColumnWrapper} data-testid={`${testId}-deviations-table-row-${index}-rationale`}>
                                <WYSIWYG
                                    content={background}
                                    updateContent={value => updateDeviationWysiwyg(rowId, 'background', value)}
                                    showBorder={false}
                                    borderColor={lightGrey}
                                    minHeight='350px'
                                    height='fit-content'
                                    maxHeight='20vh'
                                />
                            </div>
                            <div className={styles.nidColumnWrapper}>
                                <Text
                                    onChange={e => updateText(rowId, e.target.value, 'nid')}
                                    value={nid}
                                    maxLength={256}
                                    testId={`${testId}-deviations-table-row-${index}-nid`}
                                    placeholder='Negotiation ID...'
                                    marginBottom='0px'
                                    borderColour={lightGrey}
                                />
                            </div>
                            <div className={styles.datePickerColumnWrapper}>
                                <DatePicker
                                    value={dateOfApproval(approvalDate)}
                                    onChange={date => updateDateOfApproval(rowId, date)}
                                    testId={`${testId}-deviations-table-row-${index}-approval-date`}
                                    minDate={new Date()}
                                    lightBorder
                                />
                            </div>
                            <div className={styles.validFromColumnWrapper}>
                                <div className={styles.dropdownWrapper}>
                                    <Dropdown
                                        options={intervalValueOptions}
                                        value={intervalValue(validForIntervalValue)}
                                        onChange={e => updateIntervalValue(rowId, e)}
                                        isClearable={true}
                                        testId={`${testId}-deviations-table-row-${index}-valid-for-interval-value`}
                                        menuPlacement='bottom'
                                        controlBorderColor={validForIntervalValueBorder(approvalDate, validForIntervalValue)}
                                        menuPortalTarget={document.body}
                                        disabled={!approvalDate}
                                    />
                                </div>
                                <div className={styles.dropdownWrapper}>
                                    <Dropdown
                                        options={intervalOptions}
                                        value={interval(validForInterval)}
                                        onChange={e => updateInterval(rowId, e)}
                                        isClearable={true}
                                        testId={`${testId}-deviations-table-row-${index}-valid-for-interval`}
                                        controlBorderColor={validForIntervalBorder(approvalDate, validForInterval)}
                                        menuPortalTarget={document.body}
                                        disabled={!approvalDate}
                                    />
                                </div>
                            </div>
                            <div className={styles.deleteColumnWrapper} data-testid={`${testId}-deviations-table-row-${index}-delete-button-wrapper`}>
                                {showDeleteDeviation && <DeleteButton onClick={() => deleteDeviationRow(sectionId, rowId)} fontSize={16} testId={`${testId}-deviations-table-row-${index}`} />}
                            </div>
                        </div>
                    ))}
                </div>
            </Scrollable>
        </div>
    );
};
