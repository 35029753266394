import { ApplicationState } from '../../../../store/rootReducer';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { OpinionHealthIndicator, AdminOpinionState, SelectedOpinionAnalytics } from './types';

const getRoot = (state: ApplicationState): AdminOpinionState => state.admin.opinions;

export const getAdminOpinionPieData = (state: ApplicationState): PieChartAnalyticsData[] => getRoot(state).opinionPieData;
export const getIsLoadingPie = (state: ApplicationState): boolean => getRoot(state).isLoadingPie;

export const getSelectedOpinions = (state: ApplicationState): SelectedOpinionAnalytics[] => getRoot(state).selectedOpinions;
export const getSelectedOpinionHealthCategory = (state: ApplicationState): OpinionHealthIndicator | null => getRoot(state).selectedOpinionHealthCategory;

// Opinion Table
export const getIsLoadingTable = (state: ApplicationState): boolean => getRoot(state).isLoadingTable;
export const getSelectedOpinionsFilters = (state: ApplicationState): TableFilters => getRoot(state).selectedOpinionsTableFilters;
export const getSelectedOpinionsPageNumber = (state: ApplicationState): number => getRoot(state).selectedOpinionsTablePageNumber;
export const getSelectedOpinionsColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).selectedOpinionsTableColumnSort;
export const getSelectedOpinionsPageSize = (state: ApplicationState): number => getRoot(state).selectedOpinionsTablePageSize;
export const getSelectedOpinionsTotal = (state: ApplicationState): number => getRoot(state).selectedOpinionsTotal;
