import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';

import Logo from '../../../assets/logos/Ark51_HORIWHITE&GRAD.png';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DraggableItem, ListContext, ListContextValue, getItemRegistry, isItemData } from '../../shared/drag-n-drop/shared';
import styles from '../Home.module.scss';
import { getHomeScreenConfig, HomePageTile, HomeScreenConfig, updateHomeScreenConfig } from '../store';
import { availableTiles } from '../home-screen-config/AvailableTiles';
import { HomeScreenConfigContent } from '../home-screen-config/HomeScreenConfigContent';

export const MyProfileHomeScreen: React.FC = () => {
    const dispatch = useAppDispatch();

    const homeScreenConfig = useAppSelector(getHomeScreenConfig);
    const setColumns = useCallback((config: HomeScreenConfig) => dispatch(updateHomeScreenConfig(config)), [dispatch]);
    const testId = 'home-screen-config';

    const [registry] = useState(getItemRegistry);

    const [instanceId] = useState(() => Symbol(testId));

    const columnTiles = useMemo(() => Object.entries(homeScreenConfig).sort((a, b) => {
        const columnAId = parseInt(a[0].split('-')[1]);
        const columnBId = parseInt(b[0].split('-')[1]);
        return columnAId - columnBId;
    }), [homeScreenConfig]);

    const getRelevantList = useCallback((columnId: string) => homeScreenConfig[columnId].map(id => ({ id, label: id, type: columnId })), [homeScreenConfig]);

    const getTilesForColumn = useCallback((columnId: string) => homeScreenConfig[columnId], [homeScreenConfig]);

    const reorderItem = useCallback(
        ({
            startIndex,
            indexOfTarget,
            closestEdgeOfTarget,
            list,
            groupId = 'column-1'
        }: {
            startIndex: number;
            indexOfTarget: number;
            closestEdgeOfTarget: Edge | null;
            list?: DraggableItem[];
            groupId?: string;
        }) => {
            const finishIndex = getReorderDestinationIndex({
                startIndex,
                closestEdgeOfTarget,
                indexOfTarget,
                axis: 'vertical'
            });

            const tiles = list!;

            const updatedList = reorder({
                list: tiles,
                startIndex,
                finishIndex
            });

            const updatedTiles = updatedList.map(({ id }) => id) as HomePageTile[];
            setColumns({ ...homeScreenConfig, [groupId]: updatedTiles });
        }, [setColumns, homeScreenConfig]);

    const moveItem = useCallback(
        ({
            sourceGroupId,
            targetGroupId,
            sourceGroupItemIndex,
            targetGroupItemIndex,
        }: {
            sourceGroupId: string;
            targetGroupId: string;
            sourceGroupItemIndex: number;
            targetGroupItemIndex: number;
        }) => {
            if (sourceGroupId === targetGroupId) {
                return;
            }
            const targetTiles = getTilesForColumn(targetGroupId);
            const sourceTiles = getTilesForColumn(sourceGroupId);
            const sourceTile = sourceTiles[sourceGroupItemIndex];
            const targetTile = targetTiles[targetGroupItemIndex];
            const updatedSourceTiles = sourceTiles.map((tile, index) => index === sourceGroupItemIndex ? targetTile : tile);
            const updatedTargetTiles = targetTiles.map((tile, index) => index === targetGroupItemIndex ? sourceTile : tile);
            setColumns({ ...homeScreenConfig, [sourceGroupId]: updatedSourceTiles, [targetGroupId]: updatedTargetTiles });
        }, [setColumns, getTilesForColumn, homeScreenConfig]);

    useEffect(() => monitorForElements({
        canMonitor: ({ source }) => isItemData(source.data) && source.data.instanceId === instanceId,
        onDrop: ({ location, source }) => {
            const target = location.current.dropTargets[0];
            if (!target) {
                return;
            }

            const sourceData = source.data;
            const targetData = target.data;
            if (!isItemData(sourceData) || !isItemData(targetData)) {
                return;
            }

            const list = getRelevantList(sourceData.type);
            const isWithinSameColumn = sourceData.type === targetData.type;
            if (isWithinSameColumn) {
                const indexOfTarget = list.findIndex(({ id }) => id === targetData.item.id);
                if (indexOfTarget < 0) {
                    return;
                }
                reorderItem({
                    startIndex: sourceData.index,
                    indexOfTarget,
                    closestEdgeOfTarget: null,
                    list,
                    groupId: sourceData.type
                });
                return;
            }
            moveItem({
                sourceGroupId: sourceData.type,
                targetGroupId: targetData.type,
                sourceGroupItemIndex: sourceData.index,
                targetGroupItemIndex: targetData.index
            });
        },
    }), [instanceId, reorderItem, moveItem, getRelevantList]);

    const getListLength = useCallback(() => columnTiles.reduce((acc, [, tiles]) => acc + tiles.length, 0), [columnTiles]);

    const contextValue: ListContextValue = useMemo(() => ({
        registerItem: registry.register,
        reorderItem,
        instanceId,
        getListLength,
        moveItem
    }), [registry.register, reorderItem, instanceId, getListLength, moveItem]);

    const getChildElement = useCallback((id: string) => {
        const foundTile = availableTiles.find(tile => tile.id === id);
        if (foundTile) {
            return (
                <div className={styles.childWrapper}>{foundTile.tile}</div>
            );
        }
        return null;
    }, []);

    return (
        <div className={styles.homeScreenWrapper}>
            <div className={styles.headersWrapper}>
                <div className={styles.topNavWrapper} data-testid={`${testId}-top-nav-wrapper`}>
                    <div className={styles.logoWrapper} data-testid={`${testId}-top-nav-logo-wrapper`}>
                        <img className={styles.logo} src={Logo} />
                    </div>
                </div>
                <h4 className={styles.title}>Alternative Tile Options</h4>
            </div>
            <ListContext.Provider value={contextValue}>
                <div className={styles.homeScreenConfigTilesContentWrapper}>
                    {columnTiles.map(([columnId, tileIds]) => {
                        const tiles = tileIds.map(id => ({ id, label: id, type: columnId }));
                        const isLastColumn = columnId === 'column-4';
                        return (
                            <HomeScreenConfigContent key={columnId} columnId={columnId} tiles={tiles} isLastColumn={isLastColumn} getChildElement={getChildElement} />);
                    })}
                </div>
            </ListContext.Provider>
        </div>
    );
};
