import { Reducer } from 'redux';
import { set, flow, getOr } from 'lodash/fp';

import { DropdownListsActionTypes, DropdownListState } from './types';

const initialList = { dropdownListId: null, name: '', description: '', options: [] };

export const INITIAL_STATE: DropdownListState = {
    modalOpen: false,
    previewModalOpen: false,
    dropdownList: initialList,
    publishError: null,
    publishedDropdownLists: [],
    fetchError: null,
    isSaving: false
};

export const dropdownListReducer: Reducer<DropdownListState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DropdownListsActionTypes.TOGGLE_MODAL:
            return flow(
                set('modalOpen', action.payload.isOpen),
                set('dropdownList', getOr(initialList, 'dropdownList', action.payload))
            )(state);
        case DropdownListsActionTypes.UPDATE_DROPDOWN_LIST: {
            const { key, value } = action.payload;
            return set(`dropdownList[${key}]`, value, state);
        }
        case DropdownListsActionTypes.ADD_LIST_ITEM:
            return set('dropdownList[options]', state.dropdownList.options.concat(''), state);
        case DropdownListsActionTypes.UPDATE_LIST_ITEM: {
            const { index, value } = action.payload;
            return set(`dropdownList.options[${index}]`, value, state);
        }
        case DropdownListsActionTypes.REMOVE_LIST_ITEM: {
            const newListItems = state.dropdownList.options.filter((_, index) => index !== action.payload);
            return set('dropdownList[options]', newListItems, state);
        }
        case DropdownListsActionTypes.TOGGLE_PREVIEW_MODAL:
            return set('previewModalOpen', action.payload, state);
        case DropdownListsActionTypes.PUBLISH_DROPDOWN_LIST_STARTED:
            return set('isSaving', true, state);
        case DropdownListsActionTypes.PUBLISH_DROPDOWN_LIST_SUCCESSFUL:
            return flow(
                set('modalOpen', false),
                set('isSaving', false),
                set('publishedDropdownLists', action.payload),
                set('dropdownList', initialList)
            )(state);
        case DropdownListsActionTypes.PUBLISH_DROPDOWN_LIST_FAILED:
            return flow(
                set('publishError', action.payload),
                set('isSaving', false)
            )(state);
        case DropdownListsActionTypes.FETCH_ALL_DROPDOWN_LISTS_SUCCESSFUL:
            return set('publishedDropdownLists', action.payload, state);
        case DropdownListsActionTypes.FETCH_ALL_DROPDOWN_LISTS_FAILED:
            return set('fetchError', action.payload, state);
        default:
            return state;
    }
};
