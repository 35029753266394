import classnames from 'classnames';
import { RawDraftContentState } from 'draft-js';
import { isUndefined } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../../../hooks/react-redux';
import { useSingleHiddenFields } from '../../../../../../hooks/useHiddenSingleFields';
import { HiddenFields } from '../../../../../admin/my-datasets/store';
import { CalculatorField } from '../../../../../shared/calculator/constants';
import styles from '../../../../../shared/datasets/SharedStyling.module.scss';
import { Icon } from '../../../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../../../shared/icons';
import { CurrencyValue } from '../../../../../shared/number/CurrencyNumber';
import { OverflowTooltip } from '../../../../../shared/tooltip';
import { Checkbox, DateField, DocumentField, Dropdown, LabelField, LongText, Number, PartySelect, Text, Time } from '../../../../fields';
import { CalculatorField as Calculator } from '../../../../fields/CalculatorField';
import { CurrencyAmountField } from '../../../../fields/CurrencyAmountField';
import { WYSIWYGField } from '../../../../fields/WYSIWYGField';
import { DatasetFieldType } from '../../../../store';
import { InstanceMLData, MLDataValue } from '../../../../store/mlTypes';
import { FieldValue, getCurrentInstances, OpenFieldSection, OpenFieldType, SingleInstanceField, UpdatedFormField } from '../../../store';
import { MLAnswer } from '../../MLDataModal';
import mlStyle from '../MLDatasetInstance.module.scss';
import { MLDatasetField } from './MLDatasetField';
import { checkDatasetFieldHasMlData } from './MLFormInstance';

export const getField = (
    field: SingleInstanceField,
    value: FieldValue,
    isEditing: boolean,
    isUpdating: boolean,
    showFieldUpdated: boolean,
    showDatasetUpdated: boolean,
    showAIModified: boolean,
    showAIModifiedUserCorrected: boolean,
    includedInAnnex: boolean,
    index: number,
    disabled: boolean,
    fieldOpen: boolean,
    datasetId: number,
    parentFieldId: string,
    toggleSection: (fieldSection: OpenFieldSection) => void,
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean,
    mlData: InstanceMLData,
    sectionId?: string,
    groupIndex?: number,
    menuPortalTarget?: HTMLElement | null,
    modalInstance?: boolean,
    fieldHidden?: boolean
) => {
    const defaultProps = { id: field.id!, index, sectionId, groupIndex, disabled, showFieldUpdated, showDatasetUpdated, showAIModified, showAIModifiedUserCorrected, includedInAnnex, modalInstance, parentFieldId, isMLCorrection: true };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} value={value as string} datasetId={datasetId} />;
        case DatasetFieldType.LONGTEXT:
            return <LongText {...defaultProps} value={value as string} datasetId={datasetId} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={value as null | Date} datasetId={datasetId} />;
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
            return <Dropdown
                {...defaultProps}
                value={value as null | string[]}
                type={field.type}
                listId={field.settings.dropdownLinked!}
                isMulti={field.settings.isMultiSelect}
                entityType={field.settings.entityType}
                menuPortalTarget={menuPortalTarget}
                datasetId={datasetId}
            />;
        case DatasetFieldType.PARTY:
            return <PartySelect
                {...defaultProps}
                value={value as null | string[]}
                menuPortalTarget={menuPortalTarget}
                datasetId={datasetId}
            />;
        case DatasetFieldType.DOCUMENT:
            return <DocumentField id={field.id!} value={value as string[]} />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} value={value as number} datasetId={datasetId} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={value as boolean} datasetId={datasetId} centreField={field.settings.centreField} />;
        case DatasetFieldType.DATASET:
            return <MLDatasetField childDatasetId={field.settings.datasetLinked!} isEditing={isEditing} isUpdating={isUpdating} fieldOpen={fieldOpen} id={field.id!} fieldHidden={fieldHidden} mlData={mlData} />;
        case DatasetFieldType.TIME:
            return <Time {...defaultProps} value={value as string[]} datasetId={datasetId} />;
        case DatasetFieldType.WYSIWYG:
            return <WYSIWYGField
                {...defaultProps}
                value={value as RawDraftContentState | null}
                fieldOpen={fieldOpen}
                toolbarHidden={disabled}
                datasetId={datasetId}
                isCollapsible={field.settings.isCollapsible}
                toggleSection={toggleSection}
                getSectionOpen={getSectionOpen}
            />;
        case DatasetFieldType.LABEL:
            return <LabelField value={field.settings.labelContent!} />;
        case DatasetFieldType.CALCULATOR:
            return <Calculator
                {...defaultProps}
                value={value as CalculatorField[]}
                fieldType={field.settings.calculatorFieldType!}
                showOutputField={field.settings.showOutputField!}
                showGracePeriod={field.settings.showGracePeriod!}
                includeFreeTextOption={field.settings.includeFreeTextOption}
                fieldOpen
                datasetId={datasetId}
            />;
        case DatasetFieldType.CURRENCY_AMOUNT:
            return <CurrencyAmountField {...defaultProps} currencyValue={value as CurrencyValue} datasetId={datasetId} />;
        default:
            return <div className={styles.unknownFieldType}>Field type unknown.</div>;
    }
};

interface MLSingleFieldProps {
    field: SingleInstanceField;
    sectionId: string;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedFormField[];
    annexFieldIds: string[];
    width?: number;
    isLastField?: boolean;
    index: number;
    singleIndex?: string;
    groupIndex?: number;
    isAnnexInstance: boolean;
    datasetOnlySection?: boolean;
    datasetId: number;
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    mlData: InstanceMLData;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    isMultiToggle?: boolean;
    edgeCaseAnswer?: MLDataValue;
    showEdgeCaseLabel?: boolean;
    showEntireSection: boolean;
}

export const MLSingleField: React.FC<MLSingleFieldProps> = ({
    field,
    width = 100,
    isLastField = false,
    index,
    sectionId,
    singleIndex,
    groupIndex,
    isEditing,
    isUpdating,
    fieldsUpdated,
    annexFieldIds,
    isAnnexInstance,
    datasetOnlySection = false,
    datasetId,
    parentFieldId,
    instanceExecutedDateMatchesParent,
    modalInstance,
    toggleSection,
    getSectionOpen,
    mlData,
    hiddenFields,
    datasetHidden,
    isMultiToggle,
    edgeCaseAnswer,
    showEdgeCaseLabel = false,
    showEntireSection
}) => {
    const { label, settings, value, id, type, systemId } = field;
    const wysiwygFieldOpen = useMemo(() => !isUndefined(settings.isCollapsible) && !settings.isCollapsible, [settings.isCollapsible]);
    const openField = useMemo(() => ({ sectionId, datasetId: datasetId.toString() || '', fieldId: id!, type: OpenFieldType.FIELD, groupIndex, parentFieldId }), [groupIndex, datasetId, id, sectionId, parentFieldId]);
    const fieldOpen = useMemo(() => getSectionOpen(openField) || datasetOnlySection || wysiwygFieldOpen || type === DatasetFieldType.CALCULATOR, [getSectionOpen, openField, datasetOnlySection, wysiwygFieldOpen, type]);
    const fieldOpenIcon = fieldOpen ? CaretDown : CaretSide;

    const isCollapsible = settings.isCollapsible || DatasetFieldType.DATASET === type;
    const testId = singleIndex || index;
    const fieldUpdatedByInstance = useMemo(() => fieldsUpdated.map(({ id }) => id).includes(id!) && instanceExecutedDateMatchesParent, [fieldsUpdated, id, instanceExecutedDateMatchesParent]);
    const showFieldUpdated = fieldUpdatedByInstance;
    const newDatasetField = field.settings.datasetUpdated || false;
    const showDatasetUpdated = newDatasetField;
    const showAIModified = !!settings.aiModified;
    const showAIModifiedUserCorrected = !!settings.aiModifiedUserCorrected;
    const disabled = !isEditing && !(isUpdating && (fieldUpdatedByInstance || newDatasetField));
    const includedInAnnex = !isAnnexInstance && annexFieldIds.includes(id!);
    const showFieldLabel = type !== DatasetFieldType.LABEL && !datasetOnlySection;
    const menuPortalTarget = document.body;
    const isLabelField = type === DatasetFieldType.LABEL;

    const singleFieldLabel = useMemo(() => {
        return <OverflowTooltip
            className={classnames(styles.fieldLabel, {
                [styles.emptyFieldLabel]: !label,
                [styles.datasetSectionText]: type === DatasetFieldType.DATASET
            })}
            overlayText={label || 'Label...'}
            testId={`form-field-${testId}-label`}
            onClick={() => isCollapsible && toggleSection(openField)}
        />;
    }, [label, type, testId, isCollapsible, toggleSection, openField]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields && hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const sectionIsHidden = useMemo(() => !!currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(field => field.sectionId === sectionId), [currentHiddenDatasetFields, sectionId]);

    const mlFieldInstance = useMemo(() => mlData.instanceMLData.find(mlField => mlField.systemId === systemId), [mlData, systemId]);

    const fieldHidden = useMemo(() => sectionIsHidden || datasetHidden || !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields, id, sectionIsHidden, datasetHidden]);
    const allInstances = useAppSelector(getCurrentInstances);

    const datasetOnlySectionHasMlData = useMemo(() => {
        if (type === DatasetFieldType.DATASET) {
            const currentInstances = allInstances.map(({ instance }) => instance);
            const linkedDatasetId = field.settings.datasetLinked || null;
            return checkDatasetFieldHasMlData(currentInstances, linkedDatasetId, mlData);
        }
        return false;
    }, [type, allInstances, field, mlData]);

    if ((type !== DatasetFieldType.DATASET && isUndefined(edgeCaseAnswer) && (!mlFieldInstance && !showEntireSection)) || (type === DatasetFieldType.DATASET && !datasetOnlySectionHasMlData) || fieldHidden) {
        return null;
    }

    if (isMultiToggle) {
        return (
            <div className={styles.multiToggleField}>
                <div className={classnames(styles.multiToggleLabelWrapper, { [styles.emptyFieldLabel]: !label })}>
                    <div className={styles.multiToggleLabel}><OverflowTooltip overlayText={label || 'Label...'} /></div>
                </div>
                {getField(
                    field,
                    value,
                    isEditing,
                    isUpdating,
                    showFieldUpdated,
                    showDatasetUpdated,
                    showAIModified,
                    showAIModifiedUserCorrected,
                    includedInAnnex,
                    index,
                    disabled,
                    fieldOpen,
                    datasetId,
                    parentFieldId,
                    toggleSection,
                    getSectionOpen,
                    mlData,
                    sectionId,
                    groupIndex,
                    menuPortalTarget,
                    modalInstance
                )}
            </div>
        );
    }

    return (
        <div
            className={classnames(styles.singleFieldWrapper, { [styles.lastFieldInGroup]: isLastField, [styles.isLabelField]: isLabelField })}
            style={{ width: `${width}%` }}
            data-testid={`form-field-${testId}-wrapper`}
            id={`ml-dataset-instance-field-${id}`}
        >
            {showFieldLabel &&
                <div className={classnames(styles.fieldTitleWrapper, { [styles.fieldCanCollapse]: isCollapsible })}>
                    {isCollapsible &&
                        <div className={styles.fieldOpenWrapper} onClick={() => toggleSection(openField)} data-testid='collapsible-field'>
                            <Icon icon={fieldOpenIcon} fontSize={15} />
                        </div>
                    }
                    {singleFieldLabel}
                </div>
            }
            {mlFieldInstance && ((fieldOpen && isCollapsible) || !isCollapsible) &&
                <div className={mlStyle.mlFormDatasetQueryWrapper}>
                    <div className={mlStyle.aiAnswerLabel}>AI Answer:</div>
                    <MLAnswer answer={mlFieldInstance.mlValue} depth={1} queryKey={mlFieldInstance.key} />
                </div>
            }
            {showEdgeCaseLabel && ((fieldOpen && isCollapsible) || !isCollapsible) &&
                <div className={mlStyle.mlFormDatasetQueryWrapper}>
                    <div className={mlStyle.aiAnswerLabel}>AI Answer:</div>
                    <MLAnswer answer={edgeCaseAnswer!} depth={1} />
                </div>
            }
            {getField(
                field,
                value,
                isEditing,
                isUpdating,
                showFieldUpdated,
                showDatasetUpdated,
                showAIModified,
                showAIModifiedUserCorrected,
                includedInAnnex,
                index,
                disabled,
                fieldOpen,
                datasetId,
                parentFieldId,
                toggleSection,
                getSectionOpen,
                mlData,
                sectionId,
                groupIndex,
                menuPortalTarget,
                modalInstance
            )}
        </div>
    );
};
