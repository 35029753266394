import React, { useCallback, useMemo, useState } from 'react';
import { min } from 'lodash/fp';

import { Position, PositionModal } from '../../../shared/modal/PositionModal';
import styles from './Fields.module.scss';
import { Text } from '../../../shared/text/Text';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { DeleteButton } from '../../../shared/button/DeleteButton';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Toggle } from '../../../shared/toggle';

interface RefModalProps {
    isOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    refLabel: string;
    pageRef: string[];
    pageRefVerified: boolean;
    updateRefLabel: (val: string) => void;
    updatePageRef: (val: string, index: number) => void;
    removePageRef: (index: number) => void;
    verifyPageRefs: (checked: boolean) => void;
    addPageRef: () => void;
}

const FIXED_HEIGHT_ELEMENTS = 120;
const PAGE_REF = 43;

export const RefModal: React.FC<RefModalProps> = ({ closeModal, isOpen, position, updateRefLabel, refLabel, pageRef, pageRefVerified, updatePageRef, verifyPageRefs, removePageRef, addPageRef }) => {
    const [autoFocus, setAutoFocus] = useState<boolean>(false);
    const updatePageRefNumber = useCallback((value: string, index: number) => (!isNaN(parseInt(value)) || value === '') && updatePageRef(value, index), [updatePageRef]);
    const height = useMemo(() => `${FIXED_HEIGHT_ELEMENTS + (min([pageRef.length, 3])! * PAGE_REF)}px`, [pageRef.length]);
    const showDelete = useMemo(() => pageRef.length > 1, [pageRef]);
    const addPageReference = useCallback(() => {
        addPageRef();
        setAutoFocus(true);
    }, [addPageRef, setAutoFocus]);

    return (
        <PositionModal
            isOpen={isOpen}
            closeModal={closeModal}
            position={position}
            testId='ref-modal'
            height={height}
            width='250px'
        >
            <>
                <div data-testid='ref-title' className={styles.refInputTitle}>Ref</div>
                <textarea
                    style={{ resize: 'none' }}
                    maxLength={500}
                    className={styles.refInput}
                    placeholder='Add a reference...'
                    value={refLabel}
                    onChange={e => updateRefLabel(e.target.value)}
                    data-testid='ref-input'
                />
                <div className={styles.pageRefHeaderWrapper}>
                    <div data-testid='page-ref-title' className={styles.pageRefTitle}>Page Ref</div>
                    <PlusButton onClick={addPageReference} fontSize={14} />
                </div>
                {!pageRefVerified &&
                    <div className={styles.pageRefVerificationWrapper}>
                        <div>I have checked these page references</div>
                        <Toggle onChange={verifyPageRefs} checked={pageRefVerified} />
                    </div>
                }
                <div className={styles.pageRefsWrapper}>
                    <Scrollable maxHeight={`${3 * PAGE_REF}px`}>
                        {pageRef.map((pageReference, index) => (
                            <div key={index} className={styles.pageRefWrapper}>
                                <Text testId='opinion-page-ref' value={pageReference} onChange={e => updatePageRefNumber(e.target.value, index)} marginBottom='0px' autoFocus={autoFocus} />
                                {showDelete ? <DeleteButton fontSize={20} onClick={() => removePageRef(index)} testId={`search-row-${index}`} /> : <div className={styles.deletePlaceholder}></div>}
                            </div>
                        ))}
                    </Scrollable>
                </div>
            </>
        </PositionModal>
    );
};
