import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

import { tooltipColors, Colors } from './Tooltip';
import styles from './Tooltip.module.scss';

const { primary } = styles;

interface InformationTooltipProps {
    content: string | null;
    color?: keyof Colors;
    labelColor?: string;
    width?: number;
    trigger?: string;
    placement?: string;
    fontSize?: number;
    label?: string;
}

export const InformationTooltip: React.FC<InformationTooltipProps> = ({ content, color = 'french', labelColor = primary, width = 200, trigger = 'hover', placement = 'right', fontSize = 12, label = 'i' }) => {

    const overlay = content && <span style={{ maxWidth: `${width - 18}px` }} className={styles.overlayTextStyle}>{content}</span>;

    const { backgroundColor, fontColor } = tooltipColors[color];

    const tooltipStyles = {
        backgroundColor,
        color: fontColor,
        borderColor: fontColor,
        borderRadius: '5px',
        minHeight: '10px',
        display: content ? 'flex' : 'none'
    };

    const infoStyles = {
        height: `${fontSize + 1}px`,
        width: `${fontSize + 1}px`,
        minHeight: `${fontSize + 1}px`,
        minWidth: `${fontSize + 1}px`,
        fontSize: `${fontSize}px`,
        color: labelColor,
        cursor: trigger === 'click' ? 'pointer' : 'default'
    };

    const overlayStyle = {
        maxWidth: `${width}px`
    };

    return (
        <Tooltip
            placement={placement}
            overlay={overlay}
            trigger={trigger}
            overlayClassName={styles.overlay}
            overlayInnerStyle={tooltipStyles}
            overlayStyle={overlayStyle}
        >
            <div className={styles.informationTooltipIcon} style={infoStyles}>{label}</div>
        </Tooltip>
    );
};
