import React from 'react';

import { Toggle } from '../toggle';
import styles from './Table.module.scss';

interface CheckboxCellProps {
    select: (selectedId: number) => void;
    selected: number[];
    value: number;
    disabled?: boolean;
}

export const CheckboxCell: React.FC<CheckboxCellProps> = ({ select, selected, value, disabled }) => {
    const selectCell = () => select(value);
    const isChecked = selected.includes(value);

    return (
        <div className={styles.checkboxCell}>
            <Toggle
                checked={isChecked}
                onChange={selectCell}
                height={15}
                width={30}
                disabled={disabled}
            />
        </div>
    );
};
