import { isNull } from 'lodash/fp';
import React from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { AgencyAnnexBuilder } from '../../../agency-annex/AgencyAnnexBuilder';
import { getAnnexDefinition } from '../../../agency-annex/store';

interface BuilderProps {
    height: number;
}

const BUILDER_TITLE = 48;
const PRINCIPAL_INPUT = 53;

export const Builder: React.FC<BuilderProps> = ({ height }) => {
    const annexDefinition = useAppSelector(getAnnexDefinition);

    const droppableHeight = height - (BUILDER_TITLE + PRINCIPAL_INPUT);

    if (isNull(annexDefinition)) {
        return null;
    }

    return (
        <AgencyAnnexBuilder annexDefinition={annexDefinition} droppableHeight={droppableHeight} heightOffset={0} />
    );
};
