import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { WorldCountriesList, worldCountriesList } from '../../../constants/worldCountriesList';
import { IconButton } from '../../../shared/button/IconButton';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Add, Minus, Search, Sort } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { CustomTooltip, InformationTooltip } from '../../../shared/tooltip';
import styles from '../Analytics.module.scss';
import worldGeometryJson from '../../../constants/worldGeometry.json';
import { DocumentAnalyticsChart, Country, CountryEntity, clearCountryEntities, getCountryEntities, getCountryEntitiesModalOpen, getEntityJurisdictionAnalytics, getFetchingAnalytics, getIsFetchingMyCompanies, getSelectedCountry, setCountryEntities, setSelectedCountry } from '../store';
import { JurisdictionChart } from './JurisdictionChart';
import { JurisdictionEntityModal } from './JurisdictionEntityModal';

const { lightestGrey, french } = styles;

interface JurisdictionChartWrapperProps {
    dimensions?: {
        height: number;
        width: number;
    };
    tileInView: null | DocumentAnalyticsChart;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
}

export interface JurisdictionList extends WorldCountriesList {
    entities: CountryEntity[];
    documents: number;
}

const worldGeometry = worldGeometryJson as Country[];

const edgeCaseJurisdictions = ['US'];

export const JurisdictionChartWrapper: React.FC<JurisdictionChartWrapperProps> = ({ dimensions, setTileInView, tileInView, analyticsSpinner }) => {
    const dispatch = useAppDispatch();
    const [resetZoom, setResetZoom] = useState<boolean>(false);
    const [zoomSteps, setZoomSteps] = useState<number>(0);
    const [zoomUpdated, setZoomUpdated] = useState<boolean>(false);
    const [currentScale, setCurrentScale] = useState<number>(0);

    const initialScale: number = useMemo(() => tileInView === DocumentAnalyticsChart.ENTITY_JURISDICTION ? 0.2 : 0.6, [tileInView]);
    const scaleExtent: [number, number] = useMemo(() => [initialScale, 5], [initialScale]);

    const entityJurisdiction = useAppSelector(getEntityJurisdictionAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const fetchingMyCompanies = useAppSelector(getIsFetchingMyCompanies);

    const showSpinner = useMemo(() => fetchingAnalytics.includes(DocumentAnalyticsChart.ENTITY_JURISDICTION) || fetchingMyCompanies, [fetchingAnalytics, fetchingMyCompanies]);
    const toggleTileView = useCallback(() => setTileInView(isNull(tileInView) ? DocumentAnalyticsChart.ENTITY_JURISDICTION : null), [setTileInView, tileInView]);

    const getEntitiesByCountry = useCallback((id: string) => entityJurisdiction.filter(({ jurisdiction }) => edgeCaseJurisdictions.includes(id) ? jurisdiction?.includes(id) : jurisdiction === id).map(({ name, entityId, onWatchlist }) => ({ name, entityId: entityId!, onWatchlist })), [entityJurisdiction]);
    const getDocumentsByCountry = useCallback((id: string) => entityJurisdiction.filter(({ jurisdiction }) => edgeCaseJurisdictions.includes(id) ? jurisdiction?.includes(id) : jurisdiction === id).reduce((acc, cur) => acc + cur.documents, 0), [entityJurisdiction]);
    const jurisdictionList = useMemo(() => worldCountriesList.map(country => ({ entities: getEntitiesByCountry(country.id), documents: getDocumentsByCountry(country.id), ...country })), [getEntitiesByCountry, getDocumentsByCountry]);
    const countryList = worldCountriesList.map(({ name, geoId }) => ({ label: name, value: geoId }));

    const selectedCountry = useAppSelector(getSelectedCountry);
    const selectedCountryGeometry = worldGeometry.find(({ id }) => id === selectedCountry);
    const getSelectedCountryLabel = useCallback((country: string) => jurisdictionList.find(({ geoId }) => geoId === country)!.name || '', [jurisdictionList]);
    const jurisdictionValue = useMemo(() => selectedCountry ? { value: selectedCountry, label: getSelectedCountryLabel(selectedCountry) } : null, [selectedCountry, getSelectedCountryLabel]);

    const isOpen = useAppSelector(getCountryEntitiesModalOpen);
    const selectedCountryEntities = useAppSelector(getCountryEntities);
    const closeModal = useCallback(() => { dispatch(clearCountryEntities()); }, [dispatch]);
    const openCountryEntities = useCallback((name: string, entities: CountryEntity[]) => { dispatch(setCountryEntities(name, entities)); }, [dispatch]);

    const { height, width } = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 20;
            const svgHeight = dimensions.height - 98;
            return { width: svgWidth, height: svgHeight };
        }
        return { width: 1, height: 1 };
    }, [dimensions]);

    const updateDropdown = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        setZoomUpdated(false);
        dispatch(setSelectedCountry(selected));
    };

    const resetMapZoom = () => {
        dispatch(setSelectedCountry(null));
        setResetZoom(!resetZoom);
        setZoomSteps(0);
        setZoomUpdated(false);
    };

    const mapZoomIn = useCallback(() => {
        setResetZoom(false);
        zoomSteps < 0 ? setZoomSteps(1) : setZoomSteps(zoomSteps + 1);
        setZoomUpdated(true);
    }, [zoomSteps]);

    const mapZoomOut = useCallback(() => {
        setResetZoom(false);
        zoomSteps > 0 ? setZoomSteps(-1) : setZoomSteps(zoomSteps - 1);
        setZoomUpdated(true);
    }, [zoomSteps]);

    const mapZoomExtent = useCallback((value: number) => setCurrentScale(value), []);

    const zoomInDisabled = useMemo(() => currentScale >= scaleExtent[1], [currentScale, scaleExtent]);
    const zoomOutDisabled = useMemo(() => currentScale <= scaleExtent[0], [currentScale, scaleExtent]);

    useEffect(() => {
        return () => {
            dispatch(setSelectedCountry(null));
        };
    }, [dispatch]);

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.jurisdictionChartWrapper} data-testid='jurisdiction-chart-wrapper-entity'>
            <div className={styles.jurisdictionChartHeader}>
                <div className={styles.jurisdictionChartTitle} data-testid='jurisdiction-chart-title-entity'>Jurisdiction Map</div>
                <div className={styles.jurisdictionChartDescription}><InformationTooltip content='A visualisation of the company entities within your client base and their jurisdiction across the world. Select a country from the dropdown to zoom to its location.' /></div>
                <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
            </div>
            <JurisdictionChart
                id='jurisdiction'
                height={height}
                width={width}
                worldGeometry={worldGeometry}
                graphData={jurisdictionList}
                selectedCountry={selectedCountryGeometry}
                onDblClick={openCountryEntities}
                resetZoom={resetZoom}
                zoomSteps={zoomSteps}
                zoomUpdated={zoomUpdated}
                mapZoomExtent={mapZoomExtent}
                scaleExtent={scaleExtent}
            />
            <div className={styles.jurisdictionChartDropdownWrapper}>
                <div className={styles.jurisdictionChartZoomWrapper}>
                    <CustomTooltip overlayText='Zoom In' placement='top'>
                        <div>
                            <IconButton onClick={mapZoomIn} disabled={zoomInDisabled} testId='jurisdiction-map-zoom-in' icon={Add} fontSize={20} withBackground />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip overlayText='Reset Zoom' placement='top'>
                        <div>
                            <IconButton onClick={resetMapZoom} disabled={false} testId='jurisdiction-map-reset-zoom' icon={Search} fontSize={20} withBackground iconFill={french} />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip overlayText='Zoom Out' placement='top'>
                        <div>
                            <IconButton onClick={mapZoomOut} disabled={zoomOutDisabled} testId='jurisdiction-map-zoom-out' icon={Minus} fontSize={20} withBackground />
                        </div>
                    </CustomTooltip>
                </div>
                <div className={styles.jurisdictionChartDropdown}>
                    <Dropdown
                        testId='entity-jurisdiction'
                        onChange={updateDropdown}
                        value={jurisdictionValue}
                        options={countryList}
                        controlBackgroundColor={lightestGrey}
                    />
                </div>
            </div>
            {selectedCountryEntities &&
                <JurisdictionEntityModal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    selectedCountryEntities={selectedCountryEntities}
                />
            }
        </div>
    );
};
