import { set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { DoraDataManagementActionTypes, DoraDataManagementState } from './types';

export const INITIAL_STATE: DoraDataManagementState = {
    selectedTab: null
};

export const doraDataManagementReducer: Reducer<DoraDataManagementState> = (state = INITIAL_STATE, { payload, type }): DoraDataManagementState => {
    switch (type) {
        case DoraDataManagementActionTypes.SET_SELECTED_DORA_MANAGEMENT_TAB:
            return set('selectedTab', payload.selectedTab, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
