import { isNull, isUndefined } from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import Select, { Options } from 'react-select';

import { useAppSelector } from '../../../../hooks/react-redux';
import { useMenuPlacement } from '../../../../hooks/useMenuPlacement';
import { EntityType, getAllEntities } from '../../../admin/entity/store';
import { DropdownOption, customStyles } from '../../../shared/dropdown/Dropdown';
import { CustomTooltip } from '../../../shared/tooltip';
import styles from './Fields.module.scss';
import { SignOffConversation } from '../store';
import { SignOffConversationIcon } from '../sign-off';

const { amethyst, primary, disabledGrey } = styles;

interface EntityProps {
    id: string;
    value: string[] | null;
    updateValue: (value: string[] | null) => void;
    disabled?: boolean;
    showFieldUpdated?: boolean;
    menuPortalTarget?: HTMLElement | null;
    signOffConversation?: SignOffConversation;
}

export const Entity: React.FC<EntityProps> = ({
    id,
    value,
    updateValue,
    disabled = false,
    showFieldUpdated = false,
    menuPortalTarget = document.body,
    signOffConversation
}) => {
    const entityRef = useRef<HTMLDivElement>(null);
    const allEntities = useAppSelector(getAllEntities);

    const options = useMemo(() => allEntities.filter(({ type }) => type === EntityType.PERSON).map(({ name }) => ({ label: name, value: name })), [allEntities]);

    const updateEntityValue = (option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = [(option as DropdownOption).value];
        }
        updateValue(value);
    };

    const mappedValue = useMemo(() => isNull(value) ? null : { value: value[0], label: value[0] }, [value]);

    const entityTooltip = useMemo(() => {
        if (!value || !disabled) {
            return null;
        }
        return value.join(' - ');
    }, [value, disabled]);

    const MAX_MENU_HEIGHT = 150;
    const menuPlacement = useMenuPlacement(entityRef, MAX_MENU_HEIGHT);
    const controlBorderColor = useMemo(() => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return disabled ? disabledGrey : primary;
    }, [showFieldUpdated, disabled]);

    return (
        <div className={styles.entityWrapper}>
            <CustomTooltip overlayText={entityTooltip} trigger='click'>
                <div
                    data-testid={`opinion-entity-${id}`}
                    className={styles.dropdownFieldWrapper}
                    ref={entityRef}
                    style={{ maxWidth: '400px' }}
                >
                    <Select
                        className={styles.dropdownField}
                        classNamePrefix='ark-dropdown'
                        isDisabled={disabled}
                        value={mappedValue}
                        onChange={updateEntityValue}
                        isMulti={false}
                        options={options}
                        styles={customStyles}
                        controlBorderColor={controlBorderColor}
                        maxMenuHeight={MAX_MENU_HEIGHT}
                        menuPlacement={menuPlacement}
                        menuPortalTarget={menuPortalTarget}
                        fontWeight={500}
                    />
                </div>
            </CustomTooltip>
            {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={id} />}
        </div>
    );
};
