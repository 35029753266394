import React from 'react';

export const Dropdown: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,108.7,108.7,114.1,102.1,114.1z" />
        <g>
            <rect fill={color || 'currentColor'} x="81.4" y="49.9" width="22.1" height="29.1" />
            <path fill='#FFFFFF' d="M91.5,69.8L86,60.3c-0.4-0.7,0.1-1.7,1-1.7h10.9c0.9,0,1.4,0.9,1,1.7l-5.5,9.5C93,70.5,91.9,70.5,91.5,69.8z" />
            <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M102.1,80.1H26.8c-1.4,0-2.5-1.1-2.5-2.5V52.4c0-1.4,1.1-2.5,2.5-2.5h75.3c1.4,0,2.5,1.1,2.5,2.5v25.2C104.6,79,103.5,80.1,102.1,80.1z" />
        </g>
    </svg>
);
