import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SaveFile, Pencil } from '../../../shared/icons';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import styles from '../Playbook.module.scss';
import { getPlaybookIsDraft, getPlaybookPublishReason, getPlaybookSaveModalOpen, getPlaybookVersionUpdated, publishPlaybookStarted, savePlaybookStarted, setPlaybookPublishReason, togglePlaybookSaveModalOpen } from '../store';
import { VersionControl } from './tabs/VersionControl';
import { DraftPublishToggle } from './DraftPublishToggle';
import { LongText } from '../../../shared/longtext/LongText';

interface PlaybookSaveModalProps {
    testId?: string;
}

export const PlaybookSaveModal: React.FC<PlaybookSaveModalProps> = ({ testId = 'playbook-save-modal' }) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getPlaybookSaveModalOpen);
    const versionUpdated = useAppSelector(getPlaybookVersionUpdated);
    const isDraft = useAppSelector(getPlaybookIsDraft);
    const publishReason = useAppSelector(getPlaybookPublishReason);

    const closeModal = () => dispatch(togglePlaybookSaveModalOpen(false));

    const updateReason = useCallback((value: string) => dispatch(setPlaybookPublishReason(value)), [dispatch]);

    const publishPlaybook = useCallback(() => dispatch(publishPlaybookStarted()), [dispatch]);
    const savePlaybook = useCallback(() => dispatch(savePlaybookStarted()), [dispatch]);

    const saveOrPublish = useCallback(() => versionUpdated ? publishPlaybook() : savePlaybook(), [versionUpdated, publishPlaybook, savePlaybook]);

    const confirmLabel = useMemo(() => {
        if (versionUpdated) {
            return isDraft ? 'Publish as Draft' : 'Publish';
        }
        return 'Save';
    }, [versionUpdated, isDraft]);

    const modalHeader = useMemo(() => versionUpdated ? 'Publish' : 'Save', [versionUpdated]);
    const modalIcon = useMemo(() => versionUpdated ? Pencil : SaveFile, [versionUpdated]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={saveOrPublish}
            confirmLabel={confirmLabel}
            testId={testId}
        >
            <div className={styles.saveWrapper}>
                <ModalHeader icon={modalIcon} label={`${modalHeader} Playbook`} testId={`${testId}-confirmation-modal`} />
                {!versionUpdated && <div className={styles.saveSubheader} data-testid={`${testId}-confirmation-modal-subheader`}>You have updated the playbook but not incremented the version.</div>}
                {!versionUpdated && <div className={styles.saveWarning} data-testid={`${testId}-confirmation-modal-warning`}>Are you sure these changes do not warrant a version increment?</div>}
                <VersionControl testId={`${testId}-confirmation-modal-version-control`} />
                {versionUpdated &&
                    <div className={styles.publishSaveWrapper}>
                        <LongText
                            onChange={updateReason}
                            value={publishReason}
                            placeholder='Optional...'
                            label='Reason'
                            maxLength={256}
                            width='calc(100% - 12px)'
                            testId={`${testId}-confirmation-modal-reason`}
                        />
                        <DraftPublishToggle testId={`${testId}-confirmation-modal-draft-toggle`} />
                    </div>
                }
            </div>
        </ ConfirmationModal>
    );
};
