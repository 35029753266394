import { RawDraftContentState } from 'draft-js';
import { isNull, isUndefined, noop } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SectionWrapper } from '.';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { Clause, ClauseTableTab, ClauseTags, fetchAllClausesStarted, resetOpinionClausesTable, setClauseLibraryTableFilters, setClauseTableTab } from '../../../clause-library/store';
import { IconButton } from '../../../shared/button/IconButton';
import { PlusButton } from '../../../shared/button/PlusButton';
import { ClauseBuilder } from '../../../shared/clause-library/ClauseBuilder';
import { ClauseModal } from '../../../shared/clause-library/ClauseModal';
import { filterDropdownOptions } from '../../../clause-library/ClauseTable';
import { Document, LinkChain, LongText } from '../../../shared/icons';
import { Action } from '../../../shared/modal/ActionModal';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../shared/table/ArkTable';
import { ArkOpinion } from '../../my-opinions/store';
import { SignOffConversationIcon } from '../sign-off';
import { Toggle } from '../fields/Toggle';
import { AdditionalProvisions, OpinionClause, UpdatedOpinionField, addOpinionClauseClientTagStarted, checkOpinionClauseFuzzyMatch, clearOpinionClausesTableFilters, duplicateOpinionClause, fetchOpinionClauseLibraryDropdownOptionsStarted, toggleLinkOpinionClauseModal, getAllOpinionClauseIds, getAllOpinionClauses, getAllOpinionClientTags, getOpinionClause, getOpinionClauseCanSave, getOpinionClauseColumnSort, getOpinionClauseDescriptionOpen, getOpinionClauseFilters, getOpinionClauseLibraryDropdownOptions, getOpinionClauseModalOpen, getOpinionClauseTableIsLoading, getOpinionClauseTagTerm, getOpinionClausesPageNumber, getOpinionFuzzyMatchModalOpen, getOpinionFuzzyMatchResults, getTotalOpinionClauses, linkOpinionClause, openOpinionClause, opinionClausesPaginationNext, opinionClausesPaginationPrevious, saveOpinionClauseStarted, setOpinionClauseFuzzyMatchModalOpen, setOpinionClausesTableColumnSort, setOpinionClausesTableFilters, toggleOpinionClauseDescription, toggleOpinionClauseModal, updateFieldValue, updateOpinionClause, updateOpinionClauseTag, updateOpinionClauseTagTerm, getFieldSignOffNotes, getAllOpinionClauseLibraryTags } from '../store';
import { LinkOpinionClauseModal } from './LinkOpinionClausesModal';
import styles from './OpinionSection.module.scss';

const TABLE_HEADER = 50;
const TABLE_ROW_HEIGHT = 30;
const BOTTOM_ROW_PADDING = 2;
const TABLE_FOOTER = 32;

export const opinionClauseColumnDefs = (clauseActions: (data: OpinionClause) => Action[]): ArkTableColumn[] => [
    { id: 'title', header: 'Title', field: 'clauseTitle', width: 0.3, component: 'tooltip', canFilter: true, canSort: true },
    { id: 'description', header: 'Description', field: 'clauseDescription', width: 0.3, component: 'iconTooltip', icon: LongText, canFilter: true, canSort: false },
    { id: 'tags', header: 'Tags', field: 'tags', width: 0.3, component: 'tooltipListLabel', canFilter: true, canSort: true },
    { id: 'actions', header: '', field: '', width: 0.1, component: 'action', actions: clauseActions }
];

interface ClauseSectionProps {
    width: number;
    opinion: ArkOpinion;
    isEditing: boolean;
    isUpdating: boolean;
    additionalProvisions: AdditionalProvisions;
    fieldsUpdated: UpdatedOpinionField[];
    testId?: string;
}

export const ClauseSection: React.FC<ClauseSectionProps> = ({ width, opinion, isUpdating, isEditing, additionalProvisions, fieldsUpdated }) => {
    useFetchStarted([fetchOpinionClauseLibraryDropdownOptionsStarted()]);
    const { opinionId, bespoke, jurisdiction } = opinion;
    const { noneRequired } = additionalProvisions;

    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getOpinionClauseTableIsLoading);
    const clauses = useAppSelector(getAllOpinionClauses);
    const allOpinionClauseIds = useAppSelector(getAllOpinionClauseIds);
    const clauseCanSave = useAppSelector(getOpinionClauseCanSave);
    const tagTerm = useAppSelector(getOpinionClauseTagTerm);
    const clauseLibraryDropdownOptions = useAppSelector(getOpinionClauseLibraryDropdownOptions);
    const fuzzyTags = useAppSelector(getOpinionFuzzyMatchResults);
    const fuzzyMatchModalOpen = useAppSelector(getOpinionFuzzyMatchModalOpen);
    const allClientTags = useAppSelector(getAllOpinionClientTags);
    const isIndustryStandard = useMemo(() => bespoke === 0, [bespoke]);
    const pageNumber = useAppSelector(getOpinionClausesPageNumber);
    const totalClauses = useAppSelector(getTotalOpinionClauses);
    const clauseFilters = useAppSelector(getOpinionClauseFilters);
    const columnSort = useAppSelector(getOpinionClauseColumnSort);
    const clauseModalOpen = useAppSelector(getOpinionClauseModalOpen);
    const clause = useAppSelector(getOpinionClause);
    const clauseDescriptionOpen = useAppSelector(getOpinionClauseDescriptionOpen);
    const allOpinionClauseLibraryTags = useAppSelector(getAllOpinionClauseLibraryTags);
    const hasSystemClausePermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.SYSTEM_CLAUSE_MANAGEMENT]));

    const nextPage = useCallback(() => dispatch(opinionClausesPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(opinionClausesPaginationPrevious()), [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setOpinionClausesTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearOpinionClausesTableFilters()), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setOpinionClausesTableColumnSort(columnSort)), [dispatch]);
    const openClause = useCallback(({ clauseId }: OpinionClause) => dispatch(openOpinionClause(clauseId)), [dispatch]);
    const toggleClauseModal = useCallback(() => dispatch(toggleOpinionClauseModal(isIndustryStandard ? 1 : 0)), [dispatch, isIndustryStandard]);
    const opinionClauseLinking = useCallback((clauseId: number) => {
        const isAlreadyLinked = allOpinionClauseIds.includes(clauseId);
        dispatch(linkOpinionClause(opinionId, clauseId, isAlreadyLinked));
    }, [dispatch, opinionId, allOpinionClauseIds]);
    const saveClause = useCallback((close: boolean) => dispatch(saveOpinionClauseStarted(opinionId, close)), [dispatch, opinionId]);
    const updateClauseTag = useCallback((tagType: keyof ClauseTags, tags: string[] | null) => dispatch(updateOpinionClauseTag(tagType, tags)), [dispatch]);
    const updateTagTerm = useCallback((value: string) => dispatch(updateOpinionClauseTagTerm(value)), [dispatch]);
    const addClientTagStarted = useCallback((value: string) => dispatch(addOpinionClauseClientTagStarted(value)), [dispatch]);
    const setFuzzyMatchModalOpen = useCallback((value: boolean) => dispatch(setOpinionClauseFuzzyMatchModalOpen(value)), [dispatch]);
    const checkFuzzyMatch = useCallback(() => dispatch(checkOpinionClauseFuzzyMatch()), [dispatch]);
    const openLinkClausesModal = useCallback(() => {
        const clauseTableTab = isIndustryStandard ? ClauseTableTab.CURRENT_TEMPLATES : ClauseTableTab.CLIENT_CLAUSE_LIBRARY;
        dispatch(setClauseTableTab(clauseTableTab));
        if (isIndustryStandard && !isNull(jurisdiction)) {
            dispatch(setClauseLibraryTableFilters('jurisdictionTags', jurisdiction, 'text'));
        }
        dispatch(toggleLinkOpinionClauseModal());
        dispatch(fetchAllClausesStarted(1));
    }, [dispatch, isIndustryStandard, jurisdiction]);
    const duplicate = useCallback((duplicateClauseId: number, isSave: boolean) => dispatch(duplicateOpinionClause(duplicateClauseId, isSave, hasSystemClausePermission)), [dispatch, hasSystemClausePermission]);
    const toggleDescription = useCallback(() => dispatch(toggleOpinionClauseDescription()), [dispatch]);
    const updateClause = useCallback((key: keyof Clause, value: RawDraftContentState | null | string | string[] | number) => dispatch(updateOpinionClause(key, value)), [dispatch]);

    const clauseActions = useCallback((data: OpinionClause): Action[] => [
        { label: 'Open', icon: Document, onClick: () => openClause(data) },
        { label: 'Remove Link', isDelete: true, icon: LinkChain, onClick: () => opinionClauseLinking(data.clauseId) },
    ], [openClause, opinionClauseLinking]);

    const tableHeight = TABLE_HEADER + (TABLE_ROW_HEIGHT * 5) + BOTTOM_ROW_PADDING + TABLE_FOOTER;
    const displayButtons = useMemo(() => (isEditing || isUpdating) && (opinion.bespoke === 1 || (opinion.bespoke === 0 && hasSystemClausePermission)) && !noneRequired.value, [isEditing, isUpdating, opinion, hasSystemClausePermission, noneRequired.value]);

    // No Additional Provisions Required
    const sectionId = 'additionalProvisions';
    const fieldId = 'noneRequired';
    const noneRequiredId = `${sectionId}-${fieldId}`;
    const fieldUpdatedByInstance = useMemo(() => fieldsUpdated.map(({ fieldId, sectionId }) => `${sectionId}-${fieldId}`).includes(noneRequiredId), [fieldsUpdated, noneRequiredId]);
    const showFieldUpdated = fieldUpdatedByInstance && !isUpdating && !isEditing;
    const nonRequiredDisabled = (!isEditing && !(isUpdating && fieldUpdatedByInstance)) || clauses.length > 0;
    const updateValue = useCallback((value: boolean) => { dispatch(updateFieldValue(sectionId, fieldId, value)); }, [dispatch, sectionId, fieldId]);

    const signOffConversation = useAppSelector(getFieldSignOffNotes(sectionId));

    useEffect(() => () => {
        if (isIndustryStandard) {
            dispatch(resetOpinionClausesTable(ClauseTableTab.CURRENT_TEMPLATES));
            dispatch(resetOpinionClausesTable(ClauseTableTab.HISTORIC_TEMPLATES));
            dispatch(resetOpinionClausesTable(ClauseTableTab.MISCELLANEOUS_TEMPLATE));
        } else {
            dispatch(resetOpinionClausesTable(ClauseTableTab.CLIENT_CLAUSE_LIBRARY));
        }
    }, [dispatch, isIndustryStandard]);

    return (
        <>
            <SectionWrapper id={sectionId} label='Additional Provisions' isEditing={isEditing} isUpdating={isUpdating}>
                <div>
                    <div className={styles.noneRequiredSectionWrapper}>
                        <div className={styles.noneRequiredWrapper}>
                            <div className={styles.noneRequiredLabel}>{noneRequired.label}</div>
                            <Toggle updateValue={updateValue} disabled={nonRequiredDisabled} showFieldUpdated={showFieldUpdated} id={noneRequiredId} value={noneRequired.value} />
                        </div>
                        {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={sectionId} />}
                    </div>
                    {displayButtons &&
                        <div className={styles.clauseLibraryTitleContainer}>
                            <PlusButton onClick={toggleClauseModal} fontSize={20} disabled={noneRequired.value} />
                            <IconButton margin='5px' onClick={openLinkClausesModal} testId='edit-client-tags' icon={LinkChain} fontSize={18} withBorder disabled={noneRequired.value} />
                        </div>
                    }
                    {!noneRequired.value &&
                        <div className={styles.clauseTableWrapper}>
                            <ArkTable
                                width={width}
                                height={tableHeight}
                                colDefs={opinionClauseColumnDefs(clauseActions)}
                                rowData={clauses}
                                testId='client-clauses'
                                isLoading={isLoading}
                                page={pageNumber}
                                total={totalClauses}
                                next={nextPage}
                                previous={previousPage}
                                filters={clauseFilters}
                                updateFilter={updateFilter}
                                clearAllFilters={clearAllFilters}
                                onRowDoubleClicked={row => openClause(row as OpinionClause)}
                                columnSort={columnSort}
                                toggleSort={toggleColumnSort}
                                pageSize={5}
                                updatePageSize={noop}
                                filterDropdownOptions={filterDropdownOptions(clauseLibraryDropdownOptions)}
                                showPageSize={false}
                            />
                        </div>
                    }
                </div>
            </SectionWrapper>
            <ClauseModal
                isOpen={clauseModalOpen}
                closeModal={toggleClauseModal}
            >
                <ClauseBuilder
                    clause={clause}
                    clauseCanSave={clauseCanSave}
                    descriptionOpen={clauseDescriptionOpen}
                    toggleClauseModal={toggleClauseModal}
                    updateClause={updateClause}
                    saveClause={saveClause}
                    toggleDeleteClauseModal={noop}
                    duplicateClause={duplicate}
                    toggleClauseDescription={toggleDescription}
                    testId='opinion-clauses'
                    tagTerm={tagTerm}
                    clauseLibraryDropdownOptions={clauseLibraryDropdownOptions}
                    fuzzyTags={fuzzyTags}
                    fuzzyMatchModalOpen={fuzzyMatchModalOpen}
                    allClientTags={allClientTags}
                    updateClauseTag={updateClauseTag}
                    updateTagTerm={updateTagTerm}
                    checkFuzzyMatch={checkFuzzyMatch}
                    addClientTagStarted={addClientTagStarted}
                    setFuzzyMatchModalOpen={setFuzzyMatchModalOpen}
                    showDelete={false}
                    opinionClauseLinking={opinionClauseLinking}
                    opinionClauses={clauses}
                    hasSystemClausePermission={hasSystemClausePermission}
                    showConfirmationModal={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    allClauseLibraryTags={allOpinionClauseLibraryTags}
                />
            </ClauseModal>
            <LinkOpinionClauseModal
                opinionId={opinionId}
                isIndustryStandard={isIndustryStandard}
                hasSystemClausePermission={hasSystemClausePermission}
            />
        </>
    );
};
