import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Button } from '../../../shared/button/Button';
import { IconButton } from '../../../shared/button/IconButton';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Search } from '../../../shared/icons';
import styles from './Theoretical.module.scss';
import { NettingEngineSurvey, NettingEngineSubQuestion, NettingEngineQuestionType, NettingEngineTheory, updateNettingEngineTheory, NettingEngineCounterparty, setShowSummary, setLeiModalOpen, getIsChangingQuestion, setIsChangingQuestion } from '../store';
import { NettingEngineEntity } from '../../../admin/entity/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { FeaturePermission } from '../../../admin/users/store';
import { CreatableDropdown } from '../../../shared/dropdown/CreateableDropdown';

interface TheoreticalSurveyQuestionsProps {
    surveyQuestionIndex: number;
    nettingEngineSurvey: NettingEngineSurvey[];
    counterpartySubQuestion: NettingEngineSubQuestion;
    allEntities: NettingEngineEntity[];
    setQuestionIndex: (index: number) => void;
    counterpartyNameKnown: boolean | null;
    jurisdictionOptions: { value: string, label: string }[];
    updateCounterpartyOfficeOptions: (value: DropdownOption[]) => void;
}

export const TheoreticalSurveyQuestions: React.FC<TheoreticalSurveyQuestionsProps> = ({
    surveyQuestionIndex,
    nettingEngineSurvey,
    counterpartySubQuestion,
    allEntities,
    setQuestionIndex,
    counterpartyNameKnown,
    jurisdictionOptions,
    updateCounterpartyOfficeOptions
}) => {
    const changingQuestion = useAppSelector(getIsChangingQuestion);

    const dispatch = useAppDispatch();

    const selectedCounterpartyJurisdiction = useCallback((entityId: string | null) => !isNull(entityId) && allEntities.find(entity => entity.entityId === parseInt(entityId!))?.jurisdiction || undefined, [allEntities]);
    const selectedCounterpartyType = useCallback((entityId: string | null) => {
        if (!isNull(entityId)) {
            const entityInQuestion = allEntities.find(entity => entity.entityId === parseInt(entityId!));
            if (!isUndefined(entityInQuestion)) {
                if (!isNull(entityInQuestion.opinionCounterpartyType)) {
                    const { aiConfirmed, value } = entityInQuestion.opinionCounterpartyType;
                    if (!isNull(value)) {
                        return { value, aiConfirmed };
                    }
                }
            }
        }
        return undefined;
    }, [allEntities]);

    const unknownProductWarning = 'Note: if you select "No", we will return a synopsis of all products in Ark 51 from the perspective of close-out netting.';
    const counterpartyNotMultiBranch = 'Note: if you select "No", it will be assumed that the counterparty is operating from an office in the jurisdiction of its incorporation.';

    const transitionToQuestionByIncrement = useCallback((increment = 1) => {
        setTimeout(() => {
            setQuestionIndex(surveyQuestionIndex + increment);
            dispatch(setIsChangingQuestion(false));
        }, 750);
    }, [dispatch, setQuestionIndex, surveyQuestionIndex]);

    const transitionToNextQuestion = useCallback(() => transitionToQuestionByIncrement(), [transitionToQuestionByIncrement]);

    const updateDropdownAnswer = useCallback((option: DropdownOption | Options<DropdownOption> | null, key: keyof NettingEngineTheory, counterpartyKey?: keyof NettingEngineCounterparty, isMulti?: boolean) => {
        if (isMulti) {
            let selected: string[] = [];
            if (!isNull(option) && (option as Options<DropdownOption>).length > 0) {
                selected = (option as Options<DropdownOption>).map(({ value }) => value);
            }
            dispatch(updateNettingEngineTheory(key, selected));
        } else {
            if (!isNull(option)) {
                const value = (option as DropdownOption).value;
                if (!isUndefined(counterpartyKey) && counterpartyKey === 'entityId') {
                    dispatch(updateNettingEngineTheory(key, value, counterpartyKey));
                    const jurisdiction = selectedCounterpartyJurisdiction(value);
                    if (!isUndefined(jurisdiction)) {
                        if (!isNull(jurisdiction)) {
                            const matchedJurisdiction = jurisdictionOptions.map(({ value }) => value).find(eachJurisdiction => eachJurisdiction === jurisdiction);
                            if (!isUndefined(matchedJurisdiction)) {
                                dispatch(setIsChangingQuestion(true));
                                dispatch(updateNettingEngineTheory(key, matchedJurisdiction, 'jurisdiction'));
                                const counterpartyType = selectedCounterpartyType(value);
                                if (!isUndefined(counterpartyType) && counterpartyType.value.length > 0) {
                                    dispatch(updateNettingEngineTheory(key, counterpartyType.value, 'counterpartyClassification'));
                                    if (counterpartyType.aiConfirmed) {
                                        transitionToQuestionByIncrement(2);
                                    } else {
                                        transitionToNextQuestion();
                                    }
                                } else {
                                    transitionToNextQuestion();
                                }
                            }
                        }
                    }
                } else {
                    dispatch(setIsChangingQuestion(true));
                    dispatch(updateNettingEngineTheory(key, value, counterpartyKey));
                    transitionToNextQuestion();
                }
            } else {
                dispatch(updateNettingEngineTheory(key, null, counterpartyKey));
            }
            if (surveyQuestionIndex + 1 === nettingEngineSurvey.length) {
                setTimeout(() => {
                    dispatch(setIsChangingQuestion(false));
                }, 750);
            }
        }
    }, [dispatch, nettingEngineSurvey, surveyQuestionIndex, transitionToNextQuestion, transitionToQuestionByIncrement, selectedCounterpartyJurisdiction, jurisdictionOptions, selectedCounterpartyType]);

    const updateBooleanAnswer = useCallback((value: boolean, key: keyof NettingEngineTheory) => {
        dispatch(updateNettingEngineTheory(key, value));
        dispatch(setIsChangingQuestion(true));
        if (key === 'productTypeKnown' && !value) {
            transitionToQuestionByIncrement(2);
        } else if (key === 'counterpartyIsMultiBranch' && !value) {
            dispatch(setShowSummary(true));
        } else {
            transitionToNextQuestion();
        }
    }, [dispatch, transitionToNextQuestion, transitionToQuestionByIncrement]);

    const hasEntityManagementPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.ENTITY_MANAGEMENT]));
    const showLeiSearch = useMemo(() => hasEntityManagementPermission && surveyQuestionIndex === 1 && !!counterpartyNameKnown, [hasEntityManagementPermission, surveyQuestionIndex, counterpartyNameKnown]);

    const openLeiSearch = useCallback(() => dispatch(setLeiModalOpen(true)), [dispatch]);

    const { question, type, key, counterpartyKey, value, dropdownOptions, isMulti, closeOnSelect } = nettingEngineSurvey[surveyQuestionIndex];

    const surveyNote = useMemo(() => {
        if (surveyQuestionIndex === 5) {
            return unknownProductWarning;
        }
        if (surveyQuestionIndex === 7) {
            return counterpartyNotMultiBranch;
        }
        return '';
    }, [surveyQuestionIndex]);

    return (
        <div className={styles.surveyQuestionsWrapper}>
            <div className={classnames(styles.surveyQuestion, { [styles.surveyQuestionFadeIn]: !changingQuestion && surveyQuestionIndex !== 0, [styles.surveyQuestionFadeOut]: changingQuestion })}>{question}</div>
            {type === NettingEngineQuestionType.BOOLEAN ?
                <>
                    <div className={styles.surveyButtonWrapper}>
                        <Button label='Yes' onClick={() => updateBooleanAnswer(true, key)} />
                        <Button label='No' onClick={() => updateBooleanAnswer(false, key)} />
                    </div>
                    <div className={styles.surveyMessage}>{surveyNote}</div>
                </>
                :
                <div className={styles.surveyInput}>
                    <div className={styles.surveyDropdownWrapper}>
                        {key === 'counterpartyOffices' ? <CreatableDropdown
                            value={(value as DropdownOption)}
                            options={dropdownOptions!}
                            onChange={value => updateDropdownAnswer(value, key, counterpartyKey, isMulti)}
                            updateOptions={updateCounterpartyOfficeOptions}
                            isMulti={isMulti}
                            closeOnSelect={closeOnSelect}
                        /> :
                            <Dropdown
                                value={(value as DropdownOption)}
                                options={dropdownOptions!}
                                onChange={value => updateDropdownAnswer(value, key, counterpartyKey, isMulti)}
                                isMulti={isMulti}
                                closeOnSelect={closeOnSelect}
                            />
                        }
                        {surveyQuestionIndex === 1 && !!counterpartyNameKnown &&
                            <div className={styles.subQuestionWrapper}>
                                <div className={styles.counterpartySubQuestion}>Counterparty Jurisdiction</div>
                                <Dropdown
                                    value={(counterpartySubQuestion.value as DropdownOption)}
                                    options={counterpartySubQuestion.dropdownOptions!}
                                    onChange={val => updateDropdownAnswer(val, counterpartySubQuestion.key, counterpartySubQuestion.counterpartyKey)}
                                />
                            </div>
                        }
                    </div>
                    <div className={styles.inputWrapper}>
                        {showLeiSearch && <IconButton icon={Search} onClick={openLeiSearch} fontSize={35} />}
                    </div>
                </div>
            }
        </div>
    );
};
