import React from 'react';

export const OpinionReporting: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 256 256">
        <path fill="none" d="M0 0h256v256H0z" />
        <circle cx="128" cy="128" fill="none" r="96" stroke={color || 'currentColor'} strokeMiterlimit="10" strokeWidth="16" />
        <path fill="none" stroke={color || 'currentColor'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" d="M37.5 96h181M37.5 160h181" />
        <ellipse cx="128" cy="128" fill="none" rx="40" ry="93.4" stroke={color || 'currentColor'} strokeMiterlimit="10" strokeWidth="16" />
        <g>
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={16} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="130.6" y1="183.4" x2="20.2" y2="183.4" filter='drop-shadow(3px 5px 4px #999999)' />
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={16} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="93.6" y1="225.8" x2="137.4" y2="183.4" filter='drop-shadow(3px 5px 4px #999999)' />
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={16} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="93.6" y1="140" x2="137.4" y2="182.6" />
        </g>
    </svg>
);
