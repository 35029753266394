import { getOr, isNull, noop } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { ANALYSIS_MARGIN, NAV_BAR, PAGE_MARGIN_PADDING_BORDER, PAGE_VERTICAL_MARGIN_PADDING_BORDER, useSplitView } from '../../../hooks/useSplitView';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { ClientFeaturePermission } from '../../admin/users/store';
import { getClientHasFeaturePermission } from '../../auth/login/store';
import { getDatasetInstance } from '../../datasets/instances/store';
import styles from './OpinionAnalysis.module.scss';
import { AnalysisView, SelectedDocument } from '../../documents/my-documents/store';
import { Analysis, Tabs } from '../../shared/analysis/Analysis';
import { AnalysisSideMenu } from '../../shared/analysis/AnalysisSideMenu';
import { OpinionInstancesWrapper } from '../instances/OpinionInstancesWrapper';
import { getAdditionalOpinions, getIsOpening, getOpinion, getOpinionAnalysisView, getSelectedAdditionalOpinionId, getSelectedOpinionUrl, getSelectedPageNumber, openOpinionAndInstance, setAdditionalOpinionSelected, toggleOpinionAnalysisView, setSelectedOpinionPage } from '../my-opinions/store';
import { OpinionCommissionedBy } from '../../admin/opinions/store';

interface OpinionAnalysisRouteParams {
    opinionId: string | undefined;
    datasetInstanceId: string | undefined;
}

export const OpinionAnalysis: React.FC<RouteComponentProps<OpinionAnalysisRouteParams>> = ({ match }) => {
    const dispatch = useAppDispatch();
    const [screenWidth, screenHeight] = useWindowResize();
    const datasetInstance = useAppSelector(getDatasetInstance);
    const opinion = useAppSelector(getOpinion);
    const url = useAppSelector(getSelectedOpinionUrl);
    const additionalOpinions = useAppSelector(getAdditionalOpinions);
    const selectedAdditionalOpinionId = useAppSelector(getSelectedAdditionalOpinionId);
    const isOpening = useAppSelector(getIsOpening);
    const opinionAnalysisView = useAppSelector(getOpinionAnalysisView);
    const selectedPage = useAppSelector(getSelectedPageNumber);

    const hasViewISDAOpinionPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ISDA]));
    const hasViewICMAOpinionPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ICMA]));
    const hasViewISLAOpinionPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ISLA]));

    const [pageWidth, pageHeight] = useSplitView(opinionAnalysisView, PAGE_MARGIN_PADDING_BORDER, PAGE_VERTICAL_MARGIN_PADDING_BORDER);

    const toggleAnalysisView = useCallback((view: AnalysisView) => view !== opinionAnalysisView && dispatch(toggleOpinionAnalysisView(view)), [dispatch, opinionAnalysisView]);
    const selectAdditionalOpinion = useCallback((opinionId: number | null) => opinionId !== selectedAdditionalOpinionId && dispatch(setAdditionalOpinionSelected(opinionId)), [dispatch, selectedAdditionalOpinionId]);

    const setSelectedPage = useCallback((page: number | null) => dispatch(setSelectedOpinionPage(page)), [dispatch]);

    const additionalTabOptions = useMemo(() => {
        if (additionalOpinions.length) {
            return additionalOpinions.map(({ type, opinionId, description }) => ({
                title: description,
                onClick: () => selectAdditionalOpinion(opinionId),
                selected: selectedAdditionalOpinionId === opinionId,
                type
            }));
        }
        return [{ title: '', onClick: noop, selected: false }];
    }, [additionalOpinions, selectedAdditionalOpinionId, selectAdditionalOpinion]);

    const tabOptions = useMemo<Tabs[]>(() => [
        {
            title: getOr('', 'description', opinion),
            onClick: () => selectAdditionalOpinion(null),
            selected: isNull(selectedAdditionalOpinionId),
            type: opinion?.type || undefined
        },
        ...additionalTabOptions
    ], [opinion, additionalTabOptions, selectAdditionalOpinion, selectedAdditionalOpinionId]);

    const selectedOpinion = useMemo(() => {
        if (isNull(selectedAdditionalOpinionId)) {
            return opinion;
        }
        return additionalOpinions.find(({ opinionId }) => opinionId === selectedAdditionalOpinionId) || null;
    }, [selectedAdditionalOpinionId, additionalOpinions, opinion]);

    const isPermittedToView = useCallback((commissionedBy: OpinionCommissionedBy | string | null) => {
        switch (commissionedBy) {
            case OpinionCommissionedBy.ISDA:
                return hasViewISDAOpinionPermission;
            case OpinionCommissionedBy.ISLA:
                return hasViewISLAOpinionPermission;
            case OpinionCommissionedBy.ICMA:
                return hasViewICMAOpinionPermission;
            default:
                return false;
        }
    }, [hasViewISDAOpinionPermission, hasViewISLAOpinionPermission, hasViewICMAOpinionPermission]);

    const isPermittedToChangeView = useMemo(() => !!opinion && (!!opinion.bespoke || isPermittedToView(opinion.commissionedBy)), [isPermittedToView, opinion]);

    const { params } = match;

    useEffect(() => {
        if (!datasetInstance && !opinion && params.opinionId) {
            dispatch(openOpinionAndInstance(params.opinionId, params.datasetInstanceId));
        }
    }, [params, datasetInstance, dispatch, opinion]);

    const [width] = useSplitView(opinionAnalysisView, PAGE_MARGIN_PADDING_BORDER + 2, PAGE_VERTICAL_MARGIN_PADDING_BORDER);

    if (!params.opinionId) {
        return (
            <Redirect to='/home' />
        );
    }

    const analysisWrapperDimensions = { height: `${screenHeight - NAV_BAR - ANALYSIS_MARGIN}px`, width: `${screenWidth}px` };
    const pageDimensions = { height: `${pageHeight}px`, width: `${pageWidth}px` };

    return (
        <div className={styles.analysisWrapper} style={analysisWrapperDimensions}>
            <AnalysisSideMenu toggleAnalysisView={toggleAnalysisView} currentView={opinionAnalysisView} isPermittedToChangeView={isPermittedToChangeView} />
            <div className={styles.splitWrapper}>
                {opinionAnalysisView !== AnalysisView.DATASET &&
                    <div className={styles.documentAnalysisWrapper} style={pageDimensions}>
                        <Analysis
                            selected={selectedOpinion}
                            url={url}
                            documentInView={SelectedDocument.ORIGINAL}
                            isOpening={isOpening}
                            tabOptions={tabOptions}
                            documentAnalysisView={opinionAnalysisView}
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                            width={width}
                        />
                    </div>
                }
                {opinionAnalysisView !== AnalysisView.DOCUMENT &&
                    <div className={styles.datasetAnalysisWrapper} style={pageDimensions}>
                        <OpinionInstancesWrapper />
                    </div>
                }
            </div>
        </div>
    );
};
