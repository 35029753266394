import { getOr, isNull } from 'lodash/fp';
import React, { useCallback } from 'react';
import classnames from 'classnames';

import { useAppSelector } from '../../../hooks/react-redux';
import { Button } from '../../shared/button/Button';
import { Icon } from '../../shared/icon/Icon';
import { Eye, Home } from '../../shared/icons';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { CustomTooltip, OverflowTooltip } from '../../shared/tooltip';
import { Condition, getSimpleSearchTerm, getSmartSearchTerm, SearchDocumentsView, SearchResult } from '../search/store';
import styles from './SearchDocuments.module.scss';
import { DocumentType } from '../my-documents/store';

interface ResultProps {
    result: SearchResult;
    resultsType: SearchDocumentsView;
    openPreview: (result: SearchResult) => void;
    openDocument: (documentId: string, datasetInstanceId: string) => void;
    completeDocument: (documentId: number) => void;
}

export const Result: React.FC<ResultProps> = ({ result, openPreview, openDocument, completeDocument, resultsType }) => {
    const { documentId, documentType, datasetInstanceId, textSnippet, originalDocumentId, linkedEntities, entityActingAsAgent } = result;
    const simpleSearchTerm = useAppSelector(getSimpleSearchTerm);
    const smartSearchTerm = useAppSelector(getSmartSearchTerm);
    const documentIdToOpen = !isNull(originalDocumentId) ? originalDocumentId.toString() : documentId.toString();

    // Dataset instances are created for documents once they are completed
    const incompleteDocument = !result.datasetInstanceId;
    const openLabel = incompleteDocument ? 'Complete' : 'Open';
    const openSearchResult = () => incompleteDocument ? completeDocument(documentId) : openDocument(documentIdToOpen, datasetInstanceId!.toString());

    const getSearchTerm = (index: number, textSnippet: string) => {
        let i = index;
        const includesOnly = smartSearchTerm.filter(({ condition }) => condition === Condition.INCLUDES);
        while (textSnippet.toLowerCase().indexOf(includesOnly[i].searchTerm.toLowerCase()) === - 1) {
            i++;
        }
        return includesOnly[i].searchTerm;
    };

    const getTextSnippet = (textSnippet: string, index: number) => {
        const searchTerm = resultsType === SearchDocumentsView.SIMPLE_SEARCH ? simpleSearchTerm : getSearchTerm(index, textSnippet);
        const startWordIndex = textSnippet.toLowerCase().indexOf(searchTerm.toLowerCase());
        const endWordIndex = startWordIndex + searchTerm.length;
        return (
            <div className={styles.resultTextSnippet}>
                <span>{textSnippet.slice(0, startWordIndex)}</span>
                <span className={styles.resultKeyword}>{textSnippet.slice(startWordIndex, endWordIndex)}</span>
                <span>{textSnippet.slice(endWordIndex, textSnippet.length - 1)}</span>
            </div>
        );
    };

    const getTooltipContent = useCallback((entityName: string) => {
        if (linkedEntities && entityName === entityActingAsAgent) {
            return `${linkedEntities} parties included in Agency Agreement`;
        }
        return null;
    }, [linkedEntities, entityActingAsAgent]);

    const showEntityAIcon = result.entityAMyCompany === 1 || result.entityAOnWatchlist === 1;
    const showEntityBIcon = result.entityBMyCompany === 1 || result.entityBOnWatchlist === 1;

    const entityAIcon = showEntityAIcon && result.entityAMyCompany === 1 ? Home : Eye;
    const entityBIcon = showEntityBIcon && result.entityBMyCompany === 1 ? Home : Eye;

    const entityADisplayName = result.entityAName || 'Unlinked Entity';
    const entityBDisplayName = result.entityBName || 'Unlinked Entity';
    const documentDescriptionDisplayName = getOr('Unnamed document', 'documentDescription', result);
    const documentNameDisplayName = result.documentName || 'Unlinked Document';
    const preExecutionLabel = documentType === DocumentType.PRE_EXECUTION_DRAFT ? 'PRE-EXECUTION' : '';

    return (
        <div key={documentId} className={styles.resultWrapper}>
            <div className={styles.resultHeader}>
                <div className={styles.resultHeaderColumn}>
                    <OverflowTooltip className={styles.resultDescription} overlayText={documentDescriptionDisplayName} />
                    <OverflowTooltip className={styles.resultDocumentName} overlayText={documentNameDisplayName} />
                </div>
                <div className={styles.resultHeaderEntityColumn}>
                    <div className={styles.entityNameWrapper}>
                        <CustomTooltip overlayText={getTooltipContent(entityADisplayName)} placement='bottom'><div className={classnames(styles.resultEntity, { [styles.resultIconEntity]: showEntityAIcon })}>{entityADisplayName}</div></CustomTooltip>
                        {showEntityAIcon && <Icon icon={entityAIcon} fontSize={20} />}
                    </div>
                    <div className={styles.entityNameWrapper}>
                        <CustomTooltip overlayText={getTooltipContent(entityBDisplayName)} placement='bottom'><div className={classnames(styles.resultEntity, { [styles.resultIconEntity]: showEntityBIcon })}>{entityBDisplayName}</div></CustomTooltip>
                        {showEntityBIcon && <Icon icon={entityBIcon} fontSize={20} />}
                    </div>
                </div>
            </div>
            <div className={styles.resultPagesHeader}>Document Snippet</div>
            <div className={styles.resultPagesWrapper}>
                <Scrollable maxHeight='80px'>
                    {textSnippet.map((snippet, index) => (
                        <div className={styles.resultPage} key={index}>
                            {getTextSnippet(snippet, index)}
                        </div>
                    ))}
                </Scrollable>
            </div>
            <div className={styles.resultButtonsWrapper}>
                <div className={styles.preExecutionLabel}>{preExecutionLabel}</div>
                <div className={styles.rightAlignedButtons}>
                    <Button label='Preview' onClick={() => openPreview(result)} />
                    <Button label={openLabel} onClick={openSearchResult} />
                </div>
            </div>
        </div>
    );
};
