import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNull, isUndefined } from 'lodash/fp';
import { RawDraftContentState } from 'draft-js';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Spinner } from '../../shared/spinner/Spinner';
import styles from './Playbook.module.scss';
import { PlaybookTimeline } from '../../shared/playbook/playbook-history/PlaybookHistory';
import { Playbook } from '../../shared/playbook/view/Playbook';
import { getPlaybook, getPlaybookByIdStarted, getPlaybookHistory, Playbook as PlaybookType, updateExistingPlaybook, getCurrentPlaybook, getPlaybookByDefinitionIdStarted, getCurrentView, changeCurrentView, PlaybookView, getPlaybookQueries, getQueryModalOpen, getSelectedPlaybookQuery, togglePlaybookQueryModal, updateQueryResponse, getQueryResponse, getSendingQueryResponse, sendQueryResponseStarted, getSendEmailReply, toggleSendEmailReply, getQueryMarkedAsResolved, toggleQueryResolved, getOpenDeviations, toggleDeviation, getPlaybookSuggestedChanges, openPlaybookSuggestedChanges, getShowPlaybookViewLoadingSpinner, getResolvedPlaybookSuggestedChanges, setSelectedResolvedSuggestedChange, toggleReadOnlyProvisionLinkModal, openProvisionLink, getReadOnlyProvisionLinksModalOpen, getLinkedProvisionHistory, playbookBackCalled, LinkedPlaybookProvision, PlaybookProvision, toggleProvisionLinkHistoryModal, getLinkedProvisionHistoryModalOpen, isSubheaderSection } from './store';
import { Button } from '../../shared/button/Button';
import { AdminQueryModal } from './AdminQueryModal';
import { getUser } from '../../auth/login/store';
import { ABSTRACT_ID } from '../../constants/playbooks';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllBasicUsersStarted } from '../users/store';
import { isEmpty } from '../../shared/wysiwyg/WYSIWYG';
import { ResolvedSuggestedChangesModal } from './ResolvedSuggestedChangesModal';
import { useLongPress } from '../../../hooks/useLongPress';
import { LinkedProvisionHistoryModal } from '../../shared/playbook/provision-link/LinkedProvisionHistoryModal';
import { LongPressButton } from '../../shared/button/LongPressButton';
import { fetchAvailableDocumentNamesStarted } from '../documents/store';

export interface PlaybookViewerRouteParams {
    playbookId: string | undefined;
}

export const PlaybookViewer: React.FC<RouteComponentProps<PlaybookViewerRouteParams>> = ({ match: { params } }) => {
    const testId = 'admin-playbook';
    const playbook = useAppSelector(getPlaybook);
    const currentPlaybook = useAppSelector(getCurrentPlaybook);
    const playbookHistory = useAppSelector(getPlaybookHistory);
    const currentView = useAppSelector(getCurrentView);
    const queries = useAppSelector(getPlaybookQueries);
    const isOpen = useAppSelector(getQueryModalOpen);
    const query = useAppSelector(getSelectedPlaybookQuery);
    const user = useAppSelector(getUser);
    const queryResponse = useAppSelector(getQueryResponse);
    const sendingResponse = useAppSelector(getSendingQueryResponse);
    const emailReply = useAppSelector(getSendEmailReply);
    const markedAsResolved = useAppSelector(getQueryMarkedAsResolved);
    const openDeviations = useAppSelector(getOpenDeviations);
    const suggestedChanges = useAppSelector(getPlaybookSuggestedChanges);
    const showLoadingSpinner = useAppSelector(getShowPlaybookViewLoadingSpinner);
    const resolvedSuggestedChanges = useAppSelector(getResolvedPlaybookSuggestedChanges);
    const readOnlyProvisionLinksModalOpen = useAppSelector(getReadOnlyProvisionLinksModalOpen);
    const linkedProvisionHistory = useAppSelector(getLinkedProvisionHistory);
    const linkedProvisionHistoryModalOpen = useAppSelector(getLinkedProvisionHistoryModalOpen);
    useFetchStarted([fetchAllBasicUsersStarted(), fetchAvailableDocumentNamesStarted()]);

    const dispatch = useAppDispatch();
    const playbookDefinitionId = useMemo(() => currentPlaybook?.playbookDefinitionId || null, [currentPlaybook]);
    const queryDisabled = useMemo(() => playbookDefinitionId !== query?.playbookDefinitionId, [playbookDefinitionId, query]);

    const onClick = useCallback(() => dispatch(playbookBackCalled()), [dispatch]);
    const onLongPress = useCallback(() => {
        if (linkedProvisionHistory.length > 0) {
            dispatch(toggleProvisionLinkHistoryModal(true));
        }
    }, [dispatch, linkedProvisionHistory]);

    const longPressEvent = useLongPress(onLongPress, onClick);

    const closeLinkHistoryModal = useCallback(() => dispatch(toggleProvisionLinkHistoryModal(false)), [dispatch]);

    const goToHistoricalLink = useCallback((index: number | null) => dispatch(playbookBackCalled(index)), [dispatch]);

    const backButtonLabel = useMemo(() => linkedProvisionHistory.length > 0 ? 'Back' : 'My Playbooks', [linkedProvisionHistory]);
    const updatePlaybook = useCallback((playbook: PlaybookType) => dispatch(updateExistingPlaybook(playbook)), [dispatch]);

    const selectPlaybookTimeline = (value: number | number[]) => {
        const index = (value as number) - 1;
        const playbook = playbookHistory[index];
        if (playbook && playbook.playbookDefinitionId !== currentPlaybook?.playbookDefinitionId) {
            dispatch(getPlaybookByDefinitionIdStarted(playbook.playbookDefinitionId));
        }
    };

    const isTableView = currentView === PlaybookView.TABLE;
    const changeViewLabel = useMemo(() => isTableView ? 'List view' : 'Table view', [isTableView]);
    const changeView = useCallback(() => dispatch(changeCurrentView(isTableView ? PlaybookView.LIST : PlaybookView.TABLE)), [dispatch, isTableView]);

    const openQuery = useCallback((playbookQueryId: number) => dispatch(togglePlaybookQueryModal(playbookQueryId)), [dispatch]);
    const closeQueryModal = useCallback(() => dispatch(togglePlaybookQueryModal(null)), [dispatch]);

    const updateResponse = useCallback((rawValue: RawDraftContentState) => {
        dispatch(updateQueryResponse(rawValue, user!));
        if (isEmpty(rawValue)) {
            if (!isNull(queryResponse?.content)) {
                dispatch(updateQueryResponse(null, user!));
            }
        } else {
            dispatch(updateQueryResponse(rawValue, user!));
        }
    }, [dispatch, user, queryResponse]);
    const sendQueryResponse = useCallback(() => dispatch(sendQueryResponseStarted()), [dispatch]);
    const toggleEmailReply = useCallback(() => dispatch(toggleSendEmailReply()), [dispatch]);
    const toggleResolved = useCallback(() => dispatch(toggleQueryResolved()), [dispatch]);
    const goToDefinition = useCallback((playbookDefinitionId: number, sectionId: string) => {
        dispatch(getPlaybookByDefinitionIdStarted(playbookDefinitionId));
        const section = document.getElementById(sectionId);
        section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, [dispatch]);

    const togglePlaybookDeviation = useCallback((sectionId: string) => dispatch(toggleDeviation(sectionId)), [dispatch]);

    const openSuggestedChanges = useCallback((playbookId: number) => dispatch(openPlaybookSuggestedChanges(playbookId)), [dispatch]);

    const toggleLinkedProvisionModal = useCallback((sectionId: string) => dispatch(toggleReadOnlyProvisionLinkModal(sectionId)), [dispatch]);
    const openLinkToProvision = useCallback((linkTo: LinkedPlaybookProvision) => {
        const provisionTitle = (playbook.content.sections.find(section => section.sectionId === readOnlyProvisionLinksModalOpen!) as PlaybookProvision).title.filter(item => !!item).join(': ');
        const linkFrom = { playbookId: playbook.playbookId!, sectionId: readOnlyProvisionLinksModalOpen!, playbookName: playbook.name, provisionTitle };
        dispatch(openProvisionLink(linkFrom, linkTo));
    }, [dispatch, readOnlyProvisionLinksModalOpen, playbook]);

    useEffect(() => {
        if (!playbook.playbookId && params.playbookId) {
            dispatch(getPlaybookByIdStarted(parseInt(params.playbookId)));
        }
    }, [params, playbook, dispatch]);

    const showUpdate = useMemo(() => !!currentPlaybook?.isCurrent || !!currentPlaybook?.isDraft || false, [currentPlaybook]);

    const provisionTitle = useMemo(() => {
        if (query?.sectionId === ABSTRACT_ID) {
            return 'Abstract';
        }
        const section = currentPlaybook?.content.sections.find(({ sectionId }) => sectionId === query?.sectionId);
        if (section) {
            return isSubheaderSection(section) ? section.title : section.title.filter(item => !!item).join(': ');
        }
        return '';
    }, [query, currentPlaybook]);

    const modalTitle = useMemo(() => `Provision/Section: ${provisionTitle}`, [provisionTitle]);

    const getPlaybookInstanceVersion = useCallback((playbookDefinitionId: number) => {
        const playbookInstance = playbookHistory.find(instance => instance.playbookDefinitionId === playbookDefinitionId);
        if (!isUndefined(playbookInstance)) {
            const { versionMajor, versionMinor } = playbookInstance;
            return `(${versionMajor}.${versionMinor})`;
        }
        return 'Version unknown';
    }, [playbookHistory]);

    const openResolvedSuggestedChange = useCallback((playbookSuggestionId: number | null) => dispatch(setSelectedResolvedSuggestedChange(playbookSuggestionId)), [dispatch]);

    if (!playbook.playbookId || showLoadingSpinner) {
        return (
            <Spinner />
        );
    }

    return (
        <div className={styles.playbookViewWrapper} data-testid={`${testId}-viewer-wrapper`}>
            <PlaybookTimeline timeline={playbookHistory} currentPlaybook={currentPlaybook} onChange={selectPlaybookTimeline} testId={`${testId}-timeline`} />
            <div className={styles.playbookPreview}>
                <Playbook
                    playbook={playbook}
                    currentView={currentView}
                    queries={queries}
                    playbookDefinitionId={playbookDefinitionId}
                    openQuery={openQuery}
                    getPlaybookInstanceVersion={getPlaybookInstanceVersion}
                    openDeviations={openDeviations}
                    toggleDeviation={togglePlaybookDeviation}
                    suggestedChanges={suggestedChanges}
                    isAdmin
                    openAdminSuggestedChanges={openSuggestedChanges}
                    playbookHistory={playbookHistory}
                    resolvedSuggestedChanges={resolvedSuggestedChanges}
                    openResolvedSuggestedChange={openResolvedSuggestedChange}
                    provisionLinkModalOpen={readOnlyProvisionLinksModalOpen}
                    toggleLinkedProvisionModal={toggleLinkedProvisionModal}
                    openLinkToProvision={openLinkToProvision}
                    testId={testId}
                />
            </div>
            <div className={styles.buttonWrapper}>
                <div className={styles.buttonGroup}>
                    <LongPressButton longPressProps={longPressEvent} label={backButtonLabel} testId={`${testId}-back`} />
                    <Button onClick={changeView} label={changeViewLabel} testId={`${testId}-change-view`} />
                </div>
                {showUpdate && <Button onClick={() => updatePlaybook(playbook)} label={playbook.isDraft ? 'Continue Building' : 'Update'} testId={`${testId}-update`} />}
            </div>
            <ResolvedSuggestedChangesModal testId={testId} />
            <AdminQueryModal
                isOpen={isOpen}
                query={query}
                showSpinner={sendingResponse}
                closeModal={closeQueryModal}
                sendResponse={sendQueryResponse}
                updateResponse={updateResponse}
                queryResponse={queryResponse}
                queryDisabled={queryDisabled}
                modalTitle={modalTitle}
                toggleEmailReply={toggleEmailReply}
                emailReply={emailReply}
                toggleResolved={toggleResolved}
                markedAsResolved={markedAsResolved}
                goToDefinition={goToDefinition}
                testId={testId}
            />
            <LinkedProvisionHistoryModal
                isOpen={linkedProvisionHistoryModalOpen}
                closeModal={closeLinkHistoryModal}
                provisionLinkHistory={linkedProvisionHistory}
                linkToProvision={goToHistoricalLink}
            />
        </div>
    );
};
