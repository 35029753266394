import { push } from 'redux-first-history';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchAttestationProgressFailed, fetchAttestationProgressSuccessful, fetchPageUsageAnalyticsFailed, fetchPageUsageAnalyticsSuccessful, redirectAttestation } from './actions';
import { AdminAnalyticsActionTypes, AdminAttestationAnalytics } from './types';
import { fetchAttestationProgressAnalytics, fetchClientPageUsageAnalytics } from '../../../../services/analytics';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

export function* redirectAttestationAnalytics({ payload }: ReturnType<typeof redirectAttestation>) {
    const navigationPath = `/admin/attestation/manager/${payload}`;
    yield put(push(navigationPath));
}

function* redirectAttestationWatcher() {
    yield takeEvery(AdminAnalyticsActionTypes.REDIRECT_ANALYTICS_ATTESTATION, redirectAttestationAnalytics);
}

export function* attemptFetchAttestationProgress() {
    try {
        const analytics: AdminAttestationAnalytics[] = yield call(fetchAttestationProgressAnalytics);
        yield put(fetchAttestationProgressSuccessful(analytics));
    } catch (e) {
        yield put(fetchAttestationProgressFailed((e as Error).message));
    }
}

function* fetchAttestationProgressWatcher() {
    yield takeEvery(AdminAnalyticsActionTypes.FETCH_ATTESTATION_PROGRESS_ANALYTICS_STARTED, attemptFetchAttestationProgress);
}

export function* attemptFetchPageUsageAnalytics() {
    try {
        const analytics: PieChartAnalyticsData[] = yield call(fetchClientPageUsageAnalytics);
        yield put(fetchPageUsageAnalyticsSuccessful(analytics));
    } catch (e) {
        yield put(fetchPageUsageAnalyticsFailed((e as Error).message));
    }
}

function* fetchPageUsageAnalyticsWatcher() {
    yield takeEvery(AdminAnalyticsActionTypes.FETCH_PAGE_USAGE_ANALYTICS_STARTED, attemptFetchPageUsageAnalytics);
}

export function* adminAnalyticsSaga() {
    yield all([
        fork(redirectAttestationWatcher),
        fork(fetchAttestationProgressWatcher),
        fork(fetchPageUsageAnalyticsWatcher)
    ]);
}
