import React from 'react';

export const HamburgerMenu: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg width={width || '1em'} height={height || '1em'} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" xmlSpace="preserve">
        <path fill={color || 'currentColor'} d="M13,31.3L13,31.3c0-3.9,4.1-7.1,9.2-7.1l83.7,0c5,0,9.2,3.2,9.2,7.1l0,0c0,3.9-4.1,7.1-9.2,7.1l-83.8,0
	    C17.1,38.3,13,35.1,13,31.3z"/>
        <path fill={color || 'currentColor'} d="M13.8,63.7L13.8,63.7c0-3.9,4.1-7.1,9.2-7.1l83.7,0c5,0,9.2,3.2,9.2,7.1l0,0c0,3.9-4.1,7.1-9.2,7.1l-83.8,0
	    C17.9,70.8,13.8,67.6,13.8,63.7z"/>
        <path fill={color || 'currentColor'} d="M13.8,96.1L13.8,96.1c0-3.9,4.1-7.1,9.2-7.1l83.7,0c5,0,9.2,3.2,9.2,7.1l0,0c0,3.9-4.1,7.1-9.2,7.1l-83.8,0
	    C17.9,103.2,13.8,100,13.8,96.1z"/>
    </svg>
);
