import classnames from 'classnames';
import React from 'react';
import { isUndefined } from 'lodash/fp';

import styles from './Fields.module.scss';
import { SignOffConversation } from '../store';
import { SignOffConversationIcon } from '../sign-off/SignOffConversationIcon';

interface TextProps {
    id: string;
    value: string | undefined;
    updateValue: (value: string) => void;
    disabled?: boolean;
    placeholder?: string;
    showFieldUpdated?: boolean;
    signOffConversation?: SignOffConversation
}

export const Text: React.FC<TextProps> = ({
    id,
    value,
    disabled = false,
    updateValue,
    placeholder = 'Aa...',
    showFieldUpdated = false,
    signOffConversation
}) => {

    return (
        <div className={styles.textWrapper}>
            <input
                data-testid={`opinion-text-${id}`}
                className={classnames(styles.textField, {
                    [styles.updatedInputField]: showFieldUpdated
                })}
                onChange={e => updateValue(e.target.value)}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
            />
            {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={id} />}
        </div>
    );
};
