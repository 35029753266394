import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Toggle } from '../../../shared/toggle';
import { InformationTooltip } from '../../../shared/tooltip';
import styles from '../Clients.module.scss';
import { Client, ClientOpinion, ClientSection, getAvailableClientFeaturePermissions } from '../store';

const { white, grey } = styles;

interface ClientFeaturePermissionsProps {
    clientFeaturePermissionIds: number[] | null;
    toggleSection: (section: ClientSection) => void;
    getSectionOpen: (section: ClientSection) => boolean;
    updateClientProperties: (key: keyof Client, value: string | number[] | ClientOpinion[]) => void;
}

export const ClientFeaturePermissions: React.FC<ClientFeaturePermissionsProps> = ({ clientFeaturePermissionIds, toggleSection, getSectionOpen, updateClientProperties }) => {

    const allClientFeaturePermissions = useAppSelector(getAvailableClientFeaturePermissions);

    const permissionOptions = useMemo(() => allClientFeaturePermissions.filter(({ label }) => label).sort((a, b) => a.label.localeCompare(b.label)), [allClientFeaturePermissions]);

    const updateClientFeaturePermissionIds = (checked: boolean, featurePermissionId: number) => {
        const featurePermissions = checked ? [...clientFeaturePermissionIds!, featurePermissionId] : clientFeaturePermissionIds!.filter(id => id !== featurePermissionId);
        updateClientProperties('clientFeaturePermissionIds', featurePermissions);
    };

    const permissionOptionHeaders = [{ name: 'Permission' }, { name: 'Has Permission' }];

    const featurePermissionSectionOpen = useMemo(() => getSectionOpen(ClientSection.FEATURE_PERMISSIONS), [getSectionOpen]);
    const getSectionOpenIcon = useCallback((isOpen: boolean) => isOpen ? CaretDown : CaretSide, []);

    return (
        <div className={styles.clientFeaturePermissionOptionsWrapper}>
            <div className={styles.featurePermissionHeader} onClick={() => toggleSection(ClientSection.FEATURE_PERMISSIONS)}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={getSectionOpenIcon(featurePermissionSectionOpen)} fontSize={15} />
                </div>
                <div className={styles.featurePermissionOptionsTitle}>Feature Permissions Available to Client</div>
            </div>
            {featurePermissionSectionOpen &&
                <>
                    <div className={styles.featurePermissionOptionsHeader}>
                        {permissionOptionHeaders.map(({ name }) => (
                            <div key={name}>{name}</div>
                        ))}
                    </div>
                    <div className={styles.featurePermissionOptionsTable}>
                        <Scrollable maxHeight='250px'>
                            {permissionOptions.map(({ clientFeaturePermissionId, label, description }, index) => (
                                <div className={styles.featurePermissionOptionRow} key={index} style={{ backgroundColor: index % 2 ? grey : white }}>
                                    <div className={styles.featurePermissionInfo}>
                                        <div className={styles.featurePermissionLabel}>{label}</div>
                                        <div className={styles.featurePermissionDescription}><InformationTooltip content={description} /></div>
                                    </div>
                                    <Toggle
                                        checked={clientFeaturePermissionIds ? clientFeaturePermissionIds.includes(clientFeaturePermissionId!) : false}
                                        onChange={checked => updateClientFeaturePermissionIds(checked, clientFeaturePermissionId!)}
                                    />
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </>
            }
        </div>
    );
};
