import { isEqual, isUndefined } from 'lodash/fp';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Toggle } from '../../../shared/toggle';
import { OverflowTooltip } from '../../../shared/tooltip';
import { ArkOpinion } from '../../my-opinions/store';
import { SingleField } from '../fields/SingleField';
import { SignOffConversationIcon } from '../sign-off';
import { getFieldSignOffNotes, getIsFirstTimelineEntry, getOpenFieldsAndSections, toggleOpenFieldSection, updateSingleToggleValue } from '../store';
import { AnnexCoverage, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

const { lightGrey, grey, amethyst, french } = styles;

interface AnnexCoverageSectionProps {
    annexCoverage: AnnexCoverage;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
    opinion: ArkOpinion;
}

export const AnnexCoverageSection: React.FC<AnnexCoverageSectionProps> = ({ annexCoverage, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const sectionId = 'annexCoverage';
    const { standardOptions, other } = annexCoverage;
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const isFirstTimelineEntry = useAppSelector(getIsFirstTimelineEntry);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);

    const fieldUpdatedByInstance = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number) => !!fieldsUpdated.find(field => isEqual({ sectionId: field.sectionId, index: field.index, fieldId: field.fieldId }, { sectionId, index, fieldId })), [fieldsUpdated, sectionId]);
    const getIsDisabled = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number) => !isEditing && !(isUpdating && fieldUpdatedByInstance(fieldId, index)), [isUpdating, isEditing, fieldUpdatedByInstance]);
    const shouldShowFieldUpdated = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number) => fieldUpdatedByInstance(fieldId, index) && !isUpdating && !isEditing && !isFirstTimelineEntry, [isUpdating, isEditing, fieldUpdatedByInstance, isFirstTimelineEntry]);

    const signOffConversation = useAppSelector(getFieldSignOffNotes(sectionId));

    const getOptionWrapperStyle = useCallback((index: number, totalEntries: number) => {
        const numberOfLastRowEntries = totalEntries % 3 || 3;
        const lastRowEntries = Array(numberOfLastRowEntries).fill(totalEntries - numberOfLastRowEntries).map((val, i) => val + i);
        const isLastRow = lastRowEntries.includes(index);
        const isFirstColumn = ((index + 1) % 3) === 1;
        if (!isLastRow) {
            return !isFirstColumn ? { width: 'calc((100% / 3) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}`, borderBottom: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)', borderBottom: `1px solid ${lightGrey}` };
        }
        return !isFirstColumn ? { width: 'calc((100% / 3) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)' };
    }, []);

    const boxShadowColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return grey;
    }, []);

    const onColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return french;
    }, []);

    const updateValue = (fieldId: keyof AnnexCoverage, value: boolean, index: number) => dispatch(updateSingleToggleValue(sectionId, fieldId, index, value));

    return (
        <SectionWrapper id={sectionId} label='Annex Coverage' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.annexCoverageWrapper}>
                <div className={styles.coverageWrapper}>
                    <div className={styles.signOffConversationWrapper}>
                        {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={sectionId} />}
                    </div>
                    <div className={styles.optionsWrapper}>
                        {standardOptions.map((field, index) => {
                            const isDisabled = getIsDisabled('standardOptions', index);
                            const showFieldUpdated = shouldShowFieldUpdated('standardOptions', index);
                            const optionWrapperStyle = getOptionWrapperStyle(index, standardOptions.length);
                            return (
                                <div className={styles.toggleOptionWrapper} key={index} style={optionWrapperStyle}>
                                    <div className={styles.annexCoverageLabel}>
                                        <OverflowTooltip overlayText={field.label} trigger='click' />
                                    </div>
                                    <Toggle
                                        onChange={value => updateValue('standardOptions', value, index)}
                                        checked={field.value}
                                        disabled={isDisabled}
                                        boxShadowColour={boxShadowColour(showFieldUpdated)}
                                        onColour={onColour(showFieldUpdated)}
                                    />
                                </div>
                            );
                        }
                        )}
                    </div>
                    <SingleField
                        field={other}
                        fieldId='other'
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        getFieldOpen={getFieldOpen}
                        toggleFieldOpen={toggleFieldOpen}
                    />
                </div>
            </div>
        </SectionWrapper>
    );
};
