import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { DatasetDefinition, isFormDatasetDefinition } from '../../../datasets/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { getCurrentHiddenDocumentFields, getPreviewDatasetSelectedDocument } from '../store';
import { DocumentDatasetFormDefinition } from './form-definition/DocumentDatasetFormDefinition';
import { DocumentDatasetTableDefinition } from './table-definition/DocumentDatasetTableDefinition';

interface DocumentDatasetDefinitionsProps {
    datasetDefinition: DatasetDefinition;
    parents: number;
    documentNames: DropdownOption[];
    modalInstance?: boolean;
    hiddenDocumentNameIds?: number[];
    isPreview?: boolean;
    parentSectionId?: string;
}

export const DocumentDatasetDefinitions: React.FC<DocumentDatasetDefinitionsProps> = ({ datasetDefinition, parents, documentNames, modalInstance, hiddenDocumentNameIds, isPreview = false, parentSectionId }) => {
    const [screenWidth] = useWindowResize();
    const padding = parents > 0 ? 10 : 0;
    const width = (screenWidth - 80) - (parents * 10) + (modalInstance ? 18 : 0);
    const datasetInstanceWidth = modalInstance ? 'fit-content' : width;
    const hiddenFields = useAppSelector(getCurrentHiddenDocumentFields);
    const previewSelectedDocument = useAppSelector(getPreviewDatasetSelectedDocument);

    const definitionComponent = useMemo(() => {
        if (isFormDatasetDefinition(datasetDefinition)) {
            return (<DocumentDatasetFormDefinition formDefinition={datasetDefinition} parents={parents} isPreview={isPreview} modalInstance={modalInstance} hiddenFields={hiddenFields} hiddenDocumentNameIds={hiddenDocumentNameIds} documentNames={documentNames} previewSelectedDocument={previewSelectedDocument} />);
        }
        return (<DocumentDatasetTableDefinition tableDefinition={datasetDefinition} isPreview={isPreview} modalInstance={modalInstance} hiddenFields={hiddenFields} hiddenDocumentNameIds={hiddenDocumentNameIds} documentNames={documentNames} parentSectionId={parentSectionId} previewSelectedDocument={previewSelectedDocument} />);
    }, [datasetDefinition, modalInstance, parents, hiddenFields, hiddenDocumentNameIds, isPreview, documentNames, parentSectionId, previewSelectedDocument]);

    return (
        <div style={{ paddingLeft: `${padding}px`, width: `${datasetInstanceWidth}px`, maxWidth: `${screenWidth}px`, height: '100%' }}>
            {definitionComponent}
        </div>
    );
};
