import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Button } from '../../../shared/button/Button';
import { Pencil } from '../../../shared/icons';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import styles from '../ClauseTagsModal.module.scss';
import { updateTagTerm, getTagTerm, addClientTagStarted, getAllClauseLibraryTags, getClientTagFilters, getFilteredClientTags, clearClientTagFilters, getSelectedClientTag, getTagToDelete, getDeleteTagModalOpen, toggleDeleteTagModal, deleteTagStarted } from '../../store';
import { Text } from '../../../shared/text/Text';
import { DeleteTagConfirmationModal } from '../DeleteTagConfirmationModal';
import { CurrentClientTags } from './CurrentClientTags';
import { SelectedClientTags } from './SelectedClientTag';

const { lightGrey } = styles;

interface ClientTagsManagerProps {
    closeModal: () => void;
}

export const ClientTagsManager: React.FC<ClientTagsManagerProps> = ({ closeModal }) => {
    const dispatch = useAppDispatch();
    const tagTerm = useAppSelector(getTagTerm);
    const allClauseLibraryTags = useAppSelector(getAllClauseLibraryTags);
    const filters = useAppSelector(getClientTagFilters);
    const filteredTags = useAppSelector(getFilteredClientTags);
    const selectedTag = useAppSelector(getSelectedClientTag);

    const createNewTag = useCallback((value: string) => {
        dispatch(updateTagTerm(value));
    }, [dispatch]);

    const addNewClientTag = useCallback(() => {
        dispatch(addClientTagStarted(tagTerm));
    }, [dispatch, tagTerm]);

    const addTagDisabled = useMemo(() => allClauseLibraryTags.includes(tagTerm.toLowerCase()), [allClauseLibraryTags, tagTerm]);
    const filtersExist = useMemo(() => !!Object.values(filters).filter(({ text, dropdown }) => !isNull(dropdown) || !!text).length, [filters]);
    const clearFilters = useCallback(() => dispatch(clearClientTagFilters()), [dispatch]);
    const tableWidth = useMemo(() => isNull(selectedTag) ? 'calc(100% - 50px)' : 'calc(60% - 70px)', [selectedTag]);

    const isOpen = useAppSelector(getDeleteTagModalOpen);
    const tagToDelete = useAppSelector(getTagToDelete);

    const closeConfirmDeleteModal = () => dispatch(toggleDeleteTagModal(null));
    const deleteTag = () => dispatch(deleteTagStarted(tagToDelete!.clauseTagId, tagToDelete!.includedClauses));

    return (
        <>
            <div className={styles.clauseTagsWrapper} data-testid='client-tags-modal-wrapper'>
                <ModalHeader label='Configure Your Tags' icon={Pencil} testId='client-tags-modal' />
                <div className={styles.currentClientTagsWrapper} data-testid='client-tags-modal-tags-wrapper'>
                    <CurrentClientTags />
                    <SelectedClientTags />
                </div>
                <div className={styles.totalTagsWrapper} style={{ width: tableWidth }} data-testid='client-tags-modal-table-info-wrapper'>
                    <div className={styles.clearAllFiltersWrapper} data-testid='client-tags-modal-table-info-filter-wrapper'>
                        {filtersExist &&
                            <button className={styles.clearAllFilters} onClick={clearFilters} data-testid='client-tags-modal-table-info-filter-button'>Clear All Filters</button>
                        }
                    </div>
                    <div className={styles.totalTags} data-testid='client-tags-modal-table-info-total-label'>Total: {filteredTags.length}</div>
                </div>
                <div className={styles.clientTagInputWrapper} data-testid='client-tags-modal-add-tag-wrapper'>
                    <div className={styles.clientTagModalInput}>
                        <Text
                            onChange={e => createNewTag(e.target.value)}
                            value={tagTerm}
                            maxLength={256}
                            testId='client-tags-modal-add-tag'
                            placeholder='Tags...'
                            marginBottom='0px'
                            borderColour={lightGrey}
                        />
                    </div>
                    <Button onClick={addNewClientTag} label='Add Tag' disabled={addTagDisabled} testId='client-tags-modal-add-tag' />
                </div>
                <div className={styles.closeClientTagsModal} data-testid='client-tags-modal-button-wrapper'>
                    <Button onClick={closeModal} label='Close' testId='client-tags-modal-close' />
                </div>
            </div>
            <DeleteTagConfirmationModal
                isOpen={isOpen}
                closeModal={closeConfirmDeleteModal}
                confirmDelete={deleteTag}
                tagToDelete={tagToDelete?.tag || ''}
            />
        </>
    );
};
