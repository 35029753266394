import React, { MouseEvent, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { isEqual } from 'lodash/fp';

import { DatasetSection } from '../../../datasets/store';
import { Icon } from '../../icon/Icon';
import { CaretDown, CaretSide, Tick } from '../../icons';
import { OverflowTooltip } from '../../tooltip';
import { DatasetFieldSection } from '../RiskTolerance';
import { DatasetMenu } from './DatasetMenu';
import { RiskField, SelectedRiskField } from '../../../admin/risk-tolerance/store';
import styles from '../RiskTolerance.module.scss';
import { fieldListIcons } from '../../../admin/dataset-builder/FieldList';
import { RadioButton } from '../../button/RadioButton';
import { DocumentSpecificHiddenFields } from '../../../admin/dataset-builder/store';
import { useSingleHiddenDocumentFields } from '../../../../hooks/useHiddenSingleFields';
import { DocumentNameDB } from '../../../admin/documents/store';

interface SectionMenuProps {
    parentDatasetId: number;
    getDatasetFields: (datasetId: number, sectionId: string | null) => DatasetFieldSection[];
    sectionId: string | null;
    datasetSectionOpen: (datasetId: number) => boolean;
    getDatasetRiskFields: (datasetId: number, sectionId: string | null) => RiskField[];
    selectRiskField: (fieldId: string, datasetId: number) => void;
    selectedField: SelectedRiskField | null;
    toggleDatasetOpen: (datasetId: number) => void;
    getDatasetSections: (datasetId: number) => DatasetSection[];
    getSectionOpen: (sectionId: string | null, datasetId: number) => boolean;
    toggleSectionOpen: (sectionId: string | null, datasetId: number) => void;
    depth: number;
    showRiskFieldToggles: boolean;
    toggleRiskField: (datasetId: number, fieldId: string) => void;
    documentHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    agreementTypeDatasetId: number | null;
}

export const SectionMenu: React.FC<SectionMenuProps> = ({
    parentDatasetId,
    getDatasetFields,
    sectionId,
    datasetSectionOpen,
    getDatasetRiskFields,
    selectedField,
    toggleDatasetOpen,
    selectRiskField,
    getDatasetSections,
    getSectionOpen,
    toggleSectionOpen,
    depth,
    showRiskFieldToggles,
    toggleRiskField,
    documentHiddenFields,
    allDocumentNames,
    agreementTypeDatasetId
}) => {
    const sectionOpenIcon = useCallback((datasetId: number) => datasetSectionOpen(datasetId) ? CaretDown : CaretSide, [datasetSectionOpen]);
    const isSelectedRiskField = useCallback((fieldId: string, datasetId: number) => selectedField && isEqual({ fieldId, datasetId }, selectedField), [selectedField]);
    const datasetFields = useMemo(() => getDatasetFields(parentDatasetId, sectionId), [getDatasetFields, parentDatasetId, sectionId]);
    const datasetRiskFields = useMemo(() => getDatasetRiskFields(parentDatasetId, sectionId), [getDatasetRiskFields, parentDatasetId, sectionId]);

    const datasetWrapperStyle = useMemo(() => ({ width: `calc(100% - ${depth * 10}px`, paddingLeft: `${depth * 10}px` }), [depth]);

    const datasetFieldsMenu = useMemo(() => {
        if (datasetFields.length === 1) {
            const datasetId = parseInt(datasetFields[0].field.settings.datasetLinked!);
            return (
                <DatasetMenu
                    parentDatasetId={datasetId}
                    getDatasetFields={getDatasetFields}
                    datasetSectionOpen={datasetSectionOpen}
                    getDatasetRiskFields={getDatasetRiskFields}
                    selectedField={selectedField}
                    toggleDatasetOpen={toggleDatasetOpen}
                    selectRiskField={selectRiskField}
                    getDatasetSections={getDatasetSections}
                    getSectionOpen={getSectionOpen}
                    toggleSectionOpen={toggleSectionOpen}
                    depth={depth + 1}
                    showRiskFieldToggles={showRiskFieldToggles}
                    toggleRiskField={toggleRiskField}
                    documentHiddenFields={documentHiddenFields}
                    allDocumentNames={allDocumentNames}
                    agreementTypeDatasetId={agreementTypeDatasetId}
                />
            );
        }

        return (
            datasetFields.map(({ field: { label, id, settings: { datasetLinked } } }) => {
                const datasetId = parseInt(datasetLinked!);
                return (
                    <div className={styles.datasetWrapper} key={id} style={datasetWrapperStyle}>
                        <div className={styles.datasetHeader} onClick={() => toggleDatasetOpen(datasetId)} data-testid={`risk-tolerance-dataset-${datasetId}`}>
                            <div className={styles.datasetOpenIcon}>
                                <Icon icon={sectionOpenIcon(datasetId)} fontSize={15} />
                            </div>
                            <OverflowTooltip overlayText={label} />
                        </div>
                        {datasetSectionOpen(datasetId) &&
                            <DatasetMenu
                                parentDatasetId={datasetId}
                                getDatasetFields={getDatasetFields}
                                datasetSectionOpen={datasetSectionOpen}
                                getDatasetRiskFields={getDatasetRiskFields}
                                selectedField={selectedField}
                                toggleDatasetOpen={toggleDatasetOpen}
                                selectRiskField={selectRiskField}
                                getDatasetSections={getDatasetSections}
                                getSectionOpen={getSectionOpen}
                                toggleSectionOpen={toggleSectionOpen}
                                depth={depth + 1}
                                showRiskFieldToggles={showRiskFieldToggles}
                                toggleRiskField={toggleRiskField}
                                documentHiddenFields={documentHiddenFields}
                                allDocumentNames={allDocumentNames}
                                agreementTypeDatasetId={agreementTypeDatasetId}
                            />
                        }
                    </div>
                );
            })
        );
    }, [
        datasetFields,
        toggleDatasetOpen,
        sectionOpenIcon,
        getDatasetFields,
        datasetSectionOpen,
        getDatasetRiskFields,
        selectedField,
        selectRiskField,
        getDatasetSections,
        getSectionOpen,
        toggleSectionOpen,
        depth,
        datasetWrapperStyle,
        showRiskFieldToggles,
        toggleRiskField,
        documentHiddenFields,
        allDocumentNames,
        agreementTypeDatasetId
    ]);

    const LABEL_MARGIN = 10;
    const ICON_WIDTH = 28;
    const RADIO_WIDTH = 18;

    const labelStyle = useMemo(() => ({ width: `calc(100% - ${LABEL_MARGIN + ICON_WIDTH + (showRiskFieldToggles ? RADIO_WIDTH : 0)}px)` }), [showRiskFieldToggles]);

    const toggleField = useCallback((e: MouseEvent<HTMLElement>, datasetId: number, fieldId: string) => {
        e.stopPropagation();
        toggleRiskField(datasetId, fieldId);
    }, [toggleRiskField]);

    const documentSpecificHiddenFields = useMemo(() => documentHiddenFields[parentDatasetId] || [], [parentDatasetId, documentHiddenFields]);
    const documentHiddenSingleFields = useSingleHiddenDocumentFields(documentSpecificHiddenFields);

    const getFieldHiddenForAllDatasetDocuments = useCallback((id: string) => {
        const documentsHiddenForField = documentHiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds || [];
        const documentNameIdsForDataset = allDocumentNames.filter(({ datasetId }) => datasetId === agreementTypeDatasetId).map(({ documentNameId }) => documentNameId!);
        return documentsHiddenForField.length > 0 && documentNameIdsForDataset.length > 0 && documentNameIdsForDataset.every(documentNameId => documentsHiddenForField.includes(documentNameId));
    }, [allDocumentNames, documentHiddenSingleFields, agreementTypeDatasetId]);

    return (
        <div>
            <div className={styles.datasetRiskFields}>
                {datasetRiskFields.map(({ fieldId, label, type, includeRiskField }) => {
                    const fieldHiddenForAllDatasetDocuments = getFieldHiddenForAllDatasetDocuments(fieldId);
                    if (fieldHiddenForAllDatasetDocuments) {
                        return null;
                    }
                    return (
                        <div onClick={() => selectRiskField(fieldId, parentDatasetId)} className={classnames(styles.riskFieldWrapper, { [styles.selectedRiskField]: isSelectedRiskField(fieldId, parentDatasetId) })} key={fieldId}>
                            <div className={styles.riskFieldLabel} style={labelStyle}>
                                <OverflowTooltip overlayText={label} />
                            </div>
                            {showRiskFieldToggles && <RadioButton fontSize={10} withBackground={includeRiskField} onClick={e => toggleField(e, parentDatasetId, fieldId)} icon={Tick} />}
                            <div className={styles.riskFieldType}><Icon icon={fieldListIcons[type]} /></div>
                        </div>
                    );
                }
                )}
            </div>
            {datasetFieldsMenu}
        </div>
    );
};
