import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { DoraConfiguration, DoraFunction, DoraFunctionUsage, FunctionToDelete, DoraFunctionState, DoraConfigurationTimeline } from './types';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';

const getRoot = (state: ApplicationState): DoraFunctionState => state.dora.functions;

export const getAllDoraFunctions = (state: ApplicationState): DoraFunction[] => getRoot(state).doraFunctions;

export const getIsFetchingFunctions = (state: ApplicationState): boolean => getRoot(state).fetchingFunctions;

export const getDoraFunctionModalOpen = (state: ApplicationState): boolean => !isNull(getRoot(state).selectedDoraFunction);

export const getSelectedDoraFunction = (state: ApplicationState): DoraFunction | null => getRoot(state).selectedDoraFunction;

export const getCurrentDoraFunction = (state: ApplicationState): DoraFunction | null => getRoot(state).currentDoraFunction;

export const getDoraFunctionDropdownOptions = (state: ApplicationState): DropdownOption[] => {
    const doraFunctions = getRoot(state).doraFunctions;
    return doraFunctions.map(({ name }) => ({ value: name, label: name }));
};

export const getIsSavingDoraFunction = (state: ApplicationState): boolean => getRoot(state).savingDoraFunction;

export const getFunctionConfigModalOpen = (state: ApplicationState): boolean => getRoot(state).functionConfigModalOpen;

export const getDoraFunctionConfig = (state: ApplicationState): DoraConfiguration => getRoot(state).doraFunctionConfiguration;
export const getCurrentDoraFunctionConfig = (state: ApplicationState): DoraConfiguration => getRoot(state).currentDoraFunctionConfiguration;

export const getSelectedTimelineConfiguration = (state: ApplicationState): DoraConfigurationTimeline | null => getRoot(state).timelineConfiguration;

export const getConfigurationTimeline = (state: ApplicationState): DoraConfigurationTimeline[] => getRoot(state).configurationTimeline;

export const getDeleteFunctionConfirmationModalOpen = (state: ApplicationState): boolean => !isNull(getRoot(state).functionToDelete) && !getRoot(state).fetchingFunctionUsage;

export const getFunctionToDelete = (state: ApplicationState): FunctionToDelete | null => getRoot(state).functionToDelete;

export const getFunctionToDeleteUsage = (state: ApplicationState): DoraFunctionUsage[] => getRoot(state).functionToDeleteUsage;

// Table Filters and Pagination
export const getTotalDoraFunctions = (state: ApplicationState): number => getRoot(state).totalDoraFunctions;
export const getDoraFunctionsFilters = (state: ApplicationState): TableFilters => getRoot(state).doraFunctionsFilters;
export const getDoraFunctionsColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).doraFunctionsColumnSort;
export const getDoraFunctionsPageNumber = (state: ApplicationState): number => getRoot(state).doraFunctionsPage;
export const getDoraFunctionsPageSize = (state: ApplicationState): number => getRoot(state).doraFunctionsPageSize;
