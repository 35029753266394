import React, { useMemo } from 'react';

import { LinkedPlaybookProvision } from '../../../admin/playbook/store';
import { Position, PositionModal } from '../../modal/PositionModal';
import { Scrollable } from '../../scrollable/Scrollable';
import { OverflowTooltip } from '../../tooltip';
import styles from './ProvisionLink.module.scss';

const { grey } = styles;
interface LinkedProvisionModalProps {
    isOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    linkedProvisions: LinkedPlaybookProvision[];
    linkToProvision: (link: LinkedPlaybookProvision) => void;
}

export const LinkedProvisionModal: React.FC<LinkedProvisionModalProps> = ({ closeModal, isOpen, position, linkedProvisions, linkToProvision }) => {
    const height = `${linkedProvisions.length * 20}px`;
    const centeredPosition = position ? { x: position.x, y: (position.y - parseInt(height)) } : null;
    const provisionLinkBorder = useMemo(() => linkedProvisions.length > 1 ? `1px solid ${grey}` : 'none', [linkedProvisions]);

    return (
        <PositionModal
            isOpen={isOpen}
            position={centeredPosition}
            height='fit-content'
            width='300px'
            closeModal={closeModal}
            testId='linked-playbook-provision-modal'
            overflow='hidden'
        >
            <div className={styles.linkedProvisionModalWrapper} data-testid='linked-playbook-provision-modal-wrapper'>
                <Scrollable maxHeight='200px'>
                    {linkedProvisions.map(({ playbookId, playbookName, sectionId, provisionTitle }) => (
                        <div key={playbookId} className={styles.linkedProvisionWrapper} style={{ borderBottom: provisionLinkBorder }} onClick={() => linkToProvision({ playbookId, sectionId, provisionTitle, playbookName })}>
                            <div className={styles.linkedProvisionTitleWrapper}>
                                <OverflowTooltip overlayText={playbookName} className={styles.linkedPlaybookTitle} />
                                <Scrollable maxHeight='38px'>
                                    <div className={styles.linkedProvisionTitle}>{provisionTitle}</div>
                                </Scrollable>
                            </div>
                        </div>
                    ))}
                </Scrollable>
            </div>
        </PositionModal>
    );
};
