import React, { useEffect, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { AttestationsTable } from './AttestationsTable';
import styles from './Attestations.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { fetchAllUserAttestationInstancesStarted, getAllUserAttestationInstances, toggleUserAttestationInstanceView, getCompletedTableView, getSelectedUserAttestationInstance, getUserAttestationInstanceModalOpen, toggleUserAttestationInstanceModalOpen, UserAttestationInstanceDB } from './store';
import { UserAttestationInstanceModal } from './UserAttestationInstanceModal';
import { TableTabs } from '../shared/table/TableTabs';

export interface AttestationRouteParams {
    attestationInstanceId: string | undefined;
}

export const Attestations: React.FC<RouteComponentProps<AttestationRouteParams>> = ({ match: { path, params } }) => {
    const dispatch = useAppDispatch();

    const testId = 'my-attestations';
    const completed = useAppSelector(getCompletedTableView);
    const attestationInstances = useAppSelector(getAllUserAttestationInstances);
    const userAttestationInstanceModalOpen = useAppSelector(getUserAttestationInstanceModalOpen);
    const selectedUserAttestationInstance = useAppSelector(getSelectedUserAttestationInstance);

    const fetchUserAttestations = useCallback(() => dispatch(fetchAllUserAttestationInstancesStarted(completed)), [dispatch, completed]);
    const changeView = useCallback((attestationInstanceId?: string) => dispatch(toggleUserAttestationInstanceView(!completed, attestationInstanceId)), [completed, dispatch]);
    const closeUserAttestationInstance = useCallback(() => dispatch(toggleUserAttestationInstanceModalOpen(false, null)), [dispatch]);

    const openAttestationInstance = useCallback((attestation: UserAttestationInstanceDB) => dispatch(toggleUserAttestationInstanceModalOpen(true, attestation)), [dispatch]);

    const attestationInParamsExists = useMemo(() => (params.attestationInstanceId && attestationInstances.map(({ attestationInstanceId }) => attestationInstanceId.toString()).includes(params.attestationInstanceId)) || false, [attestationInstances, params]);
    useEffect(() => {
        if (!selectedUserAttestationInstance && params.attestationInstanceId && attestationInParamsExists) {
            const attestationFromParams = attestationInstances.find(({ attestationInstanceId }) => attestationInstanceId.toString() === params.attestationInstanceId)!;
            openAttestationInstance(attestationFromParams);
        }
    }, [params, selectedUserAttestationInstance, attestationInParamsExists, attestationInstances, openAttestationInstance]);

    useEffect(() => {
        if ((path.includes('completed') && !completed) || (!path.includes('completed') && completed)) {
            changeView(params.attestationInstanceId);
        }
    }, [completed, path, changeView, params]);

    useEffect(() => {
        fetchUserAttestations();
    }, [fetchUserAttestations]);

    return (
        <div className={styles.attestationsWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.attestationsContainer}>
                <div className={styles.attestationsTitle} data-testid={`${testId}-header`}>My Attestations</div>
            </div>
            <TableTabs
                tabs={[
                    { tabToggleAction: changeView, tabTitle: 'Incomplete', isSelected: !completed },
                    { tabToggleAction: changeView, tabTitle: 'Complete', isSelected: completed }
                ]}
                testId={testId}
            />
            <AttestationsTable attestationInstances={attestationInstances} testId={testId} completed={completed} />
            {selectedUserAttestationInstance &&
                <UserAttestationInstanceModal
                    isOpen={userAttestationInstanceModalOpen}
                    closeModal={closeUserAttestationInstance}
                    attestation={selectedUserAttestationInstance}
                    testId={testId}
                />
            }
        </div>
    );
};
