import React, { useMemo } from 'react';

import { DatasetFieldType } from '../../datasets/store';
import styles from './RiskTolerance.module.scss';
import { DropdownRiskFieldConfig } from './fields/DropdownRiskFieldConfig';
import { NumberRiskFieldConfig } from './fields/NumberRiskFieldConfig';
import { DateRiskFieldConfig } from './fields/DateRiskFieldConfig';
import { TimeRiskFieldConfig } from './fields/TimeRiskFieldConfig';
import { CheckboxRiskFieldConfig } from './fields/CheckboxRiskFieldConfig';
import { DropdownRiskField, NumberRiskField, RiskAssociated, RiskAssociatedValue as RiskAssociatedValueType, RiskField, DateRiskField, CheckboxRiskField, TimeRiskField, CurrencyAmountRiskField } from '../../admin/risk-tolerance/store';
import { CurrencyAmountRiskFieldConfig } from './fields/CurrencyAmountRiskFieldConfig';

interface RiskAssociatedValueProps {
    riskField: RiskField;
    riskAssociated: RiskAssociated;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValueType, selectedCurrency?: string) => void;
    selectedCurrency: string | undefined;
}

export const RiskAssociatedValue: React.FC<RiskAssociatedValueProps> = ({ riskField, riskAssociated, updateRiskConfig, selectedCurrency }) => {
    const { type } = riskField;

    const riskFieldByType = useMemo(() => {
        switch (type) {
            case DatasetFieldType.DROPDOWN:
                return <DropdownRiskFieldConfig riskAssociated={riskAssociated} riskField={riskField as DropdownRiskField} updateRiskConfig={updateRiskConfig} />;
            case DatasetFieldType.NUMBER:
                return <NumberRiskFieldConfig riskAssociated={riskAssociated} riskConfig={(riskField as NumberRiskField).riskConfig} includeRiskField={(riskField as NumberRiskField).includeRiskField} updateRiskConfig={updateRiskConfig} />;
            case DatasetFieldType.DATE:
                return <DateRiskFieldConfig riskAssociated={riskAssociated} riskField={riskField as DateRiskField} updateRiskConfig={updateRiskConfig} />;
            case DatasetFieldType.TIME:
                return <TimeRiskFieldConfig riskAssociated={riskAssociated} riskField={riskField as TimeRiskField} updateRiskConfig={updateRiskConfig} />;
            case DatasetFieldType.CHECKBOX:
                return <CheckboxRiskFieldConfig riskAssociated={riskAssociated} riskField={riskField as CheckboxRiskField} updateRiskConfig={updateRiskConfig} />;
            case DatasetFieldType.CURRENCY_AMOUNT:
                return <CurrencyAmountRiskFieldConfig riskAssociated={riskAssociated} riskField={riskField as CurrencyAmountRiskField} updateRiskConfig={updateRiskConfig} selectedCurrency={selectedCurrency} />;
            default:
                return null;
        }
    }, [type, riskField, riskAssociated, updateRiskConfig, selectedCurrency]);

    return (
        <div className={styles.riskAssociatedValue}>
            {riskFieldByType}
        </div>
    );
};
