import { isNull, noop } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { RawDraftContentState } from 'react-draft-wysiwyg';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { Button } from '../shared/button/Button';
import { Pencil } from '../shared/icons';
import { ModalHeader } from '../shared/modal/ModalHeader';
import styles from './Playbook.module.scss';
import { getUserCurrentView, getPlaybookSuggestedChange, getReadOnlySuggestedChangesOpen, setSelectedSuggestedChange, getReadOnlyChangesContent, updateReadOnlySuggestedChangesContent, updateSuggestedChangesNewMessage, getSuggestedChangesMessage, getSuggestedChangesMessageUpdated, updateSuggestedChangesConversationStarted, getSendingSuggestedChangesMessage } from './store';
import { PlaybookView, ReadOnlyContentType, SuggestedChangesDB } from '../admin/playbook/store';
import { Scrollable } from '../shared/scrollable/Scrollable';
import { ReadOnlyProvision } from '../shared/playbook/view/ReadOnlyProvision';
import { WYSIWYG, isEmpty } from '../shared/wysiwyg/WYSIWYG';
import { ABSTRACT_ID } from '../constants/playbooks';
import { formatDate, FULL_DATE_FORMAT } from '../../utils/luxon';
import { Conversation } from '../shared/playbook/conversation/Conversation';
import { getUser } from '../auth/login/store';
import { Spinner } from '../shared/spinner/Spinner';

const { white } = styles;

interface SuggestionContentProps {
    suggestedChange: SuggestedChangesDB;
    contentView: ReadOnlyContentType;
    sendingMessage: boolean;
    testId: string;
}

const BUTTON_HEIGHT = 48;
const MODAL_HEADER = 56;
const HEIGHT_OFFSET = BUTTON_HEIGHT + MODAL_HEADER;

const SuggestionContent: React.FC<SuggestionContentProps> = ({ suggestedChange, contentView, sendingMessage, testId }) => {
    const [deviationOpen, setDeviationOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const playbookView = useAppSelector(getUserCurrentView);
    const user = useAppSelector(getUser);
    const newMessage = useAppSelector(getSuggestedChangesMessage);
    const newMessageContent = isNull(newMessage) ? null : newMessage.content;

    const toggleDeviation = useCallback(() => setDeviationOpen(!deviationOpen), [setDeviationOpen, deviationOpen]);

    const updateMessage = useCallback((rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            if (!isNull(newMessageContent)) {
                dispatch(updateSuggestedChangesNewMessage(null, user!));
            }
        } else {
            dispatch(updateSuggestedChangesNewMessage(rawValue, user!));
        }
    }, [newMessageContent, user, dispatch]);

    const content = useMemo(() => {
        if (suggestedChange.suggestedSection.type !== ABSTRACT_ID) {
            const { suggestedSection: { content, changedContent } } = suggestedChange;
            return (
                <ReadOnlyProvision
                    isTableView={playbookView === PlaybookView.TABLE}
                    section={content}
                    deviationOpen={deviationOpen}
                    toggleDeviation={toggleDeviation}
                    suggestedChangeContent={changedContent}
                    testId={`${testId}-read-only-provision-0`}
                />
            );
        }
        return (
            <div className={styles.previewAbstractWrapper} data-testid={`${testId}-abstract-read-only-wrapper`}>
                <WYSIWYG
                    content={suggestedChange.suggestedSection.content}
                    updateContent={noop}
                    showBorder={false}
                    height='fit-content'
                    minHeight='fit-content'
                    maxHeight='fit-content'
                    borderColor={white}
                    padding='0px'
                    editorPadding='0px'
                    disabled
                    toolbarHidden
                />
            </div>
        );
    }, [suggestedChange, playbookView, toggleDeviation, deviationOpen, testId]);

    if (sendingMessage) {
        return (
            <div className={styles.provisionWrapper}>
                <Spinner />
            </div>
        );
    }

    if (contentView === ReadOnlyContentType.CONVERSATION) {
        return (
            <div className={styles.provisionWrapper}>
                <Conversation
                    conversation={suggestedChange.conversation}
                    currentUserId={user!.userId!}
                    showSpinner={false}
                    height={`calc(40vh - ${HEIGHT_OFFSET}px)`}
                    testId={testId}
                />
                <div data-testid={`${testId}-conversation-input`}>
                    <WYSIWYG
                        content={newMessageContent}
                        updateContent={updateMessage}
                        showBorder={false}
                        height={'calc(30vh - 40px)'}
                        maxHeight={'calc(30vh - 40px)'}
                        showWrapperBorder
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.provisionWrapper}>
            <Scrollable>
                {content}
            </Scrollable>
        </div>
    );
};

interface ReadOnlySuggestedChangesModalProps {
    testId?: string;
}

export const ReadOnlySuggestedChangesModal: React.FC<ReadOnlySuggestedChangesModalProps> = ({ testId = 'playbook' }) => {
    const dispatch = useAppDispatch();

    const isOpen = useAppSelector(getReadOnlySuggestedChangesOpen);
    const suggestedChange = useAppSelector(getPlaybookSuggestedChange);
    const contentView = useAppSelector(getReadOnlyChangesContent);
    const newMessageUpdated = useAppSelector(getSuggestedChangesMessageUpdated);
    const sendingMessage = useAppSelector(getSendingSuggestedChangesMessage);

    const isConversationView = useMemo(() => contentView === ReadOnlyContentType.CONVERSATION, [contentView]);
    const closeModal = useCallback(() => dispatch(setSelectedSuggestedChange(null)), [dispatch]);
    const toggleContent = useCallback(() => dispatch(updateReadOnlySuggestedChangesContent(isConversationView ? ReadOnlyContentType.CHANGES : ReadOnlyContentType.CONVERSATION)), [dispatch, isConversationView]);
    const toggleViewLabel = useMemo(() => `View ${isConversationView ? 'Changes' : 'Conversation'}`, [isConversationView]);
    const showSendButton = useMemo(() => newMessageUpdated && isConversationView, [newMessageUpdated, isConversationView]);
    const sendMessage = useCallback(() => dispatch(updateSuggestedChangesConversationStarted()), [dispatch]);

    if (isNull(suggestedChange)) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            className={styles.suggestedChangesModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 9 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.suggestedChangesWrapper} data-testid={`${testId}-suggested-changes-read-only-modal-wrapper`}>
                <ModalHeader label={`Suggested Change: ${suggestedChange.suggestedSection.name} - ${formatDate(suggestedChange.createdDate, FULL_DATE_FORMAT)}`} icon={Pencil} testId={`${testId}-suggested-changes-read-only-modal`} />
                <SuggestionContent suggestedChange={suggestedChange} contentView={contentView} sendingMessage={sendingMessage} testId={`${testId}-suggested-changes`} />
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' testId={`${testId}-suggested-changes-read-only-close`} />
                    <Button onClick={toggleContent} label={toggleViewLabel} testId={`${testId}-suggested-changes-read-only-conversation`} />
                    {showSendButton && <Button onClick={sendMessage} label='Send Message' disabled={sendingMessage} testId={`${testId}-suggested-changes-read-only-send`} />}
                </div>
            </div>
        </Modal>
    );
};
