import { combineReducers, Reducer } from 'redux';
import { RouterState } from 'redux-first-history';

import { adminReducer, AdminState } from '../components/admin/store';
import { AnnexDefinitionState, annexReducer } from '../components/agency-annex/store';
import { userAttestationReducer, UserAttestationState } from '../components/attestations/store';
import { authReducer, AuthState } from '../components/auth/authReducer';
import { clauseLibraryReducer, ClauseLibraryState } from '../components/clause-library/store';
import { datasetInstanceReducer, DatasetInstanceState } from '../components/datasets/instances/store';
import { documentReducer, DocumentState } from '../components/documents/store';
import { homepageReducer, HomepageState } from '../components/home/store';
import { navigationReducer, NavigationState } from '../components/navigation/store';
import { PlaybookState, userPlaybookReducer } from '../components/playbook/store';
import { OpinionState, opinionReducer } from '../components/opinions/store';
import { doraReducer, DoraState } from '../components/dora/store';

export interface ApplicationState {
    auth: AuthState;
    admin: AdminState;
    datasetInstance: DatasetInstanceState;
    navigation: NavigationState;
    document: DocumentState;
    router: RouterState;
    annexDefinition: AnnexDefinitionState;
    opinion: OpinionState;
    attestations: UserAttestationState;
    homepage: HomepageState;
    playbook: PlaybookState;
    clauseLibrary: ClauseLibraryState;
    dora: DoraState;
}

export const createRootReducer = (routerReducer: Reducer<RouterState>) => combineReducers({
    router: routerReducer,
    auth: authReducer,
    admin: adminReducer,
    datasetInstance: datasetInstanceReducer,
    navigation: navigationReducer,
    document: documentReducer,
    annexDefinition: annexReducer,
    opinion: opinionReducer,
    attestations: userAttestationReducer,
    homepage: homepageReducer,
    playbook: userPlaybookReducer,
    clauseLibrary: clauseLibraryReducer,
    dora: doraReducer
});
