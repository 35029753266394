import React from 'react';

export const Password: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <g>
            <path fill={color || 'currentColor'} d="M58.755,83.051a1.751,1.751,0,0,0-2.391-.64l-3.347,1.932V80.478a1.75,1.75,0,0,0-3.5,0v3.865L46.17,82.411a1.75,1.75,0,1,0-1.75,3.031l3.347,1.932L44.42,89.307a1.75,1.75,0,1,0,1.75,3.031l3.347-1.933V94.27a1.75,1.75,0,0,0,3.5,0V90.405l3.347,1.933a1.75,1.75,0,1,0,1.75-3.031l-3.347-1.933,3.347-1.932A1.751,1.751,0,0,0,58.755,83.051Z" />
            <path fill={color || 'currentColor'} d="M33.289,83.051a1.751,1.751,0,0,0-2.391-.64l-3.347,1.932V80.478a1.75,1.75,0,0,0-3.5,0v3.865L20.7,82.411a1.75,1.75,0,1,0-1.75,3.031L22.3,87.374l-3.347,1.933a1.75,1.75,0,1,0,1.75,3.031l3.347-1.933V94.27a1.75,1.75,0,0,0,3.5,0V90.405L30.9,92.338a1.75,1.75,0,1,0,1.75-3.031L29.3,87.374l3.347-1.932A1.751,1.751,0,0,0,33.289,83.051Z" />
            <path fill={color || 'currentColor'} d="M84.221,83.051a1.751,1.751,0,0,0-2.391-.64l-3.347,1.932V80.478a1.75,1.75,0,0,0-3.5,0v3.865l-3.347-1.932a1.75,1.75,0,1,0-1.75,3.031l3.347,1.932-3.347,1.933a1.75,1.75,0,1,0,1.75,3.031l3.347-1.933V94.27a1.75,1.75,0,0,0,3.5,0V90.405l3.347,1.933a1.75,1.75,0,1,0,1.75-3.031l-3.347-1.933,3.347-1.932A1.751,1.751,0,0,0,84.221,83.051Z" />
            <path fill={color || 'currentColor'} d="M109.687,83.051a1.751,1.751,0,0,0-2.391-.64l-3.347,1.932V80.478a1.75,1.75,0,0,0-3.5,0v3.865L97.1,82.411a1.75,1.75,0,1,0-1.75,3.031L98.7,87.374l-3.347,1.933a1.75,1.75,0,1,0,1.75,3.031l3.347-1.933V94.27a1.75,1.75,0,0,0,3.5,0V90.405l3.347,1.933a1.75,1.75,0,1,0,1.75-3.031L105.7,87.374l3.347-1.932A1.751,1.751,0,0,0,109.687,83.051Z" />
            <path fill={color || 'currentColor'} d="M118.192,69.41h-5.065V53.056a1.749,1.749,0,0,0-1.75-1.75H101.56V42.117a37.561,37.561,0,0,0-75.121,0v9.189H16.623a1.749,1.749,0,0,0-1.75,1.75V69.41H9.808a5.257,5.257,0,0,0-5.251,5.25v25.428a5.257,5.257,0,0,0,5.251,5.251h5.065v16.354a1.75,1.75,0,0,0,1.75,1.75h94.754a1.75,1.75,0,0,0,1.75-1.75V105.339h5.065a5.257,5.257,0,0,0,5.251-5.251V74.66A5.257,5.257,0,0,0,118.192,69.41ZM29.939,42.117a34.061,34.061,0,0,1,68.121,0v9.189H89.232V42.117a25.232,25.232,0,0,0-50.464,0v9.189H29.939Zm12.329,9.189V42.117a21.732,21.732,0,0,1,43.464,0v9.189Zm-23.895,3.5h91.254v14.6H18.373Zm91.254,65.137H18.373v-14.6h91.254Zm10.316-19.855a1.752,1.752,0,0,1-1.751,1.751H9.808a1.752,1.752,0,0,1-1.751-1.751V74.66a1.752,1.752,0,0,1,1.751-1.75H118.192a1.752,1.752,0,0,1,1.751,1.75Z" />
        </g>
    </svg>
);
