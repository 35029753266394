import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getConfirmNewPassword, getCurrentPassword, getErrorMessage, getIsResettingPassword, getNewPassword, getPasswordIsStrongEnough, resetPasswordStarted, resetStoreValues, updatePasswordField } from './store';
import { PasswordInput } from './PasswordInput';
import styles from './ResetPassword.module.scss';
import { PasswordStrengthChecker } from './PasswordStrengthChecker';

export const ResetPassword: React.FC = () => {
    const dispatch = useAppDispatch();
    const errorMessage = useAppSelector(getErrorMessage);
    const currentPassword = useAppSelector(getCurrentPassword);
    const newPassword = useAppSelector(getNewPassword);
    const confirmNewPassword = useAppSelector(getConfirmNewPassword);
    const resettingPassword = useAppSelector(getIsResettingPassword);
    const passwordIsStrongEnough = useAppSelector(getPasswordIsStrongEnough);

    const newPasswordsUnequal = newPassword !== confirmNewPassword;
    const emptyFields = !currentPassword.length || !newPassword.length || !confirmNewPassword.length;
    const resetPasswordDisabled = newPasswordsUnequal || emptyFields || resettingPassword || !passwordIsStrongEnough;

    const attemptResetPassword = () => dispatch(resetPasswordStarted());
    const attemptKeyPressResetPassword = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter' && !resetPasswordDisabled) {
            attemptResetPassword();
        }
    };

    const updateField = (key: string, value: string) => dispatch(updatePasswordField(key, value));

    useEffect(() => {
        return () => {
            dispatch(resetStoreValues());
        };
    }, [dispatch]);

    return (
        <div className={styles.wrapper} data-testid='reset-password-wrapper'>
            <div className={styles.inputsWrapper} id='reset-password'>
                <PasswordInput
                    testId='current-password'
                    updateField={updateField}
                    property='currentPassword'
                    value={currentPassword}
                    label='Current Password'
                    labelFontSize='14px'
                    onKeyDown={attemptKeyPressResetPassword}
                    inputPadding='10px'
                />
                <PasswordInput
                    testId='new-password'
                    updateField={updateField}
                    property='newPassword'
                    value={newPassword}
                    label='New Password'
                    labelFontSize='14px'
                    onKeyDown={attemptKeyPressResetPassword}
                    inputPadding='10px'
                />
                <PasswordInput
                    testId='confirm-new-password'
                    updateField={updateField}
                    property='confirmNewPassword'
                    value={confirmNewPassword}
                    label='Confirm New Password'
                    labelFontSize='14px'
                    onKeyDown={attemptKeyPressResetPassword}
                    inputPadding='10px'
                />
            </div>
            <PasswordStrengthChecker password={newPassword} width='100%' />
            <div className={styles.errorMessage} data-testid='reset-password-error-message'>{errorMessage}</div>
        </div>
    );
};
