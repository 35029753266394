import { isNull, isUndefined } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { FunctionMigration } from '../../../dora/my-companies/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { WATCHLIST_ENTITY } from '../../../constants/entity';
import { Entity, EntityState, Lei, EntityModalType, EntityType, EntityDB, BaseEntity, SearchEntityBy, GroupEntity, CompanyEntitySection, BranchesFuzzyMatch, ClassificationCompanyEntity, NettingEngineEntity, SubCounterpartyType } from './types';

const getIsCompleted = (entity: ClassificationCompanyEntity) => {
    const { MiFIDClassification, counterpartyType, opinionCounterpartyType, emirClassification, entityServiceType } = entity;
    return [MiFIDClassification, counterpartyType, opinionCounterpartyType, emirClassification, entityServiceType].every(field => !isUndefined(field) && ((field.value && !field.aiPopulated) || field.aiConfirmed));
};

const getRoot = (state: ApplicationState): EntityState => state.admin.entity;

export const getModalOpen = (state: ApplicationState): EntityModalType | null => getRoot(state).modalOpen;

export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;

export const getCurrentEntity = (state: ApplicationState): Entity | null => getRoot(state).currentEntity;

export const getSavedEntity = (state: ApplicationState): Entity | null => getRoot(state).savedEntity;

export const getIsSearching = (state: ApplicationState): boolean => getRoot(state).searchingEntities;

export const getSearchResults = (state: ApplicationState): Lei[] => getRoot(state).searchResults;

export const getAllEntities = (state: ApplicationState): BaseEntity[] => getRoot(state).allEntities;

export const getNettingEntities = (state: ApplicationState): NettingEngineEntity[] => getRoot(state).nettingEntities;

export const getBaseEntityDropdownOptions = (state: ApplicationState): DropdownOption[] => {
    const allEntities = getAllEntities(state);
    const groupEntities = allEntities.length > 0 && allEntities.filter(({ type }) => type === EntityType.GROUP) || [];
    const groupEntityOptions = groupEntities.length > 0 ? groupEntities.map(({ name, entityId }, index) => ({ value: entityId.toString(), label: name, showDivider: index === groupEntities.length - 1 })) : [];
    const companyEntities = allEntities.length > 0 && allEntities.filter(({ type }) => type === EntityType.COMPANY) || [];
    const companyEntityOptions = companyEntities.length > 0 ? companyEntities.map(({ name, entityId }) => ({ value: entityId.toString(), label: name })) : [];
    const entityOptions = [WATCHLIST_ENTITY, ...groupEntityOptions, ...companyEntityOptions];
    return entityOptions;
};

export const getPaginatedEntities = (state: ApplicationState): EntityDB[] => getRoot(state).paginatedEntities;

export const getAllCompanyEntityNames = (state: ApplicationState): string[] => getRoot(state).allEntities.filter(({ type }) => type === EntityType.COMPANY).map(({ name }) => name);

export const getIsFetchingEntities = (state: ApplicationState): boolean => getRoot(state).fetchingEntities;

export const getSearchEntityType = (state: ApplicationState): SearchEntityBy => getRoot(state).searchBy;

export const getSearchEntityValue = (state: ApplicationState): string => getRoot(state).searchInput;

export const getMyCompanyEntities = (state: ApplicationState): BaseEntity[] => getRoot(state).myCompanyEntities;

export const getMyEntityGroups = (state: ApplicationState): GroupEntity[] => getRoot(state).groupEntities;

export const getEntityIdsForGroup = (state: ApplicationState): string[] | null => getRoot(state).entityIdsForGroup;

export const getCompanyEntityGroups = (state: ApplicationState): string[] => getRoot(state).companyEntityGroupIds;

export const getFunctionMigration = (state: ApplicationState): FunctionMigration | null => getRoot(state).doraFunctionMigration;

export const getMigrateFunctionConfirmationModalOpen = (state: ApplicationState): boolean => {
    const functionMigration = getFunctionMigration(state);
    return !isNull(functionMigration) && functionMigration.migrations.length > 0;
};

export const getSelectedCompanySection = (state: ApplicationState): CompanyEntitySection => getRoot(state).selectedCompanySection;

export const getBranchesFuzzyMatchModalOpen = (state: ApplicationState): number | null => getRoot(state).fuzzyMatchModalOpen;

export const getBranchesFuzzyMatchResults = (state: ApplicationState): BranchesFuzzyMatch[] => getRoot(state).fuzzyMatchResults;

export const getClassificationFilter = (state: ApplicationState): string => getRoot(state).classificationFilter;

export const getShowClassificationCompleted = (state: ApplicationState): boolean => getRoot(state).showCompleted;

export const getSavedClassificationEntities = (state: ApplicationState): ClassificationCompanyEntity[] => getRoot(state).savedClassificationEntities;

export const getCompletedClassificationEntityIds = (state: ApplicationState): number[] => {
    const savedClassificationEntities = getSavedClassificationEntities(state);
    return savedClassificationEntities.filter(entity => getIsCompleted(entity)).map(({ entityId }) => entityId);
};

export const getCurrentClassificationEntities = (state: ApplicationState): ClassificationCompanyEntity[] => {
    const allEntities = getRoot(state).currentClassificationEntities;
    const nameFilter = getClassificationFilter(state);
    const showCompleted = getShowClassificationCompleted(state);
    const completedEntityIds = getCompletedClassificationEntityIds(state);
    return allEntities.filter(({ entityId, name }) => name.toLowerCase().includes(nameFilter.toLowerCase()) && (showCompleted || (!showCompleted && !completedEntityIds.includes(entityId))));
};

export const getCurrentClassifyingEntityIds = (state: ApplicationState): number[] => getRoot(state).assertingEntityClassification;

export const getEntityClassificationHasUpdated = (state: ApplicationState): boolean => getRoot(state).entityClassificationHasUpdated;

export const getEntityClassificationCloseModalOpen = (state: ApplicationState): boolean => getRoot(state).closeClassificationConfirmationModalOpen;

export const getFuzzyCompletions = (state: ApplicationState): string[] => getRoot(state).fuzzyCompletions;

export const getAutoCompletions = (state: ApplicationState): string[] => getRoot(state).autoCompletions;

export const getShowCompletions = (state: ApplicationState): boolean => getRoot(state).showCompletions;

export const getShowFuzzyAutoCompletions = (state: ApplicationState): boolean => (getFuzzyCompletions(state).length > 0 || getAutoCompletions(state).length > 0) && getShowCompletions(state);

export const getAvailableSubCounterpartyTypes = (state: ApplicationState): SubCounterpartyType[] => getRoot(state).availableOpinionSubCounterpartyTypes;

export const getSelectedSubCounterpartyType = (state: ApplicationState): SubCounterpartyType | null => getRoot(state).selectedSubCounterparty;

export const getSelectedSubCounterpartyJurisdiction = (state: ApplicationState): string | null => getRoot(state).jurisdiction;

export const getSelectedSubCounterpartyParent = (state: ApplicationState): string => getRoot(state).parentCounterparty;

export const getIsFetchingSubCounterpartyTypes = (state: ApplicationState): boolean => getRoot(state).isFetchingSubCounterpartyTypes;

// Table Filters and Pagination
export const getEntitiesPageNumber = (state: ApplicationState): number => getRoot(state).entityPage;
export const getTotalEntities = (state: ApplicationState): number => getRoot(state).totalEntities;
export const getEntityFilters = (state: ApplicationState): TableFilters => getRoot(state).entityFilters;
export const getEntityColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).entitySort;
export const getEntitiesPageSize = (state: ApplicationState): number => getRoot(state).entityPageSize;

export const getEntitiesSearchPageNumber = (state: ApplicationState): number => getRoot(state).searchEntityPage;
export const getTotalSearchEntities = (state: ApplicationState): number => getRoot(state).totalSearchEntities;
export const getEntitiesSearchPageSize = (state: ApplicationState): number => getRoot(state).searchEntityPageSize;
