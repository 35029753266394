import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import classnames from 'classnames';
import { indexOf, uniq } from 'lodash/fp';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { DraggableItem, DraggableProps, getItemData, isItemData, useListContext } from '../../../shared/drag-n-drop/shared';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import styles from '../SupplyChain.module.scss';

export type TileState = 'idle' | 'dragging' | 'over';

interface SupplyChainCompanyProps extends DraggableProps {
    type: string;
    percentageHeight?: string;
    getChildren: (id: string) => number[];
    getHasParent: (id: string) => boolean;
    total?: number;
    relativeIndex: number;
    isAdditionalOption?: boolean;
    disabled: boolean;
}

const SupplyChainCompany: React.FC<SupplyChainCompanyProps> = ({
    item,
    getChildElement,
    getChildren,
    getHasParent,
    index,
    type,
    percentageHeight,
    total,
    relativeIndex,
    isAdditionalOption,
    disabled
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { instanceId, registerItem } = useListContext();
    const [tileState, setTileState] = useState<TileState>('idle');

    const supplyChainChildren = useMemo(() => getChildren(item.id), [getChildren, item.id]);

    useEffect(() => {
        const element = ref.current;
        invariant(element);

        const data = getItemData({ item, index, instanceId, type });

        return combine(
            registerItem({ itemId: item.id, type, element }),
            draggable({
                element,
                getInitialData: () => data,
                onDragStart: () => setTileState('dragging'),
                onDrop: () => setTileState('idle'),
                canDrag: () => type !== 'rank-0' && !disabled
            }),
            dropTargetForElements({
                element,
                getData: () => data,
                getIsSticky: () => true,
                canDrop: ({ source }) =>
                    source.data.instanceId === instanceId &&
                    isItemData(source.data) &&
                    source.data.item.id !== item.id &&
                    !getChildren(source.data.item.id).includes(parseInt(item.id)) &&
                    !disabled,
                onDragEnter: () => setTileState('over'),
                onDragLeave: () => setTileState('idle'),
                onDrop: () => setTileState('idle')
            }),
        );
    }, [instanceId, index, item, type, registerItem, getChildren, disabled]);

    const tile = useMemo(() => getChildElement(item.id, index, type), [getChildElement, item.id, index, type]);
    const hasChildren = useMemo(() => supplyChainChildren.length, [supplyChainChildren]);
    const hasParent = useMemo(() => getHasParent(item.id), [getHasParent, item.id]);
    const showVertical = useMemo(() => hasParent && total && total > 1, [hasParent, total]);
    const { height, offset } = useMemo(() => {
        let height = 100;
        let offset = 0;
        if (relativeIndex === 0) {
            height = 50;
            offset = 50;
        }
        if (total && relativeIndex === total - 1) {
            height = 50;
        }
        return { height, offset };
    }, [relativeIndex, total]);

    if (isAdditionalOption) {
        return (
            <div className={styles.supplyChainAdditionalCompanyWrapper} style={{ height: percentageHeight }}>
                <div
                    className={classnames(styles.supplyChainCompany, {
                        [styles.supplyChainDragging]: tileState === 'dragging',
                        [styles.supplyChainOver]: tileState === 'over',
                        [styles.supplyChainIdle]: tileState === 'idle',
                        [styles.supplyChainDisabled]: disabled
                    })}
                    ref={ref}
                >
                    {tile}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.supplyChainCompanyWrapper} style={{ height: percentageHeight }}>
            {showVertical && <div className={styles.verticalJoiningLine} style={{ height: `${height}%`, top: `${offset}%` }} />}
            <div className={classnames(styles.horizontalJoiningLine, { [styles.horizontalFill]: hasParent })} />
            <div
                className={classnames(styles.supplyChainCompany, {
                    [styles.supplyChainDragging]: tileState === 'dragging',
                    [styles.supplyChainOver]: tileState === 'over',
                    [styles.supplyChainIdle]: tileState === 'idle',
                    [styles.parentSupplyChainCompany]: type === 'rank-0',
                    [styles.supplyChainDisabled]: disabled
                })}
                ref={ref}
            >
                {tile}
            </div>
            <div className={classnames(styles.horizontalJoiningLine, { [styles.horizontalFill]: hasChildren })} />
        </div>
    );
};

interface RankContentProps {
    rank: string;
    tiles: DraggableItem[];
    isLastColumn?: boolean;
    getChildElement: (id: string, index: number, type?: string) => JSX.Element | null;
    getParentHeightAndOffset: (id: string | undefined, numberOfTiles: number) => ({ height: number; offset: number; });
    getChildren: (id: string) => number[];
    getHasParent: (id: string) => boolean;
    droppableHeight?: number;
    disabled?: boolean;
}

export const RankContent: React.FC<RankContentProps> = ({ rank, tiles, isLastColumn = false, getChildElement, getParentHeightAndOffset, getChildren, getHasParent, droppableHeight, disabled = false }) => {

    const parentIds = useMemo(() => uniq(tiles.map(({ parentId }) => parentId)), [tiles]);

    if (isLastColumn) {
        return (
            <div className={styles.scrollableDroppableWrapper}>
                <Scrollable>
                    {tiles.map((item, index) => (
                        <SupplyChainCompany
                            getChildElement={getChildElement}
                            getChildren={getChildren}
                            getHasParent={getHasParent}
                            type={rank}
                            index={index}
                            item={item}
                            key={item.id}
                            relativeIndex={index}
                            isAdditionalOption
                            disabled={disabled}
                        />
                    ))}
                </Scrollable>
            </div>
        );
    }

    return (
        <div className={styles.droppableWrapper} style={{ height: `${droppableHeight}px` }}>
            {parentIds.map((parentId, i) => {
                const tilesWithinParent = tiles.filter(tile => tile.parentId === parentId);
                const { height, offset } = getParentHeightAndOffset(parentId, tilesWithinParent.length);
                return (
                    <div className={styles.parentWrapper} key={`${parentId}-${i}`} style={{ height: `${height}%`, top: `${offset}%` }} data-testid={`parentWrapper-${rank}-${i}`}>
                        {tilesWithinParent.map((item, i) => {
                            const index = indexOf(item, tiles);
                            const percentageHeight = `${100 / tilesWithinParent.length}%`;
                            return (
                                <SupplyChainCompany
                                    getChildElement={getChildElement}
                                    getChildren={getChildren}
                                    getHasParent={getHasParent}
                                    type={rank}
                                    index={index}
                                    item={item}
                                    key={item.id}
                                    percentageHeight={percentageHeight}
                                    total={tilesWithinParent.length}
                                    relativeIndex={i}
                                    disabled={disabled}
                                />
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};
