import { isEqual, isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { getIsOpening } from '../../../documents/my-documents/store';
import { Button } from '../../../shared/button/Button';
import { ThirdParty } from '../../../shared/icons';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { UnsavedChangesModal } from '../../../shared/modal/UnsavedChangesModal';
import { cancelAssessmentDetailsUpdated, cancelContractDetailsUpdated, getIsSaving, getNewSupplyChainElement, getSelectedAssessmentTimeline, getSelectedElementContractualInfo, getSelectedElementWizardTab, getSelectedSupplyChainId, getSupplyChainDetailsModalOpen, getSupplyChainElementAssessmentTimeline, getSupplyChainElements, getSupplyChainElementUpdated, getSupplyChainUpdated, getUnsavedChangedModalOpen, setSelectedAssessmentTimelineDetails, setUnsavedChangesModalOpen, SupplyChainElementTab, toggleDeleteConfirmationModal, toggleSupplyChainElement, upsertSupplyChainElementStarted } from '../store';
import styles from '../SupplyChain.module.scss';
import { DeleteElementModal } from './DeleteElementModal';
import { Details } from './Details';
import { ElementWizard } from './ElementWizard';

export const ElementDetails: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getSupplyChainDetailsModalOpen);
    const selectedElementId = useAppSelector(getSelectedSupplyChainId);
    const supplyChainElements = useAppSelector(getSupplyChainElements);
    const newSupplyChainElement = useAppSelector(getNewSupplyChainElement);
    const supplyChainElementUpdated = useAppSelector(getSupplyChainElementUpdated);
    const supplyChainUpdated = useAppSelector(getSupplyChainUpdated);
    const isSaving = useAppSelector(getIsSaving);
    const selectedTab = useAppSelector(getSelectedElementWizardTab);
    const currentIctContractInstance = useAppSelector(getSelectedElementContractualInfo);
    const isOpeningDocument = useAppSelector(getIsOpening);

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const selectedElement = useMemo(() => supplyChainElements.find(({ doraSupplyChainId }) => doraSupplyChainId === selectedElementId), [supplyChainElements, selectedElementId]);

    const isNewElement = useMemo(() => isNull(selectedElementId), [selectedElementId]);

    const isRankedNode = useMemo(() => selectedElement && !isNull(selectedElement.supplyChainRank), [selectedElement]);

    const closeModal = useCallback(() => supplyChainElementUpdated ? dispatch(setUnsavedChangesModalOpen(true)) : dispatch(toggleSupplyChainElement(null)), [dispatch, supplyChainElementUpdated]);
    const confirm = useCallback(() => dispatch(upsertSupplyChainElementStarted()), [dispatch]);
    const modalHeader = useMemo(() => isUndefined(selectedElement) ? 'Create New Supply Chain Company Details' : selectedElement.label, [selectedElement]);
    const confirmLabel = useMemo(() => isNewElement ? 'Create' : 'Update', [isNewElement]);
    const newElementId = useMemo(() => newSupplyChainElement.companyId || newSupplyChainElement.thirdPartyCompanyId, [newSupplyChainElement]);

    const confirmDisabled = useMemo(() => (!isNull(selectedElementId) && !supplyChainElementUpdated) || (isNull(selectedElementId) && isNull(newElementId)) || isSaving || !hasDoraFullAccessPermission, [selectedElementId, newElementId, isSaving, supplyChainElementUpdated, hasDoraFullAccessPermission]);

    const showDeleteOption = useMemo(() => (!isUndefined(selectedElement) && isNull(selectedElement.supplyChainRank) && !supplyChainUpdated) && hasDoraFullAccessPermission, [selectedElement, supplyChainUpdated, hasDoraFullAccessPermission]);

    const toggleDeleteModal = useCallback((isOpen: boolean) => dispatch(toggleDeleteConfirmationModal(isOpen)), [dispatch]);
    const openDeleteModal = useCallback(() => toggleDeleteModal(true), [toggleDeleteModal]);
    const closeDeleteModal = useCallback(() => toggleDeleteModal(false), [toggleDeleteModal]);

    const elementDetailsDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (isNewElement && !newElementId) {
            disabledArray.push('You must select whether this is one of your companies or an ICT third-party service provider');
        }
        if (!isNull(selectedElementId) && !supplyChainElementUpdated) {
            disabledArray.push('No details have been updated');
        }
        return disabledArray;
    }, [isNewElement, newElementId, selectedElementId, supplyChainElementUpdated]);

    const content = useMemo(() => {
        if (isRankedNode) {
            return <ElementWizard />;
        }
        return <Details />;
    }, [isRankedNode]);

    const assessmentTimeline = useAppSelector(getSupplyChainElementAssessmentTimeline);
    const selectedAssessmentTimeline = useAppSelector(getSelectedAssessmentTimeline);

    const assessmentIsReadOnlyView = useMemo(() => !isNull(selectedAssessmentTimeline) || !hasDoraFullAccessPermission, [selectedAssessmentTimeline, hasDoraFullAccessPermission]);
    const hasAssessmentTimeline = useMemo(() => assessmentTimeline.length > 0, [assessmentTimeline]);
    const activeAssessmentDetails = useMemo(() => hasAssessmentTimeline ? assessmentTimeline[assessmentTimeline.length - 1] : null, [hasAssessmentTimeline, assessmentTimeline]);
    const showAssessmentEditButton = useMemo(() => isEqual(selectedAssessmentTimeline, activeAssessmentDetails) && hasAssessmentTimeline && selectedTab === SupplyChainElementTab.ASSESSMENT, [hasAssessmentTimeline, selectedAssessmentTimeline, activeAssessmentDetails, selectedTab]);
    const showEdit = useMemo(() => showAssessmentEditButton, [showAssessmentEditButton]);
    const showAssessmentCancel = useMemo(() => !hasAssessmentTimeline ? false : !assessmentIsReadOnlyView && selectedTab === SupplyChainElementTab.ASSESSMENT, [hasAssessmentTimeline, assessmentIsReadOnlyView, selectedTab]);
    const showCancel = useMemo(() => showAssessmentCancel, [showAssessmentCancel]);
    const showConfirmButton = useMemo(() => selectedTab !== SupplyChainElementTab.CONTRACT, [selectedTab]);
    const showGoToDocumentButton = useMemo(() => selectedTab === SupplyChainElementTab.CONTRACT && !isNull(currentIctContractInstance), [selectedTab, currentIctContractInstance]);

    const editDetails = useCallback(() => dispatch(setSelectedAssessmentTimelineDetails(null)), [dispatch]);
    const cancelUpdate = useCallback(() => {
        if (selectedTab === SupplyChainElementTab.ASSESSMENT) {
            dispatch(setSelectedAssessmentTimelineDetails(activeAssessmentDetails));
            dispatch(cancelAssessmentDetailsUpdated());
        } else {
            dispatch(cancelContractDetailsUpdated());
        }
    }, [selectedTab, dispatch, activeAssessmentDetails]);

    const unsavedChangedModalOpen = useAppSelector(getUnsavedChangedModalOpen);
    const showUnsavedChangesModal = unsavedChangedModalOpen && hasDoraFullAccessPermission;
    const closeUnsavedChangedModal = useCallback(() => dispatch(setUnsavedChangesModalOpen(false)), [dispatch]);
    const confirmUnsavedChanged = useCallback(() => {
        dispatch(setUnsavedChangesModalOpen(false));
        dispatch(toggleSupplyChainElement(null));
    }, [dispatch]);

    const handleOpenDocument = useCallback(() => {
        if (currentIctContractInstance && !isOpeningDocument) {
            const path = `/documents/analysis/${currentIctContractInstance.originalDocumentId}/${currentIctContractInstance.instanceId}`;
            history.push(path);
            closeModal();
        }
    }, [currentIctContractInstance, isOpeningDocument, closeModal, history,]);

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <Modal
                isOpen
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
                onRequestClose={closeModal}
                style={{ overlay: { display: 'flex' } }}
                className={styles.modal}
                ariaHideApp={false}
            >
                <div className={styles.elementDetailsWrapper}>
                    <ModalHeader label={modalHeader} icon={ThirdParty} />
                    <div className={styles.elementDetails}>
                        {content}
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div className={styles.leftButtonWrapper}>
                            {showDeleteOption && <Button label='Delete' onClick={openDeleteModal} disabled={!hasDoraFullAccessPermission} />}
                            <Button onClick={closeModal} label='Close' />
                            {showCancel && <Button onClick={cancelUpdate} label='Cancel' testId='function-config-cancel' />}
                        </div>
                        <div className={styles.rightButtonWrapper}>
                            {showEdit && <Button onClick={editDetails} label='Edit' testId='function-config-edit' disabled={!hasDoraFullAccessPermission} />}
                            {showConfirmButton && <Button onClick={confirm} label={confirmLabel} disabled={confirmDisabled} disabledTooltip={elementDetailsDisabledTooltip} />}
                            {showGoToDocumentButton && <Button width='300px' onClick={handleOpenDocument} label='Expand Document Details' />}
                        </div>
                    </div>
                </div>
            </Modal>
            <DeleteElementModal closeModal={closeDeleteModal} />
            <UnsavedChangesModal
                isOpen={showUnsavedChangesModal}
                closeModal={closeUnsavedChangedModal}
                confirm={confirmUnsavedChanged}
            />
        </>
    );
};
