import { flow, indexOf, isEqual, isNull, isNumber, set, unset } from 'lodash/fp';
import { Reducer } from 'redux';
import { v4 } from 'uuid';

import { formatDateTime } from '../../../utils/luxon';
import { PlaybookCondition, PlaybookLogicalOperator, PlaybookQueryDB, PlaybookSearchContentType, PlaybookSectionType, PlaybookView, ReadOnlyContentType } from '../../admin/playbook/store';
import { LoginActionTypes } from '../../auth/login/store';
import { ABSTRACT_ID, getProvisionTitle, initialUserQuery } from '../../constants/playbooks';
import { PlaybookActionTypes, PlaybookContentType, PlaybookQuery, PlaybookState } from './types';
import { isSectionChanges } from './typeAssertions';

const stripPlaybookQueryDb = (playbook: PlaybookQueryDB, playbookName: string): PlaybookQuery => flow(
    unset('createdDate'),
    unset('clientId'),
    set('playbookName', playbookName)
)(playbook);

export const initialQuery = (playbookId: number, version: string, playbookName: string, playbookDefinitionId: number, sectionId: string, sectionTitle: string, signOff: string, userId: number, username: string): PlaybookQuery => ({
    playbookName,
    version,
    playbookDefinitionId,
    queryConversation: [initialUserQuery(playbookName, version, sectionTitle, signOff, userId, username)],
    sectionId,
    resolved: 0,
    playbookId
});

export const initialUserPlaybookSmartSearchTerm = {
    logicalOperator: PlaybookLogicalOperator.AND,
    condition: PlaybookCondition.INCLUDES,
    contentType: PlaybookSearchContentType.TITLE,
    searchTerm: ''
};

export const INITIAL_STATE: PlaybookState = {
    allPlaybooks: [],
    playbookCoverSelected: null,
    playbook: null,
    currentView: PlaybookView.LIST,
    playbookHistory: [],
    querySectionId: null,
    query: null,
    queryReady: false,
    sendingQuery: false,
    queries: [],
    suggestedChanges: null,
    additionalQuery: null,
    suggestedChangesMessage: null,
    suggestedChangesMessageUpdated: false,
    suggestedChangesUpdated: false,
    showConfirmationModal: false,
    sendingSuggestion: false,
    playbookSuggestedChanges: [],
    openDeviations: [],
    playbookSuggestedChange: null,
    readOnlySuggestedChangesContent: ReadOnlyContentType.CHANGES,
    sendingMessage: false,
    isLoading: false,
    simpleSearchTerm: '',
    smartSearchTerm: [initialUserPlaybookSmartSearchTerm],
    linkedProvisionModalOpen: null,
    provisionLinkHistory: [],
    provisionLinkHistoryModalOpen: false
};

export const userPlaybookReducer: Reducer<PlaybookState> = (state = INITIAL_STATE, { payload, type }): PlaybookState => {
    switch (type) {
        case PlaybookActionTypes.GET_ALL_PLAYBOOKS_STARTED:
            return set('isLoading', true, state);
        case PlaybookActionTypes.GET_ALL_PLAYBOOKS_SUCCESSFUL:
            return flow(
                set('allPlaybooks', payload),
                set('isLoading', false)
            )(state);
        case PlaybookActionTypes.GET_ALL_PLAYBOOKS_FAILED:
            return set('isLoading', false, state);
        case PlaybookActionTypes.TOGGLE_PLAYBOOK_COVER_SELECTED:
            return set('playbookCoverSelected', payload, state);
        case PlaybookActionTypes.GET_USER_PLAYBOOK_BY_ID_SUCCESSFUL: {
            const { playbook: { currentPlaybook, playbookHistory, playbookQueries, playbookSuggestedChanges } } = payload;
            return flow(
                set('playbook', currentPlaybook),
                set('currentView', currentPlaybook.defaultView),
                set('playbookHistory', playbookHistory),
                set('queries', playbookQueries),
                set('playbookSuggestedChanges', playbookSuggestedChanges)
            )(state);
        }
        case PlaybookActionTypes.GET_USER_PLAYBOOK_BY_DEFINITION_ID_STARTED:
            return set('isLoading', true, state);
        case PlaybookActionTypes.GET_USER_PLAYBOOK_BY_DEFINITION_ID_SUCCESSFUL: {
            const { playbook: { playbookInstance, playbookHistory } } = payload;
            return flow(
                set('playbook', playbookInstance),
                set('currentView', playbookInstance.defaultView),
                set('playbookHistory', playbookHistory),
                set('openDeviations', []),
                set('isLoading', false)
            )(state);
        }
        case PlaybookActionTypes.GET_USER_PLAYBOOK_BY_DEFINITION_ID_FAILED:
            return set('isLoading', false, state);
        case PlaybookActionTypes.CHANGE_USER_PLAYBOOK_CURRENT_VIEW:
            return set('currentView', payload, state);
        case PlaybookActionTypes.TOGGLE_PLAYBOOK_QUERY_MODAL_OPEN: {
            const { sectionId, user } = payload;
            if (isNull(sectionId)) {
                return flow(
                    set('querySectionId', sectionId),
                    set('query', null),
                    set('queryReady', false),
                    set('additionalQuery', null)
                )(state);
            }
            const { forenames, surname, userId, username } = user;
            const signOff = forenames && surname ? `${forenames} ${surname}` : '';
            const queryCreatedBy = signOff || username;
            const playbookName = state.playbook?.name || '';
            const sectionTitle = getProvisionTitle(sectionId, state.playbook!.content.sections);
            const sectionIndex = state.playbook!.content.sections.map(({ sectionId }) => sectionId).indexOf(sectionId)!;
            const reversePreviousSections = state.playbook?.content.sections.filter((_, index) => index < sectionIndex).reverse();
            const subheaderTitle = reversePreviousSections?.find(({ type }) => type === PlaybookSectionType.SUBHEADER)?.title || '';
            const title = subheaderTitle ? `${subheaderTitle} - ${sectionTitle}` : sectionTitle;
            const version = `(${state.playbook!.versionMajor}.${state.playbook!.versionMinor})`;
            const playbookDefinitionId = state.playbook!.playbookDefinitionId;
            const newQuery = initialQuery(state.playbook!.playbookId, version, playbookName, playbookDefinitionId, sectionId, title, signOff, userId, queryCreatedBy);
            return flow(
                set('querySectionId', sectionId),
                set('query', newQuery)
            )(state);
        }
        case PlaybookActionTypes.UPDATE_USER_PLAYBOOK_QUERY: {
            const { content, index } = payload;
            return set(`query.queryConversation[${index}].content`, content, state);
        }
        case PlaybookActionTypes.SEND_QUERY_STARTED:
            return set('sendingQuery', true, state);
        case PlaybookActionTypes.SEND_QUERY_SUCCESSFUL: {
            return flow(
                set('querySectionId', null),
                set('query', null),
                set('queryReady', false),
                set('sendingQuery', false),
                set('queries', payload),
            )(state);
        }
        case PlaybookActionTypes.SEND_QUERY_FAILED:
            return set('sendingQuery', false, state);
        case PlaybookActionTypes.TOGGLE_QUERY_READY:
            return set('queryReady', !state.queryReady, state);
        case PlaybookActionTypes.SET_SELECTED_QUERY: {
            const playbookName = state.playbook?.name || '';
            const selectedQuery = state.queries.find(({ playbookQueryId }) => playbookQueryId === payload) || null;
            if (isNull(selectedQuery)) {
                return set('query', null, state);
            }
            const query = stripPlaybookQueryDb(selectedQuery, playbookName);
            return set('query', query, state);
        }
        case PlaybookActionTypes.SEND_QUERY_REMINDER_STARTED:
            return set('sendingQuery', true, state);
        case PlaybookActionTypes.SEND_QUERY_REMINDER_SUCCESSFUL:
            return flow(
                set('query', null),
                set('sendingQuery', false),
                set('queries', payload),
            )(state);
        case PlaybookActionTypes.SEND_QUERY_REMINDER_FAILED:
            return set('sendingQuery', false, state);
        case PlaybookActionTypes.TOGGLE_PLAYBOOK_SUGGESTED_CHANGES_MODAL_OPEN: {
            if (isNull(payload)) {
                return flow(
                    set('suggestedChanges', null),
                    set('suggestedChangesUpdated', false),
                    set('showConfirmationModal', false),
                    set('suggestedChangesMessage', null),
                    set('suggestedChangesMessageUpdated', false),
                    set('readOnlySuggestedChangesContent', ReadOnlyContentType.CHANGES)
                )(state);
            }
            const content = payload === ABSTRACT_ID ? state.playbook!.content.abstract : state.playbook!.content.sections.find(({ sectionId }) => sectionId === payload)!;
            const type = payload === ABSTRACT_ID ? PlaybookContentType.ABSTRACT : PlaybookContentType.SECTION;
            const suggestedChanges = { content, type };
            return set('suggestedChanges', suggestedChanges, state);
        }
        case PlaybookActionTypes.UPDATE_PLAYBOOK_SUGGESTED_CHANGES: {
            const suggestedChanges = state.suggestedChanges;
            const { key, value } = payload;
            if (!isNull(suggestedChanges)) {
                if (isSectionChanges(suggestedChanges)) {
                    return set(`suggestedChanges.content[${key}]`, value, state);
                }
                return set('suggestedChanges.content', value, state);
            }
            return state;
        }
        case PlaybookActionTypes.UPDATE_PLAYBOOK_SUGGESTED_CHANGED_CONTENT:
            return set('suggestedChanges.changedContent', payload, state);
        case PlaybookActionTypes.ADD_SUGGESTED_CHANGES_DEVIATION_ROW: {
            const index = payload;
            const suggestedChanges = state.suggestedChanges;
            if (!isNull(suggestedChanges) && isSectionChanges(suggestedChanges)) {
                const newRow = { topic: '', variations: null, background: null, nid: '', rowId: v4(), approvalDate: null, validForInterval: null, validForIntervalValue: null };
                const currentDeviations = suggestedChanges.content.deviations;
                const deviations = [...currentDeviations];
                const spliceIndex = isNumber(index) ? index : deviations.length;
                deviations.splice(spliceIndex, 0, newRow);
                return set('suggestedChanges.content.deviations', deviations, state);
            }
            return state;
        }
        case PlaybookActionTypes.REMOVE_SUGGESTED_CHANGES_DEVIATION_ROW: {
            const suggestedChanges = state.suggestedChanges;
            if (!isNull(suggestedChanges) && isSectionChanges(suggestedChanges)) {
                const deviations = suggestedChanges.content.deviations.filter(({ rowId }) => rowId !== payload);
                return set('suggestedChanges.content.deviations', deviations, state);
            }
            return state;
        }
        case PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_DEVIATION_VALUE: {
            const { rowId, key, value } = payload;
            const suggestedChanges = state.suggestedChanges;
            if (!isNull(suggestedChanges) && isSectionChanges(suggestedChanges)) {
                const deviation = suggestedChanges.content.deviations.find(row => row.rowId === rowId);
                const index = indexOf(deviation, suggestedChanges.content.deviations);
                return set(`suggestedChanges.content.deviations[${index}][${key}]`, value, state);
            }
            return state;
        }
        case PlaybookActionTypes.UPDATE_ADDITIONAL_USER_QUERY: {
            const { content, user: { userId, username } } = payload;
            const queryResponse = { content, userId, username, date: formatDateTime() };
            return set('additionalQuery', queryResponse, state);
        }
        case PlaybookActionTypes.SEND_ADDITIONAL_USER_QUERY_STARTED:
            return set('sendingQuery', true, state);
        case PlaybookActionTypes.SEND_ADDITIONAL_USER_QUERY_SUCCESSFUL:
            return flow(
                set('querySectionId', null),
                set('query', null),
                set('additionalQuery', null),
                set('sendingQuery', false),
                set('queries', payload),
            )(state);
        case PlaybookActionTypes.SEND_ADDITIONAL_USER_QUERY_FAILED:
            return set('sendingQuery', false, state);
        case PlaybookActionTypes.SET_SUGGESTED_CHANGES_UPDATED:
            return set('suggestedChangesUpdated', payload, state);
        case PlaybookActionTypes.SET_SHOW_CONFIRMATION_MODAL:
            return set('showConfirmationModal', !state.showConfirmationModal, state);
        case PlaybookActionTypes.SEND_SUGGESTED_CHANGES_STARTED:
            return set('sendingSuggestion', true, state);
        case PlaybookActionTypes.SEND_SUGGESTED_CHANGES_SUCCESSFUL: {
            return flow(
                set('suggestedChanges', null),
                set('suggestedChangesUpdated', false),
                set('sendingSuggestion', false),
                set('playbookSuggestedChanges', payload),
                set('suggestedChangesMessage', null),
                set('suggestedChangesMessageUpdated', false)
            )(state);
        }
        case PlaybookActionTypes.SEND_SUGGESTED_CHANGES_FAILED:
            return set('sendingSuggestion', false, state);
        case PlaybookActionTypes.TOGGLE_USER_SECTION_DEVIATION: {
            const shouldCloseDeviation = state.openDeviations.find(sectionId => isEqual(sectionId, payload));
            const updatedOpenDeviations = shouldCloseDeviation ? state.openDeviations.filter(sectionId => !isEqual(sectionId, payload)) : [...state.openDeviations, payload];
            return set('openDeviations', updatedOpenDeviations, state);
        }
        case PlaybookActionTypes.SET_SELECTED_SUGGESTED_CHANGE: {
            const selectedSuggestedChange = state.playbookSuggestedChanges.find(({ playbookSuggestionId }) => playbookSuggestionId === payload) || null;
            if (isNull(selectedSuggestedChange)) {
                return flow(
                    set('playbookSuggestedChange', null),
                    set('suggestedChangesMessage', null),
                    set('suggestedChangesMessageUpdated', false),
                    set('readOnlySuggestedChangesContent', ReadOnlyContentType.CHANGES)
                )(state);
            }
            return set('playbookSuggestedChange', selectedSuggestedChange, state);
        }
        case PlaybookActionTypes.CLOSE_ALL_USER_DEVIATIONS:
            return set('openDeviations', [], state);
        case PlaybookActionTypes.UPDATE_USER_SUGGESTED_CHANGES_NEW_MESSAGE: {
            const { content, user: { userId, username } } = payload;
            if (isNull(state.suggestedChangesMessage)) {
                const newMessage = { content, userId, username, date: formatDateTime() };
                return flow(
                    set('suggestedChangesMessage', newMessage),
                    set('suggestedChangesMessageUpdated', !isNull(content))
                )(state);
            }
            return flow(
                set('suggestedChangesMessage.content', content),
                set('suggestedChangesMessageUpdated', !isNull(content))
            )(state);
        }
        case PlaybookActionTypes.UPDATE_READ_ONLY_SUGGESTED_CHANGES_CONTENT:
            return set('readOnlySuggestedChangesContent', payload, state);
        case PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED:
            return set('sendingMessage', true, state);
        case PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_SUCCESSFUL: {
            const { suggestedChanges, allSuggestedChanges } = payload;
            return flow(
                set('playbookSuggestedChange', suggestedChanges),
                set('playbookSuggestedChanges', allSuggestedChanges),
                set('sendingMessage', false),
                set('suggestedChangesMessage', null),
                set('suggestedChangesMessageUpdated', false)
            )(state);
        }
        case PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_FAILED:
            return set('sendingMessage', false, state);
        case PlaybookActionTypes.UPDATE_PLAYBOOK_SIMPLE_SEARCH:
            return set('simpleSearchTerm', payload.toLowerCase(), state);
        case PlaybookActionTypes.RESET_PLAYBOOK_SEARCH:
            return set('simpleSearchTerm', '', state);
        case PlaybookActionTypes.UPDATE_PLAYBOOK_SMART_SEARCH:
            return set('smartSearchTerm', payload, state);
        case PlaybookActionTypes.PLAYBOOK_SMART_SEARCH_STARTED:
            return set('isLoading', true, state);
        case PlaybookActionTypes.PLAYBOOK_SMART_SEARCH_SUCCESSFUL:
            return flow(
                set('allPlaybooks', payload),
                set('isLoading', false)
            )(state);
        case PlaybookActionTypes.PLAYBOOK_SMART_SEARCH_FAILED:
            return set('isLoading', false, state);
        case PlaybookActionTypes.RESET_PLAYBOOK_SMART_SEARCH:
            return set('smartSearchTerm', [initialUserPlaybookSmartSearchTerm], state);
        case PlaybookActionTypes.TOGGLE_USER_LINKED_PROVISION_MODAL: {
            if (payload === state.linkedProvisionModalOpen) {
                return set('linkedProvisionModalOpen', null, state);
            }
            return set('linkedProvisionModalOpen', payload, state);
        }
        case PlaybookActionTypes.UPDATE_USER_LINKED_PROVISION_HISTORY:
            return set('provisionLinkHistory', payload, state);
        case PlaybookActionTypes.TOGGLE_USER_PROVISION_LINK_HISTORY_MODAL:
            return set('provisionLinkHistoryModalOpen', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
