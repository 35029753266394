import { isNull, noop } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../../shared/table/ArkTable';
import { bespokeFormatter, commissionedByFormatter } from '../../../../shared/table/arkTableFormatters';
import styles from '../../Analytics.module.scss';
import { OpinionAverage, clearOpinionAverageTableFilters, getOpinionAverageColumnSort, getOpinionAverageFilters, getOpinionsWithAverage, redirectToOpinion, setOpinionAverageTableColumnSort, setOpinionAverageTableFilters } from '../../store';
import { getAverageLabel } from './AverageLevel';
import { TableFilterType } from '../../../../shared/modal/TableFilterModal';
import { DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { getAllDropdownLists } from '../../../../admin/dropdown-lists/store';
import { allFocusOptions, opinionCommissionedByOptions } from '../../../../constants/opinion';

const averageFormatter = (average: number) => {
    const { colour, label } = getAverageLabel(average);
    return [colour, label];
};

interface AverageTableProps {
    height: number;
    width: number;
    fetchOpinionBreakdown: (opinionId: number) => void;
    selectOpinion: (opinion: OpinionAverage) => void;
    selectedOpinion: OpinionAverage | null;
}

export const averageOptions: DropdownOption[] = [
    { value: '1', label: 'Highly Desirable' },
    { value: '0.8', label: 'Desirable' },
    { value: '0.6', label: 'Acceptable' },
    { value: '0.4', label: 'Of Concern' },
    { value: '0.2', label: 'Of Serious Concern' },
];

export const AverageTable: React.FC<AverageTableProps> = ({ height, width, fetchOpinionBreakdown, selectedOpinion, selectOpinion }) => {
    const dispatch = useAppDispatch();
    const opinionsWithAverage = useAppSelector(getOpinionsWithAverage);
    const filters = useAppSelector(getOpinionAverageFilters);
    const columnSort = useAppSelector(getOpinionAverageColumnSort);
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setOpinionAverageTableColumnSort(columnSort)), [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setOpinionAverageTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearOpinionAverageTableFilters()), [dispatch]);

    const showBreakdown = useCallback((data: OpinionAverage) => {
        fetchOpinionBreakdown(data.opinionId);
    }, [fetchOpinionBreakdown]);

    const tableWidth = useMemo(() => width / 2, [width]);
    const tableHeight = useMemo(() => height - 80, [height]);
    const selectedTableRow = useMemo(() => !isNull(selectedOpinion) ? { field: 'opinionId', fieldValue: selectedOpinion.opinionId } : undefined, [selectedOpinion]);

    const redirect = useCallback((row: OpinionAverage) => dispatch(redirectToOpinion(row.location, row.opinionId)), [dispatch]);

    const opinionJurisdictionOptions = useMemo(() => {
        const jurisdictionList = dropdownLists.find(({ name }) => name === 'OpinionJurisdiction');
        return jurisdictionList ? jurisdictionList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const bespokeOptions = useMemo(() => [{ label: 'Bespoke', value: '1' }, { label: 'Industry Standard', value: '0' }], []);

    const commissionedByOptions = useMemo(() => opinionCommissionedByOptions(), []);

    const filterDropdownOptions = useMemo(() => ({
        bespoke: bespokeOptions,
        commissionedBy: commissionedByOptions,
        jurisdiction: opinionJurisdictionOptions,
        focus: allFocusOptions,
        average: averageOptions
    }), [bespokeOptions, opinionJurisdictionOptions, commissionedByOptions]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'jurisdiction', header: 'Jurisdiction', field: 'jurisdiction', width: 0.25, component: 'tooltip', canSort: true, canFilter: true },
        { id: 'focus', header: 'Focus', field: 'focus', width: 0.15, component: 'tooltip', canSort: true, canFilter: true },
        { id: 'bespoke', header: 'Bespoke/Industry Standard', field: 'bespoke', width: 0.2, valueFormatter: bespokeFormatter, canSort: true, canFilter: true },
        { id: 'commissionedBy', header: 'Commissioned By', field: 'commissionedBy', width: 0.15, valueFormatter: commissionedByFormatter, component: 'tooltip', canSort: true, canFilter: true },
        { id: 'average', header: 'Score', field: 'average', width: 0.15, component: 'colour', valueFormatter: averageFormatter, canSort: true, canFilter: true },
        { id: 'opinionLink', header: 'Go', field: 'jurisdiction', width: 0.1, component: 'redirect', redirect }
    ];

    return (
        <div className={styles.tableWrapper}>
            <ArkTable
                colDefs={columnDefs}
                rowData={opinionsWithAverage}
                testId='opinions-average'
                page={1}
                total={opinionsWithAverage.length}
                next={noop}
                previous={noop}
                onRowClick={row => selectOpinion(row as OpinionAverage)}
                onRowDoubleClicked={row => showBreakdown(row as OpinionAverage)}
                toggleSort={toggleColumnSort}
                pageSize={opinionsWithAverage.length}
                updatePageSize={noop}
                showPageSize={false}
                width={tableWidth}
                height={tableHeight}
                selectedRow={selectedTableRow}
                clearAllFilters={clearAllFilters}
                filters={filters}
                updateFilter={updateFilter}
                removeTextFilter={['bespoke', 'focus', 'commissionedBy', 'average']}
                columnSort={columnSort}
                filterDropdownOptions={filterDropdownOptions}
            />
        </div>
    );
};
