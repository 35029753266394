import classnames from 'classnames';
import React from 'react';

import { Icon } from '../icon/Icon';
import styles from './Modal.module.scss';
import { Position, PositionModal } from './PositionModal';

interface ActionModalProps {
    isOpen: boolean;
    position: Position | null;
    actions: Action[];
    closeModal: () => void;
    width?: string;
    testId: string;
    height?: string;
}

export interface Action {
    icon: React.FC;
    label: string;
    onClick: () => void;
    withSeparator?: boolean;
    isDelete?: boolean;
    minWidth?: string;
}

export const ActionModal: React.FC<ActionModalProps> = ({
    isOpen,
    closeModal,
    position,
    actions,
    testId,
    width = 'fit-content',
    height = 'fit-content'
}) => (
    <PositionModal
        isOpen={isOpen}
        testId={testId}
        closeModal={closeModal}
        position={position}
        width={width}
        height={height}
        padding='0px'
    >
        <div className={styles.actionModal}>
            {actions.map(({ label, icon, onClick, withSeparator, isDelete, minWidth }, index) => (
                <button
                    key={label}
                    className={classnames(styles.actionsList, {
                        [styles.underlineAction]: withSeparator,
                        [styles.deleteAction]: isDelete
                    })}
                    onClick={() => { onClick(); closeModal(); }}
                    data-testid={`${testId}-button-${index}`}
                >
                    <div className={styles.actionLabel} style={{ minWidth }} data-testid={`${testId}-button-${index}-label`}>{label}</div>
                    <Icon icon={icon} fontSize={20} />
                </button>
            ))}
        </div>
    </PositionModal>
);
