import { Reducer } from 'redux';
import { set, flow, getOr } from 'lodash/fp';

import { Client, ClientActionTypes, ClientSection, ClientState } from './types';
import { LoginActionTypes } from '../../../auth/login/store';

const DEFAULT_FREE_PAGES = 1000;
const DEFAULT_MAX_PAGES = 20000;

export const initialClient: Client = {
    clientName: '',
    description: '',
    users: [],
    clientDatasetIds: [],
    clientFeaturePermissionIds: [],
    clientOpinions: [],
    demoClient: 0,
    freePages: DEFAULT_FREE_PAGES,
    maxPages: DEFAULT_MAX_PAGES,
    monthlyTotalPages: 0
};

export const INITIAL_STATE: ClientState = {
    modalOpen: false,
    clients: [],
    selectedClient: null,
    clientError: null,
    isSaving: false,
    confirmDeleteClient: null,
    deletingClient: false,
    availableClientFeaturePermissions: [],
    openClientSections: [ClientSection.USERS]
};

export const clientReducer: Reducer<ClientState> = (state = INITIAL_STATE, { payload, type }): ClientState => {
    switch (type) {
        case ClientActionTypes.TOGGLE_CLIENTS_MODAL: {
            const selectedClient = payload.isOpen ? getOr(initialClient, 'client', payload) : null;
            return flow(
                set('modalOpen', payload.isOpen),
                set('selectedClient', selectedClient)
            )(state);
        }
        case ClientActionTypes.FETCH_ALL_CLIENTS_SUCCESSFUL:
            return set('clients', payload, state);
        case ClientActionTypes.FETCH_ALL_CLIENTS_FAILED:
            return set('clientError', payload, state);
        case ClientActionTypes.UPDATE_CLIENT_DETAILS: {
            const { key, value } = payload;
            return set(`selectedClient.${key}`, value, state);
        }
        case ClientActionTypes.UPDATE_CLIENT_USERS: {
            const { users, clientId } = payload;
            const clients = state.clients.map(client => client.clientId === clientId ? { ...client, users } : client);
            return flow(
                set('clients', clients),
                set('selectedClient.users', users)
            )(state);
        }
        case ClientActionTypes.UPDATE_CLIENT_STARTED:
            return set('isSaving', true, state);
        case ClientActionTypes.UPDATE_CLIENT_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('clients', payload),
                set('selectedClient', null),
                set('modalOpen', false)
            )(state);
        case ClientActionTypes.UPDATE_CLIENT_FAILED:
            return flow(
                set('isSaving', false),
                set('clientError', payload)
            )(state);
        case ClientActionTypes.TOGGLE_DELETE_CLIENT_CONFIRMATION_MODAL:
            return set('confirmDeleteClient', payload, state);
        case ClientActionTypes.DELETE_CLIENT_STARTED:
            return set('deletingClient', true, state);
        case ClientActionTypes.DELETE_CLIENT_SUCCESSFUL:
            return flow(
                set('clients', payload),
                set('deletingClient', false),
                set('confirmDeleteClient', null)
            )(state);
        case ClientActionTypes.DELETE_CLIENT_FAILED:
            return flow(
                set('clientError', payload),
                set('deletingClient', false)
            )(state);
        case ClientActionTypes.FETCH_CLIENT_FEATURE_PERMISSIONS_SUCCESSFUL:
            return set('availableClientFeaturePermissions', payload, state);
        case ClientActionTypes.SET_OPEN_CLIENT_SECTIONS: {
            return set('openClientSections', payload, state);
        }
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
