import { isNull, isUndefined, noop } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { Icon } from '../../../shared/icon/Icon';
import { Delete, Tick } from '../../../shared/icons';
import { PopoutWysiwygModal } from '../../../shared/modal/PopoutWysiwygModal';
import { InformationTooltip } from '../../../shared/tooltip';
import { getDoraFunctionConfig } from '../../functions/store';
import styles from '../SupplyChain.module.scss';
import { getCurrentFunctionCompanyDetails, updateCompanyFunctionDetails } from '../store';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { FeaturePermission } from '../../../admin/users/store';
import { RadioListOption } from '../../../shared/radio/RadioList';
import { RadioRow } from '../../../shared/radio/RadioRow';

const { red, green } = styles;

export const CompanyFunctionDetails: React.FC = () => {
    const dispatch = useAppDispatch();
    const [screenWidth] = useWindowResize();

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const popoutWidth = useMemo(() => `${screenWidth * 0.5}px`, [screenWidth]);

    const details = useAppSelector(getCurrentFunctionCompanyDetails);
    const doraFunctionConfig = useAppSelector(getDoraFunctionConfig);

    const updateDetailValue = useCallback((value: boolean) => dispatch(updateCompanyFunctionDetails(value)), [dispatch]);

    const includesThirdPartyCompanies = useMemo(() => !isNull(details) && !!details.isThirdParty, [details]);
    const includesIntraGroupCompanies = useMemo(() => !isNull(details) && !!details.isIntraGroup, [details]);
    const getIcon = useCallback((doesInclude: boolean) => doesInclude ? Tick : Delete, []);
    const getColour = useCallback((doesInclude: boolean) => doesInclude ? green : red, []);

    const triggerElement = useMemo(() => <InformationTooltip content={'Click here view your company\'s definition of critical or important'} />, []);

    const criticalOrImportantOptions: RadioListOption[] = useMemo(() => [
        {
            id: 'yes',
            isSelected: details?.isCriticalOrImportant ?? false,
            label: 'Yes',
            disabled: !hasDoraFullAccessPermission
        },
        {
            id: 'no',
            isSelected: !isNull(details) && !isUndefined(details.isCriticalOrImportant) && !details.isCriticalOrImportant,
            label: 'No',
            disabled: !hasDoraFullAccessPermission
        }
    ], [details, hasDoraFullAccessPermission]);

    const toggleCriticalOrImportant = useCallback((id: string) => updateDetailValue(id === 'yes'), [updateDetailValue]);

    if (isNull(details)) {
        return null;
    }

    return (
        <div className={styles.supplyChainDetailsWrapper}>
            <div className={styles.detailsWrapper}>
                <div className={styles.criticalOrImportantWrapper}>
                    <div className={styles.criticalOrImportantQuestionWrapper}>
                        <div className={styles.criticalOrImportantQuestionLabel}>
                            Is this function deemed critical or important as defined by your company?
                        </div>
                        <PopoutWysiwygModal disabled hideToolbar updateContent={noop} content={doraFunctionConfig.details} width={popoutWidth} triggerElement={triggerElement} />
                    </div>
                    <div className={styles.criticalOrImportantRadioWrapper}>
                        <RadioRow options={criticalOrImportantOptions} onChange={toggleCriticalOrImportant} testId='dora-function-critical-or-important' />
                    </div>
                </div>
                <div className={styles.supplyChainInfoWrapper}>
                    <div className={styles.supplyChainInfo}>
                        <div className={styles.supplyChainInfoLabel}>Includes Intra Group companies</div>
                        <div className={styles.supplyChainInfoIcon}><Icon icon={getIcon(includesIntraGroupCompanies)} color={getColour(includesIntraGroupCompanies)} /></div>
                    </div>
                    <div className={styles.supplyChainInfo}>
                        <div className={styles.supplyChainInfoLabel}>Includes ICT Third-Party Service Providers</div>
                        <div className={styles.supplyChainInfoIcon}><Icon icon={getIcon(includesThirdPartyCompanies)} color={getColour(includesThirdPartyCompanies)} /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
