import React, { useCallback, useMemo, useState } from 'react';

import { ArkDocument, getDocumentAnalysisPageTotal, LinkedDocument, setDocumentAnalysisPageTotal } from '../my-documents/store';
import { PDFViewer, ReactPdf } from './PDFViewer';
import styles from './DocumentViewer.module.scss';
import { ArkOpinion } from '../../opinions/my-opinions/store';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';

interface DocumentViewerProps {
    url: string;
    selectedDocument: ArkDocument | ArkOpinion | LinkedDocument;
    width: number;
    selectedPage?: number | null;
    showScheduleStartPage?: boolean;
    setSelectedPage?: (page: number | null) => void;
    scheduleStartPage?: number;
}

export const DocumentViewer: React.FC<DocumentViewerProps> = ({ url, selectedDocument, width, selectedPage, showScheduleStartPage, setSelectedPage, scheduleStartPage }) => {
    const dispatch = useAppDispatch();
    const [localNumPages, setLocalNumPages] = useState<null | number>(null);

    const documentNumPages = useAppSelector(getDocumentAnalysisPageTotal);
    const setDocumentNumPages = useCallback((numberOfPages: number | null) => dispatch(setDocumentAnalysisPageTotal(numberOfPages)), [dispatch]);

    const setNumPages = useCallback((numPages: number | null) => {
        if (showScheduleStartPage) {
            setDocumentNumPages(numPages);
        }
        setLocalNumPages(numPages);
    }, [showScheduleStartPage, setDocumentNumPages]);

    const onLoadSuccess = useCallback(({ numPages }: ReactPdf) => setNumPages(numPages), [setNumPages]);

    const numPages = useMemo(() => showScheduleStartPage ? documentNumPages : localNumPages, [showScheduleStartPage, documentNumPages, localNumPages]);

    if (selectedDocument.mimeType === 'application/pdf') {
        return (
            <div className={styles.pdfViewerWrapper}>
                <PDFViewer
                    onLoadSuccess={onLoadSuccess}
                    resetNumPages={() => setNumPages(null)}
                    numPages={numPages}
                    pdfUrl={url}
                    width={width}
                    selectedPage={selectedPage}
                    showScheduleStartPage={showScheduleStartPage}
                    setSelectedPage={setSelectedPage}
                    scheduleStartPage={scheduleStartPage}
                />
            </div>
        );
    }

    return null;
};
