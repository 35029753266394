import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { NotificationState, Notification, SystemNotification, ActiveMLDocument } from './types';

const getRoot = (state: ApplicationState): NotificationState => state.auth.notification;

export const getNotifications = (state: ApplicationState): Notification[] => getRoot(state).notifications;

export const getNotificationsModalOpen = (state: ApplicationState): boolean => getRoot(state).modalOpen;

export const getSystemNotification = (state: ApplicationState): SystemNotification | null => getRoot(state).systemNotification;

export const getSystemNotificationModalOpen = (state: ApplicationState): boolean => !isNull(getSystemNotification(state));

export const getMLProgressModalOpen = (state: ApplicationState): boolean => getRoot(state).mlProgressModalOpen;

export const getActiveMLDocuments = (state: ApplicationState): ActiveMLDocument[] => getRoot(state).activeMLDocuments;

export const getShowMLInProgressIndicator = (state: ApplicationState): boolean => getActiveMLDocuments(state).length > 0;

export const getShowMLHourglassIndicator = (state: ApplicationState): boolean => getActiveMLDocuments(state).filter(({ progress }) => ![0, 100].includes(progress)).length > 0;

export const getShowPortfolioStatistics = (state: ApplicationState): boolean => getRoot(state).showPortfolioStatistics;

export const getPortfolioStatistics = (state: ApplicationState): string[] => getRoot(state).portfolioStatistics;

export const getShowPortfolioStatisticsBanner = (state: ApplicationState): boolean => getShowPortfolioStatistics(state) && getPortfolioStatistics(state).length > 0;
