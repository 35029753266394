import { DropdownList } from '../components/admin/dropdown-lists/store';
import { Clause, ClauseTags } from '../components/clause-library/store';
import { TableFilters } from '../components/shared/modal/TableFilterModal';
import { ColumnSort } from '../components/shared/table/ArkTable';
import { api } from './services';

interface SaveClauseRequest {
    clause: Clause;
    opinionId?: number;
}

interface DeleteClauseRequest {
    clauseId: number;
}

interface GetAllClausesRequest {
    isSystemTemplate: boolean;
    isCurrent: boolean;
    isMiscellaneous: boolean;
    pageNumber: number;
    filters: TableFilters;
    columnSort: ColumnSort | undefined;
    pageSize: number;
}

interface SaveClientTagRequest {
    clauseTagId: number;
    tag: string;
    category: keyof ClauseTags;
    includedClauses: number[];
    clausesAdded: number[];
    clausesRemoved: number [];
}

export interface SaveTemplateTagRequest {
    currentTag: string,
    updatedTag: string | undefined,
    currentDropdownList: DropdownList,
    updatedDropdownList: DropdownList | undefined,
    currentCategory: keyof ClauseTags,
    updatedCategory: keyof ClauseTags | undefined
}

interface DeleteTemplateTagRequest {
    tagToDelete: string,
    tagCategory: keyof ClauseTags,
    updatedDropdownList: DropdownList
}

export const saveClause = async (reqBody: SaveClauseRequest) => await api('clauseLibrary/save', { ...reqBody }, 'post');
export const deleteClause = async (reqBody: DeleteClauseRequest) => await api('clauseLibrary/delete', { ...reqBody }, 'post');
export const getAllClauses = async (reqBody: GetAllClausesRequest) => await api('clauseLibrary/getAll', { ...reqBody }, 'post');
export const getAllClientTags = async () => await api('clauseLibrary/clientTags/getAll', {}, 'get');
export const addNewClientTag = async (reqBody: { tag: string }) => await api('clauseLibrary/clientTags/addTag', { ...reqBody }, 'post');
export const deleteClientTag = async (reqBody: { clauseTagId: number, includedClauses: number[] }) => await api('clauseLibrary/clientTags/deleteTag', { ...reqBody }, 'post');
export const saveClientTag = async (reqBody: SaveClientTagRequest) => await api('clauseLibrary/clientTags/saveTag', { ...reqBody }, 'post');
export const addNewSystemTag = async (reqBody: { dropdownList: DropdownList, newTag: string, category: keyof ClauseTags }) => await api('clauseLibrary/systemTags/addTag', { ...reqBody }, 'post');
export const saveTemplateTag = async (reqBody: SaveTemplateTagRequest ) => await api('clauseLibrary/systemTags/saveTag', { ...reqBody }, 'post');
export const deleteTemplateTag = async (reqBody: DeleteTemplateTagRequest ) => await api('clauseLibrary/systemTags/deleteTag', { ...reqBody }, 'post');
