import { ColDef, GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getUserRole } from '../../../auth/login/store';
import { systemAdminRole } from '../../../constants/permittedRoles';
import { Icon } from '../../../shared/icon/Icon';
import { Delete, Document, Tick } from '../../../shared/icons';
import { Action } from '../../../shared/modal/ActionModal';
import { Table, stringFormatter } from '../../../shared/table';
import { ActionCell } from '../../../shared/table/ActionCell';
import { InformationTooltip } from '../../../shared/tooltip';
import { AttestationFormDB, stripAttestationForm, toggleAttestationFormBuilderWizardOpen, toggleDeleteAttestationFormConfirmationModal } from '../store';
import styles from './AttestationForms.module.scss';

const IconCell: React.FC<ICellRendererParams> = ({ value }) => (
    <div className={styles.tableIconWrapper}><Icon icon={value ? Tick : Delete} color={value ? 'green' : 'red'} /></div>
);

const InformationIconCell: React.FC<ICellRendererParams> = ({ value }) => (
    <div className={styles.tableIconWrapper}><InformationTooltip content={value} /></div>
);

interface AttestationFormsProps {
    attestationForms: AttestationFormDB[];
}

export const AttestationFormsTable: React.FC<AttestationFormsProps> = ({ attestationForms }) => {
    const dispatch = useAppDispatch();

    const userRole = useAppSelector(getUserRole);
    const isSystemAdmin = systemAdminRole.includes(userRole!);

    const openAttestationForm = useCallback((data: AttestationFormDB) => dispatch(toggleAttestationFormBuilderWizardOpen(true, stripAttestationForm(data))), [dispatch]);

    const onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => openAttestationForm(data);

    const openDeleteConfirmationModal = useCallback((data: AttestationFormDB) => dispatch(toggleDeleteAttestationFormConfirmationModal(data)), [dispatch]);

    const attestationFormOptions = useCallback((data: AttestationFormDB): Action[] => {
        let actions: Action[] = [
            { label: 'Open', icon: Document, onClick: () => openAttestationForm(data), withSeparator: isSystemAdmin || data.isSystem === 0 }
        ];
        if (isSystemAdmin || data.isSystem === 0) {
            actions.push({ label: 'Delete', icon: Delete, onClick: () => openDeleteConfirmationModal(data), isDelete: true });
        }
        return actions;
    }, [openAttestationForm, openDeleteConfirmationModal, isSystemAdmin]);

    const actionCellParams = {
        testId: 'attestation-forms-actions',
        actions: attestationFormOptions
    };

    const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };
    const columnDefs: ColDef[] = [
        { colId: 'name', headerName: 'Name', field: 'name', cellStyle: defaultCellStyle, valueFormatter: stringFormatter, width: 0.55 },
        { colId: 'description', headerName: 'Description', field: 'description', cellRenderer: 'informationIconCellRenderer', width: 0.2 },
        { colId: 'system', headerName: 'Is System Attestation Form?', field: 'isSystem', cellRenderer: 'iconCellRenderer', width: 0.2 },
        { colId: 'actions', headerName: '', field: '', cellRenderer: 'actionCellRenderer', width: 0.05, cellRendererParams: actionCellParams }
    ];

    const gridOptions: GridOptions = {
        components: {
            iconCellRenderer: IconCell,
            informationIconCellRenderer: InformationIconCell,
            actionCellRenderer: ActionCell
        },
        paginationPageSize: 20
    };

    return (
        <Table
            rowData={attestationForms}
            colDefs={columnDefs}
            gridOptions={gridOptions}
            percentageWidths
            onRowDoubleClicked={onRowDoubleClicked}
            testId='admin-attestation-forms'
        />
    );
};
