import React, { useMemo } from 'react';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Icon as ArkIcon } from '../../shared/icon/Icon';
import sharedStyles from './SharedBuilder.module.scss';
import { addDatasetField, addDatasetFieldToGroup, getFormDatasetFields, getSelectedGroup, getSelectedSection, openSectionModal } from './store';
import { DatasetField, DatasetFieldType, DatasetType, GroupDatasetField, SingleDatasetField } from '../../datasets/store';
import { Checkbox, Number, Date, Text, LongText, Link, Group, Section, Time, Dropdown, Dataset, Document, Form, Calculator, User, Currencies } from '../../shared/icons';
import { EntityType } from '../entity/store';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { defaultField } from './utils';

export const MAX_GROUP_WIDTH = 100;

// Section, Group and Dataset field types can never be added to a group, their width is wider than the max group width
export const fieldTypeWidths = {
    [DatasetFieldType.TEXT]: 30,
    [DatasetFieldType.LONGTEXT]: 50,
    [DatasetFieldType.NUMBER]: 25,
    [DatasetFieldType.CHECKBOX]: 10,
    [DatasetFieldType.DROPDOWN]: 33,
    [DatasetFieldType.TIME]: 45,
    [DatasetFieldType.DATE]: 25,
    [DatasetFieldType.DATASET]: 101,
    [DatasetFieldType.SECTION]: 101,
    [DatasetFieldType.GROUP]: 101,
    [DatasetFieldType.LINKFIELD]: 33,
    [DatasetFieldType.DOCUMENT]: 33,
    [DatasetFieldType.WYSIWYG]: 50,
    [DatasetFieldType.LABEL]: 50,
    [DatasetFieldType.CALCULATOR]: 50,
    [DatasetFieldType.MULTI_TOGGLE]: 101,
    [DatasetFieldType.PARTY]: 33,
    [DatasetFieldType.CURRENCY_AMOUNT]: 45
};

export const getRemainingGroupWidth = (fields: SingleDatasetField[]) => {
    const childFieldsWidth = fields.reduce((acc, cur) => acc + fieldTypeWidths[cur.type], 0);
    return MAX_GROUP_WIDTH - childFieldsWidth;
};

const fields = (type: DatasetType): DatasetField[] => ([
    {
        ...defaultField,
        type: DatasetFieldType.SECTION,
    },
    {
        ...defaultField,
        type: DatasetFieldType.TEXT,
    },
    {
        ...defaultField,
        type: DatasetFieldType.LONGTEXT,
    },
    {
        ...defaultField,
        type: DatasetFieldType.NUMBER,
        settings: { ...defaultField.settings, riskField: true }
    },
    {
        ...defaultField,
        type: DatasetFieldType.CHECKBOX,
        settings: { ...defaultField.settings, centreField: false, riskField: true }
    },
    {
        ...defaultField,
        settings: { ...defaultField.settings, isMultiSelect: false, dropdownLinked: null, riskField: true },
        type: DatasetFieldType.DROPDOWN,
    },
    {
        ...defaultField,
        type: DatasetFieldType.DATE,
        settings: { ...defaultField.settings, riskField: true }
    },
    {
        ...defaultField,
        type: DatasetFieldType.DATASET,
        settings: { ...defaultField.settings, datasetLinked: null }
    },
    {
        type: DatasetFieldType.GROUP,
        children: []
    },
    {
        type: DatasetFieldType.MULTI_TOGGLE,
        label: '',
        children: []
    },
    {
        ...defaultField,
        settings: { ...defaultField.settings, isMultiSelect: false, entityType: EntityType.COMPANY },
        type: DatasetFieldType.LINKFIELD
    },
    {
        ...defaultField,
        type: DatasetFieldType.PARTY,
        settings: defaultField.settings
    },
    {
        ...defaultField,
        type: DatasetFieldType.TIME,
        settings: { ...defaultField.settings, riskField: true }
    },
    {
        ...defaultField,
        type: DatasetFieldType.DOCUMENT,
        settings: { ...defaultField.settings, documentProperty: null }
    },
    {
        ...defaultField,
        type: DatasetFieldType.WYSIWYG,
        settings: { ...defaultField.settings, isCollapsible: type === DatasetType.TABLE }
    },
    {
        ...defaultField,
        label: 'Read Only Label Field',
        type: DatasetFieldType.LABEL,
        settings: { ...defaultField.settings, labelContent: null }
    },
    {
        ...defaultField,
        type: DatasetFieldType.CALCULATOR,
        settings: { ...defaultField.settings, calculatorFieldType: null }
    },
    {
        ...defaultField,
        type: DatasetFieldType.CURRENCY_AMOUNT,
        settings: { ...defaultField.settings, riskField: true }
    }
]);

const getFields = (type: DatasetType) => type === DatasetType.TABLE ? fields(type).filter(({ type }) => ![DatasetFieldType.SECTION, DatasetFieldType.GROUP, DatasetFieldType.LABEL, DatasetFieldType.MULTI_TOGGLE].includes(type)) : fields(type);

interface FieldListButtonProps {
    label: DatasetFieldType;
    onClick: () => void;
    disabled: boolean;
    isFirstItem: boolean;
    isLastItem: boolean;
}

export const fieldListIcons = {
    [DatasetFieldType.SECTION]: Section,
    [DatasetFieldType.TEXT]: Text,
    [DatasetFieldType.LONGTEXT]: LongText,
    [DatasetFieldType.NUMBER]: Number,
    [DatasetFieldType.CHECKBOX]: Checkbox,
    [DatasetFieldType.DROPDOWN]: Dropdown,
    [DatasetFieldType.DATE]: Date,
    [DatasetFieldType.DATASET]: Dataset,
    [DatasetFieldType.GROUP]: Group,
    [DatasetFieldType.LINKFIELD]: Link,
    [DatasetFieldType.TIME]: Time,
    [DatasetFieldType.DOCUMENT]: Document,
    [DatasetFieldType.WIZARD]: Form,
    [DatasetFieldType.WYSIWYG]: LongText,
    [DatasetFieldType.LABEL]: Text,
    [DatasetFieldType.CALCULATOR]: Calculator,
    [DatasetFieldType.MULTI_TOGGLE]: Checkbox,
    [DatasetFieldType.PARTY]: User,
    [DatasetFieldType.CURRENCY_AMOUNT]: Currencies
};

const FieldListItem: React.FC<FieldListButtonProps> = ({ label, onClick, disabled, isFirstItem, isLastItem }) => (
    <button
        onClick={onClick}
        className={classnames(sharedStyles.fieldListItem, {
            [sharedStyles.firstItem]: isFirstItem,
            [sharedStyles.lastItem]: isLastItem
        })}
        disabled={disabled}
        data-testid={`field-list-item-button-${label}`}
    >
        <div className={sharedStyles.fieldListLabel} data-testid={`field-list-item-${label}`}>{label}</div>
        <ArkIcon icon={fieldListIcons[label]} fontSize={40} />
    </button>
);

interface FieldListProps {
    datasetType: DatasetType;
}

export const FieldList: React.FC<FieldListProps> = ({ datasetType }) => {
    const dispatch = useAppDispatch();

    const selectedSection = useAppSelector(getSelectedSection);
    const selectedGroupId = useAppSelector(getSelectedGroup);
    const formFields = useAppSelector(getFormDatasetFields);

    const addFieldToDefinition = (field: DatasetField) => {
        if (field.type !== DatasetFieldType.SECTION) {
            selectedGroupId ? dispatch(addDatasetFieldToGroup(field, selectedGroupId)) : dispatch(addDatasetField(field));
        } else {
            dispatch(openSectionModal());
        }
    };

    const selectedGroupRemainingWidth: number | null = useMemo(() => {
        if (!selectedGroupId || !formFields) {
            return null;
        }
        const selectedGroupField = formFields.find(({ id }) => id === selectedGroupId) as GroupDatasetField;
        if (selectedGroupId && selectedGroupField.type === DatasetFieldType.MULTI_TOGGLE) {
            // This will enable only checkbox fields to be selected from remaining options
            return fieldTypeWidths[DatasetFieldType.CHECKBOX];
        }
        const childFields = (formFields.find(({ id }) => id === selectedGroupId) as GroupDatasetField).children;
        return getRemainingGroupWidth(childFields);
    }, [selectedGroupId, formFields]);

    const isFieldDisabled = (field: DatasetField) => {
        if (datasetType === DatasetType.FORM) {
            if (!selectedSection && field.type !== DatasetFieldType.SECTION) {
                return true;
            } else if (field.type === DatasetFieldType.GROUP && !!selectedGroupId) {
                return true;
            } else if (selectedGroupId && formFields && formFields.length) {
                if (field.type === DatasetFieldType.SECTION) {
                    return false;
                }
                return fieldTypeWidths[field.type] > selectedGroupRemainingWidth!;
            } else {
                return false;
            }
        }
        return false;
    };

    const availableFields = getFields(datasetType);
    const sortedFields = useMemo(() => availableFields.sort((a, b) => a.type.localeCompare(b.type)), [availableFields]);

    return (
        <div className={sharedStyles.fieldListWrapper} data-testid='field-list-items-wrapper'>
            <Scrollable>
                {sortedFields.map((field, index) => {
                    const disabled = isFieldDisabled(field);
                    const isFirstItem = index === 0;
                    const isLastItem = index === availableFields.length - 1;
                    const addField = () => addFieldToDefinition(field);
                    return (
                        <FieldListItem
                            key={field.type}
                            label={field.type}
                            onClick={addField}
                            disabled={disabled}
                            isFirstItem={isFirstItem}
                            isLastItem={isLastItem}
                        />
                    );
                })}
            </Scrollable>
        </div>
    );
};
