import { Country } from '../documents/analytics/store';

export interface WorldCountriesList {
    id: string;
    geoId: string;
    name: string;
}

export const worldCountriesList: WorldCountriesList[] = [
    { id: 'AF', name: 'Afghanistan', geoId: 'AFG' },
    { id: 'AL', name: 'Albania', geoId: 'ALB' },
    { id: 'DZ', name: 'Algeria', geoId: 'DZA' },
    { id: 'AS', name: 'American Samoa', geoId: 'ASM' },
    { id: 'AD', name: 'Andorra', geoId: 'AND' },
    { id: 'AO', name: 'Angola', geoId: 'AGO' },
    { id: 'AI', name: 'Anguilla', geoId: 'AIA' },
    { id: 'AG', name: 'Antigua and Barbuda', geoId: 'ATG' },
    { id: 'AR', name: 'Argentina', geoId: 'ARG' },
    { id: 'AM', name: 'Armenia', geoId: 'ARM' },
    { id: 'AW', name: 'Aruba', geoId: 'ABW' },
    { id: 'AU', name: 'Australia', geoId: 'AUS' },
    { id: 'AT', name: 'Austria', geoId: 'AUT' },
    { id: 'AZ', name: 'Azerbaijan', geoId: 'AZE' },
    { id: 'BS', name: 'Bahamas (the)', geoId: 'BHS' },
    { id: 'BH', name: 'Bahrain', geoId: 'BHR' },
    { id: 'BD', name: 'Bangladesh', geoId: 'BGD' },
    { id: 'BB', name: 'Barbados', geoId: 'BRB' },
    { id: 'BY', name: 'Belarus', geoId: 'BLR' },
    { id: 'BE', name: 'Belgium', geoId: 'BEL' },
    { id: 'BZ', name: 'Belize', geoId: 'BLZ' },
    { id: 'BJ', name: 'Benin', geoId: 'BEN' },
    { id: 'BM', name: 'Bermuda', geoId: 'BMU' },
    { id: 'BT', name: 'Bhutan', geoId: 'BTN' },
    { id: 'BO', name: 'Bolivia (Plurinational State of)', geoId: 'BOL' },
    { id: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', geoId: 'BES' },
    { id: 'BA', name: 'Bosnia and Herzegovina', geoId: 'BIH' },
    { id: 'BW', name: 'Botswana', geoId: 'BWA' },
    { id: 'BV', name: 'Bouvet Island', geoId: 'BVT' },
    { id: 'BR', name: 'Brazil', geoId: 'BRA' },
    { id: 'IO', name: 'British Indian Ocean Territory (the)', geoId: 'IOT' },
    { id: 'BN', name: 'Brunei Darussalam', geoId: 'BRN' },
    { id: 'BG', name: 'Bulgaria', geoId: 'BGR' },
    { id: 'BF', name: 'Burkina Faso', geoId: 'BFA' },
    { id: 'BI', name: 'Burundi', geoId: 'BDI' },
    { id: 'CV', name: 'Cabo Verde', geoId: 'CPV' },
    { id: 'KH', name: 'Cambodia', geoId: 'KHM' },
    { id: 'CM', name: 'Cameroon', geoId: 'CMR' },
    { id: 'CA', name: 'Canada', geoId: 'CAN' },
    { id: 'CA-ON', name: 'Canada (Ontario)', geoId: 'CAN' },
    { id: 'KY', name: 'Cayman Islands (the)', geoId: 'CYM' },
    { id: 'CF', name: 'Central African Republic (the)', geoId: 'CAF' },
    { id: 'TD', name: 'Chad', geoId: 'TCD' },
    { id: 'CL', name: 'Chile', geoId: 'CHL' },
    { id: 'CN', name: 'China', geoId: 'CHN' },
    { id: 'CX', name: 'Christmas Island', geoId: 'CXR' },
    { id: 'CC', name: 'Cocos (Keeling) Islands (the)', geoId: 'CCK' },
    { id: 'CO', name: 'Colombia', geoId: 'COL' },
    { id: 'KM', name: 'Comoros (the)', geoId: 'COM' },
    { id: 'CD', name: 'Congo (the Democratic Republic of the)', geoId: 'COD' },
    { id: 'CG', name: 'Congo (the)', geoId: 'COG' },
    { id: 'CK', name: 'Cook Islands (the)', geoId: 'COK' },
    { id: 'CR', name: 'Costa Rica', geoId: 'CRI' },
    { id: 'HR', name: 'Croatia', geoId: 'HRV' },
    { id: 'CU', name: 'Cuba', geoId: 'CUB' },
    { id: 'CW', name: 'Curaçao', geoId: 'CUW' },
    { id: 'CY', name: 'Cyprus', geoId: 'CYP' },
    { id: 'CZ', name: 'Czechia', geoId: 'CZE' },
    { id: 'CI', name: 'Cote d\'Ivoire', geoId: 'CIV' },
    { id: 'DK', name: 'Denmark', geoId: 'DNK' },
    { id: 'DJ', name: 'Djibouti', geoId: 'DJI' },
    { id: 'DM', name: 'Dominica', geoId: 'DMA' },
    { id: 'DO', name: 'Dominican Republic (the)', geoId: 'DOM' },
    { id: 'EC', name: 'Ecuador', geoId: 'ECU' },
    { id: 'EG', name: 'Egypt', geoId: 'EGY' },
    { id: 'SV', name: 'El Salvador', geoId: 'SLV' },
    { id: 'GQ', name: 'Equatorial Guinea', geoId: 'GNQ' },
    { id: 'ER', name: 'Eritrea', geoId: 'ERI' },
    { id: 'EE', name: 'Estonia', geoId: 'EST' },
    { id: 'SZ', name: 'Eswatini', geoId: 'SWZ' },
    { id: 'ET', name: 'Ethiopia', geoId: 'ETH' },
    { id: 'FK', name: 'Falkland Islands (the) [Malvinas]', geoId: 'FLK' },
    { id: 'FO', name: 'Faroe Islands (the)', geoId: 'FRO' },
    { id: 'FJ', name: 'Fiji', geoId: 'FJI' },
    { id: 'FI', name: 'Finland', geoId: 'FIN' },
    { id: 'FR', name: 'France', geoId: 'FRA' },
    { id: 'GF', name: 'French Guiana', geoId: 'GUF' },
    { id: 'PF', name: 'French Polynesia', geoId: 'PYF' },
    { id: 'TF', name: 'French Southern Territories (the)', geoId: 'ATF' },
    { id: 'GA', name: 'Gabon', geoId: 'GAB' },
    { id: 'GM', name: 'Gambia (the)', geoId: 'GMB' },
    { id: 'GE', name: 'Georgia', geoId: 'GEO' },
    { id: 'DE', name: 'Germany', geoId: 'DEU' },
    { id: 'GH', name: 'Ghana', geoId: 'GHA' },
    { id: 'GI', name: 'Gibraltar', geoId: 'GIB' },
    { id: 'GR', name: 'Greece', geoId: 'GRC' },
    { id: 'GL', name: 'Greenland', geoId: 'GRL' },
    { id: 'GD', name: 'Grenada', geoId: 'GRD' },
    { id: 'GP', name: 'Guadeloupe', geoId: 'GLP' },
    { id: 'GU', name: 'Guam', geoId: 'GUM' },
    { id: 'GT', name: 'Guatemala', geoId: 'GTM' },
    { id: 'GG', name: 'Guernsey', geoId: 'GGY' },
    { id: 'GN', name: 'Guinea', geoId: 'GIN' },
    { id: 'GW', name: 'Guinea-Bissau', geoId: 'GNB' },
    { id: 'GY', name: 'Guyana', geoId: 'GUY' },
    { id: 'HT', name: 'Haiti', geoId: 'HTI' },
    { id: 'HM', name: 'Heard Island and McDonald Islands', geoId: 'HMD' },
    { id: 'VA', name: 'Holy See (the)', geoId: 'VAT' },
    { id: 'HN', name: 'Honduras', geoId: 'HND' },
    { id: 'HK', name: 'Hong Kong', geoId: 'HKG' },
    { id: 'HU', name: 'Hungary', geoId: 'HUN' },
    { id: 'IS', name: 'Iceland', geoId: 'ISL' },
    { id: 'IN', name: 'India', geoId: 'IND' },
    { id: 'ID', name: 'Indonesia', geoId: 'IDN' },
    { id: 'IR', name: 'Iran (Islamic Republic of)', geoId: 'IRN' },
    { id: 'IQ', name: 'Iraq', geoId: 'IRQ' },
    { id: 'IE', name: 'Ireland', geoId: 'IRL' },
    { id: 'IM', name: 'Isle of Man', geoId: 'IMN' },
    { id: 'IL', name: 'Israel', geoId: 'ISR' },
    { id: 'IT', name: 'Italy', geoId: 'ITA' },
    { id: 'JM', name: 'Jamaica', geoId: 'JAM' },
    { id: 'JP', name: 'Japan', geoId: 'JPN' },
    { id: 'JE', name: 'Jersey', geoId: 'JEY' },
    { id: 'JO', name: 'Jordan', geoId: 'JOR' },
    { id: 'KZ', name: 'Kazakhstan', geoId: 'KAZ' },
    { id: 'KE', name: 'Kenya', geoId: 'KEN' },
    { id: 'KI', name: 'Kiribati', geoId: 'KIR' },
    { id: 'KP', name: 'Korea (the Democratic People\'s Republic of)', geoId: 'PRK' },
    { id: 'KR', name: 'Korea (the Republic of)', geoId: 'KOR' },
    { id: 'XK', name: 'Kosovo', geoId: 'XKK' },
    { id: 'KW', name: 'Kuwait', geoId: 'KWT' },
    { id: 'KG', name: 'Kyrgyzstan', geoId: 'KGZ' },
    { id: 'LA', name: 'Lao People\'s Democratic Republic (the)', geoId: 'LAO' },
    { id: 'LV', name: 'Latvia', geoId: 'LVA' },
    { id: 'LB', name: 'Lebanon', geoId: 'LBN' },
    { id: 'LS', name: 'Lesotho', geoId: 'LSO' },
    { id: 'LR', name: 'Liberia', geoId: 'LBR' },
    { id: 'LY', name: 'Libya', geoId: 'LBY' },
    { id: 'LI', name: 'Liechtenstein', geoId: 'LIE' },
    { id: 'LT', name: 'Lithuania', geoId: 'LTU' },
    { id: 'LU', name: 'Luxembourg', geoId: 'LUX' },
    { id: 'MO', name: 'Macao', geoId: 'MAC' },
    { id: 'MG', name: 'Madagascar', geoId: 'MDG' },
    { id: 'MW', name: 'Malawi', geoId: 'MWI' },
    { id: 'MY', name: 'Malaysia', geoId: 'MYS' },
    { id: 'MV', name: 'Maldives', geoId: 'MDV' },
    { id: 'ML', name: 'Mali', geoId: 'MLI' },
    { id: 'MT', name: 'Malta', geoId: 'MLT' },
    { id: 'MH', name: 'Marshall Islands (the)', geoId: 'MHL' },
    { id: 'MQ', name: 'Martinique', geoId: 'MTQ' },
    { id: 'MR', name: 'Mauritania', geoId: 'MRT' },
    { id: 'MU', name: 'Mauritius', geoId: 'MUS' },
    { id: 'YT', name: 'Mayotte', geoId: 'MYT' },
    { id: 'MX', name: 'Mexico', geoId: 'MEX' },
    { id: 'FM', name: 'Micronesia (Federated States of)', geoId: 'FSM' },
    { id: 'MD', name: 'Moldova (the Republic of)', geoId: 'MDA' },
    { id: 'MC', name: 'Monaco', geoId: 'MCO' },
    { id: 'MN', name: 'Mongolia', geoId: 'MNG' },
    { id: 'ME', name: 'Montenegro', geoId: 'MNE' },
    { id: 'MS', name: 'Montserrat', geoId: 'MSR' },
    { id: 'MA', name: 'Morocco', geoId: 'MAR' },
    { id: 'MZ', name: 'Mozambique', geoId: 'MOZ' },
    { id: 'MM', name: 'Myanmar', geoId: 'MMR' },
    { id: 'NA', name: 'Namibia', geoId: 'NAM' },
    { id: 'NR', name: 'Nauru', geoId: 'NRU' },
    { id: 'NP', name: 'Nepal', geoId: 'NPL' },
    { id: 'NL', name: 'Netherlands (the)', geoId: 'NLD' },
    { id: 'NC', name: 'New Caledonia', geoId: 'NCL' },
    { id: 'NZ', name: 'New Zealand', geoId: 'NZL' },
    { id: 'NI', name: 'Nicaragua', geoId: 'NIC' },
    { id: 'NE', name: 'Niger (the)', geoId: 'NER' },
    { id: 'NG', name: 'Nigeria', geoId: 'NGA' },
    { id: 'NU', name: 'Niue', geoId: 'NIU' },
    { id: 'NF', name: 'Norfolk Island', geoId: 'NFK' },
    { id: 'MP', name: 'Northern Mariana Islands (the)', geoId: 'MNP' },
    { id: 'NO', name: 'Norway', geoId: 'NOR' },
    { id: 'OM', name: 'Oman', geoId: 'OMN' },
    { id: 'PK', name: 'Pakistan', geoId: 'PAK' },
    { id: 'PW', name: 'Palau', geoId: 'PLW' },
    { id: 'PS', name: 'Palestine, State of', geoId: 'PSE' },
    { id: 'PA', name: 'Panama', geoId: 'PAN' },
    { id: 'PG', name: 'Papua New Guinea', geoId: 'PNG' },
    { id: 'PY', name: 'Paraguay', geoId: 'PRY' },
    { id: 'PE', name: 'Peru', geoId: 'PER' },
    { id: 'PH', name: 'Philippines (the)', geoId: 'PHL' },
    { id: 'PN', name: 'Pitcairn', geoId: 'PCN' },
    { id: 'PL', name: 'Poland', geoId: 'POL' },
    { id: 'PT', name: 'Portugal', geoId: 'PRT' },
    { id: 'PR', name: 'Puerto Rico', geoId: 'PRI' },
    { id: 'QA', name: 'Qatar', geoId: 'QAT' },
    { id: 'MK', name: 'Republic of North Macedonia', geoId: 'MKD' },
    { id: 'RO', name: 'Romania', geoId: 'ROU' },
    { id: 'RU', name: 'Russian Federation (the)', geoId: 'RUS' },
    { id: 'RW', name: 'Rwanda', geoId: 'RWA' },
    { id: 'RE', name: 'Réunion', geoId: 'REU' },
    { id: 'BL', name: 'Saint Barthélemy', geoId: 'BLM' },
    { id: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha', geoId: 'SHN' },
    { id: 'KN', name: 'Saint Kitts and Nevis', geoId: 'KNA' },
    { id: 'LC', name: 'Saint Lucia', geoId: 'LCA' },
    { id: 'MF', name: 'Saint Martin (French part)', geoId: 'MAF' },
    { id: 'PM', name: 'Saint Pierre and Miquelon', geoId: 'SPM' },
    { id: 'VC', name: 'Saint Vincent and the Grenadines', geoId: 'VCT' },
    { id: 'WS', name: 'Samoa', geoId: 'WSM' },
    { id: 'SM', name: 'San Marino', geoId: 'SMR' },
    { id: 'ST', name: 'Sao Tome and Principe', geoId: 'STP' },
    { id: 'SA', name: 'Saudi Arabia', geoId: 'SAU' },
    { id: 'SN', name: 'Senegal', geoId: 'SEN' },
    { id: 'RS', name: 'Serbia', geoId: 'SRB' },
    { id: 'SC', name: 'Seychelles', geoId: 'SYC' },
    { id: 'SL', name: 'Sierra Leone', geoId: 'SLE' },
    { id: 'SG', name: 'Singapore', geoId: 'SGP' },
    { id: 'SX', name: 'Sint Maarten (Dutch part)', geoId: 'SXM' },
    { id: 'SK', name: 'Slovakia', geoId: 'SVK' },
    { id: 'SI', name: 'Slovenia', geoId: 'SVN' },
    { id: 'SB', name: 'Solomon Islands', geoId: 'SLB' },
    { id: 'SO', name: 'Somalia', geoId: 'SOM' },
    { id: 'ZA', name: 'South Africa', geoId: 'ZAF' },
    { id: 'GS', name: 'South Georgia and the South Sandwich Islands', geoId: 'SGS' },
    { id: 'SS', name: 'South Sudan', geoId: 'SSD' },
    { id: 'ES', name: 'Spain', geoId: 'ESP' },
    { id: 'LK', name: 'Sri Lanka', geoId: 'LKA' },
    { id: 'SD', name: 'Sudan (the)', geoId: 'SDN' },
    { id: 'SR', name: 'Suriname', geoId: 'SUR' },
    { id: 'SJ', name: 'Svalbard and Jan Mayen', geoId: 'SJM' },
    { id: 'SE', name: 'Sweden', geoId: 'SWE' },
    { id: 'CH', name: 'Switzerland', geoId: 'CHE' },
    { id: 'SY', name: 'Syrian Arab Republic', geoId: 'SYR' },
    { id: 'TW', name: 'Taiwan', geoId: 'TWN' },
    { id: 'TJ', name: 'Tajikistan', geoId: 'TJK' },
    { id: 'TZ', name: 'Tanzania, United Republic of', geoId: 'TZA' },
    { id: 'TH', name: 'Thailand', geoId: 'THA' },
    { id: 'TL', name: 'Timor-Leste', geoId: 'TLS' },
    { id: 'TG', name: 'Togo', geoId: 'TGO' },
    { id: 'TK', name: 'Tokelau', geoId: 'TKL' },
    { id: 'TO', name: 'Tonga', geoId: 'TON' },
    { id: 'TT', name: 'Trinidad and Tobago', geoId: 'TTO' },
    { id: 'TN', name: 'Tunisia', geoId: 'TUN' },
    { id: 'TR', name: 'Turkey', geoId: 'TUR' },
    { id: 'TM', name: 'Turkmenistan', geoId: 'TKM' },
    { id: 'TC', name: 'Turks and Caicos Islands (the)', geoId: 'TCA' },
    { id: 'TV', name: 'Tuvalu', geoId: 'TUV' },
    { id: 'UG', name: 'Uganda', geoId: 'UGA' },
    { id: 'UA', name: 'Ukraine', geoId: 'UKR' },
    { id: 'AE', name: 'United Arab Emirates (the)', geoId: 'ARE' },
    { id: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland (the)', geoId: 'GBR' },
    { id: 'UM', name: 'United States Minor Outlying Islands (the)', geoId: 'UMI' },
    { id: 'US', name: 'United States of America (the)', geoId: 'USA' },
    { id: 'UY', name: 'Uruguay', geoId: 'URY' },
    { id: 'UZ', name: 'Uzbekistan', geoId: 'UZB' },
    { id: 'VU', name: 'Vanuatu', geoId: 'VUT' },
    { id: 'VE', name: 'Venezuela (Bolivarian Republic of)', geoId: 'VEN' },
    { id: 'VN', name: 'Viet Nam', geoId: 'VNM' },
    { id: 'VG', name: 'Virgin Islands (British)', geoId: 'VGB' },
    { id: 'VI', name: 'Virgin Islands (U.S.)', geoId: 'VIR' },
    { id: 'WF', name: 'Wallis and Futuna', geoId: 'WLF' },
    { id: 'EH', name: 'Western Sahara', geoId: 'ESH' },
    { id: 'YE', name: 'Yemen', geoId: 'YEM' },
    { id: 'ZM', name: 'Zambia', geoId: 'ZMB' },
    { id: 'ZW', name: 'Zimbabwe', geoId: 'ZWE' },
    { id: 'AX', name: 'Åland Islands', geoId: 'ALA' }
];

export const unitedKingdom: Country = {
    id: 'GBR',
    type: 'Feature',
    properties: { name: 'United Kingdom' },
    geometry: {
        type: 'MultiPolygon',
        coordinates: [
            [
                [
                    [
                        -6.287505662999905,
                        49.91400788000006
                    ],
                    [
                        -6.297271287999934,
                        49.909613348000065
                    ],
                    [
                        -6.30915279899989,
                        49.91364166900003
                    ],
                    [
                        -6.307443813999896,
                        49.927435614000146
                    ],
                    [
                        -6.298817511999886,
                        49.935492255000085
                    ],
                    [
                        -6.292225714999916,
                        49.93207428600006
                    ],
                    [
                        -6.28416907499988,
                        49.92275625200013
                    ],
                    [
                        -6.287505662999905,
                        49.91400788000006
                    ]
                ]
            ],
            [
                [
                    [
                        -6.326486782999893,
                        49.949204820000105
                    ],
                    [
                        -6.332508917999888,
                        49.941717841000056
                    ],
                    [
                        -6.341216600999871,
                        49.94476959800012
                    ],
                    [
                        -6.348052537999905,
                        49.956203518000095
                    ],
                    [
                        -6.348947719999899,
                        49.9643415390001
                    ],
                    [
                        -6.341786261999857,
                        49.96360911699999
                    ],
                    [
                        -6.336822068999908,
                        49.96092357
                    ],
                    [
                        -6.333648240999935,
                        49.95970286699999
                    ],
                    [
                        -6.328033006999931,
                        49.956610419000086
                    ],
                    [
                        -6.326486782999893,
                        49.949204820000105
                    ]
                ]
            ],
            [
                [
                    [
                        -1.295765753999916,
                        50.77317942900011
                    ],
                    [
                        -1.14427649599989,
                        50.73379140800013
                    ],
                    [
                        -1.119536912999905,
                        50.734198309000035
                    ],
                    [
                        -1.109120245999918,
                        50.73285553600006
                    ],
                    [
                        -1.097035285999908,
                        50.72695547100004
                    ],
                    [
                        -1.096424933999856,
                        50.72443268400009
                    ],
                    [
                        -1.097645636999857,
                        50.71600983300006
                    ],
                    [
                        -1.097035285999908,
                        50.71332428600009
                    ],
                    [
                        -1.09422766799986,
                        50.71263255400014
                    ],
                    [
                        -1.085560675999943,
                        50.714016018
                    ],
                    [
                        -1.082753058999913,
                        50.71332428600009
                    ],
                    [
                        -1.062326626999948,
                        50.69281647300012
                    ],
                    [
                        -1.062326626999948,
                        50.68528880400005
                    ],
                    [
                        -1.066965298999946,
                        50.68524811400006
                    ],
                    [
                        -1.069650844999927,
                        50.683498440000065
                    ],
                    [
                        -1.071888800999915,
                        50.68097565300015
                    ],
                    [
                        -1.075306769999941,
                        50.67853424700003
                    ],
                    [
                        -1.112700975999871,
                        50.67145416900014
                    ],
                    [
                        -1.12865149599989,
                        50.666449286000116
                    ],
                    [
                        -1.156361456999889,
                        50.65078359600015
                    ],
                    [
                        -1.162220831999917,
                        50.645982164000074
                    ],
                    [
                        -1.16470292899993,
                        50.64093659100003
                    ],
                    [
                        -1.164133266999983,
                        50.61937083500011
                    ],
                    [
                        -1.16502844999988,
                        50.60822174700009
                    ],
                    [
                        -1.168446417999888,
                        50.603461005
                    ],
                    [
                        -1.279204881999931,
                        50.58250560100005
                    ],
                    [
                        -1.315541144999941,
                        50.58917877800009
                    ],
                    [
                        -1.445952928999873,
                        50.64813873900006
                    ],
                    [
                        -1.452788865999878,
                        50.65428294500013
                    ],
                    [
                        -1.485951300999915,
                        50.66933828300016
                    ],
                    [
                        -1.497425910999937,
                        50.67230866100006
                    ],
                    [
                        -1.535145636999914,
                        50.66937897300015
                    ],
                    [
                        -1.551503058999941,
                        50.66510651200004
                    ],
                    [
                        -1.569488084999875,
                        50.65802643400015
                    ],
                    [
                        -1.545318162999877,
                        50.686102606000176
                    ],
                    [
                        -1.505930141999983,
                        50.707709052000084
                    ],
                    [
                        -1.418690558999884,
                        50.73379140800013
                    ],
                    [
                        -1.420887824999852,
                        50.730454820000105
                    ],
                    [
                        -1.423451300999972,
                        50.72370026200015
                    ],
                    [
                        -1.425363735999923,
                        50.7201195330001
                    ],
                    [
                        -1.400868292999888,
                        50.72199127800015
                    ],
                    [
                        -1.377552863999881,
                        50.734198309000035
                    ],
                    [
                        -1.343495245999861,
                        50.76105377800003
                    ],
                    [
                        -1.31851152299987,
                        50.772162177000055
                    ],
                    [
                        -1.295765753999916,
                        50.77317942900011
                    ]
                ]
            ],
            [
                [
                    [
                        -0.938832160999908,
                        50.78839752800009
                    ],
                    [
                        -0.938832160999908,
                        50.78095123900012
                    ],
                    [
                        -1.020741339999859,
                        50.78839752800009
                    ],
                    [
                        -1.020741339999859,
                        50.79515208500014
                    ],
                    [
                        -0.998687303999873,
                        50.80101146000008
                    ],
                    [
                        -0.972564256999902,
                        50.82977936400012
                    ],
                    [
                        -0.959868943999936,
                        50.829331773000135
                    ],
                    [
                        -0.950306769999969,
                        50.823797919000114
                    ],
                    [
                        -0.947824673999946,
                        50.81781647300009
                    ],
                    [
                        -0.95140540299991,
                        50.81244538000014
                    ],
                    [
                        -0.959868943999936,
                        50.80825429900001
                    ],
                    [
                        -0.959868943999936,
                        50.80206940300003
                    ],
                    [
                        -0.954579230999883,
                        50.79881419499999
                    ],
                    [
                        -0.94562740799995,
                        50.791815497000115
                    ],
                    [
                        -0.938832160999908,
                        50.78839752800009
                    ]
                ]
            ],
            [
                [
                    [
                        -4.663482225999928,
                        51.16486237200017
                    ],
                    [
                        -4.670480923999918,
                        51.160101630000085
                    ],
                    [
                        -4.678537563999953,
                        51.16705963700015
                    ],
                    [
                        -4.679798956999974,
                        51.18056875200013
                    ],
                    [
                        -4.676421678999958,
                        51.19310130400014
                    ],
                    [
                        -4.671213344999956,
                        51.195054429
                    ],
                    [
                        -4.666981574999852,
                        51.18768952
                    ],
                    [
                        -4.6654353509999,
                        51.18357982000005
                    ],
                    [
                        -4.663970506999931,
                        51.17706940300015
                    ],
                    [
                        -4.663482225999928,
                        51.16486237200017
                    ]
                ]
            ],
            [
                [
                    [
                        0.936371290000125,
                        51.37103913000006
                    ],
                    [
                        0.91146894600007,
                        51.364406643
                    ],
                    [
                        0.794769727000073,
                        51.370550848000065
                    ],
                    [
                        0.77507571700005,
                        51.37531159100017
                    ],
                    [
                        0.75757897200009,
                        51.386419989
                    ],
                    [
                        0.743500196000042,
                        51.400091864000146
                    ],
                    [
                        0.733897332000083,
                        51.41217682500006
                    ],
                    [
                        0.746836785000085,
                        51.43964264500009
                    ],
                    [
                        0.753916863000143,
                        51.445705471000096
                    ],
                    [
                        0.766937696000127,
                        51.447943427000055
                    ],
                    [
                        0.890798373000052,
                        51.421047268000095
                    ],
                    [
                        0.900563998000109,
                        51.417547919000086
                    ],
                    [
                        0.91146894600007,
                        51.41217682500006
                    ],
                    [
                        0.932465040000125,
                        51.39769114799999
                    ],
                    [
                        0.942149285000085,
                        51.38300202
                    ],
                    [
                        0.936371290000125,
                        51.37103913000006
                    ]
                ]
            ],
            [
                [
                    [
                        -4.655669725999871,
                        53.31976959800009
                    ],
                    [
                        -4.644195115999906,
                        53.31952545800014
                    ],
                    [
                        -4.626291469999956,
                        53.320868231000034
                    ],
                    [
                        -4.618885870999861,
                        53.317084052
                    ],
                    [
                        -4.60383053299995,
                        53.30817291900014
                    ],
                    [
                        -4.590443488999938,
                        53.29755280200011
                    ],
                    [
                        -4.580922003999945,
                        53.28546784100003
                    ],
                    [
                        -4.577259894999912,
                        53.272040106000034
                    ],
                    [
                        -4.57274329299986,
                        53.26650625200001
                    ],
                    [
                        -4.563954230999883,
                        53.26044342700011
                    ],
                    [
                        -4.558461066999939,
                        53.25275299700009
                    ],
                    [
                        -4.563628709999961,
                        53.241929429
                    ],
                    [
                        -4.571888800999943,
                        53.23948802300005
                    ],
                    [
                        -4.581898566999911,
                        53.24201080900018
                    ],
                    [
                        -4.602406378999888,
                        53.253078518
                    ],
                    [
                        -4.622792120999861,
                        53.27594635600012
                    ],
                    [
                        -4.63255774599989,
                        53.282945054000024
                    ],
                    [
                        -4.641346808999884,
                        53.284369208000086
                    ],
                    [
                        -4.67039954299986,
                        53.282945054000024
                    ],
                    [
                        -4.676665818999908,
                        53.28510163
                    ],
                    [
                        -4.693959113999881,
                        53.30280182500012
                    ],
                    [
                        -4.693959113999881,
                        53.30963776200012
                    ],
                    [
                        -4.667307094999956,
                        53.32391998900012
                    ],
                    [
                        -4.655669725999871,
                        53.31976959800009
                    ]
                ]
            ],
            [
                [
                    [
                        -4.203236456999946,
                        53.29848867400001
                    ],
                    [
                        -4.190581834999875,
                        53.296576239000146
                    ],
                    [
                        -4.177805141999926,
                        53.29755280200011
                    ],
                    [
                        -4.154896613999938,
                        53.30182526200004
                    ],
                    [
                        -4.142486131999903,
                        53.30280182500012
                    ],
                    [
                        -4.136626756999874,
                        53.30508047100007
                    ],
                    [
                        -4.127797003999916,
                        53.31484609600004
                    ],
                    [
                        -4.121978318999879,
                        53.317084052
                    ],
                    [
                        -4.115142381999931,
                        53.31590403899999
                    ],
                    [
                        -4.10252844999988,
                        53.310777085000055
                    ],
                    [
                        -4.098133917999888,
                        53.30963776200012
                    ],
                    [
                        -4.068918423999946,
                        53.31037018400015
                    ],
                    [
                        -4.055043097999942,
                        53.308539130000085
                    ],
                    [
                        -4.042876756999874,
                        53.30280182500012
                    ],
                    [
                        -4.063384568999908,
                        53.28929271000014
                    ],
                    [
                        -4.098052537999905,
                        53.2501488300001
                    ],
                    [
                        -4.121978318999879,
                        53.241929429
                    ],
                    [
                        -4.146717902999853,
                        53.239081122000144
                    ],
                    [
                        -4.171986456999889,
                        53.23139069200012
                    ],
                    [
                        -4.195546027999853,
                        53.220282294000114
                    ],
                    [
                        -4.214833136999886,
                        53.20722077000012
                    ],
                    [
                        -4.218495245999918,
                        53.20404694200006
                    ],
                    [
                        -4.220366990999906,
                        53.20148346600014
                    ],
                    [
                        -4.22101803299995,
                        53.19745514500009
                    ],
                    [
                        -4.22101803299995,
                        53.190130927000084
                    ],
                    [
                        -4.222035285999908,
                        53.182114976000136
                    ],
                    [
                        -4.225005662999905,
                        53.18121979400014
                    ],
                    [
                        -4.229359503999916,
                        53.18219635600012
                    ],
                    [
                        -4.264556443999879,
                        53.16693756700015
                    ],
                    [
                        -4.280140753999945,
                        53.15497467700011
                    ],
                    [
                        -4.343861456999917,
                        53.132066148000106
                    ],
                    [
                        -4.354359503999888,
                        53.129950262000094
                    ],
                    [
                        -4.36668860599994,
                        53.13141510600015
                    ],
                    [
                        -4.392404751999891,
                        53.13833242400007
                    ],
                    [
                        -4.392689581999917,
                        53.13605377800009
                    ],
                    [
                        -4.398671027999939,
                        53.13275788000006
                    ],
                    [
                        -4.406605597999885,
                        53.13060130400005
                    ],
                    [
                        -4.412831183999856,
                        53.132066148000106
                    ],
                    [
                        -4.41429602799991,
                        53.137844143000095
                    ],
                    [
                        -4.413685675999943,
                        53.14712148600013
                    ],
                    [
                        -4.411976691999939,
                        53.155585028000175
                    ],
                    [
                        -4.409738735999952,
                        53.15940989799999
                    ],
                    [
                        -4.40192623599998,
                        53.1637230490001
                    ],
                    [
                        -4.390370245999861,
                        53.173081773000135
                    ],
                    [
                        -4.38512122299997,
                        53.182440497000144
                    ],
                    [
                        -4.395741339999916,
                        53.186712958000086
                    ],
                    [
                        -4.410878058999913,
                        53.18219635600012
                    ],
                    [
                        -4.432850714999916,
                        53.16290924700017
                    ],
                    [
                        -4.447580532999922,
                        53.15940989799999
                    ],
                    [
                        -4.448557094999899,
                        53.16254303600006
                    ],
                    [
                        -4.481678839999916,
                        53.17987702000015
                    ],
                    [
                        -4.484486456999974,
                        53.18060944200009
                    ],
                    [
                        -4.492502407999922,
                        53.17934804900007
                    ],
                    [
                        -4.495350714999859,
                        53.17987702000015
                    ],
                    [
                        -4.496001756999903,
                        53.18256256700012
                    ],
                    [
                        -4.494699673999918,
                        53.190863348
                    ],
                    [
                        -4.495350714999859,
                        53.19354889500009
                    ],
                    [
                        -4.495106574999909,
                        53.196478583000115
                    ],
                    [
                        -4.496327277999939,
                        53.20380280200011
                    ],
                    [
                        -4.499623175999972,
                        53.211249091000084
                    ],
                    [
                        -4.519886847999885,
                        53.22284577
                    ],
                    [
                        -4.553089972999885,
                        53.261948960000055
                    ],
                    [
                        -4.571034308999856,
                        53.27545807500003
                    ],
                    [
                        -4.571034308999856,
                        53.282945054000024
                    ],
                    [
                        -4.565256313999981,
                        53.293036200000145
                    ],
                    [
                        -4.563221808999856,
                        53.308254299000126
                    ],
                    [
                        -4.563628709999961,
                        53.33006419500002
                    ],
                    [
                        -4.558664516999897,
                        53.36334870000009
                    ],
                    [
                        -4.560861782999893,
                        53.37783437700013
                    ],
                    [
                        -4.571034308999856,
                        53.39276764500009
                    ],
                    [
                        -4.553456183999913,
                        53.40058014500009
                    ],
                    [
                        -4.425363735999952,
                        53.42670319200012
                    ],
                    [
                        -4.412831183999856,
                        53.426947333000086
                    ],
                    [
                        -4.34349524599989,
                        53.42007070500007
                    ],
                    [
                        -4.32469641799986,
                        53.419501044000086
                    ],
                    [
                        -4.286529100999871,
                        53.40643952000012
                    ],
                    [
                        -4.276722785999937,
                        53.401027736000074
                    ],
                    [
                        -4.271880662999877,
                        53.389064846000124
                    ],
                    [
                        -4.269357876999948,
                        53.377101955000015
                    ],
                    [
                        -4.266021287999934,
                        53.371649481000176
                    ],
                    [
                        -4.266916469999927,
                        53.36896393400009
                    ],
                    [
                        -4.254505988999881,
                        53.36322663000011
                    ],
                    [
                        -4.240061001999919,
                        53.35834381700015
                    ],
                    [
                        -4.234730597999885,
                        53.35805898600002
                    ],
                    [
                        -4.233713344999899,
                        53.352972723
                    ],
                    [
                        -4.23485266799986,
                        53.34613678600009
                    ],
                    [
                        -4.235178188999953,
                        53.34007396000008
                    ],
                    [
                        -4.231556769999912,
                        53.33759186400006
                    ],
                    [
                        -4.230620897999927,
                        53.33527252800009
                    ],
                    [
                        -4.214833136999886,
                        53.32391998900012
                    ],
                    [
                        -4.209339972999942,
                        53.31240469000009
                    ],
                    [
                        -4.207427537999877,
                        53.30390045800006
                    ],
                    [
                        -4.203236456999946,
                        53.29848867400001
                    ]
                ]
            ],
            [
                [
                    [
                        -6.440012173999918,
                        55.23602936400003
                    ],
                    [
                        -6.415923631999903,
                        55.231878973000065
                    ],
                    [
                        -6.378163214999916,
                        55.24624258000013
                    ],
                    [
                        -6.35289466099988,
                        55.24262116100009
                    ],
                    [
                        -6.255848761999943,
                        55.21088288000011
                    ],
                    [
                        -6.239816860999952,
                        55.20831940300003
                    ],
                    [
                        -6.217640753999888,
                        55.21039459800015
                    ],
                    [
                        -6.179554816999882,
                        55.219712632
                    ],
                    [
                        -6.160023566999911,
                        55.22190989799999
                    ],
                    [
                        -6.12083899599989,
                        55.21784088700012
                    ],
                    [
                        -6.100982225999871,
                        55.21238841399999
                    ],
                    [
                        -6.092396613999966,
                        55.20490143400012
                    ],
                    [
                        -6.084217902999853,
                        55.20221588700004
                    ],
                    [
                        -6.047230597999914,
                        55.178534247000115
                    ],
                    [
                        -6.037098761999857,
                        55.167914130000085
                    ],
                    [
                        -6.040638800999943,
                        55.112046617000104
                    ],
                    [
                        -6.047434048999946,
                        55.095648505
                    ],
                    [
                        -6.06208248599998,
                        55.07412344000009
                    ],
                    [
                        -6.054351365999878,
                        55.065130927000055
                    ],
                    [
                        -6.035389777999853,
                        55.063625393
                    ],
                    [
                        -6.016672329999892,
                        55.06427643400015
                    ],
                    [
                        -6.003529425999915,
                        55.06317780200011
                    ],
                    [
                        -5.988677537999877,
                        55.05947500200007
                    ],
                    [
                        -5.975738084999875,
                        55.05304596600014
                    ],
                    [
                        -5.968251105999883,
                        55.0438500020001
                    ],
                    [
                        -5.970204230999855,
                        55.02582428600006
                    ],
                    [
                        -5.988270636999857,
                        54.98867422100015
                    ],
                    [
                        -5.986236131999931,
                        54.98175690300015
                    ],
                    [
                        -5.957142706999946,
                        54.978949286
                    ],
                    [
                        -5.930327928999873,
                        54.97089264500006
                    ],
                    [
                        -5.906605597999942,
                        54.95795319200006
                    ],
                    [
                        -5.886341925999943,
                        54.94082265800013
                    ],
                    [
                        -5.881988084999961,
                        54.934027411
                    ],
                    [
                        -5.87523352799991,
                        54.91791413000011
                    ],
                    [
                        -5.869252081999889,
                        54.91038646000008
                    ],
                    [
                        -5.862782355999912,
                        54.905951239
                    ],
                    [
                        -5.847035285999937,
                        54.898138739
                    ],
                    [
                        -5.839182094999956,
                        54.893011786
                    ],
                    [
                        -5.829986131999903,
                        54.88515859600001
                    ],
                    [
                        -5.820952928999873,
                        54.875067450000145
                    ],
                    [
                        -5.813954230999912,
                        54.86277903899999
                    ],
                    [
                        -5.81118730399993,
                        54.84833405200014
                    ],
                    [
                        -5.800526495999918,
                        54.820746161000116
                    ],
                    [
                        -5.775257941999939,
                        54.81150950700005
                    ],
                    [
                        -5.745594855999912,
                        54.80459219000015
                    ],
                    [
                        -5.721831834999904,
                        54.783758856000176
                    ],
                    [
                        -5.715646938999953,
                        54.783758856000176
                    ],
                    [
                        -5.733754035999965,
                        54.80524323100009
                    ],
                    [
                        -5.797596808999884,
                        54.844549872000115
                    ],
                    [
                        -5.78172766799986,
                        54.861721096000124
                    ],
                    [
                        -5.751616990999935,
                        54.85687897300015
                    ],
                    [
                        -5.720611131999903,
                        54.841009833000115
                    ],
                    [
                        -5.701975063999925,
                        54.82469310100011
                    ],
                    [
                        -5.689849412999905,
                        54.793117580000015
                    ],
                    [
                        -5.692616339999887,
                        54.76520416900017
                    ],
                    [
                        -5.706898566999882,
                        54.742987372000115
                    ],
                    [
                        -5.729318813999896,
                        54.72850169500008
                    ],
                    [
                        -5.758900519999941,
                        54.71991608300006
                    ],
                    [
                        -5.818430141999954,
                        54.70962148600013
                    ],
                    [
                        -5.845366990999935,
                        54.701239325000174
                    ],
                    [
                        -5.871490037999877,
                        54.68732330900009
                    ],
                    [
                        -5.890736456999917,
                        54.67230866100006
                    ],
                    [
                        -5.914336717999959,
                        54.64683665600016
                    ],
                    [
                        -5.927235480999883,
                        54.63296133000007
                    ],
                    [
                        -5.915150519999884,
                        54.62824127800015
                    ],
                    [
                        -5.910959438999953,
                        54.61945221600011
                    ],
                    [
                        -5.909982876999891,
                        54.61054108300006
                    ],
                    [
                        -5.907378709999875,
                        54.605617580000015
                    ],
                    [
                        -5.894886847999885,
                        54.60480377800009
                    ],
                    [
                        -5.887277798999918,
                        54.609808661000116
                    ],
                    [
                        -5.881988084999961,
                        54.61615631700006
                    ],
                    [
                        -5.871001756999903,
                        54.62230052300005
                    ],
                    [
                        -5.848784959999932,
                        54.6432152360001
                    ],
                    [
                        -5.839019334999904,
                        54.64972565300009
                    ],
                    [
                        -5.803822394999941,
                        54.659613348
                    ],
                    [
                        -5.769520636999886,
                        54.67462799700003
                    ],
                    [
                        -5.747954881999874,
                        54.68040599200002
                    ],
                    [
                        -5.732411261999886,
                        54.67698802299999
                    ],
                    [
                        -5.716623501999919,
                        54.66986725500014
                    ],
                    [
                        -5.694691535999908,
                        54.668687242000104
                    ],
                    [
                        -5.57469641799986,
                        54.68008047100007
                    ],
                    [
                        -5.568999803999901,
                        54.676947333
                    ],
                    [
                        -5.556711391999926,
                        54.662787177000084
                    ],
                    [
                        -5.547108527999853,
                        54.659613348
                    ],
                    [
                        -5.536610480999883,
                        54.657456773000106
                    ],
                    [
                        -5.533273891999954,
                        54.65167877800006
                    ],
                    [
                        -5.532785610999952,
                        54.6432152360001
                    ],
                    [
                        -5.530100063999896,
                        54.63296133000007
                    ],
                    [
                        -5.514393683999913,
                        54.59788646
                    ],
                    [
                        -5.502552863999881,
                        54.58348216400013
                    ],
                    [
                        -5.48570716099988,
                        54.57770416900014
                    ],
                    [
                        -5.478911912999934,
                        54.569484768000066
                    ],
                    [
                        -5.472889777999939,
                        54.53009674700017
                    ],
                    [
                        -5.468617316999911,
                        54.516262111000074
                    ],
                    [
                        -5.437082485999923,
                        54.48786041900014
                    ],
                    [
                        -5.430775519999884,
                        54.48517487200017
                    ],
                    [
                        -5.437570766999897,
                        54.47565338700018
                    ],
                    [
                        -5.436512824999852,
                        54.470200914000046
                    ],
                    [
                        -5.433461066999939,
                        54.46625397300015
                    ],
                    [
                        -5.433827277999882,
                        54.461004950000174
                    ],
                    [
                        -5.438832160999908,
                        54.45490143400018
                    ],
                    [
                        -5.458119269999941,
                        54.43740469000012
                    ],
                    [
                        -5.464263475999928,
                        54.42523834800015
                    ],
                    [
                        -5.457386847999914,
                        54.40867747599999
                    ],
                    [
                        -5.461171027999853,
                        54.39207591400013
                    ],
                    [
                        -5.470855272999899,
                        54.384344794000114
                    ],
                    [
                        -5.483998175999943,
                        54.377590236000074
                    ],
                    [
                        -5.489125128999888,
                        54.369574286000116
                    ],
                    [
                        -5.474842902999882,
                        54.358587958000086
                    ],
                    [
                        -5.489572719999956,
                        54.353705145
                    ],
                    [
                        -5.502023891999897,
                        54.33966705900015
                    ],
                    [
                        -5.515777147999899,
                        54.33808014500012
                    ],
                    [
                        -5.52765865799995,
                        54.34528229400014
                    ],
                    [
                        -5.536976691999911,
                        54.35927969
                    ],
                    [
                        -5.547108527999853,
                        54.37909577000012
                    ],
                    [
                        -5.558990037999905,
                        54.38263580900009
                    ],
                    [
                        -5.569488084999875,
                        54.391506252000156
                    ],
                    [
                        -5.576649542999917,
                        54.402777411000145
                    ],
                    [
                        -5.578439907999893,
                        54.413804429
                    ],
                    [
                        -5.571685350999871,
                        54.42560455900015
                    ],
                    [
                        -5.548695441999882,
                        54.44281647300009
                    ],
                    [
                        -5.543690558999856,
                        54.457831122000115
                    ],
                    [
                        -5.547352667999888,
                        54.48908112200017
                    ],
                    [
                        -5.558013475999928,
                        54.51190827000009
                    ],
                    [
                        -5.575306769999912,
                        54.52899811400012
                    ],
                    [
                        -5.644886847999914,
                        54.56777578300016
                    ],
                    [
                        -5.671864386999886,
                        54.576808986000074
                    ],
                    [
                        -5.701975063999925,
                        54.57770416900014
                    ],
                    [
                        -5.701975063999925,
                        54.57078685100008
                    ],
                    [
                        -5.694650844999899,
                        54.56110260600009
                    ],
                    [
                        -5.69741777299987,
                        54.551214911
                    ],
                    [
                        -5.70604407499988,
                        54.54266998900006
                    ],
                    [
                        -5.715646938999953,
                        54.53668854400014
                    ],
                    [
                        -5.701527472999914,
                        54.53632233300014
                    ],
                    [
                        -5.69041907499988,
                        54.53204987200003
                    ],
                    [
                        -5.681507941999939,
                        54.52684153900013
                    ],
                    [
                        -5.674061652999882,
                        54.52366771000008
                    ],
                    [
                        -5.636952277999853,
                        54.52204010600006
                    ],
                    [
                        -5.625599738999881,
                        54.516262111000074
                    ],
                    [
                        -5.641916469999899,
                        54.51264069200012
                    ],
                    [
                        -5.663929816999911,
                        54.51019928600009
                    ],
                    [
                        -5.670318162999934,
                        54.505682684000035
                    ],
                    [
                        -5.639963344999956,
                        54.495754299000126
                    ],
                    [
                        -5.644764777999853,
                        54.48371002800012
                    ],
                    [
                        -5.644602016999897,
                        54.472113348
                    ],
                    [
                        -5.638498501999891,
                        54.463568427000084
                    ],
                    [
                        -5.625599738999881,
                        54.461004950000174
                    ],
                    [
                        -5.626698370999918,
                        54.45669179900007
                    ],
                    [
                        -5.628000454999921,
                        54.45380280200014
                    ],
                    [
                        -5.63312740799995,
                        54.44733307500003
                    ],
                    [
                        -5.625599738999881,
                        54.44110748900009
                    ],
                    [
                        -5.653553839999916,
                        54.388983466000056
                    ],
                    [
                        -5.661447719999899,
                        54.37958405200011
                    ],
                    [
                        -5.697906053999873,
                        54.36229075700011
                    ],
                    [
                        -5.708851691999911,
                        54.35175202000015
                    ],
                    [
                        -5.668324347999914,
                        54.36066315300009
                    ],
                    [
                        -5.622141079999892,
                        54.376654364
                    ],
                    [
                        -5.579701300999915,
                        54.383693752000156
                    ],
                    [
                        -5.550526495999861,
                        54.36542389500009
                    ],
                    [
                        -5.540679490999935,
                        54.327337958
                    ],
                    [
                        -5.553049282999893,
                        54.29852936400006
                    ],
                    [
                        -5.579497850999871,
                        54.27570221600014
                    ],
                    [
                        -5.612619594999899,
                        54.25556061400012
                    ],
                    [
                        -5.617176886999857,
                        54.2547875020001
                    ],
                    [
                        -5.628529425999915,
                        54.256537177
                    ],
                    [
                        -5.63312740799995,
                        54.25556061400012
                    ],
                    [
                        -5.639963344999956,
                        54.235052802000055
                    ],
                    [
                        -5.646839972999885,
                        54.23216380400014
                    ],
                    [
                        -5.655751105999912,
                        54.229681708
                    ],
                    [
                        -5.663400844999927,
                        54.230861721000124
                    ],
                    [
                        -5.666656053999901,
                        54.238836981000176
                    ],
                    [
                        -5.678822394999884,
                        54.24616120000009
                    ],
                    [
                        -5.736887173999918,
                        54.24909088700012
                    ],
                    [
                        -5.779449022999899,
                        54.246527411
                    ],
                    [
                        -5.822661912999877,
                        54.237209377000156
                    ],
                    [
                        -5.84227454299986,
                        54.235052802000055
                    ],
                    [
                        -5.862049933999884,
                        54.23053620000012
                    ],
                    [
                        -5.868031378999888,
                        54.218491929
                    ],
                    [
                        -5.866037563999953,
                        54.184149481000034
                    ],
                    [
                        -5.865834113999881,
                        54.18048737200009
                    ],
                    [
                        -5.86851966099988,
                        54.161322333000115
                    ],
                    [
                        -5.874623175999943,
                        54.14052969000012
                    ],
                    [
                        -5.883208787999877,
                        54.12075429900001
                    ],
                    [
                        -5.893788214999916,
                        54.104681708000115
                    ],
                    [
                        -5.911976691999911,
                        54.09129466400013
                    ],
                    [
                        -5.953846808999913,
                        54.07868073100015
                    ],
                    [
                        -5.984730597999885,
                        54.05931224200013
                    ],
                    [
                        -6.027251756999931,
                        54.04022858300014
                    ],
                    [
                        -6.040516730999883,
                        54.03644440300003
                    ],
                    [
                        -6.079579230999912,
                        54.03457265800016
                    ],
                    [
                        -6.095448370999861,
                        54.03636302300005
                    ],
                    [
                        -6.112904425999915,
                        54.043890692
                    ],
                    [
                        -6.112904425999915,
                        54.05011627800015
                    ],
                    [
                        -6.093006964999916,
                        54.055894273000106
                    ],
                    [
                        -6.08495032499988,
                        54.05695221600017
                    ],
                    [
                        -6.161529100999871,
                        54.0737165390001
                    ],
                    [
                        -6.174305792999917,
                        54.074286200000145
                    ],
                    [
                        -6.185861782999922,
                        54.09267812700001
                    ],
                    [
                        -6.212554490999935,
                        54.09845612200017
                    ],
                    [
                        -6.242502407999922,
                        54.09975820500007
                    ],
                    [
                        -6.263661261999914,
                        54.104681708000115
                    ],
                    [
                        -6.269886847999885,
                        54.09792715100009
                    ],
                    [
                        -6.284650430999932,
                        54.105225728
                    ],
                    [
                        -6.299584920999877,
                        54.10403717100009
                    ],
                    [
                        -6.313795938999931,
                        54.09969635000003
                    ],
                    [
                        -6.327128458999965,
                        54.09788767500011
                    ],
                    [
                        -6.338962361999961,
                        54.10295196599999
                    ],
                    [
                        -6.346662149999872,
                        54.10987660700009
                    ],
                    [
                        -6.354827025999896,
                        54.110651754000074
                    ],
                    [
                        -6.368107869999903,
                        54.09731923400007
                    ],
                    [
                        -6.369348103999926,
                        54.09111806200018
                    ],
                    [
                        -6.367642781999876,
                        54.083418274000095
                    ],
                    [
                        -6.366919310999975,
                        54.075098369000116
                    ],
                    [
                        -6.371156778999932,
                        54.06677846300006
                    ],
                    [
                        -6.377512980999939,
                        54.06326446600012
                    ],
                    [
                        -6.401025756999928,
                        54.06088735000016
                    ],
                    [
                        -6.426760619999925,
                        54.05540964800018
                    ],
                    [
                        -6.433891967999955,
                        54.05530629500005
                    ],
                    [
                        -6.440299844999913,
                        54.05799346900007
                    ],
                    [
                        -6.446242635999937,
                        54.06243764300008
                    ],
                    [
                        -6.450221719999917,
                        54.06667511100012
                    ],
                    [
                        -6.450893513999887,
                        54.06843210900011
                    ],
                    [
                        -6.478540405999894,
                        54.06770863900012
                    ],
                    [
                        -6.564013224999911,
                        54.04895009400012
                    ],
                    [
                        -6.571868041999949,
                        54.04951853400017
                    ],
                    [
                        -6.587164265999917,
                        54.053342590000014
                    ],
                    [
                        -6.59507076,
                        54.05241241500006
                    ],
                    [
                        -6.600290079999894,
                        54.04895009400012
                    ],
                    [
                        -6.605217158999977,
                        54.04447525800005
                    ],
                    [
                        -6.610987100999893,
                        54.03923492400018
                    ],
                    [
                        -6.61646480399989,
                        54.03727122100004
                    ],
                    [
                        -6.630624144999928,
                        54.04181874600009
                    ],
                    [
                        -6.657289184999883,
                        54.06114573200013
                    ],
                    [
                        -6.672533732999909,
                        54.06843210900011
                    ],
                    [
                        -6.657082478999911,
                        54.09194488600009
                    ],
                    [
                        -6.655790567999873,
                        54.1033137000001
                    ],
                    [
                        -6.666435913999862,
                        54.114785869000016
                    ],
                    [
                        -6.643956664999848,
                        54.13183909100006
                    ],
                    [
                        -6.634551554999888,
                        54.150132548000116
                    ],
                    [
                        -6.640339314999949,
                        54.168012594000075
                    ],
                    [
                        -6.648448442999893,
                        54.17366497000013
                    ],
                    [
                        -6.663025268999917,
                        54.18382558200007
                    ],
                    [
                        -6.683954223999905,
                        54.19436757500013
                    ],
                    [
                        -6.694651244999932,
                        54.197984925000114
                    ],
                    [
                        -6.706536824999944,
                        54.19891510100017
                    ],
                    [
                        -6.717233845999942,
                        54.19514272100004
                    ],
                    [
                        -6.724778605999916,
                        54.18863149100015
                    ],
                    [
                        -6.732840128999897,
                        54.1835672000001
                    ],
                    [
                        -6.74477738499985,
                        54.184187317000166
                    ],
                    [
                        -6.766481486999851,
                        54.19235219400018
                    ],
                    [
                        -6.78782385299985,
                        54.20299753900018
                    ],
                    [
                        -6.807357543999927,
                        54.216330058
                    ],
                    [
                        -6.823945678999849,
                        54.23234975200013
                    ],
                    [
                        -6.829733438999909,
                        54.24237498000018
                    ],
                    [
                        -6.833195759999882,
                        54.252090149000125
                    ],
                    [
                        -6.837743285999892,
                        54.26051340800011
                    ],
                    [
                        -6.846683308999928,
                        54.266456197000124
                    ],
                    [
                        -6.882185017999888,
                        54.277256572000155
                    ],
                    [
                        -6.864666707999902,
                        54.28273427300006
                    ],
                    [
                        -6.856656860999948,
                        54.29281117800018
                    ],
                    [
                        -6.85841385899991,
                        54.30733225600012
                    ],
                    [
                        -6.870014701999907,
                        54.326000603000026
                    ],
                    [
                        -6.879704548999911,
                        54.34159373000013
                    ],
                    [
                        -6.885957397999903,
                        54.34562449200003
                    ],
                    [
                        -6.897894652999952,
                        54.3461929330001
                    ],
                    [
                        -6.905956176999922,
                        54.34903513700006
                    ],
                    [
                        -6.915051228999914,
                        54.36593332900004
                    ],
                    [
                        -6.922027547999903,
                        54.37270294200009
                    ],
                    [
                        -6.984090942999956,
                        54.40308868500013
                    ],
                    [
                        -7.01783565299985,
                        54.41316558800018
                    ],
                    [
                        -7.049254923999882,
                        54.41151194300012
                    ],
                    [
                        -7.078503783999906,
                        54.39471710300016
                    ],
                    [
                        -7.127027953999914,
                        54.34975860600015
                    ],
                    [
                        -7.15989416499994,
                        54.335185852000095
                    ],
                    [
                        -7.168059040999935,
                        54.33503082400016
                    ],
                    [
                        -7.185629028999927,
                        54.336942851000074
                    ],
                    [
                        -7.189469552999896,
                        54.33569177100013
                    ],
                    [
                        -7.192450316999981,
                        54.33472076400007
                    ],
                    [
                        -7.193948933999877,
                        54.32996653200017
                    ],
                    [
                        -7.1914684649999,
                        54.323868713000124
                    ],
                    [
                        -7.187644408999887,
                        54.31880442300006
                    ],
                    [
                        -7.185008910999954,
                        54.31725413100013
                    ],
                    [
                        -7.184647175999942,
                        54.316634013000154
                    ],
                    [
                        -7.192398640999954,
                        54.30738393200012
                    ],
                    [
                        -7.193690551999907,
                        54.30753896099999
                    ],
                    [
                        -7.199168253999915,
                        54.30345652300015
                    ],
                    [
                        -7.206661336999929,
                        54.304903463000144
                    ],
                    [
                        -7.211467244999938,
                        54.30417999300006
                    ],
                    [
                        -7.209038452999891,
                        54.29343129600015
                    ],
                    [
                        -7.175500446999877,
                        54.283664449000085
                    ],
                    [
                        -7.181494913999899,
                        54.26976348900003
                    ],
                    [
                        -7.145889851999925,
                        54.252090149000125
                    ],
                    [
                        -7.159739135999899,
                        54.24066965700011
                    ],
                    [
                        -7.153331257999952,
                        54.22423655300004
                    ],
                    [
                        -7.174725300999853,
                        54.21607167600003
                    ],
                    [
                        -7.229502319999938,
                        54.207545064000115
                    ],
                    [
                        -7.24087113499985,
                        54.202325745000095
                    ],
                    [
                        -7.249087686999872,
                        54.19741648400016
                    ],
                    [
                        -7.255495564999904,
                        54.190853577000084
                    ],
                    [
                        -7.261128295999924,
                        54.18088002500015
                    ],
                    [
                        -7.256322387999916,
                        54.176900940000095
                    ],
                    [
                        -7.247020629999895,
                        54.17225006200012
                    ],
                    [
                        -7.245780395999873,
                        54.16697906500009
                    ],
                    [
                        -7.265365762999892,
                        54.161139629
                    ],
                    [
                        -7.260869913999954,
                        54.151166077000184
                    ],
                    [
                        -7.263505411999887,
                        54.140985820000125
                    ],
                    [
                        -7.270740112999931,
                        54.13225250200007
                    ],
                    [
                        -7.280041870999867,
                        54.126154683
                    ],
                    [
                        -7.293787800999922,
                        54.122020569000156
                    ],
                    [
                        -7.297353474999881,
                        54.12589630200013
                    ],
                    [
                        -7.296319946999887,
                        54.13499135400009
                    ],
                    [
                        -7.2964233,
                        54.14651519800013
                    ],
                    [
                        -7.295028035999849,
                        54.15540354500014
                    ],
                    [
                        -7.292134155999861,
                        54.16263824500011
                    ],
                    [
                        -7.295544799999988,
                        54.16511871400009
                    ],
                    [
                        -7.325982218999855,
                        54.154576722000016
                    ],
                    [
                        -7.333165242999911,
                        54.14940907900002
                    ],
                    [
                        -7.333165242999911,
                        54.142742819000105
                    ],
                    [
                        -7.310324259999902,
                        54.11468251600017
                    ],
                    [
                        -7.316489273999906,
                        54.11428045000012
                    ],
                    [
                        -7.326964069999917,
                        54.11359731000009
                    ],
                    [
                        -7.375488240999914,
                        54.12331248000011
                    ],
                    [
                        -7.390216023999898,
                        54.121193746000145
                    ],
                    [
                        -7.394660197999912,
                        54.121917217000046
                    ],
                    [
                        -7.396623900999941,
                        54.126258036000124
                    ],
                    [
                        -7.403961954999943,
                        54.13504303000012
                    ],
                    [
                        -7.412230183999895,
                        54.13643829300018
                    ],
                    [
                        -7.422255411999913,
                        54.135456442000034
                    ],
                    [
                        -7.425769408999884,
                        54.13695505800003
                    ],
                    [
                        -7.414658976999959,
                        54.14568837600011
                    ],
                    [
                        -7.439618692999943,
                        54.146928610000046
                    ],
                    [
                        -7.480753132999922,
                        54.12765330000009
                    ],
                    [
                        -7.502302204999864,
                        54.125121155000116
                    ],
                    [
                        -7.609014037999941,
                        54.139900615000116
                    ],
                    [
                        -7.620796264999854,
                        54.144964905
                    ],
                    [
                        -7.624051879999854,
                        54.15333648700009
                    ],
                    [
                        -7.625705525999905,
                        54.162173157
                    ],
                    [
                        -7.632681843999905,
                        54.168529358000015
                    ],
                    [
                        -7.704770466999889,
                        54.20036204100016
                    ],
                    [
                        -7.722598836999907,
                        54.202325745000095
                    ],
                    [
                        -7.782078409999883,
                        54.20000030500016
                    ],
                    [
                        -7.836958780999908,
                        54.204341126000045
                    ],
                    [
                        -7.856440796999891,
                        54.211420797000144
                    ],
                    [
                        -7.869669961999875,
                        54.22687205000007
                    ],
                    [
                        -7.873649047999948,
                        54.27105540000015
                    ],
                    [
                        -7.880263631999867,
                        54.287023417000015
                    ],
                    [
                        -7.894965576999937,
                        54.293586324
                    ],
                    [
                        -7.93411047299989,
                        54.29704864600005
                    ],
                    [
                        -7.942074756999915,
                        54.29887328100001
                    ],
                    [
                        -7.950801961999929,
                        54.30087270200006
                    ],
                    [
                        -7.968010213999889,
                        54.312189840000045
                    ],
                    [
                        -7.981032673999948,
                        54.32655588900015
                    ],
                    [
                        -8.002194172999879,
                        54.35792348300008
                    ],
                    [
                        -8.031339680999906,
                        54.3580268350001
                    ],
                    [
                        -8.056480265999937,
                        54.365881654000034
                    ],
                    [
                        -8.079941364999911,
                        54.38019602500013
                    ],
                    [
                        -8.12241939299986,
                        54.41523264600015
                    ],
                    [
                        -8.146345580999963,
                        54.430735576000146
                    ],
                    [
                        -8.156034911999882,
                        54.439055481000125
                    ],
                    [
                        -8.15812780799996,
                        54.44711700500001
                    ],
                    [
                        -8.161176717999894,
                        54.45481679300009
                    ],
                    [
                        -8.173837442999911,
                        54.46174143500018
                    ],
                    [
                        -8.168466710999894,
                        54.46347695500005
                    ],
                    [
                        -8.150169636999891,
                        54.46938954700006
                    ],
                    [
                        -8.09704626499996,
                        54.478587952000154
                    ],
                    [
                        -8.072448282999886,
                        54.48706288700005
                    ],
                    [
                        -8.06097611499996,
                        54.49331573500014
                    ],
                    [
                        -8.05586014799988,
                        54.49755320300001
                    ],
                    [
                        -8.043561156999914,
                        54.51222930900009
                    ],
                    [
                        -8.023278157999925,
                        54.52969594400004
                    ],
                    [
                        -8.002194172999879,
                        54.543441875000084
                    ],
                    [
                        -7.926410685999912,
                        54.53305491200014
                    ],
                    [
                        -7.887136596999937,
                        54.532124736000114
                    ],
                    [
                        -7.848844360999919,
                        54.54090972900009
                    ],
                    [
                        -7.749263875999873,
                        54.596151836
                    ],
                    [
                        -7.707871052999906,
                        54.60416168300016
                    ],
                    [
                        -7.707944064999907,
                        54.60470786900005
                    ],
                    [
                        -7.707951474999902,
                        54.60476330300013
                    ],
                    [
                        -7.710558227999854,
                        54.624263815000134
                    ],
                    [
                        -7.721203572999855,
                        54.625865784000084
                    ],
                    [
                        -7.736758178999878,
                        54.61925120000008
                    ],
                    [
                        -7.753553019999941,
                        54.614496969000086
                    ],
                    [
                        -7.769159301999878,
                        54.61801096600014
                    ],
                    [
                        -7.801198689999893,
                        54.63480580700009
                    ],
                    [
                        -7.814996296999937,
                        54.639456686000145
                    ],
                    [
                        -7.822541055999892,
                        54.63811309800009
                    ],
                    [
                        -7.838043985999889,
                        54.631240133000134
                    ],
                    [
                        -7.846415567999969,
                        54.631446838
                    ],
                    [
                        -7.854270385999911,
                        54.63640777600013
                    ],
                    [
                        -7.864347290999945,
                        54.64906850199999
                    ],
                    [
                        -7.87277054899991,
                        54.65222076500014
                    ],
                    [
                        -7.890082153999913,
                        54.65506296800011
                    ],
                    [
                        -7.906618611999932,
                        54.66131581600011
                    ],
                    [
                        -7.914835164999943,
                        54.671651103000116
                    ],
                    [
                        -7.907135375999872,
                        54.686688945000085
                    ],
                    [
                        -7.913233194999918,
                        54.68865264900013
                    ],
                    [
                        -7.929666300999884,
                        54.69671417300013
                    ],
                    [
                        -7.880108601999922,
                        54.711028544000115
                    ],
                    [
                        -7.845898803999916,
                        54.73102732300005
                    ],
                    [
                        -7.832152872999899,
                        54.73061391200004
                    ],
                    [
                        -7.803575805999913,
                        54.716144511000024
                    ],
                    [
                        -7.770502888999943,
                        54.70601593100007
                    ],
                    [
                        -7.736603149999922,
                        54.70746287100006
                    ],
                    [
                        -7.702289997999912,
                        54.71888336200014
                    ],
                    [
                        -7.667511759999854,
                        54.73877878900005
                    ],
                    [
                        -7.649683390999911,
                        54.7448766080001
                    ],
                    [
                        -7.615370238999986,
                        54.7393472290001
                    ],
                    [
                        -7.585397907999948,
                        54.74472157800007
                    ],
                    [
                        -7.566639363999855,
                        54.73867543600012
                    ],
                    [
                        -7.556149047999895,
                        54.73836537800004
                    ],
                    [
                        -7.543074910999849,
                        54.74167266900004
                    ],
                    [
                        -7.543023233999918,
                        54.74379140300012
                    ],
                    [
                        -7.548397583999901,
                        54.747202047000044
                    ],
                    [
                        -7.551704874999899,
                        54.75469513000017
                    ],
                    [
                        -7.549741170999937,
                        54.779603170000044
                    ],
                    [
                        -7.543384968999931,
                        54.79309071900012
                    ],
                    [
                        -7.470831258999908,
                        54.845283916000184
                    ],
                    [
                        -7.455069945999924,
                        54.86300893200011
                    ],
                    [
                        -7.444734659999909,
                        54.88445465100013
                    ],
                    [
                        -7.444734659999909,
                        54.89489329100017
                    ],
                    [
                        -7.448972126999877,
                        54.920318095000155
                    ],
                    [
                        -7.445251423999878,
                        54.932151998000094
                    ],
                    [
                        -7.436983194999925,
                        54.93830149400016
                    ],
                    [
                        -7.417139444999918,
                        54.94305572600014
                    ],
                    [
                        -7.415755225999874,
                        54.944372422000114
                    ],
                    [
                        -7.408664510999927,
                        54.95111724800013
                    ],
                    [
                        -7.407424275999887,
                        54.959437155
                    ],
                    [
                        -7.413108682999848,
                        54.98496531200014
                    ],
                    [
                        -7.409077921999938,
                        54.992044983000156
                    ],
                    [
                        -7.403755248999886,
                        54.993130189000155
                    ],
                    [
                        -7.401119750999953,
                        54.99483551100003
                    ],
                    [
                        -7.405253865999896,
                        55.003568827
                    ],
                    [
                        -7.376986856999906,
                        55.028890279000066
                    ],
                    [
                        -7.366961629999906,
                        55.03555654000009
                    ],
                    [
                        -7.355024372999935,
                        55.04093088800015
                    ],
                    [
                        -7.291152302999905,
                        55.04661529600018
                    ],
                    [
                        -7.282987426999881,
                        55.05183461600002
                    ],
                    [
                        -7.275287638999885,
                        55.05886261000002
                    ],
                    [
                        -7.266502644999889,
                        55.06516713500004
                    ],
                    [
                        -7.247097142933143,
                        55.0693283139659
                    ],
                    [
                        -7.247100389999929,
                        55.069322007
                    ],
                    [
                        -7.256743943999908,
                        55.05068594
                    ],
                    [
                        -7.240956183999856,
                        55.0502790390001
                    ],
                    [
                        -7.195423956999889,
                        55.058050848
                    ],
                    [
                        -7.177479620999861,
                        55.05695221600014
                    ],
                    [
                        -7.133900519999941,
                        55.0438500020001
                    ],
                    [
                        -7.099476691999911,
                        55.04096100500006
                    ],
                    [
                        -7.065052863999881,
                        55.0438500020001
                    ],
                    [
                        -7.05370032499988,
                        55.04873281500015
                    ],
                    [
                        -7.03189042899993,
                        55.06492747600011
                    ],
                    [
                        -7.017201300999943,
                        55.07111237200017
                    ],
                    [
                        -7.01781165299991,
                        55.083970445000105
                    ],
                    [
                        -7.009917772999927,
                        55.102240302000084
                    ],
                    [
                        -6.997954881999903,
                        55.11863841399999
                    ],
                    [
                        -6.986480272999955,
                        55.12571849200005
                    ],
                    [
                        -6.974924282999922,
                        55.135443427
                    ],
                    [
                        -6.968658006999874,
                        55.18065013200011
                    ],
                    [
                        -6.962554490999878,
                        55.19464752800009
                    ],
                    [
                        -6.946685350999871,
                        55.19537995000012
                    ],
                    [
                        -6.903553839999859,
                        55.17865631700009
                    ],
                    [
                        -6.883778449999909,
                        55.17413971600003
                    ],
                    [
                        -6.754505988999938,
                        55.17780182500009
                    ],
                    [
                        -6.640451626999919,
                        55.20831940300003
                    ],
                    [
                        -6.571278449999852,
                        55.21124909100014
                    ],
                    [
                        -6.554514126999919,
                        55.21857330900015
                    ],
                    [
                        -6.539173956999946,
                        55.23065827000015
                    ],
                    [
                        -6.519846157999922,
                        55.240668036000145
                    ],
                    [
                        -6.498117641999954,
                        55.24713776200004
                    ],
                    [
                        -6.475941535999908,
                        55.248683986000074
                    ],
                    [
                        -6.440012173999918,
                        55.23602936400003
                    ]
                ]
            ],
            [
                [
                    [
                        -6.174305792999917,
                        55.303900458
                    ],
                    [
                        -6.171864386999886,
                        55.29319896000008
                    ],
                    [
                        -6.178089972999914,
                        55.28058502800009
                    ],
                    [
                        -6.187570766999926,
                        55.26935455900009
                    ],
                    [
                        -6.194813605999855,
                        55.26292552299999
                    ],
                    [
                        -6.193267381999903,
                        55.27098216400013
                    ],
                    [
                        -6.192860480999912,
                        55.27578359600001
                    ],
                    [
                        -6.19176184799997,
                        55.279364325000174
                    ],
                    [
                        -6.18797766799986,
                        55.284002997000115
                    ],
                    [
                        -6.211089647999899,
                        55.295314846000096
                    ],
                    [
                        -6.235829230999883,
                        55.29669830900015
                    ],
                    [
                        -6.260609503999916,
                        55.29507070500013
                    ],
                    [
                        -6.283558722999914,
                        55.29706452000009
                    ],
                    [
                        -6.272531704999977,
                        55.30304596600011
                    ],
                    [
                        -6.261463995999918,
                        55.30752187700007
                    ],
                    [
                        -6.249623175999886,
                        55.31028880400011
                    ],
                    [
                        -6.236398891999926,
                        55.31134674700017
                    ],
                    [
                        -6.184803839999887,
                        55.30683014500012
                    ],
                    [
                        -6.174305792999917,
                        55.303900458
                    ]
                ]
            ],
            [
                [
                    [
                        -5.11778723899991,
                        55.43976471600014
                    ],
                    [
                        -5.163563605999912,
                        55.43488190300009
                    ],
                    [
                        -5.217355923999946,
                        55.43797435100008
                    ],
                    [
                        -5.263091600999871,
                        55.44721100500014
                    ],
                    [
                        -5.304676886999857,
                        55.46214427299999
                    ],
                    [
                        -5.345692511999857,
                        55.48265208500014
                    ],
                    [
                        -5.345692511999857,
                        55.48944733300006
                    ],
                    [
                        -5.34618079299986,
                        55.49603913000014
                    ],
                    [
                        -5.355336066999939,
                        55.52289459800012
                    ],
                    [
                        -5.359364386999914,
                        55.530462958000086
                    ],
                    [
                        -5.359364386999914,
                        55.53729889500009
                    ],
                    [
                        -5.354237433999884,
                        55.539780992000104
                    ],
                    [
                        -5.352284308999856,
                        55.54230377800003
                    ],
                    [
                        -5.350412563999953,
                        55.54547760600009
                    ],
                    [
                        -5.345692511999857,
                        55.55027903899999
                    ],
                    [
                        -5.370432094999927,
                        55.57025788000006
                    ],
                    [
                        -5.389068162999934,
                        55.590236721000124
                    ],
                    [
                        -5.3978572259999,
                        55.614203192
                    ],
                    [
                        -5.39289303299995,
                        55.64655182500012
                    ],
                    [
                        -5.37938391799986,
                        55.668117580000015
                    ],
                    [
                        -5.355213995999861,
                        55.68960195500013
                    ],
                    [
                        -5.324330206999917,
                        55.705023505000085
                    ],
                    [
                        -5.290516730999855,
                        55.70856354400014
                    ],
                    [
                        -5.292591925999943,
                        55.71198151200015
                    ],
                    [
                        -5.295155402999853,
                        55.71942780200014
                    ],
                    [
                        -5.29735266799986,
                        55.72284577000015
                    ],
                    [
                        -5.271351691999882,
                        55.723822333000115
                    ],
                    [
                        -5.263172980999855,
                        55.72284577000015
                    ],
                    [
                        -5.198312954999977,
                        55.704331773000135
                    ],
                    [
                        -5.180653449999852,
                        55.69489166900003
                    ],
                    [
                        -5.159982876999948,
                        55.676214911000145
                    ],
                    [
                        -5.145985480999883,
                        55.654974677000055
                    ],
                    [
                        -5.125965949999909,
                        55.60553620000009
                    ],
                    [
                        -5.137440558999856,
                        55.59202708500011
                    ],
                    [
                        -5.09422766799986,
                        55.566555080000015
                    ],
                    [
                        -5.091867641999897,
                        55.54413483300003
                    ],
                    [
                        -5.101673956999946,
                        55.540716864
                    ],
                    [
                        -5.116037563999925,
                        55.54043203300016
                    ],
                    [
                        -5.125233527999882,
                        55.53693268400018
                    ],
                    [
                        -5.119740363999938,
                        55.52362702000015
                    ],
                    [
                        -5.111561652999853,
                        55.51992422100001
                    ],
                    [
                        -5.070790167999888,
                        55.50934479400014
                    ],
                    [
                        -5.093006964999859,
                        55.500433661000116
                    ],
                    [
                        -5.091053839999887,
                        55.48729075700005
                    ],
                    [
                        -5.080799933999884,
                        55.47166575700008
                    ],
                    [
                        -5.078236456999946,
                        55.455308335000055
                    ],
                    [
                        -5.092030402999882,
                        55.44611237200009
                    ],
                    [
                        -5.11778723899991,
                        55.43976471600014
                    ]
                ]
            ],
            [
                [
                    [
                        -5.009388800999886,
                        55.729315497000144
                    ],
                    [
                        -5.016184048999918,
                        55.72284577000015
                    ],
                    [
                        -5.028797980999912,
                        55.72333405200014
                    ],
                    [
                        -5.038482225999871,
                        55.731878973000065
                    ],
                    [
                        -5.05028235599994,
                        55.74949778899999
                    ],
                    [
                        -5.115589972999914,
                        55.784369208000115
                    ],
                    [
                        -5.125965949999909,
                        55.801011460000055
                    ],
                    [
                        -5.137562628999916,
                        55.82904694200006
                    ],
                    [
                        -5.139637824999852,
                        55.84198639500009
                    ],
                    [
                        -5.174224412999934,
                        55.85537344000009
                    ],
                    [
                        -5.180653449999852,
                        55.862779039000074
                    ],
                    [
                        -5.188588019999912,
                        55.878119208000115
                    ],
                    [
                        -5.200795050999886,
                        55.895493882
                    ],
                    [
                        -5.199533657999893,
                        55.91107819200009
                    ],
                    [
                        -5.166900193999936,
                        55.92084381700012
                    ],
                    [
                        -5.135975714999887,
                        55.90595123900009
                    ],
                    [
                        -5.105539516999926,
                        55.88670482000013
                    ],
                    [
                        -5.096506313999925,
                        55.883530992000075
                    ],
                    [
                        -5.08763587099989,
                        55.88263580900015
                    ],
                    [
                        -5.080922003999945,
                        55.879584052000084
                    ],
                    [
                        -5.07518469999988,
                        55.85691966400013
                    ],
                    [
                        -5.068023240999935,
                        55.852118231000034
                    ],
                    [
                        -5.058827277999853,
                        55.850165106000176
                    ],
                    [
                        -5.05028235599994,
                        55.84577057500009
                    ],
                    [
                        -5.038075324999852,
                        55.832424221000096
                    ],
                    [
                        -5.028920050999886,
                        55.81928131700012
                    ],
                    [
                        -5.021839972999914,
                        55.805609442
                    ],
                    [
                        -5.011463995999918,
                        55.774481512000094
                    ],
                    [
                        -5.012766079999921,
                        55.768377997000115
                    ],
                    [
                        -5.019195115999935,
                        55.7631696640001
                    ],
                    [
                        -5.029774542999888,
                        55.74949778899999
                    ],
                    [
                        -5.019032355999855,
                        55.74530670800006
                    ],
                    [
                        -5.011341925999943,
                        55.737697658000016
                    ],
                    [
                        -5.009388800999886,
                        55.729315497000144
                    ]
                ]
            ],
            [
                [
                    [
                        -6.102365688999953,
                        55.83124420800006
                    ],
                    [
                        -6.099232550999886,
                        55.801011460000055
                    ],
                    [
                        -6.093088344999899,
                        55.79169342700011
                    ],
                    [
                        -6.079090949999852,
                        55.78148021000008
                    ],
                    [
                        -6.063954230999855,
                        55.77338288000006
                    ],
                    [
                        -6.054554816999911,
                        55.770005601000136
                    ],
                    [
                        -6.049794074999909,
                        55.76634349199999
                    ],
                    [
                        -6.052316860999923,
                        55.758042710000026
                    ],
                    [
                        -6.056874152999882,
                        55.74884674700003
                    ],
                    [
                        -6.058257615999878,
                        55.74274323100006
                    ],
                    [
                        -6.053212042999917,
                        55.73729075700011
                    ],
                    [
                        -6.046620245999861,
                        55.733547268000066
                    ],
                    [
                        -6.030384894999912,
                        55.72907135600012
                    ],
                    [
                        -6.032785610999952,
                        55.71767812700013
                    ],
                    [
                        -6.032338019999941,
                        55.70636627800015
                    ],
                    [
                        -6.029286261999857,
                        55.696234442
                    ],
                    [
                        -6.023508266999897,
                        55.688137111000074
                    ],
                    [
                        -6.023508266999897,
                        55.68187083500014
                    ],
                    [
                        -6.035389777999853,
                        55.67845286700013
                    ],
                    [
                        -6.045725063999953,
                        55.674261786
                    ],
                    [
                        -6.053618943999936,
                        55.66836172100015
                    ],
                    [
                        -6.058257615999878,
                        55.66014232000008
                    ],
                    [
                        -6.064442511999914,
                        55.667629299000126
                    ],
                    [
                        -6.082427537999877,
                        55.65208567900011
                    ],
                    [
                        -6.105458136999857,
                        55.64020416900017
                    ],
                    [
                        -6.131906704999921,
                        55.63182200700011
                    ],
                    [
                        -6.160023566999911,
                        55.62665436400003
                    ],
                    [
                        -6.202544725999871,
                        55.630560614
                    ],
                    [
                        -6.215321417999917,
                        55.62665436400003
                    ],
                    [
                        -6.222767706999889,
                        55.60553620000009
                    ],
                    [
                        -6.226958787999905,
                        55.60175202000006
                    ],
                    [
                        -6.23656165299991,
                        55.595607815
                    ],
                    [
                        -6.261870897999927,
                        55.583807684000035
                    ],
                    [
                        -6.287342902999853,
                        55.57843659100017
                    ],
                    [
                        -6.31118730399993,
                        55.58323802300005
                    ],
                    [
                        -6.32518469999988,
                        55.60553620000009
                    ],
                    [
                        -6.319243943999879,
                        55.62824127800006
                    ],
                    [
                        -6.29747473899991,
                        55.64248281500015
                    ],
                    [
                        -6.243234829999949,
                        55.66014232000008
                    ],
                    [
                        -6.264800584999875,
                        55.69550202000009
                    ],
                    [
                        -6.277088995999918,
                        55.70400625200001
                    ],
                    [
                        -6.316761847999942,
                        55.715073960000055
                    ],
                    [
                        -6.32518469999988,
                        55.715399481000176
                    ],
                    [
                        -6.320952928999873,
                        55.733465887000094
                    ],
                    [
                        -6.295521613999938,
                        55.75189850500011
                    ],
                    [
                        -6.249989386999886,
                        55.776841539000046
                    ],
                    [
                        -6.27179928299995,
                        55.78302643400012
                    ],
                    [
                        -6.300445115999906,
                        55.785793361000074
                    ],
                    [
                        -6.328724738999881,
                        55.783148505
                    ],
                    [
                        -6.349029100999871,
                        55.77342357000005
                    ],
                    [
                        -6.372547980999912,
                        55.73871491100009
                    ],
                    [
                        -6.386586066999882,
                        55.72231679900007
                    ],
                    [
                        -6.418568488999881,
                        55.70962148600002
                    ],
                    [
                        -6.450306769999941,
                        55.68256256700006
                    ],
                    [
                        -6.462961391999897,
                        55.67446523600013
                    ],
                    [
                        -6.490956183999913,
                        55.67182038000006
                    ],
                    [
                        -6.503529425999915,
                        55.68134186400006
                    ],
                    [
                        -6.50243079299986,
                        55.69765859600007
                    ],
                    [
                        -6.489654100999928,
                        55.715399481000176
                    ],
                    [
                        -6.494699673999946,
                        55.717230536000145
                    ],
                    [
                        -6.498605923999946,
                        55.721136786000145
                    ],
                    [
                        -6.503895636999914,
                        55.72284577000015
                    ],
                    [
                        -6.493234829999892,
                        55.73924388200005
                    ],
                    [
                        -6.448638475999928,
                        55.776841539000046
                    ],
                    [
                        -6.482167120999861,
                        55.790472723
                    ],
                    [
                        -6.469064907999893,
                        55.79694245000009
                    ],
                    [
                        -6.460804816999911,
                        55.80320872600005
                    ],
                    [
                        -6.456654425999943,
                        55.81264883000007
                    ],
                    [
                        -6.455474412999934,
                        55.82835521000014
                    ],
                    [
                        -6.452788865999963,
                        55.84088776200004
                    ],
                    [
                        -6.445220506999903,
                        55.848822333
                    ],
                    [
                        -6.409087693999879,
                        55.86359284100017
                    ],
                    [
                        -6.354481574999909,
                        55.87742747600008
                    ],
                    [
                        -6.342640753999945,
                        55.88491445500013
                    ],
                    [
                        -6.331369594999956,
                        55.889960028000175
                    ],
                    [
                        -6.318348761999886,
                        55.88670482000013
                    ],
                    [
                        -6.325917120999918,
                        55.8803571640001
                    ],
                    [
                        -6.338205532999979,
                        55.85199616100006
                    ],
                    [
                        -6.338205532999979,
                        55.84577057500009
                    ],
                    [
                        -6.330718553999901,
                        55.82782623900006
                    ],
                    [
                        -6.323231574999852,
                        55.82050202000015
                    ],
                    [
                        -6.310902472999885,
                        55.81842682500003
                    ],
                    [
                        -6.310902472999885,
                        55.825262762000065
                    ],
                    [
                        -6.318430141999954,
                        55.844916083
                    ],
                    [
                        -6.296986456999917,
                        55.86717357000005
                    ],
                    [
                        -6.264800584999875,
                        55.88540273600013
                    ],
                    [
                        -6.229074673999918,
                        55.89728424700017
                    ],
                    [
                        -6.204253709999875,
                        55.91657135600012
                    ],
                    [
                        -6.191395636999857,
                        55.92084381700012
                    ],
                    [
                        -6.177154100999928,
                        55.923081773000106
                    ],
                    [
                        -6.162220831999889,
                        55.928452867000075
                    ],
                    [
                        -6.14907792899993,
                        55.93520742400001
                    ],
                    [
                        -6.140126105999912,
                        55.941351630000085
                    ],
                    [
                        -6.130116339999916,
                        55.93939850500014
                    ],
                    [
                        -6.12368730399993,
                        55.93549225500014
                    ],
                    [
                        -6.120228644999912,
                        55.92942942900014
                    ],
                    [
                        -6.115956183999913,
                        55.88678620000012
                    ],
                    [
                        -6.102365688999953,
                        55.83124420800006
                    ]
                ]
            ],
            [
                [
                    [
                        -6.13337154899989,
                        56.11261627800015
                    ],
                    [
                        -6.165638800999915,
                        56.08148834800015
                    ],
                    [
                        -6.177154100999928,
                        56.06146881700009
                    ],
                    [
                        -6.174305792999917,
                        56.04376862200017
                    ],
                    [
                        -6.19432532499988,
                        56.03530508000013
                    ],
                    [
                        -6.221669074999852,
                        56.03082916900017
                    ],
                    [
                        -6.249175584999875,
                        56.031317450000145
                    ],
                    [
                        -6.269886847999885,
                        56.037543036000116
                    ],
                    [
                        -6.254302537999905,
                        56.04462311400009
                    ],
                    [
                        -6.246815558999913,
                        56.0561384140001
                    ],
                    [
                        -6.241688605999912,
                        56.06948476800012
                    ],
                    [
                        -6.23265540299991,
                        56.08161041900003
                    ],
                    [
                        -6.211740688999925,
                        56.09699127800015
                    ],
                    [
                        -6.20571855399993,
                        56.10272858300014
                    ],
                    [
                        -6.19823157499988,
                        56.106268622000115
                    ],
                    [
                        -6.18260657499988,
                        56.10260651200015
                    ],
                    [
                        -6.174305792999917,
                        56.10643138200008
                    ],
                    [
                        -6.177398240999878,
                        56.1102562520001
                    ],
                    [
                        -6.179025844999899,
                        56.11294179900007
                    ],
                    [
                        -6.18179277299987,
                        56.11945221600017
                    ],
                    [
                        -6.164947068999879,
                        56.12079498900006
                    ],
                    [
                        -6.14940344999988,
                        56.12494538
                    ],
                    [
                        -6.138010219999899,
                        56.124701239000146
                    ],
                    [
                        -6.13337154899989,
                        56.11261627800015
                    ]
                ]
            ],
            [
                [
                    [
                        -5.780181443999908,
                        56.020738023000106
                    ],
                    [
                        -5.864491339999916,
                        55.92674388200005
                    ],
                    [
                        -5.886341925999943,
                        55.88670482000013
                    ],
                    [
                        -5.896839972999914,
                        55.88890208500011
                    ],
                    [
                        -5.921538865999906,
                        55.87128327
                    ],
                    [
                        -5.938140428999873,
                        55.86627838700015
                    ],
                    [
                        -5.945423956999889,
                        55.85858795800014
                    ],
                    [
                        -5.95539303299995,
                        55.82005442900005
                    ],
                    [
                        -5.962066209999932,
                        55.80475495000009
                    ],
                    [
                        -5.987538214999916,
                        55.794623114000146
                    ],
                    [
                        -6.026600714999887,
                        55.796087958
                    ],
                    [
                        -6.062408006999903,
                        55.80947500200001
                    ],
                    [
                        -6.078114386999857,
                        55.83490631700012
                    ],
                    [
                        -6.086903449999852,
                        55.8829613300001
                    ],
                    [
                        -6.08495032499988,
                        55.900336005000085
                    ],
                    [
                        -6.075184699999852,
                        55.91596100500006
                    ],
                    [
                        -6.060170050999915,
                        55.922919012000065
                    ],
                    [
                        -6.04312089799987,
                        55.92796458500008
                    ],
                    [
                        -6.026966925999915,
                        55.93793366100009
                    ],
                    [
                        -5.997425910999937,
                        55.951727606000176
                    ],
                    [
                        -5.919056769999884,
                        55.960394598000065
                    ],
                    [
                        -5.886341925999943,
                        55.97614166900003
                    ],
                    [
                        -5.910023566999882,
                        55.97650788000014
                    ],
                    [
                        -5.946848110999952,
                        55.970648505
                    ],
                    [
                        -5.98070227799991,
                        55.969916083000086
                    ],
                    [
                        -5.995594855999855,
                        55.986029364000146
                    ],
                    [
                        -5.983265753999916,
                        56.00885651200015
                    ],
                    [
                        -5.955189581999917,
                        56.03473541900017
                    ],
                    [
                        -5.924427863999938,
                        56.05597565300015
                    ],
                    [
                        -5.903960740999878,
                        56.06484609600001
                    ],
                    [
                        -5.873687303999873,
                        56.07013580900018
                    ],
                    [
                        -5.797596808999884,
                        56.11261627800015
                    ],
                    [
                        -5.746490037999905,
                        56.141750393
                    ],
                    [
                        -5.715931769999884,
                        56.15314362200017
                    ],
                    [
                        -5.694488084999932,
                        56.147406317
                    ],
                    [
                        -5.6908259759999,
                        56.137844143
                    ],
                    [
                        -5.68797766799986,
                        56.124090887000094
                    ],
                    [
                        -5.689320441999939,
                        56.111761786000145
                    ],
                    [
                        -5.705067511999886,
                        56.103013414000074
                    ],
                    [
                        -5.7201228509999,
                        56.08812083500011
                    ],
                    [
                        -5.740223761999857,
                        56.07648346600011
                    ],
                    [
                        -5.780181443999908,
                        56.020738023000106
                    ]
                ]
            ],
            [
                [
                    [
                        -5.725209113999938,
                        56.16282786700013
                    ],
                    [
                        -5.749134894999912,
                        56.16107819200012
                    ],
                    [
                        -5.738352016999897,
                        56.17747630400014
                    ],
                    [
                        -5.722238735999923,
                        56.187079169000114
                    ],
                    [
                        -5.688303188999981,
                        56.202053127000156
                    ],
                    [
                        -5.680897589999887,
                        56.19456614799999
                    ],
                    [
                        -5.673654751999948,
                        56.17894114800002
                    ],
                    [
                        -5.694203253999916,
                        56.1686058610001
                    ],
                    [
                        -5.725209113999938,
                        56.16282786700013
                    ]
                ]
            ],
            [
                [
                    [
                        -5.612619594999899,
                        56.263495184000035
                    ],
                    [
                        -5.610585089999859,
                        56.2494164080001
                    ],
                    [
                        -5.618153449999909,
                        56.23505280200011
                    ],
                    [
                        -5.628163214999887,
                        56.22052643400018
                    ],
                    [
                        -5.637440558999856,
                        56.18927643400009
                    ],
                    [
                        -5.647572394999912,
                        56.19537995000009
                    ],
                    [
                        -5.666656053999901,
                        56.22247955900012
                    ],
                    [
                        -5.65640214799987,
                        56.23200104400003
                    ],
                    [
                        -5.645334438999925,
                        56.255926825000174
                    ],
                    [
                        -5.63312740799995,
                        56.263495184000035
                    ],
                    [
                        -5.627837693999879,
                        56.25999583500014
                    ],
                    [
                        -5.622954881999903,
                        56.25763580900018
                    ],
                    [
                        -5.618031378999945,
                        56.25812409100017
                    ],
                    [
                        -5.612619594999899,
                        56.263495184000035
                    ]
                ]
            ],
            [
                [
                    [
                        -5.584624803999873,
                        56.32558828300016
                    ],
                    [
                        -5.59044348899991,
                        56.31362539300012
                    ],
                    [
                        -5.599964972999914,
                        56.305487372000115
                    ],
                    [
                        -5.605336066999882,
                        56.29694245000009
                    ],
                    [
                        -5.598988410999937,
                        56.283921617000104
                    ],
                    [
                        -5.621693488999881,
                        56.27606842700011
                    ],
                    [
                        -5.640288865999878,
                        56.28278229400017
                    ],
                    [
                        -5.64671790299991,
                        56.29877350500006
                    ],
                    [
                        -5.63312740799995,
                        56.318752346000124
                    ],
                    [
                        -5.620757615999906,
                        56.32420482
                    ],
                    [
                        -5.59788977799991,
                        56.32184479400003
                    ],
                    [
                        -5.584624803999873,
                        56.32558828300016
                    ]
                ]
            ],
            [
                [
                    [
                        -5.502756313999925,
                        56.42112864800005
                    ],
                    [
                        -5.518055792999888,
                        56.40387604400014
                    ],
                    [
                        -5.536203579999892,
                        56.392238674000126
                    ],
                    [
                        -5.557972785999937,
                        56.38646067900008
                    ],
                    [
                        -5.584624803999873,
                        56.38703034100014
                    ],
                    [
                        -5.57982337099989,
                        56.39642975500006
                    ],
                    [
                        -5.573963995999947,
                        56.40289948100015
                    ],
                    [
                        -5.557972785999937,
                        56.41429271000014
                    ],
                    [
                        -5.526519334999904,
                        56.4135602890001
                    ],
                    [
                        -5.512359178999901,
                        56.41543203300007
                    ],
                    [
                        -5.502756313999925,
                        56.42112864800005
                    ]
                ]
            ],
            [
                [
                    [
                        -6.160023566999911,
                        56.469549872000144
                    ],
                    [
                        -6.212473110999952,
                        56.46784088700004
                    ],
                    [
                        -6.237456834999904,
                        56.47003815300003
                    ],
                    [
                        -6.263661261999914,
                        56.48322174700017
                    ],
                    [
                        -6.253244594999927,
                        56.492865302000055
                    ],
                    [
                        -6.242054816999911,
                        56.499579169000086
                    ],
                    [
                        -6.229603644999912,
                        56.50332265800013
                    ],
                    [
                        -6.215321417999917,
                        56.50364817900005
                    ],
                    [
                        -6.200754360999895,
                        56.49494049700017
                    ],
                    [
                        -6.170562303999873,
                        56.481146552000055
                    ],
                    [
                        -6.160023566999911,
                        56.469549872000144
                    ]
                ]
            ],
            [
                [
                    [
                        -6.881581183999913,
                        56.444281317
                    ],
                    [
                        -6.902455206999889,
                        56.44375234600001
                    ],
                    [
                        -6.965199347999885,
                        56.4598656270001
                    ],
                    [
                        -6.973988410999937,
                        56.47150299700009
                    ],
                    [
                        -6.979725714999916,
                        56.48712799700017
                    ],
                    [
                        -6.989898240999963,
                        56.50364817900005
                    ],
                    [
                        -6.968129035999908,
                        56.510484117000075
                    ],
                    [
                        -6.900502081999946,
                        56.51666901200004
                    ],
                    [
                        -6.878081834999932,
                        56.52130768400015
                    ],
                    [
                        -6.841135219999899,
                        56.53473541900014
                    ],
                    [
                        -6.81859290299991,
                        56.53782786700005
                    ],
                    [
                        -6.767201300999886,
                        56.54857005400005
                    ],
                    [
                        -6.741851365999878,
                        56.54783763200011
                    ],
                    [
                        -6.729237433999884,
                        56.53034088700018
                    ],
                    [
                        -6.743316209999932,
                        56.51780833500008
                    ],
                    [
                        -6.803822394999912,
                        56.51361725500014
                    ],
                    [
                        -6.805572068999908,
                        56.496893622000115
                    ],
                    [
                        -6.825510219999899,
                        56.48729075700014
                    ],
                    [
                        -6.847727016999954,
                        56.484808661000116
                    ],
                    [
                        -6.86929277299987,
                        56.479641018
                    ],
                    [
                        -6.887521938999953,
                        56.46210358300006
                    ],
                    [
                        -6.881581183999913,
                        56.444281317
                    ]
                ]
            ],
            [
                [
                    [
                        -6.033518032999893,
                        56.618841864
                    ],
                    [
                        -6.008778449999909,
                        56.59870026200015
                    ],
                    [
                        -5.968251105999883,
                        56.54466380400005
                    ],
                    [
                        -5.965199347999885,
                        56.537665106000176
                    ],
                    [
                        -5.965158657999893,
                        56.53107330900009
                    ],
                    [
                        -5.962554490999906,
                        56.52610911700005
                    ],
                    [
                        -5.952137824999909,
                        56.524155992000104
                    ],
                    [
                        -5.85220292899993,
                        56.524155992000104
                    ],
                    [
                        -5.844146287999905,
                        56.52057526200004
                    ],
                    [
                        -5.83690344999988,
                        56.51463450700011
                    ],
                    [
                        -5.829457160999908,
                        56.510809637000094
                    ],
                    [
                        -5.814035610999895,
                        56.51666901200004
                    ],
                    [
                        -5.804188605999855,
                        56.51776764500009
                    ],
                    [
                        -5.794341600999928,
                        56.51666901200004
                    ],
                    [
                        -5.787342902999853,
                        56.51361725500014
                    ],
                    [
                        -5.765207485999895,
                        56.49420807500003
                    ],
                    [
                        -5.756662563999953,
                        56.49005768400009
                    ],
                    [
                        -5.746937628999916,
                        56.48843008000007
                    ],
                    [
                        -5.728423631999903,
                        56.490179755000085
                    ],
                    [
                        -5.721831834999904,
                        56.49005768400009
                    ],
                    [
                        -5.710357225999871,
                        56.48484935100011
                    ],
                    [
                        -5.696766730999912,
                        56.47565338700004
                    ],
                    [
                        -5.685536261999914,
                        56.464056708000115
                    ],
                    [
                        -5.680897589999887,
                        56.45184967700014
                    ],
                    [
                        -5.673451300999915,
                        56.449693101000136
                    ],
                    [
                        -5.658843553999901,
                        56.45172760600015
                    ],
                    [
                        -5.648426886999914,
                        56.44773997599999
                    ],
                    [
                        -5.653553839999916,
                        56.42796458500008
                    ],
                    [
                        -5.66185462099989,
                        56.43138255400008
                    ],
                    [
                        -5.670033331999917,
                        56.43309153899999
                    ],
                    [
                        -5.688303188999981,
                        56.43480052299999
                    ],
                    [
                        -5.679554816999911,
                        56.42796458500008
                    ],
                    [
                        -5.664947068999879,
                        56.413885809000035
                    ],
                    [
                        -5.657622850999871,
                        56.40009186400012
                    ],
                    [
                        -5.670318162999934,
                        56.39378489799999
                    ],
                    [
                        -5.703033006999874,
                        56.39337799700017
                    ],
                    [
                        -5.71743730399993,
                        56.397365627000156
                    ],
                    [
                        -5.715646938999953,
                        56.408107815000065
                    ],
                    [
                        -5.721669074999852,
                        56.412339585
                    ],
                    [
                        -5.727894660999908,
                        56.41429271000014
                    ],
                    [
                        -5.73468990799995,
                        56.414780992000104
                    ],
                    [
                        -5.74233964799987,
                        56.41429271000014
                    ],
                    [
                        -5.739084438999925,
                        56.401190497000144
                    ],
                    [
                        -5.751088019999941,
                        56.38939036700002
                    ],
                    [
                        -5.783924933999856,
                        56.37335846600011
                    ],
                    [
                        -5.783924933999856,
                        56.36591217700011
                    ],
                    [
                        -5.767974412999934,
                        56.372748114000146
                    ],
                    [
                        -5.749989386999914,
                        56.37714264500012
                    ],
                    [
                        -5.712228969999927,
                        56.38019440300003
                    ],
                    [
                        -5.695952928999901,
                        56.375433661000116
                    ],
                    [
                        -5.702707485999952,
                        56.36456940300003
                    ],
                    [
                        -5.719960089999859,
                        56.35276927300008
                    ],
                    [
                        -5.808705206999917,
                        56.31891510600009
                    ],
                    [
                        -5.848215298999975,
                        56.31362539300012
                    ],
                    [
                        -5.880116339999887,
                        56.32558828300016
                    ],
                    [
                        -5.873687303999873,
                        56.32855866100009
                    ],
                    [
                        -5.862700975999928,
                        56.33515045800014
                    ],
                    [
                        -5.853505011999857,
                        56.343695380000085
                    ],
                    [
                        -5.85220292899993,
                        56.35285065300015
                    ],
                    [
                        -5.860422329999949,
                        56.35834381700009
                    ],
                    [
                        -5.872670050999915,
                        56.35773346600003
                    ],
                    [
                        -5.897206183999941,
                        56.35285065300015
                    ],
                    [
                        -5.902333136999857,
                        56.34967682500009
                    ],
                    [
                        -5.907785610999895,
                        56.34259674700003
                    ],
                    [
                        -5.914784308999856,
                        56.33551666900014
                    ],
                    [
                        -5.924183722999885,
                        56.332342841000084
                    ],
                    [
                        -5.957875128999888,
                        56.32807038
                    ],
                    [
                        -6.037464972999885,
                        56.30524323100015
                    ],
                    [
                        -6.054554816999911,
                        56.29450104400014
                    ],
                    [
                        -6.065988735999952,
                        56.28921133000007
                    ],
                    [
                        -6.078521287999877,
                        56.291083075000145
                    ],
                    [
                        -6.090931769999912,
                        56.29572174700017
                    ],
                    [
                        -6.102365688999953,
                        56.2982445330001
                    ],
                    [
                        -6.112538214999887,
                        56.29621002800015
                    ],
                    [
                        -6.136301235999895,
                        56.28693268400009
                    ],
                    [
                        -6.146962042999917,
                        56.283921617000104
                    ],
                    [
                        -6.239816860999952,
                        56.283921617000104
                    ],
                    [
                        -6.245757615999878,
                        56.280747789000046
                    ],
                    [
                        -6.250355597999914,
                        56.27374909100014
                    ],
                    [
                        -6.256459113999881,
                        56.266669012000094
                    ],
                    [
                        -6.266835089999887,
                        56.263495184000035
                    ],
                    [
                        -6.279286261999914,
                        56.264105536
                    ],
                    [
                        -6.300038214999916,
                        56.26801178600009
                    ],
                    [
                        -6.327504035999937,
                        56.27724844000015
                    ],
                    [
                        -6.345611131999874,
                        56.28831614799999
                    ],
                    [
                        -6.360178188999896,
                        56.30361562700013
                    ],
                    [
                        -6.366118943999908,
                        56.32217031500015
                    ],
                    [
                        -6.357004360999923,
                        56.33633047100007
                    ],
                    [
                        -6.335438605999912,
                        56.34454987200017
                    ],
                    [
                        -6.310292120999918,
                        56.34735748900012
                    ],
                    [
                        -6.290394660999937,
                        56.34540436400009
                    ],
                    [
                        -6.274525519999912,
                        56.33486562700001
                    ],
                    [
                        -6.261219855999883,
                        56.32029857
                    ],
                    [
                        -6.247222459999932,
                        56.311672268000095
                    ],
                    [
                        -6.228911912999877,
                        56.318752346000124
                    ],
                    [
                        -6.228911912999877,
                        56.32558828300016
                    ],
                    [
                        -6.23680579299986,
                        56.326605536000116
                    ],
                    [
                        -6.241118943999936,
                        56.32827383000013
                    ],
                    [
                        -6.244536912999934,
                        56.33026764500009
                    ],
                    [
                        -6.249989386999886,
                        56.332342841000084
                    ],
                    [
                        -6.231760219999899,
                        56.33690013200014
                    ],
                    [
                        -6.186634894999941,
                        56.33124420800006
                    ],
                    [
                        -6.170887824999909,
                        56.33576080900009
                    ],
                    [
                        -6.153920050999915,
                        56.34479401200012
                    ],
                    [
                        -6.08495032499988,
                        56.35285065300015
                    ],
                    [
                        -6.039906378999916,
                        56.37103913000014
                    ],
                    [
                        -6.020822719999927,
                        56.382025458
                    ],
                    [
                        -6.026966925999915,
                        56.38703034100014
                    ],
                    [
                        -6.046945766999983,
                        56.38397858300014
                    ],
                    [
                        -6.105458136999857,
                        56.36591217700011
                    ],
                    [
                        -6.15404212099989,
                        56.35895416900006
                    ],
                    [
                        -6.178944464999916,
                        56.35993073100012
                    ],
                    [
                        -6.198557094999899,
                        56.36961497600008
                    ],
                    [
                        -6.203236456999917,
                        56.380316473
                    ],
                    [
                        -6.198841925999915,
                        56.39032623900009
                    ],
                    [
                        -6.189198370999861,
                        56.39777252800015
                    ],
                    [
                        -6.178049282999922,
                        56.400620835
                    ],
                    [
                        -6.164784308999913,
                        56.40253327000015
                    ],
                    [
                        -6.157215949999852,
                        56.40737539300012
                    ],
                    [
                        -6.142648891999926,
                        56.42796458500008
                    ],
                    [
                        -6.139637824999937,
                        56.43524811400009
                    ],
                    [
                        -6.135121222999885,
                        56.44232819200015
                    ],
                    [
                        -6.126535610999952,
                        56.448431708000115
                    ],
                    [
                        -6.11693274599989,
                        56.450384833000086
                    ],
                    [
                        -6.081532355999883,
                        56.448431708000115
                    ],
                    [
                        -6.021839972999885,
                        56.464097398000106
                    ],
                    [
                        -6.009836391999954,
                        56.469549872000144
                    ],
                    [
                        -6.004017706999917,
                        56.479681708
                    ],
                    [
                        -6.00328528599988,
                        56.48940664300015
                    ],
                    [
                        -6.007476365999906,
                        56.49762604400014
                    ],
                    [
                        -6.016672329999892,
                        56.50364817900005
                    ],
                    [
                        -6.081125454999949,
                        56.485052802000055
                    ],
                    [
                        -6.102365688999953,
                        56.48322174700017
                    ],
                    [
                        -6.109974738999881,
                        56.480698960000055
                    ],
                    [
                        -6.118478969999927,
                        56.476263739
                    ],
                    [
                        -6.12743079299986,
                        56.47435130400014
                    ],
                    [
                        -6.136789516999897,
                        56.47943756700006
                    ],
                    [
                        -6.153065558999913,
                        56.500067450000174
                    ],
                    [
                        -6.162098761999914,
                        56.509222723000065
                    ],
                    [
                        -6.174305792999917,
                        56.51666901200004
                    ],
                    [
                        -6.194935675999915,
                        56.52334219
                    ],
                    [
                        -6.288482225999871,
                        56.53229401200012
                    ],
                    [
                        -6.307728644999912,
                        56.53729889500006
                    ],
                    [
                        -6.324330206999889,
                        56.54425690300015
                    ],
                    [
                        -6.338205532999979,
                        56.55206940300015
                    ],
                    [
                        -6.290394660999937,
                        56.571966864000146
                    ],
                    [
                        -6.298695441999911,
                        56.581244208
                    ],
                    [
                        -6.319203253999888,
                        56.593980210000055
                    ],
                    [
                        -6.32518469999988,
                        56.60610586100013
                    ],
                    [
                        -6.28844153599988,
                        56.60797760600012
                    ],
                    [
                        -6.272124803999873,
                        56.61220937700013
                    ],
                    [
                        -6.256255662999934,
                        56.62034739800005
                    ],
                    [
                        -6.24742591099988,
                        56.61261627800003
                    ],
                    [
                        -6.238636847999914,
                        56.60858795800006
                    ],
                    [
                        -6.230213995999861,
                        56.60858795800006
                    ],
                    [
                        -6.222767706999889,
                        56.612941799000154
                    ],
                    [
                        -6.220285610999952,
                        56.63226959800009
                    ],
                    [
                        -6.190744594999899,
                        56.64492422100015
                    ],
                    [
                        -6.151275193999936,
                        56.651800848000065
                    ],
                    [
                        -6.119048631999903,
                        56.653876044000086
                    ],
                    [
                        -6.105336066999882,
                        56.64850495000003
                    ],
                    [
                        -6.058257615999878,
                        56.64020416900014
                    ],
                    [
                        -6.05992591099988,
                        56.635687567
                    ],
                    [
                        -6.062652147999927,
                        56.62482330900012
                    ],
                    [
                        -6.064442511999914,
                        56.62034739800005
                    ],
                    [
                        -6.033518032999893,
                        56.618841864
                    ]
                ]
            ],
            [
                [
                    [
                        -6.564116990999906,
                        56.59870026200015
                    ],
                    [
                        -6.616322394999912,
                        56.575181382
                    ],
                    [
                        -6.633046027999853,
                        56.571966864000146
                    ],
                    [
                        -6.657215949999852,
                        56.57782623900009
                    ],
                    [
                        -6.668934699999852,
                        56.5777855490001
                    ],
                    [
                        -6.67398027299987,
                        56.56854889500012
                    ],
                    [
                        -6.67788652299987,
                        56.55825429900001
                    ],
                    [
                        -6.686838344999899,
                        56.56378815300003
                    ],
                    [
                        -6.696278449999909,
                        56.57599518400012
                    ],
                    [
                        -6.701893683999913,
                        56.58563873900009
                    ],
                    [
                        -6.674468553999873,
                        56.588446356000034
                    ],
                    [
                        -6.648101365999963,
                        56.60285065300009
                    ],
                    [
                        -6.602894660999908,
                        56.64362213700015
                    ],
                    [
                        -6.591379360999895,
                        56.651841539000074
                    ],
                    [
                        -6.55923417899993,
                        56.66526927300005
                    ],
                    [
                        -6.535633917999888,
                        56.67084381700006
                    ],
                    [
                        -6.505848761999886,
                        56.68537018400009
                    ],
                    [
                        -6.489654100999928,
                        56.68866608300014
                    ],
                    [
                        -6.462310350999871,
                        56.691229559000035
                    ],
                    [
                        -6.4525447259999,
                        56.688421942
                    ],
                    [
                        -6.459217902999882,
                        56.67812734600007
                    ],
                    [
                        -6.468739386999857,
                        56.66815827000009
                    ],
                    [
                        -6.482533331999889,
                        56.647894598000065
                    ],
                    [
                        -6.489654100999928,
                        56.64020416900014
                    ],
                    [
                        -6.497222459999875,
                        56.634344794000114
                    ],
                    [
                        -6.504953579999892,
                        56.630072333
                    ],
                    [
                        -6.514271613999938,
                        56.62742747600011
                    ],
                    [
                        -6.527251756999931,
                        56.626532294000114
                    ],
                    [
                        -6.564116990999906,
                        56.59870026200015
                    ]
                ]
            ],
            [
                [
                    [
                        -7.381988084999904,
                        56.98753489800008
                    ],
                    [
                        -7.373524542999888,
                        56.98285553600006
                    ],
                    [
                        -7.402414516999897,
                        56.98338450700014
                    ],
                    [
                        -7.411040818999908,
                        56.97972239799999
                    ],
                    [
                        -7.419178839999916,
                        56.973334052000055
                    ],
                    [
                        -7.427235480999855,
                        56.96857330900015
                    ],
                    [
                        -7.435047980999855,
                        56.965277411000116
                    ],
                    [
                        -7.442372199999852,
                        56.962958075000145
                    ],
                    [
                        -7.436838344999927,
                        56.95368073100009
                    ],
                    [
                        -7.445301886999857,
                        56.947088934000035
                    ],
                    [
                        -7.460804816999882,
                        56.943182684000035
                    ],
                    [
                        -7.476551886999943,
                        56.941880601000136
                    ],
                    [
                        -7.541411912999934,
                        56.948716539000046
                    ],
                    [
                        -7.556752081999889,
                        56.95307038000014
                    ],
                    [
                        -7.556223110999895,
                        56.96263255400014
                    ],
                    [
                        -7.544911261999914,
                        56.9723167990001
                    ],
                    [
                        -7.528065558999913,
                        56.97662995000009
                    ],
                    [
                        -7.516509568999879,
                        56.98118724200005
                    ],
                    [
                        -7.511586066999911,
                        56.99164459800012
                    ],
                    [
                        -7.514027472999942,
                        57.00291575700011
                    ],
                    [
                        -7.524322068999879,
                        57.010199286000116
                    ],
                    [
                        -7.499908006999931,
                        57.020086981000034
                    ],
                    [
                        -7.475697394999941,
                        57.02301666900014
                    ],
                    [
                        -7.457102016999926,
                        57.031561591000084
                    ],
                    [
                        -7.449208136999857,
                        57.05857982000013
                    ],
                    [
                        -7.444691535999908,
                        57.05853913000014
                    ],
                    [
                        -7.441395636999886,
                        57.05719635600015
                    ],
                    [
                        -7.434966600999871,
                        57.05174388200011
                    ],
                    [
                        -7.428130662999934,
                        57.05133698100009
                    ],
                    [
                        -7.422963019999941,
                        57.05011627800009
                    ],
                    [
                        -7.418690558999913,
                        57.04804108300006
                    ],
                    [
                        -7.414418097999914,
                        57.04490794499999
                    ],
                    [
                        -7.428781704999892,
                        57.02773672100007
                    ],
                    [
                        -7.434966600999871,
                        57.02383047100007
                    ],
                    [
                        -7.402821417999888,
                        57.014634507
                    ],
                    [
                        -7.393950975999957,
                        57.010199286000116
                    ],
                    [
                        -7.389068162999877,
                        57.003607489000146
                    ],
                    [
                        -7.38609778599988,
                        56.995306708000086
                    ],
                    [
                        -7.381988084999904,
                        56.98753489800008
                    ]
                ]
            ],
            [
                [
                    [
                        -6.269886847999885,
                        57.017564195000105
                    ],
                    [
                        -6.269886847999885,
                        57.010199286000116
                    ],
                    [
                        -6.243234829999949,
                        57.010199286000116
                    ],
                    [
                        -6.256743943999936,
                        56.97378164300015
                    ],
                    [
                        -6.276031053999873,
                        56.95311107000013
                    ],
                    [
                        -6.303618943999879,
                        56.94399648600013
                    ],
                    [
                        -6.341867641999926,
                        56.941880601000136
                    ],
                    [
                        -6.359771287999877,
                        56.947333075000174
                    ],
                    [
                        -6.376128709999904,
                        56.97117747600005
                    ],
                    [
                        -6.390288865999906,
                        56.97662995000009
                    ],
                    [
                        -6.405384894999912,
                        56.98053620000009
                    ],
                    [
                        -6.436756964999859,
                        56.998480536000145
                    ],
                    [
                        -6.455474412999934,
                        57.003973700000145
                    ],
                    [
                        -6.395253058999884,
                        57.04193756700009
                    ],
                    [
                        -6.364247199999852,
                        57.05565013200011
                    ],
                    [
                        -6.32518469999988,
                        57.05857982000013
                    ],
                    [
                        -6.272938605999883,
                        57.040228583000086
                    ],
                    [
                        -6.25096594999988,
                        57.02655670800014
                    ],
                    [
                        -6.269886847999885,
                        57.017564195000105
                    ]
                ]
            ],
            [
                [
                    [
                        -6.51593990799995,
                        57.05068594000015
                    ],
                    [
                        -6.548247850999871,
                        57.047593492000075
                    ],
                    [
                        -6.580433722999914,
                        57.048407294000086
                    ],
                    [
                        -6.606353318999908,
                        57.05174388200011
                    ],
                    [
                        -6.590809699999909,
                        57.058986721000124
                    ],
                    [
                        -6.556304490999906,
                        57.07013580900015
                    ],
                    [
                        -6.537464972999942,
                        57.072821356000034
                    ],
                    [
                        -6.52586829299986,
                        57.06712474200005
                    ],
                    [
                        -6.50153561099998,
                        57.06366608300006
                    ],
                    [
                        -6.489654100999928,
                        57.05857982000013
                    ],
                    [
                        -6.51593990799995,
                        57.05068594000015
                    ]
                ]
            ],
            [
                [
                    [
                        -5.925445115999906,
                        57.291693427000084
                    ],
                    [
                        -5.958607550999915,
                        57.28449127800015
                    ],
                    [
                        -5.98485266799986,
                        57.29043203300007
                    ],
                    [
                        -6.007801886999857,
                        57.30361562700004
                    ],
                    [
                        -6.01390540299991,
                        57.31745026200012
                    ],
                    [
                        -5.989979620999861,
                        57.32550690300015
                    ],
                    [
                        -5.955718553999901,
                        57.32160065300015
                    ],
                    [
                        -5.929351365999906,
                        57.30719635600009
                    ],
                    [
                        -5.925445115999906,
                        57.291693427000084
                    ]
                ]
            ],
            [
                [
                    [
                        -7.353016730999912,
                        57.40802643400015
                    ],
                    [
                        -7.263579881999931,
                        57.373928127000156
                    ],
                    [
                        -7.272084113999938,
                        57.370266018
                    ],
                    [
                        -7.279123501999919,
                        57.36994049700017
                    ],
                    [
                        -7.298410610999952,
                        57.373928127000156
                    ],
                    [
                        -7.298410610999952,
                        57.36709219000012
                    ],
                    [
                        -7.27635657499988,
                        57.36473216399999
                    ],
                    [
                        -7.244862433999856,
                        57.348456122000144
                    ],
                    [
                        -7.222645636999886,
                        57.34658437700007
                    ],
                    [
                        -7.222645636999886,
                        57.33978913000006
                    ],
                    [
                        -7.250355597999885,
                        57.33185455900018
                    ],
                    [
                        -7.271799282999922,
                        57.337469794000086
                    ],
                    [
                        -7.290150519999884,
                        57.347398179
                    ],
                    [
                        -7.33161373599998,
                        57.36090729400014
                    ],
                    [
                        -7.351389126999891,
                        57.37669505400011
                    ],
                    [
                        -7.371083136999857,
                        57.3876000020001
                    ],
                    [
                        -7.393950975999957,
                        57.380764065000065
                    ],
                    [
                        -7.387766079999892,
                        57.37750885600012
                    ],
                    [
                        -7.38109290299991,
                        57.37303294500005
                    ],
                    [
                        -7.375721808999884,
                        57.368109442
                    ],
                    [
                        -7.373524542999888,
                        57.36359284100014
                    ],
                    [
                        -7.37047278599988,
                        57.35512929900001
                    ],
                    [
                        -7.363189256999874,
                        57.353216864000146
                    ],
                    [
                        -7.354156053999873,
                        57.35374583500011
                    ],
                    [
                        -7.346180792999917,
                        57.35276927300005
                    ],
                    [
                        -7.32201087099989,
                        57.34658437700007
                    ],
                    [
                        -7.317941860999923,
                        57.344468492000075
                    ],
                    [
                        -7.305287238999881,
                        57.33234284100017
                    ],
                    [
                        -7.290272589999859,
                        57.32831452
                    ],
                    [
                        -7.259388800999886,
                        57.328029690000065
                    ],
                    [
                        -7.243763800999915,
                        57.32550690300015
                    ],
                    [
                        -7.232004360999923,
                        57.31879303600003
                    ],
                    [
                        -7.222645636999886,
                        57.31061432500009
                    ],
                    [
                        -7.211822068999908,
                        57.30487702000012
                    ],
                    [
                        -7.195423956999889,
                        57.30560944200006
                    ],
                    [
                        -7.200347459999961,
                        57.29096100500006
                    ],
                    [
                        -7.209706183999884,
                        57.28546784100003
                    ],
                    [
                        -7.221424933999884,
                        57.2821312520001
                    ],
                    [
                        -7.233225063999953,
                        57.27431875200001
                    ],
                    [
                        -7.239165818999879,
                        57.2652855490001
                    ],
                    [
                        -7.249582485999952,
                        57.24469635600015
                    ],
                    [
                        -7.256743943999908,
                        57.23672109600001
                    ],
                    [
                        -7.276112433999913,
                        57.23175690300015
                    ],
                    [
                        -7.353016730999912,
                        57.242905992000075
                    ],
                    [
                        -7.353016730999912,
                        57.23672109600001
                    ],
                    [
                        -7.28880774599989,
                        57.22589752800012
                    ],
                    [
                        -7.271066860999895,
                        57.21625397300012
                    ],
                    [
                        -7.26219641799986,
                        57.200873114
                    ],
                    [
                        -7.257720506999874,
                        57.17975495000009
                    ],
                    [
                        -7.25991777299987,
                        57.15981679900001
                    ],
                    [
                        -7.271066860999895,
                        57.147935289000046
                    ],
                    [
                        -7.28844153599988,
                        57.153998114000146
                    ],
                    [
                        -7.307606574999852,
                        57.15582916900003
                    ],
                    [
                        -7.346180792999917,
                        57.15477122600008
                    ],
                    [
                        -7.346180792999917,
                        57.147935289000046
                    ],
                    [
                        -7.328439907999922,
                        57.14618561400006
                    ],
                    [
                        -7.292469855999855,
                        57.148871161000116
                    ],
                    [
                        -7.277251756999874,
                        57.147935289000046
                    ],
                    [
                        -7.259999152999853,
                        57.14004140800007
                    ],
                    [
                        -7.248402472999942,
                        57.129461981000034
                    ],
                    [
                        -7.235951300999915,
                        57.121161200000145
                    ],
                    [
                        -7.215891079999949,
                        57.120062567
                    ],
                    [
                        -7.215891079999949,
                        57.113836981000034
                    ],
                    [
                        -7.239125128999888,
                        57.10053131700012
                    ],
                    [
                        -7.321685350999871,
                        57.10993073100006
                    ],
                    [
                        -7.353016730999912,
                        57.09955475500014
                    ],
                    [
                        -7.381988084999904,
                        57.118353583
                    ],
                    [
                        -7.390695766999897,
                        57.129055080000015
                    ],
                    [
                        -7.416656053999901,
                        57.18158600500006
                    ],
                    [
                        -7.420399542999917,
                        57.19773997600011
                    ],
                    [
                        -7.414418097999914,
                        57.209418036000116
                    ],
                    [
                        -7.414418097999914,
                        57.21625397300012
                    ],
                    [
                        -7.431467251999948,
                        57.22943756700009
                    ],
                    [
                        -7.442290818999879,
                        57.23460521
                    ],
                    [
                        -7.455433722999914,
                        57.23672109600001
                    ],
                    [
                        -7.442738410999965,
                        57.24477773600013
                    ],
                    [
                        -7.434966600999871,
                        57.251898505
                    ],
                    [
                        -7.421945766999954,
                        57.2709007830001
                    ],
                    [
                        -7.417307094999927,
                        57.28143952000015
                    ],
                    [
                        -7.414947068999879,
                        57.290106512000065
                    ],
                    [
                        -7.409982876999948,
                        57.295965887000094
                    ],
                    [
                        -7.39708411399991,
                        57.298163153000175
                    ],
                    [
                        -7.389719204999921,
                        57.31195709800009
                    ],
                    [
                        -7.401519334999904,
                        57.34235260600015
                    ],
                    [
                        -7.428130662999934,
                        57.3876000020001
                    ],
                    [
                        -7.40843665299991,
                        57.39557526200004
                    ],
                    [
                        -7.362863735999952,
                        57.4078636740001
                    ],
                    [
                        -7.353016730999912,
                        57.40802643400015
                    ]
                ]
            ],
            [
                [
                    [
                        -7.292469855999855,
                        57.48529694200009
                    ],
                    [
                        -7.284494594999984,
                        57.482855536000145
                    ],
                    [
                        -7.274728969999927,
                        57.48224518400018
                    ],
                    [
                        -7.263579881999931,
                        57.483099677
                    ],
                    [
                        -7.265288865999935,
                        57.478745835
                    ],
                    [
                        -7.265736456999917,
                        57.478013414000074
                    ],
                    [
                        -7.266997850999928,
                        57.478176174000126
                    ],
                    [
                        -7.271066860999895,
                        57.47626373900006
                    ],
                    [
                        -7.262521938999953,
                        57.47211334800012
                    ],
                    [
                        -7.254261847999885,
                        57.47284577000006
                    ],
                    [
                        -7.245757615999935,
                        57.47516510600012
                    ],
                    [
                        -7.236317511999914,
                        57.47626373900006
                    ],
                    [
                        -7.229481574999909,
                        57.47455475500006
                    ],
                    [
                        -7.202137824999852,
                        57.46263255400011
                    ],
                    [
                        -7.2084041009999,
                        57.45644765800016
                    ],
                    [
                        -7.222035285999937,
                        57.460923570000105
                    ],
                    [
                        -7.24469967399989,
                        57.449652411000116
                    ],
                    [
                        -7.263579881999931,
                        57.448960679
                    ],
                    [
                        -7.258208787999877,
                        57.43911367400007
                    ],
                    [
                        -7.249663865999935,
                        57.43524811400006
                    ],
                    [
                        -7.226429816999911,
                        57.43528880400005
                    ],
                    [
                        -7.212025519999941,
                        57.43219635600015
                    ],
                    [
                        -7.20409094999988,
                        57.425441799000126
                    ],
                    [
                        -7.205433722999885,
                        57.41860586100002
                    ],
                    [
                        -7.292062954999921,
                        57.404730536000116
                    ],
                    [
                        -7.318267381999874,
                        57.40802643400015
                    ],
                    [
                        -7.396148240999935,
                        57.43398672100015
                    ],
                    [
                        -7.4076228509999,
                        57.44562409100014
                    ],
                    [
                        -7.402699347999914,
                        57.46434153900013
                    ],
                    [
                        -7.389271613999938,
                        57.47988515800013
                    ],
                    [
                        -7.369618292999888,
                        57.48932526200004
                    ],
                    [
                        -7.346180792999917,
                        57.489935614
                    ],
                    [
                        -7.334462042999917,
                        57.48509349200013
                    ],
                    [
                        -7.322132941999882,
                        57.482896226000136
                    ],
                    [
                        -7.309803839999916,
                        57.48411692900005
                    ],
                    [
                        -7.298410610999952,
                        57.489935614
                    ],
                    [
                        -7.292469855999855,
                        57.48529694200009
                    ]
                ]
            ],
            [
                [
                    [
                        -5.982533331999889,
                        57.49746328300016
                    ],
                    [
                        -5.986724412999905,
                        57.48224518400018
                    ],
                    [
                        -6.003041144999941,
                        57.452826239
                    ],
                    [
                        -6.017648891999954,
                        57.418198960000026
                    ],
                    [
                        -6.016672329999892,
                        57.3876000020001
                    ],
                    [
                        -6.008900519999884,
                        57.38019440300009
                    ],
                    [
                        -5.99510657499988,
                        57.363714911000116
                    ],
                    [
                        -5.989491339999887,
                        57.34731679900001
                    ],
                    [
                        -6.006459113999938,
                        57.33978913000006
                    ],
                    [
                        -6.022857225999871,
                        57.33624909100017
                    ],
                    [
                        -6.038685675999886,
                        57.32977936400006
                    ],
                    [
                        -6.054798956999946,
                        57.326402085000055
                    ],
                    [
                        -6.071929490999906,
                        57.33234284100017
                    ],
                    [
                        -6.069243943999908,
                        57.339544989
                    ],
                    [
                        -6.067616339999887,
                        57.34247467700011
                    ],
                    [
                        -6.064442511999914,
                        57.34658437700007
                    ],
                    [
                        -6.075428839999887,
                        57.35504791900003
                    ],
                    [
                        -6.07713782499988,
                        57.371771552000055
                    ],
                    [
                        -6.069081183999856,
                        57.43610260600015
                    ],
                    [
                        -6.061634894999884,
                        57.45270416900003
                    ],
                    [
                        -6.04552161399991,
                        57.46063873900009
                    ],
                    [
                        -6.016672329999892,
                        57.46263255400011
                    ],
                    [
                        -6.016672329999892,
                        57.469427802000055
                    ],
                    [
                        -6.023589647999899,
                        57.47418854400014
                    ],
                    [
                        -6.029042120999918,
                        57.47939687700013
                    ],
                    [
                        -6.037098761999857,
                        57.489935614
                    ],
                    [
                        -6.02586829299986,
                        57.49624258000013
                    ],
                    [
                        -6.015858527999882,
                        57.49677155200011
                    ],
                    [
                        -5.995594855999855,
                        57.489935614
                    ],
                    [
                        -5.995594855999855,
                        57.49746328300016
                    ],
                    [
                        -6.009836391999954,
                        57.51105377800012
                    ],
                    [
                        -6.000884568999936,
                        57.50971100500011
                    ],
                    [
                        -5.993316209999904,
                        57.50702545800014
                    ],
                    [
                        -5.987212693999908,
                        57.502875067
                    ],
                    [
                        -5.982533331999889,
                        57.49746328300016
                    ]
                ]
            ],
            [
                [
                    [
                        -5.968006964999859,
                        57.53392161700013
                    ],
                    [
                        -5.968251105999883,
                        57.51788971600003
                    ],
                    [
                        -5.983713344999899,
                        57.5223656270001
                    ],
                    [
                        -5.989979620999861,
                        57.53294505400005
                    ],
                    [
                        -5.98729407499988,
                        57.54629140800016
                    ],
                    [
                        -5.975697394999884,
                        57.558823960000055
                    ],
                    [
                        -5.980091925999943,
                        57.56146881700015
                    ],
                    [
                        -5.980376756999874,
                        57.56244538000011
                    ],
                    [
                        -5.982533331999889,
                        57.56631094000012
                    ],
                    [
                        -5.975697394999884,
                        57.57233307500003
                    ],
                    [
                        -5.968658006999903,
                        57.57656484600015
                    ],
                    [
                        -5.961903449999852,
                        57.577297268000095
                    ],
                    [
                        -5.955189581999917,
                        57.572495835
                    ],
                    [
                        -5.968006964999859,
                        57.53392161700013
                    ]
                ]
            ],
            [
                [
                    [
                        -13.688588019999912,
                        57.59625885600009
                    ],
                    [
                        -13.690419074999909,
                        57.59625885600009
                    ],
                    [
                        -13.691314256999902,
                        57.59650299700006
                    ],
                    [
                        -13.691314256999902,
                        57.59715403899999
                    ],
                    [
                        -13.690419074999909,
                        57.59780508000013
                    ],
                    [
                        -13.688588019999912,
                        57.59780508000013
                    ],
                    [
                        -13.687652147999927,
                        57.59715403899999
                    ],
                    [
                        -13.687652147999927,
                        57.59686920800006
                    ],
                    [
                        -13.688588019999912,
                        57.59625885600009
                    ]
                ]
            ],
            [
                [
                    [
                        -7.189116990999935,
                        57.65509674700017
                    ],
                    [
                        -7.172840949999909,
                        57.649562893000066
                    ],
                    [
                        -7.142567511999914,
                        57.65810781500009
                    ],
                    [
                        -7.129383917999888,
                        57.65289948100009
                    ],
                    [
                        -7.114572719999899,
                        57.652329820000105
                    ],
                    [
                        -7.092600063999896,
                        57.65208567900008
                    ],
                    [
                        -7.074086066999882,
                        57.646389065
                    ],
                    [
                        -7.065052863999881,
                        57.63397858300006
                    ],
                    [
                        -7.075428839999859,
                        57.628363348000065
                    ],
                    [
                        -7.092355923999946,
                        57.61151764500006
                    ],
                    [
                        -7.099191860999952,
                        57.60732656500012
                    ],
                    [
                        -7.113107876999948,
                        57.60871002800012
                    ],
                    [
                        -7.123117641999926,
                        57.61570872599999
                    ],
                    [
                        -7.130767381999874,
                        57.623480536
                    ],
                    [
                        -7.137318488999938,
                        57.62714264500015
                    ],
                    [
                        -7.148426886999886,
                        57.629339911000145
                    ],
                    [
                        -7.171131964999916,
                        57.641424872000144
                    ],
                    [
                        -7.215891079999949,
                        57.641424872000144
                    ],
                    [
                        -7.215891079999949,
                        57.63397858300006
                    ],
                    [
                        -7.204253709999932,
                        57.63336823100012
                    ],
                    [
                        -7.197255011999886,
                        57.63125234600001
                    ],
                    [
                        -7.196156378999916,
                        57.62718333500014
                    ],
                    [
                        -7.202137824999852,
                        57.62091705900009
                    ],
                    [
                        -7.202137824999852,
                        57.613470770000035
                    ],
                    [
                        -7.173247850999928,
                        57.61465078300013
                    ],
                    [
                        -7.164418097999885,
                        57.609930731000034
                    ],
                    [
                        -7.161203579999921,
                        57.596380927000084
                    ],
                    [
                        -7.154286261999914,
                        57.587347723000065
                    ],
                    [
                        -7.13805091099988,
                        57.58698151200004
                    ],
                    [
                        -7.10602779899989,
                        57.59296295800006
                    ],
                    [
                        -7.125599738999938,
                        57.571112372000115
                    ],
                    [
                        -7.149973110999923,
                        57.561712958
                    ],
                    [
                        -7.2084041009999,
                        57.558823960000055
                    ],
                    [
                        -7.215728318999908,
                        57.557359117000104
                    ],
                    [
                        -7.230213995999861,
                        57.552394924000154
                    ],
                    [
                        -7.236317511999914,
                        57.55206940300003
                    ],
                    [
                        -7.243275519999912,
                        57.55609772300009
                    ],
                    [
                        -7.252064581999889,
                        57.56928131700015
                    ],
                    [
                        -7.256743943999908,
                        57.572495835
                    ],
                    [
                        -7.269886847999942,
                        57.570542710000055
                    ],
                    [
                        -7.312082485999895,
                        57.55206940300003
                    ],
                    [
                        -7.312082485999895,
                        57.544582424000154
                    ],
                    [
                        -7.240061001999948,
                        57.544582424000154
                    ],
                    [
                        -7.181304490999878,
                        57.553900458
                    ],
                    [
                        -7.161203579999921,
                        57.55206940300003
                    ],
                    [
                        -7.144154425999943,
                        57.548529364000146
                    ],
                    [
                        -7.137684699999852,
                        57.54511139500012
                    ],
                    [
                        -7.133900519999941,
                        57.53839752800009
                    ],
                    [
                        -7.137277798999946,
                        57.53213125200013
                    ],
                    [
                        -7.157541469999956,
                        57.51105377800012
                    ],
                    [
                        -7.199208136999914,
                        57.51268138200014
                    ],
                    [
                        -7.219838019999941,
                        57.51080963700015
                    ],
                    [
                        -7.236317511999914,
                        57.503607489000146
                    ],
                    [
                        -7.25251217399989,
                        57.510321356000176
                    ],
                    [
                        -7.287464972999885,
                        57.50877513200014
                    ],
                    [
                        -7.305287238999881,
                        57.51105377800012
                    ],
                    [
                        -7.314686652999882,
                        57.514593817
                    ],
                    [
                        -7.318470831999917,
                        57.51703522300012
                    ],
                    [
                        -7.329986131999959,
                        57.528876044000086
                    ],
                    [
                        -7.333485480999855,
                        57.53432851800012
                    ],
                    [
                        -7.338124152999882,
                        57.539740302000084
                    ],
                    [
                        -7.346180792999917,
                        57.544582424000154
                    ],
                    [
                        -7.337554490999906,
                        57.54507070500013
                    ],
                    [
                        -7.318267381999874,
                        57.55206940300003
                    ],
                    [
                        -7.351226365999935,
                        57.55369700700005
                    ],
                    [
                        -7.411610480999855,
                        57.57514069200009
                    ],
                    [
                        -7.442372199999852,
                        57.579291083000115
                    ],
                    [
                        -7.449208136999857,
                        57.5729027360001
                    ],
                    [
                        -7.455922003999916,
                        57.56842682500003
                    ],
                    [
                        -7.462880011999886,
                        57.56631094000012
                    ],
                    [
                        -7.47085527299987,
                        57.56720612200003
                    ],
                    [
                        -7.483265753999888,
                        57.571478583000115
                    ],
                    [
                        -7.499419725999928,
                        57.575018622000115
                    ],
                    [
                        -7.544829881999931,
                        57.59296295800006
                    ],
                    [
                        -7.544829881999931,
                        57.59979889500009
                    ],
                    [
                        -7.522206183999884,
                        57.608384507
                    ],
                    [
                        -7.506581183999884,
                        57.62555573100012
                    ],
                    [
                        -7.493031378999916,
                        57.64541250200004
                    ],
                    [
                        -7.476551886999943,
                        57.66193268400009
                    ],
                    [
                        -7.455311652999853,
                        57.655462958
                    ],
                    [
                        -7.387115037999934,
                        57.66193268400009
                    ],
                    [
                        -7.387115037999934,
                        57.65509674700017
                    ],
                    [
                        -7.394927537999934,
                        57.65444570500013
                    ],
                    [
                        -7.414418097999914,
                        57.64826080900015
                    ],
                    [
                        -7.396555141999954,
                        57.63532135600015
                    ],
                    [
                        -7.366851365999906,
                        57.65143463700004
                    ],
                    [
                        -7.3353572259999,
                        57.67609284100003
                    ],
                    [
                        -7.312082485999895,
                        57.68854401200004
                    ],
                    [
                        -7.312082485999895,
                        57.682359117000075
                    ],
                    [
                        -7.318186001999891,
                        57.678859768000095
                    ],
                    [
                        -7.32286536399991,
                        57.67475006700012
                    ],
                    [
                        -7.33250891799986,
                        57.66193268400009
                    ],
                    [
                        -7.277902798999918,
                        57.649562893000066
                    ],
                    [
                        -7.256743943999908,
                        57.64826080900015
                    ],
                    [
                        -7.256743943999908,
                        57.65509674700017
                    ],
                    [
                        -7.263417120999861,
                        57.65599192900008
                    ],
                    [
                        -7.277251756999874,
                        57.66193268400009
                    ],
                    [
                        -7.255970831999889,
                        57.66693756700015
                    ],
                    [
                        -7.209055141999926,
                        57.68602122600011
                    ],
                    [
                        -7.181711391999954,
                        57.68854401200004
                    ],
                    [
                        -7.169178839999859,
                        57.6791852890001
                    ],
                    [
                        -7.165394660999937,
                        57.66986725500006
                    ],
                    [
                        -7.171538865999906,
                        57.6615257830001
                    ],
                    [
                        -7.189116990999935,
                        57.65509674700017
                    ]
                ]
            ],
            [
                [
                    [
                        -6.310902472999885,
                        57.70282623900012
                    ],
                    [
                        -6.302235480999912,
                        57.69236888200005
                    ],
                    [
                        -6.293324347999942,
                        57.68602122600011
                    ],
                    [
                        -6.269886847999885,
                        57.67552317900005
                    ],
                    [
                        -6.260487433999856,
                        57.66864655200014
                    ],
                    [
                        -6.236073370999918,
                        57.646185614000146
                    ],
                    [
                        -6.23265540299991,
                        57.641424872000144
                    ],
                    [
                        -6.18797766799986,
                        57.62091705900009
                    ],
                    [
                        -6.168853318999879,
                        57.60761139500006
                    ],
                    [
                        -6.158355272999899,
                        57.59784577000012
                    ],
                    [
                        -6.153879360999923,
                        57.589544989000146
                    ],
                    [
                        -6.14468339799987,
                        57.52000560100005
                    ],
                    [
                        -6.135121222999885,
                        57.49005768400018
                    ],
                    [
                        -6.13337154899989,
                        57.47626373900006
                    ],
                    [
                        -6.136057094999956,
                        57.461330471000124
                    ],
                    [
                        -6.145090298999946,
                        57.438625393000066
                    ],
                    [
                        -6.146962042999917,
                        57.42511627800009
                    ],
                    [
                        -6.15249589799987,
                        57.41754791900014
                    ],
                    [
                        -6.178863084999932,
                        57.40355052299999
                    ],
                    [
                        -6.18797766799986,
                        57.39435455900012
                    ],
                    [
                        -6.175852016999954,
                        57.400824286000116
                    ],
                    [
                        -6.159250454999892,
                        57.407375393
                    ],
                    [
                        -6.14159094999988,
                        57.41079336100002
                    ],
                    [
                        -6.126535610999952,
                        57.40802643400015
                    ],
                    [
                        -6.117909308999856,
                        57.39777252800003
                    ],
                    [
                        -6.12352454299986,
                        57.38825104400003
                    ],
                    [
                        -6.134103969999899,
                        57.38003164300012
                    ],
                    [
                        -6.140126105999912,
                        57.373928127000156
                    ],
                    [
                        -6.13524329299986,
                        57.357367255000085
                    ],
                    [
                        -6.120757615999906,
                        57.34707265800016
                    ],
                    [
                        -6.105702277999882,
                        57.33966705900018
                    ],
                    [
                        -6.099232550999886,
                        57.33234284100017
                    ],
                    [
                        -6.106597459999875,
                        57.323472398000135
                    ],
                    [
                        -6.140288865999878,
                        57.312445380000085
                    ],
                    [
                        -6.153879360999923,
                        57.30560944200006
                    ],
                    [
                        -6.068186001999948,
                        57.31183502800012
                    ],
                    [
                        -6.045399542999945,
                        57.308294989000146
                    ],
                    [
                        -6.041086391999926,
                        57.30011627800003
                    ],
                    [
                        -6.052886522999899,
                        57.29100169500005
                    ],
                    [
                        -6.078114386999857,
                        57.28449127800015
                    ],
                    [
                        -6.078114386999857,
                        57.27765534100003
                    ],
                    [
                        -6.050852016999954,
                        57.27924225500006
                    ],
                    [
                        -6.028879360999952,
                        57.28534577000015
                    ],
                    [
                        -6.008452928999901,
                        57.28676992400004
                    ],
                    [
                        -5.986236131999931,
                        57.27431875200001
                    ],
                    [
                        -5.976673956999946,
                        57.27033112200003
                    ],
                    [
                        -5.96344967399989,
                        57.26947663000011
                    ],
                    [
                        -5.938140428999873,
                        57.2709007830001
                    ],
                    [
                        -5.9232478509999,
                        57.26691315300012
                    ],
                    [
                        -5.91380774599989,
                        57.25787995000012
                    ],
                    [
                        -5.907134568999936,
                        57.24848053600009
                    ],
                    [
                        -5.900624152999853,
                        57.242905992000075
                    ],
                    [
                        -5.886219855999883,
                        57.242092190000065
                    ],
                    [
                        -5.877308722999942,
                        57.246812242000075
                    ],
                    [
                        -5.87132727799991,
                        57.253241278000175
                    ],
                    [
                        -5.865834113999881,
                        57.257147528000175
                    ],
                    [
                        -5.824330206999889,
                        57.25617096600011
                    ],
                    [
                        -5.795806443999908,
                        57.271795966000084
                    ],
                    [
                        -5.76789303299995,
                        57.27741120000009
                    ],
                    [
                        -5.738514777999853,
                        57.27728913000011
                    ],
                    [
                        -5.721831834999904,
                        57.26463450700014
                    ],
                    [
                        -5.647368943999936,
                        57.26463450700014
                    ],
                    [
                        -5.663889126999919,
                        57.239813544000086
                    ],
                    [
                        -5.663482225999928,
                        57.2333031270001
                    ],
                    [
                        -5.659087693999936,
                        57.22247955900009
                    ],
                    [
                        -5.662953253999945,
                        57.21271393400015
                    ],
                    [
                        -5.670643683999856,
                        57.20465729400003
                    ],
                    [
                        -5.690907355999883,
                        57.18952057500003
                    ],
                    [
                        -5.727284308999856,
                        57.172796942
                    ],
                    [
                        -5.74233964799987,
                        57.16844310100011
                    ],
                    [
                        -5.782053188999896,
                        57.16624583500011
                    ],
                    [
                        -5.788970506999874,
                        57.159491278000175
                    ],
                    [
                        -5.790760870999861,
                        57.137396552
                    ],
                    [
                        -5.800363735999952,
                        57.123724677000055
                    ],
                    [
                        -5.821441209999875,
                        57.114447333
                    ],
                    [
                        -5.842640753999945,
                        57.10797760600009
                    ],
                    [
                        -5.85220292899993,
                        57.10297272300015
                    ],
                    [
                        -5.860096808999913,
                        57.09536367400001
                    ],
                    [
                        -5.918853318999908,
                        57.05011627800009
                    ],
                    [
                        -5.934925910999908,
                        57.04120514500015
                    ],
                    [
                        -5.953439907999922,
                        57.03412506700009
                    ],
                    [
                        -6.006743943999879,
                        57.02838776200012
                    ],
                    [
                        -6.020822719999927,
                        57.03188711100002
                    ],
                    [
                        -6.030384894999912,
                        57.04490794499999
                    ],
                    [
                        -6.027251756999931,
                        57.06216054900001
                    ],
                    [
                        -6.000233527999882,
                        57.08392975500006
                    ],
                    [
                        -6.003041144999941,
                        57.09271881700012
                    ],
                    [
                        -6.003041144999941,
                        57.09955475500014
                    ],
                    [
                        -5.997059699999909,
                        57.10175202000012
                    ],
                    [
                        -5.994211391999897,
                        57.10455963700018
                    ],
                    [
                        -5.989979620999861,
                        57.113836981000034
                    ],
                    [
                        -5.986927863999881,
                        57.11823151200012
                    ],
                    [
                        -5.980091925999943,
                        57.12311432500009
                    ],
                    [
                        -5.961822068999879,
                        57.142157294000086
                    ],
                    [
                        -5.952788865999878,
                        57.148830471000124
                    ],
                    [
                        -5.94155839799987,
                        57.15477122600008
                    ],
                    [
                        -5.845366990999935,
                        57.18146393400009
                    ],
                    [
                        -5.845366990999935,
                        57.188299872000115
                    ],
                    [
                        -5.872954881999931,
                        57.17999909100003
                    ],
                    [
                        -5.900868292999888,
                        57.17552317900008
                    ],
                    [
                        -5.986236131999931,
                        57.17462799700017
                    ],
                    [
                        -5.991688605999883,
                        57.17719147300009
                    ],
                    [
                        -5.993072068999936,
                        57.183172919000086
                    ],
                    [
                        -5.993316209999904,
                        57.19017161699999
                    ],
                    [
                        -5.995594855999855,
                        57.19574616100009
                    ],
                    [
                        -6.01927649599989,
                        57.21759674700003
                    ],
                    [
                        -6.03221594999988,
                        57.22650788000006
                    ],
                    [
                        -6.044016079999949,
                        57.229925848000065
                    ],
                    [
                        -6.039784308999856,
                        57.21938711100002
                    ],
                    [
                        -6.035389777999853,
                        57.211493231000034
                    ],
                    [
                        -6.023508266999897,
                        57.19574616100009
                    ],
                    [
                        -6.034087693999936,
                        57.181097723000065
                    ],
                    [
                        -6.040842251999891,
                        57.174139716000084
                    ],
                    [
                        -6.050852016999954,
                        57.16844310100011
                    ],
                    [
                        -6.044016079999949,
                        57.16103750200001
                    ],
                    [
                        -6.056263800999915,
                        57.15289948100009
                    ],
                    [
                        -6.064605272999899,
                        57.141750393000095
                    ],
                    [
                        -6.073841925999943,
                        57.13178131700009
                    ],
                    [
                        -6.088612433999856,
                        57.12742747599999
                    ],
                    [
                        -6.101429816999882,
                        57.135199286
                    ],
                    [
                        -6.107167120999861,
                        57.153021552000084
                    ],
                    [
                        -6.111195441999911,
                        57.17328522300009
                    ],
                    [
                        -6.112904425999915,
                        57.188299872000115
                    ],
                    [
                        -6.120961066999939,
                        57.193793036000145
                    ],
                    [
                        -6.126454230999883,
                        57.19521719
                    ],
                    [
                        -6.132069464999887,
                        57.19318268400018
                    ],
                    [
                        -6.140126105999912,
                        57.188299872000115
                    ],
                    [
                        -6.147450324999909,
                        57.19452545800006
                    ],
                    [
                        -6.155588344999927,
                        57.198879299000154
                    ],
                    [
                        -6.164458787999877,
                        57.20136139500009
                    ],
                    [
                        -6.174305792999917,
                        57.201971747000144
                    ],
                    [
                        -6.174305792999917,
                        57.19574616100009
                    ],
                    [
                        -6.159820115999963,
                        57.18764883000016
                    ],
                    [
                        -6.177154100999928,
                        57.18121979400014
                    ],
                    [
                        -6.318348761999886,
                        57.16103750200001
                    ],
                    [
                        -6.318348761999886,
                        57.16844310100011
                    ],
                    [
                        -6.306996222999885,
                        57.17389557500006
                    ],
                    [
                        -6.276722785999908,
                        57.19574616100009
                    ],
                    [
                        -6.276722785999908,
                        57.201971747000144
                    ],
                    [
                        -6.295318162999905,
                        57.20237864800005
                    ],
                    [
                        -6.313099738999881,
                        57.19989655200011
                    ],
                    [
                        -6.329986131999874,
                        57.19501373900006
                    ],
                    [
                        -6.345611131999874,
                        57.188299872000115
                    ],
                    [
                        -6.364735480999855,
                        57.20453522300015
                    ],
                    [
                        -6.379790818999936,
                        57.22308991100006
                    ],
                    [
                        -6.36888587099989,
                        57.22577545800014
                    ],
                    [
                        -6.359486456999946,
                        57.22943756700009
                    ],
                    [
                        -6.351714647999955,
                        57.234808661000145
                    ],
                    [
                        -6.345611131999874,
                        57.242905992000075
                    ],
                    [
                        -6.391428188999953,
                        57.24054596600003
                    ],
                    [
                        -6.408192511999886,
                        57.24335358300006
                    ],
                    [
                        -6.455474412999934,
                        57.27765534100003
                    ],
                    [
                        -6.457997199999852,
                        57.28099192900005
                    ],
                    [
                        -6.462961391999897,
                        57.29043203300007
                    ],
                    [
                        -6.466053839999887,
                        57.29474518400018
                    ],
                    [
                        -6.471180792999888,
                        57.29901764500009
                    ],
                    [
                        -6.47557532499988,
                        57.30141836100013
                    ],
                    [
                        -6.479156053999873,
                        57.304754950000145
                    ],
                    [
                        -6.482167120999861,
                        57.31183502800012
                    ],
                    [
                        -6.436187303999901,
                        57.32973867400007
                    ],
                    [
                        -6.416411912999877,
                        57.33454010600015
                    ],
                    [
                        -6.394032355999855,
                        57.33234284100017
                    ],
                    [
                        -6.376332160999937,
                        57.32379791900014
                    ],
                    [
                        -6.358509894999884,
                        57.31183502800012
                    ],
                    [
                        -6.337635870999918,
                        57.301499742000104
                    ],
                    [
                        -6.310902472999885,
                        57.298163153000175
                    ],
                    [
                        -6.323882615999878,
                        57.31142812700001
                    ],
                    [
                        -6.386586066999882,
                        57.33978913000006
                    ],
                    [
                        -6.399525519999884,
                        57.348456122000144
                    ],
                    [
                        -6.406158006999931,
                        57.35154857000013
                    ],
                    [
                        -6.417632615999906,
                        57.35276927300005
                    ],
                    [
                        -6.428822394999912,
                        57.35150788000014
                    ],
                    [
                        -6.45055091099988,
                        57.34650299700009
                    ],
                    [
                        -6.462961391999897,
                        57.34658437700007
                    ],
                    [
                        -6.448638475999928,
                        57.35276927300005
                    ],
                    [
                        -6.448638475999928,
                        57.36025625200001
                    ],
                    [
                        -6.456613735999952,
                        57.36115143400009
                    ],
                    [
                        -6.475941535999908,
                        57.36709219000012
                    ],
                    [
                        -6.468739386999857,
                        57.3685570330001
                    ],
                    [
                        -6.465809699999852,
                        57.370021877000156
                    ],
                    [
                        -6.462961391999897,
                        57.373928127000156
                    ],
                    [
                        -6.472157355999883,
                        57.37653229400014
                    ],
                    [
                        -6.478871222999914,
                        57.381903387000094
                    ],
                    [
                        -6.480824347999885,
                        57.390082098
                    ],
                    [
                        -6.475941535999908,
                        57.40119049700006
                    ],
                    [
                        -6.490834113999938,
                        57.39899323100015
                    ],
                    [
                        -6.510609503999888,
                        57.385565497000144
                    ],
                    [
                        -6.523793097999914,
                        57.380764065000065
                    ],
                    [
                        -6.518706834999904,
                        57.38955312700013
                    ],
                    [
                        -6.517648891999954,
                        57.39887116100009
                    ],
                    [
                        -6.521229620999918,
                        57.4078636740001
                    ],
                    [
                        -6.530629035999937,
                        57.41547272300012
                    ],
                    [
                        -6.538929816999911,
                        57.40232982
                    ],
                    [
                        -6.550404425999972,
                        57.39801666900017
                    ],
                    [
                        -6.562123175999943,
                        57.39545319200006
                    ],
                    [
                        -6.571522589999887,
                        57.3876000020001
                    ],
                    [
                        -6.573150193999908,
                        57.37612539300012
                    ],
                    [
                        -6.562123175999943,
                        57.35785553600006
                    ],
                    [
                        -6.564116990999906,
                        57.34658437700007
                    ],
                    [
                        -6.580555792999888,
                        57.33881256700009
                    ],
                    [
                        -6.67247473899991,
                        57.35594310100011
                    ],
                    [
                        -6.695952928999873,
                        57.36440664300015
                    ],
                    [
                        -6.714751756999931,
                        57.376613674000126
                    ],
                    [
                        -6.722401495999861,
                        57.39093659100011
                    ],
                    [
                        -6.731678839999916,
                        57.416978257
                    ],
                    [
                        -6.773182745999918,
                        57.434068101000136
                    ],
                    [
                        -6.78384355399993,
                        57.45644765800016
                    ],
                    [
                        -6.722401495999861,
                        57.45644765800016
                    ],
                    [
                        -6.736317511999857,
                        57.465155341000056
                    ],
                    [
                        -6.745228644999884,
                        57.47589752800015
                    ],
                    [
                        -6.747141079999921,
                        57.48794179900007
                    ],
                    [
                        -6.739735480999855,
                        57.50055573100006
                    ],
                    [
                        -6.726307745999861,
                        57.51141998900012
                    ],
                    [
                        -6.715931769999941,
                        57.514227606000176
                    ],
                    [
                        -6.706450975999871,
                        57.51093170800014
                    ],
                    [
                        -6.653553839999887,
                        57.46869538000011
                    ],
                    [
                        -6.647328253999945,
                        57.459540106000034
                    ],
                    [
                        -6.638091600999871,
                        57.4532738300001
                    ],
                    [
                        -6.620025193999936,
                        57.44550202000009
                    ],
                    [
                        -6.606800910999908,
                        57.44391510600015
                    ],
                    [
                        -6.612538214999887,
                        57.45644765800016
                    ],
                    [
                        -6.612538214999887,
                        57.46263255400011
                    ],
                    [
                        -6.608265753999888,
                        57.46458567900008
                    ],
                    [
                        -6.60415605399993,
                        57.46775950700014
                    ],
                    [
                        -6.599476691999911,
                        57.469427802000055
                    ],
                    [
                        -6.616688605999912,
                        57.480902411
                    ],
                    [
                        -6.61937415299991,
                        57.483099677
                    ],
                    [
                        -6.622222459999932,
                        57.48639557500003
                    ],
                    [
                        -6.631255662999962,
                        57.49359772300015
                    ],
                    [
                        -6.633046027999853,
                        57.49746328300016
                    ],
                    [
                        -6.630604620999918,
                        57.50177643400015
                    ],
                    [
                        -6.621408657999922,
                        57.503851630000085
                    ],
                    [
                        -6.61937415299991,
                        57.50731028899999
                    ],
                    [
                        -6.611439581999946,
                        57.51219310100005
                    ],
                    [
                        -6.593617316999882,
                        57.510972398000135
                    ],
                    [
                        -6.564116990999906,
                        57.503607489000146
                    ],
                    [
                        -6.573963995999918,
                        57.521714585000055
                    ],
                    [
                        -6.599354620999947,
                        57.53754303600009
                    ],
                    [
                        -6.62987219999988,
                        57.548529364000146
                    ],
                    [
                        -6.654774542999917,
                        57.55206940300003
                    ],
                    [
                        -6.64159094999988,
                        57.56195709800012
                    ],
                    [
                        -6.631011522999899,
                        57.5768089860001
                    ],
                    [
                        -6.628895636999914,
                        57.591131903000175
                    ],
                    [
                        -6.640451626999919,
                        57.59979889500009
                    ],
                    [
                        -6.640451626999919,
                        57.60732656500012
                    ],
                    [
                        -6.619781053999901,
                        57.60716380400008
                    ],
                    [
                        -6.605620897999899,
                        57.59955475500014
                    ],
                    [
                        -6.594309048999918,
                        57.59052155200011
                    ],
                    [
                        -6.572255011999914,
                        57.58250560099999
                    ],
                    [
                        -6.568430141999897,
                        57.574286200000174
                    ],
                    [
                        -6.56688391799986,
                        57.56509023600002
                    ],
                    [
                        -6.564116990999906,
                        57.558823960000055
                    ],
                    [
                        -6.554595506999903,
                        57.55247630400014
                    ],
                    [
                        -6.547759568999879,
                        57.549546617000104
                    ],
                    [
                        -6.501210089999859,
                        57.53554922100015
                    ],
                    [
                        -6.49038652299987,
                        57.53025950700008
                    ],
                    [
                        -6.47907467399989,
                        57.5209821640001
                    ],
                    [
                        -6.464914516999954,
                        57.51251862200017
                    ],
                    [
                        -6.451649542999917,
                        57.5133324240001
                    ],
                    [
                        -6.43765214799987,
                        57.5172386740001
                    ],
                    [
                        -6.420765753999973,
                        57.51788971600003
                    ],
                    [
                        -6.443470831999917,
                        57.488104559000035
                    ],
                    [
                        -6.433501756999931,
                        57.486476955000015
                    ],
                    [
                        -6.412098761999886,
                        57.50136953300016
                    ],
                    [
                        -6.400257941999911,
                        57.5209821640001
                    ],
                    [
                        -6.386952277999882,
                        57.51727936400009
                    ],
                    [
                        -6.310902472999885,
                        57.46263255400011
                    ],
                    [
                        -6.310902472999885,
                        57.469427802000055
                    ],
                    [
                        -6.31578528599988,
                        57.47280508000007
                    ],
                    [
                        -6.320952928999873,
                        57.479315497000144
                    ],
                    [
                        -6.32518469999988,
                        57.483099677
                    ],
                    [
                        -6.324615037999905,
                        57.496812242000104
                    ],
                    [
                        -6.338531053999901,
                        57.50946686400009
                    ],
                    [
                        -6.372954881999931,
                        57.53156159100017
                    ],
                    [
                        -6.389556443999879,
                        57.55247630400014
                    ],
                    [
                        -6.385853644999941,
                        57.56264883000007
                    ],
                    [
                        -6.372425910999937,
                        57.57257721600017
                    ],
                    [
                        -6.35985266799986,
                        57.59296295800006
                    ],
                    [
                        -6.371937628999973,
                        57.59186432500012
                    ],
                    [
                        -6.383371548999918,
                        57.592922268000066
                    ],
                    [
                        -6.393137173999946,
                        57.59564850500014
                    ],
                    [
                        -6.400257941999911,
                        57.59979889500009
                    ],
                    [
                        -6.395863410999937,
                        57.613470770000035
                    ],
                    [
                        -6.401112433999913,
                        57.621527411000145
                    ],
                    [
                        -6.40953528599988,
                        57.62734609600001
                    ],
                    [
                        -6.414540167999917,
                        57.63397858300006
                    ],
                    [
                        -6.415679490999935,
                        57.64581940300003
                    ],
                    [
                        -6.41344153599988,
                        57.64923737200003
                    ],
                    [
                        -6.406605597999942,
                        57.650336005000085
                    ],
                    [
                        -6.366566535999908,
                        57.66425202000006
                    ],
                    [
                        -6.35985266799986,
                        57.66811758000007
                    ],
                    [
                        -6.351185675999886,
                        57.680121161
                    ],
                    [
                        -6.345285610999923,
                        57.69220612200009
                    ],
                    [
                        -6.337961391999926,
                        57.699164130000085
                    ],
                    [
                        -6.32518469999988,
                        57.69599030200011
                    ],
                    [
                        -6.317494269999884,
                        57.697699286000116
                    ],
                    [
                        -6.314279751999919,
                        57.699164130000085
                    ],
                    [
                        -6.310902472999885,
                        57.70282623900012
                    ]
                ]
            ],
            [
                [
                    [
                        -7.170033331999974,
                        57.715155341000084
                    ],
                    [
                        -7.19896399599989,
                        57.70795319200015
                    ],
                    [
                        -7.222645636999886,
                        57.709662177000055
                    ],
                    [
                        -7.180165167999917,
                        57.73468659100014
                    ],
                    [
                        -7.161203579999921,
                        57.73700592700011
                    ],
                    [
                        -7.152007615999935,
                        57.726304429000024
                    ],
                    [
                        -7.170033331999974,
                        57.715155341000084
                    ]
                ]
            ],
            [
                [
                    [
                        -8.56895911399991,
                        57.807562567
                    ],
                    [
                        -8.590891079999949,
                        57.804754950000145
                    ],
                    [
                        -8.61070716099988,
                        57.81273021
                    ],
                    [
                        -8.621001756999874,
                        57.83319733300006
                    ],
                    [
                        -8.60529537699992,
                        57.82640208500014
                    ],
                    [
                        -8.569081183999884,
                        57.823675848000065
                    ],
                    [
                        -8.552235480999883,
                        57.81891510600015
                    ],
                    [
                        -8.56895911399991,
                        57.807562567
                    ]
                ]
            ],
            [
                [
                    [
                        -6.997385219999927,
                        57.915106512000094
                    ],
                    [
                        -6.997059699999909,
                        57.893540757
                    ],
                    [
                        -7.0091853509999,
                        57.88959381700003
                    ],
                    [
                        -7.044585740999906,
                        57.90208567900011
                    ],
                    [
                        -7.048085089999887,
                        57.89081452000012
                    ],
                    [
                        -7.05516516799986,
                        57.88418203300016
                    ],
                    [
                        -7.065500454999949,
                        57.88104889500009
                    ],
                    [
                        -7.079335089999859,
                        57.880316473000065
                    ],
                    [
                        -7.077056443999879,
                        57.88861725500014
                    ],
                    [
                        -7.076771613999938,
                        57.890692450000145
                    ],
                    [
                        -7.07787024599989,
                        57.89118073100006
                    ],
                    [
                        -7.079335089999859,
                        57.89468008000013
                    ],
                    [
                        -7.037749803999873,
                        57.91632721600003
                    ],
                    [
                        -7.015533006999931,
                        57.92194245000003
                    ],
                    [
                        -6.997385219999927,
                        57.915106512000094
                    ]
                ]
            ],
            [
                [
                    [
                        -6.861154751999948,
                        58.25336334800012
                    ],
                    [
                        -6.828928188999896,
                        58.23379140800016
                    ],
                    [
                        -6.81859290299991,
                        58.23725006700006
                    ],
                    [
                        -6.809641079999977,
                        58.23224518400012
                    ],
                    [
                        -6.801991339999859,
                        58.22614166900003
                    ],
                    [
                        -6.795765753999888,
                        58.21881745000003
                    ],
                    [
                        -6.791249152999939,
                        58.20994700700008
                    ],
                    [
                        -6.873280402999853,
                        58.20994700700008
                    ],
                    [
                        -6.873280402999853,
                        58.21678294499999
                    ],
                    [
                        -6.862863735999952,
                        58.21645742400007
                    ],
                    [
                        -6.85960852799991,
                        58.21678294499999
                    ],
                    [
                        -6.85960852799991,
                        58.223049221000124
                    ],
                    [
                        -6.872222459999904,
                        58.22679271000008
                    ],
                    [
                        -6.882313605999855,
                        58.236273505000085
                    ],
                    [
                        -6.886219855999855,
                        58.24762604400014
                    ],
                    [
                        -6.880034959999875,
                        58.25775788000011
                    ],
                    [
                        -6.861154751999948,
                        58.25336334800012
                    ]
                ]
            ],
            [
                [
                    [
                        -6.195546027999882,
                        58.356268622000115
                    ],
                    [
                        -6.182240363999881,
                        58.353664455000015
                    ],
                    [
                        -6.167469855999883,
                        58.353949286000145
                    ],
                    [
                        -6.167469855999883,
                        58.34650299700017
                    ],
                    [
                        -6.188832160999937,
                        58.340806382
                    ],
                    [
                        -6.233713344999956,
                        58.32282135600015
                    ],
                    [
                        -6.249989386999886,
                        58.312974351000136
                    ],
                    [
                        -6.244984503999945,
                        58.304144598000065
                    ],
                    [
                        -6.246408657999922,
                        58.297552802
                    ],
                    [
                        -6.253814256999903,
                        58.29336172100007
                    ],
                    [
                        -6.266835089999887,
                        58.29189687700001
                    ],
                    [
                        -6.278675910999937,
                        58.288723049000126
                    ],
                    [
                        -6.288238084999932,
                        58.274603583000115
                    ],
                    [
                        -6.317860480999883,
                        58.268296617000075
                    ],
                    [
                        -6.319935675999915,
                        58.260931708000086
                    ],
                    [
                        -6.31696529899989,
                        58.252142645
                    ],
                    [
                        -6.318348761999886,
                        58.244696356000034
                    ],
                    [
                        -6.327463344999956,
                        58.23973216399999
                    ],
                    [
                        -6.33849036399991,
                        58.237616278000175
                    ],
                    [
                        -6.366118943999908,
                        58.23725006700006
                    ],
                    [
                        -6.347727016999954,
                        58.22113678600009
                    ],
                    [
                        -6.310861782999893,
                        58.21503327
                    ],
                    [
                        -6.270904100999957,
                        58.21649811400006
                    ],
                    [
                        -6.243234829999949,
                        58.223049221000124
                    ],
                    [
                        -6.194813605999855,
                        58.250921942
                    ],
                    [
                        -6.179758266999926,
                        58.25556061400012
                    ],
                    [
                        -6.161122199999852,
                        58.258978583000115
                    ],
                    [
                        -6.15070553299995,
                        58.25836823100015
                    ],
                    [
                        -6.160023566999911,
                        58.250921942
                    ],
                    [
                        -6.160023566999911,
                        58.244696356000034
                    ],
                    [
                        -6.153879360999923,
                        58.244696356000034
                    ],
                    [
                        -6.158558722999942,
                        58.23981354400014
                    ],
                    [
                        -6.163319464999859,
                        58.233587958
                    ],
                    [
                        -6.167469855999883,
                        58.23041413000014
                    ],
                    [
                        -6.164865688999896,
                        58.22675202000009
                    ],
                    [
                        -6.160023566999911,
                        58.21678294499999
                    ],
                    [
                        -6.186431443999908,
                        58.20990631700009
                    ],
                    [
                        -6.195220506999874,
                        58.206488348000065
                    ],
                    [
                        -6.20213782499988,
                        58.20197174700003
                    ],
                    [
                        -6.211659308999856,
                        58.192287502000156
                    ],
                    [
                        -6.215321417999917,
                        58.189439195000105
                    ],
                    [
                        -6.233550584999904,
                        58.18427155200011
                    ],
                    [
                        -6.24742591099988,
                        58.18545156500015
                    ],
                    [
                        -6.276722785999908,
                        58.19627513200014
                    ],
                    [
                        -6.29356848899991,
                        58.19936758000004
                    ],
                    [
                        -6.338205532999979,
                        58.19627513200014
                    ],
                    [
                        -6.3548884759999,
                        58.19936758000004
                    ],
                    [
                        -6.384673631999931,
                        58.20970286700013
                    ],
                    [
                        -6.400257941999911,
                        58.20994700700008
                    ],
                    [
                        -6.377023891999897,
                        58.173529364
                    ],
                    [
                        -6.366118943999908,
                        58.16209544500005
                    ],
                    [
                        -6.374501105999883,
                        58.157660223000065
                    ],
                    [
                        -6.378041144999941,
                        58.15363190300012
                    ],
                    [
                        -6.377268032999922,
                        58.14862702000006
                    ],
                    [
                        -6.372954881999931,
                        58.141058661000116
                    ],
                    [
                        -6.390695766999926,
                        58.133530992000075
                    ],
                    [
                        -6.441232876999919,
                        58.12738678600009
                    ],
                    [
                        -6.474720831999889,
                        58.10521067900011
                    ],
                    [
                        -6.489654100999928,
                        58.10008372600002
                    ],
                    [
                        -6.499094204999949,
                        58.10040924700003
                    ],
                    [
                        -6.514393683999913,
                        58.106146552
                    ],
                    [
                        -6.523793097999914,
                        58.10687897300012
                    ],
                    [
                        -6.551096157999893,
                        58.09324778899999
                    ],
                    [
                        -6.603179490999935,
                        58.085638739000146
                    ],
                    [
                        -6.61937415299991,
                        58.08641185100008
                    ],
                    [
                        -6.61937415299991,
                        58.079575914000046
                    ],
                    [
                        -6.581288214999916,
                        58.07900625200007
                    ],
                    [
                        -6.451324022999899,
                        58.095689195000105
                    ],
                    [
                        -6.420643683999884,
                        58.10565827
                    ],
                    [
                        -6.407093878999916,
                        58.10687897300012
                    ],
                    [
                        -6.394032355999855,
                        58.101792710000026
                    ],
                    [
                        -6.383900519999912,
                        58.091782945000105
                    ],
                    [
                        -6.378570115999935,
                        58.07904694200006
                    ],
                    [
                        -6.379790818999936,
                        58.065985419000086
                    ],
                    [
                        -6.375721808999884,
                        58.06427643400018
                    ],
                    [
                        -6.366118943999908,
                        58.05853913000011
                    ],
                    [
                        -6.373687303999873,
                        58.052923895000035
                    ],
                    [
                        -6.381906704999949,
                        58.048895575000145
                    ],
                    [
                        -6.390695766999926,
                        58.04637278900013
                    ],
                    [
                        -6.400257941999911,
                        58.045477606000034
                    ],
                    [
                        -6.389475063999896,
                        58.04120514500012
                    ],
                    [
                        -6.377674933999856,
                        58.038763739
                    ],
                    [
                        -6.365101691999939,
                        58.03790924700009
                    ],
                    [
                        -6.352447068999879,
                        58.03864166900003
                    ],
                    [
                        -6.370961066999882,
                        58.02724844000015
                    ],
                    [
                        -6.385080532999922,
                        58.01593659100017
                    ],
                    [
                        -6.400868292999888,
                        58.007310289000074
                    ],
                    [
                        -6.424468553999901,
                        58.00389232000008
                    ],
                    [
                        -6.518218553999901,
                        58.01264069200012
                    ],
                    [
                        -6.539296027999853,
                        58.01727936400006
                    ],
                    [
                        -6.544911261999857,
                        58.01752350500003
                    ],
                    [
                        -6.550689256999903,
                        58.01007721600003
                    ],
                    [
                        -6.542225714999859,
                        58.005682684000064
                    ],
                    [
                        -6.520741339999916,
                        58.00079987200017
                    ],
                    [
                        -6.505116339999859,
                        57.992499091000084
                    ],
                    [
                        -6.469634568999965,
                        57.98688385600009
                    ],
                    [
                        -6.455474412999934,
                        57.97654857
                    ],
                    [
                        -6.455474412999934,
                        57.96971263200008
                    ],
                    [
                        -6.464995897999927,
                        57.965643622000115
                    ],
                    [
                        -6.481190558999884,
                        57.949774481000176
                    ],
                    [
                        -6.489654100999928,
                        57.943060614000146
                    ],
                    [
                        -6.50755774599989,
                        57.93598053600009
                    ],
                    [
                        -6.520130988999881,
                        57.93659088700004
                    ],
                    [
                        -6.532866990999935,
                        57.94041575700005
                    ],
                    [
                        -6.551096157999893,
                        57.943060614000146
                    ],
                    [
                        -6.551096157999893,
                        57.935614325000145
                    ],
                    [
                        -6.546701626999919,
                        57.93382396000008
                    ],
                    [
                        -6.541981574999909,
                        57.93085358300006
                    ],
                    [
                        -6.537464972999942,
                        57.92877838700004
                    ],
                    [
                        -6.537464972999942,
                        57.921332098000065
                    ],
                    [
                        -6.559803839999887,
                        57.915228583000086
                    ],
                    [
                        -6.578439907999893,
                        57.917222398000106
                    ],
                    [
                        -6.593861456999917,
                        57.924872137000065
                    ],
                    [
                        -6.606353318999908,
                        57.935614325000145
                    ],
                    [
                        -6.614979620999918,
                        57.94916413000011
                    ],
                    [
                        -6.620472785999937,
                        57.95453522300009
                    ],
                    [
                        -6.62987219999988,
                        57.95673248900009
                    ],
                    [
                        -6.634999152999882,
                        57.95160553600006
                    ],
                    [
                        -6.629628058999941,
                        57.94025299700009
                    ],
                    [
                        -6.620025193999936,
                        57.92816803600009
                    ],
                    [
                        -6.612538214999887,
                        57.921332098000065
                    ],
                    [
                        -6.61937415299991,
                        57.915106512000094
                    ],
                    [
                        -6.651193813999925,
                        57.92381419499999
                    ],
                    [
                        -6.660959438999896,
                        57.92877838700004
                    ],
                    [
                        -6.66616777299987,
                        57.93390534100014
                    ],
                    [
                        -6.672434048999918,
                        57.94619375200001
                    ],
                    [
                        -6.699818488999881,
                        57.97622304900007
                    ],
                    [
                        -6.707753058999856,
                        57.989569403000175
                    ],
                    [
                        -6.709380662999877,
                        58.00389232000008
                    ],
                    [
                        -6.694650844999899,
                        58.03009674700009
                    ],
                    [
                        -6.665842251999948,
                        58.04466380400011
                    ],
                    [
                        -6.629465298999975,
                        58.05093008000007
                    ],
                    [
                        -6.592030402999853,
                        58.05231354400014
                    ],
                    [
                        -6.592030402999853,
                        58.05853913000011
                    ],
                    [
                        -6.688303188999953,
                        58.05853913000011
                    ],
                    [
                        -6.688303188999953,
                        58.05231354400014
                    ],
                    [
                        -6.692697719999927,
                        58.040961005000085
                    ],
                    [
                        -6.716053839999916,
                        58.024969794000086
                    ],
                    [
                        -6.744496222999942,
                        58.01048411700013
                    ],
                    [
                        -6.764027472999914,
                        58.00389232000008
                    ],
                    [
                        -6.750884568999879,
                        57.99298737200009
                    ],
                    [
                        -6.730091925999886,
                        57.96295807500003
                    ],
                    [
                        -6.718983527999853,
                        57.95673248900009
                    ],
                    [
                        -6.704741990999935,
                        57.95319245000009
                    ],
                    [
                        -6.693226691999911,
                        57.94505442900008
                    ],
                    [
                        -6.683420376999891,
                        57.93573639500012
                    ],
                    [
                        -6.67398027299987,
                        57.92877838700004
                    ],
                    [
                        -6.679351365999935,
                        57.926825262000094
                    ],
                    [
                        -6.683461066999882,
                        57.92422109600001
                    ],
                    [
                        -6.688465949999909,
                        57.92206452
                    ],
                    [
                        -6.695668097999942,
                        57.921332098000065
                    ],
                    [
                        -6.688954230999912,
                        57.91494375200013
                    ],
                    [
                        -6.680327928999901,
                        57.91205475500011
                    ],
                    [
                        -6.67235266799986,
                        57.91058991100006
                    ],
                    [
                        -6.667795376999891,
                        57.90827057500009
                    ],
                    [
                        -6.665150519999912,
                        57.90155670800014
                    ],
                    [
                        -6.660959438999896,
                        57.880316473000065
                    ],
                    [
                        -6.742909308999913,
                        57.891262111000046
                    ],
                    [
                        -6.777658657999979,
                        57.90208567900011
                    ],
                    [
                        -6.787017381999903,
                        57.89883047100007
                    ],
                    [
                        -6.792388475999871,
                        57.89374420800006
                    ],
                    [
                        -6.797352667999917,
                        57.88739655200011
                    ],
                    [
                        -6.805572068999908,
                        57.880316473000065
                    ],
                    [
                        -6.794260219999927,
                        57.876206773000106
                    ],
                    [
                        -6.7728572259999,
                        57.87124258000016
                    ],
                    [
                        -6.764027472999914,
                        57.86790599200013
                    ],
                    [
                        -6.740712042999917,
                        57.84503815300003
                    ],
                    [
                        -6.736683722999942,
                        57.83942291900003
                    ],
                    [
                        -6.741932745999861,
                        57.831488348000065
                    ],
                    [
                        -6.75560462099989,
                        57.82648346600003
                    ],
                    [
                        -6.773508266999926,
                        57.82461172100015
                    ],
                    [
                        -6.791249152999939,
                        57.825751044000086
                    ],
                    [
                        -6.791249152999939,
                        57.81891510600015
                    ],
                    [
                        -6.78384355399993,
                        57.81891510600015
                    ],
                    [
                        -6.78384355399993,
                        57.81268952
                    ],
                    [
                        -6.806141730999883,
                        57.81464264500006
                    ],
                    [
                        -6.852691209999904,
                        57.83319733300006
                    ],
                    [
                        -6.85960852799991,
                        57.825751044000086
                    ],
                    [
                        -6.854156053999873,
                        57.823431708000115
                    ],
                    [
                        -6.845855272999899,
                        57.81639232000005
                    ],
                    [
                        -6.839100714999859,
                        57.81268952
                    ],
                    [
                        -6.85016842399989,
                        57.80292389500006
                    ],
                    [
                        -6.861154751999948,
                        57.796332098
                    ],
                    [
                        -6.887521938999953,
                        57.784816799000154
                    ],
                    [
                        -6.882720506999874,
                        57.78286367400001
                    ],
                    [
                        -6.877837693999879,
                        57.77985260600012
                    ],
                    [
                        -6.873280402999853,
                        57.77798086100013
                    ],
                    [
                        -6.893950975999871,
                        57.76654694200009
                    ],
                    [
                        -6.955148891999897,
                        57.743841864000146
                    ],
                    [
                        -6.973500128999945,
                        57.73334381700006
                    ],
                    [
                        -6.98078365799995,
                        57.735052802000084
                    ],
                    [
                        -7.031646287999905,
                        57.76740143400009
                    ],
                    [
                        -7.041371222999942,
                        57.77049388200008
                    ],
                    [
                        -7.10187740799995,
                        57.811468817
                    ],
                    [
                        -7.112782355999912,
                        57.815863348000065
                    ],
                    [
                        -7.117298956999889,
                        57.823065497000115
                    ],
                    [
                        -7.124623175999886,
                        57.83075592700011
                    ],
                    [
                        -7.125070766999954,
                        57.83690013200011
                    ],
                    [
                        -7.109445766999897,
                        57.83942291900003
                    ],
                    [
                        -7.096994594999956,
                        57.83803945500013
                    ],
                    [
                        -7.088612433999913,
                        57.833400783000016
                    ],
                    [
                        -7.083241339999859,
                        57.82510000200013
                    ],
                    [
                        -7.079335089999859,
                        57.81268952
                    ],
                    [
                        -6.997385219999927,
                        57.85370514500012
                    ],
                    [
                        -6.984852667999917,
                        57.864894924000126
                    ],
                    [
                        -6.962269660999937,
                        57.86884186400012
                    ],
                    [
                        -6.914173956999889,
                        57.86790599200013
                    ],
                    [
                        -6.962554490999878,
                        57.88784414300004
                    ],
                    [
                        -6.962554490999878,
                        57.89468008000013
                    ],
                    [
                        -6.911610480999883,
                        57.91058991100006
                    ],
                    [
                        -6.881703253999888,
                        57.91429271
                    ],
                    [
                        -6.851877407999893,
                        57.91058991100006
                    ],
                    [
                        -6.832264777999853,
                        57.90208567900011
                    ],
                    [
                        -6.841867641999926,
                        57.90704987200017
                    ],
                    [
                        -6.851551886999886,
                        57.911403713000155
                    ],
                    [
                        -6.85960852799991,
                        57.915228583000086
                    ],
                    [
                        -6.862945115999935,
                        57.91803620000003
                    ],
                    [
                        -6.865834113999881,
                        57.921332098000065
                    ],
                    [
                        -6.846994594999927,
                        57.92792389500015
                    ],
                    [
                        -6.839100714999859,
                        57.92877838700004
                    ],
                    [
                        -6.839100714999859,
                        57.935614325000145
                    ],
                    [
                        -6.897084113999938,
                        57.935614325000145
                    ],
                    [
                        -6.910796678999873,
                        57.938910223
                    ],
                    [
                        -6.937001105999912,
                        57.953436591000056
                    ],
                    [
                        -6.952056443999908,
                        57.95673248900009
                    ],
                    [
                        -7.009755011999857,
                        57.955267645000035
                    ],
                    [
                        -7.036284959999932,
                        57.96063873900006
                    ],
                    [
                        -7.044585740999906,
                        57.97654857
                    ],
                    [
                        -7.057281053999873,
                        57.970851955000015
                    ],
                    [
                        -7.0658259759999,
                        57.97101471600017
                    ],
                    [
                        -7.112782355999912,
                        57.99022044500013
                    ],
                    [
                        -7.096669074999852,
                        57.99746328300013
                    ],
                    [
                        -7.084828253999888,
                        58.00592682500009
                    ],
                    [
                        -7.071766730999912,
                        58.01190827
                    ],
                    [
                        -7.051909959999904,
                        58.011379299000126
                    ],
                    [
                        -7.051909959999904,
                        58.01752350500003
                    ],
                    [
                        -7.05492102799991,
                        58.01976146
                    ],
                    [
                        -7.055653449999852,
                        58.020697333000086
                    ],
                    [
                        -7.056141730999912,
                        58.02195872599999
                    ],
                    [
                        -7.058216925999943,
                        58.024969794000086
                    ],
                    [
                        -7.027902798999946,
                        58.02753327000009
                    ],
                    [
                        -6.995228644999912,
                        58.03375885600015
                    ],
                    [
                        -6.934641079999949,
                        58.05231354400014
                    ],
                    [
                        -7.028716600999871,
                        58.038397528000175
                    ],
                    [
                        -7.058216925999943,
                        58.03864166900003
                    ],
                    [
                        -7.053618943999908,
                        58.047796942
                    ],
                    [
                        -7.045765753999916,
                        58.05263906500009
                    ],
                    [
                        -7.035552537999905,
                        58.053900458
                    ],
                    [
                        -7.024037238999938,
                        58.05231354400014
                    ],
                    [
                        -7.024037238999938,
                        58.05853913000011
                    ],
                    [
                        -7.051909959999904,
                        58.05853913000011
                    ],
                    [
                        -7.042795376999919,
                        58.06183502800015
                    ],
                    [
                        -7.035023566999911,
                        58.06635163000003
                    ],
                    [
                        -7.028716600999871,
                        58.07221100500006
                    ],
                    [
                        -7.024037238999938,
                        58.079575914000046
                    ],
                    [
                        -7.067494269999912,
                        58.06273021000014
                    ],
                    [
                        -7.075550910999937,
                        58.06224192900005
                    ],
                    [
                        -7.104888475999928,
                        58.09479401200012
                    ],
                    [
                        -7.112782355999912,
                        58.10008372600002
                    ],
                    [
                        -7.107533331999917,
                        58.11200592700014
                    ],
                    [
                        -7.113189256999931,
                        58.116400458000115
                    ],
                    [
                        -7.121937628999888,
                        58.118597723000036
                    ],
                    [
                        -7.126454230999855,
                        58.12396881700006
                    ],
                    [
                        -7.123402472999942,
                        58.13666413000014
                    ],
                    [
                        -7.112131313999953,
                        58.145453192
                    ],
                    [
                        -7.112782355999912,
                        58.15534088700012
                    ],
                    [
                        -7.104156053999901,
                        58.157049872000115
                    ],
                    [
                        -7.100168423999946,
                        58.15940989800008
                    ],
                    [
                        -7.097645636999914,
                        58.16303131700012
                    ],
                    [
                        -7.093006964999887,
                        58.168402411
                    ],
                    [
                        -7.10024980399993,
                        58.170111395
                    ],
                    [
                        -7.107411261999857,
                        58.17413971600014
                    ],
                    [
                        -7.112782355999912,
                        58.175848700000145
                    ],
                    [
                        -7.112782355999912,
                        58.182033596000124
                    ],
                    [
                        -7.037709113999881,
                        58.18463776200004
                    ],
                    [
                        -7.017201300999943,
                        58.189439195000105
                    ],
                    [
                        -7.04344641799986,
                        58.19594961100002
                    ],
                    [
                        -7.053944464999944,
                        58.20136139500006
                    ],
                    [
                        -7.058216925999943,
                        58.21336497599999
                    ],
                    [
                        -7.056060350999928,
                        58.22565338700004
                    ],
                    [
                        -7.050160285999908,
                        58.232367255
                    ],
                    [
                        -7.030873175999886,
                        58.244696356000034
                    ],
                    [
                        -7.016102667999888,
                        58.23859284100014
                    ],
                    [
                        -6.977284308999884,
                        58.227362372000144
                    ],
                    [
                        -6.969471808999884,
                        58.22675202000009
                    ],
                    [
                        -6.962025519999912,
                        58.230169989
                    ],
                    [
                        -6.907338019999969,
                        58.21678294499999
                    ],
                    [
                        -6.907338019999969,
                        58.20994700700008
                    ],
                    [
                        -6.937082485999895,
                        58.21100495000003
                    ],
                    [
                        -6.930409308999913,
                        58.196844794000114
                    ],
                    [
                        -6.909657355999912,
                        58.179754950000145
                    ],
                    [
                        -6.897084113999938,
                        58.17210521000014
                    ],
                    [
                        -6.898345506999931,
                        58.15672435099999
                    ],
                    [
                        -6.888050910999937,
                        58.135891018
                    ],
                    [
                        -6.872873501999948,
                        58.11688873900003
                    ],
                    [
                        -6.85960852799991,
                        58.10687897300012
                    ],
                    [
                        -6.861439581999889,
                        58.11587148600013
                    ],
                    [
                        -6.86188717399989,
                        58.12140534100017
                    ],
                    [
                        -6.85960852799991,
                        58.134833075000145
                    ],
                    [
                        -6.8666072259999,
                        58.14118073100009
                    ],
                    [
                        -6.881581183999913,
                        58.158636786000145
                    ],
                    [
                        -6.887521938999953,
                        58.168402411
                    ],
                    [
                        -6.877512173999946,
                        58.17218659100011
                    ],
                    [
                        -6.873931443999908,
                        58.179388739000146
                    ],
                    [
                        -6.872670050999886,
                        58.18634674700003
                    ],
                    [
                        -6.869496222999914,
                        58.189439195000105
                    ],
                    [
                        -6.825062628999916,
                        58.195379950000145
                    ],
                    [
                        -6.815174933999884,
                        58.19969310100005
                    ],
                    [
                        -6.798451300999943,
                        58.202378648000135
                    ],
                    [
                        -6.772572394999941,
                        58.19550202000012
                    ],
                    [
                        -6.748524542999917,
                        58.18292877800003
                    ],
                    [
                        -6.736683722999942,
                        58.168402411
                    ],
                    [
                        -6.709380662999877,
                        58.189439195000105
                    ],
                    [
                        -6.734364386999886,
                        58.19769928600003
                    ],
                    [
                        -6.742909308999913,
                        58.20311107000005
                    ],
                    [
                        -6.746815558999913,
                        58.20921458500014
                    ],
                    [
                        -6.752552863999881,
                        58.22479889500012
                    ],
                    [
                        -6.757191535999908,
                        58.23041413000014
                    ],
                    [
                        -6.763050910999937,
                        58.23167552300005
                    ],
                    [
                        -6.777821417999945,
                        58.22947825700005
                    ],
                    [
                        -6.78384355399993,
                        58.23041413000014
                    ],
                    [
                        -6.785715298999918,
                        58.23297760600006
                    ],
                    [
                        -6.789540167999917,
                        58.24201080900015
                    ],
                    [
                        -6.791249152999939,
                        58.244696356000034
                    ],
                    [
                        -6.802723761999886,
                        58.253729559000035
                    ],
                    [
                        -6.807687954999921,
                        58.2586937520001
                    ],
                    [
                        -6.811838344999956,
                        58.26459381700012
                    ],
                    [
                        -6.806752081999946,
                        58.26654694200009
                    ],
                    [
                        -6.802723761999886,
                        58.268988348
                    ],
                    [
                        -6.798003709999875,
                        58.270982164000046
                    ],
                    [
                        -6.791249152999939,
                        58.27138906500015
                    ],
                    [
                        -6.796457485999923,
                        58.278631903000175
                    ],
                    [
                        -6.801177537999934,
                        58.2833519550001
                    ],
                    [
                        -6.807728644999912,
                        58.285467841000084
                    ],
                    [
                        -6.81859290299991,
                        58.285101630000085
                    ],
                    [
                        -6.736968553999901,
                        58.32363515800016
                    ],
                    [
                        -6.715565558999856,
                        58.33966705900015
                    ],
                    [
                        -6.703358527999882,
                        58.33588288000011
                    ],
                    [
                        -6.695952928999873,
                        58.338446356000034
                    ],
                    [
                        -6.689686652999853,
                        58.343329169000114
                    ],
                    [
                        -6.681385870999861,
                        58.34650299700017
                    ],
                    [
                        -6.647328253999945,
                        58.34650299700017
                    ],
                    [
                        -6.620676235999895,
                        58.350734768000095
                    ],
                    [
                        -6.557484503999916,
                        58.36855703300007
                    ],
                    [
                        -6.548491990999906,
                        58.372788804
                    ],
                    [
                        -6.523345506999931,
                        58.39077383000013
                    ],
                    [
                        -6.345611131999874,
                        58.46320221600017
                    ],
                    [
                        -6.296294725999871,
                        58.497259833000086
                    ],
                    [
                        -6.267404751999948,
                        58.51203034100017
                    ],
                    [
                        -6.243234829999949,
                        58.510972398000106
                    ],
                    [
                        -6.223052537999934,
                        58.499212958000115
                    ],
                    [
                        -6.205393032999922,
                        58.48126862200009
                    ],
                    [
                        -6.192738410999937,
                        58.46206289300012
                    ],
                    [
                        -6.18797766799986,
                        58.44611237200003
                    ],
                    [
                        -6.169992641999897,
                        58.42999909100014
                    ],
                    [
                        -6.166859503999916,
                        58.41950104400017
                    ],
                    [
                        -6.184925910999937,
                        58.414780992000075
                    ],
                    [
                        -6.192372199999909,
                        58.40912506700009
                    ],
                    [
                        -6.215321417999917,
                        58.36762116100009
                    ],
                    [
                        -6.206898566999968,
                        58.36115143400018
                    ],
                    [
                        -6.195546027999882,
                        58.356268622000115
                    ]
                ]
            ],
            [
                [
                    [
                        -3.34788977799991,
                        58.65204498900006
                    ],
                    [
                        -3.340565558999913,
                        58.64813873900006
                    ],
                    [
                        -3.309885219999899,
                        58.64813873900006
                    ],
                    [
                        -3.244252081999889,
                        58.659002997000144
                    ],
                    [
                        -3.20409094999988,
                        58.66111888200005
                    ],
                    [
                        -3.151926235999952,
                        58.64126211100016
                    ],
                    [
                        -3.118763800999915,
                        58.64109935099999
                    ],
                    [
                        -3.05288652299987,
                        58.64813873900006
                    ],
                    [
                        -3.019113735999895,
                        58.64032623900009
                    ],
                    [
                        -3.025380011999857,
                        58.62213776200018
                    ],
                    [
                        -3.044422980999855,
                        58.60162995000003
                    ],
                    [
                        -3.049183722999942,
                        58.58673737200017
                    ],
                    [
                        -3.060454881999931,
                        58.578436591000084
                    ],
                    [
                        -3.075428839999859,
                        58.56037018400018
                    ],
                    [
                        -3.084543423999946,
                        58.551947333000115
                    ],
                    [
                        -3.1107478509999,
                        58.538641669000114
                    ],
                    [
                        -3.118031378999888,
                        58.53204987200003
                    ],
                    [
                        -3.129261847999914,
                        58.51264069200012
                    ],
                    [
                        -3.129302537999905,
                        58.496405341000084
                    ],
                    [
                        -3.118723110999923,
                        58.484442450000145
                    ],
                    [
                        -3.097523566999939,
                        58.47748444200006
                    ],
                    [
                        -3.085072394999912,
                        58.478257554
                    ],
                    [
                        -3.07396399599989,
                        58.48167552299999
                    ],
                    [
                        -3.064442511999914,
                        58.48297760600009
                    ],
                    [
                        -3.056630011999914,
                        58.47748444200006
                    ],
                    [
                        -3.053334113999881,
                        58.46576569200006
                    ],
                    [
                        -3.057728644999941,
                        58.456854559000035
                    ],
                    [
                        -3.06676184799997,
                        58.45115794500005
                    ],
                    [
                        -3.077056443999879,
                        58.44953034100003
                    ],
                    [
                        -3.086333787999934,
                        58.414211330000015
                    ],
                    [
                        -3.129790818999879,
                        58.3692894550001
                    ],
                    [
                        -3.182769334999904,
                        58.32843659100014
                    ],
                    [
                        -3.220529751999891,
                        58.30556875200013
                    ],
                    [
                        -3.262277798999918,
                        58.292629299000126
                    ],
                    [
                        -3.349842902999853,
                        58.276109117000075
                    ],
                    [
                        -3.38499915299991,
                        58.26459381700012
                    ],
                    [
                        -3.438099738999938,
                        58.236029364000146
                    ],
                    [
                        -3.447010870999861,
                        58.22675202000009
                    ],
                    [
                        -3.454741990999878,
                        58.210150458000115
                    ],
                    [
                        -3.473459438999953,
                        58.19342682500009
                    ],
                    [
                        -3.515288865999878,
                        58.168402411
                    ],
                    [
                        -3.735015428999901,
                        58.072170315000065
                    ],
                    [
                        -3.809193488999938,
                        58.05190664300015
                    ],
                    [
                        -3.827504035999908,
                        58.04205963700004
                    ],
                    [
                        -3.836252407999893,
                        58.03143952000009
                    ],
                    [
                        -3.846099412999905,
                        58.011175848000065
                    ],
                    [
                        -3.851714647999899,
                        58.00389232000008
                    ],
                    [
                        -3.860585089999859,
                        57.99892812700001
                    ],
                    [
                        -3.885609503999945,
                        57.99140045800006
                    ],
                    [
                        -3.917307094999927,
                        57.987453518000066
                    ],
                    [
                        -3.98143469999988,
                        57.963568427
                    ],
                    [
                        -3.990956183999884,
                        57.96039459800012
                    ],
                    [
                        -3.997670050999915,
                        57.95441315300012
                    ],
                    [
                        -4.001372850999871,
                        57.94611237200003
                    ],
                    [
                        -4.001942511999857,
                        57.935614325000145
                    ],
                    [
                        -4.018299933999856,
                        57.940619208
                    ],
                    [
                        -4.023060675999943,
                        57.943060614000146
                    ],
                    [
                        -4.016184048999946,
                        57.94928620000009
                    ],
                    [
                        -4.030140753999916,
                        57.95319245000009
                    ],
                    [
                        -4.055775519999884,
                        57.95498281500009
                    ],
                    [
                        -4.084380662999877,
                        57.96417877800015
                    ],
                    [
                        -4.089100714999887,
                        57.960150458000086
                    ],
                    [
                        -4.086333787999905,
                        57.95189036700002
                    ],
                    [
                        -4.077626105999912,
                        57.943060614000146
                    ],
                    [
                        -4.062652147999899,
                        57.93695709800015
                    ],
                    [
                        -4.03148352799991,
                        57.93618398600013
                    ],
                    [
                        -4.016184048999946,
                        57.93219635600015
                    ],
                    [
                        -4.009429490999906,
                        57.927679755
                    ],
                    [
                        -3.988270636999914,
                        57.90827057500009
                    ],
                    [
                        -4.008941209999932,
                        57.88959381700003
                    ],
                    [
                        -4.013824022999899,
                        57.87933991100009
                    ],
                    [
                        -4.009348110999923,
                        57.86790599200013
                    ],
                    [
                        -4.02513587099989,
                        57.8680687520001
                    ],
                    [
                        -4.054839647999899,
                        57.87433502800006
                    ],
                    [
                        -4.070220506999931,
                        57.874172268000095
                    ],
                    [
                        -4.079335089999916,
                        57.870266018000095
                    ],
                    [
                        -4.096913214999859,
                        57.857367255000085
                    ],
                    [
                        -4.104969855999912,
                        57.85370514500012
                    ],
                    [
                        -4.132923956999917,
                        57.85415273600002
                    ],
                    [
                        -4.190419074999909,
                        57.87006256700012
                    ],
                    [
                        -4.2181697259999,
                        57.874172268000095
                    ],
                    [
                        -4.298898891999926,
                        57.8627790390001
                    ],
                    [
                        -4.320952928999901,
                        57.87103913000003
                    ],
                    [
                        -4.353179490999963,
                        57.89520905200011
                    ],
                    [
                        -4.372303839999916,
                        57.90448639500006
                    ],
                    [
                        -4.392404751999891,
                        57.90827057500009
                    ],
                    [
                        -4.33958899599989,
                        57.865708726000136
                    ],
                    [
                        -4.307728644999884,
                        57.85146719000012
                    ],
                    [
                        -4.269398566999939,
                        57.846869208
                    ],
                    [
                        -4.22907467399989,
                        57.85724518400009
                    ],
                    [
                        -4.208241339999887,
                        57.859198309000064
                    ],
                    [
                        -4.190581834999875,
                        57.85028717700011
                    ],
                    [
                        -4.173451300999943,
                        57.83901601800004
                    ],
                    [
                        -4.149728969999927,
                        57.82982005400005
                    ],
                    [
                        -4.133452928999901,
                        57.830145575000174
                    ],
                    [
                        -4.139068162999905,
                        57.846869208
                    ],
                    [
                        -4.075062628999916,
                        57.82318756700009
                    ],
                    [
                        -4.039906378999945,
                        57.818182684000035
                    ],
                    [
                        -3.983509894999912,
                        57.84235260600006
                    ],
                    [
                        -3.951486782999922,
                        57.83832428600009
                    ],
                    [
                        -3.933705206999917,
                        57.8256696640001
                    ],
                    [
                        -3.947336391999983,
                        57.81268952
                    ],
                    [
                        -3.914784308999913,
                        57.80768463700015
                    ],
                    [
                        -3.877674933999913,
                        57.81671784100017
                    ],
                    [
                        -3.841867641999897,
                        57.83490631700006
                    ],
                    [
                        -3.813547329999949,
                        57.85712311400012
                    ],
                    [
                        -3.798451300999943,
                        57.86652252800015
                    ],
                    [
                        -3.786040818999908,
                        57.865423895
                    ],
                    [
                        -3.779449022999927,
                        57.85565827000006
                    ],
                    [
                        -3.78221594999988,
                        57.83942291900003
                    ],
                    [
                        -3.79002844999988,
                        57.83071523600013
                    ],
                    [
                        -3.92601477799991,
                        57.734808661000116
                    ],
                    [
                        -3.948841925999915,
                        57.72492096600003
                    ],
                    [
                        -3.975209113999938,
                        57.70327383000013
                    ],
                    [
                        -3.988270636999914,
                        57.69599030200011
                    ],
                    [
                        -4.008046027999939,
                        57.693793036000116
                    ],
                    [
                        -4.02367102799991,
                        57.69782135600009
                    ],
                    [
                        -4.028635219999956,
                        57.70770905200003
                    ],
                    [
                        -4.016184048999946,
                        57.723334052
                    ],
                    [
                        -4.042958136999857,
                        57.73729075700005
                    ],
                    [
                        -4.077951626999948,
                        57.73029205900018
                    ],
                    [
                        -4.155344204999949,
                        57.69257233300003
                    ],
                    [
                        -4.168568488999881,
                        57.68943919500013
                    ],
                    [
                        -4.193999803999901,
                        57.68854401200004
                    ],
                    [
                        -4.259266730999883,
                        57.67869700700011
                    ],
                    [
                        -4.277821417999888,
                        57.68060944200009
                    ],
                    [
                        -4.294789191999882,
                        57.68016185099999
                    ],
                    [
                        -4.302154100999871,
                        57.66860586100016
                    ],
                    [
                        -4.30882727799991,
                        57.654852606000034
                    ],
                    [
                        -4.324045376999891,
                        57.64826080900015
                    ],
                    [
                        -4.338205532999922,
                        57.64508698100009
                    ],
                    [
                        -4.403187628999888,
                        57.60952383000013
                    ],
                    [
                        -4.412180141999897,
                        57.60297272300015
                    ],
                    [
                        -4.420318162999905,
                        57.59296295800006
                    ],
                    [
                        -4.428456183999856,
                        57.57754140800013
                    ],
                    [
                        -4.423451300999886,
                        57.5768089860001
                    ],
                    [
                        -4.409901495999918,
                        57.582464911
                    ],
                    [
                        -4.37987219999988,
                        57.5893415390001
                    ],
                    [
                        -4.365101691999911,
                        57.59723541900017
                    ],
                    [
                        -4.351063605999855,
                        57.60736725500011
                    ],
                    [
                        -4.340809699999909,
                        57.61717357000005
                    ],
                    [
                        -4.32872473899991,
                        57.62628815300015
                    ],
                    [
                        -4.283111131999874,
                        57.641424872000144
                    ],
                    [
                        -4.244536912999905,
                        57.66303131700015
                    ],
                    [
                        -4.201893683999884,
                        57.674994208000086
                    ],
                    [
                        -4.193755662999934,
                        57.67552317900005
                    ],
                    [
                        -4.18224036399991,
                        57.67279694200009
                    ],
                    [
                        -4.169016079999892,
                        57.66376373900009
                    ],
                    [
                        -4.118723110999923,
                        57.65770091399999
                    ],
                    [
                        -4.096791144999884,
                        57.65810781500009
                    ],
                    [
                        -4.080718553999901,
                        57.664984442
                    ],
                    [
                        -4.068104620999918,
                        57.672308661
                    ],
                    [
                        -4.024647589999887,
                        57.687201239000146
                    ],
                    [
                        -4.009348110999923,
                        57.68854401200004
                    ],
                    [
                        -4.004505988999938,
                        57.67597077000015
                    ],
                    [
                        -4.025257941999939,
                        57.65521881700015
                    ],
                    [
                        -4.054188605999855,
                        57.63572825700005
                    ],
                    [
                        -4.0834041009999,
                        57.62335846600003
                    ],
                    [
                        -4.118560350999871,
                        57.59296295800006
                    ],
                    [
                        -4.115467902999882,
                        57.58783600500014
                    ],
                    [
                        -4.112294074999909,
                        57.584133205000015
                    ],
                    [
                        -4.108876105999883,
                        57.581447658000044
                    ],
                    [
                        -4.104969855999912,
                        57.579291083000115
                    ],
                    [
                        -4.15135657499988,
                        57.576849677
                    ],
                    [
                        -4.170643683999913,
                        57.570746161
                    ],
                    [
                        -4.187489386999914,
                        57.558823960000055
                    ],
                    [
                        -4.178089972999885,
                        57.549546617000104
                    ],
                    [
                        -4.184925910999908,
                        57.54816315300003
                    ],
                    [
                        -4.207997199999852,
                        57.55206940300003
                    ],
                    [
                        -4.262603318999908,
                        57.55206940300003
                    ],
                    [
                        -4.262603318999908,
                        57.544582424000154
                    ],
                    [
                        -4.233957485999952,
                        57.54511139500012
                    ],
                    [
                        -4.220285610999923,
                        57.543402411000116
                    ],
                    [
                        -4.207997199999852,
                        57.53839752800009
                    ],
                    [
                        -4.226307745999918,
                        57.51544830900009
                    ],
                    [
                        -4.237456834999932,
                        57.50531647300015
                    ],
                    [
                        -4.248931443999879,
                        57.49746328300016
                    ],
                    [
                        -4.212798631999931,
                        57.49217357
                    ],
                    [
                        -4.194813605999912,
                        57.491603908000016
                    ],
                    [
                        -4.173898891999954,
                        57.49746328300016
                    ],
                    [
                        -4.1576228509999,
                        57.50849030200011
                    ],
                    [
                        -4.150054490999878,
                        57.5150414080001
                    ],
                    [
                        -4.149322068999936,
                        57.51788971600003
                    ],
                    [
                        -4.126779751999948,
                        57.51886627800012
                    ],
                    [
                        -4.116200324999909,
                        57.52179596600003
                    ],
                    [
                        -4.097523566999911,
                        57.536688544000086
                    ],
                    [
                        -4.08299719999988,
                        57.54441966400002
                    ],
                    [
                        -4.067290818999908,
                        57.549953518
                    ],
                    [
                        -4.053130662999905,
                        57.55206940300003
                    ],
                    [
                        -4.034982876999891,
                        57.55687083500011
                    ],
                    [
                        -4.046457485999923,
                        57.567857164000074
                    ],
                    [
                        -4.083851691999882,
                        57.58612702000015
                    ],
                    [
                        -4.056019660999937,
                        57.58515045800006
                    ],
                    [
                        -3.964344855999855,
                        57.59296295800006
                    ],
                    [
                        -3.868275519999884,
                        57.58787669500013
                    ],
                    [
                        -3.837473110999895,
                        57.59296295800006
                    ],
                    [
                        -3.684803839999944,
                        57.65444570500013
                    ],
                    [
                        -3.625152147999927,
                        57.66193268400009
                    ],
                    [
                        -3.632557745999918,
                        57.650946356000034
                    ],
                    [
                        -3.637684699999852,
                        57.64622630400014
                    ],
                    [
                        -3.645619269999912,
                        57.641424872000144
                    ],
                    [
                        -3.632476365999935,
                        57.63564687700007
                    ],
                    [
                        -3.619618292999917,
                        57.636216539000046
                    ],
                    [
                        -3.606068488999938,
                        57.63947174700009
                    ],
                    [
                        -3.590972459999932,
                        57.641424872000144
                    ],
                    [
                        -3.611561652999882,
                        57.66811758000007
                    ],
                    [
                        -3.57477779899989,
                        57.661118882
                    ],
                    [
                        -3.554921027999882,
                        57.65985748900009
                    ],
                    [
                        -3.535755988999938,
                        57.66193268400009
                    ],
                    [
                        -3.521473761999943,
                        57.667792059000035
                    ],
                    [
                        -3.505441860999952,
                        57.67865631700012
                    ],
                    [
                        -3.495961066999939,
                        57.69135163
                    ],
                    [
                        -3.501698370999918,
                        57.70282623900012
                    ],
                    [
                        -3.491851365999878,
                        57.713120835000055
                    ],
                    [
                        -3.482818162999877,
                        57.71401601800015
                    ],
                    [
                        -3.473052537999934,
                        57.71100495000006
                    ],
                    [
                        -3.46068274599989,
                        57.709662177000055
                    ],
                    [
                        -3.447865363999881,
                        57.712469794000114
                    ],
                    [
                        -3.421254035999937,
                        57.72138092700014
                    ],
                    [
                        -3.409169074999909,
                        57.723334052
                    ],
                    [
                        -3.284738735999923,
                        57.72044505400005
                    ],
                    [
                        -3.210072394999912,
                        57.693996486000074
                    ],
                    [
                        -3.078684048999918,
                        57.669419664000074
                    ],
                    [
                        -3.034901495999947,
                        57.66860586100016
                    ],
                    [
                        -2.994496222999885,
                        57.67552317900005
                    ],
                    [
                        -2.932687954999892,
                        57.69961172100007
                    ],
                    [
                        -2.916086391999926,
                        57.70282623900012
                    ],
                    [
                        -2.75804602799991,
                        57.70282623900012
                    ],
                    [
                        -2.751210089999887,
                        57.70062897300015
                    ],
                    [
                        -2.741118943999936,
                        57.690863348
                    ],
                    [
                        -2.731068488999938,
                        57.68854401200004
                    ],
                    [
                        -2.719878709999932,
                        57.69061920800006
                    ],
                    [
                        -2.711537238999881,
                        57.693996486000074
                    ],
                    [
                        -2.703521287999934,
                        57.694728908000016
                    ],
                    [
                        -2.69294186099998,
                        57.68854401200004
                    ],
                    [
                        -2.658924933999856,
                        57.69745514500009
                    ],
                    [
                        -2.573394334999932,
                        57.68146393400018
                    ],
                    [
                        -2.541981574999909,
                        57.682359117000075
                    ],
                    [
                        -2.526112433999884,
                        57.66860586100016
                    ],
                    [
                        -2.518137173999918,
                        57.670111395
                    ],
                    [
                        -2.511057094999956,
                        57.67780182500003
                    ],
                    [
                        -2.497954881999903,
                        57.682359117000075
                    ],
                    [
                        -2.480051235999952,
                        57.68121979400003
                    ],
                    [
                        -2.432850714999859,
                        57.66811758000007
                    ],
                    [
                        -2.425526495999861,
                        57.67572663000003
                    ],
                    [
                        -2.417795376999948,
                        57.674261786000145
                    ],
                    [
                        -2.408762173999946,
                        57.66962311400012
                    ],
                    [
                        -2.398060675999915,
                        57.66811758000007
                    ],
                    [
                        -2.386586066999882,
                        57.67157623900006
                    ],
                    [
                        -2.369496222999914,
                        57.6805687520001
                    ],
                    [
                        -2.360503709999904,
                        57.682359117000075
                    ],
                    [
                        -2.341053839999916,
                        57.675848700000174
                    ],
                    [
                        -2.328968878999916,
                        57.674058335
                    ],
                    [
                        -2.323597785999937,
                        57.67894114800008
                    ],
                    [
                        -2.320546027999853,
                        57.68455638200005
                    ],
                    [
                        -2.313384568999908,
                        57.68992747600011
                    ],
                    [
                        -2.30406653599988,
                        57.693996486000074
                    ],
                    [
                        -2.295643683999913,
                        57.69599030200011
                    ],
                    [
                        -2.274322068999936,
                        57.69383372600011
                    ],
                    [
                        -2.230132615999878,
                        57.679144598000036
                    ],
                    [
                        -2.209950324999852,
                        57.67552317900005
                    ],
                    [
                        -2.18407141799986,
                        57.67845286699999
                    ],
                    [
                        -2.124338344999956,
                        57.70282623900012
                    ],
                    [
                        -2.108143683999913,
                        57.70498281500012
                    ],
                    [
                        -1.997670050999886,
                        57.70282623900012
                    ],
                    [
                        -1.996937628999945,
                        57.69904205900012
                    ],
                    [
                        -1.990834113999966,
                        57.69037506700012
                    ],
                    [
                        -1.982167120999861,
                        57.681097723000065
                    ],
                    [
                        -1.973500128999888,
                        57.67552317900005
                    ],
                    [
                        -1.961293097999885,
                        57.67470937700013
                    ],
                    [
                        -1.944732225999928,
                        57.68256256700012
                    ],
                    [
                        -1.932484503999945,
                        57.682359117000075
                    ],
                    [
                        -1.925689256999931,
                        57.678168036000145
                    ],
                    [
                        -1.914214647999899,
                        57.664740302000055
                    ],
                    [
                        -1.829457160999908,
                        57.613470770000035
                    ],
                    [
                        -1.820464647999899,
                        57.596340236000074
                    ],
                    [
                        -1.817005988999881,
                        57.578192450000174
                    ],
                    [
                        -1.811390753999888,
                        57.560492255000085
                    ],
                    [
                        -1.796009894999941,
                        57.544582424000154
                    ],
                    [
                        -1.796009894999941,
                        57.53839752800009
                    ],
                    [
                        -1.800038214999916,
                        57.52586497599999
                    ],
                    [
                        -1.78954016799986,
                        57.51483795800014
                    ],
                    [
                        -1.761138475999928,
                        57.49746328300016
                    ],
                    [
                        -1.775380011999914,
                        57.49652741100009
                    ],
                    [
                        -1.782134568999965,
                        57.488999742000104
                    ],
                    [
                        -1.781239386999857,
                        57.48041413000003
                    ],
                    [
                        -1.771717902999882,
                        57.47626373900006
                    ],
                    [
                        -1.759348110999923,
                        57.47357819200009
                    ],
                    [
                        -1.765939907999893,
                        57.46743398600002
                    ],
                    [
                        -1.789173956999917,
                        57.45644765800016
                    ],
                    [
                        -1.830148891999954,
                        57.42617422100015
                    ],
                    [
                        -1.847482876999919,
                        57.40814850500011
                    ],
                    [
                        -1.849964972999942,
                        57.39435455900012
                    ],
                    [
                        -1.954497850999871,
                        57.34113190300003
                    ],
                    [
                        -1.980295376999891,
                        57.319281317
                    ],
                    [
                        -2.04466712099989,
                        57.22650788000006
                    ],
                    [
                        -2.068511522999899,
                        57.17462799700017
                    ],
                    [
                        -2.074126756999874,
                        57.154201565
                    ],
                    [
                        -2.071400519999912,
                        57.135565497000115
                    ],
                    [
                        -2.052357550999915,
                        57.12742747599999
                    ],
                    [
                        -2.049305792999917,
                        57.11884186400009
                    ],
                    [
                        -2.065256313999925,
                        57.10053131700012
                    ],
                    [
                        -2.0658259759999,
                        57.09983958500008
                    ],
                    [
                        -2.156158006999903,
                        57.020697333
                    ],
                    [
                        -2.186919725999871,
                        56.98468659100003
                    ],
                    [
                        -2.200103318999936,
                        56.948716539000046
                    ],
                    [
                        -2.197132941999911,
                        56.940252997000115
                    ],
                    [
                        -2.191232876999891,
                        56.934149481000034
                    ],
                    [
                        -2.186268683999941,
                        56.92674388200014
                    ],
                    [
                        -2.186390753999916,
                        56.91453685100008
                    ],
                    [
                        -2.191314256999874,
                        56.908921617000075
                    ],
                    [
                        -2.209706183999913,
                        56.89581940300009
                    ],
                    [
                        -2.213693813999896,
                        56.890692450000174
                    ],
                    [
                        -2.219878709999961,
                        56.87262604400014
                    ],
                    [
                        -2.234608527999853,
                        56.861395575000145
                    ],
                    [
                        -2.2689509759999,
                        56.84634023600013
                    ],
                    [
                        -2.295806443999879,
                        56.82526276200012
                    ],
                    [
                        -2.319813605999912,
                        56.801581122000115
                    ],
                    [
                        -2.33608964799987,
                        56.79083893400009
                    ],
                    [
                        -2.391835089999859,
                        56.771185614000146
                    ],
                    [
                        -2.40868079299986,
                        56.76292552300005
                    ],
                    [
                        -2.426421678999873,
                        56.75128815300006
                    ],
                    [
                        -2.435943162999934,
                        56.74038320500007
                    ],
                    [
                        -2.440744594999927,
                        56.73484935100005
                    ],
                    [
                        -2.451649542999917,
                        56.690985419000086
                    ],
                    [
                        -2.463734503999916,
                        56.67877838700012
                    ],
                    [
                        -2.477406378999945,
                        56.668687242000075
                    ],
                    [
                        -2.487456834999932,
                        56.653876044000086
                    ],
                    [
                        -2.487538214999916,
                        56.646063544000086
                    ],
                    [
                        -2.481271938999953,
                        56.63239166900014
                    ],
                    [
                        -2.480620897999899,
                        56.626532294000114
                    ],
                    [
                        -2.486195441999911,
                        56.619574286000116
                    ],
                    [
                        -2.504058397999899,
                        56.608832098
                    ],
                    [
                        -2.514556443999965,
                        56.59149811400012
                    ],
                    [
                        -2.530018683999884,
                        56.58075592700011
                    ],
                    [
                        -2.623768683999884,
                        56.54340241100006
                    ],
                    [
                        -2.640939907999922,
                        56.52236562700004
                    ],
                    [
                        -2.661366339999887,
                        56.51422760600009
                    ],
                    [
                        -2.69977779899989,
                        56.50364817900005
                    ],
                    [
                        -2.708729620999918,
                        56.49599844000012
                    ],
                    [
                        -2.727650519999941,
                        56.469549872000144
                    ],
                    [
                        -2.737294074999909,
                        56.46702708500011
                    ],
                    [
                        -2.744374152999882,
                        56.469549872000144
                    ],
                    [
                        -2.752308722999942,
                        56.473618882000025
                    ],
                    [
                        -2.764800584999932,
                        56.475734768
                    ],
                    [
                        -2.819325324999937,
                        56.47129954600014
                    ],
                    [
                        -3.055653449999852,
                        56.45213450700008
                    ],
                    [
                        -3.060617641999897,
                        56.45172760600015
                    ],
                    [
                        -3.099273240999935,
                        56.44188060100005
                    ],
                    [
                        -3.133900519999912,
                        56.426947333
                    ],
                    [
                        -3.238189256999903,
                        56.36774323100009
                    ],
                    [
                        -3.280181443999879,
                        56.35789622599999
                    ],
                    [
                        -3.323475714999859,
                        56.36591217700011
                    ],
                    [
                        -3.311512824999909,
                        56.356512762000094
                    ],
                    [
                        -3.294016079999977,
                        56.35276927300008
                    ],
                    [
                        -3.251535610999895,
                        56.35285065300015
                    ],
                    [
                        -3.230620897999927,
                        56.35602448100012
                    ],
                    [
                        -3.188384568999908,
                        56.37018463700004
                    ],
                    [
                        -3.149566209999904,
                        56.37555573100009
                    ],
                    [
                        -2.950266079999921,
                        56.431870835000055
                    ],
                    [
                        -2.939930792999917,
                        56.43854401200012
                    ],
                    [
                        -2.93065344999988,
                        56.448065497000115
                    ],
                    [
                        -2.909087693999936,
                        56.45571523600013
                    ],
                    [
                        -2.884999152999939,
                        56.45799388200011
                    ],
                    [
                        -2.867909308999884,
                        56.45184967700014
                    ],
                    [
                        -2.850087042999917,
                        56.44204336100002
                    ],
                    [
                        -2.814768032999893,
                        56.440415757
                    ],
                    [
                        -2.802805141999954,
                        56.42796458500008
                    ],
                    [
                        -2.800892706999889,
                        56.40892161699999
                    ],
                    [
                        -2.808257615999878,
                        56.39105866100003
                    ],
                    [
                        -2.821603969999899,
                        56.37616608300006
                    ],
                    [
                        -2.837554490999906,
                        56.36591217700011
                    ],
                    [
                        -2.812977667999888,
                        56.36591217700011
                    ],
                    [
                        -2.809193488999881,
                        56.36286041900003
                    ],
                    [
                        -2.804758266999897,
                        56.3491071640001
                    ],
                    [
                        -2.802805141999954,
                        56.34540436400009
                    ],
                    [
                        -2.79149329299986,
                        56.342474677000055
                    ],
                    [
                        -2.782622850999928,
                        56.34162018400015
                    ],
                    [
                        -2.775257941999911,
                        56.33942291900014
                    ],
                    [
                        -2.768625454999949,
                        56.332342841000084
                    ],
                    [
                        -2.677154100999928,
                        56.32843659100011
                    ],
                    [
                        -2.655262824999909,
                        56.32217031500015
                    ],
                    [
                        -2.621205206999889,
                        56.30442942900005
                    ],
                    [
                        -2.613189256999931,
                        56.30190664300012
                    ],
                    [
                        -2.576812303999958,
                        56.283921617000104
                    ],
                    [
                        -2.576812303999958,
                        56.277777411000116
                    ],
                    [
                        -2.630116339999916,
                        56.24852122599999
                    ],
                    [
                        -2.648345506999903,
                        56.22870514500009
                    ],
                    [
                        -2.672027147999927,
                        56.22239817900014
                    ],
                    [
                        -2.720204230999883,
                        56.216294664000046
                    ],
                    [
                        -2.783599412999905,
                        56.191961981000176
                    ],
                    [
                        -2.806141730999883,
                        56.18838125200001
                    ],
                    [
                        -2.830718553999901,
                        56.19074127800015
                    ],
                    [
                        -2.892079230999883,
                        56.208889065000065
                    ],
                    [
                        -2.940052863999881,
                        56.209418036000145
                    ],
                    [
                        -2.964344855999883,
                        56.20563385600012
                    ],
                    [
                        -2.974680141999954,
                        56.19830963700012
                    ],
                    [
                        -2.982411261999886,
                        56.189113674000126
                    ],
                    [
                        -3.035552537999877,
                        56.16791413000006
                    ],
                    [
                        -3.091053839999859,
                        56.132269598
                    ],
                    [
                        -3.107533331999917,
                        56.12689850500014
                    ],
                    [
                        -3.12755286399991,
                        56.12287018400018
                    ],
                    [
                        -3.138295050999915,
                        56.11220937700013
                    ],
                    [
                        -3.152251756999874,
                        56.078517971000124
                    ],
                    [
                        -3.163563605999855,
                        56.06386953300013
                    ],
                    [
                        -3.17837480399993,
                        56.058091539000074
                    ],
                    [
                        -3.248443162999905,
                        56.05597565300015
                    ],
                    [
                        -3.268381313999896,
                        56.05093008000004
                    ],
                    [
                        -3.303049282999893,
                        56.037543036000116
                    ],
                    [
                        -3.342681443999908,
                        56.02716705900012
                    ],
                    [
                        -3.381825324999852,
                        56.02338288
                    ],
                    [
                        -3.420969204999892,
                        56.024888414000046
                    ],
                    [
                        -3.58259029899989,
                        56.05174388200014
                    ],
                    [
                        -3.671498175999915,
                        56.05084870000006
                    ],
                    [
                        -3.710031704999892,
                        56.058091539000074
                    ],
                    [
                        -3.745432094999899,
                        56.072211005
                    ],
                    [
                        -3.746490037999877,
                        56.072780666000156
                    ],
                    [
                        -3.78221594999988,
                        56.09218984600007
                    ],
                    [
                        -3.803130662999934,
                        56.10712311400012
                    ],
                    [
                        -3.817005988999938,
                        56.11225006700012
                    ],
                    [
                        -3.837473110999895,
                        56.11261627800015
                    ],
                    [
                        -3.837473110999895,
                        56.10643138200008
                    ],
                    [
                        -3.777699347999942,
                        56.08283112200006
                    ],
                    [
                        -3.769154425999915,
                        56.07514069200012
                    ],
                    [
                        -3.760853644999941,
                        56.07245514500015
                    ],
                    [
                        -3.726429816999911,
                        56.038031317
                    ],
                    [
                        -3.721424933999856,
                        56.030707098
                    ],
                    [
                        -3.71263587099989,
                        56.028998114
                    ],
                    [
                        -3.693470831999917,
                        56.03115469
                    ],
                    [
                        -3.686634894999912,
                        56.030707098
                    ],
                    [
                        -3.679554816999939,
                        56.02545807500012
                    ],
                    [
                        -3.674794074999852,
                        56.018703518000095
                    ],
                    [
                        -3.66828365799995,
                        56.01276276200015
                    ],
                    [
                        -3.656239386999857,
                        56.010199286000145
                    ],
                    [
                        -3.599720831999917,
                        56.01723867400004
                    ],
                    [
                        -3.577381964999887,
                        56.017035223000065
                    ],
                    [
                        -3.405425584999904,
                        55.98973216399999
                    ],
                    [
                        -3.358265753999916,
                        55.98973216399999
                    ],
                    [
                        -3.340891079999921,
                        55.994452216000084
                    ],
                    [
                        -3.331044074999909,
                        55.994086005000085
                    ],
                    [
                        -3.320423956999946,
                        55.986029364000146
                    ],
                    [
                        -3.303456183999884,
                        55.977606512000094
                    ],
                    [
                        -3.121815558999913,
                        55.96930573100012
                    ],
                    [
                        -3.114735480999855,
                        55.96613190300015
                    ],
                    [
                        -3.096831834999904,
                        55.95197174700003
                    ],
                    [
                        -3.090728318999908,
                        55.948187567
                    ],
                    [
                        -3.078236456999917,
                        55.946844794000114
                    ],
                    [
                        -3.015695766999897,
                        55.95050690300015
                    ],
                    [
                        -2.939930792999917,
                        55.96930573100012
                    ],
                    [
                        -2.916656053999901,
                        55.9806175800001
                    ],
                    [
                        -2.900054490999935,
                        55.99616120000009
                    ],
                    [
                        -2.882883266999926,
                        56.00849030200014
                    ],
                    [
                        -2.85798092399989,
                        56.010199286000145
                    ],
                    [
                        -2.863636847999885,
                        56.022284247000144
                    ],
                    [
                        -2.866322394999941,
                        56.026760158000016
                    ],
                    [
                        -2.870961066999882,
                        56.030707098
                    ],
                    [
                        -2.852772589999887,
                        56.039780992000104
                    ],
                    [
                        -2.823150193999936,
                        56.059759833000086
                    ],
                    [
                        -2.802805141999954,
                        56.06484609600001
                    ],
                    [
                        -2.635121222999942,
                        56.058050848000065
                    ],
                    [
                        -2.615956183999884,
                        56.053168036
                    ],
                    [
                        -2.587554490999878,
                        56.030951239000146
                    ],
                    [
                        -2.569406704999949,
                        56.02448151200004
                    ],
                    [
                        -2.578358527999882,
                        56.00800202000006
                    ],
                    [
                        -2.583648240999878,
                        56.00275299700017
                    ],
                    [
                        -2.569569464999916,
                        55.99994538000011
                    ],
                    [
                        -2.544056769999941,
                        56.00267161699999
                    ],
                    [
                        -2.528960740999935,
                        55.99656810100011
                    ],
                    [
                        -2.5248103509999,
                        56.000555731000176
                    ],
                    [
                        -2.514556443999965,
                        56.005764065000065
                    ],
                    [
                        -2.507923956999889,
                        56.010199286000145
                    ],
                    [
                        -2.493275519999884,
                        56.00153229400014
                    ],
                    [
                        -2.452504035999908,
                        55.98525625200004
                    ],
                    [
                        -2.41437740799995,
                        55.97833893400012
                    ],
                    [
                        -2.350941535999908,
                        55.948187567
                    ],
                    [
                        -2.307769334999904,
                        55.93500397300015
                    ],
                    [
                        -2.146839972999942,
                        55.91730377800015
                    ],
                    [
                        -2.138010219999899,
                        55.91461823100015
                    ],
                    [
                        -2.132557745999861,
                        55.90643952000006
                    ],
                    [
                        -2.13109290299991,
                        55.897202867000104
                    ],
                    [
                        -2.128773566999939,
                        55.889797268
                    ],
                    [
                        -2.120961066999939,
                        55.88670482000013
                    ],
                    [
                        -2.099476691999911,
                        55.88178131700006
                    ],
                    [
                        -2.080189581999889,
                        55.86937083500014
                    ],
                    [
                        -2.022857225999957,
                        55.80548737200003
                    ],
                    [
                        -2.02285626077952,
                        55.805486299532944
                    ],
                    [
                        -2.009673631999903,
                        55.79083893400012
                    ],
                    [
                        -1.877837693999908,
                        55.69489166900003
                    ],
                    [
                        -1.863189256999903,
                        55.67161692900011
                    ],
                    [
                        -1.853098110999923,
                        55.65932851800015
                    ],
                    [
                        -1.831532355999912,
                        55.65070221600014
                    ],
                    [
                        -1.826079881999903,
                        55.64362213700018
                    ],
                    [
                        -1.8216853509999,
                        55.636419989000146
                    ],
                    [
                        -1.816477016999897,
                        55.632879950000174
                    ],
                    [
                        -1.807118292999888,
                        55.634507554
                    ],
                    [
                        -1.800770636999857,
                        55.63922760600009
                    ],
                    [
                        -1.796294725999871,
                        55.644232489000146
                    ],
                    [
                        -1.792591925999943,
                        55.64655182500012
                    ],
                    [
                        -1.781402147999927,
                        55.645656643
                    ],
                    [
                        -1.768625454999892,
                        55.641913153000175
                    ],
                    [
                        -1.756499803999901,
                        55.63361237200009
                    ],
                    [
                        -1.747670050999943,
                        55.61920807500003
                    ],
                    [
                        -1.767974412999934,
                        55.61920807500003
                    ],
                    [
                        -1.767974412999934,
                        55.61298248900009
                    ],
                    [
                        -1.755970831999917,
                        55.60887278900013
                    ],
                    [
                        -1.720855272999927,
                        55.61456940300012
                    ],
                    [
                        -1.699126756999874,
                        55.61298248900009
                    ],
                    [
                        -1.630848761999886,
                        55.58510976800012
                    ],
                    [
                        -1.630848761999886,
                        55.578273830000015
                    ],
                    [
                        -1.636341925999886,
                        55.57168203300013
                    ],
                    [
                        -1.634673631999874,
                        55.56439850500011
                    ],
                    [
                        -1.627674933999913,
                        55.55711497600011
                    ],
                    [
                        -1.617176886999943,
                        55.55027903899999
                    ],
                    [
                        -1.630848761999886,
                        55.54413483300003
                    ],
                    [
                        -1.630848761999886,
                        55.53729889500009
                    ],
                    [
                        -1.622222459999875,
                        55.53408437700013
                    ],
                    [
                        -1.602935350999928,
                        55.52362702000015
                    ],
                    [
                        -1.602935350999928,
                        55.51679108300014
                    ],
                    [
                        -1.611317511999886,
                        55.508042710000055
                    ],
                    [
                        -1.606353318999936,
                        55.503241278000175
                    ],
                    [
                        -1.596343553999873,
                        55.49990469000015
                    ],
                    [
                        -1.589344855999883,
                        55.49567291900003
                    ],
                    [
                        -1.58495032499988,
                        55.48371002800009
                    ],
                    [
                        -1.581369594999927,
                        55.41665273600002
                    ],
                    [
                        -1.583119269999912,
                        55.406927802000055
                    ],
                    [
                        -1.588286912999905,
                        55.39057038000014
                    ],
                    [
                        -1.589100714999916,
                        55.385199286
                    ],
                    [
                        -1.589344855999883,
                        55.376532294000086
                    ],
                    [
                        -1.583322719999956,
                        55.35472239800005
                    ],
                    [
                        -1.558583136999886,
                        55.328924872000115
                    ],
                    [
                        -1.555816209999932,
                        55.31134674700017
                    ],
                    [
                        -1.557036912999934,
                        55.30662669500008
                    ],
                    [
                        -1.558990037999905,
                        55.30206940300003
                    ],
                    [
                        -1.561919725999928,
                        55.29779694200012
                    ],
                    [
                        -1.565744594999927,
                        55.293646552000084
                    ],
                    [
                        -1.570057745999947,
                        55.286200262000094
                    ],
                    [
                        -1.568226691999968,
                        55.27960846600003
                    ],
                    [
                        -1.564279751999891,
                        55.27326080900009
                    ],
                    [
                        -1.562082485999895,
                        55.26666901200012
                    ],
                    [
                        -1.556467251999891,
                        55.255031643
                    ],
                    [
                        -1.54393469999988,
                        55.243882554
                    ],
                    [
                        -1.520985480999883,
                        55.22939687700013
                    ],
                    [
                        -1.529164191999911,
                        55.21625397300009
                    ],
                    [
                        -1.524077928999873,
                        55.210109768
                    ],
                    [
                        -1.514271613999938,
                        55.204779364000146
                    ],
                    [
                        -1.507964647999899,
                        55.19464752800009
                    ],
                    [
                        -1.510812954999949,
                        55.184759833000086
                    ],
                    [
                        -1.517567511999886,
                        55.17621491100006
                    ],
                    [
                        -1.52212480399993,
                        55.16746653899999
                    ],
                    [
                        -1.517933722999914,
                        55.15713125200007
                    ],
                    [
                        -1.500721808999884,
                        55.141791083000115
                    ],
                    [
                        -1.495961066999882,
                        55.13458893400018
                    ],
                    [
                        -1.492543097999885,
                        55.11298248900009
                    ],
                    [
                        -1.483754035999908,
                        55.091701565
                    ],
                    [
                        -1.480091925999943,
                        55.085394598000065
                    ],
                    [
                        -1.476226365999935,
                        55.08364492400007
                    ],
                    [
                        -1.463286912999934,
                        55.080308335000055
                    ],
                    [
                        -1.45958411399991,
                        55.07859935100005
                    ],
                    [
                        -1.458078579999949,
                        55.075751044000114
                    ],
                    [
                        -1.454701300999943,
                        55.067572333
                    ],
                    [
                        -1.438303188999925,
                        55.04230377800006
                    ],
                    [
                        -1.429188605999855,
                        55.03327057500003
                    ],
                    [
                        -1.42406165299991,
                        55.024359442
                    ],
                    [
                        -1.419504360999895,
                        55.01170482000013
                    ],
                    [
                        -1.412912563999896,
                        55.00031159100014
                    ],
                    [
                        -1.401519334999932,
                        54.99542877800009
                    ],
                    [
                        -1.398182745999918,
                        54.992621161000145
                    ],
                    [
                        -1.370228644999884,
                        54.975531317
                    ],
                    [
                        -1.362660285999937,
                        54.964789130000085
                    ],
                    [
                        -1.361724412999934,
                        54.958685614
                    ],
                    [
                        -1.363514777999853,
                        54.95425039300012
                    ],
                    [
                        -1.364003058999913,
                        54.94818756700012
                    ],
                    [
                        -1.362863735999895,
                        54.92593008000007
                    ],
                    [
                        -1.36034094999988,
                        54.91705963700004
                    ],
                    [
                        -1.353138800999943,
                        54.91351959800015
                    ],
                    [
                        -1.355824347999914,
                        54.90428294499999
                    ],
                    [
                        -1.296783006999874,
                        54.77993398600016
                    ],
                    [
                        -1.275461391999926,
                        54.74843984600015
                    ],
                    [
                        -1.24046790299991,
                        54.72166575700005
                    ],
                    [
                        -1.225087042999888,
                        54.71503327000009
                    ],
                    [
                        -1.172230597999914,
                        54.701239325000174
                    ],
                    [
                        -1.172230597999914,
                        54.693793036
                    ],
                    [
                        -1.192616339999887,
                        54.693793036
                    ],
                    [
                        -1.192453579999921,
                        54.689276434000035
                    ],
                    [
                        -1.190785285999908,
                        54.68618398600016
                    ],
                    [
                        -1.185170050999915,
                        54.68008047100007
                    ],
                    [
                        -1.182728644999884,
                        54.67206452000012
                    ],
                    [
                        -1.178822394999884,
                        54.66474030200011
                    ],
                    [
                        -1.172922329999949,
                        54.6583519550001
                    ],
                    [
                        -1.16470292899993,
                        54.652777411
                    ],
                    [
                        -1.172922329999949,
                        54.64419179900007
                    ],
                    [
                        -1.182484503999916,
                        54.639227606000034
                    ],
                    [
                        -1.206288214999916,
                        54.63296133000007
                    ],
                    [
                        -1.20372473899991,
                        54.62571849200005
                    ],
                    [
                        -1.198841925999943,
                        54.62156810100011
                    ],
                    [
                        -1.191802537999877,
                        54.619777736000046
                    ],
                    [
                        -1.182118292999917,
                        54.61928945500013
                    ],
                    [
                        -1.179351365999963,
                        54.62091705900015
                    ],
                    [
                        -1.178456183999856,
                        54.624212958
                    ],
                    [
                        -1.176991339999916,
                        54.62677643400009
                    ],
                    [
                        -1.172230597999914,
                        54.62612539300015
                    ],
                    [
                        -1.169585740999906,
                        54.623602606000034
                    ],
                    [
                        -1.165435350999871,
                        54.61871979400017
                    ],
                    [
                        -1.162953253999945,
                        54.613959052000084
                    ],
                    [
                        -1.16470292899993,
                        54.61180247600008
                    ],
                    [
                        -1.152658657999922,
                        54.61318594000015
                    ],
                    [
                        -1.14671790299991,
                        54.61847565300003
                    ],
                    [
                        -1.138050910999908,
                        54.64655182500003
                    ],
                    [
                        -1.120594855999855,
                        54.63300202000006
                    ],
                    [
                        -1.103627081999889,
                        54.6246605490001
                    ],
                    [
                        -1.084217902999882,
                        54.620428778000175
                    ],
                    [
                        -1.042062954999921,
                        54.61709219000015
                    ],
                    [
                        -0.993478969999956,
                        54.598130601000136
                    ],
                    [
                        -0.787912563999953,
                        54.56077708500008
                    ],
                    [
                        -0.562977667999888,
                        54.47736237200009
                    ],
                    [
                        -0.534657355999855,
                        54.461004950000174
                    ],
                    [
                        -0.5228572259999,
                        54.44708893400009
                    ],
                    [
                        -0.521799282999922,
                        54.438055731000176
                    ],
                    [
                        -0.523589647999927,
                        54.43024323100009
                    ],
                    [
                        -0.520415818999936,
                        54.42007070500013
                    ],
                    [
                        -0.509999152999853,
                        54.411566473
                    ],
                    [
                        -0.476796027999853,
                        54.39720286700013
                    ],
                    [
                        -0.462757941999882,
                        54.388983466000056
                    ],
                    [
                        -0.44774329299986,
                        54.3731957050001
                    ],
                    [
                        -0.430572068999936,
                        54.34931061400012
                    ],
                    [
                        -0.41844641799986,
                        54.32404205900015
                    ],
                    [
                        -0.418039516999926,
                        54.303941148000106
                    ],
                    [
                        -0.41454016799986,
                        54.29730866100006
                    ],
                    [
                        -0.409006313999924,
                        54.29075755400008
                    ],
                    [
                        -0.401193813999924,
                        54.28563060100005
                    ],
                    [
                        -0.390736456999946,
                        54.28351471600014
                    ],
                    [
                        -0.394886847999913,
                        54.273138739000146
                    ],
                    [
                        -0.397572394999884,
                        54.269191799000154
                    ],
                    [
                        -0.38927161399991,
                        54.26406484600015
                    ],
                    [
                        -0.370472785999937,
                        54.247707424000126
                    ],
                    [
                        -0.36278235599994,
                        54.242499091000056
                    ],
                    [
                        -0.322010870999861,
                        54.23607005400011
                    ],
                    [
                        -0.3119197259999,
                        54.23163483300006
                    ],
                    [
                        -0.300119594999927,
                        54.224676825000145
                    ],
                    [
                        -0.277088995999861,
                        54.21775950700005
                    ],
                    [
                        -0.269602016999897,
                        54.2076683610001
                    ],
                    [
                        -0.262562628999916,
                        54.18109772300015
                    ],
                    [
                        -0.260365363999938,
                        54.17698802300002
                    ],
                    [
                        -0.233876105999855,
                        54.16046784100003
                    ],
                    [
                        -0.111439581999889,
                        54.131577867000104
                    ],
                    [
                        -0.075510219999899,
                        54.112127997000115
                    ],
                    [
                        -0.163929816999939,
                        54.08340078300016
                    ],
                    [
                        -0.194691535999937,
                        54.062323309000035
                    ],
                    [
                        -0.219471808999884,
                        54.02277252800009
                    ],
                    [
                        -0.197417772999898,
                        53.994818427000055
                    ],
                    [
                        -0.168690558999856,
                        53.92934804900001
                    ],
                    [
                        -0.151112433999913,
                        53.899318752000156
                    ],
                    [
                        -0.045155402999853,
                        53.80121491100006
                    ],
                    [
                        0.133474155000044,
                        53.642645575000145
                    ],
                    [
                        0.149261915000068,
                        53.609605210000026
                    ],
                    [
                        0.136892123000052,
                        53.57713450700011
                    ],
                    [
                        0.131195509000065,
                        53.573431708000086
                    ],
                    [
                        0.109629754000139,
                        53.56289297100001
                    ],
                    [
                        0.131358269000117,
                        53.59369538
                    ],
                    [
                        0.135996941000144,
                        53.61066315300015
                    ],
                    [
                        0.120209181000092,
                        53.61810944200012
                    ],
                    [
                        0.099457227000102,
                        53.62230052300008
                    ],
                    [
                        0.056162957000112,
                        53.641262111000074
                    ],
                    [
                        0.034515821000127,
                        53.64606354400017
                    ],
                    [
                        0.014821811000076,
                        53.64325592700011
                    ],
                    [
                        -0.02391516799986,
                        53.62885163000006
                    ],
                    [
                        -0.045033331999946,
                        53.62555573100012
                    ],
                    [
                        -0.069406704999921,
                        53.62693919499999
                    ],
                    [
                        -0.092274542999917,
                        53.63104889500015
                    ],
                    [
                        -0.112619594999927,
                        53.637518622000144
                    ],
                    [
                        -0.130034959999904,
                        53.64606354400017
                    ],
                    [
                        -0.225331183999856,
                        53.719916083000086
                    ],
                    [
                        -0.260365363999938,
                        53.73541901200018
                    ],
                    [
                        -0.295643683999884,
                        53.7387962910001
                    ],
                    [
                        -0.30516516799986,
                        53.739691473
                    ],
                    [
                        -0.431141730999911,
                        53.714300848000065
                    ],
                    [
                        -0.449696417999917,
                        53.714300848000065
                    ],
                    [
                        -0.510487433999856,
                        53.714300848000065
                    ],
                    [
                        -0.544422980999883,
                        53.70945872599999
                    ],
                    [
                        -0.551503058999856,
                        53.71116771
                    ],
                    [
                        -0.576242641999926,
                        53.725653387000065
                    ],
                    [
                        -0.579172329999949,
                        53.727972723
                    ],
                    [
                        -0.63703365799995,
                        53.73200104400017
                    ],
                    [
                        -0.658355272999927,
                        53.727972723
                    ],
                    [
                        -0.726470506999874,
                        53.70062897300012
                    ],
                    [
                        -0.717355923999889,
                        53.69867584800009
                    ],
                    [
                        -0.708566860999895,
                        53.69574616100006
                    ],
                    [
                        -0.700184699999852,
                        53.69183991100009
                    ],
                    [
                        -0.692453579999921,
                        53.68699778900002
                    ],
                    [
                        -0.67747962099989,
                        53.70282623900012
                    ],
                    [
                        -0.6498103509999,
                        53.710598049000126
                    ],
                    [
                        -0.619536912999905,
                        53.71185944200015
                    ],
                    [
                        -0.596180792999917,
                        53.70807526200012
                    ],
                    [
                        -0.555490688999924,
                        53.69049713700018
                    ],
                    [
                        -0.544748501999919,
                        53.68357982
                    ],
                    [
                        -0.52993730399993,
                        53.67767975500006
                    ],
                    [
                        -0.514027472999913,
                        53.68130117400001
                    ],
                    [
                        -0.486317511999857,
                        53.694484768000066
                    ],
                    [
                        -0.307240363999881,
                        53.71426015800007
                    ],
                    [
                        -0.279367641999926,
                        53.70709870000006
                    ],
                    [
                        -0.260365363999938,
                        53.68699778900002
                    ],
                    [
                        -0.188628709999932,
                        53.62042877800009
                    ],
                    [
                        -0.144032355999855,
                        53.606634833
                    ],
                    [
                        -0.113189256999902,
                        53.584621486000074
                    ],
                    [
                        -0.095936652999882,
                        53.57713450700011
                    ],
                    [
                        -0.060943162999877,
                        53.57396067900005
                    ],
                    [
                        -0.04662024599989,
                        53.570379950000174
                    ],
                    [
                        0.000091993000098,
                        53.53534577000009
                    ],
                    [
                        0.09595787900011,
                        53.488348700000145
                    ],
                    [
                        0.115082227000073,
                        53.483221747000144
                    ],
                    [
                        0.134043816000087,
                        53.48061758000016
                    ],
                    [
                        0.151866082000083,
                        53.47581614800008
                    ],
                    [
                        0.167816602000073,
                        53.4645042990001
                    ],
                    [
                        0.170909050000148,
                        53.457017320000105
                    ],
                    [
                        0.173106316000144,
                        53.44676341399999
                    ],
                    [
                        0.175791863000114,
                        53.43768952000009
                    ],
                    [
                        0.181488477000102,
                        53.433742580000015
                    ],
                    [
                        0.190603061000104,
                        53.43187083500014
                    ],
                    [
                        0.219493035000113,
                        53.419501044000086
                    ],
                    [
                        0.211924675000063,
                        53.412665106000176
                    ],
                    [
                        0.229340040000125,
                        53.40477122600011
                    ],
                    [
                        0.244883660000141,
                        53.39093659100011
                    ],
                    [
                        0.256114129000139,
                        53.375067450000174
                    ],
                    [
                        0.260427280000044,
                        53.36147695500013
                    ],
                    [
                        0.342133009000122,
                        53.22630442900011
                    ],
                    [
                        0.356211785000085,
                        53.188544012000065
                    ],
                    [
                        0.356618686000076,
                        53.14573802300005
                    ],
                    [
                        0.335785352000102,
                        53.09345123900006
                    ],
                    [
                        0.32984459700009,
                        53.08649323100015
                    ],
                    [
                        0.318695509000065,
                        53.08368561400012
                    ],
                    [
                        0.298838738000143,
                        53.081203518000095
                    ],
                    [
                        0.282481316000144,
                        53.07485586100016
                    ],
                    [
                        0.19304446700005,
                        53.020086981000034
                    ],
                    [
                        0.174489780000044,
                        53.01544830900009
                    ],
                    [
                        0.160817905000101,
                        53.008978583
                    ],
                    [
                        0.082123243000098,
                        52.938666083000086
                    ],
                    [
                        0.061167839000063,
                        52.92499420800014
                    ],
                    [
                        0.037608269000117,
                        52.91917552299999
                    ],
                    [
                        0.022146030000101,
                        52.91258372600011
                    ],
                    [
                        0.008555535000141,
                        52.89862702000015
                    ],
                    [
                        0.010264519000145,
                        52.88646067900005
                    ],
                    [
                        0.040660027000115,
                        52.88507721600017
                    ],
                    [
                        0.092539910000085,
                        52.89252350500006
                    ],
                    [
                        0.105642123000081,
                        52.889349677
                    ],
                    [
                        0.133474155000044,
                        52.87518952000006
                    ],
                    [
                        0.147146030000073,
                        52.872015692
                    ],
                    [
                        0.170909050000148,
                        52.86212799700009
                    ],
                    [
                        0.222422722000147,
                        52.813666083
                    ],
                    [
                        0.246755405000101,
                        52.795721747000144
                    ],
                    [
                        0.264496290000096,
                        52.80369700700011
                    ],
                    [
                        0.285655144000117,
                        52.80556875200007
                    ],
                    [
                        0.32553144600007,
                        52.803168036000116
                    ],
                    [
                        0.341807488000114,
                        52.79743073100015
                    ],
                    [
                        0.369883660000113,
                        52.773016669000114
                    ],
                    [
                        0.384532097000118,
                        52.76837799700009
                    ],
                    [
                        0.384532097000118,
                        52.77521393400009
                    ],
                    [
                        0.379242384000065,
                        52.79083893400018
                    ],
                    [
                        0.399424675000091,
                        52.80951569200006
                    ],
                    [
                        0.425547722000118,
                        52.827378648000135
                    ],
                    [
                        0.438649936000104,
                        52.84072500200013
                    ],
                    [
                        0.444021030000073,
                        52.86505768400012
                    ],
                    [
                        0.456716342000107,
                        52.89199453300007
                    ],
                    [
                        0.472341342000107,
                        52.916449286000116
                    ],
                    [
                        0.485687696000099,
                        52.933498440000065
                    ],
                    [
                        0.520274285000113,
                        52.95526764500012
                    ],
                    [
                        0.569590691000087,
                        52.969305731000176
                    ],
                    [
                        0.623383009000065,
                        52.97565338700012
                    ],
                    [
                        0.671885613000114,
                        52.97443268400009
                    ],
                    [
                        0.664398634000065,
                        52.981878973000065
                    ],
                    [
                        0.684743686000047,
                        52.98639557500003
                    ],
                    [
                        0.706797722000147,
                        52.983221747000144
                    ],
                    [
                        0.729177280000073,
                        52.977484442
                    ],
                    [
                        0.750498894000117,
                        52.97443268400009
                    ],
                    [
                        0.835703972000118,
                        52.97443268400009
                    ],
                    [
                        0.875498894000089,
                        52.96816640800013
                    ],
                    [
                        0.921071811000047,
                        52.95465729400017
                    ],
                    [
                        0.968435092000107,
                        52.94749583500014
                    ],
                    [
                        1.013926629000082,
                        52.960150458
                    ],
                    [
                        1.003428582000083,
                        52.96474844000012
                    ],
                    [
                        0.991547071000042,
                        52.96747467700011
                    ],
                    [
                        0.979014519000145,
                        52.9684105490001
                    ],
                    [
                        0.966075066000144,
                        52.96759674700017
                    ],
                    [
                        0.966075066000144,
                        52.97443268400009
                    ],
                    [
                        1.274668816000116,
                        52.929185289000074
                    ],
                    [
                        1.39600670700014,
                        52.891424872000115
                    ],
                    [
                        1.644216342000107,
                        52.77582428600006
                    ],
                    [
                        1.673350457000055,
                        52.75568268400012
                    ],
                    [
                        1.69703209700009,
                        52.731024481000034
                    ],
                    [
                        1.71583092500012,
                        52.68374258000007
                    ],
                    [
                        1.73414147200009,
                        52.65460846600011
                    ],
                    [
                        1.747243686000076,
                        52.62518952000012
                    ],
                    [
                        1.74073326900006,
                        52.60390859600015
                    ],
                    [
                        1.746836785000141,
                        52.58852773600002
                    ],
                    [
                        1.748708530000016,
                        52.56879303600009
                    ],
                    [
                        1.747569207000083,
                        52.532782294000114
                    ],
                    [
                        1.771169467000021,
                        52.48590729400014
                    ],
                    [
                        1.767100457000055,
                        52.476955471000124
                    ],
                    [
                        1.754405144000089,
                        52.46735260600015
                    ],
                    [
                        1.729258660000113,
                        52.41559479400003
                    ],
                    [
                        1.730153842000107,
                        52.40591054900007
                    ],
                    [
                        1.727875196000127,
                        52.39630768400009
                    ],
                    [
                        1.683604363000086,
                        52.32453034100011
                    ],
                    [
                        1.65186608200014,
                        52.28912995000009
                    ],
                    [
                        1.641123894000117,
                        52.28180573100009
                    ],
                    [
                        1.630544467000078,
                        52.27045319200012
                    ],
                    [
                        1.628265821000099,
                        52.244818427000055
                    ],
                    [
                        1.630869988000086,
                        52.199774481000176
                    ],
                    [
                        1.625498894000117,
                        52.180365302000055
                    ],
                    [
                        1.607432488000114,
                        52.14594147300012
                    ],
                    [
                        1.603526238000114,
                        52.12783437700004
                    ],
                    [
                        1.589203321000127,
                        52.10081614800008
                    ],
                    [
                        1.587575717000107,
                        52.08698151200018
                    ],
                    [
                        1.582286004000139,
                        52.08148834800015
                    ],
                    [
                        1.503672722000118,
                        52.060980536
                    ],
                    [
                        1.486827019000117,
                        52.04901764500006
                    ],
                    [
                        1.47201582100007,
                        52.056219794000086
                    ],
                    [
                        1.466807488000143,
                        52.048000393
                    ],
                    [
                        1.46387780000012,
                        52.03514232000008
                    ],
                    [
                        1.455821160000113,
                        52.02850983300003
                    ],
                    [
                        1.44898522200009,
                        52.02496979400003
                    ],
                    [
                        1.424815300000091,
                        52.001857815000065
                    ],
                    [
                        1.35482832100007,
                        51.95404694200015
                    ],
                    [
                        1.34351647200009,
                        51.94285716400013
                    ],
                    [
                        1.332286004000082,
                        51.940090236000074
                    ],
                    [
                        1.264414910000085,
                        51.99437083500011
                    ],
                    [
                        1.234873894000117,
                        52.00031159100003
                    ],
                    [
                        1.185231967000021,
                        52.025091864
                    ],
                    [
                        1.15788821700005,
                        52.02850983300003
                    ],
                    [
                        1.15788821700005,
                        52.02167389500009
                    ],
                    [
                        1.193532748000109,
                        52.00079987200003
                    ],
                    [
                        1.214366082000083,
                        51.99144114799999
                    ],
                    [
                        1.260264519000145,
                        51.984808661000116
                    ],
                    [
                        1.270762566000116,
                        51.98151276200018
                    ],
                    [
                        1.275157097000118,
                        51.976996161000116
                    ],
                    [
                        1.274424675000091,
                        51.96088288000006
                    ],
                    [
                        1.271006707000083,
                        51.95644765800007
                    ],
                    [
                        1.250743035000056,
                        51.95962148600013
                    ],
                    [
                        1.238942905000101,
                        51.95864492400007
                    ],
                    [
                        1.217621290000125,
                        51.95433177300008
                    ],
                    [
                        1.205739780000073,
                        51.953436591000084
                    ],
                    [
                        1.193207227000073,
                        51.955511786
                    ],
                    [
                        1.165293816000144,
                        51.96702708500014
                    ],
                    [
                        1.143077019000089,
                        51.96605052300008
                    ],
                    [
                        1.094493035000113,
                        51.9559593770001
                    ],
                    [
                        1.069102410000085,
                        51.953436591000084
                    ],
                    [
                        1.09546959700009,
                        51.94574616100006
                    ],
                    [
                        1.262054884000037,
                        51.93915436400009
                    ],
                    [
                        1.281993035000113,
                        51.94660065300015
                    ],
                    [
                        1.278493686000047,
                        51.92792389500009
                    ],
                    [
                        1.199554884000094,
                        51.877671617000104
                    ],
                    [
                        1.21225019600007,
                        51.87164948100012
                    ],
                    [
                        1.22787519600007,
                        51.86737702
                    ],
                    [
                        1.264414910000085,
                        51.86399974199999
                    ],
                    [
                        1.274750196000099,
                        51.870306708000115
                    ],
                    [
                        1.282237175000063,
                        51.880560614000146
                    ],
                    [
                        1.28663170700014,
                        51.88165924700009
                    ],
                    [
                        1.288259311000076,
                        51.86058177300008
                    ],
                    [
                        1.27637780000012,
                        51.84479401200012
                    ],
                    [
                        1.219248894000145,
                        51.81183502800015
                    ],
                    [
                        1.167816602000045,
                        51.79022858300006
                    ],
                    [
                        1.135427280000044,
                        51.78172435100011
                    ],
                    [
                        1.065440300000148,
                        51.775295315
                    ],
                    [
                        1.04664147200009,
                        51.777044989
                    ],
                    [
                        1.037282748000052,
                        51.78217194200012
                    ],
                    [
                        1.021332227000045,
                        51.80255768400009
                    ],
                    [
                        0.988780144000089,
                        51.83026764500009
                    ],
                    [
                        0.97982832100007,
                        51.84357330900009
                    ],
                    [
                        0.977061394000089,
                        51.824611721000096
                    ],
                    [
                        0.962087436000076,
                        51.81378815300009
                    ],
                    [
                        0.925140821000099,
                        51.80255768400009
                    ],
                    [
                        0.894297722000147,
                        51.78461334800015
                    ],
                    [
                        0.886973504000139,
                        51.78213125200013
                    ],
                    [
                        0.886403842000078,
                        51.77667877800009
                    ],
                    [
                        0.883474155000073,
                        51.76471588700015
                    ],
                    [
                        0.878916863000114,
                        51.75275299700003
                    ],
                    [
                        0.873708530000016,
                        51.74730052299999
                    ],
                    [
                        0.861989780000044,
                        51.74555084800009
                    ],
                    [
                        0.845957879000139,
                        51.736761786000116
                    ],
                    [
                        0.835703972000118,
                        51.73371002800003
                    ],
                    [
                        0.823496941000144,
                        51.733832098
                    ],
                    [
                        0.801442905000044,
                        51.73969147300015
                    ],
                    [
                        0.791758660000085,
                        51.741156317
                    ],
                    [
                        0.767100457000083,
                        51.739447333
                    ],
                    [
                        0.720876498000081,
                        51.72817617400001
                    ],
                    [
                        0.698578321000042,
                        51.72003815300009
                    ],
                    [
                        0.714203321000127,
                        51.715277411
                    ],
                    [
                        0.732432488000114,
                        51.71377187700013
                    ],
                    [
                        0.749278191000116,
                        51.70848216400016
                    ],
                    [
                        0.760752800000148,
                        51.69269440300003
                    ],
                    [
                        0.789073113000114,
                        51.710150458000086
                    ],
                    [
                        0.853037957000112,
                        51.71889883000016
                    ],
                    [
                        0.883555535000141,
                        51.72687409100011
                    ],
                    [
                        0.912364129000082,
                        51.74152252800003
                    ],
                    [
                        0.930023634000094,
                        51.74359772300015
                    ],
                    [
                        0.945567254000082,
                        51.73371002800003
                    ],
                    [
                        0.947438998000052,
                        51.7252464860001
                    ],
                    [
                        0.944590691000116,
                        51.71548086100013
                    ],
                    [
                        0.940440300000063,
                        51.707993882
                    ],
                    [
                        0.938161655000101,
                        51.70636627800015
                    ],
                    [
                        0.940196160000113,
                        51.698431708
                    ],
                    [
                        0.944021030000044,
                        51.690578518
                    ],
                    [
                        0.951833530000044,
                        51.67841217700011
                    ],
                    [
                        0.941742384000065,
                        51.673041083000086
                    ],
                    [
                        0.937998894000145,
                        51.664496161000145
                    ],
                    [
                        0.938161655000101,
                        51.641262111000046
                    ],
                    [
                        0.934743686000104,
                        51.635891018000066
                    ],
                    [
                        0.92676842500012,
                        51.63092682500012
                    ],
                    [
                        0.91146894600007,
                        51.623846747000144
                    ],
                    [
                        0.93848717500012,
                        51.62498607
                    ],
                    [
                        0.948090040000096,
                        51.621527411
                    ],
                    [
                        0.951833530000044,
                        51.617010809000035
                    ],
                    [
                        0.924489780000073,
                        51.58832428600009
                    ],
                    [
                        0.87330162900011,
                        51.559475002000156
                    ],
                    [
                        0.828298373000024,
                        51.54185618700005
                    ],
                    [
                        0.816254102000102,
                        51.53717682500012
                    ],
                    [
                        0.771250847000118,
                        51.52826569200009
                    ],
                    [
                        0.692067905000044,
                        51.536322333000115
                    ],
                    [
                        0.664398634000065,
                        51.53506094
                    ],
                    [
                        0.663747592000107,
                        51.535549221000096
                    ],
                    [
                        0.650563998000052,
                        51.53579336100013
                    ],
                    [
                        0.646657748000052,
                        51.53534577000012
                    ],
                    [
                        0.644053582000055,
                        51.53506094
                    ],
                    [
                        0.642751498000081,
                        51.53351471600014
                    ],
                    [
                        0.623383009000065,
                        51.52204010600012
                    ],
                    [
                        0.608653191000144,
                        51.51898834800012
                    ],
                    [
                        0.595225457000055,
                        51.518622137000094
                    ],
                    [
                        0.582286004000053,
                        51.51654694200009
                    ],
                    [
                        0.56885826900006,
                        51.508368231000176
                    ],
                    [
                        0.552744988000086,
                        51.51797109600015
                    ],
                    [
                        0.547211134000065,
                        51.517726955000015
                    ],
                    [
                        0.525401238000114,
                        51.51691315300009
                    ],
                    [
                        0.456065300000063,
                        51.50556061400012
                    ],
                    [
                        0.450856967000078,
                        51.49827708500011
                    ],
                    [
                        0.448008660000141,
                        51.48822663000014
                    ],
                    [
                        0.44166100400011,
                        51.477036851000136
                    ],
                    [
                        0.428477410000141,
                        51.46710846600003
                    ],
                    [
                        0.414073113000086,
                        51.46190013200014
                    ],
                    [
                        0.399180535000141,
                        51.45840078300013
                    ],
                    [
                        0.384532097000118,
                        51.45311107000008
                    ],
                    [
                        0.448415561000047,
                        51.459947007
                    ],
                    [
                        0.45679772200009,
                        51.463120835000055
                    ],
                    [
                        0.461436394000117,
                        51.47016022300012
                    ],
                    [
                        0.464854363000143,
                        51.477240302
                    ],
                    [
                        0.469574415000039,
                        51.48041413000006
                    ],
                    [
                        0.53484134200005,
                        51.491685289000046
                    ],
                    [
                        0.694834832000112,
                        51.477036851000136
                    ],
                    [
                        0.709483269000117,
                        51.470526434000035
                    ],
                    [
                        0.721934441000144,
                        51.456284898000135
                    ],
                    [
                        0.723155144000145,
                        51.44672272300015
                    ],
                    [
                        0.713145379000082,
                        51.44122955900012
                    ],
                    [
                        0.692393425000091,
                        51.43952057500012
                    ],
                    [
                        0.656504754000082,
                        51.44452545800006
                    ],
                    [
                        0.640961134000094,
                        51.44415924700006
                    ],
                    [
                        0.609385613000086,
                        51.42511627800015
                    ],
                    [
                        0.572764519000145,
                        51.419582424000126
                    ],
                    [
                        0.554535352000073,
                        51.41217682500006
                    ],
                    [
                        0.56226647200009,
                        51.40656159100014
                    ],
                    [
                        0.569102410000113,
                        51.39960358300006
                    ],
                    [
                        0.576508009000094,
                        51.39362213700004
                    ],
                    [
                        0.585948113000114,
                        51.391058661000116
                    ],
                    [
                        0.671885613000114,
                        51.391058661000116
                    ],
                    [
                        0.712901238000114,
                        51.384222723
                    ],
                    [
                        0.704925977000073,
                        51.39598216400019
                    ],
                    [
                        0.700368686000047,
                        51.4098167990001
                    ],
                    [
                        0.705332879000082,
                        51.41950104400014
                    ],
                    [
                        0.726410352000102,
                        51.41901276200015
                    ],
                    [
                        0.728363477000045,
                        51.406236070000105
                    ],
                    [
                        0.73926842500012,
                        51.38792552300005
                    ],
                    [
                        0.75342858200014,
                        51.371527411000145
                    ],
                    [
                        0.764414910000113,
                        51.364406643
                    ],
                    [
                        0.977224155000073,
                        51.34918854400003
                    ],
                    [
                        1.100922071000127,
                        51.37323639500015
                    ],
                    [
                        1.423838738000114,
                        51.39207591400019
                    ],
                    [
                        1.448252800000148,
                        51.38287995000003
                    ],
                    [
                        1.439138217000078,
                        51.35008372600011
                    ],
                    [
                        1.435557488000086,
                        51.34373607
                    ],
                    [
                        1.430837436000076,
                        51.33779531500015
                    ],
                    [
                        1.425140821000099,
                        51.33295319200009
                    ],
                    [
                        1.418630405000101,
                        51.32965729400006
                    ],
                    [
                        1.382985873000052,
                        51.32977936400003
                    ],
                    [
                        1.377614780000101,
                        51.32623932500003
                    ],
                    [
                        1.38062584700009,
                        51.30475495000009
                    ],
                    [
                        1.387868686000047,
                        51.28790924700009
                    ],
                    [
                        1.404307488000114,
                        51.261379299000126
                    ],
                    [
                        1.413096550000091,
                        51.22174713700012
                    ],
                    [
                        1.404795769000117,
                        51.18353913000006
                    ],
                    [
                        1.384450717000107,
                        51.15167877800003
                    ],
                    [
                        1.357188347000118,
                        51.131008205000015
                    ],
                    [
                        1.290375196000099,
                        51.11652252800015
                    ],
                    [
                        1.262950066000144,
                        51.10325755400014
                    ],
                    [
                        1.25163821700005,
                        51.102525132000025
                    ],
                    [
                        1.229991082000055,
                        51.10370514500012
                    ],
                    [
                        1.218109571000099,
                        51.10097890800016
                    ],
                    [
                        1.208262566000087,
                        51.09467194200012
                    ],
                    [
                        1.19971764400006,
                        51.08759186400006
                    ],
                    [
                        1.192067905000044,
                        51.08258698100012
                    ],
                    [
                        1.172211134000122,
                        51.077378648000106
                    ],
                    [
                        1.106700066000087,
                        51.07636139500006
                    ],
                    [
                        1.087168816000116,
                        51.07298411700005
                    ],
                    [
                        1.066905144000117,
                        51.06439850500011
                    ],
                    [
                        1.02759850400011,
                        51.0416527360001
                    ],
                    [
                        0.976410352000045,
                        50.99445221600011
                    ],
                    [
                        0.96648196700005,
                        50.98273346600003
                    ],
                    [
                        0.969086134000037,
                        50.95685455900012
                    ],
                    [
                        0.97982832100007,
                        50.91815827000006
                    ],
                    [
                        0.945160352000073,
                        50.909369208
                    ],
                    [
                        0.869802280000016,
                        50.92511627800015
                    ],
                    [
                        0.80225670700014,
                        50.939195054000024
                    ],
                    [
                        0.762461785000056,
                        50.930894273000106
                    ],
                    [
                        0.664398634000065,
                        50.87030670800014
                    ],
                    [
                        0.624196811000076,
                        50.85871002800003
                    ],
                    [
                        0.407481316000116,
                        50.829331773000135
                    ],
                    [
                        0.370290561000019,
                        50.81879303600006
                    ],
                    [
                        0.365244988000086,
                        50.81899648600002
                    ],
                    [
                        0.354258660000113,
                        50.80963776200018
                    ],
                    [
                        0.344411655000101,
                        50.79905833500014
                    ],
                    [
                        0.342295769000089,
                        50.79515208500014
                    ],
                    [
                        0.308116082000112,
                        50.78095123900012
                    ],
                    [
                        0.271006707000083,
                        50.74738190300009
                    ],
                    [
                        0.233897332000083,
                        50.74701569200009
                    ],
                    [
                        0.214121941000144,
                        50.74892812700013
                    ],
                    [
                        0.168304884000065,
                        50.75983307500012
                    ],
                    [
                        0.157399936000076,
                        50.76105377800003
                    ],
                    [
                        0.122080925000148,
                        50.76080963700018
                    ],
                    [
                        0.113047722000147,
                        50.76414622600011
                    ],
                    [
                        0.095550977000102,
                        50.7750511740001
                    ],
                    [
                        0.077036980000088,
                        50.77899811400009
                    ],
                    [
                        0.034515821000127,
                        50.78095123900012
                    ],
                    [
                        0.017548048000151,
                        50.78485748900012
                    ],
                    [
                        -0.173166469999899,
                        50.82900625200001
                    ],
                    [
                        -0.206125454999892,
                        50.83104075700014
                    ],
                    [
                        -0.208892381999931,
                        50.83010488500004
                    ],
                    [
                        -0.233143683999941,
                        50.821926174000126
                    ],
                    [
                        -0.26968339799987,
                        50.83128489800008
                    ],
                    [
                        -0.397572394999884,
                        50.80206940300003
                    ],
                    [
                        -0.56895911399991,
                        50.80206940300003
                    ],
                    [
                        -0.732736782999922,
                        50.76727936400009
                    ],
                    [
                        -0.74156653599988,
                        50.76923248900012
                    ],
                    [
                        -0.749867316999939,
                        50.77387116100006
                    ],
                    [
                        -0.758208787999905,
                        50.77708567900011
                    ],
                    [
                        -0.767567511999857,
                        50.77472565300015
                    ],
                    [
                        -0.773101365999878,
                        50.76601797100007
                    ],
                    [
                        -0.766265428999873,
                        50.747137762000065
                    ],
                    [
                        -0.770985480999855,
                        50.73688385600012
                    ],
                    [
                        -0.789947068999879,
                        50.730047919000114
                    ],
                    [
                        -0.812814907999893,
                        50.73476797100001
                    ],
                    [
                        -0.904123501999948,
                        50.771877346000124
                    ],
                    [
                        -0.911488410999937,
                        50.77781810100005
                    ],
                    [
                        -0.905873175999943,
                        50.78803131700009
                    ],
                    [
                        -0.892486131999931,
                        50.79462311400006
                    ],
                    [
                        -0.863636847999913,
                        50.80206940300003
                    ],
                    [
                        -0.863636847999913,
                        50.80825429900001
                    ],
                    [
                        -0.873605923999918,
                        50.812933661000116
                    ],
                    [
                        -0.893137173999889,
                        50.82534414300015
                    ],
                    [
                        -0.904693162999905,
                        50.829331773000135
                    ],
                    [
                        -0.909535285999908,
                        50.82599518400009
                    ],
                    [
                        -0.921701626999891,
                        50.82160065300012
                    ],
                    [
                        -0.928944464999915,
                        50.822821356000034
                    ],
                    [
                        -0.919016079999892,
                        50.83616771000014
                    ],
                    [
                        -0.929798956999917,
                        50.83999258000016
                    ],
                    [
                        -0.939198370999918,
                        50.8432477890001
                    ],
                    [
                        -0.970773891999926,
                        50.84699127800003
                    ],
                    [
                        -0.994862433999856,
                        50.847072658000016
                    ],
                    [
                        -1.001861131999931,
                        50.847113348
                    ],
                    [
                        -1.020741339999859,
                        50.84365469
                    ],
                    [
                        -1.033558722999885,
                        50.82591380400011
                    ],
                    [
                        -1.042632615999906,
                        50.801906643000066
                    ],
                    [
                        -1.056223110999952,
                        50.783026434000035
                    ],
                    [
                        -1.082753058999913,
                        50.78095123900012
                    ],
                    [
                        -1.103016730999912,
                        50.79547760600015
                    ],
                    [
                        -1.093617316999911,
                        50.812933661000116
                    ],
                    [
                        -1.075306769999941,
                        50.83002350500006
                    ],
                    [
                        -1.069162563999953,
                        50.84365469
                    ],
                    [
                        -1.087310350999871,
                        50.84979889500009
                    ],
                    [
                        -1.088124152999882,
                        50.8497582050001
                    ],
                    [
                        -1.1498103509999,
                        50.84666575700011
                    ],
                    [
                        -1.16470292899993,
                        50.84365469
                    ],
                    [
                        -1.129709438999953,
                        50.82510000200001
                    ],
                    [
                        -1.123768683999913,
                        50.81879303600006
                    ],
                    [
                        -1.121205206999917,
                        50.79938385600015
                    ],
                    [
                        -1.124908006999874,
                        50.788723049000126
                    ],
                    [
                        -1.138050910999908,
                        50.78095123900012
                    ],
                    [
                        -1.151112433999884,
                        50.781398830000015
                    ],
                    [
                        -1.169341600999871,
                        50.78587474199999
                    ],
                    [
                        -1.185617641999897,
                        50.792425848000065
                    ],
                    [
                        -1.192616339999887,
                        50.798651434000035
                    ],
                    [
                        -1.199289516999926,
                        50.807928778000175
                    ],
                    [
                        -1.231516079999892,
                        50.818915106000034
                    ],
                    [
                        -1.255197719999899,
                        50.83193594000012
                    ],
                    [
                        -1.288156704999892,
                        50.83917877800015
                    ],
                    [
                        -1.301421678999901,
                        50.84365469
                    ],
                    [
                        -1.318592902999853,
                        50.856919664000046
                    ],
                    [
                        -1.338937954999921,
                        50.8727074240001
                    ],
                    [
                        -1.449289516999897,
                        50.912054755000085
                    ],
                    [
                        -1.466420050999915,
                        50.91815827000006
                    ],
                    [
                        -1.466420050999915,
                        50.91193268400012
                    ],
                    [
                        -1.456450975999928,
                        50.90875885600006
                    ],
                    [
                        -1.437977667999917,
                        50.90131256700006
                    ],
                    [
                        -1.425607876999948,
                        50.900091864000146
                    ],
                    [
                        -1.401112433999856,
                        50.88117096600003
                    ],
                    [
                        -1.323068813999896,
                        50.82575104400014
                    ],
                    [
                        -1.314279751999919,
                        50.81216054900001
                    ],
                    [
                        -1.317290818999936,
                        50.800238348000065
                    ],
                    [
                        -1.339833136999914,
                        50.79515208500014
                    ],
                    [
                        -1.403960740999878,
                        50.79157135600015
                    ],
                    [
                        -1.418690558999884,
                        50.78839752800009
                    ],
                    [
                        -1.411366339999859,
                        50.78489817900011
                    ],
                    [
                        -1.404937303999958,
                        50.78095123900012
                    ],
                    [
                        -1.404937303999958,
                        50.77472565300015
                    ],
                    [
                        -1.450510219999899,
                        50.76935455900009
                    ],
                    [
                        -1.476226365999935,
                        50.763413804
                    ],
                    [
                        -1.487538214999944,
                        50.75771719
                    ],
                    [
                        -1.491932745999918,
                        50.757269598
                    ],
                    [
                        -1.524728969999927,
                        50.76105377800003
                    ],
                    [
                        -1.557199673999918,
                        50.72329336100016
                    ],
                    [
                        -1.559396938999896,
                        50.71922435100011
                    ],
                    [
                        -1.561187303999901,
                        50.718980210000055
                    ],
                    [
                        -1.580718553999873,
                        50.722642320000105
                    ],
                    [
                        -1.602121548999918,
                        50.73175690300012
                    ],
                    [
                        -1.670643683999856,
                        50.74005768400018
                    ],
                    [
                        -1.672922329999921,
                        50.74005768400018
                    ],
                    [
                        -1.693023240999878,
                        50.73993561400012
                    ],
                    [
                        -1.715077277999882,
                        50.735785223000065
                    ],
                    [
                        -1.753529425999886,
                        50.72284577000006
                    ],
                    [
                        -1.771717902999882,
                        50.7201195330001
                    ],
                    [
                        -1.797434048999918,
                        50.723171291000185
                    ],
                    [
                        -1.835072394999912,
                        50.72768789300015
                    ],
                    [
                        -1.857411261999857,
                        50.72695547100004
                    ],
                    [
                        -1.877349412999934,
                        50.72284577000006
                    ],
                    [
                        -1.896351691999911,
                        50.71629466400019
                    ],
                    [
                        -1.896880662999905,
                        50.71605052300005
                    ],
                    [
                        -1.913889126999948,
                        50.707424221000124
                    ],
                    [
                        -1.929107225999928,
                        50.69586823100012
                    ],
                    [
                        -1.940012173999918,
                        50.69082265800007
                    ],
                    [
                        -1.944325324999909,
                        50.69765859600001
                    ],
                    [
                        -1.946115688999896,
                        50.70791250200013
                    ],
                    [
                        -1.949289516999954,
                        50.71332428600009
                    ],
                    [
                        -1.9877009759999,
                        50.7201195330001
                    ],
                    [
                        -2.01390540299991,
                        50.71893952000006
                    ],
                    [
                        -2.02196204299986,
                        50.7201195330001
                    ],
                    [
                        -2.025461391999954,
                        50.723863023000135
                    ],
                    [
                        -2.026193813999981,
                        50.72508372600005
                    ],
                    [
                        -2.033843553999901,
                        50.737127997000144
                    ],
                    [
                        -2.038970506999931,
                        50.73993561400012
                    ],
                    [
                        -2.048085089999916,
                        50.734808661
                    ],
                    [
                        -2.082671678999901,
                        50.698960679
                    ],
                    [
                        -2.069569464999859,
                        50.70010000200013
                    ],
                    [
                        -2.057728644999884,
                        50.702826239
                    ],
                    [
                        -2.046620245999861,
                        50.70726146000008
                    ],
                    [
                        -2.035633917999888,
                        50.71332428600009
                    ],
                    [
                        -2.01414954299986,
                        50.68878815300015
                    ],
                    [
                        -2.002512173999946,
                        50.6813011740001
                    ],
                    [
                        -1.984120245999918,
                        50.67853424700003
                    ],
                    [
                        -1.967437303999873,
                        50.678656317
                    ],
                    [
                        -1.952788865999878,
                        50.67658112200017
                    ],
                    [
                        -1.945871548999946,
                        50.66885000200007
                    ],
                    [
                        -1.952951626999919,
                        50.6518415390001
                    ],
                    [
                        -1.938221808999856,
                        50.64581940300009
                    ],
                    [
                        -1.932484503999945,
                        50.64443594000004
                    ],
                    [
                        -1.967274542999917,
                        50.61709219000015
                    ],
                    [
                        -1.965199347999885,
                        50.6021996110001
                    ],
                    [
                        -1.983713344999899,
                        50.59662506700009
                    ],
                    [
                        -2.065174933999856,
                        50.595526434000035
                    ],
                    [
                        -2.082183397999927,
                        50.59784577
                    ],
                    [
                        -2.157338019999912,
                        50.62051015800016
                    ],
                    [
                        -2.342640753999945,
                        50.633775132
                    ],
                    [
                        -2.377064581999889,
                        50.647528387000094
                    ],
                    [
                        -2.398345506999959,
                        50.64557526200015
                    ],
                    [
                        -2.435902472999942,
                        50.637518622000115
                    ],
                    [
                        -2.447621222999942,
                        50.62787506700015
                    ],
                    [
                        -2.460072394999884,
                        50.606512762000094
                    ],
                    [
                        -2.465646938999981,
                        50.58515045800014
                    ],
                    [
                        -2.4564509759999,
                        50.57550690300015
                    ],
                    [
                        -2.431223110999952,
                        50.570257880000085
                    ],
                    [
                        -2.428700324999937,
                        50.55756256700012
                    ],
                    [
                        -2.439279751999891,
                        50.541815497000144
                    ],
                    [
                        -2.45335852799991,
                        50.52765534100014
                    ],
                    [
                        -2.45954342399989,
                        50.52765534100014
                    ],
                    [
                        -2.4603572259999,
                        50.56549713700018
                    ],
                    [
                        -2.483509894999941,
                        50.59039948100012
                    ],
                    [
                        -2.69294186099998,
                        50.69281647300012
                    ],
                    [
                        -2.864816860999895,
                        50.73379140800013
                    ],
                    [
                        -2.887318488999881,
                        50.734361070000105
                    ],
                    [
                        -2.94554602799991,
                        50.72581614799999
                    ],
                    [
                        -2.962757941999939,
                        50.72329336100016
                    ],
                    [
                        -2.998199022999927,
                        50.70823802300005
                    ],
                    [
                        -3.021839972999942,
                        50.70648834800015
                    ],
                    [
                        -3.059722459999904,
                        50.71332428600009
                    ],
                    [
                        -3.071359829999892,
                        50.71100495000012
                    ],
                    [
                        -3.094146287999934,
                        50.698960679
                    ],
                    [
                        -3.099232550999943,
                        50.697088934000035
                    ],
                    [
                        -3.11583411399991,
                        50.687974351000136
                    ],
                    [
                        -3.125477667999888,
                        50.68528880400005
                    ],
                    [
                        -3.135894334999875,
                        50.68593984600001
                    ],
                    [
                        -3.155140753999916,
                        50.69135163000006
                    ],
                    [
                        -3.166493292999888,
                        50.69281647300012
                    ],
                    [
                        -3.185617641999954,
                        50.69074127800009
                    ],
                    [
                        -3.219146287999905,
                        50.680812893
                    ],
                    [
                        -3.259836391999897,
                        50.67454661700005
                    ],
                    [
                        -3.271392381999903,
                        50.66449616100009
                    ],
                    [
                        -3.280425584999904,
                        50.651190497000144
                    ],
                    [
                        -3.295562303999901,
                        50.637518622000115
                    ],
                    [
                        -3.368153449999909,
                        50.61709219000015
                    ],
                    [
                        -3.390736456999889,
                        50.617824611000074
                    ],
                    [
                        -3.407541469999899,
                        50.62128327000006
                    ],
                    [
                        -3.420969204999892,
                        50.629461981000176
                    ],
                    [
                        -3.433338995999918,
                        50.64443594000004
                    ],
                    [
                        -3.446359829999921,
                        50.668280341000084
                    ],
                    [
                        -3.450428839999887,
                        50.67230866100006
                    ],
                    [
                        -3.453277147999927,
                        50.67328522300012
                    ],
                    [
                        -3.455433722999914,
                        50.675441799000126
                    ],
                    [
                        -3.458607550999886,
                        50.67755768400015
                    ],
                    [
                        -3.464426235999923,
                        50.67853424700003
                    ],
                    [
                        -3.46776282499988,
                        50.67641836100002
                    ],
                    [
                        -3.468902147999899,
                        50.67169830900009
                    ],
                    [
                        -3.467844204999949,
                        50.666978257
                    ],
                    [
                        -3.455311652999853,
                        50.657904364
                    ],
                    [
                        -3.442046678999901,
                        50.62352122600005
                    ],
                    [
                        -3.433338995999918,
                        50.61025625200013
                    ],
                    [
                        -3.451161261999914,
                        50.599432684000035
                    ],
                    [
                        -3.467274542999888,
                        50.585598049000126
                    ],
                    [
                        -3.481760219999927,
                        50.56854889500009
                    ],
                    [
                        -3.494862433999884,
                        50.54816315300009
                    ],
                    [
                        -3.505970831999917,
                        50.52081940300003
                    ],
                    [
                        -3.505034959999932,
                        50.50120677300008
                    ],
                    [
                        -3.487456834999904,
                        50.459418036000116
                    ],
                    [
                        -3.509755011999857,
                        50.458482164000046
                    ],
                    [
                        -3.532134568999879,
                        50.454779364
                    ],
                    [
                        -3.549427863999881,
                        50.446519273000106
                    ],
                    [
                        -3.556263800999886,
                        50.43211497600005
                    ],
                    [
                        -3.549305792999888,
                        50.41559479400017
                    ],
                    [
                        -3.531971808999913,
                        50.41022370000012
                    ],
                    [
                        -3.487456834999904,
                        50.411566473
                    ],
                    [
                        -3.487456834999904,
                        50.40477122599999
                    ],
                    [
                        -3.4974666009999,
                        50.38735586100002
                    ],
                    [
                        -3.49828040299991,
                        50.383693752000156
                    ],
                    [
                        -3.507313605999912,
                        50.38279857000008
                    ],
                    [
                        -3.514963344999927,
                        50.37995026200012
                    ],
                    [
                        -3.520130988999938,
                        50.37482330900012
                    ],
                    [
                        -3.526926235999952,
                        50.349798895
                    ],
                    [
                        -3.538889126999891,
                        50.34442780200014
                    ],
                    [
                        -3.554514126999891,
                        50.347642320000105
                    ],
                    [
                        -3.570546027999882,
                        50.35639069200009
                    ],
                    [
                        -3.577788865999906,
                        50.33405182500006
                    ],
                    [
                        -3.599110480999883,
                        50.325832424000126
                    ],
                    [
                        -3.62157141799986,
                        50.321926174000154
                    ],
                    [
                        -3.631988084999875,
                        50.31232330900015
                    ],
                    [
                        -3.637359178999901,
                        50.29539622599999
                    ],
                    [
                        -3.647572394999941,
                        50.274562893
                    ],
                    [
                        -3.653309699999909,
                        50.25104401200015
                    ],
                    [
                        -3.645619269999912,
                        50.22601959800015
                    ],
                    [
                        -3.660633917999917,
                        50.22101471600011
                    ],
                    [
                        -3.700917120999918,
                        50.21352773600013
                    ],
                    [
                        -3.718006964999859,
                        50.2123884140001
                    ],
                    [
                        -3.74038652299987,
                        50.215887762000094
                    ],
                    [
                        -3.758615688999953,
                        50.22174713700004
                    ],
                    [
                        -3.774240688999925,
                        50.22296784100014
                    ],
                    [
                        -3.788970506999931,
                        50.2123884140001
                    ],
                    [
                        -3.826324022999899,
                        50.23419830900015
                    ],
                    [
                        -3.8841853509999,
                        50.28046295800014
                    ],
                    [
                        -3.949940558999884,
                        50.318996486000046
                    ],
                    [
                        -3.961008266999926,
                        50.32225169499999
                    ],
                    [
                        -3.970204230999883,
                        50.320746161000116
                    ],
                    [
                        -3.995757615999878,
                        50.31142812700007
                    ],
                    [
                        -4.00560462099989,
                        50.309230861000074
                    ],
                    [
                        -4.011341925999972,
                        50.30695221600003
                    ],
                    [
                        -4.024769660999965,
                        50.29718659100017
                    ],
                    [
                        -4.032948370999861,
                        50.294907945000105
                    ],
                    [
                        -4.044056769999884,
                        50.29645416900014
                    ],
                    [
                        -4.054351365999906,
                        50.30036041900006
                    ],
                    [
                        -4.070220506999931,
                        50.309230861000074
                    ],
                    [
                        -4.067941860999952,
                        50.31281159100014
                    ],
                    [
                        -4.064035610999952,
                        50.32225169499999
                    ],
                    [
                        -4.086008266999897,
                        50.326239325000145
                    ],
                    [
                        -4.108265753999945,
                        50.336615302000055
                    ],
                    [
                        -4.118723110999923,
                        50.35187409100003
                    ],
                    [
                        -4.104969855999912,
                        50.37067291900017
                    ],
                    [
                        -4.141753709999875,
                        50.36904531500015
                    ],
                    [
                        -4.152740037999934,
                        50.37067291900017
                    ],
                    [
                        -4.165516730999883,
                        50.37636953300016
                    ],
                    [
                        -4.171457485999923,
                        50.38279857000008
                    ],
                    [
                        -4.175770636999914,
                        50.39085521
                    ],
                    [
                        -4.183827277999853,
                        50.40106842700014
                    ],
                    [
                        -4.191477016999983,
                        50.41762929900001
                    ],
                    [
                        -4.183908657999922,
                        50.431708075000145
                    ],
                    [
                        -4.170318162999877,
                        50.445135809000035
                    ],
                    [
                        -4.15953528599988,
                        50.459418036000116
                    ],
                    [
                        -4.179432745999861,
                        50.45307038
                    ],
                    [
                        -4.189564581999917,
                        50.45368073100015
                    ],
                    [
                        -4.20055091099988,
                        50.459418036000116
                    ],
                    [
                        -4.203684048999946,
                        50.451402085
                    ],
                    [
                        -4.204741990999906,
                        50.44867584800012
                    ],
                    [
                        -4.212391730999855,
                        50.44147370000009
                    ],
                    [
                        -4.222523566999882,
                        50.43797435099999
                    ],
                    [
                        -4.234730597999885,
                        50.43829987200003
                    ],
                    [
                        -4.234730597999885,
                        50.43211497600005
                    ],
                    [
                        -4.22020423099994,
                        50.42584870000009
                    ],
                    [
                        -4.216135219999956,
                        50.415716864000146
                    ],
                    [
                        -4.219715949999852,
                        50.405096747000115
                    ],
                    [
                        -4.228505011999914,
                        50.3973656270001
                    ],
                    [
                        -4.241444464999916,
                        50.394680080000015
                    ],
                    [
                        -4.289906378999888,
                        50.3973656270001
                    ],
                    [
                        -4.273019985999895,
                        50.38190338700015
                    ],
                    [
                        -4.21617591099988,
                        50.38776276200012
                    ],
                    [
                        -4.193755662999934,
                        50.37689850500011
                    ],
                    [
                        -4.228505011999914,
                        50.37067291900017
                    ],
                    [
                        -4.228505011999914,
                        50.36383698100015
                    ],
                    [
                        -4.220122850999957,
                        50.36530182500012
                    ],
                    [
                        -4.20055091099988,
                        50.36383698100015
                    ],
                    [
                        -4.207997199999852,
                        50.35639069200009
                    ],
                    [
                        -4.180043097999914,
                        50.35639069200009
                    ],
                    [
                        -4.180043097999914,
                        50.35016510600012
                    ],
                    [
                        -4.191314256999903,
                        50.34756094000012
                    ],
                    [
                        -4.197987433999884,
                        50.34223053600006
                    ],
                    [
                        -4.199126756999903,
                        50.33539459800012
                    ],
                    [
                        -4.193755662999934,
                        50.32843659100014
                    ],
                    [
                        -4.193755662999934,
                        50.32225169499999
                    ],
                    [
                        -4.214995897999927,
                        50.32314687700007
                    ],
                    [
                        -4.225697394999941,
                        50.33539459800012
                    ],
                    [
                        -4.235096808999884,
                        50.34955475500006
                    ],
                    [
                        -4.252349412999905,
                        50.35639069200009
                    ],
                    [
                        -4.337717251999919,
                        50.37067291900017
                    ],
                    [
                        -4.383697068999908,
                        50.36749909100011
                    ],
                    [
                        -4.456206834999904,
                        50.338202216000084
                    ],
                    [
                        -4.495350714999859,
                        50.32843659100014
                    ],
                    [
                        -4.659820115999906,
                        50.32225169499999
                    ],
                    [
                        -4.659575975999957,
                        50.32062409100014
                    ],
                    [
                        -4.66270911399991,
                        50.317775783000016
                    ],
                    [
                        -4.667795376999948,
                        50.31533437700007
                    ],
                    [
                        -4.673451300999943,
                        50.31541575700005
                    ],
                    [
                        -4.687163865999878,
                        50.34271881700015
                    ],
                    [
                        -4.694325324999909,
                        50.34345123900006
                    ],
                    [
                        -4.700184699999852,
                        50.34088776200015
                    ],
                    [
                        -4.705555792999917,
                        50.337551174000126
                    ],
                    [
                        -4.711333787999877,
                        50.33588288000011
                    ],
                    [
                        -4.731556769999884,
                        50.334662177
                    ],
                    [
                        -4.752105272999927,
                        50.33030833500011
                    ],
                    [
                        -4.763295050999943,
                        50.32208893400012
                    ],
                    [
                        -4.755441860999952,
                        50.309230861000074
                    ],
                    [
                        -4.755441860999952,
                        50.30174388200011
                    ],
                    [
                        -4.762359178999873,
                        50.298041083000086
                    ],
                    [
                        -4.771636522999927,
                        50.291571356000176
                    ],
                    [
                        -4.779855923999918,
                        50.28432851800015
                    ],
                    [
                        -4.783355272999899,
                        50.277899481000034
                    ],
                    [
                        -4.78189042899993,
                        50.27179596600014
                    ],
                    [
                        -4.776519334999875,
                        50.265082098
                    ],
                    [
                        -4.776519334999875,
                        50.260809637000094
                    ],
                    [
                        -4.788441535999937,
                        50.24209219000012
                    ],
                    [
                        -4.790150519999941,
                        50.236558335000026
                    ],
                    [
                        -4.800160285999908,
                        50.22955963700004
                    ],
                    [
                        -4.849720831999946,
                        50.23456452000006
                    ],
                    [
                        -4.868723110999952,
                        50.22943756700006
                    ],
                    [
                        -4.887318488999938,
                        50.2149925800001
                    ],
                    [
                        -4.904774542999917,
                        50.208441473
                    ],
                    [
                        -4.947255011999886,
                        50.199367580000015
                    ],
                    [
                        -4.960682745999861,
                        50.19049713700015
                    ],
                    [
                        -5.002430792999917,
                        50.144110419000086
                    ],
                    [
                        -5.014556443999908,
                        50.15656159100003
                    ],
                    [
                        -5.020375128999945,
                        50.191961981000034
                    ],
                    [
                        -5.033192511999886,
                        50.199367580000015
                    ],
                    [
                        -5.05296790299991,
                        50.19578685100005
                    ],
                    [
                        -5.053618943999965,
                        50.1869164080001
                    ],
                    [
                        -5.051869269999969,
                        50.17552317900014
                    ],
                    [
                        -5.064564581999917,
                        50.16461823100015
                    ],
                    [
                        -5.064564581999917,
                        50.158392645
                    ],
                    [
                        -5.058501756999931,
                        50.155462958000086
                    ],
                    [
                        -5.043446417999917,
                        50.144110419000086
                    ],
                    [
                        -5.051869269999969,
                        50.14459870000009
                    ],
                    [
                        -5.058461066999939,
                        50.142726955000015
                    ],
                    [
                        -5.070790167999888,
                        50.137274481000176
                    ],
                    [
                        -5.080311652999882,
                        50.13271719000012
                    ],
                    [
                        -5.081613735999895,
                        50.125392971000124
                    ],
                    [
                        -5.080962693999936,
                        50.11717357000013
                    ],
                    [
                        -5.085031704999892,
                        50.10993073100012
                    ],
                    [
                        -5.093902147999927,
                        50.10553620000003
                    ],
                    [
                        -5.125965949999909,
                        50.09634023600016
                    ],
                    [
                        -5.114654100999928,
                        50.096625067
                    ],
                    [
                        -5.10606848899991,
                        50.09511953300013
                    ],
                    [
                        -5.091867641999897,
                        50.089504299000126
                    ],
                    [
                        -5.086903449999852,
                        50.08905670800014
                    ],
                    [
                        -5.075347459999932,
                        50.09048086100002
                    ],
                    [
                        -5.070790167999888,
                        50.089504299000126
                    ],
                    [
                        -5.068470831999917,
                        50.08515045800014
                    ],
                    [
                        -5.06493079299986,
                        50.07184479400003
                    ],
                    [
                        -5.060861782999893,
                        50.069037177000084
                    ],
                    [
                        -5.056507941999882,
                        50.06061432500003
                    ],
                    [
                        -5.069488084999875,
                        50.042181708
                    ],
                    [
                        -5.098052537999877,
                        50.014390367000104
                    ],
                    [
                        -5.107289191999939,
                        50.01300690300003
                    ],
                    [
                        -5.127430792999888,
                        50.01544830900018
                    ],
                    [
                        -5.139637824999852,
                        50.014390367000104
                    ],
                    [
                        -5.146473761999886,
                        50.010565497000115
                    ],
                    [
                        -5.176136847999914,
                        49.987941799000126
                    ],
                    [
                        -5.18773352799991,
                        49.96450429900007
                    ],
                    [
                        -5.191151495999918,
                        49.959133205000015
                    ],
                    [
                        -5.200103318999965,
                        49.961371161
                    ],
                    [
                        -5.2103572259999,
                        49.96674225500006
                    ],
                    [
                        -5.240386522999927,
                        49.988348700000145
                    ],
                    [
                        -5.245228644999912,
                        49.99310944200015
                    ],
                    [
                        -5.249501105999912,
                        50.00006745000003
                    ],
                    [
                        -5.253041144999912,
                        50.01264069200012
                    ],
                    [
                        -5.252268032999893,
                        50.02069733300006
                    ],
                    [
                        -5.252674933999884,
                        50.027777411000116
                    ],
                    [
                        -5.259755011999857,
                        50.037665106000034
                    ],
                    [
                        -5.288400844999927,
                        50.06769440300009
                    ],
                    [
                        -5.304351365999935,
                        50.08026764500006
                    ],
                    [
                        -5.32396399599989,
                        50.089504299000126
                    ],
                    [
                        -5.384185350999871,
                        50.10797760600015
                    ],
                    [
                        -5.424712693999879,
                        50.112738348000065
                    ],
                    [
                        -5.458119269999941,
                        50.12588125200001
                    ],
                    [
                        -5.474842902999882,
                        50.13043854400014
                    ],
                    [
                        -5.495676235999952,
                        50.12962474200005
                    ],
                    [
                        -5.521839972999914,
                        50.12372467700011
                    ],
                    [
                        -5.540923631999874,
                        50.11347077
                    ],
                    [
                        -5.540638800999943,
                        50.09975820500007
                    ],
                    [
                        -5.534657355999855,
                        50.082342841000084
                    ],
                    [
                        -5.545562303999901,
                        50.06891510600009
                    ],
                    [
                        -5.56346594999988,
                        50.059637762000065
                    ],
                    [
                        -5.578439907999893,
                        50.054754950000174
                    ],
                    [
                        -5.619455532999922,
                        50.04621002800015
                    ],
                    [
                        -5.667388475999928,
                        50.04287344000012
                    ],
                    [
                        -5.705148891999983,
                        50.05231354400014
                    ],
                    [
                        -5.715646938999953,
                        50.08270905200011
                    ],
                    [
                        -5.705962693999879,
                        50.08348216400013
                    ],
                    [
                        -5.698801235999952,
                        50.08539459800009
                    ],
                    [
                        -5.694488084999932,
                        50.089504299000126
                    ],
                    [
                        -5.694406704999949,
                        50.09577057500009
                    ],
                    [
                        -5.697092251999948,
                        50.10053131700009
                    ],
                    [
                        -5.700306769999912,
                        50.10321686400006
                    ],
                    [
                        -5.701975063999925,
                        50.10317617400007
                    ],
                    [
                        -5.707386847999942,
                        50.12201569200003
                    ],
                    [
                        -5.709217902999853,
                        50.13263580900015
                    ],
                    [
                        -5.705393032999922,
                        50.137274481000176
                    ],
                    [
                        -5.703521287999962,
                        50.140611070000105
                    ],
                    [
                        -5.684641079999921,
                        50.16152578300007
                    ],
                    [
                        -5.676747199999852,
                        50.16502513200005
                    ],
                    [
                        -5.647368943999936,
                        50.17206452000012
                    ],
                    [
                        -5.583607550999915,
                        50.195868231000034
                    ],
                    [
                        -5.54942786399991,
                        50.20351797100015
                    ],
                    [
                        -5.510975714999916,
                        50.219468492000075
                    ],
                    [
                        -5.489125128999888,
                        50.21979401200018
                    ],
                    [
                        -5.458119269999941,
                        50.200751044000086
                    ],
                    [
                        -5.437977667999917,
                        50.194322007
                    ],
                    [
                        -5.417388475999871,
                        50.20246002800009
                    ],
                    [
                        -5.40294348899991,
                        50.21735260600015
                    ],
                    [
                        -5.395863410999937,
                        50.227240302000055
                    ],
                    [
                        -5.39289303299995,
                        50.236558335000026
                    ],
                    [
                        -5.387562628999888,
                        50.24030182500003
                    ],
                    [
                        -5.351918097999914,
                        50.24030182500003
                    ],
                    [
                        -5.314849412999905,
                        50.25360748900006
                    ],
                    [
                        -5.200306769999912,
                        50.33075592700003
                    ],
                    [
                        -5.163563605999912,
                        50.347235419000086
                    ],
                    [
                        -5.149891730999883,
                        50.35016510600012
                    ],
                    [
                        -5.146473761999886,
                        50.355292059000035
                    ],
                    [
                        -5.145659959999875,
                        50.366888739000146
                    ],
                    [
                        -5.147043423999946,
                        50.37938060100005
                    ],
                    [
                        -5.153675910999908,
                        50.399481512000094
                    ],
                    [
                        -5.142404751999919,
                        50.40448639500015
                    ],
                    [
                        -5.127268032999922,
                        50.40521881700009
                    ],
                    [
                        -5.119740363999938,
                        50.40477122599999
                    ],
                    [
                        -5.05028235599994,
                        50.42869700700014
                    ],
                    [
                        -5.048166469999927,
                        50.43439362200003
                    ],
                    [
                        -5.038726365999906,
                        50.4479027360001
                    ],
                    [
                        -5.029774542999888,
                        50.486761786
                    ],
                    [
                        -5.028635219999927,
                        50.507025458000115
                    ],
                    [
                        -5.025257941999911,
                        50.52423737200003
                    ],
                    [
                        -5.015614386999857,
                        50.53803131700015
                    ],
                    [
                        -4.995594855999883,
                        50.54816315300009
                    ],
                    [
                        -4.975168423999918,
                        50.54816315300009
                    ],
                    [
                        -4.967844204999921,
                        50.551581122000115
                    ],
                    [
                        -4.954823370999918,
                        50.561224677000084
                    ],
                    [
                        -4.947255011999886,
                        50.56244538
                    ],
                    [
                        -4.941477016999897,
                        50.55695221600014
                    ],
                    [
                        -4.937123175999915,
                        50.54531484600015
                    ],
                    [
                        -4.934437628999945,
                        50.53164297100004
                    ],
                    [
                        -4.933583136999857,
                        50.52024974200005
                    ],
                    [
                        -4.926869269999884,
                        50.52289459800015
                    ],
                    [
                        -4.924305792999888,
                        50.52448151200018
                    ],
                    [
                        -4.920521613999966,
                        50.52765534100014
                    ],
                    [
                        -4.902414516999954,
                        50.52057526200018
                    ],
                    [
                        -4.860951300999943,
                        50.51919179900001
                    ],
                    [
                        -4.844838019999884,
                        50.514064846000096
                    ],
                    [
                        -4.855824347999942,
                        50.52741120000009
                    ],
                    [
                        -4.873280402999882,
                        50.534084377000156
                    ],
                    [
                        -4.906239386999857,
                        50.54197825700011
                    ],
                    [
                        -4.921864386999857,
                        50.55487702000015
                    ],
                    [
                        -4.920236782999922,
                        50.564154364
                    ],
                    [
                        -4.915191209999904,
                        50.572495835000055
                    ],
                    [
                        -4.920521613999966,
                        50.58295319200015
                    ],
                    [
                        -4.920521613999966,
                        50.58917877800009
                    ],
                    [
                        -4.895659959999904,
                        50.58592357000005
                    ],
                    [
                        -4.821197068999936,
                        50.58917877800009
                    ],
                    [
                        -4.792469855999883,
                        50.5952009140001
                    ],
                    [
                        -4.776600714999859,
                        50.60984935100011
                    ],
                    [
                        -4.765044725999928,
                        50.62799713700012
                    ],
                    [
                        -4.749256964999887,
                        50.64443594000004
                    ],
                    [
                        -4.755441860999952,
                        50.65936920800006
                    ],
                    [
                        -4.751942511999886,
                        50.67007070500007
                    ],
                    [
                        -4.74193274599989,
                        50.67487213700015
                    ],
                    [
                        -4.728138800999886,
                        50.67230866100006
                    ],
                    [
                        -4.657297329999892,
                        50.71112702000009
                    ],
                    [
                        -4.645578579999892,
                        50.72353750200001
                    ],
                    [
                        -4.637806769999884,
                        50.74115631700012
                    ],
                    [
                        -4.619007941999939,
                        50.75478750200007
                    ],
                    [
                        -4.577259894999912,
                        50.77472565300015
                    ],
                    [
                        -4.550119594999984,
                        50.809556382000025
                    ],
                    [
                        -4.554921027999882,
                        50.89777252800009
                    ],
                    [
                        -4.543771938999953,
                        50.939195054000024
                    ],
                    [
                        -4.536854620999861,
                        50.94757721600014
                    ],
                    [
                        -4.522694464999916,
                        50.97272370000006
                    ],
                    [
                        -4.526682094999899,
                        50.980373440000065
                    ],
                    [
                        -4.530425584999932,
                        50.99469635600015
                    ],
                    [
                        -4.531320766999926,
                        51.00869375200001
                    ],
                    [
                        -4.526437954999949,
                        51.01496002800015
                    ],
                    [
                        -4.437326626999891,
                        51.01496002800015
                    ],
                    [
                        -4.426421678999901,
                        51.012681382
                    ],
                    [
                        -4.405751105999883,
                        51.00287506700006
                    ],
                    [
                        -4.395741339999916,
                        51.000677802000084
                    ],
                    [
                        -4.337717251999919,
                        50.99664948100009
                    ],
                    [
                        -4.317290818999879,
                        51.000677802000084
                    ],
                    [
                        -4.302316860999923,
                        51.007513739
                    ],
                    [
                        -4.234730597999885,
                        51.055324611000046
                    ],
                    [
                        -4.229156053999873,
                        51.06110260600009
                    ],
                    [
                        -4.224680141999897,
                        51.06708405200003
                    ],
                    [
                        -4.218495245999918,
                        51.07245514500006
                    ],
                    [
                        -4.207997199999852,
                        51.07636139500006
                    ],
                    [
                        -4.2142634759999,
                        51.08600495000003
                    ],
                    [
                        -4.226307745999918,
                        51.11322663000011
                    ],
                    [
                        -4.228505011999914,
                        51.12042877800015
                    ],
                    [
                        -4.231922980999912,
                        51.12669505400011
                    ],
                    [
                        -4.24901282499988,
                        51.14288971600014
                    ],
                    [
                        -4.255767381999903,
                        51.151516018000066
                    ],
                    [
                        -4.222320115999935,
                        51.149725653000175
                    ],
                    [
                        -4.214833136999886,
                        51.151516018000066
                    ],
                    [
                        -4.20836341099988,
                        51.16229889500006
                    ],
                    [
                        -4.209828253999916,
                        51.17283763200014
                    ],
                    [
                        -4.217274542999917,
                        51.18138255400005
                    ],
                    [
                        -4.228505011999914,
                        51.18622467700014
                    ],
                    [
                        -4.228505011999914,
                        51.192450262000094
                    ],
                    [
                        -4.152333136999857,
                        51.211615302000055
                    ],
                    [
                        -3.969471808999856,
                        51.22239817900005
                    ],
                    [
                        -3.931996222999914,
                        51.2313500020001
                    ],
                    [
                        -3.913197394999884,
                        51.23338450700011
                    ],
                    [
                        -3.840891079999921,
                        51.23338450700011
                    ],
                    [
                        -3.818959113999881,
                        51.236070054
                    ],
                    [
                        -3.787098761999857,
                        51.24677155200011
                    ],
                    [
                        -3.769154425999915,
                        51.24770742400001
                    ],
                    [
                        -3.639759894999941,
                        51.226385809000035
                    ],
                    [
                        -3.559315558999884,
                        51.22809479400014
                    ],
                    [
                        -3.434193488999938,
                        51.20978424700017
                    ],
                    [
                        -3.404367641999926,
                        51.192043361000074
                    ],
                    [
                        -3.38499915299991,
                        51.18622467700014
                    ],
                    [
                        -3.270741339999859,
                        51.189398505
                    ],
                    [
                        -3.198882615999878,
                        51.203558661000116
                    ],
                    [
                        -3.162749803999958,
                        51.20673248900009
                    ],
                    [
                        -3.096750454999921,
                        51.204535223
                    ],
                    [
                        -3.057525193999908,
                        51.20815664300015
                    ],
                    [
                        -3.029286261999857,
                        51.22040436400012
                    ],
                    [
                        -3.021839972999942,
                        51.21356842700003
                    ],
                    [
                        -3.035552537999877,
                        51.19928620000012
                    ],
                    [
                        -3.021839972999942,
                        51.192450262000094
                    ],
                    [
                        -3.004790818999908,
                        51.21312083500011
                    ],
                    [
                        -3.001332160999908,
                        51.22040436400012
                    ],
                    [
                        -2.999582485999923,
                        51.233221747000144
                    ],
                    [
                        -3.001535610999952,
                        51.239691473000065
                    ],
                    [
                        -3.004994269999941,
                        51.24506256700012
                    ],
                    [
                        -3.008168097999914,
                        51.25454336100002
                    ],
                    [
                        -3.007639126999948,
                        51.29197825700005
                    ],
                    [
                        -3.010609503999945,
                        51.31098053600006
                    ],
                    [
                        -3.021839972999942,
                        51.32282135600012
                    ],
                    [
                        -3.00243079299986,
                        51.31907786699999
                    ],
                    [
                        -2.992176886999914,
                        51.321926174000126
                    ],
                    [
                        -2.992095506999931,
                        51.3220482440001
                    ],
                    [
                        -2.988392706999889,
                        51.331122137000094
                    ],
                    [
                        -2.987782355999855,
                        51.346665757000025
                    ],
                    [
                        -2.979888475999871,
                        51.35586172100007
                    ],
                    [
                        -2.965972459999904,
                        51.36493561400009
                    ],
                    [
                        -2.959584113999881,
                        51.374253648000106
                    ],
                    [
                        -2.974680141999954,
                        51.384222723
                    ],
                    [
                        -2.952951626999891,
                        51.39850495000012
                    ],
                    [
                        -2.921009894999884,
                        51.39443594000015
                    ],
                    [
                        -2.884185350999928,
                        51.41697825700014
                    ],
                    [
                        -2.816395636999914,
                        51.47361888200011
                    ],
                    [
                        -2.798573370999918,
                        51.48261139500015
                    ],
                    [
                        -2.780873175999915,
                        51.489081122000144
                    ],
                    [
                        -2.761219855999883,
                        51.492865302000084
                    ],
                    [
                        -2.737619594999927,
                        51.494086005
                    ],
                    [
                        -2.716420050999943,
                        51.499579169000114
                    ],
                    [
                        -2.696156378999916,
                        51.512640692
                    ],
                    [
                        -2.607818162999877,
                        51.607367255000085
                    ],
                    [
                        -2.599598761999886,
                        51.61139557500003
                    ],
                    [
                        -2.58922278599988,
                        51.61424388200008
                    ],
                    [
                        -2.580433722999914,
                        51.61863841400016
                    ],
                    [
                        -2.576812303999958,
                        51.62750885600012
                    ],
                    [
                        -2.57445227799991,
                        51.63572825700011
                    ],
                    [
                        -2.568959113999881,
                        51.64496491100006
                    ],
                    [
                        -2.562245245999918,
                        51.65338776200012
                    ],
                    [
                        -2.55638587099989,
                        51.65924713700015
                    ],
                    [
                        -2.499175584999904,
                        51.69676341400016
                    ],
                    [
                        -2.464995897999927,
                        51.72589752800003
                    ],
                    [
                        -2.447743292999917,
                        51.73187897300015
                    ],
                    [
                        -2.404896613999938,
                        51.741156317
                    ],
                    [
                        -2.388539191999911,
                        51.75043366100006
                    ],
                    [
                        -2.380767381999931,
                        51.76190827
                    ],
                    [
                        -2.383371548999918,
                        51.77326080900018
                    ],
                    [
                        -2.398060675999915,
                        51.78213125200013
                    ],
                    [
                        -2.402414516999897,
                        51.76357656500012
                    ],
                    [
                        -2.42039954299986,
                        51.75311920800014
                    ],
                    [
                        -2.443430141999926,
                        51.748439846000124
                    ],
                    [
                        -2.463286912999934,
                        51.74730052299999
                    ],
                    [
                        -2.482085740999878,
                        51.74286530200011
                    ],
                    [
                        -2.49583899599989,
                        51.73200104400003
                    ],
                    [
                        -2.507964647999899,
                        51.718573309000035
                    ],
                    [
                        -2.522124803999901,
                        51.70636627800015
                    ],
                    [
                        -2.581695115999906,
                        51.681708075000145
                    ],
                    [
                        -2.591135219999927,
                        51.67536041900003
                    ],
                    [
                        -2.600209113999938,
                        51.665594794000086
                    ],
                    [
                        -2.665322474518433,
                        51.617278320788856
                    ],
                    [
                        -2.665353969999956,
                        51.617254950000174
                    ],
                    [
                        -2.693755662999877,
                        51.59617747600005
                    ],
                    [
                        -2.71312415299991,
                        51.586615302000084
                    ],
                    [
                        -2.760487433999856,
                        51.57977936400006
                    ],
                    [
                        -2.798085089999859,
                        51.56350332200013
                    ],
                    [
                        -2.816395636999914,
                        51.555568752000156
                    ],
                    [
                        -2.858713344999899,
                        51.54612864800005
                    ],
                    [
                        -2.943348761999914,
                        51.54254791900017
                    ],
                    [
                        -2.956288214999916,
                        51.54572174700003
                    ],
                    [
                        -2.964100714999916,
                        51.55280182500009
                    ],
                    [
                        -2.969471808999884,
                        51.55988190300015
                    ],
                    [
                        -2.974680141999954,
                        51.56297435100005
                    ],
                    [
                        -2.984038865999906,
                        51.558539130000085
                    ],
                    [
                        -3.01024329299986,
                        51.53803131700012
                    ],
                    [
                        -3.015695766999897,
                        51.531642971000096
                    ],
                    [
                        -3.073719855999855,
                        51.508368231000176
                    ],
                    [
                        -3.076486782999922,
                        51.50775788000011
                    ],
                    [
                        -3.088937954999921,
                        51.50507233300014
                    ],
                    [
                        -3.10562089799987,
                        51.497219143000066
                    ],
                    [
                        -3.131703253999945,
                        51.48041413000006
                    ],
                    [
                        -3.13508053299995,
                        51.476304429000024
                    ],
                    [
                        -3.140939907999893,
                        51.46478913000006
                    ],
                    [
                        -3.145375128999888,
                        51.459947007
                    ],
                    [
                        -3.152414516999926,
                        51.45718008000013
                    ],
                    [
                        -3.16665605399993,
                        51.456000067
                    ],
                    [
                        -3.172393357999908,
                        51.45327383000013
                    ],
                    [
                        -3.172718878999945,
                        51.45311107000008
                    ],
                    [
                        -3.160064256999874,
                        51.431830145
                    ],
                    [
                        -3.167144334999932,
                        51.410467841000056
                    ],
                    [
                        -3.184803839999859,
                        51.397609768
                    ],
                    [
                        -3.204009568999908,
                        51.40159739799999
                    ],
                    [
                        -3.218861456999946,
                        51.402777411000116
                    ],
                    [
                        -3.289418097999942,
                        51.384222723
                    ],
                    [
                        -3.539173956999946,
                        51.39850495000012
                    ],
                    [
                        -3.557443813999896,
                        51.40509674700017
                    ],
                    [
                        -3.575306769999884,
                        51.42043691600013
                    ],
                    [
                        -3.604725714999859,
                        51.445705471000096
                    ],
                    [
                        -3.663197394999941,
                        51.47622304900004
                    ],
                    [
                        -3.676991339999859,
                        51.477036851000136
                    ],
                    [
                        -3.695423956999889,
                        51.471502997000115
                    ],
                    [
                        -3.717925584999875,
                        51.478583075000174
                    ],
                    [
                        -3.737619594999899,
                        51.49087148600013
                    ],
                    [
                        -3.748036261999914,
                        51.500921942
                    ],
                    [
                        -3.749419725999871,
                        51.50535716400016
                    ],
                    [
                        -3.747670050999886,
                        51.507025458
                    ],
                    [
                        -3.744536912999905,
                        51.50763580900015
                    ],
                    [
                        -3.741851365999935,
                        51.508368231000176
                    ],
                    [
                        -3.749338344999899,
                        51.52806224200013
                    ],
                    [
                        -3.751901821999894,
                        51.53306712400017
                    ],
                    [
                        -3.758941209999875,
                        51.54677969000009
                    ],
                    [
                        -3.770130988999881,
                        51.56329987200017
                    ],
                    [
                        -3.78221594999988,
                        51.57664622599999
                    ],
                    [
                        -3.790272589999916,
                        51.58197663000014
                    ],
                    [
                        -3.807728644999884,
                        51.589178778000175
                    ],
                    [
                        -3.816965298999946,
                        51.59650299700009
                    ],
                    [
                        -3.832671678999901,
                        51.61554596600017
                    ],
                    [
                        -3.841623501999948,
                        51.621527411
                    ],
                    [
                        -3.854888475999871,
                        51.623846747000144
                    ],
                    [
                        -3.886789516999897,
                        51.62140534100003
                    ],
                    [
                        -3.962391730999883,
                        51.61562734600015
                    ],
                    [
                        -3.976633266999897,
                        51.611883856000034
                    ],
                    [
                        -3.989979620999918,
                        51.60651276200015
                    ],
                    [
                        -3.998768683999884,
                        51.60028717700011
                    ],
                    [
                        -3.999908006999903,
                        51.59088776200018
                    ],
                    [
                        -3.992258266999897,
                        51.58112213700004
                    ],
                    [
                        -3.981800910999908,
                        51.57160065300015
                    ],
                    [
                        -3.974598761999886,
                        51.56297435100005
                    ],
                    [
                        -4.016184048999946,
                        51.56297435100005
                    ],
                    [
                        -4.01610266799986,
                        51.560370184000064
                    ],
                    [
                        -4.020822719999956,
                        51.555853583
                    ],
                    [
                        -4.026519334999961,
                        51.55402252800012
                    ],
                    [
                        -4.029204881999931,
                        51.559271552
                    ],
                    [
                        -4.031971808999884,
                        51.56313711100002
                    ],
                    [
                        -4.038563605999883,
                        51.56622955900009
                    ],
                    [
                        -4.046457485999923,
                        51.568426825000174
                    ],
                    [
                        -4.053130662999905,
                        51.5692406270001
                    ],
                    [
                        -4.056304490999878,
                        51.5670433610001
                    ],
                    [
                        -4.065581834999904,
                        51.557562567
                    ],
                    [
                        -4.070220506999931,
                        51.555568752000156
                    ],
                    [
                        -4.076812303999901,
                        51.556952216000056
                    ],
                    [
                        -4.087269660999908,
                        51.5619164080001
                    ],
                    [
                        -4.112131313999925,
                        51.565619208000115
                    ],
                    [
                        -4.121652798999946,
                        51.56598541900014
                    ],
                    [
                        -4.132191535999908,
                        51.56297435100005
                    ],
                    [
                        -4.14273027299987,
                        51.556952216000056
                    ],
                    [
                        -4.15680904899989,
                        51.546291408000016
                    ],
                    [
                        -4.166411912999877,
                        51.54254791900017
                    ],
                    [
                        -4.176503058999856,
                        51.544256903000175
                    ],
                    [
                        -4.187733527999853,
                        51.54877350500011
                    ],
                    [
                        -4.196766730999855,
                        51.54938385600009
                    ],
                    [
                        -4.20055091099988,
                        51.538763739000146
                    ],
                    [
                        -4.213368292999917,
                        51.539129950000145
                    ],
                    [
                        -4.272450324999852,
                        51.554754950000145
                    ],
                    [
                        -4.289906378999888,
                        51.555568752000156
                    ],
                    [
                        -4.293324347999914,
                        51.559149481000034
                    ],
                    [
                        -4.295155402999882,
                        51.56195709800009
                    ],
                    [
                        -4.297352667999888,
                        51.5692406270001
                    ],
                    [
                        -4.27830969999988,
                        51.57819245000003
                    ],
                    [
                        -4.280832485999895,
                        51.58881256700006
                    ],
                    [
                        -4.28970292899993,
                        51.601548570000105
                    ],
                    [
                        -4.289906378999888,
                        51.617010809000035
                    ],
                    [
                        -4.255767381999903,
                        51.623846747000144
                    ],
                    [
                        -4.252023891999954,
                        51.627101955000015
                    ],
                    [
                        -4.241281704999949,
                        51.639349677000084
                    ],
                    [
                        -4.234730597999885,
                        51.64496491100006
                    ],
                    [
                        -4.228505011999914,
                        51.637437242000104
                    ],
                    [
                        -4.234730597999885,
                        51.637437242000104
                    ],
                    [
                        -4.234730597999885,
                        51.631293036000116
                    ],
                    [
                        -4.227853969999956,
                        51.62811920800006
                    ],
                    [
                        -4.22101803299995,
                        51.623846747000144
                    ],
                    [
                        -4.190093553999901,
                        51.63027578300007
                    ],
                    [
                        -4.139027472999914,
                        51.63231028900019
                    ],
                    [
                        -4.091297980999855,
                        51.63987864800005
                    ],
                    [
                        -4.070220506999931,
                        51.66229889500006
                    ],
                    [
                        -4.069813605999855,
                        51.66893138200011
                    ],
                    [
                        -4.070383266999897,
                        51.67430247599999
                    ],
                    [
                        -4.070546027999882,
                        51.67597077
                    ],
                    [
                        -4.075428839999944,
                        51.677801825000174
                    ],
                    [
                        -4.087269660999908,
                        51.66885000200013
                    ],
                    [
                        -4.09544837099989,
                        51.665106512000094
                    ],
                    [
                        -4.106841600999871,
                        51.66425202
                    ],
                    [
                        -4.146351691999911,
                        51.66705963700015
                    ],
                    [
                        -4.20055091099988,
                        51.68585846600011
                    ],
                    [
                        -4.214955206999946,
                        51.67967357000013
                    ],
                    [
                        -4.293690558999913,
                        51.67226797100015
                    ],
                    [
                        -4.315297003999916,
                        51.67747630400005
                    ],
                    [
                        -4.342355923999946,
                        51.69086334800015
                    ],
                    [
                        -4.366444464999887,
                        51.70848216400016
                    ],
                    [
                        -4.379261847999914,
                        51.72687409100011
                    ],
                    [
                        -4.33804277299987,
                        51.72333405200014
                    ],
                    [
                        -4.319976365999935,
                        51.724839585
                    ],
                    [
                        -4.310454881999931,
                        51.73371002800003
                    ],
                    [
                        -4.32872473899991,
                        51.73297760600009
                    ],
                    [
                        -4.347157355999855,
                        51.73578522300015
                    ],
                    [
                        -4.362538214999887,
                        51.74286530200011
                    ],
                    [
                        -4.371815558999856,
                        51.754787502000156
                    ],
                    [
                        -4.368763800999943,
                        51.75698476800015
                    ],
                    [
                        -4.362904425999915,
                        51.76386139500015
                    ],
                    [
                        -4.358021613999938,
                        51.773138739
                    ],
                    [
                        -4.358225063999896,
                        51.78213125200013
                    ],
                    [
                        -4.365101691999911,
                        51.78904857000013
                    ],
                    [
                        -4.371490037999905,
                        51.78595612200006
                    ],
                    [
                        -4.379261847999914,
                        51.775295315
                    ],
                    [
                        -4.384917772999899,
                        51.77187734600001
                    ],
                    [
                        -4.392974412999934,
                        51.763739325000174
                    ],
                    [
                        -4.399159308999913,
                        51.761623440000065
                    ],
                    [
                        -4.409331834999875,
                        51.7631696640001
                    ],
                    [
                        -4.417876756999874,
                        51.76788971600003
                    ],
                    [
                        -4.426503058999884,
                        51.775295315
                    ],
                    [
                        -4.445790167999917,
                        51.77326080900018
                    ],
                    [
                        -4.449330206999917,
                        51.766302802
                    ],
                    [
                        -4.441395636999857,
                        51.756781317
                    ],
                    [
                        -4.426503058999884,
                        51.74730052299999
                    ],
                    [
                        -4.460764126999948,
                        51.73578522300015
                    ],
                    [
                        -4.498890753999945,
                        51.73484935100008
                    ],
                    [
                        -4.574208136999914,
                        51.741156317
                    ],
                    [
                        -4.603423631999931,
                        51.73924388200005
                    ],
                    [
                        -4.639230923999946,
                        51.73318105700015
                    ],
                    [
                        -4.645375128999945,
                        51.732123114
                    ],
                    [
                        -4.678212042999945,
                        51.7174339860001
                    ],
                    [
                        -4.680287238999938,
                        51.69269440300003
                    ],
                    [
                        -4.689442511999857,
                        51.69159577000009
                    ],
                    [
                        -4.691639777999939,
                        51.68988678600009
                    ],
                    [
                        -4.691639777999939,
                        51.68598053600009
                    ],
                    [
                        -4.693959113999881,
                        51.67841217700011
                    ],
                    [
                        -4.712310350999928,
                        51.65009186400009
                    ],
                    [
                        -4.721424933999856,
                        51.65204498900012
                    ],
                    [
                        -4.734934048999918,
                        51.65924713700015
                    ],
                    [
                        -4.741851365999906,
                        51.65607330900009
                    ],
                    [
                        -4.7591853509999,
                        51.64496491100006
                    ],
                    [
                        -4.848296678999873,
                        51.64630768400015
                    ],
                    [
                        -4.863514777999853,
                        51.64411041900017
                    ],
                    [
                        -4.878977016999954,
                        51.637437242000104
                    ],
                    [
                        -4.887806769999941,
                        51.62995026200004
                    ],
                    [
                        -4.895741339999887,
                        51.62107982000002
                    ],
                    [
                        -4.904774542999917,
                        51.613836981000176
                    ],
                    [
                        -4.923695441999911,
                        51.60862864799999
                    ],
                    [
                        -4.931996222999914,
                        51.5990257830001
                    ],
                    [
                        -4.941029425999915,
                        51.59650299700009
                    ],
                    [
                        -4.946034308999856,
                        51.59760163000011
                    ],
                    [
                        -4.956450975999928,
                        51.602769273000135
                    ],
                    [
                        -5.021595831999974,
                        51.616034247000144
                    ],
                    [
                        -5.045765753999888,
                        51.62689850500006
                    ],
                    [
                        -5.036610480999883,
                        51.637437242000104
                    ],
                    [
                        -5.051177537999934,
                        51.656927802
                    ],
                    [
                        -5.100493943999908,
                        51.6646996110001
                    ],
                    [
                        -5.119740363999938,
                        51.67841217700011
                    ],
                    [
                        -5.113433397999927,
                        51.68528880400014
                    ],
                    [
                        -5.036610480999883,
                        51.67841217700011
                    ],
                    [
                        -5.038726365999906,
                        51.68183014500012
                    ],
                    [
                        -5.041330532999893,
                        51.689398505
                    ],
                    [
                        -5.043446417999917,
                        51.69269440300003
                    ],
                    [
                        -5.028675910999937,
                        51.69293854400017
                    ],
                    [
                        -5.002552863999881,
                        51.68740469000015
                    ],
                    [
                        -4.988189256999903,
                        51.68585846600011
                    ],
                    [
                        -4.974598761999857,
                        51.687933661000116
                    ],
                    [
                        -4.941029425999915,
                        51.700140692
                    ],
                    [
                        -4.892648891999897,
                        51.70636627800015
                    ],
                    [
                        -4.868275519999941,
                        51.71678294500005
                    ],
                    [
                        -4.858062303999901,
                        51.718329169000086
                    ],
                    [
                        -4.844838019999884,
                        51.71381256700012
                    ],
                    [
                        -4.844838019999884,
                        51.72003815300009
                    ],
                    [
                        -4.87340247299997,
                        51.737738348
                    ],
                    [
                        -4.879017706999946,
                        51.76203034100017
                    ],
                    [
                        -4.862538214999887,
                        51.782375393000066
                    ],
                    [
                        -4.824330206999917,
                        51.788275458
                    ],
                    [
                        -4.824330206999917,
                        51.79572174700017
                    ],
                    [
                        -4.836008266999926,
                        51.790716864000146
                    ],
                    [
                        -4.864979620999918,
                        51.783270575000145
                    ],
                    [
                        -4.921294725999871,
                        51.780015367000104
                    ],
                    [
                        -4.933583136999857,
                        51.775295315
                    ],
                    [
                        -4.917225714999916,
                        51.775336005
                    ],
                    [
                        -4.905181443999908,
                        51.77118561400006
                    ],
                    [
                        -4.900542772999899,
                        51.76211172100015
                    ],
                    [
                        -4.906239386999857,
                        51.74730052299999
                    ],
                    [
                        -4.892079230999855,
                        51.739406643
                    ],
                    [
                        -4.895659959999904,
                        51.72817617400001
                    ],
                    [
                        -4.910552537999877,
                        51.71820709800012
                    ],
                    [
                        -4.988189256999903,
                        51.706854559000064
                    ],
                    [
                        -5.00991777299987,
                        51.70636627800015
                    ],
                    [
                        -5.149240688999925,
                        51.71808502800015
                    ],
                    [
                        -5.166900193999936,
                        51.71381256700012
                    ],
                    [
                        -5.156971808999856,
                        51.697577216000084
                    ],
                    [
                        -5.16860917899993,
                        51.688910223
                    ],
                    [
                        -5.182850714999859,
                        51.68927643400012
                    ],
                    [
                        -5.180653449999852,
                        51.700140692
                    ],
                    [
                        -5.180653449999852,
                        51.70636627800015
                    ],
                    [
                        -5.230132615999906,
                        51.73004791900017
                    ],
                    [
                        -5.249501105999912,
                        51.73371002800003
                    ],
                    [
                        -5.228138800999886,
                        51.73395416900017
                    ],
                    [
                        -5.213937954999949,
                        51.73574453300016
                    ],
                    [
                        -5.202870245999918,
                        51.74079010600009
                    ],
                    [
                        -5.191151495999918,
                        51.75104401200012
                    ],
                    [
                        -5.176828579999949,
                        51.75967031500012
                    ],
                    [
                        -5.14321855399993,
                        51.76386139500015
                    ],
                    [
                        -5.125965949999909,
                        51.76850006700009
                    ],
                    [
                        -5.110259568999936,
                        51.77850983300006
                    ],
                    [
                        -5.107085740999878,
                        51.78807200700005
                    ],
                    [
                        -5.11229407499988,
                        51.81317780200014
                    ],
                    [
                        -5.113392706999917,
                        51.82880280200011
                    ],
                    [
                        -5.117502407999979,
                        51.843695380000085
                    ],
                    [
                        -5.125884568999936,
                        51.856024481000034
                    ],
                    [
                        -5.139637824999852,
                        51.86399974199999
                    ],
                    [
                        -5.146473761999886,
                        51.85716380400005
                    ],
                    [
                        -5.164377407999922,
                        51.86676666900006
                    ],
                    [
                        -5.203765428999901,
                        51.870184637000065
                    ],
                    [
                        -5.221587693999879,
                        51.877671617000104
                    ],
                    [
                        -5.235829230999883,
                        51.87103913000006
                    ],
                    [
                        -5.291411912999934,
                        51.86399974199999
                    ],
                    [
                        -5.310943162999905,
                        51.86399974199999
                    ],
                    [
                        -5.304269985999952,
                        51.867621161000145
                    ],
                    [
                        -5.29735266799986,
                        51.870184637000065
                    ],
                    [
                        -5.29735266799986,
                        51.877671617000104
                    ],
                    [
                        -5.300689256999874,
                        51.88202545800003
                    ],
                    [
                        -5.302561001999948,
                        51.88523997600008
                    ],
                    [
                        -5.300160285999908,
                        51.888332424000154
                    ],
                    [
                        -5.290516730999855,
                        51.8919945330001
                    ],
                    [
                        -5.290516730999855,
                        51.89813873900009
                    ],
                    [
                        -5.298329230999912,
                        51.910956122000115
                    ],
                    [
                        -5.288929816999911,
                        51.91693756700012
                    ],
                    [
                        -5.252837693999936,
                        51.91864655200014
                    ],
                    [
                        -5.234201626999948,
                        51.923041083000115
                    ],
                    [
                        -5.204172329999921,
                        51.942287502000156
                    ],
                    [
                        -5.191151495999918,
                        51.94660065300015
                    ],
                    [
                        -5.160389777999853,
                        51.949448960000026
                    ],
                    [
                        -5.119536912999905,
                        51.95892975500011
                    ],
                    [
                        -5.088042772999899,
                        51.97601959800015
                    ],
                    [
                        -5.085031704999892,
                        52.001857815000065
                    ],
                    [
                        -5.070790167999888,
                        52.001857815000065
                    ],
                    [
                        -5.070790167999888,
                        52.00804271000014
                    ],
                    [
                        -5.082753058999913,
                        52.014471747000144
                    ],
                    [
                        -5.081206834999875,
                        52.021185614
                    ],
                    [
                        -5.070220506999903,
                        52.02635325700011
                    ],
                    [
                        -5.053700324999852,
                        52.02850983300003
                    ],
                    [
                        -5.026600714999916,
                        52.027411200000145
                    ],
                    [
                        -5.0150447259999,
                        52.02533600500014
                    ],
                    [
                        -4.961740688999925,
                        52.00751373900006
                    ],
                    [
                        -4.942534959999875,
                        52.00747304900007
                    ],
                    [
                        -4.913685675999943,
                        52.014837958000086
                    ],
                    [
                        -4.920521613999966,
                        52.02850983300003
                    ],
                    [
                        -4.901600714999859,
                        52.02643463700018
                    ],
                    [
                        -4.863189256999931,
                        52.01699453300007
                    ],
                    [
                        -4.841420050999972,
                        52.014837958000086
                    ],
                    [
                        -4.832264777999882,
                        52.018988348
                    ],
                    [
                        -4.833851691999911,
                        52.02875397300015
                    ],
                    [
                        -4.838286912999905,
                        52.04010651200012
                    ],
                    [
                        -4.838002081999946,
                        52.04901764500006
                    ],
                    [
                        -4.830393032999922,
                        52.05292389500006
                    ],
                    [
                        -4.796986456999946,
                        52.056463934000035
                    ],
                    [
                        -4.777577277999853,
                        52.064886786
                    ],
                    [
                        -4.763172980999883,
                        52.075873114000146
                    ],
                    [
                        -4.738677537999934,
                        52.10081614800008
                    ],
                    [
                        -4.720285610999895,
                        52.113348700000174
                    ],
                    [
                        -4.706532355999883,
                        52.11347077000015
                    ],
                    [
                        -4.673451300999943,
                        52.09739817900008
                    ],
                    [
                        -4.675689256999931,
                        52.12693919500013
                    ],
                    [
                        -4.637521938999953,
                        52.13849518400015
                    ],
                    [
                        -4.51382402299987,
                        52.135687567
                    ],
                    [
                        -4.50226803299995,
                        52.13837311400009
                    ],
                    [
                        -4.495757615999878,
                        52.143500067
                    ],
                    [
                        -4.490956183999884,
                        52.150376695000105
                    ],
                    [
                        -4.484283006999903,
                        52.15631745000003
                    ],
                    [
                        -4.454701300999943,
                        52.162054755
                    ],
                    [
                        -4.434803839999859,
                        52.17035553600009
                    ],
                    [
                        -4.415394660999965,
                        52.18154531500009
                    ],
                    [
                        -4.385568813999953,
                        52.20343659100003
                    ],
                    [
                        -4.37124589799987,
                        52.20929596600017
                    ],
                    [
                        -4.354725714999887,
                        52.212103583000115
                    ],
                    [
                        -4.334339972999914,
                        52.21283600500006
                    ],
                    [
                        -4.3158259759999,
                        52.216294664000046
                    ],
                    [
                        -4.197132941999882,
                        52.27928294500008
                    ],
                    [
                        -4.139068162999905,
                        52.33022695500007
                    ],
                    [
                        -4.10960852799991,
                        52.365301825000145
                    ],
                    [
                        -4.096262173999918,
                        52.38788483300014
                    ],
                    [
                        -4.087391730999883,
                        52.43012116100006
                    ],
                    [
                        -4.078968878999916,
                        52.453029690000065
                    ],
                    [
                        -4.067941860999952,
                        52.473618882
                    ],
                    [
                        -4.056548631999903,
                        52.487860419000114
                    ],
                    [
                        -4.061838344999984,
                        52.49705638200008
                    ],
                    [
                        -4.064035610999952,
                        52.50918203300007
                    ],
                    [
                        -4.062570766999897,
                        52.520982164000046
                    ],
                    [
                        -4.056548631999903,
                        52.52944570500007
                    ],
                    [
                        -4.048491990999878,
                        52.53046295800014
                    ],
                    [
                        -4.026600714999944,
                        52.523382880000085
                    ],
                    [
                        -4.016184048999946,
                        52.52195872600011
                    ],
                    [
                        -3.998768683999884,
                        52.52594635600009
                    ],
                    [
                        -3.947336391999983,
                        52.549261786
                    ],
                    [
                        -3.947336391999983,
                        52.556708075000174
                    ],
                    [
                        -3.974476691999911,
                        52.556545315
                    ],
                    [
                        -4.025868292999917,
                        52.545355536
                    ],
                    [
                        -4.053130662999905,
                        52.542425848000065
                    ],
                    [
                        -4.068470831999946,
                        52.55011627800009
                    ],
                    [
                        -4.114125128999888,
                        52.58860911699999
                    ],
                    [
                        -4.12547766799986,
                        52.60390859600015
                    ],
                    [
                        -4.122222459999904,
                        52.62872955900009
                    ],
                    [
                        -4.105946417999888,
                        52.65350983300006
                    ],
                    [
                        -4.084828253999945,
                        52.67267487200003
                    ],
                    [
                        -4.067128058999856,
                        52.680243231000176
                    ],
                    [
                        -4.063547329999892,
                        52.68309153900013
                    ],
                    [
                        -4.058827277999882,
                        52.69660065300009
                    ],
                    [
                        -4.056548631999903,
                        52.700669664000046
                    ],
                    [
                        -4.052723761999886,
                        52.702215887000094
                    ],
                    [
                        -4.04198157499988,
                        52.70465729400003
                    ],
                    [
                        -4.001088019999941,
                        52.72410716400013
                    ],
                    [
                        -3.988270636999914,
                        52.734279690000065
                    ],
                    [
                        -4.014719204999892,
                        52.73232656500012
                    ],
                    [
                        -4.031971808999884,
                        52.72394440300015
                    ],
                    [
                        -4.048817511999886,
                        52.72158437700001
                    ],
                    [
                        -4.125152147999927,
                        52.778306382
                    ],
                    [
                        -4.144357876999891,
                        52.80280182500012
                    ],
                    [
                        -4.14903723899991,
                        52.81207916900017
                    ],
                    [
                        -4.146229620999861,
                        52.82021719000009
                    ],
                    [
                        -4.128895636999857,
                        52.8236351580001
                    ],
                    [
                        -4.117339647999927,
                        52.830755927000055
                    ],
                    [
                        -4.119781053999873,
                        52.84625885600015
                    ],
                    [
                        -4.127756313999925,
                        52.861721096000096
                    ],
                    [
                        -4.132191535999908,
                        52.86831289300015
                    ],
                    [
                        -4.131988084999932,
                        52.88247304900007
                    ],
                    [
                        -4.129872199999852,
                        52.88922760600012
                    ],
                    [
                        -4.12360592399989,
                        52.893255927
                    ],
                    [
                        -4.064035610999952,
                        52.91917552299999
                    ],
                    [
                        -4.071400519999884,
                        52.92108795800014
                    ],
                    [
                        -4.074574347999942,
                        52.92279694200015
                    ],
                    [
                        -4.077626105999912,
                        52.926662502000156
                    ],
                    [
                        -4.089100714999887,
                        52.918850002000156
                    ],
                    [
                        -4.100493943999936,
                        52.914129950000145
                    ],
                    [
                        -4.110585089999887,
                        52.9156761740001
                    ],
                    [
                        -4.118560350999871,
                        52.926662502000156
                    ],
                    [
                        -4.144398566999882,
                        52.914780992000104
                    ],
                    [
                        -4.17162024599989,
                        52.913478908000016
                    ],
                    [
                        -4.228505011999914,
                        52.91917552299999
                    ],
                    [
                        -4.300689256999903,
                        52.90558502800003
                    ],
                    [
                        -4.317290818999879,
                        52.89935944200006
                    ],
                    [
                        -4.337310350999928,
                        52.892035223000065
                    ],
                    [
                        -4.406605597999885,
                        52.89252350500006
                    ],
                    [
                        -4.427235480999912,
                        52.88597239800008
                    ],
                    [
                        -4.446197068999936,
                        52.875921942
                    ],
                    [
                        -4.481678839999916,
                        52.85089752800009
                    ],
                    [
                        -4.4779353509999,
                        52.848334052000084
                    ],
                    [
                        -4.475493943999936,
                        52.84414297100015
                    ],
                    [
                        -4.491363084999875,
                        52.83820221600003
                    ],
                    [
                        -4.500965949999852,
                        52.82680898600016
                    ],
                    [
                        -4.50133216099988,
                        52.81391022300015
                    ],
                    [
                        -4.489125128999888,
                        52.803168036000116
                    ],
                    [
                        -4.501942511999914,
                        52.79498932500003
                    ],
                    [
                        -4.509185350999871,
                        52.791449286000145
                    ],
                    [
                        -4.516468878999973,
                        52.789496161
                    ],
                    [
                        -4.528553839999887,
                        52.790472723000065
                    ],
                    [
                        -4.542469855999855,
                        52.80068594
                    ],
                    [
                        -4.563954230999883,
                        52.80524323100015
                    ],
                    [
                        -4.583078579999949,
                        52.81464264500009
                    ],
                    [
                        -4.594634568999879,
                        52.81679922100007
                    ],
                    [
                        -4.606516079999921,
                        52.815090236000074
                    ],
                    [
                        -4.61351477799991,
                        52.81110260600009
                    ],
                    [
                        -4.619211391999897,
                        52.80654531500015
                    ],
                    [
                        -4.626332160999937,
                        52.803168036000116
                    ],
                    [
                        -4.648589647999899,
                        52.799627997000144
                    ],
                    [
                        -4.704497850999871,
                        52.803168036000116
                    ],
                    [
                        -4.719309048999918,
                        52.79751211100013
                    ],
                    [
                        -4.733225063999896,
                        52.78750234600015
                    ],
                    [
                        -4.747425910999908,
                        52.783433335
                    ],
                    [
                        -4.762847459999932,
                        52.789496161
                    ],
                    [
                        -4.752512173999946,
                        52.79877350500006
                    ],
                    [
                        -4.751454230999883,
                        52.80320872600011
                    ],
                    [
                        -4.755441860999952,
                        52.809312242000104
                    ],
                    [
                        -4.749256964999887,
                        52.81427643400015
                    ],
                    [
                        -4.734934048999918,
                        52.83047109600001
                    ],
                    [
                        -4.596058722999942,
                        52.928412177000055
                    ],
                    [
                        -4.580962693999936,
                        52.933498440000065
                    ],
                    [
                        -4.562082485999923,
                        52.93451569200012
                    ],
                    [
                        -4.543527798999918,
                        52.937933661000145
                    ],
                    [
                        -4.523996548999918,
                        52.9442406270001
                    ],
                    [
                        -4.443430141999954,
                        52.98216380400011
                    ],
                    [
                        -4.423695441999939,
                        53.002630927000084
                    ],
                    [
                        -4.358225063999896,
                        53.028469143000095
                    ],
                    [
                        -4.349964972999914,
                        53.04148997599999
                    ],
                    [
                        -4.345692511999886,
                        53.05947500200001
                    ],
                    [
                        -4.343861456999917,
                        53.10106028900002
                    ],
                    [
                        -4.340891079999892,
                        53.11273834800009
                    ],
                    [
                        -4.334339972999914,
                        53.11676666900014
                    ],
                    [
                        -4.327626105999883,
                        53.11469147300012
                    ],
                    [
                        -4.32469641799986,
                        53.10789622600011
                    ],
                    [
                        -4.320668097999885,
                        53.10150788
                    ],
                    [
                        -4.312245245999918,
                        53.109442450000145
                    ],
                    [
                        -4.304758266999954,
                        53.12099844000009
                    ],
                    [
                        -4.303618943999936,
                        53.125311591000084
                    ],
                    [
                        -4.263539191999911,
                        53.15452708500011
                    ],
                    [
                        -4.252349412999905,
                        53.15940989799999
                    ],
                    [
                        -4.234527147999927,
                        53.16400788000011
                    ],
                    [
                        -4.221302863999881,
                        53.17503489800008
                    ],
                    [
                        -4.20055091099988,
                        53.20099518400015
                    ],
                    [
                        -4.17796790299991,
                        53.21759674700003
                    ],
                    [
                        -4.151519334999932,
                        53.228094794000086
                    ],
                    [
                        -4.122222459999904,
                        53.23346588700015
                    ],
                    [
                        -4.090687628999916,
                        53.235174872000144
                    ],
                    [
                        -4.082346157999922,
                        53.23354726800012
                    ],
                    [
                        -4.074330206999889,
                        53.23065827
                    ],
                    [
                        -4.066883917999917,
                        53.22907135600015
                    ],
                    [
                        -4.054107225999871,
                        53.23436107000005
                    ],
                    [
                        -4.032297329999921,
                        53.24070872600008
                    ],
                    [
                        -4.008900519999941,
                        53.24445221600011
                    ],
                    [
                        -3.974598761999886,
                        53.26178620000009
                    ],
                    [
                        -3.865305141999954,
                        53.289129950000174
                    ],
                    [
                        -3.831288214999944,
                        53.289129950000174
                    ],
                    [
                        -3.841420050999886,
                        53.30744049700003
                    ],
                    [
                        -3.870757615999906,
                        53.32562897300012
                    ],
                    [
                        -3.87840735599994,
                        53.33759186400006
                    ],
                    [
                        -3.85997473899991,
                        53.33633047100015
                    ],
                    [
                        -3.840565558999884,
                        53.332831122000144
                    ],
                    [
                        -3.822987433999856,
                        53.32656484600001
                    ],
                    [
                        -3.810129360999923,
                        53.317084052
                    ],
                    [
                        -3.790516730999883,
                        53.323431708000115
                    ],
                    [
                        -3.768869594999984,
                        53.31915924700003
                    ],
                    [
                        -3.74819902299987,
                        53.30780670800014
                    ],
                    [
                        -3.73192298099994,
                        53.29287344
                    ],
                    [
                        -3.716297980999855,
                        53.28693268400018
                    ],
                    [
                        -3.602202928999958,
                        53.288885809000035
                    ],
                    [
                        -3.562326626999891,
                        53.294419664000046
                    ],
                    [
                        -3.372059699999909,
                        53.350897528000175
                    ],
                    [
                        -3.330311652999882,
                        53.35179271000008
                    ],
                    [
                        -3.292388475999957,
                        53.340521552000084
                    ],
                    [
                        -3.271311001999919,
                        53.33055247599999
                    ],
                    [
                        -3.26207434799997,
                        53.32050202
                    ],
                    [
                        -3.250803188999953,
                        53.312567450000145
                    ],
                    [
                        -3.122222459999932,
                        53.262274481000176
                    ],
                    [
                        -3.107533331999917,
                        53.251898505
                    ],
                    [
                        -3.0951228509999,
                        53.239325262000094
                    ],
                    [
                        -3.087757941999911,
                        53.23468659100017
                    ],
                    [
                        -3.084543423999946,
                        53.23859284100017
                    ],
                    [
                        -3.084543423999946,
                        53.25857168200004
                    ],
                    [
                        -3.084543423999946,
                        53.258577179000056
                    ],
                    [
                        -3.084543423999946,
                        53.27545807500003
                    ],
                    [
                        -3.09593665299991,
                        53.28713613500004
                    ],
                    [
                        -3.111195441999882,
                        53.30280182500012
                    ],
                    [
                        -3.135406053999873,
                        53.333807684000035
                    ],
                    [
                        -3.171538865999906,
                        53.36225006700015
                    ],
                    [
                        -3.185210740999935,
                        53.37726471600017
                    ],
                    [
                        -3.186350063999896,
                        53.39276764500009
                    ],
                    [
                        -3.17552649599989,
                        53.40273672100007
                    ],
                    [
                        -3.157297329999921,
                        53.408758856000176
                    ],
                    [
                        -3.114654100999871,
                        53.412665106000176
                    ],
                    [
                        -3.094227667999917,
                        53.4173851580001
                    ],
                    [
                        -3.058257615999935,
                        53.43475983300006
                    ],
                    [
                        -3.035552537999877,
                        53.433742580000015
                    ],
                    [
                        -3.012196417999888,
                        53.416693427000055
                    ],
                    [
                        -2.952951626999891,
                        53.32391998900012
                    ],
                    [
                        -2.935129360999923,
                        53.309475002000156
                    ],
                    [
                        -2.910552537999905,
                        53.296332098
                    ],
                    [
                        -2.88149980399993,
                        53.28839752800003
                    ],
                    [
                        -2.850575324999909,
                        53.289129950000174
                    ],
                    [
                        -2.849598761999914,
                        53.29120514500009
                    ],
                    [
                        -2.848378058999913,
                        53.295558986000074
                    ],
                    [
                        -2.844960089999887,
                        53.30011627800003
                    ],
                    [
                        -2.837554490999906,
                        53.30280182500012
                    ],
                    [
                        -2.828602667999888,
                        53.302435614
                    ],
                    [
                        -2.813384568999908,
                        53.29775625200007
                    ],
                    [
                        -2.806141730999883,
                        53.296576239000146
                    ],
                    [
                        -2.789784308999856,
                        53.29743073100015
                    ],
                    [
                        -2.775502081999974,
                        53.29987213700018
                    ],
                    [
                        -2.748117641999897,
                        53.30963776200012
                    ],
                    [
                        -2.753651495999918,
                        53.32245514500006
                    ],
                    [
                        -2.750884568999879,
                        53.33161041900006
                    ],
                    [
                        -2.743153449999852,
                        53.33852773600013
                    ],
                    [
                        -2.734486456999946,
                        53.344427802000084
                    ],
                    [
                        -2.726144985999895,
                        53.34198639500012
                    ],
                    [
                        -2.717437303999901,
                        53.34271881700006
                    ],
                    [
                        -2.708607550999943,
                        53.34613678600009
                    ],
                    [
                        -2.69977779899989,
                        53.35179271000008
                    ],
                    [
                        -2.714100714999887,
                        53.35236237200003
                    ],
                    [
                        -2.764393683999856,
                        53.345160223
                    ],
                    [
                        -2.775380011999886,
                        53.34100983300006
                    ],
                    [
                        -2.782378709999875,
                        53.32636139500015
                    ],
                    [
                        -2.799794074999852,
                        53.32103099199999
                    ],
                    [
                        -2.810332811999899,
                        53.32172272300012
                    ],
                    [
                        -2.843658006999903,
                        53.32391998900012
                    ],
                    [
                        -2.88646399599989,
                        53.333563544000086
                    ],
                    [
                        -2.924956834999875,
                        53.350002346000096
                    ],
                    [
                        -2.956939256999874,
                        53.37055084800012
                    ],
                    [
                        -2.980824347999942,
                        53.39276764500009
                    ],
                    [
                        -3.096587693999879,
                        53.544338283000016
                    ],
                    [
                        -3.101918097999914,
                        53.55805084800012
                    ],
                    [
                        -3.099476691999882,
                        53.57200755400011
                    ],
                    [
                        -3.087635870999918,
                        53.58771393400015
                    ],
                    [
                        -3.021839972999942,
                        53.65281810100011
                    ],
                    [
                        -2.968658006999874,
                        53.694037177000055
                    ],
                    [
                        -2.9603572259999,
                        53.69757721600014
                    ],
                    [
                        -2.95173092399989,
                        53.70807526200012
                    ],
                    [
                        -2.911447719999899,
                        53.725043036
                    ],
                    [
                        -2.899037238999881,
                        53.73541901200018
                    ],
                    [
                        -2.984852667999917,
                        53.73607005400014
                    ],
                    [
                        -3.021473761999857,
                        53.74579498900009
                    ],
                    [
                        -3.049183722999942,
                        53.76951732000002
                    ],
                    [
                        -3.056385870999861,
                        53.803045966000056
                    ],
                    [
                        -3.044911261999914,
                        53.87848541900017
                    ],
                    [
                        -3.056630011999914,
                        53.906154690000065
                    ],
                    [
                        -3.040679490999906,
                        53.92352936400006
                    ],
                    [
                        -3.017567511999857,
                        53.93195221600011
                    ],
                    [
                        -2.925689256999903,
                        53.952704169000114
                    ],
                    [
                        -2.909250454999921,
                        53.95384349200005
                    ],
                    [
                        -2.896107550999943,
                        53.94773997600005
                    ],
                    [
                        -2.888539191999911,
                        53.94603099200005
                    ],
                    [
                        -2.885365363999938,
                        53.95050690300012
                    ],
                    [
                        -2.881988084999932,
                        53.9565290390001
                    ],
                    [
                        -2.874175584999932,
                        53.96165599200013
                    ],
                    [
                        -2.865142381999931,
                        53.96548086100013
                    ],
                    [
                        -2.85798092399989,
                        53.967596747000144
                    ],
                    [
                        -2.869252081999889,
                        53.97882721600014
                    ],
                    [
                        -2.834462042999917,
                        53.99982330900009
                    ],
                    [
                        -2.830067511999857,
                        54.01601797100015
                    ],
                    [
                        -2.847523566999911,
                        54.00836823100012
                    ],
                    [
                        -2.864857550999886,
                        54.00429922100007
                    ],
                    [
                        -2.895497199999909,
                        54.00234609600001
                    ],
                    [
                        -2.900298631999903,
                        54.00495026200012
                    ],
                    [
                        -2.916086391999926,
                        54.026556708000115
                    ],
                    [
                        -2.918202277999853,
                        54.034328518
                    ],
                    [
                        -2.911284959999932,
                        54.039984442
                    ],
                    [
                        -2.900786912999877,
                        54.04490794500008
                    ],
                    [
                        -2.892079230999883,
                        54.05011627800015
                    ],
                    [
                        -2.872670050999886,
                        54.07160065300009
                    ],
                    [
                        -2.858876105999883,
                        54.08100006700003
                    ],
                    [
                        -2.82677161399991,
                        54.088812567
                    ],
                    [
                        -2.815174933999884,
                        54.098781643
                    ],
                    [
                        -2.795969204999921,
                        54.12518952000009
                    ],
                    [
                        -2.812814907999922,
                        54.13987864799999
                    ],
                    [
                        -2.836415167999888,
                        54.16758860900008
                    ],
                    [
                        -2.850941535999908,
                        54.18463776200004
                    ],
                    [
                        -2.854237433999856,
                        54.19407786700005
                    ],
                    [
                        -2.832875128999888,
                        54.19904205900009
                    ],
                    [
                        -2.810292120999918,
                        54.21190013200011
                    ],
                    [
                        -2.795236782999893,
                        54.22947825700005
                    ],
                    [
                        -2.795969204999921,
                        54.248724677
                    ],
                    [
                        -2.802805141999954,
                        54.248724677
                    ],
                    [
                        -2.806630011999857,
                        54.233710028000175
                    ],
                    [
                        -2.820871548999975,
                        54.22174713700004
                    ],
                    [
                        -2.850575324999909,
                        54.20774974199999
                    ],
                    [
                        -2.886382615999906,
                        54.19627513200014
                    ],
                    [
                        -2.904652472999885,
                        54.18764883000013
                    ],
                    [
                        -2.912668423999918,
                        54.17698802300002
                    ],
                    [
                        -2.920399542999945,
                        54.16205475500006
                    ],
                    [
                        -2.93919837099989,
                        54.155096747000144
                    ],
                    [
                        -2.984201626999976,
                        54.153143622000115
                    ],
                    [
                        -2.997710740999935,
                        54.15957265800013
                    ],
                    [
                        -3.011341925999886,
                        54.17413971600014
                    ],
                    [
                        -3.017323370999918,
                        54.19009023600016
                    ],
                    [
                        -3.008168097999914,
                        54.200344143000095
                    ],
                    [
                        -3.015370245999861,
                        54.21190013200011
                    ],
                    [
                        -3.024484829999949,
                        54.22158437700007
                    ],
                    [
                        -3.035755988999938,
                        54.22597890800016
                    ],
                    [
                        -3.049183722999942,
                        54.22207265800016
                    ],
                    [
                        -3.04466712099989,
                        54.20831940300015
                    ],
                    [
                        -3.047840949999852,
                        54.194322007
                    ],
                    [
                        -3.053537563999925,
                        54.18130117400001
                    ],
                    [
                        -3.056630011999914,
                        54.17023346600014
                    ],
                    [
                        -3.061024542999888,
                        54.16193268400018
                    ],
                    [
                        -3.090728318999908,
                        54.13947174700017
                    ],
                    [
                        -3.10765540299991,
                        54.118557033000016
                    ],
                    [
                        -3.117909308999913,
                        54.108832098000065
                    ],
                    [
                        -3.145253058999913,
                        54.100043036
                    ],
                    [
                        -3.14745032499988,
                        54.08869049700003
                    ],
                    [
                        -3.144398566999911,
                        54.07514069200006
                    ],
                    [
                        -3.145375128999888,
                        54.06378815300009
                    ],
                    [
                        -3.180531378999945,
                        54.09284088700015
                    ],
                    [
                        -3.189768032999893,
                        54.097886460000026
                    ],
                    [
                        -3.205718553999901,
                        54.098537502000156
                    ],
                    [
                        -3.219553188999896,
                        54.10077545800014
                    ],
                    [
                        -3.23110917899993,
                        54.105129299000126
                    ],
                    [
                        -3.240956183999856,
                        54.112127997000115
                    ],
                    [
                        -3.238107876999919,
                        54.12759023600013
                    ],
                    [
                        -3.232533331999889,
                        54.14468008000007
                    ],
                    [
                        -3.233143683999856,
                        54.15916575700011
                    ],
                    [
                        -3.248402472999914,
                        54.166815497000144
                    ],
                    [
                        -3.248402472999914,
                        54.17365143400015
                    ],
                    [
                        -3.215199347999914,
                        54.17983633000013
                    ],
                    [
                        -3.206776495999861,
                        54.20892975500003
                    ],
                    [
                        -3.209055141999926,
                        54.242743231000176
                    ],
                    [
                        -3.207386847999914,
                        54.26300690300009
                    ],
                    [
                        -3.223133917999888,
                        54.257635809000035
                    ],
                    [
                        -3.231353318999879,
                        54.24721914300015
                    ],
                    [
                        -3.240956183999856,
                        54.22207265800016
                    ],
                    [
                        -3.23216712099989,
                        54.20254140800007
                    ],
                    [
                        -3.247141079999921,
                        54.197943427000055
                    ],
                    [
                        -3.27013098899991,
                        54.199123440000065
                    ],
                    [
                        -3.286000128999916,
                        54.19721100500011
                    ],
                    [
                        -3.306792772999927,
                        54.19188060100005
                    ],
                    [
                        -3.328521287999877,
                        54.20473867400007
                    ],
                    [
                        -3.364491339999859,
                        54.242499091000056
                    ],
                    [
                        -3.394195115999878,
                        54.264390367000075
                    ],
                    [
                        -3.407378709999932,
                        54.277736721000096
                    ],
                    [
                        -3.412912563999953,
                        54.29376862200017
                    ],
                    [
                        -3.410389777999853,
                        54.301459052
                    ],
                    [
                        -3.405995245999861,
                        54.309637762000094
                    ],
                    [
                        -3.404123501999891,
                        54.31854889500012
                    ],
                    [
                        -3.411447719999899,
                        54.33274974200005
                    ],
                    [
                        -3.413441535999937,
                        54.342962958
                    ],
                    [
                        -3.41633053299995,
                        54.34491608300014
                    ],
                    [
                        -3.440174933999856,
                        54.35175202000015
                    ],
                    [
                        -3.474273240999963,
                        54.393500067
                    ],
                    [
                        -3.497710740999935,
                        54.40582916900006
                    ],
                    [
                        -3.591867641999926,
                        54.483099677000055
                    ],
                    [
                        -3.608143683999884,
                        54.48826732000008
                    ],
                    [
                        -3.613189256999903,
                        54.49164459800009
                    ],
                    [
                        -3.623687303999873,
                        54.50608958500014
                    ],
                    [
                        -3.634185350999957,
                        54.51288483300006
                    ],
                    [
                        -3.626820441999939,
                        54.52041250200004
                    ],
                    [
                        -3.616200324999909,
                        54.527573960000055
                    ],
                    [
                        -3.611561652999882,
                        54.52993398600002
                    ],
                    [
                        -3.590321417999888,
                        54.56464264500009
                    ],
                    [
                        -3.563832160999937,
                        54.642767645
                    ],
                    [
                        -3.530140753999916,
                        54.688055731000034
                    ],
                    [
                        -3.516021287999905,
                        54.71287669499999
                    ],
                    [
                        -3.509185350999871,
                        54.72166575700005
                    ],
                    [
                        -3.500721808999856,
                        54.72597890800013
                    ],
                    [
                        -3.478871222999885,
                        54.73090241100003
                    ],
                    [
                        -3.46812903599988,
                        54.735296942
                    ],
                    [
                        -3.455433722999914,
                        54.74502187700013
                    ],
                    [
                        -3.439564581999889,
                        54.76146067900014
                    ],
                    [
                        -3.430978969999956,
                        54.78046295800014
                    ],
                    [
                        -3.440174933999856,
                        54.79743073100012
                    ],
                    [
                        -3.407541469999899,
                        54.85602448100015
                    ],
                    [
                        -3.387928839999859,
                        54.883368231000034
                    ],
                    [
                        -3.364491339999859,
                        54.899807033000016
                    ],
                    [
                        -3.344960089999887,
                        54.90253327000009
                    ],
                    [
                        -3.33336341099988,
                        54.897284247000115
                    ],
                    [
                        -3.322743292999917,
                        54.889878648000106
                    ],
                    [
                        -3.306467251999891,
                        54.886175848000065
                    ],
                    [
                        -3.295725063999981,
                        54.88690827
                    ],
                    [
                        -3.254628058999884,
                        54.899807033000016
                    ],
                    [
                        -3.267323370999861,
                        54.905503648000106
                    ],
                    [
                        -3.317290818999879,
                        54.92031484600007
                    ],
                    [
                        -3.29149329299986,
                        54.936672268000095
                    ],
                    [
                        -3.276519334999932,
                        54.94342682500003
                    ],
                    [
                        -3.213612433999884,
                        54.954413153000175
                    ],
                    [
                        -3.201039191999882,
                        54.952215887000094
                    ],
                    [
                        -3.193674282999893,
                        54.948635158000016
                    ],
                    [
                        -3.187977667999917,
                        54.944484768000095
                    ],
                    [
                        -3.180165167999917,
                        54.94082265800013
                    ],
                    [
                        -3.142811652999882,
                        54.93353913000011
                    ],
                    [
                        -3.12279212099989,
                        54.93268463700012
                    ],
                    [
                        -3.03954016799986,
                        54.947170315000065
                    ],
                    [
                        -3.021839972999942,
                        54.954413153000175
                    ],
                    [
                        -3.090728318999908,
                        54.954413153000175
                    ],
                    [
                        -3.090728318999908,
                        54.96124909100011
                    ],
                    [
                        -3.071522589999859,
                        54.96312083500008
                    ],
                    [
                        -3.056223110999895,
                        54.968817450000145
                    ],
                    [
                        -3.040923631999931,
                        54.97247955900009
                    ],
                    [
                        -3.021839972999942,
                        54.96808502800012
                    ],
                    [
                        -3.021839972999942,
                        54.975531317
                    ],
                    [
                        -3.0462947259999,
                        54.98212311400006
                    ],
                    [
                        -3.050282355999883,
                        54.98151276200012
                    ],
                    [
                        -3.050291125459182,
                        54.98151137867244
                    ],
                    [
                        -3.135406053999873,
                        54.96808502800012
                    ],
                    [
                        -3.453846808999884,
                        54.98175690300015
                    ],
                    [
                        -3.470692511999886,
                        54.98444245000003
                    ],
                    [
                        -3.486236131999903,
                        54.98948802300008
                    ],
                    [
                        -3.501047329999949,
                        54.99249909100017
                    ],
                    [
                        -3.515288865999878,
                        54.98920319200012
                    ],
                    [
                        -3.51235917899993,
                        54.987127997000115
                    ],
                    [
                        -3.511463995999861,
                        54.98688385600015
                    ],
                    [
                        -3.510894334999875,
                        54.98598867400007
                    ],
                    [
                        -3.509185350999871,
                        54.98175690300015
                    ],
                    [
                        -3.526031053999873,
                        54.977118231000034
                    ],
                    [
                        -3.541859503999916,
                        54.98065827
                    ],
                    [
                        -3.570546027999882,
                        54.99542877800009
                    ],
                    [
                        -3.583607550999943,
                        54.96808502800012
                    ],
                    [
                        -3.575672980999883,
                        54.95734284100011
                    ],
                    [
                        -3.57062740799995,
                        54.937567450000174
                    ],
                    [
                        -3.568755662999905,
                        54.91600169500008
                    ],
                    [
                        -3.570546027999882,
                        54.899807033000016
                    ],
                    [
                        -3.581695115999906,
                        54.88361237200017
                    ],
                    [
                        -3.598378058999856,
                        54.87799713700015
                    ],
                    [
                        -3.691761847999914,
                        54.88320547100007
                    ],
                    [
                        -3.717640753999945,
                        54.880845445000105
                    ],
                    [
                        -3.739247199999852,
                        54.85980866100009
                    ],
                    [
                        -3.810129360999923,
                        54.86627838700018
                    ],
                    [
                        -3.807850714999859,
                        54.861070054
                    ],
                    [
                        -3.806792772999899,
                        54.86090729400003
                    ],
                    [
                        -3.80333411399991,
                        54.85887278900002
                    ],
                    [
                        -3.812367316999911,
                        54.855169989000146
                    ],
                    [
                        -3.819813605999883,
                        54.85590241100009
                    ],
                    [
                        -3.826079881999931,
                        54.85993073100015
                    ],
                    [
                        -3.831288214999944,
                        54.86627838700018
                    ],
                    [
                        -3.819976365999935,
                        54.878566799000126
                    ],
                    [
                        -3.815052863999881,
                        54.87714264500006
                    ],
                    [
                        -3.810129360999923,
                        54.87250397300012
                    ],
                    [
                        -3.811634894999884,
                        54.880031643
                    ],
                    [
                        -3.813221808999913,
                        54.883124091000084
                    ],
                    [
                        -3.816965298999946,
                        54.886175848000065
                    ],
                    [
                        -3.828846808999913,
                        54.87498607000008
                    ],
                    [
                        -3.844309048999918,
                        54.86627838700018
                    ],
                    [
                        -3.839751756999874,
                        54.85590241100009
                    ],
                    [
                        -3.837473110999895,
                        54.85199616100009
                    ],
                    [
                        -3.844105597999885,
                        54.850978908000016
                    ],
                    [
                        -3.857899542999888,
                        54.844549872000115
                    ],
                    [
                        -3.823841925999943,
                        54.82469310100011
                    ],
                    [
                        -3.833607550999886,
                        54.82005442900008
                    ],
                    [
                        -3.885731574999852,
                        54.80646393400012
                    ],
                    [
                        -3.964182094999984,
                        54.771918036000116
                    ],
                    [
                        -4.011708136999886,
                        54.768215236000074
                    ],
                    [
                        -4.042876756999874,
                        54.769476630000085
                    ],
                    [
                        -4.042876756999874,
                        54.77692291900014
                    ],
                    [
                        -4.046783006999874,
                        54.77802155200003
                    ],
                    [
                        -4.050200975999871,
                        54.77855052299999
                    ],
                    [
                        -4.053334113999938,
                        54.779974677000055
                    ],
                    [
                        -4.055775519999884,
                        54.78253815300015
                    ],
                    [
                        -4.052967902999853,
                        54.785467841000084
                    ],
                    [
                        -4.05016028599988,
                        54.78900788000006
                    ],
                    [
                        -4.049712693999879,
                        54.78998444200012
                    ],
                    [
                        -4.051747199999909,
                        54.800034898000106
                    ],
                    [
                        -4.047718878999945,
                        54.81305573100009
                    ],
                    [
                        -4.049956834999932,
                        54.82322825700014
                    ],
                    [
                        -4.063588019999884,
                        54.82684967700003
                    ],
                    [
                        -4.064035610999952,
                        54.83152903900013
                    ],
                    [
                        -4.078602667999888,
                        54.82412344000012
                    ],
                    [
                        -4.090077277999853,
                        54.80975983300006
                    ],
                    [
                        -4.093495245999861,
                        54.79266998900012
                    ],
                    [
                        -4.083851691999882,
                        54.77692291900014
                    ],
                    [
                        -4.122954881999931,
                        54.77301666900014
                    ],
                    [
                        -4.173939581999946,
                        54.79230377800009
                    ],
                    [
                        -4.209136522999899,
                        54.826239325000145
                    ],
                    [
                        -4.20055091099988,
                        54.86627838700018
                    ],
                    [
                        -4.215402798999946,
                        54.861029364
                    ],
                    [
                        -4.239735480999912,
                        54.84300364800008
                    ],
                    [
                        -4.255767381999903,
                        54.838324286000145
                    ],
                    [
                        -4.270741339999944,
                        54.839422919000086
                    ],
                    [
                        -4.345285610999895,
                        54.86017487200009
                    ],
                    [
                        -4.356922980999883,
                        54.86554596600014
                    ],
                    [
                        -4.365630662999877,
                        54.87250397300012
                    ],
                    [
                        -4.37515214799987,
                        54.88690827
                    ],
                    [
                        -4.37987219999988,
                        54.897894598000065
                    ],
                    [
                        -4.386789516999983,
                        54.90477122600008
                    ],
                    [
                        -4.402902798999946,
                        54.90729401200018
                    ],
                    [
                        -4.409006313999925,
                        54.903021552000084
                    ],
                    [
                        -4.416818813999925,
                        54.89280833500014
                    ],
                    [
                        -4.423573370999861,
                        54.88035716400013
                    ],
                    [
                        -4.426503058999884,
                        54.86945221600014
                    ],
                    [
                        -4.417225714999944,
                        54.845933335
                    ],
                    [
                        -4.409413214999859,
                        54.831244208
                    ],
                    [
                        -4.402902798999946,
                        54.82469310100011
                    ],
                    [
                        -4.386708136999886,
                        54.82416413000011
                    ],
                    [
                        -4.372425910999908,
                        54.82184479400014
                    ],
                    [
                        -4.359608527999882,
                        54.81663646000008
                    ],
                    [
                        -4.347645636999857,
                        54.80731842700011
                    ],
                    [
                        -4.344471808999884,
                        54.794501044000086
                    ],
                    [
                        -4.353179490999963,
                        54.78310781500012
                    ],
                    [
                        -4.358143683999913,
                        54.77098216400013
                    ],
                    [
                        -4.343861456999917,
                        54.75641510600009
                    ],
                    [
                        -4.356516079999892,
                        54.74188873900006
                    ],
                    [
                        -4.353260870999947,
                        54.72089264500012
                    ],
                    [
                        -4.348133917999917,
                        54.701971747000115
                    ],
                    [
                        -4.354725714999887,
                        54.693793036
                    ],
                    [
                        -4.360340949999909,
                        54.69159577
                    ],
                    [
                        -4.365305141999954,
                        54.68695709800009
                    ],
                    [
                        -4.371896938999925,
                        54.68227773600016
                    ],
                    [
                        -4.382394985999895,
                        54.68008047100007
                    ],
                    [
                        -4.405262824999909,
                        54.68207428600003
                    ],
                    [
                        -4.426503058999884,
                        54.68756745000003
                    ],
                    [
                        -4.45181230399993,
                        54.69879791900006
                    ],
                    [
                        -4.461171027999882,
                        54.701239325000174
                    ],
                    [
                        -4.49673417899993,
                        54.700628973000036
                    ],
                    [
                        -4.50560462099989,
                        54.704657294000086
                    ],
                    [
                        -4.563628709999961,
                        54.75641510600009
                    ],
                    [
                        -4.596669074999909,
                        54.77529531500012
                    ],
                    [
                        -4.714466925999943,
                        54.81785716399999
                    ],
                    [
                        -4.759592251999891,
                        54.82534414300015
                    ],
                    [
                        -4.781076626999919,
                        54.83307526200015
                    ],
                    [
                        -4.799631313999925,
                        54.86127350500014
                    ],
                    [
                        -4.821034308999884,
                        54.866644598
                    ],
                    [
                        -4.859120245999861,
                        54.86627838700018
                    ],
                    [
                        -4.883046027999853,
                        54.860093492000104
                    ],
                    [
                        -4.905873175999943,
                        54.849798895
                    ],
                    [
                        -4.925770636999857,
                        54.837388414000074
                    ],
                    [
                        -4.941029425999915,
                        54.82469310100011
                    ],
                    [
                        -4.949615037999934,
                        54.81582265800016
                    ],
                    [
                        -4.95368404899989,
                        54.810003973
                    ],
                    [
                        -4.954823370999918,
                        54.80475495000012
                    ],
                    [
                        -4.954701300999943,
                        54.79743073100012
                    ],
                    [
                        -4.95287024599989,
                        54.788967190000065
                    ],
                    [
                        -4.94790605399993,
                        54.77732982000008
                    ],
                    [
                        -4.940256313999896,
                        54.76703522300015
                    ],
                    [
                        -4.930165167999917,
                        54.76264069200006
                    ],
                    [
                        -4.923247850999928,
                        54.751857815000065
                    ],
                    [
                        -4.911936001999948,
                        54.72821686400012
                    ],
                    [
                        -4.895985480999855,
                        54.704535223
                    ],
                    [
                        -4.867258266999897,
                        54.69013092700014
                    ],
                    [
                        -4.866932745999861,
                        54.68146393400015
                    ],
                    [
                        -4.872141079999949,
                        54.66331614800005
                    ],
                    [
                        -4.87242591099988,
                        54.654038804
                    ],
                    [
                        -4.87205969999988,
                        54.649237372000115
                    ],
                    [
                        -4.859120245999861,
                        54.63296133000007
                    ],
                    [
                        -4.88060462099989,
                        54.63621653900013
                    ],
                    [
                        -4.923491990999878,
                        54.64972565300009
                    ],
                    [
                        -4.944081183999913,
                        54.652777411
                    ],
                    [
                        -4.95368404899989,
                        54.65973541900017
                    ],
                    [
                        -4.959868943999936,
                        54.67552317900011
                    ],
                    [
                        -4.958973761999857,
                        54.69196198100012
                    ],
                    [
                        -4.947255011999886,
                        54.701239325000174
                    ],
                    [
                        -4.954823370999918,
                        54.707464911000116
                    ],
                    [
                        -4.958892381999874,
                        54.71405670800003
                    ],
                    [
                        -4.959055141999926,
                        54.72093333500011
                    ],
                    [
                        -4.954701300999943,
                        54.72850169500008
                    ],
                    [
                        -4.973378058999913,
                        54.72972239799999
                    ],
                    [
                        -4.981556769999941,
                        54.73163483300014
                    ],
                    [
                        -4.988189256999903,
                        54.735296942
                    ],
                    [
                        -4.99266516799986,
                        54.744330145
                    ],
                    [
                        -4.997059699999852,
                        54.75836823100015
                    ],
                    [
                        -5.003285285999908,
                        54.77130768400015
                    ],
                    [
                        -5.013010219999956,
                        54.77692291900014
                    ],
                    [
                        -5.033192511999886,
                        54.782416083
                    ],
                    [
                        -5.128244594999899,
                        54.84894440300009
                    ],
                    [
                        -5.157134568999908,
                        54.879095770000035
                    ],
                    [
                        -5.175526495999861,
                        54.91461823100009
                    ],
                    [
                        -5.180653449999852,
                        54.954413153000175
                    ],
                    [
                        -5.177601691999882,
                        54.99054596600003
                    ],
                    [
                        -5.170806443999936,
                        55.00869375200004
                    ],
                    [
                        -5.156727667999888,
                        55.016546942
                    ],
                    [
                        -5.135609503999888,
                        55.015855210000055
                    ],
                    [
                        -5.124582485999923,
                        55.01679108300014
                    ],
                    [
                        -5.119740363999938,
                        55.019924221000124
                    ],
                    [
                        -5.115142381999931,
                        55.03156159100003
                    ],
                    [
                        -5.105213995999918,
                        55.02619049700017
                    ],
                    [
                        -5.095529751999948,
                        55.01508209800015
                    ],
                    [
                        -5.091867641999897,
                        55.009711005
                    ],
                    [
                        -5.076893683999884,
                        54.995550848000065
                    ],
                    [
                        -5.069488084999875,
                        54.98602936400006
                    ],
                    [
                        -5.064564581999917,
                        54.975531317
                    ],
                    [
                        -5.063791469999899,
                        54.96458567900014
                    ],
                    [
                        -5.065256313999953,
                        54.94033437700013
                    ],
                    [
                        -5.060861782999893,
                        54.931138414000074
                    ],
                    [
                        -5.047515428999873,
                        54.92031484600007
                    ],
                    [
                        -5.03099524599989,
                        54.91132233300006
                    ],
                    [
                        -5.013091600999928,
                        54.90961334800015
                    ],
                    [
                        -4.995594855999883,
                        54.92031484600007
                    ],
                    [
                        -4.992298956999946,
                        54.92426178600006
                    ],
                    [
                        -4.988189256999903,
                        54.92772044500008
                    ],
                    [
                        -4.988189256999903,
                        54.93455638200008
                    ],
                    [
                        -5.024077928999901,
                        54.97638580900009
                    ],
                    [
                        -5.029774542999888,
                        54.985500393000095
                    ],
                    [
                        -5.03250077999985,
                        54.993719794000086
                    ],
                    [
                        -5.033029751999919,
                        54.99526601800012
                    ],
                    [
                        -5.047027147999899,
                        55.01508209800015
                    ],
                    [
                        -5.05028235599994,
                        55.02680084800012
                    ],
                    [
                        -5.049672003999888,
                        55.0489769550001
                    ],
                    [
                        -5.047596808999856,
                        55.05573151200004
                    ],
                    [
                        -5.043446417999917,
                        55.06427643400015
                    ],
                    [
                        -5.025786912999905,
                        55.088690497000115
                    ],
                    [
                        -5.018950975999871,
                        55.102240302000084
                    ],
                    [
                        -5.016184048999918,
                        55.12262604400014
                    ],
                    [
                        -5.007801886999857,
                        55.14118073100015
                    ],
                    [
                        -4.988270636999886,
                        55.15265534100011
                    ],
                    [
                        -4.947255011999886,
                        55.167914130000085
                    ],
                    [
                        -4.882394985999895,
                        55.21857330900015
                    ],
                    [
                        -4.87755286399991,
                        55.220892645000035
                    ],
                    [
                        -4.867258266999897,
                        55.222479559000064
                    ],
                    [
                        -4.865305141999926,
                        55.22565338700012
                    ],
                    [
                        -4.865589972999885,
                        55.23631419500005
                    ],
                    [
                        -4.864816860999952,
                        55.241359768000066
                    ],
                    [
                        -4.841949022999927,
                        55.27509186400006
                    ],
                    [
                        -4.838002081999946,
                        55.284002997000115
                    ],
                    [
                        -4.837228969999927,
                        55.29385000200013
                    ],
                    [
                        -4.839751756999959,
                        55.315578518
                    ],
                    [
                        -4.838002081999946,
                        55.325018622000115
                    ],
                    [
                        -4.832508917999917,
                        55.331122137000094
                    ],
                    [
                        -4.799224412999934,
                        55.35472239800005
                    ],
                    [
                        -4.781076626999919,
                        55.36351146000011
                    ],
                    [
                        -4.773101365999878,
                        55.369370835000055
                    ],
                    [
                        -4.767160610999952,
                        55.379055080000015
                    ],
                    [
                        -4.75877844999988,
                        55.40228913000014
                    ],
                    [
                        -4.749256964999887,
                        55.41376373900009
                    ],
                    [
                        -4.717030402999853,
                        55.430487372000115
                    ],
                    [
                        -4.650502081999946,
                        55.44847239800005
                    ],
                    [
                        -4.626332160999937,
                        55.468410549000126
                    ],
                    [
                        -4.61778723899991,
                        55.495591539000046
                    ],
                    [
                        -4.626779751999948,
                        55.51972077000006
                    ],
                    [
                        -4.650786912999877,
                        55.53705475500014
                    ],
                    [
                        -4.687163865999878,
                        55.54413483300003
                    ],
                    [
                        -4.66624915299991,
                        55.56220123900012
                    ],
                    [
                        -4.66649329299986,
                        55.56329987200017
                    ],
                    [
                        -4.671131964999887,
                        55.58441803600009
                    ],
                    [
                        -4.69273841099988,
                        55.60521067900008
                    ],
                    [
                        -4.721994594999899,
                        55.61920807500003
                    ],
                    [
                        -4.776519334999875,
                        55.632879950000174
                    ],
                    [
                        -4.808745897999927,
                        55.63361237200009
                    ],
                    [
                        -4.818104620999861,
                        55.63719310100008
                    ],
                    [
                        -4.810617641999897,
                        55.64655182500012
                    ],
                    [
                        -4.810617641999897,
                        55.65399811400009
                    ],
                    [
                        -4.8685603509999,
                        55.67560455900018
                    ],
                    [
                        -4.900990363999881,
                        55.69232819200003
                    ],
                    [
                        -4.920521613999966,
                        55.70856354400014
                    ],
                    [
                        -4.87633216099988,
                        55.73525625200007
                    ],
                    [
                        -4.861073370999918,
                        55.75674062700001
                    ],
                    [
                        -4.865305141999926,
                        55.790472723
                    ],
                    [
                        -4.871734178999958,
                        55.80243561400012
                    ],
                    [
                        -4.877797003999945,
                        55.81102122600005
                    ],
                    [
                        -4.882720506999903,
                        55.82135651200015
                    ],
                    [
                        -4.888661261999943,
                        55.864528713000155
                    ],
                    [
                        -4.888824022999899,
                        55.865301825000174
                    ],
                    [
                        -4.892648891999897,
                        55.892889716000084
                    ],
                    [
                        -4.886463995999861,
                        55.919663804000024
                    ],
                    [
                        -4.872141079999949,
                        55.937201239000146
                    ],
                    [
                        -4.849354620999918,
                        55.948228257
                    ],
                    [
                        -4.818104620999861,
                        55.955633856000176
                    ],
                    [
                        -4.79938717399989,
                        55.957912502000156
                    ],
                    [
                        -4.78579667899993,
                        55.95697663000006
                    ],
                    [
                        -4.722320115999935,
                        55.940090236000074
                    ],
                    [
                        -4.705433722999942,
                        55.93846263200005
                    ],
                    [
                        -4.693959113999881,
                        55.941351630000085
                    ],
                    [
                        -4.672515428999873,
                        55.93378327000012
                    ],
                    [
                        -4.505970831999889,
                        55.91909414300012
                    ],
                    [
                        -4.481678839999916,
                        55.92829010600009
                    ],
                    [
                        -4.590606248999904,
                        55.94151439000014
                    ],
                    [
                        -4.630034959999875,
                        55.94627513200014
                    ],
                    [
                        -4.673451300999943,
                        55.96185944200012
                    ],
                    [
                        -4.724517381999931,
                        55.998358466000084
                    ],
                    [
                        -4.765044725999928,
                        56.00706614800008
                    ],
                    [
                        -4.783436652999882,
                        56.01764557500003
                    ],
                    [
                        -4.799224412999934,
                        56.030951239000146
                    ],
                    [
                        -4.810617641999897,
                        56.04376862200017
                    ],
                    [
                        -4.823719855999855,
                        56.073675848000065
                    ],
                    [
                        -4.824330206999917,
                        56.078517971000124
                    ],
                    [
                        -4.839182094999899,
                        56.08063385600015
                    ],
                    [
                        -4.84430904899989,
                        56.072984117000104
                    ],
                    [
                        -4.843739386999914,
                        56.062974351000136
                    ],
                    [
                        -4.837554490999878,
                        56.05060455900009
                    ],
                    [
                        -4.790150519999941,
                        56.010199286000145
                    ],
                    [
                        -4.778920050999915,
                        55.99209219000012
                    ],
                    [
                        -4.78530839799987,
                        55.98403554900001
                    ],
                    [
                        -4.803618943999908,
                        55.982082424000154
                    ],
                    [
                        -4.828033006999874,
                        55.982326565
                    ],
                    [
                        -4.851551886999914,
                        55.988023179
                    ],
                    [
                        -4.862294074999852,
                        56.002142645000035
                    ],
                    [
                        -4.865386522999927,
                        56.02020905200014
                    ],
                    [
                        -4.866851365999878,
                        56.05833567900011
                    ],
                    [
                        -4.865386522999927,
                        56.06671784100017
                    ],
                    [
                        -4.82632402299987,
                        56.12421295800006
                    ],
                    [
                        -4.755441860999952,
                        56.18838125200001
                    ],
                    [
                        -4.750884568999908,
                        56.202704169000086
                    ],
                    [
                        -4.755523240999935,
                        56.2065290390001
                    ],
                    [
                        -4.766265428999873,
                        56.20209381700015
                    ],
                    [
                        -4.779937303999901,
                        56.19147370000009
                    ],
                    [
                        -4.837269660999937,
                        56.124416408000016
                    ],
                    [
                        -4.851673956999889,
                        56.11261627800015
                    ],
                    [
                        -4.873117641999926,
                        56.112005927
                    ],
                    [
                        -4.88060462099989,
                        56.119859117000075
                    ],
                    [
                        -4.878977016999954,
                        56.15053945500007
                    ],
                    [
                        -4.883168097999914,
                        56.16400788000006
                    ],
                    [
                        -4.893544074999909,
                        56.17426178600009
                    ],
                    [
                        -4.907093878999973,
                        56.17706940300003
                    ],
                    [
                        -4.920521613999966,
                        56.16791413000006
                    ],
                    [
                        -4.909087693999908,
                        56.149969794000114
                    ],
                    [
                        -4.895253058999913,
                        56.114732164000074
                    ],
                    [
                        -4.886219855999912,
                        56.08038971600011
                    ],
                    [
                        -4.889149542999917,
                        56.06484609600001
                    ],
                    [
                        -4.901926235999952,
                        56.06191640800007
                    ],
                    [
                        -4.904367641999897,
                        56.054348049000126
                    ],
                    [
                        -4.900054490999906,
                        56.03412506700003
                    ],
                    [
                        -4.900054490999906,
                        56.01459381700012
                    ],
                    [
                        -4.898019985999952,
                        56.00116608300014
                    ],
                    [
                        -4.892648891999897,
                        55.98973216399999
                    ],
                    [
                        -4.906402147999927,
                        55.985744533000016
                    ],
                    [
                        -4.921457485999952,
                        55.988674221000124
                    ],
                    [
                        -4.937489386999943,
                        55.99384186400012
                    ],
                    [
                        -4.954701300999943,
                        55.99656810100011
                    ],
                    [
                        -4.954701300999943,
                        55.98973216399999
                    ],
                    [
                        -4.942005988999881,
                        55.98631419499999
                    ],
                    [
                        -4.928863084999932,
                        55.981146552000084
                    ],
                    [
                        -4.91844641799986,
                        55.973334052000084
                    ],
                    [
                        -4.913685675999943,
                        55.96185944200012
                    ],
                    [
                        -4.9173884759999,
                        55.94745514500006
                    ],
                    [
                        -4.927235480999912,
                        55.93768952000012
                    ],
                    [
                        -4.938710089999859,
                        55.92963288
                    ],
                    [
                        -4.947255011999886,
                        55.92084381700012
                    ],
                    [
                        -4.952137824999852,
                        55.90912506700015
                    ],
                    [
                        -4.954986131999874,
                        55.899359442
                    ],
                    [
                        -4.95921790299991,
                        55.890122789000046
                    ],
                    [
                        -4.968983527999853,
                        55.87986888200011
                    ],
                    [
                        -4.983143683999884,
                        55.87103913000006
                    ],
                    [
                        -4.994740363999881,
                        55.86981842700014
                    ],
                    [
                        -5.02635657499988,
                        55.87372467700014
                    ],
                    [
                        -5.042469855999855,
                        55.88475169499999
                    ],
                    [
                        -5.056752081999917,
                        55.91030508000007
                    ],
                    [
                        -5.066802537999905,
                        55.938788153000175
                    ],
                    [
                        -5.070790167999888,
                        55.95868561400006
                    ],
                    [
                        -5.110829230999912,
                        55.988959052000055
                    ],
                    [
                        -5.116037563999925,
                        56.00169505400011
                    ],
                    [
                        -5.123605923999946,
                        56.009914455000015
                    ],
                    [
                        -5.139637824999852,
                        56.00275299700017
                    ],
                    [
                        -5.123768683999913,
                        55.98631419499999
                    ],
                    [
                        -5.085682745999947,
                        55.93187083500008
                    ],
                    [
                        -5.078236456999946,
                        55.910956122000115
                    ],
                    [
                        -5.086537238999938,
                        55.901271877000156
                    ],
                    [
                        -5.105620897999927,
                        55.904974677
                    ],
                    [
                        -5.171538865999878,
                        55.93553294500013
                    ],
                    [
                        -5.180653449999852,
                        55.94843170800014
                    ],
                    [
                        -5.180653449999852,
                        55.96930573100012
                    ],
                    [
                        -5.1908259759999,
                        55.961737372000144
                    ],
                    [
                        -5.199615037999877,
                        55.94855377800012
                    ],
                    [
                        -5.205718553999873,
                        55.93451569200006
                    ],
                    [
                        -5.207915818999936,
                        55.924627997000144
                    ],
                    [
                        -5.212635870999861,
                        55.917141018000095
                    ],
                    [
                        -5.242054816999939,
                        55.892889716000084
                    ],
                    [
                        -5.21812903599988,
                        55.86469147300012
                    ],
                    [
                        -5.208159959999904,
                        55.8492699240001
                    ],
                    [
                        -5.201079881999931,
                        55.831488348
                    ],
                    [
                        -5.219390428999901,
                        55.831447658000016
                    ],
                    [
                        -5.263172980999855,
                        55.84577057500009
                    ],
                    [
                        -5.296701626999891,
                        55.852687893
                    ],
                    [
                        -5.307118292999888,
                        55.86041901200012
                    ],
                    [
                        -5.313384568999936,
                        55.88544342700011
                    ],
                    [
                        -5.31932532499988,
                        55.88910553600009
                    ],
                    [
                        -5.327219204999949,
                        55.891546942
                    ],
                    [
                        -5.334828253999888,
                        55.89667389500012
                    ],
                    [
                        -5.338286912999877,
                        55.90375397300009
                    ],
                    [
                        -5.339100714999887,
                        55.91303131700012
                    ],
                    [
                        -5.338286912999877,
                        55.931382554
                    ],
                    [
                        -5.336089647999899,
                        55.939195054
                    ],
                    [
                        -5.326161261999886,
                        55.95026276200012
                    ],
                    [
                        -5.32396399599989,
                        55.95868561400006
                    ],
                    [
                        -5.325754360999895,
                        55.967230536
                    ],
                    [
                        -5.338286912999877,
                        55.99656810100011
                    ],
                    [
                        -5.313628709999875,
                        56.01235586100013
                    ],
                    [
                        -5.204497850999928,
                        56.11945221600017
                    ],
                    [
                        -5.146473761999886,
                        56.13312409100011
                    ],
                    [
                        -5.104603644999941,
                        56.15151601800015
                    ],
                    [
                        -5.094960089999887,
                        56.157375393000095
                    ],
                    [
                        -5.059437628999916,
                        56.203111070000105
                    ],
                    [
                        -5.043446417999917,
                        56.216294664000046
                    ],
                    [
                        -5.013295050999886,
                        56.22760651200004
                    ],
                    [
                        -4.974964972999942,
                        56.237005927000055
                    ],
                    [
                        -4.94009355399993,
                        56.25141022300012
                    ],
                    [
                        -4.920521613999966,
                        56.277777411000116
                    ],
                    [
                        -4.938099738999881,
                        56.2709821640001
                    ],
                    [
                        -4.975087042999917,
                        56.24799225500011
                    ],
                    [
                        -4.991932745999918,
                        56.24298737200017
                    ],
                    [
                        -5.032704230999912,
                        56.241156317
                    ],
                    [
                        -5.049549933999913,
                        56.23704661700005
                    ],
                    [
                        -5.064564581999917,
                        56.22931549700006
                    ],
                    [
                        -5.075428839999916,
                        56.216986395
                    ],
                    [
                        -5.099436001999948,
                        56.18195221600011
                    ],
                    [
                        -5.108876105999883,
                        56.17470937700007
                    ],
                    [
                        -5.228138800999886,
                        56.12921784100011
                    ],
                    [
                        -5.310943162999905,
                        56.058050848000065
                    ],
                    [
                        -5.319935675999915,
                        56.05491771
                    ],
                    [
                        -5.338042772999927,
                        56.0541039080001
                    ],
                    [
                        -5.345692511999857,
                        56.05121491100006
                    ],
                    [
                        -5.345285610999952,
                        56.046128648000135
                    ],
                    [
                        -5.35020911399991,
                        56.03192780200011
                    ],
                    [
                        -5.356353318999908,
                        56.01976146000014
                    ],
                    [
                        -5.359364386999914,
                        56.020738023000106
                    ],
                    [
                        -5.368641730999855,
                        56.009426174000126
                    ],
                    [
                        -5.390288865999935,
                        56.00568268400009
                    ],
                    [
                        -5.415028449999909,
                        56.00690338700012
                    ],
                    [
                        -5.433827277999882,
                        56.010199286000145
                    ],
                    [
                        -5.434559699999909,
                        56.01496002800015
                    ],
                    [
                        -5.434722459999875,
                        56.02362702000015
                    ],
                    [
                        -5.437123175999915,
                        56.030015367000075
                    ],
                    [
                        -5.444406704999921,
                        56.02757396000011
                    ],
                    [
                        -5.447906053999901,
                        56.022284247000144
                    ],
                    [
                        -5.448231574999852,
                        56.016058661
                    ],
                    [
                        -5.447621222999885,
                        56.009426174000126
                    ],
                    [
                        -5.454986131999874,
                        55.955633856000176
                    ],
                    [
                        -5.45140540299991,
                        55.953436591000084
                    ],
                    [
                        -5.436431443999879,
                        55.94896067900011
                    ],
                    [
                        -5.430775519999884,
                        55.948187567
                    ],
                    [
                        -5.420806443999879,
                        55.92829010600009
                    ],
                    [
                        -5.416737433999913,
                        55.916815497000144
                    ],
                    [
                        -5.416005011999886,
                        55.90741608300014
                    ],
                    [
                        -5.412505662999905,
                        55.89960358300014
                    ],
                    [
                        -5.400380011999914,
                        55.892889716000084
                    ],
                    [
                        -5.400380011999914,
                        55.88670482000013
                    ],
                    [
                        -5.407215949999937,
                        55.88670482000013
                    ],
                    [
                        -5.407215949999937,
                        55.87986888200011
                    ],
                    [
                        -5.40294348899991,
                        55.87872955900018
                    ],
                    [
                        -5.39289303299995,
                        55.87372467700014
                    ],
                    [
                        -5.397694464999916,
                        55.871568101000136
                    ],
                    [
                        -5.402495897999927,
                        55.868353583000086
                    ],
                    [
                        -5.407215949999937,
                        55.86627838700015
                    ],
                    [
                        -5.351796027999853,
                        55.832424221000096
                    ],
                    [
                        -5.326771613999938,
                        55.808579820000105
                    ],
                    [
                        -5.317779100999928,
                        55.78302643400012
                    ],
                    [
                        -5.324574347999942,
                        55.76923248900012
                    ],
                    [
                        -5.33853105399993,
                        55.76264069200015
                    ],
                    [
                        -5.37242591099988,
                        55.756415106000176
                    ],
                    [
                        -5.387562628999888,
                        55.750311591000084
                    ],
                    [
                        -5.433461066999939,
                        55.72101471600017
                    ],
                    [
                        -5.444569464999887,
                        55.711615302000055
                    ],
                    [
                        -5.453968878999916,
                        55.7005882830001
                    ],
                    [
                        -5.461171027999853,
                        55.688137111000074
                    ],
                    [
                        -5.463612433999884,
                        55.679836330000015
                    ],
                    [
                        -5.46617591099988,
                        55.66217682500009
                    ],
                    [
                        -5.468617316999911,
                        55.65399811400009
                    ],
                    [
                        -5.490101691999939,
                        55.644598700000145
                    ],
                    [
                        -5.488270636999886,
                        55.640326239000146
                    ],
                    [
                        -5.484283006999903,
                        55.63572825700011
                    ],
                    [
                        -5.482289191999939,
                        55.632879950000174
                    ],
                    [
                        -5.483876105999883,
                        55.610052802000055
                    ],
                    [
                        -5.474761522999899,
                        55.60394928600006
                    ],
                    [
                        -5.46263587099989,
                        55.60203685100011
                    ],
                    [
                        -5.454986131999874,
                        55.59194570500013
                    ],
                    [
                        -5.46108964799987,
                        55.578802802
                    ],
                    [
                        -5.476918097999885,
                        55.574896552
                    ],
                    [
                        -5.48875891799986,
                        55.56907786700013
                    ],
                    [
                        -5.482289191999939,
                        55.55027903899999
                    ],
                    [
                        -5.503529425999943,
                        55.526516018000095
                    ],
                    [
                        -5.509632941999911,
                        55.51679108300014
                    ],
                    [
                        -5.510609503999888,
                        55.51146067900008
                    ],
                    [
                        -5.509632941999911,
                        55.492580471000124
                    ],
                    [
                        -5.510975714999916,
                        55.48444245000003
                    ],
                    [
                        -5.514271613999938,
                        55.483872789000046
                    ],
                    [
                        -5.518788214999916,
                        55.485174872000144
                    ],
                    [
                        -5.523264126999976,
                        55.48265208500014
                    ],
                    [
                        -5.54552161399991,
                        55.44916413
                    ],
                    [
                        -5.561512824999909,
                        55.43553294500005
                    ],
                    [
                        -5.584624803999873,
                        55.42743561400012
                    ],
                    [
                        -5.584624803999873,
                        55.42121002800015
                    ],
                    [
                        -5.572132941999968,
                        55.422308661
                    ],
                    [
                        -5.562082485999895,
                        55.41986725500006
                    ],
                    [
                        -5.553089972999885,
                        55.41453685100002
                    ],
                    [
                        -5.543690558999856,
                        55.406927802000055
                    ],
                    [
                        -5.527455206999889,
                        55.384344794000086
                    ],
                    [
                        -5.526682094999984,
                        55.38027578300013
                    ],
                    [
                        -5.514963344999899,
                        55.37368398600016
                    ],
                    [
                        -5.525054490999963,
                        55.358628648000135
                    ],
                    [
                        -5.557972785999937,
                        55.331854559000035
                    ],
                    [
                        -5.574370897999927,
                        55.32200755400011
                    ],
                    [
                        -5.594105597999885,
                        55.31313711100016
                    ],
                    [
                        -5.616281704999949,
                        55.306586005000085
                    ],
                    [
                        -5.639963344999956,
                        55.303900458
                    ],
                    [
                        -5.649525519999941,
                        55.30516185100011
                    ],
                    [
                        -5.669097459999932,
                        55.310858466000084
                    ],
                    [
                        -5.680897589999887,
                        55.31134674700017
                    ],
                    [
                        -5.691395636999857,
                        55.308579820000105
                    ],
                    [
                        -5.714019334999932,
                        55.29913971600011
                    ],
                    [
                        -5.725575324999852,
                        55.29706452000009
                    ],
                    [
                        -5.75454667899993,
                        55.29734935100011
                    ],
                    [
                        -5.772287563999925,
                        55.30125560100011
                    ],
                    [
                        -5.782866990999906,
                        55.31354401200015
                    ],
                    [
                        -5.794667120999861,
                        55.35639069200006
                    ],
                    [
                        -5.796701626999891,
                        55.37909577
                    ],
                    [
                        -5.791411912999905,
                        55.39862702000009
                    ],
                    [
                        -5.76341712099989,
                        55.412054755000085
                    ],
                    [
                        -5.725412563999896,
                        55.437567450000174
                    ],
                    [
                        -5.715646938999953,
                        55.447902736000074
                    ],
                    [
                        -5.715240037999934,
                        55.468898830000015
                    ],
                    [
                        -5.7201228509999,
                        55.491522528000175
                    ],
                    [
                        -5.721913214999887,
                        55.513861395000035
                    ],
                    [
                        -5.707753058999884,
                        55.543361721000096
                    ],
                    [
                        -5.712066209999875,
                        55.550726630000085
                    ],
                    [
                        -5.718861456999917,
                        55.557318427000055
                    ],
                    [
                        -5.721831834999904,
                        55.56460195500007
                    ],
                    [
                        -5.71812903599988,
                        55.575751044000086
                    ],
                    [
                        -5.694488084999932,
                        55.60553620000009
                    ],
                    [
                        -5.673329230999855,
                        55.64061107
                    ],
                    [
                        -5.669422980999855,
                        55.660711981000034
                    ],
                    [
                        -5.680897589999887,
                        55.67446523600013
                    ],
                    [
                        -5.626535610999952,
                        55.70062897300009
                    ],
                    [
                        -5.619455532999922,
                        55.71198151200015
                    ],
                    [
                        -5.613189256999874,
                        55.725775458000086
                    ],
                    [
                        -5.598296678999901,
                        55.74298737200009
                    ],
                    [
                        -5.581206834999875,
                        55.75763580900009
                    ],
                    [
                        -5.567860480999855,
                        55.76386139500006
                    ],
                    [
                        -5.539173956999889,
                        55.77094147300012
                    ],
                    [
                        -5.504628058999884,
                        55.78937409100014
                    ],
                    [
                        -5.473500128999888,
                        55.81565989800008
                    ],
                    [
                        -5.454986131999874,
                        55.84577057500009
                    ],
                    [
                        -5.55174719999988,
                        55.78363678600009
                    ],
                    [
                        -5.587473110999923,
                        55.767157294000086
                    ],
                    [
                        -5.599436001999948,
                        55.764227606000176
                    ],
                    [
                        -5.612619594999899,
                        55.76386139500006
                    ],
                    [
                        -5.605783657999979,
                        55.776841539000046
                    ],
                    [
                        -5.633208787999934,
                        55.78278229400017
                    ],
                    [
                        -5.64671790299991,
                        55.787502346000096
                    ],
                    [
                        -5.660389777999853,
                        55.79734935100011
                    ],
                    [
                        -5.667958136999886,
                        55.81102122600005
                    ],
                    [
                        -5.669016079999949,
                        55.83783600500014
                    ],
                    [
                        -5.674061652999882,
                        55.84577057500009
                    ],
                    [
                        -5.631988084999904,
                        55.88178131700006
                    ],
                    [
                        -5.625599738999881,
                        55.889837958
                    ],
                    [
                        -5.619374152999939,
                        55.90314362200003
                    ],
                    [
                        -5.604359503999916,
                        55.913641669000086
                    ],
                    [
                        -5.586293097999885,
                        55.923325914000046
                    ],
                    [
                        -5.571034308999913,
                        55.93451569200006
                    ],
                    [
                        -5.593495245999918,
                        55.92796458500008
                    ],
                    [
                        -5.62759355399993,
                        55.90534088700012
                    ],
                    [
                        -5.653797980999855,
                        55.897609768
                    ],
                    [
                        -5.671701626999919,
                        55.88690827000009
                    ],
                    [
                        -5.680897589999887,
                        55.88670482000013
                    ],
                    [
                        -5.68797766799986,
                        55.89337799700009
                    ],
                    [
                        -5.694162563999925,
                        55.904974677
                    ],
                    [
                        -5.696400519999912,
                        55.91596100500006
                    ],
                    [
                        -5.586822068999879,
                        56.01203034100003
                    ],
                    [
                        -5.578439907999893,
                        56.02448151200004
                    ],
                    [
                        -5.58267167899993,
                        56.02895742400001
                    ],
                    [
                        -5.576649542999917,
                        56.032538153000175
                    ],
                    [
                        -5.571034308999913,
                        56.037543036000116
                    ],
                    [
                        -5.583892381999931,
                        56.04071686400009
                    ],
                    [
                        -5.596424933999856,
                        56.036810614
                    ],
                    [
                        -5.609852667999917,
                        56.030015367000075
                    ],
                    [
                        -5.625599738999881,
                        56.02448151200004
                    ],
                    [
                        -5.631703253999888,
                        56.00592682500003
                    ],
                    [
                        -5.674875454999892,
                        55.97772858300006
                    ],
                    [
                        -5.674061652999882,
                        55.955633856000176
                    ],
                    [
                        -5.690785285999908,
                        55.93764883000013
                    ],
                    [
                        -5.694488084999932,
                        55.93451569200006
                    ],
                    [
                        -5.704497850999928,
                        55.93675364800005
                    ],
                    [
                        -5.707508917999917,
                        55.942572333
                    ],
                    [
                        -5.705881313999896,
                        55.94965241100006
                    ],
                    [
                        -5.701975063999925,
                        55.955633856000176
                    ],
                    [
                        -5.696441209999904,
                        55.98216380400014
                    ],
                    [
                        -5.659901495999861,
                        56.023260809000035
                    ],
                    [
                        -5.584624803999873,
                        56.08466217700011
                    ],
                    [
                        -5.581532355999883,
                        56.08828359600007
                    ],
                    [
                        -5.580067511999914,
                        56.092922268
                    ],
                    [
                        -5.577463344999927,
                        56.09699127800015
                    ],
                    [
                        -5.571034308999913,
                        56.0990257830001
                    ],
                    [
                        -5.564605272999899,
                        56.09808991100003
                    ],
                    [
                        -5.556548631999874,
                        56.09589264500012
                    ],
                    [
                        -5.550689256999931,
                        56.09349192900008
                    ],
                    [
                        -5.550526495999861,
                        56.09218984600007
                    ],
                    [
                        -5.54784094999988,
                        56.09003327000006
                    ],
                    [
                        -5.539458787999934,
                        56.086859442
                    ],
                    [
                        -5.531605597999942,
                        56.086330471000124
                    ],
                    [
                        -5.530100063999896,
                        56.09218984600007
                    ],
                    [
                        -5.534738735999923,
                        56.09853750200001
                    ],
                    [
                        -5.541411912999877,
                        56.102484442
                    ],
                    [
                        -5.547515428999873,
                        56.10488515800013
                    ],
                    [
                        -5.550526495999861,
                        56.10643138200008
                    ],
                    [
                        -5.556955532999979,
                        56.12221914300012
                    ],
                    [
                        -5.558705206999974,
                        56.13080475500014
                    ],
                    [
                        -5.554676886999914,
                        56.13739655200011
                    ],
                    [
                        -5.522531704999949,
                        56.16575755400005
                    ],
                    [
                        -5.515736456999917,
                        56.17470937700007
                    ],
                    [
                        -5.509632941999911,
                        56.18838125200001
                    ],
                    [
                        -5.539906378999916,
                        56.18341705900015
                    ],
                    [
                        -5.562489386999886,
                        56.16791413000006
                    ],
                    [
                        -5.58267167899993,
                        56.15058014500006
                    ],
                    [
                        -5.605783657999979,
                        56.13996002800012
                    ],
                    [
                        -5.599354620999861,
                        56.160589911000145
                    ],
                    [
                        -5.58641516799986,
                        56.18113841399999
                    ],
                    [
                        -5.569406704999892,
                        56.20026276200015
                    ],
                    [
                        -5.550526495999861,
                        56.216294664000046
                    ],
                    [
                        -5.559437628999888,
                        56.22955963700018
                    ],
                    [
                        -5.547230597999914,
                        56.24017975500011
                    ],
                    [
                        -5.52562415299991,
                        56.247259833
                    ],
                    [
                        -5.506174282999922,
                        56.24982330900009
                    ],
                    [
                        -5.494740363999881,
                        56.253119208000115
                    ],
                    [
                        -5.492827928999901,
                        56.26040273600013
                    ],
                    [
                        -5.499256964999916,
                        56.267645575000174
                    ],
                    [
                        -5.512684699999909,
                        56.270941473
                    ],
                    [
                        -5.554269985999895,
                        56.263495184000035
                    ],
                    [
                        -5.56314042899993,
                        56.26032135600015
                    ],
                    [
                        -5.572621222999942,
                        56.25385163000006
                    ],
                    [
                        -5.582346157999893,
                        56.24876536700005
                    ],
                    [
                        -5.592152472999914,
                        56.24982330900009
                    ],
                    [
                        -5.598052537999877,
                        56.258612372000144
                    ],
                    [
                        -5.595570441999939,
                        56.269924221000124
                    ],
                    [
                        -5.584624803999873,
                        56.29075755400011
                    ],
                    [
                        -5.57258053299995,
                        56.32807038
                    ],
                    [
                        -5.562733527999853,
                        56.34105052299999
                    ],
                    [
                        -5.543690558999856,
                        56.35285065300015
                    ],
                    [
                        -5.525217251999919,
                        56.346625067
                    ],
                    [
                        -5.49819902299987,
                        56.348334052
                    ],
                    [
                        -5.448109503999945,
                        56.35968659100017
                    ],
                    [
                        -5.448109503999945,
                        56.36591217700011
                    ],
                    [
                        -5.472157355999912,
                        56.36493561400006
                    ],
                    [
                        -5.51537024599989,
                        56.35683828300013
                    ],
                    [
                        -5.537505662999877,
                        56.35968659100017
                    ],
                    [
                        -5.517323370999861,
                        56.392767645000035
                    ],
                    [
                        -5.509632941999911,
                        56.400620835
                    ],
                    [
                        -5.498931443999908,
                        56.404730536000145
                    ],
                    [
                        -5.48607337099989,
                        56.408107815000065
                    ],
                    [
                        -5.478016730999855,
                        56.412909247000144
                    ],
                    [
                        -5.482289191999939,
                        56.42112864800005
                    ],
                    [
                        -5.457020636999914,
                        56.44086334800009
                    ],
                    [
                        -5.423573370999861,
                        56.450018622000144
                    ],
                    [
                        -5.322865363999938,
                        56.45498281500004
                    ],
                    [
                        -5.242054816999939,
                        56.44220612200017
                    ],
                    [
                        -5.208729620999861,
                        56.446844794000086
                    ],
                    [
                        -5.181874152999882,
                        56.45966217700014
                    ],
                    [
                        -5.118519660999937,
                        56.50775788
                    ],
                    [
                        -5.085682745999947,
                        56.54877350500011
                    ],
                    [
                        -5.064564581999917,
                        56.56513092700011
                    ],
                    [
                        -5.086537238999938,
                        56.558498440000065
                    ],
                    [
                        -5.099598761999914,
                        56.543646552
                    ],
                    [
                        -5.110585089999887,
                        56.52586497600011
                    ],
                    [
                        -5.125965949999909,
                        56.510484117000075
                    ],
                    [
                        -5.166900193999936,
                        56.49005768400009
                    ],
                    [
                        -5.190174933999856,
                        56.46865469000015
                    ],
                    [
                        -5.201079881999931,
                        56.46210358300006
                    ],
                    [
                        -5.243763800999943,
                        56.45921458500014
                    ],
                    [
                        -5.350168423999918,
                        56.47296784100014
                    ],
                    [
                        -5.378081834999875,
                        56.45897044499999
                    ],
                    [
                        -5.394276495999918,
                        56.465480861000074
                    ],
                    [
                        -5.410552537999962,
                        56.50364817900005
                    ],
                    [
                        -5.424143032999922,
                        56.500067450000174
                    ],
                    [
                        -5.435943162999877,
                        56.49115631700015
                    ],
                    [
                        -5.454986131999874,
                        56.469549872000144
                    ],
                    [
                        -5.468617316999911,
                        56.475734768
                    ],
                    [
                        -5.43333899599989,
                        56.52362702000012
                    ],
                    [
                        -5.427642381999903,
                        56.53782786700005
                    ],
                    [
                        -5.403797980999912,
                        56.524603583
                    ],
                    [
                        -5.379261847999885,
                        56.519191799000154
                    ],
                    [
                        -5.356678839999916,
                        56.51968008000013
                    ],
                    [
                        -5.338286912999877,
                        56.524155992000104
                    ],
                    [
                        -5.290516730999855,
                        56.54466380400005
                    ],
                    [
                        -5.265044725999928,
                        56.551214911000145
                    ],
                    [
                        -5.25255286399991,
                        56.55634186400006
                    ],
                    [
                        -5.242054816999939,
                        56.56513092700011
                    ],
                    [
                        -5.275624152999882,
                        56.5536156270001
                    ],
                    [
                        -5.286773240999906,
                        56.55206940300015
                    ],
                    [
                        -5.30679277299987,
                        56.552801825000174
                    ],
                    [
                        -5.310943162999905,
                        56.55206940300015
                    ],
                    [
                        -5.327707485999923,
                        56.54466380400005
                    ],
                    [
                        -5.338693813999896,
                        56.54230377800012
                    ],
                    [
                        -5.36034094999988,
                        56.53221263200014
                    ],
                    [
                        -5.37242591099988,
                        56.53034088700018
                    ],
                    [
                        -5.384348110999923,
                        56.533433335000055
                    ],
                    [
                        -5.400502081999889,
                        56.543646552
                    ],
                    [
                        -5.413929816999882,
                        56.54466380400005
                    ],
                    [
                        -5.410878058999884,
                        56.55206940300015
                    ],
                    [
                        -5.407297329999921,
                        56.5553246110001
                    ],
                    [
                        -5.402699347999885,
                        56.557440497000115
                    ],
                    [
                        -5.39663652299987,
                        56.561712958000115
                    ],
                    [
                        -5.392689581999889,
                        56.56647370000012
                    ],
                    [
                        -5.389312303999873,
                        56.574896552000055
                    ],
                    [
                        -5.386626756999903,
                        56.578802802000055
                    ],
                    [
                        -5.36070716099988,
                        56.606268622000115
                    ],
                    [
                        -5.351918097999914,
                        56.612941799000154
                    ],
                    [
                        -5.310861782999922,
                        56.63361237200017
                    ],
                    [
                        -5.304514126999891,
                        56.64016347900015
                    ],
                    [
                        -5.298451300999886,
                        56.64642975500011
                    ],
                    [
                        -5.317779100999928,
                        56.653876044000086
                    ],
                    [
                        -5.317779100999928,
                        56.66132233300006
                    ],
                    [
                        -5.30907141799986,
                        56.66425202000009
                    ],
                    [
                        -5.286773240999906,
                        56.674994208
                    ],
                    [
                        -5.261463995999861,
                        56.67332591399999
                    ],
                    [
                        -5.248199022999927,
                        56.67405833500011
                    ],
                    [
                        -5.218902147999899,
                        56.68866608300014
                    ],
                    [
                        -5.196441209999904,
                        56.68866608300014
                    ],
                    [
                        -5.15330969999988,
                        56.68121979400014
                    ],
                    [
                        -5.132639126999976,
                        56.684515692
                    ],
                    [
                        -5.093373175999943,
                        56.699042059000035
                    ],
                    [
                        -5.012806769999912,
                        56.70929596600014
                    ],
                    [
                        -4.995594855999883,
                        56.71596914300004
                    ],
                    [
                        -5.160552537999905,
                        56.69586823100015
                    ],
                    [
                        -5.238270636999914,
                        56.71662018400015
                    ],
                    [
                        -5.238514777999882,
                        56.72256094000009
                    ],
                    [
                        -5.228423631999903,
                        56.73647695500007
                    ],
                    [
                        -5.222075975999871,
                        56.74152252800009
                    ],
                    [
                        -5.154652472999885,
                        56.78229401200015
                    ],
                    [
                        -5.136382615999906,
                        56.79877350500006
                    ],
                    [
                        -5.119740363999938,
                        56.81842682500012
                    ],
                    [
                        -5.179066535999937,
                        56.78925202000006
                    ],
                    [
                        -5.187489386999886,
                        56.78115469000012
                    ],
                    [
                        -5.191761847999885,
                        56.774644273000135
                    ],
                    [
                        -5.202259894999941,
                        56.76911041900003
                    ],
                    [
                        -5.214670376999891,
                        56.76520416900003
                    ],
                    [
                        -5.224964972999914,
                        56.763739325000145
                    ],
                    [
                        -5.233469204999921,
                        56.76068756700006
                    ],
                    [
                        -5.237049933999913,
                        56.753363348000065
                    ],
                    [
                        -5.238921678999873,
                        56.74445221600003
                    ],
                    [
                        -5.242054816999939,
                        56.73647695500007
                    ],
                    [
                        -5.255238410999908,
                        56.72158437700001
                    ],
                    [
                        -5.268462693999936,
                        56.71308014500009
                    ],
                    [
                        -5.283355272999899,
                        56.709377346000124
                    ],
                    [
                        -5.30101477799991,
                        56.70856354400003
                    ],
                    [
                        -5.309803839999887,
                        56.706488348
                    ],
                    [
                        -5.319162563999896,
                        56.697495835
                    ],
                    [
                        -5.34015865799995,
                        56.69306061400012
                    ],
                    [
                        -5.348378058999856,
                        56.68720123900009
                    ],
                    [
                        -5.359364386999914,
                        56.674994208
                    ],
                    [
                        -5.38805091099988,
                        56.65521881700009
                    ],
                    [
                        -5.403879360999895,
                        56.649237372000144
                    ],
                    [
                        -5.424224412999905,
                        56.64704010600015
                    ],
                    [
                        -5.434396938999925,
                        56.64288971600003
                    ],
                    [
                        -5.448719855999912,
                        56.62457916900017
                    ],
                    [
                        -5.458119269999941,
                        56.62034739800005
                    ],
                    [
                        -5.470773891999926,
                        56.61839427300002
                    ],
                    [
                        -5.482574022999899,
                        56.61326732
                    ],
                    [
                        -5.493316209999904,
                        56.60639069200009
                    ],
                    [
                        -5.53343665299991,
                        56.572902736000046
                    ],
                    [
                        -5.599598761999914,
                        56.52903880400008
                    ],
                    [
                        -5.667388475999928,
                        56.498480536000145
                    ],
                    [
                        -5.674061652999882,
                        56.496893622000115
                    ],
                    [
                        -5.6830134759999,
                        56.50063711100013
                    ],
                    [
                        -5.694691535999908,
                        56.51386139500009
                    ],
                    [
                        -5.705393032999922,
                        56.51666901200004
                    ],
                    [
                        -5.721791144999912,
                        56.51850006700012
                    ],
                    [
                        -5.74083411399991,
                        56.52326080900012
                    ],
                    [
                        -5.758208787999905,
                        56.529974677000055
                    ],
                    [
                        -5.770253058999913,
                        56.53782786700005
                    ],
                    [
                        -5.755197719999899,
                        56.55402252800009
                    ],
                    [
                        -5.751047329999949,
                        56.561997789000046
                    ],
                    [
                        -5.749134894999912,
                        56.571966864000146
                    ],
                    [
                        -5.756662563999953,
                        56.571966864000146
                    ],
                    [
                        -5.793853318999936,
                        56.543280341000084
                    ],
                    [
                        -5.854359503999945,
                        56.55048248900012
                    ],
                    [
                        -6.003407355999855,
                        56.61871979400003
                    ],
                    [
                        -6.009836391999954,
                        56.626532294000114
                    ],
                    [
                        -6.008168097999942,
                        56.64227936400006
                    ],
                    [
                        -5.998036261999886,
                        56.6498884140001
                    ],
                    [
                        -5.983509894999941,
                        56.652818101000136
                    ],
                    [
                        -5.933583136999914,
                        56.654730536
                    ],
                    [
                        -5.89903723899991,
                        56.650824286
                    ],
                    [
                        -5.866851365999963,
                        56.64154694200015
                    ],
                    [
                        -5.839182094999956,
                        56.626532294000114
                    ],
                    [
                        -5.831695115999906,
                        56.633978583
                    ],
                    [
                        -5.85562089799987,
                        56.64472077
                    ],
                    [
                        -5.865589972999942,
                        56.65143463700015
                    ],
                    [
                        -5.865834113999881,
                        56.66132233300006
                    ],
                    [
                        -5.759999152999882,
                        56.70075104400006
                    ],
                    [
                        -5.735503709999961,
                        56.702337958000086
                    ],
                    [
                        -5.672596808999913,
                        56.684149481000176
                    ],
                    [
                        -5.647368943999936,
                        56.68121979400014
                    ],
                    [
                        -5.594146287999877,
                        56.68333567900008
                    ],
                    [
                        -5.564808722999942,
                        56.68764883000007
                    ],
                    [
                        -5.543690558999856,
                        56.69546133000016
                    ],
                    [
                        -5.636545376999948,
                        56.68866608300014
                    ],
                    [
                        -5.658558722999942,
                        56.69074127800015
                    ],
                    [
                        -5.708851691999911,
                        56.70856354400003
                    ],
                    [
                        -5.749989386999914,
                        56.714544989000146
                    ],
                    [
                        -5.859038865999878,
                        56.68121979400014
                    ],
                    [
                        -5.896799282999922,
                        56.675116278000175
                    ],
                    [
                        -5.907378709999875,
                        56.674994208
                    ],
                    [
                        -5.920318162999877,
                        56.67796458500011
                    ],
                    [
                        -5.942738410999908,
                        56.68675364799999
                    ],
                    [
                        -5.955555792999917,
                        56.68866608300014
                    ],
                    [
                        -6.024891730999883,
                        56.68569570500004
                    ],
                    [
                        -6.098622199999909,
                        56.69696686400012
                    ],
                    [
                        -6.123850063999896,
                        56.69562409100003
                    ],
                    [
                        -6.143544074999909,
                        56.68488190300012
                    ],
                    [
                        -6.161732550999915,
                        56.67820872600005
                    ],
                    [
                        -6.186594204999949,
                        56.68109772300009
                    ],
                    [
                        -6.20962480399993,
                        56.688462632
                    ],
                    [
                        -6.222767706999889,
                        56.69546133000016
                    ],
                    [
                        -6.228179490999935,
                        56.701076565000065
                    ],
                    [
                        -6.232533331999917,
                        56.7065290390001
                    ],
                    [
                        -6.23534094999988,
                        56.712388414000046
                    ],
                    [
                        -6.236398891999926,
                        56.71906159100011
                    ],
                    [
                        -6.234486456999889,
                        56.72858307500009
                    ],
                    [
                        -6.229562954999921,
                        56.729071356000176
                    ],
                    [
                        -6.222767706999889,
                        56.72719961100002
                    ],
                    [
                        -6.215321417999917,
                        56.72964101800015
                    ],
                    [
                        -6.193470831999974,
                        56.74852122599999
                    ],
                    [
                        -6.180531378999945,
                        56.754624742000075
                    ],
                    [
                        -6.026966925999915,
                        56.763739325000145
                    ],
                    [
                        -6.014881964999887,
                        56.767035223
                    ],
                    [
                        -5.9798884759999,
                        56.781561591000056
                    ],
                    [
                        -5.965199347999885,
                        56.78485748900009
                    ],
                    [
                        -5.948231574999909,
                        56.78253815300012
                    ],
                    [
                        -5.937977667999888,
                        56.776922919000114
                    ],
                    [
                        -5.928537563999896,
                        56.769964911000116
                    ],
                    [
                        -5.913644985999923,
                        56.763739325000145
                    ],
                    [
                        -5.860910610999923,
                        56.750799872000144
                    ],
                    [
                        -5.85220292899993,
                        56.74697500200013
                    ],
                    [
                        -5.853342251999891,
                        56.75787995000012
                    ],
                    [
                        -5.858143683999884,
                        56.76845937700007
                    ],
                    [
                        -5.868397589999887,
                        56.77586497600005
                    ],
                    [
                        -5.886341925999943,
                        56.77741120000009
                    ],
                    [
                        -5.886341925999943,
                        56.78485748900009
                    ],
                    [
                        -5.874012824999909,
                        56.785711981000176
                    ],
                    [
                        -5.861805792999917,
                        56.78510163000011
                    ],
                    [
                        -5.850087042999945,
                        56.782456773000135
                    ],
                    [
                        -5.839182094999956,
                        56.77741120000009
                    ],
                    [
                        -5.824615037999934,
                        56.78758372600005
                    ],
                    [
                        -5.802072719999956,
                        56.791815497000144
                    ],
                    [
                        -5.776600714999859,
                        56.787990627000156
                    ],
                    [
                        -5.756662563999953,
                        56.78485748900009
                    ],
                    [
                        -5.831695115999906,
                        56.80536530200011
                    ],
                    [
                        -5.850819464999859,
                        56.81801992400001
                    ],
                    [
                        -5.851185675999886,
                        56.82892487200017
                    ],
                    [
                        -5.837473110999952,
                        56.836615302
                    ],
                    [
                        -5.73387610599994,
                        56.844794012000094
                    ],
                    [
                        -5.692738410999937,
                        56.854925848000065
                    ],
                    [
                        -5.660389777999853,
                        56.872992255000085
                    ],
                    [
                        -5.67357337099989,
                        56.87653229400014
                    ],
                    [
                        -5.69200598899991,
                        56.87531159100014
                    ],
                    [
                        -5.708485480999912,
                        56.87055084800015
                    ],
                    [
                        -5.724354620999918,
                        56.85382721600011
                    ],
                    [
                        -5.744496222999885,
                        56.85415273600013
                    ],
                    [
                        -5.783924933999856,
                        56.85993073100009
                    ],
                    [
                        -5.775380011999914,
                        56.86994049700009
                    ],
                    [
                        -5.735503709999961,
                        56.894110419000086
                    ],
                    [
                        -5.780588344999927,
                        56.89874909100003
                    ],
                    [
                        -5.874867316999911,
                        56.887152411
                    ],
                    [
                        -5.921131964999887,
                        56.894110419000086
                    ],
                    [
                        -5.887562628999945,
                        56.89911530200014
                    ],
                    [
                        -5.85220292899993,
                        56.90033600500006
                    ],
                    [
                        -5.85220292899993,
                        56.907700914000046
                    ],
                    [
                        -5.859852667999888,
                        56.910549221000096
                    ],
                    [
                        -5.880116339999887,
                        56.92202383000013
                    ],
                    [
                        -5.862538214999859,
                        56.933742580000015
                    ],
                    [
                        -5.851429816999911,
                        56.954250393000066
                    ],
                    [
                        -5.842518683999884,
                        56.97744375200001
                    ],
                    [
                        -5.831695115999906,
                        56.997137762000065
                    ],
                    [
                        -5.814116990999878,
                        57.009507554
                    ],
                    [
                        -5.786040818999965,
                        57.02057526200012
                    ],
                    [
                        -5.75523841099988,
                        57.02655670800014
                    ],
                    [
                        -5.729318813999896,
                        57.02383047100007
                    ],
                    [
                        -5.719878709999875,
                        57.017564195000105
                    ],
                    [
                        -5.699940558999884,
                        56.99811432500003
                    ],
                    [
                        -5.688303188999981,
                        56.989691473000065
                    ],
                    [
                        -5.674061652999882,
                        56.983587958000086
                    ],
                    [
                        -5.658558722999942,
                        56.97947825700014
                    ],
                    [
                        -5.625599738999881,
                        56.97662995000009
                    ],
                    [
                        -5.571278449999852,
                        56.98383209800012
                    ],
                    [
                        -5.523264126999976,
                        56.997137762000065
                    ],
                    [
                        -5.523264126999976,
                        57.003973700000145
                    ],
                    [
                        -5.5404353509999,
                        57.001166083000115
                    ],
                    [
                        -5.556507941999882,
                        56.99607982
                    ],
                    [
                        -5.571278449999852,
                        56.99331289300012
                    ],
                    [
                        -5.584624803999873,
                        56.997137762000065
                    ],
                    [
                        -5.607085740999878,
                        56.98851146000014
                    ],
                    [
                        -5.628814256999931,
                        56.99188873900006
                    ],
                    [
                        -5.649891730999883,
                        56.99966054900007
                    ],
                    [
                        -5.670318162999934,
                        57.003973700000145
                    ],
                    [
                        -5.685536261999914,
                        57.00958893400015
                    ],
                    [
                        -5.681711391999897,
                        57.022406317
                    ],
                    [
                        -5.680043097999885,
                        57.036200262000094
                    ],
                    [
                        -5.701975063999925,
                        57.04490794499999
                    ],
                    [
                        -5.710926886999857,
                        57.04417552300008
                    ],
                    [
                        -5.731353318999908,
                        57.03900788000006
                    ],
                    [
                        -5.74233964799987,
                        57.03807200700008
                    ],
                    [
                        -5.75255286399991,
                        57.040594794000086
                    ],
                    [
                        -5.769602016999954,
                        57.04975006700006
                    ],
                    [
                        -5.780181443999908,
                        57.05174388200011
                    ],
                    [
                        -5.791127081999889,
                        57.059271552000084
                    ],
                    [
                        -5.776193813999925,
                        57.075832424000126
                    ],
                    [
                        -5.734486456999889,
                        57.10541413
                    ],
                    [
                        -5.722645636999914,
                        57.116441148000135
                    ],
                    [
                        -5.715646938999953,
                        57.120062567
                    ],
                    [
                        -5.703277147999899,
                        57.120754299000126
                    ],
                    [
                        -5.673695441999939,
                        57.11904531500012
                    ],
                    [
                        -5.663482225999928,
                        57.123724677000055
                    ],
                    [
                        -5.645863410999908,
                        57.129461981000034
                    ],
                    [
                        -5.62083899599989,
                        57.12409088700015
                    ],
                    [
                        -5.578439907999893,
                        57.10639069200006
                    ],
                    [
                        -5.558257615999878,
                        57.100409247000144
                    ],
                    [
                        -5.459828253999945,
                        57.09979889500009
                    ],
                    [
                        -5.454986131999874,
                        57.10297272300015
                    ],
                    [
                        -5.447417772999927,
                        57.11017487200009
                    ],
                    [
                        -5.430775519999884,
                        57.11074453300016
                    ],
                    [
                        -5.414296027999882,
                        57.10822174700006
                    ],
                    [
                        -5.407215949999937,
                        57.10639069200006
                    ],
                    [
                        -5.400380011999914,
                        57.10639069200006
                    ],
                    [
                        -5.400380011999914,
                        57.113836981000034
                    ],
                    [
                        -5.420033331999946,
                        57.12067291900014
                    ],
                    [
                        -5.503895636999857,
                        57.112697658000016
                    ],
                    [
                        -5.527414516999897,
                        57.10748932500012
                    ],
                    [
                        -5.540638800999943,
                        57.10639069200006
                    ],
                    [
                        -5.547718878999916,
                        57.110093492000104
                    ],
                    [
                        -5.573312954999892,
                        57.127875067
                    ],
                    [
                        -5.584624803999873,
                        57.13369375200013
                    ],
                    [
                        -5.668446417999888,
                        57.147650458000115
                    ],
                    [
                        -5.680897589999887,
                        57.157904364000146
                    ],
                    [
                        -5.673817511999914,
                        57.174994208
                    ],
                    [
                        -5.638824022999927,
                        57.20237864800005
                    ],
                    [
                        -5.625599738999881,
                        57.21625397300012
                    ],
                    [
                        -5.644154425999886,
                        57.233221747000115
                    ],
                    [
                        -5.620106574999852,
                        57.25214264500012
                    ],
                    [
                        -5.578236456999946,
                        57.26675039300015
                    ],
                    [
                        -5.543690558999856,
                        57.2709007830001
                    ],
                    [
                        -5.511586066999882,
                        57.25763580900015
                    ],
                    [
                        -5.480091925999943,
                        57.23700592700014
                    ],
                    [
                        -5.44627844999988,
                        57.22304922100007
                    ],
                    [
                        -5.407215949999937,
                        57.229925848000065
                    ],
                    [
                        -5.407215949999937,
                        57.23672109600001
                    ],
                    [
                        -5.448841925999886,
                        57.242092190000065
                    ],
                    [
                        -5.458119269999941,
                        57.24664948100009
                    ],
                    [
                        -5.472889777999939,
                        57.26121653900013
                    ],
                    [
                        -5.482899542999917,
                        57.26801178600006
                    ],
                    [
                        -5.492502407999893,
                        57.2709007830001
                    ],
                    [
                        -5.501942511999914,
                        57.27765534100003
                    ],
                    [
                        -5.48892167899993,
                        57.292425848
                    ],
                    [
                        -5.464833136999886,
                        57.30662669500013
                    ],
                    [
                        -5.440663214999887,
                        57.31183502800012
                    ],
                    [
                        -5.440663214999887,
                        57.319281317
                    ],
                    [
                        -5.46499589799987,
                        57.32493724199999
                    ],
                    [
                        -5.481760219999956,
                        57.316717841000084
                    ],
                    [
                        -5.49429277299987,
                        57.30442942900014
                    ],
                    [
                        -5.506174282999922,
                        57.298163153000175
                    ],
                    [
                        -5.523426886999943,
                        57.29515208500008
                    ],
                    [
                        -5.558461066999911,
                        57.28143952000015
                    ],
                    [
                        -5.578439907999893,
                        57.27765534100003
                    ],
                    [
                        -5.599436001999948,
                        57.27924225500006
                    ],
                    [
                        -5.637440558999856,
                        57.28900788
                    ],
                    [
                        -5.656971808999913,
                        57.29132721600017
                    ],
                    [
                        -5.700062628999945,
                        57.28359609600015
                    ],
                    [
                        -5.720570441999911,
                        57.28449127800015
                    ],
                    [
                        -5.729318813999896,
                        57.298163153000175
                    ],
                    [
                        -5.72524980399993,
                        57.30158112200009
                    ],
                    [
                        -5.701975063999925,
                        57.33234284100017
                    ],
                    [
                        -5.693755662999905,
                        57.337103583000086
                    ],
                    [
                        -5.671986456999946,
                        57.344427802000084
                    ],
                    [
                        -5.663482225999928,
                        57.349676825000145
                    ],
                    [
                        -5.654367641999926,
                        57.35394928600009
                    ],
                    [
                        -5.647206183999884,
                        57.34992096600011
                    ],
                    [
                        -5.641468878999916,
                        57.343207098000065
                    ],
                    [
                        -5.636545376999948,
                        57.33978913000006
                    ],
                    [
                        -5.613189256999874,
                        57.34198639500015
                    ],
                    [
                        -5.501291469999956,
                        57.37099844000012
                    ],
                    [
                        -5.456654425999886,
                        57.39354075700011
                    ],
                    [
                        -5.448109503999945,
                        57.42169830900009
                    ],
                    [
                        -5.465077277999853,
                        57.42389557500009
                    ],
                    [
                        -5.492054816999882,
                        57.40875885600009
                    ],
                    [
                        -5.537505662999877,
                        57.373928127000156
                    ],
                    [
                        -5.56346594999988,
                        57.36310455900015
                    ],
                    [
                        -5.586659308999913,
                        57.36505768400009
                    ],
                    [
                        -5.610910610999895,
                        57.37140534100003
                    ],
                    [
                        -5.639963344999956,
                        57.373928127000156
                    ],
                    [
                        -5.615589972999914,
                        57.38247304900007
                    ],
                    [
                        -5.607085740999878,
                        57.389105536000145
                    ],
                    [
                        -5.609201626999891,
                        57.39777252800003
                    ],
                    [
                        -5.617746548999918,
                        57.410101630000085
                    ],
                    [
                        -5.619618292999888,
                        57.416693427000055
                    ],
                    [
                        -5.624012824999852,
                        57.41640859600004
                    ],
                    [
                        -5.639963344999956,
                        57.40802643400015
                    ],
                    [
                        -5.707183397999899,
                        57.359767971000124
                    ],
                    [
                        -5.732411261999886,
                        57.35276927300005
                    ],
                    [
                        -5.7845759759999,
                        57.34857819200012
                    ],
                    [
                        -5.807769334999932,
                        57.35492584800015
                    ],
                    [
                        -5.824859178999873,
                        57.39435455900012
                    ],
                    [
                        -5.822255011999886,
                        57.39350006700012
                    ],
                    [
                        -5.817372199999909,
                        57.39520905200011
                    ],
                    [
                        -5.812855597999942,
                        57.398098049000154
                    ],
                    [
                        -5.81118730399993,
                        57.40119049700006
                    ],
                    [
                        -5.813872850999928,
                        57.40452708500008
                    ],
                    [
                        -5.823109503999888,
                        57.412339585000055
                    ],
                    [
                        -5.824859178999873,
                        57.41547272300012
                    ],
                    [
                        -5.825266079999892,
                        57.428900458000115
                    ],
                    [
                        -5.821888800999943,
                        57.43553294499999
                    ],
                    [
                        -5.81118730399993,
                        57.442206122000144
                    ],
                    [
                        -5.821522589999859,
                        57.4454613300001
                    ],
                    [
                        -5.85252844999988,
                        57.45038483300006
                    ],
                    [
                        -5.859038865999878,
                        57.45270416900003
                    ],
                    [
                        -5.861195441999882,
                        57.463446356000034
                    ],
                    [
                        -5.8705134759999,
                        57.48078034100003
                    ],
                    [
                        -5.872670050999915,
                        57.49371979400003
                    ],
                    [
                        -5.86969967399989,
                        57.50088125200007
                    ],
                    [
                        -5.856068488999938,
                        57.517808335000055
                    ],
                    [
                        -5.85220292899993,
                        57.524115302
                    ],
                    [
                        -5.851063605999912,
                        57.53839752800009
                    ],
                    [
                        -5.851470506999903,
                        57.55109284100014
                    ],
                    [
                        -5.847075975999928,
                        57.55890534100014
                    ],
                    [
                        -5.831695115999906,
                        57.558823960000055
                    ],
                    [
                        -5.833851691999882,
                        57.56338125200001
                    ],
                    [
                        -5.837025519999941,
                        57.57477448100015
                    ],
                    [
                        -5.839182094999956,
                        57.579291083000115
                    ],
                    [
                        -5.821400519999884,
                        57.583197333000115
                    ],
                    [
                        -5.803618943999879,
                        57.58136627800006
                    ],
                    [
                        -5.786366339999887,
                        57.57518138200008
                    ],
                    [
                        -5.732574022999927,
                        57.54523346600011
                    ],
                    [
                        -5.715646938999953,
                        57.53839752800009
                    ],
                    [
                        -5.707020636999857,
                        57.542303778000175
                    ],
                    [
                        -5.689768032999922,
                        57.52789948100012
                    ],
                    [
                        -5.680897589999887,
                        57.524115302
                    ],
                    [
                        -5.65086829299986,
                        57.51264069200015
                    ],
                    [
                        -5.639963344999956,
                        57.51105377800012
                    ],
                    [
                        -5.645334438999925,
                        57.51666901200012
                    ],
                    [
                        -5.648304816999939,
                        57.52187734600001
                    ],
                    [
                        -5.650542772999927,
                        57.526800848000065
                    ],
                    [
                        -5.653553839999916,
                        57.53156159100017
                    ],
                    [
                        -5.581532355999883,
                        57.53839752800009
                    ],
                    [
                        -5.540272589999944,
                        57.53449127800009
                    ],
                    [
                        -5.516835089999859,
                        57.534857489
                    ],
                    [
                        -5.512684699999909,
                        57.54148997600008
                    ],
                    [
                        -5.54312089799987,
                        57.555365302000055
                    ],
                    [
                        -5.588368292999917,
                        57.56102122600005
                    ],
                    [
                        -5.632720506999931,
                        57.55768463700012
                    ],
                    [
                        -5.660389777999853,
                        57.544582424000154
                    ],
                    [
                        -5.694488084999932,
                        57.56631094000012
                    ],
                    [
                        -5.689198370999861,
                        57.56940338700012
                    ],
                    [
                        -5.680897589999887,
                        57.579291083000115
                    ],
                    [
                        -5.71422278599988,
                        57.58270905200014
                    ],
                    [
                        -5.731678839999859,
                        57.598293361000046
                    ],
                    [
                        -5.744252081999917,
                        57.61798737200017
                    ],
                    [
                        -5.762806769999941,
                        57.63397858300006
                    ],
                    [
                        -5.778146938999896,
                        57.637437242000075
                    ],
                    [
                        -5.793609178999901,
                        57.638576565
                    ],
                    [
                        -5.805897589999859,
                        57.642645575000145
                    ],
                    [
                        -5.81118730399993,
                        57.65509674700017
                    ],
                    [
                        -5.805409308999884,
                        57.667181708000086
                    ],
                    [
                        -5.785023566999882,
                        57.679510809000035
                    ],
                    [
                        -5.790760870999861,
                        57.68854401200004
                    ],
                    [
                        -5.790760870999861,
                        57.69599030200011
                    ],
                    [
                        -5.753570115999878,
                        57.70746491100006
                    ],
                    [
                        -5.734486456999889,
                        57.70795319200015
                    ],
                    [
                        -5.704986131999903,
                        57.690130927000084
                    ],
                    [
                        -5.692494269999912,
                        57.691066799000154
                    ],
                    [
                        -5.68195553299995,
                        57.69871653899999
                    ],
                    [
                        -5.674061652999882,
                        57.709662177000055
                    ],
                    [
                        -5.685170050999915,
                        57.71141185100005
                    ],
                    [
                        -5.692290818999936,
                        57.71649811400006
                    ],
                    [
                        -5.698841925999943,
                        57.723089911000145
                    ],
                    [
                        -5.708851691999911,
                        57.729559637000065
                    ],
                    [
                        -5.720529751999919,
                        57.73338450700005
                    ],
                    [
                        -5.803822394999941,
                        57.743841864000146
                    ],
                    [
                        -5.806955532999922,
                        57.75462474200005
                    ],
                    [
                        -5.802072719999956,
                        57.78058502800003
                    ],
                    [
                        -5.803822394999941,
                        57.79222239800005
                    ],
                    [
                        -5.801258917999945,
                        57.800441799000126
                    ],
                    [
                        -5.809925910999937,
                        57.82062409100017
                    ],
                    [
                        -5.81118730399993,
                        57.83319733300006
                    ],
                    [
                        -5.807362433999913,
                        57.84393952000009
                    ],
                    [
                        -5.799956834999932,
                        57.85370514500012
                    ],
                    [
                        -5.78954016799986,
                        57.862005927
                    ],
                    [
                        -5.776519334999875,
                        57.86790599200013
                    ],
                    [
                        -5.751128709999932,
                        57.87335846600017
                    ],
                    [
                        -5.724761522999927,
                        57.873521226000136
                    ],
                    [
                        -5.6986384759999,
                        57.86908600500006
                    ],
                    [
                        -5.674061652999882,
                        57.86050039300015
                    ],
                    [
                        -5.677886522999899,
                        57.84690989799999
                    ],
                    [
                        -5.674794074999909,
                        57.82709381700009
                    ],
                    [
                        -5.667958136999886,
                        57.806952216000056
                    ],
                    [
                        -5.660389777999853,
                        57.79222239800005
                    ],
                    [
                        -5.650542772999927,
                        57.78180573100015
                    ],
                    [
                        -5.637359178999873,
                        57.774237372000115
                    ],
                    [
                        -5.622141079999892,
                        57.77020905200014
                    ],
                    [
                        -5.605783657999979,
                        57.77049388200008
                    ],
                    [
                        -5.613270636999857,
                        57.77521393400018
                    ],
                    [
                        -5.617909308999884,
                        57.78021881700012
                    ],
                    [
                        -5.625599738999881,
                        57.79222239800005
                    ],
                    [
                        -5.61302649599989,
                        57.792629299000154
                    ],
                    [
                        -5.603260870999861,
                        57.79108307500012
                    ],
                    [
                        -5.584624803999873,
                        57.784816799000154
                    ],
                    [
                        -5.591175910999937,
                        57.80442942900011
                    ],
                    [
                        -5.587025519999912,
                        57.81858958500014
                    ],
                    [
                        -5.585275844999927,
                        57.83144765800007
                    ],
                    [
                        -5.598988410999937,
                        57.846869208
                    ],
                    [
                        -5.637562628999916,
                        57.866359768000095
                    ],
                    [
                        -5.650380011999857,
                        57.877752997000144
                    ],
                    [
                        -5.639963344999956,
                        57.88784414300004
                    ],
                    [
                        -5.647694464999887,
                        57.88898346600014
                    ],
                    [
                        -5.650786912999877,
                        57.89081452000012
                    ],
                    [
                        -5.653553839999916,
                        57.89468008000013
                    ],
                    [
                        -5.643950975999928,
                        57.90420156500012
                    ],
                    [
                        -5.620757615999906,
                        57.92088450700008
                    ],
                    [
                        -5.612619594999899,
                        57.92877838700004
                    ],
                    [
                        -5.598215298999918,
                        57.919012762000094
                    ],
                    [
                        -5.587310350999957,
                        57.915106512000094
                    ],
                    [
                        -5.550526495999861,
                        57.915106512000094
                    ],
                    [
                        -5.556263800999943,
                        57.88776276200004
                    ],
                    [
                        -5.52790279899989,
                        57.86758047100001
                    ],
                    [
                        -5.487049933999856,
                        57.85586172100004
                    ],
                    [
                        -5.454986131999874,
                        57.85370514500012
                    ],
                    [
                        -5.461171027999853,
                        57.86050039300015
                    ],
                    [
                        -5.449045376999948,
                        57.86912669500005
                    ],
                    [
                        -5.437123175999915,
                        57.89765045800014
                    ],
                    [
                        -5.427642381999903,
                        57.90827057500009
                    ],
                    [
                        -5.414906378999945,
                        57.90814850500011
                    ],
                    [
                        -5.369496222999942,
                        57.89752838700018
                    ],
                    [
                        -5.35562089799987,
                        57.891262111000046
                    ],
                    [
                        -5.32750403599988,
                        57.873277085
                    ],
                    [
                        -5.293853318999879,
                        57.86204661699999
                    ],
                    [
                        -5.221587693999879,
                        57.846869208
                    ],
                    [
                        -5.236317511999886,
                        57.86078522300009
                    ],
                    [
                        -5.247547980999883,
                        57.867010809000035
                    ],
                    [
                        -5.276763475999928,
                        57.874172268000095
                    ],
                    [
                        -5.320668097999942,
                        57.898504950000145
                    ],
                    [
                        -5.384917772999899,
                        57.91412995000003
                    ],
                    [
                        -5.39289303299995,
                        57.91819896
                    ],
                    [
                        -5.394398566999882,
                        57.923895575000174
                    ],
                    [
                        -5.396839972999914,
                        57.926214911000145
                    ],
                    [
                        -5.397328253999916,
                        57.928900458000115
                    ],
                    [
                        -5.39289303299995,
                        57.935614325000145
                    ],
                    [
                        -5.386545376999919,
                        57.93642812700007
                    ],
                    [
                        -5.378977016999954,
                        57.93183014500012
                    ],
                    [
                        -5.37173417899993,
                        57.931057033000016
                    ],
                    [
                        -5.366200324999909,
                        57.943060614000146
                    ],
                    [
                        -5.347157355999912,
                        57.93671295800003
                    ],
                    [
                        -5.319162563999896,
                        57.914740302
                    ],
                    [
                        -5.29735266799986,
                        57.90827057500009
                    ],
                    [
                        -5.286000128999888,
                        57.908758856000176
                    ],
                    [
                        -5.26695716099988,
                        57.91400788000006
                    ],
                    [
                        -5.256255662999877,
                        57.915106512000094
                    ],
                    [
                        -5.243641730999883,
                        57.912909247000115
                    ],
                    [
                        -5.221018032999922,
                        57.90403880400008
                    ],
                    [
                        -5.211293097999885,
                        57.90208567900011
                    ],
                    [
                        -5.198801235999952,
                        57.89777252800012
                    ],
                    [
                        -5.162261522999927,
                        57.878485419000086
                    ],
                    [
                        -5.119984503999888,
                        57.867987372000115
                    ],
                    [
                        -5.091135219999956,
                        57.84031810100011
                    ],
                    [
                        -5.070790167999888,
                        57.83319733300006
                    ],
                    [
                        -5.07648678299995,
                        57.83852773600013
                    ],
                    [
                        -5.083404100999871,
                        57.84756094000012
                    ],
                    [
                        -5.089344855999883,
                        57.85724518400009
                    ],
                    [
                        -5.091867641999897,
                        57.86420319200009
                    ],
                    [
                        -5.097157355999883,
                        57.870917059000035
                    ],
                    [
                        -5.197255011999914,
                        57.917873440000065
                    ],
                    [
                        -5.221587693999879,
                        57.921332098000065
                    ],
                    [
                        -5.221587693999879,
                        57.92877838700004
                    ],
                    [
                        -5.201893683999856,
                        57.92865631700006
                    ],
                    [
                        -5.193959113999881,
                        57.93598053600009
                    ],
                    [
                        -5.19554602799991,
                        57.94765859600007
                    ],
                    [
                        -5.204497850999928,
                        57.960150458000086
                    ],
                    [
                        -5.216704881999903,
                        57.96588776200015
                    ],
                    [
                        -5.248890753999973,
                        57.96938711100013
                    ],
                    [
                        -5.263172980999855,
                        57.97654857
                    ],
                    [
                        -5.292632615999935,
                        57.98061758000013
                    ],
                    [
                        -5.333892381999903,
                        58.00853099199999
                    ],
                    [
                        -5.359364386999914,
                        58.011379299000126
                    ],
                    [
                        -5.357167120999918,
                        58.01406484600001
                    ],
                    [
                        -5.356678839999916,
                        58.014878648000106
                    ],
                    [
                        -5.351918097999914,
                        58.01752350500003
                    ],
                    [
                        -5.366932745999861,
                        58.02887604400017
                    ],
                    [
                        -5.386586066999911,
                        58.03253815300003
                    ],
                    [
                        -5.427642381999903,
                        58.03119538000014
                    ],
                    [
                        -5.424305792999888,
                        58.035549221000124
                    ],
                    [
                        -5.422352667999917,
                        58.03896719000015
                    ],
                    [
                        -5.419585740999878,
                        58.04205963700004
                    ],
                    [
                        -5.413929816999882,
                        58.045477606000034
                    ],
                    [
                        -5.413929816999882,
                        58.05231354400014
                    ],
                    [
                        -5.447010870999918,
                        58.084377346000124
                    ],
                    [
                        -5.428089972999885,
                        58.097967841000084
                    ],
                    [
                        -5.3900447259999,
                        58.092189846000124
                    ],
                    [
                        -5.366200324999909,
                        58.065985419000086
                    ],
                    [
                        -5.34430904899989,
                        58.07550690300009
                    ],
                    [
                        -5.322865363999938,
                        58.071722723000065
                    ],
                    [
                        -5.300770636999857,
                        58.070135809000035
                    ],
                    [
                        -5.276763475999928,
                        58.08641185100008
                    ],
                    [
                        -5.283070441999939,
                        58.08641185100008
                    ],
                    [
                        -5.278065558999913,
                        58.096258856000176
                    ],
                    [
                        -5.278797980999855,
                        58.105373440000065
                    ],
                    [
                        -5.283558722999942,
                        58.11347077
                    ],
                    [
                        -5.290516730999855,
                        58.120550848000065
                    ],
                    [
                        -5.276763475999928,
                        58.120550848000065
                    ],
                    [
                        -5.276763475999928,
                        58.12738678600009
                    ],
                    [
                        -5.29735266799986,
                        58.134833075000145
                    ],
                    [
                        -5.284087693999908,
                        58.14008209800015
                    ],
                    [
                        -5.255523240999935,
                        58.14378489799999
                    ],
                    [
                        -5.242054816999939,
                        58.14789459800012
                    ],
                    [
                        -5.242054816999939,
                        58.15534088700012
                    ],
                    [
                        -5.275054490999906,
                        58.154527085
                    ],
                    [
                        -5.290842251999948,
                        58.15631745000009
                    ],
                    [
                        -5.304798956999917,
                        58.16209544500005
                    ],
                    [
                        -5.29735266799986,
                        58.168402411
                    ],
                    [
                        -5.304798956999917,
                        58.173325914000046
                    ],
                    [
                        -5.313059048999918,
                        58.17731354400003
                    ],
                    [
                        -5.321888800999886,
                        58.18024323100015
                    ],
                    [
                        -5.331450975999871,
                        58.182033596000124
                    ],
                    [
                        -5.329335089999859,
                        58.18671295800006
                    ],
                    [
                        -5.326161261999886,
                        58.19843170800014
                    ],
                    [
                        -5.32396399599989,
                        58.20311107000005
                    ],
                    [
                        -5.336822068999908,
                        58.20319245000003
                    ],
                    [
                        -5.346791144999912,
                        58.20685455900009
                    ],
                    [
                        -5.39289303299995,
                        58.244696356000034
                    ],
                    [
                        -5.39207923099994,
                        58.26137929900007
                    ],
                    [
                        -5.375884568999879,
                        58.26422760600012
                    ],
                    [
                        -5.352894660999908,
                        58.25893789300012
                    ],
                    [
                        -5.310780402999853,
                        58.243394273000135
                    ],
                    [
                        -5.293853318999879,
                        58.24091217700011
                    ],
                    [
                        -5.276966925999886,
                        58.243394273000135
                    ],
                    [
                        -5.237294074999852,
                        58.257635809000035
                    ],
                    [
                        -5.216053839999859,
                        58.26141998900006
                    ],
                    [
                        -5.194325324999909,
                        58.259995835
                    ],
                    [
                        -5.17320716099988,
                        58.250921942
                    ],
                    [
                        -5.165638800999943,
                        58.258246161
                    ],
                    [
                        -5.152251756999931,
                        58.264837958000086
                    ],
                    [
                        -5.138335740999935,
                        58.269598700000174
                    ],
                    [
                        -5.129383917999917,
                        58.27138906500015
                    ],
                    [
                        -5.111561652999853,
                        58.268255927000084
                    ],
                    [
                        -5.081288214999859,
                        58.25409577000006
                    ],
                    [
                        -5.067697719999899,
                        58.250921942
                    ],
                    [
                        -5.01773027299987,
                        58.25324127800015
                    ],
                    [
                        -5.002430792999917,
                        58.250921942
                    ],
                    [
                        -4.933583136999857,
                        58.223049221000124
                    ],
                    [
                        -4.943430141999954,
                        58.233587958
                    ],
                    [
                        -4.954823370999918,
                        58.23981354400014
                    ],
                    [
                        -4.982004360999923,
                        58.250921942
                    ],
                    [
                        -4.968861456999889,
                        58.25678131700012
                    ],
                    [
                        -4.940337693999879,
                        58.25946686400012
                    ],
                    [
                        -4.926747199999909,
                        58.26459381700012
                    ],
                    [
                        -4.990223761999914,
                        58.27142975500014
                    ],
                    [
                        -5.00991777299987,
                        58.27138906500015
                    ],
                    [
                        -5.018055792999888,
                        58.268622137000094
                    ],
                    [
                        -5.029367641999954,
                        58.25971100500006
                    ],
                    [
                        -5.040028449999909,
                        58.25775788000011
                    ],
                    [
                        -5.050119594999956,
                        58.259914455000015
                    ],
                    [
                        -5.070708787999905,
                        58.26923248900006
                    ],
                    [
                        -5.081654425999886,
                        58.27138906500015
                    ],
                    [
                        -5.096424933999856,
                        58.276760158000016
                    ],
                    [
                        -5.125965949999909,
                        58.3001976580001
                    ],
                    [
                        -5.136219855999855,
                        58.30556875200013
                    ],
                    [
                        -5.137440558999856,
                        58.309271552
                    ],
                    [
                        -5.156361456999889,
                        58.324611721000124
                    ],
                    [
                        -5.160145636999914,
                        58.32660553600009
                    ],
                    [
                        -5.167795376999919,
                        58.33926015800013
                    ],
                    [
                        -5.171009894999884,
                        58.34666575700014
                    ],
                    [
                        -5.17320716099988,
                        58.353949286000145
                    ],
                    [
                        -5.15330969999988,
                        58.353949286000145
                    ],
                    [
                        -5.163400844999956,
                        58.36456940300009
                    ],
                    [
                        -5.162912563999953,
                        58.37449778900002
                    ],
                    [
                        -5.155913865999878,
                        58.38446686400009
                    ],
                    [
                        -5.146473761999886,
                        58.39496491100006
                    ],
                    [
                        -5.145497199999909,
                        58.40005117400007
                    ],
                    [
                        -5.14671790299991,
                        58.40664297100015
                    ],
                    [
                        -5.146839972999885,
                        58.41242096600003
                    ],
                    [
                        -5.14305579299986,
                        58.414780992000075
                    ],
                    [
                        -5.135487433999913,
                        58.413763739
                    ],
                    [
                        -5.122222459999875,
                        58.40957265800007
                    ],
                    [
                        -5.116037563999925,
                        58.40859609600001
                    ],
                    [
                        -5.057972785999937,
                        58.387274481000034
                    ],
                    [
                        -5.016184048999918,
                        58.38812897300012
                    ],
                    [
                        -5.043934699999909,
                        58.40009186400006
                    ],
                    [
                        -5.053374803999901,
                        58.40664297100015
                    ],
                    [
                        -5.05028235599994,
                        58.414780992000075
                    ],
                    [
                        -5.057036912999877,
                        58.42218659100014
                    ],
                    [
                        -5.066151495999861,
                        58.41714101800012
                    ],
                    [
                        -5.076161261999857,
                        58.414496161000145
                    ],
                    [
                        -5.086822068999879,
                        58.41388580900018
                    ],
                    [
                        -5.098052537999877,
                        58.414780992000075
                    ],
                    [
                        -5.098052537999877,
                        58.42218659100014
                    ],
                    [
                        -5.088124152999882,
                        58.42194245000012
                    ],
                    [
                        -5.085031704999892,
                        58.42218659100014
                    ],
                    [
                        -5.085031704999892,
                        58.429022528000175
                    ],
                    [
                        -5.091786261999914,
                        58.42975495000012
                    ],
                    [
                        -5.09601803299995,
                        58.43146393400012
                    ],
                    [
                        -5.105539516999926,
                        58.43650950700014
                    ],
                    [
                        -5.050119594999956,
                        58.45380280200014
                    ],
                    [
                        -5.02554277299987,
                        58.44769928600006
                    ],
                    [
                        -4.995594855999883,
                        58.43650950700014
                    ],
                    [
                        -5.105539516999926,
                        58.48737213700015
                    ],
                    [
                        -5.105620897999927,
                        58.50556061400006
                    ],
                    [
                        -5.10366777299987,
                        58.51422760600015
                    ],
                    [
                        -5.095855272999899,
                        58.518866278000175
                    ],
                    [
                        -5.042795376999948,
                        58.53546784100014
                    ],
                    [
                        -5.027984178999901,
                        58.54409414300015
                    ],
                    [
                        -5.016184048999918,
                        58.559393622000115
                    ],
                    [
                        -5.022938605999855,
                        58.559393622000115
                    ],
                    [
                        -5.015207485999952,
                        58.580064195000105
                    ],
                    [
                        -5.011586066999882,
                        58.60057200700008
                    ],
                    [
                        -5.004953579999921,
                        58.61774323100009
                    ],
                    [
                        -4.988189256999903,
                        58.62832265800016
                    ],
                    [
                        -4.859486456999889,
                        58.61322663000014
                    ],
                    [
                        -4.835926886999857,
                        58.60504791900006
                    ],
                    [
                        -4.818104620999861,
                        58.59292226800015
                    ],
                    [
                        -4.817941860999895,
                        58.58759186400009
                    ],
                    [
                        -4.812814907999893,
                        58.571966864
                    ],
                    [
                        -4.806752081999889,
                        58.558417059000035
                    ],
                    [
                        -4.803822394999884,
                        58.559393622000115
                    ],
                    [
                        -4.804595506999903,
                        58.548325914000074
                    ],
                    [
                        -4.807240363999881,
                        58.539618231000176
                    ],
                    [
                        -4.811634894999941,
                        58.53204987200003
                    ],
                    [
                        -4.818104620999861,
                        58.52464427300005
                    ],
                    [
                        -4.799794074999909,
                        58.532456773000135
                    ],
                    [
                        -4.791818813999953,
                        58.54165273600002
                    ],
                    [
                        -4.789906378999888,
                        58.55174388200008
                    ],
                    [
                        -4.790150519999941,
                        58.56248607
                    ],
                    [
                        -4.785755988999938,
                        58.57705312700001
                    ],
                    [
                        -4.777088995999861,
                        58.58331940300015
                    ],
                    [
                        -4.771595831999917,
                        58.590399481000034
                    ],
                    [
                        -4.776519334999875,
                        58.60716380400005
                    ],
                    [
                        -4.758859829999977,
                        58.5991071640001
                    ],
                    [
                        -4.728016730999912,
                        58.57746002800003
                    ],
                    [
                        -4.711333787999877,
                        58.57306549700006
                    ],
                    [
                        -4.683257615999878,
                        58.56150950700011
                    ],
                    [
                        -4.67039954299986,
                        58.559393622000115
                    ],
                    [
                        -4.660023566999939,
                        58.555853583000115
                    ],
                    [
                        -4.659291144999912,
                        58.54755280200014
                    ],
                    [
                        -4.663238084999904,
                        58.53839752800015
                    ],
                    [
                        -4.667307094999956,
                        58.53204987200003
                    ],
                    [
                        -4.679025844999956,
                        58.52391185100011
                    ],
                    [
                        -4.714466925999943,
                        58.50560130400005
                    ],
                    [
                        -4.732411261999886,
                        58.48045482000008
                    ],
                    [
                        -4.751535610999952,
                        58.46161530200014
                    ],
                    [
                        -4.760975714999887,
                        58.44798411699999
                    ],
                    [
                        -4.742421027999882,
                        58.44953034100003
                    ],
                    [
                        -4.682972785999937,
                        58.48456452000012
                    ],
                    [
                        -4.673451300999943,
                        58.48737213700015
                    ],
                    [
                        -4.666859503999945,
                        58.50291575700008
                    ],
                    [
                        -4.650502081999946,
                        58.50995514500015
                    ],
                    [
                        -4.630441860999895,
                        58.51496002800009
                    ],
                    [
                        -4.612131313999925,
                        58.52464427300005
                    ],
                    [
                        -4.601063605999883,
                        58.54193756700015
                    ],
                    [
                        -4.595122850999871,
                        58.560003973000065
                    ],
                    [
                        -4.584950324999909,
                        58.57416413
                    ],
                    [
                        -4.560536261999886,
                        58.57990143400015
                    ],
                    [
                        -4.517486131999931,
                        58.575506903000175
                    ],
                    [
                        -4.475493943999936,
                        58.56561920800006
                    ],
                    [
                        -4.426503058999884,
                        58.5463727890001
                    ],
                    [
                        -4.426503058999884,
                        58.53888580900015
                    ],
                    [
                        -4.42788652299987,
                        58.53530508000007
                    ],
                    [
                        -4.42870032499988,
                        58.534735419000114
                    ],
                    [
                        -4.426503058999884,
                        58.52464427300005
                    ],
                    [
                        -4.449940558999856,
                        58.510199286
                    ],
                    [
                        -4.464751756999931,
                        58.49201080900009
                    ],
                    [
                        -4.477650519999941,
                        58.471340236000074
                    ],
                    [
                        -4.495350714999859,
                        58.44953034100003
                    ],
                    [
                        -4.478098110999952,
                        58.453558661
                    ],
                    [
                        -4.461171027999882,
                        58.46246979400003
                    ],
                    [
                        -4.433908657999979,
                        58.483628648000135
                    ],
                    [
                        -4.432728644999941,
                        58.48761627800012
                    ],
                    [
                        -4.433990037999962,
                        58.492377020000035
                    ],
                    [
                        -4.434234178999901,
                        58.49628327
                    ],
                    [
                        -4.43016516799986,
                        58.49799225500011
                    ],
                    [
                        -4.425933397999927,
                        58.49909088700015
                    ],
                    [
                        -4.38536536399991,
                        58.52240631700006
                    ],
                    [
                        -4.382394985999895,
                        58.52464427300005
                    ],
                    [
                        -4.343861456999917,
                        58.53888580900015
                    ],
                    [
                        -4.317005988999938,
                        58.54572174700017
                    ],
                    [
                        -4.306996222999942,
                        58.5463727890001
                    ],
                    [
                        -4.287180141999926,
                        58.54291413000011
                    ],
                    [
                        -4.257313605999855,
                        58.527980861000074
                    ],
                    [
                        -4.234730597999885,
                        58.52464427300005
                    ],
                    [
                        -4.248931443999879,
                        58.53204987200003
                    ],
                    [
                        -4.238189256999874,
                        58.53644440300012
                    ],
                    [
                        -4.232248501999948,
                        58.54242584800012
                    ],
                    [
                        -4.227691209999904,
                        58.54816315300009
                    ],
                    [
                        -4.22101803299995,
                        58.551947333000115
                    ],
                    [
                        -4.209706183999856,
                        58.552923895
                    ],
                    [
                        -4.190785285999937,
                        58.54783763200008
                    ],
                    [
                        -4.176991339999916,
                        58.5463727890001
                    ],
                    [
                        -4.168202277999882,
                        58.54938385600012
                    ],
                    [
                        -4.160552537999934,
                        58.562567450000174
                    ],
                    [
                        -4.149322068999936,
                        58.56561920800006
                    ],
                    [
                        -4.111195441999939,
                        58.56561920800006
                    ],
                    [
                        -4.090240037999905,
                        58.562079169000086
                    ],
                    [
                        -4.081450975999928,
                        58.56183502800003
                    ],
                    [
                        -4.070220506999931,
                        58.56561920800006
                    ],
                    [
                        -4.061512824999852,
                        58.57282135600009
                    ],
                    [
                        -4.048817511999886,
                        58.58958567900011
                    ],
                    [
                        -4.039784308999884,
                        58.59292226800015
                    ],
                    [
                        -4.030629035999908,
                        58.594061591000084
                    ],
                    [
                        -4.029042120999861,
                        58.59516022300012
                    ],
                    [
                        -4.02782141799986,
                        58.592718817
                    ],
                    [
                        -4.009266730999855,
                        58.57330963700018
                    ],
                    [
                        -4.003773566999911,
                        58.57062409100011
                    ],
                    [
                        -3.98143469999988,
                        58.57306549700006
                    ],
                    [
                        -3.895741339999916,
                        58.56561920800006
                    ],
                    [
                        -3.810129360999923,
                        58.57306549700006
                    ],
                    [
                        -3.766957160999908,
                        58.58372630400008
                    ],
                    [
                        -3.698353644999912,
                        58.611151434000035
                    ],
                    [
                        -3.65990149599989,
                        58.620835679
                    ],
                    [
                        -3.597564256999931,
                        58.62714264500012
                    ],
                    [
                        -3.565500454999949,
                        58.626613674000154
                    ],
                    [
                        -3.542591925999943,
                        58.620835679
                    ],
                    [
                        -3.552479620999861,
                        58.61546458500011
                    ],
                    [
                        -3.556263800999886,
                        58.613999742000075
                    ],
                    [
                        -3.532053188999896,
                        58.60211823100012
                    ],
                    [
                        -3.504017706999889,
                        58.603338934000035
                    ],
                    [
                        -3.447010870999861,
                        58.613999742000075
                    ],
                    [
                        -3.391468878999916,
                        58.60065338700015
                    ],
                    [
                        -3.364084438999953,
                        58.600816148000106
                    ],
                    [
                        -3.350819464999916,
                        58.620835679
                    ],
                    [
                        -3.367746548999918,
                        58.624701239
                    ],
                    [
                        -3.386057094999956,
                        58.631740627000156
                    ],
                    [
                        -3.402251756999931,
                        58.64203522300009
                    ],
                    [
                        -3.412912563999953,
                        58.65558502800006
                    ],
                    [
                        -3.390044725999957,
                        58.67177969
                    ],
                    [
                        -3.374867316999968,
                        58.677069403000175
                    ],
                    [
                        -3.358265753999916,
                        58.675482489000146
                    ],
                    [
                        -3.354359503999916,
                        58.67015208500008
                    ],
                    [
                        -3.35179602799991,
                        58.66083405200011
                    ],
                    [
                        -3.34788977799991,
                        58.65204498900006
                    ]
                ]
            ],
            [
                [
                    [
                        -2.885365363999938,
                        58.820054429
                    ],
                    [
                        -2.913563605999912,
                        58.810492255
                    ],
                    [
                        -2.919422980999855,
                        58.806463934000035
                    ],
                    [
                        -2.921131964999859,
                        58.7975121110001
                    ],
                    [
                        -2.915760870999918,
                        58.79067617400007
                    ],
                    [
                        -2.913970506999931,
                        58.78485748900012
                    ],
                    [
                        -2.926340298999946,
                        58.77912018400015
                    ],
                    [
                        -2.915394660999908,
                        58.76215241100009
                    ],
                    [
                        -2.911040818999908,
                        58.7486839860001
                    ],
                    [
                        -2.917836066999939,
                        58.73834870000009
                    ],
                    [
                        -2.939930792999917,
                        58.730698960000055
                    ],
                    [
                        -2.987782355999855,
                        58.757391669000086
                    ],
                    [
                        -2.987782355999855,
                        58.76483795800006
                    ],
                    [
                        -2.974354620999861,
                        58.77204010600009
                    ],
                    [
                        -2.984852667999917,
                        58.787095445000105
                    ],
                    [
                        -3.015695766999897,
                        58.8126488300001
                    ],
                    [
                        -3.003895636999914,
                        58.80817291900003
                    ],
                    [
                        -2.991322394999912,
                        58.80662669499999
                    ],
                    [
                        -2.978871222999914,
                        58.80817291900003
                    ],
                    [
                        -2.967193162999905,
                        58.8126488300001
                    ],
                    [
                        -2.967193162999905,
                        58.820054429
                    ],
                    [
                        -3.035552537999877,
                        58.820054429
                    ],
                    [
                        -3.035552537999877,
                        58.82632070500013
                    ],
                    [
                        -2.996978318999908,
                        58.83527252800015
                    ],
                    [
                        -2.952951626999891,
                        58.83999258000007
                    ],
                    [
                        -2.906971808999856,
                        58.838080145
                    ],
                    [
                        -2.883656378999945,
                        58.83218008000007
                    ],
                    [
                        -2.885365363999938,
                        58.820054429
                    ]
                ]
            ],
            [
                [
                    [
                        -3.246083136999857,
                        58.89618561400006
                    ],
                    [
                        -3.193186001999919,
                        58.85358307500003
                    ],
                    [
                        -3.207386847999914,
                        58.84739817900005
                    ],
                    [
                        -3.207386847999914,
                        58.83999258000007
                    ],
                    [
                        -3.195546027999853,
                        58.839422919000086
                    ],
                    [
                        -3.18724524599989,
                        58.836493231000176
                    ],
                    [
                        -3.172718878999945,
                        58.82632070500013
                    ],
                    [
                        -3.172718878999945,
                        58.820054429
                    ],
                    [
                        -3.191802537999934,
                        58.81867096600011
                    ],
                    [
                        -3.21548417899993,
                        58.81427643400012
                    ],
                    [
                        -3.236683722999942,
                        58.805731512000094
                    ],
                    [
                        -3.248402472999914,
                        58.792181708000115
                    ],
                    [
                        -3.218495245999861,
                        58.79328034100017
                    ],
                    [
                        -3.160145636999857,
                        58.80801015800007
                    ],
                    [
                        -3.131703253999945,
                        58.806463934000035
                    ],
                    [
                        -3.156320766999926,
                        58.7867699240001
                    ],
                    [
                        -3.205922003999945,
                        58.77903880400008
                    ],
                    [
                        -3.29930579299986,
                        58.77912018400015
                    ],
                    [
                        -3.304269985999895,
                        58.78384023600016
                    ],
                    [
                        -3.323475714999859,
                        58.80951569200012
                    ],
                    [
                        -3.327788865999963,
                        58.818304755
                    ],
                    [
                        -3.33763587099989,
                        58.82391998900009
                    ],
                    [
                        -3.349191860999895,
                        58.82843659100014
                    ],
                    [
                        -3.358265753999916,
                        58.83315664300015
                    ],
                    [
                        -3.378773566999939,
                        58.85358307500003
                    ],
                    [
                        -3.379872199999909,
                        58.85927969
                    ],
                    [
                        -3.375965949999909,
                        58.86359284100011
                    ],
                    [
                        -3.373524542999888,
                        58.86798737200009
                    ],
                    [
                        -3.378773566999939,
                        58.87409088700018
                    ],
                    [
                        -3.385731574999852,
                        58.87539297100007
                    ],
                    [
                        -3.397613084999904,
                        58.869533596000124
                    ],
                    [
                        -3.402333136999914,
                        58.87067291900017
                    ],
                    [
                        -3.411976691999882,
                        58.87498607000008
                    ],
                    [
                        -3.422189907999922,
                        58.874009507
                    ],
                    [
                        -3.43016516799986,
                        58.87482330900009
                    ],
                    [
                        -3.433338995999918,
                        58.88495514500006
                    ],
                    [
                        -3.418446417999888,
                        58.90448639500015
                    ],
                    [
                        -3.41633053299995,
                        58.908270575000145
                    ],
                    [
                        -3.393218553999901,
                        58.920233466000084
                    ],
                    [
                        -3.349354620999861,
                        58.92853424700017
                    ],
                    [
                        -3.337147589999887,
                        58.92869700700014
                    ],
                    [
                        -3.321848110999923,
                        58.92365143400009
                    ],
                    [
                        -3.28758704299986,
                        58.905951239
                    ],
                    [
                        -3.265451626999891,
                        58.902044989
                    ],
                    [
                        -3.246083136999857,
                        58.89618561400006
                    ]
                ]
            ],
            [
                [
                    [
                        -3.156158006999874,
                        59.14223867400004
                    ],
                    [
                        -3.121571417999888,
                        59.12396881700015
                    ],
                    [
                        -3.108306443999936,
                        59.124212958
                    ],
                    [
                        -3.09593665299991,
                        59.12734609600007
                    ],
                    [
                        -3.084543423999946,
                        59.12799713700012
                    ],
                    [
                        -3.074208136999857,
                        59.12376536700002
                    ],
                    [
                        -3.058461066999882,
                        59.11176178600009
                    ],
                    [
                        -3.049183722999942,
                        59.10809967700011
                    ],
                    [
                        -3.063547329999921,
                        59.101996161000116
                    ],
                    [
                        -3.070301886999857,
                        59.101263739
                    ],
                    [
                        -3.051421678999901,
                        59.09015534100017
                    ],
                    [
                        -2.994496222999885,
                        59.07330963700015
                    ],
                    [
                        -3.001861131999874,
                        59.07176341400013
                    ],
                    [
                        -3.004994269999941,
                        59.070257880000085
                    ],
                    [
                        -3.008168097999914,
                        59.06655508000013
                    ],
                    [
                        -3.003773566999939,
                        59.06443919500013
                    ],
                    [
                        -2.999012824999852,
                        59.061509507000025
                    ],
                    [
                        -2.994496222999885,
                        59.05971914300004
                    ],
                    [
                        -3.05370032499988,
                        59.04726797100001
                    ],
                    [
                        -3.062814907999893,
                        59.0494652360001
                    ],
                    [
                        -3.071197068999936,
                        59.033433335
                    ],
                    [
                        -3.090931769999884,
                        59.018744208
                    ],
                    [
                        -3.113921678999958,
                        59.008205471000124
                    ],
                    [
                        -3.131703253999945,
                        59.00507233300006
                    ],
                    [
                        -3.116688605999912,
                        58.99819570500016
                    ],
                    [
                        -3.097320115999906,
                        58.99404531500004
                    ],
                    [
                        -3.059722459999904,
                        58.991400458000115
                    ],
                    [
                        -3.044585740999906,
                        58.997992255
                    ],
                    [
                        -3.029204881999874,
                        59.01032135600015
                    ],
                    [
                        -3.011952277999853,
                        59.01740143400012
                    ],
                    [
                        -2.974436001999919,
                        58.999090887000065
                    ],
                    [
                        -2.9603572259999,
                        59.00116608300006
                    ],
                    [
                        -2.947132941999939,
                        59.007554429
                    ],
                    [
                        -2.933094855999912,
                        59.01129791900003
                    ],
                    [
                        -2.917307094999956,
                        59.00617096600011
                    ],
                    [
                        -2.906564907999922,
                        58.996568101000136
                    ],
                    [
                        -2.895375128999916,
                        58.991034247000115
                    ],
                    [
                        -2.878407355999855,
                        58.99823639500015
                    ],
                    [
                        -2.880523240999878,
                        58.99359772300012
                    ],
                    [
                        -2.883452928999901,
                        58.98191966400013
                    ],
                    [
                        -2.885365363999938,
                        58.977118231000034
                    ],
                    [
                        -2.864735480999912,
                        58.974839585000055
                    ],
                    [
                        -2.847645636999886,
                        58.98029205900009
                    ],
                    [
                        -2.831166144999884,
                        58.98769765800007
                    ],
                    [
                        -2.812977667999888,
                        58.991400458000115
                    ],
                    [
                        -2.790150519999884,
                        58.99225495000003
                    ],
                    [
                        -2.78062903599988,
                        58.990301825000174
                    ],
                    [
                        -2.775380011999886,
                        58.98395416900014
                    ],
                    [
                        -2.781890428999901,
                        58.97394440300015
                    ],
                    [
                        -2.822499152999882,
                        58.95856354400003
                    ],
                    [
                        -2.837554490999906,
                        58.950384833000115
                    ],
                    [
                        -2.826242641999926,
                        58.93740469000012
                    ],
                    [
                        -2.811919725999928,
                        58.92914459800012
                    ],
                    [
                        -2.794789191999911,
                        58.92609284100014
                    ],
                    [
                        -2.775380011999886,
                        58.92869700700014
                    ],
                    [
                        -2.789418097999942,
                        58.94989655200014
                    ],
                    [
                        -2.795969204999921,
                        58.956691799000154
                    ],
                    [
                        -2.777577277999882,
                        58.95750560100008
                    ],
                    [
                        -2.744048631999931,
                        58.96918366100006
                    ],
                    [
                        -2.727650519999941,
                        58.97089264500006
                    ],
                    [
                        -2.713612433999884,
                        58.96686432500012
                    ],
                    [
                        -2.707630988999881,
                        58.95897044500013
                    ],
                    [
                        -2.706206834999904,
                        58.947455145
                    ],
                    [
                        -2.70653235599994,
                        58.93244049700017
                    ],
                    [
                        -2.714751756999931,
                        58.92548248900009
                    ],
                    [
                        -2.733754035999937,
                        58.92015208500011
                    ],
                    [
                        -2.771962042999888,
                        58.915716864000146
                    ],
                    [
                        -2.785023566999882,
                        58.91071198100009
                    ],
                    [
                        -2.808013475999928,
                        58.88800690300015
                    ],
                    [
                        -2.823841925999886,
                        58.881537177000055
                    ],
                    [
                        -2.839344855999883,
                        58.88263580900009
                    ],
                    [
                        -2.91502844999988,
                        58.899115302
                    ],
                    [
                        -2.926828579999949,
                        58.90485260600015
                    ],
                    [
                        -2.937245245999947,
                        58.91323476800004
                    ],
                    [
                        -2.973622199999909,
                        58.95380280200011
                    ],
                    [
                        -2.981190558999884,
                        58.960109768000066
                    ],
                    [
                        -2.993763800999943,
                        58.95880768400015
                    ],
                    [
                        -3.007801886999914,
                        58.949042059000035
                    ],
                    [
                        -3.023833787999905,
                        58.940497137000094
                    ],
                    [
                        -3.0423884759999,
                        58.943019924000126
                    ],
                    [
                        -3.052561001999948,
                        58.93878815300009
                    ],
                    [
                        -3.061756964999916,
                        58.93939850500006
                    ],
                    [
                        -3.071685350999928,
                        58.941839911
                    ],
                    [
                        -3.084543423999946,
                        58.943019924000126
                    ],
                    [
                        -3.070301886999857,
                        58.92869700700014
                    ],
                    [
                        -3.101226365999878,
                        58.93012116100003
                    ],
                    [
                        -3.113189256999931,
                        58.92666250200001
                    ],
                    [
                        -3.118031378999888,
                        58.915716864000146
                    ],
                    [
                        -3.142648891999897,
                        58.921210028000175
                    ],
                    [
                        -3.202788865999878,
                        58.915920315
                    ],
                    [
                        -3.22789466099988,
                        58.92869700700014
                    ],
                    [
                        -3.23851477799991,
                        58.950913804
                    ],
                    [
                        -3.235340949999852,
                        58.97256094000009
                    ],
                    [
                        -3.22329667899993,
                        58.99135976800012
                    ],
                    [
                        -3.207386847999914,
                        59.00507233300006
                    ],
                    [
                        -3.207386847999914,
                        59.01129791900003
                    ],
                    [
                        -3.214751756999903,
                        59.01748281500009
                    ],
                    [
                        -3.22044837099989,
                        59.024115302000055
                    ],
                    [
                        -3.224680141999926,
                        59.03119538000011
                    ],
                    [
                        -3.22789466099988,
                        59.0385602890001
                    ],
                    [
                        -3.23875891799986,
                        59.035142320000105
                    ],
                    [
                        -3.245838995999918,
                        59.03050364799999
                    ],
                    [
                        -3.247222459999904,
                        59.02484772300009
                    ],
                    [
                        -3.240956183999856,
                        59.018133856000034
                    ],
                    [
                        -3.240956183999856,
                        59.01129791900003
                    ],
                    [
                        -3.250396287999877,
                        59.01447174700009
                    ],
                    [
                        -3.259185350999928,
                        59.01581452000009
                    ],
                    [
                        -3.267486131999903,
                        59.01471588700004
                    ],
                    [
                        -3.275786912999905,
                        59.01129791900003
                    ],
                    [
                        -3.266590949999909,
                        59.007147528000175
                    ],
                    [
                        -3.263050910999937,
                        59.00092194200012
                    ],
                    [
                        -3.264068162999905,
                        58.99306875200013
                    ],
                    [
                        -3.26207434799997,
                        58.991400458000115
                    ],
                    [
                        -3.256988084999932,
                        58.97833893400015
                    ],
                    [
                        -3.26537024599989,
                        58.96918366100006
                    ],
                    [
                        -3.289418097999942,
                        58.956691799000154
                    ],
                    [
                        -3.289418097999942,
                        58.950384833000115
                    ],
                    [
                        -3.323231574999909,
                        58.95718008000013
                    ],
                    [
                        -3.353260870999861,
                        58.98281484600001
                    ],
                    [
                        -3.366688605999855,
                        59.01609935100011
                    ],
                    [
                        -3.350819464999916,
                        59.046047268000095
                    ],
                    [
                        -3.34398352799991,
                        59.05280182500012
                    ],
                    [
                        -3.350209113999881,
                        59.09796784100017
                    ],
                    [
                        -3.323801235999895,
                        59.12742747600005
                    ],
                    [
                        -3.279286261999886,
                        59.14354075700011
                    ],
                    [
                        -3.231068488999938,
                        59.14842357
                    ],
                    [
                        -3.182728644999912,
                        59.147162177000084
                    ],
                    [
                        -3.156158006999874,
                        59.14223867400004
                    ]
                ]
            ],
            [
                [
                    [
                        -2.614857550999943,
                        59.15216705900012
                    ],
                    [
                        -2.617787238999881,
                        59.14842357
                    ],
                    [
                        -2.590077277999882,
                        59.14887116100009
                    ],
                    [
                        -2.577951626999891,
                        59.147202867000075
                    ],
                    [
                        -2.569406704999949,
                        59.14223867400004
                    ],
                    [
                        -2.58234615799995,
                        59.143988348000036
                    ],
                    [
                        -2.592884894999941,
                        59.138576565000065
                    ],
                    [
                        -2.595204230999883,
                        59.12954336100016
                    ],
                    [
                        -2.583648240999878,
                        59.12055084800012
                    ],
                    [
                        -2.571603969999956,
                        59.11884186400012
                    ],
                    [
                        -2.558705206999946,
                        59.12083567900008
                    ],
                    [
                        -2.535267706999946,
                        59.12799713700012
                    ],
                    [
                        -2.534738735999895,
                        59.12287018400009
                    ],
                    [
                        -2.53648841099988,
                        59.12156810100011
                    ],
                    [
                        -2.539296027999853,
                        59.12173086100016
                    ],
                    [
                        -2.541981574999909,
                        59.12055084800012
                    ],
                    [
                        -2.548451300999915,
                        59.10903554900001
                    ],
                    [
                        -2.542388475999928,
                        59.102972723
                    ],
                    [
                        -2.514759894999912,
                        59.09381745000003
                    ],
                    [
                        -2.532622850999871,
                        59.083563544000086
                    ],
                    [
                        -2.576039191999939,
                        59.080064195000105
                    ],
                    [
                        -2.597320115999906,
                        59.07330963700015
                    ],
                    [
                        -2.599476691999911,
                        59.09589264500015
                    ],
                    [
                        -2.614369269999941,
                        59.10724518400012
                    ],
                    [
                        -2.634429490999906,
                        59.10862864800002
                    ],
                    [
                        -2.651844855999883,
                        59.101263739
                    ],
                    [
                        -2.649159308999913,
                        59.08551666900014
                    ],
                    [
                        -2.670725063999925,
                        59.081529039000074
                    ],
                    [
                        -2.686105923999946,
                        59.08917877800009
                    ],
                    [
                        -2.665516730999912,
                        59.10809967700011
                    ],
                    [
                        -2.650502081999889,
                        59.11176178600009
                    ],
                    [
                        -2.634836391999926,
                        59.11273834800015
                    ],
                    [
                        -2.622425910999908,
                        59.11644114799999
                    ],
                    [
                        -2.617787238999881,
                        59.12799713700012
                    ],
                    [
                        -2.624256964999887,
                        59.13385651200015
                    ],
                    [
                        -2.655181443999908,
                        59.15127187700013
                    ],
                    [
                        -2.665516730999912,
                        59.15521881700012
                    ],
                    [
                        -2.625640428999958,
                        59.16242096600014
                    ],
                    [
                        -2.61156165299991,
                        59.161444403000175
                    ],
                    [
                        -2.61156165299991,
                        59.15521881700012
                    ],
                    [
                        -2.614857550999943,
                        59.15216705900012
                    ]
                ]
            ],
            [
                [
                    [
                        -3.029123501999891,
                        59.18235911700013
                    ],
                    [
                        -3.021839972999942,
                        59.18231842700014
                    ],
                    [
                        -2.980824347999942,
                        59.18744538000006
                    ],
                    [
                        -2.967884894999941,
                        59.18744538000006
                    ],
                    [
                        -2.952951626999891,
                        59.18317291900014
                    ],
                    [
                        -2.952463344999927,
                        59.178168036
                    ],
                    [
                        -2.953846808999884,
                        59.17674388200014
                    ],
                    [
                        -2.956776495999918,
                        59.17670319200015
                    ],
                    [
                        -2.9603572259999,
                        59.17576732000008
                    ],
                    [
                        -2.97716223899991,
                        59.16339752800003
                    ],
                    [
                        -2.974029100999928,
                        59.147528387000094
                    ],
                    [
                        -2.97524980399993,
                        59.13377513200008
                    ],
                    [
                        -3.004790818999908,
                        59.12799713700012
                    ],
                    [
                        -3.036447719999984,
                        59.12909577000006
                    ],
                    [
                        -3.065581834999932,
                        59.133612372000115
                    ],
                    [
                        -3.091786261999886,
                        59.142808335
                    ],
                    [
                        -3.114654100999871,
                        59.1583519550001
                    ],
                    [
                        -3.120432094999927,
                        59.17658112200017
                    ],
                    [
                        -3.102406378999916,
                        59.190252997000115
                    ],
                    [
                        -3.074208136999857,
                        59.19749583500014
                    ],
                    [
                        -3.049183722999942,
                        59.196844794000086
                    ],
                    [
                        -3.048085089999887,
                        59.19489166900003
                    ],
                    [
                        -3.0462947259999,
                        59.190863348000065
                    ],
                    [
                        -3.042551235999952,
                        59.186346747000115
                    ],
                    [
                        -3.035552537999877,
                        59.18317291900014
                    ],
                    [
                        -3.029123501999891,
                        59.18235911700013
                    ]
                ]
            ],
            [
                [
                    [
                        -2.768625454999949,
                        59.244614976000136
                    ],
                    [
                        -2.768625454999949,
                        59.237860419000086
                    ],
                    [
                        -2.755441860999923,
                        59.23940664300012
                    ],
                    [
                        -2.743641730999855,
                        59.238104559000035
                    ],
                    [
                        -2.734201626999919,
                        59.23334381700012
                    ],
                    [
                        -2.727650519999941,
                        59.22418854400014
                    ],
                    [
                        -2.734201626999919,
                        59.215521552000055
                    ],
                    [
                        -2.756337042999917,
                        59.19806549700009
                    ],
                    [
                        -2.761097785999908,
                        59.19310130400005
                    ],
                    [
                        -2.761097785999908,
                        59.177313544000114
                    ],
                    [
                        -2.758941209999904,
                        59.16421133000013
                    ],
                    [
                        -2.751088019999912,
                        59.15607330900012
                    ],
                    [
                        -2.734486456999946,
                        59.15521881700012
                    ],
                    [
                        -2.734486456999946,
                        59.14842357
                    ],
                    [
                        -2.764027472999914,
                        59.14264557500003
                    ],
                    [
                        -2.781361456999917,
                        59.141424872000115
                    ],
                    [
                        -2.789133266999926,
                        59.145331122000115
                    ],
                    [
                        -2.792551235999923,
                        59.158636786000116
                    ],
                    [
                        -2.801625128999916,
                        59.16815827000012
                    ],
                    [
                        -2.814768032999893,
                        59.17389557500009
                    ],
                    [
                        -2.830067511999857,
                        59.17576732000008
                    ],
                    [
                        -2.830067511999857,
                        59.196844794000086
                    ],
                    [
                        -2.817290818999908,
                        59.19122955900018
                    ],
                    [
                        -2.80711829299986,
                        59.19179922100015
                    ],
                    [
                        -2.797271287999934,
                        59.195013739
                    ],
                    [
                        -2.785959438999953,
                        59.196844794000086
                    ],
                    [
                        -2.78490149599989,
                        59.200344143000066
                    ],
                    [
                        -2.790272589999859,
                        59.21759674700017
                    ],
                    [
                        -2.789133266999926,
                        59.22418854400014
                    ],
                    [
                        -2.791900193999879,
                        59.2369652360001
                    ],
                    [
                        -2.787912563999896,
                        59.24721914300012
                    ],
                    [
                        -2.777455206999917,
                        59.25291575700011
                    ],
                    [
                        -2.761097785999908,
                        59.2521019550001
                    ],
                    [
                        -2.761097785999908,
                        59.244614976000136
                    ],
                    [
                        -2.768625454999949,
                        59.244614976000136
                    ]
                ]
            ],
            [
                [
                    [
                        -2.395497199999909,
                        59.29507070500016
                    ],
                    [
                        -2.392486131999903,
                        59.28546784100017
                    ],
                    [
                        -2.439808722999942,
                        59.28416575700008
                    ],
                    [
                        -2.458973761999914,
                        59.279120184000035
                    ],
                    [
                        -2.46694902299987,
                        59.26821523600016
                    ],
                    [
                        -2.477853969999956,
                        59.26276276200004
                    ],
                    [
                        -2.497385219999927,
                        59.23920319200009
                    ],
                    [
                        -2.504505988999881,
                        59.23338450700011
                    ],
                    [
                        -2.514759894999912,
                        59.23041413000011
                    ],
                    [
                        -2.516265428999873,
                        59.25214264500009
                    ],
                    [
                        -2.533111131999874,
                        59.251166083000115
                    ],
                    [
                        -2.576812303999958,
                        59.22418854400014
                    ],
                    [
                        -2.571766730999912,
                        59.23456452000015
                    ],
                    [
                        -2.569406704999949,
                        59.237860419000086
                    ],
                    [
                        -2.569406704999949,
                        59.244614976000136
                    ],
                    [
                        -2.588612433999913,
                        59.23867422100001
                    ],
                    [
                        -2.596791144999912,
                        59.2391625020001
                    ],
                    [
                        -2.60415605399993,
                        59.244614976000136
                    ],
                    [
                        -2.641224738999938,
                        59.217962958
                    ],
                    [
                        -2.661854620999861,
                        59.20709870000012
                    ],
                    [
                        -2.679269985999923,
                        59.20990631700006
                    ],
                    [
                        -2.681874152999853,
                        59.196356512000094
                    ],
                    [
                        -2.687652147999899,
                        59.19017161700013
                    ],
                    [
                        -2.692616339999859,
                        59.19228750200013
                    ],
                    [
                        -2.69294186099998,
                        59.20368073100009
                    ],
                    [
                        -2.687977667999917,
                        59.217962958
                    ],
                    [
                        -2.681019660999937,
                        59.22313060100002
                    ],
                    [
                        -2.671538865999935,
                        59.22532786699999
                    ],
                    [
                        -2.658802863999881,
                        59.23041413000011
                    ],
                    [
                        -2.649728969999956,
                        59.2369652360001
                    ],
                    [
                        -2.633168097999914,
                        59.25226471600014
                    ],
                    [
                        -2.624623175999886,
                        59.25828685100008
                    ],
                    [
                        -2.612619594999956,
                        59.26154205900012
                    ],
                    [
                        -2.602772589999859,
                        59.261419989000146
                    ],
                    [
                        -2.595570441999911,
                        59.26483795800014
                    ],
                    [
                        -2.591135219999927,
                        59.27875397300012
                    ],
                    [
                        -2.596669074999852,
                        59.28180573100012
                    ],
                    [
                        -2.61156165299991,
                        59.29303620000003
                    ],
                    [
                        -2.587717251999919,
                        59.29315827
                    ],
                    [
                        -2.547230597999885,
                        59.30573151200018
                    ],
                    [
                        -2.522124803999901,
                        59.306708075000145
                    ],
                    [
                        -2.522124803999901,
                        59.29926178600009
                    ],
                    [
                        -2.536976691999882,
                        59.294378973
                    ],
                    [
                        -2.551828579999921,
                        59.28742096600003
                    ],
                    [
                        -2.576812303999958,
                        59.27130768400015
                    ],
                    [
                        -2.56932532499988,
                        59.27016836100002
                    ],
                    [
                        -2.566273566999882,
                        59.268784898000135
                    ],
                    [
                        -2.563221808999884,
                        59.26508209800009
                    ],
                    [
                        -2.551625128999888,
                        59.27081940300015
                    ],
                    [
                        -2.518788214999887,
                        59.274318752000156
                    ],
                    [
                        -2.492421027999882,
                        59.28937409100017
                    ],
                    [
                        -2.477202928999901,
                        59.29075755400005
                    ],
                    [
                        -2.46117102799991,
                        59.29067617400007
                    ],
                    [
                        -2.446400519999941,
                        59.29303620000003
                    ],
                    [
                        -2.424956834999904,
                        59.31045156500009
                    ],
                    [
                        -2.411040818999908,
                        59.31899648600002
                    ],
                    [
                        -2.404896613999938,
                        59.31635163000011
                    ],
                    [
                        -2.395497199999909,
                        59.29507070500016
                    ]
                ]
            ],
            [
                [
                    [
                        -2.946359829999921,
                        59.34955475500014
                    ],
                    [
                        -2.947661912999934,
                        59.34853750200007
                    ],
                    [
                        -2.950184699999852,
                        59.34861888200005
                    ],
                    [
                        -2.952951626999891,
                        59.347723700000145
                    ],
                    [
                        -2.963937954999949,
                        59.34023672100001
                    ],
                    [
                        -2.964507615999935,
                        59.33535390800013
                    ],
                    [
                        -2.9603572259999,
                        59.32656484600007
                    ],
                    [
                        -2.980946417999917,
                        59.32591380400011
                    ],
                    [
                        -2.987782355999855,
                        59.32656484600007
                    ],
                    [
                        -2.987782355999855,
                        59.31976959800012
                    ],
                    [
                        -2.909535285999937,
                        59.307033596000096
                    ],
                    [
                        -2.895497199999909,
                        59.30296458500011
                    ],
                    [
                        -2.876535610999895,
                        59.27692291900014
                    ],
                    [
                        -2.870961066999882,
                        59.27130768400015
                    ],
                    [
                        -2.85960852799991,
                        59.26752350500011
                    ],
                    [
                        -2.848866339999887,
                        59.266791083
                    ],
                    [
                        -2.839019334999875,
                        59.26508209800009
                    ],
                    [
                        -2.830067511999857,
                        59.25828685100008
                    ],
                    [
                        -2.830067511999857,
                        59.2521019550001
                    ],
                    [
                        -2.850087042999917,
                        59.25214264500009
                    ],
                    [
                        -2.862172003999916,
                        59.24721914300012
                    ],
                    [
                        -2.885365363999938,
                        59.23041413000011
                    ],
                    [
                        -2.884022589999859,
                        59.23924388200008
                    ],
                    [
                        -2.882232225999871,
                        59.24603913
                    ],
                    [
                        -2.878407355999855,
                        59.25193919500013
                    ],
                    [
                        -2.870961066999882,
                        59.25828685100008
                    ],
                    [
                        -2.920318162999962,
                        59.28314850500011
                    ],
                    [
                        -2.947417772999899,
                        59.29120514500015
                    ],
                    [
                        -2.974680141999954,
                        59.29303620000003
                    ],
                    [
                        -2.974680141999954,
                        59.285589911000145
                    ],
                    [
                        -2.969797329999892,
                        59.281927802
                    ],
                    [
                        -2.9603572259999,
                        59.27130768400015
                    ],
                    [
                        -2.974680141999954,
                        59.27875397300012
                    ],
                    [
                        -2.982411261999886,
                        59.26992422100007
                    ],
                    [
                        -2.9896541009999,
                        59.26707591400013
                    ],
                    [
                        -2.997792120999918,
                        59.268133856000176
                    ],
                    [
                        -3.008168097999914,
                        59.27130768400015
                    ],
                    [
                        -3.015126105999912,
                        59.27488841400013
                    ],
                    [
                        -3.020253058999913,
                        59.28034088700015
                    ],
                    [
                        -3.034657355999883,
                        59.301214911000116
                    ],
                    [
                        -3.036691860999923,
                        59.306708075000145
                    ],
                    [
                        -3.039947068999879,
                        59.31199778900013
                    ],
                    [
                        -3.049183722999942,
                        59.31976959800012
                    ],
                    [
                        -3.058705206999917,
                        59.32298411699999
                    ],
                    [
                        -3.06700598899991,
                        59.32269928600006
                    ],
                    [
                        -3.073353644999941,
                        59.32453034100003
                    ],
                    [
                        -3.077056443999879,
                        59.33405182500003
                    ],
                    [
                        -3.056263800999886,
                        59.33429596600017
                    ],
                    [
                        -3.024322068999879,
                        59.33038971600017
                    ],
                    [
                        -3.008168097999914,
                        59.33405182500003
                    ],
                    [
                        -2.980336066999882,
                        59.35504791900017
                    ],
                    [
                        -2.965199347999885,
                        59.360907294000114
                    ],
                    [
                        -2.946685350999871,
                        59.353908596000124
                    ],
                    [
                        -2.946359829999921,
                        59.34955475500014
                    ]
                ]
            ],
            [
                [
                    [
                        -1.608143683999913,
                        59.54734935100008
                    ],
                    [
                        -1.610422329999892,
                        59.53949616100009
                    ],
                    [
                        -1.596750454999949,
                        59.53949616100009
                    ],
                    [
                        -1.596750454999949,
                        59.532049872000115
                    ],
                    [
                        -1.613392706999917,
                        59.52659739800008
                    ],
                    [
                        -1.631255662999877,
                        59.5169945330001
                    ],
                    [
                        -1.64549719999988,
                        59.51410553600006
                    ],
                    [
                        -1.651275193999936,
                        59.528957424000126
                    ],
                    [
                        -1.643666144999912,
                        59.545640367000075
                    ],
                    [
                        -1.625233527999882,
                        59.555812893
                    ],
                    [
                        -1.602853969999956,
                        59.56024811400009
                    ],
                    [
                        -1.583119269999912,
                        59.55996328300013
                    ],
                    [
                        -1.582386847999885,
                        59.55512116100006
                    ],
                    [
                        -1.583851691999939,
                        59.553697007
                    ],
                    [
                        -1.58641516799986,
                        59.55361562700001
                    ],
                    [
                        -1.589344855999883,
                        59.552476304
                    ],
                    [
                        -1.60366777299987,
                        59.55093008000013
                    ],
                    [
                        -1.608143683999913,
                        59.54734935100008
                    ]
                ]
            ],
            [
                [
                    [
                        -1.321766730999912,
                        60.093085028000175
                    ],
                    [
                        -1.323353644999941,
                        60.08820221600011
                    ],
                    [
                        -1.31509355399993,
                        60.093736070000105
                    ],
                    [
                        -1.308257615999935,
                        60.094142971000124
                    ],
                    [
                        -1.304798956999917,
                        60.086574611000074
                    ],
                    [
                        -1.309803839999859,
                        60.07636139500015
                    ],
                    [
                        -1.317738410999937,
                        60.06622955900018
                    ],
                    [
                        -1.326771613999938,
                        60.05060455900009
                    ],
                    [
                        -1.333566860999952,
                        60.04360586100002
                    ],
                    [
                        -1.339019334999904,
                        60.04389069200006
                    ],
                    [
                        -1.338002081999946,
                        60.051092841000084
                    ],
                    [
                        -1.333851691999911,
                        60.06150950700008
                    ],
                    [
                        -1.333119269999884,
                        60.073675848000065
                    ],
                    [
                        -1.33462480399993,
                        60.07632070500016
                    ],
                    [
                        -1.335804816999939,
                        60.070054429000024
                    ],
                    [
                        -1.33836829299986,
                        60.064032294000086
                    ],
                    [
                        -1.353871222999885,
                        60.05121491100006
                    ],
                    [
                        -1.367909308999913,
                        60.04482656500012
                    ],
                    [
                        -1.369862433999884,
                        60.04913971600014
                    ],
                    [
                        -1.357004360999952,
                        60.05817291900014
                    ],
                    [
                        -1.352040167999888,
                        60.069525458000115
                    ],
                    [
                        -1.347564256999931,
                        60.083441473
                    ],
                    [
                        -1.338002081999946,
                        60.09345123900009
                    ],
                    [
                        -1.340443488999881,
                        60.099676825000145
                    ],
                    [
                        -1.346994594999956,
                        60.10455963700012
                    ],
                    [
                        -1.345692511999857,
                        60.108954169000086
                    ],
                    [
                        -1.327788865999906,
                        60.11139557500003
                    ],
                    [
                        -1.321156378999945,
                        60.115668036000145
                    ],
                    [
                        -1.315825975999871,
                        60.115464585
                    ],
                    [
                        -1.315052863999938,
                        60.106390692
                    ],
                    [
                        -1.321766730999912,
                        60.093085028000175
                    ]
                ]
            ],
            [
                [
                    [
                        -2.048085089999916,
                        60.12921784100011
                    ],
                    [
                        -2.06297766799986,
                        60.117987372000115
                    ],
                    [
                        -2.086903449999937,
                        60.123277085
                    ],
                    [
                        -2.105376756999959,
                        60.13206614800005
                    ],
                    [
                        -2.112294074999852,
                        60.138006903000175
                    ],
                    [
                        -2.110340949999909,
                        60.14533112200017
                    ],
                    [
                        -2.103423631999903,
                        60.1500511740001
                    ],
                    [
                        -2.087880011999914,
                        60.15546295800014
                    ],
                    [
                        -2.064686652999882,
                        60.15729401200012
                    ],
                    [
                        -2.053212042999917,
                        60.155503648000135
                    ],
                    [
                        -2.048898891999926,
                        60.14712148600016
                    ],
                    [
                        -2.048085089999916,
                        60.12921784100011
                    ]
                ]
            ],
            [
                [
                    [
                        -1.074940558999913,
                        60.18280670800003
                    ],
                    [
                        -1.073150193999936,
                        60.173000393000066
                    ],
                    [
                        -1.069935675999886,
                        60.164211330000015
                    ],
                    [
                        -1.062855597999914,
                        60.16404857000005
                    ],
                    [
                        -1.050526495999861,
                        60.16962311400006
                    ],
                    [
                        -1.050445115999878,
                        60.16278717700014
                    ],
                    [
                        -1.052723761999857,
                        60.14842357000008
                    ],
                    [
                        -1.059478318999908,
                        60.13764069200006
                    ],
                    [
                        -1.066761847999914,
                        60.12921784100011
                    ],
                    [
                        -1.073801235999895,
                        60.107733466000084
                    ],
                    [
                        -1.083892381999931,
                        60.10516998900009
                    ],
                    [
                        -1.112945115999935,
                        60.116278387000094
                    ],
                    [
                        -1.122222459999875,
                        60.12311432500003
                    ],
                    [
                        -1.119252081999946,
                        60.15558502800012
                    ],
                    [
                        -1.135650193999879,
                        60.16795482000005
                    ],
                    [
                        -1.134917772999927,
                        60.17869700700005
                    ],
                    [
                        -1.115793423999946,
                        60.18016185100011
                    ],
                    [
                        -1.097523566999882,
                        60.17572663000014
                    ],
                    [
                        -1.089588995999918,
                        60.17816803600009
                    ],
                    [
                        -1.08641516799986,
                        60.18646881700006
                    ],
                    [
                        -1.07945716099988,
                        60.18829987200003
                    ],
                    [
                        -1.074940558999913,
                        60.18280670800003
                    ]
                ]
            ],
            [
                [
                    [
                        -0.975982225999928,
                        60.34031810100005
                    ],
                    [
                        -1.015207485999952,
                        60.3341332050001
                    ],
                    [
                        -1.034087693999879,
                        60.340155341000084
                    ],
                    [
                        -1.028920050999886,
                        60.34564850500011
                    ],
                    [
                        -1.023589647999899,
                        60.349432684000064
                    ],
                    [
                        -1.021555141999954,
                        60.35447825700008
                    ],
                    [
                        -1.016428188999953,
                        60.36200592700014
                    ],
                    [
                        -1.00641842399989,
                        60.368963934000035
                    ],
                    [
                        -0.995228644999884,
                        60.37164948100009
                    ],
                    [
                        -0.983631964999859,
                        60.37230052300005
                    ],
                    [
                        -0.974476691999882,
                        60.37592194200012
                    ],
                    [
                        -0.967600063999953,
                        60.38104889500012
                    ],
                    [
                        -0.93228105399993,
                        60.38275788000014
                    ],
                    [
                        -0.921783006999874,
                        60.38458893400012
                    ],
                    [
                        -0.918771938999953,
                        60.38617584800015
                    ],
                    [
                        -0.912668423999889,
                        60.38678620000009
                    ],
                    [
                        -0.911203579999921,
                        60.382310289000046
                    ],
                    [
                        -0.917713995999918,
                        60.376654364000146
                    ],
                    [
                        -0.931385870999861,
                        60.36847565300003
                    ],
                    [
                        -0.948597785999965,
                        60.35586172100015
                    ],
                    [
                        -0.975982225999928,
                        60.34031810100005
                    ]
                ]
            ],
            [
                [
                    [
                        -0.80907141799986,
                        60.60830312700013
                    ],
                    [
                        -0.800282355999883,
                        60.60797760600012
                    ],
                    [
                        -0.793527798999946,
                        60.61050039300012
                    ],
                    [
                        -0.788319464999859,
                        60.61359284100011
                    ],
                    [
                        -0.784575975999928,
                        60.61513906500015
                    ],
                    [
                        -0.769154425999886,
                        60.61318594
                    ],
                    [
                        -0.758371548999889,
                        60.607570705000015
                    ],
                    [
                        -0.754790818999908,
                        60.59894440300009
                    ],
                    [
                        -0.760731574999852,
                        60.587836005000085
                    ],
                    [
                        -0.785227016999954,
                        60.57632070500013
                    ],
                    [
                        -0.813140428999901,
                        60.58047109600007
                    ],
                    [
                        -0.842152472999885,
                        60.58832428600006
                    ],
                    [
                        -0.870472785999937,
                        60.587836005000085
                    ],
                    [
                        -0.870472785999937,
                        60.580389716000084
                    ],
                    [
                        -0.864898240999935,
                        60.57721588700004
                    ],
                    [
                        -0.86192786399991,
                        60.57420482000013
                    ],
                    [
                        -0.859608527999853,
                        60.571030992000075
                    ],
                    [
                        -0.856190558999856,
                        60.56732819200012
                    ],
                    [
                        -0.883534308999913,
                        60.57054271000008
                    ],
                    [
                        -0.905344204999949,
                        60.586859442
                    ],
                    [
                        -0.938832160999908,
                        60.62132396000011
                    ],
                    [
                        -0.938832160999908,
                        60.62872955900012
                    ],
                    [
                        -0.822132941999911,
                        60.62872955900012
                    ],
                    [
                        -0.81151282499988,
                        60.62588125200007
                    ],
                    [
                        -0.810047980999911,
                        60.61933014500009
                    ],
                    [
                        -0.811268683999856,
                        60.61229075700011
                    ],
                    [
                        -0.80907141799986,
                        60.60830312700013
                    ]
                ]
            ],
            [
                [
                    [
                        -1.292103644999969,
                        60.59959544500005
                    ],
                    [
                        -1.293527798999918,
                        60.59324778900013
                    ],
                    [
                        -1.299143032999922,
                        60.58926015800013
                    ],
                    [
                        -1.308705206999917,
                        60.587836005000085
                    ],
                    [
                        -1.301421678999901,
                        60.564846096000096
                    ],
                    [
                        -1.309437628999945,
                        60.55410390800007
                    ],
                    [
                        -1.32396399599989,
                        60.54645416900014
                    ],
                    [
                        -1.336089647999899,
                        60.533189195000105
                    ],
                    [
                        -1.315541144999941,
                        60.533189195000105
                    ],
                    [
                        -1.320301886999857,
                        60.52826569200006
                    ],
                    [
                        -1.324818488999881,
                        60.52655670800006
                    ],
                    [
                        -1.336089647999899,
                        60.525783596000124
                    ],
                    [
                        -1.33055579299986,
                        60.52387116100009
                    ],
                    [
                        -1.327300584999904,
                        60.52167389500009
                    ],
                    [
                        -1.323353644999941,
                        60.51984284100011
                    ],
                    [
                        -1.315541144999941,
                        60.518947658000016
                    ],
                    [
                        -1.336089647999899,
                        60.48541901200018
                    ],
                    [
                        -1.326527472999885,
                        60.486395575000145
                    ],
                    [
                        -1.317494269999884,
                        60.48891836100016
                    ],
                    [
                        -1.309152798999918,
                        60.49290599200005
                    ],
                    [
                        -1.301421678999901,
                        60.49843984600007
                    ],
                    [
                        -1.295033331999889,
                        60.49225495000009
                    ],
                    [
                        -1.309152798999918,
                        60.47703685100011
                    ],
                    [
                        -1.311919725999871,
                        60.47089264500012
                    ],
                    [
                        -1.308705206999917,
                        60.46491120000003
                    ],
                    [
                        -1.308705206999917,
                        60.457464911000145
                    ],
                    [
                        -1.327951626999948,
                        60.45319245000003
                    ],
                    [
                        -1.351796027999853,
                        60.418361721000096
                    ],
                    [
                        -1.370228644999884,
                        60.4096540390001
                    ],
                    [
                        -1.370228644999884,
                        60.402167059000035
                    ],
                    [
                        -1.357492641999926,
                        60.40143463700012
                    ],
                    [
                        -1.345041469999899,
                        60.402329820000105
                    ],
                    [
                        -1.333363410999908,
                        60.40501536699999
                    ],
                    [
                        -1.322987433999913,
                        60.4096540390001
                    ],
                    [
                        -1.324330206999917,
                        60.42772044500013
                    ],
                    [
                        -1.296986456999917,
                        60.43797435100005
                    ],
                    [
                        -1.233631964999887,
                        60.44379303600003
                    ],
                    [
                        -1.280995245999918,
                        60.46185944200012
                    ],
                    [
                        -1.295033331999889,
                        60.47113678600009
                    ],
                    [
                        -1.284738735999952,
                        60.47870514500012
                    ],
                    [
                        -1.271962042999917,
                        60.48427969000012
                    ],
                    [
                        -1.261952277999853,
                        60.481594143000066
                    ],
                    [
                        -1.26036536399991,
                        60.46491120000003
                    ],
                    [
                        -1.251210089999916,
                        60.47085195500013
                    ],
                    [
                        -1.23078365799995,
                        60.488714911000116
                    ],
                    [
                        -1.22687740799995,
                        60.495306708
                    ],
                    [
                        -1.217518683999913,
                        60.49799225500006
                    ],
                    [
                        -1.199289516999926,
                        60.49237702000006
                    ],
                    [
                        -1.186268683999856,
                        60.48224518400012
                    ],
                    [
                        -1.192616339999887,
                        60.47113678600009
                    ],
                    [
                        -1.18016516799986,
                        60.468207098000065
                    ],
                    [
                        -1.169911261999857,
                        60.46312083500014
                    ],
                    [
                        -1.162342902999882,
                        60.45526764500015
                    ],
                    [
                        -1.157866990999935,
                        60.44379303600003
                    ],
                    [
                        -1.163075324999909,
                        60.43927643400015
                    ],
                    [
                        -1.16470292899993,
                        60.43695709800009
                    ],
                    [
                        -1.151722785999937,
                        60.430812893
                    ],
                    [
                        -1.163970506999903,
                        60.425482489000146
                    ],
                    [
                        -1.185902472999942,
                        60.43158600500014
                    ],
                    [
                        -1.196034308999884,
                        60.427069403000175
                    ],
                    [
                        -1.204335089999887,
                        60.42015208500008
                    ],
                    [
                        -1.214019334999932,
                        60.41498444200009
                    ],
                    [
                        -1.224191860999895,
                        60.41144440300009
                    ],
                    [
                        -1.220773891999954,
                        60.40912506700012
                    ],
                    [
                        -1.208648240999878,
                        60.41071198100015
                    ],
                    [
                        -1.185170050999915,
                        60.416489976000136
                    ],
                    [
                        -1.189116990999906,
                        60.41400788
                    ],
                    [
                        -1.19945227799991,
                        60.4096540390001
                    ],
                    [
                        -1.19945227799991,
                        60.402167059000035
                    ],
                    [
                        -1.165435350999871,
                        60.41205475500006
                    ],
                    [
                        -1.153675910999908,
                        60.40790436400012
                    ],
                    [
                        -1.151722785999937,
                        60.38857656500009
                    ],
                    [
                        -1.14427649599989,
                        60.38857656500009
                    ],
                    [
                        -1.140288865999906,
                        60.398871161
                    ],
                    [
                        -1.136952277999882,
                        60.39948151200015
                    ],
                    [
                        -1.132150844999984,
                        60.398016669000114
                    ],
                    [
                        -1.123768683999913,
                        60.402167059000035
                    ],
                    [
                        -1.119740363999938,
                        60.40814850500006
                    ],
                    [
                        -1.116688605999855,
                        60.41567617400001
                    ],
                    [
                        -1.112049933999941,
                        60.42365143400015
                    ],
                    [
                        -1.103423631999931,
                        60.430812893
                    ],
                    [
                        -1.099964972999914,
                        60.427476304
                    ],
                    [
                        -1.098378058999884,
                        60.424221096000124
                    ],
                    [
                        -1.097035285999908,
                        60.416489976000136
                    ],
                    [
                        -1.088002081999889,
                        60.433010158000016
                    ],
                    [
                        -1.072743292999917,
                        60.44452545800014
                    ],
                    [
                        -1.053944464999887,
                        60.45050690300015
                    ],
                    [
                        -1.034413214999887,
                        60.45062897300012
                    ],
                    [
                        -1.048695441999882,
                        60.43333567900014
                    ],
                    [
                        -1.119252081999946,
                        60.391302802000055
                    ],
                    [
                        -1.125070766999926,
                        60.383734442
                    ],
                    [
                        -1.123768683999913,
                        60.37555573100009
                    ],
                    [
                        -1.117543097999942,
                        60.37392812700007
                    ],
                    [
                        -1.110015428999901,
                        60.37872955900015
                    ],
                    [
                        -1.100209113999881,
                        60.38857656500009
                    ],
                    [
                        -1.094471808999913,
                        60.39150625200001
                    ],
                    [
                        -1.0814509759999,
                        60.39622630400011
                    ],
                    [
                        -1.068267381999874,
                        60.39630768400009
                    ],
                    [
                        -1.062326626999948,
                        60.38544342700011
                    ],
                    [
                        -1.062896287999905,
                        60.379868882000025
                    ],
                    [
                        -1.065419074999909,
                        60.36957428600009
                    ],
                    [
                        -1.070423956999946,
                        60.359523830000015
                    ],
                    [
                        -1.079172329999949,
                        60.35504791900014
                    ],
                    [
                        -1.15721594999988,
                        60.35077545800014
                    ],
                    [
                        -1.172230597999914,
                        60.347601630000085
                    ],
                    [
                        -1.151600714999887,
                        60.33730703300016
                    ],
                    [
                        -1.101226365999935,
                        60.33112213700018
                    ],
                    [
                        -1.075306769999941,
                        60.32094961100013
                    ],
                    [
                        -1.123117641999954,
                        60.29633209800012
                    ],
                    [
                        -1.134348110999952,
                        60.292954820000105
                    ],
                    [
                        -1.137928839999859,
                        60.29071686400012
                    ],
                    [
                        -1.138579881999903,
                        60.285589911000116
                    ],
                    [
                        -1.137115037999934,
                        60.27977122600008
                    ],
                    [
                        -1.134348110999952,
                        60.27558014500012
                    ],
                    [
                        -1.129505988999881,
                        60.27399323100009
                    ],
                    [
                        -1.122222459999875,
                        60.279242255
                    ],
                    [
                        -1.117014126999891,
                        60.278713283000016
                    ],
                    [
                        -1.109364386999857,
                        60.27619049700009
                    ],
                    [
                        -1.09585527299987,
                        60.275091864000146
                    ],
                    [
                        -1.089751756999903,
                        60.27252838700004
                    ],
                    [
                        -1.129709438999953,
                        60.25283437700001
                    ],
                    [
                        -1.147857225999928,
                        60.24884674700003
                    ],
                    [
                        -1.16470292899993,
                        60.25141022300012
                    ],
                    [
                        -1.174672003999945,
                        60.26463450700008
                    ],
                    [
                        -1.182932094999927,
                        60.27191803600009
                    ],
                    [
                        -1.192616339999887,
                        60.27252838700004
                    ],
                    [
                        -1.199126756999874,
                        60.26691315300006
                    ],
                    [
                        -1.198109503999916,
                        60.260565497000115
                    ],
                    [
                        -1.192534959999904,
                        60.25495026200012
                    ],
                    [
                        -1.185170050999915,
                        60.25141022300012
                    ],
                    [
                        -1.185170050999915,
                        60.24518463700015
                    ],
                    [
                        -1.194935675999943,
                        60.245347398000135
                    ],
                    [
                        -1.203277147999927,
                        60.24384186400009
                    ],
                    [
                        -1.219390428999901,
                        60.238348700000145
                    ],
                    [
                        -1.219390428999901,
                        60.232163804
                    ],
                    [
                        -1.179025844999927,
                        60.232163804
                    ],
                    [
                        -1.185536261999914,
                        60.225409247000144
                    ],
                    [
                        -1.190907355999883,
                        60.21845123900006
                    ],
                    [
                        -1.19945227799991,
                        60.20425039300015
                    ],
                    [
                        -1.18101966099988,
                        60.217840887000094
                    ],
                    [
                        -1.171131964999859,
                        60.22256094
                    ],
                    [
                        -1.157866990999935,
                        60.22467682500012
                    ],
                    [
                        -1.187245245999918,
                        60.19122955900015
                    ],
                    [
                        -1.192616339999887,
                        60.17633698100009
                    ],
                    [
                        -1.176503058999913,
                        60.187892971000124
                    ],
                    [
                        -1.155140753999945,
                        60.19257233300014
                    ],
                    [
                        -1.143950975999957,
                        60.18756745000012
                    ],
                    [
                        -1.157866990999935,
                        60.17011139500015
                    ],
                    [
                        -1.123768683999913,
                        60.15582916900014
                    ],
                    [
                        -1.123768683999913,
                        60.149603583
                    ],
                    [
                        -1.151722785999937,
                        60.149603583
                    ],
                    [
                        -1.151722785999937,
                        60.142157294000114
                    ],
                    [
                        -1.14427649599989,
                        60.142157294000114
                    ],
                    [
                        -1.14427649599989,
                        60.13532135600009
                    ],
                    [
                        -1.160878058999941,
                        60.141180731000034
                    ],
                    [
                        -1.170969204999892,
                        60.13621653899999
                    ],
                    [
                        -1.181548631999931,
                        60.129299221000096
                    ],
                    [
                        -1.19945227799991,
                        60.12909577000012
                    ],
                    [
                        -1.185170050999915,
                        60.12164948100015
                    ],
                    [
                        -1.189849412999934,
                        60.113348700000174
                    ],
                    [
                        -1.195139126999891,
                        60.10871002800015
                    ],
                    [
                        -1.2025447259999,
                        60.106919664000074
                    ],
                    [
                        -1.213205532999922,
                        60.10736725500006
                    ],
                    [
                        -1.213205532999922,
                        60.1012230490001
                    ],
                    [
                        -1.205311652999853,
                        60.100165106000034
                    ],
                    [
                        -1.185170050999915,
                        60.094387111000074
                    ],
                    [
                        -1.202259894999941,
                        60.08307526200018
                    ],
                    [
                        -1.19286048099994,
                        60.069810289000074
                    ],
                    [
                        -1.17442786399991,
                        60.06028880400005
                    ],
                    [
                        -1.16470292899993,
                        60.06024811400006
                    ],
                    [
                        -1.170074022999899,
                        60.04596588700018
                    ],
                    [
                        -1.185170050999915,
                        60.03827545800006
                    ],
                    [
                        -1.20372473899991,
                        60.03652578300016
                    ],
                    [
                        -1.219390428999901,
                        60.03974030200011
                    ],
                    [
                        -1.215077277999882,
                        60.026190497000144
                    ],
                    [
                        -1.192616339999887,
                        59.99750397300009
                    ],
                    [
                        -1.201242641999897,
                        59.986395575000145
                    ],
                    [
                        -1.215809699999909,
                        59.98688385600015
                    ],
                    [
                        -1.247303839999916,
                        59.99750397300009
                    ],
                    [
                        -1.242990688999925,
                        59.97687409100017
                    ],
                    [
                        -1.245228644999912,
                        59.955755927000055
                    ],
                    [
                        -1.253570115999878,
                        59.936957098
                    ],
                    [
                        -1.267730272999899,
                        59.923041083000115
                    ],
                    [
                        -1.271839972999942,
                        59.92780182500003
                    ],
                    [
                        -1.274566209999932,
                        59.92987702000015
                    ],
                    [
                        -1.26659094999988,
                        59.91819896000014
                    ],
                    [
                        -1.26341712099989,
                        59.907171942
                    ],
                    [
                        -1.265939907999922,
                        59.89557526200018
                    ],
                    [
                        -1.274566209999932,
                        59.882066148000106
                    ],
                    [
                        -1.263742641999926,
                        59.874904690000065
                    ],
                    [
                        -1.260975714999859,
                        59.866400458000115
                    ],
                    [
                        -1.265004035999937,
                        59.85883209800009
                    ],
                    [
                        -1.274566209999932,
                        59.854193427000055
                    ],
                    [
                        -1.278431769999912,
                        59.85911692900011
                    ],
                    [
                        -1.295033331999889,
                        59.87466054900004
                    ],
                    [
                        -1.30288652299987,
                        59.86432526200012
                    ],
                    [
                        -1.311105923999946,
                        59.86554596600003
                    ],
                    [
                        -1.312855597999942,
                        59.873114325000174
                    ],
                    [
                        -1.301421678999901,
                        59.882066148000106
                    ],
                    [
                        -1.301421678999901,
                        59.88890208500011
                    ],
                    [
                        -1.316477016999926,
                        59.89598216399999
                    ],
                    [
                        -1.347889777999853,
                        59.891994533000016
                    ],
                    [
                        -1.364003058999913,
                        59.89630768400012
                    ],
                    [
                        -1.375965949999852,
                        59.90863678600006
                    ],
                    [
                        -1.35802161399991,
                        59.92157623900006
                    ],
                    [
                        -1.364003058999913,
                        59.93732330900012
                    ],
                    [
                        -1.355702277999853,
                        59.94546133000013
                    ],
                    [
                        -1.345855272999927,
                        59.950384833
                    ],
                    [
                        -1.334787563999896,
                        59.952134507
                    ],
                    [
                        -1.322987433999913,
                        59.95099518400015
                    ],
                    [
                        -1.322987433999913,
                        59.95661041900014
                    ],
                    [
                        -1.341053839999944,
                        59.96775950700008
                    ],
                    [
                        -1.349720831999917,
                        59.97089264500015
                    ],
                    [
                        -1.349720831999917,
                        59.977687893000066
                    ],
                    [
                        -1.322987433999913,
                        59.977687893000066
                    ],
                    [
                        -1.328724738999881,
                        59.99551015800016
                    ],
                    [
                        -1.318023240999878,
                        60.01113515800013
                    ],
                    [
                        -1.30288652299987,
                        60.02802155200011
                    ],
                    [
                        -1.287709113999881,
                        60.075628973
                    ],
                    [
                        -1.273060675999886,
                        60.09520091399999
                    ],
                    [
                        -1.262603318999879,
                        60.11505768400018
                    ],
                    [
                        -1.267730272999899,
                        60.142157294000114
                    ],
                    [
                        -1.273060675999886,
                        60.14618561400009
                    ],
                    [
                        -1.278960740999906,
                        60.145086981000034
                    ],
                    [
                        -1.284535285999908,
                        60.14594147300012
                    ],
                    [
                        -1.288889126999919,
                        60.15582916900014
                    ],
                    [
                        -1.288156704999892,
                        60.159613348000065
                    ],
                    [
                        -1.285267706999946,
                        60.16461823100012
                    ],
                    [
                        -1.269357876999919,
                        60.185451565
                    ],
                    [
                        -1.279530402999882,
                        60.18512604400017
                    ],
                    [
                        -1.294056769999912,
                        60.17967357000013
                    ],
                    [
                        -1.298166469999956,
                        60.17633698100009
                    ],
                    [
                        -1.30919348899991,
                        60.17706940300003
                    ],
                    [
                        -1.312408006999874,
                        60.18065013200011
                    ],
                    [
                        -1.309315558999884,
                        60.18903229400017
                    ],
                    [
                        -1.294667120999861,
                        60.21539948100015
                    ],
                    [
                        -1.286773240999906,
                        60.225287177000084
                    ],
                    [
                        -1.277699347999885,
                        60.23322174700006
                    ],
                    [
                        -1.267730272999899,
                        60.238348700000145
                    ],
                    [
                        -1.267730272999899,
                        60.24518463700015
                    ],
                    [
                        -1.286936001999948,
                        60.24628327000012
                    ],
                    [
                        -1.301584438999953,
                        60.23578522300015
                    ],
                    [
                        -1.329253709999875,
                        60.20425039300015
                    ],
                    [
                        -1.343006964999887,
                        60.22748444200006
                    ],
                    [
                        -1.357655402999882,
                        60.24433014500006
                    ],
                    [
                        -1.378570115999935,
                        60.254787502000156
                    ],
                    [
                        -1.411122199999909,
                        60.258856512000094
                    ],
                    [
                        -1.411122199999909,
                        60.25141022300012
                    ],
                    [
                        -1.391835089999887,
                        60.24652741100006
                    ],
                    [
                        -1.376088019999912,
                        60.237941799000154
                    ],
                    [
                        -1.362456834999875,
                        60.225978908000016
                    ],
                    [
                        -1.349720831999917,
                        60.21108633000016
                    ],
                    [
                        -1.356556769999941,
                        60.19676341399999
                    ],
                    [
                        -1.376088019999912,
                        60.20709870000009
                    ],
                    [
                        -1.38532467399989,
                        60.21059804900007
                    ],
                    [
                        -1.398101365999935,
                        60.21108633000016
                    ],
                    [
                        -1.397572394999941,
                        60.19269440300012
                    ],
                    [
                        -1.404611782999922,
                        60.17951080900015
                    ],
                    [
                        -1.413644985999923,
                        60.17698802300005
                    ],
                    [
                        -1.418690558999884,
                        60.190619208
                    ],
                    [
                        -1.430775519999884,
                        60.18488190300003
                    ],
                    [
                        -1.434925910999908,
                        60.17446523600013
                    ],
                    [
                        -1.437733527999853,
                        60.16339752800009
                    ],
                    [
                        -1.445952928999873,
                        60.15582916900014
                    ],
                    [
                        -1.45543372299997,
                        60.15619538000006
                    ],
                    [
                        -1.49429277299987,
                        60.17011139500015
                    ],
                    [
                        -1.496408657999893,
                        60.17218659100017
                    ],
                    [
                        -1.517933722999914,
                        60.18451569200003
                    ],
                    [
                        -1.520985480999883,
                        60.1837425800001
                    ],
                    [
                        -1.527251756999931,
                        60.19293854400014
                    ],
                    [
                        -1.52672278599988,
                        60.197577216000084
                    ],
                    [
                        -1.520985480999883,
                        60.20425039300015
                    ],
                    [
                        -1.509510870999861,
                        60.20994700700011
                    ],
                    [
                        -1.479400193999908,
                        60.214016018000095
                    ],
                    [
                        -1.466420050999915,
                        60.217230536000145
                    ],
                    [
                        -1.479237433999856,
                        60.222235419000086
                    ],
                    [
                        -1.503651495999918,
                        60.243353583
                    ],
                    [
                        -1.507964647999899,
                        60.23529694200006
                    ],
                    [
                        -1.513335740999878,
                        60.22166575700011
                    ],
                    [
                        -1.525054490999935,
                        60.212347723000065
                    ],
                    [
                        -1.536773240999935,
                        60.210923570000105
                    ],
                    [
                        -1.542144334999904,
                        60.220933335
                    ],
                    [
                        -1.5482478509999,
                        60.22972239800005
                    ],
                    [
                        -1.56265214799987,
                        60.22744375200007
                    ],
                    [
                        -1.593006964999916,
                        60.217230536000145
                    ],
                    [
                        -1.615142381999903,
                        60.2157250020001
                    ],
                    [
                        -1.624663865999906,
                        60.217230536000145
                    ],
                    [
                        -1.630238410999908,
                        60.220892645
                    ],
                    [
                        -1.631174282999893,
                        60.22589752800003
                    ],
                    [
                        -1.632801886999914,
                        60.23029205900012
                    ],
                    [
                        -1.640858527999853,
                        60.232163804
                    ],
                    [
                        -1.661284959999932,
                        60.23387278899999
                    ],
                    [
                        -1.669748501999948,
                        60.239569403000175
                    ],
                    [
                        -1.679351365999935,
                        60.26569245000003
                    ],
                    [
                        -1.690174933999856,
                        60.28514232000013
                    ],
                    [
                        -1.686390753999916,
                        60.29092031500009
                    ],
                    [
                        -1.671864386999886,
                        60.29979075700014
                    ],
                    [
                        -1.657378709999932,
                        60.30621979400014
                    ],
                    [
                        -1.638742641999926,
                        60.31146881700012
                    ],
                    [
                        -1.61937415299991,
                        60.314357815000065
                    ],
                    [
                        -1.602935350999928,
                        60.31346263200008
                    ],
                    [
                        -1.588286912999905,
                        60.30703359600015
                    ],
                    [
                        -1.577219204999892,
                        60.29873281500009
                    ],
                    [
                        -1.565297003999945,
                        60.29266998900009
                    ],
                    [
                        -1.548410610999952,
                        60.292954820000105
                    ],
                    [
                        -1.555816209999932,
                        60.30662669500005
                    ],
                    [
                        -1.520985480999883,
                        60.29979075700014
                    ],
                    [
                        -1.520985480999883,
                        60.30662669500005
                    ],
                    [
                        -1.528431769999884,
                        60.30662669500005
                    ],
                    [
                        -1.528431769999884,
                        60.31346263200008
                    ],
                    [
                        -1.481434699999852,
                        60.316473700000174
                    ],
                    [
                        -1.467600063999953,
                        60.30853913000011
                    ],
                    [
                        -1.466420050999915,
                        60.278713283000016
                    ],
                    [
                        -1.461415167999888,
                        60.27973053600009
                    ],
                    [
                        -1.45018469999988,
                        60.28375885600006
                    ],
                    [
                        -1.445952928999873,
                        60.28620026200018
                    ],
                    [
                        -1.450917120999918,
                        60.291734117000104
                    ],
                    [
                        -1.456450975999928,
                        60.30231354400014
                    ],
                    [
                        -1.45958411399991,
                        60.30662669500005
                    ],
                    [
                        -1.439035610999952,
                        60.30662669500005
                    ],
                    [
                        -1.441802537999905,
                        60.310695705000015
                    ],
                    [
                        -1.445952928999873,
                        60.32094961100013
                    ],
                    [
                        -1.422759568999936,
                        60.32611725500014
                    ],
                    [
                        -1.396351691999939,
                        60.32184479400003
                    ],
                    [
                        -1.374256964999859,
                        60.308417059000035
                    ],
                    [
                        -1.364003058999913,
                        60.28620026200018
                    ],
                    [
                        -1.350005662999877,
                        60.29775625200001
                    ],
                    [
                        -1.336089647999899,
                        60.30662669500005
                    ],
                    [
                        -1.364003058999913,
                        60.32094961100013
                    ],
                    [
                        -1.343495245999861,
                        60.347601630000085
                    ],
                    [
                        -1.336577928999873,
                        60.34345123900012
                    ],
                    [
                        -1.33031165299991,
                        60.342027085000055
                    ],
                    [
                        -1.315541144999941,
                        60.341376044000114
                    ],
                    [
                        -1.328114386999914,
                        60.352687893000095
                    ],
                    [
                        -1.310170050999886,
                        60.35586172100015
                    ],
                    [
                        -1.26036536399991,
                        60.35504791900014
                    ],
                    [
                        -1.284291144999884,
                        60.36351146
                    ],
                    [
                        -1.313832160999937,
                        60.36684804900001
                    ],
                    [
                        -1.337554490999935,
                        60.37445709800015
                    ],
                    [
                        -1.343495245999861,
                        60.39598216399999
                    ],
                    [
                        -1.362538214999859,
                        60.384711005000085
                    ],
                    [
                        -1.373402472999942,
                        60.38108958500011
                    ],
                    [
                        -1.384429490999906,
                        60.38239166900003
                    ],
                    [
                        -1.39500891799986,
                        60.38996002800015
                    ],
                    [
                        -1.398589647999927,
                        60.39960358300014
                    ],
                    [
                        -1.395253058999884,
                        60.40916575700011
                    ],
                    [
                        -1.384429490999906,
                        60.416489976000136
                    ],
                    [
                        -1.384429490999906,
                        60.423325914000046
                    ],
                    [
                        -1.415638800999886,
                        60.423163153000175
                    ],
                    [
                        -1.429107225999871,
                        60.425238348
                    ],
                    [
                        -1.439035610999952,
                        60.430812893
                    ],
                    [
                        -1.436268683999913,
                        60.44318268400015
                    ],
                    [
                        -1.438099738999881,
                        60.46051666900006
                    ],
                    [
                        -1.445952928999873,
                        60.49225495000009
                    ],
                    [
                        -1.459380662999934,
                        60.4728050800001
                    ],
                    [
                        -1.469838019999941,
                        60.46222565300006
                    ],
                    [
                        -1.480091925999943,
                        60.457464911000145
                    ],
                    [
                        -1.495594855999883,
                        60.46088288000006
                    ],
                    [
                        -1.499094204999949,
                        60.46906159100014
                    ],
                    [
                        -1.493072068999936,
                        60.478461005
                    ],
                    [
                        -1.480091925999943,
                        60.48541901200018
                    ],
                    [
                        -1.50023352799991,
                        60.48517487200003
                    ],
                    [
                        -1.516753709999875,
                        60.48261139500012
                    ],
                    [
                        -1.532093878999916,
                        60.48309967700011
                    ],
                    [
                        -1.548410610999952,
                        60.49225495000009
                    ],
                    [
                        -1.557484503999945,
                        60.48065827000009
                    ],
                    [
                        -1.57201087099989,
                        60.47675202000009
                    ],
                    [
                        -1.606678839999859,
                        60.477972723
                    ],
                    [
                        -1.609364386999857,
                        60.48334381700006
                    ],
                    [
                        -1.60415605399993,
                        60.495021877000156
                    ],
                    [
                        -1.595122850999928,
                        60.50678131700012
                    ],
                    [
                        -1.58617102799991,
                        60.51211172100001
                    ],
                    [
                        -1.561512824999909,
                        60.51068756700012
                    ],
                    [
                        -1.555816209999932,
                        60.51211172100001
                    ],
                    [
                        -1.550689256999931,
                        60.5190290390001
                    ],
                    [
                        -1.550200975999928,
                        60.526353257000025
                    ],
                    [
                        -1.550689256999931,
                        60.53339264500006
                    ],
                    [
                        -1.548410610999952,
                        60.539374091000084
                    ],
                    [
                        -1.538726365999878,
                        60.54897695500007
                    ],
                    [
                        -1.526966925999915,
                        60.55683014500015
                    ],
                    [
                        -1.513986782999922,
                        60.557806708000115
                    ],
                    [
                        -1.500559048999918,
                        60.54682038000006
                    ],
                    [
                        -1.488189256999874,
                        60.525824286000116
                    ],
                    [
                        -1.455067511999857,
                        60.50885651200015
                    ],
                    [
                        -1.417469855999855,
                        60.502183335
                    ],
                    [
                        -1.384429490999906,
                        60.51211172100001
                    ],
                    [
                        -1.395741339999887,
                        60.51666901200004
                    ],
                    [
                        -1.410715298999918,
                        60.518622137000094
                    ],
                    [
                        -1.435943162999877,
                        60.518947658000016
                    ],
                    [
                        -1.443186001999891,
                        60.52252838700018
                    ],
                    [
                        -1.451975063999953,
                        60.53017812700001
                    ],
                    [
                        -1.463856574999909,
                        60.53742096600014
                    ],
                    [
                        -1.480091925999943,
                        60.539374091000084
                    ],
                    [
                        -1.471587693999936,
                        60.54645416900014
                    ],
                    [
                        -1.45799719999988,
                        60.563869533000016
                    ],
                    [
                        -1.452788865999878,
                        60.56732819200012
                    ],
                    [
                        -1.441639777999853,
                        60.56879303600009
                    ],
                    [
                        -1.411122199999909,
                        60.580389716000084
                    ],
                    [
                        -1.421457485999923,
                        60.586167710000055
                    ],
                    [
                        -1.425363735999923,
                        60.587836005000085
                    ],
                    [
                        -1.416493292999888,
                        60.59503815300012
                    ],
                    [
                        -1.414051886999857,
                        60.59992096600017
                    ],
                    [
                        -1.415638800999886,
                        60.60382721600017
                    ],
                    [
                        -1.418690558999884,
                        60.60830312700013
                    ],
                    [
                        -1.394154425999943,
                        60.61432526200004
                    ],
                    [
                        -1.366851365999878,
                        60.61225006700012
                    ],
                    [
                        -1.339751756999931,
                        60.604641018000095
                    ],
                    [
                        -1.315541144999941,
                        60.59406159100003
                    ],
                    [
                        -1.312489386999857,
                        60.59829336100016
                    ],
                    [
                        -1.308705206999917,
                        60.60146719000012
                    ],
                    [
                        -1.326161261999886,
                        60.61115143400018
                    ],
                    [
                        -1.32396399599989,
                        60.62132396000011
                    ],
                    [
                        -1.301421678999901,
                        60.64305247599999
                    ],
                    [
                        -1.291411912999934,
                        60.62311432500012
                    ],
                    [
                        -1.290679490999906,
                        60.61513906500015
                    ],
                    [
                        -1.295033331999889,
                        60.60830312700013
                    ],
                    [
                        -1.292103644999969,
                        60.59959544500005
                    ]
                ]
            ],
            [
                [
                    [
                        -1.079904751999976,
                        60.735541083
                    ],
                    [
                        -1.069162563999953,
                        60.718166408000016
                    ],
                    [
                        -1.057606574999852,
                        60.729722398000135
                    ],
                    [
                        -1.036976691999911,
                        60.73314036700005
                    ],
                    [
                        -0.993478969999956,
                        60.73114655200011
                    ],
                    [
                        -0.993153449999852,
                        60.71771881700012
                    ],
                    [
                        -0.994740363999881,
                        60.713609117000075
                    ],
                    [
                        -1.000884568999965,
                        60.71137116100009
                    ],
                    [
                        -1.000884568999965,
                        60.703924872000115
                    ],
                    [
                        -0.995961066999911,
                        60.70246002800015
                    ],
                    [
                        -0.9916072259999,
                        60.69953034100003
                    ],
                    [
                        -0.987172003999916,
                        60.697699286000145
                    ],
                    [
                        -0.997954881999931,
                        60.685614325000145
                    ],
                    [
                        -0.997059699999852,
                        60.67609284100014
                    ],
                    [
                        -0.979725714999859,
                        60.656683661000145
                    ],
                    [
                        -0.98570716099988,
                        60.64590078300013
                    ],
                    [
                        -0.993478969999956,
                        60.63564687700001
                    ],
                    [
                        -1.025868292999888,
                        60.65448639500015
                    ],
                    [
                        -1.042591925999915,
                        60.661566473
                    ],
                    [
                        -1.062326626999948,
                        60.66290924700009
                    ],
                    [
                        -1.050526495999861,
                        60.65558502800009
                    ],
                    [
                        -1.036773240999935,
                        60.64288971600003
                    ],
                    [
                        -1.013905402999853,
                        60.61513906500015
                    ],
                    [
                        -1.02708899599989,
                        60.61009349200013
                    ],
                    [
                        -1.041005011999886,
                        60.60797760600012
                    ],
                    [
                        -1.055287238999881,
                        60.609442450000174
                    ],
                    [
                        -1.069162563999953,
                        60.61513906500015
                    ],
                    [
                        -1.064279751999891,
                        60.60407135600012
                    ],
                    [
                        -1.052072719999899,
                        60.597642320000105
                    ],
                    [
                        -1.036366339999859,
                        60.594712632
                    ],
                    [
                        -1.020741339999859,
                        60.59406159100003
                    ],
                    [
                        -1.022694464999916,
                        60.59072500200001
                    ],
                    [
                        -1.022938605999855,
                        60.589829820000105
                    ],
                    [
                        -1.028187628999945,
                        60.587836005000085
                    ],
                    [
                        -1.011057094999899,
                        60.57770416900003
                    ],
                    [
                        -1.01073157499988,
                        60.56549713700004
                    ],
                    [
                        -1.022531704999949,
                        60.55565013200011
                    ],
                    [
                        -1.041859503999888,
                        60.55304596600003
                    ],
                    [
                        -1.026966925999915,
                        60.53583405200011
                    ],
                    [
                        -1.02245032499988,
                        60.52724844
                    ],
                    [
                        -1.020741339999859,
                        60.51552969
                    ],
                    [
                        -1.023793097999942,
                        60.502997137000094
                    ],
                    [
                        -1.031727667999917,
                        60.50067780200014
                    ],
                    [
                        -1.055490688999925,
                        60.50584544500005
                    ],
                    [
                        -1.080067511999943,
                        60.50519440300009
                    ],
                    [
                        -1.092030402999882,
                        60.50771719000004
                    ],
                    [
                        -1.097035285999908,
                        60.51552969
                    ],
                    [
                        -1.100697394999941,
                        60.515041408000016
                    ],
                    [
                        -1.106556769999912,
                        60.51105377800006
                    ],
                    [
                        -1.107736782999922,
                        60.505072333000115
                    ],
                    [
                        -1.097035285999908,
                        60.49843984600007
                    ],
                    [
                        -1.097035285999908,
                        60.49225495000009
                    ],
                    [
                        -1.130523240999963,
                        60.49603913000011
                    ],
                    [
                        -1.153879360999952,
                        60.50836823100015
                    ],
                    [
                        -1.167795376999919,
                        60.53046295800006
                    ],
                    [
                        -1.180043097999885,
                        60.624660549000154
                    ],
                    [
                        -1.171376105999912,
                        60.6459821640001
                    ],
                    [
                        -1.138050910999908,
                        60.649237372000144
                    ],
                    [
                        -1.138050910999908,
                        60.64305247599999
                    ],
                    [
                        -1.141021287999934,
                        60.63117096600014
                    ],
                    [
                        -1.130116339999859,
                        60.61933014500009
                    ],
                    [
                        -1.114491339999859,
                        60.612453518000066
                    ],
                    [
                        -1.103423631999931,
                        60.61513906500015
                    ],
                    [
                        -1.106556769999912,
                        60.619777736000074
                    ],
                    [
                        -1.120594855999855,
                        60.64618561400006
                    ],
                    [
                        -1.123768683999913,
                        60.65981679900001
                    ],
                    [
                        -1.120961066999882,
                        60.666408596000096
                    ],
                    [
                        -1.11148027299987,
                        60.68113841399999
                    ],
                    [
                        -1.113921678999901,
                        60.684027411000116
                    ],
                    [
                        -1.119536912999905,
                        60.68789297100004
                    ],
                    [
                        -1.120432094999899,
                        60.696926174000126
                    ],
                    [
                        -1.118031378999945,
                        60.70722077000015
                    ],
                    [
                        -1.113921678999901,
                        60.71474844
                    ],
                    [
                        -1.103993292999888,
                        60.72577545800006
                    ],
                    [
                        -1.09211178299995,
                        60.73533763200014
                    ],
                    [
                        -1.079904751999976,
                        60.735541083
                    ]
                ]
            ],
            [
                [
                    [
                        -0.775786912999934,
                        60.791571356000034
                    ],
                    [
                        -0.795765753999916,
                        60.78563060100002
                    ],
                    [
                        -0.808461066999882,
                        60.77863190300012
                    ],
                    [
                        -0.801665818999879,
                        60.76654694200012
                    ],
                    [
                        -0.801665818999879,
                        60.75910065300003
                    ],
                    [
                        -0.821400519999884,
                        60.758530992000075
                    ],
                    [
                        -0.814564581999889,
                        60.75714752800009
                    ],
                    [
                        -0.8080134759999,
                        60.75511302300008
                    ],
                    [
                        -0.801665818999879,
                        60.75234609600001
                    ],
                    [
                        -0.801665818999879,
                        60.74481842700014
                    ],
                    [
                        -0.842762824999852,
                        60.72402578300016
                    ],
                    [
                        -0.850005662999877,
                        60.718166408000016
                    ],
                    [
                        -0.843006964999887,
                        60.70600006700012
                    ],
                    [
                        -0.828846808999884,
                        60.694891669000086
                    ],
                    [
                        -0.821888800999886,
                        60.68500397300009
                    ],
                    [
                        -0.836415167999917,
                        60.67658112200003
                    ],
                    [
                        -0.84618079299986,
                        60.67597077000009
                    ],
                    [
                        -0.854481574999852,
                        60.678290106000034
                    ],
                    [
                        -0.862212693999936,
                        60.681586005000085
                    ],
                    [
                        -0.870472785999937,
                        60.684027411000116
                    ],
                    [
                        -0.890126105999883,
                        60.6849632830001
                    ],
                    [
                        -0.904693162999905,
                        60.684027411000116
                    ],
                    [
                        -0.934803839999859,
                        60.68211497600005
                    ],
                    [
                        -0.950306769999969,
                        60.68431224200005
                    ],
                    [
                        -0.96617591099988,
                        60.69017161699999
                    ],
                    [
                        -0.961333787999905,
                        60.700018622000115
                    ],
                    [
                        -0.959950324999852,
                        60.709418036000145
                    ],
                    [
                        -0.961822068999908,
                        60.717922268000066
                    ],
                    [
                        -0.96617591099988,
                        60.725002346000124
                    ],
                    [
                        -0.948597785999965,
                        60.71796295800006
                    ],
                    [
                        -0.943226691999911,
                        60.726019598
                    ],
                    [
                        -0.945790167999917,
                        60.74030182500009
                    ],
                    [
                        -0.952463344999956,
                        60.75234609600001
                    ],
                    [
                        -0.935699022999927,
                        60.75600820500016
                    ],
                    [
                        -0.926136847999942,
                        60.76959870000012
                    ],
                    [
                        -0.926177537999934,
                        60.78465403900013
                    ],
                    [
                        -0.938832160999908,
                        60.79266998900006
                    ],
                    [
                        -0.938832160999908,
                        60.80011627800015
                    ],
                    [
                        -0.923085089999859,
                        60.804103908000044
                    ],
                    [
                        -0.904164191999939,
                        60.813950914000046
                    ],
                    [
                        -0.890533006999902,
                        60.82558828300016
                    ],
                    [
                        -0.891021287999877,
                        60.83486562700001
                    ],
                    [
                        -0.881499803999901,
                        60.84503815300015
                    ],
                    [
                        -0.870106574999909,
                        60.8452009140001
                    ],
                    [
                        -0.86034094999988,
                        60.8375511740001
                    ],
                    [
                        -0.856190558999856,
                        60.82396067900014
                    ],
                    [
                        -0.86034094999988,
                        60.81293366100009
                    ],
                    [
                        -0.870187954999892,
                        60.806382554
                    ],
                    [
                        -0.881662563999953,
                        60.800848700000174
                    ],
                    [
                        -0.891021287999877,
                        60.79266998900006
                    ],
                    [
                        -0.888335740999906,
                        60.789740302000055
                    ],
                    [
                        -0.876128709999932,
                        60.79266998900006
                    ],
                    [
                        -0.861968553999901,
                        60.79824453300007
                    ],
                    [
                        -0.853138800999943,
                        60.803534247000144
                    ],
                    [
                        -0.83177649599989,
                        60.84027741100006
                    ],
                    [
                        -0.822132941999911,
                        60.84788646
                    ],
                    [
                        -0.815581834999932,
                        60.84308502800009
                    ],
                    [
                        -0.809722459999904,
                        60.84170156500012
                    ],
                    [
                        -0.795480923999889,
                        60.841009833000086
                    ],
                    [
                        -0.782785610999923,
                        60.83820221600003
                    ],
                    [
                        -0.77183997299997,
                        60.83340078300013
                    ],
                    [
                        -0.76390540299991,
                        60.82542552299999
                    ],
                    [
                        -0.760731574999852,
                        60.81313711100013
                    ],
                    [
                        -0.768788214999887,
                        60.81268952000012
                    ],
                    [
                        -0.787993943999936,
                        60.80695221600017
                    ],
                    [
                        -0.768544074999937,
                        60.80097077000015
                    ],
                    [
                        -0.760731574999852,
                        60.80011627800015
                    ],
                    [
                        -0.775786912999934,
                        60.791571356000034
                    ]
                ]
            ]
        ]
    }
};
