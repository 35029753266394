import React from 'react';
import Switch from 'react-switch';

import styles from './Toggle.module.scss';
import { TooltipList } from '../tooltip';

interface ToggleProps {
    checked: boolean;
    onChange: (checked: boolean, e: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>, id: string) => void;
    testId?: string;
    disabled?: boolean;
    disabledTooltip?: string[] | null;
    height?: number;
    width?: number;
    boxShadowColour?: string;
    onColour?: string;
}

export const Toggle: React.FC<ToggleProps> = ({ checked, onChange, testId = '', disabled = false, height = 18, width = 36, boxShadowColour = styles.grey, onColour = '#5390d9', disabledTooltip = null }) => {
    return (
        <TooltipList overlayText={disabledTooltip} color='disabled' trigger='click' showBulletPoints={false}>
            <div className={styles.toggleWrapper}>
                <Switch
                    checked={checked}
                    onChange={onChange}
                    height={height}
                    width={width}
                    handleDiameter={height}
                    onColor={onColour}
                    boxShadow={`inset 0 0 2px 1px ${boxShadowColour}`}
                    activeBoxShadow={`0 0 2px 2px ${styles.french}`}
                    data-testid={`${testId}-toggle`}
                    disabled={disabled}
                />
            </div>
        </TooltipList>
    );
};
