import { CurrentAgreementType, ClientDocumentGroup, DocumentNameDB } from '../components/admin/documents/store';
import { ArkDocument, LinkedDocument } from '../components/documents/my-documents/store';
import { SearchFilter, SmartSearch } from '../components/documents/search/store';
import { TableFilters } from '../components/shared/modal/TableFilterModal';
import { ColumnSort } from '../components/shared/table/ArkTable';
import { api } from './services';

type UploadDocumentsRequest = FormData;
type SearchDocumentsRequest = { searchTerm: string | SmartSearch[]; page: number; filters: SearchFilter; };
type LinkDocumentRequest = { documentId: number; linkedDocumentId: number; pageNumber: number; filters: TableFilters; columnSort: ColumnSort | undefined; pageSize: number; };
type ExportCSVRequest = { documentNameId: number; documentId: number; datasetInstanceId?: number; };
type ExportCDMRequest = { documentId: number; };
type DeleteSecondaryDocumentRequest = { documentId: number; parentDatasetId: number; };
interface FetchAllRequest { pageNumber: number; filters: TableFilters; columnSort: ColumnSort | undefined; pageSize: number; }
interface FetchByAgreementTypeRequest extends FetchAllRequest { agreementTypeId: number; }
interface DeleteDocumentRequest extends FetchAllRequest { location: string; incomplete: boolean; preExecution: boolean; }

export const uploadDocuments = async (reqBody: UploadDocumentsRequest) => await api('document/upload', reqBody, 'post', {}, true);
export const uploadDocumentInAnalysis = async (reqBody: UploadDocumentsRequest) => await api('document/secondary/upload', reqBody, 'post', {}, true);

export const updateDocumentDetails = async (reqBody: ArkDocument) => await api('document/updateDetails', { ...reqBody }, 'post');

export const renameDocumentDescriptions = async (reqBody: { documents: LinkedDocument[]; }) => await api('document/updateDescriptions', { ...reqBody }, 'post');

export const fetchAllOriginalDocuments = async (reqBody: FetchAllRequest) => await api('document/getAll', { ...reqBody }, 'post');
export const fetchAllBaseDocuments = async (reqBody: FetchAllRequest) => await api('document/getBaseDocuments', { ...reqBody }, 'post');
export const fetchDocumentsByAgreementType = async (reqBody: FetchByAgreementTypeRequest) => await api('document/getAllByAgreementType', { ...reqBody }, 'post');
export const analyzeDocument = async (reqBody: { location: string; }) => await api('document/analyze', { ...reqBody }, 'post');
export const completeInitialAI = async (reqBody: { documentId: number; }) => await api('document/initialAI', { ...reqBody }, 'post');
export const deleteDocument = async (reqBody: DeleteDocumentRequest) => await api('document/delete', { ...reqBody }, 'post');
export const deleteSecondaryDocument = async (reqBody: DeleteSecondaryDocumentRequest) => await api('document/secondary/delete', { ...reqBody }, 'post');
export const openDocument = async (reqBody: { location: string }) => await api('document/open', { ...reqBody }, 'post', {}, false, true);
export const downloadDocument = async (reqBody: { location: string }) => await api('document/download', { ...reqBody }, 'post', {}, false, true);
export const getDocumentById = async (reqBody: { documentId: number; datasetInstanceId?: string; }) => await api('document/getById', { ...reqBody }, 'post');
export const searchDocuments = async (reqBody: SearchDocumentsRequest) => await api('document/search', { ...reqBody }, 'post');
export const smartSearchDocuments = async (reqBody: SearchDocumentsRequest) => await api('document/smartSearch', { ...reqBody }, 'post');
export const downloadAllDocuments = async (reqBody: { locations: string[] }) => await api('document/downloadAll', { ...reqBody }, 'post', {}, false, true);
export const linkDocumentToOriginal = async (reqBody: LinkDocumentRequest) => await api('document/linkToOriginal', { ...reqBody }, 'post');
export const fetchDocumentsLinkedToOriginalDocument = async (reqBody: { documentId: number }) => await api('document/getLinkedDocuments', { ...reqBody }, 'post');

export const fetchMLData = async (reqBody: { documentId: number; }) => await api('document/mlData', { ...reqBody }, 'post');
export const updateVersionConfirmed = async (reqBody: { versionConfirmed: number; documentId: number; }) => await api('document/mlData/versionConfirmed/update', { ...reqBody }, 'post');
export const runLatestMLQueries = async (reqBody: { documentId: number; }) => await api('document/mlData/runLatest', { ...reqBody }, 'post');
export const fetchMLReadyAgreementTypeIds = async () => await api('document/mlData/mlAgreementTypes', {}, 'get');

export const fetchAllDocumentNames = async () => await api('document/names/getAll', {}, 'get');
export const fetchAllAgreementTypes = async () => await api('document/names/agreementType/getAll', {}, 'get');
export const fetchAvailableDocumentNames = async () => await api('document/names/getAvailable', {}, 'get');
export const updateDocumentName = async (reqBody: DocumentNameDB) => await api('document/names/update', reqBody, 'post');
export const updateAgreementTypes = async (reqBody: { updatedAgreementTypes: CurrentAgreementType[]; }) => await api('document/names/agreementType/upsert', reqBody, 'post');

export const fetchAllIncompleteDocuments = async (reqBody: FetchAllRequest) => await api('document/incomplete/getAll', { ...reqBody }, 'post');

export const fetchAllPreExecutionDocuments = async (reqBody: FetchAllRequest) => await api('document/preExecution/getAll', { ...reqBody }, 'post');

export const exportDocumentToCSV = async (reqBody: ExportCSVRequest) => await api('document/export/csv', { ...reqBody }, 'post', {}, false, true);
export const exportAllToCSV = async (reqBody: { agreementTypeId: number; }) => await api('document/exportAll/csv', { ...reqBody }, 'post', {}, false, true);

export const exportDocumentToCDM = async (reqBody: ExportCDMRequest) => await api('document/export/cdm', { ...reqBody }, 'post', {});

export const fetchAllDocumentGroups = async () => await api('document/names/group/getAll', {}, 'get');
export const upsertClientDocumentGroup = async (reqBody: ClientDocumentGroup) => await api('document/names/group/upsert', { ...reqBody }, 'post');
export const deleteClientDocumentGroup = async (reqBody: { clientDocumentGroupId: number }) => await api('document/names/group/delete', { ...reqBody }, 'post');
