import { isEqual, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Toggle } from '../../../shared/toggle';
import { SingleField } from '../fields/SingleField';
import { addOpinionFieldCustomChild, createToggleField, getCurrentInstanceType, getFieldSignOffNotes, getIsFirstTimelineEntry, getOpenFieldsAndSections, toggleOpenFieldSection, updateCustomToggleLabel, updateSingleToggleValue } from '../store';
import { OpinionInstanceType, TransactionCoverage, UpdatedOpinionField } from '../store/types';
import { SectionWrapper } from './SectionWrapper';
import styles from './OpinionSection.module.scss';
import { OverflowTooltip } from '../../../shared/tooltip';
import { SignOffConversationIcon } from '../sign-off';

const { lightGrey, amethyst, grey, french } = styles;

interface TransactionCoverageProps {
    transactionCoverage: TransactionCoverage;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const TransactionCoverageSection: React.FC<TransactionCoverageProps> = ({ transactionCoverage, isEditing, isUpdating, fieldsUpdated }) => {
    const [autoFocus, setAutoFocus] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const isFirstTimelineEntry = useAppSelector(getIsFirstTimelineEntry);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'transactionCoverage';
    const { standardOptions, customOptions, other } = transactionCoverage;
    const opinionType = useAppSelector(getCurrentInstanceType);
    const isNettingOpinion = opinionType === OpinionInstanceType.NETTING;

    const signOffConversation = useAppSelector(getFieldSignOffNotes(sectionId));

    const updateValue = (fieldId: 'standardOptions' | 'customOptions', value: boolean, index: number) => dispatch(updateSingleToggleValue(sectionId, fieldId, index, value));

    const fieldUpdatedByInstance = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number) => !!fieldsUpdated.find(field => isEqual({ fieldId: field.fieldId, sectionId: field.sectionId, index: field.index }, { fieldId, sectionId, index })), [fieldsUpdated, sectionId]);
    const getIsDisabled = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number) => !isEditing && !(isUpdating && fieldUpdatedByInstance(fieldId, index)), [isUpdating, isEditing, fieldUpdatedByInstance]);
    const shouldShowFieldUpdated = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number) => fieldUpdatedByInstance(fieldId, index) && !isUpdating && !isEditing && !isFirstTimelineEntry, [isUpdating, isEditing, fieldUpdatedByInstance, isFirstTimelineEntry]);

    const disabled = !isEditing && !isUpdating;

    const updateAll = (checked: boolean) => {
        standardOptions.filter(({ label }) => label !== 'Other').forEach((_, index) => updateValue('standardOptions', checked, index));
        customOptions.forEach((_, index) => updateValue('customOptions', checked, index));
    };

    const getOptionWrapperStyle = useCallback((index: number) => {
        const totalEntries = standardOptions.length + customOptions.length;
        const numberOfLastRowEntries = totalEntries % 3 || 3;
        const lastRowEntries = Array(numberOfLastRowEntries).fill(totalEntries - numberOfLastRowEntries).map((val, i) => val + i);
        const isLastRow = lastRowEntries.includes(index);
        const isFirstColumn = ((index + 1) % 3) === 1;
        if (!isLastRow) {
            return !isFirstColumn ? { width: 'calc((100% / 3) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}`, borderBottom: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)', borderBottom: `1px solid ${lightGrey}` };
        }
        return !isFirstColumn ? { width: 'calc((100% / 3) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)' };
    }, [standardOptions.length, customOptions.length]);

    const boxShadowColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return grey;
    }, []);

    const onColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return french;
    }, []);

    const updateCustomLabel = useCallback((value: string, index: number) => dispatch(updateCustomToggleLabel(sectionId, index, value)), [dispatch, sectionId]);

    const addCustomOption = useCallback(() => {
        const newField = createToggleField('');
        const newCustomOptions = [...customOptions, newField];
        dispatch(addOpinionFieldCustomChild(sectionId, newCustomOptions));
        setAutoFocus(true);
    }, [dispatch, sectionId, customOptions]);

    const allSelected = useMemo(() => [...standardOptions, ...customOptions].filter(({ label }) => label !== 'Other').map(({ value }) => value).every(value => !!value), [standardOptions, customOptions]);

    const customIndexOffset = useMemo(() => standardOptions.length, [standardOptions]);

    const nettingEngineTooltip = 'Any products selected in this section are considered valid in the Netting Engine analysis';

    return (
        <SectionWrapper id={sectionId} label='Transaction Coverage' isEditing={isEditing} isUpdating={isUpdating} isNettingEngineField={isNettingOpinion} nettingEngineTooltip={nettingEngineTooltip}>
            <div className={styles.transactionCoverageSectionWrapper}>
                <div className={styles.transactionCoverageWrapper}>
                    <div className={styles.transactionCoverageHeaderWrapper}>
                        <div className={styles.selectAllWrapper}>
                            <div className={styles.selectAllLabel}>Select All</div>
                            <Toggle onChange={updateAll} checked={allSelected} disabled={disabled} />
                        </div>
                        <div className={styles.addOptionWrapper}>
                            <div className={styles.addOptionLabel}>Add another option</div>
                            <div className={styles.addOptionButton}><PlusButton onClick={addCustomOption} fontSize={16} disabled={disabled} /></div>
                            {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={sectionId} />}
                        </div>
                    </div>
                    <div className={styles.transactionCoverageOptionsWrapper} data-testid={'transaction-coverage-standard-options-wrapper'}>
                        {standardOptions.map(({ value, label }, index) => {
                            const fieldId = 'standardOptions';
                            const isDisabled = getIsDisabled(fieldId, index);
                            const showFieldUpdated = shouldShowFieldUpdated(fieldId, index);
                            const optionWrapperStyle = getOptionWrapperStyle(index);
                            return (
                                <div className={styles.toggleOptionWrapper} key={index} style={optionWrapperStyle}>
                                    <div className={styles.transactionCoverageLabel}>
                                        <OverflowTooltip overlayText={label} trigger='click' />
                                    </div>
                                    <Toggle
                                        onChange={checked => updateValue(fieldId, checked, index)}
                                        checked={value}
                                        disabled={isDisabled}
                                        boxShadowColour={boxShadowColour(showFieldUpdated)}
                                        onColour={onColour(showFieldUpdated)}
                                    />
                                </div>
                            );
                        })}
                        {customOptions.map(({ label, value }, index) => {
                            const fieldId = 'customOptions';
                            const isDisabled = getIsDisabled(fieldId, index);
                            const showFieldUpdated = shouldShowFieldUpdated(fieldId, index);
                            const optionWrapperStyle = getOptionWrapperStyle(customIndexOffset + index);
                            return (
                                <div key={index} className={styles.toggleOptionWrapper} style={optionWrapperStyle}>
                                    {disabled ?
                                        <div className={styles.transactionCoverageLabel}>
                                            <OverflowTooltip overlayText={label} trigger='click' />
                                        </div>
                                        : <input
                                            className={styles.toggleOptionInput}
                                            value={label}
                                            onChange={e => updateCustomLabel(e.target.value, index)}
                                            data-testid={`transaction-coverage-custom-option-input-${index}`}
                                            autoFocus={autoFocus}
                                        />
                                    }
                                    <Toggle
                                        onChange={checked => updateValue(fieldId, checked, index)}
                                        checked={value}
                                        disabled={isDisabled}
                                        boxShadowColour={boxShadowColour(showFieldUpdated)}
                                        onColour={onColour(showFieldUpdated)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <SingleField
                    field={other}
                    fieldId='other'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
            </div>
        </SectionWrapper>
    );
};
