import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { OtherIssues, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface OtherIssuesProps {
    otherIssues: OtherIssues;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const OtherIssuesSection: React.FC<OtherIssuesProps> = ({ otherIssues, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'otherIssues';
    const { otherKeyIssues } = otherIssues;

    return (
        <SectionWrapper id={sectionId} label='Other Issues' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.otherIssuesSectionWrapper}>
                <SingleField
                    field={otherKeyIssues}
                    fieldId='otherKeyIssues'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    singleSectionField
                />
            </div>
        </SectionWrapper>
    );
};
