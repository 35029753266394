import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DeleteButton } from '../../shared/button/DeleteButton';
import { PlusButton } from '../../shared/button/PlusButton';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Text } from '../../shared/text/Text';
import { OverflowTooltip } from '../../shared/tooltip';
import styles from './DocumentName.module.scss';
import { addNewAgreementType, getAgreementTypesAreUpdating, getAgreementTypesHaveUpdated, getCurrentAgreementTypes, removeNewAgreementType, updateAgreementTypeName, updateAgreementTypesStarted } from './store';

const { red, primary } = styles;

interface AgreementTypesModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

export const AgreementTypesModal: React.FC<AgreementTypesModalProps> = ({ isOpen, closeModal }) => {
    const dispatch = useAppDispatch();
    const agreementTypes = useAppSelector(getCurrentAgreementTypes);
    const haveUpdated = useAppSelector(getAgreementTypesHaveUpdated);
    const isSaving = useAppSelector(getAgreementTypesAreUpdating);

    const scrollToLatest = useCallback((index: number) => {
        const agreementTypeInput = document.getElementById(`agreement-type-${index}`);
        agreementTypeInput?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, []);

    const updateName = useCallback((index: number, name: string) => dispatch(updateAgreementTypeName(index, name)), [dispatch]);
    const addAgreementType = useCallback(() => {
        const newIndex = agreementTypes.length;
        dispatch(addNewAgreementType());
        setTimeout(() => scrollToLatest(newIndex), 150);
    }, [dispatch, agreementTypes.length, scrollToLatest]);
    const removeAgreementType = useCallback((index: number) => dispatch(removeNewAgreementType(index)), [dispatch]);

    const validateAgreementType = useCallback((index: number) => {
        const agreementType = agreementTypes.find((_, i) => i === index);
        if (agreementType) {
            const otherAgreementTypeNames = agreementTypes.filter((_, i) => i !== index).map(({ name }) => name);
            return agreementType.name.length > 0 && !otherAgreementTypeNames.includes(agreementType.name);
        }
        return false;
    }, [agreementTypes]);

    const allAgreementTypesValid = useMemo(() => agreementTypes.every((_, index) => validateAgreementType(index)), [agreementTypes, validateAgreementType]);
    const saveDisabled = useMemo(() => !allAgreementTypesValid || !haveUpdated || isSaving, [allAgreementTypesValid, haveUpdated, isSaving]);
    const saveDisabledTooltip = useMemo(() => !allAgreementTypesValid ? ['Some of the agreement types have failed validation'] : [], [allAgreementTypesValid]);

    const updateAgreementTypes = useCallback(() => dispatch(updateAgreementTypesStarted()), [dispatch]);

    if (!isOpen) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel='Save'
            confirmDisabled={saveDisabled}
            confirmDisabledTooltip={saveDisabledTooltip}
            testId='agreement-types'
            confirm={updateAgreementTypes}
            closeLabel='Close'
        >
            <div className={styles.agreementTypesModalWrapper} data-testid='agreement-types-modal-wrapper'>
                <div className={styles.modalHeader}>
                    <OverflowTooltip testId='agreement-types-header' overlayText='Agreement Types' className={styles.modalHeaderLabel} />
                    <PlusButton onClick={addAgreementType} fontSize={25} />
                </div>
                <div className={styles.agreementTypesWrapper}>
                    <Scrollable>
                        {agreementTypes.map(({ agreementTypeId, name }, index) => {
                            const fieldIsValid = validateAgreementType(index);
                            return (
                                <div className={styles.agreementType} key={index} id={`agreement-type-${index}`}>
                                    <div className={styles.agreementTypeInput} style={{ width: isNull(agreementTypeId) ? 'calc(100% - 25px)' : '100%' }}>
                                        <Text
                                            value={name}
                                            onChange={e => updateName(index, e.target.value)}
                                            testId={`agreement-type-${index}`}
                                            maxLength={200}
                                            marginBottom='0'
                                            borderColour={fieldIsValid ? primary : red}
                                        />
                                    </div>
                                    {isNull(agreementTypeId) &&
                                        <div className={styles.agreementTypeRemove}><DeleteButton onClick={() => removeAgreementType(index)} fontSize={20} /></div>
                                    }
                                </div>
                            );
                        })}
                    </Scrollable>
                </div>
            </div>
        </ConfirmationModal>
    );
};
