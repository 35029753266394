import { action } from 'typesafe-actions';

import { AdminAIActionTypes, AIAgreementType, DocumentQuery, PortfolioTotal, SystemIdInfo, SystemIdInfoUI } from './types';
import { FieldValue } from '../../../datasets/instances/store';

export const fetchAIAgreementTypesStarted = () => action(AdminAIActionTypes.FETCH_AI_AGREEMENT_TYPES_STARTED);
export const fetchAIAgreementTypesSuccessful = (agreementTypes: AIAgreementType[], portfolioTotal: PortfolioTotal) => action(AdminAIActionTypes.FETCH_AI_AGREEMENT_TYPES_SUCCESSFUL, { agreementTypes, portfolioTotal });
export const fetchAIAgreementTypesFailed = (e: string) => action(AdminAIActionTypes.FETCH_AI_AGREEMENT_TYPES_FAILED, e);

export const fetchAgreementTypeQueriesStarted = (agreementTypeId: number, shouldRedirect = true) => action(AdminAIActionTypes.FETCH_AGREEMENT_TYPE_QUERIES_STARTED, { agreementTypeId, shouldRedirect });
export const fetchAgreementTypeQueriesSuccessful = (queries: DocumentQuery[], systemInfo: SystemIdInfo[]) => action(AdminAIActionTypes.FETCH_AGREEMENT_TYPE_QUERIES_SUCCESSFUL, { queries, systemInfo });
export const fetchAgreementTypeQueriesFailed = (e: string) => action(AdminAIActionTypes.FETCH_AGREEMENT_TYPE_QUERIES_FAILED, e);

export const setSelectedAIAgreementType = (agreementTypeId: number | null, shouldRedirect = true) => action(AdminAIActionTypes.SET_SELECTED_AI_AGREEMENT_TYPE, { agreementTypeId, shouldRedirect });

export const setQueryOrder = (queryOrder: DocumentQuery[]) => action(AdminAIActionTypes.SET_QUERY_ORDER, queryOrder);

export const saveQueryOrderStarted = () => action(AdminAIActionTypes.SAVE_QUERY_ORDER_STARTED);
export const saveQueryOrderSuccessful = (queries: DocumentQuery[]) => action(AdminAIActionTypes.SAVE_QUERY_ORDER_SUCCESSFUL, queries);
export const saveQueryOrderFailed = (e: string) => action(AdminAIActionTypes.SAVE_QUERY_ORDER_FAILED, e);

export const addNewDocumentQuery = () => action(AdminAIActionTypes.ADD_NEW_DOCUMENT_QUERY);

export const updateDocumentQuery = (queryOrder: number, key: keyof DocumentQuery, value: string | number | number[] | FieldValue) => action(AdminAIActionTypes.UPDATE_DOCUMENT_QUERY, { queryOrder, key, value });

export const updateQuerySystemInfo = (queryOrder: number, value: SystemIdInfoUI) => action(AdminAIActionTypes.UPDATE_DOCUMENT_QUERY_SYSTEM_INFO, { queryOrder, value });

export const deleteDocumentQuery = (queryOrder: number) => action(AdminAIActionTypes.DELETE_DOCUMENT_QUERY, queryOrder);

export const setDocumentQueriesUpdated = (value: boolean) => action(AdminAIActionTypes.SET_DOCUMENT_QUERIES_UPDATED, value);

export const setIsEditingDocumentQueries = (value: boolean) => action(AdminAIActionTypes.SET_IS_EDITING_DOCUMENT_QUERIES, value);

export const saveUpdatedQueriesStarted = () => action(AdminAIActionTypes.SAVE_UPDATED_QUERIES_STARTED);
export const saveUpdatedQueriesSuccessful = (agreementTypes: AIAgreementType[], portfolioTotal: PortfolioTotal, queries: DocumentQuery[]) => action(AdminAIActionTypes.SAVE_UPDATED_QUERIES_SUCCESSFUL, { agreementTypes, portfolioTotal, queries });
export const saveUpdatedQueriesFailed = (e: string) => action(AdminAIActionTypes.SAVE_UPDATED_QUERIES_FAILED, e);

export const setLastUpdatedOrder = (order: number | null) => action(AdminAIActionTypes.SET_LAST_UPDATED_ORDER, order);

export const setAIQueriesSelectedClient = (selectedClientId: number | null) => action(AdminAIActionTypes.SET_AI_QUERIES_SELECTED_CLIENT, { selectedClientId });
