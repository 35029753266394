import React from 'react';

import { IconButton } from '../button/IconButton';
import { ArrowRight } from '../icons';
import { CustomTooltip } from '../tooltip';
import styles from './Table.module.scss';

interface LinkCellParams {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data: any;
    value: string;
    redirect: (rowData: any) => void;
    testId: string;
}

export const LinkCell: React.FC<LinkCellParams> = ({ data, value, testId, redirect }) => (
    <CustomTooltip overlayText={`Go to ${value}`} placement='left'>
        <div className={styles.actionWrapper}>
            <IconButton icon={ArrowRight} onClick={() => redirect(data)} testId={testId} />
        </div>
    </CustomTooltip>
);
