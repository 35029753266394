import React, { useMemo } from 'react';

import { CreateAttestationScheduler, Scheduler, SchedulerAction, SendEmailScheduler } from '../store';
import { CreateAttestationContent } from './CreateAttestationContent';
import { ScheduleEmailContent } from './ScheduleEmailContent';
import styles from '../Workflow.module.scss';

interface ContentProps {
    event: Scheduler;
    testId: string;
    isEditing: boolean;
}

export const Content: React.FC<ContentProps> = ({ event, testId, isEditing }) => {

    const tabContent = useMemo(() => {
        switch (event.action) {
            case SchedulerAction.CREATE_ATTESTATION:
                return <CreateAttestationContent event={event as CreateAttestationScheduler} isEditing={isEditing} testId={`${testId}-content`} />;
            case SchedulerAction.SEND_EMAIL:
                return <ScheduleEmailContent event={event as SendEmailScheduler} testId={`${testId}-send-email-content`} isEditing={isEditing} />;
            default:
                return null;
        }
    }, [event, testId, isEditing]);

    return (
        <div className={styles.contentTabWrapper} data-testid={`${testId}-content-wrapper`}>
            {tabContent}
        </div>
    );
};

