import React from 'react';

export const Checkbox: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,108.7,108.7,114.1,102.1,114.1z" />
        <g>
            <path fill={color || 'currentColor'} d="M48.9,97.7L48,98.5c-2.5,2.3-6.2,1.8-8.2-0.9l-13-17.3c-2-2.7-1.6-6.8,0.8-9l0.9-0.8c2.5-2.3,6.2-1.8,8.2,0.9l13,17.3C51.7,91.4,51.3,95.4,48.9,97.7z" />
            <path fill={color || 'currentColor'} d="M40,97.5l-0.7-1c-2-2.7-1.6-6.8,0.8-9l49.5-44.9c2.5-2.3,6.2-1.8,8.2,0.9l0.7,1c2,2.7,1.6,6.8-0.8,9L48.2,98.4C45.7,100.6,42,100.3,40,97.5z" />
        </g>
    </svg>
);
