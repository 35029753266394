import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { Position, PositionModal } from '../../shared/modal/PositionModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { OverflowTooltip } from '../../shared/tooltip';
import styles from './DatasetBuilder.module.scss';
import { getPreviousDatasetDefinitions } from './store';

const { grey } = styles;

interface DatasetHistoryModalProps {
    isOpen: boolean;
    closeModal: () => void;
    goBackToDefinition: (index: number) => void;
    position: Position;
}

export const DatasetHistoryModal: React.FC<DatasetHistoryModalProps> = ({ closeModal, isOpen, goBackToDefinition, position: { x, y } }) => {
    const previousDefinitions = useAppSelector(getPreviousDatasetDefinitions);
    const getProvisionLinkBorder = useCallback((index: number) => (previousDefinitions.length > 1) && ((previousDefinitions.length - 1) !== index) ? `1px solid ${grey}` : 'none', [previousDefinitions]);
    const MAX_HEIGHT = 400;
    const PREVIOUS_DATASET_HEIGHT = 30;
    const modalHeight = useMemo(() => previousDefinitions.length * PREVIOUS_DATASET_HEIGHT >= MAX_HEIGHT ? MAX_HEIGHT : previousDefinitions.length * PREVIOUS_DATASET_HEIGHT, [previousDefinitions]);
    const modalPosition = useMemo(() => ({ x, y: y - modalHeight }), [modalHeight, x, y]);
    return (
        <PositionModal
            isOpen={isOpen}
            position={modalPosition}
            height='fit-content'
            width='300px'
            closeModal={closeModal}
            testId='dataset-definition-history'
            overflow='hidden'
            showOverlay
        >
            <div className={styles.datasetHistoryModalWrapper} data-testid='dataset-definition-history-wrapper'>
                <Scrollable maxHeight={`${MAX_HEIGHT}px`}>
                    {previousDefinitions.map(({ datasetId, datasetTitle }, index) => (
                        <div key={datasetId} className={styles.previousDatasetWrapper} style={{ borderBottom: getProvisionLinkBorder(index) }} onClick={() => goBackToDefinition(index)}>
                            <OverflowTooltip overlayText={datasetTitle} className={styles.previousDatasetTitle} />
                        </div>
                    ))}
                </Scrollable>
            </div>
        </PositionModal>
    );
};
