import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DatasetDefinition, DatasetDefinitionDB } from '../../datasets/store';
import { Form } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';
import { deleteDatasetStarted, getIsDeleting } from './store';

interface DeleteDatasetConfirmationModalProps {
    isOpen: boolean;
    definition: DatasetDefinitionDB | DatasetDefinition;
    closeModal: () => void;
}

export const DeleteDatasetConfirmationModal: React.FC<DeleteDatasetConfirmationModalProps> = ({ isOpen, closeModal, definition }) => {
    const dispatch = useAppDispatch();
    const isDeleting = useAppSelector(getIsDeleting);
    const { datasetId, datasetTitle } = definition;
    const deleteDataset = () => dispatch(deleteDatasetStarted(datasetId!));

    const warning = 'This will permanently delete this dataset and remove it from any other datasets it is linked to. This will also affect any active instances which reference this dataset. This cannot be undone.';

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={deleteDataset}
            isDeleting={isDeleting}
            headerLabel='Delete Dataset'
            name={datasetTitle}
            testId='dataset'
            warning={warning}
            icon={Form}
        />
    );
};
