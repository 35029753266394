import React from 'react';
import classnames from 'classnames';

import styles from './Button.module.scss';
import { Icon as ArkIcon } from '../icon/Icon';
import { TooltipList } from '../tooltip';

interface LongPressEvent {
    onMouseDown: (e: React.MouseEvent<Element, globalThis.MouseEvent>) => void;
    onMouseUp: (e: React.MouseEvent<Element, globalThis.MouseEvent>) => void;
    onMouseLeave: (e: React.MouseEvent<Element, globalThis.MouseEvent>) => void;
}

interface ButtonProps {
    label: string;
    longPressProps: LongPressEvent;
    disabled?: boolean;
    icon?: React.FC;
    fontSize?: number;
    color?: string;
    selected?: boolean;
    testId?: string;
    disabledTooltip?: string[] | null;
    width?: string;
}

export const LongPressButton: React.FC<ButtonProps> = ({ label = '', longPressProps, disabled = false, fontSize, icon, color, selected = false, testId = '', disabledTooltip = null, width = '150px' }) => (
    <TooltipList overlayText={disabledTooltip} color='disabled'>
        <div className={styles.buttonWrapper}>
            <button
                {...longPressProps}
                className={classnames(styles.button, {
                    [styles.selectedButton]: selected
                })}
                disabled={disabled}
                data-testid={`button-${testId}`}
                style={{ width }}
            >
                <div className={styles.content} data-testid={`button-${testId}-label`}>
                    {icon && <ArkIcon icon={icon} fontSize={fontSize} color={color} />}
                    <div style={{ marginLeft: icon ? '5px' : '0' }}>{label}</div>
                </div>
            </button>
        </div>
    </TooltipList>
);
