import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../../../hooks/react-redux';
import { DropdownOption } from '../../../../../shared/dropdown/Dropdown';
import { getDefinitionsForDataset } from '../../../store';
import { DocumentDatasetDefinitions } from '../../DocumentDatasetDefinitions';

interface DocumentDatasetFieldProps {
    childDatasetId: string;
    fieldOpen: boolean;
    parents: number;
    hiddenDocumentNameIds: number[] | undefined;
    isPreview: boolean;
    modalInstance?: boolean;
    documentNames: DropdownOption[];
    parentSectionId: string;
}

export const DocumentDatasetField: React.FC<DocumentDatasetFieldProps> = ({
    childDatasetId,
    fieldOpen,
    parents,
    hiddenDocumentNameIds,
    isPreview,
    modalInstance,
    documentNames,
    parentSectionId
}) => {
    const datasetDefinitions = useAppSelector(getDefinitionsForDataset);
    const datasetDefinition = useMemo(() => datasetDefinitions.find(({ datasetId }) => (datasetId === parseInt(childDatasetId) || null)), [datasetDefinitions, childDatasetId]);

    return (
        <div data-testid={`dataset-definition-wrapper-${childDatasetId}`}>
            {fieldOpen && datasetDefinition && <DocumentDatasetDefinitions datasetDefinition={datasetDefinition} parents={parents + 1} modalInstance={modalInstance} hiddenDocumentNameIds={hiddenDocumentNameIds} isPreview={isPreview} documentNames={documentNames} parentSectionId={parentSectionId} />}
        </div>
    );
};
