import { ApplicationState } from '../../../../store/rootReducer';
import { ScheduleEventTab, Scheduler, WorkflowState, SchedulerDB, CalendarEvent, WorkflowPage, ScheduledActionsListView } from './types';

const getRoot = (state: ApplicationState): WorkflowState => state.admin.workflow;

export const getWorkflowPage = (state: ApplicationState): WorkflowPage => getRoot(state).workflowPage;

export const getCalendarWizardOpen = (state: ApplicationState): boolean => getRoot(state).calendarWizardOpen;

export const getCalendarEvent = (state: ApplicationState): Scheduler => getRoot(state).event;

export const getToolbarDate = (state: ApplicationState): Date => getRoot(state).toolbarDate;

export const getIsEditing = (state: ApplicationState): boolean => getRoot(state).isEditing;

export const getScheduledActionUpdated = (state: ApplicationState): boolean => getRoot(state).showUpdatedMessage;

export const getSelectedMonthYear = (state: ApplicationState): string => getRoot(state).selectedMonthYear;

export const getSelectedEventTab = (state: ApplicationState): ScheduleEventTab => getRoot(state).selectedEventTab;

export const getSelectedEventTabValid = (state: ApplicationState): boolean => getRoot(state).selectedTabValid;

export const getScheduledDetailsConfirmed = (state: ApplicationState): boolean => getRoot(state).scheduleDetailsCorrect;

export const getIsSavingScheduledAction = (state: ApplicationState): boolean => getRoot(state).isSaving;

export const getAllScheduledActions = (state: ApplicationState): SchedulerDB[] => getRoot(state).scheduledActions;

export const getScheduledEvents = (state: ApplicationState): CalendarEvent[] => getRoot(state).selectedMonthActions;

export const getConfirmDeleteEvent = (state: ApplicationState): number | null => getRoot(state).confirmDeleteEvent;

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;

export const getScheduledActionsListView = (state: ApplicationState): ScheduledActionsListView => getRoot(state).scheduledActionsListView;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getSelectedEventDate = (state: ApplicationState): string | null => getRoot(state).selectedEventDate;
