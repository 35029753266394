import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Button } from '../../../shared/button/Button';
import styles from '../ClauseTagsModal.module.scss';
import { getAllClientClauses, ClauseTags, toggleDeleteTagModal, CurrentClientTag, getSelectedClientTag, getClientTagModalCanSave, updateSelectedClientTag, saveTagStarted } from '../../store';
import { Text } from '../../../shared/text/Text';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { tagCategoryOptions } from '../../../shared/clause-library/ClauseModalTags';
import { DropdownSummary } from '../../../shared/dropdown/DropdownSummary';

export const SelectedClientTags: React.FC = () => {

    const dispatch = useAppDispatch();
    const selectedTag = useAppSelector(getSelectedClientTag);
    const clientClauses = useAppSelector(getAllClientClauses);
    const canSave = useAppSelector(getClientTagModalCanSave);

    const clientClauseOptions: DropdownOption[] = useMemo(() => clientClauses.map(({ clauseId, title }) => ({ value: clauseId!.toString(), label: title })), [clientClauses]);

    const selectedTagType = useCallback((category: keyof ClauseTags) => tagCategoryOptions.filter(({ value }) => value === category) || null, []);

    const selectTagType = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(option)) {
            const type = (option as DropdownOption).value as keyof ClauseTags;
            dispatch(updateSelectedClientTag('category', type));
        }
    }, [dispatch]);

    const updateCurrentTag = useCallback((value: string) => dispatch(updateSelectedClientTag('tag', value)), [dispatch]);

    const deleteTag = useCallback((tag: CurrentClientTag) => dispatch(toggleDeleteTagModal(tag)), [dispatch]);

    const saveTag = useCallback((selectedTag: CurrentClientTag) => dispatch(saveTagStarted(selectedTag)), [dispatch]);

    const selectedClauses = useMemo(() => !isNull(selectedTag) && selectedTag.includedClauses.length > 0 ? clientClauseOptions.filter(({ value }) => selectedTag.includedClauses.includes(parseInt(value))) : null, [clientClauseOptions, selectedTag]);

    const selectClausesUsingTag = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(dropdownValue) && !isNull(selectedTag) && (dropdownValue as Options<DropdownOption>).length > 0) {
            const includedClauses = (dropdownValue as Options<DropdownOption>).map(({ value }) => parseInt(value));
            dispatch(updateSelectedClientTag('includedClauses', includedClauses));
        } else {
            dispatch(updateSelectedClientTag('includedClauses', []));
        }
    }, [dispatch, selectedTag]);

    if (isNull(selectedTag)) {
        return null;
    }

    return (
        <div className={styles.selectedTagWrapper} data-testid='client-tags-modal-selected-tag-wrapper'>
            <div className={styles.tagInputWrapper} data-testid='client-tags-modal-selected-tag-edit-wrapper'>
                <Text
                    onChange={e => updateCurrentTag(e.target.value)}
                    value={selectedTag.tag}
                    maxLength={256}
                    testId='client-tags-modal-selected-tag-edit'
                    placeholder='Tags...'
                    marginBottom='0px'
                    label='Edit Tag'
                />
            </div>
            <div className={styles.tagInputWrapper} data-testid='client-tags-modal-selected-tag-category-wrapper'>
                <Dropdown
                    value={selectedTagType(selectedTag.category)}
                    onChange={selectTagType}
                    options={tagCategoryOptions}
                    placeholder='Select...'
                    testId='client-tags-modal-selected-tag-category'
                    isClearable
                    label='Tag Category'
                    marginBottom='10px'
                />
            </div>
            <div className={styles.tagInputWrapper} data-testid='client-tags-modal-selected-tag-usage-wrapper'>
                <DropdownSummary
                    value={selectedClauses}
                    onChange={selectClausesUsingTag}
                    options={clientClauseOptions}
                    placeholder='Select...'
                    testId='client-tags-modal-selected-tag-usage'
                    isClearable
                    label='Clauses using this tag'
                    marginBottom='10px'
                    isMulti
                />
            </div>
            <div className={styles.selectedTagButtonWrapper} data-testid='client-tags-modal-selected-tag-buttons-wrapper'>
                <Button onClick={() => deleteTag(selectedTag)} label='Delete Tag' testId='client-tags-modal-selected-tag-delete' />
                <Button onClick={() => saveTag(selectedTag)} disabled={!canSave} label='Save Changes' testId='client-tags-modal-selected-tag-save' />
            </div>
        </div>
    );
};
