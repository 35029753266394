import React from 'react';

import { Home } from '../icons';
import styles from './Table.module.scss';
import { Icon } from '../icon/Icon';

interface IconCellParams {
    value: boolean;
    testId: string;
    icon?: React.FC;
}

export const IconCell: React.FC<IconCellParams> = ({ value, testId, icon = Home }) => (
    <div className={styles.iconCellWrapper} data-testid={testId}>
        {value &&
            <div>
                <Icon icon={icon} fontSize={20} />
            </div>
        }
    </div>
);
