import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';

import { useAppDispatch } from '../../../../../../hooks/react-redux';
import { useSingleHiddenFields } from '../../../../../../hooks/useHiddenSingleFields';
import { HiddenFields } from '../../../../../admin/my-datasets/store';
import { PlusButton } from '../../../../../shared/button/PlusButton';
import { Toggle } from '../../../../../shared/toggle';
import { OverflowTooltip } from '../../../../../shared/tooltip';
import { GroupInstanceField, OpenFieldSection, UpdatedFormField, addFieldCustomChild, updateFieldCustomChild, userCorrectAIFieldValue } from '../../../store';
import styles from '../../../../../shared/datasets/SharedStyling.module.scss';
import { MLSingleField } from './MLSingleField';
import { InstanceMLData } from '../../../../store/mlTypes';

const { lightGrey, amethyst, french, grey } = styles;

interface MLMultiToggleProps {
    field: GroupInstanceField;
    groupIndex: number;
    sectionId: string;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedFormField[];
    annexFieldIds: string[];
    isAnnexInstance: boolean;
    datasetId: number;
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean,
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    mlData: InstanceMLData;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    showEntireSection: boolean;
}

export const MLMultiToggle: React.FC<MLMultiToggleProps> = ({
    field,
    groupIndex,
    sectionId,
    isEditing,
    isUpdating,
    fieldsUpdated,
    annexFieldIds,
    isAnnexInstance,
    datasetId,
    parentFieldId,
    instanceExecutedDateMatchesParent,
    modalInstance,
    getSectionOpen,
    toggleSection,
    mlData,
    hiddenFields,
    datasetHidden,
    showEntireSection
}) => {
    const [autoFocus, setAutoFocus] = useState<boolean>(false);
    const { children, customChildren, label } = field;
    const customOptions = useMemo(() => customChildren || [], [customChildren]);
    const dispatch = useAppDispatch();
    const currentHiddenDatasetFields = useMemo(() => hiddenFields && hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);
    const updateValue = (value: boolean, index: number) => dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex));

    const childArray = useMemo(() => children.filter(({ id }) => !hiddenSingleFields.map(({ fieldId }) => fieldId).includes(id!)), [children, hiddenSingleFields]);
    const fieldUpdatedByInstance = useMemo(() => childArray.map(({ id }) => id).some(id => fieldsUpdated.map(({ id }) => id).includes(id!)) && instanceExecutedDateMatchesParent, [fieldsUpdated, childArray, instanceExecutedDateMatchesParent]);
    const showFieldUpdated = fieldUpdatedByInstance && !isUpdating && !isEditing;
    const disabled = !isEditing && !(isUpdating && fieldUpdatedByInstance);

    const updateAll = (checked: boolean) => {
        const childIndexes = childArray.map(({ id }) => children.map(({ id }) => id).indexOf(id));
        childIndexes.forEach(index => updateValue(checked, index));
    };

    const getOptionWrapperStyle = useCallback((index: number) => {
        const isEndColumn = !((index + 1) % 3);
        return !isEndColumn ? { width: 'calc((100% / 3) - 24px)', marginRight: '10px', borderRight: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)' };
    }, []);

    const childFieldHidden = useCallback((id: string) => !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields]);

    const childFieldHasMLQuestion = useCallback((systemId: string) => !!mlData.instanceMLData.find(mlField => mlField.systemId === systemId) || showEntireSection, [mlData, showEntireSection]);

    const boxShadowColour = useMemo(() => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return grey;
    }, [showFieldUpdated]);

    const onColour = useMemo(() => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return french;
    }, [showFieldUpdated]);

    const updateCustomToggleProperty = useCallback((value: boolean | string, index: number, property: 'label' | 'value') => dispatch(updateFieldCustomChild(datasetId, parentFieldId, value, index, sectionId, groupIndex, property)), [dispatch, datasetId, parentFieldId, groupIndex, sectionId]);

    const updateCustomToggleValue = useCallback((value: boolean, index: number) => updateCustomToggleProperty(value, index, 'value'), [updateCustomToggleProperty]);
    const updateCustomToggleLabel = useCallback((value: string, index: number) => updateCustomToggleProperty(value, index, 'label'), [updateCustomToggleProperty]);

    const addCustomOption = useCallback(() => {
        dispatch(addFieldCustomChild(datasetId, parentFieldId, sectionId, groupIndex));
        setAutoFocus(true);
    }, [dispatch, datasetId, parentFieldId, groupIndex, sectionId]);

    const allSelected = useMemo(() => [...childArray, ...customOptions].map(({ value }) => value).every(value => !!value), [childArray, customOptions]);

    return (
        <div className={styles.multiToggleWrapper}>
            <div className={classnames(styles.multiToggleGroupLabel, { [styles.emptyFieldLabel]: !label })}>
                <OverflowTooltip overlayText={label || 'Label...'} />
            </div>
            <div className={styles.multiToggleHeaderWrapper}>
                <div className={styles.selectAllWrapper}>
                    <div className={styles.selectAllLabel}>Select All</div>
                    <Toggle onChange={updateAll} checked={allSelected} disabled={disabled} />
                </div>
                <div className={styles.addOptionWrapper}>
                    <div className={styles.addOptionLabel}>Add another option</div>
                    <div className={styles.addOptionButton}><PlusButton onClick={addCustomOption} fontSize={16} disabled={disabled} /></div>
                </div>
            </div>
            <div className={styles.multiToggleOptionsWrapper} data-testid={`form-preview-multi-toggle-${groupIndex}-wrapper`}>
                {children.map((field, index) => {
                    const childHidden = childFieldHidden(field.id!);
                    const childMlQuestion = childFieldHasMLQuestion(field.systemId);
                    if (childHidden || !childMlQuestion) {
                        return null;
                    }
                    const singleIndex = `${groupIndex}${index}`;
                    const optionWrapperStyle = getOptionWrapperStyle(index);
                    return (
                        <div className={styles.toggleOptionWrapper} key={field.id} style={optionWrapperStyle}>
                            <MLSingleField
                                key={field.id}
                                field={field}
                                index={index}
                                groupIndex={groupIndex}
                                singleIndex={singleIndex}
                                sectionId={sectionId}
                                isEditing={isEditing}
                                isUpdating={isUpdating}
                                fieldsUpdated={fieldsUpdated}
                                annexFieldIds={annexFieldIds}
                                isAnnexInstance={isAnnexInstance}
                                datasetId={datasetId}
                                parentFieldId={parentFieldId}
                                instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                                modalInstance={modalInstance}
                                toggleSection={toggleSection}
                                getSectionOpen={getSectionOpen}
                                mlData={mlData}
                                hiddenFields={hiddenFields}
                                datasetHidden={datasetHidden}
                                isMultiToggle
                                showEntireSection={showEntireSection}
                            />
                        </div>
                    );
                })}
                {customOptions.map(({ label, id, value }, i) => {
                    const displayIndex = childArray.length + i;
                    const optionWrapperStyle = getOptionWrapperStyle(displayIndex);
                    return (
                        <div key={id!} className={styles.toggleOptionWrapper} style={optionWrapperStyle}>
                            {disabled ?
                                <div className={styles.multiToggleLabel}>
                                    <OverflowTooltip overlayText={label} />
                                </div>
                                : <input
                                    className={styles.toggleOptionInput}
                                    value={label}
                                    onChange={e => updateCustomToggleLabel(e.target.value, i)}
                                    data-testid={`multi-toggle-custom-option-input-${i}`}
                                    autoFocus={autoFocus}
                                />
                            }
                            <Toggle
                                onChange={checked => updateCustomToggleValue(checked, i)}
                                checked={value as boolean}
                                disabled={disabled}
                                boxShadowColour={boxShadowColour}
                                onColour={onColour}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
