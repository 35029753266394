import React, { useCallback, useMemo, useState } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import styles from '../ClauseTagsModal.module.scss';
import { Button } from '../../../shared/button/Button';
import { Pencil } from '../../../shared/icons';
import { addSystemTagStarted, ClauseTagDropdownOptions, ClauseTags, deleteTemplateTagStarted, getAllClauseLibraryTags, getDeleteTemplateTagModalOpen, getIsSavingTemplateTag, getTagTerm, getTemplateTagToDelete, setSelectedTemplateTag, toggleDeleteTagModal, updateTagTerm } from '../../store';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { tagCategoryOptions } from '../../../shared/clause-library/ClauseModalTags';
import { Text } from '../../../shared/text/Text';
import { SortOrder } from '../../../shared/table/ArkTable';
import { Spinner } from '../../../shared/spinner/Spinner';
import { DeleteTagConfirmationModal } from '../DeleteTagConfirmationModal';
import { CurrentTemplateTags } from './CurrentTemplateTags';
import { SelectedTemplateTag } from './SelectedTemplateTag';

const { lightGrey } = styles;

interface SystemTagsManagerProps {
    closeModal: () => void;
    clauseLibraryDropdownOptions: ClauseTagDropdownOptions;
}

export const SystemTagsManager: React.FC<SystemTagsManagerProps> = ({ closeModal, clauseLibraryDropdownOptions }) => {
    const dispatch = useAppDispatch();

    const systemTagCategoryOptions = useMemo(() => tagCategoryOptions.filter(option => option.value !== 'jurisdiction'), []);

    const [tagCategory, setTagCategory] = useState<keyof ClauseTags>('miscellaneous');
    const systemTagCategory = useMemo(() => systemTagCategoryOptions.find(({ value }) => value === tagCategory)!, [tagCategory, systemTagCategoryOptions]);
    const selectTagType = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(option)) {
            const type = (option as DropdownOption).value as keyof ClauseTags;
            setTagCategory(type);
        } else {
            setTagCategory('miscellaneous');
        }
        dispatch(setSelectedTemplateTag(null));
    }, [dispatch]);

    const categoryTags = useMemo(() => {
        switch (tagCategory) {
            case 'agreementType':
                return clauseLibraryDropdownOptions.agreementTypeOptions;
            case 'counterpartyType':
                return clauseLibraryDropdownOptions.counterpartyTypeOptions;
            case 'productType':
                return clauseLibraryDropdownOptions.productTypeOptions;
            case 'provisionType':
                return clauseLibraryDropdownOptions.provisionTypeOptions;
            case 'opinions':
                return clauseLibraryDropdownOptions.opinionsOptions;
            case 'miscellaneous':
            default:
                return clauseLibraryDropdownOptions.miscellaneousSystemOptions;
        }
    }, [tagCategory, clauseLibraryDropdownOptions]);

    const [columnOrder, setColumnOrder] = useState<SortOrder | null>(null);
    const [tagFilter, setTagFilter] = useState<string | null>(null);

    const isSaving = useAppSelector(getIsSavingTemplateTag);

    const filteredTags = useMemo(() => {
        let sortedTags = categoryTags;
        if (columnOrder === SortOrder.ASCENDING || isNull(columnOrder)) {
            sortedTags = categoryTags.sort((a, b) => a.label.localeCompare(b.label));
        }
        if (columnOrder === SortOrder.DESCENDING) {
            sortedTags = categoryTags.sort((a, b) => b.label.localeCompare(a.label));
        }
        let filteredTags = sortedTags;
        if (tagFilter && tagFilter !== '') {
            filteredTags = filteredTags.filter(tag => tagFilter.split(' ').some(tagFilter => tag.label.toLowerCase().includes(tagFilter.toLowerCase())));
        }
        return filteredTags;
    }, [categoryTags, columnOrder, tagFilter]);

    const filtersExist = useMemo(() => !isNull(columnOrder) || !isNull(tagFilter), [tagFilter, columnOrder]);

    const clearFilters = useCallback(() => {
        setColumnOrder(null);
        setTagFilter(null);
    }, []);

    const deleteConfirmationOpen = useAppSelector(getDeleteTemplateTagModalOpen);
    const tagToDelete = useAppSelector(getTemplateTagToDelete);
    const closeConfirmDeleteModal = () => dispatch(toggleDeleteTagModal(null));
    const deleteTag = () => {
        dispatch(deleteTemplateTagStarted(tagToDelete!));
        closeConfirmDeleteModal();
    };

    const tagTerm = useAppSelector(getTagTerm);
    const createNewTag = useCallback((value: string) => {
        dispatch(updateTagTerm(value));
    }, [dispatch]);

    const addTemplateTag = useCallback(() => {
        dispatch(addSystemTagStarted(tagTerm, tagCategory!));
    }, [dispatch, tagTerm, tagCategory]);

    const allClauseLibraryTags = useAppSelector(getAllClauseLibraryTags);
    const addTagDisabled = useMemo(() => allClauseLibraryTags.includes(tagTerm.toLowerCase()) || tagTerm === '', [allClauseLibraryTags, tagTerm]);

    if (isSaving) {
        return <Spinner />;
    }

    return (
        <>
            <div className={styles.clauseTagsWrapper} data-testid='template-tags-modal-wrapper'>
                <ModalHeader label='Configure Clause Library Template Tags' icon={Pencil} testId='template-tags-modal' />
                <div className={styles.tagTypeDropdown} data-testid={'template-modal-tags-categories-wrapper'}>
                    <Dropdown
                        value={systemTagCategory}
                        onChange={selectTagType}
                        options={systemTagCategoryOptions}
                        placeholder='Select...'
                        testId='template-modal-tags-category'
                        label='Tag Category'
                        marginBottom='10px'
                        disabled={false}
                    />
                </div>
                <div className={styles.currentTemplateTagsWrapper} data-testid='client-tags-modal-tags-wrapper'>
                    <CurrentTemplateTags
                        tagCategory={tagCategory}
                        columnOrder={columnOrder}
                        setColumnOrder={setColumnOrder}
                        tagFilter={tagFilter}
                        setTagFilter={setTagFilter}
                        filteredTags={filteredTags}
                    />
                    <SelectedTemplateTag />
                </div>
                <div className={styles.totalTagsWrapper} style={{ width: 'calc(60% - 70px)' }} data-testid='client-tags-modal-table-info-wrapper'>
                    <div className={styles.clearAllFiltersWrapper} data-testid='client-tags-modal-table-info-filter-wrapper'>
                        {filtersExist &&
                            <button className={styles.clearAllFilters} onClick={clearFilters} data-testid='client-tags-modal-table-info-filter-button'>Clear All Filters</button>
                        }
                    </div>
                    <div className={styles.totalTags} data-testid='client-tags-modal-table-info-total-label'>Total: {filteredTags.length}</div>
                </div>
                <div className={styles.clientTagInputWrapper} data-testid='client-tags-modal-add-tag-wrapper'>
                    <div className={styles.clientTagModalInput}>
                        <Text
                            onChange={e => createNewTag(e.target.value)}
                            value={tagTerm}
                            maxLength={256}
                            testId='client-tags-modal-add-tag'
                            placeholder='Tags...'
                            marginBottom='0px'
                            borderColour={lightGrey}
                        />
                    </div>
                    <Button onClick={addTemplateTag} label='Add Tag' disabled={addTagDisabled} testId='client-tags-modal-add-tag' />
                </div>
                <div className={styles.closeClientTagsModal} data-testid='template-tags-modal-button-wrapper'>
                    <Button onClick={closeModal} label='Close' testId='template-tags-modal-close' />
                </div>
            </div>
            <DeleteTagConfirmationModal
                isOpen={deleteConfirmationOpen}
                closeModal={closeConfirmDeleteModal}
                confirmDelete={deleteTag}
                tagToDelete={tagToDelete?.tag || ''}
            />
        </>
    );
};
