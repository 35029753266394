import React from 'react';
import { isUndefined } from 'lodash/fp';
import classnames from 'classnames';

import { OverflowTooltip } from '../tooltip';
import styles from './Table.module.scss';
import { ArkTableDocumentEntity } from '../../documents/my-documents/store';
import { Icon } from '../icon/Icon';
import { Eye, Home } from '../icons';

export const EntityCell: React.FC<{ value: ArkTableDocumentEntity; testId?: string; }> = ({ value, testId }) => {
    if (isUndefined(value)) {
        return null;
    }

    const { entityName, entityOnWatchlist, myCompanyEntity } = value;
    const showIcon = entityOnWatchlist === 1 || myCompanyEntity === 1;
    const icon = showIcon && myCompanyEntity === 1 ? Home : Eye;

    return (
        <div className={styles.entityCellWrapper}>
            <OverflowTooltip className={classnames(styles.entityValue, { [styles.entityValueWithIcon]: showIcon })} overlayText={entityName} testId={testId} />
            {showIcon && <Icon icon={icon} fontSize={20} />}
        </div>
    );
};
