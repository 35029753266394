import { isEqual, isNull, isUndefined, uniq } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { OpenFieldType, SingleInstanceField } from '../../../../datasets/instances/store';
import { DatasetField, DatasetFieldType, DatasetSection, FormDatasetDefinition, isGroupField } from '../../../../datasets/store';
import { DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { Icon } from '../../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../../shared/icons';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { InformationTooltip } from '../../../../shared/tooltip';
import { DatasetsOpenFieldSection, DatasetsOpenSection, DocumentSpecificHiddenFields, HiddenDocumentSection, addOpenFieldSection, getOpenFieldsAndSections, removeOpenFieldSection, toggleDocumentHiddenField } from '../../store';
import styles from '../ConfigureDatasets.module.scss';
import { DatasetDocumentSelect } from '../DatasetDocumentSelect';
import { SectionPageRef } from '../SectionPageRef';
import { DocumentDatasetGroupField } from './fields/DocumentDatasetGroupField';
import { DocumentDatasetMultiToggle } from './fields/DocumentDatasetMultiToggleField';
import { DocumentDatasetSingleField } from './fields/DocumentDatasetSingleField';

interface FieldsProps {
    fields: DatasetField[];
    sectionId: string;
    datasetOnlySection?: boolean;
    datasetId: number;
    parents: number;
    toggleSection: (fieldSection: DatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: DatasetsOpenFieldSection) => boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: DocumentSpecificHiddenFields;
    hiddenDocumentNameIds?: number[];
    documentNames: DropdownOption[];
    previewSelectedDocument: number | null;
}

export const Fields: React.FC<FieldsProps> = ({
    fields,
    sectionId,
    datasetOnlySection = false,
    datasetId,
    parents,
    toggleSection,
    getSectionOpen,
    isPreview,
    modalInstance,
    hiddenFields,
    hiddenDocumentNameIds,
    documentNames,
    previewSelectedDocument
}) => {
    const getPreviewContent = useCallback((field: DatasetField, index: number) => {
        if (isGroupField(field)) {
            if (field.type === DatasetFieldType.GROUP) {
                return (
                    <DocumentDatasetGroupField
                        field={field}
                        key={field.id}
                        groupIndex={index}
                        sectionId={sectionId}
                        datasetId={datasetId}
                        parents={parents}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        isPreview={isPreview}
                        modalInstance={modalInstance}
                        hiddenFields={hiddenFields}
                        hiddenDocumentNameIds={hiddenDocumentNameIds}
                        documentNames={documentNames}
                        previewSelectedDocument={previewSelectedDocument}
                    />
                );
            }
            if (field.type === DatasetFieldType.MULTI_TOGGLE) {
                return (
                    <DocumentDatasetMultiToggle
                        field={field}
                        key={field.id}
                        groupIndex={index}
                        sectionId={sectionId}
                        datasetId={datasetId}
                        isPreview={isPreview}
                        hiddenFields={hiddenFields}
                        hiddenDocumentNameIds={hiddenDocumentNameIds}
                        documentNames={documentNames}
                        previewSelectedDocument={previewSelectedDocument}
                    />
                );
            }
        }
        return (
            <DocumentDatasetSingleField
                field={field as SingleInstanceField}
                key={field.id}
                sectionId={sectionId}
                datasetOnlySection={datasetOnlySection}
                datasetId={datasetId}
                parents={parents}
                toggleSection={toggleSection}
                getSectionOpen={getSectionOpen}
                isPreview={isPreview}
                modalInstance={modalInstance}
                hiddenFields={hiddenFields}
                hiddenDocumentNameIds={hiddenDocumentNameIds}
                documentNames={documentNames}
                previewSelectedDocument={previewSelectedDocument}
            />
        );
    }, [
        sectionId,
        datasetOnlySection,
        datasetId,
        parents,
        toggleSection,
        getSectionOpen,
        isPreview,
        modalInstance,
        hiddenFields,
        hiddenDocumentNameIds,
        documentNames,
        previewSelectedDocument
    ]);

    return (
        <div className={styles.allFieldsWrapper}>
            {fields.map((field, index) => getPreviewContent(field, index))}
        </div>
    );
};

interface SectionProps {
    section: DatasetSection;
    formDefinition: FormDatasetDefinition;
    datasetId: number;
    parents: number;
    toggleSection: (fieldSection: DatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: DatasetsOpenFieldSection) => boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: DocumentSpecificHiddenFields;
    hiddenDocumentNameIds?: number[];
    documentNames: DropdownOption[];
    previewSelectedDocument: number | null;
}

export const Section: React.FC<SectionProps> = ({
    section,
    formDefinition,
    datasetId,
    parents,
    toggleSection,
    getSectionOpen,
    isPreview,
    modalInstance,
    hiddenFields,
    hiddenDocumentNameIds,
    documentNames,
    previewSelectedDocument
}) => {

    const { id, label, description } = section;
    const dispatch = useAppDispatch();
    const openSection: DatasetsOpenSection = { sectionId: id, datasetId: datasetId.toString(), type: OpenFieldType.SECTION };
    const sectionOpen = getSectionOpen(openSection);
    const fields = formDefinition.datasetFields[id];
    const datasetOnlySection = fields.length === 1 && fields[0].type === DatasetFieldType.DATASET;
    const sectionOpenIcon = sectionOpen ? CaretDown : CaretSide;

    const setDocumentsForSection = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: number[] = [];
        if (!isNull(dropdownValue)) {
            const applicableDocuments = (dropdownValue as Options<DropdownOption>).map(({ value }) => value);
            value = documentNames.filter(({ value }) => !applicableDocuments.includes(value)).map(({ value }) => parseInt(value));
            const hiddenField: HiddenDocumentSection = { sectionId: id, type: OpenFieldType.SECTION, documentNameIds: value };
            dispatch(toggleDocumentHiddenField(datasetId, hiddenField));
        }
    };

    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId] || [], [datasetId, hiddenFields]);
    const currentHiddenDocumentsForSection = currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(({ sectionId }) => sectionId === id)?.documentNameIds || [];

    const parentHiddenDocuments = !isUndefined(hiddenDocumentNameIds) ? hiddenDocumentNameIds : [];
    const allDocumentsHiddenForField = uniq([...currentHiddenDocumentsForSection, ...parentHiddenDocuments]);
    const sectionDocuments = allDocumentsHiddenForField.length > 0 ? documentNames.filter(({ value }) => !allDocumentsHiddenForField.includes(parseInt(value))) : documentNames;
    const documentNameOptions = parentHiddenDocuments.length > 0 ? documentNames.map(option => parentHiddenDocuments.includes(parseInt(option.value)) ? ({ ...option, disabled: true }) : option) : documentNames;

    if (isPreview && !isNull(previewSelectedDocument) && allDocumentsHiddenForField.includes(previewSelectedDocument)) {
        return null;
    }

    return (
        <div key={id} className={styles.sectionWrapper} data-testid={`form-section-${id}-wrapper`}>
            <div className={styles.sectionHeaderWrapper}>
                <div className={styles.sectionHeader} onClick={() => toggleSection(openSection)} data-testid={`form-section-${id}-collapse`}>
                    <div className={styles.sectionOpenIcon}>
                        <Icon icon={sectionOpenIcon} fontSize={15} />
                    </div>
                    <div className={styles.sectionLabel} data-testid={`form-section-${id}-label`}>{label}</div>
                    {description && <div className={styles.sectionDescription}><InformationTooltip content={description} /></div>}
                </div>
                {!isPreview && <DatasetDocumentSelect selectedDocuments={sectionDocuments} onChange={setDocumentsForSection} documentNames={documentNameOptions} label={label} />}
                {!isPreview && <SectionPageRef datasetId={datasetId} documentNames={documentNameOptions} label={label} sectionId={id} />}
            </div>
            {sectionOpen &&
                <Fields
                    fields={fields}
                    sectionId={id}
                    datasetOnlySection={datasetOnlySection}
                    datasetId={datasetId}
                    parents={parents}
                    toggleSection={toggleSection}
                    getSectionOpen={getSectionOpen}
                    isPreview={isPreview}
                    modalInstance={modalInstance}
                    hiddenFields={hiddenFields}
                    hiddenDocumentNameIds={hiddenDocumentNameIds}
                    documentNames={documentNames}
                    previewSelectedDocument={previewSelectedDocument}
                />
            }
        </div>
    );
};

interface DocumentDatasetFormDefinitionProps {
    formDefinition: FormDatasetDefinition;
    parents: number;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: DocumentSpecificHiddenFields;
    hiddenDocumentNameIds?: number[];
    documentNames: DropdownOption[];
    previewSelectedDocument: number | null;
}

export const DocumentDatasetFormDefinition: React.FC<DocumentDatasetFormDefinitionProps> = ({ formDefinition, parents, isPreview, modalInstance, hiddenFields, hiddenDocumentNameIds, documentNames, previewSelectedDocument }) => {
    const dispatch = useAppDispatch();

    const { datasetSections, datasetFields, datasetId } = formDefinition;
    const firstSection = useMemo(() => datasetSections[0], [datasetSections]);
    const isShortFormInstance = useMemo(() => datasetSections.length === 1 && !firstSection.label, [datasetSections, firstSection]);
    const shortFormFields = useMemo(() => datasetFields[firstSection.id], [datasetFields, firstSection]);
    const datasetOnlySection = useMemo(() => shortFormFields.length === 1 && shortFormFields[0].type === DatasetFieldType.DATASET, [shortFormFields]);
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getSectionOpen = useCallback((fieldSection: DatasetsOpenFieldSection) => openFieldsAndSections.some(openSection => isEqual(openSection, fieldSection)), [openFieldsAndSections]);

    const toggleSection = useCallback((fieldSection: DatasetsOpenFieldSection) => {
        const sectionOpen = openFieldsAndSections.find(openSection => isEqual(openSection, fieldSection));
        sectionOpen ? dispatch(removeOpenFieldSection(sectionOpen)) : dispatch(addOpenFieldSection(fieldSection));
    }, [dispatch, openFieldsAndSections]);

    return (
        <div className={styles.formDefinitionWrapper} data-testid='form-definition-wrapper'>
            <Scrollable>
                {isShortFormInstance ? (
                    <Fields
                        fields={datasetFields[firstSection.id]}
                        sectionId={firstSection.id}
                        datasetOnlySection={datasetOnlySection}
                        datasetId={datasetId!}
                        parents={parents}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        isPreview={isPreview}
                        modalInstance={modalInstance}
                        hiddenFields={hiddenFields}
                        hiddenDocumentNameIds={hiddenDocumentNameIds}
                        documentNames={documentNames}
                        previewSelectedDocument={previewSelectedDocument}
                    />
                ) : (datasetSections.map(section => (
                    <Section
                        section={section}
                        key={section.id}
                        formDefinition={formDefinition}
                        datasetId={datasetId!}
                        parents={parents}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        isPreview={isPreview}
                        modalInstance={modalInstance}
                        hiddenFields={hiddenFields}
                        hiddenDocumentNameIds={hiddenDocumentNameIds}
                        documentNames={documentNames}
                        previewSelectedDocument={previewSelectedDocument}
                    />
                )))}
            </Scrollable>
        </div>
    );
};
