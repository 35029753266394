import { action } from 'typesafe-actions';

import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { RiskToleranceDataset } from '../../../admin/risk-tolerance/store';
import { AllDocumentsRiskTolerance, DocumentAnalyticsActionTypes, DocumentAnalyticsChart, BaseArkDocument, CountryEntity, DocumentFieldRiskToleranceAnalytics, DocumentRiskTolerance, DocumentRiskView, EntityJurisdictionAnalytics, TableDocumentsRiskToleranceDB, EntityDocumentRiskView, PreExecutionDocumentRiskView } from './types';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

export const fetchDocumentSpreadStarted = (entityId: number | null) => action(DocumentAnalyticsActionTypes.FETCH_DOCUMENT_SPREAD_ANALYTICS_STARTED, { entityId, chart: DocumentAnalyticsChart.DOCUMENT_SPREAD });
export const fetchDocumentSpreadSuccessful = (analytics: PieChartAnalyticsData[]) => action(DocumentAnalyticsActionTypes.FETCH_DOCUMENT_SPREAD_ANALYTICS_SUCCESSFUL, { analytics, chart: DocumentAnalyticsChart.DOCUMENT_SPREAD, key: 'documentSpread' });
export const fetchDocumentSpreadFailed = (e: string) => action(DocumentAnalyticsActionTypes.FETCH_DOCUMENT_SPREAD_ANALYTICS_FAILED, { error: e, chart: DocumentAnalyticsChart.DOCUMENT_SPREAD });

export const fetchEntitySpreadStarted = (entityId: number | null) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_SPREAD_ANALYTICS_STARTED, { entityId, chart: DocumentAnalyticsChart.ENTITY_SPREAD });
export const fetchEntitySpreadSuccessful = (analytics: PieChartAnalyticsData[]) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_SPREAD_ANALYTICS_SUCCESSFUL, { analytics, chart: DocumentAnalyticsChart.ENTITY_SPREAD, key: 'entitySpread' });
export const fetchEntitySpreadFailed = (e: string) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_SPREAD_ANALYTICS_FAILED, { error: e, chart: DocumentAnalyticsChart.ENTITY_SPREAD });

export const fetchAllMyCompanyEntitiesStarted = () => action(DocumentAnalyticsActionTypes.FETCH_ALL_MY_COMPANY_ENTITIES_STARTED);
export const fetchAllMyCompanyEntitiesSuccessful = (entities: DropdownOption[]) => action(DocumentAnalyticsActionTypes.FETCH_ALL_MY_COMPANY_ENTITIES_SUCCESSFUL, entities);
export const fetchAllMyCompanyEntitiesFailed = (error: string) => action(DocumentAnalyticsActionTypes.FETCH_ALL_MY_COMPANY_ENTITIES_FAILED, error);

export const fetchEntityJurisdictionStarted = (entityId: number) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_JURISDICTION_ANALYTICS_STARTED, { entityId, chart: DocumentAnalyticsChart.ENTITY_JURISDICTION });
export const fetchEntityJurisdictionSuccessful = (analytics: EntityJurisdictionAnalytics[]) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_JURISDICTION_ANALYTICS_SUCCESSFUL, { analytics, chart: DocumentAnalyticsChart.ENTITY_JURISDICTION, key: 'entityJurisdiction' });
export const fetchEntityJurisdictionFailed = (e: string) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_JURISDICTION_ANALYTICS_FAILED, { error: e, chart: DocumentAnalyticsChart.ENTITY_JURISDICTION });

export const fetchJurisdictionDocumentsStarted = (entityId: number, pageNumber = 1) => action(DocumentAnalyticsActionTypes.FETCH_JURISDICTION_DOCUMENTS_STARTED, { entityId, pageNumber });
export const fetchJurisdictionDocumentsSuccessful = (documents: BaseArkDocument[], total: number, pageNumber: number) => action(DocumentAnalyticsActionTypes.FETCH_JURISDICTION_DOCUMENTS_SUCCESSFUL, { documents, pageNumber, total });
export const fetchJurisdictionDocumentsFailed = (e: string) => action(DocumentAnalyticsActionTypes.FETCH_JURISDICTION_DOCUMENTS_FAILED, e);

export const selectJurisdictionDocumentsPage = (entityId: number, pageNumber: number) => action(DocumentAnalyticsActionTypes.JURISDICTION_DOCUMENTS_SELECT_PAGE, { pageNumber, entityId });

export const setSelectedCountry = (country: string | null) => action(DocumentAnalyticsActionTypes.SET_SELECTED_COUNTRY, country);
export const setCountryEntities = (name: string, entities: CountryEntity[]) => action(DocumentAnalyticsActionTypes.SET_COUNTRY_ENTITIES, { name, entities });
export const clearCountryEntities = () => action(DocumentAnalyticsActionTypes.CLEAR_COUNTRY_ENTITIES);
export const setSelectedMyCompanyEntity = (entityId: number | null) => action(DocumentAnalyticsActionTypes.SET_SELECTED_MY_COMPANY_ENTITY, entityId);

export const fetchDocumentRiskToleranceStarted = (documentId: number, isPreExecution?: boolean) => action(DocumentAnalyticsActionTypes.FETCH_DOCUMENT_RISK_TOLERANCE_STARTED, { documentId, isPreExecution });
export const fetchDocumentRiskToleranceSuccessful = (documentRiskTolerance: DocumentRiskTolerance | null) => action(DocumentAnalyticsActionTypes.FETCH_DOCUMENT_RISK_TOLERANCE_SUCCESSFUL, documentRiskTolerance);
export const fetchDocumentRiskToleranceFailed = (error: string) => action(DocumentAnalyticsActionTypes.FETCH_DOCUMENT_RISK_TOLERANCE_FAILED, error);

export const fetchAllDocumentsRiskToleranceStarted = () => action(DocumentAnalyticsActionTypes.FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_STARTED);
export const fetchAllDocumentsRiskToleranceSuccessful = (allDocumentsRiskTolerance: AllDocumentsRiskTolerance) => action(DocumentAnalyticsActionTypes.FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL, allDocumentsRiskTolerance);
export const fetchAllDocumentsRiskToleranceFailed = (error: string) => action(DocumentAnalyticsActionTypes.FETCH_ALL_DOCUMENTS_RISK_TOLERANCE_FAILED, error);

export const fetchAllEntityDocumentsRiskToleranceStarted = (entityId: number) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_STARTED, entityId);
export const fetchAllEntityDocumentsRiskToleranceSuccessful = (allDocumentsRiskTolerance: AllDocumentsRiskTolerance) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL, allDocumentsRiskTolerance);
export const fetchAllEntityDocumentsRiskToleranceFailed = (error: string) => action(DocumentAnalyticsActionTypes.FETCH_ENTITY_DOCUMENTS_RISK_TOLERANCE_FAILED, error);

export const fetchAllPreExecutionDocumentsRiskToleranceStarted = () => action(DocumentAnalyticsActionTypes.FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_STARTED);
export const fetchAllPreExecutionDocumentsRiskToleranceSuccessful = (allDocumentsRiskTolerance: AllDocumentsRiskTolerance) => action(DocumentAnalyticsActionTypes.FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL, allDocumentsRiskTolerance);
export const fetchAllPreExecutionDocumentsRiskToleranceFailed = (error: string) => action(DocumentAnalyticsActionTypes.FETCH_ALL_PRE_EXECUTION_DOCUMENTS_RISK_TOLERANCE_FAILED, error);

export const fetchPaginatedDocumentsRiskToleranceStarted = (pageNumber = 1, entityId?: number, isPreExecution?: boolean) => action(DocumentAnalyticsActionTypes.FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_STARTED, { pageNumber, entityId, isPreExecution });
export const fetchPaginatedDocumentsRiskToleranceSuccessful = (documents: TableDocumentsRiskToleranceDB[], total: number, pageNumber: number) => action(DocumentAnalyticsActionTypes.FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_SUCCESSFUL, { documents, total, pageNumber });
export const fetchPaginatedDocumentsRiskToleranceFailed = (error: string) => action(DocumentAnalyticsActionTypes.FETCH_PAGINATED_DOCUMENTS_RISK_TOLERANCE_FAILED, error);

export const fetchAllDatasetRiskToleranceStarted = (entityId?: number, isPreExecution?: boolean) => action(DocumentAnalyticsActionTypes.FETCH_ALL_DATASETS_RISK_TOLERANCE_STARTED, { entityId, isPreExecution });
export const fetchAllDatasetRiskToleranceSuccessful = (riskToleranceDatasets: RiskToleranceDataset[]) => action(DocumentAnalyticsActionTypes.FETCH_ALL_DATASETS_RISK_TOLERANCE_SUCCESSFUL, riskToleranceDatasets);
export const fetchAllDatasetRiskToleranceFailed = (error: string) => action(DocumentAnalyticsActionTypes.FETCH_ALL_DATASETS_RISK_TOLERANCE_FAILED, error);

export const setRiskToleranceView = (view: DocumentRiskView) => action(DocumentAnalyticsActionTypes.SET_RISK_TOLERANCE_VIEW, view);

export const setEntityDocumentRiskToleranceView = (view: EntityDocumentRiskView) => action(DocumentAnalyticsActionTypes.SET_ENTITY_DOCUMENT_RISK_TOLERANCE_VIEW, view);

export const setPreExecutionDocumentRiskToleranceView = (view: PreExecutionDocumentRiskView) => action(DocumentAnalyticsActionTypes.SET_PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE_VIEW, view);

export const fetchDatasetFieldRiskToleranceStarted = (pageNumber = 1, entityId?: number, isPreExecution?: boolean) => action(DocumentAnalyticsActionTypes.FETCH_DATASET_FIELD_RISK_TOLERANCE_STARTED, { pageNumber, entityId, isPreExecution });
export const fetchDatasetFieldRiskToleranceSuccessful = (documents: DocumentFieldRiskToleranceAnalytics[], total: number, overallPercentage: number, pageNumber: number) => action(DocumentAnalyticsActionTypes.FETCH_DATASET_FIELD_RISK_TOLERANCE_SUCCESSFUL, { documents, total, overallPercentage, pageNumber });
export const fetchDatasetFieldRiskToleranceFailed = (error: string) => action(DocumentAnalyticsActionTypes.FETCH_DATASET_FIELD_RISK_TOLERANCE_FAILED, error);

export const setRiskToleranceDatasetId = (datasetId: number | null) => action(DocumentAnalyticsActionTypes.SET_RISK_TOLERANCE_DATASET_ID, datasetId);

export const getRiskFieldDocumentMeta = (originalDocumentId: number) => action(DocumentAnalyticsActionTypes.GET_SELECTED_RISK_FIELD_DOCUMENT_META, originalDocumentId);

export const setSelectedDatasetRiskField = (childDatasetId: number | null, fieldId: string | null) => action(DocumentAnalyticsActionTypes.SET_SELECTED_DATASET_RISK_FIELD, { childDatasetId, fieldId });

export const redirectToDocumentInstance = (originalDocumentId: number, datasetInstanceId: number) => action(DocumentAnalyticsActionTypes.REDIRECT_TO_DOCUMENT_INSTANCE, { originalDocumentId, datasetInstanceId });

export const setDocumentTileInView = (tileInView: DocumentAnalyticsChart | null, shouldRedirect: boolean) => action(DocumentAnalyticsActionTypes.SET_DOCUMENT_ANALYTICS_TILE_IN_VIEW, { tileInView, shouldRedirect });

// Paginated Risk Tolerance Documents Filters
export const setDocumentRiskToleranceTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS, { key, value, type });
export const clearDocumentRiskToleranceTableFilters = () => action(DocumentAnalyticsActionTypes.CLEAR_DOCUMENT_RISK_TOLERANCE_TABLE_FILTERS);
export const setDocumentRiskToleranceTableColumnSort = (columnSort: ColumnSort | undefined) => action(DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_TABLE_COLUMN_SORT, columnSort);
export const setDocumentRiskTolerancePageSize = (pageSize: number) => action(DocumentAnalyticsActionTypes.SET_DOCUMENT_RISK_TOLERANCE_PAGE_SIZE, pageSize);
export const documentRiskTolerancePaginationNext = () => action(DocumentAnalyticsActionTypes.DOCUMENT_RISK_TOLERANCE_PAGINATION_NEXT);
export const documentRiskTolerancePaginationPrevious = () => action(DocumentAnalyticsActionTypes.DOCUMENT_RISK_TOLERANCE_PAGINATION_PREVIOUS);

// Paginated Risk Tolerance Dataset Field Filters
export const setDatasetFieldRiskToleranceTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS, { key, value, type });
export const clearDatasetFieldRiskToleranceTableFilters = () => action(DocumentAnalyticsActionTypes.CLEAR_DATASET_FIELD_RISK_TOLERANCE_TABLE_FILTERS);
export const setDatasetFieldRiskToleranceTableColumnSort = (columnSort: ColumnSort | undefined) => action(DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_TABLE_COLUMN_SORT, columnSort);
export const setDatasetFieldRiskTolerancePageSize = (pageSize: number) => action(DocumentAnalyticsActionTypes.SET_DATASET_FIELD_RISK_TOLERANCE_PAGE_SIZE, pageSize);
export const datasetFieldRiskTolerancePaginationNext = () => action(DocumentAnalyticsActionTypes.DATASET_FIELD_RISK_TOLERANCE_PAGINATION_NEXT);
export const datasetFieldRiskTolerancePaginationPrevious = () => action(DocumentAnalyticsActionTypes.DATASET_FIELD_RISK_TOLERANCE_PAGINATION_PREVIOUS);
