import { noop } from 'lodash/fp';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Icon } from '../../shared/icon/Icon';
import { SaveFile, WarningSign } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import styles from './DatasetInstance.module.scss';

interface UnsavedChangesPromptProps {
    when: boolean;
    pathname: string;
    toggleEdit: () => void;
}

export const UnsavedChangesPrompt: React.FC<UnsavedChangesPromptProps> = ({ when, pathname, toggleEdit }) => {
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState(pathname);

    useEffect(() => {
        if (when) {
            history.block(({ pathname }) => {
                if (pathname !== currentPath) {
                    setCurrentPath(pathname);
                    setShowPrompt(true);
                }
                return 'true';
            });
        } else {
            history.block(noop);
        }

        return () => {
            history.block(noop);
        };
    }, [history, when, currentPath]);

    const handleConfirm = useCallback(() => {
        history.block(noop);
        history.push(currentPath);
        toggleEdit();
    }, [currentPath, history, toggleEdit]);

    if (showPrompt) {
        return (
            <ConfirmationModal
                isOpen={showPrompt}
                closeModal={() => setShowPrompt(false)}
                confirm={handleConfirm}
                confirmLabel='Yes, do not save'
            >
                <div className={styles.modalWrapper}>
                    <ModalHeader
                        testId='unsaved-changes-modal'
                        label='Unsaved Changes'
                        icon={SaveFile}
                    />
                    <div className={styles.modalWarning}>
                        <Icon icon={WarningSign} />
                        <div className={styles.modalWarningTitle}>You have unsaved changes.</div>
                    </div>
                    <div className={styles.modalContent}>Are you sure you want to navigate away from this page without saving?</div>
                </div>
            </ConfirmationModal>
        );
    }
    return null;
};
