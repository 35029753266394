import React from 'react';

import { Filter } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Position, PositionModal } from '../../shared/modal/PositionModal';
import styles from './Clients.module.scss';

interface JurisdictionFilterModalProps {
    closeModal: () => void;
    position: Position;
    setFilterValue: (value: string) => void;
    value: string;
}

export const JurisdictionFilterModal: React.FC<JurisdictionFilterModalProps> = ({ closeModal, position, setFilterValue, value }) => (
    <PositionModal
        isOpen
        testId='client-opinion-jurisdiction-filter'
        closeModal={closeModal}
        position={position}
        height='fit-content'
        width='155px'
    >
        <div className={styles.tableFilterModal}>
            <ModalHeader icon={Filter} label='Filter' iconSize={20} overflowTooltipClassName={styles.filterHeader} marginBottom='5px' />
            <input
                placeholder='Add filter...'
                className={styles.tableFilterInput}
                onChange={e => setFilterValue(e.target.value)}
                value={value}
                autoFocus
            />
        </div>
    </PositionModal>
);
