import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { RawDraftContentState } from 'draft-js';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { Button } from '../shared/button/Button';
import { CaretDown, CaretSide, Pencil } from '../shared/icons';
import { ModalHeader } from '../shared/modal/ModalHeader';
import styles from './Playbook.module.scss';
import { getSuggestedChangesModalOpen, getSuggestedChanges, toggleSuggestedChangesModalOpen, getUserCurrentView, isSectionChanges, updateSuggestedChanges, getSuggestedChangesUpdated, setShowConfirmationModal, getSendingSuggestion, sendSuggestedChangesStarted, addSuggestedChangesDeviationRow, deleteSuggestedChangesDeviationRow, updateSuggestedChangesSectionDeviation, getSuggestedChangesMessage, updateSuggestedChangesNewMessage } from './store';
import { Provision } from '../shared/playbook/view/Provision';
import { Abstract } from '../shared/playbook/view/Abstract';
import { PlaybookProvision, PlaybookView, RelevantStakeholders } from '../admin/playbook/store';
import { Scrollable } from '../shared/scrollable/Scrollable';
import { Spinner } from '../shared/spinner/Spinner';
import { WYSIWYG, isEmpty } from '../shared/wysiwyg/WYSIWYG';
import { getUser } from '../auth/login/store';
import { Icon } from '../shared/icon/Icon';

interface SuggestedChangesModalProps {
    testId?: string;
}

export const SuggestedChangesModal: React.FC<SuggestedChangesModalProps> = ({ testId = 'playbook' }) => {
    const [justificationOpen, setJustificationOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getSuggestedChangesModalOpen);
    const suggestedChanges = useAppSelector(getSuggestedChanges);
    const playbookView = useAppSelector(getUserCurrentView);
    const suggestedChangesUpdated = useAppSelector(getSuggestedChangesUpdated);
    const isSendingSuggestion = useAppSelector(getSendingSuggestion);
    const newMessage = useAppSelector(getSuggestedChangesMessage);
    const user = useAppSelector(getUser);

    const closeModal = useCallback(() => {
        suggestedChangesUpdated ? dispatch(setShowConfirmationModal()) : dispatch(toggleSuggestedChangesModalOpen(null));
        setJustificationOpen(false);
    }, [dispatch, suggestedChangesUpdated]);

    const updateChanges = useCallback((value: RawDraftContentState | null | string | string[] | RelevantStakeholders[], key?: keyof PlaybookProvision) => { dispatch(updateSuggestedChanges(value, key)); }, [dispatch]);
    const updateProvision = useCallback((sectionId: string, key: keyof PlaybookProvision, value: RawDraftContentState | null | string | string[] | RelevantStakeholders[]) => updateChanges(value, key), [updateChanges]);
    const updateAbstract = useCallback((value: RawDraftContentState | null) => updateChanges(value), [updateChanges]);
    const suggestChanges = useCallback(() => dispatch(sendSuggestedChangesStarted()), [dispatch]);
    const addDeviation = useCallback((sectionId: string, index?: number) => dispatch(addSuggestedChangesDeviationRow(index)), [dispatch]);
    const deleteDeviation = useCallback((sectionId: string, rowId: string) => dispatch(deleteSuggestedChangesDeviationRow(rowId)), [dispatch]);
    const updateDeviationColumn = useCallback((sectionId: string, rowId: string, key: string, value: string | null | RawDraftContentState | number) => dispatch(updateSuggestedChangesSectionDeviation(rowId, key, value)), [dispatch]);

    const scrollToInput = useCallback(() => {
        const additionalDetails = document.getElementById('additional-details');
        additionalDetails?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }, []);

    const toggleJustificationOpen = useCallback(() => {
        setJustificationOpen(!justificationOpen);
        if (!justificationOpen) {
            setTimeout(() => scrollToInput(), 150);
        }
    }, [justificationOpen, scrollToInput]);

    const newMessageContent = isNull(newMessage) ? null : newMessage.content;
    const updateNewMessage = useCallback((rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            if (!isNull(newMessageContent)) {
                dispatch(updateSuggestedChangesNewMessage(null, user!));
            }
        } else {
            dispatch(updateSuggestedChangesNewMessage(rawValue, user!));
        }
    }, [newMessageContent, user, dispatch]);

    const justificationOpenIcon = justificationOpen ? CaretDown : CaretSide;

    const content = useMemo(() => {
        if (isNull(suggestedChanges)) {
            return null;
        }
        if (isSendingSuggestion) {
            return <Spinner />;
        }
        if (isSectionChanges(suggestedChanges)) {
            const { content } = suggestedChanges;
            return (
                <Provision
                    isTableView={playbookView === PlaybookView.TABLE}
                    section={content}
                    showDelete={false}
                    updateProvision={updateProvision}
                    showBorder={false}
                    showCollapseToggle={false}
                    addDeviation={addDeviation}
                    deleteDeviationRow={deleteDeviation}
                    updateDeviationColumn={updateDeviationColumn}
                    testId={`${testId}-suggested-changes-0`}
                />
            );
        }
        return (
            <Abstract
                abstract={suggestedChanges.content}
                updateAbstract={updateAbstract}
                showBorder={false}
                testId={`${testId}-suggested-changes`}
            />
        );
    }, [suggestedChanges, playbookView, updateProvision, updateAbstract, isSendingSuggestion, addDeviation, deleteDeviation, updateDeviationColumn, testId]);

    if (isNull(suggestedChanges)) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            className={styles.suggestedChangesModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 9 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.suggestedChangesWrapper} data-testid={`${testId}-suggested-changes-modal-wrapper`}>
                <ModalHeader label='Suggest Changes' icon={Pencil} testId={`${testId}-suggested-changes-modal`} />
                <div className={styles.provisionWrapper}>
                    <Scrollable>
                        {content}
                        {!isSendingSuggestion &&
                            <div className={styles.newMessageWrapper} data-testid={`${testId}-suggested-changes-new-message-wrapper`}>
                                <div className={styles.newMessageHeader} onClick={toggleJustificationOpen} data-testid={`${testId}-suggested-changes-new-message-toggle`}>
                                    <div className={styles.newMessageCollapseIcon}>
                                        <Icon icon={justificationOpenIcon} fontSize={16} />
                                    </div>
                                    <div className={styles.newMessageTitle} data-testid={`${testId}-suggested-changes-new-message-title`}>Provide additional details and/or justification for your suggestion (optional)</div>
                                </div>
                                {justificationOpen &&
                                    <div data-testid={`${testId}-suggested-changes-new-message-content`} id='additional-details'>
                                        <WYSIWYG
                                            content={newMessageContent}
                                            updateContent={value => updateNewMessage(value)}
                                            showBorder={false}
                                            height={'calc(30vh - 40px)'}
                                            maxHeight={'calc(30vh - 40px)'}
                                            showWrapperBorder
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </Scrollable>
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' testId={`${testId}-suggested-changes-close`} />
                    <Button onClick={suggestChanges} disabled={!suggestedChangesUpdated || isSendingSuggestion} label='Suggest Changes' testId={`${testId}-suggested-changes-send`} />
                </div>
            </div>
        </Modal>
    );
};
