import React from 'react';

import { LinkedDocument } from '../../documents/my-documents/store';
import { Position, PositionModal } from '../../shared/modal/PositionModal';
import { OverflowTooltip } from '../../shared/tooltip';
import styles from './DatasetInstance.module.scss';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Link } from '../../shared/icons';
import { Scrollable } from '../../shared/scrollable/Scrollable';

interface LinkedDocumentModalProps {
    isOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    linkedDocuments: LinkedDocument[];
    linkToDocument: (location: string, documentId: number, mimeType: string) => void;
}

export const LinkedDocumentModal: React.FC<LinkedDocumentModalProps> = ({ closeModal, isOpen, position, linkedDocuments, linkToDocument }) => {
    const centeredPosition = position ? { x: position.x, y: (position.y - 306) } : null;
    return (
        <PositionModal
            isOpen={isOpen}
            position={centeredPosition}
            height='306px'
            width='500px'
            closeModal={closeModal}
            testId='linked-document-modal'
        >
            <ModalHeader label='Linked Documents' icon={Link} />
            <div className={styles.linkedDocumentModalWrapper} data-testid='linked-document-modal-wrapper'>
                <Scrollable>
                    {linkedDocuments.map(({ documentDescription, documentId, location, mimeType, documentName, agreementType, entityAName, entityBName }) => (
                        <button key={documentId} className={styles.linkedDocumentButton} onClick={() => linkToDocument(location, documentId, mimeType)}>
                            <div className={styles.documentNameDetails}>
                                <OverflowTooltip overlayText={documentName} className={styles.documentName} />
                                <OverflowTooltip overlayText={agreementType} className={styles.agreementType} />
                            </div>
                            <div className={styles.documentDescriptionWrapper}>
                                <OverflowTooltip overlayText={`Filename: ${documentDescription}`} className={styles.documentDescription} />
                            </div>
                            <div className={styles.partyLabels}>
                                <div className={styles.splitWidthLeftWrapper}>Party A</div>
                                <div className={styles.splitWidthRightWrapper}>Party B</div>
                            </div>
                            <div className={styles.partyDetails}>
                                <div className={styles.splitWidthLeftWrapper}>
                                    <OverflowTooltip overlayText={entityAName} className={styles.entityName} />
                                </div>
                                <div className={styles.splitWidthRightWrapper}>
                                    <OverflowTooltip overlayText={entityBName} className={styles.entityName} />
                                </div>
                            </div>
                        </button>
                    ))}
                </Scrollable>
            </div>
        </PositionModal>
    );
};
