import { Reducer } from 'redux';
import { set, flow, isNull } from 'lodash/fp';

import { MyOpinionActionTypes, MyOpinionsState, OpinionsTableView, OpinionsView } from './types';
import { LoginActionTypes } from '../../../auth/login/store';
import { AnalysisView } from '../../../documents/my-documents/store/types';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';

export const INITIAL_STATE: MyOpinionsState = {
    opinionsView: OpinionsView.TABLE,
    opinionsTableView: OpinionsTableView.COMPLETE,
    modalOpen: false,
    filesToUpload: null,
    isUploading: false,
    selectedIncompleteOpinions: [],
    uploadError: null,
    opinionDetailsModalOpen: false,
    isOpening: false,
    isLoading: false,
    incompleteOpinionUrl: null,
    incompleteOpinion: null,
    incompleteOpinionError: null,
    incompleteOpinionDetailsUpdated: false,
    isUpdatingOpinionDetails: false,
    incompleteOpinions: [],
    opinions: [],
    opinionUrl: null,
    opinion: null,
    additionalOpinions: [],
    additionalOpinionUrls: [],
    selectedAdditionalOpinionId: null,
    opinionPage: 1,
    totalOpinions: 0,
    opinionFilters: {},
    opinionPageSize: DEFAULT_PAGE_SIZE,
    opinionColumnSort: undefined,
    incompleteOpinionPage: 1,
    totalIncompleteOpinions: 0,
    incompleteOpinionFilters: {},
    incompleteOpinionPageSize: DEFAULT_PAGE_SIZE,
    incompleteOpinionColumnSort: undefined,
    opinionAnalysisView: AnalysisView.SPLIT,
    isDeleting: false,
    deleteError: null,
    opinionToDelete: null,
    linkedOpinions: [],
    unauthorisedOpinion: null,
    selectedCountry: null,
    mapOpinions: [],
    isEnquiring: false,
    countryOpinions: null,
    resetOpinionsMapZoom: false,
    opinionsMapZoomSteps: 0,
    opinionsMapZoomUpdated: false,
    opinionsMapCurrentScale: 0,
    opinionsMapScaleExtent: [0.2, 5],
    britishOpinions: null,
    potentialBaseOpinion: null,
    selectedPageNumber: null,
    isDownloadingSummary: false,
    isOpeningSummary: false,
    opinionSummaryUrl: null,
    summaryGenerating: false,
    summaryPreviewModalOpen: false,
    opinionCustomFiltersModalOpen: false,
    selectedOpinionCustomFilterId: null,
    opinionCustomFilterHasUpdated: false
};

export const myOpinionsReducer: Reducer<MyOpinionsState> = (state = INITIAL_STATE, { payload, type }): MyOpinionsState => {
    switch (type) {
        case MyOpinionActionTypes.TOGGLE_OPINIONS_VIEW:
            return set('opinionsView', payload, state);
        case MyOpinionActionTypes.TOGGLE_OPINIONS_TABLE_VIEW:
            return set('opinionsTableView', payload, state);
        case MyOpinionActionTypes.TOGGLE_OPINION_MODAL:
            return flow(
                set('modalOpen', payload),
                set('filesToUpload', null)
            )(state);
        case MyOpinionActionTypes.SET_INITIAL_UPLOAD_OPINIONS:
            return set('filesToUpload', payload, state);
        case MyOpinionActionTypes.UPLOAD_OPINION_STARTED:
        case MyOpinionActionTypes.UPLOAD_SUPPORT_OPINION_STARTED:
            return set('isUploading', true, state);
        case MyOpinionActionTypes.UPLOAD_OPINION_SUCCESSFUL:
            return flow(
                set('isUploading', false),
                set('modalOpen', false),
                set('selectedIncompleteOpinions', payload),
                set('filesToUpload', null)
            )(state);
        case MyOpinionActionTypes.UPLOAD_SUPPORT_OPINION_SUCCESSFUL:
            return flow(
                set('isUploading', false),
                set('filesToUpload', null)
            )(state);
        case MyOpinionActionTypes.UPLOAD_OPINION_FAILED:
        case MyOpinionActionTypes.UPLOAD_SUPPORT_OPINION_FAILED:
            return flow(
                set('uploadError', payload),
                set('isUploading', false)
            )(state);
        case MyOpinionActionTypes.TOGGLE_OPINION_DETAILS_MODAL: {
            const selectedIncompleteOpinions = payload ? state.selectedIncompleteOpinions : [];
            return flow(
                set('opinionDetailsModalOpen', payload),
                set('selectedIncompleteOpinions', selectedIncompleteOpinions)
            )(state);
        }
        case MyOpinionActionTypes.OPEN_INCOMPLETE_OPINION_STARTED:
            return flow(
                set('isOpening', true),
                set('incompleteOpinionUrl', null),
                set('incompleteOpinion', null),
                set('incompleteOpinionError', null)
            )(state);
        case MyOpinionActionTypes.OPEN_INCOMPLETE_OPINION_SUCCESSFUL: {
            const { opinion, opinionUrl } = payload;
            return flow(
                set('isOpening', false),
                set('incompleteOpinionUrl', opinionUrl),
                set('incompleteOpinion', opinion)
            )(state);
        }
        case MyOpinionActionTypes.OPEN_INCOMPLETE_OPINION_FAILED:
            return flow(
                set('isOpening', false),
                set('incompleteOpinionError', payload)
            )(state);
        case MyOpinionActionTypes.INCOMPLETE_OPINION_UPDATE_VALUE: {
            const { key, value } = payload;
            return set(`incompleteOpinion[${key}]`, value, state);
        }
        case MyOpinionActionTypes.INCOMPLETE_OPINION_DETAILS_UPDATED:
            return set('incompleteOpinionDetailsUpdated', payload, state);
        case MyOpinionActionTypes.UPDATE_OPINION_DETAILS_STARTED:
            return set('isUpdatingOpinionDetails', true, state);
        case MyOpinionActionTypes.UPDATE_OPINION_DETAILS_SUCCESSFUL:
            return flow(
                set('isUpdatingOpinionDetails', false),
                set('incompleteOpinionDetailsUpdated', false),
                set('selectedIncompleteOpinions', payload)
            )(state);
        case MyOpinionActionTypes.UPDATE_OPINION_DETAILS_FAILED:
            return set('isUpdatingOpinionDetails', false, state);
        case MyOpinionActionTypes.FETCH_INCOMPLETE_OPINIONS_STARTED:
        case MyOpinionActionTypes.FETCH_OPINIONS_STARTED:
            return set('isLoading', true, state);
        case MyOpinionActionTypes.FETCH_INCOMPLETE_OPINIONS_FAILED:
        case MyOpinionActionTypes.FETCH_OPINIONS_FAILED:
            return set('isLoading', false, state);
        case MyOpinionActionTypes.FETCH_INCOMPLETE_OPINIONS_SUCCESSFUL:
            return flow(
                set('incompleteOpinions', payload.incompleteOpinions),
                set('totalIncompleteOpinions', payload.total || state.totalIncompleteOpinions),
                set('incompleteOpinionPage', payload.pageNumber || state.incompleteOpinionPage),
                set('isLoading', false)
            )(state);
        case MyOpinionActionTypes.SET_SELECTED_INCOMPLETE_OPINIONS:
            return set('selectedIncompleteOpinions', payload, state);
        case MyOpinionActionTypes.FETCH_OPINIONS_SUCCESSFUL:
            return flow(
                set('opinions', payload.opinions),
                set('totalOpinions', payload.total || state.totalOpinions),
                set('opinionPage', payload.pageNumber || state.opinionPage),
                set('isLoading', false)
            )(state);
        case MyOpinionActionTypes.OPEN_OPINION_STARTED:
            return flow(
                set('isOpening', true),
                set('selectedPageNumber', null),
                set('additionalOpinions', []),
                set('additionalOpinionUrls', [])
            )(state);
        case MyOpinionActionTypes.OPEN_OPINION_SUCCESSFUL: {
            const { opinion, opinionUrl } = payload;
            return flow(
                set('opinion', opinion),
                set('opinionUrl', opinionUrl),
                set('isOpening', false),
            )(state);
        }
        case MyOpinionActionTypes.OPEN_OPINION_FAILED:
            return set('isOpening', false, state);
        case MyOpinionActionTypes.RESET_ALL_OPINIONS:
            return {
                ...INITIAL_STATE,
                opinions: state.opinions,
                opinionsView: state.opinionsView
            };
        case MyOpinionActionTypes.SET_OPINION_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`opinionFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`opinionFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case MyOpinionActionTypes.CLEAR_OPINION_TABLE_FILTERS:
            return flow(
                set('opinionFilters', {}),
                set('selectedOpinionCustomFilterId', payload ? null : state.selectedOpinionCustomFilterId),
                set('opinionCustomFilterHasUpdated', false)
            )(state);
        case MyOpinionActionTypes.SET_OPINION_TABLE_COLUMN_SORT:
            return set('opinionColumnSort', payload, state);
        case MyOpinionActionTypes.SET_OPINIONS_PAGE_SIZE:
            return set('opinionPageSize', payload, state);
        case MyOpinionActionTypes.SET_INCOMPLETE_OPINION_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`incompleteOpinionFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`incompleteOpinionFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case MyOpinionActionTypes.CLEAR_INCOMPLETE_OPINION_TABLE_FILTERS:
            return set('incompleteOpinionFilters', {}, state);
        case MyOpinionActionTypes.SET_INCOMPLETE_OPINION_TABLE_COLUMN_SORT:
            return set('incompleteOpinionColumnSort', payload, state);
        case MyOpinionActionTypes.SET_INCOMPLETE_OPINIONS_PAGE_SIZE:
            return set('incompleteOpinionPageSize', payload, state);
        case MyOpinionActionTypes.TOGGLE_OPINION_ANALYSIS_VIEW:
            return set('opinionAnalysisView', payload, state);
        case MyOpinionActionTypes.TOGGLE_DELETE_OPINION_MODAL:
            return set('opinionToDelete', payload, state);
        case MyOpinionActionTypes.DELETE_OPINION_STARTED:
            return set('isDeleting', true, state);
        case MyOpinionActionTypes.DELETE_OPINION_SUCCESSFUL:
            return flow(
                set('isDeleting', false),
                set('opinionToDelete', null)
            )(state);
        case MyOpinionActionTypes.DELETE_OPINION_FAILED:
            return flow(
                set('isDeleting', false),
                set('deleteError', payload)
            )(state);
        case MyOpinionActionTypes.SET_LINKED_OPINIONS:
            return set('linkedOpinions', payload, state);
        case MyOpinionActionTypes.SET_UNAUTHORISED_OPINION:
            return set('unauthorisedOpinion', payload, state);
        case MyOpinionActionTypes.SET_SELECTED_OPINIONS_JURISDICTION:
            return set('selectedCountry', payload, state);
        case MyOpinionActionTypes.FETCH_ALL_OPINIONS_BY_JURISDICTION_STARTED:
            return set('isLoading', true, state);
        case MyOpinionActionTypes.FETCH_ALL_OPINIONS_BY_JURISDICTION_SUCCESSFUL:
            return flow(
                set('isLoading', false),
                set('mapOpinions', payload)
            )(state);
        case MyOpinionActionTypes.FETCH_ALL_OPINIONS_BY_JURISDICTION_FAILED:
            return set('isLoading', false, state);
        case MyOpinionActionTypes.ENQUIRE_OPINION_STARTED:
            return set('isEnquiring', true, state);
        case MyOpinionActionTypes.ENQUIRE_OPINION_SUCCESSFUL:
            return flow(
                set('isEnquiring', false),
                set('unauthorisedOpinion', null)
            )(state);
        case MyOpinionActionTypes.ENQUIRE_OPINION_FAILED:
            return set('isEnquiring', false, state);
        case MyOpinionActionTypes.SET_COUNTRY_OPINIONS:
            return set('countryOpinions', payload, state);
        case MyOpinionActionTypes.RESET_OPINIONS_MAP_ZOOM:
            return set('resetOpinionsMapZoom', payload, state);
        case MyOpinionActionTypes.SET_OPINIONS_MAP_ZOOM_STEPS:
            return set('opinionsMapZoomSteps', payload, state);
        case MyOpinionActionTypes.SET_OPINIONS_MAP_ZOOM_UPDATED:
            return set('opinionsMapZoomUpdated', payload, state);
        case MyOpinionActionTypes.SET_OPINIONS_MAP_CURRENT_SCALE:
            return set('opinionsMapCurrentScale', payload, state);
        case MyOpinionActionTypes.SET_BRITISH_OPINIONS:
            return set('britishOpinions', payload, state);
        case MyOpinionActionTypes.OPEN_ADDITIONAL_OPINIONS_STARTED:
            return set('isOpening', true, state);
        case MyOpinionActionTypes.OPEN_ADDITIONAL_OPINIONS_SUCCESSFUL: {
            const { additionalOpinions, urls } = payload;
            return flow(
                set('isOpening', false),
                set('additionalOpinions', additionalOpinions),
                set('additionalOpinionUrls', urls)
            )(state);
        }
        case MyOpinionActionTypes.OPEN_ADDITIONAL_OPINIONS_FAILED:
            return set('isOpening', false, state);
        case MyOpinionActionTypes.SET_ADDITIONAL_OPINION_SELECTED:
            return flow(
                set('selectedAdditionalOpinionId', payload),
                set('selectedPageNumber', null)
            )(state);
        case MyOpinionActionTypes.SET_POTENTIAL_BASE_OPINION:
            return set('potentialBaseOpinion', payload, state);
        case MyOpinionActionTypes.ASSERT_POTENTIAL_BASE_OPINION_SUCCESSFUL:
            return set('potentialBaseOpinion', null, state);
        case MyOpinionActionTypes.SET_SELECTED_OPINION_PAGE:
            return set('selectedPageNumber', payload, state);
        case MyOpinionActionTypes.SET_OPINION_SUMMARY_GENERATING:
            return set('summaryGenerating', payload, state);
        case MyOpinionActionTypes.SET_UPDATED_OPINION_SUMMARY_ID: {
            const { opinionId, opinionSummaryId } = payload;
            const opinions = state.opinions.map(opinion => opinion.opinionId === opinionId ? set('opinionSummaryId', opinionSummaryId, opinion) : opinion);
            if (!isNull(state.opinion) && state.opinion.opinionId === opinionId) {
                return flow(
                    set('opinion.opinionSummaryId', opinionSummaryId),
                    set('opinions', opinions),
                    set('summaryGenerating', false)
                )(state);
            }
            return set('opinions', opinions, state);
        }
        case MyOpinionActionTypes.OPEN_OPINION_SUMMARY_STARTED:
            return set('isOpeningSummary', true, state);
        case MyOpinionActionTypes.OPEN_OPINION_SUMMARY_SUCCESSFUL:
            return flow(
                set('opinionSummaryUrl', payload),
                set('isOpeningSummary', false)
            )(state);
        case MyOpinionActionTypes.OPEN_OPINION_SUMMARY_FAILED:
            return set('isOpeningSummary', false, state);
        case MyOpinionActionTypes.TOGGLE_OPINION_SUMMARY_PREVIEW_OPEN:
            return set('summaryPreviewModalOpen', payload, state);
        case MyOpinionActionTypes.DOWNLOAD_OPINION_SUMMARY_STARTED:
            return set('isDownloadingSummary', true, state);
        case MyOpinionActionTypes.DOWNLOAD_OPINION_SUMMARY_SUCCESSFUL:
            return flow(
                set('summaryPreviewModalOpen', false),
                set('isDownloadingSummary', false)
            )(state);
        case MyOpinionActionTypes.DOWNLOAD_OPINION_SUMMARY_FAILED:
            return set('isDownloadingSummary', false, state);
        case MyOpinionActionTypes.TOGGLE_OPINION_CUSTOM_FILTERS_MODAL:
            return set('opinionCustomFiltersModalOpen', payload, state);
        case MyOpinionActionTypes.SET_SELECTED_OPINION_CUSTOM_FILTER_ID:
            return set('selectedOpinionCustomFilterId', payload, state);
        case MyOpinionActionTypes.SET_OPINION_CUSTOM_FILTER_HAS_UPDATED:
            return set('opinionCustomFilterHasUpdated', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
