import React from 'react';
import Modal from 'react-modal';

import styles from './ClauseModal.module.scss';

interface ClauseModalProps {
    isOpen: boolean;
    closeModal: () => void;
    shouldCloseOnOverlayClick?: boolean
    shouldCloseOnEsc?: boolean;
    children?: React.ReactNode;
}

export const ClauseModal: React.FC<ClauseModalProps> = ({
    isOpen,
    closeModal,
    shouldCloseOnOverlayClick = false,
    shouldCloseOnEsc = false,
    children
}) => {

    return (
        <Modal
            isOpen={isOpen}
            className={styles.clauseModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 10 } }}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            shouldCloseOnEsc={shouldCloseOnEsc}
            onRequestClose={closeModal}
        >
            {children}
        </Modal>
    );
};
