import React from 'react';

import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { WarningSign } from '../../shared/icons';
import styles from './NettingEngine.module.scss';

interface NettingEngineSectionTenModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

export const NettingEngineSectionTenModal: React.FC<NettingEngineSectionTenModalProps> = ({ isOpen, closeModal }) => (
    <ConfirmationModal
        isOpen={isOpen}
        closeModal={closeModal}
        showConfirm={false}
        closeLabel='Close'
    >
        <div className={styles.sectionTenAModalWrapper}>
            <ModalHeader
                testId='netting-engine-section-ten-modal'
                label='Section 10(a) warning'
                icon={WarningSign}
            />
            <div className={styles.modalWarning}>
                <div className={styles.modalWarningTitle}>Important!</div>
            </div>
            <div className={styles.modalContent}>It has been indicated that Section 10(a) of the ISDA Master Agreement will <span className={styles.modalImpact}>NOT</span> apply, despite the fact that the counterparty is operating as a <span className={styles.modalImpact}>Multibranch Party</span> across multiple jurisdictions.</div>
            <div className={styles.modalContent}>As such, it is <span className={styles.modalImpact}>not possible</span> to conclude that close-out netting will be enforceable, regardless of the information contained within the relevant opinions.</div>
        </div>
    </ConfirmationModal>
);
