import React, { useCallback } from 'react';
import classnames from 'classnames';
import { isNull } from 'lodash/fp';

import { BasicPlaybook, PlaybookPage, setPlaybookPage } from '../../admin/playbook/store';
import styles from './PlaybookBookcase.module.scss';
import { OverflowTooltip, TooltipList } from '../tooltip';
import { bookTiltedLeft, bookTiltedRight } from '../../constants/playbooks';
import { AgreementType, getAvailableDocumentNames } from '../../admin/documents/store';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { NAV_BAR } from '../../../hooks/useSplitView';
import { SystemTemplateBookEnd } from './SytemTemplateBookEnd';

interface PlaybookShelfProps {
    playbooks: BasicPlaybook[];
    openPlaybookCoverModal: (playbook: BasicPlaybook) => void;
    isTopShelf?: boolean;
    showDefaultBook?: boolean;
    templateShelf?: boolean;
    showTemplateBookEnd?: boolean;
    testId?: string;
}

const SIMPLE_SEARCH = 48;
const BOOKSHELVES = 60;
const BOOKSHELVES_PADDING = 80;
const MARGIN_BOTTOM = 20;

export const PlaybookShelf: React.FC<PlaybookShelfProps> = ({ playbooks, isTopShelf = false, showDefaultBook = false, openPlaybookCoverModal, templateShelf = false, showTemplateBookEnd = false, testId = 'playbooks-shelf' }) => {
    const [, screenHeight] = useWindowResize();
    const FIXED_HEIGHTS = NAV_BAR + SIMPLE_SEARCH + BOOKSHELVES + BOOKSHELVES_PADDING + MARGIN_BOTTOM;
    const bookShelfHeight = (screenHeight - FIXED_HEIGHTS) / 2;
    const dispatch = useAppDispatch();

    const allDocumentNames = useAppSelector(getAvailableDocumentNames);

    const getDocumentNames = useCallback((ids: string[] | null) => {
        const documentNames = ids ? allDocumentNames.reduce((acc: string[], { documentNameId, documentName }) => documentNameId && ids.includes(documentNameId.toString()) ? [...acc, documentName] : acc, []) : [];
        return documentNames;
    }, [allDocumentNames]);

    const createNewPlaybook = useCallback(() => dispatch(setPlaybookPage(PlaybookPage.BUILD)), [dispatch]);

    if (showDefaultBook && !templateShelf) {
        return (
            <div className={styles.bookShelf} style={{ height: bookShelfHeight }}>
                <div className={classnames(styles.bookWrapper, {
                    [styles.bookDefault]: true
                })}>
                    <div className={styles.book} onClick={createNewPlaybook}>
                        <OverflowTooltip className={styles.bookTitle} overlayText='Create your first Playbook' testId='my-playbooks-playbook-title' isVerticalText />
                    </div>
                </div>
            </div>
        );
    }

    const numberOfSystemTemplates = playbooks.filter(({ isSystemTemplate }) => !!isSystemTemplate).length;

    return (
        <div className={styles.bookShelf} style={{ height: bookShelfHeight }} data-testid={`${testId}-wrapper`}>
            {playbooks.map((playbook, index) => {
                const { name, versionMajor, versionMinor, createdBy, agreementType, agreementTypeId, documentNameIds, isDraft } = playbook;
                const showSystemTemplateBookEnd = showTemplateBookEnd && numberOfSystemTemplates > 0 && (numberOfSystemTemplates - 1) === index;
                return (
                    <>
                        <div
                            key={index}
                            onClick={() => openPlaybookCoverModal(playbook)}
                            className={classnames({
                                [styles.bookTiltedLeft]: (numberOfSystemTemplates) === index ? false : bookTiltedLeft(index, playbooks.length - 1, isTopShelf),
                                [styles.bookTiltedRight]: bookTiltedRight(index, playbooks.length - 1, isTopShelf),
                            })}
                            data-testid={`${testId}-book-${index}-wrapper`}
                        >
                            <div className={classnames(styles.bookWrapper, {
                                [styles.bookDefault]: isNull(agreementTypeId),
                                [styles.bookOne]: agreementTypeId === AgreementType.ISDA,
                                [styles.bookTwo]: agreementTypeId === AgreementType.CSA,
                                [styles.bookThree]: agreementTypeId === AgreementType.IM_CSA,
                                [styles.bookFour]: agreementTypeId === AgreementType.GMRA,
                                [styles.bookFive]: agreementTypeId === AgreementType.MRA,
                                [styles.bookSix]: agreementTypeId === AgreementType.GMSLA,
                                [styles.bookSeven]: agreementTypeId === AgreementType.MSLA,
                                [styles.bookEight]: agreementTypeId === AgreementType.DISTRIBUTION_AGREEMENT,
                                [styles.bookNine]: agreementTypeId === AgreementType.FUND_SIDE_LETTER,
                                [styles.bookTen]: agreementTypeId === AgreementType.CONFIDENTIALITY_AGREEMENT,
                                [styles.bookEleven]: agreementTypeId === AgreementType.INVESTMENT_MANAGEMENT,
                                [styles.bookTwelve]: agreementTypeId === AgreementType.ACCOUNT_CONTROL_AGREEMENT,
                                [styles.bookThirteen]: agreementTypeId === AgreementType.LOAN_AGREEMENT,
                                [styles.bookFourteen]: agreementTypeId === AgreementType.CLEARED_DERIVATIVES_EXECUTION_AGREEMENT,
                                [styles.bookFifteen]: agreementTypeId === AgreementType.RAF_REVIEW,
                            })}>
                                <div className={styles.book}>
                                    <OverflowTooltip className={styles.bookTitle} overlayText={name} testId={`${testId}-book-${index}-title`} isVerticalText />
                                    {!!isDraft && <div className={styles.draftWrapper} data-testid={`${testId}-book-${index}-draft`}>(DRAFT)</div>}
                                    <div className={styles.authorAndVolumeWrapper}>
                                        <OverflowTooltip className={styles.bookAuthor} overlayText={createdBy} testId={`${testId}-book-${index}-author`} />
                                        <div className={styles.bookVolume} data-testid={`${testId}-book-${index}-volume`}>{`Vol ${versionMajor}.${versionMinor}`}</div>
                                        <TooltipList overlayText={getDocumentNames(documentNameIds)} color='disabled' placement='bottom'>
                                            <div className={styles.bookAuthor}>
                                                {!isNull(agreementType) &&
                                                    <OverflowTooltip overlayText={agreementType} testId={`${testId}-book-${index}-agreement-type`} placement='top' />
                                                }
                                            </div>
                                        </TooltipList>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showSystemTemplateBookEnd && <SystemTemplateBookEnd />}
                    </>
                );
            })}
        </div>
    );
};
