import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { LinkDocumentTableModal } from '../../shared/modal/LinkDocumentTableModal';
import { documentColDefs } from '../../shared/table';
import { ColumnSort } from '../../shared/table/ArkTable';
import {
    clearAgreementTypeDocumentTableFilters,
    getAgreementTypeDocumentColumnSort,
    getAgreementTypeDocumentFilters,
    getAgreementTypeDocumentPageSize,
    getAgreementTypeDocuments,
    getAgreementTypeDocumentsPageNumber,
    getIsLoading,
    getTotalAgreementTypeDocuments,
    agreementTypeDocumentsPaginationNext,
    agreementTypeDocumentsPaginationPrevious,
    setAgreementTypeDocumentsPageSize,
    setAgreementTypeDocumentTableColumnSort,
    setAgreementTypeDocumentTableFilters
} from './store';
import { TableFilterType } from '../../shared/modal/TableFilterModal';

interface ISDADocumentsModalProps {
    select: (value: number) => void;
    close: () => void;
    selectedId: number | null;
}

export const ISDADocumentsModal: React.FC<ISDADocumentsModalProps> = ({ select, close, selectedId }) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getIsLoading);

    const isdaDocuments = useAppSelector(getAgreementTypeDocuments);

    const pageNumber = useAppSelector(getAgreementTypeDocumentsPageNumber);
    const totalDocuments = useAppSelector(getTotalAgreementTypeDocuments);
    const filters = useAppSelector(getAgreementTypeDocumentFilters);
    const columnSort = useAppSelector(getAgreementTypeDocumentColumnSort);
    const pageSize = useAppSelector(getAgreementTypeDocumentPageSize);
    const nextPage = useCallback(() => { dispatch(agreementTypeDocumentsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(agreementTypeDocumentsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setAgreementTypeDocumentTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearAgreementTypeDocumentTableFilters()); }, [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setAgreementTypeDocumentTableColumnSort(columnSort)); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setAgreementTypeDocumentsPageSize(pageSize)); }, [dispatch]);

    const selectedIds = useMemo(() => selectedId ? [selectedId] : null, [selectedId]);

    return (
        <LinkDocumentTableModal
            isOpen
            close={close}
            title='ISDA Master Agreement Documents'
            testId='original-link-isda-documents'
            documents={isdaDocuments}
            selectedIds={selectedIds}
            selectedField='documentId'
            columnDefs={documentColDefs}
            select={select}
            page={pageNumber}
            total={totalDocuments}
            filters={filters}
            next={nextPage}
            previous={previousPage}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            isLoading={isLoading}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
        />
    );
};
