import { getOr, isNull, isUndefined, set } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAvailableDocumentNamesStarted, getAvailableDocumentNames } from '../../admin/documents/store';
import { getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { fetchAllEntitiesStarted, getBaseEntityDropdownOptions } from '../../admin/entity/store';
import { CustomFilters, FilterTable } from '../../auth/login/store';
import { allFocusOptions, opinionCommissionedByOptions, opinionScopeOptions } from '../../constants/opinion';
import { FilterDropdownOptions, TableFilters, TableFilterType } from '../../shared/modal/TableFilterModal';
import { CustomFilterHeader, ReadOnlyFilters } from '../../shared/table/ReadOnlyFilters';

interface ReadOnlyCustomFiltersBodyProps {
    selectedTile: CustomFilters | null;
}

export const ReadOnlyCustomFiltersBody: React.FC<ReadOnlyCustomFiltersBodyProps> = (({ selectedTile }) => {
    useFetchStarted([fetchAvailableDocumentNamesStarted(), fetchAllEntitiesStarted(true)]);

    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const entityOptions = useAppSelector(getBaseEntityDropdownOptions);
    const dropdownLists = useAppSelector(getAllDropdownLists);

    // Documents
    const documentFilterHeaders: CustomFilterHeader[] = useMemo(() => [{ header: 'Document Description', field: 'documentDescription' }, { header: 'Document Name', field: 'documentName' }, { header: 'Entity A', field: 'entityA' }, { header: 'Entity B', field: 'entityB' }], []);
    const documentNameOptions = useMemo(() => allDocumentNames
        .filter(({ datasetId }) => datasetId)
        .map(({ documentName }) => ({ value: documentName, label: documentName })), [allDocumentNames]
    );

    const documentFilterDropdownOptions = useMemo(() => ({
        documentName: documentNameOptions,
        entityA: entityOptions,
        entityB: entityOptions
    }), [documentNameOptions, entityOptions]);

    // Opinions
    const opinionFilterHeaders: CustomFilterHeader[] = useMemo(() => [{ header: 'Jurisdiction', field: 'jurisdiction' }, { header: 'Focus', field: 'focus' }, { header: 'Bespoke', field: 'bespoke' }, { header: 'Scope', field: 'scope' }, { header: 'Commissioned By', field: 'commissionedBy' }], []);
    const opinionJurisdictionOptions = useMemo(() => {
        const jurisdictionList = dropdownLists.find(({ name }) => name === 'OpinionJurisdiction');
        return jurisdictionList ? jurisdictionList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);
    const bespokeOptions = useMemo(() => [{ label: 'Bespoke', value: '1' }, { label: 'Industry Standard', value: '0' }], []);
    const scopeOptions = useMemo(() => opinionScopeOptions(), []);
    const commissionedByOptions = useMemo(() => opinionCommissionedByOptions(), []);

    const opinionFilterDropdownOptions = useMemo(() => ({
        scope: scopeOptions,
        bespoke: bespokeOptions,
        commissionedBy: commissionedByOptions,
        jurisdiction: opinionJurisdictionOptions,
        focus: allFocusOptions
    }), [bespokeOptions, opinionJurisdictionOptions, scopeOptions, commissionedByOptions]);

    const isDocument = selectedTile?.filterTable === FilterTable.DOCUMENT;
    const dropdownOptions: FilterDropdownOptions = isDocument ? documentFilterDropdownOptions : opinionFilterDropdownOptions;
    const customFilterHeaders = isDocument ? documentFilterHeaders : opinionFilterHeaders;
    const filters: TableFilters | null = useMemo(() => {
        if (isNull(selectedTile)) {
            return null;
        }
        return customFilterHeaders.reduce((acc, curr) => {
            const fieldFilters: TableFilterType | undefined = getOr(undefined, curr.field, selectedTile);
            if (!isUndefined(fieldFilters)) {
                return set(curr.field, fieldFilters, acc);
            }
            return acc;
        }, {});
    }, [selectedTile, customFilterHeaders]);

    if (isNull(filters)) {
        return null;
    }

    return (
        <ReadOnlyFilters
            customFilterHeaders={customFilterHeaders}
            filterDropdownOptions={dropdownOptions}
            filters={filters}
        />
    );
});
