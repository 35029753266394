import React, { useCallback, useMemo } from 'react';

import styles from './MyCompanies.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getEntityIsSaving, getIsFetchingEntityFunctionIds, getSelectedEntityFunctionIds, updateSelectedEntityFunctionIds } from './store';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { LongText } from '../../shared/icons';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { FeaturePermission } from '../../admin/users/store';
import { clearFunctionsTableFilters, functionsPaginationNext, functionsPaginationPrevious, getAllDoraFunctions, setFunctionsPageSize, setFunctionsTableColumnSort, setFunctionsTableFilters, getTotalDoraFunctions, getDoraFunctionDropdownOptions, getDoraFunctionsColumnSort, getDoraFunctionsFilters, getDoraFunctionsPageNumber, getDoraFunctionsPageSize, } from '../functions/store';

export const MyCompanyFunctionsPerformedTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const doraFunctions = useAppSelector(getAllDoraFunctions);
    const isLoading = useAppSelector(getIsFetchingEntityFunctionIds);
    const pageNumber = useAppSelector(getDoraFunctionsPageNumber);
    const totalCompanies = useAppSelector(getTotalDoraFunctions);
    const functionFilters = useAppSelector(getDoraFunctionsFilters);
    const columnSort = useAppSelector(getDoraFunctionsColumnSort);
    const pageSize = useAppSelector(getDoraFunctionsPageSize);
    const doraFunctionOptions = useAppSelector(getDoraFunctionDropdownOptions);
    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));
    const selectedEntityFunctionIds = useAppSelector(getSelectedEntityFunctionIds);
    const entityIsSaving = useAppSelector(getEntityIsSaving);

    const nextPage = useCallback(() => dispatch(functionsPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(functionsPaginationPrevious()), [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setFunctionsTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearFunctionsTableFilters()), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setFunctionsTableColumnSort(columnSort)), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setFunctionsPageSize(pageSize)), [dispatch]);

    const toggleDoraFunction = useCallback((functionId: string | number) => {
        const id = typeof functionId === 'string' ? parseInt(functionId) : functionId;
        const alreadyIncluded = selectedEntityFunctionIds.includes(id) ? selectedEntityFunctionIds.filter(functionId => functionId !== id) : [...selectedEntityFunctionIds, id];
        dispatch(updateSelectedEntityFunctionIds(alreadyIncluded));
    }, [dispatch, selectedEntityFunctionIds]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'name', header: 'Name', field: 'name', width: 0.80, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'description', header: 'Description', field: 'description', width: 0.15, component: 'iconTooltip', icon: LongText },
        { id: 'doraFunctionId', header: 'Performs Function', field: 'doraFunctionId', width: 0.15, select: toggleDoraFunction, selected: selectedEntityFunctionIds, disabled: () => !hasDoraFullAccessPermission || entityIsSaving, component: 'checkbox' },
    ];

    const filterDropdownOptions = useMemo(() => ({
        name: doraFunctionOptions
    }), [doraFunctionOptions]);

    const [screenWidth, screenHeight] = useWindowResize();

    const tableWidth = screenWidth * 0.8;
    const tableHeight = ((screenHeight * 0.7) / 10) * 4.5;

    return (
        <div className={styles.functionsTableWrapper}>
            <ArkTable
                colDefs={columnDefs}
                rowData={doraFunctions}
                testId='dora-functions'
                isLoading={isLoading}
                page={pageNumber}
                total={totalCompanies}
                next={nextPage}
                previous={previousPage}
                filters={functionFilters}
                updateFilter={updateFilter}
                clearAllFilters={clearAllFilters}
                columnSort={columnSort}
                toggleSort={toggleColumnSort}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                filterDropdownOptions={filterDropdownOptions}
                width={tableWidth}
                height={tableHeight}
            />
        </div>
    );
};
