import React from 'react';

import { Calendar } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';

interface DeleteConfirmationModalProps {
    isOpen: boolean;
    name: string;
    deleteScheduledAction: () => void;
    isDeleting: boolean;
    closeModal: () => void;
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ isOpen, deleteScheduledAction, closeModal, isDeleting, name }) => (
    <TypeDeleteConfirmationModal
        isOpen={isOpen}
        closeModal={closeModal}
        deleteAction={deleteScheduledAction}
        isDeleting={isDeleting}
        headerLabel='Delete Scheduled Action'
        name={name}
        testId='scheduled-action'
        deleteLabel='scheduled action'
        icon={Calendar}
    />
);
