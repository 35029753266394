import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isNull } from 'lodash/fp';

import { Colors, CustomTooltip } from './Tooltip';

interface OverflowTooltipProps {
    overlayText: JSX.Element | string | null;
    onClick?: () => void;
    arrowContent?: React.ReactNode | undefined;
    color?: keyof Colors;
    width?: number;
    trigger?: string;
    placement?: string;
    className?: string;
    testId?: string;
    isVerticalText?: boolean;
}

export const OverflowTooltip: React.FC<OverflowTooltipProps> = ({ overlayText, color, width, trigger, placement, className, testId, onClick, isVerticalText = false }) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const textElementRef = useRef<HTMLDivElement>(null);

    const compareSize = useCallback(() => {
        if (!isNull(textElementRef) && !isNull(textElementRef.current)) {
            isVerticalText ? setIsOverflow(textElementRef.current.scrollHeight > textElementRef.current.clientHeight) : setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
        }
    }, [textElementRef, isVerticalText]);

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
        () => {
            window.removeEventListener('resize', compareSize);
        };
    }, [compareSize]);

    const content = useMemo(() => isOverflowed ? overlayText : null, [isOverflowed, overlayText]);

    return (
        <CustomTooltip
            overlayText={content}
            trigger={trigger}
            color={color}
            width={width}
            placement={placement}
        >
            <div
                ref={textElementRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: trigger === 'click' && isOverflowed ? 'pointer' : 'default'
                }}
                className={className}
                data-testid={`${testId}-overflow-tooltip`}
                onClick={onClick}
            >
                {overlayText}
            </div>
        </CustomTooltip>
    );
};
