import React from 'react';

import { useWindowResize } from '../../../hooks/useWindowResize';
import { PositionModal, Position } from '../../shared/modal/PositionModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import styles from './ClauseModal.module.scss';
import { Icon } from '../../shared/icon/Icon';
import { Tick } from '../../shared/icons';
import { getTagBackgroundColour } from './ClauseModalTags';
import { ClauseTags, FuzzyMatchTags } from '../../clause-library/store';

const { white } = styles;

interface TagsFuzzyMatchModalProps {
    isOpen: boolean;
    fuzzyModalPosition: Position;
    inputWidth: number;
    toggleTag: (key: keyof ClauseTags, value: string) => void
    tagInUse: (value: string) => boolean;
    closeModal: () => void;
    fuzzyTags: FuzzyMatchTags[];
    testId: string;
}

export const TagsFuzzyMatchModal: React.FC<TagsFuzzyMatchModalProps> = ({ isOpen, fuzzyModalPosition, inputWidth, toggleTag, tagInUse, closeModal, fuzzyTags, testId }) => {
    const [, screenHeight] = useWindowResize();
    const maxScrollableHeight = (screenHeight - fuzzyModalPosition.y) - 20;

    return (
        <PositionModal
            isOpen={isOpen}
            position={fuzzyModalPosition}
            height='fit-content'
            width={`${inputWidth}px`}
            closeModal={closeModal}
            testId={`${testId}-modal-fuzzy-matches`}
            overflow='hidden'
        >
            <div className={styles.fuzzyMatchesModalWrapper}>
                <Scrollable maxHeight={`${maxScrollableHeight}px`}>
                    {
                        fuzzyTags.map(({ key, value }, index) => {
                            const tagSelected = tagInUse(value);
                            return (
                                <div key={key} className={styles.fuzzyMatchWrapper} onClick={() => toggleTag(key, value)} data-testid={`${testId}-modal-fuzzy-match-${index}-wrapper`}>
                                    <div className={styles.fuzzyMatchType} style={{ backgroundColor: getTagBackgroundColour(key) }} data-testid={`${testId}-modal-fuzzy-match-${index}-icon`}>
                                        {tagSelected && <Icon color={white} icon={Tick} fontSize={18} />}
                                    </div>
                                    <div className={styles.fuzzyMatchTitle} data-testid={`${testId}-modal-fuzzy-match-${index}-label`}>{value}</div>
                                </div>
                            );
                        })
                    }
                </Scrollable>
            </div>
        </PositionModal>
    );
};
