import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, fork, put, takeEvery, takeLeading, call, select, debounce } from 'redux-saga/effects';
import { isNull, isUndefined } from 'lodash/fp';

import {
    calculateExistingDocumentsNettingEngineFailed,
    calculateExistingDocumentsNettingEngineSuccessful,
    calculateNettingEngineTheoryFailed,
    calculateNettingEngineTheorySuccessful,
    enquireOpinionFailed,
    enquireOpinionSuccessful,
    enquireSingleNettingOpinion,
    fetchNettingEngineDocumentsFailed,
    fetchNettingEngineDocumentsStarted,
    fetchNettingEngineDocumentsSuccessful,
    openNettingEngineDocumentFailed,
    openNettingEngineDocumentStarted,
    openNettingEngineDocumentSuccessful,
    resetNettingEnginePage,
    setNettingEnginePage
} from './actions';
import { OpinionNettingEngineActionTypes, NettingPage, NettingResponseInformation, NettingEngineTheory, NettingRequestInformation, NettingEngineTableView } from './types';
import { enquireNetting, calculateNettingEngineAnalysis, fetchAllNettingDocuments, getNettingDocument } from '../../../../services/opinion';
import { getCurrentNettingEngineDocument, getLocalLawIsValidJurisdiction, getNettingEngineDocumentColumnSort, getNettingEngineDocumentFilters, getNettingEngineDocumentPageSize, getNettingEngineDocumentsPageNumber, getNettingEngineTableView, getNettingEngineTheorySurvey, getUnauthorisedOpinionJurisdictions } from './selectors';
import { ArkDocument, DocumentType, openDocumentSuccessful, openSecondaryDocumentStarted, resetSecondaryDocument, SelectedDocument, setSecondaryDocument, toggleDocumentInView } from '../../../documents/my-documents/store';
import { getDocumentById, openDocument } from '../../../../services/document';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { getDocumentUrl } from '../../../../utils/getDocumentUrl';

export function* redirectNettingEnginePage({ payload }: ReturnType<typeof setNettingEnginePage>) {
    let navigationPath = '/opinions/netting-engine';
    if (payload !== NettingPage.SELECT) {
        navigationPath = `/opinions/netting-engine/${payload === NettingPage.DOCUMENTS ? 'documents' : 'theory'}`;
    } else {
        yield put(resetNettingEnginePage());
    }
    yield put(push(navigationPath));
}

function* redirectNettingEnginePageWatcher() {
    yield takeEvery(OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_PAGE, redirectNettingEnginePage);
}

export function* attemptCalculateNettingEngineTheory() {
    try {
        let { counterparty: { jurisdiction, counterpartyClassification }, governingLaw, agreement, productsTraded, counterpartyOffices }: NettingEngineTheory = yield select(getNettingEngineTheorySurvey);
        const request: NettingRequestInformation = {
            counterpartyJurisdiction: jurisdiction || '',
            governingLawJurisdiction: governingLaw || '',
            agreementType: agreement!,
            productTypes: productsTraded || [],
            branches: counterpartyOffices,
            counterpartyType: counterpartyClassification || ''
        };
        const result: NettingResponseInformation = yield call(calculateNettingEngineAnalysis, request);
        yield put(calculateNettingEngineTheorySuccessful(result));
    } catch (e) {
        yield put(calculateNettingEngineTheoryFailed((e as Error).message));
        toast.error('Unable to calculate the results. Please try again.');
    }
}

function* calculateNettingEngineTheoryWatcher() {
    yield takeLeading(OpinionNettingEngineActionTypes.CALCULATE_NETTING_ENGINE_THEORY_STARTED, attemptCalculateNettingEngineTheory);
}

export function* attemptNettingEngineOpinionEnquireAll() {
    try {
        const jurisdictions: string[] = yield select(getUnauthorisedOpinionJurisdictions);
        const request = { jurisdictions };
        if (jurisdictions.length > 0) {
            yield call(enquireNetting, request);
        }
        yield put(enquireOpinionSuccessful(jurisdictions));
        toast('Enquiry sent');
    } catch (e) {
        toast.error('Unable to enquire about opinions. Please try again.');
        yield put(enquireOpinionFailed());
    }
}

function* nettingEngineOpinionEnquireAllWatcher() {
    yield takeLeading(OpinionNettingEngineActionTypes.ENQUIRE_ALL_NETTING_OPINIONS_STARTED, attemptNettingEngineOpinionEnquireAll);
}

export function* attemptNettingEngineOpinionEnquireSingle({ payload }: ReturnType<typeof enquireSingleNettingOpinion>) {
    try {
        const unauthorisedJurisdictions: string[] = yield select(getUnauthorisedOpinionJurisdictions);
        const localLawJurisdiction: string | null = yield select(getLocalLawIsValidJurisdiction);
        const jurisdictions = isNull(localLawJurisdiction) ? [payload] : [payload, localLawJurisdiction];
        if (unauthorisedJurisdictions.includes(payload)) {
            const request = { jurisdictions };
            yield call(enquireNetting, request);
        }
        yield put(enquireOpinionSuccessful(jurisdictions));
        toast('Enquiry sent');
    } catch (e) {
        toast.error('Unable to enquire about opinion. Please try again.');
        yield put(enquireOpinionFailed());
    }
}

function* nettingEngineOpinionEnquireSingleWatcher() {
    yield takeLeading(OpinionNettingEngineActionTypes.ENQUIRE_SINGLE_NETTING_OPINION_STARTED, attemptNettingEngineOpinionEnquireSingle);
}

export function* attemptFetchAllNettingDocuments({ payload }: ReturnType<typeof fetchNettingEngineDocumentsStarted>) {
    try {
        const { pageNumber, documentType } = payload;
        const filters: TableFilters = yield select(getNettingEngineDocumentFilters);
        const columnSort: ColumnSort | undefined = yield select(getNettingEngineDocumentColumnSort);
        const pageSize: number = yield select(getNettingEngineDocumentPageSize);
        const { documents, total }: { documents: ArkDocument[], total: number } = yield call(fetchAllNettingDocuments, { filters, pageNumber, columnSort, pageSize, documentType });
        yield put(fetchNettingEngineDocumentsSuccessful(documents, total, pageNumber));
    } catch (e) {
        toast.error('Unable to fetch the latest netting engine documents. Please try again.');
        yield put(fetchNettingEngineDocumentsFailed((e as Error).message));
    }
}

function* attemptFetchAllNettingDocumentsWatcher() {
    yield takeEvery(OpinionNettingEngineActionTypes.FETCH_NETTING_ENGINE_DOCUMENTS_STARTED, attemptFetchAllNettingDocuments);
}

// Netting Engine Document Pagination Sagas

export function* nettingEnginePaginationNext() {
    const pageNumber: number = yield select(getNettingEngineDocumentsPageNumber);
    const nettingEngineView: NettingEngineTableView = yield select(getNettingEngineTableView);
    const documentFetchType = nettingEngineView === NettingEngineTableView.EXISTING ? DocumentType.ORIGINAL : DocumentType.PRE_EXECUTION_DRAFT;
    yield put(fetchNettingEngineDocumentsStarted(pageNumber + 1, documentFetchType));
}

function* nettingEnginePaginationNextWatcher() {
    yield takeEvery(OpinionNettingEngineActionTypes.NETTING_ENGINE_DOCUMENTS_PAGINATION_NEXT, nettingEnginePaginationNext);
}

export function* nettingEnginePaginationPrevious() {
    const pageNumber: number = yield select(getNettingEngineDocumentsPageNumber);
    const nettingEngineView: NettingEngineTableView = yield select(getNettingEngineTableView);
    const documentFetchType = nettingEngineView === NettingEngineTableView.EXISTING ? DocumentType.ORIGINAL : DocumentType.PRE_EXECUTION_DRAFT;
    yield put(fetchNettingEngineDocumentsStarted(pageNumber - 1, documentFetchType));
}

function* nettingEnginePaginationPreviousWatcher() {
    yield takeEvery(OpinionNettingEngineActionTypes.NETTING_ENGINE_DOCUMENTS_PAGINATION_PREVIOUS, nettingEnginePaginationPrevious);
}

export function* nettingEngineTableConfigUpdated() {
    const nettingEngineView: NettingEngineTableView = yield select(getNettingEngineTableView);
    const documentFetchType = nettingEngineView === NettingEngineTableView.EXISTING ? DocumentType.ORIGINAL : DocumentType.PRE_EXECUTION_DRAFT;
    yield put(fetchNettingEngineDocumentsStarted(1, documentFetchType));
}

function* nettingEngineTableConfigUpdatedWatcher() {
    yield debounce(200, [OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS, OpinionNettingEngineActionTypes.CLEAR_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS, OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENT_TABLE_COLUMN_SORT, OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENTS_PAGE_SIZE], nettingEngineTableConfigUpdated);
}

export function* attemptOpenNettingEngineDocument({ payload }: ReturnType<typeof openNettingEngineDocumentStarted>) {
    try {
        const { documentId, shouldRedirect } = payload;
        const { nettingDocument, location, mimeType, originalDocumentId, latestDocumentId }: { nettingDocument: NettingEngineTheory, location: string, mimeType: string, originalDocumentId: number, latestDocumentId: number } = yield call(getNettingDocument, { documentId });
        const document: ArkDocument = yield call(getDocumentById, { documentId });
        const documentBlob: Blob = yield call(openDocument, { location });
        const documentUrl: string = yield call(getDocumentUrl, mimeType, documentBlob);
        yield put(openDocumentSuccessful(documentUrl, document));
        if (originalDocumentId && latestDocumentId && originalDocumentId !== latestDocumentId) {
            const secondaryDocument: ArkDocument = yield call(getDocumentById, { documentId: latestDocumentId });
            yield put(openSecondaryDocumentStarted(secondaryDocument));
            yield put(setSecondaryDocument(secondaryDocument));
            yield put(toggleDocumentInView(SelectedDocument.SECONDARY));
        } else {
            yield put(resetSecondaryDocument());
            yield put(toggleDocumentInView(SelectedDocument.ORIGINAL));
        }
        yield put(openNettingEngineDocumentSuccessful(nettingDocument));
        if (shouldRedirect) {
            yield put(push(`/opinions/netting-engine/documents/${documentId}`));
        }
    } catch (e) {
        yield put(openNettingEngineDocumentFailed((e as Error).message));
        yield put(push('/opinions/netting-engine'));
        toast.error('Unable to open netting engine document. Please try again.');
    }
}

function* openNettingEngineDocumentWatcher() {
    yield takeEvery(OpinionNettingEngineActionTypes.OPEN_NETTING_ENGINE_DOCUMENT_STARTED, attemptOpenNettingEngineDocument);
}

export function* attemptCalculateExistingDocumentsNettingEngine() {
    try {
        let { counterparty: { jurisdiction, counterpartyClassification }, governingLaw, agreement, productsTraded, counterpartyOffices, sectionTenAApplies }: NettingEngineTheory = yield select(getCurrentNettingEngineDocument);
        const request: NettingRequestInformation = {
            counterpartyJurisdiction: jurisdiction || '',
            governingLawJurisdiction: governingLaw || '',
            agreementType: agreement!,
            productTypes: productsTraded || [],
            branches: counterpartyOffices,
            counterpartyType: counterpartyClassification || ''
        };
        if (!isNull(sectionTenAApplies) && !isUndefined(sectionTenAApplies)) {
            request.sectionTenAApplies = sectionTenAApplies;
        }
        const result: NettingResponseInformation = yield call(calculateNettingEngineAnalysis, request);
        yield put(calculateExistingDocumentsNettingEngineSuccessful(result));
    } catch (e) {
        yield put(calculateExistingDocumentsNettingEngineFailed((e as Error).message));
        toast.error('Unable to calculate the results. Please try again.');
    }
}

function* calculateExistingDocumentsNettingEngineWatcher() {
    yield takeLeading(OpinionNettingEngineActionTypes.CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_STARTED, attemptCalculateExistingDocumentsNettingEngine);
}

export function* opinionNettingEngineSaga() {
    yield all([
        fork(redirectNettingEnginePageWatcher),
        fork(calculateNettingEngineTheoryWatcher),
        fork(nettingEngineOpinionEnquireAllWatcher),
        fork(nettingEngineOpinionEnquireSingleWatcher),
        fork(attemptFetchAllNettingDocumentsWatcher),
        fork(nettingEnginePaginationNextWatcher),
        fork(nettingEnginePaginationPreviousWatcher),
        fork(nettingEngineTableConfigUpdatedWatcher),
        fork(openNettingEngineDocumentWatcher),
        fork(calculateExistingDocumentsNettingEngineWatcher)
    ]);
}
