import { isNull, max, min, noop, set } from 'lodash/fp';
import Slider from 'rc-slider';
import React, { useCallback, useMemo } from 'react';

import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { CustomTooltip } from '../../../shared/tooltip';
import styles from './Theoretical.module.scss';
import { NettingEngineSurvey, NettingEngineQuestionType } from '../store';

const { french, grey, white } = styles;

interface MarkerProps {
    nettingSurvey: NettingEngineSurvey;
}

const Marker: React.FC<MarkerProps> = ({ nettingSurvey }) => {
    const { type, isMulti, value, question } = nettingSurvey;
    let answer = '';
    if (isNull(value) || (value as DropdownOption[]).length === 0) {
        answer = '';
    } else {
        if (type === NettingEngineQuestionType.BOOLEAN) answer = !value ? 'No' : 'Yes';
        if (type === NettingEngineQuestionType.DROPDOWN) {
            if (isMulti) {
                const answers = (value as DropdownOption[]).map(({ label }) => label);
                answer = `${answers[0]}${answers.length > 1 ? ` (+${answers.length - 1})` : ''}`;
            } else {
                answer = (value as DropdownOption).label;
            }
        }
    }
    const overlayText = answer ? `${question}: ${answer}` : nettingSurvey.question;
    return (
        <CustomTooltip overlayText={overlayText} placement='top'>
            <div className={styles.sliderMarker} />
        </CustomTooltip>
    );
};

interface TheoreticalSurveySliderProps {
    surveyQuestionIndex: number;
    nettingEngineSurvey: NettingEngineSurvey[];
    setQuestionIndex: (index: number) => void;
}

export const TheoreticalSurveySlider: React.FC<TheoreticalSurveySliderProps> = ({
    surveyQuestionIndex,
    nettingEngineSurvey,
    setQuestionIndex
}) => {
    const surveyQuestions = useMemo(() => nettingEngineSurvey.reduce((acc, nettingSurvey, index) => set(index, <Marker nettingSurvey={nettingSurvey} />, acc), {}), [nettingEngineSurvey]);

    const minValue = min((Object.keys(surveyQuestions).map(val => parseInt(val))));
    const maxValue = max((Object.keys(surveyQuestions).map(val => parseInt(val))));
    const minimum = minValue && maxValue !== minValue ? minValue : 0;

    const selectSurveyPoint = useCallback((index: number | number[]) => {
        if (index > surveyQuestionIndex && isNull(nettingEngineSurvey[surveyQuestionIndex].value)) {
            return noop;
        }
        if (!isNull(nettingEngineSurvey[index as number].value) || isNull(nettingEngineSurvey[index as number].value) && !isNull(nettingEngineSurvey[(index as number) - 1].value)) {
            setQuestionIndex(index as number);
        }
    }, [nettingEngineSurvey, setQuestionIndex, surveyQuestionIndex]);

    return (
        <div className={styles.surveyQuestionSlider}>
            <Slider
                min={minimum}
                marks={surveyQuestions}
                onChange={selectSurveyPoint}
                defaultValue={maxValue}
                value={surveyQuestionIndex}
                max={maxValue}
                step={1}
                trackStyle={{ backgroundColor: french }}
                railStyle={{ backgroundColor: grey }}
                handleStyle={{ backgroundColor: white, border: `1px solid ${french}`, height: '16px', width: '16px', marginTop: '-6px', opacity: 1 }}
                dotStyle={{ backgroundColor: white, border: `1px solid ${french}` }}
            />
        </div>
    );
};
