import { flow, isNull, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { Condition, LogicalOperator, PreExecutionFilterOption, SearchDocumentActionTypes, SearchDocumentsState, SearchDocumentsView } from './types';

export const initialSmartSearchTerm = {
    logicalOperator: LogicalOperator.AND,
    condition: Condition.INCLUDES,
    searchTerm: ''
};

export const initialSearchFilter = {
    documentNameId: null,
    entityId: null,
    includePreExecution: PreExecutionFilterOption.EXCLUDE,
    date: {
        to: null,
        from: null
    },
    isExclusiveEntityFilter: false
};

export const INITIAL_STATE: SearchDocumentsState = {
    searchDocumentsView: SearchDocumentsView.SIMPLE_SEARCH,
    filterModalOpen: false,
    simpleSearchTerm: '',
    smartSearchTerm: [initialSmartSearchTerm],
    isSearching: false,
    searchResults: null,
    searchFilter: initialSearchFilter,
    searchResultsType: null,
    totalSearchResults: 0,
    searchPage: 1,
    totalSearchPages: 0,
    searchError: null,
    isLoading: false,
    isOpening: false,
    documentPreviewModalOpen: false,
    documentPreviewUrl: null,
    documentPreviewResult: null,
    documentPreviewError: null,
};

export const searchDocumentsReducer: Reducer<SearchDocumentsState> = (state = INITIAL_STATE, action): SearchDocumentsState => {
    switch (action.type) {
        case SearchDocumentActionTypes.TOGGLE_SEARCH_DOCUMENTS_VIEW: {
            const { view, isPreExecution } = action.payload;
            const includePreExecution = isPreExecution ? PreExecutionFilterOption.EXCLUSIVE : PreExecutionFilterOption.EXCLUDE;
            if (!isNull(view)) {
                return flow(
                    set('searchDocumentsView', view),
                    set('searchFilter.includePreExecution', includePreExecution),
                )(state);
            }
            return state;
        }
        case SearchDocumentActionTypes.UPDATE_SEARCH_FILTER: {
            const { key, value } = action.payload;
            return set(`searchFilter[${key}]`, value, state);
        }
        case SearchDocumentActionTypes.UPDATE_EXECUTED_DATE_FILTER: {
            const { key, value } = action.payload;
            return set(`searchFilter.date[${key}]`, value, state);
        }
        case SearchDocumentActionTypes.RESET_FILTERS:
            return set('searchFilter', initialSearchFilter, state);
        case SearchDocumentActionTypes.TOGGLE_SEARCH_FILTER_MODAL:
            return set('filterModalOpen', action.payload, state);
        case SearchDocumentActionTypes.UPDATE_SIMPLE_SEARCH:
            return set('simpleSearchTerm', action.payload, state);
        case SearchDocumentActionTypes.UPDATE_SMART_SEARCH:
            return set('smartSearchTerm', action.payload, state);
        case SearchDocumentActionTypes.SEARCH_DOCUMENTS_STARTED:
            return flow(
                set('isSearching', true),
                set('searchPage', action.payload)
            )(state);
        case SearchDocumentActionTypes.SEARCH_DOCUMENTS_SUCCESSFUL: {
            const { searchResults, pages, searchType, total } = action.payload;
            return flow(
                set('isSearching', false),
                set('searchResults', searchResults),
                set('totalSearchPages', pages),
                set('searchResultsType', searchType),
                set('totalSearchResults', total)
            )(state);
        }
        case SearchDocumentActionTypes.SEARCH_DOCUMENTS_FAILED:
            return flow(
                set('isSearching', false),
                set('searchError', action.payload)
            )(state);
        case SearchDocumentActionTypes.RESET_SEARCH:
            return flow(
                set('isSearching', false),
                set('searchResults', null),
                set('searchPage', 1),
                set('totalSearchPages', 0),
                set('searchResultsType', null),
                set('simpleSearchTerm', ''),
                set('smartSearchTerm', [initialSmartSearchTerm]),
                set('searchFilter', initialSearchFilter),
                set('totalSearchResults', 0)
            )(state);
        case SearchDocumentActionTypes.TOGGLE_DOCUMENT_PREVIEW_MODAL:
            return set('documentPreviewModalOpen', action.payload, state);
        case SearchDocumentActionTypes.OPEN_DOCUMENT_PREVIEW_STARTED:
            return flow(
                set('documentPreviewModalOpen', true),
                set('isOpening', true),
                set('documentPreviewUrl', null),
                set('documentPreviewResult', null),
                set('documentPreviewError', null)
            )(state);
        case SearchDocumentActionTypes.OPEN_DOCUMENT_PREVIEW_SUCCESSFUL: {
            const { previewResult, documentUrl } = action.payload;
            return flow(
                set('isOpening', false),
                set('documentPreviewUrl', documentUrl),
                set('documentPreviewResult', previewResult)
            )(state);
        }
        case SearchDocumentActionTypes.OPEN_DOCUMENT_PREVIEW_FAILED:
            return flow(
                set('isOpening', false),
                set('documentPreviewError', action.payload)
            )(state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
