import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { SingleDatasetField } from '../../datasets/store';
import { DraggableItem } from '../../shared/drag-n-drop/shared';
import sharedStyles from './SharedBuilder.module.scss';
import { Content } from './dataset-components/Content';
import { getTableDatasetFields, updateDatasetFields } from './store';
import { DragDrop } from '../../shared/drag-n-drop/DragDrop';

export const TableDatasetBuilder: React.FC = () => {
    const fields = useAppSelector(getTableDatasetFields);
    const dispatch = useAppDispatch();
    const [, screenHeight] = useWindowResize();

    const listOptions = useMemo(() => fields.map(({ id, label, type }) => ({ id: id!, label: label ? `${label} (${type})` : type })), [fields]);

    const updateList = (list: DraggableItem[]) => {
        const newFields = list.map(({ id }) => fields.find(field => field.id === id)!);
        dispatch(updateDatasetFields(newFields));
    };

    const getChildElement = useCallback((childId: string, index: number) => {
        const field = fields.find(({ id }) => id === childId);
        if (field) {
            return (
                <Content field={field as SingleDatasetField} key={field.id} index={index} />
            );
        }
        return null;
    }, [fields]);

    // This is the height of the modal minus other fixed height elements such as header, title and buttons wrapper
    const droppableHeight = screenHeight * 0.8 - (41 + 60 + 58);
    return (
        <div className={sharedStyles.tableBuilder} data-testid='table-dataset-builder-wrapper' style={{ height: `${droppableHeight}px` }}>
            {fields.length > 0 ?
                <DragDrop getChildElement={getChildElement} list={listOptions} listId='table-builder' updateList={updateList} /> :
                <div className={sharedStyles.placeholder}>
                    <div className={sharedStyles.placeholderText} data-testid='table-dataset-builder-no-fields'>Select a dataset field to begin building dataset</div>
                </div>
            }
        </div>
    );
};
