import { flattenDeep } from 'lodash/fp';

import { SingleDatasetField, DatasetFieldType, isFormDatasetDefinition, isGroupField, DatasetDefinition, FormDatasetDefinition } from '../../../datasets/store';

export const validateField = (field: SingleDatasetField) => {
    switch (field.type) {
        case DatasetFieldType.TEXT:
        case DatasetFieldType.LONGTEXT:
        case DatasetFieldType.DATE:
        case DatasetFieldType.NUMBER:
        case DatasetFieldType.TIME:
        case DatasetFieldType.CHECKBOX:
        case DatasetFieldType.LINKFIELD:
        case DatasetFieldType.WYSIWYG:
        case DatasetFieldType.PARTY:
        case DatasetFieldType.CURRENCY_AMOUNT:
            return true;
        case DatasetFieldType.DROPDOWN:
            return !!field.settings.dropdownLinked;
        case DatasetFieldType.LABEL:
            return !!field.settings.labelContent;
        case DatasetFieldType.DOCUMENT:
            return !!field.settings.documentProperty;
        case DatasetFieldType.DATASET:
            return !!field.settings.datasetLinked;
        case DatasetFieldType.CALCULATOR:
            return !!field.settings.calculatorFieldType;
        default:
            return false;
    }
};

export const validateAllFields = (definition: DatasetDefinition) => {
    if (isFormDatasetDefinition(definition)) {
        const allFields = flattenDeep(Object.values(definition.datasetFields));
        return allFields.every(field => {
            if (isGroupField(field)) {
                return field.children.every(childField => validateField(childField));
            }
            return validateField(field);
        });
    }
    return definition.datasetFields.every(field => validateField(field));
};

export const validateFieldLabels = (definition: DatasetDefinition) => {
    const { datasetFields } = definition;
    if (isFormDatasetDefinition(definition)) {
        const allFields = flattenDeep(Object.values(datasetFields));
        return allFields.every(field => {
            if (isGroupField(field)) {
                return field.children.every(childField => !!childField.label);
            }
            return !!field.label;
        });
    }
    return definition.datasetFields.every(field => !!field.label);
};

export const validateSectionFieldLabels = (definition: FormDatasetDefinition, sectionId: string) => {
    const { datasetFields } = definition;
    const sectionFields = datasetFields[sectionId];
    return sectionFields.every(field => {
        if (isGroupField(field)) {
            return field.children.every(childField => !!childField.label);
        }
        return !!field.label;
    });
};

export const validateSectionFields = (definition: FormDatasetDefinition, sectionId: string) => {
    const { datasetFields } = definition;
    const sectionFields = datasetFields[sectionId];
    return sectionFields.every(field => {
        if (isGroupField(field)) {
            return field.children.every(childField => validateField(childField));
        }
        return validateField(field);
    });
};
