import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { flow, indexOf, isNull, isUndefined, max, noop, set, uniq } from 'lodash/fp';
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { DeleteButton } from '../../../shared/button/DeleteButton';
import { IconButton } from '../../../shared/button/IconButton';
import { ListContext, ListContextValue, getItemRegistry, isItemData } from '../../../shared/drag-n-drop/shared';
import { Document, Settings, Tick, Delete, CheckList } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { IconTooltip, OverflowTooltip } from '../../../shared/tooltip';
import styles from '../SupplyChain.module.scss';
import { SupplyChainElement, getDroppableHeight, getSupplyChainElements, setDroppableHeight, toggleSupplyChainElement, updateSupplyChain, getCurrentFunctionCompanyDetails, fetchElementDetailsStarted } from '../store';
import { ElementDetails } from './ElementDetails';
import { ListPlaceholder } from './ListPlaceholder';
import { RankContent } from './RankContent';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { FeaturePermission } from '../../../admin/users/store';
import { DROPPABLE_OFFSET } from '../SupplyChainConfigure';
import { Icon } from '../../../shared/icon/Icon';

const { green, red, grey, primary } = styles;

interface HeightAndOffset {
    height: number;
    offset: number;
}

const MIN_SUPPLY_CHAIN_HEIGHT = 70;

export const SupplyChainDragDrop: React.FC = () => {
    const testId = 'supply-chain';
    const dispatch = useAppDispatch();
    const supplyChain = useAppSelector(getSupplyChainElements);
    const [, screenHeight] = useWindowResize();
    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));
    const maxSupplyChainDroppableHeight = useAppSelector(getDroppableHeight);
    const [registry] = useState(getItemRegistry);
    const details = useAppSelector(getCurrentFunctionCompanyDetails);
    const isCriticalOrImportant = useMemo(() => !isNull(details) && !!details.isCriticalOrImportant, [details]);

    const [instanceId] = useState(() => Symbol(testId));

    const updateDroppableHeight = useCallback((droppableHeight: number) => dispatch(setDroppableHeight(droppableHeight)), [dispatch]);
    const openSupplyChainElement = useCallback((doraSupplyChainId: number) => {
        dispatch(toggleSupplyChainElement(doraSupplyChainId));
        dispatch(fetchElementDetailsStarted(doraSupplyChainId));
    }, [dispatch]);
    const setSupplyChain = useCallback((supplyChain: SupplyChainElement[]) => dispatch(updateSupplyChain(supplyChain)), [dispatch]);

    const screenHeightDroppableHeight = useMemo(() => screenHeight - DROPPABLE_OFFSET, [screenHeight]);
    const droppableHeight = useMemo(() => max([screenHeightDroppableHeight, maxSupplyChainDroppableHeight])!, [screenHeightDroppableHeight, maxSupplyChainDroppableHeight]);

    const getOrderedCompaniesForRank = useCallback((rank: number | null) => supplyChain.filter(({ supplyChainRank }) => supplyChainRank === rank).sort((a, b) => (a.parentId || 0) - (b.parentId || 0)), [supplyChain]);
    const rankCompanies = useMemo(() => uniq(supplyChain.map(({ supplyChainRank }) => supplyChainRank)).sort((a, b) => (a || 0) - (b || 0)).map(rank => ({ rank: `rank-${rank}`, companies: getOrderedCompaniesForRank(rank) })), [supplyChain, getOrderedCompaniesForRank]);
    const supplyChainCompanies = useMemo(() => rankCompanies.filter(({ rank }) => rank !== 'rank-null'), [rankCompanies]);
    const availableCompanies = useMemo(() => rankCompanies.filter(({ rank }) => rank === 'rank-null'), [rankCompanies]);

    const getMovedItem = useCallback((currentRank: number | null, currentIndex: number) => supplyChain.filter(({ supplyChainRank }) => supplyChainRank === currentRank).sort((a, b) => (a.parentId || 0) - (b.parentId || 0))[currentIndex], [supplyChain]);

    const getChildren = useCallback((id: number) => supplyChain.filter(({ parentId }) => parentId === id).map(({ doraSupplyChainId }) => doraSupplyChainId), [supplyChain]);

    const getAllChildren = useCallback((id: string) => {
        const children = getChildren(parseInt(id));
        let allChildren = children;
        let currentChildren = children;
        while (currentChildren.length) {
            const children = currentChildren.reduce((acc: number[], cur) => [...acc, ...getChildren(cur)], []);
            currentChildren = children;
            allChildren = [...allChildren, ...children];
        }
        return allChildren;
    }, [getChildren]);

    const moveItem = useCallback(
        ({
            sourceGroupId,
            targetGroupId,
            sourceGroupItemIndex,
            targetGroupItemIndex,
        }: {
            sourceGroupId: string;
            targetGroupId: string;
            sourceGroupItemIndex: number;
            targetGroupItemIndex: number;
        }) => {
            const currentRankString = sourceGroupId.split('-')[1];
            const currentRank = isNaN(parseInt(currentRankString)) ? null : parseInt(currentRankString);
            const parentRankString = targetGroupId.split('-')[1];
            const parentRank = isNaN(parseInt(parentRankString)) ? null : parseInt(parentRankString);
            const parentItem = getMovedItem(parentRank, targetGroupItemIndex);
            const parentId = isNull(parentRank) ? null : parentItem?.doraSupplyChainId || 0;
            const movedItem = getMovedItem(currentRank, sourceGroupItemIndex);
            const newRank = !isNull(parentRank) ? parentRank + 1 : null;
            let updatedSupplyChain = supplyChain.map(element => element.doraSupplyChainId === movedItem.doraSupplyChainId ? { ...element, supplyChainRank: newRank, parentId } : element);
            const children = getAllChildren(movedItem.doraSupplyChainId.toString());
            if (children.length > 0) {
                const getUpdatedRank = (currentChildRank: number, movedElementNewRank: number | null) => {
                    if (typeof currentRank === 'number' && typeof movedElementNewRank === 'number') {
                        const difference = movedElementNewRank - currentRank;
                        return currentChildRank + difference;
                    }
                    return null;
                };
                updatedSupplyChain = updatedSupplyChain.map(element => {
                    const updatedRank = getUpdatedRank(element.supplyChainRank!, newRank);
                    if (children.includes(element.doraSupplyChainId)) {
                        return { ...element, supplyChainRank: updatedRank, parentId: isNull(updatedRank) ? null : element.parentId };
                    }
                    return element;
                });
            }
            setSupplyChain(updatedSupplyChain);
        }, [getMovedItem, setSupplyChain, supplyChain, getAllChildren]);

    const removeElement = useCallback((event: MouseEvent<HTMLButtonElement>, company: SupplyChainElement) => {
        event.stopPropagation();
        let updatedSupplyChain = supplyChain.map(element => element.doraSupplyChainId === company.doraSupplyChainId ? { ...element, supplyChainRank: null, parentId: null } : element);
        const children = getAllChildren(company.doraSupplyChainId.toString());
        if (children.length > 0) {
            updatedSupplyChain = updatedSupplyChain.map(element => children.includes(element.doraSupplyChainId) ? { ...element, supplyChainRank: null, parentId: null } : element);
        }
        setSupplyChain(updatedSupplyChain);
    }, [supplyChain, getAllChildren, setSupplyChain]);

    useEffect(() => monitorForElements({
        canMonitor: ({ source }) => isItemData(source.data) && source.data.instanceId === instanceId,
        onDrop: ({ location, source }) => {
            const target = location.current.dropTargets[0];
            if (!target) {
                return;
            }

            const sourceData = source.data;
            const targetData = target.data;
            if (!isItemData(sourceData) || !isItemData(targetData)) {
                return;
            }

            moveItem({
                sourceGroupId: sourceData.type,
                targetGroupId: targetData.type,
                sourceGroupItemIndex: sourceData.index,
                targetGroupItemIndex: targetData.index
            });
        },
    }), [instanceId, moveItem]);

    const getListLength = useCallback(() => rankCompanies.reduce((acc, { companies }) => acc + companies.length, 0), [rankCompanies]);

    const contextValue: ListContextValue = useMemo(() => ({
        registerItem: registry.register,
        reorderItem: noop,
        instanceId,
        getListLength,
        moveItem
    }), [registry.register, instanceId, getListLength, moveItem]);

    const getContractDetails = useCallback((company: SupplyChainElement) => {
        const hasContractDetails = company.hasContractDetails;
        const contractDetailsIconFill = primary;
        const contractDetailsTooltip = `Contractual Information ${hasContractDetails ? 'Present' : 'Missing'}`;
        const hasContractDetailsIcon = hasContractDetails ? { icon: Tick, color: green } : { icon: Delete, color: red };
        const showContractDetailsIcon = true;
        return { contractDetailsIconFill, contractDetailsTooltip, hasContractDetailsIcon, showContractDetailsIcon };
    }, []);

    const getAssessmentDetails = useCallback((company: SupplyChainElement, isMaterial: boolean) => {
        const hasAssessmentDetails = company.hasAssessmentDetails;
        let assessmentDetailsTooltip = `Assessment Information ${hasAssessmentDetails ? 'Present' : 'Missing'}`;
        const sharedTooltip = 'An Assessment is only required if the ';
        const notCritical = 'function is deemed critical or important';
        const notMaterial = 'supply chain element has been deemed as materially important';
        if (!isCriticalOrImportant || !isMaterial) {
            if (!isCriticalOrImportant && !isMaterial) {
                assessmentDetailsTooltip = `${sharedTooltip} ${notMaterial} and the ${notCritical}`;
            } else {
                assessmentDetailsTooltip = `${sharedTooltip} ${!isMaterial ? notMaterial : notCritical}`;
            }
        }
        const assessmentDetailsIconFill = (!isCriticalOrImportant || !isMaterial) ? grey : primary;
        const hasAssessmentDetailsIcon = hasAssessmentDetails ? { icon: Tick, color: green } : { icon: Delete, color: red };
        const showAssessmentDetailsIcon = (!isCriticalOrImportant || !isMaterial) && !hasAssessmentDetails ? false : true;
        return { assessmentDetailsIconFill, assessmentDetailsTooltip, hasAssessmentDetailsIcon, showAssessmentDetailsIcon };
    }, [isCriticalOrImportant]);

    const getChildElement = useCallback((id: string) => {
        const company = supplyChain.find(company => company.doraSupplyChainId === parseInt(id));
        if (company) {
            const showDelete = !isNull(company.supplyChainRank) && company.supplyChainRank > 0 && hasDoraFullAccessPermission;
            const showDetailsIcon = isNull(company.supplyChainRank) || company.supplyChainRank > 0;
            const isThirdParty = !isNull(company.thirdPartyCompanyId);
            const isIntraGroup = !isNull(company.companyId);
            const isParent = company.supplyChainRank === 0;
            const isRankedNode = !isNull(company.supplyChainRank);
            const isMaterial = !isUndefined(company.details.isMaterial) && !!company.details.isMaterial;
            const { contractDetailsIconFill, contractDetailsTooltip, hasContractDetailsIcon, showContractDetailsIcon } = getContractDetails(company);
            const { assessmentDetailsIconFill, assessmentDetailsTooltip, hasAssessmentDetailsIcon, showAssessmentDetailsIcon } = getAssessmentDetails(company, isMaterial);
            return (
                <div className={styles.doraSupplyChainElement}>
                    {!isParent &&
                        <div className={classnames(styles.elementIdentifier, {
                            [styles.isThirdParty]: isThirdParty,
                            [styles.isIntraGroup]: isIntraGroup
                        })}
                        />
                    }
                    {!isParent &&
                        <div className={styles.headerWrapper}>
                            {showDetailsIcon && <IconButton icon={Settings} onClick={() => openSupplyChainElement(company.doraSupplyChainId)} fontSize={15} />}
                            {isRankedNode &&
                                <div className={styles.rankOneIcons}>
                                    <div className={styles.iconWrapper}>
                                        <IconTooltip content={contractDetailsTooltip} icon={Document} fontSize={15} iconColor={contractDetailsIconFill} trigger='click' />
                                        {showContractDetailsIcon &&
                                            <div className={styles.iconValue}>
                                                <Icon icon={hasContractDetailsIcon.icon} fontSize={10} color={hasContractDetailsIcon.color} />
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.iconWrapper}>
                                        <IconTooltip content={assessmentDetailsTooltip} icon={CheckList} fontSize={15} iconColor={assessmentDetailsIconFill} trigger='click' />
                                        {showAssessmentDetailsIcon &&
                                            <div className={styles.iconValue}>
                                                <Icon icon={hasAssessmentDetailsIcon.icon} fontSize={10} color={hasAssessmentDetailsIcon.color} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {showDelete && <DeleteButton onClick={e => removeElement(e, company)} fontSize={15} />}
                        </div>
                    }
                    <div className={styles.labelWrapper}>
                        <OverflowTooltip overlayText={company.label} />
                    </div>
                </div>
            );
        }
        return null;
    }, [supplyChain, removeElement, openSupplyChainElement, hasDoraFullAccessPermission, getAssessmentDetails, getContractDetails]);

    const getHasParent = useCallback((id: string) => {
        const company = supplyChain.find(company => company.doraSupplyChainId === parseInt(id));
        return !!company && !isNull(company.parentId);
    }, [supplyChain]);

    const getParentIds = useCallback((parentId: string | undefined) => {
        if (parentId && parentId !== '0') {
            const parentCompany = supplyChain.find(({ doraSupplyChainId }) => doraSupplyChainId === parseInt(parentId));
            if (parentCompany) {
                let currentRank = parentCompany.supplyChainRank;
                let parentId: number | null = parentCompany.parentId;
                let parentIds: number[] = [parentCompany.doraSupplyChainId];
                while (!isNull(currentRank) && currentRank > 1) {
                    const nextParentCompany = supplyChain.find(({ doraSupplyChainId }) => doraSupplyChainId === parentId);
                    currentRank = nextParentCompany ? nextParentCompany.supplyChainRank : null;
                    if (!isNull(currentRank) && currentRank >= 1) {
                        parentId = nextParentCompany ? nextParentCompany.parentId : null;
                        if (nextParentCompany && typeof parentId === 'number') {
                            parentIds = [nextParentCompany.doraSupplyChainId, ...parentIds];
                        }
                    }
                }
                return parentIds;
            }
        }
        return [];
    }, [supplyChain]);

    const getParentHeightAndOffset = useCallback((parentId: string | undefined, numberOfTiles: number) => {
        const initialHeight = 100;
        const initialOffset = 0;
        if (parentId && parentId !== '0') {
            const parentCompany = supplyChain.find(({ doraSupplyChainId }) => doraSupplyChainId === parseInt(parentId));
            if (parentCompany && !isNull(parentCompany.supplyChainRank)) {
                const upwardSupplyChain = rankCompanies.filter(({ rank }) => {
                    const parsedRank = parseInt(rank.split('-')[1]);
                    return parsedRank <= parentCompany.supplyChainRank! && parsedRank > 0;
                });
                const parentIds = getParentIds(parentId);
                const heightAndOffset = upwardSupplyChain.reduce((acc: HeightAndOffset, { companies }, index) => {
                    const parentId = parentIds[index];
                    const previousParentId = parentIds[index - 1];
                    const relevantCompanies = isUndefined(previousParentId) ? companies : companies.filter(({ parentId }) => parentId === previousParentId);
                    const numberOfCompaniesInRank = relevantCompanies.length;
                    const companyIds = relevantCompanies.map(({ doraSupplyChainId }) => doraSupplyChainId);
                    const indexOfId = indexOf(parentId, companyIds);
                    const height = acc.height / numberOfCompaniesInRank;
                    const offset = acc.offset + (height * indexOfId);
                    return flow(
                        set('height', height),
                        set('offset', offset)
                    )(acc);
                }, { height: initialHeight, offset: initialOffset });
                const { height } = heightAndOffset;
                if (((height / 100) * droppableHeight) < (numberOfTiles * MIN_SUPPLY_CHAIN_HEIGHT)) {
                    const increment = 100 / height;
                    const newDroppableHeight = (numberOfTiles * MIN_SUPPLY_CHAIN_HEIGHT) * increment;
                    updateDroppableHeight(newDroppableHeight);
                }
                return heightAndOffset;
            }
        }
        return { height: initialHeight, offset: initialOffset };
    }, [supplyChain, rankCompanies, getParentIds, droppableHeight, updateDroppableHeight]);

    return (
        <div className={styles.supplyChainContentWrapper}>
            <ListContext.Provider value={contextValue}>
                <div className={styles.supplyChainConfigWrapper}>
                    <div className={styles.configWrapper}>
                        <Scrollable>
                            <div className={styles.config}>
                                {supplyChainCompanies.map(({ rank, companies }) => {
                                    const tiles = companies.map(({ doraSupplyChainId, label, parentId }) => ({ id: doraSupplyChainId.toString(), label, type: rank, parentId: parentId?.toString() || undefined }));
                                    return (
                                        <RankContent
                                            key={rank}
                                            rank={rank}
                                            tiles={tiles}
                                            getChildElement={getChildElement}
                                            getChildren={getAllChildren}
                                            getHasParent={getHasParent}
                                            getParentHeightAndOffset={getParentHeightAndOffset}
                                            droppableHeight={droppableHeight}
                                            disabled={!hasDoraFullAccessPermission}
                                        />);
                                })}
                            </div>
                        </Scrollable>
                    </div>
                    <div className={styles.availableCompanies}>
                        {availableCompanies.length ? availableCompanies.map(({ rank, companies }) => {
                            const tiles = companies.map(({ doraSupplyChainId, label }) => ({ id: doraSupplyChainId.toString(), label, type: rank }));
                            return (
                                <RankContent
                                    key={rank}
                                    rank={rank}
                                    tiles={tiles}
                                    isLastColumn
                                    getChildElement={getChildElement}
                                    getChildren={getAllChildren}
                                    getHasParent={getHasParent}
                                    getParentHeightAndOffset={getParentHeightAndOffset}
                                    disabled={!hasDoraFullAccessPermission}
                                />);
                        }) : <ListPlaceholder />}
                    </div>
                </div>
            </ListContext.Provider>
            <ElementDetails />
        </div>
    );
};
