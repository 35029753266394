import React from 'react';

import styles from '../Reports.module.scss';
import { DocumentSelect } from './DocumentSelect';

export const Select: React.FC = () => (
    <div className={styles.selectWrapper}>
        <DocumentSelect />
    </div>
);
