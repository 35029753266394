import React, { useMemo, useState } from 'react';
import { Options } from 'react-select';
import { isNull } from 'lodash/fp';

import { Filter } from '../icons';
import { ModalHeader } from './ModalHeader';
import { Position, PositionModal } from './PositionModal';
import styles from './Modal.module.scss';
import { DropdownOption } from '../dropdown/Dropdown';
import { DropdownSummary } from '../dropdown/DropdownSummary';

export interface TableFilterType {
    text: string;
    dropdown: string[] | null;
}

export interface TableFilters {
    [field: string]: TableFilterType;
}

export interface FilterDropdownOptions {
    [field: string]: DropdownOption[];
}

interface TableFilterModalProps {
    position: Position | null;
    filters: TableFilters;
    filterDropdownOptions: FilterDropdownOptions;
    field: string;
    closeModal: () => void;
    updateFilter: (field: string, value: string | string[] | null, type?: keyof TableFilterType) => void;
    testId: string;
    textFilterVisible?: boolean;
}

export const TableFilterModal: React.FC<TableFilterModalProps> = ({
    closeModal,
    position,
    filters,
    filterDropdownOptions,
    updateFilter,
    field,
    testId,
    textFilterVisible = true
}) => {
    const initialTextFilterValue = useMemo(() => filters[field] && filters[field].text || '', [filters, field]);
    const dropdownOptions = useMemo(() => filterDropdownOptions[field] || [], [filterDropdownOptions, field]);
    const dropdownFilterValue = useMemo(() => {
        if (dropdownOptions.length && filters[field] && filters[field].dropdown) {
            return dropdownOptions.filter(({ value }) => filters[field].dropdown!.includes(value));
        }
        return null;
    }, [dropdownOptions, field, filters]);
    const [filterTextValue, setFilterTextValue] = useState<string>(initialTextFilterValue);

    const updateFilters = (e: React.KeyboardEvent<HTMLInputElement>) => e.key.toLowerCase() === 'enter' && updateFilter(field, filterTextValue, 'text');
    const updateDropdownFilter = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(dropdownValue) && (dropdownValue as Options<DropdownOption>).length > 0) {
            updateFilter(field, (dropdownValue as Options<DropdownOption>).map(({ value }) => value), 'dropdown');
        } else {
            updateFilter(field, null, 'dropdown');
        }
    };

    return (
        <PositionModal
            isOpen
            testId={testId}
            closeModal={closeModal}
            position={position}
            height='fit-content'
            width={`${dropdownOptions.length ? 300 : 155}px`}
        >
            <div className={styles.tableFilterModal}>
                <ModalHeader icon={Filter} label='Filter' iconSize={20} overflowTooltipClassName={styles.filterModalHeader} marginBottom='5px' testId={testId} />
                {textFilterVisible && <input
                    placeholder='Add filter...'
                    className={styles.tableFilterInput}
                    data-testid={`${testId}-input`}
                    onChange={e => setFilterTextValue(e.target.value)}
                    onKeyDown={updateFilters}
                    value={filterTextValue}
                    autoFocus
                />}
                {!!dropdownOptions.length &&
                    <div className={styles.dropdownFilterWrapper}>
                        <DropdownSummary
                            value={dropdownFilterValue}
                            onChange={updateDropdownFilter}
                            options={dropdownOptions}
                            testId='table-filter'
                            menuPlacement='bottom'
                            hideIndicator={true}
                            isClearable
                            menuPortalTarget={document.body}
                            isMulti
                        />
                    </div>
                }
            </div>
        </PositionModal>
    );
};
