import React, { useCallback } from 'react';
import { noop } from 'lodash/fp';

import { GroupDatasetField } from '../../../../datasets/store';
import styles from './FormPreview.module.scss';
import { SingleFieldPreview } from './SingleFieldPreview';
import { Toggle } from '../../../../shared/toggle';
import { PlusButton } from '../../../../shared/button/PlusButton';

const { lightGrey } = styles;

interface MultiTogglePreviewProps {
    field: GroupDatasetField;
    groupIndex: number;
}

export const MultiTogglePreview: React.FC<MultiTogglePreviewProps> = ({ field: { children }, groupIndex }) => {
    const getOptionWrapperStyle = useCallback((index: number) => {
        const isEndColumn = !((index + 1) % 3);
        return !isEndColumn ? { width: 'calc((100% / 3) - 21px)', marginRight: '10px', borderRight: `1px solid ${lightGrey}` } : { width: 'calc((100% / 3) - 10px)' };
    }, []);

    return (
        <div className={styles.multiToggleWrapper}>
            <div className={styles.multiToggleHeaderWrapper}>
                <div className={styles.selectAllWrapper}>
                    <div className={styles.selectAllLabel}>Select All</div>
                    <Toggle onChange={noop} checked={false} disabled />
                </div>
                <div className={styles.addOptionWrapper}>
                    <div className={styles.addOptionLabel}>Add another option</div>
                    <div className={styles.addOptionButton}><PlusButton onClick={noop} fontSize={16} disabled /></div>
                </div>
            </div>
            <div className={styles.multiToggleOptionsWrapper} data-testid={`form-preview-multi-toggle-${groupIndex}-wrapper`}>
                {children.map((field, index) => {
                    const singleIndex = `${groupIndex}${index}`;
                    const optionWrapperStyle = getOptionWrapperStyle(index);
                    return (
                        <div className={styles.toggleOptionWrapper} key={field.id} style={optionWrapperStyle}>
                            <SingleFieldPreview
                                field={field}
                                singleIndex={singleIndex}
                                index={index}
                                groupIndex={groupIndex}
                                isMultiToggle
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
