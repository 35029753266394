import React from 'react';

export const AttestationManager: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg x='0px' y='0px' width={width || '1em'} height={height || '1em'} viewBox='0 0 128 128'>
        <path fill={color || 'currentColor'} d="M57.7,39.7h-1.3c-1.1,0-2-0.9-2-2.1c0-0.6,0.2-1.1,0.7-1.5l0.9-0.9c0.9-0.9,0.9-2.3,0-3.1l-2-2c-0.4-0.4-1-0.6-1.6-0.6
   s-1.1,0.2-1.6,0.6L50,31.1c-0.4,0.5-0.9,0.7-1.5,0.7c-1.1,0-2.1-0.9-2.1-2v-1.4c0-1.2-1-2.2-2.2-2.2h-2.7c-1.2,0-2.2,1-2.2,2.2v1.4
   c0,1.1-0.9,2-2.1,2c-0.6,0-1.1-0.2-1.5-0.7l-0.9-0.9c-0.4-0.4-1-0.6-1.6-0.6c-0.6,0-1.1,0.2-1.6,0.6l-2,1.9c-0.9,0.9-0.9,2.3,0,3.1
   l0.8,0.8c0.4,0.4,0.7,0.9,0.7,1.5c0,1.1-0.9,2.1-2,2.1H28c-1.2,0-2.2,0.9-2.2,2.1v1.4v1.4c0,1.2,1,2.1,2.2,2.1h1.3
   c1.1,0,2,0.9,2,2.1c0,0.6-0.2,1.1-0.7,1.5l-0.8,0.8c-0.9,0.9-0.9,2.3,0,3.1l2,2c0.4,0.4,1,0.6,1.6,0.6s1.1-0.2,1.6-0.6l0.9-0.9
   c0.4-0.4,0.9-0.7,1.5-0.7c1.1,0,2.1,0.9,2.1,2V58c0,1.2,1,2.2,2.2,2.2h2.7c1.2,0,2.2-1,2.2-2.2v-1.4c0-1.1,0.9-2,2.1-2
   c0.6,0,1.1,0.2,1.5,0.7l0.8,0.8c0.4,0.4,1,0.6,1.6,0.6s1.1-0.2,1.6-0.6l2-2c0.9-0.9,0.9-2.3,0-3.1L55,50.2c-0.4-0.4-0.7-0.9-0.7-1.5
   c0-1.1,0.9-2.1,2-2.1h1.3c1.2,0,2.1-0.9,2.1-2.1v-1.4v-1.4C59.8,40.6,58.9,39.7,57.7,39.7z M49.9,43.2L49.9,43.2
   c0,3.9-3.1,7.1-7.1,7.1s-7.1-3.2-7.1-7.1l0,0l0,0c0-3.9,3.1-7.1,7.1-7.1S49.9,39.3,49.9,43.2L49.9,43.2z"/>
        <g>
            <path fill={color || 'currentColor'} d="M50.4,81.6c0,5.8-4.7,10.5-10.5,10.5s-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5C45.7,71.1,50.4,75.8,50.4,81.6" />
            <path fill={color || 'currentColor'} d="M58,104.5c-2.1-5.8-6.7-10.2-12.6-11.9c-0.2,0-0.4,0-0.5,0c-1.5,0.8-3.1,1.2-4.9,1.2s-3.4-0.4-4.9-1.2
       c-0.1-0.1-0.3-0.1-0.5,0C28.7,94.3,24,98.8,22,104.5c-0.6,1.6,0.6,3.3,2.3,3.3h31.5C57.4,107.8,58.6,106,58,104.5L58,104.5z"/>
        </g>
        <g>
            <path fill={color || 'currentColor'} d="M78.6,104.3l-0.5,0.4c-1.3,1.2-3.2,0.9-4.3-0.5L67,95.2c-1-1.4-0.8-3.6,0.4-4.7l0.5-0.4c1.3-1.2,3.2-0.9,4.3,0.5l6.8,9.1
       C80.1,101,79.9,103.1,78.6,104.3z"/>
            <path fill={color || 'currentColor'} d="M74,104.2l-0.4-0.5c-1-1.4-0.8-3.6,0.4-4.7l25.9-23.5c1.3-1.2,3.2-0.9,4.3,0.5l0.4,0.5c1,1.4,0.8,3.6-0.4,4.7l-25.9,23.5
       C76.9,105.8,75,105.7,74,104.2z"/>
        </g>
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2
   C114.1,108.7,108.7,114.1,102.1,114.1z"/>
        <g>
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="70.6" y1="33.3" x2="104.6" y2="33.3" />
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="70.6" y1="41.8" x2="104.6" y2="41.8" />
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="70.6" y1="50.3" x2="104.6" y2="50.3" />
        </g>
    </svg>
);

