import React, { useCallback, useMemo } from 'react';
import { isEqual, isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { CheckList } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import styles from './DoraFunctions.module.scss';
import { getDoraFunctionModalOpen, getIsSavingDoraFunction, getSelectedDoraFunction, getCurrentDoraFunction, saveDoraFunctionStarted, toggleSelectedDoraFunctionModal, updateDoraFunctionValue } from './store';
import { Text } from '../../shared/text/Text';
import { LongText } from '../../shared/longtext/LongText';
import { getUserRole, getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { systemAdminRole } from '../../constants/permittedRoles';
import { InformationTooltip } from '../../shared/tooltip';
import { Toggle } from '../../shared/toggle';
import { FeaturePermission } from '../../admin/users/store';
import { Spinner } from '../../shared/spinner/Spinner';

export const DoraFunctionsModal: React.FC = () => {

    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getDoraFunctionModalOpen);
    const closeModal = useCallback(() => dispatch(toggleSelectedDoraFunctionModal(null)), [dispatch]);
    const selectedFunction = useAppSelector(getSelectedDoraFunction);
    const currentFunction = useAppSelector(getCurrentDoraFunction);
    const isSavingFunction = useAppSelector(getIsSavingDoraFunction);

    const userRole = useAppSelector(getUserRole);

    const isSystemAdmin = systemAdminRole.includes(userRole!);

    const updateFunctionName = (value: string) => dispatch(updateDoraFunctionValue('name', value));
    const updateDescription = (value: string) => dispatch(updateDoraFunctionValue('description', value));
    const toggleIsSystem = (value: boolean) => {
        const isSystemFunction = value ? 1 : 0;
        isSystemAdmin && dispatch(updateDoraFunctionValue('systemFunction', isSystemFunction));
    };
    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const modalDisabled = useMemo(() => !isSystemAdmin && selectedFunction?.systemFunction === 1 || !hasDoraFullAccessPermission, [isSystemAdmin, selectedFunction, hasDoraFullAccessPermission]);
    const functionUpdated = useMemo(() => !isEqual(selectedFunction, currentFunction), [selectedFunction, currentFunction]);
    const saveDisabled = useMemo(() => modalDisabled || selectedFunction?.name === '' || !functionUpdated, [modalDisabled, functionUpdated, selectedFunction]);
    const saveFunction = useCallback(() => {
        dispatch(saveDoraFunctionStarted());
        closeModal();
    }, [dispatch, closeModal]);

    if (isNull(selectedFunction)) {
        return null;
    }

    const { name, description, systemFunction } = selectedFunction;

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel='Save'
            confirmDisabled={saveDisabled}
            confirm={saveFunction}
            testId='dora-functions'
            confirmDisabledTooltip={undefined}
        >
            <div className={styles.functionsModalWrapper} style={{ minHeight: isSystemAdmin ? '350px' : '280px' }} data-testid='dora-function-modal-wrapper'>
                <ModalHeader
                    label='DORA Function'
                    testId='dora-function-modal'
                    icon={CheckList}
                />
                {isSavingFunction ? <Spinner /> : <>
                    <div className={styles.functionTextInput}>
                        <Text
                            testId='dora-function-name'
                            label='Name'
                            maxLength={500}
                            value={name}
                            onChange={e => updateFunctionName(e.target.value)}
                            disabled={modalDisabled}
                        />
                    </div>
                    <div className={styles.functionTextInput}>
                        <LongText
                            testId='dora-function-description'
                            label='Description'
                            maxLength={1000}
                            value={description}
                            onChange={updateDescription}
                            width='calc(100% - 10px)'
                            disabled={modalDisabled}
                        />
                    </div>
                    {isSystemAdmin && <div className={styles.isSystemFunctionWrapper}>
                        <div className={styles.isSystemFunctionLabelWrapper}>
                            <div className={styles.isSystemFunctionLabel} data-testid='dora-function-system-toggle-label'>System DORA Function?</div>
                            <InformationTooltip placement='left' trigger='click' content='Make this function available to all clients' />
                        </div>
                        <Toggle
                            checked={!!systemFunction}
                            onChange={val => toggleIsSystem(val)}
                            testId='dora-function-system-toggle'
                        />
                    </div>}
                </>}
            </div>
        </ConfirmationModal>
    );
};
