import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { Delete, Document, Time, User } from '../../../shared/icons';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../shared/table/ArkTable';
import { dateFormatter } from '../../../shared/table/arkTableFormatters';
import {
    AttestationInstanceStatus,
    AttestationManager,
    attestationInstancesPaginationNext,
    attestationInstancesPaginationPrevious,
    clearAttestationInstancesTableFilters,
    getAttestationInstancesColumnSort,
    getAttestationInstancesFilters,
    getAttestationInstancesPageNumber,
    getAttestationInstancesPageSize,
    getIsLoading,
    getTotalAttestationInstances,
    setAttestationInstancesPageSize,
    setAttestationInstancesTableColumnSort,
    setAttestationInstancesTableFilters,
    toggleAttestationManagerWizardOpen,
    toggleDeleteAttestationConfirmationModal
} from '../store';

interface AttestationManagerTableProps {
    attestations: AttestationManager[];
    completed: boolean;
}

export interface AttestationManagerTableRow {
    attestationInstanceId: number;
    deadline: string | null;
    createdDate: string;
    completedDate: string | null;
    attestationName: string;
    attestationStatus: AttestationInstanceStatus[];
}

export const AttestationManagerTable: React.FC<AttestationManagerTableProps> = ({ attestations, completed }) => {
    const dispatch = useAppDispatch();
    const getAttestation = useCallback((attestationInstanceId: number) => attestations.find(({ attestationInstance }) => attestationInstance.attestationInstanceId === attestationInstanceId)!, [attestations]);
    const openAttestationInstance = useCallback((row: AttestationManagerTableRow) => dispatch(toggleAttestationManagerWizardOpen(true, getAttestation(row.attestationInstanceId))), [dispatch, getAttestation]);
    const openDeleteConfirmationModal = useCallback((row: AttestationManagerTableRow) => dispatch(toggleDeleteAttestationConfirmationModal(row)), [dispatch]);

    const isLoading = useAppSelector(getIsLoading);
    const pageNumber = useAppSelector(getAttestationInstancesPageNumber);
    const total = useAppSelector(getTotalAttestationInstances);
    const filters = useAppSelector(getAttestationInstancesFilters);
    const columnSort = useAppSelector(getAttestationInstancesColumnSort);
    const pageSize = useAppSelector(getAttestationInstancesPageSize);
    const nextPage = useCallback(() => { dispatch(attestationInstancesPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(attestationInstancesPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null) => { dispatch(setAttestationInstancesTableFilters(key, value)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearAttestationInstancesTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setAttestationInstancesPageSize(pageSize)); }, [dispatch]);

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setAttestationInstancesTableColumnSort(columnSort)); }, [dispatch]);

    const rowData = useMemo(() => attestations.map(({ attestationInstance: { completedDate, deadline, createdDate, attestationName, attestationInstanceId }, attestationStatus }) => ({
        attestationName,
        createdDate,
        deadline,
        completedDate,
        attestationStatus,
        attestationInstanceId
    })), [attestations]);

    const actions = useCallback((row: AttestationManagerTableRow) => {
        const options = [
            { label: 'Open', icon: Document, onClick: () => openAttestationInstance(row), withSeparator: true },
            { label: 'Delete', icon: Delete, onClick: () => openDeleteConfirmationModal(row), isDelete: true }
        ];
        return options;
    }, [openAttestationInstance, openDeleteConfirmationModal]);

    const usersFormatter = useCallback((attestationStatus: AttestationInstanceStatus[]) => attestationStatus.map(({ username }) => username), []);
    const statusFormatter = useCallback((attestationStatus: AttestationInstanceStatus[]) => attestationStatus.map(({ username, status }) => `${username} - ${capitaliseStartLetter(status)}`), []);

    const columnDefs: ArkTableColumn[] = useMemo(() => {
        if (!completed) {
            return [
                { id: 'name', header: 'Name', field: 'attestationName', component: 'tooltip', width: 0.5, canFilter: true, canSort: true },
                { id: 'users', header: 'Users', field: 'attestationStatus', valueFormatter: usersFormatter, component: 'tooltipList', icon: User, width: 0.075 },
                { id: 'status', header: 'Status', field: 'attestationStatus', valueFormatter: statusFormatter, component: 'tooltipList', icon: Time, width: 0.075 },
                { id: 'createdDate', header: 'Created Date', field: 'createdDate', width: 0.15, valueFormatter: dateFormatter, canSort: true },
                { id: 'deadline', header: 'Deadline', field: 'deadline', width: 0.15, component: 'deadline', canSort: true },
                { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
            ];
        }
        return [
            { id: 'name', header: 'Name', field: 'attestationName', component: 'tooltip', width: 0.4, canFilter: true, canSort: true },
            { id: 'users', header: 'Users', field: 'attestationStatus', valueFormatter: usersFormatter, component: 'tooltipList', icon: User, width: 0.075 },
            { id: 'status', header: 'Status', field: 'attestationStatus', valueFormatter: statusFormatter, component: 'tooltipList', icon: Time, width: 0.075 },
            { id: 'createdDate', header: 'Created Date', field: 'createdDate', width: 0.14, valueFormatter: dateFormatter, canSort: true },
            { id: 'completedDate', header: 'Completed Date', field: 'completedDate', width: 0.14, valueFormatter: dateFormatter, canSort: true },
            { id: 'deadline', header: 'Deadline', field: 'deadline', width: 0.12, component: 'deadline', canSort: true },
            { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
        ];
    }, [completed, actions, usersFormatter, statusFormatter]);

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={rowData}
            testId='admin-attestation-manager'
            isLoading={isLoading}
            page={pageNumber}
            total={total}
            next={nextPage}
            previous={previousPage}
            filters={filters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => openAttestationInstance(row as AttestationManagerTableRow)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
        />
    );
};
