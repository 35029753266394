import React from 'react';

export const SaveFile: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <g>
            <path fill={color || 'currentColor'} d="M110.236,39.983l-22.22-22.22a1.754,1.754,0,0,0-1.238-.513H25.046a7.806,7.806,0,0,0-7.8,7.8v77.908a7.806,7.806,0,0,0,7.8,7.8h77.907a7.805,7.805,0,0,0,7.8-7.8V41.221A1.751,1.751,0,0,0,110.236,39.983ZM84.765,20.75V41.009a5.959,5.959,0,0,1-5.953,5.953H49.186a5.96,5.96,0,0,1-5.953-5.953V20.75Zm-50.093,86.5V75.771a5.96,5.96,0,0,1,5.953-5.953H87.374a5.96,5.96,0,0,1,5.952,5.953V107.25Zm72.577-4.3a4.3,4.3,0,0,1-4.3,4.3H96.826V75.771a9.463,9.463,0,0,0-9.452-9.453H40.625a9.464,9.464,0,0,0-9.453,9.453V107.25H25.046a4.3,4.3,0,0,1-4.3-4.3V25.046a4.3,4.3,0,0,1,4.3-4.3H39.733V41.009a9.464,9.464,0,0,0,9.453,9.453H78.812a9.464,9.464,0,0,0,9.453-9.453V22.961l18.984,18.985Z"/>
            <path fill={color || 'currentColor'} d="M72.566,42.963h4.269a3.056,3.056,0,0,0,3.053-3.053V26.615a3.056,3.056,0,0,0-3.053-3.053H72.566a3.057,3.057,0,0,0-3.053,3.053V39.91A3.057,3.057,0,0,0,72.566,42.963Zm.447-15.9h3.375v12.4H73.013Z"/>
            <path fill={color || 'currentColor'} d="M81.978,77.917H46.019a1.75,1.75,0,0,0,0,3.5H81.978a1.75,1.75,0,0,0,0-3.5Z"/>
            <path fill={color || 'currentColor'} d="M81.978,86.784H46.019a1.75,1.75,0,1,0,0,3.5H81.978a1.75,1.75,0,0,0,0-3.5Z"/>
            <path fill={color || 'currentColor'} d="M81.978,95.652H46.019a1.75,1.75,0,1,0,0,3.5H81.978a1.75,1.75,0,0,0,0-3.5Z"/>
        </g>
    </svg>
);
