import React, { useCallback } from 'react';

import { ConfirmationModal } from '../shared/modal/ConfirmationModal';
import { ModalHeader } from '../shared/modal/ModalHeader';
import styles from './Playbook.module.scss';
import { Pencil } from '../shared/icons';
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { getConfirmationModalOpen, setShowConfirmationModal, toggleSuggestedChangesModalOpen } from './store';

interface SuggestedChangesConfirmationModalProps {
    testId?: string;
}

export const SuggestedChangesConfirmationModal: React.FC<SuggestedChangesConfirmationModalProps> = ({ testId = 'playbook' }) => {
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector(getConfirmationModalOpen);
    const close = useCallback(() => dispatch(setShowConfirmationModal()), [dispatch]);
    const confirm = useCallback(() => dispatch(toggleSuggestedChangesModalOpen(null)), [dispatch]);
    return (
        <ConfirmationModal
            isOpen={modalOpen}
            closeModal={close}
            confirm={confirm}
            confirmLabel='Yes, Close'
            confirmDisabled={false}
            testId={`${testId}-suggested-changes-modal`}
        >
            <div className={styles.confirmationModalWrapper}>
                <ModalHeader
                    testId={`${testId}-suggested-changes-confirmation-modal`}
                    label='Close Suggest Changes'
                    icon={Pencil}
                />
                <div className={styles.confirmationModalHeader} data-testid={`${testId}-suggested-changes-confirmation-modal-info`}>Are you sure you want to close?</div>
                <div className={styles.confirmationModalSubheader} data-testid={`${testId}-suggested-changes-confirmation-modal-warning`}>Any suggested changes will be lost</div>
            </div>
        </ConfirmationModal>
    );
};
