import { action } from 'typesafe-actions';

import { ResetPasswordActionTypes } from './types';

export const updatePasswordField = (key: string, value: string) => action(ResetPasswordActionTypes.UPDATE_PASSWORD_FIELD, { key, value });

export const resetPasswordStarted = (token?: string) => action(ResetPasswordActionTypes.RESET_PASSWORD_STARTED, token);
export const resetPasswordSuccessful = () => action(ResetPasswordActionTypes.RESET_PASSWORD_SUCCESSFUL);
export const resetPasswordFailed = (error: string) => action(ResetPasswordActionTypes.RESET_PASSWORD_FAILED, error);

export const checkResetLinkValidStarted = (token: string) => action(ResetPasswordActionTypes.CHECK_RESET_LINK_IS_VALID_STARTED, token);
export const checkResetLinkValidSuccessful = () => action(ResetPasswordActionTypes.CHECK_RESET_LINK_IS_VALID_SUCCESSFUL);
export const checkResetLinkValidFailed = (error: string) => action(ResetPasswordActionTypes.CHECK_RESET_LINK_IS_VALID_FAILED, error);

export const setPasswordScore = (score: number) => action(ResetPasswordActionTypes.SET_PASSWORD_SCORE, score);

export const resetStoreValues = () => action(ResetPasswordActionTypes.RESET_STORE_VALUES);
