import classNames from 'classnames';
import React from 'react';

import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { SubCounterpartyType } from '../../../admin/entity/store';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Toggle } from '../../../shared/toggle';
import { InformationTooltip, OverflowTooltip } from '../../../shared/tooltip';
import styles from './OpinionSection.module.scss';

interface SubCounterpartyCardProps {
    label: string;
    readOnly: boolean;
    onChange: () => void;
    checked: boolean;
}

const SubCounterpartyCard: React.FC<SubCounterpartyCardProps> = ({ readOnly, onChange, label, checked }) => (
    <div className={styles.subCounterpartyConfigToggleCard}>
        <div style={{ width: `calc(100% - ${readOnly ? 0 : 46}px)`, marginRight: `${readOnly ? 0 : 10}px` }}><OverflowTooltip overlayText={label} /></div>
        {!readOnly && <Toggle checked={checked} onChange={onChange} />}
    </div>
);

interface SubCounterpartyColumnProps {
    columnId: string;
    readOnly: boolean;
    subCounterpartyTypes: SubCounterpartyType[];
    updateIncludedIds: (id: number) => void;
    includeAllSubCounterpartyTypes: (checked: boolean) => void;
    allSubCounterpartyIdsIncluded: boolean;
    showSelectAllLabel?: boolean;
}

export const SubCounterpartyColumn: React.FC<SubCounterpartyColumnProps> = ({
    columnId,
    subCounterpartyTypes,
    readOnly,
    updateIncludedIds,
    includeAllSubCounterpartyTypes,
    allSubCounterpartyIdsIncluded,
    showSelectAllLabel = false
}) => {
    const isIncludedColumn = columnId === 'included';
    const tooltipContent = `Based on the provided opinion, these sub counterparty types are ${columnId} ${isIncludedColumn ? 'in' : 'from'} the netting analysis`;
    return (
        <div className={styles.subCounterpartyTypesColumnWrapper}>
            <div className={styles.subCounterpartyTypesColumnSection}>
                <div className={styles.subCounterpartyTypesColumnHeader}>
                    <div className={styles.subCounterpartyTypesColumnHeaderSection}>
                        <div className={styles.subCounterpartyTypesColumnTitle}>{capitaliseStartLetter(columnId)}</div>
                        <InformationTooltip content={tooltipContent} />
                    </div>
                    {(isIncludedColumn && !readOnly) &&
                        <div className={styles.subCounterpartyTypesColumnHeaderSelectAll}>
                            {showSelectAllLabel && <div style={{ marginRight: '5px', width: 'calc(100% - 41px)' }}><OverflowTooltip overlayText='Select All' /></div>}
                            <Toggle checked={allSubCounterpartyIdsIncluded || false} onChange={includeAllSubCounterpartyTypes} />
                        </div>
                    }
                </div>
                <div className={classNames(styles.subCounterpartyTypesColumnListWrapper, {
                    [styles.included]: isIncludedColumn,
                    [styles.excluded]: !isIncludedColumn,
                })}>
                    <Scrollable>
                        {subCounterpartyTypes.map(({ subCounterpartyTypeId, subCounterpartyName }) => <SubCounterpartyCard
                            key={subCounterpartyTypeId!}
                            checked={isIncludedColumn}
                            readOnly={readOnly}
                            label={subCounterpartyName}
                            onChange={() => updateIncludedIds(subCounterpartyTypeId!)}
                        />)}
                    </Scrollable>
                </div>
            </div>
        </div>
    );
};
