import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { ClientFeaturePermission, FeaturePermission } from '../../../admin/users/store';
import { getClientHasFeaturePermission, getUserHasFeaturePermission } from '../../../auth/login/store';
import { UploadModal } from '../../../shared/modal/UploadModal';
import { getFilesToUpload, getIsUploading, getUploadModalOpen, setInitialUploadDocuments, toggleUploadModal, uploadDocumentStarted } from '../store';

const inAnalysis = false;

export const UploadDocumentsModal: React.FC = () => {
    const hasDocumentExtractAIPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DOCUMENT_EXTRACT_WITH_AI]));
    const [completeWithAI, setCompleteWithAI] = useState(hasDocumentExtractAIPermission);
    const [analyseLater, setAnalyseLater] = useState(false);
    const dispatch = useAppDispatch();
    const uploadModalOpen = useAppSelector(getUploadModalOpen);
    const isUploading = useAppSelector(getIsUploading);
    const files = useAppSelector(getFilesToUpload);
    const setDocumentsForUpload = (files: File[]) => dispatch(setInitialUploadDocuments(files));
    const closeModal = () => dispatch(toggleUploadModal(false));
    const hasUploadDocumentPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.UPLOAD_DOCUMENTS]));
    const uploadDocument = () => hasUploadDocumentPermission && dispatch(uploadDocumentStarted(inAnalysis, completeWithAI, analyseLater));

    return (
        <UploadModal
            isOpen={uploadModalOpen}
            setUploadDocuments={setDocumentsForUpload}
            modalLabel='Upload New Document(s)'
            testId='upload-document-modal'
            closeModal={closeModal}
            isUploading={isUploading}
            files={files}
            uploadDocument={uploadDocument}
            completeWithAI={completeWithAI}
            setCompleteWithAI={setCompleteWithAI}
            showCompleteWithAIOption={hasDocumentExtractAIPermission}
            canUpload={hasUploadDocumentPermission}
            showAnalyseLaterOption
            analyseLater={analyseLater}
            setAnalyseLater={setAnalyseLater}
        />
    );
};
