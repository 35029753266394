import { action } from 'typesafe-actions';

import { AdminAttestationActionTypes, AttestationConfirmationModal, AttestationForm, AttestationFormDB, AttestationManager, AttestationManagerTab, AttestationPage, AttestationQuestion, AttestationQuestionConfig, AttestationStatus, ConfigValue, FormBuilderTab, InstancesToCreate, UserAnswerId } from './types';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { AttestationManagerTableRow } from '../manager/AttestationManagerTable';

export const setAttestationPage = (page: AttestationPage) => action(AdminAttestationActionTypes.SELECT_ATTESTATION_PAGE, page);

export const toggleAttestationFormBuilderWizardOpen = (isOpen: boolean, attestationForm?: AttestationForm) => action(AdminAttestationActionTypes.TOGGLE_ATTESTATION_FORM_BUILDER_WIZARD_OPEN, { isOpen, attestationForm });

export const setSelectedFormTab = (tab: FormBuilderTab) => action(AdminAttestationActionTypes.SET_SELECTED_ATTESTATION_FORM_TAB, tab);

export const updateAttestationFormValue = (key: keyof AttestationForm, value: string | number | AttestationQuestion[]) => action(AdminAttestationActionTypes.UPDATE_ATTESTATION_FORM_VALUE, { key, value });

export const addUserAnswer = () => action(AdminAttestationActionTypes.ADD_ATTESTATION_FORM_USER_ANSWER);
export const removeUserAnswer = (id: string) => action(AdminAttestationActionTypes.REMOVE_ATTESTATION_FORM_USER_ANSWER, id);
export const updateUserAnswerLabel = (index: number, label: string) => action(AdminAttestationActionTypes.UPDATE_ATTESTATION_FORM_USER_ANSWER_LABEL, { index, label });

export const addAttestationQuestion = () => action(AdminAttestationActionTypes.ADD_ATTESTATION_QUESTION);
export const removeAttestationQuestion = (index: number) => action(AdminAttestationActionTypes.REMOVE_ATTESTATION_QUESTION, index);

export const updateAttestationQuestionValue = (index: number, key: keyof AttestationQuestion, value: string) => action(AdminAttestationActionTypes.UPDATE_ATTESTATION_QUESTION_VALUE, { key, value, index });
export const updateAttestationQuestionConfigValue = (index: number, key: keyof AttestationQuestionConfig, value: ConfigValue) => action(AdminAttestationActionTypes.UPDATE_ATTESTATION_QUESTION_CONFIG_VALUE, { key, value, index });
export const toggleAttestationConfigModalOpen = (index: number | null) => action(AdminAttestationActionTypes.TOGGLE_ATTESTATION_QUESTION_CONFIG_MODAL_OPEN, index);

export const addCustomAnswer = (index: number) => action(AdminAttestationActionTypes.ADD_CUSTOM_ANSWER, index);
export const updateCustomAnswer = (attestationIndex: number, index: number, value: string) => action(AdminAttestationActionTypes.UPDATE_CUSTOM_ANSWER, { attestationIndex, index, value });
export const removeCustomAnswer = (attestationIndex: number, index: number) => action(AdminAttestationActionTypes.REMOVE_CUSTOM_ANSWER, { attestationIndex, index });

export const toggleAttestationConfirmationModal = (modalOpen: AttestationConfirmationModal | null) => action(AdminAttestationActionTypes.TOGGLE_ATTESTATION_FORM_BUILDER_CONFIRMATION_MODAL, modalOpen);

export const saveAttestationFormStarted = () => action(AdminAttestationActionTypes.SAVE_ATTESTATION_FORM_STARTED);
export const saveAttestationFormSuccessful = (attestations: AttestationFormDB[]) => action(AdminAttestationActionTypes.SAVE_ATTESTATION_FORM_SUCCESSFUL, attestations);
export const saveAttestationFormFailed = (error: string) => action(AdminAttestationActionTypes.SAVE_ATTESTATION_FORM_FAILED, { error });

export const fetchAllAttestationFormsStarted = () => action(AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_FORMS_STARTED);
export const fetchAllAttestationFormsSuccessful = (attestations: AttestationFormDB[]) => action(AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_FORMS_SUCCESSFUL, attestations);
export const fetchAllAttestationFormsFailed = (error: string) => action(AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_FORMS_FAILED, { error });

export const setAttestationFormUpdated = (hasUpdated: boolean) => action(AdminAttestationActionTypes.SET_ATTESTATION_FORM_UPDATED, hasUpdated);

export const deleteAttestationFormStarted = (attestationFormId: number) => action(AdminAttestationActionTypes.DELETE_ATTESTATION_FORM_STARTED, attestationFormId);
export const deleteAttestationFormSuccessful = (attestations: AttestationFormDB[]) => action(AdminAttestationActionTypes.DELETE_ATTESTATION_FORM_SUCCESSFUL, attestations);
export const deleteAttestationFormFailed = (error: string) => action(AdminAttestationActionTypes.DELETE_ATTESTATION_FORM_FAILED, { error });

export const toggleAttestationInstanceView = (completed: boolean, attestationInstanceId?: string) => action(AdminAttestationActionTypes.TOGGLE_ATTESTATION_INSTANCE_VIEW, { completed, attestationInstanceId });
export const toggleNewAttestationModalOpen = (isOpen: boolean) => action(AdminAttestationActionTypes.TOGGLE_NEW_ATTESTATION_MODAL_OPEN, isOpen);

export const createAttestationStarted = () => action(AdminAttestationActionTypes.CREATE_ATTESTATION_STARTED);
export const createAttestationSuccessful = (attestations: AttestationManager[], total?: number) => action(AdminAttestationActionTypes.CREATE_ATTESTATION_SUCCESSFUL, { attestations, total });
export const createAttestationFailed = (error: string) => action(AdminAttestationActionTypes.CREATE_ATTESTATION_FAILED, { error });

export const updateNewAttestationFormId = (attestationFormId: number, userIds: UserAnswerId[], attestations: AttestationQuestion[]) => action(AdminAttestationActionTypes.UPDATE_NEW_ATTESTATION_FORM_ID, { attestationFormId, userIds, attestations });
export const updateNewAttestationInstances = (instancesToCreate: InstancesToCreate[]) => action(AdminAttestationActionTypes.UPDATE_ATTESTATION_INSTANCES, instancesToCreate);

export const toggleAttestationManagerWizardOpen = (isOpen: boolean, attestationInstance?: AttestationManager) => action(AdminAttestationActionTypes.TOGGLE_ATTESTATION_MANAGER_WIZARD_OPEN, { isOpen, attestationInstance });

export const setSelectedManagerTab = (tab: AttestationManagerTab) => action(AdminAttestationActionTypes.SET_SELECTED_ATTESTATION_MANAGER_TAB, tab);

export const fetchAllAttestationInstancesStarted = (completed: boolean, pageNumber = 1) => action(AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_INSTANCES_STARTED, { completed, pageNumber });
export const fetchAllAttestationInstancesSuccessful = (attestations: AttestationManager[], total?: number, pageNumber?: number) => action(AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_INSTANCES_SUCCESSFUL, { attestations, total, pageNumber });
export const fetchAllAttestationInstancesFailed = (error: string) => action(AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_INSTANCES_FAILED, { error });

export const deleteAttestationInstanceStarted = (attestationInstanceId: number) => action(AdminAttestationActionTypes.DELETE_ATTESTATION_INSTANCE_STARTED, attestationInstanceId);
export const deleteAttestationInstanceSuccessful = (attestations: AttestationManager[], total?: number) => action(AdminAttestationActionTypes.DELETE_ATTESTATION_INSTANCE_SUCCESSFUL, { attestations, total });
export const deleteAttestationInstanceFailed = (error: string) => action(AdminAttestationActionTypes.DELETE_ATTESTATION_INSTANCE_FAILED, { error });

export const updateAttestationDeadline = (value: string | null) => action(AdminAttestationActionTypes.UPDATE_ATTESTATION_DEADLINE, value);
export const updateAttestationUserStatus = (index: number, value: AttestationStatus) => action(AdminAttestationActionTypes.UPDATE_ATTESTATION_USER_STATUS, { index, value });

export const setAttestationInstanceUpdated = (hasUpdated: boolean) => action(AdminAttestationActionTypes.SET_ATTESTATION_INSTANCE_UPDATED, hasUpdated);

export const saveAttestationInstanceStarted = () => action(AdminAttestationActionTypes.SAVE_ATTESTATION_INSTANCE_STARTED);
export const saveAttestationInstanceSuccessful = (attestations: AttestationManager[], total?: number) => action(AdminAttestationActionTypes.SAVE_ATTESTATION_INSTANCE_SUCCESSFUL, { attestations, total });
export const saveAttestationInstanceFailed = (error: string) => action(AdminAttestationActionTypes.SAVE_ATTESTATION_INSTANCE_FAILED, { error });

export const toggleDeleteAttestationFormConfirmationModal = (attestationForm: AttestationForm | null) => action(AdminAttestationActionTypes.TOGGLE_DELETE_ATTESTATION_FORM_CONFIRMATION_MODAL, attestationForm);
export const toggleDeleteAttestationConfirmationModal = (attestation: AttestationManagerTableRow | null) => action(AdminAttestationActionTypes.TOGGLE_DELETE_ATTESTATION_CONFIRMATION_MODAL, attestation);

// Table Filters and Pagination
export const setAttestationInstancesTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_TABLE_FILTERS, { key, value, type });
export const clearAttestationInstancesTableFilters = () => action(AdminAttestationActionTypes.CLEAR_ATTESTATION_INSTANCES_TABLE_FILTERS);
export const setAttestationInstancesTableColumnSort = (columnSort: ColumnSort | undefined) => action(AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_TABLE_COLUMN_SORT, columnSort);
export const setAttestationInstancesPageSize = (pageSize: number) => action(AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_PAGE_SIZE, pageSize);
export const attestationInstancesPaginationNext = () => action(AdminAttestationActionTypes.ATTESTATION_INSTANCES_PAGINATION_NEXT);
export const attestationInstancesPaginationPrevious = () => action(AdminAttestationActionTypes.ATTESTATION_INSTANCES_PAGINATION_PREVIOUS);
