import React, { useMemo } from 'react';
import { min } from 'lodash/fp';

import { AdminAnalyticsChart, getClientPageUsageAnalytics, getFetchingAnalytics, ClientPageUsage } from './store';
import { PieChart } from '../../shared/analytics/PieChart';
import styles from './AdminAnalytics.module.scss';
import { InformationTooltip } from '../../shared/tooltip';
import { Icon } from '../../shared/icon/Icon';
import { useAppSelector } from '../../../hooks/react-redux';
import { Spinner } from '../../shared/spinner/Spinner';
import { Document } from '../../shared/icons';

const { mandatory, amber, grey } = styles;

interface PageUsagePieChartWrapperProps {
    dimensions?: {
        height: number;
        width: number;
    }
    analyticsSpinner: boolean;
}

const getDataColour = (key: string) => {
    if (key === ClientPageUsage.AVAILABLE_PAGES) return grey;
    if (key === ClientPageUsage.AVAILABLE_FREE_PAGES) return mandatory;
    return amber;
};

export const PageUsagePieChartWrapper: React.FC<PageUsagePieChartWrapperProps> = ({ dimensions, analyticsSpinner }) => {
    const diameter = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 20;
            const svgHeight = dimensions.height - 55;
            return min([svgWidth, svgHeight])!;
        }
        return 0;
    }, [dimensions]);

    const data = useAppSelector(getClientPageUsageAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(AdminAnalyticsChart.PAGE_USAGE), [fetchingAnalytics]);

    const filteredData = useMemo(() => data.filter(({ value }) => value).map(data => ({ ...data, color: getDataColour(data.key) })), [data]);
    const description = 'The chart below displays the number of document pages you have uploaded this month along with the remainder of pages available to upload before reaching your monthly limit.';

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.chartWrapper}>
            <div className={styles.chartHeader}>
                <div className={styles.chartTitle}>Page Usage This Month</div>
                <div className={styles.chartDescription}><InformationTooltip content={description} /></div>
            </div>
            <PieChart data={filteredData} diameter={diameter} />
            <div
                className={styles.pieChartIcon}
                style={{ height: `${diameter / 3}px`, width: `${diameter / 3}px` }}
            >
                <Icon icon={Document} fontSize={diameter / 5} />
            </div>
        </div>
    );
};
