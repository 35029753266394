import React from 'react';

export const Export: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <g>
            <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M105.7,114.1H47.5c-4.5,0-8.1-3.7-8.1-8.1V22c0-4.5,3.7-8.1,8.1-8.1h58.2c4.5,0,8.1,3.7,8.1,8.1v84
		C113.9,110.5,110.2,114.1,105.7,114.1z"/>
            <path fill={color || 'currentColor'} d="M64.3,30.2h24.6c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H64.3c-1,0-1.8,0.8-1.8,1.8C62.6,29.4,63.3,30.2,64.3,30.2z" />
            <path fill={color || 'currentColor'} d="M52,83.8h49.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H52c-1,0-1.8,0.8-1.8,1.8S51,83.8,52,83.8z" />
            <path fill={color || 'currentColor'} d="M52,73.1h24.6c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H52c-1,0-1.8,0.8-1.8,1.8S51,73.1,52,73.1z" />
            <path fill={color || 'currentColor'} d="M52,62.3h49.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H52c-1,0-1.8,0.8-1.8,1.8S51,62.3,52,62.3z" />
            <path fill={color || 'currentColor'} d="M52,40.9h49.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H52c-1,0-1.8,0.8-1.8,1.8C50.2,40.1,51,40.9,52,40.9z" />
            <path fill={color || 'currentColor'} d="M52,51.6h49.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H52c-1,0-1.8,0.8-1.8,1.8S51,51.6,52,51.6z" />
            <path fill={color || 'currentColor'} d="M64.3,101.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H52c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8H64.3z" />
        </g>
        <g>
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={8} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="65.3" y1="91.7" x2="10.1" y2="91.7" />
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={8} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="46.8" y1="112.9" x2="68.7" y2="91.7" />
            <line fill='none' stroke={color || 'currentColor'} strokeWidth={8} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="46.8" y1="70" x2="68.7" y2="91.3" />
        </g>
    </svg>
);

