import React from 'react';

import { Icon } from '../icon/Icon';
import { CustomTooltip } from '../tooltip';
import { Button } from './Button';
import styles from './Button.module.scss';

export interface ButtonResizeProps {
    showIconButton: boolean;
    onClick: () => void;
    label: string;
    icon?: React.FC;
    hasIcon?: boolean;
    disabled?: boolean,
    disabledTooltip?: string[] | null
    testId?: string;
    width?: string;
}

export const ButtonResize: React.FC<ButtonResizeProps> = ({ showIconButton, onClick, label, icon, hasIcon = false, disabled = false, disabledTooltip = [], testId = '', width }) => {
    const buttonTestId = testId.length > 0 ? `${testId}-${label.toLowerCase().replace(/ +/g, '-')}` : label.toLowerCase().replace(/ +/g, '-');
    if (showIconButton) {
        return (<CustomTooltip overlayText={label} placement='top'>
            <button className={styles.iconButtonWrapper} disabled={disabled} onClick={onClick} data-testid={buttonTestId}>
                <Icon icon={icon!} />
            </button>
        </CustomTooltip>);
    } else {
        return (<Button label={label} onClick={onClick} disabled={disabled} icon={hasIcon ? icon : undefined} disabledTooltip={disabledTooltip} testId={buttonTestId} fontSize={15} width={width} />);
    }
};
