import React, { useCallback, useMemo } from 'react';
import { flattenDeep, isEqual, max, set, uniq } from 'lodash/fp';

import { AdminAnalyticsChart, getAttestationProgressAnalytics, getFetchingAnalytics, redirectAttestation, StackChartAnalyticsData, StackData } from './store';
import styles from './AdminAnalytics.module.scss';
import { InformationTooltip } from '../../shared/tooltip';
import { StackChart } from '../../shared/analytics/StackChart';
import { getDeadline } from '../../shared/table';
import { formatDate } from '../../../utils/luxon';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Spinner } from '../../shared/spinner/Spinner';

const { french } = styles;

interface AttestationProgressProps {
    dimensions?: {
        height: number;
        width: number;
    }
    analyticsSpinner: boolean;
}

export const AdminAttestationProgress: React.FC<AttestationProgressProps> = ({ dimensions, analyticsSpinner }) => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(getAttestationProgressAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(AdminAnalyticsChart.ATTESTATION_PROGRESS), [fetchingAnalytics]);
    const viewAttestation = useCallback((attestationInstanceId: number) => { dispatch(redirectAttestation(attestationInstanceId)); }, [dispatch]);

    const { height, width } = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 20;
            const svgHeight = dimensions.height - 55;
            return { height: svgHeight, width: svgWidth };
        }
        return { height: 0, width: 0 };
    }, [dimensions]);

    const stackChartData: StackChartAnalyticsData[] = useMemo(() => data.map(({ name, users, attestationInstanceId }) => {
        const data: StackData = users.reduce((acc, { userAnswerId, weighting }) => set(userAnswerId, weighting, acc), {});
        return { key: `${name} (${attestationInstanceId})`, id: attestationInstanceId, ...data };
    }), [data]);

    const keys = useMemo(() => uniq(flattenDeep(data.map(({ users }) => users.map(({ userAnswerId }) => userAnswerId)))), [data]);

    const findAttestation = useCallback((id: string) => data.find(({ attestationInstanceId, name }) => isEqual(id, `${name.replace(/[\s,(,)]/g, '').toLowerCase()}${attestationInstanceId}`)), [data]);

    const getPercentageFill = useCallback((id: string) => {
        const [attestationNameAndId, answerId] = id.split(':');
        const attestation = findAttestation(attestationNameAndId);
        if (attestation) {
            return attestation.users.find(({ userAnswerId }) => isEqual(userAnswerId, answerId))?.percentageComplete || 0;
        }
        return 0;
    }, [findAttestation]);

    const getBackgroundColor = useCallback((id: string) => {
        const attestation = findAttestation(id);
        if (attestation) {
            return getDeadline(attestation.deadline).color;
        }
        return french;
    }, [findAttestation]);

    const getTooltipDetails = useCallback((instanceId: number, layerId: string) => {
        const attestation = data.find(({ attestationInstanceId }) => isEqual(instanceId, attestationInstanceId));
        if (attestation) {
            const { deadline } = attestation;
            const user = attestation.users.find(({ userAnswerId }) => isEqual(userAnswerId, layerId));
            if (user) {
                const { name, percentageComplete, redFlagAnswers } = user;
                let tooltipRows = [`${name}`, `${percentageComplete}% Complete`, `${redFlagAnswers} Red Flag Answers`];
                if (deadline) {
                    tooltipRows.push(`Deadline: ${formatDate(deadline)}`);
                }
                const content = tooltipRows.map(row => `<tspan dy=12 x=10>${row}</tspan>`).join('');
                const tooltipHeight = (tooltipRows.length + 1) * 12;
                const tooltipWidth = max([10 + (max(tooltipRows.map(row => row.length))! * 7), 100])!;
                return { height: tooltipHeight, width: tooltipWidth, content };
            }
            return null;
        }
        return null;
    }, [data]);

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.chartWrapper} data-testid='attestation-progress-wrapper'>
            <div className={styles.chartHeader}>
                <div className={styles.chartTitle} data-testid='attestation-progress-title'>Attestations</div>
                <div className={styles.chartDescription}><InformationTooltip content='A visualisation of the progress of each attestation assigned within your client. Double click on a bar to see that Attestation.' /></div>
            </div>
            <div style={{ height: '100%' }}>
                <StackChart
                    data={stackChartData}
                    testId='attestation-progress'
                    height={height}
                    width={width}
                    keys={keys}
                    getPercentageFill={getPercentageFill}
                    getBackgroundColor={getBackgroundColor}
                    getTooltipDetails={getTooltipDetails}
                    onDblClick={viewAttestation}
                />
            </div>
        </div>
    );
};
