import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { DoraMyCompaniesView, DoraMyCompaniesState, FunctionMigration } from './types';
import { BaseEntity, CompanyEntity } from '../../../admin/entity/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';

const getRoot = (state: ApplicationState): DoraMyCompaniesState => state.dora.myCompanies;

export const getDoraMyCompaniesView = (state: ApplicationState): DoraMyCompaniesView => getRoot(state).myCompaniesView;

export const getDoraCompanyEntityModalOpen = (state: ApplicationState): boolean => getRoot(state).companyEntityModalOpen;

export const getSelectedDoraCompanyEntity = (state: ApplicationState): CompanyEntity | null => getRoot(state).selectedCompanyEntity;

export const getSavedDoraCompanyEntity = (state: ApplicationState): CompanyEntity | null => getRoot(state).savedCompanyEntity;

export const getCurrentEntityFunctionIds = (state: ApplicationState): number[] => getRoot(state).currentCompanyEntityFunctionIds;

export const getSelectedEntityFunctionIds = (state: ApplicationState): number[] => getRoot(state).selectedCompanyEntityFunctionIds;

export const getPaginatedInScopeDoraCompanies = (state: ApplicationState): CompanyEntity[] => getRoot(state).inScopeCompanies;

export const getPaginatedOutOfDoraCompanies = (state: ApplicationState): CompanyEntity[] => getRoot(state).outOfScopeCompanies;

export const getIsFetchingCompanies = (state: ApplicationState): boolean => getRoot(state).fetchingCompanies;

export const getIsFetchingEntityFunctionIds = (state: ApplicationState): boolean => getRoot(state).fetchingEntityFunctionIds;

export const getMyCompanyEntities = (state: ApplicationState): BaseEntity[] => getRoot(state).myCompanyEntities;

export const getMyCompanyEntitiesDropdownOptions = (state: ApplicationState): DropdownOption[] => {
    const companyEntities = getRoot(state).myCompanyEntities;
    return companyEntities.map(({ name }) => ({ value: name, label: name }));
};

export const getMigrateFunctionConfirmationModalOpen = (state: ApplicationState): boolean => {
    const functionMigration = getRoot(state).functionMigration;
    return !isNull(functionMigration) && functionMigration.migrations.length > 0;
};

export const getFunctionMigration = (state: ApplicationState): FunctionMigration | null => getRoot(state).functionMigration;

export const getIsCompanyFunctionMigration = (state: ApplicationState): boolean => getRoot(state).isCompanyFunctionMigration;

export const getEntityDescriptionModalOpen = (state: ApplicationState): boolean => getRoot(state).entityDescriptionOpen;

export const getEntityIsSaving = (state: ApplicationState): boolean => getRoot(state).isSavingEntity;

// Table Filters and Pagination
// In scope
export const getInScopeTotalCompanies = (state: ApplicationState): number => getRoot(state).inScopeTotalCompanies;
export const getInScopeCompanyFilters = (state: ApplicationState): TableFilters => getRoot(state).inScopeCompanyFilters;
export const getInScopeCompanyColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).inScopeCompanySort;
export const getInScopeCompaniesPageNumber = (state: ApplicationState): number => getRoot(state).inScopeCompanyPage;
export const getInScopeCompaniesPageSize = (state: ApplicationState): number => getRoot(state).inScopeCompanyPageSize;
// Out of scope
export const getOutOfScopeTotalCompanies = (state: ApplicationState): number => getRoot(state).outOfScopeTotalCompanies;
export const getOutOfScopeCompanyFilters = (state: ApplicationState): TableFilters => getRoot(state).outOfScopeCompanyFilters;
export const getOutOfScopeCompanyColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).outOfScopeCompanySort;
export const getOutOfScopeCompaniesPageNumber = (state: ApplicationState): number => getRoot(state).outOfScopeCompanyPage;
export const getOutOfScopeCompaniesPageSize = (state: ApplicationState): number => getRoot(state).outOfScopeCompanyPageSize;
