import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { PAGE_MARGIN_PADDING_BORDER, useSplitView, TIMELINE_WRAPPER } from '../../../../hooks/useSplitView';
import { getAvailableSubCounterpartyTypes, getSelectedSubCounterpartyType, updateOpinionSubCounterparty, upsertOpinionSubCounterpartyTypesStarted, selectSubCounterpartyTypesJurisdiction } from '../../../admin/entity/store';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermission, getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { opinionCounterpartyTypeOptions } from '../../../constants/entity';
import { DatasetModal } from '../../../datasets/instances/modal-instance/DatasetModal';
import { Button } from '../../../shared/button/Button';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Group } from '../../../shared/icons';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Text } from '../../../shared/text/Text';
import { OverflowTooltip } from '../../../shared/tooltip';
import { getOpinionAnalysisView, getOpinion } from '../../my-opinions/store';
import { CounterpartyCoverage, getSelectedSubCounterpartyParent, selectSubCounterpartiesParent, toggleSubCounterpartyModal } from '../store';
import styles from './OpinionSection.module.scss';
import { SubCounterpartConfigBody } from './SubCounterpartyConfigBody';

const widthOffset = PAGE_MARGIN_PADDING_BORDER;

interface SubCounterpartyConfigModalProps {
    counterpartyCoverage: CounterpartyCoverage;
    isOpen: boolean;
    cancel: () => void;
    readOnly: boolean;
    jurisdiction: string | null;
}

export const SubCounterpartyConfigModal: React.FC<SubCounterpartyConfigModalProps> = ({ isOpen, cancel, readOnly, counterpartyCoverage, jurisdiction }) => {
    const dispatch = useAppDispatch();
    const opinionAnalysisView = useAppSelector(getOpinionAnalysisView);
    const selectedParentCounterpartyType = useAppSelector(getSelectedSubCounterpartyParent);
    const availableSubCounterpartyTypes = useAppSelector(getAvailableSubCounterpartyTypes);
    const selectedSubCounterparty = useAppSelector(getSelectedSubCounterpartyType);
    const userHasIndustryStandardPermissions = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));
    const userHasEditSubCounterpartyPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.SUB_COUNTERPARTY_TYPE]));
    const opinion = useAppSelector(getOpinion);
    const isIndustryStandardOpinion = !opinion?.bespoke || false;

    const subCounterpartyNameValue = selectedSubCounterparty?.subCounterpartyName || '';
    const setSubCounterpartyName = useCallback((value: string) => dispatch(updateOpinionSubCounterparty('subCounterpartyName', value)), [dispatch]);
    const updateCounterpartyName = useCallback((event: ChangeEvent<HTMLInputElement>) => setSubCounterpartyName(event.target.value), [setSubCounterpartyName]);
    const saveOpinionSubCounterPartyType = useCallback(() => dispatch(upsertOpinionSubCounterpartyTypesStarted(true)), [dispatch]);
    const setParentSubCounterpartyType = useCallback((value: string) => dispatch(selectSubCounterpartiesParent(value)), [dispatch]);
    const setSubCounterpartyJurisdiction = useCallback((value: string | null) => dispatch(selectSubCounterpartyTypesJurisdiction(value)), [dispatch]);

    const subCounterpartyTypeNameExists = useMemo(() => availableSubCounterpartyTypes.filter(({ parentCounterpartyType }) => selectedParentCounterpartyType === parentCounterpartyType).some(({ subCounterpartyName }) => subCounterpartyName === selectedSubCounterparty?.subCounterpartyName), [availableSubCounterpartyTypes, selectedSubCounterparty, selectedParentCounterpartyType]);
    const addNewDisabled = useMemo(() => subCounterpartyNameValue.length === 0 || subCounterpartyTypeNameExists, [subCounterpartyTypeNameExists, subCounterpartyNameValue]);
    useEffect(() => {
        const isSystem = (userHasIndustryStandardPermissions && isIndustryStandardOpinion) ? 1 : 0;
        dispatch(updateOpinionSubCounterparty('isSystem', isSystem));
    }, [dispatch, userHasIndustryStandardPermissions, isIndustryStandardOpinion]);

    useEffect(() => {
        setSubCounterpartyJurisdiction(jurisdiction);
        return () => {
            setParentSubCounterpartyType('');
            setSubCounterpartyJurisdiction(null);
            setSubCounterpartyName('');
            dispatch(toggleSubCounterpartyModal(false));
        };
    }, [dispatch, setParentSubCounterpartyType, jurisdiction, setSubCounterpartyName, setSubCounterpartyJurisdiction]);

    const handleSetParentCounterpartyType = (parentCounterparty: string) => {
        dispatch(updateOpinionSubCounterparty('parentCounterpartyType', parentCounterparty));
        setParentSubCounterpartyType(parentCounterparty);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!addNewDisabled) {
            if (e.key.toLowerCase() === 'enter' && subCounterpartyNameValue.length !== 0) {
                saveOpinionSubCounterPartyType();
            }
        }
    };

    const [width, height] = useSplitView(opinionAnalysisView, widthOffset, TIMELINE_WRAPPER);

    const showAddSubCounterpartyInput = useMemo(() => !readOnly && ((userHasEditSubCounterpartyPermission && !isIndustryStandardOpinion) || (isIndustryStandardOpinion && userHasIndustryStandardPermissions)), [userHasEditSubCounterpartyPermission, userHasIndustryStandardPermissions, isIndustryStandardOpinion, readOnly]);

    return (
        <DatasetModal
            isOpen={isOpen}
            closeModal={cancel}
            isOpinionInstance
        >
            <div className={styles.subCounterpartyTypesModalWrapper} style={{ width, height }}>
                <ModalHeader label='Sub Counterparty Types' icon={Group}></ModalHeader>
                <div className={styles.subCounterpartyTypesModalBody}>
                    <div className={styles.subCounterpartyTypesSelectColumn}>
                        <Scrollable>
                            {opinionCounterpartyTypeOptions.map((counterPartyType, index) => <div
                                className={classNames(styles.parentCounterpartyCard, { [styles.selected]: counterPartyType === selectedParentCounterpartyType })}
                                onClick={() => handleSetParentCounterpartyType(counterPartyType)}
                                key={index}>
                                <OverflowTooltip className={styles.parentCounterpartyCardTooltip} overlayText={counterPartyType}></OverflowTooltip>
                            </div>)}
                        </Scrollable>
                    </div>
                    {!selectedParentCounterpartyType ? <div className={styles.subCounterpartyTypesIncludedItemsSectionWrapper}></div> :
                        <div className={styles.subCounterpartyTypesIncludedItemsSectionWrapper}>
                            <SubCounterpartConfigBody
                                counterpartyCoverage={counterpartyCoverage}
                                readOnly={readOnly}
                                availableSubCounterpartyTypes={availableSubCounterpartyTypes}
                                isIndustryStandardOpinion={isIndustryStandardOpinion}
                                showSelectAllLabel={width > 700}
                            />
                            {showAddSubCounterpartyInput && <div className={styles.addNewSubCounterpartyTypeWrapper}>
                                <Text
                                    testId='new-sub-counterparty-instance'
                                    value={subCounterpartyNameValue}
                                    onChange={updateCounterpartyName}
                                    onKeyDown={handleKeyDown}
                                    marginRight='5px'
                                    maxLength={254}
                                    placeholder='Add a new Sub Counterparty Type'
                                    marginBottom='0px'
                                    width='calc(100% - 31px)'
                                />
                                <div className={styles.addNewSubCounterpartyTypeButtonWrapper}>
                                    <PlusButton onClick={saveOpinionSubCounterPartyType} disabled={addNewDisabled} fontSize={20} />
                                </div>
                            </div>}
                        </div>}
                </div>
                <div className={styles.subCounterpartyTypesModalButtonWrapper}>
                    <Button onClick={cancel} label='Close' />
                </div>
            </div>
        </DatasetModal>
    );
};
