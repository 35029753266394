import { isNull, noop } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';

import styles from './Playbook.module.scss';
import { getCurrentView, getResolvedChangesContent, getResolvedPlaybookSuggestedChange, getResolvedSuggestedChangesOpen, PlaybookView, ReadOnlyContentType, setSelectedResolvedSuggestedChange, SuggestedChangesDB, toggleResolvedSuggestedChangesContent } from './store';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getUser } from '../../auth/login/store';
import { ReadOnlyProvision } from '../../shared/playbook/view/ReadOnlyProvision';
import { ABSTRACT_ID } from '../../constants/playbooks';
import { WYSIWYG } from '../../shared/wysiwyg/WYSIWYG';
import { Conversation } from '../../shared/playbook/conversation/Conversation';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { formatDate, FULL_DATE_FORMAT } from '../../../utils/luxon';
import { PlaybookIcon, Tick } from '../../shared/icons';
import { Button } from '../../shared/button/Button';
import { getAllBasicUsers } from '../users/store';
import { Icon } from '../../shared/icon/Icon';

const { white } = styles;

interface SuggestionContentProps {
    suggestedChange: SuggestedChangesDB;
    contentView: ReadOnlyContentType;
    testId: string;
}

const BUTTON_HEIGHT = 48;
const MODAL_HEADER = 56;
const HEIGHT_OFFSET = BUTTON_HEIGHT + MODAL_HEADER;

const SuggestionContent: React.FC<SuggestionContentProps> = ({ suggestedChange, contentView, testId }) => {
    const [deviationOpen, setDeviationOpen] = useState<boolean>(false);

    const playbookView = useAppSelector(getCurrentView);
    const user = useAppSelector(getUser);

    const toggleDeviation = useCallback(() => setDeviationOpen(!deviationOpen), [setDeviationOpen, deviationOpen]);

    const allUsers = useAppSelector(getAllBasicUsers);
    const getDisplayName = useCallback((id: number) => {
        const user = allUsers.find(({ userId }) => userId === id);
        if (!user) {
            return 'unknown';
        }
        const { forenames, username, surname } = user;
        return forenames ? `${forenames} ${surname}` : username;
    }, [allUsers]);

    const content = useMemo(() => {
        if (suggestedChange.suggestedSection.type !== ABSTRACT_ID) {
            const { suggestedSection: { content, changedContent } } = suggestedChange;
            return (
                <ReadOnlyProvision
                    isTableView={playbookView === PlaybookView.TABLE}
                    section={content}
                    deviationOpen={deviationOpen}
                    toggleDeviation={toggleDeviation}
                    suggestedChangeContent={changedContent}
                    testId={`${testId}-read-only-provision-0`}
                />
            );
        }
        return (
            <div className={styles.previewAbstractWrapper} data-testid={`${testId}-abstract-read-only-wrapper`}>
                <WYSIWYG
                    content={suggestedChange.suggestedSection.content}
                    updateContent={noop}
                    showBorder={false}
                    height='fit-content'
                    minHeight='fit-content'
                    maxHeight='fit-content'
                    borderColor={white}
                    padding='0px'
                    editorPadding='0px'
                    disabled
                    toolbarHidden
                />
            </div>
        );
    }, [suggestedChange, playbookView, toggleDeviation, deviationOpen, testId]);

    if (contentView === ReadOnlyContentType.CONVERSATION) {
        const resolvedByName = getDisplayName(suggestedChange.resolvedBy!);
        const resolvedDateFormatted = formatDate(suggestedChange.resolvedDate!, FULL_DATE_FORMAT);
        if (suggestedChange.conversation.length === 0) {
            return (
                <div className={styles.provisionWrapper}>
                    <div className={styles.resolvedConversationPlaceholder} style={{ height: `calc(60vh - ${HEIGHT_OFFSET}px)` }}>No messages were exchanged</div>
                    <div className={styles.resolvedSignOff}>
                        {resolvedByName} resolved the suggested changes on {resolvedDateFormatted}
                        <div className={styles.resolvedChangesIcon}><Icon icon={Tick} fontSize={18} color={white} /></div>
                    </div>
                </div>
            );
        }
        return (
            <div className={styles.provisionWrapper}>
                <Conversation
                    conversation={suggestedChange.conversation}
                    currentUserId={user!.userId!}
                    showSpinner={false}
                    height={`calc(60vh - ${HEIGHT_OFFSET}px)`}
                    testId={testId}
                />
                <div className={styles.resolvedSignOff} data-testid={`${testId}-conversation-sign-off`}>
                    {resolvedByName} resolved the suggested changes on {resolvedDateFormatted}
                    <div className={styles.resolvedChangesIcon}><Icon icon={Tick} fontSize={18} color={white} /></div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.provisionWrapper}>
            <Scrollable>
                {content}
            </Scrollable>
        </div>
    );
};

interface ResolvedSuggestedChangesModalProps {
    testId?: string;
}

export const ResolvedSuggestedChangesModal: React.FC<ResolvedSuggestedChangesModalProps> = ({ testId }) => {
    const dispatch = useAppDispatch();

    const isOpen = useAppSelector(getResolvedSuggestedChangesOpen);
    const suggestedChange = useAppSelector(getResolvedPlaybookSuggestedChange);
    const contentView = useAppSelector(getResolvedChangesContent);

    const isConversationView = useMemo(() => contentView === ReadOnlyContentType.CONVERSATION, [contentView]);
    const closeModal = useCallback(() => dispatch(setSelectedResolvedSuggestedChange(null)), [dispatch]);
    const toggleContent = useCallback(() => dispatch(toggleResolvedSuggestedChangesContent(isConversationView ? ReadOnlyContentType.CHANGES : ReadOnlyContentType.CONVERSATION)), [dispatch, isConversationView]);
    const toggleViewLabel = useMemo(() => `View ${isConversationView ? 'Changes' : 'Conversation'}`, [isConversationView]);
    const modalHeaderLabel = useMemo(() => !isNull(suggestedChange) ? `Suggested Change: ${suggestedChange.suggestedSection.name} - ${formatDate(suggestedChange.createdDate, FULL_DATE_FORMAT)}` : '', [suggestedChange]);

    if (isNull(suggestedChange)) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            className={styles.resolvedSuggestedChangesModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 9 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.suggestedChangesWrapper} data-testid={`${testId}-resolved-suggested-changes-modal-wrapper`}>
                <ModalHeader label={modalHeaderLabel} icon={PlaybookIcon} testId={`${testId}-resolved-suggested-changes-modal`} />
                <SuggestionContent suggestedChange={suggestedChange} contentView={contentView} testId={`${testId}-resolved-suggested-changes`} />
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' testId={`${testId}-resolved-suggested-changes-close`} />
                    <Button onClick={toggleContent} label={toggleViewLabel} testId={`${testId}-resolved-suggested-changes-conversation`} />
                </div>
            </div>
        </Modal>
    );
};
