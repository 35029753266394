import { isNaN, isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Options } from 'react-select';

import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import styles from './RiskField.module.scss';
import { NumberRiskConfig, RiskAssociated, RiskAssociatedValue, RiskComparator, NumberComparatorRisk } from '../../../admin/risk-tolerance/store';

const { grey } = styles;

interface NumberRiskFieldConfigProps {
    riskConfig: NumberRiskConfig | null;
    includeRiskField: boolean;
    riskAssociated: RiskAssociated;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValue, selectedCurrency?: string) => void;
    selectedCurrency?: string;
}

export const NumberRiskFieldConfig: React.FC<NumberRiskFieldConfigProps> = ({ riskConfig, includeRiskField, riskAssociated, updateRiskConfig, selectedCurrency }) => {

    const [comparator, setComparator] = useState<null | RiskComparator>(null);
    const [value, setValue] = useState<undefined | number>(undefined);

    const comparatorOptions = useMemo(() => Object.values(RiskComparator).map(value => ({ value, label: value })), []);
    const comparatorValue = useMemo(() => comparator ? { value: comparator, label: comparator } : null, [comparator]);

    const updateComparatorValue = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let updatedComparator = null;
        if (!isNull(dropdownValue)) {
            updatedComparator = (dropdownValue as DropdownOption).value as RiskComparator;
        }
        const numberConfig: NumberComparatorRisk = { value, comparator: updatedComparator };
        setComparator(updatedComparator);
        updateRiskConfig(riskAssociated, numberConfig, selectedCurrency);
    }, [updateRiskConfig, riskAssociated, value, selectedCurrency]);

    const updateValue = useCallback((stringValue: string) => {
        const numberValue = parseInt(stringValue.replace(/,/g, ''));
        if (!isNaN(numberValue) || (isNaN(numberValue) && stringValue.length === 0)) {
            const numberConfig: NumberComparatorRisk = { value: numberValue || undefined, comparator };
            setValue(numberValue || undefined);
            updateRiskConfig(riskAssociated, numberConfig, selectedCurrency);
        }
    }, [updateRiskConfig, riskAssociated, comparator, selectedCurrency]);

    useEffect(() => {
        if (!isNull(riskConfig)) {
            setValue(riskConfig[riskAssociated].value);
            setComparator(riskConfig[riskAssociated].comparator);
            return () => {
                setValue(undefined);
                setComparator(null);
            };
        }
    }, [riskConfig, riskAssociated]);

    const formattedNumberValue = useMemo(() => value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '', [value]);

    return (
        <div className={styles.numberWrapper}>
            <div className={styles.comparatorWrapper}>
                <Dropdown options={comparatorOptions} value={comparatorValue} onChange={updateComparatorValue} isClearable controlBorderColor={grey} disabled={!includeRiskField} />
            </div>
            <div className={styles.numberInputWrapper}>
                <input
                    className={styles.numberField}
                    value={formattedNumberValue}
                    onChange={e => updateValue(e.target.value)}
                    disabled={!includeRiskField}
                />
            </div>
        </div>
    );
};
