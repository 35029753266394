import { isEqual, isNull, uniq } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { AdminAIState, AIAgreementType, DocumentQuery, PortfolioTotal, SystemIdInfo, UpdatedQueryOrder } from './types';

const getRoot = (state: ApplicationState): AdminAIState => state.admin.ai;

export const getIsFetchingAgreementTypes = (state: ApplicationState): boolean => getRoot(state).isFetchingAgreementTypes;

export const getIsFetchingDocumentQueries = (state: ApplicationState): boolean => getRoot(state).isFetchingQueries;

export const getSelectedAIAgreementTypeId = (state: ApplicationState): number | null => getRoot(state).selectedAgreement;

export const getAllAIAgreementTypes = (state: ApplicationState): AIAgreementType[] => getRoot(state).agreementTypes;

export const getPortfolioTotal = (state: ApplicationState): PortfolioTotal | null => getRoot(state).portfolioTotal;

export const getCurrentDocumentQueries = (state: ApplicationState): DocumentQuery[] => getRoot(state).currentQueries;

export const getSavedDocumentQueries = (state: ApplicationState): DocumentQuery[] => getRoot(state).savedQueries.sort((a, b) => a.queryOrder - b.queryOrder);

export const getUpdatedQueryOrder = (state: ApplicationState): UpdatedQueryOrder[] => {
    const savedQueryOrder = getSavedDocumentQueries(state);
    const currentQueryOrder = getCurrentDocumentQueries(state);
    const updatedQueryOrder: UpdatedQueryOrder[] = currentQueryOrder.map(({ documentQueryId }, index) => ({ documentQueryId: documentQueryId!, queryOrder: index + 1 }));
    const updatedQueries = updatedQueryOrder.reduce((acc: UpdatedQueryOrder[], cur) => {
        const savedQuery = savedQueryOrder.find(({ documentQueryId }) => documentQueryId === cur.documentQueryId)!;
        if (savedQuery.queryOrder !== cur.queryOrder) {
            acc.push(cur);
        }
        return acc;
    }, []);
    return updatedQueries;
};

export const getQueriesUpdated = (state: ApplicationState): boolean => getRoot(state).queriesUpdated;

export const getIsEditingDocumentQueries = (state: ApplicationState): boolean => getRoot(state).isEditing;

export const getUpdatedQueries = (state: ApplicationState): DocumentQuery[] => {
    const currentQueries = getCurrentDocumentQueries(state);
    const savedQueries = getSavedDocumentQueries(state);
    const updatedQueries = currentQueries.reduce((acc: DocumentQuery[], cur) => {
        if (isNull(cur.documentQueryId)) {
            acc.push(cur);
        } else {
            const savedQuery = savedQueries.find(({ documentQueryId }) => documentQueryId === cur.documentQueryId)!;
            if (!isEqual(savedQuery, cur)) {
                acc.push(cur);
            }
        }
        return acc;
    }, []);
    return updatedQueries;
};

export const getDocumentQuerySaveDisabled = (state: ApplicationState): { saveDisabled: boolean, saveDisabledTooltip: string[] } => {
    const queriesUpdated = getQueriesUpdated(state);
    const currentQueries = getCurrentDocumentQueries(state);
    const allQueriesHaveQuestionLength = currentQueries.map(({ question }) => question).every(question => question.length > 0);
    const allSystemIdsHaveLength = currentQueries.map(({ systemId }) => systemId).every(systemId => systemId.length > 0);
    const allDocumentNameIdsHaveLength = currentQueries.map(({ documentNameIds }) => documentNameIds).every(documentNameIds => documentNameIds.length > 0);
    const allKeys = currentQueries.map(({ queryKey }) => queryKey);
    const allKeysHaveLength = allKeys.every(queryKey => queryKey.length > 0);
    const allKeysAreUnique = uniq(allKeys).length === allKeys.length;
    const allQueryKeysHaveLengthAndUnique = allKeysHaveLength && allKeysAreUnique;

    let saveDisabledTooltip: string[] = [];
    if (!allQueriesHaveQuestionLength) {
        saveDisabledTooltip.push('Some of the queries do not have a question');
    }
    if (!allSystemIdsHaveLength) {
        saveDisabledTooltip.push('Some of the queries do not have a system Id');
    }
    if (!allDocumentNameIdsHaveLength) {
        saveDisabledTooltip.push('Some of the queries do not have a document name assigned');
    }
    if (!allQueryKeysHaveLengthAndUnique) {
        saveDisabledTooltip.push('Some of the queries do not have a unique query key');
    }

    const saveDisabled = !queriesUpdated || saveDisabledTooltip.length > 0;

    return { saveDisabled, saveDisabledTooltip };
};

export const getLastUpdatedOrder = (state: ApplicationState): number | null => getRoot(state).lastUpdatedOrder;

export const getAgreementTypeSystemInfo = (state: ApplicationState): SystemIdInfo[] => getRoot(state).agreementTypeSystemInfo;

export const getSelectedClientId = (state: ApplicationState): number | null => getRoot(state).selectedClientId;
