import { Reducer } from 'redux';
import { flow, set, unset } from 'lodash/fp';

import { LoginState, LoginActionTypes, User } from './types';

export const INITIAL_STATE: LoginState = {
    user: null,
    isLoggedIn: false,
    loginError: null,
    logoutError: null,
    checkingAuth: true,
    loggingOut: false,
    reminderType: null,
    reminderError: null,
    socket: null,
    logoutWarningModalOpen: false,
    profileModalOpen: false,
    profileDetails: null,
    editingProfile: false,
    updatingProfile: false,
    profileHasUpdated: false,
    switchingAccounts: false,
    switchAccountError: null,
    switchUsername: null,
    switchPassword: '',
    idleCheckTokenValid: false,
    sendingReminder: false,
    showResetPassword: false,
    showSwitchAccount: false
};

export const loginReducer: Reducer<LoginState> = (state = INITIAL_STATE, { type, payload }): LoginState => {
    switch (type) {
        case LoginActionTypes.LOGIN_SUCCESSFUL:
            return flow(
                set('user', payload),
                set('profileDetails', payload),
                set('isLoggedIn', true),
                set('loginError', null),
                set('checkingAuth', false)
            )(state);
        case LoginActionTypes.LOGIN_FAILED:
            return set('loginError', payload, state);
        case LoginActionTypes.REDIRECT_TO_LOGIN:
            return set('checkingAuth', false, state);
        case LoginActionTypes.LOGOUT_STARTED:
            return flow(
                set('loggingOut', true),
                set('logoutWarningModalOpen', false)
            )(state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return { ...INITIAL_STATE, checkingAuth: false, socket: state.socket };
        case LoginActionTypes.LOGOUT_FAILED:
            return flow(
                set('logoutError', payload),
                set('loggingOut', false),
                set('logoutWarningModalOpen', false)
            )(state);
        case LoginActionTypes.SET_REMINDER_TYPE:
            return set('reminderType', payload, state);
        case LoginActionTypes.FORGOTTEN_USERNAME_REMINDER_STARTED:
        case LoginActionTypes.FORGOTTEN_PASSWORD_REMINDER_STARTED:
            return set('sendingReminder', true, state);
        case LoginActionTypes.FORGOTTEN_USERNAME_REMINDER_SUCCESSFUL:
        case LoginActionTypes.FORGOTTEN_PASSWORD_REMINDER_SUCCESSFUL:
            return flow(
                set('reminderType', null),
                set('reminderError', null),
                set('sendingReminder', false)
            )(state);
        case LoginActionTypes.FORGOTTEN_USERNAME_REMINDER_FAILED:
        case LoginActionTypes.FORGOTTEN_PASSWORD_REMINDER_FAILED:
            return flow(
                set('reminderError', payload),
                set('sendingReminder', false)
            )(state);
        case LoginActionTypes.SET_SOCKETS_CONNECTION:
            return set('socket', payload, state);
        case LoginActionTypes.TOGGLE_LOGOUT_WARNING_MODAL:
            return set('logoutWarningModalOpen', payload, state);
        case LoginActionTypes.USER_UPDATED:
            return flow(
                set('user', payload),
                set('profileDetails', payload)
            )(state);
        case LoginActionTypes.TOGGLE_MY_PROFILE_MODAL: {
            if (!payload) {
                return flow(
                    set('profileModalOpen', false),
                    set('editingProfile', false),
                    set('profileHasUpdated', false),
                    set('profileDetails', state.user)
                )(state);
            }
            return flow(
                set('profileModalOpen', true),
                set('profileDetails', state.user)
            )(state);
        }
        case LoginActionTypes.EDIT_MY_PROFILE_DETAILS: {
            if (!payload) {
                const profileDetails: User = {
                    ...state.profileDetails!,
                    forenames: state.user!.forenames,
                    surname: state.user!.surname,
                    profilePicture: state.user?.profilePicture
                };
                return flow(
                    set('editingProfile', payload),
                    set('profileHasUpdated', false),
                    set('profileDetails', profileDetails)
                )(state);
            }
            return set('editingProfile', payload, state);
        }
        case LoginActionTypes.SET_MY_PROFILE_UPDATED:
            return set('profileHasUpdated', payload, state);
        case LoginActionTypes.UPDATE_MY_PROFILE_DETAILS: {
            const { key, value } = payload;
            return set(`profileDetails[${key}]`, value, state);
        }
        case LoginActionTypes.UPDATE_MY_PROFILE_STARTED:
            return set('updatingProfile', true, state);
        case LoginActionTypes.UPDATE_MY_PROFILE_SUCCESSFUL:
            return flow(
                unset('profileDetails.file'),
                set('updatingProfile', false),
                set('profileHasUpdated', false),
                set('editingProfile', false)
            )(state);
        case LoginActionTypes.UPDATE_MY_PROFILE_FAILED:
            return set('updatingProfile', false, state);
        case LoginActionTypes.SET_UPDATING_PROFILE:
            return set('updatingProfile', payload, state);
        case LoginActionTypes.SWITCH_USER_ACCOUNT_STARTED:
            return set('switchingAccounts', true, state);
        case LoginActionTypes.SWITCH_USER_ACCOUNT_SUCCESSFUL:
            return flow(
                set('switchingAccounts', false),
                set('switchAccountError', null),
                set('profileModalOpen', false),
                set('switchUsername', null),
                set('switchPassword', '')
            )(state);
        case LoginActionTypes.SWITCH_USER_ACCOUNT_FAILED:
            return flow(
                set('switchingAccounts', false),
                set('switchAccountError', payload),
                set('switchPassword', '')
            )(state);
        case LoginActionTypes.SET_ACCOUNT_SWITCH_USERNAME:
            return set('switchUsername', payload, state);
        case LoginActionTypes.SET_ACCOUNT_SWITCH_PASSWORD:
            return set('switchPassword', payload, state);
        case LoginActionTypes.SET_SHOW_RESET_PASSWORD:
            return set('showResetPassword', payload, state);
        case LoginActionTypes.SET_SHOW_SWITCH_ACCOUNT: {
            if (!payload) {
                return flow(
                    set('showSwitchAccount', false),
                    set('switchingAccounts', false),
                    set('switchAccountError', null),
                    set('switchUsername', null),
                    set('switchPassword', '')
                )(state);
            }
            return set('showSwitchAccount', payload, state);
        }
        case LoginActionTypes.UPDATE_USER_CUSTOM_FILTERS:
            return flow(
                set('user.customFilters', payload),
                set('profileDetails.customFilters', payload)
            )(state);
        case LoginActionTypes.SET_IDLE_CHECK_TOKEN_VALID:
            return set('idleCheckTokenValid', payload, state);
        default:
            return state;
    }
};
