import { toast } from 'react-toastify';
import { all, call, fork, put, select, takeLeading } from 'redux-saga/effects';

import { sendNotification } from '../../../../services/notifications';
import { sendNotificationFailed, sendNotificationSuccessful } from './actions';
import { getNotificationToSend } from './selectors';
import { NotificationTemplate, SendNotificationActionTypes } from './types';

export function* attemptSendNotification() {
    try {
        const notification: NotificationTemplate = yield select(getNotificationToSend);
        yield call(sendNotification, { notification });
        yield put(sendNotificationSuccessful());
        toast('Successfully sent notification');
    } catch (e) {
        toast.error('Notification failed to send. Please try again.');
        yield put(sendNotificationFailed((e as Error).message));
    }
}

function* sendNotificationWatcher() {
    yield takeLeading(SendNotificationActionTypes.SEND_NOTIFICATION_STARTED, attemptSendNotification);
}

export function* sendNotificationSaga() {
    yield all([
        fork(sendNotificationWatcher)
    ]);
}
