import { all, fork } from 'redux-saga/effects';

import { adminAnalyticsSaga } from '../components/admin/analytics/store/sagas';
import { adminAttestationsSaga } from '../components/admin/attestations/store/sagas';
import { clientSaga } from '../components/admin/clients/store/sagas';
import { datasetBuilderSaga } from '../components/admin/dataset-builder/store/sagas';
import { documentNameSaga } from '../components/admin/documents/store/sagas';
import { dropdownListSaga } from '../components/admin/dropdown-lists/store/sagas';
import { entitySaga } from '../components/admin/entity/store/sagas';
import { myDatasetsSaga } from '../components/admin/my-datasets/store/sagas';
import { sendNotificationSaga } from '../components/admin/notification/store/sagas';
import { adminOpinionsSaga } from '../components/admin/opinions/store/sagas';
import { adminPlaybookSaga } from '../components/admin/playbook/store/sagas';
import { riskToleranceSaga } from '../components/admin/risk-tolerance/store/sagas';
import { userSaga } from '../components/admin/users/store/sagas';
import { workflowSaga } from '../components/admin/workflow/store/sagas';
import { annexDefinitionSaga } from '../components/agency-annex/store/sagas';
import { userAttestationsSaga } from '../components/attestations/store/sagas';
import { loginSaga } from '../components/auth/login/store';
import { notificationSaga } from '../components/auth/notifications/store/sagas';
import { registrationSaga } from '../components/auth/registration/store/sagas';
import { resetPasswordSaga } from '../components/auth/resetPassword/store';
import { clauseLibrarySaga } from '../components/clause-library/store/sagas';
import { datasetInstanceSaga } from '../components/datasets/instances/store/sagas';
import { documentAnalyticsSaga } from '../components/documents/analytics/store/sagas';
import { myDocumentsSaga } from '../components/documents/my-documents/store/sagas';
import { documentReportingSaga } from '../components/documents/reporting/store';
import { searchDocumentsSaga } from '../components/documents/search/store/sagas';
import { doraSaga } from '../components/dora/store';
import { homepageSaga } from '../components/home/store/sagas';
import { opinionInstanceSaga } from '../components/opinions/instances/store/sagas';
import { myOpinionsSaga } from '../components/opinions/my-opinions/store/sagas';
import { opinionReportingSaga } from '../components/opinions/reporting/store';
import { opinionAnalyticsSaga } from '../components/opinions/analytics/store/sagas';
import { opinionNettingEngineSaga } from '../components/opinions/netting-engine/store';
import { userPlaybookSaga } from '../components/playbook/store/sagas';
import { adminAISaga } from '../components/admin/ai/store';

export default function* rootSaga() {
    yield all([
        fork(loginSaga),
        fork(resetPasswordSaga),
        fork(datasetBuilderSaga),
        fork(documentNameSaga),
        fork(datasetInstanceSaga),
        fork(myDocumentsSaga),
        fork(searchDocumentsSaga),
        fork(documentAnalyticsSaga),
        fork(documentReportingSaga),
        fork(entitySaga),
        fork(dropdownListSaga),
        fork(userSaga),
        fork(annexDefinitionSaga),
        fork(myOpinionsSaga),
        fork(opinionReportingSaga),
        fork(opinionInstanceSaga),
        fork(adminAttestationsSaga),
        fork(userAttestationsSaga),
        fork(clientSaga),
        fork(notificationSaga),
        fork(workflowSaga),
        fork(registrationSaga),
        fork(homepageSaga),
        fork(myDatasetsSaga),
        fork(adminPlaybookSaga),
        fork(sendNotificationSaga),
        fork(userPlaybookSaga),
        fork(clauseLibrarySaga),
        fork(riskToleranceSaga),
        fork(opinionAnalyticsSaga),
        fork(adminAnalyticsSaga),
        fork(opinionNettingEngineSaga),
        fork(doraSaga),
        fork(adminOpinionsSaga),
        fork(adminAISaga)
    ]);
}
