import { isNull, noop } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Options } from 'react-select';
import classNames from 'classnames';

import Logo from '../../../../assets/logos/Ark51_HORIBLACK&GRAD.png';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getUserRole } from '../../../auth/login/store';
import { adminRoles } from '../../../constants/permittedRoles';
import { Button } from '../../../shared/button/Button';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { scopeFormatter } from '../../../shared/table/arkTableFormatters';
import { WYSIWYG } from '../../../shared/wysiwyg/WYSIWYG';
import { defaultEmptyWysiwyg } from '../../instances/fields/DropdownDetails';
import { enquireOpinionStarted } from '../../my-opinions/store';
import styles from '../Analytics.module.scss';
import { OpinionMapAnalytics, PermittedOpinionMapAnalytics, getOpinionAnalyticsScope, getOpinionSingleField, getSelectedSingleFieldJurisdiction, getSingleDataPointMapAnalytics, setOpinionSingleField, getViewSignOffAnswers, setViewSignOffAnswers, SmileyTileEnum } from '../store';
import { getAvailableDropdownDetailsFieldsForScope } from '../store/utils';
import { UK } from './SingleDataPointChartWrapper';
import { SingleIndicator, SmileyIndicator } from '../../../shared/analytics/SingleIndicator';
import { Toggle } from '../../../shared/toggle';
import { getPositiveAndNegativeAnswers } from '../../../constants/opinion';

const { lightestGrey, primary, red, amber, green } = styles;

interface UnauthorisedOpinionProps {
    opinionId: number;
}

const UnauthorisedOpinion: React.FC<UnauthorisedOpinionProps> = ({ opinionId }) => {
    const dispatch = useAppDispatch();
    const userRole = useAppSelector(getUserRole);
    const isAdminUser = adminRoles.includes(userRole!);

    const messageOne = useMemo(() => isAdminUser ? 'If you would like to know more about this opinion and the cost associated with accessing this content.' : 'If you think this opinion would be of interest to your company, please reach out to your team administrator', [isAdminUser]);
    const messageTwo = useMemo(() => isAdminUser ? 'Please click the \'Enquire\' button below to send an enquiry email and someone from our team will be in touch' : 'They can use this page so send an enquiry via the button below and someone from our team will be in touch', [isAdminUser]);

    const sendEnquiry = useCallback(() => { dispatch(enquireOpinionStarted(opinionId)); }, [dispatch, opinionId]);

    return (
        <div className={styles.unauthorisedOpinionWrapper}>
            <div className={styles.warningHeader}>You do not currently have access to view this opinion</div>
            <div className={styles.unauthorisedOpinionAccess}>{messageOne}</div>
            <div className={styles.unauthorisedOpinionAccess}>{messageTwo}</div>
            {isAdminUser &&
                <div className={styles.enquireButtonWrapper}>
                    <Button label='Enquire' onClick={sendEnquiry} />
                </div>
            }
        </div>
    );
};

interface PermittedInformationProps {
    opinion: PermittedOpinionMapAnalytics;
    selectedTile: SmileyTileEnum;
}

export const PermittedInformation: React.FC<PermittedInformationProps> = ({ opinion, selectedTile }) => {
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
    const wysiwygOpenIcon = detailsOpen ? CaretDown : CaretSide;
    const toggleDetailsOpen = () => setDetailsOpen(!detailsOpen);

    const showClientValues = useMemo(() => selectedTile === SmileyTileEnum.CLIENT_ANSWER, [selectedTile]);

    const { dropdownValue, wysiwygValue, isPositive, isNegative, signOff } = opinion;

    const renderedDropdownValue = useMemo(() => {
        if (showClientValues) {
            if (!isNull(signOff)) {
                return signOff.dropdownValue ? { value: signOff.dropdownValue[0], label: signOff.dropdownValue[0] } : null;
            }
        }
        return isNull(dropdownValue) ? null : { value: dropdownValue, label: dropdownValue };
    }, [dropdownValue, signOff, showClientValues]);

    const detailsLabel = useMemo(() => {
        if (!isNull(signOff) && showClientValues) {
            return 'Note';
        }
        return `Details${isNull(wysiwygValue) ? ' (none)' : ''}`;
    }, [wysiwygValue, signOff, showClientValues]);

    const wysiwygFieldValue = useMemo(() => {
        const wysiwyg = !isNull(signOff) && showClientValues ? signOff.notes : wysiwygValue;
        return isNull(wysiwyg) ? defaultEmptyWysiwyg : wysiwyg;
    }, [wysiwygValue, signOff, showClientValues]);

    const color = useMemo(() => {
        if (isNull(dropdownValue) || isNull(wysiwygValue)) {
            return primary;
        }
        if (isPositive) {
            return green;
        }
        if (isNegative) {
            return red;
        }
        if (!isNegative && !isPositive) {
            return amber;
        }
    }, [isPositive, isNegative, dropdownValue, wysiwygValue]);

    return (
        <div className={styles.permittedOpinionWrapper}>
            <div className={styles.dropdownWrapper}>
                <Dropdown
                    options={[]}
                    value={renderedDropdownValue}
                    disabled={true}
                    menuPortalTarget={document.body}
                    onChange={noop}
                    placeholder='No value provided'
                />
            </div>
            <div className={styles.wysiwygDetailsWrapper}>
                <div className={styles.wysiwygHeader} onClick={toggleDetailsOpen} data-testid='permitted-opinion-details-collapse'>
                    <div className={styles.wysiwygOpenIcon}>
                        <Icon icon={wysiwygOpenIcon} fontSize={15} color={color} />
                    </div>
                    <div className={styles.wysiwygLabel} data-testid='permitted-opinion-wysiwyg-details'>{detailsLabel}</div>
                </div>
                {detailsOpen &&
                    <div className={styles.wysiwygContent}>
                        <WYSIWYG
                            content={wysiwygFieldValue}
                            updateContent={noop}
                            disabled={true}
                            toolbarHidden={true}
                            height='fit-content'
                            maxHeight='fit-content'
                            min-height='fit-content'
                            showBorder={false}
                            isReadOnly
                        />
                    </div>
                }
            </div>
        </div>
    );
};

interface ExtensiveDataProps {
    extensiveData: OpinionMapAnalytics[];
    question: DropdownOption | null;
    height: number;
}

const ExtensiveData: React.FC<ExtensiveDataProps> = ({ extensiveData, question, height }) => {
    const [selectedTile, setSelectedTile] = useState<SmileyTileEnum>(SmileyTileEnum.CLIENT_ANSWER);

    const getFieldContent = useCallback((extensiveData: OpinionMapAnalytics) => {
        const { isPermitted, opinionId } = extensiveData;
        if (!isPermitted) {
            return (
                <UnauthorisedOpinion opinionId={opinionId} />
            );
        } else {
            const permittedData = extensiveData as PermittedOpinionMapAnalytics;
            return (
                <PermittedInformation opinion={permittedData} selectedTile={selectedTile} />
            );
        }
    }, [selectedTile]);

    const getIndicator = useCallback((extensiveData: OpinionMapAnalytics, isSignOff: boolean) => {
        const { isPermitted, signOff } = extensiveData;
        if (!isPermitted) {
            return SmileyIndicator.NONE;
        } else {
            if (isSignOff) {
                return signOff?.smileyValue || SmileyIndicator.NONE;
            }
            const permittedData = extensiveData as PermittedOpinionMapAnalytics;
            const { dropdownValue } = permittedData;
            let isPositiveValue;
            let isNegativeValue;
            if (question && question.value) {
                const [sectionId, fieldId] = question.value.split('---');
                const { negativeAnswers, positiveAnswers } = getPositiveAndNegativeAnswers(sectionId, fieldId);
                isPositiveValue = !isNull(dropdownValue) && positiveAnswers.includes(dropdownValue);
                isNegativeValue = !isNull(dropdownValue) && negativeAnswers.includes(dropdownValue);
            }

            if (isPositiveValue) {
                return SmileyIndicator.POSITIVE;
            }
            if (isNegativeValue) {
                return SmileyIndicator.NEGATIVE;
            }
            if (isNull(dropdownValue)) {
                return SmileyIndicator.NONE;
            }
            return SmileyIndicator.NEUTRAL;
        }
    }, [question]);

    const smileyIndicators = useCallback((data: OpinionMapAnalytics, clientAnswerSelected: boolean) => {

        if (isNull(data.signOff)) {
            return <SingleIndicator indicator={getIndicator(data, false)} fontSize={60} />;
        }

        return <div className={styles.indicatorWrapper}>
            <div className={classNames(styles.indicatorTile, { [styles.selectedIndicatorTile]: !clientAnswerSelected })} onClick={() => setSelectedTile(SmileyTileEnum.ARK_ANSWER)}>
                <SingleIndicator indicator={getIndicator(data, false)} fontSize={!clientAnswerSelected ? 60 : 35} />
                <img className={styles.logoWrapper} src={Logo} />
            </div>
            <div className={classNames(styles.indicatorTile, { [styles.selectedIndicatorTile]: clientAnswerSelected })} onClick={() => setSelectedTile(SmileyTileEnum.CLIENT_ANSWER)}>
                <SingleIndicator indicator={getIndicator(data, true)} fontSize={!clientAnswerSelected ? 35 : 60} />
            </div>
        </div>;
    }, [getIndicator]);

    return (
        <div className={styles.extensiveDataWrapper}>
            <Scrollable height={`${height}px`}>
                {extensiveData.map((data, i) => {
                    const { jurisdiction, scope, commissionedBy } = data;
                    const isFirstField = i === 0;
                    const clientAnswerSelected = selectedTile === SmileyTileEnum.CLIENT_ANSWER;
                    return (
                        <div className={styles.opinionWrapper} key={i}>
                            {!isFirstField && <div className={styles.subDivider} />}
                            {smileyIndicators(data, clientAnswerSelected)}
                            <div className={styles.jurisdiction}>{jurisdiction}</div>
                            <div className={styles.scope}>Scope: {scopeFormatter(scope)}</div>
                            <div className={styles.commissionedBy}>Commissioned By: {commissionedBy}</div>
                            <div className={styles.subDivider} />
                            <div className={styles.question}>{question?.label || ''}</div>
                            {getFieldContent(data)}
                        </div>
                    );
                })}
            </Scrollable>
        </div>
    );
};

interface SingleDataPointDetailsProps {
    height: number;
}

export const SingleDataPointDetails: React.FC<SingleDataPointDetailsProps> = ({ height }) => {
    const dispatch = useAppDispatch();

    const data = useAppSelector(getSingleDataPointMapAnalytics);
    const scope = useAppSelector(getOpinionAnalyticsScope);
    const singleField = useAppSelector(getOpinionSingleField);
    const inspectedJurisdiction = useAppSelector(getSelectedSingleFieldJurisdiction);
    const viewSignOffAnswers = useAppSelector(getViewSignOffAnswers);

    const extensiveData = useMemo(() => {
        if (inspectedJurisdiction === UK) {
            return data.filter(({ jurisdiction }) => ['England & Wales', 'Scotland'].includes(jurisdiction));
        }
        return data.filter(({ jurisdiction }) => jurisdiction === inspectedJurisdiction);
    }, [data, inspectedJurisdiction]);

    const singleFieldOptions = useMemo(() => getAvailableDropdownDetailsFieldsForScope(scope), [scope]);
    const singleFieldValue = useMemo(() => singleFieldOptions.find(({ value }) => value === `${singleField.sectionId}---${singleField.fieldId}`) || null, [singleFieldOptions, singleField]);

    const updateSelectedField = (option: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(option)) {
            const value = (option as DropdownOption).value;
            const [sectionId, fieldId] = value.split('---');
            dispatch(setOpinionSingleField({ sectionId, fieldId }));
        }
    };
    const QUESTION_HEIGHT = !viewSignOffAnswers ? 65 : 100;
    const DIVIDER_HEIGHT = 42;
    const extensiveDataHeight = useMemo(() => height - (QUESTION_HEIGHT + DIVIDER_HEIGHT), [height, QUESTION_HEIGHT]);

    const handleSetViewSignOffAnswers = useCallback((checked: boolean) => { dispatch(setViewSignOffAnswers(checked)); }, [dispatch]);

    return (
        <div className={styles.singleDataPointDetailsWrapper}>
            <div className={styles.dropdownWrapper}>
                <div className={styles.viewSignOffToggleWrapper}>
                    <div className={styles.toggleLabel}>Display my sign off answers</div>
                    <Toggle checked={viewSignOffAnswers} onChange={handleSetViewSignOffAnswers} />
                </div>
                <div className={styles.dropdownLabel}>Choose another data point</div>
                <div className={styles.dropdownContent}>
                    <Dropdown
                        testId='opinion-field-select'
                        onChange={updateSelectedField}
                        value={singleFieldValue}
                        options={singleFieldOptions}
                        controlBackgroundColor={lightestGrey}
                        isClearable={false}
                    />
                </div>
            </div>
            <div className={styles.divider} />
            {extensiveData.length > 0 &&
                <ExtensiveData extensiveData={extensiveData} question={singleFieldValue} height={extensiveDataHeight} />
            }
        </div>
    );
};
