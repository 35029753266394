import { isNull } from 'lodash';
import { isUndefined } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { Clause, FuzzyMatchTags, ClauseTagDropdownOptions, ClientTag } from '../../../clause-library/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ScrollableElement } from '../../../shared/scrollable/Scrollable';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { OpinionClause, OpinionInstance, OpinionInstanceState, OpinionInstanceType, SignOffConversation, SignOffOpinionInstance, SignOffOpinionInstanceContent, SignOffSmileyAndNote, TimelineOpinionInstance, UpdatedOpinionField } from './types';
import { checkAllPageRefsVerified, getUnverifiedSectionIds } from './utils';

const getRoot = (state: ApplicationState): OpinionInstanceState => state.opinion.instance;

export const getSavedInstance = (state: ApplicationState): OpinionInstance | null => getRoot(state).savedInstance;

export const getCurrentInstance = (state: ApplicationState): OpinionInstance | null => getRoot(state).currentInstance;

export const getCurrentInstanceType = (state: ApplicationState): OpinionInstanceType | undefined => getRoot(state).currentInstance?.type;

export const getInstanceIncludesUnverifiedPageRefs = (state: ApplicationState): boolean => {
    const currentInstance = getCurrentInstance(state);
    return isNull(currentInstance) || !checkAllPageRefsVerified(currentInstance.content);
};

export const getAllFieldsUpdated = (state: ApplicationState): UpdatedOpinionField[] => getCurrentInstance(state)?.fieldsUpdated || [];

export const getAdditionalProvisionsUpdated = (state: ApplicationState): boolean => getRoot(state).additionalProvisionsUpdated;

export const getSectionIdsUpdated = (state: ApplicationState): string[] => {
    const ADDITIONAL_PROVISIONS_SECTION_ID = 'additionalProvisions';
    const additionalProvisionsUpdated = getAdditionalProvisionsUpdated(state);
    let sectionIdsUpdated = getAllFieldsUpdated(state).map(({ sectionId }) => sectionId);
    if (additionalProvisionsUpdated && !sectionIdsUpdated.includes(ADDITIONAL_PROVISIONS_SECTION_ID)) {
        sectionIdsUpdated.push(ADDITIONAL_PROVISIONS_SECTION_ID);
    }
    return sectionIdsUpdated;
};

export const getSectionIdsWithUnverifiedPageRefs = (state: ApplicationState): string[] => {
    const currentInstance = getCurrentInstance(state);
    return !isNull(currentInstance) ? getUnverifiedSectionIds(currentInstance.content) : [];
};

export const getSectionUpdated = (sectionId: string) => (state: ApplicationState): boolean => getSectionIdsUpdated(state).includes(sectionId);

export const getSectionIncludesUnverifiedPageRefs = (sectionId: string) => (state: ApplicationState): boolean => getSectionIdsWithUnverifiedPageRefs(state).includes(sectionId);

export const getSavedSignOffNotes = (state: ApplicationState): SignOffOpinionInstance | null => getRoot(state).savedSignOff;

export const getCurrentSignOffNotes = (state: ApplicationState): SignOffOpinionInstance | null => getRoot(state).currentSignOff;

export const getSignOffNotesContent = (state: ApplicationState): SignOffSmileyAndNote => getRoot(state).signOffNotesContent;

export const getFieldSignOffNotes = (section: string, field?: string) => (state: ApplicationState): SignOffConversation | undefined => {
    if (['counterpartyCoverage', 'transactionCoverage', 'agreementCoverage', 'additionalProvisions', 'annexCoverage'].includes(section)) {
        const notes = getCurrentSignOffNotes(state)?.content[section as keyof SignOffOpinionInstanceContent];
        if (!isUndefined(notes)) {
            return notes as SignOffConversation;
        }
        return notes;
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return (getCurrentSignOffNotes(state)?.content[section as keyof SignOffOpinionInstanceContent] as any)[field!];
};

export const getSavedFieldSignOffNotes = (section: string, field?: string) => (state: ApplicationState): SignOffConversation | undefined => {
    if (['counterpartyCoverage', 'transactionCoverage', 'agreementCoverage', 'additionalProvisions', 'annexCoverage'].includes(section)) {
        const notes = getSavedSignOffNotes(state)?.content[section as keyof SignOffOpinionInstanceContent];
        if (!isUndefined(notes)) {
            return notes as SignOffConversation;
        }
        return notes;
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return (getSavedSignOffNotes(state)?.content[section as keyof SignOffOpinionInstanceContent] as any)[field!];
};

export const getIsEditingSignOff = (state: ApplicationState): boolean => getRoot(state).isEditingSignOff;

export const getIsEditingNote = (state: ApplicationState): boolean => !isNull(getRoot(state).indexOfUpdatedNote);

export const getSignOffInstanceUpdated = (state: ApplicationState): boolean => getRoot(state).signOffInstanceUpdated;

export const getSignOffNotesModalOpen = (state: ApplicationState): boolean => getRoot(state).signOffNotesModalOpen;

export const getSectionOrFieldId = (state: ApplicationState): string => getRoot(state).sectionOrFieldId;

export const getSignOffModalOpen = (state: ApplicationState): boolean => getRoot(state).signOffModalOpen;

export const getOpinionInstanceTimeline = (state: ApplicationState): TimelineOpinionInstance[] => getRoot(state).timeline;

export const getIsFirstTimelineEntry = (state: ApplicationState): boolean => {
    const timeline = getOpinionInstanceTimeline(state);
    const savedOpinion = getSavedInstance(state);
    return !isNull(savedOpinion) && timeline[0].opinionInstanceId === savedOpinion.opinionInstanceId;
};

export const getOpenFieldsAndSections = (state: ApplicationState): string[] => getRoot(state).openFieldsAndSections;

export const getIsEditing = (state: ApplicationState): boolean => getRoot(state).isEditing;

export const getIsUpdating = (state: ApplicationState): boolean => getRoot(state).isUpdating;

export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getInstanceUpdated = (state: ApplicationState): boolean => getRoot(state).instanceUpdated;

export const getUpdatedInstanceModalOpen = (state: ApplicationState): boolean => getRoot(state).updatedInstanceModalOpen;

export const getConfirmSaveModalOpen = (state: ApplicationState): boolean => getRoot(state).confirmSaveModalOpen;

export const getLinkedOpinionModalOpen = (state: ApplicationState): boolean => getRoot(state).linkedOpinionModalOpen;

export const getScrollableWrapper = (state: ApplicationState): ScrollableElement | null => getRoot(state).scrollableWrapper;

export const getSupportOpinionUploadModalOpen = (state: ApplicationState): boolean => getRoot(state).supportOpinionUploadModalOpen;

export const getSubCounterpartyTypeModalOpen = (state: ApplicationState): boolean => getRoot(state).subCounterpartyTypeModalOpen;

export const getSelectedSubCounterpartyParent = (state: ApplicationState): string => getRoot(state).parentCounterparty;

// Opinion Clauses

export const getAllOpinionClauses = (state: ApplicationState): OpinionClause[] => getRoot(state).opinionClauses;

export const getAllOpinionClauseIds = (state: ApplicationState): number[] => getRoot(state).allOpinionClauseIds;

export const getOpinionClauseTableIsLoading = (state: ApplicationState): boolean => getRoot(state).clauseTableLoading;

export const getOpinionClauseModalOpen = (state: ApplicationState): boolean => getRoot(state).clauseModalOpen;

export const getOpinionClause = (state: ApplicationState): Clause => getRoot(state).clause;

export const getOpinionClauseDescriptionOpen = (state: ApplicationState): boolean => getRoot(state).clauseDescriptionOpen;

export const getCurrentOpinionClause = (state: ApplicationState): Clause | null => getRoot(state).currentClause;

export const getOpinionClauseTagTerm = (state: ApplicationState): string => getRoot(state).tagTerm;

export const getOpinionFuzzyMatchResults = (state: ApplicationState): FuzzyMatchTags[] => getRoot(state).fuzzyMatchResults;

export const getOpinionFuzzyMatchModalOpen = (state: ApplicationState): boolean => getRoot(state).fuzzyMatchModalOpen;

export const getOpinionClauseLibraryDropdownOptions = (state: ApplicationState): ClauseTagDropdownOptions => getRoot(state).clauseLibraryDropdownOptions;

export const getAllOpinionClauseLibraryTags = (state: ApplicationState): string[] => {
    const { agreementTypeOptions, jurisdictionOptions, counterpartyTypeOptions, productTypeOptions, provisionTypeOptions, miscellaneousOptions, opinionsOptions } = getOpinionClauseLibraryDropdownOptions(state);
    const getOptionValues = (options: DropdownOption[]) => options.map(({ value }) => value.toLowerCase());
    return [...getOptionValues(agreementTypeOptions), ...getOptionValues(jurisdictionOptions), ...getOptionValues(counterpartyTypeOptions), ...getOptionValues(productTypeOptions), ...getOptionValues(provisionTypeOptions), ...getOptionValues(miscellaneousOptions), ...getOptionValues(opinionsOptions)];
};

export const getAllOpinionClientTags = (state: ApplicationState): ClientTag[] => getRoot(state).allClientTags;

export const getOpinionClauseCanSave = (state: ApplicationState): boolean => getRoot(state).clauseCanSave;

export const getExistingClausesModalOpen = (state: ApplicationState): boolean => getRoot(state).linkOpinionClauseModalOpen;

export const getAgreementCoverageSearchTerm = (state: ApplicationState): string => getRoot(state).agreementCoverageSearchTerm;

export const getCurrentInstanceIsSignedOff = (state: ApplicationState): boolean => {
    const savedSignOff = getSavedSignOffNotes(state);
    return savedSignOff ? !isNull(savedSignOff.approvedBy) : false;
};

// Clause Table Filters and Pagination
export const getOpinionClausesPageNumber = (state: ApplicationState): number => getRoot(state).opinionClausePage;
export const getTotalOpinionClauses = (state: ApplicationState): number => getRoot(state).totalOpinionClauses;
export const getOpinionClauseFilters = (state: ApplicationState): TableFilters => getRoot(state).opinionClauseFilters;
export const getOpinionClauseColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).opinionClauseSort;
