import { RawDraftContentState } from 'draft-js';
import { Event } from 'react-big-calendar';

import { UserAnswerId } from '../../attestations/store';

export enum WorkflowActionTypes {
    SET_WORKFLOW_PAGE = 'SET_WORKFLOW_PAGE',
    TOGGLE_SCHEDULE_WIZARD = 'TOGGLE_SCHEDULE_WIZARD',
    SET_EVENT_VALUE = 'SET_EVENT_VALUE',
    SET_TOOLBAR_DATE = 'SET_TOOLBAR_DATE',
    SET_SELECTED_MONTH_YEAR = 'SET_SELECTED_MONTH_YEAR',
    RESET_CALENDAR = 'RESET_CALENDAR',
    SET_SELECTED_EVENT_TAB = 'SET_SELECTED_EVENT_TAB',
    SET_SELECTED_EVENT_TAB_VALID = 'SET_SELECTED_EVENT_TAB_VALID',
    UPDATE_CONTENT_ATTESTATION_ID = 'UPDATE_CONTENT_ATTESTATION_ID',
    UPDATE_CONTENT_ATTESTATION_INSTANCES = 'UPDATE_CONTENT_ATTESTATION_INSTANCES',
    RUN_INDIRECT_RULES_STARTED = 'RUN_INDIRECT_RULES_STARTED',
    RUN_INDIRECT_RULES_SUCCESSFUL = 'RUN_INDIRECT_RULES_SUCCESSFUL',
    RUN_INDIRECT_RULES_FAILED = 'RUN_INDIRECT_RULES_FAILED',
    SET_SCHEDULED_EMAIL_CONTENT = 'SET_SCHEDULED_EMAIL_CONTENT',
    CONFIRM_SCHEDULED_DETAILS = 'CONFIRM_SCHEDULED_DETAILS',
    SAVE_SCHEDULED_ACTION_STARTED = 'SAVE_SCHEDULED_ACTION_STARTED',
    SAVE_SCHEDULED_ACTION_SUCCESSFUL = 'SAVE_SCHEDULED_ACTION_SUCCESSFUL',
    SAVE_SCHEDULED_ACTION_FAILED = 'SAVE_SCHEDULED_ACTION_FAILED',
    FETCH_CALENDAR_EVENTS_STARTED = 'FETCH_CALENDAR_EVENTS_STARTED',
    FETCH_CALENDAR_EVENTS_SUCCESSFUL = 'FETCH_CALENDAR_EVENTS_SUCCESSFUL',
    FETCH_CALENDAR_EVENTS_FAILED = 'FETCH_CALENDAR_EVENTS_FAILED',
    FETCH_ALL_CALENDAR_EVENTS_STARTED = 'FETCH_ALL_CALENDAR_EVENTS_STARTED',
    FETCH_ALL_CALENDAR_EVENTS_SUCCESSFUL = 'FETCH_ALL_CALENDAR_EVENTS_SUCCESSFUL',
    FETCH_ALL_CALENDAR_EVENTS_FAILED = 'FETCH_ALL_CALENDAR_EVENTS_FAILED',
    SET_SCHEDULED_ACTION_EDITING = 'SET_SCHEDULED_ACTION_EDITING',
    SHOW_SCHEDULE_UPDATED_MESSAGE = 'SHOW_SCHEDULE_UPDATED_MESSAGE',
    DELETE_SCHEDULED_ACTION_STARTED = 'DELETE_SCHEDULED_ACTION_STARTED',
    DELETE_SCHEDULED_ACTION_SUCCESSFUL = 'DELETE_SCHEDULED_ACTION_SUCCESSFUL',
    DELETE_SCHEDULED_ACTION_FAILED = 'DELETE_SCHEDULED_ACTION_FAILED',
    TOGGLE_DELETE_EVENT_CONFIRMATION_MODAL = 'TOGGLE_DELETE_EVENT_CONFIRMATION_MODAL',
    TOGGLE_SCHEDULED_ACTIONS_LIST_VIEW = 'TOGGLE_SCHEDULED_ACTIONS_LIST_VIEW',
    SKIP_SCHEDULED_ACTION_STARTED = 'SKIP_SCHEDULED_ACTION_STARTED',
    SKIP_SCHEDULED_ACTION_SUCCESSFUL = 'SKIP_SCHEDULED_ACTION_SUCCESSFUL',
    SKIP_SCHEDULED_ACTION_FAILED = 'SKIP_SCHEDULED_ACTION_FAILED',
    SET_SELECTED_EVENT_DATE = 'SET_SELECTED_EVENT_DATE',
    RECOVER_SCHEDULED_ACTION_STARTED = 'RECOVER_SCHEDULED_ACTION_STARTED',
    RECOVER_SCHEDULED_ACTION_SUCCESSFUL = 'RECOVER_SCHEDULED_ACTION_SUCCESSFUL',
    RECOVER_SCHEDULED_ACTION_FAILED = 'RECOVER_SCHEDULED_ACTION_FAILED'
}

export enum WorkflowPage {
    SELECT = 'select',
    LIST = 'list',
    CALENDAR = 'calendar'
}

export enum SchedulerInterval {
    DAYS = 'days',
    WEEKS = 'weeks',
    MONTHS = 'months'
}

export enum SchedulerAction {
    CREATE_ATTESTATION = 'create attestation',
    SEND_EMAIL = 'send email',
}

export enum ScheduleEventTab {
    SCHEDULE = 'Schedule',
    CONTENT = 'Content',
    CONFIRM = 'Confirm'
}

export enum ScheduledActionsListView {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
    COMPLETED = 'completed'
}

export interface BaseScheduler {
    scheduledActionId?: number;
    firstDate: string | null;
    nextDate: string | null;
    repeat: number;
    hasCompleted: number;
    isArchived: number;
    interval: SchedulerInterval | null;
    intervalValue: number | null;
    action: SchedulerAction | null;
    content: SendEmailContent | CreateAttestationContent | null;
    isSkipped?: boolean;
}

export interface BaseSchedulerActionDB extends BaseScheduler {
    firstDate: string;
    action: SchedulerAction;
    content: SendEmailContent | CreateAttestationContent;
    hasCompleted: number;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    isArchived: number;
    clientId: number;
}

export interface SendEmailContent {
    userIds: number[] | null;
    subject: string;
    wysiwygTemplate: RawDraftContentState | null;
}

export interface SendEmailScheduler extends BaseScheduler {
    action: SchedulerAction.SEND_EMAIL;
    content: SendEmailContent;
}

export interface SendEmailSchedulerDB extends BaseSchedulerActionDB {
    action: SchedulerAction.SEND_EMAIL;
    content: SendEmailContent;
}

export interface AttestationInstanceUsers extends UserAnswerId {
    username?: string;
}
export interface AttestationInstanceScheduler {
    userIds: AttestationInstanceUsers[];
    deadlineInterval: SchedulerInterval | null;
    deadlineIntervalValue: number | null;
}

export interface CreateAttestationContent {
    attestationFormId: number | null;
    instancesToCreate: AttestationInstanceScheduler[];
}

export interface CreateAttestationScheduler extends BaseScheduler {
    action: SchedulerAction.CREATE_ATTESTATION;
    content: CreateAttestationContent;
}

export interface CreateAttestationSchedulerDB extends BaseSchedulerActionDB {
    action: SchedulerAction.CREATE_ATTESTATION;
    content: CreateAttestationContent;
}

export type Scheduler = SendEmailScheduler | CreateAttestationScheduler | BaseScheduler;

export type SchedulerDB = SendEmailSchedulerDB | CreateAttestationSchedulerDB;

export interface ScheduledEventsByMonth {
    [day: string]: SchedulerDB[];
}

export interface CalendarEmailEvent extends Event, SendEmailSchedulerDB {}
export interface CalendarAttestationEvent extends Event, CreateAttestationSchedulerDB {}
export type CalendarEvent = CalendarEmailEvent | CalendarAttestationEvent;

export interface WorkflowState {
    workflowPage: WorkflowPage;
    calendarWizardOpen: boolean;
    selectedEventTab: ScheduleEventTab;
    selectedTabValid: boolean;
    event: Scheduler;
    toolbarDate: Date;
    selectedMonthYear: string;
    scheduleDetailsCorrect: boolean;
    isSaving: boolean;
    scheduledActions: SchedulerDB[];
    selectedMonthActions: CalendarEvent[];
    isEditing: boolean;
    showUpdatedMessage: boolean;
    confirmDeleteEvent: number | null;
    isDeleting: boolean;
    scheduledActionsListView: ScheduledActionsListView;
    isLoading: boolean;
    selectedEventDate: string | null;
}
