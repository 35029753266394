import React, { useMemo, useCallback, useState } from 'react';
import { isUndefined, max, isNull, min, noop } from 'lodash/fp';
import classnames from 'classnames';

import Logo from '../../../assets/logos/Ark51_VERTBLACK&BLACK.png';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Spinner } from '../spinner/Spinner';
import { Action } from '../modal/ActionModal';
import { ActionCell } from './ActionCell';
import { AnalysisCell } from './AnalysisCell';
import { DeadlineCell } from './DeadlineCell';
import { TooltipCell } from './TooltipCell';
import styles from './Table.module.scss';
import { IconButton } from '../button/IconButton';
import { Filter, SaveFile, Sort, SortAscending, SortDescending } from '../icons';
import { InformationTooltip, OverflowTooltip } from '../tooltip';
import { Position } from '../modal/PositionModal';
import { FilterDropdownOptions, TableFilterModal, TableFilterType, TableFilters } from '../modal/TableFilterModal';
import { SelectCell } from './SelectCell';
import { CheckboxCell } from './CheckboxCell';
import { IconCell } from './IconCell';
import { Scrollable } from '../scrollable/Scrollable';
import { TooltipListCell } from './TooltipListCell';
import { TickCell } from './TickCell';
import { AddressCell } from './AddressCell';
import { FlagCell } from './FlagCell';
import { TooltipListLabelCell } from './TooltipListLabelCell';
import { ColourCell } from './ColourCell';
import { IconTooltipCell } from './IconTooltipCell';
import { TableCell } from './TableCell';
import { LinkCell } from './LinkCell';
import { EntityCell } from './EntityCell';
import { DateCell, DateCellColourBoundaries } from './DateCell';
import { CustomFiltersSaveModal } from './CustomFiltersSaveModal';
import { Icon } from '../icon/Icon';
import { OtherNamesCell } from './OtherNamesCell';

const { grey, white, fadedWhite } = styles;

/* eslint-disable @typescript-eslint/no-explicit-any */

const EmptyTable: React.FC<{ height: number; }> = ({ height }) => (
    <div className={styles.emptyPlaceholder} style={{ height: `${height}px` }}>
        <img className={styles.logoWrapper} src={Logo} />
    </div>
);

export interface ArkTableComponents {
    tooltip: React.FC;
    iconTooltip: React.FC;
    tooltipList: React.FC;
    tooltipListLabel: React.FC;
    deadline: React.FC;
    action: React.FC;
    analysis: React.FC;
    select: React.FC;
    checkbox: React.FC;
    icon: React.FC;
    tick: React.FC;
    address: React.FC;
    flag: React.FC;
    colour: React.FC;
    table: React.FC;
    redirect: React.FC;
    entity: React.FC;
    disabled: React.FC;
    colorDate: React.FC;
    otherNames: React.FC;
}

export interface ArkTableColumn {
    id: string;
    field: string;
    description?: string;
    header: string;
    component?: keyof ArkTableComponents;
    secondaryComponent?: keyof ArkTableComponents;
    actions?: (data: any) => Action[];
    icon?: React.FC;
    valueFormatter?: (value: any, row: any) => string | string[] | boolean | null;
    selectedIds?: number[] | string[] | null;
    selected?: number[];
    select?: (selectedId: number | string) => void;
    selectDisabled?: (selectedId: number) => boolean;
    canFilter?: boolean;
    canSort?: boolean;
    width: number | string;
    parentField?: string;
    redirect?: (row: any) => void;
    disabled?: (row: any) => boolean;
    isLoading?: (row: any) => boolean;
    colours?: DateCellColourBoundaries;
}

export interface ArkRow {
    [property: string]: any;
}

export enum SortOrder {
    ASCENDING = 'ASC',
    DESCENDING = 'DESC'
}

export interface ColumnSort {
    field: string;
    order: SortOrder;
}

export interface SelectedArkRow {
    field: string;
    fieldValue: any;
}

interface ArkTableProps {
    rowData: ArkRow[];
    colDefs: ArkTableColumn[];
    width?: number;
    height?: number;
    testId?: string;
    isLoading?: boolean;
    page: number;
    total: number;
    next: () => void;
    previous: () => void;
    filters: TableFilters;
    filterDropdownOptions?: FilterDropdownOptions;
    updateFilter: (field: string, value: string | string[] | null, type?: keyof TableFilterType) => void;
    clearAllFilters: () => void;
    onRowDoubleClicked?: (rowData: ArkRow) => void;
    columnSort?: ColumnSort;
    toggleSort?: (columnSort: ColumnSort) => void;
    pageSize: number;
    updatePageSize: (pageSize: number) => void;
    onRowClick?: (rowData: ArkRow) => void;
    removeTextFilter?: string[];
    showPageSize?: boolean;
    selectedRow?: SelectedArkRow;
    saveCustomFilters?: (label: string, createNew: boolean) => void;
    saveButtonIsVisible?: boolean;
    customFilterName?: string;
    isExistingCustomFilter?: boolean;
}

export const ArkTable: React.FC<ArkTableProps> = ({
    rowData,
    colDefs,
    width,
    height,
    testId = 'ark-table',
    isLoading = false,
    page,
    total,
    next,
    previous,
    filters,
    filterDropdownOptions = {},
    updateFilter,
    clearAllFilters,
    onRowDoubleClicked = noop,
    columnSort,
    toggleSort = noop,
    pageSize,
    updatePageSize,
    onRowClick = noop,
    removeTextFilter,
    showPageSize = true,
    selectedRow,
    saveCustomFilters = noop,
    saveButtonIsVisible = false,
    customFilterName = '',
    isExistingCustomFilter = false
}) => {
    const [filterFieldOpen, setFilterFieldOpen] = useState<string | null>(null);
    const [position, setPosition] = useState<Position | null>(null);
    const [screenWidth, screenHeight] = useWindowResize();
    const [customFilterSaveModal, setCustomFilterSaveModal] = useState<boolean>(false);

    const tableWidth = useMemo(() => (width ? width : screenWidth * 0.85) - 2, [screenWidth, width]);
    const tableHeight = useMemo(() => (height ? height : screenHeight * 0.75) - 2, [screenHeight, height]);
    const tableBodyHeight = useMemo(() => tableHeight - 82, [tableHeight]);

    const saveCustomFiltersAndClose = useCallback((label: string, createNew: boolean) => {
        saveCustomFilters(label, createNew);
        setCustomFilterSaveModal(false);
    }, [saveCustomFilters]);

    const getWidth = useCallback((multiplier: number | string) => typeof multiplier === 'string' ? multiplier : `${(tableWidth * multiplier) - 16}px`, [tableWidth]);

    const columnDefs: ArkTableColumn[] = useMemo(() => colDefs.map(colDef => ({ ...colDef, width: getWidth(colDef.width || 1 / colDefs.length) })), [getWidth, colDefs]);

    const getRowHeight = useCallback((row: ArkRow) => {
        const parentFieldId = columnDefs.find(({ secondaryComponent }) => !isUndefined(secondaryComponent))?.parentField;
        const heightAdjustment = parentFieldId && row[parentFieldId].length || 1;
        const singleRowHeight = max([tableBodyHeight / 20, 30])! - 1;
        return singleRowHeight * heightAdjustment;
    }, [tableBodyHeight, columnDefs]);

    const getCellValue = useCallback((row: ArkRow, field: string) => {
        const cellValue = row[field];
        return isUndefined(cellValue) ? null : cellValue;
    }, []);

    const getRowIsSelected = useCallback((row: ArkRow) => !isUndefined(selectedRow) && row[selectedRow.field] === selectedRow.fieldValue, [selectedRow]);

    const getCell = useCallback((
        row: ArkRow,
        field: string,
        index: number,
        parentField?: string,
        valueFormatter?: (value: any, row: any) => string | string[] | null | boolean,
        component?: keyof ArkTableComponents,
        secondaryComponent?: keyof ArkTableComponents,
        actions?: (data: ArkRow) => Action[],
        selectedIds?: number[] | string[] | null,
        select?: (selectedId: number | string) => void,
        selected?: number[], icon?: React.FC,
        selectDisabled?: (id: number) => boolean,
        redirect?: (link: string) => void,
        isDisabled?: (data: ArkRow) => boolean,
        colours?: DateCellColourBoundaries,
        isLoading?: (data: ArkRow) => boolean
    ) => {
        const value = getCellValue(row, field);
        const valueFormatted = !isUndefined(valueFormatter) ? valueFormatter(value, row) : null;
        const disabled = !isUndefined(isDisabled) ? isDisabled(row) : false;
        const showIsLoading = !isUndefined(isLoading) ? isLoading(row) : false;

        if (!component) {
            return <div className={styles.tableTextCell}>{valueFormatted || value}</div>;
        }

        switch (component) {
            case 'action':
                return <ActionCell actions={actions!} data={row} testId={`${testId}-row-${index}`} />;
            case 'analysis':
                return <AnalysisCell data={row} />;
            case 'otherNames':
                return <OtherNamesCell value={value} />;
            case 'tick':
                return <TickCell value={value} valueFormatted={valueFormatted} />;
            case 'deadline':
                return <DeadlineCell value={value} valueFormatted={valueFormatted as string | undefined | null} />;
            case 'tooltipList':
                return <TooltipListCell value={value} valueFormatted={valueFormatted as string[]} icon={icon} />;
            case 'tooltipListLabel':
                return <TooltipListLabelCell value={value} valueFormatted={valueFormatted as string[]} testId={`${testId}-row-${index}-${field}`} />;
            case 'address':
                return <AddressCell value={value} valueFormatted={valueFormatted as string[]} />;
            case 'tooltip':
                return <TooltipCell value={value} valueFormatted={valueFormatted as string} testId={`${testId}-row-${index}-${field}`} />;
            case 'iconTooltip':
                return <IconTooltipCell value={value} valueFormatted={valueFormatted as string} icon={icon} testId={`${testId}-row-${index}-${field}`} />;
            case 'select':
                return <SelectCell value={value} select={select!} selectedIds={selectedIds!} selectDisabled={selectDisabled} isLoading={showIsLoading} />;
            case 'checkbox':
                return <CheckboxCell value={value} select={select!} selected={selected!} disabled={disabled} />;
            case 'icon':
                return <IconCell value={!!value} testId='icon-cell' icon={icon} />;
            case 'flag':
                return <FlagCell value={value} />;
            case 'entity':
                return <EntityCell value={value} />;
            case 'colour':
                return <ColourCell value={value} valueFormatted={valueFormatted as string[]} />;
            case 'table':
                return <TableCell row={row} parentField={parentField!} field={field} secondaryComponent={secondaryComponent} valueFormatter={valueFormatter} />;
            case 'redirect':
                return <LinkCell data={row} value={value as string} redirect={redirect!} testId={`${testId}-row-${index}`} />;
            case 'colorDate':
                return <DateCell value={value} valueFormatted={valueFormatted as string | undefined | null} colours={colours} />;
            default:
                return <div className={styles.tableTextCell}>UNKNOWN</div>;
        }
    }, [getCellValue, testId]);

    const tableBody = useMemo(() => {
        if (isLoading) {
            return <Spinner />;
        }

        if (!rowData.length) {
            return <EmptyTable height={tableBodyHeight} />;
        }
        return rowData.map((row, index) => (
            <div className={classnames(styles.tableRowWrapper, { [styles.rowBorder]: index !== rowData.length - 1 || rowData.length < 20, [styles.isSelected]: getRowIsSelected(row) })} key={index} style={{ height: getRowHeight(row), backgroundColor: index % 2 ? grey : white }} onDoubleClick={() => onRowDoubleClicked(row)} onClick={() => onRowClick(row)} data-testid={`${testId}-table-row-${index}`}>
                {columnDefs.map(({ width, component, secondaryComponent, actions, field, parentField, valueFormatter, id, select, selectedIds, selected, icon, selectDisabled, redirect, disabled, isLoading, colours }) => (
                    <div className={styles.tableRowCell} style={{ width }} key={`${index}-${id}`}>{getCell(row, field, index, parentField, valueFormatter, component, secondaryComponent, actions, selectedIds, select, selected, icon, selectDisabled, redirect, disabled, colours, isLoading)}</div>
                ))}
            </div>
        ));
    }, [rowData, isLoading, getCell, columnDefs, getRowHeight, onRowDoubleClicked, onRowClick, testId, getRowIsSelected, tableBodyHeight]);

    const pageNumber = useMemo(() => !rowData.length ? 0 : (page - 1) * pageSize + 1, [rowData, page, pageSize]);
    const pageInfo = useMemo(() => `${pageNumber} to ${min([page * pageSize, total]) || 0} of ${total || 0}`, [page, total, pageSize, pageNumber]);
    const pageCount = useMemo(() => `Page ${page} of ${Math.ceil(total / pageSize) || 1}`, [page, total, pageSize]);

    const openFilterModal = (x: number, y: number, field: string) => {
        setPosition({ x, y });
        setFilterFieldOpen(field);
    };

    const closeFilterModal = () => {
        setPosition(null);
        setFilterFieldOpen(null);
    };

    const filtersExist = useMemo(() => !!Object.values(filters).filter(({ text, dropdown }) => !isNull(dropdown) || !!text).length, [filters]);

    const columnFilterExists = useCallback((field: string) => !!filters[field] && (!isNull(filters[field].dropdown) || !!filters[field].text), [filters]);

    const sortColumn = useCallback((sortField: string) => {
        let orderToSort = SortOrder.ASCENDING;
        if (columnSort) {
            const { field, order } = columnSort;
            orderToSort = sortField === field && order === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING;
        }
        toggleSort({ field: sortField, order: orderToSort });
    }, [columnSort, toggleSort]);

    const getSortIcon = useCallback((field: string) => {
        if (columnSort && columnSort.field === field) {
            return columnSort.order === SortOrder.ASCENDING ? SortDescending : SortAscending;
        }
        return Sort;
    }, [columnSort]);

    const pageSizeOptions = [20, 50, 100];

    const textFilterVisible = useMemo(() => !filterFieldOpen || isUndefined(removeTextFilter) || !removeTextFilter.includes(filterFieldOpen), [filterFieldOpen, removeTextFilter]);
    const nextPageDisabled = useMemo(() => !rowData.length ? true : page === Math.ceil(total / pageSize), [page, pageSize, total, rowData]);

    const customFilterHeaders = useMemo(() => columnDefs.filter(({ canFilter }) => canFilter).map(({ header, field }) => ({ header, field })), [columnDefs]);

    return (
        <div className={styles.arkTableWrapper} style={{ height: `${tableHeight}px`, width: `${tableWidth}px` }} data-testid={`${testId}-table-wrapper`}>
            <div className={styles.tableHeaderWrapper}>
                {columnDefs.map(({ width, header, id, canFilter, field, canSort, description }, index) => {
                    const showBorderRight = index !== columnDefs.length - 1;
                    const style = { width, borderRight: showBorderRight ? `1px solid ${fadedWhite}` : undefined };
                    const sortIcon = getSortIcon(field);
                    const filterApplied = columnFilterExists(field);
                    return (
                        <div className={styles.tableHeaderCell} style={style} key={id}>
                            <OverflowTooltip className={styles.tableHeaderName} overlayText={header} testId={`${testId}-table-${id}`} />
                            <div className={styles.tableHeaderIcons}>
                                {description && <InformationTooltip content={description} labelColor={white} />}
                                {canFilter && <IconButton icon={Filter} onClick={e => openFilterModal(e.clientX, e.clientY, field)} color={white} fontSize={20} testId={`${testId}-${id}-filter`} margin={canSort ? '0 5px 0 0' : '0'} showIndicator={filterApplied} />}
                                {canSort && <IconButton icon={sortIcon} onClick={() => sortColumn(field)} color={white} fontSize={20} testId={`${testId}-${id}-sort`} />}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={styles.tableBodyWrapper}>
                <Scrollable>
                    {tableBody}
                </Scrollable>
            </div>
            <div className={styles.tableFooter}>
                <div className={styles.leftTableFooter}>
                    {showPageSize &&
                        <div className={styles.pageSizeWrapper} data-testid={`${testId}-table-page-size-wrapper`}>
                            <div className={styles.pageSizeLabel} data-testid={`${testId}-table-page-size-label`}>Page Size:</div>
                            <div className={styles.pageSizeOptions}>
                                {pageSizeOptions.map(value => (
                                    <button
                                        className={classnames(styles.pageSizeButton, { [styles.selectedPageSizeButton]: value === pageSize })}
                                        key={value}
                                        onClick={() => (value !== pageSize) && updatePageSize(value)}
                                        data-testid={`${testId}-table-page-size-button-${value}`}
                                    >
                                        {value}
                                    </button>
                                ))}
                            </div>
                        </div>
                    }
                    {filtersExist &&
                        <div className={styles.clearAllFiltersWrapper}>
                            <button className={styles.clearAllFilters} onClick={clearAllFilters} data-testid={`${testId}-table-clear-filters-button`}>Clear All Filters</button>
                            {saveButtonIsVisible && <button className={styles.saveCustomFilters} onClick={() => setCustomFilterSaveModal(true)} data-testid={`${testId}-table-save-custom-filters-button`}>
                                <Icon icon={SaveFile} />
                                Save Custom Filters
                            </button>}
                        </div>
                    }
                </div>
                <div className={styles.rightTableFooter}>
                    <div className={styles.pageInfo} data-testid={`${testId}-table-page-info-wrapper`}>{pageInfo}</div>
                    <div className={styles.paginationWrapper} data-testid={`${testId}-table-pagination-wrapper`}>
                        <button className={styles.paginationButton} onClick={previous} disabled={page === 1} data-testid={`${testId}-table-pagination-button-left`}>{'<'}</button>
                        <div className={styles.pageCount} data-testid={`${testId}-table-pagination-page-count-label`}>{pageCount}</div>
                        <button className={styles.paginationButton} onClick={next} disabled={nextPageDisabled} data-testid={`${testId}-table-pagination-button-right`}>{'>'}</button>
                    </div>
                </div>
            </div>
            {!isNull(filterFieldOpen) && !isNull(position) &&
                <TableFilterModal
                    position={position}
                    field={filterFieldOpen}
                    closeModal={closeFilterModal}
                    testId={`${testId}-filter-${filterFieldOpen}`}
                    filters={filters}
                    updateFilter={updateFilter}
                    filterDropdownOptions={filterDropdownOptions}
                    textFilterVisible={textFilterVisible}
                />
            }
            <CustomFiltersSaveModal
                isOpen={customFilterSaveModal}
                closeModal={() => setCustomFilterSaveModal(false)}
                isExistingCustomFilter={isExistingCustomFilter}
                label={customFilterName}
                save={saveCustomFiltersAndClose}
                filters={filters}
                filterDropdownOptions={filterDropdownOptions}
                removeTextFilter={removeTextFilter}
                customFilterHeaders={customFilterHeaders}
            />
        </div>
    );
};
