import React, { useMemo } from 'react';
import Modal from 'react-modal';

import { Button } from '../button/Button';
import { Document } from '../icons';
import { ModalHeader } from './ModalHeader';
import { Spinner } from '../spinner/Spinner';
import { DocumentPreviewViewer } from '../../documents/document-viewer/DocumentPreviewViewer';
import styles from './Modal.module.scss';
import { Scrollable } from '../scrollable/Scrollable';

interface IncompleteDetailsModalProps {
    isOpen: boolean;
    testId?: string;
    url: string | null;
    closeModal: () => void;
    completeUpload: () => void;
    updateAndSkip: () => void;
    completeDisabledTooltip: string[];
    updateAndSkipDisabledTooltip: string[];
    updateAndSkipLabel: string;
    completeDisabled: boolean;
    updateAndSkipDisabled: boolean;
    mimeType: string | null;
    isOpeningDocument: boolean;
    label: string;
    children: JSX.Element;
    showDocumentPreview?: boolean;
    toggleDocumentPreview: () => void;
    selectedPage?: number | null;
    setSelectedPage?: (page: number | null) => void;
    isPreExecutionDraft?: boolean;
}

export const IncompleteDetailsModal: React.FC<IncompleteDetailsModalProps> = ({
    isOpen,
    url,
    testId,
    closeModal,
    completeUpload,
    updateAndSkip,
    completeDisabledTooltip,
    updateAndSkipDisabledTooltip,
    updateAndSkipDisabled,
    updateAndSkipLabel,
    completeDisabled,
    mimeType,
    isOpeningDocument,
    label,
    children,
    showDocumentPreview = true,
    toggleDocumentPreview,
    selectedPage,
    setSelectedPage,
    isPreExecutionDraft
}) => {
    const documentDetailsStyle = useMemo(() => showDocumentPreview ? 'calc(40% - 10px)' : 'calc(100% - 10px)', [showDocumentPreview]);
    const toggleDocumentDetailsLabel = useMemo(() => `${showDocumentPreview ? 'Hide' : 'Show'} Document`, [showDocumentPreview]);
    return (
        <Modal
            isOpen={isOpen}
            className={styles.incompleteDocumentsModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 4 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <ModalHeader testId={`${testId}-modal`} label={label} icon={Document} />
            <div className={styles.documentDetailsModalWrapper}>
                {showDocumentPreview && <div className={styles.documentPreviewWrapper}>
                    {isOpeningDocument ? <Spinner size={70} /> : (
                        <DocumentPreviewViewer url={url} mimeType={mimeType} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                    )}
                </div>}
                <div className={styles.documentDetailsWrapper} style={{ width: documentDetailsStyle }}>
                    <Scrollable>
                        {children}
                    </Scrollable>
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <Button onClick={toggleDocumentPreview} label={toggleDocumentDetailsLabel} testId={`${testId}-toggle-document`} />
                <div className={styles.buttonGroupRight}>
                    <Button onClick={closeModal} label='Close' testId={`${testId}-close-upload`} />
                    {!isPreExecutionDraft && <Button onClick={updateAndSkip} label={updateAndSkipLabel} testId={`${testId}-skip-upload`} disabled={updateAndSkipDisabled} disabledTooltip={updateAndSkipDisabledTooltip} />}
                    <Button onClick={completeUpload} label='Complete' disabled={completeDisabled} testId={`${testId}-complete-upload`} disabledTooltip={completeDisabledTooltip} />
                </div>
            </div>
        </Modal>
    );
};
