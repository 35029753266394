import React from 'react';

export const Search: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color, fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <ellipse fill={color || 'currentColor'} cx="74.7" cy="52.6" rx="37.8" ry="36.9" />
        <ellipse fill={fill || 'white'} cx="74.7" cy="52.7" rx="32" ry="31.2" />
        <path fill={color || 'currentColor'} d="M17.7,95c2.6-3.5,13.7-13,13.7-13l13.7-13l7,7.4l7,7.4l-13.7,13c0,0-9.9,10.5-13.7,13
	c-2.6,1.7-9.6-2.4-11.3-4.3C17.8,102.6,15.9,97.4,17.7,95z"/>
    </svg>
);

