import { OpinionScope } from '../../../admin/opinions/store';
import { DatasetType } from '../../../datasets/store';

export enum OpinionReportingActionTypes {
    SELECT_REPORT_PAGE = 'SELECT_OPINION_REPORT_PAGE',
    SELECT_WIZARD_TAB = 'SELECT_OPINION_REPORT_WIZARD_TAB',
    SELECT_SCOPE_WIZARD_TAB = 'SELECT_OPINION_REPORT_SCOPE_WIZARD_TAB',
    UPDATE_REPORT_FILTER = 'UPDATE_OPINION_REPORT_FILTER',
    UPDATE_REPORT_DATE_FILTER = 'UPDATE_OPINION_REPORT_DATE_FILTER',
    ADD_OPINION_REPORT_FIELD = 'ADD_OPINION_REPORT_FIELD',
    REMOVE_OPINION_REPORT_FIELD = 'REMOVE_OPINION_REPORT_FIELD',
    RESET_REPORT = 'RESET_REPORT',
    UPDATE_OPINION_REPORT_FIELDS = 'UPDATE_OPINION_REPORT_FIELDS',
    GENERATE_REPORT_STARTED = 'GENERATE_OPINION_REPORT_STARTED',
    GENERATE_REPORT_SUCCESSFUL = 'GENERATE_OPINION_REPORT_SUCCESSFUL',
    GENERATE_REPORT_FAILED = 'GENERATE_OPINION_REPORT_FAILED',
    SET_REPORT_UPDATED = 'SET_OPINION_REPORT_UPDATED',
    TOGGLE_REPORT_CONFIRMATION_MODAL = 'TOGGLE_OPINION_REPORT_CONFIRMATION_MODAL',
    TOGGLE_SAVE_TEMPLATE_MODAL_OPEN = 'TOGGLE_SAVE_OPINION_TEMPLATE_MODAL_OPEN',
    UPDATE_REPORT_TEMPLATE_VALUE = 'UPDATE_OPINION_REPORT_TEMPLATE_VALUE',
    SAVE_REPORT_TEMPLATE_STARTED = 'SAVE_OPINION_REPORT_TEMPLATE_STARTED',
    SAVE_REPORT_TEMPLATE_SUCCESSFUL = 'SAVE_OPINION_REPORT_TEMPLATE_SUCCESSFUL',
    SAVE_REPORT_TEMPLATE_FAILED = 'SAVE_OPINION_REPORT_TEMPLATE_FAILED',
    FETCH_ALL_REPORT_TEMPLATES_STARTED = 'FETCH_ALL_OPINION_REPORT_TEMPLATES_STARTED',
    FETCH_ALL_REPORT_TEMPLATES_SUCCESSFUL = 'FETCH_ALL_OPINION_REPORT_TEMPLATES_SUCCESSFUL',
    FETCH_ALL_REPORT_TEMPLATES_FAILED = 'FETCH_ALL_OPINION_REPORT_TEMPLATES_FAILED',
    OPEN_OPINION_REPORT_TEMPLATE = 'OPEN_OPINION_REPORT_TEMPLATE',
    DELETE_REPORT_TEMPLATE_STARTED = 'DELETE_OPINION_REPORT_TEMPLATE_STARTED',
    DELETE_REPORT_TEMPLATE_FAILED = 'DELETE_OPINION_REPORT_TEMPLATE_FAILED',
    DELETE_REPORT_TEMPLATE_SUCCESSFUL = 'DELETE_OPINION_REPORT_TEMPLATE_SUCCESSFUL',
    SET_REPORT_TEMPLATE_TO_DELETE = 'SET_OPINION_REPORT_TEMPLATE_TO_DELETE',
    SET_OPINION_REPORT_SCOPES = 'SET_OPINION_REPORT_SCOPES',
    SET_CURRENT_SCOPE = 'SET_CURRENT_SCOPE',
    SET_SCOPE_OPEN_SECTIONS = 'SET_SCOPE_OPEN_SECTIONS',
    TOGGLE_INCLUDE_SIGN_OFF_NOTES = 'TOGGLE_INCLUDE_SIGN_OFF_NOTES'
}

export enum ReportPage {
    SELECT = 'Select',
    WIZARD = 'Wizard',
    TEMPLATES = 'Templates'
}

export enum ReportTab {
    SELECT = 'Select',
    FIELDS = 'Choose Fields',
    CONFIGURE = 'Configure Columns',
    FILTER = 'Filter & Confirm'
}

export interface OpinionReportField {
    id: string;
    sectionId: string;
    label: string;
    datasetType: DatasetType.FORM;
}

export type OpinionScopeFields = {
    [scope in OpinionScope]: OpinionReportField[];
};

export type OpenScopeSections = {
    [scope in OpinionScope]: string[]
}

export interface OpinionReportTemplate {
    opinionReportTemplateId: number;
    name: string;
    description: string;
    scopes: OpinionScope[];
    reportFields: OpinionScopeFields;
    filters: OpinionReportFilter;
    includeSignOffNotes: boolean;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate: string;
    clientId: number;
}

export interface OpinionReportFilter {
    jurisdiction: string[] | null;
    focus: string[] | null;
}

export interface UpsertOpinionReportTemplate {
    name: string;
    description: string;
    reportFields: OpinionScopeFields;
    opinionReportTemplateId?: number;
    isOpinionReport: boolean;
    filters: OpinionReportFilter;
    includeSignOffNotes: number;
}

export interface OpinionReportingState {
    reportPage: ReportPage;
    selectedTab: ReportTab;
    fetchingAvailableFields: boolean;
    fetchingError: string | null;
    reportFields: OpinionScopeFields;
    generatingReport: boolean;
    reportFilters: OpinionReportFilter;
    opinionScope: OpinionScope[];
    currentScope: OpinionScope | null;
    reportUpdated: boolean;
    backConfirmationModalOpen: boolean;
    reportTemplates: OpinionReportTemplate[];
    saveTemplateModalOpen: boolean;
    templateName: string;
    templateDescription: string;
    saveTemplateError: string | null;
    selectedTemplate: OpinionReportTemplate | null;
    isDeleting: boolean;
    confirmDeleteReportTemplate: number | null;
    fetchingTemplates: boolean;
    openScopeSections: OpenScopeSections;
    includeSignOffNotes: boolean;
}
