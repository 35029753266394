import { Options } from 'react-select';

import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { DocumentNameDropdownOption } from '../upload/DocumentNameDropdown';
import { MLData } from '../../../datasets/store/mlTypes';

export enum MyDocumentActionTypes {
    TOGGLE_DOCUMENTS_VIEW = 'TOGGLE_DOCUMENTS_VIEW',
    UPLOAD_DOCUMENT_STARTED = 'UPLOAD_DOCUMENT_STARTED',
    UPLOAD_DOCUMENT_SUCCESSFUL = 'UPLOAD_DOCUMENT_SUCCESSFUL',
    UPLOAD_DOCUMENT_FAILED = 'UPLOAD_DOCUMENT_FAILED',
    UPLOAD_DOCUMENT_UPDATE_VALUE = 'UPLOAD_DOCUMENT_UPDATE_VALUE',
    UPLOAD_DOCUMENT_MODAL_OPEN = 'UPLOAD_DOCUMENT_MODAL_OPEN',
    SET_INITIAL_UPLOAD_DOCUMENTS = 'SET_INITIAL_UPLOAD_DOCUMENTS',
    FETCH_DOCUMENTS_STARTED = 'FETCH_DOCUMENTS_STARTED',
    FETCH_ORIGINAL_DOCUMENTS_SUCCESSFUL = 'FETCH_ORIGINAL_DOCUMENTS_SUCCESSFUL',
    FETCH_AGREEMENT_TYPE_DOCUMENTS_SUCCESSFUL = 'FETCH_AGREEMENT_TYPE_DOCUMENTS_SUCCESSFUL',
    FETCH_BASE_DOCUMENTS_SUCCESSFUL = 'FETCH_BASE_DOCUMENTS_SUCCESSFUL',
    FETCH_DOCUMENTS_FAILED = 'FETCH_DOCUMENTS_FAILED',
    OPEN_DOCUMENT_STARTED = 'OPEN_DOCUMENT_STARTED',
    OPEN_DOCUMENT_SUCCESSFUL = 'OPEN_DOCUMENT_SUCCESSFUL',
    OPEN_DOCUMENT_FAILED = 'OPEN_DOCUMENT_FAILED',
    DOWNLOAD_DOCUMENT_STARTED = 'DOWNLOAD_DOCUMENT_STARTED',
    DOWNLOAD_DOCUMENT_SUCCESSFUL = 'DOWNLOAD_DOCUMENT_SUCCESSFUL',
    DOWNLOAD_DOCUMENT_FAILED = 'DOWNLOAD_DOCUMENT_FAILED',
    TOGGLE_DELETE_ORIGINAL_DOCUMENT_MODAL = 'TOGGLE_DELETE_ORIGINAL_DOCUMENT_MODAL',
    DELETE_DOCUMENT_STARTED = 'DELETE_DOCUMENT_STARTED',
    DELETE_DOCUMENT_SUCCESSFUL = 'DELETE_DOCUMENT_SUCCESSFUL',
    DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED',
    UPLOAD_SECONDARY_DOCUMENT_SUCCESSFUL = 'UPLOAD_SECONDARY_DOCUMENT_SUCCESSFUL',
    OPEN_SECONDARY_DOCUMENT_STARTED = 'OPEN_SECONDARY_DOCUMENT_STARTED',
    OPEN_SECONDARY_DOCUMENT_SUCCESSFUL = 'OPEN_SECONDARY_DOCUMENT_SUCCESSFUL',
    OPEN_SECONDARY_DOCUMENT_FAILED = 'OPEN_SECONDARY_DOCUMENT_FAILED',
    OPEN_SUPPORT_DOCUMENT_STARTED = 'OPEN_SUPPORT_DOCUMENT_STARTED',
    OPEN_SUPPORT_DOCUMENT_SUCCESSFUL = 'OPEN_SUPPORT_DOCUMENT_SUCCESSFUL',
    OPEN_SUPPORT_DOCUMENT_FAILED = 'OPEN_SUPPORT_DOCUMENT_FAILED',
    TOGGLE_DOCUMENT_IN_VIEW = 'TOGGLE_DOCUMENT_IN_VIEW',
    SET_SECONDARY_DOCUMENT = 'SET_SECONDARY_DOCUMENT',
    RESET_DOCUMENT_INSTANCE = 'RESET_DOCUMENT_INSTANCE',
    RESET_SECONDARY_DOCUMENT = 'RESET_SECONDARY_DOCUMENT',
    OPEN_DOCUMENT_AND_INSTANCE = 'OPEN_DOCUMENT_AND_INSTANCE',
    TOGGLE_ORIGINAL_DOCUMENTS_MODAL = 'TOGGLE_ORIGINAL_DOCUMENTS_MODAL',
    TOGGLE_BASE_DOCUMENTS_MODAL = 'TOGGLE_BASE_DOCUMENTS_MODAL',
    TOGGLE_ISDA_DOCUMENTS_MODAL = 'TOGGLE_ISDA_DOCUMENTS_MODAL',
    DOWNLOAD_ALL_DOCUMENTS_STARTED = 'DOWNLOAD_ALL_DOCUMENTS_STARTED',
    DOWNLOAD_ALL_DOCUMENTS_SUCCESSFUL = 'DOWNLOAD_ALL_DOCUMENTS_SUCCESSFUL',
    DOWNLOAD_ALL_DOCUMENTS_FAILED = 'DOWNLOAD_ALL_DOCUMENTS_FAILED',
    TOGGLE_LINKED_DOCUMENTS_MODAL = 'TOGGLE_LINKED_DOCUMENTS_MODAL',
    LINK_DOCUMENT_STARTED = 'LINK_DOCUMENT_STARTED',
    LINK_DOCUMENT_SUCCESSFUL = 'LINK_DOCUMENT_SUCCESSFUL',
    LINK_DOCUMENT_FAILED = 'LINK_DOCUMENT_FAILED',
    TOGGLE_DOCUMENT_DETAILS_MODAL = 'TOGGLE_DOCUMENT_DETAILS_MODAL',
    OPEN_INCOMPLETE_DOCUMENT_STARTED = 'OPEN_INCOMPLETE_DOCUMENT_STARTED',
    OPEN_INCOMPLETE_DOCUMENT_SUCCESSFUL = 'OPEN_INCOMPLETE_DOCUMENT_SUCCESSFUL',
    OPEN_INCOMPLETE_DOCUMENT_FAILED = 'OPEN_INCOMPLETE_DOCUMENT_FAILED',
    INCOMPLETE_DOCUMENT_UPDATE_VALUE = 'INCOMPLETE_DOCUMENT_UPDATE_VALUE',
    INCOMPLETE_DOCUMENT_DETAILS_UPDATED = 'INCOMPLETE_DOCUMENT_DETAILS_UPDATED',
    UPDATE_DOCUMENT_DETAILS_STARTED = 'UPDATE_DOCUMENT_DETAILS_STARTED',
    UPDATE_DOCUMENT_DETAILS_SUCCESSFUL = 'UPDATE_DOCUMENT_DETAILS_SUCCESSFUL',
    UPDATE_DOCUMENT_DETAILS_FAILED = 'UPDATE_DOCUMENT_DETAILS_FAILED',
    SKIP_INCOMPLETE_DOCUMENT = 'SKIP_INCOMPLETE_DOCUMENT',
    REVERT_INCOMPLETE_DOCUMENT = 'REVERT_INCOMPLETE_DOCUMENT',
    FETCH_INCOMPLETE_DOCUMENTS_STARTED = 'FETCH_INCOMPLETE_DOCUMENTS_STARTED',
    FETCH_INCOMPLETE_DOCUMENTS_SUCCESSFUL = 'FETCH_INCOMPLETE_DOCUMENTS_SUCCESSFUL',
    FETCH_INCOMPLETE_DOCUMENTS_FAILED = 'FETCH_INCOMPLETE_DOCUMENTS_FAILED',
    SET_SELECTED_INCOMPLETE_DOCUMENTS = 'SET_SELECTED_INCOMPLETE_DOCUMENTS',
    REANALYZE_DOCUMENT_STARTED = 'REANALYZE_DOCUMENT_STARTED',
    REANALYZE_DOCUMENT_FAILED = 'REANALYZE_DOCUMENT_FAILED',
    REANALYZE_DOCUMENT_SUCCESSFUL = 'REANALYZE_DOCUMENT_SUCCESSFUL',
    DOCUMENT_ANALYSIS_COMPLETE = 'DOCUMENT_ANALYSIS_COMPLETE',
    DOCUMENT_ANALYSIS_FAILED = 'DOCUMENT_ANALYSIS_FAILED',
    RESET_SUPPORT_DOCUMENTS = 'RESET_SUPPORT_DOCUMENTS',
    EXPORT_TO_CSV_STARTED = 'EXPORT_TO_CSV_STARTED',
    EXPORT_TO_CSV_SUCCESSFUL = 'EXPORT_TO_CSV_SUCCESSFUL',
    EXPORT_TO_CSV_FAILED = 'EXPORT_TO_CSV_FAILED',
    EXPORT_TO_CDM_STARTED = 'EXPORT_TO_CDM_STARTED',
    EXPORT_TO_CDM_SUCCESSFUL = 'EXPORT_TO_CDM_SUCCESSFUL',
    EXPORT_TO_CDM_FAILED = 'EXPORT_TO_CDM_FAILED',
    TOGGLE_CDM_PREVIEW_MODAL = 'TOGGLE_CDM_PREVIEW_MODAL',
    DOWNLOAD_CDM_PREVIEW = 'DOWNLOAD_CDM_PREVIEW',
    UPDATE_ORIGINAL_DOCUMENT = 'UPDATE_ORIGINAL_DOCUMENT',
    EXPORT_ALL_CSV_STARTED = 'EXPORT_ALL_CSV_STARTED',
    EXPORT_ALL_CSV_SUCCESSFUL = 'EXPORT_ALL_CSV_SUCCESSFUL',
    EXPORT_ALL_CSV_FAILED = 'EXPORT_ALL_CSV_FAILED',
    SET_ORIGINAL_DOCUMENT_TABLE_FILTERS = 'SET_ORIGINAL_DOCUMENT_TABLE_FILTERS',
    CLEAR_ORIGINAL_DOCUMENT_TABLE_FILTERS = 'CLEAR_ORIGINAL_DOCUMENT_TABLE_FILTERS',
    SET_ORIGINAL_DOCUMENT_TABLE_COLUMN_SORT = 'SET_ORIGINAL_DOCUMENT_TABLE_COLUMN_SORT',
    ORIGINAL_DOCUMENTS_PAGINATION_NEXT = 'ORIGINAL_DOCUMENTS_PAGINATION_NEXT',
    ORIGINAL_DOCUMENTS_PAGINATION_PREVIOUS = 'ORIGINAL_DOCUMENTS_PAGINATION_PREVIOUS',
    SET_BASE_DOCUMENT_TABLE_FILTERS = 'SET_BASE_DOCUMENT_TABLE_FILTERS',
    CLEAR_BASE_DOCUMENT_TABLE_FILTERS = 'CLEAR_BASE_DOCUMENT_TABLE_FILTERS',
    SET_BASE_DOCUMENT_TABLE_COLUMN_SORT = 'SET_BASE_DOCUMENT_TABLE_COLUMN_SORT',
    BASE_DOCUMENTS_PAGINATION_NEXT = 'BASE_DOCUMENTS_PAGINATION_NEXT',
    BASE_DOCUMENTS_PAGINATION_PREVIOUS = 'BASE_DOCUMENTS_PAGINATION_PREVIOUS',
    SET_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS = 'SET_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS',
    CLEAR_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS = 'CLEAR_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS',
    SET_AGREEMENT_TYPE_DOCUMENT_TABLE_COLUMN_SORT = 'SET_AGREEMENT_TYPE_DOCUMENT_TABLE_COLUMN_SORT',
    SET_AGREEMENT_TYPE_DOCUMENTS_PAGE_SIZE = 'SET_AGREEMENT_TYPE_DOCUMENTS_PAGE_SIZE',
    AGREEMENT_TYPE_DOCUMENTS_PAGINATION_NEXT = 'AGREEMENT_TYPE_DOCUMENTS_PAGINATION_NEXT',
    AGREEMENT_TYPE_DOCUMENTS_PAGINATION_PREVIOUS = 'AGREEMENT_TYPE_DOCUMENTS_PAGINATION_PREVIOUS',
    SET_INCOMPLETE_DOCUMENT_TABLE_FILTERS = 'SET_INCOMPLETE_DOCUMENT_TABLE_FILTERS',
    CLEAR_INCOMPLETE_DOCUMENT_TABLE_FILTERS = 'CLEAR_INCOMPLETE_DOCUMENT_TABLE_FILTERS',
    SET_INCOMPLETE_DOCUMENT_TABLE_COLUMN_SORT = 'SET_INCOMPLETE_DOCUMENT_TABLE_COLUMN_SORT',
    SET_INCOMPLETE_DOCUMENTS_PAGE_SIZE = 'SET_INCOMPLETE_DOCUMENTS_PAGE_SIZE',
    INCOMPLETE_DOCUMENTS_PAGINATION_NEXT = 'INCOMPLETE_DOCUMENTS_PAGINATION_NEXT',
    INCOMPLETE_DOCUMENTS_PAGINATION_PREVIOUS = 'INCOMPLETE_DOCUMENTS_PAGINATION_PREVIOUS',
    TOGGLE_DOCUMENT_ANALYSIS_VIEW = 'TOGGLE_DOCUMENT_ANALYSIS_VIEW',
    FETCH_LINKED_DOCUMENTS_STARTED = 'FETCH_LINKED_DOCUMENTS_STARTED',
    FETCH_LINKED_DOCUMENTS_SUCCESSFUL = 'FETCH_LINKED_DOCUMENTS_SUCCESSFUL',
    FETCH_LINKED_DOCUMENTS_FAILED = 'FETCH_LINKED_DOCUMENTS_FAILED',
    TOGGLE_DOCUMENT_RENAME_MODAL_OPEN = 'TOGGLE_DOCUMENT_RENAME_MODAL_OPEN',
    UPDATE_DOCUMENT_DESCRIPTION = 'UPDATE_DOCUMENT_DESCRIPTION',
    DOCUMENT_DESCRIPTIONS_HAVE_UPDATED = 'DOCUMENT_DESCRIPTIONS_HAVE_UPDATED',
    RENAME_DOCUMENTS_STARTED = 'RENAME_DOCUMENTS_STARTED',
    RENAME_DOCUMENTS_SUCCESSFUL = 'RENAME_DOCUMENTS_SUCCESSFUL',
    RENAME_DOCUMENTS_FAILED = 'RENAME_DOCUMENTS_FAILED',
    SET_ORIGINAL_DOCUMENTS_PAGE_SIZE = 'SET_ORIGINAL_DOCUMENTS_PAGE_SIZE',
    SET_BASE_DOCUMENTS_PAGE_SIZE = 'SET_BASE_DOCUMENTS_PAGE_SIZE',
    TOGGLE_ML_COMPLETED_MODAL = 'TOGGLE_ML_COMPLETED_MODAL',
    CREATE_NEW_ENTITY_FROM_ML_DATA_STARTED = 'CREATE_NEW_ENTITY_FROM_ML_DATA_STARTED',
    CREATE_NEW_ENTITY_FROM_ML_DATA_SUCCESSFUL = 'CREATE_NEW_ENTITY_FROM_ML_DATA_SUCCESSFUL',
    CREATE_NEW_ENTITY_FROM_ML_DATA_FAILED = 'CREATE_NEW_ENTITY_FROM_ML_DATA_FAILED',
    INITIAL_AI_DOCUMENT_STARTED = 'INITIAL_AI_DOCUMENT_STARTED',
    INITIAL_AI_DOCUMENT_SUCCESSFUL = 'INITIAL_AI_DOCUMENT_SUCCESSFUL',
    INITIAL_AI_DOCUMENT_FAILED = 'INITIAL_AI_DOCUMENT_FAILED',
    RUN_LATEST_ML_QUERIES_STARTED = 'RUN_LATEST_ML_QUERIES_STARTED',
    RUN_LATEST_ML_QUERIES_SUCCESSFUL = 'RUN_LATEST_ML_QUERIES_SUCCESSFUL',
    RUN_LATEST_ML_QUERIES_FAILED = 'RUN_LATEST_ML_QUERIES_FAILED',
    SET_INCOMPLETE_DOCUMENT_SCHEDULE_PAGE = 'SET_INCOMPLETE_DOCUMENT_SCHEDULE_PAGE',
    SET_INCOMPLETE_DOCUMENT_PAGE_TOTAL = 'SET_INCOMPLETE_DOCUMENT_PAGE_TOTAL',
    SET_DOCUMENT_ANALYSIS_PAGE_TOTAL = 'SET_DOCUMENT_ANALYSIS_PAGE_TOTAL',
    SET_DOCUMENT_ANALYSIS_SELECTED_PAGE = 'SET_DOCUMENT_ANALYSIS_SELECTED_PAGE',
    SET_SCHEDULE_END_PAGE_PLACEHOLDER = 'SET_SCHEDULE_END_PAGE_PLACEHOLDER',
    FETCH_PRE_EXECUTION_DOCUMENTS_STARTED = 'FETCH_PRE_EXECUTION_DOCUMENTS_STARTED',
    FETCH_PRE_EXECUTION_DOCUMENTS_SUCCESSFUL = 'FETCH_PRE_EXECUTION_DOCUMENTS_SUCCESSFUL',
    FETCH_PRE_EXECUTION_DOCUMENTS_FAILED = 'FETCH_PRE_EXECUTION_DOCUMENTS_FAILED',
    SET_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS = 'SET_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS',
    CLEAR_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS = 'CLEAR_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS',
    SET_PRE_EXECUTION_DOCUMENT_TABLE_COLUMN_SORT = 'SET_PRE_EXECUTION_DOCUMENT_TABLE_COLUMN_SORT',
    SET_PRE_EXECUTION_DOCUMENTS_PAGE_SIZE = 'SET_PRE_EXECUTION_DOCUMENTS_PAGE_SIZE',
    PRE_EXECUTION_DOCUMENTS_PAGINATION_NEXT = 'PRE_EXECUTION_DOCUMENTS_PAGINATION_NEXT',
    PRE_EXECUTION_DOCUMENTS_PAGINATION_PREVIOUS = 'PRE_EXECUTION_DOCUMENTS_PAGINATION_PREVIOUS',
    TOGGLE_DOCUMENT_CUSTOM_FILTERS_MODAL = 'TOGGLE_DOCUMENT_CUSTOM_FILTERS_MODAL',
    UPDATE_DOCUMENT_CUSTOM_FILTERS = 'UPDATE_DOCUMENT_CUSTOM_FILTERS',
    SET_SELECTED_DOCUMENT_CUSTOM_FILTER_ID = 'SET_SELECTED_DOCUMENT_CUSTOM_FILTER_ID',
    SET_DOCUMENT_CUSTOM_FILTER_HAS_UPDATED = 'SET_DOCUMENT_CUSTOM_FILTER_HAS_UPDATED'
}

export enum DocumentsView {
    ORIGINAL_DOCUMENTS = 'original documents',
    INCOMPLETE_DOCUMENTS = 'incomplete documents',
    PRE_EXECUTION_DRAFT = 'pre execution draft'
}

export enum SelectedDocument {
    ORIGINAL = 'original',
    SECONDARY = 'secondary',
    SUPPORT = 'support'
}

export enum DocumentType {
    ORIGINAL = 'original',
    AMENDMENT = 'amendment',
    RESTATEMENT = 'restatement',
    SUPPORT = 'support',
    REPLICATION = 'replication',
    PRE_EXECUTION_DRAFT = 'pre execution draft'
}

export enum AnalysisView {
    DOCUMENT = 'document',
    DATASET = 'dataset',
    SPLIT = 'split'
}

export interface LinkedDocument {
    documentId: number;
    documentDescription: string;
    location: string;
    mimeType: string;
    executedDate: string;
    isLegacy?: boolean;
    documentName: string | null;
    agreementType: string | null;
    entityAName: string | null;
    entityBName: string | null;
}

export interface BaseDocumentToSupport {
    documentId: number;
    originalDocumentId: number | null;
    documentDescription: string;
    documentName: string;
}

export interface ArkDocument {
    documentId: number;
    location: string;
    documentDescription: string;
    documentNameId: number | null;
    documentType: DocumentType | null;
    mimeType: string;
    entityA: number | null;
    entityB: number | null;
    executedDate: string | null;
    datedAsOf: string | null;
    originalDocumentId: number | null;
    linkedDocumentId: number | null;
    modifiedBy: number;
    modifiedDate: string;
    entityAName: string | null;
    entityAMyCompany: number | null;
    entityAOnWatchlist: number | null;
    entityBName: string | null;
    entityBMyCompany: number | null;
    entityBOnWatchlist: number | null;
    documentName: string | null;
    agreementType: string | null;
    agreementTypeId: number | null;
    allDocuments: LinkedDocument[];
    isAgency: number;
    annexDefinitionId?: number;
    analysisStarted: number;
    analysisComplete: number;
    scheduleStartPage: number | null;
    scheduleEndPage: number | null;
    supportDocuments?: LinkedDocument[];
    bothEntitiesCanBeAgent?: boolean;
    agentEntitySelected?: number | null;
    amendmentLinkedDocumentIds?: number[];
    baseDocuments?: BaseDocumentToSupport[];
    isLegacy?: boolean;
    mlDataVersion?: number;
    mlDataVersionConfirmed?: number;
    latestMlDataVersion?: number;
    mlInProgress?: boolean;
    instanceComplete?: boolean;
    agencyAnnexStartPage?: number | null;
    agencyAnnexEndPage?: number | null;
    analyseLater: number;
}

export interface ArkTableDocumentEntity {
    entityId: number | null;
    entityName: string | null;
    myCompanyEntity: number | null;
    entityOnWatchlist: number | null;
}

export interface ArkTableDocument {
    documentId: number;
    location: string;
    documentDescription: string;
    documentNameId: number | null;
    documentType: DocumentType | null;
    mimeType: string;
    entityA: ArkTableDocumentEntity;
    entityB: ArkTableDocumentEntity;
    executedDate: string | null;
    datedAsOf: string | null;
    originalDocumentId: number | null;
    linkedDocumentId: number | null;
    modifiedBy: number;
    modifiedDate: string;
    documentName: string | null;
    agreementType: string | null;
    agreementTypeId: number | null;
    allDocuments: LinkedDocument[];
    isAgency: number;
    annexDefinitionId?: number;
    analysisStarted: number;
    analysisComplete: number;
    scheduleStartPage: number | null;
    supportDocuments?: LinkedDocument[];
    bothEntitiesCanBeAgent?: boolean;
    agentEntitySelected?: number | null;
    amendmentLinkedDocumentIds?: number[];
    baseDocuments?: BaseDocumentToSupport[];
    isLegacy?: boolean;
    mlDataVersion?: number;
    mlDataVersionConfirmed?: number;
    latestMlDataVersion?: number;
    mlInProgress?: boolean;
    instanceComplete?: boolean;
    agencyAnnexStartPage?: number | null;
    agencyAnnexEndPage?: number | null;
    analyseLater: number;
}

export interface DocumentLinkDetails {
    entityA: number | null;
    entityB: number | null;
    documentNameId: number | null;
    documentId: number;
}

export interface BaseUploadDocument {
    description: string;
    executedDate: string;
    file: File[] | null;
    type?: DocumentType;
    amendmentLinkedDocumentIds: number[] | null;
}

export interface AmendmentUploadDocument extends BaseUploadDocument {
    originalDocumentId: null | number;
    type: DocumentType.AMENDMENT;
    amendmentLinkedDocumentIds: number[] | null;
    datedAsOf: string | null;
}

export interface RestatementUploadDocument extends BaseUploadDocument {
    originalDocumentId: null | number;
    type: DocumentType.RESTATEMENT;
    datedAsOf: string | null;
}

export interface SupportingUploadDocument extends BaseUploadDocument {
    originalDocumentId: number;
    type: DocumentType.SUPPORT;
    datedAsOf: string | null;
}

export interface ReplicationUploadDocument extends BaseUploadDocument {
    originalDocumentId: null | number;
    type: DocumentType.REPLICATION;
}

export type SecondaryUploadDocument = AmendmentUploadDocument | RestatementUploadDocument | SupportingUploadDocument;
export type UploadDocument = SecondaryUploadDocument | BaseUploadDocument | ReplicationUploadDocument;

export type DocumentUploadValue = string | DropdownOption | DocumentNameDropdownOption | Options<DropdownOption> | Options<DocumentNameDropdownOption> | File[] | number | number[] | DocumentType | BaseDocumentToSupport[] | null | boolean | undefined;

export interface MyDocumentsState {
    documentsView: DocumentsView;
    uploadModalOpen: boolean;
    filesToUpload: File[] | null;
    selectedIncompleteDocuments: ArkDocument[];
    incompleteDocument: ArkDocument | null;
    incompleteDocumentUrl: null | string;
    incompleteDocumentError: string | null;
    incompleteDocumentDetailsUpdated: boolean;
    incompleteDocumentMLData: MLData | null;
    isUpdatingDocumentDetails: boolean;
    isUploading: boolean;
    documentDetailsModalOpen: boolean;
    isOpening: boolean;
    isLoading: boolean;
    availableOriginalDocuments: ArkDocument[];
    originalDocumentToDelete: ArkDocument | null;
    originalDocumentPage: number;
    totalOriginalDocuments: number;
    originalDocumentFilters: TableFilters;
    originalDocumentSort?: ColumnSort;
    originalDocumentPageSize: number;
    availableBaseDocuments: ArkDocument[];
    baseDocumentPage: number;
    totalBaseDocuments: number;
    baseDocumentFilters: TableFilters;
    baseDocumentSort?: ColumnSort;
    baseDocumentPageSize: number;
    agreementTypeDocuments: ArkDocument[];
    agreementTypeDocumentPage: number;
    totalAgreementTypeDocuments: number;
    agreementTypeDocumentFilters: TableFilters;
    agreementTypeDocumentSort?: ColumnSort;
    agreementTypeDocumentPageSize: number;
    fetchDocumentsAgreementTypeId: number;
    analysisUploadDocument: UploadDocument;
    originalDocumentUrl: null | string;
    originalDocument: ArkDocument | null;
    secondaryDocumentUrl: null | string;
    secondaryDocumentAnalyzing: boolean;
    secondaryDocumentError: string | null;
    secondaryDocument: ArkDocument | null;
    documentInView: null | SelectedDocument;
    newDocumentId: number | null;
    originalDocumentsModalOpen: boolean;
    baseDocumentsModalOpen: boolean;
    isdaDocumentsModalOpen: boolean;
    downloadError: string | null;
    linkDocumentsModalOpen: number | null;
    incompleteDocuments: ArkDocument[];
    incompleteDocumentPage: number;
    totalIncompleteDocuments: number;
    incompleteDocumentFilters: TableFilters;
    incompleteDocumentSort?: ColumnSort;
    incompleteDocumentPageSize: number;
    analyzeError: string | null;
    supportDocumentUrl: null | string;
    supportDocumentError: string | null;
    supportDocument: LinkedDocument | null;
    documentAnalysisView: AnalysisView;
    linkedDocuments: LinkedDocument[] | null;
    isDeleting: boolean;
    deleteError: string | null;
    renameDocument: ArkDocument | null;
    documentDescriptionsHaveUpdated: boolean;
    isRenaming: boolean;
    renameError: string | null;
    mlCompletedModalOpen: boolean;
    isCreatingEntity: boolean;
    incompleteScheduleStartPage: number | null;
    incompleteDocumentTotalPage: number | null;
    documentAnalysisSelectedPage: number | null;
    documentAnalysisTotalPage: number | null;
    scheduleStartPlaceholder: number | null;
    scheduleEndPlaceholder: number | null;
    cdmJson: object | null;
    preExecutionDocuments: ArkDocument[];
    preExecutionDocumentPage: number;
    totalPreExecutionDocuments: number;
    preExecutionDocumentFilters: TableFilters;
    preExecutionDocumentSort?: ColumnSort;
    preExecutionDocumentPageSize: number;
    documentCustomFiltersModalOpen: boolean;
    selectedDocumentCustomFilterId: string | null;
    documentCustomFilterHasUpdated: boolean;
}
