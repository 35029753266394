import React, { useCallback } from 'react';
import { min } from 'lodash/fp';

import { useWindowResize } from '../../../hooks/useWindowResize';
import { Position, PositionModal } from '../../shared/modal/PositionModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import styles from './Lei.module.scss';

const { lightGrey } = styles;

interface FuzzyAutoCompletionsModalProps {
    isOpen: boolean;
    position: Position;
    inputWidth: number;
    selectMatch: (value: string) => void;
    closeModal: () => void;
    fuzzyCompletions: string[];
    autoCompletions: string[];
    searchValue: string;
}

export const FuzzyAutoCompletionsModal: React.FC<FuzzyAutoCompletionsModalProps> = ({ isOpen, position, inputWidth, selectMatch, fuzzyCompletions, autoCompletions, searchValue, closeModal }) => {
    const [, screenHeight] = useWindowResize();
    const maxScrollableHeight = min([(screenHeight - position.y) - 60, 150])!;

    const getDisplayValue = useCallback((value: string, index: number, type: string) => {
        const indexOfSearch = value.toLowerCase().indexOf(searchValue.toLowerCase());
        if (indexOfSearch >= 0) {
            const indexOfSearchValueEnd = indexOfSearch + searchValue.length;
            return (
                <div className={styles.completionTitle} key={index} data-testid={`lei-search-${type}-completions-${index}`} onClick={() => selectMatch(value)}>
                    <span>{value.slice(0, indexOfSearch)}</span>
                    <span className={styles.boldHighlight}>{value.slice(indexOfSearch, indexOfSearchValueEnd)}</span>
                    <span>{value.slice(indexOfSearchValueEnd)}</span>
                </div>
            );
        }
        return <div className={styles.completionTitle} key={index} data-testid={`lei-search-auto-completions-${index}`} onClick={() => selectMatch(value)}>{value}</div>;
    }, [searchValue, selectMatch]);

    return (
        <PositionModal
            isOpen={isOpen}
            position={position}
            height='fit-content'
            width={`${inputWidth}px`}
            closeModal={closeModal}
            testId='lei-search-fuzzy-auto-completions'
            overflow='hidden'
        >
            <div className={styles.fuzzyAutoCompletionsModalWrapper}>
                <div className={styles.completionsWrapper} style={{ width: 'calc(50% - 11px)', marginRight: '5px', paddingRight: '5px', borderRight: `1px solid ${lightGrey}` }}>
                    <div className={styles.completionHeader}>Suggestions</div>
                    <div className={styles.completionValuesWrapper}>
                        <Scrollable maxHeight={`${maxScrollableHeight}px`}>
                            {autoCompletions.map((value, index) => getDisplayValue(value, index, 'auto'))}
                        </Scrollable>
                    </div>
                </div>
                <div className={styles.completionsWrapper} style={{ width: '50%' }}>
                    <div className={styles.completionHeader}>Did you mean?</div>
                    <div className={styles.completionValuesWrapper}>
                        <Scrollable maxHeight={`${maxScrollableHeight}px`}>
                            {fuzzyCompletions.map((value, index) => getDisplayValue(value, index, 'fuzzy'))}
                        </Scrollable>
                    </div>
                </div>
            </div>
        </PositionModal >
    );
};
