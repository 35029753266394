import React from 'react';
import { isArray, isNull } from 'lodash/fp';

import { TooltipList } from '../tooltip';
import styles from './Table.module.scss';
import { Icon } from '../icon/Icon';
import { User } from '../icons';
import { GLEIOtherName } from '../../admin/entity/store';
import { capitaliseStartLetter } from '../../../utils/regex-utils';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const OtherNamesCell: React.FC<{ value: any; }> = ({ value }) => {
    if (isNull(value) || (isArray(value) && value.length === 0)) {
        return null;
    }

    const otherNames = (value as GLEIOtherName[]).map(({ name, type }) => `${name} - ${capitaliseStartLetter((type || '').replace(/_/g, ' ').toLowerCase())}`);

    return (
        <div className={styles.otherNamesCellWrapper}>
            <TooltipList overlayText={otherNames} showBulletPoints={false}>
                <div className={styles.otherNamesCell}><Icon icon={User} /></div>
            </TooltipList>
        </div>
    );
};
