import React from 'react';

import { ArkOpinion } from '../my-opinions/store';
import { ClauseSection, CollateralTakerInsolvencySection, CounterpartyCoverageSection, EligibleCollateralSection, GeneralSection, OtherIssuesSection, ScopeSection, TakerSFTDocumentsSection, TransactionCoverageSection } from './sections';
import { CollateralAgreementCoverageSection } from './sections/CollateralAgreementCoverage';
import { CollateralTakerOpinionInstance } from './store/types';
import { collateralTakerOpinionGroups } from '../../constants/opinion';

interface CollateralTakerInstanceProps {
    instance: CollateralTakerOpinionInstance;
    opinion: ArkOpinion;
    isEditing: boolean;
    isUpdating: boolean;
    width: number;
}

export const CollateralTakerInstance: React.FC<CollateralTakerInstanceProps> = ({ instance, opinion, isEditing, isUpdating, width }) => {
    const { content: { general, collateralTakerInsolvency, sftDocuments, otherIssues, transactionCoverage, counterpartyCoverage, eligibleCollateral, agreementCoverage, additionalProvisions }, fieldsUpdated } = instance;
    return (
        <div>
            <GeneralSection general={general} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <ScopeSection opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} />
            <CollateralTakerInsolvencySection collateralTakerInsolvency={collateralTakerInsolvency} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <TakerSFTDocumentsSection sftDocuments={sftDocuments} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <OtherIssuesSection otherIssues={otherIssues} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <CollateralAgreementCoverageSection agreementCoverage={agreementCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} collateralOpinionGroups={collateralTakerOpinionGroups} />
            <TransactionCoverageSection transactionCoverage={transactionCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <CounterpartyCoverageSection counterpartyCoverage={counterpartyCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} jurisdiction={opinion.jurisdiction} />
            <EligibleCollateralSection eligibleCollateral={eligibleCollateral} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <ClauseSection additionalProvisions={additionalProvisions} opinion={opinion} width={width} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
        </div>
    );
};
