import { flow, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { PreExecutionFilterOption } from '../../search/store';
import { DocumentReportTemplate, DocumentReportingActionTypes, DocumentReportingState, ReportPage, ReportTab } from './types';

export const initialDocumentReportFilter = {
    documentNameId: null,
    entityId: null,
    includePreExecution: PreExecutionFilterOption.EXCLUDE,
    date: {
        to: null,
        from: null
    },
    isExclusiveEntityFilter: false
};

export const INITIAL_STATE: DocumentReportingState = {
    reportPage: ReportPage.SELECT,
    selectedTab: ReportTab.SELECT,
    availableFields: [],
    reportFieldHierarchy: [],
    reportFields: [],
    fetchingAvailableFields: false,
    generatingReport: false,
    fetchingError: null,
    reportFilters: initialDocumentReportFilter,
    reportUpdated: false,
    backConfirmationModalOpen: false,
    reportTemplates: [],
    saveTemplateModalOpen: false,
    templateDescription: '',
    templateName: '',
    saveTemplateError: null,
    selectedTemplate: null,
    isDeleting: false,
    confirmDeleteReportTemplate: null,
    fetchingTemplates: false
};

export const documentReportingReducer: Reducer<DocumentReportingState> = (state = INITIAL_STATE, action): DocumentReportingState => {
    switch (action.type) {
        case DocumentReportingActionTypes.SELECT_REPORT_PAGE:
            return set('reportPage', action.payload.page, state);
        case DocumentReportingActionTypes.SELECT_WIZARD_TAB:
            return set('selectedTab', action.payload, state);
        case DocumentReportingActionTypes.UPDATE_DOCUMENT_REPORT_FILTER: {
            const { key, value } = action.payload;
            return set(`reportFilters[${key}]`, value, state);
        }
        case DocumentReportingActionTypes.UPDATE_REPORT_DATE_FILTER: {
            const { key, value } = action.payload;
            return set(`reportFilters.date[${key}]`, value, state);
        }
        case DocumentReportingActionTypes.FETCH_AVAILABLE_FIELDS_STARTED:
            return flow(
                set('fetchingAvailableFields', true),
                set('fetchingError', null)
            )(state);
        case DocumentReportingActionTypes.FETCH_AVAILABLE_DOCUMENT_FIELDS_SUCCESSFUL: {
            const { reportFields, availableFields, hierarchy } = action.payload;
            return flow(
                set('fetchingAvailableFields', false),
                set('availableFields', availableFields),
                set('reportFieldHierarchy', hierarchy),
                set('reportFields', reportFields || [])
            )(state);
        }
        case DocumentReportingActionTypes.FETCH_AVAILABLE_FIELDS_FAILED:
            return flow(
                set('fetchingAvailableFields', false),
                set('fetchingError', action.payload)
            )(state);
        case DocumentReportingActionTypes.ADD_DOCUMENT_REPORT_FIELD:
            return set('reportFields', [...state.reportFields, action.payload], state);
        case DocumentReportingActionTypes.REMOVE_DOCUMENT_REPORT_FIELD:
            return set('reportFields', state.reportFields.filter(({ id }) => id !== action.payload), state);
        case DocumentReportingActionTypes.UPDATE_DOCUMENT_REPORT_FIELDS:
            return set('reportFields', action.payload, state);
        case DocumentReportingActionTypes.GENERATE_REPORT_STARTED:
            return set('generatingReport', true, state);
        case DocumentReportingActionTypes.GENERATE_REPORT_FAILED:
            return set('generatingReport', false, state);
        case DocumentReportingActionTypes.GENERATE_REPORT_SUCCESSFUL:
            return flow(
                set('reportPage', ReportPage.SELECT),
                set('selectedTab', ReportTab.SELECT),
                set('generatingReport', false),
                set('reportFilters', initialDocumentReportFilter),
                set('availableFields', []),
                set('reportFields', []),
                set('reportUpdated', false)
            )(state);
        case DocumentReportingActionTypes.RESET_REPORT: {
            return { ...INITIAL_STATE, reportTemplates: state.reportTemplates, reportPage: action.payload };
        }
        case DocumentReportingActionTypes.TOGGLE_REPORT_CONFIRMATION_MODAL:
            return set('backConfirmationModalOpen', action.payload, state);
        case DocumentReportingActionTypes.SET_REPORT_UPDATED:
            return set('reportUpdated', action.payload, state);
        case DocumentReportingActionTypes.TOGGLE_SAVE_TEMPLATE_MODAL_OPEN:
            return flow(
                set('saveTemplateModalOpen', action.payload),
                set('templateName', ''),
                set('templateDescription', '')
            )(state);
        case DocumentReportingActionTypes.UPDATE_REPORT_TEMPLATE_VALUE: {
            const { key, value } = action.payload;
            return set(`${key}`, value, state);
        }
        case DocumentReportingActionTypes.SAVE_REPORT_TEMPLATE_FAILED:
            return set('saveTemplateError', action.payload, state);
        case DocumentReportingActionTypes.SAVE_REPORT_TEMPLATE_SUCCESSFUL:
            return flow(
                set('saveTemplateError', null),
                set('saveTemplateModalOpen', false),
                set('reportUpdated', false),
                set('selectedTemplate', action.payload)
            )(state);
        case DocumentReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_STARTED:
            return set('fetchingTemplates', true, state);
        case DocumentReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_SUCCESSFUL:
            return flow(
                set('reportTemplates', action.payload),
                set('fetchingTemplates', false)
            )(state);
        case DocumentReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_FAILED:
            return flow(
                set('fetchingError', action.payload),
                set('fetchingTemplates', false)
            )(state);
        case DocumentReportingActionTypes.OPEN_DOCUMENT_REPORT_TEMPLATE: {
            const reportTemplate: DocumentReportTemplate = action.payload;
            return flow(
                set('selectedTemplate', reportTemplate),
                set('reportPage', ReportPage.WIZARD),
                set('selectedTab', ReportTab.SELECT),
                set('reportFilters', reportTemplate.filters)
            )(state);
        }
        case DocumentReportingActionTypes.DELETE_REPORT_TEMPLATE_STARTED:
            return set('isDeleting', true, state);
        case DocumentReportingActionTypes.DELETE_REPORT_TEMPLATE_SUCCESSFUL:
            return flow(
                set('isDeleting', false),
                set('reportTemplates', action.payload),
                set('confirmDeleteReportTemplate', null)
            )(state);
        case DocumentReportingActionTypes.DELETE_REPORT_TEMPLATE_FAILED:
            return set('isDeleting', false, state);
        case DocumentReportingActionTypes.SET_REPORT_TEMPLATE_TO_DELETE:
            return set('confirmDeleteReportTemplate', action.payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
