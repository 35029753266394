import React, { useMemo } from 'react';
import { isNull } from 'lodash/fp';

import styles from './MyDatasets.module.scss';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { MyDatasetDefinition } from './MyDatasetDefinition';
import { useAppSelector } from '../../../hooks/react-redux';
import { getAllMyDatasets, getMyDatasetDefinitions, getSelectedDatasetId } from './store';
import { TooltipList } from '../../shared/tooltip';
import { ModalDefinition } from './ModalDefinition';
import { Spinner } from '../../shared/spinner/Spinner';
import { MyDatasetsButtons } from './MyDatasetsButtons';

interface AllMyDatasetDefintionsProps {
    title: string;
    datasetId: number;
    isPreview?: boolean
}

export const AllMyDatasetDefinitions: React.FC<AllMyDatasetDefintionsProps> = ({ title, datasetId, isPreview = false }) => {
    const myDatasets = useAppSelector(getAllMyDatasets);
    const parentDatasetId = useAppSelector(getSelectedDatasetId);
    const agreementType = useMemo(() => !!parentDatasetId && myDatasets.find(({ datasetId }) => datasetId === parentDatasetId)?.agreementType || '', [myDatasets, parentDatasetId]);
    const documentNames = useMemo(() => !!parentDatasetId && myDatasets.find(({ datasetId }) => datasetId === parentDatasetId)?.documentNames || null, [myDatasets, parentDatasetId]);
    const [, screenHeight] = useWindowResize();
    const definitionHeight = useMemo(() => screenHeight - 240, [screenHeight]);

    const datasetDefinitions = useAppSelector(getMyDatasetDefinitions);
    const parentDefinition = useMemo(() => datasetDefinitions.find(({ datasetId }) => datasetId === parentDatasetId) || null, [datasetDefinitions, parentDatasetId]);

    if (isNull(parentDefinition)) {
        return <Spinner />;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.definitionWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.agreementWrapper}>
                        <div className={styles.agreementLabel}>{agreementType}</div>
                        <TooltipList overlayText={documentNames} placement='right' >
                            <div className={styles.documentNamesIcon}>i</div>
                        </TooltipList>
                    </div>
                </div>
                <div style={{ height: definitionHeight }}>
                    <MyDatasetDefinition datasetDefinition={parentDefinition!} parents={0} isPreview={isPreview} />
                </div>
                <MyDatasetsButtons datasetId={datasetId} />
            </div>
            <ModalDefinition isPreview={isPreview} />
        </div>
    );
};
