import { RawDraftContentState } from 'draft-js';
import { isNull, isUndefined } from 'lodash/fp';
import React, { useMemo } from 'react';

import { WYSIWYG, isEmpty } from '../../../shared/wysiwyg/WYSIWYG';
import styles from './Fields.module.scss';
import { SignOffConversation } from '../store';
import { SignOffConversationIcon } from '../sign-off';

const { amethyst, grey } = styles;

interface WYSIWYGFieldProps {
    id: string;
    value: RawDraftContentState | null;
    disabled?: boolean;
    showFieldUpdated?: boolean;
    toolbarHidden?: boolean;
    updateValue: (value: RawDraftContentState | null) => void;
    fieldOpen: boolean;
    signOffConversation?: SignOffConversation;
}

const TOOLBAR_HEIGHT = 89;

export const WYSIWYGField: React.FC<WYSIWYGFieldProps> = ({
    id,
    value,
    disabled = false,
    showFieldUpdated = false,
    toolbarHidden = false,
    updateValue,
    fieldOpen,
    signOffConversation
}) => {

    const updateWYSIWYGValue = (rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            if (!isNull(value)) {
                updateValue(null);
            }
        } else {
            updateValue(rawValue);
        }
    };

    const borderColor = useMemo(() => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return grey;
    }, [showFieldUpdated]);

    const height = useMemo(() => {
        if (disabled) {
            return 'fit-content';
        }
        return `${200 + (toolbarHidden ? 0 : TOOLBAR_HEIGHT)}px`;
    }, [toolbarHidden, disabled]);

    return (
        <div className={styles.wysiwygWrapper} data-testid={`opinion-wysiwyg-${id}-wrapper`}>
            {fieldOpen &&
                <>
                    <WYSIWYG
                        content={value}
                        updateContent={updateWYSIWYGValue}
                        disabled={disabled}
                        borderColor={borderColor}
                        toolbarHidden={toolbarHidden}
                        height={height}
                        maxHeight={height}
                        minHeight={height}
                        isReadOnly={disabled}
                        showBorder={!toolbarHidden}
                        toolbarOptions={['inline', 'history']}
                        inlineOptions={['bold', 'italic']}
                        width='calc(90%)'
                    />
                    {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={id} />}
                </>
            }
        </div>
    );
};
