import React, { useCallback, useMemo, useRef } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';

import styles from './EntityType.module.scss';
import { CaretDown, CaretSide, Delete, Tick } from '../../../shared/icons';
import { CompanyEntityBranch, getBranchesFuzzyMatchModalOpen, getBranchesFuzzyMatchResults, setBranchesFuzzyMatchModalOpen } from '../store';
import { Icon } from '../../../shared/icon/Icon';
import { Text } from '../../../shared/text/Text';
import { Button } from '../../../shared/button/Button';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Position, PositionModal } from '../../../shared/modal/PositionModal';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { getAllDropdownLists } from '../../dropdown-lists/store';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { OverflowTooltip } from '../../../shared/tooltip';
import { Toggle } from '../../../shared/toggle';

const { green } = styles;

interface BranchInputProps {
    branch: CompanyEntityBranch;
    branchIndex: number;
    onValueChange: (key: keyof CompanyEntityBranch, value: string | null | boolean, branchIndex: number, checkFuzzyMatch?: boolean) => void;
    deleteFunction: (branchIndex: number) => void;
    openBranches: number[];
    showDoraToggle: boolean;
    toggleBranchOpen: (branchIndex: number) => void;
}

export const BranchInput: React.FC<BranchInputProps> = ({ branch, onValueChange, branchIndex, deleteFunction, openBranches, showDoraToggle, toggleBranchOpen }) => {
    const { name, city, country, lei, alternativeIdentifier, inScopeForDora } = branch;

    const sectionOpen = useMemo(() => openBranches.includes(branchIndex), [openBranches, branchIndex]);
    const sectionOpenIcon = sectionOpen ? CaretDown : CaretSide;

    const dispatch = useAppDispatch();
    const fuzzyMatchResults = useAppSelector(getBranchesFuzzyMatchResults);
    const branchFuzzyMatch = useAppSelector(getBranchesFuzzyMatchModalOpen);
    const fuzzyMatchModalOpen = useMemo(() => !isNull(branchFuzzyMatch) && branchFuzzyMatch === branchIndex, [branchFuzzyMatch, branchIndex]);
    const branchInputRef = useRef<HTMLDivElement>(null);
    const fuzzyInputElement = document.getElementById(`add-branch-input-${branchIndex}`);
    const fuzzyInput = fuzzyInputElement?.getBoundingClientRect();
    const inputWidth = fuzzyInput?.width ? fuzzyInput.width - 10 : 0;
    const xPosition = fuzzyInput?.x ? fuzzyInput.x : 0;
    const yPosition = fuzzyInput?.y ? fuzzyInput.y + 65 : 0;
    const fuzzyModalPosition: Position = { x: xPosition, y: yPosition };

    const closeFuzzyMatchModal = useCallback(() => dispatch(setBranchesFuzzyMatchModalOpen(false)), [dispatch]);

    const [, screenHeight] = useWindowResize();
    const maxScrollableHeight = (screenHeight * 0.7 - fuzzyModalPosition.y);

    const toggleFuzzyBranch = useCallback((value: string) => {
        const updatedCity = city === value ? '' : value;
        onValueChange('city', updatedCity, branchIndex);
        setTimeout(() => closeFuzzyMatchModal(), 200);
    }, [branchIndex, city, onValueChange, closeFuzzyMatchModal]);

    const allDropdowns = useAppSelector(getAllDropdownLists);
    const countryDropdownList = allDropdowns.find(({ name }) => name === 'Country') || { options: [] as string[] };
    const countryDropdownOptions = countryDropdownList.options.map(value => ({ value, label: value }));
    const countryValue = countryDropdownOptions.find(({ value }) => value === country) || null;

    const updateDropdownOption = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        onValueChange('country', selected, branchIndex);
    };

    const collapsedBranchDetails = useMemo(() => {
        let details = name;
        if (name !== '' && city !== '') details = `${name}, ${city}`;
        return details || 'No Branch Details Provided';
    }, [name, city]);

    return (
        <div className={styles.branchWrapper} id={`branch-input-${branchIndex}`}>
            <div className={styles.headerWrapper}>
                <div className={styles.branchHeader}>
                    {!sectionOpen &&
                        <OverflowTooltip className={styles.branchDetails} overlayText={collapsedBranchDetails} />
                    }
                </div>
                <div className={styles.branchIcons}>
                    <div onClick={() => toggleBranchOpen(branchIndex)}><Icon icon={sectionOpenIcon} fontSize={14} /></div>
                    <div onClick={() => deleteFunction(branchIndex)}><Icon icon={Delete} fontSize={22} /></div>
                </div>
            </div>
            {sectionOpen &&
                <>
                    <div className={styles.branchInputWrapper}>
                        <Text
                            label='Branch Name'
                            onChange={e => onValueChange('name', e.target.value, branchIndex)}
                            value={name}
                            maxLength={256}
                            placeholder='Name...'
                            marginBottom='0px'
                            testId=''
                        />
                    </div>
                    <div className={styles.branchInputWrapper} id={`add-branch-input-${branchIndex}`} ref={branchInputRef}>
                        <Text
                            label='City'
                            onChange={e => onValueChange('city', e.target.value, branchIndex, true)}
                            value={city}
                            maxLength={256}
                            placeholder='Branch...'
                            marginBottom='5px'
                            testId=''
                        />
                        <Button onClick={() => onValueChange('city', capitaliseStartLetter(city), branchIndex)} label='Add city' disabled={false} />
                    </div>
                    <div className={styles.branchInputWrapper}>
                        <Dropdown
                            label='Country'
                            testId='entity-branch-country'
                            onChange={updateDropdownOption}
                            value={countryValue}
                            options={countryDropdownOptions}
                            marginBottom='5px'
                        />
                    </div>
                    <div className={styles.branchInputWrapper}>
                        <Text
                            label='Legal Entity Identifier (LEI)'
                            testId='entity-branch-lei'
                            onChange={e => onValueChange('lei', e.target.value, branchIndex)}
                            value={lei}
                            placeholder='Branch LEI...'
                            marginBottom='5px'
                        />
                    </div>
                    <div className={styles.branchInputWrapper}>
                        <Text
                            label='Alternative Identifier'
                            testId='entity-branch-alternate-lei'
                            onChange={e => onValueChange('alternativeIdentifier', e.target.value, branchIndex)}
                            value={alternativeIdentifier || ''}
                            placeholder='Alternative Identifier...'
                            marginBottom='5px'
                            disabled={lei !== ''}
                        />
                    </div>
                    {showDoraToggle && <div className={styles.branchInputWrapper}>
                        <div className={styles.toggleWrapper}>
                            <div className={styles.toggleLabel}>Is this branch in scope for DORA?</div>
                            <Toggle
                                checked={inScopeForDora!}
                                onChange={() => onValueChange('inScopeForDora', !inScopeForDora!, branchIndex)}
                            />
                        </div>

                    </div>}
                    <PositionModal
                        isOpen={fuzzyMatchModalOpen}
                        position={fuzzyModalPosition}
                        height='fit-content'
                        width={`${inputWidth}px`}
                        closeModal={closeFuzzyMatchModal}
                        testId=''
                        overflow='hidden'
                    >
                        <div className={styles.fuzzyMatchesModalWrapper}>
                            <Scrollable maxHeight={`${maxScrollableHeight}px`}>
                                {
                                    fuzzyMatchResults.map(({ value }, fuzzyIndex) => {
                                        const branchSelected = value === city;
                                        return (
                                            <div key={fuzzyIndex} className={styles.fuzzyMatchWrapper} onClick={() => toggleFuzzyBranch(value)}>
                                                <div className={styles.fuzzyMatchType}>
                                                    {branchSelected && <Icon color={green} icon={Tick} fontSize={18} />}
                                                </div>
                                                <div className={styles.fuzzyMatchTitle}>{value}</div>
                                            </div>
                                        );
                                    })
                                }
                            </Scrollable>
                        </div>
                    </PositionModal>
                </>
            }
        </div>
    );
};
