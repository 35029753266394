import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isNull, isUndefined } from 'lodash/fp';
import classnames from 'classnames';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState, RawDraftContentState, convertFromHTML, ContentState, convertToRaw, DefaultDraftBlockRenderMap } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import styles from './WYSIWYG.module.scss';

const { grey } = styles;

export const isEmpty = ({ blocks }: RawDraftContentState) => blocks.every(({ text }) => !text.length);

interface WYSIWYGProps {
    content: RawDraftContentState | null;
    updateContent: (content: RawDraftContentState) => void;
    height?: string;
    width?: string;
    borderColor?: string;
    disabled?: boolean;
    toolbarHidden?: boolean;
    showBorder?: boolean;
    placeholder?: string;
    padding?: string;
    editorPadding?: string;
    maxHeight?: string;
    minHeight?: string;
    showWrapperBorder?: boolean;
    isSelected?: boolean;
    getSelectedText?: (selectedText: string) => void;
    isReadOnly?: boolean;
    toolbarOptions?: string[];
    inlineOptions?: string[];
}

export const WYSIWYG: React.FC<WYSIWYGProps> = ({
    content,
    updateContent,
    disabled,
    height = '200px',
    maxHeight = '200px',
    minHeight = '200px',
    borderColor = grey,
    toolbarHidden = false,
    showBorder = true,
    placeholder,
    padding = '5px',
    editorPadding = '5px',
    showWrapperBorder = true,
    isSelected = false,
    getSelectedText,
    isReadOnly = false,
    toolbarOptions = ['inline', 'colorPicker', 'textAlign', 'fontSize', 'list', 'history'],
    inlineOptions = ['bold', 'italic', 'underline', 'strikethrough'],
    width
}) => {

    const [editorState, setEditorState] = useState(!isNull(content) ? EditorState.createWithContent(convertFromRaw(content)) : EditorState.createEmpty());

    const editorHeight = useMemo(() => toolbarHidden ? '100%' : 'calc(100% - 50px)', [toolbarHidden]);
    const wrapperWidth = useMemo(() => width ? width : `calc(100% - ${(parseInt(padding) * 2) + (showWrapperBorder ? 2 : 0)}px)`, [padding, showWrapperBorder, width]);

    const wrapperBorder = useMemo(() => showWrapperBorder ? `1px solid ${borderColor}` : 'none', [showWrapperBorder, borderColor]);

    const selectedText = useMemo(() => {
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();
        const selectedText = currentContentBlock.getText().slice(start, end);
        return selectedText;
    }, [editorState]);

    useEffect(() => {
        if (isSelected && !isUndefined(getSelectedText)) {
            getSelectedText(selectedText);
        }
    }, [isSelected, getSelectedText, selectedText]);

    useEffect(() => {
        if (isReadOnly) {
            if (isNull(content)) {
                setEditorState(EditorState.createEmpty());
            } else {
                setEditorState(EditorState.createWithContent(convertFromRaw(content)));
            }
        }
    }, [isReadOnly, content]);

    const handlePastedText = useCallback((text: string, html: string, editorState: EditorState) => {
        const blockRenderMap = DefaultDraftBlockRenderMap.set('br', { element: 'br' });
        const blocksFromHTML = convertFromHTML(html, undefined, blockRenderMap);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );

        const newState = EditorState.push(editorState, state, 'insert-fragment');
        const contentState = newState.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        setEditorState(newState);
        updateContent(rawContent);
        return true;
    }, [updateContent]);

    const toolbarConfig = useMemo(() => ({
        options: toolbarOptions,
        inline: {
            options: inlineOptions,
            bold: { className: styles.toolbarIcon },
            underline: { className: styles.toolbarIcon },
            italic: { className: styles.toolbarIcon },
            strikethrough: { className: styles.toolbarIcon }
        },
        colorPicker: { className: styles.toolbarIcon },
        textAlign: {
            inDropdown: true,
            className: styles.toolbarIcon,
            dropdownClassName: styles.toolbarIcon
        },
        fontSize: { className: styles.toolbarIcon },
        list: {
            options: ['unordered', 'ordered'],
            unordered: { className: styles.toolbarIcon },
            ordered: { className: styles.toolbarIcon }
        },
        history: {
            undo: { className: styles.toolbarIcon },
            redo: { className: styles.toolbarIcon }
        }
    }), [toolbarOptions, inlineOptions]);

    return (
        <Editor
            wrapperClassName={styles.wrapper}
            wrapperStyle={{ minHeight, height, maxHeight, border: wrapperBorder, padding, width: wrapperWidth }}
            toolbarHidden={toolbarHidden}
            toolbar={toolbarConfig}
            toolbarClassName={styles.toolbar}
            editorClassName={classnames(styles.editor, { [styles.removeBorder]: !showBorder })}
            editorStyle={{ height: editorHeight, padding: editorPadding }}
            placeholder={placeholder}
            onEditorStateChange={setEditorState}
            onContentStateChange={updateContent}
            handlePastedText={handlePastedText}
            editorState={editorState}
            readOnly={disabled}
            spellCheck
        />
    );
};
