import { flatten, isEqual, isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppSelector } from '../../../../hooks/react-redux';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Toggle } from '../../../shared/toggle';
import { riskAssociatedColors } from '../RiskToleranceConfig';
import { getAllDropdownLists } from '../../../admin/dropdown-lists/store';
import { DropdownRiskField, RiskAssociated, RiskAssociatedValue, riskAssociatedLabel } from '../../../admin/risk-tolerance/store';
import styles from './RiskField.module.scss';

const { grey } = styles;

interface DropdownRiskFieldConfigProps {
    riskField: DropdownRiskField;
    riskAssociated: RiskAssociated;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValue) => void;
}

export const DropdownRiskFieldConfig: React.FC<DropdownRiskFieldConfigProps> = ({ riskField, riskAssociated, updateRiskConfig }) => {
    const { riskConfig, dropdownLinked, includeRiskField } = riskField;
    const allDropdownLists = useAppSelector(getAllDropdownLists);

    const allDropdownOptions = useMemo(() => {
        if (dropdownLinked) {
            return allDropdownLists.find(({ dropdownListId }) => dropdownListId.toString() === dropdownLinked)!.options.map(value => ({ value, label: value }));
        }
        return [];
    }, [allDropdownLists, dropdownLinked]);

    const unavailableDropdownOptions = useMemo(() => Object.entries(riskConfig).reduce((acc: string[], [risk, selectedOptions]) => {
        if (parseInt(risk) === riskAssociated) {
            return acc;
        }
        return [...acc, ...selectedOptions];
    }, []), [riskConfig, riskAssociated]);

    const dropdownOptions = useMemo(() => allDropdownOptions.filter(({ value }) => !unavailableDropdownOptions.includes(value)), [allDropdownOptions, unavailableDropdownOptions]);
    const value = useMemo(() => riskConfig[riskAssociated].map(value => ({ value, label: value })), [riskConfig, riskAssociated]);

    const updateDropdownValue = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: string[] = [];
        if (!isNull(dropdownValue)) {
            value = (dropdownValue as DropdownOption[]).map(({ value }) => value);
        }
        updateRiskConfig(riskAssociated, value);
    }, [riskAssociated, updateRiskConfig]);

    const selectRemainingDisabled = useMemo(() => {
        const optionsSelected = flatten(Object.values(riskConfig));
        return isEqual(optionsSelected.length, allDropdownOptions.length) || !includeRiskField;
    }, [allDropdownOptions, riskConfig, includeRiskField]);

    const selectRemaining = useCallback(() => {
        const currentSelected = riskConfig[riskAssociated];
        const remainingOptions = dropdownOptions.map(({ value }) => value).filter(value => !currentSelected.includes(value));
        const includingRemaining = [...currentSelected, ...remainingOptions];
        updateRiskConfig(riskAssociated, includingRemaining);
    }, [riskAssociated, dropdownOptions, riskConfig, updateRiskConfig]);

    return (
        <div className={styles.dropdownWrapper}>
            <Dropdown options={dropdownOptions} value={value} onChange={updateDropdownValue} isMulti isClearable controlBorderColor={grey} disabled={!includeRiskField} />
            <div className={styles.remainingWrapper}>
                <div className={styles.remainingLabel}>Select remaining as <span style={{ color: riskAssociatedColors[riskAssociated], fontWeight: 800, textShadow: `1px 1px ${grey}` }}>"{riskAssociatedLabel[riskAssociated]}"</span></div>
                <Toggle checked={false} disabled={selectRemainingDisabled} onChange={selectRemaining} height={14} width={28} />
            </div>
        </div>
    );
};
