import { all, fork } from 'redux-saga/effects';

import { doraDataManagementSaga } from '../data-management/store/sagas';
import { doraFunctionSaga } from '../functions/store';
import { doraThirdPartySaga } from '../third-party-companies/store';
import { doraMyCompaniesSaga } from '../my-companies/store';
import { doraSupplyChainSaga } from '../supply-chain/store';
import { doraAnalyticsSaga } from '../analytics/store';
import { doraReportingSaga } from '../reporting/store';

export function* doraSaga() {
    yield all([
        fork(doraDataManagementSaga),
        fork(doraFunctionSaga),
        fork(doraThirdPartySaga),
        fork(doraMyCompaniesSaga),
        fork(doraSupplyChainSaga),
        fork(doraAnalyticsSaga),
        fork(doraReportingSaga)
    ]);
}
