import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Icon } from '../../shared/icon/Icon';
import { Link } from '../../shared/icons';
import { Position } from '../../shared/modal/PositionModal';
import styles from './OpinionInstance.module.scss';
import { LinkedOpinionModal } from './LinkedOpinionModal';
import { LinkedOpinion, openOpinionStarted, setUnauthorisedOpinion } from '../my-opinions/store';
import { closeAllOpenFieldSections, editOpinionInstance, getLinkedOpinionModalOpen, toggleLinkedOpinionModal } from './store';

interface LinkedOpinionButtonProps {
    linkedOpinions: LinkedOpinion[];
    disabled: boolean;
    jurisdiction: string;
}

export const LinkedOpinionButton: React.FC<LinkedOpinionButtonProps> = ({ linkedOpinions, disabled, jurisdiction }) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getLinkedOpinionModalOpen);
    const [position, setPosition] = useState<Position | null>(null);
    const openModal = (x: number, y: number) => {
        setPosition({ x , y });
        dispatch(toggleLinkedOpinionModal(true));
    };

    const closeModal = () => dispatch(toggleLinkedOpinionModal(false));

    const linkToOpinion = (linkedOpinion: LinkedOpinion) => {
        const { permittedToView, location, mimeType, opinionId } = linkedOpinion;
        if (permittedToView) {
            closeModal();
            dispatch(editOpinionInstance(false));
            dispatch(openOpinionStarted(location, mimeType, opinionId));
            dispatch(closeAllOpenFieldSections());
        } else {
            dispatch(setUnauthorisedOpinion(linkedOpinion));
        }
    };

    return (
        <>
            <button className={styles.iconButtonWrapper} disabled={disabled} onClick={e => openModal(e.clientX, e.clientY)}>
                <Icon icon={Link} />
            </button>
            <LinkedOpinionModal
                isOpen={isOpen}
                linkedOpinions={linkedOpinions}
                position={position}
                closeModal={closeModal}
                linkToOpinion={linkToOpinion}
                jurisdiction={jurisdiction}
            />
        </>
    );
};
