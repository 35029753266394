import classnames from 'classnames';
import { isNull } from 'lodash/fp';
import React from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { openModalDatasetStarted } from '../instances/store';
import styles from './Fields.module.scss';

interface DatasetLinkProps {
    id: string;
    value: string | null;
    childDatasetId: string;
    datasetId: number;
    parentFieldId: string;
    index: number;
    disabled?: boolean;
    rowId?: string;
    isTable?: boolean;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showClause?: boolean;
    isAgency?: boolean;
    includedInAnnex?: boolean;
    modalInstance?: boolean;
}

export const DatasetLink: React.FC<DatasetLinkProps> = ({
    id,
    value,
    datasetId,
    childDatasetId,
    disabled = false,
    index,
    rowId,
    isTable = false,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showClause = false,
    parentFieldId
}) => {
    const dispatch = useAppDispatch();
    const linkDataset = () => dispatch(openModalDatasetStarted(datasetId, rowId!, index, parentFieldId, childDatasetId, value));

    return (
        <div className={classnames(styles.datasetLinkField, {
            [styles.tableDatasetLink]: isTable,
            [styles.datasetLinkFieldWithClauseLabel]: showClause && isTable
        })} data-testid={`definition-dataset-link-${id}`}>
            <button
                onClick={linkDataset}
                className={classnames(styles.datasetLinkButton, {
                    [styles.updatedDatasetLink]: showFieldUpdated,
                    [styles.newDatasetLink]: showDatasetUpdated,
                })}
                disabled={disabled && isNull(value)}>
                Open
            </button>
        </div>
    );
};
