import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { Bookshelf, PlaybookIcon } from '../../shared/icons';
import { TileSelect, Tile } from '../../shared/tile-select/TileSelect';
import { PlaybookPage, setPlaybookPage } from './store';

export const PlaybookSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectPlaybookPage = useCallback((page: PlaybookPage) => dispatch(setPlaybookPage(page)), [dispatch]);
    const selectCreatePlaybook = useCallback(() => selectPlaybookPage(PlaybookPage.BUILD), [selectPlaybookPage]);
    const selectMyPlaybooks = useCallback(() => selectPlaybookPage(PlaybookPage.MY_PLAYBOOKS), [selectPlaybookPage]);

    const playbookTiles: Tile[] = useMemo(() => [
        {
            label: PlaybookPage.BUILD,
            description: ['Create a new custom playbook'],
            onClick: selectCreatePlaybook,
            testId: 'create-playbook',
            icon: PlaybookIcon
        },
        {
            label: PlaybookPage.MY_PLAYBOOKS,
            description: ['Manage all of your existing playbooks'],
            onClick: selectMyPlaybooks,
            testId: 'my-playbooks',
            icon: Bookshelf
        },
    ], [selectCreatePlaybook, selectMyPlaybooks]);

    return (
        <TileSelect testId='playbook' tiles={playbookTiles} />
    );
};
