import React from 'react';

export const OfficeBuilding: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill={ color || 'currentColor'} d="M105.354,107.1a1.75,1.75,0,0,0,0-3.5h-2.572V41.215a1.749,1.749,0,0,0-1.75-1.75H80.5V22.646a1.75,1.75,0,0,0-1.75-1.75H49.25a1.75,1.75,0,0,0-1.75,1.75V39.465H26.968a1.749,1.749,0,0,0-1.75,1.75V103.6H22.646a1.75,1.75,0,0,0,0,3.5h82.708ZM47.5,103.6H28.718V42.965H47.5Zm21.123,0H59.377V90.7h9.246ZM77,41.215V103.6H72.123V88.951a1.749,1.749,0,0,0-1.75-1.75H57.627a1.749,1.749,0,0,0-1.75,1.75V103.6H51V24.4H77ZM99.282,103.6H80.5V42.965H99.282Z" />
        <path fill={ color || 'currentColor'} d="M34.488,49.633h7.241a1.75,1.75,0,0,0,0-3.5H34.488a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M56.458,31.426h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M34.488,56.891h7.241a1.75,1.75,0,0,0,0-3.5H34.488a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M56.458,38.7h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M34.488,64.147h7.241a1.75,1.75,0,0,0,0-3.5H34.488a1.75,1.75,0,1,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M56.458,45.977h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M34.488,71.405h7.241a1.75,1.75,0,0,0,0-3.5H34.488a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M56.458,53.252h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M34.488,78.663h7.241a1.75,1.75,0,0,0,0-3.5H34.488a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M56.458,60.528h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M34.488,85.921h7.241a1.75,1.75,0,0,0,0-3.5H34.488a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M56.458,67.8h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M34.488,93.179h7.241a1.75,1.75,0,1,0,0-3.5H34.488a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M56.458,75.079h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,1,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M34.488,100.437h7.241a1.75,1.75,0,0,0,0-3.5H34.488a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M86.271,49.633h7.241a1.75,1.75,0,0,0,0-3.5H86.271a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M86.271,56.891h7.241a1.75,1.75,0,0,0,0-3.5H86.271a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M86.271,64.147h7.241a1.75,1.75,0,0,0,0-3.5H86.271a1.75,1.75,0,1,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M86.271,71.405h7.241a1.75,1.75,0,0,0,0-3.5H86.271a1.75,1.75,0,1,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M86.271,78.663h7.241a1.75,1.75,0,0,0,0-3.5H86.271a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M86.271,85.921h7.241a1.75,1.75,0,0,0,0-3.5H86.271a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M86.271,93.179h7.241a1.75,1.75,0,0,0,0-3.5H86.271a1.75,1.75,0,1,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M86.271,100.437h7.241a1.75,1.75,0,0,0,0-3.5H86.271a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M56.458,82.354h4.25a1.75,1.75,0,1,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M67.292,31.426h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M67.292,38.7h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M67.292,45.977h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M67.292,53.252h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M67.292,60.528h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M67.292,67.8h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M67.292,75.079h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
        <path fill={ color || 'currentColor'} d="M67.292,82.354h4.25a1.75,1.75,0,0,0,0-3.5h-4.25a1.75,1.75,0,0,0,0,3.5Z" />
    </svg>
);
