import { set } from 'lodash/fp';

import { ActiveMLDocument, ExtensiveProgress, InitialProgress } from '../auth/notifications/store';

export const initialProgressSteps = Object.values(InitialProgress).filter(progressString => typeof progressString === 'number') as number[];
export const extensiveProgressSteps = Object.values(ExtensiveProgress).filter(progressString => typeof progressString === 'number') as number[];

export const initialProgressWording = {
    [InitialProgress.FAILED]: 'Something went wrong',
    [InitialProgress.DOCUMENT_UPLOADED]: 'OCR\'ing document...',
    [InitialProgress.TEXTRACT_OCR_DOCUMENT_COMPLETE_AI]: 'Classifying document with AI...',
    [InitialProgress.TEXTRACT_OCR_DOCUMENT_COMPLETE]: 'Finalising document upload...',
    [InitialProgress.INITIAL_CLASSIFICATION_COMPLETE]: 'Cross referencing LEI Database...',
    [InitialProgress.CROSS_REFERENCING_LEI_DATABASE_COMPLETE]: 'Finalising document upload...',
    [InitialProgress.INTIAL_DOCUMENT_PROCESSING_COMPLETE]: 'Initial document upload complete'
};

export const extensiveProgressWording = {
    [ExtensiveProgress.FAILED]: 'Something went wrong',
    [ExtensiveProgress.QUERIES_AND_INSTANCE_COLLECTED]: 'Querying document...',
    [ExtensiveProgress.EXTENSIVE_QUERIES_RECEIVED]: 'Post processing results...',
    [ExtensiveProgress.ANNEX_TABLE_EXTRACTION_COMPLETE]: 'Post processing results...',
    [ExtensiveProgress.PARTIAL_POST_PROCESSING_COMPLETE]: 'Post processing results...',
    [ExtensiveProgress.FURTHER_POST_PROCESSING_COMPLETE]: 'Populating Ark51 datamap...',
    [ExtensiveProgress.ALL_POST_PROCESSING_COMPLETE]: 'Extensive data extraction complete'
};

export const setProgressString = (activeMLDocument: ActiveMLDocument) => {
    const progressString = activeMLDocument.initialUpload ? initialProgressWording[activeMLDocument.progress as InitialProgress] : extensiveProgressWording[activeMLDocument.progress as ExtensiveProgress];
    return set('progressString', progressString, activeMLDocument);
};
