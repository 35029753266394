import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Button } from '../../shared/button/Button';
import { PlusButton } from '../../shared/button/PlusButton';
import { TableTabs, Tabs } from '../../shared/table/TableTabs';
import styles from './DatasetBuilder.module.scss';
import { DatasetBuilderModal } from './DatasetBuilderModal';
import { DefinitionBuilder } from './DefinitionBuilder';
import { DefinitionTypeSelector } from './DefinitionTypeSelector';
import { PublishModal } from './PublishModal';
import { PublishedDatasetsTable } from './PublishedDatasetsTable';
import { DatasetView, closeDatasetBuilder, getDatasetDefinition, getModalOpen, openDatasetBuilder, toggleDatasetPreview, toggleDatasetView, togglePublishModal } from './store';

interface DatasetBuilderProps {
    tabs: Tabs[];
}

export const DatasetBuilder: React.FC<DatasetBuilderProps> = ({ tabs }) => {
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector(getModalOpen);
    const datasetDefinition = useAppSelector(getDatasetDefinition);

    const openBuilder = () => dispatch(openDatasetBuilder());
    const closeBuilder = () => dispatch(closeDatasetBuilder());
    const togglePreview = () => dispatch(toggleDatasetPreview());
    const openConfirmPublish = () => dispatch(togglePublishModal(true));
    const configureDatasets = () => dispatch(toggleDatasetView(DatasetView.DOCUMENTS_LIST));

    return (
        <div className={styles.datasetsWrapper} data-testid='dataset-builder-wrapper'>
            <div className={styles.datasetsTitleContainer}>
                <div className={styles.datasetsTitleButtonWrapper}>
                    <div className={styles.datasetsTitle} data-testid='published-datasets-title'>Published Datasets</div>
                    <PlusButton onClick={openBuilder} fontSize={22} testId='new-dataset-builder' />
                </div>
                <Button label='Configure Datasets' onClick={configureDatasets} />
            </div>
            <TableTabs
                tabs={tabs}
                testId='standard-datasets'
            />
            <PublishedDatasetsTable />
            <DatasetBuilderModal
                isOpen={modalOpen}
                closeModal={closeBuilder}
                confirm={openConfirmPublish}
                showButtons={!!datasetDefinition}
                closeOnOverlayClick={!datasetDefinition}
                togglePreview={togglePreview}
                confirmLabel='Publish'
                datasetDefinition={datasetDefinition}
            >
                {datasetDefinition === null && <DefinitionTypeSelector />}
                {datasetDefinition &&
                    <DefinitionBuilder datasetDefinition={datasetDefinition} />}
            </DatasetBuilderModal>
            <PublishModal />
        </div>
    );
};
