import { isEqual, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Toggle } from '../../../shared/toggle';
import { OverflowTooltip } from '../../../shared/tooltip';
import { SignOffConversationIcon } from '../sign-off';
import { getFieldSignOffNotes, getIsFirstTimelineEntry, updateSingleToggleValue } from '../store';
import { AgreementCoverage, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

const { lightGrey, amethyst, grey, french } = styles;

interface RepoAgreementCoverageProps {
    agreementCoverage: AgreementCoverage;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const RepoAgreementCoverageSection: React.FC<RepoAgreementCoverageProps> = ({ agreementCoverage, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const isFirstTimelineEntry = useAppSelector(getIsFirstTimelineEntry);
    const sectionId = 'agreementCoverage';
    const { standardOptions } = agreementCoverage;

    const updateValue = useCallback((fieldId: 'standardOptions', value: boolean, index: number) => dispatch(updateSingleToggleValue(sectionId, fieldId, index, value)), [dispatch]);

    const fieldUpdatedByInstance = useCallback((fieldId: 'standardOptions', index: number) => !!fieldsUpdated.find(field => isEqual({ fieldId: field.fieldId, sectionId: field.sectionId, index: field.index }, { fieldId, sectionId, index })), [fieldsUpdated, sectionId]);
    const getIsDisabled = useCallback((fieldId: 'standardOptions', index: number) => !isEditing && !(isUpdating && fieldUpdatedByInstance(fieldId, index)), [isUpdating, isEditing, fieldUpdatedByInstance]);
    const shouldShowFieldUpdated = useCallback((fieldId: 'standardOptions', index: number) => fieldUpdatedByInstance(fieldId, index) && !isUpdating && !isEditing && !isFirstTimelineEntry, [isUpdating, isEditing, fieldUpdatedByInstance, isFirstTimelineEntry]);

    const disabled = !isEditing && !isUpdating;

    const updateAll = (checked: boolean) => {
        standardOptions.forEach((_, index) => updateValue('standardOptions', checked, index));
    };

    const getOptionWrapperStyle = useCallback((index: number, totalEntries = standardOptions.length) => {
        const numberOfLastRowEntries = totalEntries % 2 || 2;
        const lastRowEntries = Array(numberOfLastRowEntries).fill(totalEntries - numberOfLastRowEntries).map((val, i) => val + i);
        const isLastRow = lastRowEntries.includes(index);
        const isFirstColumn = ((index + 1) % 2) === 1;
        if (!isLastRow) {
            return !isFirstColumn ? { width: 'calc((100% / 2) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}`, borderBottom: `1px solid ${lightGrey}` } : { width: 'calc((100% / 2) - 10px)', borderBottom: `1px solid ${lightGrey}` };
        }
        return !isFirstColumn ? { width: 'calc((100% / 2) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}` } : { width: 'calc((100% / 2) - 10px)' };
    }, [standardOptions.length]);

    const boxShadowColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return grey;
    }, []);

    const onColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return french;
    }, []);

    const allSelected = useMemo(() => standardOptions.map(({ value }) => value).every(value => !!value), [standardOptions]);

    const signOffConversation = useAppSelector(getFieldSignOffNotes(sectionId));

    return (
        <SectionWrapper id={sectionId} label='Agreement Coverage' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.agreementCoverageSectionWrapper}>
                <div className={styles.agreementCoverageWrapper}>
                    <div className={styles.agreementCoverageHeaderWrapper}>
                        <div className={styles.selectAllWrapper}>
                            <div className={styles.selectAllLabel}>Select All</div>
                            <Toggle onChange={updateAll} checked={allSelected} disabled={disabled} />
                        </div>
                        {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={sectionId} />}
                    </div>
                    <div className={styles.agreementCoverageOptionsWrapper} data-testid={'agreement-coverage-standard-options-wrapper'}>
                        {standardOptions.map(({ value, label }, index) => {
                            const fieldId = 'standardOptions';
                            const isDisabled = getIsDisabled(fieldId, index);
                            const showFieldUpdated = shouldShowFieldUpdated(fieldId, index);
                            const optionWrapperStyle = getOptionWrapperStyle(index);
                            return (
                                <div className={styles.toggleOptionWrapper} key={index} style={optionWrapperStyle}>
                                    <div className={styles.agreementCoverageLabel}>
                                        <OverflowTooltip overlayText={label} trigger='click' />
                                    </div>
                                    <Toggle
                                        onChange={checked => updateValue(fieldId, checked, index)}
                                        checked={value}
                                        disabled={isDisabled}
                                        boxShadowColour={boxShadowColour(showFieldUpdated)}
                                        onColour={onColour(showFieldUpdated)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
};
