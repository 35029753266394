import React from 'react';
import { createRoot } from 'react-dom/client';

import { store, history } from './store/configureStore';
import Main from './Main';

import './index.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const root = createRoot(document.getElementById('root')!);

root.render(
    <Main
        store={store}
        history={history}
    />
);
