import React from 'react';

export const SmileyNeutral: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color, fill }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={width || '1em'} height={height || '1em'} viewBox='0 0 256 256'>
        <g>
            <rect width='256' height='256' fill='none' />
            <circle r='96' opacity='0.2' cy='128' cx='128' />
            <circle strokeWidth='16' strokeMiterlimit='10' stroke={color || 'currentColor'} r='96' fill={fill} cy='128' cx='128' />
            <circle r='12' cy='108' cx='92' fill={color || 'currentColor'} />
            <circle r='12' cy='108' cx='164' fill={color || 'currentColor'} />
            <line y2='160' y1='160' x2='168' x1='88' strokeWidth='16' strokeLinejoin='round' strokeLinecap='round' stroke={color || 'currentColor'} fill='none' />
        </g>
    </svg>
);
