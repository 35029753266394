import React from 'react';

import styles from './Radio.module.scss';

interface RadioProps {
    checked: boolean;
    onChange: () => void;
    onClick: () => void;
    testId?: string;
    showWarning: boolean
    warning?: string;
    label?: string;
    fontSize?: string;
}

export const Radio: React.FC<RadioProps> = ({ checked, onChange, onClick, testId, showWarning = false, warning, label, fontSize = '12px' }) => (
    <div className={styles.radioButtonWrapper} data-testid={`${testId}-radio-button-wrapper`}>
        {showWarning && <div className={styles.radioWarningLabel} style={{ fontSize }}>{warning}</div>}
        <div className={styles.radioLabelWrapper}>
            <div className={styles.radioButtonLabel} style={{ fontSize }}>{label}</div>
            <input
                type='radio'
                checked={checked}
                onChange={onChange}
                onClick={onClick}
                data-testid={`${testId}-radio-button`}
            />
        </div>
    </div>
);
