import { toast } from 'react-toastify';
import { put, takeEvery, all, fork, select, call, takeLeading, debounce } from 'redux-saga/effects';
import { isEqual } from 'lodash/fp';

import { fetchAllAgreementTypes, fetchAllDocumentGroups, fetchAllDocumentNames, fetchAvailableDocumentNames, updateAgreementTypes, updateDocumentName, upsertClientDocumentGroup, deleteClientDocumentGroup, fetchMLReadyAgreementTypeIds } from '../../../../services/document';
import { Client, getAllClients } from '../../clients/store';
import { fetchAllAgreementTypesFailed, fetchAllAgreementTypesSuccessful, fetchAllDocumentNamesFailed, fetchAllDocumentNamesSuccessful, fetchAvailableDocumentNamesFailed, fetchAvailableDocumentNamesSuccessful, fetchMLReadyAgreementTypeIdsFailed, fetchMLReadyAgreementTypeIdsSuccessful, setAgreementTypesHaveUpdated, toggleClientDatasetConfirmationModal, updateAgreementTypesFailed, updateAgreementTypesSuccessful, updateDocumentNameFailed, updateDocumentNameSuccessful, updateValue, fetchAllDocumentGroupsFailed, fetchAllDocumentGroupsSuccessful, saveClientDocumentGroupFailed, saveClientDocumentGroupSuccessful, fetchAllDocumentGroupsStarted, deleteClientDocumentGroupFailed, deleteClientDocumentGroupSuccessful } from './actions';
import { getAgreementTypesHaveUpdated, getAllAgreementTypes, getCurrentAgreementTypes, getOpenDocumentName, getSelectedDocumentGroup } from './selectors';
import { DocumentNameDB, DocumentNameActionTypes, ClientDocumentGroup, AgreementTypeDB, CurrentAgreementType } from './types';

export function* attemptUpdateDocumentName() {
    try {
        const documentName: DocumentNameDB = yield select(getOpenDocumentName);
        const documentNames: DocumentNameDB[] = yield call(updateDocumentName, documentName);
        yield put(updateDocumentNameSuccessful(documentNames));
        toast('Successfully updated document.');
    } catch (e) {
        yield put(updateDocumentNameFailed((e as Error).message));
        toast.error('Unable to save the document. Please try again.');
    }
}

export function* attemptFetchAllDocumentNames() {
    try {
        const documentNames: DocumentNameDB[] = yield call(fetchAllDocumentNames);
        yield put(fetchAllDocumentNamesSuccessful(documentNames));
    } catch (e) {
        yield put(fetchAllDocumentNamesFailed((e as Error).message));
        toast.error('Unable to fetch the latest list of document names. Please try again.');
    }
}

function* updateDocumentNameWatcher() {
    yield takeEvery(DocumentNameActionTypes.UPDATE_DOCUMENT_NAME_STARTED, attemptUpdateDocumentName);
}

function* fetchAllDocumentNamesWatcher() {
    yield takeEvery(DocumentNameActionTypes.FETCH_ALL_DOCUMENT_NAMES_STARTED, attemptFetchAllDocumentNames);
}

export function* attemptFetchAllAgreementTypes() {
    try {
        const agreementTypes: AgreementTypeDB[] = yield call(fetchAllAgreementTypes);
        yield put(fetchAllAgreementTypesSuccessful(agreementTypes));
    } catch (e) {
        yield put(fetchAllAgreementTypesFailed((e as Error).message));
        toast.error('Unable to fetch the latest list of agreement types. Please try again.');
    }
}

function* fetchAllAgreementTypesWatcher() {
    yield takeLeading(DocumentNameActionTypes.FETCH_ALL_AGREEMENT_TYPES_STARTED, attemptFetchAllAgreementTypes);
}

export function* attemptFetchAvailableDocumentNames() {
    try {
        const documentNames: DocumentNameDB[] = yield call(fetchAvailableDocumentNames);
        yield put(fetchAvailableDocumentNamesSuccessful(documentNames));
    } catch (e) {
        yield put(fetchAvailableDocumentNamesFailed((e as Error).message));
        toast.error('Unable to fetch the latest list of document names. Please try again.');
    }
}

function* fetchAvailableDocumentNamesWatcher() {
    yield takeEvery(DocumentNameActionTypes.FETCH_AVAILABLE_DOCUMENT_NAMES_STARTED, attemptFetchAvailableDocumentNames);
}

export function* updateDocumentNameClientIds({ payload }: ReturnType<typeof toggleClientDatasetConfirmationModal>) {
    if (payload) {
        const allClients: Client[] = yield select(getAllClients);
        const allClientIds = allClients.map(({ clientId }) => clientId!);
        yield put(updateValue('clientIds', allClientIds));
    }
}

function* updateDocumentNameClientIdsWatcher() {
    yield takeEvery(DocumentNameActionTypes.TOGGLE_CLIENT_DATASET_CONFIRMATION_MODAL_OPEN, updateDocumentNameClientIds);
}

export function* attemptFetchAllDocumentGroups() {
    try {
        const documentGroups: ClientDocumentGroup[] = yield call(fetchAllDocumentGroups);
        yield put(fetchAllDocumentGroupsSuccessful(documentGroups));
    } catch (e) {
        yield put(fetchAllDocumentGroupsFailed((e as Error).message));
        toast.error('Unable to fetch the latest list of document groups. Please try again.');
    }
}

function* fetchAllDocumentGroupsWatcher() {
    yield takeEvery(DocumentNameActionTypes.FETCH_ALL_DOCUMENT_GROUPS_STARTED, attemptFetchAllDocumentGroups);
}

export function* attemptUpsertClientDocumentGroup() {
    try {
        const selectedDocumentGroup: ClientDocumentGroup = yield select(getSelectedDocumentGroup);
        yield call(upsertClientDocumentGroup, selectedDocumentGroup);
        yield put(saveClientDocumentGroupSuccessful());
        yield put(fetchAllDocumentGroupsStarted());
    } catch (e) {
        yield put(saveClientDocumentGroupFailed((e as Error).message));
        toast.error('Unable to save document group. Please try again.');
    }
}

function* upsertClientDocumentGroupWatcher() {
    yield takeEvery(DocumentNameActionTypes.SAVE_CLIENT_DOCUMENT_GROUP_STARTED, attemptUpsertClientDocumentGroup);
}

export function* attemptDeleteClientDocumentGroup() {
    try {
        const { clientDocumentGroupId } = yield select(getSelectedDocumentGroup);
        yield call(deleteClientDocumentGroup, { clientDocumentGroupId });
        yield put(deleteClientDocumentGroupSuccessful());
        yield put(fetchAllDocumentGroupsStarted());
    } catch (e) {
        yield put(deleteClientDocumentGroupFailed((e as Error).message));
        toast.error('Unable to delete document group. Please try again.');
    }
}

function* deleteClientDocumentGroupWatcher() {
    yield takeEvery(DocumentNameActionTypes.DELETE_CLIENT_DOCUMENT_GROUP_STARTED, attemptDeleteClientDocumentGroup);
}
export function* checkAgreementTypesUpdated() {
    const currentAgreementTypes: CurrentAgreementType[] = yield select(getCurrentAgreementTypes);
    const savedAgreementTypes: AgreementTypeDB[] = yield select(getAllAgreementTypes);
    const haveUpdated = !isEqual(currentAgreementTypes, savedAgreementTypes);
    yield put(setAgreementTypesHaveUpdated(haveUpdated));
}

function* checkAgreementTypesUpdatedWatcher() {
    yield debounce(200, [DocumentNameActionTypes.ADD_NEW_AGREEMENT_TYPE, DocumentNameActionTypes.REMOVE_NEW_AGREEMENT_TYPE, DocumentNameActionTypes.UPDATE_AGREEMENT_TYPE_NAME], checkAgreementTypesUpdated);
}

export function* attemptUpdateAgreementTypes() {
    try {
        const currentAgreementTypes: CurrentAgreementType[] = yield select(getCurrentAgreementTypes);
        const savedAgreementTypes: AgreementTypeDB[] = yield select(getAllAgreementTypes);
        const haveUpdated: boolean = yield select(getAgreementTypesHaveUpdated);
        if (haveUpdated) {
            const updatedAgreementTypes = currentAgreementTypes.filter((agreementType, currentIndex) => {
                const savedAgreementType = savedAgreementTypes.find((_, i) => i === currentIndex);
                return !isEqual(agreementType, savedAgreementType);
            });
            if (updatedAgreementTypes.length > 0) {
                const agreementTypes: AgreementTypeDB[] = yield call(updateAgreementTypes, { updatedAgreementTypes });
                yield put(updateAgreementTypesSuccessful(agreementTypes));
                toast('Successfully updated Agreement Types.');
            }
        }
    } catch (e) {
        yield put(updateAgreementTypesFailed((e as Error).message));
    }
}

function* updateAgreementTypesWatcher() {
    yield takeLeading(DocumentNameActionTypes.UPDATE_AGREEMENT_TYPES_STARTED, attemptUpdateAgreementTypes);
}

export function* attemptFetchMLReadyAgreementTypes() {
    try {
        const agreementTypeIds: number[] = yield call(fetchMLReadyAgreementTypeIds);
        yield put(fetchMLReadyAgreementTypeIdsSuccessful(agreementTypeIds));
    } catch (e) {
        yield put(fetchMLReadyAgreementTypeIdsFailed((e as Error).message));
    }
}

function* fetchMLReadyAgreementTypesWatcher() {
    yield takeEvery(DocumentNameActionTypes.FETCH_ML_READY_AGREEMENT_TYPES_STARTED, attemptFetchMLReadyAgreementTypes);
}
export function* documentNameSaga() {
    yield all([
        fork(updateDocumentNameWatcher),
        fork(fetchAllDocumentNamesWatcher),
        fork(fetchAvailableDocumentNamesWatcher),
        fork(updateDocumentNameClientIdsWatcher),
        fork(fetchAllDocumentGroupsWatcher),
        fork(upsertClientDocumentGroupWatcher),
        fork(deleteClientDocumentGroupWatcher),
        fork(fetchAllAgreementTypesWatcher),
        fork(checkAgreementTypesUpdatedWatcher),
        fork(updateAgreementTypesWatcher),
        fork(fetchMLReadyAgreementTypesWatcher)
    ]);
}
