import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import styles from './HomeWrapper.module.scss';
import { getUserRole } from '../auth/login/store';
import { adminRoles } from '../constants/permittedRoles';
import { Analytics, Document, Export, Search, WarningSign } from '../shared/icons';
import { ModalHeader } from '../shared/modal/ModalHeader';
import { ConfirmationModal } from '../shared/modal/ConfirmationModal';
import { ArkModule, enquireModuleStarted, getUnauthorisedModule, getUnauthorisedModuleModalOpen, toggleUnauthorisedModuleModalOpen } from '../home/store';
import { Icon } from '../shared/icon/Icon';

const availableDocumentFeatures = [
    { label: 'Storage of Documents', icon: Document },
    { label: 'Searching Documents', icon: Search },
    { label: 'Reporting', icon: Export },
    { label: 'Analytics', icon: Analytics }
];

const availableOpinionFeatures = [
    { label: 'Access to Opinion Summaries', icon: Document },
    { label: 'Reporting', icon: Export },
    { label: 'Analytics', icon: Analytics }
];

const availableDoraFeatures = [
    { label: 'Data Management', icon: Document }
];

export const UnauthorisedModuleModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const userRole = useAppSelector(getUserRole);
    const isAdminUser = adminRoles.includes(userRole!);
    const isOpen = useAppSelector(getUnauthorisedModuleModalOpen);
    const unauthorisedModule = useAppSelector(getUnauthorisedModule);

    const messageOne = useMemo(() => isAdminUser ? 'If you would like to know more about this module and the cost associated with accessing this content.' : 'If you think this module would be of interest to your company, please reach out to your team administrator', [isAdminUser]);
    const messageTwo = useMemo(() => isAdminUser ? 'Please click the \'Enquire\' button below to send an enquiry email and someone from our team will be in touch' : 'They can use this page to send an enquiry via the button below and someone from our team will be in touch', [isAdminUser]);

    const sendEnquiry = useCallback(() => { dispatch(enquireModuleStarted()); }, [dispatch]);
    const closeModal = useCallback(() => { dispatch(toggleUnauthorisedModuleModalOpen(false)); }, [dispatch]);

    const availableFeatures = useMemo(() => {
        switch (unauthorisedModule) {
            case ArkModule.DOCUMENT:
                return availableDocumentFeatures;
            case ArkModule.OPINION:
                return availableOpinionFeatures;
            case ArkModule.DORA:
                return availableDoraFeatures;
            default:
                return [];
        }
    }, [unauthorisedModule]);

    if (!isOpen || isNull(unauthorisedModule)) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen
            closeModal={closeModal}
            confirmLabel='Enquire'
            testId='unauthorised-module'
            confirm={sendEnquiry}
            showConfirm={isAdminUser}
            zIndex={20}
        >
            <ModalHeader label='Unauthorised' icon={WarningSign} />
            <div className={styles.unauthorisedModuleWrapper}>
                <div className={styles.warningHeader}>You do not currently have access to this module</div>
                <div className={styles.unauthorisedModule}>
                    <div className={styles.featuresTitle}>There are a number of features available with this module that could help you to better manage your documentation</div>
                    {availableFeatures.map(({ label, icon }) => (
                        <div key={label} className={styles.availableFeature}>
                            <Icon icon={icon} fontSize={40} />
                            <div className={styles.availableFeatureLabel}>{label}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.unauthorisedModuleAccess}>{messageOne}</div>
                <div className={styles.unauthorisedModuleAccess}>{messageTwo}</div>
            </div>
        </ConfirmationModal>
    );
};
