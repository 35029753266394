import classnames from 'classnames';
import React, { useMemo } from 'react';
import Select from 'react-select';

import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { customStyles, DropdownOptions } from '../../shared/dropdown/Dropdown';
import { CustomTooltip } from '../../shared/tooltip';
import styles from './Fields.module.scss';

interface DocumentFieldProps {
    id: string;
    value: string[] | null | undefined;
    isTable?: boolean;
    showClause?: boolean;
    includedInAnnex?: boolean;
    modalInstance?: boolean;
}

export const DocumentField: React.FC<DocumentFieldProps> = ({ id, value, showClause = false, isTable = false }) => {
    const mappedValue: DropdownOptions = value?.map(value => ({ value, label: capitaliseStartLetter(value) }));

    const isMulti = useMemo(() => !!value && value.length > 1, [value]);

    const dropdownTooltip = useMemo(() => {
        if (!value) {
            return null;
        }
        return value[0];
    }, [value]);

    return (
        <CustomTooltip overlayText={dropdownTooltip} trigger='click'>
            <div
                data-testid={`definition-document-${id}`}
                className={classnames(styles.dropdownFieldWrapper, { [styles.withClauseLabel]: showClause && isTable })}
            >
                <Select
                    className={styles.dropdownField}
                    classNamePrefix='ark-dropdown'
                    isDisabled
                    value={mappedValue}
                    isMulti={isMulti}
                    styles={customStyles}
                    isClearable={false}
                    hideIndicator
                    minControlHeight='38px'
                    fontWeight={500}
                />
            </div>
        </CustomTooltip>
    );
};
