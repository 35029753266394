import { flattenDeep } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { useWindowResize } from '../../hooks/useWindowResize';
import { ClientFeaturePermission, FeaturePermission } from '../admin/users/store';
import { getClientHasFeaturePermission, getUser, getUserHasFeaturePermission, getUserHomeScreenConfig } from '../auth/login/store';
import { resetDatasetInstance } from '../datasets/instances/store';
import { getDocumentDetailsModalOpen, resetDocumentInstance } from '../documents/my-documents/store';
import { DocumentDetailsModal } from '../documents/my-documents/upload/DocumentDetailsModal';
import { UploadDocumentsModal } from '../documents/my-documents/upload/UploadDocumentsModal';
import { resetOpinionInstance } from '../opinions/instances/store';
import { OpinionsView, resetAllOpinions } from '../opinions/my-opinions/store';
import { Add, Bookshelf, Document, Export, Form, MultiAnalytics, Opinion, OpinionAnalytics, OpinionReporting, Search } from '../shared/icons';
import { Spinner } from '../shared/spinner/Spinner';
import { DocumentSpreadChart } from './DocumentSpeadChart';
import { EntitySpreadChart } from './EntitySpreadChart';
import styles from './Home.module.scss';
import { HomeButtonTile, HomeButtonTileAdd } from './HomeButton';
import { MyAttestationProgress } from './MyAttestationProgressChart';
import { ArkModule, HomePageTile, setUnauthorisedModule } from './store';

const { grey } = styles;

interface AvailableTile {
    id: HomePageTile;
    tile: JSX.Element | null;
}

interface HomePageTileProps extends AvailableTile {
    columnId: string;
    marginBottom?: string;
}

export const Home: React.FC = () => {
    const dispatch = useAppDispatch();
    const documentDetailsModalOpen = useAppSelector(getDocumentDetailsModalOpen);
    const homeScreenConfig = useAppSelector(getUserHomeScreenConfig);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [tileDimensions, setTileDimensions] = useState<undefined | { height: number; width: number; }>(undefined);
    const [screenWidth, screenHeight] = useWindowResize();
    const user = useAppSelector(getUser);

    const hasDocumentModulePermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DOCUMENT_MODULE]));
    const hasOpinionModulePermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.OPINION_MODULE]));
    const hasUploadDocumentPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.UPLOAD_DOCUMENTS]));

    const handleUnauthorisedDocumentModuleClick = useCallback(() => hasDocumentModulePermission ? undefined : dispatch(setUnauthorisedModule(ArkModule.DOCUMENT)), [dispatch, hasDocumentModulePermission]);
    const handleUnauthorisedOpinionModuleClick = useCallback(() => hasOpinionModulePermission ? undefined : dispatch(setUnauthorisedModule(ArkModule.OPINION)), [dispatch, hasOpinionModulePermission]);
    const defaultOpinionView = useMemo(() => user?.userDefaults?.opinionPageView === OpinionsView.TABLE ? 'table' : 'map', [user]);

    const availableTiles: AvailableTile[] = useMemo(() => [
        {
            id: HomePageTile.ADD_DOCUMENTS,
            tile: <HomeButtonTileAdd label='Upload' icon={Add} testId='upload-document' disabled={!hasDocumentModulePermission || !hasUploadDocumentPermission} onDisabledClick={handleUnauthorisedDocumentModuleClick} />
        },
        {
            id: HomePageTile.MY_DOCUMENTS,
            tile: <HomeButtonTile path='documents/my-documents' label='My Documents' icon={Document} testId='my-documents' disabled={!hasDocumentModulePermission} onDisabledClick={handleUnauthorisedDocumentModuleClick} />
        },
        {
            id: HomePageTile.SEARCH_DOCUMENTS,
            tile: <HomeButtonTile path='documents/search' label='Search' icon={Search} testId='search-documents' fill={grey} disabled={!hasDocumentModulePermission} onDisabledClick={handleUnauthorisedDocumentModuleClick} />
        },
        {
            id: HomePageTile.DOCUMENT_SPREAD,
            tile: <DocumentSpreadChart dimensions={tileDimensions} analyticsSpinner={showSpinner} />
        },
        {
            id: HomePageTile.ENTITY_SPREAD,
            tile: <EntitySpreadChart dimensions={tileDimensions} analyticsSpinner={showSpinner} />
        },
        {
            id: HomePageTile.MY_ATTESTATIONS,
            tile: <MyAttestationProgress dimensions={tileDimensions} analyticsSpinner={showSpinner} />
        },
        {
            id: HomePageTile.MY_OPINIONS,
            tile: <HomeButtonTile disabled={!hasOpinionModulePermission} onDisabledClick={handleUnauthorisedOpinionModuleClick} path={`opinions/my-opinions/${defaultOpinionView}`} label='My Opinions' icon={Opinion} testId='my-opinions' />
        },
        {
            id: HomePageTile.OPINION_ANALYTICS,
            tile: <HomeButtonTile disabled={!hasOpinionModulePermission} onDisabledClick={handleUnauthorisedOpinionModuleClick} path='opinions/analytics' label='Opinion Analytics' icon={OpinionAnalytics} testId='opinions-analytics' />
        },
        {
            id: HomePageTile.DOCUMENT_ANALYTICS,
            tile: <HomeButtonTile disabled={!hasDocumentModulePermission} onDisabledClick={handleUnauthorisedDocumentModuleClick} path='documents/analytics' label='Document Analytics' icon={MultiAnalytics} testId='documents-analytics' />
        },
        {
            id: HomePageTile.OPINION_REPORTING,
            tile: <HomeButtonTile disabled={!hasOpinionModulePermission} onDisabledClick={handleUnauthorisedOpinionModuleClick} path='opinions/reporting' label='Opinion Reporting' icon={OpinionReporting} testId='opinions-reporting' />
        },
        {
            id: HomePageTile.DOCUMENT_REPORTING,
            tile: <HomeButtonTile disabled={!hasDocumentModulePermission} onDisabledClick={handleUnauthorisedDocumentModuleClick} path='documents/reporting' label='Document Reporting' icon={Export} testId='documents-reporting' />
        },
        {
            id: HomePageTile.PLAYBOOKS,
            tile: <HomeButtonTile disabled={false} path='playbook' label='Playbooks' icon={Bookshelf} testId='playbook' />
        },
        {
            id: HomePageTile.CLAUSE_LIBRARY,
            tile: <HomeButtonTile disabled={false} path='clause-library' label='Clause Library' icon={Form} testId='clause-library' />
        }
    ], [showSpinner, tileDimensions, hasDocumentModulePermission, handleUnauthorisedDocumentModuleClick, hasOpinionModulePermission, handleUnauthorisedOpinionModuleClick, hasUploadDocumentPermission, defaultOpinionView]);

    const analyticsCharts: HomePageTileProps[] = useMemo(() => flattenDeep(Object.entries(homeScreenConfig).map(([columnId, tiles]) => tiles.map((tileId, index) => {
        const tile = availableTiles.find(({ id }) => id === tileId)?.tile || null;
        return { tile, id: tileId, marginBottom: index === 0 ? '20px' : undefined, columnId };
    }))), [homeScreenConfig, availableTiles]);

    const columns = useMemo(() => ['column-1', 'column-2', 'column-3'], []);
    const getAnalyticsByColumn = useCallback((colId: string) => analyticsCharts.filter(({ columnId }) => columnId === colId), [analyticsCharts]);

    useEffect(() => {
        setShowSpinner(true);
        if (screenWidth && screenHeight) {
            const width = (screenWidth - 80) / 3;
            const height = (screenHeight - 190) / 2;
            setTileDimensions({ height, width });
            setTimeout(() => setShowSpinner(false), 50);
        }
    }, [screenWidth, screenHeight, setShowSpinner]);

    // Added to clear everything related to dataset instance in the store in case a document selected has no dataset linked
    useEffect(() => {
        dispatch(resetDatasetInstance());
        dispatch(resetDocumentInstance());
        dispatch(resetAllOpinions());
        dispatch(resetOpinionInstance());
    }, [dispatch]);

    if (showSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.tilesWrapper}>
                {columns.map(column => (
                    <div key={column} className={styles.tilesColumnWrapper}>
                        {getAnalyticsByColumn(column).map(({ id, tile, marginBottom }) => (
                            <div className={styles.tileWrapper} style={{ marginBottom }} key={id}>
                                {tile}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <DocumentDetailsModal isOpen={documentDetailsModalOpen} />
            <UploadDocumentsModal />
        </div>
    );
};
