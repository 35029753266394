import { isEqual, uniq } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { OpenFieldType } from '../../../datasets/instances/store';
import { DatasetField, DatasetFieldType, DatasetSection, FormDatasetDefinition, SingleDatasetField, isGroupField } from '../../../datasets/store';
import { RadioButton } from '../../../shared/button/RadioButton';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide, Document, Tick } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { InformationTooltip, TooltipList } from '../../../shared/tooltip';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';
import { DocumentNameDB } from '../../documents/store';
import styles from '../MyDatasets.module.scss';
import { HiddenFields, HiddenSection, MyDatasetsOpenFieldSection, MyDatasetsOpenSection, addOpenFieldSection, getOpenFieldsAndSections, removeOpenFieldSection, toggleHiddenField } from '../store';
import { GroupField } from './fields/GroupField';
import { MultiToggleField } from './fields/MultiToggleField';
import { SingleField } from './fields/SingleField';

export const getHiddenDocumentsTooltip = (hiddenDocumentNames: string[], fieldType: string) => {
    return (
        <TooltipList overlayText={hiddenDocumentNames} placement='right' listHeader={`This ${fieldType} is not included for the following documents:`}>
            <div>
                <Icon icon={Document} fontSize={20} />
            </div>
        </TooltipList>
    );
};

interface FormDefinitionProps {
    formDefinition: FormDatasetDefinition;
    parents: number;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: HiddenFields;
    datasetHidden?: boolean;
    documentSpecificHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    parentHiddenDocumentNameIds?: number[];
    parentDatasetId: number;
}

interface FieldsProps {
    fields: DatasetField[];
    sectionId: string;
    datasetOnlySection?: boolean;
    datasetId: number;
    parents: number;
    toggleSection: (fieldSection: MyDatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: MyDatasetsOpenFieldSection) => boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: HiddenFields;
    datasetHidden?: boolean;
    documentSpecificHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    parentHiddenDocumentNameIds?: number[];
    parentDatasetId: number;
}

export const Fields: React.FC<FieldsProps> = ({
    fields,
    sectionId,
    datasetOnlySection = false,
    datasetId,
    parents,
    toggleSection,
    getSectionOpen,
    isPreview,
    modalInstance,
    hiddenFields,
    datasetHidden,
    documentSpecificHiddenFields,
    allDocumentNames,
    parentHiddenDocumentNameIds,
    parentDatasetId
}) => {
    const getPreviewContent = useCallback((field: DatasetField, index: number) => {
        const isLastFieldInSection = index === fields.length - 1;
        if (isGroupField(field)) {
            if (field.type === DatasetFieldType.GROUP) {
                return (
                    <GroupField
                        field={field}
                        key={field.id}
                        groupIndex={index}
                        sectionId={sectionId}
                        datasetId={datasetId}
                        parents={parents}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        isPreview={isPreview}
                        modalInstance={modalInstance}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        documentSpecificHiddenFields={documentSpecificHiddenFields}
                        allDocumentNames={allDocumentNames}
                        parentHiddenDocumentNameIds={parentHiddenDocumentNameIds}
                        isLastFieldInSection={isLastFieldInSection}
                        parentDatasetId={parentDatasetId}
                    />
                );
            }
            if (field.type === DatasetFieldType.MULTI_TOGGLE) {
                return (
                    <MultiToggleField
                        field={field}
                        key={field.id}
                        groupIndex={index}
                        sectionId={sectionId}
                        datasetId={datasetId}
                        isPreview={isPreview}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        documentSpecificHiddenFields={documentSpecificHiddenFields}
                        allDocumentNames={allDocumentNames}
                        parentHiddenDocumentNameIds={parentHiddenDocumentNameIds}
                        parentDatasetId={parentDatasetId}
                    />
                );
            }
        }
        return (
            <SingleField
                field={field as SingleDatasetField}
                key={field.id}
                sectionId={sectionId}
                datasetOnlySection={datasetOnlySection}
                datasetId={datasetId}
                parents={parents}
                toggleSection={toggleSection}
                getSectionOpen={getSectionOpen}
                isPreview={isPreview}
                modalInstance={modalInstance}
                hiddenFields={hiddenFields}
                datasetHidden={datasetHidden}
                documentSpecificHiddenFields={documentSpecificHiddenFields}
                allDocumentNames={allDocumentNames}
                parentHiddenDocumentNameIds={parentHiddenDocumentNameIds}
                isLastFieldInSection={isLastFieldInSection}
                parentDatasetId={parentDatasetId}
            />
        );
    }, [
        sectionId,
        datasetOnlySection,
        datasetId,
        parents,
        toggleSection,
        getSectionOpen,
        isPreview,
        modalInstance,
        hiddenFields,
        datasetHidden,
        documentSpecificHiddenFields,
        allDocumentNames,
        parentHiddenDocumentNameIds,
        fields.length,
        parentDatasetId
    ]);

    return (
        <div className={styles.allFieldsWrapper}>
            {fields.map((field, index) => getPreviewContent(field, index))}
        </div>
    );
};

interface SectionProps {
    section: DatasetSection;
    formDefinition: FormDatasetDefinition;
    datasetId: number;
    parents: number;
    toggleSection: (fieldSection: MyDatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: MyDatasetsOpenFieldSection) => boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: HiddenFields;
    datasetHidden?: boolean;
    documentSpecificHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    parentHiddenDocumentNameIds?: number[];
    parentDatasetId: number;
}

export const Section: React.FC<SectionProps> = ({
    section,
    formDefinition,
    datasetId,
    parents,
    toggleSection,
    getSectionOpen,
    isPreview,
    modalInstance,
    hiddenFields,
    datasetHidden,
    documentSpecificHiddenFields,
    allDocumentNames,
    parentHiddenDocumentNameIds,
    parentDatasetId
}) => {
    const { id, label, description } = section;
    const dispatch = useAppDispatch();
    const openSection: MyDatasetsOpenSection = { sectionId: id, datasetId: datasetId.toString(), type: OpenFieldType.SECTION };
    const sectionOpen = getSectionOpen(openSection);
    const fields = formDefinition.datasetFields[id];
    const datasetOnlySection = fields.length === 1 && fields[0].type === DatasetFieldType.DATASET;
    const sectionOpenIcon = sectionOpen ? CaretDown : CaretSide;

    const toggleSectionVisible = useCallback(() => {
        const hiddenField: HiddenSection = { sectionId: id, type: OpenFieldType.SECTION };
        dispatch(toggleHiddenField(datasetId, hiddenField));
    }, [datasetId, id, dispatch]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId] || [], [datasetId, hiddenFields]);
    const sectionHidden = useMemo(() => !!currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(({ sectionId }) => sectionId === id), [currentHiddenDatasetFields, id]);

    const parentHiddenDocuments = parentHiddenDocumentNameIds || [];
    const documentHiddenFields = useMemo(() => documentSpecificHiddenFields[datasetId] || [], [datasetId, documentSpecificHiddenFields]);
    const currentHiddenDocumentsForSection = useMemo(() => documentHiddenFields.filter(({ type }) => type === OpenFieldType.SECTION).find(({ sectionId }) => sectionId === id)?.documentNameIds || [], [documentHiddenFields, id]);
    const allDocumentsHiddenForField = uniq([...currentHiddenDocumentsForSection, ...parentHiddenDocuments]);
    const hiddenDocumentNames = useMemo(() => allDocumentNames.filter(document => document.datasetId === parentDatasetId).reduce((acc: string[], { documentNameId, documentName }) => documentNameId && allDocumentsHiddenForField.includes(documentNameId) ? [...acc, documentName.toLowerCase()] : acc, []), [allDocumentNames, allDocumentsHiddenForField, parentDatasetId]);
    const documentNameIdsForDataset = useMemo(() => allDocumentNames.filter(document => document.datasetId === parentDatasetId).map(({ documentNameId }) => documentNameId!), [allDocumentNames, parentDatasetId]);
    const sectionHiddenForAllDatasetDocuments = useMemo(() => currentHiddenDocumentsForSection.length > 0 && documentNameIdsForDataset.every(documentNameId => currentHiddenDocumentsForSection.includes(documentNameId)), [documentNameIdsForDataset, currentHiddenDocumentsForSection]);

    if ((isPreview && sectionHidden) || sectionHiddenForAllDatasetDocuments) {
        return null;
    }

    return (
        <div key={id} className={styles.sectionWrapper} data-testid={`form-section-${id}-wrapper`}>
            <div className={styles.sectionHeaderWrapper}>
                <div className={styles.sectionHeader} onClick={() => toggleSection(openSection)} data-testid={`form-section-${id}-collapse`}>
                    <div className={styles.sectionOpenIcon}>
                        <Icon icon={sectionOpenIcon} fontSize={15} />
                    </div>
                    <div className={styles.sectionLabel} data-testid={`form-section-${id}-label`}>{label}</div>
                    {description && <div className={styles.sectionDescription}><InformationTooltip content={description} /></div>}
                </div>
                {!isPreview && <RadioButton fontSize={10} withBackground={!sectionHidden} onClick={toggleSectionVisible} icon={Tick} marginRight='5px' />}
                {hiddenDocumentNames.length > 0 && getHiddenDocumentsTooltip(hiddenDocumentNames, 'section')}
            </div>
            {sectionOpen &&
                <Fields
                    fields={fields}
                    sectionId={id}
                    datasetOnlySection={datasetOnlySection}
                    datasetId={datasetId}
                    parents={parents}
                    toggleSection={toggleSection}
                    getSectionOpen={getSectionOpen}
                    isPreview={isPreview}
                    modalInstance={modalInstance}
                    hiddenFields={hiddenFields}
                    datasetHidden={datasetHidden}
                    documentSpecificHiddenFields={documentSpecificHiddenFields}
                    allDocumentNames={allDocumentNames}
                    parentHiddenDocumentNameIds={parentHiddenDocumentNameIds}
                    parentDatasetId={parentDatasetId}
                />
            }
        </div>
    );
};

export const FormDefinition: React.FC<FormDefinitionProps> = ({ formDefinition, parents, isPreview, modalInstance, hiddenFields, datasetHidden, documentSpecificHiddenFields, allDocumentNames, parentHiddenDocumentNameIds, parentDatasetId }) => {
    const dispatch = useAppDispatch();
    const { datasetSections, datasetFields, datasetId } = formDefinition;
    const firstSection = useMemo(() => datasetSections[0], [datasetSections]);
    const isShortFormInstance = useMemo(() => datasetSections.length === 1 && !firstSection.label, [datasetSections, firstSection]);
    const shortFormFields = useMemo(() => datasetFields[firstSection.id], [datasetFields, firstSection]);
    const datasetOnlySection = useMemo(() => shortFormFields.length === 1 && shortFormFields[0].type === DatasetFieldType.DATASET, [shortFormFields]);
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getSectionOpen = useCallback((fieldSection: MyDatasetsOpenFieldSection) => openFieldsAndSections.some(openSection => isEqual(openSection, fieldSection)), [openFieldsAndSections]);

    const toggleSection = useCallback((fieldSection: MyDatasetsOpenFieldSection) => {
        const sectionOpen = openFieldsAndSections.find(openSection => isEqual(openSection, fieldSection));
        sectionOpen ? dispatch(removeOpenFieldSection(sectionOpen)) : dispatch(addOpenFieldSection(fieldSection));
    }, [dispatch, openFieldsAndSections]);

    return (
        <div className={styles.formDefinitionWrapper} data-testid='form-definition-wrapper'>
            <Scrollable>
                {isShortFormInstance ? (
                    <Fields
                        fields={datasetFields[firstSection.id]}
                        sectionId={firstSection.id}
                        datasetOnlySection={datasetOnlySection}
                        datasetId={datasetId!}
                        parents={parents}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        isPreview={isPreview}
                        modalInstance={modalInstance}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        documentSpecificHiddenFields={documentSpecificHiddenFields}
                        allDocumentNames={allDocumentNames}
                        parentHiddenDocumentNameIds={parentHiddenDocumentNameIds}
                        parentDatasetId={parentDatasetId}
                    />
                ) : (datasetSections.map(section => (
                    <Section
                        section={section}
                        key={section.id}
                        formDefinition={formDefinition}
                        datasetId={datasetId!}
                        parents={parents}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        isPreview={isPreview}
                        modalInstance={modalInstance}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        documentSpecificHiddenFields={documentSpecificHiddenFields}
                        allDocumentNames={allDocumentNames}
                        parentHiddenDocumentNameIds={parentHiddenDocumentNameIds}
                        parentDatasetId={parentDatasetId}
                    />
                )))}
            </Scrollable>
        </div>
    );
};
