import { isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { OpinionReportFilter, OpinionReportTemplate, OpinionReportingState, OpinionScopeFields, ReportPage, ReportTab, UpsertOpinionReportTemplate } from './types';
import { OpinionScope } from '../../../admin/opinions/store';

const getRoot = (state: ApplicationState): OpinionReportingState => state.opinion.reporting;

export const getReportPage = (state: ApplicationState): ReportPage => getRoot(state).reportPage;

export const getSelectedTab = (state: ApplicationState): ReportTab => getRoot(state).selectedTab;

export const getReportFilters = (state: ApplicationState): OpinionReportFilter => getRoot(state).reportFilters;

export const getReportFields = (state: ApplicationState): OpinionScopeFields => getRoot(state).reportFields;

export const getOpinionScope = (state: ApplicationState): OpinionScope[] => getRoot(state).opinionScope;

export const getCurrentScope = (state: ApplicationState): OpinionScope | null => getRoot(state).currentScope;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).fetchingAvailableFields;

export const getIsGeneratingReport = (state: ApplicationState): boolean => getRoot(state).generatingReport;

export const getAvailableFieldsError = (state: ApplicationState): null | string => getRoot(state).fetchingError;

export const getBackConfirmationModalOpen = (state: ApplicationState): boolean => getRoot(state).backConfirmationModalOpen;

export const getReportUpdated = (state: ApplicationState): boolean => getRoot(state).reportUpdated;

export const getAllReportTemplates = (state: ApplicationState): OpinionReportTemplate[] => getRoot(state).reportTemplates;

export const getSaveTemplateModalOpen = (state: ApplicationState): boolean => getRoot(state).saveTemplateModalOpen;

export const getReportTemplateName = (state: ApplicationState): string => getRoot(state).templateName;
export const getReportTemplateDescription = (state: ApplicationState): string => getRoot(state).templateDescription;

export const getSelectedTemplate = (state: ApplicationState): OpinionReportTemplate | null => getRoot(state).selectedTemplate;

export const getSelectedTemplateId = (state: ApplicationState): number | undefined => getSelectedTemplate(state)?.opinionReportTemplateId;

export const getConfirmDeleteReportTemplate = (state: ApplicationState): number | null => getRoot(state).confirmDeleteReportTemplate;

export const getConfirmDeleteModalOpen = (state: ApplicationState): boolean => !isNull(getConfirmDeleteReportTemplate(state));

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;

export const getUpsertReportTemplate = (state: ApplicationState): UpsertOpinionReportTemplate => {
    const reportFields = getReportFields(state);
    const name: string = getReportTemplateName(state);
    const description: string = getReportTemplateDescription(state);
    const opinionReportTemplateId = getSelectedTemplateId(state);
    const filters = getReportFilters(state);
    const includeSignOffNotes = getIncludeSignOffNotes(state);
    return {
        name,
        description,
        opinionReportTemplateId,
        reportFields,
        filters,
        includeSignOffNotes: includeSignOffNotes ? 1 : 0,
        isOpinionReport: true
    };
};

export const getIsFetchingTemplates = (state: ApplicationState): boolean => getRoot(state).fetchingTemplates;

export const getOpenScopeSections = (state: ApplicationState): string[] => {
    const currentScope = getCurrentScope(state);
    const openSections = getRoot(state).openScopeSections;
    return isNull(currentScope) ? [] : openSections[currentScope];
};

export const getIncludeSignOffNotes = (state: ApplicationState): boolean => getRoot(state).includeSignOffNotes;
