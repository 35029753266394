import React from 'react';

import { Icon } from '../icon/Icon';
import { OverflowTooltip } from '../tooltip';
import styles from './Modal.module.scss';

interface ModalHeaderProps {
    label: string;
    icon: React.FC;
    testId?: string;
    iconSize?: number;
    marginBottom?: string;
    iconColor?: string;
    iconFill?: string;
    overflowTooltipClassName?: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ label, testId, icon, iconSize = 40, overflowTooltipClassName, marginBottom = '10px', iconColor, iconFill }) => (
    <div className={styles.modalHeader} style={{ marginBottom }}>
        <OverflowTooltip testId={`${testId}-header`} overlayText={label} className={overflowTooltipClassName || styles.defaultModalHeader} />
        <Icon icon={icon} fontSize={iconSize} color={iconColor} fill={iconFill} />
    </div>
);
