import { isNull } from 'lodash';
import { push } from 'redux-first-history';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchSelectedDoraThirdPartyCoverageTableAnalytics, fetchNetworkDiagramAnalytics, fetchSelectedDoraThirdPartyCoveragePieAnalytics, fetchDoraThirdPartyCoverageAnalytics, fetchOutstandingContractData } from '../../../../services/analytics';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { fetchNetworkDiagramAnalyticsFailed, fetchNetworkDiagramAnalyticsSuccessful, fetchThirdPartyCoverageDataFailed, fetchThirdPartyCoverageDataSuccessful, fetchThirdPartyFunctionCompanyDetailsDataFailed, fetchThirdPartyFunctionCompanyDetailsDataStarted, fetchThirdPartyFunctionCompanyDetailsDataSuccessful, setDoraTileInView, fetchThirdPartyFunctionCompanyPieAnalyticsSuccessful, fetchThirdPartyFunctionCompanyPieAnalyticsFailed, fetchOutstandingContractualDataAnalyticsSuccessful, fetchOutstandingContractualDataAnalyticsFailed } from './actions';
import { getThirdPartyCoverageColumnSort, getThirdPartyCoverageFilters, getThirdPartyCoveragePageSize, getThirdPartyCoveragePageNumber, getThirdPartyCompanyId } from './selectors';
import { DoraAnalyticsActionTypes, SelectedThirdPartyCoverage, ThirdPartyCoverageAnalytics, ThirdPartyFunctionCompanyCoveragePieAnalytics, NetworkDiagramData, ContractualDataAnalytics } from './types';

export function* attemptRedirectTileInView({ payload }: ReturnType<typeof setDoraTileInView>) {
    const { tileInView, shouldRedirect } = payload;
    if (shouldRedirect) {
        const redirectUrl = isNull(tileInView) ? '/dora/analytics' : `/dora/analytics/${tileInView.replaceAll(' ', '-')}`;
        yield put(push(redirectUrl));
    }
}

function* setTileInViewWatcher() {
    yield takeEvery(DoraAnalyticsActionTypes.SET_DORA_ANALYTICS_TILE_IN_VIEW, attemptRedirectTileInView);
}

export function* attemptFetchThirdPartyCoverage() {
    try {
        const thirdPartyCoverageData: ThirdPartyCoverageAnalytics[] = yield call(fetchDoraThirdPartyCoverageAnalytics);
        yield put(fetchThirdPartyCoverageDataSuccessful(thirdPartyCoverageData));
    } catch (e) {
        yield put(fetchThirdPartyCoverageDataFailed((e as Error).message));
    }
}

function* fetchThirdPartyCoverageWatcher() {
    yield takeLatest(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_STARTED, attemptFetchThirdPartyCoverage);
}

export function* attemptFetchPaginatedThirdPartyCoverageDetails({ payload }: ReturnType<typeof fetchThirdPartyFunctionCompanyDetailsDataStarted>) {
    try {
        const { pageNumber } = payload;
        const filters: TableFilters = yield select(getThirdPartyCoverageFilters);
        const columnSort: ColumnSort | undefined = yield select(getThirdPartyCoverageColumnSort);
        const pageSize: number = yield select(getThirdPartyCoveragePageSize);
        const thirdPartyCompanyId: number = yield select(getThirdPartyCompanyId);
        const { data, total }: { data: SelectedThirdPartyCoverage[]; total: number; } = yield call(fetchSelectedDoraThirdPartyCoverageTableAnalytics, { filters, columnSort, pageNumber, pageSize, thirdPartyCompanyId, });
        yield put(fetchThirdPartyFunctionCompanyDetailsDataSuccessful(data, total));
    } catch (e) {
        yield put(fetchThirdPartyFunctionCompanyDetailsDataFailed((e as Error).message));
    }
}

function* fetchFunctionCoverageWatcher() {
    yield takeEvery(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_STARTED, attemptFetchPaginatedThirdPartyCoverageDetails);
}

export function* paginationThirdPartyFunctionCoverageNext() {
    const pageNumber: number = yield select(getThirdPartyCoveragePageNumber);
    yield put(fetchThirdPartyFunctionCompanyDetailsDataStarted(pageNumber + 1));
}

function* paginationThirdPartyFunctionCoverageNextWatcher() {
    yield takeEvery(DoraAnalyticsActionTypes.SELECTED_THIRD_PARTY_COVERAGE_PAGINATION_NEXT, paginationThirdPartyFunctionCoverageNext);
}

export function* paginationThirdPartyCoveragePrevious() {
    const pageNumber: number = yield select(getThirdPartyCoveragePageNumber);
    yield put(fetchThirdPartyFunctionCompanyDetailsDataStarted(pageNumber - 1));
}

function* paginationThirdPartyCoveragePreviousWatcher() {
    yield takeEvery(DoraAnalyticsActionTypes.SELECTED_THIRD_PARTY_COVERAGE_PAGINATION_PREVIOUS, paginationThirdPartyCoveragePrevious);
}

export function* thirdPartyFunctionCompanyTableUpdated() {
    yield put(fetchThirdPartyFunctionCompanyDetailsDataStarted(1));
}

function* thirdPartyFunctionCompanyTableUpdatedWatcher() {
    yield takeEvery([DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS, DoraAnalyticsActionTypes.CLEAR_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS, DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_COLUMN_SORT, DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_PAGE_SIZE], thirdPartyFunctionCompanyTableUpdated);
}

export function* attemptFetchThirdPartyFunctionCompanyPieAnalytics() {
    try {
        const thirdPartyCompanyId: number = yield select(getThirdPartyCompanyId);
        const { pieData, companyName }: { pieData: ThirdPartyFunctionCompanyCoveragePieAnalytics[]; companyName: string } = yield call(fetchSelectedDoraThirdPartyCoveragePieAnalytics, { thirdPartyCompanyId });
        yield put(fetchThirdPartyFunctionCompanyPieAnalyticsSuccessful(pieData, companyName));
    } catch (e) {
        yield put(fetchThirdPartyFunctionCompanyPieAnalyticsFailed((e as Error).message));
    }
}

function* fetchThirdPartyFunctionCoveragePieWatcher() {
    yield takeEvery(DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_STARTED, attemptFetchThirdPartyFunctionCompanyPieAnalytics);
}

export function* attemptFetchNetworkDiagramAnalytics() {
    try {
        const networkDiagram: NetworkDiagramData = yield call(fetchNetworkDiagramAnalytics);
        yield put(fetchNetworkDiagramAnalyticsSuccessful(networkDiagram));
    } catch (e) {
        yield put(fetchNetworkDiagramAnalyticsFailed((e as Error).message));
    }
}

function* fetchNetworkDiagramAnalyticsWatcher() {
    yield takeLatest(DoraAnalyticsActionTypes.FETCH_NETWORK_DIAGRAM_ANALYTICS_STARTED, attemptFetchNetworkDiagramAnalytics);
}

export function* attemptFetchOutstandingContractDataAnalytics() {
    try {
        const contractualDataAnalytics: ContractualDataAnalytics[] = yield call(fetchOutstandingContractData);
        yield put(fetchOutstandingContractualDataAnalyticsSuccessful(contractualDataAnalytics));
    } catch (e) {
        yield put(fetchOutstandingContractualDataAnalyticsFailed((e as Error).message));
    }
}

function* fetchOutstandingContractDataWatcher() {
    yield takeLatest(DoraAnalyticsActionTypes.FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_STARTED, attemptFetchOutstandingContractDataAnalytics);
}

export function* doraAnalyticsSaga() {
    yield all([
        fork(fetchThirdPartyCoverageWatcher),
        fork(fetchFunctionCoverageWatcher),
        fork(paginationThirdPartyFunctionCoverageNextWatcher),
        fork(paginationThirdPartyCoveragePreviousWatcher),
        fork(thirdPartyFunctionCompanyTableUpdatedWatcher),
        fork(fetchThirdPartyFunctionCoveragePieWatcher),
        fork(setTileInViewWatcher),
        fork(fetchNetworkDiagramAnalyticsWatcher),
        fork(fetchOutstandingContractDataWatcher)
    ]);
}
