import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { clearOutOfScopeCompanyTableFilters, fetchPaginatedOutOfScopeDoraCompaniesStarted, getOutOfScopeCompaniesPageNumber, getOutOfScopeCompaniesPageSize, getOutOfScopeCompanyFilters, getIsFetchingCompanies, getPaginatedOutOfDoraCompanies, getOutOfScopeTotalCompanies, setOutOfScopeCompaniesPageSize, setOutOfScopeCompanyTableColumnSort, setOutOfScopeCompanyTableFilters, setSelectedCompanyEntity, companiesOutOfScopePaginationNext, companiesOutOfScopePaginationPrevious, getOutOfScopeCompanyColumnSort, getMyCompanyEntitiesDropdownOptions, toggleCompanyEntityModal, updateSelectedEntity, saveCompanyEntityStarted } from './store';
import { Action } from '../../shared/modal/ActionModal';
import { OfficeBuilding } from '../../shared/icons';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { CompanyEntity } from '../../admin/entity/store';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { HEIGHT_OF_THE_WIZARD } from './MyCompaniesInScopeForDora';

interface MyCompaniesOutOfScopeForDoraProps {
    hasDoraFullAccessPermission: boolean;
}

export const MyCompaniesOutOfScopeForDora: React.FC<MyCompaniesOutOfScopeForDoraProps> = ({ hasDoraFullAccessPermission }) => {
    const [, screenHeight] = useWindowResize();

    useFetchStarted([fetchPaginatedOutOfScopeDoraCompaniesStarted()]);

    const dispatch = useAppDispatch();
    const setSelectedEntity = useCallback((row: CompanyEntity) => {
        dispatch(setSelectedCompanyEntity(row));
        dispatch(toggleCompanyEntityModal(true));
    }, [dispatch]);

    const companyEntities = useAppSelector(getPaginatedOutOfDoraCompanies);
    const isLoading = useAppSelector(getIsFetchingCompanies);
    const pageNumber = useAppSelector(getOutOfScopeCompaniesPageNumber);
    const totalCompanies = useAppSelector(getOutOfScopeTotalCompanies);
    const companyFilters = useAppSelector(getOutOfScopeCompanyFilters);
    const columnSort = useAppSelector(getOutOfScopeCompanyColumnSort);
    const pageSize = useAppSelector(getOutOfScopeCompaniesPageSize);
    const companyEntityOptions = useAppSelector(getMyCompanyEntitiesDropdownOptions);

    const nextPage = useCallback(() => dispatch(companiesOutOfScopePaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(companiesOutOfScopePaginationPrevious()), [dispatch]);

    const updateFilter = useCallback((key: string, value: string | string[] | null) => dispatch(setOutOfScopeCompanyTableFilters(key, value)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearOutOfScopeCompanyTableFilters()), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setOutOfScopeCompanyTableColumnSort(columnSort)), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setOutOfScopeCompaniesPageSize(pageSize)), [dispatch]);

    const actions = useCallback((data: CompanyEntity): Action[] => [{ label: 'Open', icon: OfficeBuilding, onClick: () => setSelectedEntity(data) }], [setSelectedEntity]);

    const doraMaintainerROI = useMemo(() => companyEntities.filter(({ doraMaintainerROI }) => doraMaintainerROI > 0).map(({ entityId }) => entityId!), [companyEntities]);

    const toggleScope = useCallback((entityId: string | number) => {
        const id = typeof entityId === 'string' ? parseInt(entityId) : entityId;
        const entity = companyEntities.find(entity => entity.entityId === id)!;
        dispatch(setSelectedCompanyEntity(entity));
        dispatch(updateSelectedEntity('doraInScope', 1));
        dispatch(saveCompanyEntityStarted());
    }, [dispatch, companyEntities]);

    const toggleMaintainerROI = useCallback((id: number | string) => {
        const entity = companyEntities.find(entity => entity.entityId === id)!;
        dispatch(setSelectedCompanyEntity(entity));
        const isMaintainer = entity.doraMaintainerROI === 0 ? 1 : 0;
        dispatch(updateSelectedEntity('doraMaintainerROI', isMaintainer));
        dispatch(saveCompanyEntityStarted());
    }, [dispatch, companyEntities]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'name', header: 'Name', field: 'name', width: 0.80, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'doraMaintainerROI', header: 'Maintains R.O.I', description: 'One entity should maintain a "Register of Information" for DORA purposes', field: 'entityId', width: 0.15, select: toggleMaintainerROI, selected: doraMaintainerROI, disabled: () => !hasDoraFullAccessPermission, component: 'checkbox' },
        { id: 'myCompaniesScope', header: 'Mark in scope', field: 'entityId', width: 0.15, select: toggleScope, selected: [], disabled: () => !hasDoraFullAccessPermission, component: 'checkbox' },
        { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
    ];

    const tableHeight = useMemo(() => (screenHeight * 0.75) - HEIGHT_OF_THE_WIZARD, [screenHeight]);

    const filterDropdownOptions = useMemo(() => ({
        name: companyEntityOptions
    }), [companyEntityOptions]);

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={companyEntities}
            testId='out-of-scope'
            isLoading={isLoading}
            page={pageNumber}
            total={totalCompanies}
            next={nextPage}
            previous={previousPage}
            filters={companyFilters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => setSelectedEntity(row as CompanyEntity)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
            filterDropdownOptions={filterDropdownOptions}
            height={tableHeight}
        />
    );
};
