import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Document } from '../../shared/icons';
import { Position } from '../../shared/modal/PositionModal';
import { MLProgressModal } from './MLProgressModal';
import { getMLProgressModalOpen, getShowMLHourglassIndicator, getShowMLInProgressIndicator, toggleMLProgressModal } from './store';
import { HourglassButton } from '../../shared/button/HourglassButton';

export const MLProgressButton: React.FC = () => {
    const [position, setPosition] = useState<Position | null>(null);
    const dispatch = useAppDispatch();
    const toggleModal = (isOpen: boolean) => dispatch(toggleMLProgressModal(isOpen));

    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        toggleModal(true);
    };

    const closeModal = () => {
        setPosition(null);
        toggleModal(false);
    };

    const showIndicator = useAppSelector(getShowMLInProgressIndicator);
    const showHourglass = useAppSelector(getShowMLHourglassIndicator);
    const modalOpen = useAppSelector(getMLProgressModalOpen);

    if (!showIndicator) {
        return null;
    }

    return (
        <>
            <HourglassButton
                icon={Document}
                onClick={e => openModal(e.clientX, e.clientY)}
                showHourglass={showHourglass}
                fontSize={30}
            />
            <MLProgressModal
                isOpen={modalOpen}
                closeModal={closeModal}
                testId='active-ml-progress'
                position={position}
            />
        </>
    );
};
