import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasOneFeaturePermission } from '../../auth/login/store';
import { getIsUploading } from '../../documents/my-documents/store';
import { Document } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Spinner } from '../../shared/spinner/Spinner';
import { getFilesToUpload, setUploadOpinions, uploadSupportOpinionStarted } from '../my-opinions/store';
import styles from './OpinionInstance.module.scss';
import { toggleSupportOpinionUploadModal } from './store';

interface SupportOpinionModalProps {
    isOpen: boolean;
}

export const SupportOpinionModal: React.FC<SupportOpinionModalProps> = ({ isOpen }) => {
    const dispatch = useAppDispatch();
    const closeModal = () => dispatch(toggleSupportOpinionUploadModal(false));
    const uploadOpinion = () => dispatch(uploadSupportOpinionStarted());

    const hasUploadOpinionPermission = useAppSelector(getUserHasOneFeaturePermission([FeaturePermission.UPLOAD_OPINIONS, FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));

    const isUploading = useAppSelector(getIsUploading);
    const files = useAppSelector(getFilesToUpload);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({ disabled: !hasUploadOpinionPermission, maxFiles: 1, maxSize: 10485760, accept: ['.pdf', '.doc', '.docx', '.tif'] });

    const setOpinionsForUpload = useCallback((files: File[]) => dispatch(setUploadOpinions(files)), [dispatch]);

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length) {
            setOpinionsForUpload(acceptedFiles);
        }
    }, [acceptedFiles, dispatch, setOpinionsForUpload]);

    const documentToUpload = files ? `${files[0].name} - ${(files[0].size / 1e6).toFixed(2)}MB` : 'None';
    const dropzoneText = useMemo(() => {
        if (isDragActive) {
            return 'Drop document here';
        }
        if (acceptedFiles.length) {
            return 'Document successfully added, click here or drop a new document to override';
        }
        return 'Drag \'n\' drop documents here, or click to select files';
    }, [isDragActive, acceptedFiles]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel='Upload'
            confirmDisabled={(!files || files.length === 0) || !hasUploadOpinionPermission || isUploading}
            confirm={uploadOpinion}
            testId='upload-support-opinion'
        >
            <div className={styles.uploadDocumentWrapper} data-testid='upload-support-opinion-modal-wrapper'>
                <ModalHeader
                    label='Upload a support document'
                    testId='upload-support-opinion-modal'
                    icon={Document}
                />
                {isUploading ? <div className={styles.spinnerWrapper}><Spinner /></div> : <>
                    <div {...getRootProps({ className: classnames(styles.dropzone, { [styles.dropzoneDisabled]: !hasUploadOpinionPermission }) })}>
                        <input {...getInputProps()} />
                        <div className={styles.dropzonePlaceholder}>{dropzoneText}</div>
                    </div>
                    <div className={styles.selectedDocumentWrapper}>
                        <div className={styles.selectedDocumentHeader}>Document to be uploaded</div>
                        <div className={styles.selectedDocument}>{documentToUpload}</div>
                    </div>
                </>}
            </div>
        </ConfirmationModal>
    );
};
