import React from 'react';
import { ColDef, GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';

import styles from './DocumentName.module.scss';
import { Pencil } from '../../shared/icons';
import { Icon } from '../../shared/icon/Icon';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Table } from '../../shared/table';
import { fetchAllAgreementTypesStarted, fetchAllDocumentNamesStarted, getAllDocumentNames, toggleModal } from './store';
import { useFetchStarted } from '../../../hooks/useFetchStarted';

const IconCell = (params: ICellRendererParams) => {
    const dispatch = useAppDispatch();

    const editDocument = () => dispatch(toggleModal(true, params.data));
    return (
        <div className={styles.editDocumentButton} onClick={editDocument}>
            <Icon fontSize={20} icon={Pencil} />
        </div>
    );
};

export const DocumentNameTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDocumentNamesStarted(), fetchAllAgreementTypesStarted()]);
    const documentNames = useAppSelector(getAllDocumentNames);

    const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };
    const columnDefs: ColDef[] = [
        { headerName: 'Document Name', field: 'documentName', cellStyle: defaultCellStyle, width: 0.4 },
        { headerName: 'Dataset', field: 'datasetTitle', cellStyle: defaultCellStyle, width: 0.35 },
        { headerName: 'Agreement Type', field: 'agreementType', cellStyle: defaultCellStyle, width: 0.2 },
        { headerName: '', field: '', cellRenderer: 'iconCellRenderer', width: 0.05 }
    ];

    const gridOptions: GridOptions = {
        components: {
            iconCellRenderer: IconCell
        },
        paginationPageSize: 20
    };

    const onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => dispatch(toggleModal(true, data));

    return (
        <Table
            colDefs={columnDefs}
            rowData={documentNames}
            gridOptions={gridOptions}
            percentageWidths={true}
            onRowDoubleClicked={onRowDoubleClicked}
            testId='document-name'
        />
    );
};
