import React from 'react';
import Modal from 'react-modal';

import styles from './Login.module.scss';
import { useAppSelector } from '../../../hooks/react-redux';
import { Spinner } from '../../shared/spinner/Spinner';
import { getIsLoggingOut } from './store';
import { getIsResettingPassword } from '../resetPassword/store';

export const LogoutOverlay: React.FC = () => {
    const isLoggingOut = useAppSelector(getIsLoggingOut);
    const resettingPassword = useAppSelector(getIsResettingPassword);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isLoggingOut && !resettingPassword}
            className={styles.logoutOverlay}
            style={{ overlay: { display: 'flex', zIndex: 10 } }}
        >
            <Spinner size={100} message='Logging out...' />;
        </Modal>
    );
};
