import { action } from 'typesafe-actions';

import { ExtensiveOpinionAverage, OpinionAnalyticsActionTypes, OpinionAnalyticsChart, OpinionAverage, OpinionAverageView, OpinionMapAnalytics, OpinionSingleField } from './types';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { OpinionScope } from '../../../admin/opinions/store';

export const fetchSingleDataJurisdictionStarted = () => action(OpinionAnalyticsActionTypes.FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_STARTED, { chart: OpinionAnalyticsChart.SINGLE_DATA_POINT_JURISDICTION });
export const fetchSingleDataJurisdictionSuccessful = (analytics: OpinionMapAnalytics[]) => action(OpinionAnalyticsActionTypes.FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_SUCCESSFUL, { chart: OpinionAnalyticsChart.SINGLE_DATA_POINT_JURISDICTION, key: 'singleDataPointMapAnalytics', analytics });
export const fetchSingleDataJurisdictionFailed = (e: string) => action(OpinionAnalyticsActionTypes.FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_FAILED, { error: e, chart: OpinionAnalyticsChart.SINGLE_DATA_POINT_JURISDICTION });
export const setOpinionSingleField = (singleField: OpinionSingleField) => action(OpinionAnalyticsActionTypes.SET_OPINION_SINGLE_FIELD, singleField);

export const setOpinionAnalyticsScope = (scope: OpinionScope) => action(OpinionAnalyticsActionTypes.SET_OPINION_ANALYTICS_SCOPE, scope);

export const setSelectedCountry = (country: string | null) => action(OpinionAnalyticsActionTypes.SET_SELECTED_COUNTRY, country);
export const setSelectedSingleFieldJurisdiction = (jurisdiction: string | null) => action(OpinionAnalyticsActionTypes.SET_SELECTED_SINGLE_FIELD_JURISDICTION, jurisdiction);

export const fetchOpinionsAverageStarted = () => action(OpinionAnalyticsActionTypes.FETCH_OPINIONS_AVERAGE_ANALYTICS_STARTED, { chart: OpinionAnalyticsChart.OPINIONS_AVERAGE });
export const fetchOpinionsAverageSuccessful = (opinionsWithAverage: OpinionAverage[]) => action(OpinionAnalyticsActionTypes.FETCH_OPINIONS_AVERAGE_ANALYTICS_SUCCESSFUL, { chart: OpinionAnalyticsChart.OPINIONS_AVERAGE, opinionsWithAverage });
export const fetchOpinionsAverageFailed = (e: string) => action(OpinionAnalyticsActionTypes.FETCH_OPINIONS_AVERAGE_ANALYTICS_FAILED, { error: e, chart: OpinionAnalyticsChart.OPINIONS_AVERAGE });

export const setOpinionAverageView = (view: OpinionAverageView) => action(OpinionAnalyticsActionTypes.SET_OPINION_AVERAGE_VIEW, view);

export const redirectToOpinion = (location: string, opinionId: number) => action(OpinionAnalyticsActionTypes.REDIRECT_TO_OPINION, { opinionId, location });

export const fetchOpinionAverageBreakdownStarted = (opinionId: number) => action(OpinionAnalyticsActionTypes.FETCH_OPINION_AVERAGE_BREAKDOWN_STARTED, opinionId);
export const fetchOpinionAverageBreakdownSuccessful = (extensiveOpinionAverage: ExtensiveOpinionAverage) => action(OpinionAnalyticsActionTypes.FETCH_OPINION_AVERAGE_BREAKDOWN_SUCCESSFUL, extensiveOpinionAverage);
export const fetchOpinionAverageBreakdownFailed = (e: string) => action(OpinionAnalyticsActionTypes.FETCH_OPINION_AVERAGE_BREAKDOWN_FAILED, e);

export const setOpinionAverageTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(OpinionAnalyticsActionTypes.SET_OPINION_AVERAGE_TABLE_FILTERS, { key, value, type });
export const clearOpinionAverageTableFilters = () => action(OpinionAnalyticsActionTypes.CLEAR_OPINION_AVERAGE_TABLE_FILTERS);
export const setOpinionAverageTableColumnSort = (columnSort: ColumnSort | undefined) => action(OpinionAnalyticsActionTypes.SET_OPINION_AVERAGE_TABLE_COLUMN_SORT, columnSort);

export const setOpinionTileInView = (tileInView: OpinionAnalyticsChart | null, shouldRedirect: boolean) => action(OpinionAnalyticsActionTypes.SET_OPINION_ANALYTICS_TILE_IN_VIEW, { tileInView, shouldRedirect });

export const setViewSignOffAnswers = (value: boolean) => action(OpinionAnalyticsActionTypes.SET_VIEW_SIGN_OFF_ANSWERS, value);
