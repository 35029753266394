import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { fetchAllAgreementTypesStarted, fetchAllDocumentNamesStarted, getAllAgreementTypes, getAllDocumentNames } from '../documents/store';
import styles from './AIManager.module.scss';
import { fetchAgreementTypeQueriesStarted, fetchAIAgreementTypesStarted, getAllAIAgreementTypes, getSelectedAIAgreementTypeId, setSelectedAIAgreementType, setAIQueriesSelectedClient, getSelectedClientId } from './store';
import { AgreementTypeSelect } from './AgreementTypeSelect';
import { AIQueries } from './AIQueries';
import { PlusButton } from '../../shared/button/PlusButton';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { AIManagerPieChart } from './AIManagerPieChart';
import { AIAgreementTypeModal } from './AIAgreementTypeModal';
import { Position } from '../../shared/modal/PositionModal';
import { fetchAllClientsStarted, getAllClients } from '../clients/store';
import { fetchAllDropdownListsStarted } from '../dropdown-lists/store';

const NAV_HEADER_MARGINS = 150;

export interface AIManagerRouteParams {
    agreementTypeId: string | undefined;
}

export const AIManager: React.FC<RouteComponentProps<AIManagerRouteParams>> = ({ match }) => {
    const [agreementModalOpen, setAgreementModalOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);

    const dispatch = useAppDispatch();

    useFetchStarted([fetchAllDocumentNamesStarted(), fetchAIAgreementTypesStarted(), fetchAllAgreementTypesStarted(), fetchAllClientsStarted(), fetchAllDropdownListsStarted()]);

    const selectedAgreementTypeId = useAppSelector(getSelectedAIAgreementTypeId);
    const aiAgreementTypes = useAppSelector(getAllAIAgreementTypes);
    const allAgreementTypes = useAppSelector(getAllAgreementTypes);
    const documentNames = useAppSelector(getAllDocumentNames);
    const clients = useAppSelector(getAllClients);
    const selectedClientId = useAppSelector(getSelectedClientId);

    const options: DropdownOption[] = useMemo(() => clients.map(({ clientId, clientName }) => ({ value: clientId!.toString(), label: clientName })), [clients]);

    const nonMLReadyAgreementTypeOptions = useMemo((): DropdownOption[] => {
        const currentMLAgreementTypes = aiAgreementTypes.map(({ agreementTypeId }) => agreementTypeId);
        const allDocumentNameAgreementIds = documentNames.map(({ agreementTypeId }) => agreementTypeId);
        const linkedAgreementTypes = allAgreementTypes.filter(({ agreementTypeId }) => allDocumentNameAgreementIds.includes(agreementTypeId));
        return linkedAgreementTypes.filter(({ agreementTypeId }) => !currentMLAgreementTypes.includes(agreementTypeId)).map(({ agreementTypeId, name }) => ({ label: name, value: agreementTypeId.toString() }));
    }, [aiAgreementTypes, allAgreementTypes, documentNames]);

    const selectedAgreement = useMemo(() => aiAgreementTypes.find(({ agreementTypeId }) => agreementTypeId === selectedAgreementTypeId), [aiAgreementTypes, selectedAgreementTypeId]);
    const newAgreementSelected = useMemo(() => !!nonMLReadyAgreementTypeOptions.find(({ value }) => parseInt(value) === selectedAgreementTypeId), [nonMLReadyAgreementTypeOptions, selectedAgreementTypeId]);

    const [screenWidth, screenHeight] = useWindowResize();

    const wrapperHeight = useMemo(() => screenHeight - NAV_HEADER_MARGINS, [screenHeight]);

    const selectAgreementType = useCallback((agreementTypeId: number) => dispatch(fetchAgreementTypeQueriesStarted(agreementTypeId, false)), [dispatch]);

    const pieChartMultiplier = useMemo(() => isNull(selectedAgreementTypeId) ? 0.5 : 0.3, [selectedAgreementTypeId]);

    const aiQueries = useMemo(() => {
        if (!isNull(selectedAgreementTypeId)) {
            return <AIQueries />;
        }
        return <AgreementTypeSelect />;
    }, [selectedAgreementTypeId]);

    const agreementTypeId = useMemo(() => match.params.agreementTypeId ? parseInt(match.params.agreementTypeId) : undefined, [match]);
    const agreementTypeIdInParamsExists = useMemo(() => (agreementTypeId && aiAgreementTypes.map(({ agreementTypeId }) => agreementTypeId).includes(agreementTypeId)) || false, [agreementTypeId, aiAgreementTypes]);

    const selectedClientIdOption = useMemo(() => !isNull(selectedClientId) ? options.find(({ value }) => parseInt(value) === selectedClientId)! : null, [selectedClientId, options]);

    const openModal = (x: number, y: number) => {
        setAgreementModalOpen(true);
        setPosition({ x, y });
    };

    const closeModal = useCallback(() => {
        setAgreementModalOpen(false);
        setPosition(null);
    }, [setAgreementModalOpen, setPosition]);

    const updateSelectedClientId = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: number | null = null;
        if (!isNull(dropdownValue)) {
            value = parseInt((dropdownValue as DropdownOption).value, 10);
        }
        dispatch(setAIQueriesSelectedClient(value));
        closeModal();
    }, [dispatch, closeModal]);

    useEffect(() => {
        if (isNull(selectedAgreementTypeId) && agreementTypeIdInParamsExists) {
            selectAgreementType(agreementTypeId!);
        }
    }, [selectedAgreementTypeId, agreementTypeIdInParamsExists, selectAgreementType, agreementTypeId]);

    useEffect(() => () => {
        dispatch(setSelectedAIAgreementType(null, false));
    }, [dispatch]);

    useEffect(() => () => {
        dispatch(setAIQueriesSelectedClient(null));
    }, [dispatch]);

    return (
        <div className={styles.aiManagerWrapper} data-testid='ai-manager-wrapper'>
            <div className={styles.aiManagerTitleContainer}>
                <div className={styles.aiManagerTitleWrapper}>
                    <div className={styles.aiManagerTitle} data-testid='ai-manager-title'>AI Manager</div>
                    {isNull(selectedAgreementTypeId) && <PlusButton onClick={e => openModal(e.clientX, e.clientY)} fontSize={22} testId='ai-manager-agreement-type' />}
                </div>
                <div className={styles.dropdownWrapper}>
                    <Dropdown
                        placeholder='All clients excluding DRS'
                        value={selectedClientIdOption}
                        options={options}
                        onChange={updateSelectedClientId}
                    />
                </div>
            </div>
            <div className={styles.aiManagerContentWrapper}>
                <div className={styles.aiPieChartWrapper} style={{ height: (wrapperHeight - 20), width: `calc(100% * ${pieChartMultiplier} - 10px)` }}>
                    <AIManagerPieChart
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                        selectedAgreement={selectedAgreement}
                        newAgreementSelected={newAgreementSelected}
                        pieChartMultiplier={pieChartMultiplier}
                    />
                </div>
                <div className={styles.aiAgreementWrapper} style={{ height: wrapperHeight, width: `${100 * (1 - pieChartMultiplier)}%` }}>
                    {aiQueries}
                </div>
            </div>
            <AIAgreementTypeModal isOpen={agreementModalOpen} options={nonMLReadyAgreementTypeOptions} closeModal={closeModal} position={position} />
        </div>
    );
};
