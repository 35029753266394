import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';

import { scopeFormatter } from '../../../shared/table/arkTableFormatters';
import { ArkOpinion } from '../../my-opinions/store';
import { MetaField } from '../fields/MetaField';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';
import { OpinionCommissionedBy } from '../../../admin/opinions/store';

interface ScopeSectionProps {
    opinion: ArkOpinion;
    isEditing: boolean;
    isUpdating: boolean;
}

export const ScopeSection: React.FC<ScopeSectionProps> = ({ opinion, isEditing, isUpdating }) => {
    const sectionId = 'scope';
    const { commissionedBy, commissionedByIfOther, scope } = opinion;
    const formattedScope = useMemo(() => scopeFormatter(scope), [scope]);
    return (
        <SectionWrapper id={sectionId} label='Scope' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.scopeSectionWrapper}>
                <MetaField id='scope' label='Scope' value={[formattedScope]} width='100%' />
                <div className={styles.sectionGroup}>
                    <MetaField id='commissionedBy' label='Commissioned By' value={[commissionedBy!]} width='calc(50% - 10px)' />
                    {!isNull(commissionedByIfOther) && commissionedBy === OpinionCommissionedBy.OTHER && <MetaField id='commissionedBy' label='Commissioned By (if other)' value={[commissionedByIfOther]} width='calc(50% - 10px)' />}
                </div>
            </div>
        </SectionWrapper>
    );
};
