import { isEqual } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SubCounterpartyType } from '../../../admin/entity/store';
import { CounterpartyCoverage, getSelectedSubCounterpartyParent, updateIncludedInstanceSubCounterpartyTypes } from '../store';
import styles from './OpinionSection.module.scss';
import { SubCounterpartyColumn } from './SubCounterpartyColumn';

interface SubCounterpartConfigBodyProps {
    counterpartyCoverage: CounterpartyCoverage;
    readOnly: boolean;
    availableSubCounterpartyTypes: SubCounterpartyType[];
    isIndustryStandardOpinion: boolean;
    showSelectAllLabel: boolean;
}

export const SubCounterpartConfigBody: React.FC<SubCounterpartConfigBodyProps> = ({ readOnly, counterpartyCoverage, availableSubCounterpartyTypes, isIndustryStandardOpinion = false, showSelectAllLabel }) => {
    const dispatch = useAppDispatch();
    const parentCounterparty = useAppSelector(getSelectedSubCounterpartyParent);

    const includedIds = useMemo(() => counterpartyCoverage.standardOptions.find(({ label }) => label === parentCounterparty)?.includedSubCounterpartyTypeIds || [], [counterpartyCoverage, parentCounterparty]);

    const subCounterpartyTypes = useMemo(() => {
        if (isIndustryStandardOpinion) {
            return availableSubCounterpartyTypes.filter(({ isSystem, parentCounterpartyType }) => isSystem && parentCounterpartyType === parentCounterparty);
        }
        return availableSubCounterpartyTypes.filter(({ parentCounterpartyType }) => parentCounterpartyType === parentCounterparty);
    }, [availableSubCounterpartyTypes, isIndustryStandardOpinion, parentCounterparty]);

    const allSystemIds = useMemo(() => subCounterpartyTypes.map(({ subCounterpartyTypeId }) => subCounterpartyTypeId!), [subCounterpartyTypes]);

    const includedSubCounterpartyTypes = useMemo(() => subCounterpartyTypes.filter(({ subCounterpartyTypeId }) => includedIds.includes(subCounterpartyTypeId!)), [includedIds, subCounterpartyTypes]);
    const excludedSubCounterpartyTypes = useMemo(() => subCounterpartyTypes.filter(({ subCounterpartyTypeId }) => !includedIds.includes(subCounterpartyTypeId!)), [includedIds, subCounterpartyTypes]);

    const updateIncludedIds = useCallback((subCounterpartyTypeId: number) => {
        const newIncludedArray = includedIds.includes(subCounterpartyTypeId)
            ? includedIds.filter(id => id !== subCounterpartyTypeId)
            : [...includedIds, subCounterpartyTypeId];

        dispatch(updateIncludedInstanceSubCounterpartyTypes(newIncludedArray));
    }, [dispatch, includedIds]);

    const includeAllSubCounterpartyTypes = useCallback((checked: boolean) => dispatch(updateIncludedInstanceSubCounterpartyTypes(checked ? allSystemIds : [])), [dispatch, allSystemIds]);

    const allCounterpartyTypesAreIncluded = useMemo(() => isEqual(includedIds, allSystemIds), [includedIds, allSystemIds]);

    return (
        <div className={styles.subCounterpartyConfigCardsContentWrapper}>
            <SubCounterpartyColumn
                columnId='included'
                subCounterpartyTypes={includedSubCounterpartyTypes}
                readOnly={readOnly}
                updateIncludedIds={updateIncludedIds}
                includeAllSubCounterpartyTypes={includeAllSubCounterpartyTypes}
                allSubCounterpartyIdsIncluded={allCounterpartyTypesAreIncluded}
                showSelectAllLabel={showSelectAllLabel}
            />
            <SubCounterpartyColumn
                columnId='excluded'
                subCounterpartyTypes={excludedSubCounterpartyTypes}
                readOnly={readOnly}
                updateIncludedIds={updateIncludedIds}
                includeAllSubCounterpartyTypes={includeAllSubCounterpartyTypes}
                allSubCounterpartyIdsIncluded={allCounterpartyTypesAreIncluded}
            />
        </div>
    );
};
