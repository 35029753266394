import { flattenDeep, isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { formatDate } from '../../../../utils/luxon';
import { getMLReadyAgreementTypeIds } from '../../../admin/documents/store';
import { BaseEntity } from '../../../admin/entity/store';
import { HiddenFields } from '../../../admin/my-datasets/store';
import { MyRiskTolerance, RiskTolerance } from '../../../admin/risk-tolerance/store';
import { DatasetDefinitionDB } from '../../store';
import { InstanceMLData } from '../../store/mlTypes';
import { isGroupInstanceField } from './typeAssertions';
import { DatasetInstance, DatasetInstanceState, DatasetInstanceTimeline, DocumentToDelete, FormDatasetInstance, Hierarchy, Instance, ModalInstanceParentDetails, OpenFieldSection, SearchFieldSection, WizardFieldOpen } from './types';

const getRoot = (state: ApplicationState): DatasetInstanceState => state.datasetInstance;

export const getDatasetDefinition = (state: ApplicationState): DatasetDefinitionDB | null => getRoot(state).datasetDefinition;

export const getSavedInstances = (state: ApplicationState): Instance[] => getRoot(state).savedInstances;

export const getCurrentInstances = (state: ApplicationState): Instance[] => getRoot(state).currentInstances;

export const getECSDatasetId = (state: ApplicationState): number | null => getRoot(state).ecsDatasetId;

export const getParentDatasetId = (state: ApplicationState): number | null => getRoot(state).parentDatasetId;

export const getParentExecutedDate = (state: ApplicationState): string | undefined => {
    const parentId = getRoot(state).parentDatasetId;
    if (parentId) {
        const parentInstance = getCurrentInstances(state).find(({ instance }) => instance.datasetId === parentId);
        if (parentInstance) {
            return formatDate(parentInstance.instance.executedDate);
        }
    }
    return undefined;
};

export const getDatasetInstance = (state: ApplicationState): Instance | null => {
    const parentDatasetId = getParentDatasetId(state);
    if (!parentDatasetId) {
        return null;
    }
    const savedInstances = getSavedInstances(state);
    return savedInstances.find(({ instance: { datasetId } }) => datasetId === parentDatasetId) || null;
};

export const getDatasetInstanceTimeline = (state: ApplicationState): DatasetInstanceTimeline | null => getRoot(state).timeline;

export const getDatasetInstanceHierarchy = (state: ApplicationState): Hierarchy[] => getRoot(state).hierarchy;

export const getOpenFieldsAndSections = (state: ApplicationState): OpenFieldSection[] => getRoot(state).openFieldsAndSections;

export const getOriginalDocumentId = (state: ApplicationState): number | null => {
    const datasetInstance = getDatasetInstance(state);
    if (!datasetInstance) {
        return null;
    }
    return datasetInstance.instance.originalDocumentId || null;
};

export const getInstanceDocumentId = (state: ApplicationState): number | null => {
    const datasetInstance = getDatasetInstance(state);
    if (!datasetInstance) {
        return null;
    }
    return datasetInstance.instance.documentId;
};

export const getIsEditing = (state: ApplicationState): boolean => getRoot(state).isEditing;

export const getIsUpdating = (state: ApplicationState): boolean => getRoot(state).isUpdating;

export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getInstanceUpdated = (state: ApplicationState): boolean => getDatasetInstanceHierarchy(state).some(({ hasUpdated }) => hasUpdated);

export const getUpdatedInstanceModalOpen = (state: ApplicationState): boolean => getRoot(state).updatedInstanceModalOpen;

export const getConfirmSaveModalOpen = (state: ApplicationState): boolean => getRoot(state).confirmSaveModalOpen;

export const getLinkedDocumentModalOpen = (state: ApplicationState): boolean => getRoot(state).linkedDocumentModalOpen;

export const getAnnexDefinitionFieldIds = (state: ApplicationState): string[] => getRoot(state).annexFieldIds;

export const getWizardFieldOpen = (state: ApplicationState): WizardFieldOpen | null => getRoot(state).wizardFieldOpen;

export const getSelectedLinkedEntities = (state: ApplicationState): string[] => {
    const currentInstances = getCurrentInstances(state);
    const ecsDatasetId = getECSDatasetId(state);
    if (isNull(ecsDatasetId)) {
        return [];
    }
    const ecsFormInstance = currentInstances.find(({ instance }) => !isNull(instance.datasetId) && instance.datasetId === ecsDatasetId)!.instance as FormDatasetInstance;
    const entities = flattenDeep(Object.values(ecsFormInstance.datasetFields)[0].map(field => !isGroupInstanceField(field) && field.settings.linkedEntities || []));
    return entities;
};

export const getAgencyLinkedEntities = (state: ApplicationState): BaseEntity[] => getRoot(state).agencyLinkedEntities;

export const getHiddenFields = (state: ApplicationState): HiddenFields => getRoot(state).hiddenFields;

export const getRiskToleranceForDefinitions = (state: ApplicationState): (RiskTolerance | MyRiskTolerance)[] => getRoot(state).riskToleranceForDefinitions;

export const getShowLegacy = (state: ApplicationState): boolean => getRoot(state).showLegacy;

export const getMLData = (state: ApplicationState): InstanceMLData | null => getRoot(state).mlData;

export const getInstanceContainsMLData = (state: ApplicationState): boolean => !isNull(getMLData(state));

export const getMLDataUnconfirmed = (state: ApplicationState): boolean => {
    const mlData = getMLData(state);
    const agreementTypeIds = getMLReadyAgreementTypeIds(state);
    return !isNull(mlData) && (mlData.versionConfirmed < mlData.version) && (agreementTypeIds.includes(mlData.agreementTypeId));
};

export const getMLDataModalOpen = (state: ApplicationState): boolean => getRoot(state).mlDataModalOpen;

export const getAnnexConfigurationModalOpen = (state: ApplicationState): boolean => getRoot(state).annexConfigurationModalOpen;

export const getIsUpdatingInstanceAnnexDefinitions = (state: ApplicationState): boolean => getRoot(state).isUpdatingInstanceAnnexDefinitions;

export const getAllSectionsAndFields = (state: ApplicationState): SearchFieldSection[] => getRoot(state).allSearchFieldSections;

export const getFieldSectionSearchValue = (state: ApplicationState): string => getRoot(state).fieldSectionSearch;

export const getSearchFuzzyMatches = (state: ApplicationState): SearchFieldSection[] => getRoot(state).searchFuzzyMatches;

export const getSearchFieldSection = (state: ApplicationState): SearchFieldSection | null => getRoot(state).searchFieldSection;

// Modal Instance
export const getCurrentModalInstance = (state: ApplicationState): DatasetInstance | null => getRoot(state).currentModalInstance;

export const getSavedModalInstance = (state: ApplicationState): DatasetInstance | null => getRoot(state).savedModalInstance;

export const getModalInstanceOpen = (state: ApplicationState): boolean => getRoot(state).modalInstanceOpen;

export const getModalInstanceLoading = (state: ApplicationState): boolean => getRoot(state).modalInstanceLoading;

export const getModalInstanceSaving = (state: ApplicationState): boolean => getRoot(state).modalInstanceSaving;

export const getModalInstanceUpdated = (state: ApplicationState): boolean => getRoot(state).modalInstanceUpdated;

export const getModalInstanceParentDetails = (state: ApplicationState): ModalInstanceParentDetails | null => getRoot(state).modalInstanceParentDetails;

export const getSecondaryDocumentToDelete = (state: ApplicationState): DocumentToDelete | null => getRoot(state).secondaryDocumentToDelete;

export const getDeleteSecondaryDocumentModalOpen = (state: ApplicationState): boolean => !isNull(getSecondaryDocumentToDelete(state));

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;
