import React, { useMemo } from 'react';
import zxcvbn from 'zxcvbn';

import { useAppDispatch } from '../../../hooks/react-redux';
import styles from './ResetPassword.module.scss';
import { setPasswordScore } from './store';

const { grey, red, amber, yellowGreen, green } = styles;

interface PasswordStrengthCheckerProps {
    password: string;
    passwordScoreColour?: string;
    width?: string;
}

interface PasswordStrength {
    wording: string;
    wordColour: string;
    barColours: string[];
}

const scoreColours = {
    0: { colour: red, label: 'Very Weak' },
    1: { colour: red, label: 'Weak' },
    2: { colour: amber, label: 'Okay' },
    3: { colour: yellowGreen, label: 'Good' },
    4: { colour: green, label: 'Strong' },
};

export const PasswordStrengthChecker: React.FC<PasswordStrengthCheckerProps> = ({ password, passwordScoreColour, width = '375px' }) => {
    const dispatch = useAppDispatch();
    const { score } = zxcvbn(password);
    useMemo(() => dispatch(setPasswordScore(score)), [dispatch, score]);

    const { barColours, wordColour, wording } = useMemo(() => {
        const passwordStrength: PasswordStrength = {
            wording: '',
            wordColour: '',
            barColours: [grey, grey, grey, grey]
        };

        if (!password.length || password.length < 8) {
            return passwordStrength;
        }
        const { label, colour } = scoreColours[score];
        passwordStrength.wording = label;
        passwordStrength.wordColour = passwordScoreColour || colour;
        let i = 0;
        while (i < score) {
            passwordStrength.barColours[i] = colour;
            i++;
        }

        return passwordStrength;
    }, [score, password, passwordScoreColour]);

    return (
        <div className={styles.passwordStrengthWrapper}>
            <div className={styles.barsWrapper} style={{ width: width }}>
                {barColours.map((colour: string, index: number) => (<div key={index} className={styles.bar} style={{ backgroundColor: colour }} />))}
            </div>
            <div className={styles.passwordStrength} style={{ color: wordColour, width: width }}>
                {wording}
            </div>
        </div>
    );
};
