import { RawDraftContentState } from 'draft-js';

export enum SendNotificationActionTypes {
    UPDATE_NOTIFICATION_TITLE = 'UPDATE_NOTIFICATION_TITLE',
    UPDATE_NOTIFICATION_CONTENT = 'UPDATE_NOTIFICATION_CONTENT',
    UPDATE_NOTIFICATION_CLIENT_IDS = 'UPDATE_NOTIFICATION_CLIENT_IDS',
    UPDATE_NOTIFICATION_ADMIN_ONLY = 'UPDATE_NOTIFICATION_ADMIN_ONLY',
    SEND_NOTIFICATION_STARTED = 'SEND_NOTIFICATION_STARTED',
    SEND_NOTIFICATION_SUCCESSFUL = 'SEND_NOTIFICATION_SUCCESSFUL',
    SEND_NOTIFICATION_FAILED = 'SEND_NOTIFICATION_FAILED',
    TOGGLE_NOTIFICATION_PREVIEW = 'TOGGLE_NOTIFICATION_PREVIEW',
    RESET_NOTIFICATION_CONTENT = 'RESET_NOTIFICATION_CONTENT'
}

export interface NotificationTemplate {
    title: string;
    content: RawDraftContentState | null;
    clientIds: number[];
    adminOnly: boolean;
}

export interface SendNotificationState {
    title: string;
    content: RawDraftContentState | null;
    clientIds: number[];
    adminOnly: boolean;
    isSending: boolean;
    sendError: string | null;
    previewOpen: boolean;
}
