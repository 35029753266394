import React, { useCallback, useMemo } from 'react';
import { isNull, isUndefined } from 'lodash/fp';
import classnames from 'classnames';

import styles from '../../Analytics.module.scss';
import { DocumentDatasetRiskTolerance } from '../../store';
import { DatasetType } from '../../../../datasets/store';
import { DocumentInstanceRiskField, TableInstanceRiskField, DocumentRiskBreakdown } from '../../store/types';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { DatasetFieldType } from '../../../../datasets/store/index';
import { formatDate } from '../../../../../utils/luxon';
import { FieldValue } from '../../../../datasets/instances/store';
import { OverflowTooltip } from '../../../../shared/tooltip';
import { HorizontalWeightingSlider } from '../../../../shared/risk-tolerance/WeightingSlider';
import { documentRiskLevelLabel } from './DocumentRiskToleranceLevel';
import { Button } from '../../../../shared/button/Button';
import { CurrencyValue } from '../../../../shared/number/CurrencyNumber';

export const getFieldValue = (fieldValue: FieldValue, type: DatasetFieldType) => {
    if (type === DatasetFieldType.CHECKBOX) {
        return fieldValue ? 'True' : 'False';
    }
    if (isNull(fieldValue) || isUndefined(fieldValue) || fieldValue === '') {
        return 'No Value Provided';
    }
    if (type === DatasetFieldType.DROPDOWN) {
        return (fieldValue as string[]).toString().split(',').join(', ');
    }
    if (type === DatasetFieldType.DATE) {
        return formatDate(fieldValue as string);
    }
    if (type === DatasetFieldType.NUMBER) {
        return (fieldValue as number).toString();
    }
    if (type === DatasetFieldType.TIME) {
        if ((fieldValue as string[]).every(element => element === '')) {
            return 'No Value Provided';
        }
        return (fieldValue as string[]).toString().split(',').join(', ');
    }
    if (type === DatasetFieldType.CURRENCY_AMOUNT) {
        const { currency, value } = (fieldValue as CurrencyValue);
        if (!isNull(currency) && !isUndefined(value)) {
            return `${currency}: ${value}`;
        }
        return 'No value Provided';
    }
    return '';
};

interface DocumentRiskToleranceBreakdownProps {
    documentRiskFields: DocumentDatasetRiskTolerance[] | undefined;
    height: number;
    width: number;
    setShowBreakdown: () => void;
}

export const DocumentRiskToleranceBreakdown: React.FC<DocumentRiskToleranceBreakdownProps> = ({ documentRiskFields, height, width, setShowBreakdown }) => {

    const getFieldInformation = useCallback((datasetId: number, datasetType: DatasetType, riskField: DocumentInstanceRiskField, rowId?: string, rowLabel?: string): DocumentRiskBreakdown => {
        const { label, weighting, fieldId, riskLevel, type, sectionId, value } = riskField;
        const formattedValue = getFieldValue(value, type);
        const subId = rowId ? { rowId, rowLabel } : { sectionId };
        return ({
            datasetType,
            datasetId,
            fieldId,
            fieldType: type,
            label,
            formattedValue,
            weighting,
            riskLevel,
            ...subId
        });
    }, []);

    const breakdown = useMemo(() => {
        if (isUndefined(documentRiskFields)) {
            return null;
        }
        const breakdown = documentRiskFields.reduce((acc: DocumentRiskBreakdown[], documentRiskField) => {
            const { datasetId, includedFields, type } = documentRiskField;
            if (type === DatasetType.FORM) {
                (includedFields as DocumentInstanceRiskField[]).forEach(field => {
                    const riskField = getFieldInformation(datasetId, type, field);
                    acc.push(riskField);
                });
            } else {
                const tableRiskFields = (includedFields as TableInstanceRiskField[])[0].riskFields;
                tableRiskFields.forEach((_, riskFieldIndex) => {
                    (includedFields as TableInstanceRiskField[]).forEach(field => {
                        const { rowId, riskFields, rowLabel } = field;
                        const riskField = riskFields[riskFieldIndex];
                        const tableRiskField = getFieldInformation(datasetId, type, riskField, rowId, rowLabel);
                        acc.push(tableRiskField);
                    });
                });
            }
            return acc;
        }, []).sort((a, b) => b.riskLevel - a.riskLevel || b.weighting - a.weighting);
        return breakdown;
    }, [getFieldInformation, documentRiskFields]);

    const testId = 'document-risk-tolerance-analytics-document-breakdown-field';
    const getDocumentRiskLevel = useCallback((riskLevel: number) => documentRiskLevelLabel(riskLevel), []);

    const breakdownWidth = useMemo(() => width / 2, [width]);
    const breakdownHeight = useMemo(() => height - 80, [height]);

    if (isNull(breakdown)) {
        return null;
    }

    return (
        <div className={styles.breakdownWrapper} style={{ height: `${breakdownHeight}px`, width: `${breakdownWidth}px` }}>
            <Scrollable height={`${breakdownHeight - 45}px`}>
                {breakdown.map((field, index) => {
                    const { riskLevel, rowLabel, formattedValue, weighting, fieldId, label } = field;
                    const uniqueFieldId = `${fieldId}-${index}`;
                    const { colour } = getDocumentRiskLevel(riskLevel);
                    const noValue = formattedValue === 'No Value Provided';
                    return (
                        <div key={uniqueFieldId} className={styles.riskFieldWrapper} style={{ border: `solid 2px ${colour}` }}>
                            <div className={styles.riskLevel} style={{ backgroundColor: colour }} />
                            <div className={styles.fieldAndWeightWrapper}>
                                <div className={styles.fieldWrapper}>
                                    <OverflowTooltip className={styles.fieldLabel} overlayText={label} testId={`${testId}-label`} />
                                    <div className={styles.documentAnswerWrapper}>
                                        <div className={styles.fieldAnswerWrapper}>
                                            <div className={styles.fieldAnswerLabel}>Document Value:</div>
                                            {!isUndefined(rowLabel) && <div className={styles.rowLabel}>({rowLabel})</div>}
                                            <OverflowTooltip className={classnames(styles.fieldAnswer, { [styles.fieldAnswerNoValue]: noValue })} overlayText={formattedValue} testId={`${testId}-answer`} />
                                        </div>
                                    </div>
                                </div>
                                <HorizontalWeightingSlider weighting={weighting} />
                            </div>
                        </div>
                    );
                })}
            </Scrollable>
            <Button label='Back' onClick={setShowBreakdown} />
        </div>
    );
};
