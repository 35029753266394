import React, { useMemo, useState } from 'react';
import { set } from 'lodash/fp';

import styles from './SearchDocuments.module.scss';
import { Button } from '../../shared/button/Button';
import { getSmartSearchTerm, initialSmartSearchTerm, LogicalOperator, resetSearch, searchDocumentsStarted, SmartSearch as SmartSearchType, updateSmartSearch } from '../search/store';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { SmartSearchRow } from './SmartSearchRow';
import { PlusButton } from '../../shared/button/PlusButton';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { useWindowResize } from '../../../hooks/useWindowResize';

const NAV_BAR = 100;
const SEARCH_BUTTONS = 48;
const SEARCH_HEADER = 35;
const PADDING = 60;
const HEIGHT_OFFSET = NAV_BAR + SEARCH_BUTTONS + SEARCH_HEADER + PADDING;
interface SimpleSearchProps {
    page: number;
}

export const SmartSearch: React.FC<SimpleSearchProps> = ({ page }) => {
    const dispatch = useAppDispatch();
    const [, screenHeight] = useWindowResize();
    const storedSmartSearchTerm = useAppSelector(getSmartSearchTerm);
    const [smartSearchTerm, setSmartSearchTerm] = useState<SmartSearchType[]>(storedSmartSearchTerm);
    const resetDisabled = storedSmartSearchTerm.every(({ searchTerm }) => searchTerm.length === 0);
    const smartSearchHeight = useMemo(() => screenHeight - HEIGHT_OFFSET, [screenHeight]);

    const startDocumentSearch = () => {
        dispatch(updateSmartSearch(smartSearchTerm));
        dispatch(searchDocumentsStarted(page));
    };

    const addSearchTermRow = () => setSmartSearchTerm([...smartSearchTerm, initialSmartSearchTerm]);

    const updateValue = (key: keyof SmartSearchType, value: string, rowIndex: number) => {
        const updatedSmartSearch = smartSearchTerm.map((row, index) => index === rowIndex ? set(`${key}`, value, row) : row);
        setSmartSearchTerm(updatedSmartSearch);
    };

    const removeRow = (rowIndex: number) => setSmartSearchTerm(smartSearchTerm.filter((_, index) => index !== rowIndex));

    const resetSearchTerm = () => {
        setSmartSearchTerm([initialSmartSearchTerm]);
        dispatch(resetSearch());
    };

    return (
        <div className={styles.smartSearchWrapper} data-testid='smart-search-wrapper'>
            <div className={styles.smartInputWrapper}>
                <Scrollable maxHeight={`${smartSearchHeight}px`}>
                    {smartSearchTerm.map((row, index) => {
                        const isFirstRow = index === 0;
                        const showDelete = smartSearchTerm.length !== 1 && !isFirstRow;
                        const isOr = row.logicalOperator === LogicalOperator.OR;
                        return (
                            <div key={index}>
                                {isOr && <div key={index} className={styles.conditionSeparator}></div>}
                                <SmartSearchRow
                                    key={index}
                                    index={index}
                                    isFirstRow={isFirstRow}
                                    showDelete={showDelete}
                                    removeRow={removeRow}
                                    updateValue={updateValue}
                                    row={row}
                                />
                            </div>
                        );
                    })}
                </Scrollable>
                <div className={styles.buttonWrapper}>
                    <PlusButton testId='add-search-term' onClick={addSearchTermRow} fontSize={20} />
                    <div className={styles.smartSearchButtonWrapper}>
                        <Button label='Reset' onClick={resetSearchTerm} disabled={resetDisabled} testId='reset-document-smart-search' />
                        <Button label='Search' onClick={startDocumentSearch} testId='document-smart-search' />
                    </div>
                </div>
            </div>
        </div>
    );
};
