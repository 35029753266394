import classnames from 'classnames';
import CSS from 'csstype';
import React, { useMemo } from 'react';
import { indexOf, isUndefined } from 'lodash/fp';

import { SupportDocumentsSelect } from '../../documents/analysis/SupportDocumentsSelect';
import { DocumentViewer } from '../../documents/document-viewer/DocumentViewer';
import { AnalysisView, ArkDocument, LinkedDocument, SelectedDocument } from '../../documents/my-documents/store';
import { Spinner } from '../spinner/Spinner';
import { OverflowTooltip } from '../tooltip';
import styles from './Analysis.module.scss';
import { ArkOpinion } from '../../opinions/my-opinions/store';
import { capitaliseStartLetter } from '../../../utils/regex-utils';

export interface Tabs {
    title: string;
    type?: string;
    onClick: () => void;
    selected: boolean;
    disabled?: boolean;
}

interface TabProps extends Tabs {
    align?: CSS.Property.TextAlign;
    numberOfTabs: number;
    indexOfSelected: number;
    index: number;
}

const Tab: React.FC<TabProps> = ({ title, onClick, selected, align = 'left', disabled = false, numberOfTabs, indexOfSelected, index, type }) => {
    const width = useMemo(() => {
        if (selected) {
            return 50;
        }
        return 50 / (numberOfTabs - 1);
    }, [selected, numberOfTabs]);

    return (
        <button
            className={classnames(styles.documentTabWrapper, {
                [styles.selectedTabWrapper]: selected,
                [styles.unselectedTabWrapper]: !selected,
                [styles.leftBoxShadow]: index > indexOfSelected && title.length,
                [styles.rightBoxShadow]: index < indexOfSelected && title.length,
                [styles.tabDisabled]: disabled,
            })}
            onClick={onClick}
            style={{ maxWidth: `${width}%`, width: `${width}%` }}
            disabled={disabled}
        >
            {!isUndefined(type) &&
                <div
                    className={classnames(styles.documentTabType, {
                        [styles.selectedTabType]: selected,
                        [styles.tabTypeDisabled]: disabled
                    })}
                    style={{ textAlign: align }}
                >
                    <OverflowTooltip overlayText={capitaliseStartLetter(type)} />
                </div>
            }
            <div
                className={classnames(styles.documentTabTitle, {
                    [styles.selectedTabTitle]: selected,
                    [styles.tabTitleDisabled]: disabled
                })}
                style={{ textAlign: align }}
            >
                <OverflowTooltip overlayText={title} />
            </div>
        </button>
    );
};

interface AnalysisProps {
    selected: ArkOpinion | ArkDocument | LinkedDocument | null;
    url: string | null;
    documentInView: SelectedDocument | null;
    isOpening: boolean;
    tabOptions: Tabs[];
    documentAnalysisView: AnalysisView;
    selectedPage?: number | null;
    showScheduleStartPage?: boolean;
    setSelectedPage?: (page: number | null) => void;
    scheduleStartPage?: number;
    width: number;
}

export const Analysis: React.FC<AnalysisProps> = ({ documentInView, isOpening, url, tabOptions, selected, selectedPage, showScheduleStartPage, setSelectedPage, scheduleStartPage, width }) => {
    const numberOfTabs = tabOptions.length;

    const indexOfSelected = useMemo(() => indexOf(true, tabOptions.map(({ selected }) => selected)), [tabOptions]);

    if (!documentInView || isOpening || !url) {
        return (
            <div className={styles.spinnerWrapper} data-testid='analysis-spinner-wrapper'>
                <Spinner size={70} />
            </div>
        );
    }

    return (
        <>
            <div className={styles.documentAnalysisHeader}>
                {tabOptions.map(({ title, onClick, selected, disabled, type }, index) => {
                    const align = index !== 0 ? 'right' : 'left';
                    return (
                        <Tab
                            key={index}
                            title={title}
                            type={type}
                            onClick={onClick}
                            selected={selected}
                            align={align}
                            disabled={disabled}
                            numberOfTabs={numberOfTabs}
                            indexOfSelected={indexOfSelected}
                            index={index}
                        />
                    );
                })}
            </div>
            {documentInView === SelectedDocument.SUPPORT && <SupportDocumentsSelect />}
            <div className={styles.documentAnalysis}>
                {documentInView && selected &&
                    <DocumentViewer
                        url={url}
                        selectedDocument={selected}
                        width={width}
                        selectedPage={selectedPage}
                        showScheduleStartPage={showScheduleStartPage}
                        setSelectedPage={setSelectedPage}
                        scheduleStartPage={scheduleStartPage}
                    />
                }
            </div>
        </>
    );
};
