import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getUserRole } from '../../auth/login/store';
import { adminRoles } from '../../constants/permittedRoles';
import { Icon } from '../../shared/icon/Icon';
import { Document, WarningSign } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { scopeFormatter } from '../../shared/table/arkTableFormatters';
import styles from './Opinions.module.scss';
import { ArkOpinion, LinkedOpinion, enquireOpinionStarted } from './store';
import { OpinionCommissionedBy } from '../../admin/opinions/store';

interface UnauthorisedOpinionModalProps {
    closeModal: () => void;
    opinion: LinkedOpinion | ArkOpinion;
}

export const UnauthorisedOpinionModal: React.FC<UnauthorisedOpinionModalProps> = ({ closeModal, opinion }) => {
    const dispatch = useAppDispatch();
    const userRole = useAppSelector(getUserRole);
    const isAdminUser = adminRoles.includes(userRole!);
    const { agreementType, commissionedBy, commissionedByIfOther, jurisdiction, scope, opinionId, focus } = opinion;

    const messageOne = useMemo(() => isAdminUser ? 'If you would like to know more about this opinion and the cost associated with accessing this content.' : 'If you think this opinion would be of interest to your company, please reach out to your team administrator', [isAdminUser]);
    const messageTwo = useMemo(() => isAdminUser ? 'Please click the \'Enquire\' button below to send an enquiry email and someone from our team will be in touch' : 'They can use this page to send an enquiry via the button below and someone from our team will be in touch', [isAdminUser]);

    const sendEnquiry = useCallback(() => { dispatch(enquireOpinionStarted(opinionId)); }, [dispatch, opinionId]);

    return (
        <ConfirmationModal
            isOpen
            closeModal={closeModal}
            confirmLabel='Enquire'
            testId='unauthorised-opinion'
            confirm={sendEnquiry}
            showConfirm={isAdminUser}
        >
            <ModalHeader label='Unauthorised' icon={WarningSign} />
            <div className={styles.unauthorisedOpinionWrapper}>
                <div className={styles.warningHeader}>You do not currently have access to view this opinion</div>
                <div className={styles.unauthorisedOpinion}>
                    <div className={styles.unauthorisedOpinionIcon}><Icon icon={Document} fontSize={60} /></div>
                    <div className={styles.unauthorisedOpinionDetails}>
                        <div className={styles.jurisdiction}>{jurisdiction}</div>
                        <div className={styles.focusAndAgreementType}>
                            <div className={styles.agreementType}>{agreementType}</div>
                            <div className={styles.focus}>{focus}</div>
                        </div>
                        <div className={styles.scopeAndCommissionedBy}>
                            <div className={styles.scope}>Scope: {scopeFormatter(scope)}</div>
                            <div className={styles.commissionedBy}>Commissioned By: {commissionedBy === OpinionCommissionedBy.OTHER ? commissionedByIfOther : commissionedBy}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.unauthorisedOpinionAccess}>{messageOne}</div>
                <div className={styles.unauthorisedOpinionAccess}>{messageTwo}</div>
            </div>
        </ConfirmationModal>
    );
};
