import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './HomeWrapper.module.scss';

interface SideMenuItemProps {
    label: string;
    onClick?: () => void;
    selected: boolean;
    textAlign?: string;
    path?: string;
    testId?: string;
    isChild?: boolean;
    disabled?: boolean;
}

const SideMenuButton: React.FC<SideMenuItemProps> = ({ label, onClick, selected, textAlign = 'left', testId, isChild, disabled = false }) => (
    <button
        className={classnames(
            styles.sideMenuItem,
            {
                [styles.selectedSideMenuItem]: selected,
                [styles.leftMenuItem]: textAlign === 'left',
                [styles.rightMenuItem]: textAlign === 'right',
                [styles.childItemPaddingLeft]: isChild && textAlign === 'left',
                [styles.disabledMenuItem]: disabled
            })}
        onClick={onClick}
        data-testid={`side-menu-item-${testId}-button`}
    >
        {label}
    </button>
);

export const SideMenuItem: React.FC<SideMenuItemProps> = ({ label, onClick, selected, textAlign = 'left', path, testId, isChild = false, disabled = false }) => {
    if (path && !disabled) {
        return (
            <Link to={path}>
                <SideMenuButton
                    onClick={onClick}
                    label={label}
                    selected={selected}
                    textAlign={textAlign}
                    testId={testId}
                    isChild={isChild}
                    disabled={disabled}
                />
            </Link>
        );
    } else {
        return (
            <SideMenuButton
                onClick={onClick}
                label={label}
                selected={selected}
                textAlign={textAlign}
                testId={testId}
                isChild={isChild}
                disabled={disabled}
            />
        );
    }
};
