import React from 'react';
import Select, { ActionMeta, Options, StylesConfig } from 'react-select';

import styles from './UploadDocument.module.scss';

export interface DocumentNameDropdownOption {
    documentNameId: string;
    agreementType: string | null;
    agreementTypeId: number | null;
    label: string;
    agencyDatasetId: number | null;
}

export type DropdownOptions = Options<DocumentNameDropdownOption> | Options<DocumentNameDropdownOption>[] | undefined

interface DocumentNameDropdownProps {
    value: DocumentNameDropdownOption[] | DocumentNameDropdownOption | null;
    onChange: (value: DocumentNameDropdownOption | Options<DocumentNameDropdownOption> | null, action: ActionMeta<DocumentNameDropdownOption>) => void;
    options: DocumentNameDropdownOption[];
    isMulti?: boolean;
    disabled?: boolean;
    testId?: string;
    placeholder?: string;
    label?: string;
    isClearable?: boolean;
}

const { primary, french, white, disabledGrey, fontFamily, disabledBackground, grey } = styles;

type IsMulti = boolean;
export const customStyles: StylesConfig<DocumentNameDropdownOption, IsMulti> = {
    option: (provided, state) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '3px 5px',
        color: primary,
        backgroundColor: state.isFocused ? french : white,
        fontSize: '13px',
        fontFamily: fontFamily
    }),
    control: (provided, state) => ({
        ...provided,
        fontWeight: state.selectProps.fontWeight,
        maxHeight: state.selectProps.maxControlHeight,
        minHeight: state.selectProps.minControlHeight,
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary,
        backgroundColor: state.isDisabled ? disabledBackground : 'white',
        border: `1px solid ${state.isDisabled ? disabledGrey : primary}`,
        borderRadius: '5px',
        fontSize: '13px',
        fontFamily: fontFamily,
        boxShadow: 'none'
    }),
    menu: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontFamily: fontFamily,
        zIndex: 11
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 11 }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: state.selectProps.hideIndicator ? 'none' : 'flex'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0 3px',
        maxHeight: `calc(${state.selectProps.maxControlHeight} - 2px)`,
        minHeight: `calc(${state.selectProps.minControlHeight} - 2px)`,
        justifyContent: 'flex-start'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary
    }),
    multiValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary
    }),
    menuList: (provided) => ({
        ...provided,
        '::-webkit-scrollbar': {
            width: '4px',
            height: '0px',
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-track': {
            background: disabledBackground,
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb': {
            background: disabledGrey,
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: grey
        }
    })
};

export const DocumentNameDropdown: React.FC<DocumentNameDropdownProps> = ({ value, disabled = false, onChange, options, placeholder, isClearable = true }) => (
    <Select
        classNamePrefix='ark-dropdown'
        isDisabled={disabled}
        value={value}
        onChange={onChange}
        isMulti={false}
        options={options}
        isClearable={isClearable}
        placeholder={placeholder}
        styles={customStyles}
        fontWeight={500}
    />
);
