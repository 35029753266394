import React from 'react';
import Modal from 'react-modal';

import styles from './ClauseTagsModal.module.scss';

interface ClauseModalProps {
    isOpen: boolean;
    closeModal: () => void;
    shouldCloseOnOverlayClick?: boolean
    shouldCloseOnEsc?: boolean;
    children?: React.ReactNode;
}

export const ClauseTagsModal: React.FC<ClauseModalProps> = ({
    isOpen,
    closeModal,
    shouldCloseOnOverlayClick = false,
    shouldCloseOnEsc = false,
    children
}) => (
    <Modal
        isOpen={isOpen}
        className={styles.clauseTagsModal}
        ariaHideApp={false}
        style={{ overlay: { display: 'flex', zIndex: 9 } }}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        onRequestClose={closeModal}
    >
        {children}
    </Modal>
);
