import React, { useEffect, useMemo, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { AttestationForms } from './form/AttestationForms';
import { AttestationManager } from './manager/AttestationManager';
import styles from './Attestations.module.scss';
import { AttestationsSelect } from './AttestationsSelect';
import { AttestationPage, getAttestationPage, setAttestationPage } from './store';

export interface AttestationRouteParams {
    attestationFormId: string | undefined;
    attestationInstanceId: string | undefined;
}

export const Attestations: React.FC<RouteComponentProps<AttestationRouteParams>> = (props) => {
    const attestationPage = useAppSelector(getAttestationPage);
    const dispatch = useAppDispatch();
    const resetPageView = useCallback(() => dispatch(setAttestationPage(AttestationPage.SELECT)), [dispatch]);

    useEffect(() => () => {
        resetPageView();
    }, [resetPageView]);

    const pageContent = useMemo(() => {
        switch (attestationPage) {
            case AttestationPage.SELECT:
            default:
                return <AttestationsSelect />;
            case AttestationPage.FORMS:
                return <AttestationForms {...props} />;
            case AttestationPage.MANAGER:
                return <AttestationManager {...props} />;
        }
    }, [attestationPage, props]);

    return (
        <div className={styles.adminAttestationsWrapper} data-testid='admin-attestations-wrapper'>
            {pageContent}
        </div>
    );
};
