import { flattenDeep, isEqual, noop, uniq } from 'lodash/fp';
import React, { useCallback, useState } from 'react';

import { DocumentSpecificHiddenFields } from '../../admin/dataset-builder/store';
import { DocumentNameDB } from '../../admin/documents/store';
import { HiddenField, HiddenFields, HiddenSection, isHiddenField } from '../../admin/my-datasets/store';
import { MyRiskTolerance, RiskTolerance as RiskToleranceType, SelectedRiskField } from '../../admin/risk-tolerance/store';
import { DatasetDefinition, DatasetSection, FormDatasetDefinition, FormDatasetFields, SingleDatasetField, isDatasetField, isFormDatasetDefinition, isGroupField } from '../../datasets/store';
import { Scrollable } from '../scrollable/Scrollable';
import { DatasetMenu } from './menu/DatasetMenu';

export interface DatasetFieldSection {
    field: SingleDatasetField;
    sectionId: string | null;
}

export const flattenFormDefinitionFields = (fields: FormDatasetFields) => flattenDeep(Object.entries(fields).map(([sectionId, section]) => section.map(field => isGroupField(field) ? field.children.map(field => ({ field, sectionId })) : { field, sectionId }))) as DatasetFieldSection[];

interface RiskToleranceDatasetsMenuProps {
    datasetId: number;
    setRiskToleranceField: (riskField: SelectedRiskField) => void;
    riskTolerance: (RiskToleranceType | MyRiskTolerance)[] | null;
    selectedField: SelectedRiskField | null;
    datasetDefinitions: DatasetDefinition[];
    hiddenFields?: HiddenFields;
    showRiskFieldToggles?: boolean;
    toggleRiskField?: (datasetId: number, fieldId: string) => void;
    removeClientHiddenFields?: boolean;
    documentHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    agreementTypeDatasetId: number | null;
}

export const RiskToleranceDatasetsMenu: React.FC<RiskToleranceDatasetsMenuProps> = ({
    datasetId,
    setRiskToleranceField,
    riskTolerance,
    selectedField,
    datasetDefinitions,
    hiddenFields,
    showRiskFieldToggles = false,
    toggleRiskField = noop,
    removeClientHiddenFields = false,
    documentHiddenFields,
    allDocumentNames,
    agreementTypeDatasetId
}) => {
    const [openDatasets, setOpenDatasets] = useState<number[]>([datasetId]);
    const [openSections, setOpenSections] = useState<{ sectionId: string | null; datasetId: number; }[]>([]);

    const datasetSectionOpen = useCallback((datasetId: number) => openDatasets.includes(datasetId), [openDatasets]);

    const toggleDatasetOpen = useCallback((datasetId: number) => {
        if (datasetSectionOpen(datasetId)) {
            setOpenDatasets(openDatasets.filter(id => id !== datasetId));
            setOpenSections(openSections.filter(openSection => openSection.datasetId !== datasetId));
        } else {
            setOpenDatasets([...openDatasets, datasetId]);
        }
    }, [openDatasets, openSections, setOpenDatasets, datasetSectionOpen, setOpenSections]);

    const getSectionOpen = useCallback((sectionId: string | null, datasetId: number) => !!openSections.find(section => isEqual(section, { sectionId, datasetId })), [openSections]);

    const getHiddenFieldsByDatasetId = useCallback((datasetId: number) => hiddenFields ? hiddenFields[datasetId] : [], [hiddenFields]);

    const toggleSectionOpen = useCallback((sectionId: string | null, datasetId: number) => {
        if (getSectionOpen(sectionId, datasetId)) {
            const datasetDefinition = datasetDefinitions.find(definition => definition.datasetId === datasetId) as FormDatasetDefinition;
            const sectionDatasetIds = flattenFormDefinitionFields(datasetDefinition.datasetFields).filter(formField => sectionId === formField.sectionId && isDatasetField(formField.field)).map(({ field: { settings } }) => parseInt(settings.datasetLinked!));
            setOpenDatasets(openDatasets.filter(datasetId => !sectionDatasetIds.includes(datasetId)));
            setOpenSections(openSections.filter(section => !isEqual(section, { sectionId, datasetId }) && !sectionDatasetIds.includes(section.datasetId)));
        } else {
            setOpenSections([...openSections, { sectionId, datasetId }]);
        }
    }, [openSections, setOpenSections, getSectionOpen, setOpenDatasets, openDatasets, datasetDefinitions]);

    const getDatasetRiskFields = useCallback((id: number, selectedSectionId: string | null) => {
        if (riskTolerance) {
            const datasetWithRiskFields = riskTolerance.find(({ datasetId }) => datasetId === id);
            const hiddenFields = getHiddenFieldsByDatasetId(id).filter(field => isHiddenField(field)) as HiddenField[];
            if (datasetWithRiskFields) {
                if (removeClientHiddenFields) {
                    return datasetWithRiskFields.config.filter(({ includeRiskField }) => includeRiskField).filter(({ sectionId, fieldId }) => sectionId === selectedSectionId && !hiddenFields.map(({ fieldId }) => fieldId).includes(fieldId));
                }
                return datasetWithRiskFields.config.filter(({ sectionId, fieldId }) => sectionId === selectedSectionId && !hiddenFields.map(({ fieldId }) => fieldId).includes(fieldId));
            }
        }
        return [];
    }, [riskTolerance, getHiddenFieldsByDatasetId, removeClientHiddenFields]);

    const selectRiskField = useCallback((fieldId: string, datasetId: number) => setRiskToleranceField({ fieldId, datasetId }), [setRiskToleranceField]);

    const getChildDatasetIds = useCallback((id: string): string[] => {
        const datasetDefinition = datasetDefinitions.find(({ datasetId }) => datasetId === parseInt(id));
        if (datasetDefinition) {
            if (isFormDatasetDefinition(datasetDefinition)) {
                const flattenedFields = flattenFormDefinitionFields(datasetDefinition.datasetFields);
                return flattenedFields.filter(({ field }) => isDatasetField(field)).map(({ field: { settings } }) => settings.datasetLinked!);
            }
            return datasetDefinition.datasetFields.filter(field => isDatasetField(field)).map(({ settings }) => settings.datasetLinked!);
        }
        return [];
    }, [datasetDefinitions]);

    const getChildDatasetFields = useCallback((datasetIds: string[]) => {
        const childDatasetIds = datasetIds.reduce((acc: string[], datasetId) => {
            const childDatasetIds = getChildDatasetIds(datasetId);
            return [...acc, ...childDatasetIds];
        }, []);
        return childDatasetIds;
    }, [getChildDatasetIds]);

    const getAllDatasetsByParentId = useCallback((datasetId: string) => {
        const childDatasetIds = getChildDatasetIds(datasetId);
        let allDatasetIds = [datasetId, ...childDatasetIds];
        let currentChildDatasetIds = childDatasetIds;

        while (currentChildDatasetIds.length) {
            const childDatasetIds = getChildDatasetFields(currentChildDatasetIds);
            currentChildDatasetIds = childDatasetIds;
            allDatasetIds = allDatasetIds.concat(childDatasetIds);
        }
        return uniq(allDatasetIds);
    }, [getChildDatasetFields, getChildDatasetIds]);

    const datasetIncludesRiskFields = useCallback((id: string) => {
        const allChildDatasets = getAllDatasetsByParentId(id);
        return riskTolerance && riskTolerance.map(({ datasetId }) => datasetId).some(riskDataset => allChildDatasets.includes(riskDataset.toString()));
    }, [getAllDatasetsByParentId, riskTolerance]);

    const sectionIncludesRiskFields = useCallback((id: string | null, definition: FormDatasetDefinition) => {
        const sectionDatasetId = definition.datasetId!;
        const riskToleranceForDataset = riskTolerance ? riskTolerance.find(({ datasetId }) => datasetId === sectionDatasetId) : undefined;
        const sectionDatasetFields = flattenFormDefinitionFields(definition.datasetFields).filter(({ sectionId, field }) => sectionId === id && isDatasetField(field));
        return sectionDatasetFields.some(({ field: { settings } }) => datasetIncludesRiskFields(settings.datasetLinked!)) || (riskToleranceForDataset && !!riskToleranceForDataset.config.find(({ sectionId }) => sectionId === id));
    }, [datasetIncludesRiskFields, riskTolerance]);

    const getDatasetFields = useCallback((id: number, selectedSectionId: string | null): DatasetFieldSection[] => {
        const datasetDefinition = datasetDefinitions.find(({ datasetId }) => datasetId === id);
        if (datasetDefinition) {
            const hiddenFields = getHiddenFieldsByDatasetId(id).filter(field => isHiddenField(field)) as HiddenField[];
            if (isFormDatasetDefinition(datasetDefinition)) {
                const flattenedFields = flattenFormDefinitionFields(datasetDefinition.datasetFields);
                return flattenedFields.filter(({ field, sectionId }) => isDatasetField(field) && sectionId === selectedSectionId && datasetIncludesRiskFields(field.settings.datasetLinked!) && !hiddenFields.map(({ fieldId }) => fieldId).includes(field.id!));
            }
            return datasetDefinition.datasetFields.filter(field => isDatasetField(field) && datasetIncludesRiskFields(field.settings.datasetLinked!) && !hiddenFields.map(({ fieldId }) => fieldId).includes(field.id!)).map(field => ({ field, sectionId: null }));
        }
        return [];
    }, [datasetDefinitions, datasetIncludesRiskFields, getHiddenFieldsByDatasetId]);

    const getDatasetSections = useCallback((id: number): DatasetSection[] => {
        const datasetDefinition = datasetDefinitions.find(({ datasetId }) => datasetId === id);
        if (datasetDefinition) {
            if (isFormDatasetDefinition(datasetDefinition)) {
                const hiddenSections = getHiddenFieldsByDatasetId(id).filter(field => !isHiddenField(field)) as HiddenSection[];
                return datasetDefinition.datasetSections.filter(({ id }) => sectionIncludesRiskFields(id, datasetDefinition) && !hiddenSections.map(({ sectionId }) => sectionId).includes(id));
            }
        }
        return [];
    }, [datasetDefinitions, sectionIncludesRiskFields, getHiddenFieldsByDatasetId]);

    return (

        <Scrollable>
            <DatasetMenu
                parentDatasetId={datasetId}
                getDatasetFields={getDatasetFields}
                getDatasetSections={getDatasetSections}
                datasetSectionOpen={datasetSectionOpen}
                getDatasetRiskFields={getDatasetRiskFields}
                selectedField={selectedField}
                toggleDatasetOpen={toggleDatasetOpen}
                selectRiskField={selectRiskField}
                getSectionOpen={getSectionOpen}
                toggleSectionOpen={toggleSectionOpen}
                depth={0}
                showRiskFieldToggles={showRiskFieldToggles}
                toggleRiskField={toggleRiskField}
                documentHiddenFields={documentHiddenFields}
                allDocumentNames={allDocumentNames}
                agreementTypeDatasetId={agreementTypeDatasetId}
            />
        </Scrollable>
    );
};
