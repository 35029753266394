import React from 'react';

export const SmileyNegative: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color, fill }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={width || '1em'} height={height || '1em'} viewBox='0 0 256 256'>
        <g>
            <rect width='256' height='256' fill='none' />
            <circle r='96' opacity='0.2' cy='128' cx='128' />
            <circle strokeWidth='16' strokeMiterlimit='10' stroke={color || 'currentColor'} r='96' fill={fill} cy='128' cx='128' />
            <circle r='12' cy='108' cx='92' fill={color || 'currentColor'} />
            <circle r='12' cy='108' cx='164' fill={color || 'currentColor'} />
            <path strokeWidth='16' strokeLinejoin='round' strokeLinecap='round' stroke={color || 'currentColor'} fill='none' d='m169.6,176a48.1,48.1 0 0 0 -83.2,0' />
        </g>
    </svg>
);
