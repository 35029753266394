import { action } from 'typesafe-actions';

import { DateFilter, SearchFilter } from '../../search/store';
import { DocumentReportField, DocumentReportHierarchy, DocumentReportTemplate, DocumentReportingActionTypes, ReportPage, ReportTab } from './types';

export const setReportPage = (page: ReportPage, reportTemplateId?: string) => action(DocumentReportingActionTypes.SELECT_REPORT_PAGE, { page, reportTemplateId });

export const setSelectedTab = (tab: ReportTab) => action(DocumentReportingActionTypes.SELECT_WIZARD_TAB, tab);

export const updateDocumentReportFilter = (key: keyof SearchFilter, value: string[] | string | boolean | null) => action(DocumentReportingActionTypes.UPDATE_DOCUMENT_REPORT_FILTER, { key, value });
export const updateDocumentName = (value: string[] | null) => action(DocumentReportingActionTypes.UPDATE_REPORT_DOCUMENT_NAME, value);
export const updateReportDateFilter = (key: keyof DateFilter, value: string | null) => action(DocumentReportingActionTypes.UPDATE_REPORT_DATE_FILTER, { key, value });

export const fetchAvailableFieldsStarted = (documentNameId: string, reportFields?: DocumentReportField[]) => action(DocumentReportingActionTypes.FETCH_AVAILABLE_FIELDS_STARTED, { documentNameId, reportFields });
export const fetchAvailableDocumentFieldsSuccessful = (availableFields: DocumentReportField[], hierarchy: DocumentReportHierarchy[], reportFields?: DocumentReportField[]) => action(DocumentReportingActionTypes.FETCH_AVAILABLE_DOCUMENT_FIELDS_SUCCESSFUL, { availableFields, hierarchy, reportFields });
export const fetchAvailableFieldsFailed = (error: string) => action(DocumentReportingActionTypes.FETCH_AVAILABLE_FIELDS_FAILED, error);

export const generateReportStarted = () => action(DocumentReportingActionTypes.GENERATE_REPORT_STARTED);
export const generateReportSuccessful = () => action(DocumentReportingActionTypes.GENERATE_REPORT_SUCCESSFUL);
export const generateReportFailed = (error: string) => action(DocumentReportingActionTypes.GENERATE_REPORT_FAILED, error);

export const addDocumentReportField = (field: DocumentReportField) => action(DocumentReportingActionTypes.ADD_DOCUMENT_REPORT_FIELD, field);
export const removeDocumentReportField = (fieldId: string) => action(DocumentReportingActionTypes.REMOVE_DOCUMENT_REPORT_FIELD, fieldId);
export const updateDocumentReportFields = (fields: DocumentReportField[]) => action(DocumentReportingActionTypes.UPDATE_DOCUMENT_REPORT_FIELDS, fields);

export const resetReport = (page: ReportPage) => action(DocumentReportingActionTypes.RESET_REPORT, page);

export const setReportUpdated = (value: boolean) => action(DocumentReportingActionTypes.SET_REPORT_UPDATED, value);

export const toggleConfirmationModal = (value: boolean) => action(DocumentReportingActionTypes.TOGGLE_REPORT_CONFIRMATION_MODAL, value);
export const toggleSaveTemplateModalOpen = (isOpen: boolean) => action(DocumentReportingActionTypes.TOGGLE_SAVE_TEMPLATE_MODAL_OPEN, isOpen);

export const updateReportTemplateValue = (key: 'templateName' | 'templateDescription', value: string) => action(DocumentReportingActionTypes.UPDATE_REPORT_TEMPLATE_VALUE, { key, value });

export const saveReportTemplateStarted = (saveAs = false) => action(DocumentReportingActionTypes.SAVE_REPORT_TEMPLATE_STARTED, saveAs);
export const saveReportTemplateSuccessful = (reportTemplate: DocumentReportTemplate) => action(DocumentReportingActionTypes.SAVE_REPORT_TEMPLATE_SUCCESSFUL, reportTemplate);
export const saveReportTemplateFailed = (error: string) => action(DocumentReportingActionTypes.SAVE_REPORT_TEMPLATE_FAILED, error);

export const fetchAllReportTemplatesStarted = () => action(DocumentReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_STARTED);
export const fetchAllReportTemplatesSuccessful = (reportTemplates: DocumentReportTemplate[]) => action(DocumentReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_SUCCESSFUL, reportTemplates);
export const fetchAllReportTemplatesFailed = (error: string) => action(DocumentReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_FAILED, error);

export const openDocumentReportTemplate = (reportTemplate: DocumentReportTemplate) => action(DocumentReportingActionTypes.OPEN_DOCUMENT_REPORT_TEMPLATE, reportTemplate);

export const deleteReportTemplateStarted = (reportTemplateId: number) => action(DocumentReportingActionTypes.DELETE_REPORT_TEMPLATE_STARTED, reportTemplateId);
export const deleteReportTemplateFailed = (error: string) => action(DocumentReportingActionTypes.DELETE_REPORT_TEMPLATE_FAILED, error);
export const deleteReportTemplateSuccessful = (reportTemplates: DocumentReportTemplate[]) => action(DocumentReportingActionTypes.DELETE_REPORT_TEMPLATE_SUCCESSFUL, reportTemplates);

export const setReportTemplateToDelete = (reportTemplateId: number | null) => action(DocumentReportingActionTypes.SET_REPORT_TEMPLATE_TO_DELETE, reportTemplateId);
