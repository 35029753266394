import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getPlaybook, getDeviationOrder, getReorderDeviationModalOpen, saveDeviationsOrder, setDeviationsOrder, setDeviationsOrderModalOpen, getDeviationModalSectionId, DraggableTile, PlaybookProvision } from './store';
import { ReorderModal } from './ReorderModal';

export const ReorderDeviationsModal: React.FC = () => {

    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getReorderDeviationModalOpen);
    const deviationOrder = useAppSelector(getDeviationOrder);
    const deviationSectionId = useAppSelector(getDeviationModalSectionId);
    const playbook = useAppSelector(getPlaybook);

    const closeModal = useCallback(() => dispatch(setDeviationsOrderModalOpen(null)), [dispatch]);
    const saveOrder = useCallback(() => dispatch(saveDeviationsOrder()), [dispatch]);
    const setOrder = useCallback((newDeviationOrder: DraggableTile[]) => dispatch(setDeviationsOrder(newDeviationOrder)), [dispatch]);
    const savedOrder = useMemo(() => deviationSectionId ? (playbook.content.sections.find(({ sectionId }) => sectionId === deviationSectionId) as PlaybookProvision).deviations.map(({ rowId, topic }) => ({ id: rowId, title: topic })) : [{ id: '', title: '' }], [deviationSectionId, playbook]);

    return (
        <ReorderModal
            isOpen={isOpen}
            currentOrder={deviationOrder}
            closeModal={closeModal}
            saveOrder={saveOrder}
            setOrder={setOrder}
            savedOrder={savedOrder}
            title='Amendments/Deviations'
            testId='deviations'
        />
    );
};
