import { OpenFieldType } from '../../../datasets/instances/store';
import { DatasetDefinition, DatasetSection, DatasetDefinitionDB, DatasetFieldType, AgencyDefinition, AgencyDataset, AgencyLinkedSingleField } from '../../../datasets/store/types';
import { HiddenField, HiddenSection } from '../../my-datasets/store';

export enum DatasetBuilderActionTypes {
    OPEN_BUILDER = 'OPEN_BUILDER',
    CLOSE_BUILDER = 'CLOSE_BUILDER',
    NEW_DATASET_DEFINITION = 'NEW_DATASET_DEFINITION',
    UPDATE_DATASET_DEFINITION_TITLE = 'UPDATE_DATASET_DEFINITION_TITLE',
    UPDATE_DATASET_FIELD = 'UPDATE_DATASET_FIELD',
    TOGGLE_SETTINGS_MENU_OPEN = 'TOGGLE_SETTINGS_MENU_OPEN',
    UPDATE_SETTINGS_VALUE = 'UPDATE_SETTINGS_VALUE',
    UPDATE_DATASET_FIELDS = 'UPDATE_DATASET_FIELDS',
    ADD_DATASET_FIELD = 'ADD_DATASET_FIELD',
    REMOVE_DATASET_FIELD = 'REMOVE_DATASET_FIELD',
    ADD_DATASET_FIELD_WITHIN_SECTION = 'ADD_DATASET_FIELD_WITHIN_SECTION',
    REMOVE_DATASET_FIELD_WITHIN_SECTION = 'REMOVE_DATASET_FIELD_WITHIN_SECTION',
    OPEN_SECTION_MODAL = 'OPEN_SECTION_MODAL',
    CLOSE_SECTION_MODAL = 'CLOSE_SECTION_MODAL',
    UPDATE_SELECTED_SECTION = 'UPDATE_SELECTED_SECTION',
    UPDATE_SECTIONS = 'UPDATE_SECTIONS',
    ADD_SECTION = 'ADD_SECTION',
    SAVE_SECTION = 'SAVE_SECTION',
    REMOVE_SECTION = 'REMOVE_SECTION',
    SELECT_SECTION = 'SELECT_SECTION',
    SELECT_GROUP = 'SELECT_GROUP',
    ADD_DATASET_FIELD_TO_GROUP = 'ADD_DATASET_FIELD_TO_GROUP',
    REMOVE_DATASET_FIELD_FROM_GROUP = 'REMOVE_DATASET_FIELD_FROM_GROUP',
    UPDATE_DATASET_GROUP_FIELD = 'UPDATE_DATASET_GROUP_FIELD',
    UPDATE_DATASET_GROUP_FIELDS = 'UPDATE_DATASET_GROUP_FIELDS',
    TOGGLE_GROUP_SETTINGS_MENU_OPEN = 'TOGGLE_GROUP_SETTINGS_MENU_OPEN',
    TOGGLE_REF_MODAL_OPEN = 'TOGGLE_REF_MODAL_OPEN',
    TOGGLE_GROUP_REF_MODAL_OPEN = 'TOGGLE_GROUP_REF_MODAL_OPEN',
    UPDATE_GROUP_SETTINGS_VALUE = 'UPDATE_GROUP_SETTINGS_VALUE',
    TOGGLE_PREVIEW = 'TOGGLE_PREVIEW',
    TOGGLE_PUBLISH_MODAL = 'TOGGLE_PUBLISH_MODAL',
    UPDATE_PUBLISH_REASON = 'UPDATE_PUBLISH_REASON',
    PUBLISH_DATASET_DEFINITION_STARTED = 'PUBLISH_DATASET_DEFINITION_STARTED',
    PUBLISH_DATASET_DEFINITION_SUCCESSFUL = 'PUBLISH_DATASET_DEFINITION_SUCCESSFUL',
    PUBLISH_DATASET_DEFINITION_FAILED = 'PUBLISH_DATASET_DEFINITION_FAILED',
    TOGGLE_HELP_MODAL = 'TOGGLE_HELP_MODAL',
    TOGGLE_SECTION_REORDER_MODAL = 'TOGGLE_SECTION_REORDER_MODAL',
    FETCH_ALL_DATASET_DEFINITIONS_STARTED = 'FETCH_ALL_DATASET_DEFINITIONS_STARTED',
    FETCH_ALL_DATASET_DEFINITIONS_SUCCESSFUL = 'FETCH_ALL_DATASET_DEFINITIONS_SUCCESSFUL',
    FETCH_ALL_DATASET_DEFINITIONS_FAILED = 'FETCH_ALL_DATASET_DEFINITIONS_FAILED',
    OPEN_DATASET_DEFINITION = 'OPEN_DATASET_DEFINITION',
    GO_BACK_TO_DATASET_DEFINITION = 'GO_BACK_TO_DATASET_DEFINITION',
    TOGGLE_DEFINITION_HISTORY_MODAL = 'TOGGLE_DEFINITION_HISTORY_MODAL',
    EDIT_DATASET_DEFINITION = 'EDIT_DATASET_DEFINITION',
    TOGGLE_SETTINGS_LINK_MODAL = 'TOGGLE_SETTINGS_LINK_MODAL',
    TOGGLE_IS_AGENCY = 'TOGGLE_IS_AGENCY',
    TOGGLE_DATASET_VIEW = 'TOGGLE_DATASET_VIEW',
    TOGGLE_SYSTEM_ID_MODAL_OPEN = 'TOGGLE_SYSTEM_ID_MODAL_OPEN',
    TOGGLE_GROUP_SYSTEM_ID_MODAL_OPEN = 'TOGGLE_GROUP_SYSTEM_ID_MODAL_OPEN',
    TOGGLE_DELETE_DATASET_CONFIRMATION_MODAL = 'TOGGLE_DELETE_DATASET_CONFIRMATION_MODAL',
    DELETE_DATASET_STARTED = 'DELETE_DATASET_STARTED',
    DELETE_DATASET_SUCCESSFUL = 'DELETE_DATASET_SUCCESSFUL',
    DELETE_DATASET_FAILED = 'DELETE_DATASET_FAILED',
    UPDATE_DATASET_DEFINITION_CLIENT_IDS = 'UPDATE_DATASET_DEFINITION_CLIENT_IDS',
    UPDATE_DATASET_DEFINITION_NEW_FIELDS = 'UPDATE_DATASET_DEFINITION_NEW_FIELDS',
    TOGGLE_CALCULATOR_HELP_MODAL = 'TOGGLE_CALCULATOR_HELP_MODAL',
    TOGGLE_UNSAVED_CHANGES_MODAL = 'TOGGLE_UNSAVED_CHANGES_MODAL',
    SET_DATASET_DEFINITION_HAS_UPDATED = 'SET_DATASET_DEFINITION_HAS_UPDATED',
    TOGGLE_RISK_TOLERANCE_HELP_MODAL = 'TOGGLE_RISK_TOLERANCE_HELP_MODAL',
    FETCH_ALL_DOCUMENT_DATASETS_STARTED = 'FETCH_ALL_DOCUMENT_DATASETS_STARTED',
    FETCH_ALL_DOCUMENT_DATASETS_SUCCESSFUL = 'FETCH_ALL_DOCUMENT_DATASETS_SUCCESSFUL',
    FETCH_ALL_DOCUMENT_DATASETS_FAILED = 'FETCH_ALL_DOCUMENT_DATASETS_FAILED',
    FETCH_DEFINITIONS_FOR_DATASET_STARTED = 'FETCH_DEFINITIONS_FOR_DATASET_STARTED',
    FETCH_DEFINITIONS_FOR_DATASET_SUCCESSFUL = 'FETCH_DEFINITIONS_FOR_DATASET_SUCCESSFUL',
    FETCH_DEFINITIONS_FOR_DATASET_FAILED = 'FETCH_DEFINITIONS_FOR_DATASET_FAILED',
    REMOVE_DATASETS_OPEN_FIELD_SECTION = 'REMOVE_DATASETS_OPEN_FIELD_SECTION',
    ADD_DATASETS_OPEN_FIELD_SECTION = 'ADD_DATASETS_OPEN_FIELD_SECTION',
    REMOVE_ALL_DATASETS_FIELD_SECTIONS = 'REMOVE_ALL_DATASETS_FIELD_SECTIONS',
    TOGGLE_DOCUMENT_HIDDEN_FIELDS = 'TOGGLE_DOCUMENT_HIDDEN_FIELDS',
    TOGGLE_MODAL_DOCUMENT_DATASET = 'TOGGLE_MODAL_DOCUMENT_DATASET',
    RESET_DOCUMENT_DATASET_CONFIGURE = 'RESET_DOCUMENT_DATASET_CONFIGURE',
    TOGGLE_UNSAVED_DATASET_CONFIGURATION_MODAL = 'TOGGLE_UNSAVED_DATASET_CONFIGURATION_MODAL',
    SET_PREVIEW_SELECTED_DOCUMENT = 'SET_PREVIEW_SELECTED_DOCUMENT',
    SAVE_DOCUMENT_DATASET_CONFIGURATION_STARTED = 'SAVE_DOCUMENT_DATASET_CONFIGURATION_STARTED',
    SAVE_DOCUMENT_DATASET_CONFIGURATION_SUCCESSFUL = 'SAVE_DOCUMENT_DATASET_CONFIGURATION_SUCCESSFUL',
    SAVE_DOCUMENT_DATASET_CONFIGURATION_FAILED = 'SAVE_DOCUMENT_DATASET_CONFIGURATION_FAILED',
    DATASET_DOCUMENT_CONFIG_UPDATED = 'DATASET_DOCUMENT_CONFIG_UPDATED',
    ADD_DOCUMENT_DATASET_SECTION_REFERENCE = 'ADD_DOCUMENT_DATASET_SECTION_REFERENCE',
    DELETE_DOCUMENT_DATASET_SECTION_REFERENCE = 'DELETE_DOCUMENT_DATASET_SECTION_REFERENCE',
    UPDATE_DOCUMENT_DATASET_SECTION_REFERENCE_ORDER = 'UPDATE_DOCUMENT_DATASET_SECTION_REFERENCE_ORDER',
    SET_CONFIGURE_DATASET_PARENT_ID = 'SET_CONFIGURE_DATASET_PARENT_ID',
    // Agency Builder Action Types
    TOGGLE_AGENCY_BUILDER = 'TOGGLE_AGENCY_BUILDER',
    FETCH_AGENCY_DEFINITIONS_STARTED = 'FETCH_AGENCY_DEFINITIONS_STARTED',
    FETCH_AGENCY_DEFINITIONS_SUCCESSFUL = 'FETCH_AGENCY_DEFINITIONS_SUCCESSFUL',
    FETCH_AGENCY_DEFINITIONS_FAILED = 'FETCH_AGENCY_DEFINITIONS_FAILED',
    EDIT_AGENCY_DEFINITION = 'EDIT_AGENCY_DEFINITION',
    ADD_AGENCY_DATASET_FIELD = 'ADD_AGENCY_DATASET_FIELD',
    REMOVE_AGENCY_DATASET_FIELD = 'REMOVE_AGENCY_DATASET_FIELD',
    UPDATE_AGENCY_DATASET_FIELD = 'UPDATE_AGENCY_DATASET_FIELD',
    UPDATE_AGENCY_DATASET_FIELDS = 'UPDATE_AGENCY_DATASET_FIELDS',
    TOGGLE_AGENCY_REF_MODAL_OPEN = 'TOGGLE_AGENCY_REF_MODAL_OPEN',
    TOGGLE_AGENCY_SETTINGS_MODAL_OPEN = 'TOGGLE_AGENCY_SETTINGS_MODAL_OPEN',
    UPDATE_AGENCY_SETTINGS_VALUE = 'UPDATE_AGENCY_SETTINGS_VALUE',
    UPDATE_AGENCY_LINKED_FIELDS = 'UPDATE_AGENCY_LINKED_FIELDS',
    FETCH_AGENCY_FIELDS_STARTED = 'AGENCY_BUILDER_FETCH_AGENCY_FIELDS_STARTED',
    FETCH_AGENCY_FIELDS_SUCCESSFUL = 'AGENCY_BUILDER_FETCH_AGENCY_FIELDS_SUCCESSFUL',
    FETCH_AGENCY_FIELDS_FAILED = 'AGENCY_BUILDER_FETCH_AGENCY_FIELDS_FAILED',
    TOGGLE_PUBLISH_AGENCY_CONFIRMATION = 'TOGGLE_PUBLISH_AGENCY_CONFIRMATION',
    PUBLISH_AGENCY_DATASET_DEFINITION_STARTED = 'PUBLISH_AGENCY_DATASET_DEFINITION_STARTED',
    PUBLISH_AGENCY_DATASET_DEFINITION_SUCCESSFUL = 'PUBLISH_AGENCY_DATASET_DEFINITION_SUCCESSFUL',
    PUBLISH_AGENCY_DATASET_DEFINITION_FAILED = 'PUBLISH_AGENCY_DATASET_DEFINITION_FAILED'
}

export enum DatasetView {
    TABLE_STANDARD = 'standard',
    TABLE_AGENCY = 'agency',
    DOCUMENTS_LIST = 'documents',
    DOCUMENTS_PREVIEW = 'preview',
    DOCUMENTS_CONFIGURE = 'configure'
}

export enum SectionOpen {
    NEW = 'New',
    EDIT = 'Edit'
}

export type SettingsModalType = DatasetFieldType.DATASET | DatasetFieldType.DROPDOWN | DatasetFieldType.LINKFIELD | DatasetFieldType.DOCUMENT | DatasetFieldType.LABEL | DatasetFieldType.CALCULATOR | 'highlight';

export interface SettingsModal {
    groupIndex?: number;
    index: number | null;
    type: SettingsModalType | null;
}

export interface SelectedDataset {
    agreementTypeId: number;
    datasetId: number;
}

export enum LinkDirection {
    UP = 'Up',
    DOWN = 'Down'
}

export interface DocumentsForDataset {
    documentName: string;
    documentNameId: number;
}

export interface DocumentNameDatasets {
    datasetId: number;
    documentNames: DocumentsForDataset[];
    agreementType: string;
    agreementTypeId: number;
}

export interface DatasetsOpenField {
    sectionId?: string;
    rowId?: string;
    fieldId: string;
    datasetId: string;
    groupIndex?: number;
    type: OpenFieldType.FIELD;
}

export interface DatasetsOpenSection {
    sectionId: string;
    datasetId: string;
    type: OpenFieldType.SECTION;
}

export type DatasetsOpenFieldSection = DatasetsOpenField | DatasetsOpenSection;

export interface HiddenDocumentField {
    fieldId: string;
    type: OpenFieldType.FIELD;
    documentNameIds: number[];
    sectionId?: string;
}

export interface HiddenDocumentSection {
    sectionId: string;
    type: OpenFieldType.SECTION;
    documentNameIds: number[];
}

export interface DocumentSpecificHiddenFields {
    [datasetId: number]: (HiddenDocumentField | HiddenDocumentSection)[];
}

export const isHiddenDocumentField = (field: HiddenDocumentField | HiddenDocumentSection): field is HiddenDocumentField => field.type === OpenFieldType.FIELD;

export interface DocumentDatasetModal {
    datasetId: number;
    hiddenDocumentNameIds: number[] | undefined;
}

export interface SectionReference {
    sectionId: string;
    references: string[];
}

export interface DocumentSpecificSectionReferences {
    [documentNameId: number]: SectionReference[];
}

export interface DocumentSpecificSectionIds {
    [datasetId: number]: DocumentSpecificSectionReferences;
}

export interface DatasetBuilderState {
    modalOpen: boolean;
    datasetDefinition: DatasetDefinition | null;
    selectedSection: DatasetSection | null;
    sectionModalOpen: SectionOpen | null;
    selectedGroup: string | null;
    previewOpen: boolean;
    publishModalOpen: boolean;
    publishError: string | null;
    fetchError: string | null;
    helpModalOpen: boolean;
    publishedDatasetDefinitions: DatasetDefinitionDB[];
    settingsLinkModalOpen: SettingsModal;
    datasetView: DatasetView;
    agencyModalOpen: boolean;
    agencyDefinition: AgencyDefinition | null;
    agencyDefinitions: AgencyDataset[];
    agencySettingsModalOpen: number | null;
    availableAgencyFields: AgencyLinkedSingleField[];
    publishConfirmationModalOpen: boolean;
    selectedDataset: SelectedDataset | null;
    confirmDeleteDataset: DatasetDefinitionDB | DatasetDefinition | null;
    isDeleting: boolean;
    deleteError: string | null;
    definitionClientIds: number[];
    newFields: (HiddenField | HiddenSection)[];
    reorderSectionsModalOpen: boolean;
    calculatorHelpModalOpen: boolean;
    previousDatasetIds: [];
    datasetDefinitionUpdated: boolean;
    unsavedChangesModalOpen: null | LinkDirection;
    definitionHistoryModalOpen: boolean;
    riskToleranceHelpModalOpen: boolean;
    documentNameDatasets: DocumentNameDatasets[];
    fetchingDocumentNameDatasets: boolean;
    datasetDefinitions: DatasetDefinition[];
    fetchingDatasetDefinitions: boolean;
    savedHiddenDocumentFields: DocumentSpecificHiddenFields;
    currentHiddenDocumentFields: DocumentSpecificHiddenFields;
    unsavedHiddenDocumentChangesModalOpen: boolean;
    openFieldsAndSections: DatasetsOpenFieldSection[];
    documentDatasetModal: DocumentDatasetModal | null;
    previewDatasetSelectedDocument: number | null;
    isSavingDocumentDatasetHiddenFields: boolean;
    currentDatasetSectionReferences: DocumentSpecificSectionIds;
    savedDatasetSectionReferences: DocumentSpecificSectionIds;
    datasetDocumentConfigUpdated: boolean;
    configureDatasetParentId: number | null;
}
