import { isNull } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { getUser } from '../../../auth/login/store';
import { Opinion } from '../../../shared/icons';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { OverflowTooltip } from '../../../shared/tooltip';
import { getCurrentInstanceIsSignedOff, getSignOffNotesModalOpen, SignOffConversation, SignOffSmileyAndNote } from '../store';
import styles from './SignOff.module.scss';
import { SignOffConversationNotes } from './SignOffConversationNotes';
import { SmileySelectionIcons } from './SmileySelectionIcons';

interface SignOffConversationNotesWrapperProps {
    signOffConversation: SignOffConversation;
    modalTitle: string;
    hasAddNotesPermission: boolean;
    canAddNotes: boolean;
    fieldLabel: string | null;
    isDropdownDetailsField: boolean;
}

export const SignOffConversationNotesWrapper: React.FC<SignOffConversationNotesWrapperProps> = ({ signOffConversation, modalTitle, hasAddNotesPermission, canAddNotes, fieldLabel, isDropdownDetailsField }) => {
    const [showEditNoteSpinner, setShowEditNoteSpinner] = useState(false);
    const [, screenHeight] = useWindowResize();

    useEffect(() => {
        if (showEditNoteSpinner) {
            setTimeout(() => setShowEditNoteSpinner(false), 250);
        }
    }, [showEditNoteSpinner]);

    const user = useAppSelector(getUser);
    const showSpinner = useAppSelector(getSignOffNotesModalOpen);
    const opinionInstanceIsSignedOff = useAppSelector(getCurrentInstanceIsSignedOff);
    const conversation: SignOffSmileyAndNote[] = signOffConversation.notes;
    const conversationHeight = `calc(100% - ${!hasAddNotesPermission ? 0 : 220}px)`;

    const subheader = useMemo(() => isDropdownDetailsField && !isNull(fieldLabel) ? <div className={styles.fieldLabel}><OverflowTooltip overlayText={fieldLabel} /></div> : null, [isDropdownDetailsField, fieldLabel]);

    return (
        <div className={styles.signOffNotesWrapper} style={{ height: `${screenHeight * 0.8}px` }}>
            <ModalHeader label={modalTitle} icon={Opinion} />
            {subheader}
            <div className={styles.conversationResponseWrapper}>
                <div className={styles.signOffNotesConversation} style={{ height: conversationHeight }}>
                    <SignOffConversationNotes
                        setShowEditNoteSpinner={setShowEditNoteSpinner}
                        conversation={conversation}
                        showSpinner={!showSpinner}
                        currentUserId={user!.userId}
                        hasAddNotesPermission={hasAddNotesPermission}
                        opinionInstanceIsSignedOff={opinionInstanceIsSignedOff}
                        canAddNotes={canAddNotes}
                        hasFieldLabel={!isNull(fieldLabel)}
                    />
                </div>
                {hasAddNotesPermission && !opinionInstanceIsSignedOff && canAddNotes ?
                    <div className={styles.signOffNotesAddNotes}>
                        <SmileySelectionIcons user={user!} showEditNoteSpinner={showEditNoteSpinner} isDropdownDetailsField={isDropdownDetailsField} />
                    </div>
                    : null
                }
            </div>
        </div>
    );
};
