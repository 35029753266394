import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';
import { RawDraftContentState } from 'draft-js';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DATABASE_DATE_FORMAT, formatDate } from '../../../../utils/luxon';
import { FeaturePermission } from '../../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store';
import { substitutabilityReasoningLabels, initialSubstitutabilityReasoning } from '../../../constants/dora';
import { DatePicker } from '../../../shared/datepicker/DatePicker';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { RadioList, RadioListOption } from '../../../shared/radio/RadioList';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Text } from '../../../shared/text/Text';
import { Toggle } from '../../../shared/toggle';
import { InformationTooltip } from '../../../shared/tooltip';
import { DoraSupplyChainAssessmentDetails, getCurrentFunctionCompanyDetails, getSelectedElementAssessmentInfo, IctSubstitutabilityOptions, ReintegrationPossibility, SubstitutabilityReasoning, updateSupplyChainElementAssessment, LowMediumHighOptions, AlternateIctProviders, getSupplyChainElementAssessmentTimeline, getSelectedAssessmentTimeline, setSelectedAssessmentTimelineDetails, getDetailsInformation, getAssessmentInfoFiles, closeDoraDocumentPreviewModal, downloadDoraDocumentStarted, openDoraDocumentStarted, removeDoraDocumentFile, setDoraDocumentFiles } from '../store';
import styles from '../SupplyChain.module.scss';
import { AssessmentDetailsTimeline } from './AssessmentDetailsTimeline';
import { Documents } from './Documents';
import { WYSIWYG } from '../../../shared/wysiwyg/WYSIWYG';

export const AssessmentInformation: React.FC = () => {
    const dispatch = useAppDispatch();
    const functionCompanyDetails = useAppSelector(getCurrentFunctionCompanyDetails);
    const isCriticalOrImportant = useMemo(() => !isNull(functionCompanyDetails) && !!functionCompanyDetails.isCriticalOrImportant, [functionCompanyDetails]);
    const assessmentInfo = useAppSelector(getSelectedElementAssessmentInfo);
    const assessmentTimeline = useAppSelector(getSupplyChainElementAssessmentTimeline);
    const selectedTimelineConfig = useAppSelector(getSelectedAssessmentTimeline);
    const detailsInformation = useAppSelector(getDetailsInformation);
    const assessmentFiles = useAppSelector(getAssessmentInfoFiles);

    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const timelineDetails = useMemo(() => !isNull(selectedTimelineConfig) ? selectedTimelineConfig.details! : assessmentInfo.details, [selectedTimelineConfig, assessmentInfo]);
    const selectedTimelineConfigurationId = useMemo(() => !isNull(selectedTimelineConfig) ? selectedTimelineConfig.doraSupplyChainAssessmentId : assessmentInfo.doraSupplyChainAssessmentId, [selectedTimelineConfig, assessmentInfo]);
    const assessmentInputDisabled = useMemo(() => !isNull(selectedTimelineConfig) || !hasDoraFullAccessPermission, [selectedTimelineConfig, hasDoraFullAccessPermission]);
    const isMaterial = useMemo(() => !isUndefined(detailsInformation.isMaterial) && !!detailsInformation.isMaterial, [detailsInformation]);

    const { ictSubstitutability, substitutabilityReasoning, dateOfLastAudit, exitPlan, reintegration, discontinuingImpact, alternativeIctProviders, alternateProviderIdentity, documents, furtherDetails } = timelineDetails;

    const substitutabilityDropdownOptions = Object.values(IctSubstitutabilityOptions).map(option => ({ label: option, value: option }));
    const reintegrationPossibilityOptions = Object.values(ReintegrationPossibility).map(option => ({ label: option, value: option }));
    const discontinuingIctImpactOptions = Object.values(LowMediumHighOptions).map(option => ({ label: option, value: option }));
    const alternateIctProviderOptions = Object.values(AlternateIctProviders).map(option => ({ label: option, value: option }));

    const ictSubstitutabilityValue = useMemo(() => !isNull(ictSubstitutability) ? substitutabilityDropdownOptions.find(({ value }) => value === ictSubstitutability)! : ictSubstitutability, [ictSubstitutability, substitutabilityDropdownOptions]);
    const reintegrationPossibilityValue = useMemo(() => !isNull(reintegration) ? reintegrationPossibilityOptions.find(({ value }) => value === reintegration)! : reintegration, [reintegration, reintegrationPossibilityOptions]);
    const discontinuingIctImpactValue = useMemo(() => !isNull(discontinuingImpact) ? discontinuingIctImpactOptions.find(({ value }) => value === discontinuingImpact)! : discontinuingImpact, [discontinuingImpact, discontinuingIctImpactOptions]);
    const alternateIctProviderValue = useMemo(() => !isNull(alternativeIctProviders) ? alternateIctProviderOptions.find(({ value }) => value === alternativeIctProviders)! : alternativeIctProviders, [alternativeIctProviders, alternateIctProviderOptions]);

    const showSubstitutabilityReason = useMemo(() => !isNull(ictSubstitutability) && [IctSubstitutabilityOptions.NOT_SUBSTITUTABLE, IctSubstitutabilityOptions.HIGHLY_COMPLEX].includes(ictSubstitutability), [ictSubstitutability]);
    const showAlternateIctProviderIdentityInput = useMemo(() => !isNull(alternativeIctProviders) && alternativeIctProviders === AlternateIctProviders.YES, [alternativeIctProviders]);

    const updateAssessmentDetails = useCallback((key: keyof DoraSupplyChainAssessmentDetails, value: IctSubstitutabilityOptions | SubstitutabilityReasoning | string | boolean | null | undefined | RawDraftContentState) => dispatch(updateSupplyChainElementAssessment(key, value)), [dispatch]);

    const scrollToInput = useCallback(() => {
        const alternateProviderInput = document.getElementById('alternate-ict-provider');
        alternateProviderInput?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }, []);

    const updateDropdown = (option: DropdownOption | Options<DropdownOption> | null, key: keyof DoraSupplyChainAssessmentDetails) => {
        let value = null;
        if (!isNull(option)) {
            value = (option as DropdownOption).value;
        }
        if (key === 'ictSubstitutability' && [IctSubstitutabilityOptions.MEDIUM_COMPLEXITY, IctSubstitutabilityOptions.EASY_COMPLEXITY].includes(value as IctSubstitutabilityOptions)) {
            updateAssessmentDetails('substitutabilityReasoning', initialSubstitutabilityReasoning);
        }
        if (key === 'alternativeIctProviders' && value === 'Yes') {
            setTimeout(() => scrollToInput(), 150);
        }
        if (key === 'alternativeIctProviders' && value === 'No') {
            updateAssessmentDetails('alternateProviderIdentity', undefined);
        }
        updateAssessmentDetails(key, value);
    };

    const setDateOfLastAudit = useCallback((value: Date | string | null) => {
        const datedAsOf = value ? formatDate(value, DATABASE_DATE_FORMAT) : null;
        updateAssessmentDetails('dateOfLastAudit', datedAsOf);
    }, [updateAssessmentDetails]);

    const toggleExitPlan = useCallback((value: boolean) => updateAssessmentDetails('exitPlan', value), [updateAssessmentDetails]);

    const updateAlternateIctProviderIdentity = useCallback((value: string) => updateAssessmentDetails('alternateProviderIdentity', value), [updateAssessmentDetails]);

    const substitutabilityReasons: RadioListOption[] = useMemo(() => assessmentInfo && Object.entries(substitutabilityReasoningLabels).map(([key, label]) => ({
        id: key,
        label,
        isSelected: substitutabilityReasoning[key as keyof SubstitutabilityReasoning],
        disabled: assessmentInputDisabled
    })), [assessmentInfo, substitutabilityReasoning, assessmentInputDisabled]);

    const updateSubstitutabilityReasons = useCallback((buttonId: string) => {
        const substitutabilityReasoning = substitutabilityReasons.map(({ id }) => id).reduce<SubstitutabilityReasoning>((acc, id) => {
            const selectedValue = buttonId === id;
            acc[id as keyof SubstitutabilityReasoning] = selectedValue;
            return acc;
        }, initialSubstitutabilityReasoning);
        updateAssessmentDetails('substitutabilityReasoning', substitutabilityReasoning);
    }, [updateAssessmentDetails, substitutabilityReasons]);

    const changeTimeline = useCallback((value: number | number[]) => {
        if (assessmentInputDisabled) {
            const index = (value as number) - 1;
            dispatch(setSelectedAssessmentTimelineDetails(assessmentTimeline[index]));
        }
    }, [dispatch, assessmentTimeline, assessmentInputDisabled]);

    const optionalWarningLabel = useMemo(() => {
        let label = '';
        const notCritical = 'function is not deemed critical or important';
        const notMaterial = 'supply chain element is not deemed as materially important';
        if (!isCriticalOrImportant || !isMaterial) {
            if (!isCriticalOrImportant && !isMaterial) {
                label = `For the purpose of DORA, because this ${notCritical} and this ${notMaterial}, this section is optional, and does not need to be completed`;
            } else {
                label = `For the purpose of DORA, because this ${!isMaterial ? notMaterial : notCritical}, this section is optional, and does not need to be completed`;
            }
        }
        return label;
    }, [isMaterial, isCriticalOrImportant]);

    const showOptionalLabel = useMemo(() => !isCriticalOrImportant || !isMaterial, [isCriticalOrImportant, isMaterial]);
    const reintegrationTooltip = 'Please confirm how easy (or otherwise) it would be to reintegrate the relevant ICT service back "in-house" in the event that it is provided by an ICT third-party service provider that is NOT an ICT intra-group service provider.';
    const discontinuingIctTooltip = 'Please confirm the impact for the relevant financial entity of discontinuing the ICT service provided by the ICT third-party service provider. This should be based on an internal assessment conducted by the financial entity.';

    const setUploadDocuments = useCallback((files: File[]) => dispatch(setDoraDocumentFiles(files)), [dispatch]);
    const downloadDocument = useCallback((doraDocumentId: number, description: string) => dispatch(downloadDoraDocumentStarted(doraDocumentId, description)), [dispatch]);
    const previewDocument = useCallback((doraDocumentId: number, description: string) => dispatch(openDoraDocumentStarted(doraDocumentId, description)), [dispatch]);
    const removeUploadFile = useCallback((index: number) => dispatch(removeDoraDocumentFile(index)), [dispatch]);
    const closePreview = useCallback(() => dispatch(closeDoraDocumentPreviewModal()), [dispatch]);

    const scrollableHeight = useMemo(() => assessmentTimeline.length > 0 ? 'calc(100% - 54px)' : '100%', [assessmentTimeline]);

    return (
        <div className={styles.supplyChainElementDetailsWrapper}>
            <AssessmentDetailsTimeline timeline={assessmentTimeline} selectedId={selectedTimelineConfigurationId} onChange={changeTimeline} />
            <Scrollable height={scrollableHeight}>
                {showOptionalLabel &&
                    <div className={styles.optionalLabel}>{optionalWarningLabel}</div>
                }
                <div className={styles.elementDetailsQuestionWrapper}>
                    <div className={styles.elementDetailsQuestion}>Substitutability of the ICT third-party service provider</div>
                    <Dropdown
                        disabled={assessmentInputDisabled}
                        value={ictSubstitutabilityValue}
                        options={substitutabilityDropdownOptions}
                        testId='supply-chain-element-assessment-ict-substitutability'
                        onChange={value => updateDropdown(value, 'ictSubstitutability')}
                        menuPlacement='bottom'
                    />
                </div>
                {showSubstitutabilityReason &&
                    <div className={styles.radioWrapper}>
                        <div className={styles.radioToggleLabel}>Why is the substitutability impossible or highly complex?</div>
                        <RadioList
                            options={substitutabilityReasons}
                            onChange={updateSubstitutabilityReasons}
                            testId='supply-chain-element-assessment-ict-substitutability-reasoning'
                        />
                    </div>
                }
                <div className={styles.elementDetailsQuestionWrapper}>
                    <div className={styles.elementDetailsQuestion}>Date of the last audit on the provider</div>
                    <DatePicker
                        value={dateOfLastAudit}
                        onChange={setDateOfLastAudit}
                        testId='supply-chain-element-assessment-last-audit-date'
                        maxDate={new Date()}
                        disabled={assessmentInputDisabled}
                    />
                </div>
                <div className={styles.exitPlanWrapper}>
                    <div className={styles.exitPlanLabel}>Is there an existence of an exit plan?</div>
                    <Toggle
                        checked={!!exitPlan}
                        onChange={toggleExitPlan}
                        testId='supply-chain-element-assessment-exit-plan'
                        disabled={assessmentInputDisabled}
                    />
                </div>
                <div className={styles.elementDetailsQuestionWrapper}>
                    <div className={styles.elementDetailsQuestion}>
                        Possibility of reintegration of the contracted service
                        <div className={styles.referenceTooltipWrapper}>
                            <InformationTooltip content={reintegrationTooltip} />
                        </div>
                    </div>
                    <Dropdown
                        value={reintegrationPossibilityValue}
                        options={reintegrationPossibilityOptions}
                        testId='supply-chain-element-assessment-reintegration-possibility'
                        onChange={value => updateDropdown(value, 'reintegration')}
                        disabled={assessmentInputDisabled}
                    />
                </div>
                <div className={styles.elementDetailsQuestionWrapper}>
                    <div className={styles.elementDetailsQuestion}>
                        Impact of discontinuing the ICT services
                        <div className={styles.referenceTooltipWrapper}>
                            <InformationTooltip content={discontinuingIctTooltip} />
                        </div>
                    </div>
                    <Dropdown
                        value={discontinuingIctImpactValue}
                        options={discontinuingIctImpactOptions}
                        testId='supply-chain-element-assessment-discontinuing-ict-impact'
                        onChange={value => updateDropdown(value, 'discontinuingImpact')}
                        disabled={assessmentInputDisabled}
                    />
                </div>
                <div className={styles.elementDetailsQuestionWrapper}>
                    <div className={styles.elementDetailsQuestion}>Are there any alternative ICT third party-service providers identified?</div>
                    <Dropdown
                        value={alternateIctProviderValue}
                        options={alternateIctProviderOptions}
                        testId='supply-chain-element-assessment-alternate-ict-providers'
                        onChange={value => updateDropdown(value, 'alternativeIctProviders')}
                        disabled={assessmentInputDisabled}
                    />
                </div>
                {showAlternateIctProviderIdentityInput &&
                    <div className={styles.elementDetailsQuestionWrapper}>
                        <div className={styles.elementDetailsQuestion} id='alternate-ict-provider'>What is the identity of the alternative ICT third party provider?</div>
                        <Text
                            marginBottom='0'
                            testId='supply-chain-element-assessment-alternate-ict-providers-input'
                            maxLength={256}
                            value={alternateProviderIdentity}
                            onChange={e => updateAlternateIctProviderIdentity(e.target.value)}
                            placeholder='Alternate Provider Identity...'
                            disabled={assessmentInputDisabled}
                        />
                    </div>
                }
                <div className={styles.lineBreak} />
                <div className={styles.detailsInputWrapper}>
                    <div className={styles.detailsInputLabel}>Further Details:</div>
                    <WYSIWYG
                        content={furtherDetails}
                        updateContent={val => updateAssessmentDetails('furtherDetails', val)}
                        disabled={assessmentInputDisabled}
                        toolbarHidden={assessmentInputDisabled}
                    />
                </div>
                <div className={styles.lineBreak} />
                <Documents
                    setUploadDocuments={setUploadDocuments}
                    disabled={assessmentInputDisabled}
                    isEditing={!assessmentInputDisabled}
                    closePreview={closePreview}
                    documents={documents}
                    downloadDocument={downloadDocument}
                    files={assessmentFiles}
                    previewDocument={previewDocument}
                    removeUploadFile={removeUploadFile}
                />
            </Scrollable>
        </div>
    );
};
