import React, { useMemo } from 'react';

import { DatasetDefinition, isFormDatasetDefinition } from '../../datasets/store';
import { FormDefinition } from './formDefinition/MyDatasetsFormDefinition';
import { TableDefinition } from './tableDefinition/MyDatasetsTableDefinition';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { getCurrentHiddenFields, getDocumentSpecificHiddenFields, getSelectedDatasetId } from './store';
import { useAppSelector } from '../../../hooks/react-redux';
import { getAllDocumentNames } from '../documents/store';

interface DatasetDefinitionProps {
    datasetDefinition: DatasetDefinition;
    parents: number;
    modalInstance?: boolean;
    datasetHidden?: boolean;
    isPreview?: boolean;
    parentHiddenDocumentNameIds?: number[];
}

export const MyDatasetDefinition: React.FC<DatasetDefinitionProps> = ({ datasetDefinition, parents, modalInstance, datasetHidden, isPreview = false, parentHiddenDocumentNameIds }) => {
    const [screenWidth] = useWindowResize();
    const padding = parents > 0 ? 10 : 0;
    const width = (screenWidth - 80) - (parents * 10) + (modalInstance ? 18 : 0);
    const datasetInstanceWidth = modalInstance ? 'fit-content' : width;
    const hiddenFields = useAppSelector(getCurrentHiddenFields);
    const documentSpecificHiddenFields = useAppSelector(getDocumentSpecificHiddenFields);
    const allDocumentNames = useAppSelector(getAllDocumentNames);
    const parentDatasetId = useAppSelector(getSelectedDatasetId);

    const definitionComponent = useMemo(() => {
        if (isFormDatasetDefinition(datasetDefinition)) {
            return (<FormDefinition formDefinition={datasetDefinition} parents={parents} isPreview={isPreview} modalInstance={modalInstance} hiddenFields={hiddenFields} datasetHidden={datasetHidden} documentSpecificHiddenFields={documentSpecificHiddenFields} allDocumentNames={allDocumentNames} parentHiddenDocumentNameIds={parentHiddenDocumentNameIds} parentDatasetId={parentDatasetId!} /> );
        }
        return (<TableDefinition tableDefinition={datasetDefinition} isPreview={isPreview} modalInstance={modalInstance} hiddenFields={hiddenFields} datasetHidden={datasetHidden} documentSpecificHiddenFields={documentSpecificHiddenFields} allDocumentNames={allDocumentNames} parentHiddenDocumentNameIds={parentHiddenDocumentNameIds} parentDatasetId={parentDatasetId!} />);
    }, [datasetDefinition, modalInstance, parents, hiddenFields, datasetHidden, isPreview, documentSpecificHiddenFields, allDocumentNames, parentHiddenDocumentNameIds, parentDatasetId]);

    return (
        <div style={{ paddingLeft: `${padding}px`, width: `${datasetInstanceWidth}px`, maxWidth: `${screenWidth}px`, height: '100%' }}>
            {definitionComponent}
        </div>
    );
};
