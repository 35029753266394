import { action } from 'typesafe-actions';

import { DatasetDefinition } from '../../../datasets/store';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';
import { RiskAssociated, RiskAssociatedValue, RiskTolerance, RiskToleranceActionTypes, RiskToleranceDataset, SelectedRiskField } from './types';

export const fetchAllRiskToleranceStarted = () => action(RiskToleranceActionTypes.FETCH_ALL_RISK_TOLERANCE_STARTED);
export const fetchAllRiskToleranceSuccessful = (riskToleranceDatasets: RiskToleranceDataset[]) => action(RiskToleranceActionTypes.FETCH_ALL_RISK_TOLERANCE_SUCCESSFUL, riskToleranceDatasets);
export const fetchAllRiskToleranceFailed = (error: string) => action(RiskToleranceActionTypes.FETCH_ALL_RISK_TOLERANCE_FAILED, error);

export const fetchRiskToleranceByDatasetIdStarted = (datasetId: number) => action(RiskToleranceActionTypes.FETCH_RISK_TOLERANCE_BY_DATASET_ID_STARTED, datasetId);
export const fetchRiskToleranceByDatasetIdSuccessful = (riskTolerance: RiskTolerance[], definitions: DatasetDefinition[], documentHiddenFields: DocumentSpecificHiddenFields, selectedDatasetId: number) => action(RiskToleranceActionTypes.FETCH_RISK_TOLERANCE_BY_DATASET_ID_SUCCESSFUL, { riskTolerance, definitions, documentHiddenFields, selectedDatasetId });
export const fetchRiskToleranceByDatasetIdFailed = (error: string) => action(RiskToleranceActionTypes.FETCH_RISK_TOLERANCE_BY_DATASET_ID_FAILED, error);

export const goBackToAllRiskToleranceDatasets = () => action(RiskToleranceActionTypes.GO_BACK_TO_ALL_RISK_TOLERANCE_DATASETS);

export const setCurrentRiskToleranceField = (riskField: SelectedRiskField | null) => action(RiskToleranceActionTypes.SET_CURRENT_RISK_TOLERANCE_FIELD, riskField);
export const updateRiskFieldConfig = (riskAssociated: RiskAssociated, value: RiskAssociatedValue, selectedCurrency?: string) => action(RiskToleranceActionTypes.UPDATE_RISK_FIELD_CONFIG, { riskAssociated, value, selectedCurrency });
export const updateRiskFieldWeighting = (weighting: number) => action(RiskToleranceActionTypes.UPDATE_RISK_FIELD_WEIGHTING, weighting);

export const saveRiskToleranceStarted = (datasetId: number) => action(RiskToleranceActionTypes.SAVE_RISK_TOLERANCE_STARTED, datasetId);
export const saveRiskToleranceSuccessful = () => action(RiskToleranceActionTypes.SAVE_RISK_TOLERANCE_SUCCESSFUL);
export const saveRiskToleranceFailed = () => action(RiskToleranceActionTypes.SAVE_RISK_TOLERANCE_FAILED);

export const setRiskToleranceUpdated = (hasUpdated: boolean) => action(RiskToleranceActionTypes.SET_RISK_TOLERANCE_UPDATED, hasUpdated);

export const toggleUnsavedChangesModal = (isOpen: boolean) => action(RiskToleranceActionTypes.TOGGLE_UNSAVED_CHANGES_MODAL, isOpen);
