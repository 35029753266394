import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { checkResetLinkValidStarted, getConfirmNewPassword, getErrorMessage, getIsResettingPassword, getNewPassword, getPasswordIsStrongEnough, resetPasswordStarted, resetStoreValues, updatePasswordField } from './store';
import { PasswordInput } from './PasswordInput';
import styles from './ResetPassword.module.scss';
import Logo from '../../../assets/logos/Ark51_VERTWHITE&WHITE.png';
import { PasswordStrengthChecker } from './PasswordStrengthChecker';
import { TooltipList } from '../../shared/tooltip';

const { white } = styles;

interface ResetPasswordParams {
    resetLink: string | undefined;
}

export const ResetForgottenPassword: React.FC<RouteComponentProps<ResetPasswordParams>> = ({ match }) => {
    const { params, path } = match;
    const isNewUser = path.includes('new-user');
    const dispatch = useAppDispatch();
    const errorMessage = useAppSelector(getErrorMessage);
    const newPassword = useAppSelector(getNewPassword);
    const confirmNewPassword = useAppSelector(getConfirmNewPassword);
    const resettingPassword = useAppSelector(getIsResettingPassword);
    const passwordIsStrongEnough = useAppSelector(getPasswordIsStrongEnough);

    const attemptResetPassword = () => {
        if (params.resetLink) {
            dispatch(resetPasswordStarted(params.resetLink));
        }
    };

    const attemptKeyPressResetPassword = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter' && !emptyFields) {
            attemptResetPassword();
        }
    };

    const updateField = (key: string, value: string) => dispatch(updatePasswordField(key, value));

    const emptyFields = !newPassword.length || !confirmNewPassword.length;
    const newPasswordsUnequal = newPassword !== confirmNewPassword;
    const resetDisabled = emptyFields || resettingPassword || newPasswordsUnequal || !passwordIsStrongEnough;

    const resetDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (emptyFields) {
            disabledArray.push('One or more of the password inputs are empty');
        }
        if (newPasswordsUnequal) {
            disabledArray.push('Your new password and confirm password do not match');
        }
        if (!passwordIsStrongEnough && newPassword.length) {
            disabledArray.push('We recommend increasing your password strength by using capital letters, numbers and special characters');
        }
        return disabledArray;
    }, [emptyFields, newPasswordsUnequal, passwordIsStrongEnough, newPassword]);

    useEffect(() => {
        if (params.resetLink) {
            dispatch(checkResetLinkValidStarted(params.resetLink));
        }
    }, [params.resetLink, dispatch]);

    useEffect(() => () => {
        dispatch(resetStoreValues());
    }, [dispatch]);

    const buttonLabel = isNewUser ? 'Set Password' : 'Reset Password';

    return (
        <div className={styles.resetWithLinkWrapper}>
            <img className={styles.logoWrapper} src={Logo} />
            <div className={styles.wrapper} data-testid='reset-password-wrapper'>
                {isNewUser &&
                    <div className={styles.newUserWrapper}>
                        <div className={styles.newUserWelcome}>Welcome to Ark51</div>
                        <div className={styles.newUserNote}>Please set and confirm your password below to start using the application</div>
                    </div>
                }
                <div className={styles.inputsWrapper}>
                    <PasswordInput
                        testId='new-password-with-link-input'
                        updateField={updateField}
                        property='newPassword'
                        value={newPassword}
                        label='New Password'
                        onKeyDown={attemptKeyPressResetPassword}
                        color={white}
                    />
                    <PasswordInput
                        testId='confirm-new-password-with-link-input'
                        updateField={updateField}
                        property='confirmNewPassword'
                        value={confirmNewPassword}
                        label='Confirm New Password'
                        onKeyDown={attemptKeyPressResetPassword}
                        color={white}
                    />
                </div>
                <PasswordStrengthChecker password={newPassword} passwordScoreColour={white} />
                <div className={styles.errorMessage} data-testid='reset-forgotten-password-error-message'>{errorMessage}</div>
                <TooltipList overlayText={resetDisabledTooltip} color='disabled'>
                    <div className={styles.resetPasswordButtonWrapper}>
                        <button
                            className={styles.resetPasswordButton}
                            onClick={attemptResetPassword}
                            data-testid='reset-forgotten-password-with-link'
                            disabled={resetDisabled}
                        >
                            {buttonLabel}
                        </button>
                    </div>
                </TooltipList>
            </div>
        </div>
    );
};
