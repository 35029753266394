import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { isUndefined, uniq } from 'lodash/fp';

import { DatasetFieldType, SingleDatasetField } from '../../../../datasets/store';
import styles from '../../MyDatasets.module.scss';
import { CaretDown, CaretSide, Tick } from '../../../../shared/icons';
import { InformationTooltip } from '../../../../shared/tooltip';
import { Icon } from '../../../../shared/icon/Icon';
import { toggleHiddenField, MyDatasetsOpenFieldSection, HiddenField, HiddenFields } from '../../store';
import { OpenFieldType } from '../../../../datasets/instances/store';
import { useAppDispatch } from '../../../../../hooks/react-redux';
import { RadioButton } from '../../../../shared/button/RadioButton';
import { useSingleHiddenDocumentFields, useSingleHiddenFields } from '../../../../../hooks/useHiddenSingleFields';
import { getAdminFormDatasetField, getSingleFieldLabel } from '../../../../shared/datasets/AdminDatasetFields';
import { DocumentSpecificHiddenFields } from '../../../dataset-builder/store';
import { DocumentNameDB } from '../../../documents/store';
import { getHiddenDocumentsTooltip } from '../MyDatasetsFormDefinition';

interface SingleFieldProps {
    field: SingleDatasetField;
    sectionId: string;
    width?: number;
    isLastFieldInGroup?: boolean;
    isLastFieldInSection?: boolean;
    groupIndex?: number;
    datasetOnlySection?: boolean;
    datasetId: number;
    parents: number;
    toggleSection: (fieldSection: MyDatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: MyDatasetsOpenFieldSection) => boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    hiddenFields: HiddenFields;
    datasetHidden?: boolean;
    documentSpecificHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    parentHiddenDocumentNameIds?: number[];
    parentDatasetId: number;
}

export const SingleField: React.FC<SingleFieldProps> = ({
    field,
    width = 100,
    isLastFieldInGroup = false,
    isLastFieldInSection = false,
    sectionId,
    groupIndex,
    datasetOnlySection = false,
    datasetId,
    parents,
    toggleSection,
    getSectionOpen,
    isPreview,
    modalInstance,
    hiddenFields,
    datasetHidden,
    documentSpecificHiddenFields,
    allDocumentNames,
    parentHiddenDocumentNameIds,
    parentDatasetId
}) => {
    const dispatch = useAppDispatch();
    const { label, description, refLabel, settings, type, id } = field;
    const wysiwygFieldOpen = useMemo(() => !isUndefined(settings.isCollapsible) && !settings.isCollapsible, [settings.isCollapsible]);
    const openField = useMemo(() => ({ sectionId, datasetId: datasetId.toString() || '', fieldId: id!, type: OpenFieldType.FIELD, groupIndex }), [groupIndex, datasetId, id, sectionId]);
    const fieldOpen = useMemo(() => getSectionOpen(openField) || datasetOnlySection || wysiwygFieldOpen, [getSectionOpen, openField, datasetOnlySection, wysiwygFieldOpen]);
    const fieldOpenIcon = fieldOpen ? CaretDown : CaretSide;

    const isCollapsible = settings.isCollapsible || [DatasetFieldType.DATASET, DatasetFieldType.CALCULATOR].includes(type);
    const showFieldLabel = type !== DatasetFieldType.LABEL && !datasetOnlySection;
    const isLabelField = type === DatasetFieldType.LABEL;

    const singleFieldLabel = getSingleFieldLabel(label, type, id, isCollapsible, toggleSection, openField);

    const hiddenField: HiddenField = useMemo(() => ({ fieldId: id!, type: OpenFieldType.FIELD, sectionId }), [id, sectionId]);
    const toggleFieldVisible = useCallback(() => dispatch(toggleHiddenField(datasetId, hiddenField)), [datasetId, hiddenField, dispatch]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const sectionIsHidden = useMemo(() => !!currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(field => field.sectionId === sectionId), [currentHiddenDatasetFields, sectionId]);

    const fieldHidden = useMemo(() => sectionIsHidden || datasetHidden || !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields, id, sectionIsHidden, datasetHidden]);

    const documentHiddenFields = useMemo(() => documentSpecificHiddenFields[datasetId] || [], [datasetId, documentSpecificHiddenFields]);
    const documentHiddenSingleFields = useSingleHiddenDocumentFields(documentHiddenFields);
    const documentsHiddenForSection = useMemo(() => documentHiddenFields.filter(({ type }) => type === OpenFieldType.SECTION).find(hiddenField => hiddenField.sectionId === sectionId)?.documentNameIds || [], [documentHiddenFields, sectionId]);
    const documentsHiddenForField = useMemo(() => documentHiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds || [], [documentHiddenSingleFields, id]);
    const parentHiddenDocuments = !isUndefined(parentHiddenDocumentNameIds) ? parentHiddenDocumentNameIds : [];
    const allDocumentsHiddenForField = uniq([...documentsHiddenForField, ...documentsHiddenForSection, ...parentHiddenDocuments]);
    const hiddenDocumentNames = useMemo(() => allDocumentNames.filter(document => document.datasetId === parentDatasetId).reduce((acc: string[], { documentNameId, documentName }) => documentNameId && allDocumentsHiddenForField.includes(documentNameId) ? [...acc, documentName.toLowerCase()] : acc, []), [allDocumentNames, allDocumentsHiddenForField, parentDatasetId]);
    const documentNameIdsForDataset = useMemo(() => allDocumentNames.filter(document => document.datasetId === parentDatasetId).map(({ documentNameId }) => documentNameId!), [allDocumentNames, parentDatasetId]);

    const fieldHiddenForAllDatasetDocuments = useMemo(() => documentsHiddenForField.length > 0 && documentNameIdsForDataset.every(documentNameId => documentsHiddenForField.includes(documentNameId)), [documentNameIdsForDataset, documentsHiddenForField]);
    const sectionHiddenForAllDatasetDocuments = useMemo(() => documentsHiddenForSection.length > 0 && documentNameIdsForDataset.every(documentNameId => documentsHiddenForSection.includes(documentNameId)), [documentNameIdsForDataset, documentsHiddenForSection]);

    if ((isPreview && fieldHidden) || fieldHiddenForAllDatasetDocuments || sectionHiddenForAllDatasetDocuments) {
        return null;
    }

    return (
        <div
            className={classnames(styles.singleFieldWrapper, { [styles.lastFieldInGroup]: isLastFieldInGroup, [styles.isLabelField]: isLabelField && !isLastFieldInSection })}
            style={{ width: `${width}%` }}
            data-testid={`form-field-${id}-wrapper`}
        >
            {showFieldLabel &&
                <div className={classnames(styles.fieldTitleWrapper, { [styles.fieldCanCollapse]: isCollapsible })}>
                    {isCollapsible &&
                        <div className={styles.fieldOpenWrapper} onClick={() => toggleSection(openField)} data-testid='collapsible-field'>
                            <Icon icon={fieldOpenIcon} fontSize={15} />
                        </div>
                    }
                    {singleFieldLabel}
                    {description && <InformationTooltip content={description} />}
                    {refLabel && settings.showRef && <InformationTooltip content={refLabel} label='?' />}
                    {settings.showClause && <InformationTooltip content='Add personalised clause label here' label='cl' />}
                    {!isPreview && <RadioButton fontSize={10} withBackground={!fieldHidden} disabled={sectionIsHidden || datasetHidden} onClick={toggleFieldVisible} icon={Tick} marginRight='5px' />}
                    {hiddenDocumentNames.length > 0 && getHiddenDocumentsTooltip(hiddenDocumentNames, 'field')}
                </div>
            }
            {getAdminFormDatasetField(
                field,
                datasetId,
                fieldOpen,
                parents,
                toggleSection,
                getSectionOpen,
                sectionId,
                isPreview,
                true,
                groupIndex,
                modalInstance,
                fieldHidden,
                allDocumentsHiddenForField
            )}
        </div>
    );
};
