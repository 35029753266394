import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { agreementTypeDocumentsPaginationNext, agreementTypeDocumentsPaginationPrevious, clearAgreementTypeDocumentTableFilters, getAgreementTypeDocumentColumnSort, getAgreementTypeDocumentFilters, getAgreementTypeDocumentPageSize, getAgreementTypeDocuments, getAgreementTypeDocumentsPageNumber, getIsLoading, getTotalAgreementTypeDocuments, setAgreementTypeDocumentsPageSize, setAgreementTypeDocumentTableColumnSort, setAgreementTypeDocumentTableFilters } from '../../../documents/my-documents/store';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../shared/table/ArkTable';
import { dateFormatter } from '../../../shared/table/arkTableFormatters';
import { getIsLinkingContract, getSelectedElementContractualInfo, linkSupplyChainToIctContractStarted } from '../store';

interface IctContractTableProps {
    width: number;
    height: number;
}

export const IctContractTable: React.FC<IctContractTableProps> = ({ width, height }) => {
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(getIsLoading);
    const availableIctContracts = useAppSelector(getAgreementTypeDocuments);
    const pageNumber = useAppSelector(getAgreementTypeDocumentsPageNumber);
    const total = useAppSelector(getTotalAgreementTypeDocuments);
    const filters = useAppSelector(getAgreementTypeDocumentFilters);
    const colSort = useAppSelector(getAgreementTypeDocumentColumnSort);
    const pageSize = useAppSelector(getAgreementTypeDocumentPageSize);
    const selectedIctContractInstance = useAppSelector(getSelectedElementContractualInfo);
    const isLinkingContract = useAppSelector(getIsLinkingContract);

    const next = useCallback(() => { dispatch(agreementTypeDocumentsPaginationNext()); }, [dispatch]);
    const previous = useCallback(() => { dispatch(agreementTypeDocumentsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setAgreementTypeDocumentTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearAgreementTypeDocumentTableFilters()); }, [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setAgreementTypeDocumentTableColumnSort(columnSort)); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setAgreementTypeDocumentsPageSize(pageSize)); }, [dispatch]);

    const setContractId = useCallback((id: number) => dispatch(linkSupplyChainToIctContractStarted(id)), [dispatch]);

    const rowData = useMemo(() => availableIctContracts.map(({ documentDescription, documentId, entityAName, entityBName, executedDate }) => ({ documentDescription, documentId, entityAName, entityBName, executedDate })), [availableIctContracts]);

    const selectedId = useMemo(() => !isNull(selectedIctContractInstance) ? availableIctContracts.map(({ documentId }) => documentId).filter(documentId => documentId === selectedIctContractInstance.originalDocumentId) : [], [availableIctContracts, selectedIctContractInstance]);

    const select = useCallback((documentId: number | string) => {
        setContractId(documentId as number);
    }, [setContractId]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', component: 'tooltip', canFilter: true, canSort: true, width: 0.4 },
        { id: 'entityAName', header: 'Party A', field: 'entityAName', component: 'tooltip', canFilter: true, canSort: true, width: 0.2 },
        { id: 'entityBName', header: 'Party B', field: 'entityBName', component: 'tooltip', canFilter: true, canSort: true, width: 0.2 },
        { id: 'executedDate', header: 'Date of Execution', field: 'executedDate', width: 0.1, valueFormatter: dateFormatter, canSort: true },
        { id: 'add', header: 'Link to Document', field: 'documentId', width: 0.1, component: 'select', select: select, selectedIds: selectedId, selectDisabled: () => isLinkingContract }
    ];

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={rowData}
            testId={'testId'}
            page={pageNumber}
            total={total}
            next={next}
            previous={previous}
            clearAllFilters={clearAllFilters}
            filters={filters}
            updateFilter={updateFilter}
            isLoading={isLoading}
            columnSort={colSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
            width={width}
            height={height}
        />
    );
};
