import { combineReducers } from 'redux';

import { loginReducer, LoginState } from './login/store';
import { notificationReducer, NotificationState } from './notifications/store';
import { registrationReducer, RegistrationState } from './registration/store';
import { resetPasswordReducer, ResetPasswordState } from './resetPassword/store';

export interface AuthState {
    login: LoginState;
    registration: RegistrationState;
    resetPassword: ResetPasswordState;
    notification: NotificationState;
}

export const authReducer = combineReducers({
    login: loginReducer,
    registration: registrationReducer,
    resetPassword: resetPasswordReducer,
    notification: notificationReducer
});
