import { ApplicationState } from '../../../store/rootReducer';
import { AgencyDatasetField } from '../../datasets/store';
import { AnnexDefinition, AnnexDefinitionDB, AnnexDefinitionState, InstanceAnnexDefinition, InstanceAnnexView } from './types';

const getRoot = (state: ApplicationState): AnnexDefinitionState => state.annexDefinition;

export const getAnnexDefinitionModalOpen = (state: ApplicationState): boolean => getRoot(state).annexDefinitionModalOpen;

export const getAnnexBuilderModalOpen = (state: ApplicationState): boolean => getRoot(state).annexBuilderModalOpen;

export const getIsPublishing = (state: ApplicationState): boolean => getRoot(state).isPublishing;

export const getAnnexDefinition = (state: ApplicationState): AnnexDefinition | null => getRoot(state).annexDefinition;

export const getAvailableFields = (state: ApplicationState): AgencyDatasetField[] => getRoot(state).availableFields;

export const getAllAnnexDefinitions = (state: ApplicationState): AnnexDefinitionDB[] => getRoot(state).publishedAnnexDefinitions;

export const getCurrentInstanceAnnexDefinitions = (state: ApplicationState): InstanceAnnexDefinition[] => getRoot(state).currentInstanceAnnexDefinitions;

export const getSavedInstanceAnnexDefinitions = (state: ApplicationState): InstanceAnnexDefinition[] => getRoot(state).savedInstanceAnnexDefinitions;

export const getInstanceAnnexDefinitionsUpdated = (state: ApplicationState): boolean => getRoot(state).instanceAnnexDefinitionsUpdated;

export const getInstanceAnnexView = (state: ApplicationState): InstanceAnnexView => getRoot(state).instanceAnnexView;

export const getSelectedInstanceIndex = (state: ApplicationState): number => getRoot(state).selectedInstanceIndex;
