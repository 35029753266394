import { getOr, isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getDocumentInView, getIsOpening, getDocumentUrl, getOriginalDocument, getSecondaryDocument, getSelectedSupportDocument, getSupportDocuments, SelectedDocument, toggleDocumentInView, AnalysisView, DocumentType } from '../../documents/my-documents/store';
import { Analysis, Tabs } from './Analysis';

interface DocumentAnalysisViewerProps {
    width: number;
}

export const DocumentAnalysisViewer: React.FC<DocumentAnalysisViewerProps> = ({ width }) => {
    const dispatch = useAppDispatch();

    const isOpeningDocument = useAppSelector(getIsOpening);
    const documentUrl = useAppSelector(getDocumentUrl);
    const documentInView = useAppSelector(getDocumentInView);
    const originalDocument = useAppSelector(getOriginalDocument);
    const secondaryDocument = useAppSelector(getSecondaryDocument);
    const supportDocument = useAppSelector(getSelectedSupportDocument);
    const supportDocuments = useAppSelector(getSupportDocuments);

    const selectedDocument = useMemo(() => {
        switch (documentInView) {
            case SelectedDocument.SUPPORT:
                return supportDocument;
            case SelectedDocument.SECONDARY:
                return secondaryDocument;
            case SelectedDocument.ORIGINAL:
            default:
                return originalDocument;

        }
    }, [documentInView, supportDocument, secondaryDocument, originalDocument]);

    const scheduleStartPage = useMemo(() => !isNull(originalDocument) && originalDocument.scheduleStartPage || 1, [originalDocument]);

    const toggleSelectedDocument = useCallback((selectedDocument: SelectedDocument) => selectedDocument !== documentInView && dispatch(toggleDocumentInView(selectedDocument)), [dispatch, documentInView]);

    const tabOptions = useMemo<Tabs[]>(() => {
        let tabs = [
            {
                title: getOr('', 'documentDescription', originalDocument),
                onClick: () => toggleSelectedDocument(SelectedDocument.ORIGINAL),
                selected: documentInView === SelectedDocument.ORIGINAL,
                type: originalDocument?.documentType || undefined
            },
            {
                title: getOr('', 'documentDescription', secondaryDocument),
                onClick: () => toggleSelectedDocument(SelectedDocument.SECONDARY),
                selected: documentInView === SelectedDocument.SECONDARY,
                disabled: !secondaryDocument,
                type: secondaryDocument?.documentType || undefined
            }
        ];

        if (supportDocuments.length) {
            tabs.push({
                title: `Support Documents (${supportDocuments.length})`,
                onClick: () => toggleSelectedDocument(SelectedDocument.SUPPORT),
                selected: documentInView === SelectedDocument.SUPPORT,
                type: DocumentType.SUPPORT
            });
        }

        return tabs;
    }, [toggleSelectedDocument, documentInView, originalDocument, secondaryDocument, supportDocuments]);

    return (
        <Analysis
            selected={selectedDocument}
            url={documentUrl}
            documentInView={documentInView}
            isOpening={isOpeningDocument}
            documentAnalysisView={AnalysisView.SPLIT}
            tabOptions={tabOptions}
            scheduleStartPage={scheduleStartPage}
            width={width}
        />
    );
};
