import React, { useCallback, useMemo } from 'react';

import { SingleField } from './SingleField';
import styles from '../../shared/datasets/SharedStyling.module.scss';
import { GroupInstanceField, OpenFieldSection, UpdatedFormField } from '../instances/store';
import { fieldTypeWidths, MAX_GROUP_WIDTH } from '../../admin/dataset-builder/FieldList';
import { HiddenFields } from '../../admin/my-datasets/store';
import { useSingleHiddenFields } from '../../../hooks/useHiddenSingleFields';

interface GroupFieldProps {
    field: GroupInstanceField;
    groupIndex: number;
    sectionId: string;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedFormField[];
    annexFieldIds: string[];
    isAnnexInstance: boolean;
    datasetId: number;
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean,
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    isLastFieldInSection?: boolean;
    isMLCorrection?: boolean;
}

export const GroupField: React.FC<GroupFieldProps> = ({
    field,
    groupIndex,
    sectionId,
    isEditing,
    isUpdating,
    fieldsUpdated,
    annexFieldIds,
    isAnnexInstance,
    datasetId,
    parentFieldId,
    instanceExecutedDateMatchesParent,
    modalInstance,
    getSectionOpen,
    toggleSection,
    hiddenFields,
    datasetHidden,
    isLastFieldInSection,
    isMLCorrection
}) => {
    const { children } = field;

    const currentHiddenDatasetFields = useMemo(() => hiddenFields && hiddenFields[datasetId] || [], [datasetId, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const childFieldHidden = useCallback((id: string) => !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields]);

    const childArray = useMemo(() => children.filter(({ id }) => !hiddenSingleFields.map(({ fieldId }) => fieldId).includes(id!)), [children, hiddenSingleFields]);

    const groupWidth = useMemo(() => childArray.reduce((acc, cur) => acc + (fieldTypeWidths[cur.type] / MAX_GROUP_WIDTH), 0), [childArray]);

    return (
        <div className={styles.groupFieldWrapper} data-testid={`form-group-field-${groupIndex}-wrapper`}>
            {children.map((field, index) => {
                const childHidden = childFieldHidden(field.id!);
                if (childHidden) {
                    return null;
                }
                const isLastFieldInGroup = index === childArray.length - 1;
                const singleIndex = `${groupIndex}${index}`;
                const width = fieldTypeWidths[field.type] / groupWidth * 0.98;
                return (
                    <SingleField
                        key={field.id}
                        field={field}
                        width={width}
                        isLastFieldInGroup={isLastFieldInGroup}
                        index={index}
                        groupIndex={groupIndex}
                        singleIndex={singleIndex}
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        annexFieldIds={annexFieldIds}
                        isAnnexInstance={isAnnexInstance}
                        datasetId={datasetId}
                        parentFieldId={parentFieldId}
                        instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                        modalInstance={modalInstance}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        isLastFieldInSection={isLastFieldInSection}
                        isMLCorrection={isMLCorrection}
                    />
                );
            })}
        </div>
    );
};
