import React from 'react';
import { getOr } from 'lodash/fp';

import { AddressInput } from './AddressInput';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { addOtherAddress, getAllCompanyEntityNames, PersonEntity, PersonEntityContent, removeAlternativeAddress, updateAddressEntityValue, updateEntityContent, updateEntityValue } from '../store';
import { OtherAddresses } from './OtherAddresses';
import { Text } from '../../../shared/text/Text';
import styles from './EntityType.module.scss';
import { getAllDropdownLists } from '../../dropdown-lists/store';
import { SingleSelectDropdown } from '../../../shared/dropdown/SingleSelectDropdown';
import { Scrollable } from '../../../shared/scrollable/Scrollable';

interface PersonEntityFormProps {
    entity: PersonEntity;
}

export const PersonEntityForm: React.FC<PersonEntityFormProps> = ({ entity }) => {
    const dispatch = useAppDispatch();
    const allDropdownLists = useAppSelector(getAllDropdownLists);
    const companyNames = useAppSelector(getAllCompanyEntityNames);
    const titleOptions = allDropdownLists.find(({ name }) => name === 'Title')?.options || [];
    const handleUpdateValue = (key: string, value: string | Date | null) => dispatch(updateEntityValue(key, value));
    const handleUpdateContent = (key: keyof PersonEntityContent, value: string | Date | null) => dispatch(updateEntityContent(key, value));
    const handleUpdateAddressValue = (key: string, value: string | null, index?: number) => dispatch(updateAddressEntityValue(key, value, index));
    const addAnotherAddress = () => dispatch(addOtherAddress());
    const deleteAddress = (index: number | undefined) => dispatch(removeAlternativeAddress(index));
    const { content: { title, forenames, surname, position, address, email, phoneNumber, company, faxNumber, mobileNumber } } = entity;

    const updateForenames = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = `${e.target.value} ${surname}`;
        handleUpdateContent('forenames', e.target.value);
        handleUpdateValue('name', name);
    };

    const updateSurname = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = `${forenames} ${e.target.value}`;
        handleUpdateContent('surname', e.target.value);
        handleUpdateValue('name', name);
    };

    return (
        <div className={styles.personEntityContentWrapper} data-testid='create-entity-content-wrapper'>
            <Scrollable>
                <SingleSelectDropdown
                    label='Title'
                    testId='title'
                    updateValue={value => handleUpdateContent('title', value)}
                    value={title}
                    options={titleOptions}
                />
                <Text
                    label='Forename(s)'
                    testId='entity-forenames'
                    onChange={updateForenames}
                    value={forenames}
                    placeholder='Forname(s)...'
                />
                <Text
                    label='Surname'
                    testId='entity-surname'
                    onChange={updateSurname}
                    value={surname}
                    placeholder='Surname...'
                />
                <Text
                    label='Position'
                    testId='entity-position'
                    onChange={e => handleUpdateContent('position', e.target.value)}
                    value={position}
                    placeholder='Position...'
                />
                <SingleSelectDropdown
                    label='Company'
                    testId='company'
                    updateValue={value => handleUpdateContent('company', value)}
                    value={company}
                    options={companyNames}
                />
                <div>
                    <div className={styles.addressLabel} data-testid='entity-address-label'>Address</div>
                    <AddressInput
                        address1={getOr('', 'address1', address)}
                        address2={getOr('', 'address2', address)}
                        city={getOr('', 'city', address)}
                        country={getOr('', 'country', address)}
                        postalCode={getOr('', 'postalCode', address)}
                        onValueChange={handleUpdateAddressValue}
                        deleteFunction={deleteAddress}
                        entity={entity}
                    />
                </div>
                <Text
                    label='Email'
                    testId='entity-email'
                    onChange={e => handleUpdateContent('email', e.target.value)}
                    value={email}
                    placeholder='Email...'
                />
                <Text
                    label='Phone Number'
                    testId='entity-phone-number'
                    onChange={e => handleUpdateContent('phoneNumber', e.target.value)}
                    value={phoneNumber}
                    placeholder='Phone Number...'
                />
                <Text
                    label='Mobile Number'
                    testId='entity-mobile-number'
                    onChange={e => handleUpdateContent('mobileNumber', e.target.value)}
                    value={mobileNumber}
                    placeholder='Mobile Number...'
                />
                <Text
                    label='Fax Number'
                    testId='entity-fax-number'
                    onChange={e => handleUpdateContent('faxNumber', e.target.value)}
                    value={faxNumber}
                    placeholder='Fax Number...'
                />
                <OtherAddresses
                    handleUpdateAddressValue={handleUpdateAddressValue}
                    addAddress={addAnotherAddress}
                    deleteAddress={deleteAddress}
                    entity={entity}
                />
            </Scrollable>
        </div>
    );
};
