import React, { useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { PDFViewer, ReactPdf } from './PDFViewer';
import styles from './DocumentViewer.module.scss';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getIncompleteDocumentPageTotal, setIncompleteDocumentPageTotal } from '../my-documents/store';

interface DocumentPreviewViewerProps {
    url: string | null;
    mimeType: string | null;
    selectedPage?: number | null;
    setSelectedPage?: (page: number | null) => void;
}

export const DocumentPreviewViewer: React.FC<DocumentPreviewViewerProps> = ({ url, mimeType, selectedPage, setSelectedPage }) => {
    const numPages = useAppSelector(getIncompleteDocumentPageTotal);
    const dispatch = useAppDispatch();
    const setNumPages = (numberOfPages: number | null) => dispatch(setIncompleteDocumentPageTotal(numberOfPages));

    const onLoadSuccess = ({ numPages }: ReactPdf) => setNumPages(numPages);

    const [width] = useWindowResize();
    const pageWidth = useMemo(() => (width * 0.5) - 40, [width]);

    if (mimeType === 'application/pdf' && !isNull(url)) {
        return (
            <div className={styles.pdfViewerWrapper}>
                <PDFViewer
                    onLoadSuccess={onLoadSuccess}
                    resetNumPages={() => setNumPages(null)}
                    numPages={numPages}
                    pdfUrl={url}
                    width={pageWidth}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                />
            </div>
        );
    }

    return null;
};
