import { isArray, isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { OverflowTooltip } from '../../../shared/tooltip';
import { getCurrentNettingEngineDocument, getSavedNettingEngineDocument, NettingEngineQuestionType, NettingEngineTheory, productsTradedOptions, updateNettingEngineField, calculateExistingDocumentsNettingEngineStarted, NettingEngineDocumentSummaryQuestion, NettingEngineCounterparty, getDocumentSummaryView, toggleDocumentSummaryView } from '../store';
import styles from './NettingEngineDocuments.module.scss';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { fetchAllDropdownListsStarted, getAllDropdownLists } from '../../../admin/dropdown-lists/store';
import { fetchAllNettingEntitiesStarted, getNettingEntities } from '../../../admin/entity/store';
import { counterpartyCoverageOptions, nettingEngineAgreementOptions } from '../Utils';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Button } from '../../../shared/button/Button';
import { AnalysisViewButtons } from '../../../shared/analysis/AnalysisViewButtons';
import { AnalysisView } from '../../../documents/my-documents/store';
import { ANALYSIS_MARGIN, NAV_BAR, PAGE_MARGIN_PADDING_BORDER, PAGE_VERTICAL_MARGIN_PADDING_BORDER, useSplitView } from '../../../../hooks/useSplitView';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { DocumentAnalysisViewer } from '../../../shared/analysis/DocumentAnalysisViewer';
import { CreatableDropdown } from '../../../shared/dropdown/CreateableDropdown';

const HEADER_AND_BUTTONS_WITH_MARGIN = 120;

export const NettingEngineDocumentSummary: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDropdownListsStarted(), fetchAllNettingEntitiesStarted()]);
    const savedNettingEngineDocument = useAppSelector(getSavedNettingEngineDocument);
    const currentNettingEngineDocument = useAppSelector(getCurrentNettingEngineDocument);
    const dropdownLists = useAppSelector(getAllDropdownLists);
    const allEntities = useAppSelector(getNettingEntities);
    const documentSummaryView = useAppSelector(getDocumentSummaryView);

    const [screenWidth, screenHeight] = useWindowResize();
    const [pageWidth, pageHeight] = useSplitView(documentSummaryView, PAGE_MARGIN_PADDING_BORDER, PAGE_VERTICAL_MARGIN_PADDING_BORDER);

    const entityOptions = allEntities.map(({ name, entityId }) => ({ value: entityId!.toString(), label: name }));

    const toggleAnalysisView = useCallback((view: AnalysisView) => view !== documentSummaryView && dispatch(toggleDocumentSummaryView(view)), [dispatch, documentSummaryView]);

    const back = useCallback(() => {
        history.push('/opinions/netting-engine/documents');
        dispatch(toggleDocumentSummaryView(AnalysisView.DATASET));
    }, [history, dispatch]);

    const getDropdownListOptions = useCallback((listName: string) => {
        const dropdownList = dropdownLists.find(({ name }) => name === listName);
        return dropdownList ? dropdownList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const cityOptions = getDropdownListOptions('City');
    const filteredCityOptions = cityOptions.filter(({ label }) => label !== 'Other');
    const [counterpartyOfficeOptions, setCounterpartyOfficeOptions] = useState(filteredCityOptions);
    const jurisdictionOptions = getDropdownListOptions('OpinionJurisdiction');
    const governingLawOptions = getDropdownListOptions('GoverningLaw');
    const sectionTenAOptions = getDropdownListOptions('YesNo');

    const getDropdownValueForAnswer = useCallback((answer: string | null) => isNull(answer) ? null : { value: answer, label: answer }, []);

    const getCounterpartyAnswer = useCallback((entityId: string | null) => isNull(entityId) ? null : { value: entityId, label: allEntities.find(entity => entity.entityId === parseInt(entityId))!.name }, [allEntities]);

    const getMultiDropdownValueForAnswer = useCallback((answer: string[] | null) => isNull(answer) ? null : answer.map(answer => ({ value: answer, label: answer })), []);

    const counterpartyNameKnown = useMemo(() => !isNull(currentNettingEngineDocument) ? currentNettingEngineDocument!.counterpartyNameKnown : false, [currentNettingEngineDocument]);
    const counterpartyName = useMemo(() => !isNull(currentNettingEngineDocument) && currentNettingEngineDocument.counterparty.entityId || null, [currentNettingEngineDocument]);
    const counterpartyJurisdiction = useMemo(() => !isNull(currentNettingEngineDocument) && currentNettingEngineDocument.counterparty.jurisdiction || null, [currentNettingEngineDocument]);
    const counterpartyClassification = useMemo(() => !isNull(currentNettingEngineDocument) && currentNettingEngineDocument.counterparty.counterpartyClassification || null, [currentNettingEngineDocument]);
    const agreement = useMemo(() => !isNull(currentNettingEngineDocument) && currentNettingEngineDocument.agreement || null, [currentNettingEngineDocument]);
    const governingLaw = useMemo(() => !isNull(currentNettingEngineDocument) && currentNettingEngineDocument.governingLaw || null, [currentNettingEngineDocument]);
    const sectionTenAApplies = useMemo(() => !isNull(currentNettingEngineDocument) && currentNettingEngineDocument.sectionTenAApplies || null, [currentNettingEngineDocument]);
    const productTypeKnown = useMemo(() => !isNull(currentNettingEngineDocument) ? currentNettingEngineDocument.productTypeKnown : null, [currentNettingEngineDocument]);
    const productsTraded = useMemo(() => !isNull(currentNettingEngineDocument) && currentNettingEngineDocument.productsTraded.length > 0 ? currentNettingEngineDocument.productsTraded : null, [currentNettingEngineDocument]);
    const productsTradedDropdownOptions: DropdownOption[] = useMemo(() => productsTradedOptions.map(value => ({ value, label: value })), []);
    const counterpartyIsMultiBranch = useMemo(() => !isNull(currentNettingEngineDocument) ? currentNettingEngineDocument.counterpartyIsMultiBranch : null, [currentNettingEngineDocument]);
    const counterpartyOffices = useMemo(() => !isNull(currentNettingEngineDocument) && currentNettingEngineDocument.counterpartyOffices.length > 0 ? currentNettingEngineDocument.counterpartyOffices : null, [currentNettingEngineDocument]);

    const productsTradedQuestionVisible = useMemo(() => !isNull(currentNettingEngineDocument) ? currentNettingEngineDocument.productTypeKnown : null, [currentNettingEngineDocument]);
    const counterpartyBranchQuestionVisible = useMemo(() => !isNull(currentNettingEngineDocument) ? currentNettingEngineDocument.counterpartyIsMultiBranch : null, [currentNettingEngineDocument]);

    const nettingEngineSurvey: NettingEngineDocumentSummaryQuestion[] = useMemo(() => [
        { question: 'Do you know the name of your counterparty?', type: NettingEngineQuestionType.BOOLEAN, value: counterpartyNameKnown, key: 'counterpartyNameKnown' },
        { question: 'What is the name of your counterparty?', type: NettingEngineQuestionType.DROPDOWN, dropdownOptions: entityOptions, value: getCounterpartyAnswer(counterpartyName), key: 'counterparty', counterpartyKey: 'entityId' },
        { question: 'What is the Jurisdiction of your counterparty?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(counterpartyJurisdiction), dropdownOptions: jurisdictionOptions, key: 'counterparty', counterpartyKey: 'jurisdiction' },
        { question: 'What is your counterparty’s classification?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(counterpartyClassification), dropdownOptions: counterpartyCoverageOptions, key: 'counterparty', counterpartyKey: 'counterpartyClassification' },
        { question: 'What agreement do you intend to put in place with your counterparty?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(agreement), dropdownOptions: nettingEngineAgreementOptions, key: 'agreement' },
        { question: 'What is the governing law of your agreement?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(governingLaw), dropdownOptions: governingLawOptions, key: 'governingLaw' },
        { question: 'Does section 10(a) apply to your agreement?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(sectionTenAApplies), dropdownOptions: sectionTenAOptions, key: 'sectionTenAApplies' },
        { question: 'Do you know which product types are to be traded under the relevant agreement?', type: NettingEngineQuestionType.BOOLEAN, value: productTypeKnown, key: 'productTypeKnown' },
        { question: 'Select product types to be traded', type: NettingEngineQuestionType.DROPDOWN, dropdownOptions: productsTradedDropdownOptions, value: getMultiDropdownValueForAnswer(productsTraded), key: 'productsTraded', isMulti: true, closeOnSelect: false, isHidden: !productsTradedQuestionVisible },
        { question: 'Is your counterparty acting on a multi-branch basis?', type: NettingEngineQuestionType.BOOLEAN, value: counterpartyIsMultiBranch, key: 'counterpartyIsMultiBranch' },
        { question: 'Please provide a list of all of the offices through which your counterparty may act.', type: NettingEngineQuestionType.DROPDOWN, dropdownOptions: counterpartyOfficeOptions, value: getMultiDropdownValueForAnswer(counterpartyOffices), key: 'counterpartyOffices', isMulti: true, closeOnSelect: false, isHidden: !counterpartyBranchQuestionVisible },
    ], [counterpartyClassification, entityOptions, getCounterpartyAnswer, counterpartyJurisdiction, counterpartyName, getDropdownValueForAnswer, counterpartyOfficeOptions, jurisdictionOptions, agreement, governingLaw, governingLawOptions, productTypeKnown, productsTradedDropdownOptions, counterpartyIsMultiBranch, counterpartyOffices, getMultiDropdownValueForAnswer, productsTraded, productsTradedQuestionVisible, counterpartyBranchQuestionVisible, counterpartyNameKnown, sectionTenAApplies, sectionTenAOptions]);

    const getBooleanDropdownValue = useCallback((rawValue: boolean | null) => {
        if (isNull(rawValue)) {
            return null;
        }
        return rawValue ? { value: 'Yes', label: 'Yes' } : { label: 'No', value: 'No' };
    }, []);

    const updateDropdownFilter = useCallback((key: keyof NettingEngineTheory, dropdownValue: DropdownOption | Options<DropdownOption> | null, counterpartyKey?: string) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            if (isArray(dropdownValue)) {
                // Updating individual options from a multi-select dropdown
                value = (dropdownValue as Options<DropdownOption>).map(({ value }) => value);
            } else {
                const selectedValue = (dropdownValue as DropdownOption).value;
                if (['Yes', 'No'].includes(selectedValue) && key !== 'sectionTenAApplies') {
                    value = selectedValue === 'Yes';
                } else {
                    value = selectedValue;
                }
            }
        }
        dispatch(updateNettingEngineField(key, value, counterpartyKey));
    }, [dispatch]);

    const updateCounterpartyOfficeOptions = useCallback((options: DropdownOption[]) => setCounterpartyOfficeOptions(options), []);

    const getSavedValue = useCallback((key: keyof NettingEngineTheory, counterpartyKey?: keyof NettingEngineCounterparty) => {
        if (isNull(savedNettingEngineDocument)) {
            return null;
        }
        return !isUndefined(counterpartyKey) ? (savedNettingEngineDocument[key] as NettingEngineCounterparty)[counterpartyKey] : savedNettingEngineDocument[key];
    }, [savedNettingEngineDocument]);

    const noGoverningLawValue = useMemo(() => isNull(!isNull(savedNettingEngineDocument) ? savedNettingEngineDocument['governingLaw'] : null), [savedNettingEngineDocument]);
    const noSectionTenAValue = useMemo(() => isNull(!isNull(savedNettingEngineDocument) ? savedNettingEngineDocument['sectionTenAApplies'] : null), [savedNettingEngineDocument]);

    const showWarning = useCallback((key: string) => (noGoverningLawValue && key === 'governingLaw') || (noSectionTenAValue && key === 'sectionTenAApplies'), [noGoverningLawValue, noSectionTenAValue]);

    const getFieldDisabled = useCallback((key: keyof NettingEngineTheory, counterpartyKey?: keyof NettingEngineCounterparty) => {
        const savedValue = getSavedValue(key, counterpartyKey);
        if (['counterpartyIsMultiBranch', 'counterpartyOffices', 'productTypeKnown', 'productsTraded'].includes(key)) {
            return false;
        }
        return !isNull(savedValue);
    }, [getSavedValue]);

    const summaryAnswers = useCallback((value: DropdownOption | Options<DropdownOption> | boolean | null, type: NettingEngineQuestionType, key: keyof NettingEngineTheory, counterpartyKey?: keyof NettingEngineCounterparty, dropdownOptions?: DropdownOption[], isMulti?: boolean, closeOnSelect?: boolean) => {
        switch (type) {
            case NettingEngineQuestionType.BOOLEAN: {
                const booleanValue = (value as boolean | null);
                const dropdownValue = getBooleanDropdownValue(booleanValue);
                const options = [{ value: 'Yes', label: 'Yes' }, { label: 'No', value: 'No' }];
                const isDisabled = getFieldDisabled(key, counterpartyKey);
                return (
                    <div className={styles.surveyDropdownWrapper}>
                        <Dropdown
                            value={dropdownValue}
                            options={options}
                            onChange={value => updateDropdownFilter(key, value, counterpartyKey)}
                            isMulti={false}
                            closeOnSelect={closeOnSelect}
                            disabled={isDisabled}
                        />
                    </div>
                );
            }
            case NettingEngineQuestionType.DROPDOWN: {
                const dropdownValue = value as DropdownOption | DropdownOption[] | null;
                const isDisabled = getFieldDisabled(key, counterpartyKey);
                return (
                    <div className={styles.surveyDropdownWrapper}>
                        {key === 'counterpartyOffices' ? <CreatableDropdown
                            value={dropdownValue}
                            options={dropdownOptions!}
                            onChange={value => updateDropdownFilter(key, value, counterpartyKey)}
                            updateOptions={updateCounterpartyOfficeOptions}
                            isMulti={isMulti}
                            closeOnSelect={closeOnSelect}
                            disabled={isDisabled}
                        /> :
                            <Dropdown
                                value={dropdownValue}
                                options={dropdownOptions!}
                                onChange={value => updateDropdownFilter(key, value, counterpartyKey)}
                                isMulti={isMulti}
                                closeOnSelect={closeOnSelect}
                                disabled={isDisabled}
                            />
                        }
                    </div>);
            }
        }
    }, [getBooleanDropdownValue, updateDropdownFilter, updateCounterpartyOfficeOptions, getFieldDisabled]);

    const calculate = useCallback(() => dispatch(calculateExistingDocumentsNettingEngineStarted()), [dispatch]);

    const calculationIsValid = useMemo(() =>
        [currentNettingEngineDocument, counterpartyNameKnown, counterpartyName, counterpartyJurisdiction, agreement, governingLaw, productTypeKnown, counterpartyBranchQuestionVisible, sectionTenAApplies].every(option => !isNull(option))
    , [currentNettingEngineDocument, counterpartyNameKnown, counterpartyName, counterpartyJurisdiction, agreement, governingLaw, productTypeKnown, counterpartyBranchQuestionVisible, sectionTenAApplies]);

    const analysisWrapperDimensions = { height: `${screenHeight - NAV_BAR - ANALYSIS_MARGIN - HEADER_AND_BUTTONS_WITH_MARGIN}px`, width: `${screenWidth}px` };

    const pageDimensions = { height: `${pageHeight - HEADER_AND_BUTTONS_WITH_MARGIN}px`, width: `${pageWidth}px` };

    return (
        <div className={styles.summaryWrapper}>
            <div className={styles.summaryTitle}>Summary</div>
            <div className={styles.analysisWrapper} style={analysisWrapperDimensions}>
                <div className={styles.sideMenuWrapper}>
                    <AnalysisViewButtons toggleAnalysisView={toggleAnalysisView} currentView={documentSummaryView} disabled={false} />
                </div>
                <div className={styles.splitWrapper}>
                    {documentSummaryView !== AnalysisView.DATASET &&
                        <div className={styles.documentAnalysisWrapper} style={pageDimensions}>
                            <DocumentAnalysisViewer width={pageWidth} />
                        </div>
                    }
                    {documentSummaryView !== AnalysisView.DOCUMENT &&
                        <div className={styles.datasetAnalysisWrapper} style={pageDimensions}>
                            <Scrollable>
                                <div className={styles.surveyQuestionAndAnswerWrapper}>
                                    {nettingEngineSurvey.map(({ question, value, type, isMulti, dropdownOptions, closeOnSelect, key, counterpartyKey, isHidden }, index) => (
                                        !isHidden ?
                                            <div className={styles.surveySummaryWrapper} key={index}>
                                                <div className={styles.surveySummary}>
                                                    <OverflowTooltip className={styles.surveySummaryQuestion} overlayText={question} />
                                                    {showWarning(key) && <div className={styles.surveySummaryMessage}>Note: Please update this data within the dataset instance. Otherwise you will be required to select this value every time you run the netting engine.</div>}
                                                </div>
                                                <div className={styles.surveySummaryAnswer}>{summaryAnswers(value, type, key, counterpartyKey, dropdownOptions, isMulti, closeOnSelect)}</div>
                                            </div> : null
                                    ))}
                                </div>
                            </Scrollable>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.surveyButtonCalculate}>
                <Button label='Back' onClick={back} />
                <Button label='Calculate' disabled={!calculationIsValid} onClick={calculate} />
            </div>
        </div>
    );
};
