import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../../hooks/react-redux';
import { Bookshelf, PlaybookIcon } from '../../../shared/icons';
import { TileSelect, Tile } from '../../../shared/tile-select/TileSelect';
import { BuildPage, setBuildPage } from '../store';

export const BuildSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectBuildPage = useCallback((page: BuildPage) => dispatch(setBuildPage(page)), [dispatch]);
    const selectBuildFromScratch = useCallback(() => selectBuildPage(BuildPage.BUILD_FROM_SCRATCH), [selectBuildPage]);
    const selectAvailableTemplates = useCallback(() => selectBuildPage(BuildPage.TEMPLATES), [selectBuildPage]);

    const playbookTiles: Tile[] = useMemo(() => [
        {
            label: BuildPage.BUILD_FROM_SCRATCH,
            description: ['Build your own playbook from scratch'],
            onClick: selectBuildFromScratch,
            testId: 'from-scratch',
            icon: PlaybookIcon
        },
        {
            label: BuildPage.TEMPLATES,
            description: ['Use a system template to get you started'],
            onClick: selectAvailableTemplates,
            testId: 'available-templates',
            icon: Bookshelf
        },
    ], [selectBuildFromScratch, selectAvailableTemplates]);

    return (
        <TileSelect testId='playbook-build' tiles={playbookTiles} />
    );
};
