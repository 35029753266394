import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Button } from '../../shared/button/Button';
import { DataManagementWrapper } from '../data-management/DataManagementWrapper';
import styles from './SupplyChain.module.scss';
import { SupplyChainTable } from './SupplyChainTable';
import { clearSupplyChainFilters, fetchAllFunctionCompanyDetailsStarted, fetchSupplyChainConfigurationStarted, getCompanyAndFunctionSelected, getFunctionsActive, getResetFunctionCompanyButtonVisible, setSelectedCompanyId, setSelectedFunctionId } from './store';
import { InformationTooltip } from '../../shared/tooltip';

export const SupplyChain: React.FC = () => {
    const dispatch = useAppDispatch();

    const resetButtonVisible = useAppSelector(getResetFunctionCompanyButtonVisible);
    const canOpen = useAppSelector(getCompanyAndFunctionSelected);
    const reset = useCallback(() => {
        dispatch(setSelectedCompanyId(null));
        dispatch(setSelectedFunctionId(null));
        dispatch(clearSupplyChainFilters());
    }, [dispatch]);

    const configure = useCallback(() => dispatch(fetchSupplyChainConfigurationStarted()), [dispatch]);

    const filters = useAppSelector(getFunctionsActive);
    const clearAllFilters = useCallback(() => {
        dispatch(clearSupplyChainFilters());
        dispatch(fetchAllFunctionCompanyDetailsStarted());
    }, [dispatch]);

    const info = 'Please select the function and company you wish to view/edit the supply chain information for';

    useEffect(() => () => {
        reset();
    }, [reset]);

    return (
        <DataManagementWrapper>
            <div className={styles.supplyChainWrapper}>
                <div className={styles.supplyChainTitleContainer}>
                    <div className={styles.supplyChainTitle} data-testid='supply-chain'>Supply Chain</div>
                    <InformationTooltip content={info} fontSize={22} />
                    <div className={styles.tableButtonsWrapper}>
                        {filters && <Button label='Clear All Filters' disabled={false} onClick={clearAllFilters} />}
                        {resetButtonVisible && <Button label='Reset' disabled={false} onClick={reset} />}
                        <Button label='Configure' disabled={!canOpen} onClick={configure} />
                    </div>
                </div>
                <SupplyChainTable />
            </div>
        </DataManagementWrapper>
    );
};
