import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Button } from '../../shared/button/Button';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Spinner } from '../../shared/spinner/Spinner';
import styles from './RiskTolerance.module.scss';
import { fetchAllRiskToleranceStarted, fetchRiskToleranceByDatasetIdStarted, getAllRiskToleranceDatasets, getIsLoading, RiskToleranceDataset } from './store';
import { fetchAllDropdownListsStarted } from '../dropdown-lists/store';
import { fetchAllDocumentNamesStarted } from '../documents/store';

interface RiskToleranceDatasetTilesProps {
    riskToleranceDatasets: RiskToleranceDataset[];
    isLoading: boolean;
    buttonLabel: string;
    onClick: (datasetId: number) => void;
    tileWrapperWidth?: string
}

export const RiskToleranceDatasetTiles: React.FC<RiskToleranceDatasetTilesProps> = ({ riskToleranceDatasets, isLoading, buttonLabel, onClick, tileWrapperWidth = 'calc((100% / 3) - 40px)' }) => (
    <Scrollable>
        <div className={styles.tilesWrapper}>
            {isLoading ? (<Spinner />) : (riskToleranceDatasets.map(({ datasetId, documentNames, agreementType }) => (
                <div key={datasetId} className={styles.datasetTileWrapper} style={{ width: tileWrapperWidth }}>
                    <div className={styles.documentNamesWrapper}>
                        <div className={styles.agreementType}>{agreementType}</div>
                        <div className={styles.documentNamesList}>
                            <Scrollable>
                                {documentNames.map(documentName => (
                                    <div key={documentName}>- {documentName}</div>
                                ))}
                            </Scrollable>
                        </div>
                    </div >
                    <div className={styles.buttonWrapper}>
                        <Button label={buttonLabel} onClick={() => onClick(datasetId)} />
                    </div>
                </div>
            )))}
        </div>
    </Scrollable>
);

export const RiskTolerance: React.FC = () => {
    const [, screenHeight] = useWindowResize();
    const resultsHeight = useMemo(() => screenHeight - 190, [screenHeight]);
    const dispatch = useAppDispatch();
    const riskToleranceDatasets = useAppSelector(getAllRiskToleranceDatasets);
    const isLoading = useAppSelector(getIsLoading);

    useFetchStarted([fetchAllRiskToleranceStarted(), fetchAllDropdownListsStarted(), fetchAllDocumentNamesStarted()]);

    const fetchRiskTolerance = useCallback((datasetId: number) => dispatch(fetchRiskToleranceByDatasetIdStarted(datasetId)), [dispatch]);

    return (
        <div className={styles.riskToleranceWrapper} data-testid='risk-tolerance-wrapper'>
            <div className={styles.riskToleranceHeader} data-testid='risk-tolerance-header'>Risk Tolerance</div>
            <div className={styles.riskToleranceDatasetsWrapper} data-testid='risk-tolerance-datasets-wrapper' style={{ height: `${resultsHeight}px` }}>
                <RiskToleranceDatasetTiles
                    isLoading={isLoading}
                    riskToleranceDatasets={riskToleranceDatasets}
                    buttonLabel='Configure'
                    onClick={fetchRiskTolerance}
                />
            </div>
        </div>
    );
};
