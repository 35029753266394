export enum ResetPasswordActionTypes {
    RESET_PASSWORD_STARTED = 'RESET_PASSWORD_STARTED',
    RESET_PASSWORD_SUCCESSFUL = 'RESET_PASSWORD_SUCCESSFUL',
    RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED',
    CHECK_RESET_LINK_IS_VALID_STARTED = 'CHECK_RESET_LINK_IS_VALID_STARTED',
    CHECK_RESET_LINK_IS_VALID_SUCCESSFUL = 'CHECK_RESET_LINK_IS_VALID_SUCCESSFUL',
    CHECK_RESET_LINK_IS_VALID_FAILED = 'CHECK_RESET_LINK_IS_VALID_FAILED',
    UPDATE_PASSWORD_FIELD = 'UPDATE_PASSWORD_FIELD',
    RESET_STORE_VALUES = 'RESET_STORE_VALUES',
    SET_PASSWORD_SCORE = 'SET_PASSWORD_SCORE'
}

export interface ResetPasswordState {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    errorMessage: string | null;
    resettingPassword: boolean;
    passwordScore: number;
}
