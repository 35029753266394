import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import styles from '../ClauseTagsModal.module.scss';
import { Filter, Sort, SortAscending, SortDescending } from '../../../shared/icons';
import { ClauseTags, getSelectedTemplateTag, SelectedSystemTag, setSelectedTemplateTag } from '../../store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { tagCategoryOptions } from '../../../shared/clause-library/ClauseModalTags';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { IconButton } from '../../../shared/button/IconButton';
import { OverflowTooltip } from '../../../shared/tooltip';
import { SortOrder } from '../../../shared/table/ArkTable';
import { Position } from '../../../shared/modal/PositionModal';
import { TableFilterModal } from '../../../shared/modal/TableFilterModal';

const { white, lightestGrey, frenchSelected } = styles;

interface CurrentTemplateTagsProps {
    tagCategory: keyof ClauseTags;
    columnOrder: SortOrder | null;
    setColumnOrder: (order: SortOrder | null) => void;
    tagFilter: string | null;
    setTagFilter: (tag: string | null) => void;
    filteredTags: DropdownOption[];
}

export const CurrentTemplateTags: React.FC<CurrentTemplateTagsProps> = ({ tagCategory, columnOrder, setColumnOrder, tagFilter, setTagFilter, filteredTags }) => {
    const dispatch = useAppDispatch();

    const systemTagCategoryOptions = useMemo(() => tagCategoryOptions.filter(option => option.value !== 'jurisdiction'), []);

    const systemTagCategory = useMemo(() => systemTagCategoryOptions.find(({ value }) => value === tagCategory)!, [tagCategory, systemTagCategoryOptions]);

    const [systemTagSelected, setSystemTagSelected] = useState<string | null>(null);

    const selectedTag = useAppSelector(getSelectedTemplateTag);
    const selectTag = useCallback((systemTag: DropdownOption) => {
        if (systemTag.value === systemTagSelected) {
            dispatch(setSelectedTemplateTag(null));
            setSystemTagSelected(null);
        } else {
            const selected: SelectedSystemTag = { tag: systemTag.value, category: tagCategory };
            dispatch(setSelectedTemplateTag(selected));
            setSystemTagSelected(systemTag.value);
        }
    }, [dispatch, tagCategory, systemTagSelected]);

    const [filterFieldOpen, setFilterFieldOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);

    const sortColumn = useCallback(() => {
        let orderToSort = isNull(columnOrder) ? SortOrder.DESCENDING : SortOrder.ASCENDING;
        if (columnOrder) {
            orderToSort = columnOrder === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING;
        }
        setColumnOrder(orderToSort);
    }, [setColumnOrder, columnOrder]);

    const getSortIcon = useCallback(() => {
        if (!isNull(columnOrder)) {
            return columnOrder === SortOrder.ASCENDING ? SortDescending : SortAscending;
        }
        return Sort;
    }, [columnOrder]);

    const openFilterModal = (x: number, y: number) => {
        setPosition({ x, y });
        setFilterFieldOpen(true);
    };

    const closeFilterModal = () => {
        setPosition(null);
        setFilterFieldOpen(false);
    };

    const updateFilter = useCallback((field: string, value: string | string[] | null) => {
        setColumnOrder(null);
        setTagFilter(value as string);
    }, [setColumnOrder, setTagFilter]);

    const getTagBackgroundColour = useCallback((index: number, value: string) => {
        let tagBackgroundColour = index % 2 ? lightestGrey : white;
        if (!isNull(systemTagSelected) && value === systemTagSelected) {
            tagBackgroundColour = frenchSelected;
        }
        return tagBackgroundColour;
    }, [systemTagSelected]);

    useEffect(() => {
        if (isNull(selectedTag)) {
            setSystemTagSelected(null);
        }
    }, [selectedTag, setSystemTagSelected]);

    useEffect(() => () => {
        setSystemTagSelected(null);
    }, [setSystemTagSelected]);

    return (
        <>
            <Scrollable width='60%'>
                <div className={styles.currentTagsWrapper} data-testid='client-tags-modal-current-tags-wrapper'>
                    <div className={styles.tagLabelHeader} data-testid='client-tags-modal-table-header-tag-wrapper'>
                        <div className={styles.tagHeaderLabel} data-testid='client-tags-modal-table-header-tag-label'>{`${systemTagCategory.label} Tags`}</div>
                        <div className={styles.tableHeaderIcons}>
                            <IconButton icon={Filter} onClick={e => openFilterModal(e.clientX, e.clientY)} color={white} fontSize={20} margin={'0 5px 0 0'} showIndicator={!isNull(tagFilter)} />
                            <IconButton icon={getSortIcon()} onClick={() => sortColumn()} color={white} fontSize={20} />
                        </div>
                    </div>
                    {filteredTags.map((tag, index) => {
                        const tagBackgroundColour = getTagBackgroundColour(index, tag.value);
                        return (
                            <div key={index} className={styles.currentTagWrapper} style={{ backgroundColor: tagBackgroundColour }} onClick={() => selectTag(tag)} data-testid={`template-tags-modal-table-row-${index}-tag-wrapper`}>
                                <OverflowTooltip className={styles.tagLabel} overlayText={tag.label} testId={`template-tags-modal-table-row-${index}-tag-label`} />
                            </div>
                        );
                    })
                    }
                </div>
                {filterFieldOpen && !isNull(position) &&
                    <TableFilterModal
                        position={position}
                        field='tags'
                        closeModal={closeFilterModal}
                        testId={`client-tags-modal-modal-filter-${filterFieldOpen}`}
                        filters={{ ['tags']: { text: '', dropdown: null } }}
                        updateFilter={updateFilter}
                        filterDropdownOptions={{ ['tags']: [] }}
                    />
                }
            </Scrollable>
        </>
    );
};
