import { Reducer } from 'redux';
import { set, flow } from 'lodash/fp';

import { AdminAttestationActionTypes, AdminAttestationState, AttestationInstanceQuestion, AttestationManagerTab, AttestationPage, FormBuilderTab, UserAnswerId } from './types';
import { getSelectedAttestationForm, newAttestation, newAttestationInstances, newUserAnswer } from '../utils';
import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';

export const initialAttestationInstance = (userIds: UserAnswerId[], attestations: AttestationInstanceQuestion[]) => ({
    userIds,
    deadline: null,
    attestations
});

export const INITIAL_STATE: AdminAttestationState = {
    attestationPage: AttestationPage.SELECT,
    formBuilderWizardOpen: false,
    selectedFormTab: FormBuilderTab.DEFINE,
    selectedAttestationForm: null,
    attestationQuestionConfigModalOpen: null,
    attestationConfirmationModal: null,
    isSaving: false,
    attestationForms: [],
    attestationFormUpdated: false,
    isLoading: false,
    viewCompletedAttestations: false,
    isDeleting: false,
    newAttestationModalOpen: false,
    newAttestations: null,
    attestations: [],
    selectedManagerTab: AttestationManagerTab.DETAILS,
    attestationManagerWizardOpen: false,
    selectedAttestationInstance: null,
    attestationInstanceUpdated: false,
    attestationInstancesPage: 1,
    totalAttestationInstances: 0,
    attestationInstancesFilters: {},
    attestationInstancesPageSize: DEFAULT_PAGE_SIZE,
    attestationInstancesSort: undefined,
    confirmDeleteAttestationForm: null,
    confirmDeleteAttestation: null
};

export const adminAttestationReducer: Reducer<AdminAttestationState> = (state = INITIAL_STATE, { payload, type }): AdminAttestationState => {
    switch (type) {
        case AdminAttestationActionTypes.SELECT_ATTESTATION_PAGE:
            return set('attestationPage', payload, state);
        case AdminAttestationActionTypes.TOGGLE_ATTESTATION_FORM_BUILDER_WIZARD_OPEN: {
            const { isOpen, attestationForm } = payload;
            const selectedAttestationForm = getSelectedAttestationForm(isOpen, attestationForm);
            return flow(
                set('selectedAttestationForm', selectedAttestationForm),
                set('formBuilderWizardOpen', isOpen),
                set('attestationConfirmationModal', null),
                set('selectedFormTab', FormBuilderTab.DEFINE),
                set('attestationFormUpdated', false)
            )(state);
        }
        case AdminAttestationActionTypes.SET_SELECTED_ATTESTATION_FORM_TAB:
            return set('selectedFormTab', payload, state);
        case AdminAttestationActionTypes.UPDATE_ATTESTATION_FORM_VALUE: {
            const { key, value } = payload;
            return set(`selectedAttestationForm[${key}]`, value, state);
        }
        case AdminAttestationActionTypes.ADD_ATTESTATION_FORM_USER_ANSWER:
            return set('selectedAttestationForm.userAnswers', [...state.selectedAttestationForm!.userAnswers, newUserAnswer()], state);
        case AdminAttestationActionTypes.REMOVE_ATTESTATION_FORM_USER_ANSWER:
            return set('selectedAttestationForm.userAnswers', state.selectedAttestationForm!.userAnswers.filter(({ userAnswerId }) => userAnswerId !== payload), state);
        case AdminAttestationActionTypes.UPDATE_ATTESTATION_FORM_USER_ANSWER_LABEL: {
            const { label, index } = payload;
            return set(`selectedAttestationForm.userAnswers[${index}].label`, label, state);
        }
        case AdminAttestationActionTypes.ADD_ATTESTATION_QUESTION: {
            const firstUserAnswer = state.selectedAttestationForm?.userAnswers[0].userAnswerId;
            return set('selectedAttestationForm.attestations', [...state.selectedAttestationForm!.attestations, newAttestation(firstUserAnswer)], state);
        }
        case AdminAttestationActionTypes.REMOVE_ATTESTATION_QUESTION: {
            const attestations = state.selectedAttestationForm!.attestations;
            const updatedAttestations = attestations.filter((_, index) => index !== payload);
            return set('selectedAttestationForm.attestations', updatedAttestations, state);
        }
        case AdminAttestationActionTypes.UPDATE_ATTESTATION_QUESTION_VALUE: {
            const { key, value, index } = payload;
            return set(`selectedAttestationForm.attestations[${index}][${key}]`, value, state);
        }
        case AdminAttestationActionTypes.TOGGLE_ATTESTATION_QUESTION_CONFIG_MODAL_OPEN:
            return set('attestationQuestionConfigModalOpen', payload, state);
        case AdminAttestationActionTypes.UPDATE_ATTESTATION_QUESTION_CONFIG_VALUE: {
            const { key, value, index } = payload;
            return set(`selectedAttestationForm.attestations[${index}].config[${key}]`, value, state);
        }
        case AdminAttestationActionTypes.ADD_CUSTOM_ANSWER: {
            return flow(
                set(`selectedAttestationForm.attestations[${payload}].config.customAnswers`, state.selectedAttestationForm!.attestations[payload].config.customAnswers!.concat('')),
                set(`selectedAttestationForm.attestations[${payload}].config.redFlagAnswer`, null)
            )(state);
        }
        case AdminAttestationActionTypes.UPDATE_CUSTOM_ANSWER: {
            const { index, value, attestationIndex } = payload;
            return flow(
                set(`selectedAttestationForm.attestations[${attestationIndex}].config.customAnswers[${index}]`, value),
                set(`selectedAttestationForm.attestations[${attestationIndex}].config.redFlagAnswer`, null)
            )(state);
        }
        case AdminAttestationActionTypes.REMOVE_CUSTOM_ANSWER: {
            const { attestationIndex, index } = payload;
            const newCustomAnswers = state.selectedAttestationForm!.attestations[attestationIndex].config.customAnswers?.filter((_, i) => i !== index);
            return flow(
                set(`selectedAttestationForm.attestations[${attestationIndex}].config.customAnswers`, newCustomAnswers),
                set(`selectedAttestationForm.attestations[${attestationIndex}].config.redFlagAnswer`, null)
            )(state);
        }
        case AdminAttestationActionTypes.TOGGLE_ATTESTATION_FORM_BUILDER_CONFIRMATION_MODAL:
            return set('attestationConfirmationModal', payload, state);
        case AdminAttestationActionTypes.SAVE_ATTESTATION_FORM_STARTED:
            return set('isSaving', true, state);
        case AdminAttestationActionTypes.SAVE_ATTESTATION_FORM_SUCCESSFUL:
            return flow(
                set('attestationForms', payload),
                set('isSaving', false),
                set('attestationConfirmationModal', null),
                set('formBuilderWizardOpen', false),
                set('selectedFormTab', FormBuilderTab.DEFINE),
                set('attestationFormUpdated', false)
            )(state);
        case AdminAttestationActionTypes.SAVE_ATTESTATION_FORM_FAILED:
            return set('isSaving', false, state);
        case AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_FORMS_STARTED:
            return set('isLoading', true, state);
        case AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_FORMS_SUCCESSFUL:
            return flow(
                set('isLoading', false),
                set('attestationForms', payload)
            )(state);
        case AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_FORMS_FAILED:
            return set('isLoading', false, state);
        case AdminAttestationActionTypes.SET_ATTESTATION_FORM_UPDATED:
            return set('attestationFormUpdated', payload, state);
        case AdminAttestationActionTypes.DELETE_ATTESTATION_FORM_STARTED:
            return set('isDeleting', true, state);
        case AdminAttestationActionTypes.DELETE_ATTESTATION_FORM_SUCCESSFUL:
            return flow(
                set('attestationForms', payload),
                set('isDeleting', false),
                set('confirmDeleteAttestationForm', null)
            )(state);
        case AdminAttestationActionTypes.DELETE_ATTESTATION_FORM_FAILED:
            return set('isDeleting', false, state);
        case AdminAttestationActionTypes.TOGGLE_ATTESTATION_INSTANCE_VIEW:
            return flow(
                set('viewCompletedAttestations', payload.completed),
                set('attestationInstancesPage', 1),
                set('totalAttestationInstances', 0),
                set('attestationInstancesFilters', {}),
                set('attestationInstancesPageSize', DEFAULT_PAGE_SIZE),
                set('attestationInstancesSort', undefined)
            )(state);
        case AdminAttestationActionTypes.TOGGLE_NEW_ATTESTATION_MODAL_OPEN:
            return flow(
                set('newAttestationModalOpen', payload),
                set('newAttestations', payload ? newAttestationInstances : null)
            )(state);
        case AdminAttestationActionTypes.UPDATE_NEW_ATTESTATION_FORM_ID: {
            const { attestationFormId, userIds, attestations } = payload;
            return flow(
                set('newAttestations.attestationFormId', attestationFormId),
                set('newAttestations.instancesToCreate', [initialAttestationInstance(userIds, attestations)])
            )(state);
        }
        case AdminAttestationActionTypes.UPDATE_ATTESTATION_INSTANCES:
            return set('newAttestations.instancesToCreate', payload, state);
        case AdminAttestationActionTypes.CREATE_ATTESTATION_SUCCESSFUL:
            return flow(
                set('attestations', payload.attestations),
                set('totalAttestationInstances', payload.total || state.totalAttestationInstances),
                set('newAttestationModalOpen', false),
                set('newAttestations', null)
            )(state);
        case AdminAttestationActionTypes.TOGGLE_ATTESTATION_MANAGER_WIZARD_OPEN: {
            const { isOpen, attestationInstance } = payload;
            const selectedAttestationInstance = isOpen ? attestationInstance : null;
            return flow(
                set('selectedAttestationInstance', selectedAttestationInstance),
                set('attestationManagerWizardOpen', isOpen),
                set('selectedManagerTab', AttestationManagerTab.DETAILS),
                set('attestationInstanceUpdated', false)
            )(state);
        }
        case AdminAttestationActionTypes.SET_SELECTED_ATTESTATION_MANAGER_TAB:
            return set('selectedManagerTab', payload, state);
        case AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_INSTANCES_STARTED:
            return set('isLoading', true, state);
        case AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_INSTANCES_SUCCESSFUL:
            return flow(
                set('isLoading', false),
                set('attestations', payload.attestations),
                set('totalAttestationInstances', payload.total || state.totalAttestationInstances),
                set('attestationInstancesPage', payload.pageNumber || state.attestationInstancesPage)
            )(state);
        case AdminAttestationActionTypes.FETCH_ALL_ATTESTATION_INSTANCES_FAILED:
            return set('isLoading', false, state);
        case AdminAttestationActionTypes.DELETE_ATTESTATION_INSTANCE_STARTED:
            return set('isDeleting', true, state);
        case AdminAttestationActionTypes.DELETE_ATTESTATION_INSTANCE_SUCCESSFUL:
            return flow(
                set('attestations', payload.attestations),
                set('isDeleting', false),
                set('totalAttestationInstances', payload.total || state.totalAttestationInstances),
                set('confirmDeleteAttestation', null)
            )(state);
        case AdminAttestationActionTypes.DELETE_ATTESTATION_INSTANCE_FAILED:
            return set('isDeleting', false, state);
        case AdminAttestationActionTypes.UPDATE_ATTESTATION_DEADLINE:
            return set('selectedAttestationInstance.attestationInstance.deadline', payload, state);
        case AdminAttestationActionTypes.UPDATE_ATTESTATION_USER_STATUS: {
            const { index, value } = payload;
            return set(`selectedAttestationInstance.attestationStatus[${index}].status`, value, state);
        }
        case AdminAttestationActionTypes.SET_ATTESTATION_INSTANCE_UPDATED:
            return set('attestationInstanceUpdated', payload, state);
        case AdminAttestationActionTypes.SAVE_ATTESTATION_INSTANCE_STARTED:
            return set('isSaving', true, state);
        case AdminAttestationActionTypes.SAVE_ATTESTATION_INSTANCE_SUCCESSFUL:
            return flow(
                set('attestations', payload.attestations),
                set('totalAttestationInstances', payload.total || state.totalAttestationInstances),
                set('isSaving', false),
                set('attestationManagerWizardOpen', false),
                set('selectedManagerTab', AttestationManagerTab.DETAILS),
                set('attestationInstanceUpdated', false)
            )(state);
        case AdminAttestationActionTypes.SAVE_ATTESTATION_INSTANCE_FAILED:
            return set('isSaving', false, state);
        case AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`attestationInstancesFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`attestationInstancesFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case AdminAttestationActionTypes.CLEAR_ATTESTATION_INSTANCES_TABLE_FILTERS:
            return set('attestationInstancesFilters', {}, state);
        case AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_TABLE_COLUMN_SORT:
            return set('attestationInstancesSort', payload, state);
        case AdminAttestationActionTypes.SET_ATTESTATION_INSTANCES_PAGE_SIZE:
            return set('attestationInstancesPageSize', payload, state);
        case AdminAttestationActionTypes.TOGGLE_DELETE_ATTESTATION_FORM_CONFIRMATION_MODAL:
            return set('confirmDeleteAttestationForm', payload, state);
        case AdminAttestationActionTypes.TOGGLE_DELETE_ATTESTATION_CONFIRMATION_MODAL:
            return set('confirmDeleteAttestation', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
