import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import styles from './MyDatasets.module.scss';
import { MyDatasetTile } from './MyDatasetTile';
import { setMyDatasetView, MyDatasetView, MyDatasets, fetchMyDatasetDefinitionsStarted } from './store';

interface MyDatasetsListProps {
    myDatasets: MyDatasets[];
}

export const MyDatasetsList: React.FC<MyDatasetsListProps> = ({ myDatasets }) => {
    const [, screenHeight] = useWindowResize();
    const resultsHeight = useMemo(() => screenHeight - 240, [screenHeight]);
    const dispatch = useAppDispatch();

    const fetchDatasetDefinitions = useCallback((datasetId: number) => dispatch(fetchMyDatasetDefinitionsStarted(datasetId)), [dispatch]);

    const openPreview = useCallback((datasetId: number) => {
        fetchDatasetDefinitions(datasetId);
        dispatch(setMyDatasetView(MyDatasetView.PREVIEW, datasetId));
    }, [dispatch, fetchDatasetDefinitions]);

    const openConfigure = useCallback((datasetId: number) => {
        fetchDatasetDefinitions(datasetId);
        dispatch(setMyDatasetView(MyDatasetView.CONFIGURE, datasetId));
    }, [dispatch, fetchDatasetDefinitions]);

    const openRiskTolerance = useCallback((datasetId: number) => {
        fetchDatasetDefinitions(datasetId);
        dispatch(setMyDatasetView(MyDatasetView.RISK_TOLERANCE, datasetId));
    }, [dispatch, fetchDatasetDefinitions]);

    return (
        <div className={styles.myDatasetsWrapper} data-testid='my-datasets-wrapper'>
            <div className={styles.myDatasetsHeader} data-testid='my-datasets-header'>My Datasets</div>
            <div className={styles.myDatasetsResultsWrapper} data-testid='my-datasets-results-wrapper' style={{ height: `${resultsHeight}px` }}>
                <Scrollable>
                    <div className={styles.tilesWrapper}>
                        <div className={styles.tilesColumnWrapper}>
                            {myDatasets.map(({ datasetId, documentNames, agreementType }) => (
                                <MyDatasetTile
                                    key={datasetId}
                                    datasetId={datasetId}
                                    documentNames={documentNames}
                                    agreementType={agreementType}
                                    preview={() => openPreview(datasetId)}
                                    configure={() => openConfigure(datasetId)}
                                    riskTolerance={() => openRiskTolerance(datasetId)}
                                />
                            ))}
                        </div>
                    </div>
                </Scrollable>
            </div>
        </div>
    );
};

