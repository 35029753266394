import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { CollateralTakerInsolvency, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface CollateralTakerInsolvencyProps {
    collateralTakerInsolvency: CollateralTakerInsolvency;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const CollateralTakerInsolvencySection: React.FC<CollateralTakerInsolvencyProps> = ({ collateralTakerInsolvency, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'collateralTakerInsolvency';
    const { providerRightsEnforceable, providerRequirements, allOutstandingSatisfied, subjectToStayOrFreeze, cashLeakageConclusion } = collateralTakerInsolvency;

    return (
        <SectionWrapper id={sectionId} label='Collateral Taker Insolvency' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.collateralTakerInsolvencySectionWrapper}>
                <div className={styles.sectionHeaderLabel}>Under the laws of the jurisdiction under consideration:</div>
                <SingleField
                    field={providerRightsEnforceable}
                    fieldId='providerRightsEnforceable'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={providerRequirements}
                    fieldId='providerRequirements'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={allOutstandingSatisfied}
                    fieldId='allOutstandingSatisfied'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={subjectToStayOrFreeze}
                    fieldId='subjectToStayOrFreeze'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={cashLeakageConclusion}
                    fieldId='cashLeakageConclusion'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
            </div>
        </SectionWrapper>
    );
};
