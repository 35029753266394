import { AIGeneratedEntityField, CompanyEntity, CompanyEntityBranch, Entity, EntityType, GroupEntity, PersonEntity } from '../admin/entity/store';
import { AgencyAnnexField } from '../agency-annex/store';
import { DatasetFieldType } from '../datasets/store';

export const initialAddress = {
    address1: '',
    address2: '',
    city: '',
    country: '',
    postalCode: '',
};

export const initialAIField: AIGeneratedEntityField = {
    value: null,
    aiValue: null,
    aiPopulated: false,
    aiConfirmed: false
};

export const initialBranch: CompanyEntityBranch = {
    name: '',
    city: '',
    country: null,
    lei: '',
    alternativeIdentifier: ''
};

export const initialCompanyEntity = (type: EntityType.COMPANY): CompanyEntity => ({
    entityId: null,
    name: '',
    content: {
        lei: '',
        companyNumber: '',
        address: initialAddress,
        jurisdiction: '',
        otherAddresses: [],
        bic: [''],
        MiFIDClassification: initialAIField,
        emirClassification: initialAIField,
        counterpartyType: initialAIField,
        opinionCounterpartyType: initialAIField,
        opinionSubCounterpartyType: null,
        email: '',
        phoneNumber: '',
        mobileNumber: '',
        faxNumber: '',
        primaryContact: '',
        branches: [],
        dateOfRoiIntegration: '',
        dateOfRoiDeletion: '',
        competentAuthority: null,
        doraEntityHierarchy: null,
        totalAssetsValue: null,
        otherNames: []
    },
    type,
    myCompany: 0,
    doraInScope: 0,
    doraMaintainerROI: 0,
    onWatchlist: 0,
    canBeAgent: 0,
    isDoraThirdParty: 0
});

export const initialPersonEntity = (type: EntityType.PERSON): PersonEntity => ({
    entityId: null,
    name: '',
    content: {
        title: null,
        forenames: '',
        surname: '',
        address: initialAddress,
        position: '',
        company: null,
        email: '',
        phoneNumber: '',
        mobileNumber: '',
        faxNumber: '',
        otherAddresses: []
    },
    type,
    myCompany: 0,
    doraInScope: 0,
    doraMaintainerROI: 0,
    onWatchlist: 0,
    canBeAgent: 0,
    isDoraThirdParty: 0
});

export const initialGroupEntity = (type: EntityType.GROUP): GroupEntity => ({
    entityId: null,
    name: '',
    content: {
        description: ''
    },
    type,
    myCompany: 0,
    doraInScope: 0,
    doraMaintainerROI: 0,
    onWatchlist: 0,
    canBeAgent: 0,
    isDoraThirdParty: 0
});

export const getNewEntityByType = (type: EntityType): Entity | null => {
    switch (type) {
        case EntityType.COMPANY:
            return initialCompanyEntity(type);
        case EntityType.PERSON:
            return initialPersonEntity(type);
        case EntityType.GROUP:
            return initialGroupEntity(type);
        default:
            return null;
    }
};

export const getPrincipalField = (): AgencyAnnexField => ({
    id: 'annex-field-principal',
    type: DatasetFieldType.LINKFIELD,
    label: 'Principal',
    refLabel: '',
    settings: {
        isOpen: false,
        refOpen: false,
        showRef: false,
        mandatory: false,
        clauseOpen: false,
        entityType: EntityType.COMPANY,
        showClause: false,
        agencyField: false,
        isMultiSelect: false,
        systemIdOpen: false
    },
    description: '',
    linkedFields: [],
    systemId: ''
});

export const WATCHLIST_ENTITY = { label: 'My Watchlist', value: 'ENTITIES ON WATCHLIST', showDivider: true };

export const emirOptions = [
    'Financial Counterparty (“FC”)',
    'Small Financial Counterparty (“Small FC”)',
    'Non-financial Counterparty above the clearing threshold (“NFC+”)',
    'Non-financial Counterparty below the clearing threshold (“NFC-“)',
    'Third country entity that would be an FC if established in the EU',
    'Third country entity that would be a Small FC if established in the EU',
    'Third country entity that would be an NFC+ if established in the EU',
    'Third country entity that would be an NFC- if established in the EU'
];

export const opinionCounterpartyTypeOptions = [
    'Bank/Credit Institution',
    'Central Bank',
    'Corporation',
    'Hedge Fund/Proprietary Trader',
    'Insurance Company',
    'International Organisation',
    'Investment Firm/Broker Dealer',
    'Investment Fund',
    'Local Authority',
    'Partnership',
    'Pension Fund',
    'Sovereign',
    'Sovereign Wealth Fund',
    'Sovereign-Owned Entity',
    'State of a Federal Sovereign',
    'Other'
];

export const MiFIDOptions = ['Retail Client', 'Professional Client', 'Eligible Counterparty'];

export const competentAuthorities = [
    'Austrian Financial Market Authority',
    'Federal Ministry of Education, Science and Research (Austria)',
    'National Bank of Belgium',
    'Financial Services and Markets Authority (Belgium)',
    'Bulgarian National Bank',
    'Financial Supervision Commission (Bulgaria)',
    'Croation Financial Services Supervisory Agency',
    'Croation National Bank',
    'Cyprus Ministry of Finance',
    'Cyprus Securities and Exchange Commission',
    'Central Bank of Cyprus',
    'Czech National Bank',
    'Danish FSA',
    'Financial Supervision Authority (Estonia)',
    'Financial Supervisory Authority (Finland)',
    'Bank of France',
    'Prudential Resolution and Control Authority (France)',
    'Financial Markets Authority (France)',
    'Register of Assurance, Banking and Finance (ORIAS) (France)',
    'Federal Financial Supervisory Authority (German)',
    'Deutsche Bundesbank',
    'Association of German Chambers of Commerce and Industry',
    'Financial Services Commission (Gibraltar)',
    'Bank of Greece',
    'Hellenic Capital Market Commission',
    'Magyar Nemzeti Bank',
    'Financial Supervisory Authority of Iceland',
    'Central Bank of Ireland',
    'Bank of Italy',
    'Commissione Nazionale per le Societa e la Borsa (Italy)',
    'Institute for the Supervision of Insurance (Italy)',
    'Financial and Capital Market Commission (Latvia)',
    'Financial Market Authority Liechtenstein',
    'Central Bank of the Republic of Lithuania',
    'Financial Sector Supervisory Commission (Luxembourg)',
    'Malta Financial Services Authority',
    'Netherlands Bank',
    'Netherlands Authority for the Financial Markets',
    'Financial Supervisory Authority of Norway',
    'Polish Financial Supervision Authority',
    'Bank of Portugal',
    'Portuguese Securities Markets Commission',
    'Portuguese Insurance and Pension Funds Supervisory Authority',
    'National Bank of Romania',
    'Financial Supervisory Authority (Romania)',
    'Central Bank of the Slovak Republic',
    'Bank of Slovenia',
    'Securities Market Agency (Slovenia)',
    'Insurance Supervision Agency (Slovenia)',
    'Bank of Spain',
    'National Securities Market Commission (Spain)',
    'Ministry of Economic and Competition (Spain)',
    'Swedish Financial Supervisory Authority',
    'Other'
];

export const registerOfInformationEntityHierarchy = [
    'The financial entity is the ultimate parent undertaking in the consolidation',
    'The financial entity is the parent undertaking of a sub-consolidated part in the consolidation',
    'The financial entity is a subsidiary in the consolidation and is not a parent undertaking of a sub-consolidated part',
    'The financial entity is not part of a group',
    'The financial entity is a service provider to which the financial entity (or the third-party service provider acting on its behalf) is outsourcing all its operational activities'
];
