import { action } from 'typesafe-actions';

import { ColumnSort } from '../../../shared/table/ArkTable';
import { DoraThirdPartyActionTypes, DoraThirdPartyView } from './types';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { Address, CompanyEntity, CompanyEntityContent, FuzzyAutoCompletions, Lei, SearchEntityBy } from '../../../admin/entity/store/types';

export const setSelectedThirdPartyCompany = (thirdPartyCompany: CompanyEntity | null) => action(DoraThirdPartyActionTypes.SET_SELECTED_THIRD_PARTY_COMPANY, thirdPartyCompany);

export const updateThirdPartyCompanyValue = (key: keyof CompanyEntity, value: number) => action(DoraThirdPartyActionTypes.UPDATE_THIRD_PARTY_COMPANY_VALUE, { key, value });
export const updateThirdPartyCompanyContent = (key: keyof CompanyEntityContent, value: string | Address[]) => action(DoraThirdPartyActionTypes.UPDATE_THIRD_PARTY_COMPANY_CONTENT, { key, value });
export const updateThirdPartyAddressValue = (key: string, value: string | null, index?: number | undefined) => action(DoraThirdPartyActionTypes.UPDATE_THIRD_PARTY_COMPANY_ADDRESS_VALUE, { key, value, index });

export const saveThirdPartyCompanyStarted = () => action(DoraThirdPartyActionTypes.SAVE_THIRD_PARTY_COMPANY_STARTED);
export const saveThirdPartyCompanySuccessful = (thirdPartyCompanies: CompanyEntity[], total: number, pageNumber: number) => action(DoraThirdPartyActionTypes.SAVE_THIRD_PARTY_COMPANY_SUCCESSFUL, { thirdPartyCompanies, total, pageNumber });
export const saveThirdPartyCompanyFailed = (error: string) => action(DoraThirdPartyActionTypes.SAVE_THIRD_PARTY_COMPANY_FAILED, error);

export const fetchPaginatedThirdPartyCompaniesStarted = (pageNumber = 1, active = 1) => action(DoraThirdPartyActionTypes.FETCH_PAGINATED_THIRD_PARTY_COMPANIES_STARTED, { pageNumber, active });
export const fetchPaginatedThirdPartyCompaniesSuccessful = (thirdPartyCompanies: CompanyEntity[], total?: number, pageNumber?: number) => action(DoraThirdPartyActionTypes.FETCH_PAGINATED_THIRD_PARTY_COMPANIES_SUCCESSFUL, { thirdPartyCompanies, total, pageNumber });
export const fetchPaginatedThirdPartyCompaniesFailed = (error: string) => action(DoraThirdPartyActionTypes.FETCH_PAGINATED_THIRD_PARTY_COMPANIES_FAILED, error);

export const toggleThirdPartySearchModalOpen = (isOpen: boolean) => action(DoraThirdPartyActionTypes.TOGGLE_THIRD_PARTY_SEARCH_MODAL_OPEN, isOpen);

export const searchThirdPartyCompaniesStarted = (pageNumber = 1) => action(DoraThirdPartyActionTypes.SEARCH_THIRD_PARTY_COMPANIES_STARTED, pageNumber);
export const searchThirdPartyCompaniesSuccessful = (entities: Lei[], total?: number, pageNumber?: number) => action(DoraThirdPartyActionTypes.SEARCH_THIRD_PARTY_COMPANIES_SUCCESSFUL, { entities, total, pageNumber });
export const searchThirdPartyCompaniesFailed = (error: string) => action(DoraThirdPartyActionTypes.SEARCH_THIRD_PARTY_COMPANIES_FAILED, error);

export const upsertThirdPartyLeiEntity = (lei: Lei) => action(DoraThirdPartyActionTypes.UPSERT_THIRD_PARTY_LEI_ENTITY, lei);

export const setSearchEntityValue = (value: string) => action(DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANY_SEARCH_VALUE, value);
export const setSearchEntityType = (type: SearchEntityBy | null) => action(DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANY_SEARCH_TYPE, type);
export const resetEntitySearch = () => action(DoraThirdPartyActionTypes.RESET_THIRD_PARTY_COMPANY_SEARCH);

export const fetchFuzzyAutoCompletionsStarted = () => action(DoraThirdPartyActionTypes.FETCH_FUZZY_AND_AUTOCOMPLETIONS_STARTED);
export const fetchFuzzyAutoCompletionsSuccessful = (fuzzyAutoCompletions: FuzzyAutoCompletions) => action(DoraThirdPartyActionTypes.FETCH_FUZZY_AND_AUTOCOMPLETIONS_SUCCESSFUL, fuzzyAutoCompletions);
export const fetchFuzzyAutoCompletionsFailed = (error: string) => action(DoraThirdPartyActionTypes.FETCH_FUZZY_AND_AUTOCOMPLETIONS_FAILED, error);

export const changeThirdPartyView = (view: DoraThirdPartyView) => action(DoraThirdPartyActionTypes.CHANGE_THIRD_PARTY_VIEW, view);

// Table filters and pagination
export const setThirdPartyCompaniesTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_TABLE_FILTERS, { key, value, type });
export const clearThirdPartyCompaniesTableFilters = () => action(DoraThirdPartyActionTypes.CLEAR_THIRD_PARTY_COMPANIES_TABLE_FILTERS);
export const setThirdPartyCompaniesTableColumnSort = (columnSort: ColumnSort | undefined) => action(DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT, columnSort);
export const setThirdPartyCompaniesPageSize = (pageSize: number) => action(DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_PAGE_SIZE, pageSize);
export const thirdPartyCompaniesPaginationNext = () => action(DoraThirdPartyActionTypes.THIRD_PARTY_COMPANIES_PAGINATION_NEXT);
export const thirdPartyCompaniesPaginationPrevious = () => action(DoraThirdPartyActionTypes.THIRD_PARTY_COMPANIES_PAGINATION_PREVIOUS);

export const setAvailableThirdPartyCompaniesTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS, { key, value, type });
export const clearAvailableThirdPartyCompaniesTableFilters = () => action(DoraThirdPartyActionTypes.CLEAR_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS);
export const setAvailableThirdPartyCompaniesTableColumnSort = (columnSort: ColumnSort | undefined) => action(DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT, columnSort);
export const setAvailableThirdPartyCompaniesPageSize = (pageSize: number) => action(DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_PAGE_SIZE, pageSize);
export const availableThirdPartyCompaniesPaginationNext = () => action(DoraThirdPartyActionTypes.AVAILABLE_THIRD_PARTY_COMPANIES_PAGINATION_NEXT);
export const availableThirdPartyCompaniesPaginationPrevious = () => action(DoraThirdPartyActionTypes.AVAILABLE_THIRD_PARTY_COMPANIES_PAGINATION_PREVIOUS);

export const setThirdPatyCompanySearchPageSize = (pageSize: number) => action(DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_SEARCH_PAGE_SIZE, pageSize);
export const thirdPartyCompanySearchPaginationNext = () => action(DoraThirdPartyActionTypes.THIRD_PARTY_COMPANIES_SEARCH_PAGINATION_NEXT);
export const thirdPartyCompanySearchPaginationPrevious = () => action(DoraThirdPartyActionTypes.THIRD_PARTY_COMPANIES_SEARCH_PAGINATION_PREVIOUS);
