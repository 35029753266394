import { isUndefined } from 'lodash/fp';

import { worldCountriesList } from '../../../constants/worldCountriesList';
import worldGeometryJson from '../../../constants/worldGeometry.json';
import { Country } from '../../../documents/analytics/store';
import { countriesRequiringFiltering } from './types';

const worldGeometry = worldGeometryJson as Country[];

const filteredAndMappedCoordinates = (coordinates: number[][][][], coordinateIndex: number, isGreater: boolean, value: number) => coordinates.map(array => {
    return array.map(array1 => {
        return array1.filter(coordinate => isGreater ? coordinate[coordinateIndex] > value : coordinate[coordinateIndex] < value).filter(array => array.length > 0);
    }).filter(array => array.length > 0);
}).filter(array => array.length > 0);

const getOverseasTerritoryParentGeoId = (geoId: string) => {
    if (geoId === 'BES') {
        return 'NLD';
    }
    if (geoId === 'BVT') {
        return 'NOR';
    }
    if (geoId === 'GUF') {
        return 'FRA';
    }
    if (geoId === 'GLP') {
        return 'FRA';
    }
    if (geoId === 'MTQ') {
        return 'FRA';
    }
    if (geoId === 'REU') {
        return 'FRA';
    }
    if (geoId === 'MYT') {
        return 'FRA';
    }
    if (geoId === 'TKL') {
        return 'NZL';
    }
    if (geoId === 'SJM') {
        return 'NOR';
    }
    return '';
};

const filterEdgeCaseCoordinates = (coordinates: number[][][][], geoId: string, jurisdiction: string) => {
    let filteredCoordinates: number[][][][] = coordinates;
    if (geoId === 'GBR' && jurisdiction === 'England & Wales') {
        const northEastLongitude = 55.4;
        const northWestLongitude = 54.63;
        const westernLatitude = -3.4;
        const eastNorthernIrelandLatitude = -5;
        const southNorthernIrelandLongitude = 54;
        // First filter via the north eastern longitude as this is the most northerly point
        const northFilteredCoordinates = filteredAndMappedCoordinates(coordinates, 1, false, northEastLongitude);
        // As the lower west points of Scotland (eg. Drummore, Whithorn) are more southerly than the westerly point of northern England (eg. Carlisle),
        // we have to also account for the western most latitude of the north west of england (eg. Workington, Maryport) and also filter anything west of this to avoid a small
        // portion of Scotland being included
        const northWestFilteredCoordinates = northFilteredCoordinates.map(array => {
            return array.map(array1 => {
                return array1.filter(coordinate => coordinate[1] > northWestLongitude ? coordinate[0] > westernLatitude : coordinate).filter(array => array.length > 0);
            }).filter(array => array.length > 0);
        }).filter(array => array.length > 0);
        // We then have to filter out Northern Ireland. We first use the eastern most point of norther ireland and filter anything west of it. Though we have to
        // take into account its souther longitude as places like Cornwall are further west. So we only apply this filter if it is more northern that the most
        // southern part of Northern Ireland
        filteredCoordinates = northWestFilteredCoordinates.map(array => {
            return array.map(array1 => {
                return array1.filter(coordinate => coordinate[0] < eastNorthernIrelandLatitude ? coordinate[1] < southNorthernIrelandLongitude : coordinate).filter(array => array.length > 0);
            }).filter(array => array.length > 0);
        }).filter(array => array.length > 0);
    }
    if (geoId === 'GBR' && jurisdiction === 'Scotland') {
        const easternSouthernLongitude = 55.4;
        const westernSouthernLongitude = 54.63;
        const northernLongitude = 59.5;
        const westernLatitude = -8;
        // First filter by the south west coordinates as this is the most southerly point
        const southWesternFilteredCoordinates = coordinates.map(array => {
            return array.map(array1 => {
                return array1.filter(coordinate => coordinate[1] > westernSouthernLongitude).filter(array => array.length > 0);
            }).filter(array => array.length > 0);
        }).filter(array => array.length > 0);
        // // To create the diagonal for the border, if the latitude is further west than the east coast of England check if the longitude is more
        // southerly than middle point of the border (in terms of longitude ie 55.4) and filter those out which skews border into a diagonal from
        // south west to the north east of the border with England
        const southEasternFilteredCoordinates = southWesternFilteredCoordinates.map(array => {
            return array.map(array1 => {
                return array1.filter(coordinate => coordinate[0] > -2 ? coordinate[1] > easternSouthernLongitude : coordinate).filter(array => array.length > 0);
            }).filter(array => array.length > 0);
        }).filter(array => array.length > 0);
        // Filter out the Shetlands
        const northernFilteredCoordinates = filteredAndMappedCoordinates(southEasternFilteredCoordinates, 1, false, northernLongitude);
        // Filter the most westerly island of the Hebrides
        const filteredLatitudeWest = filteredAndMappedCoordinates(northernFilteredCoordinates, 0, true, westernLatitude);
        // Filter out Norther Ireland
        filteredCoordinates = filteredLatitudeWest.map(array => {
            return array.map(array1 => {
                return array1.filter(coordinate => coordinate[1] > 54.5 && coordinate[1] < 55.3 ? coordinate[0] > -5.5 : coordinate).filter(array => array.length > 0);
            }).filter(array => array.length > 0);
        }).filter(array => array.length > 0);
    }
    if (geoId === 'GBR' && jurisdiction === 'Northern Ireland') {
        // Filter anything to the north
        filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 1, false, 55.25);
        // Filter anything to the south
        filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 1, true, 53.9);
        // Filter anything to the east
        filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 0, false, -5.4);
    }
    // Cocos Island
    if (geoId === '-99-IOT' && jurisdiction === 'Cocos (Keeling) Islands (the)') {
        // Filter anything to the east
        filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 0, false, 97);
    }
    // Christmas Island
    if (geoId === '-99-IOT' && jurisdiction === 'Christmas Island') {
        // Filter anything to the west
        filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 0, true, 105);
    }
    // Bonaire, Sint Eustatius and Saba
    if (geoId === 'BES') {
        // These islands are part of the Netherlands, so we first filter out the european coordinates
        filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 1, false, 18);
        // As there are 3 small islands quite far apart, we update Bonaire's coordinates to be closer to Saba and Sint Eustatius for display purposes
        filteredCoordinates = filteredCoordinates.map((coordinatesSet, index) => {
            if (index === 0) {
                return coordinatesSet.map(coordinates => coordinates.map((coords) => ([coords[0] + 4, coords[1] + 5])));
            }
            return coordinatesSet;
        });
    }
    return filteredCoordinates;
};

export const countryGeometry = (jurisdiction: string) => {
    // First grab the geoId for the country
    let opinionJurisdiction = jurisdiction;
    if (jurisdiction === 'Philippines') {
        opinionJurisdiction = `${jurisdiction} (the)`;
    }
    let geoId = worldCountriesList.find(({ name }) => name.toLowerCase() === opinionJurisdiction.toLowerCase())?.geoId;

    // TODO: Add 'Northern Ireland' to this array when the opinion jurisdiction has been included
    if (['England & Wales', 'Scotland'].includes(jurisdiction)) {
        geoId = 'GBR';
    }
    // Both these islands within our world geometry do not belong to an ISO code so just have -99. -IOT has been added to differentiate (Indian Ocean Territory)
    // and allow us to use the two islands
    if (['Christmas Island', 'Cocos (Keeling) Islands (the)'].includes(jurisdiction)) {
        geoId = '-99-IOT';
    }
    // attempt to extract the polygon composition of the map by the country name
    let locatedCountry = worldGeometry.find(({ id }) => id === geoId);

    // The following geoIds belong to overseas territories so we need to get the geometry of the parent country
    if (!isUndefined(geoId) && ['BES', 'BVT', 'GUF', 'GLP', 'MTQ', 'REU', 'MYT', 'TKL', 'SJM'].includes(geoId)) {
        const parentGeoId = getOverseasTerritoryParentGeoId(geoId);
        locatedCountry = worldGeometry.find(({ id }) => id === parentGeoId);
    }

    if (!isUndefined(locatedCountry)) {
        /* The following countries have complicated geo coordinates, due to overseas territories or oddly shaped outlines
        such as USA with Alaska and Hawaii and prove problematic to display nicely. Therefore we trim the extremities to only
        show the main part of the country. This is done by using either the longitude or latitude of the main body of the country
        and filtering coordinates outside of it, some countries need this applying to multiple directions. Also to clarify the numbers
        longitudes in the Northern hemisphere are positive, Southern negative. Eastern latitudes are positive, Western negative */
        const coordinates = locatedCountry?.geometry.coordinates as number[][][][];
        let filteredCoordinates: number[][][][] = coordinates;

        // These countries require bespoke logic to show correctly
        if (!isUndefined(geoId) && ['GBR', '-99-IOT', 'BES'].includes(geoId)) {
            filteredCoordinates = filterEdgeCaseCoordinates(coordinates, geoId, jurisdiction);
        }

        const filterDetails = countriesRequiringFiltering.find(({ countryGeoId }) => countryGeoId === geoId);

        // These countries need to be filtered on some or all of their cardinal directions but do not require any further logic
        if (filterDetails) {
            const { northernLongitude, southernLongitude, easternLatitude, westernLatitude } = filterDetails;
            if (northernLongitude) {
                filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 1, false, northernLongitude);
            }
            if (southernLongitude) {
                filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 1, true, southernLongitude);
            }
            if (easternLatitude) {
                filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 0, false, easternLatitude);
            }
            if (westernLatitude) {
                filteredCoordinates = filteredAndMappedCoordinates(filteredCoordinates, 0, true, westernLatitude);
            }
        }

        locatedCountry = { ...locatedCountry, geometry: { ...locatedCountry.geometry, coordinates: filteredCoordinates } };
    }
    return locatedCountry;
};
