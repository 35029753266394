import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Button } from '../../shared/button/Button';
import { fetchFunctionConfigStarted } from '../functions/store';
import styles from './SupplyChain.module.scss';
import { SupplyChainDragDrop } from './configure/SupplyChainDragDrop';
import { fetchAllBaseCompaniesStarted, getCurrentSupplyChain, getIsSaving, getSupplyChainUpdated, navigateBackToFunctionCompanySelect, openDoraSupplyChainFromParams, setDroppableHeight, toggleSupplyChainDetailsModalOpen, upsertSupplyChainConfigurationStarted, setSupplyChainHasUpdated } from './store';
import { SupplyChain } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { CompanyFunctionDetails } from './details/CompanyFunctionDetails';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { FeaturePermission } from '../../admin/users/store';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { UnsavedChangesModal } from '../../shared/modal/UnsavedChangesModal';

export const DROPPABLE_OFFSET = 306;

export interface SupplyChainConfigureRouteParams {
    doraFunctionCompanyId: string | undefined;
}

export const SupplyChainConfigure: React.FC<RouteComponentProps<SupplyChainConfigureRouteParams>> = ({ match: { params } }) => {
    const dispatch = useAppDispatch();
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [, screenHeight] = useWindowResize();
    const supplyChain = useAppSelector(getCurrentSupplyChain);
    const supplyChainHasUpdated = useAppSelector(getSupplyChainUpdated);
    const isSaving = useAppSelector(getIsSaving);
    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));
    const saveDisabled = useMemo(() => !supplyChainHasUpdated || isSaving, [isSaving, supplyChainHasUpdated]);
    const screenHeightDroppableHeight = useMemo(() => screenHeight - DROPPABLE_OFFSET, [screenHeight]);

    const backToFunctionCompanySelect = useCallback(() => {
        dispatch(navigateBackToFunctionCompanySelect());
        dispatch(setDroppableHeight(screenHeightDroppableHeight));
        setShowConfirmationModal(false);
    }, [dispatch, setShowConfirmationModal, screenHeightDroppableHeight]);

    const goBack = useCallback(() => !saveDisabled ? setShowConfirmationModal(true) : backToFunctionCompanySelect(), [saveDisabled, setShowConfirmationModal, backToFunctionCompanySelect]);

    const saveSupplyChain = useCallback(() => dispatch(upsertSupplyChainConfigurationStarted()), [dispatch]);
    const createNewSupplyChainElement = useCallback(() => dispatch(toggleSupplyChainDetailsModalOpen(true)), [dispatch]);

    useFetchStarted([fetchAllBaseCompaniesStarted()]);
    useFetchStarted([fetchFunctionConfigStarted()]);

    useEffect(() => {
        if (isNull(supplyChain) && !isUndefined(params.doraFunctionCompanyId)) {
            dispatch(openDoraSupplyChainFromParams(parseInt(params.doraFunctionCompanyId)));
        }
    }, [dispatch, supplyChain, params.doraFunctionCompanyId]);

    useEffect(() => () => {
        dispatch(setDroppableHeight(screenHeightDroppableHeight));
        dispatch(setSupplyChainHasUpdated(false));
    }, [dispatch, screenHeightDroppableHeight]);

    return (
        <div className={styles.supplyChainConfigureWrapper}>
            <ModalHeader label='Configure Supply Chain' icon={SupplyChain} />
            <CompanyFunctionDetails />
            <SupplyChainDragDrop />
            <div className={styles.buttonWrapper}>
                <div className={styles.leftButtonWrapper}>
                    <UnsavedChangesModal
                        isOpen={showConfirmationModal}
                        closeModal={() => setShowConfirmationModal(false)}
                        confirm={backToFunctionCompanySelect}
                    />
                    <Button label='Back' onClick={goBack} />
                    <div className={styles.legendWrapper}>
                        <div className={styles.legendLabel}>Key:</div>
                        <div className={styles.indicatorWrapper}>
                            <div className={styles.intraGroupIndicator} />
                            <div className={styles.indicatorLabel}>Intra Group</div>
                        </div>
                        <div className={styles.indicatorWrapper}>
                            <div className={styles.thirdPartyIndicator} />
                            <div className={styles.indicatorLabel}>Third Party</div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightButtonWrapper}>
                    {hasDoraFullAccessPermission && <Button onClick={createNewSupplyChainElement} label='Add Company' />}
                    {hasDoraFullAccessPermission && <Button onClick={saveSupplyChain} label='Save' disabled={saveDisabled} />}
                </div>
            </div>
        </div>
    );
};
