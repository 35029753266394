import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useMemo } from 'react';

import { formatDate } from '../../../../utils/luxon';
import { TimelineOpinionInstance } from '../store';
import styles from './HistoricalInstances.module.scss';
import { OverflowTooltip } from '../../../shared/tooltip';

interface ContentProps {
    description?: string;
    type?: string;
    username?: string;
    dateOfOpinion: string;
    modifiedDate: string;
    width: number;
}

interface InstanceTooltipProps {
    timelineInstance: TimelineOpinionInstance | null;
    children: JSX.Element;
    width?: number;
}

const { french, white } = styles;

const Content: React.FC<ContentProps> = ({ description = 'No document Added', username = 'Unknown', dateOfOpinion, modifiedDate, width }) => {
    const maxWidth = `${width - 20}px`;
    return (
        <div style={{ maxWidth }} className={styles.overlayWrapper}>
            <div className={styles.contentDescription}><OverflowTooltip overlayText={description} /></div>
            <div className={styles.contentDateOfOpinion}>Date of Opinion: {dateOfOpinion}</div>
            <div className={styles.contentModifiedDate}>Last Modified: {modifiedDate}</div>
            <div className={styles.contentUsername}>Last Modified By: {username}</div>
        </div>
    );
};

export const InstanceTooltip: React.FC<InstanceTooltipProps> = ({ timelineInstance, children, width = 300 }) => {
    const tooltipStyles = {
        backgroundColor: white,
        color: french,
        borderColor: french,
        borderRadius: '5px',
        minHeight: '10px',
        display: 'flex',
        padding: '8px'
    };

    const overlayStyle = {
        maxWidth: `${width}px`
    };

    const instance = useMemo(() => {
        if (timelineInstance) {
            const { username, dateOfOpinion, createdDate, description } = timelineInstance;
            return { username, dateOfOpinion, modifiedDate: formatDate(createdDate), description };
        }
        return { username: 'Unknown', dateOfOpinion: 'Unknown', modifiedDate: 'Unknown', description: 'Unknown' };
    }, [timelineInstance]);

    const { username, dateOfOpinion, modifiedDate, description } = instance;

    if (!timelineInstance) {
        return children;
    }

    return (
        <Tooltip
            placement='bottom'
            overlay={<Content
                description={description}
                username={username}
                dateOfOpinion={dateOfOpinion}
                modifiedDate={modifiedDate}
                width={width}
            />}
            trigger='hover'
            overlayInnerStyle={tooltipStyles}
            overlayStyle={overlayStyle}
        >
            {children}
        </Tooltip>
    );
};
