import { isNull, last, noop } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { AgreementType, fetchAvailableDocumentNamesStarted, getAvailableDocumentNames } from '../../../admin/documents/store';
import { SearchEntityModal } from '../../../admin/entity/SearchEntityModal';
import { EntityModalType, EntityType, fetchAllEntitiesStarted, getAllEntities, getModalOpen, searchEntitiesStarted, toggleModal } from '../../../admin/entity/store';
import { fetchAgencyFieldsStarted, fetchAllAnnexDefinitionsStarted, getAllAnnexDefinitions, getAnnexDefinitionModalOpen, toggleAnnexDefinitionModal } from '../../../agency-annex/store';
import { getUserHasFeaturePermission } from '../../../auth/login/store';
import { IconButton } from '../../../shared/button/IconButton';
import { DatePicker } from '../../../shared/datepicker/DatePicker';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Text } from '../../../shared/text/Text';
import { ArrowRight, Delete, Search } from '../../../shared/icons';
import {
    ArkDocument,
    DocumentType,
    fetchDocumentsStarted,
    fetchLinkedDocumentsStarted,
    getBaseDocuments,
    getBaseDocumentsModalOpen,
    getIncompleteMLData,
    getAgreementTypeDocuments,
    getIsdaDocumentsModalOpen,
    getLinkedDocuments,
    getOriginalDocuments,
    getOriginalDocumentsModalOpen,
    incompleteDocumentUpdateValue,
    revertIncompleteDocument,
    toggleBaseDocumentsModal,
    toggleIsdaDocumentsModal,
    toggleOriginalDocumentsModal,
    setIncompleteDocumentSchedulePage,
    getIncompleteDocumentPageTotal,
    getScheduleStartPagePlaceholder,
    getScheduleEndPagePlaceholder,
    setScheduleEndPlaceholder
} from '../store';
import { DocumentNameDropdown, DocumentNameDropdownOption } from './DocumentNameDropdown';
import styles from './UploadDocument.module.scss';
import { CustomTooltip, InformationTooltip, OverflowTooltip } from '../../../shared/tooltip';
import { AnnexDefinitionModal } from '../../../agency-annex/AnnexDefinitionModal';
import { DATABASE_DATE_FORMAT, formatDate, futureDate } from '../../../../utils/luxon';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { DocumentLinkButton } from '../../../shared/button/DocumentLinkButton';
import { Toggle } from '../../../shared/toggle';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { ISDADocumentsModal } from '../ISDADocumentsModal';
import { OriginalDocumentsModal } from '../OriginalDocumentsModal';
import { BaseDocumentsModal } from '../BaseDocumentsModal';
import { DeleteButton } from '../../../shared/button/DeleteButton';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Icon } from '../../../shared/icon/Icon';
import { FeaturePermission } from '../../../admin/users/store';
import { isLinkAgreementType } from '../../../admin/documents/store/typeAssertions';

const { lightGrey, white, amber, primary } = styles;

interface DocumentDetailsProps {
    arkDocument: ArkDocument;
}

export const DocumentDetails: React.FC<DocumentDetailsProps> = ({ arkDocument }) => {
    const dispatch = useAppDispatch();
    const { documentDescription, executedDate, datedAsOf, documentNameId, entityA, entityB, linkedDocumentId, isAgency, annexDefinitionId, documentType, originalDocumentId, amendmentLinkedDocumentIds, agentEntitySelected, baseDocuments } = arkDocument;

    const isOriginalOption = documentType === DocumentType.ORIGINAL;
    const isReplicationDocument = documentType === DocumentType.REPLICATION;
    const isSecondaryOption = !!documentType && [DocumentType.AMENDMENT, DocumentType.RESTATEMENT].includes(documentType);
    const isSupportDocument = documentType === DocumentType.SUPPORT;
    const isPreExecutionDocument = documentType === DocumentType.PRE_EXECUTION_DRAFT;
    const typeSelected = !isNull(documentType);
    const allAnnexes = useAppSelector(getAllAnnexDefinitions);
    const isdaDocuments = useAppSelector(getAgreementTypeDocuments);
    const originalDocuments = useAppSelector(getOriginalDocuments);
    const allBaseDocuments = useAppSelector(getBaseDocuments);
    const mlData = useAppSelector(getIncompleteMLData);
    const numPages = useAppSelector(getIncompleteDocumentPageTotal);
    const scheduleStartPagePlaceholder = useAppSelector(getScheduleStartPagePlaceholder);
    const scheduleEndPagePlaceholder = useAppSelector(getScheduleEndPagePlaceholder);
    const hasCompleteDocumentPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.COMPLETE_DOCUMENT_DETAILS]));

    const scrollToPage = useCallback((pageNumber: number | null) => dispatch(setIncompleteDocumentSchedulePage(pageNumber)), [dispatch]);
    const updateScheduleStartPage = useCallback((value: string) => {
        if (!isNaN(parseInt(value)) || value === '') {
            dispatch(incompleteDocumentUpdateValue('scheduleStartPage', value === '' ? null : parseInt(value)));
            if (value === '') {
                dispatch(setIncompleteDocumentSchedulePage(null));
            }
        }
    }, [dispatch]);

    const scheduleStartPage = useMemo(() => arkDocument.scheduleStartPage || scheduleStartPagePlaceholder || 1, [arkDocument.scheduleStartPage, scheduleStartPagePlaceholder]);
    const linkToScheduleStartPageDisabled = useMemo(() => !isNull(numPages) && scheduleStartPage > numPages, [scheduleStartPage, numPages]);

    const updateScheduleEndPage = useCallback((value: string) => {
        if (!isNaN(parseInt(value)) || value === '') {
            dispatch(incompleteDocumentUpdateValue('scheduleEndPage', value === '' ? null : parseInt(value)));
            if (value === '') {
                dispatch(setIncompleteDocumentSchedulePage(null));
            }
        }
    }, [dispatch]);

    const scheduleEndPage = useMemo(() => arkDocument.scheduleEndPage || scheduleEndPagePlaceholder || numPages, [arkDocument.scheduleEndPage, numPages, scheduleEndPagePlaceholder]);
    const linkToScheduleEndPageDisabled = useMemo(() => isNull(scheduleEndPage) && (!isNull(numPages) && !isNull(scheduleEndPage) && scheduleEndPage > numPages), [scheduleEndPage, numPages]);

    const agencyAnnexStartPage = useMemo(() => arkDocument.agencyAnnexStartPage || null, [arkDocument.agencyAnnexStartPage]);
    const agencyAnnexEndPage = useMemo(() => arkDocument.agencyAnnexEndPage || null, [arkDocument.agencyAnnexEndPage]);

    const updateAgencyAnnexStartPage = useCallback((value: string) => {
        if (!isNaN(parseInt(value)) || value === '') {
            dispatch(incompleteDocumentUpdateValue('agencyAnnexStartPage', value === '' ? null : parseInt(value)));
            if (value === '') {
                dispatch(setIncompleteDocumentSchedulePage(null));
            }
        }
    }, [dispatch]);

    const updateAgencyAnnexEndPage = useCallback((value: string) => {
        if (!isNaN(parseInt(value)) || value === '') {
            dispatch(incompleteDocumentUpdateValue('agencyAnnexEndPage', value === '' ? null : parseInt(value)));
            if (value === '') {
                dispatch(setIncompleteDocumentSchedulePage(null));
            }
        }
    }, [dispatch]);

    const linkToAgencyAnnexStartPageDisabled = useMemo(() => isNull(agencyAnnexStartPage) || (!isNull(numPages) && !isNull(agencyAnnexStartPage) && agencyAnnexStartPage > numPages), [agencyAnnexStartPage, numPages]);
    const linkToAgencyAnnexEndPageDisabled = useMemo(() => isNull(agencyAnnexEndPage) || (!isNull(numPages) && !isNull(agencyAnnexEndPage) && agencyAnnexEndPage > numPages), [agencyAnnexEndPage, numPages]);

    useEffect(() => {
        if (isNull(arkDocument.scheduleEndPage) && isNull(scheduleEndPagePlaceholder) && !isNull(scheduleEndPage)) {
            dispatch(setScheduleEndPlaceholder(scheduleEndPage));
        }
    }, [arkDocument.scheduleEndPage, scheduleEndPage, scheduleEndPagePlaceholder, dispatch]);

    const setDocumentDescription = (description: string) => dispatch(incompleteDocumentUpdateValue('documentDescription', description));

    const updateDropdownValue = useCallback((key: keyof ArkDocument, dropdownValue: DropdownOption | Options<DropdownOption> | null, asNumber = false) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = asNumber ? parseInt((dropdownValue as DropdownOption).value) : (dropdownValue as DropdownOption).value;
        }
        dispatch(incompleteDocumentUpdateValue(key, value));
    }, [dispatch]);

    const setDocumentType = useCallback((value: DropdownOption | Options<DropdownOption> | null) => {
        if (isNull(mlData)) {
            dispatch(revertIncompleteDocument());
        }
        updateDropdownValue('documentType', value);
        dispatch(incompleteDocumentUpdateValue('originalDocumentId', null));
    }, [dispatch, updateDropdownValue, mlData]);

    const setLinkedEntityA = (value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('entityA', value, true);
    const setLinkedEntityB = (value: DropdownOption | Options<DropdownOption> | null) => updateDropdownValue('entityB', value, true);
    const setDocumentNameId = (dropdownValue: DocumentNameDropdownOption | Options<DocumentNameDropdownOption> | null) => {
        let value = null;
        if (!isNull(dropdownValue)) {
            value = parseInt((dropdownValue as DocumentNameDropdownOption).documentNameId);
        }
        if (isOriginalOption) {
            dispatch(incompleteDocumentUpdateValue('linkedDocumentId', null));
            dispatch(incompleteDocumentUpdateValue('annexDefinitionId', undefined));
            dispatch(incompleteDocumentUpdateValue('isAgency', 0));
        }
        dispatch(incompleteDocumentUpdateValue('documentNameId', value));
    };

    const dateValue = useMemo(() => executedDate ? new Date(executedDate) : null, [executedDate]);
    const datedAsOfValue = useMemo(() => datedAsOf ? new Date(datedAsOf) : null, [datedAsOf]);

    const setExecutedDate = useCallback((value: Date | string | null) => {
        const executedDate = value ? formatDate(value, DATABASE_DATE_FORMAT) : null;
        dispatch(incompleteDocumentUpdateValue('executedDate', executedDate));
    }, [dispatch]);

    const setDatedAsOf = useCallback((value: Date | string | null) => {
        const datedAsOf = value ? formatDate(value, DATABASE_DATE_FORMAT) : null;
        dispatch(incompleteDocumentUpdateValue('datedAsOf', datedAsOf));
    }, [dispatch]);

    const setLinkedDocumentId = (value: number | null) => dispatch(incompleteDocumentUpdateValue('linkedDocumentId', value));
    const setOriginalDocumentId = (value: number | null) => dispatch(incompleteDocumentUpdateValue('originalDocumentId', value));

    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const originalDocumentDatasetId = useMemo(() => {
        if (originalDocumentId && originalDocuments.length) {
            const originalDocument = originalDocuments.find(({ documentId }) => documentId === originalDocumentId);
            if (originalDocument) {
                return allDocumentNames.find(({ documentNameId }) => documentNameId === originalDocument.documentNameId)!.datasetId!;
            }
        }
        return null;
    }, [originalDocumentId, originalDocuments, allDocumentNames]);

    const documentNameOptions = useMemo(() => allDocumentNames
        .filter(({ datasetId }) => datasetId)
        .filter(({ datasetId }) => !originalDocumentDatasetId || originalDocumentDatasetId === datasetId)
        .map(({ documentName, documentNameId, agreementType, agreementTypeId, agencyDatasetId }) => ({ documentNameId: documentNameId!.toString(), agreementType, agreementTypeId, label: documentName, agencyDatasetId })), [allDocumentNames, originalDocumentDatasetId]
    );
    const uploadDocumentName = useMemo(() => documentNameId && documentNameOptions.find(documentName => documentName.documentNameId === documentNameId!.toString()) || null, [documentNameOptions, documentNameId]);

    const openIsdaDocumentsModal = () => dispatch(toggleIsdaDocumentsModal(true));
    const closeIsdaDocumentsModal = () => dispatch(toggleIsdaDocumentsModal(false));
    const isdaDocumentsModalOpen = useAppSelector(getIsdaDocumentsModalOpen);
    const canLinkToIsda = !isNull(uploadDocumentName) && !isNull(uploadDocumentName.agreementTypeId) && isLinkAgreementType(uploadDocumentName.agreementTypeId);

    useFetchStarted([
        fetchAvailableDocumentNamesStarted(),
        fetchAllEntitiesStarted(),
        fetchDocumentsStarted(1, AgreementType.ISDA),
        fetchDocumentsStarted(),
        fetchDocumentsStarted(1, undefined, true)
    ]);

    const fetchAnnexDefinitions = useCallback(() => {
        if (uploadDocumentName) {
            dispatch(fetchAllAnnexDefinitionsStarted(uploadDocumentName.documentNameId.toString()));
        }
    }, [dispatch, uploadDocumentName]);

    const fetchAgencyFields = useCallback(() => {
        if (uploadDocumentName && uploadDocumentName.agencyDatasetId) {
            dispatch(fetchAgencyFieldsStarted(uploadDocumentName.agencyDatasetId));
        }
    }, [dispatch, uploadDocumentName]);

    const linkedDocumentDescription = useMemo(() => linkedDocumentId ? isdaDocuments.find(({ documentId }) => documentId === linkedDocumentId)!.documentDescription : 'View Documents', [linkedDocumentId, isdaDocuments]);

    const linkedDocuments = useAppSelector(getLinkedDocuments);

    const minDate = useMemo(() => {
        if (linkedDocumentId) {
            const matchedDocumentDate = isdaDocuments.find(({ documentId }) => documentId === linkedDocumentId)?.executedDate;
            return matchedDocumentDate ? new Date(matchedDocumentDate) : undefined;
        }
        if (amendmentLinkedDocumentIds) {
            const linkedDocumentsExecutionDates = linkedDocuments!.filter(({ documentId }) => amendmentLinkedDocumentIds!.includes(documentId)).map(({ executedDate }) => executedDate);
            const latest = last(linkedDocumentsExecutionDates.sort((a, b) => Date.parse(a) - Date.parse(b)))!;
            return new Date(futureDate({ days: 1 }, latest));
        }
        if (originalDocumentId) {
            if (isSupportDocument) {
                const matchedDocumentDate = allBaseDocuments.find(({ documentId }) => documentId === originalDocumentId)?.executedDate;
                return matchedDocumentDate ? new Date(matchedDocumentDate) : undefined;
            } else {
                const matchedDocumentDate = originalDocuments.find(({ documentId }) => documentId === originalDocumentId)?.executedDate;
                return matchedDocumentDate ? new Date(matchedDocumentDate) : undefined;
            }
        }
    }, [linkedDocumentId, isdaDocuments, originalDocumentId, allBaseDocuments, isSupportDocument, originalDocuments, amendmentLinkedDocumentIds, linkedDocuments]);

    const minDatedAsOf = useMemo(() => {
        if (linkedDocumentId) {
            const matchedDocumentDate = isdaDocuments.find(({ documentId }) => documentId === linkedDocumentId)?.datedAsOf;
            return matchedDocumentDate ? new Date(matchedDocumentDate) : undefined;
        }
        if (originalDocumentId) {
            if (isSupportDocument) {
                const matchedDocumentDate = allBaseDocuments.find(({ documentId }) => documentId === originalDocumentId)?.datedAsOf;
                return matchedDocumentDate ? new Date(matchedDocumentDate) : undefined;
            } else {
                const matchedDocumentDate = originalDocuments.find(({ documentId }) => documentId === originalDocumentId)?.datedAsOf;
                return matchedDocumentDate ? new Date(matchedDocumentDate) : undefined;
            }
        }
    }, [linkedDocumentId, isdaDocuments, originalDocumentId, allBaseDocuments, isSupportDocument, originalDocuments]);

    const documentTypeOptions = Object.values(DocumentType).map(type => ({ value: type, label: capitaliseStartLetter(type) }));
    const documentTypeValue = useMemo(() => {
        if (!documentType) {
            return null;
        }
        return documentTypeOptions.find(({ value }) => value === documentType) || null;
    }, [documentType, documentTypeOptions]);

    const allEntities = useAppSelector(getAllEntities);
    const agencyEntities = useMemo(() => allEntities.filter(({ canBeAgent }) => canBeAgent).map(({ name, entityId }) => ({ value: entityId!.toString(), label: name })), [allEntities]);
    const entityOptions = useMemo(() => {
        let entities = allEntities.filter(({ type }) => type === EntityType.COMPANY).map(({ name, entityId }) => ({ value: entityId!.toString(), label: name }));
        if (isAgency) {
            entities = entities.concat(agencyEntities);
        }
        return entities;
    }, [allEntities, isAgency, agencyEntities]);

    const uploadEntityA = useMemo(() => {
        if (!entityA) {
            dispatch(incompleteDocumentUpdateValue('agentEntitySelected', null));
            return null;
        }
        return entityOptions.find(({ value }) => value === entityA?.toString()) || null;
    }, [entityA, entityOptions, dispatch]);

    const uploadEntityB = useMemo(() => {
        if (!entityB) {
            dispatch(incompleteDocumentUpdateValue('agentEntitySelected', null));
            return null;
        }
        return entityOptions.find(({ value }) => value === entityB?.toString()) || null;
    }, [entityB, entityOptions, dispatch]);

    const entityIsAgency = useCallback((id: number) => allEntities.find(({ entityId }) => entityId === id)?.canBeAgent === 1 || false, [allEntities]);

    const entityAOptions = useMemo(() => {
        if (entityB) {
            if (isAgency && !entityIsAgency(entityB)) {
                return agencyEntities;
            }
            return entityOptions.filter(({ value }) => value !== entityB.toString());
        }
        return entityOptions;
    }, [entityOptions, isAgency, entityB, entityIsAgency, agencyEntities]);

    const entityBOptions = useMemo(() => {
        if (entityA) {
            if (isAgency && !entityIsAgency(entityA)) {
                return agencyEntities;
            }
            return entityOptions.filter(({ value }) => value !== entityA.toString());
        }
        return entityOptions;
    }, [entityOptions, isAgency, entityA, entityIsAgency, agencyEntities]);

    const toggleAgencyDocument = useCallback((value: boolean) => {
        if (value === false) {
            if (entityA && entityIsAgency(entityA)) {
                dispatch(incompleteDocumentUpdateValue('entityA', null));
            }
            if (entityB && entityIsAgency(entityB)) {
                dispatch(incompleteDocumentUpdateValue('entityB', null));
            }
            if (annexDefinitionId) {
                dispatch(incompleteDocumentUpdateValue('annexDefinitionId', undefined));
            }
        }
        const isAgencyDocument = value ? 1 : 0;
        dispatch(incompleteDocumentUpdateValue('isAgency', isAgencyDocument));
    }, [entityA, entityIsAgency, dispatch, entityB, annexDefinitionId]);

    const bothEntitiesSelectedNoAgency = useMemo(() => !isNull(entityA) && entityIsAgency(entityA) && !isNull(entityB) && !entityIsAgency(entityB), [entityA, entityB, entityIsAgency]);

    const bothCanBeAgency = useMemo(() => {
        const bothAgency = !!(isAgency && entityA && entityB && entityIsAgency(entityA) && entityIsAgency(entityB));
        dispatch(incompleteDocumentUpdateValue('bothEntitiesCanBeAgent', bothAgency));
        return bothAgency;
    }, [entityA, entityB, entityIsAgency, isAgency, dispatch]);

    const setAgencyEntity = useCallback((entityId: number) => dispatch(incompleteDocumentUpdateValue('agentEntitySelected', entityId === agentEntitySelected ? null : entityId)), [dispatch, agentEntitySelected]);

    const entityAIsAgent = useMemo(() => !!(agentEntitySelected && agentEntitySelected === entityA), [agentEntitySelected, entityA]);

    const entityBIsAgent = useMemo(() => !!(agentEntitySelected && agentEntitySelected === entityB), [agentEntitySelected, entityB]);

    const hasEntityManagementPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.ENTITY_MANAGEMENT]));
    const toggleEntityModal = (value: EntityModalType | null) => dispatch(toggleModal(value));
    const closeEntityModal = () => toggleEntityModal(null);
    const openSearchModal = () => toggleEntityModal(EntityModalType.SEARCH);
    const searchEntities = () => dispatch(searchEntitiesStarted());
    const searchEntityModalOpen = useAppSelector(getModalOpen);

    const annexDefinitionModalOpen = useAppSelector(getAnnexDefinitionModalOpen);
    const openAnnexDefinitionModal = () => dispatch(toggleAnnexDefinitionModal(true));
    const closeAnnexDefinitionModal = () => dispatch(toggleAnnexDefinitionModal(false));

    const removeOriginalDocumentLink = () => {
        setOriginalDocumentId(null);
        if (isNull(mlData)) {
            dispatch(incompleteDocumentUpdateValue('entityA', null));
            dispatch(incompleteDocumentUpdateValue('entityB', null));
            dispatch(incompleteDocumentUpdateValue('documentNameId', null));
            dispatch(incompleteDocumentUpdateValue('linkedDocumentId', null));
            dispatch(incompleteDocumentUpdateValue('annexDefinitionId', undefined));
            dispatch(incompleteDocumentUpdateValue('isAgency', 0));
            dispatch(incompleteDocumentUpdateValue('amendmentLinkedDocumentIds', undefined));
        }
    };

    const removeBaseDocumentLink = (id: number) => {
        const currentBaseDocuments = baseDocuments || [];
        const updatedBaseDocuments = currentBaseDocuments.filter(({ documentId }) => documentId !== id);
        dispatch(incompleteDocumentUpdateValue('baseDocuments', updatedBaseDocuments));
    };

    const openOriginalDocumentsModal = () => dispatch(toggleOriginalDocumentsModal(true));
    const closeOriginalDocumentsModal = () => dispatch(toggleOriginalDocumentsModal(false));
    const originalDocumentsModalOpen = useAppSelector(getOriginalDocumentsModalOpen);

    const openBaseDocumentsModal = () => dispatch(toggleBaseDocumentsModal(true));
    const closeBaseDocumentsModal = () => dispatch(toggleBaseDocumentsModal(false));
    const baseDocumentsModalOpen = useAppSelector(getBaseDocumentsModalOpen);

    const selectOriginalDocumentLink = (value: number) => {
        setOriginalDocumentId(value);
        const originalDocument = originalDocuments.find(({ documentId }) => documentId === value)!;
        const { entityA, entityB, documentNameId, linkedDocumentId, annexDefinitionId, isAgency } = originalDocument;
        dispatch(incompleteDocumentUpdateValue('entityA', entityA));
        dispatch(incompleteDocumentUpdateValue('entityB', entityB));
        dispatch(incompleteDocumentUpdateValue('documentNameId', documentNameId));
        dispatch(incompleteDocumentUpdateValue('annexDefinitionId', annexDefinitionId));
        dispatch(incompleteDocumentUpdateValue('isAgency', isAgency));
        if (!isReplicationDocument) {
            dispatch(incompleteDocumentUpdateValue('linkedDocumentId', linkedDocumentId));
        }
        closeOriginalDocumentsModal();
    };

    const selectBaseDocumentLink = (value: number) => {
        const baseDocument = allBaseDocuments.find(({ documentId }) => documentId === value)!;
        const { originalDocumentId, documentId, documentDescription, documentName } = baseDocument;
        const currentBaseDocuments = baseDocuments || [];
        const updatedBaseDocuments = [...currentBaseDocuments, { originalDocumentId, documentId, documentDescription, documentName: documentName! }];
        dispatch(incompleteDocumentUpdateValue('baseDocuments', updatedBaseDocuments));
    };

    const linkedDocumentIsAgency = useMemo(() => !isNull(linkedDocumentId) && !!isdaDocuments.find(({ documentId }) => documentId === linkedDocumentId)?.isAgency, [linkedDocumentId, isdaDocuments]);

    const annexDefinitionLabel = useMemo(() => {
        if (!annexDefinitionId || !allAnnexes) {
            return `Create Agency Annex${linkedDocumentIsAgency ? ' (optional)' : ''}`;
        }
        const annexDefinition = allAnnexes.find(annex => annex.annexDefinitionId === annexDefinitionId);
        if (!annexDefinition || annexDefinition && !annexDefinition.annexDefinitionTitle) {
            return `Agency Annex ${annexDefinitionId}`;
        }
        return annexDefinition.annexDefinitionTitle;
    }, [annexDefinitionId, allAnnexes, linkedDocumentIsAgency]);

    const selectISDADocumentLink = (value: number) => {
        setLinkedDocumentId(value);
        const isdaDocument = isdaDocuments.find(({ documentId }) => documentId === value)!;
        const { isAgency, entityA, entityB } = isdaDocument;
        dispatch(incompleteDocumentUpdateValue('entityA', entityA));
        dispatch(incompleteDocumentUpdateValue('entityB', entityB));
        dispatch(incompleteDocumentUpdateValue('isAgency', isAgency));
        closeIsdaDocumentsModal();
    };

    const removeISDADocumentLink = () => {
        setLinkedDocumentId(null);
        dispatch(incompleteDocumentUpdateValue('entityA', null));
        dispatch(incompleteDocumentUpdateValue('entityB', null));
        dispatch(incompleteDocumentUpdateValue('isAgency', 0));
        dispatch(incompleteDocumentUpdateValue('annexDefinitionId', undefined));
    };

    const fetchLinkedDocuments = useCallback(() => {
        if (originalDocumentId && (!!documentType && [DocumentType.AMENDMENT].includes(documentType))) {
            dispatch(fetchLinkedDocumentsStarted(originalDocumentId));
        }
    }, [dispatch, originalDocumentId, documentType]);

    const setAmendmentLinkedDocumentIds = (value: DropdownOption | Options<DropdownOption> | null) => {
        let linkedDocumentIds = null;
        if (!isNull(value)) {
            linkedDocumentIds = (value as Options<DropdownOption>).map(({ value }) => parseInt(value));
        }
        dispatch(incompleteDocumentUpdateValue('amendmentLinkedDocumentIds', linkedDocumentIds));
    };

    const selectedLinkedDocuments = useMemo(() => {
        if (amendmentLinkedDocumentIds) {
            return linkedDocuments!.filter(({ documentId }) => amendmentLinkedDocumentIds.includes(documentId)).map(({ documentId, documentDescription }) => ({ value: documentId.toString(), label: documentDescription }));
        }
        return null;
    }, [amendmentLinkedDocumentIds, linkedDocuments]);

    const linkedDocumentsList = (!isNull(linkedDocuments) && linkedDocuments.length > 0) ? linkedDocuments.map(({ documentDescription, documentId }) => ({ label: documentDescription, value: documentId.toString() })) : [];

    const originalDocumentDescription = useMemo(() => originalDocumentId && !isSupportDocument && originalDocuments.length && originalDocuments.find(({ documentId }) => documentId === originalDocumentId)?.documentDescription || 'View Documents', [originalDocumentId, originalDocuments, isSupportDocument]);
    const baseDocumentDescription = useMemo(() => !!baseDocuments && baseDocuments.length ? `${baseDocuments.length} document${baseDocuments.length > 1 ? 's' : ''} selected` : 'View Documents', [baseDocuments]);

    const agencyEntityIds = useMemo(() => allEntities.filter(({ canBeAgent }) => canBeAgent).map(({ entityId }) => entityId), [allEntities]);

    const updateAgencyEntity = useCallback(() => {
        // This is only needed when only 1 canBeAgent as we do not provide the toggle
        if (!!isAgency && !bothCanBeAgency && (entityA && entityB)) {
            let entityActingAsAgent = null;
            if (agencyEntityIds.includes(entityA)) {
                entityActingAsAgent = entityA;
            }
            if (agencyEntityIds.includes(entityB)) {
                entityActingAsAgent = entityB;
            }
            dispatch(incompleteDocumentUpdateValue('agentEntitySelected', entityActingAsAgent));
        }
    }, [isAgency, bothCanBeAgency, entityA, entityB, agencyEntityIds, dispatch]);

    const selectedBaseDocuments = useMemo(() => baseDocuments || [], [baseDocuments]);

    const aIScheduleStartPageTooltip = 'The schedule start page is used to determine the layout and location of various sections of the document. This is a crucial to our AI extraction of the document data. Both schedule start page and schedule end page are inclusive. Please confirm by typing the correct page before continuing.';
    const standardScheduleStartPageTooltip = 'The schedule start page is used to determine the layout and location of various sections of the document. This is used to create the linked scrolling between the data and document. Both schedule start page and schedule end page are inclusive. Please confirm by typing the correct page before continuing.';
    const scheduleStartPageTooltip = useMemo(() => isNull(mlData) ? standardScheduleStartPageTooltip : aIScheduleStartPageTooltip, [mlData]);
    const agencyAnnexPageTooltip = useMemo(() => 'By providing the start and end of the Agency Annex Table, we will attempt to extract this information automatically.', []);

    useEffect(() => {
        if (amendmentLinkedDocumentIds) {
            setExecutedDate(minDate || null);
        }
    }, [setExecutedDate, amendmentLinkedDocumentIds, minDate]);

    useEffect(() => {
        if (bothEntitiesSelectedNoAgency && isAgency) {
            toggleAgencyDocument(false);
        }
    }, [toggleAgencyDocument, bothEntitiesSelectedNoAgency, isAgency]);

    useEffect(() => {
        fetchAnnexDefinitions();
        fetchAgencyFields();
        fetchLinkedDocuments();
    }, [fetchAnnexDefinitions, fetchAgencyFields, updateAgencyEntity, fetchLinkedDocuments]);

    useEffect(() => {
        updateAgencyEntity();
    }, [updateAgencyEntity]);

    return (
        <div className={styles.documentDetails}>
            <Text
                label='Document Description'
                onChange={e => setDocumentDescription(e.target.value)}
                value={documentDescription}
                maxLength={256}
                testId='upload-document-modal-description'
                disabled={!hasCompleteDocumentPermission}
            />
            <div className={styles.documentTypeWrapper} data-testid='document-type-select-wrapper'>
                <div className={styles.documentTypeHeader}>Type</div>
                <div className={styles.documentTypeDropdown}>
                    <Dropdown
                        value={documentTypeValue}
                        onChange={setDocumentType}
                        options={documentTypeOptions}
                        placeholder='Select...'
                        testId='document-type'
                        disabled={!hasCompleteDocumentPermission}
                    />
                </div>
            </div>
            {!isSupportDocument ? (
                <div className={styles.contentWrapper}>
                    {typeSelected &&
                        <div data-testid='document-name-wrapper' className={styles.documentNameWrapper}>
                            <div className={styles.documentNameHeader}>Document</div>
                            <div className={styles.documentNameDropdown}>
                                <DocumentNameDropdown
                                    value={uploadDocumentName}
                                    onChange={setDocumentNameId}
                                    options={documentNameOptions}
                                    placeholder={documentNameOptions.length ? 'Select...' : 'No documents available'}
                                    disabled={!documentNameOptions.length || isSupportDocument || (!originalDocumentDatasetId && (isSecondaryOption || isReplicationDocument)) || !hasCompleteDocumentPermission}
                                    isClearable={isOriginalOption}
                                />
                            </div>
                        </div>
                    }
                    {(isOriginalOption || isReplicationDocument) && canLinkToIsda &&
                        <DocumentLinkButton
                            open={openIsdaDocumentsModal}
                            remove={removeISDADocumentLink}
                            buttonLabel={linkedDocumentDescription}
                            showRemove={!isNull(linkedDocumentId)}
                            testId='isda-document-link'
                            header='Link to ISDA document'
                            disabled={!hasCompleteDocumentPermission}
                        />
                    }
                    {isOriginalOption && uploadDocumentName && uploadDocumentName.agencyDatasetId &&
                        <div className={styles.agencyAnnexWrapper}>
                            <div className={styles.agencyTitleWrapper}>
                                <div className={styles.agencyToggleTitle}>Agency Agreement?</div>
                                <InformationTooltip content='Is this document an agency agreement between multiple parties within one document?' />
                            </div>
                            <div className={styles.agencyToggleWrapper}>
                                <Toggle
                                    checked={!!isAgency}
                                    onChange={toggleAgencyDocument}
                                    testId='upload-document-is-agency'
                                    disabled={linkedDocumentIsAgency || !hasCompleteDocumentPermission || bothEntitiesSelectedNoAgency}
                                />
                                {!!isAgency && <button className={styles.agencyDefinitionButton} onClick={openAnnexDefinitionModal} style={{ width: linkedDocumentIsAgency ? '240px' : '160px' }} disabled={!hasCompleteDocumentPermission}>{annexDefinitionLabel}</button>}
                            </div>
                        </div>
                    }
                    {typeSelected &&
                        <div data-testid='linked-entities-wrapper' className={styles.entityLinkWrapper}>
                            <div className={styles.entityLinkHeader}>
                                <div className={styles.entityLinkTitle}>Link Entities to Document</div>
                                {hasEntityManagementPermission && (isOriginalOption || isPreExecutionDocument) && <IconButton icon={Search} onClick={openSearchModal} fontSize={22} testId='search-entities' />}
                            </div>
                            <div className={styles.entityLinkDropdownWrapper}>
                                <div className={styles.entityLinkDropdownA}>
                                    <Dropdown
                                        disabled={!entityAOptions.length || isSecondaryOption || isSupportDocument || !isNull(linkedDocumentId) || (!originalDocumentDatasetId && isReplicationDocument) || !hasCompleteDocumentPermission}
                                        value={uploadEntityA}
                                        options={entityAOptions}
                                        onChange={setLinkedEntityA}
                                        testId='upload-document-entity-a'
                                        placeholder={entityAOptions.length ? 'Party A...' : 'No entities found'}
                                        menuPortalTarget={document.body}
                                    />
                                </div>
                                <div className={styles.entityLinkDropdownB}>
                                    <Dropdown
                                        disabled={!entityBOptions.length || isSecondaryOption || isSupportDocument || !isNull(linkedDocumentId) || (!originalDocumentDatasetId && isReplicationDocument) || !hasCompleteDocumentPermission}
                                        value={uploadEntityB}
                                        options={entityBOptions}
                                        onChange={setLinkedEntityB}
                                        testId='upload-document-entity-b'
                                        placeholder={entityBOptions.length ? 'Party B...' : 'No entities found'}
                                        menuPortalTarget={document.body}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {!!bothCanBeAgency && (isOriginalOption || isReplicationDocument) &&
                        <div className={styles.selectAgencyWrapper}>
                            <div className={styles.descriptionWrapper}>
                                <div className={styles.description}>Select the entity acting as an agent</div>
                                <InformationTooltip content='As both of the selected entities have the capacity to act as an agent, please specify which one is doing so within this document.' />
                            </div>
                            <div className={styles.agencyToggleWrapper}>
                                <div className={styles.partyToggleWrapper}>
                                    <div className={styles.partyLabel}>Party A</div>
                                    <Toggle
                                        checked={entityAIsAgent}
                                        onChange={() => setAgencyEntity(entityA!)}
                                        testId='entity-a-acting-agent-toggle'
                                        disabled={!!entityBIsAgent || !hasCompleteDocumentPermission}
                                    />
                                </div>
                                <div className={styles.partyToggleWrapper}>
                                    <div className={styles.partyLabel}>Party B</div>
                                    <Toggle
                                        checked={entityBIsAgent}
                                        onChange={() => setAgencyEntity(entityB!)}
                                        testId='entity-b-acting-agent-toggle'
                                        disabled={!!entityAIsAgent || !hasCompleteDocumentPermission}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {(isSecondaryOption || isReplicationDocument) &&
                        <DocumentLinkButton
                            open={openOriginalDocumentsModal}
                            remove={removeOriginalDocumentLink}
                            buttonLabel={originalDocumentDescription}
                            showRemove={!isNull(originalDocumentId)}
                            testId='original-document-link'
                            header={isReplicationDocument ? 'Document to Replicate' : 'Link to Existing Document'}
                            disabled={!hasCompleteDocumentPermission}
                        />
                    }
                    {isSecondaryOption && !isNull(linkedDocuments) && linkedDocuments.length > 0 &&
                        <div className={styles.linkedDocumentsWrapper}>
                            <div className={styles.linkedDocumentsHeader}>To which other document(s), if any, does this amendment apply (optional)?</div>
                            <Dropdown
                                testId='secondary-documents-linked-documents'
                                onChange={val => setAmendmentLinkedDocumentIds(val)}
                                value={selectedLinkedDocuments}
                                options={linkedDocumentsList}
                                isClearable={true}
                                disabled={!hasCompleteDocumentPermission}
                                placeholder='Select...'
                                isMulti
                            />
                        </div>
                    }
                    {typeSelected && !isPreExecutionDocument &&
                        <div className={styles.dateWrapper} data-testid='document-date-wrapper'>
                            <div data-testid='date-of-execution-wrapper' className={styles.executedDateWrapper}>
                                <div className={styles.executedDateHeader}>Date of Execution</div>
                                <DatePicker
                                    value={dateValue}
                                    onChange={setExecutedDate}
                                    testId='upload-document-executed-date'
                                    maxDate={new Date()}
                                    minDate={minDate}
                                    disabled={!hasCompleteDocumentPermission}
                                />
                            </div>
                            <div data-testid='dated-as-of-wrapper' className={styles.datedAsOfWrapper} style={{ paddingLeft: '20px' }}>
                                <div className={styles.datedAsOfHeader}>Dated as of</div>
                                <DatePicker
                                    value={datedAsOfValue}
                                    onChange={setDatedAsOf}
                                    testId='upload-document-dated-as-of'
                                    maxDate={new Date()}
                                    minDate={minDatedAsOf}
                                    disabled={!hasCompleteDocumentPermission}
                                />
                            </div>
                        </div>
                    }
                    {typeSelected && isOriginalOption &&
                        <div className={styles.schedulePageInfo}>
                            <div className={styles.schedulePageWrapper} style={{ width: '50%' }}>
                                <div className={styles.schedulePageLabelWrapper}>
                                    <div className={styles.schedulePageLabel}>Schedule Start Page:</div>
                                    <div className={styles.schedulePageTooltip}>
                                        <InformationTooltip content={scheduleStartPageTooltip} />
                                    </div>
                                </div>
                                <div className={styles.schedulePageInput}>
                                    <Text
                                        testId='schedule-start-page'
                                        value={arkDocument.scheduleStartPage?.toString()}
                                        onChange={e => updateScheduleStartPage(e.target.value)}
                                        marginBottom='0px'
                                        width='40%'
                                        marginRight='10px'
                                        placeholder={scheduleStartPagePlaceholder?.toString()}
                                        showBoldPlaceholder
                                        borderColour={isNull(arkDocument.scheduleStartPage) ? amber : primary}
                                        disabled={!hasCompleteDocumentPermission}
                                    />
                                    <IconButton icon={ArrowRight} onClick={() => scrollToPage(scheduleStartPage)} disabled={linkToScheduleStartPageDisabled} testId='schedule-start-page' />
                                </div>
                            </div>
                            <div className={styles.schedulePageWrapper} style={{ width: 'calc(50% - 10px)', marginLeft: '10px' }}>
                                <div className={styles.schedulePageLabelWrapper}>
                                    <div className={styles.schedulePageLabel}>Schedule End Page:</div>
                                </div>
                                <div className={styles.schedulePageInput}>
                                    <Text
                                        testId='schedule-end-page'
                                        value={arkDocument.scheduleEndPage?.toString()}
                                        onChange={e => updateScheduleEndPage(e.target.value)}
                                        marginBottom='0px'
                                        width='40%'
                                        marginRight='10px'
                                        placeholder={scheduleEndPagePlaceholder?.toString()}
                                        showBoldPlaceholder
                                        borderColour={isNull(arkDocument.scheduleEndPage) ? amber : primary}
                                        disabled={!hasCompleteDocumentPermission}
                                    />
                                    <IconButton icon={ArrowRight} onClick={() => scrollToPage(scheduleEndPage)} disabled={linkToScheduleEndPageDisabled} testId='schedule-end-page' />
                                </div>
                            </div>
                        </div>
                    }
                    {isAgency === 1 && uploadDocumentName && !isNull(uploadDocumentName.agencyDatasetId) && (isOriginalOption || isReplicationDocument) &&
                        <div className={styles.schedulePageInfo}>
                            <div className={styles.schedulePageWrapper} style={{ width: '50%' }}>
                                <div className={styles.schedulePageLabelWrapper}>
                                    <CustomTooltip overlayText='Agency Annex Start Page'>
                                        <div className={styles.schedulePageLabel}>Agency Start Page:</div>
                                    </CustomTooltip>
                                    <div className={styles.schedulePageTooltip}>
                                        <InformationTooltip content={agencyAnnexPageTooltip} />
                                    </div>
                                </div>
                                <div className={styles.schedulePageInput}>
                                    <Text
                                        testId='agency-annex-start-page'
                                        value={agencyAnnexStartPage?.toString()}
                                        onChange={e => updateAgencyAnnexStartPage(e.target.value)}
                                        marginBottom='0px'
                                        width='40%'
                                        marginRight='10px'
                                        borderColour={isNull(agencyAnnexStartPage) ? amber : primary}
                                        disabled={!hasCompleteDocumentPermission}
                                    />
                                    <IconButton icon={ArrowRight} onClick={() => scrollToPage(agencyAnnexStartPage)} disabled={linkToAgencyAnnexStartPageDisabled} testId='agency-annex-start-page' />
                                </div>
                            </div>
                            <div className={styles.schedulePageWrapper} style={{ width: 'calc(50% - 10px)', marginLeft: '10px' }}>
                                <div className={styles.schedulePageLabelWrapper}>
                                    <CustomTooltip overlayText='Agency Annex End Page'>
                                        <div className={styles.schedulePageLabel}>Agency End Page:</div>
                                    </CustomTooltip>
                                </div>
                                <div className={styles.schedulePageInput}>
                                    <Text
                                        testId='agency-annex-end-page'
                                        value={agencyAnnexEndPage?.toString()}
                                        onChange={e => updateAgencyAnnexEndPage(e.target.value)}
                                        marginBottom='0px'
                                        width='40%'
                                        marginRight='10px'
                                        borderColour={isNull(agencyAnnexEndPage) ? amber : primary}
                                        disabled={!hasCompleteDocumentPermission}
                                    />
                                    <IconButton icon={ArrowRight} onClick={() => scrollToPage(agencyAnnexEndPage)} disabled={linkToAgencyAnnexEndPageDisabled} testId='agency-annex-end-page' />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            ) : (
                <div className={styles.contentWrapper}>
                    <DocumentLinkButton
                        open={openBaseDocumentsModal}
                        remove={noop}
                        buttonLabel={baseDocumentDescription}
                        showRemove={false}
                        testId='base-document-link'
                        header='Link to document(s)'
                        disabled={!hasCompleteDocumentPermission}
                    />
                    <div className={styles.baseDocuments}>
                        <div className={styles.baseDocumentHeader}>
                            <div className={styles.baseDocumentDescription}>Document Description</div>
                            <div className={styles.baseDocumentName}>Document</div>
                            <div className={styles.baseDocumentDelete}><Icon icon={Delete} fontSize={20} /></div>
                        </div>
                        <div className={styles.baseDocumentBody}>
                            <Scrollable maxHeight='300px'>
                                {selectedBaseDocuments.length ? (selectedBaseDocuments.map(({ documentId, documentDescription, documentName }, index) => (
                                    <div className={styles.baseDocumentRow} key={documentId} style={{ backgroundColor: index % 2 ? lightGrey : white }}>
                                        <div className={styles.baseDocumentDescription}><OverflowTooltip overlayText={documentDescription} /></div>
                                        <div className={styles.baseDocumentName}><OverflowTooltip overlayText={documentName} /></div>
                                        <div className={styles.baseDocumentDelete}><DeleteButton onClick={() => removeBaseDocumentLink(documentId)} fontSize={18} disabled={!hasCompleteDocumentPermission} /></div>
                                    </div>
                                ))) : (
                                    <div className={styles.noDocumentsRow}>No documents selected</div>
                                )}
                            </Scrollable>
                        </div>
                    </div>
                </div>
            )}
            {(isOriginalOption || isReplicationDocument || isPreExecutionDocument) &&
                <SearchEntityModal
                    searchEntities={searchEntities}
                    closeModal={closeEntityModal}
                    isOpen={searchEntityModalOpen === EntityModalType.SEARCH}
                />
            }
            {(isOriginalOption || isReplicationDocument) && isdaDocumentsModalOpen && hasCompleteDocumentPermission &&
                <ISDADocumentsModal
                    select={selectISDADocumentLink}
                    close={closeIsdaDocumentsModal}
                    selectedId={linkedDocumentId}
                />
            }
            {isOriginalOption && uploadDocumentName && hasCompleteDocumentPermission &&
                <AnnexDefinitionModal
                    close={closeAnnexDefinitionModal}
                    isOpen={annexDefinitionModalOpen}
                    documentName={uploadDocumentName}
                />
            }
            {(isSecondaryOption || isReplicationDocument) && originalDocumentsModalOpen && hasCompleteDocumentPermission &&
                <OriginalDocumentsModal
                    select={selectOriginalDocumentLink}
                    close={closeOriginalDocumentsModal}
                    selectedId={originalDocumentId}
                />
            }
            {isSupportDocument && baseDocumentsModalOpen && hasCompleteDocumentPermission &&
                <BaseDocumentsModal
                    select={selectBaseDocumentLink}
                    close={closeBaseDocumentsModal}
                    baseDocuments={baseDocuments}
                />
            }
        </div>
    );
};
