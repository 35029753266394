import React, { useMemo } from 'react';
import { getOr, isNull, set } from 'lodash/fp';
import classnames from 'classnames';

import { useAppSelector } from '../../../hooks/react-redux';
import { CustomFilters, DocumentCustomFilters, FilterTable, OpinionCustomFilters } from '../../auth/login/store';
import { CustomFiltersFilterInputs } from './CustomFiltersFilterInputs';
import { TableFilters, TableFilterType } from '../../shared/modal/TableFilterModal';
import { fetchAllEntitiesStarted, getBaseEntityDropdownOptions } from '../../admin/entity/store';
import { fetchAllDocumentGroupsStarted, fetchAvailableDocumentNamesStarted, getAllClientDocumentGroups, getAvailableDocumentNames } from '../../admin/documents/store';
import styles from '../Home.module.scss';
import { allFocusOptions, opinionCommissionedByOptions, opinionScopeOptions } from '../../constants/opinion';
import { getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { useFetchStarted } from '../../../hooks/useFetchStarted';

interface TileProperties {
    label: string;
    field: keyof DocumentCustomFilters | keyof OpinionCustomFilters;
}

interface CustomFiltersBodyProps {
    updateFilter: (key: keyof DocumentCustomFilters | keyof OpinionCustomFilters, value: string | string[] | null, type?: keyof TableFilterType) => void;
    selectedTile: CustomFilters | null;
}

export const CustomFiltersBody: React.FC<CustomFiltersBodyProps> = (({ updateFilter, selectedTile }) => {

    useFetchStarted([fetchAvailableDocumentNamesStarted(), fetchAllEntitiesStarted(true), fetchAllDocumentGroupsStarted()]);

    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const entityOptions = useAppSelector(getBaseEntityDropdownOptions);
    const dropdownLists = useAppSelector(getAllDropdownLists);
    const allClientDocumentGroups = useAppSelector(getAllClientDocumentGroups);

    // Documents
    const documentTileProperties: TileProperties[] = useMemo(() => [{ label: 'Document Description', field: 'documentDescription' }, { label: 'Document Name', field: 'documentName' }, { label: 'Entity A', field: 'entityA' }, { label: 'Entity B', field: 'entityB' }], []);
    const documentNameOptions = useMemo(() => allDocumentNames
        .filter(({ datasetId }) => datasetId)
        .map(({ documentName }) => ({ value: documentName, label: documentName })), [allDocumentNames]
    );

    const clientDocumentGroupOptions = useMemo(() => allClientDocumentGroups.map(({ groupName, clientDocumentGroupId }, i) => ({ value: clientDocumentGroupId!.toString(), label: groupName, showDivider: i === allClientDocumentGroups.length - 1 })), [allClientDocumentGroups]);

    const documentsAndDocumentGroupOptions = useMemo(() => [...clientDocumentGroupOptions, ...documentNameOptions], [documentNameOptions, clientDocumentGroupOptions]);

    const documentFilterDropdownOptions = useMemo(() => ({
        documentName: documentsAndDocumentGroupOptions,
        entityA: entityOptions,
        entityB: entityOptions
    }), [documentsAndDocumentGroupOptions, entityOptions]);

    // Opinions
    const opinionTileProperties: TileProperties[] = useMemo(() => [{ label: 'Jurisdiction', field: 'jurisdiction' }, { label: 'Focus', field: 'focus' }, { label: 'Bespoke', field: 'bespoke' }, { label: 'Scope', field: 'scope' }, { label: 'Commissioned By', field: 'commissionedBy' }], []);
    const opinionJurisdictionOptions = useMemo(() => {
        const jurisdictionList = dropdownLists.find(({ name }) => name === 'OpinionJurisdiction');
        return jurisdictionList ? jurisdictionList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);
    const bespokeOptions = useMemo(() => [{ label: 'Bespoke', value: '1' }, { label: 'Industry Standard', value: '0' }], []);
    const scopeOptions = useMemo(() => opinionScopeOptions(), []);
    const commissionedByOptions = useMemo(() => opinionCommissionedByOptions(), []);

    const opinionFilterDropdownOptions = useMemo(() => ({
        scope: scopeOptions,
        bespoke: bespokeOptions,
        commissionedBy: commissionedByOptions,
        jurisdiction: opinionJurisdictionOptions,
        focus: allFocusOptions
    }), [bespokeOptions, opinionJurisdictionOptions, scopeOptions, commissionedByOptions]);

    const isDocument = selectedTile?.filterTable === FilterTable.DOCUMENT;
    const tileProperties = isDocument ? documentTileProperties : opinionTileProperties;
    const dropdownOptions = isDocument ? documentFilterDropdownOptions : opinionFilterDropdownOptions;
    const filters: TableFilters | null = useMemo(() => {
        if (isNull(selectedTile)) {
            return null;
        }
        return tileProperties.reduce((acc, curr) => {
            const fieldFilters: TableFilterType | undefined = getOr(undefined, curr.field, selectedTile);
            return set(curr.field, fieldFilters, acc);
        }, {});
    }, [selectedTile, tileProperties]);

    if (isNull(filters)) {
        return null;
    }

    return (
        <div className={styles.customFilterInformationSection}>
            {tileProperties.map(({ label, field }, index) => (
                <div key={index}>
                    <div className={classnames(styles.customFilterTileModalSectionHeader, {
                        [styles.noTopPadding]: field === 'jurisdiction' || field === 'documentDescription'
                    })}>{label}</div>
                    <CustomFiltersFilterInputs
                        filters={filters}
                        filterDropdownOptions={dropdownOptions}
                        updateFilter={updateFilter}
                        field={field}
                        textFilterVisible
                        testId={label.toLowerCase().split(' ').join('-')}
                    />
                </div>
            ))}
        </div>
    );
});
