import { isNull, uniqBy } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { DoraFunctionCompany, DoraSupplyChainDetails, DoraSupplyChainState, NewSupplyChainElement, SupplyChain, SupplyChainElement, DoraFunctionCompanyDetails, SupplyChainElementTab, DoraSupplyChainContractualInfo, DoraSupplyChainAssessmentInfo, DoraSupplyChainAssessmentTimeline, DoraDocumentPreview, IctContractDisplay } from './types';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { newSupplyChainElement } from './reducer';

const getRoot = (state: ApplicationState): DoraSupplyChainState => state.dora.supplyChain;

export const getDoraFunctionCompanies = (state: ApplicationState): DoraFunctionCompany[] => getRoot(state).doraFunctionCompanyDetails;

export const getSelectedCompanyId = (state: ApplicationState): number | null => getRoot(state).selectedCompanyId;

export const getSelectedFunctionId = (state: ApplicationState): number | null => getRoot(state).selectedFunctionId;

export const getDoraSupplyChainCompanies = (state: ApplicationState): { value: number; label: string; }[] => {
    let functionCompanyPairs = getDoraFunctionCompanies(state);
    const selectedFunction = getSelectedFunctionId(state);
    if (!isNull(selectedFunction)) {
        functionCompanyPairs = functionCompanyPairs.filter(({ doraFunctionId }) => doraFunctionId === selectedFunction);
    }
    return uniqBy('value', functionCompanyPairs.map(({ companyName, companyId }) => ({ value: companyId, label: companyName })));
};

export const getDoraSupplyChainFunctions = (state: ApplicationState): { value: number; label: string; }[] => {
    let functionCompanyPairs = getDoraFunctionCompanies(state);
    const selectedCompanyId = getSelectedCompanyId(state);
    if (!isNull(selectedCompanyId)) {
        functionCompanyPairs = functionCompanyPairs.filter(({ companyId }) => companyId === selectedCompanyId);
    }
    return uniqBy('value', functionCompanyPairs.map(({ functionName, doraFunctionId }) => ({ value: doraFunctionId, label: functionName })));
};

export const getIsFetchingDoraFunctionCompanyDetails = (state: ApplicationState): boolean => getRoot(state).isFetchingDoraFunctionCompanyDetails;

export const getResetFunctionCompanyButtonVisible = (state: ApplicationState): boolean => !isNull(getSelectedCompanyId(state)) || !isNull(getSelectedFunctionId(state));

export const getCompanyAndFunctionSelected = (state: ApplicationState): boolean => !isNull(getSelectedCompanyId(state)) && !isNull(getSelectedFunctionId(state));

export const getDroppableHeight = (state: ApplicationState): number => getRoot(state).droppableHeight;

export const getCurrentSupplyChain = (state: ApplicationState): SupplyChain | null => getRoot(state).currentSupplyChain;

export const getDetailsInformation = (state: ApplicationState): DoraSupplyChainDetails => {
    const selectedId = getSelectedSupplyChainId(state);
    if (selectedId) {
        const supplyChainElements = getSupplyChainElements(state);
        const selectedChainElement: SupplyChainElement | undefined = supplyChainElements.find(({ doraSupplyChainId }) => doraSupplyChainId === selectedId);
        if (selectedChainElement) {
            return selectedChainElement.details;
        }
    }
    const newSupplyChainElement = getNewSupplyChainElement(state);
    return newSupplyChainElement.details;
};

export const getSavedDetailsInformation = (state: ApplicationState): DoraSupplyChainDetails => {
    const selectedId = getSelectedSupplyChainId(state);
    if (selectedId) {
        const supplyChainElements = getSavedSupplyChainElements(state);
        const selectedChainElement: SupplyChainElement | undefined = supplyChainElements.find(({ doraSupplyChainId }) => doraSupplyChainId === selectedId);
        if (selectedChainElement) {
            return selectedChainElement.details;
        }
    }
    return newSupplyChainElement.details;
};

export const getSavedSupplyChain = (state: ApplicationState): SupplyChain | null => getRoot(state).savedSupplyChain;

export const getSupplyChainElements = (state: ApplicationState): SupplyChainElement[] => getCurrentSupplyChain(state)?.supplyChain || [];

export const getSavedSupplyChainElements = (state: ApplicationState): SupplyChainElement[] => getSavedSupplyChain(state)?.supplyChain || [];

export const getSupplyChainDetailsModalOpen = (state: ApplicationState): boolean => getRoot(state).supplyChainDetailsModalOpen;

export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;

export const getSupplyChainElementUpdated = (state: ApplicationState): boolean => getRoot(state).supplyChainElementHasUpdated;

export const getSupplyChainUpdated = (state: ApplicationState): boolean => getRoot(state).supplyChainHasUpdated;

export const getSelectedSupplyChainId = (state: ApplicationState): number | null => getRoot(state).selectedSupplyChainId;

export const getNewSupplyChainElement = (state: ApplicationState): NewSupplyChainElement => getRoot(state).newSupplyChainElement;

export const getAvailableCompanies = (state: ApplicationState): DropdownOption[] => getRoot(state).availableCompanies;

export const getAvailableThirdPartyCompanies = (state: ApplicationState): DropdownOption[] => getRoot(state).availableThirdPartyCompanies;

export const getCompanyFunctionFilters = (state: ApplicationState): TableFilters => getRoot(state).supplyChainFilters;

export const getFunctionsActive = (state: ApplicationState): boolean => Object.values(getCompanyFunctionFilters(state)).some(({ text, dropdown }) => text.length > 0 || !isNull(dropdown));

export const getDeleteConfirmationModalOpen = (state: ApplicationState): boolean => getRoot(state).deleteElementConfirmationModalOpen;

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;

export const getSavedFunctionCompanyDetails = (state: ApplicationState): DoraFunctionCompanyDetails | null => getSavedSupplyChain(state)?.details || null;

export const getCurrentFunctionCompanyDetails = (state: ApplicationState): DoraFunctionCompanyDetails | null => getCurrentSupplyChain(state)?.details || null;

export const getFunctionCompanyName = (state: ApplicationState): string => {
    const savedSupplyChain = getSavedSupplyChain(state);
    if (savedSupplyChain) {
        return `${savedSupplyChain.companyName} - ${savedSupplyChain.functionName}`;
    }
    return '';
};

export const getServiceDescriptionModalOpen = (state: ApplicationState): boolean => getRoot(state).serviceDescriptionOpen;

export const getSelectedElementWizardTab = (state: ApplicationState): SupplyChainElementTab => getRoot(state).selectedElementWizardTab;

export const getSelectedElementContractualInfo = (state: ApplicationState): DoraSupplyChainContractualInfo | null => getRoot(state).supplyChainElementDetails.contractualInfo;

export const getSelectedElementAssessmentInfo = (state: ApplicationState): DoraSupplyChainAssessmentInfo => getRoot(state).supplyChainElementDetails.assessmentInfo;
export const getSavedElementAssessmentInfo = (state: ApplicationState): DoraSupplyChainAssessmentInfo => getRoot(state).savedSupplyChainElementDetails.assessmentInfo;

export const getSupplyChainElementAssessmentTimeline = (state: ApplicationState): DoraSupplyChainAssessmentTimeline[] => getRoot(state).supplyChainElementDetails.assessmentTimeline;
export const getSelectedAssessmentTimeline = (state: ApplicationState): DoraSupplyChainAssessmentTimeline | null => getRoot(state).selectedSupplyChainAssessmentTimeline;

export const getUnsavedChangedModalOpen = (state: ApplicationState): boolean => getRoot(state).unsavedChangesModalOpen;

export const getDoraDocumentPreview = (state: ApplicationState): DoraDocumentPreview | null => getRoot(state).preview;

export const getIsOpening = (state: ApplicationState): boolean => getRoot(state).isOpening;
export const getIsUploading = (state: ApplicationState): boolean => getRoot(state).isUploading;
export const getIsDownloading = (state: ApplicationState): boolean => getRoot(state).isDownloading;

export const getPreviewModalOpen = (state: ApplicationState): boolean => getIsOpening(state) || !isNull(getDoraDocumentPreview(state));

export const getAssessmentInfoFiles = (state: ApplicationState): File[] => getRoot(state).assessmentInfoFiles;

export const getHasDoraFilesToUpload = (state: ApplicationState): boolean => getAssessmentInfoFiles(state).length > 0;

export const getIsLinkingContract = (state: ApplicationState): boolean => getRoot(state).linkingContract;

export const getIctContractDisplay = (state: ApplicationState): IctContractDisplay => getRoot(state).ictContractDisplay;
