import React, { useCallback, MouseEvent } from 'react';

import { FieldHighlight } from '../../../../datasets/store';
import { Icon } from '../../../../shared/icon/Icon';
import { Tick } from '../../../../shared/icons';
import styles from '../DatasetComponents.module.scss';
import { SettingsModal, updateSettingsValue } from '../../store';
import { useAppDispatch } from '../../../../../hooks/react-redux';
import { Position, PositionModal } from '../../../../shared/modal/PositionModal';
import { useFieldHighlightColor } from '../../../../../hooks/useFieldHighlightColor';

interface PaletteSquareProps {
    squareColor: FieldHighlight | null | undefined;
    onClick: (e: MouseEvent<HTMLDivElement>) => void;
    isSelected: boolean;
}

export const PaletteSquare: React.FC<PaletteSquareProps> = ({ squareColor, onClick, isSelected }) => {
    const { backgroundColor, color } = useFieldHighlightColor(squareColor);

    return (
        <div className={styles.paletteSquare} style={{ backgroundColor, color }} onClick={onClick}>
            {isSelected && <Icon icon={Tick} fontSize={16} color={color} />}
        </div>
    );
};

interface PaletteProps {
    highlight: FieldHighlight | null | undefined;
    setHighlight: (color: FieldHighlight | null) => void;
}

export const Palette: React.FC<PaletteProps> = ({ highlight, setHighlight }) => {

    const getIsSelected = useCallback((color: FieldHighlight) => color === highlight, [highlight]);

    const onClick = useCallback((color: FieldHighlight) => {
        const isSelected = getIsSelected(color);
        const highlightColor = isSelected ? null : color;
        setHighlight(highlightColor);
    }, [setHighlight, getIsSelected]);

    return (
        <div className={styles.paletteWrapper}>
            {Object.values(FieldHighlight).map(color => (
                <div key={color}><PaletteSquare squareColor={color} isSelected={getIsSelected(color)} onClick={() => onClick(color)} /></div>
            ))}
        </div>
    );
};

interface HighlightModalProps {
    isOpen: boolean;
    closeModal: () => void;
    settingsModalIndex: SettingsModal;
    highlight: FieldHighlight | null | undefined;
    position: Position | null;
}

export const HighlightModal: React.FC<HighlightModalProps> = ({ isOpen, closeModal, settingsModalIndex, highlight, position }) => {
    const dispatch = useAppDispatch();
    const { index, groupIndex } = settingsModalIndex;

    const setHighlight = (color: FieldHighlight | null) => dispatch(updateSettingsValue('highlight', color, index!, groupIndex));

    return (
        <PositionModal
            isOpen={isOpen}
            padding='5px'
            closeModal={closeModal}
            position={position}
            testId='dataset-builder-highlight-palette'
            width='fit-content'
            height='fit-content'
        >
            <Palette highlight={highlight} setHighlight={setHighlight} />
        </PositionModal>
    );
};
