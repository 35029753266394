import React, { useMemo } from 'react';
import Modal from 'react-modal';
import { isUndefined } from 'lodash/fp';

import { useAppSelector } from '../../../hooks/react-redux';
import { ANALYSIS_MARGIN, ANALYSIS_VIEW_BUTTONS, NAV_BAR, PAGE_BORDER, useSplitView } from '../../../hooks/useSplitView';
import { AnalysisView, getDocumentAnalysisView } from '../../documents/my-documents/store';
import { Button } from '../../shared/button/Button';
import { Calculator } from '../../shared/calculator/Calculator';
import { CalculatorField, CalculatorFieldType } from '../../shared/calculator/constants';
import styles from './Fields.module.scss';
import { MLDataValue } from '../store/mlTypes';
import { MLAnswer } from '../instances/ml-modal/MLDataModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';

interface CalculatorModalProps {
    isOpen: boolean;
    closeModal: () => void;
    disabled: boolean;
    fieldType: CalculatorFieldType;
    value: CalculatorField[];
    borderColor: string;
    updateValue: (output: CalculatorField[]) => void;
    id: string;
    saveModal: () => void;
    showOutputField: boolean;
    showGracePeriod: boolean;
    includeFreeTextOption: boolean;
    mlQuestion?: string;
    mlValue?: MLDataValue;
}

export const CalculatorModal: React.FC<CalculatorModalProps> = ({
    closeModal,
    isOpen,
    disabled,
    fieldType,
    value,
    borderColor,
    updateValue,
    id,
    saveModal,
    showOutputField,
    showGracePeriod,
    includeFreeTextOption,
    mlQuestion,
    mlValue
}) => {

    const widthOffset = ANALYSIS_MARGIN * 2;
    const heightOffset = ANALYSIS_MARGIN + PAGE_BORDER;
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const [width, height] = useSplitView(documentAnalysisView, widthOffset, heightOffset);
    const leftOffset = useMemo(() => documentAnalysisView === AnalysisView.SPLIT ? width + ANALYSIS_VIEW_BUTTONS + (ANALYSIS_MARGIN * 3) : ANALYSIS_VIEW_BUTTONS + ANALYSIS_MARGIN, [documentAnalysisView, width]);
    const modalContentWidth = width - 20;
    const modalContentHeight = height * 0.7;

    const showMlQuery = useMemo(() => !isUndefined(mlQuestion) && !isUndefined(mlValue), [mlQuestion, mlValue]);

    const mlQueryDivHeight = 70;
    const buttonsHeight = 50;
    const calculatorMaxHeight = useMemo(() => showMlQuery ? modalContentHeight - (mlQueryDivHeight + buttonsHeight) : modalContentHeight - buttonsHeight, [showMlQuery, modalContentHeight]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.modal}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            testId='table-dataset-calculator-input'
            style={{
                overlay: {
                    display: 'flex',
                    zIndex: 11,
                    position: 'absolute',
                    left: `${leftOffset}px`,
                    top: `${NAV_BAR}px`,
                    width: `${width}px`,
                    height: `${height}px`,
                    borderRadius: '5px'
                },
                content: {
                    width: `${modalContentWidth}px`,
                    height: 'fit-content',
                    maxHeight: `${modalContentHeight}px`,
                    padding: '10px'
                }
            }}
        >
            <div className={styles.calculatorModalWrapper} style={{ height: `calc(${modalContentHeight} - 50px)` }}>
                {showMlQuery &&
                <Scrollable height={mlQueryDivHeight.toString()}>
                    <div className={styles.mlQueryWrapper}>
                        <div className={styles.mlQuery}>{mlQuestion}</div>
                        <div className={styles.mlAnswer}><MLAnswer answer={mlValue!} depth={2} /></div>
                    </div>
                </Scrollable>
                }
                <Calculator
                    value={value}
                    updateValue={updateValue}
                    isEditing={!disabled}
                    fieldType={fieldType}
                    width={modalContentWidth}
                    testId={`table-instance-modal-calculator-${id}`}
                    borderColor={borderColor}
                    maxHeight={calculatorMaxHeight}
                    showOutputField={showOutputField}
                    showGracePeriod={showGracePeriod}
                    includeFreeTextOption={includeFreeTextOption}
                />
            </div>
            <div className={styles.calculatorModalButtons}>
                <Button onClick={closeModal} label='Cancel' testId='cancel-table-calculator-modal' />
                <Button onClick={saveModal} label='Close and Update' testId='close-and-update-table-calculator-modal' />
            </div>
        </Modal>
    );
};
