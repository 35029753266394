import React, { useState } from 'react';

import { IconButton } from '../../shared/button/IconButton';
import { Eye } from '../../shared/icons';
import styles from './ResetPassword.module.scss';

interface PasswordInputProps {
    testId: string;
    property: string;
    updateField: (property: string, value: string) => void;
    value: string;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    label: string;
    color?: string;
    labelFontSize?: string;
    inputPadding?: string;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({ testId, updateField, property, value, onKeyDown, label, color, labelFontSize = '16px', inputPadding = '15px' }) => {
    const [inputType, setInputType] = useState<string>('password');
    const togglePasswordVisible = () => inputType === 'password' ? setInputType('') : setInputType('password');
    return (
        <div className={styles.passwordWrapper}>
            <div className={styles.passwordInputLabel} style={{ color: color, fontSize: labelFontSize }} data-testid={`${testId}-label`}>{label}</div>
            <div className={styles.passwordInputWrapper}>
                <input
                    data-testid={`${testId}-input`}
                    className={styles.passwordInput}
                    onChange={e => updateField(property, e.target.value)}
                    value={value}
                    type={inputType}
                    onKeyDown={onKeyDown}
                    style={{ border: color, padding: inputPadding }}
                />
                <IconButton color={color} icon={Eye} onClick={togglePasswordVisible} fontSize={20} />
            </div>
        </div>
    );
};
