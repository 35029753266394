import { AgencyDatasetField } from '../../datasets/store';

export enum AnnexDefinitionActionTypes {
    TOGGLE_ANNEX_DEFINITION_MODAL = 'TOGGLE_ANNEX_DEFINITION_MODAL',
    TOGGLE_ANNEX_BUILDER_MODAL = 'TOGGLE_ANNEX_BUILDER_MODAL',
    CREATE_NEW_ANNEX_DEFINITION = 'CREATE_NEW_ANNEX_DEFINITION',
    UPDATE_ANNEX_DATASET_FIELDS = 'UPDATE_ANNEX_DATASET_FIELDS',
    UPDATE_ANNEX_DATASET_FIELD = 'UPDATE_ANNEX_DATASET_FIELD',
    ADD_ANNEX_DATASET_FIELD = 'ADD_ANNEX_DATASET_FIELD',
    ADD_CUSTOM_ANNEX_DATASET_FIELD = 'ADD_CUSTOM_ANNEX_DATASET_FIELD',
    REMOVE_ANNEX_DATASET_FIELD = 'REMOVE_ANNEX_DATASET_FIELD',
    PUBLISH_ANNEX_DEFINITION_STARTED = 'PUBLISH_ANNEX_DEFINITION_STARTED',
    PUBLISH_ANNEX_DEFINITION_SUCCESSFUL = 'PUBLISH_ANNEX_DEFINITION_SUCCESSFUL',
    PUBLISH_ANNEX_DEFINITION_FAILED = 'PUBLISH_ANNEX_DEFINITION_FAILED',
    FETCH_ALL_ANNEX_DEFINITIONS_STARTED = 'FETCH_ALL_ANNEX_DEFINITIONS_STARTED',
    FETCH_ALL_ANNEX_DEFINITIONS_SUCCESSFUL = 'FETCH_ALL_ANNEX_DEFINITIONS_SUCCESSFUL',
    FETCH_ALL_ANNEX_DEFINITIONS_FAILED = 'FETCH_ALL_ANNEX_DEFINITIONS_FAILED',
    FETCH_AGENCY_FIELDS_STARTED = 'FETCH_AGENCY_FIELDS_STARTED',
    FETCH_AGENCY_FIELDS_SUCCESSFUL = 'FETCH_AGENCY_FIELDS_SUCCESSFUL',
    FETCH_AGENCY_FIELDS_FAILED = 'FETCH_AGENCY_FIELDS_FAILED',
    UPDATE_ANNEX_TITLE = 'UPDATE_ANNEX_TITLE',
    DUPLICATE_ANNEX_DEFINITION = 'DUPLICATE_ANNEX_DEFINITION',
    SET_INSTANCE_ANNEX_DEFINITIONS = 'SET_INSTANCE_ANNEX_DEFINITIONS',
    UPDATE_INSTANCE_ANNEX_FIELD = 'UPDATE_INSTANCE_ANNEX_FIELD',
    REMOVE_INSTANCE_ANNEX_DEFINITION = 'REMOVE_INSTANCE_ANNEX_DEFINITION',
    ADD_INSTANCE_ANNEX_DEFINITION = 'ADD_INSTANCE_ANNEX_DEFINITION',
    SET_INSTANCE_ANNEX_VIEW = 'SET_INSTANCE_ANNEX_VIEW',
    SET_SELECTED_INSTANCE_INDEX = 'SET_SELECTED_INSTANCE_INDEX',
    SET_INSTANCE_ANNEX_DEFINITIONS_UPDATED = 'SET_INSTANCE_ANNEX_DEFINITIONS_UPDATED',
    UPSERT_ANNEX_INSTANCES_STARTED = 'UPSERT_ANNEX_INSTANCES_STARTED',
    UPSERT_ANNEX_INSTANCES_SUCCESSFUL = 'UPSERT_ANNEX_INSTANCES_SUCCESSFUL',
    UPSERT_ANNEX_INSTANCES_FAILED = 'UPSERT_ANNEX_INSTANCES_FAILED'
}

export interface AgencyAnnexField extends AgencyDatasetField {
    id: string;
    aliasLabel?: string;
    datasetId?: string;
    isCustom?: boolean;
}

export interface AnnexDefinition {
    annexDefinitionId?: number;
    documentNameId: number;
    datasetFields: AgencyAnnexField[];
    annexDefinitionTitle: string | null;
}

export interface AnnexDefinitionDB extends AnnexDefinition {
    createdBy: number;
    createdDate: string;
    username: string;
}

export interface ArkAgencyRow {
    [fieldId: string]: string | boolean | undefined;
}

export interface DocumentAnnexDefinition {
    startPage: number | null;
    endPage: number | null;
    extractedData: ArkAgencyRow[] | null;
    annexDefinitionId: number;
    extractionInProgress: number;
}

export interface InstanceAnnexDefinition extends Omit<DocumentAnnexDefinition, 'annexDefinitionId'> {
    annexDefinitionId: number | null;
    label: string;
    fieldId: string;
}

export interface UpsertInstanceAnnexDefinition extends InstanceAnnexDefinition {
    previousAnnexDefinitionId: number | null;
}

export enum InstanceAnnexView {
    CONFIGURE = 'CONFIGURE',
    SELECT = 'SELECT',
    BUILDER = 'BUILDER'
}

export interface AnnexDefinitionState {
    annexDefinitionModalOpen: boolean;
    annexBuilderModalOpen: boolean;
    availableFields: AgencyDatasetField[];
    annexDefinition: AnnexDefinition | null;
    isPublishing: boolean;
    isLoading: boolean;
    publishError: string | null;
    fetchError: string | null;
    publishedAnnexDefinitions: AnnexDefinitionDB[];
    currentInstanceAnnexDefinitions: InstanceAnnexDefinition[];
    savedInstanceAnnexDefinitions: InstanceAnnexDefinition[];
    instanceAnnexView: InstanceAnnexView;
    selectedInstanceIndex: number;
    instanceAnnexDefinitionsUpdated: boolean;
    instanceSaving: boolean;
}
