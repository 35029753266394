import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { allFocusOptions, opinionCommissionedByOptions, opinionScopeOptions } from '../../constants/opinion';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { commissionedByFormatter, dateFormatter, scopeFormatter } from '../../shared/table/arkTableFormatters';
import { fetchAllDropdownListsStarted, getAllDropdownLists } from '../dropdown-lists/store';
import { clearSelectedOpinionsTableFilters, getIsLoadingTable, getSelectedOpinionHealthCategory, getSelectedOpinions, getSelectedOpinionsColumnSort, getSelectedOpinionsFilters, getSelectedOpinionsPageNumber, getSelectedOpinionsPageSize, getSelectedOpinionsTotal, selectedOpinionsPaginationNext, selectedOpinionsPaginationPrevious, setSelectedOpinionsTableColumnSort, setSelectedOpinionsTableFilters, setSelectedOpinionsTablePageSize } from './store';

interface AdminOpinionsTableProps {
    tableWidth?: number;
    tableHeight?: number;
}

export const AdminOpinionsTable: React.FC<AdminOpinionsTableProps> = ({ tableWidth, tableHeight }) => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDropdownListsStarted()]);

    const selectedOpinionsData = useAppSelector(getSelectedOpinions);
    const isLoading = useAppSelector(getIsLoadingTable);
    const pageNumber = useAppSelector(getSelectedOpinionsPageNumber);
    const totalOpinions = useAppSelector(getSelectedOpinionsTotal);
    const pageSize = useAppSelector(getSelectedOpinionsPageSize);
    const columnSort = useAppSelector(getSelectedOpinionsColumnSort);
    const filters = useAppSelector(getSelectedOpinionsFilters);
    const dropdownLists = useAppSelector(getAllDropdownLists);
    const selectedOpinionHealthCategory = useAppSelector(getSelectedOpinionHealthCategory);

    const hideTable = useMemo(() => !selectedOpinionHealthCategory, [selectedOpinionHealthCategory]);

    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setSelectedOpinionsTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearSelectedOpinionsTableFilters()), [dispatch]);
    const nextPage = useCallback(() => dispatch(selectedOpinionsPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(selectedOpinionsPaginationPrevious()), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setSelectedOpinionsTablePageSize(pageSize)), [dispatch]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'opinionJurisdiction', header: 'Jurisdiction', field: 'jurisdiction', width: 0.2, canFilter: true, canSort: true, component: 'tooltip' },
        { id: 'focus', header: 'Focus', field: 'focus', width: 0.2, canFilter: true, component: 'tooltip', canSort: true },
        { id: 'scope', header: 'Scope', field: 'scope', width: 0.2, component: 'tooltip', canFilter: true, valueFormatter: scopeFormatter, canSort: true },
        { id: 'commissionedBy', header: 'Commissioned By', field: 'commissionedBy', width: 0.2, valueFormatter: commissionedByFormatter, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'dateOfOpinion', header: 'Date of Opinion', field: 'dateOfOpinion', width: 0.2, valueFormatter: dateFormatter, canSort: true }
    ];

    const opinionJurisdictionOptions = useMemo(() => {
        const jurisdictionList = dropdownLists.find(({ name }) => name === 'OpinionJurisdiction');
        return jurisdictionList ? jurisdictionList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const commissionedByOptions = useMemo(() => opinionCommissionedByOptions(), []);
    const scopeOptions = useMemo(() => opinionScopeOptions(), []);

    const filterDropdownOptions = useMemo(() => ({
        scope: scopeOptions,
        commissionedBy: commissionedByOptions,
        jurisdiction: opinionJurisdictionOptions,
        focus: allFocusOptions
    }), [opinionJurisdictionOptions, commissionedByOptions, scopeOptions]);

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setSelectedOpinionsTableColumnSort(columnSort)), [dispatch]);

    if (hideTable) {
        return null;
    }

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={selectedOpinionsData}
            testId='admin-opinions'
            isLoading={isLoading}
            page={pageNumber}
            total={totalOpinions}
            next={nextPage}
            previous={previousPage}
            filters={filters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            pageSize={pageSize}
            columnSort={columnSort}
            filterDropdownOptions={filterDropdownOptions}
            removeTextFilter={['scope', 'focus', 'commissionedBy']}
            toggleSort={toggleColumnSort}
            updatePageSize={updatePageSize}
            width={tableWidth}
            height={tableHeight}
        />
    );
};
