import { FunctionMigration } from '../../../dora/my-companies/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';

export enum EntityActionTypes {
    TOGGLE_ENTITY_MODAL = 'TOGGLE_ENTITY_MODAL',
    UPDATE_ENTITY_VALUE = 'UPDATE_ENTITY_VALUE',
    UPDATE_ENTITY_CONTENT = 'UPDATE_ENTITY_CONTENT',
    UPDATE_ENTITY_AI_FIELD = 'UPDATE_ENTITY_AI_FIELD',
    UPSERT_ENTITY_HANDLER = 'UPSERT_ENTITY_HANDLER',
    UPSERT_ENTITY_STARTED = 'UPSERT_ENTITY_STARTED',
    UPSERT_ENTITY_SUCCESSFUL = 'UPSERT_ENTITY_SUCCESSFUL',
    UPSERT_ENTITY_FAILED = 'UPSERT_ENTITY_FAILED',
    CHECK_DORA_SUPPLY_CHAIN_ENTITY_STARTED = 'CHECK_DORA_SUPPLY_CHAIN_ENTITY_STARTED',
    CHECK_DORA_SUPPLY_CHAIN_ENTITY_SUCCESSFUL = 'CHECK_DORA_SUPPLY_CHAIN_ENTITY_SUCCESSFUL',
    CHECK_DORA_SUPPLY_CHAIN_ENTITY_FAILED = 'CHECK_DORA_SUPPLY_CHAIN_ENTITY_FAILED',
    SET_SEARCH_ENTITY_VALUE = 'SET_SEARCH_ENTITY_VALUE',
    SET_SEARCH_ENTITY_TYPE = 'SET_SEARCH_ENTITY_TYPE',
    SEARCH_ENTITIES_STARTED = 'SEARCH_ENTITIES_STARTED',
    SEARCH_ENTITIES_SUCCESSFUL = 'SEARCH_ENTITIES_SUCCESSFUL',
    SEARCH_ENTITIES_FAILED = 'SEARCH_ENTITIES_FAILED',
    CLASSIFY_SOFT_SEARCH_ENTITY_STARTED = 'CLASSIFY_SOFT_SEARCH_ENTITY_STARTED',
    CLASSIFY_SOFT_SEARCH_ENTITY_SUCCESSFUL = 'CLASSIFY_SOFT_SEARCH_ENTITY_SUCCESSFUL',
    CLASSIFY_SOFT_SEARCH_ENTITY_FAILED = 'CLASSIFY_SOFT_SEARCH_ENTITY_FAILED',
    FETCH_ALL_ENTITIES_STARTED = 'FETCH_ALL_ENTITIES_STARTED',
    FETCH_ALL_ENTITIES_SUCCESSFUL = 'FETCH_ALL_ENTITIES_SUCCESSFUL',
    FETCH_ALL_ENTITIES_FAILED = 'FETCH_ALL_ENTITIES_FAILED',
    FETCH_ALL_NETTING_ENTITIES_STARTED = 'FETCH_ALL_NETTING_ENTITIES_STARTED',
    FETCH_ALL_NETTING_ENTITIES_SUCCESSFUL = 'FETCH_ALL_NETTING_ENTITIES_SUCCESSFUL',
    FETCH_ALL_NETTING_ENTITIES_FAILED = 'FETCH_ALL_NETTING_ENTITIES_FAILED',
    FETCH_PAGINATED_ENTITIES_STARTED = 'FETCH_PAGINATED_ENTITIES_STARTED',
    FETCH_PAGINATED_ENTITIES_SUCCESSFUL = 'FETCH_PAGINATED_ENTITIES_SUCCESSFUL',
    FETCH_PAGINATED_ENTITIES_FAILED = 'FETCH_PAGINATED_ENTITIES_FAILED',
    UPDATE_ADDRESS_ENTITY_VALUE = 'UPDATE_ADDRESS_ENTITY_VALUE',
    ADD_ALTERNATIVE_ADDRESS = 'ADD_ALTERNATIVE_ADDRESS',
    REMOVE_ALTERNATIVE_ADDRESS = 'REMOVE_ALTERNATIVE_ADDRESS',
    ADD_COMPANY_BIC_CODE = 'ADD_COMPANY_BIC_CODE',
    REMOVE_COMPANY_BIC_CODE = 'REMOVE_COMPANY_BIC_CODE',
    ADD_LEI_ENTITY_STARTED = 'ADD_LEI_ENTITY_STARTED',
    ADD_LEI_ENTITY_SUCCESSFUL = 'ADD_LEI_ENTITY_SUCCESSFUL',
    ADD_LEI_ENTITY_FAILED = 'ADD_LEI_ENTITY_FAILED',
    SELECT_ENTITY_TYPE = 'SELECT_ENTITY_TYPE',
    SET_ENTITY_TABLE_FILTERS = 'SET_ENTITY_TABLE_FILTERS',
    SET_ENTITY_TABLE_COLUMN_SORT = 'SET_ENTITY_TABLE_COLUMN_SORT',
    CLEAR_ENTITY_TABLE_FILTERS = 'CLEAR_ENTITY_TABLE_FILTERS',
    SET_ENTITIES_PAGE_SIZE = 'SET_ENTITIES_PAGE_SIZE',
    ENTITIES_PAGINATION_NEXT = 'ENTITIES_PAGINATION_NEXT',
    ENTITIES_PAGINATION_PREVIOUS = 'ENTITIES_PAGINATION_PREVIOUS',
    SET_ENTITIES_SEARCH_PAGE_SIZE = 'SET_ENTITIES_SEARCH_PAGE_SIZE',
    ENTITIES_SEARCH_PAGINATION_NEXT = 'ENTITIES_SEARCH_PAGINATION_NEXT',
    ENTITIES_SEARCH_PAGINATION_PREVIOUS = 'ENTITIES_SEARCH_PAGINATION_PREVIOUS',
    RESET_ENTITY_SEARCH = 'RESET_ENTITY_SEARCH',
    FETCH_MY_COMPANY_ENTITIES_STARTED = 'FETCH_MY_COMPANY_ENTITIES_STARTED',
    FETCH_MY_COMPANY_ENTITIES_SUCCESSFUL = 'FETCH_MY_COMPANY_ENTITIES_SUCCESSFUL',
    FETCH_MY_COMPANY_ENTITIES_FAILED = 'FETCH_MY_COMPANY_ENTITIES_FAILED',
    FETCH_MY_GROUP_ENTITIES_STARTED = 'FETCH_MY_GROUP_ENTITIES_STARTED',
    FETCH_MY_GROUP_ENTITIES_SUCCESSFUL = 'FETCH_MY_GROUP_ENTITIES_SUCCESSFUL',
    FETCH_MY_GROUP_ENTITIES_FAILED = 'FETCH_MY_GROUP_ENTITIES_FAILED',
    UPDATE_GROUP_ENTITY_IDS = 'UPDATE_GROUP_ENTITY_IDS',
    SET_COMPANY_ENTITY_GROUP_IDS = 'SET_COMPANY_ENTITY_GROUP_IDS',
    SELECT_COMPANY_ENTITY_SECTION = 'SELECT_COMPANY_ENTITY_SECTION',
    CHECK_BRANCHES_FUZZY_MATCH = 'CHECK_BRANCHES_FUZZY_MATCH',
    UPDATE_BRANCHES_FUZZY_MATCH_RESULTS = 'UPDATE_BRANCHES_FUZZY_MATCH_RESULTS',
    SET_BRANCHES_FUZZY_MATCH_MODAL_OPEN = 'SET_BRANCHES_FUZZY_MATCH_MODAL_OPEN',
    UPDATE_ENTITY_BRANCH_INFORMATION = 'UPDATE_ENTITY_BRANCH_INFORMATION',
    ADD_COMPANY_ENTITY_BRANCH = 'ADD_COMPANY_ENTITY_BRANCH',
    DELETE_COMPANY_ENTITY_BRANCH = 'DELETE_COMPANY_ENTITY_BRANCH',
    FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_STARTED = 'FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_STARTED',
    FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_SUCCESSFUL = 'FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_SUCCESSFUL',
    FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_FAILED = 'FETCH_ALL_COMPANY_CLASSIFICATION_ENTITIES_FAILED',
    ASSERT_ENTITY_CLASSIFICATION_STARTED = 'ASSERT_ENTITY_CLASSIFICATION_STARTED',
    ASSERT_ENTITY_CLASSIFICATION_SUCCESSFUL = 'ASSERT_ENTITY_CLASSIFICATION_SUCCESSFUL',
    ASSERT_ENTITY_CLASSIFICATION_FAILED = 'ASSERT_ENTITY_CLASSIFICATION_FAILED',
    SET_ENTITY_CLASSIFICATION_FILTER = 'SET_ENTITY_CLASSIFICATION_FILTER',
    TOGGLE_ENTITY_CLASSIFICATION_COMPLETED = 'TOGGLE_ENTITY_CLASSIFICATION_COMPLETED',
    UPDATE_ENTITY_CLASSIFICATION_FIELD = 'UPDATE_ENTITY_CLASSIFICATION_FIELD',
    SET_ENTITY_CLASSIFICATION_HAS_UPDATED = 'SET_ENTITY_CLASSIFICATION_HAS_UPDATED',
    TOGGLE_ENTITY_CLASSIFICATION_CLOSE_CONFIRMATION_MODAL_OPEN = 'TOGGLE_ENTITY_CLASSIFICATION_CLOSE_CONFIRMATION_MODAL_OPEN',
    UPDATE_CLASSIFICATION_ENTITIES_STARTED = 'UPDATE_CLASSIFICATION_ENTITIES_STARTED',
    UPDATE_CLASSIFICATION_ENTITIES_SUCCESSFUL = 'UPDATE_CLASSIFICATION_ENTITIES_SUCCESSFUL',
    UPDATE_CLASSIFICATION_ENTITIES_FAILED = 'UPDATE_CLASSIFICATION_ENTITIES_FAILED',
    FETCH_FUZZY_AND_AUTOCOMPLETIONS_STARTED = 'FETCH_FUZZY_AND_AUTOCOMPLETIONS_STARTED',
    FETCH_FUZZY_AND_AUTOCOMPLETIONS_SUCCESSFUL = 'FETCH_FUZZY_AND_AUTOCOMPLETIONS_SUCCESSFUL',
    FETCH_FUZZY_AND_AUTOCOMPLETIONS_FAILED = 'FETCH_FUZZY_AND_AUTOCOMPLETIONS_FAILED',
    FETCH_OPINION_SUB_COUNTERPARTY_TYPES_STARTED = 'FETCH_OPINION_SUB_COUNTERPARTY_TYPES_STARTED',
    FETCH_OPINION_SUB_COUNTERPARTY_TYPES_SUCCESSFUL = 'FETCH_OPINION_SUB_COUNTERPARTY_TYPES_SUCCESSFUL',
    FETCH_OPINION_SUB_COUNTERPARTY_TYPES_FAILED = 'FETCH_OPINION_SUB_COUNTERPARTY_TYPES_FAILED',
    UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_STARTED = 'UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_STARTED',
    UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_SUCCESSFUL = 'UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_SUCCESSFUL',
    UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_FAILED = 'UPSERT_OPINION_SUB_COUNTERPARTY_TYPES_FAILED',
    UPDATE_OPINION_SUB_COUNTERPARTY_VALUE = 'UPDATE_OPINION_SUB_COUNTERPARTY_VALUE',
    SET_SELECTED_OPINION_SUB_COUNTERPARTY = 'SET_SELECTED_OPINION_SUB_COUNTERPARTY',
    SET_SUB_COUNTERPARTY_JURISDICTION = 'SET_SUB_COUNTERPARTY_JURISDICTION',
    SET_SUB_COUNTERPARTY_PARENT = 'SET_SUB_COUNTERPARTY_PARENT',
    // Dora Entity Specific
    TOGGLE_MIGRATE_DORA_FUNCTION_CONFIRMATION_MODAL = 'TOGGLE_MIGRATE_DORA_FUNCTION_CONFIRMATION_MODAL',
    FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_STARTED = 'FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_STARTED',
    FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_SUCCESSFUL = 'FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_SUCCESSFUL',
    FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_FAILED = 'FETCH_AVAILABLE_DORA_FUNCTION_MIGRATION_OPTIONS_FAILED',
    UPDATE_DORA_COMPANY_FUNCTION_MIGRATIONS = 'UPDATE_DORA_COMPANY_FUNCTION_MIGRATIONS',
    MIGRATE_DORA_COMPANY_FUNCTIONS_STARTED = 'MIGRATE_DORA_COMPANY_FUNCTIONS_STARTED',
    MIGRATE_DORA_COMPANY_FUNCTIONS_SUCCESSFUL = 'MIGRATE_DORA_COMPANY_FUNCTIONS_SUCCESSFUL',
    MIGRATE_DORA_COMPANY_FUNCTIONS_FAILED = 'MIGRATE_DORA_COMPANY_FUNCTIONS_FAILED'
}

export type EntityAIFieldKey = 'MiFIDClassification' | 'emirClassification' | 'counterpartyType' | 'opinionCounterpartyType' | 'entityServiceType';

export enum EntityModalType {
    CREATE = 'create',
    SEARCH = 'search',
    ENTITY_CLASSIFICATION = 'entity classification'
}

export enum SearchEntityBy {
    LEI = 'lei',
    COMPANY_NAME = 'company name'
}

export enum EntityType {
    COMPANY = 'company',
    PERSON = 'person',
    GROUP = 'group'
}

export enum AddressType {
    BUSINESS = 'business',
    REGISTERED = 'registered'
}

export enum CompanyEntitySection {
    GENERAL = 'General',
    CONTACT = 'Contact',
    BRANCHES = 'Branches',
    DETAILS = 'Details',
    DORA = 'DORA'
}

export interface Address {
    address1: string;
    address2?: string;
    city: string;
    country: string;
    postalCode: string;
    addressType?: AddressType | null;
}

export interface GLEIOtherName {
    name: string | null;
    type: string | null;
    language: string | null;
}

export interface Lei {
    lei: string;
    legalName: string;
    otherNames: GLEIOtherName[];
    legalAddress: Address;
    headquartersAddress: Address;
    jurisdiction: string | null;
    associatedEntity: {
        lei: string | null;
        name: string | null;
    }
    otherAddresses: Address[];
    bic: string[] | null;
    entityExists: boolean;
}

export interface CompanyEntityBranch {
    city: string;
    name: string;
    country: string | null;
    lei: string;
    alternativeIdentifier?: string | null;
    inScopeForDora?: boolean;
}

export interface AIGeneratedEntityField {
    value: string | null;
    aiValue: string | null;
    aiPopulated: boolean;
    aiConfirmed: boolean;
}

export interface CompanyEntityContent {
    lei?: string;
    euid?: string;
    companyNumber?: string;
    address?: Address;
    jurisdiction?: string | null;
    opinionJurisdiction?: string | null;
    bic?: string[];
    otherNames: string[];
    MiFIDClassification?: AIGeneratedEntityField;
    emirClassification?: AIGeneratedEntityField;
    otherAddresses?: Address[];
    counterpartyType?: AIGeneratedEntityField;
    opinionCounterpartyType?: AIGeneratedEntityField;
    opinionSubCounterpartyType: number | null;
    email: string;
    phoneNumber: string;
    mobileNumber: string;
    faxNumber: string;
    primaryContact: string;
    entityServiceType?: AIGeneratedEntityField;
    branches: CompanyEntityBranch[];
    dateOfRoiIntegration: string;
    dateOfRoiDeletion: string;
    competentAuthority: string | null;
    otherCompetentAuthority?: string;
    doraEntityHierarchy: string | null;
    totalAssetsValue: number | null;
}

export interface PersonEntityContent {
    title: string | null;
    forenames: string;
    surname: string;
    position: string;
    company: string | null;
    address: Address;
    email: string;
    phoneNumber: string;
    mobileNumber: string;
    faxNumber: string;
    otherAddresses?: Address[];
}

export interface GroupEntityContent {
    description: string;
}

export interface CompanyEntity {
    entityId: number | null;
    name: string;
    type: EntityType.COMPANY;
    content: CompanyEntityContent;
    myCompany: number;
    doraInScope: number;
    doraMaintainerROI: number;
    onWatchlist: number;
    canBeAgent: number;
    isDoraThirdParty: number;
    isActiveThirdParty?: number;
}

export interface CompanyEntityDB extends CompanyEntity {
    active: number;
    clientId: number;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
}

export interface PersonEntity {
    entityId: number | null;
    name: string;
    type: EntityType.PERSON;
    content: PersonEntityContent;
    myCompany: number;
    doraInScope: number;
    doraMaintainerROI: number;
    onWatchlist: number;
    canBeAgent: number;
    isDoraThirdParty: number;
    isActiveThirdParty?: number;
}

export interface PersonEntityDB extends PersonEntity {
    active: number;
    clientId: number;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
}

export interface GroupEntity {
    entityId: number | null;
    name: string;
    type: EntityType.GROUP;
    content: GroupEntityContent;
    myCompany: number;
    doraInScope: number;
    doraMaintainerROI: number;
    onWatchlist: number;
    canBeAgent: number;
    isDoraThirdParty: number;
    isActiveThirdParty?: number;
}

export type Entity = CompanyEntity | PersonEntity | GroupEntity;
export type EntityDB = CompanyEntityDB | PersonEntityDB;

export interface BaseEntity {
    entityId: number;
    name: string;
    type: EntityType;
    canBeAgent: number;
    onWatchlist: number;
    jurisdiction: string | null;
}

export interface ClassifySoftSearchEntityRequest {
    lei: string;
    name: string;
    city: string;
    country: string;
    jurisdiction: string | null;
}

export interface SoftSearchEntity {
    name: string;
    jurisdiction: string | null;
    opinionCounterpartyType: AIGeneratedEntityField | null;
}

export interface NettingEngineEntity extends SoftSearchEntity {
    entityId: number;
}

export interface EntitiesForGroups {
    groupEntityId: number;
    entityIds: string[];
}

export interface BranchesFuzzyMatch {
    value: string;
    score: number;
}

export interface ClassificationCompanyEntity {
    entityId: number;
    name: string;
    counterpartyType?: AIGeneratedEntityField;
    opinionCounterpartyType?: AIGeneratedEntityField;
    entityServiceType?: AIGeneratedEntityField;
    MiFIDClassification?: AIGeneratedEntityField;
    emirClassification?: AIGeneratedEntityField;
    lei?: string;
}

export interface FuzzyAutoCompletions {
    autoCompletions: string[];
    fuzzyCompletions: string[];
}

export interface SubCounterpartyType {
    subCounterpartyTypeId?: number;
    subCounterpartyName: string;
    jurisdiction: string;
    parentCounterpartyType: string;
    isSystem: number;
}

export interface SubCounterpartyTypeDB extends SubCounterpartyType {
    createdBy: number;
    createdDate: string;
    clientId: number;
}

export interface EntityState {
    modalOpen: EntityModalType | null;
    isSaving: boolean;
    currentEntity: Entity | null;
    savedEntity: Entity | null;
    uploadError: string | null;
    searchingEntities: boolean;
    searchError: string | null;
    searchResults: Lei[];
    paginatedEntities: EntityDB[];
    allEntities: BaseEntity[];
    nettingEntities: NettingEngineEntity[];
    entityPage: number;
    totalEntities: number;
    entityFilters: TableFilters;
    entitySort?: ColumnSort;
    entityPageSize: number;
    fetchingEntities: boolean;
    fetchError: string | null;
    saveError: string | null;
    searchEntityPage: number;
    searchEntityPageSize: number;
    totalSearchEntities: number;
    searchInput: string;
    searchBy: SearchEntityBy;
    myCompanyEntities: BaseEntity[];
    groupEntities: GroupEntity[];
    entityIdsForGroup: string[] | null;
    companyEntityGroupIds: string[];
    doraFunctionMigration: FunctionMigration | null;
    selectedCompanySection: CompanyEntitySection;
    fuzzyMatchResults: BranchesFuzzyMatch[];
    fuzzyMatchModalOpen: number | null;
    currentClassificationEntities: ClassificationCompanyEntity[];
    savedClassificationEntities: ClassificationCompanyEntity[];
    fetchingClassificationEntities: boolean;
    assertingEntityClassification: number[];
    classificationFilter: string;
    showCompleted: boolean;
    entityClassificationHasUpdated: boolean;
    closeClassificationConfirmationModalOpen: boolean;
    fuzzyCompletions: string[];
    autoCompletions: string[];
    showCompletions: boolean;
    availableOpinionSubCounterpartyTypes: SubCounterpartyType[];
    selectedSubCounterparty: SubCounterpartyType;
    jurisdiction: string | null;
    parentCounterparty: string;
    isFetchingSubCounterpartyTypes: boolean;
}
