import React from 'react';

import { DateFilter, SearchFilter } from '../../search/store';
import { DocumentFilter } from '../../../shared/filter/DocumentFilter';
import styles from '../Reports.module.scss';

interface DocumentReportFilterProps {
    reportFilters: SearchFilter;
    updateFilter: (key: keyof SearchFilter, value: string[] | string | null | boolean) => void;
    updateDateFilter: (key: keyof DateFilter, value: string | null) => void;
}

export const DocumentReportFilter: React.FC<DocumentReportFilterProps> = ({ reportFilters, updateFilter, updateDateFilter }) => {
    const { date: { to, from }, isExclusiveEntityFilter, entityId, includePreExecution, documentNameGroupId } = reportFilters;
    return (
        <>
            <div className={styles.filterHeader}>Filters</div>
            <div className={styles.filtersDescription}>Select any filters you would like to apply to this report, if left blank then all documents will be included</div>
            <div className={styles.filterWrapper}>
                <DocumentFilter
                    to={to}
                    from={from}
                    entityId={entityId}
                    isExclusiveEntityFilter={isExclusiveEntityFilter}
                    includePreExecution={includePreExecution}
                    showDocumentNameFilter={false}
                    updateDateFilter={updateDateFilter}
                    updateFilter={updateFilter}
                    fontSize='14px'
                    documentNameGroupId={documentNameGroupId}
                />
            </div>
        </>
    );
};
