import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { OverflowTooltip } from '../../../shared/tooltip';
import styles from './Theoretical.module.scss';
import { NettingEngineSurvey, NettingEngineSubQuestion, NettingEngineQuestionType, NettingEngineTheory, setSurveyQuestionIndex, getShowSummary, getLeiModalOpen, setLeiModalOpen, productsTradedOptions, setIsChangingQuestion } from '../store';
import { getAllDropdownLists } from '../../../admin/dropdown-lists/store';
import { nettingEngineAgreementOptions, counterpartyCoverageOptions } from '../Utils';
import { classifySoftSearchEntityStarted, getNettingEntities, Lei, searchEntitiesStarted } from '../../../admin/entity/store';
import { SearchEntityModal } from '../../../admin/entity/SearchEntityModal';
import { TheoreticalSurveyQuestions } from './TheoreticalSurveyQuestions';
import { TheoreticalSurveySlider } from './TheoreticalSurveySlider';
import { TheoreticalSurveyButtons } from './TheoreticalSurveyButtons';

interface TheoreticalSurveyProps {
    surveyQuestionIndex: number;
    surveyAnswers: NettingEngineTheory;
}

export const TheoreticalSurvey: React.FC<TheoreticalSurveyProps> = ({
    surveyQuestionIndex,
    surveyAnswers
}) => {
    const { counterpartyNameKnown, counterparty, agreement, governingLaw, productTypeKnown, productsTraded, counterpartyIsMultiBranch, counterpartyOffices } = surveyAnswers;

    const dispatch = useAppDispatch();

    const dropdownLists = useAppSelector(getAllDropdownLists);
    const showSummary = useAppSelector(getShowSummary);
    const allEntities = useAppSelector(getNettingEntities);

    const [selectedEntity, setSelectedEntity] = useState<Lei | null>(null);

    const setQuestionIndex = useCallback((index: number) => dispatch(setSurveyQuestionIndex(index)), [dispatch]);

    const getDropdownListOptions = useCallback((listName: string) => {
        const dropdownList = dropdownLists.find(({ name }) => name === listName);
        return dropdownList ? dropdownList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const jurisdictionOptions = getDropdownListOptions('OpinionJurisdiction');
    const cityOptions = getDropdownListOptions('City');
    const filteredCityOptions = cityOptions.filter(({ label }) => label !== 'Other');
    const [counterpartyOfficeOptions, setCounterpartyOfficeOptions] = useState(filteredCityOptions);
    const governingLawOptions = getDropdownListOptions('GoverningLaw');

    const entityOptions = allEntities.map(({ name, entityId }) => ({ value: entityId!.toString(), label: name }));

    const getDropdownValueForAnswer = useCallback((answer: string | null) => isNull(answer) ? null : { value: answer, label: answer }, []);
    const getCounterpartyAnswer = useCallback((entityId: string | null) => {
        if (!isNull(selectedEntity)) {
            return { value: selectedEntity.legalName, label: selectedEntity.legalName };
        } else {
            return isNull(entityId) ? null : { value: entityId, label: allEntities.find(entity => entity.entityId === parseInt(entityId))!.name };
        }
    }, [allEntities, selectedEntity]);
    const getMultiDropdownValueForAnswer = useCallback((answer: string[] | null) => (isNull(answer) || answer.length === 0) ? null : answer.map(answer => ({ value: answer, label: answer })), []);

    const counterpartyQuestion: NettingEngineSurvey = useMemo(() => ({ question: 'What is the name of your counterparty?', type: NettingEngineQuestionType.DROPDOWN, dropdownOptions: entityOptions, value: getCounterpartyAnswer(counterparty.entityId), key: 'counterparty', counterpartyKey: 'entityId' }), [entityOptions, getCounterpartyAnswer, counterparty]);
    const counterpartySubQuestion: NettingEngineSubQuestion = useMemo(() => ({ question: 'What is the Jurisdiction of your counterparty?', value: getDropdownValueForAnswer(counterparty.jurisdiction), dropdownOptions: jurisdictionOptions, key: 'counterparty', counterpartyKey: 'jurisdiction' }), [counterparty, getDropdownValueForAnswer, jurisdictionOptions]);

    const productsTradedDropdownOptions: DropdownOption[] = useMemo(() => productsTradedOptions.map(value => ({ value, label: value })), []);
    const unknownCounterpartyQuestion: NettingEngineSurvey = useMemo(() => ({ question: 'What is the Jurisdiction of your counterparty?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(counterparty.jurisdiction), dropdownOptions: jurisdictionOptions, key: 'counterparty', counterpartyKey: 'jurisdiction' }), [counterparty, getDropdownValueForAnswer, jurisdictionOptions]);

    const nettingEngineSurvey: NettingEngineSurvey[] = useMemo(() => [
        { question: 'Do you know the name of your counterparty', type: NettingEngineQuestionType.BOOLEAN, value: counterpartyNameKnown, key: 'counterpartyNameKnown' },
        counterpartyNameKnown ? counterpartyQuestion : unknownCounterpartyQuestion,
        { question: 'What is your counterparty’s classification?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(counterparty.counterpartyClassification), dropdownOptions: counterpartyCoverageOptions, key: 'counterparty', counterpartyKey: 'counterpartyClassification' },
        { question: 'What agreement do you intend to put in place with your counterparty?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(agreement), dropdownOptions: nettingEngineAgreementOptions, key: 'agreement' },
        { question: 'What is the governing law of your agreement?', type: NettingEngineQuestionType.DROPDOWN, value: getDropdownValueForAnswer(governingLaw), dropdownOptions: governingLawOptions, key: 'governingLaw' },
        { question: 'Do you know which product types are to be traded under the relevant agreement?', type: NettingEngineQuestionType.BOOLEAN, value: productTypeKnown, key: 'productTypeKnown' },
        { question: 'Select product types to be traded', type: NettingEngineQuestionType.DROPDOWN, dropdownOptions: productsTradedDropdownOptions, value: getMultiDropdownValueForAnswer(productsTraded), key: 'productsTraded', isMulti: true, closeOnSelect: false },
        { question: 'Is your counterparty acting on a multi-branch basis?', type: NettingEngineQuestionType.BOOLEAN, value: counterpartyIsMultiBranch, key: 'counterpartyIsMultiBranch' },
        { question: 'Please provide a list of all of the offices through which your counterparty may act.', type: NettingEngineQuestionType.DROPDOWN, dropdownOptions: counterpartyOfficeOptions, value: getMultiDropdownValueForAnswer(counterpartyOffices), key: 'counterpartyOffices', isMulti: true, closeOnSelect: false },
    ], [counterpartyNameKnown, agreement, governingLaw, productTypeKnown, productsTraded, productsTradedDropdownOptions, counterpartyOfficeOptions, counterpartyIsMultiBranch, counterpartyOffices, getDropdownValueForAnswer, getMultiDropdownValueForAnswer, governingLawOptions, unknownCounterpartyQuestion, counterpartyQuestion, counterparty]);

    const getSummaryAnswer = useCallback((answer: boolean | DropdownOption | DropdownOption[] | null, type: NettingEngineQuestionType, isMulti?: boolean) => {
        if (isNull(answer)) return '';
        if (type === NettingEngineQuestionType.BOOLEAN) return !answer ? 'No' : 'Yes';
        if (isMulti) return (answer as DropdownOption[]).map(({ label }) => label);
        return (answer as DropdownOption).label;
    }, []);

    const leiModalOpen = useAppSelector(getLeiModalOpen);
    const closeEntityModal = useCallback(() => dispatch(setLeiModalOpen(false)), [dispatch]);
    const searchEntities = () => dispatch(searchEntitiesStarted());

    const softSaveEntity = useCallback((entity: Lei) => {
        setSelectedEntity(entity);
        dispatch(classifySoftSearchEntityStarted({
            name: entity.legalName,
            lei: entity.lei,
            city: entity.legalAddress.city,
            country: entity.legalAddress.country,
            jurisdiction: entity.jurisdiction
        }));
        setTimeout(() => {
            setQuestionIndex(surveyQuestionIndex + 1);
            dispatch(setIsChangingQuestion(false));
        }, 750);
    }, [dispatch, setQuestionIndex, surveyQuestionIndex]);

    const updateCounterpartyOfficeOptions = useCallback((options: DropdownOption[]) => setCounterpartyOfficeOptions(options), []);

    return (
        <div className={styles.survey}>
            <div className={styles.surveyWrapper}>
                {!showSummary &&
                    <>
                        <TheoreticalSurveySlider
                            surveyQuestionIndex={surveyQuestionIndex}
                            nettingEngineSurvey={nettingEngineSurvey}
                            setQuestionIndex={setQuestionIndex}
                        />
                        <TheoreticalSurveyQuestions
                            surveyQuestionIndex={surveyQuestionIndex}
                            nettingEngineSurvey={nettingEngineSurvey}
                            counterpartySubQuestion={counterpartySubQuestion}
                            allEntities={allEntities}
                            setQuestionIndex={setQuestionIndex}
                            counterpartyNameKnown={counterpartyNameKnown}
                            jurisdictionOptions={jurisdictionOptions}
                            updateCounterpartyOfficeOptions={updateCounterpartyOfficeOptions}
                        />
                    </>
                }
                {showSummary &&
                    <div className={styles.surveySummaryWrapper}>
                        <div className={styles.summaryTitle}>Summary</div>
                        {nettingEngineSurvey.map(({ question, value, type, isMulti }, index) => {
                            return (
                                <div className={styles.surveySummary} key={index}>
                                    <OverflowTooltip className={styles.surveySummaryQuestion} overlayText={`Q: ${question}`} />
                                    <OverflowTooltip className={styles.surveySummaryAnswer} overlayText={`A: ${getSummaryAnswer(value, type, isMulti)}`} />
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
            <TheoreticalSurveyButtons
                surveyQuestionIndex={surveyQuestionIndex}
                nettingEngineSurvey={nettingEngineSurvey}
                setQuestionIndex={setQuestionIndex}
            />
            <SearchEntityModal
                searchEntities={searchEntities}
                closeModal={closeEntityModal}
                isOpen={leiModalOpen}
                softSaveEntity={softSaveEntity}
                selectedEntity={selectedEntity}
            />
        </div>
    );
};
