import React from 'react';
import Modal from 'react-modal';

import { Form } from '../icons';
import { WizardTabProps } from './WizardTab';
import styles from './Wizard.module.scss';
import { Wizard, WizardButton } from './Wizard';

interface WizardModalProps {
    isOpen: boolean;
    closeWizard: () => void;
    closeOnOverlayClick?: boolean
    showOverlay?: boolean;
    buttons: WizardButton[];
    tabs: WizardTabProps[];
    headerIcon?: React.FC;
    title?: string;
    testId?: string;
    children?: React.ReactNode;
}

export const WizardModal: React.FC<WizardModalProps> = ({
    isOpen,
    closeWizard,
    closeOnOverlayClick = false,
    children,
    showOverlay = true,
    buttons,
    tabs,
    headerIcon = Form,
    title = 'Wizard',
    testId
}) => {
    const defaultOverlayStyle = showOverlay ? { display: 'flex', zIndex: 10 } : { display: 'flex', backgroundColor: 'transparent', zIndex: 10 };
    return (
        <Modal
            isOpen={isOpen}
            className={styles.wizard}
            ariaHideApp={false}
            style={{ overlay: defaultOverlayStyle }}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            shouldCloseOnEsc={closeOnOverlayClick}
            onRequestClose={closeWizard}
        >
            <Wizard
                children={children}
                buttons={buttons}
                tabs={tabs}
                headerIcon={headerIcon}
                title={title}
                testId={testId}
            />
        </Modal>
    );
};
