import React, { useCallback, useMemo } from 'react';
import { isNull, max, min } from 'lodash/fp';

import styles from '../Clients.module.scss';
import { Client } from '../store';
import { CustomTooltip, InformationTooltip } from '../../../shared/tooltip';
import { Number } from '../../../shared/number/Number';

const { green, lightestGrey, red, amber } = styles;

interface DocumentPageLimitsProps {
    freePages: number;
    maxPages: number | null;
    monthlyTotalPages: number;
    updateClientProperties: (key: keyof Client, value: number | null) => void;
}

export const DocumentPageLimits: React.FC<DocumentPageLimitsProps> = ({ freePages, maxPages, updateClientProperties, monthlyTotalPages }) => {
    const updateFreePages = useCallback((value: number | undefined) => updateClientProperties('freePages', value || 0), [updateClientProperties]);
    const updateMaxPages = useCallback((value: number | undefined) => updateClientProperties('maxPages', value || null), [updateClientProperties]);

    const maxPagesNumber = useMemo(() => !isNull(maxPages) ? maxPages : freePages + max([(freePages * 4), (monthlyTotalPages / 2)])!, [maxPages, freePages, monthlyTotalPages]);
    const freeBarWidth = useMemo(() => freePages < 10 ? freePages : max([(freePages / maxPagesNumber) * 100, 10])!, [freePages, maxPagesNumber]);
    const paidBarWidth = useMemo(() => 100 - freeBarWidth, [freeBarWidth]);

    const freeBarFill = useMemo(() => min([((monthlyTotalPages / freePages) * 100), 100])!, [monthlyTotalPages, freePages]);
    const paidBarFill = useMemo(() => monthlyTotalPages > 0 ? min([((max([monthlyTotalPages - freePages, 0])! / (maxPagesNumber - freePages)) * 100), 100])! : 0, [monthlyTotalPages, freePages, maxPagesNumber]);
    const showRedWarning = useMemo(() => paidBarFill > 80, [paidBarFill]);

    const freeBarStyle = useMemo(() => ({ width: `calc(${freeBarWidth}% - ${freePages > 0 ? '1' : '0'}px)`, background: `linear-gradient(90deg, ${green} 0%, ${green} ${freeBarFill}%, ${lightestGrey} ${freeBarFill}%, ${lightestGrey} 100%)`, borderRight: `${freePages > 0 ? `1px solid ${green}` : ''}`, borderRadius: freePages === maxPages ? '5px' : '5px 0 0 5px' }), [freeBarFill, freeBarWidth, freePages, maxPages]);
    const paidBarStyle = useMemo(() => ({ width: `${paidBarWidth}%`, background: `linear-gradient(90deg, ${amber} 0%, ${amber} ${showRedWarning ? paidBarFill - 40 : paidBarFill}%, ${showRedWarning ? `${red} ${paidBarFill}%,` : ''} ${lightestGrey} ${paidBarFill}%, ${lightestGrey} 100%)`, borderRadius: freePages > 0 ? '0 5px 5px 0' : '5px' }), [paidBarFill, showRedWarning, paidBarWidth, freePages]);

    return (
        <div className={styles.documentPageLimitsWrapper}>
            <div className={styles.documentPageLimitsHeader}>
                <div className={styles.documentPageLimitsTitle}>Document Upload Page Limits (per month)</div>
                <CustomTooltip overlayText={`${monthlyTotalPages} pages uploaded this month`} placement='top'>
                    <div className={styles.documentPageLimitsBar}>
                        <div className={styles.freeUsageBar} style={freeBarStyle} />
                        <div className={styles.paidBar} style={paidBarStyle} />
                    </div>
                </CustomTooltip>
            </div>
            <div className={styles.documentPageLimits}>
                <div className={styles.freePagesWrapper}>
                    <div className={styles.freePagesHeader}>
                        <div className={styles.freePagesTitle}>Free Tier Pages</div>
                        <InformationTooltip content='Set the number of free pages that a client can run through AWS Textract within their license fee per month before being invoiced' />
                    </div>
                    <div className={styles.numberInputWrapper}>
                        <Number value={freePages} onChange={updateFreePages} allowDecimal={false} />
                    </div>
                </div>
                <div className={styles.maxPagesWrapper}>
                    <div className={styles.maxPagesHeader}>
                        <div className={styles.maxPagesTitle}>Max Pages</div>
                        <InformationTooltip content='A monthly limit of pages to be uploaded and ran through AWS Textract. When left empty there is no limit, which may be helpful at onboarding stage for a client.' />
                    </div>
                    <div className={styles.numberInputWrapper}>
                        <Number value={maxPages} onChange={updateMaxPages} placeholder='Unlimited' allowDecimal={false} />
                    </div>
                </div>
            </div>
        </div>
    );
};
