import React, { useCallback } from 'react';
import { isNull } from 'lodash/fp';

import { DocumentAnalyticsChart } from '../store';
import { DocumentSpreadChart } from './DocumentSpreadChart';
import { EntitySpreadChart } from './EntitySpreadChart';
import { IconButton } from '../../../shared/button/IconButton';
import { Sort } from '../../../shared/icons';
import styles from '../Analytics.module.scss';

interface DocumentSpreadChartProps {
    dimensions: {
        height: number;
        width: number;
    }
    analyticsSpinner: boolean;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    tileInView: DocumentAnalyticsChart | null;
}

export const DocumentEntitySpreadChart: React.FC<DocumentSpreadChartProps> = ({ dimensions, analyticsSpinner, tileInView, setTileInView }) => {
    const toggleTileView = useCallback(() => setTileInView(isNull(tileInView) ? DocumentAnalyticsChart.DOCUMENT_ENTITY_SPREAD : null), [setTileInView, tileInView]);
    return (
        <div className={styles.documentEntitySpreadWrapper}>
            <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
            <DocumentSpreadChart dimensions={dimensions} analyticsSpinner={analyticsSpinner} tileInView={tileInView} setTileInView={setTileInView} />
            <EntitySpreadChart dimensions={dimensions} analyticsSpinner={analyticsSpinner} tileInView={tileInView} setTileInView={setTileInView} />
        </div>
    );
};
