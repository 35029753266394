import React from 'react';

export const Section: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,108.7,108.7,114.1,102.1,114.1z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M100.5,54.9H77.7c-1.6,0-2.9-1.3-2.9-2.9V31.2c0-1.6,1.3-2.9,2.9-2.9h22.8c1.6,0,2.9,1.3,2.9,2.9V52C103.5,53.6,102.2,54.9,100.5,54.9z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M63.4,54.9H26.5c-1.6,0-2.9-1.3-2.9-2.9V31.2c0-1.6,1.3-2.9,2.9-2.9h36.9c1.6,0,2.9,1.3,2.9,2.9V52C66.3,53.6,65,54.9,63.4,54.9z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M93.2,96.6H34.8c-1.6,0-2.9-1.3-2.9-2.9V73c0-1.6,1.3-2.9,2.9-2.9h58.4c1.6,0,2.9,1.3,2.9,2.9v20.7C96.1,95.3,94.8,96.6,93.2,96.6z" />
    </svg>
);
