import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import { Attestation, AttestationManager } from '../../shared/icons';
import { TileSelect, Tile } from '../../shared/tile-select/TileSelect';
import { AttestationPage, setAttestationPage } from './store';

export const AttestationsSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectAttestationPage = useCallback((page: AttestationPage) => dispatch(setAttestationPage(page)), [dispatch]);
    const selectForms = useCallback(() => selectAttestationPage(AttestationPage.FORMS), [selectAttestationPage]);
    const selectManager = useCallback(() => selectAttestationPage(AttestationPage.MANAGER), [selectAttestationPage]);

    const attestationsTiles: Tile[] = useMemo(() => [
        {
            label: AttestationPage.FORMS,
            description: ['Create a new attestation form'],
            onClick: selectForms,
            testId: 'forms',
            icon: Attestation
        },
        {
            label: AttestationPage.MANAGER,
            description: ['Manage all of your existing attestations'],
            onClick: selectManager,
            testId: 'manager',
            icon: AttestationManager
        },
    ], [selectForms, selectManager]);

    return (
        <TileSelect testId='admin-attestations' tiles={attestationsTiles} />
    );
};
