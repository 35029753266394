import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { LinkDocumentTableModal } from '../../shared/modal/LinkDocumentTableModal';
import { documentColDefs } from '../../shared/table';
import {
    BaseDocumentToSupport,
    baseDocumentsPaginationNext,
    baseDocumentsPaginationPrevious,
    clearBaseDocumentTableFilters,
    getBaseDocumentFilters,
    getBaseDocumentPageSize,
    getBaseDocuments,
    getBaseDocumentsPageNumber,
    getIsLoading,
    getTotalBaseDocuments,
    setBaseDocumentTableFilters,
    setBaseDocumentsPageSize,
} from './store';
import { TableFilterType } from '../../shared/modal/TableFilterModal';

interface BaseDocumentsModalProps {
    baseDocuments?: BaseDocumentToSupport[];
    select: (value: number) => void;
    close: () => void;
}

export const BaseDocumentsModal: React.FC<BaseDocumentsModalProps> = ({ baseDocuments, select, close }) => {
    const dispatch = useAppDispatch();
    const documents = useAppSelector(getBaseDocuments);
    const isLoading = useAppSelector(getIsLoading);

    const pageNumber = useAppSelector(getBaseDocumentsPageNumber);
    const totalDocuments = useAppSelector(getTotalBaseDocuments);
    const filters = useAppSelector(getBaseDocumentFilters);
    const pageSize = useAppSelector(getBaseDocumentPageSize);
    const nextPage = useCallback(() => { dispatch(baseDocumentsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(baseDocumentsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setBaseDocumentTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearBaseDocumentTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setBaseDocumentsPageSize(pageSize)); }, [dispatch]);

    const selectedIds = useMemo(() => baseDocuments && baseDocuments.length ? baseDocuments.map(({ documentId }) => documentId) : null, [baseDocuments]);
    const selectDisabled = useCallback((id: number) => {
        const document = documents.find(({ documentId }) => documentId === id);
        return !!document && !!baseDocuments && !!baseDocuments.find(({ originalDocumentId, documentId }) => document.documentId === documentId || (document.originalDocumentId === documentId && isNull(originalDocumentId)));
    }, [baseDocuments, documents]);

    return (
        <LinkDocumentTableModal
            isOpen
            select={select}
            close={close}
            documents={documents}
            selectedIds={selectedIds}
            selectedField='documentId'
            columnDefs={documentColDefs}
            testId='upload-base-documents'
            title='Available Documents'
            page={pageNumber}
            total={totalDocuments}
            filters={filters}
            next={nextPage}
            previous={previousPage}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            isLoading={isLoading}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
            selectDisabled={selectDisabled}
        />
    );
};
