import React from 'react';

import { Position, PositionModal } from '../../../shared/modal/PositionModal';
import styles from './DatasetComponents.module.scss';

interface SystemIdModalProps {
    isOpen: boolean;
    closeModal: () => void;
    position: Position | null;
    systemId: string;
    updateSystemId: (val: string) => void;
    disableInputs: boolean;
}

export const SystemIdModal: React.FC<SystemIdModalProps> = ({ closeModal, isOpen, position, updateSystemId, systemId, disableInputs }) => (
    <PositionModal
        isOpen={isOpen}
        position={position}
        closeModal={closeModal}
        testId='system-id'
        height='30px'
        width='fit-content'
        maxWidth='400px'
    >
        <textarea
            style={{ resize: 'none' }}
            maxLength={500}
            className={styles.systemIdInput}
            placeholder='Add a system id...'
            value={systemId}
            onChange={e => updateSystemId(e.target.value)}
            data-testid='system-id-input'
            disabled={disableInputs}
        />
    </PositionModal>
);
