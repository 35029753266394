import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Pencil } from '../../../shared/icons';
import { getPlaybookPublishError, getPlaybookPublishModalOpen, getPlaybookPublishReason, publishPlaybookStarted, togglePlaybookPublishModalOpen, setPlaybookPublishReason, getPlaybookIsDraft, getCurrentPlaybook, savePlaybookStarted } from '../store';
import styles from '../Playbook.module.scss';
import { LongText } from '../../../shared/longtext/LongText';
import { DraftPublishToggle } from './DraftPublishToggle';

interface PlaybookPublishModalProps {
    testId?: string;
}

export const PlaybookPublishModal: React.FC<PlaybookPublishModalProps> = ({ testId = 'playbook-publish' }) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getPlaybookPublishModalOpen);
    const publishReason = useAppSelector(getPlaybookPublishReason);
    const publishError = useAppSelector(getPlaybookPublishError);
    const isDraft = useAppSelector(getPlaybookIsDraft);
    const currentPlaybook = useAppSelector(getCurrentPlaybook);

    const currentPlaybookIsDraft = useMemo(() => currentPlaybook && currentPlaybook.isDraft, [currentPlaybook]);

    const closeModal = () => dispatch(togglePlaybookPublishModalOpen(false));

    const updateReason = useCallback((value: string) => dispatch(setPlaybookPublishReason(value)), [dispatch]);

    const publishPlaybook = useCallback(() => currentPlaybookIsDraft ? dispatch(savePlaybookStarted()) : dispatch(publishPlaybookStarted()), [dispatch, currentPlaybookIsDraft]);

    const confirmLabel = useMemo(() => isDraft ? 'Publish as Draft' : 'Publish', [isDraft]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={publishPlaybook}
            showConfirm={true}
            showClose={true}
            closeOnOverlayClick={false}
            confirmLabel={confirmLabel}
            testId={testId}
        >
            <div className={styles.publishWrapper}>
                <ModalHeader icon={Pencil} label='Publish Playbook' testId={`${testId}-confirmation-modal`} />
                <LongText
                    onChange={updateReason}
                    value={publishReason}
                    placeholder='Optional...'
                    label='Reason'
                    maxLength={256}
                    width='calc(100% - 12px)'
                    testId={`${testId}-confirmation-modal-reason`}
                />
                <DraftPublishToggle testId={`${testId}-confirmation-modal-draft-toggle`} />
                <div className={styles.publishError} data-testid={`${testId}-confirmation-modal-error-message`}>{publishError && 'There was an error when trying to publish your playbook'}</div>
            </div>
        </ ConfirmationModal>
    );
};
