import React, { MouseEvent } from 'react';

import styles from './Button.module.scss';
import { Icon as ArkIcon } from '../icon/Icon';
import { Add } from '../icons';

interface PlusButtonProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    fontSize?: number;
    testId?: string;
}

export const PlusButton: React.FC<PlusButtonProps> = ({ onClick, disabled = false, fontSize, testId = '' }) => (
    <button
        onClick={onClick}
        className={styles.plusButton}
        disabled={disabled}
        data-testid={`plus-button-${testId}`}
    >
        <ArkIcon icon={Add} fontSize={fontSize} />
    </button>
);
