import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { MarginArrangements, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface MarginArrangementsProps {
    marginArrangements: MarginArrangements;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const MarginArrangementsSection: React.FC<MarginArrangementsProps> = ({ marginArrangements, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'marginArrangements';
    const { recharacterisedSecurityInterest, actionsContinuedTitle, otherToEnsureValidity, transferorRightToExchange, alternativeMarginMethods, transfereeConsent, transfereeRightsInsolvency, clawbackDuringSuspectPeriod } = marginArrangements;

    return (
        <SectionWrapper id={sectionId} label='Margin Arrangements' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.marginArrangementsSectionWrapper}>
                <SingleField
                    field={recharacterisedSecurityInterest}
                    fieldId='recharacterisedSecurityInterest'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={actionsContinuedTitle}
                    fieldId='actionsContinuedTitle'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={otherToEnsureValidity}
                    fieldId='otherToEnsureValidity'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={transferorRightToExchange}
                    fieldId='transferorRightToExchange'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={alternativeMarginMethods}
                    fieldId='alternativeMarginMethods'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={transfereeConsent}
                    fieldId='transfereeConsent'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={transfereeRightsInsolvency}
                    fieldId='transfereeRightsInsolvency'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={clawbackDuringSuspectPeriod}
                    fieldId='clawbackDuringSuspectPeriod'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
            </div>
        </SectionWrapper>
    );
};
