import React, { useCallback, useMemo, useState } from 'react';
import { Options } from 'react-select';
import { isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DATABASE_DATE_FORMAT, formatDate } from '../../../../utils/luxon';
import { getUserHasFeaturePermissionNoAdmin } from '../../../auth/login/store/selectors';
import { doraEntityServiceType } from '../../../constants/dora';
import { Number } from '../../../shared/number/Number';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Text } from '../../../shared/text/Text';
import { Toggle } from '../../../shared/toggle';
import { InformationTooltip } from '../../../shared/tooltip';
import { FeaturePermission } from '../../users/store';
import { AIGeneratedEntityField, CompanyEntity, CompanyEntityContent, EntityAIFieldKey, updateEntityAIField, updateEntityContent, updateEntityValue } from '../store';
import { AiDropdownField } from './AiDropdownField';
import styles from './EntityType.module.scss';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { DoraDescriptionModal } from '../../../shared/modal/DoraDescriptionModal';
import { DatePicker } from '../../../shared/datepicker/DatePicker';
import { competentAuthorities, registerOfInformationEntityHierarchy } from '../../../constants/entity';

interface CompanyEntityDoraSectionProps {
    entity: CompanyEntity;
}

const { red } = styles;

export const CompanyEntityDoraSection: React.FC<CompanyEntityDoraSectionProps> = ({ entity }) => {
    const dispatch = useAppDispatch();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));

    const { name, doraInScope, doraMaintainerROI, myCompany, isDoraThirdParty, content: { entityServiceType, dateOfRoiIntegration, dateOfRoiDeletion, competentAuthority, otherCompetentAuthority, doraEntityHierarchy, totalAssetsValue } } = entity;

    const doraMaintainerDisabledTooltip = useMemo(() => doraMaintainerROI ? [`${name} is the designated entity to maintain the Register of Information for Dora.`] : null, [doraMaintainerROI, name]);
    const entityTypeOptions = useMemo(() => doraEntityServiceType.map(({ type }) => type), []);
    const competentAuthorityOptions = useMemo(() => competentAuthorities.map(value => ({ value, label: value })), []);
    const competentAuthorityValue = useMemo(() => !isNull(competentAuthority) ? ({ value: competentAuthority, label: competentAuthority }) : null, [competentAuthority]);

    const toggleDoraInScope = useCallback((value: boolean) => dispatch(updateEntityValue('doraInScope', !value ? 0 : 1)), [dispatch]);
    const toggleMaintainsROI = useCallback((value: boolean) => dispatch(updateEntityValue('doraMaintainerROI', !value ? 0 : 1)), [dispatch]);
    const toggleIsThirdParty = useCallback((value: boolean) => dispatch(updateEntityValue('isDoraThirdParty', !value ? 0 : 1)), [dispatch]);

    const updateAIFieldContent = useCallback((key: EntityAIFieldKey, secondaryKey: keyof AIGeneratedEntityField, value: string | null | boolean) => dispatch(updateEntityAIField(key, secondaryKey, value)), [dispatch]);
    const updateCounterpartyType = (value: string | null) => updateAIFieldContent('entityServiceType', 'value', value);

    const updateDropdownOption = useCallback((option: DropdownOption | Options<DropdownOption> | null, key: keyof CompanyEntityContent) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        dispatch(updateEntityContent(key, selected));
    }, [dispatch]);

    const toggleDescriptionModal = useCallback(() => setModalOpen(!modalOpen), [modalOpen]);

    const integrationDate = dateOfRoiIntegration !== '' ? new Date(dateOfRoiIntegration) : null;
    const deletionDate = dateOfRoiDeletion !== '' ? new Date(dateOfRoiDeletion) : null;

    const updateDate = useCallback((value: Date | null, key: keyof CompanyEntityContent) => {
        const deadline = value ? formatDate(value, DATABASE_DATE_FORMAT) : '';
        dispatch(updateEntityContent(key, deadline));
    }, [dispatch]);

    const updateOtherCompetentAuthority = useCallback((value: string) => dispatch(updateEntityContent('otherCompetentAuthority', value)), [dispatch]);

    const showOtherCompetentAuthority = useMemo(() => !isNull(competentAuthority) && competentAuthority === 'Other', [competentAuthority]);

    const hierarchyValue = useMemo(() => !isNull(doraEntityHierarchy) ? { label: doraEntityHierarchy, value: doraEntityHierarchy } : null, [doraEntityHierarchy]);
    const hierarchyDropdownOptions: DropdownOption[] = useMemo(() => registerOfInformationEntityHierarchy.map(value => ({ label: value, value })), []);

    const updateTotalAssets = (value: number | null | undefined) => {
        const assetsValue = isUndefined(value) ? null : value;
        dispatch(updateEntityContent('totalAssetsValue', assetsValue));
    };

    const isActiveThirdParty = !!entity.isActiveThirdParty;
    const activeThirdPartyTooltip = 'This company is an active part of a DORA supply chain within your organisation. You must remove it from all supply chains before changing its ICT Third Party Service Provider status';

    return (
        <div className={styles.doraWrapper}>
            <Scrollable>
                <div className={styles.toggleWrapper}>
                    <div className={styles.toggleLabel}>Is the company in scope for DORA?</div>
                    <Toggle
                        checked={!!doraInScope}
                        onChange={toggleDoraInScope}
                        testId='entity-dora-in-scope'
                        disabled={!hasDoraFullAccessPermission || !myCompany}
                        disabledTooltip={doraMaintainerDisabledTooltip}
                    />
                </div>
                <div className={styles.toggleWrapper}>
                    <div className={styles.toggleLabel}>Is the company the maintainer for the Register of Information?</div>
                    <Toggle
                        checked={!!doraMaintainerROI}
                        onChange={toggleMaintainsROI}
                        testId='entity-dora-maintains-roi'
                        disabled={!hasDoraFullAccessPermission}
                    />
                </div>
                <div className={styles.toggleWrapper}>
                    <div className={styles.toggleLabel}>Is the company acting as an ICT Third Party Service Provider?</div>
                    <Toggle
                        checked={!!isDoraThirdParty}
                        onChange={toggleIsThirdParty}
                        testId='entity-ict-third-party'
                        disabled={!hasDoraFullAccessPermission || !!myCompany || isActiveThirdParty}
                    />
                    {isActiveThirdParty && <div className={styles.tooltipWrapper}>
                        <InformationTooltip content={activeThirdPartyTooltip} labelColor={red} />
                    </div>}
                </div>
                <div className={styles.entityTypeInputWrapper}>
                    <div className={styles.entityTypeInputLabel}>For the purposes of DORA, this entity is a:</div>
                    <div className={styles.entityTypeWrapper}>
                        <div className={styles.dropdownWrapper}>
                            <AiDropdownField
                                testId='entity-dora-service-type'
                                updateValue={updateCounterpartyType}
                                toggleConfirmation={value => updateAIFieldContent('entityServiceType', 'aiConfirmed', value)}
                                field={entityServiceType}
                                options={entityTypeOptions}
                                disabled={!hasDoraFullAccessPermission}
                            />
                        </div>
                        <button className={styles.button} onClick={toggleDescriptionModal}>What does this mean?</button>
                        <DoraDescriptionModal isOpen={modalOpen} toggleDescriptionModal={toggleDescriptionModal} ictOrEntityTypes={doraEntityServiceType} label='Entity Type Descriptions' zIndex={10} />
                    </div>
                </div>
                <div className={styles.datesWrapper}>
                    <div className={styles.dateInputWrapper}>
                        <div className={styles.dateLabel}>Date of integration into the ROI</div>
                        <DatePicker
                            value={integrationDate}
                            onChange={value => updateDate(value, 'dateOfRoiIntegration')}
                            testId='entity-roi-integration-date'
                            disabled={!hasDoraFullAccessPermission}
                        />
                    </div>
                    <div className={styles.dateInputWrapper}>
                        <div className={styles.dateLabel}>Date of deletion from the ROI</div>
                        <DatePicker
                            value={deletionDate}
                            onChange={value => updateDate(value, 'dateOfRoiDeletion')}
                            testId='entity-roi-deletion-date'
                            disabled={!hasDoraFullAccessPermission}
                        />
                    </div>
                </div>
                <div className={styles.inputWrapper}>
                    <Dropdown
                        label='Hierarchy of the entity within the group (where applicable)'
                        testId='dora-roi-entity-doraEntityHierarchy'
                        onChange={value => updateDropdownOption(value, 'doraEntityHierarchy')}
                        value={hierarchyValue}
                        options={hierarchyDropdownOptions}
                        disabled={!hasDoraFullAccessPermission}
                    />
                </div>
                <div className={styles.inputWrapper}>
                    <div className={styles.inputLabelWrapper}>
                        <div className={styles.inputLabel}>Value of total assets - of the financial entity</div>
                    </div>
                    <Number
                        onChange={value => updateTotalAssets(value)}
                        value={totalAssetsValue}
                        placeholder='Total Assets...'
                        disabled={!hasDoraFullAccessPermission}
                    />
                </div>
                <div className={styles.inputWrapper}>
                    <div className={styles.inputLabelWrapper}>
                        <div className={styles.inputLabel}>Competent Authority</div>
                        <InformationTooltip content='Note that only the maintainer of the Register of Information needs to provide this information' />
                    </div>
                    <Dropdown
                        onChange={value => updateDropdownOption(value, 'competentAuthority')}
                        value={competentAuthorityValue}
                        options={competentAuthorityOptions}
                        disabled={!hasDoraFullAccessPermission}
                    />
                </div>
                {showOtherCompetentAuthority && <div className={styles.inputWrapper}>
                    <Text
                        label='Other Competent Authority'
                        onChange={e => updateOtherCompetentAuthority(e.target.value)}
                        value={otherCompetentAuthority || ''}
                        maxLength={256}
                        placeholder='Other Competent Authority...'
                        marginBottom='0px'
                        testId='entity-roi-other-competent-authority'
                        disabled={!hasDoraFullAccessPermission}
                    />
                </div>}
            </Scrollable>
        </div>
    );
};
