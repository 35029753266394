import React, { useCallback } from 'react';
import { isUndefined } from 'lodash/fp';

import styles from '../AttestationForms.module.scss';
import { Text } from '../../../../shared/text/Text';
import { LongText } from '../../../../shared/longtext/LongText';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { addUserAnswer, AttestationForm, removeUserAnswer, updateAttestationFormValue, updateUserAnswerLabel } from '../../store';
import { Toggle } from '../../../../shared/toggle';
import { getUserRole } from '../../../../auth/login/store';
import { InformationTooltip } from '../../../../shared/tooltip';
import { systemAdminRole } from '../../../../constants/permittedRoles';
import { Delete } from '../../../../shared/icons';
import { Icon } from '../../../../shared/icon/Icon';
import { PlusButton } from '../../../../shared/button/PlusButton';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';

interface DefineTabProps {
    attestationForm: AttestationForm;
    testId?: string;
}

export const DefineTab: React.FC<DefineTabProps> = ({ attestationForm, testId }) => {
    const dispatch = useAppDispatch();
    const { name, description, isSystem, userAnswers, attestationFormId } = attestationForm;
    const userRole = useAppSelector(getUserRole);

    const isSystemAdmin = systemAdminRole.includes(userRole!);

    const updateFormName = (value: string) => dispatch(updateAttestationFormValue('name', value));
    const updateDescription = (value: string) => dispatch(updateAttestationFormValue('description', value));
    const toggleIsSystem = (value: boolean) => {
        const isSystemAttestation = value ? 1 : 0;
        dispatch(updateAttestationFormValue('isSystem', isSystemAttestation));
    };

    const showUserRemove = useCallback((currentLabel: string) => userAnswers.filter(({ label }) => label.length).length > 1 || !currentLabel, [userAnswers]);
    const addUser = () => dispatch(addUserAnswer());
    const removeUser = (id: string, label: string) => showUserRemove(label) && dispatch(removeUserAnswer(id));
    const updateUserLabel = (index: number, value: string) => dispatch(updateUserAnswerLabel(index, value));

    // TODO: Allow these to be updated when notifications exist in the system so attestation manager owners can be notified of these changes
    const canEditUserAnswers = isUndefined(attestationFormId);

    return (
        <div className={styles.defineTab} data-testid={`${testId}-wrapper`}>
            <Scrollable>
                {isSystemAdmin && <div className={styles.isSystemToggleWrapper}>
                    <InformationTooltip placement='left' trigger='click' content='Expose this attestation form to all clients' />
                    <div className={styles.isSystemToggleLabel} data-testid={`${testId}-is-system-toggle-label`}>System Attestation Form?</div>
                    <Toggle
                        checked={!!isSystem}
                        onChange={(val) => toggleIsSystem(val)}
                        testId={`${testId}-is-system`}
                    />
                </div>}
                <Text
                    testId={`${testId}-form-name`}
                    label='Name'
                    maxLength={128}
                    value={name}
                    onChange={e => updateFormName(e.target.value)}
                />
                <LongText
                    testId={`${testId}-form-description`}
                    label='Description'
                    maxLength={1000}
                    value={description}
                    onChange={updateDescription}
                    width='calc(100% - 12px)'
                />
                <div className={styles.userAnswersWrapper} data-testid={`${testId}-user-answers-wrapper`}>
                    <div className={styles.userAnswersHeader}>
                        <div className={styles.userAnswersTitle} data-testid={`${testId}-user-answers-header`}>Who is this attestation for?</div>
                        {canEditUserAnswers && <PlusButton onClick={addUser} fontSize={14} />}
                    </div>
                    <div className={styles.userAnswerOptions}>
                        <Scrollable>
                            {userAnswers.map(({ label, userAnswerId }, index) => (
                                <div className={styles.userAnswer} key={userAnswerId}>
                                    <input
                                        className={styles.userAnswerLabel}
                                        value={label}
                                        onChange={e => updateUserLabel(index, e.target.value)}
                                        data-testid={`${testId}-user-answer-label-${index}`}
                                    />
                                    {showUserRemove(label) && canEditUserAnswers &&
                                        <button className={styles.deleteUser} onClick={() => removeUser(userAnswerId, label)} data-testid={`delete-user-answer-${index}`}>
                                            <Icon icon={Delete} fontSize={16} />
                                        </button>
                                    }
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </div>
            </Scrollable>
        </div>
    );
};
