import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../hooks/react-redux';
import styles from './DatasetInstance.module.scss';
import { ClauseModal } from './ClauseModal';
import { toggleClauseModalOpen, updateClauseLabel } from './store';
import { InformationTooltip } from '../../shared/tooltip';
import { Position } from '../../shared/modal/PositionModal';

interface ClauseProps {
    modalOpen: boolean;
    index: number;
    testId: string;
    groupIndex?: number;
    clauseLabel?: string;
    sectionId?: string;
    isEditing: boolean;
    rowId?: string;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
}

export const Clause: React.FC<ClauseProps> = ({ modalOpen, index, testId, groupIndex, clauseLabel, sectionId, isEditing, rowId, datasetId, parentFieldId, modalInstance }) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);

    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        setIsOpen(true);
        toggleMenuOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        toggleMenuOpen(false);
    };

    const updateClause = (value: string) => dispatch(updateClauseLabel(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));

    const toggleMenuOpen = (value: boolean) => dispatch(toggleClauseModalOpen(datasetId, parentFieldId, index, value, sectionId, groupIndex, rowId, modalInstance));

    const content = clauseLabel && clauseLabel.length ? clauseLabel : null;

    useEffect(() => {
        if (modalOpen) {
            setIsOpen(modalOpen);
        }
    }, [modalOpen]);

    const renderClauseButton = () => isEditing ? (
        <div className={styles.disabledClauseButton} >
            <InformationTooltip content={content} label='cl' />
        </div>
    ) : (
        <div className={styles.clauseButton} onClick={e => openModal(e.clientX, e.clientY)} data-testid={`${testId}-button`}>cl</div>
    );

    return (
        <>
            {renderClauseButton()}
            <ClauseModal
                isOpen={isOpen}
                closeModal={closeModal}
                position={position}
                updateClause={updateClause}
                clauseLabel={clauseLabel}
            />
        </>
    );
};
