import React, { useCallback, useEffect, useRef } from 'react';
import ForceGraph3D from '3d-force-graph';
import { isEqual, uniqWith } from 'lodash/fp';

import styles from '../../Analytics.module.scss';
import { SupplyChainRole, GraphNode, GraphLink, NetworkDiagramData, NetworkNodeDetails } from '../../store';

const { red, green, gold, ocean, lightestGrey, darkFrench, primary } = styles;

interface NetworkDiagramChartProps {
    height: number;
    width: number;
    setNodeDetails: (value: NetworkNodeDetails) => void;
    zoomDistance: number;
    graphData: NetworkDiagramData;
}

export const NetworkDiagramChart: React.FC<NetworkDiagramChartProps> = ({
    height,
    width,
    setNodeDetails,
    zoomDistance,
    graphData
}) => {

    const canvasRef = useRef<HTMLDivElement | null>(null);

    const updateNodeDetails = useCallback((value: NetworkNodeDetails) => setNodeDetails(value), [setNodeDetails]);

    const getSourceLinks = useCallback((id: string) => graphData.links.filter(link => (link.source as unknown as GraphNode).id === id && !link.isIndirect), [graphData]);
    const getIntraSourceLinks = useCallback((id: string) => graphData.links.filter(link => (link.source as unknown as GraphNode).id === id && link.isIndirect), [graphData]);
    const getTargetLinks = useCallback((id: string) => graphData.links.filter(link => (link.target as unknown as GraphNode).id === id && !link.isIndirect), [graphData]);
    const getLinkName = useCallback((id: string) => graphData.nodes.find(node => node.id === id)!.name, [graphData]);
    const getLinkRole = useCallback((id: string) => graphData.nodes.find(node => node.id === id)!.role, [graphData]);

    const getNodeDetails = useCallback((data: GraphNode) => {
        const { id, role, name } = data;
        const isFunction = role === SupplyChainRole.FUNCTION;
        const primaryLinks = isFunction ? getTargetLinks(id) : getSourceLinks(id);
        const directLinks = primaryLinks.reduce((acc, cur) => {
            const name: string = isFunction ? getLinkName((cur.source as unknown as GraphNode).id) : getLinkName((cur.target as unknown as GraphNode).id);
            const role: SupplyChainRole = isFunction ? getLinkRole((cur.source as unknown as GraphNode).id) : getLinkRole((cur.target as unknown as GraphNode).id);
            acc.push(({ name, role }));
            return acc;
        }, [] as { name: string, role: SupplyChainRole }[]);
        const uniqueDirectLinks = uniqWith(isEqual, directLinks);
        let indirectLinks: { name: string, role: SupplyChainRole }[] = [];
        const links = getIntraSourceLinks(id);
        indirectLinks = links.reduce((acc, cur) => {
            const name = getLinkName((cur.target as unknown as GraphNode).id);
            const role = getLinkRole((cur.target as unknown as GraphNode).id);
            acc.push({ name, role });
            return acc;
        }, [] as { name: string, role: SupplyChainRole }[]);
        const uniqueIndirectLinks = uniqWith(isEqual, indirectLinks);

        const count = [...primaryLinks, ...indirectLinks].length;
        updateNodeDetails({ name, count, role, directLinks: uniqueDirectLinks, indirectLinks: uniqueIndirectLinks });
    }, [updateNodeDetails, getSourceLinks, getTargetLinks, getLinkName, getLinkRole, getIntraSourceLinks]);

    useEffect(() => {
        const Graph = ForceGraph3D({ controlType: 'trackball' })(canvasRef.current!);
        Graph.cameraPosition({ x: 0, y: 0, z: zoomDistance });

        Graph
            .graphData(graphData)
            .width(width)
            .height(height)
            .nodeLabel(d => {
                const data = d as GraphNode;
                return `<span style='color: ${primary}'>${data.name}</span>`;
            })
            .nodeColor(d => {
                const data = d as GraphNode;
                if (data.role === SupplyChainRole.MY_COMPANY) {
                    return green;
                }
                if (data.role === SupplyChainRole.THIRD_PARTY) {
                    return gold;
                }
                return red;
            })
            .nodeOpacity(1)
            .nodeVal(d => graphData.links.filter((link) => (link.source as unknown as GraphNode).id === d.id as string).length)
            .onNodeClick(d => {
                const data = d as GraphNode;
                getNodeDetails(data);
            })
            .onNodeDrag(d => {
                const data = d as GraphNode;
                getNodeDetails(data);
            })
            .linkColor(d => (d as GraphLink).isIndirect ? ocean : darkFrench)
            .linkOpacity(1)
            .linkWidth(1)
            .linkCurvature(d => (d as GraphLink).isIndirect ? 0.1 : 0)
            .backgroundColor(lightestGrey)
            .nodeResolution(12)
            .cooldownTime(3500)
            .showNavInfo(false);

    }, [graphData, height, width, getNodeDetails, zoomDistance]);

    return (
        <div className={styles.networkDiagramChart}>
            <div style={{ height, width }} ref={canvasRef} />
        </div>
    );
};
