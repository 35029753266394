import React from 'react';

import { HorizontalDraggableList } from './horizontal/HorizontalDraggableList';
import { VerticalDraggableList } from './vertical/VerticalDraggableList';
import { DraggableListProps } from './shared';

interface DragDropProps extends DraggableListProps {
    type?: 'vertical' | 'horizontal';
}

export const DragDrop: React.FC<DragDropProps> = ({ getChildElement, list, listId, type = 'vertical', updateList, getDraggingElement, edgeColour, edgeWidth }) => {
    if (type === 'horizontal') {
        return (
            <HorizontalDraggableList
                getChildElement={getChildElement}
                getDraggingElement={getDraggingElement}
                list={list}
                listId={listId}
                updateList={updateList}
                edgeColour={edgeColour}
                edgeWidth={edgeWidth}
            />
        );
    }

    return (
        <VerticalDraggableList
            getChildElement={getChildElement}
            getDraggingElement={getDraggingElement}
            list={list}
            listId={listId}
            updateList={updateList}
            edgeColour={edgeColour}
            edgeWidth={edgeWidth}
        />
    );
};
