import { RawDraftContentState } from 'draft-js';
import { findLast, isEqual, isNull, isUndefined, noop } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { DATASET_HEIGHT_OFFSET, PAGE_MARGIN_PADDING_BORDER, useSplitView } from '../../../hooks/useSplitView';
import { formatDate, isBefore } from '../../../utils/luxon';
import { fetchAllDocumentNamesStarted } from '../../admin/documents/store';
import { FeaturePermission } from '../../admin/users/store';
import { getPathname, getUserHasFeaturePermission, getUserHasFeaturePermissionNoAdmin, getUserRole } from '../../auth/login/store';
import { adminRoles } from '../../constants/permittedRoles';
import { UnsavedChangesPrompt } from '../../datasets/instances';
import { ConfirmSaveModal } from './ConfirmSaveModal';
import { ButtonResize } from '../../shared/button/ButtonResize';
import { Icon } from '../../shared/icon/Icon';
import { CaretUp, Delete, Download, Pencil, SaveFile, Tick, WarningSign, SpeechBubble, Add } from '../../shared/icons';
import { Scrollable, ScrollableElement } from '../../shared/scrollable/Scrollable';
import { Spinner } from '../../shared/spinner/Spinner';
import { scopeFormatter } from '../../shared/table/arkTableFormatters';
import { CustomTooltip, OverflowTooltip } from '../../shared/tooltip';
import { ArkOpinion, downloadOpinionSummaryStarted, getIsOpening, getLinkedOpinions, getOpinion, getOpinionAnalysisView, getOpinionSummaryGenerating, getOpinionSummaryModalOpen, getUnauthorisedOpinion, getUnauthorisedOpinionModalOpen, openOpinionSummaryStarted, setUnauthorisedOpinion, toggleOpinionSummaryPreviewOpen } from '../my-opinions/store';
import { SummaryPreviewModal } from '../my-opinions/SummaryPreviewModal';
import { UnauthorisedOpinionModal } from '../my-opinions/UnauthorisedOpinionModal';
import { HistoricalInstances } from './historical/HistoricalInstances';
import { LinkedOpinionButton } from './LinkedOpinionButton';
import { OpinionInstance } from './OpinionInstance';
import styles from './OpinionInstance.module.scss';
import { SignOffModal } from './SignOffModal';
import { PopoutWysiwygModal } from '../../shared/modal/PopoutWysiwygModal';
import { closeAllOpenFieldSections, editOpinionInstance, getConfirmSaveModalOpen, getCurrentInstance, getCurrentInstanceIsSignedOff, getCurrentSignOffNotes, getInstanceUpdated, getIsEditing, getIsLoading, getIsSaving, getIsUpdating, getOpenFieldsAndSections, getOpinionInstanceTimeline, getScrollableWrapper, getSignOffInstanceUpdated, resetSignOffNotesInstance, setScrollableWrapper, toggleSaveInstanceModal, toggleSignOffModalOpen, updateOpinionInstance, upsertOpinionInstanceStarted, upsertSignOffOpinionInstanceStarted, getIsEditingSignOff, toggleSupportOpinionUploadModal, getSupportOpinionUploadModalOpen, commissionedByFormatter } from './store';
import { SupportOpinionModal } from './SupportOpinionModal';

export const OpinionInstancesWrapper: React.FC = () => {
    const dispatch = useAppDispatch();
    const userRole = useAppSelector(getUserRole);
    const opinionAnalysisView = useAppSelector(getOpinionAnalysisView);
    const opinion = useAppSelector(getOpinion);
    const [width, height] = useSplitView(opinionAnalysisView, PAGE_MARGIN_PADDING_BORDER, (DATASET_HEIGHT_OFFSET - 20));
    const hasIndustryPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));
    const isEditing = useAppSelector(getIsEditing);
    const isUpdating = useAppSelector(getIsUpdating);
    const isLoading = useAppSelector(getIsLoading);
    const isOpening = useAppSelector(getIsOpening);
    const instanceUpdated = useAppSelector(getInstanceUpdated);
    const currentInstance = useAppSelector(getCurrentInstance);
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const timeline = useAppSelector(getOpinionInstanceTimeline);
    const confirmSaveModalOpen = useAppSelector(getConfirmSaveModalOpen);
    const isSaving = useAppSelector(getIsSaving);
    const pathname = useAppSelector(getPathname);
    const linkedOpinions = useAppSelector(getLinkedOpinions);
    const unauthorisedOpinion = useAppSelector(getUnauthorisedOpinion);
    const unauthorisedOpinionModalOpen = useAppSelector(getUnauthorisedOpinionModalOpen);
    const scrollableWrapper = useAppSelector(getScrollableWrapper);
    const summaryPreviewModalOpen = useAppSelector(getOpinionSummaryModalOpen);
    const summaryGenerating = useAppSelector(getOpinionSummaryGenerating);
    const hasEditBespokeOpinionDatasetPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.EDIT_OPINION_DATA]));
    const hasDownloadSummaryPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.DOWNLOAD_OPINION_SUMMARIES]));
    const currentSignOffNotes = useAppSelector(getCurrentSignOffNotes);
    const signOffInstanceUpdated = useAppSelector(getSignOffInstanceUpdated);
    const hasSignOffPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.SIGN_OFF_OPINIONS]));
    const hasAddSignOffNotesPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.ADD_OPINION_NOTES]));
    const opinionInstanceIsSignedOff = useAppSelector(getCurrentInstanceIsSignedOff);
    const isEditingSignOff = useAppSelector(getIsEditingSignOff);
    const supportUploadModalOpen = useAppSelector(getSupportOpinionUploadModalOpen);

    useEffect(() => {
        if (isNull(scrollableWrapper)) {
            dispatch(setScrollableWrapper({ scrollTop: 0, totalHeight: height, scrollableHeight: height }));
        }
    }, [height, scrollableWrapper, dispatch]);

    useFetchStarted([fetchAllDocumentNamesStarted()]);

    const toggleEdit = useCallback(() => dispatch(editOpinionInstance(!isEditing)), [isEditing, dispatch]);
    const toggleSupportOpinionModal = useCallback(() => dispatch(toggleSupportOpinionUploadModal(true)), [dispatch]);
    const toggleUpdate = useCallback(() => dispatch(updateOpinionInstance(!isUpdating)), [isUpdating, dispatch]);
    const closeConfirmSaveModal = useCallback(() => dispatch(toggleSaveInstanceModal(false)), [dispatch]);
    const openConfirmSave = useCallback(() => dispatch(toggleSaveInstanceModal(true)), [dispatch]);
    const collapseAllFieldsAndSections = useCallback(() => dispatch(closeAllOpenFieldSections()), [dispatch]);
    const closeUnauthorisedModal = useCallback(() => dispatch(setUnauthorisedOpinion(null)), [dispatch]);
    const openSignOffModal = useCallback(() => dispatch(toggleSignOffModalOpen(true)), [dispatch]);

    const openSummaryPreview = useCallback(() => {
        if (opinion && opinion.opinionSummaryId) {
            dispatch(toggleOpinionSummaryPreviewOpen(true));
            dispatch(openOpinionSummaryStarted(opinion.opinionSummaryId!));
        }
    }, [dispatch, opinion]);
    const closeSummaryPreview = useCallback(() => dispatch(toggleOpinionSummaryPreviewOpen(false)), [dispatch]);
    const downloadSummary = useCallback((opinion: ArkOpinion) => dispatch(downloadOpinionSummaryStarted(opinion)), [dispatch]);

    const save = useCallback((isDraft: boolean) => {
        dispatch(upsertOpinionInstanceStarted(isDraft));
        closeConfirmSaveModal();
    }, [dispatch, closeConfirmSaveModal]);

    const selectedInstanceIsDraft = !isNull(currentInstance) && !!currentInstance.isDraft;
    const isAdminUser = adminRoles.includes(userRole!);

    const handleSave = useCallback(() => {
        if (isEditing && !selectedInstanceIsDraft && isAdminUser) {
            save(false);
        } else {
            openConfirmSave();
        }
    }, [isEditing, selectedInstanceIsDraft, isAdminUser, openConfirmSave, save]);

    const permittedToEdit = useMemo(() => !isNull(opinion) && ((!!opinion.bespoke && hasEditBespokeOpinionDatasetPermission) || (!opinion.bespoke && hasIndustryPermission)), [opinion, hasIndustryPermission, hasEditBespokeOpinionDatasetPermission]);
    const saveDisabled = (!selectedInstanceIsDraft && ((!isAdminUser && isUpdating) || isEditing) && !instanceUpdated) || isSaving;
    const showLinkIcon = useMemo(() => linkedOpinions.length > 0, [linkedOpinions.length]);
    const showDownloadIcon = useMemo(() => !!opinion?.opinionSummaryId, [opinion]);

    const saveDisabledTooltip = useMemo(() => {
        let tooltipContent = [];
        if (!instanceUpdated) {
            tooltipContent.push('You have not made any changes to this dataset');
        }
        if (isSaving) {
            tooltipContent.push('Save in progress');
        }
        return tooltipContent;
    }, [instanceUpdated, isSaving]);

    const latestPublishedInstance = useMemo(() => findLast(instance => !instance.isDraft, timeline), [timeline]);
    const isLatestPublishedInstance = useMemo(() => !isUndefined(latestPublishedInstance) && isEqual(latestPublishedInstance.opinionInstanceId, currentInstance?.opinionInstanceId), [latestPublishedInstance, currentInstance]);

    const updateButtonVisible = !isEditing && !isAdminUser && !selectedInstanceIsDraft && permittedToEdit && !isEditingSignOff;
    const editButtonVisible = ((isAdminUser && !isUpdating) || selectedInstanceIsDraft) && permittedToEdit;
    const saveButtonVisible = (isEditing || isUpdating) && permittedToEdit;
    const saveSignOffNotesButtonVisible = !isEditing && !selectedInstanceIsDraft && isLatestPublishedInstance && (hasAddSignOffNotesPermission && !opinionInstanceIsSignedOff);
    const signOffNotesButtonVisible = !isEditing && !selectedInstanceIsDraft && isLatestPublishedInstance && (hasSignOffPermission && !opinionInstanceIsSignedOff);
    const addSupportDocumentButtonVisible = editButtonVisible || updateButtonVisible;

    const showCollapseAllButton = openFieldsAndSections.length > 0;

    const previousInstances = useMemo(() => currentInstance && timeline.filter(({ dateOfOpinion }) => isBefore(dateOfOpinion, formatDate(currentInstance.dateOfOpinion))) || [], [timeline, currentInstance]);
    const previousInstanceInDraft = previousInstances.some(({ isDraft }) => isDraft);
    const editDraftDisabled = useMemo(() => previousInstanceInDraft ? ['Previous version is still saved as draft'] : [], [previousInstanceInDraft]);

    const showIconButtons = useMemo(() => {
        let displayedButtonsWidth = 0;
        if (editButtonVisible || updateButtonVisible) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (addSupportDocumentButtonVisible) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (instanceUpdated) {
            displayedButtonsWidth = displayedButtonsWidth + 170;
        }
        if (showCollapseAllButton) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (saveButtonVisible) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (saveSignOffNotesButtonVisible) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (signOffNotesButtonVisible) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (showLinkIcon) {
            displayedButtonsWidth = displayedButtonsWidth + 54;
        }
        if (showDownloadIcon) {
            displayedButtonsWidth = displayedButtonsWidth + 54;
        }
        return displayedButtonsWidth >= width;
    }, [instanceUpdated, showCollapseAllButton, saveButtonVisible, saveSignOffNotesButtonVisible, signOffNotesButtonVisible, updateButtonVisible, showLinkIcon, showDownloadIcon, width, editButtonVisible, addSupportDocumentButtonVisible]);

    const updateButton = useMemo(() => isUpdating ? { label: 'Cancel', icon: Delete } : { label: 'Update', icon: Pencil }, [isUpdating]);
    const editButton = useMemo(() => isEditing ? { label: 'Cancel', icon: Delete } : { label: 'Edit', icon: Pencil }, [isEditing]);

    const title = useMemo(() => {
        if (opinion) {
            const { jurisdiction, commissionedBy, commissionedByIfOther, scope, bespoke } = opinion;
            const commissioned = commissionedByFormatter(commissionedBy!, commissionedByIfOther, scope!, bespoke);
            return `${jurisdiction}${scope ? ` ${scopeFormatter(scope)}` : ''} Opinion Commissioned By ${commissioned || ''}`;
        }
        return '';
    }, [opinion]);

    const handleScroll = useCallback((scrollableElement: ScrollableElement) => dispatch(setScrollableWrapper(scrollableElement)), [dispatch]);

    const resetSignOffNotes = useCallback(() => {
        dispatch(resetSignOffNotesInstance());
    }, [dispatch]);

    const saveSignOffNotes = useCallback(() => {
        dispatch(upsertSignOffOpinionInstanceStarted(false));
    }, [dispatch]);

    const saveSignOffNotesDisabled = useMemo(() => {
        let tooltipContent = [];
        if (!signOffInstanceUpdated) {
            tooltipContent.push('You have not added any sign off notes');
        }
        if (isSaving) {
            tooltipContent.push('Save in progress');
        }
        return tooltipContent;
    }, [signOffInstanceUpdated, isSaving]);

    useEffect(() => () => {
        collapseAllFieldsAndSections();
    }, [collapseAllFieldsAndSections]);

    useEffect(() => () => {
        resetSignOffNotes();
    }, [resetSignOffNotes]);

    const content: RawDraftContentState | null = useMemo(() => !isNull(currentSignOffNotes) && !isNull(currentSignOffNotes.finalApprovalNotes) ? currentSignOffNotes.finalApprovalNotes : null, [currentSignOffNotes]);
    const showSignedOffButton: boolean = useMemo(() => !isNull(currentSignOffNotes) && !isNull(currentSignOffNotes.approvedBy), [currentSignOffNotes]);

    if (isLoading || isNull(currentInstance) || isOpening) {
        return (
            <div className={styles.spinnerWrapper} data-testid='opinion-instance-spinner-wrapper'>
                <Spinner size={70} />
            </div>
        );
    }

    if (!opinion) {
        return null;
    }

    return (
        <div>
            <div className={styles.opinionInstanceWrapper} data-testid='opinion-instance-wrapper' style={{ width: `${width}px` }}>
                <HistoricalInstances timeline={timeline} />
                <div className={styles.opinionInstanceHeader}>
                    <div className={styles.opinionInstanceTitleWrapper}>
                        {showSignedOffButton ?
                            <div className={styles.signOffOpinionInstanceTitle}>
                                <PopoutWysiwygModal triggerElement={<div>{`SIGNED OFF ON: ${formatDate(currentSignOffNotes!.approvalDate!)}`}</div>} content={content} updateContent={noop} disabled hideToolbar width='400px' />
                            </div>
                            : null}
                        <div className={styles.opinionInstanceTitle}>
                            <OverflowTooltip overlayText={title} placement='top' />
                        </div>
                    </div>
                </div>
                <div style={{ height: `${height}px`, zIndex: 1 }}>
                    <Scrollable handleScroll={handleScroll}>
                        <OpinionInstance opinion={opinion} instance={currentInstance} isEditing={isEditing} isUpdating={isUpdating} width={width} />
                    </Scrollable>
                </div>
                <div className={styles.footerWrapper}>
                    <div className={styles.opinionInstanceButtonWrapper} data-testid='opinion-instance-button-wrapper'>
                        {(instanceUpdated || signOffInstanceUpdated) && <div className={styles.unsavedOpinionWarning}>
                            <Icon icon={WarningSign} fontSize={25} />
                            <div className={styles.unsavedOpinionWarningTitle}>Unsaved changes</div>
                        </div>}
                        {showDownloadIcon &&
                            <CustomTooltip overlayText='Preview & Download Summary'>
                                <button className={styles.iconButtonWrapper} disabled={summaryGenerating} onClick={openSummaryPreview}>
                                    <Icon icon={Download} />
                                </button>
                            </CustomTooltip>
                        }
                        {showLinkIcon && <LinkedOpinionButton linkedOpinions={linkedOpinions} disabled={instanceUpdated} jurisdiction={opinion.jurisdiction || ''} />}
                        {addSupportDocumentButtonVisible && <ButtonResize showIconButton={showIconButtons} onClick={toggleSupportOpinionModal} label='Add Document' icon={Add} />}
                        {showCollapseAllButton && <ButtonResize showIconButton={showIconButtons} onClick={collapseAllFieldsAndSections} label='Collapse All' icon={CaretUp} hasIcon />}
                        {saveButtonVisible && <ButtonResize showIconButton={showIconButtons} onClick={handleSave} label='Save' icon={SaveFile} disabled={saveDisabled} disabledTooltip={saveDisabledTooltip} />}
                        {updateButtonVisible && <ButtonResize showIconButton={showIconButtons} onClick={toggleUpdate} label={updateButton.label} icon={updateButton.icon} />}
                        {!isEditing && saveSignOffNotesButtonVisible && <ButtonResize showIconButton={showIconButtons} onClick={saveSignOffNotes} label='Save My Notes' icon={SpeechBubble} hasIcon disabled={!signOffInstanceUpdated} disabledTooltip={saveSignOffNotesDisabled} />}
                        {!isEditing && signOffNotesButtonVisible && <ButtonResize showIconButton={showIconButtons} onClick={openSignOffModal} label='Sign Off' icon={Tick} hasIcon />}
                        {!isEditingSignOff && !signOffInstanceUpdated && editButtonVisible && <ButtonResize showIconButton={showIconButtons} onClick={toggleEdit} label={editButton.label} icon={editButton.icon} disabled={previousInstanceInDraft} disabledTooltip={editDraftDisabled} />}
                    </div>
                </div>
            </div>
            <ConfirmSaveModal
                isOpen={confirmSaveModalOpen}
                save={save}
                cancel={closeConfirmSaveModal}
                isUpdating={isUpdating}
                description={opinion.description}
                opinion={opinion}
            />
            {instanceUpdated && !isSaving && <UnsavedChangesPrompt when={instanceUpdated} pathname={pathname} toggleEdit={toggleEdit} />}
            {unauthorisedOpinionModalOpen && <UnauthorisedOpinionModal closeModal={closeUnauthorisedModal} opinion={unauthorisedOpinion!} />}
            <SupportOpinionModal isOpen={supportUploadModalOpen} />
            <SignOffModal />
            <SummaryPreviewModal isOpen={summaryPreviewModalOpen} closeModal={closeSummaryPreview} download={downloadSummary} canDownload={hasDownloadSummaryPermission} />
        </div>
    );
};
