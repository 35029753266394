import { combineReducers } from 'redux';

import { adminAttestationReducer, AdminAttestationState } from '../attestations/store';
import { clientReducer, ClientState } from '../clients/store';
import { datasetBuilderReducer, DatasetBuilderState } from '../dataset-builder/store';
import { documentNameReducer, DocumentNameState } from '../documents/store';
import { dropdownListReducer, DropdownListState } from '../dropdown-lists/store';
import { entityReducer, EntityState } from '../entity/store';
import { usersReducer, UsersState } from '../users/store';
import { workflowReducer, WorkflowState } from '../workflow/store';
import { MyDatasetsState, myDatasetsReducer } from '../my-datasets/store';
import { AdminPlaybookState, adminPlaybookReducer } from '../playbook/store';
import { sendNotificationReducer, SendNotificationState } from '../notification/store';
import { riskToleranceReducer, RiskToleranceState } from '../risk-tolerance/store';
import { AdminAnalyticsState, adminAnalyticsReducer } from '../analytics/store';
import { adminOpinionReducer, AdminOpinionState } from '../opinions/store';
import { adminAIReducer, AdminAIState } from '../ai/store';

export interface AdminState {
    ai: AdminAIState;
    attestations: AdminAttestationState;
    analytics: AdminAnalyticsState;
    dataset: DatasetBuilderState;
    documents: DocumentNameState;
    dropdownList: DropdownListState;
    entity: EntityState;
    users: UsersState;
    clients: ClientState;
    workflow: WorkflowState;
    myDatasets: MyDatasetsState;
    opinions: AdminOpinionState;
    playbook: AdminPlaybookState
    sendNotification: SendNotificationState;
    riskTolerance: RiskToleranceState;
}

export const adminReducer = combineReducers({
    ai: adminAIReducer,
    attestations: adminAttestationReducer,
    analytics: adminAnalyticsReducer,
    dataset: datasetBuilderReducer,
    documents: documentNameReducer,
    dropdownList: dropdownListReducer,
    entity: entityReducer,
    users: usersReducer,
    clients: clientReducer,
    workflow: workflowReducer,
    myDatasets: myDatasetsReducer,
    opinions: adminOpinionReducer,
    playbook: adminPlaybookReducer,
    sendNotification: sendNotificationReducer,
    riskTolerance: riskToleranceReducer
});
