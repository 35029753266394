import Fuse from 'fuse.js';
import { flatten, isEqual, isNull, isUndefined, toArray } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, debounce, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { addNewSystemTag, saveClause, getAllClientTags as getAllClientTagsServices, addNewClientTag } from '../../../../services/clause';
import { fetchAllDropdownLists } from '../../../../services/dropdownList';
import { fetchOpinionClauses, fetchOpinionInstance, fetchOpinionSignOffInstance, fetchTimelineOpinionInstances, linkClauseToOpinion, opinionInstanceExistsForOpinionId, upsertOpinionInstance, upsertSignOffOpinionInstance, UpsertSignOffRequest } from '../../../../services/opinionInstance';
import { DropdownListDB } from '../../../admin/dropdown-lists/store';
import { getIsAdmin } from '../../../auth/login/store';
import { Clause, ClauseTagDropdownOptions, ClauseTags, ClientTag, FuzzyMatchTags, dropdownOptionsByTagType, getAllClientTags, getClauseLibraryDropdownLists, getTagDropdownCategory, stripDropdownList, fetchClauseLibraryDropdownOptionsStarted } from '../../../clause-library/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { ArkOpinion, LinkedOpinion, getOpinion, getOpinionId, openAdditionalOpinionsStarted, openOpinionAndInstance, setLinkedOpinions, setOpinionSummaryGenerating } from '../../my-opinions/store';
import { addOpinionClauseClientTagFailed, addOpinionClauseClientTagStarted, addOpinionClauseClientTagSuccessful, addOpinionClauseSystemTagFailed, addOpinionClauseSystemTagStarted, addOpinionClauseSystemTagSuccessful, editOpinionInstance, fetchAllOpinionClausesFailed, fetchAllOpinionClausesStarted, fetchAllOpinionClausesSuccessful, fetchOpinionClauseLibraryDropdownOptionsFailed, fetchOpinionClauseLibraryDropdownOptionsSuccessful, linkOpinionClause, openOpinionInstanceById, openOpinionInstanceFailed, openOpinionInstanceSuccessful, openTimelineOpinionInstanceFailed, openTimelineOpinionInstanceStarted, openTimelineOpinionInstanceSuccessful, saveOpinionClauseFailed, saveOpinionClauseStarted, saveOpinionClauseSuccessful, setFieldsUpdated, setInstanceUpdated, setOpinionClauseCanSave, setOpinionSignOff, setSignOffInstanceUpdated, setTimeline, setTimelineInstance, updateAllOpinionClauseClientTags, updateDropdownDetailsContent, updateOpinionClauseFuzzyMatchResults, updateOpinionInstance, updateSmileyContent, upsertOpinionInstanceFailed, upsertOpinionInstanceStarted, upsertOpinionInstanceSuccessful, upsertSignOffOpinionInstanceFailed, upsertSignOffOpinionInstanceStarted, upsertSignOffOpinionInstanceSuccessful } from './actions';
import { getCurrentInstance, getCurrentOpinionClause, getIsSaving, getIsUpdating, getOpinionClause, getOpinionClauseColumnSort, getOpinionClauseFilters, getOpinionClauseLibraryDropdownOptions, getOpinionClauseTagTerm, getOpinionClausesPageNumber, getSavedInstance, getCurrentSignOffNotes, getSavedSignOffNotes, getSectionOrFieldId } from './selectors';
import { OpinionInstance, OpinionInstanceActionTypes, SignOffOpinionInstance, TimelineOpinionInstance } from './types';
import { compareInstanceFields, compareSignOffNotesFields, updateFieldsUpdated } from './utils';
import { fetchLinkedOpinions } from '../../../../services/opinion';
import { initialClause } from '../../../constants/clauseLibrary';
import { getPositiveAndNegativeAnswers } from '../../../constants/opinion';
import { SmileyIndicator } from '../../../shared/analytics/SingleIndicator';

const getInstance = (allInstances: TimelineOpinionInstance[], opinionId: number): TimelineOpinionInstance => allInstances.find(timeline => timeline.opinionId === opinionId)!;

export function* attemptOpenOpinionTimelineInstance({ payload }: ReturnType<typeof openTimelineOpinionInstanceStarted>) {
    try {
        const opinionInstance: OpinionInstance = yield call(fetchOpinionInstance, { opinionInstance: payload });
        const { opinionInstanceId, opinionId, additionalOpinionIds } = opinionInstance;
        const linkedOpinions: LinkedOpinion[] = yield call(fetchLinkedOpinions, { opinionId });
        yield put(fetchAllOpinionClausesStarted(opinionId, 1));
        if (opinionInstanceId) {
            yield put(push(`/opinions/analysis/${opinionId}/${opinionInstanceId}`));
        }
        yield put(setLinkedOpinions(linkedOpinions));
        yield put(openAdditionalOpinionsStarted(additionalOpinionIds));
        yield put(openTimelineOpinionInstanceSuccessful(opinionInstance));
    } catch (e) {
        yield put(openTimelineOpinionInstanceFailed((e as Error).message));
    }
}

export function* attemptOpenOpinionInstance() {
    try {
        const opinionId: number = yield select(getOpinionId);
        const timeline: TimelineOpinionInstance[] = yield call(fetchTimelineOpinionInstances, { opinionId });
        yield put(setTimeline(timeline));
        const opinionInstance = getInstance(timeline, opinionId);
        const opinionSignOff: SignOffOpinionInstance = yield call(fetchOpinionSignOffInstance, { opinionInstance });
        yield put(setOpinionSignOff(opinionSignOff));
        yield put(openTimelineOpinionInstanceStarted(opinionInstance));
        yield put(openOpinionInstanceSuccessful());
    } catch (e) {
        yield put(openOpinionInstanceFailed((e as Error).message));
    }
}

export function* attemptOpenOpinionInstanceByInstanceId({ payload }: ReturnType<typeof openOpinionInstanceById>) {
    try {
        const opinionId: number = yield select(getOpinionId);
        const { instanceExists }: { instanceExists: boolean } = yield call(opinionInstanceExistsForOpinionId, { opinionInstanceId: payload, opinionId });
        if (!instanceExists) {
            yield put(push('/opinions/my-opinions/map'));
            toast.error('Could not find an instance matching that ID. Please try again.');
        }
        const timeline: TimelineOpinionInstance[] = yield call(fetchTimelineOpinionInstances, { opinionId });
        const opinionInstance: TimelineOpinionInstance = timeline.find(({ opinionInstanceId }) => opinionInstanceId === parseInt(payload))!;
        yield put(setTimeline(timeline));
        yield put(openTimelineOpinionInstanceStarted(opinionInstance));
        yield put(openOpinionInstanceSuccessful());
    } catch (e) {
        yield put(push('/home'));
        toast.error('Unable to open dataset. Please try again.');
    }
}

export function* attemptSetTimelineInstance({ payload }: ReturnType<typeof setTimelineInstance>) {
    try {
        const { opinionId, opinionInstanceId } = payload;
        yield put(openOpinionAndInstance(opinionId.toString(), opinionInstanceId.toString()));
    } catch (e) {
        yield put(push('/home'));
        toast.error('Something went wrong. Please try again.');
    }
}

export function* saveOpinionInstance({ payload }: ReturnType<typeof upsertOpinionInstanceStarted>) {
    try {
        const currentInstance: OpinionInstance = yield select(getCurrentInstance);
        const isAdminUser: boolean = yield select(getIsAdmin);
        const isUpdating: boolean = yield select(getIsUpdating);
        const isUpdate = isUpdating || (isAdminUser && !currentInstance.isDraft);
        const savedInstance: OpinionInstance = yield select(getSavedInstance);
        const updatedInstance = updateFieldsUpdated(currentInstance, savedInstance, isUpdate, payload);
        yield put(setOpinionSummaryGenerating(true));
        const newOpinionInstanceId: string = yield call(upsertOpinionInstance, { opinionInstance: updatedInstance, isUpdate, isDraft: payload });
        yield put(upsertOpinionInstanceSuccessful(updatedInstance));
        toast('Saved successfully');
        if (!payload || (newOpinionInstanceId !== currentInstance.opinionInstanceId.toString())) {
            yield put(openOpinionInstanceById(newOpinionInstanceId));
        }
    } catch (e) {
        yield put(upsertOpinionInstanceFailed((e as Error).message));
        yield put(setOpinionSummaryGenerating(false));
        yield call(attemptOpenOpinionInstance);
        toast.error('Unable to update opinion instance.');
    }
}

export function* checkInstanceUpdated() {
    try {
        const isSaving: boolean = yield select(getIsSaving);
        const isUpdating: boolean = yield select(getIsUpdating);
        const currentInstance: OpinionInstance | null = yield select(getCurrentInstance);
        const savedInstance: OpinionInstance | null = yield select(getSavedInstance);
        if (!isSaving && !isNull(currentInstance) && !isNull(savedInstance)) {
            const { updatedFields, unchangedFields } = compareInstanceFields(currentInstance, savedInstance, isUpdating);
            const instanceUpdated = !!updatedFields.length;
            yield put(setFieldsUpdated([...unchangedFields, ...updatedFields]));
            yield put(setInstanceUpdated(instanceUpdated));
        }
    } catch (e) {
        toast.error('We encountered a problem checking if you had made any changes.');
    }
}

export function* resetInstance({ payload }: ReturnType<typeof updateOpinionInstance | typeof editOpinionInstance>) {
    try {
        if (!payload) {
            const savedInstance: OpinionInstance = yield select(getSavedInstance);
            yield put(openTimelineOpinionInstanceSuccessful(savedInstance));
        }
    } catch (e) {
        toast.error('Error');
    }
}

function* openTimelineInstanceWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.OPEN_TIMELINE_INSTANCE_STARTED, attemptOpenOpinionTimelineInstance);
}

function* setTimelineInstanceWatcher() {
    yield takeLatest(OpinionInstanceActionTypes.SET_TIMELINE_INSTANCE, attemptSetTimelineInstance);
}

function* openOpinionInstanceWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_STARTED, attemptOpenOpinionInstance);
}

function* openOpinionInstanceByInstanceIdWatcher() {
    yield takeLeading(OpinionInstanceActionTypes.OPEN_OPINION_INSTANCE_BY_ID, attemptOpenOpinionInstanceByInstanceId);
}

function* upsertOpinionInstanceWatcher() {
    yield takeLeading(OpinionInstanceActionTypes.UPSERT_OPINION_INSTANCE_STARTED, saveOpinionInstance);
}

function* fieldUpdatedWatcher() {
    yield debounce(500, [
        OpinionInstanceActionTypes.UPDATE_FIELD_VALUE,
        OpinionInstanceActionTypes.UPDATE_TABLE_CELL_FIELD_VALUE,
        OpinionInstanceActionTypes.UPDATE_DROPDOWN_FIELD_VALUE,
        OpinionInstanceActionTypes.UPDATE_WYSIWYG_FIELD_VALUE,
        OpinionInstanceActionTypes.UPDATE_FIELD_REF,
        OpinionInstanceActionTypes.UPDATE_FIELD_PAGE_REF,
        OpinionInstanceActionTypes.UPDATE_FIELD_PAGE_REF_VERIFIED,
        OpinionInstanceActionTypes.ADD_FIELD_PAGE_REF,
        OpinionInstanceActionTypes.REMOVE_FIELD_PAGE_REF,
        OpinionInstanceActionTypes.ADD_OPINION_FIELD_CUSTOM_CHILD,
        OpinionInstanceActionTypes.UPDATE_SINGLE_TOGGLE_FIELD_VALUE,
        OpinionInstanceActionTypes.UPDATE_COUNTERPARTY_COVERAGE_FIELD_VALUE,
        OpinionInstanceActionTypes.UPDATE_CUSTOM_TOGGLE_LABEL,
        OpinionInstanceActionTypes.UPDATE_INCLUDED_INSTANCE_SUB_COUNTERPARTY_TYPES
    ], checkInstanceUpdated);
}

function* cancelInstanceWatcher() {
    yield takeEvery([OpinionInstanceActionTypes.UPDATE_OPINION_INSTANCE, OpinionInstanceActionTypes.EDIT_OPINION_INSTANCE], resetInstance);
}

export function* attemptFetchAllOpinionClauses({ payload }: ReturnType<typeof fetchAllOpinionClausesStarted>) {
    try {
        const { opinionId, pageNumber } = payload;
        if (!isUndefined(opinionId)) {
            const filters: TableFilters = yield select(getOpinionClauseFilters);
            const columnSort: ColumnSort | undefined = yield select(getOpinionClauseColumnSort);
            const { clauses, total, allOpinionClauseIds, additionalProvisionsUpdated }: { clauses: Clause[]; total: number; allOpinionClauseIds: number[]; additionalProvisionsUpdated: boolean; } = yield call(fetchOpinionClauses, { opinionId, pageNumber, filters, columnSort });
            yield put(fetchAllOpinionClausesSuccessful(clauses, total, pageNumber, allOpinionClauseIds, additionalProvisionsUpdated));
        }
    } catch (e) {
        yield put(fetchAllOpinionClausesFailed((e as Error).message));
        toast.error('Unable to fetch clauses. Please try again.');
    }
}

function* fetchAllOpinionClausesWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.FETCH_ALL_OPINION_CLAUSES_STARTED, attemptFetchAllOpinionClauses);
}

export function* attemptLinkOpinionClause({ payload }: ReturnType<typeof linkOpinionClause>) {
    const { opinionId, clauseId, shouldRemoveLink } = payload;
    try {
        if (!isUndefined(opinionId)) {
            const pageNumber: number = yield select(getOpinionClausesPageNumber);
            const filters: TableFilters = yield select(getOpinionClauseFilters);
            const columnSort: ColumnSort | undefined = yield select(getOpinionClauseColumnSort);
            const { clauses, total, allOpinionClauseIds, additionalProvisionsUpdated }: { clauses: Clause[]; total: number; allOpinionClauseIds: number[]; additionalProvisionsUpdated: boolean; } = yield call(linkClauseToOpinion, { opinionId, clauseId, pageNumber, filters, columnSort, shouldRemoveLink });
            yield put(fetchAllOpinionClausesSuccessful(clauses, total, pageNumber, allOpinionClauseIds, additionalProvisionsUpdated));
            toast(`Clause successfully ${shouldRemoveLink ? 'removed from' : 'linked to'} the opinion.`);
        }
    } catch (e) {
        yield put(fetchAllOpinionClausesFailed((e as Error).message));
        toast.error(`Unable to ${shouldRemoveLink ? 'remove' : 'add'} the clause link. Please try again.`);
    }
}

function* linkOpinionClauseWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.LINK_OPINION_CLAUSE, attemptLinkOpinionClause);
}

export function* attemptSaveOpinionClause({ payload }: ReturnType<typeof saveOpinionClauseStarted>) {
    try {
        const { opinionId, closeModal } = payload;
        const clause: Clause = yield select(getOpinionClause);
        const savedClause: Clause = yield call(saveClause, { clause, opinionId });
        yield put(saveOpinionClauseSuccessful(savedClause, closeModal));
        const pageNumber: number = yield select(getOpinionClausesPageNumber);
        yield put(fetchAllOpinionClausesStarted(opinionId, pageNumber));
        toast('Clause saved successfully.');
    } catch (e) {
        yield put(saveOpinionClauseFailed((e as Error).message));
        toast.error('Unable to save the clause. Please try again.');
    }
}

function* saveOpinionClauseWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.SAVE_OPINION_CLAUSE_STARTED, attemptSaveOpinionClause);
}

export function* attemptCheckOpinionClauseUpdated() {
    try {
        const currentClause: Clause | null = yield select(getCurrentOpinionClause);
        const originalClause: Clause = isNull(currentClause) ? initialClause() : currentClause;
        const clause: Clause = yield select(getOpinionClause);
        const clauseHasTitle = clause.title !== '';
        const clauseUpdated = !isEqual(originalClause, clause);
        const canSave = clauseHasTitle && clauseUpdated;
        yield put(setOpinionClauseCanSave(canSave));
    } catch (e) {
        toast.error('Unable to check if the clause has updated. Please try again.');
    }
}

function* checkOpinionClauseUpdatedWatcher() {
    yield debounce(1000, [OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE, OpinionInstanceActionTypes.UPDATE_OPINION_CLAUSE_TAGS, OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_SYSTEM_TAG_STARTED], attemptCheckOpinionClauseUpdated);
}

export function* attemptFetchAllOpinionClauseLibraryDropdownLists() {
    try {
        const dropdownLists: DropdownListDB[] = yield call(fetchAllDropdownLists);
        const allClientSpecificTags: ClientTag[] = yield select(getAllClientTags);
        const { clientSpecificTags, dropdownOptions } = getClauseLibraryDropdownLists(dropdownLists, allClientSpecificTags);
        yield put(updateAllOpinionClauseClientTags(clientSpecificTags));
        yield put(fetchOpinionClauseLibraryDropdownOptionsSuccessful(dropdownOptions));
    } catch (e) {
        yield put(fetchOpinionClauseLibraryDropdownOptionsFailed((e as Error).message));
        toast.error('Unable to fetch the latest dropdown list options. Please try again.');
    }
}

function* fetchAllOpinionClauseLibraryDropdownListsWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_STARTED, attemptFetchAllOpinionClauseLibraryDropdownLists);
}

export function* attemptOpinionClauseFuzzyMatch() {
    try {
        const { tags, systemTemplate } = yield select(getOpinionClause);
        const allClauseTags = flatten(toArray(tags)).map(tag => tag.toLowerCase());
        const clauseLibraryDropdownOptions: ClauseTagDropdownOptions = yield select(getOpinionClauseLibraryDropdownOptions);
        const tagTerm: string = yield select(getOpinionClauseTagTerm);
        const getFuzzyMatchesByType = (key: keyof ClauseTags, value: string) => {
            const isSystemTemplate = systemTemplate === 1;
            const fuzzyMatchOptions = {
                includeScore: true,
                threshold: 0.5,
                keys: ['value']
            };
            const entityFuse = new Fuse(dropdownOptionsByTagType(key, clauseLibraryDropdownOptions, isSystemTemplate), fuzzyMatchOptions);
            const fuzzyMatches = entityFuse.search(value);
            return fuzzyMatches.map(({ item, score }) => ({ key, value: item.value, score: score! }));
        };
        const results = Object.keys(tags).map(value => getFuzzyMatchesByType(value as keyof ClauseTags, tagTerm));
        let sortedMatches: FuzzyMatchTags[] = flatten(results).sort((a, b) => a.score! - b.score!);
        sortedMatches = sortedMatches.filter(({ value }) => !allClauseTags.includes(value.toLowerCase())).slice(0, 10);
        yield put(updateOpinionClauseFuzzyMatchResults(sortedMatches));
    } catch (e) {
        toast.error('Unable to find any suggestions. Please try again.');
    }
}

function* opinionClauseFuzzyMatchWatcher() {
    yield debounce(1000, OpinionInstanceActionTypes.CHECK_OPINION_CLAUSE_FUZZY_MATCH, attemptOpinionClauseFuzzyMatch);
}

export function* attemptAddClientClauseTag({ payload }: ReturnType<typeof addOpinionClauseClientTagStarted>) {
    try {
        const currentClientTags: ClientTag[] = yield call(getAllClientTagsServices);
        const isNewTag = !currentClientTags.map(({ tag }) => tag).includes(payload);
        if (isNewTag) {
            const savedClientTags: ClientTag[] = yield call(addNewClientTag, { tag: payload });
            yield put(addOpinionClauseClientTagSuccessful(savedClientTags));
        }
    } catch (e) {
        yield put(addOpinionClauseClientTagFailed((e as Error).message));
        toast.error('Unable to add the tag. Please try again.');
    }
}

function* addClientClauseTagWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_CLIENT_TAG_STARTED, attemptAddClientClauseTag);
}

export function* attemptAddSystemClauseTag({ payload }: ReturnType<typeof addOpinionClauseSystemTagStarted>) {
    try {
        const { newTag, category, updatedTags } = payload;

        const dropdownLists: DropdownListDB[] = yield call(fetchAllDropdownLists);
        const listToUpdate = dropdownLists.find(({ name }) => name === getTagDropdownCategory(category));
        if (!isUndefined(listToUpdate)) {
            const dropdownList = stripDropdownList(listToUpdate);
            const updatedList = { ...dropdownList, options: [...dropdownList.options, newTag] };
            const { allDropdownLists, allClientTags }: { allDropdownLists: DropdownListDB[]; allClientTags: ClientTag[]; } = yield call(addNewSystemTag, { dropdownList: updatedList, newTag, category });
            const { clientSpecificTags, dropdownOptions } = getClauseLibraryDropdownLists(allDropdownLists, allClientTags);
            yield put(updateAllOpinionClauseClientTags(clientSpecificTags));
            yield put(fetchOpinionClauseLibraryDropdownOptionsSuccessful(dropdownOptions));
            yield put(addOpinionClauseSystemTagSuccessful(category, updatedTags));
            yield put(fetchClauseLibraryDropdownOptionsStarted());
        }
    } catch (e) {
        yield put(addOpinionClauseSystemTagFailed((e as Error).message));
        toast.error('Unable to add the tag. Please try again.');
    }
}

function* addSystemClauseTagWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.ADD_OPINION_CLAUSE_SYSTEM_TAG_STARTED, attemptAddSystemClauseTag);
}

export function* attemptUpsertSignOffOpinionInstance({ payload }: ReturnType<typeof upsertSignOffOpinionInstanceStarted>) {
    try {
        const signOffOpinionInstance: SignOffOpinionInstance = yield select(getCurrentSignOffNotes);
        const { clientOpinionInstanceId, opinionInstanceId, content, finalApprovalNotes } = signOffOpinionInstance;
        const reqBody: UpsertSignOffRequest = {
            clientOpinionInstanceId,
            opinionInstanceId,
            content,
            finalApprovalNotes,
            approved: payload
        };
        yield call(upsertSignOffOpinionInstance, reqBody);
        yield call(attemptOpenOpinionInstance);
        yield put(upsertSignOffOpinionInstanceSuccessful());
        payload ? toast('Signed Off Successfully') : toast('Saved Sign Off Notes Successfully');
    } catch (e) {
        yield put(upsertSignOffOpinionInstanceFailed((e as Error).message));
    }
}

function* upsertSignOffOpinionInstanceWatcher() {
    yield takeLatest(OpinionInstanceActionTypes.UPSERT_SIGN_OFF_OPINION_INSTANCE_STARTED, attemptUpsertSignOffOpinionInstance);
}

export function* checkSignOffNotesInstanceUpdated() {
    try {
        const isSaving: boolean = yield select(getIsSaving);
        const currentInstance: SignOffOpinionInstance | null = yield select(getCurrentSignOffNotes);
        const savedInstance: SignOffOpinionInstance | null = yield select(getSavedSignOffNotes);
        if (!isSaving && !isNull(currentInstance) && !isNull(savedInstance)) {
            const { updatedFields } = compareSignOffNotesFields(currentInstance, savedInstance);
            const instanceUpdated = !!updatedFields.length;
            yield put(setSignOffInstanceUpdated(instanceUpdated));
        }
    } catch (e) {
        toast.error('We encountered a problem checking if you had made any changes.');
    }
}

function* signOffNotesUpdatedWatcher() {
    yield debounce(500, [
        OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_SMILEY_CONTENT,
        OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_CONTENT
    ], checkSignOffNotesInstanceUpdated);
}

// Clause Table pagination
export function* opinionClausePaginationNext() {
    const pageNumber: number = yield select(getOpinionClausesPageNumber);
    const opinion: ArkOpinion = yield select(getOpinion);
    yield put(fetchAllOpinionClausesStarted(opinion.opinionId, pageNumber + 1));
}

function* opinionClausePaginationNextWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.OPINION_CLAUSES_PAGINATION_NEXT, opinionClausePaginationNext);
}

export function* opinionClausePaginationPrevious() {
    const pageNumber: number = yield select(getOpinionClausesPageNumber);
    const opinion: ArkOpinion = yield select(getOpinion);
    yield put(fetchAllOpinionClausesStarted(opinion.opinionId, pageNumber - 1));
}

function* opinionClausePaginationPreviousWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.OPINION_CLAUSES_PAGINATION_PREVIOUS, opinionClausePaginationPrevious);
}

export function* opinionClauseTableConfigUpdated() {
    const opinion: ArkOpinion = yield select(getOpinion);
    yield put(fetchAllOpinionClausesStarted(opinion.opinionId, 1));
}

function* opinionClauseTableConfigUpdatedWatcher() {
    yield takeEvery([OpinionInstanceActionTypes.SET_OPINION_CLAUSES_TABLE_FILTERS, OpinionInstanceActionTypes.CLEAR_OPINION_CLAUSES_TABLE_FILTERS, OpinionInstanceActionTypes.SET_OPINION_CLAUSES_TABLE_COLUMN_SORT], opinionClauseTableConfigUpdated);
}

export function* setDefaultSmileySignOff({ payload }: ReturnType<typeof updateDropdownDetailsContent>) {
    const fieldAndSection: string = yield select(getSectionOrFieldId);
    const [sectionId, fieldId] = fieldAndSection.split('-');
    let smiley: SmileyIndicator = SmileyIndicator.NONE;
    const dropdownValue = !isNull(payload) ? payload[0] : null;

    if (!isNull(dropdownValue)) {
        smiley = SmileyIndicator.NEUTRAL;
        const { positiveAnswers, negativeAnswers } = getPositiveAndNegativeAnswers(sectionId, fieldId);
        const isPositive = !isNull(dropdownValue) && positiveAnswers.includes(dropdownValue);
        const isNegative = !isNull(dropdownValue) && negativeAnswers.includes(dropdownValue);
        if (isPositive) {
            smiley = SmileyIndicator.POSITIVE;
        }
        if (isNegative) {
            smiley = SmileyIndicator.NEGATIVE;
        }
    }
    yield put(updateSmileyContent(smiley));
}

function* signOffDropdownUpdatedWatcher() {
    yield takeEvery(OpinionInstanceActionTypes.UPDATE_CURRENT_SIGN_OFF_DROPDOWN_VALUE, setDefaultSmileySignOff);
}

export function* opinionInstanceSaga() {
    yield all([
        fork(openTimelineInstanceWatcher),
        fork(openOpinionInstanceWatcher),
        fork(openOpinionInstanceByInstanceIdWatcher),
        fork(upsertOpinionInstanceWatcher),
        fork(fieldUpdatedWatcher),
        fork(cancelInstanceWatcher),
        fork(cancelInstanceWatcher),
        fork(fetchAllOpinionClausesWatcher),
        fork(opinionClausePaginationNextWatcher),
        fork(opinionClausePaginationPreviousWatcher),
        fork(opinionClauseTableConfigUpdatedWatcher),
        fork(saveOpinionClauseWatcher),
        fork(checkOpinionClauseUpdatedWatcher),
        fork(fetchAllOpinionClauseLibraryDropdownListsWatcher),
        fork(opinionClauseFuzzyMatchWatcher),
        fork(linkOpinionClauseWatcher),
        fork(setTimelineInstanceWatcher),
        fork(addSystemClauseTagWatcher),
        fork(addClientClauseTagWatcher),
        fork(upsertSignOffOpinionInstanceWatcher),
        fork(signOffNotesUpdatedWatcher),
        fork(signOffDropdownUpdatedWatcher)
    ]);
}
