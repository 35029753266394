import { action } from 'typesafe-actions';

import { ActiveMLDocument, Notification, NotificationActionTypes, SystemNotification } from './types';

export const notificationsReceived = (notifications: Notification[]) => action(NotificationActionTypes.NOTIFICATION_RECEIVED, notifications);

export const toggleNotificationModal = (isOpen: boolean) => action(NotificationActionTypes.TOGGLE_NOTIFICATION_MODAL_OPEN, isOpen);

export const toggleSystemNotificationModal = (notification: SystemNotification | null) => action(NotificationActionTypes.TOGGLE_SYSTEM_NOTIFICATION_MODAL, notification);

export const dismissNotificationStarted = (notificationId: number) => action(NotificationActionTypes.DISMISS_NOTIFICATION_STARTED, notificationId);
export const dismissNotificationSuccessful = () => action(NotificationActionTypes.DISMISS_NOTIFICATION_SUCCESSFUL);
export const dismissNotificationFailed = (error: string) => action(NotificationActionTypes.DISMISS_NOTIFICATION_FAILED, { error });

export const dismissAllNotificationsStarted = () => action(NotificationActionTypes.DISMISS_ALL_NOTIFICATIONS_STARTED);
export const dismissAllNotificationsSuccessful = () => action(NotificationActionTypes.DISMISS_ALL_NOTIFICATIONS_SUCCESSFUL);
export const dismissAllNotificationsFailed = (error: string) => action(NotificationActionTypes.DISMISS_ALL_NOTIFICATIONS_FAILED, { error });

export const toggleMLProgressModal = (isOpen: boolean) => action(NotificationActionTypes.TOGGLE_ML_PROGRESS_MODAL_OPEN, isOpen);

export const updatePercentageActiveMLDocument = (activeMLDocument: ActiveMLDocument) => action(NotificationActionTypes.UPDATE_PERCENTAGE_ACTIVE_ML_DOCUMENT, activeMLDocument);
export const triggerProgressActiveMLDocuments = (activeMLDocument: ActiveMLDocument, directFromSocket = false) => action(NotificationActionTypes.TRIGGER_PROGRESS_PERCENTAGE_ACTIVE_ML_DOCUMENT, { activeMLDocument, directFromSocket });

export const setActiveMLDocuments = (activeMLDocuments: ActiveMLDocument[]) => action(NotificationActionTypes.SET_ACTIVE_ML_DOCUMENTS, activeMLDocuments);
export const removeActiveMLDocument = (documentId: number) => action(NotificationActionTypes.REMOVE_ACTIVE_ML_DOCUMENT, documentId);

export const setPortfolioStatistics = (statistics: string[]) => action(NotificationActionTypes.SET_PORTFOLIO_STATISTICS, statistics);
export const toggleShowPortfolioStatistics = (showBanner: boolean) => action(NotificationActionTypes.TOGGLE_SHOW_PORTFOLIO_STATISTICS, showBanner);
