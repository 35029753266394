import React, { useCallback, useEffect, useMemo } from 'react';
import { RawDraftContentState } from 'draft-js';
import { noop, uniq } from 'lodash/fp';
import classnames from 'classnames';

import styles from './Conversation.module.scss';
import { WYSIWYG } from '../../wysiwyg/WYSIWYG';
import { Spinner } from '../../spinner/Spinner';
import { PlaybookMessage } from '../../../admin/playbook/store';
import { Scrollable } from '../../scrollable/Scrollable';
import { FULL_DATE_FORMAT, formatDate } from '../../../../utils/luxon';
import { useAppSelector } from '../../../../hooks/react-redux';
import { getAllBasicUsers } from '../../../admin/users/store';

const { red, amethyst, green, amber, gold } = styles;

interface ConversationColour {
    [index: number]: string
}
const conversationColours: ConversationColour = {
    1: red,
    2: amethyst,
    3: green,
    4: amber,
    5: gold
};

export interface ConversationProps {
    conversation: PlaybookMessage[];
    showSpinner: boolean;
    currentUserId: number;
    isNewMessage?: boolean;
    updateMessage?: (rawValue: RawDraftContentState, index: number) => void;
    height?: string;
    testId?: string;
}

export const Conversation: React.FC<ConversationProps> = ({
    conversation,
    showSpinner,
    currentUserId,
    isNewMessage = false,
    updateMessage = noop,
    height = '40vh',
    testId = 'playbook'
}) => {
    const allUsers = useAppSelector(getAllBasicUsers);
    const getDisplayName = useCallback((id: number) => {
        const user = allUsers.find(({ userId }) => userId === id);
        if (!user) {
            return 'unknown';
        }
        const { forenames, username, surname } = user;
        return forenames ? `${forenames} ${surname}` : username;
    }, [allUsers]);

    const uniqueUserIds = useMemo(() => uniq(conversation.map(({ userId }) => userId).filter(userId => userId !== currentUserId)), [conversation, currentUserId]);

    const getDisplayNameColour = useCallback((userId: number) => {
        const userIndex = uniqueUserIds.indexOf(userId);
        const usersColourNumber = (userIndex % 5) + 1;
        const colour = conversationColours[usersColourNumber];
        return colour;
    }, [uniqueUserIds]);

    useEffect(() => {
        const highestIndex = conversation.length - 1;
        const lastMessage = document.getElementById(`message-${highestIndex}`);
        if (lastMessage) {
            lastMessage.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation.length]);

    return (
        <div className={styles.conversationScrollableWrapper} data-testid={`${testId}-conversation-wrapper`}>
            <Scrollable height={height} maxHeight={height}>
                {showSpinner ? <div style={{ height }}><Spinner /></div> : conversation.map(({ content, date, userId }, index) => {
                    const formattedDate = formatDate(date!, FULL_DATE_FORMAT);
                    const isResponse = userId !== currentUserId;
                    const height = isNewMessage ? '35vh' : 'fit-content';
                    const displayName = getDisplayName(userId);
                    const displayNameColour = getDisplayNameColour(userId);
                    return (
                        <div key={index} className={styles.message} id={`message-${index}`} data-testid={`${testId}-conversation-${index}-wrapper`}>
                            {!isNewMessage &&
                                <div className={classnames(styles.messageMetaData, {
                                    [styles.centralisedData]: !isResponse
                                })}>
                                    {isResponse && <div className={styles.displayName} style={{ color: displayNameColour }} data-testid={`${testId}-conversation-${index}-name`}>{displayName}</div>}
                                    <div
                                        className={classnames(styles.messageDate, { [styles.responseDate]: isResponse })}
                                        data-testid={`${testId}-conversation-${index}-date`}
                                    >
                                        {formattedDate}
                                    </div>
                                </div>
                            }
                            <div
                                className={classnames(styles.messageWrapper, {
                                    [styles.messageWrapperAdmin]: !isNewMessage,
                                    [styles.messageResponseWrapper]: isResponse
                                })}
                                data-testid={`${testId}-conversation-${index}-message`}
                            >
                                <WYSIWYG
                                    content={content}
                                    updateContent={value => updateMessage(value, index)}
                                    showBorder={false}
                                    height={height}
                                    maxHeight={height}
                                    minHeight={height}
                                    showWrapperBorder
                                    toolbarHidden={!isNewMessage}
                                    disabled={!isNewMessage}
                                />
                            </div>
                        </div>
                    );
                }
                )}
            </Scrollable>
        </div>
    );
};
