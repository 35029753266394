import React, { useEffect } from 'react';

import { useAppDispatch } from '../../../../hooks/react-redux';
import { setConfigureDatasetParentId } from '../store';
import { AllDocumentDatasetDefinitions } from './AllDocumentDatasetDefinitions';

export interface DatasetConfigureProps {
    datasetId: number;
}

export const DatasetConfigure: React.FC<DatasetConfigureProps> = ({ datasetId }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setConfigureDatasetParentId(datasetId));
    }, [dispatch, datasetId]);

    return (
        <AllDocumentDatasetDefinitions title='Configure' datasetId={datasetId} />
    );
};
