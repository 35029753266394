import React from 'react';

export const ThirdParty: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg viewBox='0 0 1707 1707' width={width || '1em'} height={height || '1em'}>
        <g>
            <g>
                <path d='m361 1706c-190 0-345-155-345-345s155-344 345-344 345 154 345 344-155 345-345 345zm0-649c-168 0-304 137-304 304 0 168 136 305 304 305s304-137 304-305c0-167-136-304-304-304z' fill={color || 'currentColor'} stroke='none' />
                <path d='m417 1518c-11 0-20-9-20-20v-71c0-27 41-27 41 0v71c0 11-9 20-21 20zm-112 0c-12 0-21-9-21-20v-71c0-27 41-27 41 0v71c0 11-9 20-20 20z' fill={color || 'currentColor'} stroke='none' />
                <path d='m152 1630c-23 0-20-26-20-41 0-88 193-145 193-91 0 23-24 20-35 20-47 0-117 39-118 72 0 13 4 40-20 40zm418 0c-26 0-20-31-20-39-7-35-74-73-118-73-11 0-35 3-35-20 0-56 193 9 193 91 0 14 4 41-20 41z' fill={color || 'currentColor'} stroke='none' />
                <path d='m361 1465c-72 0-130-66-130-146 0-22-5-52 20-52s20 30 20 52c0 58 40 106 90 106 49 0 90-48 90-106 0-24-6-56 20-56s20 32 20 56c0 80-58 146-130 146z' fill={color || 'currentColor'} stroke='none' />
                <path d='m251 1307c-8 0-15-5-18-11-30-60-43-170 74-198 3-1 87-20 137 23 21-9 45-3 52 15 20 56 16 173-27 167-23-2-19-33-45-66-19 9-60 24-135 24-5 14-18 46-38 46zm103-174c-73 0-111 32-99 101 13-32 78 5 157-37 23-12 40 6 56 32 2-28-4-57-8-71-3 2-22 18-35 1-17-21-47-26-71-26z' fill={color || 'currentColor'} stroke='none' />
                <path d='m361 1593c-6 0-12-3-16-8l-57-75c-6-9-4-21 4-28 24-17 43 23 69 57 26-34 46-74 69-57 9 7 10 19 4 28l-57 75c-4 5-10 8-16 8z' fill={color || 'currentColor'} stroke='none' />
                <path d='m1346 1706c-190 0-345-155-345-345s155-344 345-344 344 154 344 344-154 345-344 345zm0-649c-168 0-304 137-304 304 0 168 136 305 304 305 167 0 304-137 304-305 0-167-137-304-304-304z' fill={color || 'currentColor'} stroke='none' />
                <path d='m1346 1465c-72 0-130-66-130-146 0-21-6-52 20-52 25 0 20 31 20 52 0 58 40 106 90 106 49 0 89-48 89-106 0-24-5-56 21-56s20 32 20 56c0 80-58 146-130 146z' fill={color || 'currentColor'} stroke='none' />
                <path d='m1402 1518c-11 0-20-9-20-20v-71c0-27 40-27 40 0v71c0 11-9 20-20 20zm-113 0c-11 0-20-9-20-20v-71c0-27 41-27 41 0v71c0 11-10 20-21 20z' fill={color || 'currentColor'} stroke='none' />
                <path d='m1137 1630c-24 0-20-26-20-41 0-88 193-145 193-91 0 23-24 20-36 20-46 0-117 39-117 72 0 14 4 40-20 40zm417 0c-25 0-19-31-20-39-7-35-73-73-117-73-12 0-35 3-35-20 0-54 186 4 193 91 1 17 1 41-21 41z' fill={color || 'currentColor'} stroke='none' />
                <path d='m1236 1307c-8 0-15-5-18-11-29-58-44-172 73-198 4-1 88-20 138 23 20-9 45-3 52 15 19 54 16 172-27 167-23-2-19-34-45-66-19 9-60 24-135 24-5 14-19 46-38 46zm103-174c-73 0-112 32-99 101 12-31 79 5 157-37 23-12 40 6 55 32 3-27-2-54-7-71-5 2-22 18-35 1-17-21-47-26-71-26z' fill={color || 'currentColor'} stroke='none' />
                <path d='m1346 1593c-7 0-13-3-16-8l-57-75c-7-9-5-21 4-28 23-17 43 23 69 57 26-34 45-74 68-57 9 7 11 19 5 28l-57 75c-4 5-10 8-16 8z' fill={color || 'currentColor'} stroke='none' />
                <path d='m788 485c-11 0-20-8-20-20v-79c0-27 40-27 40 0v79c0 11-9 20-20 20zm131 0c-11 0-20-8-20-20v-79c0-27 40-27 40 0v79c0 11-9 20-20 20z' fill={color || 'currentColor'} stroke='none' />
                <path d='m714 749c-11 0-20-9-20-20v-131c0-27 41-27 41 0v131c0 11-9 20-21 20z' fill={color || 'currentColor'} stroke='none' />
                <path d='m714 664h-103c-27 0-27-41 0-41h103c27 0 27 41 0 41z' fill={color || 'currentColor'} stroke='none' />
                <path d='m992 749c-11 0-20-9-20-20v-131c0-27 41-27 41 0v131c0 11-10 20-21 20z' fill={color || 'currentColor'} stroke='none' />
                <path d='m1096 664h-104c-26 0-26-41 0-41h104c26 0 27 41 0 41z' fill={color || 'currentColor'} stroke='none' />
                <path d='m611 749c-11 0-20-9-20-20v-157c0-78 118-127 179-127 13 0 38-3 38 20 0 24-25 20-38 20-51 0-139 43-139 87v137h445v-136c-8-43-87-88-140-88-12 0-37 4-37-20 0-56 209 7 217 106v158c0 11-9 20-20 20z' fill={color || 'currentColor'} stroke='none' />
                <path d='m853 427c-81 0-148-75-148-167 0-25-6-58 20-58s21 33 21 58c0 70 48 126 107 126 60 0 108-56 108-126 0-28-6-62 20-62 27 0 20 34 20 62 0 92-66 167-148 167z' fill={color || 'currentColor'} stroke='none' />
                <path d='m725 243c-7 0-14-4-18-11-33-66-50-195 84-226 4-1 101-22 158 28 23-11 51-5 58 14 1 5 37 111-9 182-9 14-30 12-36-4-10-28-25-56-34-68-22 12-70 32-161 32-5 14-20 53-42 53zm120-202c-94 0-137 47-115 133 3-9 5-25 22-24 138 4 159-38 178-36 19 3 36 24 50 51 5-38-5-79-9-96-8 2-26 22-41 4-20-26-56-32-85-32z' fill={color || 'currentColor'} stroke='none' />
                <path d='m853 573c-6 0-12-3-16-8l-65-88c-16-21 16-45 32-24l49 66 50-66c16-21 48 3 32 24l-65 88c-4 5-10 8-17 8z' fill={color || 'currentColor'} stroke='none' />
                <path d='m1346 1057c-11 0-20-9-20-20v-108c0-23-19-42-43-42h-860c-23 0-42 19-42 42v108c0 27-40 27-40 0v-108c0-46 37-83 82-83h860c46 0 83 37 83 83v108c0 11-9 20-20 20z' fill={color || 'currentColor'} stroke='none' />
                <path d='m853 887c-11 0-20-10-20-21v-137c0-27 41-27 41 0v137c0 12-10 21-21 21z' fill={color || 'currentColor'} stroke='none' />
            </g>
        </g>
    </svg>
);
