import { flattenDeep, isNull, noop } from 'lodash/fp';
import React, { ReactNode, useMemo } from 'react';

import { DocumentSpecificHiddenFields } from '../../admin/dataset-builder/store';
import { DocumentNameDB } from '../../admin/documents/store';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted } from '../../admin/dropdown-lists/store';
import { HiddenFields } from '../../admin/my-datasets/store';
import { MyRiskTolerance, RiskAssociated, RiskAssociatedValue, RiskField, RiskToleranceDataset, RiskTolerance as RiskToleranceType, SelectedRiskField } from '../../admin/risk-tolerance/store';
import { DatasetDefinition, FormDatasetFields, SingleDatasetField, isGroupField } from '../../datasets/store';
import { Spinner } from '../spinner/Spinner';
import styles from './RiskTolerance.module.scss';
import { RiskToleranceConfig } from './RiskToleranceConfig';
import { RiskToleranceDatasetsMenu } from './RiskToleranceDatasetsMenu';
export interface DatasetFieldSection {
    field: SingleDatasetField;
    sectionId: string | null;
}

export const flattenFormDefinitionFields = (fields: FormDatasetFields) => flattenDeep(Object.entries(fields).map(([sectionId, section]) => section.map(field => isGroupField(field) ? field.children.map(field => ({ field, sectionId })) : { field, sectionId }))) as DatasetFieldSection[];

interface RiskToleranceProps {
    datasetId: number;
    setRiskToleranceField: (riskField: SelectedRiskField) => void;
    riskTolerance: (RiskToleranceType | MyRiskTolerance)[] | null;
    selectedRiskField: RiskField | null;
    selectedField: SelectedRiskField | null;
    isLoading: boolean;
    datasetDefinitions: DatasetDefinition[];
    allRiskToleranceDatasets: RiskToleranceDataset[];
    setWeighting: (weighting: number) => void;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValue, selectedCurrency?: string) => void;
    buttonWrapper: ReactNode;
    hiddenFields?: HiddenFields;
    showRiskFieldToggles?: boolean;
    toggleRiskField?: (datasetId: number, fieldId: string) => void;
    documentHiddenFields: DocumentSpecificHiddenFields;
    allDocumentNames: DocumentNameDB[];
    agreementTypeDatasetId: number | null;
}

export const RiskTolerance: React.FC<RiskToleranceProps> = ({ datasetId,
    setRiskToleranceField,
    riskTolerance,
    selectedRiskField,
    selectedField,
    isLoading,
    datasetDefinitions,
    allRiskToleranceDatasets,
    setWeighting,
    buttonWrapper,
    updateRiskConfig,
    hiddenFields,
    showRiskFieldToggles = false,
    toggleRiskField = noop,
    documentHiddenFields,
    allDocumentNames,
    agreementTypeDatasetId
}) => {

    useFetchStarted([fetchAllDropdownListsStarted()]);

    const agreementType = useMemo(() => allRiskToleranceDatasets.find(riskDataset => riskDataset.datasetId === datasetId)?.agreementType || '', [allRiskToleranceDatasets, datasetId]);

    const availableDatasets = useMemo(() => riskTolerance && riskTolerance.map(({ datasetId, datasetTitle }) => ({ datasetId, datasetTitle })), [riskTolerance]);

    const selectedConfig = useMemo(() => {
        if (isNull(selectedRiskField)) {
            return null;
        }
        return (
            <RiskToleranceConfig
                riskField={selectedRiskField}
                setWeighting={setWeighting}
                updateRiskConfig={updateRiskConfig}
                toggleRiskField={() => toggleRiskField(datasetId, selectedRiskField.fieldId)}
                showRiskFieldToggles={showRiskFieldToggles}
            />
        );
    }, [selectedRiskField, setWeighting, updateRiskConfig, toggleRiskField, showRiskFieldToggles, datasetId]);

    if (isLoading || isNull(riskTolerance) || isNull(availableDatasets)) {
        return (
            <div className={styles.riskToleranceDatasetWrapper} data-testid='risk-tolerance-dataset-wrapper'>
                <Spinner />
            </div>
        );
    }

    if (riskTolerance.length === 0) {
        return (
            <div className={styles.riskToleranceDatasetWrapper} data-testid='risk-tolerance-dataset-wrapper'>
                <div className={styles.riskToleranceDatasetHeader} data-testid='risk-tolerance-dataset-header'>Risk Tolerance: {agreementType}</div>
                <div className={styles.emptyContentWrapper} data-testid='risk-tolerance-dataset-content-wrapper'>
                    There are no fields within this agreement type that are considered to be Risk Fields
                </div>
                {buttonWrapper}
            </div>
        );
    }

    return (
        <div className={styles.riskToleranceDatasetWrapper} data-testid='risk-tolerance-dataset-wrapper'>
            <div className={styles.riskToleranceDatasetHeader} data-testid='risk-tolerance-dataset-header'>Risk Tolerance: {agreementType}</div>
            <div className={styles.contentWrapper} data-testid='risk-tolerance-dataset-content-wrapper'>
                <div className={styles.fieldsWrapper}>
                    <RiskToleranceDatasetsMenu
                        datasetId={datasetId}
                        setRiskToleranceField={setRiskToleranceField}
                        riskTolerance={riskTolerance}
                        selectedField={selectedField}
                        datasetDefinitions={datasetDefinitions}
                        hiddenFields={hiddenFields}
                        showRiskFieldToggles={showRiskFieldToggles}
                        toggleRiskField={toggleRiskField}
                        documentHiddenFields={documentHiddenFields}
                        allDocumentNames={allDocumentNames}
                        agreementTypeDatasetId={agreementTypeDatasetId}
                    />
                </div>
                <div className={styles.configWrapper}>
                    {selectedConfig}
                </div>
            </div>
            {buttonWrapper}
        </div>
    );
};
