import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Options } from 'react-select';
import { isNull, isUndefined } from 'lodash/fp';

import styles from '../../ConfigureDatasets.module.scss';
import { TableDatasetFieldType } from '../../../../../datasets/store';
import { useAppDispatch } from '../../../../../../hooks/react-redux';
import { widthsByType } from '../../../preview/tablePreview/TableHeader';
import { OpenFieldType } from '../../../../../datasets/instances/store';
import { HiddenDocumentField, toggleDocumentHiddenField } from '../../../store';
import { DropdownOption } from '../../../../../shared/dropdown/Dropdown';
import { InformationTooltip, OverflowTooltip } from '../../../../../shared/tooltip';
import { DatasetDocumentSelect } from '../../DatasetDocumentSelect';

const { white } = styles;

interface DocumentDatasetTableHeaderProps {
    id: string;
    label: string;
    description: string;
    refLabel?: string
    showRef?: boolean;
    type: TableDatasetFieldType;
    isFirstCell: boolean;
    datasetId: number;
    isPreview: boolean;
    hiddenDocumentNameIds?: number[] | undefined;
    documentNames: DropdownOption[];
    hiddenSingleFields: HiddenDocumentField[];
    parentSectionId: string | undefined;
}

export const DocumentDatasetTableHeader: React.FC<DocumentDatasetTableHeaderProps> = ({ label, description, id, refLabel, showRef, type, isFirstCell, datasetId, isPreview, hiddenDocumentNameIds, documentNames, hiddenSingleFields }) => {
    const dispatch = useAppDispatch();
    const cellWidth = useMemo(() => `${widthsByType[type]}px`, [type]);
    const columnLabel = useMemo(() => label || 'Label...', [label]);

    const setDocumentsForField = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: number[] = [];
        if (!isNull(dropdownValue)) {
            const applicableDocuments = (dropdownValue as Options<DropdownOption>).map(({ value }) => value);
            value = documentNames.filter(({ value }) => !applicableDocuments.includes(value)).map(({ value }) => parseInt(value));
            const hiddenField: HiddenDocumentField = { fieldId: id!, type: OpenFieldType.FIELD, documentNameIds: value };
            dispatch(toggleDocumentHiddenField(datasetId, hiddenField));
        }
    }, [datasetId, dispatch, documentNames, id]);

    const currentHiddenDocumentIdsForField = hiddenSingleFields.find(({ fieldId }) => fieldId === id)?.documentNameIds;
    const relevantHiddenDocumentIdsForField = useMemo(() => !isUndefined(currentHiddenDocumentIdsForField) ? currentHiddenDocumentIdsForField.reduce((acc: number[], cur) => documentNames.map(({ value }) => parseInt(value)).includes(cur) ? [...acc, cur] : acc, []) : [], [currentHiddenDocumentIdsForField, documentNames]);

    const fieldDocuments = useMemo(() => {
        if (relevantHiddenDocumentIdsForField.length > 0) {
            return documentNames.filter(({ value }) => !relevantHiddenDocumentIdsForField.includes(parseInt(value)));
        }
        if (!isUndefined(hiddenDocumentNameIds) && hiddenDocumentNameIds.length > 0) {
            return documentNames.filter(({ value }) => !hiddenDocumentNameIds.includes(parseInt(value)));
        }
        return documentNames;
    }, [relevantHiddenDocumentIdsForField, documentNames, hiddenDocumentNameIds]);

    const documentNameOptions = useMemo(() => !isUndefined(hiddenDocumentNameIds) && hiddenDocumentNameIds.length > 0 ? documentNames.map(option => hiddenDocumentNameIds.includes(parseInt(option.value)) ? ({ ...option, disabled: true }) : option) : documentNames, [documentNames, hiddenDocumentNameIds]);

    return (
        <div className={classnames(styles.tableHeaderWrapper, { [styles.firstCell]: isFirstCell })} style={{ width: cellWidth, minWidth: cellWidth }}>
            <div className={styles.cellHeader}>
                <OverflowTooltip className={classnames(styles.headerLabel, { [styles.emptyHeaderLabel]: !label })} overlayText={columnLabel} testId={`table-header-${id}-label`} />
                <div className={styles.headerIconWrapper}>
                    {description && <InformationTooltip content={description} labelColor={white} />}
                    {refLabel && showRef && <InformationTooltip content={refLabel} label='?' labelColor={white} />}
                    {!isPreview && <DatasetDocumentSelect
                        selectedDocuments={fieldDocuments}
                        onChange={setDocumentsForField}
                        documentNames={documentNameOptions}
                        label={columnLabel}
                    />}
                </div>
            </div>
        </div>
    );
};
