import React, { useMemo, useRef } from 'react';
import Select, { Options } from 'react-select';
import { isEqual, isNull } from 'lodash/fp';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import { getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { customStyles } from '../../shared/dropdown/Dropdown';
import { useMenuPlacement } from '../../../hooks/useMenuPlacement';
import styles from './Fields.module.scss';

const { green, gold, amethyst, primary, disabledGrey, amber } = styles;
interface DropdownOption {
    value: string;
    label: string;
}

type DropdownOptions = Options<DropdownOption> | Options<DropdownOption>[] | undefined

interface CitySelectProps {
    value: string | undefined;
    options: string[];
    updateCity: (value: string | null) => void;
    disabled?: boolean;
    includedInAnnex?: boolean;
    controlBorderColor?: string;
    width?: string;
}

export const CitySelect: React.FC<CitySelectProps> = ({
    value,
    disabled = false,
    options,
    updateCity,
    includedInAnnex = false,
    controlBorderColor,
    width
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const onChange = (option: DropdownOption | Options<DropdownOption> | null) => {
        let dropdownValue = null;
        if (!isNull(option)) {
            dropdownValue = (option as DropdownOption).value;
        }
        if (!isEqual(dropdownValue, value)) {
            updateCity(dropdownValue);
        }
    };

    const mappedOptions: DropdownOptions = options.map(option => ({ value: option, label: capitaliseStartLetter(option) }));
    const mappedValue: DropdownOptions = value ? [{ value, label: capitaliseStartLetter(value) }] : undefined;

    const MAX_MENU_HEIGHT = 150;
    const menuPlacement = useMenuPlacement(dropdownRef, MAX_MENU_HEIGHT);

    const placeholder = includedInAnnex ? 'See Agency Annex' : 'Select...';

    return (
        <div
            data-testid='city-select-wrapper'
            className={styles.citySelectWrapper}
            ref={dropdownRef}
            style={{ width }}
        >
            <Select
                className={styles.citySelectField}
                classNamePrefix='ark-dropdown'
                isDisabled={disabled || includedInAnnex}
                value={mappedValue}
                onChange={onChange}
                options={mappedOptions}
                styles={customStyles}
                maxMenuHeight={MAX_MENU_HEIGHT}
                menuPlacement={menuPlacement}
                placeholder={placeholder}
                controlBorderColor={controlBorderColor}
                isClearable={!disabled && !includedInAnnex}
                fontWeight={500}
            />
        </div>
    );
};

interface TimeProps {
    id: string;
    value: string[];
    index: number;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    disabled?: boolean;
    placeholder?: string;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean;
    showAIModifiedUserCorrected?: boolean;
    isMLCorrection?: boolean;
    showClause?: boolean;
    isTable?: boolean;
    includedInAnnex?: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
}

export const Time: React.FC<TimeProps> = ({
    id,
    value,
    disabled = false,
    index,
    sectionId,
    groupIndex,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    isMLCorrection = false,
    showClause = false,
    isTable = false,
    includedInAnnex = false,
    datasetId,
    parentFieldId,
    modalInstance
}) => {
    const dispatch = useAppDispatch();
    const allDropdownLists = useAppSelector(getAllDropdownLists);
    const updateValue = (value: string[]) => {
        if (isMLCorrection) {
            dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
        }
    };

    const cityOptions = allDropdownLists.find(({ name }) => name === 'City')?.options || [];
    const cityOptionsPlusLocal = [...cityOptions, 'Local Time'];
    const updateTimeValue = (timeValue: string) => updateValue([timeValue, value[1]]);
    const updateCity = (cityValue: string | null) => updateValue([value[0], cityValue || '']);

    const isDisabled = useMemo(() => disabled || includedInAnnex, [disabled, includedInAnnex]);
    const controlBorderColor = useMemo(() => {
        if (!showDatasetUpdated && !showFieldUpdated && !showAIModified && !showAIModifiedUserCorrected) {
            return isDisabled ? disabledGrey : primary;
        }
        if (showAIModifiedUserCorrected) {
            return amber;
        }
        if (showAIModified) {
            return gold;
        }
        return showFieldUpdated ? amethyst : green;
    }, [showDatasetUpdated, showFieldUpdated, showAIModified, isDisabled, showAIModifiedUserCorrected]);

    return (
        <div className={classnames(styles.timeField, { [styles.withClauseLabel]: showClause && isTable })}>
            <input
                data-testid={`definition-time-${id}`}
                className={classnames(styles.timeValue, {
                    [styles.updatedInputField]: showFieldUpdated,
                    [styles.newInputField]: showDatasetUpdated,
                    [styles.aiInputField]: showAIModified,
                    [styles.aiCorrectedInputField]: showAIModifiedUserCorrected
                })}
                onChange={e => updateTimeValue(e.target.value)}
                value={value[0]}
                disabled={disabled || includedInAnnex}
                type='time'
            />
            <CitySelect
                options={cityOptionsPlusLocal}
                value={value[1]}
                updateCity={updateCity}
                disabled={disabled}
                includedInAnnex={includedInAnnex}
                controlBorderColor={controlBorderColor}
            />
        </div>
    );
};
