import { toast } from 'react-toastify';
import { put, takeEvery, all, fork, call, takeLeading, select, debounce } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { isEqual, last } from 'lodash/fp';

import { fetchAllMyDatasetsSuccessful, fetchAllMyDatasetsFailed, setMyDatasetView, fetchMyDatasetDefinitionsStarted, fetchMyDatasetDefinitionsSuccessful, fetchMyDatasetDefinitionsFailed, hiddenFieldsHaveUpdated, saveHiddenFieldsFailed, saveHiddenFieldsSuccessful, saveRiskToleranceFailed, saveRiskToleranceSuccessful, setRiskToleranceUpdated } from './actions';
import { MyDatasets, MyDatasetActionTypes, MyDatasetView, HiddenFields } from './types';
import { getDatasetDefinitionsByParentId, getAllMyDatasetsByParentId, upsertHiddenFields, upsertMyRiskTolerance } from '../../../../services/dataset';
import { DatasetDefinition } from '../../../datasets/store';
import { getCurrentHiddenFields, getCurrentRiskToleranceConfig, getMyRiskToleranceConfig, getSavedHiddenFields } from './selectors';
import { MyRiskTolerance, RiskTolerance } from '../../risk-tolerance/store';
import { getPathname } from '../../../auth/login/store';
import { DocumentSpecificHiddenFields } from '../../dataset-builder/store';

export function* attemptFetchAllMyDatasets() {
    try {
        const datasetDocumentNamesAndAgreementTypes: MyDatasets[] = yield call(getAllMyDatasetsByParentId);
        yield put(fetchAllMyDatasetsSuccessful(datasetDocumentNamesAndAgreementTypes));
    } catch (e) {
        toast.error('Unable to fetch the dataset document names and types. Please try again.');
        yield put(fetchAllMyDatasetsFailed((e as Error).message));
    }
}

function* fetchAllMyDatasetsWatcher() {
    yield takeEvery(MyDatasetActionTypes.FETCH_ALL_MY_DATASETS_STARTED, attemptFetchAllMyDatasets);
}

export function* redirectMyDatasetView({ payload }: ReturnType<typeof setMyDatasetView>) {
    const { view, datasetId, redirect } = payload;
    if (view !== MyDatasetView.LIST) {
        const pageView = () => {
            if (view === MyDatasetView.PREVIEW) {
                return 'preview';
            }
            if (view === MyDatasetView.CONFIGURE) {
                return 'configure';
            }
            return 'risk-tolerance';
        };
        const navigationPath = `/admin/my-datasets/${pageView()}/${datasetId}`;
        if (redirect) {
            yield put(push(navigationPath));
        }
    } else {
        const navigationPath = '/admin/my-datasets';
        yield put(push(navigationPath));
    }
}

function* redirectMyDatasetViewWatcher() {
    yield takeEvery(MyDatasetActionTypes.MY_DATASET_VIEW, redirectMyDatasetView);
}

export function* attemptFetchMyDatasetDefinitions({ payload }: ReturnType<typeof fetchMyDatasetDefinitionsStarted>) {
    try {
        const { definitions, hiddenFields, riskToleranceDefinitions, documentHiddenFields }: { definitions: DatasetDefinition[]; hiddenFields: HiddenFields; riskToleranceDefinitions: (RiskTolerance | MyRiskTolerance)[], documentHiddenFields: DocumentSpecificHiddenFields } = yield call(getDatasetDefinitionsByParentId, { datasetId: payload });
        yield put(fetchMyDatasetDefinitionsSuccessful(definitions, hiddenFields, riskToleranceDefinitions, documentHiddenFields));
    } catch (e) {
        toast.error('Unable to fetch dataset definitions. Please try again.');
        yield put(fetchMyDatasetDefinitionsFailed((e as Error).message));
    }
}

function* fetchMyDatasetDefinitionsWatcher() {
    yield takeLeading(MyDatasetActionTypes.FETCH_MY_DATASET_DEFINITIONS_STARTED, attemptFetchMyDatasetDefinitions);
}

export function* attemptUpsertHiddenFields() {
    try {
        const hiddenFields: HiddenFields = yield select(getCurrentHiddenFields);
        yield call(upsertHiddenFields, { hiddenFields });
        yield put(saveHiddenFieldsSuccessful());
        toast('Personalised dataset configuration updated successfully.');
    } catch (e) {
        toast.error('Unable to save your dataset configuration. Please try again.');
        yield put(saveHiddenFieldsFailed((e as Error).message));
    }
}

function* upsertHiddenFieldsWatcher() {
    yield takeLeading(MyDatasetActionTypes.SAVE_HIDDEN_FIELDS_STARTED, attemptUpsertHiddenFields);
}

export function* checkHiddenFieldsUpdated() {
    try {
        const savedHiddenFields: HiddenFields = yield select(getSavedHiddenFields);
        const currentHiddenFields: HiddenFields = yield select(getCurrentHiddenFields);
        const hiddenFieldsUpdated = !isEqual(Object.values(currentHiddenFields), Object.values(savedHiddenFields));
        yield put(hiddenFieldsHaveUpdated(hiddenFieldsUpdated));
    } catch (e) {
        toast.error('We encountered a problem checking if you had made any changes.');
    }
}

function* checkHiddenFieldsUpdatedWatcher() {
    yield debounce(500, MyDatasetActionTypes.TOGGLE_HIDDEN_FIELDS, checkHiddenFieldsUpdated);
}

export function* checkRiskToleranceUpdated() {
    const currentRiskToleranceConfig: (RiskTolerance | MyRiskTolerance)[] = yield select(getCurrentRiskToleranceConfig);
    const savedRiskToleranceConfig: (RiskTolerance | MyRiskTolerance)[] = yield select(getMyRiskToleranceConfig);
    const hasUpdated = !isEqual(currentRiskToleranceConfig, savedRiskToleranceConfig);
    yield put(setRiskToleranceUpdated(hasUpdated));
}

function* checkRiskToleranceUpdatedWatcher() {
    yield debounce(500, [MyDatasetActionTypes.UPDATE_RISK_FIELD_CONFIG, MyDatasetActionTypes.UPDATE_RISK_FIELD_WEIGHTING, MyDatasetActionTypes.TOGGLE_RISK_FIELD], checkRiskToleranceUpdated);
}

export function* attemptSaveRiskTolerance() {
    try {
        const currentRiskToleranceConfig: (RiskTolerance | MyRiskTolerance)[] = yield select(getCurrentRiskToleranceConfig);
        const savedRiskToleranceConfig: (RiskTolerance | MyRiskTolerance)[] = yield select(getMyRiskToleranceConfig);
        const updatedRiskToleranceDatasets = currentRiskToleranceConfig.filter(riskConfig => {
            const savedRiskConfig = savedRiskToleranceConfig.find(({ datasetId }) => riskConfig.datasetId === datasetId);
            return !!savedRiskConfig && !isEqual(savedRiskConfig, riskConfig);
        });
        const pathname: string = yield select(getPathname);
        const datasetId: number = parseInt(last(pathname.split('/'))!);
        const { newRiskTolerance }: { newRiskTolerance: (RiskTolerance | MyRiskTolerance)[]; } = yield call(upsertMyRiskTolerance, { riskTolerance: updatedRiskToleranceDatasets, datasetId });
        yield put(saveRiskToleranceSuccessful(newRiskTolerance));
        toast('Risk Tolerance saved');
    } catch (e) {
        toast.error('Unable to save risk tolerance. Please try again.');
        yield put(saveRiskToleranceFailed());
    }
}

function* saveRiskToleranceWatcher() {
    yield takeLeading(MyDatasetActionTypes.SAVE_RISK_TOLERANCE_STARTED, attemptSaveRiskTolerance);
}

export function* myDatasetsSaga() {
    yield all([
        fork(fetchAllMyDatasetsWatcher),
        fork(redirectMyDatasetViewWatcher),
        fork(fetchMyDatasetDefinitionsWatcher),
        fork(upsertHiddenFieldsWatcher),
        fork(checkHiddenFieldsUpdatedWatcher),
        fork(checkRiskToleranceUpdatedWatcher),
        fork(saveRiskToleranceWatcher)
    ]);
}
