import { flow, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { DoraReportingActionTypes, DoraReportingState, PreliminaryRoi } from './types';

const initialPreliminaryRoi: PreliminaryRoi = {
    currencyOfRegister: null
};

export const INITIAL_STATE: DoraReportingState = {
    documentDownloading: false,
    downloadError: null,
    preliminaryRoi: initialPreliminaryRoi,
    entitiesInScope: [],
    selectedInScopeEntity: null
};

export const doraReportingReducer: Reducer<DoraReportingState> = (state = INITIAL_STATE, { payload, type }): DoraReportingState => {
    switch (type) {
        case DoraReportingActionTypes.DOWNLOAD_REGISTER_OF_INFORMATION_STARTED:
            return flow(
                set('downloadError', null),
                set('documentDownloading', true)
            )(state);
        case DoraReportingActionTypes.DOWNLOAD_REGISTER_OF_INFORMATION_SUCCESSFUL: {
            return flow(
                set('documentDownloading', false),
                set('selectedInScopeEntity', null),
                set('preliminaryRoi.currencyOfRegister', null)
            )(state);
        }
        case DoraReportingActionTypes.DOWNLOAD_REGISTER_OF_INFORMATION_FAILED:
            return flow(
                set('downloadError', payload),
                set('documentDownloading', false)
            )(state);
        case DoraReportingActionTypes.SET_ROI_CURRENCY:
            return set('preliminaryRoi.currencyOfRegister', payload, state);
        case DoraReportingActionTypes.FETCH_ALL_IN_SCOPE_ENTITIES_SUCCESSFUL: {
            const selectedInScopeEntity = payload.length > 0 ? payload[0] : null;
            return flow(
                set('entitiesInScope', payload),
                set('selectedInScopeEntity', selectedInScopeEntity),
            )(state);
        }
        case DoraReportingActionTypes.SET_SELECTED_IN_SCOPE_ENTITY:
            return set('selectedInScopeEntity', payload, state);
        default:
            return state;
    }
};
