import { DateTime } from 'luxon';
import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import { RouteComponentProps } from 'react-router';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { DATABASE_DATE_FORMAT, dateNow, formatDate, isAfter, startOfDay } from '../../../../utils/luxon';
import { WorkflowCalendarToolbar } from './WorkflowCalendarToolbar';
import { WorkflowCalendarDateHeader } from './WorkflowCalendarDateHeader';
import { deleteScheduledActionStarted, fetchCalendarEventsStarted, getCalendarEvent, getConfirmDeleteEvent, getIsDeleting, getScheduledEvents, getWorkflowPage, initialEvent, runIndirectRulesStarted, SchedulerDB, setWorkflowPage, stripEvent, toggleDeleteEventConfirmationModal, toggleScheduleWizard, WorkflowPage, resetCalendar, SchedulerAction } from '../store';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { WorkflowWizard } from '../WorkflowWizard';
import { WorkflowCalendarEvent } from './WorkflowCalendarEvent';
import { fetchAllUsersStarted } from '../../users/store';
import { fetchAllAttestationFormsStarted, getAllAttestationForms } from '../../attestations/store';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { getUserRole } from '../../../auth/login/store';
import { systemAdminRole } from '../../../constants/permittedRoles';
import { Button } from '../../../shared/button/Button';
import { PlusButton } from '../../../shared/button/PlusButton';
import { PageToggleButton } from '../../../shared/button/PageToggleButton';
import { Table } from '../../../shared/icons';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal';
import { WorkflowRouteParams } from '../Workflow';
import styles from '../Workflow.module.scss';

export const WorkflowCalendar: React.FC<RouteComponentProps<WorkflowRouteParams>> = ({ match: { params } }) => {
    const testId = 'admin-worfklow-calendar';
    const userRole = useAppSelector(getUserRole);
    const isSystemAdmin = systemAdminRole.includes(userRole!);
    const localizer = luxonLocalizer(DateTime);
    const todaysDate = useMemo(() => new Date(), []);

    useFetchStarted([fetchAllAttestationFormsStarted(), fetchAllUsersStarted(), fetchCalendarEventsStarted()]);
    const dispatch = useAppDispatch();
    const event = useAppSelector(getCalendarEvent);
    const events = useAppSelector(getScheduledEvents);
    const deleteConfirmationModal = useAppSelector(getConfirmDeleteEvent);
    const attestationForms = useAppSelector(getAllAttestationForms);
    const isDeleting = useAppSelector(getIsDeleting);
    const page = useAppSelector(getWorkflowPage);
    const openWizard = useCallback((date: string | null = null) => (isNull(date) || isAfter(date, startOfDay(dateNow()))) && dispatch(toggleScheduleWizard(true, initialEvent(date))), [dispatch]);
    const openScheduledAction = useCallback((event: SchedulerDB) => dispatch(toggleScheduleWizard(true, stripEvent(event))), [dispatch]);

    const deleteScheduledAction = useCallback(() => dispatch(deleteScheduledActionStarted()), [dispatch]);
    const closeDeleteConfirmationModal = useCallback(() => dispatch(toggleDeleteEventConfirmationModal(null)), [dispatch]);

    const runIndirectRules = () => dispatch(runIndirectRulesStarted());

    const openCalendarView = useCallback(() => dispatch(setWorkflowPage(WorkflowPage.CALENDAR)), [dispatch]);
    const openListView = useCallback(() => dispatch(setWorkflowPage(WorkflowPage.LIST)), [dispatch]);

    const getAttestationFormName = useCallback((id: number) => attestationForms.find(({ attestationFormId }) => attestationFormId === id)?.name || '', [attestationForms]);

    const nameFormatter = useCallback((event: SchedulerDB) => {
        if (event.action === SchedulerAction.CREATE_ATTESTATION) {
            return getAttestationFormName(event.content.attestationFormId!);
        }
        return event.content.subject;
    }, [getAttestationFormName]);

    const deleteEventName = useMemo(() => !isNull(deleteConfirmationModal) ? nameFormatter(events.find(({ scheduledActionId }) => scheduledActionId === deleteConfirmationModal)!) : 'scheduled action', [deleteConfirmationModal, events, nameFormatter]);

    useEffect(() => () => {
        dispatch(resetCalendar(todaysDate));
    }, [dispatch, todaysDate]);

    const scheduledActionInParamsExists = useMemo(() => (params.scheduledActionId && events.map(({ scheduledActionId }) => scheduledActionId!.toString()).includes(params.scheduledActionId)) || false, [events, params]);
    useEffect(() => {
        if (!event && params.scheduledActionId && scheduledActionInParamsExists) {
            const scheduledAction = events.find(({ scheduledActionId }) => scheduledActionId!.toString() === params.scheduledActionId)!;
            openScheduledAction(scheduledAction);
        }
    }, [params, event, scheduledActionInParamsExists, events, openScheduledAction]);

    useEffect(() => {
        if (page !== WorkflowPage.CALENDAR) {
            openCalendarView();
        }
    }, [page, openCalendarView]);

    return (
        <div className={styles.calendarWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.workflowHeader}>
                <div className={styles.workflowLeftHeader}>
                    <div className={styles.workflowTitle} data-testid={`${testId}-title`}>Workflow</div>
                    <PlusButton onClick={() => openWizard()} testId={testId} fontSize={18} />
                </div>
                <PageToggleButton label='View All' onClick={openListView} icon={Table} testId='workflow-list' />
            </div>
            <Calendar
                events={events}
                views={['month']}
                defaultDate={todaysDate}
                localizer={localizer}
                popup={true}
                className={styles.workflowCalendar}
                selectable={true}
                onSelectSlot={slot => openWizard(formatDate(slot.start, DATABASE_DATE_FORMAT))}
                components={{
                    event: WorkflowCalendarEvent,
                    toolbar: WorkflowCalendarToolbar,
                    month: {
                        dateHeader: WorkflowCalendarDateHeader
                    }
                }}
            />
            {isSystemAdmin &&
                <div className={styles.runIndirectRulesButton}>
                    <Button onClick={runIndirectRules} label='Run Indirect Rules' testId='workflow-run-indirect-rules-button' />
                </div>
            }
            {event && <WorkflowWizard event={event} />}
            {!isNull(deleteConfirmationModal) &&
                <DeleteConfirmationModal
                    closeModal={closeDeleteConfirmationModal}
                    isDeleting={isDeleting}
                    deleteScheduledAction={deleteScheduledAction}
                    isOpen={!isNull(deleteConfirmationModal)}
                    name={deleteEventName}
                />
            }
        </div>
    );
};
