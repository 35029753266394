import { toast } from 'react-toastify';
import { put, takeEvery, all, fork, select, call, takeLeading } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { fetchPaginatedDoraCompanyEntities, upsertDoraEntity, fetchEntityFunctionIds, checkDoraFunctionMigration, CheckMigrationRequest, migrateCompanyFunctions, checkCompanyFunctionMigration } from '../../../../services/dora';
import { fetchAllMyCompanyEntities } from '../../../../services/entity';
import { getPathname } from '../../../auth/login/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { fetchPaginatedInScopeDoraCompaniesFailed, fetchPaginatedInScopeDoraCompaniesSuccessful, fetchPaginatedInScopeDoraCompaniesStarted, fetchPaginatedOutOfScopeDoraCompaniesStarted, fetchPaginatedOutOfScopeDoraCompaniesSuccessful, fetchPaginatedOutOfScopeDoraCompaniesFailed, toggleDoraMyCompaniesView, fetchMyCompanyEntitiesSuccessful, fetchMyCompanyEntitiesFailed, fetchEntityFunctionIdsStarted, fetchEntityFunctionIdsSuccessful, fetchEntityFunctionIdsFailed, saveCompanyEntityFailed, saveCompanyEntityStarted, fetchAvailableFunctionsForMigrationSuccessful, fetchAvailableFunctionsForMigrationFailed, companyFunctionMigrationCheckStarted, companyFunctionMigrationCheckSuccessful, companyFunctionMigrationCheckFailed, updateSelectedEntity, migrateCompanyFunctionsFailed, saveCompanyEntitySuccessful, toggleRoiMaintainer, setSelectedCompanyEntity } from './actions';
import { getOutOfScopeCompanyFilters, getInScopeCompanyFilters, getInScopeCompanyColumnSort, getOutOfScopeCompanyColumnSort, getOutOfScopeCompaniesPageSize, getInScopeCompaniesPageSize, getOutOfScopeCompaniesPageNumber, getInScopeCompaniesPageNumber, getSelectedDoraCompanyEntity, getSelectedEntityFunctionIds, getCurrentEntityFunctionIds, getFunctionMigration, getPaginatedInScopeDoraCompanies } from './selectors';
import { DoraMyCompaniesActionTypes, DoraMyCompaniesView, FunctionMigration } from './types';
import { BaseEntity, CompanyEntity } from '../../../admin/entity/store';

export function* attemptFetchPaginatedInScopeDoraCompanies({ payload }: ReturnType<typeof fetchPaginatedInScopeDoraCompaniesStarted>) {
    try {
        const { pageNumber } = payload;
        const filters: TableFilters = yield select(getInScopeCompanyFilters);
        const columnSort: ColumnSort | undefined = yield select(getInScopeCompanyColumnSort);
        const pageSize: number = yield select(getInScopeCompaniesPageSize);
        const { entities, total }: { entities: CompanyEntity[]; total: number; } = yield call(fetchPaginatedDoraCompanyEntities, { filters, pageNumber, columnSort, pageSize, doraInScope: 1 });
        yield put(fetchPaginatedInScopeDoraCompaniesSuccessful(entities, total, pageNumber));
    } catch (e) {
        toast.error('Unable to fetch the latest companies. Please try again.');
        yield put(fetchPaginatedInScopeDoraCompaniesFailed((e as Error).message));
    }
}

function* attemptFetchPaginatedInScopeDoraCompaniesWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_STARTED, attemptFetchPaginatedInScopeDoraCompanies);
}

export function* attemptFetchPaginatedOutOfScopeDoraCompanies({ payload }: ReturnType<typeof fetchPaginatedOutOfScopeDoraCompaniesStarted>) {
    try {
        const { pageNumber } = payload;
        const filters: TableFilters = yield select(getOutOfScopeCompanyFilters);
        const columnSort: ColumnSort | undefined = yield select(getOutOfScopeCompanyColumnSort);
        const pageSize: number = yield select(getOutOfScopeCompaniesPageSize);
        const { entities, total }: { entities: CompanyEntity[]; total: number; } = yield call(fetchPaginatedDoraCompanyEntities, { filters, pageNumber, columnSort, pageSize, doraInScope: 0 });
        yield put(fetchPaginatedOutOfScopeDoraCompaniesSuccessful(entities, total, pageNumber));
    } catch (e) {
        toast.error('Unable to fetch the latest companies. Please try again.');
        yield put(fetchPaginatedOutOfScopeDoraCompaniesFailed((e as Error).message));
    }
}

function* attemptFetchPaginatedOutOfScopeDoraCompaniesWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_STARTED, attemptFetchPaginatedOutOfScopeDoraCompanies);
}

export function* paginationInScopeNext() {
    const pageNumber: number = yield select(getInScopeCompaniesPageNumber);
    yield put(fetchPaginatedInScopeDoraCompaniesStarted(pageNumber + 1));
}

function* paginationInScopeNextWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.COMPANIES_IN_SCOPE_PAGINATION_NEXT, paginationInScopeNext);
}

export function* paginationInScopePrevious() {
    const pageNumber: number = yield select(getInScopeCompaniesPageNumber);
    yield put(fetchPaginatedInScopeDoraCompaniesStarted(pageNumber - 1));
}

function* paginationInScopePreviousWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.COMPANIES_IN_SCOPE_PAGINATION_PREVIOUS, paginationInScopePrevious);
}

export function* paginationOutOfScopeNext() {
    const pageNumber: number = yield select(getOutOfScopeCompaniesPageNumber);
    yield put(fetchPaginatedOutOfScopeDoraCompaniesStarted(pageNumber + 1));
}

function* paginationOutOfScopeNextWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.COMPANIES_OUT_OF_SCOPE_PAGINATION_NEXT, paginationOutOfScopeNext);
}

export function* paginationOutOfScopePrevious() {
    const pageNumber: number = yield select(getOutOfScopeCompaniesPageNumber);
    yield put(fetchPaginatedOutOfScopeDoraCompaniesStarted(pageNumber - 1));
}

function* paginationOutOfScopePreviousWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.COMPANIES_OUT_OF_SCOPE_PAGINATION_PREVIOUS, paginationOutOfScopePrevious);
}

export function* redirectCompaniesScopeTab({ payload }: ReturnType<typeof toggleDoraMyCompaniesView>) {
    const navigationPath = `/dora/data-management/companies${payload === DoraMyCompaniesView.IN_SCOPE ? '/in-scope' : '/out-of-scope'}`;
    yield put(push(navigationPath));
}

function* redirectCompaniesScopeTabWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.TOGGLE_MY_COMPANIES_VIEW, redirectCompaniesScopeTab);
}

export function* doraInScopeTableConfigUpdated() {
    yield put(fetchPaginatedInScopeDoraCompaniesStarted(1));
}

function* doraInScopeTableConfigUpdatedWatcher() {
    yield takeEvery([DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANY_TABLE_FILTERS, DoraMyCompaniesActionTypes.CLEAR_IN_SCOPE_COMPANY_TABLE_FILTERS, DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANY_TABLE_COLUMN_SORT, DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANIES_PAGE_SIZE], doraInScopeTableConfigUpdated);
}

export function* doraOutOfScopeTableConfigUpdated() {
    yield put(fetchPaginatedOutOfScopeDoraCompaniesStarted(1));
}

function* doraOutOfScopeTableConfigUpdatedWatcher() {
    yield takeEvery([DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS, DoraMyCompaniesActionTypes.CLEAR_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS, DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANY_TABLE_COLUMN_SORT, DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANIES_PAGE_SIZE], doraOutOfScopeTableConfigUpdated);
}

export function* attemptFetchMyCompanyEntities() {
    try {
        const entities: BaseEntity[] = yield call(fetchAllMyCompanyEntities);
        yield put(fetchMyCompanyEntitiesSuccessful(entities));
    } catch (e) {
        yield put(fetchMyCompanyEntitiesFailed((e as Error).message));
    }
}

function* attemptFetchMyCompanyEntitiesWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.FETCH_MY_COMPANY_ENTITIES_STARTED, attemptFetchMyCompanyEntities);
}

export function* attemptSaveCompanyEntity() {
    try {
        const companyEntity: CompanyEntity = yield select(getSelectedDoraCompanyEntity);
        const doraFunctionIds: number[] = yield select(getSelectedEntityFunctionIds);
        const pathname: string = yield select(getPathname);
        const isInScopeUrl = pathname.includes('in-scope');

        yield call(upsertDoraEntity, { entity: companyEntity, doraFunctionIds });

        if (isInScopeUrl) {
            const pageNumber: number = yield select(getInScopeCompaniesPageNumber);
            yield put(fetchPaginatedInScopeDoraCompaniesStarted(pageNumber));
            yield put(fetchEntityFunctionIdsStarted(companyEntity.entityId!));
        } else {
            const pageNumber: number = yield select(getOutOfScopeCompaniesPageNumber);
            yield put(fetchPaginatedOutOfScopeDoraCompaniesStarted(pageNumber));
        }
        yield put(saveCompanyEntitySuccessful());
        toast('Successfully updated Company Entity.');
    } catch (e) {
        yield put(saveCompanyEntityFailed((e as Error).message));
        toast.error('Unable to update Company Entity. Please try again.');
    }
}

function* attemptSaveCompanyEntityWatcher() {
    yield takeLeading(DoraMyCompaniesActionTypes.SAVE_COMPANY_ENTITY_STARTED, attemptSaveCompanyEntity);
}

export function* attemptFetchEntityFunctionIds({ payload }: ReturnType<typeof fetchEntityFunctionIdsStarted>) {
    try {
        const functionIds: number[] = yield call(fetchEntityFunctionIds, { entityId: payload });
        yield put(fetchEntityFunctionIdsSuccessful(functionIds));
    } catch (e) {
        toast.error('Unable to fetch the entity functions. Please try again.');
        yield put(fetchEntityFunctionIdsFailed((e as Error).message));
    }
}

function* attemptFetchEntityFunctionIdsWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.FETCH_ENTITY_FUNCTION_IDS_STARTED, attemptFetchEntityFunctionIds);
}

export function* attemptCheckCompanyFunctionsMigration({ payload }: ReturnType<typeof companyFunctionMigrationCheckStarted>) {
    try {
        const functionMigration: FunctionMigration = yield call(checkCompanyFunctionMigration, { entityId: payload });
        if (!functionMigration.migrations.length) {
            yield put(updateSelectedEntity('doraInScope', 0));
            yield put(saveCompanyEntityStarted());
        } else {
            yield put(companyFunctionMigrationCheckSuccessful(functionMigration));
        }
    } catch (e) {
        toast.error('Something went wrong. Please try again.');
        yield put(companyFunctionMigrationCheckFailed((e as Error).message));
    }
}

function* attemptCheckCompanyFunctionsMigrationWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.COMPANY_FUNCTION_MIGRATION_CHECK_STARTED, attemptCheckCompanyFunctionsMigration);
}

export function* attemptCheckDoraFunctionsAvailableForMigration() {
    try {
        const { entityId }: CompanyEntity = yield select(getSelectedDoraCompanyEntity);
        const selectedFunctionIds: number[] = yield select(getSelectedEntityFunctionIds);
        const currentFunctionIds: number[] = yield select(getCurrentEntityFunctionIds);
        const functionIds = currentFunctionIds.filter((id) => !selectedFunctionIds.includes(id));
        const reqBody: CheckMigrationRequest = { currentCompanyId: entityId, functionIds };
        const functionMigration: FunctionMigration = yield call(checkDoraFunctionMigration, reqBody);
        if (!functionMigration.migrations.length) {
            yield put(saveCompanyEntityStarted());
        } else {
            yield put(fetchAvailableFunctionsForMigrationSuccessful(functionMigration));
        }
    } catch (e) {
        toast.error('Something went wrong. Please try again.');
        yield put(fetchAvailableFunctionsForMigrationFailed((e as Error).message));
    }
}

function* attemptCheckDoraFunctionsAvailableForMigrationWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_STARTED, attemptCheckDoraFunctionsAvailableForMigration);
}

export function* attemptMigrateCompanyFunctions() {
    try {
        const functionMigration: FunctionMigration = yield select(getFunctionMigration);
        yield call(migrateCompanyFunctions, { functionMigration });
        yield put(saveCompanyEntityStarted());
    } catch (e) {
        yield put(migrateCompanyFunctionsFailed((e as Error).message));
        toast.error('Unable to migrate Company Functions. Please try again.');
    }
}

function* attemptMigrateCompanyFunctionsWatcher() {
    yield takeLeading(DoraMyCompaniesActionTypes.MIGRATE_COMPANY_FUNCTIONS_STARTED, attemptMigrateCompanyFunctions);
}

export function* attemptToggleRoiMaintainer({ payload }: ReturnType<typeof toggleRoiMaintainer>) {
    try {
        const companyEntities: CompanyEntity[] = yield select(getPaginatedInScopeDoraCompanies);
        const entity = companyEntities.find(entity => entity.entityId === payload);
        if (entity) {
            const functionIds: number[] = yield call(fetchEntityFunctionIds, { entityId: payload });
            yield put(fetchEntityFunctionIdsSuccessful(functionIds));
            yield put(setSelectedCompanyEntity(entity));
            const isMaintainer = entity.doraMaintainerROI === 0 ? 1 : 0;
            yield put(updateSelectedEntity('doraMaintainerROI', isMaintainer));
            yield put(saveCompanyEntityStarted());
        }
    } catch (e) {
        toast.error('Unable to update ROI Maintainer. Please try again.');
    }
}

function* toggleRoiMaintainerWatcher() {
    yield takeEvery(DoraMyCompaniesActionTypes.TOGGLE_ROI_MAINTAINER, attemptToggleRoiMaintainer);
}

export function* doraMyCompaniesSaga() {
    yield all([
        fork(attemptFetchPaginatedInScopeDoraCompaniesWatcher),
        fork(attemptFetchPaginatedOutOfScopeDoraCompaniesWatcher),
        fork(paginationInScopeNextWatcher),
        fork(paginationInScopePreviousWatcher),
        fork(paginationOutOfScopeNextWatcher),
        fork(paginationOutOfScopePreviousWatcher),
        fork(redirectCompaniesScopeTabWatcher),
        fork(doraInScopeTableConfigUpdatedWatcher),
        fork(doraOutOfScopeTableConfigUpdatedWatcher),
        fork(attemptFetchMyCompanyEntitiesWatcher),
        fork(attemptSaveCompanyEntityWatcher),
        fork(attemptFetchEntityFunctionIdsWatcher),
        fork(attemptCheckDoraFunctionsAvailableForMigrationWatcher),
        fork(attemptMigrateCompanyFunctionsWatcher),
        fork(attemptCheckCompanyFunctionsMigrationWatcher),
        fork(toggleRoiMaintainerWatcher)
    ]);
}
