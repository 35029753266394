import React, { useCallback } from 'react';
import { isUndefined } from 'lodash/fp';
import classnames from 'classnames';

import styles from './Table.module.scss';
import { ArkRow, ArkTableComponents } from './ArkTable';
import { TooltipCell } from './TooltipCell';
import { ColourCell } from './ColourCell';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const TableCell: React.FC<{
    row: ArkRow
    parentField: string;
    field: string;
    secondaryComponent?: keyof ArkTableComponents;
    valueFormatter?: (value: any, row: any) => string | string[] | boolean | null;
}> = ({ row, parentField, field, secondaryComponent, valueFormatter }) => {

    const getCellValues = useCallback((row: ArkRow, field: string): any[] => (row[parentField] as any[]).map(parentField => parentField[field]), [parentField]);

    const values = getCellValues(row, field);

    const getCell = useCallback((value: any, component: keyof ArkTableComponents | undefined) => {
        const valueFormatted = !isUndefined(valueFormatter) && valueFormatter(value, row) || null;

        if (isUndefined(component)) {
            return <div className={styles.tableTextCell}>{valueFormatted || value}</div>;
        }

        switch (component) {
            case 'tooltip':
                return <TooltipCell value={value} valueFormatted={valueFormatted as string} />;
            case 'colour':
                return <ColourCell value={value} valueFormatted={valueFormatted as string[]} />;
            default:
                return <div className={styles.tableTextCell}>UNKNOWN</div>;
        }
    }, [row, valueFormatter]);

    return (
        <div className={styles.tableCellWrapper}>
            {values.map((value, index) => (
                <div className={classnames(styles.tableCellRowWrapper, { [styles.tableCellRowBorder]: index !== values.length - 1 })} key={index} >{getCell(value, secondaryComponent)}</div>
            ))}
        </div>
    );
};
