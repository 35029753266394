import classnames from 'classnames';
import { isEqual, isNull } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';

import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { fetchAvailableDocumentNamesStarted } from '../../admin/documents/store';
import { fetchAllDropdownListsStarted } from '../../admin/dropdown-lists/store';
import { fetchAllEntitiesStarted } from '../../admin/entity/store';
import { CustomFilters, FilterTable } from '../../auth/login/store';
import { ReadOnlyCustomFiltersBody } from '../../home/custom-filters/ReadOnlyCustomFiltersBody';
import { Button } from '../button/Button';
import { Filter } from '../icons';
import { ModalHeader } from '../modal/ModalHeader';
import { Scrollable } from '../scrollable/Scrollable';
import { OverflowTooltip } from '../tooltip';
import styles from './CustomFilters.module.scss';

interface ApplyCustomFiltersModalProps {
    setCustomFilters: (filterId: string) => void;
    modalOpen: boolean;
    closeModal: (value: boolean) => void;
    customFilters: CustomFilters[];
    filterTable: FilterTable;
    selectedFilterId: string | null;
}

export const ApplyCustomFiltersModal: React.FC<ApplyCustomFiltersModalProps> = ({ setCustomFilters, modalOpen, closeModal, customFilters, filterTable, selectedFilterId }) => {
    const [selectedFilter, setSelectedFilter] = useState<CustomFilters | null>(null);

    useFetchStarted([fetchAvailableDocumentNamesStarted(), fetchAllEntitiesStarted(true), fetchAllDropdownListsStarted()]);

    const selectedId: string | null = useMemo(() => {
        if (!isNull(selectedFilter) || !isNull(selectedFilterId)) {
            if (!isNull(selectedFilter)) {
                return selectedFilter.id;
            }
            if (!isNull(selectedFilterId)) {
                return selectedFilterId;
            }
        }
        return null;
    }, [selectedFilter, selectedFilterId]);

    const closeCustomFiltersModal = useCallback(() => {
        closeModal(false);
        setSelectedFilter(null);
    }, [closeModal]);

    const onApplyCustomFilter = useCallback(() => {
        setCustomFilters(selectedId!);
        setSelectedFilter(null);
    }, [setCustomFilters, selectedId]);

    const selectFilterDetails = useCallback((filter: CustomFilters) => {
        const updateFilter = isEqual(filter, selectedFilter) ? null : filter;
        setSelectedFilter(updateFilter);
    }, [selectedFilter]);

    const isSelectedFilter = useCallback((filterId: string) => isEqual(filterId, selectedId), [selectedId]);

    const filterMainWidth = useMemo(() => !isNull(selectedFilter) ? '40%' : '100%', [selectedFilter]);

    return (
        <Modal
            isOpen={modalOpen}
            className={styles.customFiltersWrapper}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex', zIndex: 10 } }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className={styles.customFiltersContentWrapper}>
                <ModalHeader label={`My ${capitaliseStartLetter(filterTable)} Custom Filters`} icon={Filter} />
                <div className={styles.customFiltersContentBody}>
                    <div className={styles.customFiltersNames} style={{ width: filterMainWidth, borderRight: selectedFilter ? '1px solid black' : 'none' }}>
                        <Scrollable maxHeight={'calc(80vh - 109px)'}>
                            {customFilters.map((filter, index) => (
                                <OverflowTooltip
                                    className={classnames(styles.customFiltersLabel, {
                                        [styles.evenFilterLabel]: index % 2 && !isSelectedFilter(filter.id),
                                        [styles.selectedCustomFilter]: isSelectedFilter(filter.id)
                                    })}
                                    key={filter.id}
                                    onClick={() => selectFilterDetails(filter)}
                                    overlayText={filter.filterName} />
                            ))}
                        </Scrollable>
                    </div>
                    {selectedFilter &&
                        <div className={styles.customFiltersDetails}>
                            <Scrollable maxHeight={'calc(80vh - 109px)'}>
                                <ReadOnlyCustomFiltersBody selectedTile={selectedFilter} />
                            </Scrollable>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <Button onClick={closeCustomFiltersModal} label='Close' />
                <Button onClick={onApplyCustomFilter} disabled={!selectedFilter} label='Apply' />
            </div>
        </Modal>
    );
};
