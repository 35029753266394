import React from 'react';
import classnames from 'classnames';
import { noop } from 'lodash/fp';

import styles from './TablePreview.module.scss';
import { DatasetFieldType, SingleDatasetField, TableDatasetFieldType } from '../../../../datasets/store';
import { Text, Number, LongText, DateField, Dropdown, Checkbox, DatasetLink, Time, DocumentField, WYSIWYGField, PartySelect } from '../../../../datasets/fields';
import { widthsByType } from './TableHeader';
import { InformationTooltip } from '../../../../shared/tooltip';
import { CalculatorField } from '../../../../shared/calculator/constants';
import { CalculatorCell } from '../../../../datasets/fields/CalculatorCell';
import { CurrencyAmountField } from '../../../../datasets/fields/CurrencyAmountField';

const getField = (field: SingleDatasetField, index: number, showClause: boolean, isTable: boolean) => {
    const defaultProps = { id: field.id!, index, value: '', disabled: true, showClause, isTable, datasetId: 1, parentFieldId: '' };
    switch (field.type) {
        case DatasetFieldType.TEXT:
            return <Text {...defaultProps} />;
        case DatasetFieldType.LONGTEXT:
            return <LongText {...defaultProps} />;
        case DatasetFieldType.DATE:
            return <DateField {...defaultProps} value={null} />;
        case DatasetFieldType.DROPDOWN:
        case DatasetFieldType.LINKFIELD:
            return <Dropdown {...defaultProps} value={null} type={field.type} listId={field.settings.dropdownLinked!} />;
        case DatasetFieldType.PARTY:
            return <PartySelect {...defaultProps} value={null} />;
        case DatasetFieldType.DOCUMENT:
            return <DocumentField id={field.id!} value={undefined} isTable />;
        case DatasetFieldType.NUMBER:
            return <Number {...defaultProps} />;
        case DatasetFieldType.CHECKBOX:
            return <Checkbox {...defaultProps} checked={false} />;
        case DatasetFieldType.DATASET:
            return <DatasetLink {...defaultProps} value={null} childDatasetId='1' />;
        case DatasetFieldType.TIME:
            return <Time {...defaultProps} value={['', '']} />;
        case DatasetFieldType.WYSIWYG:
            return <WYSIWYGField {...defaultProps} value={null} isCollapsible={field.settings.isCollapsible} toolbarHidden={true} toggleSection={noop} getSectionOpen={() => false} />;
        case DatasetFieldType.CALCULATOR:
            return <CalculatorCell
                {...defaultProps}
                value={[] as CalculatorField[]}
                fieldType={field.settings.calculatorFieldType!}
                showOutputField={field.settings.showOutputField!}
                showGracePeriod={field.settings.showGracePeriod!}
                includeFreeTextOption={field.settings.includeFreeTextOption}
            />;
        case DatasetFieldType.CURRENCY_AMOUNT:
            return <CurrencyAmountField {...defaultProps} currencyValue={{ currency: null, value: undefined }} />;
        default:
            return <div className={styles.unknownFieldType}>Field type preview incomplete.</div>;
    }
};

interface TableCellProps {
    field: SingleDatasetField;
    index: number;
    isFirstCell: boolean;
    isLastCell: boolean;
}

export const TableCell: React.FC<TableCellProps> = ({ field, index, isFirstCell, isLastCell }) => {
    const cellWidth = `${widthsByType[field.type as TableDatasetFieldType]}px`;
    return (
        <div
            className={classnames(styles.cellWrapper, {
                [styles.firstCell]: isFirstCell,
                [styles.lastCell]: isLastCell
            })}
            style={{ width: cellWidth, minWidth: cellWidth }}
        >
            {getField(field, index, field.settings.showClause || false, true)}
            {field.settings.showClause && <InformationTooltip content='Clause Label Preview' label='cl' />}
        </div>
    );
};
