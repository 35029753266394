import { getOr } from 'lodash/fp';
import React, { useMemo } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { DropdownOption, customStyles } from '../../../../shared/dropdown/Dropdown';
import { ConfirmationModal } from '../../../../shared/modal/ConfirmationModal';
import { DropdownListDB, getAllDropdownLists } from '../../../dropdown-lists/store';
import { SettingsModal, updateSettingsValue } from '../../store';
import styles from '../DatasetComponents.module.scss';

interface DropdownListModalProps {
    isOpen: boolean;
    closeModal: () => void;
    settingsModalIndex: SettingsModal;
    value: string | null | undefined;
}

const mapDropdownListsToOptions = (lists: DropdownListDB[]) => lists
    .map(({ dropdownListId, name }) => ({ value: dropdownListId.toString(), label: name }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const DropdownListModal: React.FC<DropdownListModalProps> = ({ isOpen, closeModal, settingsModalIndex, value }) => {
    const dispatch = useAppDispatch();
    const { index, groupIndex } = settingsModalIndex;
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const selectedList = useMemo(() => dropdownLists.filter(({ dropdownListId }) => dropdownListId.toString() === value), [dropdownLists, value]);
    const options = useMemo(() => mapDropdownListsToOptions(dropdownLists), [dropdownLists]);
    const listValue = useMemo(() => selectedList ? mapDropdownListsToOptions(selectedList) : null, [selectedList]);
    const selectDropdown = (selected: SingleValue<DropdownOption> | MultiValue<DropdownOption> | null) => dispatch(updateSettingsValue('dropdownLinked', getOr(null, 'value', selected), index!, groupIndex));

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            showConfirm={false}
            closeLabel='Close'
            testId='dropdown-list'
            showOverlay={false}
        >
            <div className={styles.settingsModalLinkWrapper} data-testid='dropdown-link-wrapper'>
                <div className={styles.settingsModalLinkTitle}>Select Dropdown</div>
                <div className={styles.settingsModalLinkDropdown}>
                    <Select
                        classNamePrefix='ark-dropdown'
                        value={listValue}
                        onChange={selectDropdown}
                        options={options}
                        isClearable={true}
                        styles={customStyles}
                        fontWeight={500}
                    />
                </div>
            </div>
        </ConfirmationModal>
    );
};
