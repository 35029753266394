import { RawDraftContentState } from 'draft-js';

import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';

export enum DoraSupplyChainActionTypes {
    FETCH_ALL_FUNCTION_COMPANY_DETAILS_STARTED = 'FETCH_ALL_FUNCTION_COMPANY_DETAILS_STARTED',
    FETCH_ALL_FUNCTION_COMPANY_DETAILS_SUCCESSFUL = 'FETCH_ALL_FUNCTION_COMPANY_DETAILS_SUCCESSFUL',
    FETCH_ALL_FUNCTION_COMPANY_DETAILS_FAILED = 'FETCH_ALL_FUNCTION_COMPANY_DETAILS_FAILED',
    SET_SELECTED_COMPANY_ID = 'SET_SELECTED_DORA_SUPPLY_CHAIN_COMPANY_ID',
    SET_SELECTED_FUNCTION_ID = 'SET_SELECTED_DORA_SUPPLY_CHAIN_FUNCTION_ID',
    SET_DROPPABLE_HEIGHT = 'SET_DORA_SUPPLY_CHAIN_DROPPABLE_HEIGHT',
    TOGGLE_SUPPLY_CHAIN_ELEMENT = 'TOGGLE_SUPPLY_CHAIN_ELEMENT',
    TOGGLE_SUPPLY_CHAIN_DETAILS_MODAL_OPEN = 'TOGGLE_SUPPLY_CHAIN_DETAILS_MODAL_OPEN',
    FETCH_SUPPLY_CHAIN_CONFIGURATION_STARTED = 'FETCH_SUPPLY_CHAIN_CONFIGURATION_STARTED',
    FETCH_SUPPLY_CHAIN_CONFIGURATION_SUCCESSFUL = 'FETCH_SUPPLY_CHAIN_CONFIGURATION_SUCCESSFUL',
    FETCH_SUPPLY_CHAIN_CONFIGURATION_FAILED = 'FETCH_SUPPLY_CHAIN_CONFIGURATION_FAILED',
    UPDATE_SUPPLY_CHAIN_CONFIGURATION = 'UPDATE_SUPPLY_CHAIN_CONFIGURATION',
    UPDATE_SAVED_SUPPLY_CHAIN_CONFIGURATION = 'UPDATE_SAVED_SUPPLY_CHAIN_CONFIGURATION',
    OPEN_DORA_SUPPLY_CHAIN_FROM_PARAMS = 'OPEN_DORA_SUPPLY_CHAIN_FROM_PARAMS',
    UPDATE_NEW_DORA_SUPPLY_CHAIN_ELEMENT = 'UPDATE_DORA_SUPPLY_CHAIN_ELEMENT',
    UPDATE_DORA_SUPPLY_CHAIN_ELEMENT_DETAILS = 'UPDATE_DORA_SUPPLY_CHAIN_ELEMENT_DETAILS',
    FETCH_ALL_DORA_BASE_COMPANIES_STARTED = 'FETCH_ALL_DORA_BASE_COMPANIES_STARTED',
    FETCH_ALL_DORA_BASE_COMPANIES_SUCCESSFUL = 'FETCH_ALL_DORA_BASE_COMPANIES_SUCCESSFUL',
    FETCH_ALL_DORA_BASE_COMPANIES_FAILED = 'FETCH_ALL_DORA_BASE_COMPANIES_FAILED',
    SET_SUPPLY_CHAIN_ELEMENT_HAS_UPDATED = 'SET_SUPPLY_CHAIN_ELEMENT_HAS_UPDATED',
    SET_SUPPLY_CHAIN_HAS_UPDATED = 'SET_SUPPLY_CHAIN_HAS_UPDATED',
    UPSERT_SUPPLY_CHAIN_ELEMENT_STARTED = 'UPSERT_SUPPLY_CHAIN_ELEMENT_STARTED',
    UPSERT_SUPPLY_CHAIN_ELEMENT_SUCCESSFUL = 'UPSERT_SUPPLY_CHAIN_ELEMENT_SUCCESSFUL',
    UPSERT_SUPPLY_CHAIN_ELEMENT_FAILED = 'UPSERT_SUPPLY_CHAIN_ELEMENT_FAILED',
    UPSERT_SUPPLY_CHAIN_CONFIGURATION_STARTED = 'UPSERT_SUPPLY_CHAIN_CONFIGURATION_STARTED',
    UPSERT_SUPPLY_CHAIN_CONFIGURATION_SUCCESSFUL = 'UPSERT_SUPPLY_CHAIN_CONFIGURATION_SUCCESSFUL',
    UPSERT_SUPPLY_CHAIN_CONFIGURATION_FAILED = 'UPSERT_SUPPLY_CHAIN_CONFIGURATION_FAILED',
    UPDATE_COMPANY_FUNCTION_DETAILS = 'UPDATE_COMPANY_FUNCTION_DETAILS',
    SET_COMPANY_FILTER = 'SET_DORA_SUPPLY_COMPANY_FILTER',
    CLEAR_SUPPLY_CHAIN_TABLE_FILTERS = 'CLEAR_SUPPLY_CHAIN_TABLE_FILTERS',
    NAVIGATE_BACK_TO_FUNCTION_COMPANY_SELECT = 'NAVIGATE_BACK_TO_FUNCTION_COMPANY_SELECT',
    TOGGLE_DELETE_SUPPLY_CHAIN_ELEMENT_MODAL = 'TOGGLE_DELETE_SUPPLY_CHAIN_ELEMENT_MODAL',
    DELETE_SUPPLY_CHAIN_ELEMENT_STARTED = 'DELETE_SUPPLY_CHAIN_ELEMENT_STARTED',
    DELETE_SUPPLY_CHAIN_ELEMENT_SUCCESSFUL = 'DELETE_SUPPLY_CHAIN_ELEMENT_SUCCESSFUL',
    DELETE_SUPPLY_CHAIN_ELEMENT_FAILED = 'DELETE_SUPPLY_CHAIN_ELEMENT_FAILED',
    SET_SELECTED_SUPPLY_CHAIN_ELEMENT_TAB = 'SET_SELECTED_SUPPLY_CHAIN_ELEMENT_TAB',
    FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_STARTED = 'FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_STARTED',
    FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_SUCCESSFUL = 'FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_SUCCESSFUL',
    FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_FAILED = 'FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_FAILED',
    UPDATE_SUPPLY_CHAIN_ELEMENT_ASSESSMENT = 'UPDATE_SUPPLY_CHAIN_ELEMENT_ASSESSMENT',
    TOGGLE_IT_SERVICE_DESCRIPTION = 'TOGGLE_IT_SERVICE_DESCRIPTION',
    SET_SELECTED_ASSESSMENT_TIMELINE_DETAILS = 'SET_SELECTED_ASSESSMENT_TIMELINE_DETAILS',
    CANCEL_CONTRACT_DETAILS_UPDATES = 'CANCEL_CONTRACT_DETAILS_UPDATES',
    CANCEL_ASSESSMENT_DETAILS_UPDATES = 'CANCEL_ASSESSMENT_DETAILS_UPDATES',
    SET_UNSAVED_CHANGES_MODAL_OPEN = 'SET_UNSAVED_CHANGES_MODAL_OPEN',
    DOWNLOAD_DORA_DOCUMENT_STARTED = 'DOWNLOAD_DORA_DOCUMENT_STARTED',
    DOWNLOAD_DORA_DOCUMENT_SUCCESSFUL = 'DOWNLOAD_DORA_DOCUMENT_SUCCESSFUL',
    DOWNLOAD_DORA_DOCUMENT_FAILED = 'DOWNLOAD_DORA_DOCUMENT_FAILED',
    OPEN_DORA_DOCUMENT_STARTED = 'OPEN_DORA_DOCUMENT_STARTED',
    OPEN_DORA_DOCUMENT_SUCCESSFUL = 'OPEN_DORA_DOCUMENT_SUCCESSFUL',
    OPEN_DORA_DOCUMENT_FAILED = 'OPEN_DORA_DOCUMENT_FAILED',
    UPLOAD_DORA_DOCUMENT_STARTED = 'UPLOAD_DORA_DOCUMENT_STARTED',
    UPLOAD_DORA_DOCUMENT_SUCCESSFUL = 'UPLOAD_DORA_DOCUMENT_SUCCESSFUL',
    UPLOAD_DORA_DOCUMENT_FAILED = 'UPLOAD_DORA_DOCUMENT_FAILED',
    CLOSE_PREVIEW_DORA_DOCUMENT_MODAL = 'CLOSE_PREVIEW_DORA_DOCUMENT_MODAL',
    SET_DORA_DOCUMENT_FILES = 'SET_DORA_DOCUMENT_FILES',
    REMOVE_DORA_DOCUMENT_FILE = 'REMOVE_DORA_DOCUMENT_FILE',
    LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_STARTED = 'LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_STARTED',
    LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_SUCCESSFUL = 'LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_SUCCESSFUL',
    LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_FAILED = 'LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_FAILED',
    SET_ICT_CONTRACT_DISPLAY = 'SET_ICT_CONTRACT_DISPLAY'
}

export enum IctSubstitutabilityOptions {
    NOT_SUBSTITUTABLE = 'Not Substitutable',
    HIGHLY_COMPLEX = 'Highly complex substitutability',
    MEDIUM_COMPLEXITY = 'Medium complexity in terms of substitutability',
    EASY_COMPLEXITY = 'Easily substitutable'
}

export enum ReintegrationPossibility {
    EASY = 'Easy',
    DIFFICULT = 'Difficult',
    HIGHLY_COMPLEX = 'Highly Complex'
}

export enum LowMediumHighOptions {
    LOW = 'Low',
    MEDIUM = 'Medium',
    HIGH = 'High'
}

export enum AlternateIctProviders {
    YES = 'Yes',
    NO = 'No'
}

export enum IctContractDisplay {
   INSTANCE = 'instance',
   DOCUMENT = 'document'
}

export interface DoraFunctionCompanyDetails {
    isCriticalOrImportant?: boolean;
    isThirdParty?: boolean;
    isIntraGroup?: boolean;
}

export interface DoraFunctionCompany {
    doraFunctionCompanyId: number;
    doraFunctionId: number;
    companyId: number;
    details: DoraFunctionCompanyDetails;
    clientId: number;
    functionName: string;
    companyName: string;
}

export interface DoraSupplyChainDetails {
    serviceProvided: string | null;
    furtherICTServiceDetails: RawDraftContentState | null;
    isMaterial: boolean | undefined;
    justification: RawDraftContentState | null;
}

export interface LevelOfReliance {
    notSignificant: boolean;
    lowReliance: boolean;
    materialReliance: boolean;
    fullReliance: boolean;
}

export interface DetailsDocument {
    doraDocumentId: number;
    description: string;
}

export interface DoraSupplyChainContractualInfo {
    doraSupplyChainContractId?: number;
    documentDescription: string;
    location: string;
    originalDocumentId: number;
    mimeType: string;
    instanceId: number | undefined;
    executedDate: string | undefined;
}

export interface SubstitutabilityReasoning {
    lackOfAlternatives: boolean;
    difficultiesMigrating: boolean;
    both: boolean;
}

export interface DoraSupplyChainAssessmentDetails {
    doraSupplyChainAssessmentId?: number | null;
    ictSubstitutability: IctSubstitutabilityOptions | null;
    substitutabilityReasoning: SubstitutabilityReasoning;
    dateOfLastAudit: Date | null;
    exitPlan: boolean;
    reintegration: string | null;
    discontinuingImpact: string | null;
    alternativeIctProviders: string | null;
    alternateProviderIdentity?: string;
    documents: DetailsDocument[];
    furtherDetails: RawDraftContentState | null;
}

export interface DoraSupplyChainAssessmentInfo {
    doraSupplyChainAssessmentId?: number;
    details: DoraSupplyChainAssessmentDetails;
}

export interface DoraSupplyChainAssessmentTimeline {
    doraSupplyChainAssessmentId: number;
    details: DoraSupplyChainAssessmentDetails | null;
    effectiveFrom: string;
    effectiveTo: string | null;
    createdBy: string;
}

export interface SupplyChainElementDetails {
    contractualInfo: DoraSupplyChainContractualInfo | null;
    assessmentInfo: DoraSupplyChainAssessmentInfo;
    assessmentTimeline: DoraSupplyChainAssessmentTimeline[];
}

export interface SupplyChainElement {
    doraSupplyChainId: number;
    companyId: number | null;
    thirdPartyCompanyId: number | null;
    parentId: number | null;
    details: DoraSupplyChainDetails;
    hasContractDetails: boolean;
    hasAssessmentDetails: boolean;
    supplyChainRank: number | null;
    label: string;
}

export interface NewSupplyChainElement {
    companyId: number | null;
    thirdPartyCompanyId: number | null;
    details: DoraSupplyChainDetails;
}

export interface SupplyChain extends DoraFunctionCompany {
    supplyChain: SupplyChainElement[];
}

export enum DoraSupplyChainTableColumns {
    COMPANY = 'companyName',
    FUNCTION = 'functionName'
}

export enum SupplyChainElementTab {
    DETAILS = 'Details',
    CONTRACT = 'Contract',
    ASSESSMENT = 'Assessment'
}

export enum DoraDocumentType {
    ASSESSMENT = 'assessment'
}

export interface DoraDocumentPreview {
    url: string;
    mimeType: string;
    description: string;
    id: number;
}

export interface DoraSupplyChainState {
    doraFunctionCompanyDetails: DoraFunctionCompany[];
    isFetchingDoraFunctionCompanyDetails: boolean;
    selectedCompanyId: number | null;
    selectedFunctionId: number | null;
    droppableHeight: number;
    selectedSupplyChainId: null | number;
    supplyChainDetailsModalOpen: boolean;
    isFetchingSupplyChain: boolean;
    savedSupplyChain: SupplyChain | null;
    currentSupplyChain: SupplyChain | null;
    newSupplyChainElement: NewSupplyChainElement;
    supplyChainElementHasUpdated: boolean;
    supplyChainHasUpdated: boolean;
    availableCompanies: DropdownOption[];
    availableThirdPartyCompanies: DropdownOption[];
    isSaving: boolean;
    supplyChainFilters: TableFilters;
    deleteElementConfirmationModalOpen: boolean;
    isDeleting: boolean;
    serviceDescriptionOpen: boolean;
    selectedElementWizardTab: SupplyChainElementTab;
    savedSupplyChainElementDetails: SupplyChainElementDetails;
    supplyChainElementDetails: SupplyChainElementDetails;
    selectedSupplyChainAssessmentTimeline: DoraSupplyChainAssessmentTimeline | null;
    unsavedChangesModalOpen: boolean;
    isUploading: boolean;
    isOpening: boolean;
    isDownloading: boolean;
    preview: DoraDocumentPreview | null;
    assessmentInfoFiles: File[];
    supplyChainError: string;
    linkingContract: boolean;
    ictContractDisplay: IctContractDisplay;
}
