import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { isUndefined, noop } from 'lodash/fp';
import { select, scaleBand, scaleLinear, pointer } from 'd3';

import styles from './SharedAnalytics.module.scss';
import { Scrollable } from '../scrollable/Scrollable';
import { getDeadline } from '../table';
import { FixedXAxis } from './FixedXAxis';

const { french, white, fontFamily, lightGrey, primary } = styles;

export interface BarChartAnalytics {
    id: number;
    name: string;
    percentageFill: number;
    deadline?: string;
}

interface BarChartProps {
    data: BarChartAnalytics[];
    height: number;
    width: number;
    barHeight?: number;
    getPercentageFill?: (id: number) => number;
    getBackgroundColor?: (id: number) => string;
    onDblClick?: (id: number) => void;
    getTooltipDetails?: (id: number) => { height: number; width: number; content: string; } | null;
    testId?: string;
    showXAxisLabels?: boolean;
    xAxisDataPoints?: string[];
    xAxisLabel?: string;
}

const defaultPercentageFill = () => 100;
const defaultBackgroundColor = () => french;
const X_AXIS_OFFSET = 44;
const defaultXAxisPoints = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];

export const BarChart: React.FC<BarChartProps> = ({
    data,
    height,
    width,
    barHeight = 50,
    getPercentageFill = () => defaultPercentageFill(),
    getBackgroundColor = () => defaultBackgroundColor(),
    onDblClick = noop,
    getTooltipDetails,
    testId = 'bar-chart',
    showXAxisLabels = false,
    xAxisDataPoints = defaultXAxisPoints,
    xAxisLabel
}) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const chartHeight = useMemo(() => showXAxisLabels ? height - X_AXIS_OFFSET : height, [height, showXAxisLabels]);
    const svgHeight = useMemo(() => data.length * barHeight, [data.length, barHeight]);

    const tooltipPosition = useCallback((tooltipWidth: number, tooltipHeight: number, x: number, y: number) => {
        const tooltipOverflowX = x + tooltipWidth > width;
        const tooltipOverflowY = y + tooltipHeight > height;
        const translateX = tooltipOverflowX ? x - (tooltipWidth + 5) : x + 5;
        const translateY = tooltipOverflowY ? y - (tooltipHeight + 5) : y - 5;
        return `translate(${translateX}, ${translateY})`;
    }, [height, width]);

    useEffect(() => {
        const svg = select(svgRef.current);

        const yScale = scaleBand()
            .domain(data.map(({ id }) => id.toString()))
            .range([2, svgHeight - 2])
            .padding(0.2);

        const xScale = scaleLinear()
            .domain([0, width])
            .range([width, 2]);

        const defs = svg.append('defs').attr('class', 'defs');

        const gradients = data.map(({ id }) =>
            defs
                .append('linearGradient')
                .attr('id', `gradient-${id}`)
                .attr('x1', '0%')
                .attr('x2', `${getPercentageFill(id)}%`)
                .attr('y1', '0%')
                .attr('y2', '0%')
        );

        gradients.map(gradient =>
            gradient.selectAll('stop')
                .data((_, __, f) => [getBackgroundColor(parseInt(f[0].id.replace('gradient-', '').split(':')[0])), lightGrey])
                .enter()
                .append('stop')
                .style('stop-color', d => d)
                .attr('offset', (d, i) => `${100 * (i % 2) + 100}%`)
        );

        const barGs = svg
            .selectAll('.bar')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'bar-g')
            .attr('cursor', 'pointer');

        const bars = barGs.append('rect')
            .attr('id', ({ id }) => `${testId}-bar-${id}`)
            .attr('class', 'bar')
            .attr('fill', ({ id }) => `url(#gradient-${id})`)
            .attr('stroke', ({ deadline, id }) => deadline ? getDeadline(deadline).color : getBackgroundColor(id))
            .attr('stroke-width', 2)
            .attr('rx', 5)
            .attr('y', ({ id }) => yScale(id.toString())!)
            .attr('width', 0)
            .attr('x', () => xScale(width))
            .attr('height', 0);

        bars.transition()
            .attr('width', width > 4 ? width - 4 : width)
            .attr('height', yScale.bandwidth())
            .duration(1000);

        barGs.append('text')
            .attr('y', ({ id }) => yScale(id.toString())! + ((barHeight - 12) / 2))
            .attr('x', () => xScale(width) + 10)
            .attr('dy', '0.35em')
            .attr('fill', primary)
            .attr('text-anchor', 'left')
            .attr('font-size', '12px')
            .attr('font-family', fontFamily)
            .attr('font-weight', 600)
            .text(({ name }) => name);

        if (!isUndefined(getTooltipDetails)) {
            barGs
                .on('mouseover', () => tooltip.attr('display', null))
                .on('mouseout', () => tooltip.attr('display', 'none'))
                .on('mousemove', (e, { id }) => {
                    const [x, y] = pointer(e);
                    const tooltipDetails = getTooltipDetails(id);
                    if (tooltipDetails) {
                        tooltip.attr('transform', tooltipPosition(tooltipDetails.width, tooltipDetails.height, x, y));
                        tooltip.select('rect')
                            .attr('height', tooltipDetails.height)
                            .attr('width', tooltipDetails.width);
                        tooltip.select('text')
                            .html(tooltipDetails.content);
                    }
                });

            const tooltip = svg
                .append('g')
                .attr('class', 'tooltip')
                .attr('data-testid', `analytics-${testId}-bar-chart-tooltip`)
                .attr('display', 'none');

            tooltip
                .append('rect')
                .attr('rx', 5)
                .attr('stroke', french)
                .attr('stroke-width', 1)
                .attr('fill', white);

            tooltip
                .append('text')
                .attr('x', 10)
                .attr('y', 4)
                .attr('fill', french)
                .attr('text-anchor', 'start')
                .attr('font-size', '12px')
                .attr('font-family', fontFamily)
                .attr('font-weight', 600);
        }

        bars
            .on('dblclick', (_, { id }) => {
                onDblClick(id);
            });

        return () => {
            // Anything added to the svg on mount via .append must be removed on unmount to stop new elements being added each render
            svg.selectAll('.bar-g').remove();
            svg.selectAll('.bar').remove();
            svg.selectAll('.tooltip').remove();
            svg.selectAll('.defs').remove();
            svg.selectAll('.x-axis-label').remove();
        };

    }, [data, width, height, svgHeight, testId, getPercentageFill, getBackgroundColor, getTooltipDetails, tooltipPosition, onDblClick, barHeight]);

    return (
        <div data-testid={`analytics-${testId}-bar-chart-wrapper`} style={{ height: chartHeight, width }}>
            <Scrollable>
                <svg ref={svgRef} style={{ height: `${svgHeight}px`, width: '100%', overflow: 'visible' }} />
            </Scrollable>
            {showXAxisLabels && <FixedXAxis dataPoints={xAxisDataPoints} label={xAxisLabel} />}
        </div>
    );
};
