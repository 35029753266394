import React, { useMemo } from 'react';
import Tooltip from 'rc-tooltip';

import 'rc-tooltip/assets/bootstrap_white.css';
import { PlaybookHistory } from '../../../admin/playbook/store';
import { formatDate } from '../../../../utils/luxon';
import styles from './PlaybookHistory.module.scss';

const { french, white } = styles;

interface ContentProps {
    name: string;
    createdBy: string;
    createdDate: string;
    version: string;
    width: number;
}

const Content: React.FC<ContentProps> = ({ name, createdBy, createdDate, version, width }) => (
    <div style={{ maxWidth: `${width - 18}px` }} className={styles.overlayWrapper}>
        <div className={styles.playbookTitle}>{name}</div>
        <div className={styles.playbookAuthor}>Published By: {createdBy}</div>
        <div className={styles.playbookCreatedDate}>Published Date: {createdDate}</div>
        <div className={styles.playbookVersion}>Version: {version}</div>
    </div>
);

interface PlaybookHistoryTooltipProps {
    playbookHistory: PlaybookHistory | null;
    children: JSX.Element;
    width?: number;
}

export const PlaybookHistoryTooltip: React.FC<PlaybookHistoryTooltipProps> = ({ playbookHistory, children, width = 300 }) => {

    const tooltipStyles = {
        backgroundColor: white,
        color: french,
        borderColor: french,
        borderRadius: '5px',
        minHeight: '10px',
        display: 'flex'
    };

    const overlayStyle = {
        maxWidth: `${width}px`,
        width: 'fit-content'
    };

    const playbook = useMemo(() => {
        if (playbookHistory) {
            const { createdDate, name, versionMajor, versionMinor, createdBy } = playbookHistory;
            return { name, createdDate: formatDate(createdDate), version: `${versionMajor}.${versionMinor}`, createdBy };
        }
        return { name: 'Unknown', createdDate: 'Unknown', version: 'Unknown', createdBy: 'Unknown' };
    }, [playbookHistory]);

    const { name, createdDate, createdBy, version } = playbook;

    return (
        <Tooltip
            placement='bottom'
            overlay={<Content
                name={name}
                createdDate={createdDate}
                createdBy={createdBy}
                version={version}
                width={width}
            />}
            trigger='hover'
            overlayInnerStyle={tooltipStyles}
            overlayStyle={overlayStyle}
        >
            {children}
        </Tooltip>
    );
};
