import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Toggle } from '../../../shared/toggle';
import { AIGeneratedEntityField } from '../store';
import styles from './EntityType.module.scss';
import { CustomTooltip } from '../../../shared/tooltip';

const { primary, gold, amber, grey } = styles;

interface AiDropdownFieldProps {
    field: AIGeneratedEntityField | undefined;
    options: string[];
    updateValue: (value: string | null) => void;
    toggleConfirmation: (checked: boolean) => void;
    label?: string;
    testId: string;
    disabled?: boolean;
}

export const AiDropdownField: React.FC<AiDropdownFieldProps> = ({ field, updateValue, toggleConfirmation, options, label, testId, disabled }) => {
    const dropdownOptions = options.map(value => ({ value, label: value }));
    const dropdownValue = field && field.value ? { value: field.value, label: field.value } : null;
    const updateAIFieldDropdownOption = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = (option as DropdownOption).value;
        }
        updateValue(selected);
    }, [updateValue]);

    const controlBorderColor = useMemo(() => {
        if (disabled) {
            return grey;
        }
        if (field && field.aiPopulated && !field.aiConfirmed) {
            return field.value !== field.aiValue ? amber : gold;
        }

        return primary;
    }, [field, disabled]);

    const dropdownWidth = useMemo(() => field && field.aiPopulated && !field.aiConfirmed ? 'calc(100% - 40px' : '100%', [field]);

    return (
        <div className={styles.aiDropdownFieldWrapper}>
            {!isUndefined(label) && <div className={styles.aiDropdownFieldLabel}>{label}</div>}
            <div className={styles.aiDropdownToggleWrapper}>
                <div className={styles.aiDropdownField} style={{ width: dropdownWidth }}>
                    <Dropdown
                        testId={testId}
                        onChange={updateAIFieldDropdownOption}
                        value={dropdownValue}
                        options={dropdownOptions}
                        controlBorderColor={controlBorderColor}
                        menuPortalTarget={document.body}
                        disabled={disabled}
                    />
                </div>
                {field && field.aiPopulated && !field.aiConfirmed &&
                    <CustomTooltip overlayText='This value has been asserted with AI. Please check this is correct and toggle to confirm'>
                        <div className={styles.toggleWrapper}>
                            <Toggle onChange={toggleConfirmation} checked={field.aiConfirmed} />
                        </div>
                    </CustomTooltip>
                }
            </div>
        </div>
    );
};
