import React, { useMemo, useState } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { fetchAllDropdownListsStarted } from '../../../admin/dropdown-lists/store';
import { fetchAllEntitiesStarted } from '../../../admin/entity/store';
import { IncompleteDetailsModal } from '../../../shared/modal/IncompleteDetailsModal';
import { Spinner } from '../../../shared/spinner/Spinner';
import { OpinionDetails } from './OpinionDetails';
import { fetchOpinionsStarted, getIncompleteOpinion, getIncompleteOpinionUrl, getIsOpening, getIsUpdatingOpinion, getOpinionDetailsModalOpen, getOpinionDetailsUpdated, getSelectedIncompleteOpinions, skipIncompleteOpinion, toggleOpinionDetailsModal, updateOpinionDetailsStarted } from '../store';
import { fetchAvailableDocumentNamesStarted } from '../../../admin/documents/store';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { OpinionCommissionedBy } from '../../../admin/opinions/store';

export const OpinionDetailsModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const opinion = useAppSelector(getIncompleteOpinion);
    const isOpening = useAppSelector(getIsOpening);
    const opinionUrl = useAppSelector(getIncompleteOpinionUrl);
    const opinionDetailsModalOpen = useAppSelector(getOpinionDetailsModalOpen);
    const selectedIncompleteOpinions = useAppSelector(getSelectedIncompleteOpinions);
    const opinionDetailsUpdated = useAppSelector(getOpinionDetailsUpdated);
    const isUpdatingOpinionDetails = useAppSelector(getIsUpdatingOpinion);
    const [showDocumentPreview, setShowDocumentPreview] = useState<boolean>(true);

    const closeModal = () => dispatch(toggleOpinionDetailsModal(false));
    const completeUpload = () => dispatch(updateOpinionDetailsStarted());
    const updateAndSkip = () => opinionDetailsUpdated ? completeUpload() : dispatch(skipIncompleteOpinion());
    const toggleDocumentPreview = () => setShowDocumentPreview(!showDocumentPreview);

    useFetchStarted([
        fetchAllDropdownListsStarted(),
        fetchAllEntitiesStarted(),
        fetchAvailableDocumentNamesStarted(),
        fetchOpinionsStarted()
    ]);

    const mimeType = opinion && opinion.mimeType;
    const missingDescription = !isNull(opinion) && !opinion.description.length;
    const missingJurisdiction = !isNull(opinion) && !opinion.jurisdiction;
    const missingOpinionDate = !isNull(opinion) && !opinion.dateOfOpinion;
    const missingOpinionScope = !isNull(opinion) && !opinion.scope;
    const missingOpinionType = !isNull(opinion) && !opinion.type;
    const missingCommissionedBy = !isNull(opinion) && !opinion.commissionedBy;
    const missingCommissionedByIfOther = !isNull(opinion) && opinion.commissionedBy === OpinionCommissionedBy.OTHER && !opinion.commissionedByIfOther;
    const completeDisabled = missingDescription || missingJurisdiction || missingOpinionDate || missingOpinionScope || missingOpinionType || missingCommissionedBy || isUpdatingOpinionDetails;

    const completeDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (missingDescription) {
            disabledArray.push('Your document requires a description');
        }
        if (missingJurisdiction) {
            disabledArray.push('Your document requires a jurisdiction');
        }
        if (missingOpinionDate) {
            disabledArray.push('Your document requires a date of opinion');
        }
        if (missingOpinionScope) {
            disabledArray.push('Your document requires an opinion scope');
        }
        if (missingOpinionType) {
            disabledArray.push('Your document requires a type');
        }
        if (missingCommissionedBy || missingCommissionedByIfOther) {
            disabledArray.push('Your document requires a commissioner');
        }
        return disabledArray;
    }, [
        missingDescription,
        missingJurisdiction,
        missingOpinionDate,
        missingOpinionScope,
        missingOpinionType,
        missingCommissionedBy,
        missingCommissionedByIfOther
    ]);

    const updateAndSkipLabel = useMemo(() => {
        if (opinionDetailsUpdated) {
            return selectedIncompleteOpinions.length < 2 ? 'Update & Close' : 'Update & Skip';
        }
        return 'Skip';
    }, [opinionDetailsUpdated, selectedIncompleteOpinions]);

    const updateAndSkipDisabled = (selectedIncompleteOpinions.length < 2 && !opinionDetailsUpdated) || !completeDisabled || isUpdatingOpinionDetails;
    const updateAndSkipDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (selectedIncompleteOpinions.length < 2 && !opinionDetailsUpdated) {
            disabledArray.push('This is the only remaining opinion that you recently uploaded');
        }
        if (!completeDisabled && !isOpening) {
            disabledArray.push('Opinion is complete');
        }
        return disabledArray;
    }, [opinionDetailsUpdated, selectedIncompleteOpinions, completeDisabled, isOpening]);

    return (
        <IncompleteDetailsModal
            isOpen={opinionDetailsModalOpen}
            closeModal={closeModal}
            completeDisabledTooltip={completeDisabledTooltip}
            completeUpload={completeUpload}
            completeDisabled={completeDisabled}
            updateAndSkipDisabled={updateAndSkipDisabled}
            updateAndSkip={updateAndSkip}
            updateAndSkipDisabledTooltip={updateAndSkipDisabledTooltip}
            updateAndSkipLabel={updateAndSkipLabel}
            url={opinionUrl}
            isOpeningDocument={isOpening}
            label='Incomplete Opinion Details'
            mimeType={mimeType}
            showDocumentPreview={showDocumentPreview}
            toggleDocumentPreview={toggleDocumentPreview}
        >
            {opinion ? <OpinionDetails opinion={opinion} /> : <Spinner size={70} />}
        </IncompleteDetailsModal>
    );
};
