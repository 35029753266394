import React, { useEffect, useState, useMemo, MouseEvent, useCallback } from 'react';
import { getOr, isNull, isUndefined } from 'lodash/fp';

import { getDatasetDefinition, getDefinitionHasIncompleteFields, getSettingsLinkModalOpen, SettingsModalType, toggleSettingsLinkModal, toggleSettingsMenuOpen, updateSettingsValue } from '../store';
import { DatasetFieldSettings, DatasetFieldType, SingleDatasetField } from '../../../datasets/store';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Icon as ArkIcon } from '../../../shared/icon/Icon';
import { Settings as SettingsIcon } from '../../../shared/icons';
import sharedStyles from '../SharedBuilder.module.scss';
import { Position } from '../../../shared/modal/PositionModal';
import { DatasetLinkModal, DropdownListModal, LinkfieldEntityTypeModal, DocumentPropertyModal, CalculatorTypeModal, HighlightModal, LabelContentModal, SettingsModal } from './settings-modal';
import { validateField } from '../store/utils';

const { red, primary } = sharedStyles;

interface SettingsProps {
    settings: DatasetFieldSettings;
    index: number;
    testId: string;
    isGroup?: boolean;
    groupIndex?: number;
    field: SingleDatasetField;
}

export const Settings: React.FC<SettingsProps> = ({ settings, index, testId, isGroup = false, groupIndex, field }) => {
    const { type } = field;
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);
    const [highlightPosition, setHighlightPosition] = useState<Position | null>(null);
    const datasetDefinition = useAppSelector(getDatasetDefinition);
    const incompleteFields = useAppSelector(getDefinitionHasIncompleteFields);
    const isAgency: boolean = useMemo(() => getOr(false, 'settings.isAgency', datasetDefinition), [datasetDefinition]);

    const toggleMenuOpen = useCallback((value: boolean) => {
        isGroup ? dispatch(toggleSettingsMenuOpen(index, value, groupIndex)) : dispatch(toggleSettingsMenuOpen(index, value));
    }, [isGroup, dispatch, groupIndex, index]);

    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        setIsOpen(true);
        toggleMenuOpen(true);
    };

    const closeModal = useCallback(() => {
        setIsOpen(false);
        toggleMenuOpen(false);
    }, [toggleMenuOpen]);

    const settingsLinkModalOpen = useAppSelector(getSettingsLinkModalOpen);

    const openSettingsModalType = useCallback((type: SettingsModalType) => dispatch(toggleSettingsLinkModal(index, type, groupIndex)), [dispatch, index, groupIndex]);
    const closeSettingsModalLink = () => dispatch(toggleSettingsLinkModal(null, null));
    const openDatasetLink = useCallback(() => {
        openSettingsModalType(DatasetFieldType.DATASET);
        closeModal();
    }, [openSettingsModalType, closeModal]);
    const openDropdownLink = useCallback(() => {
        openSettingsModalType(DatasetFieldType.DROPDOWN);
        closeModal();
    }, [openSettingsModalType, closeModal]);
    const openEntityModal = useCallback(() => {
        openSettingsModalType(DatasetFieldType.LINKFIELD);
        closeModal();
    }, [openSettingsModalType, closeModal]);
    const openDocumentProperty = useCallback(() => openSettingsModalType(DatasetFieldType.DOCUMENT), [openSettingsModalType]);
    const openLabelContentModal = useCallback(() => openSettingsModalType(DatasetFieldType.LABEL), [openSettingsModalType]);
    const openCalculatorTypeModal = useCallback(() => {
        openSettingsModalType(DatasetFieldType.CALCULATOR);
        closeModal();
    }, [openSettingsModalType, closeModal]);

    const openHighlightPalette = (e: MouseEvent<HTMLDivElement>) => {
        setHighlightPosition({ x: e.clientX, y: e.clientY });
        openSettingsModalType('highlight');
    };

    const closeHighlightPalette = () => {
        setHighlightPosition(null);
        closeSettingsModalLink();
    };

    const toggleSetting = (key: keyof DatasetFieldSettings, value: boolean | null) => {
        isGroup ? dispatch(updateSettingsValue(key, value, index, groupIndex)) : dispatch(updateSettingsValue(key, value, index));
    };

    const indexesMatch = (linkModalIndex: number | null | undefined, index: number | undefined) => !isNull(linkModalIndex) && linkModalIndex === index;
    const isModalOpen = (type: SettingsModalType) =>
        indexesMatch(settingsLinkModalOpen.index, index)
        && type === settingsLinkModalOpen.type
        && ((!isGroup && isUndefined(settingsLinkModalOpen.groupIndex)) || indexesMatch(settingsLinkModalOpen.groupIndex, groupIndex));

    useEffect(() => {
        if (settings.isOpen) {
            setIsOpen(settings.isOpen);
        }
    }, [settings.isOpen]);

    const highlightedField = useMemo(() => incompleteFields && !validateField(field) ? red : primary, [incompleteFields, field]);

    return (
        <>
            <button className={sharedStyles.settingsWrapper} onClick={e => openModal(e.clientX, e.clientY)} data-testid={`${testId}-button`}>
                <ArkIcon icon={SettingsIcon} fontSize={25} color={highlightedField} />
            </button>
            <SettingsModal
                isOpen={isOpen}
                closeModal={closeModal}
                position={position}
                content={settings}
                toggleSetting={toggleSetting}
                openDatasetLink={openDatasetLink}
                openDropdownLink={openDropdownLink}
                openEntityModal={openEntityModal}
                openDocumentProperty={openDocumentProperty}
                openLabelContentModal={openLabelContentModal}
                openCalculatorTypeModal={openCalculatorTypeModal}
                openHighlightPalette={openHighlightPalette}
                isAgency={isAgency}
                type={type}
            />
            <DatasetLinkModal
                isOpen={isModalOpen(DatasetFieldType.DATASET)}
                closeModal={closeSettingsModalLink}
                settingsModalIndex={settingsLinkModalOpen}
                value={settings.datasetLinked}
            />
            <DropdownListModal
                isOpen={isModalOpen(DatasetFieldType.DROPDOWN)}
                closeModal={closeSettingsModalLink}
                settingsModalIndex={settingsLinkModalOpen}
                value={settings.dropdownLinked}
            />
            <LinkfieldEntityTypeModal
                isOpen={isModalOpen(DatasetFieldType.LINKFIELD)}
                closeModal={closeSettingsModalLink}
                settingsModalIndex={settingsLinkModalOpen}
                value={settings.entityType}
            />
            <DocumentPropertyModal
                isOpen={isModalOpen(DatasetFieldType.DOCUMENT)}
                closeModal={closeSettingsModalLink}
                settingsModalIndex={settingsLinkModalOpen}
                value={settings.documentProperty}
            />
            <CalculatorTypeModal
                isOpen={isModalOpen(DatasetFieldType.CALCULATOR)}
                closeModal={closeSettingsModalLink}
                settingsModalIndex={settingsLinkModalOpen}
                fieldType={settings.calculatorFieldType}
                showGracePeriod={settings.showGracePeriod}
                showOutputField={settings.showOutputField}
                includeFreeTextOption={settings.includeFreeTextOption}
            />
            {!isUndefined(settings.labelContent) &&
                <LabelContentModal
                    isOpen={isModalOpen(DatasetFieldType.LABEL)}
                    closeModal={closeSettingsModalLink}
                    settingsModalIndex={settingsLinkModalOpen}
                    value={settings.labelContent}
                />
            }
            {!isNull(highlightPosition) &&
                <HighlightModal
                    isOpen={isModalOpen('highlight')}
                    closeModal={closeHighlightPalette}
                    settingsModalIndex={settingsLinkModalOpen}
                    highlight={settings.highlight}
                    position={highlightPosition}
                />
            }
        </>
    );
};
