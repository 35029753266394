import classnames from 'classnames';
import { isNull, max, noop } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Thumbnail, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { IconButton } from '../../shared/button/IconButton';
import { Home } from '../../shared/icons';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Spinner } from '../../shared/spinner/Spinner';
import { CustomTooltip } from '../../shared/tooltip';
import styles from './DocumentViewer.module.scss';
import { PageWithObserver } from './PDFPage';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface ReactPdf {
    numPages: number;
}

interface PDFViewerProps {
    pdfUrl: string;
    onLoadSuccess: (pdf: ReactPdf) => void;
    resetNumPages: () => void;
    numPages: number | null;
    width: number;
    selectedPage?: number | null;
    showScheduleStartPage?: boolean;
    setSelectedPage?: (page: number | null) => void;
    scheduleStartPage?: number;
}

const THUMBNAIL_PADDING_AND_SCROLLBAR_OFFSET = 20;

export const PDFViewer: React.FC<PDFViewerProps> = ({
    pdfUrl,
    onLoadSuccess,
    numPages,
    resetNumPages,
    width,
    selectedPage = null,
    showScheduleStartPage = false,
    setSelectedPage = noop,
    scheduleStartPage = 1
}) => {
    const [showThumbnails, setShowThumbnails] = useState<boolean>(true);
    const [visiblePages, setVisiblePages] = useState({});
    const [pdfLoading, setPdfLoading] = useState<boolean>(false);
    const pagesRef = useRef<Map<number, HTMLDivElement> | null>(null);
    const thumbnailsRef = useRef<Map<number, HTMLDivElement> | null>(null);
    const pageArray = useMemo(() => numPages ? new Array(numPages).fill(1).map((_, index) => index + 1) : [], [numPages]);

    const setPageVisibility = useCallback((pageNumber: number, isIntersecting: boolean) => setVisiblePages((prevVisiblePages) => ({
        ...prevVisiblePages,
        [pageNumber]: isIntersecting
    })), []);

    const getPagesMap = useCallback(() => {
        if (!pagesRef.current) {
            pagesRef.current = new Map<number, HTMLDivElement>();
        }
        return pagesRef.current!;
    }, []);

    const setPageRef = useCallback((element: HTMLDivElement | null, pageNumber: number) => {
        const map = getPagesMap();
        if (element) {
            map.set(pageNumber, element);
        } else {
            map.delete(pageNumber);
        }
    }, [getPagesMap]);

    const scrollToPage = useCallback((pageNumber: number) => {
        const pages = getPagesMap();
        const node = pages.get(pageNumber);
        if (node) {
            node.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
            setSelectedPage(null);
        }
    }, [getPagesMap, setSelectedPage]);

    const getThumbnailsMap = useCallback(() => {
        if (!thumbnailsRef.current) {
            thumbnailsRef.current = new Map<number, HTMLDivElement>();
        }
        return thumbnailsRef.current!;
    }, []);

    const setThumbnailRef = useCallback((element: HTMLDivElement | null, pageNumber: number) => {
        const map = getThumbnailsMap();
        if (element) {
            map.set(pageNumber, element);
        } else {
            map.delete(pageNumber);
        }
    }, [getThumbnailsMap]);

    const scrollToThumbnail = useCallback((pageNumber: number) => {
        const thumbnails = getThumbnailsMap();
        const node = thumbnails.get(pageNumber);
        if (node) {
            node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, [getThumbnailsMap]);

    const toggleThumbnail = useCallback(() => setShowThumbnails(!showThumbnails), [showThumbnails]);

    const pagesWidth = useMemo(() => showThumbnails ? 0.9 : 1, [showThumbnails]);

    const currentPage = useMemo(() => max(Object.entries(visiblePages).filter(([, value]) => value).map(([key]) => parseInt(key))) || 1, [visiblePages]);

    const checkThumbnailsScrolled = useCallback(() => {
        const thumbnails = getThumbnailsMap();
        const node = thumbnails.get(currentPage);
        if (node) {
            const { top, bottom } = node.getBoundingClientRect();
            const isVisible = (top >= 0) && (bottom <= window.innerHeight);
            if (!isVisible) {
                node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
    }, [getThumbnailsMap, currentPage]);

    const completeLoading = useCallback((pdf: ReactPdf) => {
        setPdfLoading(false);
        onLoadSuccess(pdf);
    }, [onLoadSuccess]);

    const onLoading = useCallback(() => {
        setVisiblePages({});
        resetNumPages();
        setPdfLoading(true);
    }, [resetNumPages]);

    const previous = useCallback(() => scrollToPage(currentPage - 1), [scrollToPage, currentPage]);
    const next = useCallback(() => scrollToPage(currentPage + 1), [scrollToPage, currentPage]);

    const sidebarContent = useMemo(() => {
        if (showThumbnails) {
            return pageArray.map(page => (
                <div key={`thumbnail_${page}`} className={styles.thumbnailWrapper}>
                    <div className={styles.thumbnailPageNumber} style={{ left: 3, top: 3 }}>{page}</div>
                    <div className={classnames(styles.thumbnail, { [styles.selectedThumbnail]: page === currentPage })}>
                        <Thumbnail
                            pageNumber={page}
                            loading=''
                            width={width * 0.1 - THUMBNAIL_PADDING_AND_SCROLLBAR_OFFSET}
                            inputRef={element => setThumbnailRef(element, page)}
                        />
                    </div>
                </div>
            ));
        }
        return null;
    }, [pageArray, showThumbnails, width, setThumbnailRef, currentPage]);

    useEffect(() => {
        if (!isNull(selectedPage)) {
            scrollToPage(selectedPage);
        }
    }, [selectedPage, scrollToPage]);

    useEffect(() => {
        const thumbnailTimer = setTimeout(() => checkThumbnailsScrolled(), 1000);
        return () => {
            clearTimeout(thumbnailTimer);
        };
    }, [checkThumbnailsScrolled]);

    return (
        <div className={styles.pdfViewerWrapper}>
            <div className={styles.controlBarWrapper}>
                <button className={styles.sideMenuButton} onClick={toggleThumbnail}>
                    {Array(4).fill(0).map((val, i) => val + i).map(index => (
                        <div key={index} className={styles.thumbnailTile} />
                    ))}
                </button>
                <div className={styles.paginationWrapper}>
                    {pdfLoading ? (
                        <Spinner size={20} />
                    ) : (
                        <div className={styles.buttonAndIconWrapper}>
                            <div className={styles.buttonWrapper}>
                                <button className={styles.paginationButton} onClick={previous} disabled={currentPage === 1}>{'<'}</button>
                                <div className={styles.pageNumber}>{currentPage} / {numPages}</div>
                                <button className={styles.paginationButton} onClick={next} disabled={currentPage === numPages}>{'>'}</button>
                            </div>
                            {showScheduleStartPage &&
                                <CustomTooltip overlayText='Scroll to the start of schedule' placement='top'>
                                    <div className={styles.iconWrapper}>
                                        <IconButton icon={Home} onClick={() => setSelectedPage(scheduleStartPage)} fontSize={30} />
                                    </div>
                                </CustomTooltip>
                            }
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.pdfViewer}>
                <Document
                    file={pdfUrl}
                    onLoadSuccess={completeLoading}
                    onLoadProgress={onLoading}
                    loading={<Spinner />}
                    onItemClick={({ pageNumber }) => {
                        scrollToPage(pageNumber);
                    }}
                    className={styles.documentPdfWrapper}
                >
                    <div className={styles.pdfContent}>
                        {showThumbnails &&
                            <div className={styles.sidebarWrapper} style={{ width: '10%' }}>
                                <Scrollable>
                                    {sidebarContent}
                                </Scrollable>
                            </div>
                        }
                        <div className={styles.documentWrapper} style={{ width: `${pagesWidth * 100}%` }}>
                            <Scrollable>
                                {pageArray.map(page => (
                                    <PageWithObserver
                                        key={`page_${page}`}
                                        pageNumber={page}
                                        width={width}
                                        setRef={setPageRef}
                                        pagesWidth={pagesWidth}
                                        setPageVisibility={setPageVisibility}
                                        scrollToThumbnail={scrollToThumbnail}
                                    />
                                ))}
                            </Scrollable>
                        </div>
                    </div>
                </Document>
            </div>
        </div>
    );
};
