import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { Button } from '../../../shared/button/Button';
import { UnsavedChangesModal } from '../../../shared/modal/UnsavedChangesModal';
import { DatasetView, getDatasetView, removeAllFieldSections, setDatasetDocumentConfigUpdated, resetDocumentDatasetConfigure, toggleDatasetView, getUnsavedHiddenDocumentChangesModalOpen, toggleUnsavedDatasetConfigurationModal, saveDocumentDatasetConfigurationStarted, setPreviewSelectedDocument, getDatasetDocumentConfigUpdated } from '../store';
import styles from './ConfigureDatasets.module.scss';

interface DocumentDatasetsButtonsProps {
    datasetId: number;
}

export const DocumentDatasetsButtons: React.FC<DocumentDatasetsButtonsProps> = ({ datasetId }) => {
    const dispatch = useAppDispatch();

    const configUpdated = useAppSelector(getDatasetDocumentConfigUpdated);
    const unsavedChangesModalOpen = useAppSelector(getUnsavedHiddenDocumentChangesModalOpen);
    const datasetView = useAppSelector(getDatasetView);
    const collapseAllFieldsAndSections = () => dispatch(removeAllFieldSections());
    const back = useCallback(() => {
        dispatch(removeAllFieldSections());
        dispatch(setDatasetDocumentConfigUpdated(false));
        dispatch(resetDocumentDatasetConfigure());
        dispatch(toggleUnsavedDatasetConfigurationModal(false));
        dispatch(toggleDatasetView(DatasetView.DOCUMENTS_LIST));
        dispatch(setPreviewSelectedDocument(null));
    }, [dispatch]);
    const toggleUnsavedChangesModalOpen = useCallback((isOpen: boolean) => dispatch(toggleUnsavedDatasetConfigurationModal(isOpen)), [dispatch]);
    const onBackClick = useCallback(() => configUpdated ? toggleUnsavedChangesModalOpen(true) : back(), [configUpdated, toggleUnsavedChangesModalOpen, back]);
    const goToView = useCallback((view: DatasetView) => dispatch(toggleDatasetView(view, datasetId)), [dispatch, datasetId]);
    const saveDatasetConfiguration = useCallback(() => dispatch(saveDocumentDatasetConfigurationStarted()), [dispatch]);

    const buttons = useMemo(() => (
        [DatasetView.DOCUMENTS_PREVIEW, DatasetView.DOCUMENTS_CONFIGURE].filter(view => view !== datasetView)
            .map(view => (<Button key={view} label={capitaliseStartLetter(view)} onClick={() => goToView(view)} />))
    ), [datasetView, goToView]);

    const disabledTooltip = useMemo(() => !configUpdated ? ['You have not updated any of the fields.'] : null, [configUpdated]);

    return (
        <div className={styles.buttonWrapper}>
            <div>
                <Button onClick={onBackClick} label='Back' />
            </div>
            <div className={styles.rightButtonsWrapper}>
                <Button onClick={collapseAllFieldsAndSections} label='Collapse All' />
                {buttons}
                <Button onClick={saveDatasetConfiguration} label='Save' disabled={!configUpdated} disabledTooltip={disabledTooltip} />
            </div>
            <UnsavedChangesModal isOpen={unsavedChangesModalOpen} closeModal={() => toggleUnsavedChangesModalOpen(false)} confirm={back} />
        </div>
    );
};
