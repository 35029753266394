import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { CountButton } from '../../shared/button/CountButton';
import { Notification } from '../../shared/icons';
import { Position } from '../../shared/modal/PositionModal';
import { NotificationsModal } from './NotificationsModal';
import { getNotifications, getNotificationsModalOpen, toggleNotificationModal } from './store';
import styles from './Notifications.module.scss';

const { white } = styles;

export const NotificationButton: React.FC = () => {
    const [position, setPosition] = useState<Position | null>(null);
    const dispatch = useAppDispatch();
    const toggleNotificationsOpen = (isOpen: boolean) => dispatch(toggleNotificationModal(isOpen));

    const openNotifications = (x: number, y: number) => {
        setPosition({ x, y });
        toggleNotificationsOpen(true);
    };

    const closeNotifications = () => {
        setPosition(null);
        toggleNotificationsOpen(false);
    };

    const notifications = useAppSelector(getNotifications);
    const notificationsOpen = useAppSelector(getNotificationsModalOpen);

    return (
        <>
            <CountButton
                icon={Notification}
                onClick={e => openNotifications(e.clientX, e.clientY)}
                color={white}
                count={notifications.length}
                marginRight='10px'
                marginLeft='10px'
                fontSize={26}
            />
            <NotificationsModal
                isOpen={notificationsOpen}
                closeModal={closeNotifications}
                testId='notifications'
                position={position}
                notifications={notifications}
            />
        </>
    );
};
