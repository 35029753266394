import React, { useCallback, useMemo, useState } from 'react';
import { isNull, noop } from 'lodash/fp';
import classnames from 'classnames';

import { Playbook as PlaybookType, PlaybookHistory, PlaybookPageView, SuggestedChangesDB, isSubheaderSection } from '../../../admin/playbook/store';
import styles from './PlaybookSideMenu.module.scss';
import { Scrollable } from '../../scrollable/Scrollable';
import { CaretSide, HamburgerMenu, PullRequest, Sort, SortAscending, SortDescending, Tag, Tick } from '../../icons';
import { IconButton } from '../../button/IconButton';
import { CustomTooltip, OverflowTooltip } from '../../tooltip';
import { TagList } from '../tags/TagList';
import { ABSTRACT_ID, getProvisionTitle } from '../../../constants/playbooks';
import { PlaybookContentType, SuggestedChanges } from '../../../playbook/store';
import { formatDate, SHORT_MONTH_FORMAT } from '../../../../utils/luxon';
import { SuggestedChangesViewButtons } from './SuggestedChangesViewButtons';
import { SortOrder } from '../../table/ArkTable';
import { Icon as ArkIcon } from '../../icon/Icon';

const { french, primary, white } = styles;

enum SideMenuView {
    CONTENTS = 'Contents',
    TAGS = 'Tags',
    SUGGESTED_CHANGES = 'Suggested Changes',
    RESOLVED_SUGGESTED_CHANGES = 'Resolved Suggested Changes'
}

export const scrollToProvisionLink = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
        return true;
    }
    return false;
};

interface PlaybookSideMenuProps {
    playbook: PlaybookType;
    suggestedChanges?: SuggestedChangesDB[];
    openSuggestedChange?: (playbookSuggestionId: number | null) => void;
    isAdmin?: boolean;
    openAdminSuggestedChanges?: (playbookId: number) => void;
    sideMenuOpen: boolean;
    setSideMenuOpen?: (value: boolean) => void;
    showViewButtons?: boolean;
    changePlaybookPageView?: (view: PlaybookPageView) => void;
    currentView?: PlaybookPageView;
    tagsSectionFilter?: string;
    contentDisabled?: boolean;
    playbookHistory?: PlaybookHistory[];
    resolvedSuggestedChanges?: SuggestedChangesDB[];
    openResolvedSuggestedChange?: (playbookSuggestionId: number | null) => void;
    testId?: string;
}

export const PlaybookSideMenu: React.FC<PlaybookSideMenuProps> = ({
    playbook,
    suggestedChanges,
    openSuggestedChange = noop,
    isAdmin = false,
    openAdminSuggestedChanges = noop,
    sideMenuOpen,
    setSideMenuOpen = noop,
    showViewButtons = false,
    changePlaybookPageView = noop,
    currentView,
    tagsSectionFilter,
    contentDisabled = false,
    playbookHistory = [],
    resolvedSuggestedChanges,
    openResolvedSuggestedChange = noop,
    testId = 'playbook-side-menu'
}) => {
    const [sideMenuView, setSideMenuView] = useState<SideMenuView>(SideMenuView.CONTENTS);
    const [tagsSortOrder, setTagsSortOrder] = useState<SortOrder | null>(null);

    const { content: { sections }, tags, playbookId, playbookDefinitionId } = playbook;
    const playbookIsMostRecentVersion = useMemo(() => !playbookHistory.length || playbookHistory[playbookHistory.length - 1].playbookDefinitionId === playbookDefinitionId, [playbookHistory, playbookDefinitionId]);

    const scrollToSection = useCallback((sectionId: string) => {
        const section = document.getElementById(sectionId);
        section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, []);

    const selectSideMenuView = useCallback((view: SideMenuView) => {
        setSideMenuOpen(true);
        setSideMenuView(view);
    }, [setSideMenuOpen]);

    const contentsPageWidth = useMemo(() => sideMenuOpen ? 'calc(20% - 11px)' : 'fit-content', [sideMenuOpen]);

    const sectionIds = useMemo(() => [ABSTRACT_ID, ...sections.map(({ sectionId }) => sectionId)], [sections]);
    const provisionTitle = useCallback((id: string) => getProvisionTitle(id, sections), [sections]);

    const contentsPage = useMemo(() => sections.reduce((acc, section) => {
        const isSubheader = isSubheaderSection(section);
        const instepTitle = !!acc.find(({ isSubheader }) => isSubheader) && !isSubheader;
        let number = acc[acc.length - 1].number;
        number++;
        if (isSubheader) {
            number = 0;
        }
        return [...acc, { title: provisionTitle(section.sectionId), sectionId: section.sectionId, isSubheader: isSubheaderSection(section), number, instepTitle }];
    }, [{ title: 'Playbook Abstract', sectionId: ABSTRACT_ID, isSubheader: false, number: 0, instepTitle: false }]), [sections, provisionTitle]);

    const getSuggestedChangesSectionLabel = useCallback((section: SuggestedChanges) => section.type === PlaybookContentType.ABSTRACT ? 'Playbook Abstract' : section.content.title.filter(item => !!item).join(': '), []);
    const getSuggestedChangesCreatedByLabel = useCallback((section: SuggestedChanges, createdDate: string) => `${section.name} - ${formatDate(createdDate, SHORT_MONTH_FORMAT)}`, []);
    const showSuggestedChanges = useMemo(() => !!(suggestedChanges && suggestedChanges.length > 0) && playbookIsMostRecentVersion, [suggestedChanges, playbookIsMostRecentVersion]);
    const showResolvedSuggestedChanges = useMemo(() => !!(resolvedSuggestedChanges && resolvedSuggestedChanges.length > 0) && isAdmin, [resolvedSuggestedChanges, isAdmin]);

    const openSuggestedChangeModal = useCallback((sectionId: string, playbookSuggestionId: number) => {
        scrollToSection(sectionId);
        openSuggestedChange(playbookSuggestionId);
    }, [scrollToSection, openSuggestedChange]);

    const openResolvedSuggestedChangeModal = useCallback((sectionId: string, playbookSuggestionId: number) => {
        scrollToSection(sectionId);
        openResolvedSuggestedChange(playbookSuggestionId);
    }, [scrollToSection, openResolvedSuggestedChange]);

    const redirectToSuggestedChanges = useCallback(() => {
        selectSideMenuView(SideMenuView.SUGGESTED_CHANGES);
        openAdminSuggestedChanges(playbookId!);
    }, [selectSideMenuView, openAdminSuggestedChanges, playbookId]);

    const tagsSortIcon = useMemo(() => {
        if (isNull(tagsSortOrder)) {
            return Sort;
        }
        return tagsSortOrder === SortOrder.ASCENDING ? SortDescending : SortAscending;
    }, [tagsSortOrder]);

    const toggleTagsSortOrder = useCallback(() => setTagsSortOrder(tagsSortOrder === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING), [tagsSortOrder]);

    const sideMenuContent = useMemo(() => {
        if (!sideMenuOpen) {
            return null;
        }

        if (sideMenuView === SideMenuView.CONTENTS) {
            return (
                <div className={styles.sideMenuContentWrapper} data-testid={`${testId}-contents-wrapper`}>
                    <div className={styles.sideMenuTitleWrapper}>
                        <div className={styles.sideMenuTitle} data-testid={`${testId}-contents-title`}>Contents</div>
                        <div className={styles.sideMenuCollapse}>
                            <IconButton icon={CaretSide} onClick={() => setSideMenuOpen(false)} fontSize={15} testId={`${testId}-contents-collapse`} />
                        </div>
                    </div>
                    <div className={styles.sideMenuScrollableWrapper}>
                        <Scrollable>
                            {contentsPage.map(({ title, sectionId, number, instepTitle }, index) => (
                                <div key={sectionId} className={classnames(styles.contentsPageSection, { [styles.instepContentsTitle]: instepTitle })}>
                                    {number > 0 && <div className={styles.contentsPageSectionIcon}>{`${number}.`}</div>}
                                    <OverflowTooltip overlayText={title} testId={`${testId}-contents-${index}`} className={styles.contentsPageSectionLabel} onClick={() => scrollToSection(sectionId)} />
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </div>
            );
        }

        if (showSuggestedChanges && sideMenuView === SideMenuView.SUGGESTED_CHANGES) {
            return (
                <div className={styles.sideMenuContentWrapper} data-testid={`${testId}-suggested-changes-wrapper`}>
                    <div className={styles.sideMenuTitleWrapper}>
                        <div className={styles.sideMenuTitle} data-testid={`${testId}-suggested-changes-title`}>Suggested Changes</div>
                        <div className={styles.sideMenuCollapse}>
                            <IconButton icon={CaretSide} onClick={() => setSideMenuOpen(false)} fontSize={15} testId={`${testId}-suggested-changes-collapse`} />
                        </div>
                    </div>
                    {!isAdmin &&
                        <div className={styles.sideMenuScrollableWrapper}>
                            <Scrollable>
                                {suggestedChanges!.map(({ playbookSuggestionId, createdDate, suggestedSection, sectionId }, index) => (
                                    <div key={index} className={styles.suggestedChangesSection}>
                                        <div className={styles.suggestedChangesSectionIcon}>{'>'}</div>
                                        <div className={styles.suggestedChangesSectionLabelWrapper} onClick={() => openSuggestedChangeModal(sectionId, playbookSuggestionId)}>
                                            <OverflowTooltip overlayText={getSuggestedChangesSectionLabel(suggestedSection)} testId={`${testId}-suggested-changes-${playbookSuggestionId}-label`} />
                                            <OverflowTooltip overlayText={getSuggestedChangesCreatedByLabel(suggestedSection, createdDate)} testId={`${testId}-suggested-changes-${playbookSuggestionId}-created-date`} />
                                        </div>
                                    </div>
                                ))}
                            </Scrollable>
                        </div>
                    }
                </div>
            );
        }

        if (showResolvedSuggestedChanges && sideMenuView === SideMenuView.RESOLVED_SUGGESTED_CHANGES && isAdmin) {
            return (
                <div className={styles.sideMenuContentWrapper} data-testid={`${testId}-resolved-suggested-changes-wrapper`}>
                    <div className={styles.sideMenuTitleWrapper}>
                        <div className={styles.sideMenuTitle} data-testid={`${testId}-resolved-suggested-changes-title`}>Resolved Suggested Changes</div>
                        <div className={styles.sideMenuCollapse}>
                            <IconButton icon={CaretSide} onClick={() => setSideMenuOpen(false)} fontSize={15} testId={`${testId}-resolved-suggested-changes-collapse`} />
                        </div>
                    </div>
                    <div className={styles.sideMenuScrollableWrapper}>
                        <Scrollable>
                            {resolvedSuggestedChanges!.map(({ playbookSuggestionId, createdDate, suggestedSection, sectionId }) => (
                                <div key={playbookSuggestionId} className={styles.suggestedChangesSection}>
                                    <div className={styles.suggestedChangesSectionIcon}>{'>'}</div>
                                    <div className={styles.suggestedChangesSectionLabelWrapper} onClick={() => openResolvedSuggestedChangeModal(sectionId, playbookSuggestionId)}>
                                        <OverflowTooltip overlayText={getSuggestedChangesSectionLabel(suggestedSection)} testId={`${testId}-resolved-suggested-changes-${playbookSuggestionId}-label`} />
                                        <OverflowTooltip overlayText={getSuggestedChangesCreatedByLabel(suggestedSection, createdDate)} testId={`${testId}-resolved-suggested-changes-${playbookSuggestionId}-created-date`} />
                                    </div>
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.sideMenuContentWrapper} data-testid={`${testId}-tags-wrapper`}>
                <div className={styles.sideMenuTitleWrapper}>
                    <div className={styles.sideMenuLeftTitle}>
                        <div className={styles.sideMenuTitle} data-testid={`${testId}-tags-title`}>Tags</div>
                        <div className={styles.sortTagsWrapper}>
                            <IconButton icon={tagsSortIcon} onClick={toggleTagsSortOrder} fontSize={20} testId={`${testId}-tags-sort`} />
                        </div>
                    </div>
                    <div className={styles.sideMenuCollapse}>
                        <IconButton icon={CaretSide} onClick={() => setSideMenuOpen(false)} fontSize={15} testId={`${testId}-tags-collapse`} />
                    </div>
                </div>
                <div className={styles.sideMenuScrollableWrapper}>
                    <TagList
                        tags={tags}
                        sectionIds={sectionIds}
                        getProvisionTitle={provisionTitle}
                        tagsSectionFilter={tagsSectionFilter}
                        sortOrder={tagsSortOrder}
                        testId={`${testId}-tags-list`}
                    />
                </div>
            </div>
        );
    }, [tags, sectionIds, provisionTitle, contentsPage, sideMenuOpen, sideMenuView, setSideMenuOpen, showSuggestedChanges, suggestedChanges, getSuggestedChangesSectionLabel, getSuggestedChangesCreatedByLabel, openSuggestedChangeModal, isAdmin, tagsSectionFilter, tagsSortIcon, tagsSortOrder, toggleTagsSortOrder, showResolvedSuggestedChanges, resolvedSuggestedChanges, openResolvedSuggestedChangeModal, scrollToSection, testId]);

    const getIconColor = useCallback((view: SideMenuView) => view === sideMenuView ? french : primary, [sideMenuView]);
    const selectSuggestedChanges = useCallback(() => !isAdmin ? selectSideMenuView(SideMenuView.SUGGESTED_CHANGES) : redirectToSuggestedChanges(), [isAdmin, selectSideMenuView, redirectToSuggestedChanges]);

    return (
        <div className={styles.contentsPageWrapper} style={{ width: contentsPageWidth, minWidth: contentsPageWidth }} data-testid={`${testId}-wrapper`}>
            <div className={classnames(styles.iconButtonsWrapper, { [styles.sideMenuOpenIconsWrapper]: sideMenuOpen })}>
                <CustomTooltip overlayText='Contents'>
                    <div className={styles.iconWrapper}>
                        <IconButton onClick={() => selectSideMenuView(SideMenuView.CONTENTS)} testId={`${testId}-contents`} icon={HamburgerMenu} fontSize={25} color={getIconColor(SideMenuView.CONTENTS)} disabled={contentDisabled} />
                    </div>
                </CustomTooltip>
                <CustomTooltip overlayText='Tags'>
                    <div className={styles.iconWrapper}>
                        <IconButton onClick={() => selectSideMenuView(SideMenuView.TAGS)} testId={`${testId}-tags`} icon={Tag} fontSize={25} color={getIconColor(SideMenuView.TAGS)} />
                    </div>
                </CustomTooltip>
                {showSuggestedChanges &&
                    <CustomTooltip overlayText='Suggested Changes'>
                        <div className={styles.iconWrapper}>
                            <IconButton onClick={selectSuggestedChanges} testId={`${testId}-suggested-changes`} icon={PullRequest} fontSize={25} color={getIconColor(SideMenuView.SUGGESTED_CHANGES)} />
                        </div>
                    </CustomTooltip>
                }
                {showResolvedSuggestedChanges &&
                    <CustomTooltip overlayText='Resolved Suggested Changes'>
                        <div className={styles.iconWrapper}>
                            <IconButton onClick={() => selectSideMenuView(SideMenuView.RESOLVED_SUGGESTED_CHANGES)} testId={`${testId}-resolved-suggested-changes`} icon={PullRequest} fontSize={25} color={getIconColor(SideMenuView.SUGGESTED_CHANGES)} />
                            <div className={styles.resolvedChangesIcon}><ArkIcon icon={Tick} fontSize={15} color={white} /></div>
                        </div>
                    </CustomTooltip>
                }
                {showViewButtons && <SuggestedChangesViewButtons toggleView={changePlaybookPageView} currentView={currentView!} testId={`${testId}-view`} />}
            </div>
            {sideMenuContent}
        </div>
    );
};
