import { isNull, uniq } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { Delete, Mail, User } from '../../../shared/icons';
import { ActionModal } from '../../../shared/modal/ActionModal';
import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Position } from '../../../shared/modal/PositionModal';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Text } from '../../../shared/text/Text';
import { Toggle } from '../../../shared/toggle';
import { fetchAllDocumentNamesStarted, getAllDocumentNames } from '../../documents/store';
import { fetchAllDropdownListsStarted } from '../../dropdown-lists/store';
import { DeleteConfirmationModal } from '../../users/DeleteConfirmationModal';
import { UpsertUserModal } from '../../users/UpsertUserModal';
import { ClientUser, fetchAvailableFeaturePermissionsStarted, getConfirmDeleteUser, getModalOpen as getUsersModalOpen, toggleDeleteUserConfirmationModal, toggleUsersModal } from '../../users/store';
import styles from '../Clients.module.scss';
import { JurisdictionFilterModal } from '../JurisdictionFilterModal';
import { Client, ClientOpinion, ClientSection, fetchClientFeaturePermissionsStarted, getAvailableClientFeaturePermissions, getOpenClientSections, resetClientUserPassword, setOpenClientSections, updateClientDetails, updateClientStarted } from '../store';
import { ClientDocuments } from './ClientDocuments';
import { ClientFeaturePermissions } from './ClientFeaturePermissions';
import { ClientUsers } from './ClientUsers';
import { IndividualClientOpinions } from './IndividualClientOpinions';
import { DocumentPageLimits } from './DocumentPageLimits';

interface ClientModalProps {
    isOpen: boolean;
    closeModal: () => void;
    client: Client;
}

export const ClientModal: React.FC<ClientModalProps> = ({
    isOpen,
    closeModal,
    client: {
        clientName,
        description,
        users,
        clientDatasetIds,
        clientFeaturePermissionIds,
        clientId,
        clientOpinions,
        demoClient,
        freePages,
        maxPages,
        monthlyTotalPages
    }
}) => {
    const [userActionModalPosition, setUserActionModalPosition] = useState<Position | null>(null);
    const [jurisdictionFilterModalPosition, setJurisdictionFilterModalPosition] = useState<Position | null>(null);
    const [userActionModalOpen, setUserActionModalOpen] = useState<ClientUser | null>(null);
    const [jurisdictionFilter, setJurisdictionFilter] = useState<string>('');

    const dispatch = useAppDispatch();
    const allDocumentNames = useAppSelector(getAllDocumentNames);
    const allClientFeaturePermissions = useAppSelector(getAvailableClientFeaturePermissions);
    const openSections = useAppSelector(getOpenClientSections);
    useFetchStarted([fetchAllDocumentNamesStarted(), fetchAvailableFeaturePermissionsStarted(), fetchClientFeaturePermissionsStarted(), fetchAllDropdownListsStarted()]);

    const openUserActionModal = (x: number, y: number, user: ClientUser) => {
        setUserActionModalPosition({ x, y });
        setUserActionModalOpen(user);
    };

    const closeUserActionModal = () => {
        setUserActionModalPosition(null);
        setUserActionModalOpen(null);
    };

    const openJurisdictionFilter = (x: number, y: number) => setJurisdictionFilterModalPosition({ x, y });
    const closeJurisdictionFilter = () => setJurisdictionFilterModalPosition(null);

    const editUser = useCallback((user: ClientUser) => { dispatch(toggleUsersModal(true, user)); }, [dispatch]);

    const closeUserModal = () => dispatch(toggleUsersModal(false));
    const userModalOpen = useAppSelector(getUsersModalOpen);

    const confirmDeleteUser = useAppSelector(getConfirmDeleteUser);
    const openDeleteUserConfirmationModal = useCallback((user: ClientUser) => { dispatch(toggleDeleteUserConfirmationModal(user)); }, [dispatch]);
    const closeDeleteUserConfirmationModal = () => dispatch(toggleDeleteUserConfirmationModal(null));
    const resetPassword = useCallback((username: string) => dispatch(resetClientUserPassword(username)), [dispatch]);

    const userModalActions = useCallback((user: ClientUser) => [
        { label: 'Open', icon: User, onClick: () => editUser(user) },
        { label: 'Reset Password', icon: Mail, onClick: () => resetPassword(user.username), withSeparator: true, minWidth: '100px' },
        { label: 'Delete', icon: Delete, onClick: () => openDeleteUserConfirmationModal(user), isDelete: true }
    ], [editUser, openDeleteUserConfirmationModal, resetPassword]);

    const datasetOptions = useMemo(() => allDocumentNames.filter(({ datasetId }) => datasetId), [allDocumentNames]);
    const permissionOptions = useMemo(() => allClientFeaturePermissions.filter(({ label }) => label).sort((a, b) => a.label.localeCompare(b.label)), [allClientFeaturePermissions]);

    const toggleSection = useCallback((section: ClientSection) => {
        const shouldRemove = openSections.includes(section);
        let openClientSections: ClientSection[] = [];
        if (shouldRemove) {
            openClientSections = openSections.filter(clientSection => clientSection !== section);
        } else {
            openClientSections = [...openSections, section];
        }
        dispatch(setOpenClientSections(openClientSections));
    }, [dispatch, openSections]);

    const updateClientProperties = useCallback((key: keyof Client, value: string | number[] | ClientOpinion[] | number | null) => dispatch(updateClientDetails(key, value)), [dispatch]);
    const toggleDemoClient = useCallback((checked: boolean) => updateClientProperties('demoClient', checked ? 1 : 0), [updateClientProperties]);

    useEffect(() => {
        if (!clientId) {
            const newClientDatasetIds: number[] = uniq(datasetOptions.map(({ datasetId }) => datasetId!));
            updateClientProperties('clientOpinions', []);
            updateClientProperties('clientDatasetIds', newClientDatasetIds);
        }
        if (clientId && !clientFeaturePermissionIds) {
            updateClientProperties('clientFeaturePermissionIds', []);
        }
    }, [clientId, datasetOptions, updateClientProperties, permissionOptions, clientFeaturePermissionIds]);

    useEffect(() => () => {
        dispatch(setOpenClientSections([ClientSection.USERS]));
    }, [dispatch]);

    const confirm = () => dispatch(updateClientStarted());

    const confirmLabel = clientId ? 'Update' : 'Create';
    const confirmDisabled = !clientName || !description;

    const getSectionOpen = useCallback((section: ClientSection) => openSections.includes(section), [openSections]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel={confirmLabel}
            confirmDisabled={confirmDisabled}
            confirm={confirm}
            testId='upsert-client'
        >
            <ModalHeader label='Client' icon={User} testId='clients-modal' />
            <div className={styles.clientWrapper} data-testid='clients-modal-wrapper'>
                <Scrollable maxHeight='70vh'>
                    <div className={styles.clientNameWrapper}>
                        <div className={styles.clientNameTitle}>Client Name</div>
                        <div className={styles.clientName}>
                            <Text
                                testId='client-name'
                                placeholder='Name...'
                                maxLength={256}
                                value={clientName}
                                onChange={e => updateClientProperties('clientName', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.clientDescriptionWrapper}>
                        <div className={styles.clientDescriptionTitle}>Description</div>
                        <div className={styles.clientDescription}>
                            <Text
                                testId='client-description'
                                placeholder='Description...'
                                maxLength={256}
                                value={description}
                                onChange={e => updateClientProperties('description', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.demoClientWrapper}>
                        <div className={styles.demoClientTitle}>Demo Client with limited document upload privileges?</div>
                        <div className={styles.demoClient}>
                            <Toggle
                                checked={demoClient === 1}
                                onChange={toggleDemoClient}
                            />
                        </div>
                    </div>
                    <DocumentPageLimits freePages={freePages} maxPages={maxPages} updateClientProperties={updateClientProperties} monthlyTotalPages={monthlyTotalPages} />
                    <ClientUsers
                        users={users}
                        clientId={clientId!}
                        openUserActionModal={openUserActionModal}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                    />
                    <ClientFeaturePermissions
                        clientFeaturePermissionIds={clientFeaturePermissionIds}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        updateClientProperties={updateClientProperties}
                    />
                    <ClientDocuments
                        clientDatasetIds={clientDatasetIds}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        updateClientProperties={updateClientProperties}
                    />
                    <IndividualClientOpinions
                        clientOpinions={clientOpinions}
                        jurisdictionFilter={jurisdictionFilter}
                        openJurisdictionFilter={openJurisdictionFilter}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        updateClientProperties={updateClientProperties}
                    />
                </Scrollable>
            </div >
            <UpsertUserModal
                isOpen={userModalOpen}
                closeModal={closeUserModal}
                clientName={clientName}
                clientView
            />
            {userActionModalOpen &&
                <ActionModal
                    actions={userModalActions(userActionModalOpen)}
                    closeModal={closeUserActionModal}
                    isOpen={!isNull(userActionModalPosition)}
                    position={userActionModalPosition}
                    testId='client-users'
                />
            }
            {!isNull(jurisdictionFilterModalPosition) &&
                <JurisdictionFilterModal
                    closeModal={closeJurisdictionFilter}
                    value={jurisdictionFilter}
                    setFilterValue={setJurisdictionFilter}
                    position={jurisdictionFilterModalPosition}
                />
            }
            {!isNull(confirmDeleteUser) &&
                <DeleteConfirmationModal
                    isOpen
                    closeModal={closeDeleteUserConfirmationModal}
                    user={confirmDeleteUser}
                    clientId={clientId}
                    clientName={clientName}
                />
            }
        </ConfirmationModal >
    );
};
