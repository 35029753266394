import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import styles from './NettingEngine.module.scss';
import { getNettingPage , NettingPage, resetNettingEnginePage, setNettingEnginePage } from './store';
import { OpinionNettingEngineSelect } from './NettingEngineSelect';
import { NettingEngineDocuments } from './documents/NettingEngineDocuments';
import { NettingEngineTheoretical } from './theoretical/Theoretical';

export interface NettingEngineParams {
    documentId: string | undefined;
}

export const NettingEngine: React.FC<RouteComponentProps<NettingEngineParams>> = ({ match: { url } }) => {
    const dispatch = useAppDispatch();
    const nettingPage = useAppSelector(getNettingPage);

    const pageContent = useMemo(() => {
        switch (nettingPage) {
            case NettingPage.SELECT:
            default:
                return <OpinionNettingEngineSelect />;
            case NettingPage.DOCUMENTS:
                return <NettingEngineDocuments />;
            case NettingPage.THEORETICAL:
                return <NettingEngineTheoretical />;
        }
    }, [nettingPage]);

    useEffect(() => () => {
        dispatch(resetNettingEnginePage());
    }, [dispatch]);

    useEffect(() => {
        if (url.includes('theory') && nettingPage !== NettingPage.THEORETICAL) {
            dispatch(setNettingEnginePage(NettingPage.THEORETICAL));
        }
        if (url.includes('documents') && nettingPage !== NettingPage.DOCUMENTS) {
            dispatch(setNettingEnginePage(NettingPage.DOCUMENTS));
        }
        if (!url.includes('theory') && !url.includes('documents')) {
            dispatch(setNettingEnginePage(NettingPage.SELECT));
        }
    }, [dispatch, nettingPage, url]);

    return (
        <div data-testid='opinion-netting-engine-wrapper' className={styles.nettingEngineWrapper}>
            {pageContent}
        </div>
    );
};
