import React, { useMemo } from 'react';

import { useAppSelector } from '../../hooks/react-redux';
import { OfficeBuilding } from '../shared/icons';
import { Spinner } from '../shared/spinner/Spinner';
import { HomePageTile, getEntitySpreadAnalytics, getFetchingAnalytics } from './store';
import { PieChartWrapper } from './PieChartWrapper';

interface EntitySpreadChartProps {
    dimensions?: {
        height: number;
        width: number;
    }
    analyticsSpinner: boolean;
}

export const EntitySpreadChart: React.FC<EntitySpreadChartProps> = ({ dimensions, analyticsSpinner }) => {
    const entitySpreadAnalytics = useAppSelector(getEntitySpreadAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(HomePageTile.ENTITY_SPREAD) || analyticsSpinner, [fetchingAnalytics, analyticsSpinner]);

    return showSpinner ?
        <Spinner /> : (
            <PieChartWrapper
                data={entitySpreadAnalytics}
                dimensions={dimensions}
                icon={OfficeBuilding}
                testId='homepage-entity-spread'
                colors='oceanFrench'
            />
        );
};
