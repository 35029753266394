import classnames from 'classnames';
import { RawDraftContentState } from 'draft-js';
import { getOr, isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import Select, { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { dateNow } from '../../../../utils/luxon';
import { getAllDropdownLists } from '../../../admin/dropdown-lists/store';
import { User } from '../../../auth/login/store';
import { SmileyIndicator } from '../../../shared/analytics/SingleIndicator';
import { customStyles, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { Icon } from '../../../shared/icon/Icon';
import { SmileyNegative, SmileyNeutral, SmileyPositive } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { WYSIWYG } from '../../../shared/wysiwyg/WYSIWYG';
import { getSignOffNotesContent, updateDropdownDetailsContent, updateNotesSignOffContent, updateSmileyContent } from '../store';
import styles from './SignOff.module.scss';

const { green, amber, red, primary, white } = styles;

interface SmileySelectionIconsProps {
    user: User;
    showEditNoteSpinner: boolean;
    isDropdownDetailsField: boolean;
}

export const SmileySelectionIcons: React.FC<SmileySelectionIconsProps> = ({ user, showEditNoteSpinner, isDropdownDetailsField }) => {
    const dispatch = useAppDispatch();

    const displayName: string = user!.forenames.concat(' ', user!.surname);
    const name: string = displayName === ' ' ? user!.username : displayName;
    const dropdownLists = useAppSelector(getAllDropdownLists);
    const signOffNotes = useAppSelector(getSignOffNotesContent);
    const negativeSmileySelected: boolean = signOffNotes.smileyValue === SmileyIndicator.NEGATIVE;
    const neutralSmileySelected: boolean = signOffNotes.smileyValue === SmileyIndicator.NEUTRAL;
    const positiveSmileySelected: boolean = signOffNotes.smileyValue === SmileyIndicator.POSITIVE;
    const smileySelected: boolean = negativeSmileySelected || neutralSmileySelected || positiveSmileySelected;

    const mapDropdownOptions = useCallback((options: string[]) => options.map(value => ({ label: value, value })), []);
    const options = useMemo(() => mapDropdownOptions(dropdownLists.find(({ name }) => name === 'YesNoUnclear')?.options || []), [dropdownLists, mapDropdownOptions]);

    const updateDropdownValue = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = [(option as DropdownOption).value];
        }
        dispatch(updateDropdownDetailsContent(value));
    }, [dispatch]);

    const dropdownValue: string[] | null = useMemo(() => getOr(null, 'dropdownValue', signOffNotes), [signOffNotes]);

    const mappedDropdownValue = useMemo(() => options.filter(option => dropdownValue?.includes(option.value)) || null, [dropdownValue, options]);

    const selectSmileyValue = useCallback((value: SmileyIndicator) => dispatch(updateSmileyContent(value)), [dispatch]);
    const updateNotesContent = useCallback((notes: RawDraftContentState | null, createdBy: number, name: string, createdDate: string) => dispatch(updateNotesSignOffContent(notes, createdBy, name, createdDate)), [dispatch]);

    const negativeColor = { fill: negativeSmileySelected ? red : white, color: primary, fontSize: 36 };
    const neutralColor = { fill: neutralSmileySelected ? amber : white, color: primary, fontSize: 36 };
    const positiveColor = { fill: positiveSmileySelected ? green : white, color: primary, fontSize: 36 };
    const fontSize: number = smileySelected ? 36 : 100;

    const notesInput = useMemo(() => {
        if (showEditNoteSpinner) {
            return <Spinner />;
        }
        if (smileySelected) {
            return <WYSIWYG
                content={signOffNotes.notes}
                updateContent={value => updateNotesContent(value, user!.userId, name, dateNow())}
                showWrapperBorder={false}
                padding='0'
            />;
        }
        return null;
    }, [smileySelected, signOffNotes, updateNotesContent, user, name, showEditNoteSpinner]);

    const dropdownSelect = useMemo(() => {
        if (!isDropdownDetailsField || showEditNoteSpinner) {
            return null;
        }
        return (
            <div
                data-testid='opinion-sign-off-dropdown'
                className={styles.dropdownFieldWrapper}
            >
                <Select
                    className={styles.dropdownField}
                    classNamePrefix='ark-dropdown'
                    value={mappedDropdownValue}
                    onChange={updateDropdownValue}
                    options={options}
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    minControlHeight='38px'
                    fontWeight={500}
                />
            </div>
        );
    }, [isDropdownDetailsField, mappedDropdownValue, updateDropdownValue, options, showEditNoteSpinner]);

    const smileyWrapperStyle = useMemo(() => smileySelected ? { width: 'calc(20% - 10px)', marginRight: '10px' } : { width: '100%' }, [smileySelected]);

    return (
        <div className={styles.editableNoteWrapper} style={{ display: 'flex', flexDirection: smileySelected ? 'row' : 'column' }}>
            <div className={styles.smileyWrapper} style={smileyWrapperStyle}>
                {!smileySelected && !isDropdownDetailsField && !showEditNoteSpinner ? <div className={styles.signOffNotesAddNotesHeader}>Select a smiley value below to add notes</div> : null}
                {dropdownSelect}
                <div className={classnames(styles.signOffAnswerIndicator, { [styles.addedHeight]: !smileySelected })}>
                    <div className={classnames(styles.indicatorWrapper, { [styles.onClickOpacity]: negativeSmileySelected })} onClick={() => selectSmileyValue(SmileyIndicator.NEGATIVE)}><Icon icon={SmileyNegative} {...negativeColor} fontSize={fontSize} /></div>
                    <div className={classnames(styles.indicatorWrapper, { [styles.onClickOpacity]: neutralSmileySelected })} onClick={() => selectSmileyValue(SmileyIndicator.NEUTRAL)}><Icon icon={SmileyNeutral} {...neutralColor} fontSize={fontSize} /></div>
                    <div className={classnames(styles.indicatorWrapper, { [styles.onClickOpacity]: positiveSmileySelected })} onClick={() => selectSmileyValue(SmileyIndicator.POSITIVE)}><Icon icon={SmileyPositive} {...positiveColor} fontSize={fontSize} /></div>
                </div>
            </div>
            {notesInput}
        </div>
    );
};
