import React from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { incompleteDocumentUpdateValue } from '../documents/my-documents/store';
import { DocumentNameDropdownOption } from '../documents/my-documents/upload/DocumentNameDropdown';
import { Document } from '../shared/icons';
import { ModalHeader } from '../shared/modal/ModalHeader';
import { AnnexPreview } from './annex-preview/AnnexPreview';
import { AnnexBuilderModal } from './AnnexBuilderModal';
import { DocumentAnnexModal } from './DocumentAnnexModal';
import { AnnexDefinitionDB, createNewAnnexDefinition, duplicateAnnexDefinition, fetchAllAnnexDefinitionsStarted, getAllAnnexDefinitions, getAnnexBuilderModalOpen, publishAnnexDefinitionStarted, toggleAnnexBuilderModal, toggleAnnexDefinitionModal } from './store';

interface AnnexDefinitionModalProps {
    isOpen: boolean;
    close: () => void;
    documentName: DocumentNameDropdownOption;
}

export const AnnexDefinitionModal: React.FC<AnnexDefinitionModalProps> = ({ isOpen, close, documentName }) => {
    const dispatch = useAppDispatch();

    const { documentNameId } = documentName;
    const closeBuilder = () => dispatch(toggleAnnexBuilderModal(false));
    const openBuilder = () => dispatch(createNewAnnexDefinition(parseInt(documentNameId)));
    const annexBuilderOpen = useAppSelector(getAnnexBuilderModalOpen);
    const annexDefinitions = useAppSelector(getAllAnnexDefinitions);

    const publishAnnexDefinition = () => {
        dispatch(publishAnnexDefinitionStarted());
        dispatch(fetchAllAnnexDefinitionsStarted(documentNameId));
    };

    const duplicate = (annexDefinition: AnnexDefinitionDB) => dispatch(duplicateAnnexDefinition(annexDefinition));
    const select = (annexDefinitionId: number) => {
        dispatch(toggleAnnexDefinitionModal(false));
        dispatch(incompleteDocumentUpdateValue('annexDefinitionId', annexDefinitionId));
    };

    return (
        <>
            <DocumentAnnexModal
                isOpen={isOpen}
                closeModal={close}
                confirmLabel='Create New'
                confirm={openBuilder}
            >
                <ModalHeader
                    label='Agency Annex'
                    icon={Document}
                    testId='agency-annex-modal'
                />
                <AnnexPreview annexDefinitions={annexDefinitions} documentName={documentName.label} duplicate={duplicate} select={select} />
            </DocumentAnnexModal>
            <AnnexBuilderModal
                isOpen={annexBuilderOpen}
                close={closeBuilder}
                create={publishAnnexDefinition}
            />
        </>
    );
};
