import React from 'react';

import styles from './CreateAttestationInstancesTable.module.scss';
import { DatePicker } from '../datepicker/DatePicker';

interface AttestationManagerDeadlineCellProps {
    testId?: string;
    updateDeadline: (value: Date | null, index: number) => void;
    isEditing: boolean;
    deadline: string | null;
    index: number;
}

export const AttestationManagerDeadlineCell: React.FC<AttestationManagerDeadlineCellProps> = ({ testId, updateDeadline, isEditing, deadline, index }) => (
    <div className={styles.rowDeadlineWrapper} style={{ width: '200px' }} data-testid={`${testId}-attestation-instances-table-row-${index}-deadline-wrapper`}>
        <div className={styles.datePickerDropdown} data-testid={`${testId}-attestation-instances-table-row-${index}-deadline-date-picker-dropdown`}>
            <DatePicker
                value={deadline ? new Date(deadline) : null}
                onChange={val => updateDeadline(val, index)}
                testId={`${testId}-deadline`}
                minDate={new Date()}
                disabled={!isEditing}
            />
        </div>
    </ div>
);
