import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import classnames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { getItemData, useListContext } from '../../../shared/drag-n-drop/shared';
import styles from '../SupplyChain.module.scss';
import { TileState } from './RankContent';

const Placeholder: React.FC = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { registerItem, instanceId } = useListContext();
    const [tileState, setTileState] = useState<TileState>('idle');
    const item = { id: 'placeholder', label: 'Placeholder...' };
    const data = getItemData({ item, index: 0, instanceId, type: 'rank-null' });

    useEffect(() => {
        const element = ref.current;
        invariant(element);

        return combine(
            registerItem({ itemId: 'placeholder', type: 'rank-null', element }),
            dropTargetForElements({
                element,
                getData: () => data,
                getIsSticky: () => true,
                canDrop: () => true,
                onDragEnter: () => setTileState('over'),
                onDrop: () => setTileState('idle')
            }),
        );
    }, [registerItem, data]);

    const placeholderMessage = useMemo(() => tileState === 'over' ? 'Remove from Supply Chain' : '', [tileState]);

    return (
        <div className={styles.supplyChainPlaceholderWrapper}>
            <div
                className={classnames(styles.placeholder, {
                    [styles.placeholderOver]: tileState === 'over',
                    [styles.supplyChainIdle]: tileState === 'idle'
                })}
                ref={ref}
            >{placeholderMessage}</div>
        </div>
    );
};

export const ListPlaceholder: React.FC = () => (
    <div className={styles.droppableWrapper}>
        <Placeholder />
    </div>
);
