import { RawDraftContentState } from 'draft-js';
import { action } from 'typesafe-actions';

import { UserAnswerId } from '../../attestations/store';
import { AttestationInstanceScheduler, CalendarEvent, CreateAttestationContent, ScheduledActionsListView, ScheduleEventTab, Scheduler, SchedulerDB, SendEmailContent, WorkflowActionTypes, WorkflowPage } from './types';

export const setWorkflowPage = (page: WorkflowPage) => action(WorkflowActionTypes.SET_WORKFLOW_PAGE, page);

export const toggleScheduleWizard = (isOpen: boolean, event?: Scheduler) => action(WorkflowActionTypes.TOGGLE_SCHEDULE_WIZARD, { isOpen, event });

export const setEventValue = (key: keyof Scheduler, value: string | number | null | CreateAttestationContent | SendEmailContent) => action(WorkflowActionTypes.SET_EVENT_VALUE, { key, value });

export const setSelectedMonthYear = (monthYear: string) => action(WorkflowActionTypes.SET_SELECTED_MONTH_YEAR, monthYear);
export const resetCalendar = (date: Date) => action(WorkflowActionTypes.RESET_CALENDAR, date);
export const setToolbarDate = (date: Date) => action(WorkflowActionTypes.SET_TOOLBAR_DATE, date);

export const setIsEditing = (value: boolean) => action(WorkflowActionTypes.SET_SCHEDULED_ACTION_EDITING, value);
export const showUpdatedMessage = (value: boolean) => action(WorkflowActionTypes.SHOW_SCHEDULE_UPDATED_MESSAGE, value);
export const setSelectedEventTab = (tab: ScheduleEventTab) => action(WorkflowActionTypes.SET_SELECTED_EVENT_TAB, tab);
export const setSelectedTabValid = (tabValid: boolean) => action(WorkflowActionTypes.SET_SELECTED_EVENT_TAB_VALID, tabValid);

export const updateContentAttestationId = (attestationFormId: number, userAnswerIds: UserAnswerId[]) => action(WorkflowActionTypes.UPDATE_CONTENT_ATTESTATION_ID, { attestationFormId, userAnswerIds });
export const updateContentAttestationInstances = (instancesToCreate: AttestationInstanceScheduler[]) => action(WorkflowActionTypes.UPDATE_CONTENT_ATTESTATION_INSTANCES, instancesToCreate);

export const setScheduledEmailContent = (key: keyof SendEmailContent, value: number[] | string | RawDraftContentState | null) => action(WorkflowActionTypes.SET_SCHEDULED_EMAIL_CONTENT, { key, value });

export const confirmScheduleDetails = (checked: boolean) => action(WorkflowActionTypes.CONFIRM_SCHEDULED_DETAILS, checked);

export const saveScheduledActionStarted = () => action(WorkflowActionTypes.SAVE_SCHEDULED_ACTION_STARTED);
export const saveScheduledActionSuccessful = (scheduledActions: CalendarEvent[], allScheduledActions: SchedulerDB[]) => action(WorkflowActionTypes.SAVE_SCHEDULED_ACTION_SUCCESSFUL, { scheduledActions, allScheduledActions });
export const saveScheduledActionFailed = (e: string) => action(WorkflowActionTypes.SAVE_SCHEDULED_ACTION_FAILED, e);

export const runIndirectRulesStarted = () => action(WorkflowActionTypes.RUN_INDIRECT_RULES_STARTED);
export const runIndirectRulesSuccessful = () => action(WorkflowActionTypes.RUN_INDIRECT_RULES_SUCCESSFUL);
export const runIndirectRulesFailed = (error: string) => action(WorkflowActionTypes.RUN_INDIRECT_RULES_FAILED, error);

export const fetchCalendarEventsStarted = () => action(WorkflowActionTypes.FETCH_CALENDAR_EVENTS_STARTED);
export const fetchCalendarEventsSuccessful = (scheduledActions: CalendarEvent[]) => action(WorkflowActionTypes.FETCH_CALENDAR_EVENTS_SUCCESSFUL, scheduledActions);
export const fetchCalendarEventsFailed = (error: string) => action(WorkflowActionTypes.FETCH_CALENDAR_EVENTS_FAILED, error);

export const fetchAllCalendarEventsStarted = (view: ScheduledActionsListView) => action(WorkflowActionTypes.FETCH_ALL_CALENDAR_EVENTS_STARTED, view);
export const fetchAllCalendarEventsSuccessful = (scheduledActions: SchedulerDB[]) => action(WorkflowActionTypes.FETCH_ALL_CALENDAR_EVENTS_SUCCESSFUL, scheduledActions);
export const fetchAllCalendarEventsFailed = (error: string) => action(WorkflowActionTypes.FETCH_ALL_CALENDAR_EVENTS_FAILED, error);

export const toggleDeleteEventConfirmationModal = (scheduledActionId: number | null) => action(WorkflowActionTypes.TOGGLE_DELETE_EVENT_CONFIRMATION_MODAL, scheduledActionId);

export const deleteScheduledActionStarted = () => action(WorkflowActionTypes.DELETE_SCHEDULED_ACTION_STARTED);
export const deleteScheduledActionSuccessful = (scheduledActions: CalendarEvent[], allScheduledActions: SchedulerDB[]) => action(WorkflowActionTypes.DELETE_SCHEDULED_ACTION_SUCCESSFUL, { scheduledActions, allScheduledActions });
export const deleteScheduledActionFailed = (error: string) => action(WorkflowActionTypes.DELETE_SCHEDULED_ACTION_FAILED, error);

export const skipScheduledActionStarted = (scheduledActionId: number, isSkipped: boolean) => action(WorkflowActionTypes.SKIP_SCHEDULED_ACTION_STARTED, { scheduledActionId, isSkipped });
export const skipScheduledActionSuccessful = (scheduledActions: CalendarEvent[]) => action(WorkflowActionTypes.SKIP_SCHEDULED_ACTION_SUCCESSFUL, scheduledActions);
export const skipScheduledActionFailed = (error: string) => action(WorkflowActionTypes.SKIP_SCHEDULED_ACTION_FAILED, error);

export const toggleScheduledActionsListView = (view: ScheduledActionsListView) => action(WorkflowActionTypes.TOGGLE_SCHEDULED_ACTIONS_LIST_VIEW, view);

export const setSelectedEventDate = (date: string) => action(WorkflowActionTypes.SET_SELECTED_EVENT_DATE, date);

export const recoverScheduledActionStarted = (scheduledAction: Scheduler | SchedulerDB) => action(WorkflowActionTypes.RECOVER_SCHEDULED_ACTION_STARTED, scheduledAction);
export const recoverScheduledActionSuccessful = (scheduledActions: CalendarEvent[], allScheduledActions: SchedulerDB[]) => action(WorkflowActionTypes.RECOVER_SCHEDULED_ACTION_SUCCESSFUL, { scheduledActions, allScheduledActions });
export const recoverScheduledActionFailed = (error: string) => action(WorkflowActionTypes.RECOVER_SCHEDULED_ACTION_FAILED, error);
