import React, { useMemo, useCallback } from 'react';
import { isNull, noop } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { DocumentAnalyticsChart, fetchAllDatasetRiskToleranceStarted, getAllDocumentsRiskTolerance, getDocumentRiskTolerance, getFetchingAnalytics, getDocumentRiskToleranceView, setRiskToleranceView, DocumentRiskView } from '../../store';
import { useFetchStarted } from '../../../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted } from '../../../../admin/dropdown-lists/store';
import { RiskToleranceChart } from './RiskToleranceChart';

interface DocumentRiskToleranceChartWrapperProps {
    tileInView: null | DocumentAnalyticsChart;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
    dimensions?: {
        height: number;
        width: number;
    };
}

export const DocumentRiskToleranceChartWrapper: React.FC<DocumentRiskToleranceChartWrapperProps> = ({ setTileInView, tileInView, analyticsSpinner, dimensions }) => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDatasetRiskToleranceStarted(), fetchAllDropdownListsStarted()]);

    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const documentRiskTolerance = useAppSelector(getDocumentRiskTolerance);
    const allDocumentsRiskTolerance = useAppSelector(getAllDocumentsRiskTolerance);
    const riskToleranceView = useAppSelector(getDocumentRiskToleranceView);
    const chartIsFullScreen = useMemo(() => tileInView === DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE, [tileInView]);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE) || analyticsSpinner, [fetchingAnalytics, analyticsSpinner]);
    const documentTableView = useCallback((view: DocumentRiskView) => chartIsFullScreen && riskToleranceView === view, [chartIsFullScreen, riskToleranceView]);
    const showDocumentTable = useMemo(() => documentTableView(DocumentRiskView.DOCUMENTS), [documentTableView]);
    const showDocumentBreakdown = useMemo(() => documentTableView(DocumentRiskView.DOCUMENT_BREAKDOWN), [documentTableView]);
    const showDocumentDataOptions = useMemo(() => documentTableView(DocumentRiskView.DATASETS), [documentTableView]);
    const showDocumentFieldBreakdown = useMemo(() => documentTableView(DocumentRiskView.DATASET_FIELD), [documentTableView]);

    const getDocumentRiskToleranceAnswers = useCallback(() => isNull(documentRiskTolerance) ? noop : dispatch(setRiskToleranceView(DocumentRiskView.DOCUMENT_BREAKDOWN)), [documentRiskTolerance, dispatch]);
    const setDocumentsView = useCallback(() => dispatch(setRiskToleranceView(DocumentRiskView.DOCUMENTS)), [dispatch]);

    const switchRiskTypeLabel = useMemo(() => riskToleranceView !== DocumentRiskView.DATASETS ? 'Agreement Type' : 'Document Table', [riskToleranceView]);
    const switchRiskTypeValue = useCallback(() => riskToleranceView === DocumentRiskView.DATASETS ? dispatch(setRiskToleranceView(DocumentRiskView.DOCUMENTS)) : dispatch(setRiskToleranceView(DocumentRiskView.DATASETS)), [riskToleranceView, dispatch]);

    return (
        <RiskToleranceChart
            tile={DocumentAnalyticsChart.DOCUMENT_RISK_TOLERANCE}
            setTileInView={setTileInView}
            tileInView={tileInView}
            dimensions={dimensions}
            allDocumentsRiskTolerance={allDocumentsRiskTolerance}
            chartIsFullScreen={chartIsFullScreen}
            showSpinner={showSpinner}
            showDocumentTable={showDocumentTable}
            showDocumentBreakdown={showDocumentBreakdown}
            showDocumentDataOptions={showDocumentDataOptions}
            showDocumentFieldBreakdown={showDocumentFieldBreakdown}
            switchRiskTypeValue={switchRiskTypeValue}
            getDocumentRiskToleranceAnswers={getDocumentRiskToleranceAnswers}
            setDocumentsView={setDocumentsView}
            switchRiskTypeLabel={switchRiskTypeLabel}
        />
    );
};
