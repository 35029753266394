import { useCallback, useRef, useState } from 'react';

interface Options {
    delay?: number,
}

export const useLongPress = ( onLongPress: (e: React.MouseEvent) => void, onClick: (e: React.MouseEvent) => void, { delay = 300 }: Options= {}) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const timeout = useRef<NodeJS.Timeout>();

    const start = useCallback(
        (e: React.MouseEvent) => {
            timeout.current = setTimeout(() => {
                onLongPress(e);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay]
    );

    const clear = useCallback((e: React.MouseEvent, shouldTriggerClick = true) => {
        timeout.current && clearTimeout(timeout.current);
        shouldTriggerClick && !longPressTriggered && onClick?.(e);
        setLongPressTriggered(false);
    }, [onClick, longPressTriggered]);

    return {
        onMouseDown: (e: React.MouseEvent) => start(e),
        onMouseUp: (e: React.MouseEvent) => clear(e),
        onMouseLeave: (e: React.MouseEvent) => clear(e, false)
    };
};
