import classnames from 'classnames';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { Icon } from '../../shared/icon/Icon';
import { Document } from '../../shared/icons';
import { PositionModal, PositionModalProps } from '../../shared/modal/PositionModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { OverflowTooltip } from '../../shared/tooltip';
import styles from './Notifications.module.scss';
import { ActiveMLDocument, getActiveMLDocuments } from './store';

interface ProgressBarProps {
    progress: number;
    progressString?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, progressString = '' }) => {
    const progressBarWidth = useMemo(() => `${progress === 0 ? 100 : progress}%`, [progress]);
    const percentageComplete = useMemo(() => progress === 0 ? 'FAILED' : `${progress}%`, [progress]);
    return (
        <>
            <div className={styles.progressBar}>
                <div className={classnames(styles.progressPercentage, {
                    [styles.activeDocumentProgress]: ![100, 0].includes(progress),
                    [styles.completedAI]: progress === 100,
                    [styles.failedAI]: progress === 0
                })} style={{ width: progressBarWidth }} />
            </div>
            <div className={styles.progressInfo}>
                <div className={styles.progressString}>
                    <OverflowTooltip overlayText={progressString} />
                </div>
                <div className={styles.displayPercentage}>{percentageComplete}</div>
            </div>
        </>
    );
};

interface ActiveDocumentProps {
    document: ActiveMLDocument;
}

const ActiveDocument: React.FC<ActiveDocumentProps> = ({ document: { documentDescription, progress, documentId, entityAName, entityBName, documentName, initialUpload, progressString } }) => {

    if (initialUpload) {
        return (
            <div className={styles.activeMLDocument} data-testid={`active-initial-ml-document-${documentId}`}>
                <div className={styles.documentDetailsWrapper}>
                    <div className={styles.documentIconWrapper}>
                        <Icon icon={Document} />
                    </div>
                    <div className={styles.documentInformationWrapper}>
                        <div className={styles.documentName}>
                            <OverflowTooltip overlayText='Document Unclassified' />
                        </div>
                        <div className={styles.documentDescription}>
                            <OverflowTooltip overlayText={documentDescription} />
                        </div>
                    </div>
                </div>
                <ProgressBar progress={progress} progressString={progressString} />
            </div>
        );
    }

    return (
        <div className={styles.activeMLDocument} data-testid={`active-extensive-ml-document-${documentId}`}>
            <div className={styles.documentDetailsWrapper}>
                <div className={styles.documentIconWrapper}>
                    <Icon icon={Document} />
                </div>
                <div className={styles.documentInformationWrapper}>
                    <div className={styles.documentName}>
                        <OverflowTooltip overlayText={documentName} />
                    </div>
                    <div className={styles.documentDescription}>
                        <OverflowTooltip overlayText={documentDescription} />
                    </div>
                    <div className={styles.entitiesWrapper}>
                        <div className={styles.entityWrapper}>
                            <OverflowTooltip overlayText={entityAName} />
                        </div>
                        <div className={styles.entityWrapper} style={{ marginLeft: '10px' }}>
                            <OverflowTooltip overlayText={entityBName} />
                        </div>
                    </div>
                </div>
            </div>
            <ProgressBar progress={progress} progressString={progressString} />
        </div>
    );
};

export const MLProgressModal: React.FC<PositionModalProps> = ({ position, closeModal, isOpen, testId }) => {
    const activeMLDocuments = useAppSelector(getActiveMLDocuments);

    return (
        <PositionModal
            isOpen={isOpen}
            closeModal={closeModal}
            position={position}
            testId={testId}
            height='fit-content'
            renderLeftOfClick
            width='320px'
            overflow='none'
        >
            <div className={styles.activeMLDocumentsWrapper}>
                <div className={styles.activeMLDocumentsHeader}>AI Progress</div>
                <div className={styles.activeMLDocuments}>
                    <Scrollable maxHeight='50vh'>
                        {activeMLDocuments.map(document => (
                            <ActiveDocument key={document.documentId} document={document} />
                        ))}
                    </Scrollable>
                </div>
            </div>
        </PositionModal>
    );
};
