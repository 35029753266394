import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { DatasetInstance } from '../../../datasets/instances/DatasetInstance';
import { HistoricalInstances } from '../../../datasets/instances/historical/HistoricalInstances';
import { getCurrentInstances, getDatasetInstanceTimeline, getIsLoading, getParentDatasetId } from '../../../datasets/instances/store';
import { Spinner } from '../../../shared/spinner/Spinner';
import styles from '../SupplyChain.module.scss';

interface IctContractInstanceProps {
    height: number;
}

export const IctContractInstance: React.FC<IctContractInstanceProps> = ({ height }) => {
    const allInstances = useAppSelector(getCurrentInstances);
    const parentDatasetId = useAppSelector(getParentDatasetId);
    const parentInstance = useMemo(() => allInstances.map(({ instance }) => instance).find(({ datasetId }) => datasetId === parentDatasetId) || null, [allInstances, parentDatasetId]);
    const timeline = useAppSelector(getDatasetInstanceTimeline);
    const isLoadingInstance = useAppSelector(getIsLoading);

    if (!parentInstance || isLoadingInstance || !timeline) {
        return <Spinner />;
    }

    return (
        <div className={styles.datasetInstanceWrapper}>
            <HistoricalInstances timeline={timeline!} preventRedirect={true} />
            <div className={styles.datasetInstanceFormWrapper} style={{ height: `calc(${height}px - 54px)` }}>
                <DatasetInstance
                    datasetInstance={parentInstance!}
                    isEditing={false}
                    isUpdating={false}
                    parentDatasetIds={[]}
                    parentFieldId=''
                    instanceExecutedDateMatchesParent
                    modalInstance
                    fullWidth
                />
            </div>
        </div>
    );
};
