import { isArray } from 'lodash/fp';
import React from 'react';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';

import { useAppDispatch } from '../../../hooks/react-redux';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Fields.module.scss';

const convertUTCToLocalDate = (date: Date | null): null | Date => {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return date;
};

const convertLocalToUTCDate = (date: Date | [Date | null, Date | null] | null) => {
    if (isArray(date)) {
        date = date[0];
    }

    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return date;
};

interface DateFieldProps {
    id: string;
    value: Date | null;
    index: number;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    disabled?: boolean;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean;
    showAIModifiedUserCorrected?: boolean;
    isMLCorrection?: boolean;
    showClause?: boolean;
    isTable?: boolean;
    includedInAnnex?: boolean;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
}

export const DateField: React.FC<DateFieldProps> = ({
    value,
    disabled = false,
    id,
    index,
    sectionId,
    groupIndex,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    isMLCorrection = false,
    showClause = false,
    isTable = false,
    includedInAnnex = false,
    datasetId,
    modalInstance,
    parentFieldId
}) => {
    const dispatch = useAppDispatch();
    const updateValue = (value: Date | null) => {
        if (isMLCorrection) {
            dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
        }
    };

    const placeholderText = includedInAnnex ? 'See Agency Annex' : 'Select a date';

    return (
        <DatePicker
            dateFormat='dd/MM/yyyy'
            disabled={disabled || includedInAnnex}
            selected={convertUTCToLocalDate(value)}
            onChange={date => updateValue(convertLocalToUTCDate(date))}
            placeholderText={placeholderText}
            className={classnames(styles.dateField, {
                [styles.updatedInputField]: showFieldUpdated,
                [styles.newInputField]: showDatasetUpdated,
                [styles.aiInputField]: showAIModified,
                [styles.aiCorrectedInputField]: showAIModifiedUserCorrected,
                [styles.dateFieldWithClauseLabel]: showClause && isTable
            })}
            data-testid={`definition-date-${id}`}
            wrapperClassName={styles.dateFieldWrapper}
            fixedHeight
            showYearDropdown
            dateFormatCalendar='MMMM'
            yearDropdownItemNumber={40}
            scrollableYearDropdown
            dropdownMode='scroll'
            portalId='root'
        />
    );
};
