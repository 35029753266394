import { isNull, isUndefined } from 'lodash/fp';

import { formatDate, SHORT_MONTH_FORMAT } from '../../../utils/luxon';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { UserRole } from '../../constants/permittedRoles';
import { roleLabels } from '../../constants/roleLabels';
import { ArkOpinion } from '../../opinions/my-opinions/store';
import { OpinionScope, OpinionCommissionedBy } from '../../admin/opinions/store';

export const deadlineFormatter = (value: string | null) => !isNull(value) ? formatDate(value, SHORT_MONTH_FORMAT) : 'No deadline set';
export const arrayLengthFormatter = (value: string | null) => !isNull(value) ? value.length : 0;
export const dateFormatter = (value: string | null) => value ? formatDate(value, SHORT_MONTH_FORMAT) : 'NONE';
export const addressFormatter = (value: string) => value.split(', ');
export const fileSizeFormatter = (value: string | null) => `${value ? (parseInt(value) / 1e6).toFixed(2) : 0}MB`;
export const nullFormatter = (value: string | null) => isUndefined(value) ? 'Not Linked' : value;
export const versionFormatter = (value: string | null) => isUndefined(value) ? 0 : value;
export const stringFormatter = (value: string | null) => value ? value.slice(0, 1).toUpperCase() + value.slice(1) : value;
export const toCapsFormatter = (value: string | null) => value ? value.toUpperCase() : '';
export const capitaliseFirstLetterFormatter = (value: string | null) => value ? capitaliseStartLetter(value) : '';
export const entityFormatter = (value: string | null) => isNull(value) ? 'NONE' : value;
export const commissionedByFormatter = (value: string | null, data: ArkOpinion) => {
    if (isNull(value)) {
        return 'Not Linked';
    } else if (value === OpinionCommissionedBy.OTHER) {
        return isNull(data.commissionedByIfOther) ? OpinionCommissionedBy.OTHER : data.commissionedByIfOther;
    } else if (!isNull(data.scope) && data.bespoke === 0 && data.scope === OpinionScope.GMRA_GMSLA_NETTING) {
        return 'ICMA/ISLA';
    }
    return value;
};
export const roleFormatter = (value: string | null) => value ? roleLabels[value as UserRole] : '';
export const bespokeFormatter = (value: number) => value ? 'Bespoke' : 'Industry Standard';
export const scopeFormatter = (value: OpinionScope | null | string) => {
    if (isNull(value)) {
        return '';
    }
    if (value === OpinionScope.ISDA_NETTING) {
        return 'ISDA Netting';
    }
    if (value === OpinionScope.GMRA_NETTING) {
        return 'GMRA Netting';
    }
    if (value === OpinionScope.GMSLA_NETTING) {
        return 'GMSLA Netting';
    }
    if (value === OpinionScope.GMRA_GMSLA_NETTING) {
        return 'GMRA & GMSLA Netting';
    }
    return `${capitaliseStartLetter(value)} Insolvency`;
};
