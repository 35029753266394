import React, { useCallback, useMemo } from 'react';
import { set } from 'lodash/fp';

import styles from './PlaybookBookcase.module.scss';
import { PlaybookLogicalOperator, PlaybookSmartSearch as PlaybookSmartSearchType } from '../../admin/playbook/store';
import { Scrollable } from '../scrollable/Scrollable';
import { PlusButton } from '../button/PlusButton';
import { Button } from '../button/Button';
import { PlaybookSmartSearchRow } from './PlaybookSmartSearchRow';
import { useWindowResize } from '../../../hooks/useWindowResize';

const NAV_BAR = 100;
const SEARCH_BUTTONS = 48;
const PADDING = 60;
const HEIGHT_OFFSET = NAV_BAR + SEARCH_BUTTONS + PADDING;

interface PlaybookSmartSearchProps {
    initialPlaybookSmartSearchTerm: PlaybookSmartSearchType;
    resetPlaybookSearch: () => void;
    smartSearchTerm: PlaybookSmartSearchType[];
    updateSmartSearch: (searchTerm: PlaybookSmartSearchType[]) => void;
    startSmartSearch: () => void;
    playbookAuthors: string[];
    isSearching: boolean;
    testId?: string;
}

export const PlaybookSmartSearch: React.FC<PlaybookSmartSearchProps> = ({ initialPlaybookSmartSearchTerm, resetPlaybookSearch, smartSearchTerm, updateSmartSearch, startSmartSearch, playbookAuthors, isSearching = false, testId = 'playbooks' }) => {
    const [, screenHeight] = useWindowResize();

    const resetDisabled = smartSearchTerm.every(({ searchTerm }) => searchTerm.length === 0);
    const smartSearchHeight = useMemo(() => screenHeight - HEIGHT_OFFSET, [screenHeight]);

    const startPlaybookSearch = useCallback(() => startSmartSearch(), [startSmartSearch]);

    const addSearchTermRow = () => updateSmartSearch([...smartSearchTerm, initialPlaybookSmartSearchTerm]);

    const updateValue = (key: keyof PlaybookSmartSearchType, value: string, rowIndex: number) => {
        const updatedSmartSearch = smartSearchTerm.map((row, index) => index === rowIndex ? set(`${key}`, value, row) : row);
        updateSmartSearch(updatedSmartSearch);
    };

    const removeRow = (rowIndex: number) => updateSmartSearch(smartSearchTerm.filter((_, index) => index !== rowIndex));

    const resetSearchTerm = () => {
        resetPlaybookSearch();
    };

    return (
        <div className={styles.playbookSmartSearch} data-testid={`${testId}-smart-search-wrapper`}>
            <div className={styles.smartInputWrapper}>
                <Scrollable maxHeight={`${smartSearchHeight}px`}>
                    <div className={styles.smartInputRowsWrapper}>
                        {smartSearchTerm.map((row, index) => {
                            const isFirstRow = index === 0;
                            const showDelete = smartSearchTerm.length !== 1 && !isFirstRow;
                            const isOr = row.logicalOperator === PlaybookLogicalOperator.OR;
                            return (
                                <>
                                    {isOr && <div className={styles.conditionSeparator}></div>}
                                    <PlaybookSmartSearchRow
                                        key={index}
                                        index={index}
                                        isFirstRow={isFirstRow}
                                        showDelete={showDelete}
                                        removeRow={removeRow}
                                        updateValue={updateValue}
                                        row={row}
                                        playbookAuthors={playbookAuthors}
                                        testId={`${testId}-smart-search-row`}
                                    />
                                </>
                            );
                        })}
                    </div>
                </Scrollable>
                <div className={styles.buttonWrapper} data-testid={`${testId}-smart-search-buttons-wrapper`}>
                    <PlusButton onClick={addSearchTermRow} fontSize={20} testId={`${testId}-add-search-term`} />
                    <div className={styles.smartSearchButtonWrapper}>
                        <Button label='Reset' onClick={resetSearchTerm} disabled={resetDisabled} testId={`reset-${testId}-smart-search`} />
                        <Button label='Search' onClick={startPlaybookSearch} testId={`${testId}-smart-search`} disabled={isSearching} />
                    </div>
                </div>
            </div>
        </div>
    );
};
