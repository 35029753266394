import React, { useCallback, useMemo, useState } from 'react';
import { isNull } from 'lodash/fp';
import { v4 } from 'uuid';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DocumentCustomFilters, OpinionCustomFilters, FilterTable, CustomFilters } from '../../auth/login/store';
import styles from '../Home.module.scss';
import { getAddNewFilterModalOpen, getCurrentUserCustomFilters, getIsSavingCustomFilters, toggleAddNewFilterModal, resetNewCustomFilterValue, toggleFilterTileModal, addOrDeleteUserCustomFilter, CustomFiltersView, setSelectedCustomFilter } from '../store';
import { PlusButton } from '../../shared/button/PlusButton';
import { CustomFiltersTable } from '../custom-filters/CustomFiltersTable';
import { CustomFiltersAddNewFilter } from '../custom-filters/CustomFiltersAddNewFilter';
import { CustomFiltersTileModal } from '../custom-filters/CustomFilterTileModal';
import { TableTabs } from '../../shared/table/TableTabs';

const documentInitialFilter = (id: string, filterName: string): DocumentCustomFilters => ({ filterName, filterTable: FilterTable.DOCUMENT, id, entityA: { text: '', dropdown: null }, entityB: { text: '', dropdown: null }, documentDescription: { text: '', dropdown: null }, documentName: { text: '', dropdown: null } });
const opinionInitialFilter = (id: string, filterName: string): OpinionCustomFilters => ({ filterName, filterTable: FilterTable.OPINION, id, jurisdiction: { text: '', dropdown: null }, focus: { text: '', dropdown: null }, bespoke: { text: '', dropdown: null }, scope: { text: '', dropdown: null }, commissionedBy: { text: '', dropdown: null } });

export const MyProfileTableFilters: React.FC = () => {
    const dispatch = useAppDispatch();

    const testId = 'my-profile';

    const [documentFilterValue, setDocumentFilterValue] = useState<string>('');
    const [opinionFilterValue, setOpinionFilterValue] = useState<string>('');

    const isAddNewFilterModalOpen = useAppSelector(getAddNewFilterModalOpen);
    const currentCustomFilters = useAppSelector(getCurrentUserCustomFilters);
    const isSaving = useAppSelector(getIsSavingCustomFilters);

    const filteredDocumentCustomFilters = useMemo(() => !isNull(currentCustomFilters) ? currentCustomFilters.filter(({ filterName, filterTable }) => filterTable === FilterTable.DOCUMENT && filterName.toLowerCase().includes(documentFilterValue)) : [], [currentCustomFilters, documentFilterValue]);
    const filteredOpinionCustomFilters = useMemo(() => !isNull(currentCustomFilters) ? currentCustomFilters.filter(({ filterName, filterTable }) => filterTable === FilterTable.OPINION && filterName.toLowerCase().includes(opinionFilterValue)) : [], [currentCustomFilters, opinionFilterValue]);

    const addNewCustomFilter = useCallback(() => dispatch(toggleAddNewFilterModal(true)), [dispatch]);

    const closeAddNewFilterModal = useCallback(() => {
        dispatch(toggleAddNewFilterModal(false));
        dispatch(resetNewCustomFilterValue());
    }, [dispatch]);

    const openCustomFilterTile = useCallback((filterId: string) => dispatch(toggleFilterTileModal(filterId)), [dispatch]);

    const closeCustomFilterTile = useCallback(() => dispatch(toggleFilterTileModal(null)), [dispatch]);

    const onAddNewTile = useCallback((newCustomFilter: CustomFilters) => {
        const { filterName, filterTable } = newCustomFilter;
        const customFilterId = v4();
        const newTile = filterTable === FilterTable.DOCUMENT ? documentInitialFilter(customFilterId, filterName) : opinionInitialFilter(customFilterId, filterName);
        const updatedTiles: CustomFilters[] = [...currentCustomFilters, newTile];
        dispatch(addOrDeleteUserCustomFilter(updatedTiles));
        closeAddNewFilterModal();
        openCustomFilterTile(customFilterId);
    }, [currentCustomFilters, dispatch, closeAddNewFilterModal, openCustomFilterTile]);

    const onDeleteCustomFilter = useCallback((filterId: string) => {
        const updatedFilters = currentCustomFilters.filter(({ id }) => id !== filterId);
        dispatch(addOrDeleteUserCustomFilter(updatedFilters));
    }, [currentCustomFilters, dispatch]);

    const [selectedTab, setSelectedTab] = useState<CustomFiltersView>(CustomFiltersView.DOCUMENT);

    const changeTab = useCallback((tab: CustomFiltersView) => {
        dispatch(setSelectedCustomFilter(null));
        setSelectedTab(tab);
    }, [dispatch]);

    const isDocumentView = useMemo(() => selectedTab === CustomFiltersView.DOCUMENT, [selectedTab]);
    const tableTabs = [
        { tabToggleAction: () => changeTab(CustomFiltersView.DOCUMENT), tabTitle: 'Document', isSelected: isDocumentView },
        { tabToggleAction: () => changeTab(CustomFiltersView.OPINION), tabTitle: 'Opinion', isSelected: !isDocumentView }
    ];

    const table = useMemo(() => {
        if (isDocumentView) {
            return (
                <CustomFiltersTable
                    onChangeFilterValue={e => setDocumentFilterValue(e.target.value)}
                    filterValue={documentFilterValue}
                    filterTable={FilterTable.DOCUMENT}
                    onDelete={onDeleteCustomFilter}
                    filteredCustomFilters={filteredDocumentCustomFilters}
                    isSaving={isSaving}
                />
            );
        }
        return (
            <CustomFiltersTable
                onChangeFilterValue={e => setOpinionFilterValue(e.target.value)}
                filterValue={opinionFilterValue}
                filterTable={FilterTable.OPINION}
                onDelete={onDeleteCustomFilter}
                filteredCustomFilters={filteredOpinionCustomFilters}
                isSaving={isSaving}
            />
        );
    }, [isDocumentView, documentFilterValue, filteredDocumentCustomFilters, filteredOpinionCustomFilters, isSaving, onDeleteCustomFilter, opinionFilterValue]);

    return (
        <div className={styles.tableFiltersSectionWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.addWrapper}>
                    <div className={styles.customFiltersContentAddNewTitle}>Add New Filter</div>
                    <PlusButton onClick={addNewCustomFilter} fontSize={20} />
                </div>
                <div className={styles.tabsWrapper}>
                    <TableTabs
                        tabs={tableTabs}
                        testId={testId}
                        margin='0px'
                    />
                </div>
            </div>
            {table}
            <CustomFiltersAddNewFilter isOpen={isAddNewFilterModalOpen} confirm={onAddNewTile} closeModal={closeAddNewFilterModal} />
            <CustomFiltersTileModal closeModal={closeCustomFilterTile} />
        </div >
    );
};
