import download from 'downloadjs';
import { isEqual, isNull, isUndefined, unset } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, debounce, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { deleteSupplyChainElement, downloadDoraDocument, fetchAllBaseCompanies, fetchAllFunctionCompanies, fetchSupplyChainConfiguration, fetchSupplyChainElementDetails, linkSupplyChainElementToIctContract, previewDoraDocument, uploadDoraDocuments, upsertSupplyChainConfiguration, upsertSupplyChainElement } from '../../../../services/dora';
import { getDocumentUrl } from '../../../../utils/getDocumentUrl';
import { dotsToDashes } from '../../../../utils/regex-utils';
import { initialAssessmentDetails } from '../../../constants/dora';
import { openDocumentStarted } from '../../../documents/my-documents/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { deleteSupplyChainElementFailed, deleteSupplyChainElementSuccessful, downloadDoraDocumentFailed, downloadDoraDocumentStarted, downloadDoraDocumentSuccessful, fetchAllBaseCompaniesFailed, fetchAllBaseCompaniesSuccessful, fetchAllFunctionCompanyDetailsFailed, fetchAllFunctionCompanyDetailsSuccessful, fetchElementDetailsFailed, fetchElementDetailsStarted, fetchElementDetailsSuccessful, fetchSupplyChainConfigurationFailed, fetchSupplyChainConfigurationSuccessful, linkSupplyChainToIctContractFailed, linkSupplyChainToIctContractStarted, linkSupplyChainToIctContractSuccessful, openDoraDocumentFailed, openDoraDocumentStarted, openDoraDocumentSuccessful, openDoraSupplyChainFromParams, setSelectedSupplyChainElementTab, setSupplyChainElementHasUpdated, setSupplyChainHasUpdated, toggleSupplyChainDetailsModalOpen, toggleSupplyChainElement, updateSavedSupplyChain, updateSupplyChain, updateSupplyChainElementAssessment, uploadDoraDocumentFailed, uploadDoraDocumentStarted, uploadDoraDocumentSuccessful, upsertSupplyChainConfigurationFailed, upsertSupplyChainConfigurationSuccessful, upsertSupplyChainElementFailed, upsertSupplyChainElementStarted, upsertSupplyChainElementSuccessful } from './actions';
import { getAssessmentInfoFiles, getSelectedElementContractualInfo, getCompanyFunctionFilters, getCurrentSupplyChain, getDetailsInformation, getDoraFunctionCompanies, getHasDoraFilesToUpload, getNewSupplyChainElement, getSavedDetailsInformation, getSavedElementAssessmentInfo, getSavedSupplyChain, getSelectedCompanyId, getSelectedElementAssessmentInfo, getSelectedFunctionId, getSelectedSupplyChainId, getSupplyChainElements } from './selectors';
import { DetailsDocument, DoraDocumentType, DoraFunctionCompany, DoraSupplyChainActionTypes, DoraSupplyChainAssessmentDetails, DoraSupplyChainAssessmentInfo, DoraSupplyChainContractualInfo, DoraSupplyChainDetails, NewSupplyChainElement, SupplyChain, SupplyChainElement, SupplyChainElementDetails, SupplyChainElementTab } from './types';

const PDF_MIME_TYPE = 'application/pdf';

export const parentSupplyChain = (label: string): SupplyChainElement => ({
    doraSupplyChainId: 0,
    label,
    supplyChainRank: 0,
    parentId: null,
    details: {
        serviceProvided: null,
        furtherICTServiceDetails: null,
        isMaterial: false,
        justification: null
    },
    thirdPartyCompanyId: null,
    companyId: null,
    hasContractDetails: false,
    hasAssessmentDetails: false
});

export function* attemptGetAllFunctionCompanyInstances() {
    try {
        const filters: TableFilters = yield select(getCompanyFunctionFilters);
        const doraFunctionCompanies: DoraFunctionCompany[] = yield call(fetchAllFunctionCompanies, { filters });
        yield put(fetchAllFunctionCompanyDetailsSuccessful(doraFunctionCompanies));
    } catch (e) {
        toast.error('Unable to fetch dora function companies. Please try again.');
        yield put(fetchAllFunctionCompanyDetailsFailed((e as Error).message));
    }
}

function* getAllFunctionCompanyInstancesWatcher() {
    yield takeEvery(DoraSupplyChainActionTypes.FETCH_ALL_FUNCTION_COMPANY_DETAILS_STARTED, attemptGetAllFunctionCompanyInstances);
}

export function* attemptFetchSupplyChainConfiguration() {
    try {
        const doraFunctionCompanies: DoraFunctionCompany[] = yield select(getDoraFunctionCompanies);
        const selectedFunctionId: number | null = yield select(getSelectedFunctionId);
        const selectedCompanyId: number | null = yield select(getSelectedCompanyId);
        if (!isNull(selectedCompanyId) && !isNull(selectedFunctionId)) {
            const doraFunctionCompany = doraFunctionCompanies.find(({ doraFunctionId, companyId }) => (doraFunctionId === selectedFunctionId) && (companyId === selectedCompanyId));
            if (doraFunctionCompany) {
                const supplyChainConfig: SupplyChain = yield call(fetchSupplyChainConfiguration, { doraFunctionCompany });
                const { supplyChain } = supplyChainConfig;
                const { functionName, companyName, doraFunctionCompanyId } = doraFunctionCompany;
                const supplyChainWithParent = [parentSupplyChain(`${companyName}-${functionName}`), ...supplyChain];
                const supplyChainConfigWithParent = { ...supplyChainConfig, supplyChain: supplyChainWithParent };
                yield put(fetchSupplyChainConfigurationSuccessful(supplyChainConfigWithParent));
                const path = `/dora/data-management/supply-chain/${doraFunctionCompanyId}`;
                yield put(push(path));
            }
        }
    } catch (e) {
        toast.error('Unable to fetch the supply chain for this company and function pair. Please try again.');
        yield put(fetchSupplyChainConfigurationFailed((e as Error).message));
    }
}

function* fetchSupplyChainWatcher() {
    yield takeEvery(DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_CONFIGURATION_STARTED, attemptFetchSupplyChainConfiguration);
}

export function* openExistingSupplyChainElement({ payload }: ReturnType<typeof toggleSupplyChainElement>) {
    const isOpen = !isNull(payload);
    yield put(toggleSupplyChainDetailsModalOpen(isOpen));
}

function* openExistingSupplyChainElementWatcher() {
    yield takeEvery(DoraSupplyChainActionTypes.TOGGLE_SUPPLY_CHAIN_ELEMENT, openExistingSupplyChainElement);
}

export function* attemptOpenDoraSupplyChainFromParams({ payload }: ReturnType<typeof openDoraSupplyChainFromParams>) {
    try {
        const doraFunctionCompanies: DoraFunctionCompany[] = yield call(fetchAllFunctionCompanies, { filters: {} });
        yield put(fetchAllFunctionCompanyDetailsSuccessful(doraFunctionCompanies));
        const doraFunctionCompany = doraFunctionCompanies.find(({ doraFunctionCompanyId }) => doraFunctionCompanyId === payload);
        if (doraFunctionCompany) {
            const supplyChainConfig: SupplyChain = yield call(fetchSupplyChainConfiguration, { doraFunctionCompany });
            const { supplyChain } = supplyChainConfig;
            const { functionName, companyName } = doraFunctionCompany;
            const supplyChainWithParent = [parentSupplyChain(`${companyName}-${functionName}`), ...supplyChain];
            const supplyChainConfigWithParent = { ...supplyChainConfig, supplyChain: supplyChainWithParent };
            yield put(fetchSupplyChainConfigurationSuccessful(supplyChainConfigWithParent));
        } else {
            yield put(push('/dora/data-management/supply-chain'));
        }
    } catch (e) {
        yield put(push('/dora/data-management/supply-chain'));
    }
}

function* openDoraSupplyChainFromParamsWatcher() {
    yield takeEvery(DoraSupplyChainActionTypes.OPEN_DORA_SUPPLY_CHAIN_FROM_PARAMS, attemptOpenDoraSupplyChainFromParams);
}

export function* attemptFetchAllBaseCompanies() {
    try {
        const allBaseCompanies: { companies: DropdownOption[]; thirdPartyCompanies: DropdownOption[]; } = yield call(fetchAllBaseCompanies);
        yield put(fetchAllBaseCompaniesSuccessful(allBaseCompanies.companies, allBaseCompanies.thirdPartyCompanies));
    } catch (e) {
        yield put(fetchAllBaseCompaniesFailed((e as Error).message));
    }
}

function* fetchAllBaseCompaniesWatcher() {
    yield takeEvery(DoraSupplyChainActionTypes.FETCH_ALL_DORA_BASE_COMPANIES_STARTED, attemptFetchAllBaseCompanies);
}

export function* attemptUpsertSupplyChainElement() {
    try {
        const selectedSupplyChainId: number | null = yield select(getSelectedSupplyChainId);
        const supplyChainConfig: SupplyChain | null = yield select(getCurrentSupplyChain);
        if (supplyChainConfig) {
            const { supplyChain, doraFunctionCompanyId } = supplyChainConfig;
            let hasContractDetails = false;
            let hasAssessmentDetails = false;
            if (isNull(selectedSupplyChainId)) {
                const newElement: NewSupplyChainElement = yield select(getNewSupplyChainElement);
                const element: SupplyChainElement = yield call(upsertSupplyChainElement, { element: newElement, doraFunctionCompanyId });
                yield put(updateSupplyChain([...supplyChain, element]));
                const savedSupplyChainConfig: SupplyChain | null = yield select(getSavedSupplyChain);
                if (savedSupplyChainConfig) {
                    yield put(updateSavedSupplyChain([...savedSupplyChainConfig.supplyChain, element]));
                }
            } else {
                const element = supplyChain.find(({ doraSupplyChainId }) => doraSupplyChainId === selectedSupplyChainId)!;
                const hasFilesToUpload: boolean = yield select(getHasDoraFilesToUpload);
                if (hasFilesToUpload) {
                    yield put(uploadDoraDocumentStarted(selectedSupplyChainId));
                    return;
                }
                const contractualInfo: DoraSupplyChainContractualInfo | null = yield select(getSelectedElementContractualInfo);
                const assessmentInfo: DoraSupplyChainAssessmentInfo = yield select(getSelectedElementAssessmentInfo);
                hasContractDetails = !isNull(contractualInfo) && !isUndefined(contractualInfo.originalDocumentId);
                hasAssessmentDetails = !isEqual(assessmentInfo.details, initialAssessmentDetails);
                yield call(upsertSupplyChainElement, { element, doraFunctionCompanyId, assessmentInfo });
            }
            yield put(upsertSupplyChainElementSuccessful(hasContractDetails, hasAssessmentDetails));
            yield put(toggleSupplyChainElement(null));
            toast('Successfully updated supply chain information');
        }
    } catch (e) {
        yield put(upsertSupplyChainElementFailed((e as Error).message));
    }
}

function* upsertSupplyChainElementWatcher() {
    yield takeLeading(DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_ELEMENT_STARTED, attemptUpsertSupplyChainElement);
}

export function* attemptUploadDocuments({ payload }: ReturnType<typeof uploadDoraDocumentStarted>) {
    try {
        const doraSupplyChainId = payload;
        const assessmentFiles: File[] = yield select(getAssessmentInfoFiles);
        const assessmentInfo: DoraSupplyChainAssessmentInfo = yield select(getSelectedElementAssessmentInfo);
        if (assessmentFiles.length > 0) {
            let request = new FormData();
            assessmentFiles.forEach(file => request.append('files', file));
            request.append('fileInfo', JSON.stringify({ doraSupplyChainId, documentType: DoraDocumentType.ASSESSMENT }));
            const uploadedDocuments: DetailsDocument[] = yield call(uploadDoraDocuments, request);
            const documents = [...assessmentInfo.details.documents, ...uploadedDocuments];
            yield put(updateSupplyChainElementAssessment('documents', documents));
        }
        yield put(uploadDoraDocumentSuccessful());
        yield put(upsertSupplyChainElementStarted());
    } catch (e) {
        yield put(uploadDoraDocumentFailed((e as Error).message));
    }
}

function* uploadSupplyChainDocumentsWatcher() {
    yield takeLeading(DoraSupplyChainActionTypes.UPLOAD_DORA_DOCUMENT_STARTED, attemptUploadDocuments);
}

export function* attemptUpsertSupplyChainConfiguration() {
    try {
        const supplyChainConfig: SupplyChain | null = yield select(getCurrentSupplyChain);
        if (supplyChainConfig) {
            const supplyChain = supplyChainConfig.supplyChain.filter(({ supplyChainRank }) => supplyChainRank !== 0);
            const doraFunctionCompany = unset('supplyChain', supplyChainConfig);
            yield call(upsertSupplyChainConfiguration, { supplyChain, doraFunctionCompany });
            yield put(upsertSupplyChainConfigurationSuccessful());
        }
    } catch (e) {
        yield put(upsertSupplyChainConfigurationFailed((e as Error).message));
    }
}

function* upsertSupplyChainConfigurationWatcher() {
    yield takeLeading(DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_CONFIGURATION_STARTED, attemptUpsertSupplyChainConfiguration);
}

export function* checkSupplyChainUpdated() {
    const currentSupplyChainConfig: SupplyChain | null = yield select(getCurrentSupplyChain);
    const savedSupplyChainConfig: SupplyChain | null = yield select(getSavedSupplyChain);
    const hasUpdated = !isEqual(savedSupplyChainConfig, currentSupplyChainConfig);
    yield put(setSupplyChainHasUpdated(hasUpdated));
}

function* supplyChainUpdatedWatcher() {
    yield takeEvery([DoraSupplyChainActionTypes.UPDATE_SUPPLY_CHAIN_CONFIGURATION, DoraSupplyChainActionTypes.TOGGLE_SUPPLY_CHAIN_DETAILS_MODAL_OPEN, DoraSupplyChainActionTypes.UPDATE_COMPANY_FUNCTION_DETAILS], checkSupplyChainUpdated);
}

export function* checkSupplyChainElementUpdated() {
    const currentSupplyChainDetails: DoraSupplyChainDetails = yield select(getDetailsInformation);
    const savedSupplyChainDetails: DoraSupplyChainDetails = yield select(getSavedDetailsInformation);
    const currentSupplyChainAssessmentInfo: DoraSupplyChainAssessmentDetails = yield select(getSelectedElementAssessmentInfo);
    const savedSupplyChainAssessmentInfo: DoraSupplyChainAssessmentDetails = yield select(getSavedElementAssessmentInfo);
    const assessmentFiles: File[] = yield select(getAssessmentInfoFiles);
    const detailsUpdated = !isEqual(currentSupplyChainDetails, savedSupplyChainDetails);
    const assessmentUpdated = !isEqual(currentSupplyChainAssessmentInfo, savedSupplyChainAssessmentInfo);
    const hasUpdated = detailsUpdated || assessmentUpdated || assessmentFiles.length > 0;
    yield put(setSupplyChainElementHasUpdated(hasUpdated));
}

function* supplyChainElementUpdatedWatcher() {
    yield debounce(500, [
        DoraSupplyChainActionTypes.UPDATE_DORA_SUPPLY_CHAIN_ELEMENT_DETAILS,
        DoraSupplyChainActionTypes.UPDATE_SUPPLY_CHAIN_ELEMENT_ASSESSMENT,
        DoraSupplyChainActionTypes.CANCEL_CONTRACT_DETAILS_UPDATES,
        DoraSupplyChainActionTypes.CANCEL_ASSESSMENT_DETAILS_UPDATES,
        DoraSupplyChainActionTypes.SET_DORA_DOCUMENT_FILES,
        DoraSupplyChainActionTypes.REMOVE_DORA_DOCUMENT_FILE
    ], checkSupplyChainElementUpdated);
}

export function* navigateBackToSelect() {
    yield put(push('/dora/data-management/supply-chain'));
}

function* navigateBackToSelectWatcher() {
    yield takeEvery(DoraSupplyChainActionTypes.NAVIGATE_BACK_TO_FUNCTION_COMPANY_SELECT, navigateBackToSelect);
}

const getChildren = (id: number, supplyChain: SupplyChainElement[]) => supplyChain.filter(({ parentId }) => parentId === id).map(({ doraSupplyChainId }) => doraSupplyChainId);

const getAllChildren = (id: number, supplyChain: SupplyChainElement[]) => {
    const children = getChildren(id, supplyChain);
    let allChildren = children;
    let currentChildren = children;
    while (currentChildren.length) {
        const children = currentChildren.reduce((acc: number[], cur) => [...acc, ...getChildren(cur, supplyChain)], []);
        currentChildren = children;
        allChildren = [...allChildren, ...children];
    }
    return allChildren;
};

export function* attemptDeleteSupplyChainElement() {
    try {
        const selectedSupplyChainId: number | null = yield select(getSelectedSupplyChainId);
        const supplyChain: SupplyChainElement[] = yield select(getSupplyChainElements);
        if (!isNull(selectedSupplyChainId)) {
            yield call(deleteSupplyChainElement, { doraSupplyChainId: selectedSupplyChainId });
            const children = getAllChildren(selectedSupplyChainId, supplyChain);
            let updatedSupplyChain = supplyChain.filter(({ doraSupplyChainId }) => doraSupplyChainId !== selectedSupplyChainId);
            if (children.length > 0) {
                updatedSupplyChain = updatedSupplyChain.map(element => children.includes(element.doraSupplyChainId) ? { ...element, supplyChainRank: null, parentId: null } : element);
            }
            yield put(deleteSupplyChainElementSuccessful(updatedSupplyChain));
            toast('Successfully deleted company from your supply chain.');
        }
    } catch (e) {
        toast.error('Unable to delete this company from your supply chain. Please try again.');
        yield put(deleteSupplyChainElementFailed((e as Error).message));
    }
}

function* deleteSupplyChainElementWatcher() {
    yield takeLeading(DoraSupplyChainActionTypes.DELETE_SUPPLY_CHAIN_ELEMENT_STARTED, attemptDeleteSupplyChainElement);
}

export function* attemptFetchSupplyChainElementDetails({ payload }: ReturnType<typeof fetchElementDetailsStarted>) {
    try {
        const supplyChainElementDetails: SupplyChainElementDetails = yield call(fetchSupplyChainElementDetails, { doraSupplyChainId: payload });
        yield put(fetchElementDetailsSuccessful(supplyChainElementDetails));
    } catch (e) {
        toast.error('Unable to fetch the supply chain element details. Please try again.');
        yield put(fetchElementDetailsFailed((e as Error).message));
    }
}

function* fetchSupplyChainElementDetailsWatcher() {
    yield takeEvery(DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_STARTED, attemptFetchSupplyChainElementDetails);
}

export function* attemptOpenPreview({ payload }: ReturnType<typeof openDoraDocumentStarted>) {
    try {
        const { doraDocumentId, description } = payload;
        const documentBlob: Blob = yield call(previewDoraDocument, { doraDocumentId });
        const documentUrl: string = yield call(getDocumentUrl, PDF_MIME_TYPE, documentBlob);
        yield put(openDoraDocumentSuccessful({ description, id: doraDocumentId, mimeType: PDF_MIME_TYPE, url: documentUrl }));
    } catch (e) {
        yield put(openDoraDocumentFailed((e as Error).message));
    }
}

function* previewSupplyChainDocumentWatcher() {
    yield takeLeading(DoraSupplyChainActionTypes.OPEN_DORA_DOCUMENT_STARTED, attemptOpenPreview);
}

export function* attemptDownloadDocument({ payload }: ReturnType<typeof downloadDoraDocumentStarted>) {
    try {
        const { doraDocumentId, description } = payload;
        const document: Blob = yield call(downloadDoraDocument, { doraDocumentId });
        const formattedDescription = dotsToDashes(description);
        yield call(download, document, formattedDescription, PDF_MIME_TYPE);
        yield put(downloadDoraDocumentSuccessful());
    } catch (e) {
        yield put(downloadDoraDocumentFailed((e as Error).message));
    }
}

function* downloadSupplyChainDocumentWatcher() {
    yield takeLeading(DoraSupplyChainActionTypes.DOWNLOAD_DORA_DOCUMENT_STARTED, attemptDownloadDocument);
}

export function* attemptLinkSupplyChainToIctContract({ payload }: ReturnType<typeof linkSupplyChainToIctContractStarted>) {
    try {
        let doraSupplyChainContractId = undefined;
        const currentSupplyChainContractInfo: DoraSupplyChainContractualInfo = yield select(getSelectedElementContractualInfo);
        const doraSupplyChainId: number = yield select(getSelectedSupplyChainId);
        if (currentSupplyChainContractInfo) {
            doraSupplyChainContractId = currentSupplyChainContractInfo.doraSupplyChainContractId;
        }

        const elementDetails: SupplyChainElementDetails = yield call(linkSupplyChainElementToIctContract, { originalDocumentId: payload, doraSupplyChainId, doraSupplyChainContractId });
        yield put(linkSupplyChainToIctContractSuccessful(elementDetails));
        if (elementDetails.contractualInfo) {
            const { originalDocumentId, location, mimeType, instanceId } = elementDetails.contractualInfo;
            yield put(openDocumentStarted(location, originalDocumentId, mimeType, instanceId?.toString(), undefined, undefined, true));
        }
        const contractualInfo: DoraSupplyChainContractualInfo | null = yield select(getSelectedElementContractualInfo);
        const assessmentInfo: DoraSupplyChainAssessmentInfo = yield select(getSelectedElementAssessmentInfo);
        const hasContractDetails = !isNull(contractualInfo) && !isUndefined(contractualInfo.originalDocumentId);
        const hasAssessmentDetails = !isEqual(assessmentInfo.details, initialAssessmentDetails);
        yield put(upsertSupplyChainElementSuccessful(hasContractDetails, hasAssessmentDetails));
    }
    catch (e) {
        yield put(linkSupplyChainToIctContractFailed((e as Error).message));
    }
}

function* linkSupplyChainToIctContractWatcher() {
    yield takeLatest(DoraSupplyChainActionTypes.LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_STARTED, attemptLinkSupplyChainToIctContract);
}

export function* openContractOnContractTabSelect({ payload }: ReturnType<typeof setSelectedSupplyChainElementTab>) {
    try {
        if (payload === SupplyChainElementTab.CONTRACT) {
            const contractualInfo: DoraSupplyChainContractualInfo = yield select(getSelectedElementContractualInfo);
            if (!isNull(contractualInfo)) {
                yield put(openDocumentStarted(contractualInfo.location, contractualInfo.originalDocumentId, contractualInfo.mimeType, contractualInfo.instanceId?.toString(), undefined, undefined, true));
            }
        }
    }
    catch (e) {
        toast.error('Unable to fetch ICT contract. Please try again.');
    }
}

function* openContractOnContractTabSelectWatcher() {
    yield takeLatest(DoraSupplyChainActionTypes.SET_SELECTED_SUPPLY_CHAIN_ELEMENT_TAB, openContractOnContractTabSelect);
}

export function* doraSupplyChainSaga() {
    yield all([
        fork(getAllFunctionCompanyInstancesWatcher),
        fork(openExistingSupplyChainElementWatcher),
        fork(fetchSupplyChainWatcher),
        fork(openDoraSupplyChainFromParamsWatcher),
        fork(fetchAllBaseCompaniesWatcher),
        fork(upsertSupplyChainElementWatcher),
        fork(upsertSupplyChainConfigurationWatcher),
        fork(supplyChainUpdatedWatcher),
        fork(navigateBackToSelectWatcher),
        fork(supplyChainElementUpdatedWatcher),
        fork(deleteSupplyChainElementWatcher),
        fork(fetchSupplyChainElementDetailsWatcher),
        fork(uploadSupplyChainDocumentsWatcher),
        fork(previewSupplyChainDocumentWatcher),
        fork(downloadSupplyChainDocumentWatcher),
        fork(linkSupplyChainToIctContractWatcher),
        fork(openContractOnContractTabSelectWatcher)
    ]);
}
