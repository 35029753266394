import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { fetchAllCalendarEventsStarted, getScheduledActionsListView, getWorkflowPage, setWorkflowPage, WorkflowPage } from './store';
import { fetchAllAttestationFormsStarted } from '../attestations/store';
import { WorkflowCalendar } from './calendar/WorkflowCalendar';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { WorkflowList } from './list/WorkflowList';
import { WorkflowSelect } from './WorkflowSelect';
import styles from './Workflow.module.scss';

export interface WorkflowRouteParams {
    scheduledActionId: string | undefined;
}

export const Workflow: React.FC<RouteComponentProps<WorkflowRouteParams>> = (props) => {
    const dispatch = useAppDispatch();
    const scheduledActionsListView = useAppSelector(getScheduledActionsListView);
    useFetchStarted([fetchAllCalendarEventsStarted(scheduledActionsListView), fetchAllAttestationFormsStarted()]);
    const workflowPage = useAppSelector(getWorkflowPage);

    const resetPageView = useCallback(() => dispatch(setWorkflowPage(WorkflowPage.SELECT)), [dispatch]);

    useEffect(() => () => {
        resetPageView();
    }, [resetPageView]);

    const pageContent = useMemo(() => {
        switch (workflowPage) {
            case WorkflowPage.LIST:
                return <WorkflowList {...props} />;
            case WorkflowPage.CALENDAR:
                return <WorkflowCalendar {...props} />;
            case WorkflowPage.SELECT:
            default:
                return <WorkflowSelect />;
        }
    }, [workflowPage, props]);

    return (
        <div className={styles.workflowWrapper} data-testid='workflow-wrapper'>
            {pageContent}
        </div>
    );
};
