import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../../shared/table/ArkTable';
import { clearThirdPartyFunctionCompanyTableFilters, getIsFetchingThirdPartyCoverageTableData, getThirdPartyCoverageColumnSort, getThirdPartyCoverageFilters, getThirdPartyCoveragePageNumber, getThirdPartyCoveragePageSize, getPaginatedThirdPartyFunctionCompanyData, getTotalFunctionCompanies, setThirdPartyFunctionCompanyColumnSort, setThirdPartyFunctionCompanySize, setThirdPartyFunctionCompanyTableFilters, thirdPartyFunctionCompanyPaginationNext, thirdPartyFunctionCompanyPaginationPrevious, ThirdPartyFunctionCompanyCoveragePieCategories } from '../store';
import styles from '../Analytics.module.scss';

interface ThirdPartyFunctionCompanyTableProps {
    tableWidth: number;
}

const { red, green, amber, grey, yellowGreen } = styles;

export const ThirdPartyFunctionCompanyTable: React.FC<ThirdPartyFunctionCompanyTableProps> = ({ tableWidth }) => {
    const dispatch = useAppDispatch();

    const functionCompanyImpactData = useAppSelector(getPaginatedThirdPartyFunctionCompanyData);
    const isLoading = useAppSelector(getIsFetchingThirdPartyCoverageTableData);
    const pageNumber = useAppSelector(getThirdPartyCoveragePageNumber);
    const totalFunctionCompanies = useAppSelector(getTotalFunctionCompanies);
    const pageSize = useAppSelector(getThirdPartyCoveragePageSize);
    const columnSort = useAppSelector(getThirdPartyCoverageColumnSort);
    const functionCompanyFilter = useAppSelector(getThirdPartyCoverageFilters);

    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setThirdPartyFunctionCompanyTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearThirdPartyFunctionCompanyTableFilters()), [dispatch]);
    const nextPage = useCallback(() => dispatch(thirdPartyFunctionCompanyPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(thirdPartyFunctionCompanyPaginationPrevious()), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setThirdPartyFunctionCompanySize(pageSize)), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setThirdPartyFunctionCompanyColumnSort(columnSort)), [dispatch]);

    const exposureFormatter = (pieCategory: ThirdPartyFunctionCompanyCoveragePieCategories) => {
        let color = grey;
        if (pieCategory === ThirdPartyFunctionCompanyCoveragePieCategories.FULL_IMPACT) color = red;
        if (pieCategory === ThirdPartyFunctionCompanyCoveragePieCategories.CRITICAL_NOT_MATERIAL_IMPACT) color = amber;
        if (pieCategory === ThirdPartyFunctionCompanyCoveragePieCategories.NOT_CRITICAL_MATERIAL_IMPACT) color = yellowGreen;
        if (pieCategory === ThirdPartyFunctionCompanyCoveragePieCategories.NOT_CRITICAL_NOT_MATERIAL_IMPACT) color = green;
        return [color];
    };

    const columnDefs: ArkTableColumn[] = [
        { id: 'pieCategory', header: '', field: 'pieCategory', width: 0.05, component: 'colour', valueFormatter: exposureFormatter },
        { id: 'functionName', header: 'Function', field: 'functionName', width: 0.25, component: 'tooltip', canSort: true, canFilter: true },
        { id: 'companyPerformingFunction', header: 'Company', field: 'companyName', width: 0.25, component: 'tooltip', canSort: true, canFilter: true },
        { id: 'functionImpacted', header: 'In Chain?', field: 'functionImpacted', width: 0.1, component: 'tick' },
        { id: 'functionCritical', header: 'Critical?', field: 'isCritical', width: 0.1, component: 'tick' },
        { id: 'impactMaterial', header: 'Material?', field: 'isMaterial', width: 0.1, component: 'tick' },
        { id: 'supplyChainRank', header: 'Ranking', field: 'supplyChainRank', width: 0.145, component: 'tooltip', canSort: true }
    ];

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={functionCompanyImpactData}
            testId='third-party-function-breakdown'
            isLoading={isLoading}
            page={pageNumber}
            total={totalFunctionCompanies}
            next={nextPage}
            previous={previousPage}
            filters={functionCompanyFilter}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            pageSize={pageSize}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            updatePageSize={updatePageSize}
            width={tableWidth}
            height={250}
        />
    );
};
