import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';
import { isEqual, isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getFieldSignOffNotes, getIsFirstTimelineEntry, getOpenFieldsAndSections, toggleOpenFieldSection, updateTableCellValue } from '../store';
import { EligibleCollateral, OpinionDropdownField, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { EligibleCollateralRow } from './EligibleCollateralRow';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { SignOffConversationIcon } from '../sign-off';

interface EligibleCollateralSectionProps {
    eligibleCollateral: EligibleCollateral;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

const selectAllOptions = [{ value: 'Yes', label: 'Yes ' }, { value: 'No', label: 'No ' }];

export const EligibleCollateralSection: React.FC<EligibleCollateralSectionProps> = ({ eligibleCollateral, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const isFirstTimelineEntry = useAppSelector(getIsFirstTimelineEntry);
    const sectionId = 'eligibleCollateral';

    const fieldUpdatedByInstance = useCallback((fieldId: string, index: number) => !!fieldsUpdated.find(field => isEqual({ fieldId: field.fieldId, sectionId: field.sectionId, index: field.index }, { fieldId, sectionId, index })), [fieldsUpdated, sectionId]);
    const getIsDisabled = useCallback((fieldId: string, index: number) => !isEditing && !(isUpdating && fieldUpdatedByInstance(fieldId, index)), [isUpdating, isEditing, fieldUpdatedByInstance]);
    const shouldShowFieldUpdated = useCallback((fieldId: string, index: number) => fieldUpdatedByInstance(fieldId, index) && !isUpdating && !isEditing && !isFirstTimelineEntry, [isUpdating, isEditing, fieldUpdatedByInstance, isFirstTimelineEntry]);

    const disabled = !isEditing && !isUpdating;

    const {
        onShoreIncluded,
        offShoreIncluded,
        cash,
        debtSecuritiesInJurisdiction,
        debtSecuritiesOutsideJurisdiction,
        governmentDebtSecuritiesInJurisdiction,
        governmentDebtSecuritiesOutsideJurisdiction,
        debtSecuritiesMultilateral,
        equitySecuritiesInJurisdiction,
        equitySecuritiesOutsideJurisdiction
    } = eligibleCollateral;

    const checkAllEqualValue = useCallback((checkValue: string, fields: OpinionDropdownField[]) => fields.map(({ value }) => value).every(value => value?.includes(checkValue)), []);
    const allCashSelected = useCallback((checkValue: string) => checkAllEqualValue(checkValue, cash), [cash, checkAllEqualValue]);
    const allDebtSecuritiesInJurisdictionSelected = useCallback((checkValue: string) => checkAllEqualValue(checkValue, debtSecuritiesInJurisdiction), [debtSecuritiesInJurisdiction, checkAllEqualValue]);
    const allDebtSecuritiesOutsideJurisdictionSelected = useCallback((checkValue: string) => checkAllEqualValue(checkValue, debtSecuritiesOutsideJurisdiction), [debtSecuritiesOutsideJurisdiction, checkAllEqualValue]);
    const allGovernmentDebtSecuritiesInJurisdictionSelected = useCallback((checkValue: string) => checkAllEqualValue(checkValue, governmentDebtSecuritiesInJurisdiction), [governmentDebtSecuritiesInJurisdiction, checkAllEqualValue]);
    const allGovernmentDebtSecuritiesOutsideJurisdictionSelected = useCallback((checkValue: string) => checkAllEqualValue(checkValue, governmentDebtSecuritiesOutsideJurisdiction), [governmentDebtSecuritiesOutsideJurisdiction, checkAllEqualValue]);
    const allDebtSecuritiesMultilateralSelected = useCallback((checkValue: string) => checkAllEqualValue(checkValue, debtSecuritiesMultilateral), [debtSecuritiesMultilateral, checkAllEqualValue]);
    const allEquitySecuritiesInJurisdictionSelected = useCallback((checkValue: string) => checkAllEqualValue(checkValue, equitySecuritiesInJurisdiction), [equitySecuritiesInJurisdiction, checkAllEqualValue]);
    const allEquitySecuritiesOutsideJurisdictionSelected = useCallback((checkValue: string) => checkAllEqualValue(checkValue, equitySecuritiesOutsideJurisdiction), [equitySecuritiesOutsideJurisdiction, checkAllEqualValue]);
    const getAllSelected = useCallback((value: string) => [
        allCashSelected(value),
        allDebtSecuritiesInJurisdictionSelected(value),
        allDebtSecuritiesOutsideJurisdictionSelected(value),
        allGovernmentDebtSecuritiesInJurisdictionSelected(value),
        allGovernmentDebtSecuritiesOutsideJurisdictionSelected(value),
        allDebtSecuritiesMultilateralSelected(value),
        allEquitySecuritiesInJurisdictionSelected(value),
        allEquitySecuritiesOutsideJurisdictionSelected(value)
    ].every(val => val), [
        allCashSelected,
        allDebtSecuritiesInJurisdictionSelected,
        allDebtSecuritiesOutsideJurisdictionSelected,
        allGovernmentDebtSecuritiesInJurisdictionSelected,
        allGovernmentDebtSecuritiesOutsideJurisdictionSelected,
        allDebtSecuritiesMultilateralSelected,
        allEquitySecuritiesInJurisdictionSelected,
        allEquitySecuritiesOutsideJurisdictionSelected
    ]);

    const allSelectedYes = useMemo(() => getAllSelected('Yes'), [getAllSelected]);
    const allSelectedNo = useMemo(() => getAllSelected('No'), [getAllSelected]);

    const allSelectedValue = useMemo(() => {
        if (allSelectedYes) {
            return { value: 'Yes', label: 'Yes' };
        }
        if (allSelectedNo) {
            return { value: 'No', label: 'No' };
        }
        return null;
    }, [allSelectedYes, allSelectedNo]);

    const updateCellValue = useCallback((fieldId: string, index: number, value: string[] | null) => { dispatch(updateTableCellValue(sectionId, fieldId, index, value)); }, [dispatch, sectionId]);

    const updateDropdownValue = useCallback((fieldId: string, index: number, option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = [(option as DropdownOption).value];
        }
        updateCellValue(fieldId, index, value);
    }, [updateCellValue]);

    const toggleAllSelected = useCallback((option: DropdownOption | Options<DropdownOption> | null) => [
        'cash',
        'debtSecuritiesInJurisdiction',
        'debtSecuritiesOutsideJurisdiction',
        'governmentDebtSecuritiesInJurisdiction',
        'governmentDebtSecuritiesOutsideJurisdiction',
        'debtSecuritiesMultilateral',
        'equitySecuritiesInJurisdiction',
        'equitySecuritiesOutsideJurisdiction'
    ].forEach(fieldId => {
        (eligibleCollateral[fieldId as keyof EligibleCollateral] as OpinionDropdownField[]).forEach((_, index) => updateDropdownValue(fieldId, index, option));
    }), [updateDropdownValue, eligibleCollateral]);

    const tableHeaders = [
        { label: 'Collateral', width: 'calc(25% - 17px)' },
        { label: 'Cash credited', width: 'calc(15% - 17px)' },
        { label: 'Directly held bearer securities', width: 'calc(15% - 17px)' },
        { label: 'Directly held registered securities', width: 'calc(15% - 17px)' },
        { label: 'Directly held dematerialised securities', width: 'calc(15% - 17px)' },
        { label: 'Intermediated securities', width: 'calc(15% - 16px)' }
    ];

    const signOffConversation = useAppSelector(getFieldSignOffNotes(sectionId, 'eligibleCollateralTable'));

    return (
        <SectionWrapper id={sectionId} label='Eligible Collateral' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.eligibleCollateralWrapper}>
                <SingleField
                    field={onShoreIncluded}
                    fieldId='onShoreIncluded'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={offShoreIncluded}
                    fieldId='offShoreIncluded'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <div className={styles.collateralWrapper}>
                    <div className={styles.allIncludedWrapper}>
                        <div className={styles.allIncludedLabel}>All Collateral Included?</div>
                        <div className={styles.allIncludedDropdown}>
                            <Dropdown value={allSelectedValue} options={selectAllOptions} onChange={toggleAllSelected} disabled={disabled} />
                            {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={`${sectionId}-eligibleCollateralTable`} />}
                        </div>
                    </div>
                    <div className={styles.collateralTableWrapper}>
                        <div className={styles.collateralTableHeader}>
                            {tableHeaders.map(({ label, width }) => (
                                <div key={label} className={styles.collateralHeaderCell} style={{ width }}>{label}</div>
                            ))}
                        </div>
                        <div className={styles.collateralTableBody}>
                            <Scrollable maxHeight='40vh'>
                                <EligibleCollateralRow
                                    fieldId='cash'
                                    collateral='Cash credited to an account'
                                    fields={cash}
                                    getIsDisabled={getIsDisabled}
                                    shouldShowFieldUpdated={shouldShowFieldUpdated}
                                    updateField={updateCellValue}
                                    isCashRow
                                />
                                <EligibleCollateralRow
                                    fieldId='debtSecuritiesInJurisdiction'
                                    collateral='Corporate debt securities issued by an entity organised in the jurisdiction under consideration'
                                    fields={debtSecuritiesInJurisdiction}
                                    getIsDisabled={getIsDisabled}
                                    shouldShowFieldUpdated={shouldShowFieldUpdated}
                                    updateField={updateCellValue}
                                />
                                <EligibleCollateralRow
                                    fieldId='debtSecuritiesOutsideJurisdiction'
                                    collateral='Corporate debt securities issued by an entity organised outside of the jurisdiction under consideration'
                                    fields={debtSecuritiesOutsideJurisdiction}
                                    getIsDisabled={getIsDisabled}
                                    shouldShowFieldUpdated={shouldShowFieldUpdated}
                                    updateField={updateCellValue}
                                />
                                <EligibleCollateralRow
                                    fieldId='governmentDebtSecuritiesInJurisdiction'
                                    collateral='Government debt securities issued by the government of the jurisdiction under consideration'
                                    fields={governmentDebtSecuritiesInJurisdiction}
                                    getIsDisabled={getIsDisabled}
                                    shouldShowFieldUpdated={shouldShowFieldUpdated}
                                    updateField={updateCellValue}
                                />
                                <EligibleCollateralRow
                                    fieldId='governmentDebtSecuritiesOutsideJurisdiction'
                                    collateral='Government debt securities issued by a government outside of the jurisdiction under consideration'
                                    fields={governmentDebtSecuritiesOutsideJurisdiction}
                                    getIsDisabled={getIsDisabled}
                                    shouldShowFieldUpdated={shouldShowFieldUpdated}
                                    updateField={updateCellValue}
                                />
                                <EligibleCollateralRow
                                    fieldId='debtSecuritiesMultilateral'
                                    collateral='Debt securities issued by multilateral development banks and international organisations'
                                    fields={debtSecuritiesMultilateral}
                                    getIsDisabled={getIsDisabled}
                                    shouldShowFieldUpdated={shouldShowFieldUpdated}
                                    updateField={updateCellValue}
                                />
                                <EligibleCollateralRow
                                    fieldId='equitySecuritiesInJurisdiction'
                                    collateral='Equity securities issued by an entity organised in the jurisdiction under consideration'
                                    fields={equitySecuritiesInJurisdiction}
                                    getIsDisabled={getIsDisabled}
                                    shouldShowFieldUpdated={shouldShowFieldUpdated}
                                    updateField={updateCellValue}
                                />
                                <EligibleCollateralRow
                                    fieldId='equitySecuritiesOutsideJurisdiction'
                                    collateral='Equity securities issued by an entity organised outside of the jurisdiction under consideration'
                                    fields={equitySecuritiesOutsideJurisdiction}
                                    getIsDisabled={getIsDisabled}
                                    shouldShowFieldUpdated={shouldShowFieldUpdated}
                                    updateField={updateCellValue}
                                />
                            </Scrollable>
                        </div>
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
};
