import { OpinionScope, OpinionCommissionedBy } from '../../../admin/opinions/store';
import { WorldCountriesList } from '../../../constants/worldCountriesList';
import { AnalysisView } from '../../../documents/my-documents/store';
import { Position } from '../../../shared/modal/PositionModal';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';

export enum MyOpinionActionTypes {
    TOGGLE_OPINION_MODAL = 'TOGGLE_OPINION_MODAL',
    TOGGLE_OPINIONS_VIEW = 'TOGGLE_OPINIONS_VIEW',
    TOGGLE_OPINIONS_TABLE_VIEW = 'TOGGLE_OPINIONS_TABLE_VIEW',
    SET_INITIAL_UPLOAD_OPINIONS = 'SET_INITIAL_UPLOAD_OPINIONS',
    UPLOAD_OPINION_STARTED = 'UPLOAD_OPINION_STARTED',
    UPLOAD_OPINION_SUCCESSFUL = 'UPLOAD_OPINION_SUCCESSFUL',
    UPLOAD_OPINION_FAILED = 'UPLOAD_OPINION_FAILED',
    UPLOAD_SUPPORT_OPINION_STARTED = 'UPLOAD_SUPPORT_OPINION_STARTED',
    UPLOAD_SUPPORT_OPINION_SUCCESSFUL = 'UPLOAD_SUPPORT_OPINION_SUCCESSFUL',
    UPLOAD_SUPPORT_OPINION_FAILED = 'UPLOAD_SUPPORT_OPINION_FAILED',
    TOGGLE_OPINION_DETAILS_MODAL = 'TOGGLE_OPINION_DETAILS_MODAL',
    OPEN_INCOMPLETE_OPINION_STARTED = 'OPEN_INCOMPLETE_OPINION_STARTED',
    OPEN_INCOMPLETE_OPINION_SUCCESSFUL = 'OPEN_INCOMPLETE_OPINION_SUCCESSFUL',
    OPEN_INCOMPLETE_OPINION_FAILED = 'OPEN_INCOMPLETE_OPINION_FAILED',
    INCOMPLETE_OPINION_UPDATE_VALUE = 'INCOMPLETE_OPINION_UPDATE_VALUE',
    INCOMPLETE_OPINION_DETAILS_UPDATED = 'INCOMPLETE_OPINION_DETAILS_UPDATED',
    UPDATE_OPINION_DETAILS_STARTED = 'UPDATE_OPINION_DETAILS_STARTED',
    UPDATE_OPINION_DETAILS_SUCCESSFUL = 'UPDATE_OPINION_DETAILS_SUCCESSFUL',
    UPDATE_OPINION_DETAILS_FAILED = 'UPDATE_OPINION_DETAILS_FAILED',
    SKIP_INCOMPLETE_OPINION = 'SKIP_INCOMPLETE_OPINION',
    FETCH_INCOMPLETE_OPINIONS_STARTED = 'FETCH_INCOMPLETE_OPINIONS_STARTED',
    FETCH_INCOMPLETE_OPINIONS_SUCCESSFUL = 'FETCH_INCOMPLETE_OPINIONS_SUCCESSFUL',
    FETCH_INCOMPLETE_OPINIONS_FAILED = 'FETCH_INCOMPLETE_OPINIONS_FAILED',
    SET_SELECTED_INCOMPLETE_OPINIONS = 'SET_SELECTED_INCOMPLETE_OPINIONS',
    FETCH_OPINIONS_STARTED = 'FETCH_OPINIONS_STARTED',
    FETCH_OPINIONS_SUCCESSFUL = 'FETCH_OPINIONS_SUCCESSFUL',
    FETCH_OPINIONS_FAILED = 'FETCH_OPINIONS_FAILED',
    OPEN_OPINION_STARTED = 'OPEN_OPINION_STARTED',
    OPEN_OPINION_SUCCESSFUL = 'OPEN_OPINION_SUCCESSFUL',
    OPEN_OPINION_FAILED = 'OPEN_OPINION_FAILED',
    OPEN_OPINION_SUMMARY_STARTED = 'OPEN_OPINION_SUMMARY_STARTED',
    OPEN_OPINION_SUMMARY_SUCCESSFUL = 'OPEN_OPINION_SUMMARY_SUCCESSFUL',
    OPEN_OPINION_SUMMARY_FAILED = 'OPEN_OPINION_SUMMARY_FAILED',
    TOGGLE_OPINION_IN_VIEW = 'TOGGLE_OPINION_IN_VIEW',
    OPEN_OPINION_AND_INSTANCE = 'OPEN_OPINION_AND_INSTANCE',
    DOWNLOAD_OPINION_STARTED = 'DOWNLOAD_OPINION_STARTED',
    DOWNLOAD_OPINION_SUCCESSFUL = 'DOWNLOAD_OPINION_SUCCESSFUL',
    DOWNLOAD_OPINION_FAILED = 'DOWNLOAD_OPINION_FAILED',
    DOWNLOAD_ALL_OPINIONS_STARTED = 'DOWNLOAD_ALL_OPINIONS_STARTED',
    DOWNLOAD_ALL_OPINIONS_SUCCESSFUL = 'DOWNLOAD_ALL_OPINIONS_SUCCESSFUL',
    DOWNLOAD_ALL_OPINIONS_FAILED = 'DOWNLOAD_ALL_OPINIONS_FAILED',
    DOWNLOAD_OPINION_SUMMARY_STARTED = 'DOWNLOAD_OPINION_SUMMARY_STARTED',
    DOWNLOAD_OPINION_SUMMARY_SUCCESSFUL = 'DOWNLOAD_OPINION_SUMMARY_SUCCESSFUL',
    DOWNLOAD_OPINION_SUMMARY_FAILED = 'DOWNLOAD_OPINION_SUMMARY_FAILED',
    TOGGLE_DELETE_OPINION_MODAL = 'TOGGLE_DELETE_OPINION_MODAL',
    DELETE_OPINION_STARTED = 'DELETE_OPINION_STARTED',
    DELETE_OPINION_SUCCESSFUL = 'DELETE_OPINION_SUCCESSFUL',
    DELETE_OPINION_FAILED = 'DELETE_OPINION_FAILED',
    RESET_ALL_OPINIONS = 'RESET_ALL_OPINIONS',
    SET_OPINION_TABLE_FILTERS = 'SET_OPINION_TABLE_FILTERS',
    CLEAR_OPINION_TABLE_FILTERS = 'CLEAR_OPINION_TABLE_FILTERS',
    SET_OPINION_TABLE_COLUMN_SORT = 'SET_OPINION_TABLE_COLUMN_SORT',
    OPINIONS_PAGINATION_NEXT = 'OPINIONS_PAGINATION_NEXT',
    OPINIONS_PAGINATION_PREVIOUS = 'OPINIONS_PAGINATION_PREVIOUS',
    SET_OPINIONS_PAGE_SIZE = 'SET_OPINIONS_PAGE_SIZE',
    SET_INCOMPLETE_OPINION_TABLE_FILTERS = 'SET_INCOMPLETE_OPINION_TABLE_FILTERS',
    CLEAR_INCOMPLETE_OPINION_TABLE_FILTERS = 'CLEAR_INCOMPLETE_OPINION_TABLE_FILTERS',
    SET_INCOMPLETE_OPINION_TABLE_COLUMN_SORT = 'SET_INCOMPLETE_OPINION_TABLE_COLUMN_SORT',
    INCOMPLETE_OPINIONS_PAGINATION_NEXT = 'INCOMPLETE_OPINIONS_PAGINATION_NEXT',
    INCOMPLETE_OPINIONS_PAGINATION_PREVIOUS = 'INCOMPLETE_OPINIONS_PAGINATION_PREVIOUS',
    SET_INCOMPLETE_OPINIONS_PAGE_SIZE = 'SET_INCOMPLETE_OPINIONS_PAGE_SIZE',
    TOGGLE_OPINION_ANALYSIS_VIEW = 'TOGGLE_OPINION_ANALYSIS_VIEW',
    SET_LINKED_OPINIONS = 'SET_LINKED_OPINIONS',
    SET_UNAUTHORISED_OPINION = 'SET_UNAUTHORISED_OPINION',
    SET_SELECTED_OPINIONS_JURISDICTION = 'SET_SELECTED_OPINIONS_JURISDICTION',
    FETCH_ALL_OPINIONS_BY_JURISDICTION_STARTED = 'FETCH_ALL_OPINIONS_BY_JURISDICTION_STARTED',
    FETCH_ALL_OPINIONS_BY_JURISDICTION_SUCCESSFUL = 'FETCH_ALL_OPINIONS_BY_JURISDICTION_SUCCESSFUL',
    FETCH_ALL_OPINIONS_BY_JURISDICTION_FAILED = 'FETCH_ALL_OPINIONS_BY_JURISDICTION_FAILED',
    ENQUIRE_OPINION_STARTED = 'ENQUIRE_OPINION_STARTED',
    ENQUIRE_OPINION_SUCCESSFUL = 'ENQUIRE_OPINION_SUCCESSFUL',
    ENQUIRE_OPINION_FAILED = 'ENQUIRE_OPINION_FAILED',
    SET_COUNTRY_OPINIONS = 'SET_COUNTRY_OPINIONS',
    RESET_OPINIONS_MAP_ZOOM = 'RESET_OPINIONS_MAP_ZOOM',
    SET_OPINIONS_MAP_ZOOM_STEPS = 'SET_OPINIONS_MAP_ZOOM_STEPS',
    SET_OPINIONS_MAP_ZOOM_UPDATED = 'SET_OPINIONS_MAP_ZOOM_UPDATED',
    SET_OPINIONS_MAP_CURRENT_SCALE = 'SET_OPINIONS_MAP_CURRENT_SCALE',
    SET_BRITISH_OPINIONS = 'SET_BRITISH_OPINIONS',
    SET_ADDITIONAL_OPINION_SELECTED = 'SET_ADDITIONAL_OPINION_SELECTED',
    OPEN_ADDITIONAL_OPINIONS_STARTED = 'OPEN_ADDITIONAL_OPINIONS_STARTED',
    OPEN_ADDITIONAL_OPINIONS_SUCCESSFUL = 'OPEN_ADDITIONAL_OPINIONS_SUCCESSFUL',
    OPEN_ADDITIONAL_OPINIONS_FAILED = 'OPEN_ADDITIONAL_OPINIONS_FAILED',
    ASSERT_POTENTIAL_BASE_OPINION_STARTED = 'ASSERT_POTENTIAL_BASE_OPINION_STARTED',
    ASSERT_POTENTIAL_BASE_OPINION_SUCCESSFUL = 'ASSERT_POTENTIAL_BASE_OPINION_SUCCESSFUL',
    ASSERT_POTENTIAL_BASE_OPINION_FAILED = 'ASSERT_POTENTIAL_BASE_OPINION_FAILED',
    SET_POTENTIAL_BASE_OPINION = 'SET_POTENTIAL_BASE_OPINION',
    SET_SELECTED_OPINION_PAGE = 'SET_SELECTED_OPINION_PAGE',
    SET_OPINION_SUMMARY_GENERATING = 'SET_OPINION_SUMMARY_GENERATING',
    SET_UPDATED_OPINION_SUMMARY_ID = 'SET_UPDATED_OPINION_SUMMARY_ID',
    TOGGLE_OPINION_SUMMARY_PREVIEW_OPEN = 'TOGGLE_OPINION_SUMMARY_PREVIEW_OPEN',
    TOGGLE_OPINION_CUSTOM_FILTERS_MODAL = 'TOGGLE_OPINION_CUSTOM_FILTERS_MODAL',
    UPDATE_OPINION_CUSTOM_FILTERS = 'UPDATE_OPINION_CUSTOM_FILTERS',
    SET_SELECTED_OPINION_CUSTOM_FILTER_ID = 'SET_SELECTED_OPINION_CUSTOM_FILTER_ID',
    SET_OPINION_CUSTOM_FILTER_HAS_UPDATED = 'SET_OPINION_CUSTOM_FILTER_HAS_UPDATED'
}

export enum OpinionsView {
    TABLE = 'table',
    MAP = 'map'
}

export enum OpinionsTableView {
    COMPLETE = 'complete opinions',
    INCOMPLETE = 'incomplete opinions'
}

export interface ArkOpinion {
    opinionId: number;
    location: string;
    description: string;
    jurisdiction: string | null;
    focus: string;
    bespoke: number;
    dateOfOpinion: string | null;
    scope: OpinionScope | null;
    type: string | null;
    commissionedBy: OpinionCommissionedBy | null;
    commissionedByIfOther: string | null;
    mimeType: string;
    modifiedBy: number;
    modifiedDate: string;
    allOpinionLocations?: string[];
    agreementType: string;
    opinionSummaryId?: number | null;
    approvalDate?: string | null;
    approvedBy?: number | null;
}

export interface LinkedOpinion {
    opinionId: number;
    location: string;
    jurisdiction: string;
    focus: string;
    scope: OpinionScope;
    commissionedBy: OpinionCommissionedBy;
    commissionedByIfOther: string | null;
    bespoke: number;
    permittedToView: boolean;
    agreementType: string;
    mimeType: string;
    approvalDate: string | null;
    approvedBy: number | null;
    instanceComplete?: boolean;
}

export interface ArkMapOpinion {
    opinionId: number;
    location: string;
    description: string;
    jurisdiction: string;
    focus: string;
    bespoke: number;
    dateOfOpinion: string | null;
    scope: OpinionScope;
    type: string | null;
    commissionedBy: OpinionCommissionedBy;
    commissionedByIfOther: string | null;
    allOpinionLocations?: string[];
    permittedToView: boolean;
    mimeType: string;
    agreementType: string;
    approvalDate: string | null;
    approvedBy: number | null;
    instanceComplete?: boolean;
}

export interface OpinionByCountry {
    opinionId: number;
    location: string;
    jurisdiction: string;
    focus: string;
    scope: OpinionScope;
    commissionedBy: OpinionCommissionedBy;
    commissionedByIfOther: string | null;
    bespoke: number;
    permittedToView: boolean;
    agreementType: string;
    mimeType: string;
    approvalDate: string | null;
    approvedBy: number | null;
    instanceComplete?: boolean;
}

export interface CountryOpinions {
    name: string;
    opinions: OpinionByCountry[];
    position: Position;
}

export interface OpinionsJurisdictionList extends WorldCountriesList {
    opinions: OpinionByCountry[];
    numberOfOpinions: number;
    opinionDisabled: boolean;
    published: boolean;
}

export interface BritishOpinions {
    scotland: OpinionByCountry[];
    englandAndWales: OpinionByCountry[];
}

export interface MyOpinionsState {
    opinionsView: OpinionsView;
    opinionsTableView: OpinionsTableView;
    modalOpen: boolean;
    filesToUpload: File[] | null;
    isUploading: boolean;
    selectedIncompleteOpinions: ArkOpinion[];
    uploadError: string | null;
    opinionDetailsModalOpen: boolean;
    isOpening: boolean;
    isLoading: boolean;
    incompleteOpinionUrl: string | null;
    incompleteOpinion: ArkOpinion | null;
    incompleteOpinionError: string | null;
    incompleteOpinionDetailsUpdated: boolean;
    isUpdatingOpinionDetails: boolean;
    incompleteOpinions: ArkOpinion[];
    linkedOpinions: LinkedOpinion[];
    opinions: ArkOpinion[];
    opinionUrl: null | string;
    opinion: ArkOpinion | null;
    additionalOpinions: ArkOpinion[];
    additionalOpinionUrls: string[];
    selectedAdditionalOpinionId: number | null;
    opinionPage: number;
    totalOpinions: number;
    opinionFilters: TableFilters;
    opinionPageSize: number;
    opinionColumnSort: ColumnSort | undefined;
    incompleteOpinionPage: number;
    totalIncompleteOpinions: number;
    incompleteOpinionFilters: TableFilters;
    incompleteOpinionPageSize: number;
    incompleteOpinionColumnSort: ColumnSort | undefined;
    opinionAnalysisView: AnalysisView;
    opinionToDelete: ArkOpinion | null;
    isDeleting: boolean;
    deleteError: string | null;
    unauthorisedOpinion: LinkedOpinion | ArkOpinion | null;
    selectedCountry: string | null;
    mapOpinions: ArkMapOpinion[];
    isEnquiring: boolean;
    countryOpinions: CountryOpinions | null;
    resetOpinionsMapZoom: boolean;
    opinionsMapZoomSteps: number;
    opinionsMapZoomUpdated: boolean;
    opinionsMapCurrentScale: number;
    opinionsMapScaleExtent: [number, number];
    britishOpinions: BritishOpinions | null;
    potentialBaseOpinion: ArkOpinion | null;
    selectedPageNumber: number | null;
    summaryGenerating: boolean;
    isOpeningSummary: boolean;
    opinionSummaryUrl: string | null;
    summaryPreviewModalOpen: boolean;
    isDownloadingSummary: boolean;
    opinionCustomFiltersModalOpen: boolean;
    selectedOpinionCustomFilterId: string | null;
    opinionCustomFilterHasUpdated: boolean;
}
