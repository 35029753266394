import { action } from 'typesafe-actions';

import { ClientUser } from '../../users/store';
import { Client, ClientActionTypes, ClientFeaturePermissionDB, ClientOpinion, ClientSection } from './types';

export const toggleClientsModal = (isOpen: boolean, client?: Client) => action(ClientActionTypes.TOGGLE_CLIENTS_MODAL, { isOpen, client });

export const fetchAllClientsStarted = () => action(ClientActionTypes.FETCH_ALL_CLIENTS_STARTED);
export const fetchAllClientsSuccessful = (clients: Client[]) => action(ClientActionTypes.FETCH_ALL_CLIENTS_SUCCESSFUL, clients);
export const fetchAllClientsFailed = (e: string) => action(ClientActionTypes.FETCH_ALL_CLIENTS_FAILED, e);

export const updateClientDetails = (key: keyof Client, value: string | number[] | ClientOpinion[] | number | null) => action(ClientActionTypes.UPDATE_CLIENT_DETAILS, { key, value });

export const updateClientUsers = (clientId: number, users: ClientUser[]) => action(ClientActionTypes.UPDATE_CLIENT_USERS, { clientId, users });

export const updateClientStarted = () => action(ClientActionTypes.UPDATE_CLIENT_STARTED);
export const updateClientSuccessful = (clients: Client[]) => action(ClientActionTypes.UPDATE_CLIENT_SUCCESSFUL, clients);
export const updateClientFailed = (e: string) => action(ClientActionTypes.UPDATE_CLIENT_FAILED, e);

export const toggleDeleteClientConfirmationModal = (client: Client | null) => action(ClientActionTypes.TOGGLE_DELETE_CLIENT_CONFIRMATION_MODAL, client);

export const deleteClientStarted = (client: Client) => action(ClientActionTypes.DELETE_CLIENT_STARTED, { client });
export const deleteClientSuccessful = (clients: Client[]) => action(ClientActionTypes.DELETE_CLIENT_SUCCESSFUL, clients);
export const deleteClientFailed = (error: string) => action(ClientActionTypes.DELETE_CLIENT_FAILED, error);

export const fetchClientFeaturePermissionsStarted = () => action(ClientActionTypes.FETCH_CLIENT_FEATURE_PERMISSIONS_STARTED);
export const fetchClientFeaturePermissionsSuccessful = (permissions: ClientFeaturePermissionDB[]) => action(ClientActionTypes.FETCH_CLIENT_FEATURE_PERMISSIONS_SUCCESSFUL, permissions);
export const fetchClientFeaturePermissionsFailed = (error: string) => action(ClientActionTypes.FETCH_CLIENT_FEATURE_PERMISSIONS_FAILED, error);

export const resetClientUserPassword = (username: string) => action(ClientActionTypes.RESET_CLIENT_USER_PASSWORD, { username });

export const setOpenClientSections = (section: ClientSection[]) => action(ClientActionTypes.SET_OPEN_CLIENT_SECTIONS, section);
