import React, { useCallback, useMemo } from 'react';

import { ModalHeader } from '../modal/ModalHeader';
import { Form } from '../icons';
import { WizardTab, WizardTabProps } from './WizardTab';
import styles from './Wizard.module.scss';
import { OverflowModalHeader } from '../modal/OverflowModalHeader';
import { ButtonResize } from '../button/ButtonResize';

export interface WizardButton {
    label: string;
    onClick: () => void;
    disabled: boolean;
    disabledTooltip: string[] | null;
    showButton?: boolean;
    displayLeft?: boolean;
    showIconButton?: boolean;
    icon?: React.FC;
}

interface WizardProps {
    buttons: WizardButton[];
    tabs: WizardTabProps[];
    headerIcon?: React.FC;
    title?: string;
    testId?: string;
    children?: React.ReactNode;
    padding?: string;
    overflowHeader?: boolean;
    showHeader?: boolean;
}

export const Wizard: React.FC<WizardProps> = ({
    children,
    buttons,
    tabs,
    headerIcon = Form,
    title = 'Wizard',
    testId,
    padding = '10px',
    overflowHeader = false,
    showHeader = true
}) => {
    const renderWizardButtons = useCallback((buttons: WizardButton[]) => buttons.map(({ label, onClick, disabled, disabledTooltip, showButton = true, showIconButton = false, icon }) => (
        showButton && (<ButtonResize key={label} onClick={onClick} label={label} disabled={disabled} disabledTooltip={disabledTooltip} testId={`${testId}-wizard`} showIconButton={showIconButton} icon={icon} />)
    )), [testId]);

    const leftButtons = useMemo(() => renderWizardButtons(buttons.filter(({ displayLeft }) => displayLeft)), [renderWizardButtons, buttons]);
    const rightButtons = useMemo(() => renderWizardButtons(buttons.filter(({ displayLeft }) => !displayLeft)), [renderWizardButtons, buttons]);

    const header = useMemo(() => {
        if (!showHeader) {
            return null;
        }
        if (overflowHeader) {
            return <OverflowModalHeader icon={headerIcon} label={title} testId={`${testId}-wizard`} />;
        }
        return <ModalHeader icon={headerIcon} label={title} testId={`${testId}-wizard`} />;
    }, [overflowHeader, title, headerIcon, testId, showHeader]);

    return (
        <div className={styles.wrapper} data-testid={`${testId}-wizard-wrapper`} style={{ padding }}>
            {header}
            <div className={styles.tabWrapper}>
                {tabs.map(({ label, onClick, selected, disabled }) => (
                    <WizardTab key={label} label={label} onClick={onClick} selected={selected} disabled={disabled} testId={testId} />
                ))}
            </div>
            {children}
            <div className={styles.buttonWrapper}>
                <div className={styles.leftButtonWrapper}>
                    {leftButtons}
                </div>
                <div className={styles.rightButtonWrapper}>
                    {rightButtons}
                </div>
            </div>
        </div>
    );
};
