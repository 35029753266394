import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual, isNull, isUndefined, noop, sortBy } from 'lodash/fp';
import classnames from 'classnames';

import styles from './Tags.module.scss';
import { MatchingProvisions, PlaybookTag } from '../../../admin/playbook/store';
import { CaretDown, CaretSide, Search } from '../../icons';
import { Scrollable } from '../../scrollable/Scrollable';
import { Icon } from '../../icon/Icon';
import { IconTooltip, InformationTooltip, OverflowTooltip } from '../../tooltip';
import { DeleteButton } from '../../button/DeleteButton';
import { SortOrder } from '../../table/ArkTable';

interface SelectedTag {
    sectionId: string;
    rowIndex: number;
    index: number;
}

interface TagListProps {
    tags: PlaybookTag[];
    getProvisionTitle: (id: string) => string;
    sectionIds: string[];
    canDelete?: boolean;
    removeTag?: (label: string) => void;
    tagsSectionFilter?: string | undefined;
    sortOrder: SortOrder | null;
    testId?: string;
}

export const TagList: React.FC<TagListProps> = ({ tags, sectionIds, canDelete = false, removeTag = noop, tagsSectionFilter, sortOrder, testId = 'playbook-tags-list' }) => {
    const [selectedTagIndex, setSelectedTagIndex] = useState<number | undefined>();

    const [selectedTag, setSelectedTag] = useState<SelectedTag | undefined>();
    const getTotalMatches = useCallback((matchingProvisions: MatchingProvisions) => Object.values(matchingProvisions).reduce((acc, cur) => acc + cur.length, 0), []);

    const getOrderedProvisionTags = useCallback((matchingProvisions: MatchingProvisions): SelectedTag[] => {
        const allProvisionTags = Object.entries(matchingProvisions).reduce((acc: SelectedTag[], [sectionId, matches]) => {
            matches.forEach(({ rowIndex, index }) => acc.push({ sectionId, rowIndex, index }));
            return acc;
        }, []);
        const sortedTags = sectionIds.reduce((acc: SelectedTag[], id) => {
            const sectionTags: SelectedTag[] = allProvisionTags.filter(({ sectionId }) => sectionId === id);
            if (sectionTags.length) {
                const sortedSectionTags = sectionTags.sort((a, b) => a.rowIndex - b.rowIndex || a.index - b.index);
                return [...acc, ...sortedSectionTags];
            }
            return acc;
        }, []);
        return sortedTags;
    }, [sectionIds]);

    const getTagOpen = useCallback((index: number) => selectedTagIndex === index, [selectedTagIndex]);
    const getTagOpenIcon = useCallback((index: number) => getTagOpen(index) ? CaretDown : CaretSide, [getTagOpen]);

    const orderedTags = useMemo(() => {
        const tagList = isUndefined(tagsSectionFilter) ? tags : tags.map(tag => ({ ...tag, matchingProvisions: { [tagsSectionFilter]: tag.matchingProvisions[tagsSectionFilter] } }));
        if (isNull(sortOrder)) {
            return tagList;
        }
        return sortOrder === SortOrder.ASCENDING ? sortBy('label', tagList) : sortBy('label', tagList).reverse();
    }, [tags, sortOrder, tagsSectionFilter]);

    const toggleSection = useCallback((index: number) => {
        if (selectedTagIndex !== index) {
            setSelectedTagIndex(index);
            const firstTag = getOrderedProvisionTags(orderedTags[index].matchingProvisions)[0];
            setSelectedTag(firstTag);
        } else {
            setSelectedTagIndex(undefined);
            setSelectedTag(undefined);
        }
    }, [setSelectedTagIndex, selectedTagIndex, setSelectedTag, getOrderedProvisionTags, orderedTags]);

    const scrollToSection = useCallback((sectionId: string) => {
        const section = document.getElementById(sectionId);
        section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, []);

    useEffect(() => {
        if (!isUndefined(selectedTagIndex) && !isUndefined(selectedTag)) {
            scrollToSection(selectedTag.sectionId);
        }
    }, [selectedTagIndex, selectedTag, scrollToSection]);

    const getIndexOfTag = useCallback((matchingProvisions: MatchingProvisions, index: number) => {
        const tagOpen = getTagOpen(index);
        if (tagOpen) {
            const tags = getOrderedProvisionTags(matchingProvisions).map((tag, index) => {
                if (isEqual(tag, selectedTag)) {
                    getOrderedProvisionTags(matchingProvisions);
                    return index;
                }
                return null;
            });
            const tagIndex = tags.find(val => !isNull(val));
            if (isNull(tagIndex) || isUndefined(tagIndex)) {
                return undefined;
            }
            return tagIndex;
        }
        return undefined;
    }, [getTagOpen, getOrderedProvisionTags, selectedTag]);

    const toggleTag = useCallback((index: number) => {
        if (!isUndefined(selectedTagIndex)) {
            const allProvisionTags = getOrderedProvisionTags(orderedTags[selectedTagIndex].matchingProvisions);
            const tagToSelect = allProvisionTags[index];

            setSelectedTag(tagToSelect);
        }
    }, [selectedTagIndex, getOrderedProvisionTags, orderedTags, setSelectedTag]);

    useEffect(() => {
        if (!isNull(sortOrder)) {
            setSelectedTagIndex(undefined);
            setSelectedTag(undefined);
        }
    }, [sortOrder]);

    return (
        <div className={styles.tagListWrapper} data-testid={`${testId}-wrapper`}>
            <Scrollable>
                {orderedTags.map(({ label, type, matchingProvisions, searchTerm }, index) => {
                    const tagNumber = getIndexOfTag(matchingProvisions, index);
                    const total = getTotalMatches(matchingProvisions);
                    const canPrevious = !isUndefined(tagNumber) && tagNumber > 0;
                    const canNext = !isUndefined(tagNumber) && tagNumber <= total;
                    return (
                        <div className={classnames(styles.tagWrapper, { [styles.selectedTagWrapper]: index === selectedTagIndex })} key={index} data-testid={`${testId}-tag-${index}-wrapper`}>
                            <div className={styles.tagHeader} onClick={() => toggleSection(index)} data-testid={`${testId}-tag-${index}-toggle`}>
                                <div className={styles.sectionOpenIcon}>
                                    <Icon icon={getTagOpenIcon(index)} fontSize={15} />
                                </div>
                                <div className={styles.tagLabel}>
                                    <OverflowTooltip overlayText={label} testId={`${testId}-tag-${index}`} />
                                </div>
                                <div className={styles.iconWrapper}>
                                    {!!type.length && <InformationTooltip content={type} />}
                                    <IconTooltip icon={Search} content={searchTerm} />
                                    <div className={styles.totalMatches} data-testid={`${testId}-tag-${index}-total`}>{total}</div>
                                    {canDelete && <div className={styles.deleteButtonWrapper}>
                                        <DeleteButton onClick={() => removeTag(label)} fontSize={15} testId={`${testId}-tag-${index}-delete`} />
                                    </div>}
                                </div>
                            </div>
                            {getTagOpen(index) && !isUndefined(tagNumber) ? (
                                <div className={styles.tagToggleWrapper} data-testid={`${testId}-open-tag-${index}-toggle-wrapper`}>
                                    <button className={styles.previousButton} data-testid={`${testId}-open-tag-${index}-previous-button`} onClick={() => toggleTag(tagNumber - 1)} disabled={!canPrevious}>{'<'}</button>
                                    <div className={styles.matchingTagCount} data-testid={`${testId}-open-tag-${index}-total`}>{`${tagNumber + 1} / ${total}`}</div>
                                    <button className={styles.nextButton} data-testid={`${testId}-open-tag-${index}-next-button`} onClick={() => toggleTag(tagNumber + 1)} disabled={!canNext}>{'>'}</button>
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </Scrollable>
        </div>
    );
};
