import { isNull, isUndefined } from 'lodash';
import { flatten } from 'lodash/fp';

import { ApplicationState } from '../../../store/rootReducer';
import { DropdownOption } from '../../shared/dropdown/Dropdown';
import { TableFilters } from '../../shared/modal/TableFilterModal';
import { ColumnSort, SortOrder } from '../../shared/table/ArkTable';
import { ArkTableClause, Clause, ClauseLibraryState, ClauseTableTab, ClauseTagDropdownOptions, ClientTag, FuzzyMatchTags, CurrentClientTag, ClientTagColumnSort, ClientTagTableColumns, ClauseType, SelectedSystemTag, LinkedClauseOpinion } from './types';

const getRoot = (state: ApplicationState): ClauseLibraryState => state.clauseLibrary;

export const getCreateClauseModalOpen = (state: ApplicationState): boolean => getRoot(state).clauseModalOpen;

export const getClause = (state: ApplicationState): Clause => getRoot(state).clause;

export const getClauseDescriptionOpen = (state: ApplicationState): boolean => getRoot(state).clauseDescriptionOpen;

export const getCurrentClause = (state: ApplicationState): Clause | null => getRoot(state).currentClause;

export const getTagTerm = (state: ApplicationState): string => getRoot(state).tagTerm;

export const getFuzzyMatchResults = (state: ApplicationState): FuzzyMatchTags[] => getRoot(state).fuzzyMatchResults;

export const getFuzzyMatchModalOpen = (state: ApplicationState): boolean => getRoot(state).fuzzyMatchModalOpen;

export const getClauseLibraryDropdownOptions = (state: ApplicationState): ClauseTagDropdownOptions => {
    const allDropdownOptions = getRoot(state).clauseLibraryDropdownOptions;
    const { systemTemplate } = getClause(state);
    const allClientTags = getAllClientTags(state);
    const tagsModalType = getTagModalType(state);
    const isSystemTagsManager = !isNull(tagsModalType) && tagsModalType === ClauseType.TEMPLATE_CLAUSE;
    const isSystemTemplate = systemTemplate === 1 || isSystemTagsManager;
    const allClientTagLabels = allClientTags.map(({ tag }) => tag);
    const filteredDropdownOptions = Object.keys(allDropdownOptions).reduce((acc, tagKey) => {
        acc[tagKey as keyof ClauseTagDropdownOptions] = acc[tagKey as keyof ClauseTagDropdownOptions].filter(({ label }) => !allClientTagLabels.includes(label));
        return acc;
    }, { ...allDropdownOptions });
    return isSystemTemplate ? filteredDropdownOptions : allDropdownOptions;
};

export const getAllClauseLibraryTags = (state: ApplicationState): string[] => {
    const { agreementTypeOptions, jurisdictionOptions, counterpartyTypeOptions, productTypeOptions, provisionTypeOptions, miscellaneousOptions, opinionsOptions } = getClauseLibraryDropdownOptions(state);
    const getOptionValues = (options: DropdownOption[]) => options.map(({ value }) => value.toLowerCase());
    return [...getOptionValues(agreementTypeOptions), ...getOptionValues(jurisdictionOptions), ...getOptionValues(counterpartyTypeOptions), ...getOptionValues(productTypeOptions), ...getOptionValues(provisionTypeOptions), ...getOptionValues(miscellaneousOptions), ...getOptionValues(opinionsOptions)];
};

export const getClauseCanSave = (state: ApplicationState): boolean => getRoot(state).clauseCanSave;

export const getClauseTableTab = (state: ApplicationState): ClauseTableTab => getRoot(state).clauseTableTab;

export const getAllClientClauses = (state: ApplicationState): Clause[] => getRoot(state).allClientClauses;

export const getAllTemplateClauses = (state: ApplicationState): Clause[] => getRoot(state).allTemplateClauses;

export const getAllHistoricTemplateClauses = (state: ApplicationState): Clause[] => getRoot(state).allHistoricTemplateClauses;

export const getAllMiscellaneousTemplateClauses = (state: ApplicationState): Clause[] => getRoot(state).allMiscellaneousTemplateClauses;

export const getAllArkTableClauses = (state: ApplicationState): ArkTableClause[] => {
    const tableTab = getClauseTableTab(state);
    const allClientClauses = getAllClientClauses(state);
    const allTemplateClauses = getAllTemplateClauses(state);
    const allHistoricClauses = getAllHistoricTemplateClauses(state);
    const allMiscellaneousClauses = getAllMiscellaneousTemplateClauses(state);
    let allClauses = allClientClauses;
    if (tableTab === ClauseTableTab.CURRENT_TEMPLATES) allClauses = allTemplateClauses;
    if (tableTab === ClauseTableTab.HISTORIC_TEMPLATES) allClauses = allHistoricClauses;
    if (tableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE) allClauses = allMiscellaneousClauses;
    const tableClauses: ArkTableClause[] = allClauses.map(({ clauseId, title, description, content, tags, systemTemplate }) => ({ clauseId: clauseId!, clauseTitle: title, clauseDescription: description, content, agreementTags: tags.agreementType, jurisdictionTags: tags.jurisdiction, counterpartyTags: tags.counterpartyType, productTags: tags.productType, provisionTags: tags.provisionType, miscellaneousTags: tags.miscellaneous, opinionsTags: tags.opinions, systemTemplate }));
    return tableClauses;
};

export const getClauseLibraryIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getClauseToDelete = (state: ApplicationState): Clause | null => getRoot(state).clauseToDelete;
export const getDeleteClauseModalOpen = (state: ApplicationState): boolean => !isNull(getClauseToDelete(state));

export const getClauseTagsModalOpen = (state: ApplicationState): boolean => getRoot(state).clauseTagsModalOpen;

export const getAllClientTags = (state: ApplicationState): ClientTag[] => getRoot(state).allClientTags;

export const getTagToDelete = (state: ApplicationState): CurrentClientTag | null => getRoot(state).tagToDelete;
export const getDeleteTagModalOpen = (state: ApplicationState): boolean => !isNull(getTagToDelete(state));

export const getTemplateTagToDelete = (state: ApplicationState): SelectedSystemTag | null => getRoot(state).templateTagToDelete;
export const getDeleteTemplateTagModalOpen = (state: ApplicationState): boolean => !isNull(getTemplateTagToDelete(state));

export const getSelectedClientTag = (state: ApplicationState): CurrentClientTag | null => getRoot(state).selectedClientTag;
export const getCurrentSelectedClientTag = (state: ApplicationState): CurrentClientTag | null => getRoot(state).currentSelectedClientTag;

export const getClientTagModalCanSave = (state: ApplicationState): boolean => getRoot(state).clientTagModalCanSave;

export const getTemplateTagModalCanSave = (state: ApplicationState): boolean => getRoot(state).templateTagModalCanSave;

export const getClientTagColumnSort = (state: ApplicationState): ClientTagColumnSort | undefined => getRoot(state).clientTagColumnSort;
export const getClientTagFilters = (state: ApplicationState): TableFilters => getRoot(state).clientTagFilters;

export const getCurrentClientTags = (state: ApplicationState): CurrentClientTag[] => {
    const getCurrentTags = (clientTag: ClientTag): CurrentClientTag => {
        const clientClauses = getAllClientClauses(state);
        const { tag, clauseTagId, category } = clientTag;
        let clauseIds: number[] = [];
        clientClauses.map(clause => {
            const { tags: { agreementType, jurisdiction, counterpartyType, provisionType, productType, miscellaneous, opinions } } = clause;
            const allTags = flatten([agreementType, jurisdiction, counterpartyType, provisionType, productType, miscellaneous, opinions]);
            if (allTags.includes(tag)) {
                clauseIds = [...clauseIds, clause.clauseId!];
            }
        });
        return { clauseTagId, tag, category, includedClauses: clauseIds, clausesAdded: [], clausesRemoved: [] };
    };
    const clientTags = getAllClientTags(state);
    const currentClientTags = clientTags.map(tag => getCurrentTags(tag));
    return currentClientTags;
};

export const getFilteredClientTags = (state: ApplicationState): CurrentClientTag[] => {
    const currentClientTags = getCurrentClientTags(state);
    const columnSort = getClientTagColumnSort(state);
    const filters = getClientTagFilters(state);
    let sortedClientTags: CurrentClientTag[] = currentClientTags;
    if (!isUndefined(columnSort)) {
        const { column, order } = columnSort;
        switch (column) {
            case ClientTagTableColumns.TAG_CATEGORY: {
                if (order === SortOrder.ASCENDING) {
                    sortedClientTags = currentClientTags.sort((a, b) => a.category.localeCompare(b.category));
                }
                if (order === SortOrder.DESCENDING) {
                    sortedClientTags = currentClientTags.sort((a, b) => b.category.localeCompare(a.category));
                }
            }
                break;
            case ClientTagTableColumns.TAG_CLAUSE_COUNT: {
                if (order === SortOrder.ASCENDING) {
                    sortedClientTags = currentClientTags.sort((a, b) => a.includedClauses.length - b.includedClauses.length);
                }
                if (order === SortOrder.DESCENDING) {
                    sortedClientTags = currentClientTags.sort((a, b) => b.includedClauses.length - a.includedClauses.length);
                }
            }
                break;
            case ClientTagTableColumns.TAG_LABEL: {
                if (order === SortOrder.ASCENDING) {
                    sortedClientTags = currentClientTags.sort((a, b) => a.tag.localeCompare(b.tag));
                }
                if (order === SortOrder.DESCENDING) {
                    sortedClientTags = currentClientTags.sort((a, b) => b.tag.localeCompare(a.tag));
                }
            }
        }
    }

    const { tag, category } = filters;
    let filteredTags = sortedClientTags;
    if (tag && tag.text !== '') {
        filteredTags = filteredTags.filter(clientTag => tag.text.split(' ').some(tagFilter => clientTag.tag.toLowerCase().includes(tagFilter.toLowerCase())));
    }
    if (category && !isNull(category.dropdown)) {
        filteredTags = filteredTags.filter(clientTag => category.dropdown!.includes(clientTag.category));
    }
    return filteredTags;
};

export const getSystemTagCategoryModalOpen = (state: ApplicationState): boolean => getRoot(state).systemTagCategoryModalOpen;

export const getTagModalType = (state: ApplicationState): ClauseType | null => getRoot(state).tagModalType;

export const getSelectedTemplateTag = (state: ApplicationState): SelectedSystemTag | null => getRoot(state).selectedTemplateTag;

export const getCurrentSelectedTemplateTag = (state: ApplicationState): SelectedSystemTag | null => getRoot(state).currentSelectedTemplateTag;

export const getLinkedClauseOpinions = (state: ApplicationState): LinkedClauseOpinion[] => getRoot(state).linkedClauseOpinions;

export const getIsSavingTemplateTag = (state: ApplicationState): boolean => getRoot(state).savingTemplateTag;

// Table Filters and Pagination
export const getClausesPageNumber = (state: ApplicationState): number => {
    const tableTab = getClauseTableTab(state);
    if (tableTab === ClauseTableTab.CURRENT_TEMPLATES) return getRoot(state).templateClausePage;
    if (tableTab === ClauseTableTab.HISTORIC_TEMPLATES) return getRoot(state).historicTemplateClausePage;
    if (tableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE) return getRoot(state).miscellaneousTemplateClausePage;
    return getRoot(state).clientClausePage;
};
export const getTotalClauses = (state: ApplicationState): number => {
    const tableTab = getClauseTableTab(state);
    if (tableTab === ClauseTableTab.CURRENT_TEMPLATES) return getRoot(state).totalTemplateClauses;
    if (tableTab === ClauseTableTab.HISTORIC_TEMPLATES) return getRoot(state).totalHistoricTemplateClauses;
    if (tableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE) return getRoot(state).totalMiscellaneousTemplateClauses;
    return getRoot(state).totalClientClauses;
};
export const getClauseFilters = (state: ApplicationState): TableFilters => {
    const tableTab = getClauseTableTab(state);
    if (tableTab === ClauseTableTab.CURRENT_TEMPLATES) return getRoot(state).templateClauseFilters;
    if (tableTab === ClauseTableTab.HISTORIC_TEMPLATES) return getRoot(state).historicTemplateClauseFilters;
    if (tableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE) return getRoot(state).miscellaneousTemplateClauseFilters;
    return getRoot(state).clientClauseFilters;
};
export const getClauseColumnSort = (state: ApplicationState): ColumnSort | undefined => {
    const tableTab = getClauseTableTab(state);
    if (tableTab === ClauseTableTab.CURRENT_TEMPLATES) return getRoot(state).templateClauseSort;
    if (tableTab === ClauseTableTab.HISTORIC_TEMPLATES) return getRoot(state).historicTemplateClauseSort;
    if (tableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE) return getRoot(state).miscellaneousTemplateClauseSort;
    return getRoot(state).clientClauseSort;
};
export const getClausePageSize = (state: ApplicationState): number => {
    const tableTab = getClauseTableTab(state);
    if (tableTab === ClauseTableTab.CURRENT_TEMPLATES) return getRoot(state).templateClausePageSize;
    if (tableTab === ClauseTableTab.HISTORIC_TEMPLATES) return getRoot(state).historicTemplateClausePageSize;
    if (tableTab === ClauseTableTab.MISCELLANEOUS_TEMPLATE) return getRoot(state).miscellaneousTemplateClausePageSize;
    return getRoot(state).clientClausePageSize;
};
