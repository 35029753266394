import React from 'react';

export const WarningSign: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <g>
            <path fill={color || 'currentColor'} d="M126.23,101.544l-50.9-88.167a13.079,13.079,0,0,0-22.654,0l-50.9,88.167A13.08,13.08,0,0,0,13.1,121.163H114.9a13.079,13.079,0,0,0,11.327-19.619Zm-3.03,11.329a9.482,9.482,0,0,1-8.3,4.79H13.1a9.579,9.579,0,0,1-8.3-14.369L55.7,15.127a9.579,9.579,0,0,1,16.592,0l50.9,88.167A9.479,9.479,0,0,1,123.2,112.873Z"/>
            <path fill={color || 'currentColor'} d="M68.4,17.376a5.079,5.079,0,0,0-8.8,0L8.7,105.545a5.079,5.079,0,0,0,4.4,7.618H114.9a5.08,5.08,0,0,0,4.4-7.619Zm47.873,91.5a1.469,1.469,0,0,1-1.368.79H13.1a1.579,1.579,0,0,1-1.369-2.368l50.9-88.168a1.579,1.579,0,0,1,2.735,0l50.9,88.168A1.468,1.468,0,0,1,116.271,108.873Z"/>
            <path fill={color || 'currentColor'} d="M64.752,38.125h-1.5a6.872,6.872,0,0,0-6.87,7.039l.752,31a6.872,6.872,0,0,0,13.74,0l.752-31a6.872,6.872,0,0,0-6.87-7.04Zm2.62,37.96a3.373,3.373,0,0,1-6.744,0l-.752-31.006a3.374,3.374,0,0,1,3.372-3.454h1.5a3.374,3.374,0,0,1,3.372,3.455Z"/>
            <path fill={color || 'currentColor'} d="M66,87.06H62a4.756,4.756,0,0,0-4.75,4.75v4.315a4.756,4.756,0,0,0,4.75,4.75h4a4.756,4.756,0,0,0,4.75-4.75V91.81A4.756,4.756,0,0,0,66,87.06Zm1.25,9.065A1.251,1.251,0,0,1,66,97.375H62a1.25,1.25,0,0,1-1.25-1.25V91.81A1.251,1.251,0,0,1,62,90.56h4a1.252,1.252,0,0,1,1.25,1.25Z"/>
        </g>
    </svg>
);
