import { findLast, getOr, indexOf, set } from 'lodash/fp';
import { all, call, delay, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';

import { dismissAllNotifications, dismissNotification } from '../../../../services/notifications';
import { extensiveProgressSteps, initialProgressSteps, setProgressString } from '../../../constants/mlProgress';
import {
    dismissAllNotificationsFailed,
    dismissAllNotificationsSuccessful,
    dismissNotificationFailed,
    dismissNotificationStarted,
    dismissNotificationSuccessful,
    removeActiveMLDocument,
    setActiveMLDocuments,
    triggerProgressActiveMLDocuments,
    updatePercentageActiveMLDocument
} from './actions';
import { getActiveMLDocuments } from './selectors';
import { ActiveMLDocument, NotificationActionTypes } from './types';

export function* attemptDismissNotification({ payload }: ReturnType<typeof dismissNotificationStarted>) {
    try {
        yield call(dismissNotification, { notificationId: payload });
        yield put(dismissNotificationSuccessful());
    } catch (e) {
        yield put(dismissNotificationFailed((e as Error).message));
    }
}

function* dismissNotificationWatcher() {
    yield takeLeading(NotificationActionTypes.DISMISS_NOTIFICATION_STARTED, attemptDismissNotification);
}

export function* attemptDismissAllNotifications() {
    try {
        yield call(dismissAllNotifications);
        yield put(dismissAllNotificationsSuccessful());
    } catch (e) {
        yield put(dismissAllNotificationsFailed((e as Error).message));
    }
}

function* dismissAllNotificationsWatcher() {
    yield takeLeading(NotificationActionTypes.DISMISS_ALL_NOTIFICATIONS_STARTED, attemptDismissAllNotifications);
}

export function* attemptUpdateActiveMLDocumentProgress({ payload }: ReturnType<typeof triggerProgressActiveMLDocuments>) {
    const { directFromSocket } = payload;
    let activeMLDocument = payload.activeMLDocument;
    const initialMLDocuments: ActiveMLDocument[] = yield select(getActiveMLDocuments);
    const currentProgress = getOr(1, 'progress', initialMLDocuments.find(({ documentId }) => documentId === activeMLDocument.documentId));
    if (((activeMLDocument.progress > currentProgress) || activeMLDocument.progress === 0) && currentProgress !== 0) {
        if (directFromSocket) {
            activeMLDocument = setProgressString(activeMLDocument);
        }
        yield put(updatePercentageActiveMLDocument(activeMLDocument));
        if ([100, 0].includes(activeMLDocument.progress)) {
            yield delay(5000);
            yield put(removeActiveMLDocument(activeMLDocument.documentId));
        } else {
            const progressSteps = activeMLDocument.initialUpload ? initialProgressSteps : extensiveProgressSteps;
            const updatedMLDocuments: ActiveMLDocument[] = yield select(getActiveMLDocuments);
            const progress = updatedMLDocuments.find(({ documentId }) => documentId === activeMLDocument.documentId)?.progress || 0;
            let baseProgress = findLast(progressStep => progress >= progressStep, progressSteps);
            let indexOfBaseProgress = indexOf(baseProgress, progressSteps);
            let maxProgress = (progressSteps[indexOfBaseProgress + 1] - 1) || 99;
            let shouldIncrementProgress = ![0, maxProgress, 100].includes(progress);
            if (shouldIncrementProgress) {
                const randomIncrement = Math.floor((Math.random() + 1) * 1000);
                yield delay(randomIncrement);
                yield put(triggerProgressActiveMLDocuments(set('progress', progress + 1, activeMLDocument)));
            }
        }
    }
}

function* activeMLDocumentProgressWatcher() {
    yield takeEvery(NotificationActionTypes.TRIGGER_PROGRESS_PERCENTAGE_ACTIVE_ML_DOCUMENT, attemptUpdateActiveMLDocumentProgress);
}

export function* attemptTriggerAllActiveMLDocumentProgress({ payload }: ReturnType<typeof setActiveMLDocuments>) {
    const directFromSocket = true;
    for (const activeMLDocument of payload) {
        yield put(triggerProgressActiveMLDocuments(set('progress', activeMLDocument.progress + 1, activeMLDocument), directFromSocket));
    }
}

function* setAllActiveMLDocumentProgressWatcher() {
    yield takeEvery(NotificationActionTypes.SET_ACTIVE_ML_DOCUMENTS, attemptTriggerAllActiveMLDocumentProgress);
}

export function* notificationSaga() {
    yield all([
        fork(dismissNotificationWatcher),
        fork(dismissAllNotificationsWatcher),
        fork(activeMLDocumentProgressWatcher),
        fork(setAllActiveMLDocumentProgressWatcher)
    ]);
}
