import React, { useMemo } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';

import styles from './ConfigureDatasets.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Spinner } from '../../../shared/spinner/Spinner';
import { TooltipList } from '../../../shared/tooltip';
import { getAllDatasets, getDefinitionsForDataset, getIsSavingHiddenFields, getPreviewDatasetSelectedDocument, setPreviewSelectedDocument } from '../store';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { DocumentDatasetDefinitions } from './DocumentDatasetDefinitions';
import { DocumentDatasetsButtons } from './DocumentDatasetButtons';
import { DocumentDatasetModalDefinition } from './DocumentDatasetModalDefinition';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';

interface AllDatasetDefinitionsProps {
    title: string;
    datasetId: number;
    isPreview?: boolean
}

export const AllDocumentDatasetDefinitions: React.FC<AllDatasetDefinitionsProps> = ({ title, datasetId, isPreview = false }) => {
    const dispatch = useAppDispatch();
    const datasetDefinitions = useAppSelector(getDefinitionsForDataset);
    const allDatasets = useAppSelector(getAllDatasets);
    const previewSelectedDocument = useAppSelector(getPreviewDatasetSelectedDocument);
    const isSavingHiddenField = useAppSelector(getIsSavingHiddenFields);

    const selectedDocumentOption = useMemo(() => {
        if (!isNull(previewSelectedDocument)) {
            const label = allDatasets.find(dataset => dataset.datasetId === datasetId)!.documentNames.find(document => document.documentNameId === previewSelectedDocument)!.documentName;
            return { value: previewSelectedDocument.toString(), label };
        }
        return null;
    }, [allDatasets, datasetId, previewSelectedDocument]);

    const [, screenHeight] = useWindowResize();
    const definitionHeight = useMemo(() => screenHeight - 260, [screenHeight]);

    const parentDefinition = useMemo(() => datasetDefinitions.find(definition => definition.datasetId === datasetId) || null, [datasetDefinitions, datasetId]);
    const agreementType = useMemo(() => allDatasets.find(definition => definition.datasetId === datasetId)?.agreementType || '', [allDatasets, datasetId]);
    const documentNames = useMemo(() => allDatasets.find(definition => definition.datasetId === datasetId)?.documentNames || null, [allDatasets, datasetId]);
    const documentList = documentNames ? documentNames.map(({ documentName }) => documentName) : null;

    const documentOptions = useMemo(() => documentNames ? documentNames.map(({ documentName, documentNameId }) => ({ value: documentNameId.toString(), label: documentName })) : [], [documentNames]);

    const updateDropdown = (option: DropdownOption | Options<DropdownOption> | null) => {
        let selected = null;
        if (!isNull(option)) {
            selected = parseInt((option as DropdownOption).value);
            dispatch(setPreviewSelectedDocument(selected));
        }
    };

    if (isNull(parentDefinition) || isSavingHiddenField) {
        return <Spinner />;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.definitionWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.agreementAndDropdownWrapper}>
                        {isPreview &&
                            <div className={styles.documentNameDropdown}>
                                <Dropdown
                                    testId='admin-document-dataset-definitions'
                                    onChange={updateDropdown}
                                    value={selectedDocumentOption}
                                    options={documentOptions}
                                    isClearable={false}
                                />
                            </div>
                        }
                        <div className={styles.agreementWrapper}>
                            <div className={styles.agreementLabel}>{agreementType}</div>
                            <TooltipList overlayText={documentList} placement='right'>
                                <div className={styles.documentNamesIcon}>i</div>
                            </TooltipList>
                        </div>
                    </div>
                </div>
                <div style={{ height: definitionHeight }}>
                    <DocumentDatasetDefinitions datasetDefinition={parentDefinition!} documentNames={documentOptions} parents={0} isPreview={isPreview} />
                </div>
                <DocumentDatasetsButtons datasetId={datasetId} />
            </div>
            <DocumentDatasetModalDefinition isPreview={isPreview} documentNames={documentOptions} />
        </div>
    );
};
