import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { DocumentCustomFilters, OpinionCustomFilters } from '../../auth/login/store';
import { DropdownOption } from '../../shared/dropdown/Dropdown';
import { DropdownSummary } from '../../shared/dropdown/DropdownSummary';
import { FilterDropdownOptions, TableFilters, TableFilterType } from '../../shared/modal/TableFilterModal';
import { Text } from '../../shared/text/Text';
import styles from '../Home.module.scss';

interface CustomFiltersFilterInputsProps {
    filters: TableFilters;
    filterDropdownOptions: FilterDropdownOptions;
    field: keyof DocumentCustomFilters | keyof OpinionCustomFilters;
    updateFilter: (key: keyof DocumentCustomFilters | keyof OpinionCustomFilters, value: string | string[] | null, type?: keyof TableFilterType) => void;
    testId: string;
    textFilterVisible?: boolean;
}

export const CustomFiltersFilterInputs: React.FC<CustomFiltersFilterInputsProps> = ({
    filters,
    filterDropdownOptions,
    updateFilter,
    field,
    testId,
    textFilterVisible = true,
}) => {
    const customFilterValues = useMemo(() => filters[field] && filters[field].text || '', [filters, field]);
    const dropdownOptions = useMemo(() => filterDropdownOptions[field] || [], [filterDropdownOptions, field]);
    const dropdownFilterValue = useMemo(() => {
        if (dropdownOptions.length && filters[field] && filters[field].dropdown) {
            return dropdownOptions.filter(({ value }) => filters[field].dropdown!.includes(value));
        }
        return null;
    }, [dropdownOptions, field, filters]);

    const updateFilters = useCallback((value: string) => {
        updateFilter(field, value, 'text');
    }, [updateFilter, field]);

    const updateDropdownFilter = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(dropdownValue) && (dropdownValue as Options<DropdownOption>).length > 0) {
            updateFilter(field, (dropdownValue as Options<DropdownOption>).map(({ value }) => value), 'dropdown');
        } else {
            updateFilter(field, null, 'dropdown');
        }
    }, [field, updateFilter]);

    return (
        <div className={styles.customFilterModal}>
            {textFilterVisible &&
                <Text
                    label=''
                    testId={`${testId}-input`}
                    placeholder='Add filter...'
                    value={customFilterValues}
                    maxLength={128}
                    onChange={e => updateFilters(e.target.value)}
                />
            }
            {!!dropdownOptions.length &&
                <div className={styles.dropdownFilterWrapper}>
                    <DropdownSummary
                        value={dropdownFilterValue}
                        onChange={updateDropdownFilter}
                        options={dropdownOptions}
                        testId='table-filter'
                        menuPlacement='bottom'
                        hideIndicator={true}
                        isClearable
                        menuPortalTarget={document.body}
                        isMulti
                    />
                </div>
            }
        </div>
    );
};
