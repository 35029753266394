import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Options } from 'react-select';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { fetchAllDropdownListsStarted } from '../../admin/dropdown-lists/store';
import { Analytics, AnalyticsTile } from '../../shared/analytics/Analytics';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { Spinner } from '../../shared/spinner/Spinner';
import styles from './Analytics.module.scss';
import { SingleDataPointChartWrapper } from './charts/SingleDataPointChartWrapper';
import { OpinionAverageChartWrapper } from './charts/average/OpinionAverageChart';
import { OpinionAnalyticsChart, fetchOpinionsAverageStarted, fetchSingleDataJurisdictionStarted, getOpinionAnalyticsScope, getTileInView, setOpinionAnalyticsScope, setOpinionTileInView } from './store';
import { OpinionScope } from '../../admin/opinions/store';

export interface OpinionAnalyticsRouteParams {
    chart: string | undefined;
}

export const OpinionAnalytics: React.FC<RouteComponentProps<OpinionAnalyticsRouteParams>> = ({ match: { params } }) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [tileDimensions, setTileDimensions] = useState<undefined | { height: number; width: number; }>(undefined);

    const [screenWidth, screenHeight] = useWindowResize();
    const dispatch = useAppDispatch();
    useFetchStarted([fetchSingleDataJurisdictionStarted(), fetchOpinionsAverageStarted(), fetchAllDropdownListsStarted()]);

    const scope = useAppSelector(getOpinionAnalyticsScope);
    const tileInView = useAppSelector(getTileInView);

    const setTileInView = useCallback((chart: OpinionAnalyticsChart | null, shouldRedirect = true) => dispatch(setOpinionTileInView(chart, shouldRedirect)), [dispatch]);

    const scopeOptions = useMemo(() => [
        { value: OpinionScope.ISDA_NETTING, label: 'Netting Opinions (ISDA)' },
        { value: OpinionScope.COLLATERAL_PROVIDER, label: 'Collateral Opinions (Provider Insolvency)' },
        { value: OpinionScope.COLLATERAL_TAKER, label: 'Collateral Opinions (Taker Insolvency)' },
        { value: OpinionScope.GMRA_NETTING, label: 'Netting Opinions (GMRA)' },
        { value: OpinionScope.GMSLA_NETTING, label: 'Netting Opinions (GMSLA)' }
    ], []);

    const scopeValue = useMemo(() => scopeOptions.find(({ value }) => value === scope) || null, [scopeOptions, scope]);

    const setScope = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: OpinionScope | null = null;
        if (!isNull(dropdownValue)) {
            value = (dropdownValue as DropdownOption).value as OpinionScope;
            dispatch(setOpinionAnalyticsScope(value));
        }
    }, [dispatch]);

    const analyticsCharts: AnalyticsTile[] = useMemo(() => [
        {
            id: OpinionAnalyticsChart.OPINIONS_AVERAGE,
            chart: <OpinionAverageChartWrapper dimensions={tileDimensions!} tileInView={tileInView} setTileInView={setTileInView} analyticsSpinner={showSpinner} />,
            column: 1
        },
        {
            id: OpinionAnalyticsChart.SINGLE_DATA_POINT_JURISDICTION,
            chart: <SingleDataPointChartWrapper dimensions={tileDimensions!} tileInView={tileInView} setTileInView={setTileInView} analyticsSpinner={showSpinner} />,
            marginBottom: '20px',
            column: 2
        }
    ], [tileDimensions, tileInView, showSpinner, setTileInView]);

    const tileInViewChart = useMemo(() => analyticsCharts.find(({ id }) => id === tileInView)?.chart || null, [analyticsCharts, tileInView]);

    useEffect(() => {
        setShowSpinner(true);
        if (screenWidth && screenHeight) {
            const width = (screenWidth - 80) / (isNull(tileInView) ? 2 : 1);
            const height = screenHeight - 190;
            setTileDimensions({ height, width });
            setTimeout(() => setShowSpinner(false), 50);
        }
    }, [screenWidth, screenHeight, tileInView, setShowSpinner]);

    const allUrlCharts = useMemo(() => Object.values(OpinionAnalyticsChart).map(val => val.replace(' ', '-')), []);
    const tileIsValidAndNotInView = useCallback((urlChart: string) => {
        const urlChartIsValid = allUrlCharts.includes(urlChart);
        if (urlChartIsValid) {
            const chart = urlChart.replaceAll('-', ' ') as OpinionAnalyticsChart;
            return isNull(tileInView) || tileInView !== chart;
        }
    }, [tileInView, allUrlCharts]);

    useEffect(() => {
        if (params.chart && tileIsValidAndNotInView(params.chart)) {
            const chart = params.chart.replaceAll('-', ' ') as OpinionAnalyticsChart;
            setTileInView(chart, false);
        }
    }, [params, tileIsValidAndNotInView, setTileInView]);

    const dropdownSelect = useMemo(() => (
        <div className={styles.scopeDropdown}>
            <Dropdown
                testId='opinion-analytics-scope'
                onChange={setScope}
                value={scopeValue}
                options={scopeOptions}
                isClearable={false}
            />
        </div>
    ), [setScope, scopeValue, scopeOptions]);

    if (showSpinner) {
        return <Spinner />;
    }

    return (
        <Analytics
            charts={analyticsCharts}
            tileInView={tileInViewChart}
            showSpinner={showSpinner}
            dropdownSelect={dropdownSelect}
            testId='opinion-analytics'
            analyticsColumns={2}
            analyticsRows={1}
        />
    );
};
