import React, { useState } from 'react';
import classnames from 'classnames';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getCalculatorHelpModalOpen, toggleCalculatorHelpModal } from '../../admin/dataset-builder/store';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import styles from './Calculator.module.scss';
import { ModalHeader } from '../modal/ModalHeader';
import { Calculator } from '../icons';
import { Scrollable } from '../scrollable/Scrollable';

interface CalculatorHelpInput {
    label: string;
    explanation: string;
    isSymbol?: boolean;
}

const generalExplanation = [
    'Legal prose is, by its nature, incredibly flexible.  However, there are distinct disadvantages associated with this degree of flexibility.  Specifically, the same underlying concept within a document can be expressed in a wide variety of ways.  This does not assist in the process of analysing and comparing information and risks across a portfolio of ostensibly similar documents.',
    'We have tried to provide an approach which is sufficiently flexible to enable users to capture the essence of the underlying concepts within a document, but which imposes a degree of uniformity so as to enable comparison and risk assessment.',
    'Using the "Calculator Keypads" we have provided, users can select strings of "tokens" which replicate the legal prose found within actual agreements.  We hope that the process is quite intuitive, but have provided some explanations of some of the "tokens" which exist so as to assist general understanding.'
];

const inputHelp: CalculatorHelpInput[] = [
    { label: '<', explanation: 'This logical operator means "less than"', isSymbol: true },
    { label: '>', explanation: 'This logical operator means greater than"', isSymbol: true },
    { label: '<=', explanation: 'This logical operator means "less than or equal to"', isSymbol: true },
    { label: '>=', explanation: 'This logical operator means "greater than or equal to"', isSymbol: true },
    { label: '=', explanation: 'This logical operator means "equal to"', isSymbol: true },
    { label: '<>', explanation: 'This logical operator means "not equal to"', isSymbol: true },
    { label: 'OR', explanation: 'The "OR" conjunction can be used to link two statements together' },
    { label: 'AND', explanation: 'The "AND" conjunction can be used to link two statements together' },
    { label: 'MIN', explanation: 'The "MIN" function is used to express the LESSER of two or more amounts. For example, in the context of a cross-default provision, the lesser of 3% of shareholder\' equity or USD 10,000,000 would be expressed as "MIN (3% shareholders\' equity, USD 10,000,000)"' },
    { label: 'MAX', explanation: 'The "MAX" function is used to express the GREATER of two or more amounts. For example, in the context of a cross-default provision, the lesser of 3% of shareholder\' equity or USD 10,000,000 would be expressed as "MAX (3% shareholders\' equity, USD 10,000,000)"' },
    { label: '(', explanation: 'Use brackets to create "MIN" or "MAX" statements (in other words, statements which represent the "lesser of" or the "greater of" two or more amounts). The amounts should be captured within brackets (and separated by commas) - for example "MAX (3% shareholders\' equity, USD 10,000,000)"', isSymbol: true },
    { label: ')', explanation: 'Use brackets to create "MIN" or "MAX" statements (in other words, statements which represent the "lesser of" or the "greater of" two or more amounts). The amounts should be captured within brackets (and separated by commas) - for example "MAX (3% shareholders\' equity, USD 10,000,000)"', isSymbol: true },
    { label: ',', explanation: 'The "comma" operator is typically used to separate values within brackets when using the "MIN" or "MAX" function. For example, "MAX (3% shareholders\' equity, USD 10,000,000)', isSymbol: true },
    { label: 'NAV', explanation: '"NAV" stands for "Net Asset Value"' },
    { label: 'NAV / Share', explanation: '"NAV / Share" means "Net Asset Value per Share"' },
    { label: 'Ordinal Number', explanation: 'An ordinal number is a number which defines the position of something in a series, such as "first", "second", "third" or "last"' },
    { label: 'Day Type', explanation: 'Use this function to differentiate between different types of day, for "days", "business days", or "local business days"' },
    { label: 'Spread', explanation: 'A "Spread" is a specific amount charged in addition to, or deducted from, a reference interest rate. For example, "SONIA + 0.5%" or "ESTER - 0.2%"' },
    { label: 'Flat', explanation: '"Flat" is a term used in connection with an interest rate.  It is used to indicate that there is NO spread attached to the interest rate in question' },
];

export const CalculatorHelpModal: React.FC = () => {
    const [selectedHelp, setSelectedHelp] = useState<CalculatorHelpInput | null>(null);
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getCalculatorHelpModalOpen);
    const closeModal = () => dispatch(toggleCalculatorHelpModal());

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            closeLabel='Close'
            showConfirm={false}
        >
            <div className={styles.calculatorHelpWrapper}>
                <ModalHeader icon={Calculator} label='Calculator Help' />
                <div className={styles.helpContent}>
                    <div className={styles.generalHelpContent}>
                        {generalExplanation.map((help, index) => (
                            <div key={index} className={styles.generalHelp}>{help}</div>
                        ))}
                    </div>
                    <div className={styles.inputHelpContent}>
                        <div className={styles.inputHelpTitle}>Click one of the following calculator inputs for more information about how it should be used</div>
                        <div className={styles.inputHelpWrapper}>
                            <div className={styles.selectedHelpWrapper}>
                                {!isNull(selectedHelp) &&
                                    <div className={styles.selectedHelp}>
                                        <div className={styles.selectedHelpButton} style={{ fontSize: selectedHelp.isSymbol ? '30px' : '20px' }}>{selectedHelp.label}</div>
                                        <div className={styles.selectedHelpExplanation}>{selectedHelp.explanation}</div>
                                    </div>}
                            </div>
                            <div className={styles.helpKeypad}>
                                <Scrollable>
                                    <div className={styles.inputHelpItems}>
                                        {inputHelp.map(input => (
                                            <button key={input.label} className={classnames(styles.inputHelp, { [styles.selectedInput]: input === selectedHelp })} onClick={() => setSelectedHelp(input)}>
                                                <div className={styles.inputHelpLabel} style={{ fontSize: input.isSymbol ? '20px' : '16px' }}>{input.label}</div>
                                            </button>
                                        ))}
                                    </div>
                                </Scrollable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ ConfirmationModal>
    );
};
