import React, { useCallback } from 'react';
import { isEqual, noop } from 'lodash/fp';

import { OpenSuggestedChange, SuggestedChangesDB } from './store';
import styles from './Playbook.module.scss';
import { SuggestedChangeTile } from './SuggestedChangeTile';
import { ReadOnlyProvision } from '../../shared/playbook/view/ReadOnlyProvision';
import { SectionSuggestedChanges } from '../../playbook/store';
import { Scrollable } from '../../shared/scrollable/Scrollable';

const { lightGrey } = styles;

export interface SectionSuggestedChangeProps {
    suggestedChanges: SuggestedChangesDB[]
    setSuggestedChangeOpen: (playbookSuggestionId: number, sectionId: string) => void;
    openSuggestedChange: OpenSuggestedChange;
    openDeviations?: string[];
    toggleDeviation?: (sectionId: string) => void;
    sectionSuggestedChange: SectionSuggestedChanges;
    testId?: string;
}

export const SectionSuggestedChange: React.FC<SectionSuggestedChangeProps> = ({ suggestedChanges, setSuggestedChangeOpen, openSuggestedChange, openDeviations = [], toggleDeviation = noop, sectionSuggestedChange, testId = 'section-suggested-change' }) => {

    const suggestedChangeOpen = useCallback((playbookSuggestionId: number) => openSuggestedChange && openSuggestedChange.playbookSuggestionId === playbookSuggestionId || false, [openSuggestedChange]);
    const deviationOpen = useCallback((sectionId: string) => !!openDeviations.find(openDeviation => isEqual(openDeviation, sectionId)), [openDeviations]);

    return (
        <div className={styles.sectionSuggestedChangesWrapper} data-testid={`${testId}-wrapper`}>
            <Scrollable>
                <div className={styles.suggestedChangeTilesWrapper} style={{ borderBottom: `1px solid ${lightGrey}`, paddingBottom: '5px' }}>
                    {suggestedChanges.map(suggestedChange => (
                        <SuggestedChangeTile suggestedChange={suggestedChange} openSuggestedChange={openSuggestedChange} key={suggestedChange.playbookSuggestionId} setSuggestedChangeOpen={setSuggestedChangeOpen} suggestedChangeOpen={suggestedChangeOpen(suggestedChange.playbookSuggestionId)} testId={`${testId}-tile`} />
                    ))}
                </div>
            </Scrollable>
            <ReadOnlyProvision
                section={sectionSuggestedChange.content}
                isTableView={false}
                openPlaybookActionModal={noop}
                deviationOpen={deviationOpen(openSuggestedChange.sectionId)}
                toggleDeviation={toggleDeviation}
                showQueries={false}
                suggestedChangeContent={sectionSuggestedChange.changedContent}
                showValidFor
                testId={`${testId}-read-only-provision-0`}
            />
        </div>
    );
};
