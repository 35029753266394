import { flow, isNull, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { DATABASE_DATE_FORMAT, dateNow, formatDate } from '../../../../utils/luxon';
import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';
import { AnalysisView, DocumentsView, MyDocumentActionTypes, MyDocumentsState } from './types';

const initialUploadDocument = {
    description: '',
    file: null,
    executedDate: formatDate(dateNow(), DATABASE_DATE_FORMAT),
    type: undefined,
    originalDocumentId: null,
    amendmentLinkedDocumentIds: null
};

export const INITIAL_STATE: MyDocumentsState = {
    documentsView: DocumentsView.ORIGINAL_DOCUMENTS,
    uploadModalOpen: false,
    isUploading: false,
    filesToUpload: null,
    selectedIncompleteDocuments: [],
    incompleteDocument: null,
    incompleteDocumentUrl: null,
    incompleteDocumentError: null,
    incompleteDocumentDetailsUpdated: false,
    incompleteDocumentMLData: null,
    isUpdatingDocumentDetails: false,
    documentDetailsModalOpen: false,
    isLoading: false,
    isOpening: false,
    availableOriginalDocuments: [],
    originalDocumentToDelete: null,
    originalDocumentPage: 1,
    totalOriginalDocuments: 0,
    originalDocumentFilters: {},
    originalDocumentSort: undefined,
    originalDocumentPageSize: DEFAULT_PAGE_SIZE,
    availableBaseDocuments: [],
    baseDocumentPage: 1,
    totalBaseDocuments: 0,
    baseDocumentFilters: {},
    baseDocumentSort: undefined,
    baseDocumentPageSize: DEFAULT_PAGE_SIZE,
    agreementTypeDocuments: [],
    agreementTypeDocumentPage: 1,
    totalAgreementTypeDocuments: 0,
    agreementTypeDocumentFilters: {},
    agreementTypeDocumentSort: undefined,
    agreementTypeDocumentPageSize: DEFAULT_PAGE_SIZE,
    fetchDocumentsAgreementTypeId: 1,
    analysisUploadDocument: initialUploadDocument,
    originalDocumentUrl: null,
    originalDocument: null,
    secondaryDocumentUrl: null,
    secondaryDocumentAnalyzing: false,
    secondaryDocumentError: null,
    secondaryDocument: null,
    documentInView: null,
    newDocumentId: null,
    originalDocumentsModalOpen: false,
    baseDocumentsModalOpen: false,
    isdaDocumentsModalOpen: false,
    downloadError: null,
    linkDocumentsModalOpen: null,
    incompleteDocuments: [],
    incompleteDocumentPage: 1,
    totalIncompleteDocuments: 0,
    incompleteDocumentFilters: {},
    incompleteDocumentSort: undefined,
    incompleteDocumentPageSize: DEFAULT_PAGE_SIZE,
    analyzeError: null,
    supportDocumentUrl: null,
    supportDocumentError: null,
    supportDocument: null,
    documentAnalysisView: AnalysisView.SPLIT,
    linkedDocuments: null,
    isDeleting: false,
    deleteError: null,
    renameDocument: null,
    documentDescriptionsHaveUpdated: false,
    isRenaming: false,
    renameError: null,
    mlCompletedModalOpen: false,
    isCreatingEntity: false,
    incompleteScheduleStartPage: null,
    incompleteDocumentTotalPage: null,
    documentAnalysisSelectedPage: null,
    documentAnalysisTotalPage: null,
    scheduleStartPlaceholder: null,
    scheduleEndPlaceholder: null,
    cdmJson: null,
    preExecutionDocuments: [],
    preExecutionDocumentPage: 1,
    totalPreExecutionDocuments: 0,
    preExecutionDocumentFilters: {},
    preExecutionDocumentSort: undefined,
    preExecutionDocumentPageSize: DEFAULT_PAGE_SIZE,
    documentCustomFiltersModalOpen: false,
    selectedDocumentCustomFilterId: null,
    documentCustomFilterHasUpdated: false
};

export const myDocumentsReducer: Reducer<MyDocumentsState> = (state = INITIAL_STATE, action): MyDocumentsState => {
    switch (action.type) {
        case MyDocumentActionTypes.UPLOAD_DOCUMENT_MODAL_OPEN: {
            const { value, inAnalysis } = action.payload;
            if (inAnalysis) {
                return flow(
                    set('uploadModalOpen', value),
                    set('analysisUploadDocument', initialUploadDocument),
                    set('filesToUpload', null)
                )(state);
            } else {
                return flow(
                    set('uploadModalOpen', value),
                    set('filesToUpload', null)
                )(state);
            }
        }
        case MyDocumentActionTypes.TOGGLE_DOCUMENTS_VIEW:
            return set('documentsView', action.payload, state);
        case MyDocumentActionTypes.SET_INITIAL_UPLOAD_DOCUMENTS:
            return set('filesToUpload', action.payload, state);
        case MyDocumentActionTypes.UPLOAD_DOCUMENT_STARTED:
            return set('isUploading', true, state);
        case MyDocumentActionTypes.UPLOAD_DOCUMENT_SUCCESSFUL:
            return flow(
                set('isUploading', false),
                set('uploadModalOpen', false),
                set('selectedIncompleteDocuments', action.payload),
                set('filesToUpload', null)
            )(state);
        case MyDocumentActionTypes.UPLOAD_SECONDARY_DOCUMENT_SUCCESSFUL:
            return flow(
                set('isUploading', false),
                set('uploadModalOpen', false),
                set('analysisUploadDocument', initialUploadDocument)
            )(state);
        case MyDocumentActionTypes.UPLOAD_DOCUMENT_FAILED:
            return set('isUploading', false, state);
        case MyDocumentActionTypes.UPLOAD_DOCUMENT_UPDATE_VALUE: {
            const { key, value } = action.payload;
            return set(`analysisUploadDocument[${key}]`, value, state);
        }
        case MyDocumentActionTypes.FETCH_DOCUMENTS_STARTED:
            return flow(
                set('isLoading', true),
                set('fetchDocumentsAgreementTypeId', action.payload.agreementTypeId ? action.payload.agreementTypeId : 1)
            )(state);
        case MyDocumentActionTypes.FETCH_DOCUMENTS_FAILED:
            return set('isLoading', false, state);
        case MyDocumentActionTypes.FETCH_ORIGINAL_DOCUMENTS_SUCCESSFUL:
            return flow(
                set('availableOriginalDocuments', action.payload.documents),
                set('totalOriginalDocuments', action.payload.total || state.totalOriginalDocuments),
                set('originalDocumentPage', action.payload.pageNumber || state.originalDocumentPage),
                set('isLoading', false)
            )(state);
        case MyDocumentActionTypes.FETCH_BASE_DOCUMENTS_SUCCESSFUL:
            return flow(
                set('availableBaseDocuments', action.payload.documents),
                set('totalBaseDocuments', action.payload.total || state.totalBaseDocuments),
                set('baseDocumentPage', action.payload.pageNumber || state.baseDocumentPage),
                set('isLoading', false)
            )(state);
        case MyDocumentActionTypes.FETCH_AGREEMENT_TYPE_DOCUMENTS_SUCCESSFUL:
            return flow(
                set('agreementTypeDocuments', action.payload.documents),
                set('totalAgreementTypeDocuments', action.payload.total || state.totalAgreementTypeDocuments),
                set('agreementTypeDocumentPage', action.payload.pageNumber || state.agreementTypeDocumentPage),
                set('isLoading', false)
            )(state);
        case MyDocumentActionTypes.OPEN_DOCUMENT_STARTED:
        case MyDocumentActionTypes.OPEN_SECONDARY_DOCUMENT_STARTED:
        case MyDocumentActionTypes.OPEN_SUPPORT_DOCUMENT_STARTED:
            return set('isOpening', true, state);
        case MyDocumentActionTypes.OPEN_DOCUMENT_SUCCESSFUL:
            return flow(
                set('originalDocumentUrl', action.payload.documentUrl),
                set('isOpening', false),
                set('originalDocument', action.payload.document)
            )(state);
        case MyDocumentActionTypes.UPDATE_ORIGINAL_DOCUMENT:
            return set('originalDocument', action.payload, state);
        case MyDocumentActionTypes.OPEN_SECONDARY_DOCUMENT_SUCCESSFUL: {
            const { document, documentUrl } = action.payload;
            return flow(
                set('secondaryDocument', document),
                set('newDocumentId', document.documentId),
                set('secondaryDocumentUrl', documentUrl),
                set('isOpening', false),
            )(state);
        }
        case MyDocumentActionTypes.OPEN_SECONDARY_DOCUMENT_FAILED:
            return flow(
                set('secondaryDocumentError', action.payload),
                set('isOpening', false),
            )(state);
        case MyDocumentActionTypes.TOGGLE_DOCUMENT_IN_VIEW:
            return set('documentInView', action.payload, state);
        case MyDocumentActionTypes.SET_SECONDARY_DOCUMENT:
            return set('secondaryDocument', action.payload, state);
        case MyDocumentActionTypes.RESET_DOCUMENT_INSTANCE:
            return {
                ...INITIAL_STATE,
                availableOriginalDocuments: state.availableOriginalDocuments
            };
        case MyDocumentActionTypes.RESET_SECONDARY_DOCUMENT:
            return flow(
                set('secondaryDocumentUrl', null),
                set('secondaryDocument', null),
                set('secondaryDocumentError', null),
            )(state);
        case MyDocumentActionTypes.TOGGLE_ORIGINAL_DOCUMENTS_MODAL:
            return set('originalDocumentsModalOpen', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_BASE_DOCUMENTS_MODAL:
            return set('baseDocumentsModalOpen', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_ISDA_DOCUMENTS_MODAL:
            return set('isdaDocumentsModalOpen', action.payload, state);
        case MyDocumentActionTypes.DOWNLOAD_ALL_DOCUMENTS_STARTED:
            return set('downloadError', null, state);
        case MyDocumentActionTypes.DOWNLOAD_ALL_DOCUMENTS_FAILED:
            return set('downloadError', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_LINKED_DOCUMENTS_MODAL:
            return set('linkDocumentsModalOpen', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_DOCUMENT_DETAILS_MODAL: {
            const selectedIncompleteDocuments = action.payload ? state.selectedIncompleteDocuments : [];
            return flow(
                set('documentDetailsModalOpen', action.payload),
                set('selectedIncompleteDocuments', selectedIncompleteDocuments)
            )(state);
        }
        case MyDocumentActionTypes.OPEN_INCOMPLETE_DOCUMENT_STARTED:
            return flow(
                set('isOpening', true),
                set('incompleteDocumentUrl', null),
                set('incompleteDocument', null),
                set('incompleteDocumentError', null),
                set('incompleteDocumentMLData', null),
                set('scheduleStartPlaceholder', null),
                set('scheduleEndPlaceholder', null)
            )(state);
        case MyDocumentActionTypes.OPEN_INCOMPLETE_DOCUMENT_SUCCESSFUL: {
            const { document, documentUrl, mlData, scheduleStartPage, scheduleEndPage } = action.payload;
            return flow(
                set('isOpening', false),
                set('incompleteDocumentUrl', documentUrl),
                set('incompleteDocument', document),
                set('incompleteDocumentMLData', mlData),
                set('mlCompletedModalOpen', !isNull(mlData)),
                set('scheduleStartPlaceholder', scheduleStartPage),
                set('scheduleEndPlaceholder', scheduleEndPage)
            )(state);
        }
        case MyDocumentActionTypes.OPEN_INCOMPLETE_DOCUMENT_FAILED:
            return flow(
                set('isOpening', false),
                set('incompleteDocumentError', action.payload)
            )(state);
        case MyDocumentActionTypes.SET_SCHEDULE_END_PAGE_PLACEHOLDER:
            return set('scheduleEndPlaceholder', action.payload, state);
        case MyDocumentActionTypes.INCOMPLETE_DOCUMENT_UPDATE_VALUE: {
            const { key, value } = action.payload;
            return set(`incompleteDocument[${key}]`, value, state);
        }
        case MyDocumentActionTypes.INCOMPLETE_DOCUMENT_DETAILS_UPDATED:
            return set('incompleteDocumentDetailsUpdated', action.payload, state);
        case MyDocumentActionTypes.UPDATE_DOCUMENT_DETAILS_STARTED:
            return set('isUpdatingDocumentDetails', true, state);
        case MyDocumentActionTypes.UPDATE_DOCUMENT_DETAILS_SUCCESSFUL:
            return flow(
                set('isUpdatingDocumentDetails', false),
                set('incompleteDocumentDetailsUpdated', false),
                set('selectedIncompleteDocuments', action.payload)
            )(state);
        case MyDocumentActionTypes.UPDATE_DOCUMENT_DETAILS_FAILED:
            return set('isUpdatingDocumentDetails', false, state);
        case MyDocumentActionTypes.REVERT_INCOMPLETE_DOCUMENT: {
            const documentDescription = state.incompleteDocument!.documentDescription;
            const uneditedDocument = state.selectedIncompleteDocuments.find(({ documentId }) => documentId === state.incompleteDocument!.documentId)!;
            return set('incompleteDocument', { ...uneditedDocument, documentDescription }, state);
        }
        case MyDocumentActionTypes.FETCH_INCOMPLETE_DOCUMENTS_STARTED:
            return set('isLoading', true, state);
        case MyDocumentActionTypes.FETCH_INCOMPLETE_DOCUMENTS_FAILED:
            return set('isLoading', false, state);
        case MyDocumentActionTypes.FETCH_INCOMPLETE_DOCUMENTS_SUCCESSFUL:
            return flow(
                set('incompleteDocuments', action.payload.documents),
                set('totalIncompleteDocuments', action.payload.total || state.totalIncompleteDocuments),
                set('incompleteDocumentPage', action.payload.pageNumber || state.incompleteDocumentPage),
                set('isLoading', false)
            )(state);
        case MyDocumentActionTypes.SET_SELECTED_INCOMPLETE_DOCUMENTS:
            return set('selectedIncompleteDocuments', action.payload, state);
        case MyDocumentActionTypes.REANALYZE_DOCUMENT_SUCCESSFUL:
            return set('analyzeError', null, state);
        case MyDocumentActionTypes.REANALYZE_DOCUMENT_FAILED:
            return set('analyzeError', action.payload, state);
        case MyDocumentActionTypes.OPEN_SUPPORT_DOCUMENT_SUCCESSFUL: {
            const { document, documentUrl } = action.payload;
            return flow(
                set('supportDocument', document),
                set('supportDocumentUrl', documentUrl),
                set('isOpening', false)
            )(state);
        }
        case MyDocumentActionTypes.OPEN_SUPPORT_DOCUMENT_FAILED:
            return flow(
                set('supportDocumentError', action.payload),
                set('isOpening', false)
            )(state);
        case MyDocumentActionTypes.RESET_SUPPORT_DOCUMENTS:
            return flow(
                set('supportDocumentUrl', null),
                set('supportDocument', null),
                set('supportDocumentError', null)
            )(state);
        case MyDocumentActionTypes.SET_ORIGINAL_DOCUMENT_TABLE_FILTERS: {
            const isTextFilter = action.payload.type === 'text';
            return flow(
                set(`originalDocumentFilters[${action.payload.key}][${action.payload.type}]`, action.payload.value),
                set(`originalDocumentFilters[${action.payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case MyDocumentActionTypes.CLEAR_ORIGINAL_DOCUMENT_TABLE_FILTERS:
            return flow(
                set('originalDocumentFilters', {}),
                set('selectedDocumentCustomFilterId', action.payload ? null : state.selectedDocumentCustomFilterId),
                set('documentCustomFilterHasUpdated', false)
            )(state);
        case MyDocumentActionTypes.SET_ORIGINAL_DOCUMENT_TABLE_COLUMN_SORT:
            return set('originalDocumentSort', action.payload, state);
        case MyDocumentActionTypes.SET_ORIGINAL_DOCUMENTS_PAGE_SIZE:
            return set('originalDocumentPageSize', action.payload, state);
        case MyDocumentActionTypes.SET_BASE_DOCUMENT_TABLE_FILTERS: {
            const isTextFilter = action.payload.type === 'text';
            return flow(
                set(`baseDocumentFilters[${action.payload.key}][${action.payload.type}]`, action.payload.value),
                set(`baseDocumentFilters[${action.payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case MyDocumentActionTypes.CLEAR_BASE_DOCUMENT_TABLE_FILTERS:
            return set('baseDocumentFilters', {}, state);
        case MyDocumentActionTypes.SET_BASE_DOCUMENT_TABLE_COLUMN_SORT:
            return set('baseDocumentSort', action.payload, state);
        case MyDocumentActionTypes.SET_BASE_DOCUMENTS_PAGE_SIZE:
            return set('baseDocumentPageSize', action.payload, state);
        case MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS: {
            const isTextFilter = action.payload.type === 'text';
            return flow(
                set(`agreementTypeDocumentFilters[${action.payload.key}][${action.payload.type}]`, action.payload.value),
                set(`agreementTypeDocumentFilters[${action.payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case MyDocumentActionTypes.CLEAR_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS:
            return set('agreementTypeDocumentFilters', {}, state);
        case MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENT_TABLE_COLUMN_SORT:
            return set('agreementTypeDocumentSort', action.payload, state);
        case MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENTS_PAGE_SIZE:
            return set('agreementTypeDocumentPageSize', action.payload, state);
        case MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_TABLE_FILTERS: {
            const isTextFilter = action.payload.type === 'text';
            return flow(
                set(`incompleteDocumentFilters[${action.payload.key}][${action.payload.type}]`, action.payload.value),
                set(`incompleteDocumentFilters[${action.payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case MyDocumentActionTypes.CLEAR_INCOMPLETE_DOCUMENT_TABLE_FILTERS:
            return set('incompleteDocumentFilters', {}, state);
        case MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_TABLE_COLUMN_SORT:
            return set('incompleteDocumentSort', action.payload, state);
        case MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENTS_PAGE_SIZE:
            return set('incompleteDocumentPageSize', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_DOCUMENT_ANALYSIS_VIEW:
            return set('documentAnalysisView', action.payload, state);
        case MyDocumentActionTypes.FETCH_LINKED_DOCUMENTS_SUCCESSFUL:
            return set('linkedDocuments', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_DELETE_ORIGINAL_DOCUMENT_MODAL:
            return set('originalDocumentToDelete', action.payload, state);
        case MyDocumentActionTypes.DELETE_DOCUMENT_STARTED:
            return set('isDeleting', true, state);
        case MyDocumentActionTypes.DELETE_DOCUMENT_SUCCESSFUL:
            return flow(
                set('isDeleting', false),
                set('originalDocumentToDelete', null)
            )(state);
        case MyDocumentActionTypes.DELETE_DOCUMENT_FAILED:
            return flow(
                set('isDeleting', false),
                set('deleteError', action.payload)
            )(state);
        case MyDocumentActionTypes.TOGGLE_DOCUMENT_RENAME_MODAL_OPEN:
            return flow(
                set('renameDocument', action.payload),
                set('documentDescriptionsHaveUpdated', false)
            )(state);
        case MyDocumentActionTypes.UPDATE_DOCUMENT_DESCRIPTION: {
            const { documentId, description } = action.payload;
            const renameDocument = state.renameDocument!;
            const allDocuments = renameDocument.allDocuments.map(linkedDocument => linkedDocument.documentId === documentId ? set('documentDescription', description, linkedDocument) : linkedDocument);
            return set('renameDocument.allDocuments', allDocuments, state);
        }
        case MyDocumentActionTypes.RENAME_DOCUMENTS_STARTED:
            return set('isRenaming', true, state);
        case MyDocumentActionTypes.RENAME_DOCUMENTS_SUCCESSFUL:
            return flow(
                set('isRenaming', false),
                set('renameDocument', null),
                set('documentDescriptionsHaveUpdated', false)
            )(state);
        case MyDocumentActionTypes.RENAME_DOCUMENTS_FAILED:
            return flow(
                set('isRenaming', false),
                set('renameError', action.payload)
            )(state);
        case MyDocumentActionTypes.DOCUMENT_DESCRIPTIONS_HAVE_UPDATED:
            return set('documentDescriptionsHaveUpdated', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_ML_COMPLETED_MODAL:
            return set('mlCompletedModalOpen', action.payload, state);
        case MyDocumentActionTypes.CREATE_NEW_ENTITY_FROM_ML_DATA_STARTED:
            return set('isCreatingEntity', true, state);
        case MyDocumentActionTypes.CREATE_NEW_ENTITY_FROM_ML_DATA_SUCCESSFUL: {
            const { document, mlData } = action.payload;
            return flow(
                set('isCreatingEntity', false),
                set('incompleteDocument', document),
                set('incompleteDocumentMLData', mlData)
            )(state);
        }
        case MyDocumentActionTypes.CREATE_NEW_ENTITY_FROM_ML_DATA_FAILED:
            return set('isCreatingEntity', false, state);
        case MyDocumentActionTypes.INITIAL_AI_DOCUMENT_SUCCESSFUL: {
            const { documentId, mlDataVersion } = action.payload;
            const incompleteDocuments = state.incompleteDocuments.map(document => document.documentId === documentId ? set('mlDataVersion', mlDataVersion, document) : document);
            return set('incompleteDocuments', incompleteDocuments, state);
        }
        case MyDocumentActionTypes.RUN_LATEST_ML_QUERIES_SUCCESSFUL: {
            const availableOriginalDocuments = state.availableOriginalDocuments.map(document => action.payload === document.documentId ? set('mlInProgress', true, document) : document);
            return set('availableOriginalDocuments', availableOriginalDocuments, state);
        }
        case MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_SCHEDULE_PAGE:
            return set('incompleteScheduleStartPage', action.payload, state);
        case MyDocumentActionTypes.SET_DOCUMENT_ANALYSIS_SELECTED_PAGE:
            return set('documentAnalysisSelectedPage', action.payload, state);
        case MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_PAGE_TOTAL:
            return set('incompleteDocumentTotalPage', action.payload, state);
        case MyDocumentActionTypes.SET_DOCUMENT_ANALYSIS_PAGE_TOTAL:
            return set('documentAnalysisTotalPage', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_CDM_PREVIEW_MODAL:
            return set('cdmJson', action.payload, state);
        case MyDocumentActionTypes.FETCH_PRE_EXECUTION_DOCUMENTS_STARTED:
            return set('isLoading', true, state);
        case MyDocumentActionTypes.FETCH_PRE_EXECUTION_DOCUMENTS_FAILED:
            return set('isLoading', false, state);
        case MyDocumentActionTypes.FETCH_PRE_EXECUTION_DOCUMENTS_SUCCESSFUL:
            return flow(
                set('preExecutionDocuments', action.payload.documents),
                set('totalPreExecutionDocuments', action.payload.total || state.totalPreExecutionDocuments),
                set('preExecutionDocumentPage', action.payload.pageNumber || state.preExecutionDocumentPage),
                set('isLoading', false)
            )(state);
        case MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS: {
            const isTextFilter = action.payload.type === 'text';
            return flow(
                set(`preExecutionDocumentFilters[${action.payload.key}][${action.payload.type}]`, action.payload.value),
                set(`preExecutionDocumentFilters[${action.payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case MyDocumentActionTypes.CLEAR_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS:
            return set('preExecutionDocumentFilters', {}, state);
        case MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENT_TABLE_COLUMN_SORT:
            return set('preExecutionDocumentSort', action.payload, state);
        case MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENTS_PAGE_SIZE:
            return set('preExecutionDocumentPageSize', action.payload, state);
        case MyDocumentActionTypes.TOGGLE_DOCUMENT_CUSTOM_FILTERS_MODAL:
            return set('documentCustomFiltersModalOpen', action.payload, state);
        case MyDocumentActionTypes.SET_SELECTED_DOCUMENT_CUSTOM_FILTER_ID:
            return set('selectedDocumentCustomFilterId', action.payload, state);
        case MyDocumentActionTypes.SET_DOCUMENT_CUSTOM_FILTER_HAS_UPDATED:
            return set('documentCustomFilterHasUpdated', action.payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
