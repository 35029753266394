import { flow, indexOf, isNull, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes, UserDefaults } from '../../auth/login/store';
import { OpinionsView } from '../../opinions/my-opinions/store';
import { HomePageTile, HomepageAnalyticsActionTypes, HomepageState, MyProfileSection } from './types';

const initialDefaults: UserDefaults = {
    opinionPageView: OpinionsView.MAP,
    showTicker: true,
    showIntercom: true
};

export const INITIAL_STATE: HomepageState = {
    homepageFetchingAnalytics: [],
    documentSpread: [],
    entitySpread: [],
    myAttestationProgress: [],
    analyticsError: null,
    homeScreenConfigModalOpen: false,
    homeScreenConfig: null,
    currentHomeScreenConfig: null,
    unauthorisedModule: null,
    unauthorisedModuleModalOpen: false,
    isEnquiring: false,
    enquiryError: null,
    userNotPermittedModalOpen: false,
    newCustomFilter: null,
    addNewFilterModalOpen: false,
    savedUserCustomFilters: [],
    currentUserCustomFilters: [],
    filterTileModalOpen: null,
    isSavingCustomFilters: false,
    customFiltersUpdated: false,
    onCloseConfirmationDialog: false,
    myProfileSection: MyProfileSection.GENERAL,
    selectedCustomFilter: null,
    savedUserDefaults: initialDefaults,
    currentUserDefaults: initialDefaults,
    unsavedProfileChangesModalOpen: false
};

const addAnalyticsToFetchingArray = (state: HomepageState, chart: HomePageTile) => [...state.homepageFetchingAnalytics, chart];
const removeAnalyticsFromFetchingArray = (state: HomepageState, chart: HomePageTile) => state.homepageFetchingAnalytics.filter(analytics => analytics !== chart);

export const homepageReducer: Reducer<HomepageState> = (state = INITIAL_STATE, { payload, type }): HomepageState => {
    switch (type) {
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_STARTED:
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_STARTED:
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_STARTED:
            return set('homepageFetchingAnalytics', addAnalyticsToFetchingArray(state, payload), state);
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_SUCCESSFUL:
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_SUCCESSFUL:
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_SUCCESSFUL: {
            const { key, chart, analytics } = payload;
            return flow(
                set(`${key}`, analytics),
                set('homepageFetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_FAILED:
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_FAILED:
        case HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_FAILED: {
            const { error, chart } = payload;
            return flow(
                set('analyticsError', error),
                set('homepageFetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case HomepageAnalyticsActionTypes.SET_HOME_SCREEN_CONFIG:
            return flow(
                set('currentHomeScreenConfig', payload),
                set('homeScreenConfig', payload)
            )(state);
        case HomepageAnalyticsActionTypes.RESET_HOME_SCREEN_CONFIG:
            return set('homeScreenConfig', state.currentHomeScreenConfig, state);
        case HomepageAnalyticsActionTypes.UPDATE_HOME_SCREEN_CONFIG:
            return set('homeScreenConfig', payload, state);
        case HomepageAnalyticsActionTypes.TOGGLE_UNAUTHORISED_MODULE_MODAL_OPEN: {
            if (!payload) {
                return flow(
                    set('unauthorisedModuleModalOpen', payload),
                    set('unauthorisedModule', null)
                )(state);
            }
            return set('unauthorisedModuleModalOpen', payload, state);
        }
        case HomepageAnalyticsActionTypes.SET_UNAUTHORISED_MODULE:
            return set('unauthorisedModule', payload, state);
        case HomepageAnalyticsActionTypes.ENQUIRE_MODULE_STARTED:
            return set('isEnquiring', true, state);
        case HomepageAnalyticsActionTypes.ENQUIRE_MODULE_SUCCESSFUL:
            return flow(
                set('isEnquiring', false),
                set('unauthorisedModule', null),
                set('unauthorisedModuleModalOpen', false)
            )(state);
        case HomepageAnalyticsActionTypes.ENQUIRE_MODULE_FAILED:
            return flow(
                set('isEnquiring', false),
                set('enquiryError', payload)
            )(state);
        case HomepageAnalyticsActionTypes.TOGGLE_USER_NOT_PERMITTED_MODAL_OPEN:
            return set('userNotPermittedModalOpen', payload, state);
        case HomepageAnalyticsActionTypes.TOGGLE_ADD_NEW_FILTER_MODAL:
            return set('addNewFilterModalOpen', payload, state);
        case HomepageAnalyticsActionTypes.ADD_NEW_CUSTOM_FILTER: {
            const { key, value } = payload;
            return set(`newCustomFilter[${key}]`, value, state);
        }
        case HomepageAnalyticsActionTypes.RESET_ADD_NEW_FILTER_MODAL:
            return set('newCustomFilter', null, state);
        case HomepageAnalyticsActionTypes.SET_USER_CUSTOM_FILTERS: {
            return flow(
                set('savedUserCustomFilters', payload),
                set('currentUserCustomFilters', payload)
            )(state);
        }
        case HomepageAnalyticsActionTypes.ADD_OR_DELETE_USER_CUSTOM_FILTER:
            return set('currentUserCustomFilters', payload, state);
        case HomepageAnalyticsActionTypes.TOGGLE_FILTER_TILE_MODAL:
            return set('filterTileModalOpen', payload, state);
        case HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTER_VALUES: {
            const { filterId, key, type, value } = payload;
            const index = indexOf(filterId, state.currentUserCustomFilters.map(({ id }) => id));
            const isTextFilter = type === 'text';
            if (key === 'filterName') {
                return set(`currentUserCustomFilters[${index}][${key}]`, value, state);
            }
            return flow(
                set(`currentUserCustomFilters[${index}][${key}][${type}]`, value),
                set(`currentUserCustomFilters[${index}][${key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTERS_STARTED:
            return set('isSavingCustomFilters', true, state);
        case HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTERS_SUCCESSFUL: {
            return flow(
                set('savedUserCustomFilters', payload),
                set('currentUserCustomFilters', payload),
                set('isSavingCustomFilters', false),
                set('customFiltersUpdated', false),
                set('onCloseConfirmationDialog', false)
            )(state);
        }
        case HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTERS_FAILED:
            return set('isSavingCustomFilters', false, state);
        case HomepageAnalyticsActionTypes.CUSTOM_FILTERS_UPDATED:
            return set('customFiltersUpdated', payload, state);
        case HomepageAnalyticsActionTypes.RESET_CUSTOM_FILTERS_SECTION: {
            return flow(
                set('isSavingCustomFilters', false),
                set('savedUserCustomFilters', state.savedUserCustomFilters),
                set('currentUserCustomFilters', state.savedUserCustomFilters),
                set('customFiltersUpdated', false),
                set('onCloseConfirmationDialog', false),
                set('selectedCustomFilter', null)
            )(state);
        }
        case HomepageAnalyticsActionTypes.SET_MY_PROFILE_SECTION:
            return flow(
                set('myProfileSection', payload),
                set('selectedCustomFilter', null),
            )(state);
        case HomepageAnalyticsActionTypes.SET_SELECTED_CUSTOM_FILTER: {
            let value = payload;
            if (payload === state.selectedCustomFilter) value = null;
            return set('selectedCustomFilter', value, state);
        }
        case HomepageAnalyticsActionTypes.SET_USER_DEFAULTS: {
            if (!isNull(payload)) {
                return flow(
                    set('savedUserDefaults', payload),
                    set('currentUserDefaults', payload),
                )(state);
            }
            return state;
        }
        case HomepageAnalyticsActionTypes.UPDATE_USER_DEFAULTS: {
            const { key, value } = payload;
            return set(`currentUserDefaults[${key}]`, value, state);
        }
        case HomepageAnalyticsActionTypes.RESET_USER_DEFAULTS: {
            return set('currentUserDefaults', state.savedUserDefaults, state);
        }
        case HomepageAnalyticsActionTypes.SET_UNSAVED_PROFILE_CHANGES_MODAL_OPEN:
            return set('unsavedProfileChangesModalOpen', !state.unsavedProfileChangesModalOpen, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
