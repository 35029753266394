import React from 'react';

import styles from './Clients.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getModalOpen, getSelectedClient } from './store/selectors';
import { toggleClientsModal } from './store';
import { ClientModal } from './client-modal/ClientModal';
import { ClientsTable } from './ClientsTable';
import { PlusButton } from '../../shared/button/PlusButton';

export const Clients: React.FC = () => {
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector(getModalOpen);
    const selectedClient = useAppSelector(getSelectedClient);
    const toggleModal = (isOpen: boolean) => dispatch(toggleClientsModal(isOpen));
    const closeModal = () => toggleModal(false);
    const openModal = () => toggleModal(true);

    return (
        <div className={styles.clientsWrapper} data-testid='clients-wrapper'>
            <div className={styles.clientsTitleContainer}>
                <div className={styles.clientsTitle} data-testid='clients-title'>Client</div>
                <PlusButton onClick={openModal} fontSize={22} testId='clients-create-button' />
                {selectedClient && <ClientModal isOpen={modalOpen} closeModal={closeModal} client={selectedClient} />}
            </div>
            <ClientsTable />
        </div>
    );
};
