import { action } from 'typesafe-actions';

import { MLData } from '../../../datasets/store/mlTypes';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import {
    AnalysisView,
    ArkDocument,
    DocumentUploadValue,
    DocumentsView,
    LinkedDocument,
    MyDocumentActionTypes,
    SecondaryUploadDocument,
    SelectedDocument
} from './types';

export const toggleDocumentsView = (view: DocumentsView) => action(MyDocumentActionTypes.TOGGLE_DOCUMENTS_VIEW, view);

export const toggleUploadModal = (value: boolean, inAnalysis = false) => action(MyDocumentActionTypes.UPLOAD_DOCUMENT_MODAL_OPEN, { value, inAnalysis });

export const toggleMLCompletedModal = (value: boolean) => action(MyDocumentActionTypes.TOGGLE_ML_COMPLETED_MODAL, value);

export const toggleRenameDocumentModal = (document: ArkDocument | null) => action(MyDocumentActionTypes.TOGGLE_DOCUMENT_RENAME_MODAL_OPEN, document);

export const setInitialUploadDocuments = (files: File[]) => action(MyDocumentActionTypes.SET_INITIAL_UPLOAD_DOCUMENTS, files);

export const uploadDocumentStarted = (inAnalysis = false, completeWithAI = false, analyseLater = false) => action(MyDocumentActionTypes.UPLOAD_DOCUMENT_STARTED, { inAnalysis, completeWithAI, analyseLater });
export const uploadDocumentSuccessful = (documents: ArkDocument[]) => action(MyDocumentActionTypes.UPLOAD_DOCUMENT_SUCCESSFUL, documents);
export const uploadDocumentFailed = (error: string) => action(MyDocumentActionTypes.UPLOAD_DOCUMENT_FAILED, error);

export const openIncompleteDocumentStarted = (document: ArkDocument) => action(MyDocumentActionTypes.OPEN_INCOMPLETE_DOCUMENT_STARTED, document);
export const openIncompleteDocumentSuccessful = (documentUrl: string, document: ArkDocument, mlData: MLData | null, scheduleStartPage: number, scheduleEndPage: number | null) => action(MyDocumentActionTypes.OPEN_INCOMPLETE_DOCUMENT_SUCCESSFUL, { document, documentUrl, mlData, scheduleStartPage, scheduleEndPage });
export const openIncompleteDocumentFailed = (error: string) => action(MyDocumentActionTypes.OPEN_INCOMPLETE_DOCUMENT_FAILED, error);

export const uploadSecondaryDocumentSuccessful = () => action(MyDocumentActionTypes.UPLOAD_SECONDARY_DOCUMENT_SUCCESSFUL);

export const fetchDocumentsStarted = (pageNumber = 1, agreementTypeId?: number, baseDocument?: boolean) => action(MyDocumentActionTypes.FETCH_DOCUMENTS_STARTED, { pageNumber, agreementTypeId, baseDocument });
export const fetchOriginalDocumentsSuccessful = (documents: ArkDocument[], total?: number, pageNumber?: number) => action(MyDocumentActionTypes.FETCH_ORIGINAL_DOCUMENTS_SUCCESSFUL, { documents, total, pageNumber });
export const fetchAgreementTypeDocumentsSuccessful = (documents: ArkDocument[], total?: number, pageNumber?: number) => action(MyDocumentActionTypes.FETCH_AGREEMENT_TYPE_DOCUMENTS_SUCCESSFUL, { documents, total, pageNumber });
export const fetchBaseDocumentsSuccessful = (documents: ArkDocument[], total?: number, pageNumber?: number) => action(MyDocumentActionTypes.FETCH_BASE_DOCUMENTS_SUCCESSFUL, { documents, total, pageNumber });
export const fetchDocumentsFailed = (error: string) => action(MyDocumentActionTypes.FETCH_DOCUMENTS_FAILED, error);

export const updateOriginalDocument = (document: ArkDocument) => action(MyDocumentActionTypes.UPDATE_ORIGINAL_DOCUMENT, document);
export const openDocumentStarted = (location: string, documentId: number, mimeType: string, instanceId?: string, executedDate?: string, isLegacy?: boolean, preventRedirect = false) => action(MyDocumentActionTypes.OPEN_DOCUMENT_STARTED, { location, documentId, mimeType, instanceId, executedDate, isLegacy, preventRedirect });
export const openDocumentSuccessful = (documentUrl: string, document: ArkDocument) => action(MyDocumentActionTypes.OPEN_DOCUMENT_SUCCESSFUL, { documentUrl, document });
export const openDocumentFailed = (error: string, location: string) => action(MyDocumentActionTypes.OPEN_DOCUMENT_FAILED, { error, location });

export const downloadDocumentStarted = (location: string, mimeType: string, documentDescription: string) => action(MyDocumentActionTypes.DOWNLOAD_DOCUMENT_STARTED, { location, mimeType, documentDescription });
export const downloadDocumentSuccessful = () => action(MyDocumentActionTypes.DOWNLOAD_DOCUMENT_SUCCESSFUL);
export const downloadDocumentFailed = (error: string) => action(MyDocumentActionTypes.DOWNLOAD_DOCUMENT_FAILED, error);

export const uploadDocumentUpdateValue = (key: keyof SecondaryUploadDocument, value: DocumentUploadValue) => action(MyDocumentActionTypes.UPLOAD_DOCUMENT_UPDATE_VALUE, { key, value });

export const incompleteDocumentUpdateValue = (key: keyof ArkDocument, value: DocumentUploadValue) => action(MyDocumentActionTypes.INCOMPLETE_DOCUMENT_UPDATE_VALUE, { key, value });

export const toggleDeleteConfirmationModal = (documentToDelete: ArkDocument | null) => action(MyDocumentActionTypes.TOGGLE_DELETE_ORIGINAL_DOCUMENT_MODAL, documentToDelete);
export const deleteDocumentStarted = (location: string) => action(MyDocumentActionTypes.DELETE_DOCUMENT_STARTED, location);
export const deleteDocumentSuccessful = () => action(MyDocumentActionTypes.DELETE_DOCUMENT_SUCCESSFUL);
export const deleteDocumentFailed = (error: string) => action(MyDocumentActionTypes.DELETE_DOCUMENT_FAILED, error);

export const openSecondaryDocumentStarted = (secondaryDocument: ArkDocument) => action(MyDocumentActionTypes.OPEN_SECONDARY_DOCUMENT_STARTED, secondaryDocument);
export const openSecondaryDocumentSuccessful = (documentUrl: string, document: ArkDocument) => action(MyDocumentActionTypes.OPEN_SECONDARY_DOCUMENT_SUCCESSFUL, { documentUrl, document });
export const openSecondaryDocumentFailed = (error: string) => action(MyDocumentActionTypes.OPEN_SECONDARY_DOCUMENT_FAILED, error);

export const toggleDocumentInView = (selectedDocument: SelectedDocument | null) => action(MyDocumentActionTypes.TOGGLE_DOCUMENT_IN_VIEW, selectedDocument);
export const setSecondaryDocument = (secondaryDocument: ArkDocument | null) => action(MyDocumentActionTypes.SET_SECONDARY_DOCUMENT, secondaryDocument);

export const resetDocumentInstance = () => action(MyDocumentActionTypes.RESET_DOCUMENT_INSTANCE);
export const resetSecondaryDocument = () => action(MyDocumentActionTypes.RESET_SECONDARY_DOCUMENT);
export const resetSupportDocuments = () => action(MyDocumentActionTypes.RESET_SUPPORT_DOCUMENTS);

export const openDocumentAndInstance = (documentId: string, datasetInstanceId: string | undefined, showLegacy?: boolean) => action(MyDocumentActionTypes.OPEN_DOCUMENT_AND_INSTANCE, { documentId, datasetInstanceId, showLegacy });

export const toggleOriginalDocumentsModal = (value: boolean) => action(MyDocumentActionTypes.TOGGLE_ORIGINAL_DOCUMENTS_MODAL, value);

export const toggleBaseDocumentsModal = (value: boolean) => action(MyDocumentActionTypes.TOGGLE_BASE_DOCUMENTS_MODAL, value);

export const toggleIsdaDocumentsModal = (value: boolean) => action(MyDocumentActionTypes.TOGGLE_ISDA_DOCUMENTS_MODAL, value);

export const downloadAllDocumentsStarted = (locations: string[], documentDescription: string) => action(MyDocumentActionTypes.DOWNLOAD_ALL_DOCUMENTS_STARTED, { locations, documentDescription });
export const downloadAllDocumentsFailed = (error: string) => action(MyDocumentActionTypes.DOWNLOAD_ALL_DOCUMENTS_FAILED, error);
export const downloadAllDocumentsSuccessful = () => action(MyDocumentActionTypes.DOWNLOAD_ALL_DOCUMENTS_SUCCESSFUL);

export const toggleLinkDocumentsModal = (documentId: number | null) => action(MyDocumentActionTypes.TOGGLE_LINKED_DOCUMENTS_MODAL, documentId);

export const linkDocumentStarted = (documentId: number, linkedDocumentId: number) => action(MyDocumentActionTypes.LINK_DOCUMENT_STARTED, { documentId, linkedDocumentId });
export const linkDocumentSuccessful = () => action(MyDocumentActionTypes.LINK_DOCUMENT_SUCCESSFUL);
export const linkDocumentFailed = (error: string) => action(MyDocumentActionTypes.LINK_DOCUMENT_FAILED, error);

export const toggleDocumentDetailsModal = (value: boolean) => action(MyDocumentActionTypes.TOGGLE_DOCUMENT_DETAILS_MODAL, value);

export const documentDetailsHaveUpdated = (value: boolean) => action(MyDocumentActionTypes.INCOMPLETE_DOCUMENT_DETAILS_UPDATED, value);

export const updateDocumentDetailsStarted = () => action(MyDocumentActionTypes.UPDATE_DOCUMENT_DETAILS_STARTED);
export const updateDocumentDetailsFailed = (error: string) => action(MyDocumentActionTypes.UPDATE_DOCUMENT_DETAILS_FAILED, error);
export const updateDocumentDetailsSuccessful = (documents: ArkDocument[]) => action(MyDocumentActionTypes.UPDATE_DOCUMENT_DETAILS_SUCCESSFUL, documents);

export const skipIncompleteDocument = () => action(MyDocumentActionTypes.SKIP_INCOMPLETE_DOCUMENT);

export const revertIncompleteDocument = () => action(MyDocumentActionTypes.REVERT_INCOMPLETE_DOCUMENT);

export const fetchIncompleteDocumentsStarted = (pageNumber = 1) => action(MyDocumentActionTypes.FETCH_INCOMPLETE_DOCUMENTS_STARTED, pageNumber);
export const fetchIncompleteDocumentsSuccessful = (documents: ArkDocument[], total?: number, pageNumber?: number) => action(MyDocumentActionTypes.FETCH_INCOMPLETE_DOCUMENTS_SUCCESSFUL, { documents, total, pageNumber });
export const fetchIncompleteDocumentsFailed = (error: string) => action(MyDocumentActionTypes.FETCH_INCOMPLETE_DOCUMENTS_FAILED, error);

export const setSelectedIncompleteDocuments = (documents: ArkDocument[]) => action(MyDocumentActionTypes.SET_SELECTED_INCOMPLETE_DOCUMENTS, documents);

export const setDocumentAnalysisPageTotal = (numberOfPages: number | null) => action(MyDocumentActionTypes.SET_DOCUMENT_ANALYSIS_PAGE_TOTAL, numberOfPages);
export const setIncompleteDocumentPageTotal = (numberOfPages: number | null) => action(MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_PAGE_TOTAL, numberOfPages);

export const reanalyzeDocumentStarted = (location: string) => action(MyDocumentActionTypes.REANALYZE_DOCUMENT_STARTED, location);
export const reanalyzeDocumentSuccessful = () => action(MyDocumentActionTypes.REANALYZE_DOCUMENT_SUCCESSFUL);
export const reanalyzeDocumentFailed = (error: string) => action(MyDocumentActionTypes.REANALYZE_DOCUMENT_FAILED, error);

export const initialAIStarted = (documentId: number) => action(MyDocumentActionTypes.INITIAL_AI_DOCUMENT_STARTED, documentId);
export const initialAISuccessful = (documentId: number, mlDataVersion: number) => action(MyDocumentActionTypes.INITIAL_AI_DOCUMENT_SUCCESSFUL, { documentId, mlDataVersion });
export const initialAIFailed = (error: string) => action(MyDocumentActionTypes.INITIAL_AI_DOCUMENT_FAILED, error);

export const documentAnalysisComplete = (documentId: number) => action(MyDocumentActionTypes.DOCUMENT_ANALYSIS_COMPLETE, documentId);
export const documentAnalysisFailed = (documentId: number) => action(MyDocumentActionTypes.DOCUMENT_ANALYSIS_FAILED, documentId);

export const openSupportDocumentStarted = (supportDocument: LinkedDocument) => action(MyDocumentActionTypes.OPEN_SUPPORT_DOCUMENT_STARTED, supportDocument);
export const openSupportDocumentSuccessful = (documentUrl: string, document: LinkedDocument) => action(MyDocumentActionTypes.OPEN_SUPPORT_DOCUMENT_SUCCESSFUL, { documentUrl, document });
export const openSupportDocumentFailed = (error: string) => action(MyDocumentActionTypes.OPEN_SUPPORT_DOCUMENT_FAILED, error);

export const exportToCSVStarted = (documentNameId: number, documentId: number, description: string, datasetInstanceId?: number) => action(MyDocumentActionTypes.EXPORT_TO_CSV_STARTED, { documentNameId, documentId, description, datasetInstanceId });
export const exportToCSVSuccessful = () => action(MyDocumentActionTypes.EXPORT_TO_CSV_SUCCESSFUL);
export const exportToCSVFailed = (error: string) => action(MyDocumentActionTypes.EXPORT_TO_CSV_FAILED, error);

export const exportToCDMStarted = (documentId: number, instantDownload = false) => action(MyDocumentActionTypes.EXPORT_TO_CDM_STARTED, { documentId, instantDownload });
export const exportToCDMSuccessful = () => action(MyDocumentActionTypes.EXPORT_TO_CDM_SUCCESSFUL);
export const exportToCDMFailed = (error: string) => action(MyDocumentActionTypes.EXPORT_TO_CDM_FAILED, error);

export const toggleCDMPreview = (cdmDocument: object | null) => action(MyDocumentActionTypes.TOGGLE_CDM_PREVIEW_MODAL, cdmDocument);
export const downloadCDMPreview = (cdmDocument: object) => action(MyDocumentActionTypes.DOWNLOAD_CDM_PREVIEW, cdmDocument);

export const exportAllCSVStarted = () => action(MyDocumentActionTypes.EXPORT_ALL_CSV_STARTED);
export const exportAllCSVSuccessful = () => action(MyDocumentActionTypes.EXPORT_ALL_CSV_SUCCESSFUL);
export const exportAllCSVFailed = (error: string) => action(MyDocumentActionTypes.EXPORT_ALL_CSV_FAILED, error);

export const toggleDocumentAnalysisView = (view: AnalysisView) => action(MyDocumentActionTypes.TOGGLE_DOCUMENT_ANALYSIS_VIEW, view);

export const fetchLinkedDocumentsStarted = (documentId: number) => action(MyDocumentActionTypes.FETCH_LINKED_DOCUMENTS_STARTED, documentId);
export const fetchLinkedDocumentsSuccessful = (documents: LinkedDocument[]) => action(MyDocumentActionTypes.FETCH_LINKED_DOCUMENTS_SUCCESSFUL, documents);
export const fetchLinkedDocumentsFailed = (error: string) => action(MyDocumentActionTypes.FETCH_LINKED_DOCUMENTS_FAILED, error);

export const updateDocumentDescription = (documentId: number, description: string) => action(MyDocumentActionTypes.UPDATE_DOCUMENT_DESCRIPTION, { documentId, description });

export const documentDescriptionsHaveUpdated = (hasUpdated: boolean) => action(MyDocumentActionTypes.DOCUMENT_DESCRIPTIONS_HAVE_UPDATED, hasUpdated);

export const renameDocumentsStarted = () => action(MyDocumentActionTypes.RENAME_DOCUMENTS_STARTED);
export const renameDocumentsSuccessful = () => action(MyDocumentActionTypes.RENAME_DOCUMENTS_SUCCESSFUL);
export const renameDocumentsFailed = (error: string) => action(MyDocumentActionTypes.RENAME_DOCUMENTS_FAILED, error);

export const runLatestMLQueriesStarted = (documentId: number) => action(MyDocumentActionTypes.RUN_LATEST_ML_QUERIES_STARTED, documentId);
export const runLatestMLQueriesSuccessful = (documentId: number) => action(MyDocumentActionTypes.RUN_LATEST_ML_QUERIES_SUCCESSFUL, documentId);
export const runLatestMLQueriesFailed = (error: string) => action(MyDocumentActionTypes.RUN_LATEST_ML_QUERIES_FAILED, error);

export const createNewEntityFromMLDataStarted = (name: string, key: 'entityA' | 'entityB') => action(MyDocumentActionTypes.CREATE_NEW_ENTITY_FROM_ML_DATA_STARTED, { name, key });
export const createNewEntityFromMLDataSuccessful = (document: ArkDocument, mlData: MLData) => action(MyDocumentActionTypes.CREATE_NEW_ENTITY_FROM_ML_DATA_SUCCESSFUL, { document, mlData });
export const createNewEntityFromMLDataFailed = (error: string) => action(MyDocumentActionTypes.CREATE_NEW_ENTITY_FROM_ML_DATA_FAILED, error);

export const setIncompleteDocumentSchedulePage = (pageNumber: number | null) => action(MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_SCHEDULE_PAGE, pageNumber);

export const setDocumentAnalysisSelectedPage = (pageNumber: number | null) => action(MyDocumentActionTypes.SET_DOCUMENT_ANALYSIS_SELECTED_PAGE, pageNumber);

export const setScheduleEndPlaceholder = (pageNumber: number) => action(MyDocumentActionTypes.SET_SCHEDULE_END_PAGE_PLACEHOLDER, pageNumber);

export const fetchPreExecutionDocumentsStarted = (pageNumber = 1) => action(MyDocumentActionTypes.FETCH_PRE_EXECUTION_DOCUMENTS_STARTED, pageNumber);
export const fetchPreExecutionDocumentsSuccessful = (documents: ArkDocument[], total?: number, pageNumber?: number) => action(MyDocumentActionTypes.FETCH_PRE_EXECUTION_DOCUMENTS_SUCCESSFUL, { documents, total, pageNumber });
export const fetchPreExecutionDocumentsFailed = (error: string) => action(MyDocumentActionTypes.FETCH_PRE_EXECUTION_DOCUMENTS_FAILED, error);

// Document Custom Filters
export const toggleDocumentCustomFiltersModal = (isOpen: boolean) => action(MyDocumentActionTypes.TOGGLE_DOCUMENT_CUSTOM_FILTERS_MODAL, isOpen);
export const updateDocumentCustomFilters = (label: string, createNew: boolean) => action(MyDocumentActionTypes.UPDATE_DOCUMENT_CUSTOM_FILTERS, { label, createNew });
export const setSelectedDocumentCustomFilterId = (value: string | null) => action(MyDocumentActionTypes.SET_SELECTED_DOCUMENT_CUSTOM_FILTER_ID, value);
export const setDocumentCustomFilterHasUpdated = (value: boolean) => action(MyDocumentActionTypes.SET_DOCUMENT_CUSTOM_FILTER_HAS_UPDATED, value);

// Table Filters and Pagination
export const setOriginalDocumentTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(MyDocumentActionTypes.SET_ORIGINAL_DOCUMENT_TABLE_FILTERS, { key, value, type });
export const clearOriginalDocumentTableFilters = (resetSelectedFilterId = true) => action(MyDocumentActionTypes.CLEAR_ORIGINAL_DOCUMENT_TABLE_FILTERS, resetSelectedFilterId);
export const setOriginalDocumentTableColumnSort = (columnSort: ColumnSort | undefined) => action(MyDocumentActionTypes.SET_ORIGINAL_DOCUMENT_TABLE_COLUMN_SORT, columnSort);
export const setOriginalDocumentsPageSize = (pageSize: number) => action(MyDocumentActionTypes.SET_ORIGINAL_DOCUMENTS_PAGE_SIZE, pageSize);
export const originalDocumentsPaginationNext = () => action(MyDocumentActionTypes.ORIGINAL_DOCUMENTS_PAGINATION_NEXT);
export const originalDocumentsPaginationPrevious = () => action(MyDocumentActionTypes.ORIGINAL_DOCUMENTS_PAGINATION_PREVIOUS);

export const setBaseDocumentTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(MyDocumentActionTypes.SET_BASE_DOCUMENT_TABLE_FILTERS, { key, value, type });
export const clearBaseDocumentTableFilters = () => action(MyDocumentActionTypes.CLEAR_BASE_DOCUMENT_TABLE_FILTERS);
export const setBaseDocumentTableColumnSort = (columnSort: ColumnSort | undefined) => action(MyDocumentActionTypes.SET_BASE_DOCUMENT_TABLE_COLUMN_SORT, columnSort);
export const setBaseDocumentsPageSize = (pageSize: number) => action(MyDocumentActionTypes.SET_BASE_DOCUMENTS_PAGE_SIZE, pageSize);
export const baseDocumentsPaginationNext = () => action(MyDocumentActionTypes.BASE_DOCUMENTS_PAGINATION_NEXT);
export const baseDocumentsPaginationPrevious = () => action(MyDocumentActionTypes.BASE_DOCUMENTS_PAGINATION_PREVIOUS);

export const setAgreementTypeDocumentTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS, { key, value, type });
export const clearAgreementTypeDocumentTableFilters = () => action(MyDocumentActionTypes.CLEAR_AGREEMENT_TYPE_DOCUMENT_TABLE_FILTERS);
export const setAgreementTypeDocumentTableColumnSort = (columnSort: ColumnSort | undefined) => action(MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENT_TABLE_COLUMN_SORT, columnSort);
export const setAgreementTypeDocumentsPageSize = (pageSize: number) => action(MyDocumentActionTypes.SET_AGREEMENT_TYPE_DOCUMENTS_PAGE_SIZE, pageSize);
export const agreementTypeDocumentsPaginationNext = () => action(MyDocumentActionTypes.AGREEMENT_TYPE_DOCUMENTS_PAGINATION_NEXT);
export const agreementTypeDocumentsPaginationPrevious = () => action(MyDocumentActionTypes.AGREEMENT_TYPE_DOCUMENTS_PAGINATION_PREVIOUS);

export const setIncompleteDocumentTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_TABLE_FILTERS, { key, value, type });
export const clearIncompleteDocumentTableFilters = () => action(MyDocumentActionTypes.CLEAR_INCOMPLETE_DOCUMENT_TABLE_FILTERS);
export const setIncompleteDocumentTableColumnSort = (columnSort: ColumnSort | undefined) => action(MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENT_TABLE_COLUMN_SORT, columnSort);
export const setIncompleteDocumentsPageSize = (pageSize: number) => action(MyDocumentActionTypes.SET_INCOMPLETE_DOCUMENTS_PAGE_SIZE, pageSize);
export const incompleteDocumentsPaginationNext = () => action(MyDocumentActionTypes.INCOMPLETE_DOCUMENTS_PAGINATION_NEXT);
export const incompleteDocumentsPaginationPrevious = () => action(MyDocumentActionTypes.INCOMPLETE_DOCUMENTS_PAGINATION_PREVIOUS);

export const setPreExecutionDocumentTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS, { key, value, type });
export const clearPreExecutionDocumentTableFilters = () => action(MyDocumentActionTypes.CLEAR_PRE_EXECUTION_DOCUMENT_TABLE_FILTERS);
export const setPreExecutionDocumentTableColumnSort = (columnSort: ColumnSort | undefined) => action(MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENT_TABLE_COLUMN_SORT, columnSort);
export const setPreExecutionDocumentsPageSize = (pageSize: number) => action(MyDocumentActionTypes.SET_PRE_EXECUTION_DOCUMENTS_PAGE_SIZE, pageSize);
export const preExecutionDocumentsPaginationNext = () => action(MyDocumentActionTypes.PRE_EXECUTION_DOCUMENTS_PAGINATION_NEXT);
export const preExecutionDocumentsPaginationPrevious = () => action(MyDocumentActionTypes.PRE_EXECUTION_DOCUMENTS_PAGINATION_PREVIOUS);
