import React, { UIEvent, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { isUndefined } from 'lodash/fp';

export interface ScrollableElement {
    scrollTop: number;
    totalHeight: number;
    scrollableHeight: number;
}

interface ScrollableProps {
    children: JSX.Element | null | (JSX.Element | JSX.Element[] | false | null)[];
    height?: string;
    width?: string;
    maxHeight?: string;
    autoHeight?: boolean;
    handleScroll?: (scrollableElement: ScrollableElement) => void;
}

export const Scrollable: React.FC<ScrollableProps> = ({ children, height = '100%', maxHeight = '100%', width = '100%', autoHeight = true, handleScroll }) => {

    const onScroll = useCallback((e: UIEvent) => {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const scrollElement = (e as any).srcElement as HTMLDivElement;
        const { scrollTop, clientHeight, scrollHeight } = scrollElement;
        if (!isUndefined(handleScroll)) {
            handleScroll({ scrollTop, totalHeight: scrollHeight, scrollableHeight: clientHeight });
        }
    }, [handleScroll]);

    return (
        <Scrollbars
            style={{ width, height }}
            autoHeight={autoHeight}
            autoHeightMin={height}
            autoHeightMax={maxHeight}
            hideTracksWhenNotNeeded
            onScroll={onScroll}
        >
            {children}
        </Scrollbars>
    );
};
