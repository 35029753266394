import React, { useMemo, useCallback, MouseEvent, useState } from 'react';
import { RawDraftContentState } from 'draft-js';
import { isNull, isUndefined, noop } from 'lodash/fp';
import classnames from 'classnames';
import { Options } from 'react-select';

import { PlaybookDeviation, PlaybookProvision, RelevantStakeholders } from '../../../admin/playbook/store';
import { Bin, CaretDown, CaretSide, Delete, SortAscending, SortDescending, Sort, LinkChain } from '../../icons';
import { Text } from '../../text/Text';
import { IconButton } from '../../button/IconButton';
import { Icon } from '../../icon/Icon';
import { WYSIWYG, isEmpty } from '../../wysiwyg/WYSIWYG';
import styles from './PlaybookBuild.module.scss';
import { useAppSelector } from '../../../../hooks/react-redux';
import { getAllUsers } from '../../../admin/users/store';
import { Dropdown, DropdownOption } from '../../dropdown/Dropdown';
import { PlusButton } from '../../button/PlusButton';
import { Deviations } from './Deviations';
import { Position } from '../../modal/PositionModal';
import { Action, ActionModal } from '../../modal/ActionModal';
import { CustomTooltip } from '../../tooltip';

const { lightGrey, primary } = styles;

interface ProvisionProps {
    section: PlaybookProvision;
    showDelete: boolean;
    isTableView: boolean;
    sectionOpen?: boolean;
    showBorder?: boolean;
    toggleSection?: (sectionId: string) => void;
    updateProvision: (sectionId: string, key: keyof PlaybookProvision, value: RawDraftContentState | null | string | string[] | RelevantStakeholders[]) => void;
    removeSection?: (sectionId: string, hasProvisionLinks: boolean) => void;
    showCollapseToggle?: boolean;
    isBuild?: boolean;
    deviationOpen?: boolean;
    toggleDeviation?: (sectionId: string) => void;
    addDeviation?: (sectionId: string, index?: number) => void;
    deleteDeviationRow?: (sectionId: string, rowId: string) => void;
    updateDeviationColumn?: (sectionId: string, rowId: string, key: keyof PlaybookDeviation, value: RawDraftContentState | null | string | number) => void;
    openReorderDeviations?: (sectionId: string | null) => void;
    openLinkModal?: (sectionId: string) => void;
    testId?: string;
}

export const Provision: React.FC<ProvisionProps> = ({
    section,
    showDelete,
    isTableView,
    sectionOpen = true,
    updateProvision,
    toggleSection = noop,
    removeSection = noop,
    showBorder = true,
    showCollapseToggle = true,
    isBuild = false,
    deviationOpen = true,
    toggleDeviation = noop,
    addDeviation = noop,
    deleteDeviationRow = noop,
    updateDeviationColumn = noop,
    openReorderDeviations = noop,
    openLinkModal = noop,
    testId = 'playbook'
}) => {
    const [selectedDeviationIndex, setSelectedDeviationIndex] = useState<number | undefined>(undefined);
    const [position, setPosition] = useState<Position | null>(null);
    const { title, sectionId, provisionContent, provisionTitle, backgroundContent, backgroundTitle, relevantStakeholders, preferredTerms, deviations, linkedProvisions } = section;

    const sectionOpenIcon = useMemo(() => sectionOpen ? CaretDown : CaretSide, [sectionOpen]);
    const deviationOpenIcon = useMemo(() => deviationOpen ? CaretDown : CaretSide, [deviationOpen]);
    const hasMultipleDeviationRows = useMemo(() => deviations.length > 1, [deviations.length]);

    const numberOfLinkedProvisions = useMemo(() => linkedProvisions.length || null, [linkedProvisions]);

    const allUsers = useAppSelector(getAllUsers);
    const stakeholderOptions = useMemo(() => allUsers.map(({ userId, username, forenames, surname }) => ({ value: userId.toString(), label: forenames && surname ? `${forenames} ${surname}` : username })), [allUsers]);
    const selectedStakeholders = useMemo(() => relevantStakeholders.length ? stakeholderOptions.filter(({ value }) => relevantStakeholders.map(({ userId }) => userId.toString()).includes(value)) : null, [stakeholderOptions, relevantStakeholders]);

    const setStakeholders = (sectionId: string, value: DropdownOption | Options<DropdownOption> | null) => {
        let stakeholders = null;
        if (!isNull(value)) {
            stakeholders = (value as Options<DropdownOption>).map(({ value, label }) => ({ userId: parseInt(value), name: label }));
        }
        updateProvision(sectionId, 'relevantStakeholders', stakeholders);
    };

    const updateSectionWysiwyg = (sectionId: string, key: keyof PlaybookProvision, rawValue: RawDraftContentState) => {
        const value = key === 'provisionContent' ? provisionContent : backgroundContent;
        if (isEmpty(rawValue)) {
            if (!isNull(value)) {
                updateProvision(sectionId, key, null);
            }
        } else {
            updateProvision(sectionId, key, rawValue);
        }
    };

    const openDeviationActionModal = useCallback((e: MouseEvent<HTMLDivElement>, index: number) => {
        setPosition({ x: e.clientX, y: e.clientY });
        setSelectedDeviationIndex(index);
    }, [setPosition, setSelectedDeviationIndex]);

    const closeDeviationActionModal = useCallback(() => {
        setPosition(null);
        setSelectedDeviationIndex(undefined);
    }, [setPosition, setSelectedDeviationIndex]);

    const updateTitle = useCallback((sectionId: string, index: number, value: string) => {
        const newTitle = index === 0 ? [value, title[1]] : [title[0], value];
        updateProvision(sectionId, 'title', newTitle);
    }, [title, updateProvision]);

    const deviationActions = useMemo(() => {
        let actions: Action[] = [
            { label: 'Insert Below', icon: SortDescending, onClick: () => addDeviation(sectionId, selectedDeviationIndex! + 1) },
            { label: 'Insert Above', icon: SortAscending, onClick: () => addDeviation(sectionId, selectedDeviationIndex) },
        ];
        if (hasMultipleDeviationRows) {
            actions.push(
                { label: 'Reorder Rows', icon: Sort, onClick: () => openReorderDeviations(sectionId), withSeparator: true },
                { label: 'Delete Row', icon: Delete, onClick: () => deleteDeviationRow(sectionId, deviations[selectedDeviationIndex!].rowId), isDelete: true }
            );
        }
        return actions;
    }, [addDeviation, selectedDeviationIndex, sectionId, deleteDeviationRow, deviations, hasMultipleDeviationRows, openReorderDeviations]);

    return (
        <div className={styles.buildSectionWrapper} key={sectionId} style={{ border: showBorder ? `1px solid ${primary}` : 'none' }} data-testid={`${testId}-provision-wrapper`}>
            <div className={styles.buildSectionTitleWrapper}>
                <div className={styles.buildSectionTitleInputWrapper}>
                    <div className={styles.buildSectionFirstTitleInput}>
                        <Text
                            onChange={e => updateTitle(sectionId, 0, e.target.value)}
                            value={title[0]}
                            maxLength={256}
                            testId={`${testId}-provision-title-0`}
                            placeholder='Paragraph/Section'
                            marginBottom='0px'
                            borderColour={lightGrey}
                        />
                    </div>
                    <div className={styles.buildSectionTitleInputSeparator}>:</div>
                    <div className={styles.buildSectionSecondTitleInput}>
                        <Text
                            onChange={e => updateTitle(sectionId, 1, e.target.value)}
                            value={title[1]}
                            maxLength={256}
                            testId={`${testId}-provision-title-1`}
                            placeholder='Paragraph/Section'
                            marginBottom='0px'
                            borderColour={lightGrey}
                        />
                    </div>
                </div>
                <div onClick={() => toggleSection(sectionId)} className={styles.buildSectionCollapseIcon} data-testid={`${testId}-provision-collapse`}>
                    {showCollapseToggle && <Icon icon={sectionOpenIcon} fontSize={20} />}
                </div>
                {isBuild &&
                    <div className={styles.buildSectionLinkIcon}>
                        <CustomTooltip overlayText='Link Provision' placement='top'>
                            <div>
                                <IconButton onClick={() => openLinkModal(sectionId)} disabled={false} testId='admin-playbook-provision-link' icon={LinkChain} />
                            </div>
                        </CustomTooltip>
                        {!isNull(numberOfLinkedProvisions) && <div className={styles.buildSectionLinkIconNumber}>{numberOfLinkedProvisions}</div>}
                    </div>
                }
                <div className={styles.buildSectionDeleteIcon}>
                    {showDelete &&
                        <IconButton onClick={() => removeSection(sectionId, !isNull(numberOfLinkedProvisions))} disabled={!showDelete} testId='playbook-build-tab-remove-section' icon={Bin} fontSize={20} />
                    }
                </div>
            </div>
            {sectionOpen &&
                <>
                    {isBuild &&
                        <div className={styles.buildStakeholdersWrapper} data-testid={`${testId}-stakeholders-wrapper`}>
                            <div className={styles.buildStakeholdersTitle} data-testid={`${testId}-stakeholders-title`}>Relevant Stakeholders: </div>
                            <div className={styles.buildStakeholdersInput}>
                                <Dropdown
                                    value={selectedStakeholders}
                                    options={stakeholderOptions}
                                    onChange={val => setStakeholders(sectionId, val)}
                                    menuPortalTarget={document.body}
                                    testId={`${testId}-stakeholders-input`}
                                    isMulti={true}
                                    isClearable={true}
                                    disabled={false}
                                    controlBorderColor={lightGrey}
                                />
                            </div>
                        </div>
                    }
                    <div className={classnames(styles.buildSectionList, { [styles.buildSectionTable]: isTableView })}>
                        <div className={styles.buildProvisionWrapper} data-testid={`${testId}-standard-provision-wrapper`}>
                            <div className={styles.buildProvision}>
                                <div className={styles.buildProvisionTitle} data-testid={`${testId}-standard-provision-title`}>Standard Provision: </div>
                                <div className={styles.buildProvisionTitleInput}>
                                    <Text
                                        onChange={e => updateProvision(sectionId, 'provisionTitle', e.target.value)}
                                        value={provisionTitle!}
                                        maxLength={256}
                                        testId={`${testId}-standard-provision-title`}
                                        placeholder='Optional'
                                        marginBottom='0px'
                                        borderColour={lightGrey}
                                    />
                                </div>
                            </div>
                            <div data-testid={`${testId}-standard-provision-content`}>
                                <WYSIWYG
                                    content={provisionContent}
                                    updateContent={value => updateSectionWysiwyg(sectionId, 'provisionContent', value)}
                                    showBorder={false}
                                    borderColor={lightGrey}
                                    height='fit-content'
                                    maxHeight='40vh'
                                />
                            </div>
                        </div>
                        <div className={styles.buildProvisionWrapper} data-testid={`${testId}-background-wrapper`}>
                            <div className={styles.buildProvision}>
                                <div className={styles.buildProvisionTitle} data-testid={`${testId}-background-title`}>Discussion and Background: </div>
                                <div className={styles.buildProvisionTitleInput}>
                                    <Text
                                        onChange={e => updateProvision(sectionId, 'backgroundTitle', e.target.value)}
                                        value={backgroundTitle!}
                                        maxLength={256}
                                        testId={`${testId}-background-title`}
                                        placeholder='Optional'
                                        marginBottom='0px'
                                        borderColour={lightGrey}
                                    />
                                </div>
                            </div>
                            <div data-testid={`${testId}-background-content`}>
                                <WYSIWYG
                                    content={backgroundContent}
                                    updateContent={value => updateSectionWysiwyg(sectionId, 'backgroundContent', value)}
                                    showBorder={false}
                                    borderColor={lightGrey}
                                    height='fit-content'
                                    maxHeight='40vh'
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.buildPreferredTermsWrapper} data-testid={`${testId}-preferred-terms-wrapper`}>
                        <div className={styles.buildPreferredTermsTitle} data-testid={`${testId}-preferred-terms-title`}>Preferred Terms: </div>
                        <div data-testid={`${testId}-preferred-terms-content`}>
                            <WYSIWYG
                                content={preferredTerms}
                                updateContent={value => updateSectionWysiwyg(sectionId, 'preferredTerms', value)}
                                showBorder={false}
                                borderColor={lightGrey}
                                height='fit-content'
                                maxHeight='40vh'
                            />
                        </div>
                    </div>
                    <div className={styles.buildDeviationsWrapper} data-testid={`${testId}-deviations-wrapper`}>
                        <div className={styles.buildDeviationTitleWrapper}>
                            <div onClick={() => toggleDeviation(sectionId)} className={styles.buildDeviationsCollapseIcon} data-testid={`${testId}-deviations-toggle-icon`}>
                                <Icon icon={deviationOpenIcon} fontSize={16} />
                            </div>
                            <div className={styles.buildDeviationTitle} data-testid={`${testId}-deviations-title`}>Amendments/ Deviations: </div>
                            <PlusButton onClick={() => addDeviation(sectionId)} testId={`${testId}-deviations-add-row`} fontSize={16} />
                        </div>
                        {deviationOpen &&
                            <Deviations
                                deviations={deviations}
                                sectionId={sectionId}
                                deleteDeviationRow={deleteDeviationRow}
                                updateDeviationColumn={updateDeviationColumn}
                                showDeleteDeviation={hasMultipleDeviationRows}
                                openDeviationActionModal={openDeviationActionModal}
                                testId={testId}
                            />
                        }
                    </div>
                </>
            }
            <ActionModal
                isOpen={!isNull(position) && !isUndefined(selectedDeviationIndex)}
                position={position}
                closeModal={closeDeviationActionModal}
                actions={deviationActions}
                testId={`${testId}-deviation-actions`}
            />
        </div>
    );
};
