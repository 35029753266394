import { findIndex, flow, getOr, isEmpty, isEqual, isNull, isNumber, isUndefined, last, set, unset } from 'lodash/fp';
import { Reducer } from 'redux';
import { v4 } from 'uuid';

import { LoginActionTypes } from '../../../auth/login/store';
import { DatasetFieldSettings, DatasetFieldType, DatasetType, FormDatasetDefinition, SingleDatasetField, isFormDatasetDefinition } from '../../../datasets/store';
import { DatasetBuilderActionTypes, DatasetBuilderState, DatasetView, SectionOpen, isHiddenDocumentField, HiddenDocumentField, HiddenDocumentSection, SectionReference } from './types';
import { removeDocumentFromHiddenField, removeDocumentFromHiddenSection, updateHiddenFieldOrSectionForDataset } from '../utils';
import { OpenFieldType } from '../../../datasets/instances/store';

const getPropertyToUpdate = (state: DatasetBuilderState, index: number, key: keyof DatasetFieldSettings, groupIndex?: number) => {
    if (isNumber(groupIndex)) {
        return `datasetDefinition.datasetFields[${state.selectedSection!.id}][${groupIndex}].children[${index}].settings[${key}]`;
    }
    return isFormDatasetDefinition(state.datasetDefinition!) ? `datasetDefinition.datasetFields[${state.selectedSection!.id}][${index}].settings[${key}]` : `datasetDefinition.datasetFields[${index}].settings[${key}]`;
};

export const INITIAL_STATE: DatasetBuilderState = {
    modalOpen: false,
    datasetDefinition: null,
    selectedSection: null,
    sectionModalOpen: null,
    selectedGroup: null,
    previewOpen: false,
    publishModalOpen: false,
    publishError: null,
    helpModalOpen: false,
    publishedDatasetDefinitions: [],
    fetchError: null,
    settingsLinkModalOpen: { index: null, type: null },
    datasetView: DatasetView.TABLE_STANDARD,
    confirmDeleteDataset: null,
    isDeleting: false,
    deleteError: null,
    definitionClientIds: [],
    newFields: [],
    reorderSectionsModalOpen: false,
    calculatorHelpModalOpen: false,
    previousDatasetIds: [],
    datasetDefinitionUpdated: false,
    unsavedChangesModalOpen: null,
    definitionHistoryModalOpen: false,
    riskToleranceHelpModalOpen: false,
    documentNameDatasets: [],
    fetchingDocumentNameDatasets: false,
    datasetDefinitions: [],
    fetchingDatasetDefinitions: false,
    savedHiddenDocumentFields: {},
    openFieldsAndSections: [],
    currentHiddenDocumentFields: {},
    documentDatasetModal: null,
    unsavedHiddenDocumentChangesModalOpen: false,
    previewDatasetSelectedDocument: null,
    isSavingDocumentDatasetHiddenFields: false,
    currentDatasetSectionReferences: {},
    savedDatasetSectionReferences: {},
    datasetDocumentConfigUpdated: false,
    configureDatasetParentId: null,
    // Agency Builder properties
    agencyModalOpen: false,
    agencyDefinition: null,
    agencyDefinitions: [],
    agencySettingsModalOpen: null,
    availableAgencyFields: [],
    publishConfirmationModalOpen: false,
    selectedDataset: null
};

export const datasetBuilderReducer: Reducer<DatasetBuilderState> = (state = INITIAL_STATE, action): DatasetBuilderState => {
    switch (action.type) {
        case DatasetBuilderActionTypes.OPEN_BUILDER:
            return set('modalOpen', true, state);
        case DatasetBuilderActionTypes.CLOSE_BUILDER:
            return flow(
                set('modalOpen', false),
                set('datasetDefinition', null),
                set('selectedSection', null),
                set('selectedGroup', null),
                set('previewOpen', false),
                set('newFields', [])
            )(state);
        case DatasetBuilderActionTypes.NEW_DATASET_DEFINITION:
            if (action.payload === DatasetType.FORM) {
                const id = v4();
                const initialSection = { id, label: '' };
                return flow(
                    set('datasetDefinition', {
                        datasetType: action.payload,
                        datasetTitle: '',
                        datasetFields: { [id]: [] },
                        datasetSections: [initialSection],
                        publishReason: '',
                        settings: {}
                    }),
                    set('selectedSection', initialSection)
                )(state);
            } else {
                return set('datasetDefinition', {
                    datasetType: action.payload,
                    datasetTitle: '',
                    datasetFields: [],
                    publishReason: '',
                    settings: {},
                    datasetSections: null
                }, state);
            }
        case DatasetBuilderActionTypes.UPDATE_DATASET_DEFINITION_TITLE:
            return set('datasetDefinition.datasetTitle', action.payload, state);
        case DatasetBuilderActionTypes.OPEN_SECTION_MODAL: {
            if (action.payload) {
                const { label, description } = (state.datasetDefinition as FormDatasetDefinition).datasetSections.find(({ id }) => id === action.payload)!;
                return flow(
                    set('sectionModalOpen', SectionOpen.EDIT),
                    set('selectedSection', { id: action.payload, label, description }),
                    set('selectedGroup', null)
                )(state);
            } else {
                const id = v4();
                return flow(
                    set('sectionModalOpen', SectionOpen.NEW),
                    set('selectedSection', { id, label: '', description: '' }),
                    set('selectedGroup', null),
                )(state);
            }
        }
        case DatasetBuilderActionTypes.CLOSE_SECTION_MODAL: {
            const selectedSection = last((state.datasetDefinition as FormDatasetDefinition).datasetSections) || null;
            return flow(
                set('sectionModalOpen', null),
                set('selectedSection', selectedSection)
            )(state);
        }
        case DatasetBuilderActionTypes.UPDATE_SELECTED_SECTION: {
            const { key, value } = action.payload;
            return set(`selectedSection[${key}]`, value, state);
        }
        case DatasetBuilderActionTypes.ADD_SECTION: {
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const updatedSections = [...state.datasetDefinition!.datasetSections!, state.selectedSection];
                const updatedFields = { ...state.datasetDefinition?.datasetFields, [state.selectedSection!.id]: [] };
                return flow(
                    set('datasetDefinition.datasetSections', updatedSections),
                    set('datasetDefinition.datasetFields', updatedFields),
                    set('sectionModalOpen', null),
                    set('selectedGroup', null)
                )(state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.SAVE_SECTION: {
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const updatedSections = state.datasetDefinition!.datasetSections!.map(section => state.selectedSection!.id === section.id ? state.selectedSection : section);
                return flow(
                    set('datasetDefinition.datasetSections', updatedSections),
                    set('sectionModalOpen', null),
                    set('selectedGroup', null)
                )(state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.REMOVE_SECTION: {
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const updatedSections = state.datasetDefinition!.datasetSections!.filter(({ id }) => id !== action.payload);
                return flow(
                    unset(`datasetDefinition.datasetFields[${action.payload}]`),
                    set('datasetDefinition.datasetSections', updatedSections),
                    set('selectedSection', null),
                    set('selectedGroup', null),
                )(state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.SELECT_SECTION: {
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const selectedSection = state.datasetDefinition!.datasetSections.find(({ id }) => id === action.payload);
                return flow(
                    set('selectedGroup', null),
                    set('selectedSection', selectedSection)
                )(state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.UPDATE_SECTIONS: {
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                return set('datasetDefinition.datasetSections', action.payload, state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.UPDATE_DATASET_FIELD: {
            const { index, field, value } = action.payload;
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                return set(`datasetDefinition.datasetFields[${state.selectedSection!.id}][${index}][${field}]`, value, state);
            } else {
                return set(`datasetDefinition.datasetFields[${index}][${field}]`, value, state);
            }
        }
        case DatasetBuilderActionTypes.UPDATE_DATASET_FIELDS: {
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                return set(`datasetDefinition.datasetFields[${state.selectedSection!.id}]`, action.payload, state);
            } else {
                return set('datasetDefinition.datasetFields', action.payload, state);
            }
        }
        case DatasetBuilderActionTypes.ADD_DATASET_FIELD: {
            const id = v4();
            const newField = { ...action.payload, id };
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const updatedFields = [...state.datasetDefinition!.datasetFields[state.selectedSection!.id], newField];
                if (action.payload.type === DatasetFieldType.GROUP) {
                    return flow(
                        set('selectedGroup', id),
                        set(`datasetDefinition.datasetFields[${state.selectedSection!.id}]`, updatedFields)
                    )(state);
                } else {
                    return set(`datasetDefinition.datasetFields[${state.selectedSection!.id}]`, updatedFields, state);
                }
            } else {
                const updatedFields = [...state.datasetDefinition!.datasetFields!, newField];
                return set('datasetDefinition.datasetFields', updatedFields, state);
            }
        }
        case DatasetBuilderActionTypes.REMOVE_DATASET_FIELD: {
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const updatedFields = state.datasetDefinition!.datasetFields[state.selectedSection!.id].filter(({ id }) => id !== action.payload);
                return set(`datasetDefinition.datasetFields[${state.selectedSection!.id}]`, updatedFields, state);
            } else {
                const updatedFields = state.datasetDefinition!.datasetFields!.filter(({ id }) => id !== action.payload);
                return set('datasetDefinition.datasetFields', updatedFields, state);
            }
        }
        case DatasetBuilderActionTypes.TOGGLE_SETTINGS_MENU_OPEN: {
            const { index, value } = action.payload;
            const propertyToUpdate = getPropertyToUpdate(state, index, 'isOpen');
            return set(propertyToUpdate, value, state);
        }
        case DatasetBuilderActionTypes.TOGGLE_REF_MODAL_OPEN: {
            const { index, value } = action.payload;
            const propertyToUpdate = getPropertyToUpdate(state, index, 'refOpen');
            return set(propertyToUpdate, value, state);
        }
        case DatasetBuilderActionTypes.TOGGLE_SYSTEM_ID_MODAL_OPEN: {
            const { index, value } = action.payload;
            const propertyToUpdate = getPropertyToUpdate(state, index, 'systemIdOpen');
            return set(propertyToUpdate, value, state);
        }
        case DatasetBuilderActionTypes.UPDATE_SETTINGS_VALUE: {
            const { index, key, value } = action.payload;
            const propertyToUpdate = getPropertyToUpdate(state, index, key);
            return set(propertyToUpdate, value, state);
        }
        case DatasetBuilderActionTypes.SELECT_GROUP:
            return set('selectedGroup', action.payload, state);
        case DatasetBuilderActionTypes.ADD_DATASET_FIELD_TO_GROUP: {
            const newField = { ...action.payload.field, id: v4() };
            if (isFormDatasetDefinition(state.datasetDefinition!) && state.selectedGroup) {
                const groupIndex = findIndex(({ id }) => id === action.payload.id, state.datasetDefinition.datasetFields[state.selectedSection!.id]);
                const pathToUpdate = `datasetDefinition.datasetFields[${state.selectedSection!.id}][${groupIndex}].children`;
                const currentGroupFields = getOr([], pathToUpdate, state);
                const updatedFields = [...currentGroupFields, newField];
                return set(pathToUpdate, updatedFields, state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.REMOVE_DATASET_FIELD_FROM_GROUP: {
            const { id, groupIndex } = action.payload;
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const pathToUpdate = `datasetDefinition.datasetFields[${state.selectedSection!.id}][${groupIndex}].children`;
                const currentGroupFields: SingleDatasetField[] = getOr([], pathToUpdate, state);
                const updatedFields = currentGroupFields.filter(field => field.id !== id);
                return set(pathToUpdate, updatedFields, state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.UPDATE_DATASET_GROUP_FIELD: {
            const { index, field, value, groupIndex } = action.payload;
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const pathToUpdate = `datasetDefinition.datasetFields[${state.selectedSection!.id}][${groupIndex}].children[${index}][${field}]`;
                return set(pathToUpdate, value, state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.UPDATE_DATASET_GROUP_FIELDS: {
            const { fields, groupIndex } = action.payload;
            if (isFormDatasetDefinition(state.datasetDefinition!)) {
                const pathToUpdate = `datasetDefinition.datasetFields[${state.selectedSection!.id}][${groupIndex}].children`;
                return set(pathToUpdate, fields, state);
            } else {
                return state;
            }
        }
        case DatasetBuilderActionTypes.TOGGLE_GROUP_SETTINGS_MENU_OPEN: {
            const { groupIndex, index, value } = action.payload;
            const propertyToUpdate = getPropertyToUpdate(state, index, 'isOpen', groupIndex);
            return set(propertyToUpdate, value, state);
        }
        case DatasetBuilderActionTypes.TOGGLE_GROUP_REF_MODAL_OPEN: {
            const { groupIndex, index, value } = action.payload;
            const propertyToUpdate = getPropertyToUpdate(state, index, 'refOpen', groupIndex);
            return set(propertyToUpdate, value, state);
        }
        case DatasetBuilderActionTypes.TOGGLE_GROUP_SYSTEM_ID_MODAL_OPEN: {
            const { groupIndex, index, value } = action.payload;
            const propertyToUpdate = getPropertyToUpdate(state, index, 'systemIdOpen', groupIndex);
            return set(propertyToUpdate, value, state);
        }
        case DatasetBuilderActionTypes.UPDATE_GROUP_SETTINGS_VALUE: {
            const { groupIndex, index, key, value } = action.payload;
            const propertyToUpdate = `datasetDefinition.datasetFields[${state.selectedSection!.id}][${groupIndex}].children[${index}].settings[${key}]`;
            return set(propertyToUpdate, value, state);
        }
        case DatasetBuilderActionTypes.TOGGLE_PREVIEW: {
            return set('previewOpen', !state.previewOpen, state);
        }
        case DatasetBuilderActionTypes.TOGGLE_PUBLISH_MODAL: {
            if (state.datasetDefinition) {
                return flow(
                    set('publishModalOpen', action.payload),
                    set('datasetDefinition.publishReason', '')
                )(state);
            }
            return set('publishModalOpen', action.payload, state);
        }
        case DatasetBuilderActionTypes.UPDATE_PUBLISH_REASON:
            return set('datasetDefinition.publishReason', action.payload, state);
        case DatasetBuilderActionTypes.SET_DATASET_DEFINITION_HAS_UPDATED:
            return set('datasetDefinitionUpdated', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_UNSAVED_CHANGES_MODAL:
            return set('unsavedChangesModalOpen', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_DEFINITION_HISTORY_MODAL:
            return set('definitionHistoryModalOpen', action.payload, state);
        case DatasetBuilderActionTypes.PUBLISH_DATASET_DEFINITION_SUCCESSFUL:
            return { ...INITIAL_STATE, publishedDatasetDefinitions: state.publishedDatasetDefinitions };
        case DatasetBuilderActionTypes.PUBLISH_DATASET_DEFINITION_FAILED:
            return set('publishError', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_HELP_MODAL:
            return set('helpModalOpen', action.payload, state);
        case DatasetBuilderActionTypes.FETCH_ALL_DATASET_DEFINITIONS_SUCCESSFUL:
            return set('publishedDatasetDefinitions', action.payload, state);
        case DatasetBuilderActionTypes.FETCH_ALL_DATASET_DEFINITIONS_FAILED:
            return set('fetchError', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_SETTINGS_LINK_MODAL:
            return set('settingsLinkModalOpen', action.payload, state);
        case DatasetBuilderActionTypes.EDIT_DATASET_DEFINITION: {
            const { datasetDefinition, previousDatasetIds } = action.payload;
            return flow(
                set('datasetDefinition', datasetDefinition),
                set('previousDatasetIds', previousDatasetIds),
                set('modalOpen', true),
                set('datasetDefinitionUpdated', false),
                set('selectedSection', null),
                set('selectedGroup', null),
                set('newFields', [])
            )(state);
        }
        case DatasetBuilderActionTypes.TOGGLE_IS_AGENCY:
            return set('datasetDefinition.settings.isAgency', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_DATASET_VIEW: {
            const { view } = action.payload;
            return set('datasetView', view, state);
        }
        case DatasetBuilderActionTypes.TOGGLE_DELETE_DATASET_CONFIRMATION_MODAL:
            return set('confirmDeleteDataset', action.payload, state);
        case DatasetBuilderActionTypes.DELETE_DATASET_STARTED:
            return set('isDeleting', true, state);
        case DatasetBuilderActionTypes.DELETE_DATASET_SUCCESSFUL:
            return { ...INITIAL_STATE, publishedDatasetDefinitions: action.payload };
        case DatasetBuilderActionTypes.DELETE_DATASET_FAILED:
            return flow(
                set('deleteError', action.payload),
                set('isDeleting', false)
            )(state);
        case DatasetBuilderActionTypes.UPDATE_DATASET_DEFINITION_CLIENT_IDS:
            return set('definitionClientIds', action.payload, state);
        case DatasetBuilderActionTypes.UPDATE_DATASET_DEFINITION_NEW_FIELDS:
            return set('newFields', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_SECTION_REORDER_MODAL:
            return set('reorderSectionsModalOpen', !state.reorderSectionsModalOpen, state);
        // Agency Builder state changes
        case DatasetBuilderActionTypes.TOGGLE_AGENCY_BUILDER:
            return action.payload ?
                set('agencyModalOpen', action.payload, state) :
                flow(
                    set('agencyModalOpen', action.payload),
                    set('selectedDataset', null))(state);
        case DatasetBuilderActionTypes.EDIT_AGENCY_DEFINITION: {
            const { agencyDefinition, datasetTitle, agreementTypeId, datasetId } = action.payload;
            const definition = agencyDefinition ? agencyDefinition : { datasetTitle, datasetFields: [] };
            const selectedDataset = { agreementTypeId, datasetId };
            return flow(
                set('agencyDefinition', definition),
                set('selectedDataset', selectedDataset),
                set('agencyModalOpen', true)
            )(state);
        }
        case DatasetBuilderActionTypes.FETCH_AGENCY_DEFINITIONS_SUCCESSFUL:
            return set('agencyDefinitions', action.payload, state);
        case DatasetBuilderActionTypes.ADD_AGENCY_DATASET_FIELD: {
            const newField = { ...action.payload, id: v4() };
            const updatedFields = [...state.agencyDefinition!.datasetFields!, newField];
            return set('agencyDefinition.datasetFields', updatedFields, state);
        }
        case DatasetBuilderActionTypes.REMOVE_AGENCY_DATASET_FIELD: {
            const updatedFields = state.agencyDefinition!.datasetFields!.filter(({ id }) => id !== action.payload);
            return set('agencyDefinition.datasetFields', updatedFields, state);
        }
        case DatasetBuilderActionTypes.UPDATE_AGENCY_DATASET_FIELD: {
            const { index, field, value } = action.payload;
            return set(`agencyDefinition.datasetFields[${index}][${field}]`, value, state);
        }
        case DatasetBuilderActionTypes.UPDATE_AGENCY_DATASET_FIELDS:
            return set('agencyDefinition.datasetFields', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_AGENCY_SETTINGS_MODAL_OPEN:
            return set('agencySettingsModalOpen', action.payload, state);
        case DatasetBuilderActionTypes.UPDATE_AGENCY_SETTINGS_VALUE: {
            const { index, key, value } = action.payload;
            return set(`agencyDefinition.datasetFields[${index}].settings[${key}]`, value, state);
        }
        case DatasetBuilderActionTypes.UPDATE_AGENCY_LINKED_FIELDS: {
            const { index, value } = action.payload;
            return set(`agencyDefinition.datasetFields[${index}].linkedFields`, value, state);
        }
        case DatasetBuilderActionTypes.FETCH_AGENCY_FIELDS_SUCCESSFUL:
            return set('availableAgencyFields', action.payload, state);
        case DatasetBuilderActionTypes.FETCH_AGENCY_FIELDS_FAILED:
            return set('fetchError', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_PUBLISH_AGENCY_CONFIRMATION:
            return set('publishConfirmationModalOpen', action.payload, state);
        case DatasetBuilderActionTypes.PUBLISH_AGENCY_DATASET_DEFINITION_SUCCESSFUL:
            return { ...INITIAL_STATE, datasetView: DatasetView.TABLE_AGENCY };
        case DatasetBuilderActionTypes.PUBLISH_AGENCY_DATASET_DEFINITION_FAILED:
            return set('publishError', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_CALCULATOR_HELP_MODAL:
            return set('calculatorHelpModalOpen', !state.calculatorHelpModalOpen, state);
        case DatasetBuilderActionTypes.TOGGLE_RISK_TOLERANCE_HELP_MODAL:
            return set('riskToleranceHelpModalOpen', !state.riskToleranceHelpModalOpen, state);
        // Document Dataset state
        case DatasetBuilderActionTypes.FETCH_ALL_DOCUMENT_DATASETS_STARTED:
            return set('fetchingDocumentNameDatasets', true, state);
        case DatasetBuilderActionTypes.FETCH_ALL_DOCUMENT_DATASETS_SUCCESSFUL:
            return flow(
                set('documentNameDatasets', action.payload),
                set('fetchingDocumentNameDatasets', false)
            )(state);
        case DatasetBuilderActionTypes.FETCH_ALL_DOCUMENT_DATASETS_FAILED:
            return flow(
                set('fetchingDocumentNameDatasets', false)
            )(state);
        case DatasetBuilderActionTypes.FETCH_DEFINITIONS_FOR_DATASET_STARTED:
            return set('fetchingDatasetDefinitions', true, state);
        case DatasetBuilderActionTypes.FETCH_DEFINITIONS_FOR_DATASET_SUCCESSFUL: {
            const { datasetDefinitions, documentHiddenFields, documentSpecificMLSectionIds } = action.payload;
            const noCurrentHiddenFields = isEmpty(state.currentHiddenDocumentFields);
            const noCurrentDocumentMLSectionIds = isEmpty(state.currentDatasetSectionReferences);
            return flow(
                set('datasetDefinitions', datasetDefinitions),
                set('savedHiddenDocumentFields', documentHiddenFields),
                set('currentHiddenDocumentFields', noCurrentHiddenFields ? documentHiddenFields : state.currentHiddenDocumentFields),
                set('savedDatasetSectionReferences', documentSpecificMLSectionIds),
                set('currentDatasetSectionReferences', noCurrentDocumentMLSectionIds ? documentSpecificMLSectionIds : state.currentDatasetSectionReferences),
                set('fetchingDatasetDefinitions', false)
            )(state);
        }
        case DatasetBuilderActionTypes.FETCH_DEFINITIONS_FOR_DATASET_FAILED:
            return set('fetchingDatasetDefinitions', false, state);
        case DatasetBuilderActionTypes.ADD_DATASETS_OPEN_FIELD_SECTION: {
            const openFieldsAndSections = [...state.openFieldsAndSections, action.payload];
            return set('openFieldsAndSections', openFieldsAndSections, state);
        }
        case DatasetBuilderActionTypes.REMOVE_DATASETS_OPEN_FIELD_SECTION: {
            const openFieldsAndSections = state.openFieldsAndSections.filter(fieldSection => !isEqual(fieldSection, action.payload));
            return set('openFieldsAndSections', openFieldsAndSections, state);
        }
        case DatasetBuilderActionTypes.REMOVE_ALL_DATASETS_FIELD_SECTIONS:
            return set('openFieldsAndSections', [], state);
        case DatasetBuilderActionTypes.SET_CONFIGURE_DATASET_PARENT_ID:
            return set('configureDatasetParentId', action.payload, state);
        case DatasetBuilderActionTypes.TOGGLE_DOCUMENT_HIDDEN_FIELDS: {
            const { datasetId, hiddenField } = action.payload;
            const isField = isHiddenDocumentField(hiddenField);
            const datasetHiddenFields = state.currentHiddenDocumentFields[datasetId] || [];
            let shouldRemove = false;
            let currentHiddenDocumentNameIds: number[] = [];
            if (isField) {
                currentHiddenDocumentNameIds = (datasetHiddenFields.filter(({ type }) => type === OpenFieldType.FIELD) as HiddenDocumentField[]).find(({ fieldId }) => fieldId === hiddenField.fieldId)?.documentNameIds || [];
            } else {
                currentHiddenDocumentNameIds = (datasetHiddenFields.filter(({ type }) => type === OpenFieldType.SECTION) as HiddenDocumentSection[]).find(({ sectionId }) => sectionId === hiddenField.sectionId)?.documentNameIds || [];
            }
            if (currentHiddenDocumentNameIds.length > 0) {
                shouldRemove = (hiddenField.documentNameIds as number[]).every(documentNameId => currentHiddenDocumentNameIds.includes(documentNameId));
            }

            let updatedHiddenFields = { ...state.currentHiddenDocumentFields };
            const hiddenFieldId = isField ? hiddenField.fieldId : hiddenField.sectionId;

            if (shouldRemove && !isUndefined(updatedHiddenFields[datasetId])) {
                if (isField) {
                    updatedHiddenFields = removeDocumentFromHiddenField(datasetHiddenFields, state.documentNameDatasets, state.configureDatasetParentId!, hiddenField, updatedHiddenFields, datasetId, hiddenFieldId);
                } else {
                    updatedHiddenFields = removeDocumentFromHiddenSection(state.datasetDefinitions, hiddenField, updatedHiddenFields, datasetId, hiddenFieldId);
                }
            } else {
                if (isUndefined(updatedHiddenFields[datasetId])) {
                    updatedHiddenFields = { ...updatedHiddenFields, [datasetId]: [] };
                }
                const updatedFieldsForDataset = updateHiddenFieldOrSectionForDataset(isField, hiddenFieldId, hiddenField, updatedHiddenFields, datasetId);
                updatedHiddenFields[datasetId] = updatedFieldsForDataset;
            }
            return set('currentHiddenDocumentFields', updatedHiddenFields, state);
        }
        case DatasetBuilderActionTypes.TOGGLE_MODAL_DOCUMENT_DATASET: {
            const { datasetId } = action.payload;
            if (isNull(datasetId)) {
                return set('documentDatasetModal', null, state);
            }
            return set('documentDatasetModal', action.payload, state);
        }
        case DatasetBuilderActionTypes.RESET_DOCUMENT_DATASET_CONFIGURE:
            return flow(
                set('currentHiddenDocumentFields', {}),
                set('currentDatasetSectionReferences', {})
            )(state);
        case DatasetBuilderActionTypes.TOGGLE_UNSAVED_DATASET_CONFIGURATION_MODAL:
            return set('unsavedHiddenDocumentChangesModalOpen', action.payload, state);
        case DatasetBuilderActionTypes.SET_PREVIEW_SELECTED_DOCUMENT:
            return set('previewDatasetSelectedDocument', action.payload, state);
        case DatasetBuilderActionTypes.SAVE_DOCUMENT_DATASET_CONFIGURATION_STARTED:
            return set('isSavingDocumentDatasetHiddenFields', true, state);
        case DatasetBuilderActionTypes.SAVE_DOCUMENT_DATASET_CONFIGURATION_SUCCESSFUL:
            return flow(
                set('isSavingDocumentDatasetHiddenFields', false),
                set('savedHiddenDocumentFields', state.currentHiddenDocumentFields),
                set('savedDatasetSectionReferences', state.currentDatasetSectionReferences),
                set('datasetDocumentConfigUpdated', false)
            )(state);
        case DatasetBuilderActionTypes.SAVE_DOCUMENT_DATASET_CONFIGURATION_FAILED:
            return set('isSavingDocumentDatasetHiddenFields', false, state);
        case DatasetBuilderActionTypes.ADD_DOCUMENT_DATASET_SECTION_REFERENCE: {
            const { datasetId, documentNameId, sectionId, reference } = action.payload;
            const documentSectionReference = state.currentDatasetSectionReferences[datasetId][documentNameId].find(reference => reference.sectionId === sectionId);
            const updatedReferences: SectionReference = !isUndefined(documentSectionReference) ? { ...documentSectionReference, references: [...documentSectionReference.references, reference] } : { sectionId, references: [reference] };
            const filteredReferences = state.currentDatasetSectionReferences[datasetId][documentNameId].filter(reference => reference.sectionId !== sectionId);
            return set(`currentDatasetSectionReferences[${datasetId}][${documentNameId}]`, [...filteredReferences, updatedReferences], state);
        }
        case DatasetBuilderActionTypes.DELETE_DOCUMENT_DATASET_SECTION_REFERENCE: {
            const { datasetId, documentNameId, sectionId, reference } = action.payload;
            const updatedReferences = state.currentDatasetSectionReferences[datasetId][documentNameId].map(ref => ref.sectionId === sectionId ? { ...ref, references: ref.references.filter(id => id !== reference) } : ref);
            return set(`currentDatasetSectionReferences[${datasetId}][${documentNameId}]`, updatedReferences, state);
        }
        case DatasetBuilderActionTypes.UPDATE_DOCUMENT_DATASET_SECTION_REFERENCE_ORDER: {
            const { datasetId, documentNameId, sectionId, references } = action.payload;
            const updatedReferences = state.currentDatasetSectionReferences[datasetId][documentNameId].map(reference => reference.sectionId === sectionId ? { ...reference, references } : reference);
            return set(`currentDatasetSectionReferences[${datasetId}][${documentNameId}]`, updatedReferences, state);
        }
        case DatasetBuilderActionTypes.DATASET_DOCUMENT_CONFIG_UPDATED:
            return set('datasetDocumentConfigUpdated', action.payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
