import React, { useCallback } from 'react';
import { ColDef, GridOptions, ICellRendererParams, RowDoubleClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { flow, set, unset } from 'lodash/fp';

import { Table, dateFormatter, TooltipCell } from '../../../shared/table';
import { Icon } from '../../../shared/icon/Icon';
import { Attestation, Calendar, Delete, Export, Mail, Repeat } from '../../../shared/icons';
import { ActionCell } from '../../../shared/table/ActionCell';
import { useAppDispatch } from '../../../../hooks/react-redux';
import { Action } from '../../../shared/modal/ActionModal';
import { SchedulerAction, SchedulerDB, toggleScheduleWizard, stripEvent, toggleDeleteEventConfirmationModal, recoverScheduledActionStarted } from '../store';
import { AttestationFormDB } from '../../attestations/store';
import styles from '../Workflow.module.scss';
import { Spinner } from '../../../shared/spinner/Spinner';

const WorkflowActionCell: React.FC<ICellRendererParams> = ({ value }) => (
    <div className={styles.actionCellWrapper}>
        <div className={styles.actionCellLabel}>{value === SchedulerAction.CREATE_ATTESTATION ? 'Create an Attestation' : 'Send an Email'}</div>
    </div>
);

const IconCell: React.FC<ICellRendererParams> = ({ value }) => (
    <div className={styles.tableIconWrapper}>
        <Icon icon={value === SchedulerAction.CREATE_ATTESTATION ? Attestation : Mail} fontSize={20} />
    </div>
);

interface WorkflowListTableProps {
    isLoading: boolean;
    scheduledActions: SchedulerDB[];
    attestationForms: AttestationFormDB[];
    isActiveActionsView: boolean;
    isArchivedActionsView: boolean;
    testId?: string;
}

export const WorkflowListTable: React.FC<WorkflowListTableProps> = ({ isLoading, scheduledActions, attestationForms, isActiveActionsView, isArchivedActionsView, testId }) => {
    const dispatch = useAppDispatch();

    const openScheduledAction = useCallback((event: SchedulerDB) => dispatch(toggleScheduleWizard(true, stripEvent(event))), [dispatch]);

    const openDuplicateScheduledAction = useCallback((event: SchedulerDB) => {
        const duplicateEvent = flow(
            unset('scheduledActionId'),
            set('firstDate', null),
            set('nextDate', null)
        )(event);
        dispatch(toggleScheduleWizard(true, stripEvent(duplicateEvent)));
    }, [dispatch]);

    const openDeleteConfirmationModal = useCallback((event: SchedulerDB) => dispatch(toggleDeleteEventConfirmationModal(event.scheduledActionId!)), [dispatch]);

    const recoverScheduledAction = useCallback((event: SchedulerDB) => dispatch(recoverScheduledActionStarted(event)), [dispatch]);

    const onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => openScheduledAction(data);

    const scheduledActionOptions = useCallback((data: SchedulerDB): Action[] => {
        let actions: Action[] = [
            { label: 'Open', icon: Calendar, onClick: () => openScheduledAction(data), withSeparator: isArchivedActionsView },
        ];
        if (isActiveActionsView) {
            actions.push(
                { label: 'Duplicate', icon: Repeat, onClick: () => openDuplicateScheduledAction(data), withSeparator: true },
                { label: 'Delete', icon: Delete, onClick: () => openDeleteConfirmationModal(data), isDelete: true }
            );
        }
        if (isArchivedActionsView) {
            actions.push(
                { label: 'Recover', icon: Export, onClick: () => recoverScheduledAction(data) }
            );
        }
        return actions;
    }, [openScheduledAction, openDeleteConfirmationModal, openDuplicateScheduledAction, recoverScheduledAction, isActiveActionsView, isArchivedActionsView]);

    const actionCellParams = {
        testId: 'scheduled-action-options',
        actions: scheduledActionOptions
    };

    const repeatFormatter = ({ value, data }: ValueFormatterParams) => {
        if (!value) {
            return 'Never';
        }
        return `Every ${data.intervalValue} ${data.interval}`;
    };

    const getAttestationFormName = useCallback((id: number) => attestationForms.find(({ attestationFormId }) => attestationFormId === id)?.name || '', [attestationForms]);

    const nameFormatter = useCallback(({ value, data }: ValueFormatterParams) => {
        if (value === SchedulerAction.CREATE_ATTESTATION) {
            return getAttestationFormName(data.content.attestationFormId);
        }
        return data.content.subject;
    }, [getAttestationFormName]);

    const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };
    const columnDefs: ColDef[] = [
        { colId: 'icon', headerName: '', field: 'action', cellRenderer: 'iconCellRenderer', width: 0.05 },
        { colId: 'name', headerName: 'Name', field: 'action', cellRenderer: 'tooltipCellRenderer', valueFormatter: nameFormatter, width: 0.4 },
        { colId: 'action', headerName: 'Action', field: 'action', cellRenderer: 'workflowActionCellRenderer', width: 0.15 },
        { colId: 'starting', headerName: 'Starting', field: 'firstDate', cellStyle: defaultCellStyle, valueFormatter: dateFormatter, width: 0.15 },
        { colId: 'repeat', headerName: 'Repeat', field: 'repeat', cellStyle: defaultCellStyle, valueFormatter: repeatFormatter, width: 0.2 },
        { colId: 'actions', headerName: '', field: '', cellRenderer: 'actionCellRenderer', width: 0.05, cellRendererParams: actionCellParams }
    ];

    const gridOptions: GridOptions = {
        components: {
            iconCellRenderer: IconCell,
            tooltipCellRenderer: TooltipCell,
            workflowActionCellRenderer: WorkflowActionCell,
            actionCellRenderer: ActionCell
        },
        paginationPageSize: 20
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Table
            rowData={scheduledActions}
            colDefs={columnDefs}
            gridOptions={gridOptions}
            percentageWidths
            onRowDoubleClicked={onRowDoubleClicked}
            testId={testId}
            padding='0px'
        />
    );
};
