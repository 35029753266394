import React, { useCallback, useEffect } from 'react';
import { isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { Document } from '../../shared/icons';
import { Spinner } from '../../shared/spinner/Spinner';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { DocumentType, getIsUploading, SecondaryUploadDocument, uploadDocumentStarted, uploadDocumentUpdateValue, toggleUploadModal, getAnalysisUploadDocument, SupportingUploadDocument, fetchLinkedDocumentsStarted, getOriginalDocument, getSecondaryDocument } from '../../documents/my-documents/store';
import { DocumentTypeSelector } from '../../documents/my-documents/upload/DocumentTypeSelector';
import { SecondaryDocument } from '../../documents/my-documents/upload/SecondaryDocument';
import styles from './DatasetInstance.module.scss';
import { SupportDocument } from '../../documents/my-documents/upload/SupportDocument';
import { futureDate } from '../../../utils/luxon';
import { getUserHasFeaturePermission } from '../../auth/login/store';
import { FeaturePermission } from '../../admin/users/store';
import { isISDAFamilyAgreementType } from '../../admin/documents/store/typeAssertions';

interface SecondaryDocumentModalProps {
    isOpen: boolean;
    disabled?: boolean;
    originalDocumentId: number;
    documentId: number;
    executedDate: string;
    agreementTypeId?: number | null;
}

export const SecondaryDocumentModal: React.FC<SecondaryDocumentModalProps> = ({ isOpen, disabled = false, originalDocumentId, documentId, executedDate, agreementTypeId }) => {
    const dispatch = useAppDispatch();
    const document = useAppSelector(getAnalysisUploadDocument);
    const { description, file } = document;
    const closeModal = () => dispatch(toggleUploadModal(false));
    const uploadDocument = () => dispatch(uploadDocumentStarted(true));
    const originalDocument = useAppSelector(getOriginalDocument);
    const secondaryDocument = useAppSelector(getSecondaryDocument);

    const hasUploadDocumentPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.UPLOAD_DOCUMENTS]));

    const getDatedAsOf = useCallback((type: DocumentType) => {
        const isSupportDocumentType = type === DocumentType.SUPPORT;
        if (!isNull(secondaryDocument) && isSupportDocumentType) {
            return secondaryDocument.datedAsOf;
        }
        if (!isNull(originalDocument)) {
            return !isNull(originalDocument.datedAsOf) ? futureDate({ days: 1 }, originalDocument.datedAsOf) : null;
        }
        return null;
    }, [originalDocument, secondaryDocument]);

    const fetchLinkedDocuments = useCallback(() => {
        if (!isNull(agreementTypeId) && !isUndefined(agreementTypeId) && isISDAFamilyAgreementType(agreementTypeId)) {
            dispatch(fetchLinkedDocumentsStarted(originalDocumentId));
        }
    }, [agreementTypeId, dispatch, originalDocumentId]);

    useEffect(() => {
        fetchLinkedDocuments();
    }, [fetchLinkedDocuments]);

    const isUploading = useAppSelector(getIsUploading);
    const selectType = (type: DocumentType) => {
        const isSupportDocumentType = type === DocumentType.SUPPORT;
        const datedAsOf = getDatedAsOf(type);
        const baseDocumentId = isSupportDocumentType ? documentId : originalDocumentId;
        const newExecutedDate = isSupportDocumentType ? executedDate : futureDate({ days: 1 }, executedDate);
        dispatch(uploadDocumentUpdateValue('type', type));
        dispatch(uploadDocumentUpdateValue('originalDocumentId', baseDocumentId));
        dispatch(uploadDocumentUpdateValue('executedDate', newExecutedDate));
        dispatch(uploadDocumentUpdateValue('datedAsOf', datedAsOf));
    };

    const getModalContent = () => {
        if (isUploading) {
            return <div className={styles.spinnerWrapper}><Spinner /></div>;
        }
        if (isUndefined(document.type)) {
            return <DocumentTypeSelector selectType={selectType} />;
        }
        if (document.type === DocumentType.SUPPORT) {
            return <SupportDocument uploadDocument={document as SupportingUploadDocument} disabled={disabled} />;
        }
        return <SecondaryDocument disabled={disabled} uploadDocument={document as SecondaryUploadDocument} />;
    };

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel='Upload'
            confirmDisabled={!file || !description || !hasUploadDocumentPermission}
            confirm={uploadDocument}
            testId='upload-secondary-document'
        >
            <div className={styles.uploadDocumentWrapper} data-testid='upload-secondary-document-modal-wrapper'>
                <ModalHeader
                    label='Upload a new document'
                    testId='upload-secondary-document-modal'
                    icon={Document}
                />
                {getModalContent()}
            </div>
        </ConfirmationModal>
    );
};
