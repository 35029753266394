import React from 'react';

import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { QuestionMark } from '../../shared/icons';
import styles from './NettingEngine.module.scss';

interface NettingEngineAssumptionModalProps {
    isOpen: boolean;
    closeModal: () => void;
    assumptions: string[];
}

export const NettingEngineAssumptionModal: React.FC<NettingEngineAssumptionModalProps> = ({ isOpen, closeModal, assumptions }) => (
    <ConfirmationModal
        isOpen={isOpen}
        closeModal={closeModal}
        showConfirm={false}
        closeLabel='Close'
    >
        <div className={styles.nettingEngineAssumptionModalWrapper}>
            <ModalHeader
                testId='netting-engine-assumptions-modal'
                label='Assumptions'
                icon={QuestionMark}
            />
            <div className={styles.modalWarning}>
                <div className={styles.modalWarningTitle}>The analysis provided is subject to the following assumptions:</div>
            </div>
            {assumptions.map((eachAssumption, index) => (
                <div key={index} className={styles.modalContent}>{eachAssumption}</div>
            ))}
        </div>
    </ConfirmationModal>
);
