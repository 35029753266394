import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { getHelpModalOpen, toggleHelpModal } from './store';
import { DatasetType } from '../../datasets/store';
import styles from './DatasetBuilder.module.scss';
import { Scrollable } from '../../shared/scrollable/Scrollable';

interface HelpModalProps {
    datasetType: DatasetType;
}

const formHelp: string[] = [
    'A section is automatically created for you when starting a form dataset.',
    'If the section label is not updated and more sections are not added, the form will remain in short form format.',
    'Select a field type from the options on the right hand side.',
    'Populate the selected field type with any config you require from labels and descriptions, to primary keys and multi select dropdowns.',
    'Groups are no longer limited to a finite amount of items, but instead made to neatly contain items within the width of a page.',
    'Sections, Groups or Single Field Items can be edited, moved and deleted as required.',
    'To select/deselect a Group, simply click the label \'Group\'.',
    'When a group is full, you will receive a note to inform you that it is full and how many fields are included in that group.',
    'When moving items between groups, the group you are moving the item to must have the space within its group to allow this.',
    'To view how the dataset looks at any time, simply toggle \'Show Preview\' button, have a look, and \'Continue Building\' when ready.',
    'You must include a dataset title before being able to publish a dataset.',
    'You must link all included Document, Dataset and Dropdown fields in order to be able to publish a dataset.',
    'Upon publishing, you will have the option to add a publish reason or leave blank.'
];

const tableHelp: string[] = [
    'Table datasets are simpler to build than form datasets as they have no concept of groups and sections.',
    'Simply choose the fields you would like to add to your table and populate the config appropriately.',
    'Fields can be moved around and deleted as required.',
    'To view how the dataset looks at any time, simply toggle \'Show Preview\' button, have a look, and \'Continue Building\' when ready.',
    'You must include a dataset title before being able to publish a dataset.',
    'You must link all included Document, Dataset and Dropdown fields in order to be able to publish a dataset.',
    'Upon publishing, you will have the option to add a publish reason or leave blank.'
];

export const HelpModal: React.FC<HelpModalProps> = ({ datasetType }) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getHelpModalOpen);
    const closeModal = () => dispatch(toggleHelpModal(false));

    const datasetTypeHelp = datasetType === DatasetType.FORM ? formHelp : tableHelp;

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            closeLabel='Close Help'
            showConfirm={false}
            closeOnOverlayClick={false}
        >
            <div className={styles.helpWrapper}>
                <div className={styles.helpTitle}>Help</div>
                <div className={styles.helpContent}>
                    <Scrollable>
                        {datasetTypeHelp.map((help, index) => (
                            <div key={index} className={styles.helpItemWrapper}>
                                <div className={styles.helpBulletPoint}>?</div>
                                <div className={styles.helpItem}>{help}</div>
                            </div>
                        ))}
                    </Scrollable>
                </div>
            </div>
        </ ConfirmationModal>
    );
};
