import { isEqual, isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../../../hooks/react-redux';
import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { AttestationManager, getAllAttestationForms } from '../../store';
import styles from '../AttestationManager.module.scss';

interface DetailsProps {
    attestation: AttestationManager;
    testId?: string;
}

export const Details: React.FC<DetailsProps> = ({ attestation, testId }) => {
    const attestationForms = useAppSelector(getAllAttestationForms);

    const { attestationInstance, attestationStatus } = attestation;
    const { attestations } = attestationInstance;

    const attestationForm = useMemo(() => attestationForms.find(({ attestationFormId }) => attestationFormId === attestationInstance.attestationFormId)!, [attestationForms, attestationInstance]);

    const getUserAnswerLabel = useCallback((id: string) => attestationForm.userAnswers.find(({ userAnswerId }) => userAnswerId === id)?.label || '', [attestationForm]);

    const usernames = attestationStatus.map(({ username, userAnswerId }) => `${username}(${getUserAnswerLabel(userAnswerId)})`);
    const redFlagAnswers = attestations.filter(({ answer, config: { redFlagAnswer } }) => !isNull(redFlagAnswer) && isEqual(answer, redFlagAnswer)).length;
    const percentageComplete = useMemo(() => {
        const attestationsAnswered = attestations.filter(({ answer }) => !isNull(answer) && answer).length;
        const totalAttestations = attestations.length;
        return Math.round((attestationsAnswered / totalAttestations) * 100);
    }, [attestations]);

    return (
        <div className={styles.detailsWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.attestationFormName} data-testid={`${testId}-form-title`}>{attestationForm.name}</div>
            <div className={styles.detailsTableWrapper} data-testid={`${testId}-table-wrapper`}>
                <div className={styles.relevantToWrapper}>
                    <div className={styles.relevantToHeader} data-testid={`${testId}-users-label`}>This attestation is relevant to:</div>
                    <div className={styles.usernamesWrapper}>
                        <Scrollable>
                            {usernames.map((username, index) =>
                                <div key={username} className={styles.usernameLabel} data-testid={`${testId}-username-${index}`}>{username}</div>
                            )}
                        </Scrollable>
                    </div>
                </div>
                <div className={styles.redFlagWrapper}>
                    <div className={styles.redFlagHeader} data-testid={`${testId}-red-flag-label`}>This attestation currently has:</div>
                    <div className={styles.redFlagAnswers} data-testid={`${testId}-red-flag-value`}>{`${redFlagAnswers} red flag answers`}</div>
                </div>
                <div className={styles.completionWrapper}>
                    <div className={styles.completionHeader} data-testid={`${testId}-completion-label`}>This attestation is:</div>
                    <div className={styles.percentageComplete} data-testid={`${testId}-completion-value`}>{`${percentageComplete}% complete`}</div>
                </div>
            </div>
        </div>
    );
};
