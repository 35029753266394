import { max, min } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { PieChart, PieChartAnalyticsData } from '../../../shared/analytics/PieChart';
import { Spinner } from '../../../shared/spinner/Spinner';
import styles from '../Analytics.module.scss';
import { getThirdPartyFunctionCompanyPieData, getThirdPartyName, ThirdPartyFunctionCompanyCoveragePieAnalytics, ThirdPartyFunctionCompanyCoveragePieCategories, getIsFetchingThirdPartyCoverageTableData } from '../store';
import { ThirdPartyFunctionCompanyTable } from './ThirdPartyFunctionCompanyTable';

interface ThirdPartyFunctionCompanyPieProps {
    dimensions?: {
        height: number;
        width: number;
    }
    id?: string;
    testId?: string;
}

const { red, green, amber, grey, yellowGreen } = styles;

export const ThirdPartyFunctionCompanyPie: React.FC<ThirdPartyFunctionCompanyPieProps> = ({ testId, id, dimensions }) => {
    const pieData = useAppSelector(getThirdPartyFunctionCompanyPieData);
    const selectedThirdPartyName = useAppSelector(getThirdPartyName);
    const isLoading = useAppSelector(getIsFetchingThirdPartyCoverageTableData);

    const diameter = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 200;
            const svgHeight = dimensions.height - 400;
            return min([svgWidth, svgHeight])!;
        }
        return 0;
    }, [dimensions]);

    const tableWidth = useMemo(() => {
        if (dimensions) {
            const svgWidth = (dimensions.width / 2) - 20;
            return svgWidth;
        }
        return 0;
    }, [dimensions]);

    const formattedPieData: ThirdPartyFunctionCompanyCoveragePieAnalytics[] = useMemo(() => pieData.map(segment => {
        let color = grey;
        if (segment.key === ThirdPartyFunctionCompanyCoveragePieCategories.FULL_IMPACT) color = red;
        if (segment.key === ThirdPartyFunctionCompanyCoveragePieCategories.CRITICAL_NOT_MATERIAL_IMPACT) color = amber;
        if (segment.key === ThirdPartyFunctionCompanyCoveragePieCategories.NOT_CRITICAL_MATERIAL_IMPACT) color = yellowGreen;
        if (segment.key === ThirdPartyFunctionCompanyCoveragePieCategories.NOT_CRITICAL_NOT_MATERIAL_IMPACT) color = green;
        return { ...segment, color };
    }), [pieData]);

    const tooltipContent = ({ value }: PieChartAnalyticsData) => `<tspan dy=12 x=10>Total: ${value}</tspan>`;
    const tooltipWidth = (key: string, value: number) => max([(`Total: ${value}`.length * 7), 10])!;

    const body = useMemo(() => {

        if (isLoading) {
            return <Spinner />;
        }

        if (formattedPieData.length === 0) {
            return <div className={styles.noDataMessage}>{`${selectedThirdPartyName ? `${selectedThirdPartyName} is not currently part of a Supply Chain in any capacity` : 'Click a bar to show more information'}`}</div>;
        }

        return (
            <>
                <PieChart
                    data={formattedPieData}
                    testId={testId}
                    id={id}
                    diameter={diameter}
                    includeLegend
                    tooltipContent={tooltipContent}
                    tooltipWidth={tooltipWidth}
                    fullOpacity
                    rawData
                />
                <div className={styles.tableWrapper}>
                    <ThirdPartyFunctionCompanyTable tableWidth={tableWidth} />
                </div>
            </>
        );
    }, [diameter, formattedPieData, id, tableWidth, selectedThirdPartyName, testId, isLoading]);

    return (
        <div className={styles.pieChartWrapper} data-testid={`pie-chart-wrapper-${testId}`}>
            <div className={styles.pieChartHeader}>
                {selectedThirdPartyName.length ? <div className={styles.pieChartTitle} data-testid={`pie-chart-title-${testId}`}>{selectedThirdPartyName} Breakdown</div> : null}
            </div>
            {body}
        </div>
    );
};
