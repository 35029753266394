import { max, min } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { PieChart, PieChartAnalyticsData } from '../../../../shared/analytics/PieChart';
import styles from '../../Analytics.module.scss';
import { getContractualDataAnalyticsPieData, OutstandingContractualInformationPieCategories, setSelectedOutstandingContractualDataSection } from '../../store';

interface ContractualInformationPieProps {
    dimensions?: {
        height: number;
        width: number;
    }
    id?: string;
    testId?: string;
}

const { red, green, amber } = styles;

const getColor = (key: string) => {
    let color = amber;
    if (key === OutstandingContractualInformationPieCategories.COMPLETE) color = green;
    if (key === OutstandingContractualInformationPieCategories.MISSING_BOTH) color = red;
    return color;
};

export const ContractualInformationPie: React.FC<ContractualInformationPieProps> = ({ testId, id, dimensions }) => {
    const dispatch = useAppDispatch();

    const contractualData = useAppSelector(getContractualDataAnalyticsPieData);

    const setSelectedContractualInformation = useCallback((section: string) => dispatch(setSelectedOutstandingContractualDataSection(section)), [dispatch]);

    const handleDoubleClick = useCallback((section: string) => setSelectedContractualInformation(section), [setSelectedContractualInformation]);

    const pieData = useMemo(() => contractualData.map(item => ({ ...item, color: getColor(item.key) })), [contractualData]);

    const diameter = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 200;
            const svgHeight = dimensions.height - 400;
            return min([svgWidth, svgHeight])!;
        }
        return 0;
    }, [dimensions]);

    const tooltipContent = ({ value }: PieChartAnalyticsData) => `<tspan dy=12 x=10>Total: ${value}</tspan>`;
    const tooltipWidth = (key: string, value: number) => max([(`Total: ${value}`.length * 7), 10])!;

    if (pieData.length === 0) {
        return <div className={styles.noDataMessage}>No companies found with material impact upon supply chains conducted by your groups</div>;
    }

    return (
        <PieChart
            data={pieData}
            testId={testId}
            id={id}
            diameter={diameter}
            includeLegend
            tooltipContent={tooltipContent}
            tooltipWidth={tooltipWidth}
            fullOpacity
            rawData
            onDblClick={handleDoubleClick}
        />
    );
};
