import React from 'react';
import classnames from 'classnames';

import styles from './Button.module.scss';
import { Icon as ArkIcon } from '../icon/Icon';
import { TooltipList } from '../tooltip';
import { Spinner } from '../spinner/Spinner';

const { white } = styles;

interface ButtonProps {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    icon?: React.FC;
    fontSize?: number;
    color?: string;
    selected?: boolean;
    testId?: string;
    disabledTooltip?: string[] | null;
    width?: string;
    height?: string;
    renderIconRight?: boolean;
    showSpinner?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
    label = '',
    onClick,
    disabled = false,
    fontSize,
    icon,
    color,
    selected = false,
    testId = '',
    disabledTooltip = null,
    width = '150px',
    height,
    renderIconRight = false,
    showSpinner
}) => (
    <TooltipList overlayText={disabledTooltip} color='disabled'>
        <div className={styles.buttonWrapper}>
            <button
                onClick={onClick}
                className={classnames(styles.button, {
                    [styles.selectedButton]: selected
                })}
                disabled={disabled}
                data-testid={`button-${testId}`}
                style={{ width, height }}
            >
                {showSpinner ? <Spinner size={14} color={white} /> :
                    <div className={styles.content} data-testid={`button-${testId}-label`}>
                        {icon && !renderIconRight && <ArkIcon icon={icon} fontSize={fontSize} color={color} />}
                        <div style={{ marginLeft: icon && !renderIconRight ? '5px' : '0', marginRight: icon && renderIconRight ? '5px' : '0', width: icon ? `calc(100% - ${fontSize}px)` : '100%' }}>{label}</div>
                        {icon && renderIconRight && <ArkIcon icon={icon} fontSize={fontSize} color={color} />}
                    </div>
                }
            </button>
        </div>
    </TooltipList>
);
