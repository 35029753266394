import { getOr, isNull } from 'lodash/fp';
import React from 'react';
import Modal from 'react-modal';

import { useAppSelector } from '../../../hooks/react-redux';
import { Button } from '../../shared/button/Button';
import { Spinner } from '../../shared/spinner/Spinner';
import { DocumentPreviewViewer } from '../document-viewer/DocumentPreviewViewer';
import { getDocumentPreviewResult, getDocumentPreviewUrl, getIsOpening, SearchResult } from './store';
import styles from './SearchDocuments.module.scss';

interface DocumentPreviewModalProps {
    isOpen: boolean;
    closeModal: () => void;
    openDocument: (documentId: string, datasetInstanceId: string) => void;
    completeDocument: (documentId: number) => void;
}

export const DocumentPreviewModal: React.FC<DocumentPreviewModalProps> = ({ isOpen, closeModal, openDocument, completeDocument }) => {
    const isOpeningDocument = useAppSelector(getIsOpening);
    const previewUrl = useAppSelector(getDocumentPreviewUrl);
    const previewResult = useAppSelector(getDocumentPreviewResult);
    const getDocumentIdToOpen = ({ originalDocumentId, documentId }: SearchResult) => !isNull(originalDocumentId) ? originalDocumentId.toString() : documentId.toString();

    // Dataset instances are created for documents once they are completed
    const incompleteDocument = previewResult && !previewResult.datasetInstanceId;
    const openLabel = incompleteDocument ? 'Complete' : 'Open';

    const openSelectedPreview = () => {
        if (previewResult) {
            return incompleteDocument ? completeDocument(previewResult.documentId) : openDocument(getDocumentIdToOpen(previewResult), previewResult.datasetInstanceId!.toString());
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            className={styles.documentPreviewModal}
            ariaHideApp={false}
            style={{ overlay: { display: 'flex' } }}
            shouldCloseOnOverlayClick={!isOpeningDocument}
            shouldCloseOnEsc={!isOpeningDocument}
            onRequestClose={closeModal}
        >
            <div className={styles.documentPreviewWrapper}>
                <div className={styles.documentPreview}>
                    {isOpeningDocument ? <Spinner size={70} /> : (
                        <DocumentPreviewViewer url={previewUrl} mimeType={getOr(null, 'mimeType', previewResult)} />
                    )}
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' disabled={isOpeningDocument} testId='document-preview-close' />
                    <Button onClick={openSelectedPreview} label={openLabel} disabled={isOpeningDocument || !previewResult} testId='document-preview-open' />
                </div>
            </div>
        </Modal>
    );
};
