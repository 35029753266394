import { getOr, isEqual, isNull } from 'lodash/fp';
import React, { useCallback, useMemo, MouseEvent } from 'react';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { HiddenFields } from '../../../admin/my-datasets/store';
import { getIsAgencyDocument } from '../../../documents/my-documents/store';
import { Button } from '../../../shared/button/Button';
import { Icon } from '../../../shared/icon/Icon';
import { ArrowRight, CaretDown, CaretSide, Settings } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { InformationTooltip } from '../../../shared/tooltip';
import { GroupField } from '../../fields/GroupField';
import { MultiToggle } from '../../fields/MultiToggleField';
import { SingleField } from '../../fields/SingleField';
import { DatasetFieldType, DatasetSection } from '../../store';
import {
    DatasetInstanceSection,
    FormDatasetInstance,
    InstanceField,
    OpenFieldSection,
    OpenFieldType,
    OpenSection,
    SingleInstanceField,
    UpdatedFormField,
    addOpenFieldSection,
    duplicateECSTable,
    generateNewECSTableStarted,
    getAnnexDefinitionFieldIds,
    getECSDatasetId,
    getInstanceUpdated,
    getOpenFieldsAndSections,
    getSearchFieldSection,
    removeOpenFieldSection,
    scrollToSection,
    toggleAnnexConfigurationModal
} from '../store';
import { isGroupInstanceField } from '../store/typeAssertions';
import styles from '../../../shared/datasets/SharedStyling.module.scss';
import { IconButton } from '../../../shared/button/IconButton';
import { ExtractedAnnexData } from '../../store/mlTypes';

const { mandatory } = styles;

interface FormInstanceProps {
    datasetInstance: FormDatasetInstance;
    isEditing: boolean;
    isUpdating: boolean;
    parentFieldId: string;
    hiddenFields: HiddenFields;
    instanceExecutedDateMatchesParent: boolean;
    modalInstance?: boolean;
    datasetHidden?: boolean;
    isMLCorrection?: boolean;
    extractedAnnexData?: ExtractedAnnexData[];
}

interface SectionProps {
    section: DatasetSection | DatasetInstanceSection;
    datasetInstance: FormDatasetInstance;
    sectionIndex: number;
    isEditing: boolean;
    isUpdating: boolean;
    isAnnexInstance: boolean;
    annexFieldIds: string[];
    datasetId: number;
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    isAgencyECS?: boolean;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    isMLCorrection?: boolean;
    extractedAnnexData?: ExtractedAnnexData[];
}

interface FieldsProps {
    fields: InstanceField[];
    fieldsUpdated: UpdatedFormField[];
    sectionId: string;
    isEditing: boolean;
    isUpdating: boolean;
    isAnnexInstance: boolean;
    annexFieldIds: string[];
    datasetOnlySection?: boolean;
    datasetId: number;
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    modalInstance?: boolean;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
    isAgencyECS?: boolean;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    isMLCorrection?: boolean;
}

export const Fields: React.FC<FieldsProps> = ({
    fields,
    sectionId,
    isEditing,
    isUpdating,
    fieldsUpdated,
    annexFieldIds,
    isAnnexInstance,
    datasetOnlySection = false,
    datasetId,
    parentFieldId,
    instanceExecutedDateMatchesParent,
    modalInstance,
    toggleSection,
    getSectionOpen,
    isAgencyECS = false,
    hiddenFields,
    datasetHidden,
    isMLCorrection
}) => {
    const getContent = useCallback((field: InstanceField, index: number) => {
        const isLastFieldInSection = index === fields.length - 1;
        if (isGroupInstanceField(field)) {
            if (field.type === DatasetFieldType.GROUP) {
                return (
                    <GroupField
                        field={field}
                        key={field.id}
                        groupIndex={index}
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        annexFieldIds={annexFieldIds}
                        isAnnexInstance={isAnnexInstance}
                        datasetId={datasetId}
                        parentFieldId={parentFieldId}
                        instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                        modalInstance={modalInstance}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        isLastFieldInSection={isLastFieldInSection}
                        isMLCorrection={isMLCorrection}
                    />
                );
            }
            if (field.type === DatasetFieldType.MULTI_TOGGLE) {
                return (
                    <MultiToggle
                        key={field.id}
                        field={field}
                        groupIndex={index}
                        sectionId={sectionId}
                        isEditing={isEditing}
                        isUpdating={isUpdating}
                        fieldsUpdated={fieldsUpdated}
                        annexFieldIds={annexFieldIds}
                        isAnnexInstance={isAnnexInstance}
                        datasetId={datasetId}
                        parentFieldId={parentFieldId}
                        instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                        modalInstance={modalInstance}
                        toggleSection={toggleSection}
                        getSectionOpen={getSectionOpen}
                        hiddenFields={hiddenFields}
                        datasetHidden={datasetHidden}
                        isMLCorrection={isMLCorrection}
                    />
                );
            }
        }
        return (
            <SingleField
                key={field.id}
                field={field as SingleInstanceField}
                index={index}
                sectionId={sectionId}
                isEditing={isEditing}
                isUpdating={isUpdating}
                fieldsUpdated={fieldsUpdated}
                annexFieldIds={annexFieldIds}
                isAnnexInstance={isAnnexInstance}
                datasetOnlySection={datasetOnlySection}
                datasetId={datasetId}
                parentFieldId={parentFieldId}
                instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                modalInstance={modalInstance}
                toggleSection={toggleSection}
                getSectionOpen={getSectionOpen}
                isAgencyECS={isAgencyECS}
                hiddenFields={hiddenFields}
                datasetHidden={datasetHidden}
                isLastFieldInSection={isLastFieldInSection}
                isMLCorrection={isMLCorrection}
            />
        );
    }, [
        sectionId,
        isEditing,
        isUpdating,
        fieldsUpdated,
        annexFieldIds,
        isAnnexInstance,
        datasetOnlySection,
        datasetId,
        parentFieldId,
        instanceExecutedDateMatchesParent,
        modalInstance,
        toggleSection,
        getSectionOpen,
        isAgencyECS,
        hiddenFields,
        datasetHidden,
        fields.length,
        isMLCorrection
    ]);

    return (
        <div className={styles.allFieldsWrapper}>
            {fields && fields.map((field, index) => getContent(field, index))}
        </div>
    );
};

export const Section: React.FC<SectionProps> = ({
    section,
    datasetInstance,
    sectionIndex,
    isEditing,
    isUpdating,
    annexFieldIds,
    isAnnexInstance,
    datasetId,
    parentFieldId,
    instanceExecutedDateMatchesParent,
    modalInstance,
    getSectionOpen,
    toggleSection,
    isAgencyECS = false,
    hiddenFields,
    datasetHidden,
    isMLCorrection
}) => {
    const dispatch = useAppDispatch();
    const instanceUpdated = useAppSelector(getInstanceUpdated);
    const searchFieldSection = useAppSelector(getSearchFieldSection);
    const { id, label, description } = section;
    const openSection: OpenSection = useMemo(() => ({ sectionId: id, datasetId: datasetId.toString(), type: OpenFieldType.SECTION, parentFieldId }), [id, datasetId, parentFieldId]);
    const sectionOpen = getSectionOpen(openSection);
    const fields = datasetInstance.datasetFields[id];
    const datasetOnlySection = fields.length === 1 && fields[0].type === DatasetFieldType.DATASET;
    const sectionOpenIcon = sectionOpen ? CaretDown : CaretSide;
    const { fieldsUpdated } = datasetInstance;
    const showAnnexConfig = useMemo(() => id.includes('annex-section') && (isEditing || isUpdating) && !instanceUpdated && !isMLCorrection, [id, isEditing, isUpdating, instanceUpdated, isMLCorrection]);
    const pageRef: string | null = useMemo(() => getOr(null, 'pageRef', section), [section]);

    const scrollToSectionPage = useCallback(() => {
        if (!isNull(pageRef)) {
            dispatch(scrollToSection(section as DatasetInstanceSection));
        }
    }, [pageRef, section, dispatch]);

    const isSearchedSection = useMemo(() => !isNull(searchFieldSection) && searchFieldSection.type === OpenFieldType.SECTION && searchFieldSection.sectionId === id, [searchFieldSection, id]);

    const openAnnexConfigurationModal = useCallback(() => showAnnexConfig && dispatch(toggleAnnexConfigurationModal(true)), [showAnnexConfig, dispatch]);

    const onSectionClick = useCallback(() => {
        if (!sectionOpen) {
            scrollToSectionPage();
        }
        toggleSection(openSection);
    }, [sectionOpen, scrollToSectionPage, toggleSection, openSection]);

    const onRedirectClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        scrollToSectionPage();
    }, [scrollToSectionPage]);

    const configureAnnexDefinition = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        openAnnexConfigurationModal();
    }, [openAnnexConfigurationModal]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields && hiddenFields[datasetId] || [], [datasetId, hiddenFields]);
    const sectionHidden = useMemo(() => !!currentHiddenDatasetFields.filter(({ type }) => type === OpenFieldType.SECTION).find(({ sectionId }) => sectionId === id), [currentHiddenDatasetFields, id]);

    if (sectionHidden) {
        return null;
    }

    return (
        <div key={id} className={classnames(styles.sectionWrapper, { [styles.searchedSectionWrapper]: isSearchedSection })} data-testid={`form-section-${sectionIndex}-wrapper`} id={`dataset-instance-section-${id}`}>
            <div className={styles.sectionHeader} onClick={onSectionClick} data-testid={`form-section-${sectionIndex}-collapse`}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={sectionOpenIcon} fontSize={18} />
                </div>
                <div className={styles.sectionLabel} data-testid={`form-section-${sectionIndex}-label`}>{label}</div>
                {description && <div className={styles.sectionDescription}><InformationTooltip content={description} /></div>}
                {showAnnexConfig && <div className={styles.configureAnnexDefinitionWrapper}><IconButton icon={Settings} onClick={configureAnnexDefinition} fontSize={22} /></div>}
                {!isNull(pageRef) && <div className={styles.redirectWrapper}><IconButton icon={ArrowRight} onClick={onRedirectClick} fontSize={16} color={mandatory} /></div>}
            </div>
            {sectionOpen &&
                <Fields
                    fields={fields}
                    sectionId={id}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    annexFieldIds={annexFieldIds}
                    isAnnexInstance={isAnnexInstance}
                    datasetOnlySection={datasetOnlySection}
                    datasetId={datasetId}
                    parentFieldId={parentFieldId}
                    instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                    modalInstance={modalInstance}
                    toggleSection={toggleSection}
                    getSectionOpen={getSectionOpen}
                    isAgencyECS={isAgencyECS}
                    hiddenFields={hiddenFields}
                    datasetHidden={datasetHidden}
                    isMLCorrection={isMLCorrection}
                />
            }
        </div>
    );
};

export const FormInstance: React.FC<FormInstanceProps> = ({ datasetInstance, isEditing, isUpdating, hiddenFields, modalInstance, parentFieldId, datasetHidden, instanceExecutedDateMatchesParent, isMLCorrection, extractedAnnexData = [] }) => {
    const dispatch = useAppDispatch();
    const annexFieldIds = useAppSelector(getAnnexDefinitionFieldIds);
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const ecsDatasetId = useAppSelector(getECSDatasetId);
    const isAgencyDocument = useAppSelector(getIsAgencyDocument);

    const generateNewECSTable = useCallback(() => dispatch(generateNewECSTableStarted()), [dispatch]);

    const firstSection = useMemo(() => datasetInstance.datasetSections[0], [datasetInstance.datasetSections]);
    const isShortFormInstance = useMemo(() => datasetInstance.datasetSections.length === 1 && !firstSection.label, [datasetInstance.datasetSections, firstSection]);
    const shortFormFields = useMemo(() => datasetInstance.datasetFields[firstSection.id], [datasetInstance.datasetFields, firstSection]);
    const datasetOnlySection = useMemo(() => shortFormFields.length === 1 && shortFormFields[0].type === DatasetFieldType.DATASET, [shortFormFields]);
    const datasetId = useMemo(() => isNull(datasetInstance.annexDefinitionId) ? datasetInstance.datasetId! : datasetInstance.annexDefinitionId!, [datasetInstance]);

    const getSectionOpen = useCallback((fieldSection: OpenFieldSection) => openFieldsAndSections.some(openSection => isEqual(openSection, fieldSection)), [openFieldsAndSections]);

    const toggleSection = useCallback((fieldSection: OpenFieldSection) => {
        const sectionOpen = openFieldsAndSections.find(openSection => isEqual(openSection, fieldSection));
        sectionOpen ? dispatch(removeOpenFieldSection(sectionOpen)) : dispatch(addOpenFieldSection(fieldSection));
    }, [dispatch, openFieldsAndSections]);

    const isAgencyECS = useMemo(() => !isNull(datasetInstance.datasetId) && !isNull(ecsDatasetId) && datasetInstance.datasetId === ecsDatasetId && isAgencyDocument, [datasetInstance.datasetId, ecsDatasetId, isAgencyDocument]);
    const showAddECSButton = useMemo(() => isAgencyECS && (isEditing || isUpdating), [isAgencyECS, isEditing, isUpdating]);
    const showDuplicateECSButton = useMemo(() => showAddECSButton && Object.values(datasetInstance.datasetFields)[0].length === 1, [datasetInstance.datasetFields, showAddECSButton]);
    const duplicateSingleECSTable = useCallback(() => {
        const [sectionId, fields] = Object.entries(datasetInstance.datasetFields)[0];
        const { id, settings: { datasetLinked } } = fields[0] as SingleInstanceField;
        dispatch(duplicateECSTable(datasetId, parentFieldId, sectionId, id!, datasetLinked!));
    }, [dispatch, datasetId, parentFieldId, datasetInstance.datasetFields]);

    return (
        <div className={styles.formInstanceWrapper} data-testid='form-dataset-instance-wrapper'>
            <div style={{ height: showAddECSButton ? 'calc(100% - 40px)' : '100%' }}>
                <Scrollable>
                    {isShortFormInstance ? (
                        <Fields
                            fields={datasetInstance.datasetFields[firstSection.id]}
                            isEditing={isEditing}
                            sectionId={firstSection.id}
                            isUpdating={isUpdating}
                            fieldsUpdated={datasetInstance.fieldsUpdated}
                            annexFieldIds={annexFieldIds}
                            isAnnexInstance={!!datasetInstance.annexDefinitionId}
                            datasetOnlySection={datasetOnlySection}
                            datasetId={datasetId}
                            modalInstance={modalInstance}
                            toggleSection={toggleSection}
                            getSectionOpen={getSectionOpen}
                            parentFieldId={parentFieldId}
                            isAgencyECS={isAgencyECS}
                            hiddenFields={hiddenFields}
                            datasetHidden={datasetHidden}
                            instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                            isMLCorrection={isMLCorrection}
                        />
                    ) : (datasetInstance.datasetSections.map((section, index) => (
                        <Section
                            section={section}
                            key={section.id}
                            datasetInstance={datasetInstance}
                            sectionIndex={index}
                            isEditing={isEditing}
                            isUpdating={isUpdating}
                            annexFieldIds={annexFieldIds}
                            isAnnexInstance={!!datasetInstance.annexDefinitionId}
                            datasetId={datasetId}
                            modalInstance={modalInstance}
                            toggleSection={toggleSection}
                            getSectionOpen={getSectionOpen}
                            parentFieldId={parentFieldId}
                            isAgencyECS={isAgencyECS}
                            hiddenFields={hiddenFields}
                            datasetHidden={datasetHidden}
                            instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                            isMLCorrection={isMLCorrection}
                            extractedAnnexData={extractedAnnexData}
                        />
                    )))}
                </Scrollable>
                {showAddECSButton &&
                    <div className={styles.ecsButtonsWrapper}>
                        <div className={styles.addECSButtonWrapper}>
                            <Button label='Add Table' onClick={generateNewECSTable} />
                        </div>
                        {showDuplicateECSButton && <Button label='Duplicate Table' onClick={duplicateSingleECSTable} />}
                    </div>
                }
            </div>
        </div>
    );
};
