import { Reducer } from 'redux';
import { set, flow, unset } from 'lodash/fp';

import { UserAttestationState, UserAttestationActionTypes, UserAttestationInstanceDB, UserAttestationInstance } from './types';
import { LoginActionTypes } from '../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../constants/arkTable';

const stripUserAttestationInstance = (instance: UserAttestationInstanceDB): UserAttestationInstance => flow(
    unset('userId'),
    unset('createdDate'),
    unset('createdBy'),
    unset('modifiedDate'),
    unset('modifiedBy'),
    unset('completedDate'),
    unset('completedBy'),
    unset('isArchived'),
    unset('clientId')
)(instance);

export const INITIAL_STATE: UserAttestationState = {
    isLoading: false,
    completed: false,
    attestationInstances: [],
    selectedUserAttestationInstance: null,
    userAttestationInstanceModalOpen: false,
    attestationUpdated: false,
    isSaving: false,
    saveError: null,
    alertModalOpen: null,
    attestationPage: 1,
    totalAttestations: 0,
    attestationFilters: {},
    attestationPageSize: DEFAULT_PAGE_SIZE,
    attestationSort: undefined
};

export const userAttestationReducer: Reducer<UserAttestationState> = (state = INITIAL_STATE, { type, payload }): UserAttestationState => {
    switch (type) {
        case UserAttestationActionTypes.TOGGLE_USER_ATTESTATION_INSTANCE_VIEW:
            return flow(
                set('completed', payload.completed),
                set('attestationPage', 1),
                set('totalAttestations', 0),
                set('attestationFilters', {}),
                set('attestationPageSize', DEFAULT_PAGE_SIZE),
                set('attestationSort', undefined)
            )(state);
        case UserAttestationActionTypes.FETCH_ALL_USER_ATTESTATION_INSTANCES_STARTED:
            return set('isLoading', true, state);
        case UserAttestationActionTypes.FETCH_ALL_USER_ATTESTATION_INSTANCES_SUCCESSFUL:
            return flow(
                set('isLoading', false),
                set('attestationInstances', payload.attestationInstances),
                set('totalAttestations', payload.total || state.totalAttestations),
                set('attestationPage', payload.pageNumber || state.attestationPage)
            )(state);
        case UserAttestationActionTypes.FETCH_ALL_USER_ATTESTATION_INSTANCES_FAILED:
            return set('isLoading', false, state);
        case UserAttestationActionTypes.TOGGLE_USER_ATTESTATION_INSTANCE_MODAL_OPEN: {
            const { isOpen, attestationInstance } = payload;
            const strippedInstance = stripUserAttestationInstance(attestationInstance);
            const selectedAttestationInstance = isOpen ? strippedInstance : null;
            return flow(
                set('selectedUserAttestationInstance', selectedAttestationInstance),
                set('userAttestationInstanceModalOpen', isOpen),
                set('saveError', null)
            )(state);
        }
        case UserAttestationActionTypes.UPDATE_ATTESTATION_ANSWER_VALUE: {
            const { id, answer } = payload;
            const updatedAttestations = state.selectedUserAttestationInstance?.attestations.map(attestation =>
                attestation.id === id ? set('answer', answer, attestation) : attestation
            );
            return set('selectedUserAttestationInstance.attestations', updatedAttestations, state);
        }
        case UserAttestationActionTypes.UPDATE_ATTESTATION_USER_NOTES: {
            const { id, notes } = payload;
            const updatedAttestations = state.selectedUserAttestationInstance?.attestations.map(attestation =>
                attestation.id === id ? set('userNotes', notes, attestation) : attestation
            );
            return set('selectedUserAttestationInstance.attestations', updatedAttestations, state);
        }
        case UserAttestationActionTypes.SET_USER_ATTESTATION_INSTANCE_UPDATED:
            return set('attestationUpdated', payload, state);
        case UserAttestationActionTypes.SAVE_USER_ATTESTATION_INSTANCE_STARTED:
            return flow(
                set('saveError', null),
                set('isSaving', true)
            )(state);
        case UserAttestationActionTypes.SAVE_USER_ATTESTATION_INSTANCE_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('attestationInstances', payload.attestationInstances),
                set('totalAttestations', payload.total || state.totalAttestations),
                set('userAttestationInstanceModalOpen', false),
                set('selectedUserAttestationInstance', null)
            )(state);
        case UserAttestationActionTypes.SAVE_USER_ATTESTATION_INSTANCE_FAILED:
            return flow(
                set('isSaving', false),
                set('saveError', payload)
            )(state);
        case UserAttestationActionTypes.TOGGLE_ATTESTATION_ALERT_MODAL_OPEN:
            return set('alertModalOpen', payload, state);
        case UserAttestationActionTypes.SET_ATTESTATION_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`attestationFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`attestationFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case UserAttestationActionTypes.CLEAR_ATTESTATION_TABLE_FILTERS:
            return set('attestationFilters', {}, state);
        case UserAttestationActionTypes.SET_ATTESTATION_TABLE_COLUMN_SORT:
            return set('attestationSort', payload, state);
        case UserAttestationActionTypes.SET_ATTESTATIONS_PAGE_SIZE:
            return set('attestationPageSize', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
