import React, { useCallback, useMemo, useState } from 'react';

import { Icon } from '../../shared/icon/Icon';
import { Document, SaveFile } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Toggle } from '../../shared/toggle';
import styles from './OpinionInstance.module.scss';
import { ArkOpinion } from '../my-opinions/store';
import { useAppSelector } from '../../../hooks/react-redux';
import { getInstanceIncludesUnverifiedPageRefs } from './store';

interface ConfirmSaveModalProps {
    isOpen: boolean;
    isUpdating: boolean;
    save: (isDraft: boolean) => void;
    cancel: () => void;
    description: string;
    opinion: ArkOpinion;
}

interface IncompleteInstanceProps {
    description: string;
    instanceComplete: boolean;
    setInstanceComplete: (value: boolean) => void;
    isIndustryStandard: boolean;
    includesUnverifiedPageRefs: boolean;
}

const IncompleteInstance: React.FC<IncompleteInstanceProps> = ({ description, instanceComplete, setInstanceComplete, isIndustryStandard, includesUnverifiedPageRefs }) => (
    <div className={styles.incompleteInstanceWrapper}>
        <div className={styles.newDocumentWrapper}>
            <div className={styles.newDocumentHeader}>
                <Icon icon={Document} />
                <div className={styles.newDocumentTitle}>{description}</div>
            </div>
        </div>
        <div className={styles.completeWarningWrapper}>
            <div className={styles.completeWarningTitle} data-testid='complete-save-warning'>Is this dataset ready to publish?</div>
            <Toggle checked={instanceComplete} onChange={setInstanceComplete} />
        </div>
        {isIndustryStandard && <div className={styles.completeMessage}>Once published, this will be visible to all clients that are subscribed to this opinion.</div>}
        {includesUnverifiedPageRefs && <div className={styles.completeWarningMessage}>- There are some page references that have duplicated from a previous opinion and have not been verified.</div>}
        {includesUnverifiedPageRefs && <div className={styles.completeWarningMessage}>- By publishing you are asserting that you are happy to maintain these page references.</div>}
        <div className={styles.completeWarningMessage}>- If you are unsure, please leave unchecked</div>
    </div>
);

const UpdatedInstance: React.FC = () => (
    <div className={styles.updatedInstanceWrapper}>
        <div data-testid='updated-instance-save-warning' className={styles.updatedInstanceWarning}>Are you happy with the updated changes you have made?</div>
        <div className={styles.updatedInstanceInfo}>These changes will also be applied to any future amendments</div>
    </div>
);

export const ConfirmSaveModal: React.FC<ConfirmSaveModalProps> = ({ isOpen, save, cancel, isUpdating, description, opinion }) => {
    const [instanceComplete, setInstanceComplete] = useState<boolean>(false);
    const saveAsDraft = useMemo(() => !(isUpdating || instanceComplete), [isUpdating, instanceComplete]);
    const saveInstance = useCallback(() => save(saveAsDraft), [saveAsDraft, save]);
    const isIndustryStandard = opinion.bespoke === 0;
    const includesUnverifiedPageRefs = useAppSelector(getInstanceIncludesUnverifiedPageRefs);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            confirm={saveInstance}
            closeModal={cancel}
            confirmLabel={instanceComplete ? 'Save & Publish' : 'Save'}
            closeLabel='Cancel'
        >
            <div className={styles.confirmSaveModalWrapper}>
                <ModalHeader
                    testId='confirm-save'
                    label='Are you sure you want to save?'
                    icon={SaveFile}
                />
                {isUpdating ? <UpdatedInstance /> :
                    <IncompleteInstance
                        description={description}
                        instanceComplete={instanceComplete}
                        setInstanceComplete={setInstanceComplete}
                        isIndustryStandard={isIndustryStandard}
                        includesUnverifiedPageRefs={includesUnverifiedPageRefs}
                    />}
            </div>
        </ConfirmationModal>
    );
};
