import React, { useEffect, useState } from 'react';

import { Position } from '../../../shared/modal/PositionModal';
import sharedStyles from '../SharedBuilder.module.scss';
import { SystemIdModal } from './SystemIdModal';

interface SystemIdProps {
    modalOpen: boolean;
    testId: string;
    systemId: string;
    updateSystemId: (value: string) => void;
    toggleSystemIdModalOpen: (value: boolean) => void;
    disableInputs?: boolean;
}

export const SystemId: React.FC<SystemIdProps> = ({ modalOpen, testId, systemId, updateSystemId, toggleSystemIdModalOpen, disableInputs = false }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);
    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        setIsOpen(true);
        toggleSystemIdModalOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        toggleSystemIdModalOpen(false);
    };

    useEffect(() => {
        if (modalOpen) {
            setIsOpen(modalOpen);
        }
    }, [modalOpen]);

    return (
        <>
            <button className={sharedStyles.systemIdButton} onClick={e => openModal(e.clientX, e.clientY)} data-testid={`${testId}-button`}>ID</button>
            <SystemIdModal
                isOpen={isOpen}
                closeModal={closeModal}
                position={position}
                updateSystemId={updateSystemId}
                systemId={systemId}
                disableInputs={disableInputs}
            />
        </>
    );
};
