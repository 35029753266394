import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Button } from '../../shared/button/Button';
import { RiskTolerance } from '../../shared/risk-tolerance/RiskTolerance';
import { fetchAllDropdownListsStarted } from '../dropdown-lists/store';
import styles from './RiskTolerance.module.scss';
import { RiskAssociated, RiskAssociatedValue, SelectedRiskField, fetchAllRiskToleranceStarted, fetchRiskToleranceByDatasetIdStarted, getAllRiskToleranceDatasets, getCurrentRiskField, getDatasetDefinitions, getIsLoading, getIsSaving, getRiskToleranceConfig, getRiskToleranceHasUpdated, getSelectedRiskField, getUnsavedChangesModalOpen, goBackToAllRiskToleranceDatasets, saveRiskToleranceStarted, setCurrentRiskToleranceField, toggleUnsavedChangesModal, updateRiskFieldConfig, updateRiskFieldWeighting, getDocumentHiddenFields, getSelectedRiskToleranceDatasetId } from './store';
import { UnsavedChangesModal } from '../../shared/modal/UnsavedChangesModal';
import { getAllDocumentNames } from '../documents/store';

interface RiskToleranceDatasetRouteParams {
    datasetId: string;
}

export const RiskToleranceDataset: React.FC<RouteComponentProps<RiskToleranceDatasetRouteParams>> = ({ match: { params: { datasetId } } }) => {
    const dispatch = useAppDispatch();
    const riskTolerance = useAppSelector(getRiskToleranceConfig);
    const selectedRiskField = useAppSelector(getCurrentRiskField);
    const selectedField = useAppSelector(getSelectedRiskField);
    const isLoading = useAppSelector(getIsLoading);
    const datasetDefinitions = useAppSelector(getDatasetDefinitions);
    const documentHiddenFields = useAppSelector(getDocumentHiddenFields);
    const riskToleranceHasUpdated = useAppSelector(getRiskToleranceHasUpdated);
    const unsavedChangesModalOpen = useAppSelector(getUnsavedChangesModalOpen);
    const isSaving = useAppSelector(getIsSaving);
    const allDocumentNames = useAppSelector(getAllDocumentNames);
    const agreementTypeDatasetId = useAppSelector(getSelectedRiskToleranceDatasetId);

    const allRiskToleranceDatasets = useAppSelector(getAllRiskToleranceDatasets);

    const selectRiskField = useCallback((riskField: SelectedRiskField) => { dispatch(setCurrentRiskToleranceField(riskField)); }, [dispatch]);

    const setWeighting = useCallback((weighting: number) => dispatch(updateRiskFieldWeighting(weighting)), [dispatch]);
    const updateRiskConfig = useCallback((riskAssociated: RiskAssociated, value: RiskAssociatedValue, selectedCurrency?: string) => dispatch(updateRiskFieldConfig(riskAssociated, value, selectedCurrency)), [dispatch]);

    const saveDisabled = useMemo(() => !riskToleranceHasUpdated || isSaving, [riskToleranceHasUpdated, isSaving]);

    const save = useCallback(() => dispatch(saveRiskToleranceStarted(parseInt(datasetId))), [dispatch, datasetId]);
    const goBack = useCallback(() => dispatch(goBackToAllRiskToleranceDatasets()), [dispatch]);
    const toggleUnsavedChangesModalOpen = useCallback((isOpen: boolean) => dispatch(toggleUnsavedChangesModal(isOpen)), [dispatch]);
    const onBackClick = useCallback(() => riskToleranceHasUpdated ? toggleUnsavedChangesModalOpen(true) : goBack(), [riskToleranceHasUpdated, toggleUnsavedChangesModalOpen, goBack]);

    const buttonWrapper = useMemo(() => (
        <div className={styles.buttonWrapper}>
            <Button label='Back' onClick={onBackClick} />
            <Button label='Save' disabled={saveDisabled} onClick={save} />
        </div>
    ), [onBackClick, saveDisabled, save]);

    useEffect(() => {
        if (allRiskToleranceDatasets.length === 0) {
            dispatch(fetchAllRiskToleranceStarted());
            dispatch(fetchAllDropdownListsStarted());
        }
        if (isNull(riskTolerance)) {
            dispatch(fetchRiskToleranceByDatasetIdStarted(parseInt(datasetId)));
        }
    }, [dispatch, datasetId, allRiskToleranceDatasets, riskTolerance]);

    useEffect(() => () => {
        dispatch(setCurrentRiskToleranceField(null));
    }, [dispatch]);

    return (
        <>
            <RiskTolerance
                allRiskToleranceDatasets={allRiskToleranceDatasets}
                buttonWrapper={buttonWrapper}
                datasetDefinitions={datasetDefinitions}
                datasetId={parseInt(datasetId)}
                isLoading={isLoading}
                riskTolerance={riskTolerance}
                selectedRiskField={selectedRiskField}
                selectedField={selectedField}
                setRiskToleranceField={selectRiskField}
                setWeighting={setWeighting}
                updateRiskConfig={updateRiskConfig}
                documentHiddenFields={documentHiddenFields}
                allDocumentNames={allDocumentNames}
                agreementTypeDatasetId={agreementTypeDatasetId}
            />
            <UnsavedChangesModal isOpen={unsavedChangesModalOpen} closeModal={() => toggleUnsavedChangesModalOpen(false)} confirm={goBack} />
        </>
    );
};
