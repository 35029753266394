import { RawDraftContentState } from 'draft-js';

import { SuggestedChanges } from '../../../playbook/store';

export enum AdminPlaybookActionTypes {
    SELECT_PLAYBOOK_PAGE = 'SELECT_PLAYBOOK_PAGE',
    SELECT_BUILD_PAGE = 'SELECT_BUILD_PAGE',
    SELECT_CREATE_PLAYBOOK_TAB = 'SELECT_CREATE_PLAYBOOK_TAB',
    UPDATE_PLAYBOOK_VALUE = 'UPDATE_PLAYBOOK_VALUE',
    UPDATE_PLAYBOOK_CONTENT_VALUE = 'UPDATE_PLAYBOOK_CONTENT_VALUE',
    UPDATE_PLAYBOOK_TAGS = 'UPDATE_PLAYBOOK_TAGS',
    ADD_PLAYBOOK_SECTION = 'ADD_PLAYBOOK_SECTION',
    ADD_PLAYBOOK_SUBHEADER = 'ADD_PLAYBOOK_SUBHEADER',
    REMOVE_PLAYBOOK_SECTION = 'REMOVE_PLAYBOOK_SECTION',
    TOGGLE_PLAYBOOK_SECTION = 'TOGGLE_PLAYBOOK_SECTION',
    UPDATE_PLAYBOOK_PROVISION = 'UPDATE_PLAYBOOK_PROVISION',
    UPDATE_PLAYBOOK_SUBHEADER = 'UPDATE_PLAYBOOK_SUBHEADER',
    COLLAPSE_ALL_SECTIONS = 'COLLAPSE_ALL_SECTIONS',
    CHANGE_PLAYBOOK_CURRENT_VIEW = 'CHANGE_PLAYBOOK_CURRENT_VIEW',
    TOGGLE_CREATE_TAG_MODAL = 'TOGGLE_CREATE_TAG_MODAL',
    TOGGLE_PLAYBOOK_IS_DRAFT = 'TOGGLE_PLAYBOOK_IS_DRAFT',
    PUBLISH_PLAYBOOK_STARTED = 'PUBLISH_PLAYBOOK_STARTED',
    PUBLISH_PLAYBOOK_SUCCESSFUL = 'PUBLISH_PLAYBOOK_SUCCESSFUL',
    PUBLISH_PLAYBOOK_FAILED = 'PUBLISH_PLAYBOOK_FAILED',
    SAVE_PLAYBOOK_STARTED = 'SAVE_PLAYBOOK_STARTED',
    SAVE_PLAYBOOK_SUCCESSFUL = 'SAVE_PLAYBOOK_SUCCESSFUL',
    SAVE_PLAYBOOK_FAILED = 'SAVE_PLAYBOOK_FAILED',
    GET_ALL_PLAYBOOKS_STARTED = 'GET_ALL_PLAYBOOKS_STARTED',
    GET_ALL_PLAYBOOKS_SUCCESSFUL = 'GET_ALL_PLAYBOOKS_SUCCESSFUL',
    GET_ALL_PLAYBOOKS_FAILED = 'GET_ALL_PLAYBOOKS_FAILED',
    FETCH_ALL_PLAYBOOK_TEMPLATES_STARTED = 'FETCH_ALL_PLAYBOOK_TEMPLATES_STARTED',
    FETCH_ALL_PLAYBOOK_TEMPLATES_SUCCESSFUL = 'FETCH_ALL_PLAYBOOK_TEMPLATES_SUCCESSFUL',
    FETCH_ALL_PLAYBOOK_TEMPLATES_FAILED = 'FETCH_ALL_PLAYBOOK_TEMPLATES_FAILED',
    GET_PLAYBOOK_BY_ID_STARTED = 'GET_PLAYBOOK_BY_ID_STARTED',
    GET_PLAYBOOK_BY_ID_SUCCESSFUL = 'GET_PLAYBOOK_BY_ID_SUCCESSFUL',
    GET_PLAYBOOK_BY_ID_FAILED = 'GET_PLAYBOOK_BY_ID_FAILED',
    GET_PLAYBOOK_TEMPLATE_STARTED = 'GET_PLAYBOOK_TEMPLATE_STARTED',
    GET_PLAYBOOK_TEMPLATE_SUCCESSFUL = 'GET_PLAYBOOK_TEMPLATE_SUCCESSFUL',
    GET_PLAYBOOK_TEMPLATE_FAILED = 'GET_PLAYBOOK_TEMPLATE_FAILED',
    GET_PLAYBOOK_BY_DEFINITION_ID_STARTED = 'GET_PLAYBOOK_BY_DEFINITION_ID_STARTED',
    GET_PLAYBOOK_BY_DEFINITION_ID_SUCCESSFUL = 'GET_PLAYBOOK_BY_DEFINITION_ID_SUCCESSFUL',
    GET_PLAYBOOK_BY_DEFINITION_ID_FAILED = 'GET_PLAYBOOK_BY_DEFINITION_ID_FAILED',
    SET_PLAYBOOK_CAN_SAVE = 'SET_PLAYBOOK_CAN_SAVE',
    SET_PLAYBOOK_PUBLISH_REASON = 'SET_PLAYBOOK_PUBLISH_REASON',
    TOGGLE_PLAYBOOK_PUBLISH_MODAL_OPEN = 'TOGGLE_PLAYBOOK_PUBLISH_MODAL_OPEN',
    TOGGLE_PLAYBOOK_SAVE_MODAL_OPEN = 'TOGGLE_PLAYBOOK_SAVE_MODAL_OPEN',
    SET_PLAYBOOK_CAN_NEXT = 'SET_PLAYBOOK_CAN_NEXT',
    SET_PLAYBOOK_CAN_PUBLISH = 'SET_PLAYBOOK_CAN_PUBLISH',
    SET_PLAYBOOK_VERSION_UPDATED = 'SET_PLAYBOOK_VERSION_UPDATED',
    TOGGLE_PLAYBOOK_COVER_SELECTED = 'TOGGLE_ADMIN_PLAYBOOK_COVER_SELECTED',
    TOGGLE_PLAYBOOK_TEMPLATE_COVER_SELECTED = 'TOGGLE_PLAYBOOK_TEMPLATE_COVER_SELECTED',
    UPDATE_EXISTING_PLAYBOOK = 'UPDATE_EXISTING_PLAYBOOK',
    RESET_PLAYBOOK_STATE = 'RESET_PLAYBOOK_STATE',
    RESET_PLAYBOOK_BUILD = 'RESET_PLAYBOOK_BUILD',
    SET_PLAYBOOK_VERSION_MAJOR_INCREASED = 'SET_PLAYBOOK_VERSION_MAJOR_INCREASED',
    TOGGLE_ADMIN_QUERY_MODAL = 'TOGGLE_ADMIN_QUERY_MODAL',
    SET_ADMIN_SELECTED_QUERY = 'SET_ADMIN_SELECTED_QUERY',
    UPDATE_ADMIN_QUERY_RESPONSE = 'UPDATE_ADMIN_QUERY_RESPONSE',
    SEND_ADMIN_QUERY_RESPONSE_STARTED = 'SEND_ADMIN_QUERY_RESPONSE_STARTED',
    SEND_ADMIN_QUERY_RESPONSE_SUCCESSFUL = 'SEND_ADMIN_QUERY_RESPONSE_SUCCESSFUL',
    SEND_ADMIN_QUERY_RESPONSE_FAILED = 'SEND_ADMIN_QUERY_RESPONSE_FAILED',
    TOGGLE_ADMIN_SEND_EMAIL_REPLY = 'TOGGLE_ADMIN_SEND_EMAIL_REPLY',
    TOGGLE_ADMIN_QUERY_RESOLVED = 'TOGGLE_ADMIN_QUERY_RESOLVED',
    TOGGLE_SECTION_DEVIATION = 'TOGGLE_SECTION_DEVIATION',
    ADD_DEVIATION_ROW = 'ADD_DEVIATION_ROW',
    REMOVE_DEVIATION_ROW = 'REMOVE_DEVIATION_ROW',
    UPDATE_DEVIATION_VALUE = 'UPDATE_DEVIATION_VALUE',
    SET_PROVISIONS_ORDER = 'SET_PROVISIONS_ORDER',
    TOGGLE_PROVISIONS_ORDER_MODAL = 'TOGGLE_PROVISIONS_ORDER_MODAL',
    SAVE_PROVISIONS_ORDER = 'SAVE_PROVISIONS_ORDER',
    SET_DEVIATIONS_ORDER = 'SET_DEVIATIONS_ORDER',
    SET_DEVIATIONS_ORDER_MODAL = 'SET_DEVIATIONS_ORDER_MODAL',
    SAVE_DEVIATIONS_ORDER = 'SAVE_DEVIATIONS_ORDER',
    OPEN_PLAYBOOK_SUGGESTED_CHANGES = 'OPEN_PLAYBOOK_SUGGESTED_CHANGES',
    BACK_TO_OPEN_PLAYBOOK = 'BACK_TO_OPEN_PLAYBOOK',
    SET_SUGGESTED_CHANGE_OPEN = 'SET_SUGGESTED_CHANGE_OPEN',
    SET_PLAYBOOK_PAGE_VIEW = 'SET_PLAYBOOK_PAGE_VIEW',
    TOGGLE_OUTSTANDING_SUGGESTED_CHANGE = 'TOGGLE_OUTSTANDING_SUGGESTED_CHANGE',
    CONFIRM_RESOLVED_STARTED = 'CONFIRM_RESOLVED_STARTED',
    CONFIRM_RESOLVED_SUCCESSFUL = 'CONFIRM_RESOLVED_SUCCESSFUL',
    CONFIRM_RESOLVED_FAILED = 'CONFIRM_RESOLVED_FAILED',
    TOGGLE_SUGGESTION_REVIEW_MODAL_OPEN = 'TOGGLE_SUGGESTION_REVIEW_MODAL_OPEN',
    UPDATE_SUGGESTED_CHANGES_NEW_MESSAGE = 'UPDATE_SUGGESTED_CHANGES_NEW_MESSAGE',
    UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED = 'UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED',
    UPDATE_SUGGESTED_CHANGES_CONVERSATION_SUCCESSFUL = 'UPDATE_SUGGESTED_CHANGES_CONVERSATION_SUCCESSFUL',
    UPDATE_SUGGESTED_CHANGES_CONVERSATION_FAILED = 'UPDATE_SUGGESTED_CHANGES_CONVERSATION_FAILED',
    SET_RESOLVED_SUGGESTED_CHANGE = 'SET_RESOLVED_SUGGESTED_CHANGE',
    TOGGLE_RESOLVED_SUGGESTED_CHANGES_CONTENT = 'TOGGLE_RESOLVED_SUGGESTED_CHANGES_CONTENT',
    UPDATE_ADMIN_PLAYBOOK_SIMPLE_SEARCH = 'UPDATE_ADMIN_PLAYBOOK_SIMPLE_SEARCH',
    RESET_ADMIN_PLAYBOOK_SEARCH = 'RESET_ADMIN_PLAYBOOK_SEARCH',
    UPDATE_ADMIN_TEMPLATE_PLAYBOOK_SIMPLE_SEARCH = 'UPDATE_ADMIN_TEMPLATE_PLAYBOOK_SIMPLE_SEARCH',
    RESET_ADMIN_TEMPLATE_SEARCH = 'RESET_ADMIN_TEMPLATE_SEARCH',
    UPDATE_ADMIN_PLAYBOOK_SMART_SEARCH = 'UPDATE_ADMIN_PLAYBOOK_SMART_SEARCH',
    ADMIN_PLAYBOOK_SMART_SEARCH_STARTED = 'ADMIN_PLAYBOOK_SMART_SEARCH_STARTED',
    ADMIN_PLAYBOOK_SMART_SEARCH_SUCCESSFUL = 'ADMIN_PLAYBOOK_SMART_SEARCH_SUCCESSFUL',
    ADMIN_PLAYBOOK_SMART_SEARCH_FAILED = 'ADMIN_PLAYBOOK_SMART_SEARCH_FAILED',
    RESET_ADMIN_PLAYBOOK_SMART_SEARCH = 'RESET_ADMIN_PLAYBOOK_SMART_SEARCH',
    GET_AVAILABLE_PROVISION_LINKS_STARTED = 'GET_AVAILABLE_PROVISION_LINKS_STARTED',
    GET_AVAILABLE_PROVISION_LINKS_SUCCESSFUL = 'GET_AVAILABLE_PROVISION_LINKS_SUCCESSFUL',
    GET_AVAILABLE_PROVISION_LINKS_FAILED = 'GET_AVAILABLE_PROVISION_LINKS_FAILED',
    SET_PROVISION_TO_LINK = 'SET_PROVISION_TO_LINK',
    SAVE_PLAYBOOK_LINKED_PROVISIONS = 'SAVE_PLAYBOOK_LINKED_PROVISIONS',
    UPDATE_CURRENT_LINKED_PROVISIONS = 'UPDATE_CURRENT_LINKED_PROVISIONS',
    TOGGLE_LINKED_PROVISIONS_MODAL = 'TOGGLE_LINKED_PROVISIONS_MODAL',
    SET_UPDATED_PROVISION_LINKS = 'SET_UPDATED_PROVISION_LINKS',
    TOGGLE_DELETE_SECTION_CONFIRMATION_MODAL = 'TOGGLE_DELETE_SECTION_CONFIRMATION_MODAL',
    TOGGLE_READ_ONLY_LINKED_PROVISION_MODAL = 'TOGGLE_READ_ONLY_LINKED_PROVISION_MODAL',
    OPEN_PROVISION_LINK = 'OPEN_PROVISION_LINK',
    PLAYBOOK_BACK_CALLED = 'PLAYBOOK_BACK_CALLED',
    UPDATE_LINKED_PROVISION_HISTORY = 'UPDATE_LINKED_PROVISION_HISTORY',
    TOGGLE_PROVISION_LINK_HISTORY_MODAL = 'TOGGLE_PROVISION_LINK_HISTORY_MODAL',
    CHANGE_SYSTEM_ADMIN_BOOKSHELF_VIEW = 'CHANGE_SYSTEM_ADMIN_BOOKSHELF_VIEW'
}

export enum PlaybookPage {
    SELECT = 'Select',
    BUILD = 'New Playbook',
    MY_PLAYBOOKS = 'My Playbooks'
}

export enum BuildPage {
    SELECT = 'Select',
    BUILD_FROM_SCRATCH = 'Build from Scratch',
    TEMPLATES = 'Available Templates'
}

export enum PlaybookBuilderTab {
    GENERAL = 'General',
    BUILD = 'Build',
    TAGS = 'Tags',
    PREVIEW = 'Preview'
}

export enum PlaybookView {
    LIST = 'list',
    TABLE = 'table'
}

export enum PlaybookSectionType {
    PROVISION = 'provision',
    SUBHEADER = 'subheader'
}

export enum ValidForInterval {
    DAYS = 'days',
    WEEKS = 'weeks',
    MONTHS = 'months'
}

export enum PlaybookPageView {
    CURRENT = 'current',
    SPLIT = 'split',
    CHANGES = 'changes'
}

export enum PlaybookLogicalOperator {
    AND = 'AND',
    OR = 'OR'
}

export enum PlaybookCondition {
    INCLUDES = 'INCLUDES',
    NOT_INCLUDES = 'DOES NOT INCLUDE'
}

export enum PlaybookSearchContentType {
    AGREEMENT_TYPE = 'Agreement Type',
    TITLE = 'Title',
    AUTHOR = 'Author',
    TAGS = 'Tags',
    CONTENT = 'Content'
}

export enum SystemAdminBookshelfView {
    ALL = 'all',
    TEMPLATES = 'templates',
    PUBLISHED = 'published'
}

export interface PlaybookSmartSearch {
    logicalOperator: PlaybookLogicalOperator;
    condition: PlaybookCondition;
    contentType: PlaybookSearchContentType;
    searchTerm: string;
}

export interface MatchingProvisionTag {
    rowIndex: number;
    index: number;
}

export interface MatchingProvisions {
    [sectionId: string]: MatchingProvisionTag[];
}

export interface TagContent {
    matchingProvisions: MatchingProvisions;
    searchTerm: string;
}

export interface PlaybookTag extends TagContent {
    label: string;
    type: string;
}

export interface Playbook {
    name: string;
    playbookId?: number;
    playbookDefinitionId?: number;
    agreementType: string | null;
    agreementTypeId: number | null;
    documentNameIds: string[] | null;
    versionMajor: number;
    versionMinor: number;
    defaultView: PlaybookView;
    content: PlaybookContent;
    tags: PlaybookTag[];
    isSystemTemplate: number;
    isDraft: number;
    isCurrent?: number;
}

export interface PlaybookContent {
    abstract: RawDraftContentState | null;
    sections: PlaybookSection[];
}

export interface PlaybookProvision {
    title: string[];
    provisionTitle: string | null;
    provisionContent: RawDraftContentState | null;
    backgroundTitle: string | null;
    backgroundContent: RawDraftContentState | null;
    sectionId: string;
    relevantStakeholders: RelevantStakeholders[];
    preferredTerms: RawDraftContentState | null;
    deviations: PlaybookDeviation[];
    linkedProvisions: LinkedPlaybookProvision[];
    type?: PlaybookSectionType.PROVISION;
}

export interface PlaybookSubheader {
    title: string;
    type: PlaybookSectionType.SUBHEADER;
    sectionId: string;
}

export type PlaybookSection = PlaybookProvision | PlaybookSubheader;

export interface RelevantStakeholders {
    userId: number;
    name: string;
}

export interface PlaybookDeviation {
    topic: string;
    variations: RawDraftContentState | null;
    background: RawDraftContentState | null;
    nid: string;
    rowId: string;
    approvalDate: string | null;
    validForInterval: ValidForInterval | null;
    validForIntervalValue: number | null;
}

export interface PlaybookText {
    [sectionId: string]: string[];
}

export interface BasicPlaybook {
    playbookId: number;
    name: string;
    versionMajor: number;
    versionMinor: number;
    createdDate: string;
    agreementType: string | null;
    agreementTypeId: number | null;
    documentNameIds: string[] | null;
    createdBy: string;
    isDraft: number;
    isSystemTemplate: number;
}

export interface PlaybookDB extends Playbook {
    playbookDefinitionId: number;
    playbookId: number;
    isCurrent: number;
    publishReason: string | null;
    createdBy: number;
    createdDate: string;
    effectiveTo: string;
    clientId: number;
}

export interface PlaybookHistory {
    createdDate: string;
    name: string;
    versionMajor: number;
    versionMinor: number;
    createdBy: string;
    playbookDefinitionId: number;
    effectiveTo: string | null;
    isDraft: number;
}

export interface PlaybookById {
    currentPlaybook: PlaybookDB;
    playbookHistory: PlaybookHistory[];
    playbookQueries: PlaybookQueryDB[];
    playbookSuggestedChanges: SuggestedChangesDB[];
    resolvedPlaybookSuggestedChanges: SuggestedChangesDB[];
}

export interface PlaybookByDefinitionId {
    playbookInstance: PlaybookDB;
    playbookHistory: PlaybookHistory[];
    resolvedPlaybookSuggestedChanges: SuggestedChangesDB[];
}

export interface PlaybookMessage {
    content: RawDraftContentState | null;
    userId: number;
    username: string;
    date?: string;
}

export interface PlaybookQueryDB {
    playbookQueryId: number;
    playbookId: number;
    playbookDefinitionId: number;
    queryConversation: PlaybookMessage[];
    sectionId: string;
    raisedBy: number;
    createdDate: string;
    resolved: number;
    clientId: number;
    lastReminderSent: string;
}

export interface SuggestedChangesDB {
    playbookSuggestionId: number;
    playbookId: number;
    playbookDefinitionId: number;
    sectionId: string;
    suggestedSection: SuggestedChanges;
    conversation: PlaybookMessage[];
    createdBy: number;
    createdDate: string;
    outstanding: number;
    clientId: number;
    resolvedBy: number | null;
    resolvedDate: string | null;
}

export interface DraggableTile {
    id: string;
    title: string;
    isSubheader?: boolean;
}

export interface OpenSuggestedChange {
    sectionId: string;
    playbookSuggestionId: number;
}

export enum ReadOnlyContentType {
    CHANGES = 'changes',
    CONVERSATION = 'conversation'
}

export interface AvailablePlaybookProvisionLinks {
    playbookId: number;
    playbookName: string;
    sections: ProvisionLinks[];
}

export interface ProvisionLinks {
    provisionTitle: string;
    sectionId: string;
}

export interface LinkedPlaybookProvision {
    playbookId: number;
    sectionId: string;
    provisionTitle: string;
    playbookName: string;
    isDraft?: boolean;
}

export interface SectionLinkedProvisions {
    sectionId: string;
    linkedProvisions: LinkedPlaybookProvision[];
}

export interface UpdatedProvisionLinks {
    linksAdded: SectionLinkedProvisions[];
    linksRemoved: SectionLinkedProvisions[];
}

export interface AdminPlaybookState {
    playbookPage: PlaybookPage;
    buildPage: BuildPage;
    selectedTab: PlaybookBuilderTab;
    playbook: Playbook;
    openSections: string[];
    currentView: PlaybookView;
    tagToCreate: TagContent | null;
    publishReason: string;
    publishError: string | null;
    currentPlaybook: PlaybookDB | null;
    allPlaybooks: BasicPlaybook[];
    allTemplates: BasicPlaybook[];
    canSave: boolean;
    playbookHistory: PlaybookHistory[];
    publishModalOpen: boolean;
    saveModalOpen: boolean;
    canNext: boolean;
    canPublish: boolean;
    versionUpdated: boolean;
    playbookCoverSelected: BasicPlaybook | null;
    templateCoverSelected: BasicPlaybook | null;
    versionMajorIncreased: boolean;
    queries: PlaybookQueryDB[];
    selectedQuery: PlaybookQueryDB | null;
    queryResponse: PlaybookMessage | null;
    sendingResponse: boolean;
    sendEmailReply: boolean;
    queryMarkedAsResolved: boolean;
    playbookSuggestedChanges: SuggestedChangesDB[];
    openDeviations: string[];
    provisionsOrder: DraggableTile[];
    provisionsModalOpen: boolean;
    deviationsOrder: DraggableTile[];
    deviationsModalSectionId: string | null;
    openSuggestedChange: OpenSuggestedChange | null;
    playbookPageView: PlaybookPageView;
    suggestionReviewModalOpen: boolean;
    suggestedChangesMessage: PlaybookMessage | null;
    suggestedChangesMessageUpdated: boolean;
    sendingMessage: boolean;
    isLoading: boolean;
    resolvedPlaybookSuggestedChanges: SuggestedChangesDB[];
    resolvedSuggestedChange: SuggestedChangesDB | null;
    resolvedSuggestedChangesContent: ReadOnlyContentType;
    simpleSearchTerm: string;
    templateSimpleSearchTerm: string;
    smartSearchTerm: PlaybookSmartSearch[];
    availableProvisionLinks: AvailablePlaybookProvisionLinks[];
    provisionToLink: string | null;
    currentProvisionLinks: LinkedPlaybookProvision[];
    linkedProvisionModalOpen: false;
    updatedProvisionLinks: UpdatedProvisionLinks | null;
    sectionWithLinksToDelete: string | null;
    readOnlyLinkedProvisionModalOpen: string | null;
    provisionLinkHistory: LinkedPlaybookProvision[];
    provisionLinkHistoryModalOpen: boolean;
    systemAdminBookshelfView: SystemAdminBookshelfView.ALL;
}
