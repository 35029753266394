import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { SECIMSupplement, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface SECIMSupplementProps {
    secIMSupplement: SECIMSupplement;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const SECIMSupplementSection: React.FC<SECIMSupplementProps> = ({ secIMSupplement, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'secIMSupplement';
    const { secIMChanges } = secIMSupplement;

    return (
        <SectionWrapper id={sectionId} label='SEC IM Supplement' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.secIMSupplementSectionWrapper}>
                <div className={styles.sectionHeaderLabel}>Under the laws of the jurisdiction under consideration:</div>
                <SingleField
                    field={secIMChanges}
                    fieldId='secIMChanges'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
            </div>
        </SectionWrapper>
    );
};
