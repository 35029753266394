import React, { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { PlusButton } from '../../../../shared/button/PlusButton';
import { DraggableItem } from '../../../../shared/drag-n-drop/shared';
import { Icon } from '../../../../shared/icon/Icon';
import { Delete } from '../../../../shared/icons';
import { addCustomAnswer, removeCustomAnswer, updateAttestationQuestionConfigValue, updateCustomAnswer } from '../../store';
import styles from '../AttestationForms.module.scss';
import { DragDrop } from '../../../../shared/drag-n-drop/DragDrop';

interface CustomAnswerListItem {
    deleteAnswer: (index: number) => void;
    updateAnswerName: (index: number, value: string) => void;
    index: number;
    answer: string;
}

export const CustomAnswerListItem: React.FC<CustomAnswerListItem> = ({ deleteAnswer, index, updateAnswerName, answer }) => (
    <div className={styles.answerWrapper}>
        <input
            className={styles.answerInput}
            value={answer}
            onChange={e => updateAnswerName(index, e.target.value)}
            data-testid={`custom-answer-input-${index}`}
        />
        <button className={styles.deleteAnswer} onClick={() => deleteAnswer(index)} data-testid={`delete-custom-answer-${index}`}>
            <Icon icon={Delete} fontSize={16} />
        </button>
    </div>
);

interface CustomAnswerProps {
    customAnswers: string[];
    attestationIndex: number;
}

export const CustomAnswers: React.FC<CustomAnswerProps> = ({ customAnswers, attestationIndex }) => {
    const dispatch = useAppDispatch();

    const updateAnswerValue = useCallback((key: number, value: string | string[]) => dispatch(updateAttestationQuestionConfigValue(key, 'customAnswers', value)), [dispatch]);
    const addAnswer = useCallback(() => dispatch(addCustomAnswer(attestationIndex)), [dispatch, attestationIndex]);
    const deleteAnswer = useCallback((index: number) => dispatch(removeCustomAnswer(attestationIndex, index)), [dispatch, attestationIndex]);
    const updateAnswerName = useCallback((index: number, value: string) => dispatch(updateCustomAnswer(attestationIndex, index, value)), [dispatch, attestationIndex]);

    const listOptions = useMemo(() => customAnswers.map((value, index) => ({ id: index.toString(), label: value })), [customAnswers]);

    const updateList = useCallback((list: DraggableItem[]) => {
        const options = list.map(({ label }) => label);
        updateAnswerValue(attestationIndex, options);
    }, [attestationIndex, updateAnswerValue]);

    const getChildElement = useCallback((childId: string, index: number) => {
        const listOption = listOptions.find(({ id }) => id === childId);
        if (listOption) {
            return (
                <CustomAnswerListItem
                    key={index}
                    deleteAnswer={deleteAnswer}
                    index={index}
                    updateAnswerName={updateAnswerName}
                    answer={listOption.label}
                />
            );
        }
        return null;
    }, [listOptions, deleteAnswer, updateAnswerName]);

    return (
        <div className={styles.configModalDropdownWrapper}>
            <div className={styles.customAnswersHeader}>
                <div className={styles.configModalToggleLabel}>Custom Answers</div>
                <PlusButton onClick={addAnswer} fontSize={14} />
            </div>
            <div className={styles.customAnswerBuilder}>
                <DragDrop getChildElement={getChildElement} list={listOptions} listId='attestation-custom-answers' updateList={updateList} />
            </div>
        </div>
    );
};
