import React, { useMemo } from 'react';

import { ArkOpinion } from '../my-opinions/store';
import { CollateralProviderInstance } from './CollateralProviderInstance';
import { NettingInstance } from './NettingInstance';
import styles from './OpinionInstance.module.scss';
import { RepoInstance } from './RepoInstance';
import { OpinionInstance as Instance, OpinionInstanceType } from './store/types';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted } from '../../admin/dropdown-lists/store';
import { CollateralTakerInstance } from './CollateralTakerInstance';
import { StockLendingInstance } from './StockLendingInstance';
import { RepoStockLendingInstance } from './RepoStockLendingInstance';

interface OpinionInstanceProps {
    instance: Instance;
    opinion: ArkOpinion;
    isEditing: boolean;
    isUpdating: boolean;
    width: number;
}

export const OpinionInstance: React.FC<OpinionInstanceProps> = ({ isEditing, isUpdating, opinion, instance, width }) => {

    useFetchStarted([fetchAllDropdownListsStarted()]);

    const component = useMemo(() => {
        switch (instance.type) {
            case OpinionInstanceType.NETTING:
                return <NettingInstance instance={instance} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} width={width} />;
            case OpinionInstanceType.COLLATERAL_PROVIDER:
                return <CollateralProviderInstance instance={instance} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} width={width} />;
            case OpinionInstanceType.COLLATERAL_TAKER:
                return <CollateralTakerInstance instance={instance} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} width={width} />;
            case OpinionInstanceType.REPO:
                return <RepoInstance instance={instance} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} width={width} />;
            case OpinionInstanceType.STOCK_LENDING:
                return <StockLendingInstance instance={instance} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} width={width} />;
            case OpinionInstanceType.REPO_STOCK_LENDING:
                return <RepoStockLendingInstance instance={instance} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} width={width} />;
            default:
                return null;
        }
    }, [instance, opinion, isEditing, isUpdating, width]);

    return (
        <div className={styles.instanceWrapper}>
            {component}
        </div>
    );
};
