import React, { useMemo, useState } from 'react';
import { max } from 'lodash/fp';

import { IconButton } from '../button/IconButton';
import { Ellipsis } from '../icons';
import { Action, ActionModal } from '../modal/ActionModal';
import styles from './Table.module.scss';
import { ArkDocument } from '../../documents/my-documents/store';
import { AttestationFormDB } from '../../admin/attestations/store';
import { Position } from '../modal/PositionModal';
import { ClientUser } from '../../admin/users/store';

interface ActionCellParams {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data: any;
    testId: string;
    actions: (data: ArkDocument | AttestationFormDB | ClientUser) => Action[];
}

export const ActionCell: React.FC<ActionCellParams> = ({ data, testId, actions }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);

    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        setIsOpen(true);
    };

    const closeModal = () => setIsOpen(false);

    const options = useMemo(() => actions(data), [data, actions]);
    const width = useMemo(() => {
        const longestLabel = max(options.map(({ label }) => label.length)) || 0;
        const calculatedWidth = (longestLabel * 8) + 35;
        const minWidth = 100;
        const modalWidth = max([minWidth, calculatedWidth])!;
        return `${modalWidth}px`;
    }, [options]);

    const height = useMemo(() => {
        const containsSeperator = options.find(({ withSeparator }) => withSeparator) ? 11 : 8;
        const actionsHeight = (options.length * 24) + containsSeperator;
        return `${actionsHeight}px`;
    }, [options]);

    return (
        <div className={styles.actionWrapper}>
            <IconButton icon={Ellipsis} onClick={e => openModal(e.clientX, e.clientY)} testId={testId} />
            <ActionModal
                testId={testId}
                isOpen={isOpen}
                actions={options}
                closeModal={closeModal}
                position={position}
                width={width}
                height={height}
            />
        </div>
    );
};
