import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';
import { isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { Text } from '../../../../shared/text/Text';
import { getPlaybook, PlaybookView, updatePlaybookValue } from '../../store';
import styles from '../../Playbook.module.scss';
import { Dropdown, DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { getAllAgreementTypes, getAvailableDocumentNames } from '../../../documents/store';
import { Toggle } from '../../../../shared/toggle';
import { getUserRole } from '../../../../auth/login/store';
import { systemAdminRole } from '../../../../constants/permittedRoles';
import { VersionControl } from './VersionControl';
import { InformationTooltip } from '../../../../shared/tooltip';
import { RadioButton } from '../../../../shared/button/RadioButton';
import { Tick } from '../../../../shared/icons';

const { french } = styles;

interface GeneralProps {
    testId: string;
}

export const General: React.FC<GeneralProps> = ({ testId }) => {
    const dispatch = useAppDispatch();

    const userRole = useAppSelector(getUserRole);
    const isSystemAdmin = systemAdminRole.includes(userRole!);

    const setPlaybookName = (name: string) => dispatch(updatePlaybookValue('name', name));

    const playbook = useAppSelector(getPlaybook);
    const agreementTypes = useAppSelector(getAllAgreementTypes);

    const { name, agreementType, agreementTypeId, documentNameIds, isSystemTemplate, playbookId, defaultView } = playbook;

    const agreementTypeOptions = agreementTypes
        .map(({ name, agreementTypeId }) => ({ value: agreementTypeId.toString(), label: name }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const agreementTypeLinked = useMemo(() => agreementType && agreementTypeId ? { value: agreementTypeId.toString(), label: agreementType } : null, [agreementType, agreementTypeId]);

    const updateAgreementType = (option: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(option)) {
            const agreementTypeId = parseInt((option as DropdownOption).value);
            const agreementType = (option as DropdownOption).label;
            dispatch(updatePlaybookValue('agreementType', agreementType));
            dispatch(updatePlaybookValue('agreementTypeId', agreementTypeId));
        } else {
            dispatch(updatePlaybookValue('agreementType', null));
            dispatch(updatePlaybookValue('agreementTypeId', null));
            dispatch(updatePlaybookValue('documentNameIds', null));
        }
    };

    const allDocumentNames = useAppSelector(getAvailableDocumentNames);

    const selectedDatasetId = documentNameIds ? allDocumentNames.find(({ documentNameId }) => documentNameId?.toString() === documentNameIds[0])!.datasetId : null;

    const documentNameOptions = useMemo(() => allDocumentNames
        .filter(({ datasetId }) => datasetId)
        .filter(({ datasetId }) => isNull(selectedDatasetId) || selectedDatasetId === datasetId)
        .filter(document => isNull(agreementTypeLinked) || agreementType === document.agreementType)
        .map(({ documentName, documentNameId }) => ({ value: documentNameId!.toString(), label: documentName })), [allDocumentNames, selectedDatasetId, agreementTypeLinked, agreementType]
    );

    const selectedDocumentName = useMemo(() => documentNameIds ? documentNameOptions.filter(({ value }) => documentNameIds.includes(value)) : null, [documentNameIds, documentNameOptions]);

    const setSelectedDocumentNameId = (dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(dropdownValue) && (dropdownValue as Options<DropdownOption>).length > 0) {
            dispatch(updatePlaybookValue('documentNameIds', (dropdownValue as Options<DropdownOption>).map(({ value }) => value)));
        } else {
            dispatch(updatePlaybookValue('documentNameIds', null));
        }
    };

    const updateIsSystemTemplate = () => dispatch(updatePlaybookValue('isSystemTemplate', isSystemTemplate === 0 ? 1 : 0));
    const isSystemTemplateDisabled = useMemo(() => !isUndefined(playbookId) && isSystemTemplate === 1, [playbookId, isSystemTemplate]);

    const defaultViewList = useMemo(() => defaultView === PlaybookView.LIST, [defaultView]);
    const changeDefaultView = useCallback((view: PlaybookView) => dispatch(updatePlaybookValue('defaultView', view)), [dispatch]);

    return (
        <div className={styles.generalTabWrapper} data-testid={`${testId}-wrapper`}>
            <Text
                label='Playbook Title'
                onChange={e => setPlaybookName(e.target.value)}
                value={name}
                maxLength={256}
                testId={`${testId}-title`}
            />
            <Dropdown
                disabled={false}
                value={agreementTypeLinked}
                options={agreementTypeOptions}
                testId={`${testId}-agreement-type`}
                onChange={updateAgreementType}
                label='Agreement Type (Optional)'
                marginBottom='10px'
            />
            <Dropdown
                value={selectedDocumentName}
                onChange={setSelectedDocumentNameId}
                options={documentNameOptions}
                placeholder={documentNameOptions.length ? 'Select...' : 'No documents available'}
                testId={`${testId}-document-name`}
                isClearable
                isMulti
                label='Documents (Optional)'
                marginBottom='10px'
            />
            <div className={styles.buildTitlePlaybookViewWrapper} data-testid={`${testId}-view-wrapper`}>
                <div className={styles.buildPlaybookViewTitleWrapper}>
                    <div className={styles.buildPlaybookViewTitle} data-testid={`${testId}-view-label`}>Playbook View</div>
                    <InformationTooltip content='Choose the default view for this playbook.' />
                </div>
                <div className={styles.buildPlaybookViewToggleWrapper}>
                    <RadioButton fontSize={10} withBackground={defaultViewList} disabled={false} onClick={() => changeDefaultView(PlaybookView.LIST)} icon={Tick} backgroundColor={french} testId={`${testId}-view-list`} />
                    <div className={styles.buildPlaybookViewToggleTitle} data-testid={`${testId}-view-list-label`}>List</div>
                    <RadioButton fontSize={10} withBackground={!defaultViewList} disabled={false} onClick={() => changeDefaultView(PlaybookView.TABLE)} icon={Tick} backgroundColor={french} testId={`${testId}-view-table`} />
                    <div className={styles.buildPlaybookViewToggleTitle} data-testid={`${testId}-view-table-label`}>Table</div>
                </div>
            </div>
            <VersionControl testId={`${testId}-version-control`} />
            {isSystemAdmin &&
                <div className={styles.isSystemWrapper} data-testid={`${testId}-is-template-wrapper`}>
                    <div className={styles.inputLabel}>Are you creating a system template?</div>
                    <Toggle
                        checked={isSystemTemplate === 1}
                        onChange={updateIsSystemTemplate}
                        testId='create-playbook-general-tab-is-system-toggle'
                        disabled={isSystemTemplateDisabled}
                    />
                </div>
            }
        </div>
    );
};
