import React from 'react';

export const CheckList: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <g>
            <path fill={color || 'currentColor'} d="M101.144,15.212H76.267a12.389,12.389,0,0,0-24.534,0H26.856a6.758,6.758,0,0,0-6.75,6.75v94.731a6.757,6.757,0,0,0,6.75,6.75h74.288a6.757,6.757,0,0,0,6.75-6.75V21.962A6.758,6.758,0,0,0,101.144,15.212ZM93.739,29.367v79.921H34.261V29.367ZM48,25.867V18.712H80v7.155ZM64,8.057a8.918,8.918,0,0,1,8.73,7.155H55.27A8.918,8.918,0,0,1,64,8.057Zm40.394,108.636a3.254,3.254,0,0,1-3.25,3.25H26.856a3.254,3.254,0,0,1-3.25-3.25V21.962a3.254,3.254,0,0,1,3.25-3.25H44.5v7.155H32.511a1.751,1.751,0,0,0-1.75,1.75v83.421a1.751,1.751,0,0,0,1.75,1.75H95.489a1.751,1.751,0,0,0,1.75-1.75V27.617a1.751,1.751,0,0,0-1.75-1.75H83.5V18.712h17.646a3.254,3.254,0,0,1,3.25,3.25Z" />
            <path fill={color || 'currentColor'} d="M47.653,49.144a6.386,6.386,0,1,0-6.385-6.385A6.393,6.393,0,0,0,47.653,49.144Zm0-9.271a2.886,2.886,0,1,1-2.885,2.886A2.889,2.889,0,0,1,47.653,39.873Z" />
            <path fill={color || 'currentColor'} d="M47.653,66.857a6.386,6.386,0,1,0-6.385-6.386A6.394,6.394,0,0,0,47.653,66.857Zm0-9.271a2.886,2.886,0,1,1-2.885,2.885A2.889,2.889,0,0,1,47.653,57.586Z" />
            <path fill={color || 'currentColor'} d="M47.653,102.282A6.386,6.386,0,1,0,41.268,95.9,6.394,6.394,0,0,0,47.653,102.282Zm0-9.271A2.886,2.886,0,1,1,44.768,95.9,2.89,2.89,0,0,1,47.653,93.011Z" />
            <path fill={color || 'currentColor'} d="M47.653,84.569a6.386,6.386,0,1,0-6.385-6.385A6.393,6.393,0,0,0,47.653,84.569Zm0-9.271a2.886,2.886,0,1,1-2.885,2.886A2.89,2.89,0,0,1,47.653,75.3Z" />
            <path fill={color || 'currentColor'} d="M61.15,44.509H84.982a1.75,1.75,0,0,0,0-3.5H61.15a1.75,1.75,0,1,0,0,3.5Z" />
            <path fill={color || 'currentColor'} d="M61.15,62.221H84.982a1.75,1.75,0,0,0,0-3.5H61.15a1.75,1.75,0,0,0,0,3.5Z" />
            <path fill={color || 'currentColor'} d="M61.15,79.934H84.982a1.75,1.75,0,0,0,0-3.5H61.15a1.75,1.75,0,0,0,0,3.5Z" />
            <path fill={color || 'currentColor'} d="M61.15,97.646H84.982a1.75,1.75,0,0,0,0-3.5H61.15a1.75,1.75,0,1,0,0,3.5Z" />
        </g>
    </svg>
);
