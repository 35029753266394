import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { UserDefaults } from '../../auth/login/store';
import { Globe, Table } from '../../shared/icons';
import { CustomTooltip } from '../../shared/tooltip';
import styles from '../Home.module.scss';
import { OpinionsView } from '../../opinions/my-opinions/store';
import { getCurrentUserDefaults, updateUserDefaults } from '../store';
import { IconButton } from '../../shared/button/IconButton';
import { Toggle } from '../../shared/toggle';

export const MyProfileMyPreferences: React.FC = () => {

    const dispatch = useAppDispatch();

    const { opinionPageView, showIntercom, showTicker } = useAppSelector(getCurrentUserDefaults);

    const updateDefaultValue = useCallback((key: keyof UserDefaults, value: OpinionsView | boolean) => dispatch(updateUserDefaults(key, value)), [dispatch]);

    return (
        <div className={styles.myPreferencesSectionWrapper}>
            <div className={styles.preferencesHeader}>Customise some of the default behaviour within ARK 51</div>
            <div className={styles.defaultWrapper}>
                <div className={styles.defaultLabel}>Show Ark 51 statistics banner?</div>
                <Toggle
                    checked={showTicker}
                    onChange={value => updateDefaultValue('showTicker', value)}
                />
            </div>
            <div className={styles.defaultWrapper}>
                <div className={styles.defaultLabel}>Show Intercom Help?</div>
                <Toggle
                    checked={showIntercom}
                    onChange={value => updateDefaultValue('showIntercom', value)}
                />
            </div>
            <div className={styles.defaultWrapper}>
                <div className={styles.defaultLabel}>Select the default Opinions page view:</div>
                <div className={styles.opinionOptions}>
                    <CustomTooltip overlayText='Map Page View' placement='left'>
                        <div>
                            <IconButton fontSize={28} icon={Globe} onClick={() => updateDefaultValue('opinionPageView', OpinionsView.MAP)} withBackground={opinionPageView === OpinionsView.MAP} squareBorder={opinionPageView === OpinionsView.TABLE} />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip overlayText='Table Page View' placement='right'>
                        <div>
                            <IconButton fontSize={30} icon={Table} onClick={() => updateDefaultValue('opinionPageView', OpinionsView.TABLE)} withBackground={opinionPageView === OpinionsView.TABLE} squareBorder={opinionPageView === OpinionsView.MAP} />
                        </div>
                    </CustomTooltip>
                </div>
            </div>
        </div>
    );
};
