import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Options } from 'react-select';

import { useAppSelector } from '../../../hooks/react-redux';
import { fieldListIcons } from '../../admin/dataset-builder/FieldList';
import { getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { RiskAssociated, RiskAssociatedDetails, RiskAssociatedValue as RiskAssociatedValueType, RiskField, riskAssociatedLabel, CurrencyRiskConfig } from '../../admin/risk-tolerance/store';
import { DatasetFieldType } from '../../datasets/store';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';
import { Icon } from '../icon/Icon';
import { Scrollable } from '../scrollable/Scrollable';
import { InformationTooltip, OverflowTooltip } from '../tooltip';
import { RiskAssociatedValue } from './RiskAssociatedValue';
import styles from './RiskTolerance.module.scss';
import { WeightingSlider } from './WeightingSlider';
import { ConfiguredCurrenciesModal } from './ConfiguredCurrenciesModal';

const { red, amber, gold, yellowGreen, green, frenchSelected } = styles;

export const riskAssociatedColors: RiskAssociatedDetails = {
    [RiskAssociated.OF_SERIOUS_CONCERN]: red,
    [RiskAssociated.OF_CONCERN]: amber,
    [RiskAssociated.ACCEPTABLE]: gold,
    [RiskAssociated.DESIRABLE]: yellowGreen,
    [RiskAssociated.HIGHLY_DESIRABLE]: green
};

interface RiskToleranceConfigProps {
    riskField: RiskField;
    setWeighting: (weighting: number) => void;
    updateRiskConfig: (riskAssociated: RiskAssociated, value: RiskAssociatedValueType, selectedCurrency?: string) => void;
    showRiskFieldToggles: boolean;
    toggleRiskField: () => void;
}

export const RiskToleranceConfig: React.FC<RiskToleranceConfigProps> = ({ riskField, setWeighting, updateRiskConfig, showRiskFieldToggles, toggleRiskField }) => {
    const { label, type, weighting, includeRiskField, riskConfig } = riskField;
    const riskAssociatedValues = Object.values(RiskAssociated).filter(risk => typeof risk !== 'string') as RiskAssociated[];
    const title = useMemo(() => !includeRiskField ? `${label} (NOT INCLUDED IN RISK TOLERANCE)` : label, [label, includeRiskField]);

    const [selectedCurrency, setSelectedCurrency] = useState<DropdownOption | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const updateDropdownOption = (option: DropdownOption | Options<DropdownOption> | null) => {
        let newValue = null;
        if (!isNull(option)) {
            newValue = (option as DropdownOption).value;
            setSelectedCurrency({ label: newValue, value: newValue });
        } else {
            setSelectedCurrency(null);
        }
    };

    const showCurrencyDropdown = useMemo(() => type === DatasetFieldType.CURRENCY_AMOUNT, [type]);
    const currencyNotSelected = useMemo(() => showCurrencyDropdown && isNull(selectedCurrency), [showCurrencyDropdown, selectedCurrency]);
    const currency = useMemo(() => isNull(selectedCurrency) ? undefined : selectedCurrency.label, [selectedCurrency]);
    const currenciesWithConfig = useMemo(() => showCurrencyDropdown ? Object.keys(riskConfig as CurrencyRiskConfig) : [], [showCurrencyDropdown, riskConfig]);
    const hasConfiguredCurrencies = useMemo(() => currenciesWithConfig.length > 0, [currenciesWithConfig]);
    const currencyBackground = useCallback((option: string) => currenciesWithConfig.includes(option) ? frenchSelected : undefined, [currenciesWithConfig]);

    const currencyCodeOptions: DropdownOption[] = useMemo(() => {
        const currencyCodes = dropdownLists.find(({ name }) => name === 'Currency Codes');
        return currencyCodes ? currencyCodes.options.map(value => ({ value, label: value, highlightColour: currencyBackground(value) })) : [];
    }, [dropdownLists, currencyBackground]);

    const openModal = useCallback(() => setModalOpen(true), []);
    const closeModal = useCallback(() => setModalOpen(false), []);

    const titleWidth = useMemo(() => `calc(100% - ${showCurrencyDropdown ? '500' : '40'}px)`, [showCurrencyDropdown]);

    return (
        <>
            <div className={styles.configHeader}>
                <div className={styles.configTitle} style={{ width: titleWidth }}><OverflowTooltip overlayText={title} /></div>
                {showCurrencyDropdown && <div className={styles.currencyWrapper}>
                    {hasConfiguredCurrencies && <div className={styles.configuredCurrenciesButton} onClick={openModal}>Configured Currencies</div>}
                    <InformationTooltip content='Configured currencies will be highlighted in the dropdown' placement='top' />
                    <div className={styles.currencySelect}>
                        <Dropdown
                            onChange={updateDropdownOption}
                            value={selectedCurrency}
                            options={currencyCodeOptions}
                        />
                    </div>
                </div>}
                <div className={styles.configType}><Icon icon={fieldListIcons[type]} fontSize={40} /></div>
            </div>
            <div className={styles.configBody}>
                <div className={styles.configRiskWrapper}>
                    <div className={styles.configRiskHeader}>Risk Associated</div>
                    <div className={styles.riskConfiguration}>
                        <Scrollable>
                            {riskAssociatedValues.map(risk => (
                                <div className={styles.riskAssociatedOption} key={risk}>
                                    <div className={styles.riskAssociatedLabel} style={{ backgroundColor: riskAssociatedColors[risk] }}>{riskAssociatedLabel[risk]}</div>
                                    <RiskAssociatedValue riskField={riskField} riskAssociated={risk} updateRiskConfig={updateRiskConfig} selectedCurrency={currency} />
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </div>
                <div className={styles.configWeightingWrapper}>
                    <div className={styles.configWeightingHeader}>Level of Importance</div>
                    <div className={styles.weightingConfiguration}>
                        <WeightingSlider
                            weighting={weighting}
                            setWeighting={setWeighting}
                            includeRiskField={includeRiskField}
                            showRiskFieldToggles={showRiskFieldToggles}
                            toggleRiskField={toggleRiskField}
                        />
                    </div>
                </div>
                {currencyNotSelected && <div className={styles.currencyOverlay} />}
                {showCurrencyDropdown && <ConfiguredCurrenciesModal isOpen={modalOpen} closeModal={closeModal} riskConfig={riskConfig as CurrencyRiskConfig} setSelectedCurrency={setSelectedCurrency} />}
            </div>
        </>
    );
};
