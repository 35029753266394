import { action } from 'typesafe-actions';

import { DateFilter } from '../../../documents/search/store';
import { OpinionReportingActionTypes, OpinionReportField, OpinionReportFilter, OpinionReportTemplate, ReportPage, ReportTab } from './types';
import { OpinionScope } from '../../../admin/opinions/store';

export const setReportPage = (page: ReportPage, templateId?: string) => action(OpinionReportingActionTypes.SELECT_REPORT_PAGE, { page, templateId });

export const setSelectedTab = (tab: ReportTab) => action(OpinionReportingActionTypes.SELECT_WIZARD_TAB, tab);

export const setSelectedScopeTab = (tab: OpinionScope) => action(OpinionReportingActionTypes.SELECT_SCOPE_WIZARD_TAB, tab);

export const updateOpinionReportFilter = (key: keyof OpinionReportFilter, value: string[] | boolean | null) => action(OpinionReportingActionTypes.UPDATE_REPORT_FILTER, { key, value });
export const updateReportDateFilter = (key: keyof DateFilter, value: string | null) => action(OpinionReportingActionTypes.UPDATE_REPORT_DATE_FILTER, { key, value });

export const toggleIncludeSignOffNotes = (value: boolean) => action(OpinionReportingActionTypes.TOGGLE_INCLUDE_SIGN_OFF_NOTES, value);

export const generateReportStarted = () => action(OpinionReportingActionTypes.GENERATE_REPORT_STARTED);
export const generateReportSuccessful = () => action(OpinionReportingActionTypes.GENERATE_REPORT_SUCCESSFUL);
export const generateReportFailed = (error: string) => action(OpinionReportingActionTypes.GENERATE_REPORT_FAILED, error);

export const addOpinionReportField = (field: OpinionReportField) => action(OpinionReportingActionTypes.ADD_OPINION_REPORT_FIELD, field);
export const removeOpinionReportField = (fieldId: string) => action(OpinionReportingActionTypes.REMOVE_OPINION_REPORT_FIELD, fieldId);
export const updateOpinionReportFields = (fields: OpinionReportField[]) => action(OpinionReportingActionTypes.UPDATE_OPINION_REPORT_FIELDS, fields);

export const resetReport = (page: ReportPage) => action(OpinionReportingActionTypes.RESET_REPORT, page);

export const setOpinionReportScopes = (scopes: OpinionScope[], setReportFields: boolean) => action(OpinionReportingActionTypes.SET_OPINION_REPORT_SCOPES, { scopes, setReportFields });

export const setScopeOpenSections = (openSections: string[], scope: OpinionScope) => action(OpinionReportingActionTypes.SET_SCOPE_OPEN_SECTIONS, { openSections, scope });

export const setCurrentScope = (scope: OpinionScope) => action(OpinionReportingActionTypes.SET_CURRENT_SCOPE, scope);

export const setReportUpdated = (value: boolean) => action(OpinionReportingActionTypes.SET_REPORT_UPDATED, value);

export const toggleConfirmationModal = (value: boolean) => action(OpinionReportingActionTypes.TOGGLE_REPORT_CONFIRMATION_MODAL, value);
export const toggleSaveTemplateModalOpen = (isOpen: boolean) => action(OpinionReportingActionTypes.TOGGLE_SAVE_TEMPLATE_MODAL_OPEN, isOpen);

export const updateReportTemplateValue = (key: 'templateName' | 'templateDescription', value: string) => action(OpinionReportingActionTypes.UPDATE_REPORT_TEMPLATE_VALUE, { key, value });

export const saveReportTemplateStarted = (saveAs = false) => action(OpinionReportingActionTypes.SAVE_REPORT_TEMPLATE_STARTED, saveAs);
export const saveReportTemplateSuccessful = (reportTemplate: OpinionReportTemplate) => action(OpinionReportingActionTypes.SAVE_REPORT_TEMPLATE_SUCCESSFUL, reportTemplate);
export const saveReportTemplateFailed = (error: string) => action(OpinionReportingActionTypes.SAVE_REPORT_TEMPLATE_FAILED, error);

export const fetchAllReportTemplatesStarted = () => action(OpinionReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_STARTED);
export const fetchAllReportTemplatesSuccessful = (reportTemplates: OpinionReportTemplate[]) => action(OpinionReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_SUCCESSFUL, reportTemplates);
export const fetchAllReportTemplatesFailed = (error: string) => action(OpinionReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_FAILED, error);

export const openOpinionReportTemplate = (reportTemplate: OpinionReportTemplate) => action(OpinionReportingActionTypes.OPEN_OPINION_REPORT_TEMPLATE, reportTemplate);

export const deleteReportTemplateStarted = (reportTemplateId: number) => action(OpinionReportingActionTypes.DELETE_REPORT_TEMPLATE_STARTED, reportTemplateId);
export const deleteReportTemplateFailed = (error: string) => action(OpinionReportingActionTypes.DELETE_REPORT_TEMPLATE_FAILED, error);
export const deleteReportTemplateSuccessful = (reportTemplates: OpinionReportTemplate[]) => action(OpinionReportingActionTypes.DELETE_REPORT_TEMPLATE_SUCCESSFUL, reportTemplates);

export const setReportTemplateToDelete = (reportTemplateId: number | null) => action(OpinionReportingActionTypes.SET_REPORT_TEMPLATE_TO_DELETE, reportTemplateId);
