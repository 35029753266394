import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getIsEditing, getIsSwitchingAccounts, getProfileHasUpdated, getShowResetPassword, getShowSwitchAccount, getSwitchPassword, getSwitchUsername, getUpdatingProfile, setShowResetPassword, setShowSwitchAccount, switchAccountStarted, toggleEditMyProfile, toggleMyProfileModal, updateMyProfileStarted } from '../../auth/login/store';
import { Button } from '../../shared/button/Button';
import styles from '../Home.module.scss';
import { getMyProfileSection, MyProfileSection, setMyProfileSection, getHomeScreenConfig, getCustomFiltersUpdated, getIsSavingCustomFilters, getHomeScreenConfigUpdated, getUserDefaultsUpdated, resetCustomFiltersSection, getUnsavedProfileChangesModalOpen, setUnsavedProfileChangedModalOpen, resetHomeScreenConfig, resetUserDefaults } from '../store';
import { UnsavedChangesModal } from '../../shared/modal/UnsavedChangesModal';
import { getConfirmNewPassword, getCurrentPassword, getIsResettingPassword, getNewPassword, getPasswordIsStrongEnough, resetPasswordStarted } from '../../auth/resetPassword/store';

export const MyProfileButtons: React.FC = () => {
    const dispatch = useAppDispatch();
    const profileSection = useAppSelector(getMyProfileSection);
    const isEditing = useAppSelector(getIsEditing);
    const profileHasUpdated = useAppSelector(getProfileHasUpdated);
    const isUpdatingProfile = useAppSelector(getUpdatingProfile);
    const homeScreenConfig = useAppSelector(getHomeScreenConfig);
    const homeScreenConfigUpdated = useAppSelector(getHomeScreenConfigUpdated);
    const customFiltersUpdated = useAppSelector(getCustomFiltersUpdated);
    const showResetPassword = useAppSelector(getShowResetPassword);
    const showSwitchAccount = useAppSelector(getShowSwitchAccount);
    const isSwitchingAccount = useAppSelector(getIsSwitchingAccounts);
    const username = useAppSelector(getSwitchUsername);
    const password = useAppSelector(getSwitchPassword);
    const isSaving = useAppSelector(getIsSavingCustomFilters);
    const userDefaultsUpdated = useAppSelector(getUserDefaultsUpdated);
    const currentPassword = useAppSelector(getCurrentPassword);
    const newPassword = useAppSelector(getNewPassword);
    const confirmNewPassword = useAppSelector(getConfirmNewPassword);
    const resettingPassword = useAppSelector(getIsResettingPassword);
    const passwordIsStrongEnough = useAppSelector(getPasswordIsStrongEnough);
    const unsavedChangesModalOpen = useAppSelector(getUnsavedProfileChangesModalOpen);

    const editLabel = useMemo(() => isEditing ? 'Cancel' : 'Edit', [isEditing]);

    const toggleEdit = useCallback(() => dispatch(toggleEditMyProfile(!isEditing)), [dispatch, isEditing]);

    const testId = 'my-profile';

    const columnLengthValidation = useMemo(() => Object.values(homeScreenConfig).some(tiles => tiles.length < 2), [homeScreenConfig]);

    const saveDisabledTooltip = useMemo(() => {
        let tooltip: string[] = [];
        if (columnLengthValidation) tooltip.push('Home Screen - You must have six home screen tiles selected prior to saving');
        if (!isEditing && !profileHasUpdated && !homeScreenConfigUpdated && !customFiltersUpdated && !userDefaultsUpdated || isEditing && !profileHasUpdated) tooltip.push('You have not made any changes');
        if (isSaving || isUpdatingProfile) tooltip.push('Save in progress');
        return tooltip;
    }, [columnLengthValidation, homeScreenConfigUpdated, customFiltersUpdated, isSaving, isUpdatingProfile, isEditing, profileHasUpdated, userDefaultsUpdated]);

    const saveDisabled = (isEditing && !profileHasUpdated || !isEditing && !profileHasUpdated && !homeScreenConfigUpdated && !customFiltersUpdated && !userDefaultsUpdated) || isSaving || isUpdatingProfile || columnLengthValidation;

    const unsavedChangesNotes = useMemo(() => {
        let unsavedSections: string[] = [];
        if (isEditing) {
            unsavedSections.push(MyProfileSection.GENERAL);
        }
        if (homeScreenConfigUpdated) {
            unsavedSections.push(MyProfileSection.HOME_SCREEN);
        }
        if (customFiltersUpdated) {
            unsavedSections.push(MyProfileSection.TABLE_FILTERS);
        }
        if (userDefaultsUpdated) {
            unsavedSections.push(MyProfileSection.MY_PREFERENCES);
        }
        return unsavedSections;
    }, [isEditing, homeScreenConfigUpdated, customFiltersUpdated, userDefaultsUpdated]);

    const unsavedChanges = useMemo(() => unsavedChangesNotes.length > 0, [unsavedChangesNotes]);

    const saveAllUnsavedChanges = useCallback(() => dispatch(updateMyProfileStarted()), [dispatch]);

    const toggleUnsavedChangesModal = useCallback(() => dispatch(setUnsavedProfileChangedModalOpen()), [dispatch]);

    const closeModal = useCallback(() => {
        dispatch(toggleMyProfileModal(false));
        dispatch(toggleEditMyProfile(false));
        dispatch(setMyProfileSection(MyProfileSection.GENERAL));
        dispatch(resetCustomFiltersSection());
        dispatch(resetHomeScreenConfig());
        dispatch(resetUserDefaults());
        if (unsavedChanges) {
            dispatch(setUnsavedProfileChangedModalOpen());
        }
    }, [dispatch, unsavedChanges]);

    const closeAndConfirm = useCallback(() => unsavedChanges ? toggleUnsavedChangesModal() : closeModal(), [unsavedChanges, toggleUnsavedChangesModal, closeModal]);

    const cancelAccountSwitch = useCallback(() => dispatch(setShowSwitchAccount(false)), [dispatch]);

    const switchAccount = useCallback(() => !isNull(username) && dispatch(switchAccountStarted(username, password)), [dispatch, username, password]);

    const switchAccountDisabled = useMemo(() => isNull(username) || !password || isSwitchingAccount, [username, password, isSwitchingAccount]);

    const cancelResetPassword = useCallback(() => dispatch(setShowResetPassword(false)), [dispatch]);
    const attemptResetPassword = useCallback(() => dispatch(resetPasswordStarted()), [dispatch]);

    const newPasswordsUnequal = newPassword !== confirmNewPassword;
    const emptyFields = !currentPassword.length || !newPassword.length || !confirmNewPassword.length;
    const resetPasswordDisabled = newPasswordsUnequal || emptyFields || resettingPassword || !passwordIsStrongEnough;

    const disabledTooltipContent = useMemo(() => {
        let disabledArray = [];
        if (emptyFields) disabledArray.push('One or more of the password inputs are empty');
        if (newPasswordsUnequal) disabledArray.push('Your new password and confirm password do not match');
        if (!passwordIsStrongEnough && newPassword.length) disabledArray.push('We recommend increasing your password strength by using capital letters, numbers and special characters');
        return disabledArray;
    }, [emptyFields, newPasswordsUnequal, passwordIsStrongEnough, newPassword]);

    const buttons = useMemo(() => {
        if (profileSection === MyProfileSection.GENERAL) {
            return (
                <div className={styles.buttonWrapper}>
                    <div className={styles.leftButtonWrapper}>
                        {(!showSwitchAccount && !showResetPassword) && <Button label='Close' onClick={closeAndConfirm} testId={`${testId}-close`} />}
                        {showSwitchAccount && <Button label='Cancel' onClick={cancelAccountSwitch} disabled={isSwitchingAccount} />}
                        {showResetPassword && <Button onClick={cancelResetPassword} testId='cancel-reset-password' label='Cancel' />}
                    </div>
                    <div className={styles.rightButtonWrapper}>
                        {showSwitchAccount && <Button label='Switch Account' onClick={switchAccount} disabled={switchAccountDisabled} />}
                        {showResetPassword && <Button onClick={attemptResetPassword} testId='reset-password' disabled={resetPasswordDisabled} label='Reset Password' disabledTooltip={disabledTooltipContent} />}
                        {(!showSwitchAccount && !showResetPassword) &&
                            <>
                                <Button label={editLabel} onClick={toggleEdit} testId={`${testId}-edit`} />
                                <Button label='Save' onClick={saveAllUnsavedChanges} disabled={saveDisabled} disabledTooltip={saveDisabledTooltip} testId={`${testId}-confirm`} />
                            </>
                        }
                    </div>
                </div>
            );
        }
        return (
            <div className={styles.buttonWrapper}>
                <div className={styles.leftButtonWrapper}>
                    <Button label='Close' onClick={closeAndConfirm} testId={`${testId}-close`} />
                </div>
                <div className={styles.rightButtonWrapper}>
                    <Button label='Save' onClick={saveAllUnsavedChanges} disabled={saveDisabled} disabledTooltip={saveDisabledTooltip} testId={`${testId}-confirm`} />
                </div>
            </div>
        );
    }, [closeAndConfirm, toggleEdit, saveAllUnsavedChanges, testId, editLabel, profileSection, saveDisabled, saveDisabledTooltip, showSwitchAccount, showResetPassword, cancelAccountSwitch, isSwitchingAccount, switchAccount, switchAccountDisabled, attemptResetPassword, cancelResetPassword, disabledTooltipContent, resetPasswordDisabled]);
    const unsavedChangesMessage = useMemo(() => `You have unsaved changes in the following section${unsavedChangesNotes.length > 1 ? 's' : ''}`, [unsavedChangesNotes]);

    return (
        <>
            {buttons}
            <UnsavedChangesModal isOpen={unsavedChangesModalOpen} closeModal={toggleUnsavedChangesModal} confirm={closeModal}>
                <div className={styles.unsavedChangesNotesWrapper}>
                    {unsavedChangesMessage}
                    {unsavedChangesNotes.map((section, index) => (
                        <div key={index} className={styles.unsavedChangesNote}>- {section}</div>
                    ))}
                </div>
            </UnsavedChangesModal>
        </>
    );
};
