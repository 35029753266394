import { isEqual, isNull } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { Socket } from 'socket.io-client';

import { checkIdleToken, extendToken, fetchProfilePicture, login, logout, switchAccount, upsertHomeScreenConfig } from '../../../../services/auth';
import { forgotPassword, forgotUsername, updateCustomFilters, updateMyProfile, updateUserDefaults } from '../../../../services/users';
import { emailValidator } from '../../../../utils/regex-utils';
import { getInstanceUpdated, upsertDatasetInstanceStarted } from '../../../datasets/instances/store';
import { fetchHomepageAttestationProgressStarted, fetchHomepageDocumentSpreadStarted, fetchHomepageEntitySpreadStarted, fetchRelevantHomeScreenAnalytics, getCurrentUserCustomFilters, getCurrentUserDefaults, getCustomFiltersUpdated, getHomeScreenConfig, getHomeScreenConfigUpdated, getUserDefaultsUpdated, HomeScreenConfig, setUserCustomFilters, setUserDefaults, updateCustomFiltersSuccessful } from '../../../home/store';
import { getInstanceUpdated as getOpinionInstanceUpdated, upsertOpinionInstanceStarted } from '../../../opinions/instances/store';
import { forgottenPasswordReminderStarted, forgottenUsernameReminderFailed, forgottenUsernameReminderStarted, forgottenUsernameReminderSuccessful, loginFailed, loginStarted, loginSuccessful, logoutFailed, logoutStarted, logoutSuccessful, setIdleCheckTokenValid, setMyProfileUpdated, setUpdatingProfile, socketLogin, switchAccountFailed, switchAccountStarted, switchAccountSuccessful, toggleLogoutWarningModal, updateMyProfileDetails, updateMyProfileFailed, updateMyProfileSuccessful, updateUserCustomFilters, userUpdated } from './actions';
import { getMyProfile, getPathname, getSocket, getUser, getIsLoggingOut, getProfileHasUpdated } from './selectors';
import { CustomFilters, LoginActionTypes, User, UserDefaults } from './types';

export function* attemptLogin({ payload }: ReturnType<typeof loginStarted>) {
    try {
        const socket: Socket = yield select(getSocket);
        const user: User = yield call(login, { ...payload, socketId: socket.id });
        yield put(fetchRelevantHomeScreenAnalytics(user));
        yield put(loginSuccessful(user));
        yield put(setUserCustomFilters(user.customFilters || []));
        yield put(setUserDefaults(user.userDefaults));
        yield put(push('/home'));
        yield call(fetchProfilePicture, { userId: user.userId, socketId: socket.id });
    } catch (e) {
        yield put(loginFailed((e as Error).message));
    }
}

export function* socketAuthLogin({ payload }: ReturnType<typeof socketLogin>) {
    try {
        const pathname: string = yield select(getPathname);
        const url = pathname === '/login' ? '/home' : pathname;
        yield put(fetchRelevantHomeScreenAnalytics(payload));
        yield put(loginSuccessful(payload));
        yield put(setUserCustomFilters(payload.customFilters || []));
        yield put(setUserDefaults(payload.userDefaults));
        yield put(push(url));
    } catch (e) {
        yield put(push('/login'));
    }
}

export function* attemptLogout() {
    try {
        yield call(logout);
        yield put(logoutSuccessful());
        yield put(push('/login'));
    } catch (e) {
        yield put(push('/login'));
        yield put(logoutFailed((e as Error).message));
    }
}

export function* attemptSwitchUserAccount({ payload }: ReturnType<typeof switchAccountStarted>) {
    try {
        const socket: Socket = yield select(getSocket);
        const user: User = yield call(switchAccount, { ...payload, socketId: socket.id });
        yield put(logoutSuccessful());
        yield put(loginSuccessful(user));
        yield put(switchAccountSuccessful(user));
        const pathname: string = yield select(getPathname);
        if (pathname.includes('home')) {
            yield put(fetchHomepageDocumentSpreadStarted());
            yield put(fetchHomepageEntitySpreadStarted());
            yield put(fetchHomepageAttestationProgressStarted());
        } else {
            yield put(push('/home'));
        }
        yield call(fetchProfilePicture, { userId: user.userId, socketId: socket.id });
    } catch (e) {
        yield put(switchAccountFailed((e as Error).message));
    }
}

export function* attemptSaveUnsavedInstancesBeforeLogout() {
    try {
        // Before logging out, check that the user does not have any unsaved dataset or opinion instances, if they do then we should save their progress
        const instanceHasUpdated: boolean = yield select(getInstanceUpdated);
        const opinionInstanceHasUpdated: boolean = yield select(getOpinionInstanceUpdated);

        if (instanceHasUpdated) {
            yield put(upsertDatasetInstanceStarted(true));
        }
        if (opinionInstanceHasUpdated) {
            yield put(upsertOpinionInstanceStarted(true));
        }
    } catch (e) {
        toast('It appears you have unsaved data that we were unable to save on your behalf');
    }
}

export function* attemptUsernameReminder({ payload }: ReturnType<typeof forgottenUsernameReminderStarted>) {
    try {
        const { email } = payload;
        const emailValid = emailValidator(email);
        if (!emailValid) {
            yield put(forgottenUsernameReminderFailed('Email address is invalid'));
        } else {
            yield call(forgotUsername, { email });
            yield put(forgottenUsernameReminderSuccessful());
            toast('We have sent you an email containing your username');
        }
    } catch (e) {
        yield put(forgottenUsernameReminderFailed('Something went wrong please try again.'));
    }
}

export function* attemptPasswordReminder({ payload }: ReturnType<typeof forgottenPasswordReminderStarted>) {
    try {
        yield call(forgotPassword, { username: payload });
        yield put(forgottenUsernameReminderSuccessful());
        toast('We have sent you an email containing a password reset link');
    } catch (e) {
        yield put(forgottenUsernameReminderFailed('Something went wrong please try again.'));
    }
}

export function* attemptExtendToken() {
    try {
        yield call(extendToken);
        yield put(toggleLogoutWarningModal(false));
    } catch (e) {
        toast.error('Something went wrong, please try again.');
    }
}

export function* attemptIdleCheckToken() {
    try {
        const { tokenValid }: { tokenValid: boolean; } = yield call(checkIdleToken);
        if (tokenValid) {
            yield put(setIdleCheckTokenValid(true));
        } else {
            yield put(logoutStarted());
        }
    } catch (e) {
        yield put(logoutStarted());
    }
}

export function* checkProfileDetailsUpdated() {
    const user: User = yield select(getUser);
    const updatedProfile: User = yield select(getMyProfile);
    let profileHasUpdated = false;
    if (!isNull(user) && !isNull(updatedProfile)) {
        profileHasUpdated = !isEqual(user, updatedProfile);
    }
    yield put(setMyProfileUpdated(profileHasUpdated));
}

export function* attemptUpdateProfile() {
    try {
        const profileHasUpdated: boolean = yield select(getProfileHasUpdated);
        const homeScreenConfigUpdated: boolean = yield select(getHomeScreenConfigUpdated);
        const customFiltersUpdated: boolean = yield select(getCustomFiltersUpdated);
        const userDefaultsUpdated: boolean = yield select(getUserDefaultsUpdated);
        if (profileHasUpdated) {
            const updatedProfile: User = yield select(getMyProfile);
            let request = new FormData();
            if (updatedProfile.file) {
                request.append('file', updatedProfile.file);
            }
            request.append('forenames', updatedProfile.forenames);
            request.append('surname', updatedProfile.surname);
            yield call(updateMyProfile, request);
            yield put(updateMyProfileSuccessful());
        }
        if (homeScreenConfigUpdated) {
            const config: HomeScreenConfig = yield select(getHomeScreenConfig);
            yield call(upsertHomeScreenConfig, { config });
            yield put(setUpdatingProfile(false));
            yield put(updateMyProfileDetails('config', config));
        }
        if (customFiltersUpdated) {
            const currentUserCustomFilters: CustomFilters[] = yield select(getCurrentUserCustomFilters);
            yield call(updateCustomFilters, { customFilters: currentUserCustomFilters });
            yield put(updateCustomFiltersSuccessful(currentUserCustomFilters));
            yield put(updateUserCustomFilters(currentUserCustomFilters));
            yield put(setUpdatingProfile(false));
            yield put(updateMyProfileDetails('customFilters', currentUserCustomFilters));
        }
        if (userDefaultsUpdated) {
            const userDefaults: UserDefaults = yield select(getCurrentUserDefaults);
            yield call(updateUserDefaults, userDefaults);
            yield put(setUserDefaults(userDefaults));
            yield put(setUpdatingProfile(false));
            yield put(updateMyProfileDetails('userDefaults', userDefaults));
        }
        toast('Profile updated');
    } catch (e) {
        yield put(updateMyProfileFailed('Unable to update profile'));
        toast.error('Unable to update profile');
    }
}

export function* attemptUpdateHomeScreen({ payload }: ReturnType<typeof userUpdated>) {
    yield put(fetchRelevantHomeScreenAnalytics(payload));
}

const nonSecureRoutes = ['new-user', 'forgotten-password', 'registration'];

export function* redirectToLoginPage() {
    const isLoggingOut: boolean = yield select(getIsLoggingOut);
    const pathname: string = yield select(getPathname);
    const isNonSecureRoute = nonSecureRoutes.some(nonSecureRoute => pathname.includes(nonSecureRoute));
    if (!isLoggingOut && !isNonSecureRoute) {
        yield put(push('/login'));
    }
}

function* redirectToLoginWatcher() {
    yield takeEvery(LoginActionTypes.REDIRECT_TO_LOGIN, redirectToLoginPage);
}

function* loginWatcher() {
    yield takeEvery(LoginActionTypes.LOGIN_STARTED, attemptLogin);
}

function* socketLoginWatcher() {
    yield takeEvery(LoginActionTypes.SOCKET_LOGIN, socketAuthLogin);
}

function* logoutWatcher() {
    yield takeEvery(LoginActionTypes.LOGOUT_STARTED, attemptLogout);
}

function* switchAccountWatcher() {
    yield takeLeading(LoginActionTypes.SWITCH_USER_ACCOUNT_STARTED, attemptSwitchUserAccount);
}

function* logoutWarningWatcher() {
    yield takeEvery(LoginActionTypes.TOGGLE_LOGOUT_WARNING_MODAL, attemptSaveUnsavedInstancesBeforeLogout);
}

function* forgotUsernameWatcher() {
    yield takeEvery(LoginActionTypes.FORGOTTEN_USERNAME_REMINDER_STARTED, attemptUsernameReminder);
}

function* forgotPasswordWatcher() {
    yield takeLeading(LoginActionTypes.FORGOTTEN_PASSWORD_REMINDER_STARTED, attemptPasswordReminder);
}

function* extendTokenWatcher() {
    yield takeEvery(LoginActionTypes.EXTEND_TOKEN, attemptExtendToken);
}

function* idleCheckTokenWatcher() {
    yield takeEvery(LoginActionTypes.IDLE_CHECK_TOKEN, attemptIdleCheckToken);
}

function* profileDetailsUpdatedWatcher() {
    yield takeEvery([LoginActionTypes.UPDATE_MY_PROFILE_DETAILS], checkProfileDetailsUpdated);
}

function* updateProfileWatcher() {
    yield takeLeading(LoginActionTypes.UPDATE_MY_PROFILE_STARTED, attemptUpdateProfile);
}

function* updatedUserWatcher() {
    yield takeLeading(LoginActionTypes.USER_UPDATED, attemptUpdateHomeScreen);
}

export function* loginSaga() {
    yield all([
        fork(loginWatcher),
        fork(logoutWatcher),
        fork(forgotUsernameWatcher),
        fork(forgotPasswordWatcher),
        fork(socketLoginWatcher),
        fork(extendTokenWatcher),
        fork(profileDetailsUpdatedWatcher),
        fork(updateProfileWatcher),
        fork(logoutWarningWatcher),
        fork(switchAccountWatcher),
        fork(idleCheckTokenWatcher),
        fork(updatedUserWatcher),
        fork(redirectToLoginWatcher)
    ]);
}
