import { flow, isUndefined, set, uniq } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';
import { AnalysisView } from '../../../documents/my-documents/store';
import { NettingPage, NettingEngineTheory, OpinionNettingEngineState, OpinionNettingEngineActionTypes, NettingEngineTableView } from './types';

export const initialOpinionReportFilter = {
    jurisdiction: null,
    focus: null
};

export const initialNettingEngineTheory: NettingEngineTheory = {
    counterpartyNameKnown: null,
    counterparty: { entityId: null, jurisdiction: null, counterpartyClassification: null },
    agreement: null,
    governingLaw: null,
    productTypeKnown: null,
    productsTraded: [],
    counterpartyIsMultiBranch: null,
    counterpartyOffices: []
};

export const INITIAL_STATE: OpinionNettingEngineState = {
    nettingPage: NettingPage.SELECT,
    nettingEngineTheory: initialNettingEngineTheory,
    currentNettingEngineTheory: null,
    theoreticalResults: null,
    theoreticalQuestion: null,
    calculatingResults: false,
    surveyQuestionIndex: 0,
    showSummary: false,
    leiModalOpen: false,
    isChangingQuestion: false,
    nettingAssumptionsModalOpen: false,
    enquiredOpinions: [],
    isCustomConclusion: false,
    nettingEngineDocumentFilters: {},
    nettingEngineDocumentSort: undefined,
    nettingEngineDocumentPageSize: DEFAULT_PAGE_SIZE,
    isLoading: false,
    nettingEngineDocuments: [],
    totalNettingEngineDocuments: 0,
    nettingEngineDocumentPage: 1,
    isOpening: false,
    savedNettingEngineDocument: null,
    currentNettingEngineDocument: null,
    calculatingExistingDocumentsResults: false,
    existingDocumentsResults: null,
    nettingEngineTableView: NettingEngineTableView.EXISTING,
    documentSummaryView: AnalysisView.DATASET
};

export const opinionNettingEngineReducer: Reducer<OpinionNettingEngineState> = (state = INITIAL_STATE, { payload, type }): OpinionNettingEngineState => {
    switch (type) {
        case OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_PAGE:
            return set('nettingPage', payload, state);
        case OpinionNettingEngineActionTypes.RESET_NETTING_ENGINE_PAGE:
            return set('nettingPage', NettingPage.SELECT, state);
        case OpinionNettingEngineActionTypes.UPDATE_NETTING_ENGINE_THEORY: {
            const { key, value, counterpartyKey } = payload;
            if (!isUndefined(counterpartyKey)) {
                if (counterpartyKey === 'entityId') {
                    return flow(
                        set(`nettingEngineTheory[${key}][${counterpartyKey}]`, value),
                        set(`nettingEngineTheory[${key}][jurisdiction]`, null),
                        set(`nettingEngineTheory[${key}][counterpartyClassification]`, null)
                    )(state);
                }
                return set(`nettingEngineTheory[${key}][${counterpartyKey}]`, value, state);
            }
            return set(`nettingEngineTheory[${key}]`, value, state);
        }
        case OpinionNettingEngineActionTypes.CALCULATE_NETTING_ENGINE_THEORY_STARTED:
            return set('calculatingResults', true, state);
        case OpinionNettingEngineActionTypes.CALCULATE_NETTING_ENGINE_THEORY_SUCCESSFUL:
            return flow(
                set('theoreticalResults', payload),
                set('currentNettingEngineTheory', state.nettingEngineTheory),
                set('calculatingResults', false)
            )(state);
        case OpinionNettingEngineActionTypes.CALCULATE_NETTING_ENGINE_THEORY_FAILED:
            return set('calculatingResults', false, state);
        case OpinionNettingEngineActionTypes.SET_SURVEY_QUESTION_INDEX:
            return set('surveyQuestionIndex', payload, state);
        case OpinionNettingEngineActionTypes.RESET_THEORETICAL_STATE:
            return flow(
                set('nettingEngineTheory', initialNettingEngineTheory),
                set('currentNettingEngineTheory', null),
                set('theoreticalResults', null),
                set('theoreticalQuestion', null),
                set('calculatingResults', false),
                set('surveyQuestionIndex', 0),
                set('carouselLeft', '0px'),
                set('showSummary', false),
                set('enquiredOpinions', []),
                set('isCustomConclusion', false)
            )(state);
        case OpinionNettingEngineActionTypes.SET_SHOW_SUMMARY:
            return set('showSummary', payload, state);
        case OpinionNettingEngineActionTypes.SET_LEI_MODAL_OPEN:
            return set('leiModalOpen', payload, state);
        case OpinionNettingEngineActionTypes.SET_CHANGING_QUESTION:
            return set('isChangingQuestion', payload, state);
        case OpinionNettingEngineActionTypes.SET_NETTING_ASSUMPTIONS_MODAL_OPEN:
            return set('nettingAssumptionsModalOpen', payload, state);
        case OpinionNettingEngineActionTypes.ENQUIRE_NETTING_OPINION_SUCCESSFUL:
            return set('enquiredOpinions', uniq([...state.enquiredOpinions, ...payload]), state);
        case OpinionNettingEngineActionTypes.ENQUIRE_NETTING_OPINION_FAILED:
            return set('enquiredOpinions', [], state);
        case OpinionNettingEngineActionTypes.TOGGLE_INDUSTRY_OR_CUSTOM_CONCLUSIONS:
            return set('isCustomConclusion', payload, state);
        case OpinionNettingEngineActionTypes.FETCH_NETTING_ENGINE_DOCUMENTS_STARTED:
            return set('isLoading', true, state);
        case OpinionNettingEngineActionTypes.FETCH_NETTING_ENGINE_DOCUMENTS_SUCCESSFUL:
            return flow(
                set('nettingEngineDocuments', payload.documents),
                set('totalNettingEngineDocuments', payload.total || state.totalNettingEngineDocuments),
                set('nettingEngineDocumentPage', payload.pageNumber || state.nettingEngineDocumentPage),
                set('isLoading', false)
            )(state);
        case OpinionNettingEngineActionTypes.FETCH_NETTING_ENGINE_DOCUMENTS_FAILED:
            return set('isLoading', false, state);
        case OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`nettingEngineDocumentFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`nettingEngineDocumentFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case OpinionNettingEngineActionTypes.CLEAR_NETTING_ENGINE_DOCUMENT_TABLE_FILTERS:
            return set('nettingEngineDocumentFilters', {}, state);
        case OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENT_TABLE_COLUMN_SORT:
            return set('nettingEngineDocumentSort', payload, state);
        case OpinionNettingEngineActionTypes.SET_NETTING_ENGINE_DOCUMENTS_PAGE_SIZE:
            return set('nettingEngineDocumentPageSize', payload, state);
        case OpinionNettingEngineActionTypes.OPEN_NETTING_ENGINE_DOCUMENT_STARTED:
            return set('isOpening', true, state);
        case OpinionNettingEngineActionTypes.OPEN_NETTING_ENGINE_DOCUMENT_SUCCESSFUL:
            return flow(
                set('isOpening', false),
                set('savedNettingEngineDocument', payload.document),
                set('currentNettingEngineDocument', payload.document)
            )(state);
        case OpinionNettingEngineActionTypes.OPEN_NETTING_ENGINE_DOCUMENT_FAILED:
            return set('isOpening', false, state);
        case OpinionNettingEngineActionTypes.UPDATE_NETTING_ENGINE_FIELDS: {
            const { key, value, counterpartyKey } = payload;
            if (!value && ['productTypeKnown', 'counterpartyIsMultiBranch'].includes(key)) {
                // If a user marks either the productTypeKnown or counterpartyIsMultiBranch boolean dropdowns as "No" or null, we need to clear any previously selected values
                const secondKey = key === 'counterpartyIsMultiBranch' ? 'counterpartyOffices' : 'productsTraded';
                return flow(
                    set(`currentNettingEngineDocument[${key}]`, value),
                    set(`currentNettingEngineDocument[${secondKey}]`, [])
                )(state);
            }
            const path = counterpartyKey ? `currentNettingEngineDocument[${key}][${counterpartyKey}]` : `currentNettingEngineDocument[${key}]`;
            return set(path, value, state);
        }
        case OpinionNettingEngineActionTypes.CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_STARTED:
            return set('calculatingExistingDocumentsResults', true, state);
        case OpinionNettingEngineActionTypes.CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_SUCCESSFUL:
            return flow(
                set('existingDocumentsResults', payload),
                set('savedNettingEngineDocument', state.currentNettingEngineDocument),
                set('currentNettingEngineDocument', state.currentNettingEngineDocument),
                set('calculatingExistingDocumentsResults', false)
            )(state);
        case OpinionNettingEngineActionTypes.CALCULATE_EXISTING_DOCUMENTS_NETTING_ENGINE_FAILED:
            return set('calculatingExistingDocumentsResults', false, state);
        case OpinionNettingEngineActionTypes.TOGGLE_NETTING_ENGINE_VIEW:
            return set('nettingEngineTableView', payload, state);
        case OpinionNettingEngineActionTypes.RESET_DOCUMENTS_STATE:
            return flow(
                set('savedNettingEngineDocument', null),
                set('currentNettingEngineDocument', null),
                set('existingDocumentsResults', null),
                set('calculatingExistingDocumentsResults', false)
            )(state);
        case OpinionNettingEngineActionTypes.TOGGLE_DOCUMENT_SUMMARY_VIEW:
            return set('documentSummaryView', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
