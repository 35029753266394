import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Button } from '../../../shared/button/Button';
import styles from './Theoretical.module.scss';
import { calculateNettingEngineTheoryStarted, NettingEngineSurvey, setShowSummary, getShowSummary, getIsChangingQuestion } from '../store';

interface TheoreticalSurveyButtonsProps {
    surveyQuestionIndex: number;
    nettingEngineSurvey: NettingEngineSurvey[];
    setQuestionIndex: (index: number) => void;
}

export const TheoreticalSurveyButtons: React.FC<TheoreticalSurveyButtonsProps> = ({
    surveyQuestionIndex,
    nettingEngineSurvey,
    setQuestionIndex
}) => {

    const dispatch = useAppDispatch();

    const changingQuestion = useAppSelector(getIsChangingQuestion);
    const showSummary = useAppSelector(getShowSummary);

    const calculate = useCallback(() => dispatch(calculateNettingEngineTheoryStarted()), [dispatch]);

    const previousQuestion = useCallback(() => {
        if (showSummary) {
            dispatch(setShowSummary(false));
        } else {
            setQuestionIndex(surveyQuestionIndex - 1);
        }
    }, [surveyQuestionIndex, setQuestionIndex, dispatch, showSummary]);

    const nextQuestion = useCallback(() => surveyQuestionIndex === nettingEngineSurvey.length - 1 ? dispatch(setShowSummary(true)) : setQuestionIndex(surveyQuestionIndex + 1), [surveyQuestionIndex, nettingEngineSurvey, dispatch, setQuestionIndex]);

    const canPrevious = useMemo(() => surveyQuestionIndex !== 0, [surveyQuestionIndex]);

    const canNext = useMemo(() => {
        if (surveyQuestionIndex === nettingEngineSurvey.length) {
            return false;
        }
        return !changingQuestion && !isNull(nettingEngineSurvey[surveyQuestionIndex].value);
    }, [nettingEngineSurvey, surveyQuestionIndex, changingQuestion]);

    return (
        <div className={styles.surveyButtonWrapper}>
            <div className={styles.surveyButtonPrevious}>{surveyQuestionIndex !== 0 && <Button label='Previous' onClick={previousQuestion} disabled={!canPrevious} />}</div>
            {!showSummary && <div className={styles.surveyQuestionCounter}>{surveyQuestionIndex + 1} of 9</div>}
            <div className={styles.surveyButtonNext}>
                {!showSummary && canNext && !changingQuestion && <Button label='Next' onClick={nextQuestion} disabled={!canNext} />}
                {showSummary && <Button label='Calculate' onClick={calculate} />}
            </div>
        </div>
    );
};
