import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import styles from '../Reports.module.scss';
import { getOpinionScope, setOpinionReportScopes } from '../store';
import { RadioList, RadioListOption } from '../../../shared/radio/RadioList';
import { OpinionScope } from '../../../admin/opinions/store';

interface OpinionSelectProps {
    disabled: boolean;
}

export const OpinionSelect: React.FC<OpinionSelectProps> = ({ disabled }) => {
    const dispatch = useAppDispatch();
    const scope = useAppSelector(getOpinionScope);

    const scopeOptions: RadioListOption[] = useMemo(() => [
        { id: OpinionScope.ISDA_NETTING, value: OpinionScope.ISDA_NETTING, label: 'Netting Opinions (ISDA)', isSelected: scope.includes(OpinionScope.ISDA_NETTING), disabled },
        { id: OpinionScope.COLLATERAL_PROVIDER, value: OpinionScope.COLLATERAL_PROVIDER, label: 'Collateral Opinions (Provider Insolvency)', isSelected: scope.includes(OpinionScope.COLLATERAL_PROVIDER), disabled },
        { id: OpinionScope.COLLATERAL_TAKER, value: OpinionScope.COLLATERAL_TAKER, label: 'Collateral Opinions (Taker Insolvency)', isSelected: scope.includes(OpinionScope.COLLATERAL_TAKER), disabled },
        { id: OpinionScope.GMRA_NETTING, value: OpinionScope.GMRA_NETTING, label: 'Netting Opinions (GMRA)', isSelected: scope.includes(OpinionScope.GMRA_NETTING), disabled },
        { id: OpinionScope.GMSLA_NETTING, value: OpinionScope.GMSLA_NETTING, label: 'Netting Opinions (GMSLA)', isSelected: scope.includes(OpinionScope.GMSLA_NETTING), disabled }
    ], [scope, disabled]);

    const setScope = useCallback((buttonId: string) => {
        const alreadySelected = scope.includes(buttonId as OpinionScope);
        let updatedScope = scope;
        if (alreadySelected) {
            updatedScope = scope.filter(scope => scope !== buttonId as OpinionScope);
        } else {
            updatedScope = [...scope, buttonId as OpinionScope];
        }

        dispatch(setOpinionReportScopes(updatedScope, true));
    }, [scope, dispatch]);

    return (
        <div className={styles.documentNameWrapper}>
            <div className={styles.documentNameHeader} data-testid='generate-report-select-scope-label'>Select the scope(s) you would like to generate a report for</div>
            <RadioList
                options={scopeOptions}
                onChange={setScope}
                testId='generate-report-select-scope-radio'
            />
        </div>
    );
};
