import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import styles from '../ClauseTagsModal.module.scss';
import { Button } from '../../../shared/button/Button';
import { ClauseTags, getSelectedTemplateTag, getTemplateTagModalCanSave, saveTemplateTagStarted, SelectedSystemTag, toggleDeleteTagModal, updateSelectedTemplateTag } from '../../store';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { tagCategoryOptions } from '../../../shared/clause-library/ClauseModalTags';
import { Text } from '../../../shared/text/Text';
import { getUserRole } from '../../../auth/login/store';
import { systemAdminRole } from '../../../constants/permittedRoles';

export const SelectedTemplateTag: React.FC = () => {
    const dispatch = useAppDispatch();

    const systemTagCategoryOptions = useMemo(() => tagCategoryOptions.filter(option => option.value !== 'jurisdiction'), []);
    const selectedTag = useAppSelector(getSelectedTemplateTag);
    const selectedTagCategory = useMemo(() => !isNull(selectedTag) ? systemTagCategoryOptions.find(({ value }) => value === selectedTag.category)! : { value: '', label: 'Select a tag...' }, [selectedTag, systemTagCategoryOptions]);

    const userRole = useAppSelector(getUserRole);
    const isSystemAdmin = systemAdminRole.includes(userRole!);

    const updateCurrentTag = useCallback((value: string) => {
        dispatch(updateSelectedTemplateTag('tag', value));
    }, [dispatch]);

    const changeSelectedTagCategory = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(option)) {
            const category = (option as DropdownOption).value as keyof ClauseTags;
            dispatch(updateSelectedTemplateTag('category', category));
        }
    }, [dispatch]);

    const openDeleteTagModal = useCallback((systemTag: SelectedSystemTag) => dispatch(toggleDeleteTagModal(systemTag, true)), [dispatch]);
    const canSave = useAppSelector(getTemplateTagModalCanSave);
    const saveTag = useCallback((systemTag: SelectedSystemTag) => dispatch(saveTemplateTagStarted(systemTag)), [dispatch]);

    return (
        <div className={styles.selectedTagWrapper} data-testid='client-tags-modal-selected-tag-wrapper'>
            <div className={styles.tagInputWrapper} data-testid='client-tags-modal-selected-tag-edit-wrapper'>
                <Text
                    onChange={e => updateCurrentTag(e.target.value)}
                    value={selectedTag?.tag || 'Select a tag...'}
                    maxLength={256}
                    testId='client-tags-modal-selected-tag-edit'
                    placeholder='Tag...'
                    marginBottom='0px'
                    label='Edit Tag'
                    disabled={isNull(selectedTag)}
                />
            </div>
            <div className={styles.tagInputWrapper} data-testid='client-tags-modal-selected-tag-category-wrapper'>
                <Dropdown
                    value={selectedTagCategory}
                    onChange={changeSelectedTagCategory}
                    options={systemTagCategoryOptions}
                    placeholder='Select...'
                    testId='client-tags-modal-selected-tag-category'
                    isClearable
                    label='Tag Category'
                    marginBottom='10px'
                    disabled={isNull(selectedTag)}
                />
            </div>
            <div className={styles.selectedTagButtonWrapper} data-testid='client-tags-modal-selected-tag-buttons-wrapper'>
                {isSystemAdmin && <Button disabled={isNull(selectedTag)} onClick={() => openDeleteTagModal(selectedTag!)} label='Delete Tag' testId='client-tags-modal-selected-tag-delete' />}
                <Button onClick={() => saveTag(selectedTag!)} disabled={!canSave} label='Save Changes' testId='client-tags-modal-selected-tag-save' />
            </div>
            {canSave && <div className={styles.systemTagInfoMessage}>Note: You have updated a template tag, any changes you make will be applied to all clauses for all clients</div>}
        </div>
    );
};
