import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted, getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { allFocusOptions, opinionCommissionedByOptions, opinionScopeOptions } from '../../constants/opinion';
import { Delete, Document } from '../../shared/icons';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn } from '../../shared/table/ArkTable';
import { bespokeFormatter, capitaliseFirstLetterFormatter, commissionedByFormatter, dateFormatter } from '../../shared/table/arkTableFormatters';
import { ArkOpinion, clearIncompleteOpinionTableFilters, deleteOpinionStarted, fetchIncompleteOpinionsStarted, getIncompleteOpinionFilters, getIncompleteOpinionPageSize, getIncompleteOpinions, getIncompleteOpinionsPageNumber, getIsLoading, getSelectedIncompleteOpinions, getTotalIncompleteOpinions, incompleteOpinionsPaginationNext, incompleteOpinionsPaginationPrevious, openIncompleteOpinionStarted, setIncompleteOpinionTableFilters, setIncompleteOpinionsPageSize, setSelectedIncompleteOpinions, toggleOpinionDetailsModal } from './store';

export const IncompleteOpinionsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchIncompleteOpinionsStarted(), fetchAllDropdownListsStarted()]);
    const incompleteOpinions: ArkOpinion[] = useAppSelector(getIncompleteOpinions);
    const selectedIncompleteOpinions: ArkOpinion[] = useAppSelector(getSelectedIncompleteOpinions);
    const isLoading = useAppSelector(getIsLoading);
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const pageNumber = useAppSelector(getIncompleteOpinionsPageNumber);
    const totalIncompleteOpinions = useAppSelector(getTotalIncompleteOpinions);

    const opinionFilters = useAppSelector(getIncompleteOpinionFilters);
    const pageSize = useAppSelector(getIncompleteOpinionPageSize);
    const nextPage = useCallback(() => { dispatch(incompleteOpinionsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(incompleteOpinionsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setIncompleteOpinionTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearIncompleteOpinionTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setIncompleteOpinionsPageSize(pageSize)); }, [dispatch]);

    const openIncompleteOpinion = useCallback((data: ArkOpinion) => {
        dispatch(setSelectedIncompleteOpinions([data]));
        dispatch(openIncompleteOpinionStarted(data));
        dispatch(toggleOpinionDetailsModal(true));
    }, [dispatch]);

    const deleteOpinion = useCallback((data: ArkOpinion) => { dispatch(deleteOpinionStarted(data.location)); }, [dispatch]);

    const incompleteOpinionActions = useCallback((data: ArkOpinion) => [
        { label: 'Open', icon: Document, onClick: () => openIncompleteOpinion(data), withSeparator: true },
        { label: 'Delete', icon: Delete, onClick: () => deleteOpinion(data), isDelete: true }
    ], [openIncompleteOpinion, deleteOpinion]);

    const selectedOpinionIds = useMemo(() => selectedIncompleteOpinions.map(({ opinionId }) => opinionId), [selectedIncompleteOpinions]);

    const selectOpinion = useCallback((opinionId: number | string) => {
        const id = typeof opinionId === 'string' ? parseInt(opinionId) : opinionId;
        const deselect = selectedOpinionIds.includes(id);
        if (deselect) {
            const updatedList = selectedIncompleteOpinions.filter(({ opinionId }) => opinionId !== id);
            dispatch(setSelectedIncompleteOpinions(updatedList));
        } else {
            const documentSelected = incompleteOpinions.find(({ opinionId }) => opinionId === id)!;
            const updatedList = [...selectedIncompleteOpinions, documentSelected];
            dispatch(setSelectedIncompleteOpinions(updatedList));
        }
    }, [dispatch, selectedIncompleteOpinions, incompleteOpinions, selectedOpinionIds]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'checkbox', header: '', field: 'opinionId', width: 0.05, component: 'checkbox', select: selectOpinion, selected: selectedOpinionIds },
        { id: 'opinionJurisdiction', header: 'Jurisdiction', field: 'jurisdiction', width: 0.2, canFilter: true },
        { id: 'focus', header: 'Focus', field: 'focus', width: 0.17, canFilter: true },
        { id: 'bespoke', header: 'Bespoke/Industry Standard', field: 'bespoke', width: 0.17, valueFormatter: bespokeFormatter, canFilter: true },
        { id: 'scope', header: 'Scope', field: 'scope', width: 0.1, component: 'tooltip', canFilter: true, valueFormatter: capitaliseFirstLetterFormatter },
        { id: 'commissionedBy', header: 'Commissioned By', field: 'commissionedBy', width: 0.16, valueFormatter: commissionedByFormatter, component: 'tooltip', canFilter: true },
        { id: 'dateOfOpinion', header: 'Date of Opinion', field: 'dateOfOpinion', width: 0.1, valueFormatter: dateFormatter },
        { id: 'actions', header: '', field: '', width: 0.05, component: 'action', actions: incompleteOpinionActions }
    ];

    const opinionJurisdictionOptions = useMemo(() => {
        const jurisdictionList = dropdownLists.find(({ name }) => name === 'OpinionJurisdiction');
        return jurisdictionList ? jurisdictionList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const bespokeOptions = useMemo(() => [{ label: 'Bespoke', value: '1' }, { label: 'Industry Standard', value: '0' }], []);

    const scopeOptions = useMemo(() => opinionScopeOptions(), []);
    const commissionedByOptions = useMemo(() => opinionCommissionedByOptions(), []);

    const filterDropdownOptions = useMemo(() => ({
        scope: scopeOptions,
        bespoke: bespokeOptions,
        commissionedBy: commissionedByOptions,
        jurisdiction: opinionJurisdictionOptions,
        focus: allFocusOptions
    }), [bespokeOptions, opinionJurisdictionOptions, commissionedByOptions, scopeOptions]);

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={incompleteOpinions}
            testId='incomplete-opinions'
            isLoading={isLoading}
            page={pageNumber}
            total={totalIncompleteOpinions}
            next={nextPage}
            previous={previousPage}
            filters={opinionFilters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => openIncompleteOpinion(row as ArkOpinion)}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
            filterDropdownOptions={filterDropdownOptions}
            removeTextFilter={['scope', 'bespoke', 'focus', 'commissionedBy']}
        />
    );
};
