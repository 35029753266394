import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/types';
import { isNull } from 'lodash/fp';
import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

export type CleanupFn = () => void;

export type ItemEntry = { itemId: string; element: HTMLElement; type: string; };

export type ListContextValue = {
    getListLength: () => number;
    registerItem: (entry: ItemEntry) => CleanupFn;
    reorderItem: (args: {
        startIndex: number;
        indexOfTarget: number;
        closestEdgeOfTarget: Edge | null;
        list?: DraggableItem[];
        groupId?: string;
    }) => void;
    instanceId: symbol;
    moveItem?: (args: {
        sourceGroupId: string;
        targetGroupId: string;
        sourceGroupItemIndex: number;
        targetGroupItemIndex: number;
    }) => void
};

export const ListContext = createContext<ListContextValue | null>(null);

export const useListContext = () => {
    const listContext = useContext(ListContext);
    invariant(!isNull(listContext));
    return listContext;
};

export const getItemRegistry = () => {
    const registry = new Map<string, HTMLElement>();

    const register = ({ itemId, element }: ItemEntry) => {
        registry.set(itemId, element);

        return () => {
            registry.delete(itemId);
        };
    };

    const getElement = (itemId: string): HTMLElement | null => registry.get(itemId) ?? null;

    return { register, getElement };
};

const itemKey = Symbol('item');
export type ItemData = {
    [itemKey]: true;
    item: DraggableItem;
    index: number;
    instanceId: symbol;
    type: string;
};

export const getItemData = ({
    item,
    index,
    instanceId,
    type = 'standard'
}: {
    item: DraggableItem;
    index: number;
    instanceId: symbol;
    type?: string;
}): ItemData => ({
    [itemKey]: true,
    item,
    index,
    instanceId,
    type
});

export const isItemData = (data: Record<string | symbol, unknown>): data is ItemData => data[itemKey] === true;

export interface DraggableItem {
    id: string;
    label: string;
    type?: string;
    parentId?: string;
}

export interface DraggableListProps {
    listId: string;
    list: DraggableItem[];
    updateList: (items: DraggableItem[]) => void;
    getChildElement: (id: string, index: number, type?: string) => JSX.Element | null;
    getDraggingElement?: (id: string, index: number, type?: string) => JSX.Element;
    edgeColour?: string;
    edgeWidth?: number;
}

export interface DraggableProps {
    item: DraggableItem;
    index: number;
    getChildElement: (id: string, index: number, type?: string) => JSX.Element | null;
    getDraggingElement?: (id: string, index: number, type?: string) => JSX.Element;
    type?: string;
    edgeColour?: string;
    edgeWidth?: number;
}

export type DraggableState =
    | { type: 'idle' }
    | { type: 'preview'; container: HTMLElement }
    | { type: 'dragging' };

export const idleState: DraggableState = { type: 'idle' };
export const draggingState: DraggableState = { type: 'dragging' };
