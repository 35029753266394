import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { SingleField } from '../fields/SingleField';
import { getOpenFieldsAndSections, toggleOpenFieldSection } from '../store';
import { TitleTransferDocumentation, UpdatedOpinionField } from '../store/types';
import styles from './OpinionSection.module.scss';
import { SectionWrapper } from './SectionWrapper';

interface TitleTransferProps {
    titleTransfer: TitleTransferDocumentation;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
}

export const TitleTransferSection: React.FC<TitleTransferProps> = ({ titleTransfer, isEditing, isUpdating, fieldsUpdated }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'titleTransfer';
    const {
        governingLawRecognised,
        unconditionalTransferOfOwnership,
        recharacterisedSecurityInterest,
        actionsContinuedTitle,
        otherToEnsureValidity,
        transferorRightToExchange,
        transfereeConsent,
        paragraphSix,
        transfereeRightsInsolvency,
        clawbackDuringSuspectPeriod
    } = titleTransfer;

    return (
        <SectionWrapper id={sectionId} label='Title Transfer Documentation' isEditing={isEditing} isUpdating={isUpdating}>
            <div className={styles.titleTransferSectionWrapper}>
                <div className={styles.sectionHeaderLabel}>Under the laws of the jurisdiction under consideration:</div>
                <SingleField
                    field={governingLawRecognised}
                    fieldId='governingLawRecognised'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={unconditionalTransferOfOwnership}
                    fieldId='unconditionalTransferOfOwnership'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    guidanceNotes={['Please provide relevant details, including any distinctions between the treatment of cash and intermediated securities.']}
                />
                <SingleField
                    field={recharacterisedSecurityInterest}
                    fieldId='recharacterisedSecurityInterest'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['Include legal analysis confirming if any recharacterisation risk exists, and, if so, the quantum of the risk.']}
                />
                <SingleField
                    field={actionsContinuedTitle}
                    fieldId='actionsContinuedTitle'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={otherToEnsureValidity}
                    fieldId='otherToEnsureValidity'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={transferorRightToExchange}
                    fieldId='transferorRightToExchange'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                />
                <SingleField
                    field={transfereeConsent}
                    fieldId='transfereeConsent'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['Include appropriate detail as to the requirement (or lack thereof) for consent.']}
                />
                <SingleField
                    field={paragraphSix}
                    fieldId='paragraphSix'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={transfereeRightsInsolvency}
                    fieldId='transfereeRightsInsolvency'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
                <SingleField
                    field={clawbackDuringSuspectPeriod}
                    fieldId='clawbackDuringSuspectPeriod'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                    positiveAnswers={['No']}
                    negativeAnswers={['Yes', 'Yes - with exceptions']}
                    guidanceNotes={['It is common for jurisdictions to provide powers to liquidators enabling them to challenge contracts on the grounds that they provide fraudulent preferences to certain creditors, or otherwise were intended to defraud the general body of creditors as a whole. Accordingly, this question is a question of degree.', 'Suspect periods within “usual” parameters may be considered to be immaterial:', '- Maximum suspect period of around 2 years', '-	Reason for challenge to the contract is that it is intended to disadvantage / defraud general body of creditors', '- No capability to cherry pick', '- Avoidance of transactions entered into post-insolvency is not a concern on the basis this is a common feature of insolvency regimes', 'The longer the suspect period, the greater the discretion a liquidator has in relation to the suspect period, any onerous requirements to prove claims or if the burden of proof is placed on the secured party to prove its claim should be flagged.']}
                />
            </div>
        </SectionWrapper>
    );
};
