import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { Pencil } from '../shared/icons';
import { Action } from '../shared/modal/ActionModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../shared/table/ArkTable';
import { capitaliseFirstLetterFormatter, dateFormatter } from '../shared/table/arkTableFormatters';
import {
    UserAttestationInstanceDB,
    attestationsPaginationNext,
    attestationsPaginationPrevious,
    clearAttestationTableFilters,
    getAttestationColumnSort,
    getAttestationFilters,
    getAttestationsPageNumber,
    getAttestationsPageSize,
    getIsLoading,
    getTotalAttestations,
    setAttestationTableColumnSort,
    setAttestationTableFilters,
    setAttestationsPageSize,
    toggleUserAttestationInstanceModalOpen
} from './store';

interface AttestationsProps {
    attestationInstances: UserAttestationInstanceDB[];
    completed: boolean;
    testId?: string;
}

export const AttestationsTable: React.FC<AttestationsProps> = ({ attestationInstances, completed, testId }) => {
    const dispatch = useAppDispatch();
    const openUserAttestationInstance = useCallback((data: UserAttestationInstanceDB) => { dispatch(toggleUserAttestationInstanceModalOpen(true, data)); }, [dispatch]);

    const isLoading = useAppSelector(getIsLoading);
    const pageNumber = useAppSelector(getAttestationsPageNumber);
    const total = useAppSelector(getTotalAttestations);
    const filters = useAppSelector(getAttestationFilters);
    const columnSort = useAppSelector(getAttestationColumnSort);
    const pageSize = useAppSelector(getAttestationsPageSize);
    const nextPage = useCallback(() => { dispatch(attestationsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(attestationsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null) => { dispatch(setAttestationTableFilters(key, value)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearAttestationTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setAttestationsPageSize(pageSize)); }, [dispatch]);

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setAttestationTableColumnSort(columnSort)); }, [dispatch]);

    const actions = useCallback((data: UserAttestationInstanceDB): Action[] => [
        { label: 'Open', icon: Pencil, onClick: () => openUserAttestationInstance(data) }
    ], [openUserAttestationInstance]);

    const columnDefs: ArkTableColumn[] = useMemo(() => {
        if (!completed) {
            return [
                { id: 'name', header: 'Name', field: 'attestationName', component: 'tooltip', width: 0.5, canFilter: true, canSort: true },
                { id: 'status', header: 'Status', field: 'status', valueFormatter: capitaliseFirstLetterFormatter, component: 'tooltip', width: 0.15, canFilter: true },
                { id: 'createdDate', header: 'Created Date', field: 'createdDate', width: 0.15, valueFormatter: dateFormatter, canSort: true },
                { id: 'deadline', header: 'Deadline', field: 'deadline', width: 0.15, valueFormatter: dateFormatter, component: 'deadline', canSort: true },
                { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
            ];
        }
        return [
            { id: 'name', header: 'Name', field: 'attestationName', component: 'tooltip', width: 0.4, canFilter: true, canSort: true },
            { id: 'status', header: 'Status', field: 'status', valueFormatter: capitaliseFirstLetterFormatter, component: 'tooltip', width: 0.13, canFilter: true },
            { id: 'createdDate', header: 'Created Date', field: 'createdDate', width: 0.14, valueFormatter: dateFormatter, canSort: true },
            { id: 'completedDate', header: 'Completed Date', field: 'completedDate', width: 0.14, valueFormatter: dateFormatter, canSort: true },
            { id: 'deadline', header: 'Deadline', field: 'deadline', width: 0.14, valueFormatter: dateFormatter, component: 'deadline', canSort: true },
            { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
        ];
    }, [completed, actions]);

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={attestationInstances}
            testId={testId}
            isLoading={isLoading}
            page={pageNumber}
            total={total}
            next={nextPage}
            previous={previousPage}
            filters={filters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => openUserAttestationInstance(row as UserAttestationInstanceDB)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
        />
    );
};
