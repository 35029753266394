import { isNull } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { LoadingDots } from '../../../../shared/loading-dots/LoadingDots';
import { RiskAssociated, riskAssociatedLabel } from '../../../../admin/risk-tolerance/store';
import styles from '../../Analytics.module.scss';

const { red, amber, gold, yellowGreen, mandatory, french } = styles;

interface AverageLevelProps {
    percentage: number;
    chartIsFullScreen: boolean;
}

export const getAverageLabel = (percentage: number | null) => {
    if (isNull(percentage)) {
        return { colour: french, label: 'Unknown' };
    }

    if (percentage < 0.2) {
        return { colour: red, label: riskAssociatedLabel[RiskAssociated.OF_SERIOUS_CONCERN] };
    }
    if (percentage < 0.4) {
        return { colour: amber, label: riskAssociatedLabel[RiskAssociated.OF_CONCERN] };
    }
    if (percentage < 0.6) {
        return { colour: gold, label: riskAssociatedLabel[RiskAssociated.ACCEPTABLE] };
    }
    if (percentage < 0.8) {
        return { colour: yellowGreen, label: riskAssociatedLabel[RiskAssociated.DESIRABLE] };
    }
    if (percentage <= 1) {
        return { colour: mandatory, label: riskAssociatedLabel[RiskAssociated.HIGHLY_DESIRABLE] };
    }
    return { colour: french, label: 'Unknown' };
};

export const AverageLevel: React.FC<AverageLevelProps> = ({ percentage, chartIsFullScreen }) => {
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [activePercentage, setActivePercentage] = useState<number>(0);
    const { colour, label } = getAverageLabel(percentage);

    useEffect(() => {
        if (percentage !== activePercentage || percentage === 0) {
            setActivePercentage(percentage);
            setTimeout(() => setShowLoading(false), 2500);
        }
        return () => {
            setShowLoading(true);
        };
    }, [activePercentage, percentage]);

    if (showLoading) {
        return <LoadingDots ragColours />;
    }

    return (
        <div className={classnames(styles.overallRiskLevel, { [styles.overallRiskLevelFullScreen]: !!chartIsFullScreen })} style={{ color: colour }}>{label}</div>
    );
};
