import classnames from 'classnames';
import React, { MouseEvent, useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../../hooks/react-redux';
import { roleLabels } from '../../../constants/roleLabels';
import { IconButton } from '../../../shared/button/IconButton';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide, Ellipsis } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { ClientUser, toggleUsersModal } from '../../users/store';
import styles from '../Clients.module.scss';
import { ClientSection } from '../store';

const { white, grey } = styles;

interface ClientUsersProps {
    users: ClientUser[]
    clientId: number;
    openUserActionModal: (x: number, y: number, user: ClientUser) => void;
    toggleSection: (section: ClientSection) => void;
    getSectionOpen: (section: ClientSection) => boolean;
}

export const ClientUsers: React.FC<ClientUsersProps> = ({ users, clientId, openUserActionModal, toggleSection, getSectionOpen }) => {

    const dispatch = useAppDispatch();

    const createNewUser = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(toggleUsersModal(true, undefined, clientId));
    }, [dispatch, clientId]);

    const userHeaders = [{ name: 'Username', width: '50%' }, { name: 'Role', width: '45%' }, { name: '', width: '5%' }];

    const userSectionOpen = useMemo(() => getSectionOpen(ClientSection.USERS), [getSectionOpen]);
    const getSectionOpenIcon = useCallback((isOpen: boolean) => isOpen ? CaretDown : CaretSide, []);

    return (
        <div className={classnames(styles.usersWrapper, { [styles.disabled]: !clientId })} >
            <div className={styles.usersTitleWrapper} onClick={() => toggleSection(ClientSection.USERS)}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={getSectionOpenIcon(userSectionOpen)} fontSize={15} />
                </div>
                <div className={styles.usersTitle}>Users</div>
                {userSectionOpen && <PlusButton onClick={createNewUser} disabled={!clientId} fontSize={16} />}
                {!clientId &&
                    <div className={styles.usersNote}>Note: You may add users once the client has been created</div>
                }
            </div>
            {userSectionOpen &&
                <>
                    <div className={styles.usersHeader}>
                        {userHeaders.map(({ name, width }) => (
                            <div key={name} className={styles.userHeaderCell} style={{ width }}>{name}</div>
                        ))}
                    </div>
                    <div className={styles.usersTable}>
                        <Scrollable maxHeight='250px'>
                            {users.map((user, index) => (
                                <div className={styles.userRow} key={index} style={{ backgroundColor: index % 2 ? grey : white }}>
                                    <div className={styles.username}>{user.username}</div>
                                    <div className={styles.role}>{roleLabels[user.role]}</div>
                                    <div className={styles.userActionButton}>
                                        <IconButton icon={Ellipsis} fontSize={19} onClick={e => openUserActionModal(e.clientX, e.clientY, user)} />
                                    </div>
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </>
            }
        </div>
    );
};
