import { getOr, isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DATASET_HEIGHT_OFFSET, PAGE_MARGIN_PADDING_BORDER, useSplitView } from '../../../hooks/useSplitView';
import { dateNow, isBefore } from '../../../utils/luxon';
import { AgreementType, CDMDocumentNameId } from '../../admin/documents/store';
import { ClientFeaturePermission, FeaturePermission } from '../../admin/users/store';
import { getMyProfile, getPathname, getUserHasFeaturePermission, getUserRole } from '../../auth/login/store';
import { hasPermission } from '../../constants/featurePermissions';
import { adminRoles } from '../../constants/permittedRoles';
import { ArkDocument, DocumentType, exportToCDMStarted, exportToCSVStarted, getDocumentAnalysisView, getIsSecondaryAnalyzing, getNewDocumentId, getOriginalDocument, getSecondaryDocument, getUploadModalOpen, toggleUploadModal } from '../../documents/my-documents/store';
import { ButtonResize } from '../../shared/button/ButtonResize';
import { Icon } from '../../shared/icon/Icon';
import { CaretUp, WarningSign, Add, SaveFile, Delete, Pencil, Time } from '../../shared/icons';
import { Spinner } from '../../shared/spinner/Spinner';
import { CdmPreviewModal } from './CdmPreviewModal';
import { ConfirmSaveModal } from './ConfirmSaveModal';
import { DatasetInstance } from './DatasetInstance';
import styles from './DatasetInstance.module.scss';
import { DeleteSecondaryDocumentConfirmationModal } from './DeleteSecondaryDocumentConfirmationModal';
import { ExportButton } from './ExportButton';
import { HistoricalInstances } from './historical/HistoricalInstances';
import { LinkedDocumentButton } from './LinkedDocumentButton';
import { ModalInstance } from './modal-instance/ModalInstance';
import { SecondaryDocumentModal } from './SecondaryDocumentModal';
import {
    editDatasetInstance,
    getCurrentInstances,
    getConfirmSaveModalOpen,
    getDatasetDefinition,
    getDatasetInstanceTimeline,
    getInstanceUpdated,
    getIsEditing,
    getIsLoading,
    getIsSaving,
    getIsUpdating,
    getParentDatasetId,
    toggleSaveInstanceModal,
    updateDatasetInstance,
    upsertDatasetInstanceStarted,
    getOpenFieldsAndSections,
    removeAllFieldSections,
    getDeleteSecondaryDocumentModalOpen,
    toggleShowLegacy,
    getShowLegacy,
    getIsUpdatingInstanceAnnexDefinitions
} from './store';
import { UnsavedChangesPrompt } from './UnsavedChangesPrompt';
import { AnnexConfigurationModal } from './annex-builder/AnnexConfigurationModal';
import { getCurrentInstanceAnnexDefinitions } from '../../agency-annex/store';
import { LoadingDots } from '../../shared/loading-dots/LoadingDots';
import { OverflowTooltip } from '../../shared/tooltip';
import { FieldSearch } from './FieldSearch';

export const AllInstancesWrapper: React.FC = () => {
    const dispatch = useAppDispatch();
    const timeline = useAppSelector(getDatasetInstanceTimeline);
    const datasetDefinition = useAppSelector(getDatasetDefinition);
    const allInstances = useAppSelector(getCurrentInstances);
    const parentDatasetId = useAppSelector(getParentDatasetId);
    const isEditing = useAppSelector(getIsEditing);
    const isUpdating = useAppSelector(getIsUpdating);
    const userRole = useAppSelector(getUserRole);
    const isSaving = useAppSelector(getIsSaving);
    const user = useAppSelector(getMyProfile);
    const isLoading = useAppSelector(getIsLoading);
    const instanceUpdated = useAppSelector(getInstanceUpdated);
    const uploadModalOpen = useAppSelector(getUploadModalOpen);
    const confirmSaveModalOpen = useAppSelector(getConfirmSaveModalOpen);
    const deleteSecondaryDocumentModalOpen = useAppSelector(getDeleteSecondaryDocumentModalOpen);
    const secondaryDocumentAnalyzing = useAppSelector(getIsSecondaryAnalyzing);
    const pathname = useAppSelector(getPathname);
    const originalDocument = useAppSelector(getOriginalDocument);
    const newDocumentId = useAppSelector(getNewDocumentId);
    const secondaryDocument = useAppSelector(getSecondaryDocument);
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const showLegacy = useAppSelector(getShowLegacy);
    const hasEditDatasetPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.EDIT_DOCUMENT_DATA]));
    const hasUploadDocumentPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.UPLOAD_DOCUMENTS]));
    const instanceAnnexDefinitions = useAppSelector(getCurrentInstanceAnnexDefinitions);
    const isUpdatingInstanceAnnexDefinitions = useAppSelector(getIsUpdatingInstanceAnnexDefinitions);
    const widthOffset = PAGE_MARGIN_PADDING_BORDER;
    const heightOffset = DATASET_HEIGHT_OFFSET;
    const [width, height] = useSplitView(documentAnalysisView, widthOffset, heightOffset);

    const parentInstance = useMemo(() => allInstances.map(({ instance }) => instance).find(({ datasetId }) => datasetId === parentDatasetId) || null, [allInstances, parentDatasetId]);

    const toggleUpdate = useCallback(() => dispatch(updateDatasetInstance(!isUpdating)), [dispatch, isUpdating]);
    const toggleEdit = useCallback(() => dispatch(editDatasetInstance(!isEditing)), [dispatch, isEditing]);
    const closeConfirmSaveModal = useCallback(() => dispatch(toggleSaveInstanceModal(false)), [dispatch]);
    const openConfirmSave = useCallback(() => dispatch(toggleSaveInstanceModal(true)), [dispatch]);

    const collapseAllFieldsAndSections = useCallback(() => dispatch(removeAllFieldSections()), [dispatch]);

    const addDocument = useCallback(() => dispatch(toggleUploadModal(true, true)), [dispatch]);

    const toggleLegacy = useCallback(() => dispatch(toggleShowLegacy(!showLegacy)), [dispatch, showLegacy]);

    const exportToCSV = useCallback(({ documentNameId, documentId, documentDescription }: ArkDocument) => { dispatch(exportToCSVStarted(documentNameId!, documentId, documentDescription, parentInstance?.datasetInstanceId)); }, [dispatch, parentInstance?.datasetInstanceId]);

    const exportToCdm = useCallback(() => {
        if (secondaryDocument && secondaryDocument.documentNameId && Object.values(CDMDocumentNameId).includes(secondaryDocument.documentNameId)) {
            dispatch(exportToCDMStarted(secondaryDocument.documentId));
            return;
        }
        if (originalDocument) {
            dispatch(exportToCDMStarted(originalDocument.documentId));
        }
    }, [dispatch, secondaryDocument, originalDocument]);

    const save = useCallback((isDraft: boolean) => {
        dispatch(upsertDatasetInstanceStarted(isDraft));
        closeConfirmSaveModal();
    }, [dispatch, closeConfirmSaveModal]);

    const isNewInstance = false;
    const selectedInstanceIsDraft = !!(parentInstance && parentInstance.isDraft);

    const saveDisabled = isSaving || !instanceUpdated && !selectedInstanceIsDraft || secondaryDocumentAnalyzing;

    const saveDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!instanceUpdated && !selectedInstanceIsDraft) {
            disabledArray.push('You have not made any changes to this dataset');
        }
        if (secondaryDocumentAnalyzing) {
            disabledArray.push('We are still analyzing the document you uploaded');
        }
        return disabledArray;
    }, [instanceUpdated, selectedInstanceIsDraft, secondaryDocumentAnalyzing]);

    const isAdminUser = adminRoles.includes(userRole!);
    const updateButtonVisible = parentInstance && parentInstance.datasetInstanceId && !isEditing && !selectedInstanceIsDraft && hasEditDatasetPermission;
    const editButtonVisible = ((isAdminUser && !isUpdating) || selectedInstanceIsDraft) && hasEditDatasetPermission;

    const previousInstances = useMemo(() => timeline && parentInstance && timeline.instances.filter(({ executedDate, isLegacy }) => isBefore(executedDate, parentInstance.executedDate) && !isLegacy) || [], [timeline, parentInstance]);
    const previousInstanceInDraft = previousInstances.some(({ isDraft }) => isDraft);
    const editDraftDisabled = previousInstanceInDraft ? ['Previous version is still saved as draft'] : [];

    const showOriginalDocument = isNewInstance || (!secondaryDocument && selectedInstanceIsDraft);
    const showSecondaryDocument = (!!newDocumentId || selectedInstanceIsDraft) && !!secondaryDocument;
    const documentDescription = getOr('', 'documentDescription', showSecondaryDocument ? secondaryDocument : originalDocument);

    const hasRAFExportPermission = hasPermission([ClientFeaturePermission.EXPORT_RAF_DOCUMENTS_TO_CSV], user!.clientFeaturePermissionIds);

    const isPreExecutionDocument = useMemo(() => !isNull(originalDocument) && originalDocument.documentType === DocumentType.PRE_EXECUTION_DRAFT, [originalDocument]);

    const showExportButton = useMemo(() => parentInstance && originalDocument && originalDocument.agreementTypeId === AgreementType.RAF_REVIEW && hasRAFExportPermission && !isPreExecutionDocument, [parentInstance, originalDocument, hasRAFExportPermission, isPreExecutionDocument]);
    const showCDMExportButton = useMemo(() => (
        parentInstance &&
        (
            (secondaryDocument && secondaryDocument.documentNameId && Object.values(CDMDocumentNameId).includes(secondaryDocument.documentNameId)) ||
            (originalDocument && originalDocument.documentNameId && Object.values(CDMDocumentNameId).includes(originalDocument.documentNameId))
        ) && !isPreExecutionDocument
    ), [parentInstance, originalDocument, secondaryDocument, isPreExecutionDocument]);
    const showLinkDocumentButton = useMemo(() => timeline && timeline.linkedDocuments && !isPreExecutionDocument, [timeline, isPreExecutionDocument]);

    const showCollapseAllButton = !!openFieldsAndSections.length;

    const showIconButtons = useMemo(() => {
        let displayedButtonsWidth = 150;
        if (instanceUpdated) {
            displayedButtonsWidth = displayedButtonsWidth + 160;
        }
        if (timeline && timeline.linkedDocuments) {
            displayedButtonsWidth = displayedButtonsWidth + 54;
        }
        if (showExportButton) {
            displayedButtonsWidth = displayedButtonsWidth + 54;
        }
        if (showCDMExportButton) {
            displayedButtonsWidth = displayedButtonsWidth + 54;
        }
        if (showCollapseAllButton) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (isEditing || isUpdating) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (updateButtonVisible) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (selectedInstanceIsDraft) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        if (isAdminUser) {
            displayedButtonsWidth = displayedButtonsWidth + 154;
        }
        return displayedButtonsWidth >= width;
    }, [instanceUpdated, timeline, showExportButton, showCDMExportButton, showCollapseAllButton, isEditing, isUpdating, updateButtonVisible, selectedInstanceIsDraft, isAdminUser, width]);

    const updateButton = useMemo(() => isUpdating ? { label: 'Cancel', icon: Delete } : { label: 'Update', icon: Pencil }, [isUpdating]);
    const editButton = useMemo(() => isEditing ? { label: 'Cancel', icon: Delete } : { label: 'Edit', icon: Pencil }, [isEditing]);

    const isLegacyDocument = useMemo(() => {
        if (timeline) {
            const parentInstance = allInstances.find(({ instance: { datasetId } }) => datasetId === parentDatasetId);
            if (parentInstance) {
                const parentInstanceId = parentInstance.instance.datasetInstanceId!;
                const timelineInstance = timeline.instances.find(({ datasetInstanceId }) => parentInstanceId === datasetInstanceId);
                if (timelineInstance) {
                    return timelineInstance.isLegacy;
                }
            }
        }
        return false;
    }, [allInstances, parentDatasetId, timeline]);

    const isReplicationDocument = useMemo(() => !!timeline && !!timeline.replicatedOriginalDocumentId, [timeline]);
    const legacyLabel = useMemo(() => showLegacy ? 'Hide Legacy' : 'Show Legacy', [showLegacy]);

    const handleSave = useCallback(() => {
        if (isEditing && !selectedInstanceIsDraft && isAdminUser) {
            save(false);
        } else {
            openConfirmSave();
        }
    }, [isEditing, selectedInstanceIsDraft, isAdminUser, openConfirmSave, save]);

    const documentName = useMemo(() => {
        if (timeline) {
            const parentInstance = allInstances.find(({ instance: { datasetId } }) => datasetId === parentDatasetId);
            if (parentInstance) {
                const parentInstanceId = parentInstance.instance.datasetInstanceId!;
                const timelineInstance = timeline.instances.find(({ datasetInstanceId }) => parentInstanceId === datasetInstanceId);
                if (timelineInstance) {
                    return timelineInstance.documentName;
                }
            }
            return timeline.documentName;
        }
        return '';
    }, [allInstances, parentDatasetId, timeline]);

    const instanceTimelineHeader = useMemo(() => isPreExecutionDocument ? <div className={styles.preExecutionLabel}>PRE-EXECUTION</div> : <HistoricalInstances timeline={timeline!} />, [isPreExecutionDocument, timeline]);

    const agencyAnnexIsExtracting = useMemo(() => instanceAnnexDefinitions.some(({ extractionInProgress }) => extractionInProgress), [instanceAnnexDefinitions]);
    const multipleAgencyTables = useMemo(() => instanceAnnexDefinitions.length > 1, [instanceAnnexDefinitions]);

    useEffect(() => () => {
        dispatch(editDatasetInstance(false));
        dispatch(updateDatasetInstance(false));
    }, [dispatch]);

    if (isLoading) {
        return (
            <div className={styles.spinnerWrapper} data-testid='dataset-instance-spinner-wrapper'>
                <Spinner size={70} />
            </div>
        );
    }

    if (agencyAnnexIsExtracting || isUpdatingInstanceAnnexDefinitions) {
        return (
            <div className={styles.spinnerWrapper} data-testid='dataset-instance-extracting-wrapper'>
                <LoadingDots />
                <div className={styles.spinnerLabel}>Updating Agency Table{multipleAgencyTables ? 's' : ''}</div>
            </div>
        );
    }

    return (
        <div className={styles.datasetWrapper} data-testid='dataset-instance-wrapper'>
            {datasetDefinition && parentInstance && timeline && (
                <div className={styles.datasetInstanceWrapper} style={{ width: `${width}px` }}>
                    {instanceTimelineHeader}
                    <div className={styles.datasetInstanceHeader}>
                        <div className={styles.datasetInstanceSearchWrapper}><FieldSearch /></div>
                        <div className={styles.datasetInstanceTitle}><OverflowTooltip overlayText={documentName} /></div>
                    </div>
                    <div style={{ height: `${height}px`, zIndex: 1 }}>
                        <DatasetInstance datasetInstance={parentInstance} isEditing={isEditing} isUpdating={isUpdating} parentDatasetIds={[]} parentFieldId='' instanceExecutedDateMatchesParent />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className={styles.datasetInstanceButtonWrapper} style={{ width: 'fit-content' }} data-testid='dataset-instance-button-wrapper'>
                            {instanceUpdated && <div className={styles.unsavedDatasetWarning}>
                                <Icon icon={WarningSign} fontSize={25} />
                                <div className={styles.unsavedDatasetWarningTitle}>Unsaved changes</div>
                            </div>}
                            {showLinkDocumentButton && <LinkedDocumentButton linkedDocuments={timeline.linkedDocuments!} disabled={instanceUpdated} />}
                            {showExportButton && <ExportButton exportFunction={exportToCSV} originalDocument={originalDocument!} overlayText='Export to CSV' />}
                            {showCDMExportButton && <ExportButton exportFunction={exportToCdm} originalDocument={originalDocument!} overlayText='Export to CDM' />}
                            {showCollapseAllButton && <ButtonResize showIconButton={showIconButtons} onClick={collapseAllFieldsAndSections} label='Collapse All' icon={CaretUp} hasIcon />}
                            {isReplicationDocument && <ButtonResize showIconButton={showIconButtons} onClick={toggleLegacy} label={legacyLabel} icon={Time} />}
                            {!isLegacyDocument && !isPreExecutionDocument && hasUploadDocumentPermission && <ButtonResize showIconButton={showIconButtons} onClick={addDocument} label='Add Document' icon={Add} />}
                            {(isEditing || isUpdating) && !isLegacyDocument && <ButtonResize showIconButton={showIconButtons} onClick={handleSave} label='Save' icon={SaveFile} disabled={saveDisabled} disabledTooltip={saveDisabledTooltip} />}
                            {updateButtonVisible && !isLegacyDocument && <ButtonResize showIconButton={showIconButtons} onClick={toggleUpdate} label={updateButton.label} icon={updateButton.icon} />}
                            {editButtonVisible && !isLegacyDocument && <ButtonResize showIconButton={showIconButtons} onClick={toggleEdit} label={editButton.label} icon={editButton.icon} disabled={previousInstanceInDraft} disabledTooltip={editDraftDisabled} />}
                        </div>
                    </div>
                </div>
            )}
            <ModalInstance isEditing={isEditing} isUpdating={isUpdating} />
            <SecondaryDocumentModal
                isOpen={uploadModalOpen}
                disabled={false}
                originalDocumentId={parentInstance?.originalDocumentId || 0}
                documentId={parentInstance?.documentId || 0}
                executedDate={parentInstance?.executedDate || dateNow()}
                agreementTypeId={originalDocument?.agreementTypeId}
            />
            <ConfirmSaveModal
                isOpen={confirmSaveModalOpen}
                save={save}
                cancel={closeConfirmSaveModal}
                isUpdating={isUpdating}
                description={documentDescription}
                showDocument={showOriginalDocument || showSecondaryDocument}
            />
            <DeleteSecondaryDocumentConfirmationModal
                isOpen={deleteSecondaryDocumentModalOpen}
            />
            {instanceUpdated && !isSaving && <UnsavedChangesPrompt when={instanceUpdated} pathname={pathname} toggleEdit={toggleEdit} />}
            <CdmPreviewModal />
            <AnnexConfigurationModal documentName={documentName} />
        </div>
    );
};
