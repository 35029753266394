import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useCallback, MouseEvent } from 'react';
import classnames from 'classnames';

import { IconButton } from '../../../shared/button/IconButton';
import { Icon } from '../../../shared/icon/Icon';
import { CaretSide, Document } from '../../../shared/icons';
import styles from './Fields.module.scss';

interface ReadOnlyPageRefProps {
    pageRefs: string[];
    onClick: (pageNumber: number) => void;
    pageRefVerified: boolean;
}

const { lightGrey, white } = styles;

const Content: React.FC<ReadOnlyPageRefProps> = ({ pageRefs, onClick, pageRefVerified }) => {
    const goToPage = useCallback((e: MouseEvent<HTMLButtonElement | HTMLDivElement>, pageRef: string) => {
        e.stopPropagation();
        onClick(parseInt(pageRef));
    }, [onClick]);
    return (
        <div className={styles.pageRefContent}>
            <div className={styles.relevantPages}>Relevant Pages</div>
            {pageRefs.map((pageRef, index) => (
                <div key={index} className={classnames(styles.pageRefWrapper, { [styles.unverifiedPageRef]: !pageRefVerified })} onClick={e => goToPage(e, pageRef)}>
                    <div className={styles.pageRefLabel}>{pageRef}</div>
                    <div className={styles.pageRefIcon}>
                        <IconButton icon={CaretSide} onClick={e => goToPage(e, pageRef)} fontSize={14} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export const ReadOnlyPageRef: React.FC<ReadOnlyPageRefProps> = ({ pageRefs, onClick, pageRefVerified }) => {
    const tooltipStyles = {
        backgroundColor: white,
        borderColor: lightGrey,
        borderRadius: '5px',
        minHeight: '10px',
        display: 'flex',
        padding: '4px 8px'
    };

    const overlayStyle = {
        maxWidth: '300px'
    };

    return (
        <Tooltip
            placement='right'
            overlay={<Content
                pageRefs={pageRefs}
                onClick={onClick}
                pageRefVerified={pageRefVerified}
            />}
            trigger='click'
            overlayInnerStyle={tooltipStyles}
            overlayStyle={overlayStyle}
        >
            <div className={styles.readOnlyPageRef}>
                <Icon icon={Document} fontSize={20} />
            </div>
        </Tooltip>
    );
};
