import { RawDraftContentState } from 'draft-js';

import { api } from './services';
import { OpinionInstance, SignOffOpinionInstanceContent, TimelineOpinionInstance } from '../components/opinions/instances/store/types';
import { TableFilters } from '../components/shared/modal/TableFilterModal';
import { ColumnSort } from '../components/shared/table/ArkTable';

type UpsertInstanceRequest = { opinionInstance: OpinionInstance, isUpdate: boolean, isDraft: boolean };
type FetchInstanceRequest = { opinionInstance: TimelineOpinionInstance; };
type InstanceExistsRequest = { opinionInstanceId: number | string; opinionId: number | string; };

interface FetchOpinionClausesRequest {
    opinionId: number;
    pageNumber: number;
    filters: TableFilters;
    columnSort: ColumnSort | undefined;
}

interface LinkOpinionClauseRequest {
    opinionId: number;
    clauseId: number;
    pageNumber: number;
    filters: TableFilters;
    columnSort: ColumnSort | undefined;
    shouldRemoveLink: boolean;
}

export interface UpsertSignOffRequest {
    clientOpinionInstanceId: number | null;
    opinionInstanceId: number;
    content: SignOffOpinionInstanceContent;
    finalApprovalNotes: RawDraftContentState | null;
    approved: boolean;
}

export const upsertOpinionInstance = async (reqBody: UpsertInstanceRequest) => await api('opinion/instance/upsert', { ...reqBody }, 'post');
export const fetchOpinionInstance = async (reqBody: FetchInstanceRequest) => await api('opinion/instance/getById', { ...reqBody }, 'post');
export const opinionInstanceExistsForOpinionId = async (reqBody: InstanceExistsRequest) => await api('opinion/instance/exists', { ...reqBody }, 'post');

export const fetchTimelineOpinionInstances = async (reqBody: { opinionId: number | string; }) => await api('opinion/instance/getTimelineInstances', { ...reqBody }, 'post');
export const fetchOpinionSignOffInstance = async (reqBody: { opinionInstance: TimelineOpinionInstance; }) => await api('opinion/signOff/instance/getById', { ...reqBody }, 'post');
export const upsertSignOffOpinionInstance = async (reqBody: UpsertSignOffRequest) => await api('opinion/signOff/instance/upsert', { ...reqBody }, 'post');

export const fetchOpinionClauses = async (reqBody: FetchOpinionClausesRequest) => await api('opinion/instance/getOpinionClauses', { ...reqBody }, 'post');
export const linkClauseToOpinion = async (reqBody: LinkOpinionClauseRequest) => await api('opinion/instance/linkOpinionClause', { ...reqBody }, 'post');
