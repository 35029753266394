import { getOr, isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted, getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { ClientFeaturePermission, FeaturePermission } from '../../admin/users/store';
import { getClientHasFeaturePermission, getUserHasFeaturePermission, getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { allFocusOptions, opinionCommissionedByOptions, opinionScopeOptions } from '../../constants/opinion';
import { Delete, Document, Download, SaveFile } from '../../shared/icons';
import { Action } from '../../shared/modal/ActionModal';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { bespokeFormatter, commissionedByFormatter, dateFormatter, scopeFormatter } from '../../shared/table/arkTableFormatters';
import { DeleteOpinionConfirmationModal } from './DeleteOpinionConfirmationModal';
import { ArkOpinion, clearOpinionTableFilters, downloadAllOpinionsStarted, downloadOpinionStarted, downloadOpinionSummaryStarted, fetchOpinionsStarted, getDeleteOpinionModalOpen, getIsLoading, getOpinionColumnSort, getOpinionFilters, getOpinionPageSize, getOpinions, getOpinionsPageNumber, getTotalOpinions, openOpinionStarted, opinionsPaginationNext, opinionsPaginationPrevious, setOpinionTableColumnSort, setOpinionTableFilters, setOpinionsPageSize, toggleDeleteConfirmationModal, updateOpinionCustomFilters, getOpinionCustomFilterHasUpdated, getSelectedOpinionCustomFilterId, getSelectedCustomFilterName } from './store';
import { OpinionCommissionedBy } from '../../admin/opinions/store';

export const OpinionsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchOpinionsStarted(), fetchAllDropdownListsStarted()]);
    const opinions = useAppSelector(getOpinions);
    const isLoading = useAppSelector(getIsLoading);
    const deleteOpinionModalOpen = useAppSelector(getDeleteOpinionModalOpen);
    const dropdownLists = useAppSelector(getAllDropdownLists);

    const pageNumber = useAppSelector(getOpinionsPageNumber);
    const totalOpinions = useAppSelector(getTotalOpinions);
    const opinionFilters = useAppSelector(getOpinionFilters);
    const pageSize = useAppSelector(getOpinionPageSize);
    const columnSort = useAppSelector(getOpinionColumnSort);

    const nextPage = useCallback(() => { dispatch(opinionsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(opinionsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setOpinionTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearOpinionTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setOpinionsPageSize(pageSize)); }, [dispatch]);

    const openOpinion = useCallback((data: ArkOpinion) => dispatch(openOpinionStarted(data.location, data.mimeType, data.opinionId)), [dispatch]);
    const deleteOpinion = useCallback((data: ArkOpinion) => { dispatch(toggleDeleteConfirmationModal(data)); }, [dispatch]);
    const saveOpinion = useCallback((data: ArkOpinion) => { dispatch(downloadOpinionStarted(data.location, data.mimeType, data.description)); }, [dispatch]);
    const saveAllOpinions = useCallback((data: ArkOpinion) => { dispatch(downloadAllOpinionsStarted(data.allOpinionLocations!, data.description)); }, [dispatch]);
    const downloadSummary = useCallback((data: ArkOpinion) => { dispatch(downloadOpinionSummaryStarted(data)); }, [dispatch]);

    const hasIndustryStandardPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));
    const hasViewISDAOpinionPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ISDA]));
    const hasViewICMAOpinionPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ICMA]));
    const hasViewISLAOpinionPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.MEMBER_OF_ISLA]));
    const hasDownloadPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.DOWNLOAD_OPINIONS]));
    const hasDeletePermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.DELETE_OPINIONS]));
    const hasDownloadSummaryPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.DOWNLOAD_OPINION_SUMMARIES]));
    const opinionFilterUpdated = useAppSelector(getOpinionCustomFilterHasUpdated);
    const selectedFilterId = useAppSelector(getSelectedOpinionCustomFilterId);
    const selectedFilterName = useAppSelector(getSelectedCustomFilterName);

    const isPermittedToView = useCallback((commissionedBy: OpinionCommissionedBy | string | null) => {
        switch (commissionedBy) {
            case OpinionCommissionedBy.ISDA:
                return hasViewISDAOpinionPermission;
            case OpinionCommissionedBy.ISLA:
                return hasViewISLAOpinionPermission;
            case OpinionCommissionedBy.ICMA:
                return hasViewICMAOpinionPermission;
            default:
                return false;
        }
    }, [hasViewISDAOpinionPermission, hasViewISLAOpinionPermission, hasViewICMAOpinionPermission]);

    const canDownload = useCallback((opinion: ArkOpinion) => (!!opinion.bespoke || isPermittedToView(opinion.commissionedBy)) && hasDownloadPermission, [isPermittedToView, hasDownloadPermission]);
    const canDownloadSummary = useCallback((opinion: ArkOpinion) => (!isNull(opinion.opinionSummaryId) && !isUndefined(opinion.opinionSummaryId)) && hasDownloadSummaryPermission, [hasDownloadSummaryPermission]);
    const canDelete = useCallback((opinion: ArkOpinion) => (!!opinion.bespoke && hasDeletePermission) || hasIndustryStandardPermission, [hasIndustryStandardPermission, hasDeletePermission]);

    const opinionActions = useCallback((data: ArkOpinion): Action[] => {
        const showDelete = canDelete(data);
        const showDownload = canDownload(data);
        const showDownloadSummary = canDownloadSummary(data);
        let actions: Action[] = [
            { label: 'Open', icon: Document, onClick: () => openOpinion(data), withSeparator: !showDownload && !showDownloadSummary && showDelete }
        ];

        if (showDownload) {
            actions.push(
                { label: 'Download', icon: SaveFile, onClick: () => saveOpinion(data) },
                { label: `Download All (${getOr([], 'allOpinionLocations', data).length})`, icon: Download, onClick: () => saveAllOpinions(data), withSeparator: showDelete && !showDownloadSummary }
            );
        }

        if (showDownloadSummary) {
            actions.push({ label: 'Download Summary', icon: Download, onClick: () => downloadSummary(data), withSeparator: showDelete });
        }

        if (showDelete) {
            actions.push({ label: 'Delete', icon: Delete, onClick: () => deleteOpinion(data), isDelete: true });
        }
        return actions;
    }, [openOpinion, saveOpinion, saveAllOpinions, deleteOpinion, canDelete, canDownload, canDownloadSummary, downloadSummary]);

    const columnDefs: ArkTableColumn[] = useMemo(() => {
        let columns: ArkTableColumn[] = [
            { id: 'opinionJurisdiction', header: 'Jurisdiction', field: 'jurisdiction', width: hasIndustryStandardPermission ? 0.15 : 0.18, canFilter: true, canSort: true },
            { id: 'focus', header: 'Focus', field: 'focus', width: hasIndustryStandardPermission ? 0.13 : 0.15, canFilter: true, component: 'tooltip', canSort: true },
            { id: 'bespoke', header: 'Bespoke/Industry Standard', field: 'bespoke', width: 0.1, valueFormatter: bespokeFormatter, canFilter: true, canSort: true },
            { id: 'scope', header: 'Scope', field: 'scope', width: 0.14, component: 'tooltip', canFilter: true, valueFormatter: scopeFormatter, canSort: true },
            { id: 'commissionedBy', header: 'Commissioned By', field: 'commissionedBy', width: 0.13, valueFormatter: commissionedByFormatter, component: 'tooltip', canFilter: true, canSort: true },
            { id: 'dateOfOpinion', header: 'Date of Opinion', field: 'dateOfOpinion', width: 0.1, valueFormatter: dateFormatter, canSort: true },
            { id: 'approvedBy', header: 'Signed Off?', field: 'approvedBy', width: 0.07, component: 'tick' },
            { id: 'approvalDate', header: 'Sign Off Date', field: 'approvalDate', component: 'colorDate', width: 0.07 },
            { id: 'actions', header: '', field: '', width: 0.06, component: 'action', actions: opinionActions }
        ];
        if (hasIndustryStandardPermission) {
            columns.splice(8, 0, { id: 'isDraft', header: 'Published', field: 'isDraft', width: 0.05, component: 'tick', canSort: true, valueFormatter: value => value === 0 });
        }
        return columns;
    }, [hasIndustryStandardPermission, opinionActions]);

    const opinionJurisdictionOptions = useMemo(() => {
        const jurisdictionList = dropdownLists.find(({ name }) => name === 'OpinionJurisdiction');
        return jurisdictionList ? jurisdictionList.options.map(type => ({ value: type, label: type })) : [];
    }, [dropdownLists]);

    const bespokeOptions = useMemo(() => [{ label: 'Bespoke', value: '1' }, { label: 'Industry Standard', value: '0' }], []);

    const scopeOptions = useMemo(() => opinionScopeOptions(), []);
    const commissionedByOptions = useMemo(() => opinionCommissionedByOptions(), []);

    const filterDropdownOptions = useMemo(() => ({
        scope: scopeOptions,
        bespoke: bespokeOptions,
        commissionedBy: commissionedByOptions,
        jurisdiction: opinionJurisdictionOptions,
        focus: allFocusOptions
    }), [bespokeOptions, opinionJurisdictionOptions, scopeOptions, commissionedByOptions]);

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setOpinionTableColumnSort(columnSort)); }, [dispatch]);

    const saveCustomFilters = useCallback((label: string, createNew: boolean) => dispatch(updateOpinionCustomFilters(label, createNew)), [dispatch]);

    return (
        <>
            <ArkTable
                colDefs={columnDefs}
                rowData={opinions}
                testId='complete-opinions'
                isLoading={isLoading}
                page={pageNumber}
                total={totalOpinions}
                next={nextPage}
                previous={previousPage}
                filters={opinionFilters}
                updateFilter={updateFilter}
                clearAllFilters={clearAllFilters}
                onRowDoubleClicked={row => openOpinion(row as ArkOpinion)}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                filterDropdownOptions={filterDropdownOptions}
                removeTextFilter={['scope', 'bespoke', 'focus', 'commissionedBy']}
                toggleSort={toggleColumnSort}
                columnSort={columnSort}
                saveCustomFilters={saveCustomFilters}
                saveButtonIsVisible={opinionFilterUpdated}
                customFilterName={selectedFilterName}
                isExistingCustomFilter={!isNull(selectedFilterId)}
            />
            <DeleteOpinionConfirmationModal isOpen={deleteOpinionModalOpen} />
        </>
    );
};
