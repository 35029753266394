import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { isEqual, isNull, min, noop } from 'lodash/fp';

import { CalculationBuilder as CalculationBuilderType, CalculationType, CalculatorInputType } from './constants';
import { Delete } from '../icons';
import { Icon } from '../icon/Icon';
import { CustomTooltip } from '../tooltip';
import styles from './Calculator.module.scss';

const { white, lightGrey, red, grey, primary } = styles;

interface CalculationBuilderProps {
    builderIndex: number | null;
    calculationIndex: number;
    selectedBuilderIndex: number | null;
    selectedCalculationIndex: number;
    selectedCalculationBuilder: CalculationType;
    builderType?: CalculationType;
    isEditing: boolean;
    builder: CalculationBuilderType;
    onClickBuilder: () => void;
    deleteBuilder?: () => void;
    onClickItem: (index: number) => void;
    onMouseOver: (index: number) => void;
    onMouseOut: () => void;
    showOnHover: (index: number) => boolean;
    getFontSize: (type: CalculatorInputType) => string;
    updateTextField?: (value: string, index: number) => void;
    getBuilderWidth: (index: number | null, isOutput: boolean, isGracePeriod: boolean) => string;
    showRemoveRow: boolean;
    isOutput?: boolean;
    isGracePeriod?: boolean;
    bracketsInvalid: boolean;
    includeMarginBottom?: boolean;
    clearBuilder: () => void;
}

export const CalculationBuilder: React.FC<CalculationBuilderProps> = ({
    builder,
    builderIndex,
    calculationIndex,
    selectedBuilderIndex,
    selectedCalculationIndex,
    selectedCalculationBuilder,
    builderType = null,
    isEditing,
    onClickBuilder,
    deleteBuilder,
    onClickItem,
    onMouseOver,
    onMouseOut,
    showOnHover,
    getFontSize,
    getBuilderWidth,
    showRemoveRow,
    isOutput = false,
    isGracePeriod = false,
    bracketsInvalid,
    updateTextField = noop,
    includeMarginBottom = true,
    clearBuilder
}) => {
    const isSelected = useMemo(() =>
        isEqual(builderIndex, selectedBuilderIndex) &&
        isEqual(calculationIndex, selectedCalculationIndex) &&
        (isNull(builderType) || isEqual(builderType, selectedCalculationBuilder))
    , [selectedBuilderIndex, selectedCalculationIndex, calculationIndex, builderIndex, selectedCalculationBuilder, builderType]);

    const builderWidth = useMemo(() => getBuilderWidth(builderIndex, isOutput, isGracePeriod), [getBuilderWidth, isOutput, isGracePeriod, builderIndex]);

    const placeholder = useMemo(() => {
        if (builder.length) {
            return null;
        }
        if (isOutput) {
            return isEditing && isSelected ? <div className={styles.builderPlaceholder}>Choose calculator option to build output</div> : null;
        }
        if (isGracePeriod) {
            return <div className={styles.builderPlaceholder}>{isEditing && isSelected ? 'Choose calculator option to build grace period (optional)' : '(if applicable)'}</div>;
        }
        return <div className={styles.builderPlaceholder} style={{ color: (isEditing && !isSelected) || !isEditing ? grey : primary }}>{isEditing && isSelected ? 'Choose calculator option to build condition (optional)' : 'Condition of clause (optional)'}</div>;
    }, [isSelected, isEditing, builder, isOutput, isGracePeriod]);

    const textRows = useCallback((textLength: number) => min([Math.ceil((textLength + 1) / 40), 8])!, []);

    const getOutputValue = useCallback((type: CalculatorInputType, value: string, index: number) => {
        if (type === CalculatorInputType.OTHER) {
            return <textarea
                className={styles.otherOutput}
                value={value}
                onChange={e => updateTextField(e.target.value, index)}
                placeholder='Other...'
                maxLength={2000}
                autoFocus
                rows={textRows(value.length)}
                cols={30}
                disabled={!isEditing}
            />;
        }
        return <div className={styles.builderValue} style={{ fontSize: getFontSize(type) }} onClick={() => onClickItem(index)}>{value}</div>;
    }, [getFontSize, onClickItem, updateTextField, textRows, isEditing]);

    return (
        <div
            className={classnames(styles.builderItemsWrapper,
                {
                    [styles.selectedBuilder]: isSelected && isEditing,
                    [styles.invalidBrackets]: isEditing && bracketsInvalid,
                    [styles.builderMarginBottom]: includeMarginBottom
                })}
            style={{ cursor: isEditing ? 'pointer' : 'default' }}
        >
            {isOutput && <div className={styles.outputLabel}>THEN</div>}
            {isGracePeriod && <div className={styles.gracePeriodLabel}>Grace Period</div>}
            <div className={styles.builderItems} onClick={onClickBuilder} style={{ width: builderWidth }}>
                {!!builder.length && builder.map(({ value, type }, itemIndex) => (
                    <div
                        className={styles.builderWrapper}
                        key={itemIndex}
                        onMouseOver={() => onMouseOver(itemIndex)}
                        onMouseLeave={onMouseOut}
                        style={{ boxShadow: `1px 1px 3px ${showOnHover(itemIndex) ? red : lightGrey}`, backgroundColor: type === CalculatorInputType.LOGICAL_OPERATOR ? 'yellow' : white }}
                    >
                        {getOutputValue(type, value, itemIndex)}
                        {showOnHover(itemIndex) &&
                            <div className={styles.deleteItem} onClick={() => onClickItem(itemIndex)}>
                                <Icon icon={Delete} fontSize={10} />
                            </div>
                        }
                    </div>
                ))}
                {placeholder}
            </div>
            {showRemoveRow && isEditing && builder.length === 0 &&
                <CustomTooltip overlayText='Remove row'>
                    <button className={styles.removeBuilderRow} onClick={deleteBuilder}>
                        <Icon icon={Delete} fontSize={15} />
                    </button>
                </CustomTooltip>
            }
            {isEditing && builder.length > 0 &&
                <CustomTooltip overlayText='Clear row'>
                    <button className={styles.clearBuilderRow} onClick={clearBuilder}>
                        <Icon icon={Delete} fontSize={15} />
                    </button>
                </CustomTooltip>
            }
        </div>
    );
};
