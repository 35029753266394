import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { ArkTableClause, ClauseTagDropdownOptions, clearClauseLibraryTableFilters, clauseLibraryPaginationNext, clauseLibraryPaginationPrevious, duplicateClause, fetchAllClausesStarted, getClauseLibraryDropdownOptions, getClauseColumnSort, getClauseFilters, getClausePageSize, getClausesPageNumber, getTotalClauses, openSavedClause, setClauseLibraryPageSize, setClauseLibraryTableColumnSort, setClauseLibraryTableFilters, toggleDeleteClauseModal, ClauseTableTab } from './store';
import { ArkTable, ArkTableColumn, ColumnSort } from '../shared/table/ArkTable';
import { TableFilterType } from '../shared/modal/TableFilterModal';
import { Action } from '../shared/modal/ActionModal';
import { Delete, Document, LongText, SaveFile } from '../shared/icons';
import { useFetchStarted } from '../../hooks/useFetchStarted';
import { getUserHasFeaturePermission, getUserHasFeaturePermissionNoAdmin } from '../auth/login/store';
import { FeaturePermission } from '../admin/users/store';

export const filterDropdownOptions = (clauseLibraryDropdownOptions: ClauseTagDropdownOptions) => {
    const { agreementTypeOptions, jurisdictionOptions, counterpartyTypeOptions, productTypeOptions, provisionTypeOptions, miscellaneousOptions, opinionsOptions } = clauseLibraryDropdownOptions;
    return {
        agreementTags: agreementTypeOptions,
        jurisdictionTags: jurisdictionOptions,
        counterpartyTags: counterpartyTypeOptions,
        productTags: productTypeOptions,
        provisionTags: provisionTypeOptions,
        miscellaneousTags: miscellaneousOptions,
        opinionsTags: opinionsOptions,
    };
};

export const clauseColumnDefs = (clauseActions: (data: ArkTableClause) => Action[]): ArkTableColumn[] => {
    const tagsFormatter = (tags: string[]) => tags.map(tag => tag);
    return [
        { id: 'title', header: 'Title', field: 'clauseTitle', width: 0.13, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'description', header: 'Description', field: 'clauseDescription', width: 0.06, component: 'iconTooltip', icon: LongText, canFilter: true, canSort: false },
        { id: 'agreementType', header: 'Agreement Tags', field: 'agreementTags', width: 0.11, component: 'tooltipListLabel', canFilter: true, canSort: true, valueFormatter: tagsFormatter },
        { id: 'jurisdiction', header: 'Jurisdiction Tags', field: 'jurisdictionTags', width: 0.11, component: 'tooltipListLabel', canFilter: true, canSort: true, valueFormatter: tagsFormatter },
        { id: 'counterpartyType', header: 'Counterparty Tags', field: 'counterpartyTags', width: 0.11, component: 'tooltipListLabel', canFilter: true, canSort: true, valueFormatter: tagsFormatter },
        { id: 'productType', header: 'Product Tags', field: 'productTags', width: 0.11, component: 'tooltipListLabel', canFilter: true, canSort: true, valueFormatter: tagsFormatter },
        { id: 'provisionType', header: 'Provision Tags', field: 'provisionTags', width: 0.11, component: 'tooltipListLabel', canFilter: true, canSort: true, valueFormatter: tagsFormatter },
        { id: 'miscellaneousTags', header: 'Miscellaneous Tags', field: 'miscellaneousTags', width: 0.11, component: 'tooltipListLabel', canFilter: true, canSort: true, valueFormatter: tagsFormatter },
        { id: 'opinionsTags', header: 'Opinions Tags', field: 'opinionsTags', width: 0.11, component: 'tooltipListLabel', canFilter: true, canSort: true, valueFormatter: tagsFormatter },
        { id: 'actions', header: '', field: '', width: 0.04, component: 'action', actions: clauseActions }
    ];
};

interface ClauseLibraryTableProps {
    clauseTableTab: ClauseTableTab;
    allClauses: ArkTableClause[];
    isLoading: boolean;
}

export const ClauseLibraryTable: React.FC<ClauseLibraryTableProps> = ({ clauseTableTab, allClauses, isLoading }) => {
    useFetchStarted([fetchAllClausesStarted(1)]);

    const dispatch = useAppDispatch();
    const clauseLibraryDropdownOptions = useAppSelector(getClauseLibraryDropdownOptions);
    const isTemplateView = useMemo(() => clauseTableTab !== ClauseTableTab.CLIENT_CLAUSE_LIBRARY, [clauseTableTab]);

    const pageNumber = useAppSelector(getClausesPageNumber);
    const totalClauses = useAppSelector(getTotalClauses);
    const clauseFilters = useAppSelector(getClauseFilters);
    const columnSort = useAppSelector(getClauseColumnSort);
    const pageSize = useAppSelector(getClausePageSize);
    const nextPage = useCallback(() => dispatch(clauseLibraryPaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(clauseLibraryPaginationPrevious()), [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setClauseLibraryTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearClauseLibraryTableFilters()), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setClauseLibraryPageSize(pageSize)), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setClauseLibraryTableColumnSort(columnSort)); }, [dispatch]);

    const hasAddClausePermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.ADD_CLAUSES]));
    const hasSystemClausePermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.SYSTEM_CLAUSE_MANAGEMENT]));

    const openClause = useCallback(({ clauseId }: ArkTableClause) => dispatch(openSavedClause(clauseId)), [dispatch]);
    const deleteClause = useCallback(({ clauseId }: ArkTableClause) => dispatch(toggleDeleteClauseModal(clauseId)), [dispatch]);
    const duplicate = useCallback(({ clauseId }: ArkTableClause) => dispatch(duplicateClause(clauseId!, false, hasSystemClausePermission)), [dispatch, hasSystemClausePermission]);

    const clauseActions = useCallback((data: ArkTableClause): Action[] => {
        let options: Action[] = [
            { label: 'Open', icon: Document, onClick: () => openClause(data) },
        ];
        if (!isTemplateView && hasAddClausePermission) {
            options.push(
                { label: 'Duplicate', withSeparator: true, icon: SaveFile, onClick: () => duplicate(data) },
                { label: 'Delete', isDelete: true, icon: Delete, onClick: () => deleteClause(data) }
            );
        }
        if (isTemplateView && hasSystemClausePermission) {
            options.push(
                { label: 'Duplicate', icon: SaveFile, onClick: () => duplicate(data), withSeparator: hasSystemClausePermission },
                { label: 'Delete', isDelete: true, icon: Delete, onClick: () => deleteClause(data) }
            );
        }
        return options;
    }, [openClause, deleteClause, duplicate, hasAddClausePermission, isTemplateView, hasSystemClausePermission]);

    return (
        <>
            <ArkTable
                colDefs={clauseColumnDefs(clauseActions)}
                rowData={allClauses}
                testId='client-clauses'
                isLoading={isLoading}
                page={pageNumber}
                total={totalClauses}
                next={nextPage}
                previous={previousPage}
                filters={clauseFilters}
                updateFilter={updateFilter}
                clearAllFilters={clearAllFilters}
                onRowDoubleClicked={row => openClause(row as ArkTableClause)}
                columnSort={columnSort}
                toggleSort={toggleColumnSort}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                filterDropdownOptions={filterDropdownOptions(clauseLibraryDropdownOptions)}
            />
        </>
    );
};
