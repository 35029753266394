import { isNull, noop } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { adminRoles, systemAdminRole, UserRole } from '../../constants/permittedRoles';
import { roleLabels } from '../../constants/roleLabels';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { Icon } from '../../shared/icon/Icon';
import { Delete, User } from '../../shared/icons';
import { Tick } from '../../shared/icons/Tick';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { DropzonePicture } from '../../shared/picture/DropzonePicture';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Spinner } from '../../shared/spinner/Spinner';
import { Text } from '../../shared/text/Text';
import { TextWithIcon } from '../../shared/text/TextWithIcon';
import { FeaturePermissions } from './FeaturePermissions';
import { checkEmailValid, checkUsernameAvailableStarted, getCheckingUsername, getEmailValid, getNewUser, getUsernameUnavailable, NewUser, updateUserValue, upsertUserStarted } from './store';
import styles from './Users.module.scss';

interface UpsertUserModalProps {
    isOpen: boolean;
    closeModal: () => void;
    clientName?: string;
    clientView?: boolean;
}

export const UpsertUserModal: React.FC<UpsertUserModalProps> = ({ isOpen, closeModal, clientName, clientView = false }) => {
    const { username, email, userId, role, featurePermissions, forenames, surname, profilePicture } = useAppSelector(getNewUser);
    const usernameUnavailable = useAppSelector(getUsernameUnavailable);
    const checkingUsername = useAppSelector(getCheckingUsername);
    const isEmailValid = useAppSelector(getEmailValid);

    const userModalHeader = `${userId ? 'Update' : 'Create a New'} User${clientName ? ` (${clientName})` : ''}`;
    const userConfirmLabel = userId ? 'Update' : 'Add';

    const confirmDisabled = !username.length || !email.length || usernameUnavailable || !isEmailValid || !role;

    const dispatch = useAppDispatch();
    const updateUserValueByKey = (key: keyof NewUser, value: string | number[] | null) => dispatch(updateUserValue(key, value));

    if (email && email.length) {
        dispatch(checkEmailValid(email));
    }

    const updateUsername = (value: string) => {
        dispatch(checkUsernameAvailableStarted());
        updateUserValueByKey('username', value);
    };

    const updateEmail = (value: string) => {
        updateUserValueByKey('email', value);
        dispatch(checkEmailValid(value));
    };

    const updateRole = (value: DropdownOption | Options<DropdownOption> | null) => {
        let role = null;
        if (!isNull(value)) {
            role = (value as DropdownOption).value;
        }
        updateUserValueByKey('role', role);
    };

    const upsertUser = () => dispatch(upsertUserStarted(clientView));

    const usernameIndicator = () => {
        if (username.length > 0) {
            if (checkingUsername) {
                return <Spinner size={11} />;
            }
            return usernameUnavailable ? <Icon fontSize={15} icon={Delete} color='red' /> : <Icon fontSize={15} icon={Tick} color='green' />;
        }
    };

    const emailIndicator = () => {
        if (email.length === 0) {
            return undefined;
        }
        return isEmailValid ? <Icon fontSize={15} icon={Tick} color='green' /> : <Icon fontSize={15} icon={Delete} color='red' />;
    };

    const confirmDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!username.length || !email.length) {
            disabledArray.push('You must include a username and email address');
        }
        if (username.length > 0 && usernameUnavailable) {
            disabledArray.push('The username you have entered is not available');
        }
        if (!isEmailValid && email.length > 0) {
            disabledArray.push('You must use a valid email address');
        }
        if (!role) {
            disabledArray.push('You must select a user role');
        }
        return disabledArray;
    }, [username, email, isEmailValid, usernameUnavailable, role]);

    const roleOptions = [
        { value: UserRole.USER, label: roleLabels[UserRole.USER] },
        { value: UserRole.ADMIN, label: roleLabels[UserRole.ADMIN] },
    ];

    const roleValue = { value: role, label: roleLabels[role] };

    const isAdminUser = adminRoles.includes(role);
    const isSystemAdminUser = systemAdminRole.includes(role);

    const updateFeaturePermissions = (featurePermissions: number[]) => updateUserValueByKey('featurePermissions', featurePermissions);

    const initials = useMemo(() => `${forenames.charAt(0)}${surname.charAt(0)}`, [forenames, surname]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel={userConfirmLabel}
            confirmDisabled={confirmDisabled}
            testId='upsert-user'
            confirm={upsertUser}
            confirmDisabledTooltip={confirmDisabledTooltip}
        >
            <ModalHeader label={userModalHeader} icon={User} testId='users-modal' />
            <div className={styles.userContentWrapper}>
                <div className={styles.profilePictureWrapper}>
                    <DropzonePicture profilePicture={profilePicture} initials={initials} setProfilePicture={noop} disabled />
                </div>
                <div className={styles.inputsWrapper} data-testid='users-modal-wrapper'>
                    <Scrollable>
                        <TextWithIcon
                            label='Username'
                            testId='upsert-user-username'
                            placeholder='Username'
                            value={username}
                            onChange={e => updateUsername(e.target.value)}
                            iconRenderer={usernameIndicator()}
                            width='100%'
                        />
                        <Text
                            label='Forename(s)'
                            testId='upsert-user-forenames'
                            placeholder='Forename(s)'
                            value={forenames}
                            maxLength={128}
                            onChange={e => updateUserValueByKey('forenames', e.target.value)}
                        />
                        <Text
                            label='Surname'
                            testId='upsert-user-surname'
                            placeholder='Surname'
                            value={surname}
                            maxLength={128}
                            onChange={e => updateUserValueByKey('surname', e.target.value)}
                        />
                        <TextWithIcon
                            label='Email'
                            testId='upsert-user-email'
                            placeholder='Email'
                            value={email}
                            onChange={e => updateEmail(e.target.value)}
                            iconRenderer={emailIndicator()}
                            width='100%'
                        />
                        <Dropdown
                            value={roleValue}
                            options={roleOptions}
                            testId='user-role'
                            onChange={updateRole}
                            label='Role'
                            marginBottom='10px'
                            isClearable={false}
                            menuPortalTarget={document.body}
                        />
                        <FeaturePermissions featurePermissionIds={featurePermissions} updateFeaturePermissions={updateFeaturePermissions} isAdmin={isAdminUser} isSystemAdmin={isSystemAdminUser} />
                        <div>
                            <div></div>
                        </div>
                    </Scrollable>
                </div>
            </div>
        </ConfirmationModal>
    );
};
