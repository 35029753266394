import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getCurrentInstanceAnnexDefinitions, updateInstanceAnnexField } from '../../../agency-annex/store';
import styles from '../DatasetInstance.module.scss';
import { getDocumentAnalysisPageTotal, getDocumentInView, getSecondaryDocumentUrl, SelectedDocument, setDocumentAnalysisSelectedPage, toggleDocumentInView } from '../../../documents/my-documents/store';
import { IconButton } from '../../../shared/button/IconButton';
import { ArrowRight } from '../../../shared/icons';
import { CustomTooltip, InformationTooltip } from '../../../shared/tooltip';
import { Text } from '../../../shared/text/Text';

const { amber, primary } = styles;

interface AnnexPageRefsProps {
    index: number;
}

export const AnnexPageRefs: React.FC<AnnexPageRefsProps> = ({ index }) => {
    const dispatch = useAppDispatch();
    const instanceAnnexDefinitions = useAppSelector(getCurrentInstanceAnnexDefinitions);
    const documentInView = useAppSelector(getDocumentInView);
    const secondaryDocumentUrl = useAppSelector(getSecondaryDocumentUrl);
    const numPages = useAppSelector(getDocumentAnalysisPageTotal);

    const scrollToPage = useCallback((pageNumber: number | null) => {
        if (!isNull(documentInView)) {
            if (documentInView !== SelectedDocument.SECONDARY && !isNull(secondaryDocumentUrl)) {
                dispatch(toggleDocumentInView(SelectedDocument.SECONDARY));
            }
            dispatch(setDocumentAnalysisSelectedPage(pageNumber));
        }
    }, [documentInView, secondaryDocumentUrl, dispatch]);

    const updateAgencyAnnexStartPage = useCallback((value: string) => {
        if (!isNaN(parseInt(value)) || value === '') {
            dispatch(updateInstanceAnnexField('startPage', parseInt(value), index));
            if (value === '') {
                dispatch(setDocumentAnalysisSelectedPage(null));
            }
        }
    }, [dispatch, index]);

    const updateAgencyAnnexEndPage = useCallback((value: string) => {
        if (!isNaN(parseInt(value)) || value === '') {
            dispatch(updateInstanceAnnexField('endPage', parseInt(value), index));
            if (value === '') {
                dispatch(setDocumentAnalysisSelectedPage(null));
            }
        }
    }, [dispatch, index]);

    const agencyAnnexStartPage = useMemo(() => instanceAnnexDefinitions[index].startPage || null, [instanceAnnexDefinitions, index]);
    const agencyAnnexEndPage = useMemo(() => instanceAnnexDefinitions[index].endPage || null, [instanceAnnexDefinitions, index]);
    const agencyAnnexExtractedData = useMemo(() => instanceAnnexDefinitions[index].extractedData || null, [instanceAnnexDefinitions, index]);
    const agencyAnnexPageTooltip = useMemo(() => 'By providing the start and end of the Agency Annex Table, we will attempt to extract this information automatically.', []);

    const linkToAgencyAnnexStartPageDisabled = useMemo(() => isNull(agencyAnnexStartPage) || (!isNull(numPages) && !isNull(agencyAnnexStartPage) && agencyAnnexStartPage > numPages), [agencyAnnexStartPage, numPages]);
    const linkToAgencyAnnexEndPageDisabled = useMemo(() => isNull(agencyAnnexEndPage) || (!isNull(numPages) && !isNull(agencyAnnexEndPage) && agencyAnnexEndPage > numPages), [agencyAnnexEndPage, numPages]);

    return (
        <div className={styles.schedulePageInfo}>
            <div className={styles.schedulePageWrapper} style={{ width: '50%' }}>
                <div className={styles.schedulePageLabelWrapper}>
                    <CustomTooltip overlayText='Agency Annex Start Page'>
                        <div className={styles.schedulePageLabel}>Start Page:</div>
                    </CustomTooltip>
                    <div className={styles.schedulePageTooltip}>
                        {isNull(agencyAnnexExtractedData) && <InformationTooltip content={agencyAnnexPageTooltip} />}
                    </div>
                </div>
                <div className={styles.schedulePageInput}>
                    <Text
                        testId={`agency-annex-start-page-${index}`}
                        value={agencyAnnexStartPage?.toString()}
                        onChange={e => updateAgencyAnnexStartPage(e.target.value)}
                        marginBottom='0px'
                        width='40%'
                        marginRight='10px'
                        borderColour={isNull(agencyAnnexStartPage) ? amber : primary}
                        disabled={!isNull(agencyAnnexExtractedData)}
                    />
                    <IconButton icon={ArrowRight} onClick={() => scrollToPage(agencyAnnexStartPage)} disabled={linkToAgencyAnnexStartPageDisabled} testId='agency-annex-start-page' />
                </div>
            </div>
            <div className={styles.schedulePageWrapper} style={{ width: 'calc(50% - 10px)', marginLeft: '10px' }}>
                <div className={styles.schedulePageLabelWrapper}>
                    <CustomTooltip overlayText='Agency Annex End Page'>
                        <div className={styles.schedulePageLabel}>End Page:</div>
                    </CustomTooltip>
                </div>
                <div className={styles.schedulePageInput}>
                    <Text
                        testId={`agency-annex-end-page-${index}`}
                        value={agencyAnnexEndPage?.toString()}
                        onChange={e => updateAgencyAnnexEndPage(e.target.value)}
                        marginBottom='0px'
                        width='40%'
                        marginRight='10px'
                        borderColour={isNull(agencyAnnexEndPage) ? amber : primary}
                        disabled={!isNull(agencyAnnexExtractedData)}
                    />
                    <IconButton icon={ArrowRight} onClick={() => scrollToPage(agencyAnnexEndPage)} disabled={linkToAgencyAnnexEndPageDisabled} testId='agency-annex-end-page' />
                </div>
            </div>
        </div>
    );
};
