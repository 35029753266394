import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { getDocumentById, openDocument, searchDocuments, smartSearchDocuments } from '../../../../services/document';
import { getDocumentUrl } from '../../../../utils/getDocumentUrl';
import {
    openDocumentPreviewFailed,
    openDocumentPreviewStarted,
    openDocumentPreviewSuccessful,
    openIncompleteSearchDocument,
    searchDocumentsFailed,
    searchDocumentsStarted,
    searchDocumentsSuccessful,
    toggleSearchDocumentsView
} from './actions';
import { getSearchDocumentsView, getSearchFilters, getSimpleSearchTerm, getSmartSearchTerm } from './selectors';
import { SearchDocumentActionTypes, SearchDocumentsView, SearchFilter, SearchResult, SmartSearch } from './types';
import { ArkDocument, openIncompleteDocumentStarted, setSelectedIncompleteDocuments, toggleDocumentDetailsModal, getDocumentsView, DocumentsView } from '../../my-documents/store';

export function* attemptSearchDocuments({ payload }: ReturnType<typeof searchDocumentsStarted>) {
    try {
        const filters: SearchFilter = yield select(getSearchFilters);
        const searchType: SearchDocumentsView = yield select(getSearchDocumentsView);
        if (searchType === SearchDocumentsView.SIMPLE_SEARCH) {
            const searchTerm: string = yield select(getSimpleSearchTerm);
            if (searchTerm) {
                const { searchResults, pages, total }: { searchResults: SearchResult[], pages: number, total: number } = yield call(searchDocuments, { searchTerm, page: payload, filters });
                yield put(searchDocumentsSuccessful(searchResults, pages, searchType, total));
            } else {
                yield put(searchDocumentsSuccessful(null, 0, null, 0));
            }
        } else if (searchType === SearchDocumentsView.SMART_SEARCH) {
            const searchTerm: SmartSearch[] = yield select(getSmartSearchTerm);
            const smartSearchEmpty = searchTerm.length === 1 && !searchTerm[0].searchTerm;
            if (!smartSearchEmpty) {
                const { searchResults, pages, total }: { searchResults: SearchResult[], pages: number, total: number } = yield call(smartSearchDocuments, { searchTerm, page: payload, filters });
                yield put(searchDocumentsSuccessful(searchResults, pages, searchType, total));
            } else {
                yield put(searchDocumentsSuccessful(null, 0, null, 0));
            }
        }
    } catch (e) {
        yield put(searchDocumentsFailed((e as Error).message));
        toast.error('Something went wrong with your search. Please try again.');
    }
}

function* searchDocumentsWatcher() {
    yield takeLatest(SearchDocumentActionTypes.SEARCH_DOCUMENTS_STARTED, attemptSearchDocuments);
}

export function* attemptOpenDocumentPreview({ payload }: ReturnType<typeof openDocumentPreviewStarted>) {
    try {
        const { mimeType, location } = payload;
        const document: Blob = yield call(openDocument, { location });
        const documentUrl: string = yield call(getDocumentUrl, mimeType, document);
        yield put(openDocumentPreviewSuccessful(documentUrl, payload));
    } catch (e) {
        yield put(openDocumentPreviewFailed((e as Error).message));
        toast.error('Unable to open document. Please try again.');
    }
}

function* openDocumentPreviewWatcher() {
    yield takeEvery(SearchDocumentActionTypes.OPEN_DOCUMENT_PREVIEW_STARTED, attemptOpenDocumentPreview);
}

export function* attemptResetFilters() {
    yield put(searchDocumentsStarted(1));
}

function* resetFiltersWatcher() {
    yield takeEvery(SearchDocumentActionTypes.RESET_FILTERS, attemptResetFilters);
}

const getSearchViewPath = (searchView: SearchDocumentsView | null, documentsView: DocumentsView) => {
    switch (searchView) {
        case SearchDocumentsView.SMART_SEARCH:
        case SearchDocumentsView.SIMPLE_SEARCH:
            return '/documents/search';
        default:
            return documentsView === DocumentsView.PRE_EXECUTION_DRAFT ? '/documents/my-documents/pre-execution' : '/documents/my-documents';
    }
};

export function* redirectSearchDocumentView({ payload }: ReturnType<typeof toggleSearchDocumentsView>) {
    const { view } = payload;
    const documentsView: DocumentsView = yield select(getDocumentsView);
    const navigationPath = getSearchViewPath(view, documentsView);
    yield put(push(navigationPath));
}

function* redirectSearchDocumentViewWatcher() {
    yield takeEvery(SearchDocumentActionTypes.TOGGLE_SEARCH_DOCUMENTS_VIEW, redirectSearchDocumentView);
}

export function* attemptOpenIncompleteSearchDocument({ payload }: ReturnType<typeof openIncompleteSearchDocument>) {
    try {
        const incompleteDocument: ArkDocument = yield call(getDocumentById, { documentId: payload });
        yield put(push('/documents/my-documents/incomplete'));
        yield put(setSelectedIncompleteDocuments([incompleteDocument]));
        yield put(openIncompleteDocumentStarted(incompleteDocument));
        yield put(toggleDocumentDetailsModal(true));
    } catch (e) {
        toast.error('Unable to open incomplete document. Please try again.');
    }
}

function* openIncompleteSearchDocumentWatcher() {
    yield takeEvery(SearchDocumentActionTypes.OPEN_INCOMPLETE_SEARCH_DOCUMENT, attemptOpenIncompleteSearchDocument);
}

export function* searchDocumentsSaga() {
    yield all([
        fork(searchDocumentsWatcher),
        fork(openDocumentPreviewWatcher),
        fork(resetFiltersWatcher),
        fork(redirectSearchDocumentViewWatcher),
        fork(openIncompleteSearchDocumentWatcher)
    ]);
}
