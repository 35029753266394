import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Options } from 'react-select';

import { useAppDispatch } from '../../../../../hooks/react-redux';
import { capitaliseStartLetter } from '../../../../../utils/regex-utils';
import { CalculatorReadOnlyModal } from '../../../../datasets/fields/CalculatorReadOnlyModal';
import { IconButton } from '../../../../shared/button/IconButton';
import { CalculatorFieldType, freeTextOptionAvailable, gracePeriodAvailable, newCalculatorField, outputFieldAvailable } from '../../../../shared/calculator/constants';
import { Dropdown, DropdownOption } from '../../../../shared/dropdown/Dropdown';
import { Calculator } from '../../../../shared/icons';
import { ConfirmationModal } from '../../../../shared/modal/ConfirmationModal';
import { Toggle } from '../../../../shared/toggle';
import { SettingsModal, updateSettingsValue } from '../../store';
import styles from '../DatasetComponents.module.scss';

const { primary } = styles;

interface CalculatorTypeModalProps {
    isOpen: boolean;
    closeModal: () => void;
    settingsModalIndex: SettingsModal;
    fieldType: CalculatorFieldType | null | undefined;
    showGracePeriod?: boolean;
    showOutputField?: boolean;
    includeFreeTextOption?: boolean;
}

export const CalculatorTypeModal: React.FC<CalculatorTypeModalProps> = ({ isOpen, closeModal, settingsModalIndex, fieldType, showOutputField = false, showGracePeriod = false, includeFreeTextOption = false }) => {
    const dispatch = useAppDispatch();
    const { index, groupIndex } = settingsModalIndex;

    const fieldTypeOptions = useMemo(() => Object.values(CalculatorFieldType).map(value => ({ value, label: capitaliseStartLetter(value) })), []);
    const fieldTypeDropdownValue = useMemo(() => fieldType ? fieldTypeOptions.find(({ value }) => value === fieldType)! : null, [fieldType, fieldTypeOptions]);

    const gracePeriodToggleEnabled = useMemo(() => !!fieldType && gracePeriodAvailable.includes(fieldType), [fieldType]);
    const outputFieldToggleEnabled = useMemo(() => !!fieldType && outputFieldAvailable.includes(fieldType), [fieldType]);
    const includeFreeTextToggleEnabled = useMemo(() => !!fieldType && freeTextOptionAvailable.includes(fieldType), [fieldType]);

    const updateFieldType = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        if (!isNull(dropdownValue)) {
            const value = (dropdownValue as DropdownOption).value;
            const showOutput = outputFieldAvailable.includes(value as CalculatorFieldType);
            dispatch(updateSettingsValue('calculatorFieldType', value, index!, groupIndex));
            dispatch(updateSettingsValue('showOutputField', showOutput, index!, groupIndex));
            // PO Michael wants the default behaviour for Grace Period to always be off
            dispatch(updateSettingsValue('showGracePeriod', false, index!, groupIndex));
            // We do not want to enable the inclusion of free text by default ever regardless of type, this has to be enabled by System Admin
            dispatch(updateSettingsValue('includeFreeTextOption', false, index!, groupIndex));
        }
    }, [dispatch, groupIndex, index]);

    const updateShowGracePeriod = useCallback((value: boolean) => dispatch(updateSettingsValue('showGracePeriod', value, index!, groupIndex)), [dispatch, index, groupIndex]);
    const updateShowOutput = useCallback((value: boolean) => dispatch(updateSettingsValue('showOutputField', value, index!, groupIndex)), [dispatch, index, groupIndex]);
    const updateIncludeFreeTextOption = useCallback((value: boolean) => dispatch(updateSettingsValue('includeFreeTextOption', value, index!, groupIndex)), [dispatch, index, groupIndex]);

    const [showCalculatorModal, setShowCalculatorModal] = useState<boolean>(false);
    const openCalculatorModal = () => setShowCalculatorModal(true);
    const closeCalculatorModal = () => setShowCalculatorModal(false);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            showConfirm={false}
            closeLabel='Close'
            testId='calculator-field-type'
            showOverlay={false}
        >
            <div className={styles.settingsModalLinkWrapper} data-testid='calculator-field-type-wrapper'>
                <div className={styles.settingsModalLinkTitle}>Select Calculator Field Type</div>
                <div className={styles.calculatorSettingsModalLinkDropdown}>
                    <Dropdown
                        options={fieldTypeOptions}
                        value={fieldTypeDropdownValue}
                        onChange={updateFieldType}
                        isClearable={false}
                    />
                    <IconButton disabled={isNull(fieldTypeDropdownValue)} icon={Calculator} onClick={openCalculatorModal} fontSize={40} />
                </div>
                <div className={styles.calculatorRadioWrapper}>
                    <div className={styles.calculatorRadioTitle}>Show Output (THEN)</div>
                    <Toggle
                        checked={showOutputField}
                        onChange={updateShowOutput}
                        disabled={!outputFieldToggleEnabled}
                        testId='calculator-field-output'
                    />
                </div>
                <div className={styles.calculatorRadioWrapper}>
                    <div className={styles.calculatorRadioTitle}>Show Grace Period</div>
                    <Toggle
                        checked={showGracePeriod}
                        onChange={updateShowGracePeriod}
                        disabled={!gracePeriodToggleEnabled}
                        testId='calculator-field-grace-period'
                    />
                </div>
                <div className={styles.calculatorRadioWrapper}>
                    <div className={styles.calculatorRadioTitle}>Include Free Text Option</div>
                    <Toggle
                        checked={includeFreeTextOption}
                        onChange={updateIncludeFreeTextOption}
                        disabled={!includeFreeTextToggleEnabled}
                        testId='calculator-field-free-text-option'
                    />
                </div>
            </div>
            {showCalculatorModal && fieldType &&
                <CalculatorReadOnlyModal
                    isOpen={showCalculatorModal}
                    disabled={false}
                    closeModal={closeCalculatorModal}
                    fieldType={fieldType}
                    value={[newCalculatorField()]}
                    borderColor={primary}
                    showOutputField={showOutputField}
                    showGracePeriod={showGracePeriod}
                    includeFreeTextOption={includeFreeTextOption}
                />
            }
        </ConfirmationModal>
    );
};
