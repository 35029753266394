import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getClientHasFeaturePermission } from '../../auth/login/store';
import { MigrateFunctionConfirmationModal } from '../../dora/my-companies/MigrateFunctionConfirmationModal';
import { IconButton } from '../../shared/button/IconButton';
import { PlusButton } from '../../shared/button/PlusButton';
import { Icon } from '../../shared/icon/Icon';
import { Robot, Search, User } from '../../shared/icons';
import { CustomTooltip } from '../../shared/tooltip';
import { ClientFeaturePermission } from '../users/store';
import styles from './Entity.module.scss';
import { EntityClassificationModal } from './EntityClassificationModal';
import { EntityModal } from './EntityModal';
import { EntityTable } from './EntityTable';
import { SearchEntityModal } from './SearchEntityModal';
import { EntityModalType, fetchAllCompanyClassificationEntitiesStarted, getFunctionMigration, getMigrateFunctionConfirmationModalOpen, getModalOpen, migrateDoraCompanyFunctionsStarted, searchEntitiesStarted, toggleMigrateDoraFunctionConfirmationModal, toggleModal, updateDoraCompanyFunctionMigrations, upsertEntityHandler } from './store';

export const Entity: React.FC = () => {
    const dispatch = useAppDispatch();

    const toggleEntityModal = (value: EntityModalType | null) => dispatch(toggleModal(value));
    const openCreateModal = () => toggleEntityModal(EntityModalType.CREATE);
    const closeModal = () => toggleEntityModal(null);
    const uploadEntity = () => dispatch(upsertEntityHandler());
    const hasEntityClassificationPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.ENTITY_CLASSIFICATION_AI]));

    const fetchAllClassificationEntities = () => dispatch(fetchAllCompanyClassificationEntitiesStarted());
    const openSearchModal = () => toggleEntityModal(EntityModalType.SEARCH);
    const openClassificationModal = () => {
        fetchAllClassificationEntities();
        toggleEntityModal(EntityModalType.ENTITY_CLASSIFICATION);
    };
    const searchEntities = () => dispatch(searchEntitiesStarted());

    const modalOpen = useAppSelector(getModalOpen);

    const migrateModalOpen = useAppSelector(getMigrateFunctionConfirmationModalOpen);
    const functionMigration = useAppSelector(getFunctionMigration);

    const closeMigrateModal = useCallback(() => dispatch(toggleMigrateDoraFunctionConfirmationModal(false)), [dispatch]);

    const saveAndCloseMigrateModal = useCallback(() => {
        dispatch(migrateDoraCompanyFunctionsStarted());
        closeMigrateModal();
    }, [dispatch, closeMigrateModal]);

    const updateFunctionMigrations = useCallback((functionId: number, value: number | null) => dispatch(updateDoraCompanyFunctionMigrations(functionId, value)), [dispatch]);

    return (
        <div className={styles.entityWrapper} data-testid='entity-wrapper'>
            <div className={styles.entityTitleContainer}>
                <div className={styles.entityTitle} data-testid='entity-title'>Entities</div>
                <PlusButton onClick={openCreateModal} fontSize={22} testId='create-entity' />
                <IconButton icon={Search} onClick={openSearchModal} fontSize={35} testId='search-entities' />
                {hasEntityClassificationPermission &&
                    <CustomTooltip overlayText='Verify Entity AI Classification'>
                        <button onClick={openClassificationModal} className={styles.entityClassificationButton} data-testid='entity-classification-button'>
                            <Icon icon={User} fontSize={30} />
                            <div className={styles.entityClassificationOverlay}><Icon icon={Robot} fontSize={20} /></div>
                        </button>
                    </CustomTooltip>
                }
            </div>
            <EntityTable />
            <EntityModal
                uploadEntity={uploadEntity}
                closeModal={closeModal}
                isOpen={modalOpen === EntityModalType.CREATE}
            />
            <SearchEntityModal
                searchEntities={searchEntities}
                closeModal={closeModal}
                isOpen={modalOpen === EntityModalType.SEARCH}
            />
            <EntityClassificationModal
                closeModal={closeModal}
                isOpen={modalOpen === EntityModalType.ENTITY_CLASSIFICATION}
            />
            <MigrateFunctionConfirmationModal
                isOpen={migrateModalOpen}
                closeModal={closeMigrateModal}
                saveAndClose={saveAndCloseMigrateModal}
                functionMigration={functionMigration}
                isCompanyMigration={true}
                updateFunctionMigrations={updateFunctionMigrations}
            />
        </div>
    );
};
