import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { NAV_BAR } from '../../../hooks/useSplitView';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Button } from '../../shared/button/Button';
import { MyDatasetDefinition } from './MyDatasetDefinition';
import styles from './MyDatasets.module.scss';
import { getModalDatasetId, getMyDatasetDefinitions, toggleModalDatasetId } from './store';

interface ModalDefinitionProps {
    isPreview?: boolean;
}

export const ModalDefinition: React.FC<ModalDefinitionProps> = ({ isPreview = false }) => {
    const dispatch = useAppDispatch();
    const modalDatasetId = useAppSelector(getModalDatasetId);
    const isOpen = !isNull(modalDatasetId);
    const closeModal = useCallback(() => dispatch(toggleModalDatasetId(null)), [dispatch]);
    const [screenWidth, screenHeight] = useWindowResize();
    const height = screenHeight - NAV_BAR;
    const datasetDefinitions = useAppSelector(getMyDatasetDefinitions);
    const modalDefinition = useMemo(() => datasetDefinitions.find(({ datasetId }) => datasetId === modalDatasetId) || null, [datasetDefinitions, modalDatasetId]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.modal}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: 'flex',
                    zIndex: 10,
                    position: 'absolute',
                    top: `${NAV_BAR}px`,
                    width: `${screenWidth}px`,
                    height: `${height}px`,
                    borderRadius: '5px',
                }
            }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.modalDefinitionWrapper}>
                {modalDefinition && <MyDatasetDefinition datasetDefinition={modalDefinition} parents={0} modalInstance isPreview={isPreview} />}
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' />
                </div>
            </div>
        </Modal>
    );
};
