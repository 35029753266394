import { isEqual, isNull } from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import Select, { Options } from 'react-select';
import classnames from 'classnames';

import { useMenuPlacement } from '../../../hooks/useMenuPlacement';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getAllDropdownLists } from '../../admin/dropdown-lists/store';
import { EntityType, getAllEntities } from '../../admin/entity/store';
import { customStyles } from '../../shared/dropdown/Dropdown';
import { CustomTooltip } from '../../shared/tooltip';
import { updateFieldValue, userCorrectAIFieldValue } from '../instances/store';
import { DatasetFieldType } from '../store';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import styles from './Fields.module.scss';

const { green, amethyst, primary, gold, disabledGrey, amber } = styles;

export interface DropdownOption {
    value: string;
    label: string;
}

export type DropdownOptions = Options<DropdownOption> | Options<DropdownOption>[] | undefined

interface DropdownProps {
    id: string;
    value: string[] | null;
    index: number;
    sectionId?: string;
    groupIndex?: number;
    rowId?: string;
    type: DatasetFieldType.DROPDOWN | DatasetFieldType.LINKFIELD;
    listId: string;
    isMulti?: boolean;
    disabled?: boolean;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean;
    showAIModifiedUserCorrected?: boolean;
    isMLCorrection?: boolean;
    entityType?: EntityType;
    showClause?: boolean;
    isTable?: boolean;
    includedInAnnex?: boolean;
    menuPortalTarget?: HTMLElement | null;
    datasetId: number;
    parentFieldId: string;
    modalInstance?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
    id,
    value,
    disabled = false,
    isMulti = false,
    index,
    groupIndex,
    sectionId,
    type,
    listId,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    showAIModified = false,
    showAIModifiedUserCorrected = false,
    isMLCorrection = false,
    entityType = EntityType.COMPANY,
    showClause = false,
    isTable = false,
    includedInAnnex = false,
    menuPortalTarget,
    datasetId,
    parentFieldId,
    modalInstance
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    const updateValue = (value: null | string[]) => {
        if (isMLCorrection) {
            dispatch(userCorrectAIFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId));
        } else {
            dispatch(updateFieldValue(datasetId, parentFieldId, value, index, sectionId, groupIndex, rowId, modalInstance));
        }
    };

    const updateDropdownValue = (option: DropdownOption | Options<DropdownOption> | null) => {
        let dropdownValue = null;
        if (!isNull(option)) {
            if (isMulti) {
                dropdownValue = (option as Options<DropdownOption>).map(({ value }) => value);
            } else {
                dropdownValue = [(option as DropdownOption).value];
            }
        }
        if (!isEqual(dropdownValue, value)) {
            updateValue(dropdownValue);
        }
    };

    const allDropdownLists = useAppSelector(getAllDropdownLists);
    const allEntities = useAppSelector(getAllEntities);
    const options = useMemo(() => {
        if (type == DatasetFieldType.LINKFIELD) {
            return allEntities.filter(({ type }) => type === entityType).map(({ name }) => name);
        }
        return allDropdownLists.find(({ dropdownListId }) => dropdownListId.toString() === listId)?.options || [];
    }, [type, listId, allEntities, allDropdownLists, entityType]);

    const mappedOptions: DropdownOptions = useMemo(() => options.map(option => ({ value: option, label: capitaliseStartLetter(option) })), [options]);
    const mappedValue: DropdownOptions | null = useMemo(() => value?.map(value => ({ value, label: capitaliseStartLetter(value) })) || null, [value]);

    const dropdownTooltip = useMemo(() => {
        if (!value || !disabled) {
            return null;
        }
        return value.join(' - ');
    }, [value, disabled]);

    const MAX_MENU_HEIGHT = 150;
    const menuPlacement = useMenuPlacement(dropdownRef, MAX_MENU_HEIGHT);

    const placeholder = includedInAnnex ? 'See Agency Annex' : 'Select...';
    const isDisabled = useMemo(() => disabled || includedInAnnex, [disabled, includedInAnnex]);
    const controlBorderColor = useMemo(() => {
        if (!showDatasetUpdated && !showFieldUpdated && !showAIModified && !showAIModifiedUserCorrected) {
            return isDisabled ? disabledGrey : primary;
        }
        if (showAIModifiedUserCorrected) {
            return amber;
        }
        if (showAIModified) {
            return gold;
        }
        return showFieldUpdated ? amethyst : green;
    }, [showDatasetUpdated, showFieldUpdated, showAIModified, isDisabled, showAIModifiedUserCorrected]);

    return (
        <CustomTooltip overlayText={dropdownTooltip} trigger='click'>
            <div
                data-testid={`definition-dropdown-${id}`}
                className={classnames(styles.dropdownFieldWrapper, { [styles.withClauseLabel]: showClause && isTable })}
                ref={dropdownRef}
            >
                <Select
                    className={styles.dropdownField}
                    classNamePrefix='ark-dropdown'
                    isDisabled={isDisabled}
                    value={mappedValue}
                    onChange={updateDropdownValue}
                    isMulti={isMulti}
                    options={mappedOptions}
                    styles={customStyles}
                    controlBorderColor={controlBorderColor}
                    maxMenuHeight={MAX_MENU_HEIGHT}
                    menuPlacement={menuPlacement}
                    placeholder={placeholder}
                    menuPortalTarget={menuPortalTarget}
                    isClearable={!isDisabled}
                    fontWeight={500}
                />
            </div>
        </CustomTooltip>
    );
};
