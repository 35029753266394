import React, { useMemo } from 'react';
import { isNull } from 'lodash/fp';

import { AnnexDefinitionDB, duplicateAnnexDefinition, getAllAnnexDefinitions, getCurrentInstanceAnnexDefinitions, getSelectedInstanceIndex, InstanceAnnexView, setInstanceAnnexView, updateInstanceAnnexField } from '../../../agency-annex/store';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { AnnexPreview } from '../../../agency-annex/annex-preview/AnnexPreview';

interface SelectProps {
    documentName: string;
}

export const Select: React.FC<SelectProps> = ({ documentName }) => {
    const dispatch = useAppDispatch();

    const index = useAppSelector(getSelectedInstanceIndex);
    const annexDefinitions = useAppSelector(getAllAnnexDefinitions);
    const instanceAnnexDefinitions = useAppSelector(getCurrentInstanceAnnexDefinitions);

    const duplicate = (annexDefinition: AnnexDefinitionDB) => {
        dispatch(duplicateAnnexDefinition(annexDefinition));
        dispatch(setInstanceAnnexView(InstanceAnnexView.BUILDER));
    };

    const select = (annexDefinitionId: number) => {
        dispatch(updateInstanceAnnexField('annexDefinitionId', annexDefinitionId, index));
        dispatch(updateInstanceAnnexField('startPage', null, index));
        dispatch(updateInstanceAnnexField('endPage', null, index));
        dispatch(updateInstanceAnnexField('extractedData', null, index));
        dispatch(setInstanceAnnexView(InstanceAnnexView.CONFIGURE));
    };

    const selectedIds = useMemo(() => {
        const annexDefinitionId = instanceAnnexDefinitions[index].annexDefinitionId;
        return !isNull(annexDefinitionId) ? [annexDefinitionId] : [];
    }, [instanceAnnexDefinitions, index]);

    return (
        <AnnexPreview annexDefinitions={annexDefinitions} documentName={documentName} duplicate={duplicate} select={select} selectedIds={selectedIds} />
    );
};
