import React, { useMemo } from 'react';
import Select from 'react-select';
import classnames from 'classnames';

import { capitaliseStartLetter } from '../../../../utils/regex-utils';
import { customStyles, DropdownOptions } from '../../../shared/dropdown/Dropdown';
import { CustomTooltip, OverflowTooltip } from '../../../shared/tooltip';
import styles from './Fields.module.scss';

interface MetaFieldProps {
    id: string;
    value: string[] | null | undefined;
    width: string;
    isLastField?: boolean;
    label: string;
    maxWidth?: string;
    dropdownWidth?: string;
    formatter?: (value: string) => string;
}

export const MetaField: React.FC<MetaFieldProps> = ({ id, value, width, isLastField = false, label, maxWidth = '400px', dropdownWidth = '100%', formatter = capitaliseStartLetter }) => {
    const mappedValue: DropdownOptions = value?.map(value => ({ value, label: formatter(value) }));

    const isMulti = useMemo(() => !!value && value.length > 1, [value]);

    const dropdownTooltip = useMemo(() => {
        if (!value) {
            return null;
        }
        return value[0];
    }, [value]);

    return (
        <div
            className={classnames(styles.singleFieldWrapper, { [styles.lastFieldInGroup]: isLastField })}
            style={{ width }}
            data-testid={`opinion-field-${id}-wrapper`}
        >
            <div className={styles.fieldTitleWrapper}>
                <OverflowTooltip
                    className={styles.fieldLabel}
                    overlayText={label}
                    testId={`opinion-field-${id}-label`}
                />
            </div>
            <CustomTooltip overlayText={dropdownTooltip} trigger='click'>
                <div
                    data-testid={`opinion-meta-${id}`}
                    className={styles.dropdownFieldWrapper}
                    style={{ maxWidth, width: dropdownWidth }}
                >
                    <Select
                        className={styles.dropdownField}
                        classNamePrefix='ark-dropdown'
                        isDisabled
                        value={mappedValue}
                        isMulti={isMulti}
                        styles={customStyles}
                        isClearable={false}
                        hideIndicator
                        minControlHeight='38px'
                        fontWeight={500}
                    />
                </div>
            </CustomTooltip>
        </div>
    );
};
