import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Document } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';
import { deleteDocumentStarted, getIsDeleting, getOriginalDocumentToDelete, toggleDeleteConfirmationModal } from './store';

interface DeleteDocumentConfirmationModalProps {
    isOpen: boolean;
}

export const DeleteDocumentConfirmationModal: React.FC<DeleteDocumentConfirmationModalProps> = ({ isOpen }) => {
    const dispatch = useAppDispatch();
    const documentToDelete = useAppSelector(getOriginalDocumentToDelete);
    const isDeleting = useAppSelector(getIsDeleting);
    const deleteDocument = () => dispatch(deleteDocumentStarted(documentToDelete!.location));
    const closeModal = () => dispatch(toggleDeleteConfirmationModal(null));

    const warning = 'This will permanently delete this document, all amendments and their associated data';

    if (!documentToDelete) {
        return null;
    }

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={deleteDocument}
            isDeleting={isDeleting}
            headerLabel='Delete Document'
            name={documentToDelete.documentDescription}
            testId='document'
            warning={warning}
            icon={Document}
        />
    );
};
