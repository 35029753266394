import { isEqual } from 'lodash/fp';
import { DateTime, DurationLike, DurationObjectUnits } from 'luxon';

const isString = (variable: string | Date): boolean => typeof variable === 'string';

export const DATE_FORMAT = 'dd-MM-yyyy';
export const DATABASE_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm:ss`;
export const DATE_TIME_MS_FORMAT = `${DATE_TIME_FORMAT}.SSS`;
export const FULL_DATE_FORMAT = 'EEEE d MMMM yyyy';

export const FULL_MONTH_FORMAT = 'D MMMM yyyy';
export const SHORT_MONTH_FORMAT = 'dd MMM yyyy';
export const DATE_DOT_SEPARATED_FORMAT = 'dd.MM.yyyy';
export const DATE_SLASH_SEPARATED_FORMAT = 'dd/MM/yyyy';
const acceptedFormats = [DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_MS_FORMAT, FULL_DATE_FORMAT, FULL_MONTH_FORMAT, SHORT_MONTH_FORMAT, DATE_DOT_SEPARATED_FORMAT, DATE_SLASH_SEPARATED_FORMAT];

const findDateFormat = (date: string) => acceptedFormats.find(format => DateTime.fromFormat(date, format).isValid);

const dateToISOFromFormat = (date: string, format: string = DATE_TIME_FORMAT) => DateTime.fromFormat(date, format).toISO();

const getDateISO = (date: string | Date): string => {
    // Check if there is a date passed through and if not then return the current time is UTC ISO
    if (!date) {
        return dateNow();
    }
    // Check if the date fromISO is valid and return date as an ISO string
    if (isString(date) && DateTime.fromISO(date as string).isValid) {
        return DateTime.fromISO(date as string).toISO();
    }

    // Check if the date fromJSDate is valid and return date as an ISO string
    if (!isString(date) && DateTime.fromJSDate(date as Date).isValid) {
        return DateTime.fromJSDate(date as Date).toISO();
    }

    // If not then search the accepted formats and return the date to ISO string from the format it is in
    return dateToISOFromFormat(date as string, findDateFormat(date as string));
};

export const formatDate = (date: string | Date, format = DATE_FORMAT) => DateTime.fromISO(getDateISO(date)).toUTC().toFormat(format);

export const formatDateTime = (date?: string | Date) => {
    const baseDate = date ? DateTime.fromISO(getDateISO(date)).toUTC() : DateTime.utc();
    return baseDate.toUTC().toFormat(DATE_TIME_FORMAT);
};

export const formatDateTimeLocal = (date?: string | Date) => {
    const baseDate = date ? DateTime.fromISO(getDateISO(date)).toUTC() : DateTime.utc();
    return baseDate.toLocal().toFormat(DATE_TIME_FORMAT);
};

export const formatDateTimeMs = (date?: string | Date) => {
    const baseDate: DateTime = date ? DateTime.fromISO(getDateISO(date)).toUTC() : DateTime.utc();
    return baseDate.toUTC().toFormat(DATE_TIME_MS_FORMAT);
};

export const dateNow = () => DateTime.utc().toISO();

export const unix = () => DateTime.utc().toSeconds();

export const dateToUnix = (date: string | Date) => DateTime.fromISO(getDateISO(date)).toUTC().toSeconds();

export const pastDate = (measurement: DurationLike, date?: string | Date) => {
    const baseDate = date ? DateTime.fromISO(getDateISO(date)).toUTC() : DateTime.utc();
    return baseDate.minus(measurement).toISO();
};

export const futureDate = (measurement: DurationLike, date?: string | Date) => {
    const baseDate = date ? DateTime.fromISO(getDateISO(date)).toUTC() : DateTime.utc();
    return baseDate.plus(measurement).toISO();
};

export const futureDateToMs = (measurement: DurationLike, date?: string | Date) => {
    const baseDate = date ? DateTime.fromISO(getDateISO(date)).toUTC() : DateTime.utc();
    return baseDate.plus(measurement).toMillis();
};

export const endOfDay = (date: string | Date) => DateTime.fromISO(getDateISO(date)).endOf('day').toISO();

export const startOfDay = (date: string | Date) => DateTime.fromISO(getDateISO(date)).startOf('day').toISO();

export const isSame = (date1: string | Date, date2: string | Date) => isEqual(DateTime.fromISO(getDateISO(date1)), DateTime.fromISO(getDateISO(date2)));

export const isBefore = (date1: string | Date, date2: string | Date = dateNow()) => DateTime.fromISO(getDateISO(date1)) < DateTime.fromISO(getDateISO(date2));

export const isAfter = (date1: string | Date, date2: string | Date = dateNow()) => DateTime.fromISO(getDateISO(date1)) > DateTime.fromISO(getDateISO(date2));

export const isSameOrBefore = (date1: string | Date, date2: string | Date) => isSame(date1, date2) || isBefore(date1, date2);

export const isSameOrAfter = (date1: string | Date, date2: string | Date) => isSame(date1, date2) || isAfter(date1, date2);

export const diffToNowInMeasurement = (date: string | Date, measurement: keyof DurationObjectUnits) => DateTime.fromISO(getDateISO(date)).diffNow(measurement).toObject()[measurement];
