import { ApplicationState } from '../../../../store/rootReducer';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { AttestationManagerTableRow } from '../manager/AttestationManagerTable';
import { AdminAttestationState, AttestationConfirmationModal, AttestationForm, AttestationFormDB, AttestationManager, AttestationManagerTab, AttestationPage, FormBuilderTab, NewAttestationInstances } from './types';

const getRoot = (state: ApplicationState): AdminAttestationState => state.admin.attestations;

export const getAttestationPage = (state: ApplicationState): AttestationPage => getRoot(state).attestationPage;

export const getFormBuilderWizardOpen = (state: ApplicationState): boolean => getRoot(state).formBuilderWizardOpen;

export const getSelectedFormTab = (state: ApplicationState): FormBuilderTab => getRoot(state).selectedFormTab;

export const getSelectedAttestationForm = (state: ApplicationState): AttestationForm | null => getRoot(state).selectedAttestationForm;

export const getAttestationConfigModalOpen = (state: ApplicationState): number | null => getRoot(state).attestationQuestionConfigModalOpen;

export const getAttestationConfirmationModalOpen = (state: ApplicationState): AttestationConfirmationModal | null => getRoot(state).attestationConfirmationModal;

export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getAllAttestationForms = (state: ApplicationState): AttestationFormDB[] => getRoot(state).attestationForms;

export const getAttestationFormUpdated = (state: ApplicationState): boolean => getRoot(state).attestationFormUpdated;

export const getAttestationInstancesCompleted = (state: ApplicationState): boolean => getRoot(state).viewCompletedAttestations;

export const getNewAttestationModalOpen = (state: ApplicationState): boolean => getRoot(state).newAttestationModalOpen;

export const getNewAttestations = (state: ApplicationState): NewAttestationInstances | null => getRoot(state).newAttestations;

export const getManagerWizardOpen = (state: ApplicationState): boolean => getRoot(state).attestationManagerWizardOpen;

export const getSelectedManagerTab = (state: ApplicationState): AttestationManagerTab => getRoot(state).selectedManagerTab;

export const getSelectedAttestationInstance = (state: ApplicationState): AttestationManager | null => getRoot(state).selectedAttestationInstance;

export const getAllAttestationInstances = (state: ApplicationState): AttestationManager[] => getRoot(state).attestations;

export const getAttestationInstanceUpdated = (state: ApplicationState): boolean => getRoot(state).attestationInstanceUpdated;

export const getConfirmDeleteAttestationForm = (state: ApplicationState): AttestationForm | null => getRoot(state).confirmDeleteAttestationForm;

export const getConfirmDeleteAttestation = (state: ApplicationState): AttestationManagerTableRow | null => getRoot(state).confirmDeleteAttestation;

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;

// Table Filters and Pagination
export const getAttestationInstancesPageNumber = (state: ApplicationState): number => getRoot(state).attestationInstancesPage;
export const getTotalAttestationInstances = (state: ApplicationState): number => getRoot(state).totalAttestationInstances;
export const getAttestationInstancesFilters = (state: ApplicationState): TableFilters => getRoot(state).attestationInstancesFilters;
export const getAttestationInstancesColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).attestationInstancesSort;
export const getAttestationInstancesPageSize = (state: ApplicationState): number => getRoot(state).attestationInstancesPageSize;
