import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { Document } from '../../../shared/icons';
import { Spinner } from '../../../shared/spinner/Spinner';
import { DocumentAnalyticsChart, getDocumentSpreadAnalytics, getFetchingAnalytics, getIsFetchingMyCompanies } from '../store';
import { PieChartWrapper } from './PieChartWrapper';

interface DocumentSpreadChartProps {
    dimensions?: {
        height: number;
        width: number;
    }
    analyticsSpinner: boolean;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    tileInView: DocumentAnalyticsChart | null;
}

export const DocumentSpreadChart: React.FC<DocumentSpreadChartProps> = ({ dimensions, analyticsSpinner, tileInView, setTileInView }) => {
    const documentSpreadAnalytics = useAppSelector(getDocumentSpreadAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const fetchingMyCompanies = useAppSelector(getIsFetchingMyCompanies);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(DocumentAnalyticsChart.DOCUMENT_SPREAD) || fetchingMyCompanies, [fetchingAnalytics, fetchingMyCompanies]);

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <PieChartWrapper
            data={documentSpreadAnalytics}
            title='Document Spread'
            description='A visualisation of the spread of documents within your portfolio and how many of each type are in Ark51'
            dimensions={dimensions}
            icon={Document}
            testId='document-spread'
            tileInView={tileInView}
            setTileInView={setTileInView}
            currentTile={DocumentAnalyticsChart.DOCUMENT_SPREAD}
            showExpandIcon={false}
        />
    );
};
