import React from 'react';

import { Form } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Spinner } from '../../shared/spinner/Spinner';
import styles from './Reports.module.scss';

interface DeleteConfirmationModalProps {
    deleteReportTemplate: () => void;
    isDeleting: boolean;
    closeModal: () => void;
    reportTemplateName: string;
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ deleteReportTemplate, closeModal, isDeleting, reportTemplateName }) => (
    <ConfirmationModal
        isOpen
        closeModal={closeModal}
        confirmLabel='Delete'
        testId='delete-report-template'
        confirm={deleteReportTemplate}
        maxWidth={50}
    >
        <ModalHeader label='Delete Report Template' icon={Form} testId='delete-report-template-modal' />
        <div className={styles.deleteConfirmationWrapper} data-testid='delete-report-template-modal-wrapper'>
            {isDeleting ? (<Spinner message='Deleting report template...' />) : (
                <>
                    <div className={styles.warningTitle} data-testid='delete-report-template-warning-title'>Are you sure you want to delete:</div>
                    <div className={styles.name} data-testid='delete-report-template-name'>{`${reportTemplateName}?`}</div>
                </>
            )}
        </div>
    </ConfirmationModal>
);
