import { flow, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { SearchEntityBy } from '../../../admin/entity/store';
import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';
import { DoraThirdPartyActionTypes, DoraThirdPartyState, DoraThirdPartyView } from './types';

export const INITIAL_STATE: DoraThirdPartyState = {
    thirdPartyView: DoraThirdPartyView.ACTIVE_THIRD_PARTIES,
    fetchError: null,
    thirdPartyCompaniesFilters: {},
    thirdPartyCompaniesSort: undefined,
    thirdPartyCompaniesPage: 1,
    thirdPartyCompaniesPageSize: DEFAULT_PAGE_SIZE,
    availableThirdPartyCompaniesFilters: {},
    availableThirdPartyCompaniesSort: undefined,
    availableThirdPartyCompaniesPage: 1,
    availableThirdPartyCompaniesPageSize: DEFAULT_PAGE_SIZE,
    allThirdPartyCompanies: [],
    fetchingThirdPartyCompanies: false,
    totalThirdPartyCompanies: 0,
    selectedThirdPartyCompany: null,
    currentThirdPartyCompany: null,
    savingThirdPartyCompany: false,
    searchModalOpen: false,
    isSearching: false,
    searchEntityPage: 1,
    searchEntityPageSize: DEFAULT_PAGE_SIZE,
    totalSearchEntities: 0,
    searchBy: SearchEntityBy.COMPANY_NAME,
    searchInput: '',
    searchResults: [],
    isSaving: false,
    fuzzyCompletions: [],
    autoCompletions: [],
    showCompletions: false
};

export const doraThirdPartyReducer: Reducer<DoraThirdPartyState> = (state = INITIAL_STATE, { payload, type }): DoraThirdPartyState => {
    switch (type) {
        case DoraThirdPartyActionTypes.CHANGE_THIRD_PARTY_VIEW:
            return set('thirdPartyView', payload, state);
        case DoraThirdPartyActionTypes.TOGGLE_THIRD_PARTY_COMPANIES_MODAL:
            return set('selectedThirdPartyCompany', payload, state);
        case DoraThirdPartyActionTypes.FETCH_PAGINATED_THIRD_PARTY_COMPANIES_FAILED:
            return flow(
                set('fetchError', payload),
                set('fetchingThirdPartyCompanies', false)
            )(state);
        case DoraThirdPartyActionTypes.FETCH_PAGINATED_THIRD_PARTY_COMPANIES_STARTED:
            return set('fetchingThirdPartyCompanies', true, state);
        case DoraThirdPartyActionTypes.FETCH_PAGINATED_THIRD_PARTY_COMPANIES_SUCCESSFUL: {
            const companiesPage = state.thirdPartyView === DoraThirdPartyView.ACTIVE_THIRD_PARTIES ? 'thirdPartyCompaniesPage' : 'availableThirdPartyCompaniesPage';
            return flow(
                set('allThirdPartyCompanies', payload.thirdPartyCompanies),
                set('totalThirdPartyCompanies', payload.total),
                set(companiesPage, payload.pageNumber || state.thirdPartyCompaniesPage),
                set('fetchingThirdPartyCompanies', false)
            )(state);
        }
        case DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`thirdPartyCompaniesFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`thirdPartyCompaniesFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DoraThirdPartyActionTypes.CLEAR_THIRD_PARTY_COMPANIES_TABLE_FILTERS:
            return set('thirdPartyCompaniesFilters', {}, state);
        case DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT:
            return set('thirdPartyCompaniesSort', payload, state);
        case DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_PAGE_SIZE:
            return set('thirdPartyCompaniesPageSize', payload, state);
        case DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`availableThirdPartyCompaniesFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`availableThirdPartyCompaniesFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DoraThirdPartyActionTypes.CLEAR_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS:
            return set('availableThirdPartyCompaniesFilters', {}, state);
        case DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT:
            return set('availableThirdPartyCompaniesSort', payload, state);
        case DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_PAGE_SIZE:
            return set('availableThirdPartyCompaniesPageSize', payload, state);
        case DoraThirdPartyActionTypes.SET_SELECTED_THIRD_PARTY_COMPANY: {
            return flow(
                set('selectedThirdPartyCompany', payload),
                set('currentThirdPartyCompany', payload)
            )(state);
        }
        case DoraThirdPartyActionTypes.UPDATE_THIRD_PARTY_COMPANY_VALUE: {
            const { key, value } = payload;
            return set(`selectedThirdPartyCompany[${key}]`, value, state);
        }
        case DoraThirdPartyActionTypes.UPDATE_THIRD_PARTY_COMPANY_CONTENT: {
            const { key, value } = payload;
            return set(`selectedThirdPartyCompany.content[${key}]`, value, state);
        }
        case DoraThirdPartyActionTypes.UPDATE_THIRD_PARTY_COMPANY_ADDRESS_VALUE: {
            const { key, value, index } = payload;
            return set(`selectedThirdPartyCompany.content.addresses[${index}].[${key}]`, value, state);
        }
        case DoraThirdPartyActionTypes.SAVE_THIRD_PARTY_COMPANY_STARTED:
            return flow(
                set('fetchingThirdPartyCompanies', true),
                set('savingThirdPartyCompany', true)
            )(state);
        case DoraThirdPartyActionTypes.SAVE_THIRD_PARTY_COMPANY_SUCCESSFUL: {
            const { thirdPartyCompanies, total, pageNumber } = payload;
            return flow(
                set('allThirdPartyCompanies', thirdPartyCompanies),
                set('totalThirdPartyCompanies', total),
                set('thirdPartyCompaniesPage', pageNumber),
                set('fetchingThirdPartyCompanies', false),
                set('savingThirdPartyCompany', false),
                set('currentThirdPartyCompany', null),
                set('selectedThirdPartyCompany', null)
            )(state);
        }
        case DoraThirdPartyActionTypes.SAVE_THIRD_PARTY_COMPANY_FAILED:
            return flow(
                set('fetchingThirdPartyCompanies', false),
                set('savingThirdPartyCompany', false)
            )(state);
        case DoraThirdPartyActionTypes.TOGGLE_THIRD_PARTY_SEARCH_MODAL_OPEN:
            return set('searchModalOpen', payload, state);
        case DoraThirdPartyActionTypes.UPSERT_THIRD_PARTY_LEI_ENTITY:
            return set('isSaving', true, state);
        case DoraThirdPartyActionTypes.SEARCH_THIRD_PARTY_COMPANIES_STARTED:
            return flow(
                set('isSearching', true),
                set('fuzzyCompletions', []),
                set('autoCompletions', []),
                set('showCompletions', false)
            )(state);
        case DoraThirdPartyActionTypes.SEARCH_THIRD_PARTY_COMPANIES_SUCCESSFUL: {
            const { total, entities, pageNumber } = payload;
            return flow(
                set('isSearching', false),
                set('isSaving', false),
                set('searchResults', entities),
                set('totalSearchEntities', total || state.totalSearchEntities),
                set('searchEntityPage', pageNumber || state.searchEntityPage)
            )(state);
        }
        case DoraThirdPartyActionTypes.SEARCH_THIRD_PARTY_COMPANIES_FAILED:
            return flow(
                set('isSearching', false),
                set('isSaving', false)
            )(state);
        case DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_SEARCH_PAGE_SIZE:
            return set('searchEntityPageSize', payload, state);
        case DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANY_SEARCH_TYPE:
            return flow(
                set('searchBy', payload),
                set('searchInput', ''),
                set('searchEntityPage', 1),
                set('totalSearchEntities', 0),
                set('searchResults', [])
            )(state);
        case DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANY_SEARCH_VALUE:
            return flow(
                set('searchInput', payload),
                set('showCompletions', true)
            )(state);
        case DoraThirdPartyActionTypes.RESET_THIRD_PARTY_COMPANY_SEARCH:
            return flow(
                set('searchInput', ''),
                set('searchBy', SearchEntityBy.COMPANY_NAME),
                set('searchEntityPage', 1),
                set('searchEntityPageSize', DEFAULT_PAGE_SIZE),
                set('totalSearchEntities', 0),
                set('searchResults', []),
                set('fuzzyCompletions', []),
                set('autoCompletions', []),
                set('showCompletions', false)
            )(state);
        case DoraThirdPartyActionTypes.FETCH_FUZZY_AND_AUTOCOMPLETIONS_SUCCESSFUL:
            return flow(
                set('fuzzyCompletions', payload.fuzzyCompletions),
                set('autoCompletions', payload.autoCompletions)
            )(state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
