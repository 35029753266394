import { DatasetType } from '../../../datasets/store';
import { SearchFilter } from '../../search/store';

export enum DocumentReportingActionTypes {
    SELECT_REPORT_PAGE = 'SELECT_REPORT_PAGE',
    SELECT_WIZARD_TAB = 'SELECT_DOCUMENT_REPORT_WIZARD_TAB',
    UPDATE_DOCUMENT_REPORT_FILTER = 'UPDATE_DOCUMENT_REPORT_FILTER',
    UPDATE_REPORT_DATE_FILTER = 'UPDATE_REPORT_DATE_FILTER',
    FETCH_AVAILABLE_FIELDS_STARTED = 'FETCH_AVAILABLE_FIELDS_STARTED',
    FETCH_AVAILABLE_DOCUMENT_FIELDS_SUCCESSFUL = 'FETCH_AVAILABLE_DOCUMENT_FIELDS_SUCCESSFUL',
    FETCH_AVAILABLE_FIELDS_FAILED = 'FETCH_AVAILABLE_FIELDS_FAILED',
    ADD_DOCUMENT_REPORT_FIELD = 'ADD_DOCUMENT_REPORT_FIELD',
    REMOVE_DOCUMENT_REPORT_FIELD = 'REMOVE_DOCUMENT_REPORT_FIELD',
    RESET_REPORT = 'RESET_REPORT',
    UPDATE_DOCUMENT_REPORT_FIELDS = 'UPDATE_DOCUMENT_REPORT_FIELDS',
    GENERATE_REPORT_STARTED = 'GENERATE_REPORT_STARTED',
    GENERATE_REPORT_SUCCESSFUL = 'GENERATE_REPORT_SUCCESSFUL',
    GENERATE_REPORT_FAILED = 'GENERATE_REPORT_FAILED',
    SET_REPORT_UPDATED = 'SET_DOCUMENT_REPORT_UPDATED',
    TOGGLE_REPORT_CONFIRMATION_MODAL = 'TOGGLE_REPORT_CONFIRMATION_MODAL',
    TOGGLE_SAVE_TEMPLATE_MODAL_OPEN = 'TOGGLE_SAVE_TEMPLATE_MODAL_OPEN',
    UPDATE_REPORT_TEMPLATE_VALUE = 'UPDATE_REPORT_TEMPLATE_VALUE',
    SAVE_REPORT_TEMPLATE_STARTED = 'SAVE_REPORT_TEMPLATE_STARTED',
    SAVE_REPORT_TEMPLATE_SUCCESSFUL = 'SAVE_REPORT_TEMPLATE_SUCCESSFUL',
    SAVE_REPORT_TEMPLATE_FAILED = 'SAVE_REPORT_TEMPLATE_FAILED',
    FETCH_ALL_REPORT_TEMPLATES_STARTED = 'FETCH_ALL_REPORT_TEMPLATES_STARTED',
    FETCH_ALL_REPORT_TEMPLATES_SUCCESSFUL = 'FETCH_ALL_REPORT_TEMPLATES_SUCCESSFUL',
    FETCH_ALL_REPORT_TEMPLATES_FAILED = 'FETCH_ALL_REPORT_TEMPLATES_FAILED',
    OPEN_DOCUMENT_REPORT_TEMPLATE = 'OPEN_DOCUMENT_REPORT_TEMPLATE',
    DELETE_REPORT_TEMPLATE_STARTED = 'DELETE_REPORT_TEMPLATE_STARTED',
    DELETE_REPORT_TEMPLATE_FAILED = 'DELETE_REPORT_TEMPLATE_FAILED',
    DELETE_REPORT_TEMPLATE_SUCCESSFUL = 'DELETE_REPORT_TEMPLATE_SUCCESSFUL',
    UPDATE_REPORT_DOCUMENT_NAME = 'UPDATE_REPORT_DOCUMENT_NAME',
    SET_REPORT_TEMPLATE_TO_DELETE = 'SET_REPORT_TEMPLATE_TO_DELETE',
    RESET_REPORT_FILTERS = 'RESET_REPORT_FILTERS'
}

export enum ReportPage {
    SELECT = 'Select',
    WIZARD = 'Wizard',
    TEMPLATES = 'Templates'
}

export enum ReportTab {
    SELECT = 'Select',
    FIELDS = 'Choose Fields',
    CONFIGURE = 'Configure Columns',
    FILTER = 'Filter & Confirm'
}

export interface DocumentReportHierarchy {
    datasetId: number;
    datasetType: DatasetType;
    parentId: number | null;
    fieldId: string;
    sectionId: string;
    label: string;
    order: number;
}

export interface DocumentReportField {
    id: string;
    label: string;
    description: string;
    datasetId: number;
    datasetTitle: string;
    datasetType: DatasetType;
    parentFieldId: string;
    order?: number;
    fieldName: string;
}

export interface DocumentReportTemplate {
    reportTemplateId: number;
    name: string;
    description: string;
    reportFields: DocumentReportField[];
    filters: SearchFilter;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate: string;
    clientId: number;
}

export interface UpsertDocumentReportTemplate {
    name: string;
    description: string;
    reportFields: DocumentReportField[];
    reportTemplateId?: number;
    isOpinionReport: boolean;
    filters: SearchFilter;
}

export interface DocumentReportingState {
    reportPage: ReportPage;
    selectedTab: ReportTab;
    fetchingAvailableFields: boolean;
    fetchingError: string | null;
    availableFields: DocumentReportField[];
    reportFieldHierarchy: DocumentReportHierarchy[];
    reportFields: DocumentReportField[];
    generatingReport: boolean;
    reportFilters: SearchFilter;
    reportUpdated: boolean;
    backConfirmationModalOpen: boolean;
    reportTemplates: DocumentReportTemplate[];
    saveTemplateModalOpen: boolean;
    templateName: string;
    templateDescription: string;
    saveTemplateError: string | null;
    selectedTemplate: DocumentReportTemplate | null;
    isDeleting: boolean;
    confirmDeleteReportTemplate: number | null;
    fetchingTemplates: boolean;
}
