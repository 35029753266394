import { DocumentSpecificHiddenFields, DocumentSpecificSectionIds } from '../components/admin/dataset-builder/store';
import { HiddenField, HiddenSection } from '../components/admin/my-datasets/store';
import { AgencyDefinition, DatasetDefinition } from '../components/datasets/store';
import { api } from './services';

type PublishRequest = { datasetDefinition: DatasetDefinition; clientIds: number[]; newFields: (HiddenField | HiddenSection)[]; };
type FetchDatasetDefinitionRequest = { datasetId: number | string, isAgency?: boolean };
type AgencyPublishRequest = { agencyDefinition: AgencyDefinition; agreementTypeId: number, datasetId: number; };

export const fetchAllDocumentDatasets = async () => await api('dataset/documents/getAll', {}, 'get');
export const upsertHiddenDocumentDatasetFields = async (reqBody: { hiddenFields: DocumentSpecificHiddenFields; documentMLSections: DocumentSpecificSectionIds; parentDatasetId: number; }) => await api('dataset/documents/upsert', { ...reqBody }, 'post');
export const getDefinitionsForDatasetId = async (reqBody: { datasetId: number; }) => await api('dataset/documents/getDefinitionsByParentId', { ...reqBody }, 'post');

export const publishDataset = async (reqBody: PublishRequest) => await api('dataset/definition/upsertDatasetDefinition', { ...reqBody }, 'post');
export const fetchAllDatasetDefinitions = async () => await api('dataset/definition/allDefinitions', {}, 'get');
export const deleteDataset = async (reqBody: { datasetId: number; }) => await api('dataset/definition/delete', { ...reqBody }, 'post');

export const fetchDatasetDefinition = async (reqBody: FetchDatasetDefinitionRequest) => await api('dataset/definition/getById', { ...reqBody }, 'post');

// Agency Datasets
export const fetchAllAgencyDefinitions = async () => await api('dataset/agency/allDefinitions', {}, 'get');
export const publishAgencyDataset = async (reqBody: AgencyPublishRequest) => await api('dataset/agency/upsertAgencyDatasetDefinition', { ...reqBody }, 'post');
