import { flow, isUndefined, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { updateCurrencyAmountRiskToleranceConfig, updateRiskToleranceConfig } from '../../../../utils/riskTolerance';
import { LoginActionTypes } from '../../../auth/login/store';
import { RiskTolerance, RiskToleranceActionTypes, RiskToleranceState } from './types';

export const INITIAL_STATE: RiskToleranceState = {
    allRiskToleranceDatasets: [],
    riskToleranceConfig: null,
    currentRiskToleranceConfig: null,
    definitions: [],
    fetchError: null,
    isLoading: false,
    currentRiskField: null,
    riskToleranceUpdated: false,
    isSaving: false,
    unsavedChangesModalOpen: false,
    documentHiddenFields: {},
    selectedRiskToleranceDataset: null
};

export const riskToleranceReducer: Reducer<RiskToleranceState> = (state = INITIAL_STATE, { type, payload }): RiskToleranceState => {
    switch (type) {
        case RiskToleranceActionTypes.FETCH_ALL_RISK_TOLERANCE_STARTED:
            return set('isLoading', true, state);
        case RiskToleranceActionTypes.FETCH_ALL_RISK_TOLERANCE_SUCCESSFUL:
            return flow(
                set('allRiskToleranceDatasets', payload),
                set('isLoading', false)
            )(state);
        case RiskToleranceActionTypes.FETCH_ALL_RISK_TOLERANCE_FAILED:
            return flow(
                set('fetchError', payload),
                set('isLoading', false)
            )(state);
        case RiskToleranceActionTypes.FETCH_RISK_TOLERANCE_BY_DATASET_ID_SUCCESSFUL: {
            const { riskTolerance, definitions, documentHiddenFields, selectedDatasetId } = payload;
            return flow(
                set('riskToleranceConfig', riskTolerance),
                set('currentRiskToleranceConfig', riskTolerance),
                set('documentHiddenFields', documentHiddenFields),
                set('definitions', definitions),
                set('selectedRiskToleranceDataset', selectedDatasetId)
            )(state);
        }
        case RiskToleranceActionTypes.FETCH_RISK_TOLERANCE_BY_DATASET_ID_FAILED:
            return set('fetchError', payload, state);
        case RiskToleranceActionTypes.GO_BACK_TO_ALL_RISK_TOLERANCE_DATASETS:
            return flow(
                set('currentRiskToleranceConfig', null),
                set('riskToleranceConfig', null),
                set('definitions', []),
                set('unsavedChangesModalOpen', false),
                set('selectedRiskToleranceDataset', null)
            )(state);
        case RiskToleranceActionTypes.SET_CURRENT_RISK_TOLERANCE_FIELD:
            return set('currentRiskField', payload, state);
        case RiskToleranceActionTypes.UPDATE_RISK_FIELD_CONFIG: {
            const { riskAssociated, value, selectedCurrency } = payload;
            const { datasetId, fieldId } = state.currentRiskField!;
            let updatedRiskToleranceConfig: RiskTolerance[] = [];
            if (!isUndefined(selectedCurrency)) {
                updatedRiskToleranceConfig = updateCurrencyAmountRiskToleranceConfig(state.currentRiskToleranceConfig!, datasetId, fieldId, selectedCurrency, riskAssociated, value) as RiskTolerance[];
            } else {
                updatedRiskToleranceConfig = updateRiskToleranceConfig(state.currentRiskToleranceConfig!, datasetId, fieldId, riskField => set(`riskConfig[${riskAssociated}]`, value, riskField)) as RiskTolerance[];
            }
            return set('currentRiskToleranceConfig', updatedRiskToleranceConfig, state);
        }
        case RiskToleranceActionTypes.UPDATE_RISK_FIELD_WEIGHTING: {
            const { datasetId, fieldId } = state.currentRiskField!;
            const updatedRiskToleranceConfig = updateRiskToleranceConfig(state.currentRiskToleranceConfig!, datasetId, fieldId, riskField => set('weighting', payload, riskField));
            return set('currentRiskToleranceConfig', updatedRiskToleranceConfig, state);
        }
        case RiskToleranceActionTypes.SET_RISK_TOLERANCE_UPDATED:
            return set('riskToleranceUpdated', payload, state);
        case RiskToleranceActionTypes.SAVE_RISK_TOLERANCE_STARTED:
            return set('isSaving', true, state);
        case RiskToleranceActionTypes.SAVE_RISK_TOLERANCE_SUCCESSFUL:
            return flow(
                set('isSaving', false),
                set('riskToleranceConfig', state.currentRiskToleranceConfig),
                set('riskToleranceUpdated', false)
            )(state);
        case RiskToleranceActionTypes.SAVE_RISK_TOLERANCE_FAILED:
            return set('isSaving', false, state);
        case RiskToleranceActionTypes.TOGGLE_UNSAVED_CHANGES_MODAL:
            return set('unsavedChangesModalOpen', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
