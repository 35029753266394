import { action } from 'typesafe-actions';

import { ColumnSort } from '../../../shared/table/ArkTable';
import { DoraMyCompaniesActionTypes, DoraMyCompaniesView, FunctionMigration } from './types';
import { BaseEntity, CompanyEntity, CompanyEntityContent, Entity } from '../../../admin/entity/store';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';

export const toggleDoraMyCompaniesView = (view: DoraMyCompaniesView) => action(DoraMyCompaniesActionTypes.TOGGLE_MY_COMPANIES_VIEW, view);

export const setSelectedCompanyEntity = (entity: CompanyEntity | null) => action(DoraMyCompaniesActionTypes.SET_SELECTED_COMPANY_ENTITY, entity);

export const toggleCompanyEntityModal = (value: boolean) => action(DoraMyCompaniesActionTypes.TOGGLE_COMPANY_ENTITY_MODAL, value);

export const fetchMyCompanyEntitiesStarted = () => action(DoraMyCompaniesActionTypes.FETCH_MY_COMPANY_ENTITIES_STARTED);
export const fetchMyCompanyEntitiesSuccessful = (entities: BaseEntity[]) => action(DoraMyCompaniesActionTypes.FETCH_MY_COMPANY_ENTITIES_SUCCESSFUL, entities);
export const fetchMyCompanyEntitiesFailed = (error: string) => action(DoraMyCompaniesActionTypes.FETCH_MY_COMPANY_ENTITIES_FAILED, error);

export const updateSelectedEntityFunctionIds = (doraFunctionIds: number[]) => action(DoraMyCompaniesActionTypes.UPDATE_SELECTED_ENTITY_FUNCTION_IDS, doraFunctionIds);

export const updateSelectedEntity = (key: keyof Entity, value: string | null | boolean | number, contentKey?: keyof CompanyEntityContent) => action(DoraMyCompaniesActionTypes.UPDATE_SELECTED_ENTITY, { key, value, contentKey });

export const saveCompanyEntityStarted = () => action(DoraMyCompaniesActionTypes.SAVE_COMPANY_ENTITY_STARTED);
export const saveCompanyEntitySuccessful = () => action(DoraMyCompaniesActionTypes.SAVE_COMPANY_ENTITY_SUCCESSFUL);
export const saveCompanyEntityFailed = (error: string) => action(DoraMyCompaniesActionTypes.SAVE_COMPANY_ENTITY_FAILED, error);

export const fetchEntityFunctionIdsStarted = (entityId: number) => action(DoraMyCompaniesActionTypes.FETCH_ENTITY_FUNCTION_IDS_STARTED, entityId);
export const fetchEntityFunctionIdsSuccessful = (entityFunctionIds: number[] | null) => action(DoraMyCompaniesActionTypes.FETCH_ENTITY_FUNCTION_IDS_SUCCESSFUL, entityFunctionIds);
export const fetchEntityFunctionIdsFailed = (error: string) => action(DoraMyCompaniesActionTypes.FETCH_ENTITY_FUNCTION_IDS_FAILED, error);

export const companyFunctionMigrationCheckStarted = (entityId: number) => action(DoraMyCompaniesActionTypes.COMPANY_FUNCTION_MIGRATION_CHECK_STARTED, entityId);
export const companyFunctionMigrationCheckSuccessful = (functionMigration: FunctionMigration) => action(DoraMyCompaniesActionTypes.COMPANY_FUNCTION_MIGRATION_CHECK_SUCCESSFUL, functionMigration);
export const companyFunctionMigrationCheckFailed = (error: string) => action(DoraMyCompaniesActionTypes.COMPANY_FUNCTION_MIGRATION_CHECK_FAILED, error);

export const fetchPaginatedInScopeDoraCompaniesStarted = (pageNumber = 1) => action(DoraMyCompaniesActionTypes.FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_STARTED, { pageNumber });
export const fetchPaginatedInScopeDoraCompaniesSuccessful = (entities: CompanyEntity[], total?: number, pageNumber?: number) => action(DoraMyCompaniesActionTypes.FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_SUCCESSFUL, { entities, total, pageNumber });
export const fetchPaginatedInScopeDoraCompaniesFailed = (error: string) => action(DoraMyCompaniesActionTypes.FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_FAILED, error);

export const fetchPaginatedOutOfScopeDoraCompaniesStarted = (pageNumber = 1) => action(DoraMyCompaniesActionTypes.FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_STARTED, { pageNumber });
export const fetchPaginatedOutOfScopeDoraCompaniesSuccessful = (entities: CompanyEntity[], total?: number, pageNumber?: number) => action(DoraMyCompaniesActionTypes.FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_SUCCESSFUL, { entities, total, pageNumber });
export const fetchPaginatedOutOfScopeDoraCompaniesFailed = (error: string) => action(DoraMyCompaniesActionTypes.FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_FAILED, error);

export const closeMigrateFunctionConfirmationModal = () => action(DoraMyCompaniesActionTypes.CLOSE_MIGRATE_FUNCTION_CONFIRMATION_MODAL);

export const fetchAvailableFunctionsForMigrationStarted = () => action(DoraMyCompaniesActionTypes.FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_STARTED);
export const fetchAvailableFunctionsForMigrationSuccessful = (functionMigration: FunctionMigration) => action(DoraMyCompaniesActionTypes.FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_SUCCESSFUL, functionMigration);
export const fetchAvailableFunctionsForMigrationFailed = (error: string) => action(DoraMyCompaniesActionTypes.FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_FAILED, error);

export const updateCompanyFunctionMigrations = (functionId: number, value: number | null) => action(DoraMyCompaniesActionTypes.UPDATE_COMPANY_FUNCTION_MIGRATIONS, { functionId, value });

export const migrateCompanyFunctionsStarted = () => action(DoraMyCompaniesActionTypes.MIGRATE_COMPANY_FUNCTIONS_STARTED);
export const migrateCompanyFunctionsSuccessful = () => action(DoraMyCompaniesActionTypes.MIGRATE_COMPANY_FUNCTIONS_SUCCESSFUL);
export const migrateCompanyFunctionsFailed = (error: string) => action(DoraMyCompaniesActionTypes.MIGRATE_COMPANY_FUNCTIONS_FAILED, error);

export const toggleEntityServiceTypeDescriptionModal = (isOpen: boolean) => action(DoraMyCompaniesActionTypes.TOGGLE_TYPE_OF_ENTITY_DESCRIPTION, isOpen);

export const toggleRoiMaintainer = (entityId: number) => action(DoraMyCompaniesActionTypes.TOGGLE_ROI_MAINTAINER, entityId);

// Table Filters and Pagination
// In scope
export const setInScopeCompanyTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANY_TABLE_FILTERS, { key, value, type });
export const clearInScopeCompanyTableFilters = () => action(DoraMyCompaniesActionTypes.CLEAR_IN_SCOPE_COMPANY_TABLE_FILTERS);
export const companiesInScopePaginationNext = () => action(DoraMyCompaniesActionTypes.COMPANIES_IN_SCOPE_PAGINATION_NEXT);
export const companiesInScopePaginationPrevious = () => action(DoraMyCompaniesActionTypes.COMPANIES_IN_SCOPE_PAGINATION_PREVIOUS);
export const setInScopeCompanyTableColumnSort = (columnSort: ColumnSort | undefined) => action(DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANY_TABLE_COLUMN_SORT, columnSort);
export const setInScopeCompaniesPageSize = (pageSize: number) => action(DoraMyCompaniesActionTypes.SET_IN_SCOPE_COMPANIES_PAGE_SIZE, pageSize);
// Out of scope
export const setOutOfScopeCompanyTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS, { key, value, type });
export const clearOutOfScopeCompanyTableFilters = () => action(DoraMyCompaniesActionTypes.CLEAR_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS);
export const companiesOutOfScopePaginationNext = () => action(DoraMyCompaniesActionTypes.COMPANIES_OUT_OF_SCOPE_PAGINATION_NEXT);
export const companiesOutOfScopePaginationPrevious = () => action(DoraMyCompaniesActionTypes.COMPANIES_OUT_OF_SCOPE_PAGINATION_PREVIOUS);
export const setOutOfScopeCompanyTableColumnSort = (columnSort: ColumnSort | undefined) => action(DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANY_TABLE_COLUMN_SORT, columnSort);
export const setOutOfScopeCompaniesPageSize = (pageSize: number) => action(DoraMyCompaniesActionTypes.SET_OUT_OF_SCOPE_COMPANIES_PAGE_SIZE, pageSize);
