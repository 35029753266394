import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted } from '../../../admin/dropdown-lists/store';
import { fetchAllNettingEntitiesStarted } from '../../../admin/entity/store';
import { Spinner } from '../../../shared/spinner/Spinner';
import { getIsLoading, getIsOpening, fetchNettingEngineDocumentsStarted, NettingEngineTableView, getNettingEngineTableView } from '../store';
import { DocumentType } from '../../../documents/my-documents/store';
import { NettingEngineDocumentsTable } from './NettingEngineDocumentsTable';

export const NettingEngineDocuments: React.FC = () => {
    const documentView = useAppSelector(getNettingEngineTableView);
    const isExistingView = useMemo(() => documentView === NettingEngineTableView.EXISTING, [documentView]);

    useFetchStarted([fetchNettingEngineDocumentsStarted(1, isExistingView ? DocumentType.ORIGINAL : DocumentType.PRE_EXECUTION_DRAFT), fetchAllDropdownListsStarted(), fetchAllNettingEntitiesStarted()]);

    const isLoading = useAppSelector(getIsLoading);
    const isOpening = useAppSelector(getIsOpening);

    const nettingEngineBody = useMemo(() => {
        if (isLoading || isOpening) {
            return <Spinner />;
        }
        return <NettingEngineDocumentsTable isExistingView={isExistingView} />;
    }, [isLoading, isOpening, isExistingView]);

    return nettingEngineBody;
};
