import React from 'react';

export const Table: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox='0 0 128 128'>
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,108.7,108.7,114.1,102.1,114.1z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M99.5,98.1H28.5c-1.6,0-2.9-1.3-2.9-2.9V29.6c0-1.6,1.3-2.9,2.9-2.9h71.1c1.6,0,2.9,1.3,2.9,2.9v65.6C102.5,96.8,101.2,98.1,99.5,98.1z" />
        <g>
            <path fill={color || 'currentColor'} d="M101.9,103.6L101.9,103.6c-2.3,1.6-5.5,1-7.1-1.3L81.8,83.7c-1.6-2.3-1-5.5,1.3-7.1l0,0c2.3-1.6,5.5-1,7.1,1.3l12.9,18.6C104.8,98.7,104.2,102,101.9,103.6z" />
            <path fill={color || 'currentColor'} d="M69.2,64.9l1.6,19.5c0.4,4.2,5.2,6.5,8.6,4.1l16.1-11.2c3.5-2.4,3-7.7-0.8-9.5L77,59.5C73.2,57.7,68.8,60.7,69.2,64.9z" />
        </g>
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="26.9" y1="37.8" x2="104.1" y2="37.8" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="25.4" y1="52.9" x2="102.6" y2="52.9" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="25.4" y1="68" x2="102.6" y2="68" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="24.3" y1="83" x2="101.5" y2="83" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="45.6" y1="37.2" x2="45.6" y2="97.5" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="65.5" y1="37.2" x2="65.5" y2="97.5" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="85.4" y1="38.4" x2="85.4" y2="98.7" />
        <path fill={color || 'currentColor'} d="M100.6,37.8H27.4c-1.9,0-3.4-1.5-3.4-3.4v-3.1c0-1.9,1.5-3.4,3.4-3.4h73.2c1.9,0,3.4,1.5,3.4,3.4v3.1C104,36.3,102.5,37.8,100.6,37.8z" />
    </svg>
);
