import { action } from 'typesafe-actions';

import { RegistrationActionTypes, RegistrationDetails } from './types';

export const updateRegistrationDetails = (value: string, key: keyof RegistrationDetails) => action(RegistrationActionTypes.UPDATE_REGISTRATION_DETAILS, { value, key });

export const validateRegistrationEmail = (value: string) => action(RegistrationActionTypes.VALIDATE_REGISTRATION_EMAIL, value);
export const setRegistrationEmailValid = (value: boolean) => action(RegistrationActionTypes.SET_REGISTRATION_EMAIL_VALID, value);

export const submitRegistrationDetailsStarted = () => action(RegistrationActionTypes.SUBMIT_REGISTRATION_DETAILS_STARTED);
export const submitRegistrationDetailsSuccessful = () => action(RegistrationActionTypes.SUBMIT_REGISTRATION_DETAILS_SUCCESSFUL);
export const submitRegistrationDetailsFailed = (error: string) => action(RegistrationActionTypes.SUBMIT_REGISTRATION_DETAILS_FAILED, error);
