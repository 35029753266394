import React from 'react';

import { ArkDocument } from '../../documents/my-documents/store';
import { Icon } from '../../shared/icon/Icon';
import { Export } from '../../shared/icons';
import { CustomTooltip } from '../../shared/tooltip';
import styles from './DatasetInstance.module.scss';

interface ExportButtonProps {
    exportFunction: (originalDocument: ArkDocument) => void;
    originalDocument: ArkDocument;
    overlayText: string;
}

export const ExportButton: React.FC<ExportButtonProps> = ({ exportFunction, originalDocument, overlayText }) => (
    <CustomTooltip overlayText={overlayText}>
        <button data-testid='export-button-wrapper' className={styles.exportButtonWrapper} onClick={() => exportFunction(originalDocument)}>
            <Icon icon={Export} />
        </button>
    </CustomTooltip>
);
