import { isNull, noop, set } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useWindowResize } from '../../../hooks/useWindowResize';
import { Address, Lei } from '../../admin/entity/store';
import { ArkTable, ArkTableColumn } from '../../shared/table/ArkTable';
import { addressFormatter } from '../../shared/table/arkTableFormatters';
import styles from './Lei.module.scss';

interface LeiSearchResultsProps {
    isSearching: boolean;
    searchResults: Lei[];
    pageNumber: number;
    totalEntities: number;
    pageSize: number;
    previousPage: () => void;
    nextPage: () => void;
    updatePageSize: (pageSize: number) => void;
    addEntity: (lei: Lei) => void;
    isSaving: boolean;
}

export const LeiSearchResults: React.FC<LeiSearchResultsProps> = ({
    isSearching,
    searchResults,
    pageNumber,
    totalEntities,
    pageSize,
    previousPage,
    nextPage,
    updatePageSize,
    addEntity,
    isSaving
}) => {
    const [screenWidth, screenHeight] = useWindowResize();
    const [savingLei, setSavingLei] = useState<string | null>(null);

    const formatAddress = useCallback((address: Address) => {
        const addressArray = Object.values(address);
        const addressString = addressArray.filter(val => !!val).join(', ');
        return addressString;
    }, []);

    const rowData = useMemo(() => searchResults.map(entity => set('address', formatAddress(entity.legalAddress), entity)), [formatAddress, searchResults]);

    const selectedIds = useMemo(() => searchResults.filter(({ entityExists }) => entityExists).map(({ lei }) => lei), [searchResults]);

    const isLoading = useCallback((row: Lei) => !isNull(savingLei) && row.lei === savingLei && isSaving, [isSaving, savingLei]);

    const select = useCallback((lei: string | number) => {
        const id = lei as string;
        const entity = searchResults.find(({ lei }) => lei === id);
        if (entity) {
            setSavingLei(id);
            addEntity(entity);
        }
    }, [searchResults, addEntity]);

    useEffect(() => {
        if (!isSaving) {
            setSavingLei(null);
        }
    }, [isSaving]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'legalName', header: 'Company Name', field: 'legalName', component: 'tooltip', width: 0.3 },
        { id: 'lei', header: 'LEI', field: 'lei', component: 'tooltip', width: 0.2 },
        { id: 'legalAddress', header: 'Legal Address', field: 'address', component: 'address', valueFormatter: addressFormatter, width: 0.25 },
        { id: 'otherNames', header: 'Other Names', field: 'otherNames', component: 'otherNames', width: 0.1 },
        { id: 'jurisdiction', header: 'Jurisdiction', field: 'jurisdiction', width: 0.1, component: 'flag' },
        { id: 'add', header: 'Add?', field: 'lei', width: 0.05, select, selectedIds: selectedIds, component: 'select', isLoading }
    ];

    const width = useMemo(() => screenWidth * 0.9, [screenWidth]);
    const height = useMemo(() => screenHeight * 0.5, [screenHeight]);

    return (
        <div className={styles.searchResultsWrapper}>
            <ArkTable
                colDefs={columnDefs}
                rowData={rowData}
                testId='entities'
                isLoading={isSearching}
                page={pageNumber}
                total={totalEntities}
                next={nextPage}
                previous={previousPage}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                filters={{}}
                updateFilter={noop}
                clearAllFilters={noop}
                width={width}
                height={height}
            />
        </div>
    );
};
