import { isNull, noop } from 'lodash/fp';
import React, { useCallback, useMemo, useRef } from 'react';

import { Lei, SearchEntityBy } from '../../admin/entity/store';
import { Search } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Text } from '../../shared/text/Text';
import { RadioListOption } from '../radio/RadioList';
import { RadioRow } from '../radio/RadioRow';
import styles from './Lei.module.scss';
import { LeiSearchResults } from './LeiSearchResults';
import { Position } from '../modal/PositionModal';
import { FuzzyAutoCompletionsModal } from './FuzzyAutoCompletionsModal';

interface LeiSearchProps {
    pageNumber: number;
    totalEntities: number;
    pageSize: number;
    previousPage: () => void;
    nextPage: () => void;
    updatePageSize: (pageSize: number) => void;
    addEntity: (lei: Lei) => void;
    searchEntities: () => void;
    searchInput: string;
    searchBy: SearchEntityBy | null;
    setSearchInput: (value: string) => void;
    setSearchBy: (value: SearchEntityBy | null) => void;
    isSearching: boolean;
    searchResults: Lei[];
    isSaving: boolean;
    showFuzzyAutoCompletions?: boolean;
    fuzzyCompletions?: string[];
    autoCompletions?: string[];
    closeCompletionModal?: () => void;
}

export const LeiSearch: React.FC<LeiSearchProps> = ({
    pageNumber,
    totalEntities,
    pageSize,
    previousPage,
    nextPage,
    updatePageSize,
    addEntity,
    searchEntities,
    searchInput,
    searchBy,
    setSearchInput,
    setSearchBy,
    isSearching,
    searchResults,
    isSaving,
    showFuzzyAutoCompletions = false,
    fuzzyCompletions = [],
    autoCompletions = [],
    closeCompletionModal = noop
}) => {
    const searchInputRef = useRef<HTMLDivElement>(null);
    const xPosition = searchInputRef.current?.offsetLeft || 0;
    const yPosition = searchInputRef.current?.offsetTop ? searchInputRef.current.offsetTop + 40 : 0;
    const inputWidth = searchInputRef.current?.offsetWidth ? searchInputRef.current.offsetWidth - 10 : 0;
    const fuzzyAutoCompletionsPosition: Position = { x: xPosition, y: yPosition };
    const searchPlaceholder = useMemo(() => searchBy === SearchEntityBy.LEI ? 'LEI...' : 'Company Name...', [searchBy]);

    const leiSearchTooltip = 'There is a possibility that the results returned from your search may not always be accurate. Please try and search by LEI number or if your LEI does not appear in the table then please try again with a more specific search term.';

    const searchAllEntities = useCallback(() => searchBy && searchEntities(), [searchBy, searchEntities]);

    const keyDownSearch = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => e.key.toLowerCase() === 'enter' && searchAllEntities(), [searchAllEntities]);

    const selectMatch = useCallback((value: string) => {
        setSearchInput(value);
        searchAllEntities();
    }, [setSearchInput, searchAllEntities]);

    const maxLength = searchBy === SearchEntityBy.COMPANY_NAME ? 100 : 20;

    const searchOptions: RadioListOption[] = useMemo(() => [
        {
            id: SearchEntityBy.LEI,
            isSelected: searchBy === SearchEntityBy.LEI,
            label: 'LEI'
        },
        {
            id: SearchEntityBy.COMPANY_NAME,
            isSelected: searchBy === SearchEntityBy.COMPANY_NAME,
            label: 'Company Name',
            description: leiSearchTooltip
        }
    ], [searchBy, leiSearchTooltip]);

    const toggleSearchBy = useCallback((id: string) => setSearchBy(id as SearchEntityBy), [setSearchBy]);

    return (
        <div className={styles.searchEntitiesWrapper} data-testid='search-entities-wrapper'>
            <ModalHeader
                label='Search Legal Entities'
                icon={Search}
                testId='search-entities-modal'
            />
            <div className={styles.searchByWrapper}>
                <div className={styles.searchByTitle}>Search by:</div>
                <div className={styles.radioWrapper}>
                    <RadioRow options={searchOptions} onChange={toggleSearchBy} testId='search-entities' fontSize={16} />
                </div>
            </div>
            <div ref={searchInputRef}>
                <Text
                    testId='entity-search'
                    placeholder={searchPlaceholder}
                    value={searchInput}
                    maxLength={maxLength}
                    disabled={isNull(searchBy)}
                    onChange={e => setSearchInput(e.target.value)}
                    onKeyDown={keyDownSearch}
                />
            </div>
            <LeiSearchResults
                pageNumber={pageNumber}
                totalEntities={totalEntities}
                pageSize={pageSize}
                previousPage={previousPage}
                nextPage={nextPage}
                updatePageSize={updatePageSize}
                addEntity={addEntity}
                isSearching={isSearching}
                searchResults={searchResults}
                isSaving={isSaving}
            />
            <FuzzyAutoCompletionsModal
                isOpen={showFuzzyAutoCompletions}
                position={fuzzyAutoCompletionsPosition}
                inputWidth={inputWidth}
                selectMatch={selectMatch}
                closeModal={closeCompletionModal}
                fuzzyCompletions={fuzzyCompletions}
                autoCompletions={autoCompletions}
                searchValue={searchInput}
            />
        </div>
    );
};
