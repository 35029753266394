import { isNull, noop } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GaugeChart from 'react-gauge-chart';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { IconButton } from '../../../../shared/button/IconButton';
import { Icon } from '../../../../shared/icon/Icon';
import { Document, Sort } from '../../../../shared/icons';
import { Spinner } from '../../../../shared/spinner/Spinner';
import { scopeFormatter } from '../../../../shared/table/arkTableFormatters';
import { InformationTooltip, OverflowTooltip } from '../../../../shared/tooltip';
import styles from '../../Analytics.module.scss';
import { OpinionAnalyticsChart, OpinionAverage, OpinionAverageView, fetchOpinionAverageBreakdownStarted, getFetchingAnalytics, getOpinionAnalyticsScope, getOpinionAverageView, getOpinionsWithAverage, getTotalOpinionAverage, setOpinionAverageView } from '../../store';
import { AverageBreakdown } from './AverageBreakdown';
import { AverageLevel } from './AverageLevel';
import { AverageTable } from './AverageTable';
import { OpinionCommissionedBy } from '../../../../admin/opinions/store';

const { red, amber, gold, yellowGreen, mandatory, grey } = styles;

interface OpinionAverageChartWrapperProps {
    tileInView: null | OpinionAnalyticsChart;
    setTileInView: (tile: OpinionAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
    dimensions?: {
        height: number;
        width: number;
    };
}

export const OpinionAverageChartWrapper: React.FC<OpinionAverageChartWrapperProps> = ({ setTileInView, tileInView, analyticsSpinner, dimensions }) => {
    const dispatch = useAppDispatch();
    const [activePercentage, setActivePercentage] = useState<number>(0);
    const [selectedTableOpinion, setSelectedTableOpinion] = useState<OpinionAverage | null>(null);

    const scope = useAppSelector(getOpinionAnalyticsScope);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const totalAverage = useAppSelector(getTotalOpinionAverage);
    const opinionsWithAverage = useAppSelector(getOpinionsWithAverage);
    const currentView = useAppSelector(getOpinionAverageView);

    const chartIsFullScreen = useMemo(() => tileInView === OpinionAnalyticsChart.OPINIONS_AVERAGE, [tileInView]);
    const chartWidth = useMemo(() => chartIsFullScreen ? 'calc(50% - 10px)' : '90%', [chartIsFullScreen]);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(OpinionAnalyticsChart.OPINIONS_AVERAGE), [fetchingAnalytics]);

    const opinionTableView = useCallback((view: OpinionAverageView) => chartIsFullScreen && currentView === view, [chartIsFullScreen, currentView]);
    const showOpinionsTable = useMemo(() => opinionTableView(OpinionAverageView.OPINIONS), [opinionTableView]);
    const showBreakdown = useMemo(() => opinionTableView(OpinionAverageView.BREAKDOWN), [opinionTableView]);

    const toggleTileView = useCallback(() => {
        setTileInView(isNull(tileInView) ? OpinionAnalyticsChart.OPINIONS_AVERAGE : null);
    }, [setTileInView, tileInView]);

    const selectedPercentage = useMemo(() => {
        if (!isNull(selectedTableOpinion)) {
            return selectedTableOpinion.average;
        }
        return totalAverage;
    }, [selectedTableOpinion, totalAverage]);

    const totalOpinionsLabel = useMemo(() => opinionsWithAverage.length > 0 && isNull(selectedTableOpinion) ? `Total ${scopeFormatter(scope)} Opinions: ${opinionsWithAverage.length}` : '', [selectedTableOpinion, opinionsWithAverage, scope]);

    const changeView = useCallback((view: OpinionAverageView) => dispatch(setOpinionAverageView(view)), [dispatch]);

    const chartCursor = useMemo(() => isNull(selectedTableOpinion) ? 'cursor' : 'pointer', [selectedTableOpinion]);

    const fetchOpinionBreakdown = useCallback((opinionId: number) => {
        changeView(OpinionAverageView.BREAKDOWN);
        dispatch(fetchOpinionAverageBreakdownStarted(opinionId));
    }, [dispatch, changeView]);

    const getAverageBreakdown = useCallback(() => isNull(selectedTableOpinion) ? noop : fetchOpinionBreakdown(selectedTableOpinion.opinionId), [selectedTableOpinion, fetchOpinionBreakdown]);

    useEffect(() => () => {
        changeView(OpinionAverageView.OPINIONS);
        setSelectedTableOpinion(null);
    }, [dispatch, changeView]);

    useEffect(() => {
        setSelectedTableOpinion(null);
    }, [scope]);

    useEffect(() => {
        if (selectedPercentage !== activePercentage) {
            setActivePercentage(selectedPercentage);
        }
    }, [activePercentage, selectedPercentage]);

    const testId = 'opinion-average-chart';

    const wrapperDimensions = dimensions ? { height: dimensions.height, width: dimensions.width } : { height: '100%', width: '100%' };
    const tooltipInfo = useMemo(() => {
        if (showBreakdown) {
            return 'Below is an average score for the selected opinion.';
        }
        return 'Below is an average score for all opinions your client has access to.';
    }, [showBreakdown]);

    if (showSpinner || analyticsSpinner) {
        return <Spinner />;
    }

    return (
        <div className={styles.opinionAverageChartWrapper} style={wrapperDimensions} data-testid={`${testId}-wrapper`}>
            <div className={styles.opinionAverageChartHeader}>
                <div className={styles.centreGroup}>
                    <OverflowTooltip className={styles.opinionAverageChartTitle} overlayText='Opinion Average' testId={`${testId}-title`} />
                    <div className={styles.opinionAverageChartDescription}><InformationTooltip content={tooltipInfo} /></div>
                    <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>
                </div>
            </div>
            <div className={styles.chartContents}>
                <div className={styles.chartAndMeta} style={{ width: chartWidth, justifyContent: chartIsFullScreen ? '' : 'center' }}>
                    <div className={styles.gaugeChartWrapper} style={{ cursor: chartCursor }} onClick={getAverageBreakdown}>
                        <div className={styles.acceptableLabel}>Acceptable</div>
                        <GaugeChart
                            nrOfLevels={5}
                            colors={[red, amber, gold, yellowGreen, mandatory]}
                            needleColor={grey}
                            needleBaseColor={grey}
                            hideText
                            style={{ marginBottom: '0px' }}
                            percent={selectedPercentage}
                            animate={activePercentage !== selectedPercentage}
                        />
                        <div className={styles.ofSeriousConcernLabel} style={{ bottom: '20%' }}>Of Serious Concern</div>
                        <div className={styles.highlyDesirableLabel} style={{ bottom: '20%' }}>Highly Desirable</div>
                        <div>
                            <AverageLevel percentage={selectedPercentage} chartIsFullScreen={chartIsFullScreen} />
                            <div className={styles.totalOpinionsWrapper}>{totalOpinionsLabel}</div>
                        </div>
                    </div>
                    {chartIsFullScreen && !isNull(selectedTableOpinion) &&
                        <div className={styles.selectedAverage}>
                            <div className={styles.selectedAverageIcon}><Icon icon={Document} fontSize={60} /></div>
                            <div className={styles.selectedAverageDetails}>
                                <div className={styles.jurisdiction}>{selectedTableOpinion.jurisdiction}</div>
                                <div className={styles.focusAndBespoke}>
                                    <div className={styles.focus}>{selectedTableOpinion.focus}</div>
                                    <div className={styles.bespoke}>{selectedTableOpinion.bespoke ? 'Bespoke' : 'Industry Standard'}</div>
                                </div>
                                <div className={styles.commissionedBy}>Commissioned By: {selectedTableOpinion.commissionedBy === OpinionCommissionedBy.OTHER ? selectedTableOpinion.commissionedByIfOther : selectedTableOpinion.commissionedBy}</div>
                            </div>
                        </div>
                    }
                </div>
                {showOpinionsTable && <AverageTable height={dimensions?.height || 0} width={dimensions?.width || 0} fetchOpinionBreakdown={fetchOpinionBreakdown} selectedOpinion={selectedTableOpinion} selectOpinion={setSelectedTableOpinion} />}
                {showBreakdown && <AverageBreakdown height={dimensions?.height || 0} width={dimensions?.width || 0} changeView={changeView} />}
            </div>
        </div>
    );
};
