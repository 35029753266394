import { Client } from '../components/admin/clients/store';
import { api } from './services';

export type DeleteClientRequest = { clientId: number; };

export const fetchAllClients = async () => await api('client/getAll', {}, 'get');
export const updateClient = async (reqBody: Client) => await api('client/update', { ...reqBody }, 'post');
export const createClient = async (reqBody: Client) => await api('client/create', { ...reqBody }, 'post');
export const deleteClient = async (reqBody: DeleteClientRequest) => await api('client/delete', { ...reqBody }, 'post');
export const fetchAvailableClientFeaturePermissions = async () => await api('client/permissions/getAll', {}, 'get');
