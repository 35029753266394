import React from 'react';

export const Currencies: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width || '1em'} height={height || '1em'} x="0" y="0" viewBox="0 0 432 432">
        <g>
            <path d="M104 208C46.562 208 0 161.438 0 104S46.562 0 104 0s104 46.562 104 104c-.065 57.411-46.589 103.935-104 104zm0-192c-48.601 0-88 39.399-88 88s39.399 88 88 88 88-39.399 88-88c-.055-48.578-39.422-87.945-88-88zM104 432C46.562 432 0 385.438 0 328s46.562-104 104-104 104 46.562 104 104c-.065 57.411-46.589 103.935-104 104zm0-192c-48.601 0-88 39.399-88 88s39.399 88 88 88 88-39.399 88-88c-.055-48.578-39.422-87.945-88-88zM328 432c-57.438 0-104-46.562-104-104s46.562-104 104-104 104 46.562 104 104c-.065 57.411-46.589 103.935-104 104zm0-192c-48.601 0-88 39.399-88 88s39.399 88 88 88 88-39.399 88-88c-.055-48.578-39.422-87.945-88-88zM328 208c-57.438 0-104-46.562-104-104S270.562 0 328 0s104 46.562 104 104c-.065 57.411-46.589 103.935-104 104zm0-192c-48.601 0-88 39.399-88 88s39.399 88 88 88 88-39.399 88-88c-.055-48.578-39.422-87.945-88-88z" fill={color || 'currentColor'} opacity="1" data-original="#000000"></path>
            <path d="M364.438 139.441a44.562 44.562 0 0 1-28.516 3.695 41.832 41.832 0 0 1-27.876-19.391h30.602a8 8 0 0 0 0-16h-35.806a39.033 39.033 0 0 1 .04-8h35.766a8 8 0 0 0 0-16h-30.184a42.46 42.46 0 0 1 36.387-19.746 43.606 43.606 0 0 1 19.586 4.559 8.001 8.001 0 0 0 7.125-14.328 60.338 60.338 0 0 0-26.711-6.231c-23.677-.314-45.149 13.854-54.173 35.746H272a8 8 0 0 0 0 16h14.919a55.2 55.2 0 0 0-.055 8H272a8 8 0 0 0 0 16h18.29a57.61 57.61 0 0 0 42.609 35.102 60.411 60.411 0 0 0 38.664-5.078 8.001 8.001 0 0 0-7.125-14.328zM132.492 272.102 104 311.984l-28.492-39.883a7.997 7.997 0 0 0-11.164-1.812 7.997 7.997 0 0 0-1.852 11.109l25.965 36.352H76a8 8 0 0 0 0 16h20v12H76a8 8 0 0 0 0 16h20v17.5a8 8 0 0 0 16 0v-17.5h20a8 8 0 0 0 0-16h-20v-12h20a8 8 0 0 0 0-16h-12.458l25.965-36.352a7.998 7.998 0 0 0-13.015-9.296zM358.016 365.008a23.313 23.313 0 0 1-24.641 2.57 43.25 43.25 0 0 0-20.297-3.356 62.381 62.381 0 0 0 6.091-24.082h16.12a8 8 0 0 0 0-16h-18.128c-2.865-9.414-9.234-18.637-7.598-24.547 2.265-9.521 11.82-15.404 21.341-13.138a17.723 17.723 0 0 1 11.753 9.322 7.999 7.999 0 0 0 13.734-8.203c-8.977-16.339-29.499-22.307-45.838-13.33a33.755 33.755 0 0 0-16.412 21.08c-3.257 11.774 2.082 19.752 5.968 28.816h-6.726a8 8 0 0 0 0 16h9.808a61.362 61.362 0 0 1-13.308 31.832 8.835 8.835 0 0 0 11.68 12.769 26.474 26.474 0 0 1 26.391-2.109 38.866 38.866 0 0 0 39.266-4.531 8.002 8.002 0 1 0-9.204-13.093zM104.461 95.855C76.066 92.954 83.398 69.351 104 69.351a19.818 19.818 0 0 1 16.492 7.699 8 8 0 0 0 13.375-8.781A34.14 34.14 0 0 0 112 54.207v-6.54a8 8 0 0 0-16 0v6.575c-34.957 7.228-37.175 53.08 6.844 57.53 29.12 2.948 21.995 26.875 1.156 26.875a19.59 19.59 0 0 1-16.633-7.93 8.002 8.002 0 0 0-13.578 8.469A33.835 33.835 0 0 0 96 153.79v6.54a8 8 0 0 0 16 0v-6.575c35.341-7.305 36.985-53.403-7.539-57.9z" fill={color || 'currentColor'} opacity="1" data-original="#000000"></path>
        </g>
    </svg>
);
