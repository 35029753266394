import { ApplicationState } from '../../../../store/rootReducer';
import { BasicUserInfo, FeaturePermissionDB, NewUser, ClientUser, UsersState } from './types';

const getRoot = (state: ApplicationState): UsersState => state.admin.users;

export const getModalOpen = (state: ApplicationState): boolean => getRoot(state).modalOpen;

export const getNewUser = (state: ApplicationState): NewUser => getRoot(state).user;

export const getUsernameUnavailable = (state: ApplicationState): boolean => getRoot(state).usernameUnavailable;

export const getCheckingUsername = (state: ApplicationState): boolean => getRoot(state).checkingUsername;

export const getEmailValid = (state: ApplicationState): boolean => getRoot(state).emailValid;

export const getAllUsers = (state: ApplicationState): ClientUser[] => getRoot(state).allUsers;

export const getAvailableFeaturePermissions = (state: ApplicationState): FeaturePermissionDB[] => getRoot(state).availableFeaturePermissions;

export const getConfirmDeleteUser = (state: ApplicationState): ClientUser | null => getRoot(state).confirmDeleteUser;

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).deletingUser;

export const getIsFetchingUsers = (state: ApplicationState): boolean => getRoot(state).fetchingUsers;

export const getAllBasicUsers = (state: ApplicationState): BasicUserInfo[] => getRoot(state).basicUserInfo;
