import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';

export enum DoraAnalyticsActionTypes {
    SET_DORA_ANALYTICS_TILE_IN_VIEW = 'SET_DORA_ANALYTICS_TILE_IN_VIEW',
    FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_STARTED = 'FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_STARTED',
    FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_SUCCESSFUL = 'FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_SUCCESSFUL',
    FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_FAILED = 'FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_FAILED',
    SET_SELECTED_THIRD_PARTY_COMPANY_ID = 'SET_SELECTED_THIRD_PARTY_COMPANY_ID',
    FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_STARTED = 'FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_STARTED',
    FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_SUCCESSFUL = 'FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_SUCCESSFUL',
    FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_FAILED = 'FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_FAILED',
    FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_STARTED = 'FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_STARTED',
    FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_SUCCESSFUL = 'FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_SUCCESSFUL',
    FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_FAILED = 'FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_FAILED',
    SET_SELECTED_THIRD_PARTY_COVERAGE_PAGE_SIZE = 'SET_SELECTED_THIRD_PARTY_COVERAGE_PAGE_SIZE',
    SET_SELECTED_THIRD_PARTY_COVERAGE_COLUMN_SORT = 'SET_SELECTED_THIRD_PARTY_COVERAGE_COLUMN_SORT',
    SELECTED_THIRD_PARTY_COVERAGE_PAGINATION_NEXT = 'SELECTED_THIRD_PARTY_COVERAGE_PAGINATION_NEXT',
    SELECTED_THIRD_PARTY_COVERAGE_PAGINATION_PREVIOUS = 'SELECTED_THIRD_PARTY_COVERAGE_PAGINATION_PREVIOUS',
    SET_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS = 'SET_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS',
    CLEAR_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS = 'CLEAR_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS',
    FETCH_NETWORK_DIAGRAM_ANALYTICS_STARTED = 'FETCH_NETWORK_DIAGRAM_ANALYTICS_STARTED',
    FETCH_NETWORK_DIAGRAM_ANALYTICS_SUCCESSFUL = 'FETCH_NETWORK_DIAGRAM_ANALYTICS_SUCCESSFUL',
    FETCH_NETWORK_DIAGRAM_ANALYTICS_FAILED = 'FETCH_NETWORK_DIAGRAM_ANALYTICS_FAILED',
    FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_STARTED = 'FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_STARTED',
    FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_SUCCESSFUL = 'FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_SUCCESSFUL',
    FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_FAILED = 'FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_FAILED',
    SET_SELECTED_OUTSTANDING_CONTRACTUAL_DATA_SECTION = 'SET_SELECTED_OUTSTANDING_CONTRACTUAL_DATA_SECTION',
    SET_OUTSTANDING_CONTRACTUAL_DATA_MODAL_OPEN = 'SET_OUTSTANDING_CONTRACTUAL_DATA_MODAL_OPEN'
}

export enum DoraAnalyticsChart {
    THIRD_PARTY_COVERAGE = 'third party coverage',
    NETWORK_DIAGRAM = 'network diagram',
    CONTRACTUAL_DATA = 'contractual data'
}

export interface ThirdPartyCoverageAnalytics {
    name: string;
    thirdPartyCompanyId: number;
    percentage: number;
}

export interface ThirdPartyFunctionCompanyCoveragePieAnalytics {
    key: string;
    value: number;
    color?: string;
}

export enum ThirdPartyFunctionCompanyCoveragePieCategories {
       NO_IMPACT = 'Does not impact function',
       FULL_IMPACT = 'Function is critical, impact is material',
       CRITICAL_NOT_MATERIAL_IMPACT = 'Function is critical, impact not material',
       NOT_CRITICAL_MATERIAL_IMPACT = 'Function not critical, impact is material',
       NOT_CRITICAL_NOT_MATERIAL_IMPACT = 'Function not critical, impact not material'
}

export interface SelectedThirdPartyCoverage {
    functionCompanyId: number;
    functionName: string;
    companyName: string;
    functionImpacted: boolean;
    isCritical: boolean;
    isMaterial: boolean;
    supplyChainRank: number | null;
    pieCategory: ThirdPartyFunctionCompanyCoveragePieCategories;
}

export enum SupplyChainRole {
    MY_COMPANY = 'My Company',
    THIRD_PARTY = 'Third Party',
    FUNCTION = 'Function'
}

export interface GraphNode {
    id: string;
    name: string;
    role: SupplyChainRole;
}

export interface GraphLink {
    source: string;
    target: string;
    isIndirect: boolean;
}

export interface NetworkDiagramData {
    nodes: GraphNode[];
    links: GraphLink[];
}

export interface NetworkNodeDetails {
    name: string;
    count: number;
    role: SupplyChainRole;
    directLinks: { name: string, role: SupplyChainRole}[];
    indirectLinks: { name: string, role: SupplyChainRole}[];
}

export interface LegendIndicator {
    title: string;
    backgroundColor: string;
    linkIndicator?: boolean;
}

export interface ContractualDataAnalytics {
    doraSupplyChainId: number;
    doraFunctionCompanyId: number;
    doraSupplyChainContractId: number | null;
    doraSupplyChainAssessmentId: number | null;
    functionName: string;
    companyName: string;
    intraGroupCompanyName: string | null;
    thirdPartyCompanyName: string | null;
}

export enum OutstandingContractualInformationPieCategories {
    MISSING_BOTH = 'Missing Contractual AND Assessment Info',
    MISSING_ONE = 'Missing Contractual OR Assessment Info',
    COMPLETE = 'Has Both Contractual and Assessment Info'
}

export interface OutstandingContractualInformationPieAnalytics {
    key: OutstandingContractualInformationPieCategories;
    value: number;
}

export interface DoraAnalyticsState {
    tileInView: DoraAnalyticsChart | null;
    thirdPartyCoverage: ThirdPartyCoverageAnalytics[];
    fetchingAnalytics: DoraAnalyticsChart[];
    selectedThirdPartyCoveragePie: ThirdPartyFunctionCompanyCoveragePieAnalytics[];
    analyticsError: string | null;
    totalFunctionCompanies: number;
    selectedThirdPartyCoverageTable: SelectedThirdPartyCoverage[];
    selectedThirdPartyCompanyName: string;
    selectedThirdPartyCompanyId: number | null;
    selectedThirdPartyCoverageTablePageSize: number;
    selectedThirdPartyCoverageTablePageNumber: number;
    selectedThirdPartyCoverageTableColumnSort?: ColumnSort;
    selectedThirdPartyCoverageTableFilters: TableFilters;
    fetchingSelectedThirdPartyCoverageTable: boolean;
    networkDiagramData: NetworkDiagramData;
    contractualDataAnalytics: ContractualDataAnalytics[];
    selectedContractualDataAnalytics: string;
    contractualDataModalOpen: boolean;
}
