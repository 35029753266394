import { ApplicationState } from '../../../../store/rootReducer';
import { CompanyEntity } from '../../../admin/entity/store';
import { DoraReportingState, PreliminaryRoi } from './types';

const getRoot = (state: ApplicationState): DoraReportingState => state.dora.reporting;

export const getReportDownloading = (state: ApplicationState): boolean => getRoot(state).documentDownloading;

export const getPreliminaryRoi = (state: ApplicationState): PreliminaryRoi => getRoot(state).preliminaryRoi;

export const getEntitiesInScope = (state: ApplicationState): CompanyEntity[] => getRoot(state).entitiesInScope;

export const getSelectedInScopeEntity = (state: ApplicationState): CompanyEntity | null => getRoot(state).selectedInScopeEntity;
