import React from 'react';

import styles from './PlaybookReadOnly.module.scss';
import { PullRequest } from '../../icons';
import { Icon } from '../../icon/Icon';

interface SuggestedChangesMarkerProps {
    testId?: string;
}

export const SuggestedChangesMarker: React.FC<SuggestedChangesMarkerProps> = ({ testId = 'playbook' }) => (
    <div className={styles.suggestedChangeMarker} data-testid={`${testId}-suggested-change-marker`}>
        <Icon icon={PullRequest} fontSize={14} />
    </div>
);
