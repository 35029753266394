import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getPlaybook, getProvisionOrder, getReorderProvisionModalOpen, DraggableTile, saveProvisionsOrder, setProvisionsOrder, toggleProvisionsOrderModalOpen, isSubheaderSection } from './store';
import { ReorderModal } from './ReorderModal';
import { getProvisionTitle } from '../../constants/playbooks';

export const ReorderProvisionsModal: React.FC = () => {

    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getReorderProvisionModalOpen);
    const provisionOrder = useAppSelector(getProvisionOrder);
    const playbook = useAppSelector(getPlaybook);

    const provisionTitle = useCallback((id: string) => getProvisionTitle(id, playbook.content.sections), [playbook.content.sections]);

    const closeModal = useCallback(() => dispatch(toggleProvisionsOrderModalOpen()), [dispatch]);
    const saveOrder = useCallback(() => dispatch(saveProvisionsOrder()), [dispatch]);
    const setOrder = useCallback((newProvisionOrder: DraggableTile[]) => dispatch(setProvisionsOrder(newProvisionOrder)), [dispatch]);
    const savedOrder: DraggableTile[] = useMemo(() => playbook.content.sections.map(section => ({ id: section.sectionId, title: provisionTitle(section.sectionId), isSubheader: isSubheaderSection(section) })), [playbook, provisionTitle]);

    return (
        <ReorderModal
            isOpen={isOpen}
            currentOrder={provisionOrder}
            closeModal={closeModal}
            saveOrder={saveOrder}
            setOrder={setOrder}
            savedOrder={savedOrder}
            title='Provisions'
            testId='provisions'
        />
    );
};
