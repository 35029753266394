import { AttestationForm, AttestationManager, NewAttestationInstances } from '../components/admin/attestations/store';
import { UserAttestationInstance } from '../components/attestations/store';
import { TableFilters } from '../components/shared/modal/TableFilterModal';
import { ColumnSort } from '../components/shared/table/ArkTable';
import { api } from './services';

interface AttestationInstanceRequest {
    completed: boolean;
    pageSize: number;
    pageNumber: number;
    filters: TableFilters;
    columnSort: ColumnSort | undefined;
}

interface DeleteAttestationInstanceRequest extends AttestationInstanceRequest {
    attestationInstanceId: number;
}

interface SaveUserAttestationInstanceRequest extends AttestationInstanceRequest {
    attestation: UserAttestationInstance;
    isDraft: boolean;
    userAnswerId: string;
}

interface SaveAdminAttestationInstanceRequest extends AttestationInstanceRequest {
    attestation: AttestationManager;
}

interface NewAdminAttestationInstanceRequest extends AttestationInstanceRequest {
    newAttestations: NewAttestationInstances;
}

// Form
export const fetchAllAttestationForms = async () => await api('attestation/admin/form/getAll', {}, 'get');
export const upsertAttestationForm = async (reqBody: AttestationForm) => await api('attestation/admin/form/upsert', reqBody, 'post');
export const deleteAttestationForm = async (reqBody: { attestationFormId: number }) => await api('attestation/admin/form/delete', { ...reqBody }, 'post');

// Manager
export const upsertAttestationInstance = async (reqBody: NewAdminAttestationInstanceRequest) => await api('attestation/admin/upsert', reqBody, 'post');
export const fetchAllAttestationInstances = async (reqBody: AttestationInstanceRequest) => await api('attestation/admin/getAll', { ...reqBody }, 'post');
export const deleteAttestationInstance = async (reqBody: DeleteAttestationInstanceRequest) => await api('attestation/admin/delete', { ...reqBody }, 'post');
export const updateAttestationInstance = async (reqBody: SaveAdminAttestationInstanceRequest) => await api('attestation/admin/update', { ...reqBody }, 'post');
export const completeAttestationInstance = async (reqBody: SaveAdminAttestationInstanceRequest) => await api('attestation/admin/complete', { ...reqBody }, 'post');

// User
export const fetchAllUserAttestationInstances = async (reqBody: AttestationInstanceRequest) => await api('attestation/getAll', { ...reqBody }, 'post');
export const saveUserAttestationInstance = async (reqBody: SaveUserAttestationInstanceRequest) => await api('attestation/upsert', { ...reqBody }, 'post');
