import React, { useCallback, useMemo } from 'react';
import { isNull, min } from 'lodash/fp';

import { PieChart, PieColors, PieChartAnalyticsData } from '../../../shared/analytics/PieChart';
import { DocumentAnalyticsChart } from '../store';
import { Icon } from '../../../shared/icon/Icon';
import styles from '../Analytics.module.scss';
import { InformationTooltip } from '../../../shared/tooltip';
import { IconButton } from '../../../shared/button/IconButton';
import { Sort } from '../../../shared/icons';

interface PieChartWrapperProps {
    data: PieChartAnalyticsData[];
    dimensions?: {
        height: number;
        width: number;
    }
    title: string;
    description?: string;
    id?: string;
    testId?: string;
    icon?: React.FC;
    colors?: keyof PieColors;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    tileInView: DocumentAnalyticsChart | null;
    currentTile: DocumentAnalyticsChart;
    showExpandIcon?: boolean;
}

export const PieChartWrapper: React.FC<PieChartWrapperProps> = ({ title, data, testId, id, dimensions, icon, colors, description, currentTile, tileInView, setTileInView, showExpandIcon = true }) => {

    const diameter = useMemo(() => {
        if (dimensions) {
            const svgWidth = dimensions.width - 20;
            const svgHeight = dimensions.height - 55;
            return min([svgWidth, svgHeight])!;
        }
        return 0;
    }, [dimensions]);

    const filteredData = useMemo(() => data.filter(({ value }) => value), [data]);
    const toggleTileView = useCallback(() => setTileInView(isNull(tileInView) ? currentTile : null), [setTileInView, tileInView, currentTile]);

    return (
        <div className={styles.pieChartWrapper} data-testid={`pie-chart-wrapper-${testId}`}>
            <div className={styles.pieChartHeader}>
                <div className={styles.pieChartTitle} data-testid={`pie-chart-title-${testId}`}>{title}</div>
                {description && <div className={styles.pieChartDescription}><InformationTooltip content={description} /></div>}
                {showExpandIcon && <div className={styles.expandIcon}><IconButton icon={Sort} onClick={toggleTileView} fontSize={20} /></div>}
            </div>
            <PieChart data={filteredData} testId={testId} id={id} diameter={diameter} colors={colors} />
            {icon &&
                <div
                    className={styles.pieChartIcon}
                    style={{ height: `${diameter / 3}px`, width: `${diameter / 3}px` }}
                    data-testid={`pie-chart-icon-${testId}`}
                >
                    <Icon icon={icon} fontSize={diameter / 5} />
                </div>
            }
        </div>
    );
};
