import React, { useCallback, useMemo } from 'react';
import { isNull, isUndefined } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { DocumentCustomFilters, OpinionCustomFilters } from '../../auth/login/store';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { getCurrentUserCustomFilters, getFilterTileModalOpen, updateCustomFilterValue } from '../store';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { fetchAllEntitiesStarted } from '../../admin/entity/store';
import { fetchAvailableDocumentNamesStarted } from '../../admin/documents/store';
import styles from '../Home.module.scss';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Text } from '../../shared/text/Text';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { CustomTooltip } from '../../shared/tooltip';
import { CustomFiltersBody } from './CustomFiltersBody';

interface CustomFiltersTileModalProps {
    closeModal: () => void;
}

export const CustomFiltersTileModal: React.FC<CustomFiltersTileModalProps> = (({ closeModal }) => {
    const dispatch = useAppDispatch();

    useFetchStarted([fetchAvailableDocumentNamesStarted(), fetchAllEntitiesStarted(true)]);

    const selectedFilterId = useAppSelector(getFilterTileModalOpen);

    const currentCustomFilters = useAppSelector(getCurrentUserCustomFilters);

    const selectedTile = useMemo(() => currentCustomFilters.find(({ id }) => id === selectedFilterId) || null, [currentCustomFilters, selectedFilterId]);

    const filterTable = useMemo(() => !isNull(selectedTile) ? capitaliseStartLetter(selectedTile.filterTable) + ' Custom Filter' : '', [selectedTile]);

    const updateFilter = useCallback((key: keyof DocumentCustomFilters | keyof OpinionCustomFilters, value: string | string[] | null, type: keyof TableFilterType = 'text') => !isNull(selectedFilterId) && dispatch(updateCustomFilterValue(key, value, selectedFilterId, type)), [dispatch, selectedFilterId]);

    const filterNameExists = useMemo(() => !isUndefined(currentCustomFilters.filter(({ filterTable, id }) => (filterTable === selectedTile?.filterTable) && selectedFilterId !== id).find(({ filterName }) => filterName === selectedTile?.filterName)), [currentCustomFilters, selectedTile, selectedFilterId]);

    const closeDisabled = useMemo(() => !selectedTile?.filterName || filterNameExists, [selectedTile?.filterName, filterNameExists]);
    const closeDisabledTooltip = useMemo(() => {
        if (!selectedTile?.filterName) {
            return ['You must add a name'];
        }
        if (filterNameExists) {
            return ['Custom filter names must be unique'];
        }
    }, [selectedTile, filterNameExists]);

    if (isNull(selectedTile)) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen
            showConfirm={false}
            closeLabel='Close'
            closeModal={closeModal}
            closeDisabled={closeDisabled}
            closeDisabledTooltip={closeDisabledTooltip}
            zIndex={10}
            maxWidth={25}
        >
            <div className={styles.customFilterWrapper}>
                <div className={styles.filterTypeHeader}>{filterTable}</div>
                <div className={styles.filterHeaderLabel}>Filter Name</div>
                <div className={styles.filterNameInputWrapper}>
                    <CustomTooltip overlayText={selectedTile.filterName} trigger='hover'>
                        <Text
                            label=''
                            testId='custom-filters-filter-name'
                            placeholder='Custom Filter Name...'
                            value={selectedTile.filterName}
                            maxLength={128}
                            onChange={e => updateFilter('filterName', e.target.value)}
                        />
                    </CustomTooltip>
                </div>
                <div className={styles.customFilterTileModalWrapper}>
                    <Scrollable maxHeight='50vh'>
                        <div className={styles.customFilterInformationWrapper}>
                            <CustomFiltersBody updateFilter={updateFilter} selectedTile={selectedTile} />
                        </div>
                    </Scrollable>
                </div>
            </div>
        </ConfirmationModal>
    );
});
