import React, { useEffect, useMemo, useState } from 'react';

import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Analytics } from '../../shared/analytics/Analytics';
import { Spinner } from '../../shared/spinner/Spinner';
import { AdminAttestationProgress } from './AdminAttestationProgress';
import { PageUsagePieChartWrapper } from './AdminPageUsagePieChartWrapper';
import { AdminAnalyticsChart, fetchAttestationProgressStarted, fetchPageUsageAnalyticsStarted } from './store';

interface AnalyticsTile {
    id: string;
    chart: JSX.Element | null;
    column: number;
    marginBottom?: string;
}

export const AdminAnalytics: React.FC = () => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [tileDimensions, setTileDimensions] = useState<undefined | { height: number; width: number; }>(undefined);
    useFetchStarted([fetchAttestationProgressStarted(), fetchPageUsageAnalyticsStarted()]);

    const [screenWidth, screenHeight] = useWindowResize();

    const analyticsCharts: AnalyticsTile[] = useMemo(() => [
        {
            id: AdminAnalyticsChart.ATTESTATION_PROGRESS,
            chart: <AdminAttestationProgress dimensions={tileDimensions} analyticsSpinner={showSpinner} />,
            marginBottom: '20px',
            column: 1
        },
        {
            id: 'chart-2',
            chart: <PageUsagePieChartWrapper dimensions={tileDimensions} analyticsSpinner={showSpinner} />,
            marginBottom: '20px',
            column: 2
        }
    ], [tileDimensions, showSpinner]);

    useEffect(() => {
        setShowSpinner(true);
        if (screenWidth && screenHeight) {
            const width = (screenWidth - 80) / 2;
            const height = (screenHeight - 190);
            setTileDimensions({ height, width });
            setTimeout(() => setShowSpinner(false), 50);
        }
    }, [screenWidth, screenHeight, setShowSpinner]);

    if (showSpinner) {
        return <Spinner />;
    }

    return (
        <Analytics
            charts={analyticsCharts}
            tileInView={null}
            showSpinner={showSpinner}
            testId='admin-analytics'
            analyticsColumns={2}
            analyticsRows={1}
        />
    );
};
