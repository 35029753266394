import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { CompanyEntity } from '../../admin/entity/store';
import { OfficeBuilding } from '../../shared/icons';
import { Action } from '../../shared/modal/ActionModal';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { clearInScopeCompanyTableFilters, companiesInScopePaginationNext, companiesInScopePaginationPrevious, companyFunctionMigrationCheckStarted, fetchEntityFunctionIdsStarted, fetchPaginatedInScopeDoraCompaniesStarted, getInScopeCompaniesPageNumber, getInScopeCompaniesPageSize, getInScopeCompanyColumnSort, getInScopeCompanyFilters, getInScopeTotalCompanies, getIsFetchingCompanies, getMyCompanyEntitiesDropdownOptions, getPaginatedInScopeDoraCompanies, setInScopeCompaniesPageSize, setInScopeCompanyTableColumnSort, setInScopeCompanyTableFilters, setSelectedCompanyEntity, toggleCompanyEntityModal, toggleRoiMaintainer } from './store';

export const HEIGHT_OF_THE_WIZARD = 45;

interface MyCompaniesInScopeForDoraProps {
    hasDoraFullAccessPermission: boolean;
}

export const MyCompaniesInScopeForDora: React.FC<MyCompaniesInScopeForDoraProps> = ({ hasDoraFullAccessPermission }) => {
    const [, screenHeight] = useWindowResize();

    useFetchStarted([fetchPaginatedInScopeDoraCompaniesStarted()]);

    const dispatch = useAppDispatch();
    const setCompanyEntity = useCallback((row: CompanyEntity) => {
        dispatch(setSelectedCompanyEntity(row));
        dispatch(toggleCompanyEntityModal(true));
        dispatch(fetchEntityFunctionIdsStarted(row.entityId!));
    }, [dispatch]);

    const companyEntities = useAppSelector(getPaginatedInScopeDoraCompanies);
    const isLoading = useAppSelector(getIsFetchingCompanies);
    const pageNumber = useAppSelector(getInScopeCompaniesPageNumber);
    const totalCompanies = useAppSelector(getInScopeTotalCompanies);
    const companyFilters = useAppSelector(getInScopeCompanyFilters);
    const columnSort = useAppSelector(getInScopeCompanyColumnSort);
    const pageSize = useAppSelector(getInScopeCompaniesPageSize);
    const companyEntityOptions = useAppSelector(getMyCompanyEntitiesDropdownOptions);

    const nextPage = useCallback(() => dispatch(companiesInScopePaginationNext()), [dispatch]);
    const previousPage = useCallback(() => dispatch(companiesInScopePaginationPrevious()), [dispatch]);

    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => dispatch(setInScopeCompanyTableFilters(key, value, type)), [dispatch]);
    const clearAllFilters = useCallback(() => dispatch(clearInScopeCompanyTableFilters()), [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => dispatch(setInScopeCompanyTableColumnSort(columnSort)), [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => dispatch(setInScopeCompaniesPageSize(pageSize)), [dispatch]);
    const doraInScopeArray = useMemo(() => companyEntities.map(({ entityId }) => entityId!), [companyEntities]);
    const doraMaintainerROI = useMemo(() => companyEntities.filter(({ doraMaintainerROI }) => doraMaintainerROI > 0).map(({ entityId }) => entityId!), [companyEntities]);

    const actions = useCallback((data: CompanyEntity): Action[] => [{ label: 'Open', icon: OfficeBuilding, onClick: () => setCompanyEntity(data) }], [setCompanyEntity]);

    const toggleScope = useCallback((entityId: string | number) => {
        const id = typeof entityId === 'string' ? parseInt(entityId) : entityId;
        const entity = companyEntities.find(entity => entity.entityId === id)!;
        dispatch(setSelectedCompanyEntity(entity));
        dispatch(companyFunctionMigrationCheckStarted(id));
    }, [dispatch, companyEntities]);

    const toggleMaintainerROI = useCallback((id: number | string) => {
        const entityId = typeof id === 'number' ? id : parseInt(id);
        dispatch(toggleRoiMaintainer(entityId));
    }, [dispatch]);

    const inScopeDisabled = useCallback(() => !hasDoraFullAccessPermission, [hasDoraFullAccessPermission]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'name', header: 'Name', field: 'name', width: 0.65, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'doraMaintainerROI', header: 'Maintains R.O.I', description: 'One entity should maintain a "Register of Information" for DORA purposes', field: 'entityId', width: 0.15, select: toggleMaintainerROI, selected: doraMaintainerROI, disabled: () => !hasDoraFullAccessPermission, component: 'checkbox' },
        { id: 'myCompaniesScope', header: 'Mark out of scope', field: 'entityId', width: 0.15, select: toggleScope, selected: doraInScopeArray, disabled: inScopeDisabled, component: 'checkbox' },
        { id: 'actions', header: '', field: '', component: 'action', width: 0.05, actions: actions }
    ];

    const tableHeight = useMemo(() => (screenHeight * 0.75) - HEIGHT_OF_THE_WIZARD, [screenHeight]);

    const filterDropdownOptions = useMemo(() => ({
        name: companyEntityOptions
    }), [companyEntityOptions]);

    return (
        <ArkTable
            colDefs={columnDefs}
            rowData={companyEntities}
            testId='in-scope'
            isLoading={isLoading}
            page={pageNumber}
            total={totalCompanies}
            next={nextPage}
            previous={previousPage}
            filters={companyFilters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => setCompanyEntity(row as CompanyEntity)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
            filterDropdownOptions={filterDropdownOptions}
            height={tableHeight}
        />
    );
};
