import classNames from 'classnames';
import { isNull, min, values } from 'lodash/fp';
import React, { useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DatasetFieldType, GroupDatasetField } from '../../../datasets/store';
import { Draggable } from '../../../shared/drag-n-drop/vertical/Draggable';
import { Icon as ArkIcon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide, Delete, HamburgerMenu, Sort } from '../../../shared/icons';
import { fieldTypeWidths, getRemainingGroupWidth } from '../FieldList';
import sharedStyles from '../SharedBuilder.module.scss';
import { getSelectedGroup, removeDatasetField, selectGroup } from '../store';
import styles from './DatasetComponents.module.scss';

interface GroupContentProps {
    field: GroupDatasetField;
    groupId: string;
    groupIndex: number;
    getChildElement: (id: string, index: number, type?: string) => JSX.Element | null;
}

export const GroupContent: React.FC<GroupContentProps> = ({ groupId, field, groupIndex, getChildElement }) => {
    const [groupOpen, setGroupOpen] = useState<boolean>(true);
    const { children } = field;
    const dispatch = useAppDispatch();
    const selectedGroupId = useAppSelector(getSelectedGroup);

    const removeRow = () => {
        dispatch(selectGroup(null));
        dispatch(removeDatasetField(groupId));
    };

    const toggleGroup = () => {
        const groupIdToSelect = groupId === selectedGroupId ? null : groupId;
        dispatch(selectGroup(groupIdToSelect));
    };

    const groupOpenIcon = groupOpen ? CaretDown : CaretSide;

    const toggleGroupOpen = () => setGroupOpen(!groupOpen);

    const labelNotPopulated = useMemo(() => children.some(({ label }) => label.length === 0), [children]);
    const fieldNotLinked = useMemo(() => {
        return children.filter(({ type, settings }) => {
            // Excluding calculator in this check as it cannot be part of a group
            const linkedFieldExists = [DatasetFieldType.DROPDOWN, DatasetFieldType.LABEL, DatasetFieldType.DOCUMENT, DatasetFieldType.DATASET].includes(type);
            const notLinked = isNull(settings.datasetLinked) || isNull(settings.dropdownLinked) || isNull(settings.documentProperty) || isNull(settings.labelContent);
            return linkedFieldExists && notLinked;
        }).length > 0;
    }, [children]);

    const childItems = useMemo(() => children.map(({ id, label, type }) => ({ id: id!, label: label ? `${label} (${type})` : type, type: groupId })), [children, groupId]);

    const groupIsSelected = useMemo(() => groupId === selectedGroupId, [groupId, selectedGroupId]);

    return (
        <div
            className={classNames(sharedStyles.contentWrapper, {
                [styles.selectedGroupWrapper]: groupIsSelected,
                [styles.selectedGroupWrapperRequired]: labelNotPopulated || fieldNotLinked
            })}
        >
            <div className={styles.groupContentWrapper}>
                <div className={styles.headerWrapper}>
                    <div className={styles.groupHeader} onClick={toggleGroup} data-testid={`draggable-group-${groupIndex}`}>
                        <ArkIcon icon={Sort} fontSize={25} />
                        <div className={styles.groupHeaderLabel}>Group</div>
                        <ArkIcon icon={HamburgerMenu} fontSize={25} />
                    </div>
                    {children && getRemainingGroupWidth(children) < min(values(fieldTypeWidths))! &&
                        <div className={styles.groupFieldLimitWarning} data-testid={`draggable-group-${groupIndex}-full-group-warning`}>
                            {`This group is now full. (${children.length} fields)`}
                        </div>}
                    <div className={styles.groupRightButtons}>
                        <button className={sharedStyles.groupOpenToggle} onClick={toggleGroupOpen} data-testid={`draggable-group-${groupIndex}-collapse`}>
                            <ArkIcon icon={groupOpenIcon} fontSize={20} />
                        </button>
                        <button className={sharedStyles.removeRowWrapper} onClick={removeRow} data-testid={`draggable-group-${groupIndex}-delete-button`}>
                            <ArkIcon icon={Delete} fontSize={20} />
                        </button>
                    </div>
                </div>
                <div>
                    {groupOpen && childItems.map((item, index) => (
                        <Draggable
                            key={item.id}
                            item={item}
                            index={index}
                            getChildElement={getChildElement}
                            type={groupId}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
