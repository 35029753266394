import { ValueFormatterParams } from 'ag-grid-community';
import { isNull, isUndefined } from 'lodash/fp';

import { formatDate, SHORT_MONTH_FORMAT } from '../../../utils/luxon';
import { capitaliseStartLetter } from '../../../utils/regex-utils';
import { roleLabels } from '../../constants/roleLabels';
import { UserRole } from '../../constants/permittedRoles';
import { OpinionScope, OpinionCommissionedBy } from '../../admin/opinions/store';

export const deadlineFormatter = ({ value }: ValueFormatterParams) => !isNull(value) ? formatDate(value, SHORT_MONTH_FORMAT) : 'No deadline set';
export const arrayLengthFormatter = ({ value }: ValueFormatterParams) => !isNull(value) ? value.length : 0;
export const dateFormatter = ({ value }: ValueFormatterParams) => value ? formatDate(value, SHORT_MONTH_FORMAT) : 'NONE';
export const fileSizeFormatter = ({ value }: ValueFormatterParams) => `${value ? (value / 1e6).toFixed(2) : 0}MB`;
export const nullFormatter = ({ value }: ValueFormatterParams) => isUndefined(value) ? 'Not Linked' : value;
export const versionFormatter = ({ value }: ValueFormatterParams) => isUndefined(value) ? 0 : value;
export const stringFormatter = ({ value }: ValueFormatterParams) => value ? value.slice(0, 1).toUpperCase() + value.slice(1) : value;
export const toCapsFormatter = ({ value }: ValueFormatterParams) => value ? value.toUpperCase() : '';
export const capitaliseFirstLetterFormatter = ({ value }: ValueFormatterParams) => value ? capitaliseStartLetter(value) : '';
export const entityFormatter = ({ value }: ValueFormatterParams) => isNull(value) ? 'NONE' : value;
export const commissionedByFormatter = ({ value, data }: ValueFormatterParams) => {
    if (isNull(value)) {
        return 'Not Linked';
    } else if (value === OpinionCommissionedBy.OTHER) {
        return isNull(data.commissionedByIfOther) ? OpinionCommissionedBy.OTHER : data.commissionedByIfOther;
    } else if (!isNull(data.scope) && data.bespoke === 0 && data.scope === OpinionScope.GMRA_GMSLA_NETTING) {
        return 'ICMA/ISLA';
    }
    return value;
};
export const roleFormatter = ({ value }: ValueFormatterParams) => value ? roleLabels[value as UserRole] : '';
