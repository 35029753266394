import React from 'react';

import { Icon } from '../../../shared/icon/Icon';
import { Briefcase, Form, Group } from '../../../shared/icons';
import { DocumentType } from '../store';
import styles from './UploadDocument.module.scss';

const documentTypes = [
    {
        title: 'Amendment',
        icon: Group,
        subtitle: 'An amendment to a previously uploaded document',
        type: DocumentType.AMENDMENT
    },
    {
        title: 'Restatement',
        icon: Form,
        subtitle: 'A restatement document to restate a trail of changes to an original',
        type: DocumentType.RESTATEMENT
    },
    {
        title: 'Supporting Document',
        icon: Briefcase,
        subtitle: 'Supporting documentation to accompany your original document',
        type: DocumentType.SUPPORT
    }
];

interface DocumentTypeSelectorProps {
    selectType: (type: DocumentType) => void;
}

export const DocumentTypeSelector: React.FC<DocumentTypeSelectorProps> = ({ selectType }) => (
    <div className={styles.typeSelectWrapper} data-testid='document-type-selector-wrapper'>
        {documentTypes.map(({ title, icon, subtitle, type }, index) => (
            <button
                key={index}
                onClick={() => selectType(type)}
                className={styles.typeButton}
                data-testid={`${type}-document-type-select-button`}
            >
                <Icon icon={icon} fontSize={40} />
                <div className={styles.typeButtonTextWrapper}>
                    <div className={styles.typeButtonTitle} data-testid={`${type}-document-type-select-button-title`}>{title}</div>
                    <div className={styles.typeButtonSubtitle} data-testid={`${type}-document-type-select-button-subtitle`}>{subtitle}</div>
                </div>
            </button>
        ))}
    </div>
);

