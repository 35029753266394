import { all, fork, put, takeLeading, call, takeEvery, select, debounce, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { isEqual, isNull, isUndefined, set } from 'lodash/fp';

import { PlaybookActionTypes, PlaybookQuery, DeviationSuggestedChange, SuggestedChanges } from './types';
import { getAllPlaybooks, getPlaybookByDefinitionId, getPlaybookById, sendQueryAndEmail, sendQueryReminder, sendAdditionalQuery, sendSuggestedChanges, sendSuggestedChangesMessage, smartSearchPlaybooks } from '../../../services/playbook';
import { getAllPlaybookFailed, getAllPlaybookSuccessful, getUserPlaybookByDefinitionIdStarted, getUserPlaybookByIdFailed, getUserPlaybookByIdStarted, getUserPlaybookByIdSuccessful, togglePlaybookCoverSelected, getUserPlaybookByDefinitionIdSuccessful, getUserPlaybookByDefinitionIdFailed, sendQuerySuccessful, sendQueryFailed, sendQueryReminderSuccessful, sendQueryReminderFailed, sendAdditionalQuerySuccessful, sendAdditionalQueryFailed, setSuggestedChangesUpdated, sendSuggestedChangesSuccessful, sendSuggestedChangesFailed, closeAllUserDeviations, updateSuggestedChangedContent, updateSuggestedChangesConversationSuccessful, updateSuggestedChangesConversationFailed, playbookSmartSearchSuccessful, playbookSmartSearchFailed, openProvisionLink, redirectUserPlaybook, updateLinkedProvisionHistory, userPlaybookBackCalled, toggleProvisionLinkHistoryModal } from './actions';
import { BasicPlaybook, PlaybookById, PlaybookByDefinitionId, PlaybookQueryDB, PlaybookDB, PlaybookDeviation, SuggestedChangesDB, PlaybookMessage, PlaybookProvision, PlaybookSmartSearch, LinkedPlaybookProvision } from '../../admin/playbook/store';
import { getAdditionalQuery, getLinkedProvisionHistory, getPlaybookQuery, getPlaybookSmartSearchTerm, getPlaybookSuggestedChange, getSuggestedChanges, getSuggestedChangesMessage, getUserPlaybook, getUserPlaybookSuggestedChanges } from './selectors';
import { ABSTRACT_ID } from '../../constants/playbooks';
import { scrollToProvisionLink } from '../../shared/playbook/view/PlaybookSideMenu';

export function* attemptFetchAllPlaybooks() {
    try {
        const allPlaybooks: BasicPlaybook[] = yield call(getAllPlaybooks, { viewTemplates: false, includeDrafts: false });
        yield put(getAllPlaybookSuccessful(allPlaybooks));
    } catch (e) {
        yield put(getAllPlaybookFailed((e as Error).message));
        toast.error('We encountered a problem fetching the playbooks, please try again.');
    }
}

function* getAllPlaybooksWatcher() {
    yield takeLeading(PlaybookActionTypes.GET_ALL_PLAYBOOKS_STARTED, attemptFetchAllPlaybooks);
}

export function* attemptGetUserPlaybookById({ payload }: ReturnType<typeof getUserPlaybookByIdStarted>) {
    try {
        const { playbookId } = payload;
        const latestPlaybook: PlaybookById = yield call(getPlaybookById, { playbookId, includeDrafts: false, includeResolvedSuggestedChanges: false });
        yield put(getUserPlaybookByIdSuccessful(latestPlaybook));
        yield put(togglePlaybookCoverSelected(null));
        yield put(push(`/playbook/${playbookId}`));
    } catch (e) {
        yield put(getUserPlaybookByIdFailed((e as Error).message));
        toast.error('We encountered a problem fetching the playbook, please try again.');
    }

}

function* getUserPlaybookByIdWatcher() {
    yield takeEvery(PlaybookActionTypes.GET_USER_PLAYBOOK_BY_ID_STARTED, attemptGetUserPlaybookById);
}

export function* attemptGetUserPlaybookByDefinitionId({ payload }: ReturnType<typeof getUserPlaybookByDefinitionIdStarted>) {
    try {
        const { playbookDefinitionId } = payload;
        const latestPlaybook: PlaybookByDefinitionId = yield call(getPlaybookByDefinitionId, { playbookDefinitionId, includeDrafts: false, includeResolvedSuggestedChanges: false });
        yield put(getUserPlaybookByDefinitionIdSuccessful(latestPlaybook));
    } catch (e) {
        yield put(getUserPlaybookByDefinitionIdFailed((e as Error).message));
        toast.error('We encountered a problem fetching the playbook, please try again.');
    }

}

function* getUserPlaybookByDefinitionIdWatcher() {
    yield takeEvery(PlaybookActionTypes.GET_USER_PLAYBOOK_BY_DEFINITION_ID_STARTED, attemptGetUserPlaybookByDefinitionId);
}

export function* attemptRedirectUserPlaybook() {
    const navigationPath = '/playbook';
    yield put(closeAllUserDeviations());
    yield put(push(navigationPath));
}

function* redirectUserPlaybookWatcher() {
    yield takeEvery(PlaybookActionTypes.REDIRECT_USER_PLAYBOOK, attemptRedirectUserPlaybook);
}

export function* attemptSendQuery() {
    try {
        const query: PlaybookQuery = yield select(getPlaybookQuery);
        const allQueries: PlaybookQueryDB[] = yield call(sendQueryAndEmail, { query });
        yield put(sendQuerySuccessful(allQueries));
        toast('Query has been raised with the playbook administrators.');
    } catch (e) {
        yield put(sendQueryFailed((e as Error).message));
        toast.error('We encountered a problem sending the query, please try again.');
    }
}

function* attemptSendQueryWatcher() {
    yield takeLeading(PlaybookActionTypes.SEND_QUERY_STARTED, attemptSendQuery);
}

export function* attemptSendQueryReminder() {
    try {
        const query: PlaybookQuery = yield select(getPlaybookQuery);
        const { allQueries }: { allQueries: PlaybookQueryDB[] } = yield call(sendQueryReminder, { query });
        yield put(sendQueryReminderSuccessful(allQueries));
        toast('Reminder successfully sent.');
    } catch (e) {
        yield put(sendQueryReminderFailed((e as Error).message));
        toast.error('We encountered a problem sending the reminder, please try again.');
    }
}

function* attemptSendQueryReminderWatcher() {
    yield takeLeading(PlaybookActionTypes.SEND_QUERY_REMINDER_STARTED, attemptSendQueryReminder);
}

export function* attemptSendAdditionalQuery() {
    try {
        const query: PlaybookQuery = yield select(getPlaybookQuery);
        const latestPlaybook: PlaybookById = yield call(getPlaybookById, { playbookId: query.playbookId, includeDrafts: false, includeResolvedSuggestedChanges: false });
        const { playbookQueries } = latestPlaybook;
        const mostRecentQuery = playbookQueries.find(({ playbookQueryId }) => playbookQueryId === query.playbookQueryId)!;
        const additionalQuery: PlaybookMessage = yield select(getAdditionalQuery);
        const updatedQuery: PlaybookQueryDB = { ...mostRecentQuery, queryConversation: [...mostRecentQuery.queryConversation, additionalQuery] };
        const allQueries: PlaybookQueryDB[] = yield call(sendAdditionalQuery, { updatedQuery });
        yield put(sendAdditionalQuerySuccessful(allQueries));
        toast('Additional query successfully raised.');
    } catch (e) {
        yield put(sendAdditionalQueryFailed((e as Error).message));
        toast.error('We encountered a problem sending the additional query, please try again.');
    }
}

function* attemptSendAdditionalQueryWatcher() {
    yield takeLeading(PlaybookActionTypes.SEND_ADDITIONAL_USER_QUERY_STARTED, attemptSendAdditionalQuery);
}
export function* attemptCheckSuggestedChangesUpdated() {
    try {
        const playbook: PlaybookDB = yield select(getUserPlaybook);
        const currentSuggestedChanges: SuggestedChanges | null = yield select(getSuggestedChanges);
        if (isNull(currentSuggestedChanges)) {
            yield put(setSuggestedChangesUpdated(false));
            return;
        }
        let abstractUpdated = false;
        let sectionsUpdated = false;
        let provisionChanges: Array<keyof PlaybookProvision> = [];
        let deviationChanges: DeviationSuggestedChange[] = [];
        if (currentSuggestedChanges.type === ABSTRACT_ID) {
            const originalContent = playbook!.content.abstract;
            abstractUpdated = !isNull(originalContent) ? !isEqual(currentSuggestedChanges.content, originalContent) : !isNull(currentSuggestedChanges.content);
        } else {
            const originalContent = playbook!.content.sections.find(({ sectionId }) => sectionId === currentSuggestedChanges.content.sectionId) as PlaybookProvision;
            sectionsUpdated = !isEqual(originalContent, currentSuggestedChanges.content);
            provisionChanges = (Object.keys(currentSuggestedChanges.content) as Array<keyof PlaybookProvision>).filter(key => !isEqual(originalContent[key], currentSuggestedChanges.content[key]));
            const deviationsUpdated = provisionChanges.includes('deviations');
            if (deviationsUpdated) {
                const initialDeviationRow = { topic: '', variations: null, background: null, nid: '', rowId: 'unknown', approvalDate: null, validForInterval: null, validForIntervalValue: null };
                const getDefaultDeviationValue = (key: keyof PlaybookDeviation) => initialDeviationRow[key];
                deviationChanges = currentSuggestedChanges.content.deviations.reduce((acc: DeviationSuggestedChange[], deviation) => {
                    const originalDeviation = originalContent.deviations.find(originalDeviation => originalDeviation.rowId === deviation.rowId);
                    const content = (Object.keys(currentSuggestedChanges.content.deviations[0]) as Array<keyof PlaybookDeviation>).filter(key => key !== 'rowId').filter(key => {
                        const originalValue = originalDeviation ? originalDeviation[key] : getDefaultDeviationValue(key);
                        return !isEqual(originalValue, deviation[key]);
                    });
                    if (content.length) {
                        return [...acc, { rowId: deviation.rowId, content }];
                    }
                    return acc;
                }, []);
            }
        }
        yield put(updateSuggestedChangedContent({ provisionChanges, deviationChanges }));
        const changesMade = abstractUpdated || sectionsUpdated;
        yield put(setSuggestedChangesUpdated(changesMade));
    } catch (e) {
        toast.error('We encountered a problem checking if the playbook has updated.');
    }
}

function* attemptCheckSuggestedChangesUpdatedWatcher() {
    yield debounce(1000, [PlaybookActionTypes.UPDATE_PLAYBOOK_SUGGESTED_CHANGES, PlaybookActionTypes.ADD_SUGGESTED_CHANGES_DEVIATION_ROW, PlaybookActionTypes.REMOVE_SUGGESTED_CHANGES_DEVIATION_ROW, PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_DEVIATION_VALUE], attemptCheckSuggestedChangesUpdated);
}

export function* attemptSendSuggestedChanges() {
    try {
        const { playbookId, playbookDefinitionId }: PlaybookDB = yield select(getUserPlaybook);
        const suggestedChanges: SuggestedChanges = yield select(getSuggestedChanges);
        const message: PlaybookMessage | null = yield select(getSuggestedChangesMessage);
        const conversation = message ? [message] : [];
        const playbookSuggestedChanges: SuggestedChangesDB[] = yield call(sendSuggestedChanges, { suggestedChanges, playbookId, playbookDefinitionId, conversation });
        yield put(sendSuggestedChangesSuccessful(playbookSuggestedChanges));
        toast('Your suggestion has been sent to the playbook administrators for consideration.');
    } catch (e) {
        yield put(sendSuggestedChangesFailed((e as Error).message));
        toast.error('We encountered a problem sending the suggested changes, please try again.');
    }
}

function* attemptSendSuggestedChangesWatcher() {
    yield takeLeading(PlaybookActionTypes.SEND_SUGGESTED_CHANGES_STARTED, attemptSendSuggestedChanges);
}

export function* attemptUpdateSuggestedChangesConversation() {
    try {
        const suggestedChanges: SuggestedChangesDB = yield select(getPlaybookSuggestedChange);
        const message: PlaybookMessage = yield select(getSuggestedChangesMessage);
        const allSuggestedChanges: SuggestedChangesDB[] = yield select(getUserPlaybookSuggestedChanges);
        const conversation: PlaybookMessage[] = [...suggestedChanges.conversation, message];
        const updatedChanges = set('conversation', conversation, suggestedChanges);
        const updatedAllSuggestedChanges = allSuggestedChanges.map(suggestion => suggestion.playbookSuggestionId === updatedChanges.playbookSuggestionId ? updatedChanges : suggestion);
        yield call(sendSuggestedChangesMessage, { conversation, playbookSuggestionId: suggestedChanges.playbookSuggestionId, playbookDefinitionId: suggestedChanges.playbookDefinitionId });
        yield put(updateSuggestedChangesConversationSuccessful(updatedChanges, updatedAllSuggestedChanges));
    } catch (e) {
        yield put(updateSuggestedChangesConversationFailed((e as Error).message));
        toast.error('We encountered a problem sending your message, please try again.');
    }
}

function* updateSuggestedChangesConversationWatcher() {
    yield takeLeading(PlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED, attemptUpdateSuggestedChangesConversation);
}

export function* attemptSmartSearchPlaybooks() {
    try {
        const searchTerm: PlaybookSmartSearch[] = yield select(getPlaybookSmartSearchTerm);
        const filteredPlaybooks: BasicPlaybook[] = yield call(smartSearchPlaybooks, { searchTerm: searchTerm, includeDrafts: false });
        yield put(playbookSmartSearchSuccessful(filteredPlaybooks));
    } catch (e) {
        yield put(playbookSmartSearchFailed((e as Error).message));
        toast.error('We encountered a problem searching the playbooks, please try again.');
    }
}

function* attemptSmartSearchPlaybooksWatcher() {
    yield takeLeading(PlaybookActionTypes.PLAYBOOK_SMART_SEARCH_STARTED, attemptSmartSearchPlaybooks);
}

export function* attemptRedirectPlaybookProvisionLink(playbookId: number, sectionId: string) {
    const latestPlaybook: PlaybookById = yield call(getPlaybookById, { playbookId, includeDrafts: false, includeResolvedSuggestedChanges: false });
    yield put(getUserPlaybookByIdSuccessful(latestPlaybook));
    yield put(push(`/playbook/${playbookId}`));
    yield delay(500);
    let hasScrolled: boolean = yield call(scrollToProvisionLink, sectionId);
    while (!hasScrolled) {
        yield delay(250);
        hasScrolled = yield call(scrollToProvisionLink, sectionId);
    }
}

export function* attemptRedirectToPlaybookProvisionLink({ payload }: ReturnType<typeof openProvisionLink>) {
    const { linkTo: { playbookId, sectionId }, linkFrom } = payload;
    const linkedProvisionHistory: LinkedPlaybookProvision[] = yield select(getLinkedProvisionHistory);
    yield call(attemptRedirectPlaybookProvisionLink, playbookId, sectionId);
    const updatedHistory = [...linkedProvisionHistory, linkFrom];
    yield put(updateLinkedProvisionHistory(updatedHistory));
}

function* redirectToPlaybookProvisionLinkWatcher() {
    yield takeLeading([PlaybookActionTypes.OPEN_USER_PROVISION_LINK], attemptRedirectToPlaybookProvisionLink);
}

export function* attemptRedirectFromPlaybookProvisionLink({ payload }: ReturnType<typeof userPlaybookBackCalled>) {
    const linkedProvisionHistory: LinkedPlaybookProvision[] = yield select(getLinkedProvisionHistory);
    if (linkedProvisionHistory.length > 0) {
        if (isUndefined(payload)) {
            const { playbookId, sectionId }: { playbookId: number, sectionId: string } = linkedProvisionHistory[linkedProvisionHistory.length - 1];
            yield call(attemptRedirectPlaybookProvisionLink, playbookId, sectionId);
            yield put(updateLinkedProvisionHistory(linkedProvisionHistory.slice(0, -1)));
        } else {
            if (isNull(payload)) {
                yield put(updateLinkedProvisionHistory([]));
                yield put(redirectUserPlaybook());
            } else {
                const { playbookId, sectionId }: { playbookId: number, sectionId: string } = linkedProvisionHistory[payload];
                yield call(attemptRedirectPlaybookProvisionLink, playbookId, sectionId);
                yield put(updateLinkedProvisionHistory(linkedProvisionHistory.slice(0, payload)));
            }
            yield put(toggleProvisionLinkHistoryModal(false));
        }
    } else {
        yield put(redirectUserPlaybook());
    }
}

function* redirectFromPlaybookProvisionLinkWatcher() {
    yield takeLeading([PlaybookActionTypes.USER_PLAYBOOK_BACK_CALLED], attemptRedirectFromPlaybookProvisionLink);
}

export function* userPlaybookSaga() {
    yield all([
        fork(getAllPlaybooksWatcher),
        fork(getUserPlaybookByIdWatcher),
        fork(getUserPlaybookByDefinitionIdWatcher),
        fork(redirectUserPlaybookWatcher),
        fork(attemptSendQueryWatcher),
        fork(attemptSendQueryReminderWatcher),
        fork(attemptSendAdditionalQueryWatcher),
        fork(attemptCheckSuggestedChangesUpdatedWatcher),
        fork(attemptSendSuggestedChangesWatcher),
        fork(updateSuggestedChangesConversationWatcher),
        fork(attemptSmartSearchPlaybooksWatcher),
        fork(redirectToPlaybookProvisionLinkWatcher),
        fork(redirectFromPlaybookProvisionLinkWatcher)
    ]);
}
