import { AttestationInstance, AttestationInstanceDB, AttestationStatus } from '../../admin/attestations/store';
import { TableFilters } from '../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../shared/table/ArkTable';

export enum UserAttestationActionTypes {
    FETCH_ALL_USER_ATTESTATION_INSTANCES_STARTED = 'FETCH_ALL_USER_ATTESTATION_INSTANCES_STARTED',
    FETCH_ALL_USER_ATTESTATION_INSTANCES_SUCCESSFUL = 'FETCH_ALL_USER_ATTESTATION_INSTANCES_SUCCESSFUL',
    FETCH_ALL_USER_ATTESTATION_INSTANCES_FAILED = 'FETCH_ALL_USER_ATTESTATION_INSTANCES_FAILED',
    TOGGLE_USER_ATTESTATION_INSTANCE_VIEW = 'TOGGLE_USER_ATTESTATION_INSTANCE_VIEW',
    TOGGLE_USER_ATTESTATION_INSTANCE_MODAL_OPEN = 'TOGGLE_USER_ATTESTATION_INSTANCE_MODAL_OPEN',
    UPDATE_ATTESTATION_ANSWER_VALUE = 'UPDATE_ATTESTATION_ANSWER_VALUE',
    UPDATE_ATTESTATION_USER_NOTES = 'UPDATE_ATTESTATION_USER_NOTES',
    SET_USER_ATTESTATION_INSTANCE_UPDATED = 'SET_USER_ATTESTATION_INSTANCE_UPDATED',
    SAVE_USER_ATTESTATION_INSTANCE_STARTED = 'SAVE_USER_ATTESTATION_INSTANCE_STARTED',
    SAVE_USER_ATTESTATION_INSTANCE_SUCCESSFUL = 'SAVE_USER_ATTESTATION_INSTANCE_SUCCESSFUL',
    SAVE_USER_ATTESTATION_INSTANCE_FAILED = 'SAVE_USER_ATTESTATION_INSTANCE_FAILED',
    TOGGLE_ATTESTATION_ALERT_MODAL_OPEN = 'TOGGLE_ATTESTATION_ALERT_MODAL_OPEN',
    CLEAR_ATTESTATION_TABLE_FILTERS = 'CLEAR_USER_ATTESTATION_TABLE_FILTERS',
    SET_ATTESTATION_TABLE_FILTERS = 'SET_USER_ATTESTATION_TABLE_FILTERS',
    SET_ATTESTATION_TABLE_COLUMN_SORT = 'SET_USER_ATTESTATION_TABLE_COLUMN_SORT',
    SET_ATTESTATIONS_PAGE_SIZE = 'SET_USER_ATTESTATIONS_PAGE_SIZE',
    ATTESTATIONS_PAGINATION_NEXT = 'USER_ATTESTATIONS_PAGINATION_NEXT',
    ATTESTATIONS_PAGINATION_PREVIOUS = 'USER_ATTESTATIONS_PAGINATION_PREVIOUS'
}

export interface UserAttestationInstanceDB extends AttestationInstanceDB {
    userId: number;
    userAnswerId: string;
    status: AttestationStatus;
    attestedDate: string | null;
}

export interface UserAttestationInstance extends AttestationInstance {
    attestationInstanceId: number;
    attestationName: string;
    userAnswerId: string;
    status: AttestationStatus;
    attestedDate: string | null;
}

export interface UserAttestationState {
    isLoading: boolean;
    completed: boolean;
    attestationInstances: UserAttestationInstanceDB[];
    selectedUserAttestationInstance: UserAttestationInstance | null;
    userAttestationInstanceModalOpen: boolean;
    attestationUpdated: boolean;
    isSaving: boolean;
    saveError: string | null;
    alertModalOpen: string | null;
    attestationPage: number;
    totalAttestations: number;
    attestationFilters: TableFilters;
    attestationSort?: ColumnSort;
    attestationPageSize: number;
}
