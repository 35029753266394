import React, { useCallback } from 'react';

import { Scrollable } from '../../../../shared/scrollable/Scrollable';
import { InformationTooltip, OverflowTooltip } from '../../../../shared/tooltip';
import { AttestationManager } from '../../store';
import styles from '../AttestationManager.module.scss';

interface AttestationsProps {
    attestation: AttestationManager;
    testId?: string;
}

export const Attestations: React.FC<AttestationsProps> = ({ attestation, testId }) => {
    const { attestationInstance, attestationStatus } = attestation;
    const { attestations } = attestationInstance;
    const headers = [
        { label: 'Question', width: 40 },
        { label: 'User', width: 20 },
        { label: 'Answer', width: 30 },
        { label: 'User Notes', width: 10 }
    ];

    const getUsername = useCallback((userAnswerId: string) => attestationStatus.find(status => status.userAnswerId === userAnswerId)?.username || '', [attestationStatus]);

    return (
        <div className={styles.attestationsWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.attestationsHeader} data-testid={`${testId}-header`}>
                {headers.map(({ label, width }) => (
                    <div key={label} style={{ width: `${width}%` }} data-testid={`${testId}-header-${label.replace(/\s+/g, '-').toLowerCase()}`}>{label}</div>
                ))}
            </div>
            <div className={styles.attestationsBody}>
                <Scrollable>
                    {attestations.map(({ id, question, answer, userNotes, config: { userAnswerId } }, index) => (
                        <div className={styles.attestationRow} key={id} data-testid={`${testId}-row-${index}`}>
                            <OverflowTooltip className={styles.attestationQuestion} key={id} overlayText={question} testId={`${testId}-row-${index}-question`} />
                            <div className={styles.attestationUser} data-testid={`${testId}-row-${index}-username`}>{getUsername(userAnswerId)}</div>
                            <div className={styles.attestationAnswer} data-testid={`${testId}-row-${index}-answer`}>{answer || 'Awaiting Response'}</div>
                            <div className={styles.attestationUserNotes} data-testid={`${testId}-row-${index}-user-notes`}>
                                <InformationTooltip content={userNotes || 'No notes added by user'} />
                            </div>
                        </div>
                    ))}
                </Scrollable>
            </div>
        </div>
    );
};
