import React, { useCallback } from 'react';

import { OpinionFilter } from '../../../shared/filter/OpinionFilter';
import styles from '../Reports.module.scss';
import { getIncludeSignOffNotes, OpinionReportFilter as OpinionReportFilterType, toggleIncludeSignOffNotes } from '../store';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Toggle } from '../../../shared/toggle';

interface OpinionReportFilterProps {
    reportFilters: OpinionReportFilterType;
    updateFilter: (key: keyof OpinionReportFilterType, value: string[] | null | boolean) => void;
}

export const OpinionReportFilter: React.FC<OpinionReportFilterProps> = ({ reportFilters, updateFilter }) => {
    const dispatch = useAppDispatch();
    const includeSignOffNotes = useAppSelector(getIncludeSignOffNotes);
    const { focus, jurisdiction } = reportFilters;

    const toggleIncludeNotes = useCallback((value: boolean) => dispatch(toggleIncludeSignOffNotes(value)), [dispatch]);
    return (
        <>
            <div className={styles.filterHeader}>Filters</div>
            <div className={styles.filtersDescription}>Select any filters you would like to apply to this report, if left blank then all opinions will be included</div>
            <div className={styles.filterWrapper}>
                <OpinionFilter
                    jurisdiction={jurisdiction}
                    focus={focus}
                    updateFilter={updateFilter}
                />
            </div>
            <div className={styles.signOffWrapper}>
                <div className={styles.signOffLabel}>Would you like to include your 'Sign Off' notes in this report?</div>
                <Toggle onChange={toggleIncludeNotes} checked={includeSignOffNotes} />
            </div>
        </>
    );
};
