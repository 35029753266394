import { Reducer } from 'redux';
import { flow, indexOf, isUndefined, set } from 'lodash/fp';

import { NotificationState, NotificationActionTypes } from './types';
import { LoginActionTypes } from '../../login/store';

export const INITIAL_STATE: NotificationState = {
    notifications: [],
    modalOpen: false,
    systemNotification: null,
    activeMLDocuments: [],
    mlProgressModalOpen: false,
    portfolioStatistics: [],
    showPortfolioStatistics: true
};

export const notificationReducer: Reducer<NotificationState> = (state = INITIAL_STATE, { type, payload }): NotificationState => {
    switch (type) {
        case NotificationActionTypes.NOTIFICATION_RECEIVED:
            return set('notifications', payload, state);
        case NotificationActionTypes.TOGGLE_NOTIFICATION_MODAL_OPEN:
            return set('modalOpen', payload, state);
        case NotificationActionTypes.TOGGLE_SYSTEM_NOTIFICATION_MODAL:
            return set('systemNotification', payload, state);
        case NotificationActionTypes.TOGGLE_ML_PROGRESS_MODAL_OPEN:
            return set('mlProgressModalOpen', payload, state);
        case NotificationActionTypes.SET_ACTIVE_ML_DOCUMENTS:
            return set('activeMLDocuments', payload, state);
        case NotificationActionTypes.REMOVE_ACTIVE_ML_DOCUMENT: {
            const activeMLDocuments = state.activeMLDocuments.filter(({ documentId }) => documentId !== payload);
            if (activeMLDocuments.length === 0) {
                return flow(
                    set('mlProgressModalOpen', false),
                    set('activeMLDocuments', activeMLDocuments)
                )(state);
            }
            return set('activeMLDocuments', activeMLDocuments, state);
        }
        case NotificationActionTypes.UPDATE_PERCENTAGE_ACTIVE_ML_DOCUMENT: {
            const includedInActiveDocuments = !isUndefined(state.activeMLDocuments.find(({ documentId }) => documentId === payload.documentId));
            if (includedInActiveDocuments) {
                const documentIds = state.activeMLDocuments.map(({ documentId }) => documentId);
                const index = indexOf(payload.documentId, documentIds);
                return set(`activeMLDocuments[${index}]`, payload, state);
            } else {
                const activeMLDocuments = [...state.activeMLDocuments, payload];
                return set('activeMLDocuments', activeMLDocuments, state);
            }
        }
        case NotificationActionTypes.SET_PORTFOLIO_STATISTICS:
            return flow(
                set('portfolioStatistics', payload),
                set('showPortfolioStatistics', true)
            )(state);
        case NotificationActionTypes.TOGGLE_SHOW_PORTFOLIO_STATISTICS:
            return set('showPortfolioStatistics', payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
