import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/react-redux';
import { useWindowResize } from '../../hooks/useWindowResize';
import { DragDrop } from '../shared/drag-n-drop/DragDrop';
import { DraggableItem } from '../shared/drag-n-drop/shared';
import { Text } from '../shared/text/Text';
import styles from './AgencyAnnex.module.scss';
import { DraggableField } from './DraggableField';
import { FieldList } from './FieldList';
import { AnnexDefinition, getAvailableFields, updateAnnexDatasetFields, updateAnnexDefinitionTitle } from './store';

interface AgencyAnnexBuilderProps {
    annexDefinition: AnnexDefinition;
    droppableHeight?: number;
    heightOffset?: number;
}

export const AgencyAnnexBuilder: React.FC<AgencyAnnexBuilderProps> = ({ annexDefinition, droppableHeight, heightOffset = 61 }) => {
    const [, screenHeight] = useWindowResize();
    const dispatch = useAppDispatch();
    const availableFields = useAppSelector(getAvailableFields);
    const { datasetFields } = annexDefinition;

    const handleUpdateTitle = (title: string) => dispatch(updateAnnexDefinitionTitle(title));

    const listOptions = useMemo(() => datasetFields.map(({ id, label, type }) => ({ id: id!, label: label ? `${label} (${type})` : type })), [datasetFields]);

    const updateList = (list: DraggableItem[]) => {
        const newFields = list.map(({ id }) => datasetFields.find(field => field.id === id)!);
        dispatch(updateAnnexDatasetFields(newFields));
    };

    const getChildElement = useCallback((childId: string, index: number) => {
        const field = datasetFields.find(({ id }) => id === childId);
        if (field) {
            return (
                <DraggableField field={field} key={field.id} index={index} />
            );
        }
        return null;
    }, [datasetFields]);

    // This is the height of the modal minus other fixed height elements such as header and buttons wrapper
    const dragDropHeight = useMemo(() => droppableHeight || screenHeight - 360, [droppableHeight, screenHeight]);
    return (
        <div className={styles.annexBuilderContent} style={{ height: `calc(100% - ${heightOffset}px)` }}>
            <div className={styles.annexBuilderTable}>
                <Text testId='agency-annex-title-builder-wrapper' onChange={e => handleUpdateTitle(e.target.value)} placeholder='Title' value={annexDefinition.annexDefinitionTitle || ''} />
                <div className={styles.annexBuilder} data-testid='agency-annex-builder-wrapper'>
                    <div className={styles.annexBuilderDroppable} style={{ height: `${dragDropHeight}px` }} data-testid='agency-annex-builder-with-fields'>
                        <DragDrop getChildElement={getChildElement} list={listOptions} listId='annex-builder' updateList={updateList} />
                    </div>
                </div>
            </div>
            <FieldList availableFields={availableFields} datasetFields={datasetFields} />
        </div>
    );
};
