import React, { MouseEvent } from 'react';

import styles from './Button.module.scss';
import { Icon as ArkIcon } from '../icon/Icon';
import { Duplicate } from '../icons';

interface DuplicateButtonProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    fontSize?: number;
    testId?: string;
}

export const DuplicateButton: React.FC<DuplicateButtonProps> = ({ onClick, disabled = false, fontSize, testId = '' }) => (
    <button
        onClick={onClick}
        className={styles.duplicateButton}
        disabled={disabled}
        data-testid={`duplicate-button-${testId}`}
    >
        <ArkIcon icon={Duplicate} fontSize={fontSize} />
    </button>
);
