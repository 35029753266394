import { toast } from 'react-toastify';
import { put, takeEvery, all, fork, select, call, debounce, takeLeading } from 'redux-saga/effects';

import { checkUsername, upsertUser, fetchAllUsers, fetchAvailableFeaturePermissions, deleteUser, resetPassword, fetchAllBasicUsers } from '../../../../services/users';
import { updateClientUsers } from '../../clients/store';
import { checkUsernameAvailableFailed, checkUsernameAvailableSuccessful, upsertUserFailed, upsertUserSuccessful, fetchAllUsersFailed, fetchAllUsersSuccessful, fetchAvailableFeaturePermissionsFailed, fetchAvailableFeaturePermissionsSuccessful, upsertUserStarted, deleteUserStarted, deleteUserSuccessful, deleteUserFailed, resetUserPassword, fetchAllBasicUsersSuccessful, fetchAllBasicUsersFailed } from './actions';
import { getNewUser } from './selectors';
import { BasicUserInfo, FeaturePermissionDB, NewUser, ClientUser, UsersActionTypes } from './types';

export function* attemptUpsertUser({ payload }: ReturnType<typeof upsertUserStarted>) {
    try {
        const newUser: NewUser = yield select(getNewUser);
        const { userId, clientId } = newUser;
        const allUsers: ClientUser[] = yield call(upsertUser, { newUser });
        if (payload && clientId) {
            yield put(updateClientUsers(clientId, allUsers));
        } else {
            yield put(upsertUserSuccessful(allUsers));
        }
        const successToastMessage = userId ? 'Successfully updated user details' : 'Successfully added a new user';
        toast(successToastMessage);
    } catch (e) {
        yield put(upsertUserFailed((e as Error).message));
        toast.error('Unable to save the user. Please try again.');
    }
}

export function* attemptCheckUsername() {
    try {
        const { username, userId } = yield select(getNewUser);
        const usernameUnavailable: boolean = yield call(checkUsername, { username, userId });
        yield put(checkUsernameAvailableSuccessful(usernameUnavailable));
    } catch (e) {
        yield put(checkUsernameAvailableFailed((e as Error).message));
    }
}

export function* attemptFetchAllUsers() {
    try {
        const users: ClientUser[] = yield call(fetchAllUsers);
        yield put(fetchAllUsersSuccessful(users));
    } catch (e) {
        toast.error('Unable to fetch the latest list of users. Please try again.');
        yield put(fetchAllUsersFailed((e as Error).message));
    }
}

export function* attemptFetchAllBasicUsers() {
    try {
        const users: BasicUserInfo[] = yield call(fetchAllBasicUsers);
        yield put(fetchAllBasicUsersSuccessful(users));
    } catch (e) {
        toast.error('Unable to fetch the latest list of users. Please try again.');
        yield put(fetchAllBasicUsersFailed((e as Error).message));
    }
}

export function* attemptFetchAvailableFeaturePermissions() {
    try {
        const permissions: FeaturePermissionDB[] = yield call(fetchAvailableFeaturePermissions);
        yield put(fetchAvailableFeaturePermissionsSuccessful(permissions));
    } catch (e) {
        toast.error('Unable to fetch feature permissions.');
        yield put(fetchAvailableFeaturePermissionsFailed((e as Error).message));
    }
}

export function* attemptDeleteUser({ payload }: ReturnType<typeof deleteUserStarted>) {
    try {
        const { userId, clientId, username } = payload;
        const allUsers: ClientUser[] = yield call(deleteUser, { userId, clientId });
        if (clientId) {
            yield put(updateClientUsers(clientId, allUsers));
        } else {
            yield put(deleteUserSuccessful(allUsers));
        }
        toast(`${username} successfully deleted.`);
    } catch (e) {
        toast.error('Unable to delete user. Please try again.');
        yield put(deleteUserFailed((e as Error).message));
    }
}

export function* attemptResetUserPassword({ payload }: ReturnType<typeof resetUserPassword>) {
    try {
        const { username } = payload;
        yield call(resetPassword, { username });
        toast(`Password reset link sent to ${username}.`);
    } catch (e) {
        toast.error('Unable to send password reset link. Please try again.');
    }
}

function* resetUserPasswordWatcher() {
    yield takeLeading(UsersActionTypes.RESET_USER_PASSWORD, attemptResetUserPassword);
}

function* deleteUserWatcher() {
    yield takeEvery(UsersActionTypes.DELETE_USER_STARTED, attemptDeleteUser);
}

function* upsertUserWatcher() {
    yield takeEvery(UsersActionTypes.UPSERT_USER_STARTED, attemptUpsertUser);
}

function* checkUsernameWatcher() {
    yield debounce(1000, UsersActionTypes.CHECK_USERNAME_AVAILABLE_STARTED, attemptCheckUsername);
}

function* fetchAllUsersWatcher() {
    yield takeEvery(UsersActionTypes.FETCH_ALL_USERS_STARTED, attemptFetchAllUsers);
}

function* fetchAllBasicUsersWatcher() {
    yield takeEvery(UsersActionTypes.FETCH_ALL_BASIC_USERS_STARTED, attemptFetchAllBasicUsers);
}

function* fetchAvailableFeaturePermissionsWatcher() {
    yield takeEvery(UsersActionTypes.FETCH_AVAILABLE_FEATURE_PERMISSIONS_STARTED, attemptFetchAvailableFeaturePermissions);
}

export function* userSaga() {
    yield all([
        fork(upsertUserWatcher),
        fork(checkUsernameWatcher),
        fork(fetchAllUsersWatcher),
        fork(fetchAllBasicUsersWatcher),
        fork(fetchAvailableFeaturePermissionsWatcher),
        fork(deleteUserWatcher),
        fork(resetUserPasswordWatcher)
    ]);
}
