import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { isNull } from 'lodash/fp';

import { useAppSelector } from '../../../../hooks/react-redux';
import { IconButton } from '../../../shared/button/IconButton';
import { Download, Search } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { DocumentPreviewModal } from './DocumentPreviewModal';
import { DetailsDocument, getPreviewModalOpen } from '../store';
import styles from '../SupplyChain.module.scss';
import { DeleteButton } from '../../../shared/button/DeleteButton';

interface DocumentsProps {
    documents: DetailsDocument[];
    setUploadDocuments: (files: File[]) => void;
    files: File[];
    disabled: boolean;
    isEditing: boolean;
    downloadDocument: (id: number, description: string) => void;
    previewDocument: (id: number, description: string) => void;
    removeUploadFile: (index: number) => void;
    closePreview: () => void;
}

export const Documents: React.FC<DocumentsProps> = ({ documents, setUploadDocuments, files, disabled, isEditing, previewDocument, downloadDocument, closePreview, removeUploadFile }) => {
    const previewModalOpen = useAppSelector(getPreviewModalOpen);

    const onDrop = useCallback((acceptedFiles: File[]) => setUploadDocuments(acceptedFiles), [setUploadDocuments]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({ disabled, multiple: true, maxFiles: 0, accept: ['.pdf', '.doc', '.docx', '.tif'], onDrop });

    const documentPlural = files && files.length > 1 ? 's' : '';

    const dropzoneText = useMemo(() => {
        if (isDragActive) {
            return 'Drop documents here';
        }
        if (files && files.length) {
            return `Document${documentPlural} successfully added, click here to change the document${documentPlural} to be uploaded`;
        }
        return 'Drag \'n\' drop documents here';
    }, [isDragActive, files, documentPlural]);

    const tableData = useMemo(() => {
        const documentData = documents.map(({ doraDocumentId, description }) => ({ name: description, id: doraDocumentId, isPending: false }));
        const newFilesData = files.map(({ name }) => ({ name: `${name} (PENDING)`, id: null, isPending: true }));
        return [...newFilesData, ...documentData];
    }, [documents, files]);

    return (
        <div className={styles.documentsWrapper}>
            <div className={styles.documentsHeader}>Documents</div>
            {isEditing &&
                <div className={styles.documentUploadWrapper}>
                    <div {...getRootProps({ className: classnames(styles.dropzone, { [styles.dropzoneDisabled]: disabled }) })}>
                        <input {...getInputProps()} />
                        <div className={styles.dropzonePlaceholder}>{dropzoneText}</div>
                    </div>
                </div>
            }
            <div className={styles.tableWrapper}>
                <div className={styles.tableHeaderWrapper}>
                    <div className={styles.tableHeader} style={{ width: 'calc(70% - 10px)' }}>File Name</div>
                    <div className={styles.tableHeader} style={{ width: 'calc(12.5% - 10px)', justifyContent: 'center' }}>Preview</div>
                    <div className={styles.tableHeader} style={{ width: 'calc(12.5% - 10px)', justifyContent: 'center' }}>Download</div>
                    <div className={styles.tableHeader} style={{ width: 'calc(5% - 10px)' }} />
                </div>
                <div className={styles.tableBodyWrapper}>
                    <Scrollable maxHeight='240px'>
                        {tableData.map(({ id, name, isPending }, index) => (
                            <div
                                className={classnames(styles.tableRowWrapper, {
                                    [styles.oddDocument]: index % 2,
                                    [styles.pendingDocument]: isPending,
                                    [styles.pendingOdd]: index % 2 && isPending,
                                })}
                                key={index}
                            >
                                <div className={styles.tableCell} style={{ width: 'calc(70% - 10px)' }}>{name}</div>
                                <div className={styles.tableCell} style={{ width: 'calc(12.5% - 10px)', justifyContent: 'center' }}>
                                    {!isPending && <IconButton icon={Search} onClick={() => !isNull(id) && previewDocument(id, name)} fontSize={20} />}

                                </div>
                                <div className={styles.tableCell} style={{ width: 'calc(12.5% - 10px)', justifyContent: 'center' }}>
                                    {!isPending && <IconButton icon={Download} onClick={() => !isNull(id) && downloadDocument(id, name)} fontSize={20} />}

                                </div>
                                <div className={styles.tableCell} style={{ width: 'calc(5% - 10px)', justifyContent: 'flex-end' }}>
                                    {isPending && <DeleteButton onClick={() => removeUploadFile(index)} fontSize={20} />}

                                </div>
                            </div>
                        ))}
                    </Scrollable>
                </div>
            </div>
            <DocumentPreviewModal isOpen={previewModalOpen} closeModal={closePreview} download={downloadDocument} />
        </div>
    );
};
