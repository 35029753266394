import React, { useCallback, useMemo } from 'react';

import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import styles from './OpinionSection.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getOpenFieldsAndSections } from '../store/selectors';
import { toggleOpenFieldSection } from '../store/actions';

interface SubSectionWrapperProps {
    id: string;
    label: string;
    children: JSX.Element;
}

export const SubSectionWrapper: React.FC<SubSectionWrapperProps> = ({ label, id, children }) => {
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const isOpen = useMemo(() => openFieldsAndSections.includes(id), [openFieldsAndSections, id]);
    const sectionOpenIcon = isOpen ? CaretDown : CaretSide;
    const toggleSection = useCallback(() => dispatch(toggleOpenFieldSection(id)), [dispatch, id]);

    return (
        <div key={id} className={styles.subSectionWrapper} data-testid={`opinion-sub-section-${id}-wrapper`}>
            <div className={styles.subSectionHeader} onClick={toggleSection} data-testid={`opinion-sub-section-${id}-collapse`}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={sectionOpenIcon} fontSize={14} />
                </div>
                <div className={styles.subSectionLabel} data-testid={`opinion-sub-section-${id}-label`}>{label}</div>
            </div>
            {isOpen &&
                <div className={styles.allFieldsWrapper}>
                    {children}
                </div>
            }
        </div>
    );
};
