import React, { useMemo } from 'react';
import { isNull } from 'lodash/fp';
import { Options } from 'react-select';
import { RawDraftContentState } from 'draft-js';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';
import { getAllUsers } from '../../users/store';
import { SendEmailScheduler, setScheduledEmailContent } from '../store';
import { Text } from '../../../shared/text/Text';
import { WYSIWYG } from '../../../shared/wysiwyg/WYSIWYG';
import styles from '../Workflow.module.scss';

interface ScheduleEmailContentProps {
    event: SendEmailScheduler;
    testId: string;
    isEditing: boolean;
}

export const ScheduleEmailContent: React.FC<ScheduleEmailContentProps> = ({ event, testId, isEditing }) => {
    const dispatch = useAppDispatch();

    const { content } = event;
    const { userIds, subject, wysiwygTemplate } = content;

    const allUsers = useAppSelector(getAllUsers);
    const usernameOptions = useMemo(() => allUsers.map(({ userId, username }) => ({ value: userId.toString(), label: username })), [allUsers]);
    const selectedUsers = useMemo(() => {
        if (userIds) {
            return allUsers.filter(({ userId }) => userIds.includes(userId)).map(({ userId, username }) => ({ value: userId.toString(), label: username }));
        }
        return null;
    }, [userIds, allUsers]);

    const setEmailUsers = (value: DropdownOption | Options<DropdownOption> | null) => {
        let userIds = null;
        if (!isNull(value)) {
            userIds = (value as Options<DropdownOption>).map(({ value }) => parseInt(value));
        }
        dispatch(setScheduledEmailContent('userIds', userIds));
    };
    const setEmailSubject = (value: string) => dispatch(setScheduledEmailContent('subject', value));

    const setEmailBody = (rawValue: RawDraftContentState) => dispatch(setScheduledEmailContent('wysiwygTemplate', rawValue));

    return (
        <div className={styles.emailContentTabWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.emailUsersWrapper} data-testid={`${testId}-users-wrapper`}>
                <div className={styles.emailContentLabel} data-testid={`${testId}-users-label`}>To: </div>
                <div className={styles.emailUsersDropdown}>
                    <Dropdown
                        value={selectedUsers}
                        options={usernameOptions}
                        onChange={val => setEmailUsers(val)}
                        menuPortalTarget={document.body}
                        testId={`${testId}-send-email-users`}
                        isMulti={true}
                        isClearable={true}
                        maxControlHeight='fit-content'
                        disabled={!isEditing}
                    />
                </div>
            </div>
            <div className={styles.emailSubjectWrapper} data-testid={`${testId}-subject-wrapper`}>
                <div className={styles.emailContentLabel} data-testid={`${testId}-subject-label`}>Subject: </div>
                <div className={styles.emailSubjectInput}>
                    <Text
                        marginBottom='0'
                        testId={`${testId}-subject`}
                        maxLength={128}
                        value={subject}
                        onChange={e => setEmailSubject(e.target.value)}
                        placeholder='Subject...'
                        disabled={!isEditing}
                    />
                </div>
            </div>
            <div className={styles.emailBodyWrapper} data-testid={`${testId}-body-wrapper`}>
                <WYSIWYG
                    content={wysiwygTemplate}
                    updateContent={val => setEmailBody(val)}
                    height='75%'
                    maxHeight='75%'
                    minHeight='75%'
                    disabled={!isEditing}
                    toolbarHidden={!isEditing}
                />
            </div>
        </div>
    );
};

