import { isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';

import { RiskAssociated, CurrencyRiskConfig } from '../../admin/risk-tolerance/store';
import { DropdownOption } from '../dropdown/Dropdown';
import { Currencies } from '../icons';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { ModalHeader } from '../modal/ModalHeader';
import { formatNumericalValue } from '../number/Number';
import { Scrollable } from '../scrollable/Scrollable';
import { OverflowTooltip } from '../tooltip';
import styles from './RiskTolerance.module.scss';
import { riskAssociatedColors } from './RiskToleranceConfig';

interface ConfiguredCurrenciesModalProps {
    isOpen: boolean;
    closeModal: () => void;
    riskConfig: CurrencyRiskConfig;
    setSelectedCurrency: (currency: DropdownOption) => void;
}

export const ConfiguredCurrenciesModal: React.FC<ConfiguredCurrenciesModalProps> = ({ isOpen, closeModal, riskConfig, setSelectedCurrency }) => {
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const currencies = Object.keys(riskConfig);
    const riskAssociatedValues = Object.values(RiskAssociated).filter(risk => typeof risk !== 'string') as RiskAssociated[];
    const filteredCurrencies = useMemo(() => {
        if (isUndefined(filter)) {
            return currencies;
        }
        return currencies.reduce((acc: string[], cur) => {
            const splitCurrency = cur.toLowerCase().split('');
            const filterLetters = filter.toLowerCase().split('');
            if (filterLetters.every(letter => splitCurrency.includes(letter))) {
                acc.push(cur);
            }
            return acc;
        }, []);
    }, [currencies, filter]);

    const updateFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    }, []);

    const selectCurrencyConfig = useCallback((value: string) => {
        setSelectedCurrency({ label: value, value });
        closeModal();
        setFilter(undefined);
    }, [setSelectedCurrency, closeModal]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            closeLabel='Close'
            showConfirm={false}
        >
            <div className={styles.configuredCurrenciesModal}>
                <ModalHeader icon={Currencies} label='Configured Currencies' />
                <input placeholder='Search Currencies...' className={styles.currencyFilter} onChange={updateFilter} onClick={e => e.stopPropagation()} value={filter} autoFocus />
                <div className={styles.currencies}>
                    <Scrollable>
                        <div className={styles.columnsWrapper}>
                            {filteredCurrencies.map(currency => {
                                const numberConfig = riskConfig[currency];
                                return (
                                    <div className={styles.currencyWrapper} key={currency} onClick={() => selectCurrencyConfig(currency)}>
                                        <div className={styles.currencyLabel}>{currency}</div>
                                        {riskAssociatedValues.map(risk => {
                                            const value = numberConfig[risk].value ? formatNumericalValue(numberConfig[risk].value) : null;
                                            return (
                                                <div className={styles.riskAssociatedOption} key={risk}>
                                                    <div className={styles.riskLevel} style={{ backgroundColor: riskAssociatedColors[risk] }} />
                                                    <div className={styles.riskComparator}>{numberConfig[risk].comparator}</div>
                                                    <OverflowTooltip overlayText={value} />
                                                </div>
                                            );
                                        }
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </Scrollable>
                </div>
                <div>Total: {filteredCurrencies.length}</div>
            </div>
        </ ConfirmationModal>
    );
};
