import classnames from 'classnames';
import { flow, isEqual, unset } from 'lodash/fp';
import React, { useCallback } from 'react';
import Modal from 'react-modal';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Form } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { CustomTooltip, InformationTooltip, TooltipList } from '../../shared/tooltip';
import { getField } from './SingleField';
import { AgencyLinkedInstanceField, AnnexInstanceField, closeAnnexFieldWizard, getWizardFieldOpen, openAnnexFieldWizard, OpenFieldSection, SingleInstanceField } from '../instances/store';
import styles from './Fields.module.scss';
import { Scrollable } from '../../shared/scrollable/Scrollable';

interface WizardProps {
    id: string;
    value: string[];
    index: number;
    disabled?: boolean;
    rowId: string;
    showFieldUpdated?: boolean;
    showDatasetUpdated?: boolean;
    showAIModified?: boolean;
    field: AnnexInstanceField;
    isPreview?: boolean;
    datasetId: number;
    parentFieldId: string;
    toggleSection: (fieldSection: OpenFieldSection) => void;
    getSectionOpen: (fieldSection: OpenFieldSection) => boolean;
}

export const Wizard: React.FC<WizardProps> = ({
    id,
    disabled = false,
    index,
    rowId,
    showFieldUpdated = false,
    showDatasetUpdated = false,
    field,
    value,
    isPreview = false,
    datasetId,
    parentFieldId,
    toggleSection,
    getSectionOpen
}) => {
    const dispatch = useAppDispatch();
    const [screenWidth, screenHeight] = useWindowResize();
    const wizardFieldOpen = useAppSelector(getWizardFieldOpen);
    const openWizard = () => dispatch(openAnnexFieldWizard(index, rowId));
    const closeWizard = () => dispatch(closeAnnexFieldWizard());
    const isOpen = isEqual({ rowId, index }, wizardFieldOpen);

    const stripField = useCallback((linkedField: AgencyLinkedInstanceField): SingleInstanceField => flow(
        unset('datasetTitle'),
        unset('datasetId')
    )(linkedField), []);

    return (
        <div className={styles.wizardField} data-testid={`annex-definition-wizard-${id}`}>
            <button
                onClick={openWizard}
                className={classnames(styles.wizardButton, {
                    [styles.updatedWizardButton]: showFieldUpdated,
                    [styles.newWizardButton]: showDatasetUpdated,
                })}
                disabled={isPreview}
            >
                Open Wizard
            </button>
            <TooltipList overlayText={value}>
                <div className={styles.informationTooltipIcon}>i</div>
            </TooltipList>
            <Modal
                isOpen={isOpen}
                className={styles.wizardModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick
                shouldCloseOnEsc
                onRequestClose={closeWizard}
                style={{
                    overlay: {
                        display: 'flex',
                        backgroundColor: 'transparent'
                    },
                    content: {
                        position: 'absolute',
                        top: `${screenHeight / 4}px`,
                        left: `${(screenWidth / 2) + 30}px`,
                        overflow: 'visible',
                        outline: 'none',
                    }
                }}
            >
                <div className={styles.wizardWrapper}>
                    <ModalHeader label={field.aliasLabel || field.label} icon={Form} />
                    <div className={styles.fieldsWrapper}>
                        <Scrollable>
                            {field.linkedFields.map((field, wizardFieldIndex) => {
                                const { label, description, refLabel, settings, value } = field;
                                const strippedField = stripField(field);
                                return (
                                    <div
                                        className={styles.singleFieldWrapper}
                                        data-testid={`wizard-field-${wizardFieldIndex}-${id}-wrapper`}
                                        key={field.id!}
                                    >
                                        <div className={styles.fieldTitleWrapper}>
                                            <CustomTooltip overlayText={label} trigger='click'>
                                                <div className={classnames(styles.fieldLabel, { [styles.emptyFieldLabel]: !label })}
                                                    data-testid={`form-field-${wizardFieldIndex}-${id}-label`}>
                                                    {label || 'Label...'}
                                                </div>
                                            </CustomTooltip>
                                            {description && <InformationTooltip content={description} />}
                                            {refLabel && settings.showRef && <InformationTooltip content={refLabel} label='?' />}
                                        </div>
                                        {getField(strippedField, value, false, false, false, false, false, false, false, wizardFieldIndex, disabled, false, datasetId, parentFieldId, toggleSection, getSectionOpen, undefined, undefined, document.body)}
                                    </div>
                                );
                            })}
                        </Scrollable>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
