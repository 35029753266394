import React, { useMemo } from 'react';
import classnames from 'classnames';

import styles from '../../MyDatasets.module.scss';
import { SingleDatasetField, TableDatasetFieldType } from '../../../../datasets/store';
import { InformationTooltip } from '../../../../shared/tooltip';
import { widthsByType } from '../../../dataset-builder/preview/tablePreview/TableHeader';
import { MyDatasetsOpenFieldSection } from '../../store';
import { getAdminTableDatasetField } from '../../../../shared/datasets/AdminDatasetFields';

interface TableCellProps {
    field: SingleDatasetField;
    index: number;
    isFirstCell: boolean;
    datasetId: number;
    toggleSection: (fieldSection: MyDatasetsOpenFieldSection) => void;
    getSectionOpen: (fieldSection: MyDatasetsOpenFieldSection) => boolean;
    modalInstance?: boolean
    columnHidden: boolean;
}

export const TableCell: React.FC<TableCellProps> = ({ field, index, isFirstCell, datasetId, toggleSection, getSectionOpen, modalInstance, columnHidden }) => {

    const cellWidth = useMemo(() => `${widthsByType[field.type as TableDatasetFieldType]}px`, [field.type]);

    return (
        <div
            className={classnames(styles.cellWrapper, {
                [styles.firstCell]: isFirstCell,
            })}
            style={{ width: cellWidth, minWidth: cellWidth }}
            data-testid={`table-cell-${index}-wrapper`}
        >
            <div className={styles.openCellWrapper}>
                {getAdminTableDatasetField(field, index, datasetId, toggleSection, getSectionOpen, columnHidden, true, modalInstance)}
                {field.settings.showClause && <InformationTooltip content='Add personalised clause label here' label='cl' />}
            </div>
        </div>
    );
};
