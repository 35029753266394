import { action } from 'typesafe-actions';

import { AdminAnalyticsActionTypes, AdminAnalyticsChart, AdminAttestationAnalytics } from './types';
import { PieChartAnalyticsData } from '../../../shared/analytics/PieChart';

export const fetchAttestationProgressStarted = () => action(AdminAnalyticsActionTypes.FETCH_ATTESTATION_PROGRESS_ANALYTICS_STARTED, AdminAnalyticsChart.ATTESTATION_PROGRESS);
export const fetchAttestationProgressSuccessful = (analytics: AdminAttestationAnalytics[]) => action(AdminAnalyticsActionTypes.FETCH_ATTESTATION_PROGRESS_ANALYTICS_SUCCESSFUL, { analytics, chart: AdminAnalyticsChart.ATTESTATION_PROGRESS, key: 'attestationProgress' });
export const fetchAttestationProgressFailed = (e: string) => action(AdminAnalyticsActionTypes.FETCH_ATTESTATION_PROGRESS_ANALYTICS_FAILED, { error: e, chart: AdminAnalyticsChart.ATTESTATION_PROGRESS });

export const redirectAttestation = (attestationInstanceId: number) => action(AdminAnalyticsActionTypes.REDIRECT_ANALYTICS_ATTESTATION, attestationInstanceId);

export const fetchPageUsageAnalyticsStarted = () => action(AdminAnalyticsActionTypes.FETCH_PAGE_USAGE_ANALYTICS_STARTED, AdminAnalyticsChart.PAGE_USAGE);
export const fetchPageUsageAnalyticsSuccessful = (analytics: PieChartAnalyticsData[]) => action(AdminAnalyticsActionTypes.FETCH_PAGE_USAGE_ANALYTICS_SUCCESSFUL, { analytics, chart: AdminAnalyticsChart.PAGE_USAGE, key: 'pageUsageAnalytics' });
export const fetchPageUsageAnalyticsFailed = (e: string) => action(AdminAnalyticsActionTypes.FETCH_PAGE_USAGE_ANALYTICS_FAILED, { error: e, chart: AdminAnalyticsChart.PAGE_USAGE });
