import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch } from '../../../../hooks/react-redux';
import { CompanyEntity, checkBranchesFuzzyMatch, CompanyEntityBranch, addEntityBranch, updateBranchInformation, deleteEntityBranch } from '../store';
import styles from './EntityType.module.scss';
import { Button } from '../../../shared/button/Button';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Add } from '../../../shared/icons';
import { BranchInput } from './BranchInput';

interface CompanyEntityBranchesSectionProps {
    entity: CompanyEntity;
}

export const CompanyEntityBranchesSection: React.FC<CompanyEntityBranchesSectionProps> = ({ entity }) => {
    const dispatch = useAppDispatch();
    const { content: { branches } } = entity;
    const [openBranches, setOpenBranches] = useState<number[]>(branches.map((branch, index) => index));

    const updateBranch = useCallback((key: keyof CompanyEntityBranch, value: string | string[] | null | boolean, index: number, checkFuzzyMatch?: boolean) => {
        if (checkFuzzyMatch) {
            dispatch(checkBranchesFuzzyMatch(value as string, index));
        }
        dispatch(updateBranchInformation(key, value, index));
    }, [dispatch]);

    const removeBranch = useCallback((index: number) => {
        dispatch(deleteEntityBranch(index));
        setOpenBranches(openBranches.filter((_, branchIndex) => branchIndex !== index));
    }, [dispatch, setOpenBranches, openBranches]);

    const scrollToBranch = useCallback(() => {
        const newIndex = branches.length;
        const branch = document.getElementById(`branch-input-${newIndex}`);
        branch?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, [branches]);

    const addBranch = useCallback(() => {
        dispatch(addEntityBranch());
        setTimeout(() => scrollToBranch(), 150);
        setOpenBranches([...openBranches, branches.length]);
    }, [dispatch, scrollToBranch, openBranches, branches]);

    const toggleAllBranches = useCallback(() => openBranches.length === branches.length ? setOpenBranches([]) : setOpenBranches(branches.map((branch, index) => index)), [openBranches, branches]);

    const toggleBranchOpen = useCallback((branchIndex: number) => {
        const updatedOpenBranches = openBranches.includes(branchIndex) ? openBranches.filter(index => index !== branchIndex) : [...openBranches, branchIndex];
        setOpenBranches(updatedOpenBranches);
    }, [openBranches]);

    const collapseAllLabel = useMemo(() => openBranches.length === branches.length ? 'Collapse' : 'Expand', [openBranches, branches]);

    const showDoraToggle = useMemo(() => entity.myCompany === 1 && entity.doraInScope === 1, [entity]);

    return (
        <div className={styles.branchesWrapper}>
            <div className={styles.addBranchWrapper}>
                <Button label='Add Branch' icon={Add} onClick={addBranch} fontSize={20} testId='entity-add-branch' />
                {branches.length > 0 && <Button label={`${collapseAllLabel} All`} onClick={toggleAllBranches} fontSize={20} testId='collapse-entity-branches' />}
            </div>
            <div className={styles.currentBranches}>
                <Scrollable>
                    <div>
                        {branches.length > 0 && branches.map((branch, index) => (
                            <BranchInput
                                key={index}
                                branch={branch}
                                branchIndex={index}
                                onValueChange={updateBranch}
                                deleteFunction={removeBranch}
                                openBranches={openBranches}
                                showDoraToggle={showDoraToggle}
                                toggleBranchOpen={toggleBranchOpen}
                            />
                        ))}
                    </div>
                </Scrollable>
            </div>
        </div>
    );
};
