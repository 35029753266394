import { isEqual, isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Toggle } from '../../../shared/toggle';
import { SingleField } from '../fields/SingleField';
import { addOpinionFieldCustomChild, getOpenFieldsAndSections, toggleOpenFieldSection, getCurrentInstanceType, updateCustomToggleLabel, updateCounterpartyCoverageValue, createMultiToggleField, getFieldSignOffNotes, getIsFirstTimelineEntry, getSubCounterpartyTypeModalOpen, toggleSubCounterpartyModal } from '../store';
import { CounterpartyCoverage, OpinionInstanceType, UpdatedOpinionField } from '../store/types';
import { SectionWrapper } from './SectionWrapper';
import styles from './OpinionSection.module.scss';
import { CustomTooltip, IconTooltip, OverflowTooltip } from '../../../shared/tooltip';
import { Search, Settings, Tick } from '../../../shared/icons';
import { SignOffConversationIcon } from '../sign-off';
import { getUserHasFeaturePermission } from '../../../auth/login/store';
import { FeaturePermission } from '../../../admin/users/store';
import { IconButton } from '../../../shared/button/IconButton';
import { SubCounterpartyConfigModal } from './SubCounterpartyConfigModal';
import { fetchOpinionSubCounterpartyTypesStarted } from '../../../admin/entity/store';

const { lightGrey, amethyst, grey, french } = styles;

interface CounterpartyCoverageProps {
    jurisdiction: string | null;
    counterpartyCoverage: CounterpartyCoverage;
    isEditing: boolean;
    isUpdating: boolean;
    fieldsUpdated: UpdatedOpinionField[];
    columns?: number[];
}

export const CounterpartyCoverageSection: React.FC<CounterpartyCoverageProps> = ({ counterpartyCoverage, isEditing, isUpdating, fieldsUpdated, columns = [0, 1], jurisdiction }) => {
    const [autoFocus, setAutoFocus] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const getFieldOpen = useCallback((id: string) => openFieldsAndSections.includes(id), [openFieldsAndSections]);
    const toggleFieldOpen = useCallback((id: string) => dispatch(toggleOpenFieldSection(id)), [dispatch]);
    const sectionId = 'counterpartyCoverage';
    const { standardOptions, customOptions, other } = counterpartyCoverage;
    const signOffConversation = useAppSelector(getFieldSignOffNotes(sectionId));
    const isFirstTimelineEntry = useAppSelector(getIsFirstTimelineEntry);
    const subCounterpartyConfigModalOpen = useAppSelector(getSubCounterpartyTypeModalOpen);
    const userHasEditOpinionDataPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.EDIT_OPINION_DATA]));

    const openConfigModal = useCallback(() => dispatch(toggleSubCounterpartyModal(true)), [dispatch]);
    const closeConfigModal = useCallback(() => dispatch(toggleSubCounterpartyModal(false)), [dispatch]);

    useEffect(() => {
        if (jurisdiction) {
            dispatch(fetchOpinionSubCounterpartyTypesStarted(jurisdiction));
        }
    }, [dispatch, jurisdiction]);

    const opinionType = useAppSelector(getCurrentInstanceType);
    const isNettingOpinion = opinionType === OpinionInstanceType.NETTING;

    const updateValue = (fieldId: 'standardOptions' | 'customOptions', value: boolean[], index: number) => dispatch(updateCounterpartyCoverageValue(sectionId, fieldId, index, value));
    const updateColumnValue = (fieldId: 'standardOptions' | 'customOptions', value: boolean, index: number, columnIndex: number) => {
        const updatedValue = counterpartyCoverage[fieldId][index].value.map((current, i) => i === columnIndex ? value : current);
        updateValue(fieldId, updatedValue, index);
    };

    const fieldUpdatedByInstance = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number, columnIndex: number) => !!fieldsUpdated.find(field => isEqual({ fieldId: field.fieldId, sectionId: field.sectionId, index: field.index, columnIndex: field.columnIndex }, { fieldId, sectionId, index, columnIndex })), [fieldsUpdated, sectionId]);
    const getIsDisabled = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number, columnIndex: number) => !isEditing && !(isUpdating && fieldUpdatedByInstance(fieldId, index, columnIndex)), [isUpdating, isEditing, fieldUpdatedByInstance]);
    const shouldShowFieldUpdated = useCallback((fieldId: 'standardOptions' | 'customOptions', index: number, columnIndex: number) => fieldUpdatedByInstance(fieldId, index, columnIndex) && !isUpdating && !isEditing && !isFirstTimelineEntry, [isUpdating, isEditing, fieldUpdatedByInstance, isFirstTimelineEntry]);

    const disabled = !isEditing && !isUpdating;

    const updateAll = (checked: boolean, columnIndex: number) => {
        standardOptions.filter(({ label }) => label !== 'Other').forEach(({ value }, index) => {
            const updatedValue = value.map((current, i) => i === columnIndex ? checked : current);
            updateValue('standardOptions', updatedValue, index);
        });
        customOptions.forEach(({ value }, index) => {
            const updatedValue = value.map((current, i) => i === columnIndex ? checked : current);
            updateValue('customOptions', updatedValue, index);
        });
    };

    const getOptionWrapperStyle = useCallback((index: number, noBorderBottom = false) => {
        const totalEntries = standardOptions.length + customOptions.length;
        const numberOfLastRowEntries = totalEntries % 2 || 2;
        const lastRowEntries = Array(numberOfLastRowEntries).fill(totalEntries - numberOfLastRowEntries).map((val, i) => val + i);
        const isLastRow = lastRowEntries.includes(index);
        const isFirstColumn = ((index + 1) % 2) === 1;
        if (!isLastRow && !noBorderBottom) {
            return !isFirstColumn ? { width: 'calc((100% / 2) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}`, borderBottom: `1px solid ${lightGrey}` } : { width: 'calc((100% / 2) - 10px)', borderBottom: `1px solid ${lightGrey}` };
        }
        return !isFirstColumn ? { width: 'calc((100% / 2) - 24px)', paddingLeft: '15px', borderLeft: `1px solid ${lightGrey}` } : { width: 'calc((100% / 2) - 10px)' };
    }, [standardOptions.length, customOptions.length]);

    const boxShadowColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return grey;
    }, []);

    const onColour = useCallback((showFieldUpdated: boolean) => {
        if (showFieldUpdated) {
            return amethyst;
        }
        return french;
    }, []);

    const updateCustomLabel = useCallback((value: string, index: number) => dispatch(updateCustomToggleLabel(sectionId, index, value)), [dispatch, sectionId]);

    const addCustomOption = useCallback(() => {
        const newField = createMultiToggleField('', columns.length);
        const newCustomOptions = [...customOptions, newField];
        dispatch(addOpinionFieldCustomChild(sectionId, newCustomOptions));
        setAutoFocus(true);
    }, [dispatch, sectionId, columns, customOptions]);

    const getAllSelected = useCallback((columnIndex: number) => [...standardOptions, ...customOptions].filter(({ label }) => label !== 'Other').map(({ value }) => value[columnIndex]).every(value => !!value), [standardOptions, customOptions]);

    const getColumnHeaders = useCallback((columnIndex: number) => {
        const optionWrapperStyle = getOptionWrapperStyle(columnIndex, true);
        return (
            <div className={styles.columnHeaders} style={optionWrapperStyle} key={columnIndex}>
                <div className={styles.columnHeader}>
                    <IconTooltip icon={Tick} content='Covered by Opinion?' fontSize={20} />
                </div>
                <div className={styles.columnHeader}>
                    <CustomTooltip overlayText='AET Required?'>
                        <div className={styles.aetHeader}>AET</div>
                    </CustomTooltip>
                </div>
            </div>
        );
    }, [getOptionWrapperStyle]);

    const customIndexOffset = useMemo(() => standardOptions.length, [standardOptions]);

    const handleSubCounterpartyIconClick = () => {
        if (!isNull(jurisdiction)) {
            openConfigModal();
        }
    };

    const nettingEngineTooltip = 'Any counterparties selected from this section are considered valid in the Netting Engine analysis';

    return (
        <SectionWrapper id={sectionId} label='Counterparty Coverage' isEditing={isEditing} isUpdating={isUpdating} isNettingEngineField={isNettingOpinion} nettingEngineTooltip={nettingEngineTooltip}>
            <div className={styles.counterpartyCoverageSectionWrapper}>
                <div className={styles.counterpartyCoverageWrapper}>
                    <div className={styles.counterpartyCoverageHeaderWrapper}>
                        <div className={styles.selectAllWrapper}>
                            <div className={styles.selectAllLabel}>Select All</div>
                            <div className={styles.multiToggleWrapper}>
                                {columns.map(columnIndex => {
                                    const allSelected = getAllSelected(columnIndex);
                                    return (
                                        <div className={styles.toggleWrapper} key={columnIndex}>
                                            <Toggle onChange={checked => updateAll(checked, columnIndex)} checked={allSelected} disabled={disabled} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={styles.addOptionWrapper}>
                            <div className={styles.addOptionLabel}>Add another option</div>
                            <div className={styles.addOptionButton}><PlusButton onClick={addCustomOption} fontSize={16} disabled={disabled} /></div>
                            <IconButton disabled={!jurisdiction} icon={userHasEditOpinionDataPermission ? Settings : Search} onClick={handleSubCounterpartyIconClick} />
                            {!isUndefined(signOffConversation) && <SignOffConversationIcon signOffConversation={signOffConversation} id={sectionId} />}
                        </div>
                    </div>
                    <div className={styles.columnHeaderWrapper}>
                        {columns.map(columnIndex => getColumnHeaders(columnIndex))}
                    </div>
                    <div className={styles.counterpartyCoverageOptionsWrapper} data-testid='counterparty-coverage-standard-options-wrapper'>
                        {standardOptions.map(({ value, label }, index) => {
                            const fieldId = 'standardOptions';
                            const optionWrapperStyle = getOptionWrapperStyle(index);
                            return (
                                <div className={styles.toggleOptionWrapper} key={index} style={optionWrapperStyle}>
                                    <div className={styles.counterpartyCoverageLabel}>
                                        <OverflowTooltip overlayText={label} trigger='click' />
                                    </div>
                                    <div className={styles.multiToggleWrapper}>
                                        {value.map((val, i) => {
                                            const isDisabled = getIsDisabled(fieldId, index, i);
                                            const showFieldUpdated = shouldShowFieldUpdated(fieldId, index, i);
                                            return (
                                                <div className={styles.toggleWrapper} key={i}>
                                                    <Toggle
                                                        onChange={checked => updateColumnValue(fieldId, checked, index, i)}
                                                        checked={val}
                                                        disabled={isDisabled}
                                                        boxShadowColour={boxShadowColour(showFieldUpdated)}
                                                        onColour={onColour(showFieldUpdated)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        {customOptions.map(({ label, value }, index) => {
                            const fieldId = 'customOptions';
                            const optionWrapperStyle = getOptionWrapperStyle(customIndexOffset + index);
                            return (
                                <div key={index} className={styles.toggleOptionWrapper} style={optionWrapperStyle}>
                                    {disabled ?
                                        <div className={styles.counterpartyCoverageLabel}>
                                            <OverflowTooltip overlayText={label} trigger='click' />
                                        </div>
                                        : <input
                                            className={styles.toggleOptionInput}
                                            value={label}
                                            onChange={e => updateCustomLabel(e.target.value, index)}
                                            data-testid={`counterparty-coverage-custom-option-input-${index}`}
                                            autoFocus={autoFocus}
                                        />
                                    }
                                    <div className={styles.multiToggleWrapper}>
                                        {value.map((val, i) => {
                                            const isDisabled = getIsDisabled(fieldId, index, i);
                                            const showFieldUpdated = shouldShowFieldUpdated(fieldId, index, i);
                                            return (
                                                <div className={styles.toggleWrapper} key={i}>
                                                    <Toggle
                                                        onChange={checked => updateColumnValue(fieldId, checked, index, i)}
                                                        checked={val}
                                                        disabled={isDisabled}
                                                        boxShadowColour={boxShadowColour(showFieldUpdated)}
                                                        onColour={onColour(showFieldUpdated)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <SubCounterpartyConfigModal
                        isOpen={subCounterpartyConfigModalOpen}
                        cancel={closeConfigModal}
                        readOnly={!isEditing}
                        counterpartyCoverage={counterpartyCoverage}
                        jurisdiction={jurisdiction}
                    />
                </div>
                <SingleField
                    field={other}
                    fieldId='other'
                    sectionId={sectionId}
                    isEditing={isEditing}
                    isUpdating={isUpdating}
                    fieldsUpdated={fieldsUpdated}
                    getFieldOpen={getFieldOpen}
                    toggleFieldOpen={toggleFieldOpen}
                />
            </div>
        </SectionWrapper>
    );
};
