import React, { useCallback, useMemo } from 'react';
import { ColDef, GridOptions, ICellRendererParams, RowDoubleClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { isNull } from 'lodash/fp';

import styles from './Users.module.scss';
import { Delete, User, Mail } from '../../shared/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Table, roleFormatter, ActionCell } from '../../shared/table';
import { checkUsernameAvailableStarted, fetchAllUsersStarted, fetchAvailableFeaturePermissionsStarted, getAllUsers, getAvailableFeaturePermissions, getConfirmDeleteUser, getIsFetchingUsers, resetUserPassword, toggleDeleteUserConfirmationModal, toggleUsersModal, ClientUser } from './store';
import { TooltipList } from '../../shared/tooltip';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Action } from '../../shared/modal/ActionModal';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { getUserRole } from '../../auth/login/store';
import { systemAdminRole } from '../../constants/permittedRoles';

const PermissionsCell: React.FC<ICellRendererParams> = ({ valueFormatted, value }) => {
    const featurePermissions = useAppSelector(getAvailableFeaturePermissions);
    const overlayText = useMemo(() => {
        if (isNull(value)) {
            return null;
        }
        return featurePermissions.filter(({ featurePermissionId }) => value.includes(featurePermissionId)).map(({ label }) => label);
    }, [featurePermissions, value]);

    return (
        <TooltipList overlayText={overlayText} placement='top' trigger='click'>
            <div className={styles.permissionsCell}>{valueFormatted}</div>
        </TooltipList>
    );
};

export const UsersTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllUsersStarted(), fetchAvailableFeaturePermissionsStarted()]);
    const users = useAppSelector(getAllUsers);
    const featurePermissions = useAppSelector(getAvailableFeaturePermissions);
    const confirmDeleteUser = useAppSelector(getConfirmDeleteUser);
    const userRole = useAppSelector(getUserRole);
    const fetchingUsers = useAppSelector(getIsFetchingUsers);
    const isSystemAdmin = systemAdminRole.includes(userRole!);

    const featurePermissionFormatter = useCallback((params: ValueFormatterParams) => {
        if (isNull(params.value)) {
            return '';
        }
        const featurePermission = featurePermissions.find(({ featurePermissionId }) => featurePermissionId === params.value[0]);
        return featurePermission ? `${featurePermission.label} ${params.value.length > 1 ? `+ ${params.value.length - 1}` : ''}` : '';
    }, [featurePermissions]);

    const editUser = useCallback((user: ClientUser) => {
        dispatch(toggleUsersModal(true, user));
        dispatch(checkUsernameAvailableStarted());
    }, [dispatch]);

    const openDeleteConfirmationModal = useCallback((user: ClientUser) => { dispatch(toggleDeleteUserConfirmationModal(user)); }, [dispatch]);
    const closeDeleteConfirmationModal = () => dispatch(toggleDeleteUserConfirmationModal(null));
    const resetPassword = useCallback((username: string) => dispatch(resetUserPassword(username)), [dispatch]);

    const userActions = useCallback((user: ClientUser): Action[] => {
        const canDelete = (systemAdminRole.includes(user.role) && isSystemAdmin) || !systemAdminRole.includes(user.role);
        let actions: Action[] = [
            { label: 'Open', icon: User, onClick: () => editUser(user) },
            { label: 'Reset Password', icon: Mail, onClick: () => resetPassword(user.username), withSeparator: canDelete, minWidth: '100px' },
        ];
        if (canDelete) {
            actions.push({ label: 'Delete', icon: Delete, onClick: () => openDeleteConfirmationModal(user), isDelete: true });
        }
        return actions;
    }, [editUser, openDeleteConfirmationModal, isSystemAdmin, resetPassword]);

    const actionCellParams = {
        testId: 'user-actions',
        actions: userActions
    };

    const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };
    const columnDefs: ColDef[] = [
        { colId: 'username', headerName: 'Username', field: 'username', cellStyle: defaultCellStyle, width: 0.3 },
        { colId: 'email', headerName: 'Email', field: 'email', cellStyle: defaultCellStyle, width: 0.35 },
        { colId: 'role', headerName: 'Role', field: 'role', cellStyle: defaultCellStyle, width: 0.15, valueFormatter: roleFormatter },
        { colId: 'permissions', headerName: 'Permissions', field: 'featurePermissions', width: 0.15, cellRenderer: 'permissionsCellRenderer', valueFormatter: featurePermissionFormatter },
        { colId: 'actions', headerName: '', field: '', cellRenderer: 'actionCellRenderer', width: 0.05, cellRendererParams: actionCellParams }
    ];

    const gridOptions: GridOptions = {
        components: {
            actionCellRenderer: ActionCell,
            permissionsCellRenderer: PermissionsCell
        },
        paginationPageSize: 20
    };

    const onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => editUser(data);

    return (
        <>
            <Table
                colDefs={columnDefs}
                rowData={users}
                gridOptions={gridOptions}
                percentageWidths={true}
                onRowDoubleClicked={onRowDoubleClicked}
                testId='users'
                showLoadingOverlay={fetchingUsers}
            />
            {!isNull(confirmDeleteUser) &&
                <DeleteConfirmationModal
                    isOpen
                    closeModal={closeDeleteConfirmationModal}
                    user={confirmDeleteUser}
                />
            }
        </>
    );
};
