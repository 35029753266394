import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { User, getIsEditing, updateMyProfileDetails, forgottenPasswordReminderStarted, getSendingReminder, getShowAccountSwitchButton, getShowResetPassword, getShowSwitchAccount, setShowResetPassword, setShowSwitchAccount } from '../../auth/login/store';
import { roleLabels } from '../../constants/roleLabels';
import { Icon } from '../../shared/icon/Icon';
import { Password, Repeat } from '../../shared/icons';
import { DropzonePicture } from '../../shared/picture/DropzonePicture';
import { CustomTooltip, OverflowTooltip } from '../../shared/tooltip';
import styles from '../Home.module.scss';
import { ResetPassword } from '../../auth/resetPassword/ResetPassword';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { SwitchAccount } from '../SwitchAccount';

interface MyProfileGeneralProps {
    user: User;
}

export const MyProfileGeneral: React.FC<MyProfileGeneralProps> = ({ user }) => {
    const { username, role, forenames, surname, profilePicture, file } = user;

    const dispatch = useAppDispatch();
    const isEditing = useAppSelector(getIsEditing);
    const isSendingReminder = useAppSelector(getSendingReminder);
    const showAccountSwitchButton = useAppSelector(getShowAccountSwitchButton);
    const showResetPassword = useAppSelector(getShowResetPassword);
    const showSwitchAccount = useAppSelector(getShowSwitchAccount);

    const updateProfileDetails = (key: keyof User, value: File | string | undefined) => dispatch(updateMyProfileDetails(key, value));
    const setProfilePicture = (files: File[]) => updateProfileDetails('file', files.length ? files[0] : undefined);

    const updateForenames = (value: string) => updateProfileDetails('forenames', value);
    const updateSurname = (value: string) => updateProfileDetails('surname', value);

    const initials = useMemo(() => `${forenames.charAt(0)}${surname.charAt(0)}`, [forenames, surname]);

    const forgotPasswordDisabled = useMemo(() => isEditing || showResetPassword || showSwitchAccount || isSendingReminder, [isEditing, showResetPassword, showSwitchAccount, isSendingReminder]);

    const testId = 'my-profile';

    const forgotPasswordHandler = useCallback(() => !forgotPasswordDisabled && username && dispatch(forgottenPasswordReminderStarted(username)), [dispatch, username, forgotPasswordDisabled]);

    const scrollTo = useCallback(() => {
        const section = document.getElementById('reset-password');
        section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }, []);

    const resetPassword = useCallback(() => {
        if (!showSwitchAccount) {
            dispatch(setShowResetPassword(true));
            setTimeout(scrollTo, 100);
        }
    }, [dispatch, scrollTo, showSwitchAccount]);

    const switchAccount = useCallback(() => {
        if (!showResetPassword) {
            dispatch(setShowSwitchAccount(true));
        }
    }, [dispatch, showResetPassword]);

    return (
        <div className={styles.generalSectionWrapper}>
            <DropzonePicture
                setProfilePicture={setProfilePicture}
                profilePicture={profilePicture}
                file={file}
                disabled={!isEditing}
                initials={initials}
                testId={testId}
            />
            <Scrollable height='calc(100% - 222px)' autoHeight={false}>
                <div className={styles.generalInfoWrapper}>
                    <OverflowTooltip className={styles.username} overlayText={username} testId={`${testId}-username`} />
                    <div className={styles.userInfoWrapper}>
                        <div className={styles.profileInfoLabel} data-testid={`${testId}-forename-label`}>Forename(s)</div>
                        <input className={styles.profileInfoValue} disabled={!isEditing} value={forenames} onChange={e => updateForenames(e.target.value)} data-testid={`${testId}-forename-input`} />
                    </div>
                    <div className={styles.userInfoWrapper}>
                        <div className={styles.profileInfoLabel} data-testid={`${testId}-surname-label`}>Surname</div>
                        <input className={styles.profileInfoValue} disabled={!isEditing} value={surname} onChange={e => updateSurname(e.target.value)} data-testid={`${testId}-surname-input`} />
                    </div>
                    <div className={styles.userInfoWrapper}>
                        <div className={styles.profileInfoLabel} data-testid={`${testId}-role-label`}>Role</div>
                        <input className={styles.profileInfoValue} data-testid={`${testId}-role-input`} value={roleLabels[role]} disabled />
                    </div>
                </div>
                <div className={styles.accountButtonsWrapper}>
                    <div className={classnames(styles.accountButton, { [styles.accountButtonDisabled]: showSwitchAccount || isEditing })} onClick={resetPassword} style={{ justifyContent: 'flex-start' }}>
                        <Icon icon={Password} fontSize={25} />
                        <div className={styles.resetPasswordButtonLabel} data-testid={`${testId}-reset-password-label`}>Reset Password</div>
                    </div>
                    {showAccountSwitchButton && <div className={classnames(styles.accountButton, { [styles.accountButtonDisabled]: showResetPassword || isEditing })} onClick={switchAccount} style={{ justifyContent: 'center' }}>
                        <Icon icon={Repeat} fontSize={25} />
                        <div className={styles.resetPasswordButtonLabel} data-testid={`${testId}-switch-account-label`}>Switch Account</div>
                    </div>}
                    <div className={classnames(styles.accountButton, { [styles.accountButtonDisabled]: forgotPasswordDisabled })} style={{ justifyContent: 'flex-end' }}>
                        <CustomTooltip overlayText='Click here if you are unsure on your current password and would like to reset via email' placement='right'>
                            <div className={styles.resetPasswordButtonLabel} onClick={forgotPasswordHandler}> Forgot Password?</div>
                        </CustomTooltip>
                    </div>
                </div>
                {showResetPassword && <ResetPassword />}
                {showSwitchAccount && <SwitchAccount />}
            </Scrollable>
        </div>
    );
};
