import React, { useCallback, useMemo } from 'react';
import { getOr, isEqual, isNull } from 'lodash/fp';

import { AgencyDatasetFieldType, TableDatasetFieldType } from '../../store';
import { TableHeader } from './TableHeader';
import { TableCell } from './TableCell';
import { addCollapsedColumn, addOpenFieldSection, addTableDatasetRow, AnnexInstance, AnnexInstanceField, duplicateTableDatasetRow, getAnnexDefinitionFieldIds, getOpenFieldsAndSections, OpenFieldSection, removeCollapsedColumn, removeOpenFieldSection, removeTableDatasetRow, SingleInstanceField, TableDatasetInstance } from '../store';
import styles from '../../../shared/datasets/SharedStyling.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DeleteButton } from '../../../shared/button/DeleteButton';
import { PlusButton } from '../../../shared/button/PlusButton';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { DuplicateButton } from '../../../shared/button/DuplicateButton';
import { HiddenFields } from '../../../admin/my-datasets/store';
import { useSingleHiddenFields } from '../../../../hooks/useHiddenSingleFields';

interface TableInstanceProps {
    datasetInstance: TableDatasetInstance | AnnexInstance;
    modalInstance?: boolean;
    isEditing: boolean;
    parentFieldId: string;
    isUpdating: boolean;
    instanceExecutedDateMatchesParent: boolean;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
    isMLCorrection?: boolean;
}

export const TableInstance: React.FC<TableInstanceProps> = ({ datasetInstance, isEditing, isUpdating, modalInstance, parentFieldId, hiddenFields, datasetHidden, instanceExecutedDateMatchesParent, isMLCorrection }) => {
    const dispatch = useAppDispatch();

    const annexFieldIds = useAppSelector(getAnnexDefinitionFieldIds);
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const datasetId = useMemo(() => isNull(datasetInstance.annexDefinitionId) ? datasetInstance.datasetId! : datasetInstance.annexDefinitionId, [datasetInstance]);
    const removeRow = (rowId: string) => dispatch(removeTableDatasetRow(datasetId, parentFieldId, rowId, modalInstance));
    const addRow = () => dispatch(addTableDatasetRow(datasetId, parentFieldId, modalInstance));
    const duplicateRow = (rowId: string) => dispatch(duplicateTableDatasetRow(datasetId, parentFieldId, rowId, modalInstance));

    const singleRow = Object.values(datasetInstance.datasetFields).length === 1;
    const { fieldsUpdated, datasetRows, datasetFields } = datasetInstance;

    const sortedDatasetRows = useMemo(() => datasetRows.reduce((acc: [string, SingleInstanceField[] | AnnexInstanceField[]][], cur) => {
        const orderedRow = Object.entries(datasetFields).find(([rowId]) => rowId === cur);
        if (orderedRow) {
            acc.push(orderedRow);
        }
        return acc;
    }, []), [datasetRows, datasetFields]);

    const addRowVisible = useMemo(() => isEditing || isUpdating, [isEditing, isUpdating]);

    const getSectionOpen = useCallback((fieldSection: OpenFieldSection) => openFieldsAndSections.some(openSection => isEqual(openSection, fieldSection)), [openFieldsAndSections]);

    const toggleSection = useCallback((fieldSection: OpenFieldSection) => {
        const sectionOpen = openFieldsAndSections.find(openSection => isEqual(openSection, fieldSection));
        sectionOpen ? dispatch(removeOpenFieldSection(sectionOpen)) : dispatch(addOpenFieldSection(fieldSection));
    }, [dispatch, openFieldsAndSections]);

    const collapsedColumns = useMemo(() => datasetInstance.collapsedColumns || [], [datasetInstance.collapsedColumns]);

    const getColumnCollapsed = useCallback((fieldId: string) => collapsedColumns.some(id => isEqual(id, fieldId)), [collapsedColumns]);
    const toggleCollapseColumn = useCallback((fieldId: string) => {
        const columnIsCollapsed = collapsedColumns.find(id => isEqual(id, fieldId));
        columnIsCollapsed ? dispatch(removeCollapsedColumn(datasetId, parentFieldId, fieldId, modalInstance)) : dispatch(addCollapsedColumn(datasetId, parentFieldId, fieldId, modalInstance));
    }, [dispatch, collapsedColumns, datasetId, parentFieldId, modalInstance]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields && hiddenFields[datasetInstance.datasetId!] || [], [datasetInstance, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const columnIsHidden = useCallback((id: string) => datasetHidden || !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields, datasetHidden]);

    const tableFirstCell = useCallback((fieldId: string) => (Object.values(datasetInstance.datasetFields)[0].map((field: SingleInstanceField | AnnexInstanceField) => field.id)).filter((id: string) => !hiddenSingleFields.map(({ fieldId }) => fieldId).includes(id!))[0] === fieldId, [hiddenSingleFields, datasetInstance]);

    return (
        <div className={styles.tableInstanceWrapper} data-testid='table-dataset-instance-wrapper' style={{ height: '100%' }}>
            <Scrollable>
                <div className={styles.tableHeader}>
                    {Object.values(datasetInstance.datasetFields)[0].map((field: SingleInstanceField | AnnexInstanceField) => {
                        const { label, description, refLabel, settings, id, type } = field;
                        const columnCollapsed = getColumnCollapsed(id!);
                        const columnHidden = columnIsHidden(id!);
                        if (columnHidden) {
                            return null;
                        }
                        const isFirstCell = tableFirstCell(id!);
                        return (
                            <TableHeader
                                key={id!}
                                label={label}
                                description={description}
                                refLabel={refLabel}
                                showRef={settings.showRef}
                                id={id!}
                                type={type as TableDatasetFieldType | AgencyDatasetFieldType}
                                isFirstCell={isFirstCell}
                                aliasLabel={getOr(undefined, 'aliasLabel', field)}
                                columnCollapsed={columnCollapsed}
                                toggleCollapseColumn={toggleCollapseColumn}
                                highlight={settings.highlight}
                            />
                        );
                    })}
                    <div className={styles.actionRowHeader} />
                    <div className={styles.actionRowHeader} />
                </div>
                <div className={styles.tableBody} style={{ height: 'calc(100% - 50px)' }}>
                    <Scrollable>
                        {sortedDatasetRows.map(([rowId, row]) => (
                            <div className={styles.tableRow} key={rowId}>
                                {row.map((field: SingleInstanceField | AnnexInstanceField, index: number) => {
                                    const columnCollapsed = getColumnCollapsed(field.id!);
                                    const columnHidden = columnIsHidden(field.id!);
                                    if (columnHidden) {
                                        return null;
                                    }
                                    const isFirstCell = tableFirstCell(field.id!);
                                    return (
                                        <TableCell
                                            key={field.id!}
                                            field={field}
                                            index={index}
                                            isFirstCell={isFirstCell}
                                            isEditing={isEditing}
                                            isUpdating={isUpdating}
                                            rowId={rowId}
                                            fieldsUpdated={fieldsUpdated}
                                            annexFieldIds={annexFieldIds}
                                            isAnnexInstance={!!datasetInstance.annexDefinitionId}
                                            datasetId={datasetId}
                                            modalInstance={modalInstance}
                                            toggleSection={toggleSection}
                                            getSectionOpen={getSectionOpen}
                                            parentFieldId={parentFieldId}
                                            columnCollapsed={columnCollapsed}
                                            instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                                            isMLCorrection={isMLCorrection}
                                        />
                                    );
                                })}
                                <div className={styles.actionRowCell}>
                                    {isEditing && <DuplicateButton onClick={() => duplicateRow(rowId)} disabled={!isEditing} />}
                                </div>
                                <div className={styles.actionRowCell}>
                                    {!singleRow && <DeleteButton onClick={() => removeRow(rowId)} disabled={!isEditing} />}
                                </div>
                            </div>
                        ))}
                        {addRowVisible &&
                            <div className={styles.addTableRow}>
                                <PlusButton onClick={addRow} fontSize={20} />
                            </div>
                        }
                    </Scrollable>
                </div>
            </Scrollable>
        </div>
    );
};
