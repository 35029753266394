import React, { useMemo } from 'react';

import { Icon } from '../icon/Icon';
import { Delete, Tick, Time } from '../icons';
import { Spinner } from '../spinner/Spinner';
import styles from './Table.module.scss';
import { IconTooltip } from '../tooltip';

enum AnalysisStatus {
    IN_PROGRESS = 'In progress',
    COMPLETE = 'Complete',
    FAILED = 'Failed',
    LATER = 'Later'
}

const getAnalysisIcon = (status: AnalysisStatus) => {
    switch (status) {
        case AnalysisStatus.IN_PROGRESS:
            return <Spinner size={15} />;
        case AnalysisStatus.LATER:
            return <IconTooltip icon={Time} content='Analysis to be completed later' fontSize={28} />;
        case AnalysisStatus.FAILED:
            return <div className={styles.statusComplete}><Icon icon={Delete} color='red' /></div>;
        case AnalysisStatus.COMPLETE:
            return <div className={styles.statusComplete}><Icon icon={Tick} /></div>;
        default:
            return 'Unknown';
    }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const AnalysisCell: React.FC<{ data: any; }> = ({ data }) => {
    const analysisStatus = useMemo(() => {
        if (data.analysisComplete) {
            return AnalysisStatus.COMPLETE;
        }
        if (data.analyseLater) {
            return AnalysisStatus.LATER;
        }
        return data.analysisStarted ? AnalysisStatus.IN_PROGRESS : AnalysisStatus.FAILED;
    }, [data]);

    return (
        <div className={styles.analysisWrapper}>
            {getAnalysisIcon(analysisStatus)}
        </div>
    );
};
