import React, { useMemo } from 'react';
import Modal from 'react-modal';

import styles from './AgencyAnnex.module.scss';
import { useWindowResize } from '../../hooks/useWindowResize';
import { Button } from '../shared/button/Button';

interface DocumentAnnexModalProps {
    children?: React.ReactNode;
    closeModal: () => void;
    confirm: () => void;
    isOpen: boolean;
    testId?: string;
    closeLabel?: string;
    confirmLabel?: string;
    confirmDisabled?: boolean;
    closeDisabled?: boolean;
    confirmDisabledTooltip?: string[];
}

const NAV_BAR = 90;

export const DocumentAnnexModal: React.FC<DocumentAnnexModalProps> = ({
    children,
    closeModal,
    isOpen,
    closeDisabled,
    closeLabel = 'Cancel',
    confirm,
    confirmDisabled,
    confirmDisabledTooltip,
    confirmLabel = 'Confirm',
    testId
}) => {
    const [screenWidth, screenHeight] = useWindowResize();
    const leftOffset = useMemo(() => screenWidth * 0.58, [screenWidth]);

    if (!isOpen) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            className={styles.documentAnnexModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: 'flex',
                    zIndex: 9,
                    position: 'absolute',
                    left: `${leftOffset}px`,
                    top: `${NAV_BAR}px`,
                    width: `${screenWidth * 0.42}px`,
                    height: `${screenHeight - NAV_BAR}px`,
                    borderRadius: '5px'
                }
            }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.wrapper} data-testid={`${testId}-modal-wrapper`}>
                <div className={styles.contentWrapper}>
                    {children}
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label={closeLabel} disabled={closeDisabled} testId={`${testId}-close`} fontSize={18} renderIconRight />
                    <Button onClick={confirm} label={confirmLabel} disabled={confirmDisabled} testId={`${testId}-confirm`} disabledTooltip={confirmDisabledTooltip} />
                </div>
            </div>
        </Modal>
    );
};
