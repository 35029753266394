import React from 'react';

export const Group: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="49.3" y1="32.2" x2="84" y2="63.1" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,113.2H25.9c-6.6,0-12-5.4-12-12V25c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,107.8,108.7,113.2,102.1,113.2z" />
        <path fill={color || 'currentColor'} stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M97.6,72.2H77.5c-0.6,0-1.1-0.5-1.1-1.1v-16c0-0.6,0.5-1.1,1.1-1.1h20.1c0.6,0,1.1,0.5,1.1,1.1v16C98.6,71.7,98.2,72.2,97.6,72.2z" />
        <path fill='#FFFFFF' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M56.6,39.5H40.9c-0.6,0-1.1-0.5-1.1-1.1V26c0-0.6,0.5-1.1,1.1-1.1h15.7c0.6,0,1.1,0.5,1.1,1.1v12.4C57.7,39.1,57.2,39.5,56.6,39.5z" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="49.3" y1="63.1" x2="84" y2="63.1" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} x1="49.3" y1="88.4" x2="84" y2="63.1" />
        <path fill='#FFFFFF' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M47.7,70.4H32c-0.6,0-1.1-0.5-1.1-1.1V56.9c0-0.6,0.5-1.1,1.1-1.1h15.7c0.6,0,1.1,0.5,1.1,1.1v12.4C48.8,69.9,48.3,70.4,47.7,70.4z" />
        <path fill='#FFFFFF' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M56.7,96.8H41c-0.6,0-1.1-0.5-1.1-1.1V83.3c0-0.6,0.5-1.1,1.1-1.1h15.7c0.6,0,1.1,0.5,1.1,1.1v12.4C57.8,96.3,57.3,96.8,56.7,96.8z" />
    </svg>
);
