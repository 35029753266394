import { isNull } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { fetchAvailableDocumentNamesStarted } from '../../admin/documents/store';
import { fetchAllDropdownListsStarted } from '../../admin/dropdown-lists/store';
import { fetchAllEntitiesStarted } from '../../admin/entity/store';
import { DocumentReportSelect } from './DocumentReportSelect';
import { DocumentReportTemplates } from './DocumentReportTemplates';
import { DocumentReportsWizard } from './DocumentReportsWizard';
import styles from './Reports.module.scss';
import { ReportPage, fetchAllReportTemplatesStarted, getIsFetchingTemplates, getReportPage, getSelectedTemplate, resetReport, setReportPage } from './store';
import { Spinner } from '../../shared/spinner/Spinner';

export interface DocumentReportRouteParams {
    templateId: string | undefined;
}

export const DocumentReports: React.FC<RouteComponentProps<DocumentReportRouteParams>> = ({ match: { url, params } }) => {
    const dispatch = useAppDispatch();
    const reportPage = useAppSelector(getReportPage);
    const selectedTemplate = useAppSelector(getSelectedTemplate);
    const isFetching = useAppSelector(getIsFetchingTemplates);

    useFetchStarted([fetchAvailableDocumentNamesStarted(), fetchAllEntitiesStarted(true), fetchAllReportTemplatesStarted(), fetchAllDropdownListsStarted()]);

    const pageContent = useMemo(() => {
        if (isFetching && url && url.includes('templates')) {
            return <Spinner />;
        }
        switch (reportPage) {
            case ReportPage.SELECT:
            default:
                return <DocumentReportSelect />;
            case ReportPage.WIZARD:
                return <DocumentReportsWizard />;
            case ReportPage.TEMPLATES:
                return <DocumentReportTemplates />;
        }
    }, [reportPage, isFetching, url]);

    useEffect(() => {
        if (isNull(selectedTemplate) && url) {
            if (url.includes('templates')) {
                dispatch(setReportPage(ReportPage.TEMPLATES, params.templateId));
            }
        }
    }, [selectedTemplate, dispatch, url, params.templateId]);

    useEffect(() => () => {
        dispatch(resetReport(ReportPage.SELECT));
    }, [dispatch]);

    return (
        <div data-testid='document-reporting-wrapper' className={styles.reportsWrapper}>
            {pageContent}
        </div>
    );
};
