import classNames from 'classnames';
import { isNull } from 'lodash/fp';
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';
import { opinionCounterpartyTypeOptions } from '../../constants/entity';
import { IconButton } from '../../shared/button/IconButton';
import { PlusButton } from '../../shared/button/PlusButton';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { Delete, SaveFile } from '../../shared/icons';
import { LoadingDots } from '../../shared/loading-dots/LoadingDots';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Text } from '../../shared/text/Text';
import { Toggle } from '../../shared/toggle';
import { InformationTooltip } from '../../shared/tooltip';
import { fetchAllDropdownListsStarted, getAllDropdownLists } from '../dropdown-lists/store';
import { fetchOpinionSubCounterpartyTypesStarted, fetchOpinionSubCounterpartyTypesSuccessful, getAvailableSubCounterpartyTypes, getIsFetchingSubCounterpartyTypes, getIsSaving, getSelectedSubCounterpartyJurisdiction, getSelectedSubCounterpartyParent, getSelectedSubCounterpartyType, initialSubCounterparty, selectSubCounterpartyTypesJurisdiction, selectSubCounterpartyTypesParent, setSelectedOpinionSubCounterparty, SubCounterpartyType, updateOpinionSubCounterparty, upsertOpinionSubCounterpartyTypesStarted } from '../entity/store';
import { FeaturePermission } from '../users/store';
import styles from './SubCounterpartyTypes.module.scss';
import { SubCounterpartyTypesInput } from './SubCounterpartyTypesInput';

interface ParentCounterpartyButtonProps {
    handleClick: () => void;
    label: string;
    selected: boolean;
}

const ParentCounterpartyButton: React.FC<ParentCounterpartyButtonProps> = ({ handleClick, label, selected }) => <div onClick={handleClick} className={classNames(styles.parentCounterpartyButton, { [styles.selectedButton]: selected })}>{label}</div>;

export const SubCounterpartyTypes: React.FC<RouteComponentProps> = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDropdownListsStarted()]);

    const [, screenHeight] = useWindowResize();
    const parentOptionsHeight = useMemo(() => screenHeight - 305, [screenHeight]);
    const userHasIndustryStandardPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.UPLOAD_INDUSTRY_STANDARD_OPINIONS]));

    const isSaving = useAppSelector(getIsSaving);
    const jurisdiction = useAppSelector(getSelectedSubCounterpartyJurisdiction);
    const parentCounterparty = useAppSelector(getSelectedSubCounterpartyParent);
    const dropdownLists = useAppSelector(getAllDropdownLists);
    const subCounterpartyTypes = useAppSelector(getAvailableSubCounterpartyTypes);
    const selectedSubCounterparty = useAppSelector(getSelectedSubCounterpartyType);
    const isLoading = useAppSelector(getIsFetchingSubCounterpartyTypes);
    const subCounterpartyNameValue = selectedSubCounterparty ? selectedSubCounterparty.subCounterpartyName : '';
    const subCounterpartyId = selectedSubCounterparty ? selectedSubCounterparty.subCounterpartyTypeId : null;

    const setSelectedSubCounterparty = useCallback((value: SubCounterpartyType) => dispatch(setSelectedOpinionSubCounterparty(value)), [dispatch]);
    const updateCounterpartyValue = useCallback((key: keyof SubCounterpartyType, value: string | number) => dispatch(updateOpinionSubCounterparty(key, value)), [dispatch]);
    const updateCounterpartyName = (event: ChangeEvent<HTMLInputElement>) => updateCounterpartyValue('subCounterpartyName', event.target.value);
    const saveOpinionSubCounterpartyType = () => dispatch(upsertOpinionSubCounterpartyTypesStarted());
    const setJurisdiction = useCallback((value: string | null) => {
        dispatch(selectSubCounterpartyTypesJurisdiction(value));
        if (!isNull(value) && parentCounterparty) {
            dispatch(fetchOpinionSubCounterpartyTypesStarted(value, parentCounterparty));
        }
    }, [dispatch, parentCounterparty]);

    const setParentCounterparty = useCallback((value: string) => {
        dispatch(selectSubCounterpartyTypesParent(value));
        if (!isNull(jurisdiction) && value.length > 0) {
            dispatch(fetchOpinionSubCounterpartyTypesStarted(jurisdiction!, value));
        }
    }, [dispatch, jurisdiction]);

    const jurisdictionOptions = dropdownLists.find(({ name }) => name === 'OpinionJurisdiction')?.options.map(type => ({ value: type, label: type })) || [];
    const jurisdictionValue = jurisdictionOptions.filter(option => option.value === jurisdiction) || null;

    const newSubCounterpartyNameValue = useMemo(() => subCounterpartyId ? '' : subCounterpartyNameValue, [subCounterpartyNameValue, subCounterpartyId]);
    const noSubCounterpartyTypesMessage = useMemo(() => parentCounterparty && jurisdiction ? 'Click + to add a new Sub Counterparty type' : 'Select a jurisdiction and parent counterparty type to add or edit sub counterparty types', [jurisdiction, parentCounterparty]);
    const subCounterpartyTypeNameExists = useMemo(() => subCounterpartyTypes.some(({ subCounterpartyName }) => subCounterpartyName === selectedSubCounterparty?.subCounterpartyName), [subCounterpartyTypes, selectedSubCounterparty]);
    const saveNewDisabled = useMemo(() => subCounterpartyTypeNameExists || !!subCounterpartyId || newSubCounterpartyNameValue.length === 0 || !parentCounterparty, [subCounterpartyTypeNameExists, subCounterpartyId, newSubCounterpartyNameValue, parentCounterparty]);
    const userDoesNotHaveSystemPrivileges = useMemo(() => !!selectedSubCounterparty?.isSystem && !userHasIndustryStandardPermission, [selectedSubCounterparty, userHasIndustryStandardPermission]);
    const saveExistingValueDisabled = useMemo(() => subCounterpartyNameValue.length === 0, [subCounterpartyNameValue]);
    const isSystemSubCounterparty = useMemo(() => !!selectedSubCounterparty?.isSystem || false, [selectedSubCounterparty]);

    useEffect(() => {
        if (userHasIndustryStandardPermission && !subCounterpartyId) {
            updateCounterpartyValue('isSystem', 1);
        }
    }, [updateCounterpartyValue, subCounterpartyId, userHasIndustryStandardPermission]);

    const updateJurisdiction = useCallback((option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = (option as DropdownOption).value;
        } else {
            dispatch(fetchOpinionSubCounterpartyTypesSuccessful([]));
            dispatch(selectSubCounterpartyTypesParent(''));
        }
        setJurisdiction(value);
    }, [dispatch, setJurisdiction]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter' && !saveNewDisabled) {
            saveOpinionSubCounterpartyType();
        }
    };

    useEffect(() => {
        if (!jurisdiction || !parentCounterparty) {
            dispatch(fetchOpinionSubCounterpartyTypesSuccessful([]));
        }
    }, [dispatch, parentCounterparty, jurisdiction, setSelectedSubCounterparty]);

    useEffect(() => () => {
        updateCounterpartyValue('subCounterpartyName', '');
    }, [updateCounterpartyValue]);

    const tooltipContent = useMemo(() => {
        if (userDoesNotHaveSystemPrivileges) {
            return `This is a system Sub Counterparty. You do not have the relevant permissions to update it. ${saveExistingValueDisabled ? 'Also, a Sub Counterparty Type can not be saved without a name.' : ''}`;
        }
        if (saveExistingValueDisabled) {
            return 'A Sub Counterparty Type can not be saved without a name.';
        }
        return '';
    }, [userDoesNotHaveSystemPrivileges, saveExistingValueDisabled]);

    return (
        <div className={styles.subCounterpartyTypesWrapper}>
            <div className={styles.subCounterpartyTypesHeader}>
                <div className={styles.subCounterpartyTypesHeaderTitle} data-testid='sub-counterparties-title'>Sub Counterparty Types</div>
            </div>
            <div className={styles.subCounterpartyTypesMainWrapper}>
                <div className={styles.subCounterpartyTypesSectionWrapper}>
                    <div className={styles.dropdownWrapper}>
                        <Dropdown
                            label='Jurisdiction'
                            options={jurisdictionOptions}
                            value={jurisdictionValue}
                            onChange={updateJurisdiction}
                        />
                    </div>
                    {jurisdiction && <div style={{ height: parentOptionsHeight, marginTop: '20px' }}>
                        <Scrollable>
                            {opinionCounterpartyTypeOptions.map((option, index) => <ParentCounterpartyButton
                                key={index}
                                handleClick={() => setParentCounterparty(option)}
                                label={option}
                                selected={option === parentCounterparty}
                            />)}
                        </Scrollable>
                    </div>}
                </div>
                <div className={styles.subCounterpartyTypesSectionWrapper}>
                    {!isNull(jurisdiction) && <div className={styles.newInputWrapper}>
                        <Text
                            testId='new-sub-counterparty'
                            label='New Sub Counterparty Type'
                            value={newSubCounterpartyNameValue}
                            onChange={updateCounterpartyName}
                            onKeyDown={handleKeyDown}
                            disabled={!!subCounterpartyId || !parentCounterparty}
                            marginRight='10px'
                            maxLength={254}
                        />
                        <div className={styles.newInputWrapperButtonWrapper}>
                            <PlusButton disabled={saveNewDisabled} onClick={saveOpinionSubCounterpartyType} />
                        </div>
                        {userHasIndustryStandardPermission && <div className={styles.newInputWrapperToggleWrapper}>
                            <div>System Sub Counterparty?</div>
                            <Toggle checked={isSystemSubCounterparty} onChange={checked => updateCounterpartyValue('isSystem', checked ? 1 : 0)} disabled={!!subCounterpartyId || !parentCounterparty} />
                        </div>}
                    </div>}
                    {(isSaving || isLoading) ? <LoadingDots /> : <div style={{ maxHeight: parentOptionsHeight }} className={styles.subCounterpartyTypesListWrapper}>
                        <Scrollable>
                            {subCounterpartyTypes.map(subCounterparty => {
                                const { subCounterpartyName, subCounterpartyTypeId } = subCounterparty;
                                const selected = subCounterpartyTypeId === selectedSubCounterparty?.subCounterpartyTypeId;
                                return (
                                    <div className={styles.subCounterpartyWrapper} onDoubleClick={() => setSelectedSubCounterparty(subCounterparty)} key={subCounterpartyTypeId}>
                                        <SubCounterpartyTypesInput
                                            label={subCounterpartyName}
                                            selected={selected}
                                            onChange={updateCounterpartyName}
                                            selectedValueName={selectedSubCounterparty?.subCounterpartyName || ''}
                                            disabled={userDoesNotHaveSystemPrivileges}
                                        />
                                        {selected && <>
                                            <IconButton disabled={saveExistingValueDisabled || userDoesNotHaveSystemPrivileges} onClick={saveOpinionSubCounterpartyType} icon={SaveFile} />
                                            <IconButton onClick={() => setSelectedSubCounterparty(initialSubCounterparty)} icon={Delete} />
                                            {(saveExistingValueDisabled || userDoesNotHaveSystemPrivileges) && <InformationTooltip content={tooltipContent} />}
                                        </>}
                                    </div>
                                );
                            })}
                        </Scrollable>
                    </div>
                    }
                    {subCounterpartyTypes.length === 0 && <div className={styles.selectMessage}>{noSubCounterpartyTypesMessage}</div>}
                </div>
            </div>
        </div>
    );
};
