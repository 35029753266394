import React from 'react';
import Modal from 'react-modal';

import { EntityServiceType, ICTServiceTypes } from '../../constants/dora';
import { Button } from '../button/Button';
import { Information } from '../icons';
import styles from './Modal.module.scss';
import { Scrollable } from '../scrollable/Scrollable';
import { ModalHeader } from './ModalHeader';

interface DoraDescriptionModalProps {
    isOpen: boolean;
    toggleDescriptionModal: () => void;
    ictOrEntityTypes: ICTServiceTypes[] | EntityServiceType[];
    label: string;
    zIndex?: number;
}

export const DoraDescriptionModal: React.FC<DoraDescriptionModalProps> = ({ isOpen, toggleDescriptionModal, ictOrEntityTypes, label, zIndex }) => (
    <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={{ overlay: { display: 'flex', zIndex: zIndex } }}
        className={styles.serviceProvidedDescriptionModal}
    >
        <div className={styles.serviceProvidedDescriptionWrapper}>
            <ModalHeader label={label} icon={Information} iconSize={20} />
            <div className={styles.serviceProvidedDescriptionList}>
                <Scrollable>
                    {ictOrEntityTypes.map(({ type, description }: { type: string, description: string }, index: number) => (
                        <div className={styles.list} key={index}>
                            <div className={styles.serviceTypeName}>{type}</div>
                            <div className={styles.serviceTypeDescription}>{description}</div>
                        </div>
                    ))}
                </Scrollable>
            </div>
            <div className={styles.buttonWrapper}>
                <Button label='Close' onClick={toggleDescriptionModal} />
            </div>
        </div>
    </Modal>
);
