import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { MyDatasetsConfigure } from './MyDatasetsConfigure';
import { MyDatasetsList } from './MyDatasetsList';
import { MyDatasetsPreview } from './MyDatasetsPreview';
import { MyDatasetsRiskTolerance } from './MyDatasetsRiskTolerance';
import { fetchAllMyDatasetsStarted, fetchMyDatasetDefinitionsStarted, getAllMyDatasets, getMyDatasetDefinitions, getMyDatasetsView, getSelectedDatasetId, MyDatasetView, resetMyDatasets, setMyDatasetView } from './store';
import { fetchAllDropdownListsStarted } from '../dropdown-lists/store';
import { fetchAllDocumentNamesStarted } from '../documents/store';

export interface MyDatasetsRouteParams {
    datasetId: string | undefined;
}

export const MyDatasets: React.FC<RouteComponentProps<MyDatasetsRouteParams>> = (props) => {
    const dispatch = useAppDispatch();

    useFetchStarted([
        fetchAllMyDatasetsStarted(),
        fetchAllDropdownListsStarted(),
        fetchAllDocumentNamesStarted()
    ]);
    const datasetId = useMemo(() => props.match.params.datasetId ? parseInt(props.match.params.datasetId) : undefined, [props]);

    const myDatasets = useAppSelector(getAllMyDatasets);
    const myDatasetsView = useAppSelector(getMyDatasetsView);
    const parentDatasetId = useAppSelector(getSelectedDatasetId);
    const datasetDefinitions = useAppSelector(getMyDatasetDefinitions);
    const parentDefinition = useMemo(() => datasetDefinitions.map(definition => definition).find(({ datasetId }) => datasetId === parentDatasetId) || null, [datasetDefinitions, parentDatasetId]);

    const datasetIdInParamsExists = useMemo(() => (datasetId && myDatasets.map(({ datasetId }) => datasetId).includes(datasetId)) || false, [myDatasets, datasetId]);
    const urlDatasetView = useMemo(() => {
        if (props.match.url && props.match.url.includes('configure')) {
            return MyDatasetView.CONFIGURE;
        }
        if (props.match.url && props.match.url.includes('preview')) {
            return MyDatasetView.PREVIEW;
        }
        if (props.match.url && props.match.url.includes('risk-tolerance')) {
            return MyDatasetView.RISK_TOLERANCE;
        }
        return MyDatasetView.LIST;
    }, [props]);

    useEffect(() => {
        if (!parentDefinition && datasetIdInParamsExists) {
            dispatch(fetchMyDatasetDefinitionsStarted(datasetId!));
            dispatch(setMyDatasetView(urlDatasetView, datasetId!, false));
        }
    }, [dispatch, datasetIdInParamsExists, urlDatasetView, parentDefinition, datasetId]);

    useEffect(() => () => { dispatch(resetMyDatasets()); }, [dispatch]);

    const pageContent = useMemo(() => {
        switch (myDatasetsView) {
            case MyDatasetView.LIST:
            default:
                return <MyDatasetsList myDatasets={myDatasets} />;
            case MyDatasetView.PREVIEW:
                return <MyDatasetsPreview datasetId={datasetId!} />;
            case MyDatasetView.CONFIGURE:
                return <MyDatasetsConfigure datasetId={datasetId!} />;
            case MyDatasetView.RISK_TOLERANCE:
                return <MyDatasetsRiskTolerance datasetId={datasetId!} />;
        }
    }, [myDatasetsView, myDatasets, datasetId]);

    return (
        <div style={{ height: '100%', width: '100%' }}>{pageContent}</div>
    );
};

