import React, { useCallback, useState } from 'react';

import { fetchDoraFunctionsStarted, fetchFunctionConfigStarted, initialDoraFunction, toggleFunctionConfigModal, toggleSelectedDoraFunctionModal } from './store';
import { DoraFunctionsTable } from './DoraFunctionsTable';
import styles from './DoraFunctions.module.scss';
import { DoraFunctionsModal } from './DoraFunctionModal';
import { PlusButton } from '../../shared/button/PlusButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { IconButton } from '../../shared/button/IconButton';
import { Settings } from '../../shared/icons';
import { Position } from '../../shared/modal/PositionModal';
import { DoraFunctionConfigurationModal } from './DoraFunctionConfigurationModal';
import { DeleteFunctionConfirmationModal } from './DeleteFunctionConfirmationModal';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { DataManagementWrapper } from '../data-management/DataManagementWrapper';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasFeaturePermissionNoAdmin } from '../../auth/login/store';

export const DoraFunctions: React.FC = () => {
    const [position, setPosition] = useState<Position | null>(null);
    const hasDoraFullAccessPermission = useAppSelector(getUserHasFeaturePermissionNoAdmin([FeaturePermission.DORA_FULL_ACCESS]));
    useFetchStarted([fetchDoraFunctionsStarted(1), fetchFunctionConfigStarted()]);

    const dispatch = useAppDispatch();
    const setSelectedDoraFunction = useCallback(() => dispatch(toggleSelectedDoraFunctionModal(initialDoraFunction)), [dispatch]);

    const openConfigModal = useCallback((x: number, y: number) => {
        setPosition({ x, y });
        dispatch(toggleFunctionConfigModal(true));
    }, [dispatch]);

    return (
        <DataManagementWrapper>
            <div className={styles.doraTableWrapper}>
                <div className={styles.doraTableTitleContainer}>
                    <div className={styles.tableTitle} data-testid='dora-functions-table'>DORA Functions</div>
                    {hasDoraFullAccessPermission && <PlusButton onClick={setSelectedDoraFunction} fontSize={22} testId='dora-function' />}
                    <IconButton icon={Settings} onClick={e => openConfigModal(e.clientX, e.clientY)} fontSize={38} testId='dora-function-config-toggle' overlayText='View and configure function definitions' />
                </div>
                <DoraFunctionsTable />
                <DoraFunctionsModal />
                <DoraFunctionConfigurationModal position={position} setPosition={setPosition} />
                <DeleteFunctionConfirmationModal />
            </div>
        </DataManagementWrapper>
    );
};
