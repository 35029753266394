import { action } from 'typesafe-actions';

import { UserAttestationActionTypes, UserAttestationInstanceDB } from './types';
import { ColumnSort } from '../../shared/table/ArkTable';
import { TableFilterType } from '../../shared/modal/TableFilterModal';

export const toggleUserAttestationInstanceView = (completed: boolean, attestationInstanceId?: string) => action(UserAttestationActionTypes.TOGGLE_USER_ATTESTATION_INSTANCE_VIEW, { completed, attestationInstanceId });

export const fetchAllUserAttestationInstancesStarted = (completed: boolean, pageNumber = 1) => action(UserAttestationActionTypes.FETCH_ALL_USER_ATTESTATION_INSTANCES_STARTED, { completed, pageNumber });
export const fetchAllUserAttestationInstancesSuccessful = (attestationInstances: UserAttestationInstanceDB[], total?: number, pageNumber?: number) => action(UserAttestationActionTypes.FETCH_ALL_USER_ATTESTATION_INSTANCES_SUCCESSFUL, { attestationInstances, total, pageNumber });
export const fetchAllUserAttestationInstancesFailed = (error: string) => action(UserAttestationActionTypes.FETCH_ALL_USER_ATTESTATION_INSTANCES_FAILED, error);

export const toggleUserAttestationInstanceModalOpen = (isOpen: boolean, attestationInstance: UserAttestationInstanceDB | null) => action(UserAttestationActionTypes.TOGGLE_USER_ATTESTATION_INSTANCE_MODAL_OPEN, { isOpen, attestationInstance });

export const updateAttestationAnswerValue = (id: string, answer: string | null) => action(UserAttestationActionTypes.UPDATE_ATTESTATION_ANSWER_VALUE, { id, answer });
export const updateAttestationUserNotes = (id: string, notes: string) => action(UserAttestationActionTypes.UPDATE_ATTESTATION_USER_NOTES, { id, notes });

export const setUserAttestationInstanceUpdated = (value: boolean) => action(UserAttestationActionTypes.SET_USER_ATTESTATION_INSTANCE_UPDATED, value);

export const saveUserAttestationInstanceStarted = (userAnswerId: string, isDraft = false) => action(UserAttestationActionTypes.SAVE_USER_ATTESTATION_INSTANCE_STARTED, { userAnswerId, isDraft });
export const saveUserAttestationInstanceSuccessful = (attestationInstances: UserAttestationInstanceDB[], total?: number) => action(UserAttestationActionTypes.SAVE_USER_ATTESTATION_INSTANCE_SUCCESSFUL, { attestationInstances, total });
export const saveUserAttestationInstanceFailed = (error: string) => action(UserAttestationActionTypes.SAVE_USER_ATTESTATION_INSTANCE_FAILED, error);

export const toggleAlertModalOpen = (id: string | null) => action(UserAttestationActionTypes.TOGGLE_ATTESTATION_ALERT_MODAL_OPEN, id);

// Table Filters and Pagination
export const setAttestationTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(UserAttestationActionTypes.SET_ATTESTATION_TABLE_FILTERS, { key, value, type });
export const clearAttestationTableFilters = () => action(UserAttestationActionTypes.CLEAR_ATTESTATION_TABLE_FILTERS);
export const setAttestationTableColumnSort = (columnSort: ColumnSort | undefined) => action(UserAttestationActionTypes.SET_ATTESTATION_TABLE_COLUMN_SORT, columnSort);
export const setAttestationsPageSize = (pageSize: number) => action(UserAttestationActionTypes.SET_ATTESTATIONS_PAGE_SIZE, pageSize);
export const attestationsPaginationNext = () => action(UserAttestationActionTypes.ATTESTATIONS_PAGINATION_NEXT);
export const attestationsPaginationPrevious = () => action(UserAttestationActionTypes.ATTESTATIONS_PAGINATION_PREVIOUS);
