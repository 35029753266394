import { RawDraftContentState } from 'draft-js';
import { action } from 'typesafe-actions';

import { SendNotificationActionTypes } from './types';

export const sendNotificationStarted = () => action(SendNotificationActionTypes.SEND_NOTIFICATION_STARTED);
export const sendNotificationSuccessful = () => action(SendNotificationActionTypes.SEND_NOTIFICATION_SUCCESSFUL);
export const sendNotificationFailed = (e: string) => action(SendNotificationActionTypes.SEND_NOTIFICATION_FAILED, e);

export const updateNotificationTitle = (title: string) => action(SendNotificationActionTypes.UPDATE_NOTIFICATION_TITLE, title);
export const updateNotificationContent = (content: RawDraftContentState | null) => action(SendNotificationActionTypes.UPDATE_NOTIFICATION_CONTENT, content);
export const updateNotificationClientIds = (clientIds: number[]) => action(SendNotificationActionTypes.UPDATE_NOTIFICATION_CLIENT_IDS, clientIds);
export const updateNotificationAdminOnly = (adminOnly: boolean) => action(SendNotificationActionTypes.UPDATE_NOTIFICATION_ADMIN_ONLY, adminOnly);

export const toggleNotificationPreview = (isOpen: boolean) => action(SendNotificationActionTypes.TOGGLE_NOTIFICATION_PREVIEW, isOpen);

export const resetNotification = () => action(SendNotificationActionTypes.RESET_NOTIFICATION_CONTENT);
