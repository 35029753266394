import React, { useMemo, useCallback } from 'react';
import { isNull, noop } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/react-redux';
import { DocumentAnalyticsChart, getDocumentRiskTolerance, getFetchingAnalytics, setPreExecutionDocumentRiskToleranceView, fetchAllDatasetRiskToleranceStarted, getPreExecutionDocumentsRiskTolerance, PreExecutionDocumentRiskView, getPreExecutionDocumentRiskToleranceView } from '../../store';
import { useFetchStarted } from '../../../../../hooks/useFetchStarted';
import { fetchAllDropdownListsStarted } from '../../../../admin/dropdown-lists/store';
import { RiskToleranceChart } from './RiskToleranceChart';

interface PreExecutionDocumentRiskToleranceChartWrapperProps {
    tileInView: null | DocumentAnalyticsChart;
    setTileInView: (tile: DocumentAnalyticsChart | null) => void;
    analyticsSpinner: boolean;
    dimensions?: {
        height: number;
        width: number;
    };
}

export const PreExecutionDocumentRiskToleranceChartWrapper: React.FC<PreExecutionDocumentRiskToleranceChartWrapperProps> = ({ setTileInView, tileInView, analyticsSpinner, dimensions }) => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAllDatasetRiskToleranceStarted(undefined, true), fetchAllDropdownListsStarted()]);

    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const documentRiskTolerance = useAppSelector(getDocumentRiskTolerance);
    const preExecutionDocumentsRiskTolerance = useAppSelector(getPreExecutionDocumentsRiskTolerance);
    const riskToleranceView = useAppSelector(getPreExecutionDocumentRiskToleranceView);
    const chartIsFullScreen = useMemo(() => tileInView === DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE, [tileInView]);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE) || analyticsSpinner, [fetchingAnalytics, analyticsSpinner]);
    const documentTableView = useCallback((view: PreExecutionDocumentRiskView) => chartIsFullScreen && riskToleranceView === view, [chartIsFullScreen, riskToleranceView]);
    const showDocumentTable = useMemo(() => documentTableView(PreExecutionDocumentRiskView.PRE_EXECUTION_DOCUMENTS), [documentTableView]);
    const showDocumentBreakdown = useMemo(() => documentTableView(PreExecutionDocumentRiskView.PRE_EXECUTION_DOCUMENT_BREAKDOWN), [documentTableView]);
    const showDocumentDataOptions = useMemo(() => documentTableView(PreExecutionDocumentRiskView.PRE_EXECUTION_DATASETS), [documentTableView]);
    const showDocumentFieldBreakdown = useMemo(() => documentTableView(PreExecutionDocumentRiskView.PRE_EXECUTION_DATASET_FIELD), [documentTableView]);

    const getDocumentRiskToleranceAnswers = useCallback(() => isNull(documentRiskTolerance) ? noop : dispatch(setPreExecutionDocumentRiskToleranceView(PreExecutionDocumentRiskView.PRE_EXECUTION_DOCUMENT_BREAKDOWN)), [documentRiskTolerance, dispatch]);
    const setDocumentsView = useCallback(() => dispatch(setPreExecutionDocumentRiskToleranceView(PreExecutionDocumentRiskView.PRE_EXECUTION_DOCUMENTS)), [dispatch]);

    const switchRiskTypeLabel = useMemo(() => riskToleranceView !== PreExecutionDocumentRiskView.PRE_EXECUTION_DATASETS ? 'Agreement Type' : 'Document Table', [riskToleranceView]);
    const switchRiskTypeValue = useCallback(() => riskToleranceView === PreExecutionDocumentRiskView.PRE_EXECUTION_DATASETS ? dispatch(setPreExecutionDocumentRiskToleranceView(PreExecutionDocumentRiskView.PRE_EXECUTION_DOCUMENTS)) : dispatch(setPreExecutionDocumentRiskToleranceView((PreExecutionDocumentRiskView.PRE_EXECUTION_DATASETS))), [riskToleranceView, dispatch]);

    return (
        <RiskToleranceChart
            tile={DocumentAnalyticsChart.PRE_EXECUTION_DOCUMENT_RISK_TOLERANCE}
            setTileInView={setTileInView}
            tileInView={tileInView}
            dimensions={dimensions}
            allDocumentsRiskTolerance={preExecutionDocumentsRiskTolerance}
            chartIsFullScreen={chartIsFullScreen}
            showSpinner={showSpinner}
            showDocumentTable={showDocumentTable}
            showDocumentBreakdown={showDocumentBreakdown}
            showDocumentDataOptions={showDocumentDataOptions}
            showDocumentFieldBreakdown={showDocumentFieldBreakdown}
            switchRiskTypeValue={switchRiskTypeValue}
            getDocumentRiskToleranceAnswers={getDocumentRiskToleranceAnswers}
            setDocumentsView={setDocumentsView}
            switchRiskTypeLabel={switchRiskTypeLabel}
            isPreExecution
        />
    );
};
