import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { NAV_BAR } from '../../../../hooks/useSplitView';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { Button } from '../../../shared/button/Button';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { getDefinitionsForDataset, toggleModalDocumentDataset, getDocumentDatasetModal } from '../store';
import styles from './ConfigureDatasets.module.scss';
import { DocumentDatasetDefinitions } from './DocumentDatasetDefinitions';

interface DocumentDatasetModalDefinitionProps {
    documentNames: DropdownOption[];
    isPreview?: boolean;
}

export const DocumentDatasetModalDefinition: React.FC<DocumentDatasetModalDefinitionProps> = ({ documentNames, isPreview = false }) => {
    const dispatch = useAppDispatch();
    const documentDataset = useAppSelector(getDocumentDatasetModal);
    const isOpen = !isNull(documentDataset);
    const closeModal = useCallback(() => dispatch(toggleModalDocumentDataset(null, undefined)), [dispatch]);
    const [screenWidth, screenHeight] = useWindowResize();
    const height = screenHeight - NAV_BAR;
    const datasetDefinitions = useAppSelector(getDefinitionsForDataset);
    const modalDefinition = useMemo(() => datasetDefinitions.find(({ datasetId }) => datasetId === documentDataset?.datasetId) || null, [datasetDefinitions, documentDataset]);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.modal}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: 'flex',
                    zIndex: 10,
                    position: 'absolute',
                    top: `${NAV_BAR}px`,
                    width: `${screenWidth}px`,
                    height: `${height}px`,
                    borderRadius: '5px',
                }
            }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            <div className={styles.modalDefinitionWrapper}>
                {modalDefinition && <DocumentDatasetDefinitions datasetDefinition={modalDefinition} documentNames={documentNames} parents={0} modalInstance isPreview={isPreview} hiddenDocumentNameIds={documentDataset?.hiddenDocumentNameIds} />}
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' />
                </div>
            </div>
        </Modal>
    );
};
