import React from 'react';

export const Form: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,108.7,108.7,114.1,102.1,114.1z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M39.6,42.3H27.7c-1.6,0-2.9-1.3-2.9-2.9V27.5c0-1.6,1.3-2.9,2.9-2.9h11.9c1.6,0,2.9,1.3,2.9,2.9v11.9C42.5,41,41.2,42.3,39.6,42.3z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M98.8,67.4H27.7c-1.6,0-2.9-1.3-2.9-2.9V52.5c0-1.6,1.3-2.9,2.9-2.9h71.1c1.6,0,2.9,1.3,2.9,2.9v11.9C101.7,66,100.4,67.4,98.8,67.4z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M69.2,42.3H57.3c-1.6,0-2.9-1.3-2.9-2.9V27.5c0-1.6,1.3-2.9,2.9-2.9h11.9c1.6,0,2.9,1.3,2.9,2.9v11.9C72.1,41,70.8,42.3,69.2,42.3z" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="83.4" y1="26.9" x2="101.7" y2="26.9" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="83.4" y1="33.4" x2="101.7" y2="33.4" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' x1="83.4" y1="39.8" x2="101.7" y2="39.8" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M58.3,98.3H28.5c-1.6,0-2.9-1.3-2.9-2.9V83.5c0-1.6,1.3-2.9,2.9-2.9h29.9c1.6,0,2.9,1.3,2.9,2.9v11.9C61.2,97,59.9,98.3,58.3,98.3z" />
        <g>
            <path fill={color || 'currentColor'} d="M106.1,102.4L106.1,102.4c-2.3,1.6-5.5,1-7.1-1.3L86.1,82.5c-1.6-2.3-1-5.5,1.3-7.1l0,0c2.3-1.6,5.5-1,7.1,1.3l12.9,18.6C109,97.6,108.4,100.8,106.1,102.4z" />
            <path fill={color || 'currentColor'} d="M73.4,63.7l1.6,19.5c0.4,4.2,5.2,6.5,8.6,4.1l16.1-11.2c3.5-2.4,3-7.7-0.8-9.5l-17.7-8.3C77.4,56.5,73.1,59.5,73.4,63.7z" />
        </g>
    </svg>
);
