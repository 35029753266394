import { flow, set } from 'lodash/fp';
import { Reducer } from 'redux';
import { v4 } from 'uuid';

import { LoginActionTypes } from '../../auth/login/store';
import { getPrincipalField } from '../../constants/entity';
import { DatasetFieldType } from '../../datasets/store';
import { AnnexDefinitionActionTypes, AnnexDefinitionState, InstanceAnnexDefinition, InstanceAnnexView } from './types';

export const INITIAL_STATE: AnnexDefinitionState = {
    annexDefinitionModalOpen: false,
    annexBuilderModalOpen: false,
    availableFields: [],
    annexDefinition: null,
    isPublishing: false,
    isLoading: false,
    publishError: null,
    fetchError: null,
    publishedAnnexDefinitions: [],
    currentInstanceAnnexDefinitions: [],
    savedInstanceAnnexDefinitions: [],
    instanceAnnexView: InstanceAnnexView.CONFIGURE,
    selectedInstanceIndex: 0,
    instanceAnnexDefinitionsUpdated: false,
    instanceSaving: false
};

export const annexReducer: Reducer<AnnexDefinitionState> = (state = INITIAL_STATE, action): AnnexDefinitionState => {
    switch (action.type) {
        case AnnexDefinitionActionTypes.TOGGLE_ANNEX_DEFINITION_MODAL:
            return set('annexDefinitionModalOpen', action.payload, state);
        case AnnexDefinitionActionTypes.TOGGLE_ANNEX_BUILDER_MODAL:
            return set('annexBuilderModalOpen', action.payload, state);
        case AnnexDefinitionActionTypes.CREATE_NEW_ANNEX_DEFINITION: {
            const { documentNameId } = action.payload;
            return flow(
                set('annexDefinition', { datasetFields: [getPrincipalField()], documentNameId, annexDefinitionTitle: null }),
                set('annexBuilderModalOpen', true)
            )(state);
        }
        case AnnexDefinitionActionTypes.DUPLICATE_ANNEX_DEFINITION: {
            const { documentNameId, datasetFields, annexDefinitionId, annexDefinitionTitle } = action.payload;
            return flow(
                set('annexDefinition', { datasetFields, documentNameId, annexDefinitionId, annexDefinitionTitle }),
                set('annexBuilderModalOpen', true)
            )(state);
        }
        case AnnexDefinitionActionTypes.UPDATE_ANNEX_DATASET_FIELD: {
            const { index, field, value } = action.payload;
            return set(`annexDefinition.datasetFields[${index}][${field}]`, value, state);
        }
        case AnnexDefinitionActionTypes.UPDATE_ANNEX_DATASET_FIELDS:
            return set('annexDefinition.datasetFields', action.payload, state);
        case AnnexDefinitionActionTypes.ADD_ANNEX_DATASET_FIELD: {
            const updatedFields = [...state.annexDefinition!.datasetFields!, { ...action.payload, aliasLabel: '' }];
            return set('annexDefinition.datasetFields', updatedFields, state);
        }
        case AnnexDefinitionActionTypes.ADD_CUSTOM_ANNEX_DATASET_FIELD: {
            const id = v4();
            const newField = {
                id,
                label: '',
                type: DatasetFieldType.TEXT,
                description: '',
                refLabel: '',
                settings: {
                    isOpen: false,
                    mandatory: false,
                    showRef: false,
                    refOpen: false,
                    clauseOpen: false,
                    showClause: false,
                    agencyField: false
                },
                isCustom: true,
                linkedFields: []
            };
            const updatedFields = [...state.annexDefinition!.datasetFields!, newField];
            return set('annexDefinition.datasetFields', updatedFields, state);
        }
        case AnnexDefinitionActionTypes.REMOVE_ANNEX_DATASET_FIELD: {
            const updatedFields = state.annexDefinition!.datasetFields!.filter(({ id }) => id !== action.payload);
            return set('annexDefinition.datasetFields', updatedFields, state);
        }
        case AnnexDefinitionActionTypes.PUBLISH_ANNEX_DEFINITION_STARTED:
            return set('isPublishing', true, state);
        case AnnexDefinitionActionTypes.PUBLISH_ANNEX_DEFINITION_SUCCESSFUL:
            return flow(
                set('isPublishing', false),
                set('annexBuilderModalOpen', false),
                set('annexDefinitionModalOpen', false)
            )(state);
        case AnnexDefinitionActionTypes.PUBLISH_ANNEX_DEFINITION_FAILED:
            return flow(
                set('isPublishing', false),
                set('publishError', action.payload)
            )(state);
        case AnnexDefinitionActionTypes.FETCH_ALL_ANNEX_DEFINITIONS_FAILED:
            return set('fetchError', action.payload, state);
        case AnnexDefinitionActionTypes.FETCH_ALL_ANNEX_DEFINITIONS_SUCCESSFUL:
            return set('publishedAnnexDefinitions', action.payload, state);
        case AnnexDefinitionActionTypes.FETCH_AGENCY_FIELDS_STARTED:
            return set('isLoading', true, state);
        case AnnexDefinitionActionTypes.FETCH_AGENCY_FIELDS_SUCCESSFUL:
            return flow(
                set('isLoading', false),
                set('availableFields', action.payload)
            )(state);
        case AnnexDefinitionActionTypes.FETCH_AGENCY_FIELDS_FAILED:
            return flow(
                set('isLoading', false),
                set('fetchError', action.payload)
            )(state);
        case AnnexDefinitionActionTypes.UPDATE_ANNEX_TITLE:
            return set('annexDefinition.annexDefinitionTitle', action.payload, state);
        case AnnexDefinitionActionTypes.SET_INSTANCE_ANNEX_DEFINITIONS:
            return flow(
                set('savedInstanceAnnexDefinitions', action.payload),
                set('currentInstanceAnnexDefinitions', action.payload)
            )(state);
        case AnnexDefinitionActionTypes.SET_INSTANCE_ANNEX_VIEW:
            return set('instanceAnnexView', action.payload, state);
        case AnnexDefinitionActionTypes.SET_SELECTED_INSTANCE_INDEX:
            return set('selectedInstanceIndex', action.payload, state);
        case AnnexDefinitionActionTypes.SET_INSTANCE_ANNEX_DEFINITIONS_UPDATED:
            return set('instanceAnnexDefinitionsUpdated', action.payload, state);
        case AnnexDefinitionActionTypes.UPDATE_INSTANCE_ANNEX_FIELD: {
            const { value, index, field } = action.payload;
            return set(`currentInstanceAnnexDefinitions[${index}][${field}]`, value, state);
        }
        case AnnexDefinitionActionTypes.ADD_INSTANCE_ANNEX_DEFINITION: {
            const currentInstanceAnnexDefinitions = state.currentInstanceAnnexDefinitions;
            const newInstanceAnnex: InstanceAnnexDefinition = { annexDefinitionId: null, fieldId: `annex-field-${v4()}`, label: `Annex ${currentInstanceAnnexDefinitions.length + 1}`, startPage: null, endPage: null, extractedData: null, extractionInProgress: 0 };
            const instanceAnnexDefinitions = [...currentInstanceAnnexDefinitions, newInstanceAnnex];
            return flow(
                set('currentInstanceAnnexDefinitions', instanceAnnexDefinitions),
                set('selectedInstanceIndex', currentInstanceAnnexDefinitions.length),
                set('instanceAnnexView', InstanceAnnexView.SELECT)
            )(state);
        }
        case AnnexDefinitionActionTypes.REMOVE_INSTANCE_ANNEX_DEFINITION: {
            const instanceAnnexDefinitions = state.currentInstanceAnnexDefinitions.filter((_, index) => index !== action.payload);
            return set('currentInstanceAnnexDefinitions', instanceAnnexDefinitions, state);
        }
        case AnnexDefinitionActionTypes.UPSERT_ANNEX_INSTANCES_STARTED:
            return set('instanceSaving', true, state);
        case AnnexDefinitionActionTypes.UPSERT_ANNEX_INSTANCES_SUCCESSFUL:
            return set('instanceSaving', true, state);
        case AnnexDefinitionActionTypes.UPSERT_ANNEX_INSTANCES_FAILED:
            return set('instanceSaving', false, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
