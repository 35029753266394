import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Document } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';
import { deleteSecondaryDocumentStarted, getIsDeleting, getSecondaryDocumentToDelete, toggleDeleteConfirmationModal } from './store';

interface DeleteSecondaryDocumentConfirmationModalProps {
    isOpen: boolean;
}

export const DeleteSecondaryDocumentConfirmationModal: React.FC<DeleteSecondaryDocumentConfirmationModalProps> = ({ isOpen }) => {
    const dispatch = useAppDispatch();
    const secondaryDocument = useAppSelector(getSecondaryDocumentToDelete);
    const isDeleting = useAppSelector(getIsDeleting);
    const deleteSecondaryDocument = () => dispatch(deleteSecondaryDocumentStarted(secondaryDocument!.documentId, secondaryDocument!.datasetInstanceId));
    const closeModal = () => dispatch(toggleDeleteConfirmationModal(null));

    const warning = 'This will permanently delete this document and associated data changes';

    if (!secondaryDocument) {
        return null;
    }

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={deleteSecondaryDocument}
            isDeleting={isDeleting}
            headerLabel='Delete Document'
            name={secondaryDocument.documentDescription}
            testId='document'
            warning={warning}
            icon={Document}
        />
    );
};
