import { Edge, attachClosestEdge, extractClosestEdge, } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import classnames from 'classnames';
import { isUndefined } from 'lodash/fp';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import invariant from 'tiny-invariant';
import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';

import { DraggableProps, DraggableState, draggingState, getItemData, idleState, isItemData, useListContext } from '../shared';
import styles from './Draggable.module.scss';

const { french } = styles;

export const Draggable: React.FC<DraggableProps> = ({ item, index, getChildElement, type = 'standard', getDraggingElement, edgeColour = french, edgeWidth = 1 }) => {
    const { registerItem, instanceId } = useListContext();
    const ref = useRef<HTMLDivElement>(null);
    const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

    const dragHandleRef = useRef<HTMLDivElement>(null);

    const [draggableState, setDraggableState] = useState<DraggableState>(idleState);

    useEffect(() => {
        const element = ref.current;
        const dragHandle = dragHandleRef.current;
        invariant(element);
        invariant(dragHandle);

        const data = getItemData({ item, index, instanceId, type });

        return combine(
            registerItem({ itemId: item.id, element, type }),
            draggable({
                element: dragHandle,
                getInitialData: () => data,
                onGenerateDragPreview: ({ nativeSetDragImage, location }) => {
                    setCustomNativeDragPreview({
                        nativeSetDragImage,
                        getOffset: preserveOffsetOnSource({
                            element,
                            input: location.current.input,
                        }),
                        render({ container }) {
                            setDraggableState({ type: 'preview', container });

                            return () => setDraggableState(draggingState);
                        },
                    });
                },
                onDragStart: () => {
                    setDraggableState(draggingState);
                },
                onDrop: () => {
                    setDraggableState(idleState);
                },
            }),
            dropTargetForElements({
                element,
                canDrop: ({ source }) => isItemData(source.data) && source.data.instanceId === instanceId,
                getData: ({ input }) => attachClosestEdge(data, {
                    element,
                    input,
                    allowedEdges: ['top', 'bottom'],
                }),
                onDrag: ({ self, source, location }) => {
                    const isSource = source.element === element;
                    if (isSource) {
                        setClosestEdge(null);
                        return;
                    }

                    const closestEdge = extractClosestEdge(self.data);
                    const dropTargetsIncludesSubGroup = location.current.dropTargets.map(({ data }) => data.type).some(type => type !== 'standard');

                    const sourceIndex = source.data.index;
                    invariant(typeof sourceIndex === 'number');

                    const isItemBeforeSource = index === sourceIndex - 1;
                    const isItemAfterSource = index === sourceIndex + 1;

                    const isDropIndicatorHidden =
                        (isItemBeforeSource && closestEdge === 'bottom') ||
                        (isItemAfterSource && closestEdge === 'top') ||
                        (dropTargetsIncludesSubGroup && self.data.type === 'standard');

                    if (isDropIndicatorHidden) {
                        setClosestEdge(null);
                        return;
                    }

                    setClosestEdge(closestEdge);
                },
                onDragLeave: () => {
                    setClosestEdge(null);
                },
                onDrop: () => {
                    setClosestEdge(null);
                },
            }),
        );
    }, [instanceId, item, index, registerItem, type]);

    const draggingElement = useMemo(() => {
        if (!isUndefined(getDraggingElement)) {
            return getDraggingElement(item.id, index, item.type);
        }
        return <div className={styles.draggingItem}>{item.label}</div>;
    }, [getDraggingElement, item, index]);

    const edgeStyle = useMemo(() => ({ backgroundColor: edgeColour, height: `${edgeWidth}px` }), [edgeColour, edgeWidth]);

    return (
        <>
            <div
                className={classnames(styles.listItemWrapper, { [styles.disabledDragging]: draggableState.type === 'dragging' })}
                ref={ref}
            >
                {closestEdge && closestEdge === 'top' && <div className={styles.edgeIndicator} style={edgeStyle} />}
                <div ref={dragHandleRef} className={styles.dragButton}>
                    {getChildElement(item.id, index, item.type)}
                </div>
                {closestEdge && closestEdge === 'bottom' && <div className={styles.edgeIndicator} style={edgeStyle} />}
            </div>
            {draggableState.type === 'preview' &&
                createPortal(
                    draggingElement,
                    draggableState.container
                )}
        </>
    );
};
