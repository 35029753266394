import React, { useMemo } from 'react';

import { useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { Group, OfficeBuilding, User } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { fetchAllDropdownListsStarted } from '../dropdown-lists/store';
import styles from './Entity.module.scss';
import { EntityTypeSelector } from './EntityTypeSelector';
import { CompanyEntityForm } from './entityTypes/CompanyEntityForm';
import { GroupEntityForm } from './entityTypes/GroupEntityForm';
import { PersonEntityForm } from './entityTypes/PersonEntityForm';
import { CompanyEntity, EntityType, getCurrentEntity, getIsSaving, getSelectedCompanySection, GroupEntity, PersonEntity } from './store';

interface EntityModalProps {
    isOpen: boolean;
    closeModal: () => void;
    uploadEntity: () => void;
}

export const EntityModal: React.FC<EntityModalProps> = ({ isOpen, closeModal, uploadEntity }) => {
    const entity = useAppSelector(getCurrentEntity);
    const selectedSection = useAppSelector(getSelectedCompanySection);
    const isSaving = useAppSelector(getIsSaving);
    const entityType = entity && entity.type;

    const confirmLabel = useMemo(() => {
        if (isSaving) {
            return '';
        }
        return entity && entity.entityId ? 'Update' : 'Create';
    }, [isSaving, entity]);

    const modalTitle = entity && entity.entityId ? 'Update Entity' : 'Create a New Entity';
    const confirmDisabled = useMemo(() => (entity && !entity.name) || isSaving, [entity, isSaving]);
    const confirmDisabledTooltip = useMemo(() => {
        if (entity && !entity.name) {
            return ['All entities require a name'];
        }
        return isSaving ? ['Saving your entity'] : [];
    }, [entity, isSaving]);

    const getCreateEntityFormByType = () => {
        switch (entityType) {
            case EntityType.COMPANY:
                return <CompanyEntityForm entity={entity as CompanyEntity} selectedSection={selectedSection} />;
            case EntityType.PERSON:
                return <PersonEntityForm entity={entity as PersonEntity} />;
            case EntityType.GROUP:
                return <GroupEntityForm entity={entity as GroupEntity} />;
            default:
                return <EntityTypeSelector />;
        }
    };

    const getHeaderIcon = () => {
        switch (entityType) {
            case EntityType.COMPANY:
                return OfficeBuilding;
            case EntityType.GROUP:
                return Group;
            case EntityType.PERSON:
            default:
                return User;
        }
    };

    useFetchStarted([fetchAllDropdownListsStarted()]);

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel={confirmLabel}
            confirmDisabled={confirmDisabled}
            confirm={uploadEntity}
            testId='entity'
            confirmDisabledTooltip={confirmDisabledTooltip}
            showConfirmSpinner={isSaving}
        >
            <div className={styles.entityModalWrapper} data-testid='entity-modal-wrapper'>
                <ModalHeader
                    label={modalTitle}
                    testId='entity-modal'
                    icon={getHeaderIcon()}
                    marginBottom='0px'
                />
                {getCreateEntityFormByType()}
            </div>
        </ConfirmationModal>
    );
};
