import React, { useMemo, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { isNull, last } from 'lodash/fp';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { getLinkedDocuments, getOriginalDocument, SecondaryUploadDocument, uploadDocumentUpdateValue } from '../store';
import { Text } from '../../../shared/text/Text';
import { DatePicker } from '../../../shared/datepicker/DatePicker';
import styles from './UploadDocument.module.scss';
import { DATABASE_DATE_FORMAT, formatDate, futureDate } from '../../../../utils/luxon';
import { Icon } from '../../../shared/icon/Icon';
import { Document } from '../../../shared/icons';
import { Dropdown, DropdownOption } from '../../../shared/dropdown/Dropdown';

interface SecondaryDocumentProps {
    uploadDocument: SecondaryUploadDocument;
    disabled?: boolean;
}

export const SecondaryDocument: React.FC<SecondaryDocumentProps> = ({ disabled = false, uploadDocument }) => {
    const dispatch = useAppDispatch();
    const { description, executedDate, file, amendmentLinkedDocumentIds, datedAsOf } = uploadDocument;

    const setDocumentDescription = (description: string) => dispatch(uploadDocumentUpdateValue('description', description));

    const originalDocument = useAppSelector(getOriginalDocument);
    const linkedDocuments = useAppSelector(getLinkedDocuments);

    const nonLegacyLinkedDocuments = useMemo(() => linkedDocuments ? linkedDocuments.filter(({ isLegacy }) => !isLegacy) : null, [linkedDocuments]);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({ disabled, maxFiles: 1, maxSize: 10485760, accept: ['.pdf', '.doc', '.docx', '.tif'] });

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length) {
            dispatch(uploadDocumentUpdateValue('file', acceptedFiles));
        }
    }, [acceptedFiles, dispatch]);

    const documentToUpload = file ? `${file[0].name} - ${(file[0].size / 1e6).toFixed(2)}MB` : 'None';
    const dropzoneText = useMemo(() => {
        if (isDragActive) {
            return 'Drop document here';
        }
        if (acceptedFiles.length) {
            return 'Document successfully added, click here or drop a new document to override';
        }
        return 'Drag \'n\' drop documents here, or click to select files';
    }, [isDragActive, acceptedFiles]);

    const setAmendmentLinkedDocumentIds = (value: DropdownOption | Options<DropdownOption> | null) => {
        let linkedDocumentIds = null;
        if (!isNull(value)) {
            linkedDocumentIds = (value as Options<DropdownOption>).map(({ value }) => parseInt(value));
        }
        dispatch(uploadDocumentUpdateValue('amendmentLinkedDocumentIds', linkedDocumentIds));
    };

    const selectedLinkedDocuments = useMemo(() => {
        if (amendmentLinkedDocumentIds) {
            return nonLegacyLinkedDocuments!.filter(({ documentId }) => amendmentLinkedDocumentIds.includes(documentId)).map(({ documentId, documentDescription }) => ({ value: documentId.toString(), label: documentDescription }));
        }
        return null;
    }, [amendmentLinkedDocumentIds, nonLegacyLinkedDocuments]);

    const linkedDocumentsList = (!isNull(nonLegacyLinkedDocuments) && nonLegacyLinkedDocuments.length > 0) ? nonLegacyLinkedDocuments.map(({ documentDescription, documentId }) => ({ label: documentDescription, value: documentId.toString() })) : [];

    const originalDocumentDescription = useMemo(() => originalDocument ? originalDocument.documentDescription : '', [originalDocument]);

    const minDate = useMemo(() => {
        if (amendmentLinkedDocumentIds) {
            const linkedDocumentsExecutionDates = nonLegacyLinkedDocuments!.filter(({ documentId }) => amendmentLinkedDocumentIds!.includes(documentId)).map(({ executedDate }) => executedDate);
            const latest = last(linkedDocumentsExecutionDates.sort((a, b) => Date.parse(a) - Date.parse(b)))!;
            return new Date(futureDate({ days: 1 }, latest));
        }
        return originalDocument && originalDocument.executedDate ? new Date(futureDate({ days: 1 }, originalDocument.executedDate)) : undefined;
    }, [originalDocument, amendmentLinkedDocumentIds, nonLegacyLinkedDocuments]);

    const minDatedAsOf = useMemo(() => originalDocument && originalDocument.datedAsOf ? new Date(futureDate({ days: 1 }, originalDocument.datedAsOf)) : undefined, [originalDocument]);
    const dateValue = useMemo(() => executedDate ? new Date(executedDate) : null, [executedDate]);
    const datedAsOfValue = useMemo(() => datedAsOf ? new Date(datedAsOf) : null, [datedAsOf]);

    const setExecutedDate = useCallback((value: Date | string | null) => {
        const executedDate = value ? formatDate(value, DATABASE_DATE_FORMAT) : null;
        dispatch(uploadDocumentUpdateValue('executedDate', executedDate));
    }, [dispatch]);

    const setDatedAsOf = useCallback((value: Date | string | null) => {
        const datedAsOf = value ? formatDate(value, DATABASE_DATE_FORMAT) : null;
        dispatch(uploadDocumentUpdateValue('datedAsOf', datedAsOf));
    }, [dispatch]);

    useEffect(() => {
        if (amendmentLinkedDocumentIds) {
            setExecutedDate(minDate || null);
        }
    }, [setExecutedDate, amendmentLinkedDocumentIds, minDate]);

    return (
        <>
            <Text
                label='Document Description'
                onChange={e => setDocumentDescription(e.target.value)}
                value={description}
                maxLength={256}
                testId='upload-document-modal-description'
            />
            <div {...getRootProps({ className: classnames(styles.dropzone, { [styles.dropzoneDisabled]: disabled }) })}>
                <input {...getInputProps()} />
                <div className={styles.dropzonePlaceholder}>{dropzoneText}</div>
            </div>
            {!isNull(nonLegacyLinkedDocuments) && nonLegacyLinkedDocuments.length > 0 &&
                <div className={styles.linkedDocumentsWrapper}>
                    <div className={styles.linkedDocumentsHeader}>To which other document(s) does this amendment apply (optional)?</div>
                    <Dropdown
                        testId='secondary-documents-linked-documents'
                        onChange={val => setAmendmentLinkedDocumentIds(val)}
                        value={selectedLinkedDocuments}
                        options={linkedDocumentsList}
                        isClearable={true}
                        disabled={false}
                        placeholder='Select...'
                        isMulti
                    />
                </div>
            }
            <div className={styles.selectedDocumentWrapper}>
                <div className={styles.selectedDocumentHeader}>Document to be uploaded</div>
                <div className={styles.selectedDocument}>{documentToUpload}</div>
            </div>
            <div className={styles.originalDocumentHeader}>
                <Icon icon={Document} />
                <div data-testid='secondary-document-link' className={styles.originalDocumentTitle}>
                    {originalDocumentDescription}
                </div>
            </div>
            <div className={styles.dateWrapper} data-testid='document-date-wrapper'>
                <div data-testid='date-of-execution-wrapper' className={styles.executedDateWrapper}>
                    <div className={styles.executedDateHeader}>Date of Execution</div>
                    <DatePicker
                        value={dateValue}
                        onChange={setExecutedDate}
                        testId='upload-document-executed-date'
                        maxDate={new Date()}
                        minDate={minDate}
                    />
                </div>
                <div data-testid='dated-as-of-wrapper' className={styles.datedAsOfWrapper} style={{ paddingLeft: '20px' }}>
                    <div className={styles.datedAsOfHeader}>Dated as of</div>
                    <DatePicker
                        value={datedAsOfValue}
                        onChange={setDatedAsOf}
                        testId='upload-document-dated-as-of'
                        maxDate={new Date()}
                        minDate={minDatedAsOf}
                    />
                </div>
            </div>
        </>
    );
};
