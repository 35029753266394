import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Spinner } from '../../../shared/spinner/Spinner';
import styles from '../Reports.module.scss';
import { OpinionReportFilter as OpinionReportFilterType, getIsGeneratingReport, getReportFilters, updateOpinionReportFilter } from '../store';
import { OpinionReportFilter } from './OpinionReportFilter';

export const ReportFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const isGeneratingReport = useAppSelector(getIsGeneratingReport);
    const reportFilters = useAppSelector(getReportFilters);

    const updateOpinionFilter = useCallback((key: keyof OpinionReportFilterType, value: string[] | null | boolean) => dispatch(updateOpinionReportFilter(key, value)), [dispatch]);

    const content = useMemo(() => {
        if (isGeneratingReport) {
            return (<Spinner />);
        }

        return <OpinionReportFilter reportFilters={reportFilters as OpinionReportFilterType} updateFilter={updateOpinionFilter} />;
    }, [isGeneratingReport, updateOpinionFilter, reportFilters]);

    return (
        <div className={styles.reportFilterWrapper}>
            {content}
        </div>
    );
};

