import React from 'react';

import { Icon } from '../icon/Icon';
import styles from './Button.module.scss';

interface PageToggleButtonProps {
    label: string;
    onClick: () => void;
    icon: React.FC;
    testId: string;
}

export const PageToggleButton: React.FC<PageToggleButtonProps> = ({ testId, icon, onClick, label }) => (
    <div className={styles.pageToggleButtonWrapper} data-testid={`button-page-toggle-${testId}`} onClick={onClick}>
        <div className={styles.pageToggleButtonLabel} data-testid={`label-page-toggle-${testId}`}>{label}</div>
        <Icon icon={icon} fontSize={22} />
    </div>
);
