import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Toggle } from '../../../shared/toggle';
import { getAllDocumentNames } from '../../documents/store';
import styles from '../Clients.module.scss';
import { Client, ClientOpinion, ClientSection } from '../store';

const { white, grey } = styles;

interface ClientDocumentsProps {
    clientDatasetIds: number[];
    toggleSection: (section: ClientSection) => void;
    getSectionOpen: (section: ClientSection) => boolean;
    updateClientProperties: (key: keyof Client, value: string | number[] | ClientOpinion[]) => void;
}

export const ClientDocuments: React.FC<ClientDocumentsProps> = ({ clientDatasetIds, toggleSection, getSectionOpen, updateClientProperties }) => {

    const allDocumentNames = useAppSelector(getAllDocumentNames);

    const datasetOptions = useMemo(() => allDocumentNames.filter(({ datasetId }) => datasetId), [allDocumentNames]);

    const updateDatasetIds = (checked: boolean, datasetId: number) => {
        const datasetIds = checked ? [...clientDatasetIds, datasetId] : clientDatasetIds.filter(id => id !== datasetId);
        updateClientProperties('clientDatasetIds', datasetIds);
    };

    const totalDocuments = useMemo(() => datasetOptions.filter(({ datasetId }) => datasetId && clientDatasetIds.includes(datasetId)).length.toString(), [datasetOptions, clientDatasetIds]);
    const datasetOptionHeaders = [{ name: 'Document' }, { name: 'Visible' }];

    const datasetsSectionOpen = useMemo(() => getSectionOpen(ClientSection.DATASETS), [getSectionOpen]);
    const getSectionOpenIcon = useCallback((isOpen: boolean) => isOpen ? CaretDown : CaretSide, []);

    return (
        <div className={styles.datasetOptionsWrapper}>
            <div className={styles.datasetsHeader} onClick={() => toggleSection(ClientSection.DATASETS)}>
                <div className={styles.sectionOpenIcon}>
                    <Icon icon={getSectionOpenIcon(datasetsSectionOpen)} fontSize={15} />
                </div>
                <div className={styles.datasetOptionsTitle}>Documents Available to Client ({totalDocuments})</div>
            </div>
            {datasetsSectionOpen &&
                <>
                    <div className={styles.datasetOptionsHeader}>
                        {datasetOptionHeaders.map(({ name }) => (
                            <div key={name}>{name}</div>
                        ))}
                    </div>
                    <div className={styles.datasetOptionsTable}>
                        <Scrollable maxHeight='250px'>
                            {datasetOptions.map(({ datasetId, documentName }, index) => (
                                <div className={styles.datasetOptionRow} key={index} style={{ backgroundColor: index % 2 ? grey : white }}>
                                    <div className={styles.documentName}>{documentName}</div>
                                    <Toggle
                                        checked={clientDatasetIds.includes(datasetId!)}
                                        onChange={checked => updateDatasetIds(checked, datasetId!)}
                                    />
                                </div>
                            ))}
                        </Scrollable>
                    </div>
                </>
            }
        </div>
    );
};
