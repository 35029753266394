import { action } from 'typesafe-actions';
import { RawDraftContentState } from 'draft-js';

import { ColumnSort } from '../../../shared/table/ArkTable';
import { DoraFunctionActionTypes, DoraFunction, DoraConfiguration, DoraFunctionUsage, FunctionToDelete, DoraConfigurationTimeline } from './types';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';

export const fetchDoraFunctionsStarted = (pageNumber = 1) => action(DoraFunctionActionTypes.FETCH_DORA_FUNCTIONS_STARTED, { pageNumber });
export const fetchDoraFunctionsSuccessful = (doraFunctions: DoraFunction[], total: number, pageNumber: number) => action(DoraFunctionActionTypes.FETCH_DORA_FUNCTIONS_SUCCESSFUL, { doraFunctions, total, pageNumber });
export const fetchDoraFunctionsFailed = (error: string) => action(DoraFunctionActionTypes.FETCH_DORA_FUNCTIONS_FAILED, error);

export const toggleSelectedDoraFunctionModal = (doraFunction: DoraFunction | null) => action(DoraFunctionActionTypes.TOGGLE_DORA_FUNCTION_MODAL, doraFunction);

export const updateDoraFunctionValue = (key: keyof DoraFunction, value: string | number) => action(DoraFunctionActionTypes.UPDATE_DORA_FUNCTION_VALUE, { key, value });

export const saveDoraFunctionStarted = () => action(DoraFunctionActionTypes.SAVE_DORA_FUNCTION_STARTED);
export const saveDoraFunctionSuccessful = (doraFunctions: DoraFunction[], total: number, pageNumber: number, functionId: number) => action(DoraFunctionActionTypes.SAVE_DORA_FUNCTION_SUCCESSFUL, { doraFunctions, total, pageNumber, functionId });
export const saveDoraFunctionFailed = (error: string) => action(DoraFunctionActionTypes.SAVE_DORA_FUNCTION_FAILED, error);

export const toggleFunctionConfigModal = (value: boolean) => action(DoraFunctionActionTypes.TOGGLE_FUNCTION_CONFIG_MODAL, value);

export const fetchFunctionConfigStarted = () => action(DoraFunctionActionTypes.FETCH_FUNCTION_CONFIG_STARTED);
export const fetchFunctionConfigSuccessful = (doraConfig: DoraConfiguration | null, timeline: DoraConfigurationTimeline[]) => action(DoraFunctionActionTypes.FETCH_FUNCTION_CONFIG_SUCCESSFUL, { doraConfig, timeline });
export const fetchFunctionConfigFailed = (error: string) => action(DoraFunctionActionTypes.FETCH_FUNCTION_CONFIG_FAILED, error);

export const saveFunctionConfigStarted = (doraConfig: DoraConfiguration) => action(DoraFunctionActionTypes.SAVE_FUNCTION_CONFIG_STARTED, doraConfig);
export const saveFunctionConfigSuccessful = (doraConfig: DoraConfiguration, timeline: DoraConfigurationTimeline[]) => action(DoraFunctionActionTypes.SAVE_FUNCTION_CONFIG_SUCCESSFUL, { doraConfig, timeline });
export const saveFunctionConfigFailed = (error: string) => action(DoraFunctionActionTypes.SAVE_FUNCTION_CONFIG_FAILED, error);

export const updateFunctionConfig = (value: RawDraftContentState | null) => action(DoraFunctionActionTypes.UPDATE_FUNCTION_CONFIG, value);

export const setSelectedFunctionConfig = (config: DoraConfigurationTimeline | null) => action(DoraFunctionActionTypes.SET_SELECTED_FUNCTION_CONFIGURATION, config);

export const toggleDeleteFunctionConfirmationModal = (functionToDelete: FunctionToDelete | null) => action(DoraFunctionActionTypes.TOGGLE_DELETE_FUNCTION_CONFIRMATION_MODAL, functionToDelete);

export const fetchDoraFunctionUsageStarted = (doraFunctionId: number) => action(DoraFunctionActionTypes.FETCH_DORA_FUNCTION_USAGE_STARTED, doraFunctionId);
export const fetchDoraFunctionUsageSuccessful = (doraFunctionUsage: DoraFunctionUsage[]) => action(DoraFunctionActionTypes.FETCH_DORA_FUNCTION_USAGE_SUCCESSFUL, doraFunctionUsage);
export const fetchDoraFunctionUsageFailed = (error: string) => action(DoraFunctionActionTypes.FETCH_DORA_FUNCTION_USAGE_FAILED, error);

export const deleteDoraFunctionStarted = (functionId: number) => action(DoraFunctionActionTypes.DELETE_DORA_FUNCTION_STARTED, functionId);
export const deleteDoraFunctionSuccessful = (doraFunctions: DoraFunction[], total: number, pageNumber: number) => action(DoraFunctionActionTypes.DELETE_DORA_FUNCTION_SUCCESSFUL, { doraFunctions, total, pageNumber });
export const deleteDoraFunctionFailed = (error: string) => action(DoraFunctionActionTypes.DELETE_DORA_FUNCTION_FAILED, error);

// Functions table
export const setFunctionsTableFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => action(DoraFunctionActionTypes.SET_DORA_FUNCTIONS_TABLE_FILTERS, { key, value, type });
export const clearFunctionsTableFilters = () => action(DoraFunctionActionTypes.CLEAR_DORA_FUNCTIONS_TABLE_FILTERS);
export const functionsPaginationNext = () => action(DoraFunctionActionTypes.DORA_FUNCTIONS_PAGINATION_NEXT);
export const functionsPaginationPrevious = () => action(DoraFunctionActionTypes.DORA_FUNCTIONS_PAGINATION_PREVIOUS);
export const setFunctionsTableColumnSort = (columnSort: ColumnSort | undefined) => action(DoraFunctionActionTypes.SET_DORA_FUNCTIONS_TABLE_COLUMN_SORT, columnSort);
export const setFunctionsPageSize = (pageSize: number) => action(DoraFunctionActionTypes.SET_DORA_FUNCTIONS_PAGE_SIZE, pageSize);
