import React from 'react';

import { Button } from '../../../shared/button/Button';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import styles from '../DocumentName.module.scss';

interface DocumentGroupTileProps {
    groupName: string;
    configure: () => void;
    documentNames: string[];
}

export const DocumentGroupTile: React.FC<DocumentGroupTileProps> = ({ groupName, configure, documentNames }) => (
    <div className={styles.tileWrapper}>
        <div className={styles.documentNamesWrapper}>
            <div className={styles.documentNamesHeader}>{groupName}</div>
            <div className={styles.documentNamesList}>
                <Scrollable>
                    {documentNames.map((id, i) => <div className={styles.documentName} key={i}>{id}</div>)}
                </Scrollable>
            </div>
        </div >
        <div className={styles.buttonWrapper}>
            <Button label='Configure' onClick={configure} />
        </div>
    </div>
);
