import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { getAllAttestationForms } from '../../attestations/store';
import { getAllUsers } from '../../users/store';
import { CreateAttestationContent, SchedulerInterval } from '../store';
import styles from '../Workflow.module.scss';

interface ConfirmAttestationProps {
    content: CreateAttestationContent;
    testId: string;
}

export const ConfirmAttestation: React.FC<ConfirmAttestationProps> = ({ content, testId }) => {

    const { attestationFormId, instancesToCreate } = content;
    const attestationForms = useAppSelector(getAllAttestationForms);
    const attestationFormName = useMemo(() => attestationForms.find(attestation => attestation.attestationFormId === attestationFormId)?.name || 'Attestation not found', [attestationFormId, attestationForms]);
    const allUsers = useAppSelector(getAllUsers);

    const getUsernameValue = useCallback((userId: number) => allUsers.find(user => user.userId === userId)?.username || '', [allUsers]);
    const instances = useMemo(() => instancesToCreate.map(instance => ({ ...instance, userIds: instance.userIds.map(user => ({ ...user, username: getUsernameValue(user.userId!) })) })), [getUsernameValue, instancesToCreate]);
    const deadlineValue = useCallback((deadlineInterval: SchedulerInterval | null, deadlineIntervalValue: number | null) => deadlineIntervalValue ? `${deadlineIntervalValue} ${deadlineInterval}` : 'N/A', []);
    return (
        <div className={styles.confirmAttestationWrapper} data-testid={`${testId}-confirm-attestation-form-wrapper`}>
            <div className={styles.attestationTitleWrapper} data-testid={`${testId}-confirm-attestation-form-title-wrapper`}>
                <div className={styles.attestationTitleLabel} data-testid={`${testId}-confirm-attestation-form-title-label`}>Attestation form:</div>
                <div className={styles.attestationTitle} data-testid={`${testId}-confirm-attestation-form-title`}>{attestationFormName}</div>
            </div>
            <div className={styles.attestationInstancesWrapper} data-testid={`${testId}-confirm-attestation-instances-wrapper`}>
                <Scrollable>
                    {instances.map(({ deadlineInterval, deadlineIntervalValue, userIds }, index) => (
                        <div className={styles.attestationInstanceWrapper} key={index} data-testid={`${testId}-confirm-attestation-instance-wrapper`}>
                            <div className={styles.instanceUsers} data-testid={`${testId}-confirm-attestation-instance-users-wrapper`}>
                                <div className={styles.instanceUsersHeader}>
                                    <div className={styles.personToAnswer} data-testid={`${testId}-confirm-attestation-instance-person-to-answer-label`}>Person to answer</div>
                                    <div className={styles.userAssigned} data-testid={`${testId}-confirm-attestation-instance-user-assigned-label`}>User assigned</div>
                                </div>
                                {userIds.map(({ label, username, userAnswerId }, index) => (
                                    <div className={styles.instanceUsersDetails} key={userAnswerId}>
                                        <div className={styles.personToAnswer} data-testid={`${testId}-confirm-attestation-instance-user-answer-label-${index}-${userAnswerId}`}>{label}</div>
                                        <div className={styles.userAssigned} data-testid={`${testId}-confirm-attestation-instance-username-${index}-${userAnswerId}`}>{username}</div>
                                    </div>
                                ))}
                                <div className={styles.deadlineWrapper}>
                                    <div className={styles.deadlineLabel} data-testid={`${testId}-confirm-attestation-instance-deadline-label`}>Deadline: </div>
                                    <div className={styles.deadlineValue} data-testid={`${testId}-confirm-attestation-instance-deadline`}>{deadlineValue(deadlineInterval, deadlineIntervalValue)}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Scrollable>
            </div>
        </div>
    );
};
