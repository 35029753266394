import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { isEqual, isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { addDocumentReportField, DocumentReportField, getAvailableFields, getReportFields, getIsLoading, getReportFieldHierarchy, removeDocumentReportField, DocumentReportHierarchy, updateDocumentReportFields } from '../store';
import { Spinner } from '../../../shared/spinner/Spinner';
import { InformationTooltip } from '../../../shared/tooltip';
import styles from '../Reports.module.scss';
import { DatasetType } from '../../../datasets/store';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Icon } from '../../../shared/icon/Icon';
import { CaretDown, CaretSide } from '../../../shared/icons';
import { Toggle } from '../../../shared/toggle';

const { ocean, amethyst } = styles;

interface ReportFieldItemProps {
    field: DocumentReportField;
    selected: boolean;
    disabled: boolean;
    toggleField: (field: DocumentReportField, deselect: boolean) => void;
}

const isTableDataset = (datasetType: DatasetType) => datasetType === DatasetType.TABLE;

const ReportFieldItem: React.FC<ReportFieldItemProps> = ({ field, selected, toggleField, disabled }) => {
    const { label, description, datasetType } = field;
    return (
        <button onClick={() => toggleField(field, selected)} className={classnames(styles.reportFieldItemWrapper, { [styles.selectedField]: selected })} disabled={disabled}>
            <div className={styles.leftFieldItemWrapper}>
                <div className={classnames(styles.colorIndicator, {
                    [styles.tableField]: isTableDataset(datasetType),
                    [styles.formField]: !isTableDataset(datasetType),
                    [styles.disabledField]: disabled
                })} />
                <div className={styles.reportFieldLabel}>{label}</div>
                {description && <InformationTooltip content={description} />}
            </div>
        </button>
    );
};

interface FieldsByDatasetProps {
    datasetId: number;
    parentFieldId: string;
    getAvailableFieldsByDataset: (datasetId: number, parentFieldId: string) => DocumentReportField[];
    getDatasetsByParentId: (datasetId: number) => DocumentReportHierarchy[];
    toggleField: (field: DocumentReportField, deselect: boolean) => void;
    getIsSelected: (fieldId: string, parentFieldId: string) => boolean;
    getIsDisabled: (datasetId: number, datasetType: DatasetType) => boolean;
    depth: number;
    getIsSectionOpen: (label: string) => boolean;
    toggleSectionOpen: (label: string) => void;
    maxHeight?: string;
    tableDatasetSelected: number | null;
    reportFields: DocumentReportField[];
    toggleAllSectionFields: (datasetId: number, fieldId: string) => void;
    getAllSectionFieldsSelected: (datasetId: number, fieldId: string) => boolean;
}

const FieldsByDataset: React.FC<FieldsByDatasetProps> = ({
    datasetId,
    parentFieldId,
    getAvailableFieldsByDataset,
    getDatasetsByParentId,
    toggleField,
    getIsSelected,
    getIsDisabled,
    depth,
    getIsSectionOpen,
    toggleSectionOpen,
    maxHeight,
    tableDatasetSelected,
    reportFields,
    toggleAllSectionFields,
    getAllSectionFieldsSelected
}) => {
    const sectionOpenIcon = useCallback((label: string) => getIsSectionOpen(label) ? CaretDown : CaretSide, [getIsSectionOpen]);
    const datasetTypeColor = useCallback((type: DatasetType) => type === DatasetType.FORM ? ocean : amethyst, []);

    return (
        <div className={styles.reportFields} style={{ width: `calc(100% - ${depth * 20}px)`, marginLeft: `${depth * 20}px` }}>
            <Scrollable maxHeight={maxHeight}>
                {getAvailableFieldsByDataset(datasetId, parentFieldId).map(field => (
                    <ReportFieldItem
                        key={`${field.parentFieldId}-${field.id}`}
                        field={field}
                        selected={getIsSelected(field.id, parentFieldId)}
                        disabled={getIsDisabled(field.datasetId, field.datasetType)}
                        toggleField={toggleField}
                    />
                ))}
                {getDatasetsByParentId(datasetId).map(({ label, fieldId, datasetId, datasetType }) => {
                    const selectAllDisabled = !isNull(tableDatasetSelected) && datasetType === DatasetType.TABLE && datasetId !== tableDatasetSelected;
                    const allSectionFieldsSelected = getAllSectionFieldsSelected(datasetId, fieldId);
                    return (
                        <div className={styles.datasetWrapper} key={`${parentFieldId}-${fieldId}`}>
                            <div className={styles.sectionHeaderWrapper}>
                                <div className={styles.sectionHeader} onClick={() => toggleSectionOpen(label)}>
                                    <div className={styles.sectionOpenIcon}>
                                        <Icon icon={sectionOpenIcon(label)} fontSize={15} />
                                    </div>
                                    <div className={styles.colorIndicator} style={{ backgroundColor: datasetTypeColor(datasetType) }} />
                                    <div className={styles.sectionLabel}>{label}</div>
                                </div>
                                <div className={styles.selectAllToggleWrapper}>
                                    <div className={styles.selectAllToggleLabel}>{`${allSectionFieldsSelected ? 'Des' : 'S'}elect All`}</div>
                                    <Toggle
                                        checked={allSectionFieldsSelected}
                                        onChange={() => toggleAllSectionFields(datasetId, fieldId)}
                                        disabled={selectAllDisabled}
                                    />
                                </div>
                            </div>
                            {getIsSectionOpen(label) &&
                                <FieldsByDataset
                                    datasetId={datasetId}
                                    parentFieldId={fieldId}
                                    getAvailableFieldsByDataset={getAvailableFieldsByDataset}
                                    getDatasetsByParentId={getDatasetsByParentId}
                                    getIsSelected={getIsSelected}
                                    getIsDisabled={getIsDisabled}
                                    toggleField={toggleField}
                                    depth={depth + 1}
                                    getIsSectionOpen={getIsSectionOpen}
                                    toggleSectionOpen={toggleSectionOpen}
                                    maxHeight='80%'
                                    tableDatasetSelected={tableDatasetSelected}
                                    reportFields={reportFields}
                                    toggleAllSectionFields={toggleAllSectionFields}
                                    getAllSectionFieldsSelected={getAllSectionFieldsSelected}
                                />
                            }
                        </div>
                    );
                })}
            </Scrollable>
        </div>
    );
};

export const DocumentReportFields: React.FC = () => {
    const [openSections, setOpenSections] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const availableFields = useAppSelector(getAvailableFields);
    const hierarchy = useAppSelector(getReportFieldHierarchy);

    // use hierarchy to get the dataset ids then go through available fields and filter those with matching ids
    const reportFields = useAppSelector(getReportFields);
    const isLoading = useAppSelector(getIsLoading);

    const tableDatasetSelected = useMemo(() => reportFields.find(({ datasetType }) => isTableDataset(datasetType))?.datasetId || null, [reportFields]);
    const getIsSelected = useCallback((fieldId: string, parentFieldId: string) => reportFields.map(({ id, parentFieldId }) => ({ fieldId: id, parentFieldId })).some(field => isEqual(field, { parentFieldId, fieldId })), [reportFields]);
    const getIsDisabled = useCallback((datasetId: number, datasetType: DatasetType) => !isNull(tableDatasetSelected) && isTableDataset(datasetType) && datasetId !== tableDatasetSelected, [tableDatasetSelected]);
    const toggleField = useCallback((field: DocumentReportField, deselect: boolean) => deselect ? dispatch(removeDocumentReportField(field.id)) : dispatch(addDocumentReportField(field)), [dispatch]);

    const parentDatasetId = useMemo(() => hierarchy.find(({ parentId }) => isNull(parentId))?.datasetId || 0, [hierarchy]);
    const getAvailableFieldsByDataset = useCallback((datasetId: number, parentFieldId: string) => availableFields.filter(field => field.datasetId === datasetId && field.parentFieldId === parentFieldId).sort((a, b) => a.order! - b.order!), [availableFields]);

    const getDatasetsByParentId = useCallback((datasetId: number) => hierarchy.filter(dataset => dataset.parentId === datasetId).sort((a, b) => a.order - b.order), [hierarchy]);

    const getIsSectionOpen = useCallback((label: string) => openSections.includes(label), [openSections]);
    const toggleSectionOpen = useCallback((label: string) => {
        const isOpen = getIsSectionOpen(label);
        if (isOpen) {
            setOpenSections(openSections.filter(val => val !== label));
        } else {
            setOpenSections([...openSections, label]);
        }
    }, [openSections, getIsSectionOpen]);

    const allFormFields = availableFields.filter(({ datasetType }) => datasetType === DatasetType.FORM);
    const allFieldsAreSelected = useMemo(() => isEqual(reportFields.filter(({ datasetType }) => datasetType === DatasetType.FORM), allFormFields), [reportFields, allFormFields]);
    const strippedFormReportFields = reportFields.filter(({ datasetType }) => datasetType !== DatasetType.FORM);
    const allFields = useMemo(() => [...strippedFormReportFields, ...allFormFields], [strippedFormReportFields, allFormFields]);
    const toggleAllFormFields = useCallback(() => dispatch(updateDocumentReportFields(allFieldsAreSelected ? strippedFormReportFields : allFields)), [dispatch, allFieldsAreSelected, strippedFormReportFields, allFields]);

    const getDatasetChildFields = useCallback((datasetId: number, fieldId: string) => {
        let allDatasetIds: { datasetId: number, fieldId: string }[] = [{ datasetId, fieldId }];
        let parentIds: { datasetId: number, fieldId: string }[] = [{ datasetId, fieldId }];

        while (parentIds.length > 0) {
            let childIds: { datasetId: number, fieldId: string }[] = [];
            parentIds.map(parent => {
                const children = hierarchy.filter(({ datasetType }) => datasetType === DatasetType.FORM).filter(({ parentId }) => parentId === parent.datasetId).map(({ datasetId, fieldId }) => ({ datasetId, fieldId }));
                childIds = [...childIds, ...children];
            });
            allDatasetIds = [...allDatasetIds, ...childIds];
            parentIds = childIds;
        }
        const allChildFields = allDatasetIds.reduce((acc, { datasetId, fieldId }) => {
            const fields = getAvailableFieldsByDataset(datasetId, fieldId);
            acc = [...acc, ...fields];
            return acc;
        }, [] as DocumentReportField[]);
        return allChildFields;
    }, [getAvailableFieldsByDataset, hierarchy]);

    const toggleAllSectionFields = useCallback((datasetId: number, fieldId: string) => {
        const allChildFields = getDatasetChildFields(datasetId, fieldId);
        const filteredChildFields = reportFields.filter(field => !allChildFields.map(({ id }) => id).includes(field.id));
        const allChildFormFieldsAreSelected = allChildFields.length > 0 && allChildFields.every(({ id }) => reportFields.map(({ id }) => id).includes(id));
        const childFields = allChildFormFieldsAreSelected ? filteredChildFields : [...filteredChildFields, ...allChildFields];
        dispatch(updateDocumentReportFields(childFields));
    }, [dispatch, reportFields, getDatasetChildFields]);

    const getAllSectionFieldsSelected = useCallback((datasetId: number, fieldId: string) => {
        const allChildFields = getDatasetChildFields(datasetId, fieldId);
        return allChildFields.length > 0 && allChildFields.every(({ id }) => reportFields.map(({ id }) => id).includes(id));
    }, [getDatasetChildFields, reportFields]);

    return (
        <div className={styles.reportFieldsWrapper}>
            {isLoading ? (<Spinner />) : (
                <>
                    <div className={styles.fieldsHeader}>
                        <div className={styles.fieldsTitle}>Select the fields you would like to include in your report</div>
                        <div className={styles.keyWrapper}>
                            <div className={styles.keyTitle}>Dataset Type Key:</div>
                            <div className={styles.tableColorIndicator} />
                            <div className={styles.keyOption} style={{ marginRight: '10px' }}>Table</div>
                            <div className={styles.formColorIndicator} />
                            <div className={styles.keyOption}>Form</div>
                        </div>
                    </div>
                    <div className={styles.fieldsSubheaderWrapper}>
                        <div className={styles.fieldsSubheader}>If a table dataset field is selected, all fields from other table datasets will be disabled</div>
                        <div className={styles.selectAllToggleWrapper}>
                            <div className={styles.selectAllToggleLabel}>{`${allFieldsAreSelected ? 'Des' : 'S'}elect All Form Fields`}</div>
                            <Toggle
                                checked={allFieldsAreSelected}
                                onChange={toggleAllFormFields}
                            />
                        </div>
                    </div>
                    <div className={styles.allFieldsWrapper}>
                        <Scrollable>
                            <FieldsByDataset
                                datasetId={parentDatasetId}
                                parentFieldId=''
                                getAvailableFieldsByDataset={getAvailableFieldsByDataset}
                                getDatasetsByParentId={getDatasetsByParentId}
                                getIsSelected={getIsSelected}
                                getIsDisabled={getIsDisabled}
                                toggleField={toggleField}
                                depth={0}
                                getIsSectionOpen={getIsSectionOpen}
                                toggleSectionOpen={toggleSectionOpen}
                                tableDatasetSelected={tableDatasetSelected}
                                reportFields={reportFields}
                                toggleAllSectionFields={toggleAllSectionFields}
                                getAllSectionFieldsSelected={getAllSectionFieldsSelected}
                            />
                        </Scrollable>
                    </div>
                </>
            )}
        </div>
    );
};
