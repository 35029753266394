import { combineReducers } from 'redux';

import { MyOpinionsState, myOpinionsReducer } from '../my-opinions/store';
import { OpinionInstanceState, opinionInstanceReducer } from '../instances/store';
import { OpinionReportingState, opinionReportingReducer } from '../reporting/store';
import { OpinionAnalyticsState, opinionAnalyticsReducer } from '../analytics/store';
import { OpinionNettingEngineState, opinionNettingEngineReducer } from '../netting-engine/store';

export interface OpinionState {
    myOpinions: MyOpinionsState;
    reporting: OpinionReportingState;
    instance: OpinionInstanceState;
    analytics: OpinionAnalyticsState;
    nettingEngine: OpinionNettingEngineState;
}

export const opinionReducer = combineReducers({
    myOpinions: myOpinionsReducer,
    reporting: opinionReportingReducer,
    instance: opinionInstanceReducer,
    analytics: opinionAnalyticsReducer,
    nettingEngine: opinionNettingEngineReducer
});
