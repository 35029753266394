import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { RiskTolerance } from '../../shared/risk-tolerance/RiskTolerance';
import { getAllDocumentNames } from '../documents/store';
import { RiskAssociated, RiskAssociatedValue, SelectedRiskField } from '../risk-tolerance/store';
import { MyDatasetsButtons } from './MyDatasetsButtons';
import { getAllMyDatasets, getCurrentRiskField, getCurrentRiskToleranceConfig, getDocumentSpecificHiddenFields, getIsFetchingMyDatasets, getMyDatasetDefinitions, getSavedHiddenFields, getSelectedDatasetId, getSelectedRiskField, setCurrentRiskToleranceField, toggleMyRiskField, updateRiskFieldConfig, updateRiskFieldWeighting } from './store';

export interface MyDatasetsRiskToleranceProps {
    datasetId: number;
}

export const MyDatasetsRiskTolerance: React.FC<MyDatasetsRiskToleranceProps> = ({ datasetId }) => {
    const dispatch = useAppDispatch();
    const myDatasets = useAppSelector(getAllMyDatasets);
    const riskTolerance = useAppSelector(getCurrentRiskToleranceConfig);
    const selectedRiskField = useAppSelector(getCurrentRiskField);
    const selectedField = useAppSelector(getSelectedRiskField);
    const isLoading = useAppSelector(getIsFetchingMyDatasets);
    const datasetDefinitions = useAppSelector(getMyDatasetDefinitions);
    const hiddenFields = useAppSelector(getSavedHiddenFields);
    const documentHiddenFields = useAppSelector(getDocumentSpecificHiddenFields);
    const allDocumentNames = useAppSelector(getAllDocumentNames);
    const parentDatasetId = useAppSelector(getSelectedDatasetId);

    const selectRiskField = useCallback((riskField: SelectedRiskField) => { dispatch(setCurrentRiskToleranceField(riskField)); }, [dispatch]);

    const setWeighting = useCallback((weighting: number) => dispatch(updateRiskFieldWeighting(weighting)), [dispatch]);
    const updateRiskConfig = useCallback((riskAssociated: RiskAssociated, value: RiskAssociatedValue, selectedCurrency?: string) => dispatch(updateRiskFieldConfig(riskAssociated, value, selectedCurrency)), [dispatch]);
    const toggleRiskField = useCallback((datasetId: number, fieldId: string) => dispatch(toggleMyRiskField(datasetId, fieldId)), [dispatch]);

    const buttonWrapper = useMemo(() => <MyDatasetsButtons datasetId={datasetId} />, [datasetId]);
    return (
        <RiskTolerance
            allRiskToleranceDatasets={myDatasets}
            buttonWrapper={buttonWrapper}
            datasetDefinitions={datasetDefinitions}
            datasetId={datasetId}
            isLoading={isLoading}
            riskTolerance={riskTolerance}
            selectedField={selectedField}
            selectedRiskField={selectedRiskField}
            setRiskToleranceField={selectRiskField}
            setWeighting={setWeighting}
            updateRiskConfig={updateRiskConfig}
            hiddenFields={hiddenFields}
            showRiskFieldToggles
            toggleRiskField={toggleRiskField}
            documentHiddenFields={documentHiddenFields}
            allDocumentNames={allDocumentNames}
            agreementTypeDatasetId={parentDatasetId}
        />
    );
};
