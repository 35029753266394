import { flattenDeep, isEqual, isUndefined, set, uniq, unset } from 'lodash/fp';

import { OpenFieldType } from '../../datasets/instances/store';
import { DatasetDefinition, FormDatasetDefinition, FormDatasetFields, SingleDatasetField, TableDatasetDefinition, isFormDatasetDefinition, isGroupField, DatasetType, DatasetFieldType } from '../../datasets/store';
import { HiddenField, HiddenSection } from '../my-datasets/store';
import { DocumentNameDatasets, DocumentSpecificHiddenFields, HiddenDocumentField, HiddenDocumentSection } from './store';

const flattenFormFields = (fields: FormDatasetFields) => flattenDeep(Object.entries(fields).map(([sectionId, section]) => section.map(field => isGroupField(field) ? field.children.map(({ id }) => ({ fieldId: id!, sectionId, type: OpenFieldType.FIELD })) : { fieldId: field.id!, sectionId, type: OpenFieldType.FIELD }))) as HiddenField[];

const flattenTableFields = (fields: SingleDatasetField[]): HiddenField[] => fields.map(({ id }) => ({ fieldId: id!, type: OpenFieldType.FIELD }));

export const getNewFields = (currentDefinition: DatasetDefinition, existingDefinition: DatasetDefinition): (HiddenField | HiddenSection)[] => {
    if (isFormDatasetDefinition(currentDefinition)) {
        const currentSectionIds = currentDefinition.datasetSections.map(({ id }) => id);
        const existingSectionIds = (existingDefinition as FormDatasetDefinition).datasetSections.map(({ id }) => id);
        const newSectionIds = currentSectionIds.filter(id => !existingSectionIds.includes(id));
        const currentFields = flattenFormFields(currentDefinition.datasetFields).filter(({ sectionId }) => existingSectionIds.includes(sectionId!));
        const newSections: HiddenSection[] = newSectionIds.map(sectionId => ({ sectionId, type: OpenFieldType.SECTION }));
        const existingFields = flattenFormFields((existingDefinition as FormDatasetDefinition).datasetFields);
        const newFields = currentFields.filter(fieldSection => isUndefined(existingFields.find(existingFieldSection => isEqual(fieldSection, existingFieldSection))));
        return [...newSections, ...newFields];
    }
    const currentFields = flattenTableFields(currentDefinition.datasetFields);
    const existingFields = flattenTableFields((existingDefinition as TableDatasetDefinition).datasetFields);
    const newFields = currentFields.filter(({ fieldId }) => !existingFields.map(({ fieldId }) => fieldId).includes(fieldId));
    return newFields;
};

export const defaultField: Omit<SingleDatasetField, 'type'> = {
    label: '',
    description: '',
    refLabel: '',
    systemId: '',
    settings: {
        isOpen: false,
        mandatory: false,
        showRef: false,
        refOpen: false,
        clauseOpen: false,
        showClause: false,
        agencyField: false,
        systemIdOpen: false
    },
};

export const stripHiddenDataset = (currentHiddenFields: DocumentSpecificHiddenFields, datasetId: number): DocumentSpecificHiddenFields => unset(datasetId, currentHiddenFields);

export const removeDocumentFromHiddenField = (datasetHiddenFields: (HiddenDocumentField | HiddenDocumentSection)[], documentNameDatasets: DocumentNameDatasets[], configureDatasetParentId: number, hiddenField: HiddenDocumentField, updatedHiddenFields: DocumentSpecificHiddenFields, datasetId: number, hiddenFieldId: string) => {
    const currentDocumentNameIds = (datasetHiddenFields.filter(({ type }) => type === OpenFieldType.FIELD) as HiddenDocumentField[]).find(({ fieldId }) => fieldId === hiddenField.fieldId)!.documentNameIds;
    const newDocumentNameIds = (hiddenField.documentNameIds as number[]);
    const parentDatasetDocumentNameIds = documentNameDatasets!.find(({ datasetId }) => datasetId === configureDatasetParentId)!.documentNames.map(({ documentNameId }) => documentNameId);
    // strip the current of any of the datasetDocumentNameIds, then add in the newDocumentNameIds
    const filteredOfParentDatasetDocumentNameIds = currentDocumentNameIds.reduce((acc: number[], cur) => !parentDatasetDocumentNameIds.includes(cur) ? [...acc, cur] : acc, []);
    const updatedDocumentNameIds = [...filteredOfParentDatasetDocumentNameIds, ...newDocumentNameIds];
    if (!updatedDocumentNameIds.length) {
        // field has no hidden document name ids so remove the field from the list for the dataset
        const filteredFields = (updatedHiddenFields[datasetId] as HiddenDocumentField[]).filter(({ fieldId }) => fieldId !== hiddenFieldId);
        updatedHiddenFields[datasetId] = filteredFields;
    } else {
        // just need to update the documentNameIds for the field in the dataset
        const updatedDatasetFields = (updatedHiddenFields[datasetId] as HiddenDocumentField[]).map(field => {
            if (field.fieldId === hiddenFieldId) {
                return { ...field, documentNameIds: updatedDocumentNameIds };
            }
            return field;
        });
        updatedHiddenFields = { ...updatedHiddenFields, [datasetId]: updatedDatasetFields };
    }
    return updatedHiddenFields;
};

export const removeDocumentFromHiddenSection = (datasetDefinitions: DatasetDefinition[], hiddenField: HiddenDocumentSection, updatedHiddenFields: DocumentSpecificHiddenFields, datasetId: number, hiddenFieldId: string) => {
    const currentHiddenDocumentNameIds = (updatedHiddenFields[datasetId] as HiddenDocumentSection[]).find(field => field.sectionId === hiddenFieldId)!.documentNameIds;
    const fieldsForDatasetId = (datasetDefinitions.filter(({ datasetType }) => datasetType === DatasetType.FORM).find(definition => definition.datasetId === datasetId) as FormDatasetDefinition)?.datasetFields;
    const linkedDatasetIds = fieldsForDatasetId && fieldsForDatasetId[hiddenFieldId].reduce((acc, field) => {
        if (field.type === DatasetFieldType.DATASET && !!field.settings.datasetLinked) {
            acc.push(parseInt(field.settings.datasetLinked));
        }
        return acc;
    }, [] as number[]) || [];
    const documentsToRemove = currentHiddenDocumentNameIds.filter(documentNameId => !hiddenField.documentNameIds.includes(documentNameId));
    const filteredOfParentDatasetDocumentNameIds = currentHiddenDocumentNameIds.reduce((acc: number[], cur) => !documentsToRemove.includes(cur) ? [...acc, cur] : acc, []);
    if (!filteredOfParentDatasetDocumentNameIds.length) {
        // remove the section from the hidden fields
        const filteredSections = (updatedHiddenFields[datasetId] as HiddenDocumentSection[]).filter(({ sectionId }) => sectionId !== hiddenFieldId);
        updatedHiddenFields[datasetId] = filteredSections;
        if (linkedDatasetIds.length > 0) {
            linkedDatasetIds.map(datasetId => {
                if (!isUndefined(updatedHiddenFields[datasetId])) {
                    updatedHiddenFields[datasetId] = updatedHiddenFields[datasetId].map(hiddenField => ({ ...hiddenField, documentNameIds: hiddenField.documentNameIds.filter(documentNameId => !documentsToRemove!.includes(documentNameId)) }));
                }
            });
        }
    } else {
        // just update the document name ids for the section
        const updatedDatasetFields = (updatedHiddenFields[datasetId] as HiddenDocumentSection[]).map(field => {
            if (field.sectionId === hiddenFieldId) {
                return { ...field, documentNameIds: filteredOfParentDatasetDocumentNameIds };
            }
            return field;
        });
        updatedHiddenFields = { ...updatedHiddenFields, [datasetId]: updatedDatasetFields };
    }
    return updatedHiddenFields;
};

export const updateHiddenFieldOrSectionForDataset = (isField: boolean, hiddenFieldId: string, hiddenField: HiddenDocumentField | HiddenDocumentSection, updatedHiddenFields: DocumentSpecificHiddenFields, datasetId: number) => {
    let updatedFieldsForDataset: (HiddenDocumentField | HiddenDocumentSection)[] = [];
    if (isField) {
        const hiddenDocumentField = (updatedHiddenFields[datasetId] as HiddenDocumentField[]).find(({ fieldId, type }) => fieldId === hiddenFieldId && type === OpenFieldType.FIELD);
        if (!isUndefined(hiddenDocumentField)) {
            const updatedDocumentNameIds = uniq([...hiddenDocumentField.documentNameIds, ...hiddenField.documentNameIds]);
            const updatedField = set('documentNameIds', updatedDocumentNameIds, hiddenDocumentField);
            const filteredHiddenFields = (updatedHiddenFields[datasetId] as HiddenDocumentField[]).filter(({ fieldId }) => fieldId !== hiddenFieldId);
            updatedFieldsForDataset = [...filteredHiddenFields, updatedField];
        } else {
            updatedFieldsForDataset = [...updatedHiddenFields[datasetId], hiddenField];
        }
    } else {
        const hiddenDocumentSection = (updatedHiddenFields[datasetId] as HiddenDocumentSection[]).find(({ sectionId, type }) => sectionId === hiddenFieldId && type === OpenFieldType.SECTION);
        if (!isUndefined(hiddenDocumentSection)) {
            const updatedDocumentNameIds = uniq([...hiddenDocumentSection.documentNameIds, ...hiddenField.documentNameIds]);
            const updatedHiddenSection = set('documentNameIds', updatedDocumentNameIds, hiddenDocumentSection);
            const filteredHiddenSections = (updatedHiddenFields[datasetId] as HiddenDocumentSection[]).filter(({ sectionId }) => sectionId !== hiddenFieldId);
            updatedFieldsForDataset = [...filteredHiddenSections, updatedHiddenSection];
        } else {
            updatedFieldsForDataset = [...updatedHiddenFields[datasetId], hiddenField];
        }
    }
    return updatedFieldsForDataset;
};
