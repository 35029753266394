import React, { useCallback, useEffect, useMemo } from 'react';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { io, ManagerOptions, SocketOptions } from 'socket.io-client';

import { fetchAllUsersSuccessful, ClientUser } from './components/admin/users/store';
import { getIsLoggedIn, getSocket, idleCheckToken, logoutStarted, redirectToLogin, setSocketsConnection, setSocketsConnectionFailed, socketLogin, toggleLogoutWarningModal, User, userUpdated } from './components/auth/login/store';
import { ActiveMLDocument, Notification, notificationsReceived, setActiveMLDocuments, setPortfolioStatistics, triggerProgressActiveMLDocuments } from './components/auth/notifications/store';
import { documentAnalysisComplete, documentAnalysisFailed } from './components/documents/my-documents/store';
import { useAppDispatch, useAppSelector } from './hooks/react-redux';
import { apiRoot } from './services/services';
import { setUpdatedOpinionSummaryId } from './components/opinions/my-opinions/store';
import { openDatasetInstanceById } from './components/datasets/instances/store';

export const Sockets: React.FC = () => {
    const dispatch = useAppDispatch();
    const socket = useAppSelector(getSocket);
    const isLoggedIn = useAppSelector(getIsLoggedIn);

    const onIdle = useCallback((event?: Event | undefined, idleTimer?: IIdleTimer | undefined) => {
        if (isLoggedIn && socket) {
            dispatch(idleCheckToken());
            idleTimer?.reset();
        }
    }, [isLoggedIn, socket, dispatch]);

    // Run a timer which checks if user is idle every hour, this triggers a token expiry check and if token is not valid they will be logged out
    useIdleTimer({ onIdle, timeout: 1000 * 60 * 60 });

    useEffect(() => {
        if (socket) {
            /* eslint-disable no-console */
            socket.on('connect', () => console.log('Connected...'));
            socket.on('token-nearing-expiry', () => dispatch(toggleLogoutWarningModal(true)));
            socket.on('token-expired', () => dispatch(logoutStarted()));
            socket.on('password-updated', () => dispatch(logoutStarted()));
            socket.on('user-deleted', () => dispatch(logoutStarted()));
            socket.on('token-valid', (user: User) => dispatch(socketLogin(user)));
            socket.on('token-invalid', () => dispatch(redirectToLogin()));
            socket.on('user-updated', (user: User) => dispatch(userUpdated(user)));
            socket.on('document-analysis-complete', (documentId: number) => dispatch(documentAnalysisComplete(documentId)));
            socket.on('document-analysis-failed', (documentId: number) => dispatch(documentAnalysisFailed(documentId)));
            socket.on('notifications', (notifications: Notification[]) => dispatch(notificationsReceived(notifications)));
            socket.on('all-users', (allUsers: ClientUser[]) => dispatch(fetchAllUsersSuccessful(allUsers)));
            socket.on('opinion-summary-complete', ({ opinionId, opinionSummaryId }: { opinionId: number; opinionSummaryId: number; }) => dispatch(setUpdatedOpinionSummaryId(opinionId, opinionSummaryId)));
            socket.on('ml-progress', (activeMLDocument: ActiveMLDocument) => dispatch(triggerProgressActiveMLDocuments(activeMLDocument, true)));
            socket.on('active-ml-documents', (activeMLDocuments: ActiveMLDocument[]) => dispatch(setActiveMLDocuments(activeMLDocuments)));
            socket.on('portfolio-statistics', (statistics: string[]) => dispatch(setPortfolioStatistics(statistics)));
            socket.on('agency-amendment-complete', ({ originalDocumentId, datasetInstanceId }: { originalDocumentId: number; datasetInstanceId: string; }) => dispatch(openDatasetInstanceById(datasetInstanceId, false, originalDocumentId, true)));
            socket.on('user-logout', () => dispatch(redirectToLogin()));
        }
    }, [socket, dispatch]);

    const options: Partial<SocketOptions & ManagerOptions> = useMemo(() => ({
        auth: {},
        transports: ['websocket'],
        withCredentials: true,
        secure: true,
        rejectUnauthorized: false,
        port: '80'
    }), []);

    useEffect(() => {
        try {
            const socketConnection = io(apiRoot()!, options);
            dispatch(setSocketsConnection(socketConnection));
        } catch (err) {
            const error = err as Error;
            dispatch(setSocketsConnectionFailed(error.message));
        }
    }, [options, dispatch]);

    return <div />;
};
