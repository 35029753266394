import React from 'react';

interface CustomIconProps {
    icon: React.ElementType
    color?: string;
    fontSize?: number;
    fill?: string;
}

export const Icon: React.FC<CustomIconProps> = ({ fontSize = '28', color, icon: Component, ...props }) => (
    <div style={{ fontSize: `${fontSize}px`, display: 'flex', color: `${color}` }}>
        <Component {...props} />
    </div>
);
