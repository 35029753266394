import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { LinkDocumentTableModal } from '../../shared/modal/LinkDocumentTableModal';
import { documentColDefs } from '../../shared/table';
import { ColumnSort } from '../../shared/table/ArkTable';
import {
    clearOriginalDocumentTableFilters,
    getIsLoading,
    getOriginalDocumentColumnSort,
    getOriginalDocumentFilters,
    getOriginalDocumentPageSize,
    getOriginalDocuments,
    getOriginalDocumentsPageNumber,
    getTotalOriginalDocuments,
    originalDocumentsPaginationNext,
    originalDocumentsPaginationPrevious,
    setOriginalDocumentTableColumnSort,
    setOriginalDocumentTableFilters,
    setOriginalDocumentsPageSize
} from './store';
import { TableFilterType } from '../../shared/modal/TableFilterModal';

interface OriginalDocumentsModalProps {
    selectedId: number | null;
    select: (value: number) => void;
    close: () => void;
}

export const OriginalDocumentsModal: React.FC<OriginalDocumentsModalProps> = ({ selectedId, select, close }) => {
    const dispatch = useAppDispatch();
    const documents = useAppSelector(getOriginalDocuments);
    const isLoading = useAppSelector(getIsLoading);

    const pageNumber = useAppSelector(getOriginalDocumentsPageNumber);
    const totalDocuments = useAppSelector(getTotalOriginalDocuments);
    const filters = useAppSelector(getOriginalDocumentFilters);
    const columnSort = useAppSelector(getOriginalDocumentColumnSort);
    const pageSize = useAppSelector(getOriginalDocumentPageSize);
    const nextPage = useCallback(() => { dispatch(originalDocumentsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(originalDocumentsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setOriginalDocumentTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearOriginalDocumentTableFilters()); }, [dispatch]);
    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setOriginalDocumentTableColumnSort(columnSort)); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setOriginalDocumentsPageSize(pageSize)); }, [dispatch]);

    const selectedIds = useMemo(() => selectedId ? [selectedId] : null, [selectedId]);

    return (
        <LinkDocumentTableModal
            isOpen
            select={select}
            close={close}
            documents={documents}
            selectedIds={selectedIds}
            selectedField='documentId'
            columnDefs={documentColDefs}
            testId='upload-original-documents'
            title='Existing Original Documents'
            page={pageNumber}
            total={totalDocuments}
            filters={filters}
            next={nextPage}
            previous={previousPage}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            isLoading={isLoading}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
        />
    );
};
