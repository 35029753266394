import { RawDraftContentState } from 'draft-js';
import { action } from 'typesafe-actions';

import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilterType } from '../../../shared/modal/TableFilterModal';
import { DetailsDocument, DoraDocumentPreview, DoraFunctionCompany, DoraSupplyChainActionTypes, DoraSupplyChainAssessmentDetails, DoraSupplyChainAssessmentTimeline, DoraSupplyChainDetails, IctContractDisplay, IctSubstitutabilityOptions, NewSupplyChainElement, SubstitutabilityReasoning, SupplyChain, SupplyChainElement, SupplyChainElementDetails, SupplyChainElementTab } from './types';

export const fetchAllFunctionCompanyDetailsStarted = () => action(DoraSupplyChainActionTypes.FETCH_ALL_FUNCTION_COMPANY_DETAILS_STARTED);
export const fetchAllFunctionCompanyDetailsSuccessful = (doraFunctionCompanies: DoraFunctionCompany[] | null) => action(DoraSupplyChainActionTypes.FETCH_ALL_FUNCTION_COMPANY_DETAILS_SUCCESSFUL, doraFunctionCompanies);
export const fetchAllFunctionCompanyDetailsFailed = (error: string) => action(DoraSupplyChainActionTypes.FETCH_ALL_FUNCTION_COMPANY_DETAILS_FAILED, error);

export const setSelectedCompanyId = (companyId: number | null) => action(DoraSupplyChainActionTypes.SET_SELECTED_COMPANY_ID, companyId);

export const setSelectedFunctionId = (doraFunctionId: number | null) => action(DoraSupplyChainActionTypes.SET_SELECTED_FUNCTION_ID, doraFunctionId);

export const setDroppableHeight = (droppableHeight: number) => action(DoraSupplyChainActionTypes.SET_DROPPABLE_HEIGHT, droppableHeight);
export const toggleSupplyChainElement = (doraSupplyChainId: number | null) => action(DoraSupplyChainActionTypes.TOGGLE_SUPPLY_CHAIN_ELEMENT, doraSupplyChainId);
export const toggleSupplyChainDetailsModalOpen = (isOpen: boolean) => action(DoraSupplyChainActionTypes.TOGGLE_SUPPLY_CHAIN_DETAILS_MODAL_OPEN, isOpen);

export const fetchSupplyChainConfigurationStarted = () => action(DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_CONFIGURATION_STARTED);
export const fetchSupplyChainConfigurationSuccessful = (supplyChain: SupplyChain) => action(DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_CONFIGURATION_SUCCESSFUL, supplyChain);
export const fetchSupplyChainConfigurationFailed = (error: string) => action(DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_CONFIGURATION_FAILED, error);

export const updateSupplyChain = (supplyChain: SupplyChainElement[]) => action(DoraSupplyChainActionTypes.UPDATE_SUPPLY_CHAIN_CONFIGURATION, supplyChain);
export const updateSavedSupplyChain = (supplyChain: SupplyChainElement[]) => action(DoraSupplyChainActionTypes.UPDATE_SAVED_SUPPLY_CHAIN_CONFIGURATION, supplyChain);

export const openDoraSupplyChainFromParams = (doraFunctionCompanyId: number) => action(DoraSupplyChainActionTypes.OPEN_DORA_SUPPLY_CHAIN_FROM_PARAMS, doraFunctionCompanyId);

export const updateNewElement = (key: keyof NewSupplyChainElement, value: null | number) => action(DoraSupplyChainActionTypes.UPDATE_NEW_DORA_SUPPLY_CHAIN_ELEMENT, { key, value });

export const updateElementDetails = (key: keyof DoraSupplyChainDetails, value: null | RawDraftContentState | boolean | string | undefined) => action(DoraSupplyChainActionTypes.UPDATE_DORA_SUPPLY_CHAIN_ELEMENT_DETAILS, { key, value });

export const setSupplyChainElementHasUpdated = (hasUpdated: boolean) => action(DoraSupplyChainActionTypes.SET_SUPPLY_CHAIN_ELEMENT_HAS_UPDATED, hasUpdated);

export const setSupplyChainHasUpdated = (hasUpdated: boolean) => action(DoraSupplyChainActionTypes.SET_SUPPLY_CHAIN_HAS_UPDATED, hasUpdated);

export const fetchAllBaseCompaniesStarted = () => action(DoraSupplyChainActionTypes.FETCH_ALL_DORA_BASE_COMPANIES_STARTED);
export const fetchAllBaseCompaniesSuccessful = (companies: DropdownOption[], thirdPartyCompanies: DropdownOption[]) => action(DoraSupplyChainActionTypes.FETCH_ALL_DORA_BASE_COMPANIES_SUCCESSFUL, { companies, thirdPartyCompanies });
export const fetchAllBaseCompaniesFailed = (error: string) => action(DoraSupplyChainActionTypes.FETCH_ALL_DORA_BASE_COMPANIES_FAILED, error);

export const upsertSupplyChainElementStarted = () => action(DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_ELEMENT_STARTED);
export const upsertSupplyChainElementSuccessful = (hasContractDetails: boolean, hasAssessmentDetails: boolean) => action(DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_ELEMENT_SUCCESSFUL, { hasContractDetails, hasAssessmentDetails });
export const upsertSupplyChainElementFailed = (error: string) => action(DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_ELEMENT_FAILED, error);

export const upsertSupplyChainConfigurationStarted = () => action(DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_CONFIGURATION_STARTED);
export const upsertSupplyChainConfigurationSuccessful = () => action(DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_CONFIGURATION_SUCCESSFUL);
export const upsertSupplyChainConfigurationFailed = (error: string) => action(DoraSupplyChainActionTypes.UPSERT_SUPPLY_CHAIN_CONFIGURATION_FAILED, error);

export const updateCompanyFunctionDetails = (value: boolean) => action(DoraSupplyChainActionTypes.UPDATE_COMPANY_FUNCTION_DETAILS, value);

export const setCompanyFunctionFilters = (key: string, value: string | string[] | null, type: keyof TableFilterType) => action(DoraSupplyChainActionTypes.SET_COMPANY_FILTER, { key, value, type });

export const clearSupplyChainFilters = () => action(DoraSupplyChainActionTypes.CLEAR_SUPPLY_CHAIN_TABLE_FILTERS);
export const navigateBackToFunctionCompanySelect = () => action(DoraSupplyChainActionTypes.NAVIGATE_BACK_TO_FUNCTION_COMPANY_SELECT);

export const deleteSupplyChainElementStarted = () => action(DoraSupplyChainActionTypes.DELETE_SUPPLY_CHAIN_ELEMENT_STARTED);
export const deleteSupplyChainElementSuccessful = (newSupplyChain: SupplyChainElement[]) => action(DoraSupplyChainActionTypes.DELETE_SUPPLY_CHAIN_ELEMENT_SUCCESSFUL, newSupplyChain);
export const deleteSupplyChainElementFailed = (error: string) => action(DoraSupplyChainActionTypes.DELETE_SUPPLY_CHAIN_ELEMENT_FAILED, error);

export const toggleDeleteConfirmationModal = (isOpen: boolean) => action(DoraSupplyChainActionTypes.TOGGLE_DELETE_SUPPLY_CHAIN_ELEMENT_MODAL, isOpen);

export const toggleITServiceDescriptionModal = (isOpen: boolean) => action(DoraSupplyChainActionTypes.TOGGLE_IT_SERVICE_DESCRIPTION, isOpen);

export const setSelectedSupplyChainElementTab = (tab: SupplyChainElementTab) => action(DoraSupplyChainActionTypes.SET_SELECTED_SUPPLY_CHAIN_ELEMENT_TAB, tab);

export const fetchElementDetailsStarted = (doraSupplyChainId: number) => action(DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_STARTED, doraSupplyChainId);
export const fetchElementDetailsSuccessful = (details: SupplyChainElementDetails) => action(DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_SUCCESSFUL, details);
export const fetchElementDetailsFailed = (error: string) => action(DoraSupplyChainActionTypes.FETCH_SUPPLY_CHAIN_ELEMENT_DETAILS_FAILED, error);

export const updateSupplyChainElementAssessment = (key: keyof DoraSupplyChainAssessmentDetails, value: IctSubstitutabilityOptions | SubstitutabilityReasoning | string | boolean | null | undefined | DetailsDocument[] | RawDraftContentState) => action(DoraSupplyChainActionTypes.UPDATE_SUPPLY_CHAIN_ELEMENT_ASSESSMENT, { key, value });
export const setSelectedAssessmentTimelineDetails = (selectedDetails: DoraSupplyChainAssessmentTimeline | null) => action(DoraSupplyChainActionTypes.SET_SELECTED_ASSESSMENT_TIMELINE_DETAILS, selectedDetails);

export const cancelContractDetailsUpdated = () => action(DoraSupplyChainActionTypes.CANCEL_CONTRACT_DETAILS_UPDATES);
export const cancelAssessmentDetailsUpdated = () => action(DoraSupplyChainActionTypes.CANCEL_ASSESSMENT_DETAILS_UPDATES);

export const setUnsavedChangesModalOpen = (value: boolean) => action(DoraSupplyChainActionTypes.SET_UNSAVED_CHANGES_MODAL_OPEN, value);

export const uploadDoraDocumentStarted = (doraSupplyChainId: number) => action(DoraSupplyChainActionTypes.UPLOAD_DORA_DOCUMENT_STARTED, doraSupplyChainId);
export const uploadDoraDocumentSuccessful = () => action(DoraSupplyChainActionTypes.UPLOAD_DORA_DOCUMENT_SUCCESSFUL);
export const uploadDoraDocumentFailed = (error: string) => action(DoraSupplyChainActionTypes.UPLOAD_DORA_DOCUMENT_FAILED, error);

export const downloadDoraDocumentStarted = (doraDocumentId: number, description: string) => action(DoraSupplyChainActionTypes.DOWNLOAD_DORA_DOCUMENT_STARTED, { doraDocumentId, description });
export const downloadDoraDocumentSuccessful = () => action(DoraSupplyChainActionTypes.DOWNLOAD_DORA_DOCUMENT_SUCCESSFUL);
export const downloadDoraDocumentFailed = (error: string) => action(DoraSupplyChainActionTypes.DOWNLOAD_DORA_DOCUMENT_FAILED, error);

export const openDoraDocumentStarted = (doraDocumentId: number, description: string) => action(DoraSupplyChainActionTypes.OPEN_DORA_DOCUMENT_STARTED, { doraDocumentId, description });
export const openDoraDocumentSuccessful = (preview: DoraDocumentPreview) => action(DoraSupplyChainActionTypes.OPEN_DORA_DOCUMENT_SUCCESSFUL, preview);
export const openDoraDocumentFailed = (error: string) => action(DoraSupplyChainActionTypes.OPEN_DORA_DOCUMENT_FAILED, error);

export const closeDoraDocumentPreviewModal = () => action(DoraSupplyChainActionTypes.CLOSE_PREVIEW_DORA_DOCUMENT_MODAL);

export const setDoraDocumentFiles = (files: File[]) => action(DoraSupplyChainActionTypes.SET_DORA_DOCUMENT_FILES, files);

export const removeDoraDocumentFile = (index: number) => action(DoraSupplyChainActionTypes.REMOVE_DORA_DOCUMENT_FILE, index);

export const linkSupplyChainToIctContractStarted = (documentId: number) => action(DoraSupplyChainActionTypes.LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_STARTED, documentId);
export const linkSupplyChainToIctContractSuccessful = (details: SupplyChainElementDetails) => action(DoraSupplyChainActionTypes.LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_SUCCESSFUL, details);
export const linkSupplyChainToIctContractFailed = (error: string) => action(DoraSupplyChainActionTypes.LINK_SUPPLY_CHAIN_TO_ICT_CONTRACT_FAILED, error);

export const setIctContractDisplay = (view: IctContractDisplay) => action(DoraSupplyChainActionTypes.SET_ICT_CONTRACT_DISPLAY, view);
