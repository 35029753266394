import { flow, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { OpinionReportTemplate, ReportPage, ReportTab, OpinionReportingActionTypes, OpinionReportingState, OpinionScopeFields, OpenScopeSections } from './types';
import { metaFields } from './utils';
import { OpinionScope } from '../../../admin/opinions/store';

export const initialOpinionReportFilter = {
    jurisdiction: null,
    focus: null
};

export const initialReportFields: OpinionScopeFields = {
    [OpinionScope.ISDA_NETTING]: [],
    [OpinionScope.COLLATERAL_PROVIDER]: [],
    [OpinionScope.COLLATERAL_TAKER]: [],
    [OpinionScope.GMRA_NETTING]: [],
    [OpinionScope.GMSLA_NETTING]: [],
    [OpinionScope.GMRA_GMSLA_NETTING]: []
};

export const initialOpenSections: OpenScopeSections = {
    [OpinionScope.ISDA_NETTING]: [],
    [OpinionScope.COLLATERAL_PROVIDER]: [],
    [OpinionScope.COLLATERAL_TAKER]: [],
    [OpinionScope.GMRA_NETTING]: [],
    [OpinionScope.GMSLA_NETTING]: [],
    [OpinionScope.GMRA_GMSLA_NETTING]: []
};

export const INITIAL_STATE: OpinionReportingState = {
    reportPage: ReportPage.SELECT,
    selectedTab: ReportTab.SELECT,
    reportFields: initialReportFields,
    opinionScope: [],
    currentScope: null,
    fetchingAvailableFields: false,
    generatingReport: false,
    fetchingError: null,
    reportFilters: initialOpinionReportFilter,
    reportUpdated: false,
    backConfirmationModalOpen: false,
    reportTemplates: [],
    saveTemplateModalOpen: false,
    templateDescription: '',
    templateName: '',
    saveTemplateError: null,
    selectedTemplate: null,
    isDeleting: false,
    confirmDeleteReportTemplate: null,
    fetchingTemplates: false,
    openScopeSections: initialOpenSections,
    includeSignOffNotes: false
};

export const opinionReportingReducer: Reducer<OpinionReportingState> = (state = INITIAL_STATE, action): OpinionReportingState => {
    switch (action.type) {
        case OpinionReportingActionTypes.SELECT_REPORT_PAGE:
            return set('reportPage', action.payload.page, state);
        case OpinionReportingActionTypes.SELECT_WIZARD_TAB:
            return set('selectedTab', action.payload, state);
        case OpinionReportingActionTypes.SELECT_SCOPE_WIZARD_TAB:
            return set('currentScope', action.payload, state);
        case OpinionReportingActionTypes.UPDATE_REPORT_FILTER: {
            const { key, value } = action.payload;
            return set(`reportFilters[${key}]`, value, state);
        }
        case OpinionReportingActionTypes.UPDATE_REPORT_DATE_FILTER: {
            const { key, value } = action.payload;
            return set(`reportFilters.date[${key}]`, value, state);
        }
        case OpinionReportingActionTypes.ADD_OPINION_REPORT_FIELD: {
            const currentFields = state.reportFields[state.currentScope!];
            const updatedFields = [...currentFields, action.payload];
            return set(`reportFields[${state.currentScope}]`, updatedFields, state);
        }
        case OpinionReportingActionTypes.REMOVE_OPINION_REPORT_FIELD: {
            const currentFields = state.reportFields[state.currentScope!];
            const updatedFields = currentFields.filter(({ id }) => id !== action.payload);
            return set(`reportFields[${state.currentScope}]`, updatedFields, state);
        }
        case OpinionReportingActionTypes.UPDATE_OPINION_REPORT_FIELDS:
            return set(`reportFields[${state.currentScope}]`, action.payload, state);
        case OpinionReportingActionTypes.GENERATE_REPORT_STARTED:
            return set('generatingReport', true, state);
        case OpinionReportingActionTypes.GENERATE_REPORT_FAILED:
            return set('generatingReport', false, state);
        case OpinionReportingActionTypes.GENERATE_REPORT_SUCCESSFUL:
            return flow(
                set('reportPage', ReportPage.SELECT),
                set('selectedTab', ReportTab.SELECT),
                set('generatingReport', false),
                set('reportFilters', initialOpinionReportFilter),
                set('reportFields', initialReportFields),
                set('reportUpdated', false),
                set('opinionScope', []),
                set('currentScope', null),
                set('openScopeSections', initialOpenSections),
                set('selectedTemplate', null),
                set('includeSignOffNotes', false)
            )(state);
        case OpinionReportingActionTypes.RESET_REPORT: {
            return { ...INITIAL_STATE, reportTemplates: state.reportTemplates, reportPage: action.payload };
        }
        case OpinionReportingActionTypes.TOGGLE_REPORT_CONFIRMATION_MODAL:
            return set('backConfirmationModalOpen', action.payload, state);
        case OpinionReportingActionTypes.SET_REPORT_UPDATED:
            return set('reportUpdated', action.payload, state);
        case OpinionReportingActionTypes.TOGGLE_SAVE_TEMPLATE_MODAL_OPEN:
            return flow(
                set('saveTemplateModalOpen', action.payload),
                set('templateName', ''),
                set('templateDescription', '')
            )(state);
        case OpinionReportingActionTypes.UPDATE_REPORT_TEMPLATE_VALUE: {
            const { key, value } = action.payload;
            return set(`${key}`, value, state);
        }
        case OpinionReportingActionTypes.SAVE_REPORT_TEMPLATE_FAILED:
            return set('saveTemplateError', action.payload, state);
        case OpinionReportingActionTypes.SAVE_REPORT_TEMPLATE_SUCCESSFUL:
            return flow(
                set('saveTemplateError', null),
                set('saveTemplateModalOpen', false),
                set('reportUpdated', false),
                set('selectedTemplate', action.payload)
            )(state);
        case OpinionReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_STARTED:
            return set('fetchingTemplates', true, state);
        case OpinionReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_SUCCESSFUL:
            return flow(
                set('reportTemplates', action.payload),
                set('fetchingTemplates', false)
            )(state);
        case OpinionReportingActionTypes.FETCH_ALL_REPORT_TEMPLATES_FAILED:
            return flow(
                set('fetchingError', action.payload),
                set('fetchingTemplates', false)
            )(state);
        case OpinionReportingActionTypes.DELETE_REPORT_TEMPLATE_STARTED:
            return set('isDeleting', true, state);
        case OpinionReportingActionTypes.DELETE_REPORT_TEMPLATE_SUCCESSFUL:
            return flow(
                set('isDeleting', false),
                set('reportTemplates', action.payload),
                set('confirmDeleteReportTemplate', null)
            )(state);
        case OpinionReportingActionTypes.DELETE_REPORT_TEMPLATE_FAILED:
            return set('isDeleting', false, state);
        case OpinionReportingActionTypes.SET_REPORT_TEMPLATE_TO_DELETE:
            return set('confirmDeleteReportTemplate', action.payload, state);
        case OpinionReportingActionTypes.OPEN_OPINION_REPORT_TEMPLATE: {
            const reportTemplate: OpinionReportTemplate = action.payload;
            const { scopes, reportFields, filters, includeSignOffNotes } = reportTemplate;
            return flow(
                set('selectedTemplate', action.payload),
                set('reportPage', ReportPage.WIZARD),
                set('selectedTab', ReportTab.SELECT),
                set('opinionScope', scopes),
                set('currentScope', scopes[0]),
                set('reportFields', reportFields),
                set('reportFilters', filters),
                set('includeSignOffNotes', includeSignOffNotes)
            )(state);
        }
        case OpinionReportingActionTypes.SET_OPINION_REPORT_SCOPES: {
            const { scopes, setReportFields } = action.payload;
            if (setReportFields) {
                const reportFields = (scopes as OpinionScope[]).reduce((acc, cur) => {
                    acc[cur] = metaFields.filter(({ id }) => ['jurisdiction', 'focus'].includes(id));
                    return acc;
                }, { ...initialReportFields });
                return flow(
                    set('opinionScope', scopes),
                    set('currentScope', scopes[0]),
                    set('reportFields', reportFields)
                )(state);
            }
            return flow(
                set('opinionScope', scopes),
                set('currentScope', scopes[0])
            )(state);
        }
        case OpinionReportingActionTypes.SET_CURRENT_SCOPE:
            return set('currentScope', action.payload, state);
        case OpinionReportingActionTypes.SET_SCOPE_OPEN_SECTIONS: {
            const { openSections, scope } = action.payload;
            return set(`openScopeSections[${scope}]`, openSections, state);
        }
        case OpinionReportingActionTypes.TOGGLE_INCLUDE_SIGN_OFF_NOTES:
            return set('includeSignOffNotes', action.payload, state);
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
