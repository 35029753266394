import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Pencil } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Text } from '../../shared/text/Text';
import { getDocumentDescriptionsHaveUpdated, getIsRenaming, getOriginalDocumentToRename, renameDocumentsStarted, toggleRenameDocumentModal, updateDocumentDescription } from './store';
import styles from './Documents.module.scss';
import { Spinner } from '../../shared/spinner/Spinner';

interface RenameDocumentModalProps {
    isOpen: boolean;
    isPermitted: boolean;
}

export const RenameDocumentModal: React.FC<RenameDocumentModalProps> = ({ isOpen, isPermitted }) => {
    const dispatch = useAppDispatch();
    const renameDocument = useAppSelector(getOriginalDocumentToRename);
    const isRenaming = useAppSelector(getIsRenaming);
    const haveUpdated = useAppSelector(getDocumentDescriptionsHaveUpdated);
    const renameDocuments = () => dispatch(renameDocumentsStarted());
    const closeModal = () => dispatch(toggleRenameDocumentModal(null));

    const orderedDocuments = useMemo(() => renameDocument?.allDocuments.sort((a, b) => Date.parse(a.executedDate) - Date.parse(b.executedDate)) || [], [renameDocument]);
    const updateDescription = useCallback((documentId: number, description: string) => dispatch(updateDocumentDescription(documentId, description)), [dispatch]);

    if (!renameDocument || !isPermitted) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={renameDocuments}
            confirmLabel='Save'
            confirmDisabled={isRenaming || !haveUpdated}
        >
            <ModalHeader icon={Pencil} label='Rename Documents' />
            <div className={styles.documentRenameWrapper}>
                {isRenaming ? <Spinner /> :
                    <Scrollable maxHeight='300px'>
                        {orderedDocuments.map(({ documentId, documentDescription }) => (
                            <Text
                                value={documentDescription}
                                onChange={e => updateDescription(documentId, e.target.value)}
                                testId={`rename-document-${documentId}`}
                                maxLength={256}
                                key={documentId}
                            />
                        ))}
                    </Scrollable>
                }
            </div>
        </ConfirmationModal>
    );
};
