import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { PlaybookIcon } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';
import { getDeleteSectionConfirmationModalOpen, getPlaybook, getSectionWithLinksToDelete, PlaybookProvision, removePlaybookSection, toggleDeleteSectionConfirmationModal } from './store';

export const DeletePlaybookSectionConfirmationModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getDeleteSectionConfirmationModalOpen);
    const sectionId = useAppSelector(getSectionWithLinksToDelete);
    const playbook = useAppSelector(getPlaybook);
    const { content: { sections } } = playbook;

    const closeModal = () => dispatch(toggleDeleteSectionConfirmationModal(null));
    const deleteDocument = () => {
        dispatch(removePlaybookSection(sectionId!));
        closeModal();
    };

    const warning = 'Deleting this section will also permanently remove all associated links.';

    const sectionTitle = useMemo(() => !isNull(sectionId) ? (sections.find(section => section.sectionId === sectionId) as PlaybookProvision).title.filter(item => !!item).join(': ') : '', [sectionId, sections]);

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={deleteDocument}
            isDeleting={false}
            headerLabel='Delete Playbook Section'
            name={sectionTitle}
            testId='playbook-section'
            warning={warning}
            icon={PlaybookIcon}
            deleteLabel='playbook section'
        />
    );
};
