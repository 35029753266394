import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Button } from '../../shared/button/Button';
import { DocumentFilter } from '../../shared/filter/DocumentFilter';
import { Filter } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Position, PositionModal } from '../../shared/modal/PositionModal';
import { DateFilter, SearchFilter, getSearchFilters, initialSearchFilter, resetFilters, updateExecutedDateFilter, updateSearchFilter } from '../search/store';
import styles from './SearchDocuments.module.scss';

interface FilterModalProps {
    isOpen: boolean;
    closeModal: () => void;
    applyFilters: () => void;
    closeOnOverlayClick?: boolean;
    position: Position;
}

export const FilterModal: React.FC<FilterModalProps> = ({ isOpen, closeModal, applyFilters, closeOnOverlayClick = false, position }) => {
    const filters = useAppSelector(getSearchFilters);

    const { entityId, documentNameId, documentNameGroupId, date: { to, from }, isExclusiveEntityFilter, includePreExecution } = filters;
    const filtersAreEmpty = filters === initialSearchFilter;

    const dispatch = useAppDispatch();
    const handleFilterReset = () => dispatch(resetFilters());
    const updateFilter = (key: keyof SearchFilter, value: string[] | string | boolean | null) => dispatch(updateSearchFilter(key, value));
    const updateDateFilter = (key: keyof DateFilter, value: string | null) => dispatch(updateExecutedDateFilter(key, value));

    return (
        <PositionModal
            isOpen={isOpen}
            closeModal={closeModal}
            position={position}
            testId='search-document-filter'
            height='fit-content'
            width='fit-content'
            closeOnOverlayClick={closeOnOverlayClick}
        >
            <div className={styles.filterWrapper} data-testid='search-filter-modal-wrapper'>
                <ModalHeader label='Filters' icon={Filter} iconSize={22} testId='search-filter-modal' />
                <DocumentFilter
                    to={to}
                    from={from}
                    entityId={entityId}
                    documentNameId={documentNameId}
                    documentNameGroupId={documentNameGroupId}
                    isExclusiveEntityFilter={isExclusiveEntityFilter}
                    includePreExecution={includePreExecution}
                    updateFilter={updateFilter}
                    updateDateFilter={updateDateFilter}
                />
                <div className={styles.searchFilterButtonWrapper}>
                    <div className={styles.leftButtonWrapper}>
                        <Button onClick={handleFilterReset} label='Reset' testId='reset-filters' disabled={filtersAreEmpty} />
                    </div>
                    <div className={styles.rightButtonWrapper}>
                        <Button onClick={applyFilters} label='Apply' testId='apply-filters' disabled={filtersAreEmpty} />
                    </div>
                </div>
            </div>
        </PositionModal>
    );
};
