import React from 'react';

export const Dataset: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.4,111.6H26.2c-6.6,0-12-5.4-12-12V23.4c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.4,106.2,109,111.6,102.4,111.6z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M101.9,32.5L101.9,32.5c-1.2-7.1-17.7-12.6-37.9-12.6s-36.7,5.6-37.9,12.6l0,0h-0.1v0.8v18.2v0.8V53v17.4v0.8V72v19h0.1c1.2,7.1,17.7,12.6,37.9,12.6S100.7,98,101.9,91h0.1V72v-0.8v-0.8V53v-0.8v-0.8V33.2L101.9,32.5L101.9,32.5z" />
        <g>
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M64,84.6c-21,0-37.9-6-37.9-13.4V91h0.1c1.2,7.1,17.7,12.6,37.9,12.6S100.7,98,101.9,91h0.1V71.2C101.9,78.6,85,84.6,64,84.6z" />
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M26.1,70.4v0.8C26.1,70.9,26.1,70.7,26.1,70.4L26.1,70.4z" />
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M101.9,70.4c0,0.3,0.1,0.5,0.1,0.8L101.9,70.4L101.9,70.4z" />
        </g>
        <g>
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M64,65.7c-21,0-37.9-6-37.9-13.4V72h0.1C27.3,79,43.8,84.6,64,84.6S100.7,79,101.9,72h0.1V52.2C101.9,59.6,85,65.7,64,65.7z" />
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M26.1,51.4v0.8C26.1,51.9,26.1,51.7,26.1,51.4L26.1,51.4z" />
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M101.9,51.4c0,0.3,0.1,0.5,0.1,0.8L101.9,51.4L101.9,51.4z" />
        </g>
        <ellipse fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' cx="64" cy="33.2" rx="37.9" ry="13.4" />
        <g>
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M64,46.7c-21,0-37.9-6-37.9-13.4V53h0.1c1.2,7.1,17.7,12.6,37.9,12.6s36.7-5.6,37.9-12.6h0.1V33.2C101.9,40.7,85,46.7,64,46.7z" />
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M26.1,32.5v0.8C26.1,33,26.1,32.7,26.1,32.5L26.1,32.5z" />
            <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={3} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' d="M101.9,32.5c0,0.3,0.1,0.5,0.1,0.8L101.9,32.5L101.9,32.5z" />
        </g>
    </svg>
);
