import React, { useMemo } from 'react';

import { useAppSelector } from '../../hooks/react-redux';
import { Document } from '../shared/icons';
import { Spinner } from '../shared/spinner/Spinner';
import { HomePageTile, getDocumentSpreadAnalytics, getFetchingAnalytics } from './store';
import { PieChartWrapper } from './PieChartWrapper';

interface DocumentSpreadChartProps {
    dimensions?: {
        height: number;
        width: number;
    }
    analyticsSpinner: boolean;
}

export const DocumentSpreadChart: React.FC<DocumentSpreadChartProps> = ({ dimensions, analyticsSpinner }) => {
    const documentSpreadAnalytics = useAppSelector(getDocumentSpreadAnalytics);
    const fetchingAnalytics = useAppSelector(getFetchingAnalytics);
    const showSpinner = useMemo(() => fetchingAnalytics.includes(HomePageTile.DOCUMENT_SPREAD) || analyticsSpinner, [fetchingAnalytics, analyticsSpinner]);

    return showSpinner ?
        <Spinner /> : (
            <div style={{ zIndex: 3 }}>
                <PieChartWrapper
                    data={documentSpreadAnalytics}
                    dimensions={dimensions}
                    icon={Document}
                    testId='homepage-document-spread'
                />
            </div>
        );
};
