import { isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { AgreementType } from '../../../admin/documents/store';
import { fetchDocumentsStarted, getIsOpening } from '../../../documents/my-documents/store';
import { Button } from '../../../shared/button/Button';
import { IconButton } from '../../../shared/button/IconButton';
import { Form, Table } from '../../../shared/icons';
import { getIctContractDisplay, getSelectedElementContractualInfo, IctContractDisplay, setIctContractDisplay } from '../store';
import styles from '../SupplyChain.module.scss';
import { IctContractInstance } from './IctContractInstance';
import { IctContractTable } from './IctContractTable';
import { DocumentAnalysisViewer } from '../../../shared/analysis/DocumentAnalysisViewer';

const MODAL_PADDING = 10;
const WIZARD_PADDING = 10;
const BOTTOM_BUTTON_WRAPPER = 48;
const TOP_BUTTON_WRAPPER = 48;
const TOP_NAVIGATION_WRAPPER = 45;
const MODAL_HEADER = 56;
export const MODAL_HEIGHT = MODAL_PADDING + BOTTOM_BUTTON_WRAPPER + TOP_BUTTON_WRAPPER + TOP_NAVIGATION_WRAPPER + MODAL_HEADER;

export const ContractualInformation: React.FC = () => {
    const [screenWidth, screenHeight] = useWindowResize();

    const dispatch = useAppDispatch();
    const [showTable, setShowTable] = useState(true);

    useFetchStarted([fetchDocumentsStarted(1, AgreementType.ICT_CONTRACT)]);

    const currentIctContractInstance = useAppSelector(getSelectedElementContractualInfo);
    const isOpeningDocument = useAppSelector(getIsOpening);
    const ictContractDisplay = useAppSelector(getIctContractDisplay);

    useEffect(() => setShowTable(isNull(currentIctContractInstance)), [currentIctContractInstance]);

    const handleSetDisplay = useCallback((view: IctContractDisplay) => dispatch(setIctContractDisplay(view)), [dispatch]);
    const previewDocument = useCallback(() => {
        if (!isOpeningDocument) {
            const view = ictContractDisplay === IctContractDisplay.DOCUMENT ? IctContractDisplay.INSTANCE : IctContractDisplay.DOCUMENT;
            handleSetDisplay(view);
        }
    }, [ictContractDisplay, isOpeningDocument, handleSetDisplay]);

    const buttonIcon = useMemo(() => showTable ? Form : Table, [showTable]);
    const buttonLabel = useMemo(() => showTable ? 'Show Contract Details' : 'Change Contract Document', [showTable]);
    const toggleButtonLabel = useMemo(() => ictContractDisplay === IctContractDisplay.INSTANCE ? 'View Contract' : 'View Instance', [ictContractDisplay]);

    const width = useMemo(() => screenWidth * 0.7 - MODAL_PADDING - WIZARD_PADDING, [screenWidth]);
    const height = useMemo(() => screenHeight * 0.85 - MODAL_HEIGHT, [screenHeight]);

    const body = useMemo(() => {
        if (showTable) {
            return <IctContractTable width={width} height={height} />;
        }
        return ictContractDisplay === IctContractDisplay.INSTANCE ? <IctContractInstance height={height} /> : <div className={styles.documentAnalysisWrapper} style={{ height: `${height}px` }}><DocumentAnalysisViewer width={width} /></div>;
    }, [ictContractDisplay, showTable, height, width]);

    return (
        <div className={styles.contractualInformationDetailsWrapper}>
            {currentIctContractInstance &&
                <div className={styles.contractualInformationDetailsButtonWrapper}>
                    <div className={styles.contractualInformationToggleTableButtonWrapper}>
                        <IconButton icon={buttonIcon} onClick={() => setShowTable(!showTable)} overlayText={buttonLabel} />
                    </div>
                    {!showTable && <Button label={toggleButtonLabel} onClick={previewDocument} />}
                </div>
            }
            {body}
        </div>
    );
};
