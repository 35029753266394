import { Socket } from 'socket.io-client';
import { action } from 'typesafe-actions';

import { HomeScreenConfig } from '../../../home/store';
import { LoginActionTypes, User, ReminderTypes, CustomFilters, UserDefaults } from './types';

export const idleCheckToken = () => action(LoginActionTypes.IDLE_CHECK_TOKEN);
export const setIdleCheckTokenValid = (isValid: boolean) => action(LoginActionTypes.SET_IDLE_CHECK_TOKEN_VALID, { isValid });

export const logoutStarted = () => action(LoginActionTypes.LOGOUT_STARTED);
export const logoutSuccessful = () => action(LoginActionTypes.LOGOUT_SUCCESSFUL);
export const logoutFailed = (error: string) => action(LoginActionTypes.LOGOUT_FAILED, error);

export const loginStarted = (username: string, password: string) => action(LoginActionTypes.LOGIN_STARTED, { username, password });
export const loginSuccessful = (user: User) => action(LoginActionTypes.LOGIN_SUCCESSFUL, user);
export const loginFailed = (error: string) => action(LoginActionTypes.LOGIN_FAILED, error);
export const socketLogin = (user: User) => action(LoginActionTypes.SOCKET_LOGIN, user);
export const redirectToLogin = () => action(LoginActionTypes.REDIRECT_TO_LOGIN);

export const setReminderType = (type: ReminderTypes | null) => action(LoginActionTypes.SET_REMINDER_TYPE, type);

export const forgottenUsernameReminderStarted = (email: string) => action(LoginActionTypes.FORGOTTEN_USERNAME_REMINDER_STARTED, { email });
export const forgottenUsernameReminderFailed = (error: string) => action(LoginActionTypes.FORGOTTEN_USERNAME_REMINDER_FAILED, error);
export const forgottenUsernameReminderSuccessful = () => action(LoginActionTypes.FORGOTTEN_USERNAME_REMINDER_SUCCESSFUL);

export const forgottenPasswordReminderStarted = (username: string) => action(LoginActionTypes.FORGOTTEN_PASSWORD_REMINDER_STARTED, username);
export const forgottenPasswordReminderFailed = (error: string) => action(LoginActionTypes.FORGOTTEN_PASSWORD_REMINDER_FAILED, error);
export const forgottenPasswordReminderSuccessful = () => action(LoginActionTypes.FORGOTTEN_PASSWORD_REMINDER_SUCCESSFUL);

export const setSocketsConnection = (socket: Socket) => action(LoginActionTypes.SET_SOCKETS_CONNECTION, socket);
export const setSocketsConnectionFailed = (error: string) => action(LoginActionTypes.SET_SOCKETS_CONNECTION_FAILED, error);

export const toggleLogoutWarningModal = (isOpen: boolean) => action(LoginActionTypes.TOGGLE_LOGOUT_WARNING_MODAL, isOpen);
export const extendToken = () => action(LoginActionTypes.EXTEND_TOKEN);

export const userUpdated = (user: User) => action(LoginActionTypes.USER_UPDATED, user);

export const toggleMyProfileModal = (isOpen: boolean) => action(LoginActionTypes.TOGGLE_MY_PROFILE_MODAL, isOpen);
export const toggleEditMyProfile = (value: boolean) => action(LoginActionTypes.EDIT_MY_PROFILE_DETAILS, value);
export const setMyProfileUpdated = (value: boolean) => action(LoginActionTypes.SET_MY_PROFILE_UPDATED, value);

export const updateMyProfileDetails = (key: keyof User, value: string | File | HomeScreenConfig | CustomFilters[] | UserDefaults | undefined) => action(LoginActionTypes.UPDATE_MY_PROFILE_DETAILS, { key, value });
export const updateUserCustomFilters = (customFilters: CustomFilters[]) => action(LoginActionTypes.UPDATE_USER_CUSTOM_FILTERS, customFilters);

export const updateMyProfileStarted = () => action(LoginActionTypes.UPDATE_MY_PROFILE_STARTED);
export const updateMyProfileFailed = (error: string) => action(LoginActionTypes.UPDATE_MY_PROFILE_FAILED, error);
export const updateMyProfileSuccessful = () => action(LoginActionTypes.UPDATE_MY_PROFILE_SUCCESSFUL);

export const setUpdatingProfile = (value: boolean) => action(LoginActionTypes.SET_UPDATING_PROFILE, value);

export const setShowResetPassword = (value: boolean) => action(LoginActionTypes.SET_SHOW_RESET_PASSWORD, value);

export const setShowSwitchAccount = (value: boolean) => action(LoginActionTypes.SET_SHOW_SWITCH_ACCOUNT, value);

export const setAccountSwitchUsername = (username: string | null) => action(LoginActionTypes.SET_ACCOUNT_SWITCH_USERNAME, username);
export const setAccountSwitchPassword = (password: string) => action(LoginActionTypes.SET_ACCOUNT_SWITCH_PASSWORD, password);
export const switchAccountStarted = (username: string, password: string) => action(LoginActionTypes.SWITCH_USER_ACCOUNT_STARTED, { username, password });
export const switchAccountSuccessful = (user: User) => action(LoginActionTypes.SWITCH_USER_ACCOUNT_SUCCESSFUL, user);
export const switchAccountFailed = (error: string) => action(LoginActionTypes.SWITCH_USER_ACCOUNT_FAILED, error);
