import classnames from 'classnames';
import { isNull, noop } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-modal';

import { Document } from '../icons';
import { LoadingDots } from '../loading-dots/LoadingDots';
import { Scrollable } from '../scrollable/Scrollable';
import { Toggle } from '../toggle';
import { InformationTooltip, OverflowTooltip } from '../tooltip';
import { ConfirmationModal } from './ConfirmationModal';
import styles from './Modal.module.scss';
import { ModalHeader } from './ModalHeader';

interface UploadModalProps {
    isOpen: boolean;
    disabled?: boolean;
    setUploadDocuments: (files: File[]) => void;
    testId?: string;
    modalLabel: string;
    files: File[] | null;
    completeWithAI?: boolean;
    analyseLater?: boolean;
    setAnalyseLater?: (val: boolean) => void;
    setCompleteWithAI?: (val: boolean) => void;
    isUploading: boolean;
    closeModal: () => void;
    uploadDocument: () => void;
    showCompleteWithAIOption?: boolean;
    showAnalyseLaterOption?: boolean;
    canUpload?: boolean;
}

export const UploadModal: React.FC<UploadModalProps> = ({
    isOpen,
    disabled = false,
    setUploadDocuments,
    testId,
    modalLabel,
    files,
    setAnalyseLater = noop,
    analyseLater = false,
    completeWithAI = false,
    setCompleteWithAI = noop,
    isUploading,
    closeModal,
    uploadDocument,
    showCompleteWithAIOption = false,
    showAnalyseLaterOption = false,
    canUpload = false
}) => {

    const confirmDisabled = !files || !canUpload;

    const confirmDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!canUpload) {
            disabledArray.push('You do not have permission to upload documents.');
        }
        if (!files && canUpload) {
            disabledArray.push('You have not added any documents.');
        }
        return disabledArray;
    }, [files, canUpload]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 10) {
            setAnalyseLater(true);
        }
        setUploadDocuments(acceptedFiles);
    }, [setUploadDocuments, setAnalyseLater]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({ disabled: disabled || !canUpload, multiple: true, maxFiles: 0, accept: ['.pdf', '.doc', '.docx', '.tif'], onDrop });

    const documentsToUpload = useMemo(() => {
        if (!files || (files && !files.length)) {
            return <div className={styles.selectedDocument}>None</div>;
        }
        return <div className={styles.selectedDocumentsWrapper}>
            <div className={styles.tableHeader}>
                <div className={styles.nameHeaderCell}><OverflowTooltip overlayText='Filename' className={styles.headerLabel} /></div>
                <div className={styles.sizeHeaderCell}><OverflowTooltip overlayText='Size' className={styles.headerLabel} /></div>
            </div>
            <Scrollable maxHeight='210px'>
                {files.map(({ name, size }, index) => (
                    <div key={index} className={classnames(styles.tableRow, { [styles.lastTableRow]: index === files.length - 1 })}>
                        <div className={styles.nameCellWrapper}><OverflowTooltip overlayText={name} className={styles.cellLabel} /></div>
                        <div className={styles.sizeCellWrapper}><OverflowTooltip overlayText={`${(size / 1e6).toFixed(2)}MB`} className={styles.cellLabel} /></div>
                    </div>
                ))}
            </Scrollable>
        </div>;
    }, [files]);

    const documentTotal = files ? files.length : 0;
    const documentPlural = files && files.length > 1 ? 's' : '';

    const dropzoneText = useMemo(() => {
        if (isDragActive) {
            return 'Drop documents here';
        }
        if (files && files.length) {
            return `Document${documentPlural} successfully added, click here to change the document${documentPlural} to be uploaded`;
        }
        return 'Drag \'n\' drop documents here, or click to select files';
    }, [isDragActive, files, documentPlural]);

    if (isUploading) {
        return (
            <Modal
                ariaHideApp={false}
                isOpen
                className={styles.uploadingOverlay}
                style={{ overlay: { display: 'flex', zIndex: 10 } }}
            >
                <div className={styles.spinnerWrapper} data-testid='dataset-instance-extracting-wrapper'>
                    <LoadingDots />
                    <div className={styles.spinnerLabel}>Uploading Document{documentPlural}</div>
                    <div className={styles.spinnerWarning}>Do not refresh or close browser during upload process</div>
                </div>
            </Modal>
        );
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirmLabel='Upload'
            confirmDisabled={confirmDisabled}
            confirm={uploadDocument}
            testId={testId}
            confirmDisabledTooltip={confirmDisabledTooltip}
            closeOnOverlayClick={false}
        >
            <div className={styles.uploadDocumentWrapper} data-testid={`${testId}-wrapper`}>
                <ModalHeader
                    label={modalLabel}
                    testId={testId}
                    icon={Document}
                />
                <div {...getRootProps({ className: classnames(styles.dropzone, { [styles.dropzoneDisabled]: disabled }) })}>
                    <input {...getInputProps()} />
                    <div className={styles.dropzonePlaceholder}>{dropzoneText}</div>
                </div>
                <div data-testid={`${testId}-selected-document-wrapper`} className={styles.selectedDocumentWrapper}>
                    <div className={styles.selectedDocumentHeader} data-testid={`${testId}-documents-to-upload-label`}>{`Document${documentPlural} to be uploaded (${documentTotal})`}</div>
                    {documentsToUpload}
                </div>
                <div className={styles.uploadToggleOptionsWrapper}>
                    {showCompleteWithAIOption &&
                        <div className={styles.toggleOptionWrapper}>
                            <div className={styles.toggleOptionLabelWrapper}>
                                <div className={styles.toggleOptionLabel} data-testid={`${testId}-complete-with-ai-label`}>Complete Document Details with AI?</div>
                                <InformationTooltip content='If selected, we will attempt to extract key information from your document using Artificial Intelligence.' />
                            </div>
                            <Toggle
                                checked={completeWithAI}
                                onChange={val => setCompleteWithAI(val)}
                                testId={`${testId}-complete-with-ai`}
                            />
                        </div>
                    }
                    {showAnalyseLaterOption &&
                        <div className={styles.toggleOptionWrapper}>
                            <div className={styles.toggleOptionLabelWrapper}>
                                <div className={styles.toggleOptionLabel} data-testid={`${testId}-analyse-later-label`}>Analyse Later?</div>
                                <InformationTooltip content={`If selected, we will upload documents now and complete the OCR text extraction${completeWithAI ? ' and initial classification' : ''} later`} />
                            </div>
                            <Toggle
                                checked={analyseLater}
                                onChange={val => setAnalyseLater(val)}
                                testId={`${testId}-analyse-later`}
                                disabled={!isNull(files) && files.length > 10}
                            />
                        </div>
                    }
                </div>
            </div>
        </ConfirmationModal>
    );
};
