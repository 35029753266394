import React, { useCallback, useMemo } from 'react';
import { indexOf, max, min, set } from 'lodash/fp';
import Slider from 'rc-slider';

import styles from './PlaybookHistory.module.scss';
import { PlaybookDB, PlaybookHistory } from '../../../admin/playbook/store';
import { PlaybookHistoryTooltip } from './PlaybookHistoryTooltip';
import { Icon } from '../../icon/Icon';
import { Ellipsis } from '../../icons';

const { french, white, grey } = styles;

interface MarkerProps {
    playbookHistory: PlaybookHistory;
    showVersion: boolean;
}

const Marker: React.FC<MarkerProps> = ({ playbookHistory, showVersion }) => {
    const version = `${playbookHistory.versionMajor}.${playbookHistory.versionMinor}`;
    if (showVersion) {
        return (
            <PlaybookHistoryTooltip playbookHistory={playbookHistory}>
                <div className={styles.marker}>v{version}</div>
            </PlaybookHistoryTooltip>
        );
    }
    return (
        <PlaybookHistoryTooltip playbookHistory={playbookHistory}>
            <div className={styles.iconWrapper}>
                <Icon icon={Ellipsis} fontSize={20} />
            </div>
        </PlaybookHistoryTooltip>
    );
};

interface PlaybookTimelineProps {
    timeline: PlaybookHistory[];
    currentPlaybook: PlaybookDB | null;
    onChange: (value: number | number[]) => void;
    testId?: string;
}

export const PlaybookTimeline: React.FC<PlaybookTimelineProps> = ({ timeline, currentPlaybook, onChange, testId = 'playbook-timeline' }) => {

    const showVersionMarker = useCallback((numberOfMarks: number, extremityVersion: boolean, isMajorRelease: boolean) => numberOfMarks < 12 || extremityVersion || isMajorRelease, []);

    const mapAllInstances = useCallback((timeline: PlaybookHistory[]) => timeline.reduce((acc, playbookHistory, index) => {
        const numberOfMarks = timeline.length;
        const key = index + 1;
        const extremityVersion = key === 1 || key === numberOfMarks;
        const isMajorRelease = playbookHistory.versionMinor === 0;
        const showVersion = showVersionMarker(numberOfMarks, extremityVersion, isMajorRelease);
        return set(key,
            <Marker
                playbookHistory={playbookHistory}
                showVersion={showVersion}
            />,
            acc);
    }, {}), [showVersionMarker]);
    const historicalInstances = useMemo(() => mapAllInstances(timeline), [timeline, mapAllInstances]);

    const minValue = min((Object.keys(historicalInstances).map(val => parseInt(val))));
    const maxValue = max((Object.keys(historicalInstances).map(val => parseInt(val))));
    const minimum = minValue && maxValue !== minValue ? minValue : 0;
    const selected = useMemo(() => timeline.find(({ playbookDefinitionId }) => playbookDefinitionId === currentPlaybook?.playbookDefinitionId), [timeline, currentPlaybook]);

    const value = useMemo(() => indexOf(selected, timeline) + 1, [timeline, selected]);

    return (
        <div className={styles.playbookHistoryWrapper} data-testid={`${testId}-wrapper`}>
            {timeline.length ?
                <Slider
                    min={minimum}
                    marks={historicalInstances}
                    onChange={onChange}
                    defaultValue={maxValue}
                    value={value}
                    max={maxValue}
                    step={1}
                    trackStyle={{ backgroundColor: french }}
                    railStyle={{ backgroundColor: grey }}
                    handleStyle={{ backgroundColor: white, border: `1px solid ${french}`, height: '16px', width: '16px', marginTop: '-6px', opacity: 1 }}
                    dotStyle={{ backgroundColor: white, border: `1px solid ${french}` }}
                /> : <div style={{ height: '14px' }} />}
        </div>
    );
};
