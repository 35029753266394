import { isEqual } from 'lodash/fp';

import { ApplicationState } from '../../../store/rootReducer';
import { CustomFilters, UserDefaults } from '../../auth/login/store';
import { defaultHomeScreenConfig } from '../../constants/homeScreenConfig';
import { HomePageTile, HomepageState, MyAttestationAnalytics, ArkModule, HomeScreenConfig, MyProfileSection } from './types';
import { PieChartAnalyticsData } from '../../shared/analytics/PieChart';

const getRoot = (state: ApplicationState): HomepageState => state.homepage;

export const getFetchingAnalytics = (state: ApplicationState): HomePageTile[] => getRoot(state).homepageFetchingAnalytics;

export const getDocumentSpreadAnalytics = (state: ApplicationState): PieChartAnalyticsData[] => getRoot(state).documentSpread;

export const getEntitySpreadAnalytics = (state: ApplicationState): PieChartAnalyticsData[] => getRoot(state).entitySpread;

export const getMyAttestationProgressAnalytics = (state: ApplicationState): MyAttestationAnalytics[] => getRoot(state).myAttestationProgress;

export const getHomeScreenConfigModalOpen = (state: ApplicationState): boolean => getRoot(state).homeScreenConfigModalOpen;

export const getHomeScreenConfig = (state: ApplicationState): HomeScreenConfig => getRoot(state).homeScreenConfig || defaultHomeScreenConfig;

export const getCurrentHomeScreenConfig = (state: ApplicationState): HomeScreenConfig => getRoot(state).currentHomeScreenConfig || defaultHomeScreenConfig;

export const getHomeScreenConfigUpdated = (state: ApplicationState): boolean => {
    const currentConfig = getCurrentHomeScreenConfig(state);
    const config = getHomeScreenConfig(state);
    return !isEqual(currentConfig, config);
};

export const getUnauthorisedModuleModalOpen = (state: ApplicationState): boolean => getRoot(state).unauthorisedModuleModalOpen;

export const getUserNotPermittedModalOpen = (state: ApplicationState): boolean => getRoot(state).userNotPermittedModalOpen;

export const getUnauthorisedModule = (state: ApplicationState): ArkModule | null => getRoot(state).unauthorisedModule;

export const getAddNewFilterModalOpen = (state: ApplicationState): boolean => getRoot(state).addNewFilterModalOpen;

export const getNewCustomFilter = (state: ApplicationState): CustomFilters | null => getRoot(state).newCustomFilter;

export const getSavedUserCustomFilters = (state: ApplicationState): CustomFilters[] => getRoot(state).savedUserCustomFilters;

export const getCurrentUserCustomFilters = (state: ApplicationState): CustomFilters[] => getRoot(state).currentUserCustomFilters;

export const getFilterTileModalOpen = (state: ApplicationState): string | null => getRoot(state).filterTileModalOpen;

export const getIsSavingCustomFilters = (state: ApplicationState): boolean => getRoot(state).isSavingCustomFilters;

export const getCustomFiltersUpdated = (state: ApplicationState): boolean => getRoot(state).customFiltersUpdated;

export const getOnCloseConfirmationDialog = (state: ApplicationState): boolean => getRoot(state).onCloseConfirmationDialog;

export const getMyProfileSection = (state: ApplicationState): MyProfileSection => getRoot(state).myProfileSection;

export const getSelectedCustomFilter = (state: ApplicationState): CustomFilters | null => getRoot(state).selectedCustomFilter;

export const getSavedUserDefaults = (state: ApplicationState): UserDefaults => getRoot(state).savedUserDefaults;

export const getCurrentUserDefaults = (state: ApplicationState): UserDefaults => getRoot(state).currentUserDefaults;

export const getUserDefaultsUpdated = (state: ApplicationState): boolean => {
    const savedDefaults = getSavedUserDefaults(state);
    const currentDefaults = getCurrentUserDefaults(state);
    return !isEqual(savedDefaults, currentDefaults);
};

export const getUnsavedProfileChangesModalOpen = (state: ApplicationState): boolean => getRoot(state).unsavedProfileChangesModalOpen;
