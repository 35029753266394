import React from 'react';

export const Date: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128" >
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.1,114.1H25.9c-6.6,0-12-5.4-12-12V25.9c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.1,108.7,108.7,114.1,102.1,114.1z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M101.6,102.6H26.4c-1.4,0-2.5-1.1-2.5-2.5V39.4c0-1.4,1.1-2.5,2.5-2.5h75.3c1.4,0,2.5,1.1,2.5,2.5v60.7C104.1,101.5,103,102.6,101.6,102.6z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M101.6,55.9H26.4c-1.4,0-2.5-1.1-2.5-2.5V39.4c0-1.4,1.1-2.5,2.5-2.5h75.3c1.4,0,2.5,1.1,2.5,2.5v13.9C104.1,54.7,103,55.9,101.6,55.9z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M91,74.9H79.8c-0.6,0-1.1-0.5-1.1-1.1v-8.8c0-0.6,0.5-1.1,1.1-1.1H91c0.6,0,1.1,0.5,1.1,1.1v8.8C92.1,74.5,91.6,74.9,91,74.9z" />
        <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={2} strokeMiterlimit={10} d="M40.4,48.1L40.4,48.1c-2.5,0-4.5-2-4.5-4.5V28.2c0-2.5,2-4.5,4.5-4.5h0c2.5,0,4.5,2,4.5,4.5v15.4C44.9,46.1,42.9,48.1,40.4,48.1z" />
        <path fill={color || 'currentColor'} stroke='#FFFFFF' strokeWidth={2} strokeMiterlimit={10} d="M87.6,48.1L87.6,48.1c-2.5,0-4.5-2-4.5-4.5V28.2c0-2.5,2-4.5,4.5-4.5h0c2.5,0,4.5,2,4.5,4.5v15.4C92.1,46.1,90.1,48.1,87.6,48.1z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M69.6,74.9H58.4c-0.6,0-1.1-0.5-1.1-1.1v-8.8c0-0.6,0.5-1.1,1.1-1.1h11.2c0.6,0,1.1,0.5,1.1,1.1v8.8C70.7,74.5,70.2,74.9,69.6,74.9z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M48.3,74.9H37c-0.6,0-1.1-0.5-1.1-1.1v-8.8c0-0.6,0.5-1.1,1.1-1.1h11.2c0.6,0,1.1,0.5,1.1,1.1v8.8C49.3,74.5,48.8,74.9,48.3,74.9z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M91,93.8H79.7c-0.6,0-1.1-0.5-1.1-1.1V84c0-0.6,0.5-1.1,1.1-1.1H91c0.6,0,1.1,0.5,1.1,1.1v8.8C92,93.3,91.6,93.8,91,93.8z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M69.6,93.8H58.4c-0.6,0-1.1-0.5-1.1-1.1V84c0-0.6,0.5-1.1,1.1-1.1h11.2c0.6,0,1.1,0.5,1.1,1.1v8.8C70.7,93.3,70.2,93.8,69.6,93.8z" />
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={3} strokeMiterlimit={10} d="M48.2,93.8H37c-0.6,0-1.1-0.5-1.1-1.1V84c0-0.6,0.5-1.1,1.1-1.1h11.2c0.6,0,1.1,0.5,1.1,1.1v8.8C49.3,93.3,48.8,93.8,48.2,93.8z" />
    </svg>
);
