import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

import styles from './Tooltip.module.scss';

interface CustomTooltipProps {
    children: JSX.Element;
    overlayText: JSX.Element | string | null;
    arrowContent?: React.ReactNode | undefined;
    color?: keyof Colors;
    width?: number;
    trigger?: string;
    placement?: string;
}

interface CustomColor {
    backgroundColor: string;
    fontColor: string;
}

export interface Colors {
    french: CustomColor;
    primary: CustomColor;
    aqua: CustomColor;
    disabled: CustomColor;
}

const { aqua, primary, french, whiteColor, red } = styles;

export const tooltipColors: Colors = {
    'french': { backgroundColor: whiteColor, fontColor: french },
    'primary': { backgroundColor: primary, fontColor: whiteColor },
    'aqua': { backgroundColor: aqua, fontColor: primary },
    'disabled': { backgroundColor: whiteColor, fontColor: red }
};

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ overlayText, children, color = 'french', width = 200, trigger = 'hover', placement = 'right' }) => {
    const overlay = overlayText && <div style={{ maxWidth: `${width - 18}px` }} className={styles.overlayTextStyle}>{overlayText}</div>;

    const { backgroundColor, fontColor } = tooltipColors[color];

    const tooltipStyles = {
        backgroundColor,
        color: fontColor,
        borderColor: fontColor,
        borderRadius: '5px',
        minHeight: '10px',
        display: overlayText ? 'flex' : 'none'
    };

    const overlayStyle = {
        maxWidth: `${width}px`
    };

    return (
        <Tooltip
            placement={placement}
            overlay={overlay}
            trigger={trigger}
            overlayInnerStyle={tooltipStyles}
            overlayStyle={overlayStyle}
        >
            {children}
        </Tooltip>
    );
};
