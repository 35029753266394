import React, { useCallback, useMemo } from 'react';
import { noop } from 'lodash/fp';

import { Calculation, CalculationCondition, CalculationType, CalculatorField, CalculatorFieldType, CalculatorInput, CalculatorInputType, CalculatorOptionType, symbolInputs } from '../../shared/calculator/constants';
import { CalculationBlock } from '../../../components/shared/calculator/CalculationBlock';

interface ReadOnlyCalculatorFieldProps {
    value: CalculatorField[];
    calculation: Calculation[];
    output: CalculatorInput[];
    gracePeriod: CalculatorInput[];
    calculationCondition: CalculationCondition | null;
    calculationIndex: number;
    fieldType: CalculatorFieldType;
    borderColor: string;
    showOutputField: boolean;
    showGracePeriod: boolean;
}

export const ReadOnlyCalculatorField: React.FC<ReadOnlyCalculatorFieldProps> = ({
    value,
    calculation,
    output,
    gracePeriod,
    calculationCondition,
    calculationIndex,
    fieldType,
    borderColor,
    showOutputField,
    showGracePeriod
}) => {

    const selectedCalculation = useMemo(() => value[0], [value]);

    const getFontSize = useCallback((inputType: CalculatorInputType, type?: CalculatorOptionType, isInput?: boolean) => {
        if (symbolInputs.includes(inputType)) {
            return '17px';
        }
        if (isInput && inputType === CalculatorInputType.NAV_OBSERVATION_PERIOD) {
            return '11px';
        }
        if (isInput && (type === CalculatorOptionType.DROPDOWN || [CalculatorInputType.SHAREHOLDER_EQUITY, CalculatorInputType.ADDITIONAL_TERMINATION_EVENT, CalculatorInputType.OPERATIONAL_ADMINISTRATIVE_DEFAULT].includes(inputType))) {
            return '13px';
        }
        return '14px';
    }, []);

    return (
        <CalculationBlock
            outputValue={value}
            calculation={calculation}
            output={output}
            gracePeriod={gracePeriod}
            calculationCondition={calculationCondition}
            calculationIndex={calculationIndex}
            getFontSize={getFontSize}
            disabled={true}
            isEditing={false}
            selectedCalculation={selectedCalculation}
            selectedCalculationIndex={0}
            selectedBuilderIndex={null}
            setSelectedBuilderIndex={noop}
            setSelectedCalculationIndex={noop}
            updateSelectedCalculation={noop}
            selectedCalculationBuilder={CalculationType.OUTPUT}
            setSelectedCalculationBuilder={noop}
            key={calculationIndex}
            fieldType={fieldType}
            borderColor={borderColor}
            removeCalculation={noop}
            updateTextField={noop}
            showOutputField={showOutputField}
            showGracePeriod={showGracePeriod}
            canRemoveCalculationBlock={false}
        />
    );
};
