import { isUndefined } from 'lodash/fp';
import React from 'react';
import Select, { ActionMeta, GroupBase, MenuPlacement, MultiValue, Options, SingleValue, StylesConfig } from 'react-select';

import styles from './Dropdown.module.scss';

/* eslint-disable @typescript-eslint/no-unused-vars */
declare module 'react-select/dist/declarations/src/Select' {
    export interface Props<
        Option,
        IsMulti extends boolean,
        Group extends GroupBase<Option>
        > {
        marginBottom?: string;
        controlBackgroundColor?: string;
        hideIndicator?: boolean;
        controlHeight?: string;
        disableControlColor?: string;
        noControlBorder?: boolean;
        controlBorderColor?: string;
        fontWeight?: number;
        minControlHeight?: string;
        maxControlHeight?: string;
        showFieldUpdated?: boolean;
        showDatasetUpdated?: boolean;
        hideDropdownIndicator?: boolean;
        selectedIds?: string[];
    }
}

export interface DropdownOption {
    value: string;
    label: string;
    disabled?: boolean;
    showDivider?: boolean;
    isGroup?: boolean;
    highlightColour?: string;
}

export type DropdownOptions = Options<DropdownOption> | Options<DropdownOption>[] | undefined

export interface SharedDropdownProps {
    value: DropdownOption[] | DropdownOption | null;
    onChange: (value: SingleValue<DropdownOption> | MultiValue<DropdownOption> | null, action: ActionMeta<DropdownOption>) => void;
    options: DropdownOption[];
    isMulti?: boolean;
    disabled?: boolean;
    testId?: string;
    placeholder?: string;
    label?: string;
    isClearable?: boolean;
    menuPortalTarget?: HTMLElement | null;
    marginBottom?: string;
    controlBackgroundColor?: string;
    hideIndicator?: boolean;
    controlHeight?: string;
    disableControlColor?: string;
    noControlBorder?: boolean;
    controlBorderColor?: string;
    fontWeight?: number;
    minControlHeight?: string;
    maxControlHeight?: string;
    menuPlacement?: MenuPlacement;
    cursor?: string;
}

export interface DropdownProps extends SharedDropdownProps {
    closeOnSelect?: boolean;
    hideDropdownIndicator?: boolean;
    selectedIds?: string[];
}

const { primary, french, white, disabledGrey, fontFamily, disabledBackground, amethyst, green, grey, backgroundGrey, frenchSelected } = styles;

export const getControlBorderStyle = (disabled: boolean, highlight = false, newField = false, borderColor = '') => {
    if (borderColor.length) {
        return borderColor;
    }
    if (highlight) {
        return amethyst;
    }
    if (newField) {
        return green;
    }
    return disabled ? disabledGrey : primary;
};

export const getControlBackgroundColor = (disabled: boolean, backgroundColor?: string) => {
    if (disabled) {
        return disabledBackground;
    }
    return backgroundColor || white;
};

export const getOptionBackgroundColor = (value: string, isFocused: boolean, isSelected: boolean, highlightColour?: string, selectedIds: string[] = []) => {
    if (!isUndefined(highlightColour)) {
        return highlightColour;
    }
    if (isSelected || selectedIds.includes(value)) {
        return frenchSelected;
    }
    return isFocused ? french : white;
};

type IsMulti = boolean;
export const customStyles: StylesConfig<DropdownOption, IsMulti> = {
    option: (provided, state) => {
        return ({
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '3px 5px',
            color: primary,
            backgroundColor: getOptionBackgroundColor(state.data.value, state.isFocused, state.isSelected, state.data.highlightColour, state.selectProps.selectedIds),
            fontSize: '13px',
            fontWeight: state.selectProps.fontWeight,
            fontFamily: fontFamily,
            borderBottom: state.data.showDivider ? `1px dashed ${french}` : ''
        });
    },
    control: (provided, state) => ({
        ...provided,
        fontWeight: state.selectProps.fontWeight,
        maxHeight: state.selectProps.maxControlHeight,
        minHeight: state.selectProps.minControlHeight,
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary,
        backgroundColor: getControlBackgroundColor(state.isDisabled, state.selectProps.controlBackgroundColor),
        border: state.selectProps.noControlBorder ? 'none' : `1px solid ${getControlBorderStyle(state.isDisabled, state.selectProps.showFieldUpdated, state.selectProps.showDatasetUpdated, state.selectProps.controlBorderColor)}`,
        borderRadius: '5px',
        fontSize: '13px',
        fontFamily: fontFamily,
        boxShadow: 'none'
    }),
    menu: (provided, state) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: state.selectProps.fontWeight,
        fontFamily: fontFamily,
        zIndex: 11
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        display: state.data.disabled ? 'none' : provided.display
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 11 }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: state.selectProps.hideIndicator ? 'none' : 'flex'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: state.selectProps.hideDropdownIndicator ? 'none' : 'flex'
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: state.selectProps.hideDropdownIndicator ? 'none' : 'flex'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        color: primary,
        padding: '0 3px',
        maxHeight: `calc(${state.selectProps.maxControlHeight} - 2px)`,
        minHeight: `calc(${state.selectProps.minControlHeight} - 2px)`,
        justifyContent: 'flex-start'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary
    }),
    multiValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary,
        backgroundColor: backgroundGrey,
        border: `1px solid ${grey}`
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        fontWeight: 600,
        fontSize: '90%',
        color: state.isDisabled ? state.selectProps.disableControlColor || primary : primary
    }),
    menuList: (provided) => ({
        ...provided,
        '::-webkit-scrollbar': {
            width: '4px',
            height: '0px',
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-track': {
            background: disabledBackground,
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb': {
            background: disabledGrey,
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: grey
        }
    })
};

export const Dropdown: React.FC<DropdownProps> = ({
    testId,
    value,
    disabled = false,
    isMulti = false,
    onChange,
    options,
    placeholder,
    label,
    isClearable = true,
    menuPortalTarget,
    marginBottom = '',
    controlBackgroundColor,
    hideIndicator = false,
    controlHeight = '38px',
    minControlHeight = '38px',
    // This is to allow three times a multi-select value height
    maxControlHeight = '86px',
    disableControlColor,
    controlBorderColor,
    noControlBorder,
    fontWeight = 500,
    menuPlacement = 'auto',
    cursor = 'auto',
    closeOnSelect = true,
    hideDropdownIndicator = false,
    selectedIds = []
}) => (
    <div
        data-testid={`dropdown-${testId}`}
        className={styles.dropdownFieldWrapper}
        style={{ marginBottom, cursor }}
    >
        {label && <div data-testid={`dropdown-${testId}-label`} className={styles.dropdownLabel}>{label}</div>}
        <Select
            className={styles.dropdownField}
            classNamePrefix='ark-dropdown'
            isDisabled={disabled}
            value={value}
            onChange={onChange}
            isMulti={isMulti}
            options={options}
            isClearable={isClearable}
            placeholder={placeholder}
            styles={customStyles}
            menuPortalTarget={menuPortalTarget}
            isOptionDisabled={({ disabled }) => !!disabled}
            controlBackgroundColor={controlBackgroundColor}
            controlBorderColor={controlBorderColor}
            hideIndicator={hideIndicator}
            controlHeight={controlHeight}
            disableControlColor={disableControlColor}
            noControlBorder={noControlBorder}
            fontWeight={fontWeight}
            minControlHeight={minControlHeight}
            maxControlHeight={maxControlHeight}
            menuPlacement={menuPlacement}
            closeMenuOnSelect={closeOnSelect}
            hideDropdownIndicator={hideDropdownIndicator}
            selectedIds={selectedIds}
        />
    </div>
);
