import React, { useCallback } from 'react';
import classnames from 'classnames';

import { PlaybookPageView } from '../../../admin/playbook/store';
import { IconTooltip } from '../../tooltip';
import styles from './PlaybookSideMenu.module.scss';
import { Choice, ChoiceLeft, ChoiceRight } from '../../icons';

interface SuggestedChangesViewButtonsProps {
    toggleView: (view: PlaybookPageView) => void;
    currentView: PlaybookPageView;
    testId?: string;
}

export const SuggestedChangesViewButtons: React.FC<SuggestedChangesViewButtonsProps> = ({ toggleView, currentView, testId = 'suggested-changes-view' }) => {

    const isSelected = useCallback((view: PlaybookPageView) => currentView === view, [currentView]);

    return (
        <div className={styles.viewButtonsWrapper}>
            <button className={classnames(styles.viewButton, { [styles.selected]: isSelected(PlaybookPageView.CHANGES) })} onClick={() => toggleView(PlaybookPageView.CHANGES)} data-testid={`${testId}-changes-button`}>
                <IconTooltip icon={ChoiceLeft} fontSize={30} content='Suggested Changes' />
            </button>
            <button className={classnames(styles.viewButton, { [styles.selected]: isSelected(PlaybookPageView.SPLIT) })} onClick={() => toggleView(PlaybookPageView.SPLIT)} data-testid={`${testId}-split-button`}>
                <IconTooltip icon={Choice} fontSize={30} content='Split' />
            </button>
            <button className={classnames(styles.viewButton, { [styles.selected]: isSelected(PlaybookPageView.CURRENT) })} onClick={() => toggleView(PlaybookPageView.CURRENT)} data-testid={`${testId}-playbook-button`}>
                <IconTooltip icon={ChoiceRight} fontSize={30} content='Current Playbook' />
            </button>
        </div>
    );
};
