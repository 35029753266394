import { flatten, isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Icon } from '../../shared/icon/Icon';
import { SaveFile, WarningSign } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Spinner } from '../../shared/spinner/Spinner';
import { Wizard } from '../../shared/wizard/Wizard';
import styles from './Reports.module.scss';
import { SaveTemplateModal } from './SaveTemplateModal';
import { ConfigureFields, OpinionReportFields, ReportFilter, Select } from './report-tabs';
import { ReportPage, ReportTab, generateReportStarted, getBackConfirmationModalOpen, getOpinionScope, getReportFields, getReportUpdated, getSelectedTab, getSelectedTemplate, getSelectedTemplateId, saveReportTemplateStarted, setSelectedTab, toggleConfirmationModal, toggleSaveTemplateModalOpen, setReportPage, resetReport, getCurrentScope, setCurrentScope } from './store';
import { FeaturePermission } from '../../admin/users/store';
import { getUserHasFeaturePermission } from '../../auth/login/store';

export const OpinionReportsWizard: React.FC = () => {
    const dispatch = useAppDispatch();

    const testId = 'generate-opinion-report';

    const selectedTab = useAppSelector(getSelectedTab);
    const opinionScopes = useAppSelector(getOpinionScope);
    const currentScope = useAppSelector(getCurrentScope);
    const reportFields = useAppSelector(getReportFields);
    const backConfirmationOpen = useAppSelector(getBackConfirmationModalOpen);
    const reportUpdated = useAppSelector(getReportUpdated);
    const selectedTemplate = useAppSelector(getSelectedTemplate);
    const selectedTemplateId = useAppSelector(getSelectedTemplateId);

    const reportTabs = Object.values(ReportTab);
    const selectedTabIndex = reportTabs.indexOf(selectedTab);
    const returnPage = selectedTemplateId ? ReportPage.TEMPLATES : ReportPage.SELECT;

    const selectedScopeIndex = isNull(currentScope) ? 0 : opinionScopes.indexOf(currentScope);

    const hasEditOpinionReportPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.EDIT_OPINION_REPORT_TEMPLATES]));
    const hasGenerateOpinionReportPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.GENERATE_OPINION_REPORTS]));

    const canMakeTemplateChanges = hasEditOpinionReportPermission;

    const selectTab = useCallback((tab: ReportTab, isMouseClick?: boolean) => {
        if ((tab === ReportTab.FIELDS || tab === ReportTab.CONFIGURE) && isMouseClick) {
            dispatch(setCurrentScope(opinionScopes[0]));
        }
        dispatch(setSelectedTab(tab));
    }, [dispatch, opinionScopes]);

    const previous = useCallback(() => {
        if ((selectedTab === ReportTab.FIELDS || selectedTab === ReportTab.CONFIGURE) && opinionScopes.length > 1) {
            if (selectedScopeIndex > 0) {
                dispatch(setCurrentScope(opinionScopes[selectedScopeIndex - 1]));
            } else {
                if (selectedTab === ReportTab.CONFIGURE && reportTabs[selectedTabIndex - 1] === ReportTab.FIELDS) {
                    dispatch(setCurrentScope(opinionScopes[opinionScopes.length - 1]));
                }
                selectTab(reportTabs[selectedTabIndex - 1]);
            }
        } else {
            selectTab(reportTabs[selectedTabIndex - 1]);
        }
    }, [reportTabs, selectTab, selectedTabIndex, dispatch, opinionScopes, selectedTab, selectedScopeIndex]);

    const next = useCallback(() => {
        if ((selectedTab === ReportTab.FIELDS || selectedTab === ReportTab.CONFIGURE) && opinionScopes.length > 1) {
            if (selectedScopeIndex < opinionScopes.length - 1) {
                dispatch(setCurrentScope(opinionScopes[selectedScopeIndex + 1]));
            } else {
                if (selectedTab === ReportTab.FIELDS && reportTabs[selectedTabIndex + 1] === ReportTab.CONFIGURE) {
                    dispatch(setCurrentScope(opinionScopes[0]));
                }
                selectTab(reportTabs[selectedTabIndex + 1]);
            }
        } else {
            selectTab(reportTabs[selectedTabIndex + 1]);
        }
    }, [reportTabs, selectTab, selectedTabIndex, dispatch, opinionScopes, selectedTab, selectedScopeIndex]);

    const generate = useCallback(() => dispatch(generateReportStarted()), [dispatch]);
    const goBack = useCallback(() => {
        dispatch(setReportPage(returnPage));
        dispatch(resetReport(returnPage));
    }, [dispatch, returnPage]);

    const toggleBackConfirmationModal = useCallback((value: boolean) => dispatch(toggleConfirmationModal(value)), [dispatch]);
    const onBackClick = useCallback(() => reportUpdated ? toggleBackConfirmationModal(true) : goBack(), [toggleBackConfirmationModal, goBack, reportUpdated]);
    const openSaveModal = useCallback(() => dispatch(toggleSaveTemplateModalOpen(true)), [dispatch]);

    const saveTemplate = useCallback(() => dispatch(saveReportTemplateStarted()), [dispatch]);
    const firstTabIncomplete = useMemo(() => !opinionScopes.length, [opinionScopes]);

    const wizardTabs = useMemo(() => reportTabs.map(tab => ({
        label: tab,
        selected: tab === selectedTab,
        onClick: () => selectTab(tab, true),
        disabled: tab !== ReportTab.SELECT && firstTabIncomplete
    })), [selectTab, selectedTab, reportTabs, firstTabIncomplete]);

    const includesReportFields = useMemo(() => flatten(Object.values(reportFields)).length > 0, [reportFields]);

    const saveAndGenerateDisabled = useMemo(() => firstTabIncomplete || !includesReportFields, [firstTabIncomplete, includesReportFields]);

    const saveAndGenerateDisabledTooltip = useCallback((action: string) => {
        const disabledArray = [];
        if (!opinionScopes) {
            disabledArray.push(`You must select the scope of the opinion before you can ${action} a report template`);
        }
        if (!includesReportFields) {
            disabledArray.push(`You must select at least one field before you can ${action} a report template`);
        }
        if (!hasGenerateOpinionReportPermission && action === 'generate') {
            disabledArray.push('You are not permitted to generate a report');
        }
        return disabledArray;
    }, [includesReportFields, opinionScopes, hasGenerateOpinionReportPermission]);

    const showSaveButton = (isNull(selectedTemplate) || reportUpdated) && canMakeTemplateChanges;
    const showSaveExistingButton = (!isNull(selectedTemplate) && !!reportUpdated) && canMakeTemplateChanges;

    const saveLabel = `Save${selectedTemplate ? ' As' : ''}`;

    const wizardButtons = useMemo(() => [
        {
            label: 'Back',
            onClick: onBackClick,
            showButton: true,
            displayLeft: true,
            disabled: false,
            disabledTooltip: null
        },
        {
            label: saveLabel,
            onClick: openSaveModal,
            disabled: saveAndGenerateDisabled,
            disabledTooltip: saveAndGenerateDisabledTooltip('save'),
            showButton: showSaveButton,
            displayLeft: true
        },
        {
            label: 'Save',
            onClick: saveTemplate,
            disabled: saveAndGenerateDisabled,
            showButton: showSaveExistingButton,
            displayLeft: true,
            disabledTooltip: null,
        },
        {
            label: 'Previous',
            onClick: previous,
            disabled: selectedTabIndex === 0,
            disabledTooltip: null,
            showButton: selectedTabIndex !== 0
        },
        {
            label: 'Next',
            onClick: next,
            disabled: firstTabIncomplete,
            disabledTooltip: null,
            showButton: selectedTabIndex !== reportTabs.length - 1
        },
        {
            label: 'Generate',
            onClick: generate,
            disabled: saveAndGenerateDisabled || !hasGenerateOpinionReportPermission,
            disabledTooltip: saveAndGenerateDisabledTooltip('generate'),
            showButton: selectedTabIndex === reportTabs.length - 1
        }
    ], [onBackClick, previous, next, generate, reportTabs, selectedTabIndex, saveAndGenerateDisabled, saveAndGenerateDisabledTooltip, openSaveModal, showSaveButton, saveTemplate, showSaveExistingButton, saveLabel, firstTabIncomplete, hasGenerateOpinionReportPermission]);

    const reportContent = useMemo(() => {
        switch (selectedTab) {
            case ReportTab.SELECT:
                return <Select />;
            case ReportTab.FIELDS:
                return <OpinionReportFields />;
            case ReportTab.CONFIGURE:
                return <ConfigureFields />;
            case ReportTab.FILTER:
                return <ReportFilter />;
            default:
                return <Spinner />;
        }
    }, [selectedTab]);

    return (
        <div className={styles.wizardWrapper} data-testid={`${testId}-wrapper`}>
            <Wizard
                buttons={wizardButtons}
                tabs={wizardTabs}
                title='Generate a Report'
                testId={testId}
            >
                <div className={styles.contentWrapper}>
                    {reportContent}
                </div>
            </Wizard>
            <ConfirmationModal
                isOpen={backConfirmationOpen}
                closeModal={() => toggleBackConfirmationModal(false)}
                confirm={goBack}
                confirmLabel='Yes'
                closeLabel='Cancel'
                testId='report-back'
            >
                <div className={styles.backModalWrapper}>
                    <ModalHeader
                        testId='unsaved-changes-modal'
                        label='Unsaved Changes'
                        icon={SaveFile}
                    />
                    <div className={styles.backModalWarning}>
                        <Icon icon={WarningSign} />
                        <div data-testid='unsaved-changes-warning-header' className={styles.backModalWarningTitle}>You have unsaved changes.</div>
                    </div>
                    <div data-testid='unsaved-changes-warning-content' className={styles.backModalContent}>Are you sure you want to go back without saving?</div>
                </div>
            </ConfirmationModal>
            <SaveTemplateModal />
        </div>
    );
};
