import React from 'react';
import { noop } from 'lodash/fp';
import { RawDraftContentState } from 'draft-js';

import { Icon } from '../../icon/Icon';
import { CaretDown, CaretSide } from '../../icons';
import { WYSIWYG, isEmpty } from '../../wysiwyg/WYSIWYG';
import styles from './PlaybookBuild.module.scss';

const { lightGrey, primary } = styles;

interface AbstractProps {
    abstract: RawDraftContentState | null;
    updateAbstract: (value: RawDraftContentState | null) => void;
    abstractOpen?: boolean;
    toggleAbstractOpen?: () => void;
    showBorder?: boolean;
    minHeight?: string;
    showCollapseToggle?: boolean;
    maxHeight?: string;
    testId?: string;
}

export const Abstract: React.FC<AbstractProps> = ({ abstractOpen = true, toggleAbstractOpen = noop, abstract, updateAbstract, showBorder = true, minHeight = '', showCollapseToggle = true, maxHeight = '40vh', testId = 'playbook' }) => {

    const setPlaybookAbstract = (rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            updateAbstract(null);
        } else {
            updateAbstract(rawValue);
        }
    };

    return (
        <div className={styles.buildAbstractWrapper} style={{ border: showBorder ? `1px solid ${primary}` : 'none', minHeight }} data-testid={`${testId}-abstract-wrapper`}>
            <div className={styles.buildAbstractTitleWrapper}>
                <div className={styles.buildAbstract} data-testid={`${testId}-abstract-title`}>Abstract:</div>
                {showCollapseToggle &&
                    <div onClick={toggleAbstractOpen} className={styles.abstractCollapseIcon} data-testid={`${testId}-abstract-collapse-icon`}>
                        <Icon icon={abstractOpen ? CaretDown : CaretSide} fontSize={20} />
                    </div>
                }
            </div>
            {abstractOpen &&
                <div className={styles.abstractWysiwygWrapper} data-testid={`${testId}-abstract-content`}>
                    <WYSIWYG
                        content={abstract}
                        updateContent={setPlaybookAbstract}
                        showBorder={false}
                        borderColor={lightGrey}
                        height='fit-content'
                        maxHeight={maxHeight}
                    />
                </div>
            }
        </div>
    );
};
