import React, { useCallback, useMemo, useState } from 'react';
import { getOr, isEqual, isNull } from 'lodash/fp';

import { AgencyDatasetFieldType, DatasetType, TableDatasetFieldType } from '../../../../store';
import { addCollapsedColumn, addOpenFieldSection, addTableDatasetRow, AnnexInstance, AnnexInstanceField, duplicateTableDatasetRow, getAnnexDefinitionFieldIds, getOpenFieldsAndSections, OpenFieldSection, removeCollapsedColumn, removeOpenFieldSection, removeTableDatasetRow, SingleInstanceField, TableDatasetInstance } from '../../../store';
import mlStyles from '../MLDatasetInstance.module.scss';
import styles from '../../../../../shared/datasets/SharedStyling.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/react-redux';
import { Scrollable } from '../../../../../shared/scrollable/Scrollable';
import { HiddenFields } from '../../../../../admin/my-datasets/store';
import { useSingleHiddenFields } from '../../../../../../hooks/useHiddenSingleFields';
import { InstanceMLData } from '../../../../store/mlTypes';
import { MLAnswer } from '../../MLDataModal';
import { MLTableHeader } from './MLTableHeader';
import { MLTableCell } from './MLTableCell';
import { OverflowTooltip } from '../../../../../shared/tooltip';
import { DuplicateButton } from '../../../../../shared/button/DuplicateButton';
import { DeleteButton } from '../../../../../shared/button/DeleteButton';
import { PlusButton } from '../../../../../shared/button/PlusButton';

interface MLTableInstanceProps {
    datasetInstance: TableDatasetInstance | AnnexInstance;
    modalInstance?: boolean;
    isEditing: boolean;
    parentFieldId: string;
    isUpdating: boolean;
    instanceExecutedDateMatchesParent: boolean;
    mlData: InstanceMLData;
    hiddenFields?: HiddenFields;
    datasetHidden?: boolean;
}

export interface SelectedMLQuestion {
    systemId: string;
    key: string;
}

export const MLTableInstance: React.FC<MLTableInstanceProps> = ({ datasetInstance, isEditing, isUpdating, modalInstance, parentFieldId, hiddenFields, datasetHidden, instanceExecutedDateMatchesParent, mlData }) => {
    const dispatch = useAppDispatch();

    const annexFieldIds = useAppSelector(getAnnexDefinitionFieldIds);
    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const datasetId = useMemo(() => isNull(datasetInstance.annexDefinitionId) ? datasetInstance.datasetId! : datasetInstance.annexDefinitionId, [datasetInstance]);
    const removeRow = (rowId: string) => dispatch(removeTableDatasetRow(datasetId, parentFieldId, rowId, modalInstance));
    const addRow = () => dispatch(addTableDatasetRow(datasetId, parentFieldId, modalInstance));
    const duplicateRow = (rowId: string) => dispatch(duplicateTableDatasetRow(datasetId, parentFieldId, rowId, modalInstance));

    const singleRow = Object.values(datasetInstance.datasetFields).length === 1;
    const { fieldsUpdated, datasetRows, datasetFields } = datasetInstance;

    const sortedDatasetRows = useMemo(() => datasetRows.reduce((acc: [string, SingleInstanceField[]][], cur) => {
        const orderedRow = Object.entries(datasetFields).find(([rowId]) => rowId === cur);
        if (orderedRow) {
            acc.push(orderedRow);
        }
        return acc;
    }, []), [datasetRows, datasetFields]);

    const addRowVisible = useMemo(() => isEditing || isUpdating, [isEditing, isUpdating]);

    const getSectionOpen = useCallback((fieldSection: OpenFieldSection) => openFieldsAndSections.some(openSection => isEqual(openSection, fieldSection)), [openFieldsAndSections]);

    const toggleSection = useCallback((fieldSection: OpenFieldSection) => {
        const sectionOpen = openFieldsAndSections.find(openSection => isEqual(openSection, fieldSection));
        sectionOpen ? dispatch(removeOpenFieldSection(sectionOpen)) : dispatch(addOpenFieldSection(fieldSection));
    }, [dispatch, openFieldsAndSections]);

    const collapsedColumns = useMemo(() => datasetInstance.collapsedColumns || [], [datasetInstance.collapsedColumns]);

    const getColumnCollapsed = useCallback((fieldId: string) => collapsedColumns.some(id => isEqual(id, fieldId)), [collapsedColumns]);
    const toggleCollapseColumn = useCallback((fieldId: string) => {
        const columnIsCollapsed = collapsedColumns.find(id => isEqual(id, fieldId));
        columnIsCollapsed ? dispatch(removeCollapsedColumn(datasetId, parentFieldId, fieldId, modalInstance)) : dispatch(addCollapsedColumn(datasetId, parentFieldId, fieldId, modalInstance));
    }, [dispatch, collapsedColumns, datasetId, parentFieldId, modalInstance]);

    const currentHiddenDatasetFields = useMemo(() => hiddenFields && hiddenFields[datasetInstance.datasetId!] || [], [datasetInstance, hiddenFields]);

    const hiddenSingleFields = useSingleHiddenFields(currentHiddenDatasetFields);

    const columnIsHidden = useCallback((id: string) => datasetHidden || !!hiddenSingleFields.find(({ fieldId }) => fieldId === id), [hiddenSingleFields, datasetHidden]);

    const tableFirstCell = useCallback((fieldId: string) => (Object.values(datasetInstance.datasetFields)[0].map((field: SingleInstanceField | AnnexInstanceField) => field.id)).filter((id: string) => !hiddenSingleFields.map(({ fieldId }) => fieldId).includes(id!))[0] === fieldId, [hiddenSingleFields, datasetInstance]);
    const tableMLData = useMemo(() => mlData.instanceMLData.filter(({ datasetType }) => datasetType === DatasetType.TABLE), [mlData]);
    const getColumnHasMl = useCallback((fieldSystemId: string) => tableMLData.find(({ systemId, isEdgeCase }) => isEqual(systemId, fieldSystemId) && !isEdgeCase), [tableMLData]);
    const getCellMl = useCallback((fieldSystemId: string, fieldRowId: string) => {
        if (['crossAccelerationApplies', 'specifiedIndebtednessBankDepositsExcluded'].includes(fieldSystemId)) {
            return tableMLData.find(({ systemId, isEdgeCase }) => isEqual(systemId, fieldSystemId) && !isEdgeCase);
        }
        return tableMLData.find(({ systemId, rowId, isEdgeCase }) => isEqual(systemId, fieldSystemId) && isEqual(rowId, fieldRowId) && !isEdgeCase);
    }, [tableMLData]);

    const mlDataForTable = useMemo(() => tableMLData.filter(data => data.datasetId === datasetId), [datasetId, tableMLData]);

    const edgeCaseMlCell = useMemo(() => mlDataForTable.find(instanceIdentifier => !!instanceIdentifier.isEdgeCase), [mlDataForTable]);

    const [mlQuestionKey, setMlQuestionKey] = useState<SelectedMLQuestion | null>(null);

    const setHoveredQuestion = useCallback((value: SelectedMLQuestion | null) => setMlQuestionKey(value), []);

    return (
        <div className={styles.tableInstanceWrapper} data-testid='table-dataset-instance-wrapper' style={{ height: 'fit-content' }}>
            <div>
                {mlDataForTable.map(({ mlValue, question, key, systemId }) => {
                    return (
                        <div key={key} className={mlStyles.mlTableDatasetQueryWrapper} onMouseEnter={() => setHoveredQuestion({ systemId, key })} onMouseLeave={() => setHoveredQuestion(null)}>
                            <OverflowTooltip className={mlStyles.mlTableDatasetQuestion} overlayText={question} />
                            <MLAnswer answer={mlValue} depth={1} />
                        </div>
                    );
                })}
            </div>
            <Scrollable>
                <div className={styles.tableHeader}>
                    {Object.values(datasetInstance.datasetFields)[0].map((field: SingleInstanceField) => {
                        const { label, description, refLabel, settings, id, type, systemId } = field;
                        const columnMlData = getColumnHasMl(systemId);
                        const columnHidden = columnIsHidden(id!);
                        const columnCollapsed = getColumnCollapsed(id!);
                        if (columnHidden) {
                            return null;
                        }
                        const isFirstCell = tableFirstCell(id!);
                        return (
                            <MLTableHeader
                                key={id!}
                                label={label}
                                description={description}
                                refLabel={refLabel}
                                showRef={settings.showRef}
                                id={id!}
                                type={type as TableDatasetFieldType | AgencyDatasetFieldType}
                                isFirstCell={isFirstCell}
                                aliasLabel={getOr(undefined, 'aliasLabel', field)}
                                columnCollapsed={columnCollapsed}
                                toggleCollapseColumn={toggleCollapseColumn}
                                columnMlData={columnMlData}
                                mlQuestionKey={mlQuestionKey}
                                edgeCaseMlCell={edgeCaseMlCell}
                            />
                        );
                    })}
                    <div className={styles.actionRowHeader} />
                    <div className={styles.actionRowHeader} />
                </div>
                <div className={styles.tableBody} style={{ height: 'calc(100% - 50px)', maxHeight: '350px' }}>
                    <Scrollable maxHeight='350px'>
                        {sortedDatasetRows.map(([rowId, row]) => (
                            <div className={styles.tableRow} key={rowId}>
                                {row.map((field, index) => {
                                    const cellMlData = getCellMl(field.systemId, rowId);
                                    const columnCollapsed = getColumnCollapsed(field.id!);
                                    const columnHidden = columnIsHidden(field.id!);
                                    if (columnHidden) {
                                        return null;
                                    }
                                    const isFirstCell = tableFirstCell(field.id!);
                                    return (
                                        <MLTableCell
                                            key={field.id!}
                                            field={field}
                                            index={index}
                                            isFirstCell={isFirstCell}
                                            isEditing={isEditing}
                                            isUpdating={isUpdating}
                                            rowId={rowId}
                                            fieldsUpdated={fieldsUpdated}
                                            annexFieldIds={annexFieldIds}
                                            isAnnexInstance={!!datasetInstance.annexDefinitionId}
                                            datasetId={datasetId}
                                            modalInstance={modalInstance}
                                            toggleSection={toggleSection}
                                            getSectionOpen={getSectionOpen}
                                            parentFieldId={parentFieldId}
                                            columnCollapsed={columnCollapsed}
                                            instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent}
                                            cellMlData={cellMlData}
                                            mlQuestionKey={mlQuestionKey}
                                            edgeCaseMlCell={edgeCaseMlCell}
                                        />
                                    );
                                })}
                                <div className={styles.actionRowCell}>
                                    {isEditing && <DuplicateButton onClick={() => duplicateRow(rowId)} disabled={!isEditing} />}
                                </div>
                                <div className={styles.actionRowCell}>
                                    {!singleRow && <DeleteButton onClick={() => removeRow(rowId)} disabled={!isEditing} />}
                                </div>
                            </div>
                        ))}
                        {addRowVisible &&
                            <div className={styles.addTableRow}>
                                <PlusButton onClick={addRow} fontSize={20} />
                            </div>
                        }
                    </Scrollable>
                </div>
            </Scrollable>
        </div>
    );
};
