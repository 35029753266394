import React from 'react';

import { NettingOpinionInstance } from './store/types';
import { ArkOpinion } from '../my-opinions/store';
import { GeneralSection, ScopeSection, AssumptionsSection, NettingEarlyTerminationSection, NettingLegalIssuesSection, TransactionCoverageSection, CounterpartyCoverageSection, ClauseSection, NettingAgreementCoverageSection, NettingSFTTransactions } from './sections';
import { NettingOtherIssuesSection } from './sections/NettingOtherIssues';

interface NettingInstanceProps {
    instance: NettingOpinionInstance;
    opinion: ArkOpinion;
    isEditing: boolean;
    isUpdating: boolean;
    width: number;
}

export const NettingInstance: React.FC<NettingInstanceProps> = ({ instance, opinion, isEditing, isUpdating, width }) => {
    const { content: { general, assumptions, earlyTermination, legalIssues, sftTransactions, otherIssues, agreementCoverage, transactionCoverage, counterpartyCoverage, additionalProvisions }, fieldsUpdated } = instance;
    return (
        <div>
            <GeneralSection general={general} opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <ScopeSection opinion={opinion} isEditing={isEditing} isUpdating={isUpdating} />
            <AssumptionsSection assumptions={assumptions} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <NettingEarlyTerminationSection earlyTermination={earlyTermination} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <NettingLegalIssuesSection legalIssues={legalIssues} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <NettingSFTTransactions sftTransactions={sftTransactions} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <NettingOtherIssuesSection otherIssues={otherIssues} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <NettingAgreementCoverageSection agreementCoverage={agreementCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <TransactionCoverageSection transactionCoverage={transactionCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
            <CounterpartyCoverageSection counterpartyCoverage={counterpartyCoverage} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} jurisdiction={opinion.jurisdiction} />
            <ClauseSection additionalProvisions={additionalProvisions} opinion={opinion} width={width} isEditing={isEditing} isUpdating={isUpdating} fieldsUpdated={fieldsUpdated} />
        </div>
    );
};
