import React, { useMemo, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNull } from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { changeCurrentView, PlaybookBuilderTab, getCurrentView, getPlaybook, getPlaybookCanNext, getPlaybookCanSave, getSelectedTab, PlaybookPage, PlaybookView, setPlaybookPage, setSelectedTab, togglePlaybookPublishModalOpen, getPlaybookCanPublish, getPlaybookVersionUpdated, togglePlaybookSaveModalOpen, getPlaybookByIdStarted, resetPlaybookState, getCurrentPlaybook, getPlaybookIsDraft, savePlaybookStarted, toggleDeviation, getOpenDeviations, getDisablePublishAndVersionControl, getReadOnlyProvisionLinksModalOpen, toggleReadOnlyProvisionLinkModal } from '../store';
import { Spinner } from '../../../shared/spinner/Spinner';
import { Wizard } from '../../../shared/wizard/Wizard';
import styles from '../Playbook.module.scss';
import { General } from './tabs/General';
import { Build } from './tabs/Build';
import { Playbook } from '../../../shared/playbook/view/Playbook';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';
import { fetchAllAgreementTypesStarted, fetchAvailableDocumentNamesStarted } from '../../documents/store';
import { Bookshelf, HamburgerMenu, PlaybookIcon, SaveFile, Table, ArrowLeft, ArrowRight } from '../../../shared/icons';
import { Tags } from '../../../shared/playbook/tags/Tags';
import { PlaybookPublishModal } from './PlaybookPublishModal';
import { PlaybookSaveModal } from './PlaybookSaveModal';
import { PlaybookRouteParams } from '../Playbook';
import { useWindowResize } from '../../../../hooks/useWindowResize';

export const Builder: React.FC<RouteComponentProps<PlaybookRouteParams>> = ({ match: { params } }) => {
    const dispatch = useAppDispatch();

    const testId = 'admin-playbook';

    const selectedTab = useAppSelector(getSelectedTab);
    const canSave = useAppSelector(getPlaybookCanSave);
    const canNext = useAppSelector(getPlaybookCanNext);
    const canPublish = useAppSelector(getPlaybookCanPublish);
    const versionUpdated = useAppSelector(getPlaybookVersionUpdated);
    const playbook = useAppSelector(getPlaybook);
    const currentPlaybook = useAppSelector(getCurrentPlaybook);
    const isDraft = useAppSelector(getPlaybookIsDraft);
    const openDeviations = useAppSelector(getOpenDeviations);
    const disablePublishAndVersionControl = useAppSelector(getDisablePublishAndVersionControl);
    const readOnlyProvisionLinksModalOpen = useAppSelector(getReadOnlyProvisionLinksModalOpen);
    const [screenWidth] = useWindowResize();

    const { name, playbookId } = playbook;

    const playbookTabs = Object.values(PlaybookBuilderTab);
    const selectedTabIndex = playbookTabs.indexOf(selectedTab);

    const selectTab = useCallback((tab: PlaybookBuilderTab) => dispatch(setSelectedTab(tab)), [dispatch]);
    const previous = useCallback(() => selectTab(playbookTabs[selectedTabIndex - 1]), [playbookTabs, selectTab, selectedTabIndex]);
    const next = useCallback(() => selectTab(playbookTabs[selectedTabIndex + 1]), [playbookTabs, selectTab, selectedTabIndex]);
    const myPlaybooks = useCallback(() => dispatch(setPlaybookPage(PlaybookPage.MY_PLAYBOOKS)), [dispatch]);
    const viewPlaybookTimeline = useCallback(() => dispatch(getPlaybookByIdStarted(currentPlaybook!.playbookId)), [dispatch, currentPlaybook]);

    const currentView = useAppSelector(getCurrentView);
    const isTableView = currentView === PlaybookView.TABLE;
    const changeViewLabel = useMemo(() => isTableView ? 'List view' : 'Table view', [isTableView]);
    const changeViewIcon = useMemo(() => isTableView ? HamburgerMenu : Table, [isTableView]);
    const changeView = useCallback(() => dispatch(changeCurrentView(isTableView ? PlaybookView.LIST : PlaybookView.TABLE)), [dispatch, isTableView]);

    const publishPlaybook = useCallback(() => dispatch(togglePlaybookPublishModalOpen(true)), [dispatch]);
    const savePlaybook = useCallback(() => (!isDraft && !disablePublishAndVersionControl) ? dispatch(togglePlaybookSaveModalOpen(true)) : dispatch(savePlaybookStarted()), [dispatch, isDraft, disablePublishAndVersionControl]);

    const togglePlaybookDeviation = useCallback((sectionId: string) => dispatch(toggleDeviation(sectionId)), [dispatch]);

    const toggleLinkedProvisionModal = useCallback((sectionId: string) => dispatch(toggleReadOnlyProvisionLinkModal(sectionId)), [dispatch]);

    const nextDisabledTooltip = useMemo(() => !name ? ['You must provide a title for your playbook'] : [], [name]);

    const saveDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (!name) {
            disabledArray.push('You must provide a title for your playbook');
        }
        if (versionUpdated) {
            disabledArray.push('You cannot save if the version has been updated, only publish');
        }
        if (!canSave) {
            disabledArray.push('You have not updated the playbook');
        }
        return disabledArray;
    }, [name, versionUpdated, canSave]);

    const canNextDisabled = useMemo(() => selectedTabIndex === playbookTabs.length - 1 || canNext, [selectedTabIndex, playbookTabs, canNext]);

    const publishDisabledTooltip = useMemo(() => {
        let disabledArray = [];
        if (disablePublishAndVersionControl) {
            disabledArray.push('You can not publish while a draft version exists');
            return disabledArray;
        }
        if (!!playbookId && !versionUpdated && (currentPlaybook && !currentPlaybook.isDraft)) {
            disabledArray.push('You can only publish if the version has been updated');
        }
        if (!playbookId && !name) {
            disabledArray.push('You can only publish if the playbook has a title');
        }
        if (!canPublish && versionUpdated) {
            disabledArray.push('The version cannot be less than the existing playbook');
        }
        return disabledArray;
    }, [versionUpdated, playbookId, name, canPublish, currentPlaybook, disablePublishAndVersionControl]);

    const showBackButton = useMemo(() => !isNull(currentPlaybook), [currentPlaybook]);

    useFetchStarted([fetchAvailableDocumentNamesStarted(), fetchAllAgreementTypesStarted()]);

    const wizardTabs = useMemo(() => playbookTabs.map((tab) => ({
        label: tab,
        selected: tab === selectedTab,
        onClick: () => selectTab(tab),
        disabled: !canNext
    })), [selectTab, selectedTab, playbookTabs, canNext]);

    const BUTTON_WIDTH = 150;
    const PADDING = 20;

    const buttonWidth = useMemo(() => {
        let multiplier = showBackButton ? 4 : 3;
        if (selectedTabIndex !== 0) {
            multiplier++;
        }
        if (selectedTabIndex !== 0 && selectedTabIndex !== playbookTabs.length - 1) {
            multiplier++;
        }
        if (playbookId) {
            multiplier++;
        }
        return BUTTON_WIDTH * multiplier;
    }, [selectedTabIndex, showBackButton, playbookTabs.length, playbookId]);

    const showIconButtons = useMemo(() => buttonWidth >= (screenWidth - PADDING), [buttonWidth, screenWidth]);

    const wizardButtons = useMemo(() => [
        {
            label: 'Back',
            onClick: viewPlaybookTimeline,
            showButton: showBackButton,
            displayLeft: true,
            disabled: false,
            disabledTooltip: null,
            icon: ArrowLeft,
            showIconButton: showIconButtons
        },
        {
            label: 'My Playbooks',
            onClick: myPlaybooks,
            showButton: true,
            displayLeft: true,
            disabled: false,
            disabledTooltip: null,
            icon: Bookshelf,
            showIconButton: showIconButtons
        },
        {
            label: changeViewLabel,
            onClick: changeView,
            displayLeft: true,
            disabled: false,
            disabledTooltip: null,
            showButton: selectedTabIndex !== 0,
            icon: changeViewIcon,
            showIconButton: showIconButtons
        },
        {
            label: 'Previous',
            onClick: previous,
            disabled: selectedTabIndex === 0,
            disabledTooltip: null,
            showButton: selectedTabIndex !== 0,
            icon: ArrowLeft,
            showIconButton: showIconButtons
        },
        {
            label: 'Next',
            onClick: next,
            disabled: !canNextDisabled,
            disabledTooltip: nextDisabledTooltip,
            showButton: selectedTabIndex !== playbookTabs.length - 1,
            icon: ArrowRight,
            showIconButton: showIconButtons
        },
        {
            label: 'Save',
            onClick: savePlaybook,
            disabled: !canSave,
            showButton: !!playbookId,
            disabledTooltip: saveDisabledTooltip,
            icon: SaveFile,
            showIconButton: showIconButtons
        },
        {
            label: 'Publish',
            onClick: publishPlaybook,
            disabled: !canPublish || disablePublishAndVersionControl,
            showButton: true,
            disabledTooltip: publishDisabledTooltip,
            icon: PlaybookIcon,
            showIconButton: showIconButtons
        }

    ], [viewPlaybookTimeline, showBackButton, previous, next, playbookTabs, selectedTabIndex, myPlaybooks, changeView, changeViewLabel, changeViewIcon, canSave, publishPlaybook, canNextDisabled, nextDisabledTooltip, savePlaybook, playbookId, canPublish, saveDisabledTooltip, publishDisabledTooltip, disablePublishAndVersionControl, showIconButtons]);

    const playbookContent = useMemo(() => {
        switch (selectedTab) {
            case PlaybookBuilderTab.GENERAL:
                return <General testId='admin-playbook-wizard-general-tab' />;
            case PlaybookBuilderTab.BUILD:
                return <Build showIconButtons={showIconButtons} testId='admin-playbook-wizard-build-tab' />;
            case PlaybookBuilderTab.TAGS:
                return <Tags openDeviations={openDeviations} toggleDeviation={togglePlaybookDeviation} testId='admin-playbook-wizard-tags-tab' />;
            case PlaybookBuilderTab.PREVIEW:
                return <Playbook
                    playbook={playbook}
                    currentView={currentView}
                    openDeviations={openDeviations}
                    toggleDeviation={togglePlaybookDeviation}
                    provisionLinkModalOpen={readOnlyProvisionLinksModalOpen}
                    toggleLinkedProvisionModal={toggleLinkedProvisionModal}
                    testId='admin-playbook-wizard-preview-tab'
                />;
            default:
                return <Spinner />;
        }
    }, [selectedTab, playbook, currentView, openDeviations, togglePlaybookDeviation, showIconButtons, readOnlyProvisionLinksModalOpen, toggleLinkedProvisionModal]);

    const playbookIdInParamsExists = useMemo(() => (params.playbookId && !playbook.playbookId), [playbook, params]);
    useEffect(() => {
        if (!playbook.playbookId && params.playbookId && playbookIdInParamsExists) {
            dispatch(getPlaybookByIdStarted(parseInt(params.playbookId), false));
        }
    }, [params, playbook, playbookIdInParamsExists, dispatch]);

    useEffect(() => () => {
        dispatch(resetPlaybookState());
    }, [dispatch]);

    const subtitle = useMemo(() => {
        if (playbook.playbookId) {
            return currentPlaybook && currentPlaybook.isDraft ? 'Published as Draft' : 'Update';
        }
        return 'Create New';
    }, [currentPlaybook, playbook]);

    const playbookName = useMemo(() => selectedTab !== PlaybookBuilderTab.GENERAL ? ` - ${name}` : '', [name, selectedTab]);

    const title = useMemo(() => `Playbook Builder (${subtitle})${playbookName}`, [subtitle, playbookName]);

    return (
        <div className={styles.playbookWizardWrapper}>
            <Wizard
                buttons={wizardButtons}
                tabs={wizardTabs}
                title={title}
                testId={testId}
                headerIcon={PlaybookIcon}
                padding='0 10px'
                overflowHeader
            >
                <div className={styles.contentWrapper}>
                    {playbookContent}
                </div>
            </Wizard>
            <PlaybookSaveModal />
            <PlaybookPublishModal />
        </div>
    );
};
