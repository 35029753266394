import { toast } from 'react-toastify';
import { all, call, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';

import { fetchAllDropdownLists, publishDropdownList } from '../../../../services/dropdownList';
import { fetchAllDropdownListsFailed, fetchAllDropdownListsSuccessful, publishDropdownListFailed, publishDropdownListSuccessful } from './actions';
import { getDropdownList } from './selectors';
import { DropdownList, DropdownListDB, DropdownListsActionTypes } from './types';

export function* attemptUploadNewDropdownList() {
    try {
        const dropdownList: DropdownList = yield select(getDropdownList);
        const allDropdownLists: DropdownListDB[] = yield call(publishDropdownList, dropdownList);
        yield put(publishDropdownListSuccessful(allDropdownLists));
        toast(`Successfully published ${dropdownList.name} list to Dropdown Lists`);
    } catch (e) {
        yield put(publishDropdownListFailed((e as Error).message));
        toast.error('Failed to publish dropdown list. Please try again.');
    }
}

function* publishDropdownListWatcher() {
    yield takeLeading(DropdownListsActionTypes.PUBLISH_DROPDOWN_LIST_STARTED, attemptUploadNewDropdownList);
}

export function* attemptFetchAllDropdownLists() {
    try {
        const dropdownLists: DropdownListDB[] = yield call(fetchAllDropdownLists);
        yield put(fetchAllDropdownListsSuccessful(dropdownLists));
    } catch (e) {
        yield put(fetchAllDropdownListsFailed((e as Error).message));
        toast.error('Unable to fetch the latest dropdown list options. Please try again.');
    }
}

function* fetchAllDropdownListsWatcher() {
    yield takeEvery(DropdownListsActionTypes.FETCH_ALL_DROPDOWN_LISTS_STARTED, attemptFetchAllDropdownLists);
}

export function* dropdownListSaga() {
    yield all([
        fork(publishDropdownListWatcher),
        fork(fetchAllDropdownListsWatcher),
    ]);
}
