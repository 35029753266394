import { isNull, isUndefined } from 'lodash/fp';

import { ScrollableElement } from '../components/shared/scrollable/Scrollable';
import { useWindowResize } from './useWindowResize';

export const useMenuPlacement = (ref: React.RefObject<HTMLDivElement>, maxMenuHeight: number, scrollableWrapper?: ScrollableElement | null) => {
    const [, screenHeight] = useWindowResize();
    // screenHeight subtract the height of fixed elements (button wrapper and margin/padding of page)
    const instanceWrapperBottom = screenHeight - 89;
    if (ref && ref.current) {
        const { offsetTop, offsetHeight } = ref.current;
        const elementOffset = offsetHeight + offsetTop + maxMenuHeight;
        if (isNull(scrollableWrapper) || isUndefined(scrollableWrapper)) {
            const spaceForDropdown = elementOffset < instanceWrapperBottom;
            return spaceForDropdown ? 'bottom' : 'top';
        }
        const { scrollTop, scrollableHeight } = scrollableWrapper;
        const spaceForDropdown = elementOffset - scrollTop < scrollableHeight;
        return spaceForDropdown ? 'bottom' : 'top';
    }
    return 'auto';
};

