import React from 'react';

import styles from './DropdownLists.module.scss';
import { DropdownListModal } from './DropdownListModal';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getDropdownList, getIsSaving, getModalOpen, toggleModal } from './store';
import { DropdownListTable } from './DropdownListTable';
import { PlusButton } from '../../shared/button/PlusButton';

export const DropdownLists: React.FC = () => {
    const dispatch = useAppDispatch();

    const handleModalToggle = (isOpen: boolean) => dispatch(toggleModal(isOpen));
    const openModal = () => handleModalToggle(true);
    const closeModal = () => handleModalToggle(false);

    const modalOpen = useAppSelector(getModalOpen);
    const isSaving = useAppSelector(getIsSaving);
    const dropdownList = useAppSelector(getDropdownList);
    const confirmDisabled = (dropdownList && !dropdownList.name) || isSaving;

    return (
        <div className={styles.dropdownListsWrapper} data-testid='dropdown-lists-wrapper'>
            <div className={styles.dropdownListsTitleContainer} data-testid='dropdown-lists-title-container'>
                <div className={styles.dropdownListsTitle} data-testid='dropdown-lists-header'>Dropdown Lists</div>
                <PlusButton onClick={openModal} fontSize={22} testId='new-dropdown-list' />
            </div>
            <DropdownListTable />
            <DropdownListModal
                isOpen={modalOpen}
                closeModal={closeModal}
                confirmDisabled={confirmDisabled}
            />
        </div>
    );
};
