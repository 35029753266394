import { toast } from 'react-toastify';
import { put, takeEvery, all, fork, select, call, debounce } from 'redux-saga/effects';
import { flow, isNull, isUndefined, set, unset } from 'lodash/fp';
import { push } from 'redux-first-history';

import { fetchPaginatedThirdPartyCompanies, searchThirdPartyCompanies, upsertThirdPartyCompany } from '../../../../services/dora';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { fetchPaginatedThirdPartyCompaniesSuccessful, fetchPaginatedThirdPartyCompaniesStarted, fetchPaginatedThirdPartyCompaniesFailed, saveThirdPartyCompanySuccessful, saveThirdPartyCompanyFailed, searchThirdPartyCompaniesStarted, searchThirdPartyCompaniesSuccessful, searchThirdPartyCompaniesFailed, upsertThirdPartyLeiEntity, changeThirdPartyView, setSearchEntityValue, fetchFuzzyAutoCompletionsFailed, fetchFuzzyAutoCompletionsSuccessful } from './actions';
import { getThirdPartyCompaniesColumnSort, getThirdPartyCompaniesFilters, getThirdPartyCompaniesPageNumber, getThirdPartyCompaniesPageSize, getSelectedThirdPartyCompany, getThirdPartyCompanySearchPageNumber, getThirdPartyCompanySearchPageSize, getSearchValue, getSearchType, getSearchResults, getAvailableThirdPartyCompaniesFilters, getAvailableThirdPartyCompaniesColumnSort, getAvailableThirdPartyCompaniesPageSize, getAvailableThirdPartyCompaniesPageNumber, getThirdPartyView, getIsSearching } from './selectors';
import { DoraThirdPartyActionTypes, DoraThirdPartyView } from './types';
import { Address, CompanyEntity, Entity, EntityDB, EntityType, FuzzyAutoCompletions, Lei, SearchEntityBy } from '../../../admin/entity/store';
import { initialAIField } from '../../../constants/entity';
import { fetchFuzzyAutoCompletions } from '../../../../services/entity';

const stripEntity = (entity: EntityDB): Entity => flow(
    unset('clientId'),
    unset('active'),
    unset('createdBy'),
    unset('modifiedBy'),
    unset('modifiedDate'),
    unset('createdDate')
)(entity);

const updateSearchResults = (lei: string, searchResults: Lei[]) => searchResults.map(result => result.lei === lei ? set('entityExists', true, result) : result);

export function* attemptFetchPaginatedThirdPartyCompanies({ payload }: ReturnType<typeof fetchPaginatedThirdPartyCompaniesStarted>) {
    try {
        const { pageNumber, active } = payload;
        let filters: TableFilters = yield select(getThirdPartyCompaniesFilters);
        let columnSort: ColumnSort | undefined = yield select(getThirdPartyCompaniesColumnSort);
        let pageSize: number = yield select(getThirdPartyCompaniesPageSize);
        if (active === 0) {
            filters = yield select(getAvailableThirdPartyCompaniesFilters);
            columnSort = yield select(getAvailableThirdPartyCompaniesColumnSort);
            pageSize = yield select(getAvailableThirdPartyCompaniesPageSize);
        }
        const { thirdPartyCompanies, total }: { thirdPartyCompanies: EntityDB[]; total: number; } = yield call(fetchPaginatedThirdPartyCompanies, { filters, pageNumber, pageSize, active, columnSort });
        const strippedThirdPartyCompanies = thirdPartyCompanies.map(company => stripEntity(company)) as CompanyEntity[];
        yield put(fetchPaginatedThirdPartyCompaniesSuccessful(strippedThirdPartyCompanies, total, pageNumber));
    } catch (e) {
        toast.error('Unable to fetch third party companies. Please try again.');
        yield put(fetchPaginatedThirdPartyCompaniesFailed((e as Error).message));
    }
}

function* fetchPaginatedThirdPartyCompaniesWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.FETCH_PAGINATED_THIRD_PARTY_COMPANIES_STARTED, attemptFetchPaginatedThirdPartyCompanies);
}

export function* paginationThirdPartyCompaniesNext() {
    const pageNumber: number = yield select(getThirdPartyCompaniesPageNumber);
    yield put(fetchPaginatedThirdPartyCompaniesStarted(pageNumber + 1));
}

function* paginationThirdPartyCompaniesNextWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.THIRD_PARTY_COMPANIES_PAGINATION_NEXT, paginationThirdPartyCompaniesNext);
}

export function* paginationThirdPartyCompaniesPrevious() {
    const pageNumber: number = yield select(getThirdPartyCompaniesPageNumber);
    yield put(fetchPaginatedThirdPartyCompaniesStarted(pageNumber - 1));
}

function* paginationThirdPartyCompaniesPreviousWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.THIRD_PARTY_COMPANIES_PAGINATION_PREVIOUS, paginationThirdPartyCompaniesPrevious);
}

export function* thirdPartyCompaniesTableConfigUpdated() {
    yield put(fetchPaginatedThirdPartyCompaniesStarted(1));
}

function* thirdPartyCompaniesTableConfigUpdatedWatcher() {
    yield takeEvery([DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT, DoraThirdPartyActionTypes.CLEAR_THIRD_PARTY_COMPANIES_TABLE_FILTERS, DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_TABLE_FILTERS, DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_PAGE_SIZE], thirdPartyCompaniesTableConfigUpdated);
}

export function* paginationAvailableThirdPartyCompaniesNext() {
    const pageNumber: number = yield select(getAvailableThirdPartyCompaniesPageNumber);
    yield put(fetchPaginatedThirdPartyCompaniesStarted(pageNumber + 1, 0));
}

function* paginationAvailableThirdPartyCompaniesNextWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.AVAILABLE_THIRD_PARTY_COMPANIES_PAGINATION_NEXT, paginationAvailableThirdPartyCompaniesNext);
}

export function* paginationAvailableThirdPartyCompaniesPrevious() {
    const pageNumber: number = yield select(getAvailableThirdPartyCompaniesPageNumber);
    yield put(fetchPaginatedThirdPartyCompaniesStarted(pageNumber - 1, 0));
}

function* paginationAvailableThirdPartyCompaniesPreviousWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.AVAILABLE_THIRD_PARTY_COMPANIES_PAGINATION_PREVIOUS, paginationAvailableThirdPartyCompaniesPrevious);
}

export function* availableThirdPartyCompaniesTableConfigUpdated() {
    yield put(fetchPaginatedThirdPartyCompaniesStarted(1, 0));
}

function* availableThirdPartyCompaniesTableConfigUpdatedWatcher() {
    yield takeEvery([DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_COLUMN_SORT, DoraThirdPartyActionTypes.CLEAR_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS, DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_TABLE_FILTERS, DoraThirdPartyActionTypes.SET_AVAILABLE_THIRD_PARTY_COMPANIES_PAGE_SIZE], availableThirdPartyCompaniesTableConfigUpdated);
}

export function* attemptSaveThirdPartyCompany() {
    const selectedTab: DoraThirdPartyView = yield select(getThirdPartyView);
    const active = selectedTab === DoraThirdPartyView.ACTIVE_THIRD_PARTIES ? 1 : 0;
    let pageNumber: number = yield select(getThirdPartyCompaniesPageNumber);
    let filters: TableFilters = yield select(getThirdPartyCompaniesFilters);
    let columnSort: ColumnSort | undefined = yield select(getThirdPartyCompaniesColumnSort);
    let pageSize: number = yield select(getThirdPartyCompaniesPageSize);
    if (active === 0) {
        pageNumber = yield select(getAvailableThirdPartyCompaniesPageNumber);
        filters = yield select(getAvailableThirdPartyCompaniesFilters);
        columnSort = yield select(getAvailableThirdPartyCompaniesColumnSort);
        pageSize = yield select(getAvailableThirdPartyCompaniesPageSize);
    }
    const thirdPartyCompany: CompanyEntity = yield select(getSelectedThirdPartyCompany);
    try {
        const { thirdPartyCompanies, total }: { thirdPartyCompanies: EntityDB[]; total: number; } = yield call(upsertThirdPartyCompany, { thirdPartyCompany, filters, pageNumber, pageSize, active, columnSort });
        const strippedThirdPartyCompanies = thirdPartyCompanies.map(company => stripEntity(company)) as CompanyEntity[];
        yield put(saveThirdPartyCompanySuccessful(strippedThirdPartyCompanies, total, pageNumber));
        toast(`Successfully ${!isUndefined(thirdPartyCompany.entityId) ? 'updated' : 'saved'} Third Party Company.`);
    } catch (e) {
        toast.error(`Unable to ${!isUndefined(thirdPartyCompany.entityId) ? 'update' : 'save'} Third Party Company. Please try again.`);
        yield put(saveThirdPartyCompanyFailed((e as Error).message));
    }
}

function* attemptSaveThirdPartyCompanyWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.SAVE_THIRD_PARTY_COMPANY_STARTED, attemptSaveThirdPartyCompany);
}

export function* attemptSearchThirdParties({ payload }: ReturnType<typeof searchThirdPartyCompaniesStarted>) {
    try {
        const pageNumber = payload;
        const pageSize: number = yield select(getThirdPartyCompanySearchPageSize);
        const value: string = yield select(getSearchValue);
        const type: SearchEntityBy = yield select(getSearchType);
        const { entities, total }: { entities: Lei[]; total: number; } = yield call(searchThirdPartyCompanies, { type, value, pageSize, pageNumber });
        yield put(searchThirdPartyCompaniesSuccessful(entities, total, pageNumber));
    } catch (e) {
        yield put(searchThirdPartyCompaniesFailed((e as Error).message));
        toast.error('There was a problem with the search. Please try again.');
    }
}

function* searchEntitiesWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.SEARCH_THIRD_PARTY_COMPANIES_STARTED, attemptSearchThirdParties);
}

export function* attemptUploadLeiThirdParty({ payload }: ReturnType<typeof upsertThirdPartyLeiEntity>) {
    try {
        const { legalName, legalAddress, lei, headquartersAddress, jurisdiction, bic, otherNames } = payload;
        const otherAddresses: Address[] = headquartersAddress.address1 !== legalAddress.address1 || headquartersAddress.postalCode !== legalAddress.postalCode ? [headquartersAddress] : [];
        const thirdPartyCompany: CompanyEntity = {
            entityId: null,
            name: legalName,
            content: {
                lei: lei,
                address: legalAddress,
                jurisdiction: jurisdiction,
                bic: isNull(bic) ? [] : bic,
                MiFIDClassification: initialAIField,
                emirClassification: initialAIField,
                otherAddresses: otherAddresses,
                counterpartyType: initialAIField,
                opinionCounterpartyType: initialAIField,
                email: '',
                phoneNumber: '',
                mobileNumber: '',
                faxNumber: '',
                primaryContact: '',
                branches: [],
                dateOfRoiIntegration: '',
                dateOfRoiDeletion: '',
                competentAuthority: null,
                doraEntityHierarchy: null,
                totalAssetsValue: null,
                otherNames: otherNames.filter(({ name }) => !isNull(name)).map(({ name }) => name!),
                opinionSubCounterpartyType: null
            },
            type: EntityType.COMPANY,
            myCompany: 0,
            doraInScope: 0,
            doraMaintainerROI: 0,
            onWatchlist: 0,
            canBeAgent: 0,
            isDoraThirdParty: 1
        };
        const selectedTab: DoraThirdPartyView = yield select(getThirdPartyView);
        const active = selectedTab === DoraThirdPartyView.ACTIVE_THIRD_PARTIES ? 1 : 0;
        let pageNumber: number = yield select(getThirdPartyCompaniesPageNumber);
        let filters: TableFilters = yield select(getThirdPartyCompaniesFilters);
        let columnSort: ColumnSort | undefined = yield select(getThirdPartyCompaniesColumnSort);
        let pageSize: number = yield select(getThirdPartyCompaniesPageSize);
        if (active === 0) {
            pageNumber = yield select(getAvailableThirdPartyCompaniesPageNumber);
            filters = yield select(getAvailableThirdPartyCompaniesFilters);
            columnSort = yield select(getAvailableThirdPartyCompaniesColumnSort);
            pageSize = yield select(getAvailableThirdPartyCompaniesPageSize);
        }
        yield call(upsertThirdPartyCompany, { thirdPartyCompany, filters, pageSize, pageNumber, active, columnSort });
        const searchResults: Lei[] = yield select(getSearchResults);
        const updatedSearchResults = updateSearchResults(lei, searchResults);
        yield put(searchThirdPartyCompaniesSuccessful(updatedSearchResults));
        yield put(fetchPaginatedThirdPartyCompaniesStarted());
        toast(`Successfully added ${legalName} to third party companies`);
    } catch (e) {
        toast.error(`Unable to add ${payload.legalName} to entities. Please try again.`);
    }
}

function* createLeiThirdPartyCompanyWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.UPSERT_THIRD_PARTY_LEI_ENTITY, attemptUploadLeiThirdParty);
}

export function* redirectThirdPartiesTab({ payload }: ReturnType<typeof changeThirdPartyView>) {
    const navigationPath = `/dora/data-management/third-party-companies${payload === DoraThirdPartyView.ACTIVE_THIRD_PARTIES ? '/active' : '/available'}`;
    yield put(push(navigationPath));
}

function* redirectThirdPartiesTabWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.CHANGE_THIRD_PARTY_VIEW, redirectThirdPartiesTab);
}

export function* searchPaginationNext() {
    const pageNumber: number = yield select(getThirdPartyCompanySearchPageNumber);
    yield put(searchThirdPartyCompaniesStarted(pageNumber + 1));
}

function* searchPaginationNextWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.THIRD_PARTY_COMPANIES_SEARCH_PAGINATION_NEXT, searchPaginationNext);
}

export function* searchPaginationPrevious() {
    const pageNumber: number = yield select(getThirdPartyCompanySearchPageNumber);
    yield put(searchThirdPartyCompaniesStarted(pageNumber - 1));
}

function* searchPaginationPreviousWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.THIRD_PARTY_COMPANIES_SEARCH_PAGINATION_PREVIOUS, searchPaginationPrevious);
}

export function* searchPageSizeUpdated() {
    yield put(searchThirdPartyCompaniesStarted());
}

function* searchPageSizeUpdatedWatcher() {
    yield takeEvery(DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANIES_SEARCH_PAGE_SIZE, searchPageSizeUpdated);
}

export function* attemptFetchFuzzyAutoCompletions({ payload }: ReturnType<typeof setSearchEntityValue>) {
    try {
        const searchType: SearchEntityBy | null = yield select(getSearchType);
        const isSearching: boolean = yield select(getIsSearching);
        if (!isNull(searchType) && searchType === SearchEntityBy.COMPANY_NAME && !isSearching) {
            const fuzzyAutoCompletions: FuzzyAutoCompletions = yield call(fetchFuzzyAutoCompletions, { value: payload });
            yield put(fetchFuzzyAutoCompletionsSuccessful(fuzzyAutoCompletions));
        }
    } catch (e) {
        yield put(fetchFuzzyAutoCompletionsFailed((e as Error).message));
    }
}

function* fetchFuzzyAutoCompletionsWatcher() {
    yield debounce(1000, DoraThirdPartyActionTypes.SET_THIRD_PARTY_COMPANY_SEARCH_VALUE, attemptFetchFuzzyAutoCompletions);
}

export function* doraThirdPartySaga() {
    yield all([
        fork(paginationThirdPartyCompaniesNextWatcher),
        fork(paginationThirdPartyCompaniesPreviousWatcher),
        fork(fetchPaginatedThirdPartyCompaniesWatcher),
        fork(thirdPartyCompaniesTableConfigUpdatedWatcher),
        fork(attemptSaveThirdPartyCompanyWatcher),
        fork(createLeiThirdPartyCompanyWatcher),
        fork(searchEntitiesWatcher),
        fork(searchPaginationNextWatcher),
        fork(searchPaginationPreviousWatcher),
        fork(searchPageSizeUpdatedWatcher),
        fork(redirectThirdPartiesTabWatcher),
        fork(paginationAvailableThirdPartyCompaniesNextWatcher),
        fork(paginationAvailableThirdPartyCompaniesPreviousWatcher),
        fork(availableThirdPartyCompaniesTableConfigUpdatedWatcher),
        fork(fetchFuzzyAutoCompletionsWatcher)
    ]);
}
