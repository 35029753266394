import { isNull } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { ANALYSIS_MARGIN, DATASET_INSTANCE_HEADER, NAV_BAR, PAGE_MARGIN_PADDING_BORDER, useSplitView } from '../../../hooks/useSplitView';
import { downloadCDMPreview, getCdmJsonPreview, getDocumentAnalysisView, toggleCDMPreview } from '../../documents/my-documents/store';
import { Button } from '../../shared/button/Button';
import { Document } from '../../shared/icons';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import styles from './DatasetInstance.module.scss';
import { DatasetModal } from './modal-instance/DatasetModal';

const MODAL_HEADER_HEIGHT = 78;
const MODAL_PADDING = 20;
const TITLE_MARGIN = 40;
const TABLE_HEADER = 45;
const heightOffset = NAV_BAR + MODAL_HEADER_HEIGHT + MODAL_PADDING + TITLE_MARGIN + TABLE_HEADER;
const widthOffset = PAGE_MARGIN_PADDING_BORDER;

export const CdmPreviewModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const cdmPreview = useAppSelector(getCdmJsonPreview);

    const closeModal = () => dispatch(toggleCDMPreview(null));
    const download = () =>!isNull(cdmPreview) && dispatch(downloadCDMPreview(cdmPreview));

    const downloadPreview = () => {
        download();
        closeModal();
    };

    const isOpen = useMemo(() => !isNull(cdmPreview), [cdmPreview]);

    const [width, height] = useSplitView(documentAnalysisView, widthOffset, heightOffset);

    return (
        <DatasetModal
            isOpen={isOpen}
            closeModal={closeModal}
            topOffset={`${DATASET_INSTANCE_HEADER + NAV_BAR + ANALYSIS_MARGIN}px`}
        >
            <div className={styles.cdmPreviewWrapper} data-testid='cdm-preview-wrapper' style={{ width }}>
                <ModalHeader label='CDM Preview' icon={Document} />
                <div className={styles.cdmPreview} style={{ height: `${height}px` }}>
                    <Scrollable maxHeight={`${height}px`}>
                        <pre style={{ padding: '0 15px' }}>{JSON.stringify(cdmPreview, null, 2)}</pre>
                    </Scrollable>
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={closeModal} label='Close' />
                    <Button onClick={downloadPreview} label='Download' />
                </div>
            </div>
        </DatasetModal>
    );
};
