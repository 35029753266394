import { RawDraftContentState } from 'draft-js';

import { Clause, FuzzyMatchTags, ClauseTagDropdownOptions, ClientTag } from '../../../clause-library/store';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';
import { ScrollableElement } from '../../../shared/scrollable/Scrollable';
import { SmileyIndicator } from '../../../shared/analytics/SingleIndicator';

export enum OpinionInstanceActionTypes {
    UPDATE_FIELD_VALUE = 'UPDATE_OPINION_INSTANCE_FIELD_VALUE',
    UPDATE_TABLE_CELL_FIELD_VALUE = 'UPDATE_OPINION_INSTANCE_TABLE_CELL_FIELD_VALUE',
    UPDATE_DROPDOWN_FIELD_VALUE = 'UPDATE_OPINION_INSTANCE_DROPDOWN_FIELD_VALUE',
    UPDATE_WYSIWYG_FIELD_VALUE = 'UPDATE_OPINION_INSTANCE_WYSIWYG_FIELD_VALUE',
    UPDATE_SINGLE_TOGGLE_FIELD_VALUE = 'UPDATE_SINGLE_TOGGLE_FIELD_VALUE',
    UPDATE_COUNTERPARTY_COVERAGE_FIELD_VALUE = 'UPDATE_COUNTERPARTY_COVERAGE_FIELD_VALUE',
    UPDATE_CUSTOM_TOGGLE_LABEL = 'UPDATE_CUSTOM_TOGGLE_LABEL',
    ADD_OPINION_FIELD_CUSTOM_CHILD = 'ADD_OPINION_FIELD_CUSTOM_CHILD',
    TOGGLE_REF_MODAL_OPEN = 'TOGGLE_OPINION_FIELD_REF_MODAL_OPEN',
    UPDATE_FIELD_REF = 'UPDATE_OPINION_FIELD_REF',
    UPDATE_FIELD_PAGE_REF = 'UPDATE_OPINION_FIELD_PAGE_REF',
    UPDATE_FIELD_PAGE_REF_VERIFIED = 'UPDATE_OPINION_FIELD_PAGE_REF_VERIFIED',
    ADD_FIELD_PAGE_REF = 'ADD_OPINION_FIELD_PAGE_REF',
    REMOVE_FIELD_PAGE_REF = 'REMOVE_OPINION_FIELD_PAGE_REF',
    SET_FIELDS_UPDATED_VALUE = 'SET_OPINION_INSTANCE_FIELDS_UPDATED_VALUE',
    UPSERT_OPINION_INSTANCE_STARTED = 'UPSERT_OPINION_INSTANCE_STARTED',
    UPSERT_OPINION_INSTANCE_SUCCESSFUL = 'UPSERT_OPINION_INSTANCE_SUCCESSFUL',
    UPSERT_OPINION_INSTANCE_FAILED = 'UPSERT_OPINION_INSTANCE_FAILED',
    UPSERT_SIGN_OFF_OPINION_INSTANCE_STARTED = 'UPSERT_SIGN_OFF_OPINION_INSTANCE_STARTED',
    UPSERT_SIGN_OFF_OPINION_INSTANCE_SUCCESSFUL = 'UPSERT_SIGN_OFF_OPINION_INSTANCE_SUCCESSFUL',
    UPSERT_SIGN_OFF_OPINION_INSTANCE_FAILED = 'UPSERT_SIGN_OFF_OPINION_INSTANCE_FAILED',
    EDIT_OPINION_INSTANCE = 'EDIT_OPINION_INSTANCE',
    UPDATE_OPINION_INSTANCE = 'UPDATE_OPINION_INSTANCE',
    OPEN_OPINION_INSTANCE_BY_ID = 'OPEN_OPINION_INSTANCE_BY_ID',
    OPEN_OPINION_INSTANCE_STARTED = 'OPEN_OPINION_INSTANCE_STARTED',
    OPEN_OPINION_INSTANCE_SUCCESSFUL = 'OPEN_OPINION_INSTANCE_SUCCESSFUL',
    OPEN_OPINION_INSTANCE_FAILED = 'OPEN_OPINION_INSTANCE_FAILED',
    INSTANCE_UPDATED = 'OPINION_INSTANCE_UPDATED',
    TOGGLE_UPDATED_INSTANCE_MODAL = 'TOGGLE_UPDATED_OPINION_INSTANCE_MODAL',
    RESET_OPINION_INSTANCE = 'RESET_OPINION_INSTANCE',
    SET_ALL_INSTANCES = 'SET_ALL_OPINION_INSTANCES',
    TOGGLE_SAVE_INSTANCE_MODAL = 'TOGGLE_SAVE_OPINION_INSTANCE_MODAL',
    OPEN_TIMELINE_INSTANCE_STARTED = 'OPEN_TIMELINE_OPINION_INSTANCE_STARTED',
    OPEN_TIMELINE_INSTANCE_SUCCESSFUL = 'OPEN_TIMELINE_OPINION_INSTANCE_SUCCESSFUL',
    OPEN_TIMELINE_INSTANCE_FAILED = 'OPEN_TIMELINE_OPINION_INSTANCE_FAILED',
    SET_TIMELINE = 'SET_OPINION_TIMELINE',
    SET_TIMELINE_INSTANCE = 'SET_OPINION_TIMELINE_INSTANCE',
    TOGGLE_OPEN_FIELD_SECTION = 'TOGGLE_OPINION_OPEN_FIELD_SECTION',
    REMOVE_ALL_FIELD_SECTIONS = 'REMOVE_ALL_OPINION_FIELD_SECTIONS',
    TOGGLE_LINKED_OPINION_MODAL_OPEN = 'TOGGLE_LINKED_OPINION_MODAL_OPEN',
    SET_SCROLLABLE_WRAPPER = 'SET_OPINION_INSTANCE_SCROLLABLE_WRAPPER',
    SET_OPINION_SIGN_OFF = 'SET_OPINION_SIGN_OFF',
    TOGGLE_SIGN_OFF_MODAL_OPEN = 'TOGGLE_SIGN_OFF_MODAL_OPEN',
    UPDATE_SIGN_OFF_FINAL_NOTES = 'UPDATE_SIGN_OFF_FINAL_NOTES',
    TOGGLE_SUPPORT_OPINION_UPLOAD_MODAL_OPEN = 'TOGGLE_SUPPORT_OPINION_UPLOAD_MODAL_OPEN',
    TOGGLE_SUB_COUNTERPARTY_TYPE_MODAL_OPEN = 'TOGGLE_SUB_COUNTERPARTY_TYPE_MODAL_OPEN',
    UPDATE_INCLUDED_INSTANCE_SUB_COUNTERPARTY_TYPES = 'UPDATE_INCLUDED_INSTANCE_SUB_COUNTERPARTY_TYPES',
    SET_SUB_COUNTERPARTY_PARENT = 'SET_SUB_COUNTERPARTY_PARENT',
    // Opinion Clause
    FETCH_ALL_OPINION_CLAUSES_STARTED = 'FETCH_ALL_OPINION_CLAUSES_STARTED',
    FETCH_ALL_OPINION_CLAUSES_SUCCESSFUL = 'FETCH_ALL_OPINION_CLAUSES_SUCCESSFUL',
    FETCH_ALL_OPINION_CLAUSES_FAILED = 'FETCH_ALL_OPINION_CLAUSES_FAILED',
    SET_OPINION_CLAUSES_TABLE_FILTERS = 'SET_OPINION_CLAUSES_TABLE_FILTERS',
    CLEAR_OPINION_CLAUSES_TABLE_FILTERS = 'CLEAR_OPINION_CLAUSES_TABLE_FILTERS',
    SET_OPINION_CLAUSES_TABLE_COLUMN_SORT = 'SET_OPINION_CLAUSES_TABLE_COLUMN_SORT',
    OPINION_CLAUSES_PAGINATION_NEXT = 'OPINION_CLAUSES_PAGINATION_NEXT',
    OPINION_CLAUSES_PAGINATION_PREVIOUS = 'OPINION_CLAUSES_PAGINATION_PREVIOUS',
    OPEN_OPINION_CLAUSE = 'OPEN_OPINION_CLAUSE',
    TOGGLE_OPINION_CLAUSE_MODAL = 'TOGGLE_OPINION_CLAUSE_MODAL',
    TOGGLE_OPINION_CLAUSE_DESCRIPTION = 'TOGGLE_OPINION_CLAUSE_DESCRIPTION',
    UPDATE_OPINION_CLAUSE = 'UPDATE_OPINION_CLAUSE',
    SAVE_OPINION_CLAUSE_STARTED = 'SAVE_OPINION_CLAUSE_STARTED',
    SAVE_OPINION_CLAUSE_SUCCESSFUL = 'SAVE_OPINION_CLAUSE_SUCCESSFUL',
    SAVE_OPINION_CLAUSE_FAILED = 'SAVE_OPINION_CLAUSE_FAILED',
    SET_OPINION_CLAUSE_CAN_SAVE = 'SET_OPINION_CLAUSE_CAN_SAVE',
    UPDATE_OPINION_CLAUSE_TAGS = 'UPDATE_OPINION_CLAUSE_TAGS',
    FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_STARTED = 'FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_STARTED',
    FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_SUCCESSFUL = 'FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_SUCCESSFUL',
    FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_FAILED = 'FETCH_OPINION_CLAUSE_LIBRARY_DROPDOWN_OPTIONS_FAILED',
    OPINION_CLAUSE_UPDATE_ALL_CLIENT_TAGS = 'OPINION_CLAUSE_UPDATE_ALL_CLIENT_TAGS',
    UPDATE_OPINION_CLAUSE_TAG_TERM = 'UPDATE_OPINION_CLAUSE_TAG_TERM',
    CHECK_OPINION_CLAUSE_FUZZY_MATCH = 'CHECK_OPINION_CLAUSE_FUZZY_MATCH',
    ADD_OPINION_CLAUSE_CLIENT_TAG_STARTED = 'ADD_OPINION_CLAUSE_CLIENT_TAG_STARTED',
    ADD_OPINION_CLAUSE_CLIENT_TAG_SUCCESSFUL = 'ADD_OPINION_CLAUSE_CLIENT_TAG_SUCCESSFUL',
    ADD_OPINION_CLAUSE_CLIENT_TAG_FAILED = 'ADD_OPINION_CLAUSE_SYSTEM_TAG_FAILED',
    ADD_OPINION_CLAUSE_SYSTEM_TAG_STARTED = 'ADD_OPINION_CLAUSE_SYSTEM_TAG_STARTED',
    ADD_OPINION_CLAUSE_SYSTEM_TAG_SUCCESSFUL = 'ADD_OPINION_CLAUSE_SYSTEM_TAG_SUCCESSFUL',
    ADD_OPINION_CLAUSE_SYSTEM_TAG_FAILED = 'ADD_OPINION_CLAUSE_CLIENT_TAG_FAILED',
    SET_OPINION_CLAUSE_FUZZY_MATCH_MODAL_OPEN = 'SET_OPINION_CLAUSE_FUZZY_MATCH_MODAL_OPEN',
    UPDATE_OPINION_CLAUSE_FUZZY_MATCH_RESULTS = 'UPDATE_OPINION_CLAUSE_FUZZY_MATCH_RESULTS',
    TOGGLE_LINK_OPINION_CLAUSE_MODAL = 'TOGGLE_LINK_OPINION_CLAUSE_MODAL',
    FETCH_OPINION_AVAILABLE_CLAUSES = 'FETCH_OPINION_AVAILABLE_CLAUSES',
    FETCH_OPINION_AVAILABLE_CLAUSES_SUCCESSFUL = 'FETCH_OPINION_AVAILABLE_CLAUSES_SUCCESSFUL',
    FETCH_OPINION_AVAILABLE_CLAUSES_FAILED = 'FETCH_OPINION_AVAILABLE_CLAUSES_FAILED',
    DUPLICATE_OPINION_CLAUSE = 'DUPLICATE_OPINION_CLAUSE',
    LINK_OPINION_CLAUSE = 'LINK_OPINION_CLAUSE',
    UPDATE_AGREEMENT_TYPE_SEARCH = 'UPDATE_AGREEMENT_TYPE_SEARCH',
    ADD_OPINION_INSTANCE_SIGN_OFF_NOTES = 'ADD_OPINION_INSTANCE_SIGN_OFF_NOTES',
    SET_SIGN_OFF_NOTES_MODAL_OPEN = 'SET_SIGN_OFF_NOTES_MODAL_OPEN',
    UPDATE_CURRENT_SIGN_OFF_SMILEY_CONTENT = 'UPDATE_CURRENT_SIGN_OFF_SMILEY_CONTENT',
    UPDATE_CURRENT_SIGN_OFF_DROPDOWN_VALUE = 'UPDATE_CURRENT_SIGN_OFF_DROPDOWN_VALUE',
    UPDATE_CURRENT_SIGN_OFF_CONTENT = 'UPDATE_CURRENT_SIGN_OFF_CONTENT',
    SIGN_OFF_INSTANCE_UPDATED = 'SIGN_OFF_INSTANCE_UPDATED',
    RESET_SIGN_OFF_OPINION_INSTANCE = 'RESET_SIGN_OFF_OPINION_INSTANCE',
    SET_NOTE_TO_EDIT = 'SET_NOTE_TO_EDIT',
    RESET_OPINION_CLAUSES_TABLE = 'RESET_OPINION_CLAUSES_TABLE',
    UPDATE_INSTANCE_ADDITIONAL_OPINION_IDS = 'UPDATE_INSTANCE_ADDITIONAL_OPINION_IDS'
}

export interface OpinionClause {
    clauseId: number;
    clauseTitle: string;
    clauseDescription: string;
    content: RawDraftContentState | null;
    tags: string[];
}

export type OpinionFieldValue = string | boolean | string[] | null | Date | RawDraftContentState | boolean[] | undefined;

export enum OpinionInstanceType {
    NETTING = 'netting',
    COLLATERAL_PROVIDER = 'collateral provider',
    COLLATERAL_TAKER = 'collateral taker',
    REPO = 'repo',
    STOCK_LENDING = 'stock lending',
    REPO_STOCK_LENDING = 'repo/stock lending',
}

export enum OpinionInstanceFieldType {
    DROPDOWN = 'dropdown',
    ENTITY = 'entity',
    DROPDOWN_DETAILS = 'dropdown details',
    TOGGLE = 'toggle',
    MULTI_TOGGLE = 'multi-toggle',
    TEXT = 'text',
    LONGTEXT = 'longtext',
    DATE = 'date',
    WYSIWYG = 'wysiwyg'
}

export interface BasicSignOffSmileyAndNote {
    smileyValue: SmileyIndicator;
    notes: RawDraftContentState | null;
    createdBy: number | null;
    name: string;
    createdDate: string;
}

export interface DropdownDetailsSignOffSmileyAndNote extends BasicSignOffSmileyAndNote {
    dropdownValue: string[] | null;
}

export type SignOffSmileyAndNote = BasicSignOffSmileyAndNote | DropdownDetailsSignOffSmileyAndNote;

export interface BasicSignOffConversation {
    notes: BasicSignOffSmileyAndNote[];
}

export interface DropdownDetailsSignOffConversation {
    notes: DropdownDetailsSignOffSmileyAndNote[];
}

export type SignOffConversation = BasicSignOffConversation | DropdownDetailsSignOffConversation;

interface OpinionBaseField {
    ref: string;
    pageRef: string[];
    pageRefVerified: boolean;
    refOpen: boolean;
    label: string;
    description?: string;
}

export interface OpinionDropdownField extends OpinionBaseField {
    type: OpinionInstanceFieldType.DROPDOWN;
    value: string[] | null;
    isMulti: boolean;
    dropdownList: string;
}

export interface OpinionEntityField extends OpinionBaseField {
    type: OpinionInstanceFieldType.ENTITY;
    value: string[] | null;
}

export interface OpinionDropdownDetailsField extends OpinionBaseField {
    type: OpinionInstanceFieldType.DROPDOWN_DETAILS;
    dropdownValue: string[] | null;
    wysiwygValue: RawDraftContentState | null;
    dropdownList: string;
}

export interface OpinionTextField extends OpinionBaseField {
    type: OpinionInstanceFieldType.TEXT | OpinionInstanceFieldType.LONGTEXT;
    value: string | undefined;
}

export interface OpinionWYSIWYGField extends OpinionBaseField {
    type: OpinionInstanceFieldType.WYSIWYG;
    value: RawDraftContentState | null;
}

export interface OpinionDateField extends OpinionBaseField {
    type: OpinionInstanceFieldType.DATE;
    value: Date | null;
}

export interface OpinionToggleField extends OpinionBaseField {
    type: OpinionInstanceFieldType.TOGGLE;
    value: boolean;
}

export interface OpinionMultiToggleField extends OpinionBaseField {
    type: OpinionInstanceFieldType.MULTI_TOGGLE;
    value: boolean[];
    includedSubCounterpartyTypeIds?: number[];
}

export type OpinionInstanceField = OpinionTextField | OpinionToggleField | OpinionMultiToggleField | OpinionDateField | OpinionDropdownField | OpinionDropdownDetailsField | OpinionWYSIWYGField | OpinionEntityField;

export interface General {
    provider: OpinionTextField;
    reviewer: OpinionTextField;
    dateOfReview: OpinionDateField;
}

export interface Assumptions {
    governingLaw: OpinionDropdownField;
    governingLawIfOther: OpinionTextField;
    bothPartiesSingleBranch: OpinionDropdownDetailsField;
    multiBranchStatus: OpinionDropdownDetailsField;
}

export interface EarlyTerminationAndCloseOutNetting {
    optionalEarlyTermination: OpinionDropdownDetailsField;
    closeOutNetting: OpinionDropdownDetailsField;
    closeOutNettingNotCovered: OpinionDropdownDetailsField;
    aetEnforceable: OpinionDropdownDetailsField;
    aetRequired: OpinionDropdownDetailsField;
    aetAdvisable: OpinionDropdownDetailsField;
    bridgesOne: OpinionDropdownDetailsField;
    bridgesTwo: OpinionDropdownDetailsField;
    closeOutProtocol: OpinionDropdownDetailsField;
    sectionTwoAThree: OpinionDropdownDetailsField;
    paymentNettingTwoC: OpinionDropdownDetailsField;
    nonInsolvency: OpinionDropdownDetailsField;
    insolvency: OpinionDropdownDetailsField;
    closeOutLocalCurrency: OpinionDropdownDetailsField;
}

export interface NettingLegalIssues {
    nettingLegislation: OpinionDropdownDetailsField;
    governingLawRecognised: OpinionDropdownDetailsField;
    judgmentOfCourtsEnforced: OpinionDropdownDetailsField;
    arbitralAwardEnforceable: OpinionDropdownDetailsField;
    onShoreInsolvency: OpinionDropdownDetailsField;
    offShoreInsolvency: OpinionDropdownDetailsField;
    absenceOfInsolvency: OpinionDropdownDetailsField;
    capacityCounterpartyIssues: OpinionDropdownDetailsField;
    enforceabilityTransactionIssues: OpinionDropdownDetailsField;
}

export interface NettingSFT {
    sftScheduleProvisions: OpinionDropdownDetailsField;
    accelerationNonDefault: OpinionDropdownDetailsField;
    automaticAcceleration: OpinionDropdownDetailsField;
    bankruptcy: OpinionDropdownDetailsField;
}

export interface SecurityInterestDocumentation {
    governingLawRecognised: OpinionDropdownDetailsField;
    jurisdictionOfCourtsRecognised: OpinionDropdownDetailsField;
    validityRecognised: OpinionDropdownDetailsField;
    validityBodyOfLaw: OpinionDropdownDetailsField;
    perfectionGoverningLaw: OpinionDropdownDetailsField;
    collateralTakerPerfected: OpinionDropdownDetailsField;
    actionsToPerfect: OpinionDropdownDetailsField;
    eligibleCollateralCreated: OpinionDropdownDetailsField;
    affectedByFluctuation: OpinionDropdownDetailsField;
    futureObligations: OpinionDropdownDetailsField;
    futureCollateral: OpinionDropdownDetailsField;
    fluctuatingAssets: OpinionDropdownDetailsField;
    fixedAmount: OpinionDropdownDetailsField;
    holdExcessCollateral: OpinionDropdownDetailsField;
    careOfCollateral: OpinionDropdownDetailsField;
    useRehypothecatePostedCollateral: OpinionDropdownDetailsField;
    consentToSubstitution: OpinionDropdownDetailsField;
    substituteAdverseImpact: OpinionDropdownDetailsField;
    enforceRightsFormalities: OpinionDropdownDetailsField;
    enforceLimitations: OpinionDropdownDetailsField;
    enforceInDefault: OpinionDropdownDetailsField;
    insolvencyCompetingPriorities: OpinionDropdownDetailsField;
    rightsAffectedByCommencementOfInsolvency: OpinionDropdownDetailsField;
    clawbackDuringSuspectPeriod: OpinionDropdownDetailsField;
    imJapaneseAmendmentsAffectConclusion: OpinionDropdownDetailsField;
    euroclearPledgeeAffectConclusion: OpinionDropdownDetailsField;
    euroclearProprietaryRights: OpinionDropdownDetailsField;
    cashLeakageConclusion: OpinionDropdownDetailsField;
}

export interface ProviderSFTDocuments {
    loanTransactions: OpinionDropdownDetailsField;
    koreanPledge: OpinionDropdownDetailsField;
    transferOfTransferredSecurities: OpinionDropdownDetailsField;
    titleTransferAffected: OpinionDropdownDetailsField;
}

export interface TakerSFTDocuments {
    loanTransactions: OpinionDropdownDetailsField;
    koreanPledge: OpinionDropdownDetailsField;
}

export interface TitleTransferDocumentation {
    governingLawRecognised: OpinionDropdownDetailsField;
    unconditionalTransferOfOwnership: OpinionDropdownDetailsField;
    recharacterisedSecurityInterest: OpinionDropdownDetailsField;
    actionsContinuedTitle: OpinionDropdownDetailsField;
    otherToEnsureValidity: OpinionDropdownDetailsField;
    transferorRightToExchange: OpinionDropdownDetailsField;
    transfereeConsent: OpinionDropdownDetailsField;
    paragraphSix: OpinionDropdownDetailsField;
    transfereeRightsInsolvency: OpinionDropdownDetailsField;
    clawbackDuringSuspectPeriod: OpinionDropdownDetailsField;
}

export interface Protocols {
    closeOutAmountProtocol: OpinionDropdownDetailsField;
    negativeInterestProtocol: OpinionDropdownDetailsField;
}

export interface IndependentAmountProvisions {
    independentAmountProvisions: OpinionDropdownDetailsField;
}

export interface SECIMSupplement {
    secIMChanges: OpinionDropdownDetailsField;
}

export interface RepoEarlyCloseOut {
    aetEnforceable: OpinionDropdownDetailsField;
    aetRequired: OpinionDropdownDetailsField;
    aetAdvisable: OpinionDropdownDetailsField;
    optionalAetEnforceable: OpinionDropdownDetailsField;
    closeOutNettingEnforceable: OpinionDropdownDetailsField;
    baseCurrencyCashPayment: OpinionDropdownDetailsField;
    securitiesHeldOutsideJurisdiction: OpinionDropdownDetailsField;
    crossProductCrossAgreementImpact: OpinionDropdownDetailsField;
}

export interface RepoLegalIssues {
    governingLawRecognised: OpinionDropdownDetailsField;
    judgmentOfCourtsEnforced: OpinionDropdownDetailsField;
    masterAgreementEnforceableObligations: OpinionDropdownDetailsField;
    onShoreInsolvency: OpinionDropdownDetailsField;
    offShoreInsolvency: OpinionDropdownDetailsField;
}

export interface MarginArrangements {
    recharacterisedSecurityInterest: OpinionDropdownDetailsField;
    actionsContinuedTitle: OpinionDropdownDetailsField;
    otherToEnsureValidity: OpinionDropdownDetailsField;
    transferorRightToExchange: OpinionDropdownDetailsField;
    alternativeMarginMethods: OpinionDropdownDetailsField;
    transfereeConsent: OpinionDropdownDetailsField;
    transfereeRightsInsolvency: OpinionDropdownDetailsField;
    clawbackDuringSuspectPeriod: OpinionDropdownDetailsField;
}

export interface CollateralTakerInsolvency {
    providerRightsEnforceable: OpinionDropdownDetailsField;
    providerRequirements: OpinionDropdownDetailsField;
    allOutstandingSatisfied: OpinionDropdownDetailsField;
    subjectToStayOrFreeze: OpinionDropdownDetailsField;
    cashLeakageConclusion: OpinionDropdownDetailsField;
}

export interface OtherIssues {
    otherKeyIssues: OpinionWYSIWYGField;
}

export interface NettingOtherIssues extends OtherIssues {
    internationalOther: OpinionDropdownDetailsField;
}

export interface AgreementCoverage {
    standardOptions: OpinionToggleField[];
}

export interface TransactionCoverage {
    standardOptions: OpinionToggleField[];
    customOptions: OpinionToggleField[];
    other: OpinionWYSIWYGField;
}

export interface CounterpartyCoverage {
    standardOptions: OpinionMultiToggleField[];
    customOptions: OpinionMultiToggleField[];
    other: OpinionWYSIWYGField;
}

export interface AnnexCoverage {
    standardOptions: OpinionToggleField[];
    customOptions: OpinionToggleField[];
    other: OpinionWYSIWYGField;
}

export interface EligibleCollateral {
    onShoreIncluded: OpinionDropdownField;
    offShoreIncluded: OpinionDropdownField;
    cash: OpinionDropdownField[];
    debtSecuritiesInJurisdiction: OpinionDropdownField[];
    debtSecuritiesOutsideJurisdiction: OpinionDropdownField[];
    governmentDebtSecuritiesInJurisdiction: OpinionDropdownField[];
    governmentDebtSecuritiesOutsideJurisdiction: OpinionDropdownField[];
    debtSecuritiesMultilateral: OpinionDropdownField[];
    equitySecuritiesInJurisdiction: OpinionDropdownField[];
    equitySecuritiesOutsideJurisdiction: OpinionDropdownField[];
}

export interface AdditionalProvisions {
    noneRequired: OpinionToggleField;
}

export interface NettingInstance {
    general: General;
    assumptions: Assumptions;
    earlyTermination: EarlyTerminationAndCloseOutNetting;
    legalIssues: NettingLegalIssues;
    sftTransactions: NettingSFT;
    otherIssues: NettingOtherIssues;
    agreementCoverage: AgreementCoverage;
    transactionCoverage: TransactionCoverage;
    counterpartyCoverage: CounterpartyCoverage;
    additionalProvisions: AdditionalProvisions;
}

export interface CollateralTakerInstance {
    general: General;
    collateralTakerInsolvency: CollateralTakerInsolvency;
    sftDocuments: TakerSFTDocuments;
    otherIssues: OtherIssues;
    agreementCoverage: AgreementCoverage;
    transactionCoverage: TransactionCoverage;
    counterpartyCoverage: CounterpartyCoverage;
    eligibleCollateral: EligibleCollateral;
    additionalProvisions: AdditionalProvisions;
}

export interface CollateralProviderInstance {
    general: General;
    securityInterest: SecurityInterestDocumentation;
    sftDocuments: ProviderSFTDocuments;
    titleTransfer: TitleTransferDocumentation;
    protocols: Protocols;
    independentAmount: IndependentAmountProvisions;
    secIMSupplement: SECIMSupplement;
    otherIssues: OtherIssues;
    agreementCoverage: AgreementCoverage;
    transactionCoverage: TransactionCoverage;
    counterpartyCoverage: CounterpartyCoverage;
    eligibleCollateral: EligibleCollateral;
    additionalProvisions: AdditionalProvisions;
}

export interface RepoInstance {
    general: General;
    assumptions: Assumptions;
    earlyCloseOut: RepoEarlyCloseOut;
    legalIssues: RepoLegalIssues;
    marginArrangements: MarginArrangements;
    otherIssues: OtherIssues;
    agreementCoverage: AgreementCoverage;
    transactionCoverage: TransactionCoverage;
    counterpartyCoverage: CounterpartyCoverage;
    annexCoverage: AnnexCoverage;
    additionalProvisions: AdditionalProvisions;
}

export type StockLendingInstance = RepoInstance;
export type RepoStockLendingInstance = RepoInstance;

export type OpinionInstanceContent = NettingInstance | CollateralProviderInstance | CollateralTakerInstance | RepoInstance | StockLendingInstance | RepoStockLendingInstance;

export interface UpdatedOpinionField {
    sectionId: string;
    fieldId: string;
    value: OpinionFieldValue;
    ref: string;
    pageRef: string[];
    wysiwygValue?: RawDraftContentState | null;
    index?: number;
    columnIndex?: number;
    includedSubCounterpartyTypeIds?: number[];
}

export interface UpdatedSignOffNotesField {
    sectionId: string;
    fieldId: string;
    value: SignOffConversation;
}

export interface BaseOpinionInstance {
    opinionInstanceId: number;
    fieldsUpdated: UpdatedOpinionField[];
    isDraft: number;
    isCurrent: number;
    type: OpinionInstanceType;
    content: OpinionInstanceContent;
    opinionId: number;
    additionalOpinionIds: number[];
    dateOfOpinion: string;
    createdBy?: number;
    createdByName?: string;
    createdDate?: string;
    effectiveTo?: string | null;
    clientId?: number;
}

export interface NettingOpinionInstance extends BaseOpinionInstance {
    type: OpinionInstanceType.NETTING;
    content: NettingInstance;
}

export interface CollateralProviderOpinionInstance extends BaseOpinionInstance {
    type: OpinionInstanceType.COLLATERAL_PROVIDER;
    content: CollateralProviderInstance;
}

export interface CollateralTakerOpinionInstance extends BaseOpinionInstance {
    type: OpinionInstanceType.COLLATERAL_TAKER;
    content: CollateralTakerInstance;
}

export interface RepoOpinionInstance extends BaseOpinionInstance {
    type: OpinionInstanceType.REPO;
    content: RepoInstance;
}

export interface StockLendingOpinionInstance extends BaseOpinionInstance {
    type: OpinionInstanceType.STOCK_LENDING;
    content: StockLendingInstance;
}
export interface RepoStockLendingOpinionInstance extends BaseOpinionInstance {
    type: OpinionInstanceType.REPO_STOCK_LENDING;
    content: RepoStockLendingInstance;
}

export type OpinionInstance = NettingOpinionInstance | CollateralProviderOpinionInstance | CollateralTakerOpinionInstance | RepoOpinionInstance | StockLendingOpinionInstance | RepoStockLendingOpinionInstance;

export interface TimelineOpinionInstance {
    opinionInstanceId: number;
    username: string;
    opinionId: number;
    description: string;
    createdDate: string;
    dateOfOpinion: string;
    isDraft: number;
}

export interface SignOffGeneral {
    provider: SignOffConversation;
    reviewer: SignOffConversation;
    dateOfReview: SignOffConversation;
}

export interface SignOffAssumptions {
    governingLaw: SignOffConversation;
    governingLawIfOther: SignOffConversation;
    bothPartiesSingleBranch: SignOffConversation;
    multiBranchStatus: SignOffConversation;
}

export interface SignOffEarlyTerminationAndCloseOutNetting {
    optionalEarlyTermination: SignOffConversation;
    closeOutNetting: SignOffConversation;
    closeOutNettingNotCovered: SignOffConversation;
    aetEnforceable: SignOffConversation;
    aetRequired: SignOffConversation;
    aetAdvisable: SignOffConversation;
    bridgesOne: SignOffConversation;
    bridgesTwo: SignOffConversation;
    closeOutProtocol: SignOffConversation;
    sectionTwoAThree: SignOffConversation;
    paymentNettingTwoC: SignOffConversation;
    nonInsolvency: SignOffConversation;
    insolvency: SignOffConversation;
    closeOutLocalCurrency: SignOffConversation;
}

export interface SignOffNettingLegalIssues {
    nettingLegislation: SignOffConversation;
    governingLawRecognised: SignOffConversation;
    judgmentOfCourtsEnforced: SignOffConversation;
    arbitralAwardEnforceable: SignOffConversation;
    onShoreInsolvency: SignOffConversation;
    offShoreInsolvency: SignOffConversation;
    absenceOfInsolvency: SignOffConversation;
    capacityCounterpartyIssues: SignOffConversation;
    enforceabilityTransactionIssues: SignOffConversation;
}

export interface SignOffNettingSFT {
    sftScheduleProvisions: SignOffConversation;
    accelerationNonDefault: SignOffConversation;
    automaticAcceleration: SignOffConversation;
    bankruptcy: SignOffConversation;
}

export interface SignOffSecurityInterestDocumentation {
    governingLawRecognised: SignOffConversation;
    jurisdictionOfCourtsRecognised: SignOffConversation;
    validityRecognised: SignOffConversation;
    validityBodyOfLaw: SignOffConversation;
    perfectionGoverningLaw: SignOffConversation;
    collateralTakerPerfected: SignOffConversation;
    actionsToPerfect: SignOffConversation;
    eligibleCollateralCreated: SignOffConversation;
    affectedByFluctuation: SignOffConversation;
    futureObligations: SignOffConversation;
    futureCollateral: SignOffConversation;
    fluctuatingAssets: SignOffConversation;
    fixedAmount: SignOffConversation;
    holdExcessCollateral: SignOffConversation;
    careOfCollateral: SignOffConversation;
    useRehypothecatePostedCollateral: SignOffConversation;
    consentToSubstitution: SignOffConversation;
    substituteAdverseImpact: SignOffConversation;
    enforceRightsFormalities: SignOffConversation;
    enforceLimitations: SignOffConversation;
    enforceInDefault: SignOffConversation;
    insolvencyCompetingPriorities: SignOffConversation;
    rightsAffectedByCommencementOfInsolvency: SignOffConversation;
    clawbackDuringSuspectPeriod: SignOffConversation;
    imJapaneseAmendmentsAffectConclusion: SignOffConversation;
    euroclearPledgeeAffectConclusion: SignOffConversation;
    euroclearProprietaryRights: SignOffConversation;
    cashLeakageConclusion: SignOffConversation;
}

export interface SignOffProviderSFTDocuments {
    loanTransactions: SignOffConversation;
    transferOfTransferredSecurities: SignOffConversation;
    titleTransferAffected: SignOffConversation;
}

export interface SignOffTakerSFTDocuments {
    loanTransactions: SignOffConversation;
}

export interface SignOffTitleTransferDocumentation {
    governingLawRecognised: SignOffConversation;
    unconditionalTransferOfOwnership: SignOffConversation;
    recharacterisedSecurityInterest: SignOffConversation;
    actionsContinuedTitle: SignOffConversation;
    otherToEnsureValidity: SignOffConversation;
    transferorRightToExchange: SignOffConversation;
    transfereeConsent: SignOffConversation;
    paragraphSix: SignOffConversation;
    transfereeRightsInsolvency: SignOffConversation;
    clawbackDuringSuspectPeriod: SignOffConversation;
}

export interface SignOffProtocols {
    closeOutAmountProtocol: SignOffConversation;
    negativeInterestProtocol: SignOffConversation;
}

export interface SignOffIndependentAmountProvisions {
    independentAmountProvisions: SignOffConversation;
}

export interface SignOffSECIMSupplement {
    secIMChanges: SignOffConversation;
}

export interface SignOffRepoEarlyCloseOut {
    aetEnforceable: SignOffConversation;
    aetRequired: SignOffConversation;
    aetAdvisable: SignOffConversation;
    optionalAetEnforceable: SignOffConversation;
    closeOutNettingEnforceable: SignOffConversation;
    baseCurrencyCashPayment: SignOffConversation;
    securitiesHeldOutsideJurisdiction: SignOffConversation;
    crossProductCrossAgreementImpact: SignOffConversation;
}

export interface SignOffRepoLegalIssues {
    governingLawRecognised: SignOffConversation;
    judgmentOfCourtsEnforced: SignOffConversation;
    masterAgreementEnforceableObligations: SignOffConversation;
    onShoreInsolvency: SignOffConversation;
    offShoreInsolvency: SignOffConversation;
}

export interface SignOffMarginArrangements {
    recharacterisedSecurityInterest: SignOffConversation;
    actionsContinuedTitle: SignOffConversation;
    otherToEnsureValidity: SignOffConversation;
    transferorRightToExchange: SignOffConversation;
    alternativeMarginMethods: SignOffConversation;
    transfereeConsent: SignOffConversation;
    transfereeRightsInsolvency: SignOffConversation;
    clawbackDuringSuspectPeriod: SignOffConversation;
}

export interface SignOffCollateralTakerInsolvency {
    providerRightsEnforceable: SignOffConversation;
    providerRequirements: SignOffConversation;
    allOutstandingSatisfied: SignOffConversation;
    subjectToStayOrFreeze: SignOffConversation;
    cashLeakageConclusion: SignOffConversation;
}

export interface SignOffOtherIssues {
    otherKeyIssues: SignOffConversation;
}

export interface SignOffNettingOtherIssues {
    internationalOther: SignOffConversation;
}

export interface SignOffEligibleCollateral {
    onShoreIncluded: SignOffConversation;
    offShoreIncluded: SignOffConversation;
    eligibleCollateralTable: SignOffConversation;
}

export interface SignOffNettingInstance {
    general: SignOffGeneral;
    assumptions: SignOffAssumptions;
    earlyTermination: SignOffEarlyTerminationAndCloseOutNetting;
    legalIssues: SignOffNettingLegalIssues;
    sftTransactions: SignOffNettingSFT;
    otherIssues: SignOffNettingOtherIssues;
    agreementCoverage: SignOffConversation;
    transactionCoverage: SignOffConversation;
    counterpartyCoverage: SignOffConversation;
    additionalProvisions: SignOffConversation;
}

export interface SignOffCollateralTakerInstance {
    general: SignOffGeneral;
    collateralTakerInsolvency: SignOffCollateralTakerInsolvency;
    sftDocuments: SignOffTakerSFTDocuments;
    otherIssues: SignOffOtherIssues;
    agreementCoverage: SignOffConversation;
    transactionCoverage: SignOffConversation;
    counterpartyCoverage: SignOffConversation;
    eligibleCollateral: SignOffEligibleCollateral;
    additionalProvisions: SignOffConversation;
}

export interface SignOffCollateralProviderInstance {
    general: SignOffGeneral;
    securityInterest: SignOffSecurityInterestDocumentation;
    sftDocuments: SignOffProviderSFTDocuments;
    titleTransfer: SignOffTitleTransferDocumentation;
    protocols: SignOffProtocols;
    independentAmount: SignOffIndependentAmountProvisions;
    secIMSupplement: SignOffSECIMSupplement;
    otherIssues: SignOffOtherIssues;
    agreementCoverage: SignOffConversation;
    transactionCoverage: SignOffConversation;
    counterpartyCoverage: SignOffConversation;
    eligibleCollateral: SignOffEligibleCollateral;
    additionalProvisions: SignOffConversation;
}

export interface SignOffRepoInstance {
    general: SignOffGeneral;
    assumptions: SignOffAssumptions;
    earlyCloseOut: SignOffRepoEarlyCloseOut;
    legalIssues: SignOffRepoLegalIssues;
    marginArrangements: SignOffMarginArrangements;
    otherIssues: SignOffOtherIssues;
    agreementCoverage: SignOffConversation;
    transactionCoverage: SignOffConversation;
    counterpartyCoverage: SignOffConversation;
    annexCoverage: SignOffConversation;
    additionalProvisions: SignOffConversation;
}

export type SignOffStockLendingInstance = SignOffRepoInstance;

export type SignOffOpinionInstanceContent = SignOffNettingInstance | SignOffCollateralProviderInstance | SignOffCollateralTakerInstance | SignOffRepoInstance | SignOffStockLendingInstance;

export interface SignOffOpinionInstance {
    clientOpinionInstanceId: number | null;
    opinionInstanceId: number;
    content: SignOffOpinionInstanceContent;
    approvalDate: string | null;
    approvedBy: number | null;
    finalApprovalNotes: RawDraftContentState | null;
    clientId: number;
}

export interface OpinionInstanceState {
    currentInstance: OpinionInstance | null;
    savedInstance: OpinionInstance | null;
    currentSignOff: SignOffOpinionInstance | null;
    savedSignOff: SignOffOpinionInstance | null;
    openFieldsAndSections: string[];
    timeline: TimelineOpinionInstance[];
    isEditing: boolean;
    isUpdating: boolean;
    isSaving: boolean;
    error: string | null;
    isLoading: boolean;
    updatedInstanceModalOpen: boolean;
    confirmSaveModalOpen: boolean;
    instanceUpdated: boolean;
    linkedOpinionModalOpen: boolean;
    clauses: Clause[];
    opinionClauses: OpinionClause[];
    allOpinionClauseIds: number[];
    additionalProvisionsUpdated: boolean;
    clauseTableLoading: boolean;
    totalOpinionClauses: number;
    opinionClausePage: number;
    opinionClauseFilters: TableFilters;
    opinionClauseSort?: ColumnSort;
    clauseModalOpen: boolean;
    clause: Clause;
    currentClause: Clause | null;
    tagTerm: string;
    fuzzyMatchResults: FuzzyMatchTags[];
    fuzzyMatchModalOpen: boolean;
    clauseLibraryDropdownOptions: ClauseTagDropdownOptions;
    clauseCanSave: boolean;
    clauseDescriptionOpen: boolean;
    allClientTags: ClientTag[];
    linkOpinionClauseModalOpen: boolean;
    scrollableWrapper: ScrollableElement | null;
    agreementCoverageSearchTerm: string;
    signOffModalOpen: boolean;
    signOffNotesModalOpen: boolean;
    sectionOrFieldId: string;
    signOffNotesContent: SignOffSmileyAndNote;
    signOffInstanceUpdated: boolean;
    isEditingSignOff: boolean;
    supportOpinionUploadModalOpen: boolean;
    indexOfUpdatedNote: number | null;
    subCounterpartyTypeModalOpen: boolean;
    parentCounterparty: string;
}
