import { toast } from 'react-toastify';
import { put, all, fork, call, takeLeading, takeEvery, select } from 'redux-saga/effects';

import { submitRegistrationRequest } from '../../../../services/auth';
import { emailValidator } from '../../../../utils/regex-utils';
import { setRegistrationEmailValid, submitRegistrationDetailsFailed, submitRegistrationDetailsSuccessful, validateRegistrationEmail } from './actions';
import { getRegistrationDetails } from './selectors';
import { RegistrationActionTypes, RegistrationDetails } from './types';

export function* attemptSubmitDetails() {
    try {
        const registrationDetails: RegistrationDetails = yield select(getRegistrationDetails);
        yield call(submitRegistrationRequest, registrationDetails);
        yield put(submitRegistrationDetailsSuccessful());
        toast('Thanks, we will be in touch soon');
    } catch (e) {
        yield put(submitRegistrationDetailsFailed((e as Error).message));
        toast.error('Unable to process registration, please try again');
    }
}

export function* checkEmailValid({ payload }: ReturnType<typeof validateRegistrationEmail>) {
    try {
        const emailValid = emailValidator(payload);
        yield put(setRegistrationEmailValid(emailValid));
    } catch (e) {
        toast.error('Unable to validate email, please try again');
    }
}

function* submitDetailsWatcher() {
    yield takeLeading(RegistrationActionTypes.SUBMIT_REGISTRATION_DETAILS_STARTED, attemptSubmitDetails);
}

function* checkEmailValidWatcher() {
    yield takeEvery(RegistrationActionTypes.VALIDATE_REGISTRATION_EMAIL, checkEmailValid);
}

export function* registrationSaga() {
    yield all([
        fork(submitDetailsWatcher),
        fork(checkEmailValidWatcher)
    ]);
}
