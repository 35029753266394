import { ApplicationState } from '../../../../store/rootReducer';
import { Client, ClientFeaturePermissionDB, ClientSection, ClientState } from './types';

const getRoot = (state: ApplicationState): ClientState => state.admin.clients;

export const getModalOpen = (state: ApplicationState): boolean => getRoot(state).modalOpen;

export const getAllClients = (state: ApplicationState): Client[] => getRoot(state).clients;

export const getAllClientIds = (state: ApplicationState): number[] => getRoot(state).clients.map(({ clientId }) => clientId!);

export const getSelectedClient = (state: ApplicationState): Client | null => getRoot(state).selectedClient;

export const getConfirmDeleteClient = (state: ApplicationState): Client | null => getRoot(state).confirmDeleteClient;

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).deletingClient;

export const getAvailableClientFeaturePermissions = (state: ApplicationState): ClientFeaturePermissionDB[] => getRoot(state).availableClientFeaturePermissions;

export const getOpenClientSections = (state: ApplicationState): ClientSection[] => getRoot(state).openClientSections;
