import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { ClientFeaturePermission } from '../../admin/users/store';
import { getClientHasFeaturePermission } from '../../auth/login/store';
import { Analytics, Delete, Document } from '../../shared/icons';
import { Action } from '../../shared/modal/ActionModal';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { dateFormatter, entityFormatter, stringFormatter } from '../../shared/table/arkTableFormatters';
import {
    ArkDocument,
    clearIncompleteDocumentTableFilters,
    deleteDocumentStarted,
    fetchIncompleteDocumentsStarted,
    getAllIncompleteDocuments,
    getIncompleteDocumentColumnSort,
    getIncompleteDocumentFilters,
    getIncompleteDocumentPageSize,
    getIncompleteDocumentsPageNumber,
    getIsLoading,
    getSelectedIncompleteDocuments,
    getTotalIncompleteDocuments,
    incompleteDocumentsPaginationNext,
    incompleteDocumentsPaginationPrevious,
    initialAIStarted,
    openIncompleteDocumentStarted,
    reanalyzeDocumentStarted,
    setIncompleteDocumentTableColumnSort,
    setIncompleteDocumentTableFilters,
    setIncompleteDocumentsPageSize,
    setSelectedIncompleteDocuments,
    toggleDocumentDetailsModal
} from './store';

export const IncompleteDocumentsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchIncompleteDocumentsStarted()]);
    const incompleteDocuments = useAppSelector(getAllIncompleteDocuments);
    const selectedIncompleteDocuments = useAppSelector(getSelectedIncompleteDocuments);
    const isLoading = useAppSelector(getIsLoading);

    const selectedDocumentIds = useMemo(() => selectedIncompleteDocuments.map(({ documentId }) => documentId), [selectedIncompleteDocuments]);

    const selectDocument = useCallback((documentId: number | string) => {
        const id = typeof documentId === 'string' ? parseInt(documentId) : documentId;
        const deselect = selectedDocumentIds.includes(id);
        if (deselect) {
            const updatedList = selectedIncompleteDocuments.filter(({ documentId }) => documentId !== id);
            dispatch(setSelectedIncompleteDocuments(updatedList));
        } else {
            const documentSelected = incompleteDocuments.find(({ documentId }) => documentId === id)!;
            const updatedList = [...selectedIncompleteDocuments, documentSelected];
            dispatch(setSelectedIncompleteDocuments(updatedList));
        }
    }, [dispatch, incompleteDocuments, selectedDocumentIds, selectedIncompleteDocuments]);

    const pageNumber = useAppSelector(getIncompleteDocumentsPageNumber);
    const totalDocuments = useAppSelector(getTotalIncompleteDocuments);
    const documentFilters = useAppSelector(getIncompleteDocumentFilters);
    const columnSort = useAppSelector(getIncompleteDocumentColumnSort);
    const pageSize = useAppSelector(getIncompleteDocumentPageSize);
    const nextPage = useCallback(() => { dispatch(incompleteDocumentsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(incompleteDocumentsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setIncompleteDocumentTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearIncompleteDocumentTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setIncompleteDocumentsPageSize(pageSize)); }, [dispatch]);

    const hasDocumentExtractAIPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DOCUMENT_EXTRACT_WITH_AI]));

    const openDocument = useCallback((data: ArkDocument) => {
        dispatch(setSelectedIncompleteDocuments([data]));
        dispatch(openIncompleteDocumentStarted(data));
        dispatch(toggleDocumentDetailsModal(true));
    }, [dispatch]);

    const deleteDocument = useCallback(({ location }: ArkDocument) => { dispatch(deleteDocumentStarted(location)); }, [dispatch]);

    const reanalyzeDocument = useCallback((location: string) => dispatch(reanalyzeDocumentStarted(location)), [dispatch]);

    const runInitialAI = useCallback((documentId: number) => dispatch(initialAIStarted(documentId)), [dispatch]);

    const incompleteDocumentActions = useCallback((data: ArkDocument) => {
        const { analysisComplete, analysisStarted, location, mlDataVersion, documentId } = data;
        const incompleteAnalysis = !analysisComplete && analysisStarted === 0;
        const allowInitialAI = !mlDataVersion && analysisComplete && hasDocumentExtractAIPermission;
        let options: Action[] = [
            { label: 'Open', icon: Document, onClick: () => openDocument(data), withSeparator: !incompleteAnalysis && !allowInitialAI },
            { label: 'Delete', icon: Delete, onClick: () => deleteDocument(data), isDelete: true }
        ];
        if (incompleteAnalysis) {
            options.splice(1, 0, { label: 'Analyze', icon: Analytics, onClick: () => reanalyzeDocument(location), withSeparator: true });
        }
        if (allowInitialAI) {
            options.splice(1, 0, { label: 'Detect with AI', icon: Analytics, onClick: () => runInitialAI(documentId), withSeparator: true });
        }
        return options;
    }, [openDocument, deleteDocument, reanalyzeDocument, runInitialAI, hasDocumentExtractAIPermission]);

    const columnDefs: ArkTableColumn[] = [
        { id: 'checkbox', header: '', field: 'documentId', width: 0.04, component: 'checkbox', select: selectDocument, selected: selectedDocumentIds },
        { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', width: 0.27, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'documentType', header: 'Document Type', field: 'documentType', width: 0.15, component: 'tooltip', valueFormatter: stringFormatter, canFilter: true },
        { id: 'executedDate', header: 'Date of Execution', field: 'executedDate', width: 0.1, valueFormatter: dateFormatter, canSort: true },
        { id: 'entityAName', header: 'Party A', field: 'entityAName', width: 0.10, valueFormatter: entityFormatter, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'entityBName', header: 'Party B', field: 'entityBName', width: 0.10, valueFormatter: entityFormatter, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'modifiedDate', header: 'Last Modified', field: 'modifiedDate', width: 0.1, valueFormatter: dateFormatter },
        { id: 'analysis', header: 'Analyzed', field: '', width: 0.09, component: 'analysis' },
        { id: 'actions', header: '', field: '', width: 0.05, component: 'action', actions: incompleteDocumentActions }
    ];

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setIncompleteDocumentTableColumnSort(columnSort)); }, [dispatch]);

    return (
        <ArkTable
            testId='incomplete-documents'
            rowData={incompleteDocuments}
            colDefs={columnDefs}
            isLoading={isLoading}
            page={pageNumber}
            total={totalDocuments}
            next={nextPage}
            previous={previousPage}
            filters={documentFilters}
            updateFilter={updateFilter}
            clearAllFilters={clearAllFilters}
            onRowDoubleClicked={row => openDocument(row as ArkDocument)}
            columnSort={columnSort}
            toggleSort={toggleColumnSort}
            pageSize={pageSize}
            updatePageSize={updatePageSize}
        />
    );
};
