import { action } from 'typesafe-actions';

import { ArkModule, HomepageAnalyticsActionTypes, HomePageTile, HomeScreenConfig, MyAttestationAnalytics, MyProfileSection } from './types';
import { CustomFilters, DocumentCustomFilters, OpinionCustomFilters, User, UserDefaults } from '../../auth/login/store';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { OpinionsView } from '../../opinions/my-opinions/store';
import { PieChartAnalyticsData } from '../../shared/analytics/PieChart';

export const fetchHomepageDocumentSpreadStarted = () => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_STARTED, HomePageTile.DOCUMENT_SPREAD);
export const fetchHomepageDocumentSpreadSuccessful = (analytics: PieChartAnalyticsData[]) => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_SUCCESSFUL, { analytics, chart: HomePageTile.DOCUMENT_SPREAD, key: 'documentSpread' });
export const fetchHomepageDocumentSpreadFailed = (e: string) => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_DOCUMENT_SPREAD_ANALYTICS_FAILED, { error: e, chart: HomePageTile.DOCUMENT_SPREAD });

export const fetchHomepageEntitySpreadStarted = () => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_STARTED, HomePageTile.ENTITY_SPREAD);
export const fetchHomepageEntitySpreadSuccessful = (analytics: PieChartAnalyticsData[]) => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_SUCCESSFUL, { analytics, chart: HomePageTile.ENTITY_SPREAD, key: 'entitySpread' });
export const fetchHomepageEntitySpreadFailed = (e: string) => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ENTITY_SPREAD_ANALYTICS_FAILED, { error: e, chart: HomePageTile.ENTITY_SPREAD });

export const fetchHomepageAttestationProgressStarted = () => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_STARTED, HomePageTile.MY_ATTESTATIONS);
export const fetchHomepageAttestationProgressSuccessful = (analytics: MyAttestationAnalytics[]) => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_SUCCESSFUL, { analytics, chart: HomePageTile.MY_ATTESTATIONS, key: 'myAttestationProgress' });
export const fetchHomepageAttestationProgressFailed = (e: string) => action(HomepageAnalyticsActionTypes.FETCH_HOMEPAGE_ATTESTATION_PROGRESS_ANALYTICS_FAILED, { error: e, chart: HomePageTile.MY_ATTESTATIONS });

export const redirectMyAttestation = (attestationInstanceId: number) => action(HomepageAnalyticsActionTypes.REDIRECT_ANALYTICS_MY_ATTESTATION, attestationInstanceId);

export const setHomeScreenConfig = (config: HomeScreenConfig) => action(HomepageAnalyticsActionTypes.SET_HOME_SCREEN_CONFIG, config);
export const resetHomeScreenConfig = () => action(HomepageAnalyticsActionTypes.RESET_HOME_SCREEN_CONFIG);
export const updateHomeScreenConfig = (config: HomeScreenConfig) => action(HomepageAnalyticsActionTypes.UPDATE_HOME_SCREEN_CONFIG, config);
export const fetchRelevantHomeScreenAnalytics = (user?: User) => action(HomepageAnalyticsActionTypes.FETCH_RELEVANT_HOME_SCREEN_ANALYTICS, user);

export const toggleUnauthorisedModuleModalOpen = (isOpen: boolean) => action(HomepageAnalyticsActionTypes.TOGGLE_UNAUTHORISED_MODULE_MODAL_OPEN, isOpen);

export const toggleUserNotPermittedModalOpen = (isOpen: boolean) => action(HomepageAnalyticsActionTypes.TOGGLE_USER_NOT_PERMITTED_MODAL_OPEN, isOpen);

export const setUnauthorisedModule = (module: ArkModule) => action(HomepageAnalyticsActionTypes.SET_UNAUTHORISED_MODULE, module);

export const enquireModuleStarted = () => action(HomepageAnalyticsActionTypes.ENQUIRE_MODULE_STARTED);
export const enquireModuleSuccessful = () => action(HomepageAnalyticsActionTypes.ENQUIRE_MODULE_SUCCESSFUL);
export const enquireModuleFailed = (e: string) => action(HomepageAnalyticsActionTypes.ENQUIRE_MODULE_FAILED, e);

export const setMyProfileSection = (value: MyProfileSection) => action(HomepageAnalyticsActionTypes.SET_MY_PROFILE_SECTION, value);

// Custom Filters
export const toggleAddNewFilterModal = (isOpen: boolean) => action(HomepageAnalyticsActionTypes.TOGGLE_ADD_NEW_FILTER_MODAL, isOpen);

export const addNewCustomFilterValue = (key: keyof CustomFilters, value: string | string[] | null) => action(HomepageAnalyticsActionTypes.ADD_NEW_CUSTOM_FILTER, { key, value });

export const resetNewCustomFilterValue = () => action(HomepageAnalyticsActionTypes.RESET_ADD_NEW_FILTER_MODAL);

export const updateCustomFiltersStarted = () => action(HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTERS_STARTED);
export const updateCustomFiltersSuccessful = (value: CustomFilters[] | []) => action(HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTERS_SUCCESSFUL, value);
export const updateCustomFiltersFailed = (error: string) => action(HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTERS_FAILED, error);

export const setUserCustomFilters = (value: CustomFilters[] | null) => action(HomepageAnalyticsActionTypes.SET_USER_CUSTOM_FILTERS, value);

export const addOrDeleteUserCustomFilter = (value: CustomFilters[]) => action(HomepageAnalyticsActionTypes.ADD_OR_DELETE_USER_CUSTOM_FILTER, value);

export const toggleFilterTileModal = (id: string | null) => action(HomepageAnalyticsActionTypes.TOGGLE_FILTER_TILE_MODAL, id);

export const updateCustomFilterValue = (key: keyof DocumentCustomFilters | keyof OpinionCustomFilters, value: string | string[] | null, filterId: string, type?: keyof TableFilterType) => action(HomepageAnalyticsActionTypes.UPDATE_CUSTOM_FILTER_VALUES, { key, value, type, filterId });

export const setCustomFiltersUpdated = (hasUpdated: boolean) => action(HomepageAnalyticsActionTypes.CUSTOM_FILTERS_UPDATED, hasUpdated);

export const resetCustomFiltersSection = () => action(HomepageAnalyticsActionTypes.RESET_CUSTOM_FILTERS_SECTION);

export const setSelectedCustomFilter = (value: CustomFilters | null) => action(HomepageAnalyticsActionTypes.SET_SELECTED_CUSTOM_FILTER, value);

export const setUserDefaults = (value: UserDefaults | null) => action(HomepageAnalyticsActionTypes.SET_USER_DEFAULTS, value);

export const updateUserDefaults = (key: keyof UserDefaults, value: OpinionsView | boolean) => action(HomepageAnalyticsActionTypes.UPDATE_USER_DEFAULTS, { key, value });

export const resetUserDefaults = () => action(HomepageAnalyticsActionTypes.RESET_USER_DEFAULTS);

export const setUnsavedProfileChangedModalOpen = () => action(HomepageAnalyticsActionTypes.SET_UNSAVED_PROFILE_CHANGES_MODAL_OPEN);
