import React from 'react';

import { ConfirmationModal } from '../../../shared/modal/ConfirmationModal';
import { DatasetSection } from '../../../datasets/store';
import styles from './DatasetComponents.module.scss';

interface SectionModalProps {
    sectionModalOpen: boolean;
    closeModal: () => void;
    confirm: () => void;
    selectedSection: DatasetSection | null;
    updateLabel: (label: string) => void;
    updateDescription: (description: string) => void;
    isSingleSection: boolean;
    isEdit?: boolean;
}

export const SectionModal: React.FC<SectionModalProps> = ({ sectionModalOpen, closeModal, confirm, selectedSection, updateDescription, updateLabel, isSingleSection, isEdit = false }) => (
    <ConfirmationModal
        isOpen={sectionModalOpen}
        closeModal={closeModal}
        confirm={confirm}
        confirmDisabled={!selectedSection || selectedSection && !isSingleSection && !selectedSection.label}
        confirmLabel={isEdit ? 'Save' : 'Create'}
        testId='section-modal'
    >
        <div className={styles.sectionModalWrapper} data-testid='section-modal-wrapper'>
            <div className={styles.sectionModalTitle} data-testid='section-modal-header'>{isEdit ? 'Edit Section' : 'Create New Section'}</div>
            <input
                data-testid='section-modal-label'
                className={styles.sectionModalInput}
                placeholder='Label...'
                onChange={e => updateLabel(e.target.value)}
                value={selectedSection ? selectedSection.label : ''}
            />
            <input
                data-testid='section-modal-description'
                className={styles.sectionModalInput}
                placeholder='Description...'
                onChange={e => updateDescription(e.target.value)}
                value={selectedSection ? selectedSection.description : ''}
            />
        </div>
    </ConfirmationModal>
);
