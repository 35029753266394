import { RawDraftContentState } from 'draft-js';

import { EntityType } from '../../admin/entity/store';
import { ArkDocument } from '../../documents/my-documents/store';
import { CalculatorFieldType } from '../../shared/calculator/constants';

export enum DatasetType {
    FORM = 'Form',
    TABLE = 'Table'
}

export enum DatasetFieldType {
    SECTION = 'Section',
    TEXT = 'Text',
    NUMBER = 'Number',
    DATE = 'Date',
    CHECKBOX = 'Checkbox',
    DROPDOWN = 'Dropdown',
    LONGTEXT = 'Longtext',
    GROUP = 'Group',
    DATASET = 'Dataset',
    LINKFIELD = 'Linkfield',
    DOCUMENT = 'Document',
    TIME = 'Time',
    WIZARD = 'Wizard',
    WYSIWYG = 'WYSIWYG',
    LABEL = 'Label',
    CALCULATOR = 'Calculator',
    MULTI_TOGGLE = 'Multi-Toggle',
    PARTY = 'Party Select',
    CURRENCY_AMOUNT = 'Currency Amount'
}

export enum FieldHighlight {
    FRENCH = 'french',
    AMETHYST = 'amethyst',
    OCEAN = 'ocean',
    AQUA = 'aqua',
    RED = 'red',
    AMBER = 'amber',
    GOLD = 'gold',
    GREEN = 'green'
}

export type SingleDatasetFieldType = Exclude<DatasetFieldType, DatasetFieldType.GROUP | DatasetFieldType.MULTI_TOGGLE | DatasetFieldType.WIZARD>;
export type TableDatasetFieldType = Exclude<SingleDatasetFieldType, DatasetFieldType.SECTION | DatasetFieldType.LABEL>;
export type AgencyDatasetFieldType = DatasetFieldType.TEXT | DatasetFieldType.NUMBER | DatasetFieldType.DATE | DatasetFieldType.DROPDOWN | DatasetFieldType.WIZARD | DatasetFieldType.CHECKBOX | DatasetFieldType.LINKFIELD | DatasetFieldType.CURRENCY_AMOUNT;
export type RiskDatasetFieldType = DatasetFieldType.NUMBER | DatasetFieldType.DATE | DatasetFieldType.DROPDOWN | DatasetFieldType.TIME | DatasetFieldType.CHECKBOX | DatasetFieldType.CURRENCY_AMOUNT;

export interface DatasetFieldSettings {
    isOpen: boolean;
    refOpen?: boolean;
    clauseOpen?: boolean;
    primaryKey?: string;
    mandatory: boolean;
    isMultiSelect?: boolean;
    datasetLinked?: null | string;
    dropdownLinked?: null | string;
    showRef?: boolean;
    entityType?: EntityType;
    datasetUpdated?: boolean;
    documentProperty?: keyof ArkDocument | null;
    showClause?: boolean;
    showInView?: boolean;
    agencyField?: boolean;
    isCollapsible?: boolean;
    labelContent?: null | RawDraftContentState;
    systemIdOpen?: boolean;
    calculatorFieldType?: null | CalculatorFieldType;
    showOutputField?: boolean;
    showGracePeriod?: boolean;
    includeFreeTextOption?: boolean;
    linkedEntities?: string[] | null;
    centreField?: boolean;
    riskField?: boolean;
    aiModified?: boolean;
    aiModifiedUserCorrected?: boolean;
    highlight?: FieldHighlight | null;
}

export interface DatasetSection {
    id: string;
    label: string;
    description?: string;
}

export interface GroupDatasetField {
    id?: string;
    type: DatasetFieldType.GROUP | DatasetFieldType.MULTI_TOGGLE;
    children: SingleDatasetField[];
    label?: string;
}

export interface SingleDatasetField {
    id?: string;
    label: string;
    description: string;
    type: SingleDatasetFieldType;
    settings: DatasetFieldSettings;
    refLabel: string;
    clauseLabel?: string;
    systemId: string;
}

export interface AgencyLinkedSingleField extends SingleDatasetField {
    datasetId: string;
    datasetTitle: string;
}

export interface AgencyDatasetField {
    id?: string;
    label: string;
    description: string;
    type: AgencyDatasetFieldType;
    refLabel: string;
    settings: DatasetFieldSettings;
    linkedFields: AgencyLinkedSingleField[];
    systemId: string;
}

export type DatasetField = SingleDatasetField | GroupDatasetField;

export interface FormDatasetFields {
    [sectionId: string]: DatasetField[];
}

export interface DatasetDefinitionSettings {
    primaryKey?: string;
    isAgency?: boolean;
}

interface BaseDefinition {
    datasetDefinitionId?: number;
    datasetId?: number;
    datasetTitle: string;
    publishReason: string;
    settings: DatasetDefinitionSettings;
    isParent?: boolean;
}

export interface FormDatasetDefinition extends BaseDefinition {
    datasetType: DatasetType.FORM;
    datasetFields: FormDatasetFields;
    datasetSections: DatasetSection[];
}

export interface TableDatasetDefinition extends BaseDefinition {
    datasetType: DatasetType.TABLE;
    datasetFields: SingleDatasetField[];
    datasetSections?: null;
}

export type DatasetDefinition = FormDatasetDefinition | TableDatasetDefinition;

interface BaseDefinitionDB {
    datasetDefinitionId: number;
    datasetId: number;
    datasetDefinitionVersion: number;
    isCurrent: number;
    datasetTitle: string;
    settings: DatasetDefinitionSettings;
    publishReason: string;
    createdBy: number;
    createdDate: string;
    effectiveTo: string | null;
    clientId: number;
    isParent?: boolean;
}

export interface TableDatasetDefinitionDB extends BaseDefinitionDB {
    datasetType: DatasetType.TABLE;
    datasetFields: SingleDatasetField[];
    datasetSections: null;
}

export interface FormDatasetDefinitionDB extends BaseDefinitionDB {
    datasetType: DatasetType.FORM;
    datasetFields: FormDatasetFields;
    datasetSections: DatasetSection[];
}

export type DatasetDefinitionDB = FormDatasetDefinitionDB | TableDatasetDefinitionDB;

export interface AgencyDefinition {
    agencyDatasetDefinitionId?: number;
    agencyDatasetId?: number;
    datasetTitle: string;
    datasetFields: AgencyDatasetField[];
    datasetSections?: null;
}

export interface AgencyDefinitionDB {
    agencyDatasetDefinitionId: number;
    agencyDatasetId: number;
    definitionVersion: number;
    isCurrent: number;
    datasetFields: AgencyDatasetField[];
    datasetSections?: null;
    createdBy: number;
    createdDate: string;
    effectiveTo: string | null;
    clientId: number;
}

export interface AgencyDataset {
    datasetId: number;
    datasetTitle: string;
    agreementTypeId: number | null;
    agreementType: string | null;
    agencyDefinition: AgencyDefinitionDB | null;
}
