import { flow, isNull, set } from 'lodash/fp';
import { Reducer } from 'redux';

import { LoginActionTypes } from '../../../auth/login/store';
import { DEFAULT_PAGE_SIZE } from '../../../constants/arkTable';
import { DoraAnalyticsActionTypes, DoraAnalyticsChart, DoraAnalyticsState } from './types';

export const INITIAL_STATE: DoraAnalyticsState = {
    tileInView: null,
    fetchingAnalytics: [],
    thirdPartyCoverage: [],
    selectedThirdPartyCoveragePie: [],
    totalFunctionCompanies: 0,
    selectedThirdPartyCoverageTable: [],
    selectedThirdPartyCompanyName: '',
    selectedThirdPartyCompanyId: null,
    selectedThirdPartyCoverageTablePageSize: DEFAULT_PAGE_SIZE,
    selectedThirdPartyCoverageTablePageNumber: 1,
    selectedThirdPartyCoverageTableColumnSort: undefined,
    fetchingSelectedThirdPartyCoverageTable: false,
    selectedThirdPartyCoverageTableFilters: {},
    analyticsError: null,
    networkDiagramData: { nodes: [], links: [] },
    contractualDataAnalytics: [],
    selectedContractualDataAnalytics: '',
    contractualDataModalOpen: false
};

const addAnalyticsToFetchingArray = (state: DoraAnalyticsState, chart: DoraAnalyticsChart) => [...state.fetchingAnalytics, chart];
const removeAnalyticsFromFetchingArray = (state: DoraAnalyticsState, chart: DoraAnalyticsChart) => state.fetchingAnalytics.filter(analytics => analytics !== chart);

export const doraAnalyticsReducer: Reducer<DoraAnalyticsState> = (state = INITIAL_STATE, { payload, type }): DoraAnalyticsState => {
    switch (type) {
        case DoraAnalyticsActionTypes.SET_DORA_ANALYTICS_TILE_IN_VIEW:
            return flow(
                set('tileInView', payload.tileInView),
                set('selectedThirdPartyCoveragePie', state.tileInView === DoraAnalyticsChart.THIRD_PARTY_COVERAGE ? [] : state.selectedThirdPartyCoveragePie),
                set('selectedThirdPartyCoverageTable', state.tileInView === DoraAnalyticsChart.THIRD_PARTY_COVERAGE ? [] : state.selectedThirdPartyCoverageTable)
            )(state);
        case DoraAnalyticsActionTypes.FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_STARTED:
        case DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_STARTED:
        case DoraAnalyticsActionTypes.FETCH_NETWORK_DIAGRAM_ANALYTICS_STARTED:
            return set('fetchingAnalytics', addAnalyticsToFetchingArray(state, payload.chart), state);
        case DoraAnalyticsActionTypes.FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_SUCCESSFUL:
        case DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_SUCCESSFUL:
        case DoraAnalyticsActionTypes.FETCH_NETWORK_DIAGRAM_ANALYTICS_SUCCESSFUL: {
            const { chart, analytics, key } = payload;
            return flow(
                set(`${key}`, analytics),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case DoraAnalyticsActionTypes.FETCH_OUTSTANDING_CONTRACTUAL_DATA_ANALYTICS_FAILED:
        case DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_COVERAGE_ANALYTICS_FAILED:
        case DoraAnalyticsActionTypes.FETCH_NETWORK_DIAGRAM_ANALYTICS_FAILED: {
            const { error, chart } = payload;
            return flow(
                set('analyticsError', error),
                set('fetchingAnalytics', removeAnalyticsFromFetchingArray(state, chart))
            )(state);
        }
        case DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COMPANY_ID: {
            if (isNull(payload)) {
                return flow(
                    set('selectedThirdPartyCompanyId', payload),
                    set('selectedThirdPartyCoverageTablePageSize', DEFAULT_PAGE_SIZE),
                    set('selectedThirdPartyCoverageTableColumnSort', undefined),
                    set('selectedThirdPartyCoverageTablePageNumber', 1),
                    set('selectedThirdPartyCoverageTableFilters', {}),
                    set('selectedThirdPartyCompanyName', '')
                )(state);
            } else {
                return set('selectedThirdPartyCompanyId', payload, state);
            }
        }
        case DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_COLUMN_SORT: {
            return set('selectedThirdPartyCoverageTableColumnSort', payload, state);
        }
        case DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_PAGE_SIZE:
            return set('selectedThirdPartyCoverageTablePageSize', payload, state);
        case DoraAnalyticsActionTypes.SET_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS: {
            const isTextFilter = payload.type === 'text';
            return flow(
                set(`selectedThirdPartyCoverageTableFilters[${payload.key}][${payload.type}]`, payload.value),
                set(`selectedThirdPartyCoverageTableFilters[${payload.key}][${isTextFilter ? 'dropdown' : 'text'}]`, isTextFilter ? null : '')
            )(state);
        }
        case DoraAnalyticsActionTypes.CLEAR_SELECTED_THIRD_PARTY_COVERAGE_TABLE_FILTERS:
            return set('selectedThirdPartyCoverageTableFilters', {}, state);
        case DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_STARTED: {
            return set('fetchingSelectedThirdPartyCoverageTable', true, state);
        }
        case DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_SUCCESSFUL: {
            const { data, total } = payload;
            return flow(
                set('selectedThirdPartyCoverageTable', data),
                set('totalFunctionCompanies', total),
                set('fetchingSelectedThirdPartyCoverageTable', false)
            )(state);
        }
        case DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_DETAILS_FAILED: {
            const { error } = payload;
            return flow(
                set('analyticsError', error),
                set('fetchingSelectedThirdPartyCoverageTable', false)
            )(state);
        }
        case DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_SUCCESSFUL: {
            const { analytics, key, selectedThirdPartyName } = payload;
            return flow(
                set(`${key}`, analytics),
                set('selectedThirdPartyCompanyName', selectedThirdPartyName)
            )(state);
        }
        case DoraAnalyticsActionTypes.FETCH_THIRD_PARTY_FUNCTION_COMPANY_PIE_ANALYTICS_FAILED: {
            return set('analyticsError', payload, state);
        }
        case DoraAnalyticsActionTypes.SET_SELECTED_OUTSTANDING_CONTRACTUAL_DATA_SECTION: {
            return flow(
                set('selectedContractualDataAnalytics', payload),
                set('contractualDataModalOpen', !!payload)
            )(state);
        }
        case LoginActionTypes.LOGOUT_SUCCESSFUL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
