import React from 'react';

import { AllMyDatasetDefinitions } from './AllMyDatasetDefinitions';

export interface MyDatasetsConfigureProps {
    datasetId: number;
}

export const MyDatasetsConfigure: React.FC<MyDatasetsConfigureProps> = ({ datasetId }) => (
    <AllMyDatasetDefinitions title='Configure' datasetId={datasetId} />
);
