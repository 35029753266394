import { isNull } from 'lodash/fp';
import { push } from 'redux-first-history';
import { all, call, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { fetchOpinionAverageBreakdown, fetchOpinionJurisdictionSummary, fetchOpinionsAverage } from '../../../../services/analytics';
import { fetchOpinionsStarted, openOpinionStarted } from '../../my-opinions/store';
import { fetchOpinionAverageBreakdownFailed, fetchOpinionAverageBreakdownStarted, fetchOpinionAverageBreakdownSuccessful, fetchOpinionsAverageFailed, fetchOpinionsAverageStarted, fetchOpinionsAverageSuccessful, fetchSingleDataJurisdictionFailed, fetchSingleDataJurisdictionStarted, fetchSingleDataJurisdictionSuccessful, redirectToOpinion, setOpinionAnalyticsScope, setOpinionAverageView, setOpinionSingleField, setOpinionTileInView, setSelectedSingleFieldJurisdiction } from './actions';
import { ExtensiveOpinionAverage, OpinionAnalyticsActionTypes, OpinionAverage, OpinionAverageView, OpinionMapAnalytics, OpinionSingleField } from './types';
import { getOpinionAnalyticsScope, getOpinionSingleField, getViewSignOffAnswers } from './selectors';
import { getDefaultSingleFieldForScope } from './utils';
import { OpinionScope } from '../../../admin/opinions/store';

export function* attemptRedirectTileInView({ payload }: ReturnType<typeof setOpinionTileInView>) {
    const { tileInView, shouldRedirect } = payload;
    if (shouldRedirect) {
        const redirectUrl = isNull(tileInView) ? '/opinions/analytics' : `/opinions/analytics/${tileInView.replaceAll(' ', '-')}`;
        yield put(push(redirectUrl));
    }
}

function* setTileInViewWatcher() {
    yield takeEvery(OpinionAnalyticsActionTypes.SET_OPINION_ANALYTICS_TILE_IN_VIEW, attemptRedirectTileInView);
}

export function* attemptFetchOpinionJurisdictionSummary() {
    try {
        const { fieldId, sectionId }: OpinionSingleField = yield select(getOpinionSingleField);
        const viewSignOffAnswers: boolean = yield select(getViewSignOffAnswers);
        const scope: OpinionScope = yield select(getOpinionAnalyticsScope);
        const analytics: OpinionMapAnalytics[] = yield call(fetchOpinionJurisdictionSummary, { scope, sectionId, fieldId, viewSignOffAnswers });
        yield put(fetchSingleDataJurisdictionSuccessful(analytics));
    } catch (e) {
        yield put(fetchSingleDataJurisdictionFailed((e as Error).message));
    }
}

function* fetchOpinionJurisdictionSummaryWatcher() {
    yield takeLatest(OpinionAnalyticsActionTypes.FETCH_SINGLE_DATA_JURISDICTION_ANALYTICS_STARTED, attemptFetchOpinionJurisdictionSummary);
}

export function* attemptFetchOpinionsAverage() {
    try {
        const scope: OpinionScope = yield select(getOpinionAnalyticsScope);
        const { opinionsWithAverage }: { opinionsWithAverage: OpinionAverage[]; } = yield call(fetchOpinionsAverage, { scope });
        yield put(fetchOpinionsAverageSuccessful(opinionsWithAverage));
    } catch (e) {
        yield put(fetchOpinionsAverageFailed((e as Error).message));
    }
}

function* fetchOpinionsAverageWatcher() {
    yield takeLatest(OpinionAnalyticsActionTypes.FETCH_OPINIONS_AVERAGE_ANALYTICS_STARTED, attemptFetchOpinionsAverage);
}

export function* attemptFetchOpinionAverageBreakdown({ payload }: ReturnType<typeof fetchOpinionAverageBreakdownStarted>) {
    try {
        const opinionId = payload;
        const scope: OpinionScope = yield select(getOpinionAnalyticsScope);
        const extensiveOpinionAverage: ExtensiveOpinionAverage = yield call(fetchOpinionAverageBreakdown, { opinionId, scope });
        yield put(fetchOpinionAverageBreakdownSuccessful(extensiveOpinionAverage));
    } catch (e) {
        yield put(fetchOpinionAverageBreakdownFailed((e as Error).message));
    }
}

function* fetchOpinionAverageBreakdownWatcher() {
    yield takeLatest(OpinionAnalyticsActionTypes.FETCH_OPINION_AVERAGE_BREAKDOWN_STARTED, attemptFetchOpinionAverageBreakdown);
}

export function* attemptFetchOpinionJurisdiction() {
    yield put(fetchSingleDataJurisdictionStarted());
}

function* setOpinionSingleFieldWatcher() {
    yield takeLatest(OpinionAnalyticsActionTypes.SET_OPINION_SINGLE_FIELD, attemptFetchOpinionJurisdiction);
}

export function* attemptUpdateSingleField({ payload }: ReturnType<typeof setOpinionAnalyticsScope>) {
    const defaultSingleField = getDefaultSingleFieldForScope(payload);
    yield put(setSelectedSingleFieldJurisdiction(null));
    yield put(setOpinionSingleField(defaultSingleField));
    yield put(fetchOpinionsAverageStarted());
    yield put(setOpinionAverageView(OpinionAverageView.OPINIONS));
}

function* setOpinionScopeWatcher() {
    yield takeLatest(OpinionAnalyticsActionTypes.SET_OPINION_ANALYTICS_SCOPE, attemptUpdateSingleField);
}

export function* attemptRedirectToOpinion({ payload }: ReturnType<typeof redirectToOpinion>) {
    const { opinionId, location } = payload;
    yield put(fetchOpinionsStarted(1));
    yield put(openOpinionStarted(location, 'application/pdf', opinionId));
}

function* goToOpinion() {
    yield takeLeading(OpinionAnalyticsActionTypes.REDIRECT_TO_OPINION, attemptRedirectToOpinion);
}

export function* viewMySignOffToggleUpdated() {
    yield put(fetchSingleDataJurisdictionStarted());
}

function* mySignOffToggleWatcher() {
    yield takeLatest(OpinionAnalyticsActionTypes.SET_VIEW_SIGN_OFF_ANSWERS, viewMySignOffToggleUpdated);
}

export function* opinionAnalyticsSaga() {
    yield all([
        fork(setTileInViewWatcher),
        fork(fetchOpinionJurisdictionSummaryWatcher),
        fork(setOpinionSingleFieldWatcher),
        fork(setOpinionScopeWatcher),
        fork(fetchOpinionsAverageWatcher),
        fork(goToOpinion),
        fork(fetchOpinionAverageBreakdownWatcher),
        fork(mySignOffToggleWatcher)
    ]);
}
