import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash';
import { RawDraftContentState } from 'react-draft-wysiwyg';

import styles from './Playbook.module.scss';
import { Toggle } from '../../shared/toggle';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getOpenSuggestedChange, getPlaybookSuggestedChanges, getSendingSuggestedChangesMessage, getSuggestedChangesMessage, getSuggestedChangesMessageUpdated, getSuggestionReviewModalOpen, toggleOutstandingSuggestedChange, toggleSuggestionReviewModalOpen, updateSuggestedChangesConversationStarted, updateSuggestedChangesNewMessage } from './store';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { PullRequest } from '../../shared/icons';
import { Conversation } from '../../shared/playbook/conversation/Conversation';
import { WYSIWYG, isEmpty } from '../../shared/wysiwyg/WYSIWYG';
import { getUser } from '../../auth/login/store';
import { InformationTooltip } from '../../shared/tooltip';
import { Spinner } from '../../shared/spinner/Spinner';

interface ReviewSuggestionModalProps {
    testId?: string;
}

export const ReviewSuggestionModal: React.FC<ReviewSuggestionModalProps> = ({ testId }) => {
    const dispatch = useAppDispatch();

    const suggestedChanges = useAppSelector(getPlaybookSuggestedChanges);
    const openSuggestedChange = useAppSelector(getOpenSuggestedChange);
    const isOpen = useAppSelector(getSuggestionReviewModalOpen);
    const user = useAppSelector(getUser);
    const newMessageUpdated = useAppSelector(getSuggestedChangesMessageUpdated);
    const sendingMessage = useAppSelector(getSendingSuggestedChangesMessage);
    const newMessage = useAppSelector(getSuggestedChangesMessage);
    const newMessageContent = isNull(newMessage) ? null : newMessage.content;

    const sendMessage = useCallback(() => dispatch(updateSuggestedChangesConversationStarted()), [dispatch]);

    const updateMessage = useCallback((rawValue: RawDraftContentState) => {
        if (isEmpty(rawValue)) {
            if (!isNull(newMessageContent)) {
                dispatch(updateSuggestedChangesNewMessage(null, user!));
            }
        } else {
            dispatch(updateSuggestedChangesNewMessage(rawValue, user!));
        }
    }, [newMessageContent, user, dispatch]);

    const suggestedChange = useMemo(() => !isNull(openSuggestedChange) && suggestedChanges.find(({ playbookSuggestionId }) => playbookSuggestionId === openSuggestedChange.playbookSuggestionId) || null, [openSuggestedChange, suggestedChanges]);

    const closeModal = useCallback(() => dispatch(toggleSuggestionReviewModalOpen()), [dispatch]);
    const toggleOutstanding = useCallback(() => dispatch(toggleOutstandingSuggestedChange(openSuggestedChange!.playbookSuggestionId)), [dispatch, openSuggestedChange]);

    const outstandingToggleValue = useMemo(() => !isNull(openSuggestedChange) && suggestedChanges.find(suggestedChange => suggestedChange.playbookSuggestionId === openSuggestedChange.playbookSuggestionId)!.outstanding !== 1 || false, [openSuggestedChange, suggestedChanges]);

    if (isNull(suggestedChange)) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={sendMessage}
            confirmLabel='Send Message'
            confirmDisabled={!newMessageUpdated}
            closeLabel='Close'
            testId={testId}
        >
            <div className={styles.reviewSuggestionModal} data-testid={`${testId}-modal-wrapper`}>
                <div className={styles.reviewSuggestionWrapper}>
                    <ModalHeader icon={PullRequest} label='Review Suggestion' testId={`${testId}-modal`} />
                    {sendingMessage ? <Spinner /> :
                        <div className={styles.adminConversationWrapper}>
                            <Conversation conversation={suggestedChange.conversation} currentUserId={user!.userId!} showSpinner={false} height='calc(40vh - 56px)' testId={testId} />
                            <div data-testid={`${testId}-conversation-input`}>
                                <WYSIWYG
                                    content={newMessageContent}
                                    updateContent={updateMessage}
                                    showBorder={false}
                                    height={'calc(30vh - 42px)'}
                                    maxHeight={'calc(30vh - 42px)'}
                                    showWrapperBorder
                                />
                            </div>
                        </div>
                    }
                    <div className={styles.outstandingToggleWrapper} data-testid={`${testId}-toggle-wrapper`}>
                        <div className={styles.outstandingToggleInfo}>
                            <InformationTooltip content='Once a suggested change is resolved, the user that raised the suggestion will be informed via email along with a summary of the above conversation. Leave this unchecked to leave the suggestion pending.' />
                        </div>
                        <div className={styles.outstandingToggleLabel} data-testid={`${testId}-toggle-label`}>Suggestion Resolved: </div>
                        <Toggle
                            checked={outstandingToggleValue}
                            onChange={toggleOutstanding}
                            testId={testId}
                        />
                    </div>
                </div>
            </div>
        </ConfirmationModal>
    );
};
