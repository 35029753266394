import { ApplicationState } from '../../../../store/rootReducer';
import { DropdownList, DropdownListDB, DropdownListState } from './types';

const getRoot = (state: ApplicationState): DropdownListState => state.admin.dropdownList;

export const getModalOpen = (state: ApplicationState): boolean => getRoot(state).modalOpen;

export const getPreviewModalOpen = (state: ApplicationState): boolean => getRoot(state).previewModalOpen;

export const getDropdownList = (state: ApplicationState): DropdownList => getRoot(state).dropdownList;

export const getAllDropdownLists = (state: ApplicationState): DropdownListDB[] => getRoot(state).publishedDropdownLists;

export const getIsSaving = (state: ApplicationState): boolean => getRoot(state).isSaving;
