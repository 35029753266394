import { debounce, isEqual, isNull, noop, set } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { IconButton } from '../../button/IconButton';
import { Search, Sort, SortAscending, SortDescending, Tag } from '../../icons';
import { ReadOnlyProvision } from '../view/ReadOnlyProvision';
import { Scrollable } from '../../scrollable/Scrollable';
import { WYSIWYG } from '../../wysiwyg/WYSIWYG';
import styles from './Tags.module.scss';
import { getCurrentView, getPlaybook, getPlaybookText, isSubheaderSection, MatchingProvisions, PlaybookView, toggleCreateTagModal, updatePlaybookTags } from '../../../admin/playbook/store';
import { CreateTagModal } from './CreateTagModal';
import { SearchTags } from './SearchTags';
import { TagList } from './TagList';
import { ABSTRACT_ID, getProvisionTitle } from '../../../constants/playbooks';
import { SortOrder } from '../../table/ArkTable';
import { ReadOnlySubheader } from '../view/ReadOnlySubheader';

const { french, primary } = styles;

export enum TagsView {
    SEARCH = 'Search',
    LIST = 'List'
}

interface TagsProps {
    openDeviations?: string[];
    toggleDeviation?: (sectionId: string) => void;
    testId?: string;
}

export const Tags: React.FC<TagsProps> = ({ openDeviations = [], toggleDeviation = noop, testId = 'playbook-tags-tab' }) => {
    const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
    const [selectedSectionId, setSelectedSectionId] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [matchingProvisions, setMatchingProvisions] = useState<MatchingProvisions>({});
    const [tagsView, setTagsView] = useState<TagsView>(TagsView.SEARCH);

    const dispatch = useAppDispatch();

    const playbookText = useAppSelector(getPlaybookText);
    const { content: { abstract, sections }, tags } = useAppSelector(getPlaybook);

    const currentView = useAppSelector(getCurrentView);
    const isTableView = currentView === PlaybookView.TABLE || false;

    const totalMatches = useMemo(() => Object.values(matchingProvisions).reduce((acc, cur) => acc + cur.length, 0), [matchingProvisions]);

    const provisionTitle = useCallback((id: string) => getProvisionTitle(id, sections), [sections]);

    const searchPlaybook = useCallback((searchTerm: string) => {
        const regex = new RegExp(searchTerm.toLowerCase(), 'gi');
        const matchingProvisions = Object.entries(playbookText).reduce((acc: MatchingProvisions, [sectionId, rows]) => {
            const matchingRows = rows.reduce((acc: { rowIndex: number; index: number; }[], row, rowIndex) => {
                const rowMatches = row.toLowerCase().match(regex);
                rowMatches && rowMatches.forEach((_, index) => acc.push({ rowIndex, index }));
                return acc;
            }, []);
            return set(sectionId, matchingRows, acc);
        }, {});
        setMatchingProvisions(matchingProvisions);
    }, [playbookText, setMatchingProvisions]);

    const updateSearchTerm = useCallback((searchTerm: string) => {
        setSearchTerm(searchTerm);
        if (searchTerm.length) {
            debounce(1000, searchPlaybook)(searchTerm);
        } else {
            setMatchingProvisions({});
        }
    }, [searchPlaybook, setSearchTerm, setMatchingProvisions]);

    const openTagModal = useCallback(() => dispatch(toggleCreateTagModal({ matchingProvisions, searchTerm })), [matchingProvisions, searchTerm, dispatch]);

    const isSearchView = tagsView === TagsView.SEARCH;
    const selectSearch = () => !isSearchView && setTagsView(TagsView.SEARCH);
    const selectList = () => isSearchView && setTagsView(TagsView.LIST);

    const tagMenuTitle = useMemo(() => isSearchView ? 'Search' : 'Tags', [isSearchView]);

    const sectionIds = useMemo(() => [ABSTRACT_ID, ...sections.map(({ sectionId }) => sectionId)], [sections]);

    const removeTag = useCallback((tagLabel: string) => {
        const updatedTags = tags.filter(({ label }) => label !== tagLabel);
        dispatch(updatePlaybookTags(updatedTags));
    }, [dispatch, tags]);

    const deviationOpen = useCallback((sectionId: string) => !!openDeviations.find(openDeviation => isEqual(openDeviation, sectionId)), [openDeviations]);

    const sortIcon = useMemo(() => {
        if (isNull(sortOrder)) {
            return Sort;
        }
        return sortOrder === SortOrder.ASCENDING ? SortDescending : SortAscending;
    }, [sortOrder]);

    const toggleSortOrder = useCallback(() => setSortOrder(sortOrder === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING), [sortOrder]);

    return (
        <div className={styles.tagsPageWrapper} data-testid={`${testId}-wrapper`}>
            <div className={styles.tagsWrapper}>
                <div className={styles.tagsViewWrapper}>
                    <div className={styles.tagIconsWrapper}>
                        <div className={styles.searchButtonWrapper}>
                            <IconButton icon={Search} onClick={selectSearch} color={isSearchView ? french : primary} fontSize={30} testId={`${testId}-search`} />
                        </div>
                        <IconButton icon={Tag} onClick={selectList} color={!isSearchView ? french : primary} fontSize={25} testId={`${testId}-list`} />
                        {!isSearchView &&
                            <div className={styles.sortTagsWrapper}>
                                <IconButton icon={sortIcon} onClick={toggleSortOrder} fontSize={25} testId={`${testId}-sort-order`} />
                            </div>
                        }
                    </div>
                    <div className={styles.tagsHeader} data-testid={`${testId}-menu-title`}>{tagMenuTitle}</div>
                </div>
                {isSearchView ? <SearchTags
                    searchTerm={searchTerm}
                    updateSearchTerm={updateSearchTerm}
                    openTagModal={openTagModal}
                    getProvisionTitle={provisionTitle}
                    matchingProvisions={matchingProvisions}
                    totalMatches={totalMatches}
                    testId={testId}
                /> : <TagList
                    tags={tags}
                    getProvisionTitle={provisionTitle}
                    sectionIds={sectionIds}
                    canDelete
                    removeTag={removeTag}
                    sortOrder={sortOrder}
                    testId={testId}
                />
                }
            </div>
            <div className={styles.contentsWrapper} style={{ width: '80%' }}>
                <Scrollable>
                    <div className={styles.tagsAbstractWrapper} id='playbook-abstract' onClick={() => setSelectedSectionId(ABSTRACT_ID)} data-tesid={`${testId}-abstract-wrapper`}>
                        <WYSIWYG
                            content={abstract}
                            updateContent={noop}
                            showBorder={false}
                            showWrapperBorder={false}
                            height='fit-content'
                            minHeight='fit-content'
                            maxHeight='fit-content'
                            disabled={false}
                            toolbarHidden
                            isSelected={ABSTRACT_ID === selectedSectionId}
                            getSelectedText={updateSearchTerm}
                            padding='0px'
                            editorPadding='0px'
                        />
                    </div>
                    {sections.map((section, index) => isSubheaderSection(section) ? (
                        <ReadOnlySubheader
                            key={section.sectionId}
                            subheader={section}
                            testId={`${testId}-subheader-${index}`}
                        />
                    ) : (
                        <ReadOnlyProvision
                            key={section.sectionId}
                            section={section}
                            setSelectedSectionId={setSelectedSectionId}
                            selectedSectionId={selectedSectionId}
                            readOnly={false}
                            getSelectedText={updateSearchTerm}
                            isTableView={isTableView}
                            deviationOpen={deviationOpen(section.sectionId)}
                            toggleDeviation={toggleDeviation}
                            testId={`${testId}-read-only-provision-${index}`}
                        />
                    ))}
                </Scrollable>
            </div>
            <CreateTagModal testId={`${testId}-create-tag`} />
        </div>
    );
};
