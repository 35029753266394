import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Document } from '../../shared/icons';
import { TypeDeleteConfirmationModal } from '../../shared/modal/TypeDeleteConfirmationModal';
import { deleteOpinionStarted, getIsDeleting, getOpinionToDelete, toggleDeleteConfirmationModal } from './store';

interface DeleteOpinionConfirmationModalProps {
    isOpen: boolean;
}

export const DeleteOpinionConfirmationModal: React.FC<DeleteOpinionConfirmationModalProps> = ({ isOpen }) => {
    const dispatch = useAppDispatch();
    const opinionToDelete = useAppSelector(getOpinionToDelete);
    const isDeleting = useAppSelector(getIsDeleting);
    const deleteOpinion = () => dispatch(deleteOpinionStarted(opinionToDelete!.location));
    const closeModal = () => dispatch(toggleDeleteConfirmationModal(null));

    const warning = `This will permanently delete this opinion, all amendments and their associated data${!opinionToDelete?.bespoke ? ' for all clients' : ''}`;

    if (!opinionToDelete) {
        return null;
    }

    return (
        <TypeDeleteConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            deleteAction={deleteOpinion}
            isDeleting={isDeleting}
            headerLabel='Delete Opinion'
            name={opinionToDelete.description}
            testId='opinion'
            warning={warning}
            icon={Document}
        />
    );
};
