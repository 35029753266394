import React, { useMemo } from 'react';

import { MyDatasetDefinition } from '../../MyDatasetDefinition';
import { getMyDatasetDefinitions } from '../../store';
import { useAppSelector } from '../../../../../hooks/react-redux';

interface DatasetFieldProps {
    childDatasetId: string;
    fieldOpen: boolean;
    parents: number;
    fieldHidden: boolean;
    isPreview: boolean;
    modalInstance?: boolean;
    parentHiddenDocumentNameIds?: number[];
}

export const DatasetField: React.FC<DatasetFieldProps> = ({
    childDatasetId,
    fieldOpen,
    parents,
    fieldHidden,
    isPreview,
    modalInstance,
    parentHiddenDocumentNameIds
}) => {
    const datasetDefinitions = useAppSelector(getMyDatasetDefinitions);
    const datasetDefinition = useMemo(() => datasetDefinitions.find(({ datasetId }) => (datasetId === parseInt(childDatasetId) || null)), [datasetDefinitions, childDatasetId]);

    return (
        <div data-testid={`dataset-definition-wrapper-${childDatasetId}`}>
            {fieldOpen && datasetDefinition && <MyDatasetDefinition datasetDefinition={datasetDefinition} parents={parents + 1} modalInstance={modalInstance} datasetHidden={fieldHidden} isPreview={isPreview} parentHiddenDocumentNameIds={parentHiddenDocumentNameIds} />}
        </div>
    );
};
