import { ColDef, GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { flow, set, unset } from 'lodash/fp';
import React from 'react';

import { AgencyDefinition, AgencyDefinitionDB } from '../../../datasets/store';
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { Icon } from '../../../shared/icon/Icon';
import { Pencil } from '../../../shared/icons';
import { dateFormatter, Table, versionFormatter } from '../../../shared/table';
import { editAgencyDefinition, fetchAgencyDefinitionsStarted, fetchAgencyFieldsStarted, getAllAgencyDefinitions } from '../store';
import sharedStyles from '../SharedBuilder.module.scss';
import { useFetchStarted } from '../../../../hooks/useFetchStarted';

const stripDefinition = (definition: AgencyDefinitionDB | null, datasetTitle: string): AgencyDefinition | null => definition ? flow(
    unset('clientId'),
    unset('createdBy'),
    unset('createdDate'),
    unset('effectiveTo'),
    unset('isCurrent'),
    unset('definitionVersion'),
    set('datasetTitle', datasetTitle)
)(definition) : null;

const IconCell = ({ data }: ICellRendererParams) => {
    const dispatch = useAppDispatch();
    const strippedDefinition = stripDefinition(data.agencyDefinition, data.datasetTitle);

    const editDefinition = () => {
        dispatch(fetchAgencyFieldsStarted(data.datasetId));
        dispatch(editAgencyDefinition(strippedDefinition, data.datasetTitle, data.agreementTypeId, data.datasetId));
    };

    return (
        <div className={sharedStyles.editDatasetButton} onClick={editDefinition}>
            <Icon fontSize={20} icon={Pencil} />
        </div>
    );
};

export const AgencyDatasetsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchAgencyDefinitionsStarted()]);
    const agencyDefinitions = useAppSelector(getAllAgencyDefinitions);

    const defaultCellStyle = { textAlign: 'left', fontSize: '14px' };
    const columnDefs: ColDef[] = [
        { headerName: 'Title', field: 'datasetTitle', cellStyle: defaultCellStyle, width: 0.45 },
        { headerName: 'Created Date', field: 'agencyDefinition.createdDate', cellStyle: defaultCellStyle, width: 0.2, valueFormatter: dateFormatter },
        { headerName: 'Agreement Type', field: 'agreementType', cellStyle: defaultCellStyle, width: 0.15 },
        { headerName: 'Version Number', field: 'agencyDefinition.definitionVersion', cellStyle: defaultCellStyle, width: 0.15, valueFormatter: versionFormatter },
        { headerName: '', field: '', cellRenderer: 'iconCellRenderer', width: 0.05 },
    ];

    const gridOptions: GridOptions = {
        components: {
            iconCellRenderer: IconCell
        },
        paginationPageSize: 20
    };

    const onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => {
        dispatch(fetchAgencyFieldsStarted(data.datasetId));
        dispatch(editAgencyDefinition(stripDefinition(data.agencyDefinition, data.datasetTitle), data.datasetTitle, data.agreementTypeId, data.datasetId));
    };

    return (
        <Table
            colDefs={columnDefs}
            rowData={agencyDefinitions}
            gridOptions={gridOptions}
            percentageWidths={true}
            onRowDoubleClicked={onRowDoubleClicked}
            testId='agency-dataset'
            padding='0px'
        />
    );
};
