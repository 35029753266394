import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { Button } from '../../../shared/button/Button';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { Spinner } from '../../../shared/spinner/Spinner';
import { MyDatasetTile } from '../../my-datasets/MyDatasetTile';
import { DatasetView, fetchDefinitionsForDatasetIdStarted, getAllDatasets, isFetchingDatasets, toggleDatasetView } from '../store';
import styles from './ConfigureDatasets.module.scss';

export const DatasetsList: React.FC = () => {
    const [, screenHeight] = useWindowResize();
    const resultsHeight = useMemo(() => screenHeight - 240, [screenHeight]);
    const dispatch = useAppDispatch();
    const allDatasets = useAppSelector(getAllDatasets);
    const isLoading = useAppSelector(isFetchingDatasets);

    const openPreview = useCallback((datasetId: number) => {
        dispatch(toggleDatasetView(DatasetView.DOCUMENTS_PREVIEW, datasetId));
        dispatch(fetchDefinitionsForDatasetIdStarted(datasetId));
    }, [dispatch]);

    const openConfigure = useCallback((datasetId: number) => {
        dispatch(toggleDatasetView(DatasetView.DOCUMENTS_CONFIGURE, datasetId));
        dispatch(fetchDefinitionsForDatasetIdStarted(datasetId));
    }, [dispatch]);

    const backToDatasetTable = () => dispatch(toggleDatasetView(DatasetView.TABLE_STANDARD));

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className={styles.datasetsWrapper} data-testid='my-datasets-wrapper'>
            <div className={styles.datasetsTitleContainer}>
                <div className={styles.datasetsHeader} data-testid='my-datasets-header'>Document Specific Datasets</div>
                <Button label='Back' onClick={backToDatasetTable} />
            </div>
            <div className={styles.datasetsResultsWrapper} data-testid='my-datasets-results-wrapper' style={{ height: `${resultsHeight}px` }}>
                <Scrollable>
                    <div className={styles.tilesWrapper}>
                        <div className={styles.tilesColumnWrapper}>
                            {allDatasets.map(({ datasetId, documentNames, agreementType }) => {
                                const documentList = documentNames.map(({ documentName }) => documentName);
                                return (
                                    <MyDatasetTile
                                        key={datasetId}
                                        datasetId={datasetId}
                                        documentNames={documentList}
                                        agreementType={agreementType}
                                        preview={() => openPreview(datasetId)}
                                        configure={() => openConfigure(datasetId)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </Scrollable>
            </div>
        </div>
    );
};
