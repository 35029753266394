import { action } from 'typesafe-actions';

import { CompanyEntity } from '../../../admin/entity/store';
import { DoraReportingActionTypes } from './types';

export const downloadRegisterOfInformationStarted = () => action(DoraReportingActionTypes.DOWNLOAD_REGISTER_OF_INFORMATION_STARTED);
export const downloadRegisterOfInformationSuccessful = () => action(DoraReportingActionTypes.DOWNLOAD_REGISTER_OF_INFORMATION_SUCCESSFUL);
export const downloadRegisterOfInformationFailed = (error: string) => action(DoraReportingActionTypes.DOWNLOAD_REGISTER_OF_INFORMATION_FAILED, error);

export const fetchAllInScopeEntitiesStarted = () => action(DoraReportingActionTypes.FETCH_ALL_IN_SCOPE_ENTITIES_STARTED);
export const fetchAllInScopeEntitiesSuccessful = (entities: CompanyEntity[]) => action(DoraReportingActionTypes.FETCH_ALL_IN_SCOPE_ENTITIES_SUCCESSFUL, entities);
export const fetchAllInScopeEntitiesFailed = (error: string) => action(DoraReportingActionTypes.FETCH_ALL_IN_SCOPE_ENTITIES_FAILED, error);

export const setRoiCurrency = (value: string | null) => action(DoraReportingActionTypes.SET_ROI_CURRENCY, value);

export const setSelectedInScopeEntity = (entity: CompanyEntity | null) => action(DoraReportingActionTypes.SET_SELECTED_IN_SCOPE_ENTITY, entity);
