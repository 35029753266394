import React, { useEffect, useState } from 'react';

import sharedStyles from '../SharedBuilder.module.scss';
import { Icon as ArkIcon } from '../../../shared/icon/Icon';
import { Document } from '../../../shared/icons';
import { RefModal } from './RefModal';
import { Position } from '../../../shared/modal/PositionModal';

interface RefProps {
    modalOpen: boolean;
    testId: string;
    refLabel: string;
    updateRef: (value: string) => void;
    toggleMenuOpen: (value: boolean) => void;
    disableInputs?: boolean;
    disabled?: boolean;
}

export const Ref: React.FC<RefProps> = ({ modalOpen, testId, refLabel, updateRef, toggleMenuOpen, disableInputs = false, disabled = false }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position | null>(null);
    const openModal = (x: number, y: number) => {
        setPosition({ x, y });
        setIsOpen(true);
        toggleMenuOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        toggleMenuOpen(false);
    };

    useEffect(() => {
        if (modalOpen) {
            setIsOpen(modalOpen);
        }
    }, [modalOpen]);

    return (
        <>
            <button className={sharedStyles.refButton} onClick={e => openModal(e.clientX, e.clientY)} data-testid={`${testId}-button`} disabled={disabled}>
                <ArkIcon icon={Document} fontSize={25}/>
            </button>
            <RefModal
                isOpen={isOpen}
                closeModal={closeModal}
                position={position}
                updateRef={updateRef}
                refLabel={refLabel}
                disableInputs={disableInputs || disabled}
            />
        </>
    );
};
