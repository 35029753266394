import classNames from 'classnames';
import { isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { DatasetDefinition, DatasetFieldType, SingleDatasetField } from '../../../datasets/store';
import { IconButton } from '../../../shared/button/IconButton';
import { Icon as ArkIcon } from '../../../shared/icon/Icon';
import { ArrowRight, Delete } from '../../../shared/icons';
import { UnsavedChangesModal } from '../../../shared/modal/UnsavedChangesModal';
import { fieldListIcons } from '../FieldList';
import { stripDefinition } from '../PublishedDatasetsTable';
import sharedStyles from '../SharedBuilder.module.scss';
import { LinkDirection, getAllDatasetDefinitions, getDefinitionHasUnlabelledFields, getDefinitionHasUpdated, getLinkDownUnsavedChangesModalOpen, openDatasetDefinition, removeDatasetField, removeDatasetGroupField, toggleRefModalOpen, toggleSystemIdModalOpen, toggleUnsavedChangesModal, updateDatasetField, updateDatasetGroupField } from '../store';
import { Ref } from './Ref';
import { Settings } from './Settings';
import { SystemId } from './SystemId';

interface ContentProps {
    field: SingleDatasetField;
    index: number;
    groupIndex?: number;
    isGroup?: boolean;
}

export const Content: React.FC<ContentProps> = ({ field, index, groupIndex, isGroup = false }) => {
    const { label, description, id, settings, type, refLabel, systemId } = field;
    const dispatch = useAppDispatch();
    const allDefinitions = useAppSelector(getAllDatasetDefinitions);
    const hasUpdated = useAppSelector(getDefinitionHasUpdated);
    const unsavedChangesModalOpen = useAppSelector(getLinkDownUnsavedChangesModalOpen);
    const unlabelledFields = useAppSelector(getDefinitionHasUnlabelledFields);

    const updateLabel = (value: string) => {
        isGroup ? dispatch(updateDatasetGroupField('label', value, index, groupIndex!)) : dispatch(updateDatasetField('label', value, index));
    };

    const updateDescription = (value: string) => {
        isGroup ? dispatch(updateDatasetGroupField('description', value, index, groupIndex!)) : dispatch(updateDatasetField('description', value, index));
    };

    const removeRow = () => {
        isGroup ? dispatch(removeDatasetGroupField(id!, groupIndex!)) : dispatch(removeDatasetField(id!));
    };

    const updateRef = (value: string) => {
        isGroup ? dispatch(updateDatasetGroupField('refLabel', value, index, groupIndex!)) : dispatch(updateDatasetField('refLabel', value, index));
    };

    const toggleMenuOpen = (value: boolean) => {
        isGroup ? dispatch(toggleRefModalOpen(index, value, groupIndex)) : dispatch(toggleRefModalOpen(index, value));
    };

    const toggleIdModalOpen = (value: boolean) => {
        isGroup ? dispatch(toggleSystemIdModalOpen(index, value, groupIndex)) : dispatch(toggleSystemIdModalOpen(index, value));
    };

    const updateSystemId = (value: string) => {
        isGroup ? dispatch(updateDatasetGroupField('systemId', value, index, groupIndex!)) : dispatch(updateDatasetField('systemId', value, index));
    };

    const testId = `draggable-field-item-${!isUndefined(groupIndex) ? groupIndex : ''}${index}`;

    const labelValue = useMemo(() => {
        if (type === DatasetFieldType.LABEL) {
            return settings.labelContent?.blocks[0].text || '';
        }
        return label;
    }, [settings.labelContent, label, type]);

    const showDatasetLink = useMemo(() => type === DatasetFieldType.DATASET && !!settings.datasetLinked, [settings, type]);
    const datasetLinkId = useMemo(() => !!settings.datasetLinked && parseInt(settings.datasetLinked) || 0, [settings.datasetLinked]);
    const definitionLinked = useMemo(() => datasetLinkId ? stripDefinition(allDefinitions.find(({ datasetId }) => datasetId === datasetLinkId)!) : null, [datasetLinkId, allDefinitions]);

    const toggleUnsavedChangesWarningModal = useCallback((linkDirection: LinkDirection | null) => dispatch(toggleUnsavedChangesModal(linkDirection)), [dispatch]);
    const closeUnsavedChangesModal = useCallback(() => toggleUnsavedChangesWarningModal(null), [toggleUnsavedChangesWarningModal]);
    const openDefinition = useCallback((definition: DatasetDefinition) => dispatch(openDatasetDefinition(definition)), [dispatch]);
    const attemptOpenDefinition = useCallback(() => {
        if (definitionLinked) {
            hasUpdated ? toggleUnsavedChangesWarningModal(LinkDirection.DOWN) : openDefinition(definitionLinked);
        }
    }, [hasUpdated, toggleUnsavedChangesWarningModal, openDefinition, definitionLinked]);

    const confirmOpen = useCallback(() => {
        openDefinition(definitionLinked!);
        closeUnsavedChangesModal();
    }, [openDefinition, closeUnsavedChangesModal, definitionLinked]);

    return (
        <>
            <div className={sharedStyles.contentWrapper}>
                <div className={sharedStyles.type}>
                    <div className={sharedStyles.typeLabel} data-testid={testId}>{type}</div>
                    <div className={sharedStyles.typeIcons}>
                        {showDatasetLink &&
                            <div className={sharedStyles.datasetLinkWrapper}>
                                <IconButton onClick={attemptOpenDefinition} icon={ArrowRight} />
                            </div>
                        }
                        <ArkIcon icon={fieldListIcons[type]} fontSize={35} />
                    </div>
                </div>
                <div className={sharedStyles.refAndIdWrapper}>
                    <Ref
                        modalOpen={settings.refOpen || false}
                        testId={`${testId}-ref`}
                        refLabel={refLabel}
                        updateRef={updateRef}
                        toggleMenuOpen={toggleMenuOpen}
                    />
                    <SystemId
                        modalOpen={settings.systemIdOpen || false}
                        testId={`${testId}-system-id`}
                        systemId={systemId}
                        updateSystemId={updateSystemId}
                        toggleSystemIdModalOpen={toggleIdModalOpen}
                    />
                </div>
                <input
                    data-testid={`${testId}-label`}
                    className={classNames(sharedStyles.contentLabel, { [sharedStyles.contentLabelRequired]: unlabelledFields && !labelValue })}
                    placeholder='Label...'
                    onChange={e => updateLabel(e.target.value)}
                    value={labelValue}
                    disabled={type === DatasetFieldType.LABEL}
                    style={{ width: type === DatasetFieldType.LABEL ? 'calc(70% + 10px)' : '30%' }}
                />
                {type !== DatasetFieldType.LABEL &&
                    <input
                        data-testid={`${testId}-description`}
                        className={sharedStyles.contentDescription}
                        placeholder='Description...'
                        onChange={e => updateDescription(e.target.value)}
                        value={description}
                    />
                }
                <Settings
                    settings={settings}
                    index={index}
                    isGroup={isGroup}
                    groupIndex={groupIndex}
                    testId={`${testId}-settings`}
                    field={field}
                />
                <button className={sharedStyles.removeRowWrapper} onClick={removeRow} data-testid={`${testId}-delete-button`}>
                    <ArkIcon icon={Delete} fontSize={20} />
                </button>
            </div>
            <UnsavedChangesModal
                isOpen={unsavedChangesModalOpen}
                closeModal={closeUnsavedChangesModal}
                confirm={confirmOpen}
            />
        </>
    );
};
