import { getOr, isNull } from 'lodash/fp';

import { ApplicationState } from '../../../../store/rootReducer';
import { DocumentReportField, DocumentReportHierarchy, DocumentReportTemplate, DocumentReportingState, ReportPage, ReportTab, UpsertDocumentReportTemplate } from './types';
import { SearchFilter } from '../../search/store';

const getRoot = (state: ApplicationState): DocumentReportingState => state.document.reporting;

export const getReportPage = (state: ApplicationState): ReportPage => getRoot(state).reportPage;

export const getSelectedTab = (state: ApplicationState): ReportTab => getRoot(state).selectedTab;

export const getReportFilters = (state: ApplicationState): SearchFilter => getRoot(state).reportFilters;

export const getSelectedDocumentNameIds = (state: ApplicationState): string[] | null => {
    return getOr(null, 'documentNameId', getReportFilters(state));
};

export const getAvailableFields = (state: ApplicationState): DocumentReportField[] => getRoot(state).availableFields;

export const getReportFieldHierarchy = (state: ApplicationState): DocumentReportHierarchy[] => getRoot(state).reportFieldHierarchy;

export const getReportFields = (state: ApplicationState): DocumentReportField[] => getRoot(state).reportFields;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).fetchingAvailableFields;

export const getIsGeneratingReport = (state: ApplicationState): boolean => getRoot(state).generatingReport;

export const getAvailableFieldsError = (state: ApplicationState): null | string => getRoot(state).fetchingError;

export const getBackConfirmationModalOpen = (state: ApplicationState): boolean => getRoot(state).backConfirmationModalOpen;

export const getReportUpdated = (state: ApplicationState): boolean => getRoot(state).reportUpdated;

export const getAllReportTemplates = (state: ApplicationState): DocumentReportTemplate[] => getRoot(state).reportTemplates;

export const getSaveTemplateModalOpen = (state: ApplicationState): boolean => getRoot(state).saveTemplateModalOpen;

export const getReportTemplateName = (state: ApplicationState): string => getRoot(state).templateName;
export const getReportTemplateDescription = (state: ApplicationState): string => getRoot(state).templateDescription;

export const getIsFetchingTemplates = (state: ApplicationState): boolean => getRoot(state).fetchingTemplates;

export const getSelectedTemplate = (state: ApplicationState): DocumentReportTemplate | null => getRoot(state).selectedTemplate;

export const getSelectedTemplateId = (state: ApplicationState): number | undefined => getSelectedTemplate(state)?.reportTemplateId;

export const getUpsertReportTemplate = (state: ApplicationState): UpsertDocumentReportTemplate => {
    const reportFields = getReportFields(state);
    const name: string = getReportTemplateName(state);
    const description: string = getReportTemplateDescription(state);
    const reportTemplateId = getSelectedTemplateId(state);
    const filters = getReportFilters(state);
    return {
        name,
        description,
        reportTemplateId,
        reportFields,
        filters,
        isOpinionReport: false
    };
};

export const getConfirmDeleteReportTemplate = (state: ApplicationState): number | null => getRoot(state).confirmDeleteReportTemplate;

export const getConfirmDeleteModalOpen = (state: ApplicationState): boolean => !isNull(getConfirmDeleteReportTemplate(state));

export const getIsDeleting = (state: ApplicationState): boolean => getRoot(state).isDeleting;
