import { BaseEntity, CompanyEntity } from '../../../admin/entity/store';
import { DropdownOption } from '../../../shared/dropdown/Dropdown';
import { TableFilters } from '../../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../../shared/table/ArkTable';

export enum DoraMyCompaniesActionTypes {
    TOGGLE_MY_COMPANIES_VIEW = 'TOGGLE_MY_COMPANIES_VIEW',
    SET_SELECTED_COMPANY_ENTITY = 'SET_SELECTED_COMPANY_ENTITY',
    TOGGLE_COMPANY_ENTITY_MODAL = 'TOGGLE_COMPANY_ENTITY_MODAL',
    FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_STARTED = 'FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_STARTED',
    FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_SUCCESSFUL = 'FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_SUCCESSFUL',
    FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_FAILED = 'FETCH_PAGINATED_IN_SCOPE_DORA_COMPANIES_FAILED',
    FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_STARTED = 'FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_STARTED',
    FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_SUCCESSFUL = 'FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_SUCCESSFUL',
    FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_FAILED = 'FETCH_PAGINATED_OUT_OF_SCOPE_DORA_COMPANIES_FAILED',
    SET_IN_SCOPE_COMPANY_TABLE_FILTERS = 'SET_IN_SCOPE_COMPANY_TABLE_FILTERS',
    SET_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS = 'SET_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS',
    SET_IN_SCOPE_COMPANY_TABLE_COLUMN_SORT = 'SET_IN_SCOPE_COMPANY_TABLE_COLUMN_SORT',
    SET_OUT_OF_SCOPE_COMPANY_TABLE_COLUMN_SORT = 'SET_OUT_OF_SCOPE_COMPANY_TABLE_COLUMN_SORT',
    SET_IN_SCOPE_COMPANIES_PAGE_SIZE = 'SET_IN_SCOPE_COMPANIES_PAGE_SIZE',
    SET_OUT_OF_SCOPE_COMPANIES_PAGE_SIZE = 'SET_OUT_OF_SCOPE_COMPANIES_PAGE_SIZE',
    CLEAR_IN_SCOPE_COMPANY_TABLE_FILTERS = 'CLEAR_IN_SCOPE_COMPANY_TABLE_FILTERS',
    CLEAR_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS = 'CLEAR_OUT_OF_SCOPE_COMPANY_TABLE_FILTERS',
    COMPANIES_IN_SCOPE_PAGINATION_NEXT = 'COMPANIES_IN_SCOPE_PAGINATION_NEXT',
    COMPANIES_IN_SCOPE_PAGINATION_PREVIOUS = 'COMPANIES_IN_SCOPE_PAGINATION_PREVIOUS',
    COMPANIES_OUT_OF_SCOPE_PAGINATION_NEXT = 'COMPANIES_OUT_OF_SCOPE_PAGINATION_NEXT',
    COMPANIES_OUT_OF_SCOPE_PAGINATION_PREVIOUS = 'COMPANIES_OUT_OF_SCOPE_PAGINATION_PREVIOUS',
    FETCH_MY_COMPANY_ENTITIES_STARTED = 'FETCH_MY_COMPANY_ENTITIES_STARTED',
    FETCH_MY_COMPANY_ENTITIES_SUCCESSFUL = 'FETCH_MY_COMPANY_ENTITIES_SUCCESSFUL',
    FETCH_MY_COMPANY_ENTITIES_FAILED = 'FETCH_MY_COMPANY_ENTITIES_FAILED',
    SAVE_COMPANY_ENTITY_STARTED = 'SAVE_COMPANY_ENTITY_STARTED',
    SAVE_COMPANY_ENTITY_SUCCESSFUL = 'SAVE_COMPANY_ENTITY_SUCCESSFUL',
    SAVE_COMPANY_ENTITY_FAILED = 'SAVE_COMPANY_ENTITY_FAILED',
    FETCH_ENTITY_FUNCTION_IDS_STARTED = 'FETCH_ENTITY_FUNCTION_IDS_STARTED',
    FETCH_ENTITY_FUNCTION_IDS_SUCCESSFUL = 'FETCH_ENTITY_FUNCTION_IDS_SUCCESSFUL',
    FETCH_ENTITY_FUNCTION_IDS_FAILED = 'FETCH_ENTITY_FUNCTION_IDS_FAILED',
    UPDATE_SELECTED_ENTITY_FUNCTION_IDS = 'UPDATE_SELECTED_ENTITY_FUNCTION_IDS',
    FETCH_DORA_FUNCTIONS_IN_SUPPLY_CHAIN_STARTED = 'FETCH_DORA_FUNCTIONS_IN_SUPPLY_CHAIN_STARTED',
    FETCH_DORA_FUNCTIONS_IN_SUPPLY_CHAIN_SUCCESSFUL = 'FETCH_DORA_FUNCTIONS_IN_SUPPLY_CHAIN_SUCCESSFUL',
    FETCH_DORA_FUNCTIONS_IN_SUPPLY_CHAIN_FAILED = 'FETCH_DORA_FUNCTIONS_IN_SUPPLY_CHAIN_FAILED',
    CLOSE_MIGRATE_FUNCTION_CONFIRMATION_MODAL = 'CLOSE_MIGRATE_FUNCTION_CONFIRMATION_MODAL',
    FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_STARTED = 'FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_STARTED',
    FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_SUCCESSFUL = 'FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_SUCCESSFUL',
    FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_FAILED = 'FETCH_AVAILABLE_FUNCTION_MIGRATION_OPTIONS_FAILED',
    UPDATE_COMPANY_FUNCTION_MIGRATIONS = 'UPDATE_COMPANY_FUNCTION_MIGRATIONS',
    MIGRATE_COMPANY_FUNCTIONS_STARTED = 'MIGRATE_COMPANY_FUNCTIONS_STARTED',
    MIGRATE_COMPANY_FUNCTIONS_SUCCESSFUL = 'MIGRATE_COMPANY_FUNCTIONS_SUCCESSFUL',
    MIGRATE_COMPANY_FUNCTIONS_FAILED = 'MIGRATE_COMPANY_FUNCTIONS_FAILED',
    COMPANY_FUNCTION_MIGRATION_CHECK_STARTED = 'COMPANY_FUNCTION_MIGRATION_CHECK_STARTED',
    COMPANY_FUNCTION_MIGRATION_CHECK_SUCCESSFUL = 'COMPANY_FUNCTION_MIGRATION_CHECK_SUCCESSFUL',
    COMPANY_FUNCTION_MIGRATION_CHECK_FAILED = 'COMPANY_FUNCTION_MIGRATION_CHECK_FAILED',
    TOGGLE_TYPE_OF_ENTITY_DESCRIPTION = 'TOGGLE_TYPE_OF_ENTITY_DESCRIPTION',
    UPDATE_SELECTED_ENTITY = 'UPDATE_SELECTED_ENTITY',
    TOGGLE_ROI_MAINTAINER = 'TOGGLE_ROI_MAINTAINER'
}

export enum DoraMyCompaniesView {
    IN_SCOPE = 'in scope',
    OUT_OF_SCOPE = 'out of scope'
}

export interface Migrations {
    functionId: number;
    newCompanyId: number | null;
    options: DropdownOption[];
}

export interface FunctionMigration {
    currentCompanyId: number;
    migrations: Migrations[]
}

export interface DoraMyCompaniesState {
    myCompaniesView: DoraMyCompaniesView;
    companyEntityModalOpen: boolean;
    selectedCompanyEntity: CompanyEntity | null;
    savedCompanyEntity: CompanyEntity | null;
    selectedCompanyEntityFunctionIds: number[];
    currentCompanyEntityFunctionIds: number[];
    fetchingCompanies: boolean;
    inScopeCompanies: CompanyEntity[];
    outOfScopeCompanies: CompanyEntity[];
    inScopeTotalCompanies: number;
    outOfScopeTotalCompanies: number;
    inScopeCompanyFilters: TableFilters;
    outOfScopeCompanyFilters: TableFilters;
    inScopeCompanyPage: number;
    outOfScopeCompanyPage: number;
    inScopeCompanyPageSize: number;
    outOfScopeCompanyPageSize: number;
    fetchError: string | null;
    inScopeCompanySort?: ColumnSort;
    outOfScopeCompanySort?: ColumnSort;
    myCompanyEntities: BaseEntity[];
    fetchingEntityFunctionIds: boolean;
    functionMigration: FunctionMigration | null;
    isCompanyFunctionMigration: boolean;
    entityDescriptionOpen: boolean;
    isSavingEntity: boolean;
}
