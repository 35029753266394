import { RawDraftContentState } from 'draft-js';
import { action } from 'typesafe-actions';

import { User } from '../../../auth/login/store';
import { BasicPlaybook, PlaybookBuilderTab, Playbook, AdminPlaybookActionTypes, PlaybookByDefinitionId, PlaybookById, PlaybookContent, PlaybookPage, PlaybookTag, PlaybookView, TagContent, BuildPage, PlaybookQueryDB, RelevantStakeholders, DraggableTile, PlaybookPageView, SuggestedChangesDB, PlaybookProvision, ReadOnlyContentType, PlaybookSmartSearch, AvailablePlaybookProvisionLinks, LinkedPlaybookProvision, UpdatedProvisionLinks, SystemAdminBookshelfView } from './types';

export const setPlaybookPage = (page: PlaybookPage) => action(AdminPlaybookActionTypes.SELECT_PLAYBOOK_PAGE, page);

export const setBuildPage = (page: BuildPage) => action(AdminPlaybookActionTypes.SELECT_BUILD_PAGE, page);

export const setSelectedTab = (tab: PlaybookBuilderTab) => action(AdminPlaybookActionTypes.SELECT_CREATE_PLAYBOOK_TAB, tab);

export const updatePlaybookValue = (key: keyof Playbook, value: string | string[] | number | PlaybookView | null) => action(AdminPlaybookActionTypes.UPDATE_PLAYBOOK_VALUE, { key, value });
export const updatePlaybookTags = (tags: PlaybookTag[]) => action(AdminPlaybookActionTypes.UPDATE_PLAYBOOK_TAGS, tags);
export const updatePlaybookContentValue = (key: keyof PlaybookContent, value: RawDraftContentState | null) => action(AdminPlaybookActionTypes.UPDATE_PLAYBOOK_CONTENT_VALUE, { key, value });

export const addPlaybookSection = () => action(AdminPlaybookActionTypes.ADD_PLAYBOOK_SECTION);
export const addPlaybookSubheader = () => action(AdminPlaybookActionTypes.ADD_PLAYBOOK_SUBHEADER);
export const removePlaybookSection = (sectionId: string) => action(AdminPlaybookActionTypes.REMOVE_PLAYBOOK_SECTION, sectionId);

export const toggleSection = (sectionId: string) => action(AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_SECTION, sectionId);

export const toggleDeviation = (sectionId: string) => action(AdminPlaybookActionTypes.TOGGLE_SECTION_DEVIATION, sectionId);

export const addDeviationRow = (sectionId: string, index?: number) => action(AdminPlaybookActionTypes.ADD_DEVIATION_ROW, { sectionId, index });
export const deleteDeviationRow = (sectionId: string, rowId: string) => action(AdminPlaybookActionTypes.REMOVE_DEVIATION_ROW, { sectionId, rowId });
export const updateSectionDeviation = (sectionId: string, rowId: string, key: string, value: string | RawDraftContentState | null | number) => action(AdminPlaybookActionTypes.UPDATE_DEVIATION_VALUE, { sectionId, rowId, key, value });

export const updatePlaybookProvision = (sectionId: string, key: keyof PlaybookProvision, value: string | string[] | RawDraftContentState | null | RelevantStakeholders[] | LinkedPlaybookProvision) => action(AdminPlaybookActionTypes.UPDATE_PLAYBOOK_PROVISION, { sectionId, key, value });
export const updatePlaybookSubheader = (sectionId: string, value: string) => action(AdminPlaybookActionTypes.UPDATE_PLAYBOOK_SUBHEADER, { sectionId, value });

export const collapseAllSections = () => action(AdminPlaybookActionTypes.COLLAPSE_ALL_SECTIONS);

export const changeCurrentView = (view: PlaybookView) => action(AdminPlaybookActionTypes.CHANGE_PLAYBOOK_CURRENT_VIEW, view);

export const toggleCreateTagModal = (tagContent: TagContent | null) => action(AdminPlaybookActionTypes.TOGGLE_CREATE_TAG_MODAL, tagContent);

export const toggleIsDraft = () => action(AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_IS_DRAFT);

export const publishPlaybookStarted = () => action(AdminPlaybookActionTypes.PUBLISH_PLAYBOOK_STARTED);
export const publishPlaybookSuccessful = (allPlaybooks: BasicPlaybook[], playbook: Playbook, latestPlaybook: PlaybookById) => action(AdminPlaybookActionTypes.PUBLISH_PLAYBOOK_SUCCESSFUL, { allPlaybooks, playbook, latestPlaybook });
export const publishPlaybookFailed = (error: string) => action(AdminPlaybookActionTypes.PUBLISH_PLAYBOOK_FAILED, error);

export const savePlaybookStarted = () => action(AdminPlaybookActionTypes.SAVE_PLAYBOOK_STARTED);
export const savePlaybookSuccessful = (allPlaybooks: BasicPlaybook[], playbook: Playbook, latestPlaybook: PlaybookById) => action(AdminPlaybookActionTypes.SAVE_PLAYBOOK_SUCCESSFUL, { allPlaybooks, playbook, latestPlaybook });
export const savePlaybookFailed = (error: string) => action(AdminPlaybookActionTypes.SAVE_PLAYBOOK_FAILED, error);

export const getAllPlaybookStarted = () => action(AdminPlaybookActionTypes.GET_ALL_PLAYBOOKS_STARTED);
export const getAllPlaybookSuccessful = (allPlaybooks: BasicPlaybook[]) => action(AdminPlaybookActionTypes.GET_ALL_PLAYBOOKS_SUCCESSFUL, allPlaybooks);
export const getAllPlaybookFailed = (error: string) => action(AdminPlaybookActionTypes.GET_ALL_PLAYBOOKS_FAILED, error);

export const fetchAllTemplatesStarted = () => action(AdminPlaybookActionTypes.FETCH_ALL_PLAYBOOK_TEMPLATES_STARTED);
export const fetchAllTemplatesSuccessful = (playbookTemplates: BasicPlaybook[]) => action(AdminPlaybookActionTypes.FETCH_ALL_PLAYBOOK_TEMPLATES_SUCCESSFUL, playbookTemplates);
export const fetchAllTemplatesFailed = (error: string) => action(AdminPlaybookActionTypes.FETCH_ALL_PLAYBOOK_TEMPLATES_FAILED, error);

export const getPlaybookByIdStarted = (playbookId: number, listView = true, suggestedChangesView = false) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_BY_ID_STARTED, { playbookId, listView, suggestedChangesView });
export const getPlaybookByIdSuccessful = (playbook: Playbook, latestPlaybook: PlaybookById) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_BY_ID_SUCCESSFUL, { playbook, latestPlaybook });
export const getPlaybookByIdFailed = (error: string) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_BY_ID_FAILED, error);

export const getPlaybookTemplateStarted = (playbookId: number) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_TEMPLATE_STARTED, { playbookId });
export const getPlaybookTemplateSuccessful = (playbook: Playbook) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_TEMPLATE_SUCCESSFUL, playbook);
export const getPlaybookTemplateFailed = (error: string) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_TEMPLATE_FAILED, error);

export const getPlaybookByDefinitionIdStarted = (playbookDefinitionId: number) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_BY_DEFINITION_ID_STARTED, { playbookDefinitionId });
export const getPlaybookByDefinitionIdSuccessful = (playbook: Playbook, latestPlaybook: PlaybookByDefinitionId) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_BY_DEFINITION_ID_SUCCESSFUL, { playbook, latestPlaybook });
export const getPlaybookByDefinitionIdFailed = (error: string) => action(AdminPlaybookActionTypes.GET_PLAYBOOK_BY_DEFINITION_ID_FAILED, error);

export const setPlaybookCanSave = (value: boolean) => action(AdminPlaybookActionTypes.SET_PLAYBOOK_CAN_SAVE, value);

export const setPlaybookPublishReason = (value: string) => action(AdminPlaybookActionTypes.SET_PLAYBOOK_PUBLISH_REASON, value);

export const togglePlaybookSaveModalOpen = (value: boolean) => action(AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_SAVE_MODAL_OPEN, value);
export const togglePlaybookPublishModalOpen = (value: boolean) => action(AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_PUBLISH_MODAL_OPEN, value);

export const setPlaybookCanNext = (value: boolean) => action(AdminPlaybookActionTypes.SET_PLAYBOOK_CAN_NEXT, value);

export const setPlaybookCanPublish = (value: boolean) => action(AdminPlaybookActionTypes.SET_PLAYBOOK_CAN_PUBLISH, value);

export const setPlaybookVersionUpdated = (value: boolean) => action(AdminPlaybookActionTypes.SET_PLAYBOOK_VERSION_UPDATED, value);

export const setPlaybookVersionMajorIncreased = (value: boolean) => action(AdminPlaybookActionTypes.SET_PLAYBOOK_VERSION_MAJOR_INCREASED, value);

export const updateExistingPlaybook = (playbook: Playbook) => action(AdminPlaybookActionTypes.UPDATE_EXISTING_PLAYBOOK, playbook);

export const togglePlaybookCoverSelected = (playbook: BasicPlaybook | null) => action(AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_COVER_SELECTED, playbook);

export const toggleTemplateCoverSelected = (playbookTemplate: BasicPlaybook | null) => action(AdminPlaybookActionTypes.TOGGLE_PLAYBOOK_TEMPLATE_COVER_SELECTED, playbookTemplate);

export const resetPlaybookBuild = () => action(AdminPlaybookActionTypes.RESET_PLAYBOOK_BUILD);

export const resetPlaybookState = () => action(AdminPlaybookActionTypes.RESET_PLAYBOOK_STATE);

export const togglePlaybookQueryModal = (playbookQueryId: number | null) => action(AdminPlaybookActionTypes.TOGGLE_ADMIN_QUERY_MODAL, playbookQueryId);

export const updateQueryResponse = (content: RawDraftContentState | null, user: User) => action(AdminPlaybookActionTypes.UPDATE_ADMIN_QUERY_RESPONSE, { content, user });

export const sendQueryResponseStarted = () => action(AdminPlaybookActionTypes.SEND_ADMIN_QUERY_RESPONSE_STARTED);
export const sendQueryResponseSuccessful = (allQueries: PlaybookQueryDB[]) => action(AdminPlaybookActionTypes.SEND_ADMIN_QUERY_RESPONSE_SUCCESSFUL, allQueries);
export const sendQueryResponseFailed = (error: string) => action(AdminPlaybookActionTypes.SEND_ADMIN_QUERY_RESPONSE_FAILED, error);

export const toggleSendEmailReply = () => action(AdminPlaybookActionTypes.TOGGLE_ADMIN_SEND_EMAIL_REPLY);

export const toggleQueryResolved = () => action(AdminPlaybookActionTypes.TOGGLE_ADMIN_QUERY_RESOLVED);

export const toggleProvisionsOrderModalOpen = () => action(AdminPlaybookActionTypes.TOGGLE_PROVISIONS_ORDER_MODAL);
export const setProvisionsOrder = (provisionOrder: DraggableTile[]) => action(AdminPlaybookActionTypes.SET_PROVISIONS_ORDER, provisionOrder);
export const saveProvisionsOrder = () => action(AdminPlaybookActionTypes.SAVE_PROVISIONS_ORDER);

export const setDeviationsOrderModalOpen = (value: string | null) => action(AdminPlaybookActionTypes.SET_DEVIATIONS_ORDER_MODAL, value);
export const setDeviationsOrder = (deviationOrder: DraggableTile[]) => action(AdminPlaybookActionTypes.SET_DEVIATIONS_ORDER, deviationOrder);
export const saveDeviationsOrder = () => action(AdminPlaybookActionTypes.SAVE_DEVIATIONS_ORDER);

export const openPlaybookSuggestedChanges = (playbookId: number) => action(AdminPlaybookActionTypes.OPEN_PLAYBOOK_SUGGESTED_CHANGES, playbookId);
export const backToOpenPlaybook = (playbookId: number) => action(AdminPlaybookActionTypes.BACK_TO_OPEN_PLAYBOOK, playbookId);
export const setSuggestedChange = (playbookSuggestionId: number, sectionId: string) => action(AdminPlaybookActionTypes.SET_SUGGESTED_CHANGE_OPEN, { playbookSuggestionId, sectionId });

export const setPlaybookPageView = (view: PlaybookPageView) => action(AdminPlaybookActionTypes.SET_PLAYBOOK_PAGE_VIEW, view);

export const toggleOutstandingSuggestedChange = (playbookSuggestionId: number) => action(AdminPlaybookActionTypes.TOGGLE_OUTSTANDING_SUGGESTED_CHANGE, playbookSuggestionId);

export const confirmResolvedStarted = () => action(AdminPlaybookActionTypes.CONFIRM_RESOLVED_STARTED);
export const confirmResolvedSuccessful = (playbookSuggestedChanges: SuggestedChangesDB[]) => action(AdminPlaybookActionTypes.CONFIRM_RESOLVED_SUCCESSFUL, playbookSuggestedChanges);
export const confirmResolvedFailed = (error: string) => action(AdminPlaybookActionTypes.CONFIRM_RESOLVED_FAILED, error);

export const toggleSuggestionReviewModalOpen = () => action(AdminPlaybookActionTypes.TOGGLE_SUGGESTION_REVIEW_MODAL_OPEN);

export const updateSuggestedChangesNewMessage = (content: RawDraftContentState | null, user: User) => action(AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_NEW_MESSAGE, { content, user });

export const updateSuggestedChangesConversationStarted = () => action(AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_STARTED);
export const updateSuggestedChangesConversationSuccessful = (allSuggestedChanges: SuggestedChangesDB[]) => action(AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_SUCCESSFUL, allSuggestedChanges);
export const updateSuggestedChangesConversationFailed = (error: string) => action(AdminPlaybookActionTypes.UPDATE_SUGGESTED_CHANGES_CONVERSATION_FAILED, error);

export const setSelectedResolvedSuggestedChange = (playbookSuggestionId: number | null) => action(AdminPlaybookActionTypes.SET_RESOLVED_SUGGESTED_CHANGE, playbookSuggestionId);

export const toggleResolvedSuggestedChangesContent = (contentType: ReadOnlyContentType) => action(AdminPlaybookActionTypes.TOGGLE_RESOLVED_SUGGESTED_CHANGES_CONTENT, contentType);

export const updatePlaybookSimpleSearch = (searchTerm: string) => action(AdminPlaybookActionTypes.UPDATE_ADMIN_PLAYBOOK_SIMPLE_SEARCH, searchTerm);
export const resetPlaybookSearch = () => action(AdminPlaybookActionTypes.RESET_ADMIN_PLAYBOOK_SEARCH);
export const updatePlaybookTemplateSimpleSearch = (searchTerm: string) => action(AdminPlaybookActionTypes.UPDATE_ADMIN_TEMPLATE_PLAYBOOK_SIMPLE_SEARCH, searchTerm);
export const resetPlaybookTemplateSearch = () => action(AdminPlaybookActionTypes.RESET_ADMIN_TEMPLATE_SEARCH);

export const updatePlaybookSmartSearch = (searchTerm: PlaybookSmartSearch[]) => action(AdminPlaybookActionTypes.UPDATE_ADMIN_PLAYBOOK_SMART_SEARCH, searchTerm);
export const playbookSmartSearchStarted = () => action(AdminPlaybookActionTypes.ADMIN_PLAYBOOK_SMART_SEARCH_STARTED);
export const playbookSmartSearchSuccessful = (playbooks: BasicPlaybook[]) => action(AdminPlaybookActionTypes.ADMIN_PLAYBOOK_SMART_SEARCH_SUCCESSFUL, playbooks);
export const playbookSmartSearchFailed = (error: string) => action(AdminPlaybookActionTypes.ADMIN_PLAYBOOK_SMART_SEARCH_FAILED, error);
export const resetPlaybookSmartSearch = () => action(AdminPlaybookActionTypes.RESET_ADMIN_PLAYBOOK_SMART_SEARCH);

export const getAvailablePlaybookProvisionLinksStarted = () => action(AdminPlaybookActionTypes.GET_AVAILABLE_PROVISION_LINKS_STARTED);
export const getAvailablePlaybookProvisionLinksSuccessful = (availableProvisionLinks: AvailablePlaybookProvisionLinks[]) => action(AdminPlaybookActionTypes.GET_AVAILABLE_PROVISION_LINKS_SUCCESSFUL, availableProvisionLinks);
export const getAvailablePlaybookProvisionLinksFailed = (error: string) => action(AdminPlaybookActionTypes.GET_AVAILABLE_PROVISION_LINKS_FAILED, error);

export const setProvisionToLink = (sectionId: string | null) => action(AdminPlaybookActionTypes.SET_PROVISION_TO_LINK, sectionId);
export const updateCurrentLinkedProvision = (value: LinkedPlaybookProvision) => action(AdminPlaybookActionTypes.UPDATE_CURRENT_LINKED_PROVISIONS, value);
export const savePlaybookLinkedProvisions = () => action(AdminPlaybookActionTypes.SAVE_PLAYBOOK_LINKED_PROVISIONS);
export const toggleLinkedProvisionModal = () => action(AdminPlaybookActionTypes.TOGGLE_LINKED_PROVISIONS_MODAL);
export const setUpdatedProvisionLinks = (provisionLinks: UpdatedProvisionLinks) => action(AdminPlaybookActionTypes.SET_UPDATED_PROVISION_LINKS, provisionLinks);

export const toggleDeleteSectionConfirmationModal = (value: string | null) => action(AdminPlaybookActionTypes.TOGGLE_DELETE_SECTION_CONFIRMATION_MODAL, value);

export const toggleReadOnlyProvisionLinkModal = (sectionId: string | null) => action(AdminPlaybookActionTypes.TOGGLE_READ_ONLY_LINKED_PROVISION_MODAL, sectionId);
export const openProvisionLink = (linkFrom: LinkedPlaybookProvision, linkTo: LinkedPlaybookProvision) => action(AdminPlaybookActionTypes.OPEN_PROVISION_LINK, { linkFrom, linkTo });
export const updateLinkedProvisionHistory = (linkHistory: LinkedPlaybookProvision[]) => action(AdminPlaybookActionTypes.UPDATE_LINKED_PROVISION_HISTORY, linkHistory);

export const playbookBackCalled = (index?: number | null) => action(AdminPlaybookActionTypes.PLAYBOOK_BACK_CALLED, index);

export const toggleProvisionLinkHistoryModal = (value: boolean) => action(AdminPlaybookActionTypes.TOGGLE_PROVISION_LINK_HISTORY_MODAL, value);

export const changeSystemAdminBookshelfView = (view: SystemAdminBookshelfView) => action(AdminPlaybookActionTypes.CHANGE_SYSTEM_ADMIN_BOOKSHELF_VIEW, view);
