import React from 'react';

import { ConfirmationModal } from './ConfirmationModal';
import { ModalHeader } from './ModalHeader';
import { SaveFile, WarningSign } from '../icons';
import { Icon } from '../icon/Icon';
import styles from './Modal.module.scss';

interface UnsavedChangesModalProps {
    isOpen: boolean;
    closeModal: () => void;
    confirm: () => void;
    children?: JSX.Element | null;
}

export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({ isOpen, closeModal, confirm, children }) => {
    return (
        <ConfirmationModal
            isOpen={isOpen}
            closeModal={closeModal}
            confirm={confirm}
            confirmLabel='Yes, do not save'
        >
            <div className={styles.unsavedChangesModalWrapper}>
                <ModalHeader
                    testId='unsaved-changes-modal'
                    label='Unsaved Changes'
                    icon={SaveFile}
                />
                <div className={styles.modalWarning}>
                    <Icon icon={WarningSign} />
                    <div className={styles.modalWarningTitle}>You have unsaved changes.</div>
                </div>
                {children}
                <div className={styles.modalContent}>Are you sure you want to go back, any changes that have been made will be lost?</div>
            </div>
        </ConfirmationModal>
    );
};
