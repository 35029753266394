import React, { useMemo } from 'react';
import Modal from 'react-modal';

import { useAppSelector } from '../../../../hooks/react-redux';
import { ANALYSIS_MARGIN, ANALYSIS_VIEW_BUTTONS, NAV_BAR, PAGE_BORDER, useSplitView } from '../../../../hooks/useSplitView';
import { AnalysisView, getDocumentAnalysisView } from '../../../documents/my-documents/store';
import { getOpinionAnalysisView } from '../../../opinions/my-opinions/store';
import styles from './ModalInstance.module.scss';

interface DatasetModalProps {
    children?: React.ReactNode;
    closeModal: () => void;
    isOpen: boolean;
    topOffset?: string;
    isOpinionInstance?: boolean;
}

export const DatasetModal: React.FC<DatasetModalProps> = ({ children, closeModal, isOpen, topOffset = '30vh', isOpinionInstance = false }) => {
    const widthOffset = ANALYSIS_MARGIN * 2;
    const heightOffset = ANALYSIS_MARGIN + PAGE_BORDER;
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const opinionAnalysisView = useAppSelector(getOpinionAnalysisView);

    const analysisView = isOpinionInstance ? opinionAnalysisView : documentAnalysisView;

    const [width, height] = useSplitView(analysisView, widthOffset, heightOffset);
    const leftOffset = useMemo(() => analysisView === AnalysisView.SPLIT ? width + ANALYSIS_VIEW_BUTTONS + (ANALYSIS_MARGIN * 3) : ANALYSIS_VIEW_BUTTONS + ANALYSIS_MARGIN, [analysisView, width]);

    return (
        <Modal
            isOpen={isOpen && documentAnalysisView !== AnalysisView.DOCUMENT}
            className={styles.modal}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: 'flex',
                    zIndex: 9,
                    position: 'absolute',
                    left: `${leftOffset}px`,
                    top: `${NAV_BAR}px`,
                    width: `${width}px`,
                    height: `${height}px`,
                    borderRadius: '5px'
                },
                content: {
                    top: topOffset
                }
            }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onRequestClose={closeModal}
        >
            {children}
        </Modal>
    );
};
