import React from 'react';
import { isNull } from 'lodash/fp';
import Select, { Options } from 'react-select';

import { customStyles, DropdownOption, DropdownOptions } from './Dropdown';
import styles from './Dropdown.module.scss';

interface SingleSelectDropdownProps {
    value: string | null;
    options: string[];
    updateValue: (value: string | null) => void;
    label: string;
    disabled?: boolean;
    testId?: string;
}

export const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({ value, disabled = false, options, updateValue, testId = '', label }) => {

    const onChange = (option: DropdownOption | Options<DropdownOption> | null) => {
        let value = null;
        if (!isNull(option)) {
            value = (option as DropdownOption).value;
        }
        updateValue(value);
    };

    const mappedOptions: DropdownOptions = options.map(option => ({ value: option, label: option }));
    const mappedValue: DropdownOptions = value ? [{ value, label: value }] : undefined;

    return (
        <div
            data-testid={`single-select-dropdown-wrapper-${testId}`}
            className={styles.singleSelectDropdown}
        >
            <div data-testid={`single-select-dropdown-${testId}-label`} className={styles.dropdownLabel}>{label}</div>
            <Select
                className={styles.singleSelectDropdownSelect}
                classNamePrefix='ark-dropdown'
                isDisabled={disabled}
                value={mappedValue}
                onChange={onChange}
                options={mappedOptions}
                styles={customStyles}
                fontWeight={500}
            />
        </div>
    );
};
