import React, { useCallback, useMemo } from 'react';

import { useAppSelector, useAppDispatch } from '../../../hooks/react-redux';
import { Spinner } from '../../shared/spinner/Spinner';
import { WizardModal } from '../../shared/wizard/WizardModal';
import { Calendar } from '../../shared/icons';
import { Confirm, Content, Schedule } from './tabs';
import { getCalendarWizardOpen, getSelectedEventTab, getSelectedEventTabValid, saveScheduledActionStarted, ScheduleEventTab, Scheduler, setSelectedEventTab, toggleScheduleWizard, getIsSavingScheduledAction, setIsEditing, getIsEditing, getScheduledActionUpdated } from './store';
import styles from './Workflow.module.scss';

interface WorkflowWizardProps {
    event: Scheduler;
}

export const WorkflowWizard: React.FC<WorkflowWizardProps> = ({ event }) => {
    const dispatch = useAppDispatch();
    const selectedTab = useAppSelector(getSelectedEventTab);
    const tabValid = useAppSelector(getSelectedEventTabValid);
    const wizardIsOpen = useAppSelector(getCalendarWizardOpen);
    const isSaving = useAppSelector(getIsSavingScheduledAction);
    const isEditing = useAppSelector(getIsEditing);
    const hasUpdated = useAppSelector(getScheduledActionUpdated);
    const isArchived = !!event.isArchived;
    const hasCompleted = !!event.hasCompleted;

    const modalTitle = `${event.scheduledActionId ? 'Update' : 'Create'} Event`;

    const scheduleTabs = Object.values(ScheduleEventTab);
    const selectedTabIndex = scheduleTabs.indexOf(selectedTab);

    const selectTab = useCallback((tab: ScheduleEventTab) => dispatch(setSelectedEventTab(tab)), [dispatch]);
    const previous = useCallback(() => selectTab(scheduleTabs[selectedTabIndex - 1]), [scheduleTabs, selectTab, selectedTabIndex]);
    const next = useCallback(() => selectTab(scheduleTabs[selectedTabIndex + 1]), [scheduleTabs, selectTab, selectedTabIndex]);
    const startEditing = useCallback(() => dispatch(setIsEditing(true)), [dispatch]);
    const save = useCallback(() => dispatch(saveScheduledActionStarted()), [dispatch]);

    const closeWizard = useCallback(() => {
        dispatch(toggleScheduleWizard(false));
        selectTab(ScheduleEventTab.SCHEDULE);
    }, [dispatch, selectTab]);

    const wizardTabs = useMemo(() => scheduleTabs.map((tab, index) => ({
        label: tab,
        selected: tab === selectedTab,
        onClick: () => selectTab(tab),
        disabled: index > selectedTabIndex && !tabValid
    })), [selectTab, selectedTab, selectedTabIndex, scheduleTabs, tabValid]);

    const wizardButtons = useMemo(() => [
        {
            label: 'Close',
            onClick: closeWizard,
            showButton: true,
            displayLeft: true,
            disabled: false,
            disabledTooltip: null
        },
        {
            label: 'Edit',
            onClick: startEditing,
            displayLeft: true,
            disabled: false,
            disabledTooltip: null,
            showButton: !isEditing && !isArchived && !hasCompleted
        },
        {
            label: 'Previous',
            onClick: previous,
            disabled: false,
            showButton: selectedTabIndex !== 0,
            disabledTooltip: null,
        },
        {
            label: 'Next',
            onClick: next,
            disabled: !tabValid,
            disabledTooltip: null,
            showButton: selectedTabIndex !== scheduleTabs.length - 1
        },
        {
            label: 'Save',
            onClick: save,
            disabled: !tabValid || isSaving || (!!event.scheduledActionId && !hasUpdated),
            disabledTooltip: null,
            showButton: selectedTabIndex === scheduleTabs.length - 1 && isEditing
        }
    ], [
        selectedTabIndex,
        scheduleTabs,
        closeWizard,
        previous,
        next,
        save,
        tabValid,
        isSaving,
        isEditing,
        startEditing,
        event.scheduledActionId,
        hasUpdated,
        isArchived,
        hasCompleted
    ]);

    const testId = 'admin-workflow-schedule-event';

    const tabContent = useMemo(() => {
        if (isSaving) {
            return <Spinner />;
        }
        switch (selectedTab) {
            case ScheduleEventTab.SCHEDULE:
                return <Schedule event={event} isEditing={isEditing} testId={testId} />;
            case ScheduleEventTab.CONTENT:
                return <Content event={event} testId={testId} isEditing={isEditing} />;
            case ScheduleEventTab.CONFIRM:
                return <Confirm event={event} testId={testId} isEditing={isEditing} />;
            default:
                return <Spinner />;
        }
    }, [selectedTab, event, isSaving, isEditing]);

    return (
        <WizardModal
            isOpen={wizardIsOpen}
            buttons={wizardButtons}
            tabs={wizardTabs}
            title={modalTitle}
            testId={testId}
            closeWizard={closeWizard}
            headerIcon={Calendar}
        >
            <div className={styles.wizardTabContentWrapper}>
                {tabContent}
            </div>
        </WizardModal>
    );
};

