import { isNull } from 'lodash/fp';
import React from 'react';

import { useAppSelector } from '../../../../hooks/react-redux';
import styles from '../Reports.module.scss';
import { getSelectedTemplate } from '../store';
import { OpinionSelect } from './OpinionSelect';

export const Select: React.FC = () => {
    const selectedTemplate = useAppSelector(getSelectedTemplate);
    const disabled = !isNull(selectedTemplate);

    return (
        <div className={styles.selectWrapper}>
            <OpinionSelect disabled={disabled} />
        </div>
    );
};
