import { ApplicationState } from '../../../store/rootReducer';
import { TableFilters } from '../../shared/modal/TableFilterModal';
import { ColumnSort } from '../../shared/table/ArkTable';
import { UserAttestationState, UserAttestationInstanceDB, UserAttestationInstance } from './types';

const getRoot = (state: ApplicationState): UserAttestationState => state.attestations;

export const getAllUserAttestationInstances = (state: ApplicationState): UserAttestationInstanceDB[] => getRoot(state).attestationInstances;

export const getCompletedTableView = (state: ApplicationState): boolean => getRoot(state).completed;

export const getIsLoading = (state: ApplicationState): boolean => getRoot(state).isLoading;

export const getSelectedUserAttestationInstance = (state: ApplicationState): UserAttestationInstance | null => getRoot(state).selectedUserAttestationInstance;

export const getUserAttestationInstanceModalOpen = (state: ApplicationState): boolean => getRoot(state).userAttestationInstanceModalOpen;

export const getUserAttestationUpdated = (state: ApplicationState): boolean => getRoot(state).attestationUpdated;

export const getAlertModalOpen = (state: ApplicationState): string | null => getRoot(state).alertModalOpen;

// Table Filters and Pagination
export const getAttestationsPageNumber = (state: ApplicationState): number => getRoot(state).attestationPage;
export const getTotalAttestations = (state: ApplicationState): number => getRoot(state).totalAttestations;
export const getAttestationFilters = (state: ApplicationState): TableFilters => getRoot(state).attestationFilters;
export const getAttestationColumnSort = (state: ApplicationState): ColumnSort | undefined => getRoot(state).attestationSort;
export const getAttestationsPageSize = (state: ApplicationState): number => getRoot(state).attestationPageSize;
