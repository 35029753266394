import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { isNull } from 'lodash/fp';

import { OverflowTooltip, InformationTooltip, CustomTooltip } from '../../../shared/tooltip';
import styles from '../../../shared/datasets/SharedStyling.module.scss';
import { AgencyDatasetFieldType, FieldHighlight, TableDatasetFieldType } from '../../store';
import { widthsByType } from '../../../admin/dataset-builder/preview/tablePreview/TableHeader';
import { IconButton } from '../../../shared/button/IconButton';
import { CaretSide } from '../../../shared/icons';
import { useFieldHighlightColor } from '../../../../hooks/useFieldHighlightColor';
import { useAppSelector } from '../../../../hooks/react-redux';
import { getSearchFieldSection, OpenFieldType } from '../store';

const { white } = styles;

interface TableHeaderProps {
    id: string;
    label: string;
    description: string;
    refLabel?: string
    showRef?: boolean;
    type: TableDatasetFieldType | AgencyDatasetFieldType;
    isFirstCell: boolean;
    aliasLabel?: string;
    columnCollapsed: boolean;
    toggleCollapseColumn: (id: string) => void;
    highlight: FieldHighlight | null | undefined;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ label, description, id, refLabel, showRef, type, isFirstCell, aliasLabel, columnCollapsed, toggleCollapseColumn, highlight }) => {
    const searchFieldSection = useAppSelector(getSearchFieldSection);
    const cellWidth = useMemo(() => columnCollapsed ? '16px' : `${widthsByType[type]}px`, [columnCollapsed, type]);
    const columnLabel = useMemo(() => aliasLabel || label || 'Label...', [aliasLabel, label]);

    const toggleColumn = useCallback(() => toggleCollapseColumn(id), [toggleCollapseColumn, id]);

    const { color, backgroundColor } = useFieldHighlightColor(highlight, true);

    const isSearchedField = useMemo(() => !isNull(searchFieldSection) && searchFieldSection.type === OpenFieldType.FIELD && searchFieldSection.fieldId === id, [searchFieldSection, id]);

    const headerContent = useMemo(() => columnCollapsed ? (
        <div className={styles.cellHeader}>
            <CustomTooltip overlayText={columnLabel}>
                <div className={styles.headerIconWrapper}>
                    <IconButton icon={CaretSide} onClick={toggleColumn} color={white} fontSize={14} />
                </div>
            </CustomTooltip>
        </div>
    ) : (
        <div className={styles.cellHeader}>
            <OverflowTooltip className={classnames(styles.headerLabel, { [styles.emptyHeaderLabel]: !label })} overlayText={columnLabel} testId={`table-header-${id}-label`} />
            <div className={styles.iconsWrapper}>
                {aliasLabel && <InformationTooltip label='L' content={label} labelColor={white} />}
                {description && <InformationTooltip content={description} labelColor={white} />}
                {refLabel && showRef && <InformationTooltip content={refLabel} label='?' labelColor={white} />}
                <div className={styles.flippedIcon}><IconButton icon={CaretSide} onClick={toggleColumn} color={white} fontSize={14} /></div>
            </div>
        </div>
    ), [columnCollapsed, columnLabel, aliasLabel, description, refLabel, showRef, toggleColumn, label, id]);

    return (
        <div
            className={classnames(styles.tableHeaderWrapper, {
                [styles.firstCell]: isFirstCell,
                [styles.searchedFieldWrapper]: isSearchedField
            })}
            style={{ width: cellWidth, minWidth: cellWidth, color, backgroundColor }}
            id={`dataset-instance-field-${id}`}
        >
            {headerContent}
        </div>
    );
};
