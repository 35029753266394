import { toast } from 'react-toastify';
import download from 'downloadjs';
import { put, takeEvery, all, fork, call, select } from 'redux-saga/effects';

import { downloadRegisterOfInformation, fetchAllInScopeEntities } from '../../../../services/dora';
import { downloadRegisterOfInformationFailed, downloadRegisterOfInformationSuccessful, fetchAllInScopeEntitiesFailed, fetchAllInScopeEntitiesSuccessful } from './actions';
import { DoraReportingActionTypes, PreliminaryRoi } from './types';
import { getPreliminaryRoi } from './selectors';
import { CompanyEntity } from '../../../admin/entity/store';

export function* attemptDocumentDownload() {
    try {
        const preliminaryRoi: PreliminaryRoi = yield select(getPreliminaryRoi);
        const doubleRowHeight = navigator.userAgent.indexOf('Win') !== -1;
        const document: Blob = yield call(downloadRegisterOfInformation, { preliminaryRoi, doubleRowHeight });
        yield call(download, document, 'Register_Of_Information', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        yield put(downloadRegisterOfInformationSuccessful());
    } catch (e) {
        yield put(downloadRegisterOfInformationFailed((e as Error).message));
        toast.error('Unable to download ROI document. Please try again.');
    }
}

function* downloadDocumentWatcher() {
    yield takeEvery(DoraReportingActionTypes.DOWNLOAD_REGISTER_OF_INFORMATION_STARTED, attemptDocumentDownload);
}

export function* attemptFetchAllInScopeEntities() {
    try {
        const entities: CompanyEntity[] = yield call(fetchAllInScopeEntities);
        yield put(fetchAllInScopeEntitiesSuccessful(entities));
    } catch (e) {
        yield put(fetchAllInScopeEntitiesFailed((e as Error).message));
        toast.error('Unable to fetch the In Scope Entities. Please try again.');
    }
}

function* fetchAllInScopeEntitiesWatcher() {
    yield takeEvery(DoraReportingActionTypes.FETCH_ALL_IN_SCOPE_ENTITIES_STARTED, attemptFetchAllInScopeEntities);
}

export function* doraReportingSaga() {
    yield all([
        fork(downloadDocumentWatcher),
        fork(fetchAllInScopeEntitiesWatcher)
    ]);
}
