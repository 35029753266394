import classnames from 'classnames';
import React from 'react';

import styles from './HomeWrapper.module.scss';

interface CollapsibleSideMenuItemProps {
    label: string;
    isOpen: boolean;
    toggleOpen: () => void;
    textAlign?: string;
    testId?: string;
    children: JSX.Element | JSX.Element[];
    disabled?: boolean;
}

export const CollapsibleSideMenuItem: React.FC<CollapsibleSideMenuItemProps> = ({ label, isOpen, textAlign = 'left', testId, children, toggleOpen, disabled = false }) => (
    <div className={classnames(
        styles.collapsibleSideMenuWrapper,
        {
            [styles.openCollapsibleChildren]: isOpen
        }
    )} data-testid={`collapsible-side-menu-item-${testId}-wrapper`}>
        <button
            className={classnames(
                styles.sideMenuItem,
                {
                    [styles.openSideMenuItem]: isOpen,
                    [styles.leftMenuItem]: textAlign === 'left',
                    [styles.rightMenuItem]: textAlign === 'right',
                    [styles.disabledMenuItem]: disabled
                }
            )}
            data-testid={`collapsible-side-menu-item-${testId}-label`}
            onClick={() => toggleOpen()}
        >
            {label}
        </button>
        {isOpen &&
            <div className={styles.collapsibleSideMenuChildren}>
                {children}
            </div>
        }
    </div>
);
