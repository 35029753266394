import React from 'react';

import styles from './Users.module.scss';
import { PlusButton } from '../../shared/button/PlusButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getModalOpen } from './store/selectors';
import { toggleUsersModal } from './store';
import { UpsertUserModal } from './UpsertUserModal';
import { UsersTable } from './UsersTable';

export const Users: React.FC = () => {
    const dispatch = useAppDispatch();

    const handleModalToggle = (isOpen: boolean) => dispatch(toggleUsersModal(isOpen));
    const openModal = () => handleModalToggle(true);
    const closeModal = () => handleModalToggle(false);

    const modalOpen = useAppSelector(getModalOpen);

    return (
        <div className={styles.usersWrapper} data-testid='users-wrapper'>
            <div className={styles.usersTitleContainer}>
                <div className={styles.usersTitle} data-testid='users-title'>Users</div>
                <PlusButton onClick={openModal} fontSize={22} testId='create-user' />
                <UpsertUserModal isOpen={modalOpen} closeModal={closeModal} />
            </div>
            <UsersTable />
        </div>
    );
};
