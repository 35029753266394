import React from 'react';

import styles from './Guides.module.scss';

const bookshelfUrl = 'https://heyzine.com/shelf/31c84eee81.html';

const iFrameId = 'negotiation-guide-flipbook';

export const Guides: React.FC = () => (
    <div className={styles.wrapper}>
        <div className={styles.title}>Negotiation Guides</div>
        <iframe src={bookshelfUrl} className={styles.iFrame} id={iFrameId} />
    </div>
);
