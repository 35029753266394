import React from 'react';

export const Time: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width || '1em'} height={height || '1em'} viewBox="0 0 128 128">
        <path fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} d="M102.4,111.6H26.2c-6.6,0-12-5.4-12-12V23.4c0-6.6,5.4-12,12-12h76.2c6.6,0,12,5.4,12,12v76.2C114.4,106.2,109,111.6,102.4,111.6z" />
        <circle fill='none' stroke={color || 'currentColor'} strokeWidth={6} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' cx="64" cy="61.5" r="40.2" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="64.3" y1="34" x2="64.3" y2="61.5" />
        <line fill='none' stroke={color || 'currentColor'} strokeWidth={4} strokeMiterlimit={10} strokeLinecap='round' strokeLinejoin='round' x1="50.7" y1="81.6" x2="64.3" y2="61.5" />
    </svg>
);
