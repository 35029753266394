import { isUndefined } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { doraEntityServiceType } from '../../constants/dora';
import { emirOptions, MiFIDOptions, opinionCounterpartyTypeOptions } from '../../constants/entity';
import { HourglassButton } from '../../shared/button/HourglassButton';
import { Icon } from '../../shared/icon/Icon';
import { CaretDown, CaretSide, Robot, Tick } from '../../shared/icons';
import { ConfirmationModal } from '../../shared/modal/ConfirmationModal';
import { DoraDescriptionModal } from '../../shared/modal/DoraDescriptionModal';
import { ModalHeader } from '../../shared/modal/ModalHeader';
import { UnsavedChangesModal } from '../../shared/modal/UnsavedChangesModal';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { Text } from '../../shared/text/Text';
import { Toggle } from '../../shared/toggle';
import { CustomTooltip } from '../../shared/tooltip';
import { getAllDropdownLists } from '../dropdown-lists/store';
import styles from './Entity.module.scss';
import { AiDropdownField } from './entityTypes/AiDropdownField';
import { AIGeneratedEntityField, assertEntityClassificationStarted, ClassificationCompanyEntity, EntityAIFieldKey, getClassificationFilter, getCurrentClassificationEntities, getCurrentClassifyingEntityIds, getEntityClassificationCloseModalOpen, getEntityClassificationHasUpdated, getIsSaving, getShowClassificationCompleted, setEntityClassificationFilter, toggleEntityClassificationCloseModal, toggleEntityClassificationCompleted, updateClassificationEntitiesStarted, updateEntityClassificationField } from './store';

const { green, amber } = styles;

interface EntityClassificationModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

export const EntityClassificationModal: React.FC<EntityClassificationModalProps> = ({ isOpen, closeModal }) => {
    const [openEntitySectionIds, setOpenEntitySectionIds] = useState<number[]>([]);
    const [doraDescriptionModalOpen, setDoraDescriptionModalOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const classificationEntities = useAppSelector(getCurrentClassificationEntities);
    const currentClassifyingEntityIds = useAppSelector(getCurrentClassifyingEntityIds);
    const nameFilter = useAppSelector(getClassificationFilter);
    const showCompleted = useAppSelector(getShowClassificationCompleted);
    const allDropdowns = useAppSelector(getAllDropdownLists);
    const hasUpdated = useAppSelector(getEntityClassificationHasUpdated);
    const isSaving = useAppSelector(getIsSaving);
    const unsavedChangesModalOpen = useAppSelector(getEntityClassificationCloseModalOpen);

    useEffect(() => {
        if (!isOpen) {
            setOpenEntitySectionIds([]);
        }
    }, [isOpen]);

    const toggleDescriptionModal = useCallback(() => setDoraDescriptionModalOpen(!doraDescriptionModalOpen), [doraDescriptionModalOpen]);

    const getSectionOpen = useCallback((entityId: number) => openEntitySectionIds.includes(entityId), [openEntitySectionIds]);
    const toggleSection = useCallback((isOpen: boolean, entityId: number) => {
        const openSectionIds = isOpen ? openEntitySectionIds.filter(openEntityId => openEntityId !== entityId) : [...openEntitySectionIds, entityId];
        setOpenEntitySectionIds(openSectionIds);
    }, [openEntitySectionIds]);
    const getSectionOpenIcon = useCallback((isOpen: boolean) => isOpen ? CaretDown : CaretSide, []);

    const updateAIFieldContent = useCallback((entityId: number, key: EntityAIFieldKey, secondaryKey: keyof AIGeneratedEntityField, value: string | null | boolean) => dispatch(updateEntityClassificationField(entityId, key, secondaryKey, value)), [dispatch]);
    const updateEntityClassifications = useCallback(() => dispatch(updateClassificationEntitiesStarted()), [dispatch]);
    const toggleUnsavedChangesModal = useCallback((isOpen: boolean) => dispatch(toggleEntityClassificationCloseModal(isOpen)), [dispatch]);
    const closeUnsavedChangesModal = useCallback(() => toggleUnsavedChangesModal(false), [toggleUnsavedChangesModal]);
    const openUnsavedChangesModal = useCallback(() => toggleUnsavedChangesModal(true), [toggleUnsavedChangesModal]);

    const counterpartyTypeDropdownOptions = allDropdowns.find(({ name }) => name === 'CounterpartyType')?.options || [];
    const entityTypeOptions = useMemo(() => doraEntityServiceType.map(({ type }) => type), []);

    const updateCounterpartyType = (entityId: number, value: string | null) => updateAIFieldContent(entityId, 'counterpartyType', 'value', value);
    const updateOpinionCounterpartyType = (entityId: number, value: string | null) => updateAIFieldContent(entityId, 'opinionCounterpartyType', 'value', value);
    const updateEmirClassification = (entityId: number, value: string | null) => updateAIFieldContent(entityId, 'emirClassification', 'value', value);
    const updateMiFIDClassification = (entityId: number, value: string | null) => updateAIFieldContent(entityId, 'MiFIDClassification', 'value', value);
    const updateEntityServiceType = (entityId: number, value: string | null) => updateAIFieldContent(entityId, 'entityServiceType', 'value', value);

    const updateCounterpartyTypeConfirmed = (entityId: number, value: boolean) => updateAIFieldContent(entityId, 'counterpartyType', 'aiConfirmed', value);
    const updateOpinionCounterpartyTypeConfirmed = (entityId: number, value: boolean) => updateAIFieldContent(entityId, 'opinionCounterpartyType', 'aiConfirmed', value);
    const updateEmirClassificationConfirmed = (entityId: number, value: boolean) => updateAIFieldContent(entityId, 'emirClassification', 'aiConfirmed', value);
    const updateMiFIDClassificationConfirmed = (entityId: number, value: boolean) => updateAIFieldContent(entityId, 'MiFIDClassification', 'aiConfirmed', value);
    const updateEntityServiceTypeConfirmed = (entityId: number, value: boolean) => updateAIFieldContent(entityId, 'entityServiceType', 'aiConfirmed', value);

    const assertEntityClassification = useCallback((entity: ClassificationCompanyEntity) => dispatch(assertEntityClassificationStarted(entity)), [dispatch]);
    const updateFilterValue = useCallback((value: string) => dispatch(setEntityClassificationFilter(value)), [dispatch]);
    const toggleShowCompleted = useCallback((value: boolean) => dispatch(toggleEntityClassificationCompleted(value)), [dispatch]);

    const attemptCloseModal = useCallback(() => hasUpdated ? openUnsavedChangesModal() : closeModal(), [hasUpdated, openUnsavedChangesModal, closeModal]);
    const closeBothModals = useCallback(() => {
        closeUnsavedChangesModal();
        closeModal();
    }, [closeModal, closeUnsavedChangesModal]);

    const getCompleted = useCallback((entity: ClassificationCompanyEntity) => {
        const { MiFIDClassification, counterpartyType, opinionCounterpartyType, emirClassification, entityServiceType } = entity;
        return [MiFIDClassification, counterpartyType, opinionCounterpartyType, emirClassification, entityServiceType].every(field => !isUndefined(field) && ((field.value && !field.aiPopulated) || field.aiConfirmed));
    }, []);

    const getAwaitingVerification = useCallback((entity: ClassificationCompanyEntity) => {
        const { MiFIDClassification, counterpartyType, opinionCounterpartyType, emirClassification, entityServiceType } = entity;
        return [MiFIDClassification, counterpartyType, opinionCounterpartyType, emirClassification, entityServiceType].some(field => !isUndefined(field) && (field.aiPopulated && !field.aiConfirmed));
    }, []);

    const getEntityIndicator = useCallback((entity: ClassificationCompanyEntity) => {
        const completed = getCompleted(entity);
        const awaitingVerification = getAwaitingVerification(entity);
        if (completed) {
            return (
                <Icon icon={Tick} color={green} />
            );
        }
        if (awaitingVerification) {
            return (
                <CustomTooltip overlayText='AI populated fields awaiting confirmation'>
                    <div>
                        <Icon icon={Tick} color={amber} />
                    </div>
                </CustomTooltip>
            );
        }
        const showHourglass = currentClassifyingEntityIds.includes(entity.entityId);
        const maxEntitiesClassifying = currentClassifyingEntityIds.length === 5;
        return (
            <HourglassButton icon={Robot} showHourglass={showHourglass} disabled={maxEntitiesClassifying} onClick={() => assertEntityClassification(entity)} />
        );
    }, [getCompleted, getAwaitingVerification, currentClassifyingEntityIds, assertEntityClassification]);

    return (
        <>
            <ConfirmationModal
                isOpen={isOpen}
                closeModal={attemptCloseModal}
                showPreview={openEntitySectionIds.length > 0}
                openPreview={() => setOpenEntitySectionIds([])}
                previewLabel='Collapse All'
                closeLabel='Close'
                confirmDisabled={!hasUpdated || isSaving}
                confirmLabel='Save'
                confirm={updateEntityClassifications}
            >
                <ModalHeader icon={Robot} label='Entity Classification' />
                <div className={styles.filterWrapper}>
                    <Text value={nameFilter} onChange={e => updateFilterValue(e.target.value)} testId='entity-classification-filter' placeholder='Filter' width='50%' />
                    <div className={styles.showCompletedWrapper}>
                        <div className={styles.showCompletedLabel}>Include Verified Entities</div>
                        <Toggle checked={showCompleted} onChange={toggleShowCompleted} />
                    </div>
                </div>
                <div className={styles.entityClassificationModal}>
                    <Scrollable>
                        {classificationEntities.map(entity => {
                            const { entityId, name, counterpartyType, opinionCounterpartyType, MiFIDClassification, emirClassification, entityServiceType } = entity;
                            const sectionOpen = getSectionOpen(entityId);
                            return (
                                <div className={styles.classificationEntityWrapper} key={entityId}>
                                    <div className={styles.classificationEntityHeader} onClick={() => toggleSection(sectionOpen, entityId)}>
                                        <div className={styles.sectionOpenIcon}>
                                            <Icon icon={getSectionOpenIcon(sectionOpen)} fontSize={15} />
                                        </div>
                                        <div className={styles.classificationEntityTitle}>{name}</div>
                                        <div className={styles.classificationEntityIndicator}>{getEntityIndicator(entity)}</div>
                                    </div>
                                    {sectionOpen &&
                                        <div className={styles.classificationEntityFields}>
                                            <AiDropdownField
                                                label='Counterparty Type'
                                                testId='entity-counterparty-type'
                                                updateValue={value => updateCounterpartyType(entityId, value)}
                                                toggleConfirmation={value => updateCounterpartyTypeConfirmed(entityId, value)}
                                                field={counterpartyType}
                                                options={counterpartyTypeDropdownOptions}
                                            />
                                            <AiDropdownField
                                                label='Opinion Counterparty Type'
                                                testId='entity-opinion-counterparty-type'
                                                updateValue={value => updateOpinionCounterpartyType(entityId, value)}
                                                field={opinionCounterpartyType}
                                                options={opinionCounterpartyTypeOptions}
                                                toggleConfirmation={value => updateOpinionCounterpartyTypeConfirmed(entityId, value)}
                                            />
                                            <AiDropdownField
                                                label='MiFID Classification'
                                                testId='entity-mifid-classification'
                                                updateValue={value => updateMiFIDClassification(entityId, value)}
                                                toggleConfirmation={value => updateMiFIDClassificationConfirmed(entityId, value)}
                                                field={MiFIDClassification}
                                                options={MiFIDOptions}
                                            />
                                            <AiDropdownField
                                                label='EMIR Classification'
                                                testId='entity-emir-classification'
                                                updateValue={value => updateEmirClassification(entityId, value)}
                                                toggleConfirmation={value => updateEmirClassificationConfirmed(entityId, value)}
                                                field={emirClassification}
                                                options={emirOptions}
                                            />
                                            <div className={styles.entityTypeInputWrapper}>
                                                <div className={styles.entityTypeInputLabel}>For the purposes of DORA, this entity is a:</div>
                                                <div className={styles.entityTypeWrapper}>
                                                    <div className={styles.dropdownWrapper}>
                                                        <AiDropdownField
                                                            testId='entity-dora-service-type'
                                                            updateValue={value => updateEntityServiceType(entityId, value)}
                                                            toggleConfirmation={value => updateEntityServiceTypeConfirmed(entityId, value)}
                                                            field={entityServiceType}
                                                            options={entityTypeOptions}
                                                        />
                                                    </div>
                                                    <button className={styles.button} onClick={toggleDescriptionModal}>What does this mean?</button>
                                                    <DoraDescriptionModal isOpen={doraDescriptionModalOpen} toggleDescriptionModal={toggleDescriptionModal} ictOrEntityTypes={doraEntityServiceType} label='Entity Type Descriptions' zIndex={10} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            );
                        })}
                    </Scrollable>
                </div>
            </ConfirmationModal>
            <UnsavedChangesModal
                isOpen={unsavedChangesModalOpen}
                closeModal={closeUnsavedChangesModal}
                confirm={closeBothModals}
            />
        </>
    );
};
