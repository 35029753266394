import React from 'react';

import { useAppSelector } from '../../../../../hooks/react-redux';
import { PAGE_MARGIN_PADDING_BORDER, DATASET_HEIGHT_OFFSET, useSplitView } from '../../../../../hooks/useSplitView';
import { getDocumentAnalysisView } from '../../../../documents/my-documents/store';
import { DatasetType } from '../../../store';
import { InstanceMLData } from '../../../store/mlTypes';
import styles from '../../DatasetInstance.module.scss';
import { DatasetInstance, getHiddenFields } from '../../store';
import { MLFormInstance } from './form-instance/MLFormInstance';
import { MLTableInstance } from './table-instance/MLTableInstance';

interface MLDatasetInstanceProps {
    datasetInstance: DatasetInstance;
    isEditing: boolean;
    isUpdating: boolean;
    parentDatasetIds: string[];
    parentFieldId: string;
    instanceExecutedDateMatchesParent: boolean;
    mlData: InstanceMLData;
    modalInstance?: boolean;
    datasetHidden?: boolean;
}

export const MLDatasetInstance: React.FC<MLDatasetInstanceProps> = ({ datasetInstance, isEditing, isUpdating, parentDatasetIds, modalInstance, parentFieldId, datasetHidden, instanceExecutedDateMatchesParent, mlData }) => {
    const widthOffset = PAGE_MARGIN_PADDING_BORDER + (parentDatasetIds.length * 10) + (modalInstance ? 18 : 0) + 10;
    const heightOffset = DATASET_HEIGHT_OFFSET;
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const [width] = useSplitView(documentAnalysisView, widthOffset, heightOffset);
    const padding = parentDatasetIds.length ? 10 : 0;
    const datasetInstanceWidth = modalInstance ? 'fit-content' : width;
    const hiddenFields = useAppSelector(getHiddenFields);

    return (
        <div className={styles.datasetInstanceContent} style={{ width: datasetInstanceWidth, maxWidth: `${width}px`, paddingLeft: `${padding}px`, height: '100%' }}>
            {datasetInstance.datasetType === DatasetType.FORM ?
                <MLFormInstance datasetInstance={datasetInstance} isEditing={isEditing} isUpdating={isUpdating} hiddenFields={hiddenFields} modalInstance={modalInstance} parentFieldId={parentFieldId} datasetHidden={datasetHidden} instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent} mlData={mlData} /> :
                <MLTableInstance datasetInstance={datasetInstance} isEditing={isEditing} isUpdating={isUpdating} modalInstance={modalInstance} parentFieldId={parentFieldId} hiddenFields={hiddenFields} datasetHidden={datasetHidden} instanceExecutedDateMatchesParent={instanceExecutedDateMatchesParent} mlData={mlData} />
            }
        </div>
    );
};
